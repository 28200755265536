import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { analytics, logEvent } from '../../../integrations/firebase'
// imports for store
import { useSelector, useDispatch } from 'react-redux';
import { updateSystemData } from '../../../store/actions/systemActions';
import { makeStyles } from "@mui/styles";

// imports components
import SearchIcon from "../../../images/Search Icon.svg";
import { HomeItem } from "../../../Styles/Cards/HomeItem";
import { ActionDiv } from "../../../Styles/Divs/Actions";
import info from '../../../images/info.svg'
import infoWhite from '../../../images/infoWhite.svg'
import starRegular from '../../../images/starRegular.svg'
import starRegularWhite from '../../../images/starRegularWhite.svg'
import { TooltipForms } from '../../Utils/TooltipForms'
import { Tooltip } from '../../Utils/Tooltip'
import { TooltipFormsExtra } from '../../Utils/TooltipFormsExtra'
import { useViewport } from '../../../Hooks/useViewport'
import db, { trace, perf } from '../../../integrations/firebase';

const useStyles = makeStyles((theme) => ({

  show: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#37383F",
    opacity: "0.3",
    color: "white",
    // font: "500 1.1rem Rubik",
    left: "-1.7em",
    top: "1.1em",
    textAlign: "center",
    transform: "rotate(-47deg)",
    minWidth: "7em",
    height: "1.8em",
    position: "absolute",
  },
  hidden: {
    display: "none",
  },
  buttom: {
    top: "1.3em",
    left: "-2.6em",
    height: "1.8em",
    display: "flex",
    position: "absolute",
    minWidth: "11em",
    transform: "rotate(-40deg)",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
  }
}));



export default function AllActions({ actions, onFavAction }) {
  const t = trace(perf, "AllActions");
  t.start();

  const classes = useStyles();
  const [searchTerm, setSearchTerm] = useState("");
  const systemData = useSelector((state) => state.systemReducer.systemData)
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.userReducer.user);
  const { width } = useViewport();
  const breakpoint = 767;
  const isMobile = width < breakpoint;
  // activeUser.pricelessActions = ['apostille']
  const [hoveredItemId, setHoveredItemId] = useState(null);

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const searchResults = actions.filter((choice) =>
    choice.title.toLowerCase().includes(searchTerm)
  );
  const setSystemData = (item) => {
    // dataSet({ files: [], recipients: [] })
    dispatch(updateSystemData({
      ...systemData,
      action: {
        formSubtitle: item?.formSubtitle || '',
        formId: item.nid,
        formTitle: item.title,
        formPrice: item.price,
        formImage: item.image,
        formIsActive: item.isActive,
        formIsPopular: item.isPopular,
        formCommon: item.isCommon,
        type: item.type
      },
      isEditMode: false,
      isFormModalOpen: true
    }));
  }
  const returnValue = (
    <ActionDiv>
      <div className="actions-title all">
        <h2 className="h2-home">כל השירותים</h2>
        <div className="search-field">
          <img src={SearchIcon} alt="SearchIcon" />
          <input
            type="text"
            className="search-input"
            placeholder="חיפוש שירות"
            value={searchTerm}
            onChange={handleChange}
          />
        </div>
      </div>

      <div className="actions-grid">
        {searchResults.map((item) => (
          <Link to={!item.isActive ? '/' : `/forms/${item.nid}`} key={item.nid}
            style={{ position: 'relative', height: '120px', overflow: `${item.isActive ? 'unset' : 'hidden'}` }}
            onMouseEnter={() => setHoveredItemId(item.nid)}
            onMouseLeave={() => setHoveredItemId(null)}
          >
            {hoveredItemId === item.nid && item.description && (
              <div style={{ height: '0' }}>
                <TooltipFormsExtra text={item.description} />
              </div>
            )}
            <HomeItem
              sx={{ "&:hover": { background: "#eee" } }}
              // className={`${item.type === 'smallClaim' ? 'special' : ''}`}
              onClick={() => {
                if (!item.isActive) return
                logEvent(analytics, 'open form', {
                  formName: item.title
                })
                setSystemData(item);
                // setModalContent(item);
                // setIsOpen(true);
              }}
            >
              {item.isActive && activeUser && !activeUser?.isAnonymous && !activeUser?.accessToken &&
                <div className="star-tooltip">
                  {isMobile ?
                    <img type='button' src={starRegular} width={20} alt="star-regular" className="info-icon" onClick={(ev) => { onFavAction(item, true); ev.preventDefault(); ev.stopPropagation() }} />
                    //FOR SPECIAL OPTION 
                    // <img type='button' src={item.type === 'smallClaim' ? starRegularWhite : starRegular} width={20} alt="star-regular" className="info-icon" onClick={(ev) => { onFavAction(item, true); ev.preventDefault(); ev.stopPropagation() }} />
                    :
                    <div onMouseEnter={() => setHoveredItemId(null)}>
                      <Tooltip text={'הוספה לרשימת השירותים השמורים שלי'}>
                        {/* FOR SPECIAL OPTION */}
                        {/* <img type='button' src={item.type === 'smallClaim' ? starRegularWhite : starRegular} width={20} alt="star-regular" className="info-icon" onClick={(ev) => { onFavAction(item, true); ev.preventDefault(); ev.stopPropagation() }} /> */}
                        <img type='button' src={starRegular} width={20} alt="star-regular" className="info-icon" onClick={(ev) => { onFavAction(item, true); ev.preventDefault(); ev.stopPropagation() }} />
                      </Tooltip>
                    </div>
                  }
                </div>}
              {item.isActive &&
                <div className={`info-tooltip ${activeUser && !activeUser?.isAnonymous && !activeUser?.accessToken ? 'user-display' : ''}`} onMouseEnter={() => setHoveredItemId(null)}>
                  <TooltipForms type={item.type} >
                    {/* FOR SPECIAL OPTION */}
                    {/* <img type='button' src={item.type === 'smallClaim' ? infoWhite : info} width={20} alt="info-icon" className="info-icon" onClick={(ev) => { ev.preventDefault(); ev.stopPropagation() }} /> */}
                    <img type='button' src={info} width={20} alt="info-icon" className="info-icon" onClick={(ev) => { ev.preventDefault(); ev.stopPropagation() }} />
                  </TooltipForms>
                </div>}
              {item.image && <img src={item.image} alt="icon" style={{ marginLeft: "1em" }} />}


              <div className="card-details">
                {/* FOR SPECIAL OPTION */}
                {/* <h3 className={`card-title ${item.type} ${item.type === 'smallClaim' ? 'special' : ''}`}>{item.title + ' ' + (item?.bracketsTitle || '')}</h3> */}
                {/* {!(activeUser?.pricelessActions?.includes(item.type)) && <p className={`card-price ${item.type === 'smallClaim' ? 'special' : ''}`}>{item.price}</p>} */}
                <h3 className={`card-title ${item.type}`}>{item.title + ' ' + (item?.bracketsTitle || '')}</h3>
                {!(activeUser?.pricelessActions?.includes(item.type)) && <p className="card-price">{item.price}</p>}
              </div>


              {(!item.isActive) && <span
                className={`LabelPopular ${classes.show}`}
                // className={`LabelPopular ${classes.show} ${item.type === 'smallClaim' ? 'special-pop' : ''}`}
              >
                בקרוב
              </span>}
              {/* {(item.type === 'printingBinding') && <span
                className={`LabelPopular ${classes.buttom} ${item.type === 'printingBinding' ? 'special-pop' : ''}`}
              >
                חדש
              </span>} */}
            </HomeItem>
          </Link>
        ))}
      </div>

    </ActionDiv>
  );
  t.stop();
  return returnValue
}
