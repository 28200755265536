import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { updateErrorModal } from '../../../store/actions/systemActions';
import { TooltipExtra } from '../../Utils/TooltipExtra'
import info from '../../../images/info.svg'
import infoWhite from '../../../images/infoWhite.svg'
import { useViewport } from '../../../Hooks/useViewport'

export function CollectBranchSelecting({ data, setData, errors, submitForm, setSubmitForm, type }) {

    const dispatch = useDispatch();
    const { width } = useViewport();
    const breakpoint = 767;
    const isMobile = width < breakpoint;

    const options = [
        { collectStreetAddress: 'שדרות הראשונים, מגדל מילניה, קומה 17', collectCity: 'ראשון לציון', mailFolder: null, tooltipType: 'branchRishon' },
    ]

    useEffect(() => {
        if (!data.collectionAddress) {
            setData({
                ...data,
                collectionAddress: options[0]
            });
        }
    }, []);

    useEffect(() => {
        if (submitForm && errors.collectionAddress) {
            setSubmitForm(false);
            dispatch(
                updateErrorModal({
                    title: 'שגיאה',
                    errorText: 'חובה לבחור סניף',
                    submitText: 'אישור',
                    isCancel: false
                })
            );
        }
    }, [submitForm, errors]);

    const handleBranchSelected = (branch) => {
        setData({ ...data, collectionAddress: branch });
    }

    const getTitleByTypeForm = () => {
        switch (type) {
            case 'apostilSubmission':
                return 'לאיזה סניף תעדיפו להעביר את התעודות?';
            case 'apostilInCourt':
                return 'לאיזה סניף תעדיפו להעביר את המסמכים הנוטריונים?'
            case 'printingBinding':
                return 'מאיזה סניף תעדיפו לאסוף את המסמכים?'
            default:
                return 'לאיזה סניף תעדיפו להעביר את התעודות?';
        }
    }

    return (
        <div className='main-form  branch-selecting update-cmps'>
            <div className='modal-content-container block'>
                <h2 className='med-title'>{getTitleByTypeForm()}</h2>
                <p className='subtitle'>
                    ניתן לשנות בעתיד במידת הצורך
                </p>

                <div className="branches">
                    {options.map((branch) => {
                        const isChosen = data?.collectionAddress && data?.collectionAddress?.collectStreetAddress === branch?.collectStreetAddress
                        return (<div key={branch.collectCity} className={`branch ${isChosen ? 'blue' : 'white'}`} onClick={() => handleBranchSelected(branch)}>
                            <input
                                type="radio"
                                id={branch.collectStreetAddress}
                                name="branch"
                                value={branch.collectStreetAddress}
                                defaultChecked={data.collectionAddress && (data.collectionAddress.collectStreetAddress === branch.collectStreetAddress)}
                            />
                            <span className="checkmark"></span>
                            <TooltipExtra type={branch.tooltipType}>
                                <img src={isChosen && !isMobile ? infoWhite : info} alt="info-icon" className="info-icon" />
                            </TooltipExtra>
                            <h3 className='city-title'>{branch.collectCity}</h3>
                            <label htmlFor={branch.collectStreetAddress} > <p>{branch.collectStreetAddress}, </p>
                                <p>{branch.collectCity}</p>
                                {branch.mailFolder && <p className="mail">{branch.mailFolder}</p>}</label>
                        </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default CollectBranchSelecting
