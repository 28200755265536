import React from 'react';
import closeIconWhite from "../../../../images/closeIconWhite.svg";
import checkIconWhite from "../../../../images/checkIconWhite.svg";

export const AddRecipientPopup = ({ recipientAddedName, setIsAddedRecipientModal, type }) => {

  const addText = () => {
    switch (type) {
      case 'officeDelivery':
        return `הוספנו בהצלחה את המשרד ${recipientAddedName}`
      case 'addressLocate':
      case 'personLocate':
      case 'phoneLocate':
      case 'idLocate':
        return `הוספנו בהצלחה את המאותר ${recipientAddedName}`
      default:
        return `הוספנו בהצלחה את הנמען ${recipientAddedName}`
    }
  }

  return (
    <div className='add-recipient-popup'>
      <div className='add-recipient-popup-content'>

        <div className="check-icon-white">
          <img src={checkIconWhite} alt="close" />
        </div>
        <p>{addText()}</p>
      </div>

      <div className="close-icon-white" onClick={() => setIsAddedRecipientModal(false)}>
        <img src={closeIconWhite} alt="close" />
      </div>
    </div>
  );
};

export default AddRecipientPopup;
