import userService from '../../services/userService'
import { getAnalytics, setUserProperties, setUserId } from "firebase/analytics";


function makeUserProps(user) {
  if (!user) { return {} }
  return {
    uid: user.uid,
    accountType: user.accountType || 'Anonymous',
    isMaster: user.isMaster || false,
    paymentType: user.paymentType || 'REG',
    companyId: user.companyId || ''
  }
}
export function loadUsers() {
  return async dispatch => {
    const user = await userService.getUser()
    dispatch({ type: 'SET_USER', user })
  }
}

export function login(userCreds) {
  const analytics = getAnalytics();
  if (userCreds) {
    setUserId(analytics, userCreds.uid)
    setUserProperties(analytics, makeUserProps(userCreds));
  }

  return async dispatch => {
    dispatch({ type: 'SET_USER', user: userCreds })
  }
}
export function updateUser(userCreds) {
  return async dispatch => {
    dispatch({ type: 'SET_USER', user: userCreds })
  }
}

export function setInvites(invites) {
  return async dispatch => {
    dispatch({ type: 'SET_USER_INVITES', invites })
  }
}

export function setRegisteredUser(userCreds) {
  return async dispatch => {
    dispatch({ type: 'SET_REGISTERED_USER', registeredUser: userCreds })
  }
}



