import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'

export const UrgencyRecipient = ({ data, setData, formState, setFormState }) => {
  const recipients = useSelector(state => state.recipientReducer.Recipients);

  const [alignment, setAlignment] = useState('left');
  const optionA = 'REG';
  const optionB = 'URG';
  const optionC = 'VERY_URG';



  useEffect(() => {
    let { currRecipient } = formState;
    if (!currRecipient?.urgency) {
      setFormState((prevState) => ({
        ...prevState,
        currRecipient: { ...prevState.currRecipient, urgency: 'REG' },
      }));
    }
    if (data.recipients && data.recipients.length > 0 && !data.isOtherTasks) {
      currRecipient = formState.currRecipient ? formState.currRecipient : recipients[recipients.length - 1]
      setData({ ...data, isPersonalOrCompany: currRecipient.type === "ADAM_PRATI" ? "true" : "false" })
      setFormState({ ...formState, currRecipient });
    }
    setAlignment(
      (currRecipient?.urgency === optionB) ? 'left' : (currRecipient?.urgency === optionC ? 'end' : 'right'))
  }, []);

  // useEffect(() => {
  //   const { currRecipient } = formState;
  //   setAlignment(
  //     (currRecipient?.urgency === optionB) ? 'left' : (currRecipient?.urgency === optionC ? 'end' : 'right'))
  // }, [formState.currRecipient]);

  const handleUrgency = (event, newAlignment) => {
    event.preventDefault();
    setAlignment(newAlignment);
    const field = event.target.name;
    const value = event.target.value;
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: { ...prevState.currRecipient, [field]: value },
    }));
  };

  const getTextByRecipient = () => {
    const { currRecipient } = formState;

    if (!currRecipient) return;
    switch (alignment) {
      case 'left':
        return `${currRecipient.type === 'officeDelivery' ? 'ניסיון המצאה ' : 'ביקור ראשון '
          }  תוך ${currRecipient.type === 'officeDelivery' ? 1 : 1
          } ימי עסקים מיום קבלת המסמכים${currRecipient.type === 'officeDelivery' ? '' : ', וכל ביקור נוסף תוך 2 ימי עסקים'}`;
      case 'right':
        return `${currRecipient.type === 'officeDelivery' ? 'ניסיון המצאה ' : 'ביקור ראשון'
        }  תוך ${currRecipient.type === 'officeDelivery' ? 3 : 5
        } ימי עסקים מיום קבלת המסמכים${currRecipient.type === 'officeDelivery' ? '' : ', וכל ביקור נוסף תוך 3 ימי עסקים'}`;
      case 'end':
        return `${currRecipient.type === 'officeDelivery' ? 'ניסיון המצאה ' : ' ביקור ראשון  '
          } ביום קבלת המסמכים${currRecipient.type === 'officeDelivery' ? '' : ', וכל ביקור נוסף תוך 2 ימי עסקים'}`;
      default:
        return '';
    }
  };

  return (
    <div className='main-form form7-urgency'>
      <div className='modal-content-container block'>
        {formState.currRecipient?.type === 'officeDelivery' && <h2 className='large-title'>מה רמת הדחיפות לביצוע ההמצאה?</h2>}
        {!(formState.currRecipient?.type === 'officeDelivery') && <h2 className='large-title'>מה רמת הדחיפות לביצוע המסירה?</h2>}


        <div className={`form-main-toggle toggle-three ${alignment}`}>
          <span></span>
          <button
            type='button'
            name='urgency'
            value={optionA}
            onClick={(e) => handleUrgency(e, 'right')}
          >
            {'רגיל'}
          </button>
          <button
            type='button'
            name='urgency'
            value={optionB}
            onClick={(e) => handleUrgency(e, 'left')}
          >
            {'דחוף'}
          </button>
          <button
            type='button'
            name='urgency'
            value={optionC}
            onClick={(e) => handleUrgency(e, 'end')}
          >
            {'בהול'}
          </button>

        </div>
        <p className='regular'>{getTextByRecipient()}</p>
        {(alignment === 'left' ||
          alignment === 'end') &&
          <p className='regular'>*חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה {alignment === 'end' ? '12:00' : '15:00'} יחל ביום שלמחרת*</p>
        }
      </div>
    </div>
  );
};

export default UrgencyRecipient;
