import { doc, updateDoc, getDoc } from "firebase/firestore";
import db, { Logger } from "../integrations/firebase";
const logClient = new Logger(`orderService`);
async function query(user) {
  const docRef = doc(db, "activeCarts", user.uid);
  const ordersCart = await getDoc(docRef);
  return ordersCart.data().orders;
}

async function deleteOrder(user, deleteOrderId) {
  const docRef = doc(db, "activeCarts", user.uid);
  const ordersCart = await getDoc(docRef);
  const updatedOrders = ordersCart.data().orders.filter((ord) => ord._id !== deleteOrderId);

  try {
    await updateDoc(docRef, { orders: updatedOrders });
  } catch (error) {
    logClient.error('error when deleting form from orders');
    logClient.error(JSON.stringify(updatedOrders));
    logClient.error(JSON.stringify(error));
  }
}

async function _updateOrder(user, updatedOrder, formatedOrder) {
  let updated = {}
  const docRef = doc(db, "activeCarts", user.uid);
  const ordersCart = await getDoc(docRef);
  const { recipients, files } = updatedOrder

  if (recipients.length === 0) {
    delete updatedOrder.recipients
    updatedOrder.isOtherTasks = false
  } else updatedOrder.isOtherTasks = true

  if (files.length === 0) delete updatedOrder.files

  if (formatedOrder.length > 0) updated = { ...updatedOrder, orderActions: formatedOrder }
  else updated = { ...updatedOrder }
  const updatedOrders = ordersCart.data().orders.map((ord) => ord._id === updatedOrder._id ? updated : ord)

  try {
    //Error checking!
    // throw new Error("Something went wrong!");
    await updateDoc(docRef, { orders: updatedOrders });
  } catch (error) {
    logClient.error('error when updating form from orders');
    logClient.error(JSON.stringify(updatedOrders))
    logClient.error(JSON.stringify(error));
    throw error;
  }
}

async function _addOrder(user, newOrder, formatedOrder, type) {
  newOrder._id = newOrder.storageDocId;
  newOrder.userId = user.uid;
  newOrder.type = type;
  const { recipients, files } = newOrder
  if (recipients.length === 0) {
    delete newOrder.recipients
    newOrder.isOtherTasks = false
  }
  else newOrder.isOtherTasks = true
  if (files.length === 0) delete newOrder.files
  const docRef = doc(db, "activeCarts", user.uid);

  const ordersCart = await getDoc(docRef);
  const newOrderWithActions = { ...newOrder, orderActions: [...formatedOrder] }
  if (newOrderWithActions && newOrderWithActions.orderActions.length === 0) delete newOrderWithActions.orderActions
  const updatedOrders = [...ordersCart.data().orders, newOrderWithActions]

  try {
    //Error checking!
    // throw new Error("Something went wrong!");
    await updateDoc(docRef, { orders: updatedOrders });
  } catch (err) {
    logClient.error('error when adding form to orders');
    logClient.error(JSON.stringify(updatedOrders));
    logClient.error(JSON.stringify(err));
    throw err;
  }
}

function saveOrder(user, order, formatedOrder, type) {
  try {
    return order._id ? _updateOrder(user, order, formatedOrder) : _addOrder(user, order, formatedOrder, type);
  } catch (err) {
    console.log('Error while saving order:', err);
    return err;
  }
}

function makeId(length = 10) {
  var txt = "ord";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    txt += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return txt;
}

export const Orderservice = {
  query,
  deleteOrder,
  saveOrder,
  makeId
};

export default Orderservice;
