import React, { useEffect, useState } from 'react';
import { DocumentsReadyUpdate } from './DocumentsReadyUpdate';
import { AddressReturningDocumentsUpdate } from './AddressReturningDocumentsUpdate';
import { ReturnBranchSelecting } from './ReturnBranchSelecting';

export const CertificateReturnMethod = ({ data, setData, type, isError, errors, handleChanges, submitForm, setSubmitForm }) => {
  const [alignment, setAlignment] = useState('right');

  useEffect(() => {
    const { returnMethod, collectionMethod } = data;
    if (collectionMethod === 'branch' && !returnMethod) {
      setData({ ...data, returnMethod: 'branch' });
      setAlignment('left');
    } else if (!returnMethod) {
      setData({ ...data, returnMethod: 'delivery' });
      setAlignment('right');
    } else {
      setAlignment(returnMethod === 'delivery' ? 'right' : 'left');
    }
  }, []);

  const handleChangeData = (event, newAlignment) => {
    event.preventDefault();
    setSubmitForm(false);
    setAlignment(newAlignment);
    const field = event.target.name;
    const value = event.target.value;
    if (value === 'delivery') delete data.returnAddress
    else delete data.returnAddress
    setData({ ...data, [field]: value });
  };

  const getTitleByTypeForm = () => {
    switch (type) {
      case 'apostilSubmission':
        return 'כיצד תעדיפו לקבל חזרה את התעודות לאחר אימותן?';
      case 'apostilInCourt':
        return 'כיצד תעדיפו לקבל חזרה את המסמכים הנוטריונים לאחר אימותם?'
      default:
        return 'כיצד תעדיפו לקבל חזרה את התעודות לאחר אימותן?';
    }
  }

  return (
    <div className='main-form certificate-method'>
      <div className='modal-content-container block'>
        <h2 className='large-title'>{getTitleByTypeForm()}</h2>
        <p className='subtitle'>
          {'החזרה באמצעות שליח או איסוף עצמאי מאחד הסניפים שלנו '}
          <span className='underline'>
            בתיאום מראש
          </span>
        </p>

        <div className={`form-main-toggle ${alignment}`}>
          <span></span>
          <button
            name='returnMethod'
            value='delivery'
            onClick={(ev) => handleChangeData(ev, 'right')}
          >
            החזרה עם שליח
          </button>
          <button
            name='returnMethod'
            value='branch'
            onClick={(ev) => handleChangeData(ev, 'left')}
          >
            איסוף מהסניף
          </button>
        </div>

        <div className='cmp-line' />

        {alignment === 'right' ?
          <>
            {/* <DocumentsReadyUpdate data={data} setData={setData} type={type} /> */}
            {/* <div className='cmp-line' /> */}
            <AddressReturningDocumentsUpdate data={data} setData={setData} isError={isError} errors={errors} handleChanges={handleChanges} type={type} />
          </> :
          alignment === 'left' ? <ReturnBranchSelecting data={data} setData={setData} errors={errors} submitForm={submitForm} setSubmitForm={setSubmitForm} type={type} />
            : <></>}

      </div>
    </div>
  );
};

export default CertificateReturnMethod;
