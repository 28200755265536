import React, { useState, useRef, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UploadFile } from './UploadFile'
import { useReactToPrint } from "react-to-print";
import { Canvas } from '../../Utils/Canvas'
import { toJpeg } from 'html-to-image';
import { useViewport } from '../../../Hooks/useViewport'
import { getStorage, ref, uploadString, deleteObject, updateMetadata } from "firebase/storage";
import { updateErrorModal, setIsLoading } from '../../../store/actions/systemActions';
import { TailSpin } from 'react-loader-spinner'
import { setFiles } from '../../../store/actions/fileActions';
import printer from '../../../images/printer.svg';
import download from '../../../images/download.svg';

export const PowerOfAttorney = ({ setData, handleChanges, errors, submitForm, setSubmitForm, pageName, type, formState }) => {
    const [alignment, setAlignment] = useState('right');
    const data = useSelector((state) => state.dataReducer.data)
    const activeUser = useSelector((state) => state.userReducer.user);
    const dispatch = useDispatch();
    const { width } = useViewport();
    const breakpoint = 1111;
    const [showSign, setShowSign] = useState(false)
    const [trimmedDataURL, setTrimmedDataURL] = useState(null)
    const isLoading = useSelector((state) => state.systemReducer.isLoading)
    const formName = {
        landRegistration: 'לשכת רישום מקרקעין',
        landSupervisor: 'המפקח על המקרקעין'
    }

    useEffect(() => {
        const { isUpload, toApprove, powerOfAttorney, signature } = data.collectFiles[data.currSubmiterIdx];
        if (!isUpload) {
            data.collectFiles[data.currSubmiterIdx].isUpload = false
            data.collectFiles[data.currSubmiterIdx].signature = signature || '';
            data.collectFiles[data.currSubmiterIdx].powerOfAttorney = powerOfAttorney || false;
            data.collectFiles[data.currSubmiterIdx].toApprove = toApprove || false
            setData({ ...data, isEdit: true }) // isEdit=true for isSubmitter true scenario and going prev after it to details and save last recipient
            setAlignment('right');
        } else {
            setAlignment(isUpload === true ? 'left' : 'right');
        }
    }, []);

    useEffect(() => {
        if (submitForm && errors.collectFiles && !data.collectFiles[data.currSubmiterIdx].powerOfAttorney && data.collectFiles[data.currSubmiterIdx].isUpload === false) {
            setSubmitForm(false);
            dispatch(
                updateErrorModal({
                    title: 'שגיאה',
                    errorText: errors.collectFiles[data.currSubmiterIdx]?.powerOfAttorney || '',
                    submitText: 'אישור',
                    isCancel: false
                })
            );
        }
    }, [errors, submitForm, data.collectFiles[data.currSubmiterIdx]]);

    useEffect(() => {
        if ((data.collectFiles && data.collectFiles.length > 0 && !data.isOtherSubmitter && !data.isEdit)) {
            setData({ ...data, currSubmiterIdx: data.collectFiles.length - 1 });
        }
        return () => {
            setData({ ...data, isEdit: true })
        }
    }, [])

    const componentRef = useRef();

    const handleClose = () => {
        setShowSign(false)
    }

    const handleChangeCheckBox = async (event, newAlignment) => {
        event.preventDefault();
        const { value } = event.target
        dispatch(setFiles([]))
        data.collectFiles[data.currSubmiterIdx].signature = value === 'false' ? '' : data.collectFiles[data.currSubmiterIdx].signature;
        data.collectFiles[data.currSubmiterIdx].powerOfAttorney = value === 'false' ? false : data.collectFiles[data.currSubmiterIdx].powerOfAttorney;
        data.collectFiles[data.currSubmiterIdx].isUpload = value === 'true' ? true : false;
        data.collectFiles[data.currSubmiterIdx].toApprove = value === 'false' ? false : data.collectFiles[data.currSubmiterIdx].toApprove;
        setAlignment(newAlignment);
        await removeFiles()
        setData({ ...data });

    };


    const removeFiles = async () => {
        try {
            const storage = getStorage();
            const fileRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/${data.collectFiles[data.currSubmiterIdx]?.name || ''}-${data.collectFiles[data.currSubmiterIdx]?.idNum || ''}-ייפוי כח`);
            await deleteObject(fileRef)
        } catch (error) { }
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    })

    const onDownload = useCallback(() => {
        if (componentRef.current === null) {
            return
        }
        toJpeg(componentRef.current, { cacheBust: true, })
            .then(async (dataUrl) => {
                const link = document.createElement('a')
                link.download = `${data.collectFiles[data.currSubmiterIdx]?.name || ''}-${data.collectFiles[data.currSubmiterIdx]?.idNum || ''}-ייפוי כח.jpeg`;
                link.href = dataUrl
                link.click()
            })
            .catch((err) => {

            })

    }, [componentRef])


    const onApprove = useCallback(() => {
        if (componentRef.current === null) {
            return;
        }

        toJpeg(componentRef.current, { cacheBust: true })
            .then(async (dataUrl) => {
                dispatch(setIsLoading(true));
                const storage = await getStorage();
                const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/${data.collectFiles[data.currSubmiterIdx]?.name || ''}-${data.collectFiles[data.currSubmiterIdx]?.idNum || ''}-ייפוי כח.jpeg`);
                await uploadString(storageRef, dataUrl, 'data_url', { contentType: 'image/jpeg', });
                data.collectFiles[data.currSubmiterIdx] = {
                    ...data.collectFiles[data.currSubmiterIdx],
                    powerOfAttorney: true,
                    powerOfAttorneySign: dataUrl,
                    toApprove: false,
                };
                setData({ ...data });
                const link = document.createElement('a');
                link.download = 'my-image-name.jpeg';
                link.href = dataUrl;
                dispatch(setIsLoading(false));
            })
            .catch((err) => {
                // Handle error
            });
    }, [componentRef]);

    const printOrdownload = () => {
        if (width > breakpoint) {
            handlePrint()
        } else {
            onDownload()
        }
    }

    const Modal = () => {
        const showHideClassName = showSign ? "modal display-block" : "modal display-none";


        return (
            <div className={showHideClassName}>
                <section className="modal-main">
                    <div >
                        <Canvas trimmedDataURL={trimmedDataURL} setTrimmedDataURL={setTrimmedDataURL} handleClose={handleClose} data={data} setData={setData} onApprove={onApprove} />
                    </div>
                </section>
            </div>
        );
    };


    const textTitle = 'הנדון: ייפוי כח'
    const submiterName = data.collectFiles[data.currSubmiterIdx].name
    const submiterId = data.collectFiles[data.currSubmiterIdx].idNum
    const submitterLawyerNumber = data.collectFiles[data.currSubmiterIdx].lawyerNumber


    const files = data.collectFiles[data.currSubmiterIdx].files.map((file) => {
        return <p>תיק מס' {file.fileNum}
            {file.block.length > 0 && <span>, בקשר לגוש: {file.block},</span>}
            {file.lot.length > 0 && <span> חלקה: {file.lot},</span>}
            {file.subLot.length > 0 && <span> תת חלקה: {file.subLot}</span>}
        </p>
    })


    var today = new Date();
    var date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();

    return (
        <div className='main-form notarized-translation action-in-office power-of-attorney' >
            <div className='modal-content-container'>

                <h2 className='large-title'>חתימה על ייפוי כח</h2>
                <p className='subtitle'>
                    בחרו באופן החתימה על ייפוי הכח: באמצעות חתימה דיגיטלית על המסמך שלהלן, או חתימה על עותק מודפס/שהוכן עצמאית והעלתו
                </p>
                <div className={`form-main-toggle ${alignment}`}>
                    <span></span>
                    <button
                        name='isTranslation'
                        value='false'
                        onClick={(ev) => handleChangeCheckBox(ev, 'right')}
                    >
                        חתימה דיגיטלית
                    </button>
                    <button
                        name='isTranslation'
                        value='true'
                        onClick={(ev) => handleChangeCheckBox(ev, 'left')}
                    >
                        הדפסה והעלאה
                    </button>
                </div>

                {
                    alignment === 'left' &&
                    <>
                        <button
                            type='button'
                            className='btn-print'
                            onClick={printOrdownload}
                        >
                            {width > breakpoint ? <img src={printer} alt='printer' /> : <img src={download} alt='download-icon' />}
                            {width > breakpoint ? "הדפסת ייפוי הכח" : "הורדת ייפוי הכח"}

                        </button>

                        <UploadFile
                            type={type}
                            data={data}
                            setData={setData}
                            handleChanges={handleChanges}
                            errors={errors}
                            submitForm={submitForm}
                            setSubmitForm={setSubmitForm}
                            pageName={pageName}
                            formState={formState}
                        />
                    </>
                }


                <>
                    <div className="btns">


                    </div>

                    <div className={`${alignment === 'right' ? "pa-container" : "pa-container hidden"} `}>
                        <div ref={componentRef} style={{ backgroundColor: "white", textAlign: 'right', padding: '50px', fontSize: '21px', direction: 'rtl', lineHeight: '1.3', display: 'flex', flexDirection: 'column' }} >
                            <p className="destination" style={{ lineHeight: '1.4' }}>
                                לכבוד, <br />
                                {formName[type]}<br />
                                {data?.branch}  <br />
                                {data?.branchAddress.fullAddress}  <br />
                            </p>
                            <p style={{ textAlign: "center", margin: "20px 0", lineHeight: '0.9', fontWeight: '600', textDecoration: 'underline', width: '100%' }} className="subject">{textTitle}</p>
                            <p className="content" style={{ lineHeight: '1.7' }}>
                                אני הח״מ, {submiterName}, ת.ז. {submiterId},{submitterLawyerNumber ? ` מ.ר. ${submitterLawyerNumber}, ` : ' '}מייפה את כוחה של חברת דלוברי בע״מ (deLAWvery) וכל אחד מעובדיה ו/או מי מטעמה, לרבות _______ ת.ז. _________, לאסוף בשמי ועבורי את התיקים הבאים  מ{formName[type]}:
                                {files}

                                ייפוי כח זה מוגבל אך ורק לפעולות המפורטות בו.
                            </p>

                            <p className="date" style={{ margin: '30px 0' }}>
                                ולראיה באתי על החתום היום, {date}:
                            </p>
                            {(data?.currSubmiterIdx >= 0 && data?.collectFiles.length > 0 &&
                                data?.collectFiles[data.currSubmiterIdx].signature) &&
                                <img className="sigImage" src={data.collectFiles[data.currSubmiterIdx].signature} alt="signature" style={{ alignSelf: "center", marginLeft: "50px", marginTop: "20px", width: "15%" }} />
                            }
                        </div>
                        <Modal />
                        <div className="btn-cont">
                            <button type="button" className="btn-sign" onClick={() => setShowSign(true)}>לחתימה לחצו כאן</button>
                            {/* {(data?.collectFiles.length > 0 && data?.collectFiles[data.currSubmiterIdx].toApprove && !isLoading) && <button type="button" className="btn-sign approve" onClick={() => onApprove()}> לאישור המסמך</button>} */}

                            {isLoading && <div className="spinner" >
                                <TailSpin
                                    heigth="50"
                                    width="50"
                                    color='#4e71ff'
                                    ariaLabel='loading'
                                />
                            </div>}
                        </div>
                    </div>
                </>


            </div >
        </div >
    );
};

export default PowerOfAttorney;
