
export function TooltipExtra({ type, children }) {

    return (
        <div className="tooltip">
            {children}
            <p className="top">
                {type === 'master' &&
                    <>
                        משתמש מאסטר רואה את
                        <span style={{ 'fontFamily': 'AlmoniBold', 'margin': '0 4px' }}>
                            כל
                        </span>
                        ההזמנות של הארגון, ואילו משתמש שאינו מאסטר רואה רק את ההזמנות של עצמו. משתמש מאסטר יכול להזמין בשם משתמשים אחרים.
                    </>}
                {type === 'branchBeerSheva' &&
                    <>
                        <div className="branch-tooltip">
                            <span className="title-type">
                                כתובת
                            </span>
                            <span>
                                אליהו נאוי 24, באר שבע - מרכז B7 (מול Big) - ״וורקיז מתחמי עבודה״
                            </span>
                        </div>
                        <div className="branch-tooltip">
                            <span className="title-type">
                                שעות פעילות
                            </span>
                            <span>
                                ימים א׳-ה׳ בין השעות 8:00-17:00
                            </span>
                        </div>
                        <div className="branch-tooltip" style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                            <span className="title-type" style={{ 'fontFamily': 'AlmoniBold' }}>
                                נגישות
                            </span>
                            <span>
                                ניתן לגשת עם כיסא גלגלים ועם חיות שירות
                            </span>
                        </div>
                        <div className="branch-tooltip">
                            <span className="title-type">
                                חניה
                            </span>
                            <span>
                                יש חניה במתחם
                            </span>
                        </div>
                        <div className="branch-tooltip">
                            <span className="title-type">
                                שירותים
                            </span>
                            <span>
                                יש שירותים במקום
                            </span>
                        </div>
                        <div className="branch-tooltip">
                            <span className="title">
                                בתיאום טלפוני מראש
                            </span>
                        </div>
                    </>}
                {type === 'branchRishon' &&
                    <>
                        <div className="branch-tooltip">
                            <span className="title-type">
                                כתובת
                            </span>
                            <span>
                                שדרות הראשונים, מגדל מילניה, קומה 17, ראשון לציון
                            </span>
                        </div>
                        <div className="branch-tooltip">
                            <span className="title-type">
                                שעות פעילות
                            </span>
                            <span>
                                ימים א׳-ה׳ בין השעות 8:00-17:00
                            </span>
                        </div>
                        <div className="branch-tooltip">
                            <span className="title-type">
                                נגישות
                            </span>
                            <span>
                                ניתן לגשת עם כיסא גלגלים ועם חיות שירות
                            </span>
                        </div>
                        <div className="branch-tooltip">
                            <span className="title-type">
                                חניה
                            </span>
                            <span>
                                חניה בכחול לבן וחניון היקב במרחק של כ-200 מטר
                            </span>
                        </div>
                        <div className="branch-tooltip">
                            <span className="title-type">
                                שירותים
                            </span>
                            <span>
                                יש שירותים במקום
                            </span>
                        </div>
                        <div className="branch-tooltip">
                            <span className="title">
                                בתיאום טלפוני מראש
                            </span>
                        </div>
                    </>}
            </p>
        </div>
    )
}