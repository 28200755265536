import React from 'react';
import remove from '../../images/remove1.png';
import edit from '../../images/edit.svg';
import { useViewport } from '../../Hooks/useViewport'

export const ClaimRecipientPreview = ({ recipient, onRemoveRecipient, onEditRecipient, type }) => {
  const { width } = useViewport();
  const breakpoint = 1111;
  const getLabelByType = () => {
    let nameLabel = '', addressLabel = '', idLabel = '', genderLabel = '', urgencyLabel = '', phoneLabel = ''
    let nameValue = '', addressValue = '', idValue = '', genderValue = '', urgencyValue = '', phoneValue = ''
    switch (type) {
      case 'courtSubmission':
      case 'officeDelivery':
      case 'personalDelivery':
        nameLabel = recipient.type === 'ADAM_PRATI' ? 'שם מלא' : recipient.type === 'officeDelivery' ? 'שם משרד' : 'שם בית העסק'
        nameValue = recipient?.companyName || recipient?.personName || recipient?.officeName || ''
        idLabel = recipient.type === 'ADAM_PRATI' ? 'תעודת זהות' : recipient.type === 'officeDelivery' ? 'שם עו"ד המטפל בתיק' : 'ח.פ  '
        idValue = recipient?.companyId || recipient?.personId || recipient?.lawyerName || ''
        addressLabel = 'כתובת'
        addressValue = recipient.streetAddress && recipient.city ? `${recipient.streetAddress} ${recipient.streetNumber} , ${recipient.city}${recipient.floor ? `, קומה: ${recipient.floor}` : ''}${recipient.apartmentNumber ? `, דירה: ${recipient.apartmentNumber}` : ''}` : ''
        urgencyLabel = 'דחיפות'
        urgencyValue = recipient.urgency === 'REG'
          ? 'רגיל'
          : recipient.urgency === 'URG'
            ? 'דחוף'
            : 'בהול'
        break;

      case 'addressLocate':
        nameLabel = 'שם מלא'
        nameValue = `${recipient.personName} ${recipient.personLastName}`
        idLabel = 'תעודת זהות'
        idValue = recipient.personId
        genderLabel = 'מין'
        genderValue = recipient.gender === 'female' ? 'נקבה' : !recipient.gender || recipient.gender === 'unset' ? '' : 'זכר'
        phoneLabel = 'טלפון'
        phoneValue = recipient?.personPhone || ''
        break;

      case 'claimant':
        nameLabel = 'שם מלא'
        nameValue = `${recipient.claimantFirstName} ${recipient.claimantLastName}`
        idLabel = 'תעודת זהות'
        idValue = recipient.claimantId
        addressLabel = 'כתובת'
        addressValue = recipient.streetAddress && recipient.city ? `${recipient.streetAddress} ${recipient.streetNumber} , ${recipient.city}${recipient.floor ? `, קומה: ${recipient.floor}` : ''}${recipient.apartmentNumber ? `, דירה: ${recipient.apartmentNumber}` : ''}` : ''
        phoneLabel = 'טלפון'
        phoneValue = recipient?.claimantPhone || ''
        break;

      case 'defendant':
        if (recipient.isPersonalOrCompanyDefendant === 'personal') {
          nameLabel = 'שם מלא'
          nameValue = `${recipient.defendantFirstName} ${recipient.defendantLastName}`
          idLabel = 'תעודת זהות'
          idValue = recipient.defendantId || ''
          if (recipient.isIdLocate) idValue = `יבוצע איתור`
          addressLabel = 'כתובת'
          addressValue = recipient.streetAddress && recipient.city ? `${recipient.streetAddress} ${recipient.streetNumber} , ${recipient.city}${recipient.floor ? `, קומה: ${recipient.floor}` : ''}${recipient.apartmentNumber ? `, דירה: ${recipient.apartmentNumber}` : ''}` : ''
          if (recipient.isAddressLocate) addressValue = `יבוצע איתור`
          phoneLabel = 'טלפון'
          phoneValue = recipient?.defendantPhone || ''
          if (recipient.isPhoneLocate) phoneValue = `יבוצע איתור`
        } else if (recipient.isPersonalOrCompanyDefendant === 'company') {
          nameLabel = 'שם החברה'
          nameValue = `${recipient.defendantCompanyName}`
          idLabel = 'ח.פ.'
          idValue = recipient.defendantId
          addressLabel = 'כתובת'
          addressValue = recipient.streetAddress && recipient.city ? `${recipient.streetAddress} ${recipient.streetNumber} , ${recipient.city}${recipient.floor ? `, קומה: ${recipient.floor}` : ''}${recipient.apartmentNumber ? `, דירה: ${recipient.apartmentNumber}` : ''}` : ''
          phoneLabel = 'טלפון'
          phoneValue = recipient?.defendantPhone || ''
        }
        break;

      default:
        break;
    }
    return {
      name: { nameValue, nameLabel },
      id: { idLabel, idValue },
      address: { addressLabel, addressValue },
      gender: { genderLabel, genderValue },
      urgency: { urgencyValue, urgencyLabel },
      phone: { phoneLabel, phoneValue }
    }
  }

  const { name, id, address, gender, urgency, phone } = getLabelByType()


  return (
    <div className='input-container'>
      {name.nameValue && <div className='field'>
        <label>
          {name.nameLabel}
        </label>
        {width < breakpoint && <span>:</span>}
        <input
          type='text'
          disabled
          value={name.nameValue}
          className='disabled long recipient-preview'
        />
      </div>}
      {id.idLabel && <div className='field'>
        <label>
          {id.idLabel}
        </label>
        {width < breakpoint && <span>:</span>}
        <input
          type='text'
          disabled
          value={id.idValue}
          className='disabled long recipient-preview'
        />
      </div>}
      {address.addressLabel && <div className='field '>
        <label>{address.addressLabel}</label>
        {width < breakpoint && <span>:</span>}
        <input
          type='text'
          disabled
          value={address.addressValue}
          className='disabled long recipient-preview'
        />
      </div>}
      {urgency.urgencyLabel && <div className='field'>
        <label>{urgency.urgencyLabel}</label>
        {width < breakpoint && <span>:</span>}
        <input
          type='text'
          disabled
          value={urgency.urgencyValue}
          className='disabled small recipient-preview'
        />
      </div>}
      {phone.phoneLabel && <div className='field'>
        <label>{phone.phoneLabel}</label>
        {width < breakpoint && <span>:</span>}
        <input
          disabled
          type='text'
          value={phone.phoneValue}
          className='disabled  recipient-preview'
        />
      </div>}

      {gender.genderLabel && <div className='field'>
        <label>{gender.genderLabel}</label>
        {width < breakpoint && <span>:</span>}
        <input
          type='text'
          disabled
          value={gender.genderValue}
          className='disabled small recipient-preview'
        />
      </div>}

      <div className='btn-container'>
        <img
          className='icon-edit'
          src={edit}
          alt='info'
          onClick={(e) => onEditRecipient(recipient, type, e)}
        />
        <img
          src={remove}
          alt='remove'
          onClick={(e) => onRemoveRecipient(recipient.id, type, e)}
        />
      </div>
    </div>
  );
};

export default ClaimRecipientPreview;
