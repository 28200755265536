import OrdersService from "../../services/orderService";
import { formatActions } from "../../components/Forms/formsOrderSubmit";

export function setOrders(orders) {
  return (dispatch) => {
    dispatch({ type: "SET_ORDERS", orders });
  };
}

export function removeOrder(user, orderId) {
  return async (dispatch) => {
    try {
      dispatch({ type: "REMOVE_Order", OrderId: orderId });
      await OrdersService.deleteOrder(user, orderId);
    } catch (err) {
      console.log('%c  err removeOrder:', 'color: white;background: red;', err);
    }
  };
}

export function addOrder(user, data, totalRecipients, type) {
  return async (dispatch) => {
    try {
      let formatedOrder = []
      if (type !== 'apostilSubmission') {
        formatedOrder = formatActions(data, data, totalRecipients)
      }
      // console.log('%c  { ...data, type, orderActions: [...formatedOrder] }:', 'color: white;background: red;', { ...data, type, orderActions: [...formatedOrder] });
      dispatch({ type: "ADD_Order", Orders: { ...data, type, orderActions: [...formatedOrder] } });
      await OrdersService.saveOrder(user, data, formatedOrder, type);
    } catch (err) {
      await OrdersService.deleteOrder(user, data.storageDocId);
      console.log('%c  err addOrder:', 'color: white;background: red;', err);
      throw err
    }
  };
}

export function updateOrder(user, order, totalRecipients, type) {
  return async (dispatch) => {
    try {
      let formatedOrder = []
      if (type !== 'apostilSubmission') {
        formatedOrder = formatActions(order, order, totalRecipients)
      }
      dispatch({ type: "UPDATE_ORDER", order, formatedOrder });
      await OrdersService.saveOrder(user, order, formatedOrder);
    } catch (err) {
      console.log('%c  err updateOrder:', 'color: white;background: red;', err);
      throw err
    }
  };
}

