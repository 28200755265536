import React from 'react'
import { Sidebar } from './Sidebar'
import { UserProfile } from '../../UserDashboard/UserProfile'

export const RightSide = () => {

    return (
        <>
            {!window.location.pathname.includes('user') && <div className="sidebar">
                <Sidebar />
            </div>}
            {window.location.pathname.includes('user') && <div className="sidebar">
                {/* <div> */}
                <UserProfile />
                {/* </div> */}
            </div>}
            {/* <div className="sidebar">
                <Sidebar />
            </div> */}
        </>
    )
}
