import React, { useEffect } from 'react';
import close from '../../images/close.svg';
import ctrlR from '../../images/ctrlR.jpeg';
import { useSelector, useDispatch } from 'react-redux';
import { updateErrorModal } from '../../store/actions/systemActions';

export function PopUpModal() {
  const isPopUpModal = useSelector((state) => state.systemReducer.isPopUpModal);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(updateErrorModal({ errorText: '', status: '' }));
    };
  }, []);

  const getActionByStatus = (status) => {
    switch (status) {
      case 'close_modal':
        return { status: 'submit', isConfrimCloseModal: true }
      case 'delete_recipients':
        return { status: 'submit', isDeleteRecipients: true };
      case 'delete_order':
        return { status: 'submit', isDeleteOrder: true }
      case 'delete_contact':
        return { status: 'submit', isDeleteContact: true }
      case 'change_option':
        return { status: 'submit', isChangeOption: true }
      default:
        return { status: 'submit' };
    }
  }
 
  return (
    <>
      {isPopUpModal && (
        <div className='pop-up-background'>
          <div className={`system-msg`}>
            <div className='msg-content'>
              <h3>{isPopUpModal.title}</h3>
              {isPopUpModal.isFirebaseErrorImage &&
                <img className='ctrl-r' src={ctrlR} alt='ctrlR' />
              }
              <img
                className='btn-close'
                onClick={() => {
                  dispatch(updateErrorModal({ errorText: '', status: 'close' }));
                }}
                src={close}
                alt='close'
              />
            </div>
            <p>{isPopUpModal.errorText}</p>
            <div className='button-container'>
              {isPopUpModal.isCancel && <button
                type='button'
                className='main-btn undo'
                onClick={() => {
                  dispatch(updateErrorModal({ errorText: '', status: 'close' }));
                }}
              >
                ביטול
              </button>}
              <button
                type='button'
                className='main-btn accept'
                onClick={() => {
                  dispatch(updateErrorModal(getActionByStatus(isPopUpModal?.status || '')));
                }}
              >
                {isPopUpModal.submitText}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
