import { Box, styled } from "@mui/system";

const ActionDiv = styled(Box)(({ theme }) => ({
  marginTop: "2em",
  marginBottom: "2em",
  "& h2": {
    font: "0.5rem Almoni",
    fontWeight: "700",
    color: "#373c43",
  },
  "& p": { font: "22px Almoni", opacity: "0.75" },
  // "& h3": { font: "25px Almoni", fontWeight: "500", marginBottom: "0.1em", width: "75%" },
  "& h3": { marginLeft: "15px" },

}));

export { ActionDiv };
