import React, { useState, useEffect, useCallback, useRef } from 'react';
import arrow from '../../../images/arrowdropdown.png';
import { Field } from 'formik';
import { updateErrorModal, updateSystemData, setIsLoading } from '../../../store/actions/systemActions';
import { useSelector, useDispatch } from 'react-redux';
import { AddressCollectDocumentsUpdate } from './AddressCollectDocumentsUpdate';
import { AddressReturningDocumentsUpdate } from './AddressReturningDocumentsUpdate';
import { PersonalOrCompanyUpdate } from './PersonalOrCompanyUpdate';
import { PersonalAddress } from './PersonalAddress';
import { DocumentsReadyUpdate } from './DocumentsReadyUpdate';
import { TextToggle } from './PageUtils/TextToggle';
import { DeliveryPackageSize } from './DeliveryPackageSize';
import { summarizeAction } from '../../../integrations/firebase'

export const DocumentTransferType = ({ data, setData, type, handleChanges, setFormState, formState, submitForm, errors, isError, setSubmitForm, setTouch, touch, scrollTo }) => {

  const [alignment, setAlignment] = useState('right');
  const [isWaiting, setIsWaiting] = useState(false);
  const systemData = useSelector((state) => state.systemReducer.systemData);
  const dispatch = useDispatch();

  const options = [
    { text: 'שליחות ממני לנמען', subText: '(ממני אל)', value: 'fromMe' },
    { text: 'איסוף אלי', subText: '(הביאו אלי)', value: 'toMe' },
    { text: 'העברה בין צדדים', value: 'both' }
  ];

  const typeOfTransferToAlignment = {
    "fromMe": "right",
    "toMe": "left",
    "both": "end"
  }

  useEffect(() => {
    setData({
      isDoubleTransfer: data?.isDoubleTransfer || false,
      isSign: data?.isSign || false,
      collectionMethod: 'pickup',
      returnMethod: 'delivery',
      typeOfTransfer: data?.typeOfTransfer || 'fromMe',
      ...data,
    })
    if (data.typeOfTransfer) {
      setAlignment(typeOfTransferToAlignment[data.typeOfTransfer]);
    }
  }, []);

  useEffect(() => {
    dispatch(setIsLoading(isWaiting));
  }, [isWaiting]);

  useEffect(async () => {
    if (isWaiting) return
    if ((data?.collectionAddress?.collectCity && data?.collectionAddress?.collectStreetAddress && data?.collectionAddress?.collectStreetNumber) &&
      (data?.returnAddress?.returnCity && data?.returnAddress?.returnStreetAddress && data?.returnAddress?.returnStreetNumber)) {
      setIsWaiting(true)
      const res = await summarizeAction({ data, type: 'checkSpecialPrice' });
      dispatch(updateSystemData({
        ...systemData,
        isSpecialUrgencyPrice: res.data
      }));
      setIsWaiting(false)
    }
  }, [data]);

  useEffect(() => {
    if (submitForm && errors.typeOfTransfer) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: errors.typeOfTransfer,
          submitText: 'אישור',
          isCancel: false
        })
      );
    } else if (submitForm && isWaiting) {
      setSubmitForm(false);
    }
  }, [data.branch, errors, submitForm]);


  // const handleChangeCheckbox = (ev) => {
  //   const { value, name } = ev.target;
  //   setData({
  //     ...data,
  //     [name]: value === 'false' ? true : false,
  //   });
  // };

  const handleChangeToggle = (event, newAlignment) => {
    const { value, name } = event.target;
    setAlignment(newAlignment);
    setData({
      ...data,
      returnAddress: {},
      collectionAddress: {},
      [name]: value
    });
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: {
      },
    }));
  };

  const getTypeOfTransferCmps = () => {
    switch (data.typeOfTransfer) {
      case 'fromMe':
        return <>
          <AddressCollectDocumentsUpdate data={data} setData={setData} isError={isError} errors={errors} handleChanges={handleChanges} type={type} setTouch={setTouch} touch={touch} />
          <div className='cmp-line' />
          <DocumentsReadyUpdate data={data} setData={setData} type={type} />
          <PersonalOrCompanyUpdate data={data} setData={setData} setFormState={setFormState} formState={formState} isError={isError}
            errors={errors} handleChanges={handleChanges} setTouch={setTouch} touch={touch} scrollTo={scrollTo} />

        </>
      case 'toMe':
        return <>
          <PersonalOrCompanyUpdate data={data} setData={setData} setFormState={setFormState} formState={formState} isError={isError}
            errors={errors} handleChanges={handleChanges} setTouch={setTouch} touch={touch} scrollTo={scrollTo} />
          <DocumentsReadyUpdate data={data} setData={setData} type={type} />
          <div className='cmp-line' />
          <AddressReturningDocumentsUpdate data={data} setData={setData} isError={isError} errors={errors} handleChanges={handleChanges} type={type} />
        </>
      case 'both':
        return <>
          <PersonalOrCompanyUpdate data={data} setData={setData} setFormState={setFormState} formState={formState} isError={isError}
            errors={errors} handleChanges={handleChanges} setTouch={setTouch} touch={touch} scrollTo={scrollTo} />
          <DocumentsReadyUpdate data={data} setData={setData} type={type} />
          <div className='cmp-line' />
          <PersonalAddress data={data} setData={setData} isError={isError} errors={errors} handleChanges={handleChanges} setTouch={setTouch} touch={touch} type={type} />
        </>

      default:
        return <></>
    }
  }

  return (
    <div className='main-form document-transfer-type'>
      <div className='modal-content-container block'>
        <h2 className='large-title'>
          בין מי למי השליחות?
        </h2>
        <div className='toggles'>
          <div className={`form-main-toggle toggle-three xx-large ${alignment}`}>
            <span></span>
            <button
              name='typeOfTransfer'
              value={options[0].value}
              onClick={(ev) => handleChangeToggle(ev, 'right')}
              type='button'
            >
              {options[0].text}
              <br />
              {options[0].subText}
            </button>
            <button
              name='typeOfTransfer'
              value={options[1].value}
              type='button'
              onClick={(ev) => handleChangeToggle(ev, 'left')}
            >
              {options[1].text}
              <br />
              {options[1].subText}
            </button>
            <button
              name='typeOfTransfer'
              value={options[2].value}
              type='button'
              onClick={(ev) => handleChangeToggle(ev, 'end')}
            >
              {options[2].text}
            </button>
          </div>
        </div>

        <div className='recip-details-container'>
          {getTypeOfTransferCmps()}

          <TextToggle data={data} setData={setData} formParam={'isSign'} isError={isError}
            errors={errors} />
          <div className='cmp-line' />
          <TextToggle data={data} setData={setData} formParam={'isDoubleTransfer'} />
          <div className='cmp-line' />
        </div>
        < DeliveryPackageSize data={data} setData={setData} type={type} isError={isError} errors={errors} />
        <div className='document-transfer-type-container'>

          {/* <div className='transfer-type'
            onClick={() => {
              if (!isMobile) scrollTo('down)
            }}
          >
            <div
              ref={deliveryOptionsRef}
              className={
                isDropdownOpen ? 'dropdown-container open' : 'dropdown-container'
              }
            >
              <div className='dropdown-input' onClick={() => {
                setIsDropdownOpen(!isDropdownOpen);
              }}>
                <div
                  className='document-transfer-text'
                >
                  {createDeliveryAddonsString()}
                </div>
                <button
                  type='button'
                >
                  <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                </button>
              </div>

              <div className='dropdown-list'>
                {deliveryOptions.map((option, idx) => {
                  return (
                    <div
                      key={idx}
                      className='dropdown-option'
                    >
                      <Field
                        id={option.value + idx}
                        type='checkbox'
                        name={option.value}
                        value={data?.[option.value] || false}
                        onChange={handleChangeCheckbox}
                        checked={data?.[option.value] || false}
                      />
                      <label htmlFor={option.value + idx}>{option.text}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div> */}

        </div>

      </div>
    </div>
  );
};

export default DocumentTransferType;
