import React, { useState, useEffect } from 'react'
import SearchIcon from "../../../images/Search Icon.svg";
import { useDispatch } from 'react-redux';
import { updateErrorModal } from '../../../store/actions/systemActions';
import { Tooltip } from '../../Utils/Tooltip'
import info from '../../../images/info.svg'
import infoWhite from '../../../images/infoWhite.svg'
import { cityOptionsClaimTypes } from '../../../consts/data'
import { useViewport } from '../../../Hooks/useViewport'


export const ChooseClaimType = ({ data, setData, type, submitForm, errors, setSubmitForm }) => {
    const dispatch = useDispatch();
    const { width } = useViewport();
    const [options, setOptions] = useState([]);
    const breakpoint = 767;
    const isMobile = width < breakpoint;
    
    useEffect(() => {
        setOptions(cityOptionsClaimTypes)
        setData({
            ...data,
            claimants: data?.claimants ? data?.claimants : [],
            defendants: data?.defendants ? data?.defendants : [],
            claimDescription: data?.claimDescription ? data?.claimDescription : {},
        });
    }, [])

    useEffect(() => {
        if (submitForm && errors.claimType) {
            setSubmitForm(false);
            dispatch(
                updateErrorModal({
                    title: 'שגיאה',
                    errorText: errors.claimType,
                    submitText: 'אישור',
                    isCancel: false
                })
            );
        }
    }, [data.claimType, errors, submitForm]);

    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const searchResults = options.filter((choice) =>
        choice.claimType.includes(searchTerm)
    )

    const handleClaimTypeSelected = (claim) => {
        setData({
            ...data,
            claimType: claim.claimType,
            // branchAddress: {
            //     formattedAddress: branch.address,
            //     comments: branch.comments,
            //     floor: branch.floor,
            //     streetNumber: branch.streetNumber,
            //     streetAddress: branch.streetAddress,
            //     city: branch.city,
            //     fullAddress: branch.fullAddress,
            // },
            // branchFullAddress: branch.fullAddress
        });
    }
    return (
        <div className='main-form  claim-selecting'>
            <div className='modal-content-container '>
                <h2 className='large-title'>בחירת נושא התביעה</h2>

                <div className="search-field">
                    <img src={SearchIcon} alt="SearchIcon" />
                    <input
                        type="text"
                        className="search-input"
                        placeholder=""
                        value={searchTerm}
                        onChange={handleChange}
                    />
                </div>

                <div className="claims list">
                    {searchResults.map((claim, idx) => {
                        return (<div key={idx} className={`claim ${data?.claimType && data?.claimType === claim.claimType ? 'blue' : 'white'}`} onClick={() => handleClaimTypeSelected(claim)}>
                            <input
                                type="radio"
                                id={claim.claimType}
                                name="claimType"
                                value={claim.claimType}
                                defaultChecked={data?.claimType && (data?.claimType === claim.claimType)}
                            />

                            {/* <div className={`tooltip-container ${claim.claimType === 'ספק לקוח' ? 'left-tooltip' : ''}`}>
                                {claim.tooltip && <Tooltip text={claim.tooltip} link={claim.link}>
                                    <img src={!isMobile && data?.claimType && data?.claimType === claim.claimType ? infoWhite : info} width={20} alt="info-icon" className="info-icon" />
                                </Tooltip>}
                            </div> */}

                            <span className="checkmark"></span>
                            <label htmlFor={claim.claimType} >
                                <p>{claim.claimType}</p>
                            </label>
                            <img className='claim-icon' src={!isMobile && data?.claimType && data?.claimType === claim.claimType ? claim.whiteImage : claim.image} alt={`${claim.altImage}`} />
                        </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
