import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { updateSystemData } from '../../store/actions/systemActions';
import { LoginOptions } from '../Login/steps/LoginOptions'
import { SignupOptions } from './steps/SignupOptions'
import { SignupDetails } from './steps/SignupDetails'
import { OfficeDetails } from './steps/OfficeDetails'
import { ResetPasswordSent } from './steps/ResetPasswordSent'
import { Login } from './steps/Login'
import { EmailResetPassword } from './steps/EmailResetPassword'
import { SetPassword } from './steps/SetPassword'
import { VerifiedEmailPopup } from './steps/VerifiedEmailPopup';
import { FirstLogin } from './steps/FirstLogin';
import { BlockedUser } from './steps/BlockedUser';

export const LoginSingup = ({ resetPassword, verifiedEmail, oobCode, signIn, signUpCode }) => {
    const dispatch = useDispatch();
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const isDelawverySignup = JSON.parse(sessionStorage.getItem('isDelawverySignup'));

    useEffect(() => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: systemData?.currLoginData?.currentStep || 'loginOptions' }, signUpCode: signUpCode || systemData.signUpCode
        }));
        return () => {
            dispatch(updateSystemData({
                ...systemData, currLoginData: { currentStep: 'loginOptions' }, signUpCode: signUpCode || systemData.signUpCode
            }));
        }
    }, [])
    console.log(signUpCode)
    const loginStepMap = {
        loginOptions: <LoginOptions />,
        signupOptions: <SignupOptions hideLoginButton={signUpCode ? true : false} />,
        signupDetails: <SignupDetails />,
        officeDetails: <OfficeDetails />,
        login: <Login />,
        resetPassword: <EmailResetPassword />,
        resetPasswordSent: <ResetPasswordSent />,
        setPassowrd: <SetPassword oobCode={oobCode} />,
        VerifiedEmailPopup: <VerifiedEmailPopup />,
        firstLogin: <FirstLogin />,
        blockedUser: <BlockedUser />,
    }

    return (
        <div className={`pop-up-login ${isDelawverySignup && systemData.isOpenSlowly ? 'slowly' : ''}`}>
            <div className="container">
                {signIn ? loginStepMap['firstLogin'] :
                    (resetPassword || verifiedEmail) ?
                        resetPassword ?
                            loginStepMap['setPassowrd'] : loginStepMap['VerifiedEmailPopup']
                        : signUpCode ? loginStepMap['signupOptions'] :
                            loginStepMap[systemData.currLoginData?.currentStep]}
            </div>
        </div>
    )
};


