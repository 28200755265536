import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from "firebase/remote-config";
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, initializeFirestore } from "firebase/firestore";
import { getPerformance, trace } from "firebase/performance";
import { getAuth } from "firebase/auth";
import { Logger } from '@firebase/logger';
import { getAnalytics, setUserProperties, logEvent, setUserId } from "firebase/analytics";

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  databaseURL: process.env.REACT_APP_DBURL,
  apiKey: process.env.REACT_APP_FIREBASE_API,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);
// remoteConfig.settings.minimumFetchIntervalMillis = 30000;
const analytics = getAnalytics();
remoteConfig.defaultConfig = {
  "welcome_message": "Welcome"
};

const db = initializeFirestore(app, { experimentalAutoDetectLongPolling: true, ignoreUndefinedProperties: true });
const auth = getAuth()
auth.languageCode = "he"

const functions = getFunctions();
const perf = getPerformance();

const summarizeAction = httpsCallable(functions, 'summarizeAction')
const updateUserMonday = httpsCallable(functions, 'updateUserMonday')
const createPayment = httpsCallable(functions, 'payments-createPayment')
const createUser = httpsCallable(functions, 'users-createUserAndCustomer')
const createUserUnderCustomer = httpsCallable(functions, 'users-createUserUnderCustomer')
const callUserAction = httpsCallable(functions, 'users-callUserAction')
const generatePdfAction = httpsCallable(functions, 'userActions-generatePdfAction')
const generateExcelAction = httpsCallable(functions, 'userActions-generateExcelAction')
const getNumberOfPagesFromFile = httpsCallable(functions, 'userActions-getNumberOfPagesFromFile')
const onCallNotifications = httpsCallable(functions, 'notifications-onCallNotifications')
const actionCodeSettings = {
  url: process.env.REACT_APP_URL,
  handleCodeInApp: true,
};
export { functions };
export { httpsCallable };
export { summarizeAction };
export { createPayment };
export { createUser };
export { createUserUnderCustomer };
export { callUserAction };
export { generatePdfAction };
export { generateExcelAction };
export { getNumberOfPagesFromFile };
export { onCallNotifications };
export { db };
export { auth };
export { actionCodeSettings }
export { updateUserMonday }
export { Logger }
export { remoteConfig }
export { trace, perf }
export { analytics, logEvent, setUserProperties, setUserId }
export default getFirestore()
