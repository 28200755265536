const INITIAL_STATE = {
  company: null,
  companyUsers: [],
}

export function companyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_COMPANY':
      return {
        ...state,
        company: action.company
      }
    case 'SET_COMPANY_USERS':
      return {
        ...state,
        companyUsers: action.companyUsers
      }
    default:
      return state
  }
}