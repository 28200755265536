import React, { useEffect, useState, useRef } from 'react';
import { Field } from 'formik';
import arrow from '../../images/dropdown-arrow.svg';

export const ClaimStatement = ({ data, setData, handleChangeData, isError, errors }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const option1 = 'כל הסעיפים'
  const option2 = '1-פרטי התובע/ת'
  const option3 = '2-פרטי הנתבע/ת'
  const option4 = '3.א-3.ה-תיאור התביעה'
  const option5 = '3.ו-נימוקי התביעה'
  const [options, setOptions] = useState([
    { text: option1, optionType: 'all', isChecked: data?.claimDescription?.statementSectionA === option1 ? true : false },
    { text: option2, optionType: 'claimantDetails', isChecked: data?.claimDescription?.statementSectionA?.includes(option2) || data?.claimDescription?.statementSectionA === option1 ? true : false },
    { text: option3, optionType: 'defendantDetails', isChecked: data?.claimDescription?.statementSectionA?.includes(option3) || data?.claimDescription?.statementSectionA === option1 ? true : false },
    { text: option4, optionType: 'description', isChecked: data?.claimDescription?.statementSectionA?.includes(option4) || data?.claimDescription?.statementSectionA === option1 ? true : false },
    { text: option5, optionType: 'reasons', isChecked: data?.claimDescription?.statementSectionA?.includes(option5) || data?.claimDescription?.statementSectionA === option1 ? true : false },
  ]);
  const dropdownRef = useRef()
  useEffect(() => {
    let statementStr = ''
    let statementStrB = ''
    const isStatementAll = checkIfAllTrue()

    const statementArr = []
    const statementArrB = []

    if (options[0].isChecked) statementStr = option1
    else if (options.some(option => option.isChecked)) {
      options.forEach(option => {
        if (option.isChecked) {
          statementArr.push(option.text)
        } else if (option.optionType !== 'all') {
          statementArrB.push(option.text)
        }
      })
    }
    statementStr = statementStr.length ? statementStr : statementArr.join(', ')
    statementStrB = statementArrB.join(', ')
    setData({
      ...data,
      claimDescription: {
        ...data.claimDescription,
        statementSectionA: statementStr,
        statementSectionB: statementStrB,
        statementSectionSource: options[0].isChecked || isStatementAll ? '' : data?.claimDescription?.statementSectionSource,
        isStatementAll
      },
    });
  }, [options]);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (dropdownRef?.current?.contains(event.target)) {
        return;
      }
      setIsDropdownOpen(false)
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });
    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);

  const handleChangeCheckbox = (option, idx) => {
    const changeTo = !option.isChecked
    if (option.optionType === 'all') {
      const updatedOptions = []
      options.forEach(option => {
        updatedOptions.push({ ...option, isChecked: changeTo })
      })
      setOptions(updatedOptions)
      setIsDropdownOpen(false)
      return
    }

    const updatedOption = {
      ...option,
      isChecked: !option.isChecked
    }
    const updatedOptions = [...options]
    updatedOptions[idx] = updatedOption
    if (option.optionType !== 'all' && !changeTo) {
      updatedOptions[0] = { text: option1, optionType: 'all', isChecked: false }
    }
    setOptions(updatedOptions)
  };

  const checkIfAllTrue = () => {
    const isAllTrue = options.every(option => {
      if (option.optionType === 'all') return true
      else return option.isChecked
    })
    return isAllTrue
  }

  return (
    <div className='input-container statement-container'>
      <h4>3ג. הצהרה:</h4>
      <div className='statementA-container'>
        <p className='details center'>1. אני מצהיר/ה שהעובדות המופיעות בסעיפים</p>
        <div
          ref={dropdownRef}
          className={
            isDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
          }
        >
          <div className='dropdown-input' onClick={() => {
            setIsDropdownOpen(!isDropdownOpen);
          }}>
            <div
              className={`statement-text ${isError('statementSectionA')
                ? 'error'
                : data?.claimDescription?.statementSectionA
                  ? 'success'
                  : ''}`}
            >
              {data?.claimDescription?.statementSectionA ? data?.claimDescription?.statementSectionA : 'בחרו את הסעיפים שבידיעתכם האישית'}
            </div>
            <button
              type='button'
            >
              <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
            </button>
          </div>
          {isError('statementSectionA') && <p className='error-label err-details'>{errors.statementSectionA}</p>}

          <div className='dropdown-list'>
            {options.map((option, idx) => {
              return (
                <div
                  key={idx}
                  className='dropdown-option'
                >
                  <Field
                    id={'statementSectionA' + idx}
                    type='checkbox'
                    name='statementSectionA'
                    value={option.text}
                    onChange={() => { handleChangeCheckbox(option, idx) }}
                    checked={option.isChecked}
                  />
                  <label htmlFor={'statementSectionA' + idx}>{option.text}</label>
                </div>
              );
            })}
          </div>
        </div>

        <p className='details center'>שבכתב התביעה הן בידיעותי האישית.</p>
      </div>

      <div className='input-container'>
        <p className='details'>2. אני מצהיר/ה שהעובדות המופיעות בסעיפים</p>
        <div className='field'>
          <Field
            type='text'
            name='statementSectionB'
            autoComplete="new-password"
            value={data?.claimDescription?.statementSectionB || ''}
            disabled={true}
            className='disabled'
          // className={`${isError('statementSectionB')
          //   ? 'error'
          //   : data?.claimDescription?.statementSectionB
          //     ? 'success'
          //     : ''} disabled`
          // }
          />
          {/* {isError('statementSection') && <p className='error-label err-details'>{errors.statementSection}</p>} */}
        </div>
        <p className='details'>שבכתב התביעה הן נכונות לפי מיטב ידיעתי ואמונתי. </p>
      </div>

      <div className='input-container source-container'>
        <p className='details'>המקור לידיעתי ואמונתי הוא:</p>
        <div className='field'>
          <Field
            type='text'
            name='statementSectionSource'
            autoComplete="new-password"
            placeHolder={options[0].isChecked || checkIfAllTrue() ? '' : 'תארו מה הוא המקור לידיעתכם ואמונתכם'}
            value={data?.claimDescription?.statementSectionSource || ''}
            onChange={handleChangeData}
            disabled={options[0].isChecked || checkIfAllTrue()}
            className={`${options[0].isChecked || checkIfAllTrue() ? 'disabled' : ''}
              ${isError('statementSectionSource')
                ? 'error'
                : data?.claimDescription?.statementSectionSource
                  ? 'success'
                  : ''}`
            }
          />
          {isError('statementSectionSource') && <p className='error-label err-details'>{errors.statementSectionSource}</p>}
        </div>
      </div>

    </div>
  );
};

export default ClaimStatement;
