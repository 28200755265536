export function updateSystemData(systemData) {
    return (dispatch) => {
        dispatch({ type: "UPDATE_DATA_SYSTEM_DATA", systemData });
    };
}
export function setIsLoading(isLoading) {
    return (dispatch) => {
        dispatch({ type: "SET_IS_LOADING", isLoading });
    };
}

export function updateErrorModal(isPopUpModal) {
    return async (dispatch) => {
        dispatch({ type: "UPDATE_SYSTEM__POPUP_MODAL", isPopUpModal: (isPopUpModal === 'submit' || isPopUpModal === 'close') ? null : isPopUpModal });
    };
}

export function updateContactToSave(contactToSave) {
    return (dispatch) => {
        dispatch({ type: "UPDATE_CONTACT_TO_SAVE", contactToSave });
    };
}
export function updateSecondContactToSave(secondContactToSave) {
    return (dispatch) => {
        dispatch({ type: "UPDATE_SECOND_CONTACT_TO_SAVE", secondContactToSave });
    };
}

export function setMobileState(mobile) {
    return (dispatch) => {
        dispatch({ type: "SET_MOBILE", mobile });
    };
}
