import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateEmail, sendPasswordResetEmail } from "firebase/auth"
import { Field, Formik } from 'formik'
import { auth } from '../../integrations/firebase'
import { doc, updateDoc } from "@firebase/firestore";
import db, { updateUserMonday, Logger } from '../../integrations/firebase'
import { validationPersonalDetailsPage } from './validations'
import { updateUser } from '../../store/actions/userActions'
import { UpdateEmailPopup } from './UpdateEmailPopup'
// if (values.email !== activeUser.email) {
//     await updateEmail(auth.currentUser, values.email)
//     await sendPasswordResetEmail(auth, values.email)
//     await updateDoc(userRef, { email: values.email }, { merge: true });
// }
const logClient = new Logger(`UserDetails`);

export const UserDetails = () => {
    const activeUser = useSelector((state) => state.userReducer.user);
    const dispatch = useDispatch();
    const phoneRegex = /^\d{9,10}$/;
    const [passwordMsg, setPasswordMsg] = useState('')
    const [detailsMsg, setDetailsMsg] = useState('')
    const [emailOfUser, setEmailOfUser] = useState(activeUser.email)
    const [showEmailPopup, setShowEmailPopup] = useState(false)
    const [providerId, setProviderId] = useState(null)

    useEffect(() => {
        const firstProvider = activeUser?.providerData?.[0];
        const providerId = firstProvider?.providerId ?? null;
        setProviderId(providerId);
    }, [activeUser]);


    useEffect(() => {
        setEmailOfUser(activeUser.email)
    }, [activeUser.email])


    const onResetPassword = async () => {
        try {
            await sendPasswordResetEmail(auth, activeUser.email)
            setPasswordMsg('ברגעים אלה נשלח לכתובת המייל שלך קישור להחלפת סיסמה.')
        }
        catch ({ message }) {
            console.log('%c  err:', 'color: white;background: red;', message);
        }
    }

    const onSubmit = async (values) => {
        const userRef = doc(db, "users", activeUser.uid);
        const updatedUser = {
            ...activeUser,
            phoneNumber: values.phoneNumber,
            fullName: values.firstName + ' ' + values.lastName,
            firstName: values.firstName || '',
            lastName: values.lastName || '',
            lawyerNumber: values.lawyerNumber,
            isLawyer: values?.lawyerNumber?.length > 0 ? true : activeUser.isLawyer
        }
        // console.log('%c  updatedUser:', 'color: white;background: red;', updatedUser);

        try {
            dispatch(updateUser({ ...updatedUser }));
            setDetailsMsg('הפרטים עודכנו בהצלחה')
            await updateDoc(userRef, updatedUser, { merge: true }).then((r) => { }).catch((e) => {
                logClient.error('error when updating user data');
                logClient.error(JSON.stringify(updatedUser))
                logClient.error(JSON.stringify(e));
            });
            // console.log('%c  { itemId: updatedUser.mondayId, user: updatedUser }:', 'color: white;background: red;', { itemId: updatedUser.mondayId, user: updatedUser });
        }
        catch ({ message }) {
            console.log('%c  error:', 'color: white;background: red;', message);
        }
    }

    return (
        <Formik
            onSubmit={onSubmit}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            initialValues={{
                firstName: activeUser?.fullName?.split(' ')[0] || '',
                lastName: activeUser?.fullName?.split(' ').slice(1).join(' ') || '',
                phoneNumber: activeUser?.phoneNumber || '',
                lawyerNumber: activeUser?.lawyerNumber || '',
                email: activeUser?.email || '',
            }}
            enableReinitialize={true}
            validationSchema={validationPersonalDetailsPage}
        >
            {(props) => <form onSubmit={props.handleSubmit} >
                <div className='main-checkout user-details'>
                    <div className='content'>
                        <h3 className='title'>הפרטים שלי </h3>
                        <div className="main-input-container" style={{ gap: '20px' }}>
                            <div className="input-container">
                                <label>שם פרטי*</label>
                                <Field
                                    onChange={(e) => { props.handleChange(e) }}
                                    className={props.values.firstName.length > 0 ? 'success name-input' : props.touched.firstName && Boolean(props.errors.firstName) ? 'error name-input' : 'name-input'}
                                    type='text'
                                    name="firstName"
                                    value={props.values.firstName}
                                />
                                {/* <p> {(props.touched.firstName && props.errors.firstName) || null}</p> */}
                            </div>
                            <div className="input-container">
                                <label>שם משפחה*</label>
                                <Field
                                    autoComplete="new-password"
                                    onChange={(e) => { props.handleChange(e) }}
                                    className={props.values.lastName.length > 0 ? 'success name-input' : props.touched.lastName && Boolean(props.errors.lastName) ? 'error name-input' : 'name-input'}
                                    type='text'
                                    name="lastName"
                                    value={props.values.lastName}
                                />
                                {/* <p> {(props.touched.lastName && props.errors.lastName) || null}</p> */}
                            </div>
                            <div className="input-container">
                                <div className="license">
                                    <label>מס' רישיון</label>
                                    <p>*עורכי דין בלבד</p>
                                </div>
                                <Field
                                    onChange={(e) => { props.handleChange(e) }}
                                    className={props.values.lawyerNumber.length > 0 ? 'success name-input' : props.touched.lawyerNumber && Boolean(props.errors.lawyerNumber) ? 'error name-input' : 'name-input'}
                                    type='text'
                                    name="lawyerNumber"
                                    value={props.values.lawyerNumber}
                                />
                            </div>
                            <div className="input-container">
                                <div className='field'>
                                    <label>מספר פלאפון*</label>
                                    <div className='icon-input'>
                                        <Field
                                            className={phoneRegex.test(props.values.phoneNumber) ? 'success name-input' : props.touched.phoneNumber && Boolean(props.errors.phoneNumber) ? 'error name-input' : 'name-input'}
                                            name='phoneNumber'
                                            value={props.values.phoneNumber}
                                            onChange={(e) => { props.handleChange(e) }}
                                            type="text" />
                                        <div className="icon">972+</div>
                                    </div>
                                    {/* <p> {(props.touched.invoicePhoneNumber && props.errors.invoicePhoneNumber) || null}</p> */}
                                </div>
                            </div>
                        </div>
                        <button type="submit" className="main-btn long">עדכון פרטים</button>
                        <p>{detailsMsg}</p>
                        <div className='email-change-container'>
                            <h3 className="btms-titles">כתובת אימייל</h3>
                            <div className="input-container">
                               
                                <Field
                                    disabled
                                    className={'disabled'}
                                    onChange={(e) => { setEmailOfUser(e.target.value) }}
                                    type='text'
                                    name="email"
                                    value={emailOfUser}
                                />
                                {providerId === 'password' &&
                                    <button
                                        type="button"
                                        className="main-btn long update-email-btn"
                                        onClick={() => { setShowEmailPopup(true); }}>
                                        עדכון כתובת אימייל
                                    </button>
                                }
                            </div>
                        </div>

                        <h3 className="btms-titles"> החלפת סיסמה</h3>
                        <div className="btn-container">
                            <button type="button" className="btn" onClick={onResetPassword}>לחצו להחלפת סיסמה</button>
                            {/* <button type="button" className="btn">לחצו לעדכון דוא"ל</button> */}
                        </div>
                        <p>
                            {passwordMsg}
                        </p>
                        {passwordMsg && <p>
                            לא קיבלת את המייל? לחץ כאן <span onClick={onResetPassword}>לשליחה חוזרת</span>
                        </p>}
                    </div>
                </div>
                {showEmailPopup && providerId === 'password' && <UpdateEmailPopup setShowEmailPopup={setShowEmailPopup} />}
            </form>}
        </Formik>
    )
}
