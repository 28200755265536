import React, { useEffect, useState } from 'react';
import { utilService } from '../../services/utilService'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { updateErrorModal } from '../../store/actions/systemActions';

export const UserMsgItem = ({ notification, onClickNotification, isNotificationsList, isFromDash }) => {
    const date = utilService.formatDate(notification.date).split(' ')
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [isActionCanceled, setIsActionCanceled] = useState(false)
    const orders = useSelector((state) => state.userDataReducer.userOrders)
    const activeUser = useSelector((state) => state.userReducer.user);

    useEffect(() => {
        const allActionsIds = orders.flatMap((order) => order.actionData.map((action) => action?.id || null));
        if (allActionsIds.some((id) => id === notification.actionId)) setIsActionCanceled(false);
        else setIsActionCanceled(true);
    }, [orders, notification.actionId]);


    const onOpenActionPage = (actionId) => {
        if (isActionCanceled) {
            dispatch(
                updateErrorModal({
                    title: 'שגיאה',
                    errorText: 'לא ניתן להציג את ההזמנה מכיוון שהיא בוטלה. למידע נוסף פנו אלינו בצאט שבאתר',
                    submitText: 'אישור',
                    isCancel: false
                })
            );
        }
        else navigate(`/user/orders/${actionId}`)
    }

    return (
        <div className={`user-msg-item ${notification?.userInteractions?.[activeUser.uid]?.isClicked ? '' : 'not-clicked'} ${isNotificationsList ? 'action-info' : ''} 
        ${(isFromDash && notification.actionId) ? 'clickable' : ''}`}
            onClick={() => {
                onClickNotification(notification)
                if (notification?.actionId) onOpenActionPage(notification.actionId)
            }}
        >
            <div className="title">
                <p className='order-notification-title'>{notification.header}</p>
                <div className='date-title'>
                    <span className="time">{date[0]}</span>
                    <span className="date">{date[1]}</span>
                </div>
            </div>
            {notification.orderId &&
                <div className="order-num">
                    <p className='order-notification-title'>
                        מס' הזמנה: {`#${notification.orderId}`}
                    </p>
                </div>
            }
            {/* <div className="order-num"></div> */}
            <div className="description">
                {notification.body?.map((msg, idx) => <p className="description" key={idx}> {msg}</p>)}
            </div>
            {/* {!isNotificationsList && (notification.type === 'order' || notification.type === 'orderStatus' || notification.type === 'fileUploaded') && notification?.actionId && <div className="to-order"><span onClick={() => { onOpenActionPage(notification.actionId) }}>מעבר להזמנה</span></div>} */}
            {/* {isFromDash && notification?.actionId && <div className="to-order"><span onClick={() => { onOpenActionPage(notification.actionId) }}>מעבר להזמנה</span></div>} */}
            {/* {(notification.type === 'accountPaymentStatus' || notification.type === 'newAccountDeal') && <div className="to-order">מעבר לתשלום</div>} */}
            {/* {(notification.type === 'newAccountReceipt') && <div className="to-order">מעבר לחשבונית</div>} */}
        </div >
    )
}
