import React from "react";
import closeIconWhite from "../../../images/closeIconWhite.svg";



const EmergencyMsgs = ({ setIsShowEmergencyMsgs }) => {
    return (
        <header className="header-container main-layout emergency-msgs">
            <p  className="">
                יום שלישי הקרוב ה- 27.2 הוא יום שבתון (בחירות לרשויות המקומיות). חברתנו לא עובדת ביום זה ולכן לא יבוצעו שליחויות ולא יהיה מענה בשירות הלקוחות. נתראה ברביעי 😉
            </p>
            <div className="close-icon" onClick={() => setIsShowEmergencyMsgs(false)}>
                <img src={closeIconWhite} alt="close" />
            </div>
        </header>
    );
};

export default EmergencyMsgs;
