import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { doc, getDoc, updateDoc, onSnapshot } from "@firebase/firestore";
import db, { Logger, callUserAction } from '../../../integrations/firebase'
import plus from '../../../images/plus.svg';
import minus from '../../../images/minus.svg';
import { useViewport } from '../../../Hooks/useViewport'
import { TailSpin } from 'react-loader-spinner'
import { CreateUser } from './CreateUser'
import { useNavigate } from 'react-router-dom';
import { MasterToggle } from './MasterToggle'
import { UserStatusTextBtn } from './UserStatusTextBtn'
import { UserBlockBtn } from './UserBlockBtn'
import { TooltipExtra } from '../../Utils/TooltipExtra'
import { setCompanyUsers } from '../../../store/actions/companyActions';
import info from '../../../images/info.svg'
import arrow from '../../../images/dropdown-arrow.svg';
import SearchIcon from "../../../images/Search Icon.svg";

export const UsersOfCompany = ({ company }) => {
    // console.log('UsersOfCompany')
    const activeUser = useSelector((state) => state.userReducer.user);
    const isLoading = useSelector((state) => state.systemReducer.isLoading)
    const [searchTerm, setSearchTerm] = useState('');
    // const companyUsers = useSelector((state) => state.companyReducer.companyUsers);

    // const companyUsers = useSelector((state) =>
    //     state.companyReducer.companyUsers.filter((user) =>
    //         user.fullName.includes(searchTerm)
    //     )
    // );
    const allCompanyUsers = useSelector((state) => state.companyReducer.companyUsers);
    const companyUsers = allCompanyUsers.filter((user) =>
        user.fullName.includes(searchTerm) || user.email.includes(searchTerm)
    );
    
    const dispatch = useDispatch();
    const [isUsersLoading, setisUsersLoading] = useState(false)
    const [isUserPopupOpen, setIsUserPopupOpen] = useState(false)
    // const [isUserDonePopupOpen, setIsUserDonePopupOpen] = useState(false)
    const [masterLoading, setMasterLoading] = useState({ isLoading: false, idxLoading: null })
    const [sendDetailsLoading, setSendDetailsLoading] = useState(null)
    const [activityLoading, setActivityLoading] = useState({ isLoading: false, idxLoading: null })
    const logClient = new Logger(`UsersOfCompany`);
    const { width } = useViewport();
    const breakpoint = 1111;
    const isMobile = width < breakpoint;

    useEffect(() => {
        if (!companyUsers || !allCompanyUsers.length) setisUsersLoading(true)
        else setisUsersLoading(false)
    }, [companyUsers]);

    const onChangeMaster = async (user, isUserMaster, idx) => {
        if (user.userActivity === 'not-connected' || user.userActivity === 'blocked') return
        else if (activityLoading.isLoading || masterLoading.isLoading || isLoading) return
        else if (user.uid === user.companyId || user.uid === activeUser.uid) return
        const isMasterUpdate = !isUserMaster
        const userRef = doc(db, "users", user.uid);
        const updatedUser = {
            ...user,
            isMaster: isMasterUpdate,
        }
        const updatedMasters = isMasterUpdate ? [...company.masters, user.uid || ''] : company.masters.filter(uid => uid !== user.uid)
        try {
            setMasterLoading({ isLoading: true, idxLoading: idx })
            Promise.all([
                updateDoc(userRef, updatedUser, { merge: true }).then((r) => { }).catch((e) => {
                    logClient.error('error when updating user data');
                    logClient.error(JSON.stringify(updatedUser))
                    logClient.error(JSON.stringify(e));
                }),
                callUserAction({
                    companyId: activeUser.companyId,
                    masters: updatedMasters,
                    type: "setCustomerInfo"
                })
            ])
            const updatedUsers = [...companyUsers]
            updatedUsers[idx] = updatedUser
            dispatch(setCompanyUsers([...updatedUsers]));
            setMasterLoading({ isLoading: false, idxLoading: 0 })
        }
        catch ({ message }) {
            console.log('%c  error:', 'color: white;background: red;', message);
        }
    }

    const changeActivityForAwaitingUser = async (user, activityChange, idx) => {
        const isDisabled = !user?.isDisabled
        const userToSendBlock = {
            uid: user.uid,
            isDisabled,
            isAwaiting: true,
            companyId: company.managerId
        }
        await callUserAction({
            ...userToSendBlock,
            type: "setUserBlock"
        })
    }

    const onChangeActivity = async (user, activityChange, idx) => {
        if (activityLoading.isLoading || masterLoading.isLoading || isLoading) return
        if (user.uid === user.companyId) return
        if (user.isMaster) return

        if (user.userActivity === 'not-connected') {
            try {
                setActivityLoading({ isLoading: true, idxLoading: idx })
                await changeActivityForAwaitingUser(user, activityChange, idx)
                setActivityLoading({ isLoading: false, idxLoading: 0 })
                // return
            }
            catch ({ message }) {
                console.log('%c  error:', 'color: white;background: red;', message);
            }
            finally {
                return
            }
        }

        const userRef = doc(db, "users", user.uid);
        const updatedUser = {
            ...user,
            userActivity: activityChange
        }
        try {
            setActivityLoading({ isLoading: true, idxLoading: idx })
            await updateDoc(userRef, updatedUser, { merge: true }).then((r) => { }).catch((e) => {
                logClient.error('error when updating user data');
                logClient.error(JSON.stringify(updatedUser))
                logClient.error(JSON.stringify(e));
            });
            const updatedUsers = [...companyUsers]
            updatedUsers[idx] = updatedUser
            dispatch(setCompanyUsers([...updatedUsers]));
            setActivityLoading({ isLoading: false, idxLoading: 0 })
        }
        catch ({ message }) {
            console.log('%c  error:', 'color: white;background: red;', message);
        }

    }

    const handleAlignment = (user) => {
        if (user?.isMaster)
            return 'left'
        return 'right'
    }

    const onSendLoginDetails = async (user, idx) => {
        const userMondayId = company.mondayUsersIds[user.uid]
        try {
            if (sendDetailsLoading) return
            setSendDetailsLoading({ isSendingDetails: true, idxLoading: idx })
            await callUserAction({
                mondayId: userMondayId,
                email: user.email,
                type: "sendLoginDetails"
            })
            setSendDetailsLoading({ isSendDetails: true, idxLoading: idx })
            setTimeout(() => {
                setSendDetailsLoading(null)
            }, 2000);
        }
        catch ({ message }) {
            console.log('%c  error:', message);
        }
    }

    //Mobile
    const onOpenUser = (user, idx) => {
        const updatedUsers = [...companyUsers]
        updatedUsers[idx] = { ...user, isOpen: !user.isOpen }
        dispatch(setCompanyUsers([...updatedUsers]));
    }

    const sortByName = () => {
        // const users = [...companyUsers]
        // users.sort((a, b) => (a.fullName > b.fullName) ? 1 : -1);
        // dispatch(setCompanyUsers([...users]));
    }

    const handleChangeSearch = (ev) => {
        const search = ev.target.value
        setSearchTerm(search);
    };


    return (
        <div className='users-of-company-container'>
            <h3 className='user-company-title'>משתמשים</h3>

            <div className="search-field">
                <input
                    type="text"
                    className="search-input"
                    placeholder="חיפוש משתמש"
                    value={searchTerm}
                    onChange={handleChangeSearch}
                />
                <img src={SearchIcon} alt="SearchIcon" />
            </div>

            <div className='add-user-container'>
                <div>
                    <button className='btn-add' type='button' onClick={() => { setIsUserPopupOpen(true) }}>
                        +
                    </button>
                </div>
                <p>
                    הוספת משתמש חדש
                </p>

            </div>

            {!isMobile ?
                <table className='users-table'>
                    <tr className='users-table-header'>
                        <td className='is-master-td'>
                            <TooltipExtra type={'master'}>
                                <img src={info} alt="info-icon" className="info-icon" />
                            </TooltipExtra>
                            <h4 className='users-table-title'>
                                משתמש מאסטר
                            </h4>
                        </td>
                        <td className='name-td'>
                            <h4 className='users-table-title'>
                                {/* <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' /> */}
                                <span onClick={sortByName}>
                                    שם מלא
                                </span>
                            </h4>
                        </td>
                        <td className='user-name-td'>
                            <h4 className='users-table-title'>
                                אימייל משתמש
                            </h4>
                        </td>
                        <td className='user-status-td'>
                            <h4 className='users-table-title'>
                                סטאטוס
                            </h4>
                        </td>
                        <td className='user-block-td'>
                            <h4 className='users-table-title'>
                                חסימה
                            </h4>
                        </td>
                    </tr>

                    {companyUsers.map((user, idx) => {
                        return <tr key={user.uid || idx} className='user-details'>
                            <td className='is-master-td'>
                                <MasterToggle user={user} idx={idx} masterLoading={masterLoading} onChangeMaster={onChangeMaster} handleAlignment={handleAlignment} />
                            </td>
                            <td className='name-td'>
                                <p>
                                    {user.fullName || user.officeOrCompanyName || ''}
                                </p>
                            </td>
                            <td className='user-name-td'>
                                <p>
                                    {user.email || ''}
                                </p>
                            </td>
                            <td className='user-status-td'>
                                <UserStatusTextBtn user={user} idx={idx} sendDetailsLoading={sendDetailsLoading} onSendLoginDetails={onSendLoginDetails} />
                            </td>
                            <td className='user-block-td'>
                                <UserBlockBtn user={user} idx={idx} activityLoading={activityLoading} onChangeActivity={onChangeActivity} />
                            </td>
                        </tr>
                    })}
                </table>
                :
                <div className='users-cards'>
                    {companyUsers.map((user, idx) => {
                        return <div className={`user-card ${user.isOpen ? 'open-user-card' : ''}`} >
                            <div className='user-card-header'>
                                <h4 className='name'>{user.fullName || user.officeOrCompanyName || ''}</h4>
                                <img src={user.isOpen ? minus : plus} alt="plus" onClick={() => { onOpenUser(user, idx) }} />
                            </div>
                            <div className='user-card-details'>
                                <p className='user-details-p'>
                                    {user.email || ''}
                                </p>
                                <div className='user-card-action'>
                                    <div className='master-toggle'>
                                        <h4 className='type-title'>
                                            משתמש מאסטר
                                        </h4>
                                        <MasterToggle masterLoading={masterLoading} idx={idx} handleAlignment={handleAlignment} user={user} onChangeMaster={onChangeMaster} />
                                    </div>
                                    <div className='user-status'>
                                        <h4 className='type-title'>
                                            סטאטוס
                                        </h4>
                                        <UserStatusTextBtn sendDetailsLoading={sendDetailsLoading} idx={idx} onSendLoginDetails={onSendLoginDetails} user={user} />
                                    </div>
                                    <div className='user-block'>
                                        <h4 className='type-title'>
                                            חסימה
                                        </h4>
                                        <UserBlockBtn user={user} idx={idx} activityLoading={activityLoading} onChangeActivity={onChangeActivity} />
                                    </div>

                                </div>
                            </div>
                        </div>
                    })
                    }
                </div>
            }

            {(isUsersLoading && allCompanyUsers.length === 0) ?
                <div className='users-spinner'>
                    <TailSpin
                        heigth="50"
                        width="50"
                        color='#4e71ff'
                        ariaLabel='loading'
                    />
                </div>
                :
                ((allCompanyUsers.length !== 0) && (companyUsers.length === 0) ?
                    <h4 className='users-alert-title'>לא נמצאו משתמשים</h4>
                    :
                    <></>
                )
            }



            {isUserPopupOpen &&
                <CreateUser setIsUserPopupOpen={setIsUserPopupOpen} company={company} />
            }
        </div>
    )
}
