import React from 'react';
import { Outlet } from 'react-router';
import { useViewport } from '../../Hooks/useViewport'
import { useSelector, useDispatch } from 'react-redux'

import { UserMsgs } from '../UserDashboard/UserMsgs'
import { UserMain } from '../UserDashboard/UserMain'
import { UserProfile } from '../UserDashboard/UserProfile';
import db, { trace, perf } from '../../integrations/firebase';


export default function Users() {
  const t = trace(perf, "AllActions");
  t.start();
  const { width } = useViewport();
  const breakpoint = 1111;
  const notifications = useSelector((state) => state.userDataReducer.userNotifications);
  const returnValue = (
    <div className="users">

      {(width < breakpoint) && <UserProfile />}
      {(width > breakpoint) &&
        <div className="user-dash">
          {/* <UserMsgs actionNotifications={notifications} /> */}
          {/* MOVED DOWN FOR DEPLOY*/}
          <UserMain />
        </div>
      }
      {(width < breakpoint) && <Outlet />}
    </div>
  );
  t.stop();
  return returnValue
}
