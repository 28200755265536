import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { updateSystemData } from '../../../store/actions/systemActions';
import { setRegisteredUser, login } from '../../../store/actions/userActions';
import arrow from '../../../images/arrowdropdown.png';
import secure from "../../../images/secure.svg"
import { Field, Formik } from 'formik';
import { validationSignupDetails } from '../validations'
import { doc, setDoc } from "@firebase/firestore";
import db, { Logger } from '../../../integrations/firebase'
import { sendEmailVerification } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { createUser } from '../../../integrations/firebase';
import { ReactComponent as Close } from '../../../images/close.svg'
import { ReactComponent as Back } from '../../../images/back-small.svg'
import { auth, actionCodeSettings } from '../../../integrations/firebase'
import { TailSpin } from 'react-loader-spinner';
import { setIsLoading } from '../../../store/actions/systemActions'
const logClient = new Logger(`SignupDetails`);

export const SignupDetails = () => {
    const navigate = useNavigate();
    const ref = useRef()
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.systemReducer.isLoading)
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [touched, setTouched] = useState({});
    const registeredUser = useSelector((state) => state.userReducer.registeredUser);
    const activeUser = useSelector((state) => state.userReducer.user);
    const utm = useSelector((state) => state.utmReducer);
    const [isLawyer, setIsLicense] = useState(registeredUser?.isLawyer || false)
    const [accountType, setAccountType] = useState(registeredUser?.accountType || '');
    const phoneRegex = /^\d{9,10}$/;
    const [values, setValues] = useState({
        firstName: registeredUser?.fullName?.split(' ')[0] || registeredUser?.displayName?.split(' ')[0] || '',
        lastName: registeredUser?.fullName?.split(' ').slice(1).join(' ') || registeredUser?.displayName?.split(' ').slice(1).join(' ') || '',
        email: activeUser?.email || registeredUser?.email || '',
        phoneNumber: activeUser?.phoneNumber || registeredUser?.phoneNumber || '',
        lawyerNumber: registeredUser?.lawyerNumber || '',
        isTerms: registeredUser?.isTerms || false,
    })

    useEffect(() => {
        const onBodyClick = (event) => {
            if (ref.current.contains(event.target)) {
                return;
            }
            setIsDropdownOpen(false);
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });

        return () => {
            document.body.removeEventListener("click", onBodyClick, {
                capture: true,
            });
        };
    }, []);

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handleBlur = (e) => {
        setTouched({ ...touched, [e.target.name]: true })
    }

    const handleBack = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'signupOptions' }
        }));
    }

    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };

    const options = [
        { text: 'חשבון פרטי', isPrivate: true },
        { text: 'חשבון חברה / מחלקה משפטית', isPrivate: false },
        { text: 'משרד עורכי דין', isPrivate: false }
    ];

    const handleAccountType = (option) => {
        setAccountType(option.text)
        setIsDropdownOpen(false)
    }

    const handleIsLayer = (e) => {
        setIsLicense(!isLawyer)
        setValues({ ...values, lawyerNumber: '' })
    }
    const handleIsTerms = (e) => {
        setValues({ ...values, isTerms: !values.isTerms })
    }



    const onSubmit = async (values) => {
        // console.log('onSubmit')
        if (isSubmitted) return
        setIsSubmitted(true)
        const user = {
            email: activeUser.email,
            fullName: values.firstName + ' ' + values.lastName,
            firstName: values.firstName || '',
            lastName: values.lastName || '',
            phoneNumber: values.phoneNumber,
            accountType,
            isLawyer,
            lawyerNumber: values.lawyerNumber,
            isTerms: values.isTerms || registeredUser?.isTerms || false,
            addresses: [],
            discountedActions: [],
            discountPercentage: 0,
            pricelessActions: [],
            isCaseNeeded: false,
            isAuthNeeded: false,
            mondayOrderIds: [],
            notifications: [],
            masters: [(auth.currentUser?.uid || activeUser.uid)],
            signUpCode: systemData.signUpCode || null,
            utm
        }
        dispatch(setRegisteredUser({
            ...registeredUser,
            ...user
        }))
        if (accountType === "חשבון פרטי") {
            dispatch(setIsLoading(true))
            dispatch(setRegisteredUser(null))
            if (activeUser?.officeOrCompanyName || activeUser?.officeOrCompanyId || activeUser?.officeBookkeepingEmail) {
                delete activeUser.officeOrCompanyName
                delete activeUser.officeOrCompanyId
                delete activeUser.officeBookkeepingEmail
            }
            const userRef = doc(db, "users", activeUser.uid);
            await setDoc(userRef, user).then((r) => { }).catch((e) => {
                logClient.error('error when updating user data');
                logClient.error(JSON.stringify(user))
                logClient.error(JSON.stringify(e));
            });
            await createUser({})
            dispatch(login({ ...activeUser, ...user }))
            if (!auth.currentUser.emailVerified) {
                sendEmailVerification(auth.currentUser, actionCodeSettings).then(() => { });
            }
            dispatch(setIsLoading(false))

            navigate('/welcome')
            window.location.reload()
        }
        else {
            dispatch(updateSystemData({
                ...systemData, currLoginData: { currentStep: 'officeDetails' }
            }));
        }
    }

    return <>
        <div className="header-btns">
            <button type="button" className="back-btn" onClick={handleBack}>
                <p>חזרה</p>
                <Back />
            </button>
            <Close onClick={setModalClose} />
        </div>
        <div className="content">

            <div className="login-step">
                <Formik
                    onSubmit={onSubmit}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    initialValues={{
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        phoneNumber: values.phoneNumber,
                        accountType: accountType,
                        lawyerNumber: values.lawyerNumber,
                        isTerms: values.isTerms,
                    }}
                    enableReinitialize={true}
                    validationSchema={validationSignupDetails}
                >
                    {(props) => {
                        return (
                            <form onSubmit={props.handleSubmit}>
                                <h2 className="main-title">פתיחת חשבון חדש</h2>

                                <div className='field'>
                                    <label>
                                        שם פרטי*
                                    </label>
                                    <Field
                                        className={(touched.firstName || props.touched.firstName) && Boolean(props.errors.firstName) ? 'signup-input error' :
                                            props.values.firstName.length > 0 ? 'signup-input success' : 'signup-input'}
                                        type='text'
                                        name='firstName'
                                        onChange={handleChange}
                                        value={values.firstName}
                                        onBlur={handleBlur}
                                    />
                                    {/* {((touched.firstName || props.touched.firstName) && Boolean(props.errors.firstName)) && <p>{props.errors.firstName}</p>} */}
                                </div>
                                <div className='field'>
                                    <label>
                                        שם משפחה*
                                    </label>
                                    <Field
                                        className={(touched.lastName || props.touched.lastName) && Boolean(props.errors.lastName) ? 'signup-input error' :
                                            props.values.lastName.length > 0 ? 'signup-input success' : 'signup-input'}
                                        type='text'
                                        name='lastName'
                                        onChange={handleChange}
                                        value={values.lastName}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                <div className='field'>
                                    <label>
                                        מספר פלאפון*
                                    </label>
                                    <Field
                                        type='number'
                                        className={(touched.phoneNumber || props.touched.phoneNumber) && Boolean(props.errors.phoneNumber) ? 'signup-input error' :
                                            phoneRegex.test(props.values.phoneNumber) ? 'signup-input success' : 'signup-input'}
                                        name='phoneNumber'
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                        onBlur={handleBlur}
                                    />
                                </div>

                                <div className='field'>
                                    <label htmlFor="account">סוג חשבון*</label>
                                    <div
                                        className={
                                            isDropdownOpen ? 'dropdown-container open ' : 'dropdown-container'
                                        }
                                        ref={ref}
                                    >
                                        <div className='dropdown-input'
                                            onClick={() => {
                                                setIsDropdownOpen(!isDropdownOpen);
                                            }}>
                                            <div>
                                                {accountType ? accountType : <p
                                                    className={(touched.accountType || props.touched.accountType) && Boolean(props.errors.accountType) ? 'dropdown-text error' :
                                                        props.values.accountType.length > 0 ? 'dropdown-text success' : 'dropdown-text'}>בחר מתוך הרשימה...</p>}
                                            </div>
                                            <button
                                                type='button'
                                            >
                                                <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                                            </button>
                                        </div>

                                        <div className='dropdown-list'>
                                            {options.map((option, idx) => {
                                                return (
                                                    <div
                                                        className='dropdown-option'
                                                        onClick={(event) => handleAccountType(option)}
                                                    >
                                                        <div>{option.text}</div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="checkbox-container">
                                        <input type="checkbox" id="isLawyer" name="isLawyer" checked={isLawyer} onChange={() => handleIsLayer()} />
                                        <label htmlFor='isLawyer'>
                                            <span>האם יש ברשותך רישיון לעריכת דין?</span>
                                        </label>
                                        <span className="checkmark"></span>
                                    </label>

                                    {isLawyer && <div className='field'>
                                        <label>
                                            מס' הרישיון
                                        </label>
                                        <Field
                                            className={(touched.lawyerNumber || props.touched.lawyerNumber) && Boolean(props.errors.lawyerNumber) ? 'signup-input error' :
                                                props.values.lawyerNumber.length > 0 ? 'signup-input success' : 'signup-input'}
                                            type='number'
                                            name='lawyerNumber'
                                            onChange={handleChange}
                                            value={values.lawyerNumber}
                                            onBlur={handleBlur}
                                        />
                                    </div>}
                                </div>

                                <label className="checkbox-container">
                                    <Field type="checkbox" id="isTerms" name="isTerms" checked={props.values.isTerms} onChange={() => handleIsTerms()} />
                                    <label htmlFor='isTerms' className={(props.touched.isTerms && props.errors.isTerms) ? 'red-terms' : ''}>
                                        אני מסכימ/ה
                                        <span>
                                            <a className={(props.touched.isTerms && props.errors.isTerms) ? 'red-terms' : ''} href='https://delawvery.co.il/terms-of-use/' rel='noreferrer' target="_blank"> לתנאי השימוש </a>
                                            ואת
                                            <a className={(props.touched.isTerms && props.errors.isTerms) ? 'red-terms' : ''} href='https://delawvery.co.il/privacy-policy/' rel='noreferrer' target="_blank"> מדיניות הפרטיות </a>
                                        </span>
                                    </label>
                                    <span className="checkmark"></span>

                                </label>

                                <button
                                    type='submit'
                                    className='main-btn long register center'
                                    onClick={() => setTouched({ phoneNumber: true, email: true, firstName: true, lastName: true, accountType: true, isTerms: true })}>
                                    {(accountType === 'משרד עורכי דין' || accountType === 'חשבון חברה / מחלקה משפטית') ?
                                        'לשלב הבא' : isLoading ? <TailSpin
                                            heigth="50"
                                            width="50"
                                            color='#ffffff'
                                            ariaLabel='loading'
                                        /> : 'סיום הרשמה'}
                                </button>
                            </form>
                        )
                    }}
                </Formik>
                <div className="security-note">
                    <div>
                        <img src={secure} alt="secure" />
                        <p>המידע שלכם שמור בבטחה</p>
                    </div>
                    <p>רכישה מאובטחת בתקן PCI DSS</p>
                </div>
            </div >
        </div>
    </>
};


