import React from 'react'
import { useViewport } from '../../Hooks/useViewport'


export const UserActionTab = ({ tab, onToggleTab, action }) => {
    const { width } = useViewport();
    const breakpoint = 1111;

    return (
        <div className='action-tab'>
            <p className={`${tab === 'summary' ? 'active' : ''}`} onClick={() => { onToggleTab('summary') }}>סיכום הזמנה</p>
            {(width < breakpoint) &&
                <p className={`${tab === 'notifications' ? 'active' : ''}`} onClick={() => { onToggleTab('notifications') }}>הודעות</p>
            }
            {/* MOVED DOWN FOR DEPLOY*/}
            <p className={`doc ${tab === 'doc' ? 'active' : ''}`} onClick={() => { onToggleTab('doc') }}>מסמכים אישורים ותעודות</p>
        </div>

    )
}
