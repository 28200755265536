import { useSelector } from 'react-redux';
import db, { trace, perf} from '../../integrations/firebase';

export const Screen = () => {
    const t = trace(perf, "AllActions");
    t.start();
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const mobile = useSelector((state) => state.mobileReducer.mobileState);


    const className = () => {
        if (systemData.isFormModalOpen) {
            return "screen open"
        } else if (mobile.isMenuOpen) {
            return "screen open no-header"
        } else {
            return "screen"
        }
    }

    const returnValue = (
        <div className={className()}>
        </div>
    )
    t.stop()
    return returnValue
}
