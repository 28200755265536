

export function setCompany(companyCreds) {
  return async dispatch => {
    dispatch({ type: 'SET_COMPANY', company: companyCreds })
  }
}

export function setCompanyUsers(companyUsers) {
  return async dispatch => {
    dispatch({ type: 'SET_COMPANY_USERS', companyUsers })
  }
}


