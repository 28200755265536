import React, { useEffect, useState, useRef } from 'react';
import arrow from '../../../images/dropdown-arrow.svg';
import info from '../../../images/info.svg'
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { utilService } from '../../../services/utilService'
import { claimantSet } from '../../../store/actions/smallClaimActions';
import { updateErrorModal } from '../../../store/actions/systemActions';
import { CustomTooltip } from '../../Utils/CustomTooltip'
import { useApostropheSubstitution } from '../../../Hooks/useApostropheSubstitution'
import cities2 from "../../../consts/cities";
import streetRes2 from "../../../consts/streets";

export const SmallClaimClaimant = ({ data, setData, isError, errors, handleChanges, submitForm, setTouch, touch, setSubmitForm }) => {

  const currClaimant = useSelector((state) => state.smallClaimReducer.currClaimant);

  const [searchCity, setSearchCity] = useState('');
  const [searchStreet, setSearchStreet] = useApostropheSubstitution('');
  const [isCitiesDropdownOpen, setIsCitiesDropdownOpen] = useState(false);
  const [isStreetsDropdownOpen, setIsStreetsDropdownOpen] = useState(false);
  const [israelCities, setIsraelCities] = useState([])
  const [cityStreets, setCityStreets] = useState([])

  const refCity = useRef()
  const refStreet = useRef()
  const activeUser = useSelector((state) => state.userReducer.user);
  const systemData = useSelector((state) => state.systemReducer.systemData);
  const dispatch = useDispatch();

  const phoneRegex = /^\d{9,10}$/;
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  // TextBody = "https://services.israelpost.co.il/zip_data.nsf/SearchZip?OpenAgent&Location=" & city & "&POB=&Street=" & street & "&House=" & House
  // TextBody = "https://services.israelpost.co.il/zip_data.nsf/SearchZip?OpenAgent&Location=באר שבע&POB=&Street=אלישר&House=36"
  // RES88467836
  // 8467836

  const [claimantState, setClaimantState] = useState({
    id: utilService.makeId(),
    claimantFirstName: '',
    claimantLastName: '',
    claimantId: '',
    claimantPhone: '',
    claimantEmail: '',
    city: '',
    streetAddress: '',
    streetNumber: '',
    floor: '',
    apartmentNumber: '',
    postalCode: '',
    isAddressNotFound: false,
    type: 'smallClaim'
  });

  useEffect(() => {
    if (currClaimant?.id) {
      setClaimantState({ ...currClaimant })
    }
  }, []);

  useEffect(() => {
    dispatch(claimantSet(claimantState))
  }, [claimantState]);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (refCity?.current?.contains(event.target) || refStreet?.current?.contains(event.target)) {
        return;
      }
      setIsCitiesDropdownOpen(false);
      setIsStreetsDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    setSearchCity(currClaimant?.city || '')
    setSearchStreet(currClaimant?.streetAddress || '')

    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);


  useEffect(() => {
    if (isCitiesDropdownOpen) {
      getCities()
    } else {
      setIsraelCities([])
    }
    if (isStreetsDropdownOpen && claimantState?.city) {
      getStreetsOfCity(searchCity)
    } else {
      setCityStreets([])
    }
  }, [isCitiesDropdownOpen, isStreetsDropdownOpen]);

  const onBlur = () => {
    if (claimantState?.city !== searchCity) {
      setSearchCity(claimantState?.city ? claimantState?.city : '')
    }
    if (claimantState?.streetAddress !== searchStreet) {
      setSearchStreet(claimantState?.streetAddress ? claimantState?.streetAddress : '')
    }

    setIsCitiesDropdownOpen(false)
    setIsStreetsDropdownOpen(false)
  }

  const getCities = async () => {
    try {
      const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`, { signal: AbortSignal.timeout(5000) })
      const cities = await cityRes.json()
      const citiesAfterSwitch = switchBrackets(cities.result.records)
      setIsraelCities(citiesAfterSwitch)
    } catch (err) {
      const cities2 = require("../../../consts/cities")
      const citiesAfterSwitch2 = switchBrackets(cities2.result.records)
      setIsraelCities(citiesAfterSwitch2)
      console.log('Fetch error: ', err)
    }
  }

  const switchBrackets = (cities) => {
    const switchBracketsMap = {
      '(': ")",
      ')': "(",
    };

    return cities.map(city => {
      return {
        'שם_ישוב': city['שם_ישוב'].trim().replace(/\(|\)/gi, function (matched) {
          return switchBracketsMap[matched];
        })
      }
    })
  }

  const getStreetsOfCity = async (cityName) => {
    try {
      const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${cityName}`, { signal: AbortSignal.timeout(5000) })
      const StreetsOfCity = await streetRes.json()
      setCityStreets(StreetsOfCity.result.records)
    } catch (err) {
      const streetRes2 = require("../../../consts/streets")
      setCityStreets(streetRes2.result.records.filter((rec) => rec['שם_ישוב'] == cityName))
      console.log('Fetch error: ', err)
    }
  }

  const onSetChoose = (field, value) => {
    setClaimantState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setTouch({ ...touch, [field]: true });
  }

  const handleIsAddressNotFound = () => {
    setClaimantState((prevState) => ({
      ...prevState,
      isAddressNotFound: !prevState.isAddressNotFound,
    }))
  }

  const addClaimant = () => {
    const claimantsUpdated = [...data.claimants]
    if (claimantsUpdated.length) {
      const idxToUpdate = claimantsUpdated.findIndex((claimant) => claimant.id === claimantState.id)
      if (idxToUpdate === -1 && Object.keys(errors).length === 0) {
        setData({
          ...data,
          claimants: [...claimantsUpdated, claimantState]
        });
      } else {
        claimantsUpdated[idxToUpdate] = claimantState
        if (Object.keys(errors).length === 0 && claimantState.claimantId && idxToUpdate > -1) {
          setData({
            ...data,
            claimants: [...claimantsUpdated]
          });
        }
      }
    } else {
      setData({
        ...data,
        claimants: [...data.claimants, claimantState]
      });
    }
  }

  const onAddNewClaimant = () => {
    if (Object.keys(errors).length !== 0
      || !claimantState.claimantFirstName || !claimantState.claimantLastName
      || !claimantState.claimantId || !claimantState.claimantPhone
      || !claimantState.claimantEmail || !claimantState.postalCode
      || !claimantState.streetAddress || !claimantState.city
      || !claimantState.streetNumber) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'חובה למלא את כל הפרטים החסרים לפני הוספת תובע/ת נוספ/ת',
          submitText: 'אישור',
          isCancel: false
        })
      );
    }
    else {
      addClaimant()
      setClaimantState((prevState) => ({
        ...prevState,
        id: utilService.makeId(),
        claimantFirstName: '',
        claimantLastName: '',
        claimantId: '',
        claimantPhone: '',
        claimantEmail: '',
        city: '',
        streetAddress: '',
        streetNumber: '',
        floor: '',
        apartmentNumber: '',
        postalCode: '',
        isAddressNotFound: false,
        type: 'smallClaim'
      }))
      setSearchCity('')
      setSearchStreet('')
      setTouch({});
    }
  }

  const handleChangeData = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setClaimantState((prevState) => ({
      ...prevState,
      [field]: value
    }));
    handleChanges(event);
    setTouch({ ...touch, [field]: true });
  };

  const getIdx = () => {
    let idx = data?.claimants.findIndex((claimant) => claimant.id === claimantState.id)
    if (idx === -1) idx = data?.claimants?.length + 1
    else idx += 1
    return idx
  }

  return (
    <div className='form7-personal-company personal-delivery main-form high small-claim-claimant'>
      <div className='modal-content-container'>
        <div className='title-tooltip-header'>

          <CustomTooltip tooltipType={'smallClaimClaimant'}>
            <img src={info} width={20} alt="info-icon" className="info-icon" />
          </CustomTooltip>

          <h2 className='med-title right'>
            {`תובע/ת ${getIdx()}`}
          </h2>
        </div>

        <h3 className='small'>פרטים</h3>

        <div className='input-container'>
          <div className='field'>
            <label className='input-label'>
              שם פרטי*
            </label>
            <Field
              autoComplete="new-password"
              type="text"
              className={
                isError('claimantFirstName')
                  ? 'error'
                  : claimantState?.claimantFirstName
                    ? 'success'
                    : ''
              }
              value={
                claimantState?.claimantFirstName ||
                ''
              }
              name={'claimantFirstName'}
              onChange={handleChangeData}
            />
            {isError('claimantFirstName') && <p className='error-label'>{errors.claimantFirstName}</p>}
          </div>

          <div className='field'>
            <label className='input-label'>
              שם משפחה*
            </label>
            <Field
              autoComplete="new-password"
              type="text"
              className={
                isError('claimantLastName')
                  ? 'error'
                  : claimantState?.claimantLastName
                    ? 'success'
                    : ''
              }
              value={
                claimantState?.claimantLastName ||
                ''
              }
              name={'claimantLastName'}
              onChange={handleChangeData}
            />
            {isError('claimantLastName') && <p className='error-label'>{errors.claimantLastName}</p>}
          </div>

          <div className='field'>
            <label className='input-label'>
              כתובת דוא"ל*
            </label>
            <Field
              autoComplete="new-password"
              type="text"
              className={
                isError('claimantEmail')
                  ? 'error'
                  : emailRegex.test(claimantState?.claimantEmail)
                    ? 'success'
                    : ''
              }
              value={
                claimantState?.claimantEmail ||
                ''
              }
              name={'claimantEmail'}
              onChange={handleChangeData}
            />
            {isError('claimantEmail') && <p className='error-label'>{errors.claimantEmail}</p>}
          </div>

          <div className='field'>
            <label className='input-label'>
              תעודת זהות*
            </label>
            <Field
              autoComplete="new-password"
              type="number"
              className={
                isError('claimantId')
                  ? 'error'
                  : claimantState?.claimantId?.length === 9
                    ? 'success'
                    : ''
              }
              value={
                claimantState?.claimantId ||
                ''
              }
              name={'claimantId'}
              onChange={handleChangeData}
            />
            {isError('claimantId') && <p className='error-label'>{errors.claimantId}</p>}
          </div>

          <div className='field'>
            <label className='input-label'>
              טלפון*
            </label>
            <div className='icon-input'>
              <Field
                autoComplete="new-password"
                type="number"
                className={
                  isError('claimantPhone')
                    ? 'error'
                    : phoneRegex.test(claimantState?.claimantPhone)
                      ? 'success'
                      : ''
                }
                value={
                  claimantState?.claimantPhone ||
                  ''
                }
                name={'claimantPhone'}
                onChange={handleChangeData}
              />

              <div className="icon">972+</div>
            </div>
            {isError('claimantPhone') && <p className='error-label'>{errors.claimantPhone}</p>}
          </div>

        </div>

        <h3 className='small'>כתובת</h3>

        <div className='input-container'>

          <div className='field large'>
            <label className='input-label'>יישוב*</label>
            <div
              ref={refCity}
              className={
                isCitiesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
              }
            >
              <div className='dropdown-input'>
                <input
                  type="text"
                  placeholder='הזינו יישוב ובחרו מתוך הרשימה'
                  onBlur={onBlur}
                  onChange={(event) => {
                    setSearchCity(
                      event.target.value
                    );
                    setIsCitiesDropdownOpen(true);
                  }}
                  value={searchCity}
                  onClick={() => { setIsCitiesDropdownOpen(true); }}

                  name='city'
                  autoComplete="off"
                  className={
                    isError('city')
                      ? 'error'
                      : claimantState?.city
                        ? 'success'
                        : ''
                  }
                />
                <button
                  type='button'
                  onClick={() => {
                    setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                  }}
                >
                  <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                </button>
              </div>

              {isError('city') && <p className='error-label'>{errors.city}</p>}

              <div className='dropdown-list'>
                {israelCities.filter((option) => option['שם_ישוב'].includes(searchCity)).map((city, idx) => {
                  return (
                    <div className='dropdown-option' key={idx}>
                      <p
                        onMouseDown={() => {
                          const cityName = city['שם_ישוב'].trim()
                          onSetChoose('city', cityName)
                          setSearchCity(cityName)
                          setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                          if (cityName !== claimantState.city) {
                            setSearchStreet('')
                            setClaimantState((prevState) => ({
                              ...prevState,
                              streetAddress: ''
                            }))
                          }
                        }}>
                        {city['שם_ישוב']}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {claimantState.isAddressNotFound ?
            <div className='field extra-height'>
              <label className='input-label'>רחוב*</label>
              <Field
                type="text"
                name='streetAddress'
                autoComplete="new-password"
                value={searchStreet}
                onChange={(ev) => {
                  setSearchStreet(ev.target.value);
                  onSetChoose('streetAddress', ev.target.value)
                }}
                className={
                  isError('city')
                    ? 'error'
                    : claimantState?.streetAddress
                      ? 'success'
                      : ''
                }
              />
              {isError('streetAddress') && <p className='error-label'>{errors.streetAddress}</p>}

              <label className="checkbox-container">
                <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={claimantState.isAddressNotFound} onChange={() => handleIsAddressNotFound()} />
                <label className='checkbox-content' htmlFor='isAddressNotFound'>
                  לא מצאתי את הרחוב
                </label>
                <span className="checkmark"></span>
              </label>

            </div>

            :

            <div className='field extra-height'>
              <label className='input-label'>רחוב*</label>

              <div
                ref={refStreet}
                className={
                  isStreetsDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                }
              >
                <div className='dropdown-input'>
                  <input
                    type="text"
                    placeholder='הזינו רחוב ובחרו מתוך הרשימה'
                    onBlur={onBlur}
                    onChange={(event) => {
                      setSearchStreet(event.target.value);
                      setIsStreetsDropdownOpen(true);
                    }}
                    value={searchStreet}
                    onClick={() => { setIsStreetsDropdownOpen(true); }}

                    name='streetAddress'
                    autoComplete="new-password"
                    className={
                      isError('streetAddress')
                        ? 'error'
                        : claimantState?.streetAddress
                          ? 'success'
                          : ''
                    }
                  />
                  <button
                    type='button'
                    onClick={() => {
                      setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                    }}
                  >
                    <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                  </button>
                </div>

                {isError('streetAddress') && <p className='error-label'>{errors.streetAddress}</p>}

                <div className='dropdown-list'>
                  {cityStreets.filter((option) => option['שם_רחוב'].includes(searchStreet)).map((street, idx) => {
                    return (
                      <div className='dropdown-option' key={idx}>
                        <p
                          onMouseDown={() => {
                            onSetChoose('streetAddress', street['שם_רחוב'])
                            setSearchStreet(street['שם_רחוב'])
                            setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                          }}>
                          {street['שם_רחוב']}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>

              <label className="checkbox-container">
                <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={claimantState.isAddressNotFound} onChange={() => handleIsAddressNotFound()} />
                <label className='checkbox-content' htmlFor='isAddressNotFound'>
                  לא מצאתי את הרחוב
                </label>
                <span className="checkmark"></span>
              </label>

            </div>
          }

          <div className='field small' >
            <label className='input-label'>מספר*</label>
            <Field
              autoComplete="new-password"
              type="text"
              className={
                isError('streetNumber')
                  ? 'small error'
                  : claimantState?.streetNumber
                    ? 'small success'
                    : 'small'
              }
              value={claimantState.streetNumber}
              name='streetNumber'
              onChange={handleChangeData}
            />
            {isError('streetNumber') && <p className='error-label'>{errors.streetNumber}</p>}
          </div>
          <div className='field small'>
            <label className='input-label'>קומה</label>
            <Field
              type="text"
              autoComplete="new-password"
              className={
                claimantState?.floor ? 'small success' : 'small'
              }
              value={claimantState.floor}
              name='floor'
              onChange={handleChangeData}
            />
          </div>
          <div className='field small'>
            <label className='input-label'>דירה</label>
            <Field
              type="text"
              autoComplete="new-password"
              className={
                claimantState?.apartmentNumber
                  ? 'small success'
                  : 'small'
              }
              value={claimantState.apartmentNumber}
              name='apartmentNumber'
              onChange={handleChangeData}
            />
          </div>

          <div className='field small-plus' >
            <div className='postal-code-tooltip'>
              <label className='input-label'>מיקוד*</label>
              <CustomTooltip tooltipType={'postalCode'}>
                <img src={info} width={20} alt="info-icon" className="info-icon" />
              </CustomTooltip>
            </div>
            <Field
              autoComplete="new-password"
              type="number"
              className={
                isError('postalCode')
                  ? 'error'
                  : claimantState?.postalCode
                    ? 'success'
                    : ''
              }
              value={claimantState.postalCode}
              name='postalCode'
              onChange={handleChangeData}
            />
            {isError('postalCode') && <p className='error-label'>{errors.postalCode}</p>}
          </div>

        </div>

        <div className="add-claimant">
          <img
            className="btn-add"
            src='/images/plus.svg'
            alt=''
            onClick={() => {
              onAddNewClaimant()
            }}
          />
          <h3>הוספת תובע/ת נוספ/ת</h3>
        </div>
      </div>

    </div>
  );
};

export default SmallClaimClaimant;
