import React from 'react'
import { UserOrdersFilterType } from './UserOrdersFilterType'
import { UserOrdersSearch } from './UserOrdersSearch'
import { useSelector } from 'react-redux'
import closeIconBlack from "../../images/closeIconBlack.svg";

export const FiltersModal = ({ showFiltersModal, closeFiltersModal, dateType, actionType, usersOfOrders, filterBy, setFilterBy, placeHolderAction }) => {
    const activeUser = useSelector((state) => state.userReducer.user);

    const onClose = () => {
        setFilterBy({
            orderId: '',
            extraSearch: '',
            actionType: {
                type: '',
                typeToFilter: ''
            },
            dateType: {
                type: '',
                typeToFilter: ''
            },
            userType: {
                type: '',
                typeToFilter: ''
            },
        })
        closeFiltersModal(false);
    }

    return (
        <div className={`${showFiltersModal ? 'pop-up-background filters-popup' : ''}`}>
            <div className={`${showFiltersModal ? "filters-modal open" : "filters-modal"}`}>
                <div className='modal-header'>
                    <h4>
                        מסננים
                    </h4>

                    <button className='close-icon' onClick={() => { closeFiltersModal(false); }}>
                        <img src={closeIconBlack} alt="close" />
                    </button>
                </div>

                <div className='filters'>
                    <div className='filter'>
                        <p className='filter-title'>לפי תאריך</p>
                        <UserOrdersFilterType types={dateType} placeholder={'תאריכים'} setFilterBy={setFilterBy} filterBy={filterBy} filterType={'dateType'} />
                    </div>
                    <div className='filter'>
                        <p className='filter-title'>לפי שירות</p>
                        <UserOrdersFilterType types={actionType} placeholder={placeHolderAction} setFilterBy={setFilterBy} filterBy={filterBy} filterType={'actionType'} />
                    </div>
                    {activeUser?.isMaster && 
                        <div className='filter'>
                            <p className='filter-title'>לפי משתמש</p>
                            <UserOrdersFilterType types={usersOfOrders} placeholder={'כל המשתמשים'} setFilterBy={setFilterBy} filterBy={filterBy} filterType={'userType'} />
                        </div>
                    }
                </div>

                <div className='search'>
                    <p className='filter-title'>חיפוש הזמנה</p>
                    <UserOrdersSearch filterBy={filterBy} setFilterBy={setFilterBy} isFromFiltersModal={true} />
                </div>

                <button className='main-btn' onClick={() => { closeFiltersModal(false); }}>
                    הצגת תוצאות
                </button>


            </div>
        </div>
    )
}
