import StackdriverErrorReporter from 'stackdriver-errors-js';

let errorHandler;

if (process.env.NODE_ENV === 'production') {
    errorHandler = new StackdriverErrorReporter();
    errorHandler.start({
        key: 'AIzaSyAmjU_CerFxq952W3_JJm7WktcwVYATJ_Q',
        projectId: 'delawvery-online-israel',
        // Other optional arguments can be supplied, see above.
    });
} else {
    errorHandler = {report: console.error};
}

export default errorHandler;