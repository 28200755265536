import React, { useState, useEffect, useRef } from 'react';
import arrow from '../../../images/arrowdropdown.png';
import { Field } from 'formik';
import { useSelector } from 'react-redux';
import { TailSpin } from 'react-loader-spinner'

export const UsersDropdown = ({ handleChange, props, handleUserOfOrder, company }) => {
    const companyUsers = useSelector((state) => state.companyReducer.companyUsers);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const [isShowAll, setIsShowAll] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [users, setUsers] = useState([])
    const [searchUser, setSearchUser] = useState(props.values?.orderClientName || '')
    const activeUser = useSelector((state) => state.userReducer.user);
    const userRef = useRef()

    useEffect(() => {
        if (!searchUser) setIsShowAll(true)

    }, [searchUser]);

    useEffect(() => {
        if (activeUser && company) {
            setIsLoading(true)
            const notBlockedUsers = []
            for (let i = 0; i < companyUsers.length; i++) {
                const currUser = companyUsers[i];
                if (currUser?.userActivity !== 'blocked') {
                    notBlockedUsers.push(currUser)
                }
            }
            setUsers([...notBlockedUsers])
            setIsLoading(false)
        }
    }, [activeUser, company, company?.awaitingUsers]);

    useEffect(() => {
        const onBodyClick = (event) => {
            if (userRef?.current?.contains(event.target)) {
                return;
            }
            setIsDropdownOpen(false)
            setIsShowAll(true)
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });

        return () => {
            document.body.removeEventListener("click", onBodyClick, {
                capture: true,
            });
        };
    }, []);

    const onBlur = () => {
        if (props.values?.orderClientName !== searchUser) {
            setSearchUser(props.values?.orderClientName ? props.values?.orderClientName : '')
        }
        setIsDropdownOpen(false)
    }

    const handleChangeCheckbox = (user) => {
        const ev = { target: { name: 'orderClientName', value: user.fullName } }
        handleChange(ev)
        props.handleChange(ev)
        const e = { target: { name: 'invoiceEmail', value: user.email } }
        handleChange(e);
        props.handleChange(e)
        setIsDropdownOpen(false)
    };

    return (
        <div className='checkout-users-dropdown'>
            <div
                ref={userRef}
                className={
                    isDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                }
            >
                <div className='dropdown-input' onClick={() => { setIsDropdownOpen(prevState => !prevState); }}>
                    <input
                        type="text"
                        placeholder=''
                        onBlur={onBlur}
                        onChange={(event) => {
                            setSearchUser(
                                event.target.value
                            );
                            setIsDropdownOpen(true);
                            setIsShowAll(false);
                        }}
                        value={searchUser}
                        className={`${props.values.orderClientName.length > 0 ? 'success name-input' : props.touched.orderClientName && Boolean(props.errors.orderClientName) ? 'error name-input' : 'name-input'}`}
                    />
                    <button type='button'>
                        <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                    </button>
                </div>

                <div className='dropdown-list'>
                    {isLoading ?
                        <div className='spinner'>
                            <TailSpin
                                heigth="25"
                                width="25"
                                color='#4e71ff'
                                ariaLabel='loading'
                            />
                        </div>
                        :
                        users.length && users.map((user, idx) => {
                            // if (!user.fullName.includes(searchUser)) return <></>
                            // else 
                            if (isShowAll || user.fullName.includes(searchUser)) return (
                                <div
                                    key={idx}
                                    className='dropdown-option users'
                                    //To here - check
                                    onMouseDown={() => { handleChangeCheckbox(user); setSearchUser(user.fullName); handleUserOfOrder(user); setIsShowAll(true); }}
                                >
                                    <Field
                                        id={'typeOfTransfer' + idx}
                                        type='radio'
                                        name="orderClientName"
                                        value={user.fullName}
                                        //Moved from here
                                        // onMouseDown={() => { handleChangeCheckbox(user); setSearchUser(user.fullName); handleUserOfOrder(user); setIsDropdownOpen(false); setIsShowAll(true); }}
                                        checked={props.values.invoiceEmail === user.email}
                                    />
                                    <label htmlFor={'typeOfTransfer' + idx}>{user.fullName}</label>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default UsersDropdown;
