import React, { useEffect } from 'react';
import { Sidebar } from './Main/SideBar/Sidebar'
import { Search } from './Search'
import { ReactComponent as Close } from '../images/close.svg'
import { useDispatch, useSelector } from 'react-redux';
import { setMobileState } from '../store/actions/systemActions';
import db, { trace, perf} from '../integrations/firebase';


export const ModalToolbar = () => {
    const t = trace(perf, "AllActions");
    t.start();
    const mobile = useSelector((state) => state.mobileReducer.mobileState);
    const systemData = useSelector((state) => state.systemReducer.systemData);
    const dispatch = useDispatch();


    const onCloseModal = () => {
        dispatch(setMobileState({
            ...mobile,
            isModalToolbarOpen: false,
        }));
    }

    const modalPreview = (modalToolbar) => {
        switch (modalToolbar) {
            case 'search':
                return <Search />;
            case 'cart':
                return <Sidebar />;
            default:
                return <></>;
        }
    };

    const returnValue = (
        <div className={mobile.isModalToolbarOpen ? "modal-toolbar open" : "modal-toolbar"}>
            <div className="modal-close">
                <Close onClick={() => onCloseModal()} className="close-modal-icon" />
            </div>
            {modalPreview(systemData.modalToolbar)}
        </div>
    );
    t.stop()
    return returnValue
}
