import React from "react";
import { Link } from 'react-router-dom'
import { actionsSite } from "../../consts/data";
import db, { trace, perf} from '../../integrations/firebase';


export default function Footer() {
  const t = trace(perf, "AllActions");
  t.start();
  const returnValue = (
      <div className="footer-layout">
        <div className="full footer-layout desktop-footer">
          <div>


            <div className="main-footer">

              <div className="common-footer">
                <h3>
                  שירותים נפוצים
                </h3>
                <div className="actions">
                  {actionsSite.map(item => (
                      item.isActive &&
                      <Link to={`/forms/${item.nid}`}
                            className="action-footer"
                            key={item.title}
                      >
                        {item.title}
                      </Link>
                  ))}
                  {actionsSite.map(item => (
                      !item.isActive &&
                      <div to={`/forms/${item.nid}`}
                           className="action-footer"
                           key={item.title}
                      >
                        {item.title}
                      </div>
                  ))}
                </div>
              </div>
              {/* <div> */}
              <div className="chat-card">
                <img
                    src="/images/chat.svg"
                    alt="chat"
                />
                <div className="main-content">
                  <div className="content" >
                    <p className="p1">הצוות המקצועי שלנו כאן בשבילכם</p>
                    <p className="p4">בימים ראשון - חמישי, בין השעות 08:00-17:00</p>
                  </div>
                  <a
                      href="https://delawvery.co.il/contact/"
                      target="_blank"
                      rel="noreferrer"
                  >
                    <button className="main-btn small">
                      דברו איתנו
                    </button>
                  </a>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>

          <div className="footer-end" >

            <div className="footer-nav">
              <a href="https://delawvery.co.il/terms-of-use/" target="_blank" rel="noreferrer"> <p>תקנון שימוש</p> </a>
              <img src="/images/dot.svg" alt="dot" style={{ width: "5px" }} />
              <a href="https://delawvery.co.il/privacy-policy/" target="_blank" rel="noreferrer"> <p>מדיניות פרטיות</p> </a>
              <img src="/images/dot.svg" alt="dot" style={{ width: "5px" }} />
              <a href="https://delawvery.co.il/accessibility-statement/" target="_blank" rel="noreferrer"><p>הצהרת נגישות</p></a>
              <img src="/images/dot.svg" alt="dot" style={{ width: "5px" }} />

            </div>

            <div>
              <p >כל הזכויות שמורות דלוברי בע״מ © 2021</p>
            </div>
          </div>
        </div>

        {/* <div className="full footer-layout mobile-footer">
        <button className="btn-icon-footer" >
          <img src={home} alt="home icon" />
          <p>הבית</p>
        </button>
        <button className="btn-icon-footer" onClick={() => { setModalToolbar('search'); setModalToolbarOpen(true); }}>
          <img src={search} alt="search icon" />
          <p>חפש שירות</p>
        </button>
        <button className="btn-icon-footer soon" >
          <img src={myaccount} alt="my-account icon" />
          <p>החשבון שלי</p>
          <span className="coming-soon">
            בקרוב
          </span>
        </button>
        <button className="btn-icon-footer" onClick={() => { setModalToolbar('cart'); setModalToolbarOpen(true); }}>
          <div className="cart-icon">
            <img src={cart} alt="cart icon" />
            <span className="badge">9</span>
          </div>
          <p>סל הקניות</p>
        </button>

      </div> */}

      </div >
  );
  t.stop()
  return returnValue
}
