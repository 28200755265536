//documentTransfer
//העברת מסמכים

const handleSteps = (data) => {
  return [
    'DocumentTransferType',
    'UrgencyExecution',
    'FinalPage'
  ].flat()
};

const handlePrevStep = (step, setStep, formSteps, state, setMyState, data) => {
  const { nextStep } = state;
  let nowStep
  if (step < 0) return
  else nowStep = step - 1;
  setStep(nowStep);
  setMyState({ ...state, nextStep: formSteps[nowStep], isFinalStep: false });
};

const handleNextStep = (step, setStep, formSteps, state, setMyState, onAddRecipient, onAddOrder, data, setProgressBarPhase, setData, onAddEditClaimant, onAddEditDefendant, onSetLastDefendant, onSetLastClaimant, isTestUser, removeSubmitter, isLoading) => {
  const { nextStep, isFinalStep } = state;

  let nowStep;
  if (isLoading) return

  if (isFinalStep) {
    onAddOrder();
  }
  else {
    nowStep = step + 1;
  }

  setMyState({ ...state, nextStep: formSteps[nowStep] });
  setStep(nowStep);
};

const handlePhase = (nextStep, setProgressBarPhase) => {
  switch (nextStep) {
    case 'DocumentTransferType':
      setProgressBarPhase(1);
      break;
    case 'UrgencyExecution':
      setProgressBarPhase(2);
      break;

    default:
      setProgressBarPhase(2);
      break;
  }
};

export { handlePhase, handleSteps, handlePrevStep, handleNextStep };
