import { useState } from 'react';

export const useApostropheSubstitution = (initialValue = '') => {
    const [value, setValue] = useState(initialValue);

    const handleInputChange = (val) => {
        const inputValue = val.replace(/׳/g, "'");
        setValue(inputValue);
    };

    return [value, handleInputChange];
};