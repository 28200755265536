//associationsAndCompanies
//רשם העמותות וחל"צ

const handleSteps = (data) => {
  const { collectionMethod, returnMethod, docsForSubmission } = data
  const isDeposit = docsForSubmission?.some(doc => doc.isDeposit === false)
  const isCollect = docsForSubmission?.some(doc => doc.upload === false)

  return [
    'ChooseOffice',
    'WhatServedRegist',
    isCollect ? 'CollectDocumentsAndDocumentsReady' : 'CollectDocumentsOrUploadFile2',
    isDeposit ? 'Proof3' : [],
    'UrgencyExecution',
    'FinalPage'
  ].flat()
};

const handlePrevStep = (step, setStep, formSteps, state, setMyState, data, setData) => {
  const { nextStep } = state;
  let nowStep
  if (step < 0) return

  nowStep = step - 1;

  setStep(nowStep);
  setMyState({ ...state, nextStep: formSteps[nowStep], isFinalStep: false });
};


const handleNextStep = (step, setStep, formSteps, state, setMyState, onAddRecipient, onAddOrder, data) => {
  const { nextStep, isFinalStep } = state;
  let nowStep;
  if (isFinalStep) {
    onAddOrder();
  }

  nowStep = step + 1

  setMyState({ ...state, nextStep: formSteps[nowStep] });
  setStep(nowStep);
};


const handlePhase = (nextStep, setProgressBarPhase) => {
  switch (nextStep) {
    case 'ChooseOffice':
      setProgressBarPhase(1);
      break;
    case 'WhatServedRegist':
    case 'CollectFileDetails':
    case 'DeposittFileDetails':
    case 'PowerOfAttorney':
    case 'ActionsSummary':
      setProgressBarPhase(2);
      break;
    case 'CollectDocumentsOrUploadFile2':
    case 'AddressCollectDocumentsUpdate':
    case 'CollectDocumentsAndDocumentsReady':
      setProgressBarPhase(3);
      break;
    case 'Proof3':
      setProgressBarPhase(4);
      break;

    case 'UrgencyExecution':
      setProgressBarPhase(5);
      break;

    default:
      setProgressBarPhase(5);
      break;
  }
};

export { handleSteps, handlePrevStep, handleNextStep, handlePhase };
