import React, { useEffect, useState, useRef } from 'react';
import arrow from '../../../images/dropdown-arrow.svg';
import info from '../../../images/info.svg'
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { utilService } from '../../../services/utilService'
import { defendantSet } from '../../../store/actions/smallClaimActions';
import { ClaimCompanyName } from '../../SmallClaim/ClaimCompanyName'
import { updateErrorModal } from '../../../store/actions/systemActions';
import { Tooltip } from '../../Utils/Tooltip'
import { CustomTooltip } from '../../Utils/CustomTooltip'
import { useApostropheSubstitution } from '../../../Hooks/useApostropheSubstitution'
import cities2 from "../../../consts/cities";
import streetRes2 from "../../../consts/streets";

export const SmallClaimDefendant = ({ data, setData, isError, errors, handleChanges, submitForm, setTouch, touch, setSubmitForm }) => {
  const currDefendant = useSelector((state) => state.smallClaimReducer.currDefendant);
  const refDefendant = useRef()
  const [isDefendantDropdownOpen, setIsDefendantDropdownOpen] = useState(false);

  const defendantOptions = [
    { text: 'יחיד/עוסק מורשה', isPersonalOrCompanyDefendant: 'personal' },
    { text: 'חברה בע"מ', isPersonalOrCompanyDefendant: 'company' },
  ];

  const tooltipOption = [
    {
      tooltip: 'איתור מספר פלאפון של אדם באמצעות חוקר פרטי בעל רישיון משרד המשפטים. התביעה תוגש לאחר איתור מספר הפלאפון שלוקח כמה ימים בודדים ומספר הפלאפון יצוין בתביעה.'
    },
    {
      tooltip: 'איתור כתובת מגורים באמצעות חוקר פרטי בעל רישיון משרד המשפטים. אנו מתחייבים לאיתור מוצלח. כלומר אם לא איתרנו - 250₪ יוחזרו במלואם! לאחר ביצוע האיתור שלוקח כמה ימים בודדים, אנו נגיש את התביעה בציון הכתובת שאותרה. '
    },
    {
      tooltip: 'קבלת הצעת מחיר לאיתור מספר זהות באמצעות חוקר פרטי. לאחר השלמת ההזמנה ניצור עימך קשר טלפוני לצורך מתן הצעת המחיר לביצוע האיתור (בכל מקרה האיתור מותנה בהצלחה)'
    },
  ]

  const [searchCity, setSearchCity] = useState('');
  const [searchStreet, setSearchStreet] = useApostropheSubstitution('');
  const [isCitiesDropdownOpen, setIsCitiesDropdownOpen] = useState(false);
  const [isStreetsDropdownOpen, setIsStreetsDropdownOpen] = useState(false);
  const [israelCities, setIsraelCities] = useState([])
  const [cityStreets, setCityStreets] = useState([])

  const refCity = useRef()
  const refStreet = useRef()
  // const activeUser = useSelector((state) => state.userReducer.user);
  // const systemData = useSelector((state) => state.systemReducer.systemData);
  const dispatch = useDispatch();

  const phoneRegex = /^\d{9,10}$/;
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [defendantState, setDefendantState] = useState({
    id: utilService.makeId(),
    defendantFirstName: '',
    defendantLastName: '',
    defendantCompanyName: '',
    defendantId: '',
    defendantPhone: '',
    defendantEmail: '',
    city: '',
    streetAddress: '',
    streetNumber: '',
    floor: '',
    apartmentNumber: '',
    postalCode: '',
    isAddressNotFound: false,
    isPhoneLocate: false,
    isAddressLocate: false,
    isIdLocate: false,
    isPersonalOrCompanyDefendant: null,
    type: 'smallClaim'
  });

  useEffect(() => {
    if (currDefendant?.id) {
      setDefendantState({ ...currDefendant })
    }
  }, []);

  useEffect(() => {
    dispatch(defendantSet(defendantState))
  }, [defendantState]);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (refCity?.current?.contains(event.target) || refStreet?.current?.contains(event.target)) {
        return;
      }
      setIsCitiesDropdownOpen(false);
      setIsStreetsDropdownOpen(false);
      setIsDefendantDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    setSearchCity(currDefendant?.city || '')
    setSearchStreet(currDefendant?.streetAddress || '')

    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);

  useEffect(() => {
    if (isCitiesDropdownOpen) {
      getCities()
    } else {
      setIsraelCities([])
    }
    if (isStreetsDropdownOpen && defendantState?.city) {
      getStreetsOfCity(searchCity)
    } else {
      setCityStreets([])
    }
  }, [isCitiesDropdownOpen, isStreetsDropdownOpen]);

  const onBlur = () => {
    if (defendantState?.city !== searchCity) {
      setSearchCity(defendantState?.city ? defendantState?.city : '')
    }
    if (defendantState?.streetAddress !== searchStreet) {
      setSearchStreet(defendantState?.streetAddress ? defendantState?.streetAddress : '')
    }

    setIsCitiesDropdownOpen(false)
    setIsStreetsDropdownOpen(false)
  }

  const getCities = async () => {
    try {
      const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`, { signal: AbortSignal.timeout(5000) })
      const cities = await cityRes.json()
      const citiesAfterSwitch = switchBrackets(cities.result.records)
      setIsraelCities(citiesAfterSwitch)
    } catch (err) {
      const cities2 = require("../../../consts/cities")
      const citiesAfterSwitch2 = switchBrackets(cities2.result.records)
      setIsraelCities(citiesAfterSwitch2)
      console.log('Fetch error: ', err)
    }
  }

  const switchBrackets = (cities) => {
    const switchBracketsMap = {
      '(': ")",
      ')': "(",
    };

    return cities.map(city => {
      return {
        'שם_ישוב': city['שם_ישוב'].trim().replace(/\(|\)/gi, function (matched) {
          return switchBracketsMap[matched];
        })
      }
    })
  }

  const getStreetsOfCity = async (cityName) => {
    try {
      const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${cityName}`, { signal: AbortSignal.timeout(5000) })
      const StreetsOfCity = await streetRes.json()
      setCityStreets(StreetsOfCity.result.records)
    } catch (err) {
      const streetRes2 = require("../../../consts/streets")
      setCityStreets(streetRes2.result.records.filter((rec) => rec['שם_ישוב'] == cityName))
      console.log('Fetch error: ', err)
    }
  }

  const onSetChoose = (field, value, extraInfo = {}) => {
    if (field === 'defendantCompanyName') {
      let fixedValue = ''
      if (value.includes('בע~מ')) {
        fixedValue = value.replace('בע~מ', 'בע"מ')
      }
      setDefendantState((prevState) => ({
        ...prevState,
        defendantCompanyName: fixedValue || value,
        city: extraInfo['שם עיר'] || '',
        streetAddress: extraInfo['שם רחוב'] || '',
        streetNumber: extraInfo['מספר בית'] || '',
        postalCode: extraInfo['מיקוד'] || '',
        defendantId: `${extraInfo['מספר חברה']}` || '',
      }));
      setSearchCity(extraInfo['שם עיר'] || '')
      setSearchStreet(extraInfo['שם רחוב'] || '')
    } else {
      setDefendantState((prevState) => ({
        ...prevState,
        [field]: value,
      }));
    }
    setTouch({ ...touch, [field]: true });
  }

  const handleIsAddressNotFound = () => {
    setDefendantState((prevState) => ({
      ...prevState,
      isAddressNotFound: !prevState.isAddressNotFound,
    }))
  }

  const handleIsPhoneLocate = () => {
    setDefendantState((prevState) => ({
      ...prevState,
      isPhoneLocate: !prevState.isPhoneLocate,
      defendantPhone: '',
    }))
  }

  const handleIsAddressLocate = () => {
    if (!currDefendant.isAddressLocate) {
      setSearchCity('')
      setSearchStreet('')
    }

    setDefendantState((prevState) => ({
      ...prevState,
      isAddressLocate: !prevState.isAddressLocate,
      city: '',
      streetAddress: '',
      streetNumber: '',
      floor: '',
      apartmentNumber: '',
      postalCode: '',
    }))
  }
  const handleIsIdLocate = () => {
    setDefendantState((prevState) => ({
      ...prevState,
      isIdLocate: !prevState.isIdLocate,
      defendantId: '',
    }))
  }

  const handleChangeData = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setDefendantState((prevState) => ({
      ...prevState,
      [field]: value
    }));
    handleChanges(event);
    setTouch({ ...touch, [field]: true });
  };


  const handleChangeDefendantCheckbox = (event) => {
    const value = event.target.value;
    const { name } = event.target;
    if (value === 'יחיד/עוסק מורשה') {
      setDefendantState((prevState) => ({
        ...prevState,
        isPersonalOrCompanyDefendant: 'personal',

        defendantFirstName: '',
        defendantLastName: '',
        defendantCompanyName: '',
        defendantId: '',
        defendantPhone: '',
        defendantEmail: '',
        city: '',
        streetAddress: '',
        streetNumber: '',
        floor: '',
        apartmentNumber: '',
        postalCode: '',
        isAddressNotFound: false,
        isPhoneLocate: false,
        isAddressLocate: false,
        isIdLocate: false,
      }));
    } else {
      setDefendantState((prevState) => ({
        ...prevState,
        isPersonalOrCompanyDefendant: 'company',
        isPhoneLocate: false,
        isAddressLocate: false,
        isIdLocate: false,

        defendantFirstName: '',
        defendantLastName: '',
        defendantCompanyName: '',
        defendantId: '',
        defendantPhone: '',
        defendantEmail: '',
        city: '',
        streetAddress: '',
        streetNumber: '',
        floor: '',
        apartmentNumber: '',
        postalCode: '',
        isAddressNotFound: false,
      }));
    }
    // setData({
    //   ...data,
    //   [name]: +(value)
    // });
    setSearchCity('')
    setSearchStreet('')
    setIsDefendantDropdownOpen(false)
  };

  const addDefendant = () => {
    const defendantsUpdated = [...data.defendants]
    if (defendantsUpdated.length) {
      const idxToUpdate = defendantsUpdated.findIndex((defendant) => defendant.id === defendantState.id)
      if (idxToUpdate === -1 && Object.keys(errors).length === 0) {
        setData({
          ...data,
          defendants: [...defendantsUpdated, defendantState]
        });
      } else {
        defendantsUpdated[idxToUpdate] = defendantState
        if (Object.keys(errors).length === 0 && defendantState.defendantId && idxToUpdate > -1) {
          setData({
            ...data,
            defendants: [...defendantsUpdated]
          });
        }
      }
    } else {
      setData({
        ...data,
        defendants: [...data.defendants, defendantState]
      });
    }
  }

  const onAddNewDefendant = () => {
    let isErr = false
    if (!defendantState.isIdLocate && !defendantState.defendantId) isErr = true
    if (!defendantState.isAddressLocate
      && (!defendantState.streetAddress
        || !defendantState.streetNumber
        || !defendantState.postalCode
        || !defendantState.city)) isErr = true

    if (defendantState.isPersonalOrCompanyDefendant === 'personal'
      && (!defendantState.defendantFirstName
        || !defendantState.defendantFirstName)) isErr = true

    if (defendantState.isPersonalOrCompanyDefendant === 'company'
      && !defendantState.defendantCompanyName) isErr = true


    if (Object.keys(errors).length !== 0
      || isErr === true
    ) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'חובה למלא את כל הפרטים החסרים לפני הוספת נתבע/ת נוספ/ת',
          submitText: 'אישור',
          isCancel: false
        })
      );
    }
    else {
      addDefendant()
      setDefendantState((prevState) => ({
        ...prevState,
        id: utilService.makeId(),
        defendantFirstName: '',
        defendantLastName: '',
        defendantCompanyName: '',
        defendantId: '',
        defendantPhone: '',
        defendantEmail: '',
        city: '',
        streetAddress: '',
        streetNumber: '',
        floor: '',
        apartmentNumber: '',
        postalCode: '',
        isAddressNotFound: false,
        isPhoneLocate: false,
        isAddressLocate: false,
        isIdLocate: false,
        isPersonalOrCompanyDefendant: null,
        type: 'smallClaim'
      }))
      setSearchCity('')
      setSearchStreet('')
      setTouch({});
    }
  }

  const getIdx = () => {
    let idx = data?.defendants.findIndex((defendant) => defendant.id === defendantState.id)
    if (idx === -1) idx = data?.defendants?.length + 1
    else idx += 1
    return idx
  }

  return (
    <div className='main-form high small-claim-defendant'>
      <div className='modal-content-container'>
        <h2 className='med-title right'>
          {defendantState?.isPersonalOrCompanyDefendant === 'personal' ? `נתבע/ת ${getIdx()} (יחיד/עוסק מורשה)` :
            defendantState?.isPersonalOrCompanyDefendant === 'company' ? `נתבעת ${getIdx()} (חברה בע"מ)` : ''}
        </h2>

        <div className="dropdown">
          <div className='type-of-defendant'>
            <div
              ref={refDefendant}
              className={
                isDefendantDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
              }
            >
              <div className='dropdown-input' onClick={() => {
                setIsDefendantDropdownOpen(!isDefendantDropdownOpen);
              }}>
                <div className='type-of-defendant-text'>
                  {/* {data.pages ? options.find(pagesOption => pagesOption.numOfPages === data.pages).text : 'כמה עמודים אנחנו צריכים להדפיס?'} */}
                  {defendantState?.isPersonalOrCompanyDefendant === 'personal' ? 'יחיד/עוסק מורשה' :
                    defendantState?.isPersonalOrCompanyDefendant === 'company' ? 'חברה בע"מ' : 'בחירת סוג נתבע'}
                </div>
                <button type='button' onClick={(ev) => { ev.stopPropagation(); setIsDefendantDropdownOpen(!isDefendantDropdownOpen); }}>
                  <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                </button>
              </div>

              <div className='dropdown-list type-of-defendant-list'>
                {defendantOptions.map((option, idx) => {
                  return (
                    <div
                      key={idx}
                      className='dropdown-option'
                    >
                      <Field
                        id={'defendant' + idx}
                        type='radio'
                        // name='isPersonalDef'
                        value={option.text}
                        onClick={handleChangeDefendantCheckbox}
                        checked={defendantState?.isPersonalOrCompanyDefendant === option.isPersonalOrCompanyDefendant}
                      />
                      <label htmlFor={'defendant' + idx}>{option.text}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {defendantState?.isPersonalOrCompanyDefendant &&
          <>
            <div className='input-container'>
              {defendantState?.isPersonalOrCompanyDefendant === 'personal' &&
                <>
                  <div className='field'>
                    <label className='input-label'>
                      שם פרטי*
                    </label>
                    <Field
                      autoComplete="new-password"
                      type="text"
                      className={
                        isError('defendantFirstName')
                          ? 'error'
                          : defendantState?.defendantFirstName
                            ? 'success'
                            : ''
                      }
                      value={
                        defendantState?.defendantFirstName ||
                        ''
                      }
                      name={'defendantFirstName'}
                      onChange={handleChangeData}
                    />
                    {isError('defendantFirstName') && <p className='error-label'>{errors.defendantFirstName}</p>}
                  </div>

                  <div className='field'>
                    <label className='input-label'>
                      שם משפחה*
                    </label>
                    <Field
                      autoComplete="new-password"
                      type="text"
                      className={
                        isError('defendantLastName')
                          ? 'error'
                          : defendantState?.defendantLastName
                            ? 'success'
                            : ''
                      }
                      value={
                        defendantState?.defendantLastName ||
                        ''
                      }
                      name={'defendantLastName'}
                      onChange={handleChangeData}
                    />
                    {isError('defendantLastName') && <p className='error-label'>{errors.defendantLastName}</p>}
                  </div>
                </>}

              {defendantState?.isPersonalOrCompanyDefendant === 'company' &&
                <ClaimCompanyName defendantState={defendantState} handleChangeData={handleChangeData} setDefendantState={setDefendantState} onSetChoose={onSetChoose} isError={isError} errors={errors} />
              }

              <div className='field'>
                <label className='input-label'>
                  כתובת דוא"ל
                </label>
                <Field
                  autoComplete="new-password"
                  type="text"
                  className={
                    isError('defendantEmail')
                      ? 'error'
                      : emailRegex.test(defendantState?.defendantEmail)
                        ? 'success'
                        : ''
                  }
                  value={
                    defendantState?.defendantEmail ||
                    ''
                  }
                  name={'defendantEmail'}
                  onChange={handleChangeData}
                />
                {isError('defendantEmail') && <p className='error-label'>{errors.defendantEmail}</p>}
              </div>

              <div className='field'>
                <label className='input-label'>
                  {defendantState?.isPersonalOrCompanyDefendant === 'personal' ? 'תעודת זהות*' : 'ח.פ.*'}
                </label>
                <Field
                  autoComplete="new-password"
                  type="number"
                  disabled={defendantState.isIdLocate}
                  className={`${isError('defendantId') && !defendantState.isIdLocate
                    ? 'error'
                    : (defendantState?.isPersonalOrCompanyDefendant === 'personal' && defendantState?.defendantId?.length === 9)
                      || (defendantState?.isPersonalOrCompanyDefendant === 'company' && defendantState?.defendantId?.length > 0)
                      ? 'success'
                      : ''} ${defendantState.isIdLocate ? 'disabled' : ''}`
                  }
                  value={
                    defendantState?.defendantId ||
                    ''
                  }
                  name={'defendantId'}
                  onChange={handleChangeData}
                />
                {isError('defendantId') && !defendantState.isIdLocate && <p className='error-label'>{errors.defendantId}</p>}
                {defendantState?.isPersonalOrCompanyDefendant === 'personal' &&
                  <div className='id-locate-tooltip'>
                    <Tooltip text={tooltipOption[2].tooltip}>
                      <label className="checkbox-container">
                        <Field type="checkbox" id="isIdLocate" name="isIdLocate" checked={defendantState.isIdLocate} onChange={() => handleIsIdLocate()} />
                        <label className='checkbox-content' htmlFor='isIdLocate'>
                          איתור מספר זהות באמצעות חוקר פרטי
                        </label>
                        <span className="checkmark"></span>
                      </label>
                    </Tooltip>
                  </div>
                }
              </div>

              <div className='field extra-height'>
                <label className='input-label'>
                  טלפון
                </label>
                <div className='icon-input'>
                  <Field
                    autoComplete="new-password"
                    type="number"
                    disabled={defendantState.isPhoneLocate}
                    className={`${isError('defendantPhone')
                      ? 'error'
                      : phoneRegex.test(defendantState?.defendantPhone)
                        ? 'success'
                        : ''} ${defendantState.isPhoneLocate ? 'disabled' : ''}`
                    }
                    value={
                      defendantState?.defendantPhone ||
                      ''
                    }
                    name={'defendantPhone'}
                    onChange={handleChangeData}
                  />

                  <div className="icon">972+</div>
                </div>
                {isError('defendantPhone') && <p className='error-label'>{errors.defendantPhone}</p>}

                {defendantState?.isPersonalOrCompanyDefendant === 'personal' &&
                  <Tooltip text={tooltipOption[0].tooltip}>
                    <label className="checkbox-container">
                      <Field type="checkbox" id="isPhoneLocate" name="isPhoneLocate" checked={defendantState.isPhoneLocate} onChange={() => handleIsPhoneLocate()} />
                      <label className='checkbox-content' htmlFor='isPhoneLocate'>
                        איתור פלאפון בתוספת  150₪
                      </label>
                      <span className="checkmark"></span>
                    </label>
                  </Tooltip>
                }

              </div>

            </div>

            <h3 className='small'>כתובת</h3>

            <div className='input-container'>
              <div className='field large extra-height'>
                <label className='input-label'>יישוב*</label>
                <div
                  ref={refCity}
                  className={
                    isCitiesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                  }
                >
                  <div className='dropdown-input'>
                    <input
                      type="text"
                      placeholder={defendantState.isAddressLocate ? '' : 'הזינו יישוב ובחרו מתוך הרשימה'}
                      onBlur={onBlur}
                      onChange={(event) => {
                        setSearchCity(
                          event.target.value
                        );
                        setIsCitiesDropdownOpen(true);
                      }}
                      value={searchCity}
                      onClick={() => { setIsCitiesDropdownOpen(true); }}
                      disabled={defendantState.isAddressLocate}
                      name='city'
                      autoComplete="off"
                      className={`${isError('city') && !defendantState.isAddressLocate
                        ? 'error'
                        : defendantState?.city
                          ? 'success'
                          : ''} ${defendantState.isAddressLocate ? 'disabled disable-input' : ''}`
                      }
                    />
                    <button
                      type='button'
                      onClick={() => {
                        setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                      }}
                    >
                      <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                    </button>
                  </div>

                  {isError('city') && !defendantState.isAddressLocate && <p className='error-label'>{errors.city}</p>}

                  <div className='dropdown-list'>
                    {israelCities.filter((option) => option['שם_ישוב'].includes(searchCity)).map((city, idx) => {
                      return (
                        <div className='dropdown-option' key={idx}>
                          <p
                            onMouseDown={() => {
                              const cityName = city['שם_ישוב'].trim()
                              onSetChoose('city', cityName)
                              setSearchCity(cityName)
                              setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                              if (cityName !== defendantState.city) {
                                setSearchStreet('')
                                setDefendantState((prevState) => ({
                                  ...prevState,
                                  streetAddress: ''
                                }))
                              }
                            }}>
                            {city['שם_ישוב']}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {defendantState?.isPersonalOrCompanyDefendant === 'personal' &&
                  <div className='address-locate-tooltip'>
                    <Tooltip text={tooltipOption[1].tooltip}>
                      <label className="checkbox-container">
                        <Field type="checkbox" id="isAddressLocate" name="isAddressLocate" checked={defendantState.isAddressLocate} onChange={() => handleIsAddressLocate()} />
                        <label className='checkbox-content' htmlFor='isAddressLocate'>
                          איתור כתובת באמצעות חוקר פרטי בתוספת 250₪
                        </label>
                        <span className="checkmark"></span>
                      </label>
                    </Tooltip>
                  </div>
                }
              </div>

              {defendantState.isAddressNotFound ?
                <div className='field extra-height'>
                  <label className='input-label'>רחוב*</label>
                  <Field
                    type="text"
                    name='streetAddress'
                    disabled={defendantState.isAddressLocate}
                    autoComplete="new-password"
                    value={searchStreet}
                    onChange={(ev) => {
                      setSearchStreet(ev.target.value);
                      onSetChoose('streetAddress', ev.target.value)
                    }}
                    className={`${isError('streetAddress') && !defendantState.isAddressLocate
                      ? 'error'
                      : defendantState?.streetAddress
                        ? 'success'
                        : ''} ${defendantState.isAddressLocate ? 'disabled disable-input' : ''}`
                    }
                  />
                  {isError('streetAddress') && !defendantState.isAddressLocate && <p className='error-label'>{errors.streetAddress}</p>}

                  <label className="checkbox-container">
                    <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={defendantState.isAddressNotFound} onChange={() => handleIsAddressNotFound()} />
                    <label className='checkbox-content' htmlFor='isAddressNotFound'>
                      לא מצאתי את הרחוב
                    </label>
                    <span className="checkmark"></span>
                  </label>

                </div>

                :

                <div className='field extra-height'>
                  <label className='input-label'>רחוב*</label>

                  <div
                    ref={refStreet}
                    className={
                      isStreetsDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                    }
                  >
                    <div className='dropdown-input'>
                      <input
                        type="text"
                        placeholder={defendantState.isAddressLocate ? '' : 'הזינו רחוב ובחרו מתוך הרשימה'}
                        onBlur={onBlur}
                        onChange={(event) => {
                          setSearchStreet(event.target.value);
                          setIsStreetsDropdownOpen(true);
                        }}
                        value={searchStreet}
                        onClick={() => { setIsStreetsDropdownOpen(true); }}
                        disabled={defendantState.isAddressLocate}
                        name='streetAddress'
                        autoComplete="new-password"
                        className={`${isError('streetAddress') && !defendantState.isAddressLocate
                          ? 'error'
                          : defendantState?.streetAddress
                            ? 'success'
                            : ''} ${defendantState.isAddressLocate ? 'disabled disable-input' : ''}`
                        }
                      />
                      <button
                        type='button'
                        onClick={() => {
                          setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                        }}
                      >
                        <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                      </button>
                    </div>

                    {isError('streetAddress') && !defendantState.isAddressLocate && <p className='error-label'>{errors.streetAddress}</p>}

                    <div className='dropdown-list'>
                      {cityStreets.filter((option) => option['שם_רחוב'].includes(searchStreet)).map((street, idx) => {
                        return (
                          <div className='dropdown-option' key={idx}>
                            <p
                              onMouseDown={() => {
                                onSetChoose('streetAddress', street['שם_רחוב'])
                                setSearchStreet(street['שם_רחוב'])
                                setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                              }}>
                              {street['שם_רחוב']}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <label className="checkbox-container">
                    <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={defendantState.isAddressNotFound} onChange={() => handleIsAddressNotFound()} />
                    <label className='checkbox-content' htmlFor='isAddressNotFound'>
                      לא מצאתי את הרחוב
                    </label>
                    <span className="checkmark"></span>
                  </label>

                </div>
              }

              <div className='field small' >
                <label className='input-label'>מספר*</label>
                <Field
                  autoComplete="new-password"
                  type="text"
                  disabled={defendantState.isAddressLocate}
                  className={`${isError('streetNumber') && !defendantState.isAddressLocate
                    ? 'small error'
                    : defendantState?.streetNumber
                      ? 'small success'
                      : 'small'} ${defendantState.isAddressLocate ? 'disabled' : ''}`
                  }
                  value={defendantState.streetNumber}
                  name='streetNumber'
                  onChange={handleChangeData}
                />
                {isError('streetNumber') && !defendantState.isAddressLocate && <p className='error-label'>{errors.streetNumber}</p>}
              </div>
              <div className='field small'>
                <label className='input-label'>קומה</label>
                <Field
                  type="text"
                  autoComplete="new-password"
                  disabled={defendantState.isAddressLocate}
                  className={`${defendantState?.floor ? 'small success' : 'small'}
                  ${defendantState.isAddressLocate ? 'disabled' : ''}`
                  }
                  value={defendantState.floor}
                  name='floor'
                  onChange={handleChangeData}
                />
              </div>
              <div className='field small'>
                <label className='input-label'>דירה</label>
                <Field
                  type="text"
                  autoComplete="new-password"
                  disabled={defendantState.isAddressLocate}
                  className={`${defendantState?.apartmentNumber
                    ? 'small success'
                    : 'small'} ${defendantState.isAddressLocate ? 'disabled' : ''}`
                  }
                  value={defendantState.apartmentNumber}
                  name='apartmentNumber'
                  onChange={handleChangeData}
                />
              </div>

              <div className='field small-plus' >
                <div className='postal-code-tooltip'>
                  <label className='input-label'>מיקוד*</label>
                  <CustomTooltip tooltipType={'postalCode'}>
                    <img src={info} width={20} alt="info-icon" className="info-icon" />
                  </CustomTooltip>
                </div>
                <Field
                  autoComplete="new-password"
                  type="number"
                  disabled={defendantState.isAddressLocate}
                  className={`${isError('postalCode') && !defendantState.isAddressLocate
                    ? 'error'
                    : defendantState?.postalCode
                      ? 'success'
                      : ''} ${defendantState.isAddressLocate ? 'disabled' : ''}`
                  }
                  value={defendantState.postalCode}
                  name='postalCode'
                  onChange={handleChangeData}
                />
                {isError('postalCode') && !defendantState.isAddressLocate && <p className='error-label'>{errors.postalCode}</p>}
              </div>

            </div>
            <div className="add-defendant">
              <img
                className="btn-add"
                src='/images/plus.svg'
                alt=''
                onClick={() => {
                  onAddNewDefendant()
                }}
              />
              <h3>הוספת נתבע/ת נוספ/ת</h3>
            </div>
          </>
        }

      </div>

    </div>
  );
};

export default SmallClaimDefendant;
