import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ClaimRecipientPreview } from '../../SmallClaim/ClaimRecipientPreview';
import { removeRecipientId } from '../../../store/actions/dataActions';
import { removeRecipient } from '../../../store/actions/recipientActions';
import { updateErrorModal } from '../../../store/actions/systemActions';
import { claimantSet, defendantSet } from '../../../store/actions/smallClaimActions';

export const SmallClaimRecipients = ({ data, setFormState, setData, formState, setStep, type, submitForm, setSubmitForm, errors }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (submitForm && errors.claimants) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: getErrorTextByType('claimants'),
          submitText: 'אישור',
          isCancel: false
        })
      );
    } else if (submitForm && errors.defendants) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: getErrorTextByType('defendants'),
          submitText: 'אישור',
          isCancel: false
        })
      );
    }
  }, [submitForm]);

  const getErrorTextByType = (type) => {
    switch (type) {
      case 'claimants':
        return 'נדרש למלא פרטי תובע/ת אחד/אחת לפחות'

      case 'defendants':
        return 'נדרש למלא פרטי נתבע/ת אחד/אחת לפחות'

      default:
        break;
    }
  }

  const onRemoveRecipient = (recipientId, type, e) => {
    // dispatch(removeRecipientId(recipientId));
    // dispatch(removeRecipient(recipientId));
    let idx
    if (type === 'claimant') {
      const claimantsUpdated = [...data.claimants]
      idx = data?.claimants.findIndex((claimant) => claimant.id === recipientId)
      claimantsUpdated.splice(idx, 1)
      setData({
        ...data,
        claimants: [...claimantsUpdated]
      });
      claimantsUpdated.length > 0 ? dispatch(claimantSet(claimantsUpdated[0])) : dispatch(claimantSet(null))
    } else if (type === 'defendant') {
      const defendantsUpdated = [...data.defendants]
      idx = data?.defendants.findIndex((defendant) => defendant.id === recipientId)
      defendantsUpdated.splice(idx, 1)
      setData({
        ...data,
        defendants: [...defendantsUpdated]
      });
      defendantsUpdated.length > 0 ? dispatch(defendantSet(defendantsUpdated[0])) : dispatch(defendantSet(null))
    }
  };

  const onEditRecipient = (recipient, type, e) => {
    e.preventDefault();
    e.stopPropagation();

    if (type === 'claimant') {
      dispatch(claimantSet(recipient))
      setFormState((prevState) => ({
        ...prevState,
        nextStep: setNextStepByFormType('SmallClaimClaimant'),
      }));
    } else if (type === 'defendant') {
      dispatch(defendantSet(recipient))
      setFormState((prevState) => ({
        ...prevState,
        nextStep: setNextStepByFormType('SmallClaimDefendant'),
      }));
    }
  };

  const setNextStepByFormType = (type) => {
    let page = null;
    switch (type) {
      case 'SmallClaimClaimant':
        page = 'SmallClaimClaimant'
        break;
      case 'SmallClaimDefendant':
        page = 'SmallClaimDefendant';
        break;
      default:
        page = '';
        break;
    }
    const { formSteps } = formState;
    const idx = formSteps.findIndex((currPage) => currPage === page);
    setStep(idx)
  }

  const getTextByType = (type) => {
    let title = '';
    let addText = ''
    switch (type) {
      // case 'courtSubmission':
      // case 'personalDelivery':
      //   title = 'נמענים'
      //   addText = ' הוספת נמען נוסף'
      //   break;
      // case 'addressLocate':
      // case 'phoneLocate':
      //   title = 'מאותרים'
      //   addText = 'הוספת מאותר נוסף'
      //   break;
      default:
        break;
    }
    return { addText, title }
  }

  const { addText, title } = getTextByType()
  return (
    <div className='main-form recipients small-claim-recipients'>
      <div className='modal-content-container'>
        <h2 className='title'>תובעים</h2>

        {data?.claimants.map((claimant) => {
          return <ClaimRecipientPreview
            key={claimant.id}
            recipient={claimant}
            onRemoveRecipient={onRemoveRecipient}
            onEditRecipient={onEditRecipient}
            type={'claimant'}
          />
        })}

        <div className="add-recipient">
          <img
            className="btn-add"
            src='/images/plus.svg'
            alt=''
            onClick={() => {
              setFormState((prevState) => ({
                ...prevState,
                nextStep: setNextStepByFormType('SmallClaimClaimant'),
                currRecipient: null,
              }));
              dispatch(claimantSet(null))
            }}
          />
          <h3>הוספת תובע/ת נוספ/ת</h3>
        </div>


        <h2 className='title'>נתבעים</h2>

        {data?.defendants.map((defendant) => {
          return <ClaimRecipientPreview
            key={defendant.id}
            recipient={defendant}
            onRemoveRecipient={onRemoveRecipient}
            onEditRecipient={onEditRecipient}
            type={'defendant'}
          />
        })}

        <div className="add-recipient add-defendant">
          <img
            className="btn-add"
            src='/images/plus.svg'
            alt=''
            onClick={() => {
              setFormState((prevState) => ({
                ...prevState,
                nextStep: setNextStepByFormType('SmallClaimDefendant'),
                currRecipient: null,
              }));
              dispatch(defendantSet(null))
            }}
          />
          <h3>הוספת נתבע/ת נוספ/ת</h3>
        </div>

      </div>
    </div>
  );
};

export default SmallClaimRecipients;
