import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import { getDownloadURL, uploadBytes, getMetadata } from "firebase/storage"
import docImg from '../../images/doc.svg'
import { TailSpin } from 'react-loader-spinner'
import { setIsLoading } from '../../store/actions/systemActions';
import { useViewport } from '../../Hooks/useViewport'


export const UserActionDoc = ({ action, showProofsAndDocuments }) => {
    const [docs, setDocs] = useState([]);
    const activeUser = useSelector((state) => state.userReducer.user);
    const isLoading = useSelector((state) => state.systemReducer.isLoading)
    const dispatch = useDispatch();

    const { width } = useViewport();
    const breakpoint = 1111;
    const isMobile = width < breakpoint;

    // useEffect(() => {
    //     const allDocs = action.fileLinks ? [...action.fileLinks] : []
    //     const storage = getStorage();
    //     const listRef = ref(storage, `${activeUser.uid}/${action.id}/`);
    //     dispatch(setIsLoading(true))
    //     listAll(listRef)
    //         .then((res) => {
    //             res.prefixes.forEach((folderRef) => {
    //                 // All the prefixes under listRef.
    //                 // You may call listAll() recursively on them.
    //             });
    //             res.items.forEach((fileRef) => {
    //                 const doc = {}
    //                 doc.name = fileRef.name
    //                 getDownloadURL(fileRef).then((downloadURL) => {
    //                     doc.url = downloadURL
    //                 })
    //                 getMetadata(fileRef)
    //                     .then((metadata) => {
    //                         doc.isNewFile = metadata?.customMetadata?.isNewFile === 'true' ? true : false
    //                         doc.lastModified = metadata.updated
    //                         doc.contentType = metadata.contentType
    //                     })
    //                     .finally(() => {
    //                         allDocs.push(doc)
    //                         setDocs([...allDocs])
    //                         // dispatch(setIsLoading(false))
    //                     })
    //                     .catch((err) => {
    //                         console.log('error while getting file metadata', err)
    //                     });
    //             });
    //         }).finally(() => {
    //             setDocs([...allDocs])
    //             dispatch(setIsLoading(false))
    //         })
    //         .catch((err) => {
    //             console.log('error while getting listRef', err)
    //         });
    // }, [])

    useEffect(() => {
        const storage = getStorage();
        const listRef = ref(storage, `${activeUser.uid}/${action.id}/`);
        dispatch(setIsLoading(true));

        const allDocs = action.fileLinks ? [...action.fileLinks] : [];

        listAll(listRef)
            .then((res) => {
                const promises = res.items.map(async (fileRef) => {
                    const doc = {};
                    doc.name = fileRef.name;

                    const downloadURLPromise = getDownloadURL(fileRef)
                        .then((downloadURL) => {
                            doc.url = downloadURL;
                        });

                    const metadataPromise = getMetadata(fileRef)
                        .then((metadata) => {
                            doc.isNewFile = metadata?.customMetadata?.isNewFile === 'true';
                            doc.lastModified = metadata.updated;
                            doc.contentType = metadata.contentType;
                        })
                        .catch((err) => {
                            console.log('error while getting file metadata', err);
                        });

                    return Promise.all([downloadURLPromise, metadataPromise])
                        .then(() => doc);
                });

                return Promise.all(promises);
            })
            .then((docs) => {
                setDocs([...allDocs, ...docs]);
                dispatch(setIsLoading(false));
            })
            .catch((err) => {
                console.log('error while getting listRef', err);
            });
    }, []);

    const openFile = (doc) => {
        window.open(doc.url, '_blank');
    }

    const getTitle = () => {
        switch (showProofsAndDocuments) {
            case 'printDoc':
                return 'מסמכים להדפסה'
            case 'proof':
                return 'הוכחות ביצוע'
            default:
                return ''
        }
    }

    return (
        <div className='action-content-container doc-content'>
            {isMobile ?
                <h2 className='summary-titles'>מסמכים להדפסה</h2>
                :
                <h2 className='summary-titles'>{getTitle()}</h2>
            }

            <div className='docs-container'>
                {(showProofsAndDocuments === 'printDoc' || showProofsAndDocuments === 'both') &&
                    <div className={`docs ${isLoading ? 'content-center' : ''}`}>
                        {isLoading ?
                            <div className='spinner'>
                                <TailSpin
                                    heigth="100"
                                    width="100"
                                    color='#4e71ff'
                                    ariaLabel='loading'
                                />
                            </div>
                            :
                            docs.length ?
                                (docs.map((doc, idx) => {
                                    if (!doc.isNewFile) return (
                                        <div key={doc.url} className={`doc-card ${doc.contentType === 'application/pdf' ? 'iframe-card' : ''}`} onClick={() => openFile(doc)}>
                                            {((doc.contentType === 'image/png') || (doc.contentType === 'image/jpeg')) ? <img src={doc.url} /> : (doc.contentType === 'application/pdf' ? <div className='doc-iframe'>< iframe src={doc.url} /> <a></a> </div> : <img src={docImg} alt="document" />)}
                                            <p className='doc-title'>{doc.name.split('.')[0]}</p>
                                            <p className='doc-lastModified-title'>{doc.lastModified?.slice(0, 10)}</p>
                                        </div>
                                    )
                                }))
                                :
                                <></>
                        }
                    </div>
                }


                {(showProofsAndDocuments === 'proof' || showProofsAndDocuments === 'both') &&
                    <div className={`docs ${isLoading ? 'content-center' : ''}`}>
                        {isMobile &&
                            <h2 className='summary-titles'>הוכחות ביצוע</h2>
                        }

                        {isLoading ?
                            <div className='spinner'>
                                <TailSpin
                                    heigth="100"
                                    width="100"
                                    color='#4e71ff'
                                    ariaLabel='loading'
                                />
                            </div>
                            :
                            <>
                                {docs.filter(doc => doc.isNewFile).length === 0 ? (
                                    <p>עוד לא הועלו הוכחות ביצוע</p>
                                ) : (
                                    docs.map((doc, idx) => {
                                        if (doc.isNewFile) return (
                                            <div key={doc.url} className={`doc-card ${doc.contentType === 'application/pdf' ? 'iframe-card' : ''}`} onClick={() => openFile(doc)}>
                                                {((doc.contentType === 'image/png') || (doc.contentType === 'image/jpeg')) ? <img src={doc.url} /> : (doc.contentType === 'application/pdf' ? <div className='doc-iframe'>< iframe src={doc.url} /> <a></a> </div> : <img src={docImg} alt="document" />)}
                                                <p className='doc-title'>{doc.name.split('.')[0]}</p>
                                                <p className='doc-lastModified-title'>{doc.lastModified?.slice(0, 10)}</p>
                                            </div>
                                        )
                                    })
                                )}
                            </>
                        }
                    </div>
                }
            </div>
            {/* {
                (width > breakpoint) &&
                <>
                    <UserMsgs actionNotifications={notifications} isNotificationsList={true} />
                </>
            } */}
        </div >
    )
}