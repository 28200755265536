import React from 'react';
import close from '../../images/close.svg'
import { useDispatch, useSelector } from 'react-redux';
import { updateSystemData } from '../../store/actions/systemActions';
import { useNavigate } from 'react-router-dom'
import { getAuth } from "firebase/auth";

import TagManager from 'react-gtm-module'

export function PopUpModalOrder() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = getAuth().currentUser
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const tarckingData = useSelector((state) => state.orderTrackingReducer)

    if (tarckingData?.orderPrice) {
        const tagManagerArgs = {
            gtmId: 'GTM-N57K36G',
            dataLayer: {
                orderPrice: tarckingData?.orderPrice,
            }
        }
        TagManager.initialize(tagManagerArgs)
    }
    const setModalClose = () => {
        // dispatch(updateSystemData({
        //     ...systemData, isPopUpModalOrder: false
        // }));
        navigate('/')
    };

    return (
        <div className="pop-up-modal-order">
            <div className="system-msg">
                <img className="btn-close" onClick={setModalClose} src={close} alt="close" />
                <div className="msg-content">
                    <h3>ההזמנה שלך נקלטה בהצלחה!</h3>
                    <p className="decription">
                        הצוות שלנו קיבל את ההזמנה שלך ומתחיל לטפל בה כעת.  <br />
                        אישור הזמנה נשלח לחשבון הדוא"ל שלך
                    </p>
                    <div className="number-order">
                        <p className="number-order-text">
                            מספר ההזמנה שלך
                        </p>
                        <p className="number-order-text" style={{ direction: "ltr" }}>
                            {tarckingData.orderId}
                        </p>
                    </div>
                </div>
                {auth?.isAnonymous || auth?.accessToken ?
                    <button type='button' className="main-btn long"
                        onClick={setModalClose}>
                        חזרה למסך ההזמנה
                    </button>
                    :
                    <button type='button' className="main-btn long"
                        onClick={() => { navigate('/user/orders') }}>
                        מעבר להזמנות שלי
                    </button>
                }
            </div>
        </div>
    );
}


<br />