import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import remove from '../../../images/remove-grey.svg';
import edit from '../../../images/edit.svg';
import { updateErrorModal } from '../../../store/actions/systemActions';
import { getStorage, ref, deleteObject } from "firebase/storage";

//Old landRegistration
export const ActionsSummary = ({ data, setData, setFormState, setStep, formState, type, errors, submitForm, setSubmitForm }) => {
    const dispatch = useDispatch()
    const activeUser = useSelector((state) => state.userReducer.user);

    useEffect(() => {
        setData({ ...data, isEdit: false, isOtherSubmitter: false })
    }, [])

    useEffect(() => {
        if (submitForm && errors.isPassActionSummary) {
            setSubmitForm(false);
            dispatch(
                updateErrorModal({
                    title: 'שגיאה',
                    errorText: 'נדרש לבחור פעולה אחת לפחות. לאיסוף תיק מהלשכה או הפקדת תיק בלשכה',
                    submitText: 'אישור',
                    isCancel: false
                })
            );
        }
    }, [errors, submitForm]);

    const setNextStepByFormType = () => {
        let page = '';
        switch (type) {
            case 'landRegistration':
                setData({
                    ...data,
                    actionInOffice: (data.actionInOffice === 'depositFile') ? "collectFile" : data.actionInOffice,
                    isFromDeposit: 'back',
                    isOtherSubmitter: true,
                });
                setStep(2);
                break;

            default:
                break;
        }
        return page;
    }

    const onRemoveSubmitter = async (submitterId) => {
        const updatedSubmitters = data.collectFiles.filter((submitter) => submitter.id !== submitterId);
        setData({ ...data, collectFiles: updatedSubmitters, currSubmiterIdx: data.collectFiles.length - 2, actionInOffice: (updatedSubmitters.length === 0 ? 'depositFile' : data.actionInOffice) });
        const storage = getStorage();
        const fileRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/${data.collectFiles[data.currSubmiterIdx]?.name || ''}-${data.collectFiles[data.currSubmiterIdx]?.idNum || ''}-ייפוי כח`);
        await deleteObject(fileRef)
    }

    const onRemoveDeposit = () => {
        delete data.depositFiles
        setData({ ...data });
    }

    const onEditDeposit = () => {
        switch (type) {
            case 'landRegistration':
                setData({ ...data, actionInOffice: "depositFile" });
                setStep(2);
                break;
            default:
                break;
        }
    };

    const onEditSubmitter = (submitterIdx, e) => {
        switch (type) {
            case 'landRegistration':
                setData({ ...data, currSubmiterIdx: submitterIdx, isEdit: true, actionInOffice: "collectFile" });
                setStep(2);
                break;

            default:
                break;
        }
    };

    return (
        <div className='main-form actions-summary'>
            <div className='modal-content-container'>
                <h2 className='large-title'>סיכום פרטי המגישים והתיקים המבוקשים</h2>
                <div className="summary-content">
                    {data?.depositFiles?.filesCount > 0 && <div className="deposit">
                        <div className="deposit-header">
                            <h3>תיקים להפקדה</h3>
                            <div className='btn-container'>
                                <img
                                    className='icon-edit'
                                    src={edit}
                                    alt='info'
                                    onClick={() => onEditDeposit()}
                                />
                                <img
                                    src={remove}
                                    alt='remove'
                                    onClick={() => onRemoveDeposit()}
                                />
                            </div>
                        </div>
                        <p>מספר תיקים להפקדה: {data.depositFiles.filesCount}</p>
                        {data.depositFiles?.toll > 0 && <p>אגרה לתשלום: {data.depositFiles.toll} שח</p>}
                    </div>}
                    <div className="collect">
                        {(data.collectFiles?.length > 0) && <h3>תיקים לאיסוף</h3>}

                        {data?.collectFiles?.map((submiter, idx) => {
                            return <div className="deposit-details">
                                <div className="submiter-details">
                                    <h4>שם המגיש: {submiter.name}</h4>
                                    <h4>תעודת זהות: {submiter.idNum}</h4>
                                    <div className='btn-container'>
                                        <img
                                            className='icon-edit'
                                            src={edit}
                                            alt='info'
                                            onClick={(e) => onEditSubmitter(idx, e)}
                                        />
                                        <img
                                            src={remove}
                                            alt='remove'
                                            onClick={(e) => onRemoveSubmitter(submiter.id)}
                                        />
                                    </div>
                                </div>
                                <div className='submitter-files-list'>
                                    {submiter?.files?.map(file => {
                                        return <div className='submitter-file-preview' onClick={(e) => onEditSubmitter(idx, e)}>
                                            <div className='title'>
                                                <p>מספר תיק: {file.fileNum}</p>
                                                <div className='btn-container'>
                                                    <img
                                                        className='icon-edit'
                                                        src={edit}
                                                        alt='info'
                                                        onClick={(e) => onEditSubmitter(idx, e)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="details">
                                                <p>גוש: {file?.block || ''}</p>
                                                <p>חלקה: {file?.lot || ''}</p>
                                                <p>תת חלקה: {file?.subLot || ''}</p>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        })
                        }
                    </div>
                    <div className="add-recipient" onClick={() => {
                        setFormState((prevState) => ({
                            ...prevState,
                            nextStep: setNextStepByFormType(),
                        }));
                    }}>
                        <img
                            className="btn-add"
                            src='/images/plus.svg'
                            alt=''
                        />
                        <h3>{(!data.collectFiles?.length) ? "הוספת תיקים לאיסוף" : "הוספת מגיש נוסף"}</h3>
                    </div>
                </div>
            </div>
        </div >


    )
}
