const INITIAL_STATE = {
    currAction: {},
    currOrder: {},
    userOrders: [],
    userNotifications: []
}
export function userDataReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "SET_USER_ACTION":
            return {
                ...state,
                currAction: action.currAction
            }
        case "SET_USER_ORDER":
            return {
                ...state,
                currOrder: action.currOrder
            }
        case "SET_USER_ORDERS":
            return {
                ...state,
                userOrders: [...action.userOrders]
            }
        case "SET_USER_NOTIFICATIONS":
            return {
                ...state,
                userNotifications: [...action.userNotifications]
            }


        default:
            return state
    }
}