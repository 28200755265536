import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Close } from '../../images/close.svg'
import { ReactComponent as CustomerService } from '../../images/CustomerService.svg'
import { UserActionSummary } from './UserActionSummary';
import { UserActionDoc } from './UserActionDoc';
import { updateSystemData, setMobileState } from '../../store/actions/systemActions';
import { UserMsgs } from '../UserDashboard/UserMsgs'
import { UserActionTab } from './UserActionTab';
import { useViewport } from '../../Hooks/useViewport'
import { useNavigate } from "react-router-dom";
// import { ReactComponent as Back } from '../../images/ArrowDrop.svg'
import { ReactComponent as Back } from '../../images/ArrowBack.svg'
import bell from "../../images/bell_iconGray.svg";
import { useParams } from "react-router-dom";
import { actionSet, OrderSet } from '../../store/actions/userDataActions';
import { onCallNotifications } from '../../integrations/firebase';

export const UserActionInfo = () => {
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const [tab, setTab] = useState('summary');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userMsgsRef = useRef();
    const userMsgsRef2 = useRef();
    const { width } = useViewport();
    const breakpoint = 1111;
    const isMobile = width < breakpoint;
    const action = useSelector((state) => state.userDataReducer.currAction)
    const order = useSelector((state) => state.userDataReducer.currOrder)
    const orders = useSelector((state) => state.userDataReducer.userOrders)
    const activeUser = useSelector((state) => state.userReducer.user);
    const { actionId } = useParams();

    const notifications = useSelector((state) => state.userDataReducer.userNotifications);
    const [actionNotifications, setActionNotifications] = useState([]);
    const [shake, setShake] = useState('');
    const [notificationsNumber, setNotificationsNumber] = useState(0)
    const [isUserMsgsOpen, setIsUserMsgsOpen] = useState(false);

    useEffect(() => {
        const onBodyClick = (event) => {
            if (userMsgsRef?.current?.contains(event.target) || userMsgsRef2?.current?.contains(event.target)) {
                return;
            }
            setIsUserMsgsOpen(false);
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });

        return () => {
            document.body.removeEventListener("click", onBodyClick, { capture: true });
        };
    }, []);

    useEffect(() => {
        getUserNotify()
        dispatch(updateSystemData({
            ...systemData,
            isFormModalOpen: true
        }));
        return () => {
            dispatch(updateSystemData({
                ...systemData,
                isFormModalOpen: false
            }));
        };
    }, []);

    useEffect(() => {
        getUserNotify()
    }, [notifications, action])

    useEffect(() => {
        const isEmpty = Object.keys(action).length === 0;
        if (isEmpty) {
            let currOrder = {}
            let currAction = {}
            orders.forEach(order => {
                order.actionData.forEach(action => {
                    if (action.id === actionId) {
                        currAction = action
                        currOrder = order
                    }
                })
            })
            dispatch(actionSet(currAction));
            dispatch(OrderSet(currOrder));
        }
    }, [orders]);


    const getUserNotify = async () => {
        if (!notifications) return

        const notificationsOfAction = notifications.filter(
            (notification) => notification?.actionId === action.id
        );

        const notificationsCount = notificationsOfAction.reduce(
            (count, notification) =>
                count + (!notification?.userInteractions?.[activeUser.uid]?.isOpened ? 1 : 0),
            0
        );

        setActionNotifications([...notificationsOfAction])
        setNotificationsNumber(notificationsCount)
    }

    const onToggleTab = (tab) => {
        setTab(tab)
    }

    const onCancelOrder = () => {
        window.location.href = `mailto:team@delawvery.com?subject=בקשה לביטול הזמנה מספר ${order.orderId || ''}&body=.היי, אבקש לבטל את ההזמנה שבנושא`;
    }

    const onCustomerService = () => {
        window.open(
            'https://api.whatsapp.com/send/?phone=972537006707&text&app_absent=0',
            '_blank'
        );
    }

    const onCloseModal = () => {
        navigate('/user/orders')
        dispatch(actionSet({}));
        dispatch(OrderSet({}));
    }

    const onOpenNotifications = async (ev) => {
        setIsUserMsgsOpen(prevState => !prevState);

        let isChanged = false
        setNotificationsNumber(0)

        actionNotifications.forEach(notification => {
            if (!notification?.userInteractions?.[activeUser.uid]?.isOpened) isChanged = true
        });
        if (!isChanged) return

        const objToSend = {
            userId: activeUser.uid,
            actionId: action.id,
            companyId: activeUser.companyId || activeUser.uid,
            orderId: order.orderId,
            type: 'updateActionNotificationsOpen'
        }
        await onCallNotifications(objToSend)
    }

    const isActionEmpty = Object.keys(action).length === 0;
    const isOrderEmpty = Object.keys(order).length === 0;
    if (isActionEmpty) return <></>
    if (isOrderEmpty) return <></>

    return (
        <div className='user-orders'>
            <div className="main-modal open">
                <div className={`modal-header header-border ${(isMobile) ? 'mobile-header' : ''}`}>
                    <div className={`modal-title ${(isMobile) ? 'mobile-title' : ''}`}>
                        {action.formData ?
                            <>
                                <img src={action.formData.image || ''} alt='' />
                                <h3 className="modal-title-text">{action.formData.title || ''} </h3>
                            </>
                            :
                            <></>
                            // <>
                            //     <img src={order?.cart?.orders[idx]?.action?.formImage || ''} alt='' />
                            //     <h3 className="modal-title-text">{order?.cart?.orders[idx]?.action?.formTitle || ''} </h3>
                            //     {(width > breakpoint) &&
                            //         <p className="modal-title-sub">{order?.cart?.orders[idx]?.action?.formSubtitle ? `(${order?.cart?.orders[idx]?.action?.formSubtitle})` : ''}</p>
                            //     }
                            // </>
                        }
                    </div>
                    <div className="modal-title center">
                        <h3 className="modal-title-text order-title">הזמנה מס' {(isMobile) && <br />} {`#${order.orderId}` || ''}</h3>
                    </div>
                    <div className={`modal-btn-container ${(isMobile) ? 'mobile-btns' : ''}`}>
                        {(width > breakpoint) &&
                            <div className={`action-btns ${action.isDone ? 'action-done' : ''}`}>
                                <div className="icon-wrapper" onClick={() => {
                                    setShake('shake');
                                    setTimeout(() => {
                                        setShake('')
                                    }, 2000);
                                }}
                                    data-number={notificationsNumber}
                                >
                                    <div ref={userMsgsRef} className='bell-icon-container' onClick={(ev) => { onOpenNotifications(ev) }}>
                                        <img src={bell} alt="" className={`bell-icon ${shake}`} />
                                    </div>
                                    <div ref={userMsgsRef2} className={`UserMsgs-container ${isUserMsgsOpen ? 'open' : ''}`}>
                                        <UserMsgs actionNotifications={actionNotifications} isNotificationsList={true} isOpen={isUserMsgsOpen} setIsUserMsgsOpen={setIsUserMsgsOpen} />
                                    </div>
                                </div>
                                {!action.isDone &&
                                    <button className='btn cancel' onClick={onCancelOrder}>ביטול הזמנה</button>
                                }
                                <button className='btn service' onClick={onCustomerService}>
                                    <CustomerService />
                                    <span>
                                        פניה לשירות לקוחות
                                    </span>
                                </button>
                            </div>
                        }
                        {(width > breakpoint) ? <Close onClick={() => { onCloseModal() }} /> : <Back className='arrow-back' onClick={onCloseModal} />}
                    </div>
                </div>
                <div className='modal-action-content'>
                    <div className='tab-and-info'>
                        {isMobile &&
                            <UserActionTab tab={tab} onToggleTab={onToggleTab} action={action} />
                        }

                        <div className='action-info-cmps'>
                            {
                                tab === 'summary' ? <UserActionSummary action={action} notifications={actionNotifications} order={order} />
                                    : (tab === 'doc' ? <UserActionDoc action={action} showProofsAndDocuments={'both'} />
                                        : (tab === 'notifications' ? <UserMsgs actionNotifications={actionNotifications} isNotificationsList={true} /> : <></>))
                            }
                            {(width > breakpoint) &&
                                <>
                                    {/* <div className='border' /> */}
                                    {/* <UserMsgs actionNotifications={actionNotifications} isNotificationsList={true} /> */}
                                    {/* MOVED DOWN FOR DEPLOY*/}
                                </>
                            }
                        </div>
                    </div>
                </div>
                {isMobile &&
                    <div className='action-btns'>
                        {!action.isDone &&
                            <button className='btn cancel' onClick={onCancelOrder}>ביטול הזמנה</button>
                        }
                        <button className={`btn service ${action.isDone ? 'full-width' : ''}`} onClick={onCustomerService}>
                            <CustomerService />
                            <span>
                                שירות לקוחות
                            </span>
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}
