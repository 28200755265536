import React, { useState, useRef, useCallback, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { setFiles, } from '../../../store/actions/fileActions';
import { getStorage, ref, listAll, deleteObject, uploadString, uploadBytes, getDownloadURL } from "firebase/storage";
import { DocumentsReadyUpdate } from './DocumentsReadyUpdate';
import { AddressCollectDocumentsUpdate } from './AddressCollectDocumentsUpdate';
import { UploadFileUpdate } from './UploadFileUpdate';
import { DeliveryPackageSize } from './DeliveryPackageSize';

export const CollectDocumentsOrUploadFile2 = ({ setData, data, type, isError, errors, handleChanges, setTouch, touch, submitForm, setSubmitForm, isTranslation, pageName }) => {
  const refs = useRef([])
  const cmpRef = useRef();
  // const [alignment, setAlignment] = useState('right');
  const [alignment, setAlignment] = useState(data?.collectionMethod === 'upload' ? 'left' : 'right');
  const totalRecipients = useSelector((state) => state.recipientReducer.Recipients);
  const orderRecipientsIds = useSelector((state) => state.dataReducer.data.recipients);
  const myRecipients = totalRecipients.filter((recipient) => orderRecipientsIds.includes(recipient._id));
  const activeUser = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const optionA = 'איסוף עם שליח';
  const optionB = 'העלאת קובץ';
  const withPackageSize = ['personalDelivery', 'officeDelivery', 'courtSubmission']
  const withoutBindings = ['NotarizedTranslation', 'inheritanceRegistration', 'landRegistration', 'companiesRegistration', 'partnershipsRegistration', 'associationsAndCompanies']

  useEffect(() => {
    refs.current = refs.current.slice(0, myRecipients.length);
  }, [myRecipients.length, refs]);
  useEffect(() => {
    const { collectionMethod } = data;
    // setData({
    //   ...data,
    //   collectionMethod: collectionMethod ? collectionMethod : 'pickup',
    // });
    if (!collectionMethod) {
      setData({ ...data, collectionMethod: 'upload', isUpdatedUploadFileSummarize: true });
    }

    if (!collectionMethod) {
      setAlignment('left');
    } else
      setAlignment(
        collectionMethod && collectionMethod === 'pickup' ? 'right' : 'left'
      );
  }, []);

  const handleChangeData = async (event, newAlignment) => {
    event.preventDefault();
    const { name, value } = event.target;
    setAlignment(newAlignment);
    if (!(value === 'pickup')) {
      //Check here for specific users
      setData({
        ...data,
        [name]: value,
        collectionAddress: {},
        isVerySmallPackage: false,
        isSmallPackage: false,
        isBigPackage: false,
        isDeliveryWithCar: false,
        isUpdatedUploadFileSummarize: true
      });
      delete data.collectionAddress;
    }
    else {
      delete data.pages;
      delete data.bindings;
      setData({ ...data, [name]: value, pages: 0, bindings: 0, printingDetails: {}, isUpdatedUploadFileSummarize: false });
      const storage = await getStorage();
      const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}`);
      const filesList = await listAll(storageRef)
      filesList.items.map(async (file) => {
        const fileSplitLocation = file._location.path_.split('/')
        const fileName = fileSplitLocation[fileSplitLocation.length - 1]
        if (!fileName.includes('אישור מסירה')) {
          const storage = getStorage();
          const fileRef = ref(storage, file._location.path_);
          await deleteObject(fileRef)
        }
      })
      dispatch(setFiles([]))
    }
  };

  const scrollDown = () => {
    if (cmpRef.current) {
      setTimeout(() => {
        const div = cmpRef.current;
        div.scrollTo({
          top: div.scrollHeight,
          behavior: 'smooth'
        });
      }, 250);
    }
  };



  return (
    <div className='main-form collect-or-upload' ref={cmpRef}>
      <div className='modal-content-container block '>
        <h2 className='large-title main-title'>כיצד תעדיפו להעביר את המסמכים לטיפולנו?</h2>
        <p className='subtitle'>
          איסוף באמצעות שליח או העלאת קובץ להדפסה
          {withoutBindings.includes(type) ? ' ' : ' / כריכה / ודגלונים '}
          על ידנו
        </p>
        <div className='blockSelect add-line'>
          <div className={`form-main-toggle ${alignment} collect-upload-toggle`}>
            <span></span>
            <button
              name='collectionMethod'
              value='pickup'
              onClick={(ev) => handleChangeData(ev, 'right')}
            >
              {optionA}
            </button>
            <button
              name='collectionMethod'
              value='upload'
              onClick={(ev) => handleChangeData(ev, 'left')}
            >
              {optionB}
            </button>
          </div>
        </div>

        <div className='cmp-line' />
        {alignment === 'right' ?
          <>
            <DocumentsReadyUpdate data={data} setData={setData} type={type} />
            {withPackageSize.includes(type) &&
              <>
                <div className='cmp-line' />
                <DeliveryPackageSize data={data} setData={setData} type={type} isError={isError} errors={errors} />
              </>}
            <div className='cmp-line' />
            <AddressCollectDocumentsUpdate data={data} setData={setData} isError={isError} errors={errors} handleChanges={handleChanges} type={type} setTouch={setTouch} touch={touch} scrollDown={scrollDown} />
          </> :
          alignment === 'left' ? <UploadFileUpdate data={data} setData={setData} errors={errors} submitForm={submitForm} setSubmitForm={setSubmitForm}
            isTranslation={isTranslation} pageName={pageName} type={type} /> : <></>}

      </div>
    </div >
  );
};

export default CollectDocumentsOrUploadFile2;
