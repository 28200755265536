
import { createTheme,} from '@mui/material/styles';


export  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 300,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1536,
      },
    },
  });