// npm : https://www.npmjs.com/package/react-dropzone
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeFiles,
  setFiles,
  addFiles
} from '../../../store/actions/fileActions';
import arrow from '../../../images/arrowdropdown.png';
import eye from '../../../images/eye.png';
import remove from '../../../images/remove1.png';
import copypaste from '../../../images/copypaste.png';
import { Field } from 'formik';
import { updateErrorModal, setIsLoading } from '../../../store/actions/systemActions';
import doc from '../../../images/doc.svg'
import { useViewport } from '../../../Hooks/useViewport'
import { ClipboardPowerOfAttorney } from '../../Utils/ClipboardPowerOfAttorney'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";
import { getNumberOfPagesFromFile, callUserAction } from '../../../integrations/firebase';
import { TailSpin } from 'react-loader-spinner'
import { RecipientCopies } from './PageUtils/RecipientCopies'
import { Tooltip } from '../../Utils/Tooltip'
import { TooltipTriangle } from '../../Utils/TooltipTriangle'
import { StyledChoice } from './PageUtils/StyledChoice';
import { deliveryUploadOptions } from "../../../consts/data";


export function UploadFileUpdate({ data, setData, errors, submitForm, setSubmitForm, isTranslation, pageName, type }) {
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.userReducer.user);
  const totalRecipients = useSelector((state) => state.recipientReducer.Recipients);
  const orderRecipientsIds = useSelector((state) => state.dataReducer.data.recipients);
  let myRecipients = totalRecipients.filter((recipient) => orderRecipientsIds.includes(recipient._id));

  const [pagesCount, setPagesCount] = useState(data?.printingDetails?.pagesCount || 0);
  const [isCopied, setIsCopied] = useState(false);
  const isLoading = useSelector((state) => state.systemReducer.isLoading);
  const [alignment, setAlignment] = useState(data.printingDetails?.isColourful ? 'left' : 'right');

  const optionA = 'שחור/לבן';
  const optionB = 'צבעוני';
  const tooltipTxt = 'ניתן להעלות קבצי שמע ו/או קבצי וידאו ואנו נדאג להעבירם לדיסק און קי (Disk On Key) ולהגישו לבית המשפט / למסור לצדדים'
  const registrations = ['inheritanceRegistration', 'companiesRegistration', 'partnershipsRegistration', 'associationsAndCompanies']
  const audioFileForms = ['courtSubmission', 'printingBinding']

  if (type === 'courtSubmission') {
    const isShowCity = data?.courtAddressDetails?.courtName.includes(data?.courtAddressDetails?.city)
    const courtRecipient = {
      id: 'courtId',
      _id: 'courtId',
      officeName: `${data?.courtAddressDetails?.courtName}${isShowCity ? '' : ` ב${data?.courtAddressDetails?.city}`}`,
    }
    myRecipients.unshift(courtRecipient);
  } else if (type === 'printingBinding') {
    const recipient = {
      id: 'printingBindingId',
      _id: 'printingBindingId',
      personName: ``,
    }
    myRecipients.unshift(recipient);
  } else if (type === 'landSupervisor') {
    const recipient = {
      id: 'landSupervisorId',
      _id: 'landSupervisorId',
    }
    myRecipients.unshift(recipient);
  } else if (registrations.includes(type)) {
    const branchRecipient = {
      id: 'branchId',
      _id: 'branchId',
      officeName: `לשכת ${data?.branch || ''}`,
    }
    myRecipients.unshift(branchRecipient);
  }

  let files = useSelector((state) => state.fileReducer.Files);
  if ((type === 'landRegistration')) {
    if (pageName === 'UploadFile') {
      files = files.filter(file => !file.name.includes('ייפוי כח'));
    } else {
      files = files.filter(file => file.name.includes('ייפוי כח'));
    }
  }
  const { width } = useViewport();
  const breakpoint = 767;

  //No need for now
  // useEffect(() => {
  //   setData(isTranslation ? { ...data, isTranslation } : { ...data, });
  // }, []);


  useEffect(() => {
    let printPricePerUnit = formatNumber((calculatePrice() || 0).toFixed(1))

    const printTxt = renderCopiesDetails();


    let recipientsCopies = {};

    if (!data?.printingDetails?.recipientsCopies) {
      recipientsCopies = myRecipients.reduce((acc, recipient) => {
        acc[recipient._id] = 1;
        return acc;
      }, {});
    } else {
      recipientsCopies = { ...data?.printingDetails?.recipientsCopies };

      // Remove recipients that are not present in myRecipients
      Object.keys(recipientsCopies).forEach((key) => {
        if (!myRecipients.find((recipient) => recipient._id === key)) {
          delete recipientsCopies[key];
        }
      });

      // Add recipients that are present in myRecipients but not in recipientsCopies
      myRecipients.forEach((recipient) => {
        if (!recipientsCopies.hasOwnProperty(recipient._id)) {
          recipientsCopies[recipient._id] = 1;
        }
      });
    }


    const copiesCount = Object.values(recipientsCopies)
      .reduce((total, recipientCopies) => total + recipientCopies, 0);

    let printPrice = formatNumber((printPricePerUnit * copiesCount).toFixed(1))

    const printingDetails = { ...data?.printingDetails, printPrice, printPricePerUnit, pagesCount, copiesCount, printTxt, recipientsCopies }
    setData({ ...data, printingDetails });
  }, [pagesCount, data?.printingDetails?.isColourful, data?.printingDetails?.isSpiralBinding, data?.printingDetails?.isDividers, data?.printingDetails?.isBindingStrip, data?.isAudioFileAdded
  ]);


  const formatNumber = (num) => {
    // Convert the number to a string with one decimal place
    let formattedNum = Number.parseFloat(num).toFixed(1);

    // Remove any trailing zeros if they exist
    if (formattedNum.indexOf('.') !== -1) {
      formattedNum = formattedNum.replace(/\.?0+$/, '');
    }

    // Convert the formatted number back to a number
    return Number.parseFloat(formattedNum);
  }

  useEffect(() => {
    async function getFilesFromStorage() {
      try {
        dispatch(setIsLoading(true))
        const storage = await getStorage();
        const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}`);
        const filesList = await listAll(storageRef)
        const files = filesList.items.map(async (file) => {
          const str = getStorage();
          const refs = ref(str, file._location.path_);
          const uploadedFile = {
            name: file.name,
            url: await getDownloadURL(refs, file.fullPath),
            _id: file.fullPath
          }
          // if (type === 'landSupervisor') uploadedFile['formType'] = 'landSupervisor'
          return uploadedFile
        })
        if (files.length === 0) {
          dispatch(setIsLoading(false))
        }

        Promise.all(files).then((values) => {
          if (type === 'landRegistration' && pageName !== 'UploadFile') {
            values = values.filter(file => file.name.includes(data.collectFiles[data.currSubmiterIdx].idNum))
          }
          dispatch(setFiles(values))
          dispatch(setIsLoading(false))

        });
      } catch (error) {
        console.log('%c  error:', 'color: white;background: red;', error);
      } finally {
        dispatch(setIsLoading(false))
      }

    }
    getFilesFromStorage()
  }, [])

  useEffect(() => {
    async function clearFiles() {
      const storage = await getStorage();
      const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}`);
      const filesList = await listAll(storageRef)
      filesList.items.map(async (file) => {
        const storage = getStorage();
        const fileRef = ref(storage, file._location.path_);
        await deleteObject(fileRef)
      })
      dispatch(setFiles([]))
    }
    if ((type === 'apostilSubmission' && !isTranslation)) clearFiles();
  }, [isTranslation])

  useEffect(() => {
    if (type === 'landRegistration' && pageName === 'UploadFile' && submitForm && files.length === 0) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'חובה להעלות את מכתב/י הדחייה',
          submitText: 'אישור',
          isCancel: false
        })
      );
    }
    else if ((pageName !== 'NotarizedTranslation') && (data?.isFirstSubmission !== 'collect') && submitForm && files.length === 0) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'לא הועלו קבצים להדפסה',
          submitText: 'אישור',
          isCancel: false
        })
      );
    } else if ((pageName !== 'NotarizedTranslation') && submitForm && errors.pages) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'לא נבחרו כמות הדפים להדפסה',
          submitText: 'אישור',
          isCancel: false
        })
      );
    } else if (files.length === 0 && submitForm && (type === 'courtSubmission')) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'לא הועלה אף קובץ, כדי להמשיך יש להעלות קובץ.',
          submitText: 'אישור',
          isCancel: false
        })
      );
    } else if (data?.printingDetails?.isBindingStrip && !data?.printingDetails?.isSpiralBinding) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'כדי להוסיף פס תיוק יש להוסיף כריכה',
          submitText: 'אישור',
          isCancel: false
        })
      );
    } else if (submitForm && files.length && data?.printingDetails?.pagesCount === 0) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'יש להזין מספר עמודים',
          submitText: 'אישור',
          isCancel: false
        })
      );
    }
  }, [data.pages, submitForm, errors, files]);

  //FOR COUNTER
  const incrementCount = (counterType) => {
    setPagesCount(pagesCount + 1);
  }

  const decrementCount = (counterType) => {
    setPagesCount(pagesCount - 1 < 1 ? 1 : pagesCount - 1);
  }

  const handleCountChange = (event, counterType) => {
    setPagesCount(parseInt(event.target.value.replace(/^0+/, '')) || 1);
  }
  //FOR COUNTER

  const onDrop = useCallback(async (acceptedFiles) => {
    console.log('onDrop')
    const storage = await getStorage();
    acceptedFiles.forEach(async (file) => {
      let fileName = file.name
      if (type === 'landRegistration' && pageName !== 'UploadFile') {
        const { idNum, name } = data.collectFiles[data.currSubmiterIdx]
        fileName = `${name}-${idNum}-ייפוי כח`
        fileName += file.type === 'application/pdf' ? '.pdf' : ''
      }
      if (type === 'landSupervisor') {
        const prefix = "_land_supervisor";
        fileName = prefix + " " + file.name;
      }
      // uploadedFile['name'] = uploadedFile.name + ' land_supervisor'

      const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/${fileName}`);
      dispatch(setIsLoading(true))
      const isUploaded = await uploadBytes(storageRef, file)
      if (isUploaded) dispatch(setIsLoading(false))
      const url = await getDownloadURL(storageRef)
      let uploadedFile = {
        name: fileName,
        url,
        filePath: `${activeUser.uid}/${data.storageDocId}/${fileName}`,
        _id: `${activeUser.uid}/${data.storageDocId}/${fileName}`
      };
      if (type === 'landRegistration' && files.length > 0 && pageName !== 'UploadFile') {
        files[0] = uploadedFile
        dispatch(setFiles(files))
      } else {
        dispatch(addFiles(uploadedFile));
      }

      //NOT DEPLOY YET
      // const res = await callUserAction({
      //   uploadedFile,
      //   type: "getNumberOfPagesFromFile"
      // })
      // console.log('pagesCount', pagesCount)
      // console.log('res?.data', res?.data)
      // console.log('pagesCount+res?.data', pagesCount + res?.data)
      // if (res?.data) updatePagesCountByFile(res?.data)
      // // if (res?.data) setPagesCount(pagesCount + res?.data)

      // console.log('res', res)
      // console.log('uploadedFile', uploadedFile)
      // console.log('url', uploadedFile.url)
    });
  }, [activeUser]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const updatePagesCountByFile = (num) => {
    setPagesCount(pagesCount + num)
  }


  const handleClickDeleteFile = async (fileId) => {
    const storage = getStorage();
    const fileRef = ref(storage, fileId);
    await deleteObject(fileRef)
    dispatch(removeFiles(fileId));
  };

  const handleChangeData = (event, newAlignment) => {
    event.preventDefault();
    setAlignment(newAlignment);
    const field = event.target.name;
    const value = event.target.value;
    const printingDetails = { ...data?.printingDetails }
    printingDetails[field] = value === 'צבעוני' ? true : false
    setData({ ...data, printingDetails });
  };


  //Additions
  const handleAlignment = (boolType) => {
    if (boolType)
      return 'left'
    return 'right'
  }

  const onHandleAdditions = (field) => {
    const lastBoolType = data?.printingDetails[field] || false
    const printingDetails = { ...data?.printingDetails }
    printingDetails[field] = !lastBoolType
    setData({ ...data, printingDetails });
  }

  const calculatePrice = () => {
    let pagePrice = data?.printingDetails?.isColourful ? 1.6 : 0.4;
    let coversCount = Math.ceil(pagesCount / 300);
    let totalPrice = 0;

    if (data?.printingDetails?.isColourful) totalPrice += (20 + (pagePrice * pagesCount))
    else totalPrice += (pagePrice * pagesCount < 20) ? 20 : pagePrice * pagesCount
    totalPrice += (data?.isAudioFileAdded ? 150 : 0)
    if (data?.printingDetails?.isSpiralBinding) {
      totalPrice += coversCount * 50
    }
    if (data?.printingDetails?.isDividers) {
      totalPrice += coversCount * 10
    }
    if (data?.printingDetails?.isBindingStrip) {
      totalPrice += coversCount * 5
    }
    return totalPrice;
  }

  const renderCopiesDetails = () => {
    const pageStr = pagesCount === 1 ? 'עמוד אחד' : `${pagesCount} עמודים`;
    const colorStr = data?.printingDetails?.isColourful ? 'צבעוני' : 'שחור לבן';
    const extras = [];
    if (data?.printingDetails?.isSpiralBinding) extras.push('כריכת ספירלה');
    if (data?.printingDetails?.isBindingStrip) extras.push('פס תיוק');
    if (data?.printingDetails?.isDividers) extras.push('דגלונים');
    if (data?.isAudioFileAdded) extras.push('דיסק און קי');
    const extrasStr = extras.length > 0 ? `, תוספת ${extras.join(' + ')}` : '';
    return `הדפסת ${pageStr} ב${colorStr}${extrasStr}`;
  }

  const copyToClipboard = (containerid) => {
    var range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().removeAllRanges(range);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    setIsCopied(true)
  }

  const handleDownloadClick = () => {
    const powerOfAttorneyLink = 'https://firebasestorage.googleapis.com/v0/b/delawvery-prod.appspot.com/o/documents%D7%99%D7%99%D7%A4%D7%95%D7%99%20%D7%9B%D7%97%20-%20%D7%A0%D7%95%D7%A1%D7%97.docx'
    window.open(powerOfAttorneyLink, '_blank');
  };

  const handleIsAudioFileAdded = () => {
    setData({
      ...data,
      isAudioFileAdded: !data?.isAudioFileAdded
    });
  }

  return (
    <div className={` ${!(pageName === 'NotarizedTranslation') && "main-form"} upload-file-update ${(pageName === 'NotarizedTranslation') && 'notarized'} update-cmps`}>
      <div className="modal-content-container block" >
        {(pageName !== 'NotarizedTranslation') && <h2 className='large-title'>
          {(type === 'landRegistration' && pageName === 'UploadFile') ? 'העלאת מכתבי הדחייה' : 'העלאת מסמכים'}
        </h2>}
        {(pageName !== 'NotarizedTranslation' && type !== 'landRegistration' && (type === 'courtSubmission' && data.isFirstSubmission !== 'collect')) &&
          (<p className='subtitle'>העלו או גררו את המסמכים אותם תרצו לשלוח</p>)
        }

        {type === 'courtSubmission' && data.isFirstSubmission === 'collect' &&
          <p>העלו ייפוי כח או כל מסמך אחר שצריך להציג בערכאה השיפוטית</p>
        }

        {(type === 'landRegistration') &&
          (<p className='subtitle'>{pageName === 'UploadFile' ? 'העלו את מכתב/י דחיית התיק שהתקבלו מלשכת רישום המקרקעין' : 'העלו או גררו את ייפוי הכח החתום'}</p>)
        }

        <div className='upload-container'>
          {(pageName === 'NotarizedTranslation') && <div className="text-notarized">
            <p>צרפו את המסמך לקבלת הצעת מחיר מהירה</p>
            <p>*לא חובה</p>
          </div>}
          <div className='drop-area' {...getRootProps()}>
            <input {...getInputProps()} required={files.length === 0 && (pageName !== 'NotarizedTranslation') && data.isFirstSubmission !== 'collect'} />
            <div className="inside-drop-area">
              <img src='/images/upload.svg' alt='upload' />
              {isDragActive ? (
                <h3>שחרר את הקבצים כאן</h3>
              ) : (
                <h3>גוררים את הקבצים ומשחררים כאן</h3>
              )}
              <p>קבצים נתמכים:  PDF/ JPEG/ DOC</p>
              <button className='main-btn long' type='button'>
                העלאת קבצים
              </button>
            </div>
          </div>
        </div>

        {files.length > 0 && !isLoading && (<div className="files-container">
          <h3>הקבצים שלך</h3>
          {width > breakpoint &&
            <div className="titles">
              <p>שם הקובץ</p>
              <p>סטאטוס העלאה</p>
              <div className="btn-titles">
                <p>צפייה</p>
                <p>מחיקה</p>
              </div>
            </div>}
          {files.map((file) => {
            return (
              <div className="file-upload" >
                <div className="details">
                  <img src={doc} alt="document" />
                  <p>{file.name}</p>
                </div>

                {
                  width > breakpoint &&
                  <div className="status">
                    <p>הושלם</p>
                  </div>
                }

                <div className="btn-container">
                  <a href={file.url} target="_blank" rel="noreferrer">
                    <img
                      src={eye}
                      alt="preview"
                    />
                  </a>
                  <img

                    src={remove}
                    alt="remove"
                    onClick={() => handleClickDeleteFile(file._id)} />
                </div>
              </div>

            )
          }
          )}
        </div>
        )}
        {
          isLoading && <div className="spinner files-container">
            <TailSpin
              heigth="100"
              width="100"
              color='#4e71ff'
              ariaLabel='loading'
            />
          </div>
        }

        <div className="dropdowns">

          <h3 className='print-titles'>מאפייני הדפסה לעותק אחד</h3>
          <div className='printing-properties'>
            <div className='regular-properties'>
              <div className='pages-count-container'>

                <h3 className='small'>מס' עמודים</h3>

                <div className="counter">
                  <p className="counter-button" onClick={incrementCount}>
                    +
                  </p>
                  <input className='counter-input' type="number" value={pagesCount} onChange={handleCountChange} />
                  <p className="counter-button" onClick={decrementCount} disabled={pagesCount === 0}>
                    -
                  </p>
                </div>

              </div>
              <div className='colorfulness-container'>

                <h3 className='small'>צבעוניות</h3>

                <div className='block-select'>
                  <div className={`form-main-toggle ${alignment}`}>
                    <span className={`${alignment === 'right' ? 'gray' : ''}`}></span>
                    <button
                      name='isColourful'
                      value={optionA}
                      onClick={(ev) => handleChangeData(ev, 'right')}
                    >
                      {optionA}
                    </button>
                    <button
                      name='isColourful'
                      value={optionB}
                      onClick={(ev) => handleChangeData(ev, 'left')}
                    >
                      {optionB}
                    </button>
                  </div>
                </div>

              </div>
              <div className='addition-container'>

                <h3 className='small'>תוספות</h3>
                <div className='toggles'>

                  {deliveryUploadOptions.map(option =>
                    <TooltipTriangle text={option.description} className={'upload-tooltip'}>
                      <StyledChoice data={data} setData={setData} type={type} option={option} pageName={pageName} onHandleAdditions={onHandleAdditions} isUploadHandle={true} />
                    </TooltipTriangle>
                  )}

                  {/* <div className='toggle-container'>
                    <div className={`form-main-toggle  ${handleAlignment(data?.printingDetails?.isSpiralBinding)}`}
                      onClick={(ev) => {
                        onHandleAdditions('isSpiralBinding');
                      }}>
                      <span className={`${data?.printingDetails?.isSpiralBinding ? '' : 'gray'}`}></span>
                      <button
                        type='button'
                      >
                      </button>
                      <button
                        type='button'
                      >
                      </button>
                    </div>
                    <p>{'כריכת ספירלה'}</p>
                  </div>

                  <div className='toggle-container'>
                    <div className={`form-main-toggle  ${handleAlignment(data?.printingDetails?.isBindingStrip)}`}
                      onClick={(ev) => {
                        onHandleAdditions('isBindingStrip');
                      }}
                    >
                      <span className={`${data?.printingDetails?.isBindingStrip ? '' : 'gray'}`}></span>
                      <button
                        type='button'
                      >
                      </button>
                      <button
                        type='button'
                      >
                      </button>
                    </div>
                    <p>{'פס תיוק'}</p>
                  </div>

                  <div className='toggle-container'>
                    <div className={`form-main-toggle  ${handleAlignment(data?.printingDetails?.isDividers)}`}
                      onClick={(ev) => {
                        onHandleAdditions('isDividers');
                      }}
                    >
                      <span className={`${data?.printingDetails?.isDividers ? '' : 'gray'}`}></span>
                      <button
                        type='button'
                      >
                      </button>
                      <button
                        type='button'
                      >
                      </button>
                    </div>
                    <p>{'דגלונים'}</p>
                  </div> */}

                </div>

              </div>
            </div>
            {audioFileForms.includes(type) &&
              <Tooltip text={tooltipTxt}>
                <label className="checkbox-container">
                  <Field type="checkbox" id="isAudioFileAdded" name="isAudioFileAdded" checked={data?.isAudioFileAdded || false} onChange={() => handleIsAudioFileAdded()} />
                  <label className='checkbox-content' htmlFor='isAudioFileAdded'>
                    בין הקבצים שהועלו, קיים קובץ קול (אודיו) או קובץ וידאו - תוספת 150 ₪
                  </label>
                  <span className="checkmark"></span>
                </label>
              </Tooltip>
            }
          </div>

          <div className='copies-container'>
            <h3 className='print-titles'>עותקים</h3>

            {myRecipients.map((recipient, idx) => {
              return (<RecipientCopies recipient={recipient} data={data} setData={setData} type={type} />
              )
            })}
          </div>

        </div>

        {type === 'courtSubmission' && data.isFirstSubmission === 'collect' &&
          <>
            <div className='power-of-att-info'>
              <p>כדי לאסוף מסמכים מבית המשפט, על ייפוי הכח מעורך/כת הדין לשליח להיות כתוב על גבי נייר המכתבים של המשרד, בחתימת וחותמת עורך/כת הדין.</p>
              <p className='bold'>הכנו לכם נוסח ייפוי כח מקובל להעתקה והדבקה מהירה.</p>
              <button className='main-btn long copy-btn' onClick={() => { handleDownloadClick() }} type='button'>{`${isCopied ? 'הנוסח הועתק!' : 'לחצו כאן להעתקת הנוסח'}`}
                <img className='icon-copypaste' src={copypaste} alt='copypaste-icon' />
              </button>
            </div>
            {/* <ClipboardPowerOfAttorney isHidden={true} /> */}
            <ClipboardPowerOfAttorney />
          </>
        }

      </div >
    </div >
  );
}
