import React, { useState, useRef, useCallback, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { setFiles, } from '../../../store/actions/fileActions';
import { getStorage, ref, listAll, deleteObject, uploadString, uploadBytes, getDownloadURL } from "firebase/storage";
import { DeliveryConfirm } from '../../Utils/DeliveryConfirm';
import { toJpeg } from 'html-to-image';

export const CollectDocumentsOrUploadFile = ({ setData, data, type, submitForm }) => {
  const refs = useRef([])
  const [alignment, setAlignment] = useState('right');
  const totalRecipients = useSelector((state) => state.recipientReducer.Recipients);
  const orderRecipientsIds = useSelector((state) => state.dataReducer.data.recipients);
  const myRecipients = totalRecipients.filter((recipient) => orderRecipientsIds.includes(recipient._id));
  const activeUser = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();
  const optionA = 'איסוף עם שליח';
  const optionB = 'העלאת קובץ';
  const optionC = 'המסמכים אצלכם'
  const withoutBindings = ['NotarizedTranslation', 'inheritanceRegistration', 'landRegistration', 'companiesRegistration', 'partnershipsRegistration', 'associationsAndCompanies']
  useEffect(() => {
    refs.current = refs.current.slice(0, myRecipients.length);
  }, [myRecipients.length, refs]);

  useEffect(() => {
    const { collectionMethod } = data;

    if (!collectionMethod) {
      setData({ ...data, collectionMethod: 'pickup' });
    }
    // console.log('collectionMethod', collectionMethod)
    // setData({
    //   ...data,
    //   collectionMethod: collectionMethod ? collectionMethod : 'pickup',
    // });
    if (!collectionMethod) {
      setAlignment('right');
    } else
      setAlignment(
        collectionMethod && collectionMethod === 'pickup' ? 'right' : 'left'
      );
    // setAlignment(
    //   urgency === optionB ? 'left' : urgency === optionC ? 'end' : 'right'
    // );
  }, []);
  const handleChangeData = async (event, newAlignment) => {
    event.preventDefault();
    const { name, value } = event.target;
    setAlignment(newAlignment);
    if (!(value === 'pickup')) {
      setData({ ...data, [name]: value, collectionAddress: {} });
      delete data.collectionAddress;
    }
    else {
      delete data.pages;
      delete data.bindings;
      setData({ ...data, [name]: value, pages: 0, bindings: 0 });
      const storage = await getStorage();
      const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}`);
      const filesList = await listAll(storageRef)
      filesList.items.map(async (file) => {
        const fileSplitLocation = file._location.path_.split('/')
        const fileName = fileSplitLocation[fileSplitLocation.length - 1]
        if (!fileName.includes('אישור מסירה')) {
          const storage = getStorage();
          const fileRef = ref(storage, file._location.path_);
          await deleteObject(fileRef)
        }
      })
      dispatch(setFiles([]))
    }

  };

  const getImage = (i) => {
    return toJpeg(refs.current[i], { cacheBust: true, })
      .then(async (dataUrl) => {
        // dispatch(setIsLoading(true))
        const storage = await getStorage();

        // const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/${data.collectFiles[data.currSubmiterIdx]?.name || ''}-${data.collectFiles[data.currSubmiterIdx]?.idNum || ''}-ייפוי כח`);
        let fileName = `${myRecipients[i].streetAddress} ${myRecipients[i].streetNumber} , ${myRecipients[i]?.officeName || myRecipients[i]?.personName || myRecipients[i].companyName} אישור מסירה.jpeg`
        const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/${fileName}`);
        // await uploadString(storageRef, dataUrl.split(',')[1], 'base64', { contentType: 'image/png' })

        // data.collectFiles[i] = { ...data.collectFiles[i], powerOfAttorney: true, powerOfAttorneySign: dataUrl, toApprove: false }
        // setData({ ...data, })
        const link = document.createElement('a')
        link.download = 'my-image-name.jpeg'
        link.href = dataUrl
        // dispatch(setIsLoading(false))
      })
      .catch((err) => {
      })
    // return toJpeg(refs.current[i], { cacheBust: true, })
    //   .then((dataUrl) => {
    //     const link = document.createElement('a')
    //     link.download = `${myRecipients[i].streetAddress} ${myRecipients[i].streetNumber} , ${myRecipients[i]?.officeName || myRecipients[i]?.personName || myRecipients[i].companyName} אישור מסירה.jpeg`;
    //     link.href = dataUrl
    //     link.click()
    //     return dataUrl

    // const addFileTo = async () => {
    //   console.log('addFileTo')
    //   const storage = await getStorage();
    //   let fileName = `${myRecipients[i].streetAddress} ${myRecipients[i].streetNumber} , ${myRecipients[i]?.officeName || myRecipients[i]?.personName || myRecipients[i].companyName} אישור מסירה.jpeg`
    //   const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/${fileName}`);
    //   console.log('storageRef',storageRef)
    //   console.log('data',data)
    //   const file = {
    //     name: fileName,
    //     path: fileName,
    //     lastModified: Date.now(),
    //   };
    //   console.log('file',file)
    //   const isUploaded = await uploadBytes(storageRef, file)
    //   const url = await getDownloadURL(storageRef)
    //   console.log('url',url)
    //   let uploadedFile = {
    //     name: fileName,
    //     url,
    //     filePath: `${activeUser.uid}/${data.storageDocId}/${fileName}`,
    //     _id: `${activeUser.uid}/${data.storageDocId}/${fileName}`
    //   };
    // }
    // addFileTo()



    // })
    // .catch((err) => {
    //   console.log('%c  err:', 'color: white;background: red;', err);
    // })
  }

  const onDownload = useCallback(() => {
    if (refs.current === null) {
      return
    }
    refs.current.map((ref, i) => getImage(i))
  }, [refs.length])
  // console.log('myRecipients', myRecipients)
  // console.log('data.docsForSubmission', data.docsForSubmission)

  return (
    <div className='main-form collect-or-upload'>
      <div className='modal-content-container block '>
        {/* <h2 className='large-title'>כיצד תרצו שנקבל את המסמכים?</h2> */}
        <h2 className='large-title'>כיצד תעדיפו להעביר את המסמכים לטיפולנו?</h2>
        <p className='subtitle'>
          איסוף באמצעות שליח או העלאת קובץ להדפסה
          {withoutBindings.includes(type) ? ' ' : ' וכריכה '}
          על ידנו
        </p>
        <div className='blockSelect'>
          <div className={`form-main-toggle ${alignment}`}>
            <span></span>
            <button
              name='collectionMethod'
              value='pickup'
              onClick={(ev) => handleChangeData(ev, 'right')}
            >
              {optionA}
            </button>
            <button
              name='collectionMethod'
              value='upload'
              onClick={(ev) => handleChangeData(ev, 'left')}
            >
              {optionB}
            </button>
          </div>
        </div>

        {/* {alignment === 'right' && data.type === 'personalDelivery' && <>
          <button type="button" className="btn download" onClick={onDownload}>להורדת אישורי מסירה</button >
          <div className="delivery-confirms" >
            <div>
              {myRecipients?.map((recipient, index) => {
                return <div key={recipient._id} ref={el => refs.current[index] = el}>
                  <DeliveryConfirm
                    docs={data.docsForSubmission}
                    recipient={recipient}
                    activeUser={activeUser}
                    data={data}
                    myRecipients={myRecipients}
                    index={index}
                  />
                </div>
              })}
            </div>
          </div>
        </>
        } */}
      </div>
    </div >
  );
};

export default CollectDocumentsOrUploadFile;
