import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'formik';
import info from '../../../images/info.svg'
import arrow from '../../../images/dropdown-arrow.svg';
import { claimCourtsOptions } from '../../../consts/data'
import { useDropzone } from 'react-dropzone';
import Dropzone from 'react-dropzone'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { setIsLoading } from '../../../store/actions/systemActions';
import { removeFiles, setFiles, addFiles } from '../../../store/actions/fileActions';
import { useViewport } from '../../../Hooks/useViewport'
import doc from '../../../images/doc.svg'
import eye from '../../../images/eye.png';
import remove from '../../../images/remove1.png';
import { TailSpin } from 'react-loader-spinner'
import { Tooltip } from '../../Utils/Tooltip'
import { CustomTooltip } from '../../Utils/CustomTooltip'
import { ClaimStatement } from '../../SmallClaim/ClaimStatement'

//Signature
// import { toJpeg } from 'html-to-image';
import { Canvas } from '../../Utils/Canvas'

export const SmallClaimDescription = ({ type, data, setData, submitForm, setSubmitForm, isError, errors, handleChanges, setTouch, touch }) => {
  const activeUser = useSelector((state) => state.userReducer.user);
  const dispatch = useDispatch();

  const refCourtSelection = useRef()
  const [isCourtSelectionDropdownOpen, setIsCourtSelectionDropdownOpen] = useState(false);
  // const [isSignLoading, setIsSignLoading] = useState(false);
  const [searchCourt, setSearchCourt] = useState('');

  let files = useSelector((state) => state.fileReducer.Files);
  const isLoading = useSelector((state) => state.systemReducer.isLoading)
  const { width } = useViewport();
  const breakpoint = 767;

  const componentRef = useRef();
  const [showSign, setShowSign] = useState(false)
  const [trimmedDataURL, setTrimmedDataURL] = useState(null)

  useEffect(() => {
    setSearchCourt(data?.claimDescription?.court?.courtName || '')

    setData({
      ...data,
      claimFiles: data.claimFiles ? data.claimFiles : [],
      witnesses: data.witnesses ? data.witnesses : [{
        witnessName: ''
      }],
      claimDescription: data.claimDescription ? data.claimDescription : {
        signature: '',
      }
    });
  }, []);

  // useEffect(() => {
  //   if (submitForm && errors.toApprove && data?.claimDescription?.signature) {
  //     setSubmitForm(false);
  //     dispatch(
  //       updateErrorModal({
  //         title: 'שגיאה',
  //         errorText: errors.toApprove || '',
  //         submitText: 'אישור',
  //         isCancel: false
  //       })
  //     );
  //   }
  // }, [submitForm, data.claimDescription]);

  const onBlur = () => {
    if (data?.claimDescription?.court?.courtName !== searchCourt) {
      setSearchCourt(data?.claimDescription?.court?.courtName ? data?.claimDescription?.court?.courtName : '')
    }
    setIsCourtSelectionDropdownOpen(false)
  }

  const onSetChoose = (field, value, extraInfo = {}) => {
    if (field === 'court') {
      setData({
        ...data,
        claimDescription: {
          ...data.claimDescription,
          court: value
        },
      });
      setSearchCourt(value.courtName || '')
    }
    setTouch({ ...touch, [field]: true });
  }

  const handleChangeData = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setData({
      ...data,
      claimDescription: {
        ...data.claimDescription,
        [field]: value
      },
    });
    handleChanges(event);
    setTouch({ ...touch, [field]: true });
  };

  const handleClaimFileChangeData = (event, idx) => {
    const value = event.target.value;
    const updatedClaimFiles = [...data.claimFiles]
    updatedClaimFiles[idx].fileDescription = value
    setData({
      ...data,
      claimFiles: updatedClaimFiles
    });
    // handleChanges(event);
    // setTouch({ ...touch, [field]: true });
  };
  const handleWitnessChangeData = (event, idx) => {
    const value = event.target.value;
    const updatedWitnesses = [...data.witnesses]
    updatedWitnesses[idx].witnessName = value
    setData({
      ...data,
      witnesses: updatedWitnesses
    });
    // handleChanges(event);
    // setTouch({ ...touch, [field]: true });
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const storage = await getStorage();
    acceptedFiles.forEach(async (file) => {
      let fileName = file.name
      const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/${fileName}`);
      dispatch(setIsLoading(true))
      const isUploaded = await uploadBytes(storageRef, file)
      if (isUploaded) dispatch(setIsLoading(false))
      const url = await getDownloadURL(storageRef)
      let uploadedFile = {
        name: fileName,
        url,
        filePath: `${activeUser.uid}/${data.storageDocId}/${fileName}`,
        _id: `${activeUser.uid}/${data.storageDocId}/${fileName}`
      };

      if (files.length > 0) {
        files[0] = uploadedFile
        dispatch(setFiles(files))
      } else {
        dispatch(addFiles(uploadedFile));
      }
    });
  }, [activeUser]);

  const onDropClaimFile = useCallback(async (acceptedFiles, idx, data) => {
    const storage = await getStorage();
    acceptedFiles.forEach(async (file) => {
      let fileName = file.name
      const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/${fileName}`);
      // setIsClaimLoading(true)
      const isUploaded = await uploadBytes(storageRef, file)
      // if (isUploaded) setIsClaimLoading(false)
      const url = await getDownloadURL(storageRef)
      let uploadedFile = {
        fileName,
        fileDescription: data?.claimFiles[idx]?.fileDescription || '',
        url,
        type: 'claimFile',
        filePath: `${activeUser.uid}/${data.storageDocId}/${fileName}`,
        _id: `${activeUser.uid}/${data.storageDocId}/${fileName}`
      };

      const updatedClaimFile = [...data.claimFiles]
      updatedClaimFile[idx] = uploadedFile

      setData({
        ...data,
        claimFiles: updatedClaimFile
      });
    });
  }, [activeUser]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  const onOpenFileSection = () => {
    const updatedClaimFiles = [...data.claimFiles]
    updatedClaimFiles.push({
      fileName: '',
      fileDescription: '',
      url: '',
      type: 'claimFile',
      filePath: ``,
      _id: ``
    })
    setData({
      ...data,
      claimFiles: updatedClaimFiles
    });
  }

  const onAddWitness = () => {
    const updatedWitnesses = [...data.witnesses]
    updatedWitnesses.push({
      witnessName: '',
    })
    setData({
      ...data,
      witnesses: updatedWitnesses
    });
  }

  const handleClickDeleteFile = async (fileId) => {
    const storage = getStorage();
    const fileRef = ref(storage, fileId);
    await deleteObject(fileRef)
    dispatch(removeFiles(fileId));
  };
  const handleClickDeleteClaimFile = async (fileId, idx) => {
    if (fileId) {
      const storage = getStorage();
      const fileRef = ref(storage, fileId);
      await deleteObject(fileRef)
    }
    const updatedClaimFiles = [...data.claimFiles]
    updatedClaimFiles.splice(idx, 1)
    setData({
      ...data,
      claimFiles: updatedClaimFiles
    });
  };

  const handleClickDeleteWitness = async (idx) => {
    const updatedWitness = [...data.witnesses]
    if (updatedWitness.length === 1) updatedWitness[idx] = { witnessName: '' }
    else updatedWitness.splice(idx, 1)

    setData({
      ...data,
      witnesses: updatedWitness
    });
  };

  const handleIsAudioFileAdded = () => {
    setData({
      ...data,
      isAudioFileAdded: !data?.isAudioFileAdded
    });
  }

  const getToolTipText = (textType) => {
    switch (textType) {
      case 'moneyClaimed':
        return 'תארו את הסעד שאתם רוצים לקבל מבית המשפט. אם זה סכום כסף, רשמו את הסכום הכולל עד לסך מקסימלי של 34,600₪. שימו לב, סכום האגרה שתצטרכו לשלם הוא 1% מסכום התביעה, בעיגול כפולות של 5 כלפי מעלה, או 50₪, לפי הגבוה מביניהם.'
      case 'isAudioFileAdded':
        return 'ניתן להעלות קבצי שמע ו/או קבצי וידאו ואנו נדאג להעבירם לדיסק און קי (Disk on key) ולהגיש לבית המשפט עם התביעה קובץ אודיו. יש צורך בעותק מהדיסק לבית המשפט, ובנוסף עותק לכל נתבע.'
      case 'witness':
        return 'ציינו את שמות העדים שבכוונתם להביא למשפט.'
      default:
        return ''
    }
  }

  //If you want to add img of signature to firestorage
  // const onApprove = useCallback((data) => {
  //   if (componentRef.current === null) {
  //     return
  //   }

  //   toJpeg(componentRef.current, { cacheBust: true, })
  //     .then(async (dataUrl) => {
  //       setIsSignLoading(true)
  //       const storage = await getStorage();
  //       const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/signature/${activeUser?.fullName || 'תובע/ת'}-חתימה`);
  //       await uploadString(storageRef, dataUrl.split(',')[1], 'base64', { contentType: 'image/png' })
  //       // data.claimDescription = { ...data.claimDescription, claimDescriptionSign: dataUrl, toApprove: false }
  //       const updatedClaimDescription = { ...data.claimDescription, claimDescriptionSign: dataUrl, toApprove: false }
  //       setData({
  //         ...data,
  //         claimDescription: updatedClaimDescription
  //       })
  //       setIsSignLoading(false)
  //     })
  //     .catch((err) => {
  //     })
  // }, [componentRef])

  const handleClose = () => {
    setShowSign(false)
  }

  const Modal = () => {
    const showHideClassName = showSign ? "modal display-block" : "modal display-none";
    return (
      <div className={showHideClassName}>
        <section className="modal-main">
          <div >
            <Canvas trimmedDataURL={trimmedDataURL} setTrimmedDataURL={setTrimmedDataURL} handleClose={handleClose} data={data} setData={setData} type={'smallClaim'} />
          </div>
        </section>
      </div>
    );
  };

  return (
    <div className='main-form small-claim-description'>
      <div className='modal-content-container'>

        <div className='input-container court-selection'>
          <div className='tooltip-with-title court-selection-tooltip'>
            <CustomTooltip tooltipType={'smallClaimCourt'}>
              <img src={info} alt="info-icon" className="info-icon" />
            </CustomTooltip>
          </div>
          <div className='field large'>
            <div
              ref={refCourtSelection}
              className={
                isCourtSelectionDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
              }
            >
              <div className='dropdown-input'>
                <input
                  type="text"
                  placeholder={'בחרו את בית המשפט להגשת התביעה'}
                  onBlur={onBlur}
                  onChange={(event) => {
                    setSearchCourt(
                      event.target.value
                    );
                    setIsCourtSelectionDropdownOpen(true);
                  }}
                  value={searchCourt}
                  onClick={() => { setIsCourtSelectionDropdownOpen(true); }}
                  name='courtName'
                  autoComplete="off"
                  className={`${isError('courtClaimName')
                    ? 'error'
                    : data?.claimDescription?.court?.courtName
                      ? 'success'
                      : ''}`
                  }
                />
                <button
                  type='button'
                  onClick={() => {
                    setIsCourtSelectionDropdownOpen(!isCourtSelectionDropdownOpen);
                  }}
                >
                  <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                </button>
              </div>

              {isError('courtClaimName') && <p className="err-details">{errors.courtClaimName}</p>}

              <div className='dropdown-list'>
                {claimCourtsOptions.filter((option) => option.courtName.includes(searchCourt)).map((court, idx) => {
                  return (
                    <div className='dropdown-option' key={idx}>
                      <p
                        onMouseDown={() => {
                          onSetChoose('court', court)
                          setSearchCourt(court?.courtName)
                          setIsCourtSelectionDropdownOpen(false);
                        }}>
                        {court.courtName}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className='tooltip-with-title'>
          <CustomTooltip tooltipType={'claimDescription'}>
            <img src={info} width={20} alt="info-icon" className="info-icon" />
          </CustomTooltip>
          <h4
            className={`form-title ${isError('smallClaimLocation')
              ? 'error'
              : ''}`
            }
          >3. תיאור התביעה:
          </h4>
          {isError('smallClaimLocation') && <p className='error-label'>{errors.smallClaimLocation}</p>}
        </div>

        <div className="input-container">
          <p className='details'>א. המקום</p>
          <div className='field large'>
            <Field
              type='text'
              name='commitmentLocation'
              autoComplete="new-password"
              value={data?.claimDescription?.commitmentLocation || ''}
              onChange={handleChangeData}
              className={
                isError('commitmentLocation')
                  ? 'error'
                  : data?.claimDescription?.commitmentLocation
                    ? 'success'
                    : ''
              }
            />
            {isError('commitmentLocation') && <p className='error-label'>{errors.commitmentLocation}</p>}
          </div>
          <p className='details'>והתאריך (או התאריכים)</p>
          <div className='field'>
            <Field
              type='text'
              name='commitmentDate'
              autoComplete="new-password"
              value={data?.claimDescription?.commitmentDate || ''}
              onChange={handleChangeData}
              className={
                isError('commitmentDate')
                  ? 'error'
                  : data?.claimDescription?.commitmentDate
                    ? 'success'
                    : ''
              }
            />
            {isError('commitmentDate') && <p className='error-label'>{errors.commitmentDate}</p>}
          </div>
          <p className='details check'>שבהם נוצרה ההתחייבות לתשלומו של סכום הכסף הנתבע, בין בהסכם בין בעלי הדין ובין בדרך אחרת.</p>
        </div>

        <div className="input-container">
          <p className='details'>ב. המקום שנועד, או היה מכוון, לקיום התחייבות:</p>
          <div className='field'>
            <Field
              type='text'
              name='commitmentFulfillmentLocation'
              autoComplete="new-password"
              value={data?.claimDescription?.commitmentFulfillmentLocation || ''}
              onChange={handleChangeData}
              className={
                isError('commitmentFulfillmentLocation')
                  ? 'error'
                  : data?.claimDescription?.commitmentFulfillmentLocation
                    ? 'success'
                    : ''
              }
            />
            {isError('commitmentFulfillmentLocation') && <p className='error-label'>{errors.commitmentFulfillmentLocation}</p>}
          </div>
        </div>

        <div className="input-container">
          <p className='details'>ג. מקום המסירה של הנכס:</p>
          <div className='field'>
            <Field
              type='text'
              name='deliveryPropertyLocation'
              autoComplete="new-password"
              value={data?.claimDescription?.deliveryPropertyLocation || ''}
              onChange={handleChangeData}
              className={
                isError('deliveryPropertyLocation')
                  ? 'error'
                  : data?.claimDescription?.deliveryPropertyLocation
                    ? 'success'
                    : ''
              }
            />
            {isError('deliveryPropertyLocation') && <p className='error-label'>{errors.deliveryPropertyLocation}</p>}
          </div>
        </div>

        <div className="input-container">
          <p className='details'>ד. מקום המעשה או המחדל שבשלו תובעים:</p>
          <div className='field'>
            <Field
              type='text'
              name='failureOrFailureLocation'
              autoComplete="new-password"
              value={data?.claimDescription?.failureOrFailureLocation || ''}
              onChange={handleChangeData}
              className={
                isError('failureOrFailureLocation')
                  ? 'error'
                  : data?.claimDescription?.failureOrFailureLocation
                    ? 'success'
                    : ''
              }
            />
            {isError('failureOrFailureLocation') && <p className='error-label'>{errors.failureOrFailureLocation}</p>}
          </div>
        </div>

        <div className="input-container">
          <div className='tooltip-with-title money-claimed-tooltip'>
            <Tooltip text={getToolTipText('moneyClaimed')}>
              <img src={info} alt="info-icon" className="info-icon" />
            </Tooltip>
            <p className='details'>ה. סכום הכסף הנתבע:</p>
          </div>

          <div className='field small-plus'>
            <Field
              type='number'
              name='moneyClaimed'
              autoComplete="new-password"
              value={data?.claimDescription?.moneyClaimed || ''}
              onChange={handleChangeData}
              className={
                isError('productClaimed') || isError('moneyClaimed')
                  ? 'error'
                  : data?.claimDescription?.moneyClaimed && (+data?.claimDescription?.moneyClaimed < 34601)
                    ? 'success'
                    : ''
              }
            />
          </div>
          ₪
          {isError('productClaimed') && <p className='error-label'>{errors.productClaimed}</p>}
          {isError('moneyClaimed') && <p className='error-label'>{errors.moneyClaimed}</p>}
        </div>

        <div className="input-container sub-item">
          <p className='details'>או המוצר שיש להחליפו</p>
          <div className='field'>
            <Field
              type='text'
              name='productToExchange'
              autoComplete="new-password"
              value={data?.claimDescription?.productToExchange || ''}
              onChange={handleChangeData}
              className={
                isError('productClaimed')
                  ? 'error'
                  : data?.claimDescription?.productToExchange
                    ? 'success'
                    : ''
              }
            />
          </div>
          {isError('productClaimed') && <p className='error-label'>{errors.productClaimed}</p>}
        </div>

        <div className="input-container sub-item">
          <p className='details'>או העסקה שיש להורות על ביטולה</p>
          <div className='field'>
            <Field
              type='text'
              name='dealToCancel'
              autoComplete="new-password"
              value={data?.claimDescription?.dealToCancel || ''}
              onChange={handleChangeData}
              className={
                isError('productClaimed')
                  ? 'error'
                  : data?.claimDescription?.dealToCancel
                    ? 'success'
                    : ''
              }
            />
          </div>
          {isError('productClaimed') && <p className='error-label'>{errors.productClaimed}</p>}
        </div>

        <div className="input-container claim-reasons">
          <div className='input-container-header' {...getRootProps()} >
            <div className='tooltip-with-title claim-reasons-tooltip'>
              <CustomTooltip tooltipType={'claimReasons'}>
                <img src={info} width={20} alt="info-icon" className="info-icon" />
              </CustomTooltip>
              <p className='details'>ו. נימוקי התביעה (אפשר להוסיף עמודים נוספים):</p>
            </div>
            <Dropzone onDrop={async (acceptedFiles) => await onDrop(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()} className='add-file'>
                    <input {...getInputProps()} />
                    <img
                      className="btn-add"
                      src='/images/plus.svg'
                      alt=''
                    />
                    <h3>העלאת קובץ נוסח כתב תביעה לצירוף להגשה</h3>
                  </div>
                </section>
              )}
            </Dropzone>
          </div>

          {files.length > 0 && (<div className="files-container">
            {width > breakpoint &&
              <div className="titles">
                <p>שם הקובץ</p>
                <p>סטאטוס העלאה</p>
                <div className="btn-titles">
                  <p>צפייה</p>
                  <p>מחיקה</p>
                </div>
              </div>}
            {files.map((file) => {
              return (
                <div className="file-upload" >
                  <div className="details">
                    <img src={doc} alt="document" />
                    <p>{file.name}</p>
                  </div>

                  {
                    width > breakpoint &&
                    <div className="status">
                      <p>הושלם</p>
                    </div>
                  }

                  <div className="btn-container">
                    <a href={file.url} target="_blank" rel="noreferrer">
                      <img
                        src={eye}
                        alt="preview"
                      />
                    </a>
                    <img

                      src={remove}
                      alt="remove"
                      onClick={() => handleClickDeleteFile(file._id)} />
                  </div>
                </div>
              )
            }
            )}
          </div>
          )}
          {
            isLoading && <div className="spinner files-container">
              <TailSpin
                heigth="100"
                width="100"
                color='#4e71ff'
                ariaLabel='loading'
              />
            </div>
          }

          <div className='field text-field'>
            <textarea
              type='text'
              name="claimReasons"
              className={data?.claimDescription?.claimReasons ? 'success' : ''}
              onChange={handleChangeData}
              value={data?.claimDescription?.claimReasons || ''}
            />
          </div>
        </div>

        <div className="input-container claim-reasons">
          <div className='tooltip-with-title claim-reasons-files-tooltip'>
            <CustomTooltip tooltipType={'claimReasonsFiles'}>
              <img src={info} width={20} alt="info-icon" className="info-icon" />
            </CustomTooltip>
            <h4>3א. צירוף מסמכים:</h4>
          </div>

          <div className='input-container-header'>
            <p className='details'>יש חובה לצרף מסמכים שמבקשים להסתמך עליהם; אלה המסמכים שבכוונתי להסתמך עליהם בדיון והם מצורפים בזה (לדוגמה: הסכמים, מכתבים, התכתבויות אחרות לרבות בדואר אלקטרוני ובמסרונים, קבלות וחשבוניות על תשלומים, תצלומים של נזק שנתבע פיצוי בעדו וכיוצא באלה).</p>
          </div>

          {data?.claimFiles && data?.claimFiles.length > 0 && data?.claimFiles?.map((file, idx) => {
            return (
              <div className='reason' key={idx}>
                <div className='input-container-body' {...getRootProps()} >
                  <p className='input-idx'>{idx + 1}.</p>

                  <Field
                    type='text'
                    name={`fileDescription`}
                    placeholder='שם הקובץ ותיאורו'
                    autoComplete="new-password"
                    value={file.fileDescription || ''}
                    onChange={(event) => { handleClaimFileChangeData(event, idx) }}
                    className={
                      isError('claimFiles') && (!file.fileDescription || !file.filePath)
                        ? 'error'
                        : file.fileDescription
                          ? 'success'
                          : ''
                    }
                  />

                  <Dropzone onDrop={async (acceptedFiles) => await onDropClaimFile(acceptedFiles, idx, data)}>
                    {({ getRootProps, getInputProps }) => (
                      <div className='add-file-btn-container'>
                        <div {...getRootProps()} className='add-file'>
                          <input {...getInputProps()} />
                          <img
                            className="btn-add"
                            src='/images/plus.svg'
                            alt=''
                          />
                        </div>
                      </div>
                    )}
                  </Dropzone>

                  <div className="files-container">
                    {width > breakpoint &&
                      <div className="titles">
                        <p>שם הקובץ</p>
                        <p>סטאטוס העלאה</p>
                        <div className="btn-titles">
                          <p>צפייה</p>
                          <p>מחיקה</p>
                        </div>
                      </div>}

                    <div className="file-upload" >
                      <div className="details">
                        {/* <img src={doc} alt="document" /> */}
                        <p>{file.fileName}</p>
                      </div>

                      {width > breakpoint && file.url &&
                        <div className="status">
                          <p>הושלם</p>
                        </div>
                      }

                      <div className="btn-container">
                        {file.url &&
                          <a href={file.url} target="_blank" rel="noreferrer">
                            <img
                              src={eye}
                              alt="preview"
                            />
                          </a>
                        }
                        <img
                          src={remove}
                          alt="remove"
                          onClick={() => handleClickDeleteClaimFile(file._id, idx)} />
                      </div>
                    </div>
                  </div>
                </div>
                {isError('claimFiles') && !data.claimFiles[idx]?.fileDescription ? <p>{errors.claimFiles[idx]?.fileDescription}</p> : ''}
                {data.claimFiles[idx]?.fileDescription && isError('claimFiles') && !data.claimFiles[idx]?.filePath ? <p>{errors.claimFiles[idx]?.filePath}</p> : ''}
              </div>
            )
          })}
          {data?.claimFiles && data?.claimFiles.length > 0 &&
            <Tooltip text={getToolTipText('isAudioFileAdded')}>
              <label className="checkbox-container">
                <Field type="checkbox" id="isAudioFileAdded" name="isAudioFileAdded" checked={data?.isAudioFileAdded || false} onChange={() => handleIsAudioFileAdded()} />
                <label className='checkbox-content' htmlFor='isAudioFileAdded'>
                  בין הקבצים שהועלו, קיים קובץ קול (אודיו) או קובץ וידאו - תוספת 150 ₪ עבור כל עותק
                </label>
                <span className="checkmark"></span>
              </label>
            </Tooltip>
          }
        </div>

        <div className='input-container'>
          <div className='add-file'>
            <img
              className="btn-add"
              src='/images/plus.svg'
              alt=''
              onClick={onOpenFileSection}
            />
            <h3>{`העלאת מסמך ${data?.claimFiles && data?.claimFiles.length > 0 ? 'נוסף' : ''}`}</h3>
          </div>
        </div>

        <div className='input-container witness-container'>
          <div className='tooltip-with-title witness-tooltip'>
            <Tooltip text={getToolTipText('witness')}>
              <img src={info} alt="info-icon" className="info-icon" />
            </Tooltip>
            <h4>3ב. אלה העדים שבכוונתי להעיד בדיון לתמיכה בגרסתי:</h4>
          </div>

          {(data?.witnesses && data?.witnesses.length > 0) && data?.witnesses?.map((witness, idx) => {
            return (
              <div className='witness' key={idx}>
                <div className='input-container-body'>
                  <p className='input-idx'>{idx + 1}.</p>
                  <Field
                    type='text'
                    // name={`witness`}
                    autoComplete="new-password"
                    placeholder='הזינו את פרטי העד'
                    value={witness?.witnessName || ''}
                    onChange={(event) => { handleWitnessChangeData(event, idx) }}
                    className={
                      isError('witnesses') && !witness.witnessName
                        ? 'error'
                        : witness?.witnessName
                          ? 'success'
                          : ''
                    }
                  />
                  <img
                    src={remove}
                    alt="remove"
                    className='witness-remove-icon'
                    onClick={() => handleClickDeleteWitness(idx)} />
                </div>

                {isError('witnesses') && !data.witnesses[idx]?.witnessName ? <p>{errors.witnesses[idx]?.witnessName}</p> : ''}
              </div>
            )
          })
          }
        </div>

        <ul className='ul-info'>
          <li>
            <p className='details'>
              ראו מידע חשוב בהמשך בנושא זימון עדים
            </p>
          </li>
        </ul>

        <div className='input-container'>
          <div className='add-file'>
            <img
              className="btn-add"
              src='/images/plus.svg'
              alt=''
              onClick={onAddWitness}
            />
            <h3>הוספת עד/ה נוספ/ת</h3>
          </div>
        </div>

        <ClaimStatement data={data} setData={setData} handleChangeData={handleChangeData} isError={isError} errors={errors} />

        <div className='input-container signature-container'>
          <div className="btn-cont">
            <button type="button"
              className="btn-sign"
              onClick={() => {
                setSubmitForm(false);
                setShowSign(true);
                setData({
                  ...data,
                  claimDescription: {
                    ...data.claimDescription,
                    signature: '',
                  },
                });
              }}>
              לחתימה לחצו כאן
            </button>
          </div>

          <div className='signature'>
            {data?.claimDescription?.signature &&
              <h4>חתימת התובע/ת</h4>
            }
            <div ref={componentRef} style={{ backgroundColor: "white" }} >
              {data?.claimDescription?.signature &&
                <img className="signature-image" src={data?.claimDescription?.signature} alt="signature" style={{ alignSelf: "center", marginRight: '20px', width: "40%" }} />
              }
            </div>
          </div>
          <Modal />

        </div>
        <div className='signature-error'>
          {isError('signature') && <p className={
            isError('signature')
              ? 'error'
              : data?.claimDescription?.signature
                ? 'success'
                : ''
          }>{errors.signature}</p>}
        </div>
      </div>
    </div >
  );
};

export default SmallClaimDescription;
