import React, { useState, useEffect } from 'react';
import { Field } from 'formik';

export const Toll = ({ data,
    setData,
    isError,
    errors,
    handleChanges,
    setTouch,
    touch, }) => {

    const [alignment, setAlignment] = useState('right');

    useEffect(() => {
        const { isToll } = data;

        if (!isToll) {
            setData({ ...data, isToll: false });
        }
        setAlignment(!isToll ? 'left' : 'right');
    }, []);

    const handleChangeData = (event) => {
        const { value, name } = event.target;
        setData({ ...data, [name]: parseInt(value) });
        handleChanges(event);
        setTouch({ ...touch, [name]: true });
    };

    const handleChangeToggle = (event, newAlignment) => {
        const { value, name } = event.target;
        setAlignment(newAlignment);
        if (value === 'false') {
            delete data.toll;
            setData({ ...data });
        }
        setData({ ...data, [name]: value === 'true' ? true : false });
    };


    return (
        <div className='main-form form7-first-submission-or-copy'>
            <div className='modal-content-container block'>

                <h2 className='large-title'>צריכים שנשלם עבורכם את האגרה?</h2>


                <p className='subtitle'>
                    סמנו כן במידה ותרצו שנשלם עבורכם את האגרה, במידה ותבחרו לשלם לבד, יש לצרף את הקבלה למסמכים
                </p>
                <div className={`form-main-toggle ${alignment}`}>
                    <span></span>
                    <button
                        name='isFirstSubmission'
                        value={false}
                        onClick={(ev) => handleChangeToggle(ev, 'right')}
                        type='button'
                    >
                        כן
                    </button>
                    <button
                        name='isFirstSubmission'
                        value={true}
                        type='button'
                        onClick={(ev) => handleChangeToggle(ev, 'left')}
                    >
                        לא
                    </button>
                </div>

                <div
                    className={
                        alignment === 'left' ? 'agra-payment hide' : 'agra-payment'
                    }
                >
                    <div className='hidden-content'>
                        <div className='field'>
                            <label >תשלמו עבורנו סך של:</label>

                            <div className='icon-input'>
                                <Field
                                    name='toll'
                                    autoComplete="new-password"
                                    className={
                                        isError('toll')
                                            ? 'error'
                                            : data?.toll >= 0
                                                ? 'success'
                                                : ''
                                    }
                                    value={data && data.toll}
                                    type='number'
                                    onChange={handleChangeData}
                                />
                                {/* <input name='toll'
                    value={data && data.toll}
                    required
                    type='number'
                    data={data}
                  onChange={handleChangeData} /> */}
                                <div className='icon'>₪</div>
                            </div>
                            {isError('toll') && <p>{errors.toll}</p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
