import React, { useState, useEffect } from 'react';

export const SmallClaimUrgencyExecution = ({ data, setData, type }) => {
  const [alignment, setAlignment] = useState('right');
  const [personalDeliveryAlignment, setPersonalDeliveryAlignment] = useState(data.isPersonalDelivery ? 'right' : 'left');
  const [personalDeliveryUrgAlignment, setPersonalDeliveryUrgAlignment] = useState('left');
  const optionA = 'REG';
  const optionB = 'URG';
  const optionC = 'VERY_URG';
  const optionD = 'כן';
  const optionE = 'לא';

  useEffect(() => {
    const { urgency, personalDeliveryUrgency, isPersonalDelivery } = data;
    if (!urgency) {
      setData({ ...data, urgency: optionA });
    }
    if (!personalDeliveryUrgency && !isPersonalDelivery) {
      setData({ ...data, isPersonalDelivery: false });
    }

    setAlignment(
      urgency === optionB ? 'left' : urgency === optionC ? 'end' : 'right'
    );
    setPersonalDeliveryUrgAlignment(
      personalDeliveryUrgency === optionB ? 'left' : personalDeliveryUrgency === optionC ? 'end' : 'right'
    );
  }, []);

  useEffect(() => {
    let objDiv = document.getElementsByClassName("form7-urgency")[0]
    objDiv.scrollTo({
      top: objDiv.scrollHeight,
      behavior: 'smooth',
    })
  }, [data.isPersonalDelivery]);

  const handleUrgency = (event, newAlignment, field = '') => {
    event.preventDefault();
    const { name, value } = event.target;
    if (field === 'personalDeliveryUrgency') {
      setPersonalDeliveryUrgAlignment(newAlignment);
    } else {
      setAlignment(newAlignment);
    }
    setData({ ...data, [name]: value });
  };

  const handlePersonalDelivery = async (e, value) => {
    e.preventDefault();
    setPersonalDeliveryAlignment(value ? 'right' : 'left');
    setData({ ...data, isPersonalDelivery: value, personalDeliveryUrgency: data?.personalDeliveryUrgency || 'REG' });
  };


  const toggleFormatMap = {
    'smallClaim': 0,
    'personalDelivery': 1,
    'isPersonalDeliveryToggle': 2
  }

  const getTextByUrgency = (formName, alignment) => {
    const toggleFormat = getToggleFormat(toggleFormatMap[formName]);
    return toggleFormat[alignment]
  };


  const getTextPersonalUrg = () => {
    const { personalDeliveryUrgency } = data;
    return personalDeliveryUrgency === optionA ? '' : `*חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה ${personalDeliveryUrgency === optionC ? '12:00' : '15:00'} יחל ביום שלמחרת*`
  }


  const getToggleFormat = (formatIdx) => {
    const toggleFormat = [
      ['הגשה תוך 4 ימי עסקים מיום ביצוע ההזמנה (תוספת 150₪)',
        'הגשה תוך 7 ימי עסקים מיום ביצוע ההזמנה',
        'הגשה תוך 2 ימי עסקים מיום ביצוע ההזמנה (תוספת 300₪)'],
      ['ביקור ראשון תוך 1 ימי עסקים מיום קבלת המסמכים, וכל ביקור נוסף תוך 2 ימי עסקים',
        'ביקור ראשון תוך 5 ימי עסקים מיום קבלת המסמכים, וכל ביקור נוסף תוך 5 ימי עסקים מהביקור הקודם',
        'ביקור ראשון ביום קבלת המסמכים, וכל ביקור נוסף תוך 2 ימי עסקים'],
      ['מזכירות בית המשפט תשלח את כתב התביעה והזמנה לדיון בדואר רשום, לכתובת הנתבע/ים כפי שנמסרו בכתב התביעה.',
        'ביצוע המצאה של כתב התביעה והזמנה לדין במסירה אישית והגשת אישור המסירה + תצהיר מוסר מאומת ע״י עו״ד לתיק בית המשפט בו נפתחה התביעה, לקידום התיק במהירות וביעילות.',
        '']
    ][formatIdx]

    return { 'left': toggleFormat[0], 'right': toggleFormat[1], 'end': toggleFormat[2] }
  }

  return (
    <div className='main-form form7-urgency'>
      <div className='modal-content-container block'>
        <h2 className='large-title'>מה רמת הדחיפות להגשת התביעה?</h2>
        <div className={`form-main-toggle toggle-three ${alignment}`}>
          <span></span>
          <button
            type='button'
            name='urgency'
            value={optionA}
            onClick={(e) => handleUrgency(e, 'right')}
          >
            {'רגיל'}
          </button>
          <button
            type='button'
            name='urgency'
            value={optionB}
            onClick={(e) => handleUrgency(e, 'left')}
          >
            {'דחוף'}
          </button>
          <button
            type='button'
            name='urgency'
            value={optionC}
            onClick={(e) => handleUrgency(e, 'end')}
          >
            {'בהול'}
          </button>
        </div>
        <p className='label'>{getTextByUrgency(type, alignment)}</p>
        {
          data.type === 'personLocate' &&
          <p className='label'>{getTextPersonalUrg()}</p>
        }


        <div className='second-alignment'>
          <h2 className='large-title'>האם נדרשת מסירה אישית לנתבע/ים לאחר הגשה?</h2>
          <div
            className={`form-main-toggle  ${data.isPersonalDelivery ? 'right' : 'left'}`}
          >
            <span></span>
            <button
              onClick={(e) =>
                handlePersonalDelivery(e, true)
              }
            >
              {optionD}
            </button>
            <button
              onClick={(e) =>
                handlePersonalDelivery(e, false)
              }
            >
              {optionE}
            </button>
          </div>
        </div>
        <p className='label'>{getTextByUrgency('isPersonalDeliveryToggle', personalDeliveryAlignment)}</p>

        {
          data.isPersonalDelivery &&
          <div className='second-alignment'>
            <h2 className='large-title'>מה רמת הדחיפות לביצוע המסירה?</h2>

            <div className={`form-main-toggle toggle-three ${personalDeliveryUrgAlignment}`}>
              <span></span>
              <button
                type='button'
                name='personalDeliveryUrgency'
                value={optionA}
                onClick={(e) => handleUrgency(e, 'right', 'personalDeliveryUrgency')}
              >
                {'רגיל'}
              </button>
              <button
                type='button'
                name='personalDeliveryUrgency'
                value={optionB}
                onClick={(e) => handleUrgency(e, 'left', 'personalDeliveryUrgency')}
              >
                {'דחוף'}
              </button>
              <button
                type='button'
                name='personalDeliveryUrgency'
                value={optionC}
                onClick={(e) => handleUrgency(e, 'end', 'personalDeliveryUrgency')}
              >
                {'בהול'}
              </button>
            </div>
            <p className='label'>{getTextByUrgency('personalDelivery', personalDeliveryUrgAlignment)}</p>
            <p className='label'>{getTextPersonalUrg()}</p>
          </div>
        }

      </div>
    </div>
  );
};

export default SmallClaimUrgencyExecution;
