import React, { useState, useEffect, useRef } from "react";

export function TooltipFormsExtra({ text = '' }) {
    const [tooltipPosition, setTooltipPosition] = useState({});
    const tooltipRef = useRef();


    useEffect(() => {
        const tooltipRect = tooltipRef.current.getBoundingClientRect();
        const tooltipWidth = tooltipRect.width
        if (tooltipRect.x < 10) {
            setTooltipPosition({ top: '-40px', left: `${(tooltipWidth / 2)}px` })
        } else {
            setTooltipPosition({ top: '-40px', left: '50%' })
        }
    }, []);

    return (
        <div className='tooltip-forms-extra' ref={tooltipRef} style={{ ...tooltipPosition }}>
            <p className="top">
                {text}
            </p>
            <div className="triangle" />
        </div>
    )
}