const INITIAL_STATE = {
    orderPrice: null,
    orderId: null
}
export function trackingReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'SET_ORDER_PRICE':
            return { ...state, orderPrice: action.data};
        case 'SET_ORDER_ID':
            return { ...state, orderId: action.data};
        default:
            return state
    }
}