import React from "react";
import close from '../../images/close.svg';
import errorOps from '../../images/errorOps.png';
// import errorOps from '../../images/';
import { useViewport } from '../../Hooks/useViewport'
import db, { trace, perf} from '../../integrations/firebase';

export function PriceErrorPopup({ onClosePopup }) {
    const t = trace(perf, "AllActions");
    t.start();
    const { width } = useViewport();
    // const breakpoint = 1111;
    const isMobile = width <= 768;

    const setModalClose = () => {
        onClosePopup(false)
    };

    const onConfirmAction = () => {
        onClosePopup(false)
    };

    const returnValue = (
        <div className='pop-up-background'>
            <div className='system-msg'>
                <div className='msg-content'>
                    <h3> אופס!</h3>
                    <img
                        className='btn-error'
                        src={errorOps}
                        alt='errorOps'
                    />
                    <img
                        className='btn-close'
                        onClick={() => { setModalClose() }}
                        src={close}
                        alt='close'
                    />
                </div>
                <p>{'לא ניתן להשלים את ההזמנה דרך האתר.'}</p>
                {isMobile ?
                    <>
                        <p>{'להשלמת ההזמנה התקשרו אלינו -'}</p>
                        <p>{'03-3817000'}</p>
                    </>
                    :
                    <p>{'להשלמת ההזמנה התקשרו אלינו - 03-3817000'}</p>
                }

                <div className={`button-container ${isMobile ? 'mobile-container' : ''}`}>
                    {/* {<button
                        type='button'
                        className='main-btn undo'
                        onClick={() => {
                            dispatch(updateErrorModal({ errorText: '', status: 'close' }));
                        }}
                    >
                        ביטול
                    </button>} */}
                    {isMobile ?
                        <a href="tel:03-3817000"
                            className={`main-btn accept ${isMobile ? 'mobile-btn' : ''}`}
                        >{'התקשרו 03-3817000'}</a>
                        :
                        <button
                            type='button'
                            className={`main-btn accept`}
                            onClick={() => { onConfirmAction() }}
                        >
                            {'אישור'}
                        </button>
                    }
                </div>
            </div>
        </div>
    );
    t.stop();
    return returnValue
}


