import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import arrow from '../../images/dropdown-arrow.svg';
import remove from '../../images/remove-grey.svg';
import { updateErrorModal } from '../../store/actions/systemActions';
import { doc, updateDoc } from "@firebase/firestore";
import db, { updateUserMonday, Logger } from '../../integrations/firebase'
import { updateUser } from '../../store/actions/userActions'
const logClient = new Logger(`ContactList`);

export const ContactList = ({ handleChangeData, data, formState, onSetContact, isError, errors, typeOfForm }) => {
  const [isContactsDropdownOpen, setIsContactsDropdownOpen] = useState(false);
  const [contactName, setContactName] = useState('');
  const [contactIdToRemove, setContactIdToRemove] = useState(null);
  const activeUser = useSelector((state) => state.userReducer.user);
  const isPopUpModal = useSelector((state) => state.systemReducer.isPopUpModal);
  const refContact = useRef()
  const dispatch = useDispatch()

  // console.log('activeUser.userContacts', activeUser.userContacts)
  // console.log('typeOfForm', typeOfForm)
  useEffect(() => {
    setContactName(
      formState?.currRecipient?.personName ||
      formState?.currRecipient?.companyName ||
      formState?.currRecipient?.officeName ||
      '')
  }, [formState]);

  useEffect(async () => {
    const { isDeleteContact, status } = isPopUpModal;
    if (contactIdToRemove && isDeleteContact && status === 'submit') {

      const newUserContacts = activeUser.userContacts ? [...activeUser.userContacts] : []
      const userRef = doc(db, "users", activeUser.uid);
      const contactIdxToRemove = newUserContacts.findIndex(contact => contact.id === contactIdToRemove)

      newUserContacts.splice(contactIdxToRemove, 1)

      const updatedUser = {
        ...activeUser,
        userContacts: [...newUserContacts]
      }
      // console.log('%c  updatedUser:', 'color: white;background: red;', updatedUser);

      try {
        dispatch(updateUser({ ...updatedUser }));
        await updateDoc(userRef, updatedUser, { merge: true }).then((r) => { }).catch((e) => {
          logClient.error('error when updating user data');
          logClient.error(JSON.stringify(updatedUser))
          logClient.error(JSON.stringify(e));
        });
        // console.log('%c  { itemId: updatedUser.mondayId, user: updatedUser }:', 'color: white;background: red;', { itemId: updatedUser.mondayId, user: updatedUser });
      }
      catch ({ message }) {
        console.log('%c  error:', 'color: white;background: red;', message);
      }

      setContactIdToRemove(null);
    }

  }, [isPopUpModal.status, isPopUpModal.isDeleteContact]);

  const onRemoveContact = (contact) => {
    setContactIdToRemove(contact.id)
    dispatch(
      updateErrorModal({
        title: 'אזהרה',
        errorText: `האם ברצונך למחוק את ${contact.name || 'ללא שם'}?`,
        submitText: 'אישור',
        status: 'delete_contact',
        isCancel: true
      })
    );
  }

  return (
    <>
      <div className='field'>
        <label className='input-label'>
          {data.type === 'documentTransfer' ? 'שם איש קשר/חברה*'
            :
            (formState?.currRecipient && formState?.currRecipient.type === 'ADAM_PRATI'
              ? 'שם מלא*'
              : (data.type === 'officeDelivery' ? 'שם המשרד*' : 'שם בית העסק*')
            )}
        </label>

        <div
          ref={refContact}
          className={
            isContactsDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
          }
        >
          <div className='dropdown-input'>
            <input
              type="text"
              // placeholder='הזינו שם או בחרו מתוך הרשימה'
              placeholder={(data.type === 'officeDelivery' ? '' : 'הזינו שם או בחרו מתוך הרשימה')}
              onBlur={() => { setIsContactsDropdownOpen(false) }}
              onChange={(event) => {
                handleChangeData(event)
                setIsContactsDropdownOpen(true);
              }}
              value={
                formState?.currRecipient?.personName ||
                formState?.currRecipient?.companyName ||
                formState?.currRecipient?.officeName ||
                ''
              }
              onClick={() => { setIsContactsDropdownOpen(true); }}

              name={
                formState?.currRecipient && formState?.currRecipient.type === 'ADAM_PRATI'
                  ? 'personName'
                  : (data.type === 'officeDelivery' || formState?.currRecipient?.type === 'officeDelivery' ? 'officeName' : 'companyName')
              }
              autoComplete="off"
              className={
                isError('companyName') || isError('personName') || isError('officeName') || isError('recipientName')
                  ? 'error'
                  : formState?.currRecipient?.personName ||
                    formState?.currRecipient?.companyName ||
                    formState?.currRecipient?.officeName
                    ? 'success'
                    : ''
              }
            />
            <button
              type='button'
              onClick={() => {
                setIsContactsDropdownOpen(!isContactsDropdownOpen);
              }}
            >
              <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
            </button>
          </div>

          {isError('personName') && <p className='error-label'>{errors.personName}</p>}
          {isError('companyName') && <p className='error-label'>{errors.companyName}</p>}
          {isError('officeName') && <p className='error-label'>{errors.officeName}</p>}
          {isError('recipientName') && <p className='error-label'>{errors.recipientName}</p>}

          <div className='dropdown-list'>
            {activeUser.userContacts.filter((option) => (option.name.includes(contactName) && (option.type === typeOfForm || data.type === 'documentTransfer'))).map((contact, idx) => {
              return (
                <div className='dropdown-option' key={idx}>

                  <p
                    onMouseDown={() => {
                      onSetContact(contact)
                      setIsContactsDropdownOpen(false);
                    }}>
                    {contact.name}
                  </p>
                  <div className="btn-container"
                    onClick={(event) => {
                      event.stopPropagation();
                      onRemoveContact(contact);
                    }}>
                    <img
                      src={remove}
                      alt='remove'

                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactList;
