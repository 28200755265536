// npm : https://www.npmjs.com/package/react-dropzone
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import {
  removeFiles,
  setFiles,
  addFiles
} from '../../../store/actions/fileActions';
import arrow from '../../../images/arrowdropdown.png';
import eye from '../../../images/eye.png';
import remove from '../../../images/remove1.png';
import copypaste from '../../../images/copypaste.png';
import { Field } from 'formik';
import { updateErrorModal, setIsLoading } from '../../../store/actions/systemActions';
import doc from '../../../images/doc.svg'
import { useViewport } from '../../../Hooks/useViewport'
import { ClipboardPowerOfAttorney } from '../../Utils/ClipboardPowerOfAttorney'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";
import { TailSpin } from 'react-loader-spinner'

export function UploadFile({ data, setData, errors, submitForm, setSubmitForm, isTranslation, pageName, type, formState }) {
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.userReducer.user);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownBindingOpen, setIsDropdownBindingOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const isLoading = useSelector((state) => state.systemReducer.isLoading)
  const options = [
    { text: '0-50 עמודים להדפסה (20 ₪)', numOfPages: 49 },
    { text: '51-100 עמודים להדפסה (40 ₪)', numOfPages: 60 },
    { text: '101-200 עמודים להדפסה (70 ₪)', numOfPages: 150 },
    { text: '201-300 עמודים להדפסה (90 ₪)', numOfPages: 250 },
    { text: '301-400 עמודים להדפסה (105 ₪)', numOfPages: 350 },
    { text: '401-500 עמודים להדפסה (120 ₪)', numOfPages: 450 },
    { text: '501+ עמודים להדפסה (150 ₪)', numOfPages: 550 },
  ];

  const bindings = [
    { text: 'ללא כריכות', numOfBindings: -1 },
    { text: '1 כריכות + דגלונים (₪ 30)', numOfBindings: 1 },
    { text: '2 כריכות + דגלונים (₪ 60)', numOfBindings: 2 },
    { text: '3 כריכות + דגלונים (₪ 90)', numOfBindings: 3 },
    { text: '4 כריכות + דגלונים (₪ 120)', numOfBindings: 4 },
    { text: '5 כריכות + דגלונים (₪ 150)', numOfBindings: 5 }
  ];
  const withoutBindings = ['NotarizedTranslation', 'inheritanceRegistration', 'landRegistration', 'landSupervisor', 'companiesRegistration', 'partnershipsRegistration', 'associationsAndCompanies']
  const withoutPages = ['NotarizedTranslation', 'landRegistration', 'landSupervisor']

  const pagesRef = useRef()
  const bindsRef = useRef()

  let files = useSelector((state) => state.fileReducer.Files);
  // if ((type === 'landRegistration')) {
  //   if (pageName === 'UploadFile') {
  //     files = files.filter(file => !file.name.includes('ייפוי כח'));
  //   } else {
  //     files = files.filter(file => file.name.includes('ייפוי כח'));
  //   }
  // } else if (type === 'landSupervisor') {
  //   files = files.filter(file => !file.name.includes("_land_supervisor"));
  // }

  const { width } = useViewport();
  const breakpoint = 767;

  useEffect(() => {
    setData(isTranslation ? { ...data, isTranslation } : { ...data, });
  }, []);

  useEffect(() => {
    const onBodyClick = (event) => {

      if (pagesRef?.current?.contains(event.target)) {
        return;
      }
      setIsDropdownOpen(false)

      if (bindsRef?.current?.contains(event.target)) {
        return;
      }
      setIsDropdownBindingOpen(false)

    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener("click", onBodyClick, {
        capture: true,
      });
    };
  }, []);

  useEffect(() => {
    async function getFilesFromStorage() {
      try {
        dispatch(setIsLoading(true))
        const storage = await getStorage();
        const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}`);
        const filesList = await listAll(storageRef)
        const files = filesList.items.map(async (file) => {
          const str = getStorage();
          const refs = ref(str, file._location.path_);
          return {
            name: file.name,
            url: await getDownloadURL(refs, file.fullPath),
            _id: file.fullPath
          }
        })
        if (files.length === 0) {
          dispatch(setIsLoading(false))
        }

         Promise.all(files).then((values) => {
           dispatch(setFiles(values))
           dispatch(setIsLoading(false))
         });
      } catch (error) {
        console.log('%c  error:', 'color: white;background: red;', error);
      } finally {
        dispatch(setIsLoading(false))
      }

    }
    getFilesFromStorage()
  }, [])

  useEffect(() => {
    async function clearFiles() {
      const storage = await getStorage();
      const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}`);
      const filesList = await listAll(storageRef)
      filesList.items.map(async (file) => {
        const storage = getStorage();
        const fileRef = ref(storage, file._location.path_);
        await deleteObject(fileRef)
      })
      dispatch(setFiles([]))
    }
    if ((type === 'apostilSubmission' && !isTranslation)) clearFiles();
  }, [isTranslation])

  useEffect(() => {
    if (type === 'landRegistration' && submitForm && files.length === 0) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'חובה להעלות מסמכים',
          submitText: 'אישור',
          isCancel: false
        })
      );
    }
    else if ((pageName !== 'NotarizedTranslation') && (data?.isFirstSubmission !== 'collect') && submitForm && files.length === 0) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'לא הועלו קבצים להדפסה',
          submitText: 'אישור',
          isCancel: false
        })
      );
    } else if ((pageName !== 'NotarizedTranslation') && submitForm && errors.pages) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'לא נבחרו כמות הדפים להדפסה',
          submitText: 'אישור',
          isCancel: false
        })
      );
    } else if (files.length === 0 && submitForm && (type === 'courtSubmission')) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'אזהרה',
          errorText: 'לא הועלה אף קובץ, כדי להמשיך יש להעלות קובץ.',
          submitText: 'אישור',
          isCancel: false
        })
      );
    }
  }, [data.pages, submitForm, errors, files]);



  const handleChangeCheckbox = (event) => {
    const { value } = event.target;
    const { name } = event.target;
    setData({
      ...data,
      [name]: +(value)
    });
    setIsDropdownOpen(false)
    setIsDropdownBindingOpen(false)
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    console.log('onDrop')
    const storage = await getStorage();
    acceptedFiles.forEach(async (file) => {
      let fileName = file.name

      const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}/${fileName}`);
      dispatch(setIsLoading(true))
      const isUploaded = await uploadBytes(storageRef, file)
      if (isUploaded) dispatch(setIsLoading(false))
      const url = await getDownloadURL(storageRef)
      let uploadedFile = {
        name: fileName,
        url,
        filePath: `${activeUser.uid}/${data.storageDocId}/${fileName}`,
        _id: `${activeUser.uid}/${data.storageDocId}/${fileName}`
      };

      dispatch(addFiles(uploadedFile));
    });
  }, [activeUser]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


  const handleClickDeleteFile = async (fileId) => {
    const storage = getStorage();
    const fileRef = ref(storage, fileId);
    await deleteObject(fileRef)
    dispatch(removeFiles(fileId));
  };

  const copyToClipboard = (containerid) => {
    var range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().removeAllRanges(range);
    window.getSelection().addRange(range);
    document.execCommand("copy");
    setIsCopied(true)
  }

  const handleDownloadClick = () => {
    const powerOfAttorneyLink = 'https://firebasestorage.googleapis.com/v0/b/delawvery-prod.appspot.com/o/documents%2F%D7%99%D7%99%D7%A4%D7%95%D7%99%20%D7%9B%D7%97%20-%20%D7%A0%D7%95%D7%A1%D7%97.docx?alt=media&token=27777f85-f023-4183-8ea0-b49dd3f3f7df'
    window.open(powerOfAttorneyLink, '_blank');
    setIsCopied(true)
  };

  const getTitle = () => {
    switch (type) {
      case 'landRegistration':
       return "העלו ייפוי כח ומכתבי דחייה לצורך איסוף התיק/ים"
      case 'courtSubmission':
        return 'הדפסת ייפוי כח'
      default:
        return 'העלאת מסמכים'
    }
  }

  return (
    <div className={` ${!(pageName === 'NotarizedTranslation') && "main-form"} upload-file ${(pageName === 'NotarizedTranslation') && 'notarized'}`}>
      <div className="modal-content-container block" >
        {(pageName !== 'NotarizedTranslation') &&
          <>
            {type === 'landRegistration' && pageName === 'PowerOfAttorney' &&
              <div className='cmp-line' />
            }
            <h2 className='large-title'>{getTitle()}</h2>
          </>
        }
        {type === 'courtSubmission' && data.isFirstSubmission === 'collect' &&
          <>
            <div className='power-of-att-info'>
              <p>כדי לאסוף מסמכים מבית המשפט, על ייפוי הכח מעורך/כת הדין לשליח להיות כתוב על גבי נייר המכתבים של המשרד, בחתימת וחותמת עורך/כת הדין.</p>
              <p className='bold'>הכנו לכם נוסח ייפוי כח מקובל להעתקה והדבקה מהירה.</p>
              <button className='main-btn long copy-btn' onClick={() => { handleDownloadClick() }} type='button'>{`${isCopied ? 'הנוסח אצלכם!' : 'לחצו כאן להורדת הנוסח'}`}
                <img className='icon-copypaste' src={copypaste} alt='copypaste-icon' />
              </button>
            </div>
            <ClipboardPowerOfAttorney isHidden={true} />
            <ClipboardPowerOfAttorney />
            <div className='line'></div>
          </>
        }

        {(pageName !== 'NotarizedTranslation' && type !== 'landRegistration' && (type === 'courtSubmission' && data.isFirstSubmission !== 'collect')) &&
          (<p className='subtitle'>העלו או גררו את המסמכים אותם תרצו לשלוח</p>)
        }

        {type === 'courtSubmission' && data.isFirstSubmission === 'collect' &&
          <>
            <h2 className='large-title'>העלאת ייפוי כח</h2>
            <p>העלו ייפוי כח או כל מסמך אחר שצריך להציג בערכאה השיפוטית</p>
          </>
        }

        {(type === 'landRegistration') &&
          (<p className='subtitle'>{'בייפוי הכח חובה לכלול את פרטי התיק/ים, גוש / חלקה ולחתום עם מספר הרישיון של עו״ד (עדיף על נייר המכתבים של המשרד).'}</p>
          )}
        {(type === 'landRegistration') &&
            (<strong className='subtitle'>{' יש להשאיר מקום פתוח להזנת שם ומספר הזהות של השליח, לפי הנוסח שלהלן:'}</strong>
            )}
        {(type === 'landRegistration') &&
            (<p className='subtitle'><i>{'אני הח״מ, [שם של העו״ד], מייפה את כוחה של חברת דלוברי בע״מ וכל אחד מעובדיה ו/או מי מטעמה, לרבות _______________ ת.ז. _____________, לאסוף בשמי ועבורי את התיקים הבאים מלשכת רישום המקרקעין: [פרטי התיקים לאיסוף]'}</i></p>
          )}
        {(type === 'landRegistration') &&
            (<br/>)}

        <div className='upload-container'>
          {(pageName === 'NotarizedTranslation') && <div className="text-notarized">
            <p>צרפו את המסמך לקבלת הצעת מחיר מהירה</p>
            <p>*לא חובה</p>
          </div>}
          <div className='drop-area' {...getRootProps()}>
            <input {...getInputProps()} required={files.length === 0 && (pageName !== 'NotarizedTranslation') && data.isFirstSubmission !== 'collect'} />
            <div className="inside-drop-area">
              <img src='/images/upload.svg' alt='upload' />
              {isDragActive ? (
                <h3>שחרר את הקבצים כאן</h3>
              ) : (
                <h3>גוררים את הקבצים ומשחררים כאן</h3>
              )}
              <p>קבצים נתמכים:  PDF/ JPEG/ DOC</p>
              <button className='main-btn long' type='button'>
                העלאת קבצים
              </button>
            </div>
          </div>
        </div>

        {files.length > 0 && !isLoading && (<div className="files-container">
          <h3>הקבצים שלך</h3>
          {width > breakpoint &&
            <div className="titles">
              <p>שם הקובץ</p>
              <p>סטאטוס העלאה</p>
              <div className="btn-titles">
                <p>צפייה</p>
                <p>מחיקה</p>
              </div>
            </div>}
          {files.map((file) => {
            return (
              <div className="file-upload" >
                <div className="details">
                  <img src={doc} alt="document" />
                  <p>{file.name}</p>
                </div>

                {
                  width > breakpoint &&
                  <div className="status">
                    <p>הושלם</p>
                  </div>
                }

                <div className="btn-container">
                  <a href={file.url} target="_blank" rel="noreferrer">
                    <img
                      src={eye}
                      alt="preview"
                    />
                  </a>
                  <img

                    src={remove}
                    alt="remove"
                    onClick={() => handleClickDeleteFile(file._id)} />
                </div>
              </div>

            )
          }
          )}
        </div>
        )}
        {
          isLoading && <div className="spinner files-container">
            <TailSpin
              heigth="100"
              width="100"
              color='#4e71ff'
              ariaLabel='loading'
            />
          </div>
        }
        <div className="dropdowns">
          {!withoutPages.includes(pageName) && !withoutPages.includes(type) && data.isFirstSubmission !== 'collect' && <div className='number-of-copies'>
            <div className='note'>
              <p className='textTop'>עמודים להדפסה*</p>
              <p className='textBottom'>מספר העמודים הכולל להדפסה</p>
            </div>

            <div
              ref={pagesRef}
              className={
                isDropdownOpen ? 'dropdown-container open' : 'dropdown-container'
              }
            >
              <div className='dropdown-input' onClick={() => {
                setIsDropdownOpen(!isDropdownOpen);
              }}>
                <div
                  className='pages-to-print-text'
                >
                  {data.pages ? options.find(pagesOption => pagesOption.numOfPages === data.pages).text : 'כמה עמודים אנחנו צריכים להדפיס?'}
                </div>
                <button
                  type='button'

                >
                  <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                </button>
              </div>

              <div className='dropdown-list'>
                {options.map((option, idx) => {
                  return (
                    <div
                      key={idx}
                      className='dropdown-option'

                    >
                      <Field
                        id={'pages' + idx}
                        type='radio'
                        name='pages'
                        value={option.numOfPages}
                        onChange={handleChangeCheckbox}
                        checked={data.pages === option.numOfPages}
                      />
                      <label htmlFor={'pages' + idx}>{option.text}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          }
          {!withoutBindings.includes(pageName) && !withoutBindings.includes(type) && data.isFirstSubmission !== 'collect' && <div className='number-of-copies'>
            <div className='note'>
              <p className='textTop'>כריכה ודגלונים</p>
              <p className='textBottom'>כמה כריכות ספירלה עם/ בלי דגלונים</p>
            </div>

            <div
              ref={bindsRef}
              className={
                isDropdownBindingOpen ? 'dropdown-container open' : 'dropdown-container'
              }
            >
              <div className='dropdown-input' onClick={() => {
                setIsDropdownBindingOpen(!isDropdownBindingOpen);
              }}>
                <div
                  className='pages-to-print-text'
                >
                  {data.bindings ? bindings.find(bind => bind.numOfBindings === data.bindings).text : 'בחרו את מספר הכריכות'}
                </div>
                <button
                  type='button'

                >
                  <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                </button>
              </div>

              <div className='dropdown-list binds'>
                {bindings.map((binding, idx) => {

                  return (
                    <div
                      key={idx}
                      className='dropdown-option'

                    >
                      <Field
                        id={'bindings' + idx}
                        type='radio'
                        name='bindings'
                        value={binding.numOfBindings}
                        onChange={handleChangeCheckbox}
                        checked={binding.numOfBindings === data.bindings}
                      />
                      <label htmlFor={'bindings' + idx}>{binding.text}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          }
        </div>




      </div >
    </div >
  );
}
