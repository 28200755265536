const INITIAL_STATE = {
  Orders: [],
  currOrder: null,
  totalPrice: 0,
  nowOrder: null,
};

export function orderReducer(state = INITIAL_STATE, action) {

  switch (action.type) {
    case "SET_ORDERS":
      return {
        ...state,
        Orders: action.orders,
      };

    case "GET_Order":
      return {
        Orders: state.Orders.map((Order) =>
          Order._id === action.Order._id ? action.Order : Order
        ),
      };
    case "SET_NEW_ORDER":
      return {
        ...state,
        nowOrder: action.order,
      };
    case "UPDATE_NEW_ORDER":
      return {
        ...state,
        nowOrder: { ...state.nowOrder, ...action.order },
      };
    case "SET_Order":
      return {
        ...state,
        currOrder: action.Order,
      };
    case "REMOVE_Order":
      return {
        ...state,
        Orders: state.Orders.filter((Order) => Order._id !== action.OrderId),
      };
    case "ADD_Order":
      return {
        ...state,
        Orders: [...state.Orders, action.Orders],
      };
    case "UPDATE_ORDER":
      return {
        ...state,
        Orders: state.Orders.map((ord) => ord._id === action.order._id ? { ...action.order, orderActions: [...action.formatedOrder] } : ord)
      };
    case "GET_TOTAL_ORDERS_PRICE":
      return {
        ...state,
        totalPrice: state.Orders.reduce(function (totalPrice, Order) {
          return totalPrice + Order.price;
        }
        ),
      };
    default:
      return state;
  }
}
