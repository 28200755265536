import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { ReactComponent as Close } from '../../images/close.svg'
import { Field } from 'formik';
import { getAuth, updateEmail, reauthenticateWithCredential, EmailAuthProvider, fetchSignInMethodsForEmail } from "firebase/auth";
import { doc, updateDoc } from "@firebase/firestore";
import { updateUser } from '../../store/actions/userActions'
import db, { Logger, callUserAction } from '../../integrations/firebase'
import { setIsLoading } from '../../store/actions/systemActions'
import { TailSpin } from 'react-loader-spinner'


const logClient = new Logger(`UpdateEmailPopup`);

export const UpdateEmailPopup = ({ setShowEmailPopup }) => {
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.systemReducer.isLoading)
    const activeUser = useSelector((state) => state.userReducer.user);
    const [oldEmail, setOldEmail] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [pass, setPass] = useState('')
    const [errorMsg, setErrorMsg] = useState('')
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const onSubmit = async () => {
        try {
            //Validation check
            if (!emailRegex.test(newEmail)) {
                setErrorMsg('אנא הזן כתובת אימייל חוקית');
                return;
            }

            if (!emailRegex.test(oldEmail)) {
                setErrorMsg('אנא הזן כתובת אימייל חוקית עבור האימייל הנוכחי');
                return;
            }

            if (pass.length < 8) {
                setErrorMsg('הסיסמה צריכה להכיל לפחות 8 תווים');
                return;
            }


            dispatch(setIsLoading(true));
            const auth = getAuth();
            const user = auth.currentUser;

            const signInMethods = await fetchSignInMethodsForEmail(auth, newEmail);
            if (signInMethods.length > 0) {
                setErrorMsg('אימייל כבר קיים במערכת')
                return;
            }

            const updatedUser = {
                ...activeUser,
                email: newEmail
            }

            // const credential = { email: oldEmail, password: pass };
            const credential = EmailAuthProvider.credential(user.email, pass);

            await reauthenticateWithCredential(user, credential);

            // Reauthentication successful, proceed to update email in auth firebase
            await updateEmail(user, newEmail);

            if (activeUser.accountType === 'חשבון פרטי') {
                await callUserAction({
                    uid: activeUser.uid,
                    email: newEmail,
                    type: "updateNewEmailForPrivateUsers"
                })
            }

            //Update user in Front and Firebase
            dispatch(updateUser({ ...updatedUser }));
            const userRef = doc(db, "users", activeUser.uid);
            await updateDoc(userRef, updatedUser, { merge: true }).then((r) => { }).catch((e) => {
                logClient.error('error when updating user data');
                logClient.error(JSON.stringify(updatedUser))
                logClient.error(JSON.stringify(e));
            });

            setShowEmailPopup(false);
        } catch (error) {
            const errorMessage = error.message;
            if (errorMessage && errorMessage.includes('auth/wrong-password')) setErrorMsg('אימייל או סיסמה אינם נכונים')
            console.error('Error occurred:', error);
        } finally {
            dispatch(setIsLoading(false));
        }
    };

    return <div className={`pop-up-login update-email-pop-up`}>
        <div className="container">
            <div className="header-btns">
                <div></div>
                <Close onClick={() => { setShowEmailPopup(false) }} />
            </div>
            <div className="content">
                <div className="msg-content ">
                    <div className="login-step">

                        <h2 className="main-title bold">עדכון אימייל</h2>
                        <p className="decription">
                            אנא הזינו את האימייל והסיסמא הנוכחיים ואת האימייל אותו תרצו לעדכן
                        </p>
                        <div className="input-container">
                            <div className='field'>
                                <label>אימייל חדש*</label>
                                <Field
                                    type='text'
                                    className={!newEmail ? '' : emailRegex.test(newEmail) ? 'success' : 'error'}
                                    placeholder='אימייל חדש'
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    name='email'
                                    value={newEmail}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className='field'>
                                <label>אימייל נוכחי*</label>
                                <Field
                                    type='text'
                                    className={!oldEmail ? '' : emailRegex.test(oldEmail) ? 'success' : 'error'}
                                    placeholder='אימייל נוכחי'
                                    onChange={(e) => setOldEmail(e.target.value)}
                                    name='email'
                                    value={oldEmail}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className='field'>
                                <label>סיסמה*</label>
                                <Field
                                    type='password'
                                    className={!pass ? '' : pass.length >= 8 ? 'success' : 'error'}
                                    placeholder='סיסמה*'
                                    value={pass}
                                    onChange={(e) => setPass(e.target.value)}
                                    name='password'
                                />
                            </div>
                        </div>
                        {pass.length < 8 &&
                            <p>
                                סיסמה צריכה להכיל לפחות 8 תווים
                            </p>
                        }
                    </div>
                </div>
                <p>{errorMsg}</p>
                <button type="button" onClick={onSubmit} className='main-btn long register' >
                    {isLoading ? <div className='spinner'>
                        <TailSpin
                            heigth="100"
                            width="100"
                            color='#4e71ff'
                            ariaLabel='loading'
                        />
                    </div> : 'עדכון אימייל'}
                </button>
            </div>

        </div>
    </div>
};


