import { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";

import { DynamicCmp } from './DynamicCmp';

//Firebase
import { getStorage, ref, listAll, deleteObject } from "firebase/storage";

// imports for update contact
import { doc, updateDoc } from "@firebase/firestore";
import db, { Logger, updateUserMonday, analytics, logEvent, remoteConfig } from '../../integrations/firebase'

import { updateUser } from '../../store/actions/userActions'
import OrdersService from "../../services/orderService";

//Error handling
import errorHandler from '../../integrations/errorHandler'

// imports for store
import { useSelector, useDispatch } from 'react-redux';
import { updateData, dataSet } from '../../store/actions/dataActions';
import { updateSystemData, setMobileState, updateContactToSave, updateSecondContactToSave, updateErrorModal } from '../../store/actions/systemActions';
import {
  addRecipient,
  editRecipient,
} from '../../store/actions/recipientActions';
import { addOrder, updateOrder } from '../../store/actions/orderActions';
import { useNavigate } from "react-router-dom";
import { ReactComponent as Back } from '../../images/back.svg'
import { ProgressBar } from '../Utils/ProgressBar';
import orderService from '../../services/orderService'
import { Formik, Form } from 'formik';
import {
  validationCourtAddress,
  validationSchemaCompany,
  validationSchemaPerson,
  validationWhatServed,
  validationPass,
  validationFirstSubmission,
  validationSchemaAddressCollectDocuments,
  validationSchemaAddressCollectDocumentsWithSize,
  validationSchemaAddressCollectDocumentsWithoutNumber,
  validationSchemaAddressReturnDocumentsWithoutNumber,
  validationSchemaAddressReturnDocuments,
  validationSchemaOffice,
  validationUploadPage,
  validationApostilleVerification,
  validationCollectBranchSelecting,
  validationReturnBranchSelecting,
  validationFinalPage,
  validationLocateAddress,
  validationRecipients,
  validationFinalPageWithoutPrices,
  validationFinalPageWithoutPricesWithCaseNeededAndAuthNeeded,
  validationFinalPageWithoutPricesWithAuthNeeded,
  validationFinalPageWithoutPricesWithCaseNeeded,
  validationFinalPageWithPricesWithCaseNeededAndAuthNeeded,
  validationFinalPageWithPricesWithCaseNeeded,
  validationFinalPageWithPricesWithAuthNeeded,
  validationChooseOfficePage,
  validationChooseClaimTypePage,
  validationSchemaClaimant,
  validationSchemaPersonalDefendant,
  validationSchemaPersonalDefendantWithoutAddress,
  validationSchemaPersonalDefendantWithoutAddressAndId,
  validationSchemaPersonalDefendantWithoutId,
  validationSchemaCompanyDefendant,
  validationSmallClaimRecipients,
  validationSmallClaimDescription,
  validationSmallClaimDescriptionWithoutStatementB,
  validationSmallClaimContract,
  validationCollectFileDetailsPage,
  validationActionSummaryPage,
  validationPowerOfAttorneyPage,
  validationPowerOfAttorneyPageUploadFiles,
  validationDocumentTransferFromMe,
  validationDocumentTransferToMe,
  validationDocumentTransferBoth,
  validationSchemaDocumentTransferPerson,
  validationSchemaDocumentTransferOffice,
  validationPersonalAddress,
  validationCheckUpload,
  validationCheckUploadUpdate,
  validationIdLocateAddress,
  validationDocumentTransferFromMeWithSign,
  validationDocumentTransferToMeWithSign,
  validationDocumentTransferBothWithSign,
} from './validitions';
import { useViewport } from '../../Hooks/useViewport'
import { utilService } from '../../services/utilService'

import { defendantSet, claimantSet } from '../../store/actions/smallClaimActions';

import { getValue, fetchAndActivate, getBoolean } from "firebase/remote-config";

const logClient = new Logger(`GeneralForm`);
export const GeneralForm = ({ DynamicSteps, type, progressBarPhases, scrollTo }) => {
  const navigate = useNavigate();
  const totalRecipients = useSelector((state) => state.recipientReducer.Recipients);
  const data = useSelector((state) => state.dataReducer.data);
  const item = utilService.getActionByType(type)
  const nid = item.nid;
  const mobile = useSelector((state) => state.mobileReducer.mobileState);
  const systemData = useSelector((state) => state.systemReducer.systemData);
  const contactToSave = useSelector((state) => state.systemReducer.contactToSave);
  const secondContactToSave = useSelector((state) => state.systemReducer.secondContactToSave);
  const activeUser = useSelector((state) => state.userReducer.user);
  const isLoading = useSelector((state) => state.systemReducer.isLoading)

  const orderRecipientsIds = useSelector((state) => state.dataReducer.data.recipients);
  const myRecipients = totalRecipients.filter((recipient) => orderRecipientsIds.includes(recipient._id));
  const currClaimant = useSelector((state) => state.smallClaimReducer.currClaimant);
  const currDefendant = useSelector((state) => state.smallClaimReducer.currDefendant);
  const uploadedFiles = useSelector((state) => state.fileReducer.Files);
  const dispatch = useDispatch();
  const [progressBarPhase, setProgressBarPhase] = useState(1);
  const [touch, setTouch] = useState({});
  const [submitForm, setSubmitForm] = useState(false);
  const [step, setStep] = useState(0);
  const [formState, setFormState] = useState({
    nextStep: null,
    formSteps: DynamicSteps.handleSteps(data, {}, '', systemData?.isTestUser),
    currRecipient: null,
    isFinalStep: null,
  });
  // console.log('remoteConfig', remoteConfig)
  // const val = getValue(remoteConfig, "isTestUser");
  // console.log('val', val)
  // console.log('totalRecipients',totalRecipients)
  console.log('data', data)
  // console.log('mobile',mobile)
  // console.log('contactToSave', contactToSave)
  // console.log('activeUser', activeUser)
  // console.log('uploadedFiles',uploadedFiles)
  // console.log('touch',touch)
  // console.log('formState',formState)
  // console.log('systemData', systemData)
  const { width } = useViewport();
  const breakpoint = 1111;
  useEffect(() => {
    dispatch(dataSet({ ...data, type, storageDocId: data?.storageDocId || orderService.makeId() }));
    return () => {
      dispatch(dataSet({ recipients: [], files: [] }));
    }
  }, [])

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const x = await fetchAndActivate(remoteConfig);
  //       console.log('response', x)
  //       console.log(remoteConfig)
  //       const isTestUserFetch = getBoolean(remoteConfig, "isTestUser");
  //       console.log('isTestUserFetch', isTestUserFetch);
  //       setIsTestUser(isTestUserFetch);
  //       dispatch(dataSet({ ...data, isTestUser: isTestUserFetch }));
  //     } catch (error) {
  //       console.log('Error', error);
  //     }
  //   };
  //   fetchData();
  // }, [activeUser]);

  useEffect(() => {
    const { isFormModalOpen, isEditMode } = systemData
    if (!isFormModalOpen && !isEditMode) {
      dispatch(updateSystemData({
        ...systemData,
        action: {
          formSubtitle: item?.formSubtitle || '',
          formId: item.nid,
          formTitle: item.title,
          formPrice: item.price,
          formImage: item.image,
          formIsActive: item.isActive,
          formIsPopular: item.isPopular,
          formCommon: item.isCommon,
          type: item.type,
        },
        isFormModalOpen: true
      }));
    }
  }, []);

  useEffect(() => {
    setTouch({});
  }, [formState.nextStep]);

  useEffect(() => {
    // setFormState({
    //   ...formState,
    //   formSteps: DynamicSteps.handleSteps(data, formState),
    // });
    setFormState((prevState) => ({
      ...prevState,
      formSteps: DynamicSteps.handleSteps(data, formState, systemData, systemData?.isTestUser),
    }));
  }, [data]);

  useEffect(() => {
    const newSteps = DynamicSteps.handleSteps(data, formState, systemData, systemData?.isTestUser);
    setFormState((prevState) => ({
      ...prevState,
      nextStep: newSteps[step],
    }));
  }, [step]);

  useEffect(() => {
    if (systemData.isEditMode) {
      setStep(formState.formSteps.length - 1)
      setFormState({
        ...formState,
        isFinalStep: true,
      });
    }
  }, [systemData.isEditMode])

  // useEffect(() => {
  //   if (formState.formSteps.length - 1 === step) {
  //     setFormState({
  //       ...formState,
  //       isFinalStep: true,
  //     });
  //   }
  // }, [formState.formSteps.length, step])

  const setData = (data) => {
    dispatch(updateData({ ...data, action: systemData.isEditMode ? data.action : systemData.action }));
  };

  const onKeyDown = (keyEvent) => {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  const removeSubmitter = async (fileNameToExclude = '') => {
    const storage = getStorage();
    const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}`);
    const filesList = await listAll(storageRef);

    const deletePromises = filesList.items.map((file) => {
      // const storage = getStorage();
      // const fileRef = ref(storage, file._location.path_);
      // return deleteObject(fileRef);
      if (!file.name.includes(fileNameToExclude)) {
        const storage = getStorage();
        const fileRef = ref(storage, file._location.path_);
        return deleteObject(fileRef);
      } else {
        // If the file name includes the fileNameToExclude, do not delete it.
        return Promise.resolve();
      }
    });

    await Promise.all(deletePromises);
  }

  const onAddEditRecipient = async () => {
    const recipient = { ...formState.currRecipient };
    let eveningRecipients = data.eveningRecipients ? data.eveningRecipients : []
    if (recipient._id) {
      dispatch(editRecipient(recipient));
      if (type === 'personalDelivery' || (type === 'courtSubmission' && recipient.type !== 'officeDelivery')) eveningRecipients = getEveningRecipients(recipient)
      setData({
        ...data,
        isOtherTasks: false,
        eveningRecipients
      });
    } else {
      const newRecipient = await dispatch(addRecipient(recipient));
      if (type === 'personalDelivery' || (type === 'courtSubmission' && recipient.type !== 'officeDelivery')) eveningRecipients = getEveningRecipients(newRecipient)
      setData({
        ...data,
        recipients: [...data.recipients, newRecipient._id],
        isOtherTasks: false,
        eveningRecipients
      });
    }
  };

  const getEveningRecipients = (recipient) => {
    let eveningRecipients = data?.eveningRecipients ? [...new Set(data?.eveningRecipients)] : [];
    if (data.isEveningAction) {
      if (!eveningRecipients.includes(recipient._id)) {
        eveningRecipients.push(recipient._id);
      }
    } else {
      eveningRecipients = eveningRecipients.filter(id => id !== recipient._id);
    }
    return eveningRecipients
  }

  const onSetLastClaimant = (isReset) => {
    const claimants = [...data.claimants]
    if (isReset) dispatch(claimantSet({}))
    else dispatch(claimantSet(claimants[claimants.length - 1]))
  }
  const onSetLastDefendant = (isReset) => {
    const defendants = [...data.defendants]
    if (isReset) dispatch(defendantSet({}))
    else dispatch(defendantSet(defendants[defendants.length - 1]))
  }

  const onAddEditClaimant = async () => {
    const claimantsUpdated = [...data.claimants]
    if (claimantsUpdated.length) {
      const idxToUpdate = claimantsUpdated.findIndex((claimant) => claimant.id === currClaimant.id)
      if (idxToUpdate === -1) {
        setData({
          ...data,
          claimants: [...claimantsUpdated, currClaimant]
        });
      } else {
        claimantsUpdated[idxToUpdate] = currClaimant
        if (currClaimant.claimantId && idxToUpdate > -1) {
          setData({
            ...data,
            claimants: [...claimantsUpdated]
          });
        }
      }
    } else {
      setData({
        ...data,
        claimants: [...data.claimants, currClaimant]
      });
    }
  };

  const onAddEditDefendant = async () => {
    if (!currDefendant.isPersonalOrCompanyDefendant) return
    const defendantsUpdated = [...data.defendants]
    if (defendantsUpdated.length) {
      const idxToUpdate = defendantsUpdated.findIndex((defendant) => defendant.id === currDefendant.id)
      if (idxToUpdate === -1) {
        setData({
          ...data,
          defendants: [...defendantsUpdated, currDefendant]
        });
      } else {
        defendantsUpdated[idxToUpdate] = currDefendant
        if ((currDefendant.defendantFirstName || currDefendant.defendantCompanyName) && idxToUpdate > -1) {
          setData({
            ...data,
            defendants: [...defendantsUpdated]
          });
        }
      }
    } else {
      setData({
        ...data,
        defendants: [...data.defendants, currDefendant]
      });
    }
  };

  const saveContact = async () => {
    if (!contactToSave.name && systemData.isSaveContact) return
    if (!secondContactToSave.name && systemData.isSaveContactSecond) return
    const newUserContacts = activeUser.userContacts ? [...activeUser.userContacts] : []
    const userRef = doc(db, "users", activeUser.uid);

    if (systemData.isSaveContact) {
      newUserContacts.push({ ...contactToSave, id: utilService.makeId() })
      // if (contactToSave.contactId || contactToSave.id) {
      //   let idxOfContact = newUserContacts.findIndex(contact => ((contact.contactId === contactToSave.contactId) || (contact.id === contactToSave.id)));
      //   if (idxOfContact === -1) {
      //     newUserContacts.push(contactToSave)
      //   } else {
      //     newUserContacts[idxOfContact] = contactToSave
      //   }
      // } else {
      //   newUserContacts.push(contactToSave)
      // }
    }
    if (systemData.isSaveContactSecond) {
      newUserContacts.push({ ...secondContactToSave, id: utilService.makeId() })
      // if (secondContactToSave.contactId || secondContactToSave.id) {
      //   let idxOfContact = newUserContacts.findIndex(contact => ((contact.contactId === secondContactToSave.contactId) || (contact.id === contactToSave.id)));
      //   if (idxOfContact === -1) {
      //     newUserContacts.push(secondContactToSave)
      //   } else {
      //     newUserContacts[idxOfContact] = secondContactToSave
      //   }
      // } else {
      //   newUserContacts.push(secondContactToSave)
      // }
    }
    const updatedUser = {
      ...activeUser,
      userContacts: [...newUserContacts]
      // userContacts: []
    }
    // console.log('%c  updatedUser:', 'color: white;background: red;', updatedUser);

    dispatch(updateSystemData({
      ...systemData,
      isSaveContact: false,
      isSaveContactSecond: false,
    }));

    try {
      dispatch(updateUser({ ...updatedUser }));
      await updateDoc(userRef, updatedUser, { merge: true }).then((r) => { }).catch((e) => {
        logClient.error('error when updating user data');
        logClient.error(JSON.stringify(updatedUser))
        logClient.error(JSON.stringify(e));
      });
      // console.log('%c  { itemId: updatedUser.mondayId, user: updatedUser }:', 'color: white;background: red;', { itemId: updatedUser.mondayId, user: updatedUser });
    }
    catch ({ message }) {
      console.log('%c  error:', 'color: white;background: red;', message);
    }

    dispatch(updateContactToSave({}));
    dispatch(updateSecondContactToSave({}))
  }

  const onAddOrder = async () => {
    dispatch(updateSystemData({
      ...systemData,
      isFormModalOpen: false,
      isEditMode: false,
      isSpecialUrgencyPrice: false,
      modalToolbar: 'cart',
    }));
    navigate('/checkout');
    if (width < breakpoint) {
      dispatch(setMobileState({
        ...mobile,
        isModalToolbarOpen: (width < breakpoint),
      }))
    }

    try {
      if (!systemData.isEditMode) {
        await dispatch(addOrder(activeUser, data, totalRecipients, type));
      } else {
        await dispatch(updateOrder(activeUser, data, totalRecipients, type));
      }
    } catch (err) {
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: 'לא ניתן להשלים את התהליך, יש לבצע רענון לעמוד ובמידה והבעיה חוזרת - ניתן ליצור עמנו קשר בצ׳אט שבאתר או בטלפון 03-3817000',
          submitText: 'אישור',
          isCancel: false,
          isFirebaseErrorImage: true
        })
      );
      errorHandler.report(`uid of user: ${activeUser.uid}`);
      errorHandler.report(err);
    }

  };

  const onSubmit2 = async () => {
    setSubmitForm(false);
    console.log("submit2")
    logEvent(analytics, 'screen_view', {
      firebase_screen: formState.formSteps[step],
      firebase_screen_class: formState.formSteps[step],
    })
    await DynamicSteps.handleNextStep(
      step,
      setStep,
      formSteps,
      formState,
      setFormState,
      onAddEditRecipient,
      onAddOrder,
      data,
      setProgressBarPhase,
      setData,
      onAddEditClaimant,
      onAddEditDefendant,
      onSetLastDefendant,
      onSetLastClaimant,
      systemData?.isTestUser,
      removeSubmitter,
      isLoading,
    );
  };

  const getValidationSchemaByPage = (page) => {
    // console.log('page', page)
    switch (page) {
      case 'PersonalOrCompany':
        if (currRecipient?.type === 'HEVRA') return validationSchemaCompany;
        else if (currRecipient?.type === 'officeDelivery') return validationSchemaDocumentTransferOffice;
        else if (type === 'documentTransfer') return validationSchemaDocumentTransferPerson
        else return validationSchemaPerson;
      //Update of New pages
      case 'PersonalDeliveryPage1':
        if (currRecipient?.type === 'HEVRA') return validationSchemaCompany;
        else return validationSchemaPerson;
      case 'CollectDocumentsOrUploadFile2':
        if (data?.collectionMethod === 'upload') return validationCheckUploadUpdate
        else if (type === 'personalDelivery' || type === 'officeDelivery' || type === 'courtSubmission') return validationSchemaAddressCollectDocumentsWithSize
        else return validationSchemaAddressCollectDocuments;
      case 'Proof3':
        if (data?.returnMethod === 'delivery') return validationSchemaAddressReturnDocuments;
        else return validationPass
      case 'OfficeDeliveryFirst':
        return validationSchemaOffice;
      case 'CourtNameAndAction':
      case 'CourtName':
        return validationCourtAddress;
      case 'DocumentTransferType':
        if (data?.typeOfTransfer === 'fromMe') {
          if (data?.isSign) return validationDocumentTransferFromMeWithSign
          else return validationDocumentTransferFromMe
        }
        else if (data?.typeOfTransfer === 'toMe') {
          if (data?.isSign) return validationDocumentTransferToMeWithSign
          else return validationDocumentTransferToMe
        }
        else {
          if (data?.isSign) return validationDocumentTransferBothWithSign
          else return validationDocumentTransferBoth
        }
      case 'CertificateCollectMethod':
        if (data?.collectionMethod === 'branch') return validationSchemaAddressCollectDocumentsWithoutNumber
        else return validationSchemaAddressCollectDocuments
      case 'CertificateReturnMethod':
        if (data?.returnMethod === 'branch') return validationSchemaAddressReturnDocumentsWithoutNumber
        else return validationSchemaAddressReturnDocuments
      case 'CollectDocumentsAndDocumentsReady':
        return validationSchemaAddressCollectDocuments
      //Update of New pages end
      case 'OfficeDelivery':
        return validationSchemaOffice;
      case 'PersonalAddress':
        if (currRecipient?.type === 'HEVRA') return validationSchemaCompany
        else if (currRecipient?.type === 'officeDelivery') return validationSchemaDocumentTransferOffice
        else return validationPersonalAddress
      case 'WhatServed':
      case 'WhatHanded':
      case 'WhatServedRegist':
        return validationWhatServed;
      case 'Recipients':
        return validationRecipients;
      case 'AddressCollectDocuments':
      case 'AddressCollectDocumentsUpdate':
        return validationSchemaAddressCollectDocuments;
      case 'AddressReturningDocuments':
      case 'AddressReturningDocumentsUpdate':
        return validationSchemaAddressReturnDocuments;
      case 'UploadFile':
        if (type === "courtSubmission") {
          return validationCheckUpload
        } else if (type === 'landSupervisor') {
          return validationPass;
        }
        else if (type !== 'landRegistration' && data?.isFirstSubmission !== 'collect') {
          return validationUploadPage;
        } else {
          return validationPass;
        }
      case 'UploadFileUpdate':
        return validationCheckUploadUpdate
      case 'ApostilleVerification':
        return validationApostilleVerification;
      case 'CollectBranchSelecting':
        return validationCollectBranchSelecting;
      case 'ReturnBranchSelecting':
        return validationReturnBranchSelecting;
      case 'PersonDetails':
        if (type === 'idLocate') return validationIdLocateAddress
        else return validationLocateAddress
      case 'ChooseOffice':
        return validationChooseOfficePage
      case 'ChooseClaimType':
        return validationChooseClaimTypePage
      case 'SmallClaimClaimant':
        return validationSchemaClaimant
      case 'SmallClaimDefendant':
        if (currDefendant?.isPersonalOrCompanyDefendant === 'personal') {
          if (currDefendant?.isAddressLocate && !currDefendant?.isIdLocate) return validationSchemaPersonalDefendantWithoutAddress
          else if (currDefendant?.isAddressLocate && currDefendant?.isIdLocate) return validationSchemaPersonalDefendantWithoutAddressAndId
          else if (!currDefendant?.isAddressLocate && currDefendant?.isIdLocate) return validationSchemaPersonalDefendantWithoutId
          else return validationSchemaPersonalDefendant

        }
        else if (currDefendant?.isPersonalOrCompanyDefendant === 'company') return validationSchemaCompanyDefendant
      // else return validationSchemaPersonalDefendant
      case 'SmallClaimRecipients':
        return validationSmallClaimRecipients;
      case 'SmallClaimDescription':
        if (data?.claimDescription?.isStatementAll) return validationSmallClaimDescriptionWithoutStatementB
        return validationSmallClaimDescription;
      case 'SmallClaimContract':
        return validationSmallClaimContract;
      case 'ActionInOffice':
        if (data?.actionInOffice === 'collectFile') return validationPass
        else if (type === 'landSupervisor' && data?.collectionMethod === 'upload') return validationCheckUploadUpdate
        else return validationSchemaAddressCollectDocuments
      case 'CollectFileDetails':
        return validationCollectFileDetailsPage
      case 'ActionsSummary':
        return validationActionSummaryPage
      case 'PowerOfAttorney':
        if (data.collectFiles && data.collectFiles[data.currSubmiterIdx]?.isUpload) return validationPowerOfAttorneyPageUploadFiles
        return validationPowerOfAttorneyPage
      case 'FinalPage':
        if ((activeUser.pricelessActions && activeUser.pricelessActions.includes(systemData.action.type)) || data?.prices?.data?.isBid) {
          if (activeUser.isCaseNeeded && activeUser.isAuthNeeded) {
            return validationFinalPageWithoutPricesWithCaseNeededAndAuthNeeded;
          } else if (activeUser.isCaseNeeded && !activeUser.isAuthNeeded) {
            return validationFinalPageWithoutPricesWithCaseNeeded;
          } else if (!activeUser.isCaseNeeded && activeUser.isAuthNeeded) {
            return validationFinalPageWithoutPricesWithAuthNeeded;
          } else {
            return validationFinalPageWithoutPrices;
          }
        } else {
          if (activeUser.isCaseNeeded && activeUser.isAuthNeeded) {
            return validationFinalPageWithPricesWithCaseNeededAndAuthNeeded;
          } else if (activeUser.isCaseNeeded && !activeUser.isAuthNeeded) {
            return validationFinalPageWithPricesWithCaseNeeded;
          } else if (!activeUser.isCaseNeeded && activeUser.isAuthNeeded) {
            return validationFinalPageWithPricesWithAuthNeeded;
          } else {
            return validationFinalPage;
          }
        }
      default:
        return validationPass;
    }
  };

  const { nextStep, formSteps, currRecipient } = formState;

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://app.delawvery.co.il/forms/${nid}`} />
      </Helmet>

      <Formik
        onSubmit={onSubmit2}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        enableReinitialize={true}
        validationSchema={() => getValidationSchemaByPage(nextStep)}
        initialValues={{
          //pass validation for none validation pages
          pass: true,

          //general attributes for collect and return address for all form types
          collectionAddress: data?.collectionAddress || {},
          returnAddress: data?.returnAddress || {},


          // whatServed
          docsForSubmission: data?.docsForSubmission || [],

          //court name
          courtAddressDetails: data?.courtAddressDetails || { street: '', courtName: '', streetNumber: '', city: '', country: '' },

          // isFirstSubmission toll
          toll: data?.toll || (!data.isFirstSubmission ? 1 : 0),

          //recipient
          whichSide: currRecipient?.whichSide || '',
          companyName: currRecipient?.companyName || '',
          personName: currRecipient?.personName || '',
          personLastName: currRecipient?.personLastName || '',
          officeName: currRecipient?.officeName || '',
          personId: currRecipient?.personId || '',
          personPhone: currRecipient?.personPhone || '',
          companyPhone: currRecipient?.companyPhone || '',
          officePhone: currRecipient?.officePhone || '',
          recipientPhone: currRecipient?.personPhone || currRecipient?.companyPhone || currRecipient?.officePhone || '',
          recipientName: currRecipient?.personName || currRecipient?.companyName || currRecipient?.officeName || '',

          //Document Transfer
          returnRecipientName: data?.returnAddress?.personName || data?.returnAddress?.companyName || data?.returnAddress?.officeName || '',
          returnRecipientPhone: data?.returnAddress?.personPhone || data?.returnAddress?.companyPhone || data?.returnAddress?.officePhone || '',


          //smallClaim or else
          streetAddress: nextStep === 'SmallClaimDefendant' ? currDefendant?.streetAddress
            : nextStep === 'SmallClaimClaimant' ? currClaimant?.streetAddress
              : currRecipient?.streetAddress || '',

          streetNumber: nextStep === 'SmallClaimDefendant' ? currDefendant?.streetNumber
            : nextStep === 'SmallClaimClaimant' ? currClaimant?.streetNumber
              : currRecipient?.streetNumber || '',

          city: nextStep === 'SmallClaimDefendant' ? currDefendant?.city
            : nextStep === 'SmallClaimClaimant' ? currClaimant?.city
              : currRecipient?.city || '',

          //recipients page
          recipients: (type === 'courtSubmission') ? [{}] : (data?.recipients || []),

          // upload file
          pages: data?.pages || 0,
          files: uploadedFiles || [],

          //document-transfer
          typeOfTransfer: data?.typeOfTransfer || '',
          signType: data?.signType || '',

          //filing-a-small-claim
          claimType: data?.claimType || '',

          //SmallClaimClaimant
          claimantFirstName: currClaimant?.claimantFirstName || '',
          claimantLastName: currClaimant?.claimantLastName || '',
          claimantId: currClaimant?.claimantId || '',
          claimantPhone: currClaimant?.claimantPhone || '',
          claimantEmail: currClaimant?.claimantEmail || '',

          //SmallClaimDefendant
          defendantFirstName: currDefendant?.defendantFirstName || '',
          defendantLastName: currDefendant?.defendantLastName || '',
          defendantCompanyName: currDefendant?.defendantCompanyName || '',
          defendantId: currDefendant?.defendantId || '',
          defendantPhone: currDefendant?.defendantPhone || '',
          defendantEmail: currDefendant?.defendantEmail || '',

          //SmallClaimDefendant && SmallClaimClaimant
          postalCode: nextStep === 'SmallClaimDefendant' ? currDefendant?.postalCode : currClaimant?.postalCode || '',

          //SmallClaimRecipients
          claimants: data?.claimants || [],
          defendants: data?.defendants || [],

          //SmallClaimDescription
          smallClaimLocation: data?.claimDescription?.commitmentLocation || data?.claimDescription?.commitmentFulfillmentLocation || data?.claimDescription?.deliveryPropertyLocation || data?.claimDescription?.failureOrFailureLocation || '',
          courtClaimName: data?.claimDescription?.court?.courtName || '',
          statementSectionA: data?.claimDescription?.statementSectionA || '',
          statementSectionB: data?.claimDescription?.statementSectionB || '',
          productClaimed: data?.claimDescription?.moneyClaimed || data?.claimDescription?.productToExchange ||
            data?.claimDescription?.dealToCancel || '',
          moneyClaimed: data?.claimDescription?.moneyClaimed ? +data?.claimDescription?.moneyClaimed : 1,
          statementSectionSource: data?.claimDescription?.statementSectionB ? (data?.claimDescription?.statementSectionSource || '') : 'A',
          signature: data?.claimDescription?.signature || '',
          claimFiles: data?.claimFiles || [],
          witnesses: data?.witnesses || [],

          //SmallClaimContract
          claimContract: data?.claimContract || {},

          // apostille
          certificates: data?.certificates || [],
          currCountryApostille: data?.currCountryApostille || '',

          //land-registration
          branch: data?.branch || '',
          collectFiles: data?.collectFiles || null,
          isPassActionSummary: (data?.collectFiles?.length > 0 || data?.depositFiles?.filesCount > 0) || false,

          //UploadFileUpdate
          printingDetails: data?.printingDetails,

          //Package Size
          isPackageSizeChoose: data?.isVerySmallPackage || data?.isSmallPackage || data?.isBigPackage || data?.isDeliveryWithCar,

          //final page
          contactDeliveryRecipientFactor: data?.contactDeliveryRecipientFactor || '',
          contactDeliveryRecipientName: data?.contactDeliveryRecipientName || '',
          contactDeliveryRecipientPhone: data?.contactDeliveryRecipientPhone || '',
          contactDeliveryRecipientCase: data?.contactDeliveryRecipientCase || '',
          prices: (data?.prices?.data?.total) || -1,

        }}
      >
        {({ handleChange, handleSubmit, errors, touched }) => {
          const isError = (key) => {
            return (errors[key] && touch[key]) || (errors[key] && touched[key]) || (submitForm && errors[key]);
          }

          return (
            <div className="whoisthis">
              <div className="progress-bar-mobile">
                {<ProgressBar phase={progressBarPhase} progressBarPhases={progressBarPhases} />}
              </div>
              <Form
                autoComplete="new-password"
                onSubmit={handleSubmit}
                onKeyDown={onKeyDown}
                className='form-container'
              >
                <DynamicCmp
                  setProgressBarPhase={setProgressBarPhase}
                  type={type}
                  data={data}
                  setData={setData}
                  pageName={nextStep}
                  formState={formState}
                  setFormState={setFormState}
                  setStep={setStep}
                  isError={isError}
                  handleChanges={handleChange}
                  setTouch={setTouch}
                  touch={touch}
                  errors={errors}
                  submitForm={submitForm}
                  setSubmitForm={setSubmitForm}
                  scrollTo={scrollTo}
                />
                <div className='modal-footer'>
                  <div className="progress-bar-desktop">
                    <ProgressBar phase={progressBarPhase} progressBarPhases={progressBarPhases} />
                  </div>
                  <div className='btn-container'>
                    {((step > 0 || data.isOtherTasks)
                      || (nextStep === 'PersonalDeliveryPage1' && type === 'personalDelivery')
                      || (nextStep === 'OfficeDeliveryFirst' && type === 'officeDelivery')
                      || nextStep === 'PersonDetails' && (
                        type === 'personLocate' ||
                        type === 'addressLocate' ||
                        type === 'phoneLocate' ||
                        type === 'idLocate'
                      )
                    )  //Only for add recipient in the same page
                      && (
                        <Back
                          className='back-icon'
                          type='button'
                          onClick={() =>
                            DynamicSteps.handlePrevStep(
                              step,
                              setStep,
                              formSteps,
                              formState,
                              setFormState,
                              data,
                              setData,
                              setProgressBarPhase,
                              onSetLastClaimant,
                              onSetLastDefendant,
                              myRecipients,
                              systemData?.isTestUser,
                              removeSubmitter
                            )
                          }
                        />
                      )}
                    <button
                      type='submit'
                      name="__next"
                      className='btn main-btn'
                      id={`${data.type} - ${step}`}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSubmitForm(true);
                        if ((systemData.isSaveContact || systemData.isSaveContactSecond) && !(Object.keys(errors).length !== 0)) {
                          saveContact()
                        }
                      }}
                    >
                      {nextStep !== 'FinalPage' ? 'לשלב הבא' : 'סיכום הזמנה'}

                    </button>
                  </div>

                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};
