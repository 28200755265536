import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const PersonalOrCompanyToggleUpdate = ({ data, setData, setFormState, formState }) => {

  const [thirdAlignment, setThirdAlignment] = useState(
    data?.isPersonalOrCompany || 'true'
  );
  const isPopUpModal = useSelector((state) => state.systemReducer.isPopUpModal);

  const optionE = 'אדם פרטי';
  const optionF = 'בית עסק';

  useEffect(() => {
    setThirdAlignment(data?.isPersonalOrCompany || 'true')
  }, [data?.isPersonalOrCompany]);

  useEffect(() => {
    setData({
      ...data,
      deliveryAfter: true,
      isPersonalOrCompany: thirdAlignment,
    });
    // setFormState({ ...formState, currRecipient: null })
    // return () => {
    //   setData({ ...data, isOtherTasks: true })
    // }
  }, []);

  useEffect(() => {
    const { isDeleteRecipients, status } = isPopUpModal;
    if (isDeleteRecipients && status === 'submit') {
      setData({ ...data, deliveryAfter: 'false', recipients: [] });
    }
  }, [isPopUpModal.isDeleteRecipients]);

  const handleChangeData = async (e, field, value) => {
    e.preventDefault();

    if (field === 'isPrivateOrOffice') {
    } else {
      setThirdAlignment(value);
    }
    setData({ ...data, [field]: value });

    //Update from initial personalOrCompany
    // setFormState({ ...formState, currRecipient: { ...formState.currRecipient, type: (data.isPersonalOrCompany === 'true' || data.type === 'documentTransfer') ? (formState?.currRecipient?.type === 'officeDelivery' ? 'officeDelivery' : (formState?.currRecipient?.type === 'HEVRA' ? 'HEVRA' : 'ADAM_PRATI')) : 'HEVRA' } });

    const currRecipient = {
      ...formState.currRecipient,
      [value === 'true' ? 'personName' : 'companyName']: formState.currRecipient?.personName || formState.currRecipient?.companyName || '',
      [value === 'true' ? 'personId' : 'companyId']: formState.currRecipient?.personId || formState.currRecipient?.companyId || '',
      [value === 'true' ? 'personPhone' : 'companyPhone']: formState.currRecipient?.personPhone || formState.currRecipient?.companyPhone || '',
      type: value === 'true' ? 'ADAM_PRATI' : 'HEVRA',
      urgency: formState.currRecipient?.urgency || 'REG',
    }

    if (value === 'true') {
      delete currRecipient.companyName;
      delete currRecipient.companyId;
      delete currRecipient.companyPhone;
    } else {
      delete currRecipient.personName;
      delete currRecipient.personId;
      delete currRecipient.personPhone;
    }

    setFormState({ ...formState, currRecipient });
  };

  const handleAlignment = (state) => {
    if (state === 'true') {
      return 'right';
    } else {
      return 'left';
    }
  };

  return (
    <div className='main-form  personal-delivery update-cmps'>
      <div className='modal-content-container block-update'>
        <h2 className='large-title'>
          למי מיועדת המסירה?
        </h2>

        {!data.isOtherTasks && <> <p className='subtitle'>
          {`בחרו את סוג הנמען ${(data?.recipients && data?.recipients?.length < 2) ? 'הראשון' : ''} לו תרצו למסור את המסמכים. בהמשך ניתן יהיה להוסיף נמענים נוספים.`}
          {/* בחרו את סוג הנמען הראשון לו תרצו למסור את המסמכים. בהמשך ניתן יהיה להוסיף נמענים נוספים. */}
        </p>
          {/* <p className='subtitle'>
            בהמשך ניתן יהיה להוסיף נמענים נוספים.
          </p> */}
        </>}
        {data.isOtherTasks && <p className='subtitle'>
          בחרו את סוג הנמען הנוסף לו תרצו למסור את המסמכים.
        </p>}

        {
          <div>
            <div
              className={`form-main-toggle  ${handleAlignment(
                thirdAlignment
              )}`}
            >
              <span></span>
              <button
                onClick={(e) =>
                  handleChangeData(e, 'isPersonalOrCompany', 'true')
                }
              >
                {optionE}
              </button>
              <button
                onClick={(e) =>
                  handleChangeData(e, 'isPersonalOrCompany', 'false')
                }
              >
                {optionF}
              </button>
            </div>
          </div>

        }
      </div>
    </div>
  );
};

export default PersonalOrCompanyToggleUpdate;
