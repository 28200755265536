import { actionsSite } from '../consts/data'

export const utilService = {
    makeId,
    getActionByType,
    formatDate,
    calcTollByOrderType,
    countTollsByOrderType
}

function makeId(length = 6) {
    var txt = '';
    var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    for (var i = 0; i < length; i++) {
        txt += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    return txt;
}

function formatDate(timestamp) {
    var date = new Date(timestamp);
    var dateStr =
        ("00" + date.getDate()).slice(-2) + "/" +
        ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
        date.getFullYear() + " " +
        ("00" + date.getHours()).slice(-2) + ":" +
        ("00" + date.getMinutes()).slice(-2) + ":" +
        ("00" + date.getSeconds()).slice(-2);
    return dateStr;
}

function getActionByType(type) {
    switch (type) {
        case 'apostilSubmission':
            return actionsSite.find(action => action.nid === 'apostille')
        case 'addressLocate':
            return actionsSite.find(action => action.nid === 'address-locate')
        case 'idLocate':
            return actionsSite.find(action => action.nid === 'ID-locate')
        case 'personLocate':
            return actionsSite.find(action => action.nid === 'person-locate')
        case 'phoneLocate':
            return actionsSite.find(action => action.nid === 'phone-locate')
        case 'personalDelivery':
            return actionsSite.find(action => action.nid === 'service-of-process')
        case 'officeDelivery':
            return actionsSite.find(action => action.nid === 'office-delivery')
        case 'courtSubmission':
            return actionsSite.find(action => action.nid === 'file-to-court')
        case 'landRegistration':
            return actionsSite.find(action => action.nid === 'land-registration')
        case 'inheritanceRegistration':
            return actionsSite.find(action => action.nid === 'inheritance-registration')
        case 'documentTransfer':
            return actionsSite.find(action => action.nid === 'document-transfer')
        case 'companiesRegistration':
            return actionsSite.find(action => action.nid === 'companies-registration')
        case 'partnershipsRegistration':
            return actionsSite.find(action => action.nid === 'partnerships-registration')
        case 'associationsAndCompanies':
            return actionsSite.find(action => action.nid === 'associations-and-companies')
        case 'smallClaim':
            return actionsSite.find(action => action.nid === 'filing-a-small-claim')
        case 'apostilInCourt':
            return actionsSite.find(action => action.nid === 'apostille-in-court')
        case 'printingBinding':
            return actionsSite.find(action => action.nid === 'printing_binding')
        case 'landSupervisor':
            return actionsSite.find(action => action.nid === 'land_supervisor')
        default:
            return 'nothing from getActionByType';
    }
}

function calcTollByOrderType(ord) {
    let toll = 0
    if (ord.type === 'apostilSubmission' || ord.type === 'apostilInCourt') {
        toll = ord.certificates.reduce((acc, cer) => {
            acc += cer.isToll ? (cer.counter * 38) : 0
            return acc;
        }, 0);
        return toll
    } else if (ord.type === 'courtSubmission') {
        return (ord.toll || 0)
    } else if (ord.type === 'personLocate') {
        toll = ord.recipients.reduce((acc, recipient) => {
            acc += 20
            return acc;
        }, 0);
        return toll
    } else if (ord.type === 'landRegistration') {
        return (ord?.depositFiles?.toll || 0)
    }
}
function countTollsByOrderType(ord) {
    if (ord.type === 'apostilSubmission' || ord.type === 'apostilInCourt') {
        const count = ord.certificates.reduce((acc, cer) => {
            acc += cer.isToll ? cer.counter : 0
            return acc;
        }, 0);
        return { count, price: 38 }
    } else if (ord.type === 'courtSubmission') {
        return { count: 1, price: ord.toll }
    } else if (ord.type === 'personLocate') {
        return { count: ord.recipients.length, price: 20 }
    } else if (ord.type === 'landRegistration') {
        return { count: 1, price: ord?.depositFiles?.toll }
    } else {
        return { count: 0, price: 0 }
    }
}

