import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useViewport } from '../../Hooks/useViewport'
import { updateSystemData, setMobileState } from '../../store/actions/systemActions';
import { useSelector, useDispatch } from 'react-redux';
import { signInAnonymously } from "firebase/auth";
import { login } from '../../store/actions/userActions'
import { setCompany } from '../../store/actions/companyActions'
import { useNavigate } from 'react-router-dom';
import { auth } from '../../integrations/firebase'
import { ReactComponent as Logout } from '../../images/logout.svg'
import feather from "../../images/feather.svg";
import db, { trace, perf } from '../../integrations/firebase';


export const UserProfile = () => {
    const t = trace(perf, "UserProfile");
    t.start();

    const dispatch = useDispatch();
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const mobile = useSelector((state) => state.mobileReducer.mobileState)
    const activeUser = useSelector((state) => state.userReducer.user);
    const orders = useSelector((state) => state.userDataReducer.userOrders);
    const [doneCount, setDoneCount] = useState('');
    const [actionsCount, setActionsCount] = useState('');

    const navigate = useNavigate()

    const { width } = useViewport();
    const breakpoint = 1111;

    const percentageDone = 75
    const [style, setStyle] = React.useState({});

    useEffect(() => {
        let doneCounter = 0
        let actionsCounter = 0
        orders.forEach(order => {
            order.actionData.forEach(action => {
                if (action.isDone) doneCounter++
                else actionsCounter++
            })
        });
        setDoneCount(doneCounter)
        setActionsCount(actionsCounter)
    }, [orders]);

    setTimeout(() => {
        const newStyle = {
            opacity: 1,
            width: `${percentageDone}%`
        }

        setStyle(newStyle);
    }, 200);

    const onOpenModal = (value) => {
        dispatch(updateSystemData({
            ...systemData,
            modalToolbar: value
        }));
        if (width < breakpoint) {
            dispatch(setMobileState({
                ...mobile,
                isModalToolbarOpen: true,
            }))
        }
    }

    const onSignOut = () => {
        signInAnonymously(auth)
        const user = auth.currentUser;
        dispatch(setCompany(null))
        dispatch(login(user))
        navigate('/')
    }
    const returnValue = (
        <div className="user-profile">
            <div className="details">
                <img className="avatar" src={feather} alt="avatar"></img>
                {activeUser && <p className="name">{activeUser.isLawyer ? 'עו״ד ' + activeUser.fullName : activeUser.fullName}</p>}
                <p className="sub-name">{activeUser?.officeOrCompanyName || ''}</p>
            </div>
            <Link to={`/`}>
                <button className="new-order">הזמנה חדשה</button>
            </Link>
            {
                //     <div>
                //     <p className="complited"> 3/4 הזמנות הושלמו</p>
                //     <div className="progress">
                //         <div className="progress-done" style={style}>
                //             {/* {done}% */}
                //         </div>
                //     </div >
                // </div >
            }

            < div className="stats" >
                <div className="stats-item">
                    <span>{actionsCount}</span><p>הזמנות בתהליך</p>
                </div>
                <div className="stats-item">
                    <span>{doneCount}</span><p>הזמנות שהסתיימו </p>
                </div>
            </div >

            {(width > breakpoint) && <div className="devider"></div>}


            {
                (width < breakpoint) && <div className="menu">
                    <Link className="menu-item" to="orders"><p >ההזמנות שלי</p></Link>
                    <Link className="menu-item" to="msgs"><p >הודעות ועדכונים</p></Link>
                    <Link className="menu-item" to="details"><p >פרטים אישיים</p></Link>
                    <Link className="menu-item" to="addresses"><p >הכתובות שלי</p></Link>
                    {/* <Link className="menu-item" to="invoices"><p >חשבוניות</p></Link> */}
                    <Link className="menu-item" to="company"><p >החברה שלי</p></Link>
                </div>
            }


            <div className="links">
                <p className="links-title">שימושי עבורך</p>
                <a className="link" href="https://delawvery.co.il/prices/" rel="noreferrer" target="_blank">מסלולי לקוחות</a>
                <a className="link" href="https://delawvery.co.il/service-points/" rel="noreferrer" target="_blank">נקודות שירות</a>
                <a className="link" href="https://delawvery.co.il/about/" rel="noreferrer" target="_blank">אודות</a>
                <a className="link" href="https://delawvery.co.il/%d7%94%d7%a6%d7%95%d7%95%d7%aa-%d7%a9%d7%9c%d7%a0%d7%95/" rel="noreferrer" target="_blank"> הצוות שלנו</a>
                <a className="link" href="https://delawvery.co.il/faq/" rel="noreferrer" target="_blank">שאלות ותשובות</a>
                <a className="link" href="https://delawvery.co.il/blog/" rel="noreferrer" target="_blank">בלוג</a>
                <a className="link" href="https://delawvery.co.il/contact/" rel="noreferrer" target="_blank">יצירת קשר</a>
                <a className="link" href="https://delawvery.co.il/terms-of-use/" rel="noreferrer" target="_blank">תקנון שימוש  </a>
                <a className="link" href="https://delawvery.co.il/privacy-policy/" rel="noreferrer" target="_blank">מדיניות פרטיות</a>
                <a className="link" href="https://delawvery.co.il/accessibility-statement/" rel="noreferrer" target="_blank">הצהרת נגישות </a>
            </div>

            {(width > breakpoint) && <div className="devider"></div>}
            <div className="logout" onClick={onSignOut}>
                <Logout />
                <p >התנתקות מהמערכת</p>
            </div>
        </div >
    )
    t.stop()
    return returnValue
}
