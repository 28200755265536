import React from 'react'
import SpiralBinding from '../../../../images/styledChoise/SpiralBinding.svg';
import BindingStrip from '../../../../images/styledChoise/BindingStrip.svg';
import Dividers from '../../../../images/styledChoise/Dividers.svg';
import WhiteSpiralBinding from '../../../../images/styledChoise/WhiteSpiralBinding.svg';
import WhiteBindingStrip from '../../../../images/styledChoise/WhiteBindingStrip.svg';
import WhiteDividers from '../../../../images/styledChoise/WhiteDividers.svg';
import VerySmallPackage from '../../../../images/styledChoise/VerySmallPackage.svg';
import SmallPackage from '../../../../images/styledChoise/SmallPackage.svg';
import BigPackage from '../../../../images/styledChoise/BigPackage.svg';
import DeliveryWithCar from '../../../../images/styledChoise/DeliveryWithCar.svg';
import WhiteVerySmallPackage from '../../../../images/styledChoise/WhiteVerySmallPackage.svg';
import WhiteSmallPackage from '../../../../images/styledChoise/WhiteSmallPackage.svg';
import WhiteBigPackage from '../../../../images/styledChoise/WhiteBigPackage.svg';
import WhiteDeliveryWithCar from '../../../../images/styledChoise/WhiteDeliveryWithCar.svg';


export const StyledChoice = ({ data, setData, type, option, pageName, onHandleAdditions, isUploadHandle }) => {

    const optionImages = {
        SpiralBinding,
        BindingStrip,
        Dividers,
        WhiteSpiralBinding,
        WhiteBindingStrip,
        WhiteDividers,
        VerySmallPackage,
        SmallPackage,
        BigPackage,
        DeliveryWithCar,
        WhiteVerySmallPackage,
        WhiteSmallPackage,
        WhiteBigPackage,
        WhiteDeliveryWithCar
    };
    const isTrueOption = data?.[option.name] || data?.printingDetails?.[option.name] || false

    const handleOptionSelected = () => {
        if (isUploadHandle) {
            onHandleAdditions(option.name);
        } else {
            setData({
                ...data,
                [option.name]: !data?.[option.name]
            });
        }
    }

    return (
        <div className='styled-choise-container' onClick={() => handleOptionSelected()}>
            <div className={`styled-choise ${isTrueOption ? 'blue' : 'white'}`} >
                <input
                    type="radio"
                    id={option.name}
                    name={option.name}
                    value={option.name}
                    defaultChecked={isTrueOption}
                />
                <span className="checkmark"></span>
                <div className='label-img-container'>

                    {/* <label htmlFor={option.name} > */}
                    <p>{option.title}</p>
                    {/* </label> */}
                    {option.img &&
                        <img className='icon-choise' src={isTrueOption ? optionImages[option.imgWhite] : optionImages[option.img]} alt='icon-choise' />
                    }
                </div>
            </div>
        </div>
    )
}
