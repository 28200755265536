import React, { useEffect, useState } from 'react'
import { UserMsgItem } from '../UserDashboard/UserMsgItem'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from '../../store/actions/userActions'
import db, { Logger } from '../../integrations/firebase';
import { doc, updateDoc, onSnapshot } from 'firebase/firestore';
import { NotificationsSet } from '../../store/actions/userDataActions';
import { onCallNotifications } from '../../integrations/firebase';
import { useViewport } from '../../Hooks/useViewport'
import closeIconBlackThin from "../../images/closeIconBlackThin.svg";

const logClient = new Logger(`UserMsgs`);
export const UserMsgs = ({ actionNotifications, isNotificationsList, isOpen, isFromDash, setIsUserMsgsOpen }) => {
    const currUser = useSelector((state) => state.userReducer.user)
    const stateNotifications = useSelector((state) => state.userDataReducer.userNotifications);
    const dispatch = useDispatch()
    const { width } = useViewport();
    const breakpoint = 1111;
    const isMobile = width < breakpoint;

    const onClickNotification = async (readedNotification) => {
        if (readedNotification?.userInteractions?.[currUser.uid]?.isClicked) return

        const updatedNotification = {
            ...readedNotification,
            userInteractions: {
                ...(readedNotification?.userInteractions ?? {}), // Create a new object if userInteractions doesn't exist
                [currUser.uid]: {
                    ...(readedNotification?.userInteractions?.[currUser.uid] ?? {}), // Create a new object if the specific userId doesn't exist
                    isClicked: true,
                    isOpened: true,
                },
            }
        }

        const updatedAllNotifications = stateNotifications.map((notification) => {
            if ((notification.orderId === readedNotification.orderId) && (notification.date === readedNotification.date)
                && (notification.actionId ? notification.actionId === readedNotification.actionId : true)) return updatedNotification
            else return notification
        })
        dispatch(NotificationsSet(updatedAllNotifications));

        const objToSend = {
            orderId: readedNotification.orderId,
            companyId: currUser.companyId || currUser.uid,
            userId: currUser.uid,
            updatedNotification,
            type: 'updateNotificationClick'
        }

        await onCallNotifications(objToSend)
    }

    return (
        <div className={`user-msgs ${isNotificationsList ? 'notifications-list' : ''} ${isOpen ? 'open' : ''}`} >
            {!isMobile &&
                <div className='notifications-header'>
                    <h2 className={`msgs-title ${isNotificationsList ? 'notifications-list-title' : ''} ${isOpen ? 'is-visible' : ''}`}>הודעות ועדכונים</h2>
                    <div className='svg-div' onClick={() => { setIsUserMsgsOpen(false) }}>
                        <img src={closeIconBlackThin} alt="close" />
                    </div>
                </div>
            }

            {actionNotifications ?
                <div className={`msg-list ${isNotificationsList ? 'action-info-msg-list' : ''} ${isOpen ? 'is-visible' : ''}`}>
                    {currUser?.fullName && actionNotifications?.length > 0 && actionNotifications.sort(function (y, x) {
                        return x.date - y.date;
                    }).map((notification, idx) => <UserMsgItem key={idx} notification={notification} onClickNotification={onClickNotification} isNotificationsList={isNotificationsList} isFromDash={isFromDash} />)}
                </div>
                :
                <div className={`msg-list ${isNotificationsList ? 'action-info-msg-list' : ''} ${isOpen ? 'is-visible' : ''}`}>
                    {currUser?.fullName && stateNotifications?.length > 0 && stateNotifications.sort(function (y, x) {
                        return x.date - y.date;
                    }).map((notification, idx) => <UserMsgItem key={idx} notification={notification} onClickNotification={onClickNotification} isNotificationsList={isNotificationsList} isFromDash={isFromDash} />)}
                </div>
            }

        </div >
    )
}
