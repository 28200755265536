import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { callUserAction } from '../../../integrations/firebase'
import { updateUser } from '../../../store/actions/userActions'
import pencil from '../../../images/pencil.svg';
import { setIsLoading } from '../../../store/actions/systemActions'
import { TailSpin } from 'react-loader-spinner';

export const CompanyName = ({ company }) => {
    const activeUser = useSelector((state) => state.userReducer.user);
    const isLoading = useSelector((state) => state.systemReducer.isLoading)
    const [isEditMode, setIsEditMode] = useState(false)
    const [companyName, setCompanyName] = useState(activeUser?.officeOrCompanyName || activeUser?.fullName || company?.accountName || '')
    const [companyId, setCompanyId] = useState(activeUser?.officeOrCompanyId || company?.officeOrCompanyId || '')
    const [companyEmail, setCompanyEmail] = useState(activeUser?.officeBookkeepingEmail || company?.officeBookkeepingEmail || '')
    const dispatch = useDispatch();
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // console.log('activeUser', activeUser)
    // console.log('company', company)
    useEffect(() => {
        setCompanyName(activeUser?.officeOrCompanyName || activeUser?.fullName || company?.accountName || '')
        setCompanyId(activeUser?.officeOrCompanyId || company?.officeOrCompanyId || '')
    }, [activeUser, company]);

    const onChangeCompanyDetails = async () => {
        if (isLoading) return
        if (!companyName || !companyId || !emailRegex.test(companyEmail)) return
        const updatedUser = {
            ...activeUser,
            officeOrCompanyName: companyName,
            officeOrCompanyId: companyId,
            officeBookkeepingEmail: companyEmail
        }
        setCompanyName(companyName);
        setCompanyId(companyId);
        setCompanyEmail(companyEmail);
        try {
            dispatch(setIsLoading(true))
            await callUserAction({
                companyId: activeUser.companyId,
                accountName: companyName,
                officeOrCompanyId: companyId,
                officeBookkeepingEmail: companyEmail,
                type: "setCustomerInfo"
            })
            dispatch(updateUser({ ...updatedUser }));
            dispatch(setIsLoading(false))
            setIsEditMode(!isEditMode);
        }
        catch ({ message }) {
            console.log('%c  error:', 'color: white;background: red;', message);
        }

    }

    return (
        <div className='company-name-container'>
            <div className='title-edit'>
                {isEditMode ?
                    <>
                        <input
                            type="text"
                            placeholder=''
                            onChange={(event) => {
                                setCompanyName(event.target.value);
                            }}
                            className={
                                companyName
                                    ? 'success'
                                    : 'error'
                            }
                            value={companyName}
                            name='companyName'
                            autoComplete="new-password"
                        />
                        <button className='main-btn' onClick={() => { onChangeCompanyDetails(); }}>
                            {isLoading ?
                                <div className='spinner'>
                                    <TailSpin
                                        heigth="20"
                                        width="20"
                                        color='#ffffff'
                                        ariaLabel='loading'
                                    /> </div> : 'שמור שינויים'}

                        </button>
                    </>
                    :
                    <>

                        <h3 className='user-company-title'>{activeUser?.officeOrCompanyName || activeUser?.fullName || company?.accountName || ''}</h3>
                        <img src={pencil} alt="edit" className='pencil' onClick={() => { setIsEditMode(!isEditMode) }} />
                    </>

                }

            </div>
            {isEditMode ?
                <input
                    type="text"
                    placeholder='ח.פ.'
                    onChange={(event) => {
                        setCompanyId(event.target.value);
                    }}
                    className={
                        companyId
                            ? 'success'
                            : 'error'
                    }
                    value={companyId}
                    name='companyId'
                    autoComplete="new-password"
                />
                :
                <p className='company-id'>{`ח.פ. ${companyId}`}</p>
            }
            <div>
                {isEditMode ?
                    <input
                        type="text"
                        placeholder='אימייל להנהלת חשבונות'
                        onChange={(event) => {
                            setCompanyEmail(event.target.value);
                        }}
                        className={
                            emailRegex.test(companyEmail)
                                ? 'success'
                                : 'error'
                        }
                        value={companyEmail}
                        name='companyEmail'
                        autoComplete="new-password"
                    />
                    :
                    <p className='company-id'>{`אימייל להנהלת חשבונות - ${companyEmail}`}</p>
                }
            </div>
        </div>

    )
}
