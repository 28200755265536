import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import * as Sentry from "@sentry/react";

import { orderReducer } from "./reducers/orderReducer";
import { recipientReducer } from "./reducers/recipientReducer";
import { userReducer } from "./reducers/userReducer";
import { dataReducer } from "./reducers/dataReducer";
import { fileReducer } from "./reducers/fileReducer";
import { trackingReducer } from "./reducers/orderTrackingReducer";
import { systemReducer } from "./reducers/systemReducer";
import { mobileReducer } from "./reducers/mobileReducer";
import { userDataReducer } from './reducers/userDataReducer';
import { smallClaimReducer } from './reducers/smallClaimReducer'
import { companyReducer } from './reducers/companyReducer'
import { utmReducer } from './reducers/utmReducer'


const rootReducer = combineReducers({
  orderTrackingReducer: trackingReducer,
  orderReducer: orderReducer,
  userReducer: userReducer,
  dataReducer: dataReducer,
  recipientReducer: recipientReducer,
  fileReducer: fileReducer,
  systemReducer: systemReducer,
  mobileReducer: mobileReducer,
  userDataReducer: userDataReducer,
  smallClaimReducer: smallClaimReducer,
  companyReducer: companyReducer,
  utmReducer: utmReducer
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk), sentryReduxEnhancer))


export default store;
