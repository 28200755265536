const INITIAL_STATE = {
    currClaimant: {
        id: '',
        claimantFirstName: '',
        claimantLastName: '',
        claimantId: '',
        claimantPhone: '',
        claimantEmail: '',
        city: '',
        streetAddress: '',
        streetNumber: '',
        floor: '',
        apartmentNumber: '',
        postalCode: '',
        isAddressNotFound: false
    },
    currDefendant: {
        id: '',
        defendantFirstName: '',
        defendantLastName: '',
        defendantId: '',
        defendantPhone: '',
        defendantEmail: '',
        city: '',
        streetAddress: '',
        streetNumber: '',
        floor: '',
        apartmentNumber: '',
        postalCode: '',
        isAddressNotFound: false,
        isPhoneLocate: false,
        isAddressLocate: false,
        isPersonalDefendant: true
    },
    // isPersonalDefendant: true
}
export function smallClaimReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "SET_CLAIMANT":
            return {
                ...state,
                currClaimant: action.currClaimant
            }
        case "SET_DEFENDANT":
            return {
                ...state,
                currDefendant: action.currDefendant
            }
        // case "SET_IS_PERSONAL_DEFENDANT":
        //     return {
        //         ...state,
        //         isPersonalDefendant: action.isPersonalDefendant
        //     }

        default:
            return state
    }
}