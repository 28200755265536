import { ChooseOffice } from './Pages/ChooseOffice';
import { ChooseClaimType } from './Pages/ChooseClaimType';
import { SmallClaimRecipients } from './Pages/SmallClaimRecipients';
import { SmallClaimClaimant } from './Pages/SmallClaimClaimant';
import { SmallClaimDefendant } from './Pages/SmallClaimDefendant';
import { SmallClaimDescription } from './Pages/SmallClaimDescription';
import { SmallClaimContract } from './Pages/SmallClaimContract';
import { SmallClaimUrgencyExecution } from './Pages/SmallClaimUrgencyExecution';
import { DocumentTransferType } from './Pages/DocumentTransferType';
import { PersonalAddress } from './Pages/PersonalAddress';
import { ActionInOffice } from './Pages/ActionInOffice';
import { PowerOfAttorney } from './Pages/PowerOfAttorney';
import { CollectFileDetails } from './Pages/CollectFileDetails';
import { DepositFileDetails } from './Pages/DepositFileDetails';
import { ActionsSummary } from './Pages/ActionsSummary';
import { CourtName } from './Pages/CourtName';
import { FirstSubmissionOrCopyInvolves } from './Pages/FirstSubmissionOrCopyInvolves';
import { WhatServed } from './Pages/WhatServed';
import { WhatServedRegist } from './Pages/WhatServedRegist';
import { Toll } from './Pages/Toll';
import { WhatHanded } from './Pages/WhatHanded';
import { DeliveryAfterSubmission } from './Pages/DeliveryAfterSubmission';
import { Proof } from './Pages/Proof';
import { AddressReturningDocuments } from './Pages/AddressReturningDocuments';
import { UrgencyExecution } from './Pages/UrgencyExecution';
import { OfficeDelivery } from './Pages/OfficeDelivery';
import { PersonalOrCompany } from './Pages/PersonalOrCompany';
import { Recipients } from './Pages/Recipients';
import { AddressCollectDocuments } from './Pages/AddressCollectDocuments';
import { AddressCollectDocumentsUpdate } from './Pages/AddressCollectDocumentsUpdate';
import { DocumentsReady } from './Pages/DocumentsReady';
import { CollectDocumentsOrUploadFile } from './Pages/CollectDocumentsOrUploadFile';
import { UrgencyRecipient } from './Pages/UrgencyRecipient';
import { FinalPage } from './Pages/FinalPage';
import { UploadFile } from './Pages/UploadFile';
import { ApostilleVerification } from './Pages/ApostilleVerification'
import { CertificateCollectMethod } from './Pages/CertificateCollectMethod'
import { CertificateReturnMethod } from './Pages/CertificateReturnMethod'
import { CollectBranchSelecting } from './Pages/CollectBranchSelecting'
import { ReturnBranchSelecting } from './Pages/ReturnBranchSelecting'
import { NotarizedTranslation } from './Pages/NotarizedTranslation'
import { PersonalOrCompanyToggle } from './Pages/PersonalOrCompanyToggle'
import { PersonDetails } from './Pages/PersonDetails'
import { PersonalDeliveryPage1 } from './Pages/PersonalDeliveryPage1'
import { CollectDocumentsOrUploadFile2 } from './Pages/CollectDocumentsOrUploadFile2'
import { Proof3 } from './Pages/Proof3'
import { CollectDocumentsAndDocumentsReady } from './Pages/CollectDocumentsAndDocumentsReady'
import { OfficeDeliveryFirst } from './Pages/OfficeDeliveryFirst'
import { CourtNameAndAction } from './Pages/UpdatedPages/CourtNameAndAction'
import { UploadFileUpdate } from './Pages/UploadFileUpdate'
import { CollectMethod } from './Pages/CollectMethod'
import { AddressReturningDocumentsUpdate } from './Pages/AddressReturningDocumentsUpdate'
import { handlePhase as handleCourtSubmissionProgressBar } from '../Forms/Form7/DynamicSteps'
import { handlePhase as handleApostilSubmissionProgressBar } from '../Forms/Form8/DynamicSteps'
import { handlePhase as handleLocateAddressProgressBar } from '../Forms/Form18/DynamicSteps'
import { handlePhase as handleLocatePhoneProgressBar } from '../Forms/Form6/DynamicSteps'
import { handlePhase as handlePersonLocateProgressBar } from '../Forms/Form16/DynamicSteps'
import { handlePhase as handlePersonalDeliveryProgressBar } from '../Forms/Form1/DynamicSteps'
import { handlePhase as handleOfficeDeliveryProgressBar } from '../Forms/Form9/DynamicSteps'
import { handlePhase as handleLandRegistrationProgressBar } from '../Forms/Form13/DynamicSteps'
import { handlePhase as handleInheritanceRegistrationProgressBar } from '../Forms/Form11/DynamicSteps'
import { handlePhase as handleDocumentTransferProgressBar } from '../Forms/Form19/DynamicSteps'
import { handlePhase as handleCompaniesRegistration } from '../Forms/Form10/DynamicSteps'
import { handlePhase as handlePartnershipsRegistration } from '../Forms/Form2/DynamicSteps'
import { handlePhase as handleAssociationsAndCompanies } from '../Forms/Form4/DynamicSteps'
import { handlePhase as handleSmallClaim } from '../Forms/Form20/DynamicSteps'
import { handlePhase as handleIdLocateProgressBar } from '../Forms/Form17/DynamicSteps'
import { handlePhase as handleApostilInCourtProgressBar } from '../Forms/Form3/DynamicSteps'

export const DynamicCmp = (props) => {
  const { pageName } = props;

  switch (props.type) {
    case 'courtSubmission':
      handleCourtSubmissionProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'apostilSubmission':
      handleApostilSubmissionProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'addressLocate':
      handleLocateAddressProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'phoneLocate':
      handleLocatePhoneProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'personLocate':
      handlePersonLocateProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'personalDelivery':
      handlePersonalDeliveryProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'officeDelivery':
      handleOfficeDeliveryProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'landRegistration':
      handleLandRegistrationProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'inheritanceRegistration':
      handleInheritanceRegistrationProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'documentTransfer':
      handleDocumentTransferProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'companiesRegistration':
      handleCompaniesRegistration(pageName, props.setProgressBarPhase);
      break;
    case 'partnershipsRegistration':
      handlePartnershipsRegistration(pageName, props.setProgressBarPhase);
      break;
    case 'associationsAndCompanies':
      handleAssociationsAndCompanies(pageName, props.setProgressBarPhase);
      break;
    case 'smallClaim':
      handleSmallClaim(pageName, props.setProgressBarPhase);
      break;
    case 'idLocate':
      handleIdLocateProgressBar(pageName, props.setProgressBarPhase);
      break;
    case 'apostilInCourt':
      handleApostilInCourtProgressBar(pageName, props.setProgressBarPhase);
      break;
    default:
      break;
  }


  switch (pageName) {
    case 'CourtName':
      return <CourtName {...props} />;
    case 'ChooseOffice':
      return <ChooseOffice {...props} />;
    case 'ChooseClaimType':
      return <ChooseClaimType {...props} />;
    case 'SmallClaimRecipients':
      return <SmallClaimRecipients {...props} />;
    case 'SmallClaimClaimant':
      return <SmallClaimClaimant {...props} />;
    case 'SmallClaimDefendant':
      return <SmallClaimDefendant {...props} />;
    case 'SmallClaimDescription':
      return <SmallClaimDescription {...props} />;
    case 'SmallClaimContract':
      return <SmallClaimContract {...props} />;
    case 'SmallClaimUrgencyExecution':
      return <SmallClaimUrgencyExecution {...props} />;
    case 'ActionInOffice':
      return <ActionInOffice {...props} />;
    case 'CollectFileDetails':
      return <CollectFileDetails {...props} />;
    case 'DepositFileDetails':
      return <DepositFileDetails {...props} />;
    case 'PowerOfAttorney':
      return <PowerOfAttorney {...props} />;

    case 'ActionsSummary':
      return <ActionsSummary {...props} />;

    case 'PersonDetails':
      return <PersonDetails {...props} />;

    case 'FirstSubmissionOrCopyInvolves':
      return <FirstSubmissionOrCopyInvolves {...props} />;
    case 'WhatServed':
      return <WhatServed {...props} />;
    case 'WhatServedRegist':
      return <WhatServedRegist {...props} />;
    case 'Toll':
      return <Toll {...props} />;

    case 'WhatHanded':
      return <WhatHanded {...props} />;

    case 'DeliveryAfterSubmission':
      return <DeliveryAfterSubmission {...props} />;

    case 'Proof':
      return <Proof {...props} />;

    case 'AddressReturningDocuments':
      return <AddressReturningDocuments {...props} />;

    case 'UrgencyExecution':
      return <UrgencyExecution {...props} />;

    case 'OfficeDelivery':
      return <OfficeDelivery {...props} />;

    case 'PersonalOrCompany':
      return <PersonalOrCompany {...props} />;

    case 'PersonalOrCompanyToggle':
      return <PersonalOrCompanyToggle {...props} />;

    case 'Recipients':
      return <Recipients {...props} />;

    case 'CollectDocumentsOrUploadFile':
      return <CollectDocumentsOrUploadFile {...props} />;

    case 'AddressCollectDocuments':
      return <AddressCollectDocuments {...props} />;
    case 'AddressCollectDocumentsUpdate':
      return <AddressCollectDocumentsUpdate {...props} />;

    case 'DocumentsReady':
      return <DocumentsReady {...props} />;

    case 'UrgencyRecipient':
      return <UrgencyRecipient {...props} />;

    case 'UploadFile':
      return <UploadFile {...props} />;

    case 'ApostilleVerification':
      return <ApostilleVerification {...props} />;

    case 'CertificateCollectMethod':
      return <CertificateCollectMethod {...props} />;

    case 'CertificateReturnMethod':
      return <CertificateReturnMethod {...props} />;

    case 'CollectBranchSelecting':
      return <CollectBranchSelecting {...props} />;

    case 'ReturnBranchSelecting':
      return <ReturnBranchSelecting {...props} />;

    case 'NotarizedTranslation':
      return <NotarizedTranslation {...props} />;

    case 'DocumentTransferType':
      return <DocumentTransferType {...props} />;
    case 'PersonalAddress':
      return <PersonalAddress {...props} />;
    //Updated Personal Delivery
    case 'PersonalDeliveryPage1':
      return <PersonalDeliveryPage1 {...props} />;
    case 'CollectDocumentsOrUploadFile2':
      return <CollectDocumentsOrUploadFile2 {...props} />;
    case 'Proof3':
      return <Proof3 {...props} />;

    //Updated for Registrations
    case 'CollectDocumentsAndDocumentsReady':
      return <CollectDocumentsAndDocumentsReady {...props} />;

    //Updated Office Delivery
    case 'OfficeDeliveryFirst':
      return <OfficeDeliveryFirst {...props} />;

    //Updated Court Submission
    case 'CourtNameAndAction':
      return <CourtNameAndAction {...props} />;
    case 'AddressReturningDocumentsUpdate':
      return <AddressReturningDocumentsUpdate {...props} />;

    //Updated for printingBinding
    case 'UploadFileUpdate':
      return <UploadFileUpdate {...props} />;
    case 'CollectMethod':
      return <CollectMethod {...props} />;

    case 'FinalPage':
      return <FinalPage {...props} />;

    default:
      return <></>;
    // default:
    //   return <FinalPage {...props} />;
  }
};
