const INITIAL_STATE = {
  mobileState: {
    isModalToolbarOpen: false,
    modalToolbar: 'cart',
    isMenuOpen: false,
    time: 0
  }
}
export function mobileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_MOBILE':
      return {
        ...state,
        mobileState: action.mobile
      }
    default:
      return state
  }
}