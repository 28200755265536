

export function setFiles(files) {
  return (dispatch) => {
    dispatch({ type: "SET_FILES", files });
  };
}

export function removeFiles(fileId) {
  return (dispatch) => {
    try {
      dispatch({ type: "REMOVE_FILES", fileId });
    } catch (err) {
      console.log("ERROR REMOVE_FILES!");
    }
  };
}

export function addFiles(file) {
  return (dispatch) => {
    try {
      dispatch({ type: "ADD_FILES", file });
    } catch (err) {
      console.log("ERROR! ADD_FILES");
    }
  };
}




