import db from '../../integrations/firebase';
import {auth} from '../../integrations/firebase';
import { doc, onSnapshot, updateDoc, getDoc, setDoc, deleteField } from 'firebase/firestore';
import { createPayment, Logger } from '../../../src/integrations/firebase';

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Back } from '../../images/backSmallWhite.svg'
import { updateSystemData } from '../../store/actions/systemActions';
import { Formik, Field } from 'formik';
import { validationCheckoutPage } from '../Forms/validitions'
import { Outlet } from "react-router-dom";
import { TailSpin } from 'react-loader-spinner';
import { setIsLoading } from '../../store/actions/systemActions'
import { useNavigate } from 'react-router-dom';
import { utilService } from '../../services/utilService'
import { updateUser } from '../../store/actions/userActions'
import { updateOrderId, updateOrderPrice } from '../../store/actions/trackingActions'
import { setOrders } from '../../store/actions/orderActions'
import { UsersDropdown } from './SideBar/UsersDropdown'
import { trace, perf } from '../../integrations/firebase';
import TagManager from 'react-gtm-module'

const logClient = new Logger(`Checkout`);
export function Checkout() {
  const t = trace(perf, "AllActions");
  t.start();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currUser = useSelector((state) => state.userReducer.user)
  const systemData = useSelector((state) => state.systemReducer.systemData)
  const company = useSelector((state) => state.companyReducer.company)
  const notifications = useSelector((state) => state.userDataReducer.userNotifications);
  const orderPrice = useSelector((state) => state.orderTrackingReducer.orderPrice)
  const [orderUrl, setOrderUrl] = useState(null)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [userOfOrder, setuserOfOrder] = useState({})
  const [isCompanyAndNotMaster, setIsCompanyAndNotMaster] = useState(false)
  const [isCompanyAndMaster, setIsCompanyAndMaster] = useState(false)
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isLoading = useSelector((state) => state.systemReducer.isLoading)
  const orders = useSelector((state) => state.orderReducer.Orders);
  const utm = useSelector((state) => state.utmReducer);
  // console.log('orders', orders)
  // console.log('currUser', currUser)
  // console.log('company Checkout', company)
  // console.log('userOfOrder', userOfOrder)
  const [copyOrders, setCopyOrders] = useState(orders)
  const isAllActionsArePriceless = orders?.every(order => {
    return currUser?.pricelessActions?.includes(order.action.type || order.type) || order?.prices?.data?.isBid;
  })
  const isAllActionsAreIsBid = orders?.every(order => {
    return order?.prices?.data?.isBid;
  })

  // const isUserPaymentDelayed = currUser?.paymentType === 'DELAYED' || isAllActionsArePriceless
  const isUserPaymentDelayedOrPricelessAction = currUser?.paymentType === 'DELAYED' || isAllActionsArePriceless
  const isUserPaymentDelayed = currUser?.paymentType === 'DELAYED'
  const isPriclessActionsOnlyAndUserDoesntPay = isAllActionsArePriceless && isUserPaymentDelayed
  const userFullName = !isUserPaymentDelayed ? currUser?.officeOrCompanyName || currUser?.fullName || (orders && orders.length > 0 && orders[orders.length - 1].contactDeliveryRecipientName)
    : isAllActionsArePriceless ? currUser.fullName : currUser?.officeOrCompanyName || currUser?.fullName || (orders && orders.length > 0 && orders[orders.length - 1].contactDeliveryRecipientName)
  const userName = currUser?.fullName || (orders && orders.length > 0 && orders[orders.length - 1].contactDeliveryRecipientName) || ''
  const userEmail = currUser?.email || ''
  const [invoiceMail, setInvoiceMail] = useState(userEmail)
  const [orderValues, setOrderValues] = useState({
    invoiceCompanyName: userFullName,
    orderClientName: userFullName,
  })
  // console.log('isAllActionsArePriceless', isAllActionsArePriceless)
  // console.log('isPriclessActionsOnlyAndUserDoesntPay', isPriclessActionsOnlyAndUserDoesntPay)
  // console.log('userFullName',userFullName)
  //Check User full name
  // console.log('orderValues', orderValues)

  const tagManagerArgs = {
    gtmId: 'GTM-N57K36G',
  }
  TagManager.initialize(tagManagerArgs)
  useEffect(() => {
    if (!currUser) navigate('/')
    else {
      const unsub = onSnapshot(doc(db, "activeCarts", currUser.uid), (doc) => {
        if (doc.data().orders.length > 0) {
          let intermediateOrderPrice = 0
          doc.data().orders.forEach(action => {
            const price = (action?.prices?.data?.total && action?.prices?.data?.total !== -1) ? action?.prices?.data?.total : 0
            const vat = action?.prices?.data?.vat || 0
            TagManager.dataLayer({
              "event": "actionPurchase",
              "price": price,
              "actionType": action.type
            })
            intermediateOrderPrice += price
          })
          dispatch(updateOrderPrice(Number(intermediateOrderPrice.toFixed(2))
          ));
        }
        if (doc.data().hasPaid) {
          if (!(currUser.paymentType === 'REG') && isSubmitted) {
            dispatch(setIsLoading(false))
          }
          dispatch(updateOrderId(doc.data().orderId
          ));
          // window.fbq('track', 'Purchase', {currency: "ILS", value: Number(orderPrice.toFixed(2))});
          clearCart(doc.data().orderId)
          console.log("navigating")
          navigate('/new-order')
        }
      });
      return () => unsub();
    }
  }, [isSubmitted]);


  useEffect(() => {
    if (orderUrl) {
      dispatch(setIsLoading(false))
    }
  }, [orderUrl])

  useEffect(() => {
    setOrderUrl(null)
    dispatch(setIsLoading(false))
    setIsSubmitted(false)
    if (orders.length > 0) {
      const myOrders = JSON.parse(JSON.stringify(orders));
      setCopyOrders([...myOrders])
    };
  }, [orders.length])

  useEffect(() => {
    setuserOfOrder(currUser ? currUser : {})
    dispatch(updateSystemData({
      ...systemData, isCheckout: true
    }));
    dispatch(setIsLoading(false))
    return () => {
      dispatch(updateSystemData({
        ...systemData, isCheckout: false
      }));
    }
  }, [])

  useEffect(() => {

    window.addEventListener(
        "message",
        async (ev) => {
          console.log(ev.data)
          if (typeof ev.data !== "object") return;
          if (!ev.data.event_type) return;
          if (ev.data.event_type != "payment_success") {
            console.log(ev.data)
            // handle payment error
          } else if (ev.data.event_type === "payment_success"){
            function makeOrderId() {
              let result = "";
              const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
              const numbers = "0123456789";
              const charactersLength = characters.length;
              const numbersLength = numbers.length;
              for (let i = 0; i < 3; i++) {
                result += numbers.charAt(Math.floor(Math.random() * numbersLength));
              }
              for (let j = 0; j < 5; j++) {
                result += numbers.charAt(Math.floor(Math.random() * numbersLength));
              }
              return result;
            }
            const paymentBody = {
              docnum: ev.data.payment_info.docnum,
              doctype: "receipt",
              email: invoiceMail,
              utm_content: currUser.uid,
              utm_source: userOfOrder?.uid,
              utm_campaign: currUser.companyId,
              isQuick: true,
              orderId: makeOrderId()
            }
            console.log(paymentBody)
            const res = await fetch(process.env.REACT_APP_PAYMENT_SUCCESS_URL, {
              method: "POST",
              mode: "no-cors",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(paymentBody),
            })
            console.log(res);
            const docRef = doc(db, "activeCarts", currUser.uid);
            await updateDoc(docRef, { hasPaid: true, orders: [], userOfOrder: {}, orderId: paymentBody.orderId })

            // console.log(db.collection('activeCarts').doc(currUser.uid).update({ hasPaid: true, orders: [], userOfOrder: {}, orderId: makeOrderId() }))
          }
        }
    );
  }, [])

  useEffect(() => {
    if (systemData.isEditMode) {
      setOrderUrl(null)
      dispatch(setIsLoading(false))
      setIsSubmitted(false)
    }
    else {
      const myOrders = JSON.parse(JSON.stringify(orders));
      setCopyOrders([...myOrders])
    }
  }, [systemData.isEditMode, orders])

  useEffect(() => {
    checkIfIsCompanyAndMaster()
  }, [currUser]);

  const checkIfIsCompanyAndMaster = () => {
    if (currUser && (currUser?.accountType === "חשבון חברה / מחלקה משפטית"
      || currUser?.accountType === 'משרד עורכי דין')) {
      if (currUser?.isMaster) setIsCompanyAndMaster(true)
      else setIsCompanyAndNotMaster(true)
    }
  }

  const handleChange = (event) => {
    const { name, value } = event.target
    setOrderValues({ ...orderValues, [name]: value })
  }
  const handleEmailChange = (event) => {
    const { name, value } = event.target
    setInvoiceMail(value)
  }

  const handleUserOfOrder = (user) => {
    setOrderValues({ ...orderValues, 'invoiceEmail': user.email, 'orderClientName': user.fullName })
    setuserOfOrder(user)
  }

  const clearCart = async (orderId) => {
    if (currUser?.fullName && isSubmitted) {
      const docRef = doc(db, "activeCarts", currUser.uid);
      updateDoc(docRef, { hasPaid: false, orderId: deleteField() }, { merge: true }).then(() => {
        dispatch(setOrders([]));
      });
      localStorage.setItem('lastRecipients', JSON.stringify([]))
    } else {
      const docRef = doc(db, "activeCarts", currUser.uid);
      updateDoc(docRef, { hasPaid: false, orderId: deleteField() }, { merge: true }).then(() => {
        dispatch(setOrders([]));
        localStorage.setItem('lastRecipients', JSON.stringify([]))
      });
    }
  }

  const handleSubmit = async (values) => {
    console.log({
      isSubmitted,
      orders,
      isLoading
    })
    if (orders.length === 0 || isSubmitted) { return }
    setIsSubmitted(true)
    dispatch(setIsLoading(true))
    const cartTotalItems = orders.map((item) => {
      if (!(currUser?.pricelessActions?.includes(item.action.type)) || !item?.prices?.data?.isBid) {
        return item.prices.data?.receiptItems?.map((receiptItem) => {
          for (let x in receiptItem) {
            if (x === 'unitprice' || x === 'unitprice_incl' || x === 'quantity') {
              receiptItem[x] = parseFloat(receiptItem[x])
            }
          }
          return receiptItem;
        })
      } else {
        const toll = utilService.countTollsByOrderType(item)
        if (toll.price > 0) {
          return {
            "description": `ללא תמחור`,
            "unitprice_incl": toll.price,
            "quantity": toll.count,
          }
        }
      }
    })

    let flatItems = cartTotalItems.flat()
    flatItems = flatItems.filter(item => item !== undefined && item !== null)
    const cartRef = doc(db, "activeCarts", currUser.uid);
    const cart = await getDoc(cartRef);
    const cartData = await cart.data();
    const payCartRef = doc(db, "pendingPayment", currUser.uid);
    const additionalData = {
      orderClientName: values?.orderClientName || orderValues.orderClientName,
      orderEmail: values?.invoiceEmail || orderValues.invoiceEmail,
      userOfOrder: userOfOrder.isAnonymous ? {} : {
        uid: userOfOrder?.uid,
        fullName: userOfOrder?.fullName,
        officeOrCompanyName: userOfOrder?.officeOrCompanyName,
        phoneNumber: userOfOrder?.phoneNumber,
        email: userOfOrder?.email
      },
      utm
    };
    await Promise.all([
      updateDoc(cartRef, additionalData).then((r) => { }).catch((e) => {
        logClient.error('error when updating cart data');
        logClient.error(JSON.stringify(additionalData))
        logClient.error(JSON.stringify(e));
      }),
      setDoc(payCartRef, Object.assign(cartData, additionalData))
    ])

    const orderDetails = {
      paymentType: isUserPaymentDelayed || isUserPaymentDelayedOrPricelessAction ? 'DELAYED' : currUser?.paymentType || 'REG',
      invoiceInc: values?.invoiceInc || orderValues?.invoiceInc || '',
      //Update for user
      nameOnInvoice: values?.invoiceCompanyName || '',
      nameOnOrder: values?.orderClientName || '',
      invoicePhoneNumber: currUser?.fullName ? currUser?.phoneNumber : (orders && orders.length > 0) ? orders[orders.length - 1].contactDeliveryRecipientPhone : '',
      items: flatItems,
      //Update for user
      email: values?.invoiceEmail || orderValues.invoiceEmail,
      cartId: currUser.uid,
      forUser: userOfOrder?.uid,
      companyId: currUser.companyId,
      financeEmail: company?.officeBookkeepingEmail,

      //Update for user

      userOfOrder: {
        fullName: userOfOrder ? userOfOrder?.fullName : (values?.orderClientName || orderValues.orderClientName || ''),
        officeOrCompanyName: userOfOrder?.officeOrCompanyName || '',
        phoneNumber: userOfOrder ? userOfOrder?.phoneNumber : '',
        email: userOfOrder ? userOfOrder.email : (values?.invoiceEmail || orderValues.invoiceEmail),
      }
    }
    
    let resultPayment = await createPayment(orderDetails)
    // console.log('resultPayment', resultPayment)

    setOrderUrl(resultPayment.data)
    if (currUser.isAnonymous || currUser.paymentType === 'REG') {
      dispatch(setIsLoading(false))
    }
  };

  const handleClosePage = () => {
    dispatch(updateSystemData({
      ...systemData, isCheckout: false
    }));
    navigate(`/`);
  };

  const returnValue = (
    <div className='main-checkout page-layout'>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          invoiceInc: currUser?.officeOrCompanyId || company?.officeOrCompanyId || '',
          invoiceCompanyName: userFullName,
          invoiceEmail: userEmail,
          orderClientName: userName,
          isDisclaimerCheck: false
        }}
        validationSchema={validationCheckoutPage}
        enableReinitialize={true}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit} autoComplete="new-password">
            <div className='content'>
              <div className="checkout-top">
                {!isUserPaymentDelayed && !isUserPaymentDelayedOrPricelessAction ? <h2>סיום הזמנה ותשלום</h2> : <h2>אישור וסיום הזמנה</h2>}
                <button type="button" className="back-btn new-order-btn" onClick={handleClosePage}>
                  <p>להוספת שירותים להזמנה</p>
                  <Back />
                </button>
              </div>

              <div className="main-input-container">
                {!isPriclessActionsOnlyAndUserDoesntPay && !isAllActionsAreIsBid && <div className="content-bottom">
                  <h3>פרטים לחשבונית</h3>
                  <div className="inputs" >
                    <div className="input-container">
                      <label>שם על החשבונית*</label>
                      <Field
                        autoComplete="new-password"
                        onChange={(e) => { handleChange(e); props.handleChange(e) }}
                        className={`${props?.values?.invoiceCompanyName?.length > 0 ? 'success name-input' : props?.touched?.invoiceCompanyName && Boolean(props.errors.invoiceCompanyName) ? 'error name-input' : 'name-input'}
                        ${isCompanyAndNotMaster ? 'disable-input' : ''}`}
                        type='text'
                        name="invoiceCompanyName"
                        value={props?.values?.invoiceCompanyName}
                        disabled={isCompanyAndNotMaster}
                      />
                      <p> {(props.touched.invoiceCompanyName && props.errors.invoiceCompanyName) || null}</p>
                    </div>
                    <div className="input-container" >
                      <label>ע.מ./ ח.פ.</label>
                      <Field
                        autoComplete="new-password"
                        onChange={(e) => { handleChange(e); props.handleChange(e) }}
                        className={`${props.values.invoiceInc.length > 0 ? 'success name-input' : 'name-input'}
                        ${isCompanyAndNotMaster ? 'disable-input' : ''}`}
                        type='text'
                        name="invoiceInc"
                        value={props.values.invoiceInc}
                        disabled={isCompanyAndNotMaster}
                      />
                    </div>
                  </div>
                </div>}
                <div className="content-bottom">
                  <h3>פרטי המזמין</h3>
                  <div className="inputs">
                    {isCompanyAndMaster ?
                      <div className="input-container" >
                        <label>שם המזמין*</label>
                        <UsersDropdown handleChange={handleChange} props={props} orderValues={orderValues} handleUserOfOrder={handleUserOfOrder} company={company} />
                      </div>
                      :
                      <div className="input-container" >
                        <label>שם המזמין*</label>
                        <Field
                          autoComplete="new-password"
                          onChange={(e) => { handleChange(e); props.handleChange(e) }}
                          className={`${props.values.orderClientName.length > 0 ? 'success name-input' : props.touched.orderClientName && Boolean(props.errors.orderClientName) ? 'error name-input' : 'name-input'}
                          ${isCompanyAndNotMaster ? 'disable-input' : ''}`}
                          type='text'
                          name="orderClientName"
                          value={props.values.orderClientName}
                          placeholder=''
                          disabled={isCompanyAndNotMaster}
                        />
                        <p> {(props.touched.orderClientName && props.errors.orderClientName) || null}</p>
                      </div>
                    }
                    <div className="input-container" >
                      <label>כתובת דוא"ל*</label>
                      <Field
                        autoComplete="new-password"
                        onChange={(e) => { handleChange(e); handleEmailChange(e); props.handleChange(e) }}
                        className={`${emailRegex.test(props.values.invoiceEmail) ? 'success name-input' : props.touched.invoiceEmail && Boolean(props.errors.invoiceEmail) ? 'error name-input' : 'name-input'}
                        ${currUser?.accountType === "חשבון חברה / מחלקה משפטית"
                            || currUser?.accountType === 'משרד עורכי דין' ? 'disable-input' : ''}`}
                        type='text'
                        name="invoiceEmail"
                        value={props.values.invoiceEmail}
                        placeholder=''
                        disabled={currUser?.accountType === "חשבון חברה / מחלקה משפטית"
                          || currUser?.accountType === 'משרד עורכי דין'}
                      />
                      <p> {(props.touched.invoiceEmail && props.errors.invoiceEmail) || null}</p>
                    </div>

                  </div>



                </div>
              </div>
              <div className="submit-area" >
                <div className="input-container-checkbox" style={{ marginTop: '20px' }}>
                  <Field
                    autoComplete="new-password"
                    onChange={(e) => { handleChange(e); props.handleChange(e) }}
                    className='checkbox-input'
                    type='checkbox'
                    name="isDisclaimerCheck"
                    checked={props.values.isDisclaimerCheck}
                  />
                  <label className={(props.touched.isDisclaimerCheck && props.errors.isDisclaimerCheck) ? 'red-terms' : ''}>
                    קראתי ואני מאשר/ת את
                    <a href="https://delawvery.co.il/terms-of-use/" target="_blank" rel="noreferrer"> תנאי השימוש</a>
                    <a href="https://delawvery.co.il/privacy-policy/" target="_blank" rel="noreferrer"> ומדיניות הפרטיות </a>
                  </label>
                </div>
                <div className="main-input-container">
                  <button type="submit" className="main-btn long center">{isLoading ? <div>
                    <TailSpin
                      heigth="50"
                      width="50"
                      color='#ffffff'
                      ariaLabel='loading'
                    />
                  </div>
                    : !isUserPaymentDelayed && !isUserPaymentDelayedOrPricelessAction ? 'השלמת תשלום' : 'השלמת הזמנה'}</button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
      {
        !isUserPaymentDelayed && !isUserPaymentDelayedOrPricelessAction && <div className="iframe-container">
          {!isLoading && orderUrl ?
            <iframe
              title="iframe"
              src={orderUrl}
              width="800"
              height="700"
              boder="none"
              outline="none"
              scrolling="no"
            />
            : !isLoading && !orderUrl ? <></> :
              // <TailSpin
              //   heigth="100"
              //   width="100"
              //   color='#4e71ff'
              //   ariaLabel='loading'
              // />
              <></>

          }
          {!isLoading && !orderUrl && <></>}
        </div>
      }
      <Outlet />

    </div >
  );
  t.stop();
  return returnValue
}