import React from "react";
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ReactComponent as Close } from '../../../images/close.svg'

export function VerifiedEmailPopup() {
    const navigate = useNavigate();
    const systemData = useSelector((state) => state.systemReducer.systemData)

    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };
    return (<>

        <div className="email-sent">
            <Close onClick={setModalClose} />
            <div className="system-msg">

                <div className="msg-content">
                    <h2 className="main-title bold">האימות בוצע בהצלחה!</h2>
                    <p className="decription">
                        <br />
                        מצוין! אימתנו את כתובת המייל שלך. לחזרה לעמוד הבית לחצו על הכפתור והתחילו בהזמנה   <span>💨  🏍  ⚖️</span>
                    </p>
                </div>
                <button type='submit' onClick={() => navigate('/')} className='main-btn long register' >חזרה למסך ההזמנה</button>
            </div>
        </div>
    </>
    );
}


