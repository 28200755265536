const INITIAL_STATE = {
  Files: [],
};

export function fileReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_FILES':
      return {
        ...state,
        Files: action.files,
      };

    case "ADD_FILES":
      return {
        ...state,
        Files: [...state.Files, action.file],
      };

    case "REMOVE_FILES":
      return {
        ...state,
        Files: state.Files.filter((currFile) => currFile._id !== action.fileId),
      };

    default:
      return state;
  }
}
