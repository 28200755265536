import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { updateSystemData } from '../../../store/actions/systemActions';
import { setRegisteredUser, login } from '../../../store/actions/userActions';
import arrow from '../../../images/arrowdropdown.png';
import secure from "../../../images/secure.svg"
import { Field, Formik } from 'formik';
import { validationSignupUnderCustomer } from '../validations'
import { doc, setDoc } from "@firebase/firestore";
import db, { Logger } from '../../../integrations/firebase'
import { useNavigate } from 'react-router-dom';
import { createUserUnderCustomer, onCallNotifications } from '../../../integrations/firebase';
import { ReactComponent as Close } from '../../../images/close.svg'
import { ReactComponent as Back } from '../../../images/back-small.svg'
import { auth, actionCodeSettings } from '../../../integrations/firebase'
import { TailSpin } from 'react-loader-spinner';
import { setIsLoading } from '../../../store/actions/systemActions'
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const logClient = new Logger(`SignupDetails`);

export const FirstLogin = () => {
    const navigate = useNavigate();
    const ref = useRef()
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.systemReducer.isLoading)
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [touched, setTouched] = useState({});
    const activeUser = useSelector((state) => state.userReducer.user);
    const invites = useSelector((state) => state.userReducer.invites);
    const [isLawyer, setIsLicense] = useState(false)
    const [userInvitesData, setUserInvitesData] = useState(null);
    const phoneRegex = /^\d{9,10}$/;
    const [values, setValues] = useState({
        phoneNumber: activeUser?.phoneNumber || '',
        password: '',
        passwordConfirmation: '',
        email: activeUser?.email || '',
        lawyerNumber: '',
        isTerms: false,
    })

    useEffect(async () => {
        const userData = invites[activeUser?.uid]
        if (userData) {
            const obj = {
                accountType: userData.accountType,
                isAuthNeeded: userData.isAuthNeeded,
                isCaseNeeded: userData.isCaseNeeded,
                masters: userData.masters
            }
            setUserInvitesData(obj)
        }
        setValues({ ...values, email: activeUser?.email || '' })
    }, [activeUser])


    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handleBlur = (e) => {
        setTouched({ ...touched, [e.target.name]: true })
    }

    const handleBack = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'signupOptions' }
        }));
        navigate('/LoginSignup')
    }

    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };

    const handleIsLayer = (e) => {
        setIsLicense(!isLawyer)
        setValues({ ...values, lawyerNumber: '' })
    }
    const handleIsTerms = (e) => {
        setValues({ ...values, isTerms: !values.isTerms })
    }

    const onSubmit = async (values) => {
        if (isSubmitted) return
        setIsSubmitted(true)
        dispatch(setIsLoading(true))
        const user = {
            email: activeUser.email,
            fullName: activeUser.displayName,
            phoneNumber: values.phoneNumber,
            accountType: userInvitesData.accountType,
            isLawyer,
            lawyerNumber: values.lawyerNumber,
            isTerms: values.isTerms || false,
            addresses: [],
            discountedActions: [],
            discountPercentage: 0,
            pricelessActions: [],
            isCaseNeeded: userInvitesData.isCaseNeeded,
            isAuthNeeded: userInvitesData.isAuthNeeded,
            mondayOrderIds: [],
            notifications: [],
            userActivity: "active",
            masters: userInvitesData.masters,
            uid: userInvitesData.uid || activeUser.uid,
        }

        const userRef = doc(db, "users", activeUser.uid);
        await setDoc(userRef, user).then((r) => { }).catch((e) => {
            logClient.error('error when updating user data');
            logClient.error(JSON.stringify(user))
            logClient.error(JSON.stringify(e));
        });
        const objToSend = {
            displayName: activeUser.displayName || '',
            userInvitesData,
            type: 'firstLoginNotificationToMasters'
        }

        Promise.all([createUserUnderCustomer({ pass: values.password }), onCallNotifications(objToSend)]).then(() => {
            const email = values?.email || activeUser?.email
            signInWithEmailAndPassword(auth, email, values.password)
                .then(async (userCredential) => {
                    dispatch(login({ ...activeUser, ...user }))
                    dispatch(setIsLoading(false))
                    navigate('/welcome')
                    window.location.reload()
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    console.log('%c  errorMessage:', 'color: white;background: red;', errorMessage);
                });
        });
    }

    return <>
        <div className="header-btns first-login-header">
            <Close onClick={setModalClose} />
        </div>
        <div className="content">

            <div className="login-step">
                <Formik
                    onSubmit={onSubmit}
                    validateOnChange={true}
                    validateOnBlur={true}
                    validateOnMount={true}
                    initialValues={{
                        password: values.password,
                        passwordConfirmation: values.passwordConfirmation,
                        email: values.email,
                        phoneNumber: values.phoneNumber,
                        lawyerNumber: values.lawyerNumber,
                        isTerms: values.isTerms,
                    }}
                    enableReinitialize={true}
                    validationSchema={validationSignupUnderCustomer}
                >
                    {(props) => {
                        return (
                            <form onSubmit={props.handleSubmit}>
                                <h2 className="main-title">{`שלום ${activeUser?.displayName || ''},`}</h2>
                                <h3>{'חסרים 3 פרטים להשלמת ההתחברות'}</h3>
                                <div className='field'>
                                    <label>
                                        מספר פלאפון*
                                    </label>
                                    <Field
                                        type='number'
                                        className={(touched.phoneNumber || props.touched.phoneNumber) && Boolean(props.errors.phoneNumber) ? 'signup-input error' :
                                            phoneRegex.test(props.values.phoneNumber) ? 'signup-input success' : 'signup-input'}
                                        name='phoneNumber'
                                        onChange={handleChange}
                                        value={values.phoneNumber}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                <div className='field'>
                                    <label>
                                        סיסמה חדשה*
                                    </label>
                                    <Field
                                        className={(touched.password || props.touched.password) && Boolean(props.errors.password) ? 'signup-input error' :
                                            props.values.password.length >= 8 ? 'signup-input success' : 'signup-input'}
                                        type='password'
                                        name='password'
                                        value={values.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                <div className='field password-confirm-field'>
                                    <label>
                                        הזן סיסמה חדשה בשנית*
                                    </label>
                                    <Field
                                        className={(touched.passwordConfirmation || props.touched.passwordConfirmation) && Boolean(props.errors.passwordConfirmation) ? 'signup-input error' :
                                            (props.values.passwordConfirmation === props.values.password && props.values.password.length >= 8) ? 'signup-input success' : 'signup-input'}
                                        type='password'
                                        onChange={handleChange}
                                        name='passwordConfirmation'
                                        value={values.passwordConfirmation}
                                    />
                                    <p>
                                        סיסמה צריכה להכיל לפחות 8 תווים
                                    </p>
                                </div>


                                <div>
                                    <label className="checkbox-container">
                                        <input type="checkbox" id="isLawyer" name="isLawyer" checked={isLawyer} onChange={() => handleIsLayer()} />
                                        <label htmlFor='isLawyer'>
                                            <span>האם יש ברשותך רישיון לעריכת דין?</span>
                                        </label>
                                        <span className="checkmark"></span>
                                    </label>

                                    {isLawyer && <div className='field'>
                                        <label>
                                            מס' הרישיון
                                        </label>
                                        <Field
                                            className={(touched.lawyerNumber || props.touched.lawyerNumber) && Boolean(props.errors.lawyerNumber) ? 'signup-input error' :
                                                props.values.lawyerNumber.length > 0 ? 'signup-input success' : 'signup-input'}
                                            type='number'
                                            name='lawyerNumber'
                                            onChange={handleChange}
                                            value={values.lawyerNumber}
                                            onBlur={handleBlur}
                                        />
                                    </div>}
                                </div>

                                <label className="checkbox-container">
                                    <Field type="checkbox" id="isTerms" name="isTerms" checked={props.values.isTerms} onChange={() => handleIsTerms()} />
                                    <label htmlFor='isTerms' className={(props.touched.isTerms && props.errors.isTerms) ? 'red-terms' : ''}>
                                        אני מסכימ/ה
                                        <span>
                                            <a className={(props.touched.isTerms && props.errors.isTerms) ? 'red-terms' : ''} href='https://delawvery.co.il/terms-of-use/' rel='noreferrer' target="_blank"> לתנאי השימוש </a>
                                            ואת
                                            <a className={(props.touched.isTerms && props.errors.isTerms) ? 'red-terms' : ''} href='https://delawvery.co.il/privacy-policy/' rel='noreferrer' target="_blank"> מדיניות הפרטיות </a>
                                        </span>
                                    </label>
                                    <span className="checkmark"></span>

                                </label>

                                <button
                                    type='submit'
                                    className='main-btn long register center'
                                    onClick={() => setTouched({ phoneNumber: true, email: true, password: true, isTerms: true })}>
                                    {isLoading ? <TailSpin
                                        heigth="50"
                                        width="50"
                                        color='#ffffff'
                                        ariaLabel='loading'
                                    /> : 'סיום והתחברות'}
                                </button>
                            </form>
                        )
                    }}
                </Formik>
                <div className="security-note">
                    <div>
                        <img src={secure} alt="secure" />
                        <p>המידע שלכם שמור בבטחה</p>
                    </div>
                    <p>רכישה מאובטחת בתקן PCI DSS</p>
                </div>
            </div >
        </div>
    </>
};


