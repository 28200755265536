import React, { useState, useEffect, useMemo } from 'react';
import {
  getExtraTextUrgency,
} from '../../../consts/forms'
import { TextToggle } from './PageUtils/TextToggle';
import { useSelector } from 'react-redux';

export const UrgencyExecution = ({ data, setData, type }) => {
  const [alignment, setAlignment] = useState('left');
  const systemData = useSelector((state) => state.systemReducer.systemData);
  const optionA = 'REG';
  const optionB = 'URG';
  const optionC = 'VERY_URG';
  const isBold = type === 'apostilSubmission'

  useEffect(() => {
    const { urgency } = data;

    if (type === 'documentTransfer') {
      const isSpecialUrgencyPrice = systemData.isSpecialUrgencyPrice || false
      if (!urgency) {
        setData({ ...data, urgency: optionA, isSpecialUrgencyPrice });
      } else {
        setData({ ...data, isSpecialUrgencyPrice });
      }
    } else {
      if (!urgency) {
        setData({ ...data, urgency: optionA });
      }
    }

    setAlignment(
      urgency === optionB ? 'left' : urgency === optionC ? 'end' : 'right'
    );
  }, []);

  const handleUrgency = (event, newAlignment) => {
    event.preventDefault();
    const { name, value } = event.target;
    setAlignment(newAlignment);
    setData({ ...data, [name]: value });
  };

  const toggleFormatMap = {
    'courtSubmission': 0,
    'apostilSubmission': 1,
    'personLocate': 2,
    'landRegistration': 3,
    'inheritanceRegistration': 0,
    'documentTransfer': systemData.isSpecialUrgencyPrice ? 7 : 4,
    'companiesRegistration': 0,
    'partnershipsRegistration': 0,
    'associationsAndCompanies': 0,
    'apostilInCourt': 5,
    'printingBinding': 6,
    'landSupervisor': 3,
  }

  const getTextByUrgency = (formName, alignment, isNext = false) => {
    const toggleFormat = getToggleFormat(toggleFormatMap[formName], isNext);
    return toggleFormat[alignment]
  };

  const onCustomerService = () => {
    const encodedText = encodeURIComponent('שלום, אני אשמח לקבל הצעת מחיר בנוגע למשלוח של אפוסטיל משרד החוץ בפחות מ-8 ימי עסקים.');

    window.open(
      `https://api.whatsapp.com/send/?phone=972537006707&text=${encodedText}&app_absent=0`,
      '_blank'
    );
  };

  const getStyleByForm = () => {
    switch (type) {
      case 'courtSubmission':
        return 'update-cmps in-form'
      default:
        return ''
    }
  }

  const getToggleFormat = (formatIdx, isNext=false) => {
    let toggleFormat = [
      ['ביצוע תוך 1 ימי עסקים מיום קבלת המסמכים',
        'ביצוע תוך 3 ימי עסקים מיום קבלת המסמכים',
        'ביצוע ביום קבלת המסמכים'],
      ['ביצוע תוך 14 ימי עסקים מיום קבלת התעודות (יום האיסוף לא נספר במניין הימים)',
        'ביצוע תוך 30 ימי עסקים מיום קבלת התעודות (יום האיסוף לא נספר במניין הימים)',
        'ביצוע תוך 8 ימי עסקים מיום קבלת התעודות (יום האיסוף לא נספר במניין הימים)'],
      ['ביצוע תוך 3 ימי עסקים',
        'ביצוע תוך 5 ימי עסקים',
        'ביצוע תוך 1 ימי עסקים'],
      ['ביצוע תוך 1 ימי עסקים מיום איסוף התיקים',
        'ביצוע תוך 3 ימי עסקים מיום איסוף התיקים',
        'ביצוע ביום איסוף התיקים'],
      ['ביצוע תוך 1 ימי עסקים מיום איסוף המסמכים, בשליחות כפולה עד 2 ימי עסקים.',
        'ביצוע תוך 5 ימי עסקים מיום איסוף המסמכים,  בשליחות כפולה עד 10 ימי עסקים.',
        'ביצוע ביום איסוף המסמכים, בשליחות כפולה עד 1 ימי עסקים.'],
      ['ביצוע תוך 3 ימי עסקים מיום קבלת המסמכים',
        'ביצוע תוך 7 ימי עסקים מיום קבלת המסמכים',
        'ביצוע תוך 1 ימי עסקים מיום קבלת המסמכים'],
      ['ביצוע תוך 3 ימי עסקים (יום ההזמנה לא נספר במניין הימים)',
        'ביצוע תוך 5 ימי עסקים (יום ההזמנה לא נספר במניין הימים)',
        'ביצוע תוך 1 ימי עסקים (יום ההזמנה לא נספר במניין הימים)'],
      ['ביצוע תוך 4 שעות',
        'ביצוע עד סוף יום העסקים (18:00)',
        'ביצוע תוך 2 שעות']
    ][formatIdx]
    if (isNext) {
      toggleFormat = toggleFormat.map((tog) => {
        return tog + "\n(הביצוע יהיה ביום העסקים הבא)ֿ"
      })
    }
    return { 'left': toggleFormat[0], 'right': toggleFormat[1], 'end': toggleFormat[2] }
  }


  return (
    <div className={`main-form form7-urgency ${getStyleByForm()}`}>
      <div className='modal-content-container block'>

        <h2 className='large-title'>מה רמת הדחיפות לביצוע?</h2>

        <div className={`form-main-toggle toggle-three ${alignment}`}>
          <span></span>
          <button
            type='button'
            name='urgency'
            value={optionA}
            onClick={(e) => handleUrgency(e, 'right')}
          >
            {'רגיל'}
          </button>
          <button
            type='button'
            name='urgency'
            value={optionB}
            onClick={(e) => handleUrgency(e, 'left')}
          >
            {'דחוף'}
          </button>
          <button
            type='button'
            name='urgency'
            value={optionC}
            onClick={(e) => handleUrgency(e, 'end')}
          >
            {'בהול'}
          </button>
        </div>

        <p className='label'>{getTextByUrgency(type, alignment, systemData.isSpecialUrgencyPrice === 'next')}</p>
        <p className={` ${isBold ? 'bold' : 'label'}`}>{getExtraTextUrgency(data.urgency, type, systemData.isSpecialUrgencyPrice)}</p>

        {type === 'apostilSubmission' && alignment === 'end' &&
          <div className='extra-handling-urgency'>
            <p className='extra-handling-text bold'>צריכים את התעודות בפחות מ-8 ימי עסקים?</p>
            <p className='extra-handling-text'> שלחו לנו הודעה בוואטסאפ עם כל הפרטים ונחזור אליכם בהקדם האפשרי עם הצעת מחיר</p>
            <p onClick={onCustomerService} className='main-btn long'>לחצו לשליחת הודעה 053-700-6-707</p>
          </div>
        }
        {/*{(type === 'landRegistration' || type === 'landSupervisor') && (data?.actionInOffice === 'depositFile' || data?.isOtherSubmitter) &&*/}
        {/*  <div className='extra-handling-urgency'>*/}
        {/*    <h2 className='large-title extra-handling-text'>{`האם יש ${data.collectFiles?.length ? 'תיקים נוספים' : 'גם תיקים'} לאיסוף מהלשכה?`}</h2>*/}
        {/*    /!*<TextToggle data={data} setData={setData} formParam={'registrationAddDeposit'} />*!/*/}
        {/*  </div>*/}
        {/*}*/}

      </div>
    </div>
  );
};

export default UrgencyExecution;
