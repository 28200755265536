import React from "react";
import CoverImage from "../../../images/background_cover.svg";
import { useViewport } from '../../../Hooks/useViewport'

export default function HomeCover() {

  const { width } = useViewport();
  const breakpoint = 420;

  return (
    <div className="home-cover">
      <div className="home-text">
        <h1>
          בוחרים. ממלאים. מזמינים.
        </h1>

        {width > breakpoint &&
          <><p className="home-cover-subtitle">
            בוחרים שירות, ממלאים את כלל השדות ומוסיפים להזמנה!
          </p>
            <p className="home-cover-subtitle">
              כמובן שאפשר להוסיף יותר משירות אחד
              &nbsp;
              <span style={{ 'fontSize': '16px' }}>
                💨
              </span>
              <span style={{ 'fontSize': '30px' }}>
                🏍
              </span>
              <span style={{ 'fontSize': '23px' }}>
                ⚖️
              </span>
            </p>
          </>}

        {width < breakpoint && <p className="home-cover-subtitle">
          בוחרים שירות, ממלאים את כלל השדות ומוסיפים להזמנה!
          כמובן שאפשר להוסיף יותר משירות אחד
          &nbsp;
          <div>
            <span style={{ 'fontSize': '16px' }}>
              💨
            </span>
            <span style={{ 'fontSize': '30px' }}>
              🏍
            </span>
            <span style={{ 'fontSize': '23px' }}>
              ⚖️
            </span>
          </div>
        </p>
        }
      </div>
      <img src={CoverImage} alt="home-img" />
    </div>
  );
}

