import * as Yup from 'yup';

export const validationSignupPage = Yup.object().shape({
    mail: Yup.string()
        .email("נדרש למלא דוא״ל תקין")
        .required('נדרש למלא דוא״ל'),
    password: Yup.string()
        .min(8, 'קצר מדי')
        .required('נדרש להזין סיסמה '),
    passwordConfirmation: Yup.string()
        .test('passwords-match', 'סיסמאות אינן תואמות', function (value) {
            return this.parent.password === value
        })
});
export const validationSetPasswordPage = Yup.object().shape({
    password: Yup.string()
        .min(8, 'קצר מדי')
        .required('נדרש להזין סיסמה '),
    passwordConfirmation: Yup.string()
        .test('passwords-match', 'סיסמאות אינן תואמות', function (value) {
            return this.parent.password === value
        })
});

export const validationLogin = Yup.object().shape({
    email: Yup.string()
        .email("נדרש למלא דוא״ל תקין")
        .required('נדרש למלא דוא״ל'),
    password: Yup.string()
        .required('נדרש להזין סיסמה '),
});

export const validationSignupDetails = Yup.object().shape({
    firstName: Yup.string()
        .required('נדרש להזין שם פרטי '),
    lastName: Yup.string()
        .required('נדרש להזין שם משפחה '),
    accountType: Yup.string()
        .required('נדרש להזין סוג חשבון '),
    phoneNumber: Yup.string().required('נדרש להזין את מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר פלאפון תקין"
        ),
    isTerms: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "The terms and conditions must be accepted.")
});

export const validationSignupUnderCustomer = Yup.object().shape({
    phoneNumber: Yup.string().required('נדרש להזין את מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר פלאפון תקין"
        ),
    isTerms: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "The terms and conditions must be accepted."),
    password: Yup.string()
        .min(8, 'קצר מדי')
        .required('נדרש להזין סיסמה '),
    passwordConfirmation: Yup.string()
        .test('passwords-match', 'סיסמאות אינן תואמות', function (value) {
            return this.parent.password === value
        })
});

export const validationCreateUser = Yup.object().shape({
    firstName: Yup.string()
        .required('נדרש להזין שם פרטי '),
    lastName: Yup.string()
        .required('נדרש להזין שם משפחה '),
    email: Yup.string()
        .email("נדרש למלא דוא״ל תקין")
        .required('נדרש למלא דוא״ל'),
});

export const validationOfficeDetails = Yup.object().shape({
    officeOrCompanyName: Yup.string()
        .required('נדרש להזין שם משרד או חברה '),
    officeBookkeepingEmail: Yup.string()
        .email("נדרש למלא דוא״ל תקין")
        .required('נדרש למלא דוא״ל'),
});

export const validationResetPassword = Yup.object().shape({
    email: Yup.string()
        .email("נדרש למלא דוא״ל תקין")
        .required('נדרש למלא דוא״ל'),
});
