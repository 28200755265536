export function claimantSet(currClaimant) {
    return (dispatch) => {
        dispatch({ type: "SET_CLAIMANT", currClaimant });
    };
}

export function defendantSet(currDefendant) {
    return (dispatch) => {
        dispatch({ type: "SET_DEFENDANT", currDefendant });
    };
}

