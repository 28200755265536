import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import secure from "../../../images/secure.svg"
import { Field, Formik } from 'formik';
import { validationCreateUser } from '../../Login/validations'
// import db, { Logger } from '../../../integrations/firebase'
import { callUserAction } from '../../../integrations/firebase';
import { ReactComponent as Close } from '../../../images/close.svg'
import { TailSpin } from 'react-loader-spinner';
import { setIsLoading } from '../../../store/actions/systemActions'
// const logClient = new Logger(`CreateUser`);
import { createUserWithEmailAndPassword } from "firebase/auth";

export const CreateUser = ({ setIsUserPopupOpen, company }) => {
    const dispatch = useDispatch();
    const [createUserMsg, setCreateUserMsg] = useState('')
    const [touched, setTouched] = useState({});
    const activeUser = useSelector((state) => state.userReducer.user);
    const isLoading = useSelector((state) => state.systemReducer.isLoading)

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        isTerms: false,
    })

    const handleChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
    }

    const handleBlur = (e) => {
        setTouched({ ...touched, [e.target.name]: true })
    }

    const setModalClose = () => {
        setIsUserPopupOpen(false)
    };

    const onSubmit = async (values) => {
        try {
            dispatch(setIsLoading(true))
            const user = {
                companyId: company.companyId || company.uid || company.managerId,
                email: values.email,
                firstName: values.firstName,
                lastName: values.lastName,
                accountType: company?.accountType || activeUser?.accountType || '',
                isAuthNeeded: company?.isAuthNeeded || false,
                isCaseNeeded: company?.isCaseNeeded || false,
                masters: company?.masters || [],
                accountName: company?.accountName || activeUser?.officeOrCompanyName
            }
            const res = await callUserAction({
                ...user,
                type: "signSubUserUp"
            })
            dispatch(setIsLoading(false))
            if (res?.data?.errorInfo.code === 'auth/email-already-exists') setCreateUserMsg('קיים משתמש עם הדוא״ל שהוזן')
            else {
                setCreateUserMsg('המשתמש נוצר בהצלחה!')
                delayFunction(2500).then(() => { setModalClose() });
            }
        }
        catch (err) {
            console.log('err', err)
        }
    }

    const delayFunction = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    return <>
        <div className="pop-up-login create-user">
            <div className="container">
                <div className="header-btns">
                    <Close onClick={setModalClose} />
                </div>
                <div className="content" style={{ background: 'unset' }}>

                    <div className="login-step">
                        <Formik
                            onSubmit={onSubmit}
                            validateOnChange={true}
                            validateOnBlur={true}
                            validateOnMount={true}
                            initialValues={{
                                firstName: values.firstName,
                                lastName: values.lastName,
                                email: values.email
                            }}
                            enableReinitialize={true}
                            validationSchema={validationCreateUser}
                        >
                            {(props) => {
                                return (
                                    <form onSubmit={props.handleSubmit}>
                                        <h2 className="main-title">יצירה והזמנת משתמש חדש</h2>

                                        <div className='field'>
                                            <label>
                                                שם פרטי*
                                            </label>
                                            <Field
                                                className={(touched.firstName || props.touched.firstName) && Boolean(props.errors.firstName) ? 'signup-input error' :
                                                    props.values.firstName.length > 0 ? 'signup-input success' : 'signup-input'}
                                                type='text'
                                                name='firstName'
                                                onChange={handleChange}
                                                value={values.firstName}
                                                onBlur={handleBlur}
                                            />
                                            {/* {((touched.firstName || props.touched.firstName) && Boolean(props.errors.firstName)) && <p>{props.errors.firstName}</p>} */}
                                        </div>
                                        <div className='field'>
                                            <label>
                                                שם משפחה*
                                            </label>
                                            <Field
                                                className={(touched.lastName || props.touched.lastName) && Boolean(props.errors.lastName) ? 'signup-input error' :
                                                    props.values.lastName.length > 0 ? 'signup-input success' : 'signup-input'}
                                                type='text'
                                                name='lastName'
                                                onChange={handleChange}
                                                value={values.lastName}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                        <div className='field'>
                                            <label>
                                                אימייל*
                                            </label>
                                            <Field
                                                type='text'
                                                className={(touched.email || props.touched.email) && Boolean(props.errors.email) ? 'signup-input error' :
                                                    emailRegex.test(props.values.email) ? 'signup-input success' : 'signup-input'}
                                                name='email'
                                                onChange={(e) => { handleChange(e); setCreateUserMsg(''); }}
                                                value={values.email}
                                                onBlur={handleBlur}
                                            />
                                        </div>

                                        <p>{`${createUserMsg || ''}`}</p>

                                        <button
                                            type='submit'
                                            className='main-btn long register center'
                                            onClick={() => setTouched({ email: true, firstName: true, lastName: true })}>
                                            {isLoading ? <TailSpin
                                                heigth="50"
                                                width="50"
                                                color='#ffffff'
                                                ariaLabel='loading'
                                            /> : 'יצירת משתמש'}
                                        </button>
                                    </form>
                                )
                            }}
                        </Formik>
                        <div className="security-note">
                            <div>
                                <img src={secure} alt="secure" />
                                <p>המידע שלכם שמור בבטחה</p>
                            </div>
                            <p>רכישה מאובטחת בתקן PCI DSS</p>
                        </div>
                    </div >
                </div>
            </div>
        </div>
    </>
};


