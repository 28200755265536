import React, { useState, useEffect, useRef } from "react";
import { doc, onSnapshot } from 'firebase/firestore';
import db, { trace, perf } from '../../../integrations/firebase';
import { Link } from 'react-router-dom'
import Box from "@mui/material/Box";
import Logo from "../../../images/logo_navbar.svg";
import menu from "../../../images/menu.svg";
import close from "../../../images/close-icon.svg";
import cart from "../../../images/cart-footer.svg";
import feather from "../../../images/feather.svg";
import bell from "../../../images/bell_icon.svg";
import { useViewport } from '../../../Hooks/useViewport'
import { updateSystemData, setMobileState } from '../../../store/actions/systemActions';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UserMsgs } from '../../UserDashboard/UserMsgs'
import { NotificationsSet, OrdersSet } from '../../../store/actions/userDataActions';
import { onCallNotifications } from '../../../integrations/firebase';
import { updateUser } from '../../../store/actions/userActions'

export default function ButtonAppBar() {
  const t = trace(perf, "ButtonAppBar");
  t.start();
  const dispatch = useDispatch();
  const systemData = useSelector(state => state.systemReducer.systemData);
  const mobile = useSelector((state) => state.mobileReducer.mobileState);
  const orders = useSelector(state => state.orderReducer.Orders)
  const userOrders = useSelector((state) => state.userDataReducer.userOrders);
  const activeUser = useSelector((state) => state.userReducer.user);
  const notifications = useSelector((state) => state.userDataReducer.userNotifications);
  const navigate = useNavigate();
  const { width } = useViewport();
  const [notificationsNumber, setNotificationsNumber] = useState('0')
  const [notificationsOpenDate, setNotificationsOpenDate] = useState(null)
  const breakpoint = 1111;
  const isMobile = width < breakpoint;

  const [shake, setShake] = useState('');
  const [isUserMsgsOpen, setIsUserMsgsOpen] = useState(false);
  const userMsgsRef = useRef();
  const bellRef = useRef();

  useEffect(() => {
    const onBodyClick = (event) => {
      if (userMsgsRef?.current?.contains(event.target) || bellRef?.current?.contains(event.target)) {
        return;
      }
      setIsUserMsgsOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);

  useEffect(() => {
    if (notifications) {
      if (activeUser && !activeUser?.isAnonymous && activeUser?.fullName?.length > 0) {
        const notificationUnOpen = notifications && notifications.filter(notification => !notification.userInteractions?.[activeUser.uid]?.isOpened)
        if (notificationUnOpen && notificationUnOpen.length > 0) {
          if (notificationUnOpen && notificationUnOpen[0]?.date < notificationsOpenDate) {
            setNotificationsNumber('0')
          }
          else {
            setNotificationsNumber(notificationUnOpen.length + '')
            if (notificationsNumber < notificationUnOpen.length) {
              setShake('shake')
              setTimeout(() => {
                setShake('')
              }, 2000)
            }
          }
        } else {
          setNotificationsNumber('0')
        }
      }

    }
  }, [notifications]);

  const onOpenModal = (value) => {
    dispatch(updateSystemData({
      ...systemData,
      modalToolbar: value,
      isMenuOpen: false
    }));
    if (width < breakpoint) {
      dispatch(setMobileState({
        ...mobile,
        isModalToolbarOpen: true,
      }))
    }
  }

  const onToggleMenu = (e) => {
    dispatch(setMobileState({
      ...mobile,
      isMenuOpen: (Date.now() - mobile.time) > 150
    }))
  }
  const onOpenNotifications = async () => {
    setIsUserMsgsOpen(prevState => !prevState);
    let isChanged = false
    setNotificationsNumber(0)
    setNotificationsOpenDate(Date.now())

    notifications.forEach(notification => {
      if (!notification?.userInteractions?.[activeUser.uid]?.isOpened) isChanged = true
    });

    if (!isChanged) return

    // const updatedAllNotifications = notifications.map((notification) => {
    //   return {
    //     ...notification,
    //     userInteractions: {
    //       ...(notification?.userInteractions ?? {}), // Create a new object if userInteractions doesn't exist
    //       [activeUser.uid]: {
    //         ...(notification?.userInteractions?.[activeUser.uid] ?? {}), // Create a new object if the specific userId doesn't exist
    //         isOpened: true,
    //       },
    //     }
    //   }
    // })

    // const updatedOrders = userOrders.map((order) => {
    //   console.log('order', order)
    //   const updatedNotifications = order.notifications.map((notification) => {
    //     return {
    //       ...notification,
    //       userInteractions: {
    //         ...(notification?.userInteractions ?? {}),
    //         [activeUser.uid]: {
    //           ...(notification?.userInteractions?.[activeUser.uid] ?? {}),
    //           isOpened: true,
    //         },
    //       },
    //     };
    //   });

    //   return {
    //     ...order,
    //     notifications: updatedNotifications,
    //   };
    // });

    // const updatedNotifications = activeUser.notifications.map((notification) => {
    //   return {
    //     ...notification,
    //     userInteractions: {
    //       ...(notification?.userInteractions ?? {}),
    //       [activeUser.uid]: {
    //         ...(notification?.userInteractions?.[activeUser.uid] ?? {}),
    //         isOpened: true,
    //       },
    //     },
    //   };
    // });

    // const updatedUser = {
    //   ...activeUser,
    //   notifications: updatedNotifications,
    // };
    // console.log('updatedUser', updatedUser)
    // console.log('updatedOrders NEW', updatedOrders)
    // console.log('updatedAllNotifications onOpenNotifications', updatedAllNotifications)

    // dispatch(updateUser({ ...updatedUser }));
    // dispatch(NotificationsSet(updatedAllNotifications));
    // dispatch(OrdersSet(updatedOrders));

    const objToSend = {
      userId: activeUser.uid,
      companyId: activeUser.companyId || activeUser.uid,
      type: 'updateNotificationsOpen'
    }
    await onCallNotifications(objToSend)
  }

  const options = [
    { name: 'פרטים אישיים', route: '/user/details' },
    { name: 'הכתובות שלי', route: '/user/addresses' },
  ]


  const returnValue = (
    <header className="header-container main-layout">
      <div className="full main-layout">
        <div className="inner-header-container">
          <div className="header-right-side">


            <div className="right">
              <div className="menu-icon" onClick={(e) => onToggleMenu(e)}>
                {mobile.isMenuOpen && <img src={close} alt="close" />}
                {!mobile.isMenuOpen && <img src={menu} alt="menu" />}
              </div>
              {/* {activeUser && !activeUser?.isAnonymous && activeUser?.fullName?.length > 0 && <div className="icon-wrapper" onClick={() => {
                navigate(width > breakpoint ? '/user/orders' : '/user/msgs');
              }} data-number={notificationsNumber}>
                <img src={bell} alt="" className={`bell-icon ${shake}`} />
              </div>} */}
              {activeUser && !activeUser?.isAnonymous && activeUser?.fullName?.length > 0 &&
                <div className={`icon-wrapper ${notificationsNumber > 99 ? 'big' : ''}`} onClick={() => {
                  if (isMobile) navigate('/user/msgs')
                  setShake('shake');
                  setTimeout(() => {
                    setShake('')
                  }, 2000);
                }}
                  data-number={notificationsNumber}
                >
                  <div ref={bellRef} className='bell-icon-container' onClick={() => { onOpenNotifications(); }}>
                    <img src={bell} alt="" className={`bell-icon ${shake}`} />
                  </div>
                  <div ref={userMsgsRef} className={`UserMsgs-container ${isUserMsgsOpen ? 'open' : ''}`}>
                    <UserMsgs actionNotifications={notifications} isNotificationsList={true} isOpen={isUserMsgsOpen} isFromDash={true} setIsUserMsgsOpen={setIsUserMsgsOpen} />
                  </div>
                </div>
              }

              {(width < breakpoint) &&
                <div className="cart-icon" onClick={() => onOpenModal('cart')}>
                  <img src={cart} alt="cart icon" />
                  <span className="badge">{orders?.length || 0}</span>
                </div>
              }

              {!activeUser?.fullName && <Link className="login-icon" to={systemData.isCheckout ? '/checkout/LoginSignup' : '/LoginSignup'} >
                <p>
                  הרשמה / התחברות
                </p>
              </Link >}
              {activeUser && !activeUser?.isAnonymous && activeUser?.fullName?.length > 0 && <Link className="login-icon" to={(width > breakpoint) ? '/user/orders' : '/user'} >
                <p>שלום , {activeUser?.lawyerNumber ? 'עו״ד ' + activeUser.fullName : activeUser.fullName}</p>
              </Link>}
            </div>
          </div>


          {(width < breakpoint) &&
            <img src={feather} alt="feather icon" onClick={() => navigate('/')} />

          }

          {(width > breakpoint) && <Box
            onClick={() => navigate('/')}
            className="logo-icon"
            component="img"
            sx={{ width: "11em" }}
            alt="deLAWvery"
            src={Logo}
          />}
        </div>
      </div>
    </header >
  );
  t.stop()
  return returnValue
}
