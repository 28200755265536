import React, { useState } from 'react';
import { SignOptions } from './SignOptions';
import { useViewport } from '../../../../Hooks/useViewport'

export const TextToggle = ({ data, setData, formParam, priceText, type, isError, errors }) => {
  const [alignment, setAlignment] = useState(data[formParam] ? 'right' : 'left');

  const { width } = useViewport();
  const breakpoint = 767;
  const isMobile = width < breakpoint;

  let optionA = '';
  let optionB = '';
  switch (type) {
    case 'landSupervisor':
      optionA = 'הפקדה';
      optionB = 'נתקבל';
      break;
    default:
      optionA = 'כן';
      optionB = 'לא';
      break;
  }

  const handleChangeData = (event, newAlignment) => {
    event.preventDefault();
    setAlignment(newAlignment);
    const field = event.target.name;
    const value = event.target.value;
    if (field === 'isSign') {
      setData({ ...data, [field]: value === optionA ? true : false, signType: null });
    } else {
      setData({ ...data, [field]: value === optionA ? true : false });
    }
  };

  const getTitleByFormParam = () => {
    switch (formParam) {
      case 'isDoubleTransfer':
        return 'שליחות כפולה - הלוך וחזור?'
      case 'isSign':
        return 'האם להחתים את הנמען?'
      case 'isDeposit':
        return 'האם הפקדה או נתקבל?'

      default:
        return ''
    }
  }
  const getBorder = () => {
    switch (formParam) {
      case 'isSign':
        if (alignment === 'right') return true
        else return false
      default:
        return false
    }
  }

  return (
    <div className={`document-ready-smaller text-toggle update-cmps ${formParam}`}>

      <h3 className='small'>{getTitleByFormParam()}</h3>

      <div className={`block-select ${!isMobile && getBorder() ? 'border-left' : ''}`}>
        <div className={`form-main-toggle ${alignment}`}>
          <span className={`${alignment === 'left' ? 'gray' : ''}`}></span>
          <button
            name={formParam}
            value={optionA}
            onClick={(ev) => handleChangeData(ev, 'right')}
          >
            {optionA}
          </button>
          <button
            name={formParam}
            value={optionB}
            onClick={(ev) => handleChangeData(ev, 'left')}
          >
            {optionB}
          </button>
        </div>
        {alignment === 'right' && priceText &&
          <p>{priceText}</p>
        }
      </div>

      {formParam === 'isSign' && data.isSign &&
        <SignOptions data={data} setData={setData} isError={isError} errors={errors} />
      }

    </div>
  );
};

export default TextToggle;
