import React, { useEffect, useState, useRef } from 'react';
import { CourtName } from '../CourtName';
import { FirstSubmissionOrCopyInvolves } from '../FirstSubmissionOrCopyInvolves';
import { UrgencyExecution } from '../UrgencyExecution';


export const CourtNameAndAction = ({ data, setData, isError, errors, handleChanges, setTouch, touch, type }) => {

  // useEffect(() => {
  //   setFormState({
  //     ...formState, currRecipient: {
  //       ...formState.currRecipient,
  //       type: formState.currRecipient?.type || 'ADAM_PRATI',
  //       urgency: formState.currRecipient?.urgency || 'REG'
  //     }
  //   });
  //   setData({ ...data, isUpdatedDelivery: true });
  // }, []);

  useEffect(() => {
    setData({ ...data, isUpdatedDelivery: true });
  }, []);

  return (
    <div className='modal-content-container court-name-and-action'>
      <CourtName data={data} setData={setData} isError={isError} errors={errors} handleChanges={handleChanges} setTouch={setTouch} touch={touch} />
      <div className='cmp-line' />
      <FirstSubmissionOrCopyInvolves data={data} setData={setData} />
      <div className='cmp-line' />
      <UrgencyExecution data={data} setData={setData} type={type} />
    </div>
  );
};

export default CourtNameAndAction;
