import * as Yup from 'yup';

export const validationPersonalDetailsPage = Yup.object().shape({
    firstName: Yup.string()
        .required('נדרש להזין שם פרטי '),
    lastName: Yup.string()
        .required('נדרש להזין שם משפחה '),
    phoneNumber: Yup.string().required('נדרש להזין את מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר פלאפון תקין"
        ),
});

export const validationAddressPage = Yup.object().shape({
    streetNumber: Yup.string().required('נדרש למלא שדה מספר'),
    streetAddress: Yup.string().required('נדרש לבחור כתובת מן הרשימה'),
    // addressName: Yup.string().required('נדרש לבחור כתובת מן הרשימה'),
    city: Yup.string().required('נדרש למלא שדה עיר'),
    apartmentNumber: Yup.string(),
    floor: Yup.string(),
    comments: Yup.string(),
});
