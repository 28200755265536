import React, { useEffect, useState, useRef } from 'react';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { he } from 'date-fns/locale';
import { useViewport } from '../../../Hooks/useViewport'
import { TooltipTriangle } from '../../Utils/TooltipTriangle'
registerLocale('he', he)

export const DocumentsReadyUpdate = ({ data, setData, type }) => {
  const now = new Date();
  now.setDate(now.getDate() + 1);
  const today = new Date();
  let tomorrow = new Date();
  let addDays = 0
  if (today.getDay() === 4) addDays = 2
  else if (today.getDay() === 5) addDays = 1
  tomorrow.setDate(today.getDate() + 1 + addDays);
  const { width } = useViewport();
  const breakpoint = 767;
  const isMobile = width < breakpoint;

  const [alignment, setAlignment] = useState(data.isDocumentsReady === false ? 'left' : 'right');
  const [futureDate, setFutureDate] = useState(tomorrow.getTime());

  useEffect(() => {
    const { date, isDocumentsReady } = data;
    if (!isDocumentsReady && isDocumentsReady !== false) {
      setData({ ...data, isDocumentsReady: true });
    }
    if (date) {
      setFutureDate(date);
    }
    setAlignment(data.isDocumentsReady === false ? 'left' : 'right')
  }, []);

  useEffect(() => {
    const { isDocumentsReady } = data
    if (isDocumentsReady === false) {
      document.getElementById('date-pick').focus();
    }
  }, [data.isDocumentsReady]);

  const handleChangeData = (event, newAlignment) => {
    event.preventDefault();
    setAlignment(newAlignment);
    const field = event.target.name;
    const value = event.target.value;
    if (value === 'כן') {
      delete data.date;
      setData({ ...data });
    } else {
      setData({ ...data, date: futureDate });
    }
    setData({ ...data, [field]: value === 'כן' ? true : false });
  };

  const handleChangeDate = (date) => {
    now.setHours(0, 0, 0, 0);

    setData({
      ...data,
      date: !(date < now) ? date.getTime() : tomorrow,
    });
    setFutureDate(!(date < now) ? date.getTime() : tomorrow);
  };

  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 5 || day === 6;
  };

  const optionA = 'כן';
  const optionB = 'לא';

  return (
    <div className=' document-ready-smaller update-cmps'>
      <TooltipTriangle text={'בבחירת ״לא״ ניתן לבחור תאריך איסוף עתידי'}>
        {type === 'landRegistration' && <>
          <h3 className='small'>האם התיקים מוכנים לאיסוף מיידי?</h3>
        </>}
        {!(type === 'landRegistration') && <>
          {/* <h3 className='small'>המסמכים מוכנים לאיסוף או שתרצו לתאם איסוף למועד עתידי?</h3> */}
          <h3 className='small'>האם המסמכים מוכנים לאיסוף מיידי?</h3>
        </>}

        <div className={`block-select ${alignment === 'left' && !isMobile ? 'border-left' : ''}`}>
          <div className={`form-main-toggle ${alignment}`}>
            <span className={`${alignment === 'left' ? 'gray' : ''}`}></span>
            <button
              name='isDocumentsReady'
              value={optionA}
              onClick={(ev) => handleChangeData(ev, 'right')}
            >
              {optionA}
            </button>
            <button
              name='isDocumentsReady'
              value={optionB}
              onClick={(ev) => handleChangeData(ev, 'left')}
            >
              {optionB}
            </button>
          </div>
        </div>
      </TooltipTriangle>
      {alignment === 'left' && (
        <div className="input-date" >
          {!isMobile &&
            <h3>בחרו תאריך איסוף עתידי</h3>
          }
          <div id="date-pick" tabIndex="0">
            <DatePicker
              locale="he"
              selected={new Date(futureDate)}
              onChange={(date) => handleChangeDate(date)}
              dateFormat="dd/MM/yyyy"
              fixedHeight
              filterDate={(date) => !isWeekend(date)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DocumentsReadyUpdate;
