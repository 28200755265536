import React, { Component } from 'react'
import SignaturePad from 'react-signature-canvas'


export class Canvas extends Component {

    sigPad = {}
    clear = () => {
        this.sigPad.clear()
    }

    trim = () => {
        if (this.props.type === 'smallClaim') {
            this.props.data.claimDescription = {
                ...this.props.data.claimDescription, signature: this.sigPad.getTrimmedCanvas()
                    .toDataURL('image/png')
            }
        } else if (this.props.type === 'SmallClaimContractA') {
            this.props.data.claimContract = {
                ...this.props.data.claimContract, signatureA: this.sigPad.getTrimmedCanvas()
                    .toDataURL('image/png')
            }
        } else if (this.props.type === 'SmallClaimContractB') {
            this.props.data.claimContract = {
                ...this.props.data.claimContract, signatureB: this.sigPad.getTrimmedCanvas()
                    .toDataURL('image/png')
            }
        } else {
            this.props.data.collectFiles[this.props.data.currSubmiterIdx] = {
                ...this.props.data.collectFiles[this.props.data.currSubmiterIdx], signature: this.sigPad.getTrimmedCanvas()
                    .toDataURL('image/png'), powerOfAttorney: false, toApprove: true
            }
        }
        this.props.setData({ ...this.props.data })
        // this.props.setTrimmedDataURL(this.sigPad.getTrimmedCanvas()
        //     .toDataURL('image/png'))
        this.props.handleClose()
        if (this.props.onApprove) {
            setTimeout(() => {
                this.props.onApprove();
            }, 100); // Delay of 1000 milliseconds (1 second)
        }
    }


    render() {
        return <div className="container">
            <div className="sigContainer">
                <SignaturePad canvasProps={{ className: "sigPad" }}
                    ref={(ref) => { this.sigPad = ref }} />
            </div>
            <div className="btn-container-modal">
                <button type="button" className="btn-sign" onClick={this.clear}>
                    נקה
                </button>
                <button type="button" className="btn-sign" onClick={this.trim}>
                    אישור
                </button>
            </div>
        </div>
    }
}