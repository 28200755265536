import { Box, styled } from "@mui/system";

const HomeItem = styled(Box)(({ theme }) => ({
  display: "flex",
  // position:'relative',
  // overflow:'hidden',
  border: "1px solid #E6E9EF",
  borderRadius: "1.3em",
  height: "100%",
  color: "#373c43",
  alignItems: "center",
  padding: ".85em",
  cursor:'pointer',
}));

export { HomeItem };
