export function ProgressBar({ phase, progressBarPhases }) {
  const renderedProgress = (progressBarPhases) ? progressBarPhases.map((phaseName, index) => {
    return (
      <div className='step' key={index}>
        <div>
          <div
            className={`step-circle ${phase >= index + 1 ? 'complete' : ''}`}
          ></div>
          <div
            className={`line ${progressBarPhases.length + 1} ${phase >= index + 1 ? 'complete' : ''}`}
          ></div>
        </div>
        <p>{phaseName}</p>
      </div>
    );
  }) : '';

  return <div className='progress-bar'>{renderedProgress}</div>;
}
