import React, { useState, useEffect, useRef } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Field } from 'formik'
import arrow from '../../../images/dropdown-arrow.svg';
import cities2 from "../../../consts/cities";

export const CourtName = ({ data, setData, isError, errors, handleChanges, setTouch, touch }) => {
  const [courtAddress, setCourtAddress] = useState(data?.courtAddressDetails ? `${data.courtAddressDetails.street} ${data.courtAddressDetails.streetNumber} , ${data.courtAddressDetails.city} , ${data.courtAddressDetails.country} (${data.courtAddressDetails.courtName})` : '')
  const [isCourtAddressNotFound, setIsCourtAddressNotFound] = useState(data.isCourtAddressNotFound ? true : false);
  const [address, setAddress] = useState({
    courtName: data?.courtAddressDetails?.courtName || '',
    city: data?.courtAddressDetails?.city || '',
    street: data?.courtAddressDetails?.street || '',
    streetNumber: data?.courtAddressDetails?.streetNumber || '',
    isAddressNotFound: data?.courtAddressDetails?.isAddressNotFound || false
  });

  const [searchCity, setSearchCity] = useState(data?.courtAddressDetails?.city || '');
  const [searchStreet, setSearchStreet] = useState(data?.courtAddressDetails?.street || '');
  const [isCitiesDropdownOpen, setIsCitiesDropdownOpen] = useState(false);
  const [isStreetsDropdownOpen, setIsStreetsDropdownOpen] = useState(false);
  const [israelCities, setIsraelCities] = useState([])
  const [cityStreets, setCityStreets] = useState([])
  const refCity = useRef()
  const refStreet = useRef()

  useEffect(() => {
    setData({
      ...data,
      courtAddressDetails: {
        street: address.street,
        streetNumber: address.streetNumber,
        city: address.city,
        country: 'ישראל',
        courtName: address.courtName,
        isAddressNotFound: address.isAddressNotFound
      }
    });
  }, [address]);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (refCity?.current?.contains(event.target) || refStreet?.current?.contains(event.target)) {
        return;
      }
      setIsCitiesDropdownOpen(false);
      setIsStreetsDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);

  // useEffect(async () => {
  //   try {
  //     const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`)
  //     const israelCities = await cityRes.json()
  //     console.log('israelCities', israelCities.result.records.slice(1))
  //     setIsraelCities(israelCities.result.records.slice(1))
  //     if (address?.city) {
  //       const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${address?.city}`)
  //       const StreetsOfCity = await streetRes.json()
  //       setCityStreets(StreetsOfCity?.result?.records || [])
  //     }
  //   } catch (err) {
  //     console.log('Fetch error: ', err)
  //   }
  // }, []);

  useEffect(() => {
    if (isCitiesDropdownOpen) {
      getCities()
    } else {
      setIsraelCities([])
    }
    if (isStreetsDropdownOpen && data?.courtAddressDetails?.city) {
      getStreetsOfCity(data?.courtAddressDetails?.city)
    } else {
      setCityStreets([])
    }
  }, [isCitiesDropdownOpen, isStreetsDropdownOpen]);

  const onBlur = () => {
    if (address?.city !== searchCity) {
      setSearchCity(address?.city ? address?.city : '')
    }
    if (address?.street !== searchStreet) {
      setSearchStreet(address?.street ? address?.street : '')
    }
    setIsCitiesDropdownOpen(false)
    setIsStreetsDropdownOpen(false)
  }

  const getCities = async () => {
    try {
      const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`, { signal: AbortSignal.timeout(5000) })
      const cities = await cityRes.json()
      const citiesAfterSwitch = switchBrackets(cities.result.records)
      setIsraelCities(citiesAfterSwitch)
    } catch (err) {
      const cities2 = require("../../../consts/cities")
      const citiesAfterSwitch2 = switchBrackets(cities2.result.records)
      setIsraelCities(citiesAfterSwitch2)
      console.log('Fetch error: ', err)
    }
  }

  const switchBrackets = (cities) => {
    const switchBracketsMap = {
      '(': ")",
      ')': "(",
    };

    return cities.map(city => {
      return {
        'שם_ישוב': city['שם_ישוב'].trim().replace(/\(|\)/gi, function (matched) {
          return switchBracketsMap[matched];
        })
      }
    })
  }

  const getStreetsOfCity = async (cityName) => {
    try {
      const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${cityName}`, { signal: AbortSignal.timeout(5000) })
      const StreetsOfCity = await streetRes.json()
      setCityStreets(StreetsOfCity.result.records)
    } catch (err) {
      const streetRes2 = require("../../../consts/streets")
      setCityStreets(streetRes2.result.records.filter((rec) => rec['שם_ישוב'] == cityName))
      console.log('Fetch error: ', err)
    }
  }

  const onSetChoose = (field, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setTouch({ ...touch, [field]: true });
    // setData({
    //   ...data,
    //   collectionAddress: {
    //     ...data.collectionAddress,
    //     ...address,
    //     [field]: value,
    //     collectAddressName: data.collectionAddress.collectAddressName
    //   },
    // });
  }

  const undoChanges = () => {
    setSearchStreet('')
    setAddress((prevState) => ({
      ...prevState,
      street: '',
    }));
    // setData({
    //   ...data,
    //   collectionAddress: {
    //     ...data.collectionAddress,
    //     ...address,
    //     collectStreetAddress: '',
    //     collectAddressName: data.collectionAddress.collectAddressName
    //   },
    // });
  }

  const handleIsAddressNotFound = () => {
    setAddress((prevState) => ({
      ...prevState,
      isAddressNotFound: !prevState.isAddressNotFound,
    }))
  }

  const { ref } = usePlacesWidget({
    apiKey: 'AIzaSyA9YsSA7cSoOYpfXismUmaBObFUUaZxTJo',
    onPlaceSelected: (selected) => {
      if (!(selected.address_components)) return
      setTouch({
        ...touch,
        courtAddress: true,
      });
      setData({
        ...data,
        courtAddressDetails: {
          street: selected.address_components[1].long_name,
          streetNumber: selected.address_components[0].long_name,
          city: selected.address_components[2].long_name,
          country: 'ישראל',
          courtName: selected.name,

        }
      });
      setCourtAddress(`${selected.address_components[1].long_name} ${selected.address_components[0].long_name} , ${selected.address_components[2].long_name} , ${'ישראל'} (${selected.name})`)
      ref.current.value = `${selected.formatted_address} (${selected.name})`;
    },
    options: {
      types: ['geocode', 'establishment'],
      componentRestrictions: { country: 'il' },
      fields: ['name', 'formatted_address', 'address_components'],
    },
    language: "iw",
  });

  // const { ref: cityRef } = usePlacesWidget({
  //   apiKey: 'AIzaSyA9YsSA7cSoOYpfXismUmaBObFUUaZxTJo',
  //   onPlaceSelected: (selected) => {
  //     if (!(selected.name)) return
  //     setIsCityFromApi(true)
  //     setAddress((prevState) => ({
  //       ...prevState,
  //       city: selected.name,
  //     }));
  //   },
  //   options: {
  //     types: ['locality'],
  //     componentRestrictions: { country: 'il' },
  //     fields: ['name', 'formatted_address', 'address_components'],
  //   },
  // });

  const handleCheckbox = () => {
    setIsCourtAddressNotFound(!isCourtAddressNotFound)
    setSearchCity(data?.courtAddressDetails?.city || '')
    setSearchStreet(data?.courtAddressDetails?.street || '')
    setCourtAddress(data?.courtAddressDetails ? `${data.courtAddressDetails.street} ${data.courtAddressDetails.streetNumber} , ${data.courtAddressDetails.city} , ${data.courtAddressDetails.country} (${data.courtAddressDetails.courtName})` : '')
    setAddress((prevState) => ({
      ...prevState,
      ...data?.courtAddressDetails,
      isAddressNotFound: prevState.isAddressNotFound
    }))
    setData({
      ...data,
      isCourtAddressNotFound: !isCourtAddressNotFound
    });
  }

  const handleOptionalAddress = (event) => {
    const { value } = event.target;
    const { name } = event.target;
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div className='main-form update-cmps'>
      <div className='modal-content-container block low court-name'>
        <h2 className='large-title'>בחרו את הערכאה השיפוטית</h2>
        {isCourtAddressNotFound ?
          <p className='subtitle'>
            רשמו את שם הערכאה השיפוטית ואת הכתובת שלה ע"פ הפרמטרים הרשומים
          </p>
          :
          <p className='subtitle'>
            הזינו את שם הערכאה והעיר בה היא נמצאת, או את כתובתה
          </p>}
        <div className={`input-label-container ${isCourtAddressNotFound ? 'none' : ''}`}>
          <label className='small'>לדוגמה: בית משפט השלום, ראשון לציון, או, ישראל גלילי 5, ראשון לציון</label>
          <Field name='courtAddress'>
            {({ field }) => (
              <div>
                <input
                  autoComplete="new-password"
                  className={
                    isError('courtAddressDetails')
                      ? 'large courtname error'
                      : data?.courtAddressDetails?.courtName.length > 0
                        ? 'large courtname success'
                        : 'large courtname'
                  }
                  name='courtAddress'
                  placeholder='הזנת פרטי הערכאה השיפוטית'
                  {...field}
                  ref={ref}
                  type="text"
                  value={courtAddress}
                  onChange={(e) => {
                    setData({
                      ...data,
                      courtAddressDetails: { street: '', courtName: '', streetNumber: '', city: '', country: '' }
                    });
                    setCourtAddress(e.target.value);
                    handleChanges(e);

                  }}
                />
                {isError('courtAddressDetails') && <p className="align-right">{errors.courtAddressDetails.country}</p>}
              </div>
            )}
          </Field>
        </div>

        <div className={`optional-search ${isCourtAddressNotFound ? '' : 'none'}`}>
          <div className='input-container address'>

            <div className='field'>
              <label>שם ערכאה*</label>
              <Field
                type="text"
                autoComplete="new-password"
                name='courtName'
                value={address.courtName}
                onChange={handleOptionalAddress}
                className={
                  isError('courtAddressDetails')
                    ? 'med error'
                    : address.courtName?.length > 0
                      ? 'med success'
                      : 'med'
                } />
              {isError('courtAddressDetails') && <p>{errors.courtAddressDetails.courtName}</p>}
            </div>
            {/* 
            <div className='field'>
              <label>עיר*</label>
              <Field name='city'>
                {({ field }) => (
                  <div>
                    <input
                      {...field}
                      autoComplete="new-password"
                      type="text"
                      ref={cityRef}
                      placeholder='הזינו עיר ובחרו מתוך הרשימה'
                      className={
                        isError('courtAddressDetails')
                          ? 'med error'
                          : data.courtAddressDetails?.city.length > 0
                            ? 'med success'
                            : 'med'
                      }
                      name='city'
                      value={address.city}
                      onChange={handleOptionalAddress}
                    />
                    {isError('courtAddressDetails') && (
                      <p>{errors?.courtAddressDetails?.city}</p>
                    )}
                  </div>
                )}
              </Field>
            </div>

            <div className='field'>
              <label>רחוב*</label>
              <Field
                type="text"
                autoComplete="new-password"
                name='street'
                value={address.street}
                onChange={handleOptionalAddress}
                className={
                  isError('courtAddressDetails')
                    ? 'med error'
                    : address.street?.length > 0
                      ? 'med success'
                      : 'med'
                } />
              {isError('courtAddressDetails') && <p>{errors.courtAddressDetails.street}</p>}
            </div> */}

            <div className='field large'>
              <label className='input-label'>יישוב*</label>
              <div
                ref={refCity}
                className={
                  isCitiesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                }
              >
                <div className='dropdown-input'>
                  <input
                    type="text"
                    placeholder='הזינו יישוב ובחרו מתוך הרשימה'
                    onBlur={onBlur}
                    onChange={(event) => {
                      // getCities(event.target.value)
                      setSearchCity(event.target.value);
                      setIsCitiesDropdownOpen(true);
                    }}
                    value={searchCity}
                    onClick={() => { setIsCitiesDropdownOpen(true); }}
                    name='city'
                    autoComplete="off"
                    className={
                      isError('courtAddressDetails')
                        ? 'error'
                        : data.courtAddressDetails?.city.length > 0
                          ? 'success'
                          : ''
                    }
                  />
                  <button
                    type='button'
                    onClick={() => {
                      setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                    }}
                  >
                    <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                  </button>
                </div>

                {isError('courtAddressDetails') && <p className='error-label'>{errors?.courtAddressDetails?.city}</p>}

                <div className='dropdown-list'>
                  {israelCities.filter((option) => option['שם_ישוב']?.includes(searchCity)).map((city, idx) => {
                    return (
                      <div className='dropdown-option' key={idx}>
                        <p
                          onMouseDown={() => {
                            const cityName = city['שם_ישוב'].trim()
                            onSetChoose('city', cityName)
                            setSearchCity(cityName)
                            setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                            // getStreetsOfCity(cityName)
                            if (cityName !== address.city) undoChanges()
                          }}>
                          {city['שם_ישוב']}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {address.isAddressNotFound ?
              <div div className='field extra-height large'>
                <label className='input-label'>רחוב*</label>
                <Field
                  type="text"
                  name='street'
                  autoComplete="new-password"
                  value={searchStreet}
                  onChange={(ev) => {
                    setSearchStreet(ev.target.value);
                    onSetChoose('street', ev.target.value)
                  }}
                  className={
                    isError('courtAddressDetails')
                      ? 'error'
                      : address.street?.length > 0
                        ? 'success'
                        : ''
                  }
                />
                {isError('courtAddressDetails') && <p>{errors.courtAddressDetails.street}</p>}

                <label className="checkbox-container">
                  <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={address.isAddressNotFound} onChange={handleIsAddressNotFound} />
                  <label className='checkbox-content' htmlFor='isAddressNotFound'>
                    לא מצאתי את הרחוב
                  </label>
                  <span className="checkmark"></span>
                </label>
              </div>
              :

              <div className='field extra-height'>
                <label className='input-label'>רחוב*</label>

                <div
                  ref={refStreet}
                  className={
                    isStreetsDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                  }
                >
                  <div className='dropdown-input'>
                    <input
                      type="text"
                      placeholder='הזינו רחוב ובחרו מתוך הרשימה'
                      onBlur={onBlur}
                      onChange={(event) => {
                        setSearchStreet(event.target.value);
                        setIsStreetsDropdownOpen(true);
                      }}
                      value={searchStreet}
                      onClick={() => { setIsStreetsDropdownOpen(true); }}

                      name='street'
                      autoComplete="new-password"
                      className={
                        isError('courtAddressDetails')
                          ? 'error'
                          : address.street?.length > 0
                            ? 'success'
                            : ''
                      }
                    />
                    <button
                      type='button'
                      onClick={() => {
                        setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                      }}
                    >
                      <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                    </button>
                  </div>

                  {isError('courtAddressDetails') && <p>{errors.courtAddressDetails.street}</p>}

                  <div className='dropdown-list'>
                    {cityStreets.filter((option) => option['שם_רחוב']?.includes(searchStreet)).map((street, idx) => {
                      return (
                        <div className='dropdown-option' key={idx}>
                          <p
                            onMouseDown={() => {
                              onSetChoose('street', street['שם_רחוב'])
                              setSearchStreet(street['שם_רחוב'])
                              setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                            }}>
                            {street['שם_רחוב']}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <label className="checkbox-container">
                  <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={address.isAddressNotFound} onChange={handleIsAddressNotFound} />
                  <label className='checkbox-content' htmlFor='isAddressNotFound'>
                    לא מצאתי את הרחוב
                  </label>
                  <span className="checkmark"></span>
                </label>
              </div>
            }



            <div className='field small'>
              <label>מספר*</label>
              <Field
                type="text"
                autoComplete="new-password"
                name='streetNumber'
                value={address.streetNumber}
                onChange={handleOptionalAddress}
                className={
                  isError('courtAddressDetails')
                    ? 'small error'
                    : address.streetNumber?.length > 0
                      ? 'small success'
                      : 'small'
                } />
              {isError('courtAddressDetails') && <p>{errors.courtAddressDetails.streetNumber}</p>}
            </div>
          </div>
        </div>


        <div className='checkbox-d'>
          <label className="checkbox-container">
            <input
              type="checkbox"
              value={isCourtAddressNotFound}
              checked={isCourtAddressNotFound}
              onChange={() => handleCheckbox()}
            />
            <span className="checkmark"></span>
            <p className="checkbox-text">לא מצאתי את המקום</p>
          </label>
        </div>
      </div>
    </div>
  );
};

export default CourtName;
