import React from "react";
import { useEffect } from 'react';
import {
  BrowserRouter
} from 'react-router-dom'
import Main from "./components/Main/Main";
import { theme } from "./Styles/themes/Breakpoints";
import { ThemeProvider } from "@mui/system";
import store from "./store/index";
import { Provider } from "react-redux";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import jschat from '../src/js/chat'
import { Accessibility } from 'accessibility';
import { useViewport } from './Hooks/useViewport'
export default function App() {
  const { width } = useViewport();
  const breakpoint = 1111;
  const isMobile = width < breakpoint;

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)
    appHeight()
  }, [window.innerHeight]);

  useEffect(() => {
    const labels = {
      resetTitle: 'איפוס הגדרות',
      closeTitle: 'סגירת מסך נגישות',
      menuTitle: 'מסך נגישות',
      increaseText: 'הגדלת טקסט',
      decreaseText: 'הקטנת טקסט',
      increaseTextSpacing: 'הגדלת ריווח טקסט',
      decreaseTextSpacing: 'הקטנת ריווח טקסט',
      invertColors: 'היפוך צבעים',
      grayHues: 'צבעי אפור',
      underlineLinks: 'קו תחתי לינקים',
      bigCursor: 'סמן גדול',
      readingGuide: 'מדריך קריאה',
      textToSpeech: 'טקסט לדיבור',
      speechToText: 'הקראת טקסט',
    };

    const icon = {
      position: {
        bottom: { size:  30, units: 'px' },
        right: { size: isMobile ? 5 : 10, units: 'px' },
        type: 'fixed'

      }
    }
    var options = { labels, icon };
    options.textToSpeechLang = 'he'; // or any other language
    options.speechToTextLang = 'he'; // or any other language
    window.addEventListener('load', function () { new Accessibility(options); }, false);
    return () => {
      window.removeEventListener('load', function () { new Accessibility(options); }, false);
    }
  }, [])


  useEffect(() => {
    const script = document.createElement("script");

    script.src = jschat;
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <div >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Main />
          </BrowserRouter >
        </ThemeProvider>
      </Provider>

    </div >
  );
}
