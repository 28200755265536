import * as Yup from 'yup';

export const validationFinalPage = Yup.object().shape({
    contactDeliveryRecipientName: Yup.string().required('חובה להזין שם איש קשר '),
    contactDeliveryRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    prices: Yup.number().moreThan(-1, 'חובה להזין אגרה')
});

export const validationFinalPageWithCaseNeeded = Yup.object().shape({
    contactDeliveryRecipientName: Yup.string().required('חובה להזין שם איש קשר '),
    contactDeliveryRecipientCase: Yup.string().required('חובה להזין תיק לחיוב '),
    contactDeliveryRecipientFactor: Yup.string().required('חובה להזין גורם מאשר '),
    contactDeliveryRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    prices: Yup.number().moreThan(-1, 'חובה להזין אגרה')

});

export const validationFinalPageWithoutPrices = Yup.object().shape({
    contactDeliveryRecipientName: Yup.string().required('חובה להזין שם איש קשר '),
    contactDeliveryRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
});

export const validationFinalPageWithoutPricesWithCaseNeededAndAuthNeeded = Yup.object().shape({
    contactDeliveryRecipientName: Yup.string().required('חובה להזין שם איש קשר '),
    contactDeliveryRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    contactDeliveryRecipientCase: Yup.string().required('חובה להזין תיק לחיוב '),
    contactDeliveryRecipientFactor: Yup.string().required('חובה להזין גורם מאשר '),
});

export const validationFinalPageWithPricesWithCaseNeededAndAuthNeeded = Yup.object().shape({
    contactDeliveryRecipientName: Yup.string().required('חובה להזין שם איש קשר '),
    contactDeliveryRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    contactDeliveryRecipientCase: Yup.string().required('חובה להזין תיק לחיוב '),
    contactDeliveryRecipientFactor: Yup.string().required('חובה להזין גורם מאשר '),
    prices: Yup.number().moreThan(-1, 'חובה להזין אגרה')
});

export const validationFinalPageWithPricesWithAuthNeeded = Yup.object().shape({
    contactDeliveryRecipientName: Yup.string().required('חובה להזין שם איש קשר '),
    contactDeliveryRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    contactDeliveryRecipientFactor: Yup.string().required('חובה להזין גורם מאשר '),
    prices: Yup.number().moreThan(-1, 'חובה להזין אגרה')
});

export const validationFinalPageWithPricesWithCaseNeeded = Yup.object().shape({
    contactDeliveryRecipientName: Yup.string().required('חובה להזין שם איש קשר '),
    contactDeliveryRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    contactDeliveryRecipientCase: Yup.string().required('חובה להזין תיק לחיוב '),
    prices: Yup.number().moreThan(-1, 'חובה להזין אגרה')
});

export const validationFinalPageWithoutPricesWithAuthNeeded = Yup.object().shape({
    contactDeliveryRecipientName: Yup.string().required('חובה להזין שם איש קשר '),
    contactDeliveryRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    contactDeliveryRecipientFactor: Yup.string().required('חובה להזין גורם מאשר '),
});

export const validationFinalPageWithoutPricesWithCaseNeeded = Yup.object().shape({
    contactDeliveryRecipientName: Yup.string().required('חובה להזין שם איש קשר '),
    contactDeliveryRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    contactDeliveryRecipientCase: Yup.string().required('חובה להזין תיק לחיוב '),
});

export const validationRecipients = Yup.object().shape({
    recipients: Yup.array().min(1, 'נדרש למלא פרטי מאותר אחד לפחות'),
});

export const validationCheckoutPage = Yup.object().shape({
    invoiceCompanyName: Yup.string().required('נדרש להזין שם על החשבונית '),
    orderClientName: Yup.string().required('נדרש להזין את שם המזמין'),
    invoiceEmail: Yup.string()
        .required('נדרש למלא דוא״ל')
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "נדרש למלא דוא״ל תקין"),
    isDisclaimerCheck: Yup.boolean()
        .required("The terms and conditions must be accepted.")
        .oneOf([true], "The terms and conditions must be accepted.")
});

export const validationCollectBranchSelecting = Yup.object().shape({
    collectionAddress: Yup.object().shape({
        collectStreetAddress: Yup.string().required()
    })
});

export const validationCourtAddress = Yup.object().shape({
    courtAddressDetails: Yup.object().required('חובה לבחור ערכאה שיפוטית').shape({
        street: Yup.string().required('נדרש לבחור רחוב'),
        courtName: Yup.string().required('חובה למלא שם ערכאה'),
        streetNumber: Yup.string().required('חובה למלא שדה מספר רחוב'),
        city: Yup.string().required('נדרש לבחור יישוב'),
        country: Yup.string().required('חובה לבחור ערכאה שיפוטית'),
    })
});

export const validationReturnBranchSelecting = Yup.object().shape({
    returnAddress: Yup.object().shape({
        returnStreetAddress: Yup.string().required()
    })
});

export const validationFirstSubmission = Yup.object().shape({
    toll: Yup.number()
        .moreThan(-1, 'חובה להזין אגרה')
});

export const validationSchemaCompany = Yup.object().shape({
    companyName: Yup.string().required('נדרש למלא שדה שם בית עסק'),
    companyPhone: Yup.string()
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר בית'),
    city: Yup.string().required('נדרש לבחור יישוב'),
});

export const validationSchemaOffice = Yup.object().shape({
    officeName: Yup.string().required('נדרש למלא שדה שם משרד'),
    officePhone: Yup.string()
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר בית'),
    city: Yup.string().required('נדרש לבחור יישוב'),
});

export const validationSchemaAddressCollectDocuments = Yup.object().shape({
    collectionAddress: Yup.object().shape({
        collectStreetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
        collectStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        collectCity: Yup.string().required('נדרש לבחור יישוב'),
        // collectAddressName: Yup.string().required('נדרש לבחור כתובת מן הרשימה')
    })
});
export const validationSchemaAddressCollectDocumentsWithSize = Yup.object().shape({
    collectionAddress: Yup.object().shape({
        collectStreetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
        collectStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        collectCity: Yup.string().required('נדרש לבחור יישוב'),
        // collectAddressName: Yup.string().required('נדרש לבחור כתובת מן הרשימה')
    }),
    isPackageSizeChoose: Yup.boolean()
        .oneOf([true], "נדרש לבחור סוג וגודל חבילה")
        .required("נדרש לבחור סוג וגודל חבילה")
});

export const validationSchemaAddressCollectDocumentsWithoutNumber = Yup.object().shape({
    collectionAddress: Yup.object().shape({
        collectStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        collectCity: Yup.string().required('נדרש לבחור יישוב'),
    })
});

export const validationSchemaAddressReturnDocuments = Yup.object().shape({
    returnAddress: Yup.object().shape({
        returnStreetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
        returnStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        returnCity: Yup.string().required('נדרש לבחור יישוב'),
        // returnAddressName: Yup.string().required('נדרש לבחור כתובת מן הרשימה')
    })
});

export const validationSchemaAddressReturnDocumentsWithoutNumber = Yup.object().shape({
    returnAddress: Yup.object().shape({
        returnStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        returnCity: Yup.string().required('נדרש לבחור יישוב'),
    })
});

export const validationSchemaPerson = Yup.object().shape({
    personName: Yup.string().required('נדרש למלא שדה שם מלא'),
    personId: Yup.string()
        .matches(/^\d+$/, ' תעודת זהות הינה רק עם מספרים')
        .min(9, 'קצר מדי')
        .max(9, 'ארוך מדי'),
    personPhone: Yup.string()
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    city: Yup.string().required('נדרש לבחור יישוב'),

});

export const validationSchemaClaimant = Yup.object().shape({
    claimantFirstName: Yup.string().required('נדרש למלא שדה שם פרטי'),
    claimantLastName: Yup.string().required('נדרש למלא שדה שם משפחה'),
    claimantId: Yup.string()
        .required('נדרש למלא שדה תעודת זהות')
        .matches(/^\d+$/, ' תעודת זהות הינה רק עם מספרים')
        .min(9, 'קצר מדי')
        .max(9, 'ארוך מדי'),
    claimantPhone: Yup.string()
        .required('נדרש למלא שדה טלפון')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    city: Yup.string().required('נדרש לבחור יישוב'),
    postalCode: Yup.string().required('נדרש למלא מיקוד'),
    claimantEmail: Yup.string()
        .required('נדרש למלא דוא״ל')
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "נדרש למלא דוא״ל תקין"),
});

export const validationSchemaPersonalDefendant = Yup.object().shape({
    defendantFirstName: Yup.string().required('נדרש למלא שדה שם פרטי'),
    defendantLastName: Yup.string().required('נדרש למלא שדה שם משפחה'),
    defendantId: Yup.string()
        .required('נדרש למלא שדה תעודת זהות')
        .matches(/^\d+$/, ' תעודת זהות הינה רק עם מספרים')
        .min(9, 'קצר מדי')
        .max(9, 'ארוך מדי'),
    defendantPhone: Yup.string()
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    city: Yup.string().required('נדרש לבחור יישוב'),

    postalCode: Yup.string().required('נדרש למלא מיקוד'),
    defendantEmail: Yup.string()
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "נדרש למלא דוא״ל תקין"),
});

export const validationSchemaPersonalDefendantWithoutId = Yup.object().shape({
    defendantFirstName: Yup.string().required('נדרש למלא שדה שם פרטי'),
    defendantLastName: Yup.string().required('נדרש למלא שדה שם משפחה'),
    defendantPhone: Yup.string()
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    city: Yup.string().required('נדרש לבחור יישוב'),

    postalCode: Yup.string().required('נדרש למלא מיקוד'),
    defendantEmail: Yup.string()
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "נדרש למלא דוא״ל תקין"),
});

export const validationSchemaPersonalDefendantWithoutAddress = Yup.object().shape({
    defendantFirstName: Yup.string().required('נדרש למלא שדה שם פרטי'),
    defendantLastName: Yup.string().required('נדרש למלא שדה שם משפחה'),
    defendantId: Yup.string()
        .required('נדרש למלא שדה תעודת זהות')
        .matches(/^\d+$/, ' תעודת זהות הינה רק עם מספרים')
        .min(9, 'קצר מדי')
        .max(9, 'ארוך מדי'),
    defendantPhone: Yup.string()
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    defendantEmail: Yup.string()
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "נדרש למלא דוא״ל תקין"),
});
export const validationSchemaPersonalDefendantWithoutAddressAndId = Yup.object().shape({
    defendantFirstName: Yup.string().required('נדרש למלא שדה שם פרטי'),
    defendantLastName: Yup.string().required('נדרש למלא שדה שם משפחה'),
    defendantPhone: Yup.string()
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    defendantEmail: Yup.string()
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "נדרש למלא דוא״ל תקין"),
});

export const validationSchemaCompanyDefendant = Yup.object().shape({
    defendantCompanyName: Yup.string().required('נדרש למלא שדה שם חברה'),
    defendantId: Yup.string()
        .required('נדרש למלא שדה ח.פ.')
        .matches(/^\d+$/, 'ח.פ. הינו רק עם מספרים'),
    // .min(9, 'קצר מדי')
    // .max(9, 'ארוך מדי'),
    defendantPhone: Yup.string()
        // .required('נדרש למלא שדה טלפון')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    city: Yup.string().required('נדרש לבחור יישוב'),

    postalCode: Yup.string().required('נדרש למלא מיקוד'),
    defendantEmail: Yup.string()
        .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "נדרש למלא דוא״ל תקין"),
});

export const validationSmallClaimRecipients = Yup.object().shape({
    claimants: Yup.array().min(1, 'נדרש למלא פרטי תובע/ת אחד/אחת לפחות'),
    defendants: Yup.array().min(1, 'נדרש למלא פרטי נתבע/ת אחד/אחת לפחות'),
});

export const validationSmallClaimDescription = Yup.object().shape({
    courtClaimName: Yup.string().required('נדרש לבחור בית משפט להגשת התביעה'),
    smallClaimLocation: Yup.string().required('נדרש למלא את מקום התביעה'),
    statementSectionA: Yup.string().required('נדרש למלא את השדה החסר'),
    statementSectionB: Yup.string().required('נדרש למלא את השדה החסר'),
    productClaimed: Yup.string().required('נדרש למלא את השדה החסר'),
    moneyClaimed: Yup.number().lessThan(33701, 'ניתן לתבוע עד לסכום של 36,400₪ '),
    statementSectionSource: Yup.string().required('נדרש למלא את המקור'),
    signature: Yup.string().required('נדרש לחתום על הטופס'),
    // toApprove: Yup.boolean()
    //     .test('toApprove', 'נדרש לאשר את המסמך', function (value) {
    //         if (this.parent.claimDescriptionSign) return true
    //         else return false;
    //     }),
    claimFiles: Yup.array().of(
        Yup.object().shape({
            fileDescription: Yup.string().required('נדרש למלא תיאור קובץ'),
            filePath: Yup.string().required('נדרש להעלות קובץ'),
        })),
});

export const validationSmallClaimDescriptionWithoutStatementB = Yup.object().shape({
    courtClaimName: Yup.string().required('נדרש לבחור בית משפט להגשת התביעה'),
    smallClaimLocation: Yup.string().required('נדרש למלא את מקום התביעה'),
    statementSectionA: Yup.string().required('נדרש למלא את השדה החסר'),
    productClaimed: Yup.string().required('נדרש למלא את השדה החסר'),
    moneyClaimed: Yup.number().lessThan(33701, 'ניתן לתבוע עד לסכום של 36,400₪ '),
    signature: Yup.string().required('נדרש לחתום על הטופס'),
    claimFiles: Yup.array().of(
        Yup.object().shape({
            fileDescription: Yup.string().required('נדרש למלא תיאור קובץ'),
            filePath: Yup.string().required('נדרש להעלות קובץ'),
        })),
});

export const validationSmallClaimContract = Yup.object().shape({
    claimContract: Yup.object().shape({
        signatureA: Yup.string().required('נדרש לחתום על הוראות התביעה'),
        signatureB: Yup.string().required('נדרש לחתום על ההצהרה'),
        isSubmittedClaim: Yup.boolean().required('נדרש למחוק את המיותר').nullable(),
    })
});

export const validationSchemaDocumentTransferPerson = Yup.object().shape({
    personName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    personPhone: Yup.string()
        .required('נדרש למלא שדה טלפון נייד')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    city: Yup.string().required('נדרש לבחור יישוב'),
});

export const validationSchemaDocumentTransferOffice = Yup.object().shape({
    officeName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    officePhone: Yup.string()
        .required('נדרש למלא שדה טלפון נייד')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    city: Yup.string().required('נדרש לבחור יישוב'),
});

export const validationPersonalAddress = Yup.object().shape({
    personName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    personPhone: Yup.string()
        .required('נדרש למלא שדה טלפון נייד')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    city: Yup.string().required('נדרש לבחור יישוב'),
});

export const validationLocateAddress = Yup.object().shape({
    personName: Yup.string().required('נדרש למלא שדה שם פרטי '),
    personLastName: Yup.string().required('נדרש למלא שדה שם משפחה '),
    personId: Yup.string()
        .required('נדרש למלא שדה תעודת זהות ')
        .matches(/^\d+$/, ' תעודת זהות הינה רק עם מספרים')
        .min(9, 'קצר מדי')
        .max(9, 'ארוך מדי'),
    personPhone: Yup.string()
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    // streetAddress: Yup.string().required('נדרש למלא שדה כתובת'),
    // gender: Yup.string().required('נדרש למלא שדה מין'),
});

export const validationIdLocateAddress = Yup.object().shape({
    personName: Yup.string().required('נדרש למלא שדה שם פרטי '),
    personLastName: Yup.string().required('נדרש למלא שדה שם משפחה '),
    personPhone: Yup.string()
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
});

export const validationWhatServed = Yup.object().shape({
    docsForSubmission: Yup.array().min(1, 'נא לבחור לפחות מסמך אחד להגשה').of(
        Yup.object().shape({
            documentName: Yup.string().required('נדרש למלא את שם המסמך'),
        })),
});

export const validationApostilleVerification = Yup.object().shape({
    certificates: Yup.array().min(1, 'חובה לבחור תעודה'),
    currCountryApostille: Yup.string().required('חובה לבחור מדינה'),

});

export const validationPass = Yup.object().shape({
    pass: Yup.boolean().required(),
});

export const validationUploadPage = Yup.object().shape({
    pages: Yup.number().moreThan(0, 'חובה להזין מספר עמודים להעלאה')
});

export const validationCheckUploadUpdate = Yup.object().shape({
    files: Yup.array().min(1, 'לא הועלה אף קובץ, כדי להמשיך יש להעלות קובץ.'),
    printingDetails: Yup.object().shape({
        pagesCount: Yup.number().moreThan(0, 'חובה להזין מספר עמודים להעלאה')
    }),
});

export const validationCheckUpload = Yup.object().shape({
    files: Yup.array().min(1, 'לא הועלה אף קובץ, כדי להמשיך יש להעלות קובץ.')
});

export const validationChooseOfficePage = Yup.object().shape({
    branch: Yup.string().required('לא בחרתם לשכה, כדי להמשיך יש לבחור לשכה.')
});

export const validationChooseClaimTypePage = Yup.object().shape({
    claimType: Yup.string().required('לא בחרתם סוג תביעה, כדי להמשיך יש לבחור סוג תביעה.')
});

export const validationCollectFileDetailsPage = Yup.object().shape({
    collectFiles: Yup.array().of(
        Yup.object().shape({
            files: Yup.array().of(
                Yup.object().shape({
                    fileNum: Yup.string().required('נדרש למלא מספר תיק'),
                }))
        }))
});

export const validationActionSummaryPage = Yup.object().shape({
    isPassActionSummary: Yup.boolean()
        .oneOf([true], "חובה true")
        .required("חובה")
});

export const validationPowerOfAttorneyPage = Yup.object().shape({
    collectFiles: Yup.array().of(
        Yup.object().shape({
            powerOfAttorney: Yup.boolean()
                .test('powerOfAttorney', 'נדרש לחתום על יפוי הכוח', function (value) {
                    if (this.parent.isUpload) return true
                    else return value === true;
                })
        }))
});


export const validationPowerOfAttorneyPageUploadFiles = Yup.object().shape({
    files: Yup.array().min(1, 'לא הועלה  קובץ'),
});

export const validationDocumentTransferFromMe = Yup.object().shape({
    collectionAddress: Yup.object().shape({
        collectStreetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
        collectStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        collectCity: Yup.string().required('נדרש לבחור יישוב'),
    }),
    recipientName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    city: Yup.string().required('נדרש לבחור יישוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר בית'),
    recipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    isPackageSizeChoose: Yup.boolean()
        .oneOf([true], "חובה")
        .required("נדרש לבחור סוג וגודל חבילה")
});
export const validationDocumentTransferToMe = Yup.object().shape({
    recipientName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    city: Yup.string().required('נדרש לבחור יישוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר בית'),
    recipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    returnAddress: Yup.object().shape({
        returnStreetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
        returnStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        returnCity: Yup.string().required('נדרש לבחור יישוב'),
    }),
    isPackageSizeChoose: Yup.boolean()
        .oneOf([true], "חובה")
        .required("נדרש לבחור סוג וגודל חבילה")
});
export const validationDocumentTransferBoth = Yup.object().shape({
    recipientName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    city: Yup.string().required('נדרש לבחור יישוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר בית'),
    recipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    returnRecipientName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    returnRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    returnAddress: Yup.object().shape({
        returnStreetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
        returnStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        returnCity: Yup.string().required('נדרש לבחור יישוב'),
    }),
    isPackageSizeChoose: Yup.boolean()
        .oneOf([true], "חובה")
        .required("נדרש לבחור סוג וגודל חבילה")
});
export const validationDocumentTransferFromMeWithSign = Yup.object().shape({
    collectionAddress: Yup.object().shape({
        collectStreetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
        collectStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        collectCity: Yup.string().required('נדרש לבחור יישוב'),
    }),
    recipientName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    city: Yup.string().required('נדרש לבחור יישוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר בית'),
    recipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    isPackageSizeChoose: Yup.boolean()
        .oneOf([true], "חובה")
        .required("נדרש לבחור סוג וגודל חבילה"),
    signType: Yup.string().required('לא נבחרה סוג חתימה')
});
export const validationDocumentTransferToMeWithSign = Yup.object().shape({
    recipientName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    city: Yup.string().required('נדרש לבחור יישוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר בית'),
    recipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    returnAddress: Yup.object().shape({
        returnStreetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
        returnStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        returnCity: Yup.string().required('נדרש לבחור יישוב'),
    }),
    isPackageSizeChoose: Yup.boolean()
        .oneOf([true], "חובה")
        .required("נדרש לבחור סוג וגודל חבילה"),
    signType: Yup.string().required('לא נבחרה סוג חתימה')
});
export const validationDocumentTransferBothWithSign = Yup.object().shape({
    recipientName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    city: Yup.string().required('נדרש לבחור יישוב'),
    streetAddress: Yup.string().required('נדרש לבחור רחוב'),
    streetNumber: Yup.string().required('נדרש למלא שדה מספר בית'),
    recipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    returnRecipientName: Yup.string().required('נדרש למלא שדה שם איש קשר/חברה'),
    returnRecipientPhone: Yup.string().required('חובה להזין מספר פלאפון ')
        .matches(
            /^\d{9,10}$/,
            "נדרש למלא מספר טלפון תקין"
        ),
    returnAddress: Yup.object().shape({
        returnStreetNumber: Yup.string().required('נדרש למלא שדה מספר רחוב'),
        returnStreetAddress: Yup.string().required('נדרש לבחור רחוב'),
        returnCity: Yup.string().required('נדרש לבחור יישוב'),
    }),
    isPackageSizeChoose: Yup.boolean()
        .oneOf([true], "חובה")
        .required("נדרש לבחור סוג וגודל חבילה"),
    signType: Yup.string().required('לא נבחרה סוג חתימה')
});
