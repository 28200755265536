const allNull = obj => Object.values(obj).every(value => value === null);
export function setUTM(utm) {
  if (allNull(utm)) {
    return async dispatch => {
      return null;
    }
  }
  return async dispatch => {
    dispatch({ type: 'SET_UTM', utm })
  }
}



