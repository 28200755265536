// import RecipientsService from "../../services/recipienteService";
import RecipientsService from "../../services/recipientService"
// Action Dispatcher
export function loadRecipients(filterBy) {
  return async (dispatch) => {
    const Recipients = await RecipientsService.query(filterBy);
    dispatch({ type: "SET_RecipientS", Recipients });
  };
}

export function getById(RecipientsId) {
  return async (dispatch) => {
    const Recipient = await RecipientsService.getRecipientById(RecipientsId);
    // dispatch({ type: "GET_Recipient", Recipient });
    return Recipient
  };
}

export function removeRecipient(RecipientsId) {
  return async (dispatch) => {
    try {
      await RecipientsService.deleteRecipient(RecipientsId);
      dispatch({ type: "REMOVE_Recipient", RecipientId: RecipientsId });
    } catch (err) {
      console.log("ERROR!");
    }
  };
}

export function addRecipient(Recipients) {
  return async (dispatch) => {
    try {
      const savedRecipient = await RecipientsService.saveRecipient(Recipients);

      dispatch({ type: "ADD_Recipient", Recipients: savedRecipient });
      return savedRecipient
      // addToSummary(newRecipient)
    } catch (err) {
      console.log("ERROR!");
    }
  };
}


export function editRecipient(updatedRecipient) {
  return async (dispatch) => {
    try {
      dispatch({ type: "EDIT_RECIPIENT", updatedRecipient });
    } catch (err) {
      console.log("ERROR!");
    }
  };
}


export function setNewRecipient(recipiente) {
  return (dispatch) => {
    dispatch({ type: "SET_NEW_ORDER", recipiente });
  };
}

export function updateNowRecipient(recipiente) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_NEW_ORDER", recipiente });
  };
}


