import React from 'react'
import { DocumentsReadyUpdate } from './DocumentsReadyUpdate';
import { AddressCollectDocumentsUpdate } from './AddressCollectDocumentsUpdate';

export const CollectDocumentsAndDocumentsReady = ({ setData, data, type, isError, errors, handleChanges, setTouch, touch, submitForm, setSubmitForm, isTranslation, pageName }) => {

  return (
    <div className='main-form collect-or-upload'>
      <div className='modal-content-container block '>
        <h2 className='large-title'>בחירת כתובת לאיסוף המסמכים</h2>
        <p className='subtitle'>
        </p>

        <div className='cmp-line' />
        <DocumentsReadyUpdate data={data} setData={setData} type={type} />
        <div className='cmp-line' />
        <AddressCollectDocumentsUpdate data={data} setData={setData} isError={isError} errors={errors} handleChanges={handleChanges} type={type} setTouch={setTouch} touch={touch} />
      </div>
    </div >
  );
};

export default CollectDocumentsAndDocumentsReady;
