import React, { useState, useEffect, useRef } from 'react'
import arrow from '../../../../images/dropdown-arrow.svg';

export const SignOptions = ({ data, setData, isError, errors }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(true);
  const [signType, setSignOptionType] = useState(data?.signType || null);
  const ref = useRef();

  const options = [
    { signOptionType: 'digitalSign', signTypePreview: 'החתמה דיגיטלית - החזרת הוכחת הביצוע במייל' },
    { signOptionType: 'physicalSign', signTypePreview: 'החתמה פיזית - החזרת הוכחת הביצוע במייל' },
    { signOptionType: 'physicalSignWithReturn', signTypePreview: 'החתמה פיזית - החזרת הוכחת הביצוע עם שליח' }
  ];

  useEffect(() => {
    if (signType) {
      setData({
        ...data,
        signType: signType
      });
    }
  }, [signType]);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (ref.current.contains(event.target)) {
        return;
      }
      setDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener("click", onBodyClick, {
        capture: true,
      });
    };
  }, []);

  return (
    <>
      <div className="dropdown-icon-align sign-options-toggle" >
        <div
          ref={ref}
          className={
            isDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
          }
        >
          <div className='dropdown-input'>
            <input
              type="text"
              className={
                isError('signType')
                  ? 'error'
                  : data?.signType
                    ? 'success'
                    : ''
              }
              value={signType?.signTypePreview || 'בחרו מתוך הרשימה'}
              onClick={() => { setDropdownOpen(true); }}
            />
            <button
              type='button'
              onClick={() => {
                setDropdownOpen(!isDropdownOpen);
              }}
            >
              <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
            </button>
          </div>

          <div className='dropdown-list'>
            {options.map((signOption, idx) => {
              return (
                <div className='dropdown-option' key={idx}>
                  <p
                    onClick={() => {
                      setSignOptionType(signOption);
                      setDropdownOpen(!isDropdownOpen);
                    }}>
                    {signOption.signTypePreview}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {isError('signType') && <p className='error-label'>{errors.signType}</p>}
    </>


  );
}

