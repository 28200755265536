import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { updateErrorModal } from '../../../store/actions/systemActions';
import { TooltipExtra } from '../../Utils/TooltipExtra'
import info from '../../../images/info.svg'
import infoWhite from '../../../images/infoWhite.svg'
import { useViewport } from '../../../Hooks/useViewport'

export function ReturnBranchSelecting({ data, setData, errors, submitForm, setSubmitForm, type }) {
    const dispatch = useDispatch();

    const { width } = useViewport();
    const breakpoint = 767;
    const isMobile = width < breakpoint;

    const options = [
        { returnStreetAddress: 'שדרות הראשונים, מגדל מילניה, קומה 17', returnCity: 'ראשון לציון', mailFolder: null, tooltipType: 'branchRishon' },
    ]

    useEffect(() => {
        if (!data.returnAddress) {
            setData({
                ...data,
                returnAddress: options[0]
            });
        }
    }, []);

    const handleBranchSelected = (branch) => {
        setData({ ...data, returnAddress: branch });
    }

    useEffect(() => {
        const { returnAddress, collectionMethod } = data;
        if (collectionMethod === 'branch' && !returnAddress) {
            const returnAddressFromCollectAddress = {
                returnStreetAddress: data.collectionAddress.collectStreetAddress,
                returnCity: data.collectionAddress.collectCity,
                mailFolder: data.collectionAddress.mailFolder,
            }
            handleBranchSelected(returnAddressFromCollectAddress)
        }
    }, [data.collectionMethod])

    useEffect(() => {
        if (submitForm && errors.returnAddress) {
            setSubmitForm(false);
            dispatch(
                updateErrorModal({
                    title: 'שגיאה',
                    errorText: 'חובה לבחור סניף',
                    submitText: 'אישור',
                    isCancel: false
                })
            );
        }
    }, [submitForm, errors]);

    const getTitleByTypeForm = () => {
        switch (type) {
            case 'apostilSubmission':
                return 'מאיזה סניף תעדיפו לאסוף את התעודות?';
            case 'apostilInCourt':
                return 'מאיזה סניף תעדיפו לאסוף את המסמכים הנוטריונים?'
            default:
                return 'מאיזה סניף תעדיפו לאסוף את התעודות?';
        }
    }

    return (
        <div className='main-form block branch-selecting update-cmps'>
            <div className='modal-content-container block'>
                <h2 className='med-title'>{getTitleByTypeForm()}</h2>
                <p className='subtitle'>
                    ניתן לשנות בעתיד במידת הצורך
                </p>

                <div className="branches">
                    {options.map((branch) => {
                        const isChosen = data?.returnAddress && data?.returnAddress?.returnStreetAddress === branch?.returnStreetAddress
                        return (<div key={branch.returnCity} className={`branch ${isChosen ? 'blue' : 'white'}`} onClick={() => handleBranchSelected(branch)}>
                            <input
                                type="radio"
                                id={branch.returnStreetAddress}
                                name="branch"
                                value={branch.returnStreetAddress}
                                defaultChecked={data.returnAddress && (data.returnAddress.returnStreetAddress === branch.returnStreetAddress)}
                            />
                            <span className="checkmark"></span>
                            <TooltipExtra type={branch.tooltipType}>
                                <img src={isChosen && !isMobile ? infoWhite : info} alt="info-icon" className="info-icon" />
                            </TooltipExtra>
                            <h3 className='city-title'>{branch.returnCity}</h3>
                            <label htmlFor={branch.returnStreetAddress} > <p>{branch.returnStreetAddress}, </p>
                                <p>{branch.returnCity}</p>
                                {branch.mailFolder && <p className="mail">{branch.mailFolder}</p>}</label>
                        </div>
                        )
                    })}
                </div>
                {/* <div className='select-container'>
                    {options.map((branch) => {
                        return (
                            <div
                                className={`box ${data.returnAddress && data.returnAddress.returnStreetAddress === branch.returnStreetAddress ? 'blue' : 'white'}`}
                                onClick={() => handleBranchSelected(branch)}
                                key={branch.returnCity}
                            >
                                <p>{branch.returnStreetAddress}</p>
                                <p>{branch.returnCity}</p>
                                {branch.mailFolder && <p>({branch.mailFolder})</p>}
                            </div>
                        )
                    })}

                </div> */}
            </div>
        </div>
    )
}

export default ReturnBranchSelecting
