export function actionSet(currAction) {
    return (dispatch) => {
        dispatch({ type: "SET_USER_ACTION", currAction });
    };
}

export function OrderSet(currOrder) {
    return (dispatch) => {
        dispatch({ type: "SET_USER_ORDER", currOrder });
    };
}

export function OrdersSet(userOrders) {
    return (dispatch) => {
        dispatch({ type: "SET_USER_ORDERS", userOrders });
    };
}

export function NotificationsSet(userNotifications) {
    return (dispatch) => {
        dispatch({ type: "SET_USER_NOTIFICATIONS", userNotifications });
    };
}
