import React, { useState } from "react";
import { HomeItem } from "../../../Styles/Cards/HomeItem";
import { ActionDiv } from "../../../Styles/Divs/Actions";
import { Link } from 'react-router-dom';
import { useViewport } from '../../../Hooks/useViewport'

// imports for store
import { useSelector, useDispatch } from 'react-redux';
import { updateSystemData } from '../../../store/actions/systemActions';
import info from '../../../images/info.svg'
import infoWhite from '../../../images/infoWhite.svg'
import { TooltipForms } from '../../Utils/TooltipForms'
import starGold from '../../../images/starGold.svg'
import { Tooltip } from '../../Utils/Tooltip'
import { TooltipFormsExtra } from '../../Utils/TooltipFormsExtra'
import db, { trace, perf } from '../../../integrations/firebase';
import { analytics, logEvent } from '../../../integrations/firebase'

export default function FavoriteAction({ actions, onFavAction }) {
  const t = trace(perf, "FavoriteAction");
  t.start();
  const [hoveredItemId, setHoveredItemId] = useState(null);

  const systemData = useSelector((state) => state.systemReducer.systemData)
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.userReducer.user);

  const { width } = useViewport();
  const breakpoint = 767;
  const isMobile = width < breakpoint;

  const setSystemData = (item) => {
    // dataSet({ files: [], recipients: [] })
    dispatch(updateSystemData({
      ...systemData,
      action: {
        formSubtitle: item?.formSubtitle || '',
        formId: item.nid,
        formTitle: item.title,
        formPrice: item.price,
        formImage: item.image,
        formIsActive: item.isActive,
        formIsPopular: item.isPopular,
        formCommon: item.isCommon,
        type: item.type
      },
      isEditMode: false,
      isFormModalOpen: true
    }));
  }

  const returnValue = (
    <ActionDiv>
      <div className="actions-title">
        <h2 className="h2-home">שירותים ששמרתי</h2>
        <p className="p-home" >
          המחירים אינם כוללים מע"מ
        </p>
      </div>

      <div className="actions-grid">
        {actions.map(
          (action) =>
            action.isActive && (
              <Link to={`/forms/${action.nid}`}
                key={action.nid}
                style={{ position: 'relative', height: '120px', overflow: `${action.isActive ? 'unset' : 'hidden'}` }}
                onMouseEnter={() => setHoveredItemId(action.nid)}
                onMouseLeave={() => setHoveredItemId(null)}
              >
                {hoveredItemId === action.nid && action.description && (
                  <div style={{ height: '0' }}>
                    <TooltipFormsExtra text={action.description} />
                  </div>
                )}
                {/* <Link to={`/forms/${action.nid}`} key={action.nid}> */}
                <HomeItem
                  sx={{ "&:hover": { background: "#eee" } }}
                  onClick={() => {
                    logEvent(analytics, 'open form', {
                      formName: action.title
                    })
                    setSystemData(action);
                    // setModalContent(action);
                  }}
                >
                  {action.isActive && !activeUser?.isAnonymous && !activeUser?.accessToken &&
                    <div className="star-tooltip">
                      {isMobile ?
                        <img type='button' src={starGold} width={20} alt="star-gold" className="info-icon" onClick={(ev) => { onFavAction(action, false); ev.preventDefault(); ev.stopPropagation() }} />
                        :
                        <div onMouseEnter={() => setHoveredItemId(null)}>
                          <Tooltip text={'הסרה מרשימת השירותים השמורים שלי'} >
                            <img type='button' src={starGold} width={20} alt="star-gold" className="info-icon" onClick={(ev) => { onFavAction(action, false); ev.preventDefault(); ev.stopPropagation() }} />
                          </Tooltip>
                        </div>
                      }
                    </div>}
                  {action.isActive &&
                    <div className={`info-tooltip ${activeUser && !activeUser?.isAnonymous && !activeUser?.accessToken ? 'user-display' : ''}`} onMouseEnter={() => setHoveredItemId(null)}>
                      <TooltipForms type={action.type} >
                        <img type='button' src={info} width={20} alt="info-icon" className="info-icon" onClick={(ev) => { ev.preventDefault(); ev.stopPropagation() }} />
                      </TooltipForms>
                    </div>}
                  <img
                    src={action.image}
                    alt="icon"
                    style={{ marginLeft: "1em" }}
                  />
                  <div className="card-details">
                    <h3 className="card-title">{action.title + ' ' + (action?.bracketsTitle || '')}</h3>
                    {!(activeUser?.pricelessActions?.includes(action.type)) && <p className="card-price">{action.price}</p>}
                  </div>
                  {/* <span
                  className={
                    "LabelPopular " +
                    (action.isPopular ? classes.show : classes.hidden)
                  }
                >
                  פופולרי
                </span> */}


                </HomeItem>
              </Link>
            )
        )}
      </div>
    </ActionDiv>
  );
  t.stop()
  return returnValue
}
