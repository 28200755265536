const INITIAL_STATE = {
  campaign: null,
  source: null,
  medium: null,
  term: null
}
export function utmReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_UTM':
      return action.utm;
    default:
      return state
  }
}