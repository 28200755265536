//smallClaim
//הגשת תביעה קטנה

const handleSteps = (data) => {
  const { collectionMethod } = data;
  const { returnMethod } = data;

  return [
    'ChooseClaimType',
    'SmallClaimClaimant',
    'SmallClaimDefendant',
    'SmallClaimRecipients',
    'SmallClaimDescription',
    'SmallClaimContract',
    'SmallClaimUrgencyExecution',
    'FinalPage',
  ].flat()
};
const handlePrevStep = (step, setStep, formSteps, state, setMyState, data, setData, setProgressBarPhase, onSetLastClaimant, onSetLastDefendant) => {
  if (step < 0) return
  const { nextStep } = state;
  let nowStep
  if (nextStep === 'SmallClaimClaimant') {
    onSetLastClaimant()
    if ((data?.claimants?.length > 0) && (data?.defendants?.length > 0)) nowStep = 3
    else nowStep = step - 1;
  }
  else if (nextStep === 'SmallClaimDefendant') {
    onSetLastDefendant()
    if (data?.defendants?.length > 0) nowStep = 3
    else nowStep = step - 1;
  } else if (nextStep === 'ChooseClaimType') {
    nowStep = formSteps.findIndex((page) => page === 'Recipients')
  } else {
    nowStep = step - 1;
  }

  setMyState({ ...state, nextStep: formSteps[nowStep] });
  setStep(nowStep);
};

const handleNextStep = (step, setStep, formSteps, state, setMyState, onAddRecipient, onAddOrder, data, setProgressBarPhase, setData, onAddEditClaimant, onAddEditDefendant, onSetLastDefendant, onSetLastClaimant) => {
  const { nextStep, currRecipient, isFinalStep } = state;
  let nowStep;


  if (isFinalStep) {
    onAddOrder();
    onSetLastDefendant(true)
    onSetLastClaimant(true)
  }

  if (nextStep === 'SmallClaimClaimant') {
    onAddEditClaimant()
  } else if (nextStep === 'SmallClaimDefendant') {
    onAddEditDefendant()
  } 
  // else if (nextStep === 'SmallClaimDescription') {
  //   onSetLastDefendant(true)
  // }

  if (nextStep === 'SmallClaimClaimant' && (data?.defendants?.length > 0)) {
    nowStep = 3
  } else {
    nowStep = step + 1;
  }

  setMyState({ ...state, nextStep: formSteps[nowStep], currRecipient: (nextStep === 'ChooseClaimType') ? null : currRecipient });
  setStep(nowStep);
};


const handlePhase = (nextStep, setProgressBarPhase) => {

  switch (nextStep) {
    case 'ChooseClaimType':
    case 'SmallClaimClaimant':
    case 'SmallClaimDefendant':
    case 'SmallClaimRecipients':
      setProgressBarPhase(1);
      break;
    case 'SmallClaimDescription':
      setProgressBarPhase(2);
      break;
    case 'SmallClaimContract':
      setProgressBarPhase(3);
      break;

    case 'SmallClaimUrgencyExecution':
    case 'FinalPage':
      setProgressBarPhase(4);
      break;

    default:
      setProgressBarPhase(4);
      break;
  }
};

export { handleSteps, handlePrevStep, handleNextStep, handlePhase };
