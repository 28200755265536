export const Footerbtns = [
  {
    title: "איתור מספר זהות",
    image: "/images/איתור מספר זהות.svg",
  },
  {
    title: "איתור כתובת חוקר פרטי ",
    image: "/images/איתור כתובת חוקר פרטי.svg",
  },
  {
    title: "איתור מספר פאלפון",
    image: "/images/איתור מספר פאלפון.svg",
  },
  {
    title: "מסירה אישית",
    image: "/images/מסירה אישית.svg",
  },
  {
    title: "אפוסטיל משרד החוץ",
    image: "/images/אפוסטיל משרד החוץ.svg",
  },
  {
    title: "פעולה ברשם לענייני ירושה",
    image: "/images/פעולה ברשם לענייני ירושה.svg",
  },
  {
    title: "המצאות למשרדים",
    image: "/images/המצאות למשרדים.svg",
  },
  {
    title: "בכל מוסד",
    image: "/images/בכל מוסד.svg",
  },
  {
    title: "פעולה ברשם החברות",
    image: "/images/פעולה ברשם החברות.svg",
  },
  {
    title: "הוצאה לפועל",
    image: "/images/הוצאה לפועל.svg",
  },
  {
    title: "הגשה לבית משפט / בית דין",
    image: "/images/הגשה לבית משפט.svg",
  },
  {
    title: "פעולה ברשם השותפויות",
    image: "/images/פעולה ברשם השותפויות.svg",
  },
  {
    title: "איתור מען",
    image: "/images/איתור מען.svg",
  },
  {
    title: "אפוסטיל בית משפט",
    image: "/images/אפוסטיל בית משפט.svg",
  },
];
export const actionsSite = [
  {
    "title": " בית משפט / בית דין",
    "nid": "file-to-court",
    "id": "הגשה לבית משפט",
    "image": "/images/הגשה לבית משפט.svg",
    "isCommon": true,
    "isPopular": true,
    "isActive": true,
    "price": "החל מ-₪60",
    "type": "courtSubmission",
    "description": "הדפסה כריכה ודגלונים או איסוף מסמכים עם שליח והגשה לכל ערכאה שיפוטית בארץ תוך 3, 1 או 0 ימים"
  },
  {
    "title": "הדפסה וכריכה",
    "nid": "printing_binding",
    "id": "הדפסה וכריכה",
    "image": "/images/הדפסה וכריכה.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": true,
    "price": "החל מ-₪20",
    "type": "printingBinding",
    "description": "שירות הדפסה וכריכה עם שליח עד המשרד. ניתן לבחור ולהוסיף כריכת ספירלה, דגלונים לנספחים וגם פס לתיוק הכריכה בקלסר משרדי"
  },
  {
    "title": "אפוסטיל משרד החוץ",
    "nid": "apostille",
    "id": "אפוסטיל משרד החוץ",
    "image": "/images/אפוסטיל משרד החוץ.svg",
    "isCommon": true,
    "isPopular": true,
    "isActive": true,
    "price": "החל מ-₪150",
    "type": "apostilSubmission",
    "description": "אימות אפוסטיל לתעודות ציבוריות עם כל השירותים הנלווים: תשלום אגרה, אימות מקדים, שליח עד הבית"
  },
  {
    "title": "אפוסטיל בית משפט",
    "nid": "apostille-in-court",
    "id": "אפוסטיל בית משפט",
    "image": "/images/אפוסטיל בית משפט.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": true,
    "price": "החל מ-₪50",
    "type": "apostilInCourt",
    "description": "אימות אפוסטיל בית משפט למסמכים נוטריונים תוך 7, 3 או 1 ימים"
  },
  {
    "title": "מסירה אישית",
    "nid": 'service-of-process',
    "id": "מסירה אישית",
    "image": "/images/מסירה אישית.svg",
    "isCommon": true,
    "isPopular": false,
    "isActive": true,
    "price": "החל מ-₪80 ",
    "type": "personalDelivery",
    "description": "המצאה כדין לאדם פרטי או בית עסק, עד 3 ביקורים בכתובת והכנת תצהיר מוסר מאומת על ידי עורך דין"
  },
  {
    "title": "המצאות למשרדים",
    "nid": "office-delivery",
    "id": "המצאות למשרדים",
    "image": "/images/המצאות למשרדים.svg",
    "isCommon": true,
    "isPopular": true,
    "isActive": true,
    "price": "החל מ-₪60",
    "type": "officeDelivery",
    "description": "המצאה למשרד עורכי דין או משרד ממשלתי והחתמת העתק המסמך בחותמת ״נתקבל״ בלבד"
  },
  {
    "title": "העברת מסמכים",
    "nid": "document-transfer",
    "id": "העברת מסמכים",
    "image": "/images/העברת מסמכים.svg",
    "isCommon": true,
    "isPopular": true,
    "isActive": true,
    "price": "החל מ-₪30",
    "type": "documentTransfer",
    "description": "שליחות של מסמכים או חבילה מהיום להיום באזורים נתמכים, או מהיום למחר בכל הארץ"
  },
  {
    "title": "לשכת רישום מקרקעין (טאבו)",
    "nid": "land-registration",
    "id": "רישום מקרקעין",
    "image": "/images/לשכת רישום מקרקעין.svg",
    "isCommon": true,
    "isPopular": true,
    "isActive": true,
    "price": "החל מ-₪60",
    "type": "landRegistration",
    "description": "הפקדה מצולמת או איסוף תיקי טאבו בכל הלשכות בארץ. הפקת ייפוי כח אוטומטי בתהליך ההזמנה"
  },
  {
    "title": "איתור כתובת מגורים",
    "nid": "address-locate",
    "id": "איתור כתובת חוקר פרטי",
    "image": "/images/איתור כתובת חוקר פרטי.svg",
    "isCommon": false,
    "isPopular": true,
    "isActive": true,
    "price": "החל מ-₪400",
    "formSubtitle": "באמצעות חוקר פרטי",
    "type": "addressLocate",
    "description": "איתור כתובת אדם באמצעות חוקר פרטי - לא איתרנו? הכסף יוחזר במלואו"
  },
  {
    "title": "איתור מען",
    "nid": 'person-locate',
    "id": "איתור מען",
    "image": "/images/איתור מען.svg",
    "isCommon": true,
    "isPopular": false,
    "isActive": true,
    "price": "החל מ-₪50",
    "formSubtitle": "שאילתא משרד הפנים",
    "bracketsTitle": '(שאילתא)',
    "type": "personLocate",
    "description": "הפקת נסח מען ממשרד הפנים תוך 5, 3 או 1 ימים"
  },
  {
    "title": "איתור מספר פלאפון",
    "nid": 'phone-locate',
    "id": "איתור מספר פלאפון",
    "image": "/images/איתור מספר פאלפון.svg",
    "isCommon": false,
    "isPopular": true,
    "isActive": true,
    "price": "החל מ-₪350",
    "formSubtitle": "באמצעות חוקר פרטי",
    "type": "phoneLocate",
    "description": "איתור פלאפון אדם באמצעות חוקר פרטי - לא איתרנו? הכסף יוחזר במלואו"
  },
  {
    "title": "איתור מספר זהות",
    "nid": "ID-locate",
    "id": "איתור מספר זהות",
    "image": "/images/איתור מספר זהות.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": true,
    "price": "לפי הצעת מחיר",
    "formSubtitle": "באמצעות חוקר פרטי",
    "type": "idLocate",
    "description": "הצעת מחיר לאיתור מספר זהות של אדם לפי הפרטים הידועים לכם"
  },
  {
    "title": "הגשת תביעה קטנה",
    "nid": "filing-a-small-claim",
    "id": "הגשת תביעה קטנה",
    "image": "/images/הגשת תביעה קטנה.svg",
    "isCommon": false,
    "isPopular": true,
    "isActive": true,
    "price": "החל מ-₪300",
    "type": "smallClaim",
    "description": "מילוי אונליין של תביעה קטנה והגשתה על גבי הטופס המחויב בתקנות. שירותים נלווים: צירוף קבצים, הקלטות, תמונות, מסירה אישית לנתבע/ים"
  },
  {
    "title": "רשם לענייני ירושה",
    "nid": 'inheritance-registration',
    "id": "רשם לענייני ירושה",
    "image": "/images/פעולה ברשם לענייני ירושה.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": true,
    "price": "החל מ-₪60",
    "type": 'inheritanceRegistration',
    "description": "הגשת מסמכים לכל לשכה בארץ, בהפקדה מצולמת או בזימון תור לצורך החתמת ״נתקבל״"
  },
  {
    "title": "רשם החברות",
    "nid": 'companies-registration',
    "id": "רשם החברות",
    "image": "/images/פעולה ברשם החברות.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": true,
    "price": "החל מ-₪60",
    "type": "companiesRegistration",
    "description": "הגשת מסמכים לכל לשכה בארץ, בהפקדה מצולמת או בזימון תור לצורך החתמת ״נתקבל״"
  },
  {
    "title": "רשם השותפויות",
    "nid": 'partnerships-registration',
    "id": "רשם השותפויות",
    "image": "/images/פעולה ברשם השותפויות.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": true,
    "price": "החל מ-₪60",
    "type": 'partnershipsRegistration',
    "description": "הגשת מסמכים לכל לשכה בארץ, בהפקדה מצולמת או בזימון תור לצורך החתמת ״נתקבל״"
  },
  {
    "title": "רשם העמותות וחל''צ",
    "nid": 'associations-and-companies',
    "id": "רשם העמותות וחל\"צ",
    "image": "/images/פעולה ברשם העמותות וחלצ.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": true,
    "price": "החל מ-₪60",
    "type": 'associationsAndCompanies',
    "description": "הגשת מסמכים לכל לשכה בארץ, בהפקדה מצולמת או בזימון תור לצורך החתמת ״נתקבל״"
  },
  {
    "title": "המפקח על המקרקעין",
    "nid": "land_supervisor",
    "id": "המפקח על המקרקעין",
    "image": "/images/המפקח על המקרקעין.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": false,
    "price": "החל מ-₪60",
    "type": "landSupervisor",
    "description": "המפקח על המקרקעין",
  },
  {
    "title": "רשות מקרקעי ישראל",
    "nid": 12,
    "id": "רשות מקרקעי ישראל",
    "image": "/images/רשות מקרקעי ישראל.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": false,
    "price": "החל מ-₪60",
    "description": ""
  },
  {
    "title": "רשם המשכונות",
    "nid": 5,
    "id": "פעולה ברשם המשכונות",
    "image": "/images/פעולה ברשם המשכונות.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": false,
    "price": "החל מ-₪60",
    "description": ""
  },
  {
    "title": "הוצאה לפועל",
    "nid": 14,
    "id": "הוצאה לפועל",
    "image": "/images/הוצאה לפועל.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": false,
    "price": "החל מ-₪60",
    "description": ""
  },
  {
    "title": "כל מוסד אחר",
    "nid": 15,
    "id": "בכל מוסד",
    "image": "/images/בכל מוסד.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": false,
    "price": "החל מ-₪60",
    "description": ""
  },
  {
    "title": "מיסוי מקרקעין",
    "nid": "land-taxation",
    "id": "מיסוי מקרקעין",
    "image": "/images/מיסוי מקרקעין.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": false,
    "price": "החל מ-₪60",
    "type": "landTaxation",
    "description": ""
  },
  {
    "title": "חדלות פירעון",
    "nid": "insolvency",
    "id": "חדלות פירעון",
    "image": "/images/חדלות פירעון.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": false,
    "price": "החל מ-₪60",
    "type": "insolvency",
    "description": ""
  },
  {
    "title": "האפוטרופוס הכללי",
    "nid": "general-guardian",
    "id": "האפוטרופוס הכללי",
    "image": "/images/האפוטרופוס הכללי.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": false,
    "price": "החל מ-₪60",
    "type": "generalGuardian",
    "description": ""
  },
  {
    "title": "הגשת כתב הגנה לתביעה קטנה",
    "nid": "small-claim-defense-statement",
    "id": "הגשת כתב הגנה לתביעה קטנה",
    "image": "/images/הגשת כתב הגנה לתביעה קטנה.svg",
    "isCommon": false,
    "isPopular": false,
    "isActive": false,
    "price": "החל מ-₪300",
    "type": "smallClaimDefenseStatement",
    "description": ""
  },
]
export const countrys = [
  {
    country: "אוגנדה",
    isAmana: false
  },
  {
    country: "אוזבקיסטן",
    isAmana: true
  },
  {
    country: "אוסטריה",
    isAmana: true
  },

  {
    country: "אוסטרליה",
    isAmana: true
  },
  {
    country: "אוקראינה",
    isAmana: true
  },
  {
    country: "אורוגוואי",
    isAmana: true
  },
  {
    country: "אזרבייג'ן",
    isAmana: true
  },
  {
    country: "איטליה",
    isAmana: true
  },
  {
    country: "איי מרשל",
    isAmana: true
  },
  {
    country: "איי סיישל",
    isAmana: true
  },
  {
    country: "איי קוק",
    isAmana: true
  },
  {
    country: "איי שלמה",
    isAmana: false
  },
  {
    country: "אינדונזיה",
    isAmana: false
  },
  {
    country: "איסלנד",
    isAmana: true
  },
  {
    country: "איראן",
    isAmana: false
  },
  {
    country: "אירלנד",
    isAmana: true
  },
  {
    country: "אל סלוודור",
    isAmana: true
  },
  {
    country: "אלבניה",
    isAmana: true
  },
  {
    country: "אלג'יריה",
    isAmana: false
  },
  {
    country: "אנגולה",
    isAmana: false
  },
  {
    country: "אנדורה",
    isAmana: true
  },
  {
    country: "אנטיגואה וברבודה",
    isAmana: true
  },
  {
    country: "אסוואטיני",
    isAmana: true
  },
  {
    country: "אסטוניה",
    isAmana: true
  },
  {
    country: "אפגניסטן",
    isAmana: false
  },
  {
    country: "אקוודור",
    isAmana: true
  },
  {
    country: "ארגנטינה",
    isAmana: true
  },
  {
    country: "אריתריאה",
    isAmana: false
  },
  {

    country: "ארמניה",
    isAmana: true
  },
  {
    country: "ארצות הברית",
    isAmana: true
  },
  {
    country: "אתיופיה",
    isAmana: false
  },
  {
    country: "בהאמה",
    isAmana: true
  },
  {
    country: "בהוטן",
    isAmana: false
  },
  {
    country: "בוטסואנה",
    isAmana: true
  },
  {
    country: "בולגריה",
    isAmana: true
  },
  {
    country: "בוליביה",
    isAmana: true
  },
  {
    country: "בוסניה והרצגובינה",
    isAmana: true
  },
  {
    country: "בורונדי",
    isAmana: true
  },
  {
    country: "בורמה",
    isAmana: false
  },
  {
    country: "בורקינה פאסו",
    isAmana: false
  },
  {
    country: "בחריין",
    isAmana: true
  },
  {
    country: "בלגיה",
    isAmana: true
  },
  {
    country: "בליז",
    isAmana: true
  },
  {
    country: "בלארוס",
    isAmana: true
  },
  {
    country: "בנגלדש",
    isAmana: false
  },
  {
    country: "בנין",
    isAmana: false
  },
  {
    country: "ברבדוס",
    isAmana: true
  },
  {
    country: "ברוניי",
    isAmana: true
  },
  {
    country: "ברזיל",
    isAmana: true
  },
  {
    country: "בריטניה",
    isAmana: true
  },
  {
    country: "גאורגיה",
    isAmana: true
  },
  {
    country: "גאנה",
    isAmana: false
  },
  {
    country: "גבון",
    isAmana: false
  },
  {
    country: "גואטמלה",
    isAmana: true
  },
  {
    country: "גיאנה",
    isAmana: false
  },
  {
    country: "ג'יבוטי",
    isAmana: false
  },
  {
    country: "גינאה",
    isAmana: false
  },
  {
    country: "גינאה ביסאו",
    isAmana: false
  },
  {
    country: "גיניאה המשוונית",
    isAmana: false
  },
  {
    country: "גמביה",
    isAmana: false
  },
  {
    country: "ג'מייקה",
    isAmana: false
  },
  {
    country: "גרמניה",
    isAmana: true
  },
  {
    country: "גרנדה",
    isAmana: true
  },
  {
    country: "דומיניקה",
    isAmana: true
  },
  {
    country: "דנמרק",
    isAmana: true
  },
  {
    country: "דרום אפריקה",
    isAmana: true
  },
  {
    country: "דרום סודאן",
    isAmana: false
  },
  {
    country: "האיטי",
    isAmana: false
  },
  {
    country: "הונג קונג",
    isAmana: true
  },
  {
    country: "האמירויות הערביות המאוחדות",
    isAmana: false
  },
  {
    country: "הודו",
    isAmana: true
  },
  {
    country: "הולנד",
    isAmana: true
  },
  {
    country: "הונגריה",
    isAmana: true
  },
  {
    country: "הונדורס",
    isAmana: true
  },
  {
    country: "הממלכה המאוחדת",
    isAmana: true
  },
  {
    country: "וייטנאם",
    isAmana: false
  },
  {
    country: "ונואטו",
    isAmana: true
  },
  {
    country: "ונצואלה",
    isAmana: true
  },
  {
    country: "ותיקן",
    isAmana: false
  },
  {
    country: "זאיר",
    isAmana: false
  },
  {
    country: "זימבבואה",
    isAmana: false
  },
  {
    country: "זמביה",
    isAmana: false
  },
  {
    country: "חוף השנהב",
    isAmana: false
  },
  {
    country: "טג'יקיסטן",
    isAmana: true
  },
  {
    country: "טובאלו",
    isAmana: false
  },
  {
    country: "טוגו",
    isAmana: false
  },
  {
    country: "טונגה",
    isAmana: true
  },
  {
    country: "טורקמניסטן",
    isAmana: false
  },
  {
    country: "טנזניה",
    isAmana: false
  },
  {
    country: "טרינידד וטובגו",
    isAmana: true
  },
  {
    country: "יוון",
    isAmana: true
  },
  {
    country: "יפן",
    isAmana: true
  },
  {
    country: "ירדן",
    isAmana: false
  },
  {
    country: "ישראל",
    isAmana: true
  },
  {
    country: "כווית",
    isAmana: false
  },
  {
    country: "כף ורדה",
    isAmana: true
  },
  {
    country: "לאוס",
    isAmana: false
  },
  {
    country: "לבנון",
    isAmana: false
  },
  {
    country: "לוב",
    isAmana: false
  },
  {
    country: "לוקסמבורג",
    isAmana: true
  },
  {
    country: "לטביה",
    isAmana: true
  },
  {
    country: "ליבריה",
    isAmana: true
  },
  {
    country: "ליטא",
    isAmana: true
  },
  {
    country: "ליכטנשטיין",
    isAmana: true
  },
  {
    country: "לסוטו",
    isAmana: true
  },
  {
    country: "מאוריטניה",
    isAmana: false
  },
  {
    country: "מאוריציוס",
    isAmana: true
  },
  {
    country: "מאלי",
    isAmana: false
  },
  {
    country: "מדגסקר",
    isAmana: false
  },
  {
    country: "מוזמביק",
    isAmana: false
  },
  {
    country: "מולדובה",
    isAmana: true
  },
  {
    country: "מקאו",
    isAmana: true
  },

  {
    country: "מונגוליה",
    isAmana: true
  },
  {
    country: "מונטנגרו",
    isAmana: true
  },

  {
    country: "מונקו",
    isAmana: true
  },
  {
    country: "מזרח טימור",
    isAmana: false
  },
  {
    country: "מיאנמר",
    isAmana: false
  },
  {
    country: "מיקרונזיה",
    isAmana: false
  },
  {
    country: "מלאווי",
    isAmana: true
  },
  {
    country: "מלדיביים",
    isAmana: false
  },
  {
    country: "מלזיה",
    isAmana: false
  },
  {
    country: "מלטה",
    isAmana: true
  },
  {
    country: "מצרים",
    isAmana: false
  },
  {
    country: "מקאו",
    isAmana: true
  },

  {
    country: "מקדוניה",
    isAmana: true
  },
  {
    country: "מקסיקו",
    isAmana: true
  },
  {
    country: "מרוקו",
    isAmana: true
  },
  {
    country: "נאורו",
    isAmana: false
  },
  {
    country: "נורבגיה",
    isAmana: true
  },
  {
    country: "ניגריה",
    isAmana: false
  },
  {
    country: "ניו זילנד",
    isAmana: true
  },
  {
    country: "ניז'ר",
    isAmana: false
  },
  {
    country: "ניקרגואה",
    isAmana: true
  },
  {
    country: "ניואה",
    isAmana: true
  },
  {
    country: "נמיביה",
    isAmana: true
  },
  {
    country: "נפאל",
    isAmana: false
  },
  {
    country: "סאו טומה ופרינסיפה",
    isAmana: true
  },
  {
    country: "סודאן",
    isAmana: false
  },
  {
    country: "סווזילנד",
    isAmana: false
  },
  {
    country: "סומליה",
    isAmana: false
  },
  {
    country: "סוריה",
    isAmana: false
  },
  {
    country: "סורינם",
    isAmana: true
  },
  {
    country: "סיירה לאונה",
    isAmana: false
  },
  {
    country: "סין",
    isAmana: true
  },
  {
    country: "סינגפור",
    isAmana: false
  },
  {
    country: "סלובניה",
    isAmana: true
  },
  {
    country: "סלובקיה",
    isAmana: true
  },
  {
    country: "סמואה",
    isAmana: true
  },
  {
    country: "סן מרינו",
    isAmana: true
  },
  {
    country: "סנגל",
    isAmana: false
  },
  {
    country: "סנט וינסנט והגרנדינים",
    isAmana: true
  },
  {
    country: "סנט לוסיה",
    isAmana: true
  },
  {
    country: "סנט קיטס ונוויס",
    isAmana: true
  },
  {
    country: "ספרד",
    isAmana: true
  },
  {
    country: "סרביה",
    isAmana: true
  },
  {
    country: "סרי לנקה",
    isAmana: false
  },
  {
    country: "עומאן",
    isAmana: false
  },
  {
    country: "עיראק",
    isAmana: false
  },
  {
    country: "ערב הסעודית",
    isAmana: false
  },
  {
    country: "פולין",
    isAmana: true
  },
  {
    country: "פורטוגל",
    isAmana: true
  },
  {
    country: "פיג'י",
    isAmana: true
  },
  {
    country: "פיליפינים",
    isAmana: true
  },
  {
    country: "פינלנד",
    isAmana: true
  },
  {
    country: "פלאו",
    isAmana: false
  },
  {
    country: "פנמה",
    isAmana: true
  },
  {
    country: "פפואה גינאה החדשה",
    isAmana: false
  },
  {
    country: "פקיסטן",
    isAmana: false
  },
  {
    country: "פרגוואי",
    isAmana: true
  },
  {
    country: "פרו",
    isAmana: true
  },
  {
    country: "צ'אד",
    isAmana: false
  },
  {
    country: "צ'ילה",
    isAmana: true
  },
  {
    country: "צ'כיה",
    isAmana: true
  },
  {
    country: "צרפת",
    isAmana: true
  },
  {
    country: "קובה",
    isAmana: false
  },
  {
    country: "קולומביה",
    isAmana: true
  },
  {
    country: "קומורו",
    isAmana: false
  },
  {
    country: "קונגו",
    isAmana: false
  },
  {
    country: "קונגו",
    isAmana: false
  },
  {
    country: "קוסטה ריקה",
    isAmana: true
  },
  {
    country: "קוריאה הדרומית",
    isAmana: true
  },
  {
    country: "קוריאה הצפונית",
    isAmana: false
  },
  {
    country: "קזחסטן",
    isAmana: true
  },
  {
    country: "קטאר",
    isAmana: false
  },
  {
    country: "קירגיזסטן",
    isAmana: true
  },
  {
    country: "קיריבטי",
    isAmana: false
  },
  {
    country: "קמבודיה",
    isAmana: false
  },
  {
    country: "קמרון",
    isAmana: false
  },
  {
    country: "קנדה",
    isAmana: false
  },
  {
    country: "קניה",
    isAmana: false
  },
  {
    country: "קפריסין",
    isAmana: true
  },
  {
    country: "קרואטיה",
    isAmana: true
  },
  {
    country: "קריית הוותיקן",
    isAmana: false
  },
  {
    country: "רואנדה",
    isAmana: false
  },
  {
    country: "רומניה",
    isAmana: true
  },
  {
    country: "רוסיה",
    isAmana: true
  },
  {
    country: "רפובליקה הדומיניקנית",
    isAmana: true
  },
  {
    country: "רפובליקה המרכז אפריקאית",
    isAmana: false
  },
  {
    country: "הרפובליקה הסינית (טאיוואן)",
    isAmana: false
  },
  {
    country: "שבדיה",
    isAmana: true
  },
  {
    country: "שווייץ",
    isAmana: true
  },
  {
    country: "תאילנד",
    isAmana: false
  },
  {
    country: "תוניסיה",
    isAmana: true
  },
  {
    country: "תורכיה / טורקיה",
    isAmana: true
  },
  {
    country: "תימן",
    isAmana: true
  },
];
export const HEBREW_ACTIONS_TYPE = {
  "personalDelivery": "מסירה אישית",
  "officeDelivery": "המצאות למשרדים",
  "courtSubmission": "בית משפט / בית דין",
  3: "פעולה ברשם המשכונות",
  4: "רשמי גופים",
  "inheritanceRegistration": "רשם לענייני ירושה",
  6: "רשות מקרקעי ישראל",
  "landRegistration": "לשכת רישום מקרקעין",
  8: "הוצאה לפועל",
  9: "פעולה בכל מוסד",
  "apostilSubmission": "אפוסטיל משרד החוץ",
  "apostilInCourt": "אפוסטיל בית משפט",
  "personLocate": "איתור מען",
  "phoneLocate": "איתור מספר פלאפון",
  "idLocate": "איתור מספר זהות",
  "addressLocate": "איתור כתובת מגורים",
  "documentTransfer": "העברת מסמכים",
  "companiesRegistration": "רשם החברות",
  "partnershipsRegistration": "רשם השותפויות",
  "associationsAndCompanies": "רשם העמותות וחל\"צ",
  "smallClaim": "הגשת תביעה קטנה",
  "landTaxation": "מיסוי מקרקעין",
  "insolvency": "חדלות פירעון",
  "generalGuardian": "האפוטרופוס הכללי",
}

export const certificatesTypes = [
  {
    name: 'תעודת נישואין',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לתעודת נישואין, נדרש שבגב התעודה תהיה חתימת פקיד מורשה בהתאם לדת המבקש.ת, ראו דוגמא בתעודה לדוגמא',
    isCertificate: true,
    certificateText: 'בפני פקיד מורשה, לפי דת המבקש.ת',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%93%D7%95%D7%92%D7%9E%D7%90%20%D7%A9%D7%9C%20%D7%AA%D7%A2%D7%95%D7%93%D7%AA%20%D7%A0%D7%99%D7%A9%D7%95%D7%90%D7%99%D7%9F%20-%20%D7%A1%D7%95%D7%A4%D7%99.pdf?alt=media&token=41c29b46-6581-4f15-9738-0b5605d9c485'
  },
  {
    name: 'תרגום נוטריוני',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לתרגום נוטריוני, נדרש שבגב התעודה תהיה חתימת אפוסטיל של בית משפט, המאשר את התרגום. ללא חתימה מקדימה בבית המשפט משרד החוץ לא יתן חותמת אפוסטיל. ראו דוגמא בתעודה לדוגמא ',
    isCertificate: true,
    certificateText: 'אפוסטיל בית המשפט',
    certificateCost: 150,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%90%D7%99%D7%A9%D7%95%D7%A8%20%D7%A0%D7%95%D7%98%D7%A8%D7%99%D7%95%D7%A0%D7%99%20-%20%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C.pdf?alt=media&token=c894b14d-ea26-413d-b934-52c9ec4e6768'
  },
  {
    name: 'תעודת גירושין',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לתעודת גירושין, נדרש שבגב התעודה תהיה חתימת מורשה מהנהלת בתי הדין הרבניים. ראו דוגמא בתעודה לדוגמא',
    isCertificate: true,
    certificateText: 'בהנהלת בתי הדין הרבניים',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%AA%D7%A2%D7%95%D7%93%D7%AA%20%D7%92%D7%99%D7%A8%D7%95%D7%A9%D7%99%D7%9F%20-%20%D7%9E%D7%9C%D7%90.pdf?alt=media&token=c67da685-727c-4453-b2b6-17b29afcf6cf'
  },
  {
    name: 'תעודת לידה',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%93%D7%95%D7%92%D7%9E%D7%90%20%D7%9C%D7%AA%D7%A2%D7%95%D7%93%D7%AA%20%D7%9C%D7%99%D7%93%D7%94%20.pdf?alt=media&token=1d4b373c-7584-45d2-8871-570cd2ad2f0f'
  },
  {
    name: 'תמצית רישום',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%AA%D7%9E%D7%A6%D7%99%D7%AA%20%D7%A8%D7%99%D7%A9%D7%95%D7%9D%20%D7%9E%D7%9E%D7%A9%D7%A8%D7%93%20%D7%94%D7%A4%D7%A0%D7%99%D7%9D.pdf?alt=media&token=c0e614cf-3bde-4638-9dca-71ccedd6befe'
  },
  {
    name: 'תעודת מידע פלילי',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%AA%D7%A2%D7%95%D7%93%D7%AA%20%D7%9E%D7%99%D7%93%D7%A2%20%D7%A4%D7%9C%D7%99%D7%9C%D7%99.pdf?alt=media&token=5222a4a1-c02f-4c7e-a39d-8e58feaf60a8'
  },
  {
    name: 'תעודת בגרות',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לתעודת בגרות, נדרש שעל גבי התעודה תהיה חתימת פקיד מורשה מטעם אגף הבחינות של משרד החינוך. ראו דוגמא בתעודה לדוגמא. לצורך אימות מקדים במשרד החינוך, יש להעביר ייפוי כח בצירוף צילום תעודת זהות.',
    isCertificate: true,
    certificateText: 'באגף הבחינות של משרד החינוך',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%AA%D7%A2%D7%95%D7%93%D7%AA%20%D7%91%D7%92%D7%A8%D7%95%D7%AA.pdf?alt=media&token=d770a0f8-ec75-4889-90ce-f26ea79b6a93'
  },
  {
    name: 'תעודה אקדמית',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לתעודה אקדמית, נדרש שעל גבי התעודה תהיה חתימת פקיד מורשה מטעם משרד החינוך. ראו דוגמא בתעודה לדוגמא. לצורך אימות מקדים במשרד החינוך, יש להעביר ייפוי כח בצירוף צילום תעודת זהות.',
    isCertificate: true,
    certificateText: 'במשרד החינוך',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%AA%D7%A2%D7%95%D7%93%D7%94%20%D7%90%D7%A7%D7%93%D7%9E%D7%90%D7%99%D7%AA.pdf?alt=media&token=c2d5b13e-18ab-4d8a-b7c1-1725d30d2c21'
  },
  {
    name: 'נסח סימן מסחר',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לנסח סימן מסחר, עם הנסח צריך לצרף אישור מורשה חתימה משרד המשפטים, רשות הפטנטים. אישור רשות הפטנטים כרוך בתשלום אגרה בסך 49₪ באתר רשות הפטנטים. את האגרה יש לשלם ישירות לרשות הפטנטים תוך ציון מספר סימן המסחר. ראו דוגמא בתעודה לדוגמא ',
    isCertificate: true,
    certificateText: 'ברשות הפטנטים',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%A0%D7%A1%D7%97%20%D7%9E%D7%A1%D7%97%D7%A8.pdf?alt=media&token=387f4eaa-cb6c-4a06-8fe0-171e521998e0'
  },
  {
    name: 'תעודת בית ספר',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לתעודת בית ספר, נדרש שעל גבי התעודה תהיה חתימת פקיד מורשה מטעם משרד החינוך. ראו דוגמא בתעודה לדוגמא. לצורך אימות מקדים במשרד החינוך, יש להעביר ייפוי כח בצירוף צילום תעודת זהות.',
    isCertificate: true,
    certificateText: 'במשרד החינוך',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%AA%D7%A2%D7%95%D7%93%D7%AA%20%D7%90%D7%99%D7%A9%D7%95%D7%A8%20%D7%A1%D7%99%D7%95%D7%9D%20%D7%91%D7%99%D7%AA%20%D7%A1%D7%A4%D7%A8.pdf?alt=media&token=52340cc4-2a5e-4cd1-8a79-2bfcb5269ed4'
  },
  {
    name: 'תעודת הסכמה של רופא',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לתעודת הסמכה של רופא, נדרש שבגב התעודה תהיה חתימת מורשה וחותמת של משרד הבריאות ומורשי החתימה של לשכת הבריאות המחוזית בעיר המגורים *דלוברי מבצעת אימות במשרד הבריאות בלבד ולא בלשכת הבריאות המחוזית״. ראו דוגמא בתעודה לדוגמא',
    isCertificate: true,
    certificateText: 'במשרד הבריאות',
    certificateCost: 100,
    link: null
  },
  {
    name: 'פסק דין / החלטה / פסיקתא',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לפסק דין או החלטה, יש לצרף עותק שלם של פסק הדין / החלטה, החתום בחותמת וחתימת המזכיר הראשי או סגן המזכיר הראשי של בית המשפט שבו ניתן פסק הדין או ההחלטה. ראו דוגמא בתעודה לדוגמא ',
    isCertificate: true,
    certificateText: 'בבית המשפט שנתן את פסק הדין',
    certificateCost: 150,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%A4%D7%A1%D7%A7%20%D7%93%D7%99%D7%9F%20_%20%D7%94%D7%97%D7%9C%D7%98%D7%94.pdf?alt=media&token=23bf450a-d5e4-48a8-8e78-ad6cfbea656e'
  },
  {
    name: 'צו ירושה / קיום צוואה מהרשם לענייני ירושה',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לצו ירושה או צו קיום צוואה מהרשם לענייני ירושה, חובה לצרף מסמך מוקרי עם חתימת מורשי החתימה מטעם הרשם לענייני ירושה באזור המגורים בו נחתם הצו. ראו דוגמא בתעודה לדוגמא',
    isCertificate: true,
    certificateText: 'אצל הרשם לענייני ירושה',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%A6%D7%95%20%D7%99%D7%A8%D7%95%D7%A9%D7%94%20%D7%94%D7%A8%D7%A9%D7%9D%20%D7%9C%D7%A2%D7%A0%D7%99%D7%99%D7%A0%D7%99%20%D7%99%D7%A8%D7%95%D7%A9%D7%94.pdf?alt=media&token=a06c1b59-023e-49ef-ae89-fc720491d55e'
  },
  {
    name: 'אישור משרד התחבורה על רישיון נהיגה',
    varificationtoolTip: 'לצורך החתמת אפוסטיל על תעודה של משרד התחבורה, דרוש שעל גבי תעודת המקור תהיה חתימת נציג מורשה מטעם משרד התחבורה. ראו דוגמא בתעודה לדוגמא.',
    isCertificate: true,
    certificateText: 'במשרד התחבורה',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%90%D7%99%D7%A9%D7%95%D7%A8%20%D7%9E%D7%A9%D7%A8%D7%93%20%D7%94%D7%AA%D7%97%D7%91%D7%95%D7%A8%D7%94%20%D7%A2%D7%9C%20%D7%A8%D7%99%D7%A9%D7%99%D7%95%D7%9F%20%D7%A0%D7%94%D7%99%D7%92%D7%94.pdf?alt=media&token=553762cf-56d4-44cd-b2ef-e8f3538120fe'
  },
  {
    name: 'אישור תושבות מס',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לאישור תושבות מס, נדרש שתעודת המקור תיחתם על ידי עו״ד / רו״ח מטעם רשות המיסים. ראו דוגמא בתעודה לדוגמא',
    isCertificate: true,
    certificateText: 'אצל רשות המיסים',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%90%D7%99%D7%A9%D7%95%D7%A8%20%D7%AA%D7%95%D7%A9%D7%91%D7%95%D7%AA%20%D7%9E%D7%A1.pdf?alt=media&token=f855bc74-7722-4ff0-9298-446321adf1bf'
  },
  {
    name: 'צו ירושה / קיום צוואה מבית המשפט',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לצו ירושה או צו קיום צוואה מבית המשפט, על הצו להיות חתום ע״י המזכיר הראשי של בית המשפט בו נחתם הצו. ראו דוגמא בתעודה לדוגמא ',
    isCertificate: true,
    certificateText: 'בבית המשפט בו נחתם הצו',
    certificateCost: 150,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-prod.appspot.com/o/sample_certificates%2F%D7%A6%D7%95%20%D7%A7%D7%99%D7%95%D7%9D%20%D7%A6%D7%95%D7%95%D7%90%D7%94.pdf?alt=media&token=eb947663-5246-4774-a378-f28187c909df'
  },
  {
    name: 'תעודת חיים',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%AA%D7%A2%D7%95%D7%93%D7%AA%20%D7%97%D7%99%D7%99%D7%9D%20(1).pdf?alt=media&token=b59aa65f-bbcc-4352-90c4-42df2dd7dab9'
  },
  {
    name: 'תעודת פטירה',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%93%D7%95%D7%92%D7%9E%D7%90%20%D7%9C%D7%AA%D7%A2%D7%95%D7%93%D7%AA%20%D7%A4%D7%98%D7%99%D7%A8%D7%94.pdf?alt=media&token=893ff5f0-c1c2-4483-bac7-3ea0f7b36c20'
  },
  {
    name: 'תעודה המעידה על שינוי שם',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%93%D7%95%D7%92%D7%9E%D7%90%20%D7%9C%D7%AA%D7%A2%D7%95%D7%93%D7%94%20%D7%94%D7%9E%D7%A2%D7%99%D7%93%D7%94%20%D7%A2%D7%9C%20%D7%A9%D7%99%D7%A0%D7%95%D7%99%20%D7%A9%D7%9D.pdf?alt=media&token=74735fcd-5717-4f7e-af7e-3bd979900086'
  },
  {
    name: 'תעודה המעידה על אזרחות ישראלית',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%93%D7%95%D7%92%D7%9E%D7%90%20%D7%9C%D7%AA%D7%A2%D7%95%D7%93%D7%94%20%D7%94%D7%9E%D7%A2%D7%99%D7%93%D7%94%20%D7%A2%D7%9C%20%D7%90%D7%96%D7%A8%D7%97%D7%95%D7%AA%20%D7%99%D7%A9%D7%A8%D7%90%D7%9C%D7%99%D7%AA%20.pdf?alt=media&token=69db61cb-05b8-4480-bf79-ec1be7a9e0f4'
  },
  {
    name: 'תעודת התאגדות חברה',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-prod.appspot.com/o/sample_certificates%2F%D7%AA%D7%A2%D7%95%D7%93%D7%AA%20%D7%94%D7%AA%D7%90%D7%92%D7%93%D7%95%D7%AA.pdf?alt=media&token=05f90d62-5148-433e-a8ea-6e9eea832614'
  },
  {
    name: 'תעודת שינוי שם של חברה',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-prod.appspot.com/o/sample_certificates%2F%D7%A9%D7%99%D7%A0%D7%95%D7%99%20%D7%A9%D7%9D%20%D7%A9%D7%9C%20%D7%97%D7%91%D7%A8%D7%94.pdf?alt=media&token=c9a38d28-7d5a-4d8f-a693-f7328e28db1b'
  },
  {
    name: 'תעודה שהונפקה על ידי צה״ל',
    varificationtoolTip: 'לצורך החתמת אפוסטיל על תעודה מצה״ל, דרוש שהתעודה תהיה מקורית, וחתומה על ידי קצינת פניות הציבור של צה״ל. ראו דוגמא בתעודה לדוגמא',
    isCertificate: false,
    certificateText: 'אצל קצינת פניות הציבור של צה"ל',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%93%D7%95%D7%92%D7%9E%D7%90%20%D7%9C%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C%20%D7%90%D7%99%20%D7%A9%D7%99%D7%A8%D7%95%D7%AA%20%D7%A6%D7%91%D7%90%D7%99.pdf?alt=media&token=ce7dfd68-5ba3-486e-b7e7-1d29ff60015f',
  },
  {
    name: 'תעודת יהדות ורווקות',
    varificationtoolTip: 'לצורך החתמת אפוסטיל לתעודת יהדות ורווקות, נדרש שבגב התעודה תהיה חתימת פקיד מורשה, בהתאם לדת המבקש.ת, ראו דוגמא בתעודה לדוגמא',
    isCertificate: true,
    certificateText: 'בפני פקיד מורשה, לפי דת המבקש.ת',
    certificateCost: 100,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-online-israel.appspot.com/o/~2fFpublicDocuments%2F%D7%99%D7%94%D7%93%D7%95%D7%AA%20%D7%95%D7%A8%D7%95%D7%95%D7%A7%D7%95%D7%AA.pdf?alt=media&token=28187015-617f-4686-92c4-1b4fc48af06d',
  },
  {
    name: 'תעודה אחרת',
    isCertificate: true,
    certificateText: '',
    varificationtoolTip: 'במידה ותרצו לאמת תעודה ציבורית שלא ברשימה, בדקו לפני האם יש צורך באימות מקדים לאותה תעודה. אם כן, סמנו זאת וכתבו לנו בהערות בסוף ההזמנה באיזה מוסד נדרש האימות המקדים.',
    link: null
  },

]
export const certificatesTypesApostilInCourt = [
  {
    name: 'תרגום נוטריוני',
    varificationtoolTip: '',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-prod.appspot.com/o/sample_certificates%2F%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C%20%D7%91%D7%99%D7%AA%20%D7%9E%D7%A9%D7%A4%D7%98.pdf?alt=media&token=eaa8810c-4bdc-49bb-8498-a6bca8a72ef3'
  },
  {
    name: 'צוואה',
    varificationtoolTip: '',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-prod.appspot.com/o/sample_certificates%2F%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C%20%D7%91%D7%99%D7%AA%20%D7%9E%D7%A9%D7%A4%D7%98.pdf?alt=media&token=eaa8810c-4bdc-49bb-8498-a6bca8a72ef3'
  },
  {
    name: 'חוזה',
    varificationtoolTip: '',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-prod.appspot.com/o/sample_certificates%2F%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C%20%D7%91%D7%99%D7%AA%20%D7%9E%D7%A9%D7%A4%D7%98.pdf?alt=media&token=eaa8810c-4bdc-49bb-8498-a6bca8a72ef3'
  },
  {
    name: 'ייפוי כח',
    varificationtoolTip: '',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-prod.appspot.com/o/sample_certificates%2F%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C%20%D7%91%D7%99%D7%AA%20%D7%9E%D7%A9%D7%A4%D7%98.pdf?alt=media&token=eaa8810c-4bdc-49bb-8498-a6bca8a72ef3'
  },
  {
    name: 'צילום דרכון',
    varificationtoolTip: '',
    isCertificate: false,
    link: 'https://firebasestorage.googleapis.com/v0/b/delawvery-prod.appspot.com/o/sample_certificates%2F%D7%90%D7%A4%D7%95%D7%A1%D7%98%D7%99%D7%9C%20%D7%91%D7%99%D7%AA%20%D7%9E%D7%A9%D7%A4%D7%98.pdf?alt=media&token=eaa8810c-4bdc-49bb-8498-a6bca8a72ef3'
  },
  {
    name: 'אישור נוטריוני אחר',
    isCertificate: false,
    varificationtoolTip: '',
    link: null
  },
]




export const cityOptionsLandRegistration = [
  { id: 1, city: 'תל אביב', fullAddress: "שד' מנחם בגין 125, קריית הממשלה קומה 7", address: 'מנחם בגין 125, תל אביב , ישראל', comments: 'קריית הממשלה', floor: 7, streetNumber: 125, streetAddress: 'מנחם בגין', },
  { id: 2, city: 'ירושלים', fullAddress: 'בן יהודה 34, מגדל העיר, קומה 1', address: 'בן יהודה 34 , ירושלים , ישראל', comments: 'מגדל העיר', floor: 1, streetNumber: 34, streetAddress: 'בן יהודה' },
  { id: 3, city: 'חיפה', fullAddress: "פל-ים 15, א' קריית הממשלה, בניין ב' קומה 13", address: 'פל-ים 15 , חיפה , ישראל', comments: 'קריית הממשלה בניין ב', floor: 13, streetNumber: 15, streetAddress: 'פל-ים' },
  { id: 4, city: 'נצרת-עילית', fullAddress: "דרך קריית הממשלה 2, נוף הגליל (נצרת עילית) קומה 1", address: 'דרך קריית הממשלה 2 , נצרת עילית , ישראל', comments: 'דרך קריית הממשלה 2, קומה 1', floor: 1, streetNumber: 2, streetAddress: 'דרך קריית הממשלה' },
  { id: 5, city: 'באר שבע', fullAddress: "התקווה 4, קריית הממשלה בניין ג' קומה 2", address: 'התקווה 4 , באר שבע , ישראל', comments: 'קריית הממשלה בניין ג', floor: 2, streetNumber: 4, streetAddress: 'התקווה' },
  { id: 6, city: 'חולון', fullAddress: "קדושי קהיר 23, קומה 1", address: 'קדושי קהיר 23 , חולון , ישראל', comments: '', floor: 1, streetNumber: 23, streetAddress: 'קדושי קהיר' },
  { id: 7, city: 'עכו', fullAddress: "שלום הגליל 1, קומה 2 (קניון עכו הישן)", address: 'שלום הגליל 1 , עכו , ישראל', comments: 'קניון עכו הישן', floor: 2, streetNumber: 1, streetAddress: 'שלום הגליל' },
  { id: 8, city: 'נתניה', fullAddress: "ברקת ראובן 3, קומה 6", address: 'ברקת ראובן 3 , נתניה , ישראל', comments: '', floor: 6, streetNumber: 3, streetAddress: 'ברקת ראובן' },
  { id: 9, city: 'אשדוד', fullAddress: "הבנים 14, קניון סי מול, קומה 9", address: 'הבנים 14 , אשדוד , ישראל', comments: 'קניון סי מול', floor: 9, streetNumber: 14, streetAddress: 'הבנים' },
  { id: 10, city: 'פתח תקווה', fullAddress: "ההסתדרות 26, קומה 5, תא דואר 832", address: 'ההסתדרות 26 , פתח תקווה , ישראל', comments: 'תא דואר 832', floor: 5, streetNumber: 26, streetAddress: 'ההסתדרות' },
  { id: 11, city: 'רחובות', fullAddress: "אופנהיימר 10, פארק תמר קומה 5, תא דואר 124", address: 'אופנהיימר 10 , רחובות , ישראל', comments: 'פארק תמר, תא דואר 124', floor: 5, streetNumber: 10, streetAddress: 'אופנהיימר' },
]
export const cityOptionsLandSupervisor = [
  { id: 1, city: 'תל אביב', fullAddress: "שד' מנחם בגין 125, קריית הממשלה קומה 7", address: 'מנחם בגין 125, תל אביב , ישראל', comments: 'קריית הממשלה', floor: 7, streetNumber: 125, streetAddress: 'מנחם בגין', },
  { id: 2, city: 'ירושלים', fullAddress: 'בן יהודה 34, מגדל העיר, קומה 2', address: 'בן יהודה 34 , ירושלים , ישראל', comments: 'מגדל העיר', floor: 2, streetNumber: 34, streetAddress: 'בן יהודה' },
  { id: 3, city: 'חיפה', fullAddress: "פל-ים 15, חיפה (קריית הממשלה, בניין ב' - הטיל), קומה 13", address: 'פל-ים 15 , חיפה , ישראל', comments: 'קריית הממשלה בניין ב', floor: 13, streetNumber: 15, streetAddress: 'פל-ים' },
  { id: 4, city: 'נצרת-עילית', fullAddress: "דרך קריית הממשלה 2, נוף הגליל (נצרת עילית) קומה 1", address: 'דרך קריית הממשלה 2 , נצרת עילית , ישראל', comments: 'דרך קריית הממשלה 2, קומה 1', floor: 1, streetNumber: 2, streetAddress: 'דרך קריית הממשלה' },
  { id: 5, city: 'באר שבע', fullAddress: "התקווה 4, קריית הממשלה בניין ג' קומה 2", address: 'התקווה 4 , באר שבע , ישראל', comments: 'קריית המממשלה בניין ג׳, קומה 2  (ביציאה מהמעלית יש לפנות שמאלה)', floor: 2, streetNumber: 4, streetAddress: 'התקווה' },
  { id: 6, city: 'חולון', fullAddress: "קדושי קהיר 23, קומה 1", address: 'קדושי קהיר 23 , חולון , ישראל', comments: '', floor: 1, streetNumber: 23, streetAddress: 'קדושי קהיר' },
  { id: 7, city: 'עכו', fullAddress: "שלום הגליל 1, קומה 2 (קניון עכו הישן)", address: 'שלום הגליל 1 , עכו , ישראל', comments: 'קניון עכו הישן', floor: 2, streetNumber: 1, streetAddress: 'שלום הגליל' },
  { id: 8, city: 'נתניה', fullAddress: "ברקת ראובן 3, קומה 7", address: 'ברקת ראובן 3 , נתניה , ישראל', comments: '', floor: 7, streetNumber: 3, streetAddress: 'ברקת ראובן' },
  { id: 9, city: 'אשדוד', fullAddress: "הבנים 14, קניון סי מול, קומה 9", address: 'הבנים 14 , אשדוד , ישראל', comments: 'קניון סי מול', floor: 9, streetNumber: 14, streetAddress: 'הבנים' },
  { id: 10, city: 'פתח תקווה', fullAddress: "ההסתדרות 26, קומה 5", address: 'ההסתדרות 26 , פתח תקווה , ישראל', comments: '', floor: 5, streetNumber: 26, streetAddress: 'ההסתדרות' },
  { id: 11, city: 'רחובות', fullAddress: "אופנהיימר 10, פארק תמר קומה 5 ", address: 'אופנהיימר 10 , רחובות , ישראל', comments: 'פארק תמר ', floor: 5, streetNumber: 10, streetAddress: 'אופנהיימר' },
]
export const cityOptionsInheritRegistration = [
  { id: 1, city: 'תל אביב', fullAddress: "השלושה 2, קומות ב-ג", address: 'השלושה 2, תל אביב , ישראל', comments: 'קומות ב-ג, תא דואר 9040', floor: 2, streetNumber: 2, streetAddress: 'השלושה 2', },
  { id: 2, city: 'ירושלים', fullAddress: 'כנפי נשרים 15, קומה 4', address: 'כנפי נשרים 15 , ירושלים , ישראל', comments: 'תא דואר 34176', floor: 4, streetNumber: 15, streetAddress: 'כנפי נשרים 15' },
  { id: 3, city: 'חיפה', fullAddress: "פל-ים 15", address: 'פל-ים 15 , חיפה , ישראל', comments: 'תא דואר 847', floor: 0, streetNumber: 15, streetAddress: 'פל-ים' },
  { id: 4, city: 'נצרת-עילית', fullAddress: "המלאכה 3, נוף הגליל, בניין לב העסקים קומה ב", address: 'המלאכה 3 , נצרת עילית , ישראל', comments: 'בניין לב העסקים', floor: 2, streetNumber: 3, streetAddress: 'המלאכה 3' },
  { id: 5, city: 'באר שבע', fullAddress: "התקווה 4", address: 'התקווה 4 , באר שבע , ישראל', comments: 'תא דואר 965', floor: 0, streetNumber: 4, streetAddress: 'התקווה 4' },
]
export const cityOptionsCompaniesRegistration = [
  { id: 1, city: 'תל אביב', fullAddress: "השלושה 2, קומה 4", address: 'השלושה 2, תל אביב , ישראל', comments: "קומה 4 (בית מוסקוביץ')", floor: 4, streetNumber: 2, streetAddress: 'השלושה 2', },
  { id: 2, city: 'ירושלים', fullAddress: "ירמיהו 39, מגדלי הבירה, בניין א', קומה 10", address: ' ירמיהו 39 , ירושלים , ישראל', comments: "'בניין א", floor: 10, streetNumber: 39, streetAddress: 'ירמיהו 39' },
  { id: 3, city: 'חיפה', fullAddress: "פל-ים 15, בניין ב', קומה 10", address: 'פל-ים 15 , חיפה , ישראל', comments: 'תא דואר 847', floor: 10, streetNumber: 15, streetAddress: 'פל-ים' },
  { id: 4, city: 'נצרת-עילית', fullAddress: "המלאכה 3, נוף הגליל, בניין לב העסקים קומה 3", address: 'המלאכה 3 , נצרת עילית , ישראל', comments: 'בניין לב העסקים', floor: 3, streetNumber: 3, streetAddress: 'המלאכה 3' },
  { id: 5, city: 'באר שבע', fullAddress: "קרן היסוד 4, קומה 7", address: 'קרן היסוד 4 , באר שבע , ישראל', comments: '', floor: 7, streetNumber: 4, streetAddress: 'קרן היסוד 4' },
]
export const cityOptionsPartnershipsRegistration = [
  { id: 1, city: 'תל אביב', fullAddress: "השלושה 2, קומה 4", address: 'השלושה 2, תל אביב , ישראל', comments: "קומה 4 (בית מוסקוביץ')", floor: 4, streetNumber: 2, streetAddress: 'השלושה 2', },
  { id: 2, city: 'ירושלים', fullAddress: "ירמיהו 39, מגדלי הבירה, בניין 1, קומה 10", address: ' ירמיהו 39 , ירושלים , ישראל', comments: "תא דואר 28178", floor: 10, streetNumber: 39, streetAddress: 'ירמיהו 39' },
]
export const cityOptionsAssociationsAndCompanies = [
  { id: 1, city: 'תל אביב', fullAddress: "השלושה 2, קומה 4", address: 'השלושה 2, תל אביב , ישראל', comments: "קומה 4 (בית מוסקוביץ')", floor: 4, streetNumber: 2, streetAddress: 'השלושה 2', },
  { id: 2, city: 'ירושלים', fullAddress: "ירמיהו 39, מגדלי הבירה, בניין 1, קומה 10", address: ' ירמיהו 39 , ירושלים , ישראל', comments: "תא דואר 34071", floor: 10, streetNumber: 39, streetAddress: 'ירמיהו 39' },
  { id: 3, city: 'חיפה', fullAddress: "פל-ים 15, בניין ב', קומה 10", address: 'פל-ים 15 , חיפה , ישראל', comments: "בניין ב'", floor: 10, streetNumber: 15, streetAddress: 'פל-ים' },
  { id: 4, city: 'נצרת-עילית', fullAddress: "המלאכה 3, נוף הגליל, בניין 2 - לב העסקים, קומה 3", address: 'המלאכה 3 , נצרת עילית , ישראל', comments: 'בניין 2 - לב העסקים', floor: 3, streetNumber: 3, streetAddress: 'המלאכה 3' },
  { id: 5, city: 'באר שבע', fullAddress: "קרן היסוד 4, קומה 7", address: 'קרן היסוד 4 , באר שבע , ישראל', comments: '', floor: 7, streetNumber: 4, streetAddress: 'קרן היסוד 4' },
]

export const optionsCollectCourtSubmit = [
  { documentName: 'פסק דין מאושר', caseNumber: '', docType: 'פסק דין מאושר' },
  { documentName: 'החלטה', caseNumber: '', docType: 'החלטה' },
  { documentName: 'צו עיקול צד ג׳', caseNumber: '', docType: 'צו עיקול צד ג׳' },
  { documentName: 'ערובה', caseNumber: '', docType: 'ערובה', },
];
export const optionsNonCollectCourtSubmit = [
  { documentName: 'כתב תביעה', caseNumber: '', docType: 'כתב תביעה' },
  { documentName: 'כתב הגנה', caseNumber: '', docType: 'כתב הגנה' },
  { documentName: 'כתב תשובה', caseNumber: '', docType: 'כתב תשובה' },
  {
    documentName: 'כתב תביעה שכנגד',
    caseNumber: '',
    docType: 'כתב תביעה שכנגד',
  },
  {
    documentName: 'כתב תשובה שכנגד',
    caseNumber: '',
    docType: 'כתב תשובה שכנגד',
  },
  {
    documentName: 'כתב הגנה שכנגד',
    caseNumber: '',
    docType: 'כתב הגנה שכנגד',
  },
  {
    documentName: 'תצהירי עדות ראשית',
    caseNumber: '',
    docType: 'תצהירי עדות ראשית',
  },
  { documentName: 'ערעור', caseNumber: '', docType: 'ערעור' },
  {
    documentName: 'בקשת רשות ערעור',
    caseNumber: '',
    docType: 'בקשת רשות ערעור',
  },
  { documentName: 'עיקרי טיעון', caseNumber: '', docType: 'עיקרי טיעון' },
  { documentName: 'תיק מוצגים', caseNumber: '', docType: 'תיק מוצגים' },
];
export const documentsOptionsPersonalDelivery = [
  { documentName: 'אזהרה', caseNumber: '', docType: 'אזהרה' },
  { documentName: 'התראה לפני הגשת תובענה על סכום קצוב', caseNumber: '', docType: 'התראה לפני הגשת תובענה על סכום קצוב' },
  { documentName: 'החלטה', caseNumber: '', docType: 'החלטה' },
  { documentName: 'בקשה + החלטה', caseNumber: '', docType: 'בקשה + החלטה' },
  { documentName: 'כתב תביעה', caseNumber: '', docType: 'כתב תביעה' },
  { documentName: 'כתב תביעה + הזמנה לדין', caseNumber: '', docType: 'כתב תביעה + הזמנה לדין' },
  { documentName: 'כתב הגנה', caseNumber: '', docType: 'כתב הגנה' },
  { documentName: 'כתב תשובה', caseNumber: '', docType: 'כתב תשובה' },
  { documentName: 'הודעה למחזיק על עיקולים', caseNumber: '', docType: 'הודעה למחזיק על עיקולים' },
  { documentName: ' תצהירי עדות ראשית', caseNumber: '', docType: ' תצהירי עדות ראשית' },
  { documentName: 'מכתב התראה', caseNumber: '', docType: 'מכתב התראה' },
  { documentName: 'מכתב דרישה לתשלום', caseNumber: '', docType: 'מכתב דרישה לתשלום' },
  { documentName: 'בקשת רשות ערעור (בר"ע)', caseNumber: '', docType: 'בקשת רשות ערעור (בר"ע)' },
  { documentName: 'ערעור', caseNumber: '', docType: 'ערעור' },
]
export const documentsOptionsOfficeDelivery = [
  { documentName: 'החלטה', caseNumber: '', docType: 'החלטה' },
  { documentName: 'בקשה + החלטה', caseNumber: '', docType: 'בקשה + החלטה' },
  { documentName: 'כתב תביעה', caseNumber: '', docType: 'כתב תביעה' },
  { documentName: 'כתב תביעה + הזמנה לדין', caseNumber: '', docType: 'כתב תביעה + הזמנה לדין' },
  { documentName: 'כתב הגנה', caseNumber: '', docType: 'כתב הגנה' },
  { documentName: 'כתב תשובה', caseNumber: '', docType: 'כתב תשובה' },
  { documentName: 'הודעה למחזיק על עיקולים', caseNumber: '', docType: 'הודעה למחזיק על עיקולים' },
  { documentName: ' תצהירי עדות ראשית', caseNumber: '', docType: ' תצהירי עדות ראשית' },
  { documentName: 'מכתב התראה', caseNumber: '', docType: 'מכתב התראה' },
  { documentName: 'מכתב דרישה לתשלום', caseNumber: '', docType: 'מכתב דרישה לתשלום' },
  { documentName: 'בקשת רשות ערעור (בר"ע)', caseNumber: '', docType: 'בקשת רשות ערעור (בר"ע)' },
  { documentName: 'ערעור', caseNumber: '', docType: 'ערעור' },
]


export const documentsOptionsInheritRegistration = [
  // { documentName: '', docType: 'אחר', upload: true, isDeposit: true, isToll: false },
  { documentName: 'בקשה למתן צו ירושה', docType: 'בקשה למתן צו ירושה', toll: 513, upload: true, isDeposit: true, isToll: false, tooltip: 'על המבקשים להיות יורשיהם של הנפטרים, או יורשים של יורשים שנפטרו לאחר המורישים. לחצו כאן לבדיקת מסמכים נדרשים', link: 'https://www.gov.il/he/service/inheritance_order' },
  { documentName: 'בקשה לצו קיום צוואה', docType: 'בקשה לצו קיום צוואה', toll: 513, upload: false, isDeposit: true, isToll: false, tooltip: ' על המבקשים להיות זכאים על פי הכתוב בצוואה. חובה לצרף צוואה מקורית. לחצו כאן לבדיקת מסמכים נדרשים', link: 'https://www.gov.il/he/service/probate_order' },
  { documentName: 'הפקדת צוואה מקורית', docType: 'הפקדת צוואה מקורית', toll: 0, upload: false, isDeposit: true, isToll: false, tooltip: 'הפקדת צוואה מקורית ניתן לבצע רק בימי קבלת קהל ובזימון תור מראש' },
  { documentName: 'בקשה למינוי מנהל עיזבון', docType: 'בקשה למינוי מנהל עיזבון', toll: 513, upload: true, isDeposit: true, isToll: false, tooltip: 'המבקשים הינם מהיורשים המופיעים בצו הירושה או אחד מהזכאים על פי הצוואה. לחצו כאן לבדיקת מסמכים נדרשים.', link: 'https://www.gov.il/he/service/estate_manager_nomination' },
  { documentName: 'התנגדות למתן צו קיום צוואה', docType: 'התנגדות למתן צו קיום צוואה', toll: 986, upload: true, isDeposit: true, isToll: false, tooltip: 'התנגדות למתן צו קיום צוואה הינה מסמך כתוב, המוגש לרשם לענייני ירושה במטרה למנוע מתן צו קיום צוואה. ניתן להגיש התנגדות לאחר שהוגשה בקשה למתן צו קיום צוואה וטרם הוצאתו של צו קיום צוואה. לחצו כאן לבדיקת מסמכים נדרשים.', link: 'https://www.gov.il/he/service/probate_objection' },
  {
    documentName: 'התנגדות למתן צו ירושה', docType: 'התנגדות למתן צו ירושה', toll: 986, upload: true, isDeposit: true, isToll: false, tooltip: `עליכם לנסח מסמך בקשה להתנגדות למתן צו ירושה אשר יכיל את המידע הבא:
  שם ות.ז המתנגד. 
  שם ותעודת זהות המנוח.    
  שמות ופרטי תעודת זהות של כל המעורבים בדבר (כל היורשים המופיעים בצו הירושה).
  ההתנגדות עצמה ומסמכים התומכים בה (במידת הצורך).   
  עליכם להגיש תצהיר התומך בעובדות המפורטות בהתנגדות ,אשר נערך ואומת על ידי עורך דין.
  עליכם להגיש אישור או קבלה על תשלום אגרה.
  עליכם להגיש ייפוי כוח, אם המתנגד מיוצג על ידי עורך דין.
  לחצו כאן לבדיקת מסמכים נדרשים.`, link: 'https://www.gov.il/he/service/opposition_to_the_inheritance_order'
  },
  { documentName: 'בקשה לביטול צו קיום צוואה', docType: 'בקשה לביטול צו קיום צוואה', toll: 513, upload: true, isDeposit: true, isToll: false, tooltip: 'על המבקש להיות אחד מהזכאים על-פי הצוואה או מי שנפגע ממתן הצו. לחצו כאן לבדיקת מסמכים נדרשים.', link: 'https://www.gov.il/he/service/probate_order_cancelation' },
  { documentName: 'בקשה לביטול צו ירושה', docType: 'בקשה לביטול צו ירושה', toll: 513, upload: true, isDeposit: true, isToll: false, tooltip: 'על המבקש להיות אחד מהיורשים המופיעים בצו הירושה או מי שנפגעו ממתן הצו. לחצו כאן לבדיקת מסמכים נדרשים.', link: 'https://www.gov.il/he/service/inheritance_order_cancellation' },
  { documentName: 'בקשה לתיקון צו קיום צוואה', docType: 'בקשה לתיקון צו קיום צוואה', toll: 513, upload: true, isDeposit: true, isToll: false, tooltip: 'המבקש לתקן את צו קיום הצוואה הינו אחד מהזכאים לרשת על פי הצוואה, או מי שרואה עצמו נפגע ממתן הצו. לחצו כאן לבדיקת מסמכים נדרשים.', link: 'https://www.gov.il/he/service/probate_order_amendment' },
  { documentName: 'הגשת בקשה מתוקנת', docType: 'הגשת בקשה מתוקנת', toll: 240, upload: true, isDeposit: true, isToll: false, tooltip: 'על המבקש לצרף את מכתב הדחיה.' },
]

export const documentsOptionsCompaniesRegistration = [
  { documentName: 'בקשה לרישום חברה', docType: 'בקשה לרישום חברה', toll: 2711, upload: false, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר לרשום חברה במרשם רשם החברות. הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/company_registration' },
  { documentName: 'דיווח שנתי לחברה', docType: 'דיווח שנתי לחברה', toll: 0, upload: false, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר להגיש דו"ח שנתי לרשם החברות. הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/companies_annual_report' },
  { documentName: 'דיווח על שינויים במניות החברה', docType: 'דיווח על שינויים במניות החברה', toll: 0, upload: false, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר לדווח על שינויים שבוצעו במניות החברה: העברת מניות, הקצאת מניות, הגדלה, שינוי או ביטול של הון המניות הרשום והקטנת הון המניות המוקצה. הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/shares_reports' },
  { documentName: 'פירוק חברה', docType: 'פירוק חברה', toll: 0, upload: false, isDeposit: false, isToll: false, tooltip: 'השירות מאפשר לפרק חברה פרטית, חברת יחיד, חברה לתועלת הציבור וחברה זרה הרשומה ברשם החברות. הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/liquidation-of-company' },
  // { documentName: '', docType: 'אחר', upload: true, isDeposit: true, isToll: false },
]

export const documentsOptionsPartnershipsRegistration = [
  { documentName: 'רישום שותפות כללית/זרה כולל פרסום ברשומות', docType: 'רישום שותפות כללית/זרה כולל פרסום ברשומות', toll: 988, upload: false, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר לרשום שותפות כללית, שותפות מוגבלת או שותפות חוץ, ברשם השותפויות. הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/partnership_registration' },
  { documentName: 'רישום שותפות זרה/מוגבלת', docType: 'רישום שותפות זרה/מוגבלת', toll: 2701, upload: false, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר לרשום שותפות כללית, שותפות מוגבלת או שותפות חוץ, ברשם השותפויות. הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/partnership_registration' },
  { documentName: 'פירוק שותפות', docType: 'פירוק שותפות', toll: 0, upload: false, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר לפרק שותפות רשומה. הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/partnership_liquidation' },
  { documentName: 'שינוי בפרטי שותפות כללית או שותפות מוגבלת', docType: 'שינוי בפרטי שותפות כללית או שותפות מוגבלת', toll: 0, upload: false, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר לשנות פרטי שותפות כללית או שותפות מוגבלת הרשומה ברשם השותפויות. הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/general_or_limited_partnership_details_change' },
  { documentName: 'רישום שם עסק', docType: 'רישום שם עסק', toll: 270, upload: true, isDeposit: true, isToll: false, tooltip: 'שירות זה מאפשר לרשום שם עסק קיים.', link: 'https://www.gov.il/he/service/business_name_registration' },
  { documentName: 'שינוי בפרטי שותפות חוץ כללית או שותפות חוץ מוגבלת', docType: 'שינוי בפרטי שותפות חוץ כללית או שותפות חוץ מוגבלת', toll: 0, upload: false, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר לשנות פרטי שותפות חוץ הרשומה ברשם השותפויות. הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/foreign_partnership_details_change' },
  // { documentName: '', docType: 'אחר', upload: true, isDeposit: true, isToll: false },
]

export const documentsOptionsAssociationsAndCompanies = [
  { documentName: 'בקשה לרישום עמותה לתועלת הציבור', docType: 'בקשה לרישום עמותה לתועלת הציבור', toll: 841, upload: false, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר להגיש בקשה לרישום עמותה לתועלת הציבור. הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/association_registration' },
  { documentName: 'בקשה לרישום חברה לתועלת הציבור', docType: 'בקשה לרישום חברה לתועלת הציבור', toll: 775, upload: false, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר להגיש בקשה לרישום חברה לתועלת הציבור (חל"צ). הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/association_registration' },
  { documentName: 'בקשה לשינוי תקנון עמותה', docType: 'בקשה לשינוי תקנון עמותה', toll: 0, upload: false, isDeposit: true, isToll: false, tooltip: 'הנכם נדרשים לצרף מסמכים מקוריים בלבד. למידע בדבר המסמכים הנדרשים לחצו על הקישור.', link: 'https://www.gov.il/he/service/association_articles_of_association_change' },
  { documentName: 'שינוי כתובת עמותה', docType: 'שינוי כתובת עמותה', toll: 0, upload: true, isDeposit: true, isToll: false, tooltip: 'השירות מאפשר לבצע דיווח על שינוי כתובת של עמותה.', link: 'https://www.gov.il/he/service/association_address_change' },
  // { documentName: '', docType: 'אחר', upload: true, isDeposit: true, isToll: false },
]

export const cityOptionsClaimTypes = [
  { id: 1, claimType: 'ספק לקוח', image: '/images/smallClaimIcons/relMoney.svg', whiteImage: '/images/smallClaimIcons/whiteRelMoney.svg', altImage: 'relationship-money', tooltip: 'בדיקה' },
  { id: 2, claimType: 'רכב', image: '/images/smallClaimIcons/crash.svg', whiteImage: '/images/smallClaimIcons/whiteCrash.svg', altImage: 'crash', tooltip: 'בדיקה' },
  { id: 3, claimType: 'תיירות', image: '/images/smallClaimIcons/airplane.svg', whiteImage: '/images/smallClaimIcons/whiteAirplane.svg', altImage: 'airplane', tooltip: 'בדיקה' },
  { id: 4, claimType: 'שכירות', image: '/images/smallClaimIcons/apartment.svg', whiteImage: '/images/smallClaimIcons/whiteApartment.svg', altImage: 'apartment', tooltip: 'בדיקה' },
  { id: 5, claimType: 'תביעת ספאם', image: '/images/smallClaimIcons/spam.svg', whiteImage: '/images/smallClaimIcons/whiteSpam.svg', altImage: 'spam', tooltip: 'בדיקה' },
  { id: 6, claimType: 'כספית נזקי גוף', image: '/images/smallClaimIcons/injury.svg', whiteImage: '/images/smallClaimIcons/whiteInjury.svg', altImage: 'injury', tooltip: 'בדיקה' },
  { id: 7, claimType: 'הפליה', image: '/images/smallClaimIcons/discrimination.svg', whiteImage: '/images/smallClaimIcons/whiteDiscrimination.svg', altImage: 'discrimination', tooltip: 'בדיקה' },
  { id: 8, claimType: 'כל נושא אחר', image: '/images/smallClaimIcons/plus.svg', whiteImage: '/images/smallClaimIcons/whitePlus.svg', altImage: 'plus', tooltip: 'בדיקה' },
]

export const claimCourtsOptions = [
  { courtName: 'אילת', city: 'אילת', streetAddress: 'דרך יותם', streetNumber: '3', courtStreetStr: 'דרך יותם 3, אילת', district: 'south' },
  { courtName: 'אשדוד', city: 'אשדוד', streetAddress: 'מורדי הגטאות', streetNumber: '1', courtStreetStr: 'מורדי הגטאות 1, אשדוד', district: 'south' },
  { courtName: 'אשקלון', city: 'אשקלון', streetAddress: 'שדרות בן גוריון', streetNumber: '19', courtStreetStr: 'שדרות בן גוריון 19, אשקלון', district: 'south' },
  { courtName: 'באר שבע', city: 'באר שבע', streetAddress: 'התקווה', streetNumber: '5', courtStreetStr: 'התקווה 5, באר שבע', district: 'south' },
  { courtName: 'דימונה', city: 'דימונה', streetAddress: 'שדרות בן גוריון', streetNumber: '1', courtStreetStr: 'שדרות בן גוריון 1, דימונה', district: 'south' },
  { courtName: 'קריית גת', city: 'קריית גת', streetAddress: 'חשוון', streetNumber: '12', courtStreetStr: 'חשוון 12, קריית גת', district: 'south' },
  { courtName: 'כפר סבא', city: 'כפר סבא', streetAddress: 'הטחנה', streetNumber: '5', courtStreetStr: 'הטחנה 5, כפר סבא', district: 'central' },
  { courtName: 'נתניה', city: 'נתניה', streetAddress: 'הרצל', streetNumber: '57', courtStreetStr: 'הרצל 57, נתניה', district: 'central' },
  { courtName: 'פתח תקווה', city: 'פתח תקווה', streetAddress: 'בזל', streetNumber: '1', courtStreetStr: 'בזל 1, פתח תקווה', district: 'central' },
  { courtName: 'ראשון לציון', city: 'ראשון לציון', streetAddress: 'ישראל גלילי', streetNumber: '5', courtStreetStr: 'ישראל גלילי 5, ראשון לציון', district: 'central' },
  { courtName: 'רחובות', city: 'רחובות', streetAddress: 'רוז׳נסקי', streetNumber: '9', courtStreetStr: 'רוז׳נסקי 9, רחובות', district: 'central' },
  { courtName: 'רמלה', city: 'רמלה', streetAddress: 'שדרות ויצמן', streetNumber: '3', courtStreetStr: 'שדרות ויצמן 3, רמלה', district: 'central' },
  { courtName: 'בת ים', city: 'בת ים', streetAddress: 'הרב ניסנבוים יצחק', streetNumber: '7', courtStreetStr: 'הרב ניסנבוים יצחק 7, בת ים', district: 'telAviv' },
  { courtName: 'תל אביב - ויצמן', city: 'תל אביב', streetAddress: 'ויצמן', streetNumber: '1', courtStreetStr: 'ויצמן 1, תל אביב', district: 'telAviv' },
  { courtName: 'תל אביב - שוקן', city: 'תל אביב', streetAddress: 'שוקן', streetNumber: '25', courtStreetStr: 'שוקן 25, תל אביב', district: 'telAviv' },
  { courtName: 'הרצליה', city: 'הרצליה', streetAddress: 'בן גוריון', streetNumber: '31', courtStreetStr: 'בן גוריון 31, הרצליה', district: 'telAviv' },
  { courtName: 'ירושלים', city: 'ירושלים', streetAddress: 'חשין', streetNumber: '6', courtStreetStr: 'חשין 6, ירושלים)', district: 'jerusalem' },
  { courtName: 'בית שמש', city: 'בית שמש', streetAddress: 'הרצל', streetNumber: '9', courtStreetStr: 'הרצל 9, בית שמש', district: 'jerusalem' },
  { courtName: 'חדרה', city: 'חדרה', streetAddress: 'הלל יפה', streetNumber: '7א׳', courtStreetStr: 'הלל יפה 7א׳ חדרה', district: 'haifa' },
  { courtName: 'חיפה', city: 'חיפה', streetAddress: 'שדרות הפלי״ם', streetNumber: '', courtStreetStr: 'שדרות הפלי״ם, חיפה', district: 'haifa' },
  { courtName: 'עכו', city: 'עכו', streetAddress: 'יהושפט', streetNumber: '15', courtStreetStr: 'יהושפט 15, עכו', district: 'haifa' },
  { courtName: 'קריות', city: 'קריית ביאליק', streetAddress: 'דרך עכו', streetNumber: '194', courtStreetStr: 'דרך עכו 194 קריית ביאליק', district: 'haifa' },
  { courtName: 'בית שאן', city: 'בית שאן', streetAddress: 'שאול המלך', streetNumber: '31', courtStreetStr: 'שאול המלך 31, בית שאן', district: 'north' },
  { courtName: 'טבריה', city: 'טבריה', streetAddress: 'חשמונאים', streetNumber: '3', courtStreetStr: 'חשמונאים 3, טבריה', district: 'north' },
  { courtName: 'מסעדה', city: 'מסעדה', streetAddress: 'מול בית הדואר בכפר מסעדה', streetNumber: '', courtStreetStr: 'מול בית הדואר בכפר מסעדה', district: 'north' },
  { courtName: 'נצרת', city: 'נצרת', streetAddress: 'כיכר קריית יצחק רבין נוף הגליל', streetNumber: '', courtStreetStr: 'כיכר קריית יצחק רבין נוף הגליל, נצרת עילית', district: 'north' },
  { courtName: 'עפולה', city: 'עפולה', streetAddress: 'מנחם אוסישקין', streetNumber: '42', courtStreetStr: 'מנחם אוסישקין 42, עפולה', district: 'north' },
  { courtName: 'צפת', city: 'צפת', streetAddress: 'מרום כנען', streetNumber: '', courtStreetStr: 'מרום כנען צפת', district: 'north' },
  { courtName: 'קצרין', city: 'קצרין', streetAddress: 'שיאון', streetNumber: '1', courtStreetStr: 'שיאון 1, קצרין', district: 'north' },
  { courtName: 'קריית שמונה', city: 'קריית שמונה', streetAddress: 'שדרות תל-חי', streetNumber: '97', courtStreetStr: 'שדרות תל-חי 97, קריית שמונה', district: 'north' },
];

export const deliveryPackageOptions = [
  {
    title: 'מעטפה',
    description: 'בגודל של A4 כמו כריכה, מקבץ מעטפות, קלסר אחד',
    price: 0,
    img: 'VerySmallPackage',
    imgWhite: 'WhiteVerySmallPackage',
    name: 'isVerySmallPackage',
  },
  {
    title: 'קלסר',
    description: 'בגודל 21/23/31 כמו תיקי דיון, 2 קלסים, תיק קטן',
    price: 20,
    img: 'SmallPackage',
    imgWhite: 'WhiteSmallPackage',
    name: 'isSmallPackage',
  },
  {
    title: 'ארגז',
    description: 'בגודל 26/31/40 כמו ארגז בית גנזים',
    price: 30,
    img: 'BigPackage',
    imgWhite: 'WhiteBigPackage',
    name: 'isBigPackage',
  },
  {
    title: 'צריך רכב',
    description: 'השליחות מתאימה לרכב (יותר מארגז אחד, משלוח כבד, וכו׳)',
    price: 0,
    img: 'DeliveryWithCar',
    imgWhite: 'WhiteDeliveryWithCar',
    name: 'isDeliveryWithCar',
  }
]

export const deliveryUploadOptions = [
  {
    title: 'כריכת ספירלה',
    description: 'הכריכה כוללת עמוד שקוף מקדימה ועמוד אחורי לבן',
    price: null,
    img: 'SpiralBinding',
    imgWhite: 'WhiteSpiralBinding',
    name: 'isSpiralBinding',
  },
  {
    title: 'דגלונים',
    description: 'דגלונים להתחלת כל נספח. רישום מספרים או אותיות לפי הקובץ המצורף',
    price: null,
    img: 'Dividers',
    imgWhite: 'WhiteDividers',
    name: 'isDividers',
  },
  {
    title: 'פס תיוק',
    description: 'מתאים בשביל לתייק את הכריכה בקלסר משרדי',
    price: null,
    img: 'BindingStrip',
    imgWhite: 'WhiteBindingStrip',
    name: 'isBindingStrip',
  },
]

export const distantRegions = [
  "טירה",
  "טייבה",
  "כפר קאסם",
  "ג'דיידה-מכר",
  "אום אל-פחם",
  "חורה",
  "כפר מנדא",
  "יפיע",
  "טמרה",
  "עראבה",
  "דיר חנא",
  "דריג'את",
  "רהט",
  "דבוריה",
  "סחנין",
  "סח׳נין",
  "סח'נין",
  "ערערה",
  "ג'לג'וליה",
  "כפר קרע",
  "קלנסווה",
  "בוקעאתא",
  "אעבלין",
  "כפר זמר",
  "אל סייד",
  "לקיה",
  "תל שבע",
  "כפר כנא",
  "תקוע",
  "שגב-שלום",
  "עספיא",
  "איכסאל",
  "באקה אל-גרביה",
  "דייר אל-אסד",
  "סעוה",
  "כאבול",
  "נריה",
  "טלמון",
  "ריחן",
  "שקד",
  "חיננת",
  "טל מנשה",
  "אריאל",
  "ברקן"
]

export const distantNeighborhoodsInJerusalem = [
  "סילוואן",
  "עיסוויה",
  "עיסאוויה",
  "שועפאט"
]