import React, { useEffect, useState, useRef } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Field } from 'formik';
import arrow from '../../../images/dropdown-arrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateSystemData, updateContactToSave, updateSecondContactToSave } from '../../../store/actions/systemActions';
import { utilService } from '../../../services/utilService'
import ContactListPerson from '../../Utils/ContactListPerson'
import { TooltipTriangle } from '../../Utils/TooltipTriangle'
import cities2 from "../../../consts/cities";
import streetRes2 from "../../../consts/streets";

export const PersonalAddress = ({ data, setData, isError, errors, handleChanges, setTouch, touch }) => {
  const [personState, setPersonState] = useState({
    id: data?.returnAddress?.id || utilService.makeId(),
    type: data?.returnAddress?.type || 'ADAM_PRATI',
    [`${data?.returnAddress?.type === 'HEVRA' ? 'companyName' : (data?.returnAddress?.type === 'officeDelivery' ? 'officeName' : 'personName')}`]: data?.returnAddress?.personName || data?.returnAddress?.companyName || data?.returnAddress?.officeName || '',
    [`${data?.returnAddress?.type === 'HEVRA' ? 'companyPhone' : (data?.returnAddress?.type === 'officeDelivery' ? 'officePhone' : 'personPhone')}`]: data?.returnAddress?.personPhone || data?.returnAddress?.companyPhone || data?.returnAddress?.officePhone || '',
    city: data?.returnAddress?.returnCity || '',
    streetAddress: data?.returnAddress?.returnStreetAddress || '',
    streetNumber: data?.returnAddress?.returnStreetNumber || '',
    floor: data?.returnAddress?.floor || '',
    apartmentNumber: data?.returnAddress?.apartmentNumber || '',
    comments: data?.returnAddress?.comments || '',
    isAddressNotFound: data?.returnAddress?.isAddressNotFound || false
  });

  const activeUser = useSelector((state) => state.userReducer.user);
  const systemData = useSelector((state) => state.systemReducer.systemData);
  const dispatch = useDispatch();
  const [searchCity, setSearchCity] = useState(data?.returnAddress?.city || '');
  const [searchStreet, setSearchStreet] = useState(data?.returnAddress?.streetAddress || '');
  const [isCitiesDropdownOpen, setIsCitiesDropdownOpen] = useState(false);
  const [isStreetsDropdownOpen, setIsStreetsDropdownOpen] = useState(false);
  const [israelCities, setIsraelCities] = useState([])
  const [cityStreets, setCityStreets] = useState([])
  const refCity = useRef()
  const refStreet = useRef()
  const phoneRegex = /^\d{9,10}$/;
  //useEffect For documentTransfer
  useEffect(() => {
    // const {
    //   city,
    //   streetAddress,
    //   streetNumber,
    //   apartmentNumber,
    //   notes,
    //   ...remainingParams
    // } = formState.currRecipient || {};

    setData({
      ...data,
      returnAddress: {
        // ...data.returnAddress,
        ...personState,
        returnStreetAddress: personState.streetAddress || '',
        returnStreetNumber: personState.streetNumber || '',
        returnCity: personState.city || '',
        apartment: personState.apartmentNumber || '',
        returnNotes: personState.notes || ''
      },
    });
    updateContact()
    setSearchCity(personState?.city || '')
    setSearchStreet(personState.streetAddress || '')
  }, [personState]);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (refCity?.current?.contains(event.target) || refStreet?.current?.contains(event.target)) {
        return;
      }
      setIsCitiesDropdownOpen(false);
      setIsStreetsDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);

  useEffect(() => {
    if (isCitiesDropdownOpen) {
      getCities()
    } else {
      setIsraelCities([])
    }
    if (isStreetsDropdownOpen && data?.returnAddress?.city) {
      getStreetsOfCity(data?.returnAddress?.city)
    } else {
      setCityStreets([])
    }
  }, [isCitiesDropdownOpen, isStreetsDropdownOpen]);

  const onBlur = () => {
    if (personState?.city !== searchCity) {
      setSearchCity(personState?.city ? personState?.city : '')
    }
    if (personState?.streetAddress !== searchStreet) {
      setSearchStreet(personState?.streetAddress ? personState?.streetAddress : '')
    }
    setIsCitiesDropdownOpen(false)
    setIsStreetsDropdownOpen(false)
  }

  const getCities = async () => {
    try {
      const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`, { signal: AbortSignal.timeout(5000) })
      const cities = await cityRes.json()
      const citiesAfterSwitch = switchBrackets(cities.result.records)
      setIsraelCities(citiesAfterSwitch)
    } catch (err) {
      const cities2 = require("../../../consts/cities")
      const citiesAfterSwitch2 = switchBrackets(cities2.result.records)
      setIsraelCities(citiesAfterSwitch2)
      console.log('Fetch error: ', err)
    }
  }

  const switchBrackets = (cities) => {
    const switchBracketsMap = {
      '(': ")",
      ')': "(",
    };

    return cities.map(city => {
      return {
        'שם_ישוב': city['שם_ישוב'].trim().replace(/\(|\)/gi, function (matched) {
          return switchBracketsMap[matched];
        })
      }
    })
  }

  const getStreetsOfCity = async (cityName) => {
    try {
      const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${cityName}`, { signal: AbortSignal.timeout(5000) })
      const StreetsOfCity = await streetRes.json()
      setCityStreets(StreetsOfCity.result.records)
    } catch (err) {
      const streetRes2 = require("../../../consts/streets")
      setCityStreets(streetRes2.result.records.filter((rec) => rec['שם_ישוב'] == cityName))
      console.log('Fetch error: ', err)
    }
  }

  const onSetChoose = (field, value) => {
    setPersonState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setTouch({ ...touch, [field]: true });
  }

  const undoChanges = () => {
    setSearchStreet('')
    setPersonState((prevState) => ({
      ...prevState,
      streetAddress: '',
    }));
  }

  const handleIsAddressNotFound = () => {
    setPersonState((prevState) => ({
      ...prevState,
      isAddressNotFound: !prevState.isAddressNotFound,
    }))
  }

  const getFormType = (type) => {
    switch (type) {
      case 'ADAM_PRATI':
        return ['personId', 'personName', 'personPhone']
      case 'HEVRA':
        return ['companyId', 'companyName', 'companyPhone']
      case 'officeDelivery':
        return ['officeId', 'officeName', 'officePhone']
      default:
        break;
    }
  }

  const onSetContact = (contact) => {
    setPersonState((prevState) => ({
      // ...prevState,
      id: contact.id,
      // personName: contact.name || '',
      // personPhone: contact?.phone || '',

      // [`${contact.type === 'HEVRA' ? 'companyName' : 'personName'}`]: contact.name || '',
      // [`${contact.type === 'HEVRA' ? 'companyPhone' : 'personPhone'}`]: contact.phone || '',

      [getFormType(contact.type)[1]]: contact.name || '',
      [getFormType(contact.type)[2]]: contact.phone || '',

      city: contact.city || '',
      streetAddress: contact.streetAddress || '',
      streetNumber: contact.streetNumber || '',
      comments: contact.comments || '',
      floor: contact.floor || '',
      apartmentNumber: contact.apartmentNumber || '',
      isAddressNotFound: contact.isAddressNotFound || false,
      type: contact?.type || 'ADAM_PRATI',
    }));
  }

  const handleIsSaveContactSecond = () => {
    dispatch(updateSystemData({
      ...systemData,
      isSaveContactSecond: !systemData.isSaveContactSecond
    }));
  }
  const updateContact = () => {
    // const contactName = personState?.personName || personState?.companyName || personState?.officeName
    // const phone = personState?.personPhone || personState?.companyPhone || personState?.officePhone
    const contactName = personState[getFormType(personState?.type)[1]] || ''
    const phone = personState[getFormType(personState?.type)[2]] || ''

    const contact = {
      id: personState?.id || utilService.makeId(),
      name: contactName || '',
      phone: phone || '',
      city: personState?.city || '',
      streetAddress: personState?.streetAddress || '',
      streetNumber: personState?.streetNumber || '',
      comments: personState?.comments || '',
      floor: personState?.floor || '',
      apartmentNumber: personState?.apartmentNumber || '',
      isAddressNotFound: personState?.isAddressNotFound || false,
      type: personState?.type || 'ADAM_PRATI'
    }
    dispatch(updateSecondContactToSave(contact));
  }
  const handleChangeData = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setPersonState((prevState) => ({
      ...prevState,
      [field]: value
    }));

    handleChanges(event);
    setTouch({ ...touch, [field]: true });
  };

  return (
    <div className='form7-personal-company personal-delivery main-form high update-cmps self-form-style'>
      <div className='modal-content-container'>
        <h2 className='large-title'>
          כתובת יעד למסירת המסמכים
        </h2>
        <h3 className='small'>פרטים</h3>

        <div className='input-container'>

          {activeUser?.userContacts && activeUser?.userContacts?.length ? <ContactListPerson handleChangeData={handleChangeData} data={data} currRecipient={personState} onSetContact={onSetContact} isError={isError} errors={errors} typeOfForm={'documentTransfer'} />
            :
            <div className='field'>
              <label className='input-label'>
                שם איש קשר/חברה*
              </label>
              <Field
                autoComplete="new-password"
                type="text"
                className={
                  isError('returnRecipientName')
                    ? 'error'
                    : personState.personName ||
                      personState.companyName
                      ? 'success'
                      : ''
                }
                value={
                  personState.personName ||
                  personState.companyName ||
                  ''
                }
                name={
                  personState.type === 'ADAM_PRATI'
                    ? 'personName'
                    : 'companyName'
                }
                onChange={handleChangeData}
              />
              {isError('returnRecipientName') && <p className='error-label'>{errors.returnRecipientName}</p>}
            </div>
          }
          <div className='field'>
            <label className='input-label'>
              {data.type === 'documentTransfer' ? 'טלפון נייד*'
                :
                personState.type === 'ADAM_PRATI'
                  ? `טלפון נייד*`
                  : 'טלפון'}
            </label>
            <div className='icon-input'>
              <Field
                autoComplete="new-password"
                type="text"
                className={
                  isError('returnRecipientPhone')
                    ? 'error'
                    : phoneRegex.test(personState.personPhone) ||
                      phoneRegex.test(personState.companyPhone) ||
                      phoneRegex.test(personState.officePhone)
                      ? 'success'
                      : ''
                }
                value={
                  personState.personPhone ||
                  personState.companyPhone ||
                  personState.officePhone ||
                  ''
                }
                name={
                  personState.type === 'ADAM_PRATI'
                    ? 'personPhone'
                    : personState.type === 'HEVRA' ? 'companyPhone' : 'officePhone'
                }
                onChange={handleChangeData}
              />

              <div className="icon">972+</div>
            </div>
            {isError('returnRecipientPhone') && <p className='error-label'>{errors.returnRecipientPhone}</p>}
          </div>
        </div>

        <h3 className='small'>כתובת</h3>

        <div className='input-container'>
          <div className='field'>
            <label className='input-label'>יישוב*</label>
            <div
              ref={refCity}
              className={
                isCitiesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
              }
            >
              <div className='dropdown-input'>
                <input
                  type="text"
                  placeholder='הזינו יישוב ובחרו מתוך הרשימה'
                  onBlur={onBlur}
                  onChange={(event) => {
                    setSearchCity(event.target.value);
                    setIsCitiesDropdownOpen(true);
                  }}
                  value={searchCity}
                  onClick={() => { setIsCitiesDropdownOpen(true); }}
                  name='city'
                  autoComplete="off"
                  className={
                    isError('returnAddress') && !personState.city
                      ? 'error'
                      : personState.city
                        ? 'success'
                        : ''
                  }
                />
                <button
                  type='button'
                  onClick={() => {
                    setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                  }}
                >
                  <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                </button>
              </div>

              {isError('returnAddress') && <p className='error-label'>{errors?.returnAddress?.returnCity || ''}</p>}

              <div className='dropdown-list'>
                {israelCities.filter((option) => option['שם_ישוב']?.includes(searchCity)).map((city, idx) => {
                  return (
                    <div className='dropdown-option' key={idx}>
                      <p
                        onMouseDown={() => {
                          const cityName = city['שם_ישוב'].trim()
                          onSetChoose('city', cityName)
                          setSearchCity(cityName)
                          setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                          if (cityName !== personState.city) undoChanges()
                        }}>
                        {city['שם_ישוב']}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {personState.isAddressNotFound ?
            <div div className='field extra-height'>
              <label className='input-label'>רחוב*</label>
              <Field
                type="text"
                name='streetAddress'
                autoComplete="new-password"
                value={searchStreet}
                onChange={(ev) => {
                  setSearchStreet(ev.target.value);
                  onSetChoose('streetAddress', ev.target.value)
                }}
                className={
                  isError('returnAddress') && !personState.streetAddress
                    ? 'error'
                    : personState.streetAddress
                      ? 'success'
                      : ''
                }
              />
              {isError('returnAddress') && <p className='error-label'>{errors?.returnAddress?.returnStreetAddress || ''}</p>}
              <TooltipTriangle text={'אם אין את הרחוב ברשימה הנפתחת, לחצו כאן וכתבו את הרחוב'}>
                <label className="checkbox-container">
                  <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={personState.isAddressNotFound} onChange={handleIsAddressNotFound} />
                  <label className='checkbox-content' htmlFor='isAddressNotFound'>
                    לא מצאתי את הרחוב
                  </label>
                  <span className="checkmark"></span>
                </label>
              </TooltipTriangle>
            </div>
            :

            <div className='field extra-height'>
              <label className='input-label'>רחוב*</label>

              <div
                ref={refStreet}
                className={
                  isStreetsDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                }
              >
                <div className='dropdown-input'>
                  <input
                    type="text"
                    placeholder='הזינו רחוב ובחרו מתוך הרשימה'
                    onBlur={onBlur}
                    onChange={(event) => {
                      setSearchStreet(event.target.value);
                      setIsStreetsDropdownOpen(true);
                    }}
                    value={searchStreet}
                    onClick={() => { setIsStreetsDropdownOpen(true); }}

                    name='streetAddress'
                    autoComplete="new-password"
                    className={
                      isError('returnAddress') && !personState.streetAddress
                        ? 'error'
                        : personState.streetAddress
                          ? 'success'
                          : ''
                    }
                  />
                  <button
                    type='button'
                    onClick={() => {
                      setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                    }}
                  >
                    <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                  </button>
                </div>

                {isError('returnAddress') && <p className='error-label'>{errors.returnAddress.returnStreetAddress}</p>}

                <div className='dropdown-list'>
                  {cityStreets.filter((option) => option['שם_רחוב']?.includes(searchStreet)).map((street, idx) => {
                    return (
                      <div className='dropdown-option' key={idx}>
                        <p
                          onMouseDown={() => {
                            onSetChoose('streetAddress', street['שם_רחוב'])
                            setSearchStreet(street['שם_רחוב'])
                            setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                          }}>
                          {street['שם_רחוב']}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <TooltipTriangle text={'אם אין את הרחוב ברשימה הנפתחת, לחצו כאן וכתבו את הרחוב'}>
                <label className="checkbox-container">
                  <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={personState.isAddressNotFound} onChange={handleIsAddressNotFound} />
                  <label className='checkbox-content' htmlFor='isAddressNotFound'>
                    לא מצאתי את הרחוב
                  </label>
                  <span className="checkmark"></span>
                </label>
              </TooltipTriangle>
            </div>
          }

          <div className='field small-update' >
            <label className='input-label'>מספר*</label>
            <Field
              autoComplete="new-password"
              type="text"
              className={
                isError('returnAddress') && !personState.streetNumber
                  ? 'small-update error'
                  : personState.streetNumber
                    ? 'small-update success'
                    : 'small-update'
              }
              value={personState.streetNumber}
              name='streetNumber'
              onChange={handleChangeData}
            />
            {isError('returnAddress') && <p className='error-label'>{errors.returnAddress.returnStreetNumber}</p>}
          </div>
          <div className='field small-update'>
            <label className='input-label'>קומה</label>
            <Field
              type="text"
              autoComplete="new-password"
              className={
                personState.floor ? 'small-update success' : 'small-update'
              }
              value={personState.floor}
              name='floor'
              onChange={handleChangeData}
            />
          </div>
          <div className='field small-update'>
            <label className='input-label'>דירה</label>
            <Field
              type="text"
              autoComplete="new-password"
              className={
                personState.apartmentNumber
                  ? 'small-update success'
                  : 'small-update'
              }
              value={personState.apartmentNumber}
              name='apartmentNumber'
              onChange={handleChangeData}
            />
          </div>

          <div className='input-container comments-container'>
            <div className='field'>
              <label className='input-label'>הערות/פרטים נוספים</label>
              <Field
                type="text"
                autoComplete="new-password"
                placeholder={'כתבו פרטים נוספים שחשוב שנדע'}
                value={personState.comments}
                name='comments'
                onChange={handleChangeData}
                className={personState.comments ? 'success' : ''}
              />
            </div>
          </div>
        </div>

        <div className='input-container save-contact-container'>
          {!activeUser?.isAnonymous &&
            <TooltipTriangle text={'פרטי הנמען וכתובתו ישמרו להזמנות עתידיות'}>
              <label className="checkbox-container save-contact-checkbox">
                <Field type="checkbox" name="isSaveContactSecond" checked={systemData.isSaveContactSecond} onChange={() => handleIsSaveContactSecond()} />
                <label className='checkbox-content' htmlFor='isSaveContactSecond'>
                  שמירה להזמנות עתידיות
                </label>
                <span className="checkmark"></span>
              </label>
            </TooltipTriangle>
          }
        </div>
        <div className='cmp-line' />
      </div>
    </div>
  );
};

export default PersonalAddress;
