import React, { useState, useEffect } from 'react';
import * as Sentry from "@sentry/react";
import {
  Routes,
  Route,
  useNavigate, useLocation,
} from 'react-router-dom'
import { getAuth, signInAnonymously, signInWithEmailLink } from "firebase/auth";
import { getToken, getMessaging } from "firebase/messaging";
import { login, setInvites } from "../../store/actions/userActions"
import { setUTM } from "../../store/actions/utmActions"
import { RightSide } from './SideBar/RightSide'
import Content from './HomeCover/Content';
import Footer from '../Footer/Footer';
import AppBar from '../Header/AppBar/AppBar';
import EmergencyMsgs from '../Header/AppBar/EmergencyMsgs';
import { Modal } from '../Forms/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Checkout } from '../Main/Checkout'
// import { Optimization } from '../Main/Optimization'
import { PopUpModalOrder } from '../Utils/PopUpModalOrder'
import { Screen } from './Screen';
import { updateSystemData, updateErrorModal } from '../../store/actions/systemActions';
import Users from './Users'
import { PopUpModal } from '../Utils/PopUpModal';
import { ModalToolbar } from '../ModalToolbar'
import { AppMenu } from '../AppMenu'
import { LoginSingup } from '../Login/LoginSingup'
import { CreateUser } from '../UserDashboard/UserCompany/CreateUser'
import { onAuthStateChanged, onIdTokenChanged } from "firebase/auth";
import { UserOrders } from '../UserDashboard/UserOrders'
import { UserDetails } from '../UserDashboard/UserDetails'
import { UserAddressList } from '../UserDashboard/UserAddressList'
import { UserInvoices } from '../UserDashboard/UserInvoices'
import { UserCompany } from '../UserDashboard/UserCompany'
import { UserActionInfo } from '../UserDashboard/UserActionInfo'
import { ModalUser } from '../UserDashboard/ModalUser'
import { useViewport } from '../../Hooks/useViewport'
import db, { Logger, trace, perf, analytics, setUserId, setUserProperties, logEvent, auth, remoteConfig } from '../../integrations/firebase'
import errorHandler from '../../integrations/errorHandler'
import { doc, getDoc, setDoc } from "@firebase/firestore";
import { SetPassword } from '../Login/steps/SetPassword'
import { HandleActions } from './HandleActions.jsx'
import { collection, getDocs, query, where, onSnapshot, querySnapshot, serverTimestamp } from "firebase/firestore";
import { OrdersSet, NotificationsSet } from '../../store/actions/userDataActions';
import { setCompany, setCompanyUsers } from '../../store/actions/companyActions';
import { updateUser } from '../../store/actions/userActions';
import { NewUser } from './NewUser';
import { getValue, fetchAndActivate, getBoolean, getAll } from "firebase/remote-config";

const logClient = new Logger(`Main`);

function requestPermission() {
  console.log('Requesting permission...');
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  })
}

export function Main() {
  const t = trace(perf, "Main");
  t.start();
  const { width } = useViewport();
  const breakpoint = 1111;

  //isDelawverySignup - For Popup Signup
  let isDelawverySignup = JSON.parse(sessionStorage.getItem('isDelawverySignup'));
  if (isDelawverySignup === null) {
    isDelawverySignup = true;
    sessionStorage.setItem('isDelawverySignup', JSON.stringify(isDelawverySignup));
  }
  const isPopUpModal = useSelector((state) => state.systemReducer.isPopUpModal);
  const systemData = useSelector((state) => state.systemReducer.systemData);
  const activeUser = useSelector((state) => state.userReducer.user);
  const company = useSelector((state) => state.companyReducer.company);
  const [isShowEmergencyMsgs, setIsShowEmergencyMsgs] = useState(false);
  // console.log('activeUser', activeUser)
  // console.log('company', company)
  // console.log('systemData', systemData)
  // const val = getValue(remoteConfig, "isTestUser");
  // console.log('val', val)

  const mobile = useSelector((state) => state.mobileReducer.mobileState);
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query1 = useQuery()

  const email = query1.get('continueUrl')?.split('?')[1] || undefined;
  useEffect(async () => {
    let utmParams = {
      campaign: query1.get('utm_campaign'),
      source: query1.get('utm_source'),
      medium: query1.get('utm_medium'),
      term: query1.get('utm_term'),
    }
    if (query1.get('gclid')) {
      utmParams['source'] = "google"
    }
    if (!utmParams?.source && !document?.referrer?.includes("delawvery")) {
      utmParams['source'] = document.referrer
    }
    await dispatch(setUTM(utmParams))
  }, [])
  async function signIn() {
    return signInWithEmailLink(getAuth(), email, window.location.href).then((result) => {
      localStorage.setItem('usedSILink', JSON.stringify(true))
      dispatch(login(result.user))
      return null;
    }).catch((error) => {
      console.log('%c  error:', 'color: white;background: red;', error);
      const errorMessage = error.message || '';
      if (errorMessage.includes('auth/user-disabled')) {
        dispatch(updateSystemData({
          ...systemData, currLoginData: { currentStep: 'blockedUser' }
        }));
        navigate('/LoginSignup')
      }
      return null;
    });
  }



  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       console.log('fetchData')
  //       const res = await fetchAndActivate(remoteConfig);
  //       console.log('remoteConfig', remoteConfig)
  //       console.log('res', res)
  //       const isTestUserFetch = getBoolean(remoteConfig, "isTestUser");
  //       const allData = getAll(remoteConfig);
  //       console.log('allData', allData)
  //       console.log('isTestUserFetch', isTestUserFetch);
  //       dispatch(updateSystemData({
  //         ...systemData, isTestUser: isTestUserFetch
  //       }));
  //     } catch (error) {
  //       console.log('Error', error);
  //     }
  //   };

  //   //Start after 60 seconds
  //   const timeoutId = setTimeout(() => {
  //     fetchData();
  //   }, 60000);
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [activeUser]);


  const queryOrders = async (mondayId) => {
    if (!mondayId) return
    if (activeUser) {
      // const userRef = doc(db, "users", activeUser.uid);
      // const dbUser = await getDoc(userRef)
      // try {
      let orderTime;
      const now = new Date()
      const aMonthAgo = new Date(new Date().setDate(now.getDate() - 30));
      if (activeUser && !activeUser?.isAnonymous && activeUser?.fullName?.length > 0) {
        const q = activeUser.isMaster ? query(collection(db, "customers", activeUser.companyId || activeUser.uid, 'orders'))
          : query(collection(db, "customers", activeUser.companyId || activeUser.uid, 'orders'), where("userMonday", "==", mondayId));
        // const q = query(collection(db, "customers", activeUser.uid, 'orders'), where("userMonday", "==", mondayId));
        const unsub = onSnapshot(q, async (querySnapshot) => {
          const ordersFromStorage = []
          querySnapshot.forEach((doc) => {
            const order = doc.data()
            if ((activeUser.companyId || activeUser.uid) == "IIOpjpkch5MIr9SbcuGSzR1Aa1J2") {
              orderTime = new Date(+order.orderTime.slice(6, 10), +order.orderTime.slice(3, 5), +order.orderTime.slice(0, 2), +order.orderTime.slice(12, 14), +order.orderTime.slice(15, 17))
              if (orderTime > aMonthAgo) {
                ordersFromStorage.push(order)
              }
            } else {
              ordersFromStorage.push(order)
            }
          });

          const notifications = [];
          ordersFromStorage.forEach(order => {
            if (order?.notifications) notifications.push(...order.notifications);
          });

          const userRef = doc(db, "users", activeUser.uid);
          const userDoc = await getDoc(userRef);
          const userData = userDoc.data();

          if (userData?.notifications) {
            userData.notifications.forEach(notification => {
              if (notification?.header.includes('התחבר.ה בפעם הראשונה')) {
                notifications.push(notification);
              }
            });
            notifications.sort((a, b) => a.date - b.date);
          }
          dispatch(NotificationsSet(notifications));
          dispatch(OrdersSet(ordersFromStorage));
        });
        return () => {
          unsub();
        }
      }
    }
  };

  useEffect(() => {
    async function doEffect() {
      const userInvitesRef = doc(db, "invites", 'awaitingUserInvites');
      const userInvites = await getDoc(userInvitesRef);
      const userInvitesData = userInvites.data();
      dispatch(setInvites(userInvitesData))
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          if (user.email) {
            window.sib.email_id = user.email
          }
          // console.log('user', user)
          const userRef = doc(db, "users", user.uid);
          // errorHandler.setUser(user.uid);
          const dbUser = await getDoc(userRef)

          // const userInvitesRef = doc(db, "invites", user.uid);
          // const userInvitesData = await getDoc(userInvitesRef);
          // console.log('userInvitesData', userInvitesData.data())
          if (dbUser.data() && dbUser.data().userActivity === 'blocked') {
            setUserProperties(analytics, {
              userActivity: 'blocked'
            })
            dispatch(login(user))
            return
          }

          if (!dbUser.exists() && !user.isAnonymous) {
            // console.log('התחברות בלי הרשמה')
            let firstLoginType;
            const isUserOfCompany = userInvitesData[user.uid] ? userInvitesData[user.uid] : ''
            if (isUserOfCompany) {
              logEvent(analytics, 'first Login', { type: 'invite' })
              dispatch(updateSystemData({
                ...systemData, currLoginData: { currentStep: 'firstLogin' }
              }));
              navigate('/LoginSignup')
            } else {
              logEvent(analytics, 'first Login', { type: 'new customer' })
              dispatch(updateSystemData({
                ...systemData, currLoginData: { currentStep: 'signupDetails' }
              }));
              navigate('/LoginSignup')
            }
          }
          dispatch(login(dbUser.exists() ? { uid: user.uid, providerData: user.providerData, ...dbUser.data() } : user))

          const currentPath = window.location.pathname;
          const isMainURL = currentPath === '/';
          if (!dbUser.exists() && user.isAnonymous && isDelawverySignup && isMainURL) {
            setTimeout(() => {
              dispatch(updateSystemData({
                ...systemData, currLoginData: { currentStep: 'signupOptions' }, isOpenSlowly: true
              }));
              navigate('/LoginSignup');
            }, 5000);
          }

          if (!user.isAnonymous && dbUser.data()) {
            const messaging = getMessaging();
            getToken(messaging).then((currentToken) => {
              if (currentToken) {
                const docRef = doc(db, "FCMtokens", user.uid);
                setDoc(docRef, { token: currentToken, time: serverTimestamp() }).then((r) => { }).catch((e) => {
                  logClient.error('error when updating notification token');
                  logClient.error(JSON.stringify(currentToken))
                  logClient.error(JSON.stringify(e));
                })
              }
              else {
                // Show permission request UI
                console.log('No registration token available. Request permission to generate one.');
                requestPermission();
              }
            }).catch((err) => { console.log('err', err) });
          }
        }
        else {
          signInAnonymously(auth)
          logEvent(analytics, 'Anonymous Login')
          const user = auth.currentUser;
          dispatch(login(user))
        }
      });
      if (email && ((activeUser && activeUser.isAnonymous) || !activeUser) && !(JSON.parse(localStorage.getItem('usedSILink') || 'false'))) {
        logEvent(analytics, 'Email Login')
        await signIn();
      }
      if (localStorage.getItem('usedSILink')) {
        localStorage.setItem('usedSILink', JSON.stringify('false'))
      }
    }
    doEffect()
  }, []);

  useEffect(() => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userRef = doc(db, "users", user.uid);
        const dbUser = await getDoc(userRef)
        if (dbUser.data()?.mondayId) {
          await queryOrders(dbUser.data()?.mondayId || '')
        }
      }
    });
  }, [activeUser]);

  useEffect(() => {
    if (activeUser && activeUser?.companyId) {
      const unsub = onSnapshot(doc(db, "customers", activeUser.companyId), (doc) => {
        dispatch(setCompany(doc.data()));
      });
      return () => {
        unsub();
      }
    }
  }, [activeUser]);


  useEffect(() => {
    if (activeUser) {
      try {
        const unsub = onSnapshot(doc(db, "users", activeUser.uid), async (userDoc) => {
          const currUser = userDoc.data()
          if (!currUser?.isMaster || !currUser?.users) return
          const userRefs = []
          try {
            for (let i = 0; i < currUser.users.length; i++) {
              const userId = currUser.users[i];
              const userRef = doc(db, "users", userId);
              const dbUser = await getDoc(userRef)
              if (dbUser.exists()) {
                const user = { ...dbUser.data(), isOpen: false }
                userRefs.push(user)
              }
            }
            const awaitingUsers = company?.awaitingUsers ? company?.awaitingUsers : []
            const usersWithAwaiting = [...userRefs, ...awaitingUsers]
            //Sort by isMaster
            usersWithAwaiting.sort((a, b) => (a.isMaster === b.isMaster ? 0 : a.isMaster ? -1 : 1));
            //Sort by userActivity
            usersWithAwaiting.sort(compareByActivity)
            dispatch(setCompanyUsers([...usersWithAwaiting]));
          }
          catch ({ message }) {
            console.log('%c  error:', 'color: white;background: red;', message);
          }
        });
        return () => {
          unsub();
        }
      } catch (error) {
        dispatch(
          updateErrorModal({
            title: 'שגיאה',
            errorText: 'קרתה שגיאה במהלך הכניסה לאתר, יש לבצע רענון לעמוד ובמידה והבעיה חוזרת - ניתן ליצור עמנו קשר בצ׳אט שבאתר או בטלפון 03-3817000',
            submitText: 'אישור',
            isCancel: false,
            isFirebaseErrorImage: true
          })
        );
        errorHandler.report(`uid of user: ${activeUser.uid}`);
        errorHandler.report(error);
      }
    }
  }, [activeUser, company, company?.awaitingUsers]);

  const compareByActivity = (userA, userB) => {
    if (userB.userActivity === 'blocked' || userB?.isDisabled) return -1
    else return userA.userActivity < userB.userActivity ? -1 : (userA.userActivity > userB.userActivity) ? 1 : 0
  }

  const setModalToolbarOpen = (value) => {
    dispatch(updateSystemData({
      ...systemData,
      isModalToolbarOpen: value,
    }));
  };

  const { errorText } = isPopUpModal;
  const returnValue = (
    <div className={systemData.isFormModalOpen || systemData.isAppSideBarOpen || mobile.isMenuOpen ? 'app-container open' : 'app-container'}>
      <AppBar />
      {isShowEmergencyMsgs &&
        <EmergencyMsgs setIsShowEmergencyMsgs={setIsShowEmergencyMsgs} />
      }

      <div className="main-page">
        {/* <div className=""> */}
        <RightSide />
        <Routes>
          <Route index element={<Content />} />

          {/*<Route path="optimization" element={<Optimization />} />*/}
          <Route path="forms" element={<Content />}>
            <Route path=":formId" element={<Modal />} />
          </Route>

          <Route path="checkout" element={<Checkout />}>
            <Route path=":formId" element={<Modal />} />
            <Route path="LoginSignup" element={<LoginSingup />} />
          </Route>

          <Route path="new-order" element={<>
            <Checkout />
            <PopUpModalOrder />
          </>
          } />

          <Route path="user" element={<Users />}>

            <Route path="orders/:actionId" element={<>
              <UserOrders />
              <UserActionInfo />
            </>
            } />

            <Route path="orders" element={(width < breakpoint) ? <ModalUser section='orders' /> : <UserOrders />} />
            <Route path="msgs" element={(width < breakpoint) ? <ModalUser section='msgs' /> : <UserDetails />} />
            <Route path="details" element={(width < breakpoint) ? <ModalUser section='details' /> : <UserDetails />} />
            <Route path="addresses" element={(width < breakpoint) ? <ModalUser section='addresses' /> : <UserAddressList />} />
            <Route path="invoices" element={<UserInvoices />} />
            {/* <Route path="company" element={<UserCompany />} /> */}
            <Route path="company" element={(width < breakpoint) ? <ModalUser section='company' /> : <UserCompany />} />
          </Route>

          <Route path="LoginSignup" element={<>
            <Content />
            <LoginSingup />
          </>
          } />


          <Route path="welcome" element={<>
            <Content />
            <NewUser />
          </>
          } />

          <Route path="reset-password" element={<>
            <Content />
            <LoginSingup resetPassword={true} />
          </>} />

          <Route path="verified-email" element={<>
            <Content />
            <LoginSingup verifiedEmail={true} />
          </>} />
          <Route path="HandleActions" element={<HandleActions />} />
          <Route path="*" element={<Content />} />
          {/* </div> */}
        </Routes>
      </div >
      <ModalToolbar setModalToolbarOpen={setModalToolbarOpen} isModalToolbarOpen={systemData.isModalToolbarOpen} />
      <AppMenu />
      <Footer />
      <Screen isOpen={systemData.isFormModalOpen} isMenuOpen={mobile.isMenuOpen} />
      {/* {systemData.isPopUpModalOrder && <PopUpModalOrder />} */}
      {errorText && <PopUpModal />}
    </div>
  );
  t.stop();
  return returnValue
}

export default Main;
