import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from '../../Utils/Tooltip'
import { CustomTooltip } from '../../Utils/CustomTooltip'
import info from '../../../images/info.svg'
import { updateErrorModal } from '../../../store/actions/systemActions';
import { useDispatch, useSelector } from 'react-redux';

//Signature
import { Canvas } from '../../Utils/Canvas'

export const SmallClaimContract = ({ data, setFormState, setData, formState, setStep, type, submitForm, setSubmitForm, isError, errors }) => {
  const isPopUpModal = useSelector((state) => state.systemReducer.isPopUpModal);
  const [showSignA, setShowSignA] = useState(false)
  const [showSignB, setShowSignB] = useState(false)
  const [trimmedDataURL, setTrimmedDataURL] = useState(null)
  const dispatch = useDispatch()

  const componentSignARef = useRef();
  const componentSignBRef = useRef();
  useEffect(() => {
    setData({
      ...data,
      claimContract: data.claimContract ? data.claimContract : {
        signatureA: '',
        signatureB: '',
        isSubmittedClaim: null
      }
    });
  }, []);

  useEffect(() => {
    const { isChangeOption, status } = isPopUpModal;
    if (isChangeOption && status === 'submit') {
      setData({
        ...data,
        claimContract: {
          ...data.claimContract,
          isSubmittedClaim: true
        }
      });
    }
  }, [isPopUpModal.isChangeOption]);


  useEffect(() => {
    if (data?.claimContract?.isSubmittedClaim === false) {
      dispatch(
        updateErrorModal({
          title: 'שימו לב!',
          errorText: 'בחרתם להצהיר שהגשתם יותר מחמש תביעות בשנה האחרונה. אם זו בחירתכם, לאחר הגשת התביעה בית המשפט יהיה רשאי שלא לדון בה כתביעה קטנה ולהעבירה לבית משפט השלום.',
          submitText: 'שינוי בחירה',
          status: 'change_option',
          isCancel: true
        })
      );
    }
  }, [data?.claimContract?.isSubmittedClaim]);

  const getToolTipText = (textType) => {
    switch (textType) {
      case 'SectionA':
        return 'בשלב הבא תוכלו לבחור במסירה אישית לנתבע/ים ואנו נדאג למסור  את כתב התביעה עם אישור מסירה ותצהיר מוסר, אותו אנו נגיש לבית המשפט לקידום התיק במהירות ובודאות. אם תבחרו שלא למסור במסירה אישית, בית המשפט ישלח את התביעה בדואר רשום לכתובת הנתבע/ים כפי שנמסרה על ידכם בתביעה זו. שימו לב שהחובה לוודא את נכונות הכתובת של הנתבע/ים היא עליכם. לכן אם אינכם בטוחים לגבי כתובת הנתבע/ים, יש לכם אפשרות לבחור באיתור כתובת הנתבעים, בשלב הראשון ״הצדדים לתביעה״.'

      default:
        return ''
    }
  }
  const getContractText = (textType) => {
    switch (textType) {
      case 'SectionA':
        return 'לאחר שיוגש כתב התביעה תקבע מזכירות בית המשפט תאריך לדיון בתביעה; המזכירות תשלח לתובע הזמנה לדיון בדואר רשום עם אישור מסירה, וכן תשלח עותק מכתב התביעה והזמנה לדיון לכתובת של הנתבע שהתובע מסר כשהגיש את כתב התביעה (אם יש כמה נתבעים, אז לכולם); התובע נדרש לוודא שקיבל תאריך לדיון, שהכתובת של הנתבע שמסר נכונה ולזכור את תאריך הדיון כשיתקבל (יש לרשום ביומן או ליצור אמצעי תזכורת אחר);'
      case 'SectionB':
        return 'יש לעדכן את בית המשפט מיד על כל שינוי בכתובת או בטלפון של תובע או נתבע;'
      case 'SectionC':
        return 'התובע נדרש לוודא שהנתבע קיבל את כתב התביעה והזמנה לדיון: בית המשפט ייתן פסק דין נגד נתבע רק לאחר ששוכנע שהנתבע קיבל את כתב התביעה ואת ההזמנה לדיון, והנתבע לא הגיש כתב הגנה או לא התייצב לדיון לפי ההזמנה; לכן התובע נדרש לברר במזכירות 60 ימים לפני הדיון אם הנתבע קיבל את כתב התביעה ואת ההזמנה לדיון; אם הנתבע לא קיבל את כתב התביעה והזמנה לדיון, על התובע, או מי מטעמו, להמציא לנתבע את כתב התביעה וזימון לדיון במסירה אישית. הנחיות לביצוע מסירה אישית אפשר לקבל במזכירות;'
      case 'SectionD':
        return 'על הנתבע להגיש כתב הגנה בתוך 30 ימים מהיום שקיבל את כתב התביעה, אלא אם כן בית המשפט האריך, לבקשתו, את המועד להגשת כתב הגנה; אם לא הגיש הנתבע כתב הגנה במועד, התובע רשאי לבקש מבית המשפט לתת פסק דין לפי כתב התביעה בלבד, עוד לפני מועד הדיון; לבית המשפט יש שיקול דעת אם לתת פסק דין בהעדר הגנה;'
      case 'SectionE':
        return 'בתביעה יתקיים דיון אחד שבו נשמעות העדויות של בעלי הדין והעדים;'
      case 'SectionF':
        return 'על התובע להזמין את העדים בעצמו לדיון; אם התובע חושש שעד לא יתייצב ומעוניין שבית המשפט יחייב אותו בכך, על התובע להגיש מיד בקשה לזימון העד בצירוף כתובתו המלאה, טלפון נייד (אם ידוע), ופירוט מדוע עדותו נחוצה; על התובע לעקוב במזכירות אחר החלטה שתינתן; התובע חייב לוודא, 60 ימים לפני הדיון, שהעד קיבל את הזימון, ואם הוא לא קיבל אותו יש לבקש הוראות בכתב מבית המשפט.'
      default:
        return ''
    }
  }

  const handleCloseA = () => {
    setShowSignA(false)
  }
  const handleCloseB = () => {
    setShowSignB(false)
  }

  const ModalA = () => {
    const showHideClassName = showSignA ? "modal display-block" : "modal display-none";
    return (
      <div className={showHideClassName}>
        <section className="modal-main">
          <div >
            <Canvas trimmedDataURL={trimmedDataURL} setTrimmedDataURL={setTrimmedDataURL} handleClose={handleCloseA} data={data} setData={setData} type={'SmallClaimContractA'} />
          </div>
        </section>
      </div>
    );
  };

  const ModalB = () => {
    const showHideClassName = showSignB ? "modal display-block" : "modal display-none";
    return (
      <div className={showHideClassName}>
        <section className="modal-main">
          <div >
            <Canvas trimmedDataURL={trimmedDataURL} setTrimmedDataURL={setTrimmedDataURL} handleClose={handleCloseB} data={data} setData={setData} type={'SmallClaimContractB'} />
          </div>
        </section>
      </div>
    );
  };

  const onSubmittedClaim = (isSubmitted) => {
    const updatedClaimContract = { ...data.claimContract, isSubmittedClaim: isSubmitted }
    setData({
      ...data,
      claimContract: updatedClaimContract
    })
  }

  let today = new Date();
  let date = today.getDate() + '-' + (today.getMonth() + 1) + '-' + today.getFullYear();
  return (
    <div className='main-form recipients small-claim-contract'>
      <div className='modal-content-container'>
        <h4 className='form-title'>3ד. להלן מידע חשוב והוראות בעניין התביעה; יש לקרוא בעיון ולחתום לאישור:</h4>

        <div className='section-container discussion-invitation-tooltip'>
          <Tooltip text={getToolTipText('SectionA')}>
            <img src={info} alt="info-icon" className="info-icon" />
          </Tooltip>
          <h4 className='details'>
            <span className='numbering'>
              (א)
            </span>
            &nbsp;
            <span className='sub-title'>
              הזמנה לדיון:
            </span>
            &nbsp;
            {getContractText('SectionA')}
          </h4>
        </div>

        <div className='section-container'>
          <h4 className='details'>
            <span className='numbering'>
              (ב)
            </span>
            &nbsp;
            <span className='sub-title'>
              עדכון פרטים:
            </span>
            &nbsp;
            {getContractText('SectionB')}
          </h4>
        </div>

        <div className='section-container section-c-tooltip'>
          <CustomTooltip tooltipType={'SectionC'}>
            <img src={info} alt="info-icon" className="info-icon" />
          </CustomTooltip>
          <h4 className='details'>
            <span className='numbering'>
              (ג)
            </span>
            {getContractText('SectionC')}
          </h4>
        </div>

        <div className='section-container section-d-tooltip'>
          <CustomTooltip tooltipType={'SectionD'}>
            <img src={info} alt="info-icon" className="info-icon" />
          </CustomTooltip>
          <h4 className='details'>
            <span className='numbering'>
              (ד)
            </span>
            <span className='sub-title'>
              פסק דין בהעדר הגנה:
            </span>
            &nbsp;
            {getContractText('SectionD')}
          </h4>
        </div>

        <div className='section-container'>
          <h4 className='details'>
            <span className='numbering'>
              (ה)
            </span>
            &nbsp;
            <span className='sub-title'>
              הדיון בתביעה:
            </span>
            &nbsp;
            {getContractText('SectionE')}
          </h4>
        </div>

        <div className='section-container'>
          <h4 className='details'>
            <span className='numbering'>
              (ו)
            </span>
            &nbsp;
            <span className='sub-title'>
              זימון עדים:
            </span>
            &nbsp;
            {getContractText('SectionF')}
          </h4>
        </div>

        <h4>
          אני מאשר/ת שקראתי את המידע וההוראות ואפעל לפיהם
        </h4>

        <div className='signature'>
          {data?.claimContract?.signatureA &&
            <h4>חתימת התובע/ת</h4>
          }
          <div ref={componentSignARef} >
            {data?.claimContract?.signatureA &&
              <img className="signature-image" src={data?.claimContract?.signatureA} alt="signature" style={{ alignSelf: "center", marginRight: '20px', width: "40%" }} />
            }
          </div>
        </div>
        <ModalA />
        <div className='btn-error-container'>
          <div className='sign-btn-container' style={{ justifyContent: `${(data?.claimContract?.signatureA) ? 'space-between' : 'flex-start'}` }}>
            <button type="button"
              className="btn-sign"
              onClick={() => {
                setSubmitForm(false);
                setShowSignA(true);
                setData({
                  ...data,
                  claimContract: {
                    ...data.claimContract,
                    signatureA: '',
                  },
                });
              }}>
              לחתימה לחצו כאן
            </button>
          </div>

          {isError('claimContract') && <p className={`error-label error`}>{errors.claimContract?.signatureA}</p>}
        </div>

        <div className='section-container section-declaration'>
          <h4 className='declaration'>
            4. אני מצהיר כי בשנה האחרונה
            &nbsp;
            <span className={`declaration-span ${data?.claimContract?.isSubmittedClaim === true ? 'add-deco' : ''}`} onClick={() => { onSubmittedClaim(true) }}>
              הגשתי
            </span>
            &nbsp;
            /
            &nbsp;
            <span className={`declaration-span ${data?.claimContract?.isSubmittedClaim === false ? 'add-deco' : ''}`} onClick={() => { onSubmittedClaim(false) }}>
              לא הגשתי
            </span>
            &nbsp;
            (מחק את המיותר)
            בבית משפט זה יותר מחמש תביעות.
          </h4>
          {isError('claimContract') && <p className={`error-label error`}>{errors.claimContract?.isSubmittedClaim}</p>}
        </div>

        <div className='signature-b-container'>
          <div className='date-container'>
            <p>{date}</p>
            <h4 className='date-sign-title'>תאריך</h4>
          </div>

          <div className='signature-b'>
            <div>
              <div className='signature'>
                <div ref={componentSignBRef} >
                  {data?.claimContract?.signatureB &&
                    <img className="signature-image" src={data?.claimContract?.signatureB} alt="signature" style={{ alignSelf: "center", marginRight: '20px', width: "40%" }} />
                  }
                </div>
              </div>
              <ModalB />
              <h4 className='date-sign-title'>חתימת התובע/ת</h4>
            </div>
            <div className='btn-error-container sign-b'>
              <div className='sign-btn-container' style={{ justifyContent: `${(data?.claimContract?.signatureB) ? 'center' : 'center'}` }}>
                <button type="button"
                  className="btn-sign"
                  onClick={() => {
                    setSubmitForm(false);
                    setShowSignB(true);
                    setData({
                      ...data,
                      claimContract: {
                        ...data.claimContract,
                        signatureB: '',
                      },
                    });
                  }}>
                  לחתימה לחצו כאן
                </button>
              </div>

              {isError('claimContract') && <p className={`error-label error`}>{errors.claimContract?.signatureB}</p>}
            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default SmallClaimContract;