import React from 'react';
import { deliveryPackageOptions } from "../../../consts/data";
import { StyledChoice } from './PageUtils/StyledChoice';
import { Tooltip } from '../../Utils/Tooltip'
import { TooltipTriangle } from '../../Utils/TooltipTriangle'

export const DeliveryPackageSize = ({ data, setData, type, isError, errors }) => {

  return (
    <div className='delivery-package-size'>
      <div className='titles'>
        <h3> בחרו את סוג וגודל החבילה</h3>
        {isError('isPackageSizeChoose') && <p className={isError('isPackageSizeChoose') ? 'error' : ''}>{errors.isPackageSizeChoose}</p>}
      </div>
      <div className='styled-choises'>
        {deliveryPackageOptions.map(option =>
          <TooltipTriangle text={option.description} >
            <StyledChoice data={data} setData={setData} type={type} option={option} />
          </TooltipTriangle>
        )}
      </div>
    </div>);
};

export default DeliveryPackageSize;
