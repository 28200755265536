import { summarizeAction } from '../../integrations/firebase'

export async function handlePricesByFormData(type, data, totalRecipients) {
    let pricesData
    let summaryPrices
    let orderRecipients = []
    if (totalRecipients && data.recipients && data.recipients?.length > 0 && totalRecipients?.length > 0) {
        orderRecipients = totalRecipients.filter((recipient) =>
            data.recipients.includes(recipient._id)
        )
    }
    switch (type) {
        case 'apostilSubmission':
        case 'apostilInCourt':
        case 'landRegistration':
        case 'inheritanceRegistration':
        case 'documentTransfer':
        case 'companiesRegistration':
        case 'partnershipsRegistration':
        case 'associationsAndCompanies':
        case 'smallClaim':
        case 'printingBinding':
        case 'landSupervisor':
            pricesData = _generalSubmissionWithoutRecipients(type, data);
            break;
        case 'courtSubmission':
        case 'addressLocate':
        case 'phoneLocate':
        case 'personLocate':
        case 'idLocate':
        case 'personalDelivery':
        case 'officeDelivery':
            pricesData = _generalSubmissionWithRecipients(type, data, orderRecipients);
            break;

        default:
            break;
    }
    if (pricesData) {
        summaryPrices = await summarizeAction(pricesData)
        return summaryPrices;
    }
}

function _generalSubmissionWithoutRecipients(type, data,) {
    return { type, data }
}

function _generalSubmissionWithRecipients(type, data, orderRecipients) {
    const pricesData = {
        type,
        data: { ...data, recipients: orderRecipients },
    }
    return pricesData;
}

// -------------actions object generating--------------------

export function formatActions(data, order, totalRecipients) {
    const orderRecipients = totalRecipients.filter((recipient) =>
        order.recipients.includes(recipient._id)
    );
    const actions = orderRecipients.map((action) => formatAction(data, action))
    return actions;
}

export function formatAction(data, action) {
    switch (action.type) {
        case 'officeDelivery':
        case 'HEVRA':
        case 'ADAM_PRATI':
            return _formatInventionsOffice(data, action);
        case 'LOCATE':
            return action;
        default:
            return action;
    }
}

export function _formatInventionsOffice(data, action) {
    const docsForSubmission = data.docsForSubmission.map(doc => `${doc.documentName ? 'שם מסמך: ' + doc.documentName : ''}${doc.caseNumber ? (' - מספר תיק: ' + doc.caseNumber) : ''}`).join(" , ")
    const isDocsForSubmission = data?.docsForSubmission?.every(doc => doc.documentName.length === 0)

    const inventionsOfficeAction = {
        ...action,
        pages: data?.pages || null,
        bindings: data?.bindings || null,
        collectionAddress: data?.collectionAddress || null,
        returnAddress: data?.returnAddress || null,
        docsForSubmission: (!isDocsForSubmission && docsForSubmission) ? ' - ' + docsForSubmission : '',
        date: data?.date || null,
        collectionMethod: data?.collectionMethod || null,
        returnMethod: data?.returnMethod || null,
    }
    return clearObject(inventionsOfficeAction);
}

// -------------actions object generating--------------------

function clearObject(obj) {
    for (let key in obj) {
        if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
            delete obj[key];
        }
    }
    return obj
}
