import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStorage, ref, deleteObject, listAll } from "firebase/storage";
import { setFiles } from '../../../store/actions/fileActions';

export const FirstSubmissionOrCopyInvolves = ({ data, setData }) => {
  const [alignment, setAlignment] = useState('right');
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.userReducer.user);

  useEffect(() => {
    const { isFirstSubmission } = data;

    if (!isFirstSubmission) {
      setData({ ...data, isFirstSubmission: false });
    }
    setAlignment(!isFirstSubmission ? 'right'
      : isFirstSubmission === 'collect' ? 'end'
        : 'left');
  }, []);

  const clearFiles = async () => {
    const storage = await getStorage();
    const storageRef = ref(storage, `${activeUser.uid}/${data.storageDocId}`);
    const filesList = await listAll(storageRef)
    filesList.items.map(async (file) => {
      const storage = getStorage();
      const fileRef = ref(storage, file._location.path_);
      await deleteObject(fileRef)
    })
    dispatch(setFiles([]))
  }

  const handleChangeToggle = (event, newAlignment) => {
    const { value, name } = event.target;
    setAlignment(newAlignment);

    if (value === 'false') {
      delete data.toll;
    }

    if (data.isFirstSubmission === 'collect' && (value === 'true' || value === 'false')) {
      const deleteAttributes = ['docsForSubmission', 'pages', 'returnMethod', 'returnAddress']
      for (let att in data) {
        if (deleteAttributes.includes(att)) delete data[att]
      }
      clearFiles()
    }

    if (value === 'collect') {
      const deleteAttributes = ['docsForSubmission', 'pages', 'isOtherTasks', 'deliveryAfter', 'isPrivateOrOffice', 'isPersonalOrCompany', 'collectionMethod', 'isDocumentsReady', 'returnMethod', 'returnAddress', 'toll']
      for (let att in data) {
        if (deleteAttributes.includes(att)) delete data[att]
      }
      setData({ ...data, returnMethod: 'delivery', isFirstSubmission: 'collect' })
    }

    else {
      setData({
        ...data,
        [name]: value === 'true' ? true : false
      });
    }
  };

  const getTextBySubmission = () => {
    switch (alignment) {
      case 'right':
        return 'מומלץ להוסיף עותק של הדף הראשון מכל מסמך שמוגש לצורך החתמת "נתקבל"'
      case 'left':
        return 'יש לצרף שיק פתוח לתשלום אגרה, אותה אנו נשלם עבורכם או מכתב הוראה מפורט על דף לוגו של המשרד, המאשר לחייב את החשבון שמעודכן בהנהלת בתי המשפט.        '
      case 'end':
        return 'איסוף מהערכאה יכול להיות לצורך הוצאת פסקי דין מאושרים, צווים, החזר ערובה ועוד.'
      default:
        return ''
    }
  };


  return (
    <div className='main-form form7-first-submission-or-copy update-cmps'>
      <div className='modal-content-container block'>
        <h2 className='large-title'>בחרו את הפעולה הנדרשת</h2>
        {/* <p className='subtitle'>
          בפתיחת תיק יש לצרף שיק לתשלום אגרה, אותה אנו נשלם עבורכם.
          <br />
          איסוף מבית המשפט יכול להיות לצורך הוצאת פסקי דין מאושרים, צווים, החזר ערובה או כל דבר אחר.
        </p> */}
        <div className={`form-main-toggle toggle-three ${alignment}`}>
          <span></span>
          <button
            name='isFirstSubmission'
            value={false}
            onClick={(ev) => handleChangeToggle(ev, 'right')}
            type='button'
          >
            הגשה רגילה
          </button>
          <button
            name='isFirstSubmission'
            value={true}
            type='button'
            onClick={(ev) => handleChangeToggle(ev, 'left')}
          >
            פתיחת תיק
          </button>
          <button
            name='isFirstSubmission'
            value={'collect'}
            type='button'
            onClick={(ev) => handleChangeToggle(ev, 'end')}
          >
            איסוף מהערכאה
          </button>
        </div>
        <p className='label-txt'>{getTextBySubmission()}</p>
      </div>
    </div >
  );
};

export default FirstSubmissionOrCopyInvolves;
