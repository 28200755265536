import React, { useEffect, useState } from 'react'
import remove from '../../images/remove-grey.svg';
import edit from '../../images/edit.svg';
import bell from '../../images/bell.svg';
import eye from '../../images/eye.svg';
import pencil from '../../images/pencil.svg';
import { ReactComponent as Bell } from '../../images/bell.svg';
import { TailSpin } from 'react-loader-spinner'
import { doc, collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
import db from '../../integrations/firebase';
import { useSelector, useDispatch } from 'react-redux'
import { UserActionInfo } from '../UserDashboard/UserActionInfo'
import { NavLink } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
import { actionSet, OrderSet } from '../../store/actions/userDataActions';
import {
    urgencyTranslate,
    urgencyMap
} from '../../consts/forms'

export const UserOrderItem = ({ status, action }) => {
    const orders = useSelector((state) => state.userDataReducer.userOrders);
    const notifications = useSelector((state) => state.userDataReducer.userNotifications);

    const [notifyCount, setNotifyCount] = useState(0);
    const [order, setOrder] = useState(null);
    const [idx, setIdx] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const currOrder = orders.filter(order => {
            let isCurrOrder = false
            order.actionData.forEach((currAction, idx) => {
                if (currAction.id === action.id) {
                    isCurrOrder = true
                    setIdx(idx)
                }
            })
            return isCurrOrder
        })
        setOrder(currOrder[0])
    }, [])

    useEffect(() => {
        async function getUserNotify() {
            let count = 0
            notifications.forEach(notify => {
                if (notify.actionId && notify.actionId === action.id) {
                    count++
                }
            });
            setNotifyCount(count)
        }
        getUserNotify()
    }, [notifications])

    const onOpenActionInfo = () => {
        dispatch(actionSet(action));
        dispatch(OrderSet(order));
    }

    const deliveryAddress = () => {
        const collectionAddressTxt = action.collectionAddress
            ? `${action.collectionAddress.collectStreetAddress} ${action.collectionAddress?.collectStreetNumber || ""
            }, ${action.collectionAddress.collectCity} ${action.collectionAddress.floor
                ? ", קומה: " + action.collectionAddress.floor
                : ""
            } ${action.collectionAddress.apartment
                ? ", דירה: " + action.collectionAddress.apartment
                : ""
            } ${action.collectionAddress.collectNotes
                ? ", הערות: " + action.collectionAddress.collectNotes
                : ""
            } ${action.collectionAddress?.mailFolder || ""}`
            : "";
        return collectionAddressTxt
    }

    const docTransferTypes = (typeOfTransfer) => {
        const recipientReturnName = action.originalData?.returnAddress?.personName || action.originalData?.returnAddress?.officeName || action.originalData?.returnAddress?.companyName || ''
        const recipientCollectName = action.originalData?.collectionAddress?.personName || action.originalData?.collectionAddress?.officeName || action.originalData?.collectionAddress?.companyName || ''

        switch (typeOfTransfer) {
            case 'fromMe':
                return `שליחות ממני לנמען ${recipientReturnName}`
            case 'toMe':
                return `איסוף מהנמען ${recipientCollectName} אלי`
            case 'both':
                return `העברה בין ${recipientCollectName} ל${recipientReturnName}`
            default:
                return ''
        }
    }

    {
        action.type === 'documentTransfer' &&
            <div className="details">
                <p>{`${action.originalData.typeOfTransfer === 'toMe' ? (action.originalData.contactDeliveryRecipientName || order?.paymentCart?.orderClientName || order?.responseBody?.nameOnInvoice)
                    : action.originalData?.returnAddress?.personName || action.originalData?.returnAddress?.officeName || action.originalData?.returnAddress?.companyName}`}</p>
            </div>
    }

    const actionAddress = order ? order?.actionData[idx]?.action : {}
    let urgency
    if (action.type !== 'addressLocate' || 'phoneLocate') {
        // urgency = action.action ? action.action.urgency : action.urgency
        urgency = action?.action?.urgency || action?.urgency
    }
    if (action?.originalData?.urgency) urgency = action.originalData.urgency
    // console.log('action',action)

    return (
        <div className="order-item" onClick={() => { onOpenActionInfo(); navigate(`/user/orders/${action.id}`); }}>
            <div className="head">
                <div>
                    {status === 'active' ? <><Bell />
                        <p>{notifyCount} עדכונים</p> </> : 'הסתיימה'}
                    {/* MOVED DOWN FOR DEPLOY*/}
                </div>
                <div className="btn-container">
                    {/* {status === 'active' && <img src={pencil} alt="edit" />} */}
                    <img src={eye} alt="preview" />
                </div>
            </div>
            <div>
                <h4 className="title">הזמנה מס' #{order?.orderId}</h4>
                <div className="date-time">
                    <p>{order?.orderTime.split(',')[0]}</p>
                    <p>{order?.orderTime.split(',')[1]}</p>
                </div>
            </div>
            <div className="devider"></div>
            <div className='details-container'>
                <div className="details action-header">
                    {action.formData ?
                        <>
                            <img className='icon' alt='icon' src={action.formData.image} />
                            <h4>{action.formData.title}</h4>
                        </>
                        :
                        (
                            order?.cart.orders[idx] ?
                                <>
                                    <img className='icon' alt='icon' src={order?.cart.orders[idx].action.formImage} />
                                    <h4>{order?.cart.orders[idx].action.formTitle}</h4>
                                </>
                                :
                                <>
                                    <img className='icon' alt='icon' src={order?.cart.orders[0].action.formImage} />
                                    <h4>{order?.cart.orders[0].action.formTitle}</h4>
                                </>
                        )
                    }
                </div>
                {
                    (action.type !== 'apostilSubmission') &&
                    <div className="details">
                        {action.type === 'courtSubmission' &&
                            <p>{actionAddress.courtAddress}</p>
                        }
                        {(action.type === 'inheritanceRegistration' || action.type === 'landRegistration' ||
                            action.type === 'companiesRegistration' || action.type === 'partnershipsRegistration' ||
                            action.type === 'associationsAndCompanies') &&
                            <p>לשכת {action.branchAddress.city} {action.branchAddress.fullAddress}</p>
                        }

                        {action.type === 'personalDelivery' &&
                            <p>{action.action.personName || action.action.companyName}, {actionAddress.city}, {actionAddress.streetAddress} {actionAddress.streetNumber}</p>
                        }
                        {action.type === 'officeDelivery' &&
                            <p>{actionAddress.officeName} {actionAddress.city}, {actionAddress.streetAddress} {actionAddress.streetNumber}</p>
                        }
                        {action.type === 'smallClaim' &&
                            <p>{`נושא התביעה: ${action?.claimType || ''}`}</p>
                        }
                        {(action.type === 'addressLocate' ||
                            action.type === 'phoneLocate' ||
                            action.type === 'idLocate' ||
                            action.type === 'personLocate') &&
                            <div className='lines'>
                                {action.recipients.map((person, idx) => {
                                    return (
                                        <p key={idx}>{person.personFirstName} {person.personLastName}</p>
                                    )
                                })}
                            </div>
                        }
                        {action.type === 'documentTransfer' &&
                            <div className='lines'>
                                <p>{docTransferTypes(action.originalData.typeOfTransfer)}</p>
                                {
                                    action.originalData?.isDoubleTransfer && <p>שליחות כפולה (הלוך וחזור)</p>
                                }
                            </div>
                        }
                        {action.type === 'printingBinding' &&
                            <div className='lines'>
                                <p>{deliveryAddress()}</p>
                            </div>
                        }
                    </div>
                }

                {action.type !== 'addressLocate' &&
                    action.type !== 'phoneLocate' &&
                    action.type !== 'idLocate' &&
                    <div className="details">
                        <p>דחיפות</p>
                        <p>
                            {urgencyTranslate[urgency] + '- ' + urgencyMap[action.originalData.isSpecialUrgencyPrice ? 'specialUrgencyPrice' : action.type][urgency] + action.originalData?.isSpecialUrgencyPrice === 'next' && action.originalData?.type === 'documentTransfer' ? " (הביצוע יהיה ביום העסקים הבא)" : ""}
                        </p>
                    </div>
                }
                {/* {activeUser.isMaster && */}
                <div className="details italic">
                    <p><em>{order?.paymentCart?.orderClientName ||
                        order?.responseBody?.nameOnInvoice || (action?.originalData?.userOfOrder ? action.originalData?.userOfOrder?.fullName : action?.orderClientName)}</em> </p>
                </div>
                {/* } */}
                {/* <button type="button" className={`btn ${status === 'done' ? 'disabled' : ''}`}>שכפול הזמנה</button> */}
            </div>
            {/* <NavLink className={({ isActive }) => (isActive ? 'nav-title active' : 'nav-title inactive')} to="orders"><p >ההזמנות שלי</p></NavLink> */}
            {/* {isInfoOpen && <UserActionInfo onCloseModal={onCloseModal} order={order} action={action} idx={idx} notifications={actionNotifications} />} */}
        </div>

    )
}