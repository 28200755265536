import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { distantRegions, distantNeighborhoodsInJerusalem } from "../../../consts/data";
import { TooltipTriangle } from '../../Utils/TooltipTriangle'

export const UrgencyRecipientUpdate = ({ data, setData, formState, setFormState, type }) => {
  const recipients = useSelector(state => state.recipientReducer.Recipients);

  const [alignment, setAlignment] = useState('left');
  const [isDistant, setIsDistant] = useState(false);
  const optionA = 'REG';
  const optionB = 'URG';
  const optionC = 'VERY_URG';
  const withDistant = ['personalDelivery', 'officeDelivery']

  useEffect(() => {
    let { currRecipient } = formState;
    if (!currRecipient?.urgency) {
      setFormState((prevState) => ({
        ...prevState,
        currRecipient: { ...prevState.currRecipient, urgency: 'REG' },
      }));
    }
    if (data.recipients && data.recipients.length > 0 && !data.isOtherTasks) {
      currRecipient = formState.currRecipient ? formState.currRecipient : recipients[recipients.length - 1]
      setData({ ...data, isPersonalOrCompany: currRecipient.type === "ADAM_PRATI" ? "true" : "false" })
      setFormState({ ...formState, currRecipient });
    }
    setAlignment(
      (currRecipient?.urgency === optionB) ? 'left' : (currRecipient?.urgency === optionC ? 'end' : 'right'))
  }, [formState.currRecipient]);

  useEffect(() => {
    let { currRecipient } = formState;
    if (!currRecipient) return
    const isDistantCityOrStreet = isDestinationDistant(currRecipient?.city, currRecipient?.streetAddress)
    if (isDistantCityOrStreet) setAlignment('right');
    setIsDistant(isDistantCityOrStreet)
  }, [formState.currRecipient]);

  const isDestinationDistant = (city, streetAddress) => {
    if (city === 'ירושלים') {
      if (streetAddress) return distantNeighborhoodsInJerusalem.includes(streetAddress)
    } else {
      if (city) return distantRegions.includes(city)
    }
    return false
  }

  const handleUrgency = (event, newAlignment) => {
    event.preventDefault();
    if (isDistant) return
    setAlignment(newAlignment);
    const field = event.target.name;
    const value = event.target.value;
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: { ...prevState.currRecipient, [field]: value },
    }));
  };

  const getTextByRecipient = () => {
    const { currRecipient } = formState;

    if (!currRecipient) return;
    switch (alignment) {
      case 'left':
        return `${currRecipient.type === 'officeDelivery' ? 'ניסיון המצאה ' : 'ביקור ראשון '
          }  תוך ${currRecipient.type === 'officeDelivery' ? 1 : 1
          } ימי עסקים מיום קבלת המסמכים${currRecipient.type === 'officeDelivery' ? '' : ', וכל ביקור נוסף תוך 2 ימי עסקים מהביקור הקודם'}`;
      case 'right':
        return `${currRecipient.type === 'officeDelivery' ? 'ניסיון המצאה ' : 'ביקור ראשון'
        }  תוך ${currRecipient.type === 'officeDelivery' ? 3 : 5
        } ימי עסקים מיום קבלת המסמכים${currRecipient.type === 'officeDelivery' ? '' : ', וכל ביקור נוסף תוך 3 ימי עסקים'}`;
      case 'end':
        return `${currRecipient.type === 'officeDelivery' ? 'ניסיון המצאה ' : ' ביקור ראשון  '
          } ביום קבלת המסמכים${currRecipient.type === 'officeDelivery' ? '' : ', וכל ביקור נוסף תוך 2 ימי עסקים'}`;
      default:
        return '';
    }
  };

  return (
    <div className='main-form form7-urgency update-cmps form7-urgency-update'>
      <div className='modal-content-container'>
        {/* {formState.currRecipient?.type === 'officeDelivery' && <h2 className='large-title'>מה רמת הדחיפות לביצוע ההמצאה?</h2>} */}
        {/* {!(formState.currRecipient?.type === 'officeDelivery') && <h2 className='large-title'>מה רמת הדחיפות לביצוע המסירה?</h2>} */}
        <h3 className='urgency-title'>מה רמת הדחיפות?</h3>
        {/* <h3 className='small'>דחיפות</h3> */}
        {withDistant.includes(type) &&
          <div className="tooltip-container">
            {<TooltipTriangle text={'מסירות למגזר הערבי, בדואי, מעבר לקו הירוק, וכן יישובים מרוחקים נוספים, זמני הביצוע עשויים להיות עד 14 ימי עסקים'} >
              <button type='button' className='btn info' style={{ color: 'black' }}>מידע חשוב על זמני ביצוע חריגים</button>
            </TooltipTriangle>}
          </div>
        }

        <div className={`form-main-toggle toggle-three ${alignment}`}>
          <span></span>
          <button
            type='button'
            name='urgency'
            value={optionA}
            onClick={(e) => handleUrgency(e, 'right')}
          >
            {'רגיל'}
          </button>
          <button
            type='button'
            name='urgency'
            value={optionB}
            onClick={(e) => handleUrgency(e, 'left')}
          >
            {'דחוף'}
          </button>
          <button
            type='button'
            name='urgency'
            value={optionC}
            onClick={(e) => handleUrgency(e, 'end')}
          >
            {'בהול'}
          </button>

        </div>
        {isDistant && withDistant.includes(type) ?
          <>
            <p className='regular very-bold'>
              *יישוב זה נכלל ביישובים עם זמני ביצוע חריגים ולכן לא ניתן לבחור בדחיפות גבוה יותר ליישוב זה*
            </p>
            <p className='regular'>
              ביקור ראשון תוך 10 ימי עסקים מיום קבלת המסמכים. השלמת המסירה עד חודש
            </p>
          </>
          :
          <>
            <p className='regular'>{getTextByRecipient()}</p>
            {(alignment === 'left' ||
              alignment === 'end') &&
              <p className='regular'>*חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה {alignment === 'end' ? '12:00' : '15:00'} יחל ביום שלמחרת*</p>
            }
          </>
        }
      </div>
    </div>
  );
};

export default UrgencyRecipientUpdate;
