import React, { useState, useEffect } from 'react';

export function RecipientCopies({ data, setData, recipient, type }) {

  const [copiesCount, setCopiesCount] = useState(data?.printingDetails?.recipientsCopies?.[recipient._id] || 1);
  const pagesCount = data?.printingDetails?.pagesCount || 1;
  const withoutRecipientName = ['printingBinding', 'landSupervisor']

  useEffect(() => {

    const updatedRecipientsCopies = {
      ...(data?.printingDetails?.recipientsCopies || {}),
      [recipient._id]: copiesCount
    };

    const sumRecipientCopiesCount = Object.values(updatedRecipientsCopies)
      .reduce((total, recipientCopies) => total + recipientCopies, 0);

    let printPrice = formatNumber((data?.printingDetails?.printPricePerUnit * sumRecipientCopiesCount).toFixed(1));

    const printingDetails = {
      ...(data?.printingDetails || {}),
      copiesCount: sumRecipientCopiesCount,
      printPrice,
      recipientsCopies: updatedRecipientsCopies
    };

    setData({
      ...data,
      printingDetails: {
        ...data?.printingDetails,
        ...printingDetails
      }
    });
  }, [copiesCount]);


  //FOR COUNTER
  const incrementCount = () => {
    setCopiesCount(copiesCount + 1);
  }

  const decrementCount = () => {
    setCopiesCount(copiesCount - 1 < 1 ? 1 : copiesCount - 1);
  }

  const handleCountChange = (event) => {
    setCopiesCount(parseInt(event.target.value.replace(/^0+/, '')) || 1);
  }
  //FOR COUNTER



  const formatNumber = (num) => {
    // Convert the number to a string with one decimal place
    let formattedNum = Number.parseFloat(num).toFixed(1);

    // Remove any trailing zeros if they exist
    if (formattedNum.indexOf('.') !== -1) {
      formattedNum = formattedNum.replace(/\.?0+$/, '');
    }

    // Convert the formatted number back to a number
    return Number.parseFloat(formattedNum);
  }

  const renderCopiesDetails = () => {
    const pageStr = pagesCount === 1 ? 'עמוד אחד' : `${pagesCount} עמודים`;
    const colorStr = data?.printingDetails?.isColourful ? 'צבעוני' : 'שחור לבן';
    const extras = [];
    if (data?.printingDetails?.isSpiralBinding) extras.push('כריכת ספירלה');
    if (data?.printingDetails?.isBindingStrip) extras.push('פס תיוק');
    if (data?.printingDetails?.isDividers) extras.push('דגלונים');
    if (data?.isAudioFileAdded) extras.push('דיסק און קי');
    const extrasStr = extras.length > 0 ? `, תוספת ${extras.join(' + ')}` : '';
    return `הדפסת ${pageStr} ב${colorStr}${extrasStr}`;
  }

  const getPriceText = () => {
    switch (type) {
      case 'printingBinding':
      case 'landSupervisor':
        return `${Number((data?.printingDetails?.printPricePerUnit * copiesCount).toFixed(1))} ₪ / ${copiesCount} עותקים`

      default:
        return `${Number((data?.printingDetails?.printPricePerUnit * copiesCount).toFixed(1))} ₪ / ${copiesCount} עותקים ל${recipient?.personName || recipient?.companyName || recipient?.officeName || ''}`
    }
  }
  return (
    <div className='copies-actions'>
      {!withoutRecipientName.includes(type) &&
        <div className='copies-text-contianer recip-text-contianer'>
          <h3 className='copies-sub-title'>שם יעד:</h3>
          <p>{recipient?.personName || recipient?.companyName || recipient?.officeName || ''}</p>
        </div>
      }

      <div className='copies-text-contianer'>
        <h3 className='copies-sub-title'>תיאור ההדפסה:</h3>
        <p>{renderCopiesDetails()}</p>
      </div>


      <div className='copies-toggle'>
        <h3 className='copies-sub-title'>מספר עותקים</h3>
        <div className="counter">
          <p className="counter-button" onClick={incrementCount}>
            +
          </p>
          <input className='counter-input' type="number" value={copiesCount} onChange={(ev) => { handleCountChange(ev, 'copies'); }} />
          <p className="counter-button" onClick={() => { decrementCount('copies'); }} disabled={copiesCount === 0}>
            -
          </p>
        </div>
      </div>


      <div className='copies-summarize-container'>
        <h3 className='copies-sub-title'>תוספת תשלום</h3>
        <p className='summarize-sub-title'>
          עותק:
          <span className='price-txt'>
            {`${data?.printingDetails?.printPricePerUnit} ₪`}
          </span>
        </p>
        <p className='summarize-sub-title'>
          סה"כ:
          <span className='price-txt'>
            {getPriceText()}
            {/* {`${Number((data?.printingDetails?.printPricePerUnit * copiesCount).toFixed(1))} ₪ / ${copiesCount} עותקים ל${recipient?.personName || recipient?.companyName || recipient?.officeName || ''}`} */}
          </span>
        </p>
      </div>
    </div>
  );
}
