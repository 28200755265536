const Recipients = JSON.parse(localStorage.getItem("lastRecipients")) || [];

function sort(arr) {
  return arr.sort((a, b) => {
    if (a.title.toLocaleLowerCase() < b.title.toLocaleLowerCase()) {
      return -1;
    }
    if (a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase()) {
      return 1;
    }

    return 0;
  });
}

function query(filterBy = null) {
  return new Promise((resolve, reject) => {
    var RecipientsToReturn = Recipients;
    if (filterBy && filterBy.term) {
      RecipientsToReturn = filter(filterBy.term);
    }
    resolve(sort(RecipientsToReturn));
  });
}

function getRecipients(filterBy = null) {
  return new Promise((resolve, reject) => {
    var RecipientsToReturn = Recipients;
    if (filterBy && filterBy.term) {
      RecipientsToReturn = filter(filterBy.term);
    }
    resolve(sort(RecipientsToReturn));
  });
}

function getRecipientById(id) {
  return new Promise((resolve, reject) => {
    const Recipient = Recipients.find((Recipient) => Recipient._id === id);
    Recipient ? resolve(Recipient) : reject(`Recipient id ${id} not found!`);
  });
}

function deleteRecipient(id) {
  return new Promise((resolve, reject) => {
    const index = Recipients.findIndex((Recipient) => Recipient._id === id);
    if (index !== -1) {
      Recipients.splice(index, 1);
    }

    resolve(Recipients);
  });
}

function _updateRecipient(Recipient) {
  return new Promise((resolve, reject) => {
    const index = Recipients.findIndex((c) => Recipient._id === c._id);
    if (index !== -1) {
      Recipients[index] = Recipient;
    }
    resolve(Recipient);
  });
}

function _addRecipient(Recipient) {
  return new Promise((resolve, reject) => {
    Recipient._id = _makeId();
    Recipients.push(Recipient);
    resolve(Recipient);
  });
}

function saveRecipient(Recipient) {
  return Recipient._id ? _updateRecipient(Recipient) : _addRecipient(Recipient);
}

function getEmptyRecipient() {
  return {
    _id: "",
    title: "",
    locations: {},
  };
}

function filter(term) {
  term = term.toLocaleLowerCase();
  return Recipients.filter((Recipient) => {
    return Recipient.title.toLocaleLowerCase().includes(term);
  });
}

function _makeId(length = 10) {
  var txt = "recip";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (var i = 0; i < length; i++) {
    txt += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return txt;
}


const Recipientservice = {
  query,
  getRecipients,
  getRecipientById,
  deleteRecipient,
  saveRecipient,
  getEmptyRecipient,
};

export default Recipientservice;
