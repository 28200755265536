import React from 'react'
import { TailSpin } from 'react-loader-spinner'

export const UserStatusTextBtn = ({ sendDetailsLoading, idx, onSendLoginDetails, user }) => {
   
    return (
        <>
            <p>
                {(user.userActivity === 'blocked' || user?.isDisabled) ? 'חסום'
                    : (user.userActivity === 'not-connected' ? 'טרם התחבר'
                        : 'פעיל')
                }
            </p>
            {user.userActivity === 'not-connected' && !user?.isDisabled &&
                <button className='btn' onClick={() => { onSendLoginDetails(user, idx) }}>
                    {sendDetailsLoading?.idxLoading === idx && sendDetailsLoading?.isSendingDetails ?
                        <div className='spinner'>
                            <TailSpin
                                heigth="15"
                                width="15"
                                color='#4e71ff'
                                ariaLabel='loading'
                            />
                        </div> :
                        sendDetailsLoading?.idxLoading === idx && sendDetailsLoading?.isSendDetails ?
                            'פרטי ההתחברות נשלחו!' : 'שליחת פרטי התחברות'}
                </button>
            }
        </>
    )
}
