import React from 'react';
import { ReactComponent as Draft } from '../../images/draft.svg'
import { ReactComponent as DraftMobile } from '../../images/draft-mobile.svg'
import { ReactComponent as Close } from '../../images/close.svg'
import { useEffect, useState } from 'react';
import { FormsMain } from './FormsMain';
import { updateSystemData } from '../../store/actions/systemActions';
import { dataSet } from '../../store/actions/dataActions';
import { useDispatch, useSelector } from 'react-redux';
import { updateErrorModal, setIsLoading } from '../../store/actions/systemActions';
import { setFiles } from '../../store/actions/fileActions';
import { useNavigate } from 'react-router-dom';
import EmergencyMsgs from '../Header/AppBar/EmergencyMsgs';

// import {utilService} from '../'

export const Modal = () => {
  const navigate = useNavigate();
  const [isShowEmergencyMsgs, setIsShowEmergencyMsgs] = useState(false);
  const systemData = useSelector((state) => state.systemReducer.systemData)
  const orders = useSelector((state) => state.orderReducer.Orders)
  const isPopUpModal = useSelector((state) => state.systemReducer.isPopUpModal);
  const data = useSelector((state) => state.dataReducer.data);
  const currOrder = orders?.find(order => order._id === data._id) || null;
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(dataSet({ files: [], recipients: [] }))
      dispatch(setFiles([]))
      dispatch(updateSystemData({
        ...systemData,
        isFormModalOpen: false,
        isEditMode: false,
      }));
      dispatch(setIsLoading(false))
    }
  }, [])


  useEffect(() => {
    const { isConfrimCloseModal, status } = isPopUpModal;
    if (isConfrimCloseModal && status === 'submit') {
      dispatch(dataSet(!currOrder ? { files: [], recipients: [] } : { ...currOrder }))
      dispatch(updateSystemData({
        ...systemData, isFormModalOpen: false, isEditMode: false, isSpecialUrgencyPrice: false
      }));
      navigate(systemData.isCheckout ? '/checkout' : '/');
      // history.back()
    }
  }, [isPopUpModal.status, isPopUpModal.isConfrimCloseModal]);

  const onToggleModal = () => {
    if (systemData.isFormModalOpen) {
      dispatch(updateErrorModal({ title: 'אזהרה', errorText: 'בלחיצה על אישור ההזמנה תסגר ללא השינויים שבוצעו ', submitText: 'אישור', status: 'close_modal', isCancel: true }));
    }
  };

  return (
    <div id="box011" className={systemData.isFormModalOpen ? "main-modal open" : "main-modal"}>
      <div className="modal-header">
        <div className="modal-title">
          <img src={systemData?.action?.formImage || ''} alt='' />
          <h3 className="modal-title-text">{systemData?.action?.formTitle || ''} </h3>
          <p className="modal-title-sub">{systemData?.action?.formSubtitle ? `(${systemData?.action?.formSubtitle})` : ''}</p>
        </div>
        <div className="modal-btn-container">
          <button className="btn-draft">
            <Draft />
            <p>שמירת טיוטה</p>
          </button>
          <DraftMobile />
          <Close onClick={onToggleModal} />
        </div>
      </div>
      {isShowEmergencyMsgs && data.type === 'apostilSubmission' &&
        <EmergencyMsgs setIsShowEmergencyMsgs={setIsShowEmergencyMsgs} />
      }
      <FormsMain />
    </div>

  );
}