import { useSelector } from 'react-redux';
import { UserActionDoc } from './UserActionDoc';
import { useViewport } from '../../Hooks/useViewport'
import {
    urgencyTranslate,
    urgencyMap,
    getExtraTextUrgency,
    getTextByPages,
    collectMethodMap,
    returnMethodMap
} from '../../consts/forms'

export const UserActionSummary = ({ action, notifications, order }) => {
    const activeUser = useSelector((state) => state.userReducer.user);
    const originAction = action.originalData ? action.originalData : order.cart.orders[0]

    const { width } = useViewport();
    const breakpoint = 1111;
    const isMobile = width < breakpoint;

    // console.log('#################')
    // console.log('UserActionSummary')
    // console.log('originAction', originAction)
    // console.log('activeUser', activeUser)
    // console.log('action', action)
    // console.log('notifications', notifications)
    // console.log('order', order)
    // console.log('#################')

    const renderedCollectAddress = () => {
        const { collectionAddress } = originAction
        if (!collectionAddress) return
        return (
            <p className="details">
                {`רחוב ${collectionAddress.collectStreetAddress}  ${collectionAddress?.collectStreetNumber || ''} , ${collectionAddress.collectCity} ${collectionAddress.floor ? ', קומה: ' + collectionAddress.floor : ''} ${collectionAddress.apartment ? ', דירה: ' + collectionAddress.apartment : ''} ${collectionAddress.collectNotes ? ', הערות: ' + collectionAddress.collectNotes : ''} ${collectionAddress?.mailFolder ? ', ' + collectionAddress?.mailFolder : ''}`}
            </p>
        )
    }

    const renderedreturnAddress = () => {
        const { returnAddress, type } = originAction
        if (!returnAddress) return
        if (type === 'personalDeliveryClaim') {
            return (<p className="details">
                {`בית המשפט לתביעות קטנות ב${returnAddress?.returnCity || ''}, ${returnAddress?.streetStr}`}
            </p>)
        }
        else return (<p className="details">
            {`רחוב ${returnAddress.returnStreetAddress}  ${returnAddress?.returnStreetNumber || ''} , ${returnAddress.returnCity} ${returnAddress.floor ? ', קומה: ' + returnAddress.floor : ''} ${returnAddress.apartment ? ', דירה: ' + returnAddress.apartment : ''} ${returnAddress.returnNotes ? ', הערות: ' + returnAddress.returnNotes : ''} ${returnAddress?.mailFolder ? ', ' + returnAddress?.mailFolder : ''}`}
        </p>)
    }

    const getTextDocTransferType = () => {
        const { typeOfTransfer, isDoubleTransfer } = originAction

        return (
            <>
                <p className="details">
                    {typeOfTransfer === 'fromMe' ? 'שליחות ממני לנמען (ממני אל)' : (typeOfTransfer === 'toMe' ? 'איסוף מהנמען אלי (הביאו אלי)' : 'העברה בין שני צדדים')}
                </p>
                {isDoubleTransfer &&
                    <p className="details">
                        שליחות כפולה (הלוך וחזור)
                    </p>
                }
            </>
        )
    }

    const renderDocTransferPersonDetails = (isCollect) => {
        const { returnAddress, collectionAddress } = originAction
        return (
            <>
                <p className="details">
                    {`שם איש קשר/חברה: ${isCollect ? collectionAddress.personName : returnAddress.personName}`}
                </p>
                <p className="details">
                    {`טלפון נייד: ${isCollect ? collectionAddress.personPhone : returnAddress.personPhone}`}
                </p>
                {isCollect && (collectionAddress.collectNotes || collectionAddress.comments) &&
                    <p className="details">
                        {`הערות: ${collectionAddress.collectNotes || collectionAddress.comments}`}
                    </p>}

                {!isCollect && (returnAddress.returnNotes || returnAddress.comments) &&
                    <p className="details">
                        {`הערות: ${returnAddress.returnNotes || returnAddress.comments}`}
                    </p>}
            </>
        )
    }

    const renderedPrices = () => {
        const { prices } = action
        if (!prices.data.totalToll) prices.data.totalToll = 0
        return <div>{prices.data.summary.map((price, idx) => {
            return <div key={price.compHeader} className="cost-list-item">
                <h4>{price.compHeader}</h4>
                {price.compItems.map((compitem, idx) => {
                    return <div className="cost-item" key={compitem.description + idx}>
                        {compitem?.description && <div className="cost-item-description">
                            <p className="sud-title">{compitem.description}</p>
                            {(compitem.isDisplay) && <p className='price'>{compitem.price ? compitem.price : '0'} ₪</p>}
                        </div>
                        }
                        {compitem?.subItems &&
                            compitem?.subItems?.map((sub, idx) => {
                                return <>
                                    <div key={sub.description + idx} className="cost-item-description">
                                        <p className='cost-item-sub-description'>{sub.description}</p>
                                        {sub.isDisplay && <p className='price'>{sub.price ? (sub.price).toFixed(2) : '0'} ₪</p>}
                                    </div>
                                </>
                            })
                        }
                    </div>
                })}
            </div>
        })}
            <div className='total'>
                <div className='total-item'>
                    <p>סכום ביניים</p>
                    <p className='price bold'>{(prices.data?.totalPriceBeforeDiscount && ((prices.data.totalPriceBeforeDiscount)).toFixed(2)) || ((prices.data.total)).toFixed(2)} ₪</p>
                </div>
                {(activeUser.fullName && prices.data?.totalPriceBeforeDiscount) && <div className='total-item'>
                    <p>הנחה {activeUser.discountPercentage}%</p>
                    <p className='price bold'>{(prices.data?.totalPriceBeforeDiscount * (activeUser.discountPercentage / 100)).toFixed(2)}- ₪</p>
                </div>}

                {prices.data?.vat ?
                    <div className='total-item'>
                        <p>מע"מ</p>
                        <p className='price bold'> {(prices.data.vat.toFixed(2))} ₪</p>
                    </div>
                    : <></>}

                {prices.data?.totalToll ?
                    <div className='total-item'>
                        <p>סה״כ אגרה לתשלום</p>
                        <p className='price bold'> {(prices.data.totalToll.toFixed(2))} ₪</p>
                    </div>
                    :
                    <></>}
                {type === 'smallClaim' ?
                    <div className='total-item'>
                        <p>
                            {'[אגרת בית משפט 1% מסך התביעה בעיגול כלפי מעלה למכפלה של 5₪, או 50₪, לפי הגבוה]'}
                        </p>
                    </div>
                    :
                    <></>}



                <div className='total-item'>
                    <p className='bold'>סה"כ לתשלום </p>
                    <p className='price bold'>{(((prices.data.total) + (prices.data.vat) + (prices.data.totalToll))).toFixed(2)} ₪</p>
                </div>
            </div>
        </div >
    }

    const renderedRecipients = () => {
        // if (!action.action._id) return
        // console.log('_id', action.action._id)

        let FilterRecipients = []
        if (action.recipients) {
            FilterRecipients = action.recipients
        } else if (type === 'personalDelivery') {
            if (action?.action) {
                const recipient = {
                    personId: action?.action?.personId || action?.action?.companyId || '',
                    personName: action?.action?.personName || action?.action?.companyName || '',
                    streetAddress: action?.action?.streetAddress || '',
                    streetNumber: action?.action?.streetNumber || '',
                    city: action?.action?.city || '',
                    type: action?.action?.type || '',
                    _id: action?.action?.id || '',
                    urgency: action?.action?.urgency || ''
                }
                FilterRecipients.push(recipient)
            }
        } else {
            const currOrder = order.cart.orders.filter(ord => action.id.includes(ord._id))
            // order.cart.orders[0].orderActions.forEach(recipient => {
            currOrder[0].orderActions.forEach(recipient => {
                FilterRecipients.push(recipient)
            });
        }
        return FilterRecipients.map((recipient, idx) => {
            let recipientDetails
            switch (type) {
                case 'courtSubmission':
                case 'personalDelivery':
                case 'officeDelivery':
                    recipientDetails = `${recipient?.companyName || recipient?.personName || recipient?.officeName} , ${recipient?.streetAddress} ${recipient?.streetNumber} ${recipient?.city}.  `
                    break;
                case 'addressLocate':
                case 'phoneLocate':
                case 'personLocate':
                    recipientDetails = `שם מלא: ${recipient.personFirstName} ${recipient.personLastName}${recipient?.personId ? ` , ת.ז: ${recipient?.personId}` : ''}.`
                    break;
                case 'idLocate':
                    recipientDetails = `שם מלא: ${recipient.personFirstName} ${recipient.personLastName}${recipient?.personPhone ? `, מספר פלאפון: ${recipient?.personPhone}` : ''}`
                    break;
                default:
                    break;
            }
            return (<>
                <p key={idx} className="details">{recipientDetails}</p>
                {recipient.urgency && <p key={recipient.urgency + idx} className="details">    {`דחיפות: ${urgencyTranslate[recipient?.urgency] + '- ' + urgencyMap[type][recipient?.urgency]} `} </p>}
                {(action.type === 'officeDelivery' || action.type === 'personalDelivery') && (action.action.urgency === 'VERY_URG' || action.action.urgency === 'URG') &&
                    <p className='details'>*חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה {action.action.urgency === 'VERY_URG' ? '12:00' : '15:00'} יחל ביום שלמחרת*</p>
                }
            </>
            );
        })
    }

    const renderedCourt = () => {
        const isShowCity = originAction?.courtAddressDetails?.courtName.includes(originAction?.courtAddressDetails?.city)
        return <p className='details'>{originAction?.courtAddressDetails?.courtName} {isShowCity ? '' : ` ב${originAction?.courtAddressDetails?.city}`}</p>
    }

    const renderedPrintingDetails = () => {
        return <p className='details'>{`${originAction?.printingDetails?.printTxt || ''}`}</p>
    }

    const renderedDefendants = (defendant, idx) => {
        if (defendant.isPersonalOrCompanyDefendant === 'personal') {
            return (<>
                <p key={defendant.defendantId + idx} className="details">{`נתבע/ת ${idx + 1} - ${defendant.defendantFirstName} ${defendant.defendantLastName}`}</p>
            </>
            );
        } else {
            return (<>
                <p key={defendant.defendantId + idx} className="details">{`נתבע/ת ${idx + 1} - ${defendant.defendantCompanyName}`}</p>
            </>
            );
        }
    }

    // const { type } = originAction
    const type = originAction?.type || action?.type

    return (
        <div className={`action-content-container summary-content `}>

            <div className={`summary-info-content right`} >
                <h2 className='summary-titles'>סיכמנו בשבילך</h2>

                <div className="order-summary-list">

                    {((type === 'landRegistration') || (type === 'inheritanceRegistration') || (type === 'companiesRegistration')
                        || (type === 'partnershipsRegistration') || (type === 'associationsAndCompanies')) && (<>
                            <div className="order-summary-item">
                                <div className="item-details">
                                    <h4>שם הלשכה</h4>
                                    <p>{action.branchAddress.city}</p>
                                    <p> {action.branchAddress.fullAddress} </p>
                                </div>
                            </div>
                            {action?.depositFiles && <div className="order-summary-item">
                                <div className="item-details">
                                    <h4>תיקים להפקדה</h4>
                                    <p>כמות התיקים להפקדה: {action?.depositFiles?.filesCount}</p>
                                </div>
                            </div>}
                            {action?.collectFiles &&
                                <div className="order-summary-item">
                                    <div className="item-details">
                                        <h4>תיקים לאיסוף</h4>
                                        {action?.collectFiles?.map((submiter, idx) => {
                                            return <div className="submiter-container" >
                                                <div className="details">
                                                    <p className="submiter">שם המגיש: {submiter.name}</p>
                                                    <p className="submiter">תעודת זהות: {submiter.idNum}</p></div>
                                                {submiter.files.map(file => {
                                                    return <div className="details-file">
                                                        <p>מספר התיק: {file.fileNum}</p>
                                                        <p>גוש: {file.block}</p>
                                                        <p>חלקה: {file.lot}</p>
                                                        <p>תת חלקה: {file.subLot}</p>
                                                    </div>
                                                })
                                                }
                                            </div>
                                        })}
                                    </div>
                                </div>
                            }
                        </>
                        )}

                    {type === 'courtSubmission' && (
                        <div className="order-summary-item">
                            <div className="item-details">
                                <h4>ערכאה</h4>
                                {renderedCourt()}
                            </div>
                        </div>
                    )}

                    {type === 'printingBinding' && (
                        <div className="order-summary-item">
                            <div className="item-details">
                                <h4>מאפייני ההדפסה</h4>
                                {renderedPrintingDetails()}
                            </div>
                        </div>
                    )}


                    {(type === 'addressLocate' || type === 'phoneLocate' || type === 'personLocate' || type === 'idLocate') && (
                        <div className="order-summary-item">
                            <div className="item-details">
                                <h4>מאותרים</h4>
                                {renderedRecipients()}
                            </div>
                        </div>
                    )}

                    {(type === 'addressLocateClaim' || type === 'phoneLocateClaim' || type === 'idLocateClaim') && (
                        <div className="order-summary-item">
                            <div className="item-details">
                                <h4>מאותרים</h4>
                                <p className="details">{`שם מלא: ${originAction.recipient.personFirstName} ${originAction.recipient.personLastName}${originAction.recipient.personId ? ` , ת.ז: ${originAction.recipient.personId}` : ''}`}</p>
                            </div>
                        </div>
                    )}

                    {(type === 'personalDelivery' || type === 'officeDelivery' || (type === 'courtSubmission' && action.action._id)) && (
                        <div className="order-summary-item">
                            <div className="item-details">

                                {(type === 'personalDelivery' || type === 'courtSubmission') ? <h4>נמענים</h4> : <h4>משרדים</h4>}
                                {renderedRecipients()}
                            </div>
                        </div>
                    )}
                    {type === 'personalDeliveryClaim' && (
                        <div className="order-summary-item">
                            <div className="item-details">
                                <h4>נמענים</h4>
                                <p className="details">{`${originAction.recipient?.name} , ${originAction?.recipient?.streetAddress} ${originAction?.recipient?.streetNumber} ${originAction?.recipient?.city}`}</p>
                                {originAction?.urgency && <p className="details">    {`דחיפות: ${urgencyTranslate[originAction?.urgency] + '- ' + urgencyMap['personalDelivery'][originAction?.urgency]} `} </p>}
                                {(originAction?.urgency === 'VERY_URG' || originAction?.urgency === 'URG') &&
                                    <p className='details'>*חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה {originAction?.urgency === 'VERY_URG' ? '12:00' : '15:00'} יחל ביום שלמחרת*</p>
                                }
                            </div>
                        </div>
                    )}


                    {(type === 'personalDelivery' || type === 'officeDelivery' || type === 'courtSubmission' || type === 'inheritanceRegistration') && (
                        <div div className="order-summary-item">
                            <div className="item-details">
                                {((type === 'courtSubmission' && originAction.isFirstSubmission !== 'collect') || (type === 'inheritanceRegistration')) && <h4>מה מגישים</h4>}
                                {(type === 'courtSubmission' && originAction.isFirstSubmission === 'collect') && <h4>מה אוספים</h4>}
                                {!(type === 'courtSubmission') && !(type === 'inheritanceRegistration') && <h4>מה מוסרים</h4>}
                                <div className="handed-list">{Array.isArray(originAction?.docsForSubmission) ? originAction?.docsForSubmission?.map(doc => {
                                    return <div className="item"> <p>{doc.documentName}</p>  {doc.caseNumber && <p className="details" > מספר התיק: {doc.caseNumber} </p>} </div>
                                }) :
                                    <p className='details'>{originAction?.docsForSubmission}</p>}</div>
                            </div>
                        </div>
                    )}

                    {((type === 'companiesRegistration') || (type === 'partnershipsRegistration') || (type === 'associationsAndCompanies')) && (
                        <div div className="order-summary-item">
                            <div className="item-details">
                                <h4>מה מגישים</h4>
                                <div className="handed-list">{originAction?.docsForSubmission?.map(doc => {
                                    return <div className="item"> <p>{doc.documentName}</p>  {doc.caseNumber && <p className="details" > מספר התיק: {doc.caseNumber} </p>} </div>
                                })}</div>
                            </div>
                        </div>)}

                    {(type === 'apostilSubmission' || type === 'apostilInCourt') && <div className="order-summary-item">
                        <div className="item-details">
                            <h4>התעודות שלי</h4>
                            {action?.certificates?.map((certificate, idx) => {
                                return <>
                                    <p key={certificate.name + idx} className="details">{certificate.name} -  סה״כ תעודות:   {certificate.counter}</p>
                                    {certificate.isVerification && <p className="details">כולל ביצוע אימות מקדים</p>}
                                    {certificate.isToll && <p className="details">כולל תשלום אגרה</p>}
                                </>
                            })}
                        </div>
                    </div>}

                    {type === 'documentTransfer' && (
                        <div className="order-summary-item">
                            <div className="item-details">
                                <h4>סוג השליחות</h4>
                                {getTextDocTransferType()}
                            </div>
                        </div>
                    )}

                    {originAction?.collectionMethod && <div className="order-summary-item">
                        <div className="item-details">
                            <h4>{originAction.type === 'documentTransfer' ? 'מוצא' : 'איך מקבלים'}</h4>
                            <p>
                                {collectMethodMap[type](originAction?.collectionMethod) === 'איסוף עם שליח'
                                    ? 'עם שליח' : collectMethodMap[type](originAction?.collectionMethod)}
                            </p>
                            {originAction?.collectionMethod !== 'upload' && <div>{renderedCollectAddress()}</div>}
                            {originAction?.printingDetails?.printTxt && originAction?.prices?.data?.total === -1 && <p className="details">{originAction?.printingDetails?.printTxt}</p>}
                            {originAction?.collectionMethod === 'upload' && <p className="details">{getTextByPages(originAction?.pages)}</p>}
                            {originAction?.collectionMethod === 'upload' && originAction?.bindings && <p className="details">כמות כריכות ודגלונים: {originAction?.bindings}</p>}
                            {originAction.type === 'documentTransfer' && originAction.collectionAddress.personName ? renderDocTransferPersonDetails(true) : ''}
                        </div>
                    </div>
                    }

                    {type === 'landRegistration' && <div className="order-summary-item">
                        <div className="item-details">
                            <h4>הכתובת שלך</h4>
                            <div>{renderedCollectAddress()}</div>
                        </div>
                    </div>
                    }

                    {originAction?.returnMethod && <div className="order-summary-item">
                        <div className="item-details">
                            <h4>{originAction.type === 'documentTransfer' ? 'יעד' : 'איך מחזירים'}</h4>
                            <p>
                                {originAction?.returnMethod && returnMethodMap[type](originAction?.returnMethod)}
                            </p>

                            {originAction?.returnMethod !== 'upload' && <div>{renderedreturnAddress()}</div>}
                            {originAction?.returnMethod === 'upload' && <p className="details">הוכחת הביצוע תשלח בדוא"ל</p>}
                            {originAction.type === 'documentTransfer' && originAction.returnAddress.personName ? renderDocTransferPersonDetails(false) : ''}
                        </div>
                    </div>}

                    {type === 'smallClaim' && (
                        <div className="order-summary-item">
                            <div className="item-details">
                                <h4>נושא התביעה</h4>
                                <p className='details'>{originAction?.claimType || ''}</p>
                            </div>
                        </div>
                    )}

                    {type === 'smallClaim' && <div className="order-summary-item">
                        <div className="item-details">
                            <h4>הצדדים</h4>
                            {originAction?.claimants?.map((claimant, idx) => {
                                return <>
                                    <p key={claimant.claimantId + idx} className="details">{`תובע/ת ${idx + 1} - ${claimant.claimantFirstName || ''} ${claimant.claimantLastName || ''}`}</p>
                                </>
                            })}
                            {originAction?.defendants?.map((defendant, idx) => {
                                return renderedDefendants(defendant, idx)
                            })}
                        </div>
                    </div>}

                    {type === 'smallClaim' && <div className="order-summary-item">
                        <div className="item-details">
                            <h4>תיאור התביעה</h4>
                            <p className='details'>{`
                                  בית משפט לתביעות קטנות 
                                    ${'ב' + (originAction?.claimDescription?.court?.courtName || '')}
                                       `
                            }</p>
                        </div>
                    </div>}

                    {originAction?.urgency && <div className="order-summary-item">
                        <div className="item-details">
                            <h4>רמת הדחיפות</h4>
                            <p className="details" >
                                {urgencyTranslate[originAction?.urgency] + '- ' + urgencyMap[originAction?.isSpecialUrgencyPrice && type === 'documentTransfer' ? 'specialUrgencyPrice' : type][originAction?.urgency] + originAction?.isSpecialUrgencyPrice === 'next' && type === 'documentTransfer' ? " (הביצוע יהיה ביום העסקים הבא)" : ""}
                            </p>
                            <p className='details'>{getExtraTextUrgency(originAction?.urgency, type, true)}</p>
                        </div>
                    </div>}

                </div>

                <div className='optional-details'>
                    {action.contact.notes &&
                        <div className='action-notes'>
                            <h3 className='title'>הערות נוספות להזמנה</h3>
                            <p className='sub-title notes'>{action.contact.notes}</p>
                        </div>
                    }
                    <div className='action-contact'>
                        {(action.contact.authorizer || action.contact.billedCase) &&
                            <h3>לשימוש פנימי</h3>
                        }
                        {action.contact.billedCase &&
                            <div className='authorizer'>
                                <h3 className='title'>תיק לחיוב</h3>
                                <p className='sub-title contact'>{action.contact.billedCase}</p>
                            </div>
                        }
                        {action.contact.authorizer &&
                            <div>
                                <h3 className='title'>גורם מאשר</h3>
                                <p className='sub-title contact'>{action.contact.authorizer}</p>
                            </div>
                        }
                    </div>

                </div>
            </div>

            {(action.prices.data.total && (action.prices.data.total !== -1)) ?
                <>
                    <div className='line'></div>
                    <div className='price-info-content left'>
                        <h2 className='title summary-titles'>עלויות</h2>
                        {renderedPrices()}
                    </div>
                </>
                :
                <></>
            }


            {/* <div className='border' /> */}
            {/* MOVED DOWN FOR DEPLOY*/}
            {/* {(width > breakpoint) &&
                <>
                    <UserMsgs actionNotifications={notifications} isNotificationsList={true} />
                </>
            } */}
            {!isMobile &&
                <UserActionDoc action={action} showProofsAndDocuments={'printDoc'} />
            }
            {!isMobile &&
                <UserActionDoc action={action} showProofsAndDocuments={'proof'} />
            }
        </div>

    )
}
