import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import edit from '../../../images/edit.svg';
import { handlePricesByFormData } from '../formsOrderSubmit';
import { Tooltip } from '../../Utils/Tooltip';
import { Field } from 'formik';
import { TailSpin } from 'react-loader-spinner'
import { setIsLoading, updateSystemData } from '../../../store/actions/systemActions'
import { PriceErrorPopup } from '../../Main/PriceErrorPopup'
import {
  urgencyTranslate,
  urgencyMap,
  getExtraTextUrgency,
  getTextByPages,
  collectMethodMap,
  returnMethodMap
} from '../../../consts/forms'
import { summarizeAction } from '../../../integrations/firebase'


export function FinalPage({ setSubmitForm, submitForm, isError, setTouch, touch, errors, handleChanges, setFormState, type, setData, formState, setStep }) {
  const data = useSelector((state) => state.dataReducer.data);
  const [prices, setPrices] = useState({})
  const [priceError, setPriceError] = useState(false);
  const recipients = useSelector((state) => state.recipientReducer.Recipients);
  const orderRecipientsIds = useSelector((state) => state.dataReducer.data.recipients);
  const phoneRegex = /^\d{9,10}$/;
  const isLoading = useSelector((state) => state.systemReducer.isLoading)
  const dispatch = useDispatch();
  const activeUser = useSelector((state) => state.userReducer.user);
  const systemData = useSelector((state) => state.systemReducer.systemData);
  // just for deploy
  // console.log('data', data)
  // console.log('systemData', systemData)
  if (!activeUser?.pricelessActions) {
    activeUser.pricelessActions = []
  }
  const isPriceless = (activeUser?.pricelessActions?.includes(systemData.action.type))
  // const isPriceless = (activeUser?.pricelessActions?.includes(systemData.action.type)) && activeUser.paymentMethod === 'PRICESLESS'
  useEffect(() => {
    setFormState({ ...formState, isFinalStep: true });

    async function fetchSpecialPrice() {
      const res = await summarizeAction({ data, type: 'checkSpecialPrice' });
      dispatch(updateSystemData({
        ...systemData,
        isSpecialUrgencyPrice: res.data
      }));
      setData({
        ...data,
        contactDeliveryRecipientName: activeUser?.fullName || '',
        contactDeliveryRecipientPhone: activeUser?.phoneNumber || '',
        isSpecialUrgencyPrice: res.data || false,
      })
      return res.data
    }

    if (type === 'documentTransfer') {
      fetchSpecialPrice()
    } else {
      setData({
        ...data,
        contactDeliveryRecipientName: activeUser?.fullName || '',
        contactDeliveryRecipientPhone: activeUser?.phoneNumber || '',
      })
    }

    async function fetchData() {
      dispatch(setIsLoading(true))
      try {
        let res
        if (type === 'documentTransfer') {
          res = await summarizeAction({ data, type: 'checkSpecialPrice' });
          dispatch(updateSystemData({
            ...systemData,
            isSpecialUrgencyPrice: res.data
          }));
        }
        const pricesData = await handlePricesByFormData(type, {
          ...data,
          isSpecialUrgencyPrice: res?.data || false,
          user: activeUser.fullName ? { ...activeUser } : null,
          contactDeliveryRecipientName: activeUser?.fullName || '',
          contactDeliveryRecipientPhone: activeUser?.phoneNumber || ''
        }, recipients)
        setPrices(pricesData)
      } catch (err) {
        setPriceError(true)
      }
    }


    if (!isPriceless) {
      fetchData();
    }
    return () => {
      setFormState({ ...formState, isFinalStep: false });
    }
  }, []);

  useEffect(() => {

    const isPrices = prices?.hasOwnProperty('data') || null;
    if (isPrices) {
      setData({ ...data, prices })
      dispatch(setIsLoading(false))
    }
    // // edit bug could be when edit and then go fast to submit
    else if (!isPrices) {
      setData({ ...data, prices: { ...prices, data: { total: -1 } } })
    }
  }, [prices])


  const handleChangeData = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setTouch({ ...touch, [field]: true });
    setData({
      ...data,
      [field]: value,
    });
    handleChanges(event)
  };

  useEffect(() => {
    if ((errors.contactDeliveryRecipientName || errors.contactDeliveryRecipientPhone) && (submitForm)) {
      document.getElementById('contact').focus();
      setSubmitForm(false)
    }
    if (submitForm) {
      setTouch({
        ...touch,
        contactDeliveryRecipientName: true,
        contactDeliveryRecipientPhone: true,
        contactDeliveryRecipientFactor: true,
        contactDeliveryRecipientCase: true
      })
    }
  }, [errors, submitForm]);

  const editPage = (e, pageName) => {
    e.preventDefault();
    e.stopPropagation();
    const { formSteps } = formState;
    const idx = formSteps.findIndex((page) => page === pageName);
    setFormState((prevState) => ({
      ...prevState,
      nextStep: formSteps[idx],
      isFinalStep: false
    }));
    setStep(idx);
  };

  const handleTouch = (e) => {
    const field = e.target.name;
    setTouch({ ...touch, [field]: true });
  };

  const getTextDocTransferType = () => {
    const { typeOfTransfer, isDoubleTransfer } = data

    return (
      <>
        <p className="details">
          {typeOfTransfer === 'fromMe' ? 'שליחות ממני לנמען (ממני אל)' : (typeOfTransfer === 'toMe' ? 'איסוף מהנמען אלי (הביאו אלי)' : 'העברה בין שני צדדים')}
        </p>
        {isDoubleTransfer &&
          <p className="details">
            שליחות כפולה (הלוך וחזור)
          </p>
        }
      </>
    )
  }

  const renderedRecipients = () => {
    if (!data.recipients) return
    const FilterRecipients = recipients.filter((recipient) =>
      data.recipients.includes(recipient._id)
    );

    return FilterRecipients.map((recipient, idx) => {
      let recipientDetails
      switch (type) {
        case 'courtSubmission':
        case 'personalDelivery':
        case 'officeDelivery':
          recipientDetails = `${recipient?.companyName || recipient?.personName || recipient?.officeName} , ${recipient?.streetAddress} ${recipient?.streetNumber} ${recipient?.city}.  `
          break;
        case 'addressLocate':
        case 'phoneLocate':
        case 'personLocate':
          recipientDetails = `שם מלא: ${recipient.personName} ${recipient.personLastName} , ת.ז: ${recipient?.personId}.  `
          break;
        case 'idLocate':
          recipientDetails = `שם מלא: ${recipient.personName} ${recipient.personLastName}`
          break;
        default:
          break;
      }
      return (<>
        <p key={idx} className="details">{recipientDetails}</p>
        {recipient.urgency && <p key={recipient.urgency + idx} className="details">    {`דחיפות: ${urgencyTranslate[recipient?.urgency] + '- ' + urgencyMap[type][recipient?.urgency]} `} </p>}
        {(data.type === 'officeDelivery' || data.type === 'personalDelivery' || data.type === 'courtSubmission') && (FilterRecipients[idx].urgency === 'VERY_URG' || FilterRecipients[idx].urgency === 'URG') &&
          <p className='details'>*חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה {FilterRecipients[idx].urgency === 'VERY_URG' ? '12:00' : '15:00'} יחל ביום שלמחרת*</p>
        }
      </>
      );
    })
  }

  const renderedCourt = () => {
    const isShowCity = data?.courtAddressDetails?.courtName.includes(data?.courtAddressDetails?.city)
    return <p className='details'>{data?.courtAddressDetails?.courtName} {isShowCity ? '' : ` ב${data?.courtAddressDetails?.city}`}</p>
  }

  const renderedPrintingDetails = () => {
    return <p className='details'>{`${data?.printingDetails?.printTxt || ''}`}</p>
  }

  const renderedDefendants = (defendant, idx) => {
    if (defendant.isPersonalOrCompanyDefendant === 'personal') {
      return (<>
        <p key={defendant.defendantId + idx} className="details">{`נתבע/ת ${idx + 1} - ${defendant.defendantFirstName} ${defendant.defendantLastName}`}</p>
      </>
      );
    } else {
      return (<>
        <p key={defendant.defendantId + idx} className="details">{`נתבע/ת ${idx + 1} - ${defendant.defendantCompanyName}`}</p>
      </>
      );
    }
  }

  const renderedPrices = () => {
    if (isPriceless) {
      return <></>
    }
    if (!prices) return
    const { data } = prices
    if (!data) return
    return <div>{prices.data.summary.map((price, idx) => {
      return <div key={price.compHeader} className="cost-list-item">
        <h4>{price.compHeader}</h4>

        {price.compItems.map((compitem, idx) => {
          return <div className="cost-item" key={compitem.description + idx}>
            {compitem?.description && <div className="cost-item-description">
              <p className="sud-title">{compitem.description}</p>
              {(compitem.isDisplay) && <p className='price'>{compitem.price} ₪</p>}
            </div>
            }
            {compitem?.subItems &&
              compitem?.subItems?.map((sub, idx) => {
                return <div key={sub.description + idx} className="cost-item-description">
                  <p className='cost-item-sub-description'>{sub.description}</p>
                  {sub.isDisplay && <p className='price'>{sub.price} ₪</p>}
                </div>
              })
            }
          </div>
        })}

      </div>
    })
    }
      {
        prices.data.isBid ?
          <div className='total'>
            <div className='total-item'>
              {/* <p className='bold'>סה"כ לתשלום </p> */}
              <p className='price bold bid'>{'לאחר השלמת ההזמנה ניצור איתך קשר ונשלח אליך הצעת מחיר'}</p>
            </div>
          </div>
          :
          <div className='total'>
            <div className='total-item'>
              <p>סכום ביניים</p>
              <p className='price bold'>{(prices.data?.totalPriceBeforeDiscount && ((prices.data.totalPriceBeforeDiscount)).toFixed(2)) || ((prices.data.total)).toFixed(2)} ₪</p>
            </div>
            {(activeUser.fullName && prices.data?.totalPriceBeforeDiscount) && <div className='total-item'>
              <p>הנחה {activeUser.discountPercentage}%</p>
              <p className='price bold'>{(prices.data?.totalPriceBeforeDiscount * (activeUser.discountPercentage / 100)).toFixed(2)}- ₪</p>
            </div>}

            <div className='total-item'>
              <p>מע"מ</p>
              <p className='price bold'> {(prices.data.vat.toFixed(2))} ₪</p>
            </div>

            {prices.data?.totalToll ?
              <div className='total-item'>
                <p>
                  {'סה"כ אגרה לתשלום'}
                </p>
                <p className='price bold'> {(prices.data.totalToll.toFixed(2))} ₪</p>
              </div>
              :
              <></>}
            {type === 'smallClaim' ?
              <div className='total-item'>
                <p>
                  {'[אגרת בית משפט 1% מסך התביעה בעיגול כלפי מעלה למכפלה של 5₪, או 50₪, לפי הגבוה]'}
                </p>
              </div>
              :
              <></>}

            <div className='total-item'>
              <p className='bold'>סה"כ לתשלום </p>
              <p className='price bold'>{(((prices.data.total) + (prices.data.vat) + (prices.data.totalToll))).toFixed(2)} ₪</p>
            </div>
          </div>
      }
    </div >
  }

  const renderedCollectAddress = () => {
    const { collectionAddress } = data
    if (!collectionAddress) return
    return (
      <p className="details">
        {`רחוב ${collectionAddress.collectStreetAddress}  ${collectionAddress?.collectStreetNumber || ''} , ${collectionAddress.collectCity} ${collectionAddress.floor ? ', קומה: ' + collectionAddress.floor : ''} ${collectionAddress.apartment ? ', דירה: ' + collectionAddress.apartment : ''} ${collectionAddress.collectNotes ? ', הערות: ' + collectionAddress.collectNotes : ''} ${collectionAddress?.mailFolder ? ', ' + collectionAddress?.mailFolder : ''}`}
      </p>
    )
  }

  const renderedreturnAddress = () => {
    const { returnAddress } = data
    if (!returnAddress) return
    return (
      <p className="details">
        {`רחוב ${returnAddress.returnStreetAddress}  ${returnAddress?.returnStreetNumber || ''} , ${returnAddress.returnCity} ${returnAddress.floor ? ', קומה: ' + returnAddress.floor : ''} ${returnAddress.apartment ? ', דירה: ' + returnAddress.apartment : ''} ${returnAddress.returnNotes ? ', הערות: ' + returnAddress.returnNotes : ''} ${returnAddress?.mailFolder ? ', ' + returnAddress?.mailFolder : ''}`}
      </p>)
  }

  const renderDocTransferPersonDetails = (isCollect) => {
    const { returnAddress, collectionAddress } = data
    return (
      <>
        <p className="details">
          {`שם איש קשר/חברה: ${isCollect ? collectionAddress.personName : returnAddress.personName}`}
        </p>
        <p className="details">
          {`טלפון נייד: ${isCollect ? collectionAddress.personPhone : returnAddress.personPhone}`}
        </p>

        {isCollect && (collectionAddress.collectNotes || collectionAddress.comments) &&
          <p className="details">
            {`הערות: ${collectionAddress.collectNotes || collectionAddress.comments}`}
          </p>}

        {!isCollect && (returnAddress.returnNotes || returnAddress.comments) &&
          <p className="details">
            {`הערות: ${returnAddress.returnNotes || returnAddress.comments}`}
          </p>}
      </>
    )
  }

  const getPageNameByTypeAndAction = (type, action) => {
    switch (type) {
      case 'apostilSubmission':
      case 'apostilInCourt':
        return getApostilPagesByAction(action);
      case 'courtSubmission':
        return getCourtSubmissionPagesByAction(action);
      case 'personalDelivery':
      case 'officeDelivery':
        return getDeliveryPagesByAction(action);
      case 'landRegistration':
        return getLandRegistrationByAction(action);
      case 'landSupervisor':
        if (data.actionInOffice === 'depositFile') return 'ActionInOffice';
        else return getLandRegistrationByAction(action);
      case 'inheritanceRegistration':
      case 'companiesRegistration':
      case 'partnershipsRegistration':
      case 'associationsAndCompanies':
        return getInheritRegistrationByAction(action);
      case 'documentTransfer':
        return 'DocumentTransferType';
      case 'personLocate':
        return 'Proof3';
      case 'printingBinding':
        return 'CollectMethod';
      default:
        return ''
    }
  }

  const getRecipientsPageNameByAction = () => {
    switch (type) {
      case 'officeDelivery':
      case 'personalDelivery':
        const myRecipients = recipients.filter((recipient) => orderRecipientsIds.includes(recipient._id));
        const editRecipient = myRecipients[myRecipients.length - 1]
        setFormState((prevState) => ({
          ...prevState,
          currRecipient: editRecipient
        }));
        if (data.recipients.length === 1) {
          if (type === 'personalDelivery') return 'PersonalDeliveryPage1'
          else if (type === 'officeDelivery') return 'OfficeDeliveryFirst'
        } else return 'Recipients'
      default:
        return 'Recipients'
    }
  }

  const getDocumentTransferPagesByAction = (action) => {
    const { typeOfTransfer } = data
    if (typeOfTransfer === 'fromMe') {
      switch (action) {
        case 'collectAddress':
          return 'AddressCollectDocuments';
        case 'returnAddress':
          return 'PersonalOrCompany';
        default:
          return ''
      }
    } else if (typeOfTransfer === 'toMe') {
      switch (action) {
        case 'collectAddress':
          return 'PersonalOrCompany';
        case 'returnAddress':
          return 'AddressReturningDocuments';
        default:
          return ''
      }
    } else {
      switch (action) {
        case 'collectAddress':
          return 'PersonalOrCompany';
        case 'returnAddress':
          return 'PersonalAddress';
        default:
          return ''
      }
    }
  }

  const getDeliveryPagesByAction = (action) => {
    switch (action) {
      case 'returnAddress':
        return 'Proof'
      case 'Proof3':
        return 'Proof3'
      case 'collectAddress':
        return 'CollectDocumentsOrUploadFile';
      case 'CollectDocumentsOrUploadFile2':
        return 'CollectDocumentsOrUploadFile2'
      case 'documentName':
        return 'WhatHanded';
      default:
        return ''
    }
  }

  const getInheritRegistrationByAction = (action) => {
    switch (action) {
      case 'collectAddress':
        return 'CollectDocumentsOrUploadFile2';
      case 'documentName':
        return 'WhatServedRegist';
      case 'returnAddress':
        return 'Proof3'
      default:
        return ''
    }
  }
  const getLandRegistrationByAction = (action) => {
    switch (action) {
      case 'collectAddress':
        return 'AddressCollectDocumentsUpdate';
      default:
        return ''
    }
  }

  const getApostilPagesByAction = (action) => {
    switch (action) {
      case 'returnAddress':
        return 'CertificateReturnMethod'
      case 'collectAddress':
        return 'CertificateCollectMethod';
      default:
        return ''
    }
  }

  const getCourtSubmissionPagesByAction = (action) => {
    switch (action) {
      case 'returnAddress':
        return 'Proof'
      case 'collectAddress':
        return 'CollectDocumentsOrUploadFile';
      case 'CollectDocumentsOrUploadFile2':
        return 'CollectDocumentsOrUploadFile2';
      case 'Proof3':
        return 'Proof3'
      case 'documentName':
        return 'WhatServed';
      default:
        return ''
    }
  }

  return (
    <div className="main-form">
      <div className='modal-content-container final-page'>
        <div className="right">
          <div className="order-summary-list">
            <h2>סיכמנו בשבילך:</h2>

            {((type === 'landRegistration') || (type === 'inheritanceRegistration') || (type === 'companiesRegistration')
              || (type === 'partnershipsRegistration') || (type === 'associationsAndCompanies') || (type === 'landSupervisor')) && (<>
                <div className="order-summary-item">
                  <div className="item-details">
                    <h4>שם הלשכה</h4>
                    <p>{data.branch}</p>
                    <p> {data.branchAddress.fullAddress} </p>
                  </div>
                  <img
                    src={edit}
                    onClick={(e) => editPage(e, 'ChooseOffice')}
                    alt='edit'
                  />
                </div>
                {data?.depositFiles && <div className="order-summary-item">
                  <div className="item-details">
                    <h4>תיקים להפקדה</h4>
                    <p>כמות התיקים להפקדה: {data?.depositFiles?.filesCount}</p>
                  </div>
                  <img
                    src={edit}
                    onClick={(e) => editPage(e, 'ActionInOffice')}
                    alt='edit'
                  />
                </div>}
                {data?.collectFiles &&
                  <div className="order-summary-item">
                    <div className="item-details">
                      <h4>תיקים לאיסוף</h4>
                      {data?.collectFiles?.map((submiter, idx) => {
                        return <div className="submiter-container" >
                          <div className="details">
                            <p className="submiter">שם המגיש: {submiter.name}</p>
                            <p className="submiter">תעודת זהות: {submiter.idNum}</p></div>
                          {submiter.files.map(file => {
                            return <div className="details-file">
                              <p>מספר התיק: {file.fileNum}</p>
                              <p>גוש: {file.block}</p>
                              <p>חלקה: {file.lot}</p>
                              <p>תת חלקה: {file.subLot}</p>
                            </div>
                          })
                          }
                        </div>
                      })}
                    </div>
                    <img
                      src={edit}
                      onClick={(e) => editPage(e, data.isOtherSubmitter ? 'CollectFileDetails' : 'ActionInOffice')}
                      alt='edit'
                    />
                  </div>
                }
              </>
              )}

            {type === 'courtSubmission' && (
              <div className="order-summary-item">
                <div className="item-details">
                  <h4>ערכאה</h4>
                  {renderedCourt()}
                </div>
                <img
                  src={edit}
                  onClick={(e) => editPage(e, 'CourtNameAndAction')}
                  alt='edit'
                />
              </div>
            )}
            {type === 'printingBinding' && (
              <div className="order-summary-item">
                <div className="item-details">
                  <h4>מאפייני ההדפסה</h4>
                  {renderedPrintingDetails()}
                </div>
                <img
                  src={edit}
                  onClick={(e) => editPage(e, 'UploadFileUpdate')}
                  alt='edit'
                />
              </div>
            )}

            {(type === 'addressLocate' || type === 'phoneLocate' || type === 'personLocate' || type === 'idLocate') && (
              <div className="order-summary-item">
                <div className="item-details">
                  <h3>מאותרים</h3>
                  {renderedRecipients()}
                </div>
                <img
                  src={edit}
                  onClick={(e) => editPage(e, 'Recipients')}
                  alt='edit'
                />
              </div>
            )}

            {(type === 'personalDelivery' || type === 'officeDelivery' || (type === 'courtSubmission' && data.recipients && data.recipients.length > 0)) && (
              <div className="order-summary-item">
                <div className="item-details">

                  {(type === 'personalDelivery' || type === 'courtSubmission') ? <h4>נמענים</h4> : <h4>משרדים</h4>}
                  {renderedRecipients()}
                </div>
                <img
                  src={edit}
                  onClick={(e) => editPage(e, getRecipientsPageNameByAction(type))}
                  alt='edit'
                />
              </div>
            )}

            {type === 'documentTransfer' && (
              <div className="order-summary-item">
                <div className="item-details">
                  <h4>סוג השליחות</h4>
                  {getTextDocTransferType()}
                </div>
                <img
                  src={edit}
                  onClick={(e) => editPage(e, 'DocumentTransferType')}
                  alt='edit'
                />
              </div>
            )}


            {(type === 'personalDelivery' || type === 'officeDelivery' || type === 'courtSubmission' || type === 'inheritanceRegistration') && (
              <div div className="order-summary-item">
                <div className="item-details">
                  {((type === 'courtSubmission' && data.isFirstSubmission !== 'collect') || (type === 'inheritanceRegistration')) && <h4>מה מגישים</h4>}
                  {(type === 'courtSubmission' && data.isFirstSubmission === 'collect') && <h4>מה אוספים</h4>}
                  {!(type === 'courtSubmission') && !(type === 'inheritanceRegistration') && <h4>מה מוסרים</h4>}
                  <div className="handed-list">{data?.docsForSubmission?.map(doc => {
                    return <div className="item"> <p>{doc.documentName}</p>  {doc.caseNumber && <p className="details" > מספר התיק: {doc.caseNumber} </p>} </div>
                  })}</div>
                </div>
                <img
                  src={edit}
                  onClick={(e) => editPage(e, getPageNameByTypeAndAction(type, 'documentName'))}
                  alt='edit'
                />
              </div>
            )}

            {((type === 'companiesRegistration') || (type === 'partnershipsRegistration') || (type === 'associationsAndCompanies')) && (
              <div div className="order-summary-item">
                <div className="item-details">
                  <h4>מה מגישים</h4>
                  <div className="handed-list">{data?.docsForSubmission?.map(doc => {
                    return <div className="item"> <p>{doc.documentName}</p>  {doc.caseNumber && <p className="details" > מספר התיק: {doc.caseNumber} </p>} </div>
                  })}</div>
                </div>
                <img
                  src={edit}
                  onClick={(e) => editPage(e, getPageNameByTypeAndAction(type, 'documentName'))}
                  alt='edit'
                />
              </div>)}

            {(type === 'apostilSubmission' || type === 'apostilInCourt') && <div className="order-summary-item">
              <div className="item-details">
                <h4>{type === 'apostilInCourt' ? 'המסמכים שלי' : 'התעודות שלי'}</h4>
                {data?.certificates?.map((certificate, idx) => {
                  return <>
                    <p key={certificate.name + idx} className="details">{certificate.name} -  סה״כ תעודות:   {certificate.counter}</p>
                    {certificate.isVerification && <p className="details">כולל ביצוע אימות מקדים</p>}
                    {certificate.isToll && <p className="details">כולל תשלום אגרה</p>}
                  </>
                })}
              </div>
              <img
                src={edit}
                onClick={(e) => editPage(e, 'ApostilleVerification')}
                alt='edit'
              />
            </div>}

            {data?.collectionMethod && <div className="order-summary-item">
              <div className="item-details">
                <h4>{data.type === 'documentTransfer' ? 'מוצא' : 'איך מקבלים'}</h4>
                <p>
                  {collectMethodMap[type](data?.collectionMethod)}
                </p>
                {data?.collectionMethod !== 'upload' && <div>{renderedCollectAddress()}</div>}
                {data?.collectionMethod === 'upload' && <p className="details">{getTextByPages(data?.pages)}</p>}
                {data?.collectionMethod === 'upload' && data?.bindings && data?.bindings > 0 ? <p className="details">כמות כריכות ודגלונים: {data?.bindings}</p> : <></>}
                {data.type === 'documentTransfer' && data.collectionAddress.personName ? renderDocTransferPersonDetails(true) : ''}
              </div>
              <img
                src={edit}
                onClick={(e) => editPage(e, getPageNameByTypeAndAction(type, data.isUpdatedDelivery ? 'CollectDocumentsOrUploadFile2' : 'collectAddress'))}
                alt='edit'
              />
            </div>
            }

            {type === 'landRegistration' && <div className="order-summary-item">
              <div className="item-details">
                <h4>הכתובת שלך</h4>
                <div>{renderedCollectAddress()}</div>
              </div>
              <img
                src={edit}
                onClick={(e) => editPage(e, getPageNameByTypeAndAction(type, 'collectAddress'))}
                alt='edit'
              />
            </div>
            }

            {data?.returnMethod && <div className="order-summary-item">
              <div className="item-details">
                <h4>{data.type === 'documentTransfer' ? 'יעד' : 'איך מחזירים'}</h4>
                <p>
                  {data?.returnMethod && returnMethodMap[type](data?.returnMethod)}
                </p>

                {/* <div>{renderedreturnAddress()}</div> */}
                {data?.returnMethod !== 'upload' && <div>{renderedreturnAddress()}</div>}
                {data?.returnMethod === 'upload' && <p className="details">הוכחת הביצוע תשלח בדוא"ל</p>}
                {data.type === 'documentTransfer' && data.returnAddress.personName ? renderDocTransferPersonDetails(false) : ''}
              </div>
              <img
                src={edit}
                onClick={(e) => editPage(e, getPageNameByTypeAndAction(type, data.isUpdatedDelivery ? 'Proof3' : 'returnAddress'))}
                alt='edit'
              />
            </div>}

            {type === 'smallClaim' && (
              <div className="order-summary-item">
                <div className="item-details">
                  <h4>נושא התביעה</h4>
                  <p className='details'>{data?.claimType || ''}</p>
                </div>
                <img
                  src={edit}
                  onClick={(e) => editPage(e, 'ChooseClaimType')}
                  alt='edit'
                />
              </div>
            )}

            {type === 'smallClaim' && <div className="order-summary-item">
              <div className="item-details">
                <h4>הצדדים</h4>
                {data?.claimants?.map((claimant, idx) => {
                  return <>
                    <p key={claimant.claimantId + idx} className="details">{`תובע/ת ${idx + 1} - ${claimant.claimantFirstName} ${claimant.claimantLastName}`}</p>
                  </>
                })}
                {data?.defendants?.map((defendant, idx) => {
                  return renderedDefendants(defendant, idx)
                })}
              </div>
              <img
                src={edit}
                onClick={(e) => editPage(e, 'SmallClaimRecipients')}
                alt='edit'
              />
            </div>}

            {type === 'smallClaim' && <div className="order-summary-item">
              <div className="item-details">
                <h4>תיאור התביעה</h4>
                <p className='details'>{`
                  בית משפט לתביעות קטנות 
                  ${'ב' + (data?.claimDescription?.court?.courtName || '')}
                `
                }</p>
              </div>
              <img
                src={edit}
                onClick={(e) => editPage(e, 'SmallClaimDescription')}
                alt='edit'
              />
            </div>}

            {data?.urgency && <div className="order-summary-item">
              <div className="item-details">
                <h4>{type === 'smallClaim' ? 'סיכום' : 'רמת הדחיפות'}</h4>
                <p className="details" >
                  {type === 'smallClaim' ? 'דחיפות ההגשה - ' : ''}{urgencyTranslate[data?.urgency] + '- ' + urgencyMap[(systemData?.isSpecialUrgencyPrice || data?.isSpecialUrgencyPrice) && type === 'documentTransfer' ? 'specialUrgencyPrice' : type][data?.urgency]}
                </p>
                {<p className='details'>{getExtraTextUrgency(data?.urgency, type, true)}</p>}
                {type === 'smallClaim' &&
                  <p className='details'>
                    {`מסירה אישית לנתבעים - ${data?.isPersonalDelivery ? 'כן' : 'ללא'}`}
                  </p>
                }
                {type === 'smallClaim' && data?.isPersonalDelivery &&
                  <p className='details'>
                    {'דחיפות מסירה אישית - '}
                    {urgencyTranslate[data?.personalDeliveryUrgency] + '- ' + urgencyMap['personalDeliveryClaim'][data?.personalDeliveryUrgency]}
                  </p>
                }
              </div>
              <img
                src={edit}
                onClick={(e) => editPage(e, type === 'smallClaim' ? 'SmallClaimUrgencyExecution' : 'UrgencyExecution')}
                alt='edit'
              />
            </div>}

          </div>
          <div className='contact' >
            <Tooltip text='ציינו את פרטי איש הקשר שאיתו תרצו שנדבר על כל דבר שקשור לשליחות זו'>
              <h3>פרטי איש קשר להזמנה זו</h3>
            </Tooltip>
            <div tabIndex="0" id='contact'>
              <div className="contact-details">
                <p>שם מלא*</p>
                <Field
                  autoComplete="new-password"
                  name="contactDeliveryRecipientName"
                  onChange={handleChangeData}
                  type='text'
                  onBlur={handleTouch}
                  value={data.contactDeliveryRecipientName}
                  className={
                    (touch.contactDeliveryRecipientName && errors?.contactDeliveryRecipientName?.length > 0)
                      ? 'name-input error'
                      : data?.contactDeliveryRecipientName?.length > 0
                        ? 'name-input success'
                        : 'name-input'
                  }
                />


              </div>
              <div className="contact-details">
                <p>פלאפון*</p>
                <div className='icon-input'>
                  <Field
                    type="text"
                    autoComplete="new-password"
                    className={
                      (touch.contactDeliveryRecipientPhone && errors?.contactDeliveryRecipientPhone?.length > 0)
                        ? 'error'
                        : phoneRegex.test(data.contactDeliveryRecipientPhone)
                          ? 'success'
                          : ''
                    }
                    onBlur={handleTouch}
                    onChange={handleChangeData}
                    value={data.contactDeliveryRecipientPhone}
                    name="contactDeliveryRecipientPhone"
                  />
                  <div className='icon'>972+</div>
                </div>
              </div>
            </div>
          </div>
          <div className='textarea' >
            <h3>הערות נוספות להזמנה</h3>
            <textarea
              className={data?.contactDeliveryRecipientNotes?.length > 0 ? 'success' : ''}
              name='contactDeliveryRecipientNotes'
              onChange={handleChangeData}
              placeholder='לדוגמה: הוראות נוספות לשליח.'
              rows='3'
              value={data.contactDeliveryRecipientNotes}
            ></textarea>
          </div>

          {!activeUser?.isAnonymous && !activeUser?.accessToken &&
            <div className='internal-use'>
              <h3>לשימוש פנימי</h3>
              <div className='internal-use-field'>
                <h4>{activeUser?.isCaseNeeded ? 'תיק לחיוב*' : 'תיק לחיוב'}</h4>
                <div>
                  <Field
                    onChange={handleChangeData}
                    type='text'
                    autoComplete="new-password"
                    placeholder="הזנת תיק לחיוב"
                    value={data.contactDeliveryRecipientCase}
                    name="contactDeliveryRecipientCase"
                    className={
                      (touch.contactDeliveryRecipientCase && errors?.contactDeliveryRecipientCase?.length > 0)
                        ? 'name-input error'
                        : data?.contactDeliveryRecipientCase?.length > 0
                          ? 'name-input success'
                          : 'name-input'
                    }
                  />
                  {(touch.contactDeliveryRecipientCase && errors?.contactDeliveryRecipientCase?.length > 0) &&
                    <p>{errors.contactDeliveryRecipientCase}</p>}
                </div>
              </div>
              <div className='internal-use-field'>
                <h4>{activeUser?.isAuthNeeded ? 'גורם מאשר*' : 'גורם מאשר'}</h4>
                <div>
                  <Field
                    onChange={handleChangeData}
                    type='text'
                    autoComplete="new-password"
                    placeholder='הזנת גורם מאשר'
                    value={data.contactDeliveryRecipientFactor}
                    name="contactDeliveryRecipientFactor"
                    className={
                      (touch.contactDeliveryRecipientFactor && errors?.contactDeliveryRecipientFactor?.length > 0)
                        ? 'name-input error'
                        : data?.contactDeliveryRecipientFactor?.length > 0
                          ? 'name-input success'
                          : 'name-input'
                    }
                  />
                  {(touch.contactDeliveryRecipientFactor && errors?.contactDeliveryRecipientFactor?.length > 0) &&
                    <p>{errors.contactDeliveryRecipientFactor}</p>}
                </div>
              </div>
            </div>
          }

        </div>
        {(!isPriceless) ?
          <div className='left'>
            <h3>עלויות</h3>
            {!isLoading ? renderedPrices() : <div className='spinner'>
              <TailSpin
                heigth="100"
                width="100"
                color='#4e71ff'
                ariaLabel='loading'
              />
            </div>}
          </div> : <div className='left no-price-action' />}
      </div>
      {priceError && <PriceErrorPopup onClosePopup={setPriceError} />}
    </div >
  );
}

export default FinalPage;
