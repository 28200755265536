import React, { useState, useEffect, useRef } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Field } from 'formik';
import { useViewport } from '../../../Hooks/useViewport'
import { useSelector } from 'react-redux';
import arrow from '../../../images/dropdown-arrow.svg';
import { useApostropheSubstitution } from '../../../Hooks/useApostropheSubstitution'
import { TooltipTriangle } from '../../Utils/TooltipTriangle'
import cities2 from "../../../consts/cities";
import streetRes2 from "../../../consts/streets";

export const AddressCollectDocumentsUpdate = ({ data, setData, isError, errors, handleChanges, type, setTouch, touch, pageName, scrollDown }) => {
  const [address, setAddress] = useState({
    collectCity: '',
    collectStreetAddress: '',
    collectStreetNumber: '',
    floor: '',
    apartment: '',
    collectNotes: '',
    collectAddressName: '',
  });
  const { width } = useViewport();
  const breakpoint = 1111;
  const isMobile = width < breakpoint
  const activeUser = useSelector((state) => state.userReducer.user);
  const myAddress = activeUser?.addresses || []
  const [isAddressNotFound, setIsAddressNotFound] = useState(false);
  const [searchCity, setSearchCity] = useState('');
  const [searchStreet, setSearchStreet] = useApostropheSubstitution('');
  const [isCitiesDropdownOpen, setIsCitiesDropdownOpen] = useState(false);
  const [isStreetsDropdownOpen, setIsStreetsDropdownOpen] = useState(false);
  const [israelCities, setIsraelCities] = useState([])
  const [cityStreets, setCityStreets] = useState([])
  const refCity = useRef()
  const refStreet = useRef()
  const cmpRef = useRef();

  const [radioInputsState, setRadioInputsState] = useState({
    selected: 0,
  });


  useEffect(() => {
    if (type === 'documentTransfer') {
      setTimeout(() => {
        executeEffect();
      }, 10);
    } else {
      executeEffect();
    }

    function executeEffect() {
      const { collectionAddress } = data;
      if (collectionAddress && Object.keys(collectionAddress).length !== 0) {
        let idx = myAddress.findIndex(
          (currAddress) =>
            currAddress.city === collectionAddress.collectCity &&
            currAddress.streetAddress === collectionAddress.collectStreetAddress &&
            currAddress.streetNumber === collectionAddress.collectStreetNumber &&
            currAddress.addressName === collectionAddress.collectAddressName
        );
        if (idx === -1) {
          idx = myAddress.length;
          setAddress({ ...address, ...collectionAddress });
          setSearchCity(collectionAddress?.collectCity || '')
          setSearchStreet(collectionAddress?.collectStreetAddress || '')
        }
        setRadioInputsState({ selected: idx.toString() });
      } else {
        setRadioInputsState({ selected: '0' });
        setData({
          ...data,
          collectionAddress: {
            ...data.collectionAddress,
            collectCity: myAddress[0]?.city || '',
            collectStreetAddress: myAddress[0]?.streetAddress || '',
            collectStreetNumber: myAddress[0]?.streetNumber || '',
            floor: myAddress[0]?.floor || '',
            apartment: myAddress[0]?.apartmentNumber || '',
            collectNotes: myAddress[0]?.comments || '',
            collectAddressName: myAddress[0]?.addressName || ''
          },
        });
      }
    }
  }, []);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (refCity?.current?.contains(event.target) || refStreet?.current?.contains(event.target)) {
        return;
      }
      setIsCitiesDropdownOpen(false);
      setIsStreetsDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);

  useEffect(() => {
    if (isCitiesDropdownOpen) {
      getCities()
      if (scrollDown) scrollDown()
    } else {
      setIsraelCities([])
    }
    if (isStreetsDropdownOpen) {
      if (scrollDown) scrollDown()
    }
    if (isStreetsDropdownOpen && address?.collectCity) {
      getStreetsOfCity(address?.collectCity)
    } else {
      setCityStreets([])
    }
  }, [isCitiesDropdownOpen, isStreetsDropdownOpen]);


  const onBlur = () => {
    if (address?.collectCity !== searchCity) {
      setSearchCity(address?.collectCity ? address?.collectCity : '')
    }
    if (address?.collectStreetAddress !== searchStreet) {
      setSearchStreet(address?.collectStreetAddress ? address?.collectStreetAddress : '')
    }
    setIsCitiesDropdownOpen(false)
    setIsStreetsDropdownOpen(false)
  }

  const getCities = async () => {
    try {
      const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`, { signal: AbortSignal.timeout(5000) })
      const cities = await cityRes.json()
      const citiesAfterSwitch = switchBrackets(cities.result.records)
      setIsraelCities(citiesAfterSwitch)
    } catch (err) {
      const cities2 = require("../../../consts/cities")
      const citiesAfterSwitch2 = switchBrackets(cities2.result.records)
      setIsraelCities(citiesAfterSwitch2)
      console.log('Fetch error: ', err)
    }
  }

  const switchBrackets = (cities) => {
    const switchBracketsMap = {
      '(': ")",
      ')': "(",
    };

    return cities.map(city => {
      return {
        'שם_ישוב': city['שם_ישוב'].trim().replace(/\(|\)/gi, function (matched) {
          return switchBracketsMap[matched];
        })
      }
    })
  }

  const getStreetsOfCity = async (cityName) => {
    try {
      const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${cityName}`, { signal: AbortSignal.timeout(5000) })
      const StreetsOfCity = await streetRes.json()
      setCityStreets(StreetsOfCity.result.records)
    } catch (err) {
      const streetRes2 = require("../../../consts/streets")
      setCityStreets(streetRes2.result.records.filter((rec) => rec['שם_ישוב'] == cityName))
      console.log('Fetch error: ', err)
    }
  }

  const onSetChoose = (field, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setTouch({ ...touch, [field]: true });
    setData({
      ...data,
      collectionAddress: {
        ...data.collectionAddress,
        ...address,
        [field]: value,
        collectAddressName: data.collectionAddress?.collectAddressName
      },
    });
  }

  const undoChanges = () => {
    setSearchStreet('')
    setAddress((prevState) => ({
      ...prevState,
      collectStreetAddress: '',
    }));
    setData({
      ...data,
      collectionAddress: {
        ...data.collectionAddress,
        ...address,
        collectStreetAddress: '',
        collectAddressName: data.collectionAddress?.collectAddressName
      },
    });
  }

  const getTitleByTypeForm = () => {
    switch (type) {
      case 'courtSubmission':
      case 'officeDelivery':
      case 'personalDelivery':
        return 'בחירת כתובת לאיסוף המסמכים';
      case 'apostilSubmission':
        return 'כתובת לאיסוף התעודות';
      case 'landRegistration':
        if (data?.depositFiles || data.actionInOffice === 'depositFile') return 'בחירת כתובת לאיסוף התיקים';
        else if (data.actionInOffice === 'collectFile') return 'בחירת כתובת למסירת התיקים';
      case 'landSupervisor':
        if (data?.depositFiles || data.actionInOffice === 'depositFile') return 'בחירת כתובת לאיסוף המסמכים';
        else if (data.actionInOffice === 'collectFile') return 'בחירת כתובת למסירת המסמכים';
      case 'documentTransfer':
        return 'כתובת מוצא לאיסוף המסמכים'
      case 'apostilInCourt':
        return 'כתובת לאיסוף המסמכים הנוטריונים'
      case 'inheritanceRegistration':
      case 'partnershipsRegistration':
      case 'associationsAndCompanies':
      case 'companiesRegistration':
        const isCollect = data?.docsForSubmission?.some(doc => doc.upload === false)
        return isCollect ? '' : 'בחירת כתובת לאיסוף המסמכים';
      case 'printingBinding':
        return 'בחירת כתובת למסירת המסמכים'
      default:
        return 'בחירת כתובת לאיסוף המסמכים';
    }
  }

  const getSelfStyleForm = () => {
    switch (type) {
      case 'personalDelivery':
      case 'officeDelivery':
      case 'documentTransfer':
      case 'apostilSubmission':
      case 'apostilInCourt':
      case 'inheritanceRegistration':
      case 'partnershipsRegistration':
      case 'associationsAndCompanies':
      case 'companiesRegistration':
      case 'printingBinding':
        return 'self-form-style';
      case 'courtSubmission':
        if (data?.isFirstSubmission !== 'collect') return 'self-form-style';
      case 'landSupervisor':
        if (!pageName) return 'self-form-style';
        else return '';
      case 'landRegistration':
        if (data?.actionInOffice === 'depositFile') return 'self-form-style';
      default:
        return '';
    }
  }

  const handleChangeCheckbox = (event) => {
    const { value, name } = event.target;
    if (parseInt(value) <= myAddress.length) {
      setRadioInputsState((prevState) => ({
        ...prevState,
        selected: value,
      }));
    }
    setData({
      ...data,
      collectionAddress:
        value === myAddress.length.toString()
          ? {
            ...data.collectionAddress,
            ...address,
            // collectAddressName: data.collectionAddress.collectAddressName
            // floor: name === 'floor' ? value : address.floor,
            // apartment: name === 'apartment' ? value : address.apartment,

          }
          : {
            ...data.collectionAddress,
            collectCity: myAddress[value].city,
            collectStreetAddress: myAddress[value].streetAddress,
            collectStreetNumber: myAddress[value].streetNumber,
            floor: myAddress[value].floor,
            apartment: myAddress[value].apartmentNumber,
            collectNotes: myAddress[value]?.comments || '',
            collectAddressName: myAddress[value]?.addressName || ''
          },
    });
  };

  const handleChangeNewAddress = (event) => {
    const { value } = event.target;
    const { name } = event.target;

    setRadioInputsState((prevState) => ({
      ...prevState,
      selected: myAddress.length.toString(),
    }));
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setData({
      ...data,
      collectionAddress: {
        ...data.collectionAddress,
        ...address,
        [name]: value,
        collectAddressName: data.collectionAddress?.collectAddressName
      },
    });
    handleChanges(event);
  };
  const renderedDataDesktop = myAddress.map((currAddress, idx) => {
    return (
      <div className='input-container address data-desktop' style={{ paddingTop: idx === 0 ? '45px' : '15px', borderBottom: (idx === myAddress.length - 1) && !data.isShowNewAddress ? 'none' : '' }}>
        <div className='radio-btn-container'>
          <Field
            className='radio-btn'
            type='radio'
            id={idx}
            value={idx}
            onChange={handleChangeCheckbox}
            checked={radioInputsState.selected === idx.toString()}
          /></div>
        <div className='field'>
          {idx === 0 ? <label>יישוב</label> : <></>}
          <Field
            type="text"
            className='med disabled'
            disabled
            value={currAddress.city}
            name='collectCity'
          />
        </div>

        <div className='field'>
          {idx === 0 ? <label>רחוב</label> : <></>}
          <Field
            type="text"
            className='med disabled'
            disabled
            value={currAddress.streetAddress}
            name='collectStreetAddress'
          />
        </div>

        <div className='field'>
          {idx === 0 ? <label>מספר</label> : <></>}
          <Field
            type="text"
            className='small disabled'
            disabled
            value={currAddress.streetNumber}
            name='streetNumber'
          />
        </div>
        <div className='field'>
          {idx === 0 ? <label>קומה</label> : <></>}
          <Field
            type="text"
            className='small disabled'
            disabled
            value={currAddress.floor}
            name='floor'
          />
        </div>
        <div className='field'>
          {idx === 0 ? <label>דירה</label> : <></>}
          <Field
            type="text"
            className='small disabled'
            disabled
            value={currAddress.apartmentNumber}
            name='apartment'
          />
        </div>

        <div className='field'>
          {idx === 0 ? <label>הערות</label> : <></>}
          <Field
            type="text"
            name='collectNotes'
            disabled
            value={currAddress.comments}
            className="med disabled"
          />
        </div>
        <p className={`choose-address-btn ${radioInputsState.selected === idx.toString() ? 'selected' : ''}`}
          onClick={() => { handleChangeCheckbox({ target: { value: idx.toString(), name: '' } }) }}>
          בחירה
        </p>
      </div>
    );
  });

  const renderedDataMobile = myAddress.map((currAddress, idx) => {
    return (
      <div className='mobile-address-container'>
        <div className='radio-btn-container'>
          <Field
            className='radio-btn'
            type='radio'
            id={idx}
            value={idx}
            onChange={handleChangeCheckbox}
            checked={radioInputsState.selected === idx.toString()}
          />
        </div>
        <div className="address-details">
          <p>{`${currAddress?.streetAddress} ${currAddress.streetNumber ? currAddress.streetNumber : ''}`}</p>
          <p>{`${currAddress?.city}`}</p>
          <p>{`קומה: ${currAddress?.floor}   |   דירה: ${currAddress?.apartmentNumber}`}</p>
          <p>{`הערות: ${currAddress?.comments}`}</p>
        </div>
      </div>
    );
  });

  return (
    <div className={`main-form form7-addresses-update update-cmps ${getSelfStyleForm()}`} ref={cmpRef}>
      <div className='modal-content-container'>
        {type === 'documentTransfer' && <div className='cmp-line' />}
        <h2 className='large-title' >{getTitleByTypeForm()}</h2>
        {!getSelfStyleForm() &&
          <div className='cmp-line' style={{ marginBottom: '15px' }} />
        }
        {/* {isMobile ? <></> : <div className='addresses-titles'>
          <p className='point'></p>
          <p className='large-title'>יישוב</p>
          <p className='large-title'>רחוב</p>
          <p className='small-title'>מספר</p>
          <p className='small-title'>קומה</p>
          <p className='small-title'>דירה</p>
          <p className='large-title'>הערות</p>
        </div>} */}

        {/* {isMobile ? '' : myAddress?.length === 0 ? '' : <h3>רשימת הכתובות שלי</h3>} */}

        {isMobile ? renderedDataMobile : renderedDataDesktop}

        {/* {isMobile && (
          <div className='new-address'>
            <Field
              autoComplete="new-password"
              className='radio-btn'
              type='radio'
              id={myAddress.length}
              value={myAddress.length}
              onChange={handleChangeCheckbox}
              checked={radioInputsState.selected === myAddress.length.toString()}
            />
            <h3 >הוספת כתובת חדשה</h3>
          </div>)} */}



        {(data.isShowNewAddress || activeUser?.isAnonymous || !myAddress.length) ?
          <div className='input-container extra-address'>
            <div className='input-container radio-container'>
              <Field
                autoComplete="new-password"
                className='radio-btn'
                type='radio'
                id={myAddress.length}
                value={myAddress.length}
                onChange={handleChangeCheckbox}
                checked={radioInputsState.selected === myAddress.length.toString()}
              />
              {isMobile && (
                <p>בחירת כתובת חדשה</p>
              )}
            </div>
            <div className='field med'>
              {(isMobile || !myAddress.length) &&
                <label className='input-label'>יישוב*</label>
              }
              <div
                ref={refCity}
                className={
                  isCitiesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                }
              >
                <div className='dropdown-input'>
                  <input
                    type="text"
                    placeholder='הזינו יישוב ובחרו מתוך הרשימה'
                    onBlur={onBlur}
                    onChange={(event) => {
                      setSearchCity(event.target.value);
                      setIsCitiesDropdownOpen(true);
                      setRadioInputsState((prevState) => ({
                        ...prevState,
                        selected: myAddress.length.toString(),
                      }));
                    }}
                    value={searchCity}
                    onClick={() => { setIsCitiesDropdownOpen(true); }}
                    name='collectCity'
                    autoComplete="new-password"
                    className={
                      // isError('collectionAddress')
                      //   ? 'error'
                      //   : formState?.currRecipient?.city
                      //     ? 'success'
                      //     : ''
                      isError('collectionAddress')
                        ? 'med error'
                        : address.collectCity?.length > 0 && radioInputsState.selected === myAddress.length.toString()
                          ? 'med success'
                          : 'med'
                    }
                  />
                  <button
                    type='button'
                    onClick={() => {
                      setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                    }}
                  >
                    <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                  </button>
                </div>

                {isError('collectionAddress') && <p className='error-label'>{errors.collectionAddress.collectCity}</p>}

                <div className='dropdown-list med'>
                  {israelCities.filter((option) => option['שם_ישוב'].includes(searchCity)).map((city, idx) => {
                    return (
                      <div className='dropdown-option' key={idx}>
                        <p
                          onMouseDown={() => {
                            const cityName = city['שם_ישוב'].trim()
                            onSetChoose('collectCity', cityName)
                            setSearchCity(cityName)
                            setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                            // getStreetsOfCity(cityName)
                            setRadioInputsState((prevState) => ({
                              ...prevState,
                              selected: myAddress.length.toString(),
                            }));
                            if (cityName !== address.collectCity) undoChanges()
                          }}>
                          {city['שם_ישוב']}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            {isAddressNotFound ?
              <div div className='field med'>
                {(isMobile || !myAddress.length) &&
                  <label className='input-label'>רחוב*</label>
                }
                <Field
                  type="text"
                  name='collectStreetAddress'
                  autoComplete="new-password"
                  value={searchStreet}
                  onChange={(ev) => {
                    setSearchStreet(ev.target.value);
                    onSetChoose('collectStreetAddress', ev.target.value)
                  }}
                  className={
                    // isError('streetAddress')
                    //   ? 'error'
                    //   : formState?.currRecipient?.streetAddress
                    //     ? 'success'
                    //     : ''
                    isError('collectionAddress')
                      ? 'med error'
                      : data.collectionAddress?.collectStreetAddress && radioInputsState.selected === myAddress.length.toString()
                        ? 'med success'
                        : 'med'
                  }
                />
                {isError('collectionAddress') && <p className='error-label'>{errors?.collectionAddress?.collectStreetAddress}</p>}
                <TooltipTriangle text={'אם אין את הרחוב ברשימה הנפתחת, לחצו כאן וכתבו את הרחוב'}>
                  <label className="checkbox-container">
                    <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={isAddressNotFound} onChange={() => setIsAddressNotFound(!isAddressNotFound)} />
                    <label className='checkbox-content' htmlFor='isAddressNotFound'>
                      לא מצאתי את הרחוב
                    </label>
                    <span className="checkmark"></span>
                  </label>
                </TooltipTriangle>
              </div>
              :

              <div className='field med'>
                {(isMobile || !myAddress.length) &&
                  <label className='input-label'>רחוב*</label>
                }

                <div
                  ref={refStreet}
                  className={
                    isStreetsDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                  }
                >
                  <div className='dropdown-input'>
                    <input
                      type="text"
                      placeholder='הזינו רחוב ובחרו מתוך הרשימה'
                      onBlur={onBlur}
                      onChange={(event) => {
                        setSearchStreet(event.target.value);
                        setIsStreetsDropdownOpen(true);
                      }}
                      value={searchStreet}
                      onClick={() => { setIsStreetsDropdownOpen(true); }}

                      name='collectStreetAddress'
                      autoComplete="new-password"
                      className={
                        // isError('streetAddress')
                        //   ? 'error'
                        //   : formState?.currRecipient?.streetAddress
                        //     ? 'success'
                        //     : ''
                        isError('collectionAddress')
                          ? 'med error'
                          : data.collectionAddress?.collectStreetAddress && radioInputsState.selected === myAddress.length.toString()
                            ? 'med success'
                            : 'med'
                      }
                    />
                    <button
                      type='button'
                      onClick={() => {
                        setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                      }}
                    >
                      <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                    </button>
                  </div>

                  {isError('collectionAddress') && <p className='error-label'>{errors?.collectionAddress?.collectStreetAddress}</p>}

                  <div className='dropdown-list med'>
                    {cityStreets.filter((option) => option['שם_רחוב'].includes(searchStreet)).map((street, idx) => {
                      return (
                        <div className='dropdown-option' key={idx}>
                          <p
                            onMouseDown={() => {
                              onSetChoose('collectStreetAddress', street['שם_רחוב'])
                              setSearchStreet(street['שם_רחוב'])
                              setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                            }}>
                            {street['שם_רחוב']}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <TooltipTriangle text={'אם אין את הרחוב ברשימה הנפתחת, לחצו כאן וכתבו את הרחוב'}>
                  <label className="checkbox-container">
                    <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={isAddressNotFound} onChange={() => setIsAddressNotFound(!isAddressNotFound)} />
                    <label className='checkbox-content' htmlFor='isAddressNotFound'>
                      לא מצאתי את הרחוב
                    </label>
                    <span className="checkmark"></span>
                  </label>
                </TooltipTriangle>
              </div>
            }

            <div className='field small'>
              {(isMobile || !myAddress.length) &&
                <label className='input-label'>מספר*</label>
              }
              <Field
                type="text"
                name='collectStreetNumber'
                autoComplete="new-password"
                value={address.collectStreetNumber}
                onChange={handleChangeNewAddress}
                className={
                  isError('collectionAddress')
                    ? 'small error'
                    : address.collectStreetNumber.length > 0 && radioInputsState.selected === myAddress.length.toString()
                      ? 'small success'
                      : 'small'
                }
              />
              {isError('collectionAddress') && (
                <p>{errors.collectionAddress.collectStreetNumber}</p>
              )}
            </div>
            <div className='field small'>
              {(isMobile || !myAddress.length) &&
                <label className='input-label'>קומה</label>
              }
              <Field
                type="text"
                autoComplete="new-password"
                className={address.floor && radioInputsState.selected === myAddress.length.toString() ? 'small success' : 'small'}
                name='floor'
                onChange={handleChangeNewAddress}
                value={address.floor}
              />
            </div>
            <div className='field small'>
              {(isMobile || !myAddress.length) &&
                <label className='input-label'>דירה</label>
              }
              <Field
                type="text"
                autoComplete="new-password"
                className={address.apartment && radioInputsState.selected === myAddress.length.toString() ? 'small success' : 'small'}
                name='apartment'
                onChange={handleChangeNewAddress}
                value={address.apartment}
              />
            </div>
            <div className='field'>
              {(isMobile || !myAddress.length) &&
                <label className='input-label'>הערות</label>
              }
              <Field
                type="text"
                name='collectNotes'
                autoComplete="new-password"
                value={address.collectNotes}
                onChange={handleChangeNewAddress}
                className={
                  address.collectNotes.length > 0 && radioInputsState.selected === myAddress.length.toString()
                    ? 'med success'
                    : 'med'
                }
              />
            </div>
          </div>
          :
          <div className="add-address">
            <img
              className="btn-add"
              src='/images/plus.svg'
              alt=''
              onClick={() => {
                setData({ ...data, isShowNewAddress: true });
                handleChangeCheckbox({ target: { value: myAddress.length.toString(), name: '' } })

                // setFormState((prevState) => ({
                //   ...prevState,
                //   nextStep: setNextStepByFormType(),
                //   currRecipient: null,
                // }));
                // setData({ ...data, isOtherTasks: true });
              }}
            />
            <h3>{'הוספת כתובת חדשה'}</h3>
          </div>


        }

      </div>
    </div >
  );
};

export default AddressCollectDocumentsUpdate;
