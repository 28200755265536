import React, { useEffect, useState, useRef } from 'react'
import { UserOrdersFilterType } from './UserOrdersFilterType'
import { FiltersModal } from './FiltersModal'
import { useDispatch, useSelector } from 'react-redux'
import printerGray from '../../images/printerGray.svg';
import pdf from '../../images/pdf.svg';
import excel from '../../images/excel.svg';
import { useViewport } from '../../Hooks/useViewport'
import { generatePdfAction, generateExcelAction } from '../../integrations/firebase';
import { TailSpin } from 'react-loader-spinner'
import { setIsLoading } from '../../store/actions/systemActions'
import arrow from '../../images/dropdown-arrow.svg';


export const UserOrdersFilter = ({ filterBy, setFilterBy, filteredActions }) => {
    const orders = useSelector((state) => state.userDataReducer.userOrders);
    const dispatch = useDispatch();
    const activeUser = useSelector((state) => state.userReducer.user);

    const [usersOfOrders, setUsersOfOrders] = useState([]);
    const [showFiltersModal, setShowFiltersModal] = useState(false);
    const isLoading = useSelector((state) => state.systemReducer.isLoading)
    const [isExcelLoading, setIsExcelLoading] = useState(false);
    const [isPrintLoading, setIsPrintLoading] = useState(false);
    const [isGenerateFilesDropdownOpen, setIsGenerateFilesDropdownOpen] = useState(false);


    const [inputWidth, setInputWidth] = useState('250px');
    const { width } = useViewport();
    const breakpoint = 767;
    const isMobile = width < breakpoint;
    const isSmallDesktop = width > 1111 && width < 1150;
    const refGenerate = useRef()

    const generateFilesOptions = [{ name: 'הדפסה', img: '' }, { name: 'PDF', img: '' }, { name: 'Excel', img: '' }]

    useEffect(() => {
        if (isSmallDesktop) setInputWidth('130px')
        else if (width > 1150 && width < 1200) setInputWidth('145px')
        else if (width > 1200 && width < 1250) setInputWidth('165px')
        else if (width > 1250 && width < 1300) setInputWidth('185px')
        else if (width > 1300 && width < 1350) setInputWidth('210px')
        else if (width > 1350 && width < 1450) setInputWidth('225px')
    }, [width])

    useEffect(() => {
        const onBodyClick = (event) => {
            if (refGenerate?.current?.contains(event.target)) {
                return;
            }
            setIsGenerateFilesDropdownOpen(false);
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });

        return () => {
            document.body.removeEventListener("click", onBodyClick, { capture: true });
        };
    }, []);

    useEffect(() => {
        const users = [{ type: 'כולם', typeToFilter: 'all' }]
        orders.forEach(order => {
            const pos = users.map(user => user.type).indexOf(order?.cart?.orderClientName || '');

            if ((pos === -1) && order.cart.orderClientName && order.cart.orderEmail) users.push({
                type: order.cart.orderClientName,
                typeToFilter: order.cart.orderEmail
            })
        });
        setUsersOfOrders([...users])
    }, [orders])

    const actionType = [
        { type: 'הכל', typeToFilter: 'all' },
        { type: 'הגשת תביעה קטנה', typeToFilter: 'smallClaim' },
        { type: 'אפוסטיל משרד החוץ', typeToFilter: 'apostilSubmission' },
        { type: 'אפוסטיל בית משפט', typeToFilter: 'apostilInCourt' },
        { type: 'איתור כתובת מגורים', typeToFilter: 'addressLocate' },
        { type: 'איתור מספר פלאפון', typeToFilter: 'phoneLocate' },
        { type: "מסירה אישית", typeToFilter: 'personalDelivery' },
        { type: "איתור מען", typeToFilter: 'personLocate' },
        { type: "המצאות למשרדים", typeToFilter: 'officeDelivery' },
        { type: "העברת מסמכים", typeToFilter: 'documentTransfer' },
        { type: "רשם לענייני ירושה", typeToFilter: 'inheritanceRegistration' },
        { type: "רשם השותפויות", typeToFilter: 'partnershipsRegistration' },
        { type: "רשם העמותות וחל''צ", typeToFilter: 'associationsAndCompanies' },
        { type: "רשם החברות", typeToFilter: 'companiesRegistration' },
        { type: "לשכת רישום מקרקעין", typeToFilter: 'landRegistration' },
        { type: "בית משפט / בית דין", typeToFilter: 'courtSubmission' },
        { type: "איתור מספר זהות", typeToFilter: 'idLocate' },
    ]
    const dateType = [
        { type: 'הכל', typeToFilter: 'all' },
        { type: 'היום', typeToFilter: 'today' },
        { type: 'אתמול', typeToFilter: 'yesterday' },
        { type: 'השבוע', typeToFilter: 'thisWeek' },
        { type: 'שבוע שעבר', typeToFilter: 'lastWeek' },
        { type: 'החודש', typeToFilter: 'thisMonth' },
        { type: 'חודש שעבר', typeToFilter: 'lastMonth' },
        { type: 'השנה הנוכחית', typeToFilter: 'thisYear' },
        { type: 'טווח תאריכים', typeToFilter: 'chooseDate' },
    ]
    const checkJobStatus = async (jobId) => {
        const res = await fetch(`https://api.pdf.co/v1/job/check?jobid=${jobId}`, {
            headers: {
                "x-api-key": "jonathanganor@gmail.com_e8977ecc4636d1e67e49361373a30b828c6cf33f271226990e2583d9104bd06800a3f8ec",
            },
        });
        const resData = await res.json();
        return resData;
    };

    const onPdf = async () => {
        if (isLoading) return
        const ordersAndActions = {
            orders,
            actions: filteredActions
        }
        let downloadUrl
        try {
            dispatch(setIsLoading(true))
            const res = await generatePdfAction(ordersAndActions);
            const jobId = res.data.jobId;
            let jobStatus = await checkJobStatus(jobId);
            while (jobStatus.status !== "success") {
                jobStatus = await checkJobStatus(jobId);
                await new Promise(resolve => setTimeout(resolve, 5000));
            }
            //Job completed
            downloadUrl = jobStatus.url;
            dispatch(setIsLoading(false))
        } catch (err) {
            console.log('err', err)
            dispatch(setIsLoading(false))
            setIsGenerateFilesDropdownOpen(false);
        }

        try {
            //Download the pdf from url
            const fileName = getFileName()
            const pdfBlob = await fetch(downloadUrl).then((resp) => resp.blob());
            const pdfObjectUrl = URL.createObjectURL(pdfBlob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = pdfObjectUrl;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(pdfObjectUrl);
        } catch (err) {
            console.log('err', err)
        }
    };

    const onExcel = async () => {
        if (isExcelLoading) return
        const ordersAndActions = {
            orders,
            actions: filteredActions
        }
        try {
            setIsExcelLoading(true)
            const res = await generateExcelAction(ordersAndActions)
            setIsExcelLoading(false)
            downloadURL(res.data)
            setIsGenerateFilesDropdownOpen(false);
        } catch (err) {
            console.log('err', err)
        }
    }

    const onPrint = async () => {
        if (isPrintLoading) return
        const ordersAndActions = {
            orders,
            actions: filteredActions
        }

        try {
            setIsPrintLoading(true)
            const res = await generatePdfAction(ordersAndActions)
            setIsPrintLoading(false)
            setIsGenerateFilesDropdownOpen(false);
            fetch(res.data, {
                method: 'GET'
            }).then(resp => resp.blob())
                .then(blob => {
                    const dataUrl = window.URL.createObjectURL(blob);
                    const pdfWindow = window.open(dataUrl);
                    pdfWindow.print();
                })
        } catch (err) {
            console.log('err', err)
        }
    }

    const downloadURL = (url) => {
        // const fileName = getFileName()
        let link = document.createElement("a");
        // link.download = fileName;
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const getFileName = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();
        today = dd + '/' + mm + '/' + yyyy;

        const fileName = `delawvery-report-${today}`
        return fileName
    }

    const placeHolderAction = 'כל סוגי השירותים'

    return (
        <div className='filter-container'>
            {isMobile ?
                <button className='btn-filter-action' onClick={() => { setShowFiltersModal(true) }}>
                    חיפוש ומסננים
                </button>
                :
                <div className='filters'>
                    <UserOrdersFilterType types={dateType} placeholder={'תאריכים'} setFilterBy={setFilterBy} filterBy={filterBy} filterType={'dateType'} inputWidth={inputWidth} />
                    <UserOrdersFilterType types={actionType} placeholder={placeHolderAction} setFilterBy={setFilterBy} filterBy={filterBy} filterType={'actionType'} inputWidth={inputWidth} />
                    {activeUser?.isMaster &&
                        <UserOrdersFilterType types={usersOfOrders} placeholder={'כל המשתמשים'} setFilterBy={setFilterBy} filterBy={filterBy} filterType={'userType'} inputWidth={inputWidth} />
                    }
                </div>
            }

            <FiltersModal showFiltersModal={showFiltersModal} closeFiltersModal={setShowFiltersModal} dateType={dateType} actionType={actionType} usersOfOrders={usersOfOrders} setFilterBy={setFilterBy} filterBy={filterBy} placeHolderAction={placeHolderAction} />

            <div className="filter-actions">
                <div className="dropdown-export-documents" >
                    <div
                        ref={refGenerate}
                        className={
                            isGenerateFilesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                        }
                    >
                        <div className='dropdown-input'>
                            <input
                                type="text"
                                placeholder='ייצוא דוח מפורט'
                                onChange={(event) => {
                                    setIsGenerateFilesDropdownOpen(true);
                                }}
                                onClick={() => { setIsGenerateFilesDropdownOpen(true); }}
                            />
                            <button
                                type='button'
                                onClick={() => {
                                    setIsGenerateFilesDropdownOpen(!isGenerateFilesDropdownOpen);
                                }}
                            >
                                <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                            </button>
                        </div>

                        <div className='dropdown-list'>
                            {generateFilesOptions.map((option, idx) => {
                                return (
                                    <div className='dropdown-option' key={idx}>
                                        {option.name === 'הדפסה' &&
                                            <button
                                                type='button'
                                                className='btn-filter-action'
                                                onClick={onPrint}
                                            >
                                                {isPrintLoading ? <div className='spinner'>
                                                    <TailSpin
                                                        heigth="100"
                                                        width="100"
                                                        color='#4e71ff'
                                                        ariaLabel='loading'
                                                    />
                                                </div>
                                                    :
                                                    <>
                                                        <img src={printerGray} alt='printerGray' />
                                                        הדפסת דוח
                                                    </>
                                                }
                                            </button>
                                        }

                                        {
                                            option.name === 'PDF' &&
                                            <button
                                                type='button'
                                                className='btn-filter-action'
                                                onClick={onPdf}
                                            >
                                                {isLoading ? <div className='spinner'>
                                                    <TailSpin
                                                        heigth="100"
                                                        width="100"
                                                        color='#4e71ff'
                                                        ariaLabel='loading'
                                                    />
                                                </div>
                                                    :
                                                    <>
                                                        <img src={pdf} alt='pdf' />
                                                        PDF ייצוא דוח
                                                    </>
                                                }
                                            </button>
                                        }

                                        {
                                            option.name === 'Excel' &&
                                            <button
                                                type='button'
                                                className='btn-filter-action'
                                                onClick={onExcel}
                                            >
                                                {isExcelLoading ? <div className='spinner'>
                                                    <TailSpin
                                                        heigth="100"
                                                        width="100"
                                                        color='#4e71ff'
                                                        ariaLabel='loading'
                                                    />
                                                </div>
                                                    :
                                                    <>
                                                        <img src={excel} alt='excel' />
                                                        ייצוא דוח לאקסל
                                                    </>
                                                }
                                            </button>
                                        }

                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
