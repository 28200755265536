import React, { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const PersonalOrCompanyToggle = ({ data, setData, setFormState, formState }) => {
  const [firstAlignment, setFirstAlignment] = useState(
    data?.deliveryAfter || 'false'
  );
  const [secondAlignment, setSecondAlignment] = useState(
    data?.isPrivateOrOffice || 'false'
  );

  const [thirdAlignment, setThirdAlignment] = useState(
    data?.isPersonalOrCompany || 'true'
  );
  const isPopUpModal = useSelector((state) => state.systemReducer.isPopUpModal);


  const optionE = 'אדם פרטי';
  const optionF = 'בית עסק';

  useEffect(() => {
    setData({
      ...data,
      deliveryAfter: true,
      isPersonalOrCompany: thirdAlignment,
    });
    setFormState({ ...formState, currRecipient: null })
    // return () => {
    //   setData({ ...data, isOtherTasks: true })
    // }
  }, []);

  useEffect(() => {
    const { isDeleteRecipients, status } = isPopUpModal;
    if (isDeleteRecipients && status === 'submit') {
      setData({ ...data, deliveryAfter: 'false', recipients: [] });
      setFirstAlignment('false');
    }
  }, [isPopUpModal.isDeleteRecipients]);

  const handleChangeData = async (e, field, value) => {
    e.preventDefault();

    if (field === 'isPrivateOrOffice') {
      setSecondAlignment(value);
    } else {
      setThirdAlignment(value);
    }
    setData({ ...data, [field]: value });
  };

  const handleAlignment = (state) => {
    if (state === 'true') {
      return 'right';
    } else {
      return 'left';
    }
  };

  return (
    <div className='main-form  personal-delivery'>
      <div className='modal-content-container block'>
        <h2 className='large-title'>
          למי מיועדת המסירה?
        </h2>

        {!data.isOtherTasks && <> <p className='subtitle'>
          בחרו את סוג הנמען הראשון לו תרצו למסור את המסמכים.
        </p>
          <p className='subtitle'>
            בהמשך ניתן יהיה להוסיף נמענים נוספים.
          </p>
        </>}
        {data.isOtherTasks && <p className='subtitle'>
          בחרו את סוג הנמען הנוסף לו תרצו למסור את המסמכים.
        </p>}

        {
          <div>
            <div
              className={`form-main-toggle  ${handleAlignment(
                thirdAlignment
              )}`}
            >
              <span></span>
              <button
                onClick={(e) =>
                  handleChangeData(e, 'isPersonalOrCompany', 'true')
                }
              >
                {optionE}
              </button>
              <button
                onClick={(e) =>
                  handleChangeData(e, 'isPersonalOrCompany', 'false')
                }
              >
                {optionF}
              </button>
            </div>
          </div>

        }
      </div>
    </div>
  );
};

export default PersonalOrCompanyToggle;
