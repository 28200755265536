module.exports = {
    "help": "https://data.gov.il/api/3/action/help_show?name=datastore_search",
    "success": true,
    "result": {
    "include_total": true,
        "limit": 32000,
        "q": "",
        "records_format": "objects",
        "resource_id": "d4901968-dad3-4845-a9b0-a57d027f11ab",
        "total_estimation_threshold": null,
        "records": [
        {
            "_id": 1,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 967,
            "שם_ישוב": "אבו ג'ווייעד )שבט(       ",
            "שם_ישוב_לועזי": "ABU JUWEI'ID             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 2,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש                  ",
            "שם_ישוב_לועזי": "ABU GHOSH                ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 3,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן                 ",
            "שם_ישוב_לועזי": "ABU SINAN                ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 4,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 935,
            "שם_ישוב": "אבו סריחאן )שבט(         ",
            "שם_ישוב_לועזי": "ABU SUREIHAN             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 5,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 958,
            "שם_ישוב": "אבו עבדון )שבט(          ",
            "שם_ישוב_לועזי": "ABU  ABDUN               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 6,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1042,
            "שם_ישוב": "אבו עמאר )שבט(           ",
            "שם_ישוב_לועזי": "ABU  AMMAR               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 7,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 932,
            "שם_ישוב": "אבו עמרה )שבט(           ",
            "שם_ישוב_לועזי": "ABU  AMRE                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 8,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 968,
            "שם_ישוב": "אבו קורינאת )שבט(        ",
            "שם_ישוב_לועזי": "ABU QUREINAT             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 9,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1342,
            "שם_ישוב": "אבו קרינאת )יישוב(       ",
            "שם_ישוב_לועזי": "ABU QUREINAT             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 68,
            "שם_מועצה": "נווה מדבר"
        },
        {
            "_id": 10,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 966,
            "שם_ישוב": "אבו רובייעה )שבט(        ",
            "שם_ישוב_לועזי": "ABU RUBEI'A              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 11,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 961,
            "שם_ישוב": "אבו רוקייק )שבט(         ",
            "שם_ישוב_לועזי": "ABU RUQAYYEQ             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 12,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1375,
            "שם_ישוב": "אבו תלול                 ",
            "שם_ישוב_לועזי": "ABU TULUL                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 68,
            "שם_מועצה": "נווה מדבר"
        },
        {
            "_id": 13,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין                    ",
            "שם_ישוב_לועזי": "IBTIN                    ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 14,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון                  ",
            "שם_ישוב_לועזי": "AVTALYON                 ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 15,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל                    ",
            "שם_ישוב_לועזי": "AVI'EL                   ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 45,
            "שם_מועצה": "אלונה"
        },
        {
            "_id": 16,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1115,
            "שם_ישוב": "אביבים                   ",
            "שם_ישוב_לועזי": "AVIVIM                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 17,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 819,
            "שם_ישוב": "אביגדור                  ",
            "שם_ישוב_לועזי": "AVIGEDOR                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 18,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל                   ",
            "שם_ישוב_לועזי": "AVIHAYIL                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 19,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2052,
            "שם_ישוב": "אביטל                    ",
            "שם_ישוב_לועזי": "AVITAL                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 20,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1070,
            "שם_ישוב": "אביעזר                   ",
            "שם_ישוב_לועזי": "AVI'EZER                 ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 21,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים                   ",
            "שם_ישוב_לועזי": "ABBIRIM                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 22,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה                ",
            "שם_ישוב_לועזי": "EVEN YEHUDA              ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 23,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1081,
            "שם_ישוב": "אבן מנחם                 ",
            "שם_ישוב_לועזי": "EVEN MENAHEM             ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 24,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר                 ",
            "שם_ישוב_לועזי": "EVEN SAPPIR              ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 25,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל                ",
            "שם_ישוב_לועזי": "EVEN SHEMU'EL            ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 26,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4011,
            "שם_ישוב": "אבני איתן                ",
            "שם_ישוב_לועזי": "AVNE ETAN                ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 27,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ                 ",
            "שם_ישוב_לועזי": "AVNE HEFEZ               ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 28,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3786,
            "שם_ישוב": "אבנת                     ",
            "שם_ישוב_לועזי": "AVENAT                   ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 74,
            "שם_מועצה": "מגילות ים המלח"
        },
        {
            "_id": 29,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1311,
            "שם_ישוב": "אבשלום                   ",
            "שם_ישוב_לועזי": "AVSHALOM                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 30,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3759,
            "שם_ישוב": "אדורה                    ",
            "שם_ישוב_לועזי": "ADORA                    ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 31,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 113,
            "שם_ישוב": "אדירים                   ",
            "שם_ישוב_לועזי": "ADDIRIM                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 32,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית                    ",
            "שם_ישוב_לועזי": "ADAMIT                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 33,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1123,
            "שם_ישוב": "אדרת                     ",
            "שם_ישוב_לועזי": "ADDERET                  ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 34,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים                    ",
            "שם_ישוב_לועזי": "UDIM                     ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 35,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4010,
            "שם_ישוב": "אודם                     ",
            "שם_ישוב_לועזי": "ODEM                     ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 36,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1046,
            "שם_ישוב": "אוהד                     ",
            "שם_ישוב_לועזי": "OHAD                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 37,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם               ",
            "שם_ישוב_לועזי": "UMM AL-FAHM              ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 38,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2024,
            "שם_ישוב": "אום אל-קוטוף             ",
            "שם_ישוב_לועזי": "UMM AL-QUTUF             ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 39,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1358,
            "שם_ישוב": "אום בטין                 ",
            "שם_ישוב_לועזי": "UMM BATIN                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 69,
            "שם_מועצה": "אל קסום"
        },
        {
            "_id": 40,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן                     ",
            "שם_ישוב_לועזי": "OMEN                     ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 41,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 680,
            "שם_ישוב": "אומץ                     ",
            "שם_ישוב_לועזי": "OMEZ                     ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 42,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים                   ",
            "שם_ישוב_לועזי": "OFAQIM                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 43,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1294,
            "שם_ישוב": "אור הגנוז                ",
            "שם_ישוב_לועזי": "OR HAGANUZ               ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 44,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 67,
            "שם_ישוב": "אור הנר                  ",
            "שם_ישוב_לועזי": "OR HANER                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 45,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה                ",
            "שם_ישוב_לועזי": "OR YEHUDA                ",
            "סמל_נפה": 52,
            "שם_נפה": "רמת גן              ",
            "סמל_לשכת_מנא": 52,
            "לשכה": "רמת גן                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 46,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא                ",
            "שם_ישוב_לועזי": "OR AQIVA                 ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 47,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה                     ",
            "שם_ישוב_לועזי": "ORA                      ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 48,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2012,
            "שם_ישוב": "אורות                    ",
            "שם_ישוב_לועזי": "OROT                     ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 49,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4013,
            "שם_ישוב": "אורטל                    ",
            "שם_ישוב_לועזי": "ORTAL                    ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 50,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 403,
            "שם_ישוב": "אורים                    ",
            "שם_ישוב_לועזי": "URIM                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 51,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 882,
            "שם_ישוב": "אורנים                   ",
            "שם_ישוב_לועזי": "ORANIM                   ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 52,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית                   ",
            "שם_ישוב_לועזי": "ORANIT                   ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 53,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 278,
            "שם_ישוב": "אושה                     ",
            "שם_ישוב_לועזי": "USHA                     ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 54,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור                     ",
            "שם_ישוב_לועזי": "AZOR                     ",
            "סמל_נפה": 53,
            "שם_נפה": "חולון               ",
            "סמל_לשכת_מנא": 53,
            "לשכה": "חולון                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 55,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1157,
            "שם_ישוב": "אחווה                    ",
            "שם_ישוב_לועזי": "AHAWA                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 56,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 821,
            "שם_ישוב": "אחוזם                    ",
            "שם_ישוב_לועזי": "AHUZZAM                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 57,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק                ",
            "שם_ישוב_לועזי": "AHUZZAT BARAQ            ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 58,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד                   ",
            "שם_ישוב_לועזי": "AHIHUD                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 59,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב                   ",
            "שם_ישוב_לועזי": "AHITUV                   ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 60,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 804,
            "שם_ישוב": "אחיסמך                   ",
            "שם_ישוב_לועזי": "AHISAMAKH                ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 61,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 797,
            "שם_ישוב": "אחיעזר                   ",
            "שם_ישוב_לועזי": "AHI'EZER                 ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 40,
            "שם_מועצה": "שדות דן"
        },
        {
            "_id": 62,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 965,
            "שם_ישוב": "אטרש )שבט(               ",
            "שם_ישוב_לועזי": "ATRASH                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 63,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 338,
            "שם_ישוב": "איבים                    ",
            "שם_ישוב_לועזי": "IBBIM                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 64,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 716,
            "שם_ישוב": "אייל                     ",
            "שם_ישוב_לועזי": "EYAL                     ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 65,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 77,
            "שם_ישוב": "איילת השחר               ",
            "שם_ישוב_לועזי": "AYYELET HASHAHAR         ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 66,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 294,
            "שם_ישוב": "אילון                    ",
            "שם_ישוב_לועזי": "ELON                     ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 67,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1126,
            "שם_ישוב": "אילות                    ",
            "שם_ישוב_לועזי": "ELOT                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 68,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה                   ",
            "שם_ישוב_לועזי": "ILANIYYA                 ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 69,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת                     ",
            "שם_ישוב_לועזי": "ELAT                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 70,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס                    ",
            "שם_ישוב_לועזי": "IRUS                     ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 27,
            "שם_מועצה": "גן רווה"
        },
        {
            "_id": 71,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3762,
            "שם_ישוב": "איתמר                    ",
            "שם_ישוב_לועזי": "ITAMAR                   ",
            "סמל_נפה": 72,
            "שם_נפה": "שכם                 ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 72,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 37,
            "שם_ישוב": "איתן                     ",
            "שם_ישוב_לועזי": "ETAN                     ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 73,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 886,
            "שם_ישוב": "איתנים                   ",
            "שם_ישוב_לועזי": "ETANIM                   ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 74,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל                    ",
            "שם_ישוב_לועזי": "IKSAL                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 75,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1359,
            "שם_ישוב": "אל סייד                  ",
            "שם_ישוב_לועזי": "AL SAYYID                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 69,
            "שם_מועצה": "אל קסום"
        },
        {
            "_id": 76,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1339,
            "שם_ישוב": "אל-עזי                   ",
            "שם_ישוב_לועזי": "AL-AZY                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 77,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1316,
            "שם_ישוב": "אל-עריאן                 ",
            "שם_ישוב_לועזי": "AL-ARYAN                 ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 78,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4003,
            "שם_ישוב": "אל-רום                   ",
            "שם_ישוב_לועזי": "EL-ROM                   ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 79,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1145,
            "שם_ישוב": "אלומה                    ",
            "שם_ישוב_לועזי": "ALUMMA                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 80,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 330,
            "שם_ישוב": "אלומות                   ",
            "שם_ישוב_לועזי": "ALUMMOT                  ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 81,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל               ",
            "שם_ישוב_לועזי": "ALLON HAGALIL            ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 82,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה                ",
            "שם_ישוב_לועזי": "ELON MORE                ",
            "סמל_נפה": 72,
            "שם_נפה": "שכם                 ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 83,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות                ",
            "שם_ישוב_לועזי": "ALLON SHEVUT             ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 84,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא                ",
            "שם_ישוב_לועזי": "ALLONE ABBA              ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 85,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4017,
            "שם_ישוב": "אלוני הבשן               ",
            "שם_ישוב_לועזי": "ALLONE HABASHAN          ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 86,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 868,
            "שם_ישוב": "אלוני יצחק               ",
            "שם_ישוב_לועזי": "ALLONE YIZHAQ            ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 87,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 285,
            "שם_ישוב": "אלונים                   ",
            "שם_ישוב_לועזי": "ALLONIM                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 88,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4002,
            "שם_ישוב": "אלי-עד                   ",
            "שם_ישוב_לועזי": "ELI AL                   ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 89,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב                    ",
            "שם_ישוב_לועזי": "ELIAV                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 90,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין                   ",
            "שם_ישוב_לועזי": "ELYAKHIN                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 91,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1248,
            "שם_ישוב": "אליפז                    ",
            "שם_ישוב_לועזי": "ELIFAZ                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 92,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט                   ",
            "שם_ישוב_לועזי": "ELIFELET                 ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 93,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 682,
            "שם_ישוב": "אליקים                   ",
            "שם_ישוב_לועזי": "ELYAQIM                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 94,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב                   ",
            "שם_ישוב_לועזי": "ELYASHIV                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 95,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע                   ",
            "שם_ישוב_לועזי": "ELISHAMA                 ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 96,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1125,
            "שם_ישוב": "אלמגור                   ",
            "שם_ישוב_לועזי": "ALMAGOR                  ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 97,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3556,
            "שם_ישוב": "אלמוג                    ",
            "שם_ישוב_לועזי": "ALMOG                    ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 74,
            "שם_מועצה": "מגילות ים המלח"
        },
        {
            "_id": 98,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד                     ",
            "שם_ישוב_לועזי": "EL'AD                    ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 99,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר                    ",
            "שם_ישוב_לועזי": "EL'AZAR                  ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 100,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה                ",
            "שם_ישוב_לועזי": "ALFE MENASHE             ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 101,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש                    ",
            "שם_ישוב_לועזי": "ELQOSH                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 102,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה                    ",
            "שם_ישוב_לועזי": "ELQANA                   ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 103,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 772,
            "שם_ישוב": "אמונים                   ",
            "שם_ישוב_לועזי": "EMUNIM                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 104,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1064,
            "שם_ישוב": "אמירים                   ",
            "שם_ישוב_לועזי": "AMIRIM                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 105,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון                    ",
            "שם_ישוב_לועזי": "AMNUN                    ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 106,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 23,
            "שם_ישוב": "אמציה                    ",
            "שם_ישוב_לועזי": "AMAZYA                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 107,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4012,
            "שם_ישוב": "אניעם                    ",
            "שם_ישוב_לועזי": "ANI'AM                   ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 108,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 960,
            "שם_ישוב": "אסד )שבט(                ",
            "שם_ישוב_לועזי": "ASAD                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 109,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3754,
            "שם_ישוב": "אספר                     ",
            "שם_ישוב_לועזי": "ASEFAR                   ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 110,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין                   ",
            "שם_ישוב_לועזי": "I'BILLIN                 ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 111,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 963,
            "שם_ישוב": "אעצם )שבט(               ",
            "שם_ישוב_לועזי": "A'SAM                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 112,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 959,
            "שם_ישוב": "אפיניש )שבט(             ",
            "שם_ישוב_לועזי": "AFEINISH                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 113,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4301,
            "שם_ישוב": "אפיק                     ",
            "שם_ישוב_לועזי": "AFIQ                     ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 114,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 176,
            "שם_ישוב": "אפיקים                   ",
            "שם_ישוב_לועזי": "AFIQIM                   ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 115,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 313,
            "שם_ישוב": "אפק                      ",
            "שם_ישוב_לועזי": "AFEQ                     ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 116,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת                     ",
            "שם_ישוב_לועזי": "EFRAT                    ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 117,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל                     ",
            "שם_ישוב_לועזי": "ARBEL                    ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 118,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3598,
            "שם_ישוב": "ארגמן                    ",
            "שם_ישוב_לועזי": "ARGAMAN                  ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 119,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 714,
            "שם_ישוב": "ארז                      ",
            "שם_ישוב_לועזי": "EREZ                     ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 120,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל                    ",
            "שם_ישוב_לועזי": "ARI'EL                   ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 121,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1324,
            "שם_ישוב": "ארסוף                    ",
            "שם_ישוב_לועזי": "ARSUF                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 122,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 71,
            "שם_ישוב": "אשבול                    ",
            "שם_ישוב_לועזי": "ESHBOL                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 123,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1276,
            "שם_ישוב": "אשבל                     ",
            "שם_ישוב_לועזי": "NAHAL ESHBAL             ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 124,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד                    ",
            "שם_ישוב_לועזי": "ASHDOD                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 69,
            "לשכה": "אשדוד                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 125,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 199,
            "שם_ישוב": "אשדות יעקב  )איחוד(      ",
            "שם_ישוב_לועזי": "ASHDOT YA'AQOV(IHUD)     ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 126,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 188,
            "שם_ישוב": "אשדות יעקב  )מאוחד(      ",
            "שם_ישוב_לועזי": "ASHDOT YA'AQOV(ME'UH     ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 127,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1188,
            "שם_ישוב": "אשחר                     ",
            "שם_ישוב_לועזי": "ESHHAR                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 128,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3722,
            "שם_ישוב": "אשכולות                  ",
            "שם_ישוב_לועזי": "ESHKOLOT                 ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 129,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2021,
            "שם_ישוב": "אשל הנשיא                ",
            "שם_ישוב_לועזי": "ESHEL HANASI             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 130,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1152,
            "שם_ישוב": "אשלים                    ",
            "שם_ישוב_לועזי": "ASHALIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 131,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון                   ",
            "שם_ישוב_לועזי": "ASHQELON                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 132,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת                     ",
            "שם_ישוב_לועזי": "ASHERAT                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 133,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 740,
            "שם_ישוב": "אשתאול                   ",
            "שם_ישוב_לועזי": "ESHTA'OL                 ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 134,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1298,
            "שם_ישוב": "אתגר                     ",
            "שם_ישוב_לועזי": "ETGAR                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 135,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה            ",
            "שם_ישוב_לועזי": "BAQA AL-GHARBIYYE        ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 136,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה                 ",
            "שם_ישוב_לועזי": "BE'ER ORA                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 137,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים                 ",
            "שם_ישוב_לועזי": "BEER GANNIM              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 138,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 155,
            "שם_ישוב": "באר טוביה                ",
            "שם_ישוב_לועזי": "BE'ER TUVEYA             ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 139,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב                 ",
            "שם_ישוב_לועזי": "BE'ER YA'AQOV            ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 140,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1278,
            "שם_ישוב": "באר מילכה                ",
            "שם_ישוב_לועזי": "BE'ER MILKA              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 141,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע                  ",
            "שם_ישוב_לועזי": "BE'ER SHEVA              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 142,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 450,
            "שם_ישוב": "בארות יצחק               ",
            "שם_ישוב_לועזי": "BE'EROT YIZHAQ           ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 143,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 697,
            "שם_ישוב": "בארותיים                 ",
            "שם_ישוב_לועזי": "BE'EROTAYIM              ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 144,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 399,
            "שם_ישוב": "בארי                     ",
            "שם_ישוב_לועזי": "BE'ERI                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 145,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל              ",
            "שם_ישוב_לועזי": "BUSTAN HAGALIL           ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 146,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת         ",
            "שם_ישוב_לועזי": "BU'EINE-NUJEIDAT         ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 147,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4001,
            "שם_ישוב": "בוקעאתא                  ",
            "שם_ישוב_לועזי": "BUQ'ATA                  ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 148,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 698,
            "שם_ישוב": "בורגתה                   ",
            "שם_ישוב_לועזי": "BURGETA                  ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 149,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן                      ",
            "שם_ישוב_לועזי": "BAHAN                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 150,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 762,
            "שם_ישוב": "בטחה                     ",
            "שם_ישוב_לועזי": "BITHA                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 151,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון                   ",
            "שם_ישוב_לועזי": "BIZZARON                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 152,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור             ",
            "שם_ישוב_לועזי": "BIR EL-MAKSUR            ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 153,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1348,
            "שם_ישוב": "ביר הדאג'                ",
            "שם_ישוב_לועזי": "BIR HADAGE               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 68,
            "שם_מועצה": "נווה מדבר"
        },
        {
            "_id": 154,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 368,
            "שם_ישוב": "ביריה                    ",
            "שם_ישוב_לועזי": "BIRIYYA                  ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 155,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 317,
            "שם_ישוב": "בית אורן                 ",
            "שם_ישוב_לועזי": "BET OREN                 ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 156,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל                   ",
            "שם_ישוב_לועזי": "BET EL                   ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 157,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 562,
            "שם_ישוב": "בית אלעזרי               ",
            "שם_ישוב_לועזי": "BET EL'AZARI             ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 28,
            "שם_מועצה": "ברנר"
        },
        {
            "_id": 158,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 95,
            "שם_ישוב": "בית אלפא                 ",
            "שם_ישוב_לועזי": "BET ALFA                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 159,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה                 ",
            "שם_ישוב_לועזי": "BET ARYE                 ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 160,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1076,
            "שם_ישוב": "בית ברל                  ",
            "שם_ישוב_לועזי": "BET BERL                 ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 161,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן                  ",
            "שם_ישוב_לועזי": "BEIT JANN                ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 162,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 619,
            "שם_ישוב": "בית גוברין               ",
            "שם_ישוב_לועזי": "BET GUVRIN               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 163,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 571,
            "שם_ישוב": "בית גמליאל               ",
            "שם_ישוב_לועזי": "BET GAMLI'EL             ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 29,
            "שם_מועצה": "חבל יבנה"
        },
        {
            "_id": 164,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן                  ",
            "שם_ישוב_לועזי": "BET DAGAN                ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 165,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 723,
            "שם_ישוב": "בית הגדי                 ",
            "שם_ישוב_לועזי": "BET HAGADDI              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 166,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 373,
            "שם_ישוב": "בית הלוי                 ",
            "שם_ישוב_לועזי": "BET HALEVI               ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 167,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 322,
            "שם_ישוב": "בית הלל                  ",
            "שם_ישוב_לועזי": "BET HILLEL               ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 168,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 572,
            "שם_ישוב": "בית העמק                 ",
            "שם_ישוב_לועזי": "BET HAEMEQ               ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 169,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3645,
            "שם_ישוב": "בית הערבה                ",
            "שם_ישוב_לועזי": "BET HAARAVA              ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 74,
            "שם_מועצה": "מגילות ים המלח"
        },
        {
            "_id": 170,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 242,
            "שם_ישוב": "בית השיטה                ",
            "שם_ישוב_לועזי": "BET HASHITTA             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 171,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 353,
            "שם_ישוב": "בית זיד                  ",
            "שם_ישוב_לועזי": "BET ZEID                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 172,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית                  ",
            "שם_ישוב_לועזי": "BET ZAYIT                ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 173,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 143,
            "שם_ישוב": "בית זרע                  ",
            "שם_ישוב_לועזי": "BET ZERA                 ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 174,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון                ",
            "שם_ישוב_לועזי": "BET HORON                ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 175,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות                ",
            "שם_ישוב_לועזי": "BET HERUT                ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 176,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2033,
            "שם_ישוב": "בית חלקיה                ",
            "שם_ישוב_לועזי": "BET HILQIYYA             ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 31,
            "שם_מועצה": "נחל שורק"
        },
        {
            "_id": 177,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 159,
            "שם_ישוב": "בית חנן                  ",
            "שם_ישוב_לועזי": "BET HANAN                ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 27,
            "שם_מועצה": "גן רווה"
        },
        {
            "_id": 178,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה                ",
            "שם_ישוב_לועזי": "BET HANANYA              ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 179,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי              ",
            "שם_ישוב_לועזי": "BET HASHMONAY            ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 180,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע                ",
            "שם_ישוב_לועזי": "BET YEHOSHUA             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 181,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 265,
            "שם_ישוב": "בית יוסף                 ",
            "שם_ישוב_לועזי": "BET YOSEF                ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 182,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי                 ",
            "שם_ישוב_לועזי": "BET YANNAY               ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 183,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר         ",
            "שם_ישוב_לועזי": "BET YIZHAQ-SH. HEFER     ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 184,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית          ",
            "שם_ישוב_לועזי": "BET LEHEM HAGELILIT      ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 185,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 751,
            "שם_ישוב": "בית מאיר                 ",
            "שם_ישוב_לועזי": "BET ME'IR                ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 186,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 784,
            "שם_ישוב": "בית נחמיה                ",
            "שם_ישוב_לועזי": "BET NEHEMYA              ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 187,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 16,
            "שם_ישוב": "בית ניר                  ",
            "שם_ישוב_לועזי": "BET NIR                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 188,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה                ",
            "שם_ישוב_לועזי": "BET NEQOFA               ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 189,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד                 ",
            "שם_ישוב_לועזי": "BET OVED                 ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 27,
            "שם_מועצה": "גן רווה"
        },
        {
            "_id": 190,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 301,
            "שם_ישוב": "בית עוזיאל               ",
            "שם_ישוב_לועזי": "BET UZZI'EL              ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 191,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 756,
            "שם_ישוב": "בית עזרא                 ",
            "שם_ישוב_לועזי": "BET EZRA                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 192,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף                 ",
            "שם_ישוב_לועזי": "BET ARIF                 ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 193,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 212,
            "שם_ישוב": "בית צבי                  ",
            "שם_ישוב_לועזי": "BET ZEVI                 ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 194,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 598,
            "שם_ישוב": "בית קמה                  ",
            "שם_ישוב_לועזי": "BET QAMA                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 195,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת                  ",
            "שם_ישוב_לועזי": "BET QESHET               ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 196,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 848,
            "שם_ישוב": "בית רבן                  ",
            "שם_ישוב_לועזי": "BET RABBAN               ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 29,
            "שם_מועצה": "חבל יבנה"
        },
        {
            "_id": 197,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון                ",
            "שם_ישוב_לועזי": "BET RIMMON               ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 198,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן                  ",
            "שם_ישוב_לועזי": "BET SHE'AN               ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 199,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש                  ",
            "שם_ישוב_לועזי": "BET SHEMESH              ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 200,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים                ",
            "שם_ישוב_לועזי": "BET SHE'ARIM             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 201,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 747,
            "שם_ישוב": "בית שקמה                 ",
            "שם_ישוב_לועזי": "BET SHIQMA               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 202,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן                ",
            "שם_ישוב_לועזי": "BITAN AHARON             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 203,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית               ",
            "שם_ישוב_לועזי": "BETAR ILLIT              ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 204,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה                  ",
            "שם_ישוב_לועזי": "BALFURIYYA               ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 205,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי                  ",
            "שם_ישוב_לועזי": "BEN ZAKKAY               ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 29,
            "שם_מועצה": "חבל יבנה"
        },
        {
            "_id": 206,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 712,
            "שם_ישוב": "בן עמי                   ",
            "שם_ישוב_לועזי": "BEN AMMI                 ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 207,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1084,
            "שם_ישוב": "בן שמן )כפר נוער(        ",
            "שם_ישוב_לועזי": "BEN SHEMEN(K.NO'AR)      ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 208,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2013,
            "שם_ישוב": "בן שמן )מושב(            ",
            "שם_ישוב_לועזי": "BEN SHEMEN (MOSHAV)      ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 209,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק                  ",
            "שם_ישוב_לועזי": "BENE BERAQ               ",
            "סמל_נפה": 52,
            "שם_נפה": "רמת גן              ",
            "סמל_לשכת_מנא": 54,
            "לשכה": "בני ברק                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 210,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים                ",
            "שם_ישוב_לועזי": "BNE DKALIM               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 211,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום                 ",
            "שם_ישוב_לועזי": "BENE DAROM               ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 29,
            "שם_מועצה": "חבל יבנה"
        },
        {
            "_id": 212,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור                 ",
            "שם_ישוב_לועזי": "BENE DEROR               ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 213,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4015,
            "שם_ישוב": "בני יהודה                ",
            "שם_ישוב_לועזי": "BENE YEHUDA              ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 214,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1363,
            "שם_ישוב": "בני נצרים                ",
            "שם_ישוב_לועזי": "BENE NEZARIM             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 215,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות                ",
            "שם_ישוב_לועזי": "BENE ATAROT              ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 216,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עי\"ש                 ",
            "שם_ישוב_לועזי": "BENE AYISH               ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 217,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון                 ",
            "שם_ישוב_לועזי": "BENE ZIYYON              ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 218,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם                  ",
            "שם_ישוב_לועזי": "BENE RE'EM               ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 31,
            "שם_מועצה": "נחל שורק"
        },
        {
            "_id": 219,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 685,
            "שם_ישוב": "בניה                     ",
            "שם_ישוב_לועזי": "BENAYA                   ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 28,
            "שם_מועצה": "ברנר"
        },
        {
            "_id": 220,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה         ",
            "שם_ישוב_לועזי": "BINYAMINA                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 221,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמ\"ה                    ",
            "שם_ישוב_לועזי": "BASMA                    ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 222,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון               ",
            "שם_ישוב_לועזי": "BASMAT TAB'UN            ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 223,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה                     ",
            "שם_ישוב_לועזי": "BI NE                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 224,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה                     ",
            "שם_ישוב_לועזי": "BAZRA                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 225,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 589,
            "שם_ישוב": "בצת                      ",
            "שם_ישוב_לועזי": "BEZET                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 226,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 864,
            "שם_ישוב": "בקוע                     ",
            "שם_ישוב_לועזי": "BEQOA                    ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 227,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3612,
            "שם_ישוב": "בקעות                    ",
            "שם_ישוב_לועזי": "BEQA'OT                  ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 228,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 823,
            "שם_ישוב": "בר גיורא                 ",
            "שם_ישוב_לועזי": "BAR GIYYORA              ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 229,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1191,
            "שם_ישוב": "בר יוחאי                 ",
            "שם_ישוב_לועזי": "BAR YOHAY                ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 230,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין                   ",
            "שם_ישוב_לועזי": "BRUKHIN                  ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 231,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 428,
            "שם_ישוב": "ברור חיל                 ",
            "שם_ישוב_לועזי": "BEROR HAYIL              ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 232,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2060,
            "שם_ישוב": "ברוש                     ",
            "שם_ישוב_לועזי": "BEROSH                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 233,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה                     ",
            "שם_ישוב_לועזי": "BERAKHA                  ",
            "סמל_נפה": 72,
            "שם_נפה": "שכם                 ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 234,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 746,
            "שם_ישוב": "ברכיה                    ",
            "שם_ישוב_לועזי": "BEREKHYA                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 235,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 667,
            "שם_ישוב": "ברעם                     ",
            "שם_ישוב_לועזי": "BAR'AM                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 236,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 141,
            "שם_ישוב": "ברק                      ",
            "שם_ישוב_לועזי": "BARAQ                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 237,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 617,
            "שם_ישוב": "ברקאי                    ",
            "שם_ישוב_לועזי": "BARQAY                   ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 238,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן                     ",
            "שם_ישוב_לועזי": "BARQAN                   ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 239,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת                     ",
            "שם_ישוב_לועזי": "BAREQET                  ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 240,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1323,
            "שם_ישוב": "בת הדר                   ",
            "שם_ישוב_לועזי": "BAT HADAR                ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 241,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1361,
            "שם_ישוב": "בת חן                    ",
            "שם_ישוב_לועזי": "BAT HEN                  ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 242,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר                   ",
            "שם_ישוב_לועזי": "BAT HEFER                ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 243,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1401,
            "שם_ישוב": "בת חצור                  ",
            "שם_ישוב_לועזי": "BAT HAZOR                ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 244,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים                    ",
            "שם_ישוב_לועזי": "BAT YAM                  ",
            "סמל_נפה": 53,
            "שם_נפה": "חולון               ",
            "סמל_לשכת_מנא": 53,
            "לשכה": "חולון                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 245,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3794,
            "שם_ישוב": "בת עין                   ",
            "שם_ישוב_לועזי": "BAT AYIN                 ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 246,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה                  ",
            "שם_ישוב_לועזי": "BAT SHELOMO              ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 247,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר              ",
            "שם_ישוב_לועזי": "JUDEIDE-MAKER            ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 248,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס                    ",
            "שם_ישוב_לועזי": "JULIS                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 249,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה                ",
            "שם_ישוב_לועזי": "JALJULYE                 ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 250,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 976,
            "שם_ישוב": "ג'נאביב )שבט(            ",
            "שם_ישוב_לועזי": "JUNNABIB                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 251,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא              ",
            "שם_ישוב_לועזי": "JISR AZ-ZARQA            ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 252,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 487,
            "שם_ישוב": "ג'ש )גוש חלב(            ",
            "שם_ישוב_לועזי": "JISH(GUSH HALAV)         ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 253,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת                      ",
            "שם_ישוב_לועזי": "JAAT                     ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 254,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן               ",
            "שם_ישוב_לועזי": "GE'ULE TEMAN             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 255,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים                   ",
            "שם_ישוב_לועזי": "GE'ULIM                  ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 256,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה                    ",
            "שם_ישוב_לועזי": "GE'ALYA                  ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 27,
            "שם_מועצה": "גן רווה"
        },
        {
            "_id": 257,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 352,
            "שם_ישוב": "גבולות                   ",
            "שם_ישוב_לועזי": "GEVULOT                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 258,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 424,
            "שם_ישוב": "גבים                     ",
            "שם_ישוב_לועזי": "GEVIM                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 259,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 86,
            "שם_ישוב": "גבע                      ",
            "שם_ישוב_לועזי": "GEVA                     ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 260,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין               ",
            "שם_ישוב_לועזי": "GEVA BINYAMIN            ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 261,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל                 ",
            "שם_ישוב_לועזי": "GEVA  KARMEL             ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 262,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2014,
            "שם_ישוב": "גבעולים                  ",
            "שם_ישוב_לועזי": "GIV'OLIM                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 263,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה              ",
            "שם_ישוב_לועזי": "GIV'ON HAHADASHA         ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 264,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר                 ",
            "שם_ישוב_לועזי": "GEVA'OT BAR              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 265,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני                ",
            "שם_ישוב_לועזי": "GIV'AT AVNI              ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 266,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה                 ",
            "שם_ישוב_לועזי": "GIV'AT ELA               ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 267,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 147,
            "שם_ישוב": "גבעת ברנר                ",
            "שם_ישוב_לועזי": "GIV'AT BRENNER           ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 28,
            "שם_מועצה": "ברנר"
        },
        {
            "_id": 268,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 870,
            "שם_ישוב": "גבעת השלושה              ",
            "שם_ישוב_לועזי": "GIV'AT HASHELOSHA        ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 269,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב                 ",
            "שם_ישוב_לועזי": "GIV'AT ZE'EV             ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 270,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת ח\"ן                 ",
            "שם_ישוב_לועזי": "GIV'AT HEN               ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 271,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2018,
            "שם_ישוב": "גבעת חיים )איחוד(        ",
            "שם_ישוב_לועזי": "GIV'AT HAYYIM (IHUD)     ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 272,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 173,
            "שם_ישוב": "גבעת חיים )מאוחד(        ",
            "שם_ישוב_לועזי": "GIV'AT HAYYIM(ME'UHA     ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 273,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב                ",
            "שם_ישוב_לועזי": "GIV'AT YO'AV             ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 274,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים               ",
            "שם_ישוב_לועזי": "GIV'AT YE'ARIM           ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 275,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 919,
            "שם_ישוב": "גבעת ישעיהו              ",
            "שם_ישוב_לועזי": "GIV'AT YESHA'YAHU        ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 276,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כ\"ח                 ",
            "שם_ישוב_לועזי": "GIV'AT KOAH              ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 277,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת ניל\"י               ",
            "שם_ישוב_לועזי": "GIV'AT NILI              ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 45,
            "שם_מועצה": "אלונה"
        },
        {
            "_id": 278,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 703,
            "שם_ישוב": "גבעת עוז                 ",
            "שם_ישוב_לועזי": "GIV'AT OZ                ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 279,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל               ",
            "שם_ישוב_לועזי": "GIV'AT SHEMU'EL          ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 280,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 566,
            "שם_ישוב": "גבעת שמש                 ",
            "שם_ישוב_לועזי": "GIV'AT SHEMESH           ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 281,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1077,
            "שם_ישוב": "גבעת שפירא               ",
            "שם_ישוב_לועזי": "GIV'AT SHAPPIRA          ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 282,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 793,
            "שם_ישוב": "גבעתי                    ",
            "שם_ישוב_לועזי": "GIV'ATI                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 283,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים                  ",
            "שם_ישוב_לועזי": "GIV'ATAYIM               ",
            "סמל_נפה": 52,
            "שם_נפה": "רמת גן              ",
            "סמל_לשכת_מנא": 52,
            "לשכה": "רמת גן                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 284,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 342,
            "שם_ישוב": "גברעם                    ",
            "שם_ישוב_לועזי": "GEVAR'AM                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 285,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 133,
            "שם_ישוב": "גבת                      ",
            "שם_ישוב_לועזי": "GEVAT                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 286,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 35,
            "שם_ישוב": "גדות                     ",
            "שם_ישוב_לועזי": "GADOT                    ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 287,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 145,
            "שם_ישוב": "גדיש                     ",
            "שם_ישוב_לועזי": "GADISH                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 288,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה                   ",
            "שם_ישוב_לועזי": "GID'ONA                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 289,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה                     ",
            "שם_ישוב_לועזי": "GEDERA                   ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 290,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 852,
            "שם_ישוב": "גונן                     ",
            "שם_ישוב_לועזי": "GONEN                    ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 291,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן                     ",
            "שם_ישוב_לועזי": "GOREN                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 292,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1219,
            "שם_ישוב": "גורנות הגליל             ",
            "שם_ישוב_לועזי": "GORNOT HAGALIL           ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 293,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 457,
            "שם_ישוב": "גזית                     ",
            "שם_ישוב_לועזי": "GAZIT                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 294,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 370,
            "שם_ישוב": "גזר                      ",
            "שם_ישוב_לועזי": "GEZER                    ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 295,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 706,
            "שם_ישוב": "גיאה                     ",
            "שם_ישוב_לועזי": "GE'A                     ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 296,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 196,
            "שם_ישוב": "גיבתון                   ",
            "שם_ישוב_לועזי": "GIBBETON                 ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 28,
            "שם_מועצה": "ברנר"
        },
        {
            "_id": 297,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1043,
            "שם_ישוב": "גיזו                     ",
            "שם_ישוב_לועזי": "GIZO                     ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 298,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון                    ",
            "שם_ישוב_לועזי": "GILON                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 299,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 736,
            "שם_ישוב": "גילת                     ",
            "שם_ישוב_לועזי": "GILAT                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 300,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 262,
            "שם_ישוב": "גינוסר                   ",
            "שם_ישוב_לועזי": "GINNOSAR                 ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 301,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר                   ",
            "שם_ישוב_לועזי": "GINNEGAR                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 302,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון                   ",
            "שם_ישוב_לועזי": "GINNATON                 ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 303,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1206,
            "שם_ישוב": "גיתה                     ",
            "שם_ישוב_לועזי": "GITTA                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 304,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3613,
            "שם_ישוב": "גיתית                    ",
            "שם_ישוב_לועזי": "GITTIT                   ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 305,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 393,
            "שם_ישוב": "גלאון                    ",
            "שם_ישוב_לועזי": "GAL'ON                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 306,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3606,
            "שם_ישוב": "גלגל                     ",
            "שם_ישוב_לועזי": "GILGAL                   ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 307,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 346,
            "שם_ישוב": "גליל ים                  ",
            "שם_ישוב_לועזי": "GELIL YAM                ",
            "סמל_נפה": 51,
            "שם_נפה": "תל אביב             ",
            "סמל_לשכת_מנא": 57,
            "לשכה": "הרצליה                   ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 308,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 369,
            "שם_ישוב": "גלעד )אבן יצחק(          ",
            "שם_ישוב_לועזי": "EVEN YIZHAQ(GAL'ED)      ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 309,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 745,
            "שם_ישוב": "גמזו                     ",
            "שם_ישוב_לועזי": "GIMZO                    ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 310,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1072,
            "שם_ישוב": "גן הדרום                 ",
            "שם_ישוב_לועזי": "GAN HADAROM              ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 32,
            "שם_מועצה": "גדרות"
        },
        {
            "_id": 311,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון               ",
            "שם_ישוב_לועזי": "GAN HASHOMERON           ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 312,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים                  ",
            "שם_ישוב_לועזי": "GAN HAYYIM               ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 313,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה                 ",
            "שם_ישוב_לועזי": "GAN YOSHIYYA             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 314,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה                  ",
            "שם_ישוב_לועזי": "GAN YAVNE                ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 315,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר                    ",
            "שם_ישוב_לועזי": "GAN NER                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 316,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 311,
            "שם_ישוב": "גן שורק                  ",
            "שם_ישוב_לועזי": "GAN SOREQ                ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 27,
            "שם_מועצה": "גן רווה"
        },
        {
            "_id": 317,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 144,
            "שם_ישוב": "גן שלמה                  ",
            "שם_ישוב_לועזי": "GAN SHELOMO              ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 28,
            "שם_מועצה": "ברנר"
        },
        {
            "_id": 318,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 72,
            "שם_ישוב": "גן שמואל                 ",
            "שם_ישוב_לועזי": "GAN SHEMU'EL             ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 319,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות                     ",
            "שם_ישוב_לועזי": "GANNOT                   ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 40,
            "שם_מועצה": "שדות דן"
        },
        {
            "_id": 320,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר                 ",
            "שם_ישוב_לועזי": "GANNOT HADAR             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 321,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1103,
            "שם_ישוב": "גני הדר                  ",
            "שם_ישוב_לועזי": "GANNE HADAR              ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 322,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל                   ",
            "שם_ישוב_לועזי": "GANNE TAL                ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 31,
            "שם_מועצה": "נחל שורק"
        },
        {
            "_id": 323,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 862,
            "שם_ישוב": "גני יוחנן                ",
            "שם_ישוב_לועזי": "GANNE YOHANAN            ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 324,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין              ",
            "שם_ישוב_לועזי": "GANNE MODIIN             ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 325,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם                   ",
            "שם_ישוב_לועזי": "GANNE AM                 ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 326,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה                ",
            "שם_ישוב_לועזי": "GANNE TIQWA              ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 327,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 842,
            "שם_ישוב": "געש                      ",
            "שם_ישוב_לועזי": "GA'ASH                   ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 328,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 463,
            "שם_ישוב": "געתון                    ",
            "שם_ישוב_לועזי": "GA'TON                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 329,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 39,
            "שם_ישוב": "גפן                      ",
            "שם_ישוב_לועזי": "GEFEN                    ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 330,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1129,
            "שם_ישוב": "גרופית                   ",
            "שם_ישוב_לועזי": "GEROFIT                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 331,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4022,
            "שם_ישוב": "גשור                     ",
            "שם_ישוב_לועזי": "GESHUR                   ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 332,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 305,
            "שם_ישוב": "גשר                      ",
            "שם_ישוב_לועזי": "GESHER                   ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 333,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו                 ",
            "שם_ישוב_לועזי": "GESHER HAZIW             ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 334,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 340,
            "שם_ישוב": "גת )קיבוץ(               ",
            "שם_ישוב_לועזי": "GAT(QIBBUZ)              ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 335,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 128,
            "שם_ישוב": "גת רימון                 ",
            "שם_ישוב_לועזי": "GAT RIMMON               ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 336,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל            ",
            "שם_ישוב_לועזי": "DALIYAT AL-KARMEL        ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 337,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 146,
            "שם_ישוב": "דבורה                    ",
            "שם_ישוב_לועזי": "DEVORA                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 338,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה                   ",
            "שם_ישוב_לועזי": "DABBURYE                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 339,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 849,
            "שם_ישוב": "דבירה                    ",
            "שם_ישוב_לועזי": "DEVIRA                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 340,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 407,
            "שם_ישוב": "דברת                     ",
            "שם_ישוב_לועזי": "DAVERAT                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 341,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 62,
            "שם_ישוב": "דגניה א'                 ",
            "שם_ישוב_לועזי": "DEGANYA ALEF             ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 342,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 79,
            "שם_ישוב": "דגניה ב'                 ",
            "שם_ישוב_לועזי": "DEGANYA BET              ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 343,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1067,
            "שם_ישוב": "דוב\"ב                    ",
            "שם_ישוב_לועזי": "DOVEV                    ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 344,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב                     ",
            "שם_ישוב_לועזי": "DOLEV                    ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 345,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 738,
            "שם_ישוב": "דור                      ",
            "שם_ישוב_לועזי": "DOR                      ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 346,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 336,
            "שם_ישוב": "דורות                    ",
            "שם_ישוב_לועזי": "DOROT                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 347,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 475,
            "שם_ישוב": "דחי                      ",
            "שם_ישוב_לועזי": "DAHI                     ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 66,
            "שם_מועצה": "בוסתאן אל-מרג'"
        },
        {
            "_id": 348,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד              ",
            "שם_ישוב_לועזי": "DEIR AL-ASAD             ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 349,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא                 ",
            "שם_ישוב_לועזי": "DEIR HANNA               ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 350,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 493,
            "שם_ישוב": "דייר ראפאת               ",
            "שם_ישוב_לועזי": "DEIR RAFAT               ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 351,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה                   ",
            "שם_ישוב_לועזי": "DIMONA                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 352,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2063,
            "שם_ישוב": "דישון                    ",
            "שם_ישוב_לועזי": "DISHON                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 353,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 300,
            "שם_ישוב": "דליה                     ",
            "שם_ישוב_לועזי": "DALIYYA                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 354,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 431,
            "שם_ישוב": "דלתון                    ",
            "שם_ישוב_לועזי": "DALTON                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 355,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה                   ",
            "שם_ישוב_לועזי": "DEMEIDE                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 356,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 303,
            "שם_ישוב": "דן                       ",
            "שם_ישוב_לועזי": "DAN                      ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 357,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 302,
            "שם_ישוב": "דפנה                     ",
            "שם_ישוב_לועזי": "DAFNA                    ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 358,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1241,
            "שם_ישוב": "דקל                      ",
            "שם_ישוב_לועזי": "DEQEL                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 359,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את                  ",
            "שם_ישוב_לועזי": "DERIG'AT                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 69,
            "שם_מועצה": "אל קסום"
        },
        {
            "_id": 360,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 702,
            "שם_ישוב": "האון                     ",
            "שם_ישוב_לועזי": "HAON                     ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 361,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים                   ",
            "שם_ישוב_לועזי": "HABONIM                  ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 362,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 356,
            "שם_ישוב": "הגושרים                  ",
            "שם_ישוב_לועזי": "HAGOSHERIM               ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 363,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם                   ",
            "שם_ישוב_לועזי": "HADAR AM                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 364,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון                ",
            "שם_ישוב_לועזי": "HOD HASHARON             ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 365,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 726,
            "שם_ישוב": "הודיה                    ",
            "שם_ישוב_לועזי": "HODIYYA                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 366,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1322,
            "שם_ישוב": "הודיות                   ",
            "שם_ישוב_לועזי": "HODAYOT                  ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 367,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1169,
            "שם_ישוב": "הוואשלה )שבט(            ",
            "שם_ישוב_לועזי": "HAWASHLA                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 368,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 956,
            "שם_ישוב": "הוזייל )שבט(             ",
            "שם_ישוב_לועזי": "HUZAYYEL                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 369,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה                   ",
            "שם_ישוב_לועזי": "HOSHA'AYA                ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 370,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 250,
            "שם_ישוב": "הזורע                    ",
            "שם_ישוב_לועזי": "HAZOREA                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 371,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 307,
            "שם_ישוב": "הזורעים                  ",
            "שם_ישוב_לועזי": "HAZORE'IM                ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 372,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 434,
            "שם_ישוב": "החותרים                  ",
            "שם_ישוב_לועזי": "HAHOTERIM                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 373,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב                    ",
            "שם_ישוב_לועזי": "HAYOGEV                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 374,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1208,
            "שם_ישוב": "הילה                     ",
            "שם_ישוב_לועזי": "HILLA                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 375,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 377,
            "שם_ישוב": "המעפיל                   ",
            "שם_ישוב_לועזי": "HAMA'PIL                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 376,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 677,
            "שם_ישוב": "הסוללים                  ",
            "שם_ישוב_לועזי": "HASOLELIM                ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 377,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 423,
            "שם_ישוב": "העוגן                    ",
            "שם_ישוב_לועזי": "HAOGEN                   ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 378,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר                   ",
            "שם_ישוב_לועזי": "HAR ADAR                 ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 379,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה                  ",
            "שם_ישוב_לועזי": "HAR GILLO                ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 380,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1261,
            "שם_ישוב": "הר עמשא                  ",
            "שם_ישוב_לועזי": "HAR AMASA                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 51,
            "שם_מועצה": "תמר"
        },
        {
            "_id": 381,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 464,
            "שם_ישוב": "הראל                     ",
            "שם_ישוב_לועזי": "HAR'EL                   ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 382,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1249,
            "שם_ישוב": "הרדוף                    ",
            "שם_ישוב_לועזי": "HARDUF                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 383,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה                   ",
            "שם_ישוב_לועזי": "HERZELIYYA               ",
            "סמל_נפה": 51,
            "שם_נפה": "תל אביב             ",
            "סמל_לשכת_מנא": 57,
            "לשכה": "הרצליה                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 384,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית                    ",
            "שם_ישוב_לועזי": "HARARIT                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 385,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3639,
            "שם_ישוב": "ורד יריחו                ",
            "שם_ישוב_לועזי": "WERED YERIHO             ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 74,
            "שם_מועצה": "מגילות ים המלח"
        },
        {
            "_id": 386,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1133,
            "שם_ישוב": "ורדון                    ",
            "שם_ישוב_לועזי": "WARDON                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 387,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2742,
            "שם_ישוב": "זבארגה )שבט(             ",
            "שם_ישוב_לועזי": "ZABARGA                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 388,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 815,
            "שם_ישוב": "זבדיאל                   ",
            "שם_ישוב_לועזי": "ZAVDI'EL                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 389,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר                     ",
            "שם_ישוב_לועזי": "ZOHAR                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 390,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 584,
            "שם_ישוב": "זיקים                    ",
            "שם_ישוב_לועזי": "ZIQIM                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 391,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 788,
            "שם_ישוב": "זיתן                     ",
            "שם_ישוב_לועזי": "ZETAN                    ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 40,
            "שם_מועצה": "שדות דן"
        },
        {
            "_id": 392,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב               ",
            "שם_ישוב_לועזי": "ZIKHRON YA'AQOV          ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 393,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 799,
            "שם_ישוב": "זכריה                    ",
            "שם_ישוב_לועזי": "ZEKHARYA                 ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 394,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1290,
            "שם_ישוב": "זמר                      ",
            "שם_ישוב_לועזי": "ZEMER                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 395,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1065,
            "שם_ישוב": "זמרת                     ",
            "שם_ישוב_לועזי": "ZIMRAT                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 396,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 816,
            "שם_ישוב": "זנוח                     ",
            "שם_ישוב_לועזי": "ZANOAH                   ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 397,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2064,
            "שם_ישוב": "זרועה                    ",
            "שם_ישוב_לועזי": "ZERU'A                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 398,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר                    ",
            "שם_ישוב_לועזי": "ZARZIR                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 399,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 818,
            "שם_ישוב": "זרחיה                    ",
            "שם_ישוב_לועזי": "ZERAHYA                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 400,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1321,
            "שם_ישוב": "ח'ואלד                   ",
            "שם_ישוב_לועזי": "KHAWALED                 ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 401,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 986,
            "שם_ישוב": "ח'ואלד )שבט(             ",
            "שם_ישוב_לועזי": "KHAWALED                 ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 402,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 235,
            "שם_ישוב": "חבצלת השרון              ",
            "שם_ישוב_לועזי": "HAVAZZELET HASHARON      ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 403,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר                      ",
            "שם_ישוב_לועזי": "HEVER                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 404,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון                    ",
            "שם_ישוב_לועזי": "                         ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 405,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור                     ",
            "שם_ישוב_לועזי": "HAGOR                    ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 406,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי                      ",
            "שם_ישוב_לועזי": "HAGGAI                   ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 407,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 205,
            "שם_ישוב": "חגלה                     ",
            "שם_ישוב_לועזי": "HOGLA                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 408,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4026,
            "שם_ישוב": "חד-נס                    ",
            "שם_ישוב_לועזי": "HAD-NES                  ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 409,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד                     ",
            "שם_ישוב_לועזי": "HADID                    ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 410,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה                     ",
            "שם_ישוב_לועזי": "HADERA                   ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 411,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 948,
            "שם_ישוב": "חוג'ייראת )ד'הרה(        ",
            "שם_ישוב_לועזי": "HUJEIRAT (DAHRA)         ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 412,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 160,
            "שם_ישוב": "חולדה                    ",
            "שם_ישוב_לועזי": "HULDA                    ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 413,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון                    ",
            "שם_ישוב_לועזי": "HOLON                    ",
            "סמל_נפה": 53,
            "שם_נפה": "חולון               ",
            "סמל_לשכת_מנא": 53,
            "לשכה": "חולון                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 414,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1239,
            "שם_ישוב": "חולית                    ",
            "שם_ישוב_לועזי": "HOLIT                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 415,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 253,
            "שם_ישוב": "חולתה                    ",
            "שם_ישוב_לועזי": "HULATA                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 416,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן                     ",
            "שם_ישוב_לועזי": "HOSEN                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 417,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1332,
            "שם_ישוב": "חוסנייה                  ",
            "שם_ישוב_לועזי": "HUSSNIYYA                ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 418,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית                    ",
            "שם_ישוב_לועזי": "HOFIT                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 419,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 374,
            "שם_ישוב": "חוקוק                    ",
            "שם_ישוב_לועזי": "HUQOQ                    ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 420,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה                     ",
            "שם_ישוב_לועזי": "HURA                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 421,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש                   ",
            "שם_ישוב_לועזי": "HURFEISH                 ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 422,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 355,
            "שם_ישוב": "חורשים                   ",
            "שם_ישוב_לועזי": "HORESHIM                 ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 423,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1047,
            "שם_ישוב": "חזון                     ",
            "שם_ישוב_לועזי": "HAZON                    ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 424,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 219,
            "שם_ישוב": "חיבת ציון                ",
            "שם_ישוב_לועזי": "HIBBAT ZIYYON            ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 425,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית                   ",
            "שם_ישוב_לועזי": "HINNANIT                 ",
            "סמל_נפה": 71,
            "שם_נפה": "ג'נין               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 426,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה                     ",
            "שם_ישוב_לועזי": "HAIFA                    ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 427,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות                    ",
            "שם_ישוב_לועזי": "HERUT                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 428,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ                     ",
            "שם_ישוב_לועזי": "HALUZ                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 429,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3573,
            "שם_ישוב": "חלמיש                    ",
            "שם_ישוב_לועזי": "HALLAMISH                ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 430,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 820,
            "שם_ישוב": "חלץ                      ",
            "שם_ישוב_לועזי": "HELEZ                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 431,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 993,
            "שם_ישוב": "חמאם                     ",
            "שם_ישוב_לועזי": "HAMAM                    ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 65,
            "שם_מועצה": "אל-בטוף"
        },
        {
            "_id": 432,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד                      ",
            "שם_ישוב_לועזי": "HEMED                    ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 40,
            "שם_מועצה": "שדות דן"
        },
        {
            "_id": 433,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 343,
            "שם_ישוב": "חמדיה                    ",
            "שם_ישוב_לועזי": "HAMADYA                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 434,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3646,
            "שם_ישוב": "חמדת                     ",
            "שם_ישוב_לועזי": "NAHAL HEMDAT             ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 435,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3609,
            "שם_ישוב": "חמרה                     ",
            "שם_ישוב_לועזי": "HAMRA                    ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 436,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 807,
            "שם_ישוב": "חניאל                    ",
            "שם_ישוב_לועזי": "HANNI'EL                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 437,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה                    ",
            "שם_ישוב_לועזי": "HANITA                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 438,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון                    ",
            "שם_ישוב_לועזי": "HANNATON                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 439,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4005,
            "שם_ישוב": "חספין                    ",
            "שם_ישוב_לועזי": "HASPIN                   ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 440,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 363,
            "שם_ישוב": "חפץ חיים                 ",
            "שם_ישוב_לועזי": "HAFEZ HAYYIM             ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 31,
            "שם_מועצה": "נחל שורק"
        },
        {
            "_id": 441,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 90,
            "שם_ישוב": "חפצי-בה                  ",
            "שם_ישוב_לועזי": "HEFZI-BAH                ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 442,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 700,
            "שם_ישוב": "חצב                      ",
            "שם_ישוב_לועזי": "HAZAV                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 443,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 13,
            "שם_ישוב": "חצבה                     ",
            "שם_ישוב_לועזי": "HAZEVA                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 54,
            "שם_מועצה": "הערבה התיכונה"
        },
        {
            "_id": 444,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית             ",
            "שם_ישוב_לועזי": "HAZOR HAGELILIT          ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 445,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 406,
            "שם_ישוב": "חצור-אשדוד               ",
            "שם_ישוב_לועזי": "HAZOR-ASHDOD             ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 446,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1409,
            "שם_ישוב": "חצר בארותיים             ",
            "שם_ישוב_לועזי": "HAZAR BE'EROTAYIM        ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 447,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1402,
            "שם_ישוב": "חצרות חולדה              ",
            "שם_ישוב_לועזי": "HAZROT HULDA             ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 448,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1404,
            "שם_ישוב": "חצרות יסף                ",
            "שם_ישוב_לועזי": "HAZROT YASAF             ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 449,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1405,
            "שם_ישוב": "חצרות כ\"ח                ",
            "שם_ישוב_לועזי": "HAZROT KOAH              ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 450,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 397,
            "שם_ישוב": "חצרים                    ",
            "שם_ישוב_לועזי": "HAZERIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 451,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת                  ",
            "שם_ישוב_לועזי": "HEREV LE'ET              ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 452,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1024,
            "שם_ישוב": "חרוצים                   ",
            "שם_ישוב_לועזי": "HARUZIM                  ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 453,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש                     ",
            "שם_ישוב_לועזי": "HARISH                   ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 454,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3717,
            "שם_ישוב": "חרמש                     ",
            "שם_ישוב_לועזי": "HERMESH                  ",
            "סמל_נפה": 71,
            "שם_נפה": "ג'נין               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 455,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1209,
            "שם_ישוב": "חרשים                    ",
            "שם_ישוב_לועזי": "HARASHIM                 ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 456,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים                 ",
            "שם_ישוב_לועזי": "HASHMONA'IM              ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 457,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה                    ",
            "שם_ישוב_לועזי": "TIBERIAS                 ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 458,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה              ",
            "שם_ישוב_לועזי": "TUBA-ZANGARIYYE          ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 459,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן                   ",
            "שם_ישוב_לועזי": "TUR'AN                   ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 460,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה                    ",
            "שם_ישוב_לועזי": "TAYIBE                   ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 461,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 497,
            "שם_ישוב": "טייבה )בעמק(             ",
            "שם_ישוב_לועזי": "TAYIBE(BAEMEQ)           ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 462,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה                     ",
            "שם_ישוב_לועזי": "TIRE                     ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 463,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 663,
            "שם_ישוב": "טירת יהודה               ",
            "שם_ישוב_לועזי": "TIRAT YEHUDA             ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 464,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל                ",
            "שם_ישוב_לועזי": "TIRAT KARMEL             ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 465,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 268,
            "שם_ישוב": "טירת צבי                 ",
            "שם_ישוב_לועזי": "TIRAT ZEVI               ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 466,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר                   ",
            "שם_ישוב_לועזי": "TAL SHAHAR               ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 467,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל                    ",
            "שם_ישוב_לועזי": "TAL-EL                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 468,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1177,
            "שם_ישוב": "טללים                    ",
            "שם_ישוב_לועזי": "TELALIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 469,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון                    ",
            "שם_ישוב_לועזי": "TALMON                   ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 470,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה                     ",
            "שם_ישוב_לועזי": "TAMRA                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 471,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 547,
            "שם_ישוב": "טמרה )יזרעאל(            ",
            "שם_ישוב_לועזי": "TAMRA (YIZRE'EL)         ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 472,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא                      ",
            "שם_ישוב_לועזי": "TENE                     ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 473,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1214,
            "שם_ישוב": "טפחות                    ",
            "שם_ישוב_לועזי": "TEFAHOT                  ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 474,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת                ",
            "שם_ישוב_לועזי": "YANUH-JAT                ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 475,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1232,
            "שם_ישוב": "יבול                     ",
            "שם_ישוב_לועזי": "YEVUL                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 476,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל                    ",
            "שם_ישוב_לועזי": "YAVNE'EL                 ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 477,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה                     ",
            "שם_ישוב_לועזי": "YAVNE                    ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 478,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור                     ",
            "שם_ישוב_לועזי": "YAGUR                    ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 479,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 798,
            "שם_ישוב": "יגל                      ",
            "שם_ישוב_לועזי": "YAGEL                    ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 40,
            "שם_מועצה": "שדות דן"
        },
        {
            "_id": 480,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין                ",
            "שם_ישוב_לועזי": "YAD BINYAMIN             ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 31,
            "שם_מועצה": "נחל שורק"
        },
        {
            "_id": 481,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה                ",
            "שם_ישוב_לועזי": "YAD HASHEMONA            ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 482,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה                   ",
            "שם_ישוב_לועזי": "YAD HANNA                ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 483,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 358,
            "שם_ישוב": "יד מרדכי                 ",
            "שם_ישוב_לועזי": "YAD MORDEKHAY            ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 484,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 775,
            "שם_ישוב": "יד נתן                   ",
            "שם_ישוב_לועזי": "YAD NATAN                ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 485,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 64,
            "שם_ישוב": "יד רמב\"ם                 ",
            "שם_ישוב_לועזי": "YAD RAMBAM               ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 486,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1144,
            "שם_ישוב": "ידידה                    ",
            "שם_ישוב_לועזי": "YEDIDA                   ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 487,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון             ",
            "שם_ישוב_לועזי": "YEHUD-MONOSON            ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 488,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1158,
            "שם_ישוב": "יהל                      ",
            "שם_ישוב_לועזי": "YAHEL                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 489,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל                     ",
            "שם_ישוב_לועזי": "YUVAL                    ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 490,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים                   ",
            "שם_ישוב_לועזי": "YUVALIM                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 491,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת                    ",
            "שם_ישוב_לועזי": "YODEFAT                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 492,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן                    ",
            "שם_ישוב_לועזי": "YONATAN                  ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 493,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 803,
            "שם_ישוב": "יושיביה                  ",
            "שם_ישוב_לועזי": "YOSHIVYA                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 494,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 452,
            "שם_ישוב": "יזרעאל                   ",
            "שם_ישוב_לועזי": "YIZRE'EL                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 495,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 409,
            "שם_ישוב": "יחיעם                    ",
            "שם_ישוב_לועזי": "YEHI'AM                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 496,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 866,
            "שם_ישוב": "יטבתה                    ",
            "שם_ישוב_לועזי": "YOTVATA                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 497,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3607,
            "שם_ישוב": "ייט\"ב                    ",
            "שם_ישוב_לועזי": "YITAV                    ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 498,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 811,
            "שם_ישוב": "יכיני                    ",
            "שם_ישוב_לועזי": "YAKHINI                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 499,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב                     ",
            "שם_ישוב_לועזי": "YANUV                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 500,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2011,
            "שם_ישוב": "ינון                     ",
            "שם_ישוב_לועזי": "YINNON                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 501,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה               ",
            "שם_ישוב_לועזי": "YESUD HAMA'ALA           ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 502,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 440,
            "שם_ישוב": "יסודות                   ",
            "שם_ישוב_לועזי": "YESODOT                  ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 31,
            "שם_מועצה": "נחל שורק"
        },
        {
            "_id": 503,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 575,
            "שם_ישוב": "יסעור                    ",
            "שם_ישוב_לועזי": "YAS'UR                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 504,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד                      ",
            "שם_ישוב_לועזי": "YA'AD                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 505,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1117,
            "שם_ישוב": "יעל                      ",
            "שם_ישוב_לועזי": "YA'EL                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 506,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1044,
            "שם_ישוב": "יעף                      ",
            "שם_ישוב_לועזי": "YE'AF                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 507,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 795,
            "שם_ישוב": "יערה                     ",
            "שם_ישוב_לועזי": "YA'ARA                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 508,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע                     ",
            "שם_ישוב_לועזי": "YAFI                     ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 509,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3566,
            "שם_ישוב": "יפית                     ",
            "שם_ישוב_לועזי": "YAFIT                    ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 510,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת                     ",
            "שם_ישוב_לועזי": "YIF'AT                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 511,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 453,
            "שם_ישוב": "יפתח                     ",
            "שם_ישוב_לועזי": "YIFTAH                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 512,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3749,
            "שם_ישוב": "יצהר                     ",
            "שם_ישוב_לועזי": "YIZHAR                   ",
            "סמל_נפה": 72,
            "שם_נפה": "שכם                 ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 513,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 759,
            "שם_ישוב": "יציץ                     ",
            "שם_ישוב_לועזי": "YAZIZ                    ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 514,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 417,
            "שם_ישוב": "יקום                     ",
            "שם_ישוב_לועזי": "YAQUM                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 515,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר                     ",
            "שם_ישוב_לועזי": "YAQIR                    ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 516,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם )מושבה(            ",
            "שם_ישוב_לועזי": "YOQNE'AM(MOSHAVA)        ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 517,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית              ",
            "שם_ישוב_לועזי": "YOQNE'AM ILLIT           ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 518,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 623,
            "שם_ישוב": "יראון                    ",
            "שם_ישוב_לועזי": "YIR'ON                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 519,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2026,
            "שם_ישוב": "ירדנה                    ",
            "שם_ישוב_לועזי": "YARDENA                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 520,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם                    ",
            "שם_ישוב_לועזי": "YEROHAM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 521,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים                  ",
            "שם_ישוב_לועזי": "JERUSALEM                ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 522,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב                    ",
            "שם_ישוב_לועזי": "YARHIV                   ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 523,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא                     ",
            "שם_ישוב_לועזי": "YIRKA                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 524,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה                   ",
            "שם_ישוב_לועזי": "YARQONA                  ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 525,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 916,
            "שם_ישוב": "ישע                      ",
            "שם_ישוב_לועזי": "YESHA                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 526,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 805,
            "שם_ישוב": "ישעי                     ",
            "שם_ישוב_לועזי": "YISH'I                   ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 527,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש                     ",
            "שם_ישוב_לועזי": "YASHRESH                 ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 528,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1227,
            "שם_ישוב": "יתד                      ",
            "שם_ישוב_לועזי": "YATED                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 529,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1329,
            "שם_ישוב": "יתיר                     ",
            "שם_ישוב_לועזי": "                         ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 530,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול                    ",
            "שם_ישוב_לועזי": "KABUL                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 531,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 505,
            "שם_ישוב": "כאוכב אבו אל-היג'א       ",
            "שם_ישוב_לועזי": "KAOKAB ABU AL-HIJA       ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 532,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 576,
            "שם_ישוב": "כברי                     ",
            "שם_ישוב_לועזי": "KABRI                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 533,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 371,
            "שם_ישוב": "כדורי                    ",
            "שם_ישוב_לועזי": "KADOORIE                 ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 534,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1338,
            "שם_ישוב": "כדיתה                    ",
            "שם_ישוב_לועזי": "KADDITA                  ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 535,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר                ",
            "שם_ישוב_לועזי": "KOKHAV HASHAHAR          ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 536,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר                ",
            "שם_ישוב_לועזי": "KOKHAV YA'IR             ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 537,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב                ",
            "שם_ישוב_לועזי": "KOKHAV YA'AQOV           ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 538,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל               ",
            "שם_ישוב_לועזי": "KOKHAV MIKHA'EL          ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 539,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים                   ",
            "שם_ישוב_לועזי": "KORAZIM                  ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 540,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1210,
            "שם_ישוב": "כחל                      ",
            "שם_ישוב_לועזי": "KAHAL                    ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 541,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1367,
            "שם_ישוב": "כחלה                     ",
            "שם_ישוב_לועזי": "KOCHLEA                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 69,
            "שם_מועצה": "אל קסום"
        },
        {
            "_id": 542,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 840,
            "שם_ישוב": "כיסופים                  ",
            "שם_ישוב_לועזי": "KISSUFIM                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 543,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1153,
            "שם_ישוב": "כישור                    ",
            "שם_ישוב_לועזי": "KISHOR                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 544,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1183,
            "שם_ישוב": "כליל                     ",
            "שם_ישוב_לועזי": "KELIL                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 545,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1229,
            "שם_ישוב": "כלנית                    ",
            "שם_ישוב_לועזי": "KALLANIT                 ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 546,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1331,
            "שם_ישוב": "כמאנה                    ",
            "שם_ישוב_לועזי": "                         ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 547,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1291,
            "שם_ישוב": "כמהין                    ",
            "שם_ישוב_לועזי": "KEMEHIN                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 548,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון                     ",
            "שם_ישוב_לועזי": "KAMMON                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 549,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2006,
            "שם_ישוב": "כנות                     ",
            "שם_ישוב_לועזי": "KANNOT                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 550,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4028,
            "שם_ישוב": "כנף                      ",
            "שם_ישוב_לועזי": "KANAF                    ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 551,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת )מושבה(             ",
            "שם_ישוב_לועזי": "KINNERET(MOSHAVA)        ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 552,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 57,
            "שם_ישוב": "כנרת )קבוצה(             ",
            "שם_ישוב_לועזי": "KINNERET(QEVUZA)         ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 553,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה                    ",
            "שם_ישוב_לועזי": "KUSEIFE                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 554,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 859,
            "שם_ישוב": "כסלון                    ",
            "שם_ישוב_לועזי": "KESALON                  ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 555,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע                ",
            "שם_ישוב_לועזי": "KISRA-SUMEI              ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 556,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה      ",
            "שם_ישוב_לועזי": "KA'ABIYYE-TABBASH-HA     ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 557,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 857,
            "שם_ישוב": "כפר אביב                 ",
            "שם_ישוב_לועזי": "KEFAR AVIV               ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 32,
            "שם_מועצה": "גדרות"
        },
        {
            "_id": 558,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים               ",
            "שם_ישוב_לועזי": "KEFAR ADUMMIM            ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 559,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה                ",
            "שם_ישוב_לועזי": "KEFAR URIYYA             ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 560,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 690,
            "שם_ישוב": "כפר אחים                 ",
            "שם_ישוב_לועזי": "KEFAR AHIM               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 561,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק               ",
            "שם_ישוב_לועזי": "KEFAR BIALIK             ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 562,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר ביל\"ו                ",
            "שם_ישוב_לועזי": "KEFAR BILU               ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 563,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 357,
            "שם_ישוב": "כפר בלום                 ",
            "שם_ישוב_לועזי": "KEFAR BLUM               ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 564,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2010,
            "שם_ישוב": "כפר בן נון               ",
            "שם_ישוב_לועזי": "KEFAR BIN NUN            ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 565,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא                  ",
            "שם_ישוב_לועזי": "KAFAR BARA               ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 566,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך                 ",
            "שם_ישוב_לועזי": "KEFAR BARUKH             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 567,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 106,
            "שם_ישוב": "כפר גדעון                ",
            "שם_ישוב_לועזי": "KEFAR GID'ON             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 568,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 427,
            "שם_ישוב": "כפר גלים                 ",
            "שם_ישוב_לועזי": "KEFAR GALLIM             ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 569,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 310,
            "שם_ישוב": "כפר גליקסון              ",
            "שם_ישוב_לועזי": "KEFAR GLIKSON            ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 570,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 76,
            "שם_ישוב": "כפר גלעדי                ",
            "שם_ישוב_לועזי": "KEFAR GIL'ADI            ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 571,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל                ",
            "שם_ישוב_לועזי": "KEFAR DANIYYEL           ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 572,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים              ",
            "שם_ישוב_לועזי": "KEFAR HAORANIM           ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 573,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש                ",
            "שם_ישוב_לועזי": "KEFAR HAHORESH           ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 574,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 254,
            "שם_ישוב": "כפר המכבי                ",
            "שם_ישוב_לועזי": "KEFAR HAMAKKABI          ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 575,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד                ",
            "שם_ישוב_לועזי": "KEFAR HANAGID            ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 27,
            "שם_מועצה": "גן רווה"
        },
        {
            "_id": 576,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 890,
            "שם_ישוב": "כפר הנוער הדתי           ",
            "שם_ישוב_לועזי": "KEFAR HANO'AR HADATI     ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 577,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 443,
            "שם_ישוב": "כפר הנשיא                ",
            "שם_ישוב_לועזי": "KEFAR HANASI             ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 578,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס                   ",
            "שם_ישוב_לועזי": "KEFAR HESS               ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 579,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הרא\"ה                ",
            "שם_ישוב_לועזי": "KEFAR HARO'E             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 580,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הרי\"ף                ",
            "שם_ישוב_לועזי": "KEFAR HARIF              ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 581,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין               ",
            "שם_ישוב_לועזי": "KEFAR VITKIN             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 582,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג               ",
            "שם_ישוב_לועזי": "KEFAR WARBURG            ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 583,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים                ",
            "שם_ישוב_לועזי": "KEFAR WERADIM            ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 584,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1325,
            "שם_ישוב": "כפר זוהרים               ",
            "שם_ישוב_לועזי": "KEFAR ZOHARIM            ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 585,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים                ",
            "שם_ישוב_לועזי": "KEFAR ZETIM              ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 586,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חב\"ד                 ",
            "שם_ישוב_לועזי": "KEFAR HABAD              ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 40,
            "שם_מועצה": "שדות דן"
        },
        {
            "_id": 587,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 609,
            "שם_ישוב": "כפר חושן                 ",
            "שם_ישוב_לועזי": "KEFAR HOSHEN             ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 588,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים                ",
            "שם_ישוב_לועזי": "KEFAR HITTIM             ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 589,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 193,
            "שם_ישוב": "כפר חיים                 ",
            "שם_ישוב_לועזי": "KEFAR HAYYIM             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 590,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1297,
            "שם_ישוב": "כפר חנניה                ",
            "שם_ישוב_לועזי": "KEFAR HANANYA            ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 591,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א'            ",
            "שם_ישוב_לועזי": "KEFAR HASIDIM ALEF       ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 592,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 889,
            "שם_ישוב": "כפר חסידים ב'            ",
            "שם_ישוב_לועזי": "KEFAR HASIDIM BET        ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 593,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4004,
            "שם_ישוב": "כפר חרוב                 ",
            "שם_ישוב_לועזי": "KEFAR HARUV              ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 594,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן                ",
            "שם_ישוב_לועזי": "KEFAR TRUMAN             ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 595,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף                ",
            "שם_ישוב_לועזי": "KAFAR YASIF              ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 596,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה               ",
            "שם_ישוב_לועזי": "YEDIDYA                  ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 597,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע                ",
            "שם_ישוב_לועזי": "KEFAR YEHOSHUA           ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 598,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה                 ",
            "שם_ישוב_לועזי": "KEFAR YONA               ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 599,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 85,
            "שם_ישוב": "כפר יחזקאל               ",
            "שם_ישוב_לועזי": "KEFAR YEHEZQEL           ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 600,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ                 ",
            "שם_ישוב_לועזי": "KEFAR YA'BEZ             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 601,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא                  ",
            "שם_ישוב_לועזי": "KAFAR KAMA               ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 602,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא                  ",
            "שם_ישוב_לועזי": "KAFAR KANNA              ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 603,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 387,
            "שם_ישוב": "כפר מונש                 ",
            "שם_ישוב_לועזי": "KEFAR MONASH             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 604,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1095,
            "שם_ישוב": "כפר מימון                ",
            "שם_ישוב_לועזי": "KEFAR MAYMON             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 605,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מל\"ל                 ",
            "שם_ישוב_לועזי": "KEFAR MALAL              ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 606,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא                 ",
            "שם_ישוב_לועזי": "KAFAR MANDA              ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 607,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 274,
            "שם_ישוב": "כפר מנחם                 ",
            "שם_ישוב_לועזי": "KEFAR MENAHEM            ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 608,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 297,
            "שם_ישוב": "כפר מסריק                ",
            "שם_ישוב_לועזי": "KEFAR MASARYK            ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 609,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 512,
            "שם_ישוב": "כפר מצר                  ",
            "שם_ישוב_לועזי": "KAFAR MISR               ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 66,
            "שם_מועצה": "בוסתאן אל-מרג'"
        },
        {
            "_id": 610,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי                ",
            "שם_ישוב_לועזי": "KEFAR MORDEKHAY          ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 32,
            "שם_מועצה": "גדרות"
        },
        {
            "_id": 611,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 316,
            "שם_ישוב": "כפר נטר                  ",
            "שם_ישוב_לועזי": "KEFAR NETTER             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 612,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 345,
            "שם_ישוב": "כפר סאלד                 ",
            "שם_ישוב_לועזי": "KEFAR SZOLD              ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 613,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא                  ",
            "שם_ישוב_לועזי": "KEFAR SAVA               ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 614,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 107,
            "שם_ישוב": "כפר סילבר                ",
            "שם_ישוב_לועזי": "KEFAR SILVER             ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 615,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין               ",
            "שם_ישוב_לועזי": "KEFAR SIRKIN             ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 616,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 875,
            "שם_ישוב": "כפר עבודה                ",
            "שם_ישוב_לועזי": "KEFAR AVODA              ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 617,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 845,
            "שם_ישוב": "כפר עזה                  ",
            "שם_ישוב_לועזי": "KEFAR AZZA               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 618,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3488,
            "שם_ישוב": "כפר עציון                ",
            "שם_ישוב_לועזי": "KEFAR EZYON              ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 619,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס                 ",
            "שם_ישוב_לועזי": "KEFAR PINES              ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 620,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם                 ",
            "שם_ישוב_לועזי": "KAFAR QASEM              ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 621,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש                  ",
            "שם_ישוב_לועזי": "KEFAR KISH               ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 622,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע                  ",
            "שם_ישוב_לועזי": "KAFAR QARA               ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 623,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה            ",
            "שם_ישוב_לועזי": "KEFAR ROSH HANIQRA       ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 624,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1130,
            "שם_ישוב": "כפר רוזנואלד )זרעית(     ",
            "שם_ישוב_לועזי": "KEFAR ROZENWALD(ZAR.     ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 625,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 295,
            "שם_ישוב": "כפר רופין                ",
            "שם_ישוב_לועזי": "KEFAR RUPPIN             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 626,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1166,
            "שם_ישוב": "כפר רות                  ",
            "שם_ישוב_לועזי": "KEFAR RUT                ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 627,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 605,
            "שם_ישוב": "כפר שמאי                 ",
            "שם_ישוב_לועזי": "KEFAR SHAMMAY            ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 628,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 743,
            "שם_ישוב": "כפר שמואל                ",
            "שם_ישוב_לועזי": "KEFAR SHEMU'EL           ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 629,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו               ",
            "שם_ישוב_לועזי": "KEFAR SHEMARYAHU         ",
            "סמל_נפה": 51,
            "שם_נפה": "תל אביב             ",
            "סמל_לשכת_מנא": 57,
            "לשכה": "הרצליה                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 630,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור                 ",
            "שם_ישוב_לועזי": "KEFAR TAVOR              ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 631,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח                 ",
            "שם_ישוב_לועזי": "KEFAR TAPPUAH            ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 632,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 38,
            "שם_ישוב": "כרי דשא                  ",
            "שם_ישוב_לועזי": "KARE DESHE               ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 633,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום                    ",
            "שם_ישוב_לועזי": "KARKOM                   ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 634,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 664,
            "שם_ישוב": "כרם בן זמרה              ",
            "שם_ישוב_לועזי": "KEREM BEN ZIMRA          ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 635,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 88,
            "שם_ישוב": "כרם בן שמן               ",
            "שם_ישוב_לועזי": "KEREM BEN SHEMEN         ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 636,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1094,
            "שם_ישוב": "כרם יבנה )ישיבה(         ",
            "שם_ישוב_לועזי": "KEREM YAVNE(YESHIVA)     ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 29,
            "שם_מועצה": "חבל יבנה"
        },
        {
            "_id": 637,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 580,
            "שם_ישוב": "כרם מהר\"ל                ",
            "שם_ישוב_לועזי": "KEREM MAHARAL            ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 638,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1085,
            "שם_ישוב": "כרם שלום                 ",
            "שם_ישוב_לועזי": "KEREM SHALOM             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 639,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף                ",
            "שם_ישוב_לועזי": "KARME YOSEF              ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 640,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור                 ",
            "שם_ישוב_לועזי": "KARME ZUR                ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 641,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף                ",
            "שם_ישוב_לועזי": "KARME QATIF              ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 642,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל                   ",
            "שם_ישוב_לועזי": "KARMI'EL                 ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 643,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 768,
            "שם_ישוב": "כרמיה                    ",
            "שם_ישוב_לועזי": "KARMIYYA                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 644,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1198,
            "שם_ישוב": "כרמים                    ",
            "שם_ישוב_לועזי": "KERAMIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 645,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3656,
            "שם_ישוב": "כרמל                     ",
            "שם_ישוב_לועזי": "KARMEL                   ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 646,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 0,
            "שם_ישוב": "לא רשום                  ",
            "שם_ישוב_לועזי": "                         ",
            "סמל_נפה": 0,
            "שם_נפה": "לא ידוע             ",
            "סמל_לשכת_מנא": 0,
            "לשכה": "                         ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 647,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון                     ",
            "שם_ישוב_לועזי": "LAVON                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 648,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 585,
            "שם_ישוב": "לביא                     ",
            "שם_ישוב_לועזי": "LAVI                     ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 649,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1230,
            "שם_ישוב": "לבנים                    ",
            "שם_ישוב_לועזי": "LIVNIM                   ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 650,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2023,
            "שם_ישוב": "להב                      ",
            "שם_ישוב_לועזי": "LAHAV                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 651,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 380,
            "שם_ישוב": "להבות הבשן               ",
            "שם_ישוב_לועזי": "LAHAVOT HABASHAN         ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 652,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 715,
            "שם_ישוב": "להבות חביבה              ",
            "שם_ישוב_לועזי": "LAHAVOT HAVIVA           ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 653,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1271,
            "שם_ישוב": "להבים                    ",
            "שם_ישוב_לועזי": "LEHAVIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 654,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7000,
            "שם_ישוב": "לוד                      ",
            "שם_ישוב_לועזי": "LOD                      ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 655,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 52,
            "שם_ישוב": "לוזית                    ",
            "שם_ישוב_לועזי": "LUZIT                    ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 656,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 595,
            "שם_ישוב": "לוחמי הגיטאות            ",
            "שם_ישוב_לועזי": "LOHAME HAGETA'OT         ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 657,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1171,
            "שם_ישוב": "לוטם                     ",
            "שם_ישוב_לועזי": "LOTEM                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 658,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1255,
            "שם_ישוב": "לוטן                     ",
            "שם_ישוב_לועזי": "LOTAN                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 659,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 674,
            "שם_ישוב": "לימן                     ",
            "שם_ישוב_לועזי": "LIMAN                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 660,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 24,
            "שם_ישוב": "לכיש                     ",
            "שם_ישוב_לועזי": "LAKHISH                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 661,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1310,
            "שם_ישוב": "לפיד                     ",
            "שם_ישוב_לועזי": "LAPPID                   ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 662,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1173,
            "שם_ישוב": "לפידות                   ",
            "שם_ישוב_לועזי": "LAPPIDOT                 ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 663,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1060,
            "שם_ישוב": "לקיה                     ",
            "שם_ישוב_לועזי": "LAQYE                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 664,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2055,
            "שם_ישוב": "מאור                     ",
            "שם_ישוב_לועזי": "MA'OR                    ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 665,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 102,
            "שם_ישוב": "מאיר שפיה                ",
            "שם_ישוב_לועזי": "ME'IR SHEFEYA            ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 666,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 771,
            "שם_ישוב": "מבוא ביתר                ",
            "שם_ישוב_לועזי": "MEVO BETAR               ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 667,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3569,
            "שם_ישוב": "מבוא דותן                ",
            "שם_ישוב_לועזי": "MEVO DOTAN               ",
            "סמל_נפה": 71,
            "שם_נפה": "ג'נין               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 668,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3709,
            "שם_ישוב": "מבוא חורון               ",
            "שם_ישוב_לועזי": "MEVO HORON               ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 669,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4204,
            "שם_ישוב": "מבוא חמה                 ",
            "שם_ישוב_לועזי": "MEVO HAMMA               ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 670,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1141,
            "שם_ישוב": "מבוא מודיעים             ",
            "שם_ישוב_לועזי": "MEVO MODI'IM             ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 671,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1318,
            "שם_ישוב": "מבואות ים                ",
            "שם_ישוב_לועזי": "MEVO'OT YAM              ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 672,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1080,
            "שם_ישוב": "מבועים                   ",
            "שם_ישוב_לועזי": "MABBU'IM                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 673,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 829,
            "שם_ישוב": "מבטחים                   ",
            "שם_ישוב_לועזי": "MIVTAHIM                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 674,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 573,
            "שם_ישוב": "מבקיעים                  ",
            "שם_ישוב_לועזי": "MAVQI'IM                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 675,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1015,
            "שם_ישוב": "מבשרת ציון               ",
            "שם_ישוב_לועזי": "MEVASSERET ZIYYON        ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 676,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 516,
            "שם_ישוב": "מג'ד אל-כרום             ",
            "שם_ישוב_לועזי": "MAJD AL-KURUM            ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 677,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4201,
            "שם_ישוב": "מג'דל שמס                ",
            "שם_ישוב_לועזי": "MAJDAL SHAMS             ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 678,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 481,
            "שם_ישוב": "מגאר                     ",
            "שם_ישוב_לועזי": "MUGHAR                   ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 679,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 689,
            "שם_ישוב": "מגדים                    ",
            "שם_ישוב_לועזי": "MEGADIM                  ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 680,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 65,
            "שם_ישוב": "מגדל                     ",
            "שם_ישוב_לועזי": "MIGDAL                   ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 681,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 874,
            "שם_ישוב": "מגדל העמק                ",
            "שם_ישוב_לועזי": "MIGDAL HAEMEQ            ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 682,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3561,
            "שם_ישוב": "מגדל עוז                 ",
            "שם_ישוב_לועזי": "MIGDAL OZ                ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 683,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3751,
            "שם_ישוב": "מגדלים                   ",
            "שם_ישוב_לועזי": "MIGDALIM                 ",
            "סמל_נפה": 72,
            "שם_נפה": "שכם                 ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 684,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 586,
            "שם_ישוב": "מגידו                    ",
            "שם_ישוב_לועזי": "MEGIDDO                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 685,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 375,
            "שם_ישוב": "מגל                      ",
            "שם_ישוב_לועזי": "MAGGAL                   ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 686,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 695,
            "שם_ישוב": "מגן                      ",
            "שם_ישוב_לועזי": "MAGEN                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 687,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1155,
            "שם_ישוב": "מגן שאול                 ",
            "שם_ישוב_לועזי": "MAGEN SHA'UL             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 688,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 722,
            "שם_ישוב": "מגשימים                  ",
            "שם_ישוב_לועזי": "MAGSHIMIM                ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 689,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2029,
            "שם_ישוב": "מדרך עוז                 ",
            "שם_ישוב_לועזי": "MIDRAKH OZ               ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 690,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1140,
            "שם_ישוב": "מדרשת בן גוריון          ",
            "שם_ישוב_לועזי": "MIDRESHET BEN GURION     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 691,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 897,
            "שם_ישוב": "מדרשת רופין              ",
            "שם_ישוב_לועזי": "MIDRESHET RUPPIN         ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 692,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3797,
            "שם_ישוב": "מודיעין עילית            ",
            "שם_ישוב_לועזי": "MODI'IN ILLIT            ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 693,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1200,
            "שם_ישוב": "מודיעין-מכבים-רעות       ",
            "שם_ישוב_לועזי": "MODI'IN-MAKKABBIM-RE     ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 694,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 269,
            "שם_ישוב": "מולדת                    ",
            "שם_ישוב_לועזי": "MOLEDET                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 695,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 208,
            "שם_ישוב": "מוצא עילית               ",
            "שם_ישוב_לועזי": "MOZA ILLIT               ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 696,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 635,
            "שם_ישוב": "מוקייבלה                 ",
            "שם_ישוב_לועזי": "MUQEIBLE                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 697,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1163,
            "שם_ישוב": "מורן                     ",
            "שם_ישוב_לועזי": "MORAN                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 698,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1178,
            "שם_ישוב": "מורשת                    ",
            "שם_ישוב_לועזי": "MORESHET                 ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 699,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 606,
            "שם_ישוב": "מזור                     ",
            "שם_ישוב_לועזי": "MAZOR                    ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 700,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 28,
            "שם_ישוב": "מזכרת בתיה               ",
            "שם_ישוב_לועזי": "MAZKERET BATYA           ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 701,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 104,
            "שם_ישוב": "מזרע                     ",
            "שם_ישוב_לועזי": "MIZRA                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 702,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 517,
            "שם_ישוב": "מזרעה                    ",
            "שם_ישוב_לועזי": "MAZRA'A                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 703,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3599,
            "שם_ישוב": "מחולה                    ",
            "שם_ישוב_לועזי": "MEHOLA                   ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 704,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1411,
            "שם_ישוב": "מחנה הילה                ",
            "שם_ישוב_לועזי": "MAHANE HILLA             ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 46,
            "לשכה": "ראשון לציון              ",
            "סמל_מועצה_איזורית": 27,
            "שם_מועצה": "גן רווה"
        },
        {
            "_id": 705,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1418,
            "שם_ישוב": "מחנה טלי                 ",
            "שם_ישוב_לועזי": "MAHANE TALI              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 706,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1413,
            "שם_ישוב": "מחנה יהודית              ",
            "שם_ישוב_לועזי": "MAHANE YEHUDIT           ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 707,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1416,
            "שם_ישוב": "מחנה יוכבד               ",
            "שם_ישוב_לועזי": "MAHANE YOKHVED           ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 708,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1415,
            "שם_ישוב": "מחנה יפה                 ",
            "שם_ישוב_לועזי": "MAHANE YAFA              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 709,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1196,
            "שם_ישוב": "מחנה יתיר                ",
            "שם_ישוב_לועזי": "MAHANE YATTIR            ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 710,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1414,
            "שם_ישוב": "מחנה מרים                ",
            "שם_ישוב_לועזי": "MAHANE MIRYAM            ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 711,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1412,
            "שם_ישוב": "מחנה תל נוף              ",
            "שם_ישוב_לועזי": "MAHANE TEL NOF           ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 28,
            "שם_מועצה": "ברנר"
        },
        {
            "_id": 712,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 308,
            "שם_ישוב": "מחניים                   ",
            "שם_ישוב_לועזי": "MAHANAYIM                ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 713,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 776,
            "שם_ישוב": "מחסיה                    ",
            "שם_ישוב_לועזי": "MAHSEYA                  ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 714,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 43,
            "שם_ישוב": "מטולה                    ",
            "שם_ישוב_לועזי": "METULA                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 715,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 822,
            "שם_ישוב": "מטע                      ",
            "שם_ישוב_לועזי": "MATTA                    ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 716,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1128,
            "שם_ישוב": "מי עמי                   ",
            "שם_ישוב_לועזי": "ME AMMI                  ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 717,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2054,
            "שם_ישוב": "מיטב                     ",
            "שם_ישוב_לועזי": "METAV                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 718,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 649,
            "שם_ישוב": "מייסר                    ",
            "שם_ישוב_לועזי": "MEISER                   ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 719,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4019,
            "שם_ישוב": "מיצר                     ",
            "שם_ישוב_לועזי": "MEZAR                    ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 720,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1282,
            "שם_ישוב": "מירב                     ",
            "שם_ישוב_לועזי": "MERAV                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 721,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 607,
            "שם_ישוב": "מירון                    ",
            "שם_ישוב_לועזי": "MERON                    ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 722,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 731,
            "שם_ישוב": "מישר                     ",
            "שם_ישוב_לועזי": "MESHAR                   ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 32,
            "שם_מועצה": "גדרות"
        },
        {
            "_id": 723,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1268,
            "שם_ישוב": "מיתר                     ",
            "שם_ישוב_לועזי": "METAR                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 724,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3614,
            "שם_ישוב": "מכורה                    ",
            "שם_ישוב_לועזי": "MEKHORA                  ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 725,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1343,
            "שם_ישוב": "מכחול                    ",
            "שם_ישוב_לועזי": "MAKCHUL                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 69,
            "שם_מועצה": "אל קסום"
        },
        {
            "_id": 726,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 382,
            "שם_ישוב": "מכמורת                   ",
            "שם_ישוב_לועזי": "MIKHMORET                ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 727,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1202,
            "שם_ישוב": "מכמנים                   ",
            "שם_ישוב_לועזי": "MIKHMANNIM               ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 728,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 164,
            "שם_ישוב": "מלאה                     ",
            "שם_ישוב_לועזי": "MELE'A                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 729,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2044,
            "שם_ישוב": "מלילות                   ",
            "שם_ישוב_לועזי": "MELILOT                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 730,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 596,
            "שם_ישוב": "מלכיה                    ",
            "שם_ישוב_לועזי": "MALKIYYA                 ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 731,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1154,
            "שם_ישוב": "מלכישוע                  ",
            "שם_ישוב_לועזי": "MALKISHUA                ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 732,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2030,
            "שם_ישוב": "מנוחה                    ",
            "שם_ישוב_לועזי": "MENUHA                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 733,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1174,
            "שם_ישוב": "מנוף                     ",
            "שם_ישוב_לועזי": "MANOF                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 734,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1205,
            "שם_ישוב": "מנות                     ",
            "שם_ישוב_לועזי": "MANOT                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 735,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 48,
            "שם_ישוב": "מנחמיה                   ",
            "שם_ישוב_לועזי": "MENAHEMYA                ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 736,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 347,
            "שם_ישוב": "מנרה                     ",
            "שם_ישוב_לועזי": "MENNARA                  ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 737,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 994,
            "שם_ישוב": "מנשית זבדה               ",
            "שם_ישוב_לועזי": "MANSHIYYET ZABDA         ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 738,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1258,
            "שם_ישוב": "מסד                      ",
            "שם_ישוב_לועזי": "MASSAD                   ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 739,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 263,
            "שם_ישוב": "מסדה                     ",
            "שם_ישוב_לועזי": "MASSADA                  ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 740,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 298,
            "שם_ישוב": "מסילות                   ",
            "שם_ישוב_לועזי": "MESILLOT                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 741,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 742,
            "שם_ישוב": "מסילת ציון               ",
            "שם_ישוב_לועזי": "MESILLAT ZIYYON          ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 742,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 748,
            "שם_ישוב": "מסלול                    ",
            "שם_ישוב_לועזי": "MASLUL                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 743,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4203,
            "שם_ישוב": "מסעדה                    ",
            "שם_ישוב_לועזי": "MAS'ADE                  ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 744,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 939,
            "שם_ישוב": "מסעודין אל-עזאזמה        ",
            "שם_ישוב_לועזי": "MAS'UDIN AL-'AZAZME      ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 745,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 197,
            "שם_ישוב": "מעברות                   ",
            "שם_ישוב_לועזי": "MA'BAROT                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 746,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1082,
            "שם_ישוב": "מעגלים                   ",
            "שם_ישוב_לועזי": "MA'GALIM                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 747,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 678,
            "שם_ישוב": "מעגן                     ",
            "שם_ישוב_לועזי": "MA'AGAN                  ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 748,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 694,
            "שם_ישוב": "מעגן מיכאל               ",
            "שם_ישוב_לועזי": "MA'AGAN MIKHA'EL         ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 749,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 272,
            "שם_ישוב": "מעוז חיים                ",
            "שם_ישוב_לועזי": "MA'OZ HAYYIM             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 750,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3657,
            "שם_ישוב": "מעון                     ",
            "שם_ישוב_לועזי": "MA'ON                    ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 751,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 570,
            "שם_ישוב": "מעונה                    ",
            "שם_ישוב_לועזי": "ME'ONA                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 752,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 518,
            "שם_ישוב": "מעיליא                   ",
            "שם_ישוב_לועזי": "MI'ELYA                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 753,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 416,
            "שם_ישוב": "מעין ברוך                ",
            "שם_ישוב_לועזי": "MA'YAN BARUKH            ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 754,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 290,
            "שם_ישוב": "מעין צבי                 ",
            "שם_ישוב_לועזי": "MA'YAN ZEVI              ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 755,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3616,
            "שם_ישוב": "מעלה אדומים              ",
            "שם_ישוב_לועזי": "MA'ALE ADUMMIM           ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 756,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3608,
            "שם_ישוב": "מעלה אפרים               ",
            "שם_ישוב_לועזי": "MA'ALE EFRAYIM           ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 757,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1127,
            "שם_ישוב": "מעלה גלבוע               ",
            "שם_ישוב_לועזי": "MA'ALE GILBOA            ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 758,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4008,
            "שם_ישוב": "מעלה גמלא                ",
            "שם_ישוב_לועזי": "MA'ALE GAMLA             ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 759,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 286,
            "שם_ישוב": "מעלה החמישה              ",
            "שם_ישוב_לועזי": "MA'ALE HAHAMISHA         ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 760,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3752,
            "שם_ישוב": "מעלה לבונה               ",
            "שם_ישוב_לועזי": "MA'ALE LEVONA            ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 761,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3651,
            "שם_ישוב": "מעלה מכמש                ",
            "שם_ישוב_לועזי": "MA'ALE MIKHMAS           ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 762,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1327,
            "שם_ישוב": "מעלה עירון               ",
            "שם_ישוב_לועזי": "MA'ALE IRON              ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 763,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3653,
            "שם_ישוב": "מעלה עמוס                ",
            "שם_ישוב_לועזי": "MA'ALE AMOS              ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 764,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3637,
            "שם_ישוב": "מעלה שומרון              ",
            "שם_ישוב_לועזי": "MA'ALE SHOMERON          ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 765,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1063,
            "שם_ישוב": "מעלות-תרשיחא             ",
            "שם_ישוב_לועזי": "MA'ALOT-TARSHIHA         ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 766,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 344,
            "שם_ישוב": "מענית                    ",
            "שם_ישוב_לועזי": "MA'ANIT                  ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 767,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 230,
            "שם_ישוב": "מעש                      ",
            "שם_ישוב_לועזי": "MA'AS                    ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 768,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 668,
            "שם_ישוב": "מפלסים                   ",
            "שם_ישוב_לועזי": "MEFALLESIM               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 769,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3745,
            "שם_ישוב": "מצדות יהודה              ",
            "שם_ישוב_לועזי": "MEZADOT YEHUDA           ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 770,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 325,
            "שם_ישוב": "מצובה                    ",
            "שם_ישוב_לועזי": "MAZZUVA                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 771,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 757,
            "שם_ישוב": "מצליח                    ",
            "שם_ישוב_לועזי": "MAZLIAH                  ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 772,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 58,
            "שם_ישוב": "מצפה                     ",
            "שם_ישוב_לועזי": "MIZPA                    ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 773,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1222,
            "שם_ישוב": "מצפה אבי\"ב               ",
            "שם_ישוב_לועזי": "MIZPE AVIV               ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 774,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1370,
            "שם_ישוב": "מצפה אילן                ",
            "שם_ישוב_לועזי": "MITSPE ILAN              ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 775,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3576,
            "שם_ישוב": "מצפה יריחו               ",
            "שם_ישוב_לועזי": "MIZPE YERIHO             ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 776,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1190,
            "שם_ישוב": "מצפה נטופה               ",
            "שם_ישוב_לועזי": "MIZPE NETOFA             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 777,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 99,
            "שם_ישוב": "מצפה רמון                ",
            "שם_ישוב_לועזי": "MIZPE RAMON              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 778,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3610,
            "שם_ישוב": "מצפה שלם                 ",
            "שם_ישוב_לועזי": "MIZPE SHALEM             ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 74,
            "שם_מועצה": "מגילות ים המלח"
        },
        {
            "_id": 779,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 648,
            "שם_ישוב": "מצר                      ",
            "שם_ישוב_לועזי": "MEZER                    ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 780,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 22,
            "שם_ישוב": "מקווה ישראל              ",
            "שם_ישוב_לועזי": "MIQWE YISRA'EL           ",
            "סמל_נפה": 53,
            "שם_נפה": "חולון               ",
            "סמל_לשכת_מנא": 53,
            "לשכה": "חולון                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 781,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 843,
            "שם_ישוב": "מרגליות                  ",
            "שם_ישוב_לועזי": "MARGALIYYOT              ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 782,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4101,
            "שם_ישוב": "מרום גולן                ",
            "שם_ישוב_לועזי": "MEROM GOLAN              ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 783,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1340,
            "שם_ישוב": "מרחב עם                  ",
            "שם_ישוב_לועזי": "MERHAV AM                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 784,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 97,
            "שם_ישוב": "מרחביה )מושב(            ",
            "שם_ישוב_לועזי": "MERHAVYA(MOSHAV)         ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 785,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 66,
            "שם_ישוב": "מרחביה )קיבוץ(           ",
            "שם_ישוב_לועזי": "MERHAVYA(QIBBUZ)         ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 786,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1098,
            "שם_ישוב": "מרכז שפירא               ",
            "שם_ישוב_לועזי": "MERKAZ SHAPPIRA          ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 787,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 421,
            "שם_ישוב": "משאבי שדה                ",
            "שם_ישוב_לועזי": "MASH'ABBE SADE           ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 788,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 765,
            "שם_ישוב": "משגב דב                  ",
            "שם_ישוב_לועזי": "MISGAV DOV               ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 32,
            "שם_מועצה": "גדרות"
        },
        {
            "_id": 789,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 378,
            "שם_ישוב": "משגב עם                  ",
            "שם_ישוב_לועזי": "MISGAV AM                ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 790,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 520,
            "שם_ישוב": "משהד                     ",
            "שם_ישוב_לועזי": "MESHHED                  ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 791,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3605,
            "שם_ישוב": "משואה                    ",
            "שם_ישוב_לועזי": "MASSU'A                  ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 792,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 620,
            "שם_ישוב": "משואות יצחק              ",
            "שם_ישוב_לועזי": "MASSUOT YIZHAQ           ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 793,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3785,
            "שם_ישוב": "משכיות                   ",
            "שם_ישוב_לועזי": "MASKIYYOT                ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 794,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 670,
            "שם_ישוב": "משמר איילון              ",
            "שם_ישוב_לועזי": "MISHMAR AYYALON          ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 795,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 563,
            "שם_ישוב": "משמר דוד                 ",
            "שם_ישוב_לועזי": "MISHMAR DAWID            ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 796,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 732,
            "שם_ישוב": "משמר הירדן               ",
            "שם_ישוב_לועזי": "MISHMAR HAYARDEN         ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 797,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 395,
            "שם_ישוב": "משמר הנגב                ",
            "שם_ישוב_לועזי": "MISHMAR HANEGEV          ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 798,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 130,
            "שם_ישוב": "משמר העמק                ",
            "שם_ישוב_לועזי": "MISHMAR HAEMEQ           ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 799,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 729,
            "שם_ישוב": "משמר השבעה               ",
            "שם_ישוב_לועזי": "MISHMAR HASHIV'A         ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 40,
            "שם_מועצה": "שדות דן"
        },
        {
            "_id": 800,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 194,
            "שם_ישוב": "משמר השרון               ",
            "שם_ישוב_לועזי": "MISHMAR HASHARON         ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 801,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 213,
            "שם_ישוב": "משמרות                   ",
            "שם_ישוב_לועזי": "MISHMAROT                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 802,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 425,
            "שם_ישוב": "משמרת                    ",
            "שם_ישוב_לועזי": "MISHMERET                ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 803,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 791,
            "שם_ישוב": "משען                     ",
            "שם_ישוב_לועזי": "MASH'EN                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 804,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1315,
            "שם_ישוב": "מתן                      ",
            "שם_ישוב_לועזי": "MATTAN                   ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 805,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1184,
            "שם_ישוב": "מתת                      ",
            "שם_ישוב_לועזי": "MATTAT                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 806,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3648,
            "שם_ישוב": "מתתיהו                   ",
            "שם_ישוב_לועזי": "MATTITYAHU               ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 807,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4551,
            "שם_ישוב": "נאות גולן                ",
            "שם_ישוב_לועזי": "NE'OT GOLAN              ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 808,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1124,
            "שם_ישוב": "נאות הכיכר               ",
            "שם_ישוב_לועזי": "NE'OT HAKIKKAR           ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 51,
            "שם_מועצה": "תמר"
        },
        {
            "_id": 809,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 408,
            "שם_ישוב": "נאות מרדכי               ",
            "שם_ישוב_לועזי": "NE'OT MORDEKHAY          ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 810,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1197,
            "שם_ישוב": "נאות סמדר                ",
            "שם_ישוב_לועזי": "SHIZZAFON                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 811,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 524,
            "שם_ישוב": "נאעורה                   ",
            "שם_ישוב_לועזי": "NA'URA                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 812,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 396,
            "שם_ישוב": "נבטים                    ",
            "שם_ישוב_לועזי": "NEVATIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 813,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 315,
            "שם_ישוב": "נגבה                     ",
            "שם_ישוב_לועזי": "NEGBA                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 814,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3724,
            "שם_ישוב": "נגוהות                   ",
            "שם_ישוב_לועזי": "NEGOHOT                  ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 815,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 309,
            "שם_ישוב": "נהורה                    ",
            "שם_ישוב_לועזי": "NEHORA                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 816,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 80,
            "שם_ישוב": "נהלל                     ",
            "שם_ישוב_לועזי": "NAHALAL                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 817,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 9100,
            "שם_ישוב": "נהריה                    ",
            "שם_ישוב_לועזי": "NAHARIYYA                ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 818,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4304,
            "שם_ישוב": "נוב                      ",
            "שם_ישוב_לועזי": "NOV                      ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 819,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 55,
            "שם_ישוב": "נוגה                     ",
            "שם_ישוב_לועזי": "NOGAH                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 820,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1366,
            "שם_ישוב": "נווה                     ",
            "שם_ישוב_לועזי": "NAVE                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 821,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 926,
            "שם_ישוב": "נווה אבות                ",
            "שם_ישוב_לועזי": "NEWE AVOT                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 822,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 590,
            "שם_ישוב": "נווה אור                 ",
            "שם_ישוב_לועזי": "NEWE UR                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 823,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4303,
            "שם_ישוב": "נווה אטי\"ב               ",
            "שם_ישוב_לועזי": "NEWE ATIV                ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 824,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 405,
            "שם_ישוב": "נווה אילן                ",
            "שם_ישוב_לועזי": "NEWE ILAN                ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 825,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 296,
            "שם_ישוב": "נווה איתן                ",
            "שם_ישוב_לועזי": "NEWE ETAN                ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 826,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3725,
            "שם_ישוב": "נווה דניאל               ",
            "שם_ישוב_לועזי": "NEWE DANIYYEL            ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 827,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1057,
            "שם_ישוב": "נווה זוהר                ",
            "שם_ישוב_לועזי": "NEWE ZOHAR               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 51,
            "שם_מועצה": "תמר"
        },
        {
            "_id": 828,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1314,
            "שם_ישוב": "נווה זיו                 ",
            "שם_ישוב_לועזי": "NEWE ZIV                 ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 829,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1279,
            "שם_ישוב": "נווה חריף                ",
            "שם_ישוב_לועזי": "NEWE HARIF               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 830,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 312,
            "שם_ישוב": "נווה ים                  ",
            "שם_ישוב_לועזי": "NEWE YAM                 ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 831,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 686,
            "שם_ישוב": "נווה ימין                ",
            "שם_ישוב_לועזי": "NEWE YAMIN               ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 832,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 858,
            "שם_ישוב": "נווה ירק                 ",
            "שם_ישוב_לועזי": "NEWE YARAQ               ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 833,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 827,
            "שם_ישוב": "נווה מבטח                ",
            "שם_ישוב_לועזי": "NEWE MIVTAH              ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 834,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1071,
            "שם_ישוב": "נווה מיכאל               ",
            "שם_ישוב_לועזי": "NEWE MIKHA'EL            ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 835,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1259,
            "שם_ישוב": "נווה שלום                ",
            "שם_ישוב_לועזי": "NEWE SHALOM              ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 836,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 15,
            "שם_ישוב": "נועם                     ",
            "שם_ישוב_לועזי": "NO'AM                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 837,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1333,
            "שם_ישוב": "נוף איילון               ",
            "שם_ישוב_לועזי": "NOF AYYALON              ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 838,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3790,
            "שם_ישוב": "נופים                    ",
            "שם_ישוב_לועזי": "NOFIM                    ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 839,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1284,
            "שם_ישוב": "נופית                    ",
            "שם_ישוב_לועזי": "NOFIT                    ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 840,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 257,
            "שם_ישוב": "נופך                     ",
            "שם_ישוב_לועזי": "NOFEKH                   ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 841,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3726,
            "שם_ישוב": "נוקדים                   ",
            "שם_ישוב_לועזי": "NOQEDIM                  ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 842,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 447,
            "שם_ישוב": "נורדיה                   ",
            "שם_ישוב_לועזי": "NORDIYYA                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 843,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 833,
            "שם_ישוב": "נורית                    ",
            "שם_ישוב_לועזי": "NURIT                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 844,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 59,
            "שם_ישוב": "נחושה                    ",
            "שם_ישוב_לועזי": "NEHUSHA                  ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 845,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 844,
            "שם_ישוב": "נחל עוז                  ",
            "שם_ישוב_לועזי": "NAHAL OZ                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 846,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2045,
            "שם_ישוב": "נחלה                     ",
            "שם_ישוב_לועזי": "NAHALA                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 847,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3767,
            "שם_ישוב": "נחליאל                   ",
            "שם_ישוב_לועזי": "NAHALI'EL                ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 848,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 449,
            "שם_ישוב": "נחלים                    ",
            "שם_ישוב_לועזי": "NEHALIM                  ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 849,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 809,
            "שם_ישוב": "נחם                      ",
            "שם_ישוב_לועזי": "NAHAM                    ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 850,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 522,
            "שם_ישוב": "נחף                      ",
            "שם_ישוב_לועזי": "NAHEF                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 851,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 433,
            "שם_ישוב": "נחשולים                  ",
            "שם_ישוב_לועזי": "NAHSHOLIM                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 852,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 777,
            "שם_ישוב": "נחשון                    ",
            "שם_ישוב_לועזי": "NAHSHON                  ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 853,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 705,
            "שם_ישוב": "נחשונים                  ",
            "שם_ישוב_לועזי": "NAHSHONIM                ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 854,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1147,
            "שם_ישוב": "נטועה                    ",
            "שם_ישוב_לועזי": "NETU'A                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 855,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4014,
            "שם_ישוב": "נטור                     ",
            "שם_ישוב_לועזי": "NATUR                    ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 856,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1369,
            "שם_ישוב": "נטע                      ",
            "שם_ישוב_לועזי": "NETA                     ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 857,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 174,
            "שם_ישוב": "נטעים                    ",
            "שם_ישוב_לועזי": "NETA'IM                  ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 27,
            "שם_מועצה": "גן רווה"
        },
        {
            "_id": 858,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1254,
            "שם_ישוב": "נטף                      ",
            "שם_ישוב_לועזי": "NATAF                    ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 859,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 523,
            "שם_ישוב": "ניין                     ",
            "שם_ישוב_לועזי": "NEIN                     ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 66,
            "שם_מועצה": "בוסתאן אל-מרג'"
        },
        {
            "_id": 860,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3655,
            "שם_ישוב": "ניל\"י                    ",
            "שם_ישוב_לועזי": "NILI                     ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 861,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 351,
            "שם_ישוב": "ניצן                     ",
            "שם_ישוב_לועזי": "NIZZAN                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 862,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1419,
            "שם_ישוב": "ניצן ב'                  ",
            "שם_ישוב_לועזי": "NIZZAN B                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 863,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1195,
            "שם_ישוב": "ניצנה )קהילת חינוך(      ",
            "שם_ישוב_לועזי": "NIZZANA (QEHILAT HIN     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 864,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1280,
            "שם_ישוב": "ניצני סיני               ",
            "שם_ישוב_לועזי": "NIZZANE SINAY            ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 865,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 851,
            "שם_ישוב": "ניצני עוז                ",
            "שם_ישוב_לועזי": "NIZZANE OZ               ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 866,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 359,
            "שם_ישוב": "ניצנים                   ",
            "שם_ישוב_לועזי": "NIZZANIM                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 867,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 808,
            "שם_ישוב": "ניר אליהו                ",
            "שם_ישוב_לועזי": "NIR ELIYYAHU             ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 868,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 553,
            "שם_ישוב": "ניר בנים                 ",
            "שם_ישוב_לועזי": "NIR BANIM                ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 869,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 720,
            "שם_ישוב": "ניר גלים                 ",
            "שם_ישוב_לועזי": "NIR GALLIM               ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 29,
            "שם_מועצה": "חבל יבנה"
        },
        {
            "_id": 870,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 256,
            "שם_ישוב": "ניר דוד )תל עמל(         ",
            "שם_ישוב_לועזי": "NIR DAWID (TEL AMAL)     ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 871,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 11,
            "שם_ישוב": "ניר ח\"ן                  ",
            "שם_ישוב_לועזי": "NIR HEN                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 872,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 165,
            "שם_ישוב": "ניר יפה                  ",
            "שם_ישוב_לועזי": "NIR YAFE                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 873,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 402,
            "שם_ישוב": "ניר יצחק                 ",
            "שם_ישוב_לועזי": "NIR YIZHAQ               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 874,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 699,
            "שם_ישוב": "ניר ישראל                ",
            "שם_ישוב_לועזי": "NIR YISRA'EL             ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 875,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2047,
            "שם_ישוב": "ניר משה                  ",
            "שם_ישוב_לועזי": "NIR MOSHE                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 876,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 69,
            "שם_ישוב": "ניר עוז                  ",
            "שם_ישוב_לועזי": "NIR OZ                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 877,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 348,
            "שם_ישוב": "ניר עם                   ",
            "שם_ישוב_לועזי": "NIR AM                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 878,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 769,
            "שם_ישוב": "ניר עציון                ",
            "שם_ישוב_לועזי": "NIR EZYON                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 879,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2048,
            "שם_ישוב": "ניר עקיבא                ",
            "שם_ישוב_לועזי": "NIR AQIVA                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 880,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 331,
            "שם_ישוב": "ניר צבי                  ",
            "שם_ישוב_לועזי": "NIR ZEVI                 ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 40,
            "שם_מועצה": "שדות דן"
        },
        {
            "_id": 881,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 602,
            "שם_ישוב": "נירים                    ",
            "שם_ישוב_לועזי": "NIRIM                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 882,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1236,
            "שם_ישוב": "נירית                    ",
            "שם_ישוב_לועזי": "NIRIT                    ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 883,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3620,
            "שם_ישוב": "נירן                     ",
            "שם_ישוב_לועזי": "NIRAN                    ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 884,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4035,
            "שם_ישוב": "נמרוד                    ",
            "שם_ישוב_לועזי": "NIMROD                   ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 885,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 825,
            "שם_ישוב": "נס הרים                  ",
            "שם_ישוב_לועזי": "NES HARIM                ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 886,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1143,
            "שם_ישוב": "נס עמים                  ",
            "שם_ישוב_לועזי": "NES AMMIM                ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 887,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7200,
            "שם_ישוב": "נס ציונה                 ",
            "שם_ישוב_לועזי": "NES ZIYYONA              ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 888,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 186,
            "שם_ישוב": "נעורים                   ",
            "שם_ישוב_לועזי": "NE'URIM                  ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 889,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3787,
            "שם_ישוב": "נעלה                     ",
            "שם_ישוב_לועזי": "NA'ALE                   ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 890,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3713,
            "שם_ישוב": "נעמ\"ה                    ",
            "שם_ישוב_לועזי": "NAAMA                    ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 891,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 158,
            "שם_ישוב": "נען                      ",
            "שם_ישוב_לועזי": "NA'AN                    ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 892,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1041,
            "שם_ישוב": "נצאצרה )שבט(             ",
            "שם_ישוב_לועזי": "NASASRA                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 893,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1372,
            "שם_ישוב": "נצר חזני                 ",
            "שם_ישוב_לועזי": "NEZER HAZZANI            ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 31,
            "שם_מועצה": "נחל שורק"
        },
        {
            "_id": 894,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 435,
            "שם_ישוב": "נצר סרני                 ",
            "שם_ישוב_לועזי": "NEZER SERENI             ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 895,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7300,
            "שם_ישוב": "נצרת                     ",
            "שם_ישוב_לועזי": "NAZARETH                 ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 896,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1061,
            "שם_ישוב": "נצרת עילית               ",
            "שם_ישוב_לועזי": "NAZERAT ILLIT            ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 897,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2500,
            "שם_ישוב": "נשר                      ",
            "שם_ישוב_לועזי": "NESHER                   ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 898,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3555,
            "שם_ישוב": "נתיב הגדוד               ",
            "שם_ישוב_לועזי": "NETIV HAGEDUD            ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 899,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 693,
            "שם_ישוב": "נתיב הל\"ה                ",
            "שם_ישוב_לועזי": "NETIV HALAMED-HE         ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 900,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1242,
            "שם_ישוב": "נתיב העשרה               ",
            "שם_ישוב_לועזי": "NETIV HAASARA            ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 901,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 792,
            "שם_ישוב": "נתיב השיירה              ",
            "שם_ישוב_לועזי": "NETIV HASHAYYARA         ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 902,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 246,
            "שם_ישוב": "נתיבות                   ",
            "שם_ישוב_לועזי": "NETIVOT                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 903,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7400,
            "שם_ישוב": "נתניה                    ",
            "שם_ישוב_לועזי": "NETANYA                  ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 904,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 525,
            "שם_ישוב": "סאג'ור                   ",
            "שם_ישוב_לועזי": "SAJUR                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 905,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 578,
            "שם_ישוב": "סאסא                     ",
            "שם_ישוב_לועזי": "SASA                     ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 906,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 587,
            "שם_ישוב": "סביון                    ",
            "שם_ישוב_לועזי": "SAVYON                   ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 907,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2046,
            "שם_ישוב": "סגולה                    ",
            "שם_ישוב_לועזי": "SEGULA                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 908,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 942,
            "שם_ישוב": "סואעד )חמרייה(           ",
            "שם_ישוב_לועזי": "SAWA'ID(HAMRIYYE)        ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 909,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 989,
            "שם_ישוב": "סואעד )כמאנה( )שבט(      ",
            "שם_ישוב_לועזי": "SAWA'ID (KAMANE)         ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 910,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 526,
            "שם_ישוב": "סולם                     ",
            "שם_ישוב_לועזי": "SULAM                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 66,
            "שם_מועצה": "בוסתאן אל-מרג'"
        },
        {
            "_id": 911,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3756,
            "שם_ישוב": "סוסיה                    ",
            "שם_ישוב_לועזי": "SUSEYA                   ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 912,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1238,
            "שם_ישוב": "סופה                     ",
            "שם_ישוב_לועזי": "SUFA                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 913,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7500,
            "שם_ישוב": "סח'נין                   ",
            "שם_ישוב_לועזי": "SAKHNIN                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 914,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1170,
            "שם_ישוב": "סייד )שבט(               ",
            "שם_ישוב_לועזי": "SAYYID                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 915,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1245,
            "שם_ישוב": "סלמה                     ",
            "שם_ישוב_לועזי": "SALLAMA                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 916,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3567,
            "שם_ישוב": "סלעית                    ",
            "שם_ישוב_לועזי": "SAL'IT                   ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 917,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1156,
            "שם_ישוב": "סמר                      ",
            "שם_ישוב_לועזי": "SAMAR                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 918,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3777,
            "שם_ישוב": "סנסנה                    ",
            "שם_ישוב_לועזי": "                         ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 919,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 419,
            "שם_ישוב": "סעד                      ",
            "שם_ישוב_לועזי": "SA'AD                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 920,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1360,
            "שם_ישוב": "סעוה                     ",
            "שם_ישוב_לועזי": "SA'WA                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 69,
            "שם_מועצה": "אל קסום"
        },
        {
            "_id": 921,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 454,
            "שם_ישוב": "סער                      ",
            "שם_ישוב_לועזי": "SA'AR                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 922,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1176,
            "שם_ישוב": "ספיר                     ",
            "שם_ישוב_לועזי": "SAPPIR                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 54,
            "שם_מועצה": "הערבה התיכונה"
        },
        {
            "_id": 923,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 610,
            "שם_ישוב": "סתריה                    ",
            "שם_ישוב_לועזי": "SITRIYYA                 ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 924,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4501,
            "שם_ישוב": "ע'ג'ר                    ",
            "שם_ישוב_לועזי": "GHAJAR                   ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 925,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 892,
            "שם_ישוב": "עבדון                    ",
            "שם_ישוב_לועזי": "AVDON                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 926,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 376,
            "שם_ישוב": "עברון                    ",
            "שם_ישוב_לועזי": "EVRON                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 927,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 794,
            "שם_ישוב": "עגור                     ",
            "שם_ישוב_לועזי": "AGUR                     ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 928,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1199,
            "שם_ישוב": "עדי                      ",
            "שם_ישוב_לועזי": "ADI                      ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 929,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2035,
            "שם_ישוב": "עדנים                    ",
            "שם_ישוב_לועזי": "ADANIM                   ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 930,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 826,
            "שם_ישוב": "עוזה                     ",
            "שם_ישוב_לועזי": "UZA                      ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 931,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 528,
            "שם_ישוב": "עוזייר                   ",
            "שם_ישוב_לועזי": "UZEIR                    ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 65,
            "שם_מועצה": "אל-בטוף"
        },
        {
            "_id": 932,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 737,
            "שם_ישוב": "עולש                     ",
            "שם_ישוב_לועזי": "OLESH                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 933,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 666,
            "שם_ישוב": "עומר                     ",
            "שם_ישוב_לועזי": "OMER                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 934,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 810,
            "שם_ישוב": "עופר                     ",
            "שם_ישוב_לועזי": "OFER                     ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 935,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3617,
            "שם_ישוב": "עופרה                    ",
            "שם_ישוב_לועזי": "OFRA                     ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 936,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 32,
            "שם_ישוב": "עוצם                     ",
            "שם_ישוב_לועזי": "OZEM                     ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 937,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 957,
            "שם_ישוב": "עוקבי )בנו עוקבה(        ",
            "שם_ישוב_לועזי": "UQBI (BANU UQBA)         ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 938,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 328,
            "שם_ישוב": "עזוז                     ",
            "שם_ישוב_לועזי": "EZUZ                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 939,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1149,
            "שם_ישוב": "עזר                      ",
            "שם_ישוב_לועזי": "EZER                     ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 940,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 837,
            "שם_ישוב": "עזריאל                   ",
            "שם_ישוב_לועזי": "AZRI'EL                  ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 941,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 711,
            "שם_ישוב": "עזריה                    ",
            "שם_ישוב_לועזי": "AZARYA                   ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 942,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 817,
            "שם_ישוב": "עזריקם                   ",
            "שם_ישוב_לועזי": "AZRIQAM                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 943,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 969,
            "שם_ישוב": "עטאוונה )שבט(            ",
            "שם_ישוב_לועזי": "ATAWNE                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 944,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3658,
            "שם_ישוב": "עטרת                     ",
            "שם_ישוב_לועזי": "ATERET                   ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 945,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1175,
            "שם_ישוב": "עידן                     ",
            "שם_ישוב_לועזי": "IDDAN                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 54,
            "שם_מועצה": "הערבה התיכונה"
        },
        {
            "_id": 946,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 530,
            "שם_ישוב": "עיילבון                  ",
            "שם_ישוב_לועזי": "EILABUN                  ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 947,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 156,
            "שם_ישוב": "עיינות                   ",
            "שם_ישוב_לועזי": "AYANOT                   ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 27,
            "שם_מועצה": "גן רווה"
        },
        {
            "_id": 948,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 511,
            "שם_ישוב": "עילוט                    ",
            "שם_ישוב_לועזי": "ILUT                     ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 949,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 687,
            "שם_ישוב": "עין איילה                ",
            "שם_ישוב_לועזי": "EN AYYALA                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 950,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 546,
            "שם_ישוב": "עין אל-אסד               ",
            "שם_ישוב_לועזי": "EIN AL-ASAD              ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 951,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 273,
            "שם_ישוב": "עין גב                   ",
            "שם_ישוב_לועזי": "EN GEV                   ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 952,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2042,
            "שם_ישוב": "עין גדי                  ",
            "שם_ישוב_לועזי": "EN GEDI                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 51,
            "שם_מועצה": "תמר"
        },
        {
            "_id": 953,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 436,
            "שם_ישוב": "עין דור                  ",
            "שם_ישוב_לועזי": "EN DOR                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 954,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1240,
            "שם_ישוב": "עין הבשור                ",
            "שם_ישוב_לועזי": "EN HABESOR               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 955,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 74,
            "שם_ישוב": "עין הוד                  ",
            "שם_ישוב_לועזי": "EN HOD                   ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 956,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 167,
            "שם_ישוב": "עין החורש                ",
            "שם_ישוב_לועזי": "EN HAHORESH              ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 957,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 289,
            "שם_ישוב": "עין המפרץ                ",
            "שם_ישוב_לועזי": "EN HAMIFRAZ              ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 958,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 383,
            "שם_ישוב": "עין הנצי\"ב               ",
            "שם_ישוב_לועזי": "EN HANAZIV               ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 959,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 367,
            "שם_ישוב": "עין העמק                 ",
            "שם_ישוב_לועזי": "EN HAEMEQ                ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 960,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 270,
            "שם_ישוב": "עין השופט                ",
            "שם_ישוב_לועזי": "EN HASHOFET              ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 961,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 676,
            "שם_ישוב": "עין השלושה               ",
            "שם_ישוב_לועזי": "EN HASHELOSHA            ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 962,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 157,
            "שם_ישוב": "עין ורד                  ",
            "שם_ישוב_לועזי": "EN WERED                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 963,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4503,
            "שם_ישוב": "עין זיוון                ",
            "שם_ישוב_לועזי": "EN ZIWAN                 ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 964,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1320,
            "שם_ישוב": "עין חוד                  ",
            "שם_ישוב_לועזי": "EIN HOD                  ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 965,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1053,
            "שם_ישוב": "עין חצבה                 ",
            "שם_ישוב_לועזי": "EN HAZEVA                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 51,
            "שם_מועצה": "תמר"
        },
        {
            "_id": 966,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 89,
            "שם_ישוב": "עין חרוד )איחוד(         ",
            "שם_ישוב_לועזי": "EN HAROD (IHUD)          ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 967,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 82,
            "שם_ישוב": "עין חרוד )מאוחד(         ",
            "שם_ישוב_לועזי": "EN HAROD(ME'UHAD)        ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 968,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 806,
            "שם_ישוב": "עין יהב                  ",
            "שם_ישוב_לועזי": "EN YAHAV                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 54,
            "שם_מועצה": "הערבה התיכונה"
        },
        {
            "_id": 969,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 813,
            "שם_ישוב": "עין יעקב                 ",
            "שם_ישוב_לועזי": "EN YA'AQOV               ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 970,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1056,
            "שם_ישוב": "עין כרם-בי\"ס חקלאי       ",
            "שם_ישוב_לועזי": "EN KAREM-B.S.HAQLA'I     ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 971,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 426,
            "שם_ישוב": "עין כרמל                 ",
            "שם_ישוב_לועזי": "EN KARMEL                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 972,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 532,
            "שם_ישוב": "עין מאהל                 ",
            "שם_ישוב_לועזי": "EIN MAHEL                ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 973,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 521,
            "שם_ישוב": "עין נקובא                ",
            "שם_ישוב_לועזי": "EIN NAQQUBA              ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 974,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 223,
            "שם_ישוב": "עין עירון                ",
            "שם_ישוב_לועזי": "EN IRON                  ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 975,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 622,
            "שם_ישוב": "עין צורים                ",
            "שם_ישוב_לועזי": "EN ZURIM                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 976,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4502,
            "שם_ישוב": "עין קנייא                ",
            "שם_ישוב_לועזי": "EIN QINIYYE              ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 977,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 514,
            "שם_ישוב": "עין ראפה                 ",
            "שם_ישוב_לועזי": "EIN RAFA                 ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 978,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 139,
            "שם_ישוב": "עין שמר                  ",
            "שם_ישוב_לועזי": "EN SHEMER                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 979,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 880,
            "שם_ישוב": "עין שריד                 ",
            "שם_ישוב_לועזי": "EN SARID                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 980,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1251,
            "שם_ישוב": "עין תמר                  ",
            "שם_ישוב_לועזי": "EN TAMAR                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 51,
            "שם_מועצה": "תמר"
        },
        {
            "_id": 981,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 871,
            "שם_ישוב": "עינת                     ",
            "שם_ישוב_לועזי": "ENAT                     ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 982,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1187,
            "שם_ישוב": "עיר אובות                ",
            "שם_ישוב_לועזי": "IR OVOT                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 54,
            "שם_מועצה": "הערבה התיכונה"
        },
        {
            "_id": 983,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7600,
            "שם_ישוב": "עכו                      ",
            "שם_ישוב_לועזי": "AKKO                     ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 984,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1146,
            "שם_ישוב": "עלומים                   ",
            "שם_ישוב_לועזי": "ALUMIM                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 985,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3765,
            "שם_ישוב": "עלי                      ",
            "שם_ישוב_לועזי": "ELI                      ",
            "סמל_נפה": 72,
            "שם_נפה": "שכם                 ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 986,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3727,
            "שם_ישוב": "עלי זהב                  ",
            "שם_ישוב_לועזי": "ALE ZAHAV                ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 987,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 688,
            "שם_ישוב": "עלמה                     ",
            "שם_ישוב_לועזי": "ALMA                     ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 988,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3715,
            "שם_ישוב": "עלמון                    ",
            "שם_ישוב_לועזי": "ALMON                    ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 989,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1212,
            "שם_ישוב": "עמוקה                    ",
            "שם_ישוב_לועזי": "AMUQQA                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 990,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3824,
            "שם_ישוב": "עמיחי                    ",
            "שם_ישוב_לועזי": "AMMIHAY                  ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 991,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 779,
            "שם_ישוב": "עמינדב                   ",
            "שם_ישוב_לועזי": "AMMINADAV                ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 992,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 385,
            "שם_ישוב": "עמיעד                    ",
            "שם_ישוב_לועזי": "AMMI'AD                  ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 993,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 318,
            "שם_ישוב": "עמיעוז                   ",
            "שם_ישוב_לועזי": "AMMI'OZ                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 994,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 773,
            "שם_ישוב": "עמיקם                    ",
            "שם_ישוב_לועזי": "AMMIQAM                  ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 45,
            "שם_מועצה": "אלונה"
        },
        {
            "_id": 995,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 319,
            "שם_ישוב": "עמיר                     ",
            "שם_ישוב_לועזי": "AMIR                     ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 996,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3660,
            "שם_ישוב": "עמנואל                   ",
            "שם_ישוב_לועזי": "IMMANU'EL                ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 997,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 708,
            "שם_ישוב": "עמקה                     ",
            "שם_ישוב_לועזי": "AMQA                     ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 998,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3712,
            "שם_ישוב": "ענב                      ",
            "שם_ישוב_לועזי": "ENAV                     ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 999,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 534,
            "שם_ישוב": "עספיא                    ",
            "שם_ישוב_לועזי": "ISIFYA                   ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1000,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7700,
            "שם_ישוב": "עפולה                    ",
            "שם_ישוב_לועזי": "AFULA                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1001,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3778,
            "שם_ישוב": "עץ אפרים                 ",
            "שם_ישוב_לועזי": "EZ EFRAYIM               ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 1002,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 917,
            "שם_ישוב": "עצמון שגב                ",
            "שם_ישוב_לועזי": "ATSMON-SEGEV             ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1003,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 531,
            "שם_ישוב": "עראבה                    ",
            "שם_ישוב_לועזי": "ARRABE                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1004,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1246,
            "שם_ישוב": "עראמשה                   ",
            "שם_ישוב_לועזי": "ARAMSHA                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 1005,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1335,
            "שם_ישוב": "ערב אל נעים              ",
            "שם_ישוב_לועזי": "ARRAB AL NAIM            ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1006,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2560,
            "שם_ישוב": "ערד                      ",
            "שם_ישוב_לועזי": "ARAD                     ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1007,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 593,
            "שם_ישוב": "ערוגות                   ",
            "שם_ישוב_לועזי": "ARUGOT                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 1008,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 637,
            "שם_ישוב": "ערערה                    ",
            "שם_ישוב_לועזי": "AR'ARA                   ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1009,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1192,
            "שם_ישוב": "ערערה-בנגב               ",
            "שם_ישוב_לועזי": "AR'ARA-BANEGEV           ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1010,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 591,
            "שם_ישוב": "עשרת                     ",
            "שם_ישוב_לועזי": "ASERET                   ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 32,
            "שם_מועצה": "גדרות"
        },
        {
            "_id": 1011,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 53,
            "שם_ישוב": "עתלית                    ",
            "שם_ישוב_לועזי": "ATLIT                    ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 1012,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3748,
            "שם_ישוב": "עתניאל                   ",
            "שם_ישוב_לועזי": "OTNI'EL                  ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 1013,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1151,
            "שם_ישוב": "פארן                     ",
            "שם_ישוב_לועזי": "PARAN                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 54,
            "שם_מועצה": "הערבה התיכונה"
        },
        {
            "_id": 1014,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3768,
            "שם_ישוב": "פדואל                    ",
            "שם_ישוב_לועזי": "PEDU'EL                  ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 1015,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 750,
            "שם_ישוב": "פדויים                   ",
            "שם_ישוב_לועזי": "PEDUYIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 1016,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 838,
            "שם_ישוב": "פדיה                     ",
            "שם_ישוב_לועזי": "PEDAYA                   ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 1017,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1104,
            "שם_ישוב": "פוריה - כפר עבודה        ",
            "שם_ישוב_לועזי": "PORIYYA-KEFAR AVODA      ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 1018,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1105,
            "שם_ישוב": "פוריה - נווה עובד        ",
            "שם_ישוב_לועזי": "PORIYYA-NEWE OVED        ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 1019,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1313,
            "שם_ישוב": "פוריה עילית              ",
            "שם_ישוב_לועזי": "PORIYYA ILLIT            ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 1020,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 537,
            "שם_ישוב": "פוריידיס                 ",
            "שם_ישוב_לועזי": "FUREIDIS                 ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1021,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 767,
            "שם_ישוב": "פורת                     ",
            "שם_ישוב_לועזי": "PORAT                    ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 1022,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 749,
            "שם_ישוב": "פטיש                     ",
            "שם_ישוב_לועזי": "PATTISH                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 1023,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1185,
            "שם_ישוב": "פלך                      ",
            "שם_ישוב_לועזי": "PELEKH                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1024,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 597,
            "שם_ישוב": "פלמחים                   ",
            "שם_ישוב_לועזי": "PALMAHIM                 ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 27,
            "שם_מועצה": "גן רווה"
        },
        {
            "_id": 1025,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3723,
            "שם_ישוב": "פני חבר                  ",
            "שם_ישוב_לועזי": "PENE HEVER               ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 1026,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3659,
            "שם_ישוב": "פסגות                    ",
            "שם_ישוב_לועזי": "PESAGOT                  ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 1027,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 535,
            "שם_ישוב": "פסוטה                    ",
            "שם_ישוב_לועזי": "FASSUTA                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1028,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2059,
            "שם_ישוב": "פעמי תש\"ז                ",
            "שם_ישוב_לועזי": "PA'AME TASHAZ            ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 1029,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3615,
            "שם_ישוב": "פצאל                     ",
            "שם_ישוב_לועזי": "PEZA'EL                  ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 1030,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 536,
            "שם_ישוב": "פקיעין )בוקייעה(         ",
            "שם_ישוב_לועזי": "PEQI'IN (BUQEI'A)        ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1031,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 281,
            "שם_ישוב": "פקיעין חדשה              ",
            "שם_ישוב_לועזי": "PEQI'IN HADASHA          ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 1032,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7800,
            "שם_ישוב": "פרדס חנה-כרכור           ",
            "שם_ישוב_לועזי": "PARDES HANNA-KARKUR      ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1033,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 171,
            "שם_ישוב": "פרדסיה                   ",
            "שם_ישוב_לועזי": "PARDESIYYA               ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1034,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 599,
            "שם_ישוב": "פרוד                     ",
            "שם_ישוב_לועזי": "PAROD                    ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 1035,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2053,
            "שם_ישוב": "פרזון                    ",
            "שם_ישוב_לועזי": "PERAZON                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 1036,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1231,
            "שם_ישוב": "פרי גן                   ",
            "שם_ישוב_לועזי": "PERI GAN                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 1037,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7900,
            "שם_ישוב": "פתח תקווה                ",
            "שם_ישוב_לועזי": "PETAH TIQWA              ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1038,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 839,
            "שם_ישוב": "פתחיה                    ",
            "שם_ישוב_לועזי": "PETAHYA                  ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 1039,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 413,
            "שם_ישוב": "צאלים                    ",
            "שם_ישוב_לועזי": "ZE'ELIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 1040,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1180,
            "שם_ישוב": "צביה                     ",
            "שם_ישוב_לועזי": "ZVIYYA                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1041,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1213,
            "שם_ישוב": "צבעון                    ",
            "שם_ישוב_לועזי": "ZIV'ON                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 1042,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 465,
            "שם_ישוב": "צובה                     ",
            "שם_ישוב_לועזי": "ZOVA                     ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1043,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1136,
            "שם_ישוב": "צוחר                     ",
            "שם_ישוב_לועזי": "ZOHAR                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 1044,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1111,
            "שם_ישוב": "צופיה                    ",
            "שם_ישוב_לועזי": "ZOFIYYA                  ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 29,
            "שם_מועצה": "חבל יבנה"
        },
        {
            "_id": 1045,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3791,
            "שם_ישוב": "צופים                    ",
            "שם_ישוב_לועזי": "ZUFIN                    ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 1046,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 198,
            "שם_ישוב": "צופית                    ",
            "שם_ישוב_לועזי": "ZOFIT                    ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 1047,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1150,
            "שם_ישוב": "צופר                     ",
            "שם_ישוב_לועזי": "ZOFAR                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 54,
            "שם_מועצה": "הערבה התיכונה"
        },
        {
            "_id": 1048,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1102,
            "שם_ישוב": "צוקי ים                  ",
            "שם_ישוב_לועזי": "SHOSHANNAT HAAMAQIM(     ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 1049,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1262,
            "שם_ישוב": "צוקים                    ",
            "שם_ישוב_לועזי": "MAHANE BILDAD            ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 54,
            "שם_מועצה": "הערבה התיכונה"
        },
        {
            "_id": 1050,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1113,
            "שם_ישוב": "צור הדסה                 ",
            "שם_ישוב_לועזי": "ZUR HADASSA              ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1051,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1345,
            "שם_ישוב": "צור יצחק                 ",
            "שם_ישוב_לועזי": "ZUR YIZHAQ               ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 1052,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 276,
            "שם_ישוב": "צור משה                  ",
            "שם_ישוב_לועזי": "ZUR MOSHE                ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 1053,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1148,
            "שם_ישוב": "צור נתן                  ",
            "שם_ישוב_לועזי": "ZUR NATAN                ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 1054,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 774,
            "שם_ישוב": "צוריאל                   ",
            "שם_ישוב_לועזי": "ZURI'EL                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 1055,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1221,
            "שם_ישוב": "צורית                    ",
            "שם_ישוב_לועזי": "ZURIT                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1056,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 613,
            "שם_ישוב": "ציפורי                   ",
            "שם_ישוב_לועזי": "ZIPPORI                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 1057,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 796,
            "שם_ישוב": "צלפון                    ",
            "שם_ישוב_לועזי": "ZELAFON                  ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1058,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 636,
            "שם_ישוב": "צנדלה                    ",
            "שם_ישוב_לועזי": "SANDALA                  ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 1059,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 594,
            "שם_ישוב": "צפריה                    ",
            "שם_ישוב_לועזי": "ZAFRIYYA                 ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 40,
            "שם_מועצה": "שדות דן"
        },
        {
            "_id": 1060,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1079,
            "שם_ישוב": "צפרירים                  ",
            "שם_ישוב_לועזי": "ZAFRIRIM                 ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1061,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 8000,
            "שם_ישוב": "צפת                      ",
            "שם_ישוב_לועזי": "ZEFAT                    ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1062,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 612,
            "שם_ישוב": "צרופה                    ",
            "שם_ישוב_לועזי": "ZERUFA                   ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 1063,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 567,
            "שם_ישוב": "צרעה                     ",
            "שם_ישוב_לועזי": "ZOR'A                    ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1064,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1234,
            "שם_ישוב": "קבועה )שבט(              ",
            "שם_ישוב_לועזי": "QABBO'A                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1065,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 334,
            "שם_ישוב": "קבוצת יבנה               ",
            "שם_ישוב_לועזי": "QEVUZAT YAVNE            ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 29,
            "שם_מועצה": "חבל יבנה"
        },
        {
            "_id": 1066,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3557,
            "שם_ישוב": "קדומים                   ",
            "שם_ישוב_לועזי": "QEDUMIM                  ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1067,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 195,
            "שם_ישוב": "קדימה-צורן               ",
            "שם_ישוב_לועזי": "QADIMA-ZORAN             ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1068,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 392,
            "שם_ישוב": "קדמה                     ",
            "שם_ישוב_לועזי": "QEDMA                    ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 1069,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4025,
            "שם_ישוב": "קדמת צבי                 ",
            "שם_ישוב_לועזי": "QIDMAT ZEVI              ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 1070,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3781,
            "שם_ישוב": "קדר                      ",
            "שם_ישוב_לועזי": "QEDAR                    ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 1071,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 615,
            "שם_ישוב": "קדרון                    ",
            "שם_ישוב_לועזי": "QIDRON                   ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 28,
            "שם_מועצה": "ברנר"
        },
        {
            "_id": 1072,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1211,
            "שם_ישוב": "קדרים                    ",
            "שם_ישוב_לועזי": "QADDARIM                 ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 1073,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 964,
            "שם_ישוב": "קודייראת א-צאנע)שבט(     ",
            "שם_ישוב_לועזי": "QUDEIRAT AS-SANI         ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1074,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 972,
            "שם_ישוב": "קוואעין )שבט(            ",
            "שם_ישוב_לועזי": "QAWA'IN                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1075,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 766,
            "שם_ישוב": "קוממיות                  ",
            "שם_ישוב_לועזי": "QOMEMIYYUT               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 1076,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1179,
            "שם_ישוב": "קורנית                   ",
            "שם_ישוב_לועזי": "QORANIT                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1077,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1052,
            "שם_ישוב": "קטורה                    ",
            "שם_ישוב_לועזי": "QETURA                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 1078,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1167,
            "שם_ישוב": "קיסריה                   ",
            "שם_ישוב_לועזי": "QESARIYYA                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 1079,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 414,
            "שם_ישוב": "קלחים                    ",
            "שם_ישוב_לועזי": "QELAHIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 1080,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3601,
            "שם_ישוב": "קליה                     ",
            "שם_ישוב_לועזי": "QALYA                    ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 74,
            "שם_מועצה": "מגילות ים המלח"
        },
        {
            "_id": 1081,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 638,
            "שם_ישוב": "קלנסווה                  ",
            "שם_ישוב_לועזי": "QALANSAWE                ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1082,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4024,
            "שם_ישוב": "קלע                      ",
            "שם_ישוב_לועזי": "QELA                     ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 1083,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1243,
            "שם_ישוב": "קציר                     ",
            "שם_ישוב_לועזי": "QAZIR                    ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 1084,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1347,
            "שם_ישוב": "קצר א-סר                 ",
            "שם_ישוב_לועזי": "                         ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 68,
            "שם_מועצה": "נווה מדבר"
        },
        {
            "_id": 1085,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4100,
            "שם_ישוב": "קצרין                    ",
            "שם_ישוב_לועזי": "QAZRIN                   ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1086,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2620,
            "שם_ישוב": "קרית אונו                ",
            "שם_ישוב_לועזי": "QIRYAT ONO               ",
            "סמל_נפה": 52,
            "שם_נפה": "רמת גן              ",
            "סמל_לשכת_מנא": 52,
            "לשכה": "רמת גן                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1087,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3611,
            "שם_ישוב": "קרית ארבע                ",
            "שם_ישוב_לועזי": "QIRYAT ARBA              ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1088,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 6800,
            "שם_ישוב": "קרית אתא                 ",
            "שם_ישוב_לועזי": "QIRYAT ATTA              ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1089,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 9500,
            "שם_ישוב": "קרית ביאליק              ",
            "שם_ישוב_לועזי": "QIRYAT BIALIK            ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1090,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2630,
            "שם_ישוב": "קרית גת                  ",
            "שם_ישוב_לועזי": "QIRYAT GAT               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1091,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2300,
            "שם_ישוב": "קרית טבעון               ",
            "שם_ישוב_לועזי": "QIRYAT TIV'ON            ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1092,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 9600,
            "שם_ישוב": "קרית ים                  ",
            "שם_ישוב_לועזי": "QIRYAT YAM               ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1093,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1137,
            "שם_ישוב": "קרית יערים               ",
            "שם_ישוב_לועזי": "QIRYAT YE'ARIM           ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1094,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2039,
            "שם_ישוב": "קרית יערים)מוסד(         ",
            "שם_ישוב_לועזי": "QIRYAT YE'ARIM(INSTI     ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1095,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 8200,
            "שם_ישוב": "קרית מוצקין              ",
            "שם_ישוב_לועזי": "QIRYAT MOTZKIN           ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1096,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1034,
            "שם_ישוב": "קרית מלאכי               ",
            "שם_ישוב_לועזי": "QIRYAT MAL'AKHI          ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1097,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3746,
            "שם_ישוב": "קרית נטפים               ",
            "שם_ישוב_לועזי": "QIRYAT NETAFIM           ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 1098,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 78,
            "שם_ישוב": "קרית ענבים               ",
            "שם_ישוב_לועזי": "QIRYAT ANAVIM            ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1099,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 469,
            "שם_ישוב": "קרית עקרון               ",
            "שם_ישוב_לועזי": "QIRYAT EQRON             ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1100,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 412,
            "שם_ישוב": "קרית שלמה                ",
            "שם_ישוב_לועזי": "QIRYAT SHELOMO           ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 1101,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2800,
            "שם_ישוב": "קרית שמונה               ",
            "שם_ישוב_לועזי": "QIRYAT SHEMONA           ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1102,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3640,
            "שם_ישוב": "קרני שומרון              ",
            "שם_ישוב_לועזי": "QARNE SHOMERON           ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1103,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4006,
            "שם_ישוב": "קשת                      ",
            "שם_ישוב_לועזי": "QESHET                   ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 1104,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 543,
            "שם_ישוב": "ראמה                     ",
            "שם_ישוב_לועזי": "RAME                     ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1105,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1334,
            "שם_ישוב": "ראס אל-עין               ",
            "שם_ישוב_לועזי": "RAS AL-EIN               ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1106,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 990,
            "שם_ישוב": "ראס עלי                  ",
            "שם_ישוב_לועזי": "RAS ALI                  ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 1107,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2640,
            "שם_ישוב": "ראש העין                 ",
            "שם_ישוב_לועזי": "ROSH HAAYIN              ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1108,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 26,
            "שם_ישוב": "ראש פינה                 ",
            "שם_ישוב_לועזי": "ROSH PINNA               ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1109,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3602,
            "שם_ישוב": "ראש צורים                ",
            "שם_ישוב_לועזי": "ROSH ZURIM               ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 1110,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 8300,
            "שם_ישוב": "ראשון לציון              ",
            "שם_ישוב_לועזי": "RISHON LEZIYYON          ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 46,
            "לשכה": "ראשון לציון              ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1111,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3795,
            "שם_ישוב": "רבבה                     ",
            "שם_ישוב_לועזי": "REVAVA                   ",
            "סמל_נפה": 72,
            "שם_נפה": "שכם                 ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 1112,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 564,
            "שם_ישוב": "רבדים                    ",
            "שם_ישוב_לועזי": "REVADIM                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 1113,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 354,
            "שם_ישוב": "רביבים                   ",
            "שם_ישוב_לועזי": "REVIVIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 1114,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1225,
            "שם_ישוב": "רביד                     ",
            "שם_ישוב_לועזי": "RAVID                    ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 1115,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 390,
            "שם_ישוב": "רגבה                     ",
            "שם_ישוב_לועזי": "REGBA                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 1116,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 444,
            "שם_ישוב": "רגבים                    ",
            "שם_ישוב_לועזי": "REGAVIM                  ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 1117,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1161,
            "שם_ישוב": "רהט                      ",
            "שם_ישוב_לועזי": "RAHAT                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1118,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2051,
            "שם_ישוב": "רווחה                    ",
            "שם_ישוב_לועזי": "REWAHA                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 1119,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2016,
            "שם_ישוב": "רוויה                    ",
            "שם_ישוב_לועזי": "REWAYA                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 1120,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1341,
            "שם_ישוב": "רוח מדבר                 ",
            "שם_ישוב_לועזי": "RUAH MIDBAR              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 1121,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 362,
            "שם_ישוב": "רוחמה                    ",
            "שם_ישוב_לועזי": "RUHAMA                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 37,
            "שם_מועצה": "שער הנגב"
        },
        {
            "_id": 1122,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 539,
            "שם_ישוב": "רומאנה                   ",
            "שם_ישוב_לועזי": "RUMMANE                  ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 65,
            "שם_מועצה": "אל-בטוף"
        },
        {
            "_id": 1123,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 997,
            "שם_ישוב": "רומת הייב                ",
            "שם_ישוב_לועזי": "RUMAT HEIB               ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 65,
            "שם_מועצה": "אל-בטוף"
        },
        {
            "_id": 1124,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3619,
            "שם_ישוב": "רועי                     ",
            "שם_ישוב_לועזי": "RO'I                     ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 1125,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3782,
            "שם_ישוב": "רותם                     ",
            "שם_ישוב_לועזי": "ROTEM                    ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 1126,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 854,
            "שם_ישוב": "רחוב                     ",
            "שם_ישוב_לועזי": "REHOV                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 1127,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 8400,
            "שם_ישוב": "רחובות                   ",
            "שם_ישוב_לועזי": "REHOVOT                  ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1128,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3822,
            "שם_ישוב": "רחלים                    ",
            "שם_ישוב_לועזי": "REHELIM                  ",
            "סמל_נפה": 72,
            "שם_נפה": "שכם                 ",
            "סמל_לשכת_מנא": 48,
            "לשכה": "אריאל                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 1129,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 540,
            "שם_ישוב": "ריחאניה                  ",
            "שם_ישוב_לועזי": "REIHANIYYE               ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 1130,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3568,
            "שם_ישוב": "ריחן                     ",
            "שם_ישוב_לועזי": "REHAN                    ",
            "סמל_נפה": 71,
            "שם_נפה": "ג'נין               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 1131,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 542,
            "שם_ישוב": "ריינה                    ",
            "שם_ישוב_לועזי": "REINE                    ",
            "סמל_נפה": 25,
            "שם_נפה": "נצרת                ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1132,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3565,
            "שם_ישוב": "רימונים                  ",
            "שם_ישוב_לועזי": "RIMMONIM                 ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 1133,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 616,
            "שם_ישוב": "רינתיה                   ",
            "שם_ישוב_לועזי": "RINNATYA                 ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 42,
            "לשכה": "פתח תקוה                 ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 1134,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 922,
            "שם_ישוב": "רכסים                    ",
            "שם_ישוב_לועזי": "REKHASIM                 ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1135,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1069,
            "שם_ישוב": "רם-און                   ",
            "שם_ישוב_לועזי": "RAM-ON                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 1136,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4702,
            "שם_ישוב": "רמות                     ",
            "שם_ישוב_לועזי": "RAMOT                    ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 1137,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 206,
            "שם_ישוב": "רמות השבים               ",
            "שם_ישוב_לועזי": "RAMOT HASHAVIM           ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 1138,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 735,
            "שם_ישוב": "רמות מאיר                ",
            "שם_ישוב_לועזי": "RAMOT ME'IR              ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 1139,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 445,
            "שם_ישוב": "רמות מנשה                ",
            "שם_ישוב_לועזי": "RAMOT MENASHE            ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 1140,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 372,
            "שם_ישוב": "רמות נפתלי               ",
            "שם_ישוב_לועזי": "RAMOT NAFTALI            ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 1141,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 8500,
            "שם_ישוב": "רמלה                     ",
            "שם_ישוב_לועזי": "RAMLA                    ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1142,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 8600,
            "שם_ישוב": "רמת גן                   ",
            "שם_ישוב_לועזי": "RAMAT GAN                ",
            "סמל_נפה": 52,
            "שם_נפה": "רמת גן              ",
            "סמל_לשכת_מנא": 52,
            "לשכה": "רמת גן                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1143,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 135,
            "שם_ישוב": "רמת דוד                  ",
            "שם_ישוב_לועזי": "RAMAT DAWID              ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 1144,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 184,
            "שם_ישוב": "רמת הכובש                ",
            "שם_ישוב_לועזי": "RAMAT HAKOVESH           ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 1145,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 335,
            "שם_ישוב": "רמת השופט                ",
            "שם_ישוב_לועזי": "RAMAT HASHOFET           ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 13,
            "שם_מועצה": "מגידו"
        },
        {
            "_id": 1146,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2650,
            "שם_ישוב": "רמת השרון                ",
            "שם_ישוב_לועזי": "RAMAT HASHARON           ",
            "סמל_נפה": 51,
            "שם_נפה": "תל אביב             ",
            "סמל_לשכת_מנא": 57,
            "לשכה": "הרצליה                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1147,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 178,
            "שם_ישוב": "רמת יוחנן                ",
            "שם_ישוב_לועזי": "RAMAT YOHANAN            ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 1148,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 122,
            "שם_ישוב": "רמת ישי                  ",
            "שם_ישוב_לועזי": "RAMAT YISHAY             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1149,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4701,
            "שם_ישוב": "רמת מגשימים              ",
            "שם_ישוב_לועזי": "RAMAT MAGSHIMIM          ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 1150,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 339,
            "שם_ישוב": "רמת צבי                  ",
            "שם_ישוב_לועזי": "RAMAT ZEVI               ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 1151,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 460,
            "שם_ישוב": "רמת רזיאל                ",
            "שם_ישוב_לועזי": "RAMAT RAZI'EL            ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1152,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 127,
            "שם_ישוב": "רמת רחל                  ",
            "שם_ישוב_לועזי": "RAMAT RAHEL              ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1153,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 789,
            "שם_ישוב": "רנן                      ",
            "שם_ישוב_לועזי": "RANNEN                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 1154,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 713,
            "שם_ישוב": "רעים                     ",
            "שם_ישוב_לועזי": "RE'IM                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 1155,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 8700,
            "שם_ישוב": "רעננה                    ",
            "שם_ישוב_לועזי": "RA'ANANA                 ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1156,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1228,
            "שם_ישוב": "רקפת                     ",
            "שם_ישוב_לועזי": "RAQEFET                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1157,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 247,
            "שם_ישוב": "רשפון                    ",
            "שם_ישוב_לועזי": "RISHPON                  ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 1158,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 437,
            "שם_ישוב": "רשפים                    ",
            "שם_ישוב_לועזי": "RESHAFIM                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 1159,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1260,
            "שם_ישוב": "רתמים                    ",
            "שם_ישוב_לועזי": "RETAMIM                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 1160,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 324,
            "שם_ישוב": "שאר ישוב                 ",
            "שם_ישוב_לועזי": "SHE'AR YASHUV            ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 1161,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1377,
            "שם_ישוב": "שבי דרום                 ",
            "שם_ישוב_לועזי": "SHAVE DAROM              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 1162,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 282,
            "שם_ישוב": "שבי ציון                 ",
            "שם_ישוב_לועזי": "SHAVE ZIYYON             ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 1163,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3571,
            "שם_ישוב": "שבי שומרון               ",
            "שם_ישוב_לועזי": "SHAVE SHOMERON           ",
            "סמל_נפה": 72,
            "שם_נפה": "שכם                 ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 1164,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 913,
            "שם_ישוב": "שבלי - אום אל-גנם        ",
            "שם_ישוב_לועזי": "SHIBLI                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1165,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1286,
            "שם_ישוב": "שגב-שלום                 ",
            "שם_ישוב_לועזי": "SEGEV-SHALOM             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1166,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 721,
            "שם_ישוב": "שדה אילן                 ",
            "שם_ישוב_לועזי": "SEDE ILAN                ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 1167,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 304,
            "שם_ישוב": "שדה אליהו                ",
            "שם_ישוב_לועזי": "SEDE ELIYYAHU            ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 1168,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 861,
            "שם_ישוב": "שדה אליעזר               ",
            "שם_ישוב_לועזי": "SEDE ELI'EZER            ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 55,
            "שם_מועצה": "מבואות החרמון"
        },
        {
            "_id": 1169,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 885,
            "שם_ישוב": "שדה בוקר                 ",
            "שם_ישוב_לועזי": "SEDE BOQER               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 1170,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 36,
            "שם_ישוב": "שדה דוד                  ",
            "שם_ישוב_לועזי": "SEDE DAWID               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 1171,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 284,
            "שם_ישוב": "שדה ורבורג               ",
            "שם_ישוב_לועזי": "SEDE WARBURG             ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 1172,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 293,
            "שם_ישוב": "שדה יואב                 ",
            "שם_ישוב_לועזי": "SEDE YO'AV               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 35,
            "שם_מועצה": "יואב"
        },
        {
            "_id": 1173,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 142,
            "שם_ישוב": "שדה יעקב                 ",
            "שם_ישוב_לועזי": "SEDE YA'AQOV             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 1174,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2008,
            "שם_ישוב": "שדה יצחק                 ",
            "שם_ישוב_לועזי": "SEDE YIZHAQ              ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 1175,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 18,
            "שם_ישוב": "שדה משה                  ",
            "שם_ישוב_לועזי": "SEDE MOSHE               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 1176,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 259,
            "שם_ישוב": "שדה נחום                 ",
            "שם_ישוב_לועזי": "SEDE NAHUM               ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 1177,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 329,
            "שם_ישוב": "שדה נחמיה                ",
            "שם_ישוב_לועזי": "SEDE NEHEMYA             ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 1178,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1058,
            "שם_ישוב": "שדה ניצן                 ",
            "שם_ישוב_לועזי": "SEDE NIZZAN              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 1179,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 739,
            "שם_ישוב": "שדה עוזיהו               ",
            "שם_ישוב_לועזי": "SEDE UZZIYYAHU           ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 1180,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2049,
            "שם_ישוב": "שדה צבי                  ",
            "שם_ישוב_לועזי": "SEDE ZEVI                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 1181,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 327,
            "שם_ישוב": "שדות ים                  ",
            "שם_ישוב_לועזי": "SEDOT YAM                ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 15,
            "שם_מועצה": "חוף הכרמל"
        },
        {
            "_id": 1182,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 27,
            "שם_ישוב": "שדות מיכה                ",
            "שם_ישוב_לועזי": "SEDOT MIKHA              ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1183,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1223,
            "שם_ישוב": "שדי אברהם                ",
            "שם_ישוב_לועזי": "SEDE AVRAHAM             ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 1184,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2015,
            "שם_ישוב": "שדי חמד                  ",
            "שם_ישוב_לועזי": "SEDE HEMED               ",
            "סמל_נפה": 42,
            "שם_נפה": "פתח תקווה           ",
            "סמל_לשכת_מנא": 47,
            "לשכה": "כפר סבא                  ",
            "סמל_מועצה_איזורית": 20,
            "שם_מועצה": "דרום השרון"
        },
        {
            "_id": 1185,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2057,
            "שם_ישוב": "שדי תרומות               ",
            "שם_ישוב_לועזי": "SEDE TERUMOT             ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 1186,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 555,
            "שם_ישוב": "שדמה                     ",
            "שם_ישוב_לועזי": "SHEDEMA                  ",
            "סמל_נפה": 44,
            "שם_נפה": "רחובות              ",
            "סמל_לשכת_מנא": 44,
            "לשכה": "רחובות                   ",
            "סמל_מועצה_איזורית": 32,
            "שם_מועצה": "גדרות"
        },
        {
            "_id": 1187,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 306,
            "שם_ישוב": "שדמות דבורה              ",
            "שם_ישוב_לועזי": "SHADMOT DEVORA           ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 1188,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3578,
            "שם_ישוב": "שדמות מחולה              ",
            "שם_ישוב_לועזי": "SHADMOT MEHOLA           ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 1189,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1031,
            "שם_ישוב": "שדרות                    ",
            "שם_ישוב_לועזי": "SEDEROT                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1190,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 741,
            "שם_ישוב": "שואבה                    ",
            "שם_ישוב_לועזי": "SHO'EVA                  ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1191,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 761,
            "שם_ישוב": "שובה                     ",
            "שם_ישוב_לועזי": "SHUVA                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 1192,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 394,
            "שם_ישוב": "שובל                     ",
            "שם_ישוב_לועזי": "SHOVAL                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 1193,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1304,
            "שם_ישוב": "שוהם                     ",
            "שם_ישוב_לועזי": "SHOHAM                   ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1194,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 614,
            "שם_ישוב": "שומרה                    ",
            "שם_ישוב_לועזי": "SHOMERA                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 1195,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1265,
            "שם_ישוב": "שומריה                   ",
            "שם_ישוב_לועזי": "SHOMERIYYA               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 1196,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 415,
            "שם_ישוב": "שוקדה                    ",
            "שם_ישוב_לועזי": "SHOQEDA                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 1197,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 456,
            "שם_ישוב": "שורש                     ",
            "שם_ישוב_לועזי": "SHORESH                  ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1198,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1235,
            "שם_ישוב": "שורשים                   ",
            "שם_ישוב_לועזי": "SHORASHIM                ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1199,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 224,
            "שם_ישוב": "שושנת העמקים             ",
            "שם_ישוב_לועזי": "SHOSHANNAT HAAMAQIM      ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 16,
            "שם_מועצה": "עמק חפר"
        },
        {
            "_id": 1200,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 527,
            "שם_ישוב": "שזור                     ",
            "שם_ישוב_לועזי": "SHEZOR                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 1201,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 7,
            "שם_ישוב": "שחר                      ",
            "שם_ישוב_לועזי": "SHAHAR                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 1202,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1266,
            "שם_ישוב": "שחרות                    ",
            "שם_ישוב_לועזי": "SHAHARUT                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 1203,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 865,
            "שם_ישוב": "שיבולים                  ",
            "שם_ישוב_לועזי": "SHIBBOLIM                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 1204,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1267,
            "שם_ישוב": "שיטים                    ",
            "שם_ישוב_לועזי": "NAHAL SHITTIM            ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 63,
            "לשכה": "אילת                     ",
            "סמל_מועצה_איזורית": 53,
            "שם_מועצה": "חבל אילות"
        },
        {
            "_id": 1205,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 658,
            "שם_ישוב": "שייח' דנון               ",
            "שם_ישוב_לועזי": "SHEIKH DANNUN            ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 1206,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3641,
            "שם_ישוב": "שילה                     ",
            "שם_ישוב_לועזי": "SHILO                    ",
            "סמל_נפה": 74,
            "שם_נפה": "ראמאללה             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 73,
            "שם_מועצה": "מטה בנימין"
        },
        {
            "_id": 1207,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1165,
            "שם_ישוב": "שילת                     ",
            "שם_ישוב_לועזי": "SHILAT                   ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 25,
            "שם_מועצה": "חבל מודיעין"
        },
        {
            "_id": 1208,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1160,
            "שם_ישוב": "שכניה                    ",
            "שם_ישוב_לועזי": "SHEKHANYA                ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1209,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 873,
            "שם_ישוב": "שלווה                    ",
            "שם_ישוב_לועזי": "SHALWA                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 1210,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1373,
            "שם_ישוב": "שלווה במדבר              ",
            "שם_ישוב_לועזי": "SHALVA BAMIDBAR          ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 48,
            "שם_מועצה": "רמת נגב"
        },
        {
            "_id": 1211,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 439,
            "שם_ישוב": "שלוחות                   ",
            "שם_ישוב_לועזי": "SHELUHOT                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 1212,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 812,
            "שם_ישוב": "שלומי                    ",
            "שם_ישוב_לועזי": "SHELOMI                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1213,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1364,
            "שם_ישוב": "שלומית                   ",
            "שם_ישוב_לועזי": "SHLOMIT                  ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 1214,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 366,
            "שם_ישוב": "שמיר                     ",
            "שם_ישוב_לועזי": "SHAMIR                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 1215,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3784,
            "שם_ישוב": "שמעה                     ",
            "שם_ישוב_לועזי": "SHIM'A                   ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 1216,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 432,
            "שם_ישוב": "שמרת                     ",
            "שם_ישוב_לועזי": "SHAMERAT                 ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 4,
            "שם_מועצה": "מטה אשר"
        },
        {
            "_id": 1217,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1337,
            "שם_ישוב": "שמשית                    ",
            "שם_ישוב_לועזי": "SHIMSHIT                 ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 1218,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1287,
            "שם_ישוב": "שני                      ",
            "שם_ישוב_לועזי": "SHANI                    ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 1219,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1132,
            "שם_ישוב": "שניר                     ",
            "שם_ישוב_לועזי": "SENIR                    ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 1,
            "שם_מועצה": "הגליל העליון"
        },
        {
            "_id": 1220,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 538,
            "שם_ישוב": "שעב                      ",
            "שם_ישוב_לועזי": "SHA'AB                   ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1221,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1299,
            "שם_ישוב": "שעורים                   ",
            "שם_ישוב_לועזי": "SE'ORIM                  ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 1222,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 4009,
            "שם_ישוב": "שעל                      ",
            "שם_ישוב_לועזי": "SHA'AL                   ",
            "סמל_נפה": 29,
            "שם_נפה": "גולן                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 71,
            "שם_מועצה": "גולן"
        },
        {
            "_id": 1223,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 856,
            "שם_ישוב": "שעלבים                   ",
            "שם_ישוב_לועזי": "SHA'ALVIM                ",
            "סמל_נפה": 43,
            "שם_נפה": "רמלה                ",
            "סמל_לשכת_מנא": 43,
            "לשכה": "רמלה                     ",
            "סמל_מועצה_איזורית": 30,
            "שם_מועצה": "גזר"
        },
        {
            "_id": 1224,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 661,
            "שם_ישוב": "שער אפרים                ",
            "שם_ישוב_לועזי": "SHA'AR EFRAYIM           ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 1225,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 264,
            "שם_ישוב": "שער הגולן                ",
            "שם_ישוב_לועזי": "SHA'AR HAGOLAN           ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 1226,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 237,
            "שם_ישוב": "שער העמקים               ",
            "שם_ישוב_לועזי": "SHA'AR HAAMAQIM          ",
            "סמל_נפה": 31,
            "שם_נפה": "חיפה                ",
            "סמל_לשכת_מנא": 31,
            "לשכה": "חיפה                     ",
            "סמל_מועצה_איזורית": 12,
            "שם_מועצה": "זבולון"
        },
        {
            "_id": 1227,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 921,
            "שם_ישוב": "שער מנשה                 ",
            "שם_ישוב_לועזי": "SHA'AR MENASHE           ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 1228,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3720,
            "שם_ישוב": "שערי תקווה               ",
            "שם_ישוב_לועזי": "SHA'ARE TIQWA            ",
            "סמל_נפה": 73,
            "שם_נפה": "טול כרם             ",
            "סמל_לשכת_מנא": 349,
            "לשכה": "ראש העין                 ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 1229,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 232,
            "שם_ישוב": "שפיים                    ",
            "שם_ישוב_לועזי": "SHEFAYIM                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 1230,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 692,
            "שם_ישוב": "שפיר                     ",
            "שם_ישוב_לועזי": "SHAFIR                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 34,
            "שם_מועצה": "שפיר"
        },
        {
            "_id": 1231,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 846,
            "שם_ישוב": "שפר                      ",
            "שם_ישוב_לועזי": "SHEFER                   ",
            "סמל_נפה": 21,
            "שם_נפה": "צפת                 ",
            "סמל_לשכת_מנא": 21,
            "לשכה": "צפת                      ",
            "סמל_מועצה_איזורית": 2,
            "שם_מועצה": "מרום הגליל"
        },
        {
            "_id": 1232,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 8800,
            "שם_ישוב": "שפרעם                    ",
            "שם_ישוב_לועזי": "SHEFAR'AM                ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 25,
            "לשכה": "נצרת עילית               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1233,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3649,
            "שם_ישוב": "שקד                      ",
            "שם_ישוב_לועזי": "SHAQED                   ",
            "סמל_נפה": 71,
            "שם_נפה": "ג'נין               ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 72,
            "שם_מועצה": "שומרון"
        },
        {
            "_id": 1234,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1233,
            "שם_ישוב": "שקף                      ",
            "שם_ישוב_לועזי": "SHEQEF                   ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 1235,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 292,
            "שם_ישוב": "שרונה                    ",
            "שם_ישוב_לועזי": "SHARONA                  ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 3,
            "שם_מועצה": "הגליל התחתון"
        },
        {
            "_id": 1236,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1114,
            "שם_ישוב": "שריגים )לי-און(          ",
            "שם_ישוב_לועזי": "LI-ON                    ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1237,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 126,
            "שם_ישוב": "שריד                     ",
            "שם_ישוב_לועזי": "SARID                    ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 1238,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 398,
            "שם_ישוב": "שרשרת                    ",
            "שם_ישוב_לועזי": "SHARSHERET               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 1239,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1045,
            "שם_ישוב": "שתולה                    ",
            "שם_ישוב_לועזי": "SHETULA                  ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 24,
            "לשכה": "עכו                      ",
            "סמל_מועצה_איזורית": 52,
            "שם_מועצה": "מעלה יוסף"
        },
        {
            "_id": 1240,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 763,
            "שם_ישוב": "שתולים                   ",
            "שם_ישוב_לועזי": "SHETULIM                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 1241,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2062,
            "שם_ישוב": "תאשור                    ",
            "שם_ישוב_לועזי": "TE'ASHUR                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 1242,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2061,
            "שם_ישוב": "תדהר                     ",
            "שם_ישוב_לועזי": "TIDHAR                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 41,
            "שם_מועצה": "בני שמעון"
        },
        {
            "_id": 1243,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1172,
            "שם_ישוב": "תובל                     ",
            "שם_ישוב_לועזי": "TUVAL                    ",
            "סמל_נפה": 24,
            "שם_נפה": "עכו                 ",
            "סמל_לשכת_מנא": 20,
            "לשכה": "כרמיאל                   ",
            "סמל_מועצה_איזורית": 56,
            "שם_מועצה": "משגב"
        },
        {
            "_id": 1244,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3558,
            "שם_ישוב": "תומר                     ",
            "שם_ישוב_לועזי": "TOMER                    ",
            "סמל_נפה": 75,
            "שם_נפה": "ירדן )יריחו(        ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 75,
            "שם_מועצה": "ערבות הירדן"
        },
        {
            "_id": 1245,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1083,
            "שם_ישוב": "תושיה                    ",
            "שם_ישוב_לועזי": "TUSHIYYA                 ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 1246,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 163,
            "שם_ישוב": "תימורים                  ",
            "שם_ישוב_לועזי": "TIMMORIM                 ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 1247,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 10,
            "שם_ישוב": "תירוש                    ",
            "שם_ישוב_לועזי": "TIROSH                   ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1248,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 5000,
            "שם_ישוב": "תל אביב - יפו            ",
            "שם_ישוב_לועזי": "TEL AVIV - YAFO          ",
            "סמל_נפה": 51,
            "שם_נפה": "תל אביב             ",
            "סמל_לשכת_מנא": 51,
            "לשכה": "ת\"א - מרכז               ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1249,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 84,
            "שם_ישוב": "תל יוסף                  ",
            "שם_ישוב_לועזי": "TEL YOSEF                ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 8,
            "שם_מועצה": "הגלבוע"
        },
        {
            "_id": 1250,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 287,
            "שם_ישוב": "תל יצחק                  ",
            "שם_ישוב_לועזי": "TEL YIZHAQ               ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 19,
            "שם_מועצה": "חוף השרון"
        },
        {
            "_id": 1251,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 154,
            "שם_ישוב": "תל מונד                  ",
            "שם_ישוב_לועזי": "TEL MOND                 ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1252,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 103,
            "שם_ישוב": "תל עדשים                 ",
            "שם_ישוב_לועזי": "TEL ADASHIM              ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 1253,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 719,
            "שם_ישוב": "תל קציר                  ",
            "שם_ישוב_לועזי": "TEL QAZIR                ",
            "סמל_נפה": 22,
            "שם_נפה": "כנרת                ",
            "סמל_לשכת_מנא": 22,
            "לשכה": "טבריה                    ",
            "סמל_מועצה_איזורית": 6,
            "שם_מועצה": "עמק הירדן"
        },
        {
            "_id": 1254,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1054,
            "שם_ישוב": "תל שבע                   ",
            "שם_ישוב_לועזי": "TEL SHEVA                ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1255,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1283,
            "שם_ישוב": "תל תאומים                ",
            "שם_ישוב_לועזי": "TEL TE'OMIM              ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 7,
            "שם_מועצה": "עמק המעיינות"
        },
        {
            "_id": 1256,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3719,
            "שם_ישוב": "תלם                      ",
            "שם_ישוב_לועזי": "TELEM                    ",
            "סמל_נפה": 77,
            "שם_נפה": "חברון               ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 78,
            "שם_מועצה": "הר חברון"
        },
        {
            "_id": 1257,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1051,
            "שם_ישוב": "תלמי אליהו               ",
            "שם_ישוב_לועזי": "TALME ELIYYAHU           ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 1258,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2003,
            "שם_ישוב": "תלמי אלעזר               ",
            "שם_ישוב_לועזי": "TALME EL'AZAR            ",
            "סמל_נפה": 32,
            "שם_נפה": "חדרה                ",
            "סמל_לשכת_מנא": 32,
            "לשכה": "חדרה                     ",
            "סמל_מועצה_איזורית": 14,
            "שם_מועצה": "מנשה"
        },
        {
            "_id": 1259,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2050,
            "שם_ישוב": "תלמי ביל\"ו               ",
            "שם_ישוב_לועזי": "TALME BILU               ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 1260,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1237,
            "שם_ישוב": "תלמי יוסף                ",
            "שם_ישוב_לועזי": "TALME YOSEF              ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 38,
            "שם_מועצה": "אשכול"
        },
        {
            "_id": 1261,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 727,
            "שם_ישוב": "תלמי יחיאל               ",
            "שם_ישוב_לועזי": "TALME YEHI'EL            ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 33,
            "שם_מועצה": "באר טוביה"
        },
        {
            "_id": 1262,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 744,
            "שם_ישוב": "תלמי יפה                 ",
            "שם_ישוב_לועזי": "TALME YAFE               ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 36,
            "שם_מועצה": "חוף אשקלון"
        },
        {
            "_id": 1263,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 814,
            "שם_ישוב": "תלמים                    ",
            "שם_ישוב_לועזי": "TELAMIM                  ",
            "סמל_נפה": 61,
            "שם_נפה": "אשקלון              ",
            "סמל_לשכת_מנא": 61,
            "לשכה": "אשקלון                   ",
            "סמל_מועצה_איזורית": 50,
            "שם_מועצה": "לכיש"
        },
        {
            "_id": 1264,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1244,
            "שם_ישוב": "תמרת                     ",
            "שם_ישוב_לועזי": "TIMRAT                   ",
            "סמל_נפה": 23,
            "שם_נפה": "עפולה               ",
            "סמל_לשכת_מנא": 23,
            "לשכה": "עפולה                    ",
            "סמל_מועצה_איזורית": 9,
            "שם_מועצה": "עמק יזרעאל"
        },
        {
            "_id": 1265,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 2002,
            "שם_ישוב": "תנובות                   ",
            "שם_ישוב_לועזי": "TENUVOT                  ",
            "סמל_נפה": 41,
            "שם_נפה": "השרון               ",
            "סמל_לשכת_מנא": 41,
            "לשכה": "נתניה                    ",
            "סמל_מועצה_איזורית": 18,
            "שם_מועצה": "לב השרון"
        },
        {
            "_id": 1266,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 752,
            "שם_ישוב": "תעוז                     ",
            "שם_ישוב_לועזי": "TA'OZ                    ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        },
        {
            "_id": 1267,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 709,
            "שם_ישוב": "תפרח                     ",
            "שם_ישוב_לועזי": "TIFRAH                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 42,
            "שם_מועצה": "מרחבים"
        },
        {
            "_id": 1268,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 665,
            "שם_ישוב": "תקומה                    ",
            "שם_ישוב_לועזי": "TEQUMA                   ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 39,
            "שם_מועצה": "שדות נגב"
        },
        {
            "_id": 1269,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 3563,
            "שם_ישוב": "תקוע                     ",
            "שם_ישוב_לועזי": "TEQOA                    ",
            "סמל_נפה": 76,
            "שם_נפה": "בית לחם             ",
            "סמל_לשכת_מנא": 11,
            "לשכה": "ירושלים                  ",
            "סמל_מועצה_איזורית": 76,
            "שם_מועצה": "גוש עציון"
        },
        {
            "_id": 1270,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 970,
            "שם_ישוב": "תראבין א-צאנע )שבט(      ",
            "שם_ישוב_לועזי": "TARABIN AS-SANI          ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 0,
            "שם_מועצה": ""
        },
        {
            "_id": 1271,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 1346,
            "שם_ישוב": "תראבין א-צאנע)ישוב(      ",
            "שם_ישוב_לועזי": "TARABIN AS-SANI          ",
            "סמל_נפה": 62,
            "שם_נפה": "באר שבע             ",
            "סמל_לשכת_מנא": 62,
            "לשכה": "באר שבע                  ",
            "סמל_מועצה_איזורית": 69,
            "שם_מועצה": "אל קסום"
        },
        {
            "_id": 1272,
            "טבלה": "טבלת ישובים    ",
            "סמל_ישוב": 778,
            "שם_ישוב": "תרום                     ",
            "שם_ישוב_לועזי": "TARUM                    ",
            "סמל_נפה": 11,
            "שם_נפה": "ירושלים             ",
            "סמל_לשכת_מנא": 13,
            "לשכה": "בית שמש                  ",
            "סמל_מועצה_איזורית": 26,
            "שם_מועצה": "מטה יהודה"
        }
    ],
        "fields": [
        {
            "id": "_id",
            "type": "int"
        },
        {
            "id": "טבלה",
            "type": "text"
        },
        {
            "id": "סמל_ישוב",
            "type": "numeric"
        },
        {
            "id": "שם_ישוב",
            "type": "text"
        },
        {
            "id": "שם_ישוב_לועזי",
            "type": "text"
        },
        {
            "id": "סמל_נפה",
            "type": "numeric"
        },
        {
            "id": "שם_נפה",
            "type": "text"
        },
        {
            "id": "סמל_לשכת_מנא",
            "type": "numeric"
        },
        {
            "id": "לשכה",
            "type": "text"
        },
        {
            "id": "סמל_מועצה_איזורית",
            "type": "numeric"
        },
        {
            "id": "שם_מועצה",
            "type": "text"
        }
    ],
        "_links": {
        "start": "/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000",
            "next": "/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&offset=32000"
    },
    "total": 1272,
        "total_was_estimated": false
}
}