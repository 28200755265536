import React, { useState, useEffect } from 'react';

export const Proof = ({ type, data, setData }) => {
  const [alignment, setAlignment] = useState('');

  useEffect(() => {
    const { returnMethod, collectionAddress } = data;
    //fix for locates proof page
    if (!data.collectionMethod && !returnMethod) {
      setData({ ...data, returnMethod: 'delivery' });
      setAlignment('right')
    }
    else if (data.collectionMethod && !returnMethod) {
      const returnAddressByCollectAddress = collectionAddress ? {
        returnCity: data.collectionAddress.collectCity,
        returnStreetAddress: data.collectionAddress.collectStreetAddress,
        returnStreetNumber: data.collectionAddress.collectStreetNumber,
        floor: data.collectionAddress.floor,
        apartment: data.collectionAddress.apartment,
        returnAddressName: data.collectionAddress.collectAddressName,
        returnNotes: data.collectionAddress.collectNotes,
      } : null
      if (data.collectionMethod === 'pickup') {
        setData({
          ...data,
          returnMethod: 'delivery',
          returnAddress: returnAddressByCollectAddress,
        });
      } else {
        setData({
          ...data,
          returnMethod: 'upload',
        });
      }
      setAlignment(data.collectionMethod === 'pickup' ? 'right' : 'left');
    }
    else {
      setAlignment(returnMethod === 'delivery' ? 'right' : 'left')
    }
  }, []);

  const handleChangeData = (event, newAlignment) => {
    event.preventDefault();
    setAlignment(newAlignment);
    const { name, value } = event.target;
    if (value === 'upload') delete data.returnAddress;
    let address = { [name]: value }
    if (data.collectionMethod === 'pickup' && value === 'delivery') {
      const returnAddressFromCollectAddress = {
        returnCity: data.collectionAddress.collectCity,
        returnStreetAddress: data.collectionAddress.collectStreetAddress,
        returnStreetNumber: data.collectionAddress.collectStreetNumber,
        floor: data.collectionAddress.floor,
        apartment: data.collectionAddress.apartment,
        returnAddressName: data.collectionAddress.collectAddressName,
        returnNotes: data.collectionAddress.collectNotes,
      }
      address = { ...address, returnAddress: returnAddressFromCollectAddress }
    }
    setData({
      ...data,
      ...address
    });
  };

  const optionA = 'החזרה עם שליח';
  const optionB = 'שליחה למייל';

  return (
    <div className='main-form'>
      <div className='modal-content-container block'>
        <h2 className='large-title'>כיצד תעדיפו לקבל את  {!(type === 'personLocate') ? 'הוכחת הביצוע' : 'השאילתא'}?</h2>
        <p className='subtitle'>
          {/* {(type === 'personLocate') ? 'השאילתא ' : 'הוכחת הביצוע, '} */}
          החזרה באמצעות שליח או סריקה ושליחה למייל
        </p>

        <div className={`form-main-toggle ${alignment}`}>
          <span></span>
          <button
            name='returnMethod'
            value={'delivery'}
            onClick={(ev) => handleChangeData(ev, 'right')}
          >
            {optionA}
          </button>
          <button
            name='returnMethod'
            value={'upload'}
            onClick={(ev) => handleChangeData(ev, 'left')}
          >
            {optionB}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Proof;
