import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import SearchIcon from "../../images/Search Icon.svg";
import { useViewport } from '../../Hooks/useViewport'
import { onCallNotifications } from '../../integrations/firebase';

export const UserOrdersSearch = ({ filterBy, setFilterBy, isFromFiltersModal }) => {
    const activeUser = useSelector((state) => state.userReducer.user);
    const [searchTerm, setSearchTerm] = useState("");

    const { width } = useViewport();
    const breakpoint = 767;
    const isMobile = width < breakpoint;

    const handleChange = (ev) => {
        const search = ev.target.value
        setSearchTerm(search);
        if (containsHeb(search)) {
            setFilterBy((prevFilterBy) => ({
                ...prevFilterBy,
                extraSearch: search,
                orderId: ''
            }));
        } else {
            setFilterBy((prevFilterBy) => ({
                ...prevFilterBy,
                orderId: search,
                extraSearch: ''
            }));
        }
    };

    const containsHeb = (str) => {
        return (/[\u0590-\u05FF]/).test(str);
    }

    //For Development check
    // const onAddNotification = async () => {
    //     const objToSend = {
    //         userId: activeUser.uid,
    //         type: 'addNotification'
    //     }
    //     await onCallNotifications(objToSend)
    // }

    // const onRemoveNotificationInteraction = async () => {
    //     const objToSend = {
    //         userId: activeUser.uid,
    //         companyId: activeUser.companyId,
    //         type: 'removeNotificationInteraction'
    //     }
    //     await onCallNotifications(objToSend)
    // }

    return (
        <div className='title-search-container'>
            {!isFromFiltersModal &&
                <div>
                    <h3 className="title in-progress">הזמנות בתהליך</h3>
                    <p className='s-title'>לחצו על ההזמנה לקבלת פרטים נוספים</p>
                </div>
            }
            {(!isMobile || (isMobile && isFromFiltersModal)) &&
                <div className="search-field">
                    <input
                        type="text"
                        className="search-input"
                        placeholder="חיפוש הזמנה"
                        value={searchTerm}
                        onChange={handleChange}
                    />
                    <img src={SearchIcon} alt="SearchIcon" />
                </div>
            }

        </div>
    )
}
