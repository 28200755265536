const INITIAL_STATE = {
    systemData: {
        isEditMode: false,
        isCheckout: false,
        isPopUpModalOrder: false,
        isFormModalOpen: false,
        isSaveContact: false,
        isSaveContactSecond: false,
        isTestUser: true,
        formId: 0,
    },
    contactToSave: {
        id: '',
        name: '',
        phone: '',
        managerName: '',
        city: '',
        streetAddress: '',
        streetNumber: '',
        comments: '',
        floor: '',
        apartmentNumber: '',
        isAddressNotFound: false,
        type: 'ADAM_PRATI',
    },
    secondContactToSave: {
        id: '',
        name: '',
        phone: '',
        managerName: '',
        city: '',
        streetAddress: '',
        streetNumber: '',
        comments: '',
        floor: '',
        apartmentNumber: '',
        isAddressNotFound: false,
        type: 'ADAM_PRATI',
    },
    isPopUpModal: {
        errorText: '',
        title: '',
        submitText: '',
        status: ''
    },
    isLoading: false,

}
export function systemReducer(state = INITIAL_STATE, action) {
    let newState = state;
    switch (action.type) {
        case 'UPDATE_DATA_SYSTEM_DATA':
            newState = {
                ...state,
                systemData: { ...state.systemData, ...action.systemData }
            }
            break;

        case 'UPDATE_SYSTEM__POPUP_MODAL':
            newState = {
                ...state,
                isPopUpModal: action.isPopUpModal
            }
            break;

        case 'SET_IS_LOADING':
            newState = {
                ...state,
                isLoading: action.isLoading
            }
            break;

        case 'UPDATE_CONTACT_TO_SAVE':
            newState = {
                ...state,
                contactToSave: action.contactToSave
            }
            break;
        case 'UPDATE_SECOND_CONTACT_TO_SAVE':
            newState = {
                ...state,
                secondContactToSave: action.secondContactToSave
            }
            break;


        default:
            newState = state
            break;
    }
    // For debug:
    window.system = newState;
    return newState
}