import React from 'react';
import { useRef } from 'react';

// imports for forms
import { Form1 } from './Form1/Form1';
import { Form2 } from './Form2/Form2';
import { Form3 } from './Form3/Form3';
import { Form4 } from './Form4/Form4';
import { Form6 } from './Form6/Form6';
import { Form7 } from './Form7/Form7';
import { Form8 } from './Form8/Form8';
import { Form9 } from './Form9/Form9';
import { Form10 } from './Form10/Form10';
import { Form11 } from './Form11/Form11';
import { Form13 } from './Form13/Form13';
import { Form16 } from './Form16/Form16';
import { Form17 } from './Form17/Form17';
import { Form18 } from './Form18/Form18';
import { Form19 } from './Form19/Form19';
import { Form20 } from './Form20/Form20';
import { Form21 } from './Form21/Form21';
import { Form22 } from './Form22/Form22';
import { useParams } from 'react-router-dom';

export const FormsMain = () => {

  const { formId } = useParams();

  const cmpRef = useRef();

  const scrollTo = (direction) => {
    const div = cmpRef.current;
    div.scrollTo({
      top: direction === 'down' ? div.scrollHeight : 0,
      behavior: 'smooth'
    });
  };



  const Forms = (formId) => {
    switch (formId) {
      case 'address-locate':
        return <Form18 type='addressLocate' progressBarPhases={['מאותרים', 'סיכום']} scrollTo={scrollTo} />;
      case 'ID-locate':
        return <Form17 type='idLocate' progressBarPhases={['מאותרים', 'סיכום']} scrollTo={scrollTo} />;
      case 'phone-locate':
        return <Form6 type='phoneLocate' progressBarPhases={['מאותרים', 'סיכום']} scrollTo={scrollTo} />;
      case 'file-to-court':
        return <Form7 type='courtSubmission' progressBarPhases={['פרטי ההגשה', 'מסירות', 'איך מקבלים', 'איך מחזירים']} scrollTo={scrollTo} />;
      case 'person-locate':
        return <Form16 type='personLocate' progressBarPhases={['מאותרים', 'איך מחזירים', 'דחיפות']} scrollTo={scrollTo} />;
      case 'apostille':
        return <Form8 type='apostilSubmission' progressBarPhases={['בחירת תעודות', 'איך מקבלים', 'איך מחזירים', 'תרגום', 'דחיפות']} scrollTo={scrollTo} />;
      case 'service-of-process':
        return <Form1 type='personalDelivery' progressBarPhases={['נמענים', 'מה מוסרים', 'איך מקבלים', 'איך מחזירים']} scrollTo={scrollTo} />;
      case 'office-delivery':
        return <Form9 type='officeDelivery' progressBarPhases={['משרדים', 'מה מוסרים', 'איך מקבלים', 'איך מחזירים']} scrollTo={scrollTo} />;
      case 'land-registration':
        return <Form13 type='landRegistration' progressBarPhases={['הלשכה', 'פרטי התיק', 'הכתובת שלך', 'דחיפות']} scrollTo={scrollTo} />;
      case 'inheritance-registration':
        return <Form11 type='inheritanceRegistration' progressBarPhases={['הלשכה', 'מה מגישים', 'איך מקבלים', 'איך מחזירים', 'דחיפות']} scrollTo={scrollTo} />;
      case 'document-transfer':
        return <Form19 type='documentTransfer' progressBarPhases={['פרטי השליחות', 'דחיפות']} scrollTo={scrollTo} />;
      case 'companies-registration':
        return <Form10 type='companiesRegistration' progressBarPhases={['הלשכה', 'מה מגישים', 'איך מקבלים', 'איך מחזירים', 'דחיפות']} scrollTo={scrollTo} />;
      case 'partnerships-registration':
        return <Form2 type='partnershipsRegistration' progressBarPhases={['הלשכה', 'מה מגישים', 'איך מקבלים', 'איך מחזירים', 'דחיפות']} scrollTo={scrollTo} />;
      case 'associations-and-companies':
        return <Form4 type='associationsAndCompanies' progressBarPhases={['הלשכה', 'מה מגישים', 'איך מקבלים', 'איך מחזירים', 'דחיפות']} scrollTo={scrollTo} />;
      case 'filing-a-small-claim':
        return <Form20 type='smallClaim' progressBarPhases={['הצדדים לתביעה', 'תיאור התביעה', 'אישור וחתימה', 'סיכום הזמנה']} scrollTo={scrollTo} />;
      case 'apostille-in-court':
        return <Form3 type='apostilInCourt' progressBarPhases={['בחירת תעודות', 'איך מקבלים', 'איך מחזירים', 'תרגום', 'דחיפות']} scrollTo={scrollTo} />;
      case 'printing_binding':
        return <Form21 type='printingBinding' progressBarPhases={['מאפייני ההדפסה', 'איך מקבלים', 'דחיפות']} scrollTo={scrollTo} />;
      case 'land_supervisor':
        return <Form22 type='landSupervisor' progressBarPhases={['הלשכה', 'פרטי התיק', 'הכתובת שלך', 'דחיפות']} scrollTo={scrollTo} />;

      default:
        return <></>;
    }
  };

  return (
    <div className="modal-main-content" ref={cmpRef}>
      {Forms(formId)}
    </div>
  );
};
