
export function TooltipTriangle({ text = 'טולטיפ', link, className, children, unsetIdiv }) {
    return (
        <div className={`tooltip ${className || ''}`}>
            {children}
            <p className="top">
                {text}{link && <> <br />
                    <a href={link} style={{ color: '#2196f3', textDecoration: 'underline' }} target="_blank" rel="noopener noreferrer">לצפייה בקישור</a>
                    {!unsetIdiv && <i></i>}
                </>}
                <div className="triangle" />
            </p>
        </div>
    )
}