import React, { useState, useEffect } from "react";
import CommonAction from "../../Actions/CommonAction/CommonAction";
import FavoriteAction from "../../Actions/CommonAction/FavoriteAction";
import AllActions from "../../Actions/AllActions/AllAction";
import HomeCover from "./HomeCover";
import { actionsSite } from '../../../consts/data'
import { Outlet } from "react-router";

//For add/remove fav actions
import { useSelector, useDispatch } from 'react-redux'
import { doc, updateDoc } from "@firebase/firestore";
import db, { updateUserMonday, Logger } from '../../../integrations/firebase'
import { trace, perf} from '../../../integrations/firebase';

import { updateUser } from '../../../store/actions/userActions'
const logClient = new Logger(`Content`);

export default function Content() {
  const t = trace(perf, "Content");
  t.start();

  const activeUser = useSelector((state) => state.userReducer.user);
  const [userFavActions, setUserFavActions] = useState([])
  const dispatch = useDispatch();
  const actions = actionsSite

  useEffect(() => {
    updateUserFavActions()
  }, [activeUser])

  const updateUserFavActions = () => {
    if (activeUser?.favoriteActions) {
      const favActions = actions.filter(action => activeUser.favoriteActions.includes(action.type))
      setUserFavActions(favActions)
    }
  }

  const onFavAction = async (selectedAction, isAddToFav) => {
    const favoriteActions = activeUser.favoriteActions ? activeUser.favoriteActions : []
    // const favoriteActions = []//Clear
    if (isAddToFav) {
      const isAlreadyIn = favoriteActions.some(actionType => actionType === selectedAction.type)
      if (isAlreadyIn) return
      favoriteActions.push(selectedAction.type)
    } else {
      const idx = favoriteActions.indexOf(selectedAction.type)
      favoriteActions.splice(idx, 1)
    }
    const userRef = doc(db, "users", activeUser.uid);
    const updatedUser = {
      ...activeUser,
      favoriteActions
    }
    // console.log('%c  updatedUser:', 'color: white;background: red;', updatedUser);

    try {
      dispatch(updateUser({ ...updatedUser }));
      // setDetailsMsg('הפרטים עודכנו בהצלחה')
      await updateDoc(userRef, updatedUser, { merge: true }).then((r) => {}).catch((e) => {
        logClient.error('error when updating user data');
        logClient.error(JSON.stringify(updatedUser))
        logClient.error(JSON.stringify(e));
      });
      // console.log('%c  { itemId: updatedUser.mondayId, user: updatedUser }:', 'color: white;background: red;', { itemId: updatedUser.mondayId, user: updatedUser });
    }
    catch ({ message }) {
      console.log('%c  error:', 'color: white;background: red;', message);
    }

  }

  const returnValue = (
    <div className="home-container ">
      <HomeCover />
      <section className="home-page-actions">
        {!activeUser || (activeUser?.isAnonymous || activeUser?.accessToken) ?
          <CommonAction actions={actions} />
          :
          <FavoriteAction actions={userFavActions} onFavAction={onFavAction} />
        }
        <AllActions actions={actions} onFavAction={onFavAction} />
      </section>
      <Outlet />
    </div >
  );
  t.stop();
  return returnValue
}

