//returnAddress
import React, { useState, useEffect, useRef } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { Field } from 'formik';
import { useViewport } from '../../../Hooks/useViewport'
import { useSelector } from 'react-redux'
import arrow from '../../../images/dropdown-arrow.svg';
import { useApostropheSubstitution } from '../../../Hooks/useApostropheSubstitution'
import { TooltipTriangle } from '../../Utils/TooltipTriangle'
import cities2 from "../../../consts/cities";
import streetRes2 from "../../../consts/streets";

export const AddressReturningDocumentsUpdate = ({ data, setData, isError, errors, handleChanges, type }) => {
  const activeUser = useSelector((state) => state.userReducer.user);

  const [address, setAddress] = useState({
    returnCity: '',
    returnStreetAddress: '',
    returnStreetNumber: '',
    floor: '',
    apartment: '',
    returnNotes: '',
    returnAddressName: '',
    isAddressNotFound: false
  });

  const { width } = useViewport();
  const breakpoint = 1111;
  const isMobile = width < breakpoint

  const myAddress = activeUser?.addresses || []

  // const [isAddressNotFound, setIsAddressNotFound] = useState(false);
  const [searchCity, setSearchCity] = useState('');
  const [searchStreet, setSearchStreet] = useApostropheSubstitution('');
  const [isCitiesDropdownOpen, setIsCitiesDropdownOpen] = useState(false);
  const [isStreetsDropdownOpen, setIsStreetsDropdownOpen] = useState(false);
  const [israelCities, setIsraelCities] = useState([])
  const [cityStreets, setCityStreets] = useState([])
  const refCity = useRef()
  const refStreet = useRef()
  const cmpRef = useRef();

  const [radioInputsState, setRadioInputsState] = useState({
    selected: 0,
  });

  useEffect(() => {
    const { returnAddress, collectionMethod, collectionAddress } = data;
    if (data.type === 'documentTransfer') {
      if (returnAddress && Object.keys(returnAddress).length !== 0) {
        let idx
        idx = myAddress.findIndex(
          (currAddress) =>
            currAddress.city === returnAddress.returnCity &&
            currAddress.streetAddress === returnAddress.returnStreetAddress &&
            currAddress.streetNumber === returnAddress.returnStreetNumber &&
            currAddress.addressName === returnAddress.returnAddressName
        );
        if (idx === -1) {
          idx = myAddress.length;
          setAddress({ ...address, ...returnAddress });
          setRadioInputsState({ selected: (myAddress.length).toString() });
          setSearchCity(returnAddress?.returnCity || '')
          setSearchStreet(returnAddress?.returnStreetAddress || '')
        } else {
          setRadioInputsState({ selected: idx.toString() });
        }
      }
      else {
        setRadioInputsState({ selected: '0' });
        setData({
          ...data,
          returnAddress: {
            ...data.returnAddress,
            returnCity: myAddress[0]?.city || '',
            returnStreetAddress: myAddress[0]?.streetAddress || '',
            returnStreetNumber: myAddress[0]?.streetNumber || '',
            floor: myAddress[0]?.floor || '',
            apartment: myAddress[0]?.apartmentNumber || '',
            returnNotes: myAddress[0]?.comments || '',
            returnAddressName: myAddress[0]?.addressName || ''
          },
        });
      }
    }
    else if (returnAddress?.returnCity || returnAddress?.city || collectionAddress?.collectCity || collectionAddress?.city) {
      const address = returnAddress || collectionAddress;
      let idx
      if (returnAddress) {
        idx = myAddress.findIndex(
          (currAddress) =>
            currAddress.city === address.returnCity &&
            currAddress.streetAddress === address.returnStreetAddress &&
            currAddress.streetNumber === address.returnStreetNumber &&
            currAddress.addressName === address.returnAddressName
        );
      } else {
        idx = myAddress.findIndex(
          (currAddress) =>
            currAddress.city === address.collectCity &&
            currAddress.streetAddress === address.collectStreetAddress &&
            currAddress.streetNumber === address.collectStreetNumber &&
            currAddress.addressName === address.collectAddressName
        );
      }
      if (idx === -1) {
        idx = myAddress.length;
        setAddress({ ...address, ...returnAddress });
        setSearchCity(returnAddress?.returnCity || '')
        setSearchStreet(returnAddress?.returnStreetAddress || '')
      }
      setRadioInputsState({ selected: idx.toString() });
    } else {
      setRadioInputsState({ selected: '0' });
      setData({
        ...data,
        returnAddress: {
          ...data.collectionAddress,
          returnCity: myAddress[0]?.city || '',
          returnStreetAddress: myAddress[0]?.streetAddress || '',
          returnStreetNumber: myAddress[0]?.streetNumber || '',
          floor: myAddress[0]?.floor || '',
          apartment: myAddress[0]?.apartmentNumber || '',
          returnNotes: myAddress[0]?.comments || '',
          returnAddressName: myAddress[0]?.addressName || ''
        },
      });
    }

    if ((collectionMethod === 'pickup' && !returnAddress) && (data.type !== 'documentTransfer')) {
      const returnAddressFromCollectAddress = data.collectionAddress ? {
        returnCity: data?.collectionAddress?.collectCity,
        returnStreetAddress: data?.collectionAddress?.collectStreetAddress,
        returnStreetNumber: data?.collectionAddress?.collectStreetNumber,
        floor: data?.collectionAddress?.floor,
        apartment: data?.collectionAddress?.apartment,
        returnAddressName: data?.collectionAddress?.collectAddressName,
        returnNotes: data?.collectionAddress?.collectNotes,
      } : null
      setAddress(returnAddressFromCollectAddress);
      setSearchCity(returnAddressFromCollectAddress?.returnCity || '')
      setSearchStreet(returnAddressFromCollectAddress?.returnStreetAddress || '')
      setData({
        ...data,
        returnAddress: returnAddressFromCollectAddress
      });
    }

  }, []);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (refCity?.current?.contains(event.target) || refStreet?.current?.contains(event.target)) {
        return;
      }
      setIsCitiesDropdownOpen(false);
      setIsStreetsDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);

  // useEffect(async () => {
  //   try {
  //     const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`)
  //     const israelCities = await cityRes.json()
  //     setIsraelCities(israelCities.result.records.slice(1))
  //     if (address?.returnCity) {
  //       const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${address?.returnCity}`)
  //       const StreetsOfCity = await streetRes.json()
  //       setCityStreets(StreetsOfCity?.result?.records || [])
  //     }
  //   } catch (err) {
  //     console.log('Fetch error: ', err)
  //   }
  // }, []);

  useEffect(() => {
    if (isCitiesDropdownOpen) {
      getCities()
      scrollDown()
    } else {
      setIsraelCities([])
    }
    if (isStreetsDropdownOpen) {
      scrollDown()
    }
    if (isStreetsDropdownOpen && address?.returnCity) {
      getStreetsOfCity(address?.returnCity)
    } else {
      setCityStreets([])
    }
  }, [isCitiesDropdownOpen, isStreetsDropdownOpen]);


  const onBlur = () => {
    if (address?.returnCity !== searchCity) {
      setSearchCity(address?.returnCity ? address?.returnCity : '')
    }
    if (address?.returnStreetAddress !== searchStreet) {
      setSearchStreet(address?.returnStreetAddress ? address?.returnStreetAddress : '')
    }
    setIsCitiesDropdownOpen(false)
    setIsStreetsDropdownOpen(false)
  }
  const getCities = async () => {
    try {
      const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`, { signal: AbortSignal.timeout(5000) })
      const cities = await cityRes.json()
      const citiesAfterSwitch = switchBrackets(cities.result.records)
      setIsraelCities(citiesAfterSwitch)
    } catch (err) {
      const cities2 = require("../../../consts/cities")
      const citiesAfterSwitch2 = switchBrackets(cities2.result.records)
      setIsraelCities(citiesAfterSwitch2)
      console.log('Fetch error: ', err)
    }
  }

  const switchBrackets = (cities) => {
    const switchBracketsMap = {
      '(': ")",
      ')': "(",
    };

    return cities.map(city => {
      return {
        'שם_ישוב': city['שם_ישוב'].trim().replace(/\(|\)/gi, function (matched) {
          return switchBracketsMap[matched];
        })
      }
    })
  }


  const getStreetsOfCity = async (cityName) => {
    try {
      const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${cityName}`, { signal: AbortSignal.timeout(5000) })
      const StreetsOfCity = await streetRes.json()
      setCityStreets(StreetsOfCity.result.records)
    } catch (err) {
      const streetRes2 = require("../../../consts/streets")
      setCityStreets(streetRes2.result.records.filter((rec) => rec['שם_ישוב'] == cityName))
      console.log('Fetch error: ', err)
    }
  }

  const onSetChoose = (field, value) => {
    setAddress((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    // setTouch({ ...touch, [field]: true });
    setData({
      ...data,
      returnAddress: {
        ...data.returnAddress,
        ...address,
        [field]: value,
        // returnAddressName: data?.returnAddress?.returnAddressName || ''
      },
    });
  }

  const undoChanges = () => {
    setSearchStreet('')
    setAddress((prevState) => ({
      ...prevState,
      returnStreetAddress: '',
    }));
    setData({
      ...data,
      returnAddress: {
        ...data.returnAddress,
        ...address,
        returnStreetAddress: '',
        returnAddressName: data?.returnAddress?.returnAddressName || ''
      },
    });
  }

  const handleIsAddressNotFound = () => {
    setAddress((prevState) => ({
      ...prevState,
      isAddressNotFound: !prevState.isAddressNotFound,
    }))
  }

  const getTitleByTypeForm = () => {
    switch (type) {
      case 'courtSubmission':
      case 'officeDelivery':
      case 'personalDelivery':
      case 'personLocate':
        return 'בחירת כתובת להחזרת המסמכים';
      case 'apostilSubmission':
        return 'כתובת להחזרת התעודות';
      case 'documentTransfer':
        return 'כתובת יעד למסירת המסמכים';
      case 'apostilInCourt':
        return 'כתובת להחזרת המסמכים הנוטריונים'
      default:
        return 'בחירת כתובת להחזרת המסמכים';
    }
  }
  const getSelfStyleForm = () => {
    switch (type) {
      case 'personalDelivery':
      case 'officeDelivery':
      case 'documentTransfer':
      case 'personLocate':
      case 'apostilSubmission':
      case 'apostilInCourt':
      case 'inheritanceRegistration':
      case 'partnershipsRegistration':
      case 'associationsAndCompanies':
      case 'companiesRegistration':
        return 'self-form-style';
      case 'courtSubmission':
        if (data?.isFirstSubmission !== 'collect') return 'self-form-style';
      default:
        return '';
    }
  }

  // const onKeyDown = (keyEvent) => {
  //   if (keyEvent.keyCode === 8 || keyEvent.key === "Backspace") {
  //     setAddress({ ...address, returnStreetAddress: '' });
  //     setData({
  //       ...data,
  //       returnAddress: {
  //         ...data.returnAddress,
  //         returnStreetAddress: '',
  //         returnAddressName: '',
  //       },
  //     })
  //   }
  // }

  const handleChangeCheckbox = (event) => {
    const { value, name } = event.target;
    if (parseInt(value) <= myAddress.length) {
      setRadioInputsState((prevState) => ({
        ...prevState,
        selected: value,
      }));
    }
    setData({
      ...data,
      returnAddress:
        value === myAddress.length.toString()
          ? {
            ...data.returnAddress,
            ...address,
            // floor: name === 'floor' ? value : address.floor,
            // apartment: name === 'apartment' ? value : address.apartment,
          }
          : {
            ...data.returnAddress,
            returnCity: myAddress[value].city,
            returnStreetAddress: myAddress[value].streetAddress,
            returnStreetNumber: myAddress[value].streetNumber,
            floor: myAddress[value].floor,
            apartment: myAddress[value].apartmentNumber,
            returnNotes: myAddress[value]?.comments || '',
            returnAddressName: myAddress[value]?.addressName || ''

          },
    });
  };

  const scrollDown = () => {
    if (cmpRef.current) {
      setTimeout(() => {
        const div = cmpRef.current;
        div.scrollTo({
          top: div.scrollHeight,
          behavior: 'smooth'
        });
      }, 250);
    }
  };

  const handleChangeNewAddress = (event) => {
    const { value } = event.target;
    const { name } = event.target;
    setRadioInputsState((prevState) => ({
      ...prevState,
      selected: myAddress.length.toString(),
    }));
    setAddress((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setData({
      ...data,
      returnAddress: {
        ...data.returnAddress,
        ...address,
        [name]: value,
        returnAddressName: data?.returnAddress?.returnAddressName || ''
      },
    });
    handleChanges(event);
  };

  // const streetWithoutNumber = (returnStreetAddress) => {
  //   const streetWithoutNumber = returnStreetAddress.split(' ');
  //   const streetNumber = streetWithoutNumber[streetWithoutNumber.length - 1];
  //   return !isNaN(streetNumber)
  //     ? streetWithoutNumber.slice(0, streetWithoutNumber.length - 1).join(' ')
  //     : returnStreetAddress;
  // };

  // const { ref } = usePlacesWidget({
  //   onPlaceSelected: (selected) => {
  //     const splitAddress = selected.formatted_address.split(',');
  //     const streetNameSplit = splitAddress[0].split(' ');
  //     const streetNumber = streetNameSplit[streetNameSplit.length - 1];
  //     const streetNoNumber = streetWithoutNumber(splitAddress[0]);
  //     ref.current.value = streetNoNumber;
  //     setAddress((prevState) => ({
  //       ...prevState,
  //       // returnCity: splitAddress[1],
  //       returnCity: splitAddress[1] ? splitAddress[1] : selected.name,
  //       returnStreetAddress: streetNoNumber,
  //       returnStreetNumber: !isNaN(streetNumber) ? streetNumber : '',
  //     }));
  //     setData({
  //       ...data,
  //       returnAddress: {
  //         ...data.returnAddress,
  //         returnStreetAddress: streetNoNumber,
  //         // returnCity: splitAddress[1],
  //         returnCity: splitAddress[1] ? splitAddress[1] : selected.name,
  //         returnStreetNumber: !isNaN(streetNumber) ? streetNumber : '',
  //         returnAddressName: selected.name,

  //       },
  //     });
  //   },
  //   options: {
  //     types: ['geocode', 'establishment'],
  //     componentRestrictions: { country: 'il' },
  //     fields: ['name', 'formatted_address', 'address_components'],
  //   },
  // });

  const renderedDataDesktop = myAddress.map((currAddress, idx) => {
    return (
      <div className='input-container address data-desktop' style={{ paddingTop: idx === 0 ? '45px' : '15px', borderBottom: (idx === myAddress.length - 1) && !data.isShowNewAddress ? 'none' : '' }}>
        <div className='radio-btn-container'>
          <Field
            className='radio-btn'
            type='radio'
            id={idx}
            value={idx}
            onChange={handleChangeCheckbox}
            checked={radioInputsState.selected === idx.toString()}
          /></div>

        <div className='field'>
          {idx === 0 ? <label>יישוב</label> : <></>}
          <Field
            type="text"
            className='med disabled'
            disabled
            value={currAddress.city}
            name='collectCity'
          />
        </div>

        <div className='field'>
          {idx === 0 ? <label>רחוב</label> : <></>}
          <Field
            type="text"
            className='med disabled'
            disabled
            value={currAddress.streetAddress}
            name='collectStreetAddress'
          />
        </div>

        <div className='field'>
          {idx === 0 ? <label>מספר</label> : <></>}
          <Field
            type="text"
            className='small disabled'
            disabled
            value={currAddress.streetNumber}
            name='streetNumber'
          />
        </div>
        <div className='field'>
          {idx === 0 ? <label>קומה</label> : <></>}
          <Field
            type="text"
            className='small disabled'
            disabled
            value={currAddress.floor}
            name='floor'
          />
        </div>
        <div className='field'>
          {idx === 0 ? <label>דירה</label> : <></>}
          <Field
            type="text"
            className='small disabled'
            disabled
            value={currAddress.apartmentNumber}
            name='apartment'
          />
        </div>

        <div className='field'>
          {idx === 0 ? <label>הערות</label> : <></>}
          <Field
            type="text"
            name='collectNotes'
            disabled
            value={currAddress.comments}
            className="med disabled"
          />
        </div>
        <p className={`choose-address-btn ${radioInputsState.selected === idx.toString() ? 'selected' : ''}`}
          onClick={() => { handleChangeCheckbox({ target: { value: idx.toString(), name: '' } }) }}>
          בחירה
        </p>
      </div>
    );
  });

  const renderedDataMobile = myAddress.map((currAddress, idx) => {
    return (
      <div className='mobile-address-container'>
        <div className='radio-btn-container'>
          <Field
            className='radio-btn'
            type='radio'
            id={idx}
            value={idx}
            onChange={handleChangeCheckbox}
            checked={radioInputsState.selected === idx.toString()}
          />
        </div>
        <div className="address-details">
          <p>{`${currAddress?.streetAddress} ${currAddress.streetNumber ? currAddress.streetNumber : ''}`}</p>
          <p>{`${currAddress?.city}`}</p>
          <p>{`קומה: ${currAddress?.floor}   |   דירה: ${currAddress?.apartmentNumber}`}</p>
          <p>{`הערות: ${currAddress?.comments}`}</p>
        </div>
      </div>
    );
  });

  return (
    <div className={`main-form form7-addresses-update update-cmps ${getSelfStyleForm()}`} ref={cmpRef}>
      <div className='modal-content-container'>
        <h2 className='large-title'>{getTitleByTypeForm()}</h2>

        {/* {isMobile ? <></> : <div className='addresses-titles'>
          <p className='point'></p>
          <p className='large-title'>יישוב</p>
          <p className='large-title'>רחוב</p>
          <p className='small-title'>מספר</p>
          <p className='small-title'>קומה</p>
          <p className='small-title'>דירה</p>
          <p className='large-title'>הערות</p>
        </div>} */}

        {/* {isMobile ? '' : myAddress?.length === 0 ? '' : <h3>רשימת הכתובות שלי</h3>} */}
        {isMobile ? renderedDataMobile : renderedDataDesktop}

        {/* {isMobile && (
          <div className='new-address'>
            <Field
              autoComplete="new-password"
              className='radio-btn'
              type='radio'
              id={myAddress.length}
              value={myAddress.length}
              onChange={handleChangeCheckbox}
              checked={radioInputsState.selected === myAddress.length.toString()}
            />
            <h3 >הוספת כתובת חדשה</h3>
          </div>)} */}

        {(data.isShowNewAddress || activeUser.isAnonymous || !myAddress.length) ?
          <div className='input-container extra-address'>
            <div className='input-container radio-container'>
              <Field
                autoComplete="new-password"
                className='radio-btn'
                type='radio'
                id={myAddress.length}
                value={myAddress.length}
                onChange={handleChangeCheckbox}
                checked={radioInputsState.selected === myAddress.length.toString()}
              />
              {isMobile && (
                <p>בחירת כתובת חדשה</p>
              )}
            </div>
            {/* <div className='field'>
            <label>רחוב*</label>
            <Field name='returnStreetAddress'>
              {({ field }) => (
                <div>
                  <input
                    {...field}
                    ref={ref}
                    type="text"
                    autoComplete="new-password"
                    onKeyDown={onKeyDown}
                    className={
                      isError('returnAddress')
                        ? 'med error'
                        : data?.returnAddress?.returnAddressName && radioInputsState.selected === myAddress.length.toString()
                          ? 'med success'
                          : 'med'
                    }
                    name='returnStreetAddress'
                    value={radioInputsState.selected === myAddress.length.toString() ? address.returnStreetAddress : ''}
                    onChange={(e) => {
                      setAddress({
                        ...address,
                        returnStreetAddress: ref.current.value,
                      });
                      setRadioInputsState((prevState) => ({
                        ...prevState,
                        selected: myAddress.length.toString(),
                      }));
                      handleChanges(e);
                    }}
                  />
                  {isError('returnAddress') && (
                    <p>{errors.returnAddress.returnAddressName}</p>
                  )}
                </div>
              )}
            </Field>
          </div> */}

            <div className='field med'>
              {(isMobile || !myAddress.length) &&
                <label className='input-label'>יישוב*</label>
              }
              <div
                ref={refCity}
                className={
                  isCitiesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                }
              >
                <div className='dropdown-input'>
                  <input
                    type="text"
                    placeholder='הזינו יישוב ובחרו מתוך הרשימה'
                    onBlur={onBlur}
                    onChange={(event) => {
                      setSearchCity(event.target.value);
                      setIsCitiesDropdownOpen(true);
                      setRadioInputsState((prevState) => ({
                        ...prevState,
                        selected: myAddress.length.toString(),
                      }));
                    }}
                    value={searchCity}
                    onClick={() => { setIsCitiesDropdownOpen(true); }}
                    name='returnCity'
                    autoComplete="new-password"
                    className={
                      // isError('collectionAddress')
                      //   ? 'error'
                      //   : formState?.currRecipient?.city
                      //     ? 'success'
                      //     : ''
                      isError('returnAddress')
                        ? 'med error'
                        : data?.returnAddress?.returnCity && radioInputsState.selected === myAddress.length.toString()
                          ? 'med success'
                          : 'med'
                    }
                  />
                  <button
                    type='button'
                    onClick={() => {
                      setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                    }}
                  >
                    <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                  </button>
                </div>

                {isError('returnAddress') && <p className='error-label'>{errors.returnAddress.returnCity}</p>}

                <div className='dropdown-list med'>
                  {israelCities.filter((option) => option['שם_ישוב'].includes(searchCity)).map((city, idx) => {
                    return (
                      <div className='dropdown-option' key={idx}>
                        <p
                          onMouseDown={() => {
                            const cityName = city['שם_ישוב'].trim()
                            onSetChoose('returnCity', cityName)
                            setSearchCity(cityName)
                            setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                            // getStreetsOfCity(cityName)
                            setRadioInputsState((prevState) => ({
                              ...prevState,
                              selected: myAddress.length.toString(),
                            }));
                            if (cityName !== address.returnCity) undoChanges()
                          }}>
                          {city['שם_ישוב']}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>


            {address.isAddressNotFound ?
              <div div className='field med'>
                {(isMobile || !myAddress.length) &&
                  <label className='input-label'>רחוב*</label>
                }
                <Field
                  type="text"
                  name='returnStreetAddress'
                  autoComplete="new-password"
                  value={searchStreet}
                  onChange={(ev) => {
                    setSearchStreet(ev.target.value);
                    onSetChoose('returnStreetAddress', ev.target.value)
                  }}
                  className={
                    // isError('streetAddress')
                    //   ? 'error'
                    //   : formState?.currRecipient?.streetAddress
                    //     ? 'success'
                    //     : ''
                    isError('returnAddress')
                      ? 'med error'
                      : data?.returnAddress?.returnStreetAddress && radioInputsState.selected === myAddress.length.toString()
                        ? 'med success'
                        : 'med'
                  }
                />
                {isError('returnAddress') && <p className='error-label'>{errors?.returnAddress?.returnStreetAddress}</p>}
                <TooltipTriangle text={'אם אין את הרחוב ברשימה הנפתחת, לחצו כאן וכתבו את הרחוב'}>
                  <label className="checkbox-container">
                    <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={address.isAddressNotFound} onChange={handleIsAddressNotFound} />
                    <label className='checkbox-content' htmlFor='isAddressNotFound'>
                      לא מצאתי את הרחוב
                    </label>
                    <span className="checkmark"></span>
                  </label>
                </TooltipTriangle>
              </div>
              :


              <div className='field med'>
                {(isMobile || !myAddress.length) &&
                  <label className='input-label'>רחוב*</label>
                }

                <div
                  ref={refStreet}
                  className={
                    isStreetsDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                  }
                >
                  <div className='dropdown-input'>
                    <input
                      type="text"
                      placeholder='הזינו רחוב ובחרו מתוך הרשימה'
                      onBlur={onBlur}
                      onChange={(event) => {
                        setSearchStreet(event.target.value);
                        setIsStreetsDropdownOpen(true);
                      }}
                      value={searchStreet}
                      onClick={() => { setIsStreetsDropdownOpen(true); }}

                      name='returnStreetAddress'
                      autoComplete="new-password"
                      className={
                        // isError('streetAddress')
                        //   ? 'error'
                        //   : formState?.currRecipient?.streetAddress
                        //     ? 'success'
                        //     : ''
                        isError('returnAddress')
                          ? 'med error'
                          : data?.returnAddress?.returnStreetAddress && radioInputsState.selected === myAddress.length.toString()
                            ? 'med success'
                            : 'med'
                      }
                    />
                    <button
                      type='button'
                      onClick={() => {
                        setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                      }}
                    >
                      <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                    </button>
                  </div>

                  {isError('returnAddress') && <p className='error-label'>{errors?.returnAddress?.returnStreetAddress}</p>}

                  <div className='dropdown-list med'>
                    {cityStreets.filter((option) => option['שם_רחוב'].includes(searchStreet)).map((street, idx) => {
                      return (
                        <div className='dropdown-option' key={idx}>
                          <p
                            onMouseDown={() => {
                              onSetChoose('returnStreetAddress', street['שם_רחוב'])
                              setSearchStreet(street['שם_רחוב'])
                              setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                            }}>
                            {street['שם_רחוב']}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <TooltipTriangle text={'אם אין את הרחוב ברשימה הנפתחת, לחצו כאן וכתבו את הרחוב'}>
                  <label className="checkbox-container">
                    <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={address.isAddressNotFound} onChange={handleIsAddressNotFound} />
                    <label className='checkbox-content' htmlFor='isAddressNotFound'>
                      לא מצאתי את הרחוב
                    </label>
                    <span className="checkmark"></span>
                  </label>
                </TooltipTriangle>
              </div>
            }


            {/* <div className='field'>
            <label>עיר*</label>
            <Field
              type="text"
              name='returnCity'
              autoComplete="new-password"
              value={radioInputsState.selected === myAddress?.length.toString() ? address.returnCity : ''}
              onChange={handleChangeNewAddress}
              className={
                isError('returnAddress')
                  ? 'med error'
                  : address.returnCity?.length > 0 && radioInputsState.selected === myAddress?.length.toString()
                    ? 'med success'
                    : 'med'
              }
            />
            {isError('returnAddress') && <p>{errors.returnAddress.returnCity}</p>}
          </div> */}
            <div className='field small'>
              {(isMobile || !myAddress.length) &&
                <label className='input-label'>מספר*</label>
              }
              <Field
                type="text"
                name='returnStreetNumber'
                autoComplete="new-password"
                value={radioInputsState.selected === myAddress?.length.toString() ? address.returnStreetNumber : ''}
                onChange={handleChangeNewAddress}
                className={
                  isError('returnAddress')
                    ? 'small error'
                    : address.returnStreetNumber?.length > 0 && radioInputsState.selected === myAddress?.length.toString()
                      ? 'small success'
                      : 'small'
                }
              />
              {isError('returnAddress') && <p>{errors.returnAddress.returnStreetNumber}</p>}
            </div>
            <div className='field small'>
              {(isMobile || !myAddress.length) &&
                <label className='input-label'>קומה</label>
              }
              <Field
                type="text"
                className={address.floor && radioInputsState.selected === myAddress?.length.toString() ? 'small success' : 'small'}
                name='floor'
                autoComplete="new-password"
                onChange={handleChangeNewAddress}
                value={radioInputsState.selected === myAddress?.length.toString() ? address.floor : ''}
              />
            </div>
            <div className='field small'>
              {(isMobile || !myAddress.length) &&
                <label className='input-label'>דירה</label>
              }
              <Field
                type="text"
                autoComplete="new-password"
                className={address.apartment && radioInputsState.selected === myAddress?.length.toString() ? 'small success' : 'small'}
                name='apartment'
                onChange={handleChangeNewAddress}
                value={radioInputsState.selected === myAddress?.length.toString() ? address.apartment : ''}
              />
            </div>
            <div className='field'>
              {(isMobile || !myAddress.length) &&
                <label className='input-label'>הערות</label>
              }
              <Field
                type="text"
                name='returnNotes'
                autoComplete="new-password"
                value={radioInputsState.selected === myAddress?.length.toString() ? address.returnNotes : ''}
                onChange={handleChangeNewAddress}
                className={
                  address?.returnNotes?.length > 0 && radioInputsState.selected === myAddress?.length.toString()
                    ? 'med success'
                    : 'med'
                }
              />
            </div>
          </div>

          :
          <div className="add-address">
            <img
              className="btn-add"
              src='/images/plus.svg'
              alt=''
              onClick={() => {
                setData({ ...data, isShowNewAddress: true });
                handleChangeCheckbox({ target: { value: myAddress.length.toString(), name: '' } })

              }}
            />
            <h3>{'הוספת כתובת חדשה'}</h3>
          </div>


        }


        {type === 'documentTransfer' && <div className='cmp-line' />}
      </div>
    </div >
  );
};

export default AddressReturningDocumentsUpdate;
