import React, { useEffect, useState } from 'react';
import {applyActionCode, signInWithCustomToken} from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { auth } from '../../integrations/firebase'
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { useSelector, useDispatch } from 'react-redux';
import {setIsLoading, updateSystemData} from '../../store/actions/systemActions';
import {login, setRegisteredUser} from '../../store/actions/userActions';
import Content from './HomeCover/Content'
import { LoginSingup } from '../Login/LoginSingup'

export const HandleActions = () => {
    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }
    const navigate = useNavigate()
    const query = useQuery()
    const dispatch = useDispatch();

    const oobCode = query.get('oobCode')
    const mode = query.get('mode')
    const handleVerifyEmail = (auth, oobCode) => {
        applyActionCode(auth, oobCode)
            .then(() => {
                navigate('/verified-email')
                window.location.reload();
            }
            ).catch((error) => {
                const errorMessage = error.message;
                console.log('%c  errorMessage:', 'color: white;background: red;', errorMessage);
            });
    }
    switch (mode) {
        case 'OTP':
            signInWithCustomToken(auth, oobCode)
                .then(async (userCredential) => {
                    dispatch(setRegisteredUser(userCredential.user))
                    dispatch(login({ ...userCredential.user }))
                    navigate('/')
                    window.location.reload()
                })
                .catch((error) => {
                    const errorMessage = error.message;
                    console.log('%c  errorMessage:', 'color: white;background: red;', errorMessage);
                });
        case 'extra_members2023':
            return <>
                <Content />
                <LoginSingup signUpCode="extraMembers200"/>
            </>
        case 'resetPassword':
            return <>
                <Content />
                <LoginSingup resetPassword={true} oobCode={oobCode} />
            </>

        case 'verifyEmail':
            handleVerifyEmail(auth, oobCode);
            return <></>

        case 'signIn':
            console.log('CASE SIGN-IN')
            return <>
                <Content />
                <LoginSingup />
            </>
        default:
            return <></>

    }
};


