import React, { useEffect, useState } from 'react';
import { DocumentsReadyUpdate } from './DocumentsReadyUpdate';
import { AddressCollectDocumentsUpdate } from './AddressCollectDocumentsUpdate';
import { CollectBranchSelecting } from './CollectBranchSelecting';

export const CollectMethod = ({ data, setData, type, isError, errors, handleChanges, setTouch, touch, submitForm, setSubmitForm }) => {
  const [alignment, setAlignment] = useState('right');

  useEffect(() => {
    const { collectionMethod } = data;
    if (!collectionMethod) {
      setData({ ...data, collectionMethod: 'pickup' });
    }
    else {
      setAlignment(collectionMethod === 'pickup' ? 'right' : 'left');
    }
  }, []);

  const handleChangeData = (event, newAlignment) => {
    event.preventDefault();
    setSubmitForm(false);
    setAlignment(newAlignment);
    const field = event.target.name;
    const value = event.target.value;
    if (value === 'pickup') delete data.collectionAddress
    else delete data.collectionAddress
    setData({ ...data, [field]: value });
  };

  return (
    <div className='main-form form21-collect-method'>
      <div className='modal-content-container block'>
        <h2 className='large-title'>
          כיצד תעדיפו לקבל את המסמכים שהודפסו
        </h2>
        <p className='subtitle'>
          באמצעות שליח או איסוף המסמכים באופן עצמאי מאחד הסניפים שלנו
        </p>

        <div className={`form-main-toggle ${alignment}`}>
          <span></span>
          <button
            name='collectionMethod'
            value='pickup'
            onClick={(ev) => handleChangeData(ev, 'right')}
          >
            שליחות עם שליח
          </button>
          <button
            name='collectionMethod'
            value='branch'
            onClick={(ev) => handleChangeData(ev, 'left')}
          >
            איסוף מהסניף
          </button>
        </div>

        <div className='cmp-line' />

        {alignment === 'right' ?
          <>
            <AddressCollectDocumentsUpdate data={data} setData={setData} isError={isError} errors={errors} handleChanges={handleChanges} type={type} setTouch={setTouch} touch={touch} />
          </> :
          alignment === 'left' ? <CollectBranchSelecting data={data} setData={setData} errors={errors} submitForm={submitForm} setSubmitForm={setSubmitForm} type={type} />
            : <></>}

      </div>
    </div>
  );
};

export default CollectMethod;
