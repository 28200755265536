//personalDelivery
//מסירה אישית

const handleSteps = (data, formState, systemData, isTestUser) => {
  const { collectionMethod } = data;
  const { returnMethod } = data;
  if (isTestUser) {
    return [
      'PersonalDeliveryPage1',
      'Recipients',
      'WhatHanded',
      'CollectDocumentsOrUploadFile2',
      'Proof3',
      'FinalPage'
    ].flat()
  } else {
    return [
      'PersonalOrCompanyToggle',
      'PersonalOrCompany',
      'UrgencyRecipient',
      'Recipients',
      'WhatHanded',
      'CollectDocumentsOrUploadFile',
      collectionMethod === 'pickup' ? ['AddressCollectDocuments', 'DocumentsReady', 'Proof'] : (collectionMethod === 'upload' ? ['UploadFile', 'Proof'] : 'Proof'),
      returnMethod === 'delivery' ? (collectionMethod === 'pickup' ? 'FinalPage' : ['AddressReturningDocuments', 'FinalPage']) : 'FinalPage'
    ].flat()
  }


};

const handlePrevStep = (step, setStep, formSteps, state, setMyState, data, setData, setProgressBarPhase, onSetLastClaimant, onSetLastDefendant, myRecipients, isTestUser) => {
  if (step < 0) return
  const { nextStep, currRecipient } = state;
  let nowStep
  let editRecipient

  if (isTestUser) {
    if (nextStep === 'PersonalDeliveryPage1') {
      if (!currRecipient?._id) editRecipient = myRecipients[myRecipients.length - 1]
      else {
        const recipientIdx = myRecipients.findIndex(recipient => recipient._id === currRecipient._id);
        if (recipientIdx > 0) {
          editRecipient = myRecipients[recipientIdx - 1]
        }
      }
      setData({ ...data, isOtherTasks: false });
      nowStep = formSteps.findIndex((page) => page === 'PersonalDeliveryPage1')
      // nowStep = formSteps.findIndex((page) => page === 'Recipients')
    } else if (nextStep === 'Recipients') {
      if (myRecipients.length) editRecipient = myRecipients[myRecipients.length - 1]
      if (!data.recipients || data.recipients.length === 0) {
        setData({ ...data, isOtherTasks: true });
        nowStep = formSteps.findIndex((page) => page === 'PersonalDeliveryPage1')
      } else {
        nowStep = formSteps.findIndex((page) => page === 'PersonalDeliveryPage1')
      }
    }
    else if (nextStep === 'WhatHanded' && data.recipients && data.recipients.length <= 1) {
      if (myRecipients.length) editRecipient = myRecipients[myRecipients.length - 1]
      nowStep = formSteps.findIndex((page) => page === 'PersonalDeliveryPage1')
    } else {
      nowStep = step - 1;
    }
    setMyState({ ...state, nextStep: formSteps[nowStep], currRecipient: editRecipient ? editRecipient : state.currRecipient });
    setStep(nowStep);
  } else {
    if (nextStep === 'PersonalOrCompanyToggle') {
      nowStep = formSteps.findIndex((page) => page === 'Recipients')
    } else if (nextStep === 'Recipients') {
      if (!data.recipients || data.recipients.length === 0) {
        setData({ ...data, isOtherTasks: true });
        nowStep = formSteps.findIndex((page) => page === 'PersonalOrCompanyToggle')
      } else {
        nowStep = formSteps.findIndex((page) => page === 'UrgencyRecipient')
      }
    } else {
      nowStep = step - 1;
    }
    setMyState({ ...state, nextStep: formSteps[nowStep] });
    setStep(nowStep);
  }

};


const handleNextStep = (step, setStep, formSteps, state, setMyState, onAddRecipient, onAddOrder, data, setProgressBarPhase, setData, onAddEditClaimant, onAddEditDefendant, onSetLastDefendant, onSetLastClaimant, isTestUser) => {
  const { nextStep, currRecipient, isFinalStep } = state;
  let nowStep;
  if (isFinalStep) {
    onAddOrder();
  }

  if (isTestUser) {
    if (nextStep === 'PersonalDeliveryPage1' && currRecipient) {
      onAddRecipient();
      nowStep = formSteps.findIndex((page) => page === 'WhatHanded')
    } else {
      nowStep = step + 1;
    }
  } else {
    if ((nextStep === 'UrgencyRecipient' && currRecipient) || (nextStep === 'PersonalOrCompany' && currRecipient._id)) {
      onAddRecipient();
    } else {
      nowStep = step + 1;
    }
  }

  // nowStep = step + 1;

  setMyState({ ...state, nextStep: formSteps[nowStep], currRecipient: (nextStep === 'PersonalOrCompanyToggle') ? null : currRecipient });
  setStep(nowStep);
};


const handlePhase = (nextStep, setProgressBarPhase) => {

  switch (nextStep) {
    case 'PersonalDeliveryPage1':
    case 'PersonalOrCompanyToggle':
    case 'PersonalOrCompany':
    case 'UrgencyRecipient':
    case 'Recipients':
      setProgressBarPhase(1);
      break;
    case 'WhatHanded':
      setProgressBarPhase(2);
      break;
    case 'CollectDocumentsOrUploadFile':
    case 'AddressCollectDocuments':
    case 'DocumentsReady':
    case 'UploadFile':
    case 'CollectDocumentsOrUploadFile2':
      setProgressBarPhase(3);
      break;

    case 'Proof':
    case 'Proof3':
    case 'AddressReturningDocuments':
    case 'FinalPage':
      setProgressBarPhase(4);
      break;

    default:
      setProgressBarPhase(4);
      break;
  }
};

export { handleSteps, handlePrevStep, handleNextStep, handlePhase };
