import React, { useEffect, useState } from 'react';
import { DocumentsReadyUpdate } from './DocumentsReadyUpdate';
import { AddressCollectDocumentsUpdate } from './AddressCollectDocumentsUpdate';
import { CollectBranchSelecting } from './CollectBranchSelecting';

export const CertificateCollectMethod = ({ data, setData, type, isError, errors, handleChanges, setTouch, touch, submitForm, setSubmitForm }) => {
  const [alignment, setAlignment] = useState('right');

  useEffect(() => {
    const { collectionMethod } = data;
    if (!collectionMethod) {
      setData({ ...data, collectionMethod: 'pickup' });
    }
    else {
      setAlignment(collectionMethod === 'pickup' ? 'right' : 'left');
    }
  }, []);

  const handleChangeData = (event, newAlignment) => {
    event.preventDefault();
    setSubmitForm(false);
    setAlignment(newAlignment);
    const field = event.target.name;
    const value = event.target.value;
    if (value === 'pickup') delete data.collectionAddress
    else delete data.collectionAddress
    setData({ ...data, [field]: value });
  };

  return (
    <div className='main-form certificate-method'>
      <div className='modal-content-container block'>
        <h2 className='large-title'>
          {type === 'apostilInCourt' ? 'כיצד תעדיפו להעביר את המסמכים הנוטריונים לטיפולנו?' : 'כיצד תעדיפו להעביר את התעודות לטיפולנו?'}
        </h2>
        <p className='subtitle'>
          {'איסוף באמצעות שליח או העברת התעודות באופן עצמאי לאחד הסניפים שלנו '}
          <span className='underline'>
            {'בתיאום מראש'} 
          </span>
        </p>

        <div className={`form-main-toggle ${alignment}`}>
          <span></span>
          <button
            name='collectionMethod'
            value='pickup'
            onClick={(ev) => handleChangeData(ev, 'right')}
          >
            איסוף עם שליח
          </button>
          <button
            name='collectionMethod'
            value='branch'
            onClick={(ev) => handleChangeData(ev, 'left')}
          >
            העברה לסניף
          </button>
        </div>

        <div className='cmp-line' />

        {alignment === 'right' ?
          <>
            {/* <DocumentsReadyUpdate data={data} setData={setData} type={type} /> */}
            {/* <div className='cmp-line' /> */}
            <AddressCollectDocumentsUpdate data={data} setData={setData} isError={isError} errors={errors} handleChanges={handleChanges} type={type} setTouch={setTouch} touch={touch} />
          </> :
          alignment === 'left' ? <CollectBranchSelecting data={data} setData={setData} errors={errors} submitForm={submitForm} setSubmitForm={setSubmitForm} type={type} />
            : <></>}

      </div>
    </div>
  );
};

export default CertificateCollectMethod;
