
export function CustomTooltip({ tooltipType, children }) {
    const openUrl = (url, isInNewTab = true) => {
        isInNewTab ? window.open(url, '_blank') : window.open(url, '_self');
    }
    return (
        <div className="tooltip">
            {children}
            <p className="top">
                {tooltipType === 'postalCode' &&
                    <p className="details">
                        <span type="button" style={{ textDecoration: 'underline', fontWeight: '500', cursor: 'pointer' }} onClick={() => { openUrl('https://israelpost.co.il/zipcodesearch'); }}>
                            לחצו כאן על מנת לאתר את המיקוד
                        </span>
                    </p>}
                {tooltipType === 'smallClaimClaimant' &&
                    <p className='details'>
                        שימו לב! על התובע/ת בבית משפט לתביעות קטנות להיות יחיד בלבד (לא חברה בע״מ) -
                        &nbsp;
                        <span type="button" style={{ textDecoration: 'underline', fontWeight: '500', cursor: 'pointer' }} onClick={() => { openUrl('https://www.nevo.co.il/law_html/law01/055_002.htm'); }}>
                            סעיף 60 לחוק בתי המשפט [נוסח משולב], תשמ"ד-1984
                        </span>
                        &nbsp;
                    </p>}
                {tooltipType === 'claimDescription' &&
                    <p className="tooltip-details">
                        נדרש למלא לפחות
                        <span type="button" style={{ fontWeight: '600', margin: '0 4px' }} >
                            אחד
                        </span>
                        מהסעיפים א-ד המתייחסים לסמכות המקומית באופן שיתמוך בבחירת בית המשפט להגשת התביעה.
                    </p>}
                {tooltipType === 'claimReasons' &&
                    <p className="tooltip-details">
                        תארו את האירוע שבגינו מוגשת התביעה באופן ענייני, ברור וכרונולוגי שיהיה לשופט/ת תמונה מלאה ומסודרת של העובדות ונסיבות המקרה שמצדיקות לפסוק לכם את סכום הכסף או הסעד הנתבע.
                        &nbsp;
                        <span type="button" style={{ fontWeight: '600' }} >
                            ניתן גם להעלות נוסח כתב תביעה משלכם שיצורף להגשה.
                        </span>
                    </p>}
                {tooltipType === 'claimReasonsFiles' &&
                    <p className="tooltip-details">
                        צרפו את כל המסמכים, התכתבויות, קבלות, הסכמים, תמונות, סרטונים, הקלטות וכל ראיה אחרת שבכוונתם להסתמך עליהם במשפט.
                        תקנה 3(ב) לתקנות שיפוט תביעות קטנות:
                        <p style={{ fontSize: '14px', fontStyle: 'italic' }}>

                            ״ (ב)  התובע יצרף לכתב התביעה את כל המסמכים שהוא מבקש להסתמך עליהם במשפט אם הם מצויים ברשותו; ואולם בית המשפט רשאי להתיר לתובע להגיש מסמך שלא צורף לכתב התביעה אם מצא שהמסמך דרוש לצורך מתן פסק דין או מכל סיבה חשובה אחרת.״
                        </p>
                        &nbsp;
                        <span type="button" style={{ textDecoration: 'underline', fontWeight: '500', cursor: 'pointer' }} onClick={() => { openUrl('https://www.nevo.co.il/law_html/law01/055_030.htm'); }}>
                            תקנות שיפוט בתביעות קטנות (סדרי דין), תשל״ז-1976
                        </span>
                    </p>}
                {tooltipType === 'SectionC' &&
                    <p className="tooltip-details">
                        <p>
                            בית המשפט
                            <span style={{ textDecoration: 'underline', margin: '0 4px' }}>
                                לא
                            </span>
                            ייתן פסק דין אם כתב התביעה
                            <span style={{ textDecoration: 'underline', margin: '0 4px' }}>
                                לא
                            </span>
                            נמסר לנתבע/ים.
                        </p>
                        <p>
                            לכן חשוב שתבדקו מול מזכירות בית המשפט שהתביעה אכן נמסרה לנתבעים, או שתוכלו בשלב הבא  לבחור שאנו נבצע מסירה אישית לנתבע/ים וכחלק מהשירות אנו נדאג להגיש ולעדכן את בית המשפט שכתב התביעה נמסר לנתבעים.
                        </p>
                        <p>
                            תקנה 7 (ב1) לתקנות השיפוט תביעות קטנות:
                        </p>
                        <p style={{ fontSize: '14px', fontStyle: 'italic' }}>
                            ״(ב1) לא הומצא כתב התביעה עד 60 ימים לפני הדיון בידי פקיד בית המשפט כאמור בתקנת משנה (ב), ימציא התובע או מי מטעמו את כתב התביעה לנמענים 40 ימים לפחות לפני הדיון.״
                        </p>
                        <span type="button" style={{ textDecoration: 'underline', fontWeight: '600', cursor: 'pointer' }} onClick={() => { openUrl('https://www.nevo.co.il/law_html/law01/055_030.htm'); }}>
                            תקנות שיפוט בתביעות קטנות (סדרי דין), תשל״ז-1976
                        </span>
                    </p>}
                {tooltipType === 'SectionD' &&
                    <p className="tooltip-details">
                        <p>
                            תקנה 10 לתקנות שיפוט בתביעות קטנות:
                        </p>
                        <p style={{ fontSize: '14px', fontStyle: 'italic' }}>
                            ״10.  נתבע שנדרש להגיש כתב הגנה ולא הגישו תוך המועד שנקבע לכך, יתן בית המשפט פסק דין על יסוד כתב התביעה בלבד; אולם רשאי בית המשפט, אם ראה שמן הצדק לעשות כן, להזמין את בעלי הדין לטעון את טענותיהם ולהביא את ראיותיהם גם אם לא הוגש כתב הגנה כאמור.״
                        </p>
                        <span type="button" style={{ textDecoration: 'underline', fontWeight: '600', cursor: 'pointer' }} onClick={() => { openUrl('https://www.nevo.co.il/law_html/law01/055_030.htm'); }}>
                            תקנות שיפוט בתביעות קטנות (סדרי דין), תשל״ז-1976
                        </span>
                    </p>}
                {tooltipType === 'smallClaimCourt' &&
                    <p>
                        <p>
                            תביעה תוגש לאחד מבתי המשפט שבמחוז בו: מתגורר או מקום עסקו של הנתבע
                            &nbsp;
                            <span style={{ fontWeight: '600' }}>
                                או
                            </span>
                            &nbsp;
                            מקום המעשה או המחדל שבשלו תובעים
                            &nbsp;
                            <span style={{ fontWeight: '600' }}>
                                או
                            </span>
                            &nbsp;
                            תביעה שכנגד - מקום הגשת התביעה המקורית. כשיש כמה נתבעים ניתן להגיש את התביעה לכל בית משפט שמוסמך לדון בתביעה כלפי כל אחד מהם. כשמדובר בתביעה על פרסום או סחר באינטרנט ניתן להגיש את התביעה לאחד מבתי המשפט במחוז שבו מצוין מקום מגוריו או מקום עסקו של הנתבע
                            &nbsp;
                            <span style={{ fontWeight: '600' }}>
                                או של התובע.
                            </span>
                            &nbsp;
                        </p>
                        <span type="button" style={{ textDecoration: 'underline', fontWeight: '600', cursor: 'pointer' }} onClick={() => { openUrl('https://www.nevo.co.il/law_html/law01/055_030.htm'); }}>
                            תקנה 2 לתקנות שיפוט בתביעות קטנות (סדרי דין), תשל״ז - 1976:
                        </span>
                        <div style={{ fontSize: '14px', fontStyle: 'italic' }}>
                            <p>
                                ״2. (א) תביעה תוגש לבית המשפט שאזור שיפוטו הוא אחד המקומות המנויים להלן, והוא על אף האמור בכל הסכם שבין בעלי הדין; ואלה הם:
                            </p>
                            <p>
                                (1) מקום מגוריו או מקום עסקו של הנתבע;
                            </p>
                            <p>
                                (2) נמחקה;
                            </p>
                            <p>
                                (3) נמחקה;
                            </p>
                            <p>
                                (4) נמחקה;
                            </p>
                            <p>
                                (5) מקום המעשה או המחדל שבשלו תובעים;
                            </p>
                            <p>
                                (6) בתביעה שכנגד - מקום הגשת התביעה המקורית.
                            </p>
                            <p>
                                (ב) על אף האמור בתקנת משנה (א), תובענה בשל פרסום או סחר ברשת האינטרנט תוגש לבית המשפט שבאזור שיפוטו מצוי מקום מגוריו או מקום עסקו של הנתבע או מקום מגוריו או מקום עסקו של התובע, בלבד.
                            </p>
                            <p>
                                (ג) בית המשפט המוסמך לדון בתביעה הכוללת כמה נתבעים הוא בית המשפט המומך לדון נגד אחד מהם לפי תקנות משנה (א) או (ב).״
                            </p>
                        </div>
                        <div>
                            <p>רשימת בתי המשפט בחלוקה למחוזות:</p>
                            <p>
                                <span style={{ textDecoration: 'underline', fontWeight: '600' }}>מחוז דרום:</span>
                                &nbsp;
                                אילת (דרך יותם 3, אילת), אשדוד (מורדי הגטאות 1 אשדוד), אשקלון (שדרות בן גוריון 19, אשקלון), באר שבע (התקווה 5, באר שבע), דימונה (שדרות בן גוריון 1, דימונה), קריית גת (חשוון 12, קריית גת).
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline', fontWeight: '600' }}>מחוז מרכז:</span>
                                &nbsp;
                                כפר סבא (הטחנה 5, כפר סבא), נתניה (הרצל 57, נתניה), פתח תקווה (בזל 1, פתח תקווה), ראשון לציון (ישראל גלילי 5, ראשון לציון), רחובות (רוז׳נסקי 9, רחובות), רמלה (שדרות ויצמן 3, רמלה).
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline', fontWeight: '600' }}>מחוז תל אביב:</span>
                                &nbsp;
                                בת ים (הרב ניסנבוים יצחק 7, בת ים), תל אביב ויצמן (ויצמן 1, תל אביב), תל אביב שוקן (שוקן 25, תל אביב), הרצליה (בן גוריון 31, הרצליה).
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline', fontWeight: '600' }}>מחוז ירושלים:</span>
                                &nbsp;
                                בית שמש (הרצל 9, בית שמש), ירושלים (חשין 6, ירושלים).
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline', fontWeight: '600' }}>מחוז חיפה:</span>
                                &nbsp;
                                חדרה (הלל יפה 7א׳ חדרה), חיפה (שדרות הפלי״ם, חיפה), עכו (יהושפט 15, עכו), קריות (דרך עכו 194 קריית ביאליק).
                            </p>
                            <p>
                                <span style={{ textDecoration: 'underline', fontWeight: '600' }}>מחוז צפון:</span>
                                &nbsp;
                                בית שאן (שאול המלך 31, בית שאן), טבריה (חשמונאים 3, טבריה), מסעדה (מול בית הדואר בכפר מסעדה), נצרת (כיכר קריית יצחק רבין נוף הגליל, נצרת עילית), עפולה (מנחם אוסישקין 42, עפולה), צפת (מרום כנען צפת), קצרין (שיאון 1, קצרין), קריית שמונה (שדרות תל-חי 97, קריית שמונה).
                            </p>
                        </div>
                    </p>}
            </p>
        </div>
    )
}