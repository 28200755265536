import React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import google from '../../../images/google.svg'
import facebook from '../../../images/fb.svg'
import { updateSystemData } from '../../../store/actions/systemActions';
import { signInAnonymously, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider, getAuth } from "firebase/auth";
import { login } from '../../../store/actions/userActions'
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Close } from '../../../images/close.svg'
import { auth } from '../../../integrations/firebase'

export const LoginOptions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const provider = new GoogleAuthProvider();
    const fbProvider = new FacebookAuthProvider();
    const systemData = useSelector((state) => state.systemReducer.systemData)

    const onSignup = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'signupOptions' }
        }));
    }

    const onFacebookLogin = () => {
        console.log('onFacebookLogin')
        const auth = getAuth();
        signInWithPopup(auth, fbProvider)
            // console.log('signInWithPopup')
            .then((result) => {
                // console.log('result', result)
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                navigate(systemData.isCheckout ? '/checkout' : '/')
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
                // ...
            });

    }

    const onGoogleLogin = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                navigate(systemData.isCheckout ? '/checkout' : '/')
                // ...
            }).catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.email;
                // The AuthCredential type that was used.
                const credential = GoogleAuthProvider.credentialFromError(error);
            });
    }

    const onGuestLogin = () => {
        signInAnonymously(auth)
        const user = auth.currentUser;
        dispatch(login(user))
        navigate(systemData.isCheckout ? '/checkout' : '/')
    }

    const onLogin = () => {
        dispatch(updateSystemData({
            ...systemData, currLoginData: { currentStep: 'login' }
        }));
    }
    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };

    return <>
        <div className="header-btns">
            <button type='button' className='orange-btn' onClick={onSignup}>
                פתיחת חשבון
            </button>
            <Close onClick={setModalClose} />
        </div>
        <div className="content">
            <div className="login-step">
                {/* <button type='button' className='login-signup' onClick={() => onSignup()}>פתיחת חשבון</button> */}
                <h2 className="main-title">כניסה לחשבון שלך</h2>
                {/* <button onClick={onFacebookLogin} type='button' className='main-btn long option facebook'>
                    <img src={facebook} alt="facebook icon" />
                    התחברות עם Facebook
                </button> */}
                <button onClick={onGoogleLogin} type='button' className='main-btn long option'>
                    <img src={google} alt="google icon" />
                    התחברות עם Google</button>
                <div className='separator'>
                    <div className='line'></div>
                    <p>או</p>
                    <div className='line'></div>
                </div>
                <button type='button' className='main-btn long option' onClick={() => onLogin()}>דוא״ל וסיסמה</button>
                <button type='button' onClick={onGuestLogin} className='main-btn long option'>כניסה ללא חשבון </button>
                <div className='register-link'>
                    <p>עדיין אין לכם חשבון?</p>
                    <p className="link" onClick={() => onSignup()}> לחצו כאן להרשמה</p>
                </div>

            </div >
        </div>
    </>
};
