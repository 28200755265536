import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RecipientPreview } from '../../Recipient/RecipientPreview';
import { removeRecipientId } from '../../../store/actions/dataActions';
import { removeRecipient } from '../../../store/actions/recipientActions';
import { updateErrorModal } from '../../../store/actions/systemActions';

export const Recipients = ({ data, setFormState, setData, formState, setStep, type, submitForm, setSubmitForm, errors }) => {
  const totalRecipients = useSelector((state) => state.recipientReducer.Recipients);
  const orderRecipientsIds = useSelector((state) => state.dataReducer.data.recipients);
  const myRecipients = totalRecipients.filter((recipient) => orderRecipientsIds.includes(recipient._id));
  const dispatch = useDispatch();

  useEffect(() => {
    if (submitForm && errors.recipients) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          errorText: getErrorTextByType(type),
          submitText: 'אישור',
          isCancel: false
        })
      );
    }
  }, [myRecipients, submitForm]);

  useEffect(() => {
    setData({ ...data, isOtherTasks: false, isEditTasks: false });
    setFormState({ ...formState, currRecipient: null });
  }, [])

  const onRemoveRecipient = (recipientId) => {
    dispatch(removeRecipientId(recipientId));
    dispatch(removeRecipient(recipientId));
  };

  const getErrorTextByType = (type) => {
    switch (type) {
      case 'addressLocate':
      case 'phoneLocate':
      case 'personLocate':
      case 'idLocate':
        return 'נדרש למלא פרטי מאותר אחד לפחות'

      case 'personalDelivery':
      case 'courtSubmission':
        return 'נדרש למלא פרטי נמען אחד לפחות'
      case 'officeDelivery':
        return 'נדרש למלא פרטי משרד אחד לפחות'

      default:
        break;
    }

  }

  const onEditRecipient = (recipientId, e) => {
    e.preventDefault();
    e.stopPropagation();

    let idx;
    const { formSteps } = formState;
    let newFormSteps = [...formSteps];
    const editRecipient = myRecipients.find((recipient) => recipient._id === recipientId);

    switch (type) {
      case 'courtSubmission':
        idx = formSteps.findIndex((page) => page === 'OfficeDeliveryFirst' || page === 'PersonalDeliveryPage1');
        if (editRecipient.type === 'HEVRA') {
          setData({ ...data, isPrivateOrOffice: 'true', isPersonalOrCompany: 'false', isOtherTasks: true });
        } else if (editRecipient.type === 'ADAM_PRATI') {
          setData({ ...data, isPrivateOrOffice: 'true', isPersonalOrCompany: 'true', isOtherTasks: true });
        } else if (editRecipient.type === 'officeDelivery') {
          setData({ ...data, isPrivateOrOffice: 'false', isOtherTasks: true });
        }
        // idx = formSteps.findIndex((page) => page === 'OfficeDelivery' || page === 'PersonalOrCompany');
        // if (editRecipient.type === 'HEVRA') {
        //   setData({ ...data, isPrivateOrOffice: 'true', isPersonalOrCompany: 'false', isOtherTasks: true });
        // } else if (editRecipient.type === 'ADAM_PRATI') {
        //   setData({ ...data, isPrivateOrOffice: 'true', isPersonalOrCompany: 'true', isOtherTasks: true });
        // } else if (editRecipient.type === 'officeDelivery') {
        //   setData({ ...data, isPrivateOrOffice: 'false', isOtherTasks: true });
        // }
        break;

      case 'personalDelivery':
        if (data.isUpdatedDelivery) {
          idx = formSteps.findIndex((page) => page === 'PersonalDeliveryPage1');
          newFormSteps[idx] = 'PersonalDeliveryPage1';
          setData({ ...data, isEditTasks: true });
        } else {
          idx = formSteps.findIndex((page) => page === 'PersonalOrCompany');
          newFormSteps[idx] = 'PersonalOrCompany';
          setData({ ...data, isOtherTasks: true });
        }
        break;
      case 'officeDelivery':
        if (data.isUpdatedDelivery) {
          idx = formSteps.findIndex((page) => page === 'OfficeDeliveryFirst');
          newFormSteps[idx] = 'OfficeDeliveryFirst';
          setData({ ...data, isOtherTasks: true });
        } else {
          idx = formSteps.findIndex((page) => page === 'OfficeDelivery');
          newFormSteps[idx] = 'OfficeDelivery';
          setData({ ...data, isOtherTasks: true });
        }
        break;
      case 'addressLocate':
      case 'phoneLocate':
      case 'personLocate':
      case 'idLocate':
        idx = formSteps.findIndex((page) => page === 'PersonDetails');
        setData({ ...data, isOtherTasks: true });
        break;

      default:
        break;
    }

    setFormState((prevState) => ({
      ...prevState,
      currRecipient: editRecipient,
      formSteps: newFormSteps,
      nextStep: newFormSteps[idx],
    }));

    setStep(idx);
  };

  const setNextStepByFormType = () => {
    let page = null;
    switch (type) {
      case 'courtSubmission':
        page = 'DeliveryAfterSubmission';
        break;
      case 'addressLocate':
      case 'phoneLocate':
      case 'personLocate':
      case 'idLocate':
        page = 'PersonDetails';
        break;
      case 'personalDelivery':
        if (data.isUpdatedDelivery) page = 'PersonalDeliveryPage1';
        else page = 'PersonalOrCompanyToggle';
        break;
      case 'officeDelivery':
        if (data.isUpdatedDelivery) page = 'OfficeDeliveryFirst';
        else page = 'OfficeDelivery'
        break;
      default:
        page = '';
        break;
    }
    const { formSteps } = formState;
    const idx = formSteps.findIndex((currPage) => currPage === page);
    setStep(idx)
  }

  const getTextByType = () => {
    let title = '';
    let addText = ''
    switch (type) {
      case 'courtSubmission':
      case 'officeDeliverySubmission':
        title = 'נמענים'
        addText = ' הוספת נמען נוסף'
        break;
      case 'personalDelivery':
        title = 'רשימת נמענים'
        addText = ' הוספת נמען נוסף'
        break;
      case 'addressLocate':
      case 'phoneLocate':
      case 'idLocate':
        title = 'מאותרים'
        addText = 'הוספת מאותר נוסף'
        break;
      case 'personLocate':
        title = 'סיכום פרטי המאותר/ים לצורך הנפקת השאילתא'
        addText = 'הוספת מאותר נוסף'
        break;
      case 'officeDelivery':
        title = 'המשרדים'
        addText = 'הוספת משרד נוסף'
        break;
      default:
        break;
    }
    return { addText, title }
  }

  const { addText, title } = getTextByType()
  return (
    <div className='main-form recipients'>
      <div className='modal-content-container'>
        <h2 className='large-title'>{title}</h2>

        {myRecipients.map((recipient) => {
          return <RecipientPreview
            key={recipient._id}
            recipient={recipient}
            onRemoveRecipient={onRemoveRecipient}
            onEditRecipient={onEditRecipient}
            type={type}
          />
        })}

        <div className="add-recipient">
          <img
            className="btn-add"
            src='/images/plus.svg'
            alt=''
            onClick={() => {
              setFormState((prevState) => ({
                ...prevState,
                nextStep: setNextStepByFormType(),
                currRecipient: null,
              }));
              setData({ ...data, isOtherTasks: true });
            }}
          />
          <h3>{addText}</h3>
        </div>
      </div>
    </div>
  );
};

export default Recipients;
