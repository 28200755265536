//inheritanceRegistration
//רשם לענייני ירושה

const handleSteps = (data, formState, systemData, isTestUser) => {
  const { collectionMethod, returnMethod, docsForSubmission } = data
  const isDeposit = docsForSubmission?.some(doc => doc.isDeposit === false)
  const isCollect = docsForSubmission?.some(doc => doc.upload === false)
  if (isTestUser) {
    return [
      'ChooseOffice',
      'WhatServedRegist',
      isCollect ? 'CollectDocumentsAndDocumentsReady' : 'CollectDocumentsOrUploadFile2',
      isDeposit ? 'Proof3' : [],
      'UrgencyExecution',
      'FinalPage'
    ].flat()
  } else {
    return [
      'ChooseOffice',
      'WhatServedRegist',
      'CollectDocumentsOrUploadFile',
      collectionMethod === 'pickup' ? ['AddressCollectDocuments', 'DocumentsReady'] : 'UploadFile',
      isDeposit ? 'Proof' : [],
      returnMethod === 'delivery' ?
        (collectionMethod === 'pickup') ? ['UrgencyExecution', 'FinalPage'] :
          ['AddressReturningDocuments', 'UrgencyExecution', 'FinalPage']
        : ['UrgencyExecution', 'FinalPage'],
    ].flat()
  }
};

const handlePrevStep = (step, setStep, formSteps, state, setMyState, data, setData, setProgressBarPhase, onSetLastClaimant, onSetLastDefendant, myRecipients, isTestUser) => {
  const { nextStep } = state;
  let nowStep
  if (step < 0) return

  if (isTestUser) {
    nowStep = step - 1;
  } else {
    if (nextStep === 'AddressCollectDocuments') {
      const isCollect = data.docsForSubmission.some(doc => doc.upload === false)
      if (isCollect) {
        nowStep = formSteps.findIndex((page) => page === 'WhatServedRegist')
      } else {
        nowStep = step - 1;
      }
    } else {
      nowStep = step - 1;
    }
  }
  
  setStep(nowStep);
  setMyState({ ...state, nextStep: formSteps[nowStep], isFinalStep: false });
};


const handleNextStep = (step, setStep, formSteps, state, setMyState, onAddRecipient, onAddOrder, data, setProgressBarPhase, setData, onAddEditClaimant, onAddEditDefendant, onSetLastDefendant, onSetLastClaimant, isTestUser) => {
  const { nextStep, isFinalStep } = state;
  let nowStep;
  if (isFinalStep) {
    onAddOrder();
  }

  if (isTestUser) {
    // if (nextStep === 'WhatServedRegist') {
    //   const isCollect = data.docsForSubmission.some(doc => doc.upload === false)
    //   if (isCollect) {
    //     nowStep = formSteps.findIndex((page) => page === 'AddressCollectDocumentsUpdate')
    //   } else {
    //     nowStep = step + 1
    //   }
    // } 

    // else if (nextStep === 'DocumentsReady' || nextStep === 'UploadFile') {
    //   const isDeposit = data.docsForSubmission.some(doc => doc.isDeposit === false)
    //   if (!isDeposit) {
    //     nowStep = formSteps.findIndex((page) => page === 'UrgencyExecution')
    //   } else nowStep = step + 1
    // }

    // else {
    nowStep = step + 1
    // }
  } else {
    if (nextStep === 'WhatServedRegist') {
      const isCollect = data.docsForSubmission.some(doc => doc.upload === false)
      if (isCollect) {
        nowStep = formSteps.findIndex((page) => page === 'AddressCollectDocuments')
      } else {
        nowStep = step + 1
      }
    } else if (nextStep === 'DocumentsReady' || nextStep === 'UploadFile') {
      const isDeposit = data.docsForSubmission.some(doc => doc.isDeposit === false)
      if (!isDeposit) {
        nowStep = formSteps.findIndex((page) => page === 'UrgencyExecution')
      } else nowStep = step + 1
    }
    else {
      nowStep = step + 1
    }
  }

  setMyState({ ...state, nextStep: formSteps[nowStep] });
  setStep(nowStep);
};


const handlePhase = (nextStep, setProgressBarPhase) => {
  switch (nextStep) {
    case 'ChooseOffice':
      setProgressBarPhase(1);
      break;
    case 'WhatServedRegist':
    case 'CollectFileDetails':
    case 'DeposittFileDetails':
    case 'PowerOfAttorney':
    case 'ActionsSummary':
      setProgressBarPhase(2);
      break;
    case 'DocumentsReady':
    case 'AddressCollectDocuments':
    case 'CollectDocumentsOrUploadFile':
    case 'UploadFile':
    case 'CollectDocumentsOrUploadFile2':
    case 'AddressCollectDocumentsUpdate':
    case 'CollectDocumentsAndDocumentsReady':
      setProgressBarPhase(3);
      break;
    case 'Proof':
    case 'Proof3':
    case 'AddressReturningDocuments':
      setProgressBarPhase(4);
      break;

    case 'UrgencyExecution':
      setProgressBarPhase(5);
      break;

    default:
      setProgressBarPhase(5);
      break;
  }
};

export { handleSteps, handlePrevStep, handleNextStep, handlePhase };
