import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Field } from 'formik';
import {
  addRecipient,
  editRecipient,
} from '../../../store/actions/recipientActions';
import { AddRecipientPopup } from './PageUtils/AddRecipientPopup';


export const PersonDetails = ({ type, data, setData, setFormState, formState, isError, errors, handleChanges, setTouch, touch, scrollTo, submitForm, setSubmitForm }) => {
  const [isAddedRecipientModal, setIsAddedRecipientModal] = useState(false);
  const [recipientAddedName, setRecipientAddedName] = useState('');

  const recipients = useSelector(state => state.recipientReducer.Recipients);
  const dispatch = useDispatch();
  useEffect(() => {
    if ((data.recipients && data.recipients.length > 0 && !data.isOtherTasks)) {
      setFormState({ ...formState, currRecipient: recipients[recipients.length - 1] });
    }
  }, []);

  const handleAlignment = () => {
    if (currRecipient?.gender) {
      if (currRecipient.gender === "female") {
        return 'left'
      } if (currRecipient.gender === "unset") {
        return 'unset'
      } else {
        return 'right'
      }
    } else {
      return 'unset'
    }
  }

  const handleChangeCheckbox = (event) => {
    const { value } = event.target;
    handleChanges(event);
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: {
        ...prevState.currRecipient,
        gender: value,
      }
    }))
  };

  const handleChangeData = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setFormState({
      ...formState,
      currRecipient: {
        ...formState.currRecipient, [field]: value, type: 'LOCATE',
      },
    });
    handleChanges(event);
    setTouch({ ...touch, [field]: true });
  };

  const onAddEditRecipient = async () => {
    const recipient = { ...formState.currRecipient };
    const recipientName = `${(recipient?.personName + ' ') || ''}${recipient?.personLastName || ''}`
    if ((!recipient?.personName || !recipient?.personLastName || !recipient?.personId) && (type === 'personLocate')) return
    setRecipientAddedName(recipientName)
    setIsAddedRecipientModal(true)
    if (recipient._id) {
      dispatch(editRecipient(recipient));
      setData({
        ...data,
        isOtherTasks: false,
      });
    } else {
      const newRecipient = await dispatch(addRecipient(recipient));
      setData({
        ...data,
        recipients: [...data.recipients, newRecipient._id],
        isOtherTasks: false,
      });
    }
    setSubmitForm(false);
    // scrollTo('top')
  };

  const phoneRegex = /^\d{9,10}$/;


  const { currRecipient } = formState;
  return (
    <div className='person-details main-form'>
      {isAddedRecipientModal && <AddRecipientPopup recipientAddedName={recipientAddedName} setIsAddedRecipientModal={setIsAddedRecipientModal} type={data.type} />}

      <div className='modal-content-container'>

        {type === 'personLocate' && <h2 className='large-title'>הזינו את פרטי המאותר {data.isOtherTasks ? 'הנוסף ' : ''}  לגביו השאילתא</h2>}

        {!(type === 'personLocate') && <h2 className='large-title'>
          הזינו כל מידע חיוני אודות האדם {data.isOtherTasks ? 'הנוסף ' : ''}לגביו מבוקש האיתור
        </h2>}

        <div className="main-input-container">
          <div className="input-container">
            <label>שם פרטי*</label>
            <Field
              type='text'
              name='personName'
              autoComplete="new-password"
              value={currRecipient?.personName || ''}
              onChange={handleChangeData}
              className={
                isError('personName') && submitForm
                  ? 'error'
                  : formState?.currRecipient?.personName
                    ? 'success'
                    : ''
              }
            />
            {isError('personName') && submitForm && <p className='error-label'>{errors.personName}</p>}

          </div>

          <div className="input-container">
            <label>שם משפחה*</label>
            <Field
              type='text'
              name='personLastName'
              autoComplete="new-password"
              value={currRecipient?.personLastName || ''}
              onChange={handleChangeData}
              className={
                isError('personLastName') && submitForm
                  ? 'error'
                  : formState?.currRecipient?.personLastName
                    ? 'success'
                    : ''
              }
            />
            {isError('personLastName') && submitForm && <p className='error-label'>{errors.personLastName}</p>}

          </div>
          {type !== 'idLocate' &&
            <div className="input-container">
              <label>מספר זהות*</label>
              <Field
                type='text'
                name='personId'
                autoComplete="new-password"
                value={currRecipient?.personId || ''}
                onChange={handleChangeData}
                className={
                  isError('personId') && submitForm
                    ? 'error'
                    : formState?.currRecipient?.personId?.length === 9
                      ? 'success'
                      : ''
                }
              />
              {isError('personId') && submitForm && <p className='error-label'>{errors.personId}</p>}
            </div>
          }
          {type === 'idLocate' &&
            <div className="input-container">
              <label>מקום עבודה</label>
              <Field
                type='text'
                name='job'
                autoComplete="new-password"
                placeholder={'פרטו גם עבודות לשעבר'}
                value={formState?.currRecipient?.job || ''}
                onChange={handleChangeData}
                className={
                  isError('job') && submitForm
                    ? 'error'
                    : currRecipient?.job?.length > 0
                      ? 'success'
                      : ''
                }
              />
            </div>
          }
          {type !== 'phoneLocate' && type !== 'personLocate' && <div className="input-container">
            <div className='field'>
              <label>מספר פלאפון</label>
              <div className='icon-input'>
                <Field
                  type='text'
                  name='personPhone'
                  autoComplete="new-password"
                  value={currRecipient?.personPhone || ''}
                  onChange={handleChangeData}
                  className={
                    isError('personPhone') && submitForm
                      ? 'error'
                      : phoneRegex.test(formState?.currRecipient?.personPhone)
                        ? 'success'
                        : ''
                  }
                />

                <div className="icon">972+</div>
              </div>
              {isError('personPhone') && submitForm && <p className='error-label'>{errors.personPhone}</p>}
            </div>
          </div>}
          {type !== 'personLocate' && <div className="input-container">
            <label>{type === 'phoneLocate' ? 'כתובת' : 'כתובת ידועה אחרונה'}</label>
            <Field
              type='text'
              name='streetAddress'
              autoComplete="new-password"
              value={formState?.currRecipient?.streetAddress || ''}
              onChange={handleChangeData}
              className={
                isError('streetAddress') && submitForm
                  ? 'error'
                  : currRecipient?.streetAddress?.length > 0
                    ? 'success'
                    : ''
              }
            />
          </div>}

          <div className="input-container">
            <label>מין</label>
            <div className={`toggle ${handleAlignment()}`}>
              <label className="togglt-option" htmlFor="male">
                זכר
                <Field
                  className="toggle-option-radio"
                  type="radio"
                  onChange={handleChangeCheckbox}
                  id="male"
                  name="gender"
                  autoComplete="new-password"
                  value={'male'}
                  checked={currRecipient?.gender === 'male'}
                />
              </label>

              <label className="togglt-option" htmlFor="unset">
                ללא
                <Field
                  className="toggle-option-radio"
                  type="radio"
                  onChange={handleChangeCheckbox}
                  id="unset"
                  name="gender"
                  autoComplete="new-password"
                  value={'unset'}
                  checked={currRecipient?.gender === 'unset'}
                />
              </label>
              <label className="togglt-option" htmlFor="female">
                נקבה
                <Field
                  id="female"
                  type="radio"
                  name="gender"
                  value={'female'}
                  onChange={handleChangeCheckbox}
                  checked={currRecipient?.gender === 'female'}
                  autoComplete="new-password"
                  className="toggle-option-radio"
                />
              </label>


              <span></span>
            </div>
          </div>


          {!(type === 'personLocate') && <div className="input-container">
            <label> כל מידע נוסף שיכול לסייע </label>
            <textarea
              type='text'
              name="comments"
              className={formState?.currRecipient?.comments ? 'success' : ''}
              onChange={handleChangeData}
              value={formState?.currRecipient?.comments || ''}
              placeholder={type === 'idLocate' ? 'למשל: שמות בני המשפחה, גיל המאותר, סיבת החקירה, לינק לפרופיל הפייסבוק / אינסטגרם' : 'למשל: כתובת ישנה, מקום עבודה, בני משפחה, גיל, וכו'}
            />
          </div>}
        </div>
        <p className='note'>בשלב הבא ניתן יהיה להוסיף מאותרים נוספים</p>

        <div className="add-recipient">
          <img
            className="btn-add"
            src='/images/plus.svg'
            alt=''
            onClick={() => {
              onAddEditRecipient()
              setData({ ...data, isOtherTasks: true });
              setFormState((prevState) => ({
                ...prevState,
                currRecipient: {
                  type: "LOCATE"
                },
              }));
            }}
          />
          <h3>{'הוספת מאותר נוסף'}</h3>
        </div>
      </div>
    </div >
  );
};

export default PersonDetails;
