import React, { useEffect, useState } from 'react'
import { UserOrderItem } from './UserOrderItem'
import { UserOrdersSearch } from './UserOrdersSearch'
import { UserOrdersFilter } from './UserOrdersFilter'
// import { doc, collection, getDocs, onSnapshot, query, where } from "firebase/firestore";
// import db from '../../integrations/firebase';
import { useSelector } from 'react-redux'
// import SearchIcon from "../../images/Search Icon.svg";
import { HEBREW_ACTIONS_TYPE } from "../../consts/data";

export const UserOrders = () => {
    const activeUser = useSelector((state) => state.userReducer.user);
    const orders = useSelector((state) => state.userDataReducer.userOrders);
    const [isAnyDoneActions, setIsAnyDoneActions] = useState(false)
    const [actions, setActions] = useState([]);
    const [filterBy, setFilterBy] = useState({
        orderId: '',
        extraSearch: '',
        actionType: {
            type: '',
            typeToFilter: ''
        },
        dateType: {
            type: '',
            typeToFilter: ''
        },
        userType: {
            type: '',
            typeToFilter: ''
        },
    });

    const docTransferTypes = (typeOfTransfer, action) => {
        const recipientReturnName = action.originalData?.returnAddress?.personName || action.originalData?.returnAddress?.officeName || action.originalData?.returnAddress?.companyName || ''
        const recipientCollectName = action.originalData?.collectionAddress?.personName || action.originalData?.collectionAddress?.officeName || action.originalData?.collectionAddress?.companyName || ''

        switch (typeOfTransfer) {
            case 'fromMe':
                return `שליחות ממני לנמען ${recipientReturnName}`
            case 'toMe':
                return `איסוף מהנמען ${recipientCollectName} אלי`
            case 'both':
                return `העברה בין ${recipientCollectName} ל${recipientReturnName}`
            default:
                return ''
        }
    }

    useEffect(() => {
        async function getUserOrders() {
            // const q = query(collection(db, "customers", activeUser.uid, 'orders'), where("userMonday", "==", activeUser.mondayId));
            // const querySnapshot = await getDocs(q);

            const sortedOrders = sortOrders()
            const actions = ordersToActions(sortedOrders)
            setActions(actions)

            orders.map(order => (
                order.actionData.map((action, idx) => {
                    if (action.isDone) setIsAnyDoneActions(true)
                })
            ))
        }
        if (activeUser) getUserOrders()
    }, [activeUser, orders])

    useEffect(() => {
        let filteredOrders = sortOrders()
        let actions = []

        if (filterBy?.dateType?.typeToFilter) {
            const typeToFilter = filterBy?.dateType?.typeToFilter
            // console.log('typeToFilter', typeToFilter)
            let currDate = new Date(),
                currDay = currDate.getDate(),
                currMonth = currDate.getMonth() + 1,
                currYear = currDate.getFullYear();

            filteredOrders = filteredOrders.filter(order => {
                const year = +order.orderTime.slice(6, 10)
                const monthIndex = +order.orderTime.slice(3, 5)
                const day = +order.orderTime.slice(0, 2)
                const hours = +order.orderTime.slice(12, 14)
                const minutes = +order.orderTime.slice(15, 17)

                const lastMonth = currMonth - 1 === 0 ? 12 : currMonth - 1
                if (typeToFilter === 'today') return currYear === year && currMonth === monthIndex && currDay === day
                else if (typeToFilter === 'yesterday') return ((currMonth === monthIndex && currDay - 1 === day) ||
                    (currMonth - 1 === monthIndex && getDaysInMonth(currMonth, currYear) === day)
                    || (currMonth - 1 === 12 && getDaysInMonth(currMonth, currYear) === day) && currYear - 1 === year)
                else if (typeToFilter === 'thisWeek') return isDateInThisWeek(order.dateToSort)
                else if (typeToFilter === 'lastWeek') return isLastWeek(year, monthIndex, day, currYear, currMonth, currDay)
                else if (typeToFilter === 'thisMonth') return currYear === year && currMonth === monthIndex
                else if (typeToFilter === 'lastMonth') return (currYear === year || currYear - 1 === year) && lastMonth === monthIndex
                else if (typeToFilter === 'thisYear') return currYear === year
                else if (typeToFilter === 'chooseDate') {
                    if (!filterBy?.dateType?.startDate || !filterBy?.dateType?.endDate) return true
                    const dateIncludeToday = addDays(filterBy?.dateType?.endDate, 1)
                    return (filterBy?.dateType?.startDate <= order.dateToSort) && (order.dateToSort <= dateIncludeToday)
                }
                else if (typeToFilter === 'all') return true
                else return true
            })
        }

        if (filterBy?.userType?.type) {
            const typeToFilter = filterBy?.userType?.typeToFilter
            filteredOrders = filteredOrders.filter(order => {
                if (typeToFilter === 'all') return true
                const clientName = order?.cart?.orderClientName || ''
                return clientName.includes(filterBy.userType.type)
            })
        }

        if (filterBy.extraSearch) {
            filteredOrders = filteredOrders.forEach(order => {
                order.actionData.forEach(action => {
                    if (action?.formData?.title && action?.formData?.title.includes(filterBy.extraSearch)) actions.push(action)
                    else if ((action.type === 'landRegistration') || (action.type === 'partnershipsRegistration') ||
                        (action.type === 'associationsAndCompanies') || (action.type === 'companiesRegistration')
                        || (action.type === 'inheritanceRegistration')) { //לפי כתובת בית משפט
                        const branchAddress = `לשכת ${action?.branchAddress?.city || ''} ${action?.branchAddress?.fullAddress || ''}`
                        if (branchAddress.includes(filterBy.extraSearch)) actions.push(action)
                    } else if (action.type === 'apostilSubmission') { //לפי מדינה
                        if (action?.apostilCountry?.country && action.apostilCountry.country.includes(filterBy.extraSearch)) actions.push(action)
                    } else if (action.type === 'smallClaim') { // לפי סוג תביעה
                        if (action?.claimType && action.claimType.includes(filterBy.extraSearch)) actions.push(action)
                    } else if (action.type === 'personalDelivery') { //לפי נמען
                        const name = `${action?.action?.personName || action?.action?.companyName || ''}`
                        if (name.includes(filterBy.extraSearch)) actions.push(action)
                    } else if (action.type === 'officeDelivery') { //לפי נמען
                        const name = `${action?.action?.officeName || ''} ${action?.action?.city || ''} ${action?.action?.streetAddress || ''}`
                        if (name.includes(filterBy.extraSearch)) actions.push(action)

                    } else if (action.type === 'courtSubmission') { //לפי כתובת בית משפט
                        if (action?.action?.courtAddress && action.action.courtAddress.includes(filterBy.extraSearch)) actions.push(action)

                    } else if (action.type === 'documentTransfer') { //לפי כתובת בית משפט
                        if ((action?.originalData?.typeOfTransfer && docTransferTypes(action?.originalData?.typeOfTransfer, action).includes(filterBy.extraSearch))) actions.push(action)

                    } else if ((action.type === 'personLocate') || (action.type === 'phoneLocate')
                        || (action.type === 'addressLocate')) { //לפי מאותר 
                        const isInclude = action.recipients.some(recipient => {
                            const name = `${recipient.personFirstName || ''} ${recipient.personLastName || ''}`
                            return name.replace(/\s+/g, ' ').trim().includes(filterBy.extraSearch)
                        })
                        if (isInclude) actions.push(action)
                    }
                })
            });

        } else {
            filteredOrders = filteredOrders.filter(order => order.orderId.includes(filterBy.orderId));
            actions = ordersToActions(filteredOrders)
        }

        if (filterBy?.actionType?.type) {
            const typeToFilter = filterBy?.actionType?.typeToFilter
            actions = actions.filter(action => {
                if (typeToFilter === 'all') return true
                const formTitle = HEBREW_ACTIONS_TYPE[action.type] || ''
                let searchType = filterBy.actionType.type
                return formTitle.includes(searchType)
            })
        }
        setActions(actions)
    }, [filterBy])

    const ordersToActions = (orders) => {
        const allActions = []
        orders.forEach(order => (
            order.actionData.forEach((action, idx) => (
                allActions.push(action)
            ))
        ))
        return allActions
    }

    const getDaysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    }

    const isLastWeek = (year, month, day, todayYear, todayMonth, todayDay) => {
        var orderDate = new Date(year, month, day);
        var today = new Date(todayYear, todayMonth, todayDay);
        var oneWeekAgo = new Date(todayYear, todayMonth, todayDay - 7);
        var startOfCurrentWeek = new Date(todayYear, todayMonth, todayDay - today.getDay());
        return orderDate >= oneWeekAgo && orderDate < startOfCurrentWeek;
    }

    const isDateInThisWeek = (date) => {
        const todayObj = new Date();
        todayObj.setHours(0, 0, 0, 0);
        const todayDate = todayObj.getDate();
        const todayDay = todayObj.getDay();


        // get first date of week
        const firstDayOfWeek = new Date(todayObj.setDate(todayDate - todayDay));
        // get last date of week
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 7);

        // if date is equal or within the first and last dates of the week
        return firstDayOfWeek <= date && date <= lastDayOfWeek
    }

    const addDays = (date, days) => {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }

    const sortOrders = () => {
        const sortedOrders = []
        orders.forEach(data => {
            const year = +data.orderTime.slice(6, 10)
            const monthIndex = +data.orderTime.slice(3, 5) - 1
            const day = +data.orderTime.slice(0, 2)
            const hours = +data.orderTime.slice(12, 14)
            const minutes = +data.orderTime.slice(15, 17)
            data.dateToSort = new Date(year, monthIndex, day, hours, minutes)
            sortedOrders.push(data)
        });

        sortedOrders.sort(function (a, b) {
            return b.dateToSort - a.dateToSort
        })

        return sortedOrders
    }

    return (
        <div className="main-checkout user-orders">
            <UserOrdersFilter filterBy={filterBy} setFilterBy={setFilterBy} filteredActions={actions} />
            <UserOrdersSearch filterBy={filterBy} setFilterBy={setFilterBy} />
            <div className="orders-list">
                {actions.length ?
                    actions.map((action, idx) => {
                        if (!action.isDone) return <UserOrderItem status={'active'} action={action} key={action.id} idx={idx} />
                    })
                    :
                    <></>
                }
            </div>

            <h3 className="title">הזמנות שהסתיימו</h3>
            <div className={`orders-list ${isAnyDoneActions ? '' : 'temp'}`}>
                {actions.length ?
                    actions.map((action, idx) => {
                        if (action.isDone) return <UserOrderItem status={'active'} action={action} key={action.id + 'done'} idx={idx} />
                    })
                    :
                    <></>
                }
            </div>
        </div>
    )
}
