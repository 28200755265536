import React, { useEffect } from 'react'
import { Field } from 'formik';
import remove from '../../../images/remove1.png';
import { utilService } from '../../../services/utilService'
import { useViewport } from '../../../Hooks/useViewport'
import { useSelector } from 'react-redux'

export const CollectFileDetails = ({ data, setData, errors, isError, isFromActionInOffice }) => {
    const { width } = useViewport();
    const breakpoint = 599;
    const currUser = useSelector((state) => state.userReducer.user)

    useEffect(() => {
        // going back navigation load last submitter
        if ((data.collectFiles && data.collectFiles.length > 0 && !data.isOtherSubmitter && !data.isEdit)) {
            setData({ ...data, currSubmiterIdx: data.collectFiles.length - 1 });
        }

        if (data.collectFiles && data.isOtherSubmitter && !data.isEdit) {
            // add
            data.collectFiles = [...data.collectFiles, {
                id: utilService.makeId(),
                name: '',
                idNum: '',
                lawyerNumber: currUser?.lawyerNumber || '',
                powerOfAttorney: false,
                files: [{ fileId: utilService.makeId(), fileNum: '', block: '', lot: '', subLot: '' }]
            }]
            data.currSubmiterIdx = (data.collectFiles.length) - 1
            setData({ ...data })
        } else if (!data.collectFiles || data.collectFiles.length === 0) {
            // init
            data.collectFiles = [{
                id: utilService.makeId(),
                name: '',
                idNum: '',
                lawyerNumber: currUser?.lawyerNumber || '',
                powerOfAttorney: false,
                powerOfAttorneySign: '',
                files: [{ fileId: utilService.makeId(), fileNum: '', block: '', lot: '', subLot: '' }]
            }]
            data.currSubmiterIdx = (data.collectFiles.length) - 1
            setData({ ...data })
        }

    }, [])

    const addInputField = () => {
        const collectFiles = [...data.collectFiles]
        const files = [...data.collectFiles[data.currSubmiterIdx].files]
        files.push({ fileId: utilService.makeId(), fileNum: '', block: '', lot: '', subLot: '' })
        collectFiles[data.currSubmiterIdx].currFileIdx = files.length - 1
        collectFiles[data.currSubmiterIdx].files = files
        setData({ ...data, collectFiles })
    }


    const removeInputFields = (index) => {
        const updateData = data.collectFiles[data.currSubmiterIdx].files.splice(index, 1);
        setData({ ...updateData })
    }

    const handleSubmiter = (event) => {
        const field = event.target.name;
        const value = event.target.value;
        const collectFiles = [...data.collectFiles]
        collectFiles[data.currSubmiterIdx][field] = value
        setData({ ...data, collectFiles })
    }

    const handleFile = (event, idx) => {
        const field = event.target.name;
        const value = event.target.value;
        const collectFiles = [...data.collectFiles]
        const files = [...data.collectFiles[data.currSubmiterIdx].files]
        files[idx][field] = value
        collectFiles[data.currSubmiterIdx].files = files
        setData({ ...data, collectFiles })
    }

    const getFirstOptionNameByType = () => {
        switch (data.type) {
            case 'landRegistration':
                return 'איסוף תיק'
            case 'landSupervisor':
                return 'איסוף מסמכים'
            default:
                return ''
        }
    }

    const renderFiles = () => {
        if (data.collectFiles && data.currSubmiterIdx >= 0 && data.collectFiles[data.currSubmiterIdx]?.files) {
            return data.collectFiles[data.currSubmiterIdx].files.map((details, index) => {
                const { fileNum, block, lot, subLot } = details;
                return (
                    <div className="row my-3" key={index}>
                        <div className="col">
                            <div className="input-container">
                                <div className="field file" style={width < breakpoint ? { order: 0 } : { order: 'unset' }}>
                                    <label>מספר התיק*</label>
                                    <Field
                                        onChange={(evnt) => handleFile(evnt, index)}
                                        autoComplete="new-password"
                                        placeholder=''
                                        type='text'
                                        name="fileNum"
                                        value={fileNum ? fileNum : ''}

                                        className={
                                            isError('collectFiles') && !fileNum
                                                ? 'error'
                                                : fileNum
                                                    ? 'success'
                                                    : ''
                                        }
                                    />
                                    {isError('collectFiles') && !data.collectFiles[data.currSubmiterIdx]?.files[index]?.fileNum ? <p>{errors.collectFiles[data.currSubmiterIdx]?.files[index]?.fileNum}</p> : ''}
                                </div>
                                <div className="file-det-container" style={width < breakpoint ? { order: 2 } : { order: 'unset' }}>
                                    <div className="field small">
                                        <label>גוש</label>
                                        <Field
                                            onChange={(evnt) => handleFile(evnt, index)}
                                            className={block ? 'small success' : 'small'}
                                            autoComplete="new-password"
                                            placeholder=''
                                            type='text'
                                            name="block"
                                            value={block ? block : ''}
                                        />
                                    </div>
                                    <div className="field small">
                                        <label>חלקה</label>
                                        <Field
                                            onChange={(evnt) => handleFile(evnt, index)}
                                            autoComplete="new-password"
                                            placeholder=''
                                            type='text'
                                            name="lot"
                                            value={lot ? lot : ''}
                                            className={lot ? 'small success' : 'small'}

                                        />
                                    </div>
                                    <div className="field small">
                                        <label>תת חלקה</label>
                                        <Field
                                            className={subLot ? 'small success' : 'small'}
                                            onChange={(evnt) => handleFile(evnt, index)}
                                            autoComplete="new-password"
                                            placeholder=''
                                            type='text'
                                            name="subLot"
                                            value={subLot ? subLot : ''}
                                        />
                                    </div>
                                </div>
                                {(data.currSubmiterIdx >= 0 && data.collectFiles[data.currSubmiterIdx].files.length !== 1) ? <div style={width < breakpoint ? { order: 1 } : { order: 'unset' }} className="remove-contain"><button type='button' className="btn-remove" onClick={() => removeInputFields(index)}>  <img
                                    src={remove}
                                    alt='remove'
                                /></button>
                                </div> : ''}
                            </div>
                        </div>

                        <div className="col">
                        </div>
                    </div>

                )

            })
        }
    }
    return (
        <div className={`main-form action-in-office collect-file-details update-cmps ${isFromActionInOffice ? 'from-action-office' : ''}`}>
            <div className='modal-content-container '>
                {!isFromActionInOffice &&
                    <h2 className='large-title'>{getFirstOptionNameByType()}</h2>
                }
                <p className='subtitle details'>הזינו את פרטי המגיש ופרטי התיק לאיסוף לצורך הכנת ייפוי כוח מתאים</p>
                <div className='server-details'>
                    <h3>פרטי המגיש</h3>
                    <div className="input-container">
                        <div className="field">
                            <label>שם המגיש*</label>
                            <Field
                                placeholder='שם עו"ד או איש הקשר המעודכן בלשכה'
                                className={
                                    data.currSubmiterIdx >= 0 && data?.collectFiles && isError('collectFiles') && !data.collectFiles[data.currSubmiterIdx]?.name
                                        ? 'error'
                                        : data.currSubmiterIdx >= 0 && data?.collectFiles && data.collectFiles[data.currSubmiterIdx]?.name
                                            ? 'success'
                                            : ''
                                }
                                type='text'
                                name="name"
                                onChange={handleSubmiter}
                                value={data.currSubmiterIdx >= 0 && data?.collectFiles ? data.collectFiles[data.currSubmiterIdx]?.name : ''}
                            />
                            {isError('collectFiles') && !data.collectFiles[data.currSubmiterIdx]?.name ? <p>{errors.collectFiles[data.currSubmiterIdx].name}</p> : ''}
                        </div>
                        <div className="field">
                            <label>ת.ז.*</label>
                            <Field
                                className={
                                    data.currSubmiterIdx >= 0 && data?.collectFiles && isError('collectFiles') && (!data.collectFiles[data.currSubmiterIdx]?.idNum || data.collectFiles[data.currSubmiterIdx]?.idNum.length !== 9)
                                        ? 'error'
                                        : data.currSubmiterIdx >= 0 && data?.collectFiles && data.collectFiles[data.currSubmiterIdx]?.idNum.length === 9
                                            ? 'success'
                                            : ''
                                }
                                placeholder=''
                                type='number'
                                inputmode="numeric"
                                name="idNum"
                                onChange={handleSubmiter}
                                value={data.currSubmiterIdx >= 0 && data?.collectFiles ? data.collectFiles[data.currSubmiterIdx]?.idNum : ''}
                            />
                            {isError('collectFiles') && data.collectFiles[data.currSubmiterIdx]?.idNum?.length !== 9 ? <p>{errors.collectFiles[data.currSubmiterIdx].idNum}</p> : ''}

                        </div>
                        <div className="field">
                            <label>מ.ר.</label>
                            <Field
                                placeholder="מספר רשיון עורך דין"
                                className={
                                    data.currSubmiterIdx >= 0 && data?.collectFiles && data.collectFiles[data.currSubmiterIdx]?.lawyerNumber
                                        ? 'success'
                                        : ''
                                }
                                type='text'
                                name="lawyerNumber"
                                onChange={handleSubmiter}
                                value={data.currSubmiterIdx >= 0 && data?.collectFiles ? data.collectFiles[data.currSubmiterIdx]?.lawyerNumber : ''}
                            />
                        </div>
                    </div>

                    <h3>פרטי התיק</h3>
                    {renderFiles()}

                    <div className="row">
                        <div className="col-sm-12">
                        </div>
                    </div>


                    <div className="add-recipient">
                        <img
                            className="btn-add"
                            src='/images/plus.svg'
                            alt=''
                            onClick={addInputField}
                        />
                        <h3>הוספת תיק נוסף עבור אותו מגיש </h3>
                    </div>
                </div>
            </div>
        </div >

    )
}
