
export function Tooltip({ text = 'טולטיפ', link, className, children, unsetIdiv }) {
    return (
        <div className={`tooltip ${className || ''}`}>
            {children}
            <p className="top">
                {text}{link && <> <br />
                    <a href={link} style={{ color: 'blue' }} target="_blank" rel="noopener noreferrer">לצפייה בקישור</a>
                    {!unsetIdiv && <i></i>}
                </>}
            </p>
        </div>
    )
}