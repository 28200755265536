
export const urgencyTranslate = {
    'URG': 'דחוף',
    'VERY_URG': 'בהול',
    'REG': 'רגיל'
}

const urgencyType1 = {
    'URG': 'ביצוע תוך 1 ימי עסקים מיום קבלת המסמכים',
    'REG': 'ביצוע תוך 3 ימי עסקים מיום קבלת המסמכים',
    'VERY_URG': 'ביצוע ביום קבלת המסמכים ',
}

const urgencyType2 = {
    'REG': 'ביצוע תוך 30 ימי עסקים מיום קבלת התעודות (יום האיסוף לא נספר במניין הימים)',
    'URG': 'ביצוע תוך 14 ימי עסקים מיום קבלת התעודות (יום האיסוף לא נספר במניין הימים)',
    'VERY_URG': 'ביצוע תוך 8 ימי עסקים מיום קבלת התעודות (יום האיסוף לא נספר במניין הימים)',
}

const urgencyType3 = {
    'REG': 'ביצוע תוך 5 ימי עסקים',
    'URG': 'ביצוע תוך 3 ימי עסקים',
    'VERY_URG': 'ביצוע תוך 1 ימי עסקים',
}

const urgencyType4 = {
    'REG': 'ביקור ראשון תוך 5 ימי עסקים מיום קבלת המסמכים ',
    'URG': 'ביקור ראשון תוך 1 ימי עסקים מיום קבלת המסמכים',
    'VERY_URG': 'ביקור ראשון ביום קבלת המסמכים',
}

const urgencyType5 = {
    'REG': 'נסיון המצאה תוך 3 ימי עסקים מיום קבלת המסמכים ',
    'URG': 'נסיון המצאה תוך 1 ימי עסקים מיום קבלת המסמכים',
    'VERY_URG': 'נסיון המצאה ביום קבלת המסמכים',
}

const urgencyType6 = {
    'REG': 'ביצוע תוך 3 ימי עסקים מיום איסוף התיקים',
    'URG': 'ביצוע תוך 1 ימי עסקים מיום איסוף התיקים',
    'VERY_URG': 'ביצוע ביום איסוף התיקים',
}
const urgencyType7 = {
    'REG': 'ביצוע תוך 5 ימי עסקים מיום איסוף המסמכים,  בשליחות כפולה עד 10 ימי עסקים.',
    'URG': 'ביצוע תוך 1 ימי עסקים מיום איסוף המסמכים, בשליחות כפולה עד 2 ימי עסקים.',
    'VERY_URG': 'ביצוע ביום איסוף המסמכים, בשליחות כפולה עד 1 ימי עסקים.',
}

const urgencyType8 = {
    'REG': 'הגשה תוך 7 ימי עסקים מיום ביצוע ההזמנה',
    'URG': 'הגשה תוך 4 ימי עסקים מיום ביצוע ההזמנה',
    'VERY_URG': 'הגשה תוך 2 ימי עסקים מיום ביצוע ההזמנה',
}

const urgencyType9 = {
    'REG': 'ביקור ראשון תוך 5 ימי עסקים מיום קבלת המסמכים, וכל ביקור נוסף תוך 5 ימי עסקים מהביקור הקודם',
    'URG': 'ביקור ראשון תוך 1 ימי עסקים מיום קבלת המסמכים, וכל ביקור נוסף תוך 2 ימי עסקים מהביקור הקודם',
    'VERY_URG': 'ביקור ראשון ביום קבלת המסמכים, וכל ביקור נוסף תוך 2 ימי עסקים מהביקור הקודם',
}

const urgencyType10 = {
    'REG': 'ביצוע תוך 7 ימי עסקים מיום קבלת המסמכים',
    'URG': 'ביצוע תוך 3 ימי עסקים מיום קבלת המסמכים',
    'VERY_URG': 'ביצוע תוך 1 ימי עסקים מיום קבלת המסמכים',
}
const urgencyType11 = {
    'REG': 'ביצוע תוך 5 ימי עסקים (יום ההזמנה לא נספר במניין הימים)',
    'URG': 'ביצוע תוך 3 ימי עסקים (יום ההזמנה לא נספר במניין הימים)',
    'VERY_URG': 'ביצוע תוך 1 ימי עסקים (יום ההזמנה לא נספר במניין הימים)',
}
const urgencyType12 = {
    'REG': 'ביצוע עד סוף יום העסקים (18:00)',
    'URG': 'ביצוע תוך 4 שעות',
    'VERY_URG': 'ביצוע תוך 2 שעות',
}

export const urgencyMap = {
    'courtSubmission': urgencyType1,
    'apostilSubmission': urgencyType2,
    'apostilInCourt': urgencyType10,
    'form9': urgencyType1,
    'personLocate': urgencyType3,
    'personalDelivery': urgencyType9,
    'officeDelivery': urgencyType5,
    'landRegistration': urgencyType6,
    'inheritanceRegistration': urgencyType1,
    'documentTransfer': urgencyType7,
    'companiesRegistration': urgencyType1,
    'partnershipsRegistration': urgencyType1,
    'associationsAndCompanies': urgencyType1,
    'smallClaim': urgencyType8,
    'personalDeliveryClaim': urgencyType9,
    'printingBinding': urgencyType11,
    'landSupervisor': urgencyType6,
    'specialUrgencyPrice': urgencyType12
}

export const getExtraTextUrgency = (urgency, type, isShowOther) => {
    const optionA = 'REG';
    const optionB = 'URG';
    const optionC = 'VERY_URG';

    switch (type) {

        case 'personalDelivery':
        case 'personalDeliveryClaim':
        case 'officeDelivery':
        case 'inheritanceRegistration':
        case 'landRegistration':
        case 'courtSubmission':
        case 'companiesRegistration':
        case 'partnershipsRegistration':
        case 'associationsAndCompanies':
        case 'landSupervisor':
            return urgency === optionA ? '' : `*חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה ${urgency === optionC ? '12:00' : '15:00'} יחל ביום שלמחרת*`
        case 'documentTransfer':
            return isShowOther ? '' : (urgency === optionA ? '' : `*חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה ${urgency === optionC ? '12:00' : '15:00'} יחל ביום שלמחרת*`)
        case 'personLocate':
            return urgency === optionC ? '*חישוב הזמנים עבור הזמנות שיתקבלו לאחר השעה 12:00 יחל ביום שלמחרת*' : ''
        case 'apostilInCourt':
            return urgency === optionB ? '(במקרה של מעל 20 מסמכים, יום עסקים נוסף עבור כל 10 מסמכים)' :
                (urgency === optionC ? '(במקרה של מעל 10 מסמכים, יום עסקים נוסף עבור כל 10 מסמכים)' : '')
        case 'apostilSubmission':
            return ''
        default:
            return ''
    }
}

//Get the pages text
export const getTextByPages = (pages) => {
    switch (pages) {
        case 49:
            return '0-50 עמודים להדפסה'
        case 60:
            return '51-100 עמודים להדפסה'
        case 150:
            return '101-200 עמודים להדפסה'
        case 250:
            return '201-300 עמודים להדפסה'
        case 350:
            return '301-400 עמודים להדפסה'
        case 450:
            return '401-500 עמודים להדפסה'
        case 550:
            return '501+ עמודים להדפסה'
        default:
            return ''
    }
}

//collectMethodMap && returnMethodMap
const pickup = 'איסוף עם שליח'
const upload = 'העלאת קבצים'
const delivery = 'החזרה עם שליח'
const uploadProof = 'סריקת הוכחות'
const collectBranch = 'מסירה בסניף'
const collectBranch_2 = 'איסוף מהסניף'
const returnBranch = 'איסוף מהסניף'
const collectDelivery = 'מסירה עם שליח'

export const collectMethodMap = {
    'courtSubmission': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : upload },
    'personalDelivery': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : upload },
    'personalDeliveryClaim': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : upload },
    'officeDelivery': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : upload },
    'apostilSubmission': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : collectBranch },
    'apostilInCourt': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : collectBranch },
    'landRegistration': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : upload },
    'documentTransfer': (collecitonMethod) => { return collecitonMethod === 'pickup' ? '' : '' },
    'inheritanceRegistration': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : upload },
    'companiesRegistration': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : upload },
    'partnershipsRegistration': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : upload },
    'associationsAndCompanies': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : upload },
    'printingBinding': (collecitonMethod) => { return collecitonMethod === 'pickup' ? collectDelivery : collectBranch_2 },
    'landSupervisor': (collecitonMethod) => { return collecitonMethod === 'pickup' ? pickup : upload },
}

export const returnMethodMap = {
    'courtSubmission': (returnMethod) => { return returnMethod === 'delivery' ? delivery : uploadProof },
    'officeDelivery': (returnMethod) => { return returnMethod === 'delivery' ? delivery : uploadProof },
    'personalDelivery': (returnMethod) => { return returnMethod === 'delivery' ? delivery : uploadProof },
    'personalDeliveryClaim': (returnMethod) => { return returnMethod === 'delivery' ? delivery : uploadProof },
    'personLocate': (returnMethod) => { return returnMethod === 'delivery' ? delivery : uploadProof },
    'apostilSubmission': (returnMethod) => { return returnMethod === 'delivery' ? delivery : returnBranch },
    'apostilInCourt': (returnMethod) => { return returnMethod === 'delivery' ? delivery : returnBranch },
    'documentTransfer': (returnMethod) => { return returnMethod === 'delivery' ? '' : '' },
    'inheritanceRegistration': (returnMethod) => { return returnMethod === 'delivery' ? delivery : uploadProof },
    'companiesRegistration': (returnMethod) => { return returnMethod === 'delivery' ? delivery : uploadProof },
    'partnershipsRegistration': (returnMethod) => { return returnMethod === 'delivery' ? delivery : uploadProof },
    'associationsAndCompanies': (returnMethod) => { return returnMethod === 'delivery' ? delivery : uploadProof },
}

