import React, { useEffect, useState, useRef } from 'react'
import arrow from '../../images/dropdown-arrow.svg';
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import he from 'date-fns/locale/he';

export const UserOrdersFilterType = ({ types, placeholder, setFilterBy, filterBy, filterType, inputWidth }) => {
    const ref = useRef();
    const [actionType, setActionType] = useState({
        type: filterBy.type || '',
        typeToFilter: filterBy.typeToFilter || ''
    });
    const [isClicked, setIsClicked] = useState(false);
    const [isDatePicker, setIsDatePicker] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    registerLocale('he', he)

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    useEffect(() => {
        if (filterType === 'actionType') setActionType(filterBy.actionType);
        else if (filterType === 'dateType') setActionType(filterBy.dateType);
        else if (filterType === 'userType') setActionType(filterBy.userType);

    }, []);

    useEffect(() => {
        if (startDate && endDate && (actionType.typeToFilter === 'chooseDate')) {
            setActionType((prevFilterBy) => ({
                ...prevFilterBy,
                type: `${getDateFormat(startDate)} - ${getDateFormat(endDate)}`,
                startDate,
                endDate
            }));
            setIsDatePicker(false)
        }
    }, [dateRange])

    useEffect(() => {
        if ((filterType === 'dateType') && (actionType.typeToFilter === 'chooseDate')) {
            if (startDate && endDate || !actionType.type) setIsDatePicker(false)
            else setIsDatePicker(true)
        }
        else {
            setIsDatePicker(false)
        }
        setDateRange([null, null])
    }, [actionType])


    useEffect(() => {
        setFilterBy((prevFilterBy) => ({
            ...prevFilterBy,
            [filterType]: actionType
        }));

        if (!actionType.type) {
            clearActionType()
        }
    }, [actionType])

    useEffect(() => {
        const onBodyClick = (event) => {
            if (ref?.current?.contains(event.target)) {
                return;
            }
            setIsDropdownOpen(false);
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });

        return () => {
            document.body.removeEventListener("click", onBodyClick, { capture: true });
        };
    }, []);

    const clearActionType = () => {
        setFilterBy((prevFilterBy) => ({
            ...prevFilterBy,
            [filterType]: {
                type: '',
                typeToFilter: ''
            }
        }));
    }

    const getDateFormat = (date) => {
        const yyyy = date.getFullYear();
        let mm = date.getMonth() + 1; // Months start at 0!
        let dd = date.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        const formattedToday = dd + '/' + mm + '/' + yyyy;
        return formattedToday
    }

    const closeDatePicker = () => {
        setIsDatePicker(false)
    }

    return (
        <div
            ref={ref}
            className={
                isDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
            }
            style={{ width: `${inputWidth}` }}
        >
            {isDatePicker ?
                <DatePicker
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                        // if (startDate && endDate) setIsDatePicker(false)
                    }}
                    // isClearable={true}
                    locale="he"
                    open={isDatePicker}
                    onClickOutside={closeDatePicker}
                    customInput={
                        <div className='dropdown-input' style={{ width: `100%` }}>
                            <input
                                type="text"
                                placeholder={placeholder}
                                onChange={(event) => {
                                    setActionType((prevFilterBy) => ({
                                        ...prevFilterBy,
                                        type: event.target.value
                                    }));
                                    setIsDropdownOpen(true);
                                    // clearActionType();
                                }}
                                value={actionType.type}
                                onClick={() => { if (!isDatePicker) setIsDropdownOpen(true); }}
                            />
                            <button
                                type='button'
                                onClick={() => {
                                    if (!isDatePicker) setIsDropdownOpen(!isDropdownOpen);
                                }}
                            >
                                <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                            </button>
                        </div>
                    }
                />
                :
                <div className='dropdown-input' style={{ width: `100%` }}>
                    <input
                        type="text"
                        placeholder={placeholder}
                        onChange={(event) => {
                            setActionType((prevFilterBy) => ({
                                ...prevFilterBy,
                                type: event.target.value
                            }));
                            setIsDropdownOpen(true);
                            // clearActionType();
                            setIsClicked(false);
                        }}
                        value={actionType.type}
                        onClick={() => { setIsDropdownOpen(true); }}
                    />
                    <button
                        type='button'
                        onClick={() => {
                            setIsDropdownOpen(!isDropdownOpen);
                        }}
                    >
                        <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                    </button>
                </div>
            }



            <div className='dropdown-list' style={{ width: `100%` }}>
                {types.map((actionTypeMap, idx) => {
                    if (actionTypeMap.type.includes(actionType.type) || isClicked)
                        return (
                            <div className='dropdown-option' key={idx} onMouseDown={() => { setActionType(actionTypeMap); setIsDropdownOpen(!isDropdownOpen); setIsClicked(true) }}>
                                <p
                                    htmlFor={'actionTypeMap' + idx}
                                >
                                    {actionTypeMap.type}
                                </p>
                                <input
                                    id={'actionTypeMap' + idx}
                                    type="radio"
                                    name={filterType}
                                    value={actionTypeMap.type}
                                    onChange={() => {
                                        setActionType(actionTypeMap);
                                        setIsDropdownOpen(!isDropdownOpen);
                                        setIsClicked(false);
                                    }}
                                    checked={filterBy[filterType].type === actionTypeMap.type}
                                />
                            </div>
                        );
                })}
            </div>
        </div>
    )
}
