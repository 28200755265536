import React, { useEffect, useState, useRef } from 'react';
import remove from '../../images/remove1.png';
import edit from '../../images/edit.svg';
import { Field } from 'formik';
import arrow from '../../images/dropdown-arrow.svg';
import { Tooltip } from '../Utils/Tooltip'
import info from '../../images/info.svg'

export const ClaimCompanyName = ({ isError, errors, handleChanges, defendantState, setDefendantState, onSetChoose, handleChangeData }) => {

  const [searchCompany, setSearchCompany] = useState('');
  const [isCompaniesDropdownOpen, setIsCompaniesDropdownOpen] = useState(false);
  const [companies, setCompanies] = useState([])
  const refCompany = useRef()

  const tooltipStr = 'ניתן לבחור את החברה הנתבעת מרשימת החברות לצורך השלמה אוטומטית של פרטי החברה כפי שאלו מעודכנים ברשם החברות. אם יש ברשותכם נתונים אחרים, ניתן לעדכן את השדות בהתאם.'

  useEffect(() => {
    const onBodyClick = (event) => {
      if (refCompany?.current?.contains(event.target)) {
        return;
      }
      setIsCompaniesDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    setSearchCompany(defendantState?.defendantCompanyName || '')

    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);

  useEffect(() => {
    getCompanies()
  }, [searchCompany]);

  const onBlur = () => {
    if (defendantState?.defendantCompanyName !== searchCompany) {
      setSearchCompany(defendantState?.defendantCompanyName ? defendantState?.defendantCompanyName : '')
    }

    setIsCompaniesDropdownOpen(false)
  }

  const getCompanies = async () => {
    try {
      const companyRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=f004176c-b85f-4542-8901-7b3176f9a054&q=${searchCompany}`, { signal: AbortSignal.timeout(5000) })
      const companies = await companyRes.json()
      setCompanies(companies.result.records)
    } catch (err) {
      console.log('Fetch error: ', err)
    }
  }

  return (
    <div className='company-name'>
      <div className='tooltip-company-name'>
        <Tooltip text={tooltipStr} >
          <img src={info} alt="info-icon" className="info-icon" />
        </Tooltip>
      </div>

      <div className='field'>
        <label className='input-label'>
          שם החברה*
        </label>

        <div
          ref={refCompany}
          className={
            isCompaniesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
          }
        >
          <div className='dropdown-input'>
            <input
              type="text"
              onBlur={onBlur}
              onChange={(event) => {
                setSearchCompany(
                  event.target.value
                );
                handleChangeData(event)
                setIsCompaniesDropdownOpen(true);
              }}
              value={searchCompany}
              onClick={() => { setIsCompaniesDropdownOpen(true); }}
              name='defendantCompanyName'
              autoComplete="off"
              className={
                isError('defendantCompanyName')
                  ? 'error'
                  : defendantState?.defendantCompanyName
                    ? 'success'
                    : ''
              }
            />
            <button
              type='button'
              onClick={() => {
                setIsCompaniesDropdownOpen(!isCompaniesDropdownOpen);
              }}
            >
              <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
            </button>
          </div>

          {isError('defendantCompanyName') && <p className='error-label'>{errors.defendantCompanyName}</p>}

          <div className='dropdown-list'>
            {companies?.filter((option) => option['שם חברה'].includes(searchCompany)).map((company, idx) => {
              return (
                <div className='dropdown-option' key={idx}>
                  <p
                    onMouseDown={() => {
                      const companyName = company['שם חברה'].trim()
                      onSetChoose('defendantCompanyName', companyName, company)
                      setSearchCompany(companyName)
                      setIsCompaniesDropdownOpen(!isCompaniesDropdownOpen);
                    }}>
                    {company['שם חברה']}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClaimCompanyName;
