import React, { useState, useEffect } from 'react'
import { CollectFileDetails } from './CollectFileDetails'
import { DepositFileDetails } from './DepositFileDetails'
import { DocumentsReadyUpdate } from './DocumentsReadyUpdate'
import { UploadFile } from './UploadFile'
import { AddressCollectDocumentsUpdate } from './AddressCollectDocumentsUpdate'
import { CollectDocumentsOrUploadFile2 } from './CollectDocumentsOrUploadFile2'
import { TextToggle } from './PageUtils/TextToggle';
import { Tooltip } from '../../Utils/Tooltip'

// {
//     office: cityName,
//     actionInOffice: collectFile,
//     currFile: 0,
//     collectFiles:
//         [
//            {submiterName: , submiterId: , powerOfAttorney: , files: [{fileNum: , },{},{},{}]}
//            {submiterName: , submiterId: , powerOfAttorney: , files: [{fileNum: , },{},{},{}]}

//         ],
//     depositFiles: fileQuantity: 0, toll: 0 , 
//     address: {},
//         }

export const ActionInOffice = ({ data, setData, formState, errors, isError, type, handleChanges, setTouch, touch, submitForm, setSubmitForm, isTranslation, pageName }) => {
    const [alignment, setAlignment] = useState('right');
    useEffect(() => {
        const { actionInOffice, isFromDeposit } = data
        if (actionInOffice === 'depositFile' || isFromDeposit === 'true' || data.isOtherSubmitter) {
            const { depositFiles } = data
            setAlignment('left')
        } else if (actionInOffice === 'collectFile') {
            setAlignment('right');
        } else {
            setAlignment('right');
            setData({ ...data, actionInOffice: 'collectFile' })
        }
    }, []);

    const handleChangeData = (event, newAlignment) => {
        event.preventDefault();
        setAlignment(newAlignment);
        const field = event.target.name;
        const value = event.target.value;
        if (value === 'depositFile') {
            setData({ ...data, [field]: value });
        }
        else setData({ ...data, [field]: value });
    }

    const getFirstOptionNameByType = () => {
        switch (type) {
            case 'landRegistration':
                return 'איסוף תיק'
            case 'landSupervisor':
                return 'איסוף מסמכים'
            default:
                return ''
        }
    }
    const getSecondOptionNameByType = () => {
        switch (type) {
            case 'landRegistration':
                return 'הפקדת תיק'
            case 'landSupervisor':
                return 'הגשת מסמכים'
            default:
                return ''
        }
    }

    const getDepositFileCmps = () => {
        switch (type) {
            case 'landRegistration':
                return <>
                    <DepositFileDetails data={data} setData={setData} />
                    <div className='cmp-line' />
                    <DocumentsReadyUpdate data={data} setData={setData} type={type} />
                    <div className='cmp-line' />
                    <AddressCollectDocumentsUpdate data={data} setData={setData} isError={isError} errors={errors} handleChanges={handleChanges} type={type} setTouch={setTouch} touch={touch} />
                </>
            case 'landSupervisor':
                return <>
                    <Tooltip text={'ניתן לבחור בין הפקדה מצולמת בתיבת לשכת המפקח, או זימון תור למסירת המסמכים בפני נציג המפקח, והחתמת הדף הראשון בחותמת ״נתקבל״ בימים ראשון ורביעי בזימון תור מראש שיוזמן על ידינו ובהתאם לזמינות.'}>
                        <TextToggle data={data} setData={setData} formParam={'isDeposit'} type={type} />
                    </Tooltip>
                    <div className='cmp-line' />
                    <CollectDocumentsOrUploadFile2 setData={setData} data={data} type={type} isError={isError} errors={errors} handleChanges={handleChanges} setTouch={setTouch} touch={touch} submitForm={submitForm} setSubmitForm={setSubmitForm} isTranslation={isTranslation} pageName={pageName} />
                    {/* <TextToggle data={data} setData={setData} formParam={'isSign'} priceText={'תוספת 15₪'} /> */}
                </>
            default:
                return <></>
        }
    }

    return (
        <div className='main-form action-in-office'>
            <div className='modal-content-container block'>
                <h2 className='large-title'>בחרו את הפעולה הנדרשת בלשכה</h2>

                <div className={`form-main-toggle ${alignment} btm`}>
                    <span></span>
                    <button
                        name='actionInOffice'
                        value='collectFile'
                        onClick={(ev) => handleChangeData(ev, 'right')}
                    >
                        {getFirstOptionNameByType()}
                    </button>
                    <button
                        name='actionInOffice'
                        value='depositFile'
                        onClick={(ev) => handleChangeData(ev, 'left')}
                    >
                        {getSecondOptionNameByType()}
                    </button>
                </div>

                <div className='cmp-line' />
                {alignment === 'left' ?
                    <>
                        {getDepositFileCmps()}
                    </>

                    :
                    type === 'landRegistration' ?
                    <UploadFile data={data} setData={setData} errors={errors} isError={isError} submitForm={submitForm} setSubmitForm={setSubmitForm} isTranslation={isTranslation} pageName={pageName} type={type} formState={formState}/>:
                    <CollectFileDetails data={data} setData={setData} errors={errors} isError={isError} isFromActionInOffice={true} />}
            </div>
        </div>
    )
}