import React from 'react'
import remove from '../../images/remove-grey.svg';
import edit from '../../images/edit.svg';

export const UserAddressPreview = ({ address, onEditAddress, onRemoveAddress, editAddress }) => {
    return (
        <div className={`user-address-preview ${(address.id === editAddress?.id) ? 'selected' : ''}`} onClick={() => onEditAddress(address)}>
            <div className="title">

                <p> {`${address.streetAddress} ${address.streetNumber} ,${address.city}`}</p>
                {(address.id !== editAddress?.id) && <div className="btn-container" >
                    <img
                        className='icon-edit'
                        src={edit}
                        alt='info'
                        onClick={(event) => {
                            event.stopPropagation();
                            onEditAddress(address);
                        }}
                    />
                    <img
                        src={remove}
                        alt='remove'
                        onClick={(event) => {
                            event.stopPropagation();
                            onRemoveAddress(address);
                        }}
                    />
                </div>}
            </div>
            <div className="details">
                <p>קומה: {address.floor}</p>
                <p>דירה: {address.apartmentNumber}</p>
                <p>הערות: {address.comments}</p>
            </div>
        </div>
    )
}
