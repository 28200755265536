import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { callUserAction } from '../../../integrations/firebase'
import { updateUser } from '../../../store/actions/userActions'
import { Tooltip } from '../../Utils/Tooltip'
import info from '../../../images/info.svg'

export const InternalUse = () => {
    const activeUser = useSelector((state) => state.userReducer.user);
    const dispatch = useDispatch();
    const [options, setOptions] = useState([
        { optionTitle: 'תיק לחיוב', optionTitleEn: 'billedCase', isRequired: activeUser?.isCaseNeeded ? true : false },
        { optionTitle: 'גורם מאשר', optionTitleEn: 'authorizer', isRequired: activeUser?.isAuthNeeded ? true : false },
    ])
    const [isCaseLoading, setIsCaseLoading] = useState(false)
    const [isAuthLoading, setIsAuthLoading] = useState(false)

    const tooltipOption = {
        'billedCase': 'בסימון חובה עבור תיק לחיוב, כל המשתמשים בארגון יידרשו למלא שדה של פרטי תיק לחיוב במסך סיכום של כל הזמנה. ללא הזנת פרטים בשדה זה, לא יהיה ניתן להשלים את ההזמנה.',
        'authorizer': 'בסימון חובה עבור גורם מאשר, כל המשתמשים בארגון יידרשו למלא שדה של גורם מאשר במסך סיכום של כל הזמנה. ללא הזנת פרטים בשדה זה, לא יהיה ניתן להשלים את ההזמנה.',
    }

    const handleAlignment = (option) => {
        if (!option.isRequired)
            return 'right'
        return 'left'
    }

    const onChangeCaseNeeded = async () => {
        if (isCaseLoading) return
        setIsCaseLoading(true)
        const isCaseNeeded = !options[0].isRequired

        const updatedOptions = [...options]
        updatedOptions[0] = { optionTitle: 'תיק לחיוב', optionTitleEn: 'billedCase', isRequired: isCaseNeeded }
        setOptions([...updatedOptions])

        const updatedUser = {
            ...activeUser,
            isCaseNeeded
        }

        try {
            dispatch(updateUser({ ...updatedUser }));
            await callUserAction({
                companyId: activeUser.companyId,
                isCaseNeeded,
                type: "setCustomerInfo"
            })
            setIsCaseLoading(false);
        }
        catch ({ message }) {
            console.log('%c  error:', 'color: white;background: red;', message);
        }
    }

    const onChangeAuthNeeded = async () => {
        if (isAuthLoading) return
        setIsAuthLoading(true)
        const isAuthNeeded = !options[1].isRequired

        const updatedOptions = [...options]
        updatedOptions[1] = { optionTitle: 'גורם מאשר', optionTitleEn: 'authorizer', isRequired: isAuthNeeded }
        setOptions([...updatedOptions])

        const updatedUser = {
            ...activeUser,
            isAuthNeeded
        }
       
        try {
            dispatch(updateUser({ ...updatedUser }));
            await callUserAction({
                companyId: activeUser.companyId,
                isAuthNeeded,
                type: "setCustomerInfo"
            })
            setIsAuthLoading(false);
        }
        catch ({ message }) {
            console.log('%c  error:', 'color: white;background: red;', message);
        }
    }

    const handleChangeData = async (option) => {
        const isBilledCaseChanged = option.optionTitleEn === 'billedCase' ? true : false
        if (isBilledCaseChanged) onChangeCaseNeeded()
        else onChangeAuthNeeded()
    }

    return (
        <div className='internal-use-container'>
            <h3 className='user-company-title'>לשימוש פנימי</h3>
            <p className='sub-title'>{'סמנו האם לחייב את המשתמשים בארגון למלא את הפרטים הבאים:'}</p>
            <div className='internal-use-options'>
                {options.map(option => {
                    return (<div className='option-container'>
                        <Tooltip text={tooltipOption[option.optionTitleEn]}>
                            <img src={info} alt="info-icon" className="info-icon" />
                        </Tooltip>
                        <p className='option-title'>{`${option.optionTitle}`}</p>
                        <div
                            className={`form-main-toggle  ${handleAlignment(option)}`}
                        >
                            <span className={`${!option.isRequired ? 'gray' : ''}`}></span>
                            <button
                                onClick={() =>
                                    handleChangeData(option)
                                }
                            >
                                לא חובה
                            </button>
                            <button
                                onClick={() =>
                                    handleChangeData(option)
                                }
                            >
                                חובה
                            </button>
                        </div>
                        {/* <p>{option.isRequired ? 'חובה' : 'לא חובה'}</p> */}
                    </div>)
                })}

            </div>
        </div>
    )
}
