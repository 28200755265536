import React, { useEffect, useState, useRef } from 'react';
import OrdersService from '../../../services/orderService';
import remove from '../../../images/remove1.png';
import info from '../../../images/info.svg';
import arrow from '../../../images/arrowdropdown.png';
import plusWhite from '../../../images/plusWhite.svg';
import { doc, updateDoc } from '@firebase/firestore';
import db, { Logger } from '../../../integrations/firebase';
import { updateUser } from '../../../store/actions/userActions'

import { Field } from 'formik';
import { TooltipTriangle } from '../../Utils/TooltipTriangle'
import { updateErrorModal } from '../../../store/actions/systemActions';
import { useSelector, useDispatch } from 'react-redux';

import {
  documentsOptionsPersonalDelivery,
  documentsOptionsOfficeDelivery
} from '../../../consts/data'

const logClient = new Logger(`WhatHanded`);

export const WhatHanded = ({
  data,
  setData,
  isError,
  errors,
  submitForm,
  setSubmitForm,
  type
}) => {
  const activeUser = useSelector((state) => state.userReducer.user);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const ref = useRef()
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');
  const [isAddedSearch, setIsAddedSearch] = useState();
  const [options, setOptions] = useState([]);
  // const [options, setOptions] = useState([
  //   // { documentName: '', caseNumber: '', docType: 'אחר', isOther: true, placeholder: 'אחר' },
  //   // { documentName: 'מסמכים בהליך הוצל״פ', caseNumber: '', docType: '', isTitle: true },
  //   // { documentName: '', caseNumber: '', docType: 'אחר', isOther: true, placeholder: 'אחר (מסמך הליך אזרחי שאינו ברשימה)' },
  // ]);

  useEffect(() => {
    const userDocumentsToAdd = activeUser.userDocuments ? activeUser.userDocuments : {};
    switch (type) {
      case 'personalDelivery':
        setOptions([...documentsOptionsPersonalDelivery, ...(userDocumentsToAdd.personalDelivery || [])]);
        break;
      case 'officeDelivery':
        setOptions([...documentsOptionsOfficeDelivery, ...(userDocumentsToAdd.officeDelivery || [])]);
        break;
      default:
        break;
    }
  }, [])

  useEffect(() => {
    if (search) {
      //Here check if already added and if added replace
      if (isAddedSearch) {
        const updatedoptions = [...options]
        updatedoptions.pop()
        updatedoptions.push({ documentName: search, caseNumber: '', docType: search, isNew: true })
        setOptions(updatedoptions)
      } else {
        setIsAddedSearch(true)
        setOptions((prevOptions) => [
          ...prevOptions,
          { documentName: search, caseNumber: '', docType: search, isNew: true },
        ]);
      }
    } else if (!search && isAddedSearch) {
      setIsAddedSearch(false)
      const updatedoptions = [...options]
      updatedoptions.pop()
      setOptions(updatedoptions)
    }
  }, [search]);


  const personalDeliveryTooltipText = 'שם המסמך ומספר ההליך יופיעו בטופס אישור המסירה ובתצהיר מוסר, אותו אנו ניצור אוטומטית לאחר השלמת ההזמנה.'

  useEffect(() => {
    const onBodyClick = (event) => {
      if (ref.current.contains(event.target)) {
        return;
      }
      setIsDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener("click", onBodyClick, {
        capture: true,
      });
    };
  }, []);

  useEffect(() => {
    setSelectedOptions(
      data.docsForSubmission && data.docsForSubmission.length > 0
        ? data.docsForSubmission
        : []
    );
    if (!data.docsForSubmission) {
      setData({
        ...data,
        docsForSubmission: [],
      });
    }
  }, []);

  const updateUserData = async (userDocuments) => {
    try {
      const userRef = doc(db, "users", activeUser.uid);
      dispatch(updateUser({ ...activeUser, userDocuments }));
      await updateDoc(userRef, { userDocuments }, { merge: true }).then((r) => { }).catch((e) => {
        logClient.error('error when updating user addresses');
        logClient.error(JSON.stringify(userDocuments));
        logClient.error(JSON.stringify(e));
      });
    } catch (error) {
      console.log('%c  error:', 'color: white;background: red;', error.message);
    }
  };

  useEffect(() => {
    let isOther = false
    if (errors.docsForSubmission && Array.isArray(errors.docsForSubmission)) isOther = true
    if (submitForm && errors.docsForSubmission) {
      setSubmitForm(false);
      dispatch(
        updateErrorModal({
          title: 'שגיאה',
          // errorText: 'לא נבחרו מסמכים להגשה',
          errorText: isOther ? 'נדרש למלא את שם המסמך האחר' : 'חובה לבחור מסמך',
          submitText: 'אישור',
          isCancel: false
        })
      );
    }

    if (submitForm && Object.entries(errors).length === 0 && !activeUser?.isAnonymous && !activeUser?.accessToken) {
      const newOptionsForUser = data.docsForSubmission.filter(option => option.isNew)
      if (newOptionsForUser.length) {
        const newOptionsWithoutIsNew = newOptionsForUser.map((obj) => {
          const { id, isNew, ...rest } = obj;
          return rest;
        });
        const userDocuments = activeUser.userDocuments ? activeUser.userDocuments : {}
        const documentsOriginal = userDocuments[type] ? userDocuments[type] : []

        const isDuplicateDocument = (docName, documentsArray) => {
          return documentsArray.some((doc) => doc.documentName === docName);
        };

        const deliveryDocuments = documentsOriginal.slice();

        newOptionsWithoutIsNew.forEach((option) => {
          if (!isDuplicateDocument(option.documentName, deliveryDocuments)) {
            deliveryDocuments.push(option);
          }
        });

        // If nothing changed
        if (deliveryDocuments.length === documentsOriginal.length) return

        userDocuments[type] = deliveryDocuments
        updateUserData(userDocuments)
      }
    }
  }, [data.docsForSubmission, submitForm, errors]);

  // useEffect(() => {
  //   if (submitForm && errors.docsForSubmission) {
  //     setSubmitForm(false);
  //     dispatch(
  //       updateErrorModal({
  //         title: 'שגיאה',
  //         errorText: 'לא נבחרו מסמכים למסירה',
  //         submitText: 'אישור',
  //         isCancel: false
  //       })
  //     );
  //   }
  // }, [data.docsForSubmission, submitForm, errors]);

  const onSetSelection = (optionSelected) => {
    if (optionSelected.isTitle === true) return
    setSelectedOptions([
      ...selectedOptions,
      { ...optionSelected, id: OrdersService.makeId() },
    ]);
    setData({
      ...data,
      docsForSubmission: [
        ...selectedOptions,
        { ...optionSelected, id: OrdersService.makeId() },
      ],
    });
    setIsDropdownOpen(false)
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    const idx = selectedOptions.findIndex((option) => option.id === id);
    if (idx === -1) return;
    selectedOptions[idx][name] = value;
    setSelectedOptions([...selectedOptions]);
    setData({
      ...data,
      docsForSubmission: selectedOptions,
    });
  };

  const onDeleteDocument = (id) => {
    const idx = selectedOptions.findIndex((option) => option.id === id);
    if (idx === -1) return;
    selectedOptions.splice(idx, 1);
    setSelectedOptions([...selectedOptions]);
    setData({
      ...data,
      docsForSubmission: selectedOptions,
    });
  };

  return (
    <div className='main-form form7-what-served'>
      <div className='modal-content-container'>
        <h2 className='large-title'>מה מוסרים?</h2>
        <p className='p1 subtitle first-sub'>
          כתבו את שם המסמך שמוסרים ובחרו את התוצאה מהרשימה הנפתחת. ניתן לבחור יותר ממסמך אחד
        </p>
        <p className='p1 subtitle'>
          {type === 'personalDelivery' ?
            'שם המסמך ומספר ההליך יופיעו בטופס אישור המסירה תחת ״מהות הכתב הנמסר״'
            :
            'ניתן לרשום ולהוסיף מסמכים שלא מופיעים ברשימה'
          }
        </p>

        <div
          ref={ref}
          className={
            isDropdownOpen ? 'dropdown-container open' : 'dropdown-container'
          }
        >
          <div className='dropdown-input' >
            <Field
              type="text"
              className={isError('docsForSubmission') ? 'error' : ''}
              placeholder='דוגמה: כתב תביעה לפירוק שיתוף'
              autoComplete="new-password"
              onChange={(event) => {
                setSearch(
                  isError('toll')
                    ? errors.docsForSubmission
                    : event.target.value
                );
              }}
              value={search}
              onClick={() => setIsDropdownOpen(true)}

            />
            <button
              type='button'
              onClick={() => {
                setSearch(isDropdownOpen === true ? '' : search);
                setIsDropdownOpen(!isDropdownOpen);
              }}
            >
              <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
            </button>
          </div>

          <div className='dropdown-list'>
            {options
              .filter((option) => option.docType.includes(search))
              .map((option, idx) => {
                return (
                  <div
                    key={idx}
                    className='dropdown-option'
                    onClick={() => {
                      onSetSelection(option);
                    }}
                  >
                    <p className={(option.isTitle ? 'bold' : '')} >{(option.documentName.length >= 1) ? option.documentName : option.placeholder}</p>
                    {!option.isTitle && <div>
                      <button className='btn-add' type='button'>
                        +
                        {/* <img src={plusWhite} /> */}
                      </button>
                    </div>}
                  </div>
                );
              })}
          </div>
        </div>

        {selectedOptions.length > 0 && (
          <div className='selected-options'>
            <h3>{`רשימת המסמכים ${data.type === 'personalDelivery' ? 'למסירה' : 'להמצאה'}`}</h3>
            {selectedOptions.map((option, idx) => {
              return (
                <div key={idx} className='selected-item'>
                  <div className='selected-doc-name'><p className="p-med-bold">{option?.docType}</p>

                    {option.isOther === true && (
                      <>
                        {/* <input
                          type="text"
                          placeholder={option.placeholder}
                          onChange={(e) => handleChange(e, option.id)}
                          name='documentName'
                          value={option?.documentName || ''}
                        /> */}
                        <Field
                          placeholder='הזינו את שם המסמך'
                          onChange={(e) => handleChange(e, option.id)}
                          name='documentName'
                          value={option?.documentName || ''}
                          autoComplete="new-password"
                          type="text"
                          className={
                            isError('documentName')
                              ? 'error'
                              : option?.documentName?.length > 0
                                ? 'success'
                                : ''
                          }

                        />
                        {isError('documentName') && <p className='error-label'>{errors.documentName}</p>}
                      </>
                    )}
                  </div>
                  <input
                    type="text"
                    placeholder='מספר ההליך בפני הערכאה השיפוטית'
                    name='caseNumber'
                    onChange={(e) => handleChange(e, option.id)}
                    value={option?.caseNumber || ''}
                    className={option?.caseNumber?.length > 0 ? 'success' : ''}

                  />
                  {data.type === 'personalDelivery' &&
                    <div className="tooltip-container">
                      <TooltipTriangle text={personalDeliveryTooltipText}>
                        <button type='button' className='btn info' style={{ color: 'black' }}>מידע חשוב</button>
                      </TooltipTriangle>
                    </div>
                  }

                  <div className='btn-container'>
                    {/* <img className='icon-info' src={info} alt='info' /> */}

                    <img
                      className='btn-remove'
                      src={remove}
                      alt='remove'
                      onClick={(e) => onDeleteDocument(option.id)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div >
  );
};

export default WhatHanded;
