import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { doc, onSnapshot } from "@firebase/firestore";
import db from '../../integrations/firebase'
import { CompanyName } from './UserCompany/CompanyName'
import { InternalUse } from './UserCompany/InternalUse'
import { UsersOfCompany } from './UserCompany/UsersOfCompany'
import { useNavigate } from "react-router-dom";

export const UserCompany = () => {
    const activeUser = useSelector((state) => state.userReducer.user);
    const company = useSelector((state) => state.companyReducer.company)
    const navigate = useNavigate();

    useEffect(() => {
        if (activeUser?.accountType === 'חשבון פרטי' || (company && company?.masters && !company.masters.includes(activeUser.uid))) {
            navigate('/user/details')
        }
    }, [company]);

    return (
        <div className='user-company'>
            <>
                <CompanyName company={company} />
                <InternalUse company={company} />
                <UsersOfCompany company={company} />
            </>
        </div>
    )
}
