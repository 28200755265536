import React from 'react'
import { TailSpin } from 'react-loader-spinner'

export const MasterToggle = ({ masterLoading, idx, handleAlignment, user, onChangeMaster }) => {
   
    return (
        <>
            {masterLoading.isLoading && masterLoading.idxLoading === idx ?
                <div className='spinner'>
                    <TailSpin
                        heigth="15"
                        width="15"
                        color='#4e71ff'
                        ariaLabel='loading'
                    />
                </div>
                :
                <>
                    <div className={`form-main-toggle  ${handleAlignment(user)} ${user.userActivity === 'not-connected' ? 'un-pointer' : ''}`}>
                        <span className={`${user.isMaster ? '' : 'gray'}`}></span>
                        <button
                            onClick={(e) => {
                                onChangeMaster(user, user.isMaster, idx);
                            }}
                        >
                        </button>
                        <button
                            onClick={(e) => {
                                onChangeMaster(user, user.isMaster, idx);
                            }}
                        >
                        </button>
                    </div>
                    <p>{user.isMaster ? 'כן' : 'לא'}</p>
                </>
            }
        </>
    )
}
