import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// import { updateErrorModal } from '../../store/actions/systemActions';
// import { useNavigate } from 'react-router-dom'
export const UserMain = () => {
    const activeUser = useSelector((state) => state.userReducer.user);
    // const dispatch = useDispatch();
    // const navigate = useNavigate();

    // useEffect(() => {
    //     // if (!activeUser?.fullName) {
    //     //     navigate(-1)
    //     // }
    //     // if (!activeUser?.emailVerified) {
    //     //     dispatch(
    //     //         updateErrorModal({
    //     //             title: 'שגיאה',
    //     //             errorText: 'נדרש לאשר את המייל שנשלח בעת ההרשמה על מנת להשתמש באזור האישי',
    //     //             submitText: 'אישור',
    //     //             isCancel: false
    //     //         })
    //     //     );
    //     // }
    // }, [activeUser])


    return (
        <>
            {
                activeUser?.fullName?.length > 0 ? <div className="user-main">
                    <div div className="nav" >
                        <NavLink className={({ isActive }) => (isActive ? 'nav-title active' : 'nav-title inactive')} to="orders"><p >ההזמנות שלי</p></NavLink>
                        <NavLink className={({ isActive }) => (isActive ? 'nav-title active' : 'nav-title inactive')} to="details"><p >פרטים אישיים</p></NavLink>
                        <NavLink className={({ isActive }) => (isActive ? 'nav-title active' : 'nav-title inactive')} to="addresses"><p >הכתובות שלי</p></NavLink>
                        {/* <NavLink className={({ isActive }) => (isActive ? 'active' : 'inactive')} to="invoices"><p >חשבוניות</p></NavLink> */}
                        {activeUser?.isMaster && activeUser?.accountType !== 'חשבון פרטי' &&
                            <NavLink className={({ isActive }) => (isActive ? 'nav-title active' : 'nav-title inactive')} to="company"><p >החברה שלי</p></NavLink>
                        }
                    </div >
                    <div className="content">
                        <Outlet />
                    </div>
                </div > :
                    <></>
            }

        </>
    )
}
