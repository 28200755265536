import React, { useState, useEffect, useRef } from 'react';
import { Field } from 'formik';
import arrow from '../../../images/dropdown-arrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { updateSystemData, updateContactToSave } from '../../../store/actions/systemActions';
import { utilService } from '../../../services/utilService'
import ContactList from '../../Utils/ContactList'
import cities2 from "../../../consts/cities";
import streetRes2 from "../../../consts/streets";

export const OfficeDelivery = ({ data, setFormState, formState, isError, errors, handleChanges, handleBlur, setTouch, touch, }) => {
  const [address, setAddress] = useState({
    city: '',
    streetAddress: '',
    streetNumber: '',
    floor: '',
    apartmentNumber: '',
    comments: '',
  });
  const recipients = useSelector(state => state.recipientReducer.Recipients);
  const phoneRegex = /^\d{9,10}$/;

  const activeUser = useSelector((state) => state.userReducer.user);
  const systemData = useSelector((state) => state.systemReducer.systemData);
  const contactToSave = useSelector((state) => state.systemReducer.contactToSave);
  const dispatch = useDispatch();

  const [isAddressNotFound, setIsAddressNotFound] = useState(false);
  const [searchCity, setSearchCity] = useState('');
  const [searchStreet, setSearchStreet] = useState('');
  const [isCitiesDropdownOpen, setIsCitiesDropdownOpen] = useState(false);
  const [isStreetsDropdownOpen, setIsStreetsDropdownOpen] = useState(false);
  const [israelCities, setIsraelCities] = useState([])
  const [cityStreets, setCityStreets] = useState([])
  const refCity = useRef()
  const refStreet = useRef()

  useEffect(() => {
    if (isCitiesDropdownOpen) {
      getCities()
    } else {
      setIsraelCities([])
    }
    if (isStreetsDropdownOpen && address.city) {
      getStreetsOfCity(searchCity)
    } else {
      setCityStreets([])
    }
  }, [isCitiesDropdownOpen, isStreetsDropdownOpen]);

  useEffect(() => {
    let { currRecipient } = formState;
    setFormState({
      ...formState,
      currRecipient: {
        ...currRecipient,
        type: 'officeDelivery'
      },
    });
  }, []);

  useEffect(() => {

    if ((data.recipients && data.recipients.length > 0 && !data.isOtherTasks)) {
      setFormState({ ...formState, currRecipient: recipients[recipients.length - 1] });
    }
    // else if (!data.recipients || data.recipients.length === 0) {
    //   setFormState({ ...formState, currRecipient: null });
    // } -------bug dont save on prev from urgency recipient-------
  }, []);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (refCity?.current?.contains(event.target) || refStreet?.current?.contains(event.target)) {
        return;
      }
      setIsCitiesDropdownOpen(false);
      setIsStreetsDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);

  useEffect(() => {
    updateContact()
  }, [formState?.currRecipient]);

  // useEffect(async () => {
  //   try {
  //     const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`)
  //     const israelCities = await cityRes.json()
  //     setIsraelCities(israelCities.result.records.slice(1))
  //     if (address?.city) {
  //       const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${address?.city}`)
  //       const StreetsOfCity = await streetRes.json()
  //       setCityStreets(StreetsOfCity?.result?.records || [])
  //     }
  //   } catch (err) {
  //     console.log('Fetch error: ', err)
  //   }
  // }, []);

  useEffect(() => {
    const { currRecipient } = formState;
    setAddress({
      city: currRecipient?.city || '',
      streetNumber: currRecipient?.streetNumber || '',
      streetAddress: currRecipient?.streetAddress || '',
      floor: currRecipient?.floor || '',
      apartmentNumber: currRecipient?.apartmentNumber || '',
      comments: currRecipient?.comments || '',
    });

    setSearchCity(currRecipient?.city || '')
    setSearchStreet(currRecipient?.streetAddress || '')
  }, [formState]);

  const onBlur = () => {
    if (address?.city !== searchCity) {
      setSearchCity(address?.city ? address?.city : '')
    }
    if (address?.streetAddress !== searchStreet) {
      setSearchStreet(address?.streetAddress ? address?.streetAddress : '')
    }
    setIsCitiesDropdownOpen(false)
    setIsStreetsDropdownOpen(false)
  }

  const getStreetsOfCity = async (cityName) => {
    try {
      const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${cityName}`, { signal: AbortSignal.timeout(5000) })
      const StreetsOfCity = await streetRes.json()
      setCityStreets(StreetsOfCity.result.records)
    } catch (err) {
      const streetRes2 = require("../../../consts/streets")
      setCityStreets(streetRes2.result.records.filter((rec) => rec['שם_ישוב'] == cityName))
      console.log('Fetch error: ', err)
    }
  }

  const getCities = async () => {
    try {
      const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`, { signal: AbortSignal.timeout(5000) })
      const cities = await cityRes.json()
      const citiesAfterSwitch = switchBrackets(cities.result.records)
      setIsraelCities(citiesAfterSwitch)
    } catch (err) {
      const cities2 = require("../../../consts/cities")
      const citiesAfterSwitch2 = switchBrackets(cities2.result.records)
      setIsraelCities(citiesAfterSwitch2)
      console.log('Fetch error: ', err)
    }
  }

  const switchBrackets = (cities) => {
    const switchBracketsMap = {
      '(': ")",
      ')': "(",
    };

    return cities.map(city => {
      return {
        'שם_ישוב': city['שם_ישוב'].trim().replace(/\(|\)/gi, function (matched) {
          return switchBracketsMap[matched];
        })
      }
    })
  }

  const onSetChoose = (field, value) => {
    // setAddress((prevState) => ({
    //   ...prevState,
    //   [field]: value,
    // }));
    setTouch({ ...touch, [field]: true });
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: { ...prevState.currRecipient, type: 'officeDelivery', [field]: value },
    }));
  }

  const undoChanges = () => {
    setSearchStreet('')
    // setAddress((prevState) => ({
    //   ...prevState,
    //   streetAddress: '',
    // }));
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: { ...prevState.currRecipient, type: 'officeDelivery', streetAddress: '' },
    }));
  }
  const onSetContact = (contact) => {
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: {
        id: contact.id,
        officeName: contact.name || '',

        lawyerName: contact?.lawyerName || '',
        whichSide: contact?.whichSide || '',
        officePhone: contact?.phone || '',

        city: contact.city || '',
        streetAddress: contact.streetAddress || '',
        streetNumber: contact.streetNumber || '',
        comments: contact.comments || '',
        floor: contact.floor || '',
        apartmentNumber: contact.apartmentNumber || '',
        isAddressNotFound: contact.isAddressNotFound || false,
        type: contact?.type || 'officeDelivery',
      },
    }));
  }

  const handleIsSaveContact = () => {
    dispatch(updateSystemData({
      ...systemData,
      isSaveContact: !systemData.isSaveContact
    }));
  }
  const updateContact = () => {
    const contactName = formState?.currRecipient?.officeName
    const phone = formState?.currRecipient?.officePhone
    dispatch(updateContactToSave(
      {
        id: utilService.makeId(),
        name: contactName || '',
        phone: phone || '',
        lawyerName: formState?.currRecipient?.lawyerName || '',
        whichSide: formState?.currRecipient?.whichSide || '',
        city: formState?.currRecipient?.city || '',
        streetAddress: formState?.currRecipient?.streetAddress || '',
        streetNumber: formState?.currRecipient?.streetNumber || '',
        comments: formState?.currRecipient?.comments || '',
        floor: formState?.currRecipient?.floor || '',
        apartmentNumber: formState?.currRecipient?.apartmentNumber || '',
        isAddressNotFound: isAddressNotFound || false,
        type: 'officeDelivery'
      }));
  }
  // const streetWithoutNumber = (street) => {
  //   const streetWithoutNumber = street.split(' ');
  //   const streetNumber = streetWithoutNumber[streetWithoutNumber.length - 1];
  //   return !isNaN(streetNumber)
  //     ? streetWithoutNumber.slice(0, streetWithoutNumber.length - 1).join(' ')
  //     : street;
  // };

  // const { ref } = usePlacesWidget({
  //   onPlaceSelected: (selected) => {
  //     const splitAddress = selected.formatted_address.split(',');
  //     const streetNameSplit = splitAddress[0].split(' ');
  //     const streetNumber = streetNameSplit[streetNameSplit.length - 1];
  //     const streetNoNumber = streetWithoutNumber(splitAddress[0]);
  //     ref.current.value = streetNoNumber;

  //     setAddress((prevState) => ({
  //       ...prevState,
  //       // city: splitAddress[1],
  //       city: splitAddress[1] ? splitAddress[1] : selected.name,
  //       streetAddress: streetNoNumber,
  //       streetNumber: !isNaN(streetNumber) ? streetNumber : '',
  //     }));
  //     setFormState((prevState) => ({
  //       ...prevState,
  //       currRecipient: {
  //         ...prevState.currRecipient,
  //         streetAddress: streetNoNumber,
  //         // city: splitAddress[1],
  //         city: splitAddress[1] ? splitAddress[1] : selected.name,
  //         streetNumber: !isNaN(streetNumber) ? streetNumber : '',
  //       },
  //     }));
  //   },
  //   apiKey: 'AIzaSyA9YsSA7cSoOYpfXismUmaBObFUUaZxTJo',
  //   options: {
  //     types: ['geocode', 'establishment'],
  //     componentRestrictions: { country: 'il' },
  //     fields: ['name', 'formatted_address', 'address_components'],
  //   },
  // });

  const handleChangeData = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: { ...prevState.currRecipient, type: 'officeDelivery', [field]: value },
    }));
    handleChanges(event);
    setTouch({ ...touch, [field]: true });
  };

  return (
    <div className='form7-personal-company main-form high'>
      <div className='modal-content-container'>


        {((data?.recipients.length === 0) || formState?.currRecipient?._id) && <h2 className='med-title right'>לאיזה משרד מיועדת ההמצאה?</h2>}
        {((data.recipients && data.recipients.length > 0 && !formState.currRecipient?._id)) && <h2 className='med-title right'>לאיזה משרד נוסף מיועדת ההמצאה?</h2>}


        <h3 className='small'>פרטים</h3>
        <div className='input-container'>


          {activeUser?.userContacts && activeUser?.userContacts?.length ? <ContactList handleChangeData={handleChangeData} data={data} formState={formState} onSetContact={onSetContact} isError={isError} errors={errors} typeOfForm={'officeDelivery'} />
            :
            <div className='field'>
              <label className='input-label'>שם המשרד*</label>
              <Field
                type="text"
                onBlur={handleBlur}
                autoComplete="new-password"
                className={
                  isError('officeName')
                    ? 'error'
                    : formState?.currRecipient?.officeName
                      ? 'success'
                      : ''
                }
                value={formState?.currRecipient?.officeName || ''}
                name='officeName'
                onChange={handleChangeData}
              />
              {isError('officeName') && <p className='error-label'>{errors.officeName}</p>}
            </div>
          }

          <div className='field'>
            <label className='input-label'>שם עו״ד המטפל בתיק</label>
            <Field
              type="text"
              autoComplete="new-password"
              value={formState?.currRecipient?.lawyerName || ''}
              name='lawyerName'
              onChange={handleChangeData}
              className={formState?.currRecipient?.lawyerName ? 'success' : ''}
            />
          </div>
          <div className='field'>
            <label className='input-label'>טלפון</label>
            <div className='icon-input'>
              <Field
                type="text"
                autoComplete="new-password"
                className={
                  isError('officePhone')
                    ? 'error'
                    : phoneRegex.test(formState?.currRecipient?.officePhone)
                      ? 'success'
                      : ''
                }
                value={formState?.currRecipient?.officePhone || ''}
                name='officePhone'
                onChange={handleChangeData}
                onBlur={handleBlur}
              />
              <div className='icon'>972+</div>
            </div>
            {isError('officePhone') && <p className='error-label'>{errors.officePhone}</p>}
          </div>

          <div className='field'>
            <label className='input-label'>צד להליך</label>
            <Field
              autoComplete="new-password"
              type="text"
              value={
                (formState.currRecipient && formState.currRecipient?.whichSide) ||
                ''
              }
              name='whichSide'
              onChange={handleChangeData}
              className={formState?.currRecipient?.whichSide ? 'success' : ''}
            />
          </div>
        </div>
        <h3 className='small'>כתובת</h3>

        <div className='input-container'>
          {/* <div className='field street'>
            <label className='input-label'>רחוב*</label>

            <Field
              type="text"
              name='streetAddress' onBlur={handleBlur}>
              {({ field }) => (
                <div>
                  <input
                    type="text"
                    {...field}
                    onBlur={handleBlur}
                    className={
                      isError('streetAddress')
                        ? 'error'
                        : formState?.currRecipient?.streetAddress
                          ? 'success'
                          : ''
                    }
                    name='streetAddress'
                    ref={ref}
                    value={address.streetAddress}
                    onChange={(e) => {
                      setAddress({
                        ...address,
                        streetAddress: ref.current.value,
                      });
                      handleChanges(e);
                      setTouch({
                        ...touch,
                        streetNumber: true,
                        city: true,
                        streetAddress: true,
                      });
                    }}
                  />
                  {isError('streetAddress') && <p className='error-label'>{errors.streetAddress}</p>}
                </div>
              )}
            </Field>
          </div> */}

          <div className='field'>
            <label className='input-label'>יישוב*</label>

            <div
              ref={refCity}
              className={
                isCitiesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
              }
            >
              <div className='dropdown-input'>
                <input
                  type="text"
                  placeholder='הזינו יישוב ובחרו מתוך הרשימה'
                  onBlur={onBlur}
                  onChange={(event) => {
                    setSearchCity(
                      event.target.value
                    );
                    setIsCitiesDropdownOpen(true);
                  }}
                  value={searchCity}
                  onClick={() => { setIsCitiesDropdownOpen(true); }}

                  name='city'
                  autoComplete="new-password"
                  className={
                    isError('city')
                      ? 'error'
                      : formState?.currRecipient?.city
                        ? 'success'
                        : ''
                  }
                />
                <button
                  type='button'
                  onClick={() => {
                    setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                  }}
                >
                  <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                </button>
              </div>

              {isError('city') && <p className='error-label'>{errors.city}</p>}

              <div className='dropdown-list'>
                {israelCities.filter((option) => option['שם_ישוב'].includes(searchCity)).map((city, idx) => {
                  return (
                    <div className='dropdown-option' key={idx}>
                      <p
                        onMouseDown={() => {
                          const cityName = city['שם_ישוב'].trim()
                          onSetChoose('city', cityName)
                          setSearchCity(cityName)
                          setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                          // getStreetsOfCity(cityName)
                          if (cityName !== address.city) undoChanges()
                        }}>
                        {city['שם_ישוב']}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {isAddressNotFound ?
            <div className='field extra-height'>
              <label className='input-label'>רחוב*</label>
              <Field
                type="text"
                name='streetAddress'
                autoComplete="new-password"
                value={searchStreet}
                onChange={(ev) => {
                  setSearchStreet(ev.target.value);
                  onSetChoose('streetAddress', ev.target.value)
                }}
                className={
                  isError('streetAddress')
                    ? 'error'
                    : formState?.currRecipient?.streetAddress
                      ? 'success'
                      : ''
                }
              />
              {isError('streetAddress') && <p className='error-label'>{errors.streetAddress}</p>}

              <label className="checkbox-container">
                <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={isAddressNotFound} onChange={() => setIsAddressNotFound(!isAddressNotFound)} />
                <label className='checkbox-content' htmlFor='isAddressNotFound'>
                  לא מצאתי את הרחוב
                </label>
                <span className="checkmark"></span>
              </label>
            </div>
            :

            <div className='field extra-height'>
              <label className='input-label'>רחוב*</label>

              <div
                ref={refStreet}
                className={
                  isStreetsDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                }
              >
                <div className='dropdown-input'>
                  <input
                    type="text"
                    placeholder='הזינו רחוב ובחרו מתוך הרשימה'
                    onBlur={onBlur}
                    onChange={(event) => {
                      setSearchStreet(event.target.value);
                      setIsStreetsDropdownOpen(true);
                    }}
                    value={searchStreet}
                    onClick={() => { setIsStreetsDropdownOpen(true); }}

                    name='streetAddress'
                    autoComplete="new-password"
                    className={
                      isError('streetAddress')
                        ? 'error'
                        : formState?.currRecipient?.streetAddress
                          ? 'success'
                          : ''
                    }
                  />
                  <button
                    type='button'
                    onClick={() => {
                      setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                    }}
                  >
                    <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                  </button>
                </div>

                {isError('streetAddress') && <p className='error-label'>{errors.streetAddress}</p>}

                <div className='dropdown-list'>
                  {cityStreets.filter((option) => option['שם_רחוב'].includes(searchStreet)).map((street, idx) => {
                    return (
                      <div className='dropdown-option' key={idx}>
                        <p
                          onMouseDown={() => {
                            onSetChoose('streetAddress', street['שם_רחוב'])
                            setSearchStreet(street['שם_רחוב'])
                            setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                          }}>
                          {street['שם_רחוב']}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>

              <label className="checkbox-container">
                <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={isAddressNotFound} onChange={() => setIsAddressNotFound(!isAddressNotFound)} />
                <label className='checkbox-content' htmlFor='isAddressNotFound'>
                  לא מצאתי את הרחוב
                </label>
                <span className="checkmark"></span>
              </label>
            </div>
          }


          {/* <div className='field'>
            <label className='input-label'>עיר*</label>
            <Field
              type="text"
              onBlur={handleBlur}
              name='city'
              value={address.city}
              onChange={handleChangeData}
              className={
                isError('city')
                  ? 'error'
                  : formState?.currRecipient?.city
                    ? 'success'
                    : ''
              }
            />
            {isError('city') && <p className='error-label'>{errors.city}</p>}
          </div> */}

          <div className='field small'>
            <label className='input-label'>מספר*</label>
            <Field
              type="text"
              onBlur={handleBlur}
              className={
                isError('streetNumber')
                  ? 'small error'
                  : formState?.currRecipient?.streetNumber
                    ? 'small success'
                    : 'small'
              }
              value={address.streetNumber}
              name='streetNumber'
              onChange={handleChangeData}
            />
            {isError('streetNumber') && <p className='error-label'>{errors.streetNumber}</p>}
          </div>
          <div className='field small'>
            <label className='input-label'>קומה</label>
            <Field
              type="text"
              className={
                formState?.currRecipient?.floor ? 'small success' : 'small'
              }
              value={address.floor}
              name='floor'
              onChange={handleChangeData}
            />
          </div>

          <div className='field small'>
            <label className='input-label'>דירה</label>
            <Field
              type="text"
              className={
                formState?.currRecipient?.apartmentNumber
                  ? 'small success'
                  : 'small'
              }
              value={address.apartmentNumber}
              name='apartmentNumber'
              onChange={handleChangeData}
            />
          </div>

        </div>

        <div className='input-container textarea'>
          <div className='field textarea'>
            <label className='input-label'>הערות</label>
            <textarea
              placeholder='כתבו מידע נוסף שחשוב שנדע'
              multiline="true"
              rows={4}
              value={address.comments}
              name='comments'
              onChange={handleChangeData}
              className={formState?.currRecipient?.comments ? 'success' : ''}
            />
          </div>
        </div>

        {!activeUser?.isAnonymous &&
          <label className="checkbox-container save-contact-checkbox">
            <Field type="checkbox" name="isSaveContact" checked={systemData.isSaveContact} onChange={() => handleIsSaveContact()} />
            <label className='checkbox-content' htmlFor='isSaveContact'>
              שמירה להזמנות עתידיות
            </label>
            <span className="checkmark"></span>
          </label>
        }

        <p> בהמשך ניתן יהיה להוסיף משרדים נוספים</p>
      </div>
    </div >
  );
};

export default OfficeDelivery;
