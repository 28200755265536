const INITIAL_STATE = {
  Recipients: JSON.parse(localStorage.getItem("lastRecipients")) || [],
  currRecipient: null,
  totalPrice: 0,
  nowRecipient: null,
};

export function recipientReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "GET_RecipientS":
      return {
        ...state,
        Recipients: state.Recipients,
      };
    case "GET_Recipient":
      return {
        Orders: state.Orders.map((Order) =>
          Order._id === action.Order._id ? action.Order : Order
        ),
      };
    case "SET_NEW_RECIPIENT":
      return {
        ...state,
        nowRecipient: action.recipiente,
      };
    case "UPDATE_NEW_RECIPIENT":
      return {
        ...state,
        nowRecipient: { ...state.nowRecipient, ...action.recipiente },
      };
    case "SET_Recipient":
      return {
        ...state,
        currRecipient: action.Recipient,
      };
    case "REMOVE_Recipient":
      return {
        ...state,
        Recipients: state.Recipients.filter((Recipient) => Recipient._id !== action.RecipientId),
      };
    case "ADD_Recipient":
      return {
        ...state,
        Recipients: [...state.Recipients, action.Recipients],
      };
    case "EDIT_RECIPIENT":
      return {
        ...state,
        Recipients: state.Recipients.map((Recipient) =>
          Recipient._id === action.updatedRecipient._id ? action.updatedRecipient : Recipient
        ),
      };
    case "GET_TOTAL_RECIPIENTS_PRICE":
      return {
        ...state,
        totalPrice: state.Recipients.reduce(function (totalPrice, Recipient) {
          return totalPrice + Recipient.price;
        }
        ),
      };
    default:
      return state;
  }
}
