import React, { useState, useEffect } from 'react'
import { Field } from 'formik';

export const DepositFileDetails = ({ data, setData }) => {

    useEffect(() => {
        if (!data.depositFiles)
            data.depositFiles = { filesCount: 1, toll: '' }
        setData(data)
    }, [])


    const handleChangeCounter = (e, operation) => {
        let { depositFiles } = data
        if (operation === 'add') {
            depositFiles.filesCount += 1
        } else if (operation === 'reduce') {
            if (depositFiles.filesCount - 1 <= 0) {
                return
            }
            depositFiles.filesCount -= 1
        } else {
            depositFiles.filesCount = (+e.target.value === 0) ? 1 : +e.target.value
        }
        setData(data)
    }

    // const handleToll = (e) => {
    //     let { depositFiles } = data
    //     let value = +e.target.value;
    //     if (value < 0) value = 0
    //     depositFiles.toll = value
    //     setData(data);
    // }


    return (
        <div className='main-form action-in-office update-cmps file-count-container'>
            <div className='modal-content-container block'>
                {/* <h2 className='large-title'>הפקדת תיק</h2> */}
                <div className="file-count">
                    <h3 className='small'>בחרו את כמות התיקים להפקדה</h3>
                    <div className="number-input">
                        <button type='button' className="minus" onClick={(e) => handleChangeCounter(e, 'add')}>+</button>
                        <input
                            type="number"
                            min={0}
                            className="quantity"
                            value={data?.depositFiles?.filesCount || 1}
                            onChange={(e) => handleChangeCounter(e)} />
                        <button type='button' className="plus" onClick={(e) => handleChangeCounter(e, 'reduce')}>-</button>
                    </div>
                </div>

                {/* <div className='hidden-content'>
                    <h3>צריכים שנשלם עבורכם את האגרה?</h3>
                    <h3 className="small">*לא חובה*</h3>
                    <div className='field'>
                        <label >תשלמו עבורנו סך של:</label>

                        <div className='icon-input'>
                            <Field
                                name='toll'
                                autoComplete="new-password"
                                className={
                                    data?.depositFiles?.toll >= 1
                                        ? 'success'
                                        : ''
                                }
                                value={data && data?.depositFiles?.toll}
                                type='number'
                                onChange={handleToll}
                            />

                            <div className='icon'>₪</div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

