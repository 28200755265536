//landSupervisor
// המפקח על המקרקעין

const handleSteps = (data) => {
  const { actionInOffice } = data

  return [
    'ChooseOffice',
    'ActionInOffice',
    'CollectFileDetails',
    'PowerOfAttorney',
    'UploadFile',
    'AddressCollectDocumentsUpdate',
    'UrgencyExecution',
    'FinalPage'
  ].flat()
};

const handlePrevStep = (step, setStep, formSteps, state, setMyState, data, setData, setProgressBarPhase, onSetLastClaimant, onSetLastDefendant, myRecipients, isTestUser, removeSubmitter) => {
  const { nextStep } = state;
  const { actionInOffice } = data
  let nowStep

  if (nextStep === 'CollectFileDetails') {
    nowStep = formSteps.findIndex((page) => page === 'UrgencyExecution')
  } else if (nextStep === 'PowerOfAttorney') {
    if (data.isOtherSubmitter) nowStep = formSteps.findIndex((page) => page === 'CollectFileDetails')
    else nowStep = formSteps.findIndex((page) => page === 'ActionInOffice')
  } else if (nextStep === 'UrgencyExecution') {
    if (actionInOffice === 'collectFile') nowStep = formSteps.findIndex((page) => page === 'AddressCollectDocumentsUpdate')
    else nowStep = formSteps.findIndex((page) => page === 'ActionInOffice')
  } else if (nextStep === 'FinalPage' && data.isOtherSubmitter && data.collectionMethod === 'upload') {
    nowStep = formSteps.findIndex((page) => page === 'AddressCollectDocumentsUpdate')
  } else if (nextStep === 'FinalPage' && data.isOtherSubmitter) {
    nowStep = formSteps.findIndex((page) => page === 'UploadFile')
  }
  else if (step < 0) return
  else nowStep = step - 1;

  // on prev delete files if submitter change decision to deposit files
  if (nextStep === 'CollectFileDetails') {
    if (!data.collectFiles[data.currSubmiterIdx].powerOfAttorney) {
      data.collectFiles.splice(data.currSubmiterIdx, 1)
      setData({
        ...data,
        actionInOffice: (data.actionInOffice === 'collectFile') ? "depositFile" : data.actionInOffice,
        currSubmiterIdx: data.collectFiles.length > 0 ? (data.collectFiles.length - 1) : 0,
      })
    } else {
      setData({
        ...data,
        actionInOffice: (data.actionInOffice === 'collectFile') ? "depositFile" : data.actionInOffice,
      })
    }
  }

  setStep(nowStep);
  setMyState({ ...state, nextStep: formSteps[nowStep], isFinalStep: false });
};


const handleNextStep = (step, setStep, formSteps, state, setMyState, onAddRecipient, onAddOrder, data, setProgressBarPhase, setData, onAddEditClaimant, onAddEditDefendant, onSetLastDefendant, onSetLastClaimant, isTestUser, removeSubmitter) => {
  const { nextStep, isFinalStep } = state;
  let nowStep;

  if (isFinalStep) {
    onAddOrder();
  }

  if (nextStep === 'ActionInOffice') {
    if (data.actionInOffice === 'collectFile') {
      setData({
        ...data,
        depositFiles: null,
        isOtherSubmitter: false
      });
      nowStep = formSteps.findIndex((page) => page === 'PowerOfAttorney')
    }

    if (data.actionInOffice === 'depositFile') {
      if (!data.isOtherSubmitter) {
        setData({
          ...data,
          collectFiles: null
        });
      }
      nowStep = formSteps.findIndex((page) => page === 'UrgencyExecution')
    }

  } else if (nextStep === 'UploadFile' && data.isOtherSubmitter && data.collectionMethod !== 'pickup') {
    nowStep = formSteps.findIndex((page) => page === 'AddressCollectDocumentsUpdate')
  } else if (nextStep === 'UploadFile' && data.isOtherSubmitter) {
    setData({
      ...data,
      actionInOffice: 'depositFile',
    });
    nowStep = formSteps.findIndex((page) => page === 'FinalPage')
  } else if (nextStep === 'AddressCollectDocumentsUpdate' && data.actionInOffice === 'depositFile') {
    nowStep = formSteps.findIndex((page) => page === 'FinalPage')
  } else if (nextStep === 'UrgencyExecution') {
    if (data.actionInOffice === 'collectFile') {
      nowStep = step + 1
    }
    else if (data.registrationAddDeposit) {
      setData({
        ...data,
        isFromDeposit: 'back',
        isOtherSubmitter: true,
      });
      nowStep = formSteps.findIndex((page) => page === 'CollectFileDetails')
    } else {
      removeSubmitter('_land_supervisor')
      setData({
        ...data,
        collectFiles: null,
        isOtherSubmitter: false
      });
      nowStep = step + 1
    }
  }
  else {
    nowStep = step + 1
  }

  setMyState({ ...state, nextStep: formSteps[nowStep] });
  setStep(nowStep);
};


const handlePhase = (nextStep, setProgressBarPhase) => {

  switch (nextStep) {
    case 'ChooseOffice':
      setProgressBarPhase(1);
      break;
    case 'ActionInOffice':
    case 'CollectFileDetails':
    case 'DeposittFileDetails':
    case 'PowerOfAttorney':
    case 'UploadFile':
      setProgressBarPhase(2);
      break;
    case 'AddressCollectDocumentsUpdate':
      setProgressBarPhase(3);
      break;

    case 'UrgencyExecution':
    case 'FinalPage':
      setProgressBarPhase(4);
      break;

    default:
      setProgressBarPhase(4);
      break;
  }
};

export { handleSteps, handlePrevStep, handleNextStep, handlePhase };


//OLD
// const handleSteps = (data) => {
//   const { actionInOffice } = data

//   return [
//     'ChooseOffice',
//     'ActionInOffice',
//     (actionInOffice === 'depositFile' || data.isFromDeposit === 'true') ? ['DepositFileDetails', 'DocumentsReady', 'ActionsSummary'] : ['CollectFileDetails', 'PowerOfAttorney', 'UploadFile', 'ActionsSummary'],
//     'AddressCollectDocuments',
//     'UrgencyExecution',
//     'FinalPage'
//   ].flat()
// };

// const handlePrevStep = (step, setStep, formSteps, state, setMyState, data, setData,setProgressBarPhase,onSetLastClaimant,onSetLastDefendant,myRecipients,isTestUser,removeSubmitter) => {
//   const { nextStep } = state;
//   let nowStep
//   if ((nextStep === 'ActionsSummary') && (!data.collectFiles || data.collectFiles.length === 0) && (!data.depositFiles)) {
//     nowStep = formSteps.findIndex((page) => page === 'ActionInOffice')
//   } else if (nextStep === 'CollectFileDetails' && data.isOtherSubmitter) {
//     nowStep = formSteps.findIndex((page) => page === 'ActionsSummary')
//   } else if (step < 0) return
//   else nowStep = step - 1;

//   // on prev delete files if submitter change decision to deposit files
//   if (nextStep === 'CollectFileDetails' && !data.collectFiles[data.currSubmiterIdx].powerOfAttorney) {
//     data.collectFiles.splice(data.currSubmiterIdx, 1)
//     setData({
//       ...data,
//       currSubmiterIdx: data.collectFiles.length > 0 ? (data.collectFiles.length - 1) : 0
//     })
//   }
//   if (data.isFromDeposit === 'back' && nextStep === 'CollectFileDetails') {
//     setData({
//       ...data,
//       isFromDeposit: 'true'
//     })
//   }


//   setStep(nowStep);
//   setMyState({ ...state, nextStep: formSteps[nowStep], isFinalStep: false });
// };


// const handleNextStep = (step, setStep, formSteps, state, setMyState, onAddRecipient, onAddOrder, data) => {

//   const { nextStep, isFinalStep } = state;
//   let nowStep;

//   if (isFinalStep) {
//     onAddOrder();
//   }

//   if ((nextStep === 'ChooseOffice') && ((data.collectFiles && data.collectFiles.length > 0) || data.depositFile)) {
//     nowStep = formSteps.findIndex((page) => page === 'ActionsSummary')
//   }
//   else {
//     nowStep = step + 1
//   }

//   setMyState({ ...state, nextStep: formSteps[nowStep] });
//   setStep(nowStep);
// };


// const handlePhase = (nextStep, setProgressBarPhase) => {

//   switch (nextStep) {
//     case 'ChooseOffice':
//       setProgressBarPhase(1);
//       break;
//     case 'ActionInOffice':
//     case 'CollectFileDetails':
//     case 'DeposittFileDetails':
//     case 'PowerOfAttorney':
//     case 'ActionsSummary':
//     case 'UploadFile':
//       setProgressBarPhase(2);
//       break;
//     case 'DocumentsReady':
//     case 'AddressCollectDocuments':
//       setProgressBarPhase(3);
//       break;

//     case 'UrgencyExecution':
//     case 'FinalPage':
//       setProgressBarPhase(4);
//       break;

//     default:
//       setProgressBarPhase(4);
//       break;
//   }
// };

// export { handleSteps, handlePrevStep, handleNextStep, handlePhase };
