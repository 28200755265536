import React, { useEffect, useState, useRef } from 'react';
import { Field, Formik } from 'formik';
import { validationAddressPage } from './validations';
import { doc, updateDoc } from '@firebase/firestore';
import db, { Logger } from '../../integrations/firebase';
import { useSelector, useDispatch } from 'react-redux'
import { updateUser } from '../../store/actions/userActions'
import { utilService } from '../../services/utilService'
import { useNavigate } from "react-router-dom";
import arrow from '../../images/dropdown-arrow.svg';
import { useApostropheSubstitution } from '../../Hooks/useApostropheSubstitution'
import streetRes2 from "../../consts/streets";

const logClient = new Logger(`addOrEditADdress`);

export const AddOrEditAddress = ({ editAddress, setEditAddress, isFromNewUser }) => {
    const activeUser = useSelector((state) => state.userReducer.user);
    const dispatch = useDispatch();
    const [touch, setTouch] = useState({})
    const navigate = useNavigate();
    const [address, setAddress] = useState({
        city: '',
        streetAddress: '',
        streetNumber: '',
        floor: '',
        apartmentNumber: '',
        comments: '',
        addressName: '',
    });

    const [isAddressNotFound, setIsAddressNotFound] = useState(false);
    const [searchCity, setSearchCity] = useState('');
    const [searchStreet, setSearchStreet] = useApostropheSubstitution('');
    const [isCitiesDropdownOpen, setIsCitiesDropdownOpen] = useState(false);
    const [isStreetsDropdownOpen, setIsStreetsDropdownOpen] = useState(false);
    const [israelCities, setIsraelCities] = useState([])
    const [cityStreets, setCityStreets] = useState([])
    const refCity = useRef()
    const refStreet = useRef()

    useEffect(() => {
        const onBodyClick = (event) => {
            if (refCity?.current?.contains(event.target) || refStreet?.current?.contains(event.target)) {
                return;
            }
            setIsCitiesDropdownOpen(false);
            setIsStreetsDropdownOpen(false);
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });

        return () => {
            document.body.removeEventListener("click", onBodyClick, { capture: true });
        };
    }, []);

    // useEffect(async () => {
    //     try {
    //         const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`)
    //         const israelCities = await cityRes.json()
    //         setIsraelCities(israelCities.result.records.slice(1))
    //         if (address?.city) {
    //             const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${address?.city}`)
    //             const StreetsOfCity = await streetRes.json()
    //             setCityStreets(StreetsOfCity?.result?.records || [])
    //         }
    //     } catch (err) {
    //         console.log('Fetch error: ', err)
    //     }
    // }, []);

    useEffect(() => {
        if (isCitiesDropdownOpen) {
            getCities()
        } else {
            setIsraelCities([])
        }
        if (isStreetsDropdownOpen && address?.city) {
            getStreetsOfCity(address?.city)
        } else {
            setCityStreets([])
        }
    }, [isCitiesDropdownOpen, isStreetsDropdownOpen]);
    useEffect(() => {
        setAddress({
            city: editAddress?.city || '',
            streetAddress: editAddress?.streetAddress || '',
            streetNumber: editAddress?.streetNumber || '',
            floor: editAddress?.floor || '',
            apartmentNumber: editAddress?.apartmentNumber || '',
            comments: editAddress?.comments || '',
            addressName: editAddress?.addressName || '',
        });
        setSearchCity(editAddress?.city || '')
        setSearchStreet(editAddress?.streetAddress || '')
    }, [editAddress]);

    const onBlur = () => {
        if (address?.city !== searchCity) {
            setSearchCity(address?.city ? address?.city : '')
        }
        if (address?.streetAddress !== searchStreet) {
            setSearchStreet(address?.streetAddress ? address?.streetAddress : '')
        }
        setIsCitiesDropdownOpen(false)
        setIsStreetsDropdownOpen(false)
    }

    const getCities = async () => {
        try {
            const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`, { signal: AbortSignal.timeout(5000) })
            const cities = await cityRes.json()
            const citiesAfterSwitch = switchBrackets(cities.result.records)
            setIsraelCities(citiesAfterSwitch)
        } catch (err) {
            const cities2 = require("../../consts/cities")
            const citiesAfterSwitch2 = switchBrackets(cities2.result.records)
            setIsraelCities(citiesAfterSwitch2)
            console.log('Fetch error: ', err)
        }
    }

    const switchBrackets = (cities) => {
        const switchBracketsMap = {
            '(': ")",
            ')': "(",
        };

        return cities.map(city => {
            return {
                'שם_ישוב': city['שם_ישוב'].trim().replace(/\(|\)/gi, function (matched) {
                    return switchBracketsMap[matched];
                })
            }
        })
    }

    const getStreetsOfCity = async (cityName) => {
        try {
            const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${cityName}`, { signal: AbortSignal.timeout(5000) })
            const StreetsOfCity = await streetRes.json()
            setCityStreets(StreetsOfCity.result.records)
        } catch (err) {
            const streetRes2 = require("../../consts/streets")
            setCityStreets(streetRes2.result.records.filter((rec) => rec['שם_ישוב'] == cityName))
            console.log('Fetch error: ', err)
        }
    }

    const onSetChoose = (field, value) => {
        if (field === 'city') {
            setAddress((prevState) => ({
                ...prevState,
                city: value,
            }));
        } else if (field === 'streetAddress') {
            setAddress((prevState) => ({
                ...prevState,
                streetAddress: value,
            }));
        }
        setTouch({ ...touch, [field]: true });
    }


    const handleChange = (event) => {
        const { name, value } = event.target;
        setAddress({
            ...address,
            [name]: value,
        });
        setTouch({ ...touch, [name]: true })
    };

    const clearFields = () => {
        setAddress({
            city: '',
            streetAddress: '',
            streetNumber: '',
            floor: '',
            apartmentNumber: '',
            comments: '',
            addressName: '',
        })
        setSearchCity('')
        setSearchStreet('')
        setTouch({})
    }

    const onSubmit = async () => {
        let userAddresses = activeUser.addresses
        setIsAddressNotFound(false)

        if (editAddress) {
            userAddresses = userAddresses.map(currAddress => currAddress.id === editAddress.id ? { ...editAddress, ...address } : currAddress)
            setEditAddress(null)
        } else {
            userAddresses = [...userAddresses, { ...address, id: utilService.makeId() }]
        }
        try {
            const userRef = doc(db, "users", activeUser.uid);
            dispatch(updateUser({ ...activeUser, addresses: userAddresses }));
            await updateDoc(userRef, { addresses: userAddresses }, { merge: true }).then((r) => { }).catch((e) => {
                logClient.error('error when updating user addresses');
                logClient.error(JSON.stringify(userAddresses))
                logClient.error(JSON.stringify(e));
            });
            if (isFromNewUser) {
                navigate('/user/addresses')
                window.location.reload()
            }
            clearFields();
        }
        catch ({ message }) {
            console.log('%c  error:', 'color: white;background: red;', message);
        }

    };

    return (
        <Formik
            onSubmit={onSubmit}
            validateOnChange={true}
            validateOnBlur={true}
            validateOnMount={true}
            initialValues={{
                city: address.city,
                streetAddress: address.streetAddress,
                streetNumber: address.streetNumber,
                apartmentNumber: address.apartmentNumber,
                floor: address.floor,
                comments: address.comments,
                addressName: address.addressName,
            }}
            enableReinitialize={true}
            validationSchema={validationAddressPage}
        >
            {(props) => {
                const isError = (key) => (props.errors[key] && touch[key]) || (props.errors[key] && props.touched[key])
                // return <form onSubmit={(ev) => { props.handleSubmit(); props.setTouched({}); ev.preventDefault() }} >
                return <form onSubmit={(ev) => { props.handleSubmit(); ev.preventDefault() }} >
                    <div className='add-address'>
                        {isFromNewUser ?
                            <h4>נתחיל בהוספת הכתובת שלך:</h4>
                            :
                            <h3>{editAddress ? 'עדכון כתובת קיימת' : 'הוספת כתובת חדשה'}</h3>
                        }
                        <div className='fields'>
                            <div className='field large'>
                                <label className='input-label'>יישוב*</label>

                                <div
                                    ref={refCity}
                                    className={
                                        isCitiesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                                    }
                                >
                                    <div className='dropdown-input'>
                                        <input
                                            type="text"
                                            placeholder='הזינו יישוב ובחרו מתוך הרשימה'
                                            onBlur={onBlur}
                                            onChange={(event) => {
                                                setSearchCity(
                                                    event.target.value
                                                );
                                                setIsCitiesDropdownOpen(true);
                                            }}
                                            value={searchCity}
                                            onClick={() => { setIsCitiesDropdownOpen(true); }}

                                            name='city'
                                            autoComplete="new-password"
                                            className={
                                                isError('city')
                                                    ? 'error'
                                                    : address?.city
                                                        ? 'success'
                                                        : ''
                                            }
                                        />
                                        <button
                                            type='button'
                                            onClick={() => {
                                                setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                                            }}
                                        >
                                            <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                                        </button>
                                    </div>

                                    {isError('city') && <p className='error-label'>{props.errors.city}</p>}

                                    <div className='dropdown-list'>
                                        {israelCities.filter((option) => option['שם_ישוב'].includes(searchCity)).map((city, idx) => {
                                            return (
                                                <div className='dropdown-option' key={idx}>
                                                    <p
                                                        onMouseDown={() => {
                                                            const cityName = city['שם_ישוב'].trim()
                                                            onSetChoose('city', cityName)
                                                            setSearchCity(cityName)
                                                            setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                                                            // getStreetsOfCity(cityName)
                                                            if (cityName !== address.city) {
                                                                setSearchStreet('')
                                                                setAddress((prevState) => ({
                                                                    ...prevState,
                                                                    streetAddress: '',
                                                                }));
                                                            }
                                                        }}>
                                                        {city['שם_ישוב']}
                                                    </p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>

                            {isAddressNotFound ?
                                <div className='field extra-height large'>
                                    <label className='input-label'>רחוב*</label>
                                    <Field
                                        type="text"
                                        name='streetAddress'
                                        autoComplete="new-password"
                                        value={searchStreet}
                                        onChange={(ev) => {
                                            setSearchStreet(ev.target.value);
                                            onSetChoose('streetAddress', ev.target.value)
                                        }}
                                        className={
                                            isError('streetAddress')
                                                ? 'error'
                                                : address?.streetAddress
                                                    ? 'success'
                                                    : ''
                                        }
                                    />

                                    <label className="checkbox-container">
                                        <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={isAddressNotFound} onChange={() => setIsAddressNotFound(!isAddressNotFound)} />
                                        <label className='checkbox-content' htmlFor='isAddressNotFound'>
                                            לא מצאתי את הרחוב
                                        </label>
                                        <span className="checkmark"></span>
                                    </label>
                                    {isError('streetAddress') && <p className='error-label'>{props.errors.streetAddress}</p>}
                                </div>
                                :

                                <div className='field extra-height large'>
                                    <label className='input-label'>רחוב*</label>

                                    <div
                                        ref={refStreet}
                                        className={
                                            isStreetsDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                                        }
                                    >
                                        <div className='dropdown-input'>
                                            <input
                                                type="text"
                                                placeholder='הזינו רחוב ובחרו מתוך הרשימה'
                                                onBlur={onBlur}
                                                onChange={(event) => {
                                                    setSearchStreet(event.target.value);
                                                    setIsStreetsDropdownOpen(true);
                                                }}
                                                value={searchStreet}
                                                onClick={() => { setIsStreetsDropdownOpen(true); }}

                                                name='streetAddress'
                                                autoComplete="new-password"
                                                className={
                                                    isError('streetAddress')
                                                        ? 'error'
                                                        : address?.streetAddress
                                                            ? 'success'
                                                            : ''
                                                }
                                            />
                                            <button
                                                type='button'
                                                onClick={() => {
                                                    setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                                                }}
                                            >
                                                <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                                            </button>
                                        </div>

                                        <div className='dropdown-list'>
                                            {cityStreets.filter((option) => option['שם_רחוב'].includes(searchStreet)).map((street, idx) => {
                                                return (
                                                    <div className='dropdown-option' key={idx}>
                                                        <p
                                                            onMouseDown={() => {
                                                                onSetChoose('streetAddress', street['שם_רחוב'])
                                                                setSearchStreet(street['שם_רחוב'])
                                                                setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                                                            }}>
                                                            {street['שם_רחוב']}
                                                        </p>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    <label className="checkbox-container">
                                        <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={isAddressNotFound} onChange={() => setIsAddressNotFound(!isAddressNotFound)} />
                                        <label className='checkbox-content' htmlFor='isAddressNotFound'>
                                            לא מצאתי את הרחוב
                                        </label>
                                        <span className="checkmark"></span>
                                    </label>
                                    {isError('streetAddress') && <p className='error-label'>{props.errors.streetAddress}</p>}
                                </div>
                            }

                            <div className='field small'>
                                <label>מספר*</label>
                                <Field
                                    type='text'
                                    className={
                                        isError('streetNumber')
                                            ? 'small error'
                                            : address?.streetNumber
                                                ? 'small success'
                                                : 'small'
                                    }
                                    value={address.streetNumber}
                                    name='streetNumber'
                                    onChange={handleChange}
                                // onBlur={(e) => setTouch({ [e.target.name]: true })}
                                />
                                {isError('streetNumber') && <p className='error-label'>{props.errors.streetNumber}</p>}
                            </div>
                            <div className='field small'>
                                <label>קומה</label>
                                <Field
                                    type='text'
                                    className={address.floor ? 'small success' : 'small'}
                                    value={address.floor}
                                    name='floor'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='field small'>
                                <label>דירה</label>
                                <Field
                                    type='text'
                                    autoComplete='new-password'
                                    className={
                                        address?.apartmentNumber ? 'small success' : 'small'
                                    }
                                    value={address.apartmentNumber}
                                    name='apartmentNumber'
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='field'>
                                <label>הערות</label>
                                <input
                                    type='text'
                                    value={address.comments}
                                    name='comments'
                                    onChange={handleChange}
                                    className={address.comments ? 'success' : ''}
                                />
                            </div>
                        </div>
                        <button type='submit' className='main-btn long' onClick={() => {
                            setTouch({ streetAddress: true, streetNumber: true, city: true, addressName: true });
                        }}>
                            {editAddress ? 'עדכון כתובת' : 'הוספת כתובת'}
                        </button>
                        {isFromNewUser &&
                            <p className="link" onClick={() => { navigate('/'); window.location.reload(); }}>אוסיף כתובת מאוחר יותר...</p>}
                    </div>
                </form>
            }}
        </Formik >
    );
};
