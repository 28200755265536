import React, { useEffect, useState, useRef } from 'react';
import OrdersService from '../../../services/orderService';
import remove from '../../../images/remove1.png';
import arrow from '../../../images/arrowdropdown.png';
import { Field } from 'formik';
import { updateErrorModal } from '../../../store/actions/systemActions';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from '../../Utils/Tooltip'
import { TooltipTriangle } from '../../Utils/TooltipTriangle'
import { doc, updateDoc } from '@firebase/firestore';
import db, { Logger } from '../../../integrations/firebase';
import { updateUser } from '../../../store/actions/userActions'
import info from '../../../images/info.svg'
import {
    documentsOptionsInheritRegistration,
    documentsOptionsCompaniesRegistration,
    documentsOptionsPartnershipsRegistration,
    documentsOptionsAssociationsAndCompanies
} from '../../../consts/data'
const logClient = new Logger(`WhatServedRegist`);

export const WhatServedRegist = ({
    data,
    setData,
    isError,
    errors,
    submitForm,
    setSubmitForm,
    type
}) => {
    const activeUser = useSelector((state) => state.userReducer.user);

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const ref = useRef()
    const [search, setSearch] = useState('');
    const [isAddedSearch, setIsAddedSearch] = useState();
    const dispatch = useDispatch();
    const disabledOptions = ['פירוק חברה', 'הפקדת צוואה מקורית']

    useEffect(() => {
        const userDocumentsToAdd = activeUser.userDocuments ? activeUser.userDocuments : {};
        switch (type) {
            case 'inheritanceRegistration':
                setOptions([...documentsOptionsInheritRegistration, ...(userDocumentsToAdd.inheritanceRegistration || [])]);
                break;
            case 'companiesRegistration':
                setOptions([...documentsOptionsCompaniesRegistration, ...(userDocumentsToAdd.companiesRegistration || [])]);
                break;
            case 'partnershipsRegistration':
                setOptions([...documentsOptionsPartnershipsRegistration, ...(userDocumentsToAdd.partnershipsRegistration || [])]);
                break;
            case 'associationsAndCompanies':
                setOptions([...documentsOptionsAssociationsAndCompanies, ...(userDocumentsToAdd.associationsAndCompanies || [])]);
                break;
            default:
                break;
        }
    }, [])
    // { documentName: '', docType: 'אחר', upload: true, isDeposit: true, isToll: false }
    useEffect(() => {
        if (search) {
            //Here check if already added and if added replace
            if (isAddedSearch) {
                const updatedoptions = [...options]
                updatedoptions.pop()
                updatedoptions.push({ documentName: search, docType: search, upload: true, isDeposit: true, isToll: false, isNew: true })
                setOptions(updatedoptions)
            } else {
                setIsAddedSearch(true)
                setOptions((prevOptions) => [
                    ...prevOptions,
                    { documentName: search, docType: search, upload: true, isDeposit: true, isToll: false, isNew: true },
                ]);
            }
        } else if (!search && isAddedSearch) {
            setIsAddedSearch(false)
            const updatedoptions = [...options]
            updatedoptions.pop()
            setOptions(updatedoptions)
        }
    }, [search]);

    useEffect(() => {
        const onBodyClick = (event) => {
            if (ref.current.contains(event.target)) {
                return;
            }
            setIsDropdownOpen(false);
        };
        document.body.addEventListener("click", onBodyClick, { capture: true });

        return () => {
            document.body.removeEventListener("click", onBodyClick, {
                capture: true,
            });
        };

    }, []);

    useEffect(() => {
        setSelectedOptions(
            data.docsForSubmission && data.docsForSubmission.length > 0
                ? data.docsForSubmission
                : []
        );
        if (!data.docsForSubmission) {
            setData({
                ...data,
                docsForSubmission: [],
            });
        }

    }, []);

    useEffect(() => {
        let isOther = false
        if (errors.docsForSubmission && Array.isArray(errors.docsForSubmission)) isOther = true
        if (submitForm && errors.docsForSubmission) {
            setSubmitForm(false);
            dispatch(
                updateErrorModal({
                    title: 'שגיאה',
                    // errorText: 'לא נבחרו מסמכים להגשה',
                    errorText: isOther ? 'נדרש למלא את שם המסמך האחר' : 'לא נבחרו מסמכים להגשה',
                    submitText: 'אישור',
                    isCancel: false
                })
            );
        }

        if (submitForm && Object.entries(errors).length === 0 && !activeUser?.isAnonymous && !activeUser?.accessToken) {
            const newOptionsForUser = data.docsForSubmission.filter(option => option.isNew)
            if (newOptionsForUser.length) {
                const newOptionsWithoutIsNew = newOptionsForUser.map((obj) => {
                    const { id, isNew, ...rest } = obj;
                    return rest;
                });
                const userDocuments = activeUser.userDocuments ? activeUser.userDocuments : {}
                const documentsOriginal = userDocuments[type] ? userDocuments[type] : []

                const isDuplicateDocument = (docName, documentsArray) => {
                    return documentsArray.some((doc) => doc.documentName === docName);
                };

                const regisDocuments = documentsOriginal.slice();

                newOptionsWithoutIsNew.forEach((option) => {
                    if (!isDuplicateDocument(option.documentName, regisDocuments)) {
                        regisDocuments.push(option);
                    }
                });

                // If nothing changed
                if (regisDocuments.length === documentsOriginal.length) return

                userDocuments[type] = regisDocuments
                updateUserData(userDocuments)
            }
        }
    }, [data.docsForSubmission, submitForm, errors]);

    const updateUserData = async (userDocuments) => {
        try {
            const userRef = doc(db, "users", activeUser.uid);
            dispatch(updateUser({ ...activeUser, userDocuments }));
            await updateDoc(userRef, { userDocuments }, { merge: true }).then((r) => { }).catch((e) => {
                logClient.error('error when updating user addresses');
                logClient.error(JSON.stringify(userDocuments));
                logClient.error(JSON.stringify(e));
            });
        } catch (error) {
            console.log('%c  error:', 'color: white;background: red;', error.message);
        }
    };

    const getTooltipText = (option) => {
        switch (type) {
            case 'inheritanceRegistration':
                return 'ניתן לבחור אם תרצו שנפקיד את המסמכים אצל הרשם, או שנזמן תור לימים שני או רביעי בלבד, ונשים ״נתקבל״ על העמוד הראשון. בחירת ״נתקבל״ תחייב אותנו לעשות את ההגשה בימים שני או רביעי בלבד, ובמועד הקרוב ביותר הפנוי לזימון תור.'
            case 'companiesRegistration':
                if (disabledOptions.includes(option.docType)) return 'פעולה זו מחייבת בזימון תור והגשה בשעות קבלת קהל בימים ראשון ורביעי בלבד. אנו כפופים לזמינות התורים כך שניתן יהיה לבצע את ההגשה במועד הפנוי הקרוב ביותר.'
                return `ניתן לבחור בין הפקדה בתיבת רשם החברות או זימון תור למסירת המסמך בפני נציג הרשם והחתמת הדף הראשון של המסמך בחותמת "נתקבל". לתשומת לבכם, ניתן לזמן תור לימים ראשון ורביעי בלבד ואנו כפופים לזמינות התורים כך שניתן יהיה לבצע את ההגשה במועד הפנוי הקרוב ביותר.`;
            case 'partnershipsRegistration':
                return `ניתן לבחור בין הפקדה בתיבת רשם השותפויות או זימון תור למסירת המסמך בפני נציג הרשם והחתמת הדף הראשון של המסמך בחותמת "נתקבל". לתשומת לבכם, ניתן לזמן תור לימים ראשון ורביעי בלבד ואנו כפופים לזמינות התורים כך שניתן יהיה לבצע את ההגשה במועד הפנוי הקרוב ביותר.`;
            case 'associationsAndCompanies':
                return `ניתן לבחור בין הפקדה בתיבת רשם העמותות וחל"צ או זימון תור למסירת המסמך בפני נציג הרשם והחתמת הדף הראשון של המסמך בחותמת "נתקבל". לתשומת לבכם, ניתן לזמן תור לימים ראשון ורביעי בלבד ואנו כפופים לזמינות התורים כך שניתן יהיה לבצע את ההגשה במועד הפנוי הקרוב ביותר.`
            default:
                return '';
        }
    }

    const numberWithCommas = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const onSetSelection = (optionSelected) => {
        setSelectedOptions([
            ...selectedOptions,
            { ...optionSelected, id: OrdersService.makeId() },
        ]);
        const { collectionMethod } = data
        setData({
            ...data,
            collectionMethod: !collectionMethod && optionSelected.upload ? 'upload' : 'pickup',
            docsForSubmission: [
                ...selectedOptions,
                { ...optionSelected, id: OrdersService.makeId() },
            ],
        });
        setIsDropdownOpen(false)
    };

    const handleChange = (e, id, myOption = '') => {
        if (disabledOptions.includes(myOption.documentName)) return
        let { name, value, checked } = e.target;
        const idx = selectedOptions.findIndex((option) => option.id === id);
        if (idx === -1) return;
        if (value === 'false' || value === 'true') {
            value = (value === 'false') ? false : true
        }
        selectedOptions[idx][name] = (name === 'isToll') ? checked : value;
        if (type === 'inheritanceRegistration' && selectedOptions.length > 0) {
            const isDeposit = selectedOptions.some(doc => doc.isDeposit === false)
            if (!isDeposit) {
                delete data.returnMethod
                delete data.returnAddress
            }
        }
        setSelectedOptions([...selectedOptions]);
        setData({
            ...data,
            docsForSubmission: selectedOptions,
        });
    };

    const onDeleteDocument = (id) => {
        const idx = selectedOptions.findIndex((option) => option.id === id);
        if (idx === -1) return;
        selectedOptions.splice(idx, 1);
        setSelectedOptions([...selectedOptions]);
        setData({
            ...data,
            docsForSubmission: selectedOptions,
        });
    };

    const handleAlignment = (option) => {
        if (option.isDeposit)
            return 'right'
        return 'left'
    }

    return (

        <div className='main-form form7-what-served what-served-regist'>
            <div className='modal-content-container'>
                <h2 className='large-title'>מה מגישים בלשכה?</h2>
                <p className='subtitle '>
                    כתבו את שם המסמך אותו תרצו להגיש ובחרו את התוצאה מהרשימה הנפתחת. ניתן לבחור יותר ממסמך אחד
                </p>

                <div
                    ref={ref}
                    className={
                        isDropdownOpen ? 'dropdown-container open' : 'dropdown-container'
                    }
                >
                    <div className='dropdown-input'>
                        <Field
                            name="input-served"
                            autoComplete="new-password"
                            className={isError('docsForSubmission') ? 'error' : ''}
                            placeholder='דוגמה: בקשה למתן צו ירושה'
                            onChange={(event) => {
                                setSearch(
                                    isError('toll')
                                        ? errors.docsForSubmission
                                        : event.target.value
                                );

                            }}
                            value={search}
                            type="text"
                            onClick={() => setIsDropdownOpen(true)}
                        />
                        <button
                            type='button'
                            onClick={() => {
                                setSearch(isDropdownOpen === true ? '' : search);
                                setIsDropdownOpen(!isDropdownOpen);
                            }}
                        >
                            <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                        </button>
                    </div>

                    <div className='dropdown-list'>
                        {options
                            .filter((option) => option.docType.includes(search))
                            .map((option, idx) => {
                                return (
                                    <div
                                        key={idx}
                                        className='dropdown-option'
                                        onClick={() => {
                                            onSetSelection(option);
                                        }}
                                    >
                                        <p>{option?.docType || option.documentName}</p>
                                        <div>
                                            <button className='btn-add' type='button'>
                                                +
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>

                {data?.docsForSubmission?.length > 0 && (
                    <div className='selected-options'>
                        <h3>רשימת המסמכים להגשה</h3>
                        {selectedOptions.map((option, idx) => {
                            return (
                                <div key={idx} className='selected-item regist' >
                                    <div className='selected-doc-name'><p className={`p-med-bold ${option.docType === 'אחר' ? 'unwidth' : ''}`}>{option?.docType || option.documentName}</p>
                                        {/* {option.docType === 'אחר' && (
                                            <>
                                                <Field
                                                    placeholder='הזינו את שם המסמך'
                                                    onChange={(e) => handleChange(e, option.id)}
                                                    name='documentName'
                                                    value={option?.documentName || ''}
                                                    autoComplete="new-password"
                                                    type="text"
                                                    className={
                                                        isError('documentName')
                                                            ? 'error'
                                                            : option?.documentName?.length > 0
                                                                ? 'success'
                                                                : ''
                                                    }

                                                />
                                                {isError('documentName') && <p className='error-label'>{errors.documentName}</p>}
                                            </>
                                        )} */}
                                        <div className="tooltip-container">
                                            {option.tooltip && <TooltipTriangle text={option.tooltip} link={option.link}>
                                                <button type='button' className='btn info' style={{ color: 'black' }}>מידע חשוב</button>
                                            </TooltipTriangle>}
                                        </div>
                                    </div>
                                    {type != 'inheritanceRegistration' && <div className="input-container">
                                        <div className={`toggle ${handleAlignment(option)} ${disabledOptions.includes(option.documentName) ? 'dis-company' : ''}`}>
                                            <label className="togglt-option" htmlFor={option.id}>
                                                הפקדה
                                                <Field
                                                    className="toggle-option-radio"
                                                    type="radio"
                                                    id={option.id}
                                                    name="isDeposit"
                                                    value={'true'}
                                                    onChange={(e) => handleChange(e, option.id, option)}
                                                    checked={option.isDeposit === true}
                                                />
                                            </label>
                                            <label className="togglt-option" htmlFor={option.id + idx}>
                                                נתקבל
                                                <Field
                                                    id={option.id + idx}
                                                    type="radio"
                                                    name="isDeposit"
                                                    value={"false"}
                                                    onChange={(e) => handleChange(e, option.id, option)}
                                                    className="toggle-option-radio"
                                                    checked={option.isDeposit === false}
                                                />
                                            </label>
                                            <span></span>
                                        </div>
                                        <div className='info-tooltip'>
                                            {<TooltipTriangle text={getTooltipText(option)} >
                                                <img src={info} width={20} alt="info-icon" className="info-icon" />
                                            </TooltipTriangle>}
                                        </div>

                                    </div>}
                                    {option.toll > 0 && <div className="checkbox-toll">
                                        <input type="checkbox" id="isToll" name="isToll" checked={option.isToll} onChange={(e) => handleChange(e, option.id)} />
                                        <label htmlFor="isToll">שלמו עבורי אגרה בסך {numberWithCommas(option.toll)} ₪</label>
                                    </div>}


                                    <div className='btn-container'>

                                        <img
                                            className='btn-remove'
                                            src={remove}
                                            alt='remove'
                                            onClick={(e) => onDeleteDocument(option.id)}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </div >
    );
};

export default WhatServedRegist;
