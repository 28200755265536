import React, { useEffect, useState, useRef } from 'react';
import arrow from '../../../images/dropdown-arrow.svg';
import { Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { updateSystemData, updateContactToSave } from '../../../store/actions/systemActions';
import { utilService } from '../../../services/utilService'
import ContactList from '../../Utils/ContactList'
import cities2 from "../../../consts/cities";
import streetRes2 from "../../../consts/streets";

export const PersonalOrCompany = ({ data, setData, setFormState, formState, isError, errors, handleChanges, setTouch, touch, }) => {
  const [address, setAddress] = useState({
    city: '',
    streetAddress: '',
    streetNumber: '',
    floor: '',
    apartmentNumber: '',
    comments: '',
    isAddressNotFound: formState?.currRecipient?.isAddressNotFound || false
  });
  const [searchCity, setSearchCity] = useState('');
  const [searchStreet, setSearchStreet] = useState('');
  const [isCitiesDropdownOpen, setIsCitiesDropdownOpen] = useState(false);
  const [isStreetsDropdownOpen, setIsStreetsDropdownOpen] = useState(false);
  const [israelCities, setIsraelCities] = useState([])
  const [cityStreets, setCityStreets] = useState([])

  const refCity = useRef()
  const refStreet = useRef()
  const activeUser = useSelector((state) => state.userReducer.user);
  const systemData = useSelector((state) => state.systemReducer.systemData);
  const dispatch = useDispatch();

  const phoneRegex = /^\d{9,10}$/;
  useEffect(() => {
    setFormState({ ...formState, currRecipient: { ...formState.currRecipient, type: (data.isPersonalOrCompany === 'true' || data.type === 'documentTransfer') ? (formState?.currRecipient?.type === 'officeDelivery' ? 'officeDelivery' : (formState?.currRecipient?.type === 'HEVRA' ? 'HEVRA' : 'ADAM_PRATI')) : 'HEVRA' } });
  }, []);

  useEffect(() => {
    const onBodyClick = (event) => {
      if (refCity?.current?.contains(event.target) || refStreet?.current?.contains(event.target)) {
        return;
      }
      setIsCitiesDropdownOpen(false);
      setIsStreetsDropdownOpen(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });

    setSearchCity(formState?.currRecipient?.city || '')
    setSearchStreet(formState?.currRecipient?.streetAddress || '')

    return () => {
      document.body.removeEventListener("click", onBodyClick, { capture: true });
    };
  }, []);

  useEffect(() => {
    if (isCitiesDropdownOpen) {
      getCities()
    } else {
      setIsraelCities([])
    }
    if (isStreetsDropdownOpen && formState?.currRecipient?.city) {
      getStreetsOfCity(searchCity)
    } else {
      setCityStreets([])
    }
  }, [isCitiesDropdownOpen, isStreetsDropdownOpen]);

  useEffect(() => {
    const { currRecipient } = formState;
    setAddress({
      city: currRecipient?.city || '',
      streetNumber: currRecipient?.streetNumber || '',
      streetAddress: currRecipient?.streetAddress || '',
      floor: currRecipient?.floor || '',
      apartmentNumber: currRecipient?.apartmentNumber || '',
      comments: currRecipient?.comments || '',
      isAddressNotFound: currRecipient?.isAddressNotFound || false
    });

    setSearchCity(currRecipient?.city || '')
    setSearchStreet(currRecipient?.streetAddress || '')
  }, [formState]);

  useEffect(() => {
    updateContact()
  }, [address]);

  //useEffect For documentTransfer
  useEffect(() => {
    if (data.type === 'documentTransfer') {
      if (data.typeOfTransfer === 'fromMe') {
        setData({
          ...data,
          returnAddress: {
            // ...data.returnAddress,
            ...formState.currRecipient,
            returnStreetAddress: formState?.currRecipient?.streetAddress || '',
            returnStreetNumber: formState?.currRecipient?.streetNumber || '',
            returnCity: formState?.currRecipient?.city || '',
            apartment: formState?.currRecipient?.apartmentNumber || '',
            returnNotes: formState?.currRecipient?.notes || ''
          },
        });
      }
      else if (data.typeOfTransfer === 'toMe') {
        setData({
          ...data,
          collectionAddress: {
            // ...data.collectionAddress,
            ...formState.currRecipient,
            collectStreetAddress: formState?.currRecipient?.streetAddress || '',
            collectStreetNumber: formState?.currRecipient?.streetNumber || '',
            collectCity: formState?.currRecipient?.city || '',
            city: formState?.currRecipient?.city || '',
            apartment: formState?.currRecipient?.apartmentNumber || '',
            collectNotes: formState?.currRecipient?.notes || '',
          },
        });
      }
      else if (data.typeOfTransfer === 'both') {
        setData({
          ...data,
          collectionAddress: {
            // ...data.collectionAddress,
            ...formState.currRecipient,
            collectStreetAddress: formState?.currRecipient?.streetAddress || '',
            collectStreetNumber: formState?.currRecipient?.streetNumber || '',
            collectCity: formState?.currRecipient?.city || '',
            apartment: formState?.currRecipient?.apartmentNumber || '',
            collectNotes: formState?.currRecipient?.notes || ''
          },
        });
      }
    }
  }, [formState.currRecipient]);

  const onBlur = () => {
    if (currRecipient?.city !== searchCity) {
      setSearchCity(currRecipient?.city ? currRecipient?.city : '')
    }
    if (currRecipient?.streetAddress !== searchStreet) {
      setSearchStreet(currRecipient?.streetAddress ? currRecipient?.streetAddress : '')
    }

    setIsCitiesDropdownOpen(false)
    setIsStreetsDropdownOpen(false)
  }

  const getCities = async () => {
    try {
      const cityRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=d4901968-dad3-4845-a9b0-a57d027f11ab&limit=32000&q=`, { signal: AbortSignal.timeout(5000) })
      const cities = await cityRes.json()
      const citiesAfterSwitch = switchBrackets(cities.result.records)
      setIsraelCities(citiesAfterSwitch)
    } catch (err) {
      const cities2 = require("../../../consts/cities")
      const citiesAfterSwitch2 = switchBrackets(cities2.result.records)
      setIsraelCities(citiesAfterSwitch2)
      console.log('Fetch error: ', err)
    }
  }

  const switchBrackets = (cities) => {
    const switchBracketsMap = {
      '(': ")",
      ')': "(",
    };

    return cities.map(city => {
      return {
        'שם_ישוב': city['שם_ישוב'].trim().replace(/\(|\)/gi, function (matched) {
          return switchBracketsMap[matched];
        })
      }
    })
  }

  const getStreetsOfCity = async (cityName) => {
    try {
      const streetRes = await fetch(`https://data.gov.il/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&q=${cityName}`, { signal: AbortSignal.timeout(5000) })
      const StreetsOfCity = await streetRes.json()
      setCityStreets(StreetsOfCity.result.records)
    } catch (err) {
      const streetRes2 = require("../../../consts/streets")
      setCityStreets(streetRes2.result.records.filter((rec) => rec['שם_ישוב'] == cityName))
      console.log('Fetch error: ', err)
    }
  }

  const onSetChoose = (field, value) => {
    // if (field === 'city') {
    //   setFormState((prevState) => ({
    //     ...prevState,
    //     currRecipient: {
    //       ...prevState.currRecipient,
    //       city: value,
    //     },
    //   }));
    // } else if (field === 'streetAddress') {
    //   setFormState((prevState) => ({
    //     ...prevState,
    //     currRecipient: {
    //       ...prevState.currRecipient,
    //       streetAddress: value,
    //     },
    //   }));
    // }
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: {
        ...prevState.currRecipient,
        [field]: value,
      },
    }));
    setTouch({ ...touch, [field]: true });
  }

  const handleIsAddressNotFound = () => {
    setAddress((prevState) => ({
      ...prevState,
      isAddressNotFound: !prevState.isAddressNotFound,
    }))

    setFormState((prevState) => ({
      ...prevState,
      currRecipient: { ...prevState.currRecipient, isAddressNotFound: !prevState.currRecipient?.isAddressNotFound },
    }));
  }

  const getFormType = (type) => {
    switch (type) {
      case 'ADAM_PRATI':
        return ['personId', 'personName', 'personPhone']
      case 'HEVRA':
        return ['companyId', 'companyName', 'companyPhone']
      case 'officeDelivery':
        return ['officeId', 'officeName', 'officePhone']
      default:
        return ['personId', 'personName', 'personPhone']
    }
  }

  const onSetContact = (contact) => {
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: {
        id: contact.id,
        // [`${contact.type === 'HEVRA' ? 'companyId' : 'personId'}`]: contact.contactId || '',
        // [`${contact.type === 'HEVRA' ? 'companyName' : 'personName'}`]: contact.name || '',
        // [`${contact.type === 'HEVRA' ? 'companyPhone' : 'personPhone'}`]: contact.phone || '',
        [getFormType(contact.type)[0]]: data.type === 'documentTransfer' ? '' : (contact.contactId || ''),
        [getFormType(contact.type)[1]]: contact.name || '',
        [getFormType(contact.type)[2]]: contact.phone || '',

        managerName: contact?.managerName || '',
        city: contact.city || '',
        streetAddress: contact.streetAddress || '',
        streetNumber: contact.streetNumber || '',
        comments: contact.comments || '',
        floor: contact.floor || '',
        apartmentNumber: contact.apartmentNumber || '',
        isAddressNotFound: contact.isAddressNotFound || false,
        type: contact.type || 'ADAM_PRATI',
      },
    }));
  }

  const handleIsSaveContact = () => {
    dispatch(updateSystemData({
      ...systemData,
      isSaveContact: !systemData.isSaveContact
    }));
  }
  const updateContact = () => {
    let contactId = formState?.currRecipient?.personId || formState?.currRecipient?.companyId || formState?.currRecipient?.officeId
    let contactName = formState?.currRecipient?.personName || formState?.currRecipient?.companyName || formState?.currRecipient?.officeName
    let phone = formState?.currRecipient?.personPhone || formState?.currRecipient?.companyPhone || formState?.currRecipient?.officePhone

    if (formState?.currRecipient && formState?.currRecipient?.type) {
      contactId = formState?.currRecipient[getFormType(formState?.currRecipient?.type)[0]] || ''
      contactName = formState?.currRecipient[getFormType(formState?.currRecipient?.type)[1]] || ''
      phone = formState?.currRecipient[getFormType(formState?.currRecipient?.type)[2]] || ''
    }

    if (data.type === 'documentTransfer') contactId = ''
    dispatch(updateContactToSave(
      {
        id: utilService.makeId(),
        contactId: contactId || '',
        name: contactName || '',
        phone: phone || '',
        managerName: formState?.currRecipient?.managerName || '',
        city: address.city,
        streetAddress: address.streetAddress,
        streetNumber: address.streetNumber,
        comments: address.comments,
        floor: address.floor,
        apartmentNumber: address.apartmentNumber,
        isAddressNotFound: address.isAddressNotFound,
        type: formState?.currRecipient?.type || 'ADAM_PRATI',
      }));
  }

  const handleChangeData = (event) => {
    const field = event.target.name;
    const value = event.target.value;
    setFormState((prevState) => ({
      ...prevState,
      currRecipient: { ...prevState.currRecipient, [field]: value },
    }));
    handleChanges(event);
    setTouch({ ...touch, [field]: true });
  };

  const getTitleByTransferType = () => {
    switch (data.typeOfTransfer) {
      case 'fromMe':
        return 'כתובת יעד ופרטי המסירה';
      case 'toMe':
        return 'כתובת מוצא ופרטי האיסוף';
      case 'both':
        if (data.isSecond) return 'כתובת יעד ופרטי המסירה';
        else return 'כתובת מוצא ופרטי האיסוף'
      default:
        return '';
    }
  }
  const { currRecipient } = formState;

  return (
    <div className='form7-personal-company personal-delivery main-form high'>
      <div className='modal-content-container'>
        <h2 className='med-title right'>
          {data.type === 'documentTransfer' ? getTitleByTransferType()
            :
            currRecipient && currRecipient.type === 'ADAM_PRATI'
              ? 'אדם פרטי'
              : 'בית עסק'}{' '}
        </h2>
        <h3 className='small'>פרטים</h3>

        <div className='input-container'>
          {activeUser?.userContacts && activeUser?.userContacts?.length ? <ContactList handleChangeData={handleChangeData} data={data} formState={formState} onSetContact={onSetContact} isError={isError} errors={errors} typeOfForm={data.isPersonalOrCompany === 'false' ? 'HEVRA' : 'ADAM_PRATI'} />
            :
            <div className='field'>
              <label className='input-label'>
                {data.type === 'documentTransfer' ? 'שם איש קשר/חברה*' :
                  currRecipient && currRecipient.type === 'ADAM_PRATI'
                    ? 'שם מלא*'
                    : 'שם בית העסק*'}
              </label>

              <Field
                autoComplete="new-password"
                type="text"
                className={
                  isError('companyName') || isError('personName')
                    ? 'error'
                    : formState?.currRecipient?.personName ||
                      formState?.currRecipient?.companyName
                      ? 'success'
                      : ''
                }
                value={
                  formState?.currRecipient?.personName ||
                  formState?.currRecipient?.companyName ||
                  ''
                }
                name={
                  currRecipient && currRecipient.type === 'ADAM_PRATI'
                    ? 'personName'
                    : 'companyName'
                }
                onChange={handleChangeData}
              />
              {isError('personName') && <p className='error-label'>{errors.personName}</p>}
              {isError('companyName') && <p className='error-label'>{errors.companyName}</p>}
            </div>
          }

          {data.type !== 'documentTransfer' &&
            <div className='field'>
              <label className='input-label'>
                {currRecipient && currRecipient.type === 'ADAM_PRATI'
                  ? 'תעודת זהות'
                  : 'ת.ז. / ח.פ.'}
              </label>
              <Field
                autoComplete="new-password"
                type="text"
                className={
                  isError('personId')
                    ? 'error'
                    : formState?.currRecipient?.personId?.length === 9 ||
                      formState?.currRecipient?.companyId
                      ? 'success'
                      : ''
                }
                value={
                  formState?.currRecipient?.personId ||
                  formState?.currRecipient?.companyId ||
                  ''
                }
                name={
                  currRecipient && currRecipient.type === 'ADAM_PRATI'
                    ? 'personId'
                    : 'companyId'
                }
                onChange={handleChangeData}
              />
              {isError('personId') && <p className='error-label'>{errors.personId}</p>}
            </div>
          }

          <div className='field'>
            <label className='input-label'>
              {currRecipient && currRecipient.type === 'ADAM_PRATI' || data.type === 'documentTransfer'
                ? `טלפון נייד${data.type === 'documentTransfer' ? '*' : ''}`
                : 'טלפון'}
            </label>
            <div className='icon-input'>
              <Field
                autoComplete="new-password"
                type="text"
                className={
                  isError('personPhone') || isError('companyPhone') || isError('officePhone')
                    ? 'error'
                    : phoneRegex.test(formState?.currRecipient?.personPhone) ||
                      phoneRegex.test(formState?.currRecipient?.companyPhone) ||
                      phoneRegex.test(formState?.currRecipient?.officePhone)
                      ? 'success'
                      : ''
                }
                value={
                  formState?.currRecipient?.personPhone ||
                  formState?.currRecipient?.companyPhone ||
                  formState?.currRecipient?.officePhone ||
                  ''
                }
                name={
                  currRecipient && currRecipient?.type === 'ADAM_PRATI'
                    ? 'personPhone'
                    : (currRecipient?.type === 'HEVRA' ? 'companyPhone' : 'officePhone')
                }
                onChange={handleChangeData}
              />

              <div className="icon">972+</div>
            </div>
            {isError('personPhone') && <p className='error-label'>{errors.personPhone}</p>}
            {isError('companyPhone') && <p className='error-label'>{errors.companyPhone}</p>}
            {isError('officePhone') && <p className='error-label'>{errors.officePhone}</p>}
          </div>

          {currRecipient && currRecipient.type === 'HEVRA' && data.type !== 'documentTransfer' && (
            <div className='field'>

              <label className='input-label'>שם מנהל בעסק</label>
              <Field
                autoComplete="new-password"
                type="text"
                className={formState?.currRecipient?.managerName ? 'success' : ''}
                value={
                  (formState.currRecipient &&
                    formState.currRecipient?.managerName) ||
                  ''
                }
                name='managerName'
                onChange={handleChangeData}
              />
            </div>
          )}

        </div>

        <h3 className='small'>כתובת</h3>

        <div className='input-container'>

          <div className='field large'>
            <label className='input-label'>יישוב*</label>

            <div
              ref={refCity}
              className={
                // isCitiesDropdownOpen ? 'dropdown-container open' : 'dropdown-container'
                isCitiesDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
              }
            >
              <div className='dropdown-input'>
                <input
                  type="text"
                  placeholder='הזינו יישוב ובחרו מתוך הרשימה'
                  onBlur={onBlur}
                  onChange={(event) => {
                    setSearchCity(
                      event.target.value
                    );
                    setIsCitiesDropdownOpen(true);
                  }}
                  value={searchCity}
                  onClick={() => { setIsCitiesDropdownOpen(true); }}

                  name='city'
                  autoComplete="off"
                  className={
                    isError('city')
                      ? 'error'
                      : formState?.currRecipient?.city
                        ? 'success'
                        : ''
                  }
                />
                <button
                  type='button'
                  onClick={() => {
                    setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                  }}
                >
                  <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                </button>
              </div>

              {isError('city') && <p className='error-label'>{errors.city}</p>}

              <div className='dropdown-list'>
                {israelCities.filter((option) => option['שם_ישוב'].includes(searchCity)).map((city, idx) => {
                  return (
                    <div className='dropdown-option' key={idx}>
                      <p
                        onMouseDown={() => {
                          const cityName = city['שם_ישוב'].trim()
                          onSetChoose('city', cityName)
                          setSearchCity(cityName)
                          setIsCitiesDropdownOpen(!isCitiesDropdownOpen);
                          // getStreetsOfCity(cityName)
                          if (cityName !== formState.currRecipient.city) {
                            setSearchStreet('')
                            setFormState((prevState) => ({
                              ...prevState,
                              currRecipient: {
                                ...prevState.currRecipient,
                                streetAddress: '',
                              },
                            }));
                          }
                        }}>
                        {city['שם_ישוב']}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {address.isAddressNotFound ?
            <div className='field extra-height'>
              <label className='input-label'>רחוב*</label>
              <Field
                type="text"
                name='streetAddress'
                autoComplete="new-password"
                value={searchStreet}
                onChange={(ev) => {
                  setSearchStreet(ev.target.value);
                  onSetChoose('streetAddress', ev.target.value)
                }}
                className={
                  isError('city')
                    ? 'error'
                    : formState?.currRecipient?.streetAddress
                      ? 'success'
                      : ''
                }
              />
              {isError('streetAddress') && <p className='error-label'>{errors.streetAddress}</p>}

              <label className="checkbox-container">
                <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={address.isAddressNotFound} onChange={() => handleIsAddressNotFound()} />
                <label className='checkbox-content' htmlFor='isAddressNotFound'>
                  לא מצאתי את הרחוב
                </label>
                <span className="checkmark"></span>
              </label>

            </div>

            :

            <div className='field extra-height'>
              <label className='input-label'>רחוב*</label>

              <div
                ref={refStreet}
                className={
                  // isStreetsDropdownOpen ? 'dropdown-container open' : 'dropdown-container'
                  isStreetsDropdownOpen ? 'dropdown-address-container open' : 'dropdown-address-container'
                }
              >
                <div className='dropdown-input'>
                  <input
                    type="text"
                    placeholder='הזינו רחוב ובחרו מתוך הרשימה'
                    onBlur={onBlur}
                    onChange={(event) => {
                      setSearchStreet(event.target.value);
                      setIsStreetsDropdownOpen(true);
                    }}
                    value={searchStreet}
                    onClick={() => { setIsStreetsDropdownOpen(true); }}

                    name='streetAddress'
                    autoComplete="new-password"
                    className={
                      isError('streetAddress')
                        ? 'error'
                        : formState?.currRecipient?.streetAddress
                          ? 'success'
                          : ''
                    }
                  />
                  <button
                    type='button'
                    onClick={() => {
                      setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                    }}
                  >
                    <img className='icon-dropdown' src={arrow} alt='dropdown-arrow' />
                  </button>
                </div>

                {isError('streetAddress') && <p className='error-label'>{errors.streetAddress}</p>}

                <div className='dropdown-list'>
                  {cityStreets.filter((option) => option['שם_רחוב'].includes(searchStreet)).map((street, idx) => {
                    return (
                      <div className='dropdown-option' key={idx}>
                        <p
                          onMouseDown={() => {
                            onSetChoose('streetAddress', street['שם_רחוב'])
                            setSearchStreet(street['שם_רחוב'])
                            setIsStreetsDropdownOpen(!isStreetsDropdownOpen);
                          }}>
                          {street['שם_רחוב']}
                        </p>
                      </div>
                    );
                  })}
                </div>
              </div>

              <label className="checkbox-container">
                <Field type="checkbox" id="isAddressNotFound" name="isAddressNotFound" checked={address.isAddressNotFound} onChange={() => handleIsAddressNotFound()} />
                <label className='checkbox-content' htmlFor='isAddressNotFound'>
                  לא מצאתי את הרחוב
                </label>
                <span className="checkmark"></span>
              </label>

            </div>
          }

          <div className='field small' >
            <label className='input-label'>מספר*</label>
            <Field
              autoComplete="new-password"
              type="text"
              className={
                isError('streetNumber')
                  ? 'small error'
                  : formState?.currRecipient?.streetNumber
                    ? 'small success'
                    : 'small'
              }
              value={address.streetNumber}
              name='streetNumber'
              onChange={handleChangeData}
            />
            {isError('streetNumber') && <p className='error-label'>{errors.streetNumber}</p>}
          </div>
          <div className='field small'>
            <label className='input-label'>קומה</label>
            <Field
              type="text"
              autoComplete="new-password"
              className={
                formState?.currRecipient?.floor ? 'small success' : 'small'
              }
              value={address.floor}
              name='floor'
              onChange={handleChangeData}
            />
          </div>
          <div className='field small'>
            <label className='input-label'>דירה</label>
            <Field
              type="text"
              autoComplete="new-password"
              className={
                formState?.currRecipient?.apartmentNumber
                  ? 'small success'
                  : 'small'
              }
              value={address.apartmentNumber}
              name='apartmentNumber'
              onChange={handleChangeData}
            />
          </div>

        </div>
        <div className='input-container textarea'>
          <div className='field textarea'>
            <label className='input-label'>{data.type === 'documentTransfer' ? 'הערות/פרטים נוספים' : 'הערות לנמען'}</label>
            <textarea
              placeholder={data.type === 'documentTransfer' ? 'כתבו פרטים נוספים שחשוב שנדע' : 'כתבו מידע נוסף שחשוב שנדע בקשר לנמען'}
              multiline="true"
              rows={4}
              value={address.comments}
              name='comments'
              onChange={handleChangeData}
              className={formState?.currRecipient?.comments ? 'success' : ''}
            />
          </div>


        </div>

        {!activeUser?.isAnonymous &&
          <label className="checkbox-container save-contact-checkbox">
            <Field type="checkbox" name="isSaveContact" checked={systemData.isSaveContact} onChange={() => handleIsSaveContact()} />
            <label className='checkbox-content' htmlFor='isSaveContact'>
              שמירה להזמנות עתידיות
            </label>
            <span className="checkmark"></span>
          </label>
        }

        {
          data.type !== 'documentTransfer' &&
          <p>בהמשך ניתן יהיה להוסיף נמענים נוספים</p>
        }
      </div>
    </div>
  );
};

export default PersonalOrCompany;
