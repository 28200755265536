//courtSubmission
//בית משפט / בית דין

const handleSteps = (data, formState, systemData, isTestUser) => {
  const { deliveryAfter, isPrivateOrOffice, collectionMethod, returnMethod, isFirstSubmission, recipients } = data;
  let steps
  if (isTestUser) {
    steps = isFirstSubmission === 'collect' ? [
      'CourtNameAndAction',
      'WhatServed',
      'UploadFile',
      'AddressReturningDocumentsUpdate',
      'FinalPage'
    ] : [
      'CourtNameAndAction',
      'WhatServed',
      'DeliveryAfterSubmission',
      deliveryAfter === 'true' ? [(isPrivateOrOffice === 'true' ? 'PersonalDeliveryPage1' : 'OfficeDeliveryFirst'), 'Recipients', 'CollectDocumentsOrUploadFile2']
        : 'CollectDocumentsOrUploadFile2',
      'Proof3',
      'FinalPage'
    ]
  } else {
    steps = isFirstSubmission === 'collect' ? [
      'CourtName',
      'FirstSubmissionOrCopyInvolves',
      'WhatServed',
      'UploadFile',
      'AddressReturningDocuments',
      'UrgencyExecution',
      'FinalPage'
    ] : [
      'CourtName',
      'FirstSubmissionOrCopyInvolves',
      'WhatServed',
      'DeliveryAfterSubmission',
      deliveryAfter === 'true' ? [(isPrivateOrOffice === 'true' ? 'PersonalOrCompany' : 'OfficeDelivery'), 'UrgencyRecipient', 'Recipients', 'CollectDocumentsOrUploadFile']
        : 'CollectDocumentsOrUploadFile',
      collectionMethod === 'pickup' ? ['AddressCollectDocuments', 'DocumentsReady']
        : 'UploadFile',
      'Proof',
      returnMethod === 'delivery' ? (collectionMethod === 'pickup' ? ['UrgencyExecution', 'FinalPage'] : ['AddressReturningDocuments', 'UrgencyExecution', 'FinalPage']) : ['UrgencyExecution', 'FinalPage']
    ]
  }
  return steps.flat()
};

const handlePrevStep = (
  step,
  setStep,
  formSteps,
  state,
  setMyState,
  data,
  setData,
  setProgressBarPhase
) => {
  const { nextStep, currRecipient, isFinalStep } = state;
  const { returnMethod, isOtherTasks } = data;

  if (step < 0) return
  let nowStep

  if (nextStep === 'DeliveryAfterSubmission' && ((data.recipients && data.recipients.length > 0))) {
    nowStep = formSteps.findIndex((page) => page === 'Recipients')
  } else if (nextStep === 'Recipients') {
    if (!data.recipients || data.recipients.length === 0) {
      nowStep = formSteps.findIndex((page) => page === 'DeliveryAfterSubmission')
    } else {
      nowStep = formSteps.findIndex((page) => ((page === 'PersonalDeliveryPage1') || (page === 'OfficeDeliveryFirst')))
    }
  } else if ((nextStep === 'PersonalOrCompany' && currRecipient?._id) || (nextStep === 'OfficeDelivery' && currRecipient?._id)) {
    if (isOtherTasks) {
      nowStep = formSteps.findIndex((page) => page === 'Recipients')
    } else {
      nowStep = formSteps.findIndex((page) => page === 'WhatServed')
    }

  } else if ((nextStep === 'CollectDocumentsOrUploadFile2') && (!data.recipients || data.recipients.length === 0)) {
    nowStep = formSteps.findIndex((page) => page === 'DeliveryAfterSubmission')
  } else {
    nowStep = step - 1;
  }
  setMyState({ ...state, nextStep: formSteps[nowStep] });
  setStep(nowStep);





  // if (step < 0) return;
  // let nowStep;
  // if (nextStep === 'Recipients') {
  //   setMyState((prevState) => ({ ...prevState, currRecipient: null }));
  // }
  // // new recipient mode
  // if (nextStep === 'DeliveryAfterSubmission' && isOtherTasks) {
  //   nowStep = formSteps.findIndex((step) => step === 'Recipients');
  //   setData({ ...data, isOtherTasks: false, currRecipient: false });
  // } else if (
  //   //edit mode
  //   currRecipient &&
  //   (nextStep === 'UrgencyRecipient' ||
  //     nextStep === 'PersonalOrCompany' ||
  //     nextStep === 'officeDelivery')
  // ) {
  //   nowStep = formSteps.findIndex((step) => step === 'Recipients');
  //   setMyState((prevState) => ({ ...prevState, currRecipient: false }));
  //   setData({ ...data, isOtherTasks: false });
  // } else if (
  //   nextStep === 'Recipients' ||
  //   nextStep === 'officeDelivery' ||
  //   nextStep === 'PersonalOrCompany' ||
  //   nextStep === 'UrgencyRecipient'
  // ) {
  //   nowStep = formSteps.findIndex((step) => step === 'DeliveryAfterSubmission');
  //   setData({ ...data, isOtherTasks: false });
  //   setMyState((prevState) => ({ ...prevState, currRecipient: false }));
  // } else if (nextStep === 'UrgencyExecution' && returnMethod === 'upload') {
  //   nowStep = formSteps.findIndex((step) => step === 'Proof');
  // } else {
  //   nowStep = step - 1;
  // }
  // setStep(nowStep);
  // setMyState({
  //   ...state,
  //   nextStep: formSteps[nowStep],
  //   currRecipient:
  //     (nextStep === 'Recipients' || nextStep === 'DeliveryAfterSubmission') ? null : currRecipient
  // });
  // handlePhase(formSteps[nowStep], setProgressBarPhase);
};

const handleNextStep = (
  step,
  setStep,
  formSteps,
  state,
  setMyState,
  onAddRecipient,
  onAddOrder,
  data,
  setProgressBarPhase
) => {
  const { nextStep, currRecipient, isFinalStep } = state;
  const { returnMethod, deliveryAfter } = data;

  // if (step >= formSteps.length) return;
  if (isFinalStep) {
    onAddOrder();
  }


  let nowStep;
  // //save recipient
  if ((nextStep === 'UrgencyRecipient' && currRecipient) || (nextStep === 'PersonalDeliveryPage1' && currRecipient) || (nextStep === 'OfficeDeliveryFirst' && currRecipient) || (nextStep === 'PersonalOrCompany' && currRecipient?._id) || (nextStep === 'OfficeDelivery' && currRecipient?._id)) {
    onAddRecipient()
  }

  if ((nextStep === 'WhatServed') && (data.recipients && data.recipients.length > 0)) {
    nowStep = formSteps.findIndex((page) => page === 'Recipients')
  }
  else if (nextStep === 'PersonalDeliveryPage1' || nextStep === 'OfficeDeliveryFirst') {
    nowStep = formSteps.findIndex((page) => page === 'CollectDocumentsOrUploadFile2')
  }
  else {
    nowStep = step + 1;
  }


  setMyState({ ...state, nextStep: formSteps[nowStep], currRecipient: (nextStep === 'DeliveryAfterSubmission') ? null : currRecipient });
  setStep(nowStep);
};

const handlePhase = (nextStep, setProgressBarPhase) => {

  switch (nextStep) {
    case 'CourtName':
    case 'FirstSubmissionOrCopyInvolves':
    case 'CourtNameAndAction':
    case 'WhatServed':
      setProgressBarPhase(1);
      break;

    case 'DeliveryAfterSubmission':
    case 'PersonalOrCompany':
    case 'OfficeDelivery':
    case 'Recipients':
    case 'UrgencyRecipient':
    case 'PersonalDeliveryPage1':
    case 'OfficeDeliveryFirst':
      setProgressBarPhase(2);
      break;

    case 'DocumentsReady':
    case 'CollectDocumentsOrUploadFile2':
    case 'UploadFile':
    case 'AddressCollectDocuments':
      setProgressBarPhase(3);
      break;


    case 'Proof':
    case 'Proof3':
    case 'AddressReturningDocuments':
    case 'AddressReturningDocumentsUpdate':
      setProgressBarPhase(4);
      break;


    case 'FinalPage':
      setProgressBarPhase(5);
      break;

    default:
      setProgressBarPhase(5);
      break;
  }
};

export { handlePhase, handleSteps, handlePrevStep, handleNextStep };
