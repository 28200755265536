import React, { useState } from 'react'
import { UserAddressPreview } from './UserAddressPreview'
import { AddOrEditAddress } from './AddOrEditAddress'
// import { AddOrEditAddress } from './AddOrEditAddress copy'
import { useSelector, useDispatch } from 'react-redux'
import { doc, updateDoc } from '@firebase/firestore';
import db, { Logger } from '../../integrations/firebase';
import { updateUser } from '../../store/actions/userActions'
const logClient = new Logger(`userAddressList`);

export const UserAddressList = () => {
    const activeUser = useSelector((state) => state.userReducer.user);
    // console.log('%c  activeUser:', 'color: white;background: red;', activeUser);
    const [editAddress, setEditAddress] = useState(null)
    const dispatch = useDispatch();

    const onEditAddress = (address) => {
        setEditAddress(address)
    }

    const onRemoveAddress = async (address) => {
        const userAddresses = activeUser.addresses.filter((currAddress) => currAddress.id !== address.id)
        try {
            const userRef = doc(db, "users", activeUser.uid);
            await updateDoc(userRef, { addresses: userAddresses }, { merge: true }).then((r) => {}).catch((e) => {
                logClient.error('error when updating user addresses');
                logClient.error(JSON.stringify(userAddresses))
                logClient.error(JSON.stringify(e));
            });
            dispatch(updateUser({ ...activeUser, addresses: userAddresses }));
        }
        catch ({ message }) {
            console.log('%c  error:', 'color: white;background: red;', message);
        }
    }

    return (
        <div className="user-address">
            {(activeUser?.addresses.length > 0) &&
                <h3>הכתובות שלי</h3>}
            <div className="address-list" >
                {activeUser?.addresses?.map((address, idx) => (
                    <UserAddressPreview key={idx} address={address} editAddress={editAddress} onEditAddress={onEditAddress} onRemoveAddress={onRemoveAddress} />
                ))}
            </div>
            <AddOrEditAddress editAddress={editAddress} setEditAddress={setEditAddress} />
        </div>
    )
}
