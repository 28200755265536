const INITIAL_STATE = {
  users: [],
  user: null,
  registeredUser: null
}
export function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.user
      }
    case 'SET_REGISTERED_USER':
      return {
        ...state,
        registeredUser: action.registeredUser
      }
    case 'SET_USER_INVITES':
        return {
            ...state,
            invites: action.invites
        }

    default:
      return state
  }
}