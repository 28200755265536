module.exports = {
    "help": "https://data.gov.il/api/3/action/help_show?name=datastore_search",
    "success": true,
    "result": {
    "include_total": true,
        "limit": 32000,
        "q": "",
        "records_format": "objects",
        "resource_id": "9ad3862c-8391-4b2f-84a4-2d4c68625f4b",
        "total_estimation_threshold": null,
        "records":  [
        {
            "_id": 1,
            "סמל_ישוב": 967,
            "שם_ישוב": "אבו ג'ווייעד (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו ג'ווייעד (שבט)"
        },
        {
            "_id": 2,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו גוש"
        },
        {
            "_id": 3,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אל רשיד"
        },
        {
            "_id": 4,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אל-חנאא'"
        },
        {
            "_id": 5,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלעבד עלי"
        },
        {
            "_id": 6,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 134,
            "שם_רחוב": "בית הבד"
        },
        {
            "_id": 7,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ג'בר"
        },
        {
            "_id": 8,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 161,
            "שם_רחוב": "דאוד עבדאללה"
        },
        {
            "_id": 9,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 147,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 10,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 130,
            "שם_רחוב": "דרך הגוש"
        },
        {
            "_id": 11,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך השלום"
        },
        {
            "_id": 12,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך מעלה החמשה"
        },
        {
            "_id": 13,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 109,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 14,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 151,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 15,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 150,
            "שם_רחוב": "האיריס"
        },
        {
            "_id": 16,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 149,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 17,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 136,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 18,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 139,
            "שם_רחוב": "הארמונות"
        },
        {
            "_id": 19,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הבושם"
        },
        {
            "_id": 20,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 21,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 22,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 23,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 137,
            "שם_רחוב": "הגפנים"
        },
        {
            "_id": 24,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הדובדבנים"
        },
        {
            "_id": 25,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הוואדי"
        },
        {
            "_id": 26,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 140,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 27,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הזיתים"
        },
        {
            "_id": 28,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 132,
            "שם_רחוב": "החווה"
        },
        {
            "_id": 29,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 112,
            "שם_רחוב": "החומה"
        },
        {
            "_id": 30,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 162,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 31,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הלימון"
        },
        {
            "_id": 32,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 111,
            "שם_רחוב": "המאפיה הישנה"
        },
        {
            "_id": 33,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 138,
            "שם_רחוב": "המעברה"
        },
        {
            "_id": 34,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 154,
            "שם_רחוב": "המפעל"
        },
        {
            "_id": 35,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 129,
            "שם_רחוב": "המצפה"
        },
        {
            "_id": 36,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 127,
            "שם_רחוב": "המשתלה"
        },
        {
            "_id": 37,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 158,
            "שם_רחוב": "הנענע"
        },
        {
            "_id": 38,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 156,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 39,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 148,
            "שם_רחוב": "העוזרד"
        },
        {
            "_id": 40,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הפול"
        },
        {
            "_id": 41,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 157,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 42,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הרימונים"
        },
        {
            "_id": 43,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 115,
            "שם_רחוב": "השדות"
        },
        {
            "_id": 44,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 135,
            "שם_רחוב": "השושן"
        },
        {
            "_id": 45,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 146,
            "שם_רחוב": "השזיף"
        },
        {
            "_id": 46,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 103,
            "שם_רחוב": "השיכון"
        },
        {
            "_id": 47,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 152,
            "שם_רחוב": "השקדים"
        },
        {
            "_id": 48,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 133,
            "שם_רחוב": "התאנים"
        },
        {
            "_id": 49,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 126,
            "שם_רחוב": "התות"
        },
        {
            "_id": 50,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 51,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 160,
            "שם_רחוב": "חאג' עותמאן"
        },
        {
            "_id": 52,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 125,
            "שם_רחוב": "חג' מוסא"
        },
        {
            "_id": 53,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 113,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 54,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 107,
            "שם_רחוב": "מחמוד רשיד"
        },
        {
            "_id": 55,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מרכז הכפר"
        },
        {
            "_id": 56,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 128,
            "שם_רחוב": "נוטרדאם"
        },
        {
            "_id": 57,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 122,
            "שם_רחוב": "נטאף"
        },
        {
            "_id": 58,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 121,
            "שם_רחוב": "עבדאל עזיז"
        },
        {
            "_id": 59,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 131,
            "שם_רחוב": "עבדאל רחמן"
        },
        {
            "_id": 60,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 155,
            "שם_רחוב": "עמאוס"
        },
        {
            "_id": 61,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 141,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 62,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 143,
            "שם_רחוב": "ציפורן"
        },
        {
            "_id": 63,
            "סמל_ישוב": 472,
            "שם_ישוב": "אבו גוש ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שייח איסמעיל"
        },
        {
            "_id": 64,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 118,
            "שם_רחוב": "א-זייתון"
        },
        {
            "_id": 65,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 235,
            "שם_רחוב": "א-טאלע"
        },
        {
            "_id": 66,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 135,
            "שם_רחוב": "א-לוז"
        },
        {
            "_id": 67,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 214,
            "שם_רחוב": "א-נביא זכריא"
        },
        {
            "_id": 68,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 289,
            "שם_רחוב": "א-נור"
        },
        {
            "_id": 69,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 114,
            "שם_רחוב": "א-נח'יל"
        },
        {
            "_id": 70,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "א-נסים"
        },
        {
            "_id": 71,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 233,
            "שם_רחוב": "א-סאפי"
        },
        {
            "_id": 72,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 138,
            "שם_רחוב": "א-סדאקה"
        },
        {
            "_id": 73,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 226,
            "שם_רחוב": "א-סהל"
        },
        {
            "_id": 74,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 120,
            "שם_רחוב": "א-סנאבל"
        },
        {
            "_id": 75,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 236,
            "שם_רחוב": "א-סנאעה"
        },
        {
            "_id": 76,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 221,
            "שם_רחוב": "א-סרוואת"
        },
        {
            "_id": 77,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 222,
            "שם_רחוב": "א-רביע"
        },
        {
            "_id": 78,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "א-רוואבי"
        },
        {
            "_id": 79,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 151,
            "שם_רחוב": "א-רייחאן"
        },
        {
            "_id": 80,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 228,
            "שם_רחוב": "א-רסאפי"
        },
        {
            "_id": 81,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 141,
            "שם_רחוב": "א-שועלה"
        },
        {
            "_id": 82,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 204,
            "שם_רחוב": "א-שורוק"
        },
        {
            "_id": 83,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 218,
            "שם_רחוב": "א-שיח' ג'בר מועד"
        },
        {
            "_id": 84,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 131,
            "שם_רחוב": "א-שימאלי"
        },
        {
            "_id": 85,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 213,
            "שם_רחוב": "א-שפק"
        },
        {
            "_id": 86,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 196,
            "שם_רחוב": "א-תסאמוח"
        },
        {
            "_id": 87,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו סנאן"
        },
        {
            "_id": 88,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6733,
            "שם_רחוב": "אבו-אלחסן"
        },
        {
            "_id": 89,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 229,
            "שם_רחוב": "אום כולת'ום"
        },
        {
            "_id": 90,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6719,
            "שם_רחוב": "אזור תעשייה"
        },
        {
            "_id": 91,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 179,
            "שם_רחוב": "איבן היית'ם"
        },
        {
            "_id": 92,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 198,
            "שם_רחוב": "איבן חייאן"
        },
        {
            "_id": 93,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "איבן סינא"
        },
        {
            "_id": 94,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 232,
            "שם_רחוב": "איסמהאן"
        },
        {
            "_id": 95,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "אל שוקפאן"
        },
        {
            "_id": 96,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אל-אהראם"
        },
        {
            "_id": 97,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אל-אוח'וה"
        },
        {
            "_id": 98,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אל-אזהר"
        },
        {
            "_id": 99,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אל-איח'לס"
        },
        {
            "_id": 100,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אל-אמל"
        },
        {
            "_id": 101,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אל-אנדלוס"
        },
        {
            "_id": 102,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אל-אנואר"
        },
        {
            "_id": 103,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אל-אסאס"
        },
        {
            "_id": 104,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אל-אסיל"
        },
        {
            "_id": 105,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אל-אשג'אר"
        },
        {
            "_id": 106,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אל-בדר"
        },
        {
            "_id": 107,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אל-בוסתאן"
        },
        {
            "_id": 108,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אל-ביידר"
        },
        {
            "_id": 109,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אל-בילסאן"
        },
        {
            "_id": 110,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אל-בירוני"
        },
        {
            "_id": 111,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אל-בראעם"
        },
        {
            "_id": 112,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אל-ג'בל"
        },
        {
            "_id": 113,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אל-הילאל"
        },
        {
            "_id": 114,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אל-וורוד"
        },
        {
            "_id": 115,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 180,
            "שם_רחוב": "אל-ח'אנוקה"
        },
        {
            "_id": 116,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אל-ח'ואריזמי"
        },
        {
            "_id": 117,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אל-ח'יל"
        },
        {
            "_id": 118,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אל-ח'לה"
        },
        {
            "_id": 119,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 237,
            "שם_רחוב": "אל-חארה"
        },
        {
            "_id": 120,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אל-יאסמין"
        },
        {
            "_id": 121,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אל-כנאיס"
        },
        {
            "_id": 122,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אל-כרום"
        },
        {
            "_id": 123,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אל-מדארס"
        },
        {
            "_id": 124,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 212,
            "שם_רחוב": "אל-מוסתקים"
        },
        {
            "_id": 125,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אל-מותנבי"
        },
        {
            "_id": 126,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 199,
            "שם_רחוב": "אל-מחבה"
        },
        {
            "_id": 127,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אל-מנארה"
        },
        {
            "_id": 128,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אל-מסאג'יד"
        },
        {
            "_id": 129,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אל-מעבר"
        },
        {
            "_id": 130,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אל-מראחין"
        },
        {
            "_id": 131,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אל-מראע"
        },
        {
            "_id": 132,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אל-מרג'"
        },
        {
            "_id": 133,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אל-מרג'אן"
        },
        {
            "_id": 134,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אל-עאלי"
        },
        {
            "_id": 135,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אל-עין"
        },
        {
            "_id": 136,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אל-עמל"
        },
        {
            "_id": 137,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אל-ענדליב"
        },
        {
            "_id": 138,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אל-פג'ר"
        },
        {
            "_id": 139,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אל-פראבי"
        },
        {
            "_id": 140,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אל-פראהידי"
        },
        {
            "_id": 141,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אל-קמה"
        },
        {
            "_id": 142,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "אלג'ורה"
        },
        {
            "_id": 143,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "אלהדאפה"
        },
        {
            "_id": 144,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אלוחדה"
        },
        {
            "_id": 145,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6718,
            "שם_רחוב": "אלחלקאת"
        },
        {
            "_id": 146,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "אלחרוב ש חייל החדשה"
        },
        {
            "_id": 147,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6701,
            "שם_רחוב": "אלחרוב ש חייל הישנה"
        },
        {
            "_id": 148,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6715,
            "שם_רחוב": "אלמלה"
        },
        {
            "_id": 149,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6724,
            "שם_רחוב": "אלמפצלה"
        },
        {
            "_id": 150,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "אלע'וזה"
        },
        {
            "_id": 151,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "אלעקבה"
        },
        {
            "_id": 152,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אקלידס"
        },
        {
            "_id": 153,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 181,
            "שם_רחוב": "בוסנון"
        },
        {
            "_id": 154,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 243,
            "שם_רחוב": "דר אחמד סעד"
        },
        {
            "_id": 155,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 242,
            "שם_רחוב": "דרך אל-ח'רוב"
        },
        {
            "_id": 156,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 247,
            "שם_רחוב": "דרך אל-עקבה"
        },
        {
            "_id": 157,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 241,
            "שם_רחוב": "דרך עשואן"
        },
        {
            "_id": 158,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "הואדי"
        },
        {
            "_id": 159,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "השכונה הדרומית"
        },
        {
            "_id": 160,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 192,
            "שם_רחוב": "טרופיקה"
        },
        {
            "_id": 161,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 291,
            "שם_רחוב": "טריק א-סלאלם"
        },
        {
            "_id": 162,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6725,
            "שם_רחוב": "כרם אלרג'ומה"
        },
        {
            "_id": 163,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 121,
            "שם_רחוב": "מועאוייה"
        },
        {
            "_id": 164,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "מרכז הכפר"
        },
        {
            "_id": 165,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נג'מת א-סובוח"
        },
        {
            "_id": 166,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "סולטאן באשא אל-אטרש"
        },
        {
            "_id": 167,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 244,
            "שם_רחוב": "סלמאן עזאם"
        },
        {
            "_id": 168,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 288,
            "שם_רחוב": "עאטף משלב"
        },
        {
            "_id": 169,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 132,
            "שם_רחוב": "עבד אל-ווהאב"
        },
        {
            "_id": 170,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 250,
            "שם_רחוב": "עבד אל-חלים"
        },
        {
            "_id": 171,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 239,
            "שם_רחוב": "עבד-אללה ח'יר"
        },
        {
            "_id": 172,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6716,
            "שם_רחוב": "עמדאן סעד"
        },
        {
            "_id": 173,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "עשואן"
        },
        {
            "_id": 174,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 293,
            "שם_רחוב": "פארס ח'ורי"
        },
        {
            "_id": 175,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 156,
            "שם_רחוב": "פריד אל-אטרש"
        },
        {
            "_id": 176,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6717,
            "שם_רחוב": "קריית החינוך"
        },
        {
            "_id": 177,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "ראס אלכרם"
        },
        {
            "_id": 178,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 187,
            "שם_רחוב": "רח 200"
        },
        {
            "_id": 179,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רח 300"
        },
        {
            "_id": 180,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 188,
            "שם_רחוב": "רח 210"
        },
        {
            "_id": 181,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 210,
            "שם_רחוב": "רח 310"
        },
        {
            "_id": 182,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 170,
            "שם_רחוב": "רח 120"
        },
        {
            "_id": 183,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 189,
            "שם_רחוב": "רח 220"
        },
        {
            "_id": 184,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 211,
            "שם_רחוב": "רח 320"
        },
        {
            "_id": 185,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 173,
            "שם_רחוב": "רח 130"
        },
        {
            "_id": 186,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 190,
            "שם_רחוב": "רח 230"
        },
        {
            "_id": 187,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 184,
            "שם_רחוב": "רח 330"
        },
        {
            "_id": 188,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 178,
            "שם_רחוב": "רח 140"
        },
        {
            "_id": 189,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 191,
            "שם_רחוב": "רח 240"
        },
        {
            "_id": 190,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 227,
            "שם_רחוב": "רח 340"
        },
        {
            "_id": 191,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 150,
            "שם_רחוב": "רח 150"
        },
        {
            "_id": 192,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 194,
            "שם_רחוב": "רח 250"
        },
        {
            "_id": 193,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 183,
            "שם_רחוב": "רח 160"
        },
        {
            "_id": 194,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 195,
            "שם_רחוב": "רח 260"
        },
        {
            "_id": 195,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 246,
            "שם_רחוב": "רח 70"
        },
        {
            "_id": 196,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 240,
            "שם_רחוב": "רח 170"
        },
        {
            "_id": 197,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 185,
            "שם_רחוב": "רח 180"
        },
        {
            "_id": 198,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 202,
            "שם_רחוב": "רח 280"
        },
        {
            "_id": 199,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 216,
            "שם_רחוב": "רח 90"
        },
        {
            "_id": 200,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 186,
            "שם_רחוב": "רח 190"
        },
        {
            "_id": 201,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח 290"
        },
        {
            "_id": 202,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רח 41"
        },
        {
            "_id": 203,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 163,
            "שם_רחוב": "רח 51"
        },
        {
            "_id": 204,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח 2"
        },
        {
            "_id": 205,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רח 22"
        },
        {
            "_id": 206,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 193,
            "שם_רחוב": "רח 82"
        },
        {
            "_id": 207,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 146,
            "שם_רחוב": "רח 53"
        },
        {
            "_id": 208,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 140,
            "שם_רחוב": "רח 63"
        },
        {
            "_id": 209,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 147,
            "שם_רחוב": "רח 54"
        },
        {
            "_id": 210,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 154,
            "שם_רחוב": "רח 105"
        },
        {
            "_id": 211,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רח 25"
        },
        {
            "_id": 212,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 155,
            "שם_רחוב": "רח 45"
        },
        {
            "_id": 213,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 230,
            "שם_רחוב": "רח 55"
        },
        {
            "_id": 214,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 157,
            "שם_רחוב": "רח 46"
        },
        {
            "_id": 215,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 231,
            "שם_רחוב": "רח 56"
        },
        {
            "_id": 216,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 292,
            "שם_רחוב": "רח 76"
        },
        {
            "_id": 217,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 158,
            "שם_רחוב": "רח 47"
        },
        {
            "_id": 218,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 143,
            "שם_רחוב": "רח 108"
        },
        {
            "_id": 219,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 139,
            "שם_רחוב": "רח 38"
        },
        {
            "_id": 220,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 159,
            "שם_רחוב": "רח 48"
        },
        {
            "_id": 221,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 197,
            "שם_רחוב": "רח רח 270"
        },
        {
            "_id": 222,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6702,
            "שם_רחוב": "שכ הכנסייה"
        },
        {
            "_id": 223,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "שכונת המסגד"
        },
        {
            "_id": 224,
            "סמל_ישוב": 473,
            "שם_ישוב": "אבו סנאן ",
            "סמל_רחוב": 6726,
            "שם_רחוב": "שכונת השוק"
        },
        {
            "_id": 225,
            "סמל_ישוב": 935,
            "שם_ישוב": "אבו סריחאן (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו סריחאן (שבט)"
        },
        {
            "_id": 226,
            "סמל_ישוב": 958,
            "שם_ישוב": "אבו עבדון (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו עבדון (שבט)"
        },
        {
            "_id": 227,
            "סמל_ישוב": 1042,
            "שם_ישוב": "אבו עמאר (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו עמאר (שבט)"
        },
        {
            "_id": 228,
            "סמל_ישוב": 932,
            "שם_ישוב": "אבו עמרה (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו עמרה (שבט)"
        },
        {
            "_id": 229,
            "סמל_ישוב": 968,
            "שם_ישוב": "אבו קורינאת (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו קורינאת (שבט)"
        },
        {
            "_id": 230,
            "סמל_ישוב": 1342,
            "שם_ישוב": "אבו קרינאת (יישוב) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו קרינאת (יישוב)"
        },
        {
            "_id": 231,
            "סמל_ישוב": 966,
            "שם_ישוב": "אבו רובייעה (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו רובייעה (שבט)"
        },
        {
            "_id": 232,
            "סמל_ישוב": 966,
            "שם_ישוב": "אבו רובייעה (שבט) ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אל קרעאן"
        },
        {
            "_id": 233,
            "סמל_ישוב": 961,
            "שם_ישוב": "אבו רוקייק (שבט) ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אבו כף"
        },
        {
            "_id": 234,
            "סמל_ישוב": 961,
            "שם_ישוב": "אבו רוקייק (שבט) ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אבו סבית"
        },
        {
            "_id": 235,
            "סמל_ישוב": 961,
            "שם_ישוב": "אבו רוקייק (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו רוקייק (שבט)"
        },
        {
            "_id": 236,
            "סמל_ישוב": 961,
            "שם_ישוב": "אבו רוקייק (שבט) ",
            "סמל_רחוב": 107,
            "שם_רחוב": "טרשאן"
        },
        {
            "_id": 237,
            "סמל_ישוב": 1375,
            "שם_ישוב": "אבו תלול ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבו תלול"
        },
        {
            "_id": 238,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אבו בכר אלסדיק"
        },
        {
            "_id": 239,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבטין"
        },
        {
            "_id": 240,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אל בוח'ארי"
        },
        {
            "_id": 241,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אל בסאתין"
        },
        {
            "_id": 242,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אל הודא"
        },
        {
            "_id": 243,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אל ואדי"
        },
        {
            "_id": 244,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אל זהראא"
        },
        {
            "_id": 245,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אל זייתון"
        },
        {
            "_id": 246,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אל זעתר"
        },
        {
            "_id": 247,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אל ח'רוב"
        },
        {
            "_id": 248,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אל יאסמין"
        },
        {
            "_id": 249,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אל מוח'תאר"
        },
        {
            "_id": 250,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אל מנארה"
        },
        {
            "_id": 251,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אל מרכז"
        },
        {
            "_id": 252,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אל נח'יל"
        },
        {
            "_id": 253,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אל נרג'ס"
        },
        {
            "_id": 254,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אל סלאם"
        },
        {
            "_id": 255,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אל פרדוס"
        },
        {
            "_id": 256,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אל קודס"
        },
        {
            "_id": 257,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אל רמאן"
        },
        {
            "_id": 258,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלבלוט"
        },
        {
            "_id": 259,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 112,
            "שם_רחוב": "טיבה"
        },
        {
            "_id": 260,
            "סמל_ישוב": 652,
            "שם_ישוב": "אבטין ",
            "סמל_רחוב": 103,
            "שם_רחוב": "עומר אבן אלחטאב"
        },
        {
            "_id": 261,
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבטליון"
        },
        {
            "_id": 262,
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית הבד"
        },
        {
            "_id": 263,
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "בראשית"
        },
        {
            "_id": 264,
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך ההר"
        },
        {
            "_id": 265,
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 266,
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הבקעה"
        },
        {
            "_id": 267,
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 268,
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 269,
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 270,
            "סמל_ישוב": 1275,
            "שם_ישוב": "אבטליון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מצפה הימים"
        },
        {
            "_id": 271,
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אביאל"
        },
        {
            "_id": 272,
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 273,
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 274,
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הברושים"
        },
        {
            "_id": 275,
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 276,
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 277,
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 278,
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 279,
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 280,
            "סמל_ישוב": 679,
            "שם_ישוב": "אביאל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 281,
            "סמל_ישוב": 1115,
            "שם_ישוב": "אביבים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אביבים"
        },
        {
            "_id": 282,
            "סמל_ישוב": 819,
            "שם_ישוב": "אביגדור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אביגדור"
        },
        {
            "_id": 283,
            "סמל_ישוב": 3869,
            "שם_ישוב": "אביגיל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אביגיל"
        },
        {
            "_id": 284,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אביחיל"
        },
        {
            "_id": 285,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "בן צבי"
        },
        {
            "_id": 286,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 287,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "דרך פרחים"
        },
        {
            "_id": 288,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 289,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 290,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 291,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 292,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 293,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "החורשה"
        },
        {
            "_id": 294,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הלחי"
        },
        {
            "_id": 295,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 296,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 297,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 298,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 299,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 300,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 301,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "לוחמי גליפולי"
        },
        {
            "_id": 302,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נהגי הפרדות"
        },
        {
            "_id": 303,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "עין העובד"
        },
        {
            "_id": 304,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 305,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 306,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שוורצברד"
        },
        {
            "_id": 307,
            "סמל_ישוב": 175,
            "שם_ישוב": "אביחיל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 308,
            "סמל_ישוב": 2052,
            "שם_ישוב": "אביטל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אביטל"
        },
        {
            "_id": 309,
            "סמל_ישוב": 1070,
            "שם_ישוב": "אביעזר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אביעזר"
        },
        {
            "_id": 310,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבירים"
        },
        {
            "_id": 311,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "גדרון"
        },
        {
            "_id": 312,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 313,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 314,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ינשוף"
        },
        {
            "_id": 315,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 316,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 317,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "עורבני"
        },
        {
            "_id": 318,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 319,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 320,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שביל המכשפות"
        },
        {
            "_id": 321,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שביל הפיות"
        },
        {
            "_id": 322,
            "סמל_ישוב": 1220,
            "שם_ישוב": "אבירים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שחרור"
        },
        {
            "_id": 323,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אביבית"
        },
        {
            "_id": 324,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבן יהודה"
        },
        {
            "_id": 325,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 282,
            "שם_רחוב": "אבני החושן"
        },
        {
            "_id": 326,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אגמון"
        },
        {
            "_id": 327,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "אדווה"
        },
        {
            "_id": 328,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אדם"
        },
        {
            "_id": 329,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אדמונית"
        },
        {
            "_id": 330,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אהבת ציון"
        },
        {
            "_id": 331,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "אופק"
        },
        {
            "_id": 332,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "אזור תעשייה"
        },
        {
            "_id": 333,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "אחוזת מישרים"
        },
        {
            "_id": 334,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אילת השחר"
        },
        {
            "_id": 335,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אירוס הארגמן"
        },
        {
            "_id": 336,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 337,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 373,
            "שם_רחוב": "אלמוגן"
        },
        {
            "_id": 338,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 339,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 340,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אסותא"
        },
        {
            "_id": 341,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 379,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 342,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 343,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "באר גנים"
        },
        {
            "_id": 344,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 315,
            "שם_רחוב": "בושמת"
        },
        {
            "_id": 345,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "בית השואבה"
        },
        {
            "_id": 346,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "בני בנימין"
        },
        {
            "_id": 347,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 348,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 285,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 349,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "גבעונית"
        },
        {
            "_id": 350,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "גבעת רימון"
        },
        {
            "_id": 351,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "גל"
        },
        {
            "_id": 352,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 280,
            "שם_רחוב": "דבורה"
        },
        {
            "_id": 353,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "דגן"
        },
        {
            "_id": 354,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "דודאים"
        },
        {
            "_id": 355,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 356,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "דן"
        },
        {
            "_id": 357,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "דניאל"
        },
        {
            "_id": 358,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 359,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 380,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 360,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "דרך בין השדות"
        },
        {
            "_id": 361,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "דרך רבין"
        },
        {
            "_id": 362,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 363,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "האגס"
        },
        {
            "_id": 364,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "האודם"
        },
        {
            "_id": 365,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "האוכמניות"
        },
        {
            "_id": 366,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "האורזות"
        },
        {
            "_id": 367,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 368,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "האיזמרגד"
        },
        {
            "_id": 369,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 370,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "האירוסים"
        },
        {
            "_id": 371,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 372,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 373,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 374,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "הבאר"
        },
        {
            "_id": 375,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 376,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 377,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "הגבעה"
        },
        {
            "_id": 378,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 379,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 380,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 381,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 382,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "הדובדבן"
        },
        {
            "_id": 383,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "הדליות"
        },
        {
            "_id": 384,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 385,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 386,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 387,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 388,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "ההרדופים"
        },
        {
            "_id": 389,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 390,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 391,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 392,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 393,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "היבולים"
        },
        {
            "_id": 394,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 377,
            "שם_רחוב": "היונה"
        },
        {
            "_id": 395,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "היצירה"
        },
        {
            "_id": 396,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 281,
            "שם_רחוב": "הכינור"
        },
        {
            "_id": 397,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "הלוחמים"
        },
        {
            "_id": 398,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "המחתרת"
        },
        {
            "_id": 399,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "המיסדים"
        },
        {
            "_id": 400,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 401,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "המתמיד"
        },
        {
            "_id": 402,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 403,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "הנוטע"
        },
        {
            "_id": 404,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הנוטר"
        },
        {
            "_id": 405,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הנופר"
        },
        {
            "_id": 406,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 407,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 408,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "הסוף"
        },
        {
            "_id": 409,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "הסחלב"
        },
        {
            "_id": 410,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הסייפן"
        },
        {
            "_id": 411,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "העירית"
        },
        {
            "_id": 412,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 413,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 414,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "הפטל"
        },
        {
            "_id": 415,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 416,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "הפרגים"
        },
        {
            "_id": 417,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 418,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הקשת"
        },
        {
            "_id": 419,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 420,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "הרימונים"
        },
        {
            "_id": 421,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "הרקפות"
        },
        {
            "_id": 422,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 423,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 424,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "השושן"
        },
        {
            "_id": 425,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "השיח"
        },
        {
            "_id": 426,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 427,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "השלום"
        },
        {
            "_id": 428,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "השלושה"
        },
        {
            "_id": 429,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 430,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 431,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 432,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "התומר"
        },
        {
            "_id": 433,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "התות"
        },
        {
            "_id": 434,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 435,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 436,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "התקומה"
        },
        {
            "_id": 437,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "ותיקים"
        },
        {
            "_id": 438,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "זויתן"
        },
        {
            "_id": 439,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 440,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "חבצלת השרון"
        },
        {
            "_id": 441,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "חוף"
        },
        {
            "_id": 442,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "חלמונית"
        },
        {
            "_id": 443,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "חמנית"
        },
        {
            "_id": 444,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "חן"
        },
        {
            "_id": 445,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 446,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 447,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 283,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 448,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "ים"
        },
        {
            "_id": 449,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 375,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 450,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 372,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 451,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 452,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 453,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 454,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 455,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "לוע הארי"
        },
        {
            "_id": 456,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 457,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "מור"
        },
        {
            "_id": 458,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 371,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 459,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "מכבי"
        },
        {
            "_id": 460,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 318,
            "שם_רחוב": "מנטה"
        },
        {
            "_id": 461,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "מעיין"
        },
        {
            "_id": 462,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "מעלה הארגמן"
        },
        {
            "_id": 463,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "מעלה הלבונה"
        },
        {
            "_id": 464,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "מעלה הרתמים"
        },
        {
            "_id": 465,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 466,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 378,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 467,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 468,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "נווה הדר"
        },
        {
            "_id": 469,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "נחל אל-על"
        },
        {
            "_id": 470,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "נחל סער"
        },
        {
            "_id": 471,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "נחל פולג"
        },
        {
            "_id": 472,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "נחל שניר"
        },
        {
            "_id": 473,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "נחשול"
        },
        {
            "_id": 474,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "ניצן"
        },
        {
            "_id": 475,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "נר הלילה"
        },
        {
            "_id": 476,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 477,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "סהרון"
        },
        {
            "_id": 478,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 479,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "סמ האלה"
        },
        {
            "_id": 480,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "סמ החרמון"
        },
        {
            "_id": 481,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "סמ הלוטוס"
        },
        {
            "_id": 482,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "סמ הניצנים"
        },
        {
            "_id": 483,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "סמ הקולנוע"
        },
        {
            "_id": 484,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "סמ השושנה"
        },
        {
            "_id": 485,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "סמ השישה"
        },
        {
            "_id": 486,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סמ יעקובי"
        },
        {
            "_id": 487,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 381,
            "שם_רחוב": "סמ יערה"
        },
        {
            "_id": 488,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "סמטת ברקן"
        },
        {
            "_id": 489,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "סמטת גומא"
        },
        {
            "_id": 490,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 491,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 284,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 492,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "סתוונית"
        },
        {
            "_id": 493,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "עגור"
        },
        {
            "_id": 494,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "עדעד"
        },
        {
            "_id": 495,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "עופרית"
        },
        {
            "_id": 496,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "עליה"
        },
        {
            "_id": 497,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "עממי"
        },
        {
            "_id": 498,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 499,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "עציון"
        },
        {
            "_id": 500,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "פלג"
        },
        {
            "_id": 501,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 502,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "פרי ההדר"
        },
        {
            "_id": 503,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 504,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 376,
            "שם_רחוב": "צבר"
        },
        {
            "_id": 505,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "צדף"
        },
        {
            "_id": 506,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 507,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 279,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 508,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "צוק"
        },
        {
            "_id": 509,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "צורית"
        },
        {
            "_id": 510,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "ציפורן"
        },
        {
            "_id": 511,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 512,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 513,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "קחון"
        },
        {
            "_id": 514,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "קינמון"
        },
        {
            "_id": 515,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "קקטוס"
        },
        {
            "_id": 516,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 517,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "רוזמרין"
        },
        {
            "_id": 518,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "ריחן"
        },
        {
            "_id": 519,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "ריף"
        },
        {
            "_id": 520,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "רמת בן גוריון"
        },
        {
            "_id": 521,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "רמת היובל"
        },
        {
            "_id": 522,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש אסבסטונים"
        },
        {
            "_id": 523,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש הותיקים"
        },
        {
            "_id": 524,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש חן"
        },
        {
            "_id": 525,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש יבנאי"
        },
        {
            "_id": 526,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש מכבי"
        },
        {
            "_id": 527,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש עובדים"
        },
        {
            "_id": 528,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "ש עין יעקב"
        },
        {
            "_id": 529,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש עממי"
        },
        {
            "_id": 530,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "ש שכטר"
        },
        {
            "_id": 531,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 287,
            "שם_רחוב": "שבו"
        },
        {
            "_id": 532,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 533,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "שביל הנוטע"
        },
        {
            "_id": 534,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 278,
            "שם_רחוב": "שביל הפרדס"
        },
        {
            "_id": 535,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "שדמה"
        },
        {
            "_id": 536,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 286,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 537,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שומשום"
        },
        {
            "_id": 538,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 269,
            "שם_רחוב": "שונית"
        },
        {
            "_id": 539,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 540,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 541,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ הדסים"
        },
        {
            "_id": 542,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ פאר גנים"
        },
        {
            "_id": 543,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ קרוונים"
        },
        {
            "_id": 544,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 545,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "שלהבת"
        },
        {
            "_id": 546,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "שן הארי"
        },
        {
            "_id": 547,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "שנהב"
        },
        {
            "_id": 548,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 549,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 550,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 551,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 317,
            "שם_רחוב": "תורמוס"
        },
        {
            "_id": 552,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "תירוש"
        },
        {
            "_id": 553,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "תל צור"
        },
        {
            "_id": 554,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 555,
            "סמל_ישוב": 182,
            "שם_ישוב": "אבן יהודה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "תפוח"
        },
        {
            "_id": 556,
            "סמל_ישוב": 1081,
            "שם_ישוב": "אבן מנחם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבן מנחם"
        },
        {
            "_id": 557,
            "סמל_ישוב": 1081,
            "שם_ישוב": "אבן מנחם ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 558,
            "סמל_ישוב": 1081,
            "שם_ישוב": "אבן מנחם ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 559,
            "סמל_ישוב": 1081,
            "שם_ישוב": "אבן מנחם ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 560,
            "סמל_ישוב": 1081,
            "שם_ישוב": "אבן מנחם ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 561,
            "סמל_ישוב": 1081,
            "שם_ישוב": "אבן מנחם ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 562,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבן ספיר"
        },
        {
            "_id": 563,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אגט"
        },
        {
            "_id": 564,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 565,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 566,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "גביש"
        },
        {
            "_id": 567,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "גרניט"
        },
        {
            "_id": 568,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "החרסית"
        },
        {
            "_id": 569,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "חלמיש"
        },
        {
            "_id": 570,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 571,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 572,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 573,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "צור"
        },
        {
            "_id": 574,
            "סמל_ישוב": 783,
            "שם_ישוב": "אבן ספיר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 575,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבן שמואל"
        },
        {
            "_id": 576,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דרך הפרחים"
        },
        {
            "_id": 577,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך הראשונים"
        },
        {
            "_id": 578,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "האודם"
        },
        {
            "_id": 579,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 580,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 127,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 581,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הברקת"
        },
        {
            "_id": 582,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 583,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הדוכיפת"
        },
        {
            "_id": 584,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 585,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 586,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 587,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 588,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "החושן"
        },
        {
            "_id": 589,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "היהלום"
        },
        {
            "_id": 590,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 591,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 592,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הנחליאלי"
        },
        {
            "_id": 593,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 594,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 595,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הסנונית"
        },
        {
            "_id": 596,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הספיר"
        },
        {
            "_id": 597,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "העפרוני"
        },
        {
            "_id": 598,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרב אשר ליטוב"
        },
        {
            "_id": 599,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 600,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 124,
            "שם_רחוב": "השלדג"
        },
        {
            "_id": 601,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 602,
            "סמל_ישוב": 400,
            "שם_ישוב": "אבן שמואל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 603,
            "סמל_ישוב": 4011,
            "שם_ישוב": "אבני איתן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבני איתן"
        },
        {
            "_id": 604,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבני חפץ"
        },
        {
            "_id": 605,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 606,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 113,
            "שם_רחוב": "איבי הנחל"
        },
        {
            "_id": 607,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 608,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 117,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 609,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 111,
            "שם_רחוב": "גינת אגוז"
        },
        {
            "_id": 610,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 120,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 611,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 107,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 612,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דרך הראה"
        },
        {
            "_id": 613,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 101,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 614,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 615,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 110,
            "שם_רחוב": "המור"
        },
        {
            "_id": 616,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 105,
            "שם_רחוב": "חושן"
        },
        {
            "_id": 617,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 122,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 618,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 106,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 619,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 108,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 620,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 112,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 621,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 118,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 622,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "מצפה חפץ"
        },
        {
            "_id": 623,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 624,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שחר"
        },
        {
            "_id": 625,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ מעלה יעקב"
        },
        {
            "_id": 626,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 627,
            "סמל_ישוב": 3793,
            "שם_ישוב": "אבני חפץ ",
            "סמל_רחוב": 121,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 628,
            "סמל_ישוב": 3786,
            "שם_ישוב": "אבנת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבנת"
        },
        {
            "_id": 629,
            "סמל_ישוב": 1311,
            "שם_ישוב": "אבשלום ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אבשלום"
        },
        {
            "_id": 630,
            "סמל_ישוב": 1311,
            "שם_ישוב": "אבשלום ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שכ קרוונים"
        },
        {
            "_id": 631,
            "סמל_ישוב": 3759,
            "שם_ישוב": "אדורה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אדורה"
        },
        {
            "_id": 632,
            "סמל_ישוב": 3759,
            "שם_ישוב": "אדורה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 633,
            "סמל_ישוב": 3759,
            "שם_ישוב": "אדורה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 634,
            "סמל_ישוב": 3759,
            "שם_ישוב": "אדורה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 635,
            "סמל_ישוב": 3759,
            "שם_ישוב": "אדורה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 636,
            "סמל_ישוב": 113,
            "שם_ישוב": "אדירים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אדירים"
        },
        {
            "_id": 637,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אדמית"
        },
        {
            "_id": 638,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 115,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 639,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 640,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 641,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 118,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 642,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 643,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 644,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הדפנה"
        },
        {
            "_id": 645,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 116,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 646,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 647,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 112,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 648,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הלבנה"
        },
        {
            "_id": 649,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 650,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 113,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 651,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "כיכר גן חמד"
        },
        {
            "_id": 652,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "כיכר הפרחים"
        },
        {
            "_id": 653,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "כיכר מעלה ההר"
        },
        {
            "_id": 654,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר נוף החרמון"
        },
        {
            "_id": 655,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 125,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 656,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 102,
            "שם_רחוב": "נוף הים"
        },
        {
            "_id": 657,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 122,
            "שם_רחוב": "סמטת הכלניות"
        },
        {
            "_id": 658,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 119,
            "שם_רחוב": "סמטת הנרקיסים"
        },
        {
            "_id": 659,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 120,
            "שם_רחוב": "סמטת הסחלב"
        },
        {
            "_id": 660,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 121,
            "שם_רחוב": "סמטת הסייפן"
        },
        {
            "_id": 661,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 124,
            "שם_רחוב": "סמטת העירית"
        },
        {
            "_id": 662,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סמטת הרקפות"
        },
        {
            "_id": 663,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שביל הבוסתן"
        },
        {
            "_id": 664,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שביל הבושם"
        },
        {
            "_id": 665,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שביל הקשת"
        },
        {
            "_id": 666,
            "סמל_ישוב": 1068,
            "שם_ישוב": "אדמית ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שער הנוף"
        },
        {
            "_id": 667,
            "סמל_ישוב": 1123,
            "שם_ישוב": "אדרת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אדרת"
        },
        {
            "_id": 668,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אודים"
        },
        {
            "_id": 669,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 670,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "האזדרכת"
        },
        {
            "_id": 671,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "האיריס"
        },
        {
            "_id": 672,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 673,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הגבעה"
        },
        {
            "_id": 674,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הדגן"
        },
        {
            "_id": 675,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 676,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 677,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 678,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 679,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "היקינטון"
        },
        {
            "_id": 680,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 681,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 682,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "העירית"
        },
        {
            "_id": 683,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הפקאן"
        },
        {
            "_id": 684,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 685,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 686,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 687,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 688,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "התלתן"
        },
        {
            "_id": 689,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 690,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "סמ המגדל"
        },
        {
            "_id": 691,
            "סמל_ישוב": 446,
            "שם_ישוב": "אודים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 692,
            "סמל_ישוב": 4010,
            "שם_ישוב": "אודם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 693,
            "סמל_ישוב": 1046,
            "שם_ישוב": "אוהד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אוהד"
        },
        {
            "_id": 694,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אבו ד'ר אלע'פארי"
        },
        {
            "_id": 695,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אבו הורירה"
        },
        {
            "_id": 696,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אבו חניפה"
        },
        {
            "_id": 697,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 495,
            "שם_רחוב": "אבו חסן אלאשערי"
        },
        {
            "_id": 698,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 451,
            "שם_רחוב": "אבו נואס"
        },
        {
            "_id": 699,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "אבו סברי"
        },
        {
            "_id": 700,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 537,
            "שם_רחוב": "אבו סלמה"
        },
        {
            "_id": 701,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 462,
            "שם_רחוב": "אבו פראס אלחמדאני"
        },
        {
            "_id": 702,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 450,
            "שם_רחוב": "אבו תמאם"
        },
        {
            "_id": 703,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 441,
            "שם_רחוב": "אבן אל מונדר"
        },
        {
            "_id": 704,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 463,
            "שם_רחוב": "אבן אל-מוקפע"
        },
        {
            "_id": 705,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 446,
            "שם_רחוב": "אבן אל-נדים"
        },
        {
            "_id": 706,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אבן אלאתיר"
        },
        {
            "_id": 707,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 199,
            "שם_רחוב": "אבן אלג'אוזי"
        },
        {
            "_id": 708,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אבן אלהיתם"
        },
        {
            "_id": 709,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 459,
            "שם_רחוב": "אבן אלח'שאב"
        },
        {
            "_id": 710,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אבן אלנפיס"
        },
        {
            "_id": 711,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 382,
            "שם_רחוב": "אבן אלערבי"
        },
        {
            "_id": 712,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 542,
            "שם_רחוב": "אבן אלפארד'"
        },
        {
            "_id": 713,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אבן אלקיים"
        },
        {
            "_id": 714,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 454,
            "שם_רחוב": "אבן אסחאק"
        },
        {
            "_id": 715,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אבן בטוטה"
        },
        {
            "_id": 716,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אבן זיידון"
        },
        {
            "_id": 717,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 718,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 497,
            "שם_רחוב": "אבן חג'ר אלעסקלאני"
        },
        {
            "_id": 719,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אבן חזם"
        },
        {
            "_id": 720,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אבן כת'יר"
        },
        {
            "_id": 721,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 212,
            "שם_רחוב": "אבן מאגה"
        },
        {
            "_id": 722,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אבן מסעוד"
        },
        {
            "_id": 723,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 275,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 724,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אבן סירין"
        },
        {
            "_id": 725,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 460,
            "שם_רחוב": "אבן עאמר"
        },
        {
            "_id": 726,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 506,
            "שם_רחוב": "אבן עבאד"
        },
        {
            "_id": 727,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אבן עבאס"
        },
        {
            "_id": 728,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 465,
            "שם_רחוב": "אבן פרנאס"
        },
        {
            "_id": 729,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אבן רשד"
        },
        {
            "_id": 730,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אבן תימיה"
        },
        {
            "_id": 731,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 510,
            "שם_רחוב": "אג'נאדין"
        },
        {
            "_id": 732,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 545,
            "שם_רחוב": "אדוניס"
        },
        {
            "_id": 733,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אום אל-פחם"
        },
        {
            "_id": 734,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 280,
            "שם_רחוב": "אום אלעבהר"
        },
        {
            "_id": 735,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אומייה"
        },
        {
            "_id": 736,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 379,
            "שם_רחוב": "אח'ואן אלספא"
        },
        {
            "_id": 737,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 218,
            "שם_רחוב": "אחמד אבן חנבל"
        },
        {
            "_id": 738,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 485,
            "שם_רחוב": "אחמד זוויל"
        },
        {
            "_id": 739,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 477,
            "שם_רחוב": "אחמד ראמי"
        },
        {
            "_id": 740,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 470,
            "שם_רחוב": "איבן אל-נדם"
        },
        {
            "_id": 741,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 102,
            "שם_רחוב": "איוב"
        },
        {
            "_id": 742,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 429,
            "שם_רחוב": "איליאא"
        },
        {
            "_id": 743,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 455,
            "שם_רחוב": "אל מנסור"
        },
        {
            "_id": 744,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 368,
            "שם_רחוב": "אל ניל"
        },
        {
            "_id": 745,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 521,
            "שם_רחוב": "אל פאלוג'ה"
        },
        {
            "_id": 746,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 432,
            "שם_רחוב": "אל קיאמה"
        },
        {
            "_id": 747,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 375,
            "שם_רחוב": "אל-אהראם"
        },
        {
            "_id": 748,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 420,
            "שם_רחוב": "אל-אמין"
        },
        {
            "_id": 749,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 356,
            "שם_רחוב": "אל-גזאלי"
        },
        {
            "_id": 750,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אל-זביר"
        },
        {
            "_id": 751,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 249,
            "שם_רחוב": "אל-ח'ליל"
        },
        {
            "_id": 752,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 248,
            "שם_רחוב": "אל-חמה"
        },
        {
            "_id": 753,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 471,
            "שם_רחוב": "אל-כואכבי"
        },
        {
            "_id": 754,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 234,
            "שם_רחוב": "אל-מוארדי"
        },
        {
            "_id": 755,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 235,
            "שם_רחוב": "אל-מעתצם"
        },
        {
            "_id": 756,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 452,
            "שם_רחוב": "אל-מקריזי"
        },
        {
            "_id": 757,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אל-סיוטי"
        },
        {
            "_id": 758,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 228,
            "שם_רחוב": "אל-ראזי"
        },
        {
            "_id": 759,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 229,
            "שם_רחוב": "אל-ראשדון"
        },
        {
            "_id": 760,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 250,
            "שם_רחוב": "אל-רבאט"
        },
        {
            "_id": 761,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 251,
            "שם_רחוב": "אל-ריאץ'"
        },
        {
            "_id": 762,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אל-שאם"
        },
        {
            "_id": 763,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלאג'באריה"
        },
        {
            "_id": 764,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 392,
            "שם_רחוב": "אלאדריסי"
        },
        {
            "_id": 765,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 513,
            "שם_רחוב": "אלאומויון"
        },
        {
            "_id": 766,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 313,
            "שם_רחוב": "אלאח'ווה"
        },
        {
            "_id": 767,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 412,
            "שם_רחוב": "אלאחסאן"
        },
        {
            "_id": 768,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 342,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 769,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלאנדלוס"
        },
        {
            "_id": 770,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 315,
            "שם_רחוב": "אלאנואר"
        },
        {
            "_id": 771,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 425,
            "שם_רחוב": "אלאנסאר"
        },
        {
            "_id": 772,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 502,
            "שם_רחוב": "אלאסאלה"
        },
        {
            "_id": 773,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 341,
            "שם_רחוב": "אלאסלאח"
        },
        {
            "_id": 774,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 511,
            "שם_רחוב": "אלאסמעי"
        },
        {
            "_id": 775,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 527,
            "שם_רחוב": "אלאספהאני"
        },
        {
            "_id": 776,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלאקואס"
        },
        {
            "_id": 777,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 288,
            "שם_רחוב": "אלאקסא"
        },
        {
            "_id": 778,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 180,
            "שם_רחוב": "אלארג'ואן"
        },
        {
            "_id": 779,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 287,
            "שם_רחוב": "אלארז"
        },
        {
            "_id": 780,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אלארשאד"
        },
        {
            "_id": 781,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אלאשערי"
        },
        {
            "_id": 782,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 289,
            "שם_רחוב": "אלבהגה"
        },
        {
            "_id": 783,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אלבוסתאן"
        },
        {
            "_id": 784,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 478,
            "שם_רחוב": "אלבוסתאני"
        },
        {
            "_id": 785,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 371,
            "שם_רחוב": "אלבוראק"
        },
        {
            "_id": 786,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אלבח'ארי"
        },
        {
            "_id": 787,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלבחרה"
        },
        {
            "_id": 788,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אלבחתורי"
        },
        {
            "_id": 789,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 410,
            "שם_רחוב": "אלביארה"
        },
        {
            "_id": 790,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 487,
            "שם_רחוב": "אלביאתי"
        },
        {
            "_id": 791,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אלבידר"
        },
        {
            "_id": 792,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 423,
            "שם_רחוב": "אלבילסאן"
        },
        {
            "_id": 793,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אלבירוני"
        },
        {
            "_id": 794,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אלבלוט"
        },
        {
            "_id": 795,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 317,
            "שם_רחוב": "אלבנאא"
        },
        {
            "_id": 796,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 316,
            "שם_רחוב": "אלברכאת"
        },
        {
            "_id": 797,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 481,
            "שם_רחוב": "אלג'ואהרי"
        },
        {
            "_id": 798,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 518,
            "שם_רחוב": "אלג'זאאיר"
        },
        {
            "_id": 799,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 366,
            "שם_רחוב": "אלג'זאר"
        },
        {
            "_id": 800,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 498,
            "שם_רחוב": "אלג'ילאני"
        },
        {
            "_id": 801,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 298,
            "שם_רחוב": "אלג'נאן"
        },
        {
            "_id": 802,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 507,
            "שם_רחוב": "אלג'נאת"
        },
        {
            "_id": 803,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 496,
            "שם_רחוב": "אלד'הב"
        },
        {
            "_id": 804,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלד'הר"
        },
        {
            "_id": 805,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 277,
            "שם_רחוב": "אלד'הרה"
        },
        {
            "_id": 806,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אלהג'רה"
        },
        {
            "_id": 807,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 312,
            "שם_רחוב": "אלהדאיה"
        },
        {
            "_id": 808,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 530,
            "שם_רחוב": "אלהדיל"
        },
        {
            "_id": 809,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 337,
            "שם_רחוב": "אלהודא"
        },
        {
            "_id": 810,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 418,
            "שם_רחוב": "אלהלאל"
        },
        {
            "_id": 811,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 353,
            "שם_רחוב": "אלואדי"
        },
        {
            "_id": 812,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 178,
            "שם_רחוב": "אלוורוד"
        },
        {
            "_id": 813,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 435,
            "שם_רחוב": "אלוחדאת"
        },
        {
            "_id": 814,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 338,
            "שם_רחוב": "אלוחדה"
        },
        {
            "_id": 815,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 426,
            "שם_רחוב": "אלוטן"
        },
        {
            "_id": 816,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 339,
            "שם_רחוב": "אלופאא"
        },
        {
            "_id": 817,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלזהראא"
        },
        {
            "_id": 818,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 467,
            "שם_רחוב": "אלזהראוי"
        },
        {
            "_id": 819,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 820,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 397,
            "שם_רחוב": "אלזמח'שרי"
        },
        {
            "_id": 821,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אלזנבק"
        },
        {
            "_id": 822,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 550,
            "שם_רחוב": "אלזעתר"
        },
        {
            "_id": 823,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אלח'וארזמי"
        },
        {
            "_id": 824,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלח'יאם"
        },
        {
            "_id": 825,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 361,
            "שם_רחוב": "אלח'נסאא"
        },
        {
            "_id": 826,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 827,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 304,
            "שם_רחוב": "אלחאמדון"
        },
        {
            "_id": 828,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 544,
            "שם_רחוב": "אלחג'אג'"
        },
        {
            "_id": 829,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 430,
            "שם_רחוב": "אלחג'אז"
        },
        {
            "_id": 830,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 546,
            "שם_רחוב": "אלחדאדין"
        },
        {
            "_id": 831,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 434,
            "שם_רחוב": "אלחדת'"
        },
        {
            "_id": 832,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 458,
            "שם_רחוב": "אלחוסין"
        },
        {
            "_id": 833,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 322,
            "שם_רחוב": "אלחוריה"
        },
        {
            "_id": 834,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 324,
            "שם_רחוב": "אלחכמה"
        },
        {
            "_id": 835,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 466,
            "שם_רחוב": "אלחלילי"
        },
        {
            "_id": 836,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 551,
            "שם_רחוב": "אלחנון"
        },
        {
            "_id": 837,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 492,
            "שם_רחוב": "אלחסאד"
        },
        {
            "_id": 838,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 395,
            "שם_רחוב": "אלחסן"
        },
        {
            "_id": 839,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 355,
            "שם_רחוב": "אלטברי"
        },
        {
            "_id": 840,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 330,
            "שם_רחוב": "אלטיבאת"
        },
        {
            "_id": 841,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 401,
            "שם_רחוב": "אלטייב סאלח"
        },
        {
            "_id": 842,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 297,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 843,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 529,
            "שם_רחוב": "אלימאמה"
        },
        {
            "_id": 844,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 367,
            "שם_רחוב": "אלירמוק"
        },
        {
            "_id": 845,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלכולייה"
        },
        {
            "_id": 846,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 334,
            "שם_רחוב": "אלכות'ר"
        },
        {
            "_id": 847,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 468,
            "שם_רחוב": "אלכנדי"
        },
        {
            "_id": 848,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 282,
            "שם_רחוב": "אלכנדק"
        },
        {
            "_id": 849,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 293,
            "שם_רחוב": "אלכראמה"
        },
        {
            "_id": 850,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 523,
            "שם_רחוב": "אלכרמל"
        },
        {
            "_id": 851,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 301,
            "שם_רחוב": "אללוז"
        },
        {
            "_id": 852,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 302,
            "שם_רחוב": "אללימון"
        },
        {
            "_id": 853,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 197,
            "שם_רחוב": "אלמאמון"
        },
        {
            "_id": 854,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 335,
            "שם_רחוב": "אלמג'ד"
        },
        {
            "_id": 855,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלמדינה"
        },
        {
            "_id": 856,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אלמונתזה"
        },
        {
            "_id": 857,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 444,
            "שם_רחוב": "אלמות'נא"
        },
        {
            "_id": 858,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אלמותנבי"
        },
        {
            "_id": 859,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אלמחאג'נה"
        },
        {
            "_id": 860,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 351,
            "שם_רחוב": "אלמנאר"
        },
        {
            "_id": 861,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 294,
            "שם_רחוב": "אלמנארה"
        },
        {
            "_id": 862,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 421,
            "שם_רחוב": "אלמנזול"
        },
        {
            "_id": 863,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 440,
            "שם_רחוב": "אלמערי"
        },
        {
            "_id": 864,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 350,
            "שם_רחוב": "אלמערפה"
        },
        {
            "_id": 865,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 499,
            "שם_רחוב": "אלמקדסי"
        },
        {
            "_id": 866,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 310,
            "שם_רחוב": "אלמרווה"
        },
        {
            "_id": 867,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 279,
            "שם_רחוב": "אלמרמאלה"
        },
        {
            "_id": 868,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלמרתפעה"
        },
        {
            "_id": 869,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 336,
            "שם_רחוב": "אלמשאעל"
        },
        {
            "_id": 870,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 413,
            "שם_רחוב": "אלנג'אח"
        },
        {
            "_id": 871,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 531,
            "שם_רחוב": "אלנג'יבי"
        },
        {
            "_id": 872,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלנוזהה"
        },
        {
            "_id": 873,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 352,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 874,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 196,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 875,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 358,
            "שם_רחוב": "אלנסאיי"
        },
        {
            "_id": 876,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אלנענאע"
        },
        {
            "_id": 877,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 517,
            "שם_רחוב": "אלנקב"
        },
        {
            "_id": 878,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 453,
            "שם_רחוב": "אלנקשבנדי"
        },
        {
            "_id": 879,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 880,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 490,
            "שם_רחוב": "אלסאדק"
        },
        {
            "_id": 881,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 370,
            "שם_רחוב": "אלסאחל"
        },
        {
            "_id": 882,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 512,
            "שם_רחוב": "אלסאלחיה"
        },
        {
            "_id": 883,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 528,
            "שם_רחוב": "אלסבאר"
        },
        {
            "_id": 884,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אלסבר"
        },
        {
            "_id": 885,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 491,
            "שם_רחוב": "אלסהלאת"
        },
        {
            "_id": 886,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 377,
            "שם_רחוב": "אלסולטאן סולימאן"
        },
        {
            "_id": 887,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 306,
            "שם_רחוב": "אלסועדאא"
        },
        {
            "_id": 888,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אלסופסאף"
        },
        {
            "_id": 889,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלסוק"
        },
        {
            "_id": 890,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 482,
            "שם_רחוב": "אלסיאב"
        },
        {
            "_id": 891,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אלסינדיאן"
        },
        {
            "_id": 892,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 345,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 893,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 532,
            "שם_רחוב": "אלסמאקי"
        },
        {
            "_id": 894,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 394,
            "שם_רחוב": "אלסמואאל"
        },
        {
            "_id": 895,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 427,
            "שם_רחוב": "אלסמוד"
        },
        {
            "_id": 896,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 424,
            "שם_רחוב": "אלסנאבל"
        },
        {
            "_id": 897,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 309,
            "שם_רחוב": "אלספא"
        },
        {
            "_id": 898,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 449,
            "שם_רחוב": "אלע'אפקי"
        },
        {
            "_id": 899,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 508,
            "שם_רחוב": "אלע'ופראן"
        },
        {
            "_id": 900,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 292,
            "שם_רחוב": "אלעג'מי"
        },
        {
            "_id": 901,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 417,
            "שם_רחוב": "אלעדאלה"
        },
        {
            "_id": 902,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 416,
            "שם_רחוב": "אלעהד"
        },
        {
            "_id": 903,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 448,
            "שם_רחוב": "אלעואם"
        },
        {
            "_id": 904,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 445,
            "שם_רחוב": "אלעז אבן עבד אלסלאם"
        },
        {
            "_id": 905,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 331,
            "שם_רחוב": "אלעזה"
        },
        {
            "_id": 906,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 332,
            "שם_רחוב": "אלעטאא"
        },
        {
            "_id": 907,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 278,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 908,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 378,
            "שם_רחוב": "אלעסקלאני"
        },
        {
            "_id": 909,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 476,
            "שם_רחוב": "אלעקאד"
        },
        {
            "_id": 910,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 488,
            "שם_רחוב": "אלפארוק"
        },
        {
            "_id": 911,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 362,
            "שם_רחוב": "אלפאתח"
        },
        {
            "_id": 912,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 333,
            "שם_רחוב": "אלפדאא"
        },
        {
            "_id": 913,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אלפול"
        },
        {
            "_id": 914,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 369,
            "שם_רחוב": "אלפוראת"
        },
        {
            "_id": 915,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 346,
            "שם_רחוב": "אלפח'ר"
        },
        {
            "_id": 916,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 436,
            "שם_רחוב": "אלפיחאא"
        },
        {
            "_id": 917,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 347,
            "שם_רחוב": "אלפנאר"
        },
        {
            "_id": 918,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 357,
            "שם_רחוב": "אלפראבי"
        },
        {
            "_id": 919,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 457,
            "שם_רחוב": "אלפראהידי"
        },
        {
            "_id": 920,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 300,
            "שם_רחוב": "אלפרדוס"
        },
        {
            "_id": 921,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 389,
            "שם_רחוב": "אלפרזדק"
        },
        {
            "_id": 922,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 348,
            "שם_רחוב": "אלקאדי"
        },
        {
            "_id": 923,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 283,
            "שם_רחוב": "אלקאדסייה"
        },
        {
            "_id": 924,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלקאהרה"
        },
        {
            "_id": 925,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלקודס"
        },
        {
            "_id": 926,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 494,
            "שם_רחוב": "אלקינא"
        },
        {
            "_id": 927,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 456,
            "שם_רחוב": "אלקלים"
        },
        {
            "_id": 928,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 419,
            "שם_רחוב": "אלקלם"
        },
        {
            "_id": 929,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 349,
            "שם_רחוב": "אלקנדיל"
        },
        {
            "_id": 930,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 548,
            "שם_רחוב": "אלקרם"
        },
        {
            "_id": 931,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 296,
            "שם_רחוב": "אלקרנפול"
        },
        {
            "_id": 932,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 291,
            "שם_רחוב": "אלראפדין"
        },
        {
            "_id": 933,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אלראפה"
        },
        {
            "_id": 934,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אלרביע"
        },
        {
            "_id": 935,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 305,
            "שם_רחוב": "אלרודה"
        },
        {
            "_id": 936,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלרוחה"
        },
        {
            "_id": 937,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 299,
            "שם_רחוב": "אלרומאן"
        },
        {
            "_id": 938,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 326,
            "שם_רחוב": "אלרחמה"
        },
        {
            "_id": 939,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלריאחין"
        },
        {
            "_id": 940,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 941,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלריף"
        },
        {
            "_id": 942,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 415,
            "שם_רחוב": "אלרסאלה"
        },
        {
            "_id": 943,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 307,
            "שם_רחוב": "אלשאכרון"
        },
        {
            "_id": 944,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלשאע'ור"
        },
        {
            "_id": 945,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 376,
            "שם_רחוב": "אלשאפעי"
        },
        {
            "_id": 946,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 431,
            "שם_רחוב": "אלשג'רה"
        },
        {
            "_id": 947,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 437,
            "שם_רחוב": "אלשהבאא"
        },
        {
            "_id": 948,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אלשועלה"
        },
        {
            "_id": 949,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 327,
            "שם_רחוב": "אלשורוק"
        },
        {
            "_id": 950,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 364,
            "שם_רחוב": "אלשיח' חסין"
        },
        {
            "_id": 951,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 538,
            "שם_רחוב": "אלשייך תאופיק"
        },
        {
            "_id": 952,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 509,
            "שם_רחוב": "אלשריף חוסין"
        },
        {
            "_id": 953,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 344,
            "שם_רחוב": "אלת'נאא"
        },
        {
            "_id": 954,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 320,
            "שם_רחוב": "אלתואסול"
        },
        {
            "_id": 955,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 343,
            "שם_רחוב": "אלתופיק"
        },
        {
            "_id": 956,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 321,
            "שם_רחוב": "אלתוריה"
        },
        {
            "_id": 957,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 318,
            "שם_רחוב": "אלתחריר"
        },
        {
            "_id": 958,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלתסאמוח"
        },
        {
            "_id": 959,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 319,
            "שם_רחוב": "אלתעמיר"
        },
        {
            "_id": 960,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 403,
            "שם_רחוב": "אמנה בנת והב"
        },
        {
            "_id": 961,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 340,
            "שם_רחוב": "אפאק"
        },
        {
            "_id": 962,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אריחא"
        },
        {
            "_id": 963,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 247,
            "שם_רחוב": "אשבליה"
        },
        {
            "_id": 964,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 254,
            "שם_רחוב": "בג'דאד"
        },
        {
            "_id": 965,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 284,
            "שם_רחוב": "בדר"
        },
        {
            "_id": 966,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 104,
            "שם_רחוב": "ביברז"
        },
        {
            "_id": 967,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 256,
            "שם_רחוב": "בירות"
        },
        {
            "_id": 968,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 255,
            "שם_רחוב": "בית אל-מקדס"
        },
        {
            "_id": 969,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 103,
            "שם_רחוב": "בלאל"
        },
        {
            "_id": 970,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 236,
            "שם_רחוב": "ג'אבר אבן חיאן"
        },
        {
            "_id": 971,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 373,
            "שם_רחוב": "ג'בל אלערב"
        },
        {
            "_id": 972,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 504,
            "שם_רחוב": "ג'לאל אלדין אלרומי"
        },
        {
            "_id": 973,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 409,
            "שם_רחוב": "ג'מילה"
        },
        {
            "_id": 974,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 257,
            "שם_רחוב": "ג'נין"
        },
        {
            "_id": 975,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 388,
            "שם_רחוב": "ג'ריר"
        },
        {
            "_id": 976,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 267,
            "שם_רחוב": "ג'רנאטה"
        },
        {
            "_id": 977,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 526,
            "שם_רחוב": "גובראן חליל גובראן"
        },
        {
            "_id": 978,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 501,
            "שם_רחוב": "ד'את אלנטאקיין"
        },
        {
            "_id": 979,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 500,
            "שם_רחוב": "ד'ו אלנורין"
        },
        {
            "_id": 980,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 433,
            "שם_רחוב": "ד'י קאר"
        },
        {
            "_id": 981,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 385,
            "שם_רחוב": "דאהר אל עומר"
        },
        {
            "_id": 982,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 540,
            "שם_רחוב": "דבת אלמוגר"
        },
        {
            "_id": 983,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 260,
            "שם_רחוב": "דמשק"
        },
        {
            "_id": 984,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 276,
            "שם_רחוב": "דרך אבו בכר"
        },
        {
            "_id": 985,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 360,
            "שם_רחוב": "דרך אבו נופול"
        },
        {
            "_id": 986,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 216,
            "שם_רחוב": "דרך אבו עבידה"
        },
        {
            "_id": 987,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 123,
            "שם_רחוב": "דרך אלבאטן"
        },
        {
            "_id": 988,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 125,
            "שם_רחוב": "דרך אלביאדר"
        },
        {
            "_id": 989,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 126,
            "שם_רחוב": "דרך אלביאר"
        },
        {
            "_id": 990,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 127,
            "שם_רחוב": "דרך אלביר"
        },
        {
            "_id": 991,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 129,
            "שם_רחוב": "דרך אלג'בארין"
        },
        {
            "_id": 992,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 130,
            "שם_רחוב": "דרך אלגדוע אלספלי"
        },
        {
            "_id": 993,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 131,
            "שם_רחוב": "דרך אלגדוע אלעלוי"
        },
        {
            "_id": 994,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 152,
            "שם_רחוב": "דרך אלמועלקה"
        },
        {
            "_id": 995,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 148,
            "שם_רחוב": "דרך אלמחאמיד"
        },
        {
            "_id": 996,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 149,
            "שם_רחוב": "דרך אלמחג'ר"
        },
        {
            "_id": 997,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 153,
            "שם_רחוב": "דרך אלמלסאא"
        },
        {
            "_id": 998,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 541,
            "שם_רחוב": "דרך אלמסאיאת"
        },
        {
            "_id": 999,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 137,
            "שם_רחוב": "דרך אלסולטאנה"
        },
        {
            "_id": 1000,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 144,
            "שם_רחוב": "דרך אלע'זלאת"
        },
        {
            "_id": 1001,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 143,
            "שם_רחוב": "דרך אלעיון"
        },
        {
            "_id": 1002,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 133,
            "שם_רחוב": "דרך אלרבזה"
        },
        {
            "_id": 1003,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 140,
            "שם_רחוב": "דרך אלשרפה"
        },
        {
            "_id": 1004,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 155,
            "שם_רחוב": "דרך בידר דבאלה"
        },
        {
            "_id": 1005,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 166,
            "שם_רחוב": "דרך ואדי אלנסור"
        },
        {
            "_id": 1006,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 168,
            "שם_רחוב": "דרך ואדי מלחם"
        },
        {
            "_id": 1007,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 156,
            "שם_רחוב": "דרך טלעת אלמידאן"
        },
        {
            "_id": 1008,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 160,
            "שם_רחוב": "דרך עין אלד'רווה"
        },
        {
            "_id": 1009,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 163,
            "שם_רחוב": "דרך עין אלוסטא"
        },
        {
            "_id": 1010,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 161,
            "שם_רחוב": "דרך עין אלמע'ארה"
        },
        {
            "_id": 1011,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 162,
            "שם_רחוב": "דרך עין אלנבי"
        },
        {
            "_id": 1012,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 158,
            "שם_רחוב": "דרך עין אלתינה"
        },
        {
            "_id": 1013,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 164,
            "שם_רחוב": "דרך עין גראר"
        },
        {
            "_id": 1014,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 165,
            "שם_רחוב": "דרך עין ח'אלד"
        },
        {
            "_id": 1015,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 115,
            "שם_רחוב": "האג'ר"
        },
        {
            "_id": 1016,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 380,
            "שם_רחוב": "הארון אלרשיד"
        },
        {
            "_id": 1017,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 534,
            "שם_רחוב": "הנד אל חוסיני"
        },
        {
            "_id": 1018,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 167,
            "שם_רחוב": "ואדי ג'סאל"
        },
        {
            "_id": 1019,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 539,
            "שם_רחוב": "וגיה פיאד"
        },
        {
            "_id": 1020,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 514,
            "שם_רחוב": "זהא חדיד"
        },
        {
            "_id": 1021,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 169,
            "שם_רחוב": "זמזם"
        },
        {
            "_id": 1022,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 406,
            "שם_רחוב": "זנוביה"
        },
        {
            "_id": 1023,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 479,
            "שם_רחוב": "זריאב"
        },
        {
            "_id": 1024,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 240,
            "שם_רחוב": "ח'אלד אבן אלוליד"
        },
        {
            "_id": 1025,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 106,
            "שם_רחוב": "ח'דיג'ה בנת ח'וילד"
        },
        {
            "_id": 1026,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 428,
            "שם_רחוב": "ח'וביזה"
        },
        {
            "_id": 1027,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 399,
            "שם_רחוב": "ח'ליל אלסכאניני"
        },
        {
            "_id": 1028,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 386,
            "שם_רחוב": "חאתם אלטאאי"
        },
        {
            "_id": 1029,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 237,
            "שם_רחוב": "חד'יפה"
        },
        {
            "_id": 1030,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 258,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 1031,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 259,
            "שם_רחוב": "חיפא"
        },
        {
            "_id": 1032,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 543,
            "שם_רחוב": "חמזה בן ע. אלמוטלב"
        },
        {
            "_id": 1033,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 475,
            "שם_רחוב": "חנדלה"
        },
        {
            "_id": 1034,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 238,
            "שם_רחוב": "חסאן אבן ת'אבת"
        },
        {
            "_id": 1035,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 461,
            "שם_רחוב": "חסן אלבסרי"
        },
        {
            "_id": 1036,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 109,
            "שם_רחוב": "טאהא חסין"
        },
        {
            "_id": 1037,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 243,
            "שם_רחוב": "טארק אבן זיאד"
        },
        {
            "_id": 1038,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 354,
            "שם_רחוב": "טיבה"
        },
        {
            "_id": 1039,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 263,
            "שם_רחוב": "טראבלס"
        },
        {
            "_id": 1040,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 516,
            "שם_רחוב": "יאבוס"
        },
        {
            "_id": 1041,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 274,
            "שם_רחוב": "יאפא"
        },
        {
            "_id": 1042,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 402,
            "שם_רחוב": "יוסף אדריס"
        },
        {
            "_id": 1043,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 533,
            "שם_רחוב": "כלתום עודה"
        },
        {
            "_id": 1044,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 519,
            "שם_רחוב": "כנעאן"
        },
        {
            "_id": 1045,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 520,
            "שם_רחוב": "כפר קאסם"
        },
        {
            "_id": 1046,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 245,
            "שם_רחוב": "מאלכ אבן אנס"
        },
        {
            "_id": 1047,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 489,
            "שם_רחוב": "מאריה אלקבטיה"
        },
        {
            "_id": 1048,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 472,
            "שם_רחוב": "מוחמד אבו ח'דיר"
        },
        {
            "_id": 1049,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 383,
            "שם_רחוב": "מוסא בן מימון"
        },
        {
            "_id": 1050,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 390,
            "שם_רחוב": "מוסא בן נוסיר"
        },
        {
            "_id": 1051,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 486,
            "שם_רחוב": "מוסטפא אלתל"
        },
        {
            "_id": 1052,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 363,
            "שם_רחוב": "מוסעב בן עומיר"
        },
        {
            "_id": 1053,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 119,
            "שם_רחוב": "מועאויה"
        },
        {
            "_id": 1054,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 515,
            "שם_רחוב": "מי זיאדה"
        },
        {
            "_id": 1055,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 271,
            "שם_רחוב": "מכה"
        },
        {
            "_id": 1056,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "מרכז-אגבריה ומחג'נה"
        },
        {
            "_id": 1057,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "מרכז-ג'בארין ומחמיד"
        },
        {
            "_id": 1058,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 272,
            "שם_רחוב": "נאבלס"
        },
        {
            "_id": 1059,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 473,
            "שם_רחוב": "נאזים חכמת"
        },
        {
            "_id": 1060,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נוח"
        },
        {
            "_id": 1061,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 525,
            "שם_רחוב": "נוח איברהים"
        },
        {
            "_id": 1062,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 547,
            "שם_רחוב": "נור אלדין זינקי"
        },
        {
            "_id": 1063,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 535,
            "שם_רחוב": "ניזאר קבאני"
        },
        {
            "_id": 1064,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 273,
            "שם_רחוב": "ניסבור"
        },
        {
            "_id": 1065,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 404,
            "שם_רחוב": "סבאא-שבא"
        },
        {
            "_id": 1066,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 447,
            "שם_רחוב": "סולטאן באשא אלאטרש"
        },
        {
            "_id": 1067,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 242,
            "שם_רחוב": "סיבויה"
        },
        {
            "_id": 1068,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 480,
            "שם_רחוב": "סייד דרויש"
        },
        {
            "_id": 1069,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סלאח אלדין"
        },
        {
            "_id": 1070,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סלמאן אלפארסי"
        },
        {
            "_id": 1071,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 524,
            "שם_רחוב": "סנעאא"
        },
        {
            "_id": 1072,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 241,
            "שם_רחוב": "סעד אבן אבי וקאץ"
        },
        {
            "_id": 1073,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 398,
            "שם_רחוב": "סעד זג'לול"
        },
        {
            "_id": 1074,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 262,
            "שם_רחוב": "ספד"
        },
        {
            "_id": 1075,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 439,
            "שם_רחוב": "סקר קורייש"
        },
        {
            "_id": 1076,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 261,
            "שם_רחוב": "סרפנד"
        },
        {
            "_id": 1077,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 268,
            "שם_רחוב": "ע'זה"
        },
        {
            "_id": 1078,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 244,
            "שם_רחוב": "עאישה"
        },
        {
            "_id": 1079,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 400,
            "שם_רחוב": "עבד אלחמיד"
        },
        {
            "_id": 1080,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 393,
            "שם_רחוב": "עבד אלמאלק בן מרואן"
        },
        {
            "_id": 1081,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 264,
            "שם_רחוב": "עדן"
        },
        {
            "_id": 1082,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 483,
            "שם_רחוב": "עומר אבו רישה"
        },
        {
            "_id": 1083,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 536,
            "שם_רחוב": "עומר אליאפי"
        },
        {
            "_id": 1084,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 114,
            "שם_רחוב": "עומר אלמוח'תאר"
        },
        {
            "_id": 1085,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 396,
            "שם_רחוב": "עומר בן אבי רביעה"
        },
        {
            "_id": 1086,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 391,
            "שם_רחוב": "עומר בן אלעאס"
        },
        {
            "_id": 1087,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 381,
            "שם_רחוב": "עומר בן עבד אל-עזיז"
        },
        {
            "_id": 1088,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 438,
            "שם_רחוב": "עוקאד'"
        },
        {
            "_id": 1089,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 503,
            "שם_רחוב": "עוקבה בן נאפע"
        },
        {
            "_id": 1090,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 111,
            "שם_רחוב": "עות'מאן בן עפאן"
        },
        {
            "_id": 1091,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 157,
            "שם_רחוב": "עין אבראהים"
        },
        {
            "_id": 1092,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 159,
            "שם_רחוב": "עין אלדאליה"
        },
        {
            "_id": 1093,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 285,
            "שם_רחוב": "עין ג'אלוט"
        },
        {
            "_id": 1094,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 265,
            "שם_רחוב": "עכא"
        },
        {
            "_id": 1095,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 112,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 1096,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 266,
            "שם_רחוב": "עמאן"
        },
        {
            "_id": 1097,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 442,
            "שם_רחוב": "ענתרה בן שדאד"
        },
        {
            "_id": 1098,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 522,
            "שם_רחוב": "עסקלאן"
        },
        {
            "_id": 1099,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 170,
            "שם_רחוב": "ערפה"
        },
        {
            "_id": 1100,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 359,
            "שם_רחוב": "קבאא"
        },
        {
            "_id": 1101,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 269,
            "שם_רחוב": "קורטבה"
        },
        {
            "_id": 1102,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "קטאין אלשומר"
        },
        {
            "_id": 1103,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 365,
            "שם_רחוב": "קטר אלנדא"
        },
        {
            "_id": 1104,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 374,
            "שם_רחוב": "קיירואן"
        },
        {
            "_id": 1105,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 270,
            "שם_רחוב": "קיסאריה"
        },
        {
            "_id": 1106,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 407,
            "שם_רחוב": "ראבעה אל-עדויה"
        },
        {
            "_id": 1107,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 443,
            "שם_רחוב": "רג'אא אבן חיוה"
        },
        {
            "_id": 1108,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 408,
            "שם_רחוב": "רוז אל-יוסף"
        },
        {
            "_id": 1109,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 474,
            "שם_רחוב": "רוחי אלח'אלדי"
        },
        {
            "_id": 1110,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 549,
            "שם_רחוב": "שג'רת אלדר"
        },
        {
            "_id": 1111,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 405,
            "שם_רחוב": "שהרזאד"
        },
        {
            "_id": 1112,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6592,
            "שם_רחוב": "שכ אבו לאחם"
        },
        {
            "_id": 1113,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6546,
            "שם_רחוב": "שכ אבו נופל"
        },
        {
            "_id": 1114,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "שכ אל סולטאנה"
        },
        {
            "_id": 1115,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "שכ אלבאטן"
        },
        {
            "_id": 1116,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6571,
            "שם_רחוב": "שכ אלביאדר"
        },
        {
            "_id": 1117,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "שכ אלביאר"
        },
        {
            "_id": 1118,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6535,
            "שם_רחוב": "שכ אלביר"
        },
        {
            "_id": 1119,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "שכ אלדהר"
        },
        {
            "_id": 1120,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "שכ אלכאנוק"
        },
        {
            "_id": 1121,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6585,
            "שם_רחוב": "שכ אלכדור"
        },
        {
            "_id": 1122,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6537,
            "שם_רחוב": "שכ אלכינא"
        },
        {
            "_id": 1123,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ אלכלאיל"
        },
        {
            "_id": 1124,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6591,
            "שם_רחוב": "שכ אלמועלקה"
        },
        {
            "_id": 1125,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ אלמחג'ר"
        },
        {
            "_id": 1126,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6565,
            "שם_רחוב": "שכ אלמידאן"
        },
        {
            "_id": 1127,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6586,
            "שם_רחוב": "שכ אלמלסא"
        },
        {
            "_id": 1128,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6551,
            "שם_רחוב": "שכ אלמסאיאת"
        },
        {
            "_id": 1129,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "שכ אלע'זלאת"
        },
        {
            "_id": 1130,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ אלעיון"
        },
        {
            "_id": 1131,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6548,
            "שם_רחוב": "שכ אלעראיש"
        },
        {
            "_id": 1132,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6601,
            "שם_רחוב": "שכ אלקואס"
        },
        {
            "_id": 1133,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "שכ אלשאג'ור"
        },
        {
            "_id": 1134,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "שכ אסכנדר"
        },
        {
            "_id": 1135,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ בורגול"
        },
        {
            "_id": 1136,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "שכ בידר דבאלה"
        },
        {
            "_id": 1137,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ ג'דוע"
        },
        {
            "_id": 1138,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ ואדי מלחם"
        },
        {
            "_id": 1139,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6596,
            "שם_רחוב": "שכ ואדי נסור"
        },
        {
            "_id": 1140,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6602,
            "שם_רחוב": "שכ ואדי עסאן"
        },
        {
            "_id": 1141,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6568,
            "שם_רחוב": "שכ מסגד אבו עובדיה"
        },
        {
            "_id": 1142,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6580,
            "שם_רחוב": "שכ מסגד אבובכר"
        },
        {
            "_id": 1143,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6575,
            "שם_רחוב": "שכ מסגד ג'בארין"
        },
        {
            "_id": 1144,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ סויסה"
        },
        {
            "_id": 1145,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6600,
            "שם_רחוב": "שכ סתכיזראן"
        },
        {
            "_id": 1146,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6555,
            "שם_רחוב": "שכ ע'רבי"
        },
        {
            "_id": 1147,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6570,
            "שם_רחוב": "שכ עומר בן אלחטאב"
        },
        {
            "_id": 1148,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "שכ עין איברהים"
        },
        {
            "_id": 1149,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6547,
            "שם_רחוב": "שכ עין אל זיתונה"
        },
        {
            "_id": 1150,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6581,
            "שם_רחוב": "שכ עין אלד'רווה"
        },
        {
            "_id": 1151,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6597,
            "שם_רחוב": "שכ עין אלוסטא"
        },
        {
            "_id": 1152,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6598,
            "שם_רחוב": "שכ עין אלמעארה"
        },
        {
            "_id": 1153,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "שכ עין אלנבי"
        },
        {
            "_id": 1154,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ עין אלשערה"
        },
        {
            "_id": 1155,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6595,
            "שם_רחוב": "שכ עין אלתינה"
        },
        {
            "_id": 1156,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6590,
            "שם_רחוב": "שכ עין ג'ראר"
        },
        {
            "_id": 1157,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6552,
            "שם_רחוב": "שכ עין כאלד"
        },
        {
            "_id": 1158,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "שכ עקאדה"
        },
        {
            "_id": 1159,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6545,
            "שם_רחוב": "שכ עראק אלשבאב"
        },
        {
            "_id": 1160,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "שכ קחאוש"
        },
        {
            "_id": 1161,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6560,
            "שם_רחוב": "שכ ראס אלהיש"
        },
        {
            "_id": 1162,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6549,
            "שם_רחוב": "שכ רובזה"
        },
        {
            "_id": 1163,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "שכ שרפה"
        },
        {
            "_id": 1164,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 422,
            "שם_רחוב": "שקאייק אלנועמאן"
        },
        {
            "_id": 1165,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 372,
            "שם_רחוב": "תדמור"
        },
        {
            "_id": 1166,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 384,
            "שם_רחוב": "תמים אלדארי"
        },
        {
            "_id": 1167,
            "סמל_ישוב": 2710,
            "שם_ישוב": "אום אל-פחם ",
            "סמל_רחוב": 411,
            "שם_רחוב": "תקוא"
        },
        {
            "_id": 1168,
            "סמל_ישוב": 2024,
            "שם_ישוב": "אום אל-קוטוף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אום אל-קוטוף"
        },
        {
            "_id": 1169,
            "סמל_ישוב": 1358,
            "שם_ישוב": "אום בטין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אום בטין"
        },
        {
            "_id": 1170,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אומן"
        },
        {
            "_id": 1171,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אירוסים"
        },
        {
            "_id": 1172,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 1173,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלומות"
        },
        {
            "_id": 1174,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 1175,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אשל הירדן"
        },
        {
            "_id": 1176,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 1177,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך אלי ביטון"
        },
        {
            "_id": 1178,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הגלבוע"
        },
        {
            "_id": 1179,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 1180,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 121,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 1181,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 1182,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 117,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 1183,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 1184,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 119,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 1185,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 115,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 1186,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 1187,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 114,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 1188,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 120,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 1189,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 1190,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שושנים"
        },
        {
            "_id": 1191,
            "סמל_ישוב": 1108,
            "שם_ישוב": "אומן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 1192,
            "סמל_ישוב": 680,
            "שם_ישוב": "אומץ ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אומץ"
        },
        {
            "_id": 1193,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אבנר בן נר"
        },
        {
            "_id": 1194,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אברהם הרשקו"
        },
        {
            "_id": 1195,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 300,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 1196,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 502,
            "שם_רחוב": "אגמית"
        },
        {
            "_id": 1197,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 360,
            "שם_רחוב": "אדמונית"
        },
        {
            "_id": 1198,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 243,
            "שם_רחוב": "אהרון צ'חנובר"
        },
        {
            "_id": 1199,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 1200,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אופקים"
        },
        {
            "_id": 1201,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 178,
            "שם_רחוב": "אור החיים"
        },
        {
            "_id": 1202,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 439,
            "שם_רחוב": "אורים"
        },
        {
            "_id": 1203,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "אזור התעשיה והמלאכה"
        },
        {
            "_id": 1204,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 361,
            "שם_רחוב": "אטד"
        },
        {
            "_id": 1205,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 380,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 1206,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 244,
            "שם_רחוב": "אלברט איינשטיין"
        },
        {
            "_id": 1207,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 197,
            "שם_רחוב": "אלון יגאל"
        },
        {
            "_id": 1208,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 1209,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אליהו התשבי"
        },
        {
            "_id": 1210,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 346,
            "שם_רחוב": "אלישע"
        },
        {
            "_id": 1211,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 503,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 1212,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 320,
            "שם_רחוב": "אסא"
        },
        {
            "_id": 1213,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אסיף"
        },
        {
            "_id": 1214,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 341,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 1215,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אפיקים בנגב"
        },
        {
            "_id": 1216,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 1217,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 362,
            "שם_רחוב": "אקליפטוס"
        },
        {
            "_id": 1218,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 256,
            "שם_רחוב": "ארבעת המינים"
        },
        {
            "_id": 1219,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אריה ורשל"
        },
        {
            "_id": 1220,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 364,
            "שם_רחוב": "אשחר"
        },
        {
            "_id": 1221,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 365,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 1222,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 440,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 1223,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 523,
            "שם_רחוב": "בארית"
        },
        {
            "_id": 1224,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 188,
            "שם_רחוב": "בוזגלו דוד"
        },
        {
            "_id": 1225,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 509,
            "שם_רחוב": "בז"
        },
        {
            "_id": 1226,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 202,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 1227,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 177,
            "שם_רחוב": "בן איש חי"
        },
        {
            "_id": 1228,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 356,
            "שם_רחוב": "בן באר יהושע"
        },
        {
            "_id": 1229,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 342,
            "שם_רחוב": "בן בארי הושע"
        },
        {
            "_id": 1230,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 265,
            "שם_רחוב": "בן שמן"
        },
        {
            "_id": 1231,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 353,
            "שם_רחוב": "בניהו"
        },
        {
            "_id": 1232,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 167,
            "שם_רחוב": "בצלאל"
        },
        {
            "_id": 1233,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 366,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 1234,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 224,
            "שם_רחוב": "ברזילי"
        },
        {
            "_id": 1235,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 335,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 1236,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 367,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 1237,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 208,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 1238,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 368,
            "שם_רחוב": "בשמת"
        },
        {
            "_id": 1239,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "גבורי ישראל"
        },
        {
            "_id": 1240,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 305,
            "שם_רחוב": "גד"
        },
        {
            "_id": 1241,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 330,
            "שם_רחוב": "גדעון"
        },
        {
            "_id": 1242,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 1243,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 369,
            "שם_רחוב": "גומא"
        },
        {
            "_id": 1244,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 372,
            "שם_רחוב": "גיבסנית"
        },
        {
            "_id": 1245,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 436,
            "שם_רחוב": "גלאון"
        },
        {
            "_id": 1246,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 333,
            "שם_רחוב": "גלגל"
        },
        {
            "_id": 1247,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 318,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 1248,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 350,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 1249,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 373,
            "שם_רחוב": "דגן"
        },
        {
            "_id": 1250,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 374,
            "שם_רחוב": "דגנית"
        },
        {
            "_id": 1251,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 128,
            "שם_רחוב": "דדו"
        },
        {
            "_id": 1252,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 375,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 1253,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 327,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 1254,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 506,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 1255,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 376,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 1256,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 245,
            "שם_רחוב": "דן שכטמן"
        },
        {
            "_id": 1257,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 348,
            "שם_רחוב": "דניאל"
        },
        {
            "_id": 1258,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 241,
            "שם_רחוב": "דניאל כהנמן"
        },
        {
            "_id": 1259,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 414,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 1260,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 377,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 1261,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 455,
            "שם_רחוב": "דקל הדום"
        },
        {
            "_id": 1262,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 251,
            "שם_רחוב": "דקלה"
        },
        {
            "_id": 1263,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 510,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 1264,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 141,
            "שם_רחוב": "דרך אילת"
        },
        {
            "_id": 1265,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 160,
            "שם_רחוב": "דרך בית וגן"
        },
        {
            "_id": 1266,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 444,
            "שם_רחוב": "דרך הבשמים"
        },
        {
            "_id": 1267,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 250,
            "שם_רחוב": "דרך הותיקים"
        },
        {
            "_id": 1268,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 211,
            "שם_רחוב": "דרך הטייסים"
        },
        {
            "_id": 1269,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 310,
            "שם_רחוב": "דרך הנביאים"
        },
        {
            "_id": 1270,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 1087,
            "שם_רחוב": "דרך חסידות חבד"
        },
        {
            "_id": 1271,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 371,
            "שם_רחוב": "דרך ישרים"
        },
        {
            "_id": 1272,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 480,
            "שם_רחוב": "דרך משה כחלון"
        },
        {
            "_id": 1273,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 238,
            "שם_רחוב": "דרך סיירת שקד"
        },
        {
            "_id": 1274,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 126,
            "שם_רחוב": "דרך רבי יעקב אוחנה"
        },
        {
            "_id": 1275,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 255,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 1276,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 378,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 1277,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 150,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 1278,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 180,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 1279,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 1280,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 218,
            "שם_רחוב": "הבורסקאי"
        },
        {
            "_id": 1281,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 166,
            "שם_רחוב": "הבנאים"
        },
        {
            "_id": 1282,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 261,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 1283,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 270,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 1284,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 253,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 1285,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 252,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 1286,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 199,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 1287,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 1288,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 1289,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 156,
            "שם_רחוב": "הזורע"
        },
        {
            "_id": 1290,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 263,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 1291,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 258,
            "שם_רחוב": "הזמורה"
        },
        {
            "_id": 1292,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 1293,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 157,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 1294,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 176,
            "שם_רחוב": "החידא"
        },
        {
            "_id": 1295,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 268,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 1296,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 151,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 1297,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 219,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 1298,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 259,
            "שם_רחוב": "היוגב"
        },
        {
            "_id": 1299,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 221,
            "שם_רחוב": "היוזמה"
        },
        {
            "_id": 1300,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 165,
            "שם_רחוב": "היוצר"
        },
        {
            "_id": 1301,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 1302,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הכנסת הגדולה"
        },
        {
            "_id": 1303,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 271,
            "שם_רחוב": "הכרמים"
        },
        {
            "_id": 1304,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 223,
            "שם_רחוב": "הכשרת הישוב"
        },
        {
            "_id": 1305,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 182,
            "שם_רחוב": "הלל"
        },
        {
            "_id": 1306,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 169,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 1307,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 168,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 1308,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 433,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 1309,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 217,
            "שם_רחוב": "הנפח"
        },
        {
            "_id": 1310,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הנשיא"
        },
        {
            "_id": 1311,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 262,
            "שם_רחוב": "העינב"
        },
        {
            "_id": 1312,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 237,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 1313,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 170,
            "שם_רחוב": "הפלדה"
        },
        {
            "_id": 1314,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 1315,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 470,
            "שם_רחוב": "הר ארגמן"
        },
        {
            "_id": 1316,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 446,
            "שם_רחוב": "הר בוקר"
        },
        {
            "_id": 1317,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 443,
            "שם_רחוב": "הר חורשה"
        },
        {
            "_id": 1318,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 450,
            "שם_רחוב": "הר חלמיש"
        },
        {
            "_id": 1319,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 452,
            "שם_רחוב": "הר יעלון"
        },
        {
            "_id": 1320,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 454,
            "שם_רחוב": "הר כרכום"
        },
        {
            "_id": 1321,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 453,
            "שם_רחוב": "הר רתמים"
        },
        {
            "_id": 1322,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 451,
            "שם_רחוב": "הר שגיא"
        },
        {
            "_id": 1323,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 155,
            "שם_רחוב": "הרב אבוחצירה"
        },
        {
            "_id": 1324,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 185,
            "שם_רחוב": "הרב אלנקווה רפאל"
        },
        {
            "_id": 1325,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 363,
            "שם_רחוב": "הרב אלעזר אבוחצירה"
        },
        {
            "_id": 1326,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 1327,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 147,
            "שם_רחוב": "הרב חורי"
        },
        {
            "_id": 1328,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 406,
            "שם_רחוב": "הרב חיים קמיל"
        },
        {
            "_id": 1329,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 422,
            "שם_רחוב": "הרב יעקב הורוביץ"
        },
        {
            "_id": 1330,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 445,
            "שם_רחוב": "הרב מרדכי חורי"
        },
        {
            "_id": 1331,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 426,
            "שם_רחוב": "הרב עובדיה יוסף"
        },
        {
            "_id": 1332,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 1333,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 379,
            "שם_רחוב": "הרב פינקוס"
        },
        {
            "_id": 1334,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 1335,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 449,
            "שם_רחוב": "הרי אילת"
        },
        {
            "_id": 1336,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 267,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 1337,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרמבם"
        },
        {
            "_id": 1338,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 220,
            "שם_רחוב": "הרתך"
        },
        {
            "_id": 1339,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 326,
            "שם_רחוב": "השילוח"
        },
        {
            "_id": 1340,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 152,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 1341,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 266,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 1342,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 349,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 1343,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 260,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 1344,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 269,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 1345,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 1346,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 137,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 1347,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 313,
            "שם_רחוב": "זכריה"
        },
        {
            "_id": 1348,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 511,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 1349,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 315,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 1350,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 520,
            "שם_רחוב": "חוגלה"
        },
        {
            "_id": 1351,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 513,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 1352,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 517,
            "שם_רחוב": "חופית"
        },
        {
            "_id": 1353,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 332,
            "שם_רחוב": "חורב"
        },
        {
            "_id": 1354,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 175,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 1355,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 234,
            "שם_רחוב": "חט אלכסנדרוני"
        },
        {
            "_id": 1356,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 232,
            "שם_רחוב": "חט גבעתי"
        },
        {
            "_id": 1357,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 236,
            "שם_רחוב": "חט גולני"
        },
        {
            "_id": 1358,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 231,
            "שם_רחוב": "חט הנגב"
        },
        {
            "_id": 1359,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 235,
            "שם_רחוב": "חט כרמלי"
        },
        {
            "_id": 1360,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 233,
            "שם_רחוב": "חט עציוני"
        },
        {
            "_id": 1361,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 381,
            "שם_רחוב": "חלבלוב"
        },
        {
            "_id": 1362,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 382,
            "שם_רחוב": "חמנייה"
        },
        {
            "_id": 1363,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 340,
            "שם_רחוב": "חנניה"
        },
        {
            "_id": 1364,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 524,
            "שם_רחוב": "חסידה"
        },
        {
            "_id": 1365,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 183,
            "שם_רחוב": "חפץ חיים"
        },
        {
            "_id": 1366,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 383,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 1367,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 428,
            "שם_רחוב": "חצרים"
        },
        {
            "_id": 1368,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 384,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 1369,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 196,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 1370,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 194,
            "שם_רחוב": "יהדות דרום אפריקה"
        },
        {
            "_id": 1371,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 1372,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 447,
            "שם_רחוב": "יהודה מרציאנו"
        },
        {
            "_id": 1373,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 303,
            "שם_רחוב": "יהונתן"
        },
        {
            "_id": 1374,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 352,
            "שם_רחוב": "יהוצדק"
        },
        {
            "_id": 1375,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 312,
            "שם_רחוב": "יהושע בן נון"
        },
        {
            "_id": 1376,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 321,
            "שם_רחוב": "יהושפט"
        },
        {
            "_id": 1377,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 206,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 1378,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 319,
            "שם_רחוב": "יואב"
        },
        {
            "_id": 1379,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 338,
            "שם_רחוב": "יואל"
        },
        {
            "_id": 1380,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 347,
            "שם_רחוב": "יונה"
        },
        {
            "_id": 1381,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 129,
            "שם_רחוב": "יוני"
        },
        {
            "_id": 1382,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 309,
            "שם_רחוב": "יחזקאל"
        },
        {
            "_id": 1383,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 146,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 1384,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 514,
            "שם_רחוב": "יסעור"
        },
        {
            "_id": 1385,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 351,
            "שם_רחוב": "יעקב הכהן"
        },
        {
            "_id": 1386,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 385,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 1387,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 336,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 1388,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 264,
            "שם_רחוב": "יצהר"
        },
        {
            "_id": 1389,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 143,
            "שם_רחוב": "יצחק רבין"
        },
        {
            "_id": 1390,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 386,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 1391,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 304,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 1392,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 302,
            "שם_רחוב": "ירמיהו"
        },
        {
            "_id": 1393,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 512,
            "שם_רחוב": "ירקון"
        },
        {
            "_id": 1394,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 317,
            "שם_רחוב": "ישי"
        },
        {
            "_id": 1395,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 308,
            "שם_רחוב": "ישעיהו"
        },
        {
            "_id": 1396,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 249,
            "שם_רחוב": "ישראל אומן"
        },
        {
            "_id": 1397,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6018,
            "שם_רחוב": "כ' חיים ורבקה אמיר"
        },
        {
            "_id": 1398,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "כיכר ברטי לובנר"
        },
        {
            "_id": 1399,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6013,
            "שם_רחוב": "כיכר הזריחה"
        },
        {
            "_id": 1400,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6014,
            "שם_רחוב": "כיכר החינוך"
        },
        {
            "_id": 1401,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6017,
            "שם_רחוב": "כיכר היונים"
        },
        {
            "_id": 1402,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6009,
            "שם_רחוב": "כיכר הספורט"
        },
        {
            "_id": 1403,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "כיכר העיר"
        },
        {
            "_id": 1404,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6011,
            "שם_רחוב": "כיכר הפארק"
        },
        {
            "_id": 1405,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6015,
            "שם_רחוב": "כיכר השחפים"
        },
        {
            "_id": 1406,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "כיכר השער לנגב"
        },
        {
            "_id": 1407,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "כיכר חורבת פטיש"
        },
        {
            "_id": 1408,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6016,
            "שם_רחוב": "כיכר חכמי ישראל"
        },
        {
            "_id": 1409,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6012,
            "שם_רחוב": "כיכר יהדות התפוצות"
        },
        {
            "_id": 1410,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "כיכר מטרו-ווסט"
        },
        {
            "_id": 1411,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "כיכר מעגל החיים"
        },
        {
            "_id": 1412,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6010,
            "שם_רחוב": "כיכר ספרא"
        },
        {
            "_id": 1413,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר גבעתי"
        },
        {
            "_id": 1414,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 387,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 1415,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 429,
            "שם_רחוב": "כפר דרום"
        },
        {
            "_id": 1416,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "כצנלסון"
        },
        {
            "_id": 1417,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 388,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 1418,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 331,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 1419,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 389,
            "שם_רחוב": "לוטוס"
        },
        {
            "_id": 1420,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 390,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 1421,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 201,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 1422,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 391,
            "שם_רחוב": "ליבנה"
        },
        {
            "_id": 1423,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 392,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 1424,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 214,
            "שם_רחוב": "מבצע דקל"
        },
        {
            "_id": 1425,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 225,
            "שם_רחוב": "מבצע הראל"
        },
        {
            "_id": 1426,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 227,
            "שם_רחוב": "מבצע חורב"
        },
        {
            "_id": 1427,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 228,
            "שם_רחוב": "מבצע חירם"
        },
        {
            "_id": 1428,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 229,
            "שם_רחוב": "מבצע יואב"
        },
        {
            "_id": 1429,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 226,
            "שם_רחוב": "מבצע יפתח"
        },
        {
            "_id": 1430,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 132,
            "שם_רחוב": "מבצע משה"
        },
        {
            "_id": 1431,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 213,
            "שם_רחוב": "מבצע נחשון"
        },
        {
            "_id": 1432,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 230,
            "שם_רחוב": "מבצע עובדה"
        },
        {
            "_id": 1433,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 508,
            "שם_רחוב": "מגלן"
        },
        {
            "_id": 1434,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 427,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 1435,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 247,
            "שם_רחוב": "מיכאל לויט"
        },
        {
            "_id": 1436,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 442,
            "שם_רחוב": "מכתש רמון"
        },
        {
            "_id": 1437,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 311,
            "שם_רחוב": "מלאכי"
        },
        {
            "_id": 1438,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 240,
            "שם_רחוב": "מנחם בגין"
        },
        {
            "_id": 1439,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 354,
            "שם_רחוב": "מסיקה יעקב"
        },
        {
            "_id": 1440,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 203,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 1441,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 393,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 1442,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 343,
            "שם_רחוב": "מרדכי"
        },
        {
            "_id": 1443,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 394,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 1444,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 430,
            "שם_רחוב": "משמר הנגב"
        },
        {
            "_id": 1445,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "משעול האיריס"
        },
        {
            "_id": 1446,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "משעול הגפן"
        },
        {
            "_id": 1447,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "משעול הנרקיס"
        },
        {
            "_id": 1448,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 138,
            "שם_רחוב": "משעול הרקפת"
        },
        {
            "_id": 1449,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "משעול השקמה"
        },
        {
            "_id": 1450,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 432,
            "שם_רחוב": "נבטים"
        },
        {
            "_id": 1451,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 171,
            "שם_רחוב": "נחושתן"
        },
        {
            "_id": 1452,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 465,
            "שם_רחוב": "נחל אופקים"
        },
        {
            "_id": 1453,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 281,
            "שם_רחוב": "נחל אמציהו"
        },
        {
            "_id": 1454,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 282,
            "שם_רחוב": "נחל ארדון"
        },
        {
            "_id": 1455,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 274,
            "שם_רחוב": "נחל אשלים"
        },
        {
            "_id": 1456,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 283,
            "שם_רחוב": "נחל באר חיל"
        },
        {
            "_id": 1457,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 463,
            "שם_רחוב": "נחל באר שבע"
        },
        {
            "_id": 1458,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 284,
            "שם_רחוב": "נחל גוב"
        },
        {
            "_id": 1459,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 461,
            "שם_רחוב": "נחל גרר"
        },
        {
            "_id": 1460,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 285,
            "שם_רחוב": "נחל גשרון"
        },
        {
            "_id": 1461,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 462,
            "שם_רחוב": "נחל דוד"
        },
        {
            "_id": 1462,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 467,
            "שם_רחוב": "נחל הבשור"
        },
        {
            "_id": 1463,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 295,
            "שם_רחוב": "נחל הרועה"
        },
        {
            "_id": 1464,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 297,
            "שם_רחוב": "נחל זיו"
        },
        {
            "_id": 1465,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 174,
            "שם_רחוב": "נחל זיתן"
        },
        {
            "_id": 1466,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 288,
            "שם_רחוב": "נחל חברון"
        },
        {
            "_id": 1467,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 289,
            "שם_רחוב": "נחל חווה"
        },
        {
            "_id": 1468,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 290,
            "שם_רחוב": "נחל חיון"
        },
        {
            "_id": 1469,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 291,
            "שם_רחוב": "נחל חצרה"
        },
        {
            "_id": 1470,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 280,
            "שם_רחוב": "נחל חתירה"
        },
        {
            "_id": 1471,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 292,
            "שם_רחוב": "נחל ימין"
        },
        {
            "_id": 1472,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 293,
            "שם_רחוב": "נחל יתיר"
        },
        {
            "_id": 1473,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 458,
            "שם_רחוב": "נחל כיסופים"
        },
        {
            "_id": 1474,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 173,
            "שם_רחוב": "נחל כסיף"
        },
        {
            "_id": 1475,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 294,
            "שם_רחוב": "נחל לבן"
        },
        {
            "_id": 1476,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 286,
            "שם_רחוב": "נחל ממשית"
        },
        {
            "_id": 1477,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 296,
            "שם_רחוב": "נחל נוקד"
        },
        {
            "_id": 1478,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 457,
            "שם_רחוב": "נחל נטפים"
        },
        {
            "_id": 1479,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 277,
            "שם_רחוב": "נחל ניצנה"
        },
        {
            "_id": 1480,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 476,
            "שם_רחוב": "נחל נצר"
        },
        {
            "_id": 1481,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 279,
            "שם_רחוב": "נחל נקרות"
        },
        {
            "_id": 1482,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 273,
            "שם_רחוב": "נחל עבדת"
        },
        {
            "_id": 1483,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 456,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 1484,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 298,
            "שם_רחוב": "נחל עתק"
        },
        {
            "_id": 1485,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 478,
            "שם_רחוב": "נחל פארן"
        },
        {
            "_id": 1486,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 299,
            "שם_רחוב": "נחל פורה"
        },
        {
            "_id": 1487,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 464,
            "שם_רחוב": "נחל פטיש"
        },
        {
            "_id": 1488,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 471,
            "שם_רחוב": "נחל פרס"
        },
        {
            "_id": 1489,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 460,
            "שם_רחוב": "נחל צאלים"
        },
        {
            "_id": 1490,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 287,
            "שם_רחוב": "נחל צופר"
        },
        {
            "_id": 1491,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 278,
            "שם_רחוב": "נחל ציחור"
        },
        {
            "_id": 1492,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 459,
            "שם_רחוב": "נחל צין"
        },
        {
            "_id": 1493,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 475,
            "שם_רחוב": "נחל צפית"
        },
        {
            "_id": 1494,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 472,
            "שם_רחוב": "נחל קטורה"
        },
        {
            "_id": 1495,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 473,
            "שם_רחוב": "נחל רודד"
        },
        {
            "_id": 1496,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 276,
            "שם_רחוב": "נחל רות"
        },
        {
            "_id": 1497,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 474,
            "שם_רחוב": "נחל רחם"
        },
        {
            "_id": 1498,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 466,
            "שם_רחוב": "נחל רמון"
        },
        {
            "_id": 1499,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 477,
            "שם_רחוב": "נחל שני"
        },
        {
            "_id": 1500,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 468,
            "שם_רחוב": "נחל שקמה"
        },
        {
            "_id": 1501,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 275,
            "שם_רחוב": "נחל שרביט"
        },
        {
            "_id": 1502,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 272,
            "שם_רחוב": "נחל שרשרת"
        },
        {
            "_id": 1503,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 172,
            "שם_רחוב": "נחל תמנע"
        },
        {
            "_id": 1504,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 479,
            "שם_רחוב": "נחל תמר"
        },
        {
            "_id": 1505,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 518,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 1506,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 344,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 1507,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 438,
            "שם_רחוב": "נירים"
        },
        {
            "_id": 1508,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 395,
            "שם_רחוב": "נירית"
        },
        {
            "_id": 1509,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 306,
            "שם_רחוב": "נתן"
        },
        {
            "_id": 1510,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 396,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 1511,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 397,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 1512,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 469,
            "שם_רחוב": "סיגלון"
        },
        {
            "_id": 1513,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 398,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 1514,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 399,
            "שם_רחוב": "סיתוונית"
        },
        {
            "_id": 1515,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 501,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 1516,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 145,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 1517,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 519,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 1518,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 207,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 1519,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 515,
            "שם_רחוב": "עגור"
        },
        {
            "_id": 1520,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 400,
            "שם_רחוב": "עדעד"
        },
        {
            "_id": 1521,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 324,
            "שם_רחוב": "עוזיהו"
        },
        {
            "_id": 1522,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 401,
            "שם_רחוב": "עוזרד"
        },
        {
            "_id": 1523,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 195,
            "שם_רחוב": "עולי הגרדום"
        },
        {
            "_id": 1524,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 345,
            "שם_רחוב": "עזרא"
        },
        {
            "_id": 1525,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 307,
            "שם_רחוב": "עידו הנביא"
        },
        {
            "_id": 1526,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 505,
            "שם_רחוב": "עיט"
        },
        {
            "_id": 1527,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 323,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 1528,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 402,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 1529,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 339,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 1530,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 448,
            "שם_רחוב": "עמרם ביתן"
        },
        {
            "_id": 1531,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 179,
            "שם_רחוב": "עמרם בן דיואן"
        },
        {
            "_id": 1532,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 329,
            "שם_רחוב": "עמשא"
        },
        {
            "_id": 1533,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 504,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 1534,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 254,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 1535,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 403,
            "שם_רחוב": "פטל"
        },
        {
            "_id": 1536,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 186,
            "שם_רחוב": "פינטו חיים"
        },
        {
            "_id": 1537,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 404,
            "שם_רחוב": "פיקוס"
        },
        {
            "_id": 1538,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 405,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 1539,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 257,
            "שם_רחוב": "פרי מגדים"
        },
        {
            "_id": 1540,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 521,
            "שם_רחוב": "פשוש"
        },
        {
            "_id": 1541,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 148,
            "שם_רחוב": "צאלה"
        },
        {
            "_id": 1542,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 370,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 1543,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 408,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 1544,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 409,
            "שם_רחוב": "צבר"
        },
        {
            "_id": 1545,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 325,
            "שם_רחוב": "צדקיהו"
        },
        {
            "_id": 1546,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 1547,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 507,
            "שם_רחוב": "צוקית"
        },
        {
            "_id": 1548,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 410,
            "שם_רחוב": "ציפור גן עדן"
        },
        {
            "_id": 1549,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 411,
            "שם_רחוב": "ציפורן"
        },
        {
            "_id": 1550,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 412,
            "שם_רחוב": "צפצפה"
        },
        {
            "_id": 1551,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 190,
            "שם_רחוב": "קארו יוסף"
        },
        {
            "_id": 1552,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 1553,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 437,
            "שם_רחוב": "קדמה"
        },
        {
            "_id": 1554,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "קדש"
        },
        {
            "_id": 1555,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 187,
            "שם_רחוב": "קהילות בן יעקב"
        },
        {
            "_id": 1556,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 441,
            "שם_רחוב": "קהילת מטרו ווסט"
        },
        {
            "_id": 1557,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 413,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 1558,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 215,
            "שם_רחוב": "קיקוס חנית"
        },
        {
            "_id": 1559,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 159,
            "שם_רחוב": "קציר"
        },
        {
            "_id": 1560,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 337,
            "שם_רחוב": "קרית ספר"
        },
        {
            "_id": 1561,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 142,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 1562,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 131,
            "שם_רחוב": "רבי דוד דהן"
        },
        {
            "_id": 1563,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 181,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 1564,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 415,
            "שם_רחוב": "רוזמרין"
        },
        {
            "_id": 1565,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 416,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 1566,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 198,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 1567,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 357,
            "שם_רחוב": "רחבת אביר"
        },
        {
            "_id": 1568,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 358,
            "שם_רחוב": "רחבת אדר"
        },
        {
            "_id": 1569,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 417,
            "שם_רחוב": "ריחן"
        },
        {
            "_id": 1570,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "רמת שקד"
        },
        {
            "_id": 1571,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 189,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 1572,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש משטרה"
        },
        {
            "_id": 1573,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 239,
            "שם_רחוב": "שי עגנון"
        },
        {
            "_id": 1574,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 301,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 1575,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 184,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 1576,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 435,
            "שם_רחוב": "שד הציונות"
        },
        {
            "_id": 1577,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שד הרצל"
        },
        {
            "_id": 1578,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 418,
            "שם_רחוב": "שד' השיטה"
        },
        {
            "_id": 1579,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 431,
            "שם_רחוב": "שובל"
        },
        {
            "_id": 1580,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 205,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 1581,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 419,
            "שם_רחוב": "שושן"
        },
        {
            "_id": 1582,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 522,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 1583,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 420,
            "שם_רחוב": "שיבולים"
        },
        {
            "_id": 1584,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 421,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 1585,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 355,
            "שם_רחוב": "שילה"
        },
        {
            "_id": 1586,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ בן גוריון"
        },
        {
            "_id": 1587,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ בנה ביתך"
        },
        {
            "_id": 1588,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "שכ הארזים"
        },
        {
            "_id": 1589,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ הריף"
        },
        {
            "_id": 1590,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "שכ מישור הגפן"
        },
        {
            "_id": 1591,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "שכ שפירא"
        },
        {
            "_id": 1592,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "שכונת דדו"
        },
        {
            "_id": 1593,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכונת יוני"
        },
        {
            "_id": 1594,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 516,
            "שם_רחוב": "שליו"
        },
        {
            "_id": 1595,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 322,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 1596,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 316,
            "שם_רחוב": "שמואל הרואה"
        },
        {
            "_id": 1597,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 248,
            "שם_רחוב": "שמעון פרס"
        },
        {
            "_id": 1598,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 334,
            "שם_רחוב": "שמשון"
        },
        {
            "_id": 1599,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 130,
            "שם_רחוב": "שרת"
        },
        {
            "_id": 1600,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 216,
            "שם_רחוב": "תוצרת הארץ"
        },
        {
            "_id": 1601,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 423,
            "שם_רחוב": "תורמוס"
        },
        {
            "_id": 1602,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 424,
            "שם_רחוב": "תות"
        },
        {
            "_id": 1603,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 425,
            "שם_רחוב": "תלתן"
        },
        {
            "_id": 1604,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 434,
            "שם_רחוב": "תקומה"
        },
        {
            "_id": 1605,
            "סמל_ישוב": 31,
            "שם_ישוב": "אופקים ",
            "סמל_רחוב": 204,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 1606,
            "סמל_ישוב": 1294,
            "שם_ישוב": "אור הגנוז ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אור הגנוז"
        },
        {
            "_id": 1607,
            "סמל_ישוב": 67,
            "שם_ישוב": "אור הנר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אור הנר"
        },
        {
            "_id": 1608,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "אבי ואביב"
        },
        {
            "_id": 1609,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אביב משה"
        },
        {
            "_id": 1610,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אבנר"
        },
        {
            "_id": 1611,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אדם יקותיאל"
        },
        {
            "_id": 1612,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אהובה עוזרי"
        },
        {
            "_id": 1613,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 1614,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אופירה"
        },
        {
            "_id": 1615,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אור יהודה"
        },
        {
            "_id": 1616,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 1617,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "אזור תעשיה ספיר"
        },
        {
            "_id": 1618,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "אזור תעשייה א"
        },
        {
            "_id": 1619,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אזור תעשייה אלמוג"
        },
        {
            "_id": 1620,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "אזור תעשייה ב"
        },
        {
            "_id": 1621,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "אזור תעשייה יהלום"
        },
        {
            "_id": 1622,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אטד"
        },
        {
            "_id": 1623,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 269,
            "שם_רחוב": "איילת השחר"
        },
        {
            "_id": 1624,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "איריס"
        },
        {
            "_id": 1625,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 1626,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלעזר דוד"
        },
        {
            "_id": 1627,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אסירי ציון"
        },
        {
            "_id": 1628,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 1629,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 1630,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 1631,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "ארבע אמהות"
        },
        {
            "_id": 1632,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אשכול לוי"
        },
        {
            "_id": 1633,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 1634,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 3104,
            "שם_רחוב": "בן איש חי"
        },
        {
            "_id": 1635,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "בר יוחאי"
        },
        {
            "_id": 1636,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "בר לב חיים"
        },
        {
            "_id": 1637,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 1638,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 1639,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 1640,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "גולדה מאיר"
        },
        {
            "_id": 1641,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 1642,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 1643,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "דבורה עומר"
        },
        {
            "_id": 1644,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דגניה"
        },
        {
            "_id": 1645,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "דה וינצי"
        },
        {
            "_id": 1646,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 1647,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 1648,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 1649,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "דקלה"
        },
        {
            "_id": 1650,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 1651,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "האוניברסיטה"
        },
        {
            "_id": 1652,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 1653,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 1654,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 1655,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 349,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 1656,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 1657,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 341,
            "שם_רחוב": "הדדי משה"
        },
        {
            "_id": 1658,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 1659,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 1660,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 328,
            "שם_רחוב": "הוז דב"
        },
        {
            "_id": 1661,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 1662,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 1663,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "החלוצים"
        },
        {
            "_id": 1664,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 1665,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "החרושת"
        },
        {
            "_id": 1666,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "היוזמים"
        },
        {
            "_id": 1667,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 338,
            "שם_רחוב": "היוצרים"
        },
        {
            "_id": 1668,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 1669,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "היצירה"
        },
        {
            "_id": 1670,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 343,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 1671,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 1672,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "הלה"
        },
        {
            "_id": 1673,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "הלחי"
        },
        {
            "_id": 1674,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "הלנה כגן"
        },
        {
            "_id": 1675,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 344,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 1676,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 1677,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 339,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 1678,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 1679,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 337,
            "שם_רחוב": "המפעל"
        },
        {
            "_id": 1680,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "הנרייטה סאלד"
        },
        {
            "_id": 1681,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 3102,
            "שם_רחוב": "הנרקיס רמת פנקס"
        },
        {
            "_id": 1682,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 340,
            "שם_רחוב": "הסדנא"
        },
        {
            "_id": 1683,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "הסוכנות היהודית"
        },
        {
            "_id": 1684,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הסייפן"
        },
        {
            "_id": 1685,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 1686,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 1687,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הערמון"
        },
        {
            "_id": 1688,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 336,
            "שם_רחוב": "הפלדה"
        },
        {
            "_id": 1689,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 1690,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 1691,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 325,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 1692,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 346,
            "שם_רחוב": "הרב חי טייב"
        },
        {
            "_id": 1693,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 324,
            "שם_רחוב": "הרב כמוס עגיב"
        },
        {
            "_id": 1694,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 327,
            "שם_רחוב": "הרב פלח כמוס"
        },
        {
            "_id": 1695,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 1696,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 1697,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 1698,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 1699,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 1700,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 332,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 1701,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 3103,
            "שם_רחוב": "השושן"
        },
        {
            "_id": 1702,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 1703,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "השלהבת"
        },
        {
            "_id": 1704,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 1705,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 1706,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 1707,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 1708,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "זית"
        },
        {
            "_id": 1709,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "חטיבת אלכסנדרוני"
        },
        {
            "_id": 1710,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "חייק משה"
        },
        {
            "_id": 1711,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "חנה מרון"
        },
        {
            "_id": 1712,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 1713,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 1714,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 1715,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "יהדות קנדה"
        },
        {
            "_id": 1716,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 1717,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "יוסף חיים"
        },
        {
            "_id": 1718,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "יעל מן"
        },
        {
            "_id": 1719,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "יפה ירקוני"
        },
        {
            "_id": 1720,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "יצחק רבין"
        },
        {
            "_id": 1721,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 1722,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 322,
            "שם_רחוב": "כביש לוד"
        },
        {
            "_id": 1723,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "כדורי יצחק"
        },
        {
            "_id": 1724,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 351,
            "שם_רחוב": "כהן דוד"
        },
        {
            "_id": 1725,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6011,
            "שם_רחוב": "כיכר איינשטיין אריק"
        },
        {
            "_id": 1726,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6013,
            "שם_רחוב": "כיכר גאון יהורם"
        },
        {
            "_id": 1727,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6015,
            "שם_רחוב": "כיכר דמארי שושנה"
        },
        {
            "_id": 1728,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6012,
            "שם_רחוב": "כיכר הירש נורית"
        },
        {
            "_id": 1729,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6010,
            "שם_רחוב": "כיכר הספורטאים"
        },
        {
            "_id": 1730,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6014,
            "שם_רחוב": "כיכר חיטמן עוזי"
        },
        {
            "_id": 1731,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "כיכר חפר חיים"
        },
        {
            "_id": 1732,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "כיכר טהר לב יורם"
        },
        {
            "_id": 1733,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "כיכר שיר סמדר"
        },
        {
            "_id": 1734,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "כיכר שמר נעמי"
        },
        {
            "_id": 1735,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר 11 בספטמבר"
        },
        {
            "_id": 1736,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר המורשת"
        },
        {
            "_id": 1737,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר הנשיא"
        },
        {
            "_id": 1738,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר יא חללי מינכן"
        },
        {
            "_id": 1739,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 1740,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "כנען"
        },
        {
            "_id": 1741,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 345,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 1742,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "כצנלסון"
        },
        {
            "_id": 1743,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 1744,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "לאה גולדברג"
        },
        {
            "_id": 1745,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "ליבנה"
        },
        {
            "_id": 1746,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 3101,
            "שם_רחוב": "לנדאו רמת פנקס"
        },
        {
            "_id": 1747,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 1748,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 318,
            "שם_רחוב": "מונטיפיורי"
        },
        {
            "_id": 1749,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "מועלם יצחק"
        },
        {
            "_id": 1750,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "מיכאל"
        },
        {
            "_id": 1751,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 1752,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "מניה שוחט"
        },
        {
            "_id": 1753,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "מעלה החומה"
        },
        {
            "_id": 1754,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "מעלה המגדל"
        },
        {
            "_id": 1755,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 1756,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "מרים נאור"
        },
        {
            "_id": 1757,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "מרכז העיר"
        },
        {
            "_id": 1758,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "משה גניש"
        },
        {
            "_id": 1759,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "נווה הדרים"
        },
        {
            "_id": 1760,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "נווה סביון"
        },
        {
            "_id": 1761,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "ניצן"
        },
        {
            "_id": 1762,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "נעמי שמר"
        },
        {
            "_id": 1763,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "נתניהו יהונתן"
        },
        {
            "_id": 1764,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 1765,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "סגלון"
        },
        {
            "_id": 1766,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 1767,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 330,
            "שם_רחוב": "סמ בית אל"
        },
        {
            "_id": 1768,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 352,
            "שם_רחוב": "סמ גואטה אפרים"
        },
        {
            "_id": 1769,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 347,
            "שם_רחוב": "סמ הרמבן"
        },
        {
            "_id": 1770,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 348,
            "שם_רחוב": "סמ חכם שמעון"
        },
        {
            "_id": 1771,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 329,
            "שם_רחוב": "סמ מחולה"
        },
        {
            "_id": 1772,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "סמ נילי"
        },
        {
            "_id": 1773,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "סמטת במעלה"
        },
        {
            "_id": 1774,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "סמטת דוכיפת"
        },
        {
            "_id": 1775,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "סמטת דרור"
        },
        {
            "_id": 1776,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 342,
            "שם_רחוב": "סמטת הבשן"
        },
        {
            "_id": 1777,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 335,
            "שם_רחוב": "סמטת הגן"
        },
        {
            "_id": 1778,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 333,
            "שם_רחוב": "סמטת הלל"
        },
        {
            "_id": 1779,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "סמטת השחר"
        },
        {
            "_id": 1780,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 291,
            "שם_רחוב": "סמטת זמיר"
        },
        {
            "_id": 1781,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 331,
            "שם_רחוב": "סמטת חברון"
        },
        {
            "_id": 1782,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "סמטת נחליאלי"
        },
        {
            "_id": 1783,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "סמטת סנונית"
        },
        {
            "_id": 1784,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "סמטת עולי הגרדום"
        },
        {
            "_id": 1785,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "סמטת עפרוני"
        },
        {
            "_id": 1786,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "סמטת פשוש"
        },
        {
            "_id": 1787,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "סמטת קליה"
        },
        {
            "_id": 1788,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "סמטת שחף"
        },
        {
            "_id": 1789,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 334,
            "שם_רחוב": "סמטת שמאי"
        },
        {
            "_id": 1790,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "סעדון אליהו"
        },
        {
            "_id": 1791,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "סעיד משיח"
        },
        {
            "_id": 1792,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 1793,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "סקלאר"
        },
        {
            "_id": 1794,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "עבודי יחזקאל"
        },
        {
            "_id": 1795,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 3106,
            "שם_רחוב": "עגיב כמוס רמת פנקס"
        },
        {
            "_id": 1796,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "עופרה חזה"
        },
        {
            "_id": 1797,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 321,
            "שם_רחוב": "עזרא הסופר"
        },
        {
            "_id": 1798,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 1799,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "עין יהב"
        },
        {
            "_id": 1800,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 1801,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 1802,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 326,
            "שם_רחוב": "פנקס צבי"
        },
        {
            "_id": 1803,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "פרייב ישראל"
        },
        {
            "_id": 1804,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 1805,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "צפריר"
        },
        {
            "_id": 1806,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 317,
            "שם_רחוב": "קארו יוסף"
        },
        {
            "_id": 1807,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "קזז יחזקאל"
        },
        {
            "_id": 1808,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "קרית גיורא"
        },
        {
            "_id": 1809,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "רונית אלקבץ"
        },
        {
            "_id": 1810,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 323,
            "שם_רחוב": "רח ו"
        },
        {
            "_id": 1811,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "רח יח"
        },
        {
            "_id": 1812,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "רחל המשוררת"
        },
        {
            "_id": 1813,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 3105,
            "שם_רחוב": "רמא רמת פנקס"
        },
        {
            "_id": 1814,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "רמז דוד"
        },
        {
            "_id": 1815,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6600,
            "שם_רחוב": "רמת פנקס"
        },
        {
            "_id": 1816,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש אזבסטונים"
        },
        {
            "_id": 1817,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש המרכז"
        },
        {
            "_id": 1818,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש ממשלתי"
        },
        {
            "_id": 1819,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 1820,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש עממי"
        },
        {
            "_id": 1821,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "שד אלון יגאל"
        },
        {
            "_id": 1822,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "שד בגין מנחם"
        },
        {
            "_id": 1823,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 1824,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "שד בן פורת מרדכי"
        },
        {
            "_id": 1825,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "שד הבנים"
        },
        {
            "_id": 1826,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שד' יצחק שמיר"
        },
        {
            "_id": 1827,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "שדרות שמעון פרס"
        },
        {
            "_id": 1828,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 1829,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "שושנה דמארי"
        },
        {
            "_id": 1830,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "שטרן יאיר"
        },
        {
            "_id": 1831,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 1832,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "שירת הזמיר"
        },
        {
            "_id": 1833,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "שכ ההסתדרות"
        },
        {
            "_id": 1834,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ הפרדס"
        },
        {
            "_id": 1835,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ כפר ענה א"
        },
        {
            "_id": 1836,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ כפר ענה ב"
        },
        {
            "_id": 1837,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ נווה רבין"
        },
        {
            "_id": 1838,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ סקיה א"
        },
        {
            "_id": 1839,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ סקיה ב"
        },
        {
            "_id": 1840,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 319,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 1841,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 315,
            "שם_רחוב": "שלמה"
        },
        {
            "_id": 1842,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "שפרינצק"
        },
        {
            "_id": 1843,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 1844,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "שרה אהרונסון"
        },
        {
            "_id": 1845,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "שרון אריאל"
        },
        {
            "_id": 1846,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 1847,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 1848,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 320,
            "שם_רחוב": "תפארת ישראל"
        },
        {
            "_id": 1849,
            "סמל_ישוב": 2400,
            "שם_ישוב": "אור יהודה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 1850,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 1851,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אור עקיבא"
        },
        {
            "_id": 1852,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "אזור התעשייה"
        },
        {
            "_id": 1853,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "אזור תעשייה צפון"
        },
        {
            "_id": 1854,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אטלס יהודה"
        },
        {
            "_id": 1855,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 140,
            "שם_רחוב": "איחוד העם"
        },
        {
            "_id": 1856,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אילות השדה"
        },
        {
            "_id": 1857,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 138,
            "שם_רחוב": "איסר הראל"
        },
        {
            "_id": 1858,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלעזר דוד"
        },
        {
            "_id": 1859,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 1860,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 106,
            "שם_רחוב": "בלפור"
        },
        {
            "_id": 1861,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 104,
            "שם_רחוב": "בן יהודה"
        },
        {
            "_id": 1862,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 179,
            "שם_רחוב": "גלפנשטיין מיכאל"
        },
        {
            "_id": 1863,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "גני אור"
        },
        {
            "_id": 1864,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 141,
            "שם_רחוב": "דרדשתי"
        },
        {
            "_id": 1865,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 181,
            "שם_רחוב": "האילן"
        },
        {
            "_id": 1866,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 150,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 1867,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 171,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 1868,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 160,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 1869,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 156,
            "שם_רחוב": "האצל"
        },
        {
            "_id": 1870,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 210,
            "שם_רחוב": "הארבל"
        },
        {
            "_id": 1871,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 1872,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 167,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 1873,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 200,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 1874,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הגיבורים"
        },
        {
            "_id": 1875,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 1876,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 163,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 1877,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 188,
            "שם_רחוב": "הדודאים"
        },
        {
            "_id": 1878,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 174,
            "שם_רחוב": "הדפנה"
        },
        {
            "_id": 1879,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 157,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 1880,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 186,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 1881,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 151,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 1882,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 224,
            "שם_רחוב": "הזז חיים"
        },
        {
            "_id": 1883,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 182,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 1884,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 176,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 1885,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 187,
            "שם_רחוב": "החוחים"
        },
        {
            "_id": 1886,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 185,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 1887,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 214,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 1888,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 152,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 1889,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 169,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 1890,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 202,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 1891,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 159,
            "שם_רחוב": "הלחי"
        },
        {
            "_id": 1892,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 1893,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הלל"
        },
        {
            "_id": 1894,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 170,
            "שם_רחוב": "המרגלית"
        },
        {
            "_id": 1895,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 1896,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 166,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 1897,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 164,
            "שם_רחוב": "הסביונים"
        },
        {
            "_id": 1898,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 172,
            "שם_רחוב": "הסיגלית"
        },
        {
            "_id": 1899,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 116,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 1900,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 115,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 1901,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 184,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 1902,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 158,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 1903,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 212,
            "שם_רחוב": "הקסטל"
        },
        {
            "_id": 1904,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 203,
            "שם_רחוב": "הר המוריה"
        },
        {
            "_id": 1905,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 209,
            "שם_רחוב": "הר הצופים"
        },
        {
            "_id": 1906,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 211,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 1907,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 205,
            "שם_רחוב": "הר נבו"
        },
        {
            "_id": 1908,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 208,
            "שם_רחוב": "הר סיני"
        },
        {
            "_id": 1909,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 227,
            "שם_רחוב": "הר שלמה"
        },
        {
            "_id": 1910,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 1911,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הרב חי טייב"
        },
        {
            "_id": 1912,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הרב כהנא"
        },
        {
            "_id": 1913,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 136,
            "שם_רחוב": "הרב מלול יוסף"
        },
        {
            "_id": 1914,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 1915,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 177,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 1916,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 204,
            "שם_רחוב": "הרי אפרים"
        },
        {
            "_id": 1917,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 206,
            "שם_רחוב": "הרי יהודה"
        },
        {
            "_id": 1918,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 207,
            "שם_רחוב": "הרי מנשה"
        },
        {
            "_id": 1919,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 165,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 1920,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 1921,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 194,
            "שם_רחוב": "השולמית"
        },
        {
            "_id": 1922,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 168,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 1923,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 173,
            "שם_רחוב": "השיקמים"
        },
        {
            "_id": 1924,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 175,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 1925,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 161,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 1926,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 213,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 1927,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 183,
            "שם_רחוב": "התדהר"
        },
        {
            "_id": 1928,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 162,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 1929,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 128,
            "שם_רחוב": "ויסנברג יחיאל"
        },
        {
            "_id": 1930,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 1931,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 118,
            "שם_רחוב": "זך יהודה"
        },
        {
            "_id": 1932,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 193,
            "שם_רחוב": "חבצלת השרון"
        },
        {
            "_id": 1933,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 195,
            "שם_רחוב": "חגוי הסלע"
        },
        {
            "_id": 1934,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 137,
            "שם_רחוב": "חכם מנחם"
        },
        {
            "_id": 1935,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 126,
            "שם_רחוב": "חללי דקר"
        },
        {
            "_id": 1936,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 217,
            "שם_רחוב": "י.ל.פרץ"
        },
        {
            "_id": 1937,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 129,
            "שם_רחוב": "יאנסן עליזה"
        },
        {
            "_id": 1938,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 226,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 1939,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 219,
            "שם_רחוב": "ינוש קורצ'ק"
        },
        {
            "_id": 1940,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 122,
            "שם_רחוב": "כהן אלי"
        },
        {
            "_id": 1941,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 149,
            "שם_רחוב": "כרם"
        },
        {
            "_id": 1942,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 147,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 1943,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 134,
            "שם_רחוב": "מאיר דגן"
        },
        {
            "_id": 1944,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 133,
            "שם_רחוב": "מאיר עמית"
        },
        {
            "_id": 1945,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 225,
            "שם_רחוב": "מגד אהרון"
        },
        {
            "_id": 1946,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 222,
            "שם_רחוב": "מוסינזון יגאל"
        },
        {
            "_id": 1947,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 145,
            "שם_רחוב": "מור"
        },
        {
            "_id": 1948,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "מעברה"
        },
        {
            "_id": 1949,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 144,
            "שם_רחוב": "מרכבות"
        },
        {
            "_id": 1950,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 132,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 1951,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 155,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 1952,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 127,
            "שם_רחוב": "סטנלי מאיר"
        },
        {
            "_id": 1953,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 120,
            "שם_רחוב": "סמ חואטו סייג"
        },
        {
            "_id": 1954,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 189,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 1955,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 216,
            "שם_רחוב": "ספינת האגוז"
        },
        {
            "_id": 1956,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 199,
            "שם_רחוב": "עמותת תקע יתד בארץ"
        },
        {
            "_id": 1957,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 111,
            "שם_רחוב": "עשרת הרוגי מלכות"
        },
        {
            "_id": 1958,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 190,
            "שם_רחוב": "פארק תעשיה צפון"
        },
        {
            "_id": 1959,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 192,
            "שם_רחוב": "פרדס רימונים"
        },
        {
            "_id": 1960,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 191,
            "שם_רחוב": "פרי מגדים"
        },
        {
            "_id": 1961,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 131,
            "שם_רחוב": "ציוני מנחם"
        },
        {
            "_id": 1962,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 124,
            "שם_רחוב": "קופלוביץ"
        },
        {
            "_id": 1963,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 139,
            "שם_רחוב": "רבי נחמן מברסלב"
        },
        {
            "_id": 1964,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רוטשילד"
        },
        {
            "_id": 1965,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 1966,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 1967,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש בן גוריון"
        },
        {
            "_id": 1968,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש קנדי"
        },
        {
            "_id": 1969,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 221,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 1970,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 154,
            "שם_רחוב": "שד המלך דוד"
        },
        {
            "_id": 1971,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שד הנשיא וייצמן"
        },
        {
            "_id": 1972,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 215,
            "שם_רחוב": "שד ירושלים"
        },
        {
            "_id": 1973,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 180,
            "שם_רחוב": "שד שידלובסקי"
        },
        {
            "_id": 1974,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 146,
            "שם_רחוב": "שושנת העמקים"
        },
        {
            "_id": 1975,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שיכון דו משפחתי"
        },
        {
            "_id": 1976,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ אורות"
        },
        {
            "_id": 1977,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ בנה ביתך"
        },
        {
            "_id": 1978,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ גני רבין"
        },
        {
            "_id": 1979,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ היובל"
        },
        {
            "_id": 1980,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ נוה אלון"
        },
        {
            "_id": 1981,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "שכ קרוונים"
        },
        {
            "_id": 1982,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכונת שזר"
        },
        {
            "_id": 1983,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 218,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 1984,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 148,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 1985,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 123,
            "שם_רחוב": "שפירא אברהם"
        },
        {
            "_id": 1986,
            "סמל_ישוב": 1020,
            "שם_ישוב": "אור עקיבא ",
            "סמל_רחוב": 142,
            "שם_רחוב": "תלפיות"
        },
        {
            "_id": 1987,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אורה"
        },
        {
            "_id": 1988,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 1989,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 1990,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 1991,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 1992,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 1993,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 1994,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "זית"
        },
        {
            "_id": 1995,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 1996,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 1997,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 1998,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 1999,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 2000,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 2001,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 2002,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 2003,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 2004,
            "סמל_ישוב": 780,
            "שם_ישוב": "אורה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "תלתן"
        },
        {
            "_id": 2005,
            "סמל_ישוב": 2012,
            "שם_ישוב": "אורות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אורות"
        },
        {
            "_id": 2006,
            "סמל_ישוב": 2012,
            "שם_ישוב": "אורות ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האילן"
        },
        {
            "_id": 2007,
            "סמל_ישוב": 2012,
            "שם_ישוב": "אורות ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 2008,
            "סמל_ישוב": 2012,
            "שם_ישוב": "אורות ",
            "סמל_רחוב": 102,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 2009,
            "סמל_ישוב": 2012,
            "שם_ישוב": "אורות ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 2010,
            "סמל_ישוב": 2012,
            "שם_ישוב": "אורות ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שכ קרוונים"
        },
        {
            "_id": 2011,
            "סמל_ישוב": 4013,
            "שם_ישוב": "אורטל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אורטל"
        },
        {
            "_id": 2012,
            "סמל_ישוב": 403,
            "שם_ישוב": "אורים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אורים"
        },
        {
            "_id": 2013,
            "סמל_ישוב": 403,
            "שם_ישוב": "אורים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 2014,
            "סמל_ישוב": 882,
            "שם_ישוב": "אורנים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אורנים"
        },
        {
            "_id": 2015,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אורנית"
        },
        {
            "_id": 2016,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 173,
            "שם_רחוב": "איילון"
        },
        {
            "_id": 2017,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 2018,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 199,
            "שם_רחוב": "אלכסנדר"
        },
        {
            "_id": 2019,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 2020,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 185,
            "שם_רחוב": "בוקק"
        },
        {
            "_id": 2021,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 164,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 2022,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 186,
            "שם_רחוב": "דן"
        },
        {
            "_id": 2023,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 172,
            "שם_רחוב": "דרגות"
        },
        {
            "_id": 2024,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 149,
            "שם_רחוב": "דרך אודם"
        },
        {
            "_id": 2025,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 145,
            "שם_רחוב": "דרך החורש"
        },
        {
            "_id": 2026,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 155,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 2027,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 108,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 2028,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 137,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 2029,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 123,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 2030,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 190,
            "שם_רחוב": "הארבל"
        },
        {
            "_id": 2031,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 158,
            "שם_רחוב": "הארגמן"
        },
        {
            "_id": 2032,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 148,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 2033,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 143,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 2034,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 152,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 2035,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 2036,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 175,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 2037,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 198,
            "שם_רחוב": "הגעתון"
        },
        {
            "_id": 2038,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 139,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 2039,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 159,
            "שם_רחוב": "הדגנית"
        },
        {
            "_id": 2040,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 151,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 2041,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 141,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 2042,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 2043,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ההרדוף"
        },
        {
            "_id": 2044,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 2045,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 2046,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 120,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 2047,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 103,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 2048,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 117,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 2049,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 181,
            "שם_רחוב": "החצבני"
        },
        {
            "_id": 2050,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 138,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 2051,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 177,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 2052,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 119,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 2053,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הטבק"
        },
        {
            "_id": 2054,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 129,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 2055,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 126,
            "שם_רחוב": "היקינטון"
        },
        {
            "_id": 2056,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 189,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 2057,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 183,
            "שם_רחוב": "הירקון"
        },
        {
            "_id": 2058,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 2059,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הכרכום"
        },
        {
            "_id": 2060,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 176,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 2061,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הלוטוס"
        },
        {
            "_id": 2062,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הלוטם"
        },
        {
            "_id": 2063,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 161,
            "שם_רחוב": "הליבנה"
        },
        {
            "_id": 2064,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 140,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 2065,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 162,
            "שם_רחוב": "המורן"
        },
        {
            "_id": 2066,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 160,
            "שם_רחוב": "המרגנית"
        },
        {
            "_id": 2067,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 2068,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 2069,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 131,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 2070,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הסחלב"
        },
        {
            "_id": 2071,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הסיגלית"
        },
        {
            "_id": 2072,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 136,
            "שם_רחוב": "הסייפן"
        },
        {
            "_id": 2073,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 135,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 2074,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 204,
            "שם_רחוב": "הפטל"
        },
        {
            "_id": 2075,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 154,
            "שם_רחוב": "הפיקוס"
        },
        {
            "_id": 2076,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הפעמונית"
        },
        {
            "_id": 2077,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 207,
            "שם_רחוב": "הפקאן"
        },
        {
            "_id": 2078,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הפרג"
        },
        {
            "_id": 2079,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 142,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 2080,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 2081,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 2082,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 182,
            "שם_רחוב": "הקישון"
        },
        {
            "_id": 2083,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 179,
            "שם_רחוב": "הר הצופים"
        },
        {
            "_id": 2084,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 178,
            "שם_רחוב": "הר כנען"
        },
        {
            "_id": 2085,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 144,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 2086,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 2087,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 2088,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 147,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 2089,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 2090,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 104,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 2091,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 132,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 2092,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 170,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 2093,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 156,
            "שם_רחוב": "התדהר"
        },
        {
            "_id": 2094,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 157,
            "שם_רחוב": "התלתן"
        },
        {
            "_id": 2095,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 201,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 2096,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 188,
            "שם_רחוב": "זיו"
        },
        {
            "_id": 2097,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 109,
            "שם_רחוב": "לוע הארי"
        },
        {
            "_id": 2098,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 150,
            "שם_רחוב": "מבוא הזית"
        },
        {
            "_id": 2099,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 191,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 2100,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 174,
            "שם_רחוב": "נעמן"
        },
        {
            "_id": 2101,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 206,
            "שם_רחוב": "סמטת האגס"
        },
        {
            "_id": 2102,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 205,
            "שם_רחוב": "סמטת האפרסק"
        },
        {
            "_id": 2103,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 200,
            "שם_רחוב": "סמטת הארבל"
        },
        {
            "_id": 2104,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 208,
            "שם_רחוב": "סמטת המנגו"
        },
        {
            "_id": 2105,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 203,
            "שם_רחוב": "סמטת התפוז"
        },
        {
            "_id": 2106,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 202,
            "שם_רחוב": "סמטת התפוח"
        },
        {
            "_id": 2107,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 192,
            "שם_רחוב": "עצמון"
        },
        {
            "_id": 2108,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 184,
            "שם_רחוב": "ערוגות"
        },
        {
            "_id": 2109,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 197,
            "שם_רחוב": "פארן"
        },
        {
            "_id": 2110,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 180,
            "שם_רחוב": "פולג"
        },
        {
            "_id": 2111,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 187,
            "שם_רחוב": "פרצים"
        },
        {
            "_id": 2112,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 193,
            "שם_רחוב": "צין"
        },
        {
            "_id": 2113,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 171,
            "שם_רחוב": "רביבים"
        },
        {
            "_id": 2114,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 194,
            "שם_רחוב": "שורק"
        },
        {
            "_id": 2115,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ בית וגן"
        },
        {
            "_id": 2116,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ צמרות"
        },
        {
            "_id": 2117,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 195,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 2118,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 163,
            "שם_רחוב": "תות"
        },
        {
            "_id": 2119,
            "סמל_ישוב": 3760,
            "שם_ישוב": "אורנית ",
            "סמל_רחוב": 196,
            "שם_רחוב": "תנינים"
        },
        {
            "_id": 2120,
            "סמל_ישוב": 278,
            "שם_ישוב": "אושה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אושה"
        },
        {
            "_id": 2121,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אזור"
        },
        {
            "_id": 2122,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "אזור התעשייה"
        },
        {
            "_id": 2123,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אחד במאי"
        },
        {
            "_id": 2124,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 2125,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 2126,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אנילביץ"
        },
        {
            "_id": 2127,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 121,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 2128,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 119,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 2129,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 129,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 2130,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 131,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 2131,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 138,
            "שם_רחוב": "בן צבי יצחק"
        },
        {
            "_id": 2132,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 126,
            "שם_רחוב": "בנבנישתי אברהם"
        },
        {
            "_id": 2133,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 151,
            "שם_רחוב": "ברזילי"
        },
        {
            "_id": 2134,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "גני אזור"
        },
        {
            "_id": 2135,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 153,
            "שם_רחוב": "דרך אריאל שרון"
        },
        {
            "_id": 2136,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 134,
            "שם_רחוב": "דרך השבעה"
        },
        {
            "_id": 2137,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 175,
            "שם_רחוב": "האיכרים"
        },
        {
            "_id": 2138,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 161,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 2139,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 2140,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 167,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 2141,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 125,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 2142,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 124,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 2143,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 166,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 2144,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 132,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 2145,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 141,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 2146,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 162,
            "שם_רחוב": "המצודה"
        },
        {
            "_id": 2147,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 127,
            "שם_רחוב": "העליה השניה"
        },
        {
            "_id": 2148,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 2149,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 165,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 2150,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 164,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 2151,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 2152,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 148,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 2153,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הרצוג"
        },
        {
            "_id": 2154,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 2155,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 144,
            "שם_רחוב": "השבעה"
        },
        {
            "_id": 2156,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 114,
            "שם_רחוב": "השלום"
        },
        {
            "_id": 2157,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 163,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 2158,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 2159,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 139,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 2160,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 147,
            "שם_רחוב": "ז'ז'ק דוד"
        },
        {
            "_id": 2161,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 109,
            "שם_רחוב": "חסין חיים"
        },
        {
            "_id": 2162,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 154,
            "שם_רחוב": "יוסף מגנזי"
        },
        {
            "_id": 2163,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 118,
            "שם_רחוב": "יצחק שדה"
        },
        {
            "_id": 2164,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 168,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 2165,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 110,
            "שם_רחוב": "כצנלסון"
        },
        {
            "_id": 2166,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 116,
            "שם_רחוב": "ליכט"
        },
        {
            "_id": 2167,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 136,
            "שם_רחוב": "מדורגי המצודה"
        },
        {
            "_id": 2168,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 117,
            "שם_רחוב": "מכבים"
        },
        {
            "_id": 2169,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 158,
            "שם_רחוב": "משעול יעקב"
        },
        {
            "_id": 2170,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 108,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 2171,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 102,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 2172,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 143,
            "שם_רחוב": "סמטה אלמונית"
        },
        {
            "_id": 2173,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 122,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 2174,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 140,
            "שם_רחוב": "ספיר פנחס"
        },
        {
            "_id": 2175,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 152,
            "שם_רחוב": "עורקבי צדוק"
        },
        {
            "_id": 2176,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 133,
            "שם_רחוב": "פינסקר"
        },
        {
            "_id": 2177,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 130,
            "שם_רחוב": "פרץ"
        },
        {
            "_id": 2178,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 112,
            "שם_רחוב": "קפלן"
        },
        {
            "_id": 2179,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 128,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 2180,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רמז"
        },
        {
            "_id": 2181,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "ש גג"
        },
        {
            "_id": 2182,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש החקלאים"
        },
        {
            "_id": 2183,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש חדש"
        },
        {
            "_id": 2184,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש עולים"
        },
        {
            "_id": 2185,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש צריפונים"
        },
        {
            "_id": 2186,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש רסקו"
        },
        {
            "_id": 2187,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 146,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 2188,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 137,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 2189,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שוחט מניה"
        },
        {
            "_id": 2190,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ בן גוריון"
        },
        {
            "_id": 2191,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ גן ורבורג"
        },
        {
            "_id": 2192,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ יצחק שדה"
        },
        {
            "_id": 2193,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ שבענה"
        },
        {
            "_id": 2194,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שפינוזה"
        },
        {
            "_id": 2195,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 142,
            "שם_רחוב": "שפירא"
        },
        {
            "_id": 2196,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 157,
            "שם_רחוב": "שפרינצק"
        },
        {
            "_id": 2197,
            "סמל_ישוב": 565,
            "שם_ישוב": "אזור ",
            "סמל_רחוב": 135,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 2198,
            "סמל_ישוב": 1157,
            "שם_ישוב": "אחווה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אחווה"
        },
        {
            "_id": 2199,
            "סמל_ישוב": 1157,
            "שם_ישוב": "אחווה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 2200,
            "סמל_ישוב": 1157,
            "שם_ישוב": "אחווה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "זית"
        },
        {
            "_id": 2201,
            "סמל_ישוב": 1157,
            "שם_ישוב": "אחווה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "חיטה"
        },
        {
            "_id": 2202,
            "סמל_ישוב": 1157,
            "שם_ישוב": "אחווה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "נאות אחווה"
        },
        {
            "_id": 2203,
            "סמל_ישוב": 1157,
            "שם_ישוב": "אחווה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 2204,
            "סמל_ישוב": 1157,
            "שם_ישוב": "אחווה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שעורה"
        },
        {
            "_id": 2205,
            "סמל_ישוב": 1157,
            "שם_ישוב": "אחווה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 2206,
            "סמל_ישוב": 821,
            "שם_ישוב": "אחוזם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אחוזם"
        },
        {
            "_id": 2207,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אחוזת ברק"
        },
        {
            "_id": 2208,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אירוסים"
        },
        {
            "_id": 2209,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 2210,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 2211,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 118,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 2212,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 117,
            "שם_רחוב": "דבורנית"
        },
        {
            "_id": 2213,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 2214,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 2215,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 126,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 2216,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 105,
            "שם_רחוב": "זית"
        },
        {
            "_id": 2217,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 112,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 2218,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 122,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 2219,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 109,
            "שם_רחוב": "כלניות"
        },
        {
            "_id": 2220,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 111,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 2221,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 123,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 2222,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 116,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 2223,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 108,
            "שם_רחוב": "נוריות"
        },
        {
            "_id": 2224,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נרקיסים"
        },
        {
            "_id": 2225,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 121,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 2226,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 124,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 2227,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 113,
            "שם_רחוב": "סיתוונית"
        },
        {
            "_id": 2228,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 2229,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רימונים"
        },
        {
            "_id": 2230,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רקפות"
        },
        {
            "_id": 2231,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שושנים"
        },
        {
            "_id": 2232,
            "סמל_ישוב": 1330,
            "שם_ישוב": "אחוזת ברק ",
            "סמל_רחוב": 114,
            "שם_רחוב": "תורמוס"
        },
        {
            "_id": 2233,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אחיהוד"
        },
        {
            "_id": 2234,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 2235,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 2236,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 2237,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 110,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 2238,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 2239,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 2240,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 2241,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 108,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 2242,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 2243,
            "סמל_ישוב": 785,
            "שם_ישוב": "אחיהוד ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שביל החלב"
        },
        {
            "_id": 2244,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אחיטוב"
        },
        {
            "_id": 2245,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 2246,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דקלים"
        },
        {
            "_id": 2247,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 2248,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 108,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 2249,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 2250,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 2251,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 2252,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הלוטם"
        },
        {
            "_id": 2253,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "הרחבה"
        },
        {
            "_id": 2254,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 2255,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר הבנים"
        },
        {
            "_id": 2256,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר המייסדים"
        },
        {
            "_id": 2257,
            "סמל_ישוב": 850,
            "שם_ישוב": "אחיטוב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 2258,
            "סמל_ישוב": 804,
            "שם_ישוב": "אחיסמך ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אחיסמך"
        },
        {
            "_id": 2259,
            "סמל_ישוב": 804,
            "שם_ישוב": "אחיסמך ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 2260,
            "סמל_ישוב": 804,
            "שם_ישוב": "אחיסמך ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 2261,
            "סמל_ישוב": 804,
            "שם_ישוב": "אחיסמך ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 2262,
            "סמל_ישוב": 804,
            "שם_ישוב": "אחיסמך ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 2263,
            "סמל_ישוב": 804,
            "שם_ישוב": "אחיסמך ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שבעת המינים"
        },
        {
            "_id": 2264,
            "סמל_ישוב": 797,
            "שם_ישוב": "אחיעזר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אחיעזר"
        },
        {
            "_id": 2265,
            "סמל_ישוב": 797,
            "שם_ישוב": "אחיעזר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 2266,
            "סמל_ישוב": 797,
            "שם_ישוב": "אחיעזר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 2267,
            "סמל_ישוב": 797,
            "שם_ישוב": "אחיעזר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 2268,
            "סמל_ישוב": 797,
            "שם_ישוב": "אחיעזר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 2269,
            "סמל_ישוב": 797,
            "שם_ישוב": "אחיעזר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 2270,
            "סמל_ישוב": 797,
            "שם_ישוב": "אחיעזר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 2271,
            "סמל_ישוב": 797,
            "שם_ישוב": "אחיעזר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 2272,
            "סמל_ישוב": 797,
            "שם_ישוב": "אחיעזר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 2273,
            "סמל_ישוב": 965,
            "שם_ישוב": "אטרש (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אטרש (שבט)"
        },
        {
            "_id": 2274,
            "סמל_ישוב": 338,
            "שם_ישוב": "איבים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "איבים"
        },
        {
            "_id": 2275,
            "סמל_ישוב": 716,
            "שם_ישוב": "אייל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אייל"
        },
        {
            "_id": 2276,
            "סמל_ישוב": 77,
            "שם_ישוב": "איילת השחר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "איילת השחר"
        },
        {
            "_id": 2277,
            "סמל_ישוב": 77,
            "שם_ישוב": "איילת השחר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "איילת השחר (מ ק)"
        },
        {
            "_id": 2278,
            "סמל_ישוב": 294,
            "שם_ישוב": "אילון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אילון"
        },
        {
            "_id": 2279,
            "סמל_ישוב": 1126,
            "שם_ישוב": "אילות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אילות"
        },
        {
            "_id": 2280,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אילניה"
        },
        {
            "_id": 2281,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אל המעיין"
        },
        {
            "_id": 2282,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דרך הברוש"
        },
        {
            "_id": 2283,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האלונים"
        },
        {
            "_id": 2284,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 2285,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 2286,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 2287,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "החאן"
        },
        {
            "_id": 2288,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "המושבה"
        },
        {
            "_id": 2289,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 2290,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "חות השומר"
        },
        {
            "_id": 2291,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "מגדל המים"
        },
        {
            "_id": 2292,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מחל"
        },
        {
            "_id": 2293,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מעלה גולני"
        },
        {
            "_id": 2294,
            "סמל_ישוב": 49,
            "שם_ישוב": "אילניה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נוף תבור"
        },
        {
            "_id": 2295,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 801,
            "שם_רחוב": "אביב"
        },
        {
            "_id": 2296,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 436,
            "שם_רחוב": "אבני החושן"
        },
        {
            "_id": 2297,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 687,
            "שם_רחוב": "אברהם אבינו"
        },
        {
            "_id": 2298,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אגמונים"
        },
        {
            "_id": 2299,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אדום"
        },
        {
            "_id": 2300,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 499,
            "שם_רחוב": "אדמון"
        },
        {
            "_id": 2301,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 416,
            "שם_רחוב": "אדמונית"
        },
        {
            "_id": 2302,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אדר"
        },
        {
            "_id": 2303,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 417,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 2304,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 391,
            "שם_רחוב": "אוכם"
        },
        {
            "_id": 2305,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 267,
            "שם_רחוב": "אופירה"
        },
        {
            "_id": 2306,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 454,
            "שם_רחוב": "אוריון"
        },
        {
            "_id": 2307,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 418,
            "שם_רחוב": "אזוב"
        },
        {
            "_id": 2308,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "אזור המלונות"
        },
        {
            "_id": 2309,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "אזור התעשיה"
        },
        {
            "_id": 2310,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 803,
            "שם_רחוב": "אחי אילת"
        },
        {
            "_id": 2311,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 455,
            "שם_רחוב": "אטד"
        },
        {
            "_id": 2312,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 510,
            "שם_רחוב": "איזמרגד"
        },
        {
            "_id": 2313,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 796,
            "שם_רחוב": "אייר"
        },
        {
            "_id": 2314,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אילות"
        },
        {
            "_id": 2315,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 2316,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אכסניית הנסיך הקטן"
        },
        {
            "_id": 2317,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אכסנית אביב"
        },
        {
            "_id": 2318,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 546,
            "שם_רחוב": "אכסנית אל ארצי"
        },
        {
            "_id": 2319,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אכסנית בית הערבה"
        },
        {
            "_id": 2320,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 548,
            "שם_רחוב": "אכסנית הבית הלבן"
        },
        {
            "_id": 2321,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 549,
            "שם_רחוב": "אכסנית המקלט"
        },
        {
            "_id": 2322,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אכסנית הנוער"
        },
        {
            "_id": 2323,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אכסנית הרי אדום"
        },
        {
            "_id": 2324,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אכסנית טאבה"
        },
        {
            "_id": 2325,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אכסנית לב העיר"
        },
        {
            "_id": 2326,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 784,
            "שם_רחוב": "אלוורה"
        },
        {
            "_id": 2327,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 800,
            "שם_רחוב": "אלול"
        },
        {
            "_id": 2328,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 529,
            "שם_רחוב": "אנטיב"
        },
        {
            "_id": 2329,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 447,
            "שם_רחוב": "אננס"
        },
        {
            "_id": 2330,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 2331,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 682,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 2332,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 456,
            "שם_רחוב": "אפון"
        },
        {
            "_id": 2333,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 238,
            "שם_רחוב": "אפעה"
        },
        {
            "_id": 2334,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 262,
            "שם_רחוב": "אריקה"
        },
        {
            "_id": 2335,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 567,
            "שם_רחוב": "ארנבת"
        },
        {
            "_id": 2336,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 142,
            "שם_רחוב": "ארנון"
        },
        {
            "_id": 2337,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 186,
            "שם_רחוב": "ביח יוספטל"
        },
        {
            "_id": 2338,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 129,
            "שם_רחוב": "בית אורן"
        },
        {
            "_id": 2339,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 180,
            "שם_רחוב": "בית אמיתי"
        },
        {
            "_id": 2340,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 193,
            "שם_רחוב": "בית החייל"
        },
        {
            "_id": 2341,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 158,
            "שם_רחוב": "בית הכורה"
        },
        {
            "_id": 2342,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 159,
            "שם_רחוב": "בית הממשלה"
        },
        {
            "_id": 2343,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 590,
            "שם_רחוב": "בית עופר"
        },
        {
            "_id": 2344,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "בנה ביתך"
        },
        {
            "_id": 2345,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 594,
            "שם_רחוב": "בני ישראל"
        },
        {
            "_id": 2346,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 772,
            "שם_רחוב": "בקיה"
        },
        {
            "_id": 2347,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ברנע"
        },
        {
            "_id": 2348,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 349,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 2349,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 419,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 2350,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 225,
            "שם_רחוב": "בשמת"
        },
        {
            "_id": 2351,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 280,
            "שם_רחוב": "בשן"
        },
        {
            "_id": 2352,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 457,
            "שם_רחוב": "בתרן"
        },
        {
            "_id": 2353,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 751,
            "שם_רחוב": "גד השדה"
        },
        {
            "_id": 2354,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 781,
            "שם_רחוב": "גדילן"
        },
        {
            "_id": 2355,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 458,
            "שם_רחוב": "גומא"
        },
        {
            "_id": 2356,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 498,
            "שם_רחוב": "גיטרן"
        },
        {
            "_id": 2357,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 127,
            "שם_רחוב": "גן-בנימין"
        },
        {
            "_id": 2358,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 124,
            "שם_רחוב": "גנים"
        },
        {
            "_id": 2359,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 597,
            "שם_רחוב": "גרביל"
        },
        {
            "_id": 2360,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 752,
            "שם_רחוב": "גרגיר הנחלים"
        },
        {
            "_id": 2361,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 170,
            "שם_רחוב": "גרופית"
        },
        {
            "_id": 2362,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 380,
            "שם_רחוב": "גרניט"
        },
        {
            "_id": 2363,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גשרון"
        },
        {
            "_id": 2364,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 680,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 2365,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 144,
            "שם_רחוב": "דגנית"
        },
        {
            "_id": 2366,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 624,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 2367,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 776,
            "שם_רחוב": "דודאים"
        },
        {
            "_id": 2368,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 753,
            "שם_רחוב": "דוחן"
        },
        {
            "_id": 2369,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 420,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 2370,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 445,
            "שם_רחוב": "דולפין"
        },
        {
            "_id": 2371,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 146,
            "שם_רחוב": "דום"
        },
        {
            "_id": 2372,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 562,
            "שם_רחוב": "דורבן"
        },
        {
            "_id": 2373,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 268,
            "שם_רחוב": "די זהב"
        },
        {
            "_id": 2374,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 459,
            "שם_רחוב": "דפדוף"
        },
        {
            "_id": 2375,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 754,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 2376,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 460,
            "שם_רחוב": "דקר"
        },
        {
            "_id": 2377,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 355,
            "שם_רחוב": "דרבן"
        },
        {
            "_id": 2378,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 461,
            "שם_רחוב": "דרדר"
        },
        {
            "_id": 2379,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 253,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 2380,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 729,
            "שם_רחוב": "דרך ארץ החיים"
        },
        {
            "_id": 2381,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 730,
            "שם_רחוב": "דרך באר שבע"
        },
        {
            "_id": 2382,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 760,
            "שם_רחוב": "דרך הבשמים"
        },
        {
            "_id": 2383,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 635,
            "שם_רחוב": "דרך הגיא"
        },
        {
            "_id": 2384,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 726,
            "שם_רחוב": "דרך המטעים"
        },
        {
            "_id": 2385,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 692,
            "שם_רחוב": "דרך המלכים"
        },
        {
            "_id": 2386,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דרך הערבה"
        },
        {
            "_id": 2387,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 572,
            "שם_רחוב": "דרך הרים"
        },
        {
            "_id": 2388,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 725,
            "שם_רחוב": "דרך השיירות"
        },
        {
            "_id": 2389,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 750,
            "שם_רחוב": "דרך התבלינים"
        },
        {
            "_id": 2390,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 147,
            "שם_רחוב": "דרך יותם"
        },
        {
            "_id": 2391,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 255,
            "שם_רחוב": "דרך מצריים"
        },
        {
            "_id": 2392,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 727,
            "שם_רחוב": "דרךהציפורים הנודדות"
        },
        {
            "_id": 2393,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 585,
            "שם_רחוב": "האדומים"
        },
        {
            "_id": 2394,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 359,
            "שם_רחוב": "האדריכל"
        },
        {
            "_id": 2395,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 656,
            "שם_רחוב": "האהבה"
        },
        {
            "_id": 2396,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 331,
            "שם_רחוב": "האומן"
        },
        {
            "_id": 2397,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 392,
            "שם_רחוב": "האורגים"
        },
        {
            "_id": 2398,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 657,
            "שם_רחוב": "האושר"
        },
        {
            "_id": 2399,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 659,
            "שם_רחוב": "האחווה"
        },
        {
            "_id": 2400,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 393,
            "שם_רחוב": "האחות"
        },
        {
            "_id": 2401,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 166,
            "שם_רחוב": "האיצטדיון"
        },
        {
            "_id": 2402,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 347,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 2403,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 582,
            "שם_רחוב": "האכדים"
        },
        {
            "_id": 2404,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 340,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 2405,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האלמוגים"
        },
        {
            "_id": 2406,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 577,
            "שם_רחוב": "האמורים"
        },
        {
            "_id": 2407,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 818,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 2408,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 623,
            "שם_רחוב": "האפרסק"
        },
        {
            "_id": 2409,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 299,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 2410,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 306,
            "שם_רחוב": "הבורסקאי"
        },
        {
            "_id": 2411,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 813,
            "שם_רחוב": "הביכורים"
        },
        {
            "_id": 2412,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 319,
            "שם_רחוב": "הבלן"
        },
        {
            "_id": 2413,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 324,
            "שם_רחוב": "הבנאי"
        },
        {
            "_id": 2414,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 308,
            "שם_רחוב": "הברדלס"
        },
        {
            "_id": 2415,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 345,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 2416,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 658,
            "שם_רחוב": "הברכה"
        },
        {
            "_id": 2417,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הגופרית"
        },
        {
            "_id": 2418,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 373,
            "שם_רחוב": "הגיר"
        },
        {
            "_id": 2419,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 316,
            "שם_רחוב": "הגלב"
        },
        {
            "_id": 2420,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 578,
            "שם_רחוב": "הגלעדים"
        },
        {
            "_id": 2421,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 598,
            "שם_רחוב": "הגמלים"
        },
        {
            "_id": 2422,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 395,
            "שם_רחוב": "הגנן"
        },
        {
            "_id": 2423,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 446,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 2424,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 394,
            "שם_רחוב": "הדייג"
        },
        {
            "_id": 2425,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 462,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 2426,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 257,
            "שם_רחוב": "ההשכלה"
        },
        {
            "_id": 2427,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 333,
            "שם_רחוב": "הזגג"
        },
        {
            "_id": 2428,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 437,
            "שם_רחוב": "החוצב"
        },
        {
            "_id": 2429,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 119,
            "שם_רחוב": "החורב"
        },
        {
            "_id": 2430,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 370,
            "שם_רחוב": "החותרים"
        },
        {
            "_id": 2431,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 587,
            "שם_רחוב": "החיתים"
        },
        {
            "_id": 2432,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 422,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 2433,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 360,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 2434,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 575,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 2435,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 733,
            "שם_רחוב": "הטרמינל"
        },
        {
            "_id": 2436,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 366,
            "שם_רחוב": "היוצק"
        },
        {
            "_id": 2437,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 543,
            "שם_רחוב": "הים"
        },
        {
            "_id": 2438,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 570,
            "שם_רחוב": "הירח"
        },
        {
            "_id": 2439,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 661,
            "שם_רחוב": "הכוזרים"
        },
        {
            "_id": 2440,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 579,
            "שם_רחוב": "הכנענים"
        },
        {
            "_id": 2441,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 665,
            "שם_רחוב": "הלב"
        },
        {
            "_id": 2442,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 580,
            "שם_רחוב": "המדיינים"
        },
        {
            "_id": 2443,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 581,
            "שם_רחוב": "המואבים"
        },
        {
            "_id": 2444,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 323,
            "שם_רחוב": "המוצר"
        },
        {
            "_id": 2445,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 396,
            "שם_רחוב": "המיילדת"
        },
        {
            "_id": 2446,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 535,
            "שם_רחוב": "המים"
        },
        {
            "_id": 2447,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 660,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 2448,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 273,
            "שם_רחוב": "המכס"
        },
        {
            "_id": 2449,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 332,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 2450,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 125,
            "שם_רחוב": "המלחה"
        },
        {
            "_id": 2451,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 321,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 2452,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 497,
            "שם_רחוב": "המערב"
        },
        {
            "_id": 2453,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 8002,
            "שם_רחוב": "המרינה"
        },
        {
            "_id": 2454,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 588,
            "שם_רחוב": "הנבטים"
        },
        {
            "_id": 2455,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 325,
            "שם_רחוב": "הנגר"
        },
        {
            "_id": 2456,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 334,
            "שם_רחוב": "הנהג"
        },
        {
            "_id": 2457,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 439,
            "שם_רחוב": "הנווט"
        },
        {
            "_id": 2458,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 318,
            "שם_רחוב": "הנחתום"
        },
        {
            "_id": 2459,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 8001,
            "שם_רחוב": "הנמל"
        },
        {
            "_id": 2460,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 326,
            "שם_רחוב": "הנפח"
        },
        {
            "_id": 2461,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 421,
            "שם_רחוב": "הנץ"
        },
        {
            "_id": 2462,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 266,
            "שם_רחוב": "הנשר"
        },
        {
            "_id": 2463,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 662,
            "שם_רחוב": "הנתינה"
        },
        {
            "_id": 2464,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 339,
            "שם_רחוב": "הסוור"
        },
        {
            "_id": 2465,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 365,
            "שם_רחוב": "הסוללים"
        },
        {
            "_id": 2466,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 464,
            "שם_רחוב": "הסיגלית"
        },
        {
            "_id": 2467,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 397,
            "שם_רחוב": "הסנדלר"
        },
        {
            "_id": 2468,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 372,
            "שם_רחוב": "הספורטאים"
        },
        {
            "_id": 2469,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 317,
            "שם_רחוב": "הספן"
        },
        {
            "_id": 2470,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 309,
            "שם_רחוב": "הסתת"
        },
        {
            "_id": 2471,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 583,
            "שם_רחוב": "העמונים"
        },
        {
            "_id": 2472,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 254,
            "שם_רחוב": "העפרוני"
        },
        {
            "_id": 2473,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 817,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 2474,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 364,
            "שם_רחוב": "הפחח"
        },
        {
            "_id": 2475,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 245,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 2476,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 233,
            "שם_רחוב": "הפרטיזנים"
        },
        {
            "_id": 2477,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 341,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 2478,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הצבאים"
        },
        {
            "_id": 2479,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 398,
            "שם_רחוב": "הצדפים"
        },
        {
            "_id": 2480,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 330,
            "שם_רחוב": "הצלחה"
        },
        {
            "_id": 2481,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 348,
            "שם_רחוב": "הצפצפה"
        },
        {
            "_id": 2482,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 819,
            "שם_רחוב": "הקציר"
        },
        {
            "_id": 2483,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 571,
            "שם_רחוב": "הרנוג"
        },
        {
            "_id": 2484,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 666,
            "שם_רחוב": "הרעות"
        },
        {
            "_id": 2485,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 363,
            "שם_רחוב": "הרצף"
        },
        {
            "_id": 2486,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 307,
            "שם_רחוב": "הרתך"
        },
        {
            "_id": 2487,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 584,
            "שם_רחוב": "השומרונים"
        },
        {
            "_id": 2488,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 589,
            "שם_רחוב": "השומרים"
        },
        {
            "_id": 2489,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 116,
            "שם_רחוב": "השחם"
        },
        {
            "_id": 2490,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 298,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 2491,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 667,
            "שם_רחוב": "השלווה"
        },
        {
            "_id": 2492,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 438,
            "שם_רחוב": "השמאי"
        },
        {
            "_id": 2493,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 663,
            "שם_רחוב": "השמחה"
        },
        {
            "_id": 2494,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 362,
            "שם_רחוב": "השרברב"
        },
        {
            "_id": 2495,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 367,
            "שם_רחוב": "השרטט"
        },
        {
            "_id": 2496,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 328,
            "שם_רחוב": "התבונה"
        },
        {
            "_id": 2497,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 399,
            "שם_רחוב": "התוכנה"
        },
        {
            "_id": 2498,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 400,
            "שם_רחוב": "התופרת"
        },
        {
            "_id": 2499,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 327,
            "שם_רחוב": "התושיה"
        },
        {
            "_id": 2500,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 664,
            "שם_רחוב": "התקווה"
        },
        {
            "_id": 2501,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 670,
            "שם_רחוב": "ונוס"
        },
        {
            "_id": 2502,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 677,
            "שם_רחוב": "זהבית"
        },
        {
            "_id": 2503,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 466,
            "שם_רחוב": "זהורית"
        },
        {
            "_id": 2504,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 467,
            "שם_רחוב": "זהרון"
        },
        {
            "_id": 2505,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 676,
            "שם_רחוב": "זוגן"
        },
        {
            "_id": 2506,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 496,
            "שם_רחוב": "זיתן"
        },
        {
            "_id": 2507,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 322,
            "שם_רחוב": "זעת אפיך"
        },
        {
            "_id": 2508,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 230,
            "שם_רחוב": "זרזיר"
        },
        {
            "_id": 2509,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 789,
            "שם_רחוב": "חג האילנות"
        },
        {
            "_id": 2510,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 806,
            "שם_רחוב": "חג החנוכה"
        },
        {
            "_id": 2511,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 810,
            "שם_רחוב": "חג הסוכות"
        },
        {
            "_id": 2512,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 814,
            "שם_רחוב": "חג השבועות"
        },
        {
            "_id": 2513,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 785,
            "שם_רחוב": "חודשי השנה"
        },
        {
            "_id": 2514,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 7003,
            "שם_רחוב": "חוות רודד"
        },
        {
            "_id": 2515,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "חוף אלמוג"
        },
        {
            "_id": 2516,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 123,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 2517,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 109,
            "שם_רחוב": "חטיבת הנגב"
        },
        {
            "_id": 2518,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 773,
            "שם_רחוב": "חיטת הבר"
        },
        {
            "_id": 2519,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 512,
            "שם_רחוב": "חיל ההנדסה"
        },
        {
            "_id": 2520,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 507,
            "שם_רחוב": "חיל הים"
        },
        {
            "_id": 2521,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 782,
            "שם_רחוב": "חיננית"
        },
        {
            "_id": 2522,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 171,
            "שם_רחוב": "חכליל"
        },
        {
            "_id": 2523,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 678,
            "שם_רחוב": "חלמית"
        },
        {
            "_id": 2524,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 469,
            "שם_רחוב": "חניתן"
        },
        {
            "_id": 2525,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 235,
            "שם_רחוב": "חרדל"
        },
        {
            "_id": 2526,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 791,
            "שם_רחוב": "חשון"
        },
        {
            "_id": 2527,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 552,
            "שם_רחוב": "חתול הבר"
        },
        {
            "_id": 2528,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 799,
            "שם_רחוב": "טו באב"
        },
        {
            "_id": 2529,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 793,
            "שם_רחוב": "טבת"
        },
        {
            "_id": 2530,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 470,
            "שם_רחוב": "טווסון"
        },
        {
            "_id": 2531,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 423,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 2532,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 234,
            "שם_רחוב": "טוריים"
        },
        {
            "_id": 2533,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 511,
            "שם_רחוב": "טורקיז"
        },
        {
            "_id": 2534,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 636,
            "שם_רחוב": "טיילת רחבעם זאבי"
        },
        {
            "_id": 2535,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 816,
            "שם_רחוב": "טל ומטר"
        },
        {
            "_id": 2536,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 232,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 2537,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 451,
            "שם_רחוב": "יהושפט"
        },
        {
            "_id": 2538,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 574,
            "שם_רחוב": "יהל"
        },
        {
            "_id": 2539,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 118,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 2540,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 329,
            "שם_רחוב": "יוזמה"
        },
        {
            "_id": 2541,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 471,
            "שם_רחוב": "יולית ים סוף"
        },
        {
            "_id": 2542,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 788,
            "שם_רחוב": "יום אילת"
        },
        {
            "_id": 2543,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 150,
            "שם_רחוב": "ים סוף"
        },
        {
            "_id": 2544,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 424,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 2545,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 689,
            "שם_רחוב": "יעקב אבינו"
        },
        {
            "_id": 2546,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 425,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 2547,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 551,
            "שם_רחוב": "יפה זנב"
        },
        {
            "_id": 2548,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 688,
            "שם_רחוב": "יצחק אבינו"
        },
        {
            "_id": 2549,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 568,
            "שם_רחוב": "ירבוע"
        },
        {
            "_id": 2550,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 442,
            "שם_רחוב": "ירדן"
        },
        {
            "_id": 2551,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 138,
            "שם_רחוב": "ירושלים השלמה"
        },
        {
            "_id": 2552,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 755,
            "שם_רחוב": "כוכב"
        },
        {
            "_id": 2553,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6037,
            "שם_רחוב": "כיכר אדן"
        },
        {
            "_id": 2554,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6034,
            "שם_רחוב": "כיכר אנטיב"
        },
        {
            "_id": 2555,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6045,
            "שם_רחוב": "כיכר בגין"
        },
        {
            "_id": 2556,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6044,
            "שם_רחוב": "כיכר גולדה מאיר"
        },
        {
            "_id": 2557,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6033,
            "שם_רחוב": "כיכר האוניברסיטה"
        },
        {
            "_id": 2558,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6052,
            "שם_רחוב": "כיכר הג'אז"
        },
        {
            "_id": 2559,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6051,
            "שם_רחוב": "כיכר הים האדום"
        },
        {
            "_id": 2560,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6050,
            "שם_רחוב": "כיכר הרי אילת"
        },
        {
            "_id": 2561,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6048,
            "שם_רחוב": "כיכר הרמזורים"
        },
        {
            "_id": 2562,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6049,
            "שם_רחוב": "כיכר השחפים"
        },
        {
            "_id": 2563,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6041,
            "שם_רחוב": "כיכר השלושה"
        },
        {
            "_id": 2564,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6021,
            "שם_רחוב": "כיכר ויצו"
        },
        {
            "_id": 2565,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6035,
            "שם_רחוב": "כיכר יאלטה"
        },
        {
            "_id": 2566,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6042,
            "שם_רחוב": "כיכר יגאל אלון"
        },
        {
            "_id": 2567,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6020,
            "שם_רחוב": "כיכר ים סוף"
        },
        {
            "_id": 2568,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6053,
            "שם_רחוב": "כיכר יצחק נבון"
        },
        {
            "_id": 2569,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6047,
            "שם_רחוב": "כיכר יצחק שמיר"
        },
        {
            "_id": 2570,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6046,
            "שם_רחוב": "כיכר לוי אשכול"
        },
        {
            "_id": 2571,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6043,
            "שם_רחוב": "כיכר משה שרת"
        },
        {
            "_id": 2572,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר אילים"
        },
        {
            "_id": 2573,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6015,
            "שם_רחוב": "ככר אריות"
        },
        {
            "_id": 2574,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר גרוף"
        },
        {
            "_id": 2575,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6031,
            "שם_רחוב": "ככר דוד בן גוריון"
        },
        {
            "_id": 2576,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6028,
            "שם_רחוב": "ככר הבונים החופשיים"
        },
        {
            "_id": 2577,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6029,
            "שם_רחוב": "ככר הדולפינים"
        },
        {
            "_id": 2578,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר הזאבים"
        },
        {
            "_id": 2579,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6017,
            "שם_רחוב": "ככר המייסדים"
        },
        {
            "_id": 2580,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6023,
            "שם_רחוב": "ככר הנופלים"
        },
        {
            "_id": 2581,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר הנמרים"
        },
        {
            "_id": 2582,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6024,
            "שם_רחוב": "ככר הנשים"
        },
        {
            "_id": 2583,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6039,
            "שם_רחוב": "ככר ורד"
        },
        {
            "_id": 2584,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6016,
            "שם_רחוב": "ככר זעמן"
        },
        {
            "_id": 2585,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6026,
            "שם_רחוב": "ככר חבד"
        },
        {
            "_id": 2586,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6038,
            "שם_רחוב": "ככר חורשף"
        },
        {
            "_id": 2587,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר חצרות"
        },
        {
            "_id": 2588,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6019,
            "שם_רחוב": "ככר טורונטו"
        },
        {
            "_id": 2589,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6032,
            "שם_רחוב": "ככר יצחק מודעי"
        },
        {
            "_id": 2590,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6022,
            "שם_רחוב": "ככר ירושלים"
        },
        {
            "_id": 2591,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "ככר ליבנה"
        },
        {
            "_id": 2592,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6025,
            "שם_רחוב": "ככר מגדלי התאומים"
        },
        {
            "_id": 2593,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "ככר מלכי יהודה"
        },
        {
            "_id": 2594,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "ככר מלכי ישראל"
        },
        {
            "_id": 2595,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6009,
            "שם_רחוב": "ככר מקהלות"
        },
        {
            "_id": 2596,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6010,
            "שם_רחוב": "ככר נטפים"
        },
        {
            "_id": 2597,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6012,
            "שם_רחוב": "ככר צנינית"
        },
        {
            "_id": 2598,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6018,
            "שם_רחוב": "ככר ראשי העיר"
        },
        {
            "_id": 2599,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6027,
            "שם_רחוב": "ככר רבין"
        },
        {
            "_id": 2600,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6030,
            "שם_רחוב": "ככר רוטרי"
        },
        {
            "_id": 2601,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6013,
            "שם_רחוב": "ככר רפידים"
        },
        {
            "_id": 2602,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6014,
            "שם_רחוב": "ככר תל אביב"
        },
        {
            "_id": 2603,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 756,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 2604,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 441,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 2605,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 792,
            "שם_רחוב": "כסלו"
        },
        {
            "_id": 2606,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 728,
            "שם_רחוב": "כפות תמרים"
        },
        {
            "_id": 2607,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "כפר הנופש"
        },
        {
            "_id": 2608,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 495,
            "שם_רחוב": "כריש"
        },
        {
            "_id": 2609,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 721,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 2610,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 757,
            "שם_רחוב": "כרפס"
        },
        {
            "_id": 2611,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 777,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 2612,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 778,
            "שם_רחוב": "לבנדר"
        },
        {
            "_id": 2613,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 472,
            "שם_רחוב": "להבית"
        },
        {
            "_id": 2614,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 779,
            "שם_רחוב": "לואיזה"
        },
        {
            "_id": 2615,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 149,
            "שם_רחוב": "לוחמי הגיטאות"
        },
        {
            "_id": 2616,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 426,
            "שם_רחוב": "לוטוס"
        },
        {
            "_id": 2617,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 139,
            "שם_רחוב": "לוס אנג'לס"
        },
        {
            "_id": 2618,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 774,
            "שם_רחוב": "לוף ארץ ישראלי"
        },
        {
            "_id": 2619,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 342,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 2620,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 758,
            "שם_רחוב": "לימונית"
        },
        {
            "_id": 2621,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 427,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 2622,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 731,
            "שם_רחוב": "מגדל הפיקוח"
        },
        {
            "_id": 2623,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 358,
            "שם_רחוב": "מגדל חי"
        },
        {
            "_id": 2624,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 197,
            "שם_רחוב": "מגדלי אילת"
        },
        {
            "_id": 2625,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 148,
            "שם_רחוב": "מדיין"
        },
        {
            "_id": 2626,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 214,
            "שם_רחוב": "מועדון הים התיכון"
        },
        {
            "_id": 2627,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 201,
            "שם_רחוב": "מועדון צלילה סנובה"
        },
        {
            "_id": 2628,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 787,
            "שם_רחוב": "מועדי ישראל"
        },
        {
            "_id": 2629,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 775,
            "שם_רחוב": "מור"
        },
        {
            "_id": 2630,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "מח סוב"
        },
        {
            "_id": 2631,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "מחנה יוכבד"
        },
        {
            "_id": 2632,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 684,
            "שם_רחוב": "מיכל בת שאול"
        },
        {
            "_id": 2633,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 145,
            "שם_רחוב": "מישר"
        },
        {
            "_id": 2634,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 215,
            "שם_רחוב": "מלון אמבסדור"
        },
        {
            "_id": 2635,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 212,
            "שם_רחוב": "מלון אמריקנה"
        },
        {
            "_id": 2636,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 187,
            "שם_רחוב": "מלון בית אשל"
        },
        {
            "_id": 2637,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 210,
            "שם_רחוב": "מלון בל"
        },
        {
            "_id": 2638,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 242,
            "שם_רחוב": "מלון בקעת הירדן"
        },
        {
            "_id": 2639,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 240,
            "שם_רחוב": "מלון בקעת הירח"
        },
        {
            "_id": 2640,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 531,
            "שם_רחוב": "מלון גולדן טוליפ"
        },
        {
            "_id": 2641,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 244,
            "שם_רחוב": "מלון גלי אילת"
        },
        {
            "_id": 2642,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 196,
            "שם_רחוב": "מלון גני שולמית"
        },
        {
            "_id": 2643,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 508,
            "שם_רחוב": "מלון דורטל סול"
        },
        {
            "_id": 2644,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 205,
            "שם_רחוב": "מלון דליה"
        },
        {
            "_id": 2645,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 250,
            "שם_רחוב": "מלון דן"
        },
        {
            "_id": 2646,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 520,
            "שם_רחוב": "מלון דן פנורמה"
        },
        {
            "_id": 2647,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 157,
            "שם_רחוב": "מלון דקל"
        },
        {
            "_id": 2648,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 530,
            "שם_רחוב": "מלון הולידיי אין"
        },
        {
            "_id": 2649,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 189,
            "שם_רחוב": "מלון הים האדום"
        },
        {
            "_id": 2650,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 218,
            "שם_רחוב": "מלון המלך שלמה"
        },
        {
            "_id": 2651,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 536,
            "שם_רחוב": "מלון הני-מון"
        },
        {
            "_id": 2652,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 185,
            "שם_רחוב": "מלון הסלע האדום"
        },
        {
            "_id": 2653,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 504,
            "שם_רחוב": "מלון הקונכיה"
        },
        {
            "_id": 2654,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 537,
            "שם_רחוב": "מלון הרודס"
        },
        {
            "_id": 2655,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 264,
            "שם_רחוב": "מלון הרי אדום"
        },
        {
            "_id": 2656,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 505,
            "שם_רחוב": "מלון הריף"
        },
        {
            "_id": 2657,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 263,
            "שם_רחוב": "מלון ויסטה"
        },
        {
            "_id": 2658,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 188,
            "שם_רחוב": "מלון זוהר"
        },
        {
            "_id": 2659,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 542,
            "שם_רחוב": "מלון טופז"
        },
        {
            "_id": 2660,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 199,
            "שם_רחוב": "מלון לב אילת"
        },
        {
            "_id": 2661,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 524,
            "שם_רחוב": "מלון לב פתאל"
        },
        {
            "_id": 2662,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 241,
            "שם_רחוב": "מלון לגונה"
        },
        {
            "_id": 2663,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 591,
            "שם_רחוב": "מלון לויס טאואר"
        },
        {
            "_id": 2664,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 207,
            "שם_רחוב": "מלון מוריה פלאזה"
        },
        {
            "_id": 2665,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 521,
            "שם_רחוב": "מלון מיראג'"
        },
        {
            "_id": 2666,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 132,
            "שם_רחוב": "מלון מלכת שבא"
        },
        {
            "_id": 2667,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 213,
            "שם_רחוב": "מלון מפרץ השמש"
        },
        {
            "_id": 2668,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "מלון מרדיאן"
        },
        {
            "_id": 2669,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 540,
            "שם_רחוב": "מלון מרידיאן"
        },
        {
            "_id": 2670,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 151,
            "שם_רחוב": "מלון נובה"
        },
        {
            "_id": 2671,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 376,
            "שם_רחוב": "מלון נוף המפרץ"
        },
        {
            "_id": 2672,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 184,
            "שם_רחוב": "מלון נפטון"
        },
        {
            "_id": 2673,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 191,
            "שם_רחוב": "מלון סיני"
        },
        {
            "_id": 2674,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 211,
            "שם_רחוב": "מלון סנטר"
        },
        {
            "_id": 2675,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 190,
            "שם_רחוב": "מלון סנפיר"
        },
        {
            "_id": 2676,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 217,
            "שם_רחוב": "מלון ספורט"
        },
        {
            "_id": 2677,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 198,
            "שם_רחוב": "מלון עדי"
        },
        {
            "_id": 2678,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 169,
            "שם_רחוב": "מלון עציון"
        },
        {
            "_id": 2679,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 538,
            "שם_רחוב": "מלון פטיו"
        },
        {
            "_id": 2680,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 161,
            "שם_רחוב": "מלון פטרה"
        },
        {
            "_id": 2681,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 204,
            "שם_רחוב": "מלון פייר"
        },
        {
            "_id": 2682,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 539,
            "שם_רחוב": "מלון פלמירה"
        },
        {
            "_id": 2683,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 502,
            "שם_רחוב": "מלון פנורמה"
        },
        {
            "_id": 2684,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 690,
            "שם_רחוב": "מלון פרדייז גארדן"
        },
        {
            "_id": 2685,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 130,
            "שם_רחוב": "מלון צברה"
        },
        {
            "_id": 2686,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 208,
            "שם_רחוב": "מלון קיסר"
        },
        {
            "_id": 2687,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 501,
            "שם_רחוב": "מלון קלאב אין"
        },
        {
            "_id": 2688,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 503,
            "שם_רחוב": "מלון קלאב הוטל"
        },
        {
            "_id": 2689,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 509,
            "שם_רחוב": "מלון קלאב מד"
        },
        {
            "_id": 2690,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 522,
            "שם_רחוב": "מלון קראון פלאזה"
        },
        {
            "_id": 2691,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 182,
            "שם_רחוב": "מלון קרוון"
        },
        {
            "_id": 2692,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 506,
            "שם_רחוב": "מלון רד סי ספורט"
        },
        {
            "_id": 2693,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 532,
            "שם_רחוב": "מלון רויאל ביץ'"
        },
        {
            "_id": 2694,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 523,
            "שם_רחוב": "מלון רויאל גארדן"
        },
        {
            "_id": 2695,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 558,
            "שם_רחוב": "מלון רויאל פארק"
        },
        {
            "_id": 2696,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 533,
            "שם_רחוב": "מלון ריביירה"
        },
        {
            "_id": 2697,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 534,
            "שם_רחוב": "מלון שלום פלאזה"
        },
        {
            "_id": 2698,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 181,
            "שם_רחוב": "מלון שלמה"
        },
        {
            "_id": 2699,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 165,
            "שם_רחוב": "מלון תמר"
        },
        {
            "_id": 2700,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 164,
            "שם_רחוב": "מלון תרשיש"
        },
        {
            "_id": 2701,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 780,
            "שם_רחוב": "מליסה"
        },
        {
            "_id": 2702,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 375,
            "שם_רחוב": "מלכת שבא"
        },
        {
            "_id": 2703,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 759,
            "שם_רחוב": "מעוג"
        },
        {
            "_id": 2704,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מעונות מכבי"
        },
        {
            "_id": 2705,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "מערב 1"
        },
        {
            "_id": 2706,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "מערב 2"
        },
        {
            "_id": 2707,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6542,
            "שם_רחוב": "מצפה נוף"
        },
        {
            "_id": 2708,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 474,
            "שם_רחוב": "מקור החסידה"
        },
        {
            "_id": 2709,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 783,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 2710,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 2711,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 569,
            "שם_רחוב": "מריון"
        },
        {
            "_id": 2712,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 126,
            "שם_רחוב": "מרינה"
        },
        {
            "_id": 2713,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 369,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 2714,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 515,
            "שם_רחוב": "מרכז רזין"
        },
        {
            "_id": 2715,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "מרכז רכטר"
        },
        {
            "_id": 2716,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "מרכז תיירות"
        },
        {
            "_id": 2717,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 452,
            "שם_רחוב": "משעול אבובן"
        },
        {
            "_id": 2718,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 611,
            "שם_רחוב": "משעול אבוקדו"
        },
        {
            "_id": 2719,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 453,
            "שם_רחוב": "משעול אבנון"
        },
        {
            "_id": 2720,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 604,
            "שם_רחוב": "משעול אגוז"
        },
        {
            "_id": 2721,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 694,
            "שם_רחוב": "משעול אהרון הכהן"
        },
        {
            "_id": 2722,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 405,
            "שם_רחוב": "משעול אירית"
        },
        {
            "_id": 2723,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 607,
            "שם_רחוב": "משעול אלון"
        },
        {
            "_id": 2724,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 631,
            "שם_רחוב": "משעול אנונה"
        },
        {
            "_id": 2725,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 612,
            "שם_רחוב": "משעול אפרסמון"
        },
        {
            "_id": 2726,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 440,
            "שם_רחוב": "משעול אקליפטוס"
        },
        {
            "_id": 2727,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 617,
            "שם_רחוב": "משעול אשכולית"
        },
        {
            "_id": 2728,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 615,
            "שם_רחוב": "משעול אתרוג"
        },
        {
            "_id": 2729,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 638,
            "שם_רחוב": "משעול בננה"
        },
        {
            "_id": 2730,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 630,
            "שם_רחוב": "משעול גוייבה"
        },
        {
            "_id": 2731,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 734,
            "שם_רחוב": "משעול דקוטה"
        },
        {
            "_id": 2732,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 608,
            "שם_רחוב": "משעול האגס"
        },
        {
            "_id": 2733,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 260,
            "שם_רחוב": "משעול האמוראים"
        },
        {
            "_id": 2734,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 526,
            "שם_רחוב": "משעול הבז"
        },
        {
            "_id": 2735,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 258,
            "שם_רחוב": "משעול הגאונים"
        },
        {
            "_id": 2736,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 596,
            "שם_רחוב": "משעול הגירית"
        },
        {
            "_id": 2737,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 463,
            "שם_רחוב": "משעול החמנית"
        },
        {
            "_id": 2738,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 586,
            "שם_רחוב": "משעול הלויים"
        },
        {
            "_id": 2739,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 737,
            "שם_רחוב": "משעול הנורד"
        },
        {
            "_id": 2740,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 221,
            "שם_רחוב": "משעול העורב"
        },
        {
            "_id": 2741,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 404,
            "שם_רחוב": "משעול הקנה"
        },
        {
            "_id": 2742,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 595,
            "שם_רחוב": "משעול הר נבו"
        },
        {
            "_id": 2743,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 639,
            "שם_רחוב": "משעול התורן"
        },
        {
            "_id": 2744,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 272,
            "שם_רחוב": "משעול התלמוד"
        },
        {
            "_id": 2745,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 259,
            "שם_רחוב": "משעול התנאים"
        },
        {
            "_id": 2746,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 739,
            "שם_רחוב": "משעול וייקאונט"
        },
        {
            "_id": 2747,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 601,
            "שם_רחוב": "משעול זית"
        },
        {
            "_id": 2748,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 632,
            "שם_רחוב": "משעול חבושים"
        },
        {
            "_id": 2749,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 368,
            "שם_רחוב": "משעול חלבלוב"
        },
        {
            "_id": 2750,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 468,
            "שם_רחוב": "משעול חלילן"
        },
        {
            "_id": 2751,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 403,
            "שם_רחוב": "משעול חצב"
        },
        {
            "_id": 2752,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 618,
            "שם_רחוב": "משעול חרוב"
        },
        {
            "_id": 2753,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 236,
            "שם_רחוב": "משעול כלנית"
        },
        {
            "_id": 2754,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 637,
            "שם_רחוב": "משעול כרמים"
        },
        {
            "_id": 2755,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 473,
            "שם_רחוב": "משעול לופית"
        },
        {
            "_id": 2756,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 614,
            "שם_רחוב": "משעול לימון"
        },
        {
            "_id": 2757,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 278,
            "שם_רחוב": "משעול מחצבים"
        },
        {
            "_id": 2758,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 412,
            "שם_רחוב": "משעול מילחית"
        },
        {
            "_id": 2759,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 621,
            "שם_רחוב": "משעול מישמש"
        },
        {
            "_id": 2760,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 603,
            "שם_רחוב": "משעול מנגו"
        },
        {
            "_id": 2761,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 620,
            "שם_רחוב": "משעול מנדרינה"
        },
        {
            "_id": 2762,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 693,
            "שם_רחוב": "משעול משה רבנו"
        },
        {
            "_id": 2763,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 407,
            "שם_רחוב": "משעול נורית"
        },
        {
            "_id": 2764,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 406,
            "שם_רחוב": "משעול נרקיס"
        },
        {
            "_id": 2765,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 409,
            "שם_רחוב": "משעול סהרון"
        },
        {
            "_id": 2766,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 227,
            "שם_רחוב": "משעול סמר"
        },
        {
            "_id": 2767,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 223,
            "שם_רחוב": "משעול סנפיר"
        },
        {
            "_id": 2768,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 414,
            "שם_רחוב": "משעול עדעד"
        },
        {
            "_id": 2769,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 622,
            "שם_רחוב": "משעול עינב"
        },
        {
            "_id": 2770,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 602,
            "שם_רחוב": "משעול ערמון"
        },
        {
            "_id": 2771,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 633,
            "שם_רחוב": "משעול פומלה"
        },
        {
            "_id": 2772,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 413,
            "שם_רחוב": "משעול פלגית"
        },
        {
            "_id": 2773,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 610,
            "שם_רחוב": "משעול פקאן"
        },
        {
            "_id": 2774,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 410,
            "שם_רחוב": "משעול צבעוני"
        },
        {
            "_id": 2775,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 229,
            "שם_רחוב": "משעול צופר"
        },
        {
            "_id": 2776,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 402,
            "שם_רחוב": "משעול צלף"
        },
        {
            "_id": 2777,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 276,
            "שם_רחוב": "משעול צניפים"
        },
        {
            "_id": 2778,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 738,
            "שם_רחוב": "משעול קומודור"
        },
        {
            "_id": 2779,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 735,
            "שם_רחוב": "משעול קומנדו"
        },
        {
            "_id": 2780,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 736,
            "שם_רחוב": "משעול קטלינה"
        },
        {
            "_id": 2781,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 277,
            "שם_רחוב": "משעול רביב"
        },
        {
            "_id": 2782,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 411,
            "שם_רחוב": "משעול רוגן"
        },
        {
            "_id": 2783,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 685,
            "שם_רחוב": "משעול רחל אימנו"
        },
        {
            "_id": 2784,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 401,
            "שם_רחוב": "משעול ריחן"
        },
        {
            "_id": 2785,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 408,
            "שם_רחוב": "משעול ריסן"
        },
        {
            "_id": 2786,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 283,
            "שם_רחוב": "משעול שגיא"
        },
        {
            "_id": 2787,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 619,
            "שם_רחוב": "משעול שזיף"
        },
        {
            "_id": 2788,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 625,
            "שם_רחוב": "משעול שיקמה"
        },
        {
            "_id": 2789,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 605,
            "שם_רחוב": "משעול שקד"
        },
        {
            "_id": 2790,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 606,
            "שם_רחוב": "משעול תאנה"
        },
        {
            "_id": 2791,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 609,
            "שם_רחוב": "משעול תות"
        },
        {
            "_id": 2792,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 613,
            "שם_רחוב": "משעול תפוז"
        },
        {
            "_id": 2793,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 616,
            "שם_רחוב": "משעול תפוח"
        },
        {
            "_id": 2794,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 811,
            "שם_רחוב": "מתן תורה"
        },
        {
            "_id": 2795,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 269,
            "שם_רחוב": "נביעות"
        },
        {
            "_id": 2796,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 559,
            "שם_רחוב": "נווה מדבר"
        },
        {
            "_id": 2797,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 560,
            "שם_רחוב": "נוף מדבר"
        },
        {
            "_id": 2798,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 428,
            "שם_רחוב": "נופר"
        },
        {
            "_id": 2799,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 707,
            "שם_רחוב": "נחל גרעינית"
        },
        {
            "_id": 2800,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 640,
            "שם_רחוב": "נחל זרחן"
        },
        {
            "_id": 2801,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 643,
            "שם_רחוב": "נחל חביון"
        },
        {
            "_id": 2802,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 641,
            "שם_רחוב": "נחל חיון"
        },
        {
            "_id": 2803,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 270,
            "שם_רחוב": "נחל כתובות"
        },
        {
            "_id": 2804,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 642,
            "שם_רחוב": "נחל ממשית"
        },
        {
            "_id": 2805,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 143,
            "שם_רחוב": "נחל ניצוץ"
        },
        {
            "_id": 2806,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 194,
            "שם_רחוב": "נחל עמרם"
        },
        {
            "_id": 2807,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 655,
            "שם_רחוב": "נחל רחם"
        },
        {
            "_id": 2808,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 626,
            "שם_רחוב": "נחל שחרות"
        },
        {
            "_id": 2809,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 294,
            "שם_רחוב": "נחשון"
        },
        {
            "_id": 2810,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 795,
            "שם_רחוב": "ניסן"
        },
        {
            "_id": 2811,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 475,
            "שם_רחוב": "ניצרן"
        },
        {
            "_id": 2812,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 672,
            "שם_רחוב": "נירית"
        },
        {
            "_id": 2813,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 133,
            "שם_רחוב": "נמל אילת"
        },
        {
            "_id": 2814,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 600,
            "שם_רחוב": "נמנמן"
        },
        {
            "_id": 2815,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 271,
            "שם_רחוב": "נעמה"
        },
        {
            "_id": 2816,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 761,
            "שם_רחוב": "נענע משולבת"
        },
        {
            "_id": 2817,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 476,
            "שם_רחוב": "נפטון"
        },
        {
            "_id": 2818,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 429,
            "שם_רחוב": "נציץ"
        },
        {
            "_id": 2819,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 343,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 2820,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 477,
            "שם_רחוב": "סולית"
        },
        {
            "_id": 2821,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 720,
            "שם_רחוב": "סוסיתא"
        },
        {
            "_id": 2822,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 722,
            "שם_רחוב": "סופה"
        },
        {
            "_id": 2823,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 344,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 2824,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 797,
            "שם_רחוב": "סיון"
        },
        {
            "_id": 2825,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 478,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 2826,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 385,
            "שם_רחוב": "סילון"
        },
        {
            "_id": 2827,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 135,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 2828,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 762,
            "שם_רחוב": "סירה קוצנית"
        },
        {
            "_id": 2829,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 383,
            "שם_רחוב": "סמ אזורית"
        },
        {
            "_id": 2830,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 281,
            "שם_רחוב": "סמ אשליל"
        },
        {
            "_id": 2831,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 295,
            "שם_רחוב": "סמ ברק"
        },
        {
            "_id": 2832,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 290,
            "שם_רחוב": "סמ גבים"
        },
        {
            "_id": 2833,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 337,
            "שם_רחוב": "סמ דיה"
        },
        {
            "_id": 2834,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 303,
            "שם_רחוב": "סמ דישונים"
        },
        {
            "_id": 2835,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 286,
            "שם_רחוב": "סמ האשלג"
        },
        {
            "_id": 2836,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 723,
            "שם_רחוב": "סמ המכונאים"
        },
        {
            "_id": 2837,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 288,
            "שם_רחוב": "סמ המכרות"
        },
        {
            "_id": 2838,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 450,
            "שם_רחוב": "סמ המלך אחז"
        },
        {
            "_id": 2839,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 291,
            "שם_רחוב": "סמ המנגן"
        },
        {
            "_id": 2840,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 284,
            "שם_רחוב": "סמ המנהרות"
        },
        {
            "_id": 2841,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 285,
            "שם_רחוב": "סמ המסילות"
        },
        {
            "_id": 2842,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 292,
            "שם_רחוב": "סמ המערות"
        },
        {
            "_id": 2843,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 724,
            "שם_רחוב": "סמ הנגרים"
        },
        {
            "_id": 2844,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 122,
            "שם_רחוב": "סמ הנחושת"
        },
        {
            "_id": 2845,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 287,
            "שם_רחוב": "סמ הצוק"
        },
        {
            "_id": 2846,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 352,
            "שם_רחוב": "סמ הקורא"
        },
        {
            "_id": 2847,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 652,
            "שם_רחוב": "סמ הר גשור"
        },
        {
            "_id": 2848,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 649,
            "שם_רחוב": "סמ הר דלעת"
        },
        {
            "_id": 2849,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 651,
            "שם_רחוב": "סמ הר חלוקים"
        },
        {
            "_id": 2850,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 653,
            "שם_רחוב": "סמ הר יעלון"
        },
        {
            "_id": 2851,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 650,
            "שם_רחוב": "סמ הר לוץ"
        },
        {
            "_id": 2852,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 350,
            "שם_רחוב": "סמ הר סיני"
        },
        {
            "_id": 2853,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 654,
            "שם_רחוב": "סמ הר סעד"
        },
        {
            "_id": 2854,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 648,
            "שם_רחוב": "סמ הר שלמה"
        },
        {
            "_id": 2855,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 378,
            "שם_רחוב": "סמ הרדוף"
        },
        {
            "_id": 2856,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 302,
            "שם_רחוב": "סמ התנינים"
        },
        {
            "_id": 2857,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 338,
            "שם_רחוב": "סמ ורדית"
        },
        {
            "_id": 2858,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 310,
            "שם_רחוב": "סמ זיק"
        },
        {
            "_id": 2859,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 371,
            "שם_רחוב": "סמ חבצלת"
        },
        {
            "_id": 2860,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 382,
            "שם_רחוב": "סמ חלמיש"
        },
        {
            "_id": 2861,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 312,
            "שם_רחוב": "סמ טירן"
        },
        {
            "_id": 2862,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 415,
            "שם_רחוב": "סמ יסעור"
        },
        {
            "_id": 2863,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 304,
            "שם_רחוב": "סמ יענים"
        },
        {
            "_id": 2864,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 179,
            "שם_רחוב": "סמ כרכום"
        },
        {
            "_id": 2865,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 353,
            "שם_רחוב": "סמ לילית"
        },
        {
            "_id": 2866,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 377,
            "שם_רחוב": "סמ לענה"
        },
        {
            "_id": 2867,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 282,
            "שם_רחוב": "סמ מואב"
        },
        {
            "_id": 2868,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 494,
            "שם_רחוב": "סמ מולית"
        },
        {
            "_id": 2869,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 374,
            "שם_רחוב": "סמ מלכית"
        },
        {
            "_id": 2870,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סמ נחושתן"
        },
        {
            "_id": 2871,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 706,
            "שם_רחוב": "סמ נחל אורה"
        },
        {
            "_id": 2872,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 629,
            "שם_רחוב": "סמ נחל חימר"
        },
        {
            "_id": 2873,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 704,
            "שם_רחוב": "סמ נחל חמדה"
        },
        {
            "_id": 2874,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 705,
            "שם_רחוב": "סמ נחל חרוז"
        },
        {
            "_id": 2875,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 628,
            "שם_רחוב": "סמ נחל מנוחה"
        },
        {
            "_id": 2876,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 702,
            "שם_רחוב": "סמ נחל ציחור"
        },
        {
            "_id": 2877,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 701,
            "שם_רחוב": "סמ נחל קטורה"
        },
        {
            "_id": 2878,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 703,
            "שם_רחוב": "סמ נחל שני"
        },
        {
            "_id": 2879,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 314,
            "שם_רחוב": "סמ ניצנה"
        },
        {
            "_id": 2880,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 293,
            "שם_רחוב": "סמ סיגים"
        },
        {
            "_id": 2881,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 209,
            "שם_רחוב": "סמ סלעית"
        },
        {
            "_id": 2882,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 335,
            "שם_רחוב": "סמ סנונית"
        },
        {
            "_id": 2883,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 315,
            "שם_רחוב": "סמ עומר"
        },
        {
            "_id": 2884,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 112,
            "שם_רחוב": "סמ עופרים"
        },
        {
            "_id": 2885,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 336,
            "שם_רחוב": "סמ עיט"
        },
        {
            "_id": 2886,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 384,
            "שם_רחוב": "סמ עירית"
        },
        {
            "_id": 2887,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 174,
            "שם_רחוב": "סמ ערד"
        },
        {
            "_id": 2888,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 173,
            "שם_רחוב": "סמ עשוש"
        },
        {
            "_id": 2889,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 279,
            "שם_רחוב": "סמ צוקים"
        },
        {
            "_id": 2890,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 301,
            "שם_רחוב": "סמ ראמים"
        },
        {
            "_id": 2891,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 289,
            "שם_רחוב": "סמ רביבים"
        },
        {
            "_id": 2892,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 305,
            "שם_רחוב": "סמ רימון"
        },
        {
            "_id": 2893,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 192,
            "שם_רחוב": "סמ שדמית"
        },
        {
            "_id": 2894,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 389,
            "שם_רחוב": "סמ שיזר"
        },
        {
            "_id": 2895,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 313,
            "שם_רחוב": "סמ שני"
        },
        {
            "_id": 2896,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 296,
            "שם_רחוב": "סמ שעיר"
        },
        {
            "_id": 2897,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 247,
            "שם_רחוב": "סמ שפיפון"
        },
        {
            "_id": 2898,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 493,
            "שם_רחוב": "סמ שפריר"
        },
        {
            "_id": 2899,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 563,
            "שם_רחוב": "סמור"
        },
        {
            "_id": 2900,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 379,
            "שם_רחוב": "סמטת ציפחה"
        },
        {
            "_id": 2901,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 434,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 2902,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 763,
            "שם_רחוב": "סרפד"
        },
        {
            "_id": 2903,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 346,
            "שם_רחוב": "סתונית"
        },
        {
            "_id": 2904,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 802,
            "שם_רחוב": "סתיו"
        },
        {
            "_id": 2905,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 527,
            "שם_רחוב": "עגור"
        },
        {
            "_id": 2906,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 786,
            "שם_רחוב": "עונות שנה"
        },
        {
            "_id": 2907,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 557,
            "שם_רחוב": "עטלפים"
        },
        {
            "_id": 2908,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 646,
            "שם_רחוב": "עין בוקק"
        },
        {
            "_id": 2909,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 634,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 2910,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 686,
            "שם_רחוב": "עין דור"
        },
        {
            "_id": 2911,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 248,
            "שם_רחוב": "עין החתול"
        },
        {
            "_id": 2912,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 627,
            "שם_רחוב": "עין יהב"
        },
        {
            "_id": 2913,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 645,
            "שם_רחוב": "עין מור"
        },
        {
            "_id": 2914,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 576,
            "שם_רחוב": "עין נטפים"
        },
        {
            "_id": 2915,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 647,
            "שם_רחוב": "עין עקב"
        },
        {
            "_id": 2916,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 644,
            "שם_רחוב": "עין עקרבים"
        },
        {
            "_id": 2917,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 479,
            "שם_רחוב": "ערוד"
        },
        {
            "_id": 2918,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 675,
            "שם_רחוב": "ערער"
        },
        {
            "_id": 2919,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 137,
            "שם_רחוב": "פארן"
        },
        {
            "_id": 2920,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 7002,
            "שם_רחוב": "פארק תמנע"
        },
        {
            "_id": 2921,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 592,
            "שם_רחוב": "פולטי טאור"
        },
        {
            "_id": 2922,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 480,
            "שם_רחוב": "פזית"
        },
        {
            "_id": 2923,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 481,
            "שם_רחוב": "פטישן"
        },
        {
            "_id": 2924,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 482,
            "שם_רחוב": "פטל"
        },
        {
            "_id": 2925,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 573,
            "שם_רחוב": "פיאסטאני"
        },
        {
            "_id": 2926,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 483,
            "שם_רחוב": "פיקוס"
        },
        {
            "_id": 2927,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 484,
            "שם_רחוב": "פסון"
        },
        {
            "_id": 2928,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 815,
            "שם_רחוב": "פסח"
        },
        {
            "_id": 2929,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 561,
            "שם_רחוב": "פסמון"
        },
        {
            "_id": 2930,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 732,
            "שם_רחוב": "פקח טיסה"
        },
        {
            "_id": 2931,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 764,
            "שם_רחוב": "פרג"
        },
        {
            "_id": 2932,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 265,
            "שם_רחוב": "פרס"
        },
        {
            "_id": 2933,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 485,
            "שם_רחוב": "פרפרון"
        },
        {
            "_id": 2934,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 679,
            "שם_רחוב": "פרקרק"
        },
        {
            "_id": 2935,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 239,
            "שם_רחוב": "פתן"
        },
        {
            "_id": 2936,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 486,
            "שם_רחוב": "צבעון"
        },
        {
            "_id": 2937,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 671,
            "שם_רחוב": "צדק"
        },
        {
            "_id": 2938,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "צופית עלית"
        },
        {
            "_id": 2939,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "צופית תחתית"
        },
        {
            "_id": 2940,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 390,
            "שם_רחוב": "צור"
        },
        {
            "_id": 2941,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 136,
            "שם_רחוב": "צין"
        },
        {
            "_id": 2942,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 593,
            "שם_רחוב": "צפחות"
        },
        {
            "_id": 2943,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 237,
            "שם_רחוב": "צפע"
        },
        {
            "_id": 2944,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 246,
            "שם_רחוב": "קאמן"
        },
        {
            "_id": 2945,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 249,
            "שם_רחוב": "קאמפן"
        },
        {
            "_id": 2946,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "קבוץ המאוחד"
        },
        {
            "_id": 2947,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 202,
            "שם_רחוב": "קדש"
        },
        {
            "_id": 2948,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 599,
            "שם_רחוב": "קוצן זהוב"
        },
        {
            "_id": 2949,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 767,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 2950,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 175,
            "שם_רחוב": "קטורה"
        },
        {
            "_id": 2951,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 766,
            "שם_רחוב": "קטלב מצוי"
        },
        {
            "_id": 2952,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 488,
            "שם_רחוב": "קידה"
        },
        {
            "_id": 2953,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 176,
            "שם_רחוב": "קידר"
        },
        {
            "_id": 2954,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 765,
            "שם_רחוב": "קינמון"
        },
        {
            "_id": 2955,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 430,
            "שם_רחוב": "קיסוס"
        },
        {
            "_id": 2956,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 490,
            "שם_רחוב": "קיסר הדור"
        },
        {
            "_id": 2957,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 565,
            "שם_רחוב": "קיפוד"
        },
        {
            "_id": 2958,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 489,
            "שם_רחוב": "קיפודג"
        },
        {
            "_id": 2959,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 231,
            "שם_רחוב": "קרלובי-וארי"
        },
        {
            "_id": 2960,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 550,
            "שם_רחוב": "קרקל"
        },
        {
            "_id": 2961,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 808,
            "שם_רחוב": "קשת"
        },
        {
            "_id": 2962,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 809,
            "שם_רחוב": "ראש השנה"
        },
        {
            "_id": 2963,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 807,
            "שם_רחוב": "ראש חודש"
        },
        {
            "_id": 2964,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6552,
            "שם_רחוב": "רובע האבות"
        },
        {
            "_id": 2965,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6551,
            "שם_רחוב": "רובע האמהות"
        },
        {
            "_id": 2966,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6548,
            "שם_רחוב": "רובע הבשמים"
        },
        {
            "_id": 2967,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "רובע המעיינות"
        },
        {
            "_id": 2968,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6547,
            "שם_רחוב": "רובע הנחלים"
        },
        {
            "_id": 2969,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6553,
            "שם_רחוב": "רובע העונות"
        },
        {
            "_id": 2970,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6546,
            "שם_רחוב": "רובע הפירות"
        },
        {
            "_id": 2971,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6549,
            "שם_רחוב": "רובע שבטי ישראל"
        },
        {
            "_id": 2972,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רודד"
        },
        {
            "_id": 2973,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 768,
            "שם_רחוב": "רוזמרין"
        },
        {
            "_id": 2974,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 681,
            "שם_רחוב": "רות המואביה"
        },
        {
            "_id": 2975,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 261,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 2976,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "רמת יותם"
        },
        {
            "_id": 2977,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 431,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 2978,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רתמים"
        },
        {
            "_id": 2979,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "ש אופיר"
        },
        {
            "_id": 2980,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "ש אטונגים"
        },
        {
            "_id": 2981,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "ש בתי ברמן"
        },
        {
            "_id": 2982,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "ש האשל"
        },
        {
            "_id": 2983,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "ש סנפיר"
        },
        {
            "_id": 2984,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "ש פטיו"
        },
        {
            "_id": 2985,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "ש צופית"
        },
        {
            "_id": 2986,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 794,
            "שם_רחוב": "שבט"
        },
        {
            "_id": 2987,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 275,
            "שם_רחוב": "שביל בוקר"
        },
        {
            "_id": 2988,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 386,
            "שם_רחוב": "שביל הצבי"
        },
        {
            "_id": 2989,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 465,
            "שם_רחוב": "שביל השמש"
        },
        {
            "_id": 2990,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 220,
            "שם_רחוב": "שביל התור"
        },
        {
            "_id": 2991,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 553,
            "שם_רחוב": "שביל יחמורים"
        },
        {
            "_id": 2992,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 224,
            "שם_רחוב": "שביל יטבתה"
        },
        {
            "_id": 2993,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 219,
            "שם_רחוב": "שביל לוטם"
        },
        {
            "_id": 2994,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 556,
            "שם_רחוב": "שביל סוסים"
        },
        {
            "_id": 2995,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 226,
            "שם_רחוב": "שביל עברונה"
        },
        {
            "_id": 2996,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 163,
            "שם_רחוב": "שביל עידן"
        },
        {
            "_id": 2997,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 228,
            "שם_רחוב": "שביל עתק"
        },
        {
            "_id": 2998,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 545,
            "שם_רחוב": "שביל פעמי השלום"
        },
        {
            "_id": 2999,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 554,
            "שם_רחוב": "שביל פראים"
        },
        {
            "_id": 3000,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 387,
            "שם_רחוב": "שביל צבר"
        },
        {
            "_id": 3001,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 361,
            "שם_רחוב": "שביל שונית"
        },
        {
            "_id": 3002,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 555,
            "שם_רחוב": "שביל שועלים"
        },
        {
            "_id": 3003,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 222,
            "שם_רחוב": "שביל שיזף"
        },
        {
            "_id": 3004,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 168,
            "שם_רחוב": "שד ארגמן"
        },
        {
            "_id": 3005,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 121,
            "שם_רחוב": "שד התמרים"
        },
        {
            "_id": 3006,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 695,
            "שם_רחוב": "שד טורונטו"
        },
        {
            "_id": 3007,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 117,
            "שם_רחוב": "שד יעלים"
        },
        {
            "_id": 3008,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 114,
            "שם_רחוב": "שד עובדה"
        },
        {
            "_id": 3009,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 177,
            "שם_רחוב": "שד ששת הימים"
        },
        {
            "_id": 3010,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 740,
            "שם_רחוב": "שדה התעופה"
        },
        {
            "_id": 3011,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 172,
            "שם_רחוב": "שובל"
        },
        {
            "_id": 3012,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 435,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 3013,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 500,
            "שם_רחוב": "שומר"
        },
        {
            "_id": 3014,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 691,
            "שם_רחוב": "שופרון"
        },
        {
            "_id": 3015,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 134,
            "שם_רחוב": "שור"
        },
        {
            "_id": 3016,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 432,
            "שם_רחוב": "שושן"
        },
        {
            "_id": 3017,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 167,
            "שם_רחוב": "שחורת"
        },
        {
            "_id": 3018,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 178,
            "שם_רחוב": "שחמון"
        },
        {
            "_id": 3019,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 251,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 3020,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 769,
            "שם_רחוב": "שיבולים"
        },
        {
            "_id": 3021,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "שכ אורים"
        },
        {
            "_id": 3022,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "שכ אמדר"
        },
        {
            "_id": 3023,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "שכ גנים"
        },
        {
            "_id": 3024,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6545,
            "שם_רחוב": "שכ גנים ב"
        },
        {
            "_id": 3025,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6535,
            "שם_רחוב": "שכ הדקל"
        },
        {
            "_id": 3026,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6544,
            "שם_רחוב": "שכ המלחה"
        },
        {
            "_id": 3027,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "שכ העמים"
        },
        {
            "_id": 3028,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "שכ יעלים"
        },
        {
            "_id": 3029,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6538,
            "שם_רחוב": "שכ יפה נוף"
        },
        {
            "_id": 3030,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6539,
            "שם_רחוב": "שכ מצפה ים"
        },
        {
            "_id": 3031,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6543,
            "שם_רחוב": "שכ נווה מדבר"
        },
        {
            "_id": 3032,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6537,
            "שם_רחוב": "שכ עידן"
        },
        {
            "_id": 3033,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "שכ ערבה"
        },
        {
            "_id": 3034,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "שכ צאלים"
        },
        {
            "_id": 3035,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "שכ שחמון"
        },
        {
            "_id": 3036,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 6534,
            "שם_רחוב": "שכ שקמונה"
        },
        {
            "_id": 3037,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 200,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 3038,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 812,
            "שם_רחוב": "שמיני עצרת"
        },
        {
            "_id": 3039,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 771,
            "שם_רחוב": "שן הארי"
        },
        {
            "_id": 3040,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 566,
            "שם_רחוב": "שפן סלעים"
        },
        {
            "_id": 3041,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 354,
            "שם_רחוב": "שקמונה"
        },
        {
            "_id": 3042,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 491,
            "שם_רחוב": "שרביטן"
        },
        {
            "_id": 3043,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 683,
            "שם_רחוב": "שרה אימנו"
        },
        {
            "_id": 3044,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 256,
            "שם_רחוב": "שרקרק"
        },
        {
            "_id": 3045,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 492,
            "שם_רחוב": "תוכינון"
        },
        {
            "_id": 3046,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 770,
            "שם_רחוב": "תורמוס"
        },
        {
            "_id": 3047,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 433,
            "שם_רחוב": "תלתן"
        },
        {
            "_id": 3048,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 798,
            "שם_רחוב": "תמוז"
        },
        {
            "_id": 3049,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 297,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 3050,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 513,
            "שם_רחוב": "תפאר"
        },
        {
            "_id": 3051,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 300,
            "שם_רחוב": "תפוח סדום"
        },
        {
            "_id": 3052,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 544,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 3053,
            "סמל_ישוב": 2600,
            "שם_ישוב": "אילת ",
            "סמל_רחוב": 790,
            "שם_רחוב": "תשרי"
        },
        {
            "_id": 3054,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 3055,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 139,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 3056,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הבזלת"
        },
        {
            "_id": 3057,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הגרניט"
        },
        {
            "_id": 3058,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 131,
            "שם_רחוב": "הדולומיט"
        },
        {
            "_id": 3059,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 126,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 3060,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הכורכר"
        },
        {
            "_id": 3061,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 3062,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הנופר"
        },
        {
            "_id": 3063,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 3064,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 134,
            "שם_רחוב": "הנחליאלי"
        },
        {
            "_id": 3065,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 3066,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 3067,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הסחלב"
        },
        {
            "_id": 3068,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הסייפן"
        },
        {
            "_id": 3069,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 135,
            "שם_רחוב": "העגור"
        },
        {
            "_id": 3070,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 119,
            "שם_רחוב": "העירית"
        },
        {
            "_id": 3071,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 138,
            "שם_רחוב": "העפרוני"
        },
        {
            "_id": 3072,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 3073,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 136,
            "שם_רחוב": "הפשוש"
        },
        {
            "_id": 3074,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 3075,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 3076,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 3077,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 137,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 3078,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר יהדות בולגריה"
        },
        {
            "_id": 3079,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מאדים"
        },
        {
            "_id": 3080,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נגה"
        },
        {
            "_id": 3081,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 114,
            "שם_רחוב": "נחל איילון"
        },
        {
            "_id": 3082,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 106,
            "שם_רחוב": "נחל ברקן"
        },
        {
            "_id": 3083,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 108,
            "שם_רחוב": "נחל גלים"
        },
        {
            "_id": 3084,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 109,
            "שם_רחוב": "נחל דרגות"
        },
        {
            "_id": 3085,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נחל האלה"
        },
        {
            "_id": 3086,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נחל הבשור"
        },
        {
            "_id": 3087,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 111,
            "שם_רחוב": "נחל הירדן"
        },
        {
            "_id": 3088,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 110,
            "שם_רחוב": "נחל הירקון"
        },
        {
            "_id": 3089,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נחל לכיש"
        },
        {
            "_id": 3090,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 116,
            "שם_רחוב": "נחל פארן"
        },
        {
            "_id": 3091,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 115,
            "שם_רחוב": "נחל ציפורי"
        },
        {
            "_id": 3092,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 102,
            "שם_רחוב": "נחל שורק"
        },
        {
            "_id": 3093,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שבתאי"
        },
        {
            "_id": 3094,
            "סמל_ישוב": 1336,
            "שם_ישוב": "אירוס ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שדרות גן רווה"
        },
        {
            "_id": 3095,
            "סמל_ישוב": 3762,
            "שם_ישוב": "איתמר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "איתמר"
        },
        {
            "_id": 3096,
            "סמל_ישוב": 37,
            "שם_ישוב": "איתן ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "אחוזת ענוג"
        },
        {
            "_id": 3097,
            "סמל_ישוב": 37,
            "שם_ישוב": "איתן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "איתן"
        },
        {
            "_id": 3098,
            "סמל_ישוב": 886,
            "שם_ישוב": "איתנים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "איתנים"
        },
        {
            "_id": 3099,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "אזור בתי הספר"
        },
        {
            "_id": 3100,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אכסאל"
        },
        {
            "_id": 3101,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "דנאנה"
        },
        {
            "_id": 3102,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "הזיתים"
        },
        {
            "_id": 3103,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "זייתון אלשמאלי"
        },
        {
            "_id": 3104,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "מרכז-מדרום למסגד"
        },
        {
            "_id": 3105,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "מרכז-מצפון למסגד"
        },
        {
            "_id": 3106,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "סעד וסעיד"
        },
        {
            "_id": 3107,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 139,
            "שם_רחוב": "רח 100"
        },
        {
            "_id": 3108,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 251,
            "שם_רחוב": "רח 200"
        },
        {
            "_id": 3109,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 245,
            "שם_רחוב": "רח 500"
        },
        {
            "_id": 3110,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 264,
            "שם_רחוב": "רח 10"
        },
        {
            "_id": 3111,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 148,
            "שם_רחוב": "רח 110"
        },
        {
            "_id": 3112,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 262,
            "שם_רחוב": "רח 510"
        },
        {
            "_id": 3113,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 233,
            "שם_רחוב": "רח 20"
        },
        {
            "_id": 3114,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 152,
            "שם_רחוב": "רח 120"
        },
        {
            "_id": 3115,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 172,
            "שם_רחוב": "רח 30"
        },
        {
            "_id": 3116,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 216,
            "שם_רחוב": "רח 40"
        },
        {
            "_id": 3117,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רח 50"
        },
        {
            "_id": 3118,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 203,
            "שם_רחוב": "רח 150"
        },
        {
            "_id": 3119,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח 60"
        },
        {
            "_id": 3120,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 223,
            "שם_רחוב": "רח 160"
        },
        {
            "_id": 3121,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 199,
            "שם_רחוב": "רח 80"
        },
        {
            "_id": 3122,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 159,
            "שם_רחוב": "רח 90"
        },
        {
            "_id": 3123,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 157,
            "שם_רחוב": "רח 1"
        },
        {
            "_id": 3124,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 140,
            "שם_רחוב": "רח 101"
        },
        {
            "_id": 3125,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 252,
            "שם_רחוב": "רח 201"
        },
        {
            "_id": 3126,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 226,
            "שם_רחוב": "רח 11"
        },
        {
            "_id": 3127,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 149,
            "שם_רחוב": "רח 111"
        },
        {
            "_id": 3128,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 234,
            "שם_רחוב": "רח 21"
        },
        {
            "_id": 3129,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 154,
            "שם_רחוב": "רח 121"
        },
        {
            "_id": 3130,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 208,
            "שם_רחוב": "רח 31"
        },
        {
            "_id": 3131,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 217,
            "שם_רחוב": "רח 41"
        },
        {
            "_id": 3132,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רח 51"
        },
        {
            "_id": 3133,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 204,
            "שם_רחוב": "רח 151"
        },
        {
            "_id": 3134,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח 61"
        },
        {
            "_id": 3135,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 205,
            "שם_רחוב": "רח 161"
        },
        {
            "_id": 3136,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 126,
            "שם_רחוב": "רח 71"
        },
        {
            "_id": 3137,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 200,
            "שם_רחוב": "רח 81"
        },
        {
            "_id": 3138,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 160,
            "שם_רחוב": "רח 91"
        },
        {
            "_id": 3139,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 164,
            "שם_רחוב": "רח 2"
        },
        {
            "_id": 3140,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 141,
            "שם_רחוב": "רח 102"
        },
        {
            "_id": 3141,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 253,
            "שם_רחוב": "רח 202"
        },
        {
            "_id": 3142,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 246,
            "שם_רחוב": "רח 502"
        },
        {
            "_id": 3143,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 227,
            "שם_רחוב": "רח 12"
        },
        {
            "_id": 3144,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 150,
            "שם_רחוב": "רח 112"
        },
        {
            "_id": 3145,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 244,
            "שם_רחוב": "רח 512"
        },
        {
            "_id": 3146,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 235,
            "שם_רחוב": "רח 22"
        },
        {
            "_id": 3147,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רח 122"
        },
        {
            "_id": 3148,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רח 32"
        },
        {
            "_id": 3149,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 218,
            "שם_רחוב": "רח 42"
        },
        {
            "_id": 3150,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רח 52"
        },
        {
            "_id": 3151,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח 62"
        },
        {
            "_id": 3152,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 258,
            "שם_רחוב": "רח 162"
        },
        {
            "_id": 3153,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 173,
            "שם_רחוב": "רח 82"
        },
        {
            "_id": 3154,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 182,
            "שם_רחוב": "רח 92"
        },
        {
            "_id": 3155,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 165,
            "שם_רחוב": "רח 3"
        },
        {
            "_id": 3156,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 254,
            "שם_רחוב": "רח 203"
        },
        {
            "_id": 3157,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 247,
            "שם_רחוב": "רח 503"
        },
        {
            "_id": 3158,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 228,
            "שם_רחוב": "רח 13"
        },
        {
            "_id": 3159,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 151,
            "שם_רחוב": "רח 113"
        },
        {
            "_id": 3160,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 171,
            "שם_רחוב": "רח 23"
        },
        {
            "_id": 3161,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 133,
            "שם_רחוב": "רח 123"
        },
        {
            "_id": 3162,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 210,
            "שם_רחוב": "רח 33"
        },
        {
            "_id": 3163,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 219,
            "שם_רחוב": "רח 43"
        },
        {
            "_id": 3164,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רח 53"
        },
        {
            "_id": 3165,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 155,
            "שם_רחוב": "רח 153"
        },
        {
            "_id": 3166,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 156,
            "שם_רחוב": "רח 63"
        },
        {
            "_id": 3167,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 259,
            "שם_רחוב": "רח 163"
        },
        {
            "_id": 3168,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 192,
            "שם_רחוב": "רח 73"
        },
        {
            "_id": 3169,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 174,
            "שם_רחוב": "רח 83"
        },
        {
            "_id": 3170,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 161,
            "שם_רחוב": "רח 93"
        },
        {
            "_id": 3171,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 166,
            "שם_רחוב": "רח 4"
        },
        {
            "_id": 3172,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רח 104"
        },
        {
            "_id": 3173,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 248,
            "שם_רחוב": "רח 504"
        },
        {
            "_id": 3174,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 229,
            "שם_רחוב": "רח 14"
        },
        {
            "_id": 3175,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 186,
            "שם_רחוב": "רח 114"
        },
        {
            "_id": 3176,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 237,
            "שם_רחוב": "רח 24"
        },
        {
            "_id": 3177,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 134,
            "שם_רחוב": "רח 124"
        },
        {
            "_id": 3178,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 211,
            "שם_רחוב": "רח 34"
        },
        {
            "_id": 3179,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "רח 54"
        },
        {
            "_id": 3180,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 188,
            "שם_רחוב": "רח 154"
        },
        {
            "_id": 3181,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "רח 64"
        },
        {
            "_id": 3182,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 265,
            "שם_רחוב": "רח 164"
        },
        {
            "_id": 3183,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 193,
            "שם_רחוב": "רח 74"
        },
        {
            "_id": 3184,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 175,
            "שם_רחוב": "רח 84"
        },
        {
            "_id": 3185,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 162,
            "שם_רחוב": "רח 94"
        },
        {
            "_id": 3186,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 167,
            "שם_רחוב": "רח 5"
        },
        {
            "_id": 3187,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 143,
            "שם_רחוב": "רח 105"
        },
        {
            "_id": 3188,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 249,
            "שם_רחוב": "רח 505"
        },
        {
            "_id": 3189,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 158,
            "שם_רחוב": "רח 15"
        },
        {
            "_id": 3190,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 127,
            "שם_רחוב": "רח 115"
        },
        {
            "_id": 3191,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 238,
            "שם_רחוב": "רח 25"
        },
        {
            "_id": 3192,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 212,
            "שם_רחוב": "רח 35"
        },
        {
            "_id": 3193,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 100,
            "שם_רחוב": "רח 45"
        },
        {
            "_id": 3194,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רח 55"
        },
        {
            "_id": 3195,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 243,
            "שם_רחוב": "רח 155"
        },
        {
            "_id": 3196,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רח 65"
        },
        {
            "_id": 3197,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 194,
            "שם_רחוב": "רח 75"
        },
        {
            "_id": 3198,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 184,
            "שם_רחוב": "רח 95"
        },
        {
            "_id": 3199,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 195,
            "שם_רחוב": "רח 195"
        },
        {
            "_id": 3200,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 168,
            "שם_רחוב": "רח 6"
        },
        {
            "_id": 3201,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 144,
            "שם_רחוב": "רח 106"
        },
        {
            "_id": 3202,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 230,
            "שם_רחוב": "רח 16"
        },
        {
            "_id": 3203,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 128,
            "שם_רחוב": "רח 116"
        },
        {
            "_id": 3204,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 239,
            "שם_רחוב": "רח 26"
        },
        {
            "_id": 3205,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רח 36"
        },
        {
            "_id": 3206,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "רח 46"
        },
        {
            "_id": 3207,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רח 56"
        },
        {
            "_id": 3208,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 222,
            "שם_רחוב": "רח 156"
        },
        {
            "_id": 3209,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רח 66"
        },
        {
            "_id": 3210,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 220,
            "שם_רחוב": "רח 76"
        },
        {
            "_id": 3211,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 176,
            "שם_רחוב": "רח 86"
        },
        {
            "_id": 3212,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 135,
            "שם_רחוב": "רח 96"
        },
        {
            "_id": 3213,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 145,
            "שם_רחוב": "רח 107"
        },
        {
            "_id": 3214,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 250,
            "שם_רחוב": "רח 507"
        },
        {
            "_id": 3215,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 263,
            "שם_רחוב": "רח 17"
        },
        {
            "_id": 3216,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 129,
            "שם_רחוב": "רח 117"
        },
        {
            "_id": 3217,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 240,
            "שם_רחוב": "רח 27"
        },
        {
            "_id": 3218,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 261,
            "שם_רחוב": "רח 37"
        },
        {
            "_id": 3219,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רח 47"
        },
        {
            "_id": 3220,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רח 57"
        },
        {
            "_id": 3221,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רח 157"
        },
        {
            "_id": 3222,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רח 67"
        },
        {
            "_id": 3223,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 196,
            "שם_רחוב": "רח 77"
        },
        {
            "_id": 3224,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 177,
            "שם_רחוב": "רח 87"
        },
        {
            "_id": 3225,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 136,
            "שם_רחוב": "רח 97"
        },
        {
            "_id": 3226,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 169,
            "שם_רחוב": "רח 8"
        },
        {
            "_id": 3227,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 146,
            "שם_רחוב": "רח 108"
        },
        {
            "_id": 3228,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 231,
            "שם_רחוב": "רח 18"
        },
        {
            "_id": 3229,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 130,
            "שם_רחוב": "רח 118"
        },
        {
            "_id": 3230,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 241,
            "שם_רחוב": "רח 28"
        },
        {
            "_id": 3231,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 214,
            "שם_רחוב": "רח 38"
        },
        {
            "_id": 3232,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רח 48"
        },
        {
            "_id": 3233,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רח 58"
        },
        {
            "_id": 3234,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רח 158"
        },
        {
            "_id": 3235,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רח 68"
        },
        {
            "_id": 3236,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 197,
            "שם_רחוב": "רח 78"
        },
        {
            "_id": 3237,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 178,
            "שם_רחוב": "רח 88"
        },
        {
            "_id": 3238,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 137,
            "שם_רחוב": "רח 98"
        },
        {
            "_id": 3239,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 170,
            "שם_רחוב": "רח 9"
        },
        {
            "_id": 3240,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 147,
            "שם_רחוב": "רח 109"
        },
        {
            "_id": 3241,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 232,
            "שם_רחוב": "רח 19"
        },
        {
            "_id": 3242,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 131,
            "שם_רחוב": "רח 119"
        },
        {
            "_id": 3243,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 206,
            "שם_רחוב": "רח 29"
        },
        {
            "_id": 3244,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 215,
            "שם_רחוב": "רח 39"
        },
        {
            "_id": 3245,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח 49"
        },
        {
            "_id": 3246,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רח 59"
        },
        {
            "_id": 3247,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 260,
            "שם_רחוב": "רח 159"
        },
        {
            "_id": 3248,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 198,
            "שם_רחוב": "רח 79"
        },
        {
            "_id": 3249,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 179,
            "שם_רחוב": "רח 89"
        },
        {
            "_id": 3250,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 138,
            "שם_רחוב": "רח 99"
        },
        {
            "_id": 3251,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "שכונה חדשה"
        },
        {
            "_id": 3252,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "שכונה מזרחית"
        },
        {
            "_id": 3253,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "שכונה מערבית"
        },
        {
            "_id": 3254,
            "סמל_ישוב": 478,
            "שם_ישוב": "אכסאל ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "שכונה צפונית"
        },
        {
            "_id": 3255,
            "סמל_ישוב": 1359,
            "שם_ישוב": "אל סייד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אל סייד"
        },
        {
            "_id": 3256,
            "סמל_ישוב": 1339,
            "שם_ישוב": "אל-עזי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אל-עזי"
        },
        {
            "_id": 3257,
            "סמל_ישוב": 1316,
            "שם_ישוב": "אל-עריאן ",
            "סמל_רחוב": 100,
            "שם_רחוב": "א-נהדה"
        },
        {
            "_id": 3258,
            "סמל_ישוב": 1316,
            "שם_ישוב": "אל-עריאן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "א-נרג'ס"
        },
        {
            "_id": 3259,
            "סמל_ישוב": 1316,
            "שם_ישוב": "אל-עריאן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "א-סלאם"
        },
        {
            "_id": 3260,
            "סמל_ישוב": 1316,
            "שם_ישוב": "אל-עריאן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "א-שיח'"
        },
        {
            "_id": 3261,
            "סמל_ישוב": 1316,
            "שם_ישוב": "אל-עריאן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אל-הודא"
        },
        {
            "_id": 3262,
            "סמל_ישוב": 1316,
            "שם_ישוב": "אל-עריאן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אל-וורוד"
        },
        {
            "_id": 3263,
            "סמל_ישוב": 1316,
            "שם_ישוב": "אל-עריאן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אל-עריאן"
        },
        {
            "_id": 3264,
            "סמל_ישוב": 4003,
            "שם_ישוב": "אל-רום ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אל-רום"
        },
        {
            "_id": 3265,
            "סמל_ישוב": 1145,
            "שם_ישוב": "אלומה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלומה"
        },
        {
            "_id": 3266,
            "סמל_ישוב": 1145,
            "שם_ישוב": "אלומה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "חזון יחזקאל"
        },
        {
            "_id": 3267,
            "סמל_ישוב": 330,
            "שם_ישוב": "אלומות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלומות"
        },
        {
            "_id": 3268,
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלון הגליל"
        },
        {
            "_id": 3269,
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך הרקפות"
        },
        {
            "_id": 3270,
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האלונים"
        },
        {
            "_id": 3271,
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 3272,
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 3273,
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "החצבים"
        },
        {
            "_id": 3274,
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "החקלאים"
        },
        {
            "_id": 3275,
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "המוביל"
        },
        {
            "_id": 3276,
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 3277,
            "סמל_ישוב": 1182,
            "שם_ישוב": "אלון הגליל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "השלף"
        },
        {
            "_id": 3278,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלון מורה"
        },
        {
            "_id": 3279,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ארץ חמדה"
        },
        {
            "_id": 3280,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "בשן"
        },
        {
            "_id": 3281,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 3282,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך שמונה עליות"
        },
        {
            "_id": 3283,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 3284,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "כנסת ישראל"
        },
        {
            "_id": 3285,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 3286,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "משעול הכרכום"
        },
        {
            "_id": 3287,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "משעול המור"
        },
        {
            "_id": 3288,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "משעול הציפורן"
        },
        {
            "_id": 3289,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "משעול הצרי"
        },
        {
            "_id": 3290,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "נווה תרצה"
        },
        {
            "_id": 3291,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "נצח ישראל"
        },
        {
            "_id": 3292,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 3293,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ רמי"
        },
        {
            "_id": 3294,
            "סמל_ישוב": 3579,
            "שם_ישוב": "אלון מורה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 3295,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אל ההר"
        },
        {
            "_id": 3296,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלון שבות"
        },
        {
            "_id": 3297,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 120,
            "שם_רחוב": "גבעת החיש"
        },
        {
            "_id": 3298,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 3299,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 122,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 3300,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 3301,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 3302,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 3303,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 123,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 3304,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הישיבה"
        },
        {
            "_id": 3305,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הלה"
        },
        {
            "_id": 3306,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 3307,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 3308,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 104,
            "שם_רחוב": "השיירות"
        },
        {
            "_id": 3309,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 3310,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 110,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 3311,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ישיבת הר עציון"
        },
        {
            "_id": 3312,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 119,
            "שם_רחוב": "כח באייר"
        },
        {
            "_id": 3313,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 118,
            "שם_רחוב": "מגדל עדר"
        },
        {
            "_id": 3314,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מגיני הגוש"
        },
        {
            "_id": 3315,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מעלה מיכאל"
        },
        {
            "_id": 3316,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 112,
            "שם_רחוב": "משעול הגפן"
        },
        {
            "_id": 3317,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 126,
            "שם_רחוב": "משעול הדקלים"
        },
        {
            "_id": 3318,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 108,
            "שם_רחוב": "משעול הורד"
        },
        {
            "_id": 3319,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 117,
            "שם_רחוב": "נצח ירושלים"
        },
        {
            "_id": 3320,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 115,
            "שם_רחוב": "קבוצת אברהם"
        },
        {
            "_id": 3321,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ גבעות"
        },
        {
            "_id": 3322,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ גבעת הברכה"
        },
        {
            "_id": 3323,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ גבעת העץ"
        },
        {
            "_id": 3324,
            "סמל_ישוב": 3604,
            "שם_ישוב": "אלון שבות ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ משתלות"
        },
        {
            "_id": 3325,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלוני אבא"
        },
        {
            "_id": 3326,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 3327,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 117,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 3328,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האלונים"
        },
        {
            "_id": 3329,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 3330,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 3331,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 3332,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 3333,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 119,
            "שם_רחוב": "החוה"
        },
        {
            "_id": 3334,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 115,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 3335,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 109,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 3336,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הכלניות"
        },
        {
            "_id": 3337,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הכרמים"
        },
        {
            "_id": 3338,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הסמטה הירוקה"
        },
        {
            "_id": 3339,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 120,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 3340,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 116,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 3341,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הרמון"
        },
        {
            "_id": 3342,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הרקפות"
        },
        {
            "_id": 3343,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 118,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 3344,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 110,
            "שם_רחוב": "סמטת הדקלים"
        },
        {
            "_id": 3345,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 121,
            "שם_רחוב": "סמטת הצבר"
        },
        {
            "_id": 3346,
            "סמל_ישוב": 429,
            "שם_ישוב": "אלוני אבא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שדרת הברושים"
        },
        {
            "_id": 3347,
            "סמל_ישוב": 4017,
            "שם_ישוב": "אלוני הבשן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלוני הבשן"
        },
        {
            "_id": 3348,
            "סמל_ישוב": 868,
            "שם_ישוב": "אלוני יצחק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלוני יצחק"
        },
        {
            "_id": 3349,
            "סמל_ישוב": 868,
            "שם_ישוב": "אלוני יצחק ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "כפר הנוער"
        },
        {
            "_id": 3350,
            "סמל_ישוב": 285,
            "שם_ישוב": "אלונים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלונים"
        },
        {
            "_id": 3351,
            "סמל_ישוב": 4002,
            "שם_ישוב": "אלי-עד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלי-עד"
        },
        {
            "_id": 3352,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אליאב"
        },
        {
            "_id": 3353,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אשחר"
        },
        {
            "_id": 3354,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האטד"
        },
        {
            "_id": 3355,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 3356,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 3357,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 3358,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הלוטם"
        },
        {
            "_id": 3359,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הליבנה"
        },
        {
            "_id": 3360,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 3361,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 3362,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 109,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 3363,
            "סמל_ישוב": 1365,
            "שם_ישוב": "אליאב ",
            "סמל_רחוב": 100,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 3364,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אליכין"
        },
        {
            "_id": 3365,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 107,
            "שם_רחוב": "בשמת"
        },
        {
            "_id": 3366,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גבעת הבריכה"
        },
        {
            "_id": 3367,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 124,
            "שם_רחוב": "האיה"
        },
        {
            "_id": 3368,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 108,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 3369,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 121,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 3370,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 3371,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 3372,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 3373,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 3374,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 114,
            "שם_רחוב": "החוגלה"
        },
        {
            "_id": 3375,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 115,
            "שם_רחוב": "החוחית"
        },
        {
            "_id": 3376,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 113,
            "שם_רחוב": "החופית"
        },
        {
            "_id": 3377,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 112,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 3378,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 111,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 3379,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 154,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 3380,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 110,
            "שם_רחוב": "היסעור"
        },
        {
            "_id": 3381,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 3382,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 137,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 3383,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 3384,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הנחליאלי"
        },
        {
            "_id": 3385,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 155,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 3386,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 3387,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הסחלב"
        },
        {
            "_id": 3388,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הסייפן"
        },
        {
            "_id": 3389,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הסלעית"
        },
        {
            "_id": 3390,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הסנונית"
        },
        {
            "_id": 3391,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 130,
            "שם_רחוב": "העפרוני"
        },
        {
            "_id": 3392,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הפשוש"
        },
        {
            "_id": 3393,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 131,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 3394,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הצופית"
        },
        {
            "_id": 3395,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הצפורן"
        },
        {
            "_id": 3396,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 152,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 3397,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 156,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 3398,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרשש"
        },
        {
            "_id": 3399,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 134,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 3400,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 116,
            "שם_רחוב": "השלדג"
        },
        {
            "_id": 3401,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 151,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 3402,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 135,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 3403,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 105,
            "שם_רחוב": "חבצלת השרון"
        },
        {
            "_id": 3404,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 103,
            "שם_רחוב": "כנפי נשרים"
        },
        {
            "_id": 3405,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 3406,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 138,
            "שם_רחוב": "סיגלית"
        },
        {
            "_id": 3407,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 3408,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 3409,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ האלונים"
        },
        {
            "_id": 3410,
            "סמל_ישוב": 41,
            "שם_ישוב": "אליכין ",
            "סמל_רחוב": 136,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 3411,
            "סמל_ישוב": 1248,
            "שם_ישוב": "אליפז ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אליפז"
        },
        {
            "_id": 3412,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אליפלט"
        },
        {
            "_id": 3413,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 112,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 3414,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 3415,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 3416,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 3417,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 3418,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 3419,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 3420,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 3421,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 3422,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 104,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 3423,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 3424,
            "סמל_ישוב": 730,
            "שם_ישוב": "אליפלט ",
            "סמל_רחוב": 111,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 3425,
            "סמל_ישוב": 682,
            "שם_ישוב": "אליקים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אליקים"
        },
        {
            "_id": 3426,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלישיב"
        },
        {
            "_id": 3427,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הבאר"
        },
        {
            "_id": 3428,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הגבעה"
        },
        {
            "_id": 3429,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 3430,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 3431,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 3432,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 112,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 3433,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 111,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 3434,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 3435,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 3436,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 3437,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 109,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 3438,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 3439,
            "סמל_ישוב": 204,
            "שם_ישוב": "אלישיב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 3440,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלישמע"
        },
        {
            "_id": 3441,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 3442,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 3443,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 3444,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "הרחבה"
        },
        {
            "_id": 3445,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 3446,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 102,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 3447,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 114,
            "שם_רחוב": "סמ אורן"
        },
        {
            "_id": 3448,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 119,
            "שם_רחוב": "סמ אלה"
        },
        {
            "_id": 3449,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 117,
            "שם_רחוב": "סמ אלון"
        },
        {
            "_id": 3450,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 118,
            "שם_רחוב": "סמ אקליפטוס"
        },
        {
            "_id": 3451,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 113,
            "שם_רחוב": "סמ ברוש"
        },
        {
            "_id": 3452,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 110,
            "שם_רחוב": "סמ הפקאן"
        },
        {
            "_id": 3453,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 115,
            "שם_רחוב": "סמ זית"
        },
        {
            "_id": 3454,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 112,
            "שם_רחוב": "סמ חרוב"
        },
        {
            "_id": 3455,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 111,
            "שם_רחוב": "סמ צאלון"
        },
        {
            "_id": 3456,
            "סמל_ישוב": 841,
            "שם_ישוב": "אלישמע ",
            "סמל_רחוב": 116,
            "שם_רחוב": "סמ תמר"
        },
        {
            "_id": 3457,
            "סמל_ישוב": 1125,
            "שם_ישוב": "אלמגור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלמגור"
        },
        {
            "_id": 3458,
            "סמל_ישוב": 3556,
            "שם_ישוב": "אלמוג ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 3459,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אבטליון"
        },
        {
            "_id": 3460,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 3461,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 3462,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלעד"
        },
        {
            "_id": 3463,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 210,
            "שם_רחוב": "בעלי התוספות"
        },
        {
            "_id": 3464,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 223,
            "שם_רחוב": "הבח"
        },
        {
            "_id": 3465,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 3466,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הלל"
        },
        {
            "_id": 3467,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 110,
            "שם_רחוב": "המאירי"
        },
        {
            "_id": 3468,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 224,
            "שם_רחוב": "המהרשל"
        },
        {
            "_id": 3469,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 225,
            "שם_רחוב": "הרן"
        },
        {
            "_id": 3470,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הראש"
        },
        {
            "_id": 3471,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 218,
            "שם_רחוב": "הריף"
        },
        {
            "_id": 3472,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 3473,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 127,
            "שם_רחוב": "התור"
        },
        {
            "_id": 3474,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 103,
            "שם_רחוב": "חוני המעגל"
        },
        {
            "_id": 3475,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 109,
            "שם_רחוב": "סעדיה גאון"
        },
        {
            "_id": 3476,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 217,
            "שם_רחוב": "עובדיה מברטנורה"
        },
        {
            "_id": 3477,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 219,
            "שם_רחוב": "ראבד"
        },
        {
            "_id": 3478,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רבי חייא"
        },
        {
            "_id": 3479,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רבי טרפון"
        },
        {
            "_id": 3480,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רבי יהודה הלוי"
        },
        {
            "_id": 3481,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רבי יהודה הנשיא"
        },
        {
            "_id": 3482,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 221,
            "שם_רחוב": "רבי יהושע"
        },
        {
            "_id": 3483,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 203,
            "שם_רחוב": "רבי יונתן בן עוזיאל"
        },
        {
            "_id": 3484,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 204,
            "שם_רחוב": "רבי יוסי בן קיסמא"
        },
        {
            "_id": 3485,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רבי יוסף קארו"
        },
        {
            "_id": 3486,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 201,
            "שם_רחוב": "רבי מאיר"
        },
        {
            "_id": 3487,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 3488,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 202,
            "שם_רחוב": "רבי פנחס בן יאיר"
        },
        {
            "_id": 3489,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רבי שמואל הנגיד"
        },
        {
            "_id": 3490,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 215,
            "שם_רחוב": "רבינו בחיי"
        },
        {
            "_id": 3491,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 214,
            "שם_רחוב": "רבינו גרשום"
        },
        {
            "_id": 3492,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 212,
            "שם_רחוב": "רבינו חננאל"
        },
        {
            "_id": 3493,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 222,
            "שם_רחוב": "רבן גמליאל"
        },
        {
            "_id": 3494,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רבן יוחנן בן זכאי"
        },
        {
            "_id": 3495,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "רבנו ניסים גאון"
        },
        {
            "_id": 3496,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רבנו תם"
        },
        {
            "_id": 3497,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רדק"
        },
        {
            "_id": 3498,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "רובע A"
        },
        {
            "_id": 3499,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "רובע B"
        },
        {
            "_id": 3500,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "רובע C"
        },
        {
            "_id": 3501,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "רובע D"
        },
        {
            "_id": 3502,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "רובע E"
        },
        {
            "_id": 3503,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "רובע F"
        },
        {
            "_id": 3504,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "רובע G"
        },
        {
            "_id": 3505,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "רובע H"
        },
        {
            "_id": 3506,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 122,
            "שם_רחוב": "ריטבא"
        },
        {
            "_id": 3507,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 226,
            "שם_רחוב": "רמה"
        },
        {
            "_id": 3508,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 3509,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 207,
            "שם_רחוב": "רמבן"
        },
        {
            "_id": 3510,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 208,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 3511,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 206,
            "שם_רחוב": "רשבי"
        },
        {
            "_id": 3512,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 121,
            "שם_רחוב": "רשבם"
        },
        {
            "_id": 3513,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ מרומי אלעד"
        },
        {
            "_id": 3514,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שמאי"
        },
        {
            "_id": 3515,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 216,
            "שם_רחוב": "שמעון בן שטח"
        },
        {
            "_id": 3516,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 205,
            "שם_רחוב": "שמעון הצדיק"
        },
        {
            "_id": 3517,
            "סמל_ישוב": 1309,
            "שם_ישוב": "אלעד ",
            "סמל_רחוב": 125,
            "שם_רחוב": "שמעיה"
        },
        {
            "_id": 3518,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלעזר"
        },
        {
            "_id": 3519,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "בית זכריה"
        },
        {
            "_id": 3520,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "בית צור"
        },
        {
            "_id": 3521,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "גופנה"
        },
        {
            "_id": 3522,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "גוש קטיף"
        },
        {
            "_id": 3523,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "גן אור"
        },
        {
            "_id": 3524,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "גני טל"
        },
        {
            "_id": 3525,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דרך נתיב האבות"
        },
        {
            "_id": 3526,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "חשמונאים"
        },
        {
            "_id": 3527,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 3528,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "יהודית"
        },
        {
            "_id": 3529,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "יהונתן"
        },
        {
            "_id": 3530,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "יוחנן"
        },
        {
            "_id": 3531,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 3532,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מעלה בית חורון"
        },
        {
            "_id": 3533,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מתתיהו"
        },
        {
            "_id": 3534,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "נווה דקלים"
        },
        {
            "_id": 3535,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ניסנית"
        },
        {
            "_id": 3536,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "נצר חזני"
        },
        {
            "_id": 3537,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "נצרים"
        },
        {
            "_id": 3538,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ נתיב האבות"
        },
        {
            "_id": 3539,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שלומציון"
        },
        {
            "_id": 3540,
            "סמל_ישוב": 3618,
            "שם_ישוב": "אלעזר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שמעון"
        },
        {
            "_id": 3541,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אדיר"
        },
        {
            "_id": 3542,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 3543,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "אזור תעשייה"
        },
        {
            "_id": 3544,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אילון"
        },
        {
            "_id": 3545,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 3546,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלפי מנשה"
        },
        {
            "_id": 3547,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 3548,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 3549,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 3550,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "בשור"
        },
        {
            "_id": 3551,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "גבעת טל"
        },
        {
            "_id": 3552,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "גולן"
        },
        {
            "_id": 3553,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 3554,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "גליל"
        },
        {
            "_id": 3555,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 3556,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 3557,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "גרניט"
        },
        {
            "_id": 3558,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דגנית"
        },
        {
            "_id": 3559,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 3560,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "דם המכבים"
        },
        {
            "_id": 3561,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "דן"
        },
        {
            "_id": 3562,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 3563,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "דרך חסדאי אליעזר"
        },
        {
            "_id": 3564,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 3565,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 3566,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 3567,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 3568,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "השפלה"
        },
        {
            "_id": 3569,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 3570,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 3571,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 3572,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "חלוצי התעשיה"
        },
        {
            "_id": 3573,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 3574,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 3575,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 3576,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 3577,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 3578,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 3579,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "ירדן"
        },
        {
            "_id": 3580,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 3581,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "כנען"
        },
        {
            "_id": 3582,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 3583,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 3584,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 3585,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "לבנה"
        },
        {
            "_id": 3586,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 3587,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 3588,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 3589,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 3590,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "מנור"
        },
        {
            "_id": 3591,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 3592,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "משעול סייפן"
        },
        {
            "_id": 3593,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "נגב"
        },
        {
            "_id": 3594,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 3595,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 3596,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 3597,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 3598,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "סמ תבור"
        },
        {
            "_id": 3599,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "סמטת ענבר"
        },
        {
            "_id": 3600,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 3601,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 3602,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 3603,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "עצמון"
        },
        {
            "_id": 3604,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 3605,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "פולג"
        },
        {
            "_id": 3606,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "פשוש"
        },
        {
            "_id": 3607,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 3608,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 3609,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "קדרון"
        },
        {
            "_id": 3610,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רביד"
        },
        {
            "_id": 3611,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 3612,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 3613,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שגיא"
        },
        {
            "_id": 3614,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "שובל"
        },
        {
            "_id": 3615,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "שומרון"
        },
        {
            "_id": 3616,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "שורק"
        },
        {
            "_id": 3617,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ יובלים"
        },
        {
            "_id": 3618,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ כפיר יוסף"
        },
        {
            "_id": 3619,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ נופיה"
        },
        {
            "_id": 3620,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 3621,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 3622,
            "סמל_ישוב": 3750,
            "שם_ישוב": "אלפי מנשה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 3623,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלקוש"
        },
        {
            "_id": 3624,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 112,
            "שם_רחוב": "דרך הבציר"
        },
        {
            "_id": 3625,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דרך הוואדי"
        },
        {
            "_id": 3626,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דרך החריש"
        },
        {
            "_id": 3627,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דרך המסיק"
        },
        {
            "_id": 3628,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך הרגבים"
        },
        {
            "_id": 3629,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך התאנה"
        },
        {
            "_id": 3630,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך התלמים"
        },
        {
            "_id": 3631,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 3632,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הר אדיר"
        },
        {
            "_id": 3633,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הר חירם"
        },
        {
            "_id": 3634,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הר מתת"
        },
        {
            "_id": 3635,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 3636,
            "סמל_ישוב": 603,
            "שם_ישוב": "אלקוש ",
            "סמל_רחוב": 110,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 3637,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אבקת רוכל"
        },
        {
            "_id": 3638,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אדני פז"
        },
        {
            "_id": 3639,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אהלי קידר"
        },
        {
            "_id": 3640,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "איבי הנחל"
        },
        {
            "_id": 3641,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אילות השדה"
        },
        {
            "_id": 3642,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלף המגן"
        },
        {
            "_id": 3643,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אלקנה"
        },
        {
            "_id": 3644,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אעלה בתמר"
        },
        {
            "_id": 3645,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אפיקי מים"
        },
        {
            "_id": 3646,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "ארזים"
        },
        {
            "_id": 3647,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אשכולות הגפן"
        },
        {
            "_id": 3648,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "בנות ירושלים"
        },
        {
            "_id": 3649,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "גבורי ישראל"
        },
        {
            "_id": 3650,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "גבעת הלבונה"
        },
        {
            "_id": 3651,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "גינת אגוז"
        },
        {
            "_id": 3652,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 3653,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "הר גלעד"
        },
        {
            "_id": 3654,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "הר המור"
        },
        {
            "_id": 3655,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הרי בשמים"
        },
        {
            "_id": 3656,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "חבצלת השרון"
        },
        {
            "_id": 3657,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "חגוי הסלע"
        },
        {
            "_id": 3658,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "חוט השני"
        },
        {
            "_id": 3659,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 3660,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "טורי זהב"
        },
        {
            "_id": 3661,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 3662,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 3663,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מגדל דוד"
        },
        {
            "_id": 3664,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "מגדל הלבנון"
        },
        {
            "_id": 3665,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "מגן דן"
        },
        {
            "_id": 3666,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "מחניים"
        },
        {
            "_id": 3667,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "מים חיים"
        },
        {
            "_id": 3668,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "מכללת אורות"
        },
        {
            "_id": 3669,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "מעין גנים"
        },
        {
            "_id": 3670,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "מעלה ארגמן"
        },
        {
            "_id": 3671,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "משכנות הרועים"
        },
        {
            "_id": 3672,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "משעול ספיר"
        },
        {
            "_id": 3673,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "משעול תרשיש"
        },
        {
            "_id": 3674,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "ניצנים"
        },
        {
            "_id": 3675,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 3676,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "עורי צפון"
        },
        {
            "_id": 3677,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 3678,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "עמודי שיש"
        },
        {
            "_id": 3679,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "עקבי הצאן"
        },
        {
            "_id": 3680,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "ערוגות הבושם"
        },
        {
            "_id": 3681,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "עת הזמיר"
        },
        {
            "_id": 3682,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "פלח הרימון"
        },
        {
            "_id": 3683,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "פרדס רימונים"
        },
        {
            "_id": 3684,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "פרי מגדים"
        },
        {
            "_id": 3685,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "צרור המור"
        },
        {
            "_id": 3686,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "קול התור"
        },
        {
            "_id": 3687,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "קינמון"
        },
        {
            "_id": 3688,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "קרית חינוך"
        },
        {
            "_id": 3689,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רשפי אש"
        },
        {
            "_id": 3690,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "שדרות שלמה המלך"
        },
        {
            "_id": 3691,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "שומרי החומות"
        },
        {
            "_id": 3692,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "שושנים"
        },
        {
            "_id": 3693,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שושנת העמקים"
        },
        {
            "_id": 3694,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שיר השירים"
        },
        {
            "_id": 3695,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "שלטי גבורים"
        },
        {
            "_id": 3696,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 3697,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 3698,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "תלפיות"
        },
        {
            "_id": 3699,
            "סמל_ישוב": 3560,
            "שם_ישוב": "אלקנה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "תפוח"
        },
        {
            "_id": 3700,
            "סמל_ישוב": 772,
            "שם_ישוב": "אמונים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אמונים"
        },
        {
            "_id": 3701,
            "סמל_ישוב": 772,
            "שם_ישוב": "אמונים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 3702,
            "סמל_ישוב": 772,
            "שם_ישוב": "אמונים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 3703,
            "סמל_ישוב": 772,
            "שם_ישוב": "אמונים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 3704,
            "סמל_ישוב": 772,
            "שם_ישוב": "אמונים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 3705,
            "סמל_ישוב": 772,
            "שם_ישוב": "אמונים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 3706,
            "סמל_ישוב": 772,
            "שם_ישוב": "אמונים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 3707,
            "סמל_ישוב": 1064,
            "שם_ישוב": "אמירים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אמירים"
        },
        {
            "_id": 3708,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אמנון"
        },
        {
            "_id": 3709,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 3710,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 3711,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 3712,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 3713,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הפיקוס"
        },
        {
            "_id": 3714,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 3715,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 3716,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "השיקמה"
        },
        {
            "_id": 3717,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 3718,
            "סמל_ישוב": 1253,
            "שם_ישוב": "אמנון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 3719,
            "סמל_ישוב": 23,
            "שם_ישוב": "אמציה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אמציה"
        },
        {
            "_id": 3720,
            "סמל_ישוב": 4012,
            "שם_ישוב": "אניעם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אניעם"
        },
        {
            "_id": 3721,
            "סמל_ישוב": 960,
            "שם_ישוב": "אסד (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אסד (שבט)"
        },
        {
            "_id": 3722,
            "סמל_ישוב": 3754,
            "שם_ישוב": "אספר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אמרי מרדכי"
        },
        {
            "_id": 3723,
            "סמל_ישוב": 3754,
            "שם_ישוב": "אספר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אספר"
        },
        {
            "_id": 3724,
            "סמל_ישוב": 3754,
            "שם_ישוב": "אספר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "נחל דוד"
        },
        {
            "_id": 3725,
            "סמל_ישוב": 3754,
            "שם_ישוב": "אספר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "נחל דרגה"
        },
        {
            "_id": 3726,
            "סמל_ישוב": 3754,
            "שם_ישוב": "אספר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נחל עמוס"
        },
        {
            "_id": 3727,
            "סמל_ישוב": 3754,
            "שם_ישוב": "אספר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 3728,
            "סמל_ישוב": 3754,
            "שם_ישוב": "אספר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נחל קדם"
        },
        {
            "_id": 3729,
            "סמל_ישוב": 3754,
            "שם_ישוב": "אספר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נחל תקוע"
        },
        {
            "_id": 3730,
            "סמל_ישוב": 3754,
            "שם_ישוב": "אספר ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ פני קדם"
        },
        {
            "_id": 3731,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "אבו ח'רביש"
        },
        {
            "_id": 3732,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "אבו נח'לה"
        },
        {
            "_id": 3733,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "אל רג'ום"
        },
        {
            "_id": 3734,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6724,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 3735,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "אלג'בל"
        },
        {
            "_id": 3736,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "אלג'למה"
        },
        {
            "_id": 3737,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6737,
            "שם_רחוב": "אלח'לה"
        },
        {
            "_id": 3738,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "אלכרום"
        },
        {
            "_id": 3739,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "אלפס"
        },
        {
            "_id": 3740,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6716,
            "שם_רחוב": "אלשמאלי"
        },
        {
            "_id": 3741,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6733,
            "שם_רחוב": "אם אלשתויה"
        },
        {
            "_id": 3742,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אעבלין"
        },
        {
            "_id": 3743,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6735,
            "שם_רחוב": "בח'ית"
        },
        {
            "_id": 3744,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "בין אלג'לאים"
        },
        {
            "_id": 3745,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "בלישה"
        },
        {
            "_id": 3746,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "גרעין הכפר"
        },
        {
            "_id": 3747,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6736,
            "שם_רחוב": "ואדי אלסהילה"
        },
        {
            "_id": 3748,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6734,
            "שם_רחוב": "מקתול"
        },
        {
            "_id": 3749,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6715,
            "שם_רחוב": "סירת אלעג'אל"
        },
        {
            "_id": 3750,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6738,
            "שם_רחוב": "עין אלתינה"
        },
        {
            "_id": 3751,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "ראס נעמה"
        },
        {
            "_id": 3752,
            "סמל_ישוב": 529,
            "שם_ישוב": "אעבלין ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "שכונת מגרש כדורגל"
        },
        {
            "_id": 3753,
            "סמל_ישוב": 963,
            "שם_ישוב": "אעצם (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אעצם (שבט)"
        },
        {
            "_id": 3754,
            "סמל_ישוב": 959,
            "שם_ישוב": "אפיניש (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אפיניש (שבט)"
        },
        {
            "_id": 3755,
            "סמל_ישוב": 4301,
            "שם_ישוב": "אפיק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אפיק"
        },
        {
            "_id": 3756,
            "סמל_ישוב": 176,
            "שם_ישוב": "אפיקים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אפיקים"
        },
        {
            "_id": 3757,
            "סמל_ישוב": 313,
            "שם_ישוב": "אפק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אפק"
        },
        {
            "_id": 3758,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 3759,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אפרת"
        },
        {
            "_id": 3760,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אריאל"
        },
        {
            "_id": 3761,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 134,
            "שם_רחוב": "בועז"
        },
        {
            "_id": 3762,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 170,
            "שם_רחוב": "בית הבחירה"
        },
        {
            "_id": 3763,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 197,
            "שם_רחוב": "בת-שבע"
        },
        {
            "_id": 3764,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "גבעת הזית"
        },
        {
            "_id": 3765,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 167,
            "שם_רחוב": "דביר"
        },
        {
            "_id": 3766,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 162,
            "שם_רחוב": "האלומה"
        },
        {
            "_id": 3767,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הגואל"
        },
        {
            "_id": 3768,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 3769,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 3770,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 169,
            "שם_רחוב": "הדבש"
        },
        {
            "_id": 3771,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 163,
            "שם_רחוב": "הדגן"
        },
        {
            "_id": 3772,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 3773,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 159,
            "שם_רחוב": "הזורעים"
        },
        {
            "_id": 3774,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 164,
            "שם_רחוב": "החריש"
        },
        {
            "_id": 3775,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הכרמים"
        },
        {
            "_id": 3776,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 137,
            "שם_רחוב": "הלה"
        },
        {
            "_id": 3777,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 152,
            "שם_רחוב": "המנורה"
        },
        {
            "_id": 3778,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 124,
            "שם_רחוב": "המעיין"
        },
        {
            "_id": 3779,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 160,
            "שם_רחוב": "העומר"
        },
        {
            "_id": 3780,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 156,
            "שם_רחוב": "הציפורן"
        },
        {
            "_id": 3781,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 161,
            "שם_רחוב": "הקציר"
        },
        {
            "_id": 3782,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 126,
            "שם_רחוב": "השיירות"
        },
        {
            "_id": 3783,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 120,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 3784,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 118,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 3785,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 149,
            "שם_רחוב": "זית שמן"
        },
        {
            "_id": 3786,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 139,
            "שם_רחוב": "זכריה"
        },
        {
            "_id": 3787,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 146,
            "שם_רחוב": "זרובבל"
        },
        {
            "_id": 3788,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 143,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 3789,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 158,
            "שם_רחוב": "חזקיהו המלך"
        },
        {
            "_id": 3790,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 151,
            "שם_רחוב": "חנה"
        },
        {
            "_id": 3791,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 199,
            "שם_רחוב": "ידידיה"
        },
        {
            "_id": 3792,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 148,
            "שם_רחוב": "יהודה המכבי"
        },
        {
            "_id": 3793,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 147,
            "שם_רחוב": "יונתן החשמונאי"
        },
        {
            "_id": 3794,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 172,
            "שם_רחוב": "כורש"
        },
        {
            "_id": 3795,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מבוא אלון"
        },
        {
            "_id": 3796,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מבוא הדס"
        },
        {
            "_id": 3797,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 122,
            "שם_רחוב": "מבוא הדר"
        },
        {
            "_id": 3798,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 182,
            "שם_רחוב": "מבוא ההר הטוב"
        },
        {
            "_id": 3799,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "מבוא חרוב"
        },
        {
            "_id": 3800,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 178,
            "שם_רחוב": "מבוא יצהר"
        },
        {
            "_id": 3801,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 183,
            "שם_רחוב": "מבוא ניקנור"
        },
        {
            "_id": 3802,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מבוא ערבה"
        },
        {
            "_id": 3803,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 142,
            "שם_רחוב": "מגדל עדר"
        },
        {
            "_id": 3804,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 181,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 3805,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 136,
            "שם_רחוב": "מורית"
        },
        {
            "_id": 3806,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 204,
            "שם_רחוב": "מיכל"
        },
        {
            "_id": 3807,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 192,
            "שם_רחוב": "מעלה אביב"
        },
        {
            "_id": 3808,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מעלה אורן"
        },
        {
            "_id": 3809,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מעלה ברוש"
        },
        {
            "_id": 3810,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 190,
            "שם_רחוב": "מעלה גבעול"
        },
        {
            "_id": 3811,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 195,
            "שם_רחוב": "מעלה הביכורים"
        },
        {
            "_id": 3812,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מעלה הזית"
        },
        {
            "_id": 3813,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 194,
            "שם_רחוב": "מעלה הלקט"
        },
        {
            "_id": 3814,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 188,
            "שם_רחוב": "מעלה המגל"
        },
        {
            "_id": 3815,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 189,
            "שם_רחוב": "מעלה המורג"
        },
        {
            "_id": 3816,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 193,
            "שם_רחוב": "מעלה המנחה"
        },
        {
            "_id": 3817,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 177,
            "שם_רחוב": "מעלה הסלע"
        },
        {
            "_id": 3818,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 171,
            "שם_רחוב": "מעלה השקד"
        },
        {
            "_id": 3819,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 187,
            "שם_רחוב": "מעלה התלם"
        },
        {
            "_id": 3820,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מעלה יערה"
        },
        {
            "_id": 3821,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 127,
            "שם_רחוב": "מעלה כפר עציון"
        },
        {
            "_id": 3822,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 191,
            "שם_רחוב": "מעלה כרמל"
        },
        {
            "_id": 3823,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מעלה לוטם"
        },
        {
            "_id": 3824,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 131,
            "שם_רחוב": "מעלה משואות יצחק"
        },
        {
            "_id": 3825,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 141,
            "שם_רחוב": "מעלה עין צורים"
        },
        {
            "_id": 3826,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 112,
            "שם_רחוב": "מעלה קטלב"
        },
        {
            "_id": 3827,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 138,
            "שם_רחוב": "מעלה רבדים"
        },
        {
            "_id": 3828,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 121,
            "שם_רחוב": "מעלה שיבולת"
        },
        {
            "_id": 3829,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מעלה תדהר"
        },
        {
            "_id": 3830,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 202,
            "שם_רחוב": "מרים"
        },
        {
            "_id": 3831,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 153,
            "שם_רחוב": "מתתיהו הכהן"
        },
        {
            "_id": 3832,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 175,
            "שם_רחוב": "נחל אשכול"
        },
        {
            "_id": 3833,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 145,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 3834,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 203,
            "שם_רחוב": "נחשון"
        },
        {
            "_id": 3835,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 155,
            "שם_רחוב": "נטף"
        },
        {
            "_id": 3836,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 133,
            "שם_רחוב": "נעמי"
        },
        {
            "_id": 3837,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 128,
            "שם_רחוב": "נצח ירושלים"
        },
        {
            "_id": 3838,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 129,
            "שם_רחוב": "נצר ישי"
        },
        {
            "_id": 3839,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 176,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 3840,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 157,
            "שם_רחוב": "עוזיהו המלך"
        },
        {
            "_id": 3841,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 144,
            "שם_רחוב": "עזרא"
        },
        {
            "_id": 3842,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 205,
            "שם_רחוב": "עמינדב"
        },
        {
            "_id": 3843,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 186,
            "שם_רחוב": "עץ החיים"
        },
        {
            "_id": 3844,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 154,
            "שם_רחוב": "פיטום הקטורת"
        },
        {
            "_id": 3845,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 201,
            "שם_רחוב": "קהלת"
        },
        {
            "_id": 3846,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "קרית המבתר"
        },
        {
            "_id": 3847,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 130,
            "שם_רחוב": "רות"
        },
        {
            "_id": 3848,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 150,
            "שם_רחוב": "רחל אמנו"
        },
        {
            "_id": 3849,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 3850,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 173,
            "שם_רחוב": "שאלתיאל"
        },
        {
            "_id": 3851,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 180,
            "שם_רחוב": "שבות ישראל"
        },
        {
            "_id": 3852,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 135,
            "שם_רחוב": "שד המלך דוד"
        },
        {
            "_id": 3853,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 185,
            "שם_רחוב": "שד המלך שלמה"
        },
        {
            "_id": 3854,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 140,
            "שם_רחוב": "שיבת ציון"
        },
        {
            "_id": 3855,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 198,
            "שם_רחוב": "שיר השירים"
        },
        {
            "_id": 3856,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ גבעת רימון"
        },
        {
            "_id": 3857,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ דקל"
        },
        {
            "_id": 3858,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ הגפן"
        },
        {
            "_id": 3859,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ הדגן"
        },
        {
            "_id": 3860,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "שכ תאנה"
        },
        {
            "_id": 3861,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ תמר"
        },
        {
            "_id": 3862,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 184,
            "שם_רחוב": "שלם"
        },
        {
            "_id": 3863,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 119,
            "שם_רחוב": "תירוש"
        },
        {
            "_id": 3864,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 166,
            "שם_רחוב": "תלפיות"
        },
        {
            "_id": 3865,
            "סמל_ישוב": 3650,
            "שם_ישוב": "אפרת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 3866,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 3867,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 3868,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האלונים"
        },
        {
            "_id": 3869,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 3870,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 3871,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 3872,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 3873,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הנוטעים"
        },
        {
            "_id": 3874,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 3875,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 3876,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 3877,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 3878,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 3879,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 3880,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "כיכר האילנות"
        },
        {
            "_id": 3881,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר המייסדים"
        },
        {
            "_id": 3882,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מורד הצבר"
        },
        {
            "_id": 3883,
            "סמל_ישוב": 701,
            "שם_ישוב": "ארבל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שדרת הברושים"
        },
        {
            "_id": 3884,
            "סמל_ישוב": 3598,
            "שם_ישוב": "ארגמן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ארגמן"
        },
        {
            "_id": 3885,
            "סמל_ישוב": 714,
            "שם_ישוב": "ארז ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 3886,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אבנר"
        },
        {
            "_id": 3887,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אדום"
        },
        {
            "_id": 3888,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "אזור תעשיה מערב"
        },
        {
            "_id": 3889,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אילות"
        },
        {
            "_id": 3890,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 3891,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 258,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 3892,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אריאל"
        },
        {
            "_id": 3893,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 251,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 3894,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 142,
            "שם_רחוב": "בית אל"
        },
        {
            "_id": 3895,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 133,
            "שם_רחוב": "בית לחם"
        },
        {
            "_id": 3896,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "בקעת הירדן"
        },
        {
            "_id": 3897,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 204,
            "שם_רחוב": "בראון אורי"
        },
        {
            "_id": 3898,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 3899,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 2596,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 3900,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "גדעון"
        },
        {
            "_id": 3901,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 145,
            "שם_רחוב": "גילה"
        },
        {
            "_id": 3902,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 306,
            "שם_רחוב": "גנים"
        },
        {
            "_id": 3903,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דבורה"
        },
        {
            "_id": 3904,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 312,
            "שם_רחוב": "דן"
        },
        {
            "_id": 3905,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "דרך אפרתה"
        },
        {
            "_id": 3906,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 202,
            "שם_רחוב": "דרך הנחשונים"
        },
        {
            "_id": 3907,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הציונות"
        },
        {
            "_id": 3908,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 303,
            "שם_רחוב": "דרך עפרון"
        },
        {
            "_id": 3909,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 150,
            "שם_רחוב": "דרך רמת הגולן"
        },
        {
            "_id": 3910,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 208,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 3911,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 205,
            "שם_רחוב": "האצל"
        },
        {
            "_id": 3912,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 209,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 3913,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 317,
            "שם_רחוב": "הבנאי"
        },
        {
            "_id": 3914,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 138,
            "שם_רחוב": "הבשן"
        },
        {
            "_id": 3915,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 136,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 3916,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 3917,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 137,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 3918,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 3919,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 206,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 3920,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 307,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 3921,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 130,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 3922,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 311,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 3923,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 309,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 3924,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 314,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 3925,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 316,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 3926,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 213,
            "שם_רחוב": "המציל היהודי"
        },
        {
            "_id": 3927,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 3928,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 304,
            "שם_רחוב": "הסביונים"
        },
        {
            "_id": 3929,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 313,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 3930,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 315,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 3931,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 210,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 3932,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 3933,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 203,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 3934,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הקוממיות"
        },
        {
            "_id": 3935,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 148,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 3936,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 308,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 3937,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 131,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 3938,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 207,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 3939,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 135,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 3940,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 132,
            "שם_רחוב": "חברון"
        },
        {
            "_id": 3941,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 170,
            "שם_רחוב": "חופית"
        },
        {
            "_id": 3942,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 250,
            "שם_רחוב": "חושן"
        },
        {
            "_id": 3943,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 257,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 3944,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 212,
            "שם_רחוב": "יגאל כהן-אורגד"
        },
        {
            "_id": 3945,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 302,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 3946,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 253,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 3947,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "יואב"
        },
        {
            "_id": 3948,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 152,
            "שם_רחוב": "יודפת"
        },
        {
            "_id": 3949,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ימית"
        },
        {
            "_id": 3950,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 172,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 3951,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 146,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 3952,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 3953,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "כביש 101"
        },
        {
            "_id": 3954,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 160,
            "שם_רחוב": "כביש 31"
        },
        {
            "_id": 3955,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 161,
            "שם_רחוב": "כביש 32"
        },
        {
            "_id": 3956,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 162,
            "שם_רחוב": "כביש 33"
        },
        {
            "_id": 3957,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 164,
            "שם_רחוב": "כביש 34"
        },
        {
            "_id": 3958,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 165,
            "שם_רחוב": "כביש 35"
        },
        {
            "_id": 3959,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 166,
            "שם_רחוב": "כביש 36"
        },
        {
            "_id": 3960,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 147,
            "שם_רחוב": "כלילת יופי"
        },
        {
            "_id": 3961,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 305,
            "שם_רחוב": "כלניות"
        },
        {
            "_id": 3962,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 175,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 3963,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 252,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 3964,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 156,
            "שם_רחוב": "מבוא חמה"
        },
        {
            "_id": 3965,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "מבצע דני"
        },
        {
            "_id": 3966,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 125,
            "שם_רחוב": "מבצע הראל"
        },
        {
            "_id": 3967,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מבצע חירם"
        },
        {
            "_id": 3968,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מבצע עובדה"
        },
        {
            "_id": 3969,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "מבצע קדש"
        },
        {
            "_id": 3970,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 154,
            "שם_רחוב": "מואב"
        },
        {
            "_id": 3971,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 143,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 3972,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "מכללת אריאל"
        },
        {
            "_id": 3973,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 211,
            "שם_רחוב": "מעלה הגבעה"
        },
        {
            "_id": 3974,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 124,
            "שם_רחוב": "מפרץ אילת"
        },
        {
            "_id": 3975,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 123,
            "שם_רחוב": "מפרץ שלמה"
        },
        {
            "_id": 3976,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 139,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 3977,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 320,
            "שם_רחוב": "משעול א"
        },
        {
            "_id": 3978,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 321,
            "שם_רחוב": "משעול ב"
        },
        {
            "_id": 3979,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 330,
            "שם_רחוב": "משעול ברכה"
        },
        {
            "_id": 3980,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 322,
            "שם_רחוב": "משעול ג"
        },
        {
            "_id": 3981,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 323,
            "שם_רחוב": "משעול ד"
        },
        {
            "_id": 3982,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 324,
            "שם_רחוב": "משעול ה"
        },
        {
            "_id": 3983,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 301,
            "שם_רחוב": "משעול הזית"
        },
        {
            "_id": 3984,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 325,
            "שם_רחוב": "משעול ו"
        },
        {
            "_id": 3985,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 326,
            "שם_רחוב": "משעול ז"
        },
        {
            "_id": 3986,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 328,
            "שם_רחוב": "משעול רימון"
        },
        {
            "_id": 3987,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 329,
            "שם_רחוב": "משעול תאנה"
        },
        {
            "_id": 3988,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 327,
            "שם_רחוב": "משעול תמר"
        },
        {
            "_id": 3989,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 141,
            "שם_רחוב": "נוה שאנן"
        },
        {
            "_id": 3990,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 174,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 3991,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 171,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 3992,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 259,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 3993,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 157,
            "שם_רחוב": "עיר דוד"
        },
        {
            "_id": 3994,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 144,
            "שם_רחוב": "עיר היונה"
        },
        {
            "_id": 3995,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 260,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 3996,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 155,
            "שם_רחוב": "עצמון"
        },
        {
            "_id": 3997,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 3998,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 140,
            "שם_רחוב": "ציון"
        },
        {
            "_id": 3999,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "קרית המדע"
        },
        {
            "_id": 4000,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שד ירושלים"
        },
        {
            "_id": 4001,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 254,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 4002,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ ארבל"
        },
        {
            "_id": 4003,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ המור"
        },
        {
            "_id": 4004,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ הפרחים"
        },
        {
            "_id": 4005,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ הראשונים"
        },
        {
            "_id": 4006,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ יובלים"
        },
        {
            "_id": 4007,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ עצמה"
        },
        {
            "_id": 4008,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ קרוונים"
        },
        {
            "_id": 4009,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ רמים"
        },
        {
            "_id": 4010,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ שגיא"
        },
        {
            "_id": 4011,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 255,
            "שם_רחוב": "שנהב"
        },
        {
            "_id": 4012,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 310,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 4013,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 134,
            "שם_רחוב": "שער הגיא"
        },
        {
            "_id": 4014,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 4015,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 149,
            "שם_רחוב": "תאשור"
        },
        {
            "_id": 4016,
            "סמל_ישוב": 3570,
            "שם_ישוב": "אריאל ",
            "סמל_רחוב": 151,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 4017,
            "סמל_ישוב": 1324,
            "שם_ישוב": "ארסוף ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 4018,
            "סמל_ישוב": 1324,
            "שם_ישוב": "ארסוף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ארסוף"
        },
        {
            "_id": 4019,
            "סמל_ישוב": 1324,
            "שם_ישוב": "ארסוף ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגלים"
        },
        {
            "_id": 4020,
            "סמל_ישוב": 1324,
            "שם_ישוב": "ארסוף ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מבוא הים"
        },
        {
            "_id": 4021,
            "סמל_ישוב": 1324,
            "שם_ישוב": "ארסוף ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מול הים"
        },
        {
            "_id": 4022,
            "סמל_ישוב": 1324,
            "שם_ישוב": "ארסוף ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נחשול"
        },
        {
            "_id": 4023,
            "סמל_ישוב": 1324,
            "שם_ישוב": "ארסוף ",
            "סמל_רחוב": 106,
            "שם_רחוב": "צדף ים"
        },
        {
            "_id": 4024,
            "סמל_ישוב": 71,
            "שם_ישוב": "אשבול ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשבול"
        },
        {
            "_id": 4025,
            "סמל_ישוב": 1276,
            "שם_ישוב": "אשבל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשבל"
        },
        {
            "_id": 4026,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 370,
            "שם_רחוב": "אב"
        },
        {
            "_id": 4027,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אבות ישורון"
        },
        {
            "_id": 4028,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 765,
            "שם_רחוב": "אבטליון"
        },
        {
            "_id": 4029,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 768,
            "שם_רחוב": "אביי"
        },
        {
            "_id": 4030,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 4031,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 4032,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 746,
            "שם_רחוב": "אבני החושן"
        },
        {
            "_id": 4033,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 733,
            "שם_רחוב": "אבנר בן נר"
        },
        {
            "_id": 4034,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אגדיר"
        },
        {
            "_id": 4035,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 336,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 4036,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 872,
            "שם_רחוב": "אגס"
        },
        {
            "_id": 4037,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 783,
            "שם_רחוב": "אגת-שבו"
        },
        {
            "_id": 4038,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 568,
            "שם_רחוב": "אדוננו ברוך"
        },
        {
            "_id": 4039,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 654,
            "שם_רחוב": "אדיסון"
        },
        {
            "_id": 4040,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אדמור מפיטסבורג"
        },
        {
            "_id": 4041,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 365,
            "שם_רחוב": "אדר"
        },
        {
            "_id": 4042,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 460,
            "שם_רחוב": "אהוד מנור"
        },
        {
            "_id": 4043,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 607,
            "שם_רחוב": "אהרונסון שרה"
        },
        {
            "_id": 4044,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 744,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 4045,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 333,
            "שם_רחוב": "אוניון"
        },
        {
            "_id": 4046,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 682,
            "שם_רחוב": "אוסישקין מנחם"
        },
        {
            "_id": 4047,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 785,
            "שם_רחוב": "אופל"
        },
        {
            "_id": 4048,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 557,
            "שם_רחוב": "אור החיים"
        },
        {
            "_id": 4049,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 327,
            "שם_רחוב": "אור לציון"
        },
        {
            "_id": 4050,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אורט"
        },
        {
            "_id": 4051,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 813,
            "שם_רחוב": "אוריה החיתי"
        },
        {
            "_id": 4052,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6549,
            "שם_רחוב": "אזור בקמה"
        },
        {
            "_id": 4053,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "אזור המרינה"
        },
        {
            "_id": 4054,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אזור התעשיה"
        },
        {
            "_id": 4055,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "אזור יא"
        },
        {
            "_id": 4056,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6563,
            "שם_רחוב": "אזור תעשייה 1"
        },
        {
            "_id": 4057,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6564,
            "שם_רחוב": "אזור תעשייה 2"
        },
        {
            "_id": 4058,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6562,
            "שם_רחוב": "אזור תעשייה 3"
        },
        {
            "_id": 4059,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "אזורים"
        },
        {
            "_id": 4060,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אחי אילת"
        },
        {
            "_id": 4061,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 4062,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 594,
            "שם_רחוב": "אחוזת בית"
        },
        {
            "_id": 4063,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 683,
            "שם_רחוב": "אחימאיר אבא"
        },
        {
            "_id": 4064,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אחיעזר"
        },
        {
            "_id": 4065,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 782,
            "שם_רחוב": "אחלמה"
        },
        {
            "_id": 4066,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 687,
            "שם_רחוב": "אטלי"
        },
        {
            "_id": 4067,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 619,
            "שם_רחוב": "אידלסון בבה"
        },
        {
            "_id": 4068,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 236,
            "שם_רחוב": "אייל"
        },
        {
            "_id": 4069,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 367,
            "שם_רחוב": "אייר"
        },
        {
            "_id": 4070,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 409,
            "שם_רחוב": "אילון"
        },
        {
            "_id": 4071,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 299,
            "שם_רחוב": "איתמר"
        },
        {
            "_id": 4072,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 320,
            "שם_רחוב": "אל הציפור"
        },
        {
            "_id": 4073,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אלבז נתן"
        },
        {
            "_id": 4074,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 661,
            "שם_רחוב": "אלברט איינשטיין"
        },
        {
            "_id": 4075,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 371,
            "שם_רחוב": "אלול"
        },
        {
            "_id": 4076,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אלון יגאל"
        },
        {
            "_id": 4077,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 322,
            "שם_רחוב": "אליאב לובה"
        },
        {
            "_id": 4078,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 280,
            "שם_רחוב": "אליאשוילי"
        },
        {
            "_id": 4079,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 753,
            "שם_רחוב": "אליעזר בן הורקנוס"
        },
        {
            "_id": 4080,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 288,
            "שם_רחוב": "אלתרמן נתן"
        },
        {
            "_id": 4081,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אמנון ליפקין שחק"
        },
        {
            "_id": 4082,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 784,
            "שם_רחוב": "אמרלד"
        },
        {
            "_id": 4083,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 675,
            "שם_רחוב": "אנילביץ מרדכי"
        },
        {
            "_id": 4084,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "אסבסטונים"
        },
        {
            "_id": 4085,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 603,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 4086,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 467,
            "שם_רחוב": "אסתר רוט שחמורוב"
        },
        {
            "_id": 4087,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 353,
            "שם_רחוב": "אפרים לנגסנר"
        },
        {
            "_id": 4088,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 874,
            "שם_רחוב": "אפרסמון"
        },
        {
            "_id": 4089,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 870,
            "שם_רחוב": "אפרסק"
        },
        {
            "_id": 4090,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אפרתי אליהו"
        },
        {
            "_id": 4091,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 332,
            "שם_רחוב": "אקסודוס"
        },
        {
            "_id": 4092,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 601,
            "שם_רחוב": "ארבע האמהות"
        },
        {
            "_id": 4093,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 457,
            "שם_רחוב": "אריק איינשטיין"
        },
        {
            "_id": 4094,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 279,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 4095,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 248,
            "שם_רחוב": "ארן זלמן"
        },
        {
            "_id": 4096,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשדוד"
        },
        {
            "_id": 4097,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 879,
            "שם_רחוב": "אשכולית"
        },
        {
            "_id": 4098,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 179,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 4099,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 590,
            "שם_רחוב": "בבלי"
        },
        {
            "_id": 4100,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 621,
            "שם_רחוב": "בגין עליזה"
        },
        {
            "_id": 4101,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 185,
            "שם_רחוב": "בובר מרטין"
        },
        {
            "_id": 4102,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 285,
            "שם_רחוב": "בוסקילה יצחק"
        },
        {
            "_id": 4103,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 173,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 4104,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 660,
            "שם_רחוב": "בורחס חורחה"
        },
        {
            "_id": 4105,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 277,
            "שם_רחוב": "בורלא יהודה"
        },
        {
            "_id": 4106,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 902,
            "שם_רחוב": "בז"
        },
        {
            "_id": 4107,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 669,
            "שם_רחוב": "בטהובן"
        },
        {
            "_id": 4108,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 278,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 4109,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 573,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 4110,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 143,
            "שם_רחוב": "בית קנדה"
        },
        {
            "_id": 4111,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 205,
            "שם_רחוב": "בלפור"
        },
        {
            "_id": 4112,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 247,
            "שם_רחוב": "בן אליעזר אריה"
        },
        {
            "_id": 4113,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 569,
            "שם_רחוב": "בן ברוך אורי"
        },
        {
            "_id": 4114,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 570,
            "שם_רחוב": "בן דיוואן עמרם"
        },
        {
            "_id": 4115,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 341,
            "שם_רחוב": "בן הכט"
        },
        {
            "_id": 4116,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 201,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 4117,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 340,
            "שם_רחוב": "בן עמי עובד"
        },
        {
            "_id": 4118,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 504,
            "שם_רחוב": "בעלי מלאכה"
        },
        {
            "_id": 4119,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 436,
            "שם_רחוב": "בקעת הירח"
        },
        {
            "_id": 4120,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 666,
            "שם_רחוב": "ברהמס"
        },
        {
            "_id": 4121,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 612,
            "שם_רחוב": "ברוריה"
        },
        {
            "_id": 4122,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 204,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 4123,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 818,
            "שם_רחוב": "ברק בן אבינועם"
        },
        {
            "_id": 4124,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 745,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 4125,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 451,
            "שם_רחוב": "ג'ו עמר"
        },
        {
            "_id": 4126,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 318,
            "שם_רחוב": "גאפונוב"
        },
        {
            "_id": 4127,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 742,
            "שם_רחוב": "גביש"
        },
        {
            "_id": 4128,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "גבעת יונה"
        },
        {
            "_id": 4129,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 592,
            "שם_רחוב": "גבעת עדה"
        },
        {
            "_id": 4130,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 572,
            "שם_רחוב": "גדרה"
        },
        {
            "_id": 4131,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 656,
            "שם_רחוב": "גוגול"
        },
        {
            "_id": 4132,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 611,
            "שם_רחוב": "גולדברג לאה"
        },
        {
            "_id": 4133,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 232,
            "שם_רחוב": "גור דב"
        },
        {
            "_id": 4134,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 697,
            "שם_רחוב": "גור מרדכי"
        },
        {
            "_id": 4135,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 659,
            "שם_רחוב": "גטה"
        },
        {
            "_id": 4136,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 595,
            "שם_רחוב": "גיא אוני"
        },
        {
            "_id": 4137,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 424,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 4138,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 694,
            "שם_רחוב": "גלעדי ישראל"
        },
        {
            "_id": 4139,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 237,
            "שם_רחוב": "גרונר דב"
        },
        {
            "_id": 4140,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 351,
            "שם_רחוב": "גרינברג אורי צבי"
        },
        {
            "_id": 4141,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 228,
            "שם_רחוב": "גשר עד הלום"
        },
        {
            "_id": 4142,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 602,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 4143,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 700,
            "שם_רחוב": "דבורה עומר"
        },
        {
            "_id": 4144,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 578,
            "שם_רחוב": "דגניה"
        },
        {
            "_id": 4145,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 668,
            "שם_רחוב": "דה וינצ'י"
        },
        {
            "_id": 4146,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 114,
            "שם_רחוב": "דוד אלעזר"
        },
        {
            "_id": 4147,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 150,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 4148,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 509,
            "שם_רחוב": "דולומיט"
        },
        {
            "_id": 4149,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 256,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 4150,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דן שומרון"
        },
        {
            "_id": 4151,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 249,
            "שם_רחוב": "דקר"
        },
        {
            "_id": 4152,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 153,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 4153,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 479,
            "שם_רחוב": "דרך אריאל שרון"
        },
        {
            "_id": 4154,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 343,
            "שם_רחוב": "דרך ארץ"
        },
        {
            "_id": 4155,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 580,
            "שם_רחוב": "דרך בגין מנחם"
        },
        {
            "_id": 4156,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 533,
            "שם_רחוב": "דרך המכס"
        },
        {
            "_id": 4157,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 323,
            "שם_רחוב": "דרך הרכבת"
        },
        {
            "_id": 4158,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 480,
            "שם_רחוב": "דרך לסקוב"
        },
        {
            "_id": 4159,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 486,
            "שם_רחוב": "דרך ניר גלים"
        },
        {
            "_id": 4160,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 481,
            "שם_רחוב": "דרך תל מור"
        },
        {
            "_id": 4161,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 861,
            "שם_רחוב": "ה' באייר"
        },
        {
            "_id": 4162,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 289,
            "שם_רחוב": "האדמור מבעלז"
        },
        {
            "_id": 4163,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 328,
            "שם_רחוב": "האדמור מגור"
        },
        {
            "_id": 4164,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 326,
            "שם_רחוב": "האדמור מויז'ניץ"
        },
        {
            "_id": 4165,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 465,
            "שם_רחוב": "האולימפיאדה"
        },
        {
            "_id": 4166,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 508,
            "שם_רחוב": "האומן"
        },
        {
            "_id": 4167,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 538,
            "שם_רחוב": "האוניה"
        },
        {
            "_id": 4168,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 268,
            "שם_רחוב": "האופים"
        },
        {
            "_id": 4169,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 501,
            "שם_רחוב": "האורגים"
        },
        {
            "_id": 4170,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 135,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 4171,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 116,
            "שם_רחוב": "האיריס"
        },
        {
            "_id": 4172,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 4173,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 254,
            "שם_רחוב": "האלמוגים"
        },
        {
            "_id": 4174,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 211,
            "שם_רחוב": "האצל"
        },
        {
            "_id": 4175,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 556,
            "שם_רחוב": "הארי הקדוש"
        },
        {
            "_id": 4176,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 137,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 4177,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 127,
            "שם_רחוב": "האשכול"
        },
        {
            "_id": 4178,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 131,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 4179,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 491,
            "שם_רחוב": "האשלג"
        },
        {
            "_id": 4180,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 301,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 4181,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 507,
            "שם_רחוב": "הבושם"
        },
        {
            "_id": 4182,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 492,
            "שם_רחוב": "הבטיחות"
        },
        {
            "_id": 4183,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 500,
            "שם_רחוב": "הבנאים"
        },
        {
            "_id": 4184,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 330,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 4185,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 282,
            "שם_רחוב": "הבעל שם טוב"
        },
        {
            "_id": 4186,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 426,
            "שם_רחוב": "הבשור"
        },
        {
            "_id": 4187,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 331,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 4188,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 431,
            "שם_רחוב": "הגולשים"
        },
        {
            "_id": 4189,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 534,
            "שם_רחוב": "הגוררת"
        },
        {
            "_id": 4190,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 255,
            "שם_רחוב": "הגלים"
        },
        {
            "_id": 4191,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 824,
            "שם_רחוב": "הגלימה"
        },
        {
            "_id": 4192,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 4193,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 559,
            "שם_רחוב": "הגרא"
        },
        {
            "_id": 4194,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 535,
            "שם_רחוב": "הדוברה"
        },
        {
            "_id": 4195,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 252,
            "שם_רחוב": "הדולפין"
        },
        {
            "_id": 4196,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 4197,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 138,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 4198,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 140,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 4199,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 850,
            "שם_רחוב": "ההגשמה"
        },
        {
            "_id": 4200,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 503,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 4201,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 144,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 4202,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 541,
            "שם_רחוב": "ההפלגה"
        },
        {
            "_id": 4203,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 487,
            "שם_רחוב": "ההשכלה"
        },
        {
            "_id": 4204,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 235,
            "שם_רחוב": "הוז דב"
        },
        {
            "_id": 4205,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 510,
            "שם_רחוב": "הוסטל שלום"
        },
        {
            "_id": 4206,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 4207,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 4208,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 124,
            "שם_רחוב": "החבצלות"
        },
        {
            "_id": 4209,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 286,
            "שם_רחוב": "החובלים"
        },
        {
            "_id": 4210,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 587,
            "שם_רחוב": "החולה"
        },
        {
            "_id": 4211,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 441,
            "שם_רחוב": "החותרים"
        },
        {
            "_id": 4212,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 560,
            "שם_רחוב": "החידא"
        },
        {
            "_id": 4213,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 851,
            "שם_רחוב": "החלוצים"
        },
        {
            "_id": 4214,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 493,
            "שם_רחוב": "החניכים"
        },
        {
            "_id": 4215,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 494,
            "שם_רחוב": "החרושת"
        },
        {
            "_id": 4216,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 300,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 4217,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 485,
            "שם_רחוב": "החשמל"
        },
        {
            "_id": 4218,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 325,
            "שם_רחוב": "הטיילת"
        },
        {
            "_id": 4219,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 502,
            "שם_רחוב": "היהלומים"
        },
        {
            "_id": 4220,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 269,
            "שם_רחוב": "היוצר"
        },
        {
            "_id": 4221,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 498,
            "שם_רחוב": "היזמה"
        },
        {
            "_id": 4222,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 670,
            "שם_רחוב": "היידן"
        },
        {
            "_id": 4223,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 484,
            "שם_רחוב": "הימאים"
        },
        {
            "_id": 4224,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 121,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 4225,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 497,
            "שם_רחוב": "היציקה"
        },
        {
            "_id": 4226,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 309,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 4227,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 822,
            "שם_רחוב": "הכתר"
        },
        {
            "_id": 4228,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 172,
            "שם_רחוב": "הלביאה"
        },
        {
            "_id": 4229,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 763,
            "שם_רחוב": "הלל"
        },
        {
            "_id": 4230,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 241,
            "שם_רחוב": "הלפרין ירמיהו"
        },
        {
            "_id": 4231,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 862,
            "שם_רחוב": "המאבק"
        },
        {
            "_id": 4232,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 295,
            "שם_רחוב": "המברק"
        },
        {
            "_id": 4233,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 548,
            "שם_רחוב": "המגדלור"
        },
        {
            "_id": 4234,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 293,
            "שם_רחוב": "המגיד"
        },
        {
            "_id": 4235,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 499,
            "שם_רחוב": "המדע"
        },
        {
            "_id": 4236,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 496,
            "שם_רחוב": "המחקר"
        },
        {
            "_id": 4237,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 297,
            "שם_רחוב": "המליץ"
        },
        {
            "_id": 4238,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 805,
            "שם_רחוב": "המלך אליקים"
        },
        {
            "_id": 4239,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 810,
            "שם_רחוב": "המלך אמציה"
        },
        {
            "_id": 4240,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 806,
            "שם_רחוב": "המלך אסא"
        },
        {
            "_id": 4241,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 801,
            "שם_רחוב": "המלך דוד"
        },
        {
            "_id": 4242,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 820,
            "שם_רחוב": "המלך הדד"
        },
        {
            "_id": 4243,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 804,
            "שם_רחוב": "המלך חזקיה"
        },
        {
            "_id": 4244,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 808,
            "שם_רחוב": "המלך יהוא"
        },
        {
            "_id": 4245,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 819,
            "שם_רחוב": "המלך יהואחז"
        },
        {
            "_id": 4246,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 816,
            "שם_רחוב": "המלך יהויכין"
        },
        {
            "_id": 4247,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 807,
            "שם_רחוב": "המלך יהושפט"
        },
        {
            "_id": 4248,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 821,
            "שם_רחוב": "המלך יובב"
        },
        {
            "_id": 4249,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 814,
            "שם_רחוב": "המלך יותם"
        },
        {
            "_id": 4250,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 815,
            "שם_רחוב": "המלך ירבעם"
        },
        {
            "_id": 4251,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 803,
            "שם_רחוב": "המלך עזריה"
        },
        {
            "_id": 4252,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 811,
            "שם_רחוב": "המלך עמרי"
        },
        {
            "_id": 4253,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 812,
            "שם_רחוב": "המלך רחבעם"
        },
        {
            "_id": 4254,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 817,
            "שם_רחוב": "המלך שאול"
        },
        {
            "_id": 4255,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 802,
            "שם_רחוב": "המלך שלמה"
        },
        {
            "_id": 4256,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 823,
            "שם_רחוב": "המלכים"
        },
        {
            "_id": 4257,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 271,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 4258,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 122,
            "שם_רחוב": "המסחר"
        },
        {
            "_id": 4259,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 546,
            "שם_רחוב": "המעבורת"
        },
        {
            "_id": 4260,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 544,
            "שם_רחוב": "המעגן"
        },
        {
            "_id": 4261,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 145,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 4262,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 550,
            "שם_רחוב": "המפרץ"
        },
        {
            "_id": 4263,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 262,
            "שם_רחוב": "המפרש"
        },
        {
            "_id": 4264,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 547,
            "שם_רחוב": "המצפן"
        },
        {
            "_id": 4265,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 857,
            "שם_רחוב": "המרי"
        },
        {
            "_id": 4266,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 294,
            "שם_רחוב": "המשקיף"
        },
        {
            "_id": 4267,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 287,
            "שם_רחוב": "המתכת"
        },
        {
            "_id": 4268,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 319,
            "שם_רחוב": "המתמיד"
        },
        {
            "_id": 4269,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 860,
            "שם_רחוב": "המתנדבים"
        },
        {
            "_id": 4270,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 714,
            "שם_רחוב": "הנביא אליהו"
        },
        {
            "_id": 4271,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 707,
            "שם_רחוב": "הנביא אלישע"
        },
        {
            "_id": 4272,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 716,
            "שם_רחוב": "הנביא גד"
        },
        {
            "_id": 4273,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 703,
            "שם_רחוב": "הנביא דניאל"
        },
        {
            "_id": 4274,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 712,
            "שם_רחוב": "הנביא הושע"
        },
        {
            "_id": 4275,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 706,
            "שם_רחוב": "הנביא זכריה"
        },
        {
            "_id": 4276,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 722,
            "שם_רחוב": "הנביא חבקוק"
        },
        {
            "_id": 4277,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 723,
            "שם_רחוב": "הנביא חגי"
        },
        {
            "_id": 4278,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 708,
            "שם_רחוב": "הנביא יואל"
        },
        {
            "_id": 4279,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 719,
            "שם_רחוב": "הנביא יחזקאל"
        },
        {
            "_id": 4280,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 718,
            "שם_רחוב": "הנביא ירמיהו"
        },
        {
            "_id": 4281,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 717,
            "שם_רחוב": "הנביא ישעיהו"
        },
        {
            "_id": 4282,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 721,
            "שם_רחוב": "הנביא מיכה"
        },
        {
            "_id": 4283,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 711,
            "שם_רחוב": "הנביא מלאכי"
        },
        {
            "_id": 4284,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 725,
            "שם_רחוב": "הנביא נחום"
        },
        {
            "_id": 4285,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 709,
            "שם_רחוב": "הנביא נחמיה"
        },
        {
            "_id": 4286,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 713,
            "שם_רחוב": "הנביא נתן"
        },
        {
            "_id": 4287,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 726,
            "שם_רחוב": "הנביא עובדיה"
        },
        {
            "_id": 4288,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 727,
            "שם_רחוב": "הנביא עמוס"
        },
        {
            "_id": 4289,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 724,
            "שם_רחוב": "הנביא צפניה"
        },
        {
            "_id": 4290,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 705,
            "שם_רחוב": "הנביא שמואל"
        },
        {
            "_id": 4291,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 704,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 4292,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 272,
            "שם_רחוב": "הנגר"
        },
        {
            "_id": 4293,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 304,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 4294,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 273,
            "שם_רחוב": "הנחושת"
        },
        {
            "_id": 4295,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 545,
            "שם_רחוב": "הנחשול"
        },
        {
            "_id": 4296,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 488,
            "שם_רחוב": "הנפט"
        },
        {
            "_id": 4297,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 314,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 4298,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 213,
            "שם_רחוב": "הנשיא ויצמן"
        },
        {
            "_id": 4299,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 4300,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 305,
            "שם_רחוב": "הסחלב"
        },
        {
            "_id": 4301,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 316,
            "שם_רחוב": "הסייפן"
        },
        {
            "_id": 4302,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 539,
            "שם_רחוב": "הסיפון"
        },
        {
            "_id": 4303,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 537,
            "שם_רחוב": "הספנות"
        },
        {
            "_id": 4304,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 274,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 4305,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 549,
            "שם_רחוב": "העוגן"
        },
        {
            "_id": 4306,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 171,
            "שם_רחוב": "העופרים"
        },
        {
            "_id": 4307,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 275,
            "שם_רחוב": "העופרת"
        },
        {
            "_id": 4308,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 731,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 4309,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 276,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 4310,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 852,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 4311,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 141,
            "שם_רחוב": "העשרים ואחד"
        },
        {
            "_id": 4312,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 490,
            "שם_רחוב": "הפלדה"
        },
        {
            "_id": 4313,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 207,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 4314,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 489,
            "שם_רחוב": "הפריון"
        },
        {
            "_id": 4315,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 170,
            "שם_רחוב": "הצבי"
        },
        {
            "_id": 4316,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 313,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 4317,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 433,
            "שם_רחוב": "הצוללים"
        },
        {
            "_id": 4318,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 505,
            "שם_רחוב": "הצורפים"
        },
        {
            "_id": 4319,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 856,
            "שם_רחוב": "הציונות"
        },
        {
            "_id": 4320,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 296,
            "שם_רחוב": "הצפירה"
        },
        {
            "_id": 4321,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 506,
            "שם_רחוב": "הקדמה"
        },
        {
            "_id": 4322,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 495,
            "שם_רחוב": "הקיטור"
        },
        {
            "_id": 4323,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 853,
            "שם_רחוב": "הקליטה"
        },
        {
            "_id": 4324,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6545,
            "שם_רחוב": "הקריה-מגורים"
        },
        {
            "_id": 4325,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6551,
            "שם_רחוב": "הקריה-מער"
        },
        {
            "_id": 4326,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 434,
            "שם_רחוב": "הר אביטל"
        },
        {
            "_id": 4327,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 419,
            "שם_רחוב": "הר גלבוע"
        },
        {
            "_id": 4328,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 416,
            "שם_רחוב": "הר הצופים"
        },
        {
            "_id": 4329,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 428,
            "שם_רחוב": "הר כנען"
        },
        {
            "_id": 4330,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 422,
            "שם_רחוב": "הר כרמל"
        },
        {
            "_id": 4331,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 418,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 4332,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 420,
            "שם_רחוב": "הר נבו"
        },
        {
            "_id": 4333,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 425,
            "שם_רחוב": "הר ציון"
        },
        {
            "_id": 4334,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 421,
            "שם_רחוב": "הר תבור"
        },
        {
            "_id": 4335,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 555,
            "שם_רחוב": "הראש"
        },
        {
            "_id": 4336,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 234,
            "שם_רחוב": "הראם"
        },
        {
            "_id": 4337,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 4338,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 267,
            "שם_רחוב": "הרב אבוחצירה"
        },
        {
            "_id": 4339,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 281,
            "שם_רחוב": "הרב אבן דנן"
        },
        {
            "_id": 4340,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 183,
            "שם_רחוב": "הרב אברמסקי"
        },
        {
            "_id": 4341,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 561,
            "שם_רחוב": "הרב בוזגלו דוד"
        },
        {
            "_id": 4342,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 194,
            "שם_רחוב": "הרב בלולו"
        },
        {
            "_id": 4343,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 219,
            "שם_רחוב": "הרב הגר"
        },
        {
            "_id": 4344,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 159,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 4345,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 453,
            "שם_רחוב": "הרב חיים שושנה"
        },
        {
            "_id": 4346,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 525,
            "שם_רחוב": "הרב טייב"
        },
        {
            "_id": 4347,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרב יהושע מאמאן"
        },
        {
            "_id": 4348,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 515,
            "שם_רחוב": "הרב יורם אברג'ל"
        },
        {
            "_id": 4349,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 160,
            "שם_רחוב": "הרב מימון"
        },
        {
            "_id": 4350,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 563,
            "שם_רחוב": "הרב משאש יוסף"
        },
        {
            "_id": 4351,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 298,
            "שם_רחוב": "הרב ניסים"
        },
        {
            "_id": 4352,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 163,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 4353,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 566,
            "שם_רחוב": "הרב קלישר"
        },
        {
            "_id": 4354,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 264,
            "שם_רחוב": "הרב רוזובסקי"
        },
        {
            "_id": 4355,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 564,
            "שם_רחוב": "הרב ריינס"
        },
        {
            "_id": 4356,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 471,
            "שם_רחוב": "הרב רפאל הלפרין"
        },
        {
            "_id": 4357,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 259,
            "שם_רחוב": "הרב שאולי"
        },
        {
            "_id": 4358,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 303,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 4359,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 412,
            "שם_רחוב": "הרי גולן"
        },
        {
            "_id": 4360,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 586,
            "שם_רחוב": "הרים לוין"
        },
        {
            "_id": 4361,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 558,
            "שם_רחוב": "הריף"
        },
        {
            "_id": 4362,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 881,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 4363,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 526,
            "שם_רחוב": "הרמא"
        },
        {
            "_id": 4364,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 345,
            "שם_רחוב": "הרפואה"
        },
        {
            "_id": 4365,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 542,
            "שם_רחוב": "הרציף"
        },
        {
            "_id": 4366,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 315,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 4367,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 136,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 4368,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 432,
            "שם_רחוב": "השייטים"
        },
        {
            "_id": 4369,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 134,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 4370,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 132,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 4371,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 439,
            "שם_רחוב": "התורן"
        },
        {
            "_id": 4372,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 329,
            "שם_רחוב": "התלמוד"
        },
        {
            "_id": 4373,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 513,
            "שם_רחוב": "התעסוקה"
        },
        {
            "_id": 4374,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 855,
            "שם_רחוב": "התקוה"
        },
        {
            "_id": 4375,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 854,
            "שם_רחוב": "התקומה"
        },
        {
            "_id": 4376,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 512,
            "שם_רחוב": "התקשורת"
        },
        {
            "_id": 4377,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 334,
            "שם_רחוב": "ואלוס"
        },
        {
            "_id": 4378,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 673,
            "שם_רחוב": "ואן גוך"
        },
        {
            "_id": 4379,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 684,
            "שם_רחוב": "וולפסון דוד"
        },
        {
            "_id": 4380,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 671,
            "שם_רחוב": "ויקטור הוגו"
        },
        {
            "_id": 4381,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 695,
            "שם_רחוב": "ויתקין יוסף"
        },
        {
            "_id": 4382,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 720,
            "שם_רחוב": "ורדימון יצחק"
        },
        {
            "_id": 4383,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 181,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 4384,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 664,
            "שם_רחוב": "זולה אמיל"
        },
        {
            "_id": 4385,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 696,
            "שם_רחוב": "זייד אלכסנדר"
        },
        {
            "_id": 4386,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 527,
            "שם_רחוב": "זינגר בשביס"
        },
        {
            "_id": 4387,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 577,
            "שם_רחוב": "זכרון יעקב"
        },
        {
            "_id": 4388,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 688,
            "שם_רחוב": "זלמן דוד לבונטין"
        },
        {
            "_id": 4389,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 151,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 4390,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 689,
            "שם_רחוב": "זנגביל"
        },
        {
            "_id": 4391,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 574,
            "שם_רחוב": "חדרה"
        },
        {
            "_id": 4392,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 155,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 4393,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 618,
            "שם_רחוב": "חולדה הנביאה"
        },
        {
            "_id": 4394,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6539,
            "שם_רחוב": "חוף באר שבע"
        },
        {
            "_id": 4395,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6552,
            "שם_רחוב": "חוף הקשתות"
        },
        {
            "_id": 4396,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 109,
            "שם_רחוב": "חוף לידו"
        },
        {
            "_id": 4397,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 177,
            "שם_רחוב": "חוף מי עמי"
        },
        {
            "_id": 4398,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 423,
            "שם_רחוב": "חורב"
        },
        {
            "_id": 4399,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 198,
            "שם_רחוב": "חורי חיים"
        },
        {
            "_id": 4400,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 692,
            "שם_רחוב": "חזנוב עמרם"
        },
        {
            "_id": 4401,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 225,
            "שם_רחוב": "חטיבת גבעתי"
        },
        {
            "_id": 4402,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 222,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 4403,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 231,
            "שם_רחוב": "חטיבת הנגב"
        },
        {
            "_id": 4404,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 221,
            "שם_רחוב": "חטיבת יפתח"
        },
        {
            "_id": 4405,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 223,
            "שם_רחוב": "חטיבת כרמלי"
        },
        {
            "_id": 4406,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 117,
            "שם_רחוב": "חיבת ציון"
        },
        {
            "_id": 4407,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 115,
            "שם_רחוב": "חיים ברלב"
        },
        {
            "_id": 4408,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 106,
            "שם_רחוב": "חיים הרצוג"
        },
        {
            "_id": 4409,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 482,
            "שם_רחוב": "חלוצי התעשיה"
        },
        {
            "_id": 4410,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 755,
            "שם_רחוב": "חנינא בן דוסא"
        },
        {
            "_id": 4411,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 693,
            "שם_רחוב": "חנקין יהושע"
        },
        {
            "_id": 4412,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 665,
            "שם_רחוב": "חסידי אומות העולם"
        },
        {
            "_id": 4413,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 197,
            "שם_רחוב": "חסן ובן גרה"
        },
        {
            "_id": 4414,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 244,
            "שם_רחוב": "חפץ חיים"
        },
        {
            "_id": 4415,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 476,
            "שם_רחוב": "חרטום"
        },
        {
            "_id": 4416,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 413,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 4417,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 361,
            "שם_רחוב": "חשון"
        },
        {
            "_id": 4418,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 363,
            "שם_רחוב": "טבת"
        },
        {
            "_id": 4419,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 658,
            "שם_רחוב": "טולסטוי"
        },
        {
            "_id": 4420,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6538,
            "שם_רחוב": "טופ סנטר"
        },
        {
            "_id": 4421,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 468,
            "שם_רחוב": "טל ברודי"
        },
        {
            "_id": 4422,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 265,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 4423,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 208,
            "שם_רחוב": "טשרניחובסקי"
        },
        {
            "_id": 4424,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 464,
            "שם_רחוב": "יא חללי מינכן"
        },
        {
            "_id": 4425,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 190,
            "שם_רחוב": "יאיר"
        },
        {
            "_id": 4426,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 593,
            "שם_רחוב": "יבנאל"
        },
        {
            "_id": 4427,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 112,
            "שם_רחוב": "יגאל ידין"
        },
        {
            "_id": 4428,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 214,
            "שם_רחוב": "יד העפלה ממרוקו"
        },
        {
            "_id": 4429,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 217,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 4430,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 196,
            "שם_רחוב": "יהודה המכבי"
        },
        {
            "_id": 4431,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 732,
            "שם_רחוב": "יהושע בן נון"
        },
        {
            "_id": 4432,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 730,
            "שם_רחוב": "יואב בן צרויה"
        },
        {
            "_id": 4433,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 685,
            "שם_רחוב": "יואל משה סלומון"
        },
        {
            "_id": 4434,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 620,
            "שם_רחוב": "יוהנה זבוטינסקי"
        },
        {
            "_id": 4435,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 758,
            "שם_רחוב": "יוחנן בן זכאי"
        },
        {
            "_id": 4436,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 690,
            "שם_רחוב": "יום טוב צמח"
        },
        {
            "_id": 4437,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 193,
            "שם_רחוב": "יונה הנביא"
        },
        {
            "_id": 4438,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 458,
            "שם_רחוב": "יוסי בנאי"
        },
        {
            "_id": 4439,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 752,
            "שם_רחוב": "יוסי בר חלפתא"
        },
        {
            "_id": 4440,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 146,
            "שם_רחוב": "יוספטל"
        },
        {
            "_id": 4441,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 156,
            "שם_רחוב": "יורדי הסירה"
        },
        {
            "_id": 4442,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 162,
            "שם_רחוב": "ילין דוד"
        },
        {
            "_id": 4443,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 576,
            "שם_רחוב": "יסוד המעלה"
        },
        {
            "_id": 4444,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 617,
            "שם_רחוב": "יעל"
        },
        {
            "_id": 4445,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 472,
            "שם_רחוב": "יעל ארד"
        },
        {
            "_id": 4446,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 454,
            "שם_רחוב": "יפה ירקוני"
        },
        {
            "_id": 4447,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 348,
            "שם_רחוב": "יצחק אילן"
        },
        {
            "_id": 4448,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 180,
            "שם_רחוב": "יצחק הנשיא"
        },
        {
            "_id": 4449,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 167,
            "שם_רחוב": "יצחק שדה"
        },
        {
            "_id": 4450,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 344,
            "שם_רחוב": "יצחק שמיר"
        },
        {
            "_id": 4451,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 321,
            "שם_רחוב": "יקינתון"
        },
        {
            "_id": 4452,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 414,
            "שם_רחוב": "ירמוך"
        },
        {
            "_id": 4453,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 408,
            "שם_רחוב": "ירקון"
        },
        {
            "_id": 4454,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 743,
            "שם_רחוב": "ירקן"
        },
        {
            "_id": 4455,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 788,
            "שם_רחוב": "ישפה"
        },
        {
            "_id": 4456,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 859,
            "שם_רחוב": "כט בנובמבר"
        },
        {
            "_id": 4457,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כהן אלי ככר"
        },
        {
            "_id": 4458,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 392,
            "שם_רחוב": "כוכב הדרום"
        },
        {
            "_id": 4459,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 540,
            "שם_רחוב": "כוכב הים"
        },
        {
            "_id": 4460,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 390,
            "שם_רחוב": "כוכב הצפון"
        },
        {
            "_id": 4461,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 391,
            "שם_רחוב": "כוכב השחר"
        },
        {
            "_id": 4462,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6012,
            "שם_רחוב": "כיכר הדייגים"
        },
        {
            "_id": 4463,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 450,
            "שם_רחוב": "כינור"
        },
        {
            "_id": 4464,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "ככר 12 השבטים"
        },
        {
            "_id": 4465,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6009,
            "שם_רחוב": "ככר אלתא"
        },
        {
            "_id": 4466,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6011,
            "שם_רחוב": "ככר הבונים החופשיים"
        },
        {
            "_id": 4467,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר המדינה"
        },
        {
            "_id": 4468,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "ככר העונות"
        },
        {
            "_id": 4469,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר סיגל"
        },
        {
            "_id": 4470,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "ככר ציון"
        },
        {
            "_id": 4471,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6010,
            "שם_רחוב": "ככר קהילת סלוניקי"
        },
        {
            "_id": 4472,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר שרת משה"
        },
        {
            "_id": 4473,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 406,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 4474,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 362,
            "שם_רחוב": "כסלו"
        },
        {
            "_id": 4475,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 308,
            "שם_רחוב": "כפתור החולות"
        },
        {
            "_id": 4476,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 514,
            "שם_רחוב": "לוחמי האש"
        },
        {
            "_id": 4477,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 710,
            "שם_רחוב": "לוי שלמה"
        },
        {
            "_id": 4478,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 253,
            "שם_רחוב": "לויתן"
        },
        {
            "_id": 4479,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 342,
            "שם_רחוב": "לילי"
        },
        {
            "_id": 4480,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 292,
            "שם_רחוב": "למרחב"
        },
        {
            "_id": 4481,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 781,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 4482,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 760,
            "שם_רחוב": "מאיר בעל הנס"
        },
        {
            "_id": 4483,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 757,
            "שם_רחוב": "מבוא האמוראים"
        },
        {
            "_id": 4484,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 312,
            "שם_רחוב": "מבוא הגומא"
        },
        {
            "_id": 4485,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 520,
            "שם_רחוב": "מבוא הדגנית"
        },
        {
            "_id": 4486,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 523,
            "שם_רחוב": "מבוא הדודאים"
        },
        {
            "_id": 4487,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 310,
            "שם_רחוב": "מבוא הדמומית"
        },
        {
            "_id": 4488,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 521,
            "שם_רחוב": "מבוא החמציץ"
        },
        {
            "_id": 4489,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 522,
            "שם_רחוב": "מבוא הכרכום"
        },
        {
            "_id": 4490,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 483,
            "שם_רחוב": "מבוא הספנים"
        },
        {
            "_id": 4491,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 311,
            "שם_רחוב": "מבוא העירית"
        },
        {
            "_id": 4492,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 863,
            "שם_רחוב": "מבוא הקריה"
        },
        {
            "_id": 4493,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 261,
            "שם_רחוב": "מבוא הרב אלקלעי"
        },
        {
            "_id": 4494,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 306,
            "שם_רחוב": "מבוא השרביטן"
        },
        {
            "_id": 4495,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 756,
            "שם_רחוב": "מבוא התנאים"
        },
        {
            "_id": 4496,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 284,
            "שם_רחוב": "מבוא מבצע חורב"
        },
        {
            "_id": 4497,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 283,
            "שם_רחוב": "מבוא מבצע נחשון"
        },
        {
            "_id": 4498,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 901,
            "שם_רחוב": "מבצע ברק"
        },
        {
            "_id": 4499,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 734,
            "שם_רחוב": "מדרחוב הנביאים"
        },
        {
            "_id": 4500,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 212,
            "שם_רחוב": "מורדי הגטאות"
        },
        {
            "_id": 4501,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 430,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 4502,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 579,
            "שם_רחוב": "מזכרת בתיה"
        },
        {
            "_id": 4503,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 588,
            "שם_רחוב": "מטולה"
        },
        {
            "_id": 4504,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 243,
            "שם_רחוב": "מיכשוילי"
        },
        {
            "_id": 4505,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 260,
            "שם_רחוב": "מילמן"
        },
        {
            "_id": 4506,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 653,
            "שם_רחוב": "מיסטרל"
        },
        {
            "_id": 4507,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 672,
            "שם_רחוב": "מכאל אנג'לו"
        },
        {
            "_id": 4508,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 442,
            "שם_רחוב": "מכולות"
        },
        {
            "_id": 4509,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 613,
            "שם_רחוב": "מלכת שבא"
        },
        {
            "_id": 4510,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 876,
            "שם_רחוב": "מנגו"
        },
        {
            "_id": 4511,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 699,
            "שם_רחוב": "מעגל השמחה"
        },
        {
            "_id": 4512,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 339,
            "שם_רחוב": "מפקורה"
        },
        {
            "_id": 4513,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 415,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 4514,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 589,
            "שם_רחוב": "מקוה ישראל"
        },
        {
            "_id": 4515,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 192,
            "שם_רחוב": "מקלנבורג"
        },
        {
            "_id": 4516,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 125,
            "שם_רחוב": "מרזוק ושמואל"
        },
        {
            "_id": 4517,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 606,
            "שם_רחוב": "מרים הנביאה"
        },
        {
            "_id": 4518,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 238,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 4519,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 437,
            "שם_רחוב": "מרכז קליטה שלום"
        },
        {
            "_id": 4520,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 347,
            "שם_רחוב": "משה לוי(מוישה וחצי)"
        },
        {
            "_id": 4521,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 187,
            "שם_רחוב": "משמר הירדן"
        },
        {
            "_id": 4522,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 873,
            "שם_רחוב": "משמש"
        },
        {
            "_id": 4523,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 596,
            "שם_רחוב": "משנה"
        },
        {
            "_id": 4524,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 174,
            "שם_רחוב": "משעול האירוסים"
        },
        {
            "_id": 4525,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6534,
            "שם_רחוב": "מתחם אלתא"
        },
        {
            "_id": 4526,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "נאות ספיר"
        },
        {
            "_id": 4527,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 199,
            "שם_רחוב": "נגרו אהרן"
        },
        {
            "_id": 4528,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 581,
            "שם_רחוב": "נהלל"
        },
        {
            "_id": 4529,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "נוה דניה"
        },
        {
            "_id": 4530,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "נוה יהונתן"
        },
        {
            "_id": 4531,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "נוה ים"
        },
        {
            "_id": 4532,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 747,
            "שם_רחוב": "נופך"
        },
        {
            "_id": 4533,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 176,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 4534,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 403,
            "שם_רחוב": "נחל דן"
        },
        {
            "_id": 4535,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 531,
            "שם_רחוב": "נחל הליטני"
        },
        {
            "_id": 4536,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 401,
            "שם_רחוב": "נחל לכיש"
        },
        {
            "_id": 4537,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 427,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 4538,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 402,
            "שם_רחוב": "נחל שורק"
        },
        {
            "_id": 4539,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 411,
            "שם_רחוב": "נחל שניר"
        },
        {
            "_id": 4540,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 152,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 4541,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 551,
            "שם_רחוב": "נחמן מברסלב"
        },
        {
            "_id": 4542,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 108,
            "שם_רחוב": "ניסים חדד"
        },
        {
            "_id": 4543,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 366,
            "שם_רחוב": "ניסן"
        },
        {
            "_id": 4544,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6560,
            "שם_רחוב": "נמל אשדוד"
        },
        {
            "_id": 4545,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 443,
            "שם_רחוב": "נמלי ישראל"
        },
        {
            "_id": 4546,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 571,
            "שם_רחוב": "נס ציונה"
        },
        {
            "_id": 4547,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 616,
            "שם_רחוב": "נעמי"
        },
        {
            "_id": 4548,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 405,
            "שם_רחוב": "נעמן"
        },
        {
            "_id": 4549,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 858,
            "שם_רחוב": "נצח ישראל"
        },
        {
            "_id": 4550,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 651,
            "שם_רחוב": "סאלק יונה"
        },
        {
            "_id": 4551,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 158,
            "שם_רחוב": "סולד הנריטה"
        },
        {
            "_id": 4552,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 728,
            "שם_רחוב": "סופר אברהם"
        },
        {
            "_id": 4553,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 149,
            "שם_רחוב": "סטרומה"
        },
        {
            "_id": 4554,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 368,
            "שם_רחוב": "סיון"
        },
        {
            "_id": 4555,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 789,
            "שם_רחוב": "סיטרין"
        },
        {
            "_id": 4556,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 188,
            "שם_רחוב": "סילבר אבא הלל"
        },
        {
            "_id": 4557,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 139,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 4558,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 681,
            "שם_רחוב": "סירקין נחמן"
        },
        {
            "_id": 4559,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 338,
            "שם_רחוב": "סלבדור"
        },
        {
            "_id": 4560,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "סמ אל הים"
        },
        {
            "_id": 4561,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 809,
            "שם_רחוב": "סמ יהואש"
        },
        {
            "_id": 4562,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 168,
            "שם_רחוב": "סמטה א"
        },
        {
            "_id": 4563,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 169,
            "שם_רחוב": "סמטה ב"
        },
        {
            "_id": 4564,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 452,
            "שם_רחוב": "סמי אלמגריבי"
        },
        {
            "_id": 4565,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 440,
            "שם_רחוב": "סנפיר"
        },
        {
            "_id": 4566,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 230,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 4567,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 317,
            "שם_רחוב": "ספיח"
        },
        {
            "_id": 4568,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 740,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 4569,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 166,
            "שם_רחוב": "עגנון שי"
        },
        {
            "_id": 4570,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 352,
            "שם_רחוב": "עדי עם שלום"
        },
        {
            "_id": 4571,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 444,
            "שם_רחוב": "עובדי הנמל"
        },
        {
            "_id": 4572,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 461,
            "שם_רחוב": "עוזי חיטמן"
        },
        {
            "_id": 4573,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 251,
            "שם_רחוב": "עופר אברהם"
        },
        {
            "_id": 4574,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 729,
            "שם_רחוב": "עזרא הסופר"
        },
        {
            "_id": 4575,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 786,
            "שם_רחוב": "עינבר"
        },
        {
            "_id": 4576,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 466,
            "שם_רחוב": "עמנואל שפר"
        },
        {
            "_id": 4577,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 877,
            "שם_רחוב": "ענבים"
        },
        {
            "_id": 4578,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 754,
            "שם_רחוב": "עקביא בן מהללאל"
        },
        {
            "_id": 4579,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 337,
            "שם_רחוב": "פאנצו"
        },
        {
            "_id": 4580,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 417,
            "שם_רחוב": "פארן"
        },
        {
            "_id": 4581,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6535,
            "שם_רחוב": "פארק השיטה המלבינה"
        },
        {
            "_id": 4582,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 715,
            "שם_רחוב": "פורצי הדרך"
        },
        {
            "_id": 4583,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 780,
            "שם_רחוב": "פטדה"
        },
        {
            "_id": 4584,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 691,
            "שם_רחוב": "פיינברג יוסף"
        },
        {
            "_id": 4585,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 567,
            "שם_רחוב": "פינטו חיים"
        },
        {
            "_id": 4586,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 291,
            "שם_רחוב": "פן אלכסנדר"
        },
        {
            "_id": 4587,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 749,
            "שם_רחוב": "פנינה"
        },
        {
            "_id": 4588,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 662,
            "שם_רחוב": "פסטר לואי"
        },
        {
            "_id": 4589,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 357,
            "שם_רחוב": "פרופ' אברהם הרשקו"
        },
        {
            "_id": 4590,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 356,
            "שם_רחוב": "פרופ' אהרון צ'חנובר"
        },
        {
            "_id": 4591,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 358,
            "שם_רחוב": "פרופ' דן שכטמן"
        },
        {
            "_id": 4592,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 354,
            "שם_רחוב": "פרופ' ישראל אומן"
        },
        {
            "_id": 4593,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 359,
            "שם_רחוב": "פרופ' מרסל מכלוף"
        },
        {
            "_id": 4594,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 608,
            "שם_רחוב": "פרנק אנה"
        },
        {
            "_id": 4595,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 584,
            "שם_רחוב": "פתח תקווה"
        },
        {
            "_id": 4596,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 410,
            "שם_רחוב": "צאלים"
        },
        {
            "_id": 4597,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 263,
            "שם_רחוב": "צדקת יוסף"
        },
        {
            "_id": 4598,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 463,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 4599,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 864,
            "שם_רחוב": "צור הדסה"
        },
        {
            "_id": 4600,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 195,
            "שם_רחוב": "ציזלינג"
        },
        {
            "_id": 4601,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 591,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 4602,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 552,
            "שם_רחוב": "קארו יוסף"
        },
        {
            "_id": 4603,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 147,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 4604,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 175,
            "שם_רחוב": "קדושי בלזן"
        },
        {
            "_id": 4605,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 404,
            "שם_רחוב": "קדרון"
        },
        {
            "_id": 4606,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 142,
            "שם_רחוב": "קדש"
        },
        {
            "_id": 4607,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 787,
            "שם_רחוב": "קורל"
        },
        {
            "_id": 4608,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 674,
            "שם_רחוב": "קורצ'ק יאנוש"
        },
        {
            "_id": 4609,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 407,
            "שם_רחוב": "קישון"
        },
        {
            "_id": 4610,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 657,
            "שם_רחוב": "קמי אלברט"
        },
        {
            "_id": 4611,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 702,
            "שם_רחוב": "קקל"
        },
        {
            "_id": 4612,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "קרית חרדים"
        },
        {
            "_id": 4613,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "קרית פוניבש"
        },
        {
            "_id": 4614,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 258,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 4615,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 583,
            "שם_רחוב": "ראש פינה"
        },
        {
            "_id": 4616,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 770,
            "שם_רחוב": "רב אשי"
        },
        {
            "_id": 4617,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 771,
            "שם_רחוב": "רב חיסדא"
        },
        {
            "_id": 4618,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 772,
            "שם_רחוב": "רב ינאי"
        },
        {
            "_id": 4619,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 769,
            "שם_רחוב": "רבא"
        },
        {
            "_id": 4620,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 456,
            "שם_רחוב": "רבי דוד אלקיים"
        },
        {
            "_id": 4621,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 462,
            "שם_רחוב": "רבי דוד בן חסין"
        },
        {
            "_id": 4622,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 761,
            "שם_רחוב": "רבי טרפון"
        },
        {
            "_id": 4623,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 762,
            "שם_רחוב": "רבי יהודה הנשיא"
        },
        {
            "_id": 4624,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 266,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 4625,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 622,
            "שם_רחוב": "רבין יצחק"
        },
        {
            "_id": 4626,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 767,
            "שם_רחוב": "רבינא"
        },
        {
            "_id": 4627,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 554,
            "שם_רחוב": "רבנו גרשום"
        },
        {
            "_id": 4628,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 565,
            "שם_רחוב": "רבנו וידל הצרפתי"
        },
        {
            "_id": 4629,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 553,
            "שם_רחוב": "רבנו תם"
        },
        {
            "_id": 4630,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 623,
            "שם_רחוב": "רובינא חנה"
        },
        {
            "_id": 4631,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "רובע א"
        },
        {
            "_id": 4632,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "רובע ב"
        },
        {
            "_id": 4633,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "רובע ג"
        },
        {
            "_id": 4634,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "רובע ה"
        },
        {
            "_id": 4635,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "רובע ו"
        },
        {
            "_id": 4636,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "רובע ז"
        },
        {
            "_id": 4637,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "רובע ח"
        },
        {
            "_id": 4638,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "רובע ט"
        },
        {
            "_id": 4639,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "רובע טו"
        },
        {
            "_id": 4640,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6548,
            "שם_רחוב": "רובע טז"
        },
        {
            "_id": 4641,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "רובע י"
        },
        {
            "_id": 4642,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6546,
            "שם_רחוב": "רובע יב"
        },
        {
            "_id": 4643,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6547,
            "שם_רחוב": "רובע יד"
        },
        {
            "_id": 4644,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "רובע יג"
        },
        {
            "_id": 4645,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6537,
            "שם_רחוב": "רובע יז"
        },
        {
            "_id": 4646,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 128,
            "שם_רחוב": "רוגוזין"
        },
        {
            "_id": 4647,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 686,
            "שם_רחוב": "רופין ארתור"
        },
        {
            "_id": 4648,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 615,
            "שם_רחוב": "רות המואביה"
        },
        {
            "_id": 4649,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 191,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 4650,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 614,
            "שם_רחוב": "רחל אמנו"
        },
        {
            "_id": 4651,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 609,
            "שם_רחוב": "רחל ינאית"
        },
        {
            "_id": 4652,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 469,
            "שם_רחוב": "רלף קליין"
        },
        {
            "_id": 4653,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 157,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 4654,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 667,
            "שם_רחוב": "רמברנדט"
        },
        {
            "_id": 4655,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 663,
            "שם_רחוב": "רנטגן"
        },
        {
            "_id": 4656,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 346,
            "שם_רחוב": "רפאל איתן (רפול)"
        },
        {
            "_id": 4657,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 759,
            "שם_רחוב": "רשבי"
        },
        {
            "_id": 4658,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "ש אגש"
        },
        {
            "_id": 4659,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "ש אזור ד"
        },
        {
            "_id": 4660,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "ש אשטרום"
        },
        {
            "_id": 4661,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "ש חב חשמל"
        },
        {
            "_id": 4662,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "ש חסכון"
        },
        {
            "_id": 4663,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "ש רסקו"
        },
        {
            "_id": 4664,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 676,
            "שם_רחוב": "שאגל מארק"
        },
        {
            "_id": 4665,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שאול בן שמחון"
        },
        {
            "_id": 4666,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 239,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 4667,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 364,
            "שם_רחוב": "שבט"
        },
        {
            "_id": 4668,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 382,
            "שם_רחוב": "שבט אשר"
        },
        {
            "_id": 4669,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 386,
            "שם_רחוב": "שבט בנימין"
        },
        {
            "_id": 4670,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 381,
            "שם_רחוב": "שבט גד"
        },
        {
            "_id": 4671,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 379,
            "שם_רחוב": "שבט דן"
        },
        {
            "_id": 4672,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 384,
            "שם_רחוב": "שבט זבולון"
        },
        {
            "_id": 4673,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 378,
            "שם_רחוב": "שבט יהודה"
        },
        {
            "_id": 4674,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 385,
            "שם_רחוב": "שבט יוסף"
        },
        {
            "_id": 4675,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 383,
            "שם_רחוב": "שבט יששכר"
        },
        {
            "_id": 4676,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 377,
            "שם_רחוב": "שבט לוי"
        },
        {
            "_id": 4677,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 380,
            "שם_רחוב": "שבט נפתלי"
        },
        {
            "_id": 4678,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 375,
            "שם_רחוב": "שבט ראובן"
        },
        {
            "_id": 4679,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 376,
            "שם_רחוב": "שבט שמעון"
        },
        {
            "_id": 4680,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 126,
            "שם_רחוב": "שבי ציון"
        },
        {
            "_id": 4681,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 164,
            "שם_רחוב": "שביל יא"
        },
        {
            "_id": 4682,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 242,
            "שם_רחוב": "שגב"
        },
        {
            "_id": 4683,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 335,
            "שם_רחוב": "שד אלטלנה"
        },
        {
            "_id": 4684,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 597,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 4685,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 165,
            "שם_רחוב": "שד בני ברית"
        },
        {
            "_id": 4686,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 680,
            "שם_רחוב": "שד גורדון"
        },
        {
            "_id": 4687,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 701,
            "שם_רחוב": "שד המיסדים"
        },
        {
            "_id": 4688,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 307,
            "שם_רחוב": "שד הפרחים"
        },
        {
            "_id": 4689,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 189,
            "שם_רחוב": "שד הרצל"
        },
        {
            "_id": 4690,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 186,
            "שם_רחוב": "שד יפה נוף"
        },
        {
            "_id": 4691,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 302,
            "שם_רחוב": "שד ירושלים"
        },
        {
            "_id": 4692,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 677,
            "שם_רחוב": "שד מונטיפיורי"
        },
        {
            "_id": 4693,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 206,
            "שם_רחוב": "שד סנה משה"
        },
        {
            "_id": 4694,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 229,
            "שם_רחוב": "שד עוזיהו"
        },
        {
            "_id": 4695,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 679,
            "שם_רחוב": "שד רוטשילד"
        },
        {
            "_id": 4696,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 678,
            "שם_רחוב": "שד תל חי"
        },
        {
            "_id": 4697,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 698,
            "שם_רחוב": "שדרות הים התיכון"
        },
        {
            "_id": 4698,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 438,
            "שם_רחוב": "שדרות נתיבי הים"
        },
        {
            "_id": 4699,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 575,
            "שם_רחוב": "שדרות ראשון לציון"
        },
        {
            "_id": 4700,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 585,
            "שם_רחוב": "שדרות רחובות"
        },
        {
            "_id": 4701,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 562,
            "שם_רחוב": "שדרות רשי"
        },
        {
            "_id": 4702,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 741,
            "שם_רחוב": "שהם"
        },
        {
            "_id": 4703,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 543,
            "שם_רחוב": "שובר הגלים"
        },
        {
            "_id": 4704,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 610,
            "שם_רחוב": "שוחט מניה"
        },
        {
            "_id": 4705,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 429,
            "שם_רחוב": "שומרון"
        },
        {
            "_id": 4706,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 224,
            "שם_רחוב": "שועלי שמשון"
        },
        {
            "_id": 4707,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 455,
            "שם_רחוב": "שושנה דמארי"
        },
        {
            "_id": 4708,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 536,
            "שם_רחוב": "שושנת הרוחות"
        },
        {
            "_id": 4709,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 226,
            "שם_רחוב": "שחם יואב"
        },
        {
            "_id": 4710,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 655,
            "שם_רחוב": "שילר"
        },
        {
            "_id": 4711,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 355,
            "שם_רחוב": "שיר השירים"
        },
        {
            "_id": 4712,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "שכ ונטורה"
        },
        {
            "_id": 4713,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 154,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 4714,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 605,
            "שם_רחוב": "שלומציון המלכה"
        },
        {
            "_id": 4715,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 350,
            "שם_רחוב": "שלונסקי אברהם"
        },
        {
            "_id": 4716,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 270,
            "שם_רחוב": "שלזינגר ארווין"
        },
        {
            "_id": 4717,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 766,
            "שם_רחוב": "שמאי"
        },
        {
            "_id": 4718,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 218,
            "שם_רחוב": "שמואל הנגיד"
        },
        {
            "_id": 4719,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 750,
            "שם_רחוב": "שמעון בן שטח"
        },
        {
            "_id": 4720,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 470,
            "שם_רחוב": "שמעון מזרחי"
        },
        {
            "_id": 4721,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 764,
            "שם_רחוב": "שמעיה"
        },
        {
            "_id": 4722,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 735,
            "שם_רחוב": "שמר"
        },
        {
            "_id": 4723,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 435,
            "שם_רחוב": "שער האריות"
        },
        {
            "_id": 4724,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 478,
            "שם_רחוב": "שער הים"
        },
        {
            "_id": 4725,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 245,
            "שם_רחוב": "שערי יושר"
        },
        {
            "_id": 4726,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 582,
            "שם_רחוב": "שפיה"
        },
        {
            "_id": 4727,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 202,
            "שם_רחוב": "שפירא אברהם"
        },
        {
            "_id": 4728,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 257,
            "שם_רחוב": "שפירא משה חיים"
        },
        {
            "_id": 4729,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 604,
            "שם_רחוב": "שרה אמנו"
        },
        {
            "_id": 4730,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 6561,
            "שם_רחוב": "תחנת הכח"
        },
        {
            "_id": 4731,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 652,
            "שם_רחוב": "תיאודור רוזוולט"
        },
        {
            "_id": 4732,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 369,
            "שם_רחוב": "תמוז"
        },
        {
            "_id": 4733,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 250,
            "שם_רחוב": "תנין"
        },
        {
            "_id": 4734,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 878,
            "שם_רחוב": "תפוז"
        },
        {
            "_id": 4735,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 871,
            "שם_רחוב": "תפוח"
        },
        {
            "_id": 4736,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 748,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 4737,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 845,
            "שם_רחוב": "תשח"
        },
        {
            "_id": 4738,
            "סמל_ישוב": 70,
            "שם_ישוב": "אשדוד ",
            "סמל_רחוב": 360,
            "שם_רחוב": "תשרי"
        },
        {
            "_id": 4739,
            "סמל_ישוב": 199,
            "שם_ישוב": "אשדות יעקב (איחוד) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשדות יעקב (איחוד)"
        },
        {
            "_id": 4740,
            "סמל_ישוב": 188,
            "שם_ישוב": "אשדות יעקב (מאוחד) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשדות יעקב (מאוחד)"
        },
        {
            "_id": 4741,
            "סמל_ישוב": 1188,
            "שם_ישוב": "אשחר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשחר"
        },
        {
            "_id": 4742,
            "סמל_ישוב": 3722,
            "שם_ישוב": "אשכולות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשכולות"
        },
        {
            "_id": 4743,
            "סמל_ישוב": 3722,
            "שם_ישוב": "אשכולות ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "סנסנה"
        },
        {
            "_id": 4744,
            "סמל_ישוב": 2021,
            "שם_ישוב": "אשל הנשיא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשל הנשיא"
        },
        {
            "_id": 4745,
            "סמל_ישוב": 1152,
            "שם_ישוב": "אשלים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשלים"
        },
        {
            "_id": 4746,
            "סמל_ישוב": 1152,
            "שם_ישוב": "אשלים ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "חוות השחפים"
        },
        {
            "_id": 4747,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1010,
            "שם_רחוב": "אב"
        },
        {
            "_id": 4748,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 660,
            "שם_רחוב": "אבא ווביה"
        },
        {
            "_id": 4749,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 794,
            "שם_רחוב": "אבוקדו"
        },
        {
            "_id": 4750,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1064,
            "שם_רחוב": "אביבית"
        },
        {
            "_id": 4751,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 545,
            "שם_רחוב": "אבימלך"
        },
        {
            "_id": 4752,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1250,
            "שם_רחוב": "אביר יעקב"
        },
        {
            "_id": 4753,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 592,
            "שם_רחוב": "אבישג"
        },
        {
            "_id": 4754,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 457,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 4755,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 770,
            "שם_רחוב": "אבני החושן"
        },
        {
            "_id": 4756,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1110,
            "שם_רחוב": "אבני חן"
        },
        {
            "_id": 4757,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 372,
            "שם_רחוב": "אבנר בן נר"
        },
        {
            "_id": 4758,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 780,
            "שם_רחוב": "אבצן"
        },
        {
            "_id": 4759,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 612,
            "שם_רחוב": "אברבנאל"
        },
        {
            "_id": 4760,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 347,
            "שם_רחוב": "אברהם ברזילי"
        },
        {
            "_id": 4761,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 805,
            "שם_רחוב": "אבשלום"
        },
        {
            "_id": 4762,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 261,
            "שם_רחוב": "אגם דלתון"
        },
        {
            "_id": 4763,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 260,
            "שם_רחוב": "אגם זוהר"
        },
        {
            "_id": 4764,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1302,
            "שם_רחוב": "אגמון"
        },
        {
            "_id": 4765,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1136,
            "שם_רחוב": "אגמית"
        },
        {
            "_id": 4766,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 788,
            "שם_רחוב": "אגס"
        },
        {
            "_id": 4767,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 831,
            "שם_רחוב": "אדית ומשה דורות"
        },
        {
            "_id": 4768,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1194,
            "שם_רחוב": "אדלר חנה"
        },
        {
            "_id": 4769,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 379,
            "שם_רחוב": "אדם יקותיאל"
        },
        {
            "_id": 4770,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 987,
            "שם_רחוב": "אדמונד דה רוטשילד"
        },
        {
            "_id": 4771,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1011,
            "שם_רחוב": "אדר"
        },
        {
            "_id": 4772,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 983,
            "שם_רחוב": "אהבת שלום"
        },
        {
            "_id": 4773,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 541,
            "שם_רחוב": "אהוד"
        },
        {
            "_id": 4774,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1257,
            "שם_רחוב": "אהרון עמיקם"
        },
        {
            "_id": 4775,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 896,
            "שם_רחוב": "אהרונסון שרה"
        },
        {
            "_id": 4776,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 775,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 4777,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 475,
            "שם_רחוב": "אוסישקין"
        },
        {
            "_id": 4778,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1095,
            "שם_רחוב": "אופירה"
        },
        {
            "_id": 4779,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 979,
            "שם_רחוב": "אור החיים"
        },
        {
            "_id": 4780,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 974,
            "שם_רחוב": "אורה ושמחה"
        },
        {
            "_id": 4781,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 377,
            "שם_רחוב": "אורט"
        },
        {
            "_id": 4782,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6548,
            "שם_רחוב": "אז תעשיה גבעת ציון"
        },
        {
            "_id": 4783,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "אזור הסיטי"
        },
        {
            "_id": 4784,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6547,
            "שם_רחוב": "אזור תעשייה דרום"
        },
        {
            "_id": 4785,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6546,
            "שם_רחוב": "אזור תעשייה צפון"
        },
        {
            "_id": 4786,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1320,
            "שם_רחוב": "אחי דקר"
        },
        {
            "_id": 4787,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 890,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 4788,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1186,
            "שם_רחוב": "אחווה"
        },
        {
            "_id": 4789,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 384,
            "שם_רחוב": "אחוזת בית"
        },
        {
            "_id": 4790,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 378,
            "שם_רחוב": "אחימאיר אבא"
        },
        {
            "_id": 4791,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 682,
            "שם_רחוב": "אחישר אריה"
        },
        {
            "_id": 4792,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 772,
            "שם_רחוב": "אחלמה"
        },
        {
            "_id": 4793,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 606,
            "שם_רחוב": "איגר עקיבא"
        },
        {
            "_id": 4794,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1050,
            "שם_רחוב": "איילה"
        },
        {
            "_id": 4795,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1012,
            "שם_רחוב": "אייר"
        },
        {
            "_id": 4796,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1147,
            "שם_רחוב": "אילים"
        },
        {
            "_id": 4797,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 304,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 4798,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1084,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 4799,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 763,
            "שם_רחוב": "איתן רפאל"
        },
        {
            "_id": 4800,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 813,
            "שם_רחוב": "אלבז"
        },
        {
            "_id": 4801,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1033,
            "שם_רחוב": "אלול"
        },
        {
            "_id": 4802,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 419,
            "שם_רחוב": "אלון יעקב"
        },
        {
            "_id": 4803,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 416,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 4804,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1177,
            "שם_רחוב": "אלי סיני"
        },
        {
            "_id": 4805,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 754,
            "שם_רחוב": "אליאב אורי"
        },
        {
            "_id": 4806,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 964,
            "שם_רחוב": "אליהו גניש"
        },
        {
            "_id": 4807,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 514,
            "שם_רחוב": "אליהו הנביא"
        },
        {
            "_id": 4808,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 522,
            "שם_רחוב": "אלישע"
        },
        {
            "_id": 4809,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 721,
            "שם_רחוב": "אלכסנדרוני"
        },
        {
            "_id": 4810,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 994,
            "שם_רחוב": "אלמגריבי סמי"
        },
        {
            "_id": 4811,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 981,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 4812,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 814,
            "שם_רחוב": "אלעד"
        },
        {
            "_id": 4813,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 993,
            "שם_רחוב": "אלפסיה זוהרה"
        },
        {
            "_id": 4814,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 891,
            "שם_רחוב": "אלקלעי יהודה"
        },
        {
            "_id": 4815,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 598,
            "שם_רחוב": "אלקנה"
        },
        {
            "_id": 4816,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אמנון"
        },
        {
            "_id": 4817,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 487,
            "שם_רחוב": "אנה פרנק"
        },
        {
            "_id": 4818,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 424,
            "שם_רחוב": "אנחל סאנס בריס"
        },
        {
            "_id": 4819,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1166,
            "שם_רחוב": "אנייה"
        },
        {
            "_id": 4820,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 680,
            "שם_רחוב": "אנילביץ מרדכי"
        },
        {
            "_id": 4821,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 793,
            "שם_רחוב": "אננס"
        },
        {
            "_id": 4822,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1135,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 4823,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 585,
            "שם_רחוב": "אסף הרופא"
        },
        {
            "_id": 4824,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 593,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 4825,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1304,
            "שם_רחוב": "אפיקי מים"
        },
        {
            "_id": 4826,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 337,
            "שם_רחוב": "אפל דוד וצילה"
        },
        {
            "_id": 4827,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 506,
            "שם_רחוב": "אפרים"
        },
        {
            "_id": 4828,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 848,
            "שם_רחוב": "אפרסמון"
        },
        {
            "_id": 4829,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 790,
            "שם_רחוב": "אפרסק"
        },
        {
            "_id": 4830,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1217,
            "שם_רחוב": "אצוה"
        },
        {
            "_id": 4831,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1018,
            "שם_רחוב": "אקסודוס"
        },
        {
            "_id": 4832,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1224,
            "שם_רחוב": "ארגמן"
        },
        {
            "_id": 4833,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 567,
            "שם_רחוב": "ארד"
        },
        {
            "_id": 4834,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1006,
            "שם_רחוב": "אריאל"
        },
        {
            "_id": 4835,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 421,
            "שם_רחוב": "אריאל שרון"
        },
        {
            "_id": 4836,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1053,
            "שם_רחוב": "אריה"
        },
        {
            "_id": 4837,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1256,
            "שם_רחוב": "אריה כץ"
        },
        {
            "_id": 4838,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1120,
            "שם_רחוב": "אשדוד"
        },
        {
            "_id": 4839,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1075,
            "שם_רחוב": "אשכולי פז"
        },
        {
            "_id": 4840,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 795,
            "שם_רחוב": "אשכולית"
        },
        {
            "_id": 4841,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשקלון"
        },
        {
            "_id": 4842,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 553,
            "שם_רחוב": "אשר"
        },
        {
            "_id": 4843,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1127,
            "שם_רחוב": "אשתאול"
        },
        {
            "_id": 4844,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1139,
            "שם_רחוב": "אתם"
        },
        {
            "_id": 4845,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 821,
            "שם_רחוב": "בדולח"
        },
        {
            "_id": 4846,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1117,
            "שם_רחוב": "בהט"
        },
        {
            "_id": 4847,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 658,
            "שם_רחוב": "בוטראשווילי אהרון"
        },
        {
            "_id": 4848,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 559,
            "שם_רחוב": "בועז"
        },
        {
            "_id": 4849,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 555,
            "שם_רחוב": "בוקרה רחמים"
        },
        {
            "_id": 4850,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 462,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 4851,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 632,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 4852,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 829,
            "שם_רחוב": "ביכורי ענבים"
        },
        {
            "_id": 4853,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 677,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 4854,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 564,
            "שם_רחוב": "בית אבות"
        },
        {
            "_id": 4855,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1121,
            "שם_רחוב": "בית אל"
        },
        {
            "_id": 4856,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1314,
            "שם_רחוב": "בית הבאר"
        },
        {
            "_id": 4857,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 476,
            "שם_רחוב": "בית המשפט"
        },
        {
            "_id": 4858,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 584,
            "שם_רחוב": "בית השואבה"
        },
        {
            "_id": 4859,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 634,
            "שם_רחוב": "בית זולוטוב חדש"
        },
        {
            "_id": 4860,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 633,
            "שם_רחוב": "בית זולוטוב ישן"
        },
        {
            "_id": 4861,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 932,
            "שם_רחוב": "ביתר"
        },
        {
            "_id": 4862,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 989,
            "שם_רחוב": "בלסבלג אברהם"
        },
        {
            "_id": 4863,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 900,
            "שם_רחוב": "בלפור"
        },
        {
            "_id": 4864,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 884,
            "שם_רחוב": "בן אליעזר אריה"
        },
        {
            "_id": 4865,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 941,
            "שם_רחוב": "בן דוד"
        },
        {
            "_id": 4866,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 650,
            "שם_רחוב": "בן זומא"
        },
        {
            "_id": 4867,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 960,
            "שם_רחוב": "בן יהודה אליעזר"
        },
        {
            "_id": 4868,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 669,
            "שם_רחוב": "בן עוזאי"
        },
        {
            "_id": 4869,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 646,
            "שם_רחוב": "בן עטר"
        },
        {
            "_id": 4870,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 826,
            "שם_רחוב": "בן עמר"
        },
        {
            "_id": 4871,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 375,
            "שם_רחוב": "בן צבי יצחק"
        },
        {
            "_id": 4872,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 651,
            "שם_רחוב": "בן שטח"
        },
        {
            "_id": 4873,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 725,
            "שם_רחוב": "בני זמירו"
        },
        {
            "_id": 4874,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 933,
            "שם_רחוב": "בני עקיבא"
        },
        {
            "_id": 4875,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 552,
            "שם_רחוב": "בנימין"
        },
        {
            "_id": 4876,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 610,
            "שם_רחוב": "בעל שם טוב"
        },
        {
            "_id": 4877,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 265,
            "שם_רחוב": "בקעת סיירים"
        },
        {
            "_id": 4878,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 266,
            "שם_רחוב": "בקעת רימון"
        },
        {
            "_id": 4879,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 453,
            "שם_רחוב": "בר יוחאי"
        },
        {
            "_id": 4880,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 394,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 4881,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1133,
            "שם_רחוב": "ברבור"
        },
        {
            "_id": 4882,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 635,
            "שם_רחוב": "ברגסון"
        },
        {
            "_id": 4883,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 648,
            "שם_רחוב": "ברגר דוד"
        },
        {
            "_id": 4884,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 591,
            "שם_רחוב": "ברוריה"
        },
        {
            "_id": 4885,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 668,
            "שם_רחוב": "ברטנורא"
        },
        {
            "_id": 4886,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 376,
            "שם_רחוב": "ברייר דב"
        },
        {
            "_id": 4887,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 273,
            "שם_רחוב": "בריכת המשושים"
        },
        {
            "_id": 4888,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 272,
            "שם_רחוב": "בריכת רם"
        },
        {
            "_id": 4889,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1233,
            "שם_רחוב": "בריק"
        },
        {
            "_id": 4890,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 815,
            "שם_רחוב": "ברנשטיין פרץ"
        },
        {
            "_id": 4891,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 393,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 4892,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 820,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 4893,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1218,
            "שם_רחוב": "בשומת"
        },
        {
            "_id": 4894,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 827,
            "שם_רחוב": "בת גלים"
        },
        {
            "_id": 4895,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 751,
            "שם_רחוב": "בת חן"
        },
        {
            "_id": 4896,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "בתי ברמן"
        },
        {
            "_id": 4897,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "בתי לוינסון"
        },
        {
            "_id": 4898,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "בתי רסקו"
        },
        {
            "_id": 4899,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 998,
            "שם_רחוב": "ג'ו וסטנלי כהן"
        },
        {
            "_id": 4900,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1122,
            "שם_רחוב": "גבע"
        },
        {
            "_id": 4901,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 465,
            "שם_רחוב": "גבעון"
        },
        {
            "_id": 4902,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 348,
            "שם_רחוב": "גבעת המים"
        },
        {
            "_id": 4903,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "גבעת הפרחים"
        },
        {
            "_id": 4904,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "גבעת ציון"
        },
        {
            "_id": 4905,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 382,
            "שם_רחוב": "גבעתי"
        },
        {
            "_id": 4906,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 550,
            "שם_רחוב": "גד"
        },
        {
            "_id": 4907,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 645,
            "שם_רחוב": "גדוד הפורצים"
        },
        {
            "_id": 4908,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6048,
            "שם_רחוב": "גדוד עוז שבעים ושבע"
        },
        {
            "_id": 4909,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 539,
            "שם_רחוב": "גדעון"
        },
        {
            "_id": 4910,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 391,
            "שם_רחוב": "גדעון בן יואש"
        },
        {
            "_id": 4911,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1202,
            "שם_רחוב": "גובר רבקה"
        },
        {
            "_id": 4912,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 386,
            "שם_רחוב": "גולני"
        },
        {
            "_id": 4913,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1305,
            "שם_רחוב": "גומא"
        },
        {
            "_id": 4914,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 473,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 4915,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 364,
            "שם_רחוב": "גורן יונה"
        },
        {
            "_id": 4916,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 412,
            "שם_רחוב": "גוש 1250"
        },
        {
            "_id": 4917,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 409,
            "שם_רחוב": "גוש 1252"
        },
        {
            "_id": 4918,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 405,
            "שם_רחוב": "גוש 1243"
        },
        {
            "_id": 4919,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 411,
            "שם_רחוב": "גוש 1253"
        },
        {
            "_id": 4920,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 415,
            "שם_רחוב": "גוש 1234"
        },
        {
            "_id": 4921,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 401,
            "שם_רחוב": "גוש 1244"
        },
        {
            "_id": 4922,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 414,
            "שם_רחוב": "גוש 1254"
        },
        {
            "_id": 4923,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 429,
            "שם_רחוב": "גוש 1246"
        },
        {
            "_id": 4924,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 410,
            "שם_רחוב": "גוש 1257"
        },
        {
            "_id": 4925,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 408,
            "שם_רחוב": "גוש 1218"
        },
        {
            "_id": 4926,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 417,
            "שם_רחוב": "גוש 1228"
        },
        {
            "_id": 4927,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 438,
            "שם_רחוב": "גוש 1248"
        },
        {
            "_id": 4928,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 302,
            "שם_רחוב": "גוש עציון"
        },
        {
            "_id": 4929,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1003,
            "שם_רחוב": "גושן"
        },
        {
            "_id": 4930,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1182,
            "שם_רחוב": "גילה"
        },
        {
            "_id": 4931,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1005,
            "שם_רחוב": "גינוסר"
        },
        {
            "_id": 4932,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 448,
            "שם_רחוב": "גלמן פולינה"
        },
        {
            "_id": 4933,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1007,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 4934,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1019,
            "שם_רחוב": "גמלא"
        },
        {
            "_id": 4935,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "גן הורדים"
        },
        {
            "_id": 4936,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "גני ברנע"
        },
        {
            "_id": 4937,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1097,
            "שם_רחוב": "גרופית"
        },
        {
            "_id": 4938,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 605,
            "שם_רחוב": "גרופר יעקב"
        },
        {
            "_id": 4939,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 711,
            "שם_רחוב": "גרשון"
        },
        {
            "_id": 4940,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 980,
            "שם_רחוב": "גשר החיים"
        },
        {
            "_id": 4941,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 463,
            "שם_רחוב": "דר בוטוויניק מיכאל"
        },
        {
            "_id": 4942,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1129,
            "שם_רחוב": "דר עדי רן כהן"
        },
        {
            "_id": 4943,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 526,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 4944,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 731,
            "שם_רחוב": "דביר"
        },
        {
            "_id": 4945,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1069,
            "שם_רחוב": "דובב"
        },
        {
            "_id": 4946,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1063,
            "שם_רחוב": "דוגית"
        },
        {
            "_id": 4947,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 918,
            "שם_רחוב": "דוד אריאל"
        },
        {
            "_id": 4948,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 563,
            "שם_רחוב": "דוד חג'ג'"
        },
        {
            "_id": 4949,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 752,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 4950,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 253,
            "שם_רחוב": "דולפין"
        },
        {
            "_id": 4951,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1312,
            "שם_רחוב": "דורה"
        },
        {
            "_id": 4952,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1221,
            "שם_רחוב": "דוריף"
        },
        {
            "_id": 4953,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1046,
            "שם_רחוב": "דותן"
        },
        {
            "_id": 4954,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 604,
            "שם_רחוב": "דיבולט"
        },
        {
            "_id": 4955,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 986,
            "שם_רחוב": "דיזראלי"
        },
        {
            "_id": 4956,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 346,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 4957,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1180,
            "שם_רחוב": "דיצה"
        },
        {
            "_id": 4958,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 759,
            "שם_רחוב": "דליות"
        },
        {
            "_id": 4959,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1203,
            "שם_רחוב": "דמארי שושנה"
        },
        {
            "_id": 4960,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 548,
            "שם_רחוב": "דן"
        },
        {
            "_id": 4961,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1119,
            "שם_רחוב": "דן שומרון"
        },
        {
            "_id": 4962,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 524,
            "שם_רחוב": "דניאל"
        },
        {
            "_id": 4963,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 753,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 4964,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1307,
            "שם_רחוב": "דרדר"
        },
        {
            "_id": 4965,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1210,
            "שם_רחוב": "דרך ארץ"
        },
        {
            "_id": 4966,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 845,
            "שם_רחוב": "דרך היין"
        },
        {
            "_id": 4967,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1211,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 4968,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 762,
            "שם_רחוב": "דרכי איש"
        },
        {
            "_id": 4969,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 799,
            "שם_רחוב": "דרכי נועם"
        },
        {
            "_id": 4970,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 486,
            "שם_רחוב": "האבץ"
        },
        {
            "_id": 4971,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 850,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 4972,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1164,
            "שם_רחוב": "האדווה"
        },
        {
            "_id": 4973,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 730,
            "שם_רחוב": "האומן"
        },
        {
            "_id": 4974,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1171,
            "שם_רחוב": "האומץ"
        },
        {
            "_id": 4975,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 741,
            "שם_רחוב": "האופה"
        },
        {
            "_id": 4976,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 812,
            "שם_רחוב": "האופן"
        },
        {
            "_id": 4977,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1155,
            "שם_רחוב": "האוקיינוס"
        },
        {
            "_id": 4978,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 742,
            "שם_רחוב": "האורג"
        },
        {
            "_id": 4979,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 488,
            "שם_רחוב": "האורים"
        },
        {
            "_id": 4980,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 335,
            "שם_רחוב": "האילן"
        },
        {
            "_id": 4981,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 356,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 4982,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 594,
            "שם_רחוב": "האמהות"
        },
        {
            "_id": 4983,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1160,
            "שם_רחוב": "האפיק"
        },
        {
            "_id": 4984,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 611,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 4985,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 305,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 4986,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1162,
            "שם_רחוב": "האשד"
        },
        {
            "_id": 4987,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 332,
            "שם_רחוב": "האשוח"
        },
        {
            "_id": 4988,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 314,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 4989,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 368,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 4990,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 579,
            "שם_רחוב": "הבדיל"
        },
        {
            "_id": 4991,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 840,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 4992,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1231,
            "שם_רחוב": "הבוצרים"
        },
        {
            "_id": 4993,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 572,
            "שם_רחוב": "הבנאי"
        },
        {
            "_id": 4994,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 816,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 4995,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 750,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 4996,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 352,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 4997,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 575,
            "שם_רחוב": "הברזל"
        },
        {
            "_id": 4998,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 657,
            "שם_רחוב": "הבשן"
        },
        {
            "_id": 4999,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1156,
            "שם_רחוב": "הגאות"
        },
        {
            "_id": 5000,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1070,
            "שם_רחוב": "הגבורה"
        },
        {
            "_id": 5001,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 479,
            "שם_רחוב": "הגביש"
        },
        {
            "_id": 5002,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 467,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 5003,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 469,
            "שם_רחוב": "הגדנע"
        },
        {
            "_id": 5004,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1045,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 5005,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 616,
            "שם_רחוב": "הגולשים"
        },
        {
            "_id": 5006,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 811,
            "שם_רחוב": "הגלגל"
        },
        {
            "_id": 5007,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 931,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 5008,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 877,
            "שם_רחוב": "הגלשן"
        },
        {
            "_id": 5009,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 344,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 5010,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 580,
            "שם_רחוב": "הגרא"
        },
        {
            "_id": 5011,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1229,
            "שם_רחוב": "הגתות"
        },
        {
            "_id": 5012,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 609,
            "שם_רחוב": "הדגל"
        },
        {
            "_id": 5013,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 849,
            "שם_רחוב": "הדובדבן"
        },
        {
            "_id": 5014,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 617,
            "שם_רחוב": "הדייגים"
        },
        {
            "_id": 5015,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 679,
            "שם_רחוב": "הדישון"
        },
        {
            "_id": 5016,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 342,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 5017,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 321,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 5018,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 468,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 5019,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 259,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 5020,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 320,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 5021,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 630,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 5022,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 327,
            "שם_רחוב": "ההרדוף"
        },
        {
            "_id": 5023,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 308,
            "שם_רחוב": "הוורדים"
        },
        {
            "_id": 5024,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 899,
            "שם_רחוב": "הולצברג שמחה"
        },
        {
            "_id": 5025,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 518,
            "שם_רחוב": "הושע"
        },
        {
            "_id": 5026,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 924,
            "שם_רחוב": "הזגג"
        },
        {
            "_id": 5027,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 328,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 5028,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 495,
            "שם_רחוב": "הזכוכית"
        },
        {
            "_id": 5029,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 341,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 5030,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 872,
            "שם_רחוב": "הזריחה"
        },
        {
            "_id": 5031,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 309,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 5032,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 638,
            "שם_רחוב": "החובלים"
        },
        {
            "_id": 5033,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1004,
            "שם_רחוב": "החולה"
        },
        {
            "_id": 5034,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 369,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 5035,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 470,
            "שם_רחוב": "החלוץ"
        },
        {
            "_id": 5036,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 334,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 5037,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 588,
            "שם_רחוב": "החרט"
        },
        {
            "_id": 5038,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 749,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 5039,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 667,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 5040,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 732,
            "שם_רחוב": "החשמל"
        },
        {
            "_id": 5041,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 926,
            "שם_רחוב": "הטוחן"
        },
        {
            "_id": 5042,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 381,
            "שם_רחוב": "הטייסים"
        },
        {
            "_id": 5043,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1157,
            "שם_רחוב": "היובל"
        },
        {
            "_id": 5044,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1213,
            "שם_רחוב": "היוצרים"
        },
        {
            "_id": 5045,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1225,
            "שם_רחוב": "היינן"
        },
        {
            "_id": 5046,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1165,
            "שם_רחוב": "הים התיכון"
        },
        {
            "_id": 5047,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 499,
            "שם_רחוב": "היצול"
        },
        {
            "_id": 5048,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 755,
            "שם_רחוב": "היקב"
        },
        {
            "_id": 5049,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 684,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 5050,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 683,
            "שם_רחוב": "הירמוך"
        },
        {
            "_id": 5051,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 671,
            "שם_רחוב": "הירקון"
        },
        {
            "_id": 5052,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 857,
            "שם_רחוב": "הכדים"
        },
        {
            "_id": 5053,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1230,
            "שם_רחוב": "הכורמים"
        },
        {
            "_id": 5054,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 810,
            "שם_רחוב": "הכישור"
        },
        {
            "_id": 5055,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 360,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 5056,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 464,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 5057,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 373,
            "שם_רחוב": "הלוז"
        },
        {
            "_id": 5058,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1000,
            "שם_רחוב": "הלוח העברי"
        },
        {
            "_id": 5059,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1047,
            "שם_רחוב": "הלוחם"
        },
        {
            "_id": 5060,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 367,
            "שם_רחוב": "הלולב"
        },
        {
            "_id": 5061,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 565,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 5062,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 458,
            "שם_רחוב": "הלל"
        },
        {
            "_id": 5063,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1031,
            "שם_רחוב": "המופת"
        },
        {
            "_id": 5064,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 343,
            "שם_רחוב": "המורה מאיר"
        },
        {
            "_id": 5065,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 498,
            "שם_רחוב": "המחוגה"
        },
        {
            "_id": 5066,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 948,
            "שם_רחוב": "המחרשה"
        },
        {
            "_id": 5067,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1215,
            "שם_רחוב": "המיל השלישי"
        },
        {
            "_id": 5068,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 947,
            "שם_רחוב": "המלגזה"
        },
        {
            "_id": 5069,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 618,
            "שם_רחוב": "המלחים"
        },
        {
            "_id": 5070,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 587,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 5071,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 494,
            "שם_רחוב": "המסלול"
        },
        {
            "_id": 5072,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1161,
            "שם_רחוב": "המעיין"
        },
        {
            "_id": 5073,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1158,
            "שם_רחוב": "המפל"
        },
        {
            "_id": 5074,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 914,
            "שם_רחוב": "המציל היהודי"
        },
        {
            "_id": 5075,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 500,
            "שם_רחוב": "המרכבה"
        },
        {
            "_id": 5076,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 442,
            "שם_רחוב": "המשנה"
        },
        {
            "_id": 5077,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 744,
            "שם_רחוב": "המתכנן"
        },
        {
            "_id": 5078,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 992,
            "שם_רחוב": "המתמיד"
        },
        {
            "_id": 5079,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 517,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 5080,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1163,
            "שם_רחוב": "הנביעות"
        },
        {
            "_id": 5081,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1072,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 5082,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 589,
            "שם_רחוב": "הנגר"
        },
        {
            "_id": 5083,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 502,
            "שם_רחוב": "הנוטרים"
        },
        {
            "_id": 5084,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 934,
            "שם_רחוב": "הנוער העובד"
        },
        {
            "_id": 5085,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 659,
            "שם_רחוב": "הנחשול"
        },
        {
            "_id": 5086,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1106,
            "שם_רחוב": "הנמל"
        },
        {
            "_id": 5087,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 745,
            "שם_רחוב": "הנפח"
        },
        {
            "_id": 5088,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 359,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 5089,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 319,
            "שם_רחוב": "הנשיא"
        },
        {
            "_id": 5090,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1315,
            "שם_רחוב": "הסביל"
        },
        {
            "_id": 5091,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 603,
            "שם_רחוב": "הסוחר"
        },
        {
            "_id": 5092,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 838,
            "שם_רחוב": "הסוכה"
        },
        {
            "_id": 5093,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 935,
            "שם_רחוב": "הסוכנות היהודית"
        },
        {
            "_id": 5094,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 443,
            "שם_רחוב": "הסופרים היהודים"
        },
        {
            "_id": 5095,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 366,
            "שם_רחוב": "הסנה"
        },
        {
            "_id": 5096,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 322,
            "שם_רחוב": "הסנונית"
        },
        {
            "_id": 5097,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 623,
            "שם_רחוב": "הספנים"
        },
        {
            "_id": 5098,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 946,
            "שם_רחוב": "הסרגל"
        },
        {
            "_id": 5099,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 440,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 5100,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 949,
            "שם_רחוב": "העגלה"
        },
        {
            "_id": 5101,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 982,
            "שם_רחוב": "העוגן"
        },
        {
            "_id": 5102,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1091,
            "שם_רחוב": "העונות"
        },
        {
            "_id": 5103,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 576,
            "שם_רחוב": "העופרת"
        },
        {
            "_id": 5104,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 425,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 5105,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 746,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 5106,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 756,
            "שם_רחוב": "הענב"
        },
        {
            "_id": 5107,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 301,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 5108,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 358,
            "שם_רחוב": "הערמון"
        },
        {
            "_id": 5109,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1159,
            "שם_רחוב": "הפלג"
        },
        {
            "_id": 5110,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 503,
            "שם_רחוב": "הפלדה"
        },
        {
            "_id": 5111,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 482,
            "שם_רחוב": "הפלטינה"
        },
        {
            "_id": 5112,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 471,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 5113,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 493,
            "שם_רחוב": "הפנינים"
        },
        {
            "_id": 5114,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 361,
            "שם_רחוב": "הפרג"
        },
        {
            "_id": 5115,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1076,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 5116,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 331,
            "שם_רחוב": "הצאלה"
        },
        {
            "_id": 5117,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 407,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 5118,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 876,
            "שם_רחוב": "הצדפים"
        },
        {
            "_id": 5119,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 936,
            "שם_רחוב": "הצופים"
        },
        {
            "_id": 5120,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 878,
            "שם_רחוב": "הצוק"
        },
        {
            "_id": 5121,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 747,
            "שם_רחוב": "הצורף"
        },
        {
            "_id": 5122,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 383,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 5123,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 357,
            "שם_רחוב": "הצפצפה"
        },
        {
            "_id": 5124,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 925,
            "שם_רחוב": "הקדר"
        },
        {
            "_id": 5125,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 542,
            "שם_רחוב": "הקוממיות"
        },
        {
            "_id": 5126,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 873,
            "שם_רחוב": "הקונכייה"
        },
        {
            "_id": 5127,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 365,
            "שם_רחוב": "הקיקיון"
        },
        {
            "_id": 5128,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 885,
            "שם_רחוב": "הר ארבל"
        },
        {
            "_id": 5129,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 889,
            "שם_רחוב": "הר חצור"
        },
        {
            "_id": 5130,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 913,
            "שם_רחוב": "הר חרמון"
        },
        {
            "_id": 5131,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 886,
            "שם_רחוב": "הר כנען"
        },
        {
            "_id": 5132,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 888,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 5133,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 887,
            "שם_רחוב": "הר תבור"
        },
        {
            "_id": 5134,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 385,
            "שם_רחוב": "הראל"
        },
        {
            "_id": 5135,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 991,
            "שם_רחוב": "הרב אונטרמן"
        },
        {
            "_id": 5136,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 764,
            "שם_רחוב": "הרב בוזגלו דוד"
        },
        {
            "_id": 5137,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1193,
            "שם_רחוב": "הרב בן דיוואן עמרם"
        },
        {
            "_id": 5138,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1191,
            "שם_רחוב": "הרב בן חמו יעקב"
        },
        {
            "_id": 5139,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 743,
            "שם_רחוב": "הרב ברוך אברהמי"
        },
        {
            "_id": 5140,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 444,
            "שם_רחוב": "הרב גניש חואטו"
        },
        {
            "_id": 5141,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 596,
            "שם_רחוב": "הרב דוד אברג'ל"
        },
        {
            "_id": 5142,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 966,
            "שם_רחוב": "הרב דוד קדוש"
        },
        {
            "_id": 5143,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 450,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 5144,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 733,
            "שם_רחוב": "הרב חי טייב"
        },
        {
            "_id": 5145,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1130,
            "שם_רחוב": "הרב חיים שושנה"
        },
        {
            "_id": 5146,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 672,
            "שם_רחוב": "הרב טולדנו"
        },
        {
            "_id": 5147,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1252,
            "שם_רחוב": "הרב יוסף חיים בלוי"
        },
        {
            "_id": 5148,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1251,
            "שם_רחוב": "הרב יוסף שרביט"
        },
        {
            "_id": 5149,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1152,
            "שם_רחוב": "הרב יורם אברג'ל"
        },
        {
            "_id": 5150,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 428,
            "שם_רחוב": "הרב יצחק ברזני"
        },
        {
            "_id": 5151,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 445,
            "שם_רחוב": "הרב כהנא חיים"
        },
        {
            "_id": 5152,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 737,
            "שם_רחוב": "הרב כלפון משה הכהן"
        },
        {
            "_id": 5153,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 761,
            "שם_רחוב": "הרב לוין אריה"
        },
        {
            "_id": 5154,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1255,
            "שם_רחוב": "הרב מאיר יהודה גץ"
        },
        {
            "_id": 5155,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 681,
            "שם_רחוב": "הרב מימון"
        },
        {
            "_id": 5156,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 990,
            "שם_רחוב": "הרב מנצור בן שמעון"
        },
        {
            "_id": 5157,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1301,
            "שם_רחוב": "הרב מצליח מאזוז"
        },
        {
            "_id": 5158,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 719,
            "שם_רחוב": "הרב מרדכי אליהו"
        },
        {
            "_id": 5159,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 735,
            "שם_רחוב": "הרב משאש שלום"
        },
        {
            "_id": 5160,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 285,
            "שם_רחוב": "הרב משה ג'יהסי"
        },
        {
            "_id": 5161,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 952,
            "שם_רחוב": "הרב משה צבי נריה"
        },
        {
            "_id": 5162,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 996,
            "שם_רחוב": "הרב נבון גד"
        },
        {
            "_id": 5163,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 374,
            "שם_רחוב": "הרב נורוק"
        },
        {
            "_id": 5164,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 254,
            "שם_רחוב": "הרב נסים יצחק"
        },
        {
            "_id": 5165,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 724,
            "שם_רחוב": "הרב סבג ברוך"
        },
        {
            "_id": 5166,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 556,
            "שם_רחוב": "הרב ספו"
        },
        {
            "_id": 5167,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 629,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 5168,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 736,
            "שם_רחוב": "הרב ערוסי יחיא"
        },
        {
            "_id": 5169,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 422,
            "שם_רחוב": "הרב פינטו"
        },
        {
            "_id": 5170,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 557,
            "שם_רחוב": "הרב פרג'ון"
        },
        {
            "_id": 5171,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 729,
            "שם_רחוב": "הרב צבי יהודה קוק"
        },
        {
            "_id": 5172,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 942,
            "שם_רחוב": "הרב קארו יוסף"
        },
        {
            "_id": 5173,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 995,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 5174,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1192,
            "שם_רחוב": "הרב רקח יעקב"
        },
        {
            "_id": 5175,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 478,
            "שם_רחוב": "הרב שאול הכהן"
        },
        {
            "_id": 5176,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 897,
            "שם_רחוב": "הרב שלמה גורן"
        },
        {
            "_id": 5177,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1300,
            "שם_רחוב": "הרבי מלובביץ'"
        },
        {
            "_id": 5178,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 955,
            "שם_רחוב": "הרבנית ברכה קפאח"
        },
        {
            "_id": 5179,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 940,
            "שם_רחוב": "הריף"
        },
        {
            "_id": 5180,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 317,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 5181,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 566,
            "שם_רחוב": "הרכבת"
        },
        {
            "_id": 5182,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 927,
            "שם_רחוב": "הרפד"
        },
        {
            "_id": 5183,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 435,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 5184,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 371,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 5185,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 570,
            "שם_רחוב": "הרתך"
        },
        {
            "_id": 5186,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 554,
            "שם_רחוב": "השבטים"
        },
        {
            "_id": 5187,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 858,
            "שם_רחוב": "השוטר"
        },
        {
            "_id": 5188,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 797,
            "שם_רחוב": "השולמית"
        },
        {
            "_id": 5189,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 937,
            "שם_רחוב": "השומר הצעיר"
        },
        {
            "_id": 5190,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1038,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 5191,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1107,
            "שם_רחוב": "השונית"
        },
        {
            "_id": 5192,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 538,
            "שם_רחוב": "השופטים"
        },
        {
            "_id": 5193,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 307,
            "שם_רחוב": "השושנים"
        },
        {
            "_id": 5194,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 655,
            "שם_רחוב": "השחיינים"
        },
        {
            "_id": 5195,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 329,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 5196,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 362,
            "שם_רחוב": "השייטת"
        },
        {
            "_id": 5197,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 370,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 5198,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1067,
            "שם_רחוב": "השפלה"
        },
        {
            "_id": 5199,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 427,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 5200,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 871,
            "שם_רחוב": "השקיעה"
        },
        {
            "_id": 5201,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 315,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 5202,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1111,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 5203,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 851,
            "שם_רחוב": "השריונאים"
        },
        {
            "_id": 5204,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 323,
            "שם_רחוב": "התור"
        },
        {
            "_id": 5205,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 355,
            "שם_רחוב": "התותחנים"
        },
        {
            "_id": 5206,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 507,
            "שם_רחוב": "התחיה"
        },
        {
            "_id": 5207,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 446,
            "שם_רחוב": "התלמוד"
        },
        {
            "_id": 5208,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 312,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 5209,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1077,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 5210,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 333,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 5211,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 906,
            "שם_רחוב": "התקווה"
        },
        {
            "_id": 5212,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 984,
            "שם_רחוב": "ובר משה"
        },
        {
            "_id": 5213,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 727,
            "שם_רחוב": "וייס יעקב"
        },
        {
            "_id": 5214,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 898,
            "שם_רחוב": "ויצמן חיים"
        },
        {
            "_id": 5215,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1190,
            "שם_רחוב": "וקסנר בלה"
        },
        {
            "_id": 5216,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 796,
            "שם_רחוב": "ורון יורם"
        },
        {
            "_id": 5217,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 643,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 5218,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 765,
            "שם_רחוב": "זאבי רחבעם"
        },
        {
            "_id": 5219,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 544,
            "שם_רחוב": "זבולון"
        },
        {
            "_id": 5220,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 874,
            "שם_רחוב": "זהבון"
        },
        {
            "_id": 5221,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 313,
            "שם_רחוב": "זונאבנד יחזקאל"
        },
        {
            "_id": 5222,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 836,
            "שם_רחוב": "זכרון יעקב"
        },
        {
            "_id": 5223,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 530,
            "שם_רחוב": "זכריה"
        },
        {
            "_id": 5224,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 757,
            "שם_רחוב": "זמורה"
        },
        {
            "_id": 5225,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 702,
            "שם_רחוב": "זרובבל"
        },
        {
            "_id": 5226,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1071,
            "שם_רחוב": "זרעית"
        },
        {
            "_id": 5227,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 847,
            "שם_רחוב": "חן"
        },
        {
            "_id": 5228,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 583,
            "שם_רחוב": "חבד"
        },
        {
            "_id": 5229,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 698,
            "שם_רחוב": "חביב אבשלום"
        },
        {
            "_id": 5230,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 531,
            "שם_רחוב": "חבקוק"
        },
        {
            "_id": 5231,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 515,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 5232,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1183,
            "שם_רחוב": "חדווה"
        },
        {
            "_id": 5233,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 644,
            "שם_רחוב": "חובבי ציון"
        },
        {
            "_id": 5234,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 804,
            "שם_רחוב": "חוגלה"
        },
        {
            "_id": 5235,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1078,
            "שם_רחוב": "חודשי השנה"
        },
        {
            "_id": 5236,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1056,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 5237,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 574,
            "שם_רחוב": "חולדה הנביאה"
        },
        {
            "_id": 5238,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1322,
            "שם_רחוב": "חומה ומגדל"
        },
        {
            "_id": 5239,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 663,
            "שם_רחוב": "חוני המעגל"
        },
        {
            "_id": 5240,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1066,
            "שם_רחוב": "חופית"
        },
        {
            "_id": 5241,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 748,
            "שם_רחוב": "חוצות היוצר"
        },
        {
            "_id": 5242,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "חות רסקו"
        },
        {
            "_id": 5243,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 722,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 5244,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1299,
            "שם_רחוב": "חזון עובדיה"
        },
        {
            "_id": 5245,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 330,
            "שם_רחוב": "חזקיהו"
        },
        {
            "_id": 5246,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 354,
            "שם_רחוב": "חטיבת עציוני"
        },
        {
            "_id": 5247,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 854,
            "שם_רחוב": "חיל"
        },
        {
            "_id": 5248,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 628,
            "שם_רחוב": "חידא"
        },
        {
            "_id": 5249,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 970,
            "שם_רחוב": "חייבי אהרון"
        },
        {
            "_id": 5250,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 739,
            "שם_רחוב": "חיים אלישע"
        },
        {
            "_id": 5251,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 614,
            "שם_רחוב": "חיל הים"
        },
        {
            "_id": 5252,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 908,
            "שם_רחוב": "חכימי יצחק"
        },
        {
            "_id": 5253,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1219,
            "שם_רחוב": "חכליל"
        },
        {
            "_id": 5254,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 689,
            "שם_רחוב": "חכם שמעון"
        },
        {
            "_id": 5255,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 738,
            "שם_רחוב": "חכמי איטליה"
        },
        {
            "_id": 5256,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1092,
            "שם_רחוב": "חלמונית"
        },
        {
            "_id": 5257,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1214,
            "שם_רחוב": "חמרא"
        },
        {
            "_id": 5258,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 571,
            "שם_רחוב": "חנה ושבעת בניה"
        },
        {
            "_id": 5259,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1049,
            "שם_רחוב": "חניתה"
        },
        {
            "_id": 5260,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 490,
            "שם_רחוב": "חננאל"
        },
        {
            "_id": 5261,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 705,
            "שם_רחוב": "חנניה"
        },
        {
            "_id": 5262,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1057,
            "שם_רחוב": "חסידה"
        },
        {
            "_id": 5263,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 599,
            "שם_רחוב": "חפני"
        },
        {
            "_id": 5264,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1079,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 5265,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1096,
            "שם_רחוב": "חצבה"
        },
        {
            "_id": 5266,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1318,
            "שם_רחוב": "חקלאי"
        },
        {
            "_id": 5267,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1080,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 5268,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1138,
            "שם_רחוב": "חשמונה"
        },
        {
            "_id": 5269,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 607,
            "שם_רחוב": "חתם סופר"
        },
        {
            "_id": 5270,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 678,
            "שם_רחוב": "חתן סופר"
        },
        {
            "_id": 5271,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 726,
            "שם_רחוב": "טבועי אגוז"
        },
        {
            "_id": 5272,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 710,
            "שם_רחוב": "טבועי סטרומה"
        },
        {
            "_id": 5273,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1132,
            "שם_רחוב": "טבלן"
        },
        {
            "_id": 5274,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1020,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 5275,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1013,
            "שם_רחוב": "טבת"
        },
        {
            "_id": 5276,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 954,
            "שם_רחוב": "טדי קולק"
        },
        {
            "_id": 5277,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1114,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 5278,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1118,
            "שם_רחוב": "טורקיז"
        },
        {
            "_id": 5279,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 921,
            "שם_רחוב": "טיילת שמשון ודלילה"
        },
        {
            "_id": 5280,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1201,
            "שם_רחוב": "טיכו אנה"
        },
        {
            "_id": 5281,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1065,
            "שם_רחוב": "טירן"
        },
        {
            "_id": 5282,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 817,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 5283,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 619,
            "שם_רחוב": "טריווש יוסף אליהו"
        },
        {
            "_id": 5284,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 631,
            "שם_רחוב": "טשרניחובסקי"
        },
        {
            "_id": 5285,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 967,
            "שם_רחוב": "יח משפחות"
        },
        {
            "_id": 5286,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 622,
            "שם_רחוב": "יל פרץ"
        },
        {
            "_id": 5287,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 582,
            "שם_רחוב": "יבנה"
        },
        {
            "_id": 5288,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1185,
            "שם_רחוב": "ידידות"
        },
        {
            "_id": 5289,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 447,
            "שם_רחוב": "יהדות אמריקה הלטנ"
        },
        {
            "_id": 5290,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1060,
            "שם_רחוב": "יהדות בריטניה"
        },
        {
            "_id": 5291,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1062,
            "שם_רחוב": "יהדות ספרד"
        },
        {
            "_id": 5292,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 595,
            "שם_רחוב": "יהואש"
        },
        {
            "_id": 5293,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 608,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 5294,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 956,
            "שם_רחוב": "יהודה עמיחי"
        },
        {
            "_id": 5295,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 573,
            "שם_רחוב": "יהודית"
        },
        {
            "_id": 5296,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 551,
            "שם_רחוב": "יהודית שיינין"
        },
        {
            "_id": 5297,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 528,
            "שם_רחוב": "יהושע"
        },
        {
            "_id": 5298,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 339,
            "שם_רחוב": "יהושפט"
        },
        {
            "_id": 5299,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 778,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 5300,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 715,
            "שם_רחוב": "יואב"
        },
        {
            "_id": 5301,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 529,
            "שם_רחוב": "יואל"
        },
        {
            "_id": 5302,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1009,
            "שם_רחוב": "יודפת"
        },
        {
            "_id": 5303,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 310,
            "שם_רחוב": "יוהנסבורג"
        },
        {
            "_id": 5304,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1253,
            "שם_רחוב": "יוחנן"
        },
        {
            "_id": 5305,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1074,
            "שם_רחוב": "יוטבתה"
        },
        {
            "_id": 5306,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 577,
            "שם_רחוב": "יוכבד"
        },
        {
            "_id": 5307,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 692,
            "שם_רחוב": "יוליש משה"
        },
        {
            "_id": 5308,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 508,
            "שם_רחוב": "יונה"
        },
        {
            "_id": 5309,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 641,
            "שם_רחוב": "יוספטל"
        },
        {
            "_id": 5310,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 626,
            "שם_רחוב": "יורדי הים"
        },
        {
            "_id": 5311,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 802,
            "שם_רחוב": "יותם"
        },
        {
            "_id": 5312,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 968,
            "שם_רחוב": "יחזקאל לוין"
        },
        {
            "_id": 5313,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 905,
            "שם_רחוב": "יחיעם"
        },
        {
            "_id": 5314,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 713,
            "שם_רחוב": "יכין"
        },
        {
            "_id": 5315,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 547,
            "שם_רחוב": "ילין"
        },
        {
            "_id": 5316,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1205,
            "שם_רחוב": "ילן-שטקליס מרים"
        },
        {
            "_id": 5317,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 590,
            "שם_רחוב": "ילתא"
        },
        {
            "_id": 5318,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1102,
            "שם_רחוב": "ים המלח"
        },
        {
            "_id": 5319,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1104,
            "שם_רחוב": "ים סוף"
        },
        {
            "_id": 5320,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1042,
            "שם_רחוב": "ימית"
        },
        {
            "_id": 5321,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 316,
            "שם_רחוב": "ינאי המלך"
        },
        {
            "_id": 5322,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 581,
            "שם_רחוב": "ינוקא"
        },
        {
            "_id": 5323,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 501,
            "שם_רחוב": "יסודי עזרא"
        },
        {
            "_id": 5324,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1081,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 5325,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1137,
            "שם_רחוב": "יסעור"
        },
        {
            "_id": 5326,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 520,
            "שם_רחוב": "יעל"
        },
        {
            "_id": 5327,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 664,
            "שם_רחוב": "יעקב נוי"
        },
        {
            "_id": 5328,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 961,
            "שם_רחוב": "יעקב עמיצור"
        },
        {
            "_id": 5329,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1223,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 5330,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 306,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 5331,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 586,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 5332,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 392,
            "שם_רחוב": "יפתח הגלעדי"
        },
        {
            "_id": 5333,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 769,
            "שם_רחוב": "יצחק בנימין"
        },
        {
            "_id": 5334,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 420,
            "שם_רחוב": "יצחק שמיר"
        },
        {
            "_id": 5335,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 466,
            "שם_רחוב": "יריחו"
        },
        {
            "_id": 5336,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 527,
            "שם_רחוב": "ירמיהו"
        },
        {
            "_id": 5337,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 978,
            "שם_רחוב": "ישא ברכה"
        },
        {
            "_id": 5338,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 714,
            "שם_רחוב": "ישי"
        },
        {
            "_id": 5339,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 532,
            "שם_רחוב": "ישעיהו"
        },
        {
            "_id": 5340,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 959,
            "שם_רחוב": "ישעיהו ארנפלד"
        },
        {
            "_id": 5341,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 777,
            "שם_רחוב": "ישפה"
        },
        {
            "_id": 5342,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 543,
            "שם_רחוב": "יששכר"
        },
        {
            "_id": 5343,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1321,
            "שם_רחוב": "כג יורדי הסירה"
        },
        {
            "_id": 5344,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 767,
            "שם_רחוב": "כט בנובמבר"
        },
        {
            "_id": 5345,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 909,
            "שם_רחוב": "כהן שלמה"
        },
        {
            "_id": 5346,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "כוכב הצפון"
        },
        {
            "_id": 5347,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 255,
            "שם_רחוב": "כוכב ים"
        },
        {
            "_id": 5348,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 670,
            "שם_רחוב": "כורש"
        },
        {
            "_id": 5349,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6046,
            "שם_רחוב": "כיכר אליעזר עמר"
        },
        {
            "_id": 5350,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6033,
            "שם_רחוב": "כיכר אמונה"
        },
        {
            "_id": 5351,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6020,
            "שם_רחוב": "כיכר אמסלם שלום"
        },
        {
            "_id": 5352,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6028,
            "שם_רחוב": "כיכר אריס חיים"
        },
        {
            "_id": 5353,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6049,
            "שם_רחוב": "כיכר בכור כחלון"
        },
        {
            "_id": 5354,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר ברק"
        },
        {
            "_id": 5355,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "כיכר גולומב"
        },
        {
            "_id": 5356,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6031,
            "שם_רחוב": "כיכר גוש קטיף"
        },
        {
            "_id": 5357,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6037,
            "שם_רחוב": "כיכר גרוס מרדכי"
        },
        {
            "_id": 5358,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6041,
            "שם_רחוב": "כיכר דר מיכאל לביא"
        },
        {
            "_id": 5359,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6039,
            "שם_רחוב": "כיכר דוד אהרוני"
        },
        {
            "_id": 5360,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "כיכר דוד המלך"
        },
        {
            "_id": 5361,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6054,
            "שם_רחוב": "כיכר דניאל רבי"
        },
        {
            "_id": 5362,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6022,
            "שם_רחוב": "כיכר דקטור מקס"
        },
        {
            "_id": 5363,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6057,
            "שם_רחוב": "כיכר הליקוודטורים"
        },
        {
            "_id": 5364,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6035,
            "שם_רחוב": "כיכר המשטרה"
        },
        {
            "_id": 5365,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6018,
            "שם_רחוב": "כיכר הנרקיס"
        },
        {
            "_id": 5366,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "כיכר העצמאות"
        },
        {
            "_id": 5367,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6014,
            "שם_רחוב": "כיכר הרבי מלובביץ"
        },
        {
            "_id": 5368,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6017,
            "שם_רחוב": "כיכר הרופא"
        },
        {
            "_id": 5369,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6051,
            "שם_רחוב": "כיכר ויטקה שור"
        },
        {
            "_id": 5370,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6032,
            "שם_רחוב": "כיכר ויצו"
        },
        {
            "_id": 5371,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6024,
            "שם_רחוב": "כיכר זנזורי שמעון"
        },
        {
            "_id": 5372,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6030,
            "שם_רחוב": "כיכר חזקיהו"
        },
        {
            "_id": 5373,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6040,
            "שם_רחוב": "כיכר חיים אביבי"
        },
        {
            "_id": 5374,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6023,
            "שם_רחוב": "כיכר חיימסון"
        },
        {
            "_id": 5375,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6053,
            "שם_רחוב": "כיכר יעקב יעקבי"
        },
        {
            "_id": 5376,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6055,
            "שם_רחוב": "כיכר יצחק חלפון"
        },
        {
            "_id": 5377,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "כיכר לוי"
        },
        {
            "_id": 5378,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6036,
            "שם_רחוב": "כיכר לחי"
        },
        {
            "_id": 5379,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6045,
            "שם_רחוב": "כיכר מוחי בוקרה"
        },
        {
            "_id": 5380,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6038,
            "שם_רחוב": "כיכר מיכה עמיר"
        },
        {
            "_id": 5381,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6021,
            "שם_רחוב": "כיכר מיכואלס שלמה"
        },
        {
            "_id": 5382,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6050,
            "שם_רחוב": "כיכר מינה כהן"
        },
        {
            "_id": 5383,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6047,
            "שם_רחוב": "כיכר ממלכת הכהנים"
        },
        {
            "_id": 5384,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "כיכר מנגר"
        },
        {
            "_id": 5385,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "כיכר מרגלית משה"
        },
        {
            "_id": 5386,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6025,
            "שם_רחוב": "כיכר ניו יורק"
        },
        {
            "_id": 5387,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6052,
            "שם_רחוב": "כיכר ניסים חגאי"
        },
        {
            "_id": 5388,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6034,
            "שם_רחוב": "כיכר נעמת"
        },
        {
            "_id": 5389,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6015,
            "שם_רחוב": "כיכר סטרייג' הנרי"
        },
        {
            "_id": 5390,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6009,
            "שם_רחוב": "כיכר סמואל"
        },
        {
            "_id": 5391,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6010,
            "שם_רחוב": "כיכר עתניאל"
        },
        {
            "_id": 5392,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6044,
            "שם_רחוב": "כיכר פנחס חסן"
        },
        {
            "_id": 5393,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6029,
            "שם_רחוב": "כיכר רוטרי"
        },
        {
            "_id": 5394,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6012,
            "שם_רחוב": "כיכר שאול המלך"
        },
        {
            "_id": 5395,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6026,
            "שם_רחוב": "כיכר שושן"
        },
        {
            "_id": 5396,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6027,
            "שם_רחוב": "כיכר שושנה יוסף"
        },
        {
            "_id": 5397,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6013,
            "שם_רחוב": "כיכר שלמה המלך"
        },
        {
            "_id": 5398,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6042,
            "שם_רחוב": "כיכר שלמה חוגי"
        },
        {
            "_id": 5399,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6016,
            "שם_רחוב": "כיכר שרמן ארצ'י"
        },
        {
            "_id": 5400,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 969,
            "שם_רחוב": "ככר פעילי גבעת ציון"
        },
        {
            "_id": 5401,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1022,
            "שם_רחוב": "כל ישראל חברים"
        },
        {
            "_id": 5402,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 324,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 5403,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1021,
            "שם_רחוב": "כנפי נשרים"
        },
        {
            "_id": 5404,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1101,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 5405,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1014,
            "שם_רחוב": "כסלו"
        },
        {
            "_id": 5406,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6557,
            "שם_רחוב": "כפר גולף"
        },
        {
            "_id": 5407,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1178,
            "שם_רחוב": "כפר דרום"
        },
        {
            "_id": 5408,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 351,
            "שם_רחוב": "כפר הנופש הצרפתי"
        },
        {
            "_id": 5409,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 855,
            "שם_רחוב": "כפר הנופש לחיילים"
        },
        {
            "_id": 5410,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6558,
            "שם_רחוב": "כפרי הנופש"
        },
        {
            "_id": 5411,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 477,
            "שם_רחוב": "כצנלסון"
        },
        {
            "_id": 5412,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1082,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 5413,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "כרמי ציון"
        },
        {
            "_id": 5414,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 923,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 5415,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 636,
            "שם_רחוב": "כתב סופר"
        },
        {
            "_id": 5416,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1052,
            "שם_רחוב": "לביאה"
        },
        {
            "_id": 5417,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1149,
            "שם_רחוב": "לבנה"
        },
        {
            "_id": 5418,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1234,
            "שם_רחוב": "לגין"
        },
        {
            "_id": 5419,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 533,
            "שם_רחוב": "לוחמי הגטאות"
        },
        {
            "_id": 5420,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 758,
            "שם_רחוב": "לוטוס"
        },
        {
            "_id": 5421,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 256,
            "שם_רחוב": "לויתן"
        },
        {
            "_id": 5422,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 325,
            "שם_רחוב": "לוע הארי"
        },
        {
            "_id": 5423,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1204,
            "שם_רחוב": "ליבוביץ נחמה"
        },
        {
            "_id": 5424,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 973,
            "שם_רחוב": "לייפר- מילר רחל"
        },
        {
            "_id": 5425,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1023,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 5426,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 436,
            "שם_רחוב": "לנדאו לב"
        },
        {
            "_id": 5427,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 825,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 5428,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1036,
            "שם_רחוב": "מאדים"
        },
        {
            "_id": 5429,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1303,
            "שם_רחוב": "מבוע"
        },
        {
            "_id": 5430,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 768,
            "שם_רחוב": "מבצע אסף"
        },
        {
            "_id": 5431,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 766,
            "שם_רחוב": "מבצע חורב"
        },
        {
            "_id": 5432,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 353,
            "שם_רחוב": "מבצע חירם"
        },
        {
            "_id": 5433,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1024,
            "שם_רחוב": "מבצע משה"
        },
        {
            "_id": 5434,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1236,
            "שם_רחוב": "מבשלה"
        },
        {
            "_id": 5435,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "מגדל"
        },
        {
            "_id": 5436,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 615,
            "שם_רחוב": "מגדלור"
        },
        {
            "_id": 5437,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1025,
            "שם_רחוב": "מגידו"
        },
        {
            "_id": 5438,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1143,
            "שם_רחוב": "מדבר סין"
        },
        {
            "_id": 5439,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 336,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 5440,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1146,
            "שם_רחוב": "מוסרות"
        },
        {
            "_id": 5441,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 976,
            "שם_רחוב": "מועדים לשמחה"
        },
        {
            "_id": 5442,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 728,
            "שם_רחוב": "מור"
        },
        {
            "_id": 5443,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 712,
            "שם_רחוב": "מור משולם"
        },
        {
            "_id": 5444,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1176,
            "שם_רחוב": "מורג"
        },
        {
            "_id": 5445,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1235,
            "שם_רחוב": "מזקקה"
        },
        {
            "_id": 5446,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 806,
            "שם_רחוב": "מחל"
        },
        {
            "_id": 5447,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1123,
            "שם_רחוב": "מחניים"
        },
        {
            "_id": 5448,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1195,
            "שם_רחוב": "מיטיב בני"
        },
        {
            "_id": 5449,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 525,
            "שם_רחוב": "מיכה"
        },
        {
            "_id": 5450,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 654,
            "שם_רחוב": "מילרוד אליעזר"
        },
        {
            "_id": 5451,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 703,
            "שם_רחוב": "מישאל"
        },
        {
            "_id": 5452,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1098,
            "שם_רחוב": "מיתר"
        },
        {
            "_id": 5453,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 257,
            "שם_רחוב": "מכמורת"
        },
        {
            "_id": 5454,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 509,
            "שם_רחוב": "מלאכי"
        },
        {
            "_id": 5455,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 834,
            "שם_רחוב": "מלון החוף"
        },
        {
            "_id": 5456,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 326,
            "שם_רחוב": "מלכי ישראל"
        },
        {
            "_id": 5457,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1112,
            "שם_רחוב": "מלכיט"
        },
        {
            "_id": 5458,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 789,
            "שם_רחוב": "מנגו"
        },
        {
            "_id": 5459,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 620,
            "שם_רחוב": "מנדלי מוכר ספרים"
        },
        {
            "_id": 5460,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 505,
            "שם_רחוב": "מנשה"
        },
        {
            "_id": 5461,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1145,
            "שם_רחוב": "מסעי בני ישראל"
        },
        {
            "_id": 5462,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 363,
            "שם_רחוב": "מעגן"
        },
        {
            "_id": 5463,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 625,
            "שם_רחוב": "מעון אקדמאים"
        },
        {
            "_id": 5464,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 696,
            "שם_רחוב": "מעון קשישים"
        },
        {
            "_id": 5465,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 860,
            "שם_רחוב": "מעלה הגת"
        },
        {
            "_id": 5466,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 911,
            "שם_רחוב": "מעלות אשר"
        },
        {
            "_id": 5467,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 274,
            "שם_רחוב": "מפל התנור"
        },
        {
            "_id": 5468,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 258,
            "שם_רחוב": "מפרץ"
        },
        {
            "_id": 5469,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1168,
            "שם_רחוב": "מפרשית"
        },
        {
            "_id": 5470,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 661,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 5471,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "מצפה ברנע"
        },
        {
            "_id": 5472,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1142,
            "שם_רחוב": "מקהלות"
        },
        {
            "_id": 5473,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 977,
            "שם_רחוב": "מקור הברכה"
        },
        {
            "_id": 5474,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 537,
            "שם_רחוב": "מרגלית"
        },
        {
            "_id": 5475,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 965,
            "שם_רחוב": "מרדכי ברששת"
        },
        {
            "_id": 5476,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1015,
            "שם_רחוב": "מרחשוון"
        },
        {
            "_id": 5477,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 953,
            "שם_רחוב": "מרטי דיויס"
        },
        {
            "_id": 5478,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 912,
            "שם_רחוב": "מרידור יעקב"
        },
        {
            "_id": 5479,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 521,
            "שם_רחוב": "מרים"
        },
        {
            "_id": 5480,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 957,
            "שם_רחוב": "מרים בן פורת"
        },
        {
            "_id": 5481,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1108,
            "שם_רחוב": "מרינה"
        },
        {
            "_id": 5482,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 665,
            "שם_רחוב": "מרכוס"
        },
        {
            "_id": 5483,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1100,
            "שם_רחוב": "מרכז נפתי"
        },
        {
            "_id": 5484,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 400,
            "שם_רחוב": "מרכז קליטה בית קנדה"
        },
        {
            "_id": 5485,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 901,
            "שם_רחוב": "מרכז קליטה כלנית"
        },
        {
            "_id": 5486,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1196,
            "שם_רחוב": "מרכז רפואי ברזילי"
        },
        {
            "_id": 5487,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 950,
            "שם_רחוב": "משה לנדוי"
        },
        {
            "_id": 5488,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 823,
            "שם_רחוב": "משכית"
        },
        {
            "_id": 5489,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1140,
            "שם_רחוב": "מתקה"
        },
        {
            "_id": 5490,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "נאות אשקלון"
        },
        {
            "_id": 5491,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "נאות ברנע"
        },
        {
            "_id": 5492,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "נאות גנים"
        },
        {
            "_id": 5493,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 985,
            "שם_רחוב": "נבון אופירה"
        },
        {
            "_id": 5494,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 723,
            "שם_רחוב": "נהרדעא"
        },
        {
            "_id": 5495,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1041,
            "שם_רחוב": "נוגה"
        },
        {
            "_id": 5496,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6553,
            "שם_רחוב": "נוה אילן"
        },
        {
            "_id": 5497,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6552,
            "שם_רחוב": "נוה הדקלים"
        },
        {
            "_id": 5498,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6551,
            "שם_רחוב": "נוה ההדרים"
        },
        {
            "_id": 5499,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "נווה אלונים"
        },
        {
            "_id": 5500,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1308,
            "שם_רחוב": "נווה מדבר"
        },
        {
            "_id": 5501,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1059,
            "שם_רחוב": "נווה שלום"
        },
        {
            "_id": 5502,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 803,
            "שם_רחוב": "נועה"
        },
        {
            "_id": 5503,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1310,
            "שם_רחוב": "נופר"
        },
        {
            "_id": 5504,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 892,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 5505,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1083,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 5506,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 474,
            "שם_רחוב": "נחל"
        },
        {
            "_id": 5507,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 497,
            "שם_רחוב": "נחושתן"
        },
        {
            "_id": 5508,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 279,
            "שם_רחוב": "נחל דרגות"
        },
        {
            "_id": 5509,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 280,
            "שם_רחוב": "נחל חלילים"
        },
        {
            "_id": 5510,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 917,
            "שם_רחוב": "נחל לוטם"
        },
        {
            "_id": 5511,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 880,
            "שם_רחוב": "נחל מורן"
        },
        {
            "_id": 5512,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 930,
            "שם_רחוב": "נחל נטפים"
        },
        {
            "_id": 5513,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1103,
            "שם_רחוב": "נחל נעמן"
        },
        {
            "_id": 5514,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 919,
            "שם_רחוב": "נחל נקרות"
        },
        {
            "_id": 5515,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 882,
            "שם_רחוב": "נחל נריה"
        },
        {
            "_id": 5516,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1128,
            "שם_רחוב": "נחל עירון"
        },
        {
            "_id": 5517,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 278,
            "שם_רחוב": "נחל עמוד"
        },
        {
            "_id": 5518,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 269,
            "שם_רחוב": "נחל פרת"
        },
        {
            "_id": 5519,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 281,
            "שם_רחוב": "נחל צאלים"
        },
        {
            "_id": 5520,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 277,
            "שם_רחוב": "נחל צלמון"
        },
        {
            "_id": 5521,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 881,
            "שם_רחוב": "נחל קשת"
        },
        {
            "_id": 5522,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 879,
            "שם_רחוב": "נחל שני"
        },
        {
            "_id": 5523,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 945,
            "שם_רחוב": "נחל שפירים"
        },
        {
            "_id": 5524,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 271,
            "שם_רחוב": "נחל תנינים"
        },
        {
            "_id": 5525,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1232,
            "שם_רחוב": "נחלה"
        },
        {
            "_id": 5526,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1058,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 5527,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 511,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 5528,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 396,
            "שם_רחוב": "נחשון"
        },
        {
            "_id": 5529,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 859,
            "שם_רחוב": "נטעים"
        },
        {
            "_id": 5530,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 484,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 5531,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6056,
            "שם_רחוב": "נינה פינטו אבקסיס"
        },
        {
            "_id": 5532,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1016,
            "שם_רחוב": "ניסן"
        },
        {
            "_id": 5533,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1175,
            "שם_רחוב": "ניסנית"
        },
        {
            "_id": 5534,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1094,
            "שם_רחוב": "ניצנה"
        },
        {
            "_id": 5535,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 691,
            "שם_רחוב": "ניצנים"
        },
        {
            "_id": 5536,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1051,
            "שם_רחוב": "נמר"
        },
        {
            "_id": 5537,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 578,
            "שם_רחוב": "נעמי"
        },
        {
            "_id": 5538,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 549,
            "שם_רחוב": "נפתלי"
        },
        {
            "_id": 5539,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 516,
            "שם_רחוב": "נתן הנביא"
        },
        {
            "_id": 5540,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 988,
            "שם_רחוב": "נתניהו יונתן"
        },
        {
            "_id": 5541,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 472,
            "שם_רחוב": "סאלד הנרייטה"
        },
        {
            "_id": 5542,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1085,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 5543,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1216,
            "שם_רחוב": "סברי מרנן"
        },
        {
            "_id": 5544,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 395,
            "שם_רחוב": "סגל צבי"
        },
        {
            "_id": 5545,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1254,
            "שם_רחוב": "סוזי רביץ"
        },
        {
            "_id": 5546,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1086,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 5547,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 734,
            "שם_רחוב": "סטרייג' הנרי"
        },
        {
            "_id": 5548,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 350,
            "שם_רחוב": "סיגל"
        },
        {
            "_id": 5549,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1017,
            "שם_רחוב": "סיון"
        },
        {
            "_id": 5550,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1087,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 5551,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 662,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 5552,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1167,
            "שם_רחוב": "סירה"
        },
        {
            "_id": 5553,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 830,
            "שם_רחוב": "סלע חיים"
        },
        {
            "_id": 5554,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1073,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 5555,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 601,
            "שם_רחוב": "סמ חמד"
        },
        {
            "_id": 5556,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 380,
            "שם_רחוב": "סמ כרמלי"
        },
        {
            "_id": 5557,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 801,
            "שם_רחוב": "סמ מילכה"
        },
        {
            "_id": 5558,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 920,
            "שם_רחוב": "סמ שמגר"
        },
        {
            "_id": 5559,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1113,
            "שם_רחוב": "סמ תיסלית"
        },
        {
            "_id": 5560,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 822,
            "שם_רחוב": "סמ' איילון"
        },
        {
            "_id": 5561,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 828,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 5562,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 642,
            "שם_רחוב": "סמולנסקין"
        },
        {
            "_id": 5563,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 251,
            "שם_רחוב": "סנפיר"
        },
        {
            "_id": 5564,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 716,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 5565,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 939,
            "שם_רחוב": "סעדון אילן"
        },
        {
            "_id": 5566,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 461,
            "שם_רחוב": "סעדיה גאון"
        },
        {
            "_id": 5567,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1169,
            "שם_רחוב": "ספינה"
        },
        {
            "_id": 5568,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 771,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 5569,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 694,
            "שם_רחוב": "עגנון שי"
        },
        {
            "_id": 5570,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1008,
            "שם_רחוב": "עדולם"
        },
        {
            "_id": 5571,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 519,
            "שם_רחוב": "עובדיה"
        },
        {
            "_id": 5572,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 388,
            "שם_רחוב": "עודד"
        },
        {
            "_id": 5573,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 944,
            "שם_רחוב": "עוזר אריה"
        },
        {
            "_id": 5574,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 699,
            "שם_רחוב": "עולי הגרדום"
        },
        {
            "_id": 5575,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 510,
            "שם_רחוב": "עזרא"
        },
        {
            "_id": 5576,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 704,
            "שם_רחוב": "עזריה"
        },
        {
            "_id": 5577,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1316,
            "שם_רחוב": "עיינות"
        },
        {
            "_id": 5578,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "עין הים"
        },
        {
            "_id": 5579,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1313,
            "שם_רחוב": "עין הנציב"
        },
        {
            "_id": 5580,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 283,
            "שם_רחוב": "עין התכלת"
        },
        {
            "_id": 5581,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 276,
            "שם_רחוב": "עין כפיר"
        },
        {
            "_id": 5582,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 282,
            "שם_רחוב": "עין נטפים"
        },
        {
            "_id": 5583,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 262,
            "שם_רחוב": "עין עבדת"
        },
        {
            "_id": 5584,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 264,
            "שם_רחוב": "עין עברונה"
        },
        {
            "_id": 5585,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1309,
            "שם_רחוב": "עין עקב"
        },
        {
            "_id": 5586,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 275,
            "שם_רחוב": "עין שוקק"
        },
        {
            "_id": 5587,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 263,
            "שם_רחוב": "עינות צוקים"
        },
        {
            "_id": 5588,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1088,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 5589,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 706,
            "שם_רחוב": "עלי"
        },
        {
            "_id": 5590,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 938,
            "שם_רחוב": "עלית הנוער"
        },
        {
            "_id": 5591,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 512,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 5592,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1026,
            "שם_רחוב": "עמיעד"
        },
        {
            "_id": 5593,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 903,
            "שם_רחוב": "עמיעוז"
        },
        {
            "_id": 5594,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1039,
            "שם_רחוב": "עמישב"
        },
        {
            "_id": 5595,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 491,
            "שם_רחוב": "עמנואל"
        },
        {
            "_id": 5596,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 404,
            "שם_רחוב": "עמנואל מורנו"
        },
        {
            "_id": 5597,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 997,
            "שם_רחוב": "עמר ג'ו"
        },
        {
            "_id": 5598,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1319,
            "שם_רחוב": "ענבי בר"
        },
        {
            "_id": 5599,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 824,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 5600,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1227,
            "שם_רחוב": "עסיס (ענבים)"
        },
        {
            "_id": 5601,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1055,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 5602,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 784,
            "שם_רחוב": "עצי פרי"
        },
        {
            "_id": 5603,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1144,
            "שם_רחוב": "ערבות מואב"
        },
        {
            "_id": 5604,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1028,
            "שם_רחוב": "עשרת השבטים"
        },
        {
            "_id": 5605,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 883,
            "שם_רחוב": "עתניאל"
        },
        {
            "_id": 5606,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 833,
            "שם_רחוב": "פארק אשקלון"
        },
        {
            "_id": 5607,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 776,
            "שם_רחוב": "פטדה"
        },
        {
            "_id": 5608,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 597,
            "שם_רחוב": "פינחס"
        },
        {
            "_id": 5609,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 674,
            "שם_רחוב": "פינס יוסף"
        },
        {
            "_id": 5610,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 902,
            "שם_רחוב": "פלוגות"
        },
        {
            "_id": 5611,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1116,
            "שם_רחוב": "פנינת ים"
        },
        {
            "_id": 5612,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 441,
            "שם_רחוב": "פסטרנק בוריס"
        },
        {
            "_id": 5613,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 349,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 5614,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 791,
            "שם_רחוב": "פקאן"
        },
        {
            "_id": 5615,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1029,
            "שם_רחוב": "פקיעין"
        },
        {
            "_id": 5616,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 832,
            "שם_רחוב": "פרידן יונתן"
        },
        {
            "_id": 5617,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1089,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 5618,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 951,
            "שם_רחוב": "צדוק דורון"
        },
        {
            "_id": 5619,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1037,
            "שם_רחוב": "צדק"
        },
        {
            "_id": 5620,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 437,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 5621,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1179,
            "שם_רחוב": "צהלה"
        },
        {
            "_id": 5622,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1170,
            "שם_רחוב": "צוללת"
        },
        {
            "_id": 5623,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1054,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 5624,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 639,
            "שם_רחוב": "צור אפרים"
        },
        {
            "_id": 5625,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 904,
            "שם_רחוב": "צוריאל"
        },
        {
            "_id": 5626,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 686,
            "שם_רחוב": "צין"
        },
        {
            "_id": 5627,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1061,
            "שם_רחוב": "ציפורי"
        },
        {
            "_id": 5628,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1090,
            "שם_רחוב": "ציפורן"
        },
        {
            "_id": 5629,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 399,
            "שם_רחוב": "צלופחד"
        },
        {
            "_id": 5630,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1148,
            "שם_רחוב": "צלמונה"
        },
        {
            "_id": 5631,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1093,
            "שם_רחוב": "צלף"
        },
        {
            "_id": 5632,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1317,
            "שם_רחוב": "צלצל"
        },
        {
            "_id": 5633,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 485,
            "שם_רחוב": "קאפח אהרון"
        },
        {
            "_id": 5634,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1032,
            "שם_רחוב": "קדומים"
        },
        {
            "_id": 5635,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 907,
            "שם_רחוב": "קדושי קהיר"
        },
        {
            "_id": 5636,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 449,
            "שם_רחוב": "קול משה"
        },
        {
            "_id": 5637,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1226,
            "שם_רחוב": "קונדיטון"
        },
        {
            "_id": 5638,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 430,
            "שם_רחוב": "קופת חולים"
        },
        {
            "_id": 5639,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 640,
            "שם_רחוב": "קורצ'אק"
        },
        {
            "_id": 5640,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 397,
            "שם_רחוב": "קיבוץ גלויות"
        },
        {
            "_id": 5641,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1030,
            "שם_רחוב": "קיסריה"
        },
        {
            "_id": 5642,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1306,
            "שם_רחוב": "קני סוף"
        },
        {
            "_id": 5643,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1212,
            "שם_רחוב": "קנקני אשקלון"
        },
        {
            "_id": 5644,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 318,
            "שם_רחוב": "קפסטט"
        },
        {
            "_id": 5645,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 835,
            "שם_רחוב": "קצרין"
        },
        {
            "_id": 5646,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 434,
            "שם_רחוב": "קקל"
        },
        {
            "_id": 5647,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 389,
            "שם_רחוב": "קרייתי"
        },
        {
            "_id": 5648,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6561,
            "שם_רחוב": "קרית גולדה מאיר"
        },
        {
            "_id": 5649,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6549,
            "שם_רחוב": "קרית חינוך דרום"
        },
        {
            "_id": 5650,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6560,
            "שם_רחוב": "קרית חינוך צפון"
        },
        {
            "_id": 5651,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 895,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 5652,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 546,
            "שם_רחוב": "ראובן"
        },
        {
            "_id": 5653,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 837,
            "שם_רחוב": "ראשון לציון"
        },
        {
            "_id": 5654,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 454,
            "שם_רחוב": "רבי מאיר"
        },
        {
            "_id": 5655,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 480,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 5656,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 426,
            "שם_רחוב": "רבי רפאל אנקווה"
        },
        {
            "_id": 5657,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 568,
            "שם_רחוב": "רבקה"
        },
        {
            "_id": 5658,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1206,
            "שם_רחוב": "רובינא חנה"
        },
        {
            "_id": 5659,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 523,
            "שם_רחוב": "רות"
        },
        {
            "_id": 5660,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 345,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 5661,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 489,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 5662,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1151,
            "שם_רחוב": "רחבת הרב אבהר שלום"
        },
        {
            "_id": 5663,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6019,
            "שם_רחוב": "רחבת ווסק"
        },
        {
            "_id": 5664,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 958,
            "שם_רחוב": "רחל ינאית בן צבי"
        },
        {
            "_id": 5665,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1181,
            "שם_רחוב": "רינה"
        },
        {
            "_id": 5666,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 456,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 5667,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 451,
            "שם_רחוב": "רמז דוד"
        },
        {
            "_id": 5668,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "רמת בן גוריון"
        },
        {
            "_id": 5669,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "רמת חן"
        },
        {
            "_id": 5670,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "רמת כרמים"
        },
        {
            "_id": 5671,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 452,
            "שם_רחוב": "רנבסקיה פאינה"
        },
        {
            "_id": 5672,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1184,
            "שם_רחוב": "רעות"
        },
        {
            "_id": 5673,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1141,
            "שם_רחוב": "רפידים"
        },
        {
            "_id": 5674,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 875,
            "שם_רחוב": "רפסודה"
        },
        {
            "_id": 5675,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6571,
            "שם_רחוב": "רצועת החוף והמלונות"
        },
        {
            "_id": 5676,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 460,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 5677,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "ש אגש שמשון"
        },
        {
            "_id": 5678,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6562,
            "שם_רחוב": "ש בנה ביתך"
        },
        {
            "_id": 5679,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש ברנע"
        },
        {
            "_id": 5680,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "ש דרומי"
        },
        {
            "_id": 5681,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "ש הצכים"
        },
        {
            "_id": 5682,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6563,
            "שם_רחוב": "ש וילות"
        },
        {
            "_id": 5683,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "ש זוגות צעירים"
        },
        {
            "_id": 5684,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש חסכון א"
        },
        {
            "_id": 5685,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "ש חסכון ב"
        },
        {
            "_id": 5686,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש יובל גד"
        },
        {
            "_id": 5687,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש מגדל המים"
        },
        {
            "_id": 5688,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "ש מפונים"
        },
        {
            "_id": 5689,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש נוה רז"
        },
        {
            "_id": 5690,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "ש צפון ב"
        },
        {
            "_id": 5691,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6564,
            "שם_רחוב": "ש קרוונים"
        },
        {
            "_id": 5692,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6534,
            "שם_רחוב": "ש רמז"
        },
        {
            "_id": 5693,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6535,
            "שם_רחוב": "ש רמת אשכול"
        },
        {
            "_id": 5694,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "ש שוטרים"
        },
        {
            "_id": 5695,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "ש שיקמים"
        },
        {
            "_id": 5696,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6543,
            "שם_רחוב": "ש שמשון"
        },
        {
            "_id": 5697,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1150,
            "שם_רחוב": "ש.ב.ישעיה"
        },
        {
            "_id": 5698,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1222,
            "שם_רחוב": "שאנין"
        },
        {
            "_id": 5699,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 868,
            "שם_רחוב": "שאר ישוב"
        },
        {
            "_id": 5700,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 773,
            "שם_רחוב": "שבו"
        },
        {
            "_id": 5701,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 455,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 5702,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1068,
            "שם_רחוב": "שבט"
        },
        {
            "_id": 5703,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 637,
            "שם_רחוב": "שבט סופר"
        },
        {
            "_id": 5704,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1040,
            "שם_רחוב": "שביט"
        },
        {
            "_id": 5705,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 916,
            "שם_רחוב": "שביל דר פיינגולד ג"
        },
        {
            "_id": 5706,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 915,
            "שם_רחוב": "שביל דינור ברוך"
        },
        {
            "_id": 5707,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 928,
            "שם_רחוב": "שביל החייט"
        },
        {
            "_id": 5708,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1001,
            "שם_רחוב": "שביל הלימון"
        },
        {
            "_id": 5709,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 929,
            "שם_רחוב": "שביל הסנדלר"
        },
        {
            "_id": 5710,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1109,
            "שם_רחוב": "שביל העוז"
        },
        {
            "_id": 5711,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 338,
            "שם_רחוב": "שביל חכם יעקב"
        },
        {
            "_id": 5712,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 922,
            "שם_רחוב": "שביל נווה חוף"
        },
        {
            "_id": 5713,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 387,
            "שם_רחוב": "שביל שבע"
        },
        {
            "_id": 5714,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1311,
            "שם_רחוב": "שבשבט"
        },
        {
            "_id": 5715,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1043,
            "שם_רחוב": "שבתאי"
        },
        {
            "_id": 5716,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 856,
            "שם_רחוב": "שד אצל"
        },
        {
            "_id": 5717,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 653,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 5718,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 740,
            "שם_רחוב": "שד בר-לב חיים"
        },
        {
            "_id": 5719,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 311,
            "שם_רחוב": "שד דרום אפריקה"
        },
        {
            "_id": 5720,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 496,
            "שם_רחוב": "שד התעשיה"
        },
        {
            "_id": 5721,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 390,
            "שם_רחוב": "שד ירושלים"
        },
        {
            "_id": 5722,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 818,
            "שם_רחוב": "שד מונטיפיורי"
        },
        {
            "_id": 5723,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 270,
            "שם_רחוב": "שד עמק האלה"
        },
        {
            "_id": 5724,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 267,
            "שם_רחוב": "שד עמק חפר"
        },
        {
            "_id": 5725,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 268,
            "שם_רחוב": "שד עמק יזרעאל"
        },
        {
            "_id": 5726,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6011,
            "שם_רחוב": "שד צפניה"
        },
        {
            "_id": 5727,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 861,
            "שם_רחוב": "שד קדש"
        },
        {
            "_id": 5728,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 693,
            "שם_רחוב": "שד שפירא"
        },
        {
            "_id": 5729,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1035,
            "שם_רחוב": "שד' בגין מנחם"
        },
        {
            "_id": 5730,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 893,
            "שם_רחוב": "שד' הציונות"
        },
        {
            "_id": 5731,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 561,
            "שם_רחוב": "שד' עופר"
        },
        {
            "_id": 5732,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1115,
            "שם_רחוב": "שד' רבין יצחק"
        },
        {
            "_id": 5733,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 779,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 5734,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 842,
            "שם_רחוב": "שורק"
        },
        {
            "_id": 5735,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 844,
            "שם_רחוב": "שורשים"
        },
        {
            "_id": 5736,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 785,
            "שם_רחוב": "שזיף"
        },
        {
            "_id": 5737,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1131,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 5738,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 971,
            "שם_רחוב": "שטיפטל מרק"
        },
        {
            "_id": 5739,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 340,
            "שם_רחוב": "שטרן אברהם"
        },
        {
            "_id": 5740,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 540,
            "שם_רחוב": "שיבת ציון"
        },
        {
            "_id": 5741,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1125,
            "שם_רחוב": "שילה"
        },
        {
            "_id": 5742,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1220,
            "שם_רחוב": "שירת היין"
        },
        {
            "_id": 5743,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ אפרידר"
        },
        {
            "_id": 5744,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6539,
            "שם_רחוב": "שכ אשכולי פז"
        },
        {
            "_id": 5745,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6556,
            "שם_רחוב": "שכ ברנע ב'"
        },
        {
            "_id": 5746,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6555,
            "שם_רחוב": "שכ ברנע ג'"
        },
        {
            "_id": 5747,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6542,
            "שם_רחוב": "שכ האגמים"
        },
        {
            "_id": 5748,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6545,
            "שם_רחוב": "שכ הנשיא הרצוג"
        },
        {
            "_id": 5749,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6537,
            "שם_רחוב": "שכ הצפון א"
        },
        {
            "_id": 5750,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6544,
            "שם_רחוב": "שכ ז'בוטינסקי"
        },
        {
            "_id": 5751,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6538,
            "שם_רחוב": "שכ נוה חוף"
        },
        {
            "_id": 5752,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6565,
            "שם_רחוב": "שכ נוה ים"
        },
        {
            "_id": 5753,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "שכ קדש ברנע"
        },
        {
            "_id": 5754,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6566,
            "שם_רחוב": "שכ שמשון ה"
        },
        {
            "_id": 5755,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "שכון ופתוח"
        },
        {
            "_id": 5756,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1187,
            "שם_רחוב": "שלווה"
        },
        {
            "_id": 5757,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 621,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 5758,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 652,
            "שם_רחוב": "שלומציון"
        },
        {
            "_id": 5759,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 999,
            "שם_רחוב": "שלמה אביבי"
        },
        {
            "_id": 5760,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 459,
            "שם_רחוב": "שמאי"
        },
        {
            "_id": 5761,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 513,
            "שם_רחוב": "שמואל"
        },
        {
            "_id": 5762,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 6043,
            "שם_רחוב": "שמואל וסרל קויברי"
        },
        {
            "_id": 5763,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 975,
            "שם_רחוב": "שמחת עולם"
        },
        {
            "_id": 5764,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 600,
            "שם_רחוב": "שמעון פרס"
        },
        {
            "_id": 5765,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1200,
            "שם_רחוב": "שמר נעמי"
        },
        {
            "_id": 5766,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 760,
            "שם_רחוב": "שמש יוסף"
        },
        {
            "_id": 5767,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 697,
            "שם_רחוב": "שני אליהו"
        },
        {
            "_id": 5768,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1034,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 5769,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 787,
            "שם_רחוב": "שסק"
        },
        {
            "_id": 5770,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1228,
            "שם_רחוב": "שפיה"
        },
        {
            "_id": 5771,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 602,
            "שם_רחוב": "שפינוזה"
        },
        {
            "_id": 5772,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 504,
            "שם_רחוב": "שפירא יוסף"
        },
        {
            "_id": 5773,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1134,
            "שם_רחוב": "שקנאי"
        },
        {
            "_id": 5774,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 569,
            "שם_רחוב": "שרה"
        },
        {
            "_id": 5775,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1124,
            "שם_רחוב": "שרה ומנחם וקשטוק"
        },
        {
            "_id": 5776,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 843,
            "שם_רחוב": "שריג"
        },
        {
            "_id": 5777,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 690,
            "שם_רחוב": "שרמן ארצ'י"
        },
        {
            "_id": 5778,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 894,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 5779,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 846,
            "שם_רחוב": "שתילים"
        },
        {
            "_id": 5780,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 786,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 5781,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 972,
            "שם_רחוב": "תגר אריה"
        },
        {
            "_id": 5782,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 852,
            "שם_רחוב": "תגר עליזה"
        },
        {
            "_id": 5783,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 403,
            "שם_רחוב": "תורמוס"
        },
        {
            "_id": 5784,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 792,
            "שם_רחוב": "תות"
        },
        {
            "_id": 5785,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 841,
            "שם_רחוב": "תירוש"
        },
        {
            "_id": 5786,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 303,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 5787,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1105,
            "שם_רחוב": "תלתן"
        },
        {
            "_id": 5788,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1048,
            "שם_רחוב": "תמוז"
        },
        {
            "_id": 5789,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1099,
            "שם_רחוב": "תמנע"
        },
        {
            "_id": 5790,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 839,
            "שם_רחוב": "תנובת השדה"
        },
        {
            "_id": 5791,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1126,
            "שם_רחוב": "תענך"
        },
        {
            "_id": 5792,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 800,
            "שם_רחוב": "תרצה"
        },
        {
            "_id": 5793,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 774,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 5794,
            "סמל_ישוב": 7100,
            "שם_ישוב": "אשקלון ",
            "סמל_רחוב": 1044,
            "שם_רחוב": "תשרי"
        },
        {
            "_id": 5795,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשרת"
        },
        {
            "_id": 5796,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 132,
            "שם_רחוב": "דרך האבוקדו"
        },
        {
            "_id": 5797,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך החרוב"
        },
        {
            "_id": 5798,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך העצים"
        },
        {
            "_id": 5799,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 117,
            "שם_רחוב": "דרך הפרחים"
        },
        {
            "_id": 5800,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 130,
            "שם_רחוב": "ההרדוף"
        },
        {
            "_id": 5801,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 131,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 5802,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 110,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 5803,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 129,
            "שם_רחוב": "שביל האירוס"
        },
        {
            "_id": 5804,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 113,
            "שם_רחוב": "שביל האלה"
        },
        {
            "_id": 5805,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שביל האלון"
        },
        {
            "_id": 5806,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שביל הארז"
        },
        {
            "_id": 5807,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שביל הברוש"
        },
        {
            "_id": 5808,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 114,
            "שם_רחוב": "שביל הגפן"
        },
        {
            "_id": 5809,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 118,
            "שם_רחוב": "שביל הדפנה"
        },
        {
            "_id": 5810,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שביל הזית"
        },
        {
            "_id": 5811,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 125,
            "שם_רחוב": "שביל החצב"
        },
        {
            "_id": 5812,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 126,
            "שם_רחוב": "שביל היסמין"
        },
        {
            "_id": 5813,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 124,
            "שם_רחוב": "שביל הכרכום"
        },
        {
            "_id": 5814,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שביל הלימון"
        },
        {
            "_id": 5815,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 119,
            "שם_רחוב": "שביל המורן"
        },
        {
            "_id": 5816,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 122,
            "שם_רחוב": "שביל הנרקיס"
        },
        {
            "_id": 5817,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שביל הסביון"
        },
        {
            "_id": 5818,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 121,
            "שם_רחוב": "שביל הסחלב"
        },
        {
            "_id": 5819,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שביל הפיקוס"
        },
        {
            "_id": 5820,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 127,
            "שם_רחוב": "שביל הצבעוני"
        },
        {
            "_id": 5821,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 128,
            "שם_רחוב": "שביל הרותם"
        },
        {
            "_id": 5822,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שביל הרימון"
        },
        {
            "_id": 5823,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 123,
            "שם_רחוב": "שביל הרקפת"
        },
        {
            "_id": 5824,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שביל השקד"
        },
        {
            "_id": 5825,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שביל השקמה"
        },
        {
            "_id": 5826,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שביל התמר"
        },
        {
            "_id": 5827,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 115,
            "שם_רחוב": "שביל כליל החורש"
        },
        {
            "_id": 5828,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 133,
            "שם_רחוב": "שביל עץ התות"
        },
        {
            "_id": 5829,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "שכונת הזיתים"
        },
        {
            "_id": 5830,
            "סמל_ישוב": 1256,
            "שם_ישוב": "אשרת ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "שכונת הראשונים"
        },
        {
            "_id": 5831,
            "סמל_ישוב": 740,
            "שם_ישוב": "אשתאול ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אשתאול"
        },
        {
            "_id": 5832,
            "סמל_ישוב": 1298,
            "שם_ישוב": "אתגר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "אתגר"
        },
        {
            "_id": 5833,
            "סמל_ישוב": 1298,
            "שם_ישוב": "אתגר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ש קרוונים"
        },
        {
            "_id": 5834,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אאל יאסר"
        },
        {
            "_id": 5835,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אאסיה"
        },
        {
            "_id": 5836,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "אבו אלהאוה"
        },
        {
            "_id": 5837,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אבו בקר אלסדיק"
        },
        {
            "_id": 5838,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אבו ד'ר אלגפארי"
        },
        {
            "_id": 5839,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אבו חניפה"
        },
        {
            "_id": 5840,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אבו כביר"
        },
        {
            "_id": 5841,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "אבו נאר"
        },
        {
            "_id": 5842,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "אבו קביר"
        },
        {
            "_id": 5843,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אבן אלג'ראח"
        },
        {
            "_id": 5844,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אבן אלשאטר"
        },
        {
            "_id": 5845,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אבן בטוטה"
        },
        {
            "_id": 5846,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אבן ח'ולדון"
        },
        {
            "_id": 5847,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אבן חנבל"
        },
        {
            "_id": 5848,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 5849,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אבן תיימה"
        },
        {
            "_id": 5850,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אבראהים טוקאן"
        },
        {
            "_id": 5851,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אוסאמה בן זייד"
        },
        {
            "_id": 5852,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6760,
            "שם_רחוב": "אזור התעשייה"
        },
        {
            "_id": 5853,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אחמד שאווקי"
        },
        {
            "_id": 5854,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6764,
            "שם_רחוב": "אל קביליה"
        },
        {
            "_id": 5855,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "אלאהליה"
        },
        {
            "_id": 5856,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אלאזדהאר"
        },
        {
            "_id": 5857,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אלאימאן"
        },
        {
            "_id": 5858,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אלאמאם אחמד"
        },
        {
            "_id": 5859,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 5860,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "אלאנדלוס"
        },
        {
            "_id": 5861,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אלאסמעי"
        },
        {
            "_id": 5862,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אלאספהאני"
        },
        {
            "_id": 5863,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אלאקחוואן"
        },
        {
            "_id": 5864,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "אלארז"
        },
        {
            "_id": 5865,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "אלבוחתורי"
        },
        {
            "_id": 5866,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "אלבוסתאן"
        },
        {
            "_id": 5867,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אלבוראק"
        },
        {
            "_id": 5868,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "אלבוריני"
        },
        {
            "_id": 5869,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "אלביארק"
        },
        {
            "_id": 5870,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "אלבלוט"
        },
        {
            "_id": 5871,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "אלבסה"
        },
        {
            "_id": 5872,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אלבסוס"
        },
        {
            "_id": 5873,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אלבסמה"
        },
        {
            "_id": 5874,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "אלבראעם"
        },
        {
            "_id": 5875,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "אלברייה"
        },
        {
            "_id": 5876,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6750,
            "שם_רחוב": "אלג'באל"
        },
        {
            "_id": 5877,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "אלג'בל"
        },
        {
            "_id": 5878,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 291,
            "שם_רחוב": "אלג'זאלי"
        },
        {
            "_id": 5879,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "אלג'זירה"
        },
        {
            "_id": 5880,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אלג'זרי"
        },
        {
            "_id": 5881,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אלג'למה"
        },
        {
            "_id": 5882,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "אלג'מיזה"
        },
        {
            "_id": 5883,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "אלדרדס"
        },
        {
            "_id": 5884,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 335,
            "שם_רחוב": "אלהודא"
        },
        {
            "_id": 5885,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 334,
            "שם_רחוב": "אלהודהוד"
        },
        {
            "_id": 5886,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 336,
            "שם_רחוב": "אלהלאל"
        },
        {
            "_id": 5887,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 337,
            "שם_רחוב": "אלוואחה"
        },
        {
            "_id": 5888,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 338,
            "שם_רחוב": "אלווחדה"
        },
        {
            "_id": 5889,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 340,
            "שם_רחוב": "אלוופאא"
        },
        {
            "_id": 5890,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 341,
            "שם_רחוב": "אלוופאק"
        },
        {
            "_id": 5891,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 339,
            "שם_רחוב": "אלוורוד"
        },
        {
            "_id": 5892,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "אלזהראווי"
        },
        {
            "_id": 5893,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "אלזוהור"
        },
        {
            "_id": 5894,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 5895,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "אלח'נדק"
        },
        {
            "_id": 5896,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6741,
            "שם_רחוב": "אלח'רבה"
        },
        {
            "_id": 5897,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "אלחג'ה נפיסה"
        },
        {
            "_id": 5898,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "אלחולפאא אלראשדין"
        },
        {
            "_id": 5899,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "אלחוסיין"
        },
        {
            "_id": 5900,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אלחליל"
        },
        {
            "_id": 5901,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "אלחמד"
        },
        {
            "_id": 5902,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אלחסון"
        },
        {
            "_id": 5903,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אלחסן"
        },
        {
            "_id": 5904,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "אלחקמה"
        },
        {
            "_id": 5905,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "אלחרוב"
        },
        {
            "_id": 5906,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 287,
            "שם_רחוב": "אלטאאוס"
        },
        {
            "_id": 5907,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 642,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 5908,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 344,
            "שם_רחוב": "אלימאמה"
        },
        {
            "_id": 5909,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 343,
            "שם_רחוב": "אלירמוק"
        },
        {
            "_id": 5910,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "אלכנארי"
        },
        {
            "_id": 5911,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "אלכנסאא"
        },
        {
            "_id": 5912,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "אלמג'דל"
        },
        {
            "_id": 5913,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אלמדינה אלמונאוורה"
        },
        {
            "_id": 5914,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6761,
            "שם_רחוב": "אלמוארס"
        },
        {
            "_id": 5915,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 324,
            "שם_רחוב": "אלמוהאג'רין"
        },
        {
            "_id": 5916,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אלמוואדה"
        },
        {
            "_id": 5917,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 315,
            "שם_רחוב": "אלמוסטפא"
        },
        {
            "_id": 5918,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "אלמותנבי"
        },
        {
            "_id": 5919,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 322,
            "שם_רחוב": "אלמנזול"
        },
        {
            "_id": 5920,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 323,
            "שם_רחוב": "אלמנפלוטי"
        },
        {
            "_id": 5921,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 317,
            "שם_רחוב": "אלמעסרה"
        },
        {
            "_id": 5922,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "אלמערפה"
        },
        {
            "_id": 5923,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 318,
            "שם_רחוב": "אלמקדאד"
        },
        {
            "_id": 5924,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 320,
            "שם_רחוב": "אלמקריזי"
        },
        {
            "_id": 5925,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אלמרווה"
        },
        {
            "_id": 5926,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 326,
            "שם_רחוב": "אלנבעה"
        },
        {
            "_id": 5927,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 332,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 5928,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 329,
            "שם_רחוב": "אלנזלה"
        },
        {
            "_id": 5929,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 333,
            "שם_רחוב": "אלניל"
        },
        {
            "_id": 5930,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 327,
            "שם_רחוב": "אלנכיל"
        },
        {
            "_id": 5931,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 331,
            "שם_רחוב": "אלנמארק"
        },
        {
            "_id": 5932,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6762,
            "שם_רחוב": "אלנמלה"
        },
        {
            "_id": 5933,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 330,
            "שם_רחוב": "אלנסר"
        },
        {
            "_id": 5934,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 5935,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 279,
            "שם_רחוב": "אלסבאח"
        },
        {
            "_id": 5936,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "אלסבאע"
        },
        {
            "_id": 5937,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 281,
            "שם_רחוב": "אלסדאקה"
        },
        {
            "_id": 5938,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "אלסוויקה"
        },
        {
            "_id": 5939,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "אלסומעה"
        },
        {
            "_id": 5940,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 280,
            "שם_רחוב": "אלסחאבה"
        },
        {
            "_id": 5941,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 5942,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 394,
            "שם_רחוב": "אלסלאמה"
        },
        {
            "_id": 5943,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "אלסנדיאן"
        },
        {
            "_id": 5944,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 285,
            "שם_רחוב": "אלסנובר"
        },
        {
            "_id": 5945,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 283,
            "שם_רחוב": "אלספאא"
        },
        {
            "_id": 5946,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 284,
            "שם_רחוב": "אלסקר"
        },
        {
            "_id": 5947,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 282,
            "שם_רחוב": "אלסראט"
        },
        {
            "_id": 5948,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "אלסרוו"
        },
        {
            "_id": 5949,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אלעאוודה"
        },
        {
            "_id": 5950,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 288,
            "שם_רחוב": "אלעג'מי"
        },
        {
            "_id": 5951,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 395,
            "שם_רחוב": "אלעזה"
        },
        {
            "_id": 5952,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 289,
            "שם_רחוב": "אלעקאד"
        },
        {
            "_id": 5953,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 292,
            "שם_רחוב": "אלפאראבי"
        },
        {
            "_id": 5954,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 297,
            "שם_רחוב": "אלפוארס"
        },
        {
            "_id": 5955,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 295,
            "שם_רחוב": "אלפוראת"
        },
        {
            "_id": 5956,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 296,
            "שם_רחוב": "אלפרדוס"
        },
        {
            "_id": 5957,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 298,
            "שם_רחוב": "אלקאדסיה"
        },
        {
            "_id": 5958,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "אלקאוות'ר"
        },
        {
            "_id": 5959,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 299,
            "שם_רחוב": "אלקאסמי"
        },
        {
            "_id": 5960,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "אלקודס"
        },
        {
            "_id": 5961,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "אלקורונפול"
        },
        {
            "_id": 5962,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "אלקזאז"
        },
        {
            "_id": 5963,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "אלקמה"
        },
        {
            "_id": 5964,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אלקמר"
        },
        {
            "_id": 5965,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "אלקראמה"
        },
        {
            "_id": 5966,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "אלקרוואן"
        },
        {
            "_id": 5967,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "אלראוודה"
        },
        {
            "_id": 5968,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "אלראזי"
        },
        {
            "_id": 5969,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "אלרבאט"
        },
        {
            "_id": 5970,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "אלרביע"
        },
        {
            "_id": 5971,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "אלרדוואן"
        },
        {
            "_id": 5972,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "אלרומאן"
        },
        {
            "_id": 5973,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "אלרחמה"
        },
        {
            "_id": 5974,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "אלריאחין"
        },
        {
            "_id": 5975,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "אלריאן"
        },
        {
            "_id": 5976,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "אלרפעה"
        },
        {
            "_id": 5977,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "אלשבאסיה ג'ת"
        },
        {
            "_id": 5978,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "אלשוקפאן"
        },
        {
            "_id": 5979,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 278,
            "שם_רחוב": "אלשייח' תיים"
        },
        {
            "_id": 5980,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6751,
            "שם_רחוב": "אלשמיה"
        },
        {
            "_id": 5981,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "אלשמס"
        },
        {
            "_id": 5982,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "אלתאבעין"
        },
        {
            "_id": 5983,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "אלתקווא"
        },
        {
            "_id": 5984,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "אם אלוואוויאת"
        },
        {
            "_id": 5985,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6743,
            "שם_רחוב": "אם אלרקאקי"
        },
        {
            "_id": 5986,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "באקה אל-גרביה"
        },
        {
            "_id": 5987,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 349,
            "שם_רחוב": "ביר באקה"
        },
        {
            "_id": 5988,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 350,
            "שם_רחוב": "ביר בורין"
        },
        {
            "_id": 5989,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 347,
            "שם_רחוב": "בלאל בן רבאח"
        },
        {
            "_id": 5990,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 352,
            "שם_רחוב": "ג'ובראן"
        },
        {
            "_id": 5991,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 359,
            "שם_רחוב": "דג'לה"
        },
        {
            "_id": 5992,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 367,
            "שם_רחוב": "דראר בן אלאזוור"
        },
        {
            "_id": 5993,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6752,
            "שם_רחוב": "המרכז (העיר הישנה)"
        },
        {
            "_id": 5994,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "ואדי אלח'רובי"
        },
        {
            "_id": 5995,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 360,
            "שם_רחוב": "זהרת אלמדאאן"
        },
        {
            "_id": 5996,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "ח'לת אלמרדאוי"
        },
        {
            "_id": 5997,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6742,
            "שם_רחוב": "ח'לת זיתא"
        },
        {
            "_id": 5998,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "חאתם אלטאאי"
        },
        {
            "_id": 5999,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 354,
            "שם_רחוב": "חסן אלבסרי"
        },
        {
            "_id": 6000,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 369,
            "שם_רחוב": "טאהה חוסיין"
        },
        {
            "_id": 6001,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 368,
            "שם_רחוב": "טארק בן זיאד"
        },
        {
            "_id": 6002,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 392,
            "שם_רחוב": "יאפא"
        },
        {
            "_id": 6003,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 393,
            "שם_רחוב": "ית'רב"
        },
        {
            "_id": 6004,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6763,
            "שם_רחוב": "למריבעה"
        },
        {
            "_id": 6005,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 383,
            "שם_רחוב": "מועאד בן ג'בל"
        },
        {
            "_id": 6006,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 385,
            "שם_רחוב": "מכה אלמוקרמה"
        },
        {
            "_id": 6007,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 384,
            "שם_רחוב": "מערוף אלרסאפי"
        },
        {
            "_id": 6008,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "מרג'ת אלע'וזלאן"
        },
        {
            "_id": 6009,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 365,
            "שם_רחוב": "סלאח אלדין"
        },
        {
            "_id": 6010,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "סמ 20 1"
        },
        {
            "_id": 6011,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "סמ 1 1"
        },
        {
            "_id": 6012,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "סמ 1 2"
        },
        {
            "_id": 6013,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "סמ 12 2"
        },
        {
            "_id": 6014,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "סמ 15 1"
        },
        {
            "_id": 6015,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "סמ 6 1"
        },
        {
            "_id": 6016,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "סמ 19 1"
        },
        {
            "_id": 6017,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 366,
            "שם_רחוב": "סנעאא"
        },
        {
            "_id": 6018,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 361,
            "שם_רחוב": "סעד בן אבי וקאס"
        },
        {
            "_id": 6019,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "עובאדה בן אלסאמט"
        },
        {
            "_id": 6020,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 376,
            "שם_רחוב": "עומר אלמוכתאר"
        },
        {
            "_id": 6021,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 375,
            "שם_רחוב": "עומר בן אלח'טאב"
        },
        {
            "_id": 6022,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 372,
            "שם_רחוב": "עוקבה"
        },
        {
            "_id": 6023,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 371,
            "שם_רחוב": "עותמאן בן עפאן"
        },
        {
            "_id": 6024,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 373,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 6025,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 374,
            "שם_רחוב": "עמאר בן יאסר"
        },
        {
            "_id": 6026,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 377,
            "שם_רחוב": "ענתרה"
        },
        {
            "_id": 6027,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 378,
            "שם_רחוב": "פאטמה אלזהאא"
        },
        {
            "_id": 6028,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רח 10"
        },
        {
            "_id": 6029,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רח 20"
        },
        {
            "_id": 6030,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "רח 30"
        },
        {
            "_id": 6031,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "רח 1"
        },
        {
            "_id": 6032,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רח 11"
        },
        {
            "_id": 6033,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "רח 21"
        },
        {
            "_id": 6034,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "רח 31"
        },
        {
            "_id": 6035,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רח 2"
        },
        {
            "_id": 6036,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רח 12"
        },
        {
            "_id": 6037,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רח 22"
        },
        {
            "_id": 6038,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רח 32"
        },
        {
            "_id": 6039,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רח 3"
        },
        {
            "_id": 6040,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רח 13"
        },
        {
            "_id": 6041,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רח 23"
        },
        {
            "_id": 6042,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח 4"
        },
        {
            "_id": 6043,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רח 14"
        },
        {
            "_id": 6044,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רח 24"
        },
        {
            "_id": 6045,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רח 5"
        },
        {
            "_id": 6046,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח 15"
        },
        {
            "_id": 6047,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רח 25"
        },
        {
            "_id": 6048,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רח 6"
        },
        {
            "_id": 6049,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רח 16"
        },
        {
            "_id": 6050,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "רח 26"
        },
        {
            "_id": 6051,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רח 7"
        },
        {
            "_id": 6052,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רח 17"
        },
        {
            "_id": 6053,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "רח 27"
        },
        {
            "_id": 6054,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רח 8"
        },
        {
            "_id": 6055,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח 18"
        },
        {
            "_id": 6056,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "רח 28"
        },
        {
            "_id": 6057,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "רח 9"
        },
        {
            "_id": 6058,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח 19"
        },
        {
            "_id": 6059,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "רח 29"
        },
        {
            "_id": 6060,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 364,
            "שם_רחוב": "שקאאק אלנעמאן"
        },
        {
            "_id": 6061,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 363,
            "שם_רחוב": "שרחביל בן חסנה"
        },
        {
            "_id": 6062,
            "סמל_ישוב": 6000,
            "שם_ישוב": "באקה אל-גרביה ",
            "סמל_רחוב": 351,
            "שם_רחוב": "תבוק"
        },
        {
            "_id": 6063,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אורה"
        },
        {
            "_id": 6064,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "באר אורה"
        },
        {
            "_id": 6065,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "משעול אוכם"
        },
        {
            "_id": 6066,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "משעול דוחן"
        },
        {
            "_id": 6067,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "משעול דרדר"
        },
        {
            "_id": 6068,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "משעול חכליל"
        },
        {
            "_id": 6069,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "משעול כוכב"
        },
        {
            "_id": 6070,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "משעול לענה"
        },
        {
            "_id": 6071,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "משעול מכרות"
        },
        {
            "_id": 6072,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "משעול נשף"
        },
        {
            "_id": 6073,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "משעול סהרון"
        },
        {
            "_id": 6074,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "משעול סיתוונית"
        },
        {
            "_id": 6075,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "משעול עמרם"
        },
        {
            "_id": 6076,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "משעול פיקוס"
        },
        {
            "_id": 6077,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "משעול צלף"
        },
        {
            "_id": 6078,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "משעול רחם"
        },
        {
            "_id": 6079,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "משעול שגיא"
        },
        {
            "_id": 6080,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "משעול שחורת"
        },
        {
            "_id": 6081,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "משעול שנהבית"
        },
        {
            "_id": 6082,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "סמ דקל דום"
        },
        {
            "_id": 6083,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סמ האשל"
        },
        {
            "_id": 6084,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סמ הר כרכום"
        },
        {
            "_id": 6085,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "סמ הר צניפים"
        },
        {
            "_id": 6086,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "סמ הר שני"
        },
        {
            "_id": 6087,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "סמ השיטה"
        },
        {
            "_id": 6088,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "סמ שיזף"
        },
        {
            "_id": 6089,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "עמודי שלמה"
        },
        {
            "_id": 6090,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רותם המדבר"
        },
        {
            "_id": 6091,
            "סמל_ישוב": 21,
            "שם_ישוב": "באר אורה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "תמנע"
        },
        {
            "_id": 6092,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אדווה"
        },
        {
            "_id": 6093,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "איבי הנחל"
        },
        {
            "_id": 6094,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 6095,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אפיקי מים"
        },
        {
            "_id": 6096,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "באר גנים"
        },
        {
            "_id": 6097,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 6098,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך האור"
        },
        {
            "_id": 6099,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דרך הגן"
        },
        {
            "_id": 6100,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 6101,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 6102,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 6103,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "החריש"
        },
        {
            "_id": 6104,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 6105,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "זית"
        },
        {
            "_id": 6106,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "חבצלת החוף"
        },
        {
            "_id": 6107,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 6108,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ישפה"
        },
        {
            "_id": 6109,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 6110,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "מלכית"
        },
        {
            "_id": 6111,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נוף ים"
        },
        {
            "_id": 6112,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 6113,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "צדף"
        },
        {
            "_id": 6114,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רותם המדבר"
        },
        {
            "_id": 6115,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 6116,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 6117,
            "סמל_ישוב": 1376,
            "שם_ישוב": "באר גנים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "שונית"
        },
        {
            "_id": 6118,
            "סמל_ישוב": 155,
            "שם_ישוב": "באר טוביה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "באר טוביה"
        },
        {
            "_id": 6119,
            "סמל_ישוב": 155,
            "שם_ישוב": "באר טוביה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שכ קרוונים"
        },
        {
            "_id": 6120,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אבא הלל"
        },
        {
            "_id": 6121,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אביב"
        },
        {
            "_id": 6122,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אגמיה"
        },
        {
            "_id": 6123,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 196,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 6124,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "אזור תעשייה"
        },
        {
            "_id": 6125,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 143,
            "שם_רחוב": "איילה"
        },
        {
            "_id": 6126,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 212,
            "שם_רחוב": "אלות"
        },
        {
            "_id": 6127,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אליהו"
        },
        {
            "_id": 6128,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "באר יעקב"
        },
        {
            "_id": 6129,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ביח אסף הרופא"
        },
        {
            "_id": 6130,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 125,
            "שם_רחוב": "ביח מלבן"
        },
        {
            "_id": 6131,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 123,
            "שם_רחוב": "ביח שמואל הרופא"
        },
        {
            "_id": 6132,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 132,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 6133,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 234,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 6134,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גבעת חוטר"
        },
        {
            "_id": 6135,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 224,
            "שם_רחוב": "גולדברג לאה"
        },
        {
            "_id": 6136,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 240,
            "שם_רחוב": "גיא"
        },
        {
            "_id": 6137,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "גני מנחם"
        },
        {
            "_id": 6138,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 246,
            "שם_רחוב": "דגן"
        },
        {
            "_id": 6139,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 148,
            "שם_רחוב": "דוד הכהן"
        },
        {
            "_id": 6140,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 194,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 6141,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 178,
            "שם_רחוב": "דיזנגוף"
        },
        {
            "_id": 6142,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 183,
            "שם_רחוב": "דן"
        },
        {
            "_id": 6143,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דניאל יצחקי"
        },
        {
            "_id": 6144,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 144,
            "שם_רחוב": "דרך חיים"
        },
        {
            "_id": 6145,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 156,
            "שם_רחוב": "דרך סטון יוסף"
        },
        {
            "_id": 6146,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך צהל"
        },
        {
            "_id": 6147,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך רון ארד"
        },
        {
            "_id": 6148,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 154,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 6149,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 151,
            "שם_רחוב": "האיריס"
        },
        {
            "_id": 6150,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 117,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 6151,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 165,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 6152,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 175,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 6153,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 164,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 6154,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 198,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 6155,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 179,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 6156,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 160,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 6157,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 213,
            "שם_רחוב": "הבשור"
        },
        {
            "_id": 6158,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 180,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 6159,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 6160,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 230,
            "שם_רחוב": "הדובדבן"
        },
        {
            "_id": 6161,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 168,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 6162,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 188,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 6163,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 174,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 6164,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הולצברג שמחה"
        },
        {
            "_id": 6165,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 139,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 6166,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 189,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 6167,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 173,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 6168,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 152,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 6169,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 118,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 6170,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 181,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 6171,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 120,
            "שם_רחוב": "היוזמה"
        },
        {
            "_id": 6172,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 147,
            "שם_רחוב": "היוצר"
        },
        {
            "_id": 6173,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 187,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 6174,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 185,
            "שם_רחוב": "הירמוך"
        },
        {
            "_id": 6175,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 184,
            "שם_רחוב": "הירקון"
        },
        {
            "_id": 6176,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 150,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 6177,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 131,
            "שם_רחוב": "המושבה"
        },
        {
            "_id": 6178,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 191,
            "שם_רחוב": "הנקר"
        },
        {
            "_id": 6179,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 6180,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 172,
            "שם_רחוב": "הסנונית"
        },
        {
            "_id": 6181,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 176,
            "שם_רחוב": "העפרוני"
        },
        {
            "_id": 6182,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 214,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 6183,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 171,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 6184,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הצמיחה"
        },
        {
            "_id": 6185,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 186,
            "שם_רחוב": "הקישון"
        },
        {
            "_id": 6186,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הרב ניסים טולדנו"
        },
        {
            "_id": 6187,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 6188,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 6189,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 157,
            "שם_רחוב": "הרב שפירא"
        },
        {
            "_id": 6190,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 142,
            "שם_רחוב": "השושנים"
        },
        {
            "_id": 6191,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 162,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 6192,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 145,
            "שם_רחוב": "השניים"
        },
        {
            "_id": 6193,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 163,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 6194,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 155,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 6195,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 182,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 6196,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 161,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 6197,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 124,
            "שם_רחוב": "התנופה"
        },
        {
            "_id": 6198,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 197,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 6199,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 209,
            "שם_רחוב": "ורדית"
        },
        {
            "_id": 6200,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 112,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 6201,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 202,
            "שם_רחוב": "חופית"
        },
        {
            "_id": 6202,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 228,
            "שם_רחוב": "חושן"
        },
        {
            "_id": 6203,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 6204,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 216,
            "שם_רחוב": "חרוד"
        },
        {
            "_id": 6205,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 220,
            "שם_רחוב": "חתני פרס ישראל"
        },
        {
            "_id": 6206,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 225,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 6207,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 149,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 6208,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 239,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 6209,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 115,
            "שם_רחוב": "יחזקאל באטה"
        },
        {
            "_id": 6210,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 141,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 6211,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 207,
            "שם_רחוב": "יסעור"
        },
        {
            "_id": 6212,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 122,
            "שם_רחוב": "ישיבת באר יעקב"
        },
        {
            "_id": 6213,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 135,
            "שם_רחוב": "ישיבת שארית יוסף"
        },
        {
            "_id": 6214,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 238,
            "שם_רחוב": "ישפה"
        },
        {
            "_id": 6215,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "כ חלוצי מעברת חוטר"
        },
        {
            "_id": 6216,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "כיכר המייסדים"
        },
        {
            "_id": 6217,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "כיכר השוטר"
        },
        {
            "_id": 6218,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "כיכר ערים תאומות"
        },
        {
            "_id": 6219,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "כיכר תנועות הנוער"
        },
        {
            "_id": 6220,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר חנה"
        },
        {
            "_id": 6221,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר קפלן"
        },
        {
            "_id": 6222,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר רוטרי"
        },
        {
            "_id": 6223,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "כפר יוהנה זבוטינסקי"
        },
        {
            "_id": 6224,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 242,
            "שם_רחוב": "כרמים"
        },
        {
            "_id": 6225,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 203,
            "שם_רחוב": "ליבנית"
        },
        {
            "_id": 6226,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 200,
            "שם_רחוב": "ליכטנשטיין אברהם"
        },
        {
            "_id": 6227,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 227,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 6228,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 107,
            "שם_רחוב": "מאיר בעל הנס"
        },
        {
            "_id": 6229,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 221,
            "שם_רחוב": "מנור אהוד"
        },
        {
            "_id": 6230,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "נוה ספיר"
        },
        {
            "_id": 6231,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "נווה דורון"
        },
        {
            "_id": 6232,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 235,
            "שם_רחוב": "נופך"
        },
        {
            "_id": 6233,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 114,
            "שם_רחוב": "נחום"
        },
        {
            "_id": 6234,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 195,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 6235,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 140,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 6236,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 208,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 6237,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 177,
            "שם_רחוב": "סמ האנקור"
        },
        {
            "_id": 6238,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 159,
            "שם_רחוב": "סמ האשל"
        },
        {
            "_id": 6239,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 158,
            "שם_רחוב": "סמ הצאלון"
        },
        {
            "_id": 6240,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 233,
            "שם_רחוב": "סמ הרימון"
        },
        {
            "_id": 6241,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 231,
            "שם_רחוב": "סמ הרקפת"
        },
        {
            "_id": 6242,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 167,
            "שם_רחוב": "סמ השיקמה"
        },
        {
            "_id": 6243,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 232,
            "שם_רחוב": "סמ התפוח"
        },
        {
            "_id": 6244,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 226,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 6245,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "סרפנד אל עמר"
        },
        {
            "_id": 6246,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 126,
            "שם_רחוב": "עודד"
        },
        {
            "_id": 6247,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 245,
            "שם_רחוב": "עומר"
        },
        {
            "_id": 6248,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 244,
            "שם_רחוב": "עמק"
        },
        {
            "_id": 6249,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 129,
            "שם_רחוב": "עמרם נעים"
        },
        {
            "_id": 6250,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 215,
            "שם_רחוב": "ערוגות"
        },
        {
            "_id": 6251,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "פארק תלמ"
        },
        {
            "_id": 6252,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 206,
            "שם_רחוב": "פלמינגו"
        },
        {
            "_id": 6253,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 190,
            "שם_רחוב": "פשוש"
        },
        {
            "_id": 6254,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 192,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 6255,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 205,
            "שם_רחוב": "צוקית"
        },
        {
            "_id": 6256,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "צריפין"
        },
        {
            "_id": 6257,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 223,
            "שם_רחוב": "קישון אפרים"
        },
        {
            "_id": 6258,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 243,
            "שם_רחוב": "קציר"
        },
        {
            "_id": 6259,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 166,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 6260,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש אגש"
        },
        {
            "_id": 6261,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש טוניס"
        },
        {
            "_id": 6262,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש נוה עובד"
        },
        {
            "_id": 6263,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "ש עובדי מלבן"
        },
        {
            "_id": 6264,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 134,
            "שם_רחוב": "שא נס"
        },
        {
            "_id": 6265,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 236,
            "שם_רחוב": "שבו"
        },
        {
            "_id": 6266,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 127,
            "שם_רחוב": "שביט"
        },
        {
            "_id": 6267,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 169,
            "שם_רחוב": "שד האלונים"
        },
        {
            "_id": 6268,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 170,
            "שם_רחוב": "שד רבין יצחק"
        },
        {
            "_id": 6269,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 222,
            "שם_רחוב": "שד שמר נעמי"
        },
        {
            "_id": 6270,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 241,
            "שם_רחוב": "שדות"
        },
        {
            "_id": 6271,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 210,
            "שם_רחוב": "שדרות יצחק שמיר"
        },
        {
            "_id": 6272,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 237,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 6273,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 193,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 6274,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ הרצל"
        },
        {
            "_id": 6275,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ חתני פרס ישראל"
        },
        {
            "_id": 6276,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ רמבם"
        },
        {
            "_id": 6277,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ תלמי מנשה"
        },
        {
            "_id": 6278,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 199,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 6279,
            "סמל_ישוב": 2530,
            "שם_ישוב": "באר יעקב ",
            "סמל_רחוב": 146,
            "שם_רחוב": "שמוליק בורג"
        },
        {
            "_id": 6280,
            "סמל_ישוב": 1278,
            "שם_ישוב": "באר מילכה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "באר מילכה"
        },
        {
            "_id": 6281,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 551,
            "שם_רחוב": "אבוחצירא"
        },
        {
            "_id": 6282,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אבטליון"
        },
        {
            "_id": 6283,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1029,
            "שם_רחוב": "אביבית"
        },
        {
            "_id": 6284,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1287,
            "שם_רחוב": "אביגד"
        },
        {
            "_id": 6285,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1054,
            "שם_רחוב": "אביגדור המאירי"
        },
        {
            "_id": 6286,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 449,
            "שם_רחוב": "אביגור שאול"
        },
        {
            "_id": 6287,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1111,
            "שם_רחוב": "אביגיל"
        },
        {
            "_id": 6288,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 552,
            "שם_רחוב": "אבידוב צבי"
        },
        {
            "_id": 6289,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1031,
            "שם_רחוב": "אבידום מנחם"
        },
        {
            "_id": 6290,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1332,
            "שם_רחוב": "אבידן שמעון"
        },
        {
            "_id": 6291,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אביה השופט"
        },
        {
            "_id": 6292,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1112,
            "שם_רחוב": "אביטל"
        },
        {
            "_id": 6293,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1113,
            "שם_רחוב": "אבישג"
        },
        {
            "_id": 6294,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 497,
            "שם_רחוב": "אבישי"
        },
        {
            "_id": 6295,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1114,
            "שם_רחוב": "אביתר הכהן"
        },
        {
            "_id": 6296,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1236,
            "שם_רחוב": "אבן ארי מיכאל"
        },
        {
            "_id": 6297,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 401,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 6298,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 6299,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1115,
            "שם_רחוב": "אבן שפרוט"
        },
        {
            "_id": 6300,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1381,
            "שם_רחוב": "אבן-שושן אברהם"
        },
        {
            "_id": 6301,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 475,
            "שם_רחוב": "אבנר"
        },
        {
            "_id": 6302,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 347,
            "שם_רחוב": "אברבנאל"
        },
        {
            "_id": 6303,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אברהם אבינו"
        },
        {
            "_id": 6304,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1344,
            "שם_רחוב": "אברמסון שרגא"
        },
        {
            "_id": 6305,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1116,
            "שם_רחוב": "אבשלום"
        },
        {
            "_id": 6306,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 554,
            "שם_רחוב": "אגן"
        },
        {
            "_id": 6307,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1117,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 6308,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1420,
            "שם_רחוב": "אגס"
        },
        {
            "_id": 6309,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1376,
            "שם_רחוב": "אגרנט שמעון"
        },
        {
            "_id": 6310,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אדלה עאזר (כדן)"
        },
        {
            "_id": 6311,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 555,
            "שם_רחוב": "אדלר"
        },
        {
            "_id": 6312,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1032,
            "שם_רחוב": "אדמון ידידיה"
        },
        {
            "_id": 6313,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 976,
            "שם_רחוב": "אהוד בן גרא"
        },
        {
            "_id": 6314,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1227,
            "שם_רחוב": "אהרוני יוחנן"
        },
        {
            "_id": 6315,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1118,
            "שם_רחוב": "אהרונסון"
        },
        {
            "_id": 6316,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1222,
            "שם_רחוב": "אהרליך פאול"
        },
        {
            "_id": 6317,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 963,
            "שם_רחוב": "אוהלי קידר"
        },
        {
            "_id": 6318,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1447,
            "שם_רחוב": "אוכמנית"
        },
        {
            "_id": 6319,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אולגה שמיר"
        },
        {
            "_id": 6320,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1119,
            "שם_רחוב": "אוליצקי אריה"
        },
        {
            "_id": 6321,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1228,
            "שם_רחוב": "אולנדורף פרנץ"
        },
        {
            "_id": 6322,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 556,
            "שם_רחוב": "אולשן יצחק"
        },
        {
            "_id": 6323,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 521,
            "שם_רחוב": "אוניברס בן גוריון"
        },
        {
            "_id": 6324,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1465,
            "שם_רחוב": "אוניברסיטת ב גוריון"
        },
        {
            "_id": 6325,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 984,
            "שם_רחוב": "אוסטרובסקי גרשון"
        },
        {
            "_id": 6326,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 801,
            "שם_רחוב": "אוסישקין"
        },
        {
            "_id": 6327,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1383,
            "שם_רחוב": "אורבך אפרים"
        },
        {
            "_id": 6328,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1088,
            "שם_רחוב": "אורות ישראל"
        },
        {
            "_id": 6329,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1288,
            "שם_רחוב": "אורי צבי"
        },
        {
            "_id": 6330,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 901,
            "שם_רחוב": "אוריה החיתי"
        },
        {
            "_id": 6331,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1385,
            "שם_רחוב": "אורלנד יעקב"
        },
        {
            "_id": 6332,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1446,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 6333,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6606,
            "שם_רחוב": "אזור התעשיה"
        },
        {
            "_id": 6334,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 355,
            "שם_רחוב": "אחאב"
        },
        {
            "_id": 6335,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 802,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 6336,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1120,
            "שם_רחוב": "אחימאיר אבא"
        },
        {
            "_id": 6337,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1127,
            "שם_רחוב": "אחינועם"
        },
        {
            "_id": 6338,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1001,
            "שם_רחוב": "אטד"
        },
        {
            "_id": 6339,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אידה בוקסבוים"
        },
        {
            "_id": 6340,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1314,
            "שם_רחוב": "אייזנשטדט"
        },
        {
            "_id": 6341,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1099,
            "שם_רחוב": "איילת השחר"
        },
        {
            "_id": 6342,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 988,
            "שם_רחוב": "אימבר"
        },
        {
            "_id": 6343,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 402,
            "שם_רחוב": "אינשטיין"
        },
        {
            "_id": 6344,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1015,
            "שם_רחוב": "אירוס הנגב"
        },
        {
            "_id": 6345,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1253,
            "שם_רחוב": "אלבק חנוך"
        },
        {
            "_id": 6346,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1265,
            "שם_רחוב": "אלדד"
        },
        {
            "_id": 6347,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1448,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 6348,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 616,
            "שם_רחוב": "אלון יגאל"
        },
        {
            "_id": 6349,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1320,
            "שם_רחוב": "אלוף אדם יקותיאל"
        },
        {
            "_id": 6350,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1323,
            "שם_רחוב": "אלוף יריב אהרון"
        },
        {
            "_id": 6351,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1321,
            "שם_רחוב": "אלוף מגן קלמן"
        },
        {
            "_id": 6352,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1322,
            "שם_רחוב": "אלוף מנדלר אלברט"
        },
        {
            "_id": 6353,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1128,
            "שם_רחוב": "אלחריזי"
        },
        {
            "_id": 6354,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אליאונור ברנהיים"
        },
        {
            "_id": 6355,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אליהו הנביא"
        },
        {
            "_id": 6356,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 558,
            "שם_רחוב": "אלישבע"
        },
        {
            "_id": 6357,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 903,
            "שם_רחוב": "אלישע"
        },
        {
            "_id": 6358,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1258,
            "שם_רחוב": "אלישר"
        },
        {
            "_id": 6359,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אלכסנדר ינאי"
        },
        {
            "_id": 6360,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1449,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 6361,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אלעזר בן יאיר"
        },
        {
            "_id": 6362,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 348,
            "שם_רחוב": "אלפסי"
        },
        {
            "_id": 6363,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1289,
            "שם_רחוב": "אלקחי מרדכי"
        },
        {
            "_id": 6364,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 884,
            "שם_רחוב": "אלקיים ניסים"
        },
        {
            "_id": 6365,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1262,
            "שם_רחוב": "אלקלעי יהודה"
        },
        {
            "_id": 6366,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1121,
            "שם_רחוב": "אלרואי דוד"
        },
        {
            "_id": 6367,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 454,
            "שם_רחוב": "אלתרמן"
        },
        {
            "_id": 6368,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1313,
            "שם_רחוב": "אמיר אברהם"
        },
        {
            "_id": 6369,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אמירה בלייברג"
        },
        {
            "_id": 6370,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1122,
            "שם_רחוב": "אמנון"
        },
        {
            "_id": 6371,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 902,
            "שם_רחוב": "אנילביץ"
        },
        {
            "_id": 6372,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1311,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 6373,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אנקור"
        },
        {
            "_id": 6374,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 298,
            "שם_רחוב": "אסא"
        },
        {
            "_id": 6375,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 611,
            "שם_רחוב": "אסבסטונים"
        },
        {
            "_id": 6376,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 601,
            "שם_רחוב": "אסירי ציון"
        },
        {
            "_id": 6377,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1035,
            "שם_רחוב": "אסנת"
        },
        {
            "_id": 6378,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אסנת ברזאני"
        },
        {
            "_id": 6379,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 403,
            "שם_רחוב": "אסף הרופא"
        },
        {
            "_id": 6380,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1203,
            "שם_רחוב": "אסר טוביאס"
        },
        {
            "_id": 6381,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1123,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 6382,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 871,
            "שם_רחוב": "אפיקים בנגב"
        },
        {
            "_id": 6383,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 489,
            "שם_רחוב": "אפק"
        },
        {
            "_id": 6384,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 904,
            "שם_רחוב": "אפרים"
        },
        {
            "_id": 6385,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1450,
            "שם_רחוב": "אפרסמון"
        },
        {
            "_id": 6386,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 559,
            "שם_רחוב": "אפרת יעקב"
        },
        {
            "_id": 6387,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1307,
            "שם_רחוב": "אפשטיין ישראל"
        },
        {
            "_id": 6388,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 900,
            "שם_רחוב": "אצג"
        },
        {
            "_id": 6389,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 373,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 6390,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1437,
            "שם_רחוב": "אקליפטוס"
        },
        {
            "_id": 6391,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 560,
            "שם_רחוב": "אקצין בנימין"
        },
        {
            "_id": 6392,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1036,
            "שם_רחוב": "ארבע הגיבורות"
        },
        {
            "_id": 6393,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1384,
            "שם_רחוב": "ארגוב אלכסנדר"
        },
        {
            "_id": 6394,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1387,
            "שם_רחוב": "ארדון מרדכי"
        },
        {
            "_id": 6395,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 286,
            "שם_רחוב": "אריסטובולוס"
        },
        {
            "_id": 6396,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 501,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 6397,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1110,
            "שם_רחוב": "ארליך שמחה"
        },
        {
            "_id": 6398,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1221,
            "שם_רחוב": "ארלנגר ג'וזף"
        },
        {
            "_id": 6399,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 468,
            "שם_רחוב": "ארן זלמן"
        },
        {
            "_id": 6400,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 561,
            "שם_רחוב": "ארנון יצחק"
        },
        {
            "_id": 6401,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 266,
            "שם_רחוב": "ארניה אסוולדו"
        },
        {
            "_id": 6402,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1095,
            "שם_רחוב": "ארץ חמדה"
        },
        {
            "_id": 6403,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 667,
            "שם_רחוב": "ארתור ווסרמיל"
        },
        {
            "_id": 6404,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 369,
            "שם_רחוב": "אש שלום"
        },
        {
            "_id": 6405,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1037,
            "שם_רחוב": "אשבל מיכאל"
        },
        {
            "_id": 6406,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1371,
            "שם_רחוב": "אשכולית"
        },
        {
            "_id": 6407,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1002,
            "שם_רחוב": "אשלים"
        },
        {
            "_id": 6408,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 905,
            "שם_רחוב": "אשר"
        },
        {
            "_id": 6409,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "באר שבע"
        },
        {
            "_id": 6410,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 428,
            "שם_רחוב": "בארון דבורה"
        },
        {
            "_id": 6411,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 534,
            "שם_רחוב": "בגין מנחם"
        },
        {
            "_id": 6412,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1268,
            "שם_רחוב": "בדר יוחנן"
        },
        {
            "_id": 6413,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 553,
            "שם_רחוב": "בובר מרטין"
        },
        {
            "_id": 6414,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 528,
            "שם_רחוב": "בוגלה יונה"
        },
        {
            "_id": 6415,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1353,
            "שם_רחוב": "בודנהיימר"
        },
        {
            "_id": 6416,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 161,
            "שם_רחוב": "בונה אויערבך"
        },
        {
            "_id": 6417,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1038,
            "שם_רחוב": "בוסקוביץ אלכסנדר"
        },
        {
            "_id": 6418,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1124,
            "שם_רחוב": "בוסתנאי"
        },
        {
            "_id": 6419,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 134,
            "שם_רחוב": "בורג יוסף"
        },
        {
            "_id": 6420,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 803,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 6421,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 343,
            "שם_רחוב": "בורלא יהודה"
        },
        {
            "_id": 6422,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1214,
            "שם_רחוב": "בורן מכס"
        },
        {
            "_id": 6423,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 638,
            "שם_רחוב": "בושמת"
        },
        {
            "_id": 6424,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 131,
            "שם_רחוב": "בז"
        },
        {
            "_id": 6425,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 804,
            "שם_רחוב": "בזל"
        },
        {
            "_id": 6426,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1017,
            "שם_רחוב": "ביס חקלאי"
        },
        {
            "_id": 6427,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 404,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 6428,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 974,
            "שם_רחוב": "ביח הדסה"
        },
        {
            "_id": 6429,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1039,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 6430,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 832,
            "שם_רחוב": "בינט מקס"
        },
        {
            "_id": 6431,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 346,
            "שם_רחוב": "בית אל"
        },
        {
            "_id": 6432,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 906,
            "שם_רחוב": "בית אשל"
        },
        {
            "_id": 6433,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 993,
            "שם_רחוב": "בית החייל"
        },
        {
            "_id": 6434,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 991,
            "שם_רחוב": "בית יציב"
        },
        {
            "_id": 6435,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 381,
            "שם_רחוב": "בית לחם"
        },
        {
            "_id": 6436,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1291,
            "שם_רחוב": "בית צורי אליהו"
        },
        {
            "_id": 6437,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 334,
            "שם_רחוב": "בית רמט"
        },
        {
            "_id": 6438,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 486,
            "שם_רחוב": "בית שאן"
        },
        {
            "_id": 6439,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1125,
            "שם_רחוב": "בלהה"
        },
        {
            "_id": 6440,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1233,
            "שם_רחוב": "בלוך רודולף"
        },
        {
            "_id": 6441,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 805,
            "שם_רחוב": "בלפור"
        },
        {
            "_id": 6442,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1126,
            "שם_רחוב": "בלקינד נעמן"
        },
        {
            "_id": 6443,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 562,
            "שם_רחוב": "בן איש חי"
        },
        {
            "_id": 6444,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 563,
            "שם_רחוב": "בן אשר"
        },
        {
            "_id": 6445,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1205,
            "שם_רחוב": "בן דוד יוסף"
        },
        {
            "_id": 6446,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 564,
            "שם_רחוב": "בן חביב"
        },
        {
            "_id": 6447,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1040,
            "שם_רחוב": "בן חיים פאול"
        },
        {
            "_id": 6448,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 502,
            "שם_רחוב": "בן יהודה"
        },
        {
            "_id": 6449,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1290,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 6450,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 432,
            "שם_רחוב": "בן יעקב אהרון"
        },
        {
            "_id": 6451,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1041,
            "שם_רחוב": "בן יעקב צבי"
        },
        {
            "_id": 6452,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1130,
            "שם_רחוב": "בן לברט"
        },
        {
            "_id": 6453,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1131,
            "שם_רחוב": "בן סרוק מנחם"
        },
        {
            "_id": 6454,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 433,
            "שם_רחוב": "בן עדי הרברט"
        },
        {
            "_id": 6455,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 895,
            "שם_רחוב": "בן עמי אברהם"
        },
        {
            "_id": 6456,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 834,
            "שם_רחוב": "בן צבי יצחק"
        },
        {
            "_id": 6457,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 243,
            "שם_רחוב": "בן שטח שמעון"
        },
        {
            "_id": 6458,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1408,
            "שם_רחוב": "בן-אסא בנימין"
        },
        {
            "_id": 6459,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 663,
            "שם_רחוב": "בן-חור אליהו"
        },
        {
            "_id": 6460,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6611,
            "שם_רחוב": "בנה ביתך רמות"
        },
        {
            "_id": 6461,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1243,
            "שם_רחוב": "בנטואיץ יוסף"
        },
        {
            "_id": 6462,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1129,
            "שם_רחוב": "בנטוב מרדכי"
        },
        {
            "_id": 6463,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 291,
            "שם_רחוב": "בני אור"
        },
        {
            "_id": 6464,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 907,
            "שם_רחוב": "בנימין"
        },
        {
            "_id": 6465,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 267,
            "שם_רחוב": "בנימין מטודלה"
        },
        {
            "_id": 6466,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "בסיס חצרים"
        },
        {
            "_id": 6467,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 602,
            "שם_רחוב": "בסקין צבי"
        },
        {
            "_id": 6468,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 279,
            "שם_רחוב": "בעלי התוספות"
        },
        {
            "_id": 6469,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1201,
            "שם_רחוב": "בער יצחק"
        },
        {
            "_id": 6470,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 405,
            "שם_רחוב": "בצלאל"
        },
        {
            "_id": 6471,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1345,
            "שם_רחוב": "בקי רוברטו"
        },
        {
            "_id": 6472,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 806,
            "שם_רחוב": "בר אילן"
        },
        {
            "_id": 6473,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1248,
            "שם_רחוב": "בר הלל יהושע"
        },
        {
            "_id": 6474,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 241,
            "שם_רחוב": "בר יוחאי"
        },
        {
            "_id": 6475,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 908,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 6476,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1043,
            "שם_רחוב": "בר ניסן"
        },
        {
            "_id": 6477,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 664,
            "שם_רחוב": "בר-לב חיים"
        },
        {
            "_id": 6478,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 132,
            "שם_רחוב": "ברבור"
        },
        {
            "_id": 6479,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1247,
            "שם_רחוב": "ברגסון אנרי"
        },
        {
            "_id": 6480,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1042,
            "שם_רחוב": "ברדיצ'ב אבא"
        },
        {
            "_id": 6481,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 565,
            "שם_רחוב": "ברדיצ'בסקי"
        },
        {
            "_id": 6482,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1132,
            "שם_רחוב": "ברוד מקס"
        },
        {
            "_id": 6483,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1256,
            "שם_רחוב": "ברוור אברהם"
        },
        {
            "_id": 6484,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1133,
            "שם_רחוב": "ברוידס אברהם"
        },
        {
            "_id": 6485,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1134,
            "שם_רחוב": "ברזילי ישראל"
        },
        {
            "_id": 6486,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1292,
            "שם_רחוב": "ברזני משה"
        },
        {
            "_id": 6487,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 566,
            "שם_רחוב": "ברטונוב יהושע"
        },
        {
            "_id": 6488,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 567,
            "שם_רחוב": "ברית כהונה"
        },
        {
            "_id": 6489,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 190,
            "שם_רחוב": "ברכה צפירה"
        },
        {
            "_id": 6490,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1231,
            "שם_רחוב": "ברני רוברט"
        },
        {
            "_id": 6491,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1395,
            "שם_רחוב": "ברנע דב"
        },
        {
            "_id": 6492,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 268,
            "שם_רחוב": "ברנפלד שמעון"
        },
        {
            "_id": 6493,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 807,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 6494,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 822,
            "שם_רחוב": "ברנשטיין לאונרד"
        },
        {
            "_id": 6495,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 137,
            "שם_רחוב": "ברנשטיין פרץ"
        },
        {
            "_id": 6496,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 909,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 6497,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1136,
            "שם_רחוב": "ברקת ראובן"
        },
        {
            "_id": 6498,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1045,
            "שם_רחוב": "ברש אשר"
        },
        {
            "_id": 6499,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1137,
            "שם_רחוב": "בת שבע"
        },
        {
            "_id": 6500,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 184,
            "שם_רחוב": "בת שבע בן אליהו"
        },
        {
            "_id": 6501,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1404,
            "שם_רחוב": "גבולות"
        },
        {
            "_id": 6502,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 503,
            "שם_רחוב": "גבעתי"
        },
        {
            "_id": 6503,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1293,
            "שם_רחוב": "גבריהו חיים"
        },
        {
            "_id": 6504,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 431,
            "שם_רחוב": "גבתי חיים"
        },
        {
            "_id": 6505,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 910,
            "שם_רחוב": "גד"
        },
        {
            "_id": 6506,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1138,
            "שם_רחוב": "גדליהו"
        },
        {
            "_id": 6507,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 872,
            "שם_רחוב": "גוזלן"
        },
        {
            "_id": 6508,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1100,
            "שם_רחוב": "גוטמן נחום"
        },
        {
            "_id": 6509,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 569,
            "שם_רחוב": "גויטיין דוד"
        },
        {
            "_id": 6510,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 427,
            "שם_רחוב": "גולדברג לאה"
        },
        {
            "_id": 6511,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1363,
            "שם_רחוב": "גולדמן נחום"
        },
        {
            "_id": 6512,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1046,
            "שם_רחוב": "גולדשטיין פרץ"
        },
        {
            "_id": 6513,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 504,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 6514,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1047,
            "שם_רחוב": "גולי קניה"
        },
        {
            "_id": 6515,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 505,
            "שם_רחוב": "גולני"
        },
        {
            "_id": 6516,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1445,
            "שם_רחוב": "גומא"
        },
        {
            "_id": 6517,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1048,
            "שם_רחוב": "גור יהודה"
        },
        {
            "_id": 6518,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 808,
            "שם_רחוב": "גורדון א ד"
        },
        {
            "_id": 6519,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 506,
            "שם_רחוב": "גוש עציון"
        },
        {
            "_id": 6520,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 657,
            "שם_רחוב": "גיבור דניס"
        },
        {
            "_id": 6521,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1049,
            "שם_רחוב": "גיבתון חנוך"
        },
        {
            "_id": 6522,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1237,
            "שם_רחוב": "גינצבורג דוד"
        },
        {
            "_id": 6523,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 325,
            "שם_רחוב": "גירית"
        },
        {
            "_id": 6524,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1050,
            "שם_רחוב": "גלזמן יוסף"
        },
        {
            "_id": 6525,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1361,
            "שם_רחוב": "גלילי ישראל"
        },
        {
            "_id": 6526,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 568,
            "שם_רחוב": "גלנטי"
        },
        {
            "_id": 6527,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 208,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 6528,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 324,
            "שם_רחוב": "גמל"
        },
        {
            "_id": 6529,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1295,
            "שם_רחוב": "גרונר דב"
        },
        {
            "_id": 6530,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1051,
            "שם_רחוב": "גרוסמן מאיר"
        },
        {
            "_id": 6531,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1139,
            "שם_רחוב": "גרינבוים יצחק"
        },
        {
            "_id": 6532,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1346,
            "שם_רחוב": "גרינפלד"
        },
        {
            "_id": 6533,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1140,
            "שם_רחוב": "גרינשפן הרשל"
        },
        {
            "_id": 6534,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 406,
            "שם_רחוב": "גרץ"
        },
        {
            "_id": 6535,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 209,
            "שם_רחוב": "גרר"
        },
        {
            "_id": 6536,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 911,
            "שם_רחוב": "גרשון"
        },
        {
            "_id": 6537,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1251,
            "שם_רחוב": "גרשם שלום"
        },
        {
            "_id": 6538,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 841,
            "שם_רחוב": "גרשפלד"
        },
        {
            "_id": 6539,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 487,
            "שם_רחוב": "גת"
        },
        {
            "_id": 6540,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 165,
            "שם_רחוב": "דר ג'ין קלוס-פישמן"
        },
        {
            "_id": 6541,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 175,
            "שם_רחוב": "דר וילהלמינה כהן"
        },
        {
            "_id": 6542,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1161,
            "שם_רחוב": "דר טיכו"
        },
        {
            "_id": 6543,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 891,
            "שם_רחוב": "דר פליקס זנדמן"
        },
        {
            "_id": 6544,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 183,
            "שם_רחוב": "דר קריסטינה סיקורה"
        },
        {
            "_id": 6545,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 467,
            "שם_רחוב": "דב יוסף"
        },
        {
            "_id": 6546,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 912,
            "שם_רחוב": "דבורה"
        },
        {
            "_id": 6547,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1350,
            "שם_רחוב": "דביר"
        },
        {
            "_id": 6548,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1306,
            "שם_רחוב": "דה הוושי גאורג"
        },
        {
            "_id": 6549,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1141,
            "שם_רחוב": "דה-בוטון"
        },
        {
            "_id": 6550,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1235,
            "שם_רחוב": "דה-שליט עמוס"
        },
        {
            "_id": 6551,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 136,
            "שם_רחוב": "דהאן מישאל"
        },
        {
            "_id": 6552,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1372,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 6553,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 407,
            "שם_רחוב": "דובנוב"
        },
        {
            "_id": 6554,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 372,
            "שם_רחוב": "דוד אלעזר"
        },
        {
            "_id": 6555,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 210,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 6556,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1142,
            "שם_רחוב": "דוד הראובני"
        },
        {
            "_id": 6557,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1003,
            "שם_רחוב": "דודאים"
        },
        {
            "_id": 6558,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1204,
            "שם_רחוב": "דוידוביץ לב"
        },
        {
            "_id": 6559,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1317,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 6560,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1431,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 6561,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1426,
            "שם_רחוב": "דולפין"
        },
        {
            "_id": 6562,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1108,
            "שם_רחוב": "דולצ'ין אריה"
        },
        {
            "_id": 6563,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 155,
            "שם_רחוב": "דונה גרציה נשיא"
        },
        {
            "_id": 6564,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 570,
            "שם_רחוב": "דונקלבלום מנחם"
        },
        {
            "_id": 6565,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 374,
            "שם_רחוב": "דורי יעקב"
        },
        {
            "_id": 6566,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 873,
            "שם_רחוב": "דורפמן"
        },
        {
            "_id": 6567,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1351,
            "שם_רחוב": "דותן"
        },
        {
            "_id": 6568,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1053,
            "שם_רחוב": "די זהב אפרים"
        },
        {
            "_id": 6569,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1362,
            "שם_רחוב": "דידנר מנחם"
        },
        {
            "_id": 6570,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 121,
            "שם_רחוב": "דיה"
        },
        {
            "_id": 6571,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 572,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 6572,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 434,
            "שם_רחוב": "דינה"
        },
        {
            "_id": 6573,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1103,
            "שם_רחוב": "דינור בן-ציון"
        },
        {
            "_id": 6574,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1430,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 6575,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 177,
            "שם_רחוב": "דליה רביקוביץ"
        },
        {
            "_id": 6576,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 913,
            "שם_רחוב": "דן"
        },
        {
            "_id": 6577,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1378,
            "שם_רחוב": "דנציגר יצחק"
        },
        {
            "_id": 6578,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 631,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 6579,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1411,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 6580,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 448,
            "שם_רחוב": "דקלה"
        },
        {
            "_id": 6581,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1143,
            "שם_רחוב": "דקר"
        },
        {
            "_id": 6582,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 530,
            "שם_רחוב": "דר ניסתר"
        },
        {
            "_id": 6583,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 321,
            "שם_רחוב": "דרבן"
        },
        {
            "_id": 6584,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1144,
            "שם_רחוב": "דרויאנוב אברהם"
        },
        {
            "_id": 6585,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 130,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 6586,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1294,
            "שם_רחוב": "דרזנר יחיאל"
        },
        {
            "_id": 6587,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1220,
            "שם_רחוב": "דרך אביטל אביגדור"
        },
        {
            "_id": 6588,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1389,
            "שם_רחוב": "דרך אילן רמון"
        },
        {
            "_id": 6589,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 702,
            "שם_רחוב": "דרך אילת"
        },
        {
            "_id": 6590,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך אליהו נאוי"
        },
        {
            "_id": 6591,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 359,
            "שם_רחוב": "דרך ג'ו אלון"
        },
        {
            "_id": 6592,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 810,
            "שם_רחוב": "דרך המשחררים"
        },
        {
            "_id": 6593,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך חברון"
        },
        {
            "_id": 6594,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 277,
            "שם_רחוב": "דרך מצדה"
        },
        {
            "_id": 6595,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 309,
            "שם_רחוב": "דרך שמשון"
        },
        {
            "_id": 6596,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 914,
            "שם_רחוב": "האבות"
        },
        {
            "_id": 6597,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 571,
            "שם_רחוב": "האדרת"
        },
        {
            "_id": 6598,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1358,
            "שם_רחוב": "האוזנר גדעון"
        },
        {
            "_id": 6599,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 531,
            "שם_רחוב": "האורגים"
        },
        {
            "_id": 6600,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1238,
            "שם_רחוב": "האז גיאורג"
        },
        {
            "_id": 6601,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 332,
            "שם_רחוב": "האיילה"
        },
        {
            "_id": 6602,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 211,
            "שם_רחוב": "האיסיים"
        },
        {
            "_id": 6603,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 272,
            "שם_רחוב": "האמוראים"
        },
        {
            "_id": 6604,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 141,
            "שם_רחוב": "האנרגיה"
        },
        {
            "_id": 6605,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 855,
            "שם_רחוב": "הבדיל"
        },
        {
            "_id": 6606,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 865,
            "שם_רחוב": "הבונים"
        },
        {
            "_id": 6607,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 886,
            "שם_רחוב": "הברזל"
        },
        {
            "_id": 6608,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 207,
            "שם_רחוב": "הבשור"
        },
        {
            "_id": 6609,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 603,
            "שם_רחוב": "הגאולים"
        },
        {
            "_id": 6610,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1146,
            "שם_רחוב": "הגאונים"
        },
        {
            "_id": 6611,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 978,
            "שם_רחוב": "הגדודים"
        },
        {
            "_id": 6612,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 342,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 6613,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 887,
            "שם_רחוב": "הגנן"
        },
        {
            "_id": 6614,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 985,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 6615,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 916,
            "שם_רחוב": "הגר"
        },
        {
            "_id": 6616,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 852,
            "שם_רחוב": "הדגן"
        },
        {
            "_id": 6617,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 917,
            "שם_רחוב": "הדסה"
        },
        {
            "_id": 6618,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1200,
            "שם_רחוב": "הדעת"
        },
        {
            "_id": 6619,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1405,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 6620,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1319,
            "שם_רחוב": "הדרי זאב (ויניה)"
        },
        {
            "_id": 6621,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 918,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 6622,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 919,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 6623,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 408,
            "שם_רחוב": "הוברמן"
        },
        {
            "_id": 6624,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1250,
            "שם_רחוב": "הוגו שמואל"
        },
        {
            "_id": 6625,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 662,
            "שם_רחוב": "הוכברג נתנאל"
        },
        {
            "_id": 6626,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 500,
            "שם_רחוב": "הולצברג שמחה"
        },
        {
            "_id": 6627,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 920,
            "שם_רחוב": "הושע"
        },
        {
            "_id": 6628,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 274,
            "שם_רחוב": "הזוהר"
        },
        {
            "_id": 6629,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 888,
            "שם_רחוב": "הזורע"
        },
        {
            "_id": 6630,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 421,
            "שם_רחוב": "הזז חיים"
        },
        {
            "_id": 6631,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 144,
            "שם_רחוב": "החדשנות"
        },
        {
            "_id": 6632,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 889,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 6633,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 367,
            "שם_רחוב": "החידא"
        },
        {
            "_id": 6634,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 921,
            "שם_רחוב": "החלוץ"
        },
        {
            "_id": 6635,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 863,
            "שם_רחוב": "החרט"
        },
        {
            "_id": 6636,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1147,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 6637,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 853,
            "שם_רחוב": "החשמלאי"
        },
        {
            "_id": 6638,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 869,
            "שם_רחוב": "הטחנה"
        },
        {
            "_id": 6639,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 858,
            "שם_רחוב": "היוצרים"
        },
        {
            "_id": 6640,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1246,
            "שם_רחוב": "היזה פאול"
        },
        {
            "_id": 6641,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1148,
            "שם_רחוב": "היילפרין ליפמן"
        },
        {
            "_id": 6642,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 604,
            "שם_רחוב": "הכג"
        },
        {
            "_id": 6643,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 409,
            "שם_רחוב": "הכוזרי"
        },
        {
            "_id": 6644,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 809,
            "שם_רחוב": "הכותל המערבי"
        },
        {
            "_id": 6645,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1259,
            "שם_רחוב": "הכט ראובן"
        },
        {
            "_id": 6646,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 251,
            "שם_רחוב": "הכנסת"
        },
        {
            "_id": 6647,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 615,
            "שם_רחוב": "הכשרת הישוב"
        },
        {
            "_id": 6648,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 959,
            "שם_רחוב": "הלה"
        },
        {
            "_id": 6649,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 247,
            "שם_רחוב": "הלל הזקן"
        },
        {
            "_id": 6650,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 989,
            "שם_רחוב": "הלמן אברהם"
        },
        {
            "_id": 6651,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 605,
            "שם_רחוב": "הלפרין"
        },
        {
            "_id": 6652,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 573,
            "שם_רחוב": "המאירי מנחם"
        },
        {
            "_id": 6653,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 574,
            "שם_רחוב": "המביט"
        },
        {
            "_id": 6654,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1240,
            "שם_רחוב": "המדע"
        },
        {
            "_id": 6655,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 575,
            "שם_רחוב": "המהרל"
        },
        {
            "_id": 6656,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 981,
            "שם_רחוב": "המוכתר"
        },
        {
            "_id": 6657,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 831,
            "שם_רחוב": "המוסכים"
        },
        {
            "_id": 6658,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 437,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 6659,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 922,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 6660,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 864,
            "שם_רחוב": "המכונאי"
        },
        {
            "_id": 6661,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 212,
            "שם_רחוב": "המכתשים"
        },
        {
            "_id": 6662,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 833,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 6663,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1149,
            "שם_רחוב": "המלכים"
        },
        {
            "_id": 6664,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 859,
            "שם_רחוב": "המנוף"
        },
        {
            "_id": 6665,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 862,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 6666,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 990,
            "שם_רחוב": "המסילה"
        },
        {
            "_id": 6667,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 606,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 6668,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1337,
            "שם_רחוב": "המר זבולון"
        },
        {
            "_id": 6669,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1296,
            "שם_רחוב": "המרד"
        },
        {
            "_id": 6670,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1409,
            "שם_רחוב": "המשביר"
        },
        {
            "_id": 6671,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 866,
            "שם_רחוב": "המשק"
        },
        {
            "_id": 6672,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 215,
            "שם_רחוב": "הנבטים"
        },
        {
            "_id": 6673,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1367,
            "שם_רחוב": "הנגבי"
        },
        {
            "_id": 6674,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 835,
            "שם_רחוב": "הנגרים"
        },
        {
            "_id": 6675,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 525,
            "שם_רחוב": "הנדיב"
        },
        {
            "_id": 6676,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 507,
            "שם_רחוב": "הנוטר"
        },
        {
            "_id": 6677,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 958,
            "שם_רחוב": "הנוקם"
        },
        {
            "_id": 6678,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 854,
            "שם_רחוב": "הנחושת"
        },
        {
            "_id": 6679,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 890,
            "שם_רחוב": "הנפח"
        },
        {
            "_id": 6680,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 812,
            "שם_רחוב": "הס משה"
        },
        {
            "_id": 6681,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 410,
            "שם_רחוב": "הסופרים"
        },
        {
            "_id": 6682,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1016,
            "שם_רחוב": "העל"
        },
        {
            "_id": 6683,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "העיר העתיקה"
        },
        {
            "_id": 6684,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 304,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 6685,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 953,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 6686,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 860,
            "שם_רחוב": "הפועלים"
        },
        {
            "_id": 6687,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 861,
            "שם_רחוב": "הפחח"
        },
        {
            "_id": 6688,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 857,
            "שם_רחוב": "הפלדה"
        },
        {
            "_id": 6689,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 923,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 6690,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 856,
            "שם_רחוב": "הפלס"
        },
        {
            "_id": 6691,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1055,
            "שם_רחוב": "הפרטיזנים"
        },
        {
            "_id": 6692,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 331,
            "שם_רחוב": "הצבי"
        },
        {
            "_id": 6693,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 281,
            "שם_רחוב": "הצדיק מירושלים"
        },
        {
            "_id": 6694,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 614,
            "שם_רחוב": "הציונות"
        },
        {
            "_id": 6695,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1057,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 6696,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 252,
            "שם_רחוב": "הקנאים"
        },
        {
            "_id": 6697,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "הקריה עש א ברגמן"
        },
        {
            "_id": 6698,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1282,
            "שם_רחוב": "הר בוקר"
        },
        {
            "_id": 6699,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1283,
            "שם_רחוב": "הר בקע"
        },
        {
            "_id": 6700,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1278,
            "שם_רחוב": "הר חרמון"
        },
        {
            "_id": 6701,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1280,
            "שם_רחוב": "הר כנען"
        },
        {
            "_id": 6702,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1285,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 6703,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1281,
            "שם_רחוב": "הר נס"
        },
        {
            "_id": 6704,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1286,
            "שם_רחוב": "הר עודד"
        },
        {
            "_id": 6705,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1277,
            "שם_רחוב": "הר עצמון"
        },
        {
            "_id": 6706,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1276,
            "שם_רחוב": "הר רמון"
        },
        {
            "_id": 6707,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1284,
            "שם_רחוב": "הר תבור"
        },
        {
            "_id": 6708,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 577,
            "שם_רחוב": "הרן"
        },
        {
            "_id": 6709,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 349,
            "שם_רחוב": "הראש"
        },
        {
            "_id": 6710,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 557,
            "שם_רחוב": "הרב אונטרמן"
        },
        {
            "_id": 6711,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 899,
            "שם_רחוב": "הרב אלעזר קליין"
        },
        {
            "_id": 6712,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 269,
            "שם_רחוב": "הרב אסף שמחה"
        },
        {
            "_id": 6713,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 255,
            "שם_רחוב": "הרב גבאי מנחם"
        },
        {
            "_id": 6714,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1375,
            "שם_רחוב": "הרב גורן שלמה"
        },
        {
            "_id": 6715,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 473,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 6716,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1156,
            "שם_רחוב": "הרב חביב חיים"
        },
        {
            "_id": 6717,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 370,
            "שם_רחוב": "הרב חורי חיים"
        },
        {
            "_id": 6718,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הרב טייב חי"
        },
        {
            "_id": 6719,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 435,
            "שם_רחוב": "הרב טנא שלמה"
        },
        {
            "_id": 6720,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 138,
            "שם_רחוב": "הרב כץ אליהו"
        },
        {
            "_id": 6721,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 236,
            "שם_רחוב": "הרב מימון"
        },
        {
            "_id": 6722,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 490,
            "שם_רחוב": "הרב מרקדו"
        },
        {
            "_id": 6723,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 232,
            "שם_רחוב": "הרב סוסו יוסף"
        },
        {
            "_id": 6724,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 264,
            "שם_רחוב": "הרב סילברט"
        },
        {
            "_id": 6725,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1176,
            "שם_רחוב": "הרב עדני"
        },
        {
            "_id": 6726,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 378,
            "שם_רחוב": "הרב פרדס"
        },
        {
            "_id": 6727,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 598,
            "שם_רחוב": "הרב פרנק"
        },
        {
            "_id": 6728,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1189,
            "שם_רחוב": "הרב קפאח"
        },
        {
            "_id": 6729,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1400,
            "שם_רחוב": "הרב ריינס"
        },
        {
            "_id": 6730,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 653,
            "שם_רחוב": "הרב רצאבי"
        },
        {
            "_id": 6731,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1391,
            "שם_רחוב": "הרב שושנה"
        },
        {
            "_id": 6732,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 924,
            "שם_רחוב": "הרבבה"
        },
        {
            "_id": 6733,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 840,
            "שם_רחוב": "הרבי מלובביטש"
        },
        {
            "_id": 6734,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 576,
            "שם_רחוב": "הרדבז"
        },
        {
            "_id": 6735,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1022,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 6736,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1324,
            "שם_רחוב": "הרמלין יוסף"
        },
        {
            "_id": 6737,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 925,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 6738,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 826,
            "שם_רחוב": "הרצפלד אברהם"
        },
        {
            "_id": 6739,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 892,
            "שם_רחוב": "הרתך"
        },
        {
            "_id": 6740,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 926,
            "שם_רחוב": "השבטים"
        },
        {
            "_id": 6741,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 983,
            "שם_רחוב": "השווקים"
        },
        {
            "_id": 6742,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 813,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 6743,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1388,
            "שם_רחוב": "השופט אלקיים"
        },
        {
            "_id": 6744,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1352,
            "שם_רחוב": "השופטים"
        },
        {
            "_id": 6745,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 508,
            "שם_רחוב": "השלום"
        },
        {
            "_id": 6746,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 140,
            "שם_רחוב": "התבונה"
        },
        {
            "_id": 6747,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1135,
            "שם_רחוב": "התורה הגואלת"
        },
        {
            "_id": 6748,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1150,
            "שם_רחוב": "התיבונים"
        },
        {
            "_id": 6749,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 271,
            "שם_רחוב": "התלמוד"
        },
        {
            "_id": 6750,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 273,
            "שם_רחוב": "התנאים"
        },
        {
            "_id": 6751,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 828,
            "שם_רחוב": "התקוה"
        },
        {
            "_id": 6752,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 838,
            "שם_רחוב": "וולפסון דוד"
        },
        {
            "_id": 6753,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1058,
            "שם_רחוב": "ויטנברג יצחק"
        },
        {
            "_id": 6754,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1297,
            "שם_רחוב": "וייס יעקב"
        },
        {
            "_id": 6755,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1298,
            "שם_רחוב": "וילנאי זאב"
        },
        {
            "_id": 6756,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1377,
            "שם_רחוב": "וילנסקי משה"
        },
        {
            "_id": 6757,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1059,
            "שם_רחוב": "וילנר אריה"
        },
        {
            "_id": 6758,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 509,
            "שם_רחוב": "וינגייט"
        },
        {
            "_id": 6759,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1151,
            "שם_רחוב": "ויניק זאב"
        },
        {
            "_id": 6760,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 814,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 6761,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1215,
            "שם_רחוב": "וירשובסקי חיים"
        },
        {
            "_id": 6762,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 578,
            "שם_רחוב": "ויתקון אלפרד"
        },
        {
            "_id": 6763,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1354,
            "שם_רחוב": "ולבר יצחק"
        },
        {
            "_id": 6764,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 874,
            "שם_רחוב": "ונטורה"
        },
        {
            "_id": 6765,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1223,
            "שם_רחוב": "וקסמן זלמן"
        },
        {
            "_id": 6766,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 815,
            "שם_רחוב": "ורבורג"
        },
        {
            "_id": 6767,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1021,
            "שם_רחוב": "ורד"
        },
        {
            "_id": 6768,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 310,
            "שם_רחוב": "ורדימון יצחק"
        },
        {
            "_id": 6769,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1224,
            "שם_רחוב": "ורטהיימר חיים"
        },
        {
            "_id": 6770,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 607,
            "שם_רחוב": "ז'בוטינסקי זאב"
        },
        {
            "_id": 6771,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 194,
            "שם_רחוב": "ז'קלין כהנוב"
        },
        {
            "_id": 6772,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 927,
            "שם_רחוב": "זבולון"
        },
        {
            "_id": 6773,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1213,
            "שם_רחוב": "זהרי מיכאל"
        },
        {
            "_id": 6774,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 168,
            "שם_רחוב": "זוהרה אלפסיה"
        },
        {
            "_id": 6775,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 579,
            "שם_רחוב": "זוסמן יואל"
        },
        {
            "_id": 6776,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 580,
            "שם_רחוב": "זילברג משה"
        },
        {
            "_id": 6777,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1060,
            "שם_רחוב": "זינדר חמדה וצבי"
        },
        {
            "_id": 6778,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 510,
            "שם_רחוב": "זיסו"
        },
        {
            "_id": 6779,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1044,
            "שם_רחוב": "זכות משה"
        },
        {
            "_id": 6780,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 928,
            "שם_רחוב": "זכריה"
        },
        {
            "_id": 6781,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 668,
            "שם_רחוב": "זלמן כספי"
        },
        {
            "_id": 6782,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1152,
            "שם_רחוב": "זלפה"
        },
        {
            "_id": 6783,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 581,
            "שם_רחוב": "זמורה משה"
        },
        {
            "_id": 6784,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1260,
            "שם_רחוב": "זמיר ישעיהו"
        },
        {
            "_id": 6785,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1153,
            "שם_רחוב": "זמנהוף"
        },
        {
            "_id": 6786,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1154,
            "שם_רחוב": "זנגויל ישראל"
        },
        {
            "_id": 6787,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1216,
            "שם_רחוב": "זקש נלי"
        },
        {
            "_id": 6788,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1155,
            "שם_רחוב": "זרובבל"
        },
        {
            "_id": 6789,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 318,
            "שם_רחוב": "זרזיר"
        },
        {
            "_id": 6790,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1239,
            "שם_רחוב": "זרחין אלכסנדר"
        },
        {
            "_id": 6791,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1325,
            "שם_רחוב": "זריצקי יוסף"
        },
        {
            "_id": 6792,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 658,
            "שם_רחוב": "חן"
        },
        {
            "_id": 6793,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1299,
            "שם_רחוב": "חביב אבשלום"
        },
        {
            "_id": 6794,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 582,
            "שם_רחוב": "חבס ברכה"
        },
        {
            "_id": 6795,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1005,
            "שם_רחוב": "חבצלת הנגב"
        },
        {
            "_id": 6796,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 929,
            "שם_רחוב": "חבקוק"
        },
        {
            "_id": 6797,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 930,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 6798,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 317,
            "שם_רחוב": "חגלה"
        },
        {
            "_id": 6799,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1061,
            "שם_רחוב": "חובב משה"
        },
        {
            "_id": 6800,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1157,
            "שם_רחוב": "חוה"
        },
        {
            "_id": 6801,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 125,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 6802,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 511,
            "שם_רחוב": "חומה ומגדל"
        },
        {
            "_id": 6803,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1424,
            "שם_רחוב": "חופית"
        },
        {
            "_id": 6804,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 411,
            "שם_רחוב": "חזל"
        },
        {
            "_id": 6805,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1062,
            "שם_רחוב": "חזמה יעקב"
        },
        {
            "_id": 6806,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1308,
            "שם_רחוב": "חזן יעקב"
        },
        {
            "_id": 6807,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 470,
            "שם_רחוב": "חזני מיכאל"
        },
        {
            "_id": 6808,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 358,
            "שם_רחוב": "חזקיהו"
        },
        {
            "_id": 6809,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1063,
            "שם_רחוב": "חטיבה שמונה"
        },
        {
            "_id": 6810,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 931,
            "שם_רחוב": "חטיבת הנגב"
        },
        {
            "_id": 6811,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 526,
            "שם_רחוב": "חיבת ציון"
        },
        {
            "_id": 6812,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 665,
            "שם_רחוב": "חיים סנה"
        },
        {
            "_id": 6813,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 111,
            "שם_רחוב": "חיים רביב"
        },
        {
            "_id": 6814,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1300,
            "שם_רחוב": "חכים אליהו"
        },
        {
            "_id": 6815,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1364,
            "שם_רחוב": "חלילי"
        },
        {
            "_id": 6816,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 142,
            "שם_רחוב": "חלקיקי האור"
        },
        {
            "_id": 6817,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1330,
            "שם_רחוב": "חמיאל יצחק"
        },
        {
            "_id": 6818,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1232,
            "שם_רחוב": "חן ארנסט"
        },
        {
            "_id": 6819,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1158,
            "שם_רחוב": "חנה"
        },
        {
            "_id": 6820,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 176,
            "שם_רחוב": "חנה אבנור"
        },
        {
            "_id": 6821,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 932,
            "שם_רחוב": "חנני"
        },
        {
            "_id": 6822,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 522,
            "שם_רחוב": "חנני חיים"
        },
        {
            "_id": 6823,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 870,
            "שם_רחוב": "חנקין"
        },
        {
            "_id": 6824,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 129,
            "שם_רחוב": "חסידה"
        },
        {
            "_id": 6825,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1403,
            "שם_רחוב": "חסמן מרק"
        },
        {
            "_id": 6826,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1006,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 6827,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 384,
            "שם_רחוב": "חצור"
        },
        {
            "_id": 6828,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 327,
            "שם_רחוב": "חרדון"
        },
        {
            "_id": 6829,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1397,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 6830,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1025,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 6831,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 583,
            "שם_רחוב": "חשין"
        },
        {
            "_id": 6832,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 368,
            "שם_רחוב": "חתם סופר"
        },
        {
            "_id": 6833,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 875,
            "שם_רחוב": "טאוב"
        },
        {
            "_id": 6834,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1159,
            "שם_רחוב": "טביוב"
        },
        {
            "_id": 6835,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 340,
            "שם_רחוב": "טבנקין"
        },
        {
            "_id": 6836,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 389,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 6837,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1347,
            "שם_רחוב": "טדסקי גד"
        },
        {
            "_id": 6838,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1160,
            "שם_רחוב": "טוב משה"
        },
        {
            "_id": 6839,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 180,
            "שם_רחוב": "טובה ברץ"
        },
        {
            "_id": 6840,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 173,
            "שם_רחוב": "טובה סנהדראי"
        },
        {
            "_id": 6841,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1310,
            "שם_רחוב": "טווס"
        },
        {
            "_id": 6842,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 659,
            "שם_רחוב": "טולידנו"
        },
        {
            "_id": 6843,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1245,
            "שם_רחוב": "טור סיני נפתלי"
        },
        {
            "_id": 6844,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1064,
            "שם_רחוב": "טיומקין זאב"
        },
        {
            "_id": 6845,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1092,
            "שם_רחוב": "טל חרמון"
        },
        {
            "_id": 6846,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 584,
            "שם_רחוב": "טלמון יעקב"
        },
        {
            "_id": 6847,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1065,
            "שם_רחוב": "טננבוים מרדכי"
        },
        {
            "_id": 6848,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 164,
            "שם_רחוב": "טרודי קוזלובסקי"
        },
        {
            "_id": 6849,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 933,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 6850,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1342,
            "שם_רחוב": "טרק גבריאל"
        },
        {
            "_id": 6851,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 412,
            "שם_רחוב": "טשרניחובסקי"
        },
        {
            "_id": 6852,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 436,
            "שם_רחוב": "יא' הספורטאים"
        },
        {
            "_id": 6853,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 851,
            "שם_רחוב": "יאיר"
        },
        {
            "_id": 6854,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 299,
            "שם_רחוב": "יאשיהו"
        },
        {
            "_id": 6855,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 608,
            "שם_רחוב": "יד ושם"
        },
        {
            "_id": 6856,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 354,
            "שם_רחוב": "יהוא"
        },
        {
            "_id": 6857,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 376,
            "שם_רחוב": "יהואש"
        },
        {
            "_id": 6858,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 263,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 6859,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 843,
            "שם_רחוב": "יהודה הנחתום"
        },
        {
            "_id": 6860,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 250,
            "שם_רחוב": "יהודה הנשיא"
        },
        {
            "_id": 6861,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 352,
            "שם_רחוב": "יהודי סוריה"
        },
        {
            "_id": 6862,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 357,
            "שם_רחוב": "יהויכין"
        },
        {
            "_id": 6863,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 297,
            "שם_רחוב": "יהורם"
        },
        {
            "_id": 6864,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 846,
            "שם_רחוב": "יהושע הצורף"
        },
        {
            "_id": 6865,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 371,
            "שם_רחוב": "יהושפט"
        },
        {
            "_id": 6866,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 218,
            "שם_רחוב": "יואל השופט"
        },
        {
            "_id": 6867,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1066,
            "שם_רחוב": "יוהנה ז'בוטינסקי"
        },
        {
            "_id": 6868,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 242,
            "שם_רחוב": "יוחנן בן זכאי"
        },
        {
            "_id": 6869,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 219,
            "שם_רחוב": "יוחנן הורקנוס"
        },
        {
            "_id": 6870,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 585,
            "שם_רחוב": "יוכבד בת מרים"
        },
        {
            "_id": 6871,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 935,
            "שם_רחוב": "יונה הנביא"
        },
        {
            "_id": 6872,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 844,
            "שם_רחוב": "יוסי הבורסקאי"
        },
        {
            "_id": 6873,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 936,
            "שם_רחוב": "יוסף"
        },
        {
            "_id": 6874,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 276,
            "שם_רחוב": "יוסף בן מתתיהו"
        },
        {
            "_id": 6875,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 915,
            "שם_רחוב": "יוסף ומאיר"
        },
        {
            "_id": 6876,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 225,
            "שם_רחוב": "יוספטל גיורא"
        },
        {
            "_id": 6877,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1366,
            "שם_רחוב": "יוסקה"
        },
        {
            "_id": 6878,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 937,
            "שם_רחוב": "יחזקאל"
        },
        {
            "_id": 6879,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 885,
            "שם_רחוב": "יחיל חיים"
        },
        {
            "_id": 6880,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 220,
            "שם_רחוב": "יטבתה"
        },
        {
            "_id": 6881,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1067,
            "שם_רחוב": "ייבין יהושע"
        },
        {
            "_id": 6882,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1068,
            "שם_רחוב": "ילג"
        },
        {
            "_id": 6883,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1249,
            "שם_רחוב": "ילין דוד"
        },
        {
            "_id": 6884,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1316,
            "שם_רחוב": "ילין-מור"
        },
        {
            "_id": 6885,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 586,
            "שם_רחוב": "ילן-שטקליס מרים"
        },
        {
            "_id": 6886,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1326,
            "שם_רחוב": "ינקו מרסל"
        },
        {
            "_id": 6887,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 124,
            "שם_רחוב": "ינשוף"
        },
        {
            "_id": 6888,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1018,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 6889,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 128,
            "שם_רחוב": "יסעור"
        },
        {
            "_id": 6890,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 816,
            "שם_רחוב": "יסקי"
        },
        {
            "_id": 6891,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 237,
            "שם_רחוב": "יעבץ יעקב"
        },
        {
            "_id": 6892,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1318,
            "שם_רחוב": "יעקב (בודה) עורב"
        },
        {
            "_id": 6893,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 221,
            "שם_רחוב": "יעקב אבינו"
        },
        {
            "_id": 6894,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 439,
            "שם_רחוב": "יעקב עורבי"
        },
        {
            "_id": 6895,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1444,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 6896,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1102,
            "שם_רחוב": "יערי מאיר"
        },
        {
            "_id": 6897,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1338,
            "שם_רחוב": "יפה אברהם"
        },
        {
            "_id": 6898,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 170,
            "שם_רחוב": "יפה ירקוני"
        },
        {
            "_id": 6899,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 842,
            "שם_רחוב": "יפה לייב"
        },
        {
            "_id": 6900,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 977,
            "שם_רחוב": "יפתח הגלעדי"
        },
        {
            "_id": 6901,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 222,
            "שם_רחוב": "יצחק אבינו"
        },
        {
            "_id": 6902,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 847,
            "שם_רחוב": "יצחק נפחא"
        },
        {
            "_id": 6903,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 301,
            "שם_רחוב": "יציאת אירופה"
        },
        {
            "_id": 6904,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 333,
            "שם_רחוב": "ירבוע"
        },
        {
            "_id": 6905,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1422,
            "שם_רחוב": "ירדן"
        },
        {
            "_id": 6906,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 382,
            "שם_רחוב": "יריחו"
        },
        {
            "_id": 6907,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 934,
            "שם_רחוב": "ירמיהו"
        },
        {
            "_id": 6908,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 587,
            "שם_רחוב": "ירקוני עמוס"
        },
        {
            "_id": 6909,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 588,
            "שם_רחוב": "ישא ברכה"
        },
        {
            "_id": 6910,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 938,
            "שם_רחוב": "ישמעאל"
        },
        {
            "_id": 6911,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 939,
            "שם_רחוב": "ישעיהו הנביא"
        },
        {
            "_id": 6912,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 491,
            "שם_רחוב": "ישעיהו ישראל"
        },
        {
            "_id": 6913,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ישראל ריבק"
        },
        {
            "_id": 6914,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 940,
            "שם_רחוב": "יששכר"
        },
        {
            "_id": 6915,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 613,
            "שם_רחוב": "כהן אלי"
        },
        {
            "_id": 6916,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 422,
            "שם_רחוב": "כהן יעקב"
        },
        {
            "_id": 6917,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 896,
            "שם_רחוב": "כהן לאונרד"
        },
        {
            "_id": 6918,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 351,
            "שם_רחוב": "כחול לבן"
        },
        {
            "_id": 6919,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6026,
            "שם_רחוב": "כיכר חנניה עמר"
        },
        {
            "_id": 6920,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6016,
            "שם_רחוב": "כיכר שפרן אלכסנדר"
        },
        {
            "_id": 6921,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר אביר"
        },
        {
            "_id": 6922,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6022,
            "שם_רחוב": "ככר אדיס אבבה"
        },
        {
            "_id": 6923,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6019,
            "שם_רחוב": "ככר איפרגן"
        },
        {
            "_id": 6924,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6024,
            "שם_רחוב": "ככר אקלום פרדה יזזא"
        },
        {
            "_id": 6925,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6000,
            "שם_רחוב": "ככר ארגוני נשים"
        },
        {
            "_id": 6926,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר בני שמעון"
        },
        {
            "_id": 6927,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6012,
            "שם_רחוב": "ככר הבונים החופשים"
        },
        {
            "_id": 6928,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר הברית"
        },
        {
            "_id": 6929,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר הגנים"
        },
        {
            "_id": 6930,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6017,
            "שם_רחוב": "ככר המתנדבים"
        },
        {
            "_id": 6931,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר הקיני"
        },
        {
            "_id": 6932,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "ככר הקניזי"
        },
        {
            "_id": 6933,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6015,
            "שם_רחוב": "ככר התאומים"
        },
        {
            "_id": 6934,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6013,
            "שם_רחוב": "ככר וופרטל"
        },
        {
            "_id": 6935,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "ככר זיסו"
        },
        {
            "_id": 6936,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6014,
            "שם_רחוב": "ככר ליון"
        },
        {
            "_id": 6937,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6018,
            "שם_רחוב": "ככר מונטריאול"
        },
        {
            "_id": 6938,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "ככר מעון"
        },
        {
            "_id": 6939,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6025,
            "שם_רחוב": "ככר סגל שרגא"
        },
        {
            "_id": 6940,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6023,
            "שם_רחוב": "ככר סימון"
        },
        {
            "_id": 6941,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6021,
            "שם_רחוב": "ככר פרידמן"
        },
        {
            "_id": 6942,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6009,
            "שם_רחוב": "ככר קפלן"
        },
        {
            "_id": 6943,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6011,
            "שם_רחוב": "ככר רוטרי"
        },
        {
            "_id": 6944,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6010,
            "שם_רחוב": "ככר רחבים"
        },
        {
            "_id": 6945,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1019,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 6946,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 971,
            "שם_רחוב": "כפר הדרום"
        },
        {
            "_id": 6947,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6560,
            "שם_רחוב": "כפר רפאל"
        },
        {
            "_id": 6948,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 817,
            "שם_רחוב": "כצנלסון"
        },
        {
            "_id": 6949,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1443,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 6950,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 512,
            "שם_רחוב": "כרמלי"
        },
        {
            "_id": 6951,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1162,
            "שם_רחוב": "לאה"
        },
        {
            "_id": 6952,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 700,
            "שם_רחוב": "לאה רבין"
        },
        {
            "_id": 6953,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1413,
            "שם_רחוב": "לאון אדרי"
        },
        {
            "_id": 6954,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1333,
            "שם_רחוב": "לב משה"
        },
        {
            "_id": 6955,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1340,
            "שם_רחוב": "לבון פנחס"
        },
        {
            "_id": 6956,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1069,
            "שם_רחוב": "לברי מרק"
        },
        {
            "_id": 6957,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 897,
            "שם_רחוב": "להב"
        },
        {
            "_id": 6958,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1163,
            "שם_רחוב": "לוזון אפרים"
        },
        {
            "_id": 6959,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 827,
            "שם_רחוב": "לוין שמריהו"
        },
        {
            "_id": 6960,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1301,
            "שם_רחוב": "לויצקי יעקב"
        },
        {
            "_id": 6961,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1339,
            "שם_רחוב": "לוריא צבי"
        },
        {
            "_id": 6962,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1419,
            "שם_רחוב": "ליבנה"
        },
        {
            "_id": 6963,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1257,
            "שם_רחוב": "ליליאן"
        },
        {
            "_id": 6964,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1023,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 6965,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1398,
            "שם_רחוב": "לימון"
        },
        {
            "_id": 6966,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 635,
            "שם_רחוב": "לימונית"
        },
        {
            "_id": 6967,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1212,
            "שם_רחוב": "לינדנר יוחנן"
        },
        {
            "_id": 6968,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1209,
            "שם_רחוב": "ליפמן גבריאל"
        },
        {
            "_id": 6969,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1164,
            "שם_רחוב": "לישנסקי יוסף"
        },
        {
            "_id": 6970,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 383,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 6971,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 423,
            "שם_רחוב": "למדן יצחק"
        },
        {
            "_id": 6972,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1105,
            "שם_רחוב": "לנדאו חיים"
        },
        {
            "_id": 6973,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1165,
            "שם_רחוב": "לסקר פרץ"
        },
        {
            "_id": 6974,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 120,
            "שם_רחוב": "לרון אפרים"
        },
        {
            "_id": 6975,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1070,
            "שם_רחוב": "מאפו אברהם"
        },
        {
            "_id": 6976,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 162,
            "שם_רחוב": "מארי קירי"
        },
        {
            "_id": 6977,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 960,
            "שם_רחוב": "מבצע אסף"
        },
        {
            "_id": 6978,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 280,
            "שם_רחוב": "מבצע דני"
        },
        {
            "_id": 6979,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 293,
            "שם_רחוב": "מבצע חורב"
        },
        {
            "_id": 6980,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 292,
            "שם_רחוב": "מבצע חירם"
        },
        {
            "_id": 6981,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 285,
            "שם_רחוב": "מבצע יואב"
        },
        {
            "_id": 6982,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 282,
            "שם_רחוב": "מבצע לוט"
        },
        {
            "_id": 6983,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1071,
            "שם_רחוב": "מבצע משה"
        },
        {
            "_id": 6984,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 283,
            "שם_רחוב": "מבצע נחשון"
        },
        {
            "_id": 6985,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 284,
            "שם_רחוב": "מבצע עובדה"
        },
        {
            "_id": 6986,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 388,
            "שם_רחוב": "מגידו"
        },
        {
            "_id": 6987,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 295,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 6988,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1329,
            "שם_רחוב": "מודעי יצחק"
        },
        {
            "_id": 6989,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1402,
            "שם_רחוב": "מוטה גור"
        },
        {
            "_id": 6990,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1166,
            "שם_רחוב": "מויאל אליהו"
        },
        {
            "_id": 6991,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1167,
            "שם_רחוב": "מולכו שלמה"
        },
        {
            "_id": 6992,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 818,
            "שם_רחוב": "מונטיפיורי"
        },
        {
            "_id": 6993,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 385,
            "שם_רחוב": "מוצא"
        },
        {
            "_id": 6994,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 941,
            "שם_רחוב": "מורדי הגטאות"
        },
        {
            "_id": 6995,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מורטון מנדל"
        },
        {
            "_id": 6996,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 849,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 6997,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 589,
            "שם_רחוב": "מזוז מצליח"
        },
        {
            "_id": 6998,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1380,
            "שם_רחוב": "מזר בנימין"
        },
        {
            "_id": 6999,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 876,
            "שם_רחוב": "מזרחי"
        },
        {
            "_id": 7000,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1052,
            "שם_רחוב": "מי מרום"
        },
        {
            "_id": 7001,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1217,
            "שם_רחוב": "מיכאלסון יצחק"
        },
        {
            "_id": 7002,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 942,
            "שם_רחוב": "מיכה"
        },
        {
            "_id": 7003,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1168,
            "שם_רחוב": "מיכל"
        },
        {
            "_id": 7004,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 429,
            "שם_רחוב": "מינץ"
        },
        {
            "_id": 7005,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 379,
            "שם_רחוב": "מכללת קיי"
        },
        {
            "_id": 7006,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 943,
            "שם_רחוב": "מלאכי"
        },
        {
            "_id": 7007,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 961,
            "שם_רחוב": "מלון ערבה"
        },
        {
            "_id": 7008,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 839,
            "שם_רחוב": "מלון פרדייז"
        },
        {
            "_id": 7009,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 893,
            "שם_רחוב": "מלל סעדיה"
        },
        {
            "_id": 7010,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 478,
            "שם_רחוב": "ממשית"
        },
        {
            "_id": 7011,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 819,
            "שם_רחוב": "מנדלי מוכר ספרים"
        },
        {
            "_id": 7012,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 413,
            "שם_רחוב": "מנדלסון"
        },
        {
            "_id": 7013,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 537,
            "שם_רחוב": "מנצ'ל פועה דר"
        },
        {
            "_id": 7014,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 944,
            "שם_רחוב": "מנשה"
        },
        {
            "_id": 7015,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 590,
            "שם_רחוב": "מסקין אהרון"
        },
        {
            "_id": 7016,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 300,
            "שם_רחוב": "מעון עולים"
        },
        {
            "_id": 7017,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1302,
            "שם_רחוב": "מעלה החטיבה"
        },
        {
            "_id": 7018,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 337,
            "שם_רחוב": "מעלה עקרבים"
        },
        {
            "_id": 7019,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1083,
            "שם_רחוב": "מקור חיים"
        },
        {
            "_id": 7020,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 377,
            "שם_רחוב": "מקלף מרדכי"
        },
        {
            "_id": 7021,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 302,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 7022,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 877,
            "שם_רחוב": "מרגולין"
        },
        {
            "_id": 7023,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 167,
            "שם_רחוב": "מרגיט להמן"
        },
        {
            "_id": 7024,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1328,
            "שם_רחוב": "מרגלית מאיר"
        },
        {
            "_id": 7025,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 632,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 7026,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 223,
            "שם_רחוב": "מרטון יחזקאל"
        },
        {
            "_id": 7027,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1034,
            "שם_רחוב": "מרידור"
        },
        {
            "_id": 7028,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 189,
            "שם_רחוב": "מרים בן פורת"
        },
        {
            "_id": 7029,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1169,
            "שם_רחוב": "מרים הנביאה"
        },
        {
            "_id": 7030,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 609,
            "שם_רחוב": "מרכוס"
        },
        {
            "_id": 7031,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6605,
            "שם_רחוב": "מרכז אזרחי"
        },
        {
            "_id": 7032,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1470,
            "שם_רחוב": "מרכז ביג"
        },
        {
            "_id": 7033,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 426,
            "שם_רחוב": "מרכז הנגב"
        },
        {
            "_id": 7034,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 992,
            "שם_רחוב": "מרכז קליטה א"
        },
        {
            "_id": 7035,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 400,
            "שם_רחוב": "מרכז קליטה ג"
        },
        {
            "_id": 7036,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 536,
            "שם_רחוב": "מרכז קליטה חרוב"
        },
        {
            "_id": 7037,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 330,
            "שם_רחוב": "מרכז קליטה יעלים"
        },
        {
            "_id": 7038,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 535,
            "שם_רחוב": "מרכז קליטה קלישר"
        },
        {
            "_id": 7039,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1466,
            "שם_רחוב": "מרכז רפואי סורוקה"
        },
        {
            "_id": 7040,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1170,
            "שם_רחוב": "מרלא"
        },
        {
            "_id": 7041,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 945,
            "שם_רחוב": "משה אדר"
        },
        {
            "_id": 7042,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1241,
            "שם_רחוב": "משה יצחק"
        },
        {
            "_id": 7043,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1271,
            "שם_רחוב": "משה לוי"
        },
        {
            "_id": 7044,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 287,
            "שם_רחוב": "משעול ביתר"
        },
        {
            "_id": 7045,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 452,
            "שם_רחוב": "משעול גבעון"
        },
        {
            "_id": 7046,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 954,
            "שם_רחוב": "משעול הערבה"
        },
        {
            "_id": 7047,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 481,
            "שם_רחוב": "משעול חפר"
        },
        {
            "_id": 7048,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1208,
            "שם_רחוב": "משעול טם איגור"
        },
        {
            "_id": 7049,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 294,
            "שם_רחוב": "משעול יודפת"
        },
        {
            "_id": 7050,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 951,
            "שם_רחוב": "משעול לוי"
        },
        {
            "_id": 7051,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 399,
            "שם_רחוב": "משעול מבצע יונתן"
        },
        {
            "_id": 7052,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 451,
            "שם_רחוב": "משעול מצפה"
        },
        {
            "_id": 7053,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 336,
            "שם_רחוב": "משעול ניצנה"
        },
        {
            "_id": 7054,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 380,
            "שם_רחוב": "משעול סוסיה"
        },
        {
            "_id": 7055,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 453,
            "שם_רחוב": "משעול עקרון"
        },
        {
            "_id": 7056,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1104,
            "שם_רחוב": "משעול פנקס דוד"
        },
        {
            "_id": 7057,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 946,
            "שם_רחוב": "מתתיהו"
        },
        {
            "_id": 7058,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6602,
            "שם_רחוב": "נאות אילן"
        },
        {
            "_id": 7059,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 338,
            "שם_רחוב": "נאות הככר"
        },
        {
            "_id": 7060,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6601,
            "שם_רחוב": "נאות חצרים"
        },
        {
            "_id": 7061,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6565,
            "שם_רחוב": "נאות לון"
        },
        {
            "_id": 7062,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 339,
            "שם_רחוב": "נאות מדבר"
        },
        {
            "_id": 7063,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 947,
            "שם_רחוב": "נגבה"
        },
        {
            "_id": 7064,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 375,
            "שם_רחוב": "נדב"
        },
        {
            "_id": 7065,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 836,
            "שם_רחוב": "נהרים"
        },
        {
            "_id": 7066,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6600,
            "שם_רחוב": "נווה זאב"
        },
        {
            "_id": 7067,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6555,
            "שם_רחוב": "נווה מנחם"
        },
        {
            "_id": 7068,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1219,
            "שם_רחוב": "נויפלד הנרי"
        },
        {
            "_id": 7069,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 533,
            "שם_רחוב": "נורדאו מקס"
        },
        {
            "_id": 7070,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1020,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 7071,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 948,
            "שם_רחוב": "נחום"
        },
        {
            "_id": 7072,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 677,
            "שם_רחוב": "נחל בוקק"
        },
        {
            "_id": 7073,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 685,
            "שם_רחוב": "נחל גוב"
        },
        {
            "_id": 7074,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 693,
            "שם_רחוב": "נחל דישון"
        },
        {
            "_id": 7075,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 691,
            "שם_רחוב": "נחל דליות"
        },
        {
            "_id": 7076,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 670,
            "שם_רחוב": "נחל חבר"
        },
        {
            "_id": 7077,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 682,
            "שם_רחוב": "נחל חיון"
        },
        {
            "_id": 7078,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 679,
            "שם_רחוב": "נחל יבניאל"
        },
        {
            "_id": 7079,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 676,
            "שם_רחוב": "נחל יהל"
        },
        {
            "_id": 7080,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 678,
            "שם_רחוב": "נחל ימין"
        },
        {
            "_id": 7081,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 687,
            "שם_רחוב": "נחל לבן"
        },
        {
            "_id": 7082,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 688,
            "שם_רחוב": "נחל משושים"
        },
        {
            "_id": 7083,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 674,
            "שם_רחוב": "נחל משמר"
        },
        {
            "_id": 7084,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 683,
            "שם_רחוב": "נחל נטיפים"
        },
        {
            "_id": 7085,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 672,
            "שם_רחוב": "נחל נקרות"
        },
        {
            "_id": 7086,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 692,
            "שם_רחוב": "נחל סער"
        },
        {
            "_id": 7087,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 681,
            "שם_רחוב": "נחל עמוד"
        },
        {
            "_id": 7088,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 689,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 7089,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6556,
            "שם_רחוב": "נחל עשן"
        },
        {
            "_id": 7090,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 684,
            "שם_רחוב": "נחל פרס"
        },
        {
            "_id": 7091,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 671,
            "שם_רחוב": "נחל פרת"
        },
        {
            "_id": 7092,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 690,
            "שם_רחוב": "נחל ציחור"
        },
        {
            "_id": 7093,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 680,
            "שם_רחוב": "נחל צלמון"
        },
        {
            "_id": 7094,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 673,
            "שם_רחוב": "נחל קדרון"
        },
        {
            "_id": 7095,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 675,
            "שם_רחוב": "נחל שילה"
        },
        {
            "_id": 7096,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 224,
            "שם_רחוב": "נחליאל"
        },
        {
            "_id": 7097,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1315,
            "שם_רחוב": "נטר"
        },
        {
            "_id": 7098,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1072,
            "שם_רחוב": "ניב דוד"
        },
        {
            "_id": 7099,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 513,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 7100,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 878,
            "שם_רחוב": "ניסנמן"
        },
        {
            "_id": 7101,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 550,
            "שם_רחוב": "ניצני עזריאל"
        },
        {
            "_id": 7102,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1171,
            "שם_רחוב": "ניר נחום"
        },
        {
            "_id": 7103,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1109,
            "שם_רחוב": "נמיר מרדכי"
        },
        {
            "_id": 7104,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1172,
            "שם_רחוב": "נעמי"
        },
        {
            "_id": 7105,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 160,
            "שם_רחוב": "נעמי שמר"
        },
        {
            "_id": 7106,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 949,
            "שם_רחוב": "נפתלי"
        },
        {
            "_id": 7107,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1303,
            "שם_רחוב": "נקר מאיר"
        },
        {
            "_id": 7108,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 260,
            "שם_רחוב": "נרבוני"
        },
        {
            "_id": 7109,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1027,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 7110,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1360,
            "שם_רחוב": "נרקיס עוזי"
        },
        {
            "_id": 7111,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 127,
            "שם_רחוב": "נשר"
        },
        {
            "_id": 7112,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1106,
            "שם_רחוב": "סבידור מנחם"
        },
        {
            "_id": 7113,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1406,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 7114,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1252,
            "שם_רחוב": "סגל צבי"
        },
        {
            "_id": 7115,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1242,
            "שם_רחוב": "סדן דב"
        },
        {
            "_id": 7116,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 867,
            "שם_רחוב": "סדנא"
        },
        {
            "_id": 7117,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1097,
            "שם_רחוב": "סוד השבועה"
        },
        {
            "_id": 7118,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 192,
            "שם_רחוב": "סוזן דניאל-נטף"
        },
        {
            "_id": 7119,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 214,
            "שם_רחוב": "סוכות"
        },
        {
            "_id": 7120,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 837,
            "שם_רחוב": "סולד הנריאטה"
        },
        {
            "_id": 7121,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 820,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 7122,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 591,
            "שם_רחוב": "סטולר שמואל"
        },
        {
            "_id": 7123,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 303,
            "שם_רחוב": "סטרומה"
        },
        {
            "_id": 7124,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1442,
            "שם_רחוב": "סיגלון"
        },
        {
            "_id": 7125,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1407,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 7126,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1073,
            "שם_רחוב": "סילמן יהודה"
        },
        {
            "_id": 7127,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1230,
            "שם_רחוב": "סימון ארנסט דוד"
        },
        {
            "_id": 7128,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 335,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 7129,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1074,
            "שם_רחוב": "סירני"
        },
        {
            "_id": 7130,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 879,
            "שם_רחוב": "סלמה"
        },
        {
            "_id": 7131,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 592,
            "שם_רחוב": "סלנט שמואל"
        },
        {
            "_id": 7132,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 315,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 7133,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 979,
            "שם_רחוב": "סמ הגדם"
        },
        {
            "_id": 7134,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 265,
            "שם_רחוב": "סמ יהונתן"
        },
        {
            "_id": 7135,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 253,
            "שם_רחוב": "סמ ערד"
        },
        {
            "_id": 7136,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 230,
            "שם_רחוב": "סמ צקלג"
        },
        {
            "_id": 7137,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 980,
            "שם_רחוב": "סמ קטורה"
        },
        {
            "_id": 7138,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 972,
            "שם_רחוב": "סמ ראומה"
        },
        {
            "_id": 7139,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 233,
            "שם_רחוב": "סמ רפידים"
        },
        {
            "_id": 7140,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1255,
            "שם_רחוב": "סמבורסקי שמואל"
        },
        {
            "_id": 7141,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1173,
            "שם_רחוב": "סמולנסקין"
        },
        {
            "_id": 7142,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 821,
            "שם_רחוב": "סמטס"
        },
        {
            "_id": 7143,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1075,
            "שם_רחוב": "סמילן רות"
        },
        {
            "_id": 7144,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 950,
            "שם_רחוב": "סמילנסקי"
        },
        {
            "_id": 7145,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1396,
            "שם_רחוב": "סנה משה"
        },
        {
            "_id": 7146,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1416,
            "שם_רחוב": "סנה נחום"
        },
        {
            "_id": 7147,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 244,
            "שם_רחוב": "סנהדרין"
        },
        {
            "_id": 7148,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 514,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 7149,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 414,
            "שם_רחוב": "סעדיה גאון"
        },
        {
            "_id": 7150,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1076,
            "שם_רחוב": "סקולסקי שלמה"
        },
        {
            "_id": 7151,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1175,
            "שם_רחוב": "סרלין יוסף"
        },
        {
            "_id": 7152,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1077,
            "שם_רחוב": "סרן דב"
        },
        {
            "_id": 7153,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 593,
            "שם_רחוב": "סרנה יחזקאל"
        },
        {
            "_id": 7154,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1078,
            "שם_רחוב": "סתוי משה"
        },
        {
            "_id": 7155,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1008,
            "שם_רחוב": "סתונית"
        },
        {
            "_id": 7156,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 488,
            "שם_רחוב": "עבדת"
        },
        {
            "_id": 7157,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1312,
            "שם_רחוב": "עגור"
        },
        {
            "_id": 7158,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 344,
            "שם_רחוב": "עגנון"
        },
        {
            "_id": 7159,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 158,
            "שם_רחוב": "עדה גלר"
        },
        {
            "_id": 7160,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 226,
            "שם_רחוב": "עוזיה המלך"
        },
        {
            "_id": 7161,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 610,
            "שם_רחוב": "עולי הגרדום"
        },
        {
            "_id": 7162,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 182,
            "שם_רחוב": "עופרה חזה"
        },
        {
            "_id": 7163,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 312,
            "שם_רחוב": "עורב"
        },
        {
            "_id": 7164,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 126,
            "שם_רחוב": "עיט"
        },
        {
            "_id": 7165,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 484,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 7166,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1004,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 7167,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 483,
            "שם_רחוב": "עכו"
        },
        {
            "_id": 7168,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 519,
            "שם_רחוב": "עליאש מרדכי"
        },
        {
            "_id": 7169,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 156,
            "שם_רחוב": "עליזה בגין"
        },
        {
            "_id": 7170,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 185,
            "שם_רחוב": "עליזה בירון"
        },
        {
            "_id": 7171,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 952,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 7172,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1435,
            "שם_רחוב": "עמיעז"
        },
        {
            "_id": 7173,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1348,
            "שם_רחוב": "עמיצור שמשון"
        },
        {
            "_id": 7174,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 594,
            "שם_רחוב": "עמיר אנדה"
        },
        {
            "_id": 7175,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 868,
            "שם_רחוב": "עמל"
        },
        {
            "_id": 7176,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6608,
            "שם_רחוב": "עמק שרה"
        },
        {
            "_id": 7177,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 296,
            "שם_רחוב": "עמרי"
        },
        {
            "_id": 7178,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 139,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 7179,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 323,
            "שם_רחוב": "עפרים"
        },
        {
            "_id": 7180,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1177,
            "שם_רחוב": "פאבל"
        },
        {
            "_id": 7181,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 179,
            "שם_רחוב": "פאני מוגרבי"
        },
        {
            "_id": 7182,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 227,
            "שם_רחוב": "פארן"
        },
        {
            "_id": 7183,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 157,
            "שם_רחוב": "פולה בן גוריון"
        },
        {
            "_id": 7184,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1178,
            "שם_רחוב": "פועה"
        },
        {
            "_id": 7185,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1080,
            "שם_רחוב": "פורת לאה"
        },
        {
            "_id": 7186,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1368,
            "שם_רחוב": "פורת קלמן"
        },
        {
            "_id": 7187,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1000,
            "שם_רחוב": "פטל"
        },
        {
            "_id": 7188,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1382,
            "שם_רחוב": "פטנקין דן"
        },
        {
            "_id": 7189,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 305,
            "שם_רחוב": "פטריה"
        },
        {
            "_id": 7190,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1081,
            "שם_רחוב": "פיארברג"
        },
        {
            "_id": 7191,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1179,
            "שם_רחוב": "פייגנבאום אריה"
        },
        {
            "_id": 7192,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1180,
            "שם_רחוב": "פיינברג אבשלום"
        },
        {
            "_id": 7193,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1304,
            "שם_רחוב": "פיינשטיין מאיר"
        },
        {
            "_id": 7194,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1082,
            "שם_רחוב": "פיכמן יעקב"
        },
        {
            "_id": 7195,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1181,
            "שם_רחוב": "פילדרמן"
        },
        {
            "_id": 7196,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 133,
            "שם_רחוב": "פינסקר"
        },
        {
            "_id": 7197,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1401,
            "שם_רחוב": "פינקל שמעון"
        },
        {
            "_id": 7198,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1349,
            "שם_רחוב": "פיקרד"
        },
        {
            "_id": 7199,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1269,
            "שם_רחוב": "פלדברג שמואל"
        },
        {
            "_id": 7200,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1379,
            "שם_רחוב": "פלוסר דוד"
        },
        {
            "_id": 7201,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 166,
            "שם_רחוב": "פלורי אשר"
        },
        {
            "_id": 7202,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1211,
            "שם_רחוב": "פליקס"
        },
        {
            "_id": 7203,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1028,
            "שם_רחוב": "פלפלון"
        },
        {
            "_id": 7204,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 845,
            "שם_רחוב": "פנחס החוצב"
        },
        {
            "_id": 7205,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 595,
            "שם_רחוב": "פני משה"
        },
        {
            "_id": 7206,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1182,
            "שם_רחוב": "פנינה"
        },
        {
            "_id": 7207,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 326,
            "שם_רחוב": "פסמון"
        },
        {
            "_id": 7208,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1429,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 7209,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1009,
            "שם_רחוב": "פקועה"
        },
        {
            "_id": 7210,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1218,
            "שם_רחוב": "פראוור יהושע"
        },
        {
            "_id": 7211,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 415,
            "שם_רחוב": "פרויד"
        },
        {
            "_id": 7212,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 596,
            "שם_רחוב": "פרומקין גד"
        },
        {
            "_id": 7213,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 438,
            "שם_רחוב": "פרופ' מחרז אברהם"
        },
        {
            "_id": 7214,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 975,
            "שם_רחוב": "פרופ' עלי דיוויס"
        },
        {
            "_id": 7215,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1460,
            "שם_רחוב": "פרופ' רוזן נתן"
        },
        {
            "_id": 7216,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1197,
            "שם_רחוב": "פרופ' שיבא"
        },
        {
            "_id": 7217,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1084,
            "שם_רחוב": "פרטוש עדן"
        },
        {
            "_id": 7218,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 597,
            "שם_רחוב": "פרי חדש"
        },
        {
            "_id": 7219,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 986,
            "שם_רחוב": "פריאר רחה"
        },
        {
            "_id": 7220,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1343,
            "שם_רחוב": "פריבס משה"
        },
        {
            "_id": 7221,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1202,
            "שם_רחוב": "פריד אלפרד"
        },
        {
            "_id": 7222,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 661,
            "שם_רחוב": "פריץ אלברט"
        },
        {
            "_id": 7223,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1085,
            "שם_רחוב": "פרישמן דוד"
        },
        {
            "_id": 7224,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1207,
            "שם_רחוב": "פרנק ג'ימס"
        },
        {
            "_id": 7225,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1183,
            "שם_רחוב": "פרסיץ שושנה"
        },
        {
            "_id": 7226,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1086,
            "שם_רחוב": "פרץ י ל"
        },
        {
            "_id": 7227,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 123,
            "שם_רחוב": "פשוש"
        },
        {
            "_id": 7228,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1184,
            "שם_רחוב": "צ'לנוב יחיאל"
        },
        {
            "_id": 7229,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1024,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 7230,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1421,
            "שם_רחוב": "צאלים"
        },
        {
            "_id": 7231,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1261,
            "שם_רחוב": "צבי-רן שלמה"
        },
        {
            "_id": 7232,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 228,
            "שם_רחוב": "צביה המלכה"
        },
        {
            "_id": 7233,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1087,
            "שם_רחוב": "צביה ויצחק"
        },
        {
            "_id": 7234,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1010,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 7235,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1011,
            "שם_רחוב": "צבר"
        },
        {
            "_id": 7236,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 356,
            "שם_רחוב": "צדקיהו"
        },
        {
            "_id": 7237,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1229,
            "שם_רחוב": "צונדק ברנרד והרמן"
        },
        {
            "_id": 7238,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1433,
            "שם_רחוב": "צוער"
        },
        {
            "_id": 7239,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 119,
            "שם_רחוב": "צוקית"
        },
        {
            "_id": 7240,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1101,
            "שם_רחוב": "ציזלינג"
        },
        {
            "_id": 7241,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 229,
            "שם_רחוב": "צין"
        },
        {
            "_id": 7242,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 143,
            "שם_רחוב": "ציר הזמן"
        },
        {
            "_id": 7243,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1185,
            "שם_רחוב": "צפורה"
        },
        {
            "_id": 7244,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 387,
            "שם_רחוב": "צפורי"
        },
        {
            "_id": 7245,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 955,
            "שם_רחוב": "צפניה"
        },
        {
            "_id": 7246,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1279,
            "שם_רחוב": "צפצפה"
        },
        {
            "_id": 7247,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 345,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 7248,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 306,
            "שם_רחוב": "קדושי בגדד"
        },
        {
            "_id": 7249,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1186,
            "שם_רחוב": "קדושי יאסי"
        },
        {
            "_id": 7250,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1187,
            "שם_רחוב": "קדושי סלוניקי"
        },
        {
            "_id": 7251,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 492,
            "שם_רחוב": "קדיש לוז"
        },
        {
            "_id": 7252,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 231,
            "שם_רחוב": "קדש"
        },
        {
            "_id": 7253,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1394,
            "שם_רחוב": "קהירי"
        },
        {
            "_id": 7254,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1030,
            "שם_רחוב": "קובנר אבא"
        },
        {
            "_id": 7255,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "קוטגים"
        },
        {
            "_id": 7256,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 455,
            "שם_רחוב": "קויפמן"
        },
        {
            "_id": 7257,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1079,
            "שם_רחוב": "קול התור"
        },
        {
            "_id": 7258,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1033,
            "שם_רחוב": "קול מבשר"
        },
        {
            "_id": 7259,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 314,
            "שם_רחוב": "קורא"
        },
        {
            "_id": 7260,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1188,
            "שם_רחוב": "קורא הדורות"
        },
        {
            "_id": 7261,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 600,
            "שם_רחוב": "קורבן העדה"
        },
        {
            "_id": 7262,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 634,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 7263,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 880,
            "שם_רחוב": "קטינקא"
        },
        {
            "_id": 7264,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 636,
            "שם_רחוב": "קינמון"
        },
        {
            "_id": 7265,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 496,
            "שם_רחוב": "קיסריה"
        },
        {
            "_id": 7266,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 322,
            "שם_רחוב": "קיפוד"
        },
        {
            "_id": 7267,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 962,
            "שם_רחוב": "קיפניס לוין"
        },
        {
            "_id": 7268,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1423,
            "שם_רחוב": "קישון"
        },
        {
            "_id": 7269,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 520,
            "שם_רחוב": "קלאוזנר"
        },
        {
            "_id": 7270,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 515,
            "שם_רחוב": "קלישר"
        },
        {
            "_id": 7271,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 599,
            "שם_רחוב": "קלצ'קין רפאל"
        },
        {
            "_id": 7272,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 982,
            "שם_רחוב": "קנדל הנרי"
        },
        {
            "_id": 7273,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 703,
            "שם_רחוב": "קניון הנגב"
        },
        {
            "_id": 7274,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 307,
            "שם_רחוב": "קפריסין"
        },
        {
            "_id": 7275,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1234,
            "שם_רחוב": "קציר אהרון"
        },
        {
            "_id": 7276,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1386,
            "שם_רחוב": "קראוס גרטרוד"
        },
        {
            "_id": 7277,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 829,
            "שם_רחוב": "קרויצר מנחם"
        },
        {
            "_id": 7278,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 881,
            "שם_רחוב": "קרונמן"
        },
        {
            "_id": 7279,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1244,
            "שם_רחוב": "קרוק גלעד דורותיה"
        },
        {
            "_id": 7280,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 516,
            "שם_רחוב": "קרייתי"
        },
        {
            "_id": 7281,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "קרית וולפסון"
        },
        {
            "_id": 7282,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6607,
            "שם_רחוב": "קרית יהודית"
        },
        {
            "_id": 7283,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 612,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 7284,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 956,
            "שם_רחוב": "קרן קיימת לישראל"
        },
        {
            "_id": 7285,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1305,
            "שם_רחוב": "קשאני אליעזר"
        },
        {
            "_id": 7286,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 957,
            "שם_רחוב": "ראובן"
        },
        {
            "_id": 7287,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1145,
            "שם_רחוב": "רב האי גאון"
        },
        {
            "_id": 7288,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 278,
            "שם_רחוב": "רבי טרפון"
        },
        {
            "_id": 7289,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 240,
            "שם_רחוב": "רבי מאיר בעל הנס"
        },
        {
            "_id": 7290,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 254,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 7291,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 249,
            "שם_רחוב": "רבן גמליאל"
        },
        {
            "_id": 7292,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 288,
            "שם_רחוב": "רבנו תם"
        },
        {
            "_id": 7293,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1190,
            "שם_רחוב": "רבקה"
        },
        {
            "_id": 7294,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 350,
            "שם_רחוב": "רדק"
        },
        {
            "_id": 7295,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1334,
            "שם_רחוב": "רובין דב"
        },
        {
            "_id": 7296,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 420,
            "שם_רחוב": "רובין ראובן"
        },
        {
            "_id": 7297,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 651,
            "שם_רחוב": "רובינא חנה"
        },
        {
            "_id": 7298,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 882,
            "שם_רחוב": "רובק"
        },
        {
            "_id": 7299,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 652,
            "שם_רחוב": "רודנסקי שמואל"
        },
        {
            "_id": 7300,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 191,
            "שם_רחוב": "רוזה גינצברג גינוסר"
        },
        {
            "_id": 7301,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 633,
            "שם_רחוב": "רוזמרין"
        },
        {
            "_id": 7302,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1191,
            "שם_רחוב": "רוזן פנחס"
        },
        {
            "_id": 7303,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1089,
            "שם_רחוב": "רוזנבלום הרצל"
        },
        {
            "_id": 7304,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1090,
            "שם_רחוב": "רוט אגון"
        },
        {
            "_id": 7305,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 517,
            "שם_רחוב": "רוטנברג"
        },
        {
            "_id": 7306,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 898,
            "שם_רחוב": "רוטנברג יוליה ושמוא"
        },
        {
            "_id": 7307,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1390,
            "שם_רחוב": "רוטנשטרייך"
        },
        {
            "_id": 7308,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1091,
            "שם_רחוב": "רונאל דב"
        },
        {
            "_id": 7309,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 270,
            "שם_רחוב": "רוסי אלפרד"
        },
        {
            "_id": 7310,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 471,
            "שם_רחוב": "רופין ארתור"
        },
        {
            "_id": 7311,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1107,
            "שם_רחוב": "רוקח ישראל"
        },
        {
            "_id": 7312,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1192,
            "שם_רחוב": "רות"
        },
        {
            "_id": 7313,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 178,
            "שם_רחוב": "רות הילמן"
        },
        {
            "_id": 7314,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1012,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 7315,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 524,
            "שם_רחוב": "רזיאל"
        },
        {
            "_id": 7316,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1365,
            "שם_רחוב": "רחבעם זאבי"
        },
        {
            "_id": 7317,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 477,
            "שם_רחוב": "רחבת אופירה"
        },
        {
            "_id": 7318,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 391,
            "שם_רחוב": "רחבת אחזיה"
        },
        {
            "_id": 7319,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 461,
            "שם_רחוב": "רחבת איבצן"
        },
        {
            "_id": 7320,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 462,
            "שם_רחוב": "רחבת אלכסנדרוני"
        },
        {
            "_id": 7321,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 392,
            "שם_רחוב": "רחבת אמציה"
        },
        {
            "_id": 7322,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 973,
            "שם_רחוב": "רחבת באר אברהם"
        },
        {
            "_id": 7323,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 466,
            "שם_רחוב": "רחבת בן נון"
        },
        {
            "_id": 7324,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 362,
            "שם_רחוב": "רחבת בן עטר"
        },
        {
            "_id": 7325,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 476,
            "שם_רחוב": "רחבת גדעון"
        },
        {
            "_id": 7326,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 458,
            "שם_רחוב": "רחבת המרי העברי"
        },
        {
            "_id": 7327,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 465,
            "שם_רחוב": "רחבת הראל"
        },
        {
            "_id": 7328,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 361,
            "שם_רחוב": "רחבת הרב עוזיאל"
        },
        {
            "_id": 7329,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 364,
            "שם_רחוב": "רחבת הרב קוק"
        },
        {
            "_id": 7330,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 365,
            "שם_רחוב": "רחבת השלה"
        },
        {
            "_id": 7331,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 523,
            "שם_רחוב": "רחבת חיל"
        },
        {
            "_id": 7332,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 480,
            "שם_רחוב": "רחבת יבנה"
        },
        {
            "_id": 7333,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 393,
            "שם_רחוב": "רחבת יותם"
        },
        {
            "_id": 7334,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 482,
            "שם_רחוב": "רחבת ימית"
        },
        {
            "_id": 7335,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 529,
            "שם_רחוב": "רחבת יעל"
        },
        {
            "_id": 7336,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 464,
            "שם_רחוב": "רחבת לחי"
        },
        {
            "_id": 7337,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 397,
            "שם_רחוב": "רחבת מבצע ארז"
        },
        {
            "_id": 7338,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 390,
            "שם_רחוב": "רחבת מבצע ברוש"
        },
        {
            "_id": 7339,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 396,
            "שם_רחוב": "רחבת מבצע יקב"
        },
        {
            "_id": 7340,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 398,
            "שם_רחוב": "רחבת מבצע קדם"
        },
        {
            "_id": 7341,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 395,
            "שם_רחוב": "רחבת מבצע קילשון"
        },
        {
            "_id": 7342,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 457,
            "שם_רחוב": "רחבת מולדת"
        },
        {
            "_id": 7343,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 456,
            "שם_רחוב": "רחבת מילוס"
        },
        {
            "_id": 7344,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 474,
            "שם_רחוב": "רחבת עשהאל"
        },
        {
            "_id": 7345,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 463,
            "שם_רחוב": "רחבת פל-ים"
        },
        {
            "_id": 7346,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 363,
            "שם_רחוב": "רחבת רמא"
        },
        {
            "_id": 7347,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 394,
            "שם_רחוב": "רחבת שלומציון"
        },
        {
            "_id": 7348,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 459,
            "שם_רחוב": "רחבת שמגר השופט"
        },
        {
            "_id": 7349,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1193,
            "שם_רחוב": "רחל אמנו"
        },
        {
            "_id": 7350,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 425,
            "שם_רחוב": "רחל המשוררת"
        },
        {
            "_id": 7351,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1266,
            "שם_רחוב": "רחל ינאית"
        },
        {
            "_id": 7352,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 193,
            "שם_רחוב": "רחל צברי"
        },
        {
            "_id": 7353,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1206,
            "שם_רחוב": "ריבי איזידור"
        },
        {
            "_id": 7354,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 637,
            "שם_רחוב": "ריחן"
        },
        {
            "_id": 7355,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1093,
            "שם_רחוב": "רייס רפאל"
        },
        {
            "_id": 7356,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 527,
            "שם_רחוב": "רייק חביבה"
        },
        {
            "_id": 7357,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1373,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 7358,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1194,
            "שם_רחוב": "רימלט אלימלך"
        },
        {
            "_id": 7359,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 258,
            "שם_רחוב": "רינגלבלום"
        },
        {
            "_id": 7360,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1225,
            "שם_רחוב": "רינר מרכוס"
        },
        {
            "_id": 7361,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 964,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 7362,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 289,
            "שם_רחוב": "רמבן"
        },
        {
            "_id": 7363,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6610,
            "שם_רחוב": "רמות באר שבע"
        },
        {
            "_id": 7364,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 416,
            "שם_רחוב": "רמחל"
        },
        {
            "_id": 7365,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 494,
            "שם_רחוב": "רמלה"
        },
        {
            "_id": 7366,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1210,
            "שם_רחוב": "רנה קסן"
        },
        {
            "_id": 7367,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1309,
            "שם_רחוב": "רפאל איתן (רפול)"
        },
        {
            "_id": 7368,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1226,
            "שם_רחוב": "רקח יואל"
        },
        {
            "_id": 7369,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1026,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 7370,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 275,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 7371,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1392,
            "שם_רחוב": "רשל"
        },
        {
            "_id": 7372,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 654,
            "שם_רחוב": "רשבא"
        },
        {
            "_id": 7373,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6576,
            "שם_רחוב": "ש ביח רוקיה"
        },
        {
            "_id": 7374,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6616,
            "שם_רחוב": "ש בתי אבן"
        },
        {
            "_id": 7375,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6595,
            "שם_רחוב": "ש דרום חדש"
        },
        {
            "_id": 7376,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6596,
            "שם_רחוב": "ש חצרים"
        },
        {
            "_id": 7377,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "ש יא"
        },
        {
            "_id": 7378,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6617,
            "שם_רחוב": "ש משכנות"
        },
        {
            "_id": 7379,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6597,
            "שם_רחוב": "ש סולל בונה"
        },
        {
            "_id": 7380,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6577,
            "שם_רחוב": "ש עובדים ב"
        },
        {
            "_id": 7381,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6585,
            "שם_רחוב": "ש עממי א"
        },
        {
            "_id": 7382,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6618,
            "שם_רחוב": "ש עממי ג"
        },
        {
            "_id": 7383,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6578,
            "שם_רחוב": "ש רסקו"
        },
        {
            "_id": 7384,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 353,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 7385,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1195,
            "שם_רחוב": "שאול ואליהו"
        },
        {
            "_id": 7386,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 308,
            "שם_רחוב": "שאר ישוב"
        },
        {
            "_id": 7387,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 848,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 7388,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1355,
            "שם_רחוב": "שד אשכול"
        },
        {
            "_id": 7389,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 7390,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1270,
            "שם_רחוב": "שד בנצ כרמל"
        },
        {
            "_id": 7391,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 472,
            "שם_רחוב": "שד גולדה מאיר"
        },
        {
            "_id": 7392,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1267,
            "שם_רחוב": "שד האנדרטה"
        },
        {
            "_id": 7393,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1056,
            "שם_רחוב": "שד הנשיא הרצוג חיים"
        },
        {
            "_id": 7394,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1369,
            "שם_רחוב": "שד חכם דוד"
        },
        {
            "_id": 7395,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 366,
            "שם_רחוב": "שד טוביהו דוד"
        },
        {
            "_id": 7396,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 485,
            "שם_רחוב": "שד ידין יגאל"
        },
        {
            "_id": 7397,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 311,
            "שם_רחוב": "שד יעלים"
        },
        {
            "_id": 7398,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 360,
            "שם_רחוב": "שד ירושלים"
        },
        {
            "_id": 7399,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1336,
            "שם_רחוב": "שד מרש יעקב"
        },
        {
            "_id": 7400,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1370,
            "שם_רחוב": "שד צהל"
        },
        {
            "_id": 7401,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 811,
            "שם_רחוב": "שד רגר יצחק"
        },
        {
            "_id": 7402,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1410,
            "שם_רחוב": "שד רוטשילד"
        },
        {
            "_id": 7403,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 965,
            "שם_רחוב": "שדה יצחק"
        },
        {
            "_id": 7404,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 145,
            "שם_רחוב": "שדרת היחסות"
        },
        {
            "_id": 7405,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1356,
            "שם_רחוב": "שדרת הערים התאומות"
        },
        {
            "_id": 7406,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 995,
            "שם_רחוב": "שדרת חיל הנדסה"
        },
        {
            "_id": 7407,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 135,
            "שם_רחוב": "שוחט ישראל"
        },
        {
            "_id": 7408,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1196,
            "שם_רחוב": "שולמית"
        },
        {
            "_id": 7409,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 495,
            "שם_רחוב": "שומרון"
        },
        {
            "_id": 7410,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 518,
            "שם_רחוב": "שועלי שמשון"
        },
        {
            "_id": 7411,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1335,
            "שם_רחוב": "שופט אברהם"
        },
        {
            "_id": 7412,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1094,
            "שם_רחוב": "שופמן גרשון"
        },
        {
            "_id": 7413,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1436,
            "שם_רחוב": "שורק"
        },
        {
            "_id": 7414,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 174,
            "שם_רחוב": "שושנה דמארי"
        },
        {
            "_id": 7415,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 195,
            "שם_רחוב": "שושנה שבבו"
        },
        {
            "_id": 7416,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 701,
            "שם_רחוב": "שזר זלמן"
        },
        {
            "_id": 7417,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 823,
            "שם_רחוב": "שחל"
        },
        {
            "_id": 7418,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1432,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 7419,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1013,
            "שם_רחוב": "שטה"
        },
        {
            "_id": 7420,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 656,
            "שם_רחוב": "שטיין ריכרד"
        },
        {
            "_id": 7421,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 655,
            "שם_רחוב": "שטיינברג יהודה"
        },
        {
            "_id": 7422,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 417,
            "שם_רחוב": "שטרוק"
        },
        {
            "_id": 7423,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1327,
            "שם_רחוב": "שטרייכמן יחזקאל"
        },
        {
            "_id": 7424,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1341,
            "שם_רחוב": "שטרן יוסף"
        },
        {
            "_id": 7425,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 479,
            "שם_רחוב": "שיבטה"
        },
        {
            "_id": 7426,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1014,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 7427,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 460,
            "שם_רחוב": "שיטרית"
        },
        {
            "_id": 7428,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1357,
            "שם_רחוב": "שינדלר אוסקר"
        },
        {
            "_id": 7429,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1434,
            "שם_רחוב": "שיפון"
        },
        {
            "_id": 7430,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 830,
            "שם_רחוב": "שיפר יצחק"
        },
        {
            "_id": 7431,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1254,
            "שם_רחוב": "שירמן חיים"
        },
        {
            "_id": 7432,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6587,
            "שם_רחוב": "שכ א"
        },
        {
            "_id": 7433,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6579,
            "שם_רחוב": "שכ ב"
        },
        {
            "_id": 7434,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6620,
            "שם_רחוב": "שכ ג"
        },
        {
            "_id": 7435,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ ד"
        },
        {
            "_id": 7436,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6568,
            "שם_רחוב": "שכ ה"
        },
        {
            "_id": 7437,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ ו"
        },
        {
            "_id": 7438,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6561,
            "שם_רחוב": "שכ ט"
        },
        {
            "_id": 7439,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "שכ כלניות"
        },
        {
            "_id": 7440,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6598,
            "שם_רחוב": "שכ נוה נוי"
        },
        {
            "_id": 7441,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6599,
            "שם_רחוב": "שכ נחל בקע"
        },
        {
            "_id": 7442,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "שכ סיגליות"
        },
        {
            "_id": 7443,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6612,
            "שם_רחוב": "שכ רמות ב'"
        },
        {
            "_id": 7444,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 6613,
            "שם_רחוב": "שכ רמות ד'"
        },
        {
            "_id": 7445,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 386,
            "שם_רחוב": "שכם"
        },
        {
            "_id": 7446,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 122,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 7447,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 418,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 7448,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 341,
            "שם_רחוב": "שלונסקי"
        },
        {
            "_id": 7449,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 987,
            "שם_רחוב": "שלושת המצפים"
        },
        {
            "_id": 7450,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 313,
            "שם_רחוב": "שליו"
        },
        {
            "_id": 7451,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 234,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 7452,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 966,
            "שם_רחוב": "שלשת בני עין חרוד"
        },
        {
            "_id": 7453,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 248,
            "שם_רחוב": "שמאי"
        },
        {
            "_id": 7454,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1096,
            "שם_רחוב": "שמאלי אליעזר"
        },
        {
            "_id": 7455,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 967,
            "שם_רחוב": "שמואל הנביא"
        },
        {
            "_id": 7456,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 968,
            "שם_רחוב": "שמחה"
        },
        {
            "_id": 7457,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 969,
            "שם_רחוב": "שמחוני אסף"
        },
        {
            "_id": 7458,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 660,
            "שם_רחוב": "שמחת כהן"
        },
        {
            "_id": 7459,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 328,
            "שם_רחוב": "שממית"
        },
        {
            "_id": 7460,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 206,
            "שם_רחוב": "שמעון בר גיורא"
        },
        {
            "_id": 7461,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 419,
            "שם_רחוב": "שמעוני"
        },
        {
            "_id": 7462,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 246,
            "שם_רחוב": "שמעיה"
        },
        {
            "_id": 7463,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 329,
            "שם_רחוב": "שנונית"
        },
        {
            "_id": 7464,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 424,
            "שם_רחוב": "שניאור"
        },
        {
            "_id": 7465,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 883,
            "שם_רחוב": "שניידרוביץ"
        },
        {
            "_id": 7466,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1399,
            "שם_רחוב": "שסק"
        },
        {
            "_id": 7467,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1414,
            "שם_רחוב": "שעורה"
        },
        {
            "_id": 7468,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 499,
            "שם_רחוב": "שער הגיא"
        },
        {
            "_id": 7469,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 824,
            "שם_רחוב": "שפירא"
        },
        {
            "_id": 7470,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1198,
            "שם_רחוב": "שפרה"
        },
        {
            "_id": 7471,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 238,
            "שם_רחוב": "שפרינצק"
        },
        {
            "_id": 7472,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 262,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 7473,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1374,
            "שם_רחוב": "שקמה"
        },
        {
            "_id": 7474,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 970,
            "שם_רחוב": "שרה אמנו"
        },
        {
            "_id": 7475,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 639,
            "שם_רחוב": "שרה ומאיר בץ"
        },
        {
            "_id": 7476,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 169,
            "שם_רחוב": "שרה לוי-תנאי"
        },
        {
            "_id": 7477,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 188,
            "שם_רחוב": "שרה עזריהו"
        },
        {
            "_id": 7478,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1359,
            "שם_רחוב": "שריג נחום"
        },
        {
            "_id": 7479,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1199,
            "שם_רחוב": "שרירא גאון"
        },
        {
            "_id": 7480,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 532,
            "שם_רחוב": "שרעבי"
        },
        {
            "_id": 7481,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1393,
            "שם_רחוב": "שרשבסקי"
        },
        {
            "_id": 7482,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 469,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 7483,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 450,
            "שם_רחוב": "ששון אליהו"
        },
        {
            "_id": 7484,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 498,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 7485,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1264,
            "שם_רחוב": "שתל יצחק"
        },
        {
            "_id": 7486,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1415,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 7487,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1417,
            "שם_רחוב": "תאשור"
        },
        {
            "_id": 7488,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 994,
            "שם_רחוב": "תבורי חיים"
        },
        {
            "_id": 7489,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1418,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 7490,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 113,
            "שם_רחוב": "תוצרת הארץ"
        },
        {
            "_id": 7491,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1425,
            "שם_רחוב": "תור"
        },
        {
            "_id": 7492,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 112,
            "שם_רחוב": "תורן יהושע"
        },
        {
            "_id": 7493,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 825,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 7494,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1098,
            "שם_רחוב": "תלמה"
        },
        {
            "_id": 7495,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1331,
            "שם_רחוב": "תלמי מאיר"
        },
        {
            "_id": 7496,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 430,
            "שם_רחוב": "תמיר שמואל"
        },
        {
            "_id": 7497,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 493,
            "שם_רחוב": "תמנע"
        },
        {
            "_id": 7498,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1007,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 7499,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 181,
            "שם_רחוב": "תמר שחר"
        },
        {
            "_id": 7500,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1412,
            "שם_רחוב": "תנובה"
        },
        {
            "_id": 7501,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1427,
            "שם_רחוב": "תפוז"
        },
        {
            "_id": 7502,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1428,
            "שם_רחוב": "תפוח"
        },
        {
            "_id": 7503,
            "סמל_ישוב": 9000,
            "שם_ישוב": "באר שבע ",
            "סמל_רחוב": 1263,
            "שם_רחוב": "תקוע יוסף"
        },
        {
            "_id": 7504,
            "סמל_ישוב": 450,
            "שם_ישוב": "בארות יצחק ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אולפן"
        },
        {
            "_id": 7505,
            "סמל_ישוב": 450,
            "שם_ישוב": "בארות יצחק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בארות יצחק"
        },
        {
            "_id": 7506,
            "סמל_ישוב": 697,
            "שם_ישוב": "בארותיים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בארותיים"
        },
        {
            "_id": 7507,
            "סמל_ישוב": 697,
            "שם_ישוב": "בארותיים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האפיק"
        },
        {
            "_id": 7508,
            "סמל_ישוב": 697,
            "שם_ישוב": "בארותיים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "היובלים"
        },
        {
            "_id": 7509,
            "סמל_ישוב": 697,
            "שם_ישוב": "בארותיים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הנחלים"
        },
        {
            "_id": 7510,
            "סמל_ישוב": 697,
            "שם_ישוב": "בארותיים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הפלג"
        },
        {
            "_id": 7511,
            "סמל_ישוב": 399,
            "שם_ישוב": "בארי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 7512,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בוסתן הגליל"
        },
        {
            "_id": 7513,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך הגנים"
        },
        {
            "_id": 7514,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הזית"
        },
        {
            "_id": 7515,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 7516,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך הנחל"
        },
        {
            "_id": 7517,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "כפר הנוער נירים"
        },
        {
            "_id": 7518,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שביל האלמוג"
        },
        {
            "_id": 7519,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שביל הדוגית"
        },
        {
            "_id": 7520,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שביל החבצלת"
        },
        {
            "_id": 7521,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "שביל החוף"
        },
        {
            "_id": 7522,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שביל המפרש"
        },
        {
            "_id": 7523,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שביל העוגן"
        },
        {
            "_id": 7524,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שביל השונית"
        },
        {
            "_id": 7525,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "שביל השחף"
        },
        {
            "_id": 7526,
            "סמל_ישוב": 559,
            "שם_ישוב": "בוסתן הגליל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שד האקליפטוס"
        },
        {
            "_id": 7527,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אבו בכר"
        },
        {
            "_id": 7528,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אל-סופסאף"
        },
        {
            "_id": 7529,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 178,
            "שם_רחוב": "אלאבטאל"
        },
        {
            "_id": 7530,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלאופוק"
        },
        {
            "_id": 7531,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלאח'וה"
        },
        {
            "_id": 7532,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלאמאל"
        },
        {
            "_id": 7533,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלבוראק"
        },
        {
            "_id": 7534,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלבטוף"
        },
        {
            "_id": 7535,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 7536,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלבלאבל"
        },
        {
            "_id": 7537,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלג'אמע"
        },
        {
            "_id": 7538,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 183,
            "שם_רחוב": "אלג'בל"
        },
        {
            "_id": 7539,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלג'ורן"
        },
        {
            "_id": 7540,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אלג'יעה"
        },
        {
            "_id": 7541,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 180,
            "שם_רחוב": "אלג'ליל"
        },
        {
            "_id": 7542,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלדחנון"
        },
        {
            "_id": 7543,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלהודא"
        },
        {
            "_id": 7544,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלופאא"
        },
        {
            "_id": 7545,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אלזהור"
        },
        {
            "_id": 7546,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלזהראא"
        },
        {
            "_id": 7547,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 7548,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אלח'ולאפאא"
        },
        {
            "_id": 7549,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אלח'יאל"
        },
        {
            "_id": 7550,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלח'נסאא"
        },
        {
            "_id": 7551,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלח'רבה"
        },
        {
            "_id": 7552,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 7553,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אלחורייה"
        },
        {
            "_id": 7554,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 7555,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אלירמוך"
        },
        {
            "_id": 7556,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלכורום"
        },
        {
            "_id": 7557,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלכפה"
        },
        {
            "_id": 7558,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אלכראמה"
        },
        {
            "_id": 7559,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלכרם"
        },
        {
            "_id": 7560,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אללוז"
        },
        {
            "_id": 7561,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלמידאן"
        },
        {
            "_id": 7562,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלמיהבאש"
        },
        {
            "_id": 7563,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלמנזול"
        },
        {
            "_id": 7564,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלמסבע'ה"
        },
        {
            "_id": 7565,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אלמעאלי"
        },
        {
            "_id": 7566,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלמעסרה"
        },
        {
            "_id": 7567,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אלמראח"
        },
        {
            "_id": 7568,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלמרוה"
        },
        {
            "_id": 7569,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אלנג'אח"
        },
        {
            "_id": 7570,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 7571,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 7572,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אלסולטאן"
        },
        {
            "_id": 7573,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 7574,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלסנדיאן"
        },
        {
            "_id": 7575,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלע'זאלין"
        },
        {
            "_id": 7576,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלע'רוב"
        },
        {
            "_id": 7577,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלעביר"
        },
        {
            "_id": 7578,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 7579,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אלקאדסייה"
        },
        {
            "_id": 7580,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלקודס"
        },
        {
            "_id": 7581,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלקלעה"
        },
        {
            "_id": 7582,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלרבאעין"
        },
        {
            "_id": 7583,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלרביע"
        },
        {
            "_id": 7584,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלרדואן"
        },
        {
            "_id": 7585,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלרחמה"
        },
        {
            "_id": 7586,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 7587,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלרשיד"
        },
        {
            "_id": 7588,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אלשאם"
        },
        {
            "_id": 7589,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלשאפעי"
        },
        {
            "_id": 7590,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלשורוק"
        },
        {
            "_id": 7591,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלשיח'"
        },
        {
            "_id": 7592,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אלתוחיד"
        },
        {
            "_id": 7593,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלתין"
        },
        {
            "_id": 7594,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אלתנור"
        },
        {
            "_id": 7595,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלתקוא"
        },
        {
            "_id": 7596,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בועיינה-נוג'ידאת"
        },
        {
            "_id": 7597,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "בועינה"
        },
        {
            "_id": 7598,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 111,
            "שם_רחוב": "בילאל"
        },
        {
            "_id": 7599,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 173,
            "שם_רחוב": "דרב אלראס"
        },
        {
            "_id": 7600,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 163,
            "שם_רחוב": "ואדי אלברכה"
        },
        {
            "_id": 7601,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 118,
            "שם_רחוב": "ואדי ע'וראב"
        },
        {
            "_id": 7602,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 7603,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 110,
            "שם_רחוב": "טארק בן זיאד"
        },
        {
            "_id": 7604,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 112,
            "שם_רחוב": "מועאד' בן ג'בל"
        },
        {
            "_id": 7605,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 128,
            "שם_רחוב": "מסלח'ית"
        },
        {
            "_id": 7606,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 177,
            "שם_רחוב": "נג'ד"
        },
        {
            "_id": 7607,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 101,
            "שם_רחוב": "נוג'ידאת"
        },
        {
            "_id": 7608,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 135,
            "שם_רחוב": "סבאח אלח'יר"
        },
        {
            "_id": 7609,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 109,
            "שם_רחוב": "סלאח אלדין"
        },
        {
            "_id": 7610,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 170,
            "שם_רחוב": "עומר בן אלח'טאב"
        },
        {
            "_id": 7611,
            "סמל_ישוב": 482,
            "שם_ישוב": "בועיינה-נוג'ידאת ",
            "סמל_רחוב": 125,
            "שם_רחוב": "קורטובה"
        },
        {
            "_id": 7612,
            "סמל_ישוב": 4001,
            "שם_ישוב": "בוקעאתא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בוקעאתא"
        },
        {
            "_id": 7613,
            "סמל_ישוב": 698,
            "שם_ישוב": "בורגתה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בורגתה"
        },
        {
            "_id": 7614,
            "סמל_ישוב": 698,
            "שם_ישוב": "בורגתה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 7615,
            "סמל_ישוב": 698,
            "שם_ישוב": "בורגתה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 7616,
            "סמל_ישוב": 698,
            "שם_ישוב": "בורגתה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 7617,
            "סמל_ישוב": 698,
            "שם_ישוב": "בורגתה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 7618,
            "סמל_ישוב": 698,
            "שם_ישוב": "בורגתה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 7619,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 7620,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 7621,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 7622,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 7623,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלמוגן"
        },
        {
            "_id": 7624,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 7625,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 7626,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בחן"
        },
        {
            "_id": 7627,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 116,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 7628,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 7629,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 7630,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 114,
            "שם_רחוב": "זית"
        },
        {
            "_id": 7631,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "יד חנה מאוחד"
        },
        {
            "_id": 7632,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 7633,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 7634,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 118,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 7635,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 7636,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 7637,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 115,
            "שם_רחוב": "שקמה"
        },
        {
            "_id": 7638,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 117,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 7639,
            "סמל_ישוב": 2043,
            "שם_ישוב": "בחן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 7640,
            "סמל_ישוב": 762,
            "שם_ישוב": "בטחה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בטחה"
        },
        {
            "_id": 7641,
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ביצרון"
        },
        {
            "_id": 7642,
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך הפיקוסים"
        },
        {
            "_id": 7643,
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הותיקים"
        },
        {
            "_id": 7644,
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "החיילים"
        },
        {
            "_id": 7645,
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 7646,
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הנוריות"
        },
        {
            "_id": 7647,
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 7648,
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "העולים"
        },
        {
            "_id": 7649,
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "העקרונים"
        },
        {
            "_id": 7650,
            "סמל_ישוב": 234,
            "שם_ישוב": "ביצרון ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ החיילים"
        },
        {
            "_id": 7651,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 105,
            "שם_רחוב": "א-זהרה"
        },
        {
            "_id": 7652,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 161,
            "שם_רחוב": "א-זיתון"
        },
        {
            "_id": 7653,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 162,
            "שם_רחוב": "א-זעתר"
        },
        {
            "_id": 7654,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 302,
            "שם_רחוב": "א-טבראני"
        },
        {
            "_id": 7655,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 204,
            "שם_רחוב": "א-טקה"
        },
        {
            "_id": 7656,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 141,
            "שם_רחוב": "א-לוז"
        },
        {
            "_id": 7657,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 339,
            "שם_רחוב": "א-לימון"
        },
        {
            "_id": 7658,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 121,
            "שם_רחוב": "א-נארג'ס"
        },
        {
            "_id": 7659,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 153,
            "שם_רחוב": "א-נג'אח"
        },
        {
            "_id": 7660,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 107,
            "שם_רחוב": "א-נועמאן"
        },
        {
            "_id": 7661,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 315,
            "שם_רחוב": "א-נור"
        },
        {
            "_id": 7662,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 139,
            "שם_רחוב": "א-נח'יל"
        },
        {
            "_id": 7663,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 160,
            "שם_רחוב": "א-נענע"
        },
        {
            "_id": 7664,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 322,
            "שם_רחוב": "א-ס'נובר"
        },
        {
            "_id": 7665,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 164,
            "שם_רחוב": "א-סדרה"
        },
        {
            "_id": 7666,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 152,
            "שם_רחוב": "א-סינדייאן"
        },
        {
            "_id": 7667,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 305,
            "שם_רחוב": "א-סלאם"
        },
        {
            "_id": 7668,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 352,
            "שם_רחוב": "א-סנאבל"
        },
        {
            "_id": 7669,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 183,
            "שם_רחוב": "א-סרג'"
        },
        {
            "_id": 7670,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 336,
            "שם_רחוב": "א-סרו"
        },
        {
            "_id": 7671,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 131,
            "שם_רחוב": "א-סריס"
        },
        {
            "_id": 7672,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 166,
            "שם_רחוב": "א-ראס"
        },
        {
            "_id": 7673,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 103,
            "שם_רחוב": "א-רביע"
        },
        {
            "_id": 7674,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 316,
            "שם_רחוב": "א-רוודאת"
        },
        {
            "_id": 7675,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 317,
            "שם_רחוב": "א-רוויכאת"
        },
        {
            "_id": 7676,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 200,
            "שם_רחוב": "א-רומאן"
        },
        {
            "_id": 7677,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 123,
            "שם_רחוב": "א-ריחאן"
        },
        {
            "_id": 7678,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 342,
            "שם_רחוב": "א-שורוק"
        },
        {
            "_id": 7679,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 341,
            "שם_רחוב": "א-תות"
        },
        {
            "_id": 7680,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 113,
            "שם_רחוב": "א-תין"
        },
        {
            "_id": 7681,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 321,
            "שם_רחוב": "א-תלה"
        },
        {
            "_id": 7682,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אכליל אל-ג'בל"
        },
        {
            "_id": 7683,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אל-אנוואר"
        },
        {
            "_id": 7684,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 306,
            "שם_רחוב": "אל-ארז"
        },
        {
            "_id": 7685,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אל-בוטום"
        },
        {
            "_id": 7686,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אל-בוסתאן"
        },
        {
            "_id": 7687,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 100,
            "שם_רחוב": "אל-ביר"
        },
        {
            "_id": 7688,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 320,
            "שם_רחוב": "אל-בלוט"
        },
        {
            "_id": 7689,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 354,
            "שם_רחוב": "אל-ג'וז"
        },
        {
            "_id": 7690,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אל-הודא"
        },
        {
            "_id": 7691,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אל-ואדי"
        },
        {
            "_id": 7692,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 330,
            "שם_רחוב": "אל-ואחה"
        },
        {
            "_id": 7693,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 337,
            "שם_רחוב": "אל-ורוד"
        },
        {
            "_id": 7694,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אל-ח'ירבה"
        },
        {
            "_id": 7695,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אל-ח'רוב"
        },
        {
            "_id": 7696,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אל-חאווז"
        },
        {
            "_id": 7697,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 301,
            "שם_רחוב": "אל-חאכורה"
        },
        {
            "_id": 7698,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אל-חסנייה"
        },
        {
            "_id": 7699,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אל-יאסמין"
        },
        {
            "_id": 7700,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אל-כסארה"
        },
        {
            "_id": 7701,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 348,
            "שם_רחוב": "אל-כרם"
        },
        {
            "_id": 7702,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אל-מג'ליס"
        },
        {
            "_id": 7703,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אל-מדארס"
        },
        {
            "_id": 7704,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אל-מוזירה"
        },
        {
            "_id": 7705,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אל-מוכמאן"
        },
        {
            "_id": 7706,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אל-מונתזה"
        },
        {
            "_id": 7707,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 319,
            "שם_רחוב": "אל-מטלה"
        },
        {
            "_id": 7708,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אל-מידאן"
        },
        {
            "_id": 7709,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אל-מלעב"
        },
        {
            "_id": 7710,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אל-מנס'ור"
        },
        {
            "_id": 7711,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אל-מסאעיד"
        },
        {
            "_id": 7712,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אל-מרג'"
        },
        {
            "_id": 7713,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אל-מרכז"
        },
        {
            "_id": 7714,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 351,
            "שם_רחוב": "אל-עוואבדה"
        },
        {
            "_id": 7715,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אל-עין"
        },
        {
            "_id": 7716,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אל-עקד"
        },
        {
            "_id": 7717,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אל-פורוסיה"
        },
        {
            "_id": 7718,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ביר אל-מכסור"
        },
        {
            "_id": 7719,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 134,
            "שם_רחוב": "עיכסאן"
        },
        {
            "_id": 7720,
            "סמל_ישוב": 998,
            "שם_ישוב": "ביר אל-מכסור ",
            "סמל_רחוב": 203,
            "שם_רחוב": "עקילה"
        },
        {
            "_id": 7721,
            "סמל_ישוב": 1348,
            "שם_ישוב": "ביר הדאג' ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ביר הדאג'"
        },
        {
            "_id": 7722,
            "סמל_ישוב": 368,
            "שם_ישוב": "ביריה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ביריה"
        },
        {
            "_id": 7723,
            "סמל_ישוב": 368,
            "שם_ישוב": "ביריה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "כהן איציק"
        },
        {
            "_id": 7724,
            "סמל_ישוב": 368,
            "שם_ישוב": "ביריה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מדר חיים"
        },
        {
            "_id": 7725,
            "סמל_ישוב": 317,
            "שם_ישוב": "בית אורן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית אורן"
        },
        {
            "_id": 7726,
            "סמל_ישוב": 317,
            "שם_ישוב": "בית אורן ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "בית סוהר דמון"
        },
        {
            "_id": 7727,
            "סמל_ישוב": 317,
            "שם_ישוב": "בית אורן ",
            "סמל_רחוב": 7002,
            "שם_רחוב": "חוות בית אורן"
        },
        {
            "_id": 7728,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אבשלום"
        },
        {
            "_id": 7729,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אדיר זיק"
        },
        {
            "_id": 7730,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אופירה"
        },
        {
            "_id": 7731,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 7732,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אלעזר"
        },
        {
            "_id": 7733,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אסא המלך"
        },
        {
            "_id": 7734,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אעלה בתמר"
        },
        {
            "_id": 7735,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אפרים"
        },
        {
            "_id": 7736,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 195,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 7737,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 174,
            "שם_רחוב": "ארץ חמדה"
        },
        {
            "_id": 7738,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 199,
            "שם_רחוב": "בועז"
        },
        {
            "_id": 7739,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 7740,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית אל"
        },
        {
            "_id": 7741,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "בית אל ב'"
        },
        {
            "_id": 7742,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 212,
            "שם_רחוב": "בנימין"
        },
        {
            "_id": 7743,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 136,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 7744,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 124,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 7745,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 144,
            "שם_רחוב": "גינת אגוז"
        },
        {
            "_id": 7746,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 166,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 7747,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דקלה"
        },
        {
            "_id": 7748,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 177,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 7749,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 132,
            "שם_רחוב": "דרך ביטחון"
        },
        {
            "_id": 7750,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 200,
            "שם_רחוב": "דרך בית אל"
        },
        {
            "_id": 7751,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 215,
            "שם_רחוב": "דרך האמהות"
        },
        {
            "_id": 7752,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 153,
            "שם_רחוב": "דרך החיים"
        },
        {
            "_id": 7753,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 140,
            "שם_רחוב": "דרך החלוצים"
        },
        {
            "_id": 7754,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 216,
            "שם_רחוב": "דרך העמק"
        },
        {
            "_id": 7755,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 208,
            "שם_רחוב": "דרך שבטי ישראל"
        },
        {
            "_id": 7756,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 160,
            "שם_רחוב": "דרך שבעת המינים"
        },
        {
            "_id": 7757,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 155,
            "שם_רחוב": "האולפנא"
        },
        {
            "_id": 7758,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 187,
            "שם_רחוב": "האחוזה"
        },
        {
            "_id": 7759,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 197,
            "שם_רחוב": "הגנה"
        },
        {
            "_id": 7760,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 7761,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 146,
            "שם_רחוב": "המפעל"
        },
        {
            "_id": 7762,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 157,
            "שם_רחוב": "הנזיר"
        },
        {
            "_id": 7763,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 176,
            "שם_רחוב": "הנציב"
        },
        {
            "_id": 7764,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 190,
            "שם_רחוב": "הרב מרדכי אליהו"
        },
        {
            "_id": 7765,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 189,
            "שם_רחוב": "הרב שפירא"
        },
        {
            "_id": 7766,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 179,
            "שם_רחוב": "התחיה"
        },
        {
            "_id": 7767,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 209,
            "שם_רחוב": "זבולון"
        },
        {
            "_id": 7768,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 165,
            "שם_רחוב": "זית"
        },
        {
            "_id": 7769,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 172,
            "שם_רחוב": "חזקיהו המלך"
        },
        {
            "_id": 7770,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 156,
            "שם_רחוב": "חיבת ציון"
        },
        {
            "_id": 7771,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 161,
            "שם_רחוב": "חיטה"
        },
        {
            "_id": 7772,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 180,
            "שם_רחוב": "חלמיש"
        },
        {
            "_id": 7773,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 120,
            "שם_רחוב": "חצר אדר"
        },
        {
            "_id": 7774,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "חרובית"
        },
        {
            "_id": 7775,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 181,
            "שם_רחוב": "יהודה המכבי"
        },
        {
            "_id": 7776,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 170,
            "שם_רחוב": "יהושפט"
        },
        {
            "_id": 7777,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 126,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 7778,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 185,
            "שם_רחוב": "יוחנן"
        },
        {
            "_id": 7779,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 183,
            "שם_רחוב": "יונתן"
        },
        {
            "_id": 7780,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 171,
            "שם_רחוב": "יותם"
        },
        {
            "_id": 7781,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 198,
            "שם_רחוב": "יכין"
        },
        {
            "_id": 7782,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ימית"
        },
        {
            "_id": 7783,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 141,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 7784,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 142,
            "שם_רחוב": "כ' בחשון"
        },
        {
            "_id": 7785,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 173,
            "שם_רחוב": "כינור דוד"
        },
        {
            "_id": 7786,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כנפי נשרים"
        },
        {
            "_id": 7787,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 214,
            "שם_רחוב": "לאה"
        },
        {
            "_id": 7788,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "לבי במזרח"
        },
        {
            "_id": 7789,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 207,
            "שם_רחוב": "לוי"
        },
        {
            "_id": 7790,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 196,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 7791,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "מבוא אבני החושן"
        },
        {
            "_id": 7792,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 148,
            "שם_רחוב": "מבוא אתרוג"
        },
        {
            "_id": 7793,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 134,
            "שם_רחוב": "מבוא האורנים"
        },
        {
            "_id": 7794,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 147,
            "שם_רחוב": "מבוא הדס"
        },
        {
            "_id": 7795,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 151,
            "שם_רחוב": "מבוא הישיבה"
        },
        {
            "_id": 7796,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 139,
            "שם_רחוב": "מבוא התקומה"
        },
        {
            "_id": 7797,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 127,
            "שם_רחוב": "מבוא ליבנה"
        },
        {
            "_id": 7798,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מבצע שלמה"
        },
        {
            "_id": 7799,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 159,
            "שם_רחוב": "מי מרום"
        },
        {
            "_id": 7800,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 211,
            "שם_רחוב": "מנשה"
        },
        {
            "_id": 7801,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 150,
            "שם_רחוב": "מעגלי הראה"
        },
        {
            "_id": 7802,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 186,
            "שם_רחוב": "מעוז צור"
        },
        {
            "_id": 7803,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 175,
            "שם_רחוב": "מעלה האמונה"
        },
        {
            "_id": 7804,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 218,
            "שם_רחוב": "מעלה הרב אלון"
        },
        {
            "_id": 7805,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 178,
            "שם_רחוב": "מעלה שופר"
        },
        {
            "_id": 7806,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 7807,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 184,
            "שם_רחוב": "מתתיהו"
        },
        {
            "_id": 7808,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 154,
            "שם_רחוב": "נריה"
        },
        {
            "_id": 7809,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 158,
            "שם_רחוב": "סולם יעקב"
        },
        {
            "_id": 7810,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "סופה"
        },
        {
            "_id": 7811,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 125,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 7812,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 135,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 7813,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 145,
            "שם_רחוב": "ערוגות הבושם"
        },
        {
            "_id": 7814,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 128,
            "שם_רחוב": "ערמון"
        },
        {
            "_id": 7815,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "פרי-גן"
        },
        {
            "_id": 7816,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 143,
            "שם_רחוב": "פריאל"
        },
        {
            "_id": 7817,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "קול התור"
        },
        {
            "_id": 7818,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 129,
            "שם_רחוב": "קטלב"
        },
        {
            "_id": 7819,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "קיבוץ גלויות"
        },
        {
            "_id": 7820,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 201,
            "שם_רחוב": "קרית הישיבה"
        },
        {
            "_id": 7821,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 205,
            "שם_רחוב": "ראובן"
        },
        {
            "_id": 7822,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רחל"
        },
        {
            "_id": 7823,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 162,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 7824,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 167,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 7825,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שבות סיני"
        },
        {
            "_id": 7826,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 219,
            "שם_רחוב": "שביל האור"
        },
        {
            "_id": 7827,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 217,
            "שם_רחוב": "שביל האמונה"
        },
        {
            "_id": 7828,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "שדות"
        },
        {
            "_id": 7829,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "שדרות לוז"
        },
        {
            "_id": 7830,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 123,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 7831,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 137,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 7832,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ אבן פינה"
        },
        {
            "_id": 7833,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ בן הודה"
        },
        {
            "_id": 7834,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "שכ גבעת האולפנה"
        },
        {
            "_id": 7835,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ גינות בית אל"
        },
        {
            "_id": 7836,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "שכ דוד"
        },
        {
            "_id": 7837,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ הואדי"
        },
        {
            "_id": 7838,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ נוף"
        },
        {
            "_id": 7839,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ ניצני בית אל"
        },
        {
            "_id": 7840,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ ניצנית"
        },
        {
            "_id": 7841,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ פסגת יעקב"
        },
        {
            "_id": 7842,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ קדם"
        },
        {
            "_id": 7843,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ ראשית"
        },
        {
            "_id": 7844,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכונה א"
        },
        {
            "_id": 7845,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכונה ב"
        },
        {
            "_id": 7846,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 168,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 7847,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 206,
            "שם_רחוב": "שמעון"
        },
        {
            "_id": 7848,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 131,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 7849,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 163,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 7850,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 138,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 7851,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 152,
            "שם_רחוב": "תורה ועבודה"
        },
        {
            "_id": 7852,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "תל חיים"
        },
        {
            "_id": 7853,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 164,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 7854,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 133,
            "שם_רחוב": "תפוח"
        },
        {
            "_id": 7855,
            "סמל_ישוב": 3574,
            "שם_ישוב": "בית אל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 7856,
            "סמל_ישוב": 562,
            "שם_ישוב": "בית אלעזרי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית אלעזרי"
        },
        {
            "_id": 7857,
            "סמל_ישוב": 562,
            "שם_ישוב": "בית אלעזרי ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 7858,
            "סמל_ישוב": 562,
            "שם_ישוב": "בית אלעזרי ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 7859,
            "סמל_ישוב": 562,
            "שם_ישוב": "בית אלעזרי ",
            "סמל_רחוב": 103,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 7860,
            "סמל_ישוב": 562,
            "שם_ישוב": "בית אלעזרי ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 7861,
            "סמל_ישוב": 562,
            "שם_ישוב": "בית אלעזרי ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 7862,
            "סמל_ישוב": 562,
            "שם_ישוב": "בית אלעזרי ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 7863,
            "סמל_ישוב": 95,
            "שם_ישוב": "בית אלפא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אולפן"
        },
        {
            "_id": 7864,
            "סמל_ישוב": 95,
            "שם_ישוב": "בית אלפא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית אלפא"
        },
        {
            "_id": 7865,
            "סמל_ישוב": 95,
            "שם_ישוב": "בית אלפא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מרכז קליטה גלבוע"
        },
        {
            "_id": 7866,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אחירותם"
        },
        {
            "_id": 7867,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "איבן גבירול"
        },
        {
            "_id": 7868,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 7869,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אלונים"
        },
        {
            "_id": 7870,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלתרמן נתן"
        },
        {
            "_id": 7871,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אריאל מאיר"
        },
        {
            "_id": 7872,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אריק איינשטיין"
        },
        {
            "_id": 7873,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אשחר"
        },
        {
            "_id": 7874,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית אריה"
        },
        {
            "_id": 7875,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גבעת הבריכה"
        },
        {
            "_id": 7876,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 7877,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דבורנית"
        },
        {
            "_id": 7878,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 7879,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 7880,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 7881,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 7882,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 3107,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 7883,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 7884,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 3103,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 7885,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הברושים"
        },
        {
            "_id": 7886,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "הגדי"
        },
        {
            "_id": 7887,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 7888,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 3102,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 7889,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 7890,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "הלוי יהודה"
        },
        {
            "_id": 7891,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "העופר"
        },
        {
            "_id": 7892,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "הרב שבזי"
        },
        {
            "_id": 7893,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 3106,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 7894,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 3101,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 7895,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "השירה העברית"
        },
        {
            "_id": 7896,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 3105,
            "שם_רחוב": "השקד עופרים"
        },
        {
            "_id": 7897,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 7898,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 7899,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "חוטמית"
        },
        {
            "_id": 7900,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "חזה עופרה"
        },
        {
            "_id": 7901,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "חיננית"
        },
        {
            "_id": 7902,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 7903,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 7904,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "טיכו אנה"
        },
        {
            "_id": 7905,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "כוכבית"
        },
        {
            "_id": 7906,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 7907,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 7908,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "כרמים"
        },
        {
            "_id": 7909,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 7910,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "לביא אריק"
        },
        {
            "_id": 7911,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 7912,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 3104,
            "שם_רחוב": "מעלה הזית"
        },
        {
            "_id": 7913,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "מעלה שילה"
        },
        {
            "_id": 7914,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "מצפה ים"
        },
        {
            "_id": 7915,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 7916,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "מרק אסיה"
        },
        {
            "_id": 7917,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ניסנית"
        },
        {
            "_id": 7918,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "נירית"
        },
        {
            "_id": 7919,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "עופרים"
        },
        {
            "_id": 7920,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 7921,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 7922,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "פן אלכסנדר"
        },
        {
            "_id": 7923,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 7924,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "פריחת השקד"
        },
        {
            "_id": 7925,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "צורית"
        },
        {
            "_id": 7926,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "צלף"
        },
        {
            "_id": 7927,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 7928,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "קטלב"
        },
        {
            "_id": 7929,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "קיסוסית"
        },
        {
            "_id": 7930,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "רחל המשוררת"
        },
        {
            "_id": 7931,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 7932,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "שד הניצנים"
        },
        {
            "_id": 7933,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "שטרן יאיר"
        },
        {
            "_id": 7934,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שיטים"
        },
        {
            "_id": 7935,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "שמר נעמי"
        },
        {
            "_id": 7936,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 3108,
            "שם_רחוב": "שקמה"
        },
        {
            "_id": 7937,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "שריג"
        },
        {
            "_id": 7938,
            "סמל_ישוב": 3652,
            "שם_ישוב": "בית אריה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "תירוש"
        },
        {
            "_id": 7939,
            "סמל_ישוב": 1076,
            "שם_ישוב": "בית ברל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית ברל"
        },
        {
            "_id": 7940,
            "סמל_ישוב": 1076,
            "שם_ישוב": "בית ברל ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "קלמניה"
        },
        {
            "_id": 7941,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 387,
            "שם_רחוב": "אלאוח'וה"
        },
        {
            "_id": 7942,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 370,
            "שם_רחוב": "אלאחלאם"
        },
        {
            "_id": 7943,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 326,
            "שם_רחוב": "אלאמיר אלסייד"
        },
        {
            "_id": 7944,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 276,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 7945,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 336,
            "שם_רחוב": "אלאנביא"
        },
        {
            "_id": 7946,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 272,
            "שם_רחוב": "אלאס"
        },
        {
            "_id": 7947,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 356,
            "שם_רחוב": "אלאסד"
        },
        {
            "_id": 7948,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אלבאבור"
        },
        {
            "_id": 7949,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 297,
            "שם_רחוב": "אלבאשיק"
        },
        {
            "_id": 7950,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 183,
            "שם_רחוב": "אלבוטום"
        },
        {
            "_id": 7951,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 377,
            "שם_רחוב": "אלבורטוקאל"
        },
        {
            "_id": 7952,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 304,
            "שם_רחוב": "אלבט"
        },
        {
            "_id": 7953,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלביאדה"
        },
        {
            "_id": 7954,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אלביאדי"
        },
        {
            "_id": 7955,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אלבירק"
        },
        {
            "_id": 7956,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אלבירקי"
        },
        {
            "_id": 7957,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אלבית אלעתיק"
        },
        {
            "_id": 7958,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אלבלאן"
        },
        {
            "_id": 7959,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 280,
            "שם_רחוב": "אלבלוט"
        },
        {
            "_id": 7960,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 322,
            "שם_רחוב": "אלג'וז"
        },
        {
            "_id": 7961,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אלג'רמק"
        },
        {
            "_id": 7962,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 373,
            "שם_רחוב": "אלד'הר"
        },
        {
            "_id": 7963,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 268,
            "שם_רחוב": "אלדואר"
        },
        {
            "_id": 7964,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלדוירי"
        },
        {
            "_id": 7965,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אלדוראק"
        },
        {
            "_id": 7966,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 380,
            "שם_רחוב": "אלדורה"
        },
        {
            "_id": 7967,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "אלדידבה"
        },
        {
            "_id": 7968,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלדראג'"
        },
        {
            "_id": 7969,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 334,
            "שם_רחוב": "אלדרג'ה"
        },
        {
            "_id": 7970,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלהודהוד"
        },
        {
            "_id": 7971,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 341,
            "שם_רחוב": "אלהיל"
        },
        {
            "_id": 7972,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלהנבל"
        },
        {
            "_id": 7973,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 281,
            "שם_רחוב": "אלוארד אלג'ורי"
        },
        {
            "_id": 7974,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 338,
            "שם_רחוב": "אלוורוד"
        },
        {
            "_id": 7975,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אלועל"
        },
        {
            "_id": 7976,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלזאבוד"
        },
        {
            "_id": 7977,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 286,
            "שם_רחוב": "אלזאן"
        },
        {
            "_id": 7978,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אלזאתוני"
        },
        {
            "_id": 7979,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 262,
            "שם_רחוב": "אלזוהור"
        },
        {
            "_id": 7980,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 264,
            "שם_רחוב": "אלזית"
        },
        {
            "_id": 7981,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 294,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 7982,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 344,
            "שם_רחוב": "אלזנבק"
        },
        {
            "_id": 7983,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 366,
            "שם_רחוב": "אלזערור"
        },
        {
            "_id": 7984,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אלזעתר"
        },
        {
            "_id": 7985,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלזריקי"
        },
        {
            "_id": 7986,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 379,
            "שם_רחוב": "אלח'יט"
        },
        {
            "_id": 7987,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 384,
            "שם_רחוב": "אלח'ס"
        },
        {
            "_id": 7988,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 302,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 7989,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 374,
            "שם_רחוב": "אלח'תאף"
        },
        {
            "_id": 7990,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 277,
            "שם_רחוב": "אלחבק"
        },
        {
            "_id": 7991,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 307,
            "שם_רחוב": "אלחג'ל"
        },
        {
            "_id": 7992,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלחדארי"
        },
        {
            "_id": 7993,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלחופחאף"
        },
        {
            "_id": 7994,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 391,
            "שם_רחוב": "אלחינדאג'"
        },
        {
            "_id": 7995,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 319,
            "שם_רחוב": "אלחלוה"
        },
        {
            "_id": 7996,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 197,
            "שם_רחוב": "אלחמאם"
        },
        {
            "_id": 7997,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 363,
            "שם_רחוב": "אלחמל"
        },
        {
            "_id": 7998,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 354,
            "שם_רחוב": "אלחסון"
        },
        {
            "_id": 7999,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלטואלי"
        },
        {
            "_id": 8000,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 330,
            "שם_רחוב": "אלטיון"
        },
        {
            "_id": 8001,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אלימאם"
        },
        {
            "_id": 8002,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלינסון"
        },
        {
            "_id": 8003,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 288,
            "שם_רחוב": "אלכאפור"
        },
        {
            "_id": 8004,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 397,
            "שם_רחוב": "אלכיווי"
        },
        {
            "_id": 8005,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אלכינא"
        },
        {
            "_id": 8006,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלכמון"
        },
        {
            "_id": 8007,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 313,
            "שם_רחוב": "אלכנאר"
        },
        {
            "_id": 8008,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 292,
            "שם_רחוב": "אלכרז"
        },
        {
            "_id": 8009,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלכרמה"
        },
        {
            "_id": 8010,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלכרק"
        },
        {
            "_id": 8011,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 395,
            "שם_רחוב": "אללוז"
        },
        {
            "_id": 8012,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אללוטוס"
        },
        {
            "_id": 8013,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 283,
            "שם_רחוב": "אללומאם"
        },
        {
            "_id": 8014,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אללימון"
        },
        {
            "_id": 8015,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אללקלק"
        },
        {
            "_id": 8016,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלמדרסה"
        },
        {
            "_id": 8017,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלמהא"
        },
        {
            "_id": 8018,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 259,
            "שם_רחוב": "אלמוברה"
        },
        {
            "_id": 8019,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אלמוסתראח"
        },
        {
            "_id": 8020,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אלמחלב"
        },
        {
            "_id": 8021,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 195,
            "שם_רחוב": "אלמטל"
        },
        {
            "_id": 8022,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אלמידאן"
        },
        {
            "_id": 8023,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלמיס"
        },
        {
            "_id": 8024,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 343,
            "שם_רחוב": "אלמיסק"
        },
        {
            "_id": 8025,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אלמל"
        },
        {
            "_id": 8026,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלמלעב"
        },
        {
            "_id": 8027,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 196,
            "שם_רחוב": "אלמנארה"
        },
        {
            "_id": 8028,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 267,
            "שם_רחוב": "אלמנבר"
        },
        {
            "_id": 8029,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 194,
            "שם_רחוב": "אלמנטרה"
        },
        {
            "_id": 8030,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "אלמסירב"
        },
        {
            "_id": 8031,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 315,
            "שם_רחוב": "אלמספה"
        },
        {
            "_id": 8032,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 316,
            "שם_רחוב": "אלמע'ארה"
        },
        {
            "_id": 8033,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 295,
            "שם_רחוב": "אלמעסרה"
        },
        {
            "_id": 8034,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 274,
            "שם_רחוב": "אלמקלע"
        },
        {
            "_id": 8035,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלמרואני"
        },
        {
            "_id": 8036,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אלמרשקה"
        },
        {
            "_id": 8037,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אלמשמש"
        },
        {
            "_id": 8038,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 320,
            "שם_רחוב": "אלנוקרה"
        },
        {
            "_id": 8039,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלנורס"
        },
        {
            "_id": 8040,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 8041,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 332,
            "שם_רחוב": "אלנימר"
        },
        {
            "_id": 8042,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "אלנסיבה"
        },
        {
            "_id": 8043,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אלנסר"
        },
        {
            "_id": 8044,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 362,
            "שם_רחוב": "אלנעאמה"
        },
        {
            "_id": 8045,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלנענע"
        },
        {
            "_id": 8046,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלנעסה"
        },
        {
            "_id": 8047,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 369,
            "שם_רחוב": "אלנרגיס"
        },
        {
            "_id": 8048,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 282,
            "שם_רחוב": "אלסאוסן"
        },
        {
            "_id": 8049,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 388,
            "שם_רחוב": "אלסבע"
        },
        {
            "_id": 8050,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אלסבר"
        },
        {
            "_id": 8051,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 360,
            "שם_רחוב": "אלסדיר"
        },
        {
            "_id": 8052,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "אלסוואנה"
        },
        {
            "_id": 8053,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 365,
            "שם_רחוב": "אלסוויד"
        },
        {
            "_id": 8054,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אלסולם"
        },
        {
            "_id": 8055,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 371,
            "שם_רחוב": "אלסומאק"
        },
        {
            "_id": 8056,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 305,
            "שם_רחוב": "אלסומן"
        },
        {
            "_id": 8057,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אלסופסאף"
        },
        {
            "_id": 8058,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אלסלוא"
        },
        {
            "_id": 8059,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלסמרמר"
        },
        {
            "_id": 8060,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 321,
            "שם_רחוב": "אלסנאבל"
        },
        {
            "_id": 8061,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אלסנאפרה"
        },
        {
            "_id": 8062,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אלסנובר"
        },
        {
            "_id": 8063,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלסנונו"
        },
        {
            "_id": 8064,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 389,
            "שם_רחוב": "אלסעבניה"
        },
        {
            "_id": 8065,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6715,
            "שם_רחוב": "אלספאיה ועין לג'רון"
        },
        {
            "_id": 8066,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 236,
            "שם_רחוב": "אלסרוה"
        },
        {
            "_id": 8067,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלסריס"
        },
        {
            "_id": 8068,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 229,
            "שם_רחוב": "אלע'אר"
        },
        {
            "_id": 8069,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלע'ורדא"
        },
        {
            "_id": 8070,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 386,
            "שם_רחוב": "אלע'זאל"
        },
        {
            "_id": 8071,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 299,
            "שם_רחוב": "אלע'טאס"
        },
        {
            "_id": 8072,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 285,
            "שם_רחוב": "אלעבהר"
        },
        {
            "_id": 8073,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אלעוטרה"
        },
        {
            "_id": 8074,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 284,
            "שם_רחוב": "אלעוינה"
        },
        {
            "_id": 8075,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלעוליק"
        },
        {
            "_id": 8076,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אלעוסג'"
        },
        {
            "_id": 8077,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 312,
            "שם_רחוב": "אלעזמיה"
        },
        {
            "_id": 8078,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אלעלם"
        },
        {
            "_id": 8079,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלעמשקה"
        },
        {
            "_id": 8080,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 253,
            "שם_רחוב": "אלענב"
        },
        {
            "_id": 8081,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 333,
            "שם_רחוב": "אלעסל"
        },
        {
            "_id": 8082,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 296,
            "שם_רחוב": "אלעקאב"
        },
        {
            "_id": 8083,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלעקבאת"
        },
        {
            "_id": 8084,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלערישה"
        },
        {
            "_id": 8085,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 273,
            "שם_רחוב": "אלערער"
        },
        {
            "_id": 8086,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 368,
            "שם_רחוב": "אלפהד"
        },
        {
            "_id": 8087,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "אלפוקרה"
        },
        {
            "_id": 8088,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלפיג'ין"
        },
        {
            "_id": 8089,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 351,
            "שם_רחוב": "אלפרס"
        },
        {
            "_id": 8090,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 289,
            "שם_רחוב": "אלקאתל"
        },
        {
            "_id": 8091,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 396,
            "שם_רחוב": "אלקבריש"
        },
        {
            "_id": 8092,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 392,
            "שם_רחוב": "אלקדח"
        },
        {
            "_id": 8093,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "אלקובע"
        },
        {
            "_id": 8094,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 337,
            "שם_רחוב": "אלקורונפול"
        },
        {
            "_id": 8095,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלקורטאס"
        },
        {
            "_id": 8096,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 331,
            "שם_רחוב": "אלקותון"
        },
        {
            "_id": 8097,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלקירקיס"
        },
        {
            "_id": 8098,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 372,
            "שם_רחוב": "אלקמח"
        },
        {
            "_id": 8099,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 367,
            "שם_רחוב": "אלקנדול"
        },
        {
            "_id": 8100,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אלקסב"
        },
        {
            "_id": 8101,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 317,
            "שם_רחוב": "אלקעדה"
        },
        {
            "_id": 8102,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלקרקשאני"
        },
        {
            "_id": 8103,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלראיה"
        },
        {
            "_id": 8104,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלרביסה"
        },
        {
            "_id": 8105,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6734,
            "שם_רחוב": "אלרויס"
        },
        {
            "_id": 8106,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 293,
            "שם_רחוב": "אלרומאן"
        },
        {
            "_id": 8107,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלריאן"
        },
        {
            "_id": 8108,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 258,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 8109,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 270,
            "שם_רחוב": "אלרים"
        },
        {
            "_id": 8110,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אלרע'בה"
        },
        {
            "_id": 8111,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 298,
            "שם_רחוב": "אלרפראף"
        },
        {
            "_id": 8112,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 238,
            "שם_רחוב": "אלשאחוט"
        },
        {
            "_id": 8113,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלשברק"
        },
        {
            "_id": 8114,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 287,
            "שם_רחוב": "אלשג'רה"
        },
        {
            "_id": 8115,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלשוחמאני"
        },
        {
            "_id": 8116,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלשומר"
        },
        {
            "_id": 8117,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלשחרור"
        },
        {
            "_id": 8118,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלשיח"
        },
        {
            "_id": 8119,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אלשיתוי"
        },
        {
            "_id": 8120,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אלשמיה"
        },
        {
            "_id": 8121,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלתופאחי"
        },
        {
            "_id": 8122,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלתורכמאן"
        },
        {
            "_id": 8123,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלתות"
        },
        {
            "_id": 8124,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 249,
            "שם_רחוב": "אלתין"
        },
        {
            "_id": 8125,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 235,
            "שם_רחוב": "אלתמר"
        },
        {
            "_id": 8126,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 335,
            "שם_רחוב": "אלתנור"
        },
        {
            "_id": 8127,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אמין טריף"
        },
        {
            "_id": 8128,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 339,
            "שם_רחוב": "אנאנאס"
        },
        {
            "_id": 8129,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אסאבע אלערוס"
        },
        {
            "_id": 8130,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 228,
            "שם_רחוב": "באבונג'"
        },
        {
            "_id": 8131,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 174,
            "שם_רחוב": "בהאא אלדין"
        },
        {
            "_id": 8132,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 376,
            "שם_רחוב": "בוזריק"
        },
        {
            "_id": 8133,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 254,
            "שם_רחוב": "בונדוק"
        },
        {
            "_id": 8134,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "בוקראטי"
        },
        {
            "_id": 8135,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 265,
            "שם_רחוב": "ביאדר אלסמאט"
        },
        {
            "_id": 8136,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 324,
            "שם_רחוב": "ביאדר אלע'ירז"
        },
        {
            "_id": 8137,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 353,
            "שם_רחוב": "ביאדר אלרביעה"
        },
        {
            "_id": 8138,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית ג'ן"
        },
        {
            "_id": 8139,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 160,
            "שם_רחוב": "בית ג'ן עין אלאסד"
        },
        {
            "_id": 8140,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 269,
            "שם_רחוב": "ברזא"
        },
        {
            "_id": 8141,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 275,
            "שם_רחוב": "ברכת חאמד"
        },
        {
            "_id": 8142,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 232,
            "שם_רחוב": "ג'וז אלהנד"
        },
        {
            "_id": 8143,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 279,
            "שם_רחוב": "ג'וזת אלטיב"
        },
        {
            "_id": 8144,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 266,
            "שם_רחוב": "ג'ומז"
        },
        {
            "_id": 8145,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 225,
            "שם_רחוב": "ג'זאר"
        },
        {
            "_id": 8146,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 212,
            "שם_רחוב": "ג'עסאס"
        },
        {
            "_id": 8147,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 192,
            "שם_רחוב": "דבוקי"
        },
        {
            "_id": 8148,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 357,
            "שם_רחוב": "דולפין"
        },
        {
            "_id": 8149,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 218,
            "שם_רחוב": "דורדאר"
        },
        {
            "_id": 8150,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 156,
            "שם_רחוב": "דפלה"
        },
        {
            "_id": 8151,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 263,
            "שם_רחוב": "דרב אלתבאן"
        },
        {
            "_id": 8152,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "הגלעין הישן"
        },
        {
            "_id": 8153,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 340,
            "שם_רחוב": "הזאר"
        },
        {
            "_id": 8154,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 241,
            "שם_רחוב": "הליון"
        },
        {
            "_id": 8155,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 226,
            "שם_רחוב": "זיזאפון"
        },
        {
            "_id": 8156,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 189,
            "שם_רחוב": "זנג'ביל"
        },
        {
            "_id": 8157,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 358,
            "שם_רחוב": "זראפה"
        },
        {
            "_id": 8158,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 306,
            "שם_רחוב": "זרזור"
        },
        {
            "_id": 8159,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 301,
            "שם_רחוב": "ח'וזאמה"
        },
        {
            "_id": 8160,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 231,
            "שם_רחוב": "ח'וך"
        },
        {
            "_id": 8161,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 348,
            "שם_רחוב": "ח'ולנג'אן"
        },
        {
            "_id": 8162,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "ח'לת אלג'ב"
        },
        {
            "_id": 8163,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 199,
            "שם_רחוב": "ח'לת אלזע'לול"
        },
        {
            "_id": 8164,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 172,
            "שם_רחוב": "ח'לת אלענק"
        },
        {
            "_id": 8165,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 198,
            "שם_רחוב": "ח'לת למסאק"
        },
        {
            "_id": 8166,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 378,
            "שם_רחוב": "ח'לת עיאד"
        },
        {
            "_id": 8167,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 207,
            "שם_רחוב": "חבק"
        },
        {
            "_id": 8168,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 349,
            "שם_רחוב": "חבת אלברכה"
        },
        {
            "_id": 8169,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6733,
            "שם_רחוב": "חיידר"
        },
        {
            "_id": 8170,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 215,
            "שם_רחוב": "חלואני"
        },
        {
            "_id": 8171,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 205,
            "שם_רחוב": "חמזה דרויש"
        },
        {
            "_id": 8172,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 243,
            "שם_רחוב": "כובת אלעג'וז"
        },
        {
            "_id": 8173,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 220,
            "שם_רחוב": "כולוניא"
        },
        {
            "_id": 8174,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 187,
            "שם_רחוב": "כומטרה"
        },
        {
            "_id": 8175,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 382,
            "שם_רחוב": "כוסא"
        },
        {
            "_id": 8176,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 385,
            "שם_רחוב": "כוסברה"
        },
        {
            "_id": 8177,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 327,
            "שם_רחוב": "כוראת'"
        },
        {
            "_id": 8178,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 309,
            "שם_רחוב": "כורכור"
        },
        {
            "_id": 8179,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 244,
            "שם_רחוב": "כילך"
        },
        {
            "_id": 8180,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כמאל ג'ונבלאט"
        },
        {
            "_id": 8181,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 250,
            "שם_רחוב": "כסטנה"
        },
        {
            "_id": 8182,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 200,
            "שם_רחוב": "כף אלדב"
        },
        {
            "_id": 8183,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 248,
            "שם_רחוב": "כרום אלחסנה"
        },
        {
            "_id": 8184,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 361,
            "שם_רחוב": "כרכדאן"
        },
        {
            "_id": 8185,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 260,
            "שם_רחוב": "כרם סואר"
        },
        {
            "_id": 8186,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 247,
            "שם_רחוב": "כרם עיד"
        },
        {
            "_id": 8187,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "לואיזה"
        },
        {
            "_id": 8188,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 257,
            "שם_רחוב": "לובלאב"
        },
        {
            "_id": 8189,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 223,
            "שם_רחוב": "ליילאק"
        },
        {
            "_id": 8190,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 128,
            "שם_רחוב": "מאלק אלחזין"
        },
        {
            "_id": 8191,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 178,
            "שם_רחוב": "מדאואר"
        },
        {
            "_id": 8192,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 211,
            "שם_רחוב": "מוחמד אבו שקרא"
        },
        {
            "_id": 8193,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 364,
            "שם_רחוב": "מוסתכא"
        },
        {
            "_id": 8194,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 355,
            "שם_רחוב": "מוע'ר אלעיד"
        },
        {
            "_id": 8195,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 182,
            "שם_רחוב": "מיאל אלשמס"
        },
        {
            "_id": 8196,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 383,
            "שם_רחוב": "מלפוף"
        },
        {
            "_id": 8197,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 390,
            "שם_רחוב": "מרג' אלע'וזלאן"
        },
        {
            "_id": 8198,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 291,
            "שם_רחוב": "מרדקוש"
        },
        {
            "_id": 8199,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "משפחת קבלאן"
        },
        {
            "_id": 8200,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "נבעת אלחור"
        },
        {
            "_id": 8201,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 352,
            "שם_רחוב": "נבעת סידו"
        },
        {
            "_id": 8202,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 234,
            "שם_רחוב": "נג'אס"
        },
        {
            "_id": 8203,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 204,
            "שם_רחוב": "נואף ע'זאלי"
        },
        {
            "_id": 8204,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 255,
            "שם_רחוב": "נכתרין"
        },
        {
            "_id": 8205,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 310,
            "שם_רחוב": "נפלה"
        },
        {
            "_id": 8206,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 359,
            "שם_רחוב": "סולטאן אלאטרש"
        },
        {
            "_id": 8207,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 180,
            "שם_רחוב": "סופרג'ל"
        },
        {
            "_id": 8208,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 318,
            "שם_רחוב": "סוק אלד'איע"
        },
        {
            "_id": 8209,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 300,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 8210,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 251,
            "שם_רחוב": "סידרה"
        },
        {
            "_id": 8211,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 237,
            "שם_רחוב": "סימסים"
        },
        {
            "_id": 8212,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 271,
            "שם_רחוב": "סינדיאן"
        },
        {
            "_id": 8213,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 346,
            "שם_רחוב": "סנג'אב"
        },
        {
            "_id": 8214,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 323,
            "שם_רחוב": "סנטארוזה"
        },
        {
            "_id": 8215,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 261,
            "שם_רחוב": "סרטבאני"
        },
        {
            "_id": 8216,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 190,
            "שם_רחוב": "עג'לאן"
        },
        {
            "_id": 8217,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 342,
            "שם_רחוב": "עדס"
        },
        {
            "_id": 8218,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6700,
            "שם_רחוב": "עין אלנום"
        },
        {
            "_id": 8219,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 242,
            "שם_רחוב": "עכוב"
        },
        {
            "_id": 8220,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 214,
            "שם_רחוב": "ענאב"
        },
        {
            "_id": 8221,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 213,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 8222,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 329,
            "שם_רחוב": "עסא אלראעי"
        },
        {
            "_id": 8223,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 118,
            "שם_רחוב": "פירי"
        },
        {
            "_id": 8224,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 381,
            "שם_רחוב": "פלפל"
        },
        {
            "_id": 8225,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 130,
            "שם_רחוב": "פם אלסמקה"
        },
        {
            "_id": 8226,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 240,
            "שם_רחוב": "קורס ענה"
        },
        {
            "_id": 8227,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 206,
            "שם_רחוב": "קחואן"
        },
        {
            "_id": 8228,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 252,
            "שם_רחוב": "קנדול"
        },
        {
            "_id": 8229,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 256,
            "שם_רחוב": "קראסייה"
        },
        {
            "_id": 8230,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 350,
            "שם_רחוב": "קרפה"
        },
        {
            "_id": 8231,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 159,
            "שם_רחוב": "ראס אלעקבה"
        },
        {
            "_id": 8232,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6724,
            "שם_רחוב": "ראס טלעה וחלת עמירה"
        },
        {
            "_id": 8233,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 173,
            "שם_רחוב": "רקאטי"
        },
        {
            "_id": 8234,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 191,
            "שם_רחוב": "רשאד"
        },
        {
            "_id": 8235,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 193,
            "שם_רחוב": "שאהין אלע'זאל"
        },
        {
            "_id": 8236,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 278,
            "שם_רחוב": "שי פארסי"
        },
        {
            "_id": 8237,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "שכ אלע'דיר וסרטבה"
        },
        {
            "_id": 8238,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "שכ חיילים משוחררים"
        },
        {
            "_id": 8239,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 202,
            "שם_רחוב": "שכיב ארסלאן"
        },
        {
            "_id": 8240,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 375,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 8241,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 308,
            "שם_רחוב": "שרקרק"
        },
        {
            "_id": 8242,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 393,
            "שם_רחוב": "תל אלפוח'אר"
        },
        {
            "_id": 8243,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 394,
            "שם_רחוב": "תלאל אלריח"
        },
        {
            "_id": 8244,
            "סמל_ישוב": 480,
            "שם_ישוב": "בית ג'ן ",
            "סמל_רחוב": 239,
            "שם_רחוב": "תנתול"
        },
        {
            "_id": 8245,
            "סמל_ישוב": 619,
            "שם_ישוב": "בית גוברין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית גוברין"
        },
        {
            "_id": 8246,
            "סמל_ישוב": 571,
            "שם_ישוב": "בית גמליאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית גמליאל"
        },
        {
            "_id": 8247,
            "סמל_ישוב": 571,
            "שם_ישוב": "בית גמליאל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 8248,
            "סמל_ישוב": 571,
            "שם_ישוב": "בית גמליאל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ההדר"
        },
        {
            "_id": 8249,
            "סמל_ישוב": 571,
            "שם_ישוב": "בית גמליאל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 8250,
            "סמל_ישוב": 571,
            "שם_ישוב": "בית גמליאל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 8251,
            "סמל_ישוב": 571,
            "שם_ישוב": "בית גמליאל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 8252,
            "סמל_ישוב": 571,
            "שם_ישוב": "בית גמליאל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 8253,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אברהם אבינו"
        },
        {
            "_id": 8254,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 139,
            "שם_רחוב": "איריס"
        },
        {
            "_id": 8255,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 8256,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 141,
            "שם_רחוב": "ארבעת המינים"
        },
        {
            "_id": 8257,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 130,
            "שם_רחוב": "בגין מנחם"
        },
        {
            "_id": 8258,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית דגן"
        },
        {
            "_id": 8259,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "בנה ביתך"
        },
        {
            "_id": 8260,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 156,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 8261,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דרך המג"
        },
        {
            "_id": 8262,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 116,
            "שם_רחוב": "האשכולית"
        },
        {
            "_id": 8263,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 117,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 8264,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 131,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 8265,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 144,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 8266,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 150,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 8267,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 8268,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 143,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 8269,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 8270,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 122,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 8271,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 149,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 8272,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 128,
            "שם_רחוב": "היובל"
        },
        {
            "_id": 8273,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 148,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 8274,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 142,
            "שם_רחוב": "הלולב"
        },
        {
            "_id": 8275,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 8276,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 8277,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 146,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 8278,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 8279,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 8280,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 123,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 8281,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 147,
            "שם_רחוב": "השפלה"
        },
        {
            "_id": 8282,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 145,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 8283,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 125,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 8284,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 121,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 8285,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 118,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 8286,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 140,
            "שם_רחוב": "ורד"
        },
        {
            "_id": 8287,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 8288,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 132,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 8289,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 8290,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 151,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 8291,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 136,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 8292,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 133,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 8293,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "כצנלסון"
        },
        {
            "_id": 8294,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 135,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 8295,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "נווה הדרים"
        },
        {
            "_id": 8296,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 138,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 8297,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 134,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 8298,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 8299,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 8300,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "קפלן"
        },
        {
            "_id": 8301,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "קקל"
        },
        {
            "_id": 8302,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רח ט"
        },
        {
            "_id": 8303,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רח י"
        },
        {
            "_id": 8304,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רמבן"
        },
        {
            "_id": 8305,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רמז"
        },
        {
            "_id": 8306,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 137,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 8307,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "ש נווה גנים"
        },
        {
            "_id": 8308,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש נקודת חן"
        },
        {
            "_id": 8309,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש צמרות דגן"
        },
        {
            "_id": 8310,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 8311,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 127,
            "שם_רחוב": "שבעת המינים"
        },
        {
            "_id": 8312,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 152,
            "שם_רחוב": "שומרון"
        },
        {
            "_id": 8313,
            "סמל_ישוב": 466,
            "שם_ישוב": "בית דגן ",
            "סמל_רחוב": 157,
            "שם_רחוב": "שקמה"
        },
        {
            "_id": 8314,
            "סמל_ישוב": 723,
            "שם_ישוב": "בית הגדי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית הגדי"
        },
        {
            "_id": 8315,
            "סמל_ישוב": 373,
            "שם_ישוב": "בית הלוי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית הלוי"
        },
        {
            "_id": 8316,
            "סמל_ישוב": 373,
            "שם_ישוב": "בית הלוי ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "הרחבה"
        },
        {
            "_id": 8317,
            "סמל_ישוב": 322,
            "שם_ישוב": "בית הלל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית הלל"
        },
        {
            "_id": 8318,
            "סמל_ישוב": 572,
            "שם_ישוב": "בית העמק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית העמק"
        },
        {
            "_id": 8319,
            "סמל_ישוב": 3645,
            "שם_ישוב": "בית הערבה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית הערבה"
        },
        {
            "_id": 8320,
            "סמל_ישוב": 242,
            "שם_ישוב": "בית השיטה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית השיטה"
        },
        {
            "_id": 8321,
            "סמל_ישוב": 353,
            "שם_ישוב": "בית זיד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית זיד"
        },
        {
            "_id": 8322,
            "סמל_ישוב": 353,
            "שם_ישוב": "בית זיד ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "כפר תקווה"
        },
        {
            "_id": 8323,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית זית"
        },
        {
            "_id": 8324,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 106,
            "שם_רחוב": "בריכות היער"
        },
        {
            "_id": 8325,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גבעת ברושים"
        },
        {
            "_id": 8326,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דרך הגבעה"
        },
        {
            "_id": 8327,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך הזיתים"
        },
        {
            "_id": 8328,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דרך הצבר"
        },
        {
            "_id": 8329,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דרך השקד"
        },
        {
            "_id": 8330,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 108,
            "שם_רחוב": "האגם"
        },
        {
            "_id": 8331,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 8332,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 112,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 8333,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 8334,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 8335,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 8336,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מעלה הערבה"
        },
        {
            "_id": 8337,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מעלה השיטה"
        },
        {
            "_id": 8338,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נתיב האורנים"
        },
        {
            "_id": 8339,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 110,
            "שם_רחוב": "נתיב האפרסק"
        },
        {
            "_id": 8340,
            "סמל_ישוב": 710,
            "שם_ישוב": "בית זית ",
            "סמל_רחוב": 105,
            "שם_רחוב": "עין כרם"
        },
        {
            "_id": 8341,
            "סמל_ישוב": 143,
            "שם_ישוב": "בית זרע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית זרע"
        },
        {
            "_id": 8342,
            "סמל_ישוב": 143,
            "שם_ישוב": "בית זרע ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 8343,
            "סמל_ישוב": 3864,
            "שם_ישוב": "בית חגלה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית חגלה"
        },
        {
            "_id": 8344,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אבני אלגביש"
        },
        {
            "_id": 8345,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "איחוד העם"
        },
        {
            "_id": 8346,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית חורון"
        },
        {
            "_id": 8347,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דרך אוהד"
        },
        {
            "_id": 8348,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך הנוף"
        },
        {
            "_id": 8349,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך יהודה המכבי"
        },
        {
            "_id": 8350,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 8351,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הסובלנות"
        },
        {
            "_id": 8352,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "זאבי רחבעם"
        },
        {
            "_id": 8353,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "יהושע בן נון"
        },
        {
            "_id": 8354,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "כא בכסלו"
        },
        {
            "_id": 8355,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מבוא דור ההמשך"
        },
        {
            "_id": 8356,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מבוא המיצפור"
        },
        {
            "_id": 8357,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מעלה בית חורון"
        },
        {
            "_id": 8358,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נחלת אפרים"
        },
        {
            "_id": 8359,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 8360,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 8361,
            "סמל_ישוב": 3575,
            "שם_ישוב": "בית חורון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שארה"
        },
        {
            "_id": 8362,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית חירות"
        },
        {
            "_id": 8363,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך ההדרים"
        },
        {
            "_id": 8364,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 8365,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 107,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 8366,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 8367,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 8368,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 117,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 8369,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הכיכר"
        },
        {
            "_id": 8370,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 109,
            "שם_רחוב": "המדגרה"
        },
        {
            "_id": 8371,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 8372,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 8373,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 102,
            "שם_רחוב": "השונית"
        },
        {
            "_id": 8374,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 112,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 8375,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כוכב הים"
        },
        {
            "_id": 8376,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מורד ההדרים"
        },
        {
            "_id": 8377,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מעלה הפיקוס"
        },
        {
            "_id": 8378,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 115,
            "שם_רחוב": "סמ האגוז"
        },
        {
            "_id": 8379,
            "סמל_ישוב": 877,
            "שם_ישוב": "בית חירות ",
            "סמל_רחוב": 116,
            "שם_רחוב": "סמ הראשונים"
        },
        {
            "_id": 8380,
            "סמל_ישוב": 2033,
            "שם_ישוב": "בית חלקיה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית חלקיה"
        },
        {
            "_id": 8381,
            "סמל_ישוב": 159,
            "שם_ישוב": "בית חנן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית חנן"
        },
        {
            "_id": 8382,
            "סמל_ישוב": 159,
            "שם_ישוב": "בית חנן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "גבעת האילנות"
        },
        {
            "_id": 8383,
            "סמל_ישוב": 159,
            "שם_ישוב": "בית חנן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גבעת ההתיישבות"
        },
        {
            "_id": 8384,
            "סמל_ישוב": 159,
            "שם_ישוב": "בית חנן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך הברושים"
        },
        {
            "_id": 8385,
            "סמל_ישוב": 159,
            "שם_ישוב": "בית חנן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך המגשימים"
        },
        {
            "_id": 8386,
            "סמל_ישוב": 159,
            "שם_ישוב": "בית חנן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך המיסדים"
        },
        {
            "_id": 8387,
            "סמל_ישוב": 159,
            "שם_ישוב": "בית חנן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הצבר"
        },
        {
            "_id": 8388,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 8389,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 8390,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "בהט"
        },
        {
            "_id": 8391,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 8392,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית חנניה"
        },
        {
            "_id": 8393,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 8394,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "גרניט"
        },
        {
            "_id": 8395,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך אמת המים"
        },
        {
            "_id": 8396,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 8397,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דרך הכרמל"
        },
        {
            "_id": 8398,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "חלמיש"
        },
        {
            "_id": 8399,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 8400,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "טורקיז"
        },
        {
            "_id": 8401,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 8402,
            "סמל_ישוב": 800,
            "שם_ישוב": "בית חנניה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 8403,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלעזר החורני"
        },
        {
            "_id": 8404,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אשכול"
        },
        {
            "_id": 8405,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית חשמונאי"
        },
        {
            "_id": 8406,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך היין"
        },
        {
            "_id": 8407,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך הכרמים"
        },
        {
            "_id": 8408,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך המכבים"
        },
        {
            "_id": 8409,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 8410,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 8411,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 8412,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 105,
            "שם_רחוב": "יהודה המכבי"
        },
        {
            "_id": 8413,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 118,
            "שם_רחוב": "יוחנן הגדי"
        },
        {
            "_id": 8414,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 103,
            "שם_רחוב": "יונתן הוופסי"
        },
        {
            "_id": 8415,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 112,
            "שם_רחוב": "עינב"
        },
        {
            "_id": 8416,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ הכרמים"
        },
        {
            "_id": 8417,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שמעון התרסי"
        },
        {
            "_id": 8418,
            "סמל_ישוב": 1050,
            "שם_ישוב": "בית חשמונאי ",
            "סמל_רחוב": 114,
            "שם_רחוב": "שריג"
        },
        {
            "_id": 8419,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 8420,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 108,
            "שם_רחוב": "ארגמן"
        },
        {
            "_id": 8421,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית יהושע"
        },
        {
            "_id": 8422,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 104,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 8423,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 8424,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 204,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 8425,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 202,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 8426,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "הרחבה"
        },
        {
            "_id": 8427,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 205,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 8428,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "הרציף"
        },
        {
            "_id": 8429,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 206,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 8430,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 203,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 8431,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 105,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 8432,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 8433,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שהם"
        },
        {
            "_id": 8434,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שני"
        },
        {
            "_id": 8435,
            "סמל_ישוב": 288,
            "שם_ישוב": "בית יהושע ",
            "סמל_רחוב": 107,
            "שם_רחוב": "תכלת"
        },
        {
            "_id": 8436,
            "סמל_ישוב": 265,
            "שם_ישוב": "בית יוסף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית יוסף"
        },
        {
            "_id": 8437,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 8438,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית ינאי"
        },
        {
            "_id": 8439,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דולפין"
        },
        {
            "_id": 8440,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דקר"
        },
        {
            "_id": 8441,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 105,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 8442,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הים"
        },
        {
            "_id": 8443,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הצוק"
        },
        {
            "_id": 8444,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 8445,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 102,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 8446,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מצפה ים"
        },
        {
            "_id": 8447,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נר הלילה"
        },
        {
            "_id": 8448,
            "סמל_ישוב": 200,
            "שם_ישוב": "בית ינאי ",
            "סמל_רחוב": 110,
            "שם_רחוב": "קלר"
        },
        {
            "_id": 8449,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית יצחק-שער חפר"
        },
        {
            "_id": 8450,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "דרך האילנות"
        },
        {
            "_id": 8451,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "דרך הרימונים"
        },
        {
            "_id": 8452,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "דרך השדות"
        },
        {
            "_id": 8453,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "דרך השרון"
        },
        {
            "_id": 8454,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך חפר"
        },
        {
            "_id": 8455,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך נירה"
        },
        {
            "_id": 8456,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 8457,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 137,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 8458,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 140,
            "שם_רחוב": "האלונים"
        },
        {
            "_id": 8459,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 8460,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 8461,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 8462,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 8463,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 135,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 8464,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 8465,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 8466,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 139,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 8467,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 8468,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 8469,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "המגדל"
        },
        {
            "_id": 8470,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 8471,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 8472,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "העירית"
        },
        {
            "_id": 8473,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 8474,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 8475,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 134,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 8476,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 141,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 8477,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 8478,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 138,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 8479,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 136,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 8480,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 129,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 8481,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 8482,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התמרים"
        },
        {
            "_id": 8483,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 132,
            "שם_רחוב": "משעול האילנות"
        },
        {
            "_id": 8484,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 133,
            "שם_רחוב": "סמטת 778"
        },
        {
            "_id": 8485,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 131,
            "שם_רחוב": "סמטת האקליפטוס"
        },
        {
            "_id": 8486,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "סמטת ההדרים"
        },
        {
            "_id": 8487,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 126,
            "שם_רחוב": "סמטת החרובים"
        },
        {
            "_id": 8488,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 130,
            "שם_רחוב": "סמטת הצבר"
        },
        {
            "_id": 8489,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 142,
            "שם_רחוב": "סמטת התות"
        },
        {
            "_id": 8490,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רחבת הבנים"
        },
        {
            "_id": 8491,
            "סמל_ישוב": 326,
            "שם_ישוב": "בית יצחק-שער חפר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "שער הכפר"
        },
        {
            "_id": 8492,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית לחם הגלילית"
        },
        {
            "_id": 8493,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך היער"
        },
        {
            "_id": 8494,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 109,
            "שם_רחוב": "האביב"
        },
        {
            "_id": 8495,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 8496,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 8497,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 116,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 8498,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 104,
            "שם_רחוב": "היוגב"
        },
        {
            "_id": 8499,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 8500,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 115,
            "שם_רחוב": "העומר"
        },
        {
            "_id": 8501,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 110,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 8502,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 8503,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 103,
            "שם_רחוב": "השדות"
        },
        {
            "_id": 8504,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 101,
            "שם_רחוב": "השדרה הטמפלרית"
        },
        {
            "_id": 8505,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התבואה"
        },
        {
            "_id": 8506,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מגדל המים"
        },
        {
            "_id": 8507,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 114,
            "שם_רחוב": "סמטת הפעמון"
        },
        {
            "_id": 8508,
            "סמל_ישוב": 430,
            "שם_ישוב": "בית לחם הגלילית ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רגבים"
        },
        {
            "_id": 8509,
            "סמל_ישוב": 751,
            "שם_ישוב": "בית מאיר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית מאיר"
        },
        {
            "_id": 8510,
            "סמל_ישוב": 751,
            "שם_ישוב": "בית מאיר ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "רמות שפירא"
        },
        {
            "_id": 8511,
            "סמל_ישוב": 784,
            "שם_ישוב": "בית נחמיה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית נחמיה"
        },
        {
            "_id": 8512,
            "סמל_ישוב": 784,
            "שם_ישוב": "בית נחמיה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הורד"
        },
        {
            "_id": 8513,
            "סמל_ישוב": 784,
            "שם_ישוב": "בית נחמיה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך החצב"
        },
        {
            "_id": 8514,
            "סמל_ישוב": 784,
            "שם_ישוב": "בית נחמיה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך החרצית"
        },
        {
            "_id": 8515,
            "סמל_ישוב": 784,
            "שם_ישוב": "בית נחמיה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך היסמין"
        },
        {
            "_id": 8516,
            "סמל_ישוב": 784,
            "שם_ישוב": "בית נחמיה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דרך היקינטון"
        },
        {
            "_id": 8517,
            "סמל_ישוב": 784,
            "שם_ישוב": "בית נחמיה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך הכלנית"
        },
        {
            "_id": 8518,
            "סמל_ישוב": 16,
            "שם_ישוב": "בית ניר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית ניר"
        },
        {
            "_id": 8519,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית נקופה"
        },
        {
            "_id": 8520,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 8521,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 8522,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 8523,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 8524,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 8525,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 8526,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 8527,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 8528,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 8529,
            "סמל_ישוב": 672,
            "שם_ישוב": "בית נקופה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "סמ התמר"
        },
        {
            "_id": 8530,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית עובד"
        },
        {
            "_id": 8531,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ההתיישבות"
        },
        {
            "_id": 8532,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 102,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 8533,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 106,
            "שם_רחוב": "משעול איריס"
        },
        {
            "_id": 8534,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 116,
            "שם_רחוב": "משעול דבורנית"
        },
        {
            "_id": 8535,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 115,
            "שם_רחוב": "משעול הרדוף"
        },
        {
            "_id": 8536,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 104,
            "שם_רחוב": "משעול חצב"
        },
        {
            "_id": 8537,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 107,
            "שם_רחוב": "משעול חרצית"
        },
        {
            "_id": 8538,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 108,
            "שם_רחוב": "משעול כלנית"
        },
        {
            "_id": 8539,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 110,
            "שם_רחוב": "משעול כרכום"
        },
        {
            "_id": 8540,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 111,
            "שם_רחוב": "משעול לוטם"
        },
        {
            "_id": 8541,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 103,
            "שם_רחוב": "משעול מרווה"
        },
        {
            "_id": 8542,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 113,
            "שם_רחוב": "משעול נורית"
        },
        {
            "_id": 8543,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 112,
            "שם_רחוב": "משעול נרקיס"
        },
        {
            "_id": 8544,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 117,
            "שם_רחוב": "משעול סביון"
        },
        {
            "_id": 8545,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 105,
            "שם_רחוב": "משעול פעמונית"
        },
        {
            "_id": 8546,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 109,
            "שם_רחוב": "משעול צבעוני"
        },
        {
            "_id": 8547,
            "סמל_ישוב": 202,
            "שם_ישוב": "בית עובד ",
            "סמל_רחוב": 114,
            "שם_רחוב": "משעול רותם"
        },
        {
            "_id": 8548,
            "סמל_ישוב": 301,
            "שם_ישוב": "בית עוזיאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית עוזיאל"
        },
        {
            "_id": 8549,
            "סמל_ישוב": 756,
            "שם_ישוב": "בית עזרא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית עזרא"
        },
        {
            "_id": 8550,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 8551,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אשלים"
        },
        {
            "_id": 8552,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית עריף"
        },
        {
            "_id": 8553,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 8554,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 108,
            "שם_רחוב": "יעלים"
        },
        {
            "_id": 8555,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ירדן"
        },
        {
            "_id": 8556,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 110,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 8557,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ערוגות"
        },
        {
            "_id": 8558,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 109,
            "שם_רחוב": "פולג"
        },
        {
            "_id": 8559,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 8560,
            "סמל_ישוב": 604,
            "שם_ישוב": "בית עריף ",
            "סמל_רחוב": 104,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 8561,
            "סמל_ישוב": 212,
            "שם_ישוב": "בית צבי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית צבי"
        },
        {
            "_id": 8562,
            "סמל_ישוב": 598,
            "שם_ישוב": "בית קמה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית קמה"
        },
        {
            "_id": 8563,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית קשת"
        },
        {
            "_id": 8564,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 8565,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 114,
            "שם_רחוב": "דרך האלונים"
        },
        {
            "_id": 8566,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דרך היער"
        },
        {
            "_id": 8567,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הרעות"
        },
        {
            "_id": 8568,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך השבעה"
        },
        {
            "_id": 8569,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האלות"
        },
        {
            "_id": 8570,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הבוסתנים"
        },
        {
            "_id": 8571,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגפנים"
        },
        {
            "_id": 8572,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הדומים"
        },
        {
            "_id": 8573,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הר גמל"
        },
        {
            "_id": 8574,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הר דבורה"
        },
        {
            "_id": 8575,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הר תבור"
        },
        {
            "_id": 8576,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 8577,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר הפלמח"
        },
        {
            "_id": 8578,
            "סמל_ישוב": 365,
            "שם_ישוב": "בית קשת ",
            "סמל_רחוב": 110,
            "שם_רחוב": "שביל עץ הפרפרים"
        },
        {
            "_id": 8579,
            "סמל_ישוב": 848,
            "שם_ישוב": "בית רבן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית רבן"
        },
        {
            "_id": 8580,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "ארגמן"
        },
        {
            "_id": 8581,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית רימון"
        },
        {
            "_id": 8582,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "גינת האגוז"
        },
        {
            "_id": 8583,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 8584,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 8585,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הדודאים"
        },
        {
            "_id": 8586,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 8587,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 8588,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 8589,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "חוט השני"
        },
        {
            "_id": 8590,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 8591,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 8592,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מעיין גנים"
        },
        {
            "_id": 8593,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ערוגות הבושם"
        },
        {
            "_id": 8594,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "עת הזמיר"
        },
        {
            "_id": 8595,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "פלח הרימון"
        },
        {
            "_id": 8596,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "פרדס רימונים"
        },
        {
            "_id": 8597,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שושנת העמקים"
        },
        {
            "_id": 8598,
            "סמל_ישוב": 1162,
            "שם_ישוב": "בית רימון ",
            "סמל_רחוב": 118,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 8599,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אביטן יהושע"
        },
        {
            "_id": 8600,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אביר יעקב"
        },
        {
            "_id": 8601,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אבן שושן אברהם"
        },
        {
            "_id": 8602,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אהבת ישראל"
        },
        {
            "_id": 8603,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אהבת ציון"
        },
        {
            "_id": 8604,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 308,
            "שם_רחוב": "אהוד מנור"
        },
        {
            "_id": 8605,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 8606,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "אזור תעשיה מזרחי"
        },
        {
            "_id": 8607,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "אזור תעשיה צפוני"
        },
        {
            "_id": 8608,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אחי אילת"
        },
        {
            "_id": 8609,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אחי דקר"
        },
        {
            "_id": 8610,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 8611,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 236,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 8612,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 240,
            "שם_רחוב": "איתן רפאל"
        },
        {
            "_id": 8613,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 8614,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 8615,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 8616,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 183,
            "שם_רחוב": "אמנון ליפקין שחק"
        },
        {
            "_id": 8617,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 8618,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 202,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 8619,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 301,
            "שם_רחוב": "אריק איינשטיין"
        },
        {
            "_id": 8620,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 8621,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 328,
            "שם_רחוב": "בבא סאלי"
        },
        {
            "_id": 8622,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 150,
            "שם_רחוב": "בויאר אווה"
        },
        {
            "_id": 8623,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 156,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 8624,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 135,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 8625,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית שאן"
        },
        {
            "_id": 8626,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 136,
            "שם_רחוב": "בלפור"
        },
        {
            "_id": 8627,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 212,
            "שם_רחוב": "בן גוריון"
        },
        {
            "_id": 8628,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 168,
            "שם_רחוב": "בן יהודה"
        },
        {
            "_id": 8629,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 138,
            "שם_רחוב": "בן לולו"
        },
        {
            "_id": 8630,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 221,
            "שם_רחוב": "בן צבי"
        },
        {
            "_id": 8631,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 164,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 8632,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 243,
            "שם_רחוב": "בר-לב חיים"
        },
        {
            "_id": 8633,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 219,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 8634,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 165,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 8635,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 252,
            "שם_רחוב": "גולדה מאיר"
        },
        {
            "_id": 8636,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 142,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 8637,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 320,
            "שם_רחוב": "גרינבר אורי צבי"
        },
        {
            "_id": 8638,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 143,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 8639,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 244,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 8640,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 241,
            "שם_רחוב": "דוד אלעזר"
        },
        {
            "_id": 8641,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 214,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 8642,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 326,
            "שם_רחוב": "דונה גרציה"
        },
        {
            "_id": 8643,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 321,
            "שם_רחוב": "דורי יעקב"
        },
        {
            "_id": 8644,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 226,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 8645,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 222,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 8646,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 311,
            "שם_רחוב": "דרך הבנים"
        },
        {
            "_id": 8647,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 217,
            "שם_רחוב": "דרך השלום"
        },
        {
            "_id": 8648,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 312,
            "שם_רחוב": "דרך מאיר"
        },
        {
            "_id": 8649,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 260,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 8650,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 144,
            "שם_רחוב": "הבונים"
        },
        {
            "_id": 8651,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 262,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 8652,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 261,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 8653,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 271,
            "שם_רחוב": "הבקעה"
        },
        {
            "_id": 8654,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 192,
            "שם_רחוב": "הגביש"
        },
        {
            "_id": 8655,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 275,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 8656,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 179,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 8657,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 263,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 8658,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 264,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 8659,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 267,
            "שם_רחוב": "הדגן"
        },
        {
            "_id": 8660,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 266,
            "שם_רחוב": "ההדר"
        },
        {
            "_id": 8661,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 280,
            "שם_רחוב": "החופית"
        },
        {
            "_id": 8662,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 265,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 8663,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 191,
            "שם_רחוב": "החרמש"
        },
        {
            "_id": 8664,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 199,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 8665,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 195,
            "שם_רחוב": "החשמל"
        },
        {
            "_id": 8666,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 188,
            "שם_רחוב": "היוזמה"
        },
        {
            "_id": 8667,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 187,
            "שם_רחוב": "היצירה"
        },
        {
            "_id": 8668,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 282,
            "שם_רחוב": "הירקון"
        },
        {
            "_id": 8669,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 218,
            "שם_רחוב": "היתד"
        },
        {
            "_id": 8670,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 273,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 8671,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 255,
            "שם_רחוב": "הלה"
        },
        {
            "_id": 8672,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 250,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 8673,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 114,
            "שם_רחוב": "המירון"
        },
        {
            "_id": 8674,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 163,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 8675,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 122,
            "שם_רחוב": "המשטרה"
        },
        {
            "_id": 8676,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 8677,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 194,
            "שם_רחוב": "הסדנא"
        },
        {
            "_id": 8678,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 185,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 8679,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "העיר העתיקה"
        },
        {
            "_id": 8680,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 189,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 8681,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 141,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 8682,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 117,
            "שם_רחוב": "העצמון"
        },
        {
            "_id": 8683,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 251,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 8684,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 145,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 8685,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 281,
            "שם_רחוב": "הקציר"
        },
        {
            "_id": 8686,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 256,
            "שם_רחוב": "הראל"
        },
        {
            "_id": 8687,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הרב אברהם בדוש"
        },
        {
            "_id": 8688,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 175,
            "שם_רחוב": "הרב אהרון שטיינמן"
        },
        {
            "_id": 8689,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 322,
            "שם_רחוב": "הרב אליהו הכהן"
        },
        {
            "_id": 8690,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 327,
            "שם_רחוב": "הרב אלישע וישלינצקי"
        },
        {
            "_id": 8691,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 171,
            "שם_רחוב": "הרב יוסף שמחון"
        },
        {
            "_id": 8692,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 169,
            "שם_רחוב": "הרב יצחק הרצוג"
        },
        {
            "_id": 8693,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 329,
            "שם_רחוב": "הרב ישראל קבלו"
        },
        {
            "_id": 8694,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הרב לסרי"
        },
        {
            "_id": 8695,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 258,
            "שם_רחוב": "הרב מרדכי אליהו"
        },
        {
            "_id": 8696,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הרב סויסה דוד"
        },
        {
            "_id": 8697,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 330,
            "שם_רחוב": "הרב עובדיה יוסף"
        },
        {
            "_id": 8698,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 157,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 8699,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 177,
            "שם_רחוב": "הרב שך"
        },
        {
            "_id": 8700,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 139,
            "שם_רחוב": "הרופאים"
        },
        {
            "_id": 8701,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 155,
            "שם_רחוב": "הרמבם"
        },
        {
            "_id": 8702,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 323,
            "שם_רחוב": "הרצוג חיים"
        },
        {
            "_id": 8703,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 8704,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 186,
            "שם_רחוב": "הרתך"
        },
        {
            "_id": 8705,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 274,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 8706,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 121,
            "שם_רחוב": "השחם"
        },
        {
            "_id": 8707,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 259,
            "שם_רחוב": "השייטת"
        },
        {
            "_id": 8708,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "השישה"
        },
        {
            "_id": 8709,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 270,
            "שם_רחוב": "השפלה"
        },
        {
            "_id": 8710,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 272,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 8711,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 137,
            "שם_רחוב": "התומר"
        },
        {
            "_id": 8712,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 268,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 8713,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 193,
            "שם_רחוב": "התנופה"
        },
        {
            "_id": 8714,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 324,
            "שם_רחוב": "וייצמן עזר"
        },
        {
            "_id": 8715,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 206,
            "שם_רחוב": "ויצמן חיים"
        },
        {
            "_id": 8716,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 182,
            "שם_רחוב": "זאבי רחבעם"
        },
        {
            "_id": 8717,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 205,
            "שם_רחוב": "זית"
        },
        {
            "_id": 8718,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 231,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 8719,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "חומה ומגדל"
        },
        {
            "_id": 8720,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 269,
            "שם_רחוב": "חטיבת גבעתי"
        },
        {
            "_id": 8721,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 225,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 8722,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 276,
            "שם_רחוב": "חטיבת הנחל"
        },
        {
            "_id": 8723,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 283,
            "שם_רחוב": "חטיבת קרייתי"
        },
        {
            "_id": 8724,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 228,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 8725,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 284,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 8726,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 148,
            "שם_רחוב": "טולידנו"
        },
        {
            "_id": 8727,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 197,
            "שם_רחוב": "טיילת המלך חסן השני"
        },
        {
            "_id": 8728,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 140,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 8729,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 238,
            "שם_רחוב": "ידין יגאל"
        },
        {
            "_id": 8730,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 8731,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 286,
            "שם_רחוב": "יונתן"
        },
        {
            "_id": 8732,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 307,
            "שם_רחוב": "יוסי בנאי"
        },
        {
            "_id": 8733,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 235,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 8734,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 313,
            "שם_רחוב": "יעקב ג'קיטו מאסטרו"
        },
        {
            "_id": 8735,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 302,
            "שם_רחוב": "יפה ירקוני"
        },
        {
            "_id": 8736,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 287,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 8737,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "ירושלים הבירה"
        },
        {
            "_id": 8738,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 304,
            "שם_רחוב": "ישראל פוליאקוב"
        },
        {
            "_id": 8739,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 210,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 8740,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 158,
            "שם_רחוב": "כנסת ישראל"
        },
        {
            "_id": 8741,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 131,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 8742,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 317,
            "שם_רחוב": "לאה גולדברג"
        },
        {
            "_id": 8743,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 249,
            "שם_רחוב": "לוי אשכול"
        },
        {
            "_id": 8744,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 288,
            "שם_רחוב": "לוי משה"
        },
        {
            "_id": 8745,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 289,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 8746,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 290,
            "שם_רחוב": "ליטני"
        },
        {
            "_id": 8747,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 196,
            "שם_רחוב": "ללוש אנדרה"
        },
        {
            "_id": 8748,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 291,
            "שם_רחוב": "לסקוב חיים"
        },
        {
            "_id": 8749,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 151,
            "שם_רחוב": "מאיר בעל הנס"
        },
        {
            "_id": 8750,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 173,
            "שם_רחוב": "מוטה גור"
        },
        {
            "_id": 8751,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 146,
            "שם_רחוב": "מיזוג גלויות"
        },
        {
            "_id": 8752,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 147,
            "שם_רחוב": "מכלוף יעקב"
        },
        {
            "_id": 8753,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 120,
            "שם_רחוב": "מעון עולים"
        },
        {
            "_id": 8754,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 292,
            "שם_רחוב": "מעפילי אגוז"
        },
        {
            "_id": 8755,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 293,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 8756,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 246,
            "שם_רחוב": "מקלף מרדכי"
        },
        {
            "_id": 8757,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 149,
            "שם_רחוב": "מרקוס"
        },
        {
            "_id": 8758,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 174,
            "שם_רחוב": "נהריים"
        },
        {
            "_id": 8759,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "נוף הגלבוע"
        },
        {
            "_id": 8760,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "נוף הירדן"
        },
        {
            "_id": 8761,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 233,
            "שם_רחוב": "נופר"
        },
        {
            "_id": 8762,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 134,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 8763,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 237,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 8764,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 310,
            "שם_רחוב": "נח מרדינגר"
        },
        {
            "_id": 8765,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 303,
            "שם_רחוב": "נחום היימן"
        },
        {
            "_id": 8766,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 294,
            "שם_רחוב": "נחשון"
        },
        {
            "_id": 8767,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 248,
            "שם_רחוב": "נרקיס עוזי"
        },
        {
            "_id": 8768,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 229,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 8769,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 167,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 8770,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 305,
            "שם_רחוב": "עוזי חיטמן"
        },
        {
            "_id": 8771,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 306,
            "שם_רחוב": "עופרה חזה"
        },
        {
            "_id": 8772,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 315,
            "שם_רחוב": "עזריה אלון"
        },
        {
            "_id": 8773,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 152,
            "שם_רחוב": "עליה"
        },
        {
            "_id": 8774,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 213,
            "שם_רחוב": "פיקוס"
        },
        {
            "_id": 8775,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 234,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 8776,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 247,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 8777,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 295,
            "שם_רחוב": "צור צבי"
        },
        {
            "_id": 8778,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 325,
            "שם_רחוב": "צורי נחמיה"
        },
        {
            "_id": 8779,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 227,
            "שם_רחוב": "ציפורן"
        },
        {
            "_id": 8780,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 207,
            "שם_רחוב": "צפצפה"
        },
        {
            "_id": 8781,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 296,
            "שם_רחוב": "קדש"
        },
        {
            "_id": 8782,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 184,
            "שם_רחוב": "קהילת יהדות סלוניקי"
        },
        {
            "_id": 8783,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 153,
            "שם_רחוב": "קפלן"
        },
        {
            "_id": 8784,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "קרית רבין"
        },
        {
            "_id": 8785,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 170,
            "שם_רחוב": "רבי אברהם תורג'מן"
        },
        {
            "_id": 8786,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 319,
            "שם_רחוב": "רבי נחמן מברסלב"
        },
        {
            "_id": 8787,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 198,
            "שם_רחוב": "רבינוביץ חנן"
        },
        {
            "_id": 8788,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 159,
            "שם_רחוב": "רוגובסקי"
        },
        {
            "_id": 8789,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 318,
            "שם_רחוב": "רועי קליין"
        },
        {
            "_id": 8790,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 220,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 8791,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רח א"
        },
        {
            "_id": 8792,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רח ד"
        },
        {
            "_id": 8793,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח ה"
        },
        {
            "_id": 8794,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רח ו"
        },
        {
            "_id": 8795,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רח ח"
        },
        {
            "_id": 8796,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רח ט"
        },
        {
            "_id": 8797,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 316,
            "שם_רחוב": "רחל המשוררת"
        },
        {
            "_id": 8798,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 211,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 8799,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 297,
            "שם_רחוב": "רמון אילן"
        },
        {
            "_id": 8800,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 160,
            "שם_רחוב": "רמז"
        },
        {
            "_id": 8801,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 232,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 8802,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש בנה ביתך"
        },
        {
            "_id": 8803,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "ש ז'בוטינסקי"
        },
        {
            "_id": 8804,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש עממי"
        },
        {
            "_id": 8805,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש צריפים אדומים"
        },
        {
            "_id": 8806,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש קדמת עדן"
        },
        {
            "_id": 8807,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 8808,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 245,
            "שם_רחוב": "שד יצחק רבין"
        },
        {
            "_id": 8809,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שד מנחם בגין"
        },
        {
            "_id": 8810,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 181,
            "שם_רחוב": "שדרות הארבעה"
        },
        {
            "_id": 8811,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 298,
            "שם_רחוב": "שומרון דן"
        },
        {
            "_id": 8812,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 309,
            "שם_רחוב": "שושנה דמארי"
        },
        {
            "_id": 8813,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 204,
            "שם_רחוב": "שזר זלמן"
        },
        {
            "_id": 8814,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 277,
            "שם_רחוב": "שטרן יאיר"
        },
        {
            "_id": 8815,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 208,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 8816,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 239,
            "שם_רחוב": "שיקמה"
        },
        {
            "_id": 8817,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ אליהו"
        },
        {
            "_id": 8818,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ יפה נוף"
        },
        {
            "_id": 8819,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "שכ יצחק נבון"
        },
        {
            "_id": 8820,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "שכ יצחק שמיר"
        },
        {
            "_id": 8821,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "שכ מרכז"
        },
        {
            "_id": 8822,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "שכ נווה הדקל"
        },
        {
            "_id": 8823,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ נווה הנחל"
        },
        {
            "_id": 8824,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "שכ נוף גלעד"
        },
        {
            "_id": 8825,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ נוף גנים"
        },
        {
            "_id": 8826,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ נוף קדומים"
        },
        {
            "_id": 8827,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ נוף שאן"
        },
        {
            "_id": 8828,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ צמרת"
        },
        {
            "_id": 8829,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 299,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 8830,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 278,
            "שם_רחוב": "שלום הגליל"
        },
        {
            "_id": 8831,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 314,
            "שם_רחוב": "שמעון ויזנטל"
        },
        {
            "_id": 8832,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 300,
            "שם_רחוב": "שמר נעמי"
        },
        {
            "_id": 8833,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 154,
            "שם_רחוב": "שמש יצחק"
        },
        {
            "_id": 8834,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 216,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 8835,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 253,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 8836,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 254,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 8837,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 209,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 8838,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 180,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 8839,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 190,
            "שם_רחוב": "תוצרת הארץ"
        },
        {
            "_id": 8840,
            "סמל_ישוב": 9200,
            "שם_ישוב": "בית שאן ",
            "סמל_רחוב": 279,
            "שם_רחוב": "תמיר מרדכי"
        },
        {
            "_id": 8841,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אבא נעמת"
        },
        {
            "_id": 8842,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 280,
            "שם_רחוב": "אביטל"
        },
        {
            "_id": 8843,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 340,
            "שם_רחוב": "אביי"
        },
        {
            "_id": 8844,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 293,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 8845,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 237,
            "שם_רחוב": "אדם יקותיאל"
        },
        {
            "_id": 8846,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אהבת ישראל"
        },
        {
            "_id": 8847,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 503,
            "שם_רחוב": "אהבת שלום"
        },
        {
            "_id": 8848,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 370,
            "שם_רחוב": "אהרונסון"
        },
        {
            "_id": 8849,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 371,
            "שם_רחוב": "אהרן שמי"
        },
        {
            "_id": 8850,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 318,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 8851,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 455,
            "שם_רחוב": "אוהל יהושע"
        },
        {
            "_id": 8852,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אולפן המשטרה"
        },
        {
            "_id": 8853,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אור החיים"
        },
        {
            "_id": 8854,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אור שמח"
        },
        {
            "_id": 8855,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 281,
            "שם_רחוב": "אורטל"
        },
        {
            "_id": 8856,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "אזור התעשיה צפון"
        },
        {
            "_id": 8857,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "אזור תעשיה מערבי"
        },
        {
            "_id": 8858,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 425,
            "שם_רחוב": "אחיה השילוני"
        },
        {
            "_id": 8859,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אחיעזר"
        },
        {
            "_id": 8860,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 570,
            "שם_רחוב": "אילפא"
        },
        {
            "_id": 8861,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 380,
            "שם_רחוב": "אלבז"
        },
        {
            "_id": 8862,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלון יגאל"
        },
        {
            "_id": 8863,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 473,
            "שם_רחוב": "אליהו הנביא"
        },
        {
            "_id": 8864,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אליכין"
        },
        {
            "_id": 8865,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 474,
            "שם_רחוב": "אלישע הנביא"
        },
        {
            "_id": 8866,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלנקוה רפאל"
        },
        {
            "_id": 8867,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלעזרי"
        },
        {
            "_id": 8868,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אמרי אמת"
        },
        {
            "_id": 8869,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 504,
            "שם_רחוב": "אמרי נועם"
        },
        {
            "_id": 8870,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 513,
            "שם_רחוב": "אסתר"
        },
        {
            "_id": 8871,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 8872,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אשכול לוי"
        },
        {
            "_id": 8873,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 231,
            "שם_רחוב": "אשר"
        },
        {
            "_id": 8874,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 487,
            "שם_רחוב": "באר שבע"
        },
        {
            "_id": 8875,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 128,
            "שם_רחוב": "בוסקילה יוסף"
        },
        {
            "_id": 8876,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 319,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 8877,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 123,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 8878,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ביחר שמשון"
        },
        {
            "_id": 8879,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 130,
            "שם_רחוב": "בית ג'מאל"
        },
        {
            "_id": 8880,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 149,
            "שם_רחוב": "בית זולוטוב"
        },
        {
            "_id": 8881,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 226,
            "שם_רחוב": "בית ישראל"
        },
        {
            "_id": 8882,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית שמש"
        },
        {
            "_id": 8883,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "בית שמש הותיקה"
        },
        {
            "_id": 8884,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 189,
            "שם_רחוב": "בן איש חי"
        },
        {
            "_id": 8885,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 202,
            "שם_רחוב": "בן אליעזר"
        },
        {
            "_id": 8886,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 254,
            "שם_רחוב": "בן גוריון פולה"
        },
        {
            "_id": 8887,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 158,
            "שם_רחוב": "בן גרא"
        },
        {
            "_id": 8888,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 252,
            "שם_רחוב": "בן זאב יהודה"
        },
        {
            "_id": 8889,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 450,
            "שם_רחוב": "בן עזאי"
        },
        {
            "_id": 8890,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 145,
            "שם_רחוב": "בן צבי"
        },
        {
            "_id": 8891,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 187,
            "שם_רחוב": "בן ציון אבא שאול"
        },
        {
            "_id": 8892,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 297,
            "שם_רחוב": "בנאי יוסי"
        },
        {
            "_id": 8893,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 124,
            "שם_רחוב": "בני דן"
        },
        {
            "_id": 8894,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 323,
            "שם_רחוב": "בנימין"
        },
        {
            "_id": 8895,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 325,
            "שם_רחוב": "בעל הסולם"
        },
        {
            "_id": 8896,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 505,
            "שם_רחוב": "בעל התניא"
        },
        {
            "_id": 8897,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 203,
            "שם_רחוב": "בר אילן"
        },
        {
            "_id": 8898,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 361,
            "שם_רחוב": "בר קפרא"
        },
        {
            "_id": 8899,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 229,
            "שם_רחוב": "ברכת אברהם"
        },
        {
            "_id": 8900,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "גבעת בן צבי"
        },
        {
            "_id": 8901,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "גבעת ויצמן"
        },
        {
            "_id": 8902,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "גבעת סביון"
        },
        {
            "_id": 8903,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "גבעת שזר"
        },
        {
            "_id": 8904,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "גבעת שרת"
        },
        {
            "_id": 8905,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 232,
            "שם_רחוב": "גד"
        },
        {
            "_id": 8906,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 240,
            "שם_רחוב": "גונן שמואל"
        },
        {
            "_id": 8907,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 122,
            "שם_רחוב": "גור אריה"
        },
        {
            "_id": 8908,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 243,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 8909,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "גני יהודית"
        },
        {
            "_id": 8910,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "גני שמש"
        },
        {
            "_id": 8911,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 316,
            "שם_רחוב": "גרניט"
        },
        {
            "_id": 8912,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 550,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 8913,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 453,
            "שם_רחוב": "דובר שלום"
        },
        {
            "_id": 8914,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 257,
            "שם_רחוב": "דורי יעקב"
        },
        {
            "_id": 8915,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 315,
            "שם_רחוב": "דלומיט"
        },
        {
            "_id": 8916,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 8917,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דם המכבים"
        },
        {
            "_id": 8918,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 515,
            "שם_רחוב": "דניאל"
        },
        {
            "_id": 8919,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 285,
            "שם_רחוב": "דרך החיים"
        },
        {
            "_id": 8920,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 247,
            "שם_רחוב": "דרך רבין יצחק"
        },
        {
            "_id": 8921,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 552,
            "שם_רחוב": "דרכי איש"
        },
        {
            "_id": 8922,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 449,
            "שם_רחוב": "דרכי אליעזר"
        },
        {
            "_id": 8923,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 272,
            "שם_רחוב": "האגס"
        },
        {
            "_id": 8924,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 502,
            "שם_רחוב": "האדמור מבעלזא"
        },
        {
            "_id": 8925,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 506,
            "שם_רחוב": "האדמור מרוז'ין"
        },
        {
            "_id": 8926,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 507,
            "שם_רחוב": "האדרת"
        },
        {
            "_id": 8927,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האומן"
        },
        {
            "_id": 8928,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 268,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 8929,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 379,
            "שם_רחוב": "האחים"
        },
        {
            "_id": 8930,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 166,
            "שם_רחוב": "האיריס"
        },
        {
            "_id": 8931,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 125,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 8932,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 273,
            "שם_רחוב": "האפרסק"
        },
        {
            "_id": 8933,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 138,
            "שם_רחוב": "האצל"
        },
        {
            "_id": 8934,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 244,
            "שם_רחוב": "הארבל"
        },
        {
            "_id": 8935,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 274,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 8936,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 174,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 8937,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 269,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 8938,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 141,
            "שם_רחוב": "הבנאי"
        },
        {
            "_id": 8939,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 508,
            "שם_רחוב": "הבעל שם טוב"
        },
        {
            "_id": 8940,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 266,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 8941,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 509,
            "שם_רחוב": "הגאון מוילנא"
        },
        {
            "_id": 8942,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 251,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 8943,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 248,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 8944,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 8945,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 176,
            "שם_רחוב": "הדובדבן"
        },
        {
            "_id": 8946,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 278,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 8947,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 270,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 8948,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 263,
            "שם_רחוב": "ההדר"
        },
        {
            "_id": 8949,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 305,
            "שם_רחוב": "ההכשרה"
        },
        {
            "_id": 8950,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 476,
            "שם_רחוב": "הושע הנביא"
        },
        {
            "_id": 8951,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 155,
            "שם_רחוב": "הותיקים"
        },
        {
            "_id": 8952,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 193,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 8953,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 526,
            "שם_רחוב": "החוזה מלובלין"
        },
        {
            "_id": 8954,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החלוצים"
        },
        {
            "_id": 8955,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 188,
            "שם_רחוב": "החפץ חיים"
        },
        {
            "_id": 8956,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 191,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 8957,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 264,
            "שם_רחוב": "החרובים"
        },
        {
            "_id": 8958,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 306,
            "שם_רחוב": "החרוצים"
        },
        {
            "_id": 8959,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 245,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 8960,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 192,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 8961,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 152,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 8962,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 195,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 8963,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 301,
            "שם_רחוב": "היצירה"
        },
        {
            "_id": 8964,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 167,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 8965,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 495,
            "שם_רחוב": "הלל"
        },
        {
            "_id": 8966,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 527,
            "שם_רחוב": "המגיד ממעזריטש"
        },
        {
            "_id": 8967,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 220,
            "שם_רחוב": "המהריל דיסקין"
        },
        {
            "_id": 8968,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 136,
            "שם_רחוב": "המורה יעקב"
        },
        {
            "_id": 8969,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 260,
            "שם_רחוב": "המורה צבי"
        },
        {
            "_id": 8970,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 126,
            "שם_רחוב": "המכתש"
        },
        {
            "_id": 8971,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 304,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 8972,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 511,
            "שם_רחוב": "המלך דוד"
        },
        {
            "_id": 8973,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 142,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 8974,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 121,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 8975,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 112,
            "שם_רחוב": "המשלט"
        },
        {
            "_id": 8976,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 311,
            "שם_רחוב": "הנביא עובדיה"
        },
        {
            "_id": 8977,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 312,
            "שם_רחוב": "הנביא עמוס"
        },
        {
            "_id": 8978,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 529,
            "שם_רחוב": "הנביא צפניה"
        },
        {
            "_id": 8979,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 165,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 8980,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 528,
            "שם_רחוב": "הנציב"
        },
        {
            "_id": 8981,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 169,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 8982,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הנשיא"
        },
        {
            "_id": 8983,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 168,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 8984,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 308,
            "שם_רחוב": "הסוללה"
        },
        {
            "_id": 8985,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 163,
            "שם_רחוב": "הסיגלית"
        },
        {
            "_id": 8986,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 105,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 8987,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 303,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 8988,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 265,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 8989,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 275,
            "שם_רחוב": "הערמון"
        },
        {
            "_id": 8990,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 276,
            "שם_רחוב": "הצאלה"
        },
        {
            "_id": 8991,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 310,
            "שם_רחוב": "הצבע"
        },
        {
            "_id": 8992,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 448,
            "שם_רחוב": "הצדיק משטפנשט"
        },
        {
            "_id": 8993,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 194,
            "שם_רחוב": "הציפורן"
        },
        {
            "_id": 8994,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 286,
            "שם_רחוב": "הר בשן"
        },
        {
            "_id": 8995,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 289,
            "שם_רחוב": "הר גבנונים"
        },
        {
            "_id": 8996,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 300,
            "שם_רחוב": "הר הכרמל"
        },
        {
            "_id": 8997,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 287,
            "שם_רחוב": "הר המוריה"
        },
        {
            "_id": 8998,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 288,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 8999,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 284,
            "שם_רחוב": "הר נבו"
        },
        {
            "_id": 9000,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 551,
            "שם_רחוב": "הרב אברהם שפירא"
        },
        {
            "_id": 9001,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 553,
            "שם_רחוב": "הרב איידלשטיין"
        },
        {
            "_id": 9002,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 534,
            "שם_רחוב": "הרב אלישיב"
        },
        {
            "_id": 9003,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 556,
            "שם_רחוב": "הרב ברוק"
        },
        {
            "_id": 9004,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 532,
            "שם_רחוב": "הרב גריינימן"
        },
        {
            "_id": 9005,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 531,
            "שם_רחוב": "הרב האדמור החלוץ"
        },
        {
            "_id": 9006,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 216,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 9007,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 533,
            "שם_רחוב": "הרב יוסף עזרן"
        },
        {
            "_id": 9008,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 535,
            "שם_רחוב": "הרב יעקב יוסף"
        },
        {
            "_id": 9009,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 536,
            "שם_רחוב": "הרב יצחק כדורי"
        },
        {
            "_id": 9010,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 554,
            "שם_רחוב": "הרב ישראל גרוסמן"
        },
        {
            "_id": 9011,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 555,
            "שם_רחוב": "הרב ליפקוביץ"
        },
        {
            "_id": 9012,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 213,
            "שם_רחוב": "הרב מבריסק"
        },
        {
            "_id": 9013,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 537,
            "שם_רחוב": "הרב מנחם פרוש"
        },
        {
            "_id": 9014,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 538,
            "שם_רחוב": "הרב מרדכי צמח אליהו"
        },
        {
            "_id": 9015,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 214,
            "שם_רחוב": "הרב ספקטור"
        },
        {
            "_id": 9016,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 539,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 9017,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 546,
            "שם_רחוב": "הרב שלמה זלמן"
        },
        {
            "_id": 9018,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 540,
            "שם_רחוב": "הרב שלמה לורניץ"
        },
        {
            "_id": 9019,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 279,
            "שם_רחוב": "הרטוב"
        },
        {
            "_id": 9020,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 9021,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 164,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 9022,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 547,
            "שם_רחוב": "הרשר הירש"
        },
        {
            "_id": 9023,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 144,
            "שם_רחוב": "השבעה"
        },
        {
            "_id": 9024,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 190,
            "שם_רחוב": "השושן"
        },
        {
            "_id": 9025,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 277,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 9026,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 261,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 9027,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 377,
            "שם_רחוב": "השלושה"
        },
        {
            "_id": 9028,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 131,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 9029,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 262,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 9030,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 246,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 9031,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 302,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 9032,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 271,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 9033,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 307,
            "שם_רחוב": "וירג'יניה"
        },
        {
            "_id": 9034,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 207,
            "שם_רחוב": "ורד"
        },
        {
            "_id": 9035,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 9036,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 209,
            "שם_רחוב": "זכרון יעקב"
        },
        {
            "_id": 9037,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 480,
            "שם_רחוב": "זכריה הנביא"
        },
        {
            "_id": 9038,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 568,
            "שם_רחוב": "זעירי"
        },
        {
            "_id": 9039,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 382,
            "שם_רחוב": "חביבה רייך"
        },
        {
            "_id": 9040,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 481,
            "שם_רחוב": "חבקוק הנביא"
        },
        {
            "_id": 9041,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 485,
            "שם_רחוב": "חברון"
        },
        {
            "_id": 9042,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 384,
            "שם_רחוב": "חגואל"
        },
        {
            "_id": 9043,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 426,
            "שם_רחוב": "חגי הנביא"
        },
        {
            "_id": 9044,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 314,
            "שם_רחוב": "חולדה הנביאה"
        },
        {
            "_id": 9045,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 309,
            "שם_רחוב": "חושן"
        },
        {
            "_id": 9046,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 212,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 9047,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 186,
            "שם_רחוב": "חידא"
        },
        {
            "_id": 9048,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 541,
            "שם_רחוב": "חלקת יהושע"
        },
        {
            "_id": 9049,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 444,
            "שם_רחוב": "חצרות ערבי הנחל"
        },
        {
            "_id": 9050,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 488,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 9051,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 321,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 9052,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 299,
            "שם_רחוב": "טורקיז"
        },
        {
            "_id": 9053,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 372,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 9054,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 378,
            "שם_רחוב": "יאיר"
        },
        {
            "_id": 9055,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 489,
            "שם_רחוב": "יבנה"
        },
        {
            "_id": 9056,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 255,
            "שם_רחוב": "ידין יגאל"
        },
        {
            "_id": 9057,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 294,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 9058,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 180,
            "שם_רחוב": "יהודה המכבי"
        },
        {
            "_id": 9059,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 317,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 9060,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 484,
            "שם_רחוב": "יואל הנביא"
        },
        {
            "_id": 9061,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 542,
            "שם_רחוב": "יוכבד"
        },
        {
            "_id": 9062,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 427,
            "שם_רחוב": "יונה בן אמיתי"
        },
        {
            "_id": 9063,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 376,
            "שם_רחוב": "יונתן"
        },
        {
            "_id": 9064,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 183,
            "שם_רחוב": "יונתן בן עוזיאל"
        },
        {
            "_id": 9065,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 523,
            "שם_רחוב": "יחזקאל הנביא"
        },
        {
            "_id": 9066,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 204,
            "שם_רחוב": "יצחק שדה"
        },
        {
            "_id": 9067,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 490,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 9068,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 524,
            "שם_רחוב": "ירמיהו הנביא"
        },
        {
            "_id": 9069,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 521,
            "שם_רחוב": "ישעיהו הנביא"
        },
        {
            "_id": 9070,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 185,
            "שם_רחוב": "כביש 38"
        },
        {
            "_id": 9071,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר נוימן"
        },
        {
            "_id": 9072,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 221,
            "שם_רחוב": "לב שמחה"
        },
        {
            "_id": 9073,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 239,
            "שם_רחוב": "לוין אריה"
        },
        {
            "_id": 9074,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 235,
            "שם_רחוב": "לנר דן"
        },
        {
            "_id": 9075,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 241,
            "שם_רחוב": "לסקוב חיים"
        },
        {
            "_id": 9076,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 236,
            "שם_רחוב": "מגן קלמן"
        },
        {
            "_id": 9077,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 153,
            "שם_רחוב": "מורדי הגטאות"
        },
        {
            "_id": 9078,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 157,
            "שם_רחוב": "מורי יעקב"
        },
        {
            "_id": 9079,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 501,
            "שם_רחוב": "מחזיקי הדת"
        },
        {
            "_id": 9080,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 282,
            "שם_רחוב": "מיטל"
        },
        {
            "_id": 9081,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 518,
            "שם_רחוב": "מלאכי הנביא"
        },
        {
            "_id": 9082,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 238,
            "שם_רחוב": "מנדלר אלברט"
        },
        {
            "_id": 9083,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 118,
            "שם_רחוב": "מנוח"
        },
        {
            "_id": 9084,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 296,
            "שם_רחוב": "מנור אהוד"
        },
        {
            "_id": 9085,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 228,
            "שם_רחוב": "מנחת יצחק"
        },
        {
            "_id": 9086,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 132,
            "שם_רחוב": "מעלה דוד"
        },
        {
            "_id": 9087,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 133,
            "שם_רחוב": "מעלה הסיירים"
        },
        {
            "_id": 9088,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 134,
            "שם_רחוב": "מעלה הצופים"
        },
        {
            "_id": 9089,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 135,
            "שם_רחוב": "מעלה יאיר"
        },
        {
            "_id": 9090,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 500,
            "שם_רחוב": "מעלות הרמא"
        },
        {
            "_id": 9091,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 230,
            "שם_רחוב": "מעפילי אגוז"
        },
        {
            "_id": 9092,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 354,
            "שם_רחוב": "מערבא"
        },
        {
            "_id": 9093,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 225,
            "שם_רחוב": "מעשי חייא"
        },
        {
            "_id": 9094,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 139,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 9095,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 494,
            "שם_רחוב": "מצפה הרשפ"
        },
        {
            "_id": 9096,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 256,
            "שם_רחוב": "מקלף מרדכי"
        },
        {
            "_id": 9097,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 560,
            "שם_רחוב": "מר זוטרא"
        },
        {
            "_id": 9098,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 564,
            "שם_רחוב": "מר ינוקא"
        },
        {
            "_id": 9099,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 350,
            "שם_רחוב": "מר עוקבא"
        },
        {
            "_id": 9100,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 522,
            "שם_רחוב": "מרים הנביאה"
        },
        {
            "_id": 9101,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 151,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 9102,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 381,
            "שם_רחוב": "משה ארנס"
        },
        {
            "_id": 9103,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 520,
            "שם_רחוב": "משה רבנו"
        },
        {
            "_id": 9104,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 108,
            "שם_רחוב": "משטרת הרטוב"
        },
        {
            "_id": 9105,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 259,
            "שם_רחוב": "משעול המתמיד"
        },
        {
            "_id": 9106,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 410,
            "שם_רחוב": "נהר הירדן"
        },
        {
            "_id": 9107,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 420,
            "שם_רחוב": "נהר הירקון"
        },
        {
            "_id": 9108,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 351,
            "שם_רחוב": "נהרדעא"
        },
        {
            "_id": 9109,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "נוה ספיר"
        },
        {
            "_id": 9110,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 544,
            "שם_רחוב": "נועם אלימלך"
        },
        {
            "_id": 9111,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "נופי אביב"
        },
        {
            "_id": 9112,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 178,
            "שם_רחוב": "נופי הדר"
        },
        {
            "_id": 9113,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 428,
            "שם_רחוב": "נחום הנביא"
        },
        {
            "_id": 9114,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 411,
            "שם_רחוב": "נחל אוריה"
        },
        {
            "_id": 9115,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 412,
            "שם_רחוב": "נחל איילון"
        },
        {
            "_id": 9116,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 441,
            "שם_רחוב": "נחל אכזיב"
        },
        {
            "_id": 9117,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 409,
            "שם_רחוב": "נחל אל-על"
        },
        {
            "_id": 9118,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 408,
            "שם_רחוב": "נחל אלכסנדר"
        },
        {
            "_id": 9119,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 413,
            "שם_רחוב": "נחל גילה"
        },
        {
            "_id": 9120,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 400,
            "שם_רחוב": "נחל דולב"
        },
        {
            "_id": 9121,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 439,
            "שם_רחוב": "נחל הבשור"
        },
        {
            "_id": 9122,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 419,
            "שם_רחוב": "נחל הקישון"
        },
        {
            "_id": 9123,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 433,
            "שם_רחוב": "נחל זוהר"
        },
        {
            "_id": 9124,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 430,
            "שם_רחוב": "נחל זויתן"
        },
        {
            "_id": 9125,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 434,
            "שם_רחוב": "נחל חבר"
        },
        {
            "_id": 9126,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 401,
            "שם_רחוב": "נחל יעלה"
        },
        {
            "_id": 9127,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 414,
            "שם_רחוב": "נחל לוז"
        },
        {
            "_id": 9128,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 415,
            "שם_רחוב": "נחל לכיש"
        },
        {
            "_id": 9129,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 402,
            "שם_רחוב": "נחל מאור"
        },
        {
            "_id": 9130,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 403,
            "שם_רחוב": "נחל מטע"
        },
        {
            "_id": 9131,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 416,
            "שם_רחוב": "נחל מיכה"
        },
        {
            "_id": 9132,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 442,
            "שם_רחוב": "נחל משמר"
        },
        {
            "_id": 9133,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 418,
            "שם_רחוב": "נחל נועם"
        },
        {
            "_id": 9134,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 417,
            "שם_רחוב": "נחל נחשון"
        },
        {
            "_id": 9135,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 443,
            "שם_רחוב": "נחל ניצנים"
        },
        {
            "_id": 9136,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 445,
            "שם_רחוב": "נחל סנסן"
        },
        {
            "_id": 9137,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 435,
            "שם_רחוב": "נחל עין גדי"
        },
        {
            "_id": 9138,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 436,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 9139,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 530,
            "שם_רחוב": "נחל פולג"
        },
        {
            "_id": 9140,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 548,
            "שם_רחוב": "נחל פרת"
        },
        {
            "_id": 9141,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 543,
            "שם_רחוב": "נחל קדרון"
        },
        {
            "_id": 9142,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 404,
            "שם_רחוב": "נחל קטלב"
        },
        {
            "_id": 9143,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 440,
            "שם_רחוב": "נחל רביבים"
        },
        {
            "_id": 9144,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 405,
            "שם_רחוב": "נחל רזיאל"
        },
        {
            "_id": 9145,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 421,
            "שם_רחוב": "נחל רמות"
        },
        {
            "_id": 9146,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 438,
            "שם_רחוב": "נחל רפאים"
        },
        {
            "_id": 9147,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 422,
            "שם_רחוב": "נחל שורק"
        },
        {
            "_id": 9148,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 423,
            "שם_רחוב": "נחל שחם"
        },
        {
            "_id": 9149,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 406,
            "שם_רחוב": "נחל שמשון"
        },
        {
            "_id": 9150,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 429,
            "שם_רחוב": "נחל שניר"
        },
        {
            "_id": 9151,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 407,
            "שם_רחוב": "נחל תמנע"
        },
        {
            "_id": 9152,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 424,
            "שם_רחוב": "נחל תמר"
        },
        {
            "_id": 9153,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 516,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 9154,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 295,
            "שם_רחוב": "נעמי שמר"
        },
        {
            "_id": 9155,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 253,
            "שם_רחוב": "נפתלי"
        },
        {
            "_id": 9156,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 447,
            "שם_רחוב": "נריה הנביא"
        },
        {
            "_id": 9157,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 196,
            "שם_רחוב": "נתיב מאיר"
        },
        {
            "_id": 9158,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 313,
            "שם_רחוב": "נתן הנביא"
        },
        {
            "_id": 9159,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 210,
            "שם_רחוב": "סולם יעקב"
        },
        {
            "_id": 9160,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 360,
            "שם_רחוב": "סורא"
        },
        {
            "_id": 9161,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 208,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 9162,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 137,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 9163,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 206,
            "שם_רחוב": "סיתוונית"
        },
        {
            "_id": 9164,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 322,
            "שם_רחוב": "סלע"
        },
        {
            "_id": 9165,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 199,
            "שם_רחוב": "סמ בן אליעזר"
        },
        {
            "_id": 9166,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 181,
            "שם_רחוב": "סמ האלון"
        },
        {
            "_id": 9167,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 154,
            "שם_רחוב": "סמ הגפן"
        },
        {
            "_id": 9168,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 143,
            "שם_רחוב": "סמ ויצו"
        },
        {
            "_id": 9169,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 249,
            "שם_רחוב": "סמ עלית הנוער"
        },
        {
            "_id": 9170,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 117,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 9171,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 383,
            "שם_רחוב": "סנש"
        },
        {
            "_id": 9172,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 320,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 9173,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 517,
            "שם_רחוב": "עזרא"
        },
        {
            "_id": 9174,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 519,
            "שם_רחוב": "עידו הנביא"
        },
        {
            "_id": 9175,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 250,
            "שם_רחוב": "עלית הנוער"
        },
        {
            "_id": 9176,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 114,
            "שם_רחוב": "עמק הזיתים"
        },
        {
            "_id": 9177,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 452,
            "שם_רחוב": "עקביא בן מהללאל"
        },
        {
            "_id": 9178,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 355,
            "שם_רחוב": "פומפדיתא"
        },
        {
            "_id": 9179,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "פסגות השבע"
        },
        {
            "_id": 9180,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 497,
            "שם_רחוב": "פעמי משיח"
        },
        {
            "_id": 9181,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 374,
            "שם_רחוב": "פרנק"
        },
        {
            "_id": 9182,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 375,
            "שם_רחוב": "פרנקל"
        },
        {
            "_id": 9183,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 177,
            "שם_רחוב": "פרס ראובן"
        },
        {
            "_id": 9184,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 491,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 9185,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "צריפים אדומים"
        },
        {
            "_id": 9186,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 197,
            "שם_רחוב": "קארו יוסף"
        },
        {
            "_id": 9187,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 457,
            "שם_רחוב": "קדושת אהרון"
        },
        {
            "_id": 9188,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 218,
            "שם_רחוב": "קהילת יעקב"
        },
        {
            "_id": 9189,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 486,
            "שם_רחוב": "קריית ארבע"
        },
        {
            "_id": 9190,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 324,
            "שם_רחוב": "קריסטל"
        },
        {
            "_id": 9191,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "קרית אריה"
        },
        {
            "_id": 9192,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "קרית בן אליעזר"
        },
        {
            "_id": 9193,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "קרית החינוך"
        },
        {
            "_id": 9194,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "קרית הרב ניסים"
        },
        {
            "_id": 9195,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "קרית הרמא"
        },
        {
            "_id": 9196,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 147,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 9197,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 234,
            "שם_רחוב": "ראובן"
        },
        {
            "_id": 9198,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 357,
            "שם_רחוב": "רב אבדימי"
        },
        {
            "_id": 9199,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 332,
            "שם_רחוב": "רב אבהו"
        },
        {
            "_id": 9200,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 338,
            "שם_רחוב": "רב אמי"
        },
        {
            "_id": 9201,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 342,
            "שם_רחוב": "רב אמימר"
        },
        {
            "_id": 9202,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 339,
            "שם_רחוב": "רב אשי"
        },
        {
            "_id": 9203,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 349,
            "שם_רחוב": "רב המנונא"
        },
        {
            "_id": 9204,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 337,
            "שם_רחוב": "רב זביד"
        },
        {
            "_id": 9205,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 343,
            "שם_רחוב": "רב זירא"
        },
        {
            "_id": 9206,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 563,
            "שם_רחוב": "רב חלקיה בר טובי"
        },
        {
            "_id": 9207,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 345,
            "שם_רחוב": "רב חנן"
        },
        {
            "_id": 9208,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 565,
            "שם_רחוב": "רב חננאל"
        },
        {
            "_id": 9209,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 561,
            "שם_רחוב": "רב חסדא"
        },
        {
            "_id": 9210,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 569,
            "שם_רחוב": "רב ייבא סבא"
        },
        {
            "_id": 9211,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 353,
            "שם_רחוב": "רב כהנא"
        },
        {
            "_id": 9212,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 348,
            "שם_רחוב": "רב מנשיא"
        },
        {
            "_id": 9213,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 359,
            "שם_רחוב": "רב מרי"
        },
        {
            "_id": 9214,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 567,
            "שם_רחוב": "רב משרשיא"
        },
        {
            "_id": 9215,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 341,
            "שם_רחוב": "רב עולא"
        },
        {
            "_id": 9216,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 358,
            "שם_רחוב": "רב עמרם"
        },
        {
            "_id": 9217,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 336,
            "שם_רחוב": "רב פפא"
        },
        {
            "_id": 9218,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 346,
            "שם_רחוב": "רב שילא"
        },
        {
            "_id": 9219,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 331,
            "שם_רחוב": "רב ששת"
        },
        {
            "_id": 9220,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 335,
            "שם_רחוב": "רב תנחומא"
        },
        {
            "_id": 9221,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 344,
            "שם_רחוב": "רבא"
        },
        {
            "_id": 9222,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 465,
            "שם_רחוב": "רבי אלעזר"
        },
        {
            "_id": 9223,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 557,
            "שם_רחוב": "רבי ברכיה"
        },
        {
            "_id": 9224,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 562,
            "שם_רחוב": "רבי חזקיה"
        },
        {
            "_id": 9225,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 571,
            "שם_רחוב": "רבי חלבו"
        },
        {
            "_id": 9226,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 558,
            "שם_רחוב": "רבי חנינא"
        },
        {
            "_id": 9227,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 468,
            "שם_רחוב": "רבי טרפון"
        },
        {
            "_id": 9228,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 463,
            "שם_רחוב": "רבי יהודה הנשיא"
        },
        {
            "_id": 9229,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 462,
            "שם_רחוב": "רבי יהושע"
        },
        {
            "_id": 9230,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 451,
            "שם_רחוב": "רבי יוסי בן קיסמא"
        },
        {
            "_id": 9231,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 458,
            "שם_רחוב": "רבי ינאי"
        },
        {
            "_id": 9232,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 559,
            "שם_רחוב": "רבי יצחק נפחא"
        },
        {
            "_id": 9233,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 456,
            "שם_רחוב": "רבי ישמעאל"
        },
        {
            "_id": 9234,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 464,
            "שם_רחוב": "רבי לויטס"
        },
        {
            "_id": 9235,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 467,
            "שם_רחוב": "רבי מאיר בעל הנס"
        },
        {
            "_id": 9236,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 460,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 9237,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 454,
            "שם_רחוב": "רבי פנחס בן יאיר"
        },
        {
            "_id": 9238,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 566,
            "שם_רחוב": "רבי פרדא"
        },
        {
            "_id": 9239,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 469,
            "שם_רחוב": "רבי צדוק"
        },
        {
            "_id": 9240,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 198,
            "שם_רחוב": "רבי צדקה יהודה"
        },
        {
            "_id": 9241,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 334,
            "שם_רחוב": "רבינא"
        },
        {
            "_id": 9242,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 159,
            "שם_רחוב": "רביצקי"
        },
        {
            "_id": 9243,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 471,
            "שם_רחוב": "רבן יוחנן בן זכאי"
        },
        {
            "_id": 9244,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 217,
            "שם_רחוב": "רבנו חיים הלוי"
        },
        {
            "_id": 9245,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 283,
            "שם_רחוב": "רויטל"
        },
        {
            "_id": 9246,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 373,
            "שם_רחוב": "רועי קליין"
        },
        {
            "_id": 9247,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 514,
            "שם_רחוב": "רות"
        },
        {
            "_id": 9248,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 258,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 9249,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 298,
            "שם_רחוב": "רחל המשוררת"
        },
        {
            "_id": 9250,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 470,
            "שם_רחוב": "ריבל"
        },
        {
            "_id": 9251,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 182,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 9252,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 347,
            "שם_רחוב": "ריש לקיש"
        },
        {
            "_id": 9253,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 140,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 9254,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "רמת בית שמש"
        },
        {
            "_id": 9255,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "רמת בית שמש ב"
        },
        {
            "_id": 9256,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רמת לחי"
        },
        {
            "_id": 9257,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 205,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 9258,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 461,
            "שם_רחוב": "רשבג"
        },
        {
            "_id": 9259,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 466,
            "שם_רחוב": "רשבי"
        },
        {
            "_id": 9260,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "ש 160"
        },
        {
            "_id": 9261,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "ש 144"
        },
        {
            "_id": 9262,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "ש 116"
        },
        {
            "_id": 9263,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש ותיקים"
        },
        {
            "_id": 9264,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש חצרות"
        },
        {
            "_id": 9265,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש משק עזר"
        },
        {
            "_id": 9266,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "ש קוטג'"
        },
        {
            "_id": 9267,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "ש רמת לחי"
        },
        {
            "_id": 9268,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 512,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 9269,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 171,
            "שם_רחוב": "שביל האשל"
        },
        {
            "_id": 9270,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 437,
            "שם_רחוב": "שביל הגיחון"
        },
        {
            "_id": 9271,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שביל הלה"
        },
        {
            "_id": 9272,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 446,
            "שם_רחוב": "שביל הנחש"
        },
        {
            "_id": 9273,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 156,
            "שם_רחוב": "שביל התמרים"
        },
        {
            "_id": 9274,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 200,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 9275,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 162,
            "שם_רחוב": "שד הדקל"
        },
        {
            "_id": 9276,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 201,
            "שם_רחוב": "שד נהר הדן"
        },
        {
            "_id": 9277,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 549,
            "שם_רחוב": "שד נחל נובע"
        },
        {
            "_id": 9278,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 492,
            "שם_רחוב": "שד נחל צאלים"
        },
        {
            "_id": 9279,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 333,
            "שם_רחוב": "שד' האמוראים"
        },
        {
            "_id": 9280,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 119,
            "שם_רחוב": "שועלי שמשון"
        },
        {
            "_id": 9281,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ הגפן"
        },
        {
            "_id": 9282,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "שכ השחר"
        },
        {
            "_id": 9283,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "שכ מחסיה"
        },
        {
            "_id": 9284,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6534,
            "שם_רחוב": "שכ קרית חזון עובדיה"
        },
        {
            "_id": 9285,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "שכ רמת התנאים"
        },
        {
            "_id": 9286,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 510,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 9287,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 493,
            "שם_רחוב": "שמואל הנביא"
        },
        {
            "_id": 9288,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 233,
            "שם_רחוב": "שמעון"
        },
        {
            "_id": 9289,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 227,
            "שם_רחוב": "שער השמיים"
        },
        {
            "_id": 9290,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 219,
            "שם_רחוב": "שפת אמת"
        },
        {
            "_id": 9291,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 172,
            "שם_רחוב": "שקמה"
        },
        {
            "_id": 9292,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 267,
            "שם_רחוב": "תאשור"
        },
        {
            "_id": 9293,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 175,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 9294,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 107,
            "שם_רחוב": "תחנת הרכבת"
        },
        {
            "_id": 9295,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 459,
            "שם_רחוב": "תכלת מרדכי"
        },
        {
            "_id": 9296,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 352,
            "שם_רחוב": "תלמוד בבלי"
        },
        {
            "_id": 9297,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 356,
            "שם_רחוב": "תלמוד ירושלמי"
        },
        {
            "_id": 9298,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 545,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 9299,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 160,
            "שם_רחוב": "תפארת משה"
        },
        {
            "_id": 9300,
            "סמל_ישוב": 2610,
            "שם_ישוב": "בית שמש ",
            "סמל_רחוב": 525,
            "שם_רחוב": "תפארת נפתלי"
        },
        {
            "_id": 9301,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "ארגון אפרים"
        },
        {
            "_id": 9302,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית שערים"
        },
        {
            "_id": 9303,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דרך הנוער"
        },
        {
            "_id": 9304,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך נהלל"
        },
        {
            "_id": 9305,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "דרך רמת ישי"
        },
        {
            "_id": 9306,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 9307,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "העץ הגדול"
        },
        {
            "_id": 9308,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הפרסה"
        },
        {
            "_id": 9309,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 9310,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "משעול האשכולית"
        },
        {
            "_id": 9311,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "משעול הגן"
        },
        {
            "_id": 9312,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "משעול הלימון"
        },
        {
            "_id": 9313,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "משעול הנרקיסים"
        },
        {
            "_id": 9314,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "משעול הפקאן"
        },
        {
            "_id": 9315,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "משעול הרקפות"
        },
        {
            "_id": 9316,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "משעול התפוז"
        },
        {
            "_id": 9317,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שביל החלב"
        },
        {
            "_id": 9318,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ גבעת הכלניות"
        },
        {
            "_id": 9319,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ הגורן"
        },
        {
            "_id": 9320,
            "סמל_ישוב": 248,
            "שם_ישוב": "בית שערים ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ הפרדס"
        },
        {
            "_id": 9321,
            "סמל_ישוב": 747,
            "שם_ישוב": "בית שקמה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בית שקמה"
        },
        {
            "_id": 9322,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "בית יערי"
        },
        {
            "_id": 9323,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ביתן אהרן"
        },
        {
            "_id": 9324,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בת חן"
        },
        {
            "_id": 9325,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך האילנות"
        },
        {
            "_id": 9326,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך הבאר"
        },
        {
            "_id": 9327,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך המשתלה"
        },
        {
            "_id": 9328,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 9329,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 9330,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 9331,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 9332,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 6000,
            "שם_רחוב": "ככר המייסדים"
        },
        {
            "_id": 9333,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "סמ הבנים"
        },
        {
            "_id": 9334,
            "סמל_ישוב": 252,
            "שם_ישוב": "ביתן אהרן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שדרת הפיקוסים"
        },
        {
            "_id": 9335,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 9336,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אדמורי באיאן"
        },
        {
            "_id": 9337,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אדמורי ויז'ניץ"
        },
        {
            "_id": 9338,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אוהב ישראל"
        },
        {
            "_id": 9339,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אוהל שרה"
        },
        {
            "_id": 9340,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אור אברהם"
        },
        {
            "_id": 9341,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אור החיים"
        },
        {
            "_id": 9342,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אורחות אהרון"
        },
        {
            "_id": 9343,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "אזור תעשיה הערבה"
        },
        {
            "_id": 9344,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 169,
            "שם_רחוב": "איגרות משה"
        },
        {
            "_id": 9345,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלעזר המודעי"
        },
        {
            "_id": 9346,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אם הבנים"
        },
        {
            "_id": 9347,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אמרי סופר"
        },
        {
            "_id": 9348,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אש קודש"
        },
        {
            "_id": 9349,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 193,
            "שם_רחוב": "באבא אלעזר"
        },
        {
            "_id": 9350,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 148,
            "שם_רחוב": "באר יעקב"
        },
        {
            "_id": 9351,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ביתר עילית"
        },
        {
            "_id": 9352,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 110,
            "שם_רחוב": "בן איש חי"
        },
        {
            "_id": 9353,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 202,
            "שם_רחוב": "בנין דוד"
        },
        {
            "_id": 9354,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "בעל התניא"
        },
        {
            "_id": 9355,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "גבעה A"
        },
        {
            "_id": 9356,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 129,
            "שם_רחוב": "דברי אמונה"
        },
        {
            "_id": 9357,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דברי חיים"
        },
        {
            "_id": 9358,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 119,
            "שם_רחוב": "דון יצחק אברבנאל"
        },
        {
            "_id": 9359,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 188,
            "שם_רחוב": "דרך בנימין הצדיק"
        },
        {
            "_id": 9360,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 229,
            "שם_רחוב": "דרך הרמז"
        },
        {
            "_id": 9361,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 226,
            "שם_רחוב": "דרכי איש"
        },
        {
            "_id": 9362,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 179,
            "שם_רחוב": "האדמור מבוסטון"
        },
        {
            "_id": 9363,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 136,
            "שם_רחוב": "האדמור מלעלוב"
        },
        {
            "_id": 9364,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 114,
            "שם_רחוב": "האדמור מסלונים"
        },
        {
            "_id": 9365,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 115,
            "שם_רחוב": "האדמור מרוז'ין"
        },
        {
            "_id": 9366,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 150,
            "שם_רחוב": "הבבא סאלי"
        },
        {
            "_id": 9367,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הבעל שם טוב"
        },
        {
            "_id": 9368,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגרא"
        },
        {
            "_id": 9369,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 151,
            "שם_רחוב": "הדף היומי"
        },
        {
            "_id": 9370,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הדר ביתר"
        },
        {
            "_id": 9371,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 227,
            "שם_רחוב": "החוזה מלובלין"
        },
        {
            "_id": 9372,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 109,
            "שם_רחוב": "החידא"
        },
        {
            "_id": 9373,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הטוב והמטיב"
        },
        {
            "_id": 9374,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 192,
            "שם_רחוב": "המביט"
        },
        {
            "_id": 9375,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 204,
            "שם_רחוב": "המגיד ממעזריטש"
        },
        {
            "_id": 9376,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 205,
            "שם_רחוב": "המהריץ"
        },
        {
            "_id": 9377,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 216,
            "שם_רחוב": "הרן"
        },
        {
            "_id": 9378,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 220,
            "שם_רחוב": "הרב אבא שאול"
        },
        {
            "_id": 9379,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 162,
            "שם_רחוב": "הרב אוירבך"
        },
        {
            "_id": 9380,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 146,
            "שם_רחוב": "הרב אטון"
        },
        {
            "_id": 9381,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 138,
            "שם_רחוב": "הרב אלישיב"
        },
        {
            "_id": 9382,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הרב אריאלי יצחק"
        },
        {
            "_id": 9383,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הרב בנדר"
        },
        {
            "_id": 9384,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 147,
            "שם_רחוב": "הרב ברוידא"
        },
        {
            "_id": 9385,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 225,
            "שם_רחוב": "הרב ברוק בן-ציון"
        },
        {
            "_id": 9386,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 203,
            "שם_רחוב": "הרב ברים"
        },
        {
            "_id": 9387,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 187,
            "שם_רחוב": "הרב כדורי"
        },
        {
            "_id": 9388,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 218,
            "שם_רחוב": "הרב מוצפי יעקב"
        },
        {
            "_id": 9389,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 190,
            "שם_רחוב": "הרב מסלנט"
        },
        {
            "_id": 9390,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 180,
            "שם_רחוב": "הרב נאה חיים"
        },
        {
            "_id": 9391,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 182,
            "שם_רחוב": "הרב עטיה"
        },
        {
            "_id": 9392,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הרב צדקא יהודה"
        },
        {
            "_id": 9393,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 159,
            "שם_רחוב": "הרב קנייבסקי"
        },
        {
            "_id": 9394,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 172,
            "שם_רחוב": "הרב קעניג"
        },
        {
            "_id": 9395,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 186,
            "שם_רחוב": "הרב רוזובסקי"
        },
        {
            "_id": 9396,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 217,
            "שם_רחוב": "הרב רייטפארט יצחק"
        },
        {
            "_id": 9397,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 171,
            "שם_רחוב": "הרב רפול"
        },
        {
            "_id": 9398,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 174,
            "שם_רחוב": "הרב שבדרון שלום"
        },
        {
            "_id": 9399,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 223,
            "שם_רחוב": "הרב שך"
        },
        {
            "_id": 9400,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 157,
            "שם_רחוב": "הרבי מזוועהיל"
        },
        {
            "_id": 9401,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 161,
            "שם_רחוב": "הרבי מליובאוויטש"
        },
        {
            "_id": 9402,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 160,
            "שם_רחוב": "הרבי מנדבורנה"
        },
        {
            "_id": 9403,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 164,
            "שם_רחוב": "הרבי מקרעטשניף"
        },
        {
            "_id": 9404,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 191,
            "שם_רחוב": "הרמק"
        },
        {
            "_id": 9405,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הרמבן"
        },
        {
            "_id": 9406,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 116,
            "שם_רחוב": "התעוררות תשובה"
        },
        {
            "_id": 9407,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 206,
            "שם_רחוב": "חבד"
        },
        {
            "_id": 9408,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 128,
            "שם_רחוב": "חושן משפט"
        },
        {
            "_id": 9409,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 120,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 9410,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 207,
            "שם_רחוב": "חיי יצחק"
        },
        {
            "_id": 9411,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 196,
            "שם_רחוב": "חכמת אליעזר"
        },
        {
            "_id": 9412,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 189,
            "שם_רחוב": "חכמת חיים"
        },
        {
            "_id": 9413,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 168,
            "שם_רחוב": "חלקת יואב"
        },
        {
            "_id": 9414,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 166,
            "שם_רחוב": "חפץ חיים"
        },
        {
            "_id": 9415,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 104,
            "שם_רחוב": "חתם סופר"
        },
        {
            "_id": 9416,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 199,
            "שם_רחוב": "טוב דעת"
        },
        {
            "_id": 9417,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 139,
            "שם_רחוב": "יביע אומר"
        },
        {
            "_id": 9418,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 123,
            "שם_רחוב": "יוחנן בן זכאי"
        },
        {
            "_id": 9419,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 127,
            "שם_רחוב": "יורה דעה"
        },
        {
            "_id": 9420,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 209,
            "שם_רחוב": "יסוד העבודה"
        },
        {
            "_id": 9421,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 181,
            "שם_רחוב": "ישועות משה"
        },
        {
            "_id": 9422,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 144,
            "שם_רחוב": "ישיבת מיר"
        },
        {
            "_id": 9423,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 228,
            "שם_רחוב": "ישמח ישראל"
        },
        {
            "_id": 9424,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 221,
            "שם_רחוב": "כנסת יחזקאל"
        },
        {
            "_id": 9425,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 219,
            "שם_רחוב": "כנסת מרדכי"
        },
        {
            "_id": 9426,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 170,
            "שם_רחוב": "כף החיים"
        },
        {
            "_id": 9427,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 142,
            "שם_רחוב": "מאור עיניים"
        },
        {
            "_id": 9428,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 153,
            "שם_רחוב": "מבוא מרגלית"
        },
        {
            "_id": 9429,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 200,
            "שם_רחוב": "מעלות בת שבע"
        },
        {
            "_id": 9430,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 178,
            "שם_רחוב": "מעלות גאוני בריסק"
        },
        {
            "_id": 9431,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 135,
            "שם_רחוב": "מעלות דמשק אליעזר"
        },
        {
            "_id": 9432,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 231,
            "שם_רחוב": "מעלות הרב ליפקוביץ'"
        },
        {
            "_id": 9433,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 176,
            "שם_רחוב": "מעלות הרב מטשכנוב"
        },
        {
            "_id": 9434,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 134,
            "שם_רחוב": "מעלות הרב צדוק"
        },
        {
            "_id": 9435,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מעלות הרי יהודה"
        },
        {
            "_id": 9436,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 234,
            "שם_רחוב": "מעלות חיה מושקא"
        },
        {
            "_id": 9437,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 131,
            "שם_רחוב": "מעלות חקר ועיון"
        },
        {
            "_id": 9438,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 233,
            "שם_רחוב": "מעלות יעל"
        },
        {
            "_id": 9439,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 124,
            "שם_רחוב": "מעלות ירושלים"
        },
        {
            "_id": 9440,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 132,
            "שם_רחוב": "מעלות כתר תורה"
        },
        {
            "_id": 9441,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 235,
            "שם_רחוב": "מעלות מנחת יהודה"
        },
        {
            "_id": 9442,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 198,
            "שם_רחוב": "מעלות פאר הריבש"
        },
        {
            "_id": 9443,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 232,
            "שם_רחוב": "מעלות רבי אהרון"
        },
        {
            "_id": 9444,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 175,
            "שם_רחוב": "מעלות רבי הלל"
        },
        {
            "_id": 9445,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 133,
            "שם_רחוב": "מעלות שושנה"
        },
        {
            "_id": 9446,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 155,
            "שם_רחוב": "מפעל השס"
        },
        {
            "_id": 9447,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 167,
            "שם_רחוב": "נעם אלימלך"
        },
        {
            "_id": 9448,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 143,
            "שם_רחוב": "נתיבות שלום"
        },
        {
            "_id": 9449,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 145,
            "שם_רחוב": "עקבי אבירים"
        },
        {
            "_id": 9450,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 211,
            "שם_רחוב": "פחד יצחק"
        },
        {
            "_id": 9451,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 212,
            "שם_רחוב": "פנים מאירים"
        },
        {
            "_id": 9452,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 173,
            "שם_רחוב": "צמח צדק"
        },
        {
            "_id": 9453,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 237,
            "שם_רחוב": "צפנת פענח"
        },
        {
            "_id": 9454,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 108,
            "שם_רחוב": "קארו יוסף"
        },
        {
            "_id": 9455,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 213,
            "שם_רחוב": "קדושת לוי"
        },
        {
            "_id": 9456,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 230,
            "שם_רחוב": "קדושת ציון"
        },
        {
            "_id": 9457,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 154,
            "שם_רחוב": "רבי נחמן מברסלב"
        },
        {
            "_id": 9458,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 9459,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רבי שבזי שלום"
        },
        {
            "_id": 9460,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 140,
            "שם_רחוב": "שבט הלוי"
        },
        {
            "_id": 9461,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 130,
            "שם_רחוב": "שד דרך הארי"
        },
        {
            "_id": 9462,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 165,
            "שם_רחוב": "שדה חמד"
        },
        {
            "_id": 9463,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ האתרוג"
        },
        {
            "_id": 9464,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ הגפן"
        },
        {
            "_id": 9465,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "שכ הדס"
        },
        {
            "_id": 9466,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ הדקל"
        },
        {
            "_id": 9467,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ התמר"
        },
        {
            "_id": 9468,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 214,
            "שם_רחוב": "שלום רב"
        },
        {
            "_id": 9469,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 197,
            "שם_רחוב": "שמן למאור"
        },
        {
            "_id": 9470,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 195,
            "שם_רחוב": "שער לוי"
        },
        {
            "_id": 9471,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 141,
            "שם_רחוב": "שפע חיים"
        },
        {
            "_id": 9472,
            "סמל_ישוב": 3780,
            "שם_ישוב": "ביתר עילית ",
            "סמל_רחוב": 215,
            "שם_רחוב": "תומכי תמימים"
        },
        {
            "_id": 9473,
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 9474,
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בלפוריה"
        },
        {
            "_id": 9475,
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 9476,
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 9477,
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 9478,
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 9479,
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 9480,
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 9481,
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 9482,
            "סמל_ישוב": 94,
            "שם_ישוב": "בלפוריה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שבעת המינים"
        },
        {
            "_id": 9483,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בן זכאי"
        },
        {
            "_id": 9484,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 9485,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 107,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 9486,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 110,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 9487,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 9488,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 9489,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 9490,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 9491,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 105,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 9492,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 9493,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 9494,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 9495,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 9496,
            "סמל_ישוב": 760,
            "שם_ישוב": "בן זכאי ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 9497,
            "סמל_ישוב": 712,
            "שם_ישוב": "בן עמי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בן עמי"
        },
        {
            "_id": 9498,
            "סמל_ישוב": 712,
            "שם_ישוב": "בן עמי ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך פרחי הבר"
        },
        {
            "_id": 9499,
            "סמל_ישוב": 712,
            "שם_ישוב": "בן עמי ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שביל החרציות"
        },
        {
            "_id": 9500,
            "סמל_ישוב": 712,
            "שם_ישוב": "בן עמי ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שביל הנרקיסים"
        },
        {
            "_id": 9501,
            "סמל_ישוב": 712,
            "שם_ישוב": "בן עמי ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שביל הסביונים"
        },
        {
            "_id": 9502,
            "סמל_ישוב": 712,
            "שם_ישוב": "בן עמי ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שביל הרקפות"
        },
        {
            "_id": 9503,
            "סמל_ישוב": 1084,
            "שם_ישוב": "בן שמן (כפר נוער) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בן שמן (כפר נוער)"
        },
        {
            "_id": 9504,
            "סמל_ישוב": 2013,
            "שם_ישוב": "בן שמן (מושב) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בן שמן (מושב)"
        },
        {
            "_id": 9505,
            "סמל_ישוב": 2013,
            "שם_ישוב": "בן שמן (מושב) ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך הגפן"
        },
        {
            "_id": 9506,
            "סמל_ישוב": 2013,
            "שם_ישוב": "בן שמן (מושב) ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הזית"
        },
        {
            "_id": 9507,
            "סמל_ישוב": 2013,
            "שם_ישוב": "בן שמן (מושב) ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך הרימון"
        },
        {
            "_id": 9508,
            "סמל_ישוב": 2013,
            "שם_ישוב": "בן שמן (מושב) ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך התמר"
        },
        {
            "_id": 9509,
            "סמל_ישוב": 2013,
            "שם_ישוב": "בן שמן (מושב) ",
            "סמל_רחוב": 105,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 9510,
            "סמל_ישוב": 2013,
            "שם_ישוב": "בן שמן (מושב) ",
            "סמל_רחוב": 106,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 9511,
            "סמל_ישוב": 2013,
            "שם_ישוב": "בן שמן (מושב) ",
            "סמל_רחוב": 107,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 9512,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 304,
            "שם_רחוב": "אבו חצירא ישראל"
        },
        {
            "_id": 9513,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 522,
            "שם_רחוב": "אבטליון"
        },
        {
            "_id": 9514,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 231,
            "שם_רחוב": "אביעד"
        },
        {
            "_id": 9515,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 507,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 9516,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 531,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 9517,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 530,
            "שם_רחוב": "אבן שפרוט"
        },
        {
            "_id": 9518,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 731,
            "שם_רחוב": "אבני נזר"
        },
        {
            "_id": 9519,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אברבנאל"
        },
        {
            "_id": 9520,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אדמורי אלכסנדר"
        },
        {
            "_id": 9521,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 815,
            "שם_רחוב": "אדמורי שאץ"
        },
        {
            "_id": 9522,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 817,
            "שם_רחוב": "אהבת שלום"
        },
        {
            "_id": 9523,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 835,
            "שם_רחוב": "אהרונוביץ ראובן"
        },
        {
            "_id": 9524,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 925,
            "שם_רחוב": "אהרונסון"
        },
        {
            "_id": 9525,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 729,
            "שם_רחוב": "אונקלוס"
        },
        {
            "_id": 9526,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אוסישקין"
        },
        {
            "_id": 9527,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 904,
            "שם_רחוב": "אור החיים"
        },
        {
            "_id": 9528,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 805,
            "שם_רחוב": "אורליאן"
        },
        {
            "_id": 9529,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "אזור תעשיה צפון"
        },
        {
            "_id": 9530,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 403,
            "שם_רחוב": "אחיה השילוני"
        },
        {
            "_id": 9531,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 515,
            "שם_רחוב": "אחיעזר"
        },
        {
            "_id": 9532,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 526,
            "שם_רחוב": "אלחריזי"
        },
        {
            "_id": 9533,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 843,
            "שם_רחוב": "אליהו הנביא"
        },
        {
            "_id": 9534,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 845,
            "שם_רחוב": "אלישע"
        },
        {
            "_id": 9535,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 721,
            "שם_רחוב": "אלקבץ"
        },
        {
            "_id": 9536,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 715,
            "שם_רחוב": "אלקלעי"
        },
        {
            "_id": 9537,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 887,
            "שם_רחוב": "אלרום"
        },
        {
            "_id": 9538,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 937,
            "שם_רחוב": "אלשיך"
        },
        {
            "_id": 9539,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 877,
            "שם_רחוב": "אמרי ברוך"
        },
        {
            "_id": 9540,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 816,
            "שם_רחוב": "אמרי חיים"
        },
        {
            "_id": 9541,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 934,
            "שם_רחוב": "אנילביץ"
        },
        {
            "_id": 9542,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 927,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 9543,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 422,
            "שם_רחוב": "אפשטיין פנחס"
        },
        {
            "_id": 9544,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 9545,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 724,
            "שם_רחוב": "אשל אברהם"
        },
        {
            "_id": 9546,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 893,
            "שם_רחוב": "באר מים חיים"
        },
        {
            "_id": 9547,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 949,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 9548,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 310,
            "שם_רחוב": "בגין מנחם"
        },
        {
            "_id": 9549,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 973,
            "שם_רחוב": "בגנו משה"
        },
        {
            "_id": 9550,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 219,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 9551,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 741,
            "שם_רחוב": "ביח מעיני הישועה"
        },
        {
            "_id": 9552,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 222,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 9553,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 601,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 9554,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 876,
            "שם_רחוב": "ביריה"
        },
        {
            "_id": 9555,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 801,
            "שם_רחוב": "בירנבוים"
        },
        {
            "_id": 9556,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 831,
            "שם_רחוב": "בית הלל"
        },
        {
            "_id": 9557,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 110,
            "שם_רחוב": "בית חורון"
        },
        {
            "_id": 9558,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 703,
            "שם_רחוב": "בית יוסף"
        },
        {
            "_id": 9559,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 889,
            "שם_רחוב": "בית שמאי"
        },
        {
            "_id": 9560,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 401,
            "שם_רחוב": "בן גוריון דוד"
        },
        {
            "_id": 9561,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 814,
            "שם_רחוב": "בן דוד אברהם"
        },
        {
            "_id": 9562,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 722,
            "שם_רחוב": "בן זומא"
        },
        {
            "_id": 9563,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 918,
            "שם_רחוב": "בן זכאי"
        },
        {
            "_id": 9564,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 312,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 9565,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 107,
            "שם_רחוב": "בן נון"
        },
        {
            "_id": 9566,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 981,
            "שם_רחוב": "בן נריה ברוך"
        },
        {
            "_id": 9567,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 440,
            "שם_רחוב": "בן סירא"
        },
        {
            "_id": 9568,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 732,
            "שם_רחוב": "בן עזאי"
        },
        {
            "_id": 9569,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 505,
            "שם_רחוב": "בן פתחיה"
        },
        {
            "_id": 9570,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 935,
            "שם_רחוב": "בן שטח"
        },
        {
            "_id": 9571,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בני ברק"
        },
        {
            "_id": 9572,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 215,
            "שם_רחוב": "בנימין אברהם"
        },
        {
            "_id": 9573,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 900,
            "שם_רחוב": "בעל התניא"
        },
        {
            "_id": 9574,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 901,
            "שם_רחוב": "בעל שם טוב"
        },
        {
            "_id": 9575,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 706,
            "שם_רחוב": "בר אילן"
        },
        {
            "_id": 9576,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 836,
            "שם_רחוב": "בר יוחאי"
        },
        {
            "_id": 9577,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 9578,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 710,
            "שם_רחוב": "ברויאר"
        },
        {
            "_id": 9579,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 717,
            "שם_רחוב": "ברוריה"
        },
        {
            "_id": 9580,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 913,
            "שם_רחוב": "ברטנורה"
        },
        {
            "_id": 9581,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 874,
            "שם_רחוב": "ברנדשטטר"
        },
        {
            "_id": 9582,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 863,
            "שם_רחוב": "בתי אבות"
        },
        {
            "_id": 9583,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 442,
            "שם_רחוב": "בתי גולדשטין"
        },
        {
            "_id": 9584,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 970,
            "שם_רחוב": "גאוני בריסק"
        },
        {
            "_id": 9585,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 727,
            "שם_רחוב": "גבורי ישראל"
        },
        {
            "_id": 9586,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "גבעת סוקולוב"
        },
        {
            "_id": 9587,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 871,
            "שם_רחוב": "גבעת פנחס"
        },
        {
            "_id": 9588,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "גבעת רוקח"
        },
        {
            "_id": 9589,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 718,
            "שם_רחוב": "גוטמכר"
        },
        {
            "_id": 9590,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 955,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 9591,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 946,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 9592,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 525,
            "שם_רחוב": "גן העצמאות"
        },
        {
            "_id": 9593,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 240,
            "שם_רחוב": "גניחובסקי"
        },
        {
            "_id": 9594,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 305,
            "שם_רחוב": "גרונר"
        },
        {
            "_id": 9595,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 232,
            "שם_רחוב": "גרינברג"
        },
        {
            "_id": 9596,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 921,
            "שם_רחוב": "גרשטנקורן"
        },
        {
            "_id": 9597,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 302,
            "שם_רחוב": "דר ברט"
        },
        {
            "_id": 9598,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 834,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 9599,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 415,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 9600,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 867,
            "שם_רחוב": "דון יוסף הנשיא"
        },
        {
            "_id": 9601,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 624,
            "שם_רחוב": "דונולו"
        },
        {
            "_id": 9602,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 872,
            "שם_רחוב": "דמשק אליעזר"
        },
        {
            "_id": 9603,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 329,
            "שם_רחוב": "דנגור"
        },
        {
            "_id": 9604,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 926,
            "שם_רחוב": "דניאל"
        },
        {
            "_id": 9605,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 539,
            "שם_רחוב": "דקר"
        },
        {
            "_id": 9606,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דרך אם המושבות"
        },
        {
            "_id": 9607,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 739,
            "שם_רחוב": "דרך הנשיא הראשון"
        },
        {
            "_id": 9608,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 917,
            "שם_רחוב": "האדמור מבלז"
        },
        {
            "_id": 9609,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 846,
            "שם_רחוב": "האדמור מגור"
        },
        {
            "_id": 9610,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 878,
            "שם_רחוב": "האדמור מויז'ניץ"
        },
        {
            "_id": 9611,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 890,
            "שם_רחוב": "האדמור מוישוי"
        },
        {
            "_id": 9612,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 822,
            "שם_רחוב": "האדמור מצנז"
        },
        {
            "_id": 9613,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 809,
            "שם_רחוב": "האדמור מקוצק"
        },
        {
            "_id": 9614,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 847,
            "שם_רחוב": "האדמור מראדזמין"
        },
        {
            "_id": 9615,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 418,
            "שם_רחוב": "האדמור מרוז'ין"
        },
        {
            "_id": 9616,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 818,
            "שם_רחוב": "האדמור מנדבורנא"
        },
        {
            "_id": 9617,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 625,
            "שם_רחוב": "האדמורים מספינקא"
        },
        {
            "_id": 9618,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 423,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 9619,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 208,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 9620,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 428,
            "שם_רחוב": "הגרא"
        },
        {
            "_id": 9621,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 225,
            "שם_רחוב": "הדסים"
        },
        {
            "_id": 9622,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 226,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 9623,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 221,
            "שם_רחוב": "הוז דב"
        },
        {
            "_id": 9624,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 612,
            "שם_רחוב": "הושע"
        },
        {
            "_id": 9625,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 207,
            "שם_רחוב": "החלוצים"
        },
        {
            "_id": 9626,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 828,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 9627,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 313,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 9628,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 881,
            "שם_רחוב": "הילדסהיימר"
        },
        {
            "_id": 9629,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 616,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 9630,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 203,
            "שם_רחוב": "הירקון"
        },
        {
            "_id": 9631,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הירש ברוך"
        },
        {
            "_id": 9632,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 513,
            "שם_רחוב": "הלוחמים"
        },
        {
            "_id": 9633,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 915,
            "שם_רחוב": "הלפרין"
        },
        {
            "_id": 9634,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 924,
            "שם_רחוב": "המהרל"
        },
        {
            "_id": 9635,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 309,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 9636,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 707,
            "שם_רחוב": "המר זבולון"
        },
        {
            "_id": 9637,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 210,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 9638,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 605,
            "שם_רחוב": "העליה השניה"
        },
        {
            "_id": 9639,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 716,
            "שם_רחוב": "הפלוגה הדתית"
        },
        {
            "_id": 9640,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 216,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 9641,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 884,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 9642,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הקישון"
        },
        {
            "_id": 9643,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 524,
            "שם_רחוב": "הר הבית"
        },
        {
            "_id": 9644,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 529,
            "שם_רחוב": "הר נבו"
        },
        {
            "_id": 9645,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 812,
            "שם_רחוב": "הר סיני"
        },
        {
            "_id": 9646,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 501,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 9647,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 248,
            "שם_רחוב": "הרב אברמסקי"
        },
        {
            "_id": 9648,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 984,
            "שם_רחוב": "הרב בארון קלמן"
        },
        {
            "_id": 9649,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 520,
            "שם_רחוב": "הרב בלוי"
        },
        {
            "_id": 9650,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 870,
            "שם_רחוב": "הרב בן יעקב"
        },
        {
            "_id": 9651,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 324,
            "שם_רחוב": "הרב ברוט"
        },
        {
            "_id": 9652,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 956,
            "שם_רחוב": "הרב גולד"
        },
        {
            "_id": 9653,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 959,
            "שם_רחוב": "הרב גרוסברד"
        },
        {
            "_id": 9654,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 911,
            "שם_רחוב": "הרב דסלר"
        },
        {
            "_id": 9655,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 939,
            "שם_רחוב": "הרב הירש"
        },
        {
            "_id": 9656,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 940,
            "שם_רחוב": "הרב וסרמן אלחנן"
        },
        {
            "_id": 9657,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 875,
            "שם_רחוב": "הרב ורנר"
        },
        {
            "_id": 9658,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 995,
            "שם_רחוב": "הרב זוננפלד יוסף"
        },
        {
            "_id": 9659,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 446,
            "שם_רחוב": "הרב זיטניצקי"
        },
        {
            "_id": 9660,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 996,
            "שם_רחוב": "הרב זמבא מנחם"
        },
        {
            "_id": 9661,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 213,
            "שם_רחוב": "הרב חדד שאול"
        },
        {
            "_id": 9662,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 898,
            "שם_רחוב": "הרב טולידנו"
        },
        {
            "_id": 9663,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 908,
            "שם_רחוב": "הרב יברוב משה"
        },
        {
            "_id": 9664,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 326,
            "שם_רחוב": "הרב יוסף חיים"
        },
        {
            "_id": 9665,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 997,
            "שם_רחוב": "הרב כהן שלמה"
        },
        {
            "_id": 9666,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 891,
            "שם_רחוב": "הרב כהנמן"
        },
        {
            "_id": 9667,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 957,
            "שם_רחוב": "הרב לנדא יעקב"
        },
        {
            "_id": 9668,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 888,
            "שם_רחוב": "הרב מיודסר"
        },
        {
            "_id": 9669,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 514,
            "שם_רחוב": "הרב מימון"
        },
        {
            "_id": 9670,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 842,
            "שם_רחוב": "הרב מלצר"
        },
        {
            "_id": 9671,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 250,
            "שם_רחוב": "הרב מנצור בן שמעון"
        },
        {
            "_id": 9672,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 245,
            "שם_רחוב": "הרב מצליח"
        },
        {
            "_id": 9673,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 713,
            "שם_רחוב": "הרב משה זאב פלדמן"
        },
        {
            "_id": 9674,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 807,
            "שם_רחוב": "הרב נסים יצחק"
        },
        {
            "_id": 9675,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 896,
            "שם_רחוב": "הרב סורוצקין"
        },
        {
            "_id": 9676,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 808,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 9677,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 998,
            "שם_רחוב": "הרב עטיה עזרה"
        },
        {
            "_id": 9678,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 975,
            "שם_רחוב": "הרב פוברסקי"
        },
        {
            "_id": 9679,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 734,
            "שם_רחוב": "הרב פרדו משה"
        },
        {
            "_id": 9680,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 327,
            "שם_רחוב": "הרב פתאיה"
        },
        {
            "_id": 9681,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 519,
            "שם_רחוב": "הרב קוטלר"
        },
        {
            "_id": 9682,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 408,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 9683,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 516,
            "שם_רחוב": "הרב קליש"
        },
        {
            "_id": 9684,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 234,
            "שם_רחוב": "הרב ראט משולם"
        },
        {
            "_id": 9685,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 430,
            "שם_רחוב": "הרב רבינוב"
        },
        {
            "_id": 9686,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 810,
            "שם_רחוב": "הרב רבינוביץ אריה"
        },
        {
            "_id": 9687,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 855,
            "שם_רחוב": "הרב רוזובסקי"
        },
        {
            "_id": 9688,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 540,
            "שם_רחוב": "הרב שטרסר"
        },
        {
            "_id": 9689,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 228,
            "שם_רחוב": "הרב שך"
        },
        {
            "_id": 9690,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 964,
            "שם_רחוב": "הרב שלום שלום"
        },
        {
            "_id": 9691,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 417,
            "שם_רחוב": "הרב שמידמן יצחק"
        },
        {
            "_id": 9692,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 332,
            "שם_רחוב": "הרב שמש דוד"
        },
        {
            "_id": 9693,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 711,
            "שם_רחוב": "הרב שפירא"
        },
        {
            "_id": 9694,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 533,
            "שם_רחוב": "הרב שר"
        },
        {
            "_id": 9695,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 237,
            "שם_רחוב": "הרי גולן"
        },
        {
            "_id": 9696,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 445,
            "שם_רחוב": "הרצוג"
        },
        {
            "_id": 9697,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 224,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 9698,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 837,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 9699,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 606,
            "שם_רחוב": "השלושה"
        },
        {
            "_id": 9700,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 218,
            "שם_רחוב": "השניים"
        },
        {
            "_id": 9701,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 249,
            "שם_רחוב": "השר אלון יגאל"
        },
        {
            "_id": 9702,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 883,
            "שם_רחוב": "השריונאים"
        },
        {
            "_id": 9703,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 444,
            "שם_רחוב": "התנאים"
        },
        {
            "_id": 9704,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 542,
            "שם_רחוב": "וגמן מ ב"
        },
        {
            "_id": 9705,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 922,
            "שם_רחוב": "וולפסון"
        },
        {
            "_id": 9706,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 912,
            "שם_רחוב": "וילקומירר"
        },
        {
            "_id": 9707,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 920,
            "שם_רחוב": "וינברג שמואל"
        },
        {
            "_id": 9708,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 243,
            "שם_רחוב": "וינרב"
        },
        {
            "_id": 9709,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 704,
            "שם_רחוב": "ועד ארבע ארצות"
        },
        {
            "_id": 9710,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 503,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 9711,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 607,
            "שם_רחוב": "זכריה"
        },
        {
            "_id": 9712,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 314,
            "שם_רחוב": "זרובבל"
        },
        {
            "_id": 9713,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 622,
            "שם_רחוב": "חבקוק"
        },
        {
            "_id": 9714,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 406,
            "שם_רחוב": "חברון"
        },
        {
            "_id": 9715,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 611,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 9716,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 945,
            "שם_רחוב": "חולדה הנביאה"
        },
        {
            "_id": 9717,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 830,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 9718,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 204,
            "שם_רחוב": "חי טייב"
        },
        {
            "_id": 9719,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 619,
            "שם_רחוב": "חידא"
        },
        {
            "_id": 9720,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 886,
            "שם_רחוב": "חידושי הרים"
        },
        {
            "_id": 9721,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 602,
            "שם_רחוב": "חנקין"
        },
        {
            "_id": 9722,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 910,
            "שם_רחוב": "חפץ חיים"
        },
        {
            "_id": 9723,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 412,
            "שם_רחוב": "חרלפ"
        },
        {
            "_id": 9724,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 610,
            "שם_רחוב": "חשב סופר"
        },
        {
            "_id": 9725,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 509,
            "שם_רחוב": "חתם סופר"
        },
        {
            "_id": 9726,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 405,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 9727,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 879,
            "שם_רחוב": "טכורש"
        },
        {
            "_id": 9728,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 873,
            "שם_רחוב": "טל חיים"
        },
        {
            "_id": 9729,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 220,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 9730,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 425,
            "שם_רחוב": "יגאל"
        },
        {
            "_id": 9731,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 517,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 9732,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 933,
            "שם_רחוב": "יהודה הנשיא"
        },
        {
            "_id": 9733,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 953,
            "שם_רחוב": "יהודית"
        },
        {
            "_id": 9734,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 420,
            "שם_רחוב": "יהושע"
        },
        {
            "_id": 9735,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 620,
            "שם_רחוב": "יואל"
        },
        {
            "_id": 9736,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 804,
            "שם_רחוב": "יוחנן הסנדלר"
        },
        {
            "_id": 9737,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 844,
            "שם_רחוב": "יונה הנביא"
        },
        {
            "_id": 9738,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 311,
            "שם_רחוב": "יונתן"
        },
        {
            "_id": 9739,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 433,
            "שם_רחוב": "יחזקאל"
        },
        {
            "_id": 9740,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 714,
            "שם_רחוב": "יעבץ"
        },
        {
            "_id": 9741,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 947,
            "שם_רחוב": "יעל"
        },
        {
            "_id": 9742,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 961,
            "שם_רחוב": "יפה"
        },
        {
            "_id": 9743,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 897,
            "שם_רחוב": "יצחק מאיר הכהן"
        },
        {
            "_id": 9744,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 626,
            "שם_רחוב": "יצחק נפחא"
        },
        {
            "_id": 9745,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 414,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 9746,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 432,
            "שם_רחוב": "ירמיהו"
        },
        {
            "_id": 9747,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 866,
            "שם_רחוב": "ישיבת בית מאיר"
        },
        {
            "_id": 9748,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 895,
            "שם_רחוב": "ישיבת ויז'ניץ"
        },
        {
            "_id": 9749,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 829,
            "שם_רחוב": "ישמח משה"
        },
        {
            "_id": 9750,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 435,
            "שם_רחוב": "ישעיהו"
        },
        {
            "_id": 9751,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 510,
            "שם_רחוב": "ישראל מסלנט"
        },
        {
            "_id": 9752,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר האחד עשר"
        },
        {
            "_id": 9753,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר האחים מאיר"
        },
        {
            "_id": 9754,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר התעשיה"
        },
        {
            "_id": 9755,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 106,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 9756,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 702,
            "שם_רחוב": "לוי יצחק"
        },
        {
            "_id": 9757,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 708,
            "שם_רחוב": "לוין מרישא"
        },
        {
            "_id": 9758,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 109,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 9759,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 333,
            "שם_רחוב": "ליפשיץ"
        },
        {
            "_id": 9760,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 212,
            "שם_רחוב": "לנדאו חיים"
        },
        {
            "_id": 9761,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 301,
            "שם_רחוב": "מבצע קדש"
        },
        {
            "_id": 9762,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 906,
            "שם_רחוב": "מהרשל"
        },
        {
            "_id": 9763,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 930,
            "שם_רחוב": "מוהליבר"
        },
        {
            "_id": 9764,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 824,
            "שם_רחוב": "מחזיקי הדת"
        },
        {
            "_id": 9765,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מחנה דב"
        },
        {
            "_id": 9766,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 613,
            "שם_רחוב": "מיכה"
        },
        {
            "_id": 9767,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 538,
            "שם_רחוב": "מינץ"
        },
        {
            "_id": 9768,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 411,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 9769,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 303,
            "שם_רחוב": "מכלוף דוד"
        },
        {
            "_id": 9770,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 841,
            "שם_רחוב": "מלאכי"
        },
        {
            "_id": 9771,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 227,
            "שם_רחוב": "מלבן"
        },
        {
            "_id": 9772,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 409,
            "שם_רחוב": "מנחם"
        },
        {
            "_id": 9773,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 938,
            "שם_רחוב": "מעיינה של תורה"
        },
        {
            "_id": 9774,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 506,
            "שם_רחוב": "מעלות הדף היומי"
        },
        {
            "_id": 9775,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 965,
            "שם_רחוב": "מעלות זלמן מילשטיין"
        },
        {
            "_id": 9776,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 543,
            "שם_רחוב": "מעלות יהודית וויל"
        },
        {
            "_id": 9777,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 982,
            "שם_רחוב": "מעלות מאיר"
        },
        {
            "_id": 9778,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 989,
            "שם_רחוב": "מעלות מרגלית"
        },
        {
            "_id": 9779,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 629,
            "שם_רחוב": "מפרץ שלמה"
        },
        {
            "_id": 9780,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 9781,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 944,
            "שם_רחוב": "מרים הנביאה"
        },
        {
            "_id": 9782,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "מרכז בעלי מלאכה"
        },
        {
            "_id": 9783,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 628,
            "שם_רחוב": "משה אריה"
        },
        {
            "_id": 9784,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 441,
            "שם_רחוב": "משפחת מקובר"
        },
        {
            "_id": 9785,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6534,
            "שם_רחוב": "מתחם הרכבת"
        },
        {
            "_id": 9786,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 827,
            "שם_רחוב": "מתתיהו"
        },
        {
            "_id": 9787,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 239,
            "שם_רחוב": "נויפלד"
        },
        {
            "_id": 9788,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 733,
            "שם_רחוב": "נועם אלימלך"
        },
        {
            "_id": 9789,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 223,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 9790,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 233,
            "שם_רחוב": "נורוק"
        },
        {
            "_id": 9791,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 840,
            "שם_רחוב": "נחום"
        },
        {
            "_id": 9792,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 803,
            "שם_רחוב": "נחל שורק"
        },
        {
            "_id": 9793,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 833,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 9794,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 869,
            "שם_רחוב": "נחמן מברסלב"
        },
        {
            "_id": 9795,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 802,
            "שם_רחוב": "נחשוני יהודה"
        },
        {
            "_id": 9796,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 230,
            "שם_רחוב": "נידוני בגדד"
        },
        {
            "_id": 9797,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 108,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 9798,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 942,
            "שם_רחוב": "ניסנבוים"
        },
        {
            "_id": 9799,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 402,
            "שם_רחוב": "נתן הנביא"
        },
        {
            "_id": 9800,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 331,
            "שם_רחוב": "סומך"
        },
        {
            "_id": 9801,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 518,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 9802,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 894,
            "שם_רחוב": "סירוקה שמעון"
        },
        {
            "_id": 9803,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 909,
            "שם_רחוב": "סמ אפרים"
        },
        {
            "_id": 9804,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 523,
            "שם_רחוב": "סמ במברגר"
        },
        {
            "_id": 9805,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 929,
            "שם_רחוב": "סמ גוטליב"
        },
        {
            "_id": 9806,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 436,
            "שם_רחוב": "סמ הבנים"
        },
        {
            "_id": 9807,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 725,
            "שם_רחוב": "סמ החייל"
        },
        {
            "_id": 9808,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 919,
            "שם_רחוב": "סמ יבנה"
        },
        {
            "_id": 9809,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 960,
            "שם_רחוב": "סמ מנשה"
        },
        {
            "_id": 9810,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 862,
            "שם_רחוב": "סמ משה יוסף"
        },
        {
            "_id": 9811,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 603,
            "שם_רחוב": "סמ סירקין"
        },
        {
            "_id": 9812,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 421,
            "שם_רחוב": "סמ רחל"
        },
        {
            "_id": 9813,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 604,
            "שם_רחוב": "סמטת אזר"
        },
        {
            "_id": 9814,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 916,
            "שם_רחוב": "סמטת הארי"
        },
        {
            "_id": 9815,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 954,
            "שם_רחוב": "סנש"
        },
        {
            "_id": 9816,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 936,
            "שם_רחוב": "סעדיה גאון"
        },
        {
            "_id": 9817,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 330,
            "שם_רחוב": "סרור דנה"
        },
        {
            "_id": 9818,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 971,
            "שם_רחוב": "עבודת ישראל"
        },
        {
            "_id": 9819,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 931,
            "שם_רחוב": "עובדיה"
        },
        {
            "_id": 9820,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 825,
            "שם_רחוב": "עזרא"
        },
        {
            "_id": 9821,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 404,
            "שם_רחוב": "עלי הכהן"
        },
        {
            "_id": 9822,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 614,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 9823,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 407,
            "שם_רחוב": "עמי"
        },
        {
            "_id": 9824,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 932,
            "שם_רחוב": "עמיאל"
        },
        {
            "_id": 9825,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 202,
            "שם_רחוב": "עמק יזרעאל"
        },
        {
            "_id": 9826,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 851,
            "שם_רחוב": "עמרם גאון"
        },
        {
            "_id": 9827,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 251,
            "שם_רחוב": "פדרמן דוזיה"
        },
        {
            "_id": 9828,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 413,
            "שם_רחוב": "פוקס אהרון דב"
        },
        {
            "_id": 9829,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 112,
            "שם_רחוב": "פורצי כלא עכו"
        },
        {
            "_id": 9830,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 928,
            "שם_רחוב": "פנקס"
        },
        {
            "_id": 9831,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "פרדס כץ"
        },
        {
            "_id": 9832,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 504,
            "שם_רחוב": "פרל חיים"
        },
        {
            "_id": 9833,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 244,
            "שם_רחוב": "צבי"
        },
        {
            "_id": 9834,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 618,
            "שם_רחוב": "צייטלין"
        },
        {
            "_id": 9835,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 701,
            "שם_רחוב": "צירלסון"
        },
        {
            "_id": 9836,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 820,
            "שם_רחוב": "צמח צדיק"
        },
        {
            "_id": 9837,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 615,
            "שם_רחוב": "צפניה"
        },
        {
            "_id": 9838,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 410,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 9839,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 813,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 9840,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 736,
            "שם_רחוב": "קדושי גורליץ"
        },
        {
            "_id": 9841,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 737,
            "שם_רחוב": "קדושי לוב"
        },
        {
            "_id": 9842,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 892,
            "שם_רחוב": "קדושת יום טוב"
        },
        {
            "_id": 9843,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 806,
            "שם_רחוב": "קהילות יעקב"
        },
        {
            "_id": 9844,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 241,
            "שם_רחוב": "קובלסקי"
        },
        {
            "_id": 9845,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 811,
            "שם_רחוב": "קושניר"
        },
        {
            "_id": 9846,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 705,
            "שם_רחוב": "קלישר"
        },
        {
            "_id": 9847,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 962,
            "שם_רחוב": "קפלנסקי"
        },
        {
            "_id": 9848,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "קרית הרצוג"
        },
        {
            "_id": 9849,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "קרית יואל"
        },
        {
            "_id": 9850,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "קרית נדבורנא"
        },
        {
            "_id": 9851,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 907,
            "שם_רחוב": "ראבד"
        },
        {
            "_id": 9852,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 885,
            "שם_רחוב": "רב אמי"
        },
        {
            "_id": 9853,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 848,
            "שם_רחוב": "רב אסי"
        },
        {
            "_id": 9854,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 821,
            "שם_רחוב": "רב אשי"
        },
        {
            "_id": 9855,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 853,
            "שם_רחוב": "רב האי גאון"
        },
        {
            "_id": 9856,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 958,
            "שם_רחוב": "רבי אבא"
        },
        {
            "_id": 9857,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 416,
            "שם_רחוב": "רבי אליעזר"
        },
        {
            "_id": 9858,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 720,
            "שם_רחוב": "רבי חייא"
        },
        {
            "_id": 9859,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 431,
            "שם_רחוב": "רבי יוסי"
        },
        {
            "_id": 9860,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 838,
            "שם_רחוב": "רבי ישמעאל"
        },
        {
            "_id": 9861,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 728,
            "שם_רחוב": "רבי מאיר"
        },
        {
            "_id": 9862,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 502,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 9863,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 826,
            "שם_רחוב": "רבינא"
        },
        {
            "_id": 9864,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 726,
            "שם_רחוב": "רבן גמליאל"
        },
        {
            "_id": 9865,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 511,
            "שם_רחוב": "רבנו אשר"
        },
        {
            "_id": 9866,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 608,
            "שם_רחוב": "רבנו בחיי"
        },
        {
            "_id": 9867,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 903,
            "שם_רחוב": "רבנו תם"
        },
        {
            "_id": 9868,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 609,
            "שם_רחוב": "רדק"
        },
        {
            "_id": 9869,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 882,
            "שם_רחוב": "רוזנהיים"
        },
        {
            "_id": 9870,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 308,
            "שם_רחוב": "רזיאל"
        },
        {
            "_id": 9871,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 943,
            "שם_רחוב": "רח 282"
        },
        {
            "_id": 9872,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 740,
            "שם_רחוב": "רח 333"
        },
        {
            "_id": 9873,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 508,
            "שם_רחוב": "רח 293"
        },
        {
            "_id": 9874,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 832,
            "שם_רחוב": "רח 294"
        },
        {
            "_id": 9875,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 738,
            "שם_רחוב": "רח 335"
        },
        {
            "_id": 9876,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 868,
            "שם_רחוב": "רח 435"
        },
        {
            "_id": 9877,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 849,
            "שם_רחוב": "רח 295"
        },
        {
            "_id": 9878,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 735,
            "שם_רחוב": "רח 338"
        },
        {
            "_id": 9879,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 315,
            "שם_רחוב": "רח 288"
        },
        {
            "_id": 9880,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 899,
            "שם_רחוב": "רח 339"
        },
        {
            "_id": 9881,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 541,
            "שם_רחוב": "רחביה"
        },
        {
            "_id": 9882,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 247,
            "שם_רחוב": "רחבת קלמר"
        },
        {
            "_id": 9883,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 627,
            "שם_רחוב": "רייך אריה"
        },
        {
            "_id": 9884,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 712,
            "שם_רחוב": "ריינס"
        },
        {
            "_id": 9885,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 438,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 9886,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 963,
            "שם_רחוב": "רלבג"
        },
        {
            "_id": 9887,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 528,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 9888,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 823,
            "שם_רחוב": "רמבן"
        },
        {
            "_id": 9889,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 426,
            "שם_רחוב": "רמחל"
        },
        {
            "_id": 9890,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "רמת אהרון"
        },
        {
            "_id": 9891,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "רמת אלחנן"
        },
        {
            "_id": 9892,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "רמת דוד"
        },
        {
            "_id": 9893,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 902,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 9894,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 839,
            "שם_רחוב": "רשבם"
        },
        {
            "_id": 9895,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "ש אגי"
        },
        {
            "_id": 9896,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש גורדון"
        },
        {
            "_id": 9897,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "ש גני גד"
        },
        {
            "_id": 9898,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש הפומז"
        },
        {
            "_id": 9899,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "ש חזון איש"
        },
        {
            "_id": 9900,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "ש חסכון"
        },
        {
            "_id": 9901,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "ש טישלר"
        },
        {
            "_id": 9902,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש לומיר"
        },
        {
            "_id": 9903,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "ש משכנות"
        },
        {
            "_id": 9904,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "ש נוה אחיעזר"
        },
        {
            "_id": 9905,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "ש סגל"
        },
        {
            "_id": 9906,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6535,
            "שם_רחוב": "ש עובדים"
        },
        {
            "_id": 9907,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "ש עממי"
        },
        {
            "_id": 9908,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "ש פוניבז'"
        },
        {
            "_id": 9909,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "ש צהל"
        },
        {
            "_id": 9910,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש ק הישיבה"
        },
        {
            "_id": 9911,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש רוגוזין"
        },
        {
            "_id": 9912,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש תל גבורים"
        },
        {
            "_id": 9913,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 854,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 9914,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 532,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 9915,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 527,
            "שם_רחוב": "שבטי ישראל"
        },
        {
            "_id": 9916,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 941,
            "שם_רחוב": "שד אביי ורבא"
        },
        {
            "_id": 9917,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 211,
            "שם_רחוב": "שד אינשטיין"
        },
        {
            "_id": 9918,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 880,
            "שם_רחוב": "שד האדמור מטאלנא"
        },
        {
            "_id": 9919,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 948,
            "שם_רחוב": "שד הנציב"
        },
        {
            "_id": 9920,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 242,
            "שם_רחוב": "שד הרב לוין אריה"
        },
        {
            "_id": 9921,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 923,
            "שם_רחוב": "שד כפר עציון"
        },
        {
            "_id": 9922,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 427,
            "שם_רחוב": "שד רבי טרפון"
        },
        {
            "_id": 9923,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 951,
            "שם_רחוב": "שד רמז"
        },
        {
            "_id": 9924,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 429,
            "שם_רחוב": "שדל"
        },
        {
            "_id": 9925,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 950,
            "שם_רחוב": "שדה יצחק"
        },
        {
            "_id": 9926,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 709,
            "שם_רחוב": "שחל"
        },
        {
            "_id": 9927,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6537,
            "שם_רחוב": "שכ אבו לבן"
        },
        {
            "_id": 9928,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "שכ ה"
        },
        {
            "_id": 9929,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6539,
            "שם_רחוב": "שכ הפועל המזרחי א'"
        },
        {
            "_id": 9930,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "שכ הפועל המזרחי ב'"
        },
        {
            "_id": 9931,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6538,
            "שם_רחוב": "שכ הפועל המזרחי ג'"
        },
        {
            "_id": 9932,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "שכ הר שלום"
        },
        {
            "_id": 9933,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "שכ ויז'ניץ"
        },
        {
            "_id": 9934,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "שכ יסודות"
        },
        {
            "_id": 9935,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "שכ נווה און"
        },
        {
            "_id": 9936,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "שכ פסגת רחל"
        },
        {
            "_id": 9937,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "שכון ו"
        },
        {
            "_id": 9938,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 914,
            "שם_רחוב": "שלה"
        },
        {
            "_id": 9939,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 236,
            "שם_רחוב": "שלוש השעות"
        },
        {
            "_id": 9940,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 850,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 9941,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 434,
            "שם_רחוב": "שמואל הנביא"
        },
        {
            "_id": 9942,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 952,
            "שם_רחוב": "שמחוני"
        },
        {
            "_id": 9943,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 719,
            "שם_רחוב": "שמעון הצדיק"
        },
        {
            "_id": 9944,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 512,
            "שם_רחוב": "שמעיה"
        },
        {
            "_id": 9945,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 856,
            "שם_רחוב": "שמשון הגבור"
        },
        {
            "_id": 9946,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 9947,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 905,
            "שם_רחוב": "שפת אמת"
        },
        {
            "_id": 9948,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 114,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 9949,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 306,
            "שם_רחוב": "תורה ומנוחה"
        },
        {
            "_id": 9950,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 819,
            "שם_רחוב": "תורת חיים"
        },
        {
            "_id": 9951,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "תל גיבורים"
        },
        {
            "_id": 9952,
            "סמל_ישוב": 6100,
            "שם_ישוב": "בני ברק ",
            "סמל_רחוב": 113,
            "שם_רחוב": "תרפד"
        },
        {
            "_id": 9953,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אהבת ישראל"
        },
        {
            "_id": 9954,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בני דקלים"
        },
        {
            "_id": 9955,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 134,
            "שם_רחוב": "גאולה וישועה"
        },
        {
            "_id": 9956,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 9957,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך גוש קטיף"
        },
        {
            "_id": 9958,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך הדרום"
        },
        {
            "_id": 9959,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האגם"
        },
        {
            "_id": 9960,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 130,
            "שם_רחוב": "האמונה"
        },
        {
            "_id": 9961,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "האשכול"
        },
        {
            "_id": 9962,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 9963,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הזמורה"
        },
        {
            "_id": 9964,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 144,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 9965,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 9966,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "העינב"
        },
        {
            "_id": 9967,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 143,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 9968,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 142,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 9969,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 141,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 9970,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 125,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 9971,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 140,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 9972,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "חיי עולם"
        },
        {
            "_id": 9973,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 9974,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "כיסופים"
        },
        {
            "_id": 9975,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "נחלת אבות"
        },
        {
            "_id": 9976,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 131,
            "שם_רחוב": "נצח ישראל"
        },
        {
            "_id": 9977,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סמטת הכותל"
        },
        {
            "_id": 9978,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סמטת ציון"
        },
        {
            "_id": 9979,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "קריה נאמנה"
        },
        {
            "_id": 9980,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שדרות נווה דקלים"
        },
        {
            "_id": 9981,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שירת הים"
        },
        {
            "_id": 9982,
            "סמל_ישוב": 1368,
            "שם_ישוב": "בני דקלים ",
            "סמל_רחוב": 133,
            "שם_רחוב": "תורת חסד"
        },
        {
            "_id": 9983,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 118,
            "שם_רחוב": "איתנים"
        },
        {
            "_id": 9984,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בני דרום"
        },
        {
            "_id": 9985,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 104,
            "שם_רחוב": "בעלי מלאכה"
        },
        {
            "_id": 9986,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 9987,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 125,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 9988,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 121,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 9989,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 122,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 9990,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 136,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 9991,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 9992,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 9993,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 134,
            "שם_רחוב": "הגדיד"
        },
        {
            "_id": 9994,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 9995,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הדקליה"
        },
        {
            "_id": 9996,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 9997,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 109,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 9998,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 135,
            "שם_רחוב": "החריש"
        },
        {
            "_id": 9999,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 112,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 10000,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 10001,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 131,
            "שם_רחוב": "המסיק"
        },
        {
            "_id": 10002,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 10003,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הקטיף"
        },
        {
            "_id": 10004,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 10005,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 10006,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 137,
            "שם_רחוב": "השדה"
        },
        {
            "_id": 10007,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 103,
            "שם_רחוב": "השחר"
        },
        {
            "_id": 10008,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 124,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 10009,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 128,
            "שם_רחוב": "השיקמה"
        },
        {
            "_id": 10010,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 127,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 10011,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 126,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 10012,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 115,
            "שם_רחוב": "התקוה"
        },
        {
            "_id": 10013,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 117,
            "שם_רחוב": "יובל הכה"
        },
        {
            "_id": 10014,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 113,
            "שם_רחוב": "כב באדר"
        },
        {
            "_id": 10015,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מורשה"
        },
        {
            "_id": 10016,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מעלה החאן"
        },
        {
            "_id": 10017,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 107,
            "שם_רחוב": "משעול התאנים"
        },
        {
            "_id": 10018,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 119,
            "שם_רחוב": "נתיבות"
        },
        {
            "_id": 10019,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שדרת האשלים"
        },
        {
            "_id": 10020,
            "סמל_ישוב": 592,
            "שם_ישוב": "בני דרום ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שיר המעלות"
        },
        {
            "_id": 10021,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 10022,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 10023,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלמוגן"
        },
        {
            "_id": 10024,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 106,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 10025,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בני דרור"
        },
        {
            "_id": 10026,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 107,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 10027,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 201,
            "שם_רחוב": "דרך הדורות"
        },
        {
            "_id": 10028,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דרך הדרור"
        },
        {
            "_id": 10029,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 124,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 10030,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 119,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 10031,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 118,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 10032,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 10033,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 10034,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 10035,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 10036,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 121,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 10037,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 117,
            "שם_רחוב": "זית"
        },
        {
            "_id": 10038,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 116,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 10039,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 108,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 10040,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "מתחם קניון דרורים"
        },
        {
            "_id": 10041,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 7002,
            "שם_רחוב": "קרית חינוך דרור"
        },
        {
            "_id": 10042,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 10043,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 10044,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 114,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 10045,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 101,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 10046,
            "סמל_ישוב": 386,
            "שם_ישוב": "בני דרור ",
            "סמל_רחוב": 104,
            "שם_רחוב": "תמנע"
        },
        {
            "_id": 10047,
            "סמל_ישוב": 4015,
            "שם_ישוב": "בני יהודה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בני יהודה"
        },
        {
            "_id": 10048,
            "סמל_ישוב": 1363,
            "שם_ישוב": "בני נצרים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בני נצרים"
        },
        {
            "_id": 10049,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בני עטרות"
        },
        {
            "_id": 10050,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך האקליפטוס"
        },
        {
            "_id": 10051,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דרך הבארות"
        },
        {
            "_id": 10052,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך הכרמים"
        },
        {
            "_id": 10053,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דרך הפרחים"
        },
        {
            "_id": 10054,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך הראשונים"
        },
        {
            "_id": 10055,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 10056,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 10057,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 106,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 10058,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 10059,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 10060,
            "סמל_ישוב": 448,
            "שם_ישוב": "בני עטרות ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 10061,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בגין מנחם"
        },
        {
            "_id": 10062,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בני עיש"
        },
        {
            "_id": 10063,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 106,
            "שם_רחוב": "גבורי ישראל"
        },
        {
            "_id": 10064,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גני הכפר"
        },
        {
            "_id": 10065,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "גני טל"
        },
        {
            "_id": 10066,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 127,
            "שם_רחוב": "דרך הנצחון"
        },
        {
            "_id": 10067,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 123,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 10068,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 117,
            "שם_רחוב": "האיריסים"
        },
        {
            "_id": 10069,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 125,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 10070,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 124,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 10071,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 139,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 10072,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 136,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 10073,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 10074,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 10075,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 140,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 10076,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 134,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 10077,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 114,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 10078,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הכהנים"
        },
        {
            "_id": 10079,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הכלניות"
        },
        {
            "_id": 10080,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 107,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 10081,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הצלף"
        },
        {
            "_id": 10082,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הרב אהרון דנין"
        },
        {
            "_id": 10083,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הרב יחיא אלקשמה"
        },
        {
            "_id": 10084,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 10085,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 10086,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 133,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 10087,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 128,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 10088,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 135,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 10089,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 137,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 10090,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 120,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 10091,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 130,
            "שם_רחוב": "זנדני משה"
        },
        {
            "_id": 10092,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר יחיא ענאקי"
        },
        {
            "_id": 10093,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 104,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 10094,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "נאות דליה"
        },
        {
            "_id": 10095,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "נווה אורנים"
        },
        {
            "_id": 10096,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 141,
            "שם_רחוב": "סמ בגין"
        },
        {
            "_id": 10097,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 145,
            "שם_רחוב": "סמ דגן"
        },
        {
            "_id": 10098,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 131,
            "שם_רחוב": "סמ זנדני"
        },
        {
            "_id": 10099,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 119,
            "שם_רחוב": "סמ סיגלית"
        },
        {
            "_id": 10100,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 142,
            "שם_רחוב": "סמ קוסמת"
        },
        {
            "_id": 10101,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 144,
            "שם_רחוב": "סמ שיבולת"
        },
        {
            "_id": 10102,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 143,
            "שם_רחוב": "סמ שיפון"
        },
        {
            "_id": 10103,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 146,
            "שם_רחוב": "סמ תבואה"
        },
        {
            "_id": 10104,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 103,
            "שם_רחוב": "סמ תמר"
        },
        {
            "_id": 10105,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 147,
            "שם_רחוב": "סמטת תירוש"
        },
        {
            "_id": 10106,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 132,
            "שם_רחוב": "ערבי נחל"
        },
        {
            "_id": 10107,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "פנינת טל"
        },
        {
            "_id": 10108,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 105,
            "שם_רחוב": "צאלה"
        },
        {
            "_id": 10109,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רבין יצחק"
        },
        {
            "_id": 10110,
            "סמל_ישוב": 1066,
            "שם_ישוב": "בני עיש ",
            "סמל_רחוב": 138,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 10111,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 10112,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אנשי בראשית"
        },
        {
            "_id": 10113,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בני ציון"
        },
        {
            "_id": 10114,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 124,
            "שם_רחוב": "דרך השדות"
        },
        {
            "_id": 10115,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 125,
            "שם_רחוב": "דרך השלולית"
        },
        {
            "_id": 10116,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הלימון"
        },
        {
            "_id": 10117,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 120,
            "שם_רחוב": "המחרשה"
        },
        {
            "_id": 10118,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "המנים"
        },
        {
            "_id": 10119,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 10120,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "התלתן"
        },
        {
            "_id": 10121,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 121,
            "שם_רחוב": "חורשת האורנים"
        },
        {
            "_id": 10122,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 122,
            "שם_רחוב": "חורשת האקליפטוס"
        },
        {
            "_id": 10123,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "חמציץ"
        },
        {
            "_id": 10124,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 10125,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 119,
            "שם_רחוב": "חרמש"
        },
        {
            "_id": 10126,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 10127,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "לוטוס"
        },
        {
            "_id": 10128,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 128,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 10129,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מגל"
        },
        {
            "_id": 10130,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 10131,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "נענע"
        },
        {
            "_id": 10132,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 10133,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 10134,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "פרג"
        },
        {
            "_id": 10135,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 127,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 10136,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 10137,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 129,
            "שם_רחוב": "רימונים"
        },
        {
            "_id": 10138,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 123,
            "שם_רחוב": "שביל קליפות התפוזים"
        },
        {
            "_id": 10139,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 10140,
            "סמל_ישוב": 418,
            "שם_ישוב": "בני ציון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "תורמוס"
        },
        {
            "_id": 10141,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 10142,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בני ראם"
        },
        {
            "_id": 10143,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 10144,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 10145,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 10146,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 10147,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 112,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 10148,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 10149,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 10150,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 109,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 10151,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הטופח"
        },
        {
            "_id": 10152,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 110,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 10153,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 10154,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 10155,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 10156,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הסיגלית"
        },
        {
            "_id": 10157,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 119,
            "שם_רחוב": "העירית"
        },
        {
            "_id": 10158,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הפרג"
        },
        {
            "_id": 10159,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 10160,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הקורנית"
        },
        {
            "_id": 10161,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 10162,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 123,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 10163,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 116,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 10164,
            "סמל_ישוב": 588,
            "שם_ישוב": "בני ראם ",
            "סמל_רחוב": 115,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 10165,
            "סמל_ישוב": 685,
            "שם_ישוב": "בניה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בניה"
        },
        {
            "_id": 10166,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אדמונית החורש"
        },
        {
            "_id": 10167,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "אזור תעשיה רכבת"
        },
        {
            "_id": 10168,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "בנימינה"
        },
        {
            "_id": 10169,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בנימינה-גבעת עדה"
        },
        {
            "_id": 10170,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "בריכת המים"
        },
        {
            "_id": 10171,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "גבעת עדה"
        },
        {
            "_id": 10172,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "גבעת עדה הרחבה"
        },
        {
            "_id": 10173,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "גני בנימינה"
        },
        {
            "_id": 10174,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3165,
            "שם_רחוב": "דרך בנימינה גע"
        },
        {
            "_id": 10175,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3128,
            "שם_רחוב": "דרך הברושים גע"
        },
        {
            "_id": 10176,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "דרך המסילה"
        },
        {
            "_id": 10177,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "דרך העצמאות"
        },
        {
            "_id": 10178,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "דרך נילי"
        },
        {
            "_id": 10179,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "דרך פיקא"
        },
        {
            "_id": 10180,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3103,
            "שם_רחוב": "דרך רגבים גבעת עדה"
        },
        {
            "_id": 10181,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "דרך תל צור"
        },
        {
            "_id": 10182,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3118,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 10183,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3117,
            "שם_רחוב": "האגס גבעת עדה"
        },
        {
            "_id": 10184,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "האגסים"
        },
        {
            "_id": 10185,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "האופק"
        },
        {
            "_id": 10186,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "האור"
        },
        {
            "_id": 10187,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 10188,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3132,
            "שם_רחוב": "האורנים גבעת עדה"
        },
        {
            "_id": 10189,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "האיכר"
        },
        {
            "_id": 10190,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 10191,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "האירית"
        },
        {
            "_id": 10192,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3110,
            "שם_רחוב": "האלה גבעת עדה"
        },
        {
            "_id": 10193,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3114,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 10194,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "האמן"
        },
        {
            "_id": 10195,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 10196,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "האפיק"
        },
        {
            "_id": 10197,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 10198,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3120,
            "שם_רחוב": "הארזים גבעת עדה"
        },
        {
            "_id": 10199,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3125,
            "שם_רחוב": "האשכול גבעת עדה"
        },
        {
            "_id": 10200,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "האשכולית"
        },
        {
            "_id": 10201,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 10202,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 10203,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 10204,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "הבושם"
        },
        {
            "_id": 10205,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הבזלת"
        },
        {
            "_id": 10206,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 10207,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 10208,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הגבורים"
        },
        {
            "_id": 10209,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "הגביע"
        },
        {
            "_id": 10210,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3133,
            "שם_רחוב": "הגבעה גבעת עדה"
        },
        {
            "_id": 10211,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "הגדיש"
        },
        {
            "_id": 10212,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "הגדנע"
        },
        {
            "_id": 10213,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3129,
            "שם_רחוב": "הגומא גבעת עדה"
        },
        {
            "_id": 10214,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 10215,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "הגזית"
        },
        {
            "_id": 10216,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "הגיא"
        },
        {
            "_id": 10217,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 10218,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3116,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 10219,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "הגפנים"
        },
        {
            "_id": 10220,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 10221,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הדגן"
        },
        {
            "_id": 10222,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 10223,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3158,
            "שם_רחוב": "הדפנה גבעת עדה"
        },
        {
            "_id": 10224,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3115,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 10225,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הדקלים"
        },
        {
            "_id": 10226,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3131,
            "שם_רחוב": "הדר גבעת עדה"
        },
        {
            "_id": 10227,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 10228,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 10229,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 10230,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "ההרדופים"
        },
        {
            "_id": 10231,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "הושע"
        },
        {
            "_id": 10232,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3112,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 10233,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 10234,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3109,
            "שם_רחוב": "החוטמית"
        },
        {
            "_id": 10235,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 10236,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3102,
            "שם_רחוב": "החורשים"
        },
        {
            "_id": 10237,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 10238,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3123,
            "שם_רחוב": "החיטים"
        },
        {
            "_id": 10239,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3126,
            "שם_רחוב": "החלמוניות"
        },
        {
            "_id": 10240,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 10241,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3127,
            "שם_רחוב": "החרובים"
        },
        {
            "_id": 10242,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 10243,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 10244,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הטחנה"
        },
        {
            "_id": 10245,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "היובל"
        },
        {
            "_id": 10246,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "היוגב"
        },
        {
            "_id": 10247,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "היין"
        },
        {
            "_id": 10248,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 10249,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3106,
            "שם_רחוב": "היער גבעת עדה"
        },
        {
            "_id": 10250,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "היקב"
        },
        {
            "_id": 10251,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3160,
            "שם_רחוב": "היקינתון גבעת עדה"
        },
        {
            "_id": 10252,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "הככר"
        },
        {
            "_id": 10253,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 10254,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "הכרכום"
        },
        {
            "_id": 10255,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 10256,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 10257,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3168,
            "שם_רחוב": "הלבונה גבעת עדה"
        },
        {
            "_id": 10258,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "הלוטם"
        },
        {
            "_id": 10259,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3150,
            "שם_רחוב": "הליבנה גבעת עדה"
        },
        {
            "_id": 10260,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "הלימון"
        },
        {
            "_id": 10261,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "המגל"
        },
        {
            "_id": 10262,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "המורג"
        },
        {
            "_id": 10263,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "המורה"
        },
        {
            "_id": 10264,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "המיסדים"
        },
        {
            "_id": 10265,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 10266,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "המעיין"
        },
        {
            "_id": 10267,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "המרוה"
        },
        {
            "_id": 10268,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "המרחב"
        },
        {
            "_id": 10269,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 10270,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הנדיב"
        },
        {
            "_id": 10271,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3164,
            "שם_רחוב": "הנופר גבעת עדה"
        },
        {
            "_id": 10272,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 10273,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 10274,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3105,
            "שם_רחוב": "הניצנים"
        },
        {
            "_id": 10275,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 10276,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הנשיא"
        },
        {
            "_id": 10277,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הנשר"
        },
        {
            "_id": 10278,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 10279,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3136,
            "שם_רחוב": "הסביון גבעת עדה"
        },
        {
            "_id": 10280,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "הסחלב"
        },
        {
            "_id": 10281,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "הסיגלית"
        },
        {
            "_id": 10282,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "הסמדר"
        },
        {
            "_id": 10283,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "הספיר"
        },
        {
            "_id": 10284,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 10285,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3104,
            "שם_רחוב": "העומר גבעת עדה"
        },
        {
            "_id": 10286,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "העינב"
        },
        {
            "_id": 10287,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3161,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 10288,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "הערבות"
        },
        {
            "_id": 10289,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "הפומלה"
        },
        {
            "_id": 10290,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "הפועל"
        },
        {
            "_id": 10291,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "הפלג"
        },
        {
            "_id": 10292,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 10293,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 10294,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הציפורן"
        },
        {
            "_id": 10295,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "הקורל"
        },
        {
            "_id": 10296,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "הקטיף"
        },
        {
            "_id": 10297,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3121,
            "שם_רחוב": "הקמה גבעת עדה"
        },
        {
            "_id": 10298,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "הקציר"
        },
        {
            "_id": 10299,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3111,
            "שם_רחוב": "הראשונים גבעת עדה"
        },
        {
            "_id": 10300,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3130,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 10301,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הרופא"
        },
        {
            "_id": 10302,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3113,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 10303,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרימונים"
        },
        {
            "_id": 10304,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 10305,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "השדה"
        },
        {
            "_id": 10306,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "השושנה"
        },
        {
            "_id": 10307,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 10308,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "השחר"
        },
        {
            "_id": 10309,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "השיבולים"
        },
        {
            "_id": 10310,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3153,
            "שם_רחוב": "השיבולת"
        },
        {
            "_id": 10311,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 10312,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 10313,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "השיש"
        },
        {
            "_id": 10314,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "השניים"
        },
        {
            "_id": 10315,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3154,
            "שם_רחוב": "השסק גבעת עדה"
        },
        {
            "_id": 10316,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 10317,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3124,
            "שם_רחוב": "השעורים"
        },
        {
            "_id": 10318,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3119,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 10319,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "השריג"
        },
        {
            "_id": 10320,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 10321,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3148,
            "שם_רחוב": "התבואה"
        },
        {
            "_id": 10322,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3162,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 10323,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "התלתן"
        },
        {
            "_id": 10324,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "התמסח"
        },
        {
            "_id": 10325,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 10326,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 10327,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3107,
            "שם_רחוב": "חי גבעת עדה"
        },
        {
            "_id": 10328,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "חבקוק"
        },
        {
            "_id": 10329,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 10330,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "טיילת שוני"
        },
        {
            "_id": 10331,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "יואל"
        },
        {
            "_id": 10332,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "יונה"
        },
        {
            "_id": 10333,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "ימי בנימינה"
        },
        {
            "_id": 10334,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "יפה נוף גבעת עדה"
        },
        {
            "_id": 10335,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3169,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 10336,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "כרמי בנימינה"
        },
        {
            "_id": 10337,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "מיכה"
        },
        {
            "_id": 10338,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3108,
            "שם_רחוב": "מכון אור שמח גע"
        },
        {
            "_id": 10339,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "מע בנימינה"
        },
        {
            "_id": 10340,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3156,
            "שם_רחוב": "מעלה הצבר גבעת עדה"
        },
        {
            "_id": 10341,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "מצודת שוני"
        },
        {
            "_id": 10342,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3101,
            "שם_רחוב": "מרכז קליטה גבעת עדה"
        },
        {
            "_id": 10343,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3147,
            "שם_רחוב": "משעול האלומה גע"
        },
        {
            "_id": 10344,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3149,
            "שם_רחוב": "משעול החמנית גע"
        },
        {
            "_id": 10345,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "נחלת בנימינה"
        },
        {
            "_id": 10346,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נחלת ז'בוטינסקי"
        },
        {
            "_id": 10347,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 10348,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3141,
            "שם_רחוב": "סמ האיריס גבעת עדה"
        },
        {
            "_id": 10349,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3122,
            "שם_רחוב": "סמ הדגנים"
        },
        {
            "_id": 10350,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3142,
            "שם_רחוב": "סמ הדגנית גבעת עדה"
        },
        {
            "_id": 10351,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3134,
            "שם_רחוב": "סמ החבצלות"
        },
        {
            "_id": 10352,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3140,
            "שם_רחוב": "סמ החרצית גבעת עדה"
        },
        {
            "_id": 10353,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3144,
            "שם_רחוב": "סמ הכלניות"
        },
        {
            "_id": 10354,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3163,
            "שם_רחוב": "סמ הכרמים"
        },
        {
            "_id": 10355,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3143,
            "שם_רחוב": "סמ הנוריות"
        },
        {
            "_id": 10356,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3145,
            "שם_רחוב": "סמ הנרקיסים"
        },
        {
            "_id": 10357,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3159,
            "שם_רחוב": "סמ הסיתוונית גע"
        },
        {
            "_id": 10358,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3151,
            "שם_רחוב": "סמ העירית גבעת עדה"
        },
        {
            "_id": 10359,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3152,
            "שם_רחוב": "סמ הפעמונית גע"
        },
        {
            "_id": 10360,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3137,
            "שם_רחוב": "סמ הצבעוני גבעת עדה"
        },
        {
            "_id": 10361,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3135,
            "שם_רחוב": "סמ הרקפות"
        },
        {
            "_id": 10362,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3139,
            "שם_רחוב": "סמ חצב גבעת עדה"
        },
        {
            "_id": 10363,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "סמטת החרוב"
        },
        {
            "_id": 10364,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 10365,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "עץ האגוז"
        },
        {
            "_id": 10366,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "עץ האלון"
        },
        {
            "_id": 10367,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "עץ הזית"
        },
        {
            "_id": 10368,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "עץ השקד"
        },
        {
            "_id": 10369,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3155,
            "שם_רחוב": "עץ התאנה"
        },
        {
            "_id": 10370,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "פריחת ההדרים"
        },
        {
            "_id": 10371,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3146,
            "שם_רחוב": "פריחת הסמדר"
        },
        {
            "_id": 10372,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 10373,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 3167,
            "שם_רחוב": "רויטל גבעת עדה"
        },
        {
            "_id": 10374,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 10375,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "ש אילנות גבעת עדה"
        },
        {
            "_id": 10376,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש גבעת חן"
        },
        {
            "_id": 10377,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש חדש"
        },
        {
            "_id": 10378,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "ש חדש גבעת עדה"
        },
        {
            "_id": 10379,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "ש יוספטל גבעת עדה"
        },
        {
            "_id": 10380,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש נוה עובד"
        },
        {
            "_id": 10381,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש עובדים"
        },
        {
            "_id": 10382,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 10383,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "ש עמידר גע"
        },
        {
            "_id": 10384,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש עממי"
        },
        {
            "_id": 10385,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "שביל אל הגן"
        },
        {
            "_id": 10386,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שביל החלב"
        },
        {
            "_id": 10387,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "שביל החלמונית"
        },
        {
            "_id": 10388,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "שביל הכרמלית"
        },
        {
            "_id": 10389,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "שביל הנוער"
        },
        {
            "_id": 10390,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "שביל הסתונית"
        },
        {
            "_id": 10391,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "שביל התורמוס"
        },
        {
            "_id": 10392,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ גב הפועל"
        },
        {
            "_id": 10393,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "שכ הצמחונים גע"
        },
        {
            "_id": 10394,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ הרכבת"
        },
        {
            "_id": 10395,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ ורבורג"
        },
        {
            "_id": 10396,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ ז'בוטינסקי"
        },
        {
            "_id": 10397,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ זרעוניה"
        },
        {
            "_id": 10398,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ יעקב"
        },
        {
            "_id": 10399,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ ישורון"
        },
        {
            "_id": 10400,
            "סמל_ישוב": 9800,
            "שם_ישוב": "בנימינה-גבעת עדה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 10401,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אבו בכר אלסדיק"
        },
        {
            "_id": 10402,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אבו מדור"
        },
        {
            "_id": 10403,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אבן אלהייתם"
        },
        {
            "_id": 10404,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אבן אלקיים"
        },
        {
            "_id": 10405,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אבן זיידון"
        },
        {
            "_id": 10406,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 10407,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 10408,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אבן תיימיה"
        },
        {
            "_id": 10409,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אום אלקורא"
        },
        {
            "_id": 10410,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אחמד שוקי"
        },
        {
            "_id": 10411,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אל מלעב"
        },
        {
            "_id": 10412,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אל עין"
        },
        {
            "_id": 10413,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אל תקוא"
        },
        {
            "_id": 10414,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלאחוט"
        },
        {
            "_id": 10415,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "אלאנדלוס"
        },
        {
            "_id": 10416,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "אלאקסא"
        },
        {
            "_id": 10417,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלבוח'ארי"
        },
        {
            "_id": 10418,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אלבטימאת"
        },
        {
            "_id": 10419,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 10420,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלביירוני"
        },
        {
            "_id": 10421,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אלבסאתין"
        },
        {
            "_id": 10422,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אלבראק"
        },
        {
            "_id": 10423,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלבריד"
        },
        {
            "_id": 10424,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "אלברקאן"
        },
        {
            "_id": 10425,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלג'אמע"
        },
        {
            "_id": 10426,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלג'זירה"
        },
        {
            "_id": 10427,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אלגדראן"
        },
        {
            "_id": 10428,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אלגולאן"
        },
        {
            "_id": 10429,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אלגזאלי"
        },
        {
            "_id": 10430,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אלואד"
        },
        {
            "_id": 10431,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלופאא"
        },
        {
            "_id": 10432,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלזהראא"
        },
        {
            "_id": 10433,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 10434,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלח'ליל"
        },
        {
            "_id": 10435,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אלחג'אז"
        },
        {
            "_id": 10436,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלחף"
        },
        {
            "_id": 10437,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אלחרייה"
        },
        {
            "_id": 10438,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלטוילה"
        },
        {
            "_id": 10439,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 10440,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלירמוק"
        },
        {
            "_id": 10441,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלכוארזמי"
        },
        {
            "_id": 10442,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלכלה"
        },
        {
            "_id": 10443,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אלכפרין"
        },
        {
            "_id": 10444,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלמדינה"
        },
        {
            "_id": 10445,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "אלמותנבי"
        },
        {
            "_id": 10446,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלמחבה"
        },
        {
            "_id": 10447,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלמרוה"
        },
        {
            "_id": 10448,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלנג'אח"
        },
        {
            "_id": 10449,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אלניל"
        },
        {
            "_id": 10450,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "אלסדיק"
        },
        {
            "_id": 10451,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלסדרי"
        },
        {
            "_id": 10452,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 10453,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אלסלטאן"
        },
        {
            "_id": 10454,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אלסעאדה"
        },
        {
            "_id": 10455,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אלספאא"
        },
        {
            "_id": 10456,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלפאראבי"
        },
        {
            "_id": 10457,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אלפארוק"
        },
        {
            "_id": 10458,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלפאתח"
        },
        {
            "_id": 10459,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלפרוש"
        },
        {
            "_id": 10460,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלקאדסיה"
        },
        {
            "_id": 10461,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלקאהרה"
        },
        {
            "_id": 10462,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלקדס"
        },
        {
            "_id": 10463,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלקלעה"
        },
        {
            "_id": 10464,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלראזי"
        },
        {
            "_id": 10465,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אלרבידא"
        },
        {
            "_id": 10466,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "אלרוחה"
        },
        {
            "_id": 10467,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלרחמה"
        },
        {
            "_id": 10468,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלשאם"
        },
        {
            "_id": 10469,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אלשאפעי"
        },
        {
            "_id": 10470,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אלתות"
        },
        {
            "_id": 10471,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלתסאמוח"
        },
        {
            "_id": 10472,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אם יונס"
        },
        {
            "_id": 10473,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "בגדאד"
        },
        {
            "_id": 10474,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "בלאל בן רבאח"
        },
        {
            "_id": 10475,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בסמה"
        },
        {
            "_id": 10476,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ברטעה"
        },
        {
            "_id": 10477,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "דהרת חמד"
        },
        {
            "_id": 10478,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "דמשק"
        },
        {
            "_id": 10479,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "ח'ור אלברזה"
        },
        {
            "_id": 10480,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "חוראא"
        },
        {
            "_id": 10481,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 10482,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 10483,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "חלב"
        },
        {
            "_id": 10484,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "חסן בן ת'אבת"
        },
        {
            "_id": 10485,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "טארק בן זיאד"
        },
        {
            "_id": 10486,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "טיבה"
        },
        {
            "_id": 10487,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "יאפא"
        },
        {
            "_id": 10488,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "כלת חדאד"
        },
        {
            "_id": 10489,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "מאלק בן אנאס"
        },
        {
            "_id": 10490,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "מועאוויה"
        },
        {
            "_id": 10491,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "מכה"
        },
        {
            "_id": 10492,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "מרים בנת עמראן"
        },
        {
            "_id": 10493,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "סלאח אל דין"
        },
        {
            "_id": 10494,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "עומר אלמוכתאר"
        },
        {
            "_id": 10495,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "עומר בן אלח'טאב"
        },
        {
            "_id": 10496,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "עות'מאן בן עפאן"
        },
        {
            "_id": 10497,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "עין א-סהלה"
        },
        {
            "_id": 10498,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 10499,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "עליאא"
        },
        {
            "_id": 10500,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "קיסריה"
        },
        {
            "_id": 10501,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "ראס אלעין"
        },
        {
            "_id": 10502,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "רח 45"
        },
        {
            "_id": 10503,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "רח 76"
        },
        {
            "_id": 10504,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "רח 86"
        },
        {
            "_id": 10505,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "רח 97"
        },
        {
            "_id": 10506,
            "סמל_ישוב": 1326,
            "שם_ישוב": "בסמה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "תאנים"
        },
        {
            "_id": 10507,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אבו בכר אלצדיק"
        },
        {
            "_id": 10508,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 132,
            "שם_רחוב": "איבן סינא"
        },
        {
            "_id": 10509,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אל אמל"
        },
        {
            "_id": 10510,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אל ואדי"
        },
        {
            "_id": 10511,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלבוסתאן"
        },
        {
            "_id": 10512,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלבחר"
        },
        {
            "_id": 10513,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 10514,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלביאן"
        },
        {
            "_id": 10515,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלבסאתין"
        },
        {
            "_id": 10516,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלג'אר"
        },
        {
            "_id": 10517,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלג'בל"
        },
        {
            "_id": 10518,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלזהור"
        },
        {
            "_id": 10519,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 10520,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלכרום"
        },
        {
            "_id": 10521,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלכרמה"
        },
        {
            "_id": 10522,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלנהדה"
        },
        {
            "_id": 10523,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלנזאז"
        },
        {
            "_id": 10524,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 10525,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 10526,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלסנדיאן"
        },
        {
            "_id": 10527,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלפולאת"
        },
        {
            "_id": 10528,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלרביע"
        },
        {
            "_id": 10529,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלרומאן"
        },
        {
            "_id": 10530,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלרנד"
        },
        {
            "_id": 10531,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלשיח' נימר"
        },
        {
            "_id": 10532,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלתין"
        },
        {
            "_id": 10533,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "בית סומא"
        },
        {
            "_id": 10534,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בסמת טבעון"
        },
        {
            "_id": 10535,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 138,
            "שם_רחוב": "דרך יצחק רבין"
        },
        {
            "_id": 10536,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 10537,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 201,
            "שם_רחוב": "זובידאת"
        },
        {
            "_id": 10538,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 137,
            "שם_רחוב": "ח'אלד בן אלואליד"
        },
        {
            "_id": 10539,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 118,
            "שם_רחוב": "ח'ואלד"
        },
        {
            "_id": 10540,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 123,
            "שם_רחוב": "ח'רבה"
        },
        {
            "_id": 10541,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "חלף"
        },
        {
            "_id": 10542,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 126,
            "שם_רחוב": "מועד בן ג'בל"
        },
        {
            "_id": 10543,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 128,
            "שם_רחוב": "מחמוד אלשיך"
        },
        {
            "_id": 10544,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "משאיח סעדיה"
        },
        {
            "_id": 10545,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 125,
            "שם_רחוב": "עומר בן אלח'טאב"
        },
        {
            "_id": 10546,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 127,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 10547,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 134,
            "שם_רחוב": "ראס אלואדי"
        },
        {
            "_id": 10548,
            "סמל_ישוב": 944,
            "שם_ישוב": "בסמת טבעון ",
            "סמל_רחוב": 130,
            "שם_רחוב": "רחיים"
        },
        {
            "_id": 10549,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6742,
            "שם_רחוב": "אבו ח'רובה"
        },
        {
            "_id": 10550,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6781,
            "שם_רחוב": "אל לקאיא"
        },
        {
            "_id": 10551,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6750,
            "שם_רחוב": "אלח'לאל"
        },
        {
            "_id": 10552,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6743,
            "שם_רחוב": "אלח'נקה"
        },
        {
            "_id": 10553,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6746,
            "שם_רחוב": "אלכרם אלחמר"
        },
        {
            "_id": 10554,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6744,
            "שם_רחוב": "אלסוואעד"
        },
        {
            "_id": 10555,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6756,
            "שם_רחוב": "אלעריד"
        },
        {
            "_id": 10556,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6752,
            "שם_רחוב": "אלקלעה"
        },
        {
            "_id": 10557,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6755,
            "שם_רחוב": "בידר אלשוק"
        },
        {
            "_id": 10558,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בענה"
        },
        {
            "_id": 10559,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6754,
            "שם_רחוב": "הכפר הישן"
        },
        {
            "_id": 10560,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6751,
            "שם_רחוב": "המגרשים"
        },
        {
            "_id": 10561,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6753,
            "שם_רחוב": "כרם אלדאר"
        },
        {
            "_id": 10562,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "כרם נחלה"
        },
        {
            "_id": 10563,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6745,
            "שם_רחוב": "ע'דארה"
        },
        {
            "_id": 10564,
            "סמל_ישוב": 483,
            "שם_ישוב": "בענה ",
            "סמל_רחוב": 6741,
            "שם_רחוב": "קטעת אלג'אמע"
        },
        {
            "_id": 10565,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בצרה"
        },
        {
            "_id": 10566,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 10567,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הבאר"
        },
        {
            "_id": 10568,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגן"
        },
        {
            "_id": 10569,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הנחלים"
        },
        {
            "_id": 10570,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 10571,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 10572,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 10573,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 10574,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הצופית"
        },
        {
            "_id": 10575,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 10576,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "יונק הדבש"
        },
        {
            "_id": 10577,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 10578,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מגדל המים"
        },
        {
            "_id": 10579,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 10580,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 10581,
            "סמל_ישוב": 389,
            "שם_ישוב": "בצרה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 10582,
            "סמל_ישוב": 589,
            "שם_ישוב": "בצת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בצת"
        },
        {
            "_id": 10583,
            "סמל_ישוב": 864,
            "שם_ישוב": "בקוע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בקוע"
        },
        {
            "_id": 10584,
            "סמל_ישוב": 3612,
            "שם_ישוב": "בקעות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בקעות"
        },
        {
            "_id": 10585,
            "סמל_ישוב": 823,
            "שם_ישוב": "בר גיורא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בר גיורא"
        },
        {
            "_id": 10586,
            "סמל_ישוב": 823,
            "שם_ישוב": "בר גיורא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 10587,
            "סמל_ישוב": 823,
            "שם_ישוב": "בר גיורא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 10588,
            "סמל_ישוב": 823,
            "שם_ישוב": "בר גיורא ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 10589,
            "סמל_ישוב": 823,
            "שם_ישוב": "בר גיורא ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הקטלב"
        },
        {
            "_id": 10590,
            "סמל_ישוב": 823,
            "שם_ישוב": "בר גיורא ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 10591,
            "סמל_ישוב": 823,
            "שם_ישוב": "בר גיורא ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 10592,
            "סמל_ישוב": 1191,
            "שם_ישוב": "בר יוחאי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בר יוחאי"
        },
        {
            "_id": 10593,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ברוכין"
        },
        {
            "_id": 10594,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 110,
            "שם_רחוב": "האדרת"
        },
        {
            "_id": 10595,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ההדר"
        },
        {
            "_id": 10596,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 112,
            "שם_רחוב": "ההוד"
        },
        {
            "_id": 10597,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הזוהר"
        },
        {
            "_id": 10598,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החיל"
        },
        {
            "_id": 10599,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הנוי"
        },
        {
            "_id": 10600,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הנצח"
        },
        {
            "_id": 10601,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 102,
            "שם_רחוב": "העוז"
        },
        {
            "_id": 10602,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הצבי"
        },
        {
            "_id": 10603,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הרון"
        },
        {
            "_id": 10604,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 113,
            "שם_רחוב": "השבח"
        },
        {
            "_id": 10605,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 108,
            "שם_רחוב": "השגב"
        },
        {
            "_id": 10606,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 103,
            "שם_רחוב": "השיר"
        },
        {
            "_id": 10607,
            "סמל_ישוב": 3744,
            "שם_ישוב": "ברוכין ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התהילה"
        },
        {
            "_id": 10608,
            "סמל_ישוב": 428,
            "שם_ישוב": "ברור חיל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ברור חיל"
        },
        {
            "_id": 10609,
            "סמל_ישוב": 2060,
            "שם_ישוב": "ברוש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 10610,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אביר יעקב"
        },
        {
            "_id": 10611,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אבן ישראל"
        },
        {
            "_id": 10612,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "איתן"
        },
        {
            "_id": 10613,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ברכה"
        },
        {
            "_id": 10614,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "ברכת יוסף"
        },
        {
            "_id": 10615,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 10616,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 10617,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "הר גריזים"
        },
        {
            "_id": 10618,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הרב איתמר בן גל"
        },
        {
            "_id": 10619,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "חברון"
        },
        {
            "_id": 10620,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 10621,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מגדים"
        },
        {
            "_id": 10622,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מצפה שלם"
        },
        {
            "_id": 10623,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "עלי עין"
        },
        {
            "_id": 10624,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "צפנת"
        },
        {
            "_id": 10625,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "קדם"
        },
        {
            "_id": 10626,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "קרית הישיבה"
        },
        {
            "_id": 10627,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "קשתי"
        },
        {
            "_id": 10628,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "שערי ברכה"
        },
        {
            "_id": 10629,
            "סמל_ישוב": 3710,
            "שם_ישוב": "ברכה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "תבואות"
        },
        {
            "_id": 10630,
            "סמל_ישוב": 746,
            "שם_ישוב": "ברכיה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ברכיה"
        },
        {
            "_id": 10631,
            "סמל_ישוב": 667,
            "שם_ישוב": "ברעם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ברעם"
        },
        {
            "_id": 10632,
            "סמל_ישוב": 141,
            "שם_ישוב": "ברק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 10633,
            "סמל_ישוב": 617,
            "שם_ישוב": "ברקאי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ברקאי"
        },
        {
            "_id": 10634,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 10635,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 10636,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 10637,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אננס"
        },
        {
            "_id": 10638,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 10639,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 10640,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך ברקן"
        },
        {
            "_id": 10641,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 124,
            "שם_רחוב": "האגס"
        },
        {
            "_id": 10642,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 117,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 10643,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 123,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 10644,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 133,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 10645,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 10646,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 118,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 10647,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 134,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 10648,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 137,
            "שם_רחוב": "הדגנית"
        },
        {
            "_id": 10649,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 10650,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 10651,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 10652,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 125,
            "שם_רחוב": "החצבים"
        },
        {
            "_id": 10653,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 129,
            "שם_רחוב": "היקינטון"
        },
        {
            "_id": 10654,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 10655,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 10656,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 10657,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הסיגלית"
        },
        {
            "_id": 10658,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 142,
            "שם_רחוב": "הסיוון"
        },
        {
            "_id": 10659,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 126,
            "שם_רחוב": "העירית"
        },
        {
            "_id": 10660,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 10661,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 138,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 10662,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 10663,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 128,
            "שם_רחוב": "השושנים"
        },
        {
            "_id": 10664,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 114,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 10665,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 135,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 10666,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 132,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 10667,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "חלמיש"
        },
        {
            "_id": 10668,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 10669,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 139,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 10670,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 140,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 10671,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 141,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 10672,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "מעלה ישראל"
        },
        {
            "_id": 10673,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 10674,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 10675,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 10676,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 10677,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 7002,
            "שם_רחוב": "שכ קרוואנים"
        },
        {
            "_id": 10678,
            "סמל_ישוב": 3654,
            "שם_ישוב": "ברקן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שנהב"
        },
        {
            "_id": 10679,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 10680,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אור המזרח"
        },
        {
            "_id": 10681,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 10682,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 10683,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 10684,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 10685,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 115,
            "שם_רחוב": "החושן"
        },
        {
            "_id": 10686,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "היהלום"
        },
        {
            "_id": 10687,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 10688,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הלבונה"
        },
        {
            "_id": 10689,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הספיר"
        },
        {
            "_id": 10690,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הפיקוס"
        },
        {
            "_id": 10691,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 10692,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הרב שלום יצחק"
        },
        {
            "_id": 10693,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 10694,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 111,
            "שם_רחוב": "חנה"
        },
        {
            "_id": 10695,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 120,
            "שם_רחוב": "סמטת האורן"
        },
        {
            "_id": 10696,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 116,
            "שם_רחוב": "סמטת הברוש"
        },
        {
            "_id": 10697,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 117,
            "שם_רחוב": "סמטת ההדסים"
        },
        {
            "_id": 10698,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 118,
            "שם_רחוב": "סמטת הרימון"
        },
        {
            "_id": 10699,
            "סמל_ישוב": 2038,
            "שם_ישוב": "ברקת ",
            "סמל_רחוב": 119,
            "שם_רחוב": "סמטת התמר"
        },
        {
            "_id": 10700,
            "סמל_ישוב": 1323,
            "שם_ישוב": "בת הדר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בת הדר"
        },
        {
            "_id": 10701,
            "סמל_ישוב": 1361,
            "שם_ישוב": "בת חן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בת חן"
        },
        {
            "_id": 10702,
            "סמל_ישוב": 1361,
            "שם_ישוב": "בת חן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 10703,
            "סמל_ישוב": 1361,
            "שם_ישוב": "בת חן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 10704,
            "סמל_ישוב": 1361,
            "שם_ישוב": "בת חן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "זית"
        },
        {
            "_id": 10705,
            "סמל_ישוב": 1361,
            "שם_ישוב": "בת חן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 10706,
            "סמל_ישוב": 1361,
            "שם_ישוב": "בת חן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 10707,
            "סמל_ישוב": 1361,
            "שם_ישוב": "בת חן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 10708,
            "סמל_ישוב": 1361,
            "שם_ישוב": "בת חן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 10709,
            "סמל_ישוב": 1361,
            "שם_ישוב": "בת חן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 10710,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 10711,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 10712,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 10713,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 10714,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "בית השיטה"
        },
        {
            "_id": 10715,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "בית זית"
        },
        {
            "_id": 10716,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "בית ניר"
        },
        {
            "_id": 10717,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "בית קמה"
        },
        {
            "_id": 10718,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 135,
            "שם_רחוב": "בית קשת"
        },
        {
            "_id": 10719,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "בצת"
        },
        {
            "_id": 10720,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "בקעות"
        },
        {
            "_id": 10721,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 10722,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בת חפר"
        },
        {
            "_id": 10723,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "גולן"
        },
        {
            "_id": 10724,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 10725,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 10726,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "גמלא"
        },
        {
            "_id": 10727,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 133,
            "שם_רחוב": "דוחל"
        },
        {
            "_id": 10728,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 10729,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "דיה"
        },
        {
            "_id": 10730,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 10731,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 10732,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "חיטה"
        },
        {
            "_id": 10733,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "חיננית"
        },
        {
            "_id": 10734,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "חלבלוב"
        },
        {
            "_id": 10735,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "חלמונית"
        },
        {
            "_id": 10736,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "חמציץ"
        },
        {
            "_id": 10737,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 10738,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 126,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 10739,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 128,
            "שם_רחוב": "שד הגליל"
        },
        {
            "_id": 10740,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שד הנגב"
        },
        {
            "_id": 10741,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 131,
            "שם_רחוב": "שד הערבה"
        },
        {
            "_id": 10742,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 132,
            "שם_רחוב": "שד השומרון"
        },
        {
            "_id": 10743,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "שד השפלה"
        },
        {
            "_id": 10744,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 130,
            "שם_רחוב": "שד השרון"
        },
        {
            "_id": 10745,
            "סמל_ישוב": 1319,
            "שם_ישוב": "בת חפר ",
            "סמל_רחוב": 129,
            "שם_רחוב": "שד עמק חפר"
        },
        {
            "_id": 10746,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אהוד מנור"
        },
        {
            "_id": 10747,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 646,
            "שם_רחוב": "אוסישקין"
        },
        {
            "_id": 10748,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 637,
            "שם_רחוב": "אופיר"
        },
        {
            "_id": 10749,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 510,
            "שם_רחוב": "אורט ישראל"
        },
        {
            "_id": 10750,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "אזור התעשיה"
        },
        {
            "_id": 10751,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "אזור חוף הים"
        },
        {
            "_id": 10752,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 657,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 10753,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 505,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 10754,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 649,
            "שם_רחוב": "אימבר"
        },
        {
            "_id": 10755,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 315,
            "שם_רחוב": "אלמליח"
        },
        {
            "_id": 10756,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 647,
            "שם_רחוב": "אלתרמן"
        },
        {
            "_id": 10757,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 406,
            "שם_רחוב": "אנילביץ"
        },
        {
            "_id": 10758,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 614,
            "שם_רחוב": "אסירי ציון"
        },
        {
            "_id": 10759,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 645,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 10760,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 638,
            "שם_רחוב": "ארזים"
        },
        {
            "_id": 10761,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 100,
            "שם_רחוב": "אריק אינשטיין"
        },
        {
            "_id": 10762,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 209,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 10763,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 506,
            "שם_רחוב": "אש שלום"
        },
        {
            "_id": 10764,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 434,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 10765,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 653,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 10766,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 658,
            "שם_רחוב": "בזל"
        },
        {
            "_id": 10767,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 129,
            "שם_רחוב": "ביח ממשלתי"
        },
        {
            "_id": 10768,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 411,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 10769,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 664,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 10770,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 423,
            "שם_רחוב": "בית וגן"
        },
        {
            "_id": 10771,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 622,
            "שם_רחוב": "בכר ניסים"
        },
        {
            "_id": 10772,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 221,
            "שם_רחוב": "בלפור"
        },
        {
            "_id": 10773,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 10774,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 402,
            "שם_רחוב": "בר אילן"
        },
        {
            "_id": 10775,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 316,
            "שם_רחוב": "בר יהודה"
        },
        {
            "_id": 10776,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 217,
            "שם_רחוב": "ברדיצ'ב אבא"
        },
        {
            "_id": 10777,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 314,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 10778,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 668,
            "שם_רחוב": "ברץ"
        },
        {
            "_id": 10779,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בת ים"
        },
        {
            "_id": 10780,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 307,
            "שם_רחוב": "גאולים"
        },
        {
            "_id": 10781,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 322,
            "שם_רחוב": "גבעתי"
        },
        {
            "_id": 10782,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 409,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 10783,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "גוש עציון"
        },
        {
            "_id": 10784,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 632,
            "שם_רחוב": "גלנטי"
        },
        {
            "_id": 10785,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 652,
            "שם_רחוב": "גנוסר"
        },
        {
            "_id": 10786,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 131,
            "שם_רחוב": "גנם מתתיהו"
        },
        {
            "_id": 10787,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 553,
            "שם_רחוב": "גנרל קניג פייר"
        },
        {
            "_id": 10788,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 624,
            "שם_רחוב": "גריזים"
        },
        {
            "_id": 10789,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 135,
            "שם_רחוב": "דר כהן משה"
        },
        {
            "_id": 10790,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 313,
            "שם_רחוב": "דר ניר נחום"
        },
        {
            "_id": 10791,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 408,
            "שם_רחוב": "דגניה"
        },
        {
            "_id": 10792,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 663,
            "שם_רחוב": "דוגית"
        },
        {
            "_id": 10793,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 660,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 10794,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 318,
            "שם_רחוב": "דולפין"
        },
        {
            "_id": 10795,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 615,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 10796,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 426,
            "שם_רחוב": "דניאל"
        },
        {
            "_id": 10797,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 301,
            "שם_רחוב": "דנין יחזקאל"
        },
        {
            "_id": 10798,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 319,
            "שם_רחוב": "דקר"
        },
        {
            "_id": 10799,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 555,
            "שם_רחוב": "דרך אלון יגאל"
        },
        {
            "_id": 10800,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 212,
            "שם_רחוב": "דרך בן גוריון"
        },
        {
            "_id": 10801,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 620,
            "שם_רחוב": "דרך מנחם בגין"
        },
        {
            "_id": 10802,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 669,
            "שם_רחוב": "האדמור מבאבוב"
        },
        {
            "_id": 10803,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 606,
            "שם_רחוב": "האורגים"
        },
        {
            "_id": 10804,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 631,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 10805,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 666,
            "שם_רחוב": "הבריגדה"
        },
        {
            "_id": 10806,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הגבורים"
        },
        {
            "_id": 10807,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 511,
            "שם_רחוב": "הגבעות"
        },
        {
            "_id": 10808,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 419,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 10809,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 672,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 10810,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 536,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 10811,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 10812,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 642,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 10813,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 10814,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 512,
            "שם_רחוב": "הדדי ציון שאול"
        },
        {
            "_id": 10815,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 633,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 10816,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 430,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 10817,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 125,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 10818,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 407,
            "שם_רחוב": "הוז דב"
        },
        {
            "_id": 10819,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 202,
            "שם_רחוב": "הולצברג שמחה"
        },
        {
            "_id": 10820,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 10821,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "החלוצים"
        },
        {
            "_id": 10822,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 422,
            "שם_רחוב": "החרושת"
        },
        {
            "_id": 10823,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 403,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 10824,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 610,
            "שם_רחוב": "החשמל"
        },
        {
            "_id": 10825,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 228,
            "שם_רחוב": "הים"
        },
        {
            "_id": 10826,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 232,
            "שם_רחוב": "הירש"
        },
        {
            "_id": 10827,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 542,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 10828,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 323,
            "שם_רחוב": "הלא"
        },
        {
            "_id": 10829,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 208,
            "שם_רחוב": "הלפר"
        },
        {
            "_id": 10830,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 656,
            "שם_רחוב": "המיסדים"
        },
        {
            "_id": 10831,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 603,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 10832,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 10833,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "המרכז"
        },
        {
            "_id": 10834,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 648,
            "שם_רחוב": "המשוררת רחל"
        },
        {
            "_id": 10835,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 304,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 10836,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 621,
            "שם_רחוב": "הסבוראים"
        },
        {
            "_id": 10837,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 605,
            "שם_רחוב": "הסוללים"
        },
        {
            "_id": 10838,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 219,
            "שם_רחוב": "העמדות"
        },
        {
            "_id": 10839,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 607,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 10840,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 543,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 10841,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 535,
            "שם_רחוב": "העצמון"
        },
        {
            "_id": 10842,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 404,
            "שם_רחוב": "הפועל"
        },
        {
            "_id": 10843,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 321,
            "שם_רחוב": "הפטמן"
        },
        {
            "_id": 10844,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 609,
            "שם_רחוב": "הפלדה"
        },
        {
            "_id": 10845,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 10846,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 611,
            "שם_רחוב": "הצורפים"
        },
        {
            "_id": 10847,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 10848,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 320,
            "שם_רחוב": "הצפירה"
        },
        {
            "_id": 10849,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 311,
            "שם_רחוב": "הקוממיות"
        },
        {
            "_id": 10850,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 226,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 10851,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הרב אביעד"
        },
        {
            "_id": 10852,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 237,
            "שם_רחוב": "הרב בר שאול"
        },
        {
            "_id": 10853,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 665,
            "שם_רחוב": "הרב גביזון"
        },
        {
            "_id": 10854,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 416,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 10855,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 544,
            "שם_רחוב": "הרב לוזון"
        },
        {
            "_id": 10856,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 523,
            "שם_רחוב": "הרב לוי"
        },
        {
            "_id": 10857,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 613,
            "שם_רחוב": "הרב מימון"
        },
        {
            "_id": 10858,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 508,
            "שם_רחוב": "הרב ניסנבוים יצחק"
        },
        {
            "_id": 10859,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 414,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 10860,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 628,
            "שם_רחוב": "הרב צבי יהודה"
        },
        {
            "_id": 10861,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 214,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 10862,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 240,
            "שם_רחוב": "הרב קוקיס"
        },
        {
            "_id": 10863,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 619,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 10864,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 210,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 10865,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 413,
            "שם_רחוב": "הרשת"
        },
        {
            "_id": 10866,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 623,
            "שם_רחוב": "השבטים"
        },
        {
            "_id": 10867,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 529,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 10868,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "השומרים"
        },
        {
            "_id": 10869,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "השלושה"
        },
        {
            "_id": 10870,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 308,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 10871,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 644,
            "שם_רחוב": "השר ברזילי"
        },
        {
            "_id": 10872,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 437,
            "שם_רחוב": "השר פנקס"
        },
        {
            "_id": 10873,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 446,
            "שם_רחוב": "השר שפירא"
        },
        {
            "_id": 10874,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 531,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 10875,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 641,
            "שם_רחוב": "התותחנים"
        },
        {
            "_id": 10876,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 501,
            "שם_רחוב": "התחיה"
        },
        {
            "_id": 10877,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 10878,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 604,
            "שם_רחוב": "וישינגרד ישראל"
        },
        {
            "_id": 10879,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 302,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 10880,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 305,
            "שם_רחוב": "ז'ילבר"
        },
        {
            "_id": 10881,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 431,
            "שם_רחוב": "זמנהוף"
        },
        {
            "_id": 10882,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 654,
            "שם_רחוב": "זרובבל"
        },
        {
            "_id": 10883,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6542,
            "שם_רחוב": "חוף הסלע"
        },
        {
            "_id": 10884,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 667,
            "שם_רחוב": "חיש"
        },
        {
            "_id": 10885,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 133,
            "שם_רחוב": "חלמית"
        },
        {
            "_id": 10886,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 678,
            "שם_רחוב": "חלפון"
        },
        {
            "_id": 10887,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 532,
            "שם_רחוב": "חצור"
        },
        {
            "_id": 10888,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 639,
            "שם_רחוב": "טבנקין"
        },
        {
            "_id": 10889,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 420,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 10890,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 412,
            "שם_רחוב": "טשרניחובסקי"
        },
        {
            "_id": 10891,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 309,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 10892,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 608,
            "שם_רחוב": "יוחנן הסנדלר"
        },
        {
            "_id": 10893,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 132,
            "שם_רחוב": "יוסף הנשיא"
        },
        {
            "_id": 10894,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 537,
            "שם_רחוב": "יטבתה"
        },
        {
            "_id": 10895,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 677,
            "שם_רחוב": "יצחק נפחא"
        },
        {
            "_id": 10896,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 312,
            "שם_רחוב": "יצחק שדה"
        },
        {
            "_id": 10897,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 602,
            "שם_רחוב": "יקואל מנחם"
        },
        {
            "_id": 10898,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 651,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 10899,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 10900,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 546,
            "שם_רחוב": "ירקוני"
        },
        {
            "_id": 10901,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 218,
            "שם_רחוב": "ישראל בן ציון"
        },
        {
            "_id": 10902,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 521,
            "שם_רחוב": "כט בנובמבר"
        },
        {
            "_id": 10903,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 140,
            "שם_רחוב": "כביש גבול חולון"
        },
        {
            "_id": 10904,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 518,
            "שם_רחוב": "כהן אלי"
        },
        {
            "_id": 10905,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 541,
            "שם_רחוב": "כובשי החרמון"
        },
        {
            "_id": 10906,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר המגינים"
        },
        {
            "_id": 10907,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר המדינה"
        },
        {
            "_id": 10908,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר העצמאות"
        },
        {
            "_id": 10909,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר וינגייט"
        },
        {
            "_id": 10910,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 650,
            "שם_רחוב": "כנור דוד"
        },
        {
            "_id": 10911,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 433,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 10912,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 401,
            "שם_רחוב": "כצנלסון ברל"
        },
        {
            "_id": 10913,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 517,
            "שם_רחוב": "לויתן"
        },
        {
            "_id": 10914,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 629,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 10915,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 522,
            "שם_רחוב": "ליבורנו"
        },
        {
            "_id": 10916,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 224,
            "שם_רחוב": "ליבסקינד"
        },
        {
            "_id": 10917,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 415,
            "שם_רחוב": "ליואי אברהם"
        },
        {
            "_id": 10918,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 540,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 10919,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 425,
            "שם_רחוב": "מג"
        },
        {
            "_id": 10920,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 503,
            "שם_רחוב": "מבצע סיני"
        },
        {
            "_id": 10921,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 539,
            "שם_רחוב": "מגידו"
        },
        {
            "_id": 10922,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 519,
            "שם_רחוב": "מודיליאני"
        },
        {
            "_id": 10923,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 538,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 10924,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 670,
            "שם_רחוב": "מוהליבר"
        },
        {
            "_id": 10925,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 634,
            "שם_רחוב": "מוכרי הסיגריות"
        },
        {
            "_id": 10926,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 130,
            "שם_רחוב": "מחרוזת"
        },
        {
            "_id": 10927,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 442,
            "שם_רחוב": "מייזל זלמן"
        },
        {
            "_id": 10928,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 229,
            "שם_רחוב": "מלון מרינה"
        },
        {
            "_id": 10929,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 227,
            "שם_רחוב": "מלון סאן טון"
        },
        {
            "_id": 10930,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 436,
            "שם_רחוב": "מנדלי מוכר ספרים"
        },
        {
            "_id": 10931,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 225,
            "שם_רחוב": "מסיקה"
        },
        {
            "_id": 10932,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 216,
            "שם_רחוב": "מסריק"
        },
        {
            "_id": 10933,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "מע בת ים"
        },
        {
            "_id": 10934,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 223,
            "שם_רחוב": "מעבר ישי"
        },
        {
            "_id": 10935,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "מעונות ים"
        },
        {
            "_id": 10936,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 222,
            "שם_רחוב": "מעלה השחרור"
        },
        {
            "_id": 10937,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 530,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 10938,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 675,
            "שם_רחוב": "מצודת ביתר"
        },
        {
            "_id": 10939,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 127,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 10940,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 659,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 10941,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 325,
            "שם_רחוב": "משעול אייל"
        },
        {
            "_id": 10942,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "נגבה"
        },
        {
            "_id": 10943,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 239,
            "שם_רחוב": "נויקלן"
        },
        {
            "_id": 10944,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 443,
            "שם_רחוב": "נורדאו מקס"
        },
        {
            "_id": 10945,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "נחייסי אליהו"
        },
        {
            "_id": 10946,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "ניצנה"
        },
        {
            "_id": 10947,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "נעמי שמר"
        },
        {
            "_id": 10948,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 630,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 10949,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 514,
            "שם_רחוב": "סולד הנרייטה"
        },
        {
            "_id": 10950,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 215,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 10951,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 515,
            "שם_רחוב": "סטרומה"
        },
        {
            "_id": 10952,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 527,
            "שם_רחוב": "סמ האילנות"
        },
        {
            "_id": 10953,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 525,
            "שם_רחוב": "סמ הורדים"
        },
        {
            "_id": 10954,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 528,
            "שם_רחוב": "סמ הצפורנים"
        },
        {
            "_id": 10955,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 231,
            "שם_רחוב": "סמ הראשונים"
        },
        {
            "_id": 10956,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 526,
            "שם_רחוב": "סמ השושנים"
        },
        {
            "_id": 10957,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 533,
            "שם_רחוב": "סמ השרון"
        },
        {
            "_id": 10958,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 520,
            "שם_רחוב": "סמ ישראלס"
        },
        {
            "_id": 10959,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 427,
            "שם_רחוב": "סמ מיסיסטרנו"
        },
        {
            "_id": 10960,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 428,
            "שם_רחוב": "סמ נייגו"
        },
        {
            "_id": 10961,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 524,
            "שם_רחוב": "סמ נילי"
        },
        {
            "_id": 10962,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 507,
            "שם_רחוב": "סנה משה"
        },
        {
            "_id": 10963,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 211,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 10964,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 655,
            "שם_רחוב": "עגנון"
        },
        {
            "_id": 10965,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 627,
            "שם_רחוב": "עדולם"
        },
        {
            "_id": 10966,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "עפרה חזה"
        },
        {
            "_id": 10967,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 206,
            "שם_רחוב": "פיינשטיין"
        },
        {
            "_id": 10968,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 324,
            "שם_רחוב": "פרופ' יואלי"
        },
        {
            "_id": 10969,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 410,
            "שם_רחוב": "פרלשטיין"
        },
        {
            "_id": 10970,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 612,
            "שם_רחוב": "פרנק אנה"
        },
        {
            "_id": 10971,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 616,
            "שם_רחוב": "פרץ"
        },
        {
            "_id": 10972,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 625,
            "שם_רחוב": "צ'רניאקוב"
        },
        {
            "_id": 10973,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 417,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 10974,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 10975,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "קדושי לוצק"
        },
        {
            "_id": 10976,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 213,
            "שם_רחוב": "קדושי קהיר"
        },
        {
            "_id": 10977,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 640,
            "שם_רחוב": "קהילת סלונים"
        },
        {
            "_id": 10978,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 421,
            "שם_רחוב": "קורצ'ק"
        },
        {
            "_id": 10979,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 418,
            "שם_רחוב": "קלויזנר"
        },
        {
            "_id": 10980,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 601,
            "שם_רחוב": "קנמון אהוד"
        },
        {
            "_id": 10981,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6538,
            "שם_רחוב": "קרית באבוב"
        },
        {
            "_id": 10982,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 441,
            "שם_רחוב": "קריתי"
        },
        {
            "_id": 10983,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 626,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 10984,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "קרן קימת לישראל"
        },
        {
            "_id": 10985,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 317,
            "שם_רחוב": "רבי בנימין"
        },
        {
            "_id": 10986,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 552,
            "שם_רחוב": "רבינוביץ'"
        },
        {
            "_id": 10987,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 502,
            "שם_רחוב": "רהב"
        },
        {
            "_id": 10988,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 220,
            "שם_רחוב": "רוטשילד"
        },
        {
            "_id": 10989,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 534,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 10990,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח 271"
        },
        {
            "_id": 10991,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 438,
            "שם_רחוב": "רייק חביבה"
        },
        {
            "_id": 10992,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 435,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 10993,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 207,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 10994,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 405,
            "שם_רחוב": "רמז"
        },
        {
            "_id": 10995,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "רמת הנשיא"
        },
        {
            "_id": 10996,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "רמת לוז"
        },
        {
            "_id": 10997,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 643,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 10998,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "ש העיריה"
        },
        {
            "_id": 10999,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6537,
            "שם_רחוב": "ש הפוהמז"
        },
        {
            "_id": 11000,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "ש ותיקים"
        },
        {
            "_id": 11001,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "ש עולה חדש"
        },
        {
            "_id": 11002,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 11003,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "ש רמות ים"
        },
        {
            "_id": 11004,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "ש רמת יוסף"
        },
        {
            "_id": 11005,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 662,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 11006,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 617,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 11007,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 201,
            "שם_רחוב": "שד העצמאות"
        },
        {
            "_id": 11008,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 680,
            "שם_רחוב": "שד וילרבאן"
        },
        {
            "_id": 11009,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 310,
            "שם_רחוב": "שד יוספטל גיורא"
        },
        {
            "_id": 11010,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "שושנה דמארי"
        },
        {
            "_id": 11011,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 636,
            "שם_רחוב": "שטרן"
        },
        {
            "_id": 11012,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 306,
            "שם_רחוב": "שיבת ציון"
        },
        {
            "_id": 11013,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 635,
            "שם_רחוב": "שינדלר אוסקר"
        },
        {
            "_id": 11014,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6539,
            "שם_רחוב": "שכ הקרוונים"
        },
        {
            "_id": 11015,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ סחנה ג"
        },
        {
            "_id": 11016,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "שכ סחנה ד"
        },
        {
            "_id": 11017,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 432,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 11018,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 661,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 11019,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 676,
            "שם_רחוב": "שמעון הבורסקי"
        },
        {
            "_id": 11020,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 671,
            "שם_רחוב": "שפרבר חיים"
        },
        {
            "_id": 11021,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 439,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 11022,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 440,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 11023,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 545,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 11024,
            "סמל_ישוב": 6200,
            "שם_ישוב": "בת ים ",
            "סמל_רחוב": 513,
            "שם_רחוב": "תנין"
        },
        {
            "_id": 11025,
            "סמל_ישוב": 3794,
            "שם_ישוב": "בת עין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בת עין"
        },
        {
            "_id": 11026,
            "סמל_ישוב": 3794,
            "שם_ישוב": "בת עין ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ישיבת בת עין"
        },
        {
            "_id": 11027,
            "סמל_ישוב": 3794,
            "שם_ישוב": "בת עין ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מדרשת בת עין"
        },
        {
            "_id": 11028,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "בת שלמה"
        },
        {
            "_id": 11029,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "האפרסק"
        },
        {
            "_id": 11030,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 11031,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 11032,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 11033,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "המשמש"
        },
        {
            "_id": 11034,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 11035,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "השזיף"
        },
        {
            "_id": 11036,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "השסק"
        },
        {
            "_id": 11037,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 11038,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "משעול הלימון"
        },
        {
            "_id": 11039,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "משעול הצבר"
        },
        {
            "_id": 11040,
            "סמל_ישוב": 33,
            "שם_ישוב": "בת שלמה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "משעול התפוח"
        },
        {
            "_id": 11041,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "אזור התעשייה"
        },
        {
            "_id": 11042,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "אל מרג'"
        },
        {
            "_id": 11043,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ג'דיידה"
        },
        {
            "_id": 11044,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ג'דיידה-מכר"
        },
        {
            "_id": 11045,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "גרעין הכפר-ג'דיידה"
        },
        {
            "_id": 11046,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "הגרעין הישן-מכר"
        },
        {
            "_id": 11047,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "השכונה הדרומית-מכר"
        },
        {
            "_id": 11048,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6742,
            "שם_רחוב": "השכונה המערבית-מכר"
        },
        {
            "_id": 11049,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6741,
            "שם_רחוב": "השכונה הצפונית-מכר"
        },
        {
            "_id": 11050,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 201,
            "שם_רחוב": "מכר"
        },
        {
            "_id": 11051,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 199,
            "שם_רחוב": "רח 1100"
        },
        {
            "_id": 11052,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רח 1010"
        },
        {
            "_id": 11053,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 210,
            "שם_רחוב": "רח 1110"
        },
        {
            "_id": 11054,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "רח 1020"
        },
        {
            "_id": 11055,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 220,
            "שם_רחוב": "רח 1120"
        },
        {
            "_id": 11056,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 130,
            "שם_רחוב": "רח 1030"
        },
        {
            "_id": 11057,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 230,
            "שם_רחוב": "רח 1130"
        },
        {
            "_id": 11058,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 140,
            "שם_רחוב": "רח 1040"
        },
        {
            "_id": 11059,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 240,
            "שם_רחוב": "רח 1140"
        },
        {
            "_id": 11060,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 150,
            "שם_רחוב": "רח 1050"
        },
        {
            "_id": 11061,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 160,
            "שם_רחוב": "רח 1060"
        },
        {
            "_id": 11062,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 169,
            "שם_רחוב": "רח 1070"
        },
        {
            "_id": 11063,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 179,
            "שם_רחוב": "רח 1080"
        },
        {
            "_id": 11064,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 189,
            "שם_רחוב": "רח 1090"
        },
        {
            "_id": 11065,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רח 1001"
        },
        {
            "_id": 11066,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 200,
            "שם_רחוב": "רח 1101"
        },
        {
            "_id": 11067,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רח 1011"
        },
        {
            "_id": 11068,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 211,
            "שם_רחוב": "רח 1111"
        },
        {
            "_id": 11069,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רח 1021"
        },
        {
            "_id": 11070,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 221,
            "שם_רחוב": "רח 1121"
        },
        {
            "_id": 11071,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 131,
            "שם_רחוב": "רח 1031"
        },
        {
            "_id": 11072,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 231,
            "שם_רחוב": "רח 1131"
        },
        {
            "_id": 11073,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 141,
            "שם_רחוב": "רח 1041"
        },
        {
            "_id": 11074,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 241,
            "שם_רחוב": "רח 1141"
        },
        {
            "_id": 11075,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 151,
            "שם_רחוב": "רח 1051"
        },
        {
            "_id": 11076,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 161,
            "שם_רחוב": "רח 1061"
        },
        {
            "_id": 11077,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 170,
            "שם_רחוב": "רח 1071"
        },
        {
            "_id": 11078,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 180,
            "שם_רחוב": "רח 1081"
        },
        {
            "_id": 11079,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 190,
            "שם_רחוב": "רח 1091"
        },
        {
            "_id": 11080,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רח 1002"
        },
        {
            "_id": 11081,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 202,
            "שם_רחוב": "רח 1102"
        },
        {
            "_id": 11082,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רח 1012"
        },
        {
            "_id": 11083,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 212,
            "שם_רחוב": "רח 1112"
        },
        {
            "_id": 11084,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 222,
            "שם_רחוב": "רח 1122"
        },
        {
            "_id": 11085,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רח 1032"
        },
        {
            "_id": 11086,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 232,
            "שם_רחוב": "רח 1132"
        },
        {
            "_id": 11087,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רח 1042"
        },
        {
            "_id": 11088,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 242,
            "שם_רחוב": "רח 1142"
        },
        {
            "_id": 11089,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 152,
            "שם_רחוב": "רח 1052"
        },
        {
            "_id": 11090,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 162,
            "שם_רחוב": "רח 1062"
        },
        {
            "_id": 11091,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 171,
            "שם_רחוב": "רח 1072"
        },
        {
            "_id": 11092,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 181,
            "שם_רחוב": "רח 1082"
        },
        {
            "_id": 11093,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 191,
            "שם_רחוב": "רח 1092"
        },
        {
            "_id": 11094,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח 1003"
        },
        {
            "_id": 11095,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 203,
            "שם_רחוב": "רח 1103"
        },
        {
            "_id": 11096,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רח 1013"
        },
        {
            "_id": 11097,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רח 1113"
        },
        {
            "_id": 11098,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רח 1023"
        },
        {
            "_id": 11099,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 223,
            "שם_רחוב": "רח 1123"
        },
        {
            "_id": 11100,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 133,
            "שם_רחוב": "רח 1033"
        },
        {
            "_id": 11101,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 233,
            "שם_רחוב": "רח 1133"
        },
        {
            "_id": 11102,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 143,
            "שם_רחוב": "רח 1043"
        },
        {
            "_id": 11103,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 153,
            "שם_רחוב": "רח 1053"
        },
        {
            "_id": 11104,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 163,
            "שם_רחוב": "רח 1063"
        },
        {
            "_id": 11105,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 172,
            "שם_רחוב": "רח 1073"
        },
        {
            "_id": 11106,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 182,
            "שם_רחוב": "רח 1083"
        },
        {
            "_id": 11107,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 192,
            "שם_רחוב": "רח 1093"
        },
        {
            "_id": 11108,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רח 1004"
        },
        {
            "_id": 11109,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 204,
            "שם_רחוב": "רח 1104"
        },
        {
            "_id": 11110,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח 1014"
        },
        {
            "_id": 11111,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 214,
            "שם_רחוב": "רח 1114"
        },
        {
            "_id": 11112,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רח 1024"
        },
        {
            "_id": 11113,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 224,
            "שם_רחוב": "רח 1124"
        },
        {
            "_id": 11114,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 134,
            "שם_רחוב": "רח 1034"
        },
        {
            "_id": 11115,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 144,
            "שם_רחוב": "רח 1044"
        },
        {
            "_id": 11116,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 154,
            "שם_רחוב": "רח 1054"
        },
        {
            "_id": 11117,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 164,
            "שם_רחוב": "רח 1064"
        },
        {
            "_id": 11118,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 173,
            "שם_רחוב": "רח 1074"
        },
        {
            "_id": 11119,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 183,
            "שם_רחוב": "רח 1084"
        },
        {
            "_id": 11120,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 193,
            "שם_רחוב": "רח 1094"
        },
        {
            "_id": 11121,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רח 1005"
        },
        {
            "_id": 11122,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 205,
            "שם_רחוב": "רח 1105"
        },
        {
            "_id": 11123,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רח 1015"
        },
        {
            "_id": 11124,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 215,
            "שם_רחוב": "רח 1115"
        },
        {
            "_id": 11125,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רח 1025"
        },
        {
            "_id": 11126,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 225,
            "שם_רחוב": "רח 1125"
        },
        {
            "_id": 11127,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 135,
            "שם_רחוב": "רח 1035"
        },
        {
            "_id": 11128,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 145,
            "שם_רחוב": "רח 1045"
        },
        {
            "_id": 11129,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 155,
            "שם_רחוב": "רח 1055"
        },
        {
            "_id": 11130,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 165,
            "שם_רחוב": "רח 1065"
        },
        {
            "_id": 11131,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 174,
            "שם_רחוב": "רח 1075"
        },
        {
            "_id": 11132,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 184,
            "שם_רחוב": "רח 1085"
        },
        {
            "_id": 11133,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 194,
            "שם_רחוב": "רח 1095"
        },
        {
            "_id": 11134,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רח 1006"
        },
        {
            "_id": 11135,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 206,
            "שם_רחוב": "רח 1106"
        },
        {
            "_id": 11136,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רח 1016"
        },
        {
            "_id": 11137,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 216,
            "שם_רחוב": "רח 1116"
        },
        {
            "_id": 11138,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 126,
            "שם_רחוב": "רח 1026"
        },
        {
            "_id": 11139,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 226,
            "שם_רחוב": "רח 1126"
        },
        {
            "_id": 11140,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 136,
            "שם_רחוב": "רח 1036"
        },
        {
            "_id": 11141,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 236,
            "שם_רחוב": "רח 1136"
        },
        {
            "_id": 11142,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 146,
            "שם_רחוב": "רח 1046"
        },
        {
            "_id": 11143,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 156,
            "שם_רחוב": "רח 1056"
        },
        {
            "_id": 11144,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 166,
            "שם_רחוב": "רח 1066"
        },
        {
            "_id": 11145,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 175,
            "שם_רחוב": "רח 1076"
        },
        {
            "_id": 11146,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 185,
            "שם_רחוב": "רח 1086"
        },
        {
            "_id": 11147,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 195,
            "שם_רחוב": "רח 1096"
        },
        {
            "_id": 11148,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רח 1007"
        },
        {
            "_id": 11149,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 207,
            "שם_רחוב": "רח 1107"
        },
        {
            "_id": 11150,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח 1017"
        },
        {
            "_id": 11151,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 217,
            "שם_רחוב": "רח 1117"
        },
        {
            "_id": 11152,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "רח 1027"
        },
        {
            "_id": 11153,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 227,
            "שם_רחוב": "רח 1127"
        },
        {
            "_id": 11154,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 137,
            "שם_רחוב": "רח 1037"
        },
        {
            "_id": 11155,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 237,
            "שם_רחוב": "רח 1137"
        },
        {
            "_id": 11156,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 147,
            "שם_רחוב": "רח 1047"
        },
        {
            "_id": 11157,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 157,
            "שם_רחוב": "רח 1057"
        },
        {
            "_id": 11158,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 176,
            "שם_רחוב": "רח 1077"
        },
        {
            "_id": 11159,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 186,
            "שם_רחוב": "רח 1087"
        },
        {
            "_id": 11160,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 196,
            "שם_רחוב": "רח 1097"
        },
        {
            "_id": 11161,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "רח 1008"
        },
        {
            "_id": 11162,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 208,
            "שם_רחוב": "רח 1108"
        },
        {
            "_id": 11163,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח 1018"
        },
        {
            "_id": 11164,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 218,
            "שם_רחוב": "רח 1118"
        },
        {
            "_id": 11165,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 128,
            "שם_רחוב": "רח 1028"
        },
        {
            "_id": 11166,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 228,
            "שם_רחוב": "רח 1128"
        },
        {
            "_id": 11167,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 138,
            "שם_רחוב": "רח 1038"
        },
        {
            "_id": 11168,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 238,
            "שם_רחוב": "רח 1138"
        },
        {
            "_id": 11169,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 148,
            "שם_רחוב": "רח 1048"
        },
        {
            "_id": 11170,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 158,
            "שם_רחוב": "רח 1058"
        },
        {
            "_id": 11171,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 167,
            "שם_רחוב": "רח 1068"
        },
        {
            "_id": 11172,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 177,
            "שם_רחוב": "רח 1078"
        },
        {
            "_id": 11173,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 187,
            "שם_רחוב": "רח 1088"
        },
        {
            "_id": 11174,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 197,
            "שם_רחוב": "רח 1098"
        },
        {
            "_id": 11175,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רח 1009"
        },
        {
            "_id": 11176,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רח 1109"
        },
        {
            "_id": 11177,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רח 1019"
        },
        {
            "_id": 11178,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 219,
            "שם_רחוב": "רח 1119"
        },
        {
            "_id": 11179,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 129,
            "שם_רחוב": "רח 1029"
        },
        {
            "_id": 11180,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 229,
            "שם_רחוב": "רח 1129"
        },
        {
            "_id": 11181,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 139,
            "שם_רחוב": "רח 1039"
        },
        {
            "_id": 11182,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 239,
            "שם_רחוב": "רח 1139"
        },
        {
            "_id": 11183,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 149,
            "שם_רחוב": "רח 1049"
        },
        {
            "_id": 11184,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 159,
            "שם_רחוב": "רח 1059"
        },
        {
            "_id": 11185,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 168,
            "שם_רחוב": "רח 1069"
        },
        {
            "_id": 11186,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 178,
            "שם_רחוב": "רח 1079"
        },
        {
            "_id": 11187,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 188,
            "שם_רחוב": "רח 1089"
        },
        {
            "_id": 11188,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 198,
            "שם_רחוב": "רח 1099"
        },
        {
            "_id": 11189,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "שיכונים דרומיים-מכר"
        },
        {
            "_id": 11190,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6734,
            "שם_רחוב": "שיכונים מזרחיים-מכר"
        },
        {
            "_id": 11191,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "שכ ברבור"
        },
        {
            "_id": 11192,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "שכ דרומית-ג'דיידה"
        },
        {
            "_id": 11193,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "שכ מזרח דרום-ג'דידה"
        },
        {
            "_id": 11194,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6733,
            "שם_רחוב": "שכ מזרח דרומית-מכר"
        },
        {
            "_id": 11195,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "שכ מזרחית-ג'דיידה"
        },
        {
            "_id": 11196,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "שכ מערבית-ג'דיידה"
        },
        {
            "_id": 11197,
            "סמל_ישוב": 1292,
            "שם_ישוב": "ג'דיידה-מכר ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "שכ צפונית-ג'דיידה"
        },
        {
            "_id": 11198,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 11199,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אלאס'יל"
        },
        {
            "_id": 11200,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אלאע'ניאא"
        },
        {
            "_id": 11201,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אלארז"
        },
        {
            "_id": 11202,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלבאבור"
        },
        {
            "_id": 11203,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלבאנזין"
        },
        {
            "_id": 11204,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלבאניאס"
        },
        {
            "_id": 11205,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלבאשא"
        },
        {
            "_id": 11206,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אלבוסתאן"
        },
        {
            "_id": 11207,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלבטיח'ה"
        },
        {
            "_id": 11208,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אלביארה"
        },
        {
            "_id": 11209,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלבידר"
        },
        {
            "_id": 11210,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלביר"
        },
        {
            "_id": 11211,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 194,
            "שם_רחוב": "אלבלח"
        },
        {
            "_id": 11212,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלבלסם"
        },
        {
            "_id": 11213,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אלבנא"
        },
        {
            "_id": 11214,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אלבנפסג'"
        },
        {
            "_id": 11215,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אלברק"
        },
        {
            "_id": 11216,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אלג'באל"
        },
        {
            "_id": 11217,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלג'בל"
        },
        {
            "_id": 11218,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אלג'ורי"
        },
        {
            "_id": 11219,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלג'ליל"
        },
        {
            "_id": 11220,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אלדיואן"
        },
        {
            "_id": 11221,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלואדי"
        },
        {
            "_id": 11222,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלואדי אלשמאלי"
        },
        {
            "_id": 11223,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלוזיאת"
        },
        {
            "_id": 11224,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 197,
            "שם_רחוב": "אלורד"
        },
        {
            "_id": 11225,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אלורור"
        },
        {
            "_id": 11226,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלזהור"
        },
        {
            "_id": 11227,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלזהר"
        },
        {
            "_id": 11228,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אלזית"
        },
        {
            "_id": 11229,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 11230,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלזקאק"
        },
        {
            "_id": 11231,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אלח'זאן"
        },
        {
            "_id": 11232,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אלח'יזראן"
        },
        {
            "_id": 11233,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אלח'יל"
        },
        {
            "_id": 11234,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אלח'לא"
        },
        {
            "_id": 11235,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלח'לאל"
        },
        {
            "_id": 11236,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלח'לה"
        },
        {
            "_id": 11237,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 212,
            "שם_רחוב": "אלח'רבה"
        },
        {
            "_id": 11238,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלח'רובה"
        },
        {
            "_id": 11239,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אלחואר"
        },
        {
            "_id": 11240,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אלחור"
        },
        {
            "_id": 11241,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלחורה"
        },
        {
            "_id": 11242,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אלחורש"
        },
        {
            "_id": 11243,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלחיאת"
        },
        {
            "_id": 11244,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אלחס'יד"
        },
        {
            "_id": 11245,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלכרום"
        },
        {
            "_id": 11246,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אלכרז"
        },
        {
            "_id": 11247,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אלכרם"
        },
        {
            "_id": 11248,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלכרמל"
        },
        {
            "_id": 11249,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלמג'ד"
        },
        {
            "_id": 11250,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 218,
            "שם_רחוב": "אלמוהרה"
        },
        {
            "_id": 11251,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אלמוח'תארה"
        },
        {
            "_id": 11252,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אלמוסתקבל"
        },
        {
            "_id": 11253,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלמורג'אן"
        },
        {
            "_id": 11254,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלמזראב"
        },
        {
            "_id": 11255,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלמטל"
        },
        {
            "_id": 11256,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלמידאן"
        },
        {
            "_id": 11257,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אלמנזול"
        },
        {
            "_id": 11258,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אלמנקוש"
        },
        {
            "_id": 11259,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אלמע'איר"
        },
        {
            "_id": 11260,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלמע'ארה"
        },
        {
            "_id": 11261,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלמעס'רה"
        },
        {
            "_id": 11262,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלנג'מה"
        },
        {
            "_id": 11263,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אלנואר"
        },
        {
            "_id": 11264,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אלנורס"
        },
        {
            "_id": 11265,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 196,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 11266,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלנח'ל"
        },
        {
            "_id": 11267,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלנחאס"
        },
        {
            "_id": 11268,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אלנקאטה"
        },
        {
            "_id": 11269,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 11270,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 180,
            "שם_רחוב": "אלס'באר"
        },
        {
            "_id": 11271,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלס'בר"
        },
        {
            "_id": 11272,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אלס'פא"
        },
        {
            "_id": 11273,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלסהל"
        },
        {
            "_id": 11274,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אלסומאק"
        },
        {
            "_id": 11275,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אלסיוף"
        },
        {
            "_id": 11276,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 11277,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 193,
            "שם_רחוב": "אלסמא"
        },
        {
            "_id": 11278,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלסמרא"
        },
        {
            "_id": 11279,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלסנדיאן"
        },
        {
            "_id": 11280,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אלספאח"
        },
        {
            "_id": 11281,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אלסריס"
        },
        {
            "_id": 11282,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 183,
            "שם_רחוב": "אלע'אר"
        },
        {
            "_id": 11283,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלע'ורבאל"
        },
        {
            "_id": 11284,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אלע'זאל"
        },
        {
            "_id": 11285,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אלעאלי"
        },
        {
            "_id": 11286,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלעיון"
        },
        {
            "_id": 11287,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלעלם"
        },
        {
            "_id": 11288,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלענאב"
        },
        {
            "_id": 11289,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלענב"
        },
        {
            "_id": 11290,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלענברה"
        },
        {
            "_id": 11291,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלקובה"
        },
        {
            "_id": 11292,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אלרומאנה"
        },
        {
            "_id": 11293,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 192,
            "שם_רחוב": "אלרוע'ב"
        },
        {
            "_id": 11294,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלריאד'ה"
        },
        {
            "_id": 11295,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלרפיף"
        },
        {
            "_id": 11296,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אלרפראף"
        },
        {
            "_id": 11297,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלשביבה"
        },
        {
            "_id": 11298,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלשורה"
        },
        {
            "_id": 11299,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלשיח'"
        },
        {
            "_id": 11300,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלשיח' עלי פארס"
        },
        {
            "_id": 11301,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אלשמאל"
        },
        {
            "_id": 11302,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלשקרא"
        },
        {
            "_id": 11303,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אלשרוק"
        },
        {
            "_id": 11304,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 195,
            "שם_רחוב": "אלתמר"
        },
        {
            "_id": 11305,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלתנור"
        },
        {
            "_id": 11306,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ג'ולס"
        },
        {
            "_id": 11307,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 127,
            "שם_רחוב": "ואדי חמרא"
        },
        {
            "_id": 11308,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 142,
            "שם_רחוב": "ירכא"
        },
        {
            "_id": 11309,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 126,
            "שם_רחוב": "כרם אלעדס"
        },
        {
            "_id": 11310,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 172,
            "שם_רחוב": "מארוש"
        },
        {
            "_id": 11311,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 152,
            "שם_רחוב": "מג'יזל"
        },
        {
            "_id": 11312,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 131,
            "שם_רחוב": "ראס ח'רוב"
        },
        {
            "_id": 11313,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 153,
            "שם_רחוב": "ראס קונבר"
        },
        {
            "_id": 11314,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 182,
            "שם_רחוב": "רומאן"
        },
        {
            "_id": 11315,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 125,
            "שם_רחוב": "תל גאד"
        },
        {
            "_id": 11316,
            "סמל_ישוב": 485,
            "שם_ישוב": "ג'ולס ",
            "סמל_רחוב": 169,
            "שם_רחוב": "תלה"
        },
        {
            "_id": 11317,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "א-זיתונה"
        },
        {
            "_id": 11318,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "א-נופליה"
        },
        {
            "_id": 11319,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "א-נור"
        },
        {
            "_id": 11320,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "א-נח'יל"
        },
        {
            "_id": 11321,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "א-נרג'ס"
        },
        {
            "_id": 11322,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "א-סלאם"
        },
        {
            "_id": 11323,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "א-סנאבל"
        },
        {
            "_id": 11324,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "א-סנובר"
        },
        {
            "_id": 11325,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "א-ראזי"
        },
        {
            "_id": 11326,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "א-רבאט"
        },
        {
            "_id": 11327,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "א-רוודה"
        },
        {
            "_id": 11328,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "א-רומאנה"
        },
        {
            "_id": 11329,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "א-רחמה"
        },
        {
            "_id": 11330,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "א-ריחאן"
        },
        {
            "_id": 11331,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "א-שאם"
        },
        {
            "_id": 11332,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "א-שאפעי"
        },
        {
            "_id": 11333,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "א-תל"
        },
        {
            "_id": 11334,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "א-תקווה"
        },
        {
            "_id": 11335,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אבו בכר"
        },
        {
            "_id": 11336,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אבו עבידה אלג'ראח"
        },
        {
            "_id": 11337,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אבן אל-הית'ם"
        },
        {
            "_id": 11338,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אבן חלדון"
        },
        {
            "_id": 11339,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 11340,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אל איסראא"
        },
        {
            "_id": 11341,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אל חיכמה"
        },
        {
            "_id": 11342,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "אל כראמה"
        },
        {
            "_id": 11343,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אל מעראג'"
        },
        {
            "_id": 11344,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אל קודס"
        },
        {
            "_id": 11345,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אל-אח'לאס"
        },
        {
            "_id": 11346,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אל-אחא"
        },
        {
            "_id": 11347,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אל-אימאן"
        },
        {
            "_id": 11348,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אל-איתיחאד"
        },
        {
            "_id": 11349,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אל-אמל"
        },
        {
            "_id": 11350,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אל-אנדלוס"
        },
        {
            "_id": 11351,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אל-ארז"
        },
        {
            "_id": 11352,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אל-בוח'ארי"
        },
        {
            "_id": 11353,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אל-ביארה"
        },
        {
            "_id": 11354,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אל-בירוני"
        },
        {
            "_id": 11355,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אל-ג'יזאיר"
        },
        {
            "_id": 11356,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אל-ג'ליל"
        },
        {
            "_id": 11357,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אל-הודא"
        },
        {
            "_id": 11358,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אל-וורוד"
        },
        {
            "_id": 11359,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אל-וחדה"
        },
        {
            "_id": 11360,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אל-ופאא"
        },
        {
            "_id": 11361,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אל-ופאק"
        },
        {
            "_id": 11362,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "אל-זאקור"
        },
        {
            "_id": 11363,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אל-זהראא"
        },
        {
            "_id": 11364,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אל-ח'אן"
        },
        {
            "_id": 11365,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אל-חנסאא"
        },
        {
            "_id": 11366,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אל-יסמין"
        },
        {
            "_id": 11367,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אל-עדל"
        },
        {
            "_id": 11368,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אל-קדאסיה"
        },
        {
            "_id": 11369,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "בגדד"
        },
        {
            "_id": 11370,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "ביירות"
        },
        {
            "_id": 11371,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "ביסאן"
        },
        {
            "_id": 11372,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ג'לג'וליה"
        },
        {
            "_id": 11373,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "דמשק"
        },
        {
            "_id": 11374,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "ואדי קאנא"
        },
        {
            "_id": 11375,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "ח'אלד אבן אל-ווליד"
        },
        {
            "_id": 11376,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "ח'ריש"
        },
        {
            "_id": 11377,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 11378,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 11379,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "טריק חבלה"
        },
        {
            "_id": 11380,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "יאפא"
        },
        {
            "_id": 11381,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "כפר סבא"
        },
        {
            "_id": 11382,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "מווילח"
        },
        {
            "_id": 11383,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "מכה"
        },
        {
            "_id": 11384,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "ס'פד"
        },
        {
            "_id": 11385,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "סלאח א-דין"
        },
        {
            "_id": 11386,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "ע'תמאן אבן עפאן"
        },
        {
            "_id": 11387,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "עומר אבן אל ח'טאב"
        },
        {
            "_id": 11388,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "עומר אל מוח'תאר"
        },
        {
            "_id": 11389,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "עין ג'אלות"
        },
        {
            "_id": 11390,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "עכא"
        },
        {
            "_id": 11391,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "עלי אבן אבי טאלב"
        },
        {
            "_id": 11392,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "ר'זה"
        },
        {
            "_id": 11393,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "ראס א-טביב"
        },
        {
            "_id": 11394,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רח 10"
        },
        {
            "_id": 11395,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "רח 110"
        },
        {
            "_id": 11396,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רח 112"
        },
        {
            "_id": 11397,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רח 105"
        },
        {
            "_id": 11398,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח 87"
        },
        {
            "_id": 11399,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רח 88"
        },
        {
            "_id": 11400,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "שמס א-דין"
        },
        {
            "_id": 11401,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "שקאיק א-נעמאן"
        },
        {
            "_id": 11402,
            "סמל_ישוב": 627,
            "שם_ישוב": "ג'לג'וליה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "תוניס"
        },
        {
            "_id": 11403,
            "סמל_ישוב": 976,
            "שם_ישוב": "ג'נאביב (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ג'נאביב (שבט)"
        },
        {
            "_id": 11404,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אבו בקר אלסדיק"
        },
        {
            "_id": 11405,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אבן חלדון"
        },
        {
            "_id": 11406,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 11407,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "אזור הים"
        },
        {
            "_id": 11408,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אל בחר"
        },
        {
            "_id": 11409,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אל לוז"
        },
        {
            "_id": 11410,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלאמואג'"
        },
        {
            "_id": 11411,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלאסד"
        },
        {
            "_id": 11412,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 11413,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלביארה"
        },
        {
            "_id": 11414,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלביר"
        },
        {
            "_id": 11415,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלבריד"
        },
        {
            "_id": 11416,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלהודהוד"
        },
        {
            "_id": 11417,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלואדי"
        },
        {
            "_id": 11418,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלזוקאק"
        },
        {
            "_id": 11419,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלזור"
        },
        {
            "_id": 11420,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 11421,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלחסקה"
        },
        {
            "_id": 11422,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלחרוב"
        },
        {
            "_id": 11423,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלטואחין"
        },
        {
            "_id": 11424,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלטנטורה"
        },
        {
            "_id": 11425,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלטרפה"
        },
        {
            "_id": 11426,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 11427,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלכוארזמי"
        },
        {
            "_id": 11428,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אלכנסאא'"
        },
        {
            "_id": 11429,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלכרמל"
        },
        {
            "_id": 11430,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלמוארס"
        },
        {
            "_id": 11431,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלמורג'אן"
        },
        {
            "_id": 11432,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלמחג'ר"
        },
        {
            "_id": 11433,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלמינא"
        },
        {
            "_id": 11434,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלמנארה"
        },
        {
            "_id": 11435,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלמרסאא"
        },
        {
            "_id": 11436,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלמתנבי"
        },
        {
            "_id": 11437,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלנהדה"
        },
        {
            "_id": 11438,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלסאחה"
        },
        {
            "_id": 11439,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלסדאף"
        },
        {
            "_id": 11440,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלסדרה"
        },
        {
            "_id": 11441,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלסמאר"
        },
        {
            "_id": 11442,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלסראג'"
        },
        {
            "_id": 11443,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 11444,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלקודס"
        },
        {
            "_id": 11445,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלקייסר"
        },
        {
            "_id": 11446,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלקנאה"
        },
        {
            "_id": 11447,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלקנדיל"
        },
        {
            "_id": 11448,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלקרש"
        },
        {
            "_id": 11449,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלראוודאת"
        },
        {
            "_id": 11450,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלרמאן"
        },
        {
            "_id": 11451,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלרמל"
        },
        {
            "_id": 11452,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלשאטיא"
        },
        {
            "_id": 11453,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלשאפיעי"
        },
        {
            "_id": 11454,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלשומריה"
        },
        {
            "_id": 11455,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלתמאסיח"
        },
        {
            "_id": 11456,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אנס בן מאלק"
        },
        {
            "_id": 11457,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 166,
            "שם_רחוב": "באב אלנהר"
        },
        {
            "_id": 11458,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 149,
            "שם_רחוב": "בגדאד"
        },
        {
            "_id": 11459,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 131,
            "שם_רחוב": "בלאל בן רבאח"
        },
        {
            "_id": 11460,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 152,
            "שם_רחוב": "ג'בראן ח'ליל ג'בראן"
        },
        {
            "_id": 11461,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 146,
            "שם_רחוב": "ג'ורת אלסלמון"
        },
        {
            "_id": 11462,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ג'סר א-זרקא"
        },
        {
            "_id": 11463,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 147,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 11464,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 163,
            "שם_רחוב": "יאפא"
        },
        {
            "_id": 11465,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "כיכר אלאקסא"
        },
        {
            "_id": 11466,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר אלבחרייה"
        },
        {
            "_id": 11467,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "כיכר אלג'אווהרה"
        },
        {
            "_id": 11468,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "כיכר אלטאחונה"
        },
        {
            "_id": 11469,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "כיכר אלכבארה"
        },
        {
            "_id": 11470,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "כיכר אלמתחף"
        },
        {
            "_id": 11471,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "כיכר אלסריס"
        },
        {
            "_id": 11472,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 120,
            "שם_רחוב": "מכה"
        },
        {
            "_id": 11473,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "מרכז הכפר"
        },
        {
            "_id": 11474,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 140,
            "שם_רחוב": "סלאח אלדין"
        },
        {
            "_id": 11475,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "עומר בן אלכטאב"
        },
        {
            "_id": 11476,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 108,
            "שם_רחוב": "עות'מאן בן עפאן"
        },
        {
            "_id": 11477,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 134,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 11478,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 129,
            "שם_רחוב": "קריית אלסיאדין"
        },
        {
            "_id": 11479,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 114,
            "שם_רחוב": "שג'רת אלדור"
        },
        {
            "_id": 11480,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "שכ אלמוארס"
        },
        {
            "_id": 11481,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "שכ אלרמל"
        },
        {
            "_id": 11482,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "שכ אלשומריה"
        },
        {
            "_id": 11483,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "שכונה דרומית"
        },
        {
            "_id": 11484,
            "סמל_ישוב": 541,
            "שם_ישוב": "ג'סר א-זרקא ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "שכונה צפונית"
        },
        {
            "_id": 11485,
            "סמל_ישוב": 487,
            "שם_ישוב": "ג'ש (גוש חלב) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ג'ש (גוש חלב)"
        },
        {
            "_id": 11486,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 174,
            "שם_רחוב": "א-דופלא"
        },
        {
            "_id": 11487,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 179,
            "שם_רחוב": "א-זאוויה"
        },
        {
            "_id": 11488,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 180,
            "שם_רחוב": "א-זבדיה"
        },
        {
            "_id": 11489,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 185,
            "שם_רחוב": "א-זהראוי"
        },
        {
            "_id": 11490,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 201,
            "שם_רחוב": "א-זהרה"
        },
        {
            "_id": 11491,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 202,
            "שם_רחוב": "א-זוהור"
        },
        {
            "_id": 11492,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 186,
            "שם_רחוב": "א-זיתון"
        },
        {
            "_id": 11493,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 184,
            "שם_רחוב": "א-זנג'ביל"
        },
        {
            "_id": 11494,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 181,
            "שם_רחוב": "א-זעאריר"
        },
        {
            "_id": 11495,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 183,
            "שם_רחוב": "א-זערורה"
        },
        {
            "_id": 11496,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 182,
            "שם_רחוב": "א-זעתר"
        },
        {
            "_id": 11497,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 200,
            "שם_רחוב": "א-טברי"
        },
        {
            "_id": 11498,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 235,
            "שם_רחוב": "א-נח'יל"
        },
        {
            "_id": 11499,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 234,
            "שם_רחוב": "א-נטוש"
        },
        {
            "_id": 11500,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 236,
            "שם_רחוב": "א-נמלאת"
        },
        {
            "_id": 11501,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 198,
            "שם_רחוב": "א-ס'ופסאף"
        },
        {
            "_id": 11502,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 199,
            "שם_רחוב": "א-ס'נובר"
        },
        {
            "_id": 11503,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 197,
            "שם_רחוב": "א-ס'פא"
        },
        {
            "_id": 11504,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 190,
            "שם_רחוב": "א-סוידיה"
        },
        {
            "_id": 11505,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 188,
            "שם_רחוב": "א-סולטאן סולימאן"
        },
        {
            "_id": 11506,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 189,
            "שם_רחוב": "א-סינדיאן"
        },
        {
            "_id": 11507,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 187,
            "שם_רחוב": "א-סעאדה"
        },
        {
            "_id": 11508,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 177,
            "שם_רחוב": "א-רוודה"
        },
        {
            "_id": 11509,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 176,
            "שם_רחוב": "א-רומאן"
        },
        {
            "_id": 11510,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 178,
            "שם_רחוב": "א-ריחאן"
        },
        {
            "_id": 11511,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 191,
            "שם_רחוב": "א-שאפיעי"
        },
        {
            "_id": 11512,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 192,
            "שם_רחוב": "א-שומר"
        },
        {
            "_id": 11513,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 196,
            "שם_רחוב": "א-שיח' מחמד א-ס'לאח"
        },
        {
            "_id": 11514,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 194,
            "שם_רחוב": "א-שיח' סעיד"
        },
        {
            "_id": 11515,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 195,
            "שם_רחוב": "א-שיח' עבד אללה"
        },
        {
            "_id": 11516,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 193,
            "שם_רחוב": "א-שיח' רדא"
        },
        {
            "_id": 11517,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 156,
            "שם_רחוב": "א-תין"
        },
        {
            "_id": 11518,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אבו א-ד'ר"
        },
        {
            "_id": 11519,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אבו א-דרדאא"
        },
        {
            "_id": 11520,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אבו איוב אל-אנס'ארי"
        },
        {
            "_id": 11521,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 247,
            "שם_רחוב": "אבו בכר א-ס'דיק"
        },
        {
            "_id": 11522,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אבו ד'ר אל-ר'פארי"
        },
        {
            "_id": 11523,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 253,
            "שם_רחוב": "אבו הורירה"
        },
        {
            "_id": 11524,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 251,
            "שם_רחוב": "אבו כאסטרו"
        },
        {
            "_id": 11525,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אבו מוסא אל-אשערי"
        },
        {
            "_id": 11526,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אבו סנה"
        },
        {
            "_id": 11527,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אבו עובידה אל-ג'ראח"
        },
        {
            "_id": 11528,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 250,
            "שם_רחוב": "אבו פראס אל-חמדני"
        },
        {
            "_id": 11529,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אבו קאהוק"
        },
        {
            "_id": 11530,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 248,
            "שם_רחוב": "אבו תמאם"
        },
        {
            "_id": 11531,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אבן אלאסוד אלדואלי"
        },
        {
            "_id": 11532,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אדם"
        },
        {
            "_id": 11533,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 241,
            "שם_רחוב": "אום סלמה"
        },
        {
            "_id": 11534,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אוסאמה בין זיד"
        },
        {
            "_id": 11535,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אחמד אבו עסבה"
        },
        {
            "_id": 11536,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אחמד אמין"
        },
        {
            "_id": 11537,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אחמד ג'באלי"
        },
        {
            "_id": 11538,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 122,
            "שם_רחוב": "איבן אל-בח'אר"
        },
        {
            "_id": 11539,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 123,
            "שם_רחוב": "איבן אל-היתם"
        },
        {
            "_id": 11540,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 366,
            "שם_רחוב": "איבן בטוטה"
        },
        {
            "_id": 11541,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 127,
            "שם_רחוב": "איבן זידון"
        },
        {
            "_id": 11542,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 125,
            "שם_רחוב": "איבן ח'לדון"
        },
        {
            "_id": 11543,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 131,
            "שם_רחוב": "איבן כת'יר"
        },
        {
            "_id": 11544,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 132,
            "שם_רחוב": "איבן מנדור"
        },
        {
            "_id": 11545,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 129,
            "שם_רחוב": "איבן סינא"
        },
        {
            "_id": 11546,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 128,
            "שם_רחוב": "איבן סירין"
        },
        {
            "_id": 11547,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 130,
            "שם_רחוב": "איבן עסאכר"
        },
        {
            "_id": 11548,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 126,
            "שם_רחוב": "איבן רושד"
        },
        {
            "_id": 11549,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 243,
            "שם_רחוב": "איבראהים אל-ח'ליל"
        },
        {
            "_id": 11550,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אל-אדריסי"
        },
        {
            "_id": 11551,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אל-אימאם אל-קורטובי"
        },
        {
            "_id": 11552,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אל-אקחוואן"
        },
        {
            "_id": 11553,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אל-ארז"
        },
        {
            "_id": 11554,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 365,
            "שם_רחוב": "אל-את'ל"
        },
        {
            "_id": 11555,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אל-בדיה"
        },
        {
            "_id": 11556,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אל-ביאד"
        },
        {
            "_id": 11557,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אל-ביאדר"
        },
        {
            "_id": 11558,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אל-ביאן"
        },
        {
            "_id": 11559,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אל-ביר א-שרקי"
        },
        {
            "_id": 11560,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אל-בירוני"
        },
        {
            "_id": 11561,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אל-בראא"
        },
        {
            "_id": 11562,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אל-בתאני"
        },
        {
            "_id": 11563,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אל-ג'ורף"
        },
        {
            "_id": 11564,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אל-ג'למה"
        },
        {
            "_id": 11565,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אל-ג'מיזה"
        },
        {
            "_id": 11566,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אל-ג'נחאת"
        },
        {
            "_id": 11567,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אל-ג'עדה"
        },
        {
            "_id": 11568,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אל-ג'עריה"
        },
        {
            "_id": 11569,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 237,
            "שם_רחוב": "אל-המדאנה"
        },
        {
            "_id": 11570,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 239,
            "שם_רחוב": "אל-ווערה"
        },
        {
            "_id": 11571,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 238,
            "שם_רחוב": "אל-וורוד"
        },
        {
            "_id": 11572,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אל-ח'וארג'"
        },
        {
            "_id": 11573,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אל-ח'ואריזמי"
        },
        {
            "_id": 11574,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אל-ח'לה"
        },
        {
            "_id": 11575,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אל-ח'ליל בין אחמד"
        },
        {
            "_id": 11576,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אל-ח'נסאא"
        },
        {
            "_id": 11577,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אל-ח'רב"
        },
        {
            "_id": 11578,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אל-ח'רוב"
        },
        {
            "_id": 11579,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אל-חג' יחיא"
        },
        {
            "_id": 11580,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אל-חיג'אז"
        },
        {
            "_id": 11581,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אל-חמידייאת"
        },
        {
            "_id": 11582,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אל-חסן אל-בס'רי"
        },
        {
            "_id": 11583,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אל-יקטין"
        },
        {
            "_id": 11584,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אל-כאפור"
        },
        {
            "_id": 11585,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אל-כירואן"
        },
        {
            "_id": 11586,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אל-מדינה אל-מנוורה"
        },
        {
            "_id": 11587,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אל-מוארס"
        },
        {
            "_id": 11588,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אל-מוס'רארה"
        },
        {
            "_id": 11589,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 225,
            "שם_רחוב": "אל-מוסלא"
        },
        {
            "_id": 11590,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 231,
            "שם_רחוב": "אל-מינטקה א-סנאעייה"
        },
        {
            "_id": 11591,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 228,
            "שם_רחוב": "אל-מיקדאד בין אסווד"
        },
        {
            "_id": 11592,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אל-מירמיה"
        },
        {
            "_id": 11593,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אל-מנת'ור"
        },
        {
            "_id": 11594,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אל-מסק"
        },
        {
            "_id": 11595,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 226,
            "שם_רחוב": "אל-מעס'רה"
        },
        {
            "_id": 11596,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אל-מקברה א-שרקייה"
        },
        {
            "_id": 11597,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 229,
            "שם_רחוב": "אל-מקריזי"
        },
        {
            "_id": 11598,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אל-עטארין"
        },
        {
            "_id": 11599,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אל-ענאב"
        },
        {
            "_id": 11600,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אל-ענבר"
        },
        {
            "_id": 11601,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אל-עקד"
        },
        {
            "_id": 11602,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אל-פול"
        },
        {
            "_id": 11603,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אל-פח'ת'"
        },
        {
            "_id": 11604,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אל-פיג'ם"
        },
        {
            "_id": 11605,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אל-פראבי"
        },
        {
            "_id": 11606,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 212,
            "שם_רחוב": "אל-פרדוס"
        },
        {
            "_id": 11607,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אל-קוב"
        },
        {
            "_id": 11608,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אל-קודס"
        },
        {
            "_id": 11609,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 218,
            "שם_רחוב": "אל-קטאין"
        },
        {
            "_id": 11610,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אל-ר'זאלי"
        },
        {
            "_id": 11611,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אנס בין מאליק"
        },
        {
            "_id": 11612,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 264,
            "שם_רחוב": "ביירות"
        },
        {
            "_id": 11613,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 257,
            "שם_רחוב": "בילאל בין רבאח"
        },
        {
            "_id": 11614,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 265,
            "שם_רחוב": "ביסאן"
        },
        {
            "_id": 11615,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 262,
            "שם_רחוב": "ביר א-סמא"
        },
        {
            "_id": 11616,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 261,
            "שם_רחוב": "ביר אל-בום"
        },
        {
            "_id": 11617,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 263,
            "שם_רחוב": "ביר שבטבט"
        },
        {
            "_id": 11618,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 259,
            "שם_רחוב": "בית אל-מועלם"
        },
        {
            "_id": 11619,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 260,
            "שם_רחוב": "בית אל-מקדס"
        },
        {
            "_id": 11620,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 258,
            "שם_רחוב": "בלקיס"
        },
        {
            "_id": 11621,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 255,
            "שם_רחוב": "בס'רא א-שאם"
        },
        {
            "_id": 11622,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 256,
            "שם_רחוב": "בר'דאד"
        },
        {
            "_id": 11623,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 254,
            "שם_רחוב": "בראק א-סוח'נה"
        },
        {
            "_id": 11624,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 267,
            "שם_רחוב": "ג'מיל נאיף"
        },
        {
            "_id": 11625,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ג'ת"
        },
        {
            "_id": 11626,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 266,
            "שם_רחוב": "ג'ת פדלה"
        },
        {
            "_id": 11627,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6783,
            "שם_רחוב": "ג'ת-שכונה דרומית"
        },
        {
            "_id": 11628,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6772,
            "שם_רחוב": "גרעין הכפר"
        },
        {
            "_id": 11629,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 310,
            "שם_רחוב": "ד'הר א-שנאם"
        },
        {
            "_id": 11630,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 286,
            "שם_רחוב": "ד. עבד א-רחים"
        },
        {
            "_id": 11631,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 285,
            "שם_רחוב": "ד.דאווד ערו"
        },
        {
            "_id": 11632,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 357,
            "שם_רחוב": "הוד"
        },
        {
            "_id": 11633,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 358,
            "שם_רחוב": "וער אל-פאיז"
        },
        {
            "_id": 11634,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 628,
            "שם_רחוב": "זוהור א-סלאם"
        },
        {
            "_id": 11635,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 292,
            "שם_רחוב": "זיד בין חארת'ה"
        },
        {
            "_id": 11636,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 291,
            "שם_רחוב": "זיד בין ת'אבת"
        },
        {
            "_id": 11637,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 293,
            "שם_רחוב": "זינב בינת ג'חש"
        },
        {
            "_id": 11638,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 279,
            "שם_רחוב": "ח'אלד בין אל-וליד"
        },
        {
            "_id": 11639,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 281,
            "שם_רחוב": "ח'ושת אל-ח'רוף"
        },
        {
            "_id": 11640,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 282,
            "שם_רחוב": "ח'לאיל נעים"
        },
        {
            "_id": 11641,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 283,
            "שם_רחוב": "ח'לת זיתה"
        },
        {
            "_id": 11642,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 284,
            "שם_רחוב": "ח'לת סמור"
        },
        {
            "_id": 11643,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 278,
            "שם_רחוב": "ח'נדלה בין אבי עאמר"
        },
        {
            "_id": 11644,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 270,
            "שם_רחוב": "חביב בין זיד"
        },
        {
            "_id": 11645,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 268,
            "שם_רחוב": "חבלת אבו סלאמה"
        },
        {
            "_id": 11646,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 269,
            "שם_רחוב": "חבלת אבו עלי"
        },
        {
            "_id": 11647,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 280,
            "שם_רחוב": "חדיג'ה בינת ח'וילד"
        },
        {
            "_id": 11648,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 271,
            "שם_רחוב": "חודיפה בין אל-ימאן"
        },
        {
            "_id": 11649,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 274,
            "שם_רחוב": "חיטין"
        },
        {
            "_id": 11650,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 276,
            "שם_רחוב": "חלימה א-סעדייה"
        },
        {
            "_id": 11651,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 277,
            "שם_רחוב": "חמזה בן עבד אלמוטלב"
        },
        {
            "_id": 11652,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 273,
            "שם_רחוב": "חסן בין ת'אבת"
        },
        {
            "_id": 11653,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 275,
            "שם_רחוב": "חפיסה בינת עמרו"
        },
        {
            "_id": 11654,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 272,
            "שם_רחוב": "חראא"
        },
        {
            "_id": 11655,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 309,
            "שם_רחוב": "טה"
        },
        {
            "_id": 11656,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 308,
            "שם_רחוב": "טלחה בין עוביד אללה"
        },
        {
            "_id": 11657,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 359,
            "שם_רחוב": "יאסין"
        },
        {
            "_id": 11658,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 362,
            "שם_רחוב": "יונס"
        },
        {
            "_id": 11659,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 361,
            "שם_רחוב": "יוסף"
        },
        {
            "_id": 11660,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 360,
            "שם_רחוב": "יעקוב"
        },
        {
            "_id": 11661,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 341,
            "שם_רחוב": "כוסיה"
        },
        {
            "_id": 11662,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 339,
            "שם_רחוב": "כעב בין זוהיר"
        },
        {
            "_id": 11663,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 340,
            "שם_רחוב": "כעוש"
        },
        {
            "_id": 11664,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 342,
            "שם_רחוב": "מאלק בין דינאר"
        },
        {
            "_id": 11665,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 343,
            "שם_רחוב": "מוחמד אל-פאתח"
        },
        {
            "_id": 11666,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 353,
            "שם_רחוב": "מוס בין נס'יר"
        },
        {
            "_id": 11667,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 348,
            "שם_רחוב": "מוס'טפא מחמוד"
        },
        {
            "_id": 11668,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 354,
            "שם_רחוב": "מוסא"
        },
        {
            "_id": 11669,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 355,
            "שם_רחוב": "מוסא איבן לסיר"
        },
        {
            "_id": 11670,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 349,
            "שם_רחוב": "מוסאעב בין עומיר"
        },
        {
            "_id": 11671,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 350,
            "שם_רחוב": "מועד בין ג'בל"
        },
        {
            "_id": 11672,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 344,
            "שם_רחוב": "מחמד מתולי א-שעראוי"
        },
        {
            "_id": 11673,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 351,
            "שם_רחוב": "מערוף א-רסאפי"
        },
        {
            "_id": 11674,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 352,
            "שם_רחוב": "מר'רת דיאב"
        },
        {
            "_id": 11675,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 346,
            "שם_רחוב": "מרג' איבן עמאר"
        },
        {
            "_id": 11676,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 347,
            "שם_רחוב": "מרים"
        },
        {
            "_id": 11677,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 356,
            "שם_רחוב": "נוח"
        },
        {
            "_id": 11678,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 294,
            "שם_רחוב": "סאחת עזיזה"
        },
        {
            "_id": 11679,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 295,
            "שם_רחוב": "סאמראא"
        },
        {
            "_id": 11680,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 307,
            "שם_רחוב": "סוהיב בין סנאן"
        },
        {
            "_id": 11681,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 306,
            "שם_רחוב": "סלאח א-דין"
        },
        {
            "_id": 11682,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 304,
            "שם_רחוב": "סלימאן"
        },
        {
            "_id": 11683,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 303,
            "שם_רחוב": "סלמאן אל-פארסי"
        },
        {
            "_id": 11684,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 296,
            "שם_רחוב": "סעד בין אבי וקאס"
        },
        {
            "_id": 11685,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 298,
            "שם_רחוב": "סעד בין מועאד"
        },
        {
            "_id": 11686,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 297,
            "שם_רחוב": "סעד בין עבאדה"
        },
        {
            "_id": 11687,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 299,
            "שם_רחוב": "סעיד בין אל-מסיב"
        },
        {
            "_id": 11688,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 300,
            "שם_רחוב": "סעיד בין ג'וביר"
        },
        {
            "_id": 11689,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 301,
            "שם_רחוב": "סעיד בין זיד"
        },
        {
            "_id": 11690,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 302,
            "שם_רחוב": "סעיד בין עאמר"
        },
        {
            "_id": 11691,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 313,
            "שם_רחוב": "עבאס מחמוד אל-עקאד"
        },
        {
            "_id": 11692,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 314,
            "שם_רחוב": "עבד א-רחמן בין עוף"
        },
        {
            "_id": 11693,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 363,
            "שם_רחוב": "עבד אל-ראוף שרקיה"
        },
        {
            "_id": 11694,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 318,
            "שם_רחוב": "עבד אללה בין עומר"
        },
        {
            "_id": 11695,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 316,
            "שם_רחוב": "עבד אללה בין רואחה"
        },
        {
            "_id": 11696,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 321,
            "שם_רחוב": "עדן"
        },
        {
            "_id": 11697,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 332,
            "שם_רחוב": "עומיר בין סעד"
        },
        {
            "_id": 11698,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 329,
            "שם_רחוב": "עומר בין אלח'טאב"
        },
        {
            "_id": 11699,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 330,
            "שם_רחוב": "עומר בן עבד אל-עזיז"
        },
        {
            "_id": 11700,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 325,
            "שם_רחוב": "עוקבה בן נאפע"
        },
        {
            "_id": 11701,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 320,
            "שם_רחוב": "עות'מאן בין מוד'עין"
        },
        {
            "_id": 11702,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 319,
            "שם_רחוב": "עות'מאן בין עפאן"
        },
        {
            "_id": 11703,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 334,
            "שם_רחוב": "עין חריז"
        },
        {
            "_id": 11704,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 333,
            "שם_רחוב": "עיסא"
        },
        {
            "_id": 11705,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 327,
            "שם_רחוב": "עלי בין אבי טאלב"
        },
        {
            "_id": 11706,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 328,
            "שם_רחוב": "עמאר בין יאסר"
        },
        {
            "_id": 11707,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 331,
            "שם_רחוב": "עמרו בין אל-עאס'"
        },
        {
            "_id": 11708,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 324,
            "שם_רחוב": "עסא אלראעי"
        },
        {
            "_id": 11709,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 323,
            "שם_רחוב": "עסקלאן"
        },
        {
            "_id": 11710,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 326,
            "שם_רחוב": "עקודת א-דרויש"
        },
        {
            "_id": 11711,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 336,
            "שם_רחוב": "פאטמה א-זהראא"
        },
        {
            "_id": 11712,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 335,
            "שם_רחוב": "פארוק אל-באז"
        },
        {
            "_id": 11713,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 338,
            "שם_רחוב": "קורטובה"
        },
        {
            "_id": 11714,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 287,
            "שם_רחוב": "ראבעה אל-עדויה"
        },
        {
            "_id": 11715,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 288,
            "שם_רחוב": "ראס אל-פאיז"
        },
        {
            "_id": 11716,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 289,
            "שם_רחוב": "ראס חוסין"
        },
        {
            "_id": 11717,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 290,
            "שם_רחוב": "רג'ב"
        },
        {
            "_id": 11718,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רח 10"
        },
        {
            "_id": 11719,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רח 20"
        },
        {
            "_id": 11720,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 101,
            "שם_רחוב": "רח 1"
        },
        {
            "_id": 11721,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רח 11"
        },
        {
            "_id": 11722,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רח 2"
        },
        {
            "_id": 11723,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רח 12"
        },
        {
            "_id": 11724,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רח 3"
        },
        {
            "_id": 11725,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רח 13"
        },
        {
            "_id": 11726,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח 4"
        },
        {
            "_id": 11727,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רח 14"
        },
        {
            "_id": 11728,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רח 5"
        },
        {
            "_id": 11729,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח 15"
        },
        {
            "_id": 11730,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רח 6"
        },
        {
            "_id": 11731,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רח 16"
        },
        {
            "_id": 11732,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רח 7"
        },
        {
            "_id": 11733,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רח 17"
        },
        {
            "_id": 11734,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רח 8"
        },
        {
            "_id": 11735,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח 18"
        },
        {
            "_id": 11736,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 109,
            "שם_רחוב": "רח 9"
        },
        {
            "_id": 11737,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח 19"
        },
        {
            "_id": 11738,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש ימה"
        },
        {
            "_id": 11739,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכון א"
        },
        {
            "_id": 11740,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכון ב"
        },
        {
            "_id": 11741,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכון ג"
        },
        {
            "_id": 11742,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכון ד"
        },
        {
            "_id": 11743,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6781,
            "שם_רחוב": "שכונה מערבית"
        },
        {
            "_id": 11744,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6780,
            "שם_רחוב": "שכונה מערבית א"
        },
        {
            "_id": 11745,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6771,
            "שם_רחוב": "שכונה צפונית א"
        },
        {
            "_id": 11746,
            "סמל_ישוב": 628,
            "שם_ישוב": "ג'ת ",
            "סמל_רחוב": 6770,
            "שם_רחוב": "שכונה צפונית ב"
        },
        {
            "_id": 11747,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גאולי תימן"
        },
        {
            "_id": 11748,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גאולי תימן(שיכון)"
        },
        {
            "_id": 11749,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 11750,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 11751,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 11752,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 11753,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 11754,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 11755,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 11756,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 11757,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 11758,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 11759,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 11760,
            "סמל_ישוב": 872,
            "שם_ישוב": "גאולי תימן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 11761,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אעלה בתמר"
        },
        {
            "_id": 11762,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גאולים"
        },
        {
            "_id": 11763,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דרך השדות"
        },
        {
            "_id": 11764,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 11765,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "האילן"
        },
        {
            "_id": 11766,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 11767,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 11768,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 11769,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 11770,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ההרחבה"
        },
        {
            "_id": 11771,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ההשלמה"
        },
        {
            "_id": 11772,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הותיקים"
        },
        {
            "_id": 11773,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 11774,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השמיניה"
        },
        {
            "_id": 11775,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 11776,
            "סמל_ישוב": 379,
            "שם_ישוב": "גאולים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ככר הזיתים"
        },
        {
            "_id": 11777,
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 11778,
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 11779,
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 11780,
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 11781,
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גאליה"
        },
        {
            "_id": 11782,
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 11783,
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 11784,
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 11785,
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 11786,
            "סמל_ישוב": 853,
            "שם_ישוב": "גאליה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 11787,
            "סמל_ישוב": 352,
            "שם_ישוב": "גבולות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבולות"
        },
        {
            "_id": 11788,
            "סמל_ישוב": 424,
            "שם_ישוב": "גבים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבים"
        },
        {
            "_id": 11789,
            "סמל_ישוב": 86,
            "שם_ישוב": "גבע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבע"
        },
        {
            "_id": 11790,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 11791,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 11792,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 11793,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 117,
            "שם_רחוב": "בשמת"
        },
        {
            "_id": 11794,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבע בנימין"
        },
        {
            "_id": 11795,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 157,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 11796,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 159,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 11797,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 160,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 11798,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 155,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 11799,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 158,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 11800,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 154,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 11801,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 150,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 11802,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 123,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 11803,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 124,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 11804,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 11805,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 161,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 11806,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הציפורן"
        },
        {
            "_id": 11807,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הרדוף הנחלים"
        },
        {
            "_id": 11808,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 11809,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 156,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 11810,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 151,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 11811,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 152,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 11812,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 116,
            "שם_רחוב": "ורד"
        },
        {
            "_id": 11813,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 113,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 11814,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 101,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 11815,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 102,
            "שם_רחוב": "יקינתון"
        },
        {
            "_id": 11816,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 105,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 11817,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 107,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 11818,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 118,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 11819,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 110,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 11820,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נופר"
        },
        {
            "_id": 11821,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 121,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 11822,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 104,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 11823,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סיגלית"
        },
        {
            "_id": 11824,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 122,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 11825,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 111,
            "שם_רחוב": "פרג"
        },
        {
            "_id": 11826,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 126,
            "שם_רחוב": "רוזמרין"
        },
        {
            "_id": 11827,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 109,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 11828,
            "סמל_ישוב": 3763,
            "שם_ישוב": "גבע בנימין ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שד אדם"
        },
        {
            "_id": 11829,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבע כרמל"
        },
        {
            "_id": 11830,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך האורן"
        },
        {
            "_id": 11831,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך האילנות"
        },
        {
            "_id": 11832,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך האלון"
        },
        {
            "_id": 11833,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך האשל"
        },
        {
            "_id": 11834,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך הדקל"
        },
        {
            "_id": 11835,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך הדרים"
        },
        {
            "_id": 11836,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך הערבה"
        },
        {
            "_id": 11837,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דרך הצאלון"
        },
        {
            "_id": 11838,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דרך הרימון"
        },
        {
            "_id": 11839,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "משעול האגוז"
        },
        {
            "_id": 11840,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "משעול האשכולית"
        },
        {
            "_id": 11841,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "משעול האתרוג"
        },
        {
            "_id": 11842,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "משעול הבוסתן"
        },
        {
            "_id": 11843,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "משעול הפרדס"
        },
        {
            "_id": 11844,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "משעול השקד"
        },
        {
            "_id": 11845,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "משעול התמר"
        },
        {
            "_id": 11846,
            "סמל_ישוב": 683,
            "שם_ישוב": "גבע כרמל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "משעול התפוז"
        },
        {
            "_id": 11847,
            "סמל_ישוב": 2014,
            "שם_ישוב": "גבעולים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעולים"
        },
        {
            "_id": 11848,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 11849,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אילן"
        },
        {
            "_id": 11850,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אפרסמון"
        },
        {
            "_id": 11851,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעון החדשה"
        },
        {
            "_id": 11852,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 11853,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 11854,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 11855,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 11856,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 11857,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 11858,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 11859,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 11860,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 11861,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "זית"
        },
        {
            "_id": 11862,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 11863,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 11864,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מבוא האלה"
        },
        {
            "_id": 11865,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "מבוא הארז"
        },
        {
            "_id": 11866,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "מבוא האשל"
        },
        {
            "_id": 11867,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "מבוא האתרוג"
        },
        {
            "_id": 11868,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מבוא הברוש"
        },
        {
            "_id": 11869,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מבוא הדקל"
        },
        {
            "_id": 11870,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "מבוא הורד"
        },
        {
            "_id": 11871,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מבוא הלבנה"
        },
        {
            "_id": 11872,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "מבוא המילה"
        },
        {
            "_id": 11873,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "מבוא הערבה"
        },
        {
            "_id": 11874,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "מבוא הפטל"
        },
        {
            "_id": 11875,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "מבוא הצבר"
        },
        {
            "_id": 11876,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "מבוא הצפצפה"
        },
        {
            "_id": 11877,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "מבוא השיזף"
        },
        {
            "_id": 11878,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "מבוא השיטה"
        },
        {
            "_id": 11879,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "מבוא השקד"
        },
        {
            "_id": 11880,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "מבוא השקמה"
        },
        {
            "_id": 11881,
            "סמל_ישוב": 3644,
            "שם_ישוב": "גבעון החדשה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "מבוא התומר"
        },
        {
            "_id": 11882,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעות בר"
        },
        {
            "_id": 11883,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 11884,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 11885,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 11886,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "ורד"
        },
        {
            "_id": 11887,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "זוטה"
        },
        {
            "_id": 11888,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 11889,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "יקינתון"
        },
        {
            "_id": 11890,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 11891,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "משעול הנחל"
        },
        {
            "_id": 11892,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "משעול חלמונית"
        },
        {
            "_id": 11893,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "משעול חמנית"
        },
        {
            "_id": 11894,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "משעול חצב"
        },
        {
            "_id": 11895,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "משעול טיון"
        },
        {
            "_id": 11896,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "משעול יערה"
        },
        {
            "_id": 11897,
            "סמל_ישוב": 1344,
            "שם_ישוב": "גבעות בר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שדרות אלון"
        },
        {
            "_id": 11898,
            "סמל_ישוב": 3862,
            "שם_ישוב": "גבעות הרואה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעות הרואה"
        },
        {
            "_id": 11899,
            "סמל_ישוב": 1362,
            "שם_ישוב": "גבעות עדן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעות עדן"
        },
        {
            "_id": 11900,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 11901,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת אבני"
        },
        {
            "_id": 11902,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך חיים טופול"
        },
        {
            "_id": 11903,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 11904,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האירוסים"
        },
        {
            "_id": 11905,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 11906,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הדקלים"
        },
        {
            "_id": 11907,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 108,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 11908,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 11909,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הסביונים"
        },
        {
            "_id": 11910,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 109,
            "שם_רחוב": "כלניות"
        },
        {
            "_id": 11911,
            "סמל_ישוב": 1293,
            "שם_ישוב": "גבעת אבני ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מעלה הארזים"
        },
        {
            "_id": 11912,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אילון"
        },
        {
            "_id": 11913,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלונים"
        },
        {
            "_id": 11914,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "בית אורן"
        },
        {
            "_id": 11915,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת אלה"
        },
        {
            "_id": 11916,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "גנוסר"
        },
        {
            "_id": 11917,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 11918,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דן"
        },
        {
            "_id": 11919,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 11920,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 11921,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הגשר"
        },
        {
            "_id": 11922,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הזורעים"
        },
        {
            "_id": 11923,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 11924,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 11925,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 11926,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "חניתה"
        },
        {
            "_id": 11927,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מחניים"
        },
        {
            "_id": 11928,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "מסדה"
        },
        {
            "_id": 11929,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "מעיין צבי"
        },
        {
            "_id": 11930,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "נווה איתן"
        },
        {
            "_id": 11931,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "עין גב"
        },
        {
            "_id": 11932,
            "סמל_ישוב": 1288,
            "שם_ישוב": "גבעת אלה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "שער הגולן"
        },
        {
            "_id": 11933,
            "סמל_ישוב": 147,
            "שם_ישוב": "גבעת ברנר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת ברנר"
        },
        {
            "_id": 11934,
            "סמל_ישוב": 147,
            "שם_ישוב": "גבעת ברנר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 11935,
            "סמל_ישוב": 147,
            "שם_ישוב": "גבעת ברנר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 11936,
            "סמל_ישוב": 147,
            "שם_ישוב": "גבעת ברנר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 11937,
            "סמל_ישוב": 147,
            "שם_ישוב": "גבעת ברנר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 11938,
            "סמל_ישוב": 147,
            "שם_ישוב": "גבעת ברנר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 11939,
            "סמל_ישוב": 147,
            "שם_ישוב": "גבעת ברנר ",
            "סמל_רחוב": 100,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 11940,
            "סמל_ישוב": 147,
            "שם_ישוב": "גבעת ברנר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 11941,
            "סמל_ישוב": 147,
            "שם_ישוב": "גבעת ברנר ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ יחד"
        },
        {
            "_id": 11942,
            "סמל_ישוב": 870,
            "שם_ישוב": "גבעת השלושה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת השלושה"
        },
        {
            "_id": 11943,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 350,
            "שם_רחוב": "אבני החושן"
        },
        {
            "_id": 11944,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "אגן האיילות"
        },
        {
            "_id": 11945,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אדמונית"
        },
        {
            "_id": 11946,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 353,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 11947,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 355,
            "שם_רחוב": "אחלמה"
        },
        {
            "_id": 11948,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 317,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 11949,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 327,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 11950,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 328,
            "שם_רחוב": "ארבעת המינים"
        },
        {
            "_id": 11951,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 11952,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 407,
            "שם_רחוב": "בארות"
        },
        {
            "_id": 11953,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 204,
            "שם_רחוב": "בן נון יהושע"
        },
        {
            "_id": 11954,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 131,
            "שם_רחוב": "בנה ביתך"
        },
        {
            "_id": 11955,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 123,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 11956,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 356,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 11957,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 402,
            "שם_רחוב": "גבע"
        },
        {
            "_id": 11958,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "גבעון"
        },
        {
            "_id": 11959,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת זאב"
        },
        {
            "_id": 11960,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 121,
            "שם_רחוב": "גור אריה"
        },
        {
            "_id": 11961,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 122,
            "שם_רחוב": "גן הוורדים"
        },
        {
            "_id": 11962,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 261,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 11963,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 262,
            "שם_רחוב": "דרגות"
        },
        {
            "_id": 11964,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 264,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 11965,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 330,
            "שם_רחוב": "האיילות"
        },
        {
            "_id": 11966,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 343,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 11967,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 11968,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 331,
            "שם_רחוב": "הגדי"
        },
        {
            "_id": 11969,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 301,
            "שם_רחוב": "הגיא"
        },
        {
            "_id": 11970,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הדודאים"
        },
        {
            "_id": 11971,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 332,
            "שם_רחוב": "הדישון"
        },
        {
            "_id": 11972,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 345,
            "שם_רחוב": "הזאב"
        },
        {
            "_id": 11973,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 11974,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 416,
            "שם_רחוב": "החליל"
        },
        {
            "_id": 11975,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 333,
            "שם_רחוב": "היחמור"
        },
        {
            "_id": 11976,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 334,
            "שם_רחוב": "היעל"
        },
        {
            "_id": 11977,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 417,
            "שם_רחוב": "הכינור"
        },
        {
            "_id": 11978,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 335,
            "שם_רחוב": "הכפיר"
        },
        {
            "_id": 11979,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 344,
            "שם_רחוב": "הלביא"
        },
        {
            "_id": 11980,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 11981,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 419,
            "שם_רחוב": "הנבל"
        },
        {
            "_id": 11982,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 336,
            "שם_רחוב": "העופר"
        },
        {
            "_id": 11983,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 337,
            "שם_רחוב": "הצבי"
        },
        {
            "_id": 11984,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הר הארבל"
        },
        {
            "_id": 11985,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הר המוריה"
        },
        {
            "_id": 11986,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 7002,
            "שם_רחוב": "הר שמואל"
        },
        {
            "_id": 11987,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 338,
            "שם_רחוב": "הראם"
        },
        {
            "_id": 11988,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 11989,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 418,
            "שם_רחוב": "השופר"
        },
        {
            "_id": 11990,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 114,
            "שם_רחוב": "התומר"
        },
        {
            "_id": 11991,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 339,
            "שם_רחוב": "התן"
        },
        {
            "_id": 11992,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 257,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 11993,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 253,
            "שם_רחוב": "חבר"
        },
        {
            "_id": 11994,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 340,
            "שם_רחוב": "חי- בר"
        },
        {
            "_id": 11995,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 326,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 11996,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 341,
            "שם_רחוב": "חתול הבר"
        },
        {
            "_id": 11997,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 351,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 11998,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 259,
            "שם_רחוב": "ייטב"
        },
        {
            "_id": 11999,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 12000,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 254,
            "שם_רחוב": "יעלים"
        },
        {
            "_id": 12001,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 302,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 12002,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 313,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 12003,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 411,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 12004,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 109,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 12005,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 306,
            "שם_רחוב": "לולב"
        },
        {
            "_id": 12006,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 357,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 12007,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מבוא הגפן"
        },
        {
            "_id": 12008,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 305,
            "שם_רחוב": "מבוא הדס"
        },
        {
            "_id": 12009,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 117,
            "שם_רחוב": "מבוא הזיתים"
        },
        {
            "_id": 12010,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 107,
            "שם_רחוב": "מבוא השמש"
        },
        {
            "_id": 12011,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מבוא השקמה"
        },
        {
            "_id": 12012,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מבוא תמר"
        },
        {
            "_id": 12013,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "מורשת בנימין"
        },
        {
            "_id": 12014,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 404,
            "שם_רחוב": "מכמש"
        },
        {
            "_id": 12015,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 408,
            "שם_רחוב": "מצפה"
        },
        {
            "_id": 12016,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 310,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 12017,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "מרכז הישוב"
        },
        {
            "_id": 12018,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 409,
            "שם_רחוב": "משעול צוקים"
        },
        {
            "_id": 12019,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "נווה מנחם"
        },
        {
            "_id": 12020,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 358,
            "שם_רחוב": "נופך"
        },
        {
            "_id": 12021,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 256,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 12022,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 319,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 12023,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 124,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 12024,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 252,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 12025,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 360,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 12026,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 405,
            "שם_רחוב": "עלמון"
        },
        {
            "_id": 12027,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 202,
            "שם_רחוב": "עמק איילון"
        },
        {
            "_id": 12028,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 412,
            "שם_רחוב": "ענתות"
        },
        {
            "_id": 12029,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 410,
            "שם_רחוב": "עפרה"
        },
        {
            "_id": 12030,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 263,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 12031,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 304,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 12032,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 251,
            "שם_רחוב": "ערוגות"
        },
        {
            "_id": 12033,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 359,
            "שם_רחוב": "פטדה"
        },
        {
            "_id": 12034,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 325,
            "שם_רחוב": "פרחי הבר"
        },
        {
            "_id": 12035,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 260,
            "שם_רחוב": "פרת"
        },
        {
            "_id": 12036,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 250,
            "שם_רחוב": "צוקית"
        },
        {
            "_id": 12037,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 255,
            "שם_רחוב": "קדרון"
        },
        {
            "_id": 12038,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 406,
            "שם_רחוב": "קרית יערים"
        },
        {
            "_id": 12039,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 12040,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 415,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 12041,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 403,
            "שם_רחוב": "רמה"
        },
        {
            "_id": 12042,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 312,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 12043,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 203,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 12044,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 201,
            "שם_רחוב": "שבט בנימין"
        },
        {
            "_id": 12045,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 352,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 12046,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 342,
            "שם_רחוב": "שור הבר"
        },
        {
            "_id": 12047,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 115,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 12048,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ הדר הגבעה"
        },
        {
            "_id": 12049,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ יהודה ויהודית"
        },
        {
            "_id": 12050,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 12051,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 12052,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 116,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 12053,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 258,
            "שם_רחוב": "תור"
        },
        {
            "_id": 12054,
            "סמל_ישוב": 3730,
            "שם_ישוב": "גבעת זאב ",
            "סמל_רחוב": 354,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 12055,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ברכת עם"
        },
        {
            "_id": 12056,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת חן"
        },
        {
            "_id": 12057,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 116,
            "שם_רחוב": "מעבר לים"
        },
        {
            "_id": 12058,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סמטת בשורה"
        },
        {
            "_id": 12059,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "סמטת חמוטל"
        },
        {
            "_id": 12060,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "סמטת ילדות"
        },
        {
            "_id": 12061,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סמטת צפרירים"
        },
        {
            "_id": 12062,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 115,
            "שם_רחוב": "פלוני יש לו"
        },
        {
            "_id": 12063,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "פעמי אביב"
        },
        {
            "_id": 12064,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שירתי"
        },
        {
            "_id": 12065,
            "סמל_ישוב": 207,
            "שם_ישוב": "גבעת חן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "תרזה יפה"
        },
        {
            "_id": 12066,
            "סמל_ישוב": 2018,
            "שם_ישוב": "גבעת חיים (איחוד) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת חיים (איחוד)"
        },
        {
            "_id": 12067,
            "סמל_ישוב": 173,
            "שם_ישוב": "גבעת חיים (מאוחד) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת חיים (מאוחד)"
        },
        {
            "_id": 12068,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 126,
            "שם_רחוב": "ביר א-שגום"
        },
        {
            "_id": 12069,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 111,
            "שם_רחוב": "בית הבד"
        },
        {
            "_id": 12070,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת יואב"
        },
        {
            "_id": 12071,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך החלב"
        },
        {
            "_id": 12072,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך המחרשה"
        },
        {
            "_id": 12073,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 118,
            "שם_רחוב": "דרך תמר"
        },
        {
            "_id": 12074,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האסם"
        },
        {
            "_id": 12075,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הבוקרים"
        },
        {
            "_id": 12076,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 12077,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 12078,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החרמש"
        },
        {
            "_id": 12079,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 117,
            "שם_רחוב": "היוגב"
        },
        {
            "_id": 12080,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 108,
            "שם_רחוב": "המגל"
        },
        {
            "_id": 12081,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 112,
            "שם_רחוב": "המוסקים"
        },
        {
            "_id": 12082,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "המורג"
        },
        {
            "_id": 12083,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 120,
            "שם_רחוב": "המושבוץ"
        },
        {
            "_id": 12084,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 119,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 12085,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הנוקד"
        },
        {
            "_id": 12086,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 12087,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "השדות"
        },
        {
            "_id": 12088,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 122,
            "שם_רחוב": "התצפית"
        },
        {
            "_id": 12089,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 113,
            "שם_רחוב": "יצהר"
        },
        {
            "_id": 12090,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 123,
            "שם_רחוב": "כיכר הדורות"
        },
        {
            "_id": 12091,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מגדים"
        },
        {
            "_id": 12092,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 115,
            "שם_רחוב": "קמה"
        },
        {
            "_id": 12093,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שדמה"
        },
        {
            "_id": 12094,
            "סמל_ישוב": 4021,
            "שם_ישוב": "גבעת יואב ",
            "סמל_רחוב": 125,
            "שם_רחוב": "שדרת ההתיישבות"
        },
        {
            "_id": 12095,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "איילת חן"
        },
        {
            "_id": 12096,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אמלל שיר"
        },
        {
            "_id": 12097,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת יערים"
        },
        {
            "_id": 12098,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך שבזי שלום"
        },
        {
            "_id": 12099,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "היושבת בגנים"
        },
        {
            "_id": 12100,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הקדרה"
        },
        {
            "_id": 12101,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הר המור"
        },
        {
            "_id": 12102,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הריקמה"
        },
        {
            "_id": 12103,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "לנר ולבשמים"
        },
        {
            "_id": 12104,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 12105,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "על כנפי נשרים"
        },
        {
            "_id": 12106,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "ענבל"
        },
        {
            "_id": 12107,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "עת דודים"
        },
        {
            "_id": 12108,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "פלח הרימון"
        },
        {
            "_id": 12109,
            "סמל_ישוב": 787,
            "שם_ישוב": "גבעת יערים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "ריח הדס"
        },
        {
            "_id": 12110,
            "סמל_ישוב": 919,
            "שם_ישוב": "גבעת ישעיהו ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת ישעיהו"
        },
        {
            "_id": 12111,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת כח"
        },
        {
            "_id": 12112,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 12113,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 12114,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 112,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 12115,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 12116,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הכנרת"
        },
        {
            "_id": 12117,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 12118,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 12119,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 108,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 12120,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 109,
            "שם_רחוב": "השפלה"
        },
        {
            "_id": 12121,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 113,
            "שם_רחוב": "התיכון"
        },
        {
            "_id": 12122,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 102,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 12123,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 104,
            "שם_רחוב": "סמטת הלבנון"
        },
        {
            "_id": 12124,
            "סמל_ישוב": 802,
            "שם_ישוב": "גבעת כח ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שדרת השלום"
        },
        {
            "_id": 12125,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת נילי"
        },
        {
            "_id": 12126,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 100,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 12127,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הדוכיפת"
        },
        {
            "_id": 12128,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 12129,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הנקר"
        },
        {
            "_id": 12130,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הסנונית"
        },
        {
            "_id": 12131,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 105,
            "שם_רחוב": "העפרוני"
        },
        {
            "_id": 12132,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הצופית"
        },
        {
            "_id": 12133,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 107,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 12134,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 108,
            "שם_רחוב": "השלדג"
        },
        {
            "_id": 12135,
            "סמל_ישוב": 360,
            "שם_ישוב": "גבעת נילי ",
            "סמל_רחוב": 109,
            "שם_רחוב": "התור"
        },
        {
            "_id": 12136,
            "סמל_ישוב": 703,
            "שם_ישוב": "גבעת עוז ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת עוז"
        },
        {
            "_id": 12137,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אהרון"
        },
        {
            "_id": 12138,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אורי צבי גרינברג"
        },
        {
            "_id": 12139,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אורנים"
        },
        {
            "_id": 12140,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אלונים"
        },
        {
            "_id": 12141,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלעזר דוד"
        },
        {
            "_id": 12142,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אפרים"
        },
        {
            "_id": 12143,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 153,
            "שם_רחוב": "ארזים"
        },
        {
            "_id": 12144,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 12145,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 12146,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 12147,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 220,
            "שם_רחוב": "בגין מנחם"
        },
        {
            "_id": 12148,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 12149,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 162,
            "שם_רחוב": "בן גוריון"
        },
        {
            "_id": 12150,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 146,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 12151,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 125,
            "שם_רחוב": "בר אילן"
        },
        {
            "_id": 12152,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 142,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 12153,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 225,
            "שם_רחוב": "ברלב חיים"
        },
        {
            "_id": 12154,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ברנשטיין-כהן"
        },
        {
            "_id": 12155,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת שמואל"
        },
        {
            "_id": 12156,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 239,
            "שם_רחוב": "גולדה מאיר"
        },
        {
            "_id": 12157,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "גוש עציון"
        },
        {
            "_id": 12158,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 229,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 12159,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 234,
            "שם_רחוב": "דרך המלך"
        },
        {
            "_id": 12160,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 237,
            "שם_רחוב": "דרך יצחק רבין"
        },
        {
            "_id": 12161,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 12162,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 230,
            "שם_רחוב": "הולצברג שמחה"
        },
        {
            "_id": 12163,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הזיתים"
        },
        {
            "_id": 12164,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הללס שלמה"
        },
        {
            "_id": 12165,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 150,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 12166,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 227,
            "שם_רחוב": "המר זבולון"
        },
        {
            "_id": 12167,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 170,
            "שם_רחוב": "הנשיא"
        },
        {
            "_id": 12168,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הסיבים"
        },
        {
            "_id": 12169,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 12170,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 12171,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 12172,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 161,
            "שם_רחוב": "הציונות"
        },
        {
            "_id": 12173,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 240,
            "שם_רחוב": "הרב יהושע יגל"
        },
        {
            "_id": 12174,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 169,
            "שם_רחוב": "הרב סולם משה"
        },
        {
            "_id": 12175,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 138,
            "שם_רחוב": "הרב שלמה גורן"
        },
        {
            "_id": 12176,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 204,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 12177,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 143,
            "שם_רחוב": "הרצוג"
        },
        {
            "_id": 12178,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 12179,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 157,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 12180,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 202,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 12181,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 156,
            "שם_רחוב": "התומר"
        },
        {
            "_id": 12182,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 203,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 12183,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 210,
            "שם_רחוב": "ויסמונסקי"
        },
        {
            "_id": 12184,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 12185,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 127,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 12186,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 147,
            "שם_רחוב": "זאבי רחבעם(גנדי)"
        },
        {
            "_id": 12187,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 206,
            "שם_רחוב": "חיטמן עוזי"
        },
        {
            "_id": 12188,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 141,
            "שם_רחוב": "טירן"
        },
        {
            "_id": 12189,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 131,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 12190,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 12191,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 226,
            "שם_רחוב": "יוני נתניהו"
        },
        {
            "_id": 12192,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 238,
            "שם_רחוב": "יצחק שמיר"
        },
        {
            "_id": 12193,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 208,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 12194,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 155,
            "שם_רחוב": "ישיבת בני עקיבא"
        },
        {
            "_id": 12195,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "ככר אורן"
        },
        {
            "_id": 12196,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר אשלים"
        },
        {
            "_id": 12197,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "ככר דן"
        },
        {
            "_id": 12198,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר הבשור"
        },
        {
            "_id": 12199,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר ירדן"
        },
        {
            "_id": 12200,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר פיכמן"
        },
        {
            "_id": 12201,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "ככר שניר"
        },
        {
            "_id": 12202,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר תבור"
        },
        {
            "_id": 12203,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "לוחמי הגטאות"
        },
        {
            "_id": 12204,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 231,
            "שם_רחוב": "לוי אשכול"
        },
        {
            "_id": 12205,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 172,
            "שם_רחוב": "ליפקין שחק אמנון"
        },
        {
            "_id": 12206,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 232,
            "שם_רחוב": "לנדאו חיים"
        },
        {
            "_id": 12207,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 144,
            "שם_רחוב": "מבצע קדש"
        },
        {
            "_id": 12208,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 228,
            "שם_רחוב": "מודעי יצחק"
        },
        {
            "_id": 12209,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 221,
            "שם_רחוב": "מוטה גור"
        },
        {
            "_id": 12210,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 135,
            "שם_רחוב": "מעון אקדמאים"
        },
        {
            "_id": 12211,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "נחום"
        },
        {
            "_id": 12212,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "נימרובר"
        },
        {
            "_id": 12213,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 128,
            "שם_רחוב": "סירני"
        },
        {
            "_id": 12214,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 236,
            "שם_רחוב": "סמ בוני אברהם"
        },
        {
            "_id": 12215,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 119,
            "שם_רחוב": "סמ הרב קוק"
        },
        {
            "_id": 12216,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 235,
            "שם_רחוב": "סמ זינגר יהודה"
        },
        {
            "_id": 12217,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 137,
            "שם_רחוב": "סמ יעקב"
        },
        {
            "_id": 12218,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 168,
            "שם_רחוב": "סמ נח"
        },
        {
            "_id": 12219,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 165,
            "שם_רחוב": "סמ נחום"
        },
        {
            "_id": 12220,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 171,
            "שם_רחוב": "סמ קורדובה"
        },
        {
            "_id": 12221,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 167,
            "שם_רחוב": "סמ רבקה"
        },
        {
            "_id": 12222,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 151,
            "שם_רחוב": "סמ רחל"
        },
        {
            "_id": 12223,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 129,
            "שם_רחוב": "סמטת הדס"
        },
        {
            "_id": 12224,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 12225,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 233,
            "שם_רחוב": "עגנון שי"
        },
        {
            "_id": 12226,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 136,
            "שם_רחוב": "עוזיאל"
        },
        {
            "_id": 12227,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 223,
            "שם_רחוב": "פיקסלר צבי"
        },
        {
            "_id": 12228,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 207,
            "שם_רחוב": "פנינה קליין"
        },
        {
            "_id": 12229,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 130,
            "שם_רחוב": "פרץ"
        },
        {
            "_id": 12230,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 148,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 12231,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 120,
            "שם_רחוב": "קרפל ליפא"
        },
        {
            "_id": 12232,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 219,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 12233,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 154,
            "שם_רחוב": "רחבת אילן"
        },
        {
            "_id": 12234,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 166,
            "שם_רחוב": "רחבת קרן היסוד"
        },
        {
            "_id": 12235,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 134,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 12236,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "רמת אילן"
        },
        {
            "_id": 12237,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "רמת הדקלים"
        },
        {
            "_id": 12238,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "רמת הדר"
        },
        {
            "_id": 12239,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 224,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 12240,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 126,
            "שם_רחוב": "שד הגבורים"
        },
        {
            "_id": 12241,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שד העצמאות"
        },
        {
            "_id": 12242,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שדה יצחק"
        },
        {
            "_id": 12243,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שטיינברג"
        },
        {
            "_id": 12244,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ גבעת יהודה"
        },
        {
            "_id": 12245,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ גיורא"
        },
        {
            "_id": 12246,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ השחר"
        },
        {
            "_id": 12247,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ מרכז"
        },
        {
            "_id": 12248,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ פרדס רובין"
        },
        {
            "_id": 12249,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ פרדס רוזנבלום"
        },
        {
            "_id": 12250,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ קרית ישראל"
        },
        {
            "_id": 12251,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 205,
            "שם_רחוב": "שמר נעמי"
        },
        {
            "_id": 12252,
            "סמל_ישוב": 681,
            "שם_ישוב": "גבעת שמואל ",
            "סמל_רחוב": 140,
            "שם_רחוב": "שפינדל יונה"
        },
        {
            "_id": 12253,
            "סמל_ישוב": 566,
            "שם_ישוב": "גבעת שמש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת שמש"
        },
        {
            "_id": 12254,
            "סמל_ישוב": 1077,
            "שם_ישוב": "גבעת שפירא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעת שפירא"
        },
        {
            "_id": 12255,
            "סמל_ישוב": 793,
            "שם_ישוב": "גבעתי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעתי"
        },
        {
            "_id": 12256,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 412,
            "שם_רחוב": "אבני זכרון"
        },
        {
            "_id": 12257,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אחדות העבודה"
        },
        {
            "_id": 12258,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 620,
            "שם_רחוב": "אידמית"
        },
        {
            "_id": 12259,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 509,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 12260,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלומות"
        },
        {
            "_id": 12261,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 630,
            "שם_רחוב": "אלוף שדה"
        },
        {
            "_id": 12262,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אפנר"
        },
        {
            "_id": 12263,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 602,
            "שם_רחוב": "אפק"
        },
        {
            "_id": 12264,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 12265,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 518,
            "שם_רחוב": "אריאל"
        },
        {
            "_id": 12266,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אריאל שרון"
        },
        {
            "_id": 12267,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 313,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 12268,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 613,
            "שם_רחוב": "ארנון"
        },
        {
            "_id": 12269,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 12270,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "בלוך"
        },
        {
            "_id": 12271,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 318,
            "שם_רחוב": "בן צבי שמעון"
        },
        {
            "_id": 12272,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "בן ציון ישראלי"
        },
        {
            "_id": 12273,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 222,
            "שם_רחוב": "בראשית"
        },
        {
            "_id": 12274,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 305,
            "שם_רחוב": "ברדיצ'בסקי"
        },
        {
            "_id": 12275,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 220,
            "שם_רחוב": "ברוך"
        },
        {
            "_id": 12276,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 128,
            "שם_רחוב": "גבולות"
        },
        {
            "_id": 12277,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 204,
            "שם_רחוב": "גבע"
        },
        {
            "_id": 12278,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גבעת קוזלובסקי"
        },
        {
            "_id": 12279,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "גבעת רמבם"
        },
        {
            "_id": 12280,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבעתיים"
        },
        {
            "_id": 12281,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "גולדשטיין אהרון"
        },
        {
            "_id": 12282,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 223,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 12283,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 607,
            "שם_רחוב": "גונן"
        },
        {
            "_id": 12284,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 12285,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 402,
            "שם_רחוב": "גוש עציון"
        },
        {
            "_id": 12286,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 131,
            "שם_רחוב": "גזית"
        },
        {
            "_id": 12287,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 209,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 12288,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 505,
            "שם_רחוב": "גנסין"
        },
        {
            "_id": 12289,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 135,
            "שם_רחוב": "דב"
        },
        {
            "_id": 12290,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 409,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 12291,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 216,
            "שם_רחוב": "דן"
        },
        {
            "_id": 12292,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 210,
            "שם_רחוב": "דרך בן גוריון דוד"
        },
        {
            "_id": 12293,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 514,
            "שם_רחוב": "דרך השלום"
        },
        {
            "_id": 12294,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 601,
            "שם_רחוב": "דרך יצחק רבין"
        },
        {
            "_id": 12295,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 12296,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 517,
            "שם_רחוב": "הדגנים"
        },
        {
            "_id": 12297,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 513,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 12298,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 617,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 12299,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 410,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 12300,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 614,
            "שם_רחוב": "הידיד"
        },
        {
            "_id": 12301,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הכנסת"
        },
        {
            "_id": 12302,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 403,
            "שם_רחוב": "הלמד הא"
        },
        {
            "_id": 12303,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 609,
            "שם_רחוב": "המאבק"
        },
        {
            "_id": 12304,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 616,
            "שם_רחוב": "המאור"
        },
        {
            "_id": 12305,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 205,
            "שם_רחוב": "המבוא"
        },
        {
            "_id": 12306,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 507,
            "שם_רחוב": "המגדל"
        },
        {
            "_id": 12307,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "המורד"
        },
        {
            "_id": 12308,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 401,
            "שם_רחוב": "המלבן"
        },
        {
            "_id": 12309,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 126,
            "שם_רחוב": "המעגל"
        },
        {
            "_id": 12310,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "המעורר"
        },
        {
            "_id": 12311,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 312,
            "שם_רחוב": "המעין"
        },
        {
            "_id": 12312,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 211,
            "שם_רחוב": "המעלות"
        },
        {
            "_id": 12313,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 310,
            "שם_רחוב": "המפנה"
        },
        {
            "_id": 12314,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 203,
            "שם_רחוב": "המרי"
        },
        {
            "_id": 12315,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 502,
            "שם_רחוב": "הנגבה"
        },
        {
            "_id": 12316,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 610,
            "שם_רחוב": "הנדיב"
        },
        {
            "_id": 12317,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הפועל הצעיר"
        },
        {
            "_id": 12318,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 411,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 12319,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 506,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 12320,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 213,
            "שם_רחוב": "הראל"
        },
        {
            "_id": 12321,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 619,
            "שם_רחוב": "הרב צימבר"
        },
        {
            "_id": 12322,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 622,
            "שם_רחוב": "הרצוג"
        },
        {
            "_id": 12323,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 12324,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "השלבים"
        },
        {
            "_id": 12325,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "השנים"
        },
        {
            "_id": 12326,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 407,
            "שם_רחוב": "התעש"
        },
        {
            "_id": 12327,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 301,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 12328,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 308,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 12329,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 519,
            "שם_רחוב": "חן"
        },
        {
            "_id": 12330,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "חניתה"
        },
        {
            "_id": 12331,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 634,
            "שם_רחוב": "טבנקין"
        },
        {
            "_id": 12332,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 501,
            "שם_רחוב": "טיבר"
        },
        {
            "_id": 12333,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 625,
            "שם_רחוב": "יבניאלי"
        },
        {
            "_id": 12334,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 511,
            "שם_רחוב": "יד מרדכי"
        },
        {
            "_id": 12335,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 636,
            "שם_רחוב": "ילדי טהרן"
        },
        {
            "_id": 12336,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 612,
            "שם_רחוב": "ילין דוד"
        },
        {
            "_id": 12337,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 125,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 12338,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 132,
            "שם_רחוב": "יצחק"
        },
        {
            "_id": 12339,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 302,
            "שם_רחוב": "יצחק שדה"
        },
        {
            "_id": 12340,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 621,
            "שם_רחוב": "כורזין"
        },
        {
            "_id": 12341,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר נח"
        },
        {
            "_id": 12342,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 306,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 12343,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 218,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 12344,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 608,
            "שם_רחוב": "כף גימל"
        },
        {
            "_id": 12345,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כצנלסון"
        },
        {
            "_id": 12346,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 137,
            "שם_רחוב": "לביא"
        },
        {
            "_id": 12347,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 520,
            "שם_רחוב": "ליא קניג שטולפר"
        },
        {
            "_id": 12348,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 408,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 12349,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 611,
            "שם_רחוב": "מהרשק"
        },
        {
            "_id": 12350,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 138,
            "שם_רחוב": "מורדי הגיטאות"
        },
        {
            "_id": 12351,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 605,
            "שם_רחוב": "מנורה"
        },
        {
            "_id": 12352,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מנרה"
        },
        {
            "_id": 12353,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 219,
            "שם_רחוב": "מסדה"
        },
        {
            "_id": 12354,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 221,
            "שם_רחוב": "מעוז"
        },
        {
            "_id": 12355,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 139,
            "שם_רחוב": "מעלה בועז"
        },
        {
            "_id": 12356,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 319,
            "שם_רחוב": "מעלה יעקב"
        },
        {
            "_id": 12357,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 606,
            "שם_רחוב": "מצולות ים"
        },
        {
            "_id": 12358,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "מרכז עסקים דרום"
        },
        {
            "_id": 12359,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 404,
            "שם_רחוב": "משגב עם"
        },
        {
            "_id": 12360,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 627,
            "שם_רחוב": "משמר הירדן"
        },
        {
            "_id": 12361,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 212,
            "שם_רחוב": "ניצנה"
        },
        {
            "_id": 12362,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 637,
            "שם_רחוב": "נעמת"
        },
        {
            "_id": 12363,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 309,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 12364,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 510,
            "שם_רחוב": "סירני אנצו"
        },
        {
            "_id": 12365,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סירקין"
        },
        {
            "_id": 12366,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "סמ אליהו"
        },
        {
            "_id": 12367,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 208,
            "שם_רחוב": "סמ אסירי ציון"
        },
        {
            "_id": 12368,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 136,
            "שם_רחוב": "סמטת נחל"
        },
        {
            "_id": 12369,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 134,
            "שם_רחוב": "סמטת צור"
        },
        {
            "_id": 12370,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 512,
            "שם_רחוב": "סנש"
        },
        {
            "_id": 12371,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 508,
            "שם_רחוב": "עברונה"
        },
        {
            "_id": 12372,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 207,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 12373,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 406,
            "שם_רחוב": "עלית הנוער"
        },
        {
            "_id": 12374,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 618,
            "שם_רחוב": "עמישב"
        },
        {
            "_id": 12375,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 214,
            "שם_רחוב": "עמל"
        },
        {
            "_id": 12376,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ערבי נחל"
        },
        {
            "_id": 12377,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 413,
            "שם_רחוב": "ערד"
        },
        {
            "_id": 12378,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 127,
            "שם_רחוב": "פועלי הרכבת"
        },
        {
            "_id": 12379,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "פטאי"
        },
        {
            "_id": 12380,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 315,
            "שם_רחוב": "פינברג יעקב"
        },
        {
            "_id": 12381,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 632,
            "שם_רחוב": "צביה"
        },
        {
            "_id": 12382,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 314,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 12383,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 604,
            "שם_רחוב": "צפרירים"
        },
        {
            "_id": 12384,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 631,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 12385,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 320,
            "שם_רחוב": "קושניר"
        },
        {
            "_id": 12386,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "קלעי"
        },
        {
            "_id": 12387,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 624,
            "שם_רחוב": "קפלנסקי"
        },
        {
            "_id": 12388,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "קרית יוסף"
        },
        {
            "_id": 12389,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 633,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 12390,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "קרן קימת לישראל"
        },
        {
            "_id": 12391,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 603,
            "שם_רחוב": "רביבים"
        },
        {
            "_id": 12392,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 626,
            "שם_רחוב": "רודין אלישע"
        },
        {
            "_id": 12393,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 129,
            "שם_רחוב": "רוטברג מאיר"
        },
        {
            "_id": 12394,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 304,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 12395,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 516,
            "שם_רחוב": "רחל"
        },
        {
            "_id": 12396,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 224,
            "שם_רחוב": "ריינס"
        },
        {
            "_id": 12397,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 515,
            "שם_רחוב": "רייק"
        },
        {
            "_id": 12398,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 503,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 12399,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 225,
            "שם_רחוב": "רמה"
        },
        {
            "_id": 12400,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 504,
            "שם_רחוב": "רמז"
        },
        {
            "_id": 12401,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 307,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 12402,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש עובדי קופח"
        },
        {
            "_id": 12403,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש קרת"
        },
        {
            "_id": 12404,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש רוזנשטין"
        },
        {
            "_id": 12405,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש שילר"
        },
        {
            "_id": 12406,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 317,
            "שם_רחוב": "שד אפיקים"
        },
        {
            "_id": 12407,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 311,
            "שם_רחוב": "שדה בוקר"
        },
        {
            "_id": 12408,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שיכוני דרום"
        },
        {
            "_id": 12409,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 628,
            "שם_רחוב": "שילוב"
        },
        {
            "_id": 12410,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 202,
            "שם_רחוב": "שינקין"
        },
        {
            "_id": 12411,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ ארלוזורוב"
        },
        {
            "_id": 12412,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ בורוכוב"
        },
        {
            "_id": 12413,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ עובדי חברת חשמל"
        },
        {
            "_id": 12414,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ פועלי הרכבת"
        },
        {
            "_id": 12415,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ קרית יוסף"
        },
        {
            "_id": 12416,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ שיינקין"
        },
        {
            "_id": 12417,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 615,
            "שם_רחוב": "שפירא ישראל"
        },
        {
            "_id": 12418,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 303,
            "שם_רחוב": "שר אהרן"
        },
        {
            "_id": 12419,
            "סמל_ישוב": 6300,
            "שם_ישוב": "גבעתיים ",
            "סמל_רחוב": 635,
            "שם_רחוב": "תפוצות ישראל"
        },
        {
            "_id": 12420,
            "סמל_ישוב": 342,
            "שם_ישוב": "גברעם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גברעם"
        },
        {
            "_id": 12421,
            "סמל_ישוב": 133,
            "שם_ישוב": "גבת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גבת"
        },
        {
            "_id": 12422,
            "סמל_ישוב": 35,
            "שם_ישוב": "גדות ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 12423,
            "סמל_ישוב": 35,
            "שם_ישוב": "גדות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גדות"
        },
        {
            "_id": 12424,
            "סמל_ישוב": 145,
            "שם_ישוב": "גדיש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גדיש"
        },
        {
            "_id": 12425,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גדעונה"
        },
        {
            "_id": 12426,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 12427,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 12428,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 12429,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 12430,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 12431,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 12432,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 12433,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המעיין"
        },
        {
            "_id": 12434,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 12435,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 12436,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 12437,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 12438,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 12439,
            "סמל_ישוב": 442,
            "שם_ישוב": "גדעונה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 12440,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "אבא אחימאיר"
        },
        {
            "_id": 12441,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אבינדב ירוחם"
        },
        {
            "_id": 12442,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 12443,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "אהובה עוזרי"
        },
        {
            "_id": 12444,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 12445,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 12446,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "אזור תעשיה"
        },
        {
            "_id": 12447,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "אחוזת גדרה"
        },
        {
            "_id": 12448,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 283,
            "שם_רחוב": "אילות"
        },
        {
            "_id": 12449,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 12450,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 12451,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 12452,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 12453,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "אלמוגן"
        },
        {
            "_id": 12454,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אלשייך פינק"
        },
        {
            "_id": 12455,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "אם כל חי"
        },
        {
            "_id": 12456,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 363,
            "שם_רחוב": "אנה פרנק"
        },
        {
            "_id": 12457,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אעלה בתמר"
        },
        {
            "_id": 12458,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אפרים"
        },
        {
            "_id": 12459,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 347,
            "שם_רחוב": "אצג"
        },
        {
            "_id": 12460,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 12461,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 376,
            "שם_רחוב": "ארבע האימהות"
        },
        {
            "_id": 12462,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 12463,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "ארקין צבי"
        },
        {
            "_id": 12464,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "אשחר"
        },
        {
            "_id": 12465,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 12466,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אשר"
        },
        {
            "_id": 12467,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "אתר חפץ חיים"
        },
        {
            "_id": 12468,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 12469,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "ב אבות למושב"
        },
        {
            "_id": 12470,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "בורג יוסף"
        },
        {
            "_id": 12471,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "ביח הרצפלד"
        },
        {
            "_id": 12472,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 12473,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "בן גפן"
        },
        {
            "_id": 12474,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "בן צבי יצחק"
        },
        {
            "_id": 12475,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "בעלי מלאכה"
        },
        {
            "_id": 12476,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 12477,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 12478,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "ג'מיל דוד"
        },
        {
            "_id": 12479,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גדרה"
        },
        {
            "_id": 12480,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "גדרה הצעירה"
        },
        {
            "_id": 12481,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "גומברג שבתאי"
        },
        {
            "_id": 12482,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "דר הסנר הוגו"
        },
        {
            "_id": 12483,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 343,
            "שם_רחוב": "דר פון ויזל"
        },
        {
            "_id": 12484,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 372,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 12485,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 374,
            "שם_רחוב": "דבורה עומר"
        },
        {
            "_id": 12486,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 351,
            "שם_רחוב": "דהרי מנחם"
        },
        {
            "_id": 12487,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 12488,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 12489,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 364,
            "שם_רחוב": "דונה גרציה נשיא"
        },
        {
            "_id": 12490,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "דן"
        },
        {
            "_id": 12491,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דנציגר"
        },
        {
            "_id": 12492,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 12493,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 12494,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 12495,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "דרך ארץ"
        },
        {
            "_id": 12496,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "דרך האילנות"
        },
        {
            "_id": 12497,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 321,
            "שם_רחוב": "דרך הנחלים"
        },
        {
            "_id": 12498,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "דרך הפרחים"
        },
        {
            "_id": 12499,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 284,
            "שם_רחוב": "הארבל"
        },
        {
            "_id": 12500,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "הבילוים"
        },
        {
            "_id": 12501,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "הגא"
        },
        {
            "_id": 12502,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 293,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 12503,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 12504,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 288,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 12505,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 12506,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 12507,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "הדרים"
        },
        {
            "_id": 12508,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 12509,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "הולצברג שמחה"
        },
        {
            "_id": 12510,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "הורביץ"
        },
        {
            "_id": 12511,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 12512,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "החומה"
        },
        {
            "_id": 12513,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 12514,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 12515,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "היקב"
        },
        {
            "_id": 12516,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "הכורמים"
        },
        {
            "_id": 12517,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 12518,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 320,
            "שם_רחוב": "המהריץ"
        },
        {
            "_id": 12519,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 357,
            "שם_רחוב": "המחתרות"
        },
        {
            "_id": 12520,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "המציל היהודי"
        },
        {
            "_id": 12521,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "המר זבולון"
        },
        {
            "_id": 12522,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 281,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 12523,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "הנקין מרדכי"
        },
        {
            "_id": 12524,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "הנשיא"
        },
        {
            "_id": 12525,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "העופרים"
        },
        {
            "_id": 12526,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 12527,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 290,
            "שם_רחוב": "העמקים"
        },
        {
            "_id": 12528,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 12529,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 378,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 12530,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 317,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 12531,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 287,
            "שם_רחוב": "הר מרון"
        },
        {
            "_id": 12532,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הרב גמליאל יוסף"
        },
        {
            "_id": 12533,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הרב צוברי מאיר"
        },
        {
            "_id": 12534,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "הרב שייבה ישראל"
        },
        {
            "_id": 12535,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הרב תנעמי"
        },
        {
            "_id": 12536,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 349,
            "שם_רחוב": "הרב תעיזי יחיא"
        },
        {
            "_id": 12537,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "הרבי מליובאוויטש"
        },
        {
            "_id": 12538,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 296,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 12539,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 295,
            "שם_רחוב": "הרי אדום"
        },
        {
            "_id": 12540,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 278,
            "שם_רחוב": "הרי מואב"
        },
        {
            "_id": 12541,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 12542,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 318,
            "שם_רחוב": "הרמבם"
        },
        {
            "_id": 12543,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "הרצוג חיים"
        },
        {
            "_id": 12544,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 12545,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "הרצפלד"
        },
        {
            "_id": 12546,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 285,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 12547,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 289,
            "שם_רחוב": "השפלה"
        },
        {
            "_id": 12548,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 282,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 12549,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 355,
            "שם_רחוב": "ויינר ישראל"
        },
        {
            "_id": 12550,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 352,
            "שם_רחוב": "וייס ולדר"
        },
        {
            "_id": 12551,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 12552,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 12553,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 350,
            "שם_רחוב": "זאוברמן זאב"
        },
        {
            "_id": 12554,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "זבולון"
        },
        {
            "_id": 12555,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "זיגמן"
        },
        {
            "_id": 12556,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 297,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 12557,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "זרקון"
        },
        {
            "_id": 12558,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "חבקוק"
        },
        {
            "_id": 12559,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "חבקין יצחק"
        },
        {
            "_id": 12560,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "חגי הנביא"
        },
        {
            "_id": 12561,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 299,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 12562,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "חושן"
        },
        {
            "_id": 12563,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "חזית הדרום"
        },
        {
            "_id": 12564,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "חזנוב"
        },
        {
            "_id": 12565,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 392,
            "שם_רחוב": "חטיבת גבעתי"
        },
        {
            "_id": 12566,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 380,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 12567,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 379,
            "שם_רחוב": "חטיבת הנחל"
        },
        {
            "_id": 12568,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 381,
            "שם_רחוב": "חטיבת הצנחנים"
        },
        {
            "_id": 12569,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 388,
            "שם_רחוב": "חיל האוויר"
        },
        {
            "_id": 12570,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 391,
            "שם_רחוב": "חיל ההנדסה"
        },
        {
            "_id": 12571,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 386,
            "שם_רחוב": "חיל החימוש"
        },
        {
            "_id": 12572,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 384,
            "שם_רחוב": "חיל החינוך"
        },
        {
            "_id": 12573,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 389,
            "שם_רחוב": "חיל הים"
        },
        {
            "_id": 12574,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 383,
            "שם_רחוב": "חיל המודיעין"
        },
        {
            "_id": 12575,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 387,
            "שם_רחוב": "חיל הקשר"
        },
        {
            "_id": 12576,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 390,
            "שם_רחוב": "חיל הרפואה"
        },
        {
            "_id": 12577,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 382,
            "שם_רחוב": "חיל השריון"
        },
        {
            "_id": 12578,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 385,
            "שם_רחוב": "חיל התותחנים"
        },
        {
            "_id": 12579,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 360,
            "שם_רחוב": "חנה סנש"
        },
        {
            "_id": 12580,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 12581,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 12582,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "יא חללי מינכן"
        },
        {
            "_id": 12583,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "יגאל אלון"
        },
        {
            "_id": 12584,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 12585,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "יהודה הגדרתי"
        },
        {
            "_id": 12586,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "יהונתן"
        },
        {
            "_id": 12587,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 292,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 12588,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "יואל"
        },
        {
            "_id": 12589,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "יונה הנביא"
        },
        {
            "_id": 12590,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "יזרעאל"
        },
        {
            "_id": 12591,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 12592,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 375,
            "שם_רחוב": "יפה ירקוני"
        },
        {
            "_id": 12593,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 12594,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 12595,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 294,
            "שם_רחוב": "ירדן"
        },
        {
            "_id": 12596,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "ישעיהו ישראל"
        },
        {
            "_id": 12597,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "ישראל אילן הולצמן"
        },
        {
            "_id": 12598,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "יששכר"
        },
        {
            "_id": 12599,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "כהנוב אליעזר"
        },
        {
            "_id": 12600,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "כחלית"
        },
        {
            "_id": 12601,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 12602,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "כנפי נשרים"
        },
        {
            "_id": 12603,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "כפר אוריאל"
        },
        {
            "_id": 12604,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "כפר אליהו"
        },
        {
            "_id": 12605,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "כצנלסון"
        },
        {
            "_id": 12606,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 279,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 12607,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 361,
            "שם_רחוב": "לאה גולדברג"
        },
        {
            "_id": 12608,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "לב גדרה"
        },
        {
            "_id": 12609,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "לב המושבה"
        },
        {
            "_id": 12610,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "לובצקי"
        },
        {
            "_id": 12611,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "לוי"
        },
        {
            "_id": 12612,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "לוינסון"
        },
        {
            "_id": 12613,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "לוע הארי"
        },
        {
            "_id": 12614,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 377,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 12615,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "ליבוביץ"
        },
        {
            "_id": 12616,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 341,
            "שם_רחוב": "ליבנה"
        },
        {
            "_id": 12617,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "לילינבלום"
        },
        {
            "_id": 12618,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 12619,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "לרנר מרדכי"
        },
        {
            "_id": 12620,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 12621,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "מגינים"
        },
        {
            "_id": 12622,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "מוסינזון יגאל"
        },
        {
            "_id": 12623,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "מוצקין"
        },
        {
            "_id": 12624,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "מורי אהרון צברי"
        },
        {
            "_id": 12625,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "מחנה מרים"
        },
        {
            "_id": 12626,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "מלר יוסף צבי"
        },
        {
            "_id": 12627,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 286,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 12628,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 12629,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 12630,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "משה ויהודית רוזנר"
        },
        {
            "_id": 12631,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "משעול הרשש"
        },
        {
            "_id": 12632,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "נאות גדרה"
        },
        {
            "_id": 12633,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "נאות ראשונים"
        },
        {
            "_id": 12634,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "נבט משה"
        },
        {
            "_id": 12635,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 345,
            "שם_רחוב": "נהר שלום"
        },
        {
            "_id": 12636,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "נווה אורנים"
        },
        {
            "_id": 12637,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "נווה שולמית"
        },
        {
            "_id": 12638,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "נווה שלמה"
        },
        {
            "_id": 12639,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 291,
            "שם_רחוב": "נוטמן הנס"
        },
        {
            "_id": 12640,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "נופך"
        },
        {
            "_id": 12641,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 12642,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "נחום"
        },
        {
            "_id": 12643,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 337,
            "שם_רחוב": "נחל איילון"
        },
        {
            "_id": 12644,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 333,
            "שם_רחוב": "נחל ארנון"
        },
        {
            "_id": 12645,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 327,
            "שם_רחוב": "נחל דן"
        },
        {
            "_id": 12646,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 338,
            "שם_רחוב": "נחל הבשור"
        },
        {
            "_id": 12647,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 328,
            "שם_רחוב": "נחל הירמוך"
        },
        {
            "_id": 12648,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 323,
            "שם_רחוב": "נחל הירקון"
        },
        {
            "_id": 12649,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 334,
            "שם_רחוב": "נחל ליטאני"
        },
        {
            "_id": 12650,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 322,
            "שם_רחוב": "נחל לכיש"
        },
        {
            "_id": 12651,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 325,
            "שם_רחוב": "נחל נעמן"
        },
        {
            "_id": 12652,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 336,
            "שם_רחוב": "נחל עדה"
        },
        {
            "_id": 12653,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 324,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 12654,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 329,
            "שם_רחוב": "נחל פארן"
        },
        {
            "_id": 12655,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 335,
            "שם_רחוב": "נחל צאלים"
        },
        {
            "_id": 12656,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 332,
            "שם_רחוב": "נחל קדרון"
        },
        {
            "_id": 12657,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 330,
            "שם_רחוב": "נחל שורק"
        },
        {
            "_id": 12658,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 326,
            "שם_רחוב": "נחל שניר"
        },
        {
            "_id": 12659,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 331,
            "שם_רחוב": "נחל תנינים"
        },
        {
            "_id": 12660,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 369,
            "שם_רחוב": "נחמה ריבלין"
        },
        {
            "_id": 12661,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 358,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 12662,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "נירנברג שניאור"
        },
        {
            "_id": 12663,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "נפתלי"
        },
        {
            "_id": 12664,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 12665,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 12666,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "סברדלוב"
        },
        {
            "_id": 12667,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 348,
            "שם_רחוב": "סופר שלום"
        },
        {
            "_id": 12668,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 12669,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "סטרומה"
        },
        {
            "_id": 12670,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "סיגלביץ שרה"
        },
        {
            "_id": 12671,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "סמ דרך ארץ"
        },
        {
            "_id": 12672,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "סמ הגא"
        },
        {
            "_id": 12673,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "סמ לובצקי"
        },
        {
            "_id": 12674,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 12675,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 298,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 12676,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "סעידי שמחה"
        },
        {
            "_id": 12677,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 12678,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 280,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 12679,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 367,
            "שם_רחוב": "עליזה בגין"
        },
        {
            "_id": 12680,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 12681,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 368,
            "שם_רחוב": "עפרה חזה"
        },
        {
            "_id": 12682,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 339,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 12683,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 362,
            "שם_רחוב": "פולה בן גוריון"
        },
        {
            "_id": 12684,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "פוקס"
        },
        {
            "_id": 12685,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "פטדה"
        },
        {
            "_id": 12686,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "פייבל"
        },
        {
            "_id": 12687,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "פיינברג"
        },
        {
            "_id": 12688,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "פינס"
        },
        {
            "_id": 12689,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 342,
            "שם_רחוב": "פיקוס"
        },
        {
            "_id": 12690,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 269,
            "שם_רחוב": "פנינה"
        },
        {
            "_id": 12691,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 12692,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "פרג"
        },
        {
            "_id": 12693,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "פרחי בר"
        },
        {
            "_id": 12694,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "פרנקל אליהו"
        },
        {
            "_id": 12695,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "צאלה"
        },
        {
            "_id": 12696,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 12697,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "צברי סעדיה"
        },
        {
            "_id": 12698,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "צוקרמן"
        },
        {
            "_id": 12699,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "ציפורן"
        },
        {
            "_id": 12700,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "צמרת גדרה"
        },
        {
            "_id": 12701,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "צפניה"
        },
        {
            "_id": 12702,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 12703,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "קוסובסקי דניאל"
        },
        {
            "_id": 12704,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 340,
            "שם_רחוב": "קטלב"
        },
        {
            "_id": 12705,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 344,
            "שם_רחוב": "קפלינסקי משה"
        },
        {
            "_id": 12706,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "קפלן יעקב מאיר הכהן"
        },
        {
            "_id": 12707,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "קקל"
        },
        {
            "_id": 12708,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "קרסיקוב אריה"
        },
        {
            "_id": 12709,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "קרסנר אליעזר"
        },
        {
            "_id": 12710,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ראובן"
        },
        {
            "_id": 12711,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 319,
            "שם_רחוב": "רבי יהודה"
        },
        {
            "_id": 12712,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 366,
            "שם_רחוב": "רונה רמון"
        },
        {
            "_id": 12713,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 12714,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 354,
            "שם_רחוב": "רז אריה"
        },
        {
            "_id": 12715,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 346,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 12716,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רמז דוד"
        },
        {
            "_id": 12717,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש הנופש"
        },
        {
            "_id": 12718,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש נוה דוד"
        },
        {
            "_id": 12719,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש נוה משה"
        },
        {
            "_id": 12720,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש נוה ציון"
        },
        {
            "_id": 12721,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש סעד"
        },
        {
            "_id": 12722,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש צבא קבע"
        },
        {
            "_id": 12723,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "ש קטרה"
        },
        {
            "_id": 12724,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "שבו"
        },
        {
            "_id": 12725,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 12726,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שבטי ישראל"
        },
        {
            "_id": 12727,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "שביל מקורות"
        },
        {
            "_id": 12728,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "שד בגין מנחם"
        },
        {
            "_id": 12729,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 12730,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "שד ציונה צרפת"
        },
        {
            "_id": 12731,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "שד רבין יצחק"
        },
        {
            "_id": 12732,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 356,
            "שם_רחוב": "שד' צהל"
        },
        {
            "_id": 12733,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 12734,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 365,
            "שם_רחוב": "שולמית אלוני"
        },
        {
            "_id": 12735,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 315,
            "שם_רחוב": "שושן"
        },
        {
            "_id": 12736,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 373,
            "שם_רחוב": "שושנה דמארי"
        },
        {
            "_id": 12737,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 12738,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "שכ אזורי גדרה"
        },
        {
            "_id": 12739,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "שכ גבעת העיריות"
        },
        {
            "_id": 12740,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "שכ גולדה"
        },
        {
            "_id": 12741,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "שכ המושבה הגרמנית"
        },
        {
            "_id": 12742,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "שכ הפועלים"
        },
        {
            "_id": 12743,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "שכ נווה אדר"
        },
        {
            "_id": 12744,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "שכ נווה שולמית"
        },
        {
            "_id": 12745,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "שכ עמק הנשר"
        },
        {
            "_id": 12746,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "שכ רשפים"
        },
        {
            "_id": 12747,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "שכביץ"
        },
        {
            "_id": 12748,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 12749,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 12750,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שמעון"
        },
        {
            "_id": 12751,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "שפירא"
        },
        {
            "_id": 12752,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 359,
            "שם_רחוב": "שפק שלמה"
        },
        {
            "_id": 12753,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שפרינצק"
        },
        {
            "_id": 12754,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 12755,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "שקמה"
        },
        {
            "_id": 12756,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 371,
            "שם_רחוב": "שרה אהרונסון"
        },
        {
            "_id": 12757,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "שרעבי מנשה"
        },
        {
            "_id": 12758,
            "סמל_ישוב": 2550,
            "שם_ישוב": "גדרה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "תרמה"
        },
        {
            "_id": 12759,
            "סמל_ישוב": 852,
            "שם_ישוב": "גונן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 12760,
            "סמל_ישוב": 852,
            "שם_ישוב": "גונן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גונן"
        },
        {
            "_id": 12761,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 12762,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 12763,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 12764,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גורן"
        },
        {
            "_id": 12765,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 12766,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "זית"
        },
        {
            "_id": 12767,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מונפורט"
        },
        {
            "_id": 12768,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "קטלב"
        },
        {
            "_id": 12769,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 12770,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 12771,
            "סמל_ישוב": 755,
            "שם_ישוב": "גורן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "תעשייה"
        },
        {
            "_id": 12772,
            "סמל_ישוב": 1219,
            "שם_ישוב": "גורנות הגליל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גורנות הגליל"
        },
        {
            "_id": 12773,
            "סמל_ישוב": 1219,
            "שם_ישוב": "גורנות הגליל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 12774,
            "סמל_ישוב": 1219,
            "שם_ישוב": "גורנות הגליל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 12775,
            "סמל_ישוב": 1219,
            "שם_ישוב": "גורנות הגליל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 12776,
            "סמל_ישוב": 1219,
            "שם_ישוב": "גורנות הגליל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 12777,
            "סמל_ישוב": 1219,
            "שם_ישוב": "גורנות הגליל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 12778,
            "סמל_ישוב": 457,
            "שם_ישוב": "גזית ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 12779,
            "סמל_ישוב": 457,
            "שם_ישוב": "גזית ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 12780,
            "סמל_ישוב": 457,
            "שם_ישוב": "גזית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גזית"
        },
        {
            "_id": 12781,
            "סמל_ישוב": 457,
            "שם_ישוב": "גזית ",
            "סמל_רחוב": 103,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 12782,
            "סמל_ישוב": 457,
            "שם_ישוב": "גזית ",
            "סמל_רחוב": 104,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 12783,
            "סמל_ישוב": 457,
            "שם_ישוב": "גזית ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 12784,
            "סמל_ישוב": 457,
            "שם_ישוב": "גזית ",
            "סמל_רחוב": 106,
            "שם_רחוב": "צור"
        },
        {
            "_id": 12785,
            "סמל_ישוב": 457,
            "שם_ישוב": "גזית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 12786,
            "סמל_ישוב": 370,
            "שם_ישוב": "גזר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גזר"
        },
        {
            "_id": 12787,
            "סמל_ישוב": 370,
            "שם_ישוב": "גזר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ש קרוונים"
        },
        {
            "_id": 12788,
            "סמל_ישוב": 706,
            "שם_ישוב": "גיאה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גיאה"
        },
        {
            "_id": 12789,
            "סמל_ישוב": 196,
            "שם_ישוב": "גיבתון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "בן ישראל"
        },
        {
            "_id": 12790,
            "סמל_ישוב": 196,
            "שם_ישוב": "גיבתון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ברזילי"
        },
        {
            "_id": 12791,
            "סמל_ישוב": 196,
            "שם_ישוב": "גיבתון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גיבתון"
        },
        {
            "_id": 12792,
            "סמל_ישוב": 196,
            "שם_ישוב": "גיבתון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האם ובניה"
        },
        {
            "_id": 12793,
            "סמל_ישוב": 196,
            "שם_ישוב": "גיבתון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 12794,
            "סמל_ישוב": 196,
            "שם_ישוב": "גיבתון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "העמדות"
        },
        {
            "_id": 12795,
            "סמל_ישוב": 196,
            "שם_ישוב": "גיבתון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "יהודית"
        },
        {
            "_id": 12796,
            "סמל_ישוב": 1043,
            "שם_ישוב": "גיזו ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גיזו"
        },
        {
            "_id": 12797,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 12798,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אופיר"
        },
        {
            "_id": 12799,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 12800,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 12801,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 12802,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "גזית"
        },
        {
            "_id": 12803,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גילון"
        },
        {
            "_id": 12804,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "גרניט"
        },
        {
            "_id": 12805,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 12806,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 12807,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 12808,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "מצפה"
        },
        {
            "_id": 12809,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 12810,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 12811,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 12812,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שחם"
        },
        {
            "_id": 12813,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שנהב"
        },
        {
            "_id": 12814,
            "סמל_ישוב": 1204,
            "שם_ישוב": "גילון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 12815,
            "סמל_ישוב": 736,
            "שם_ישוב": "גילת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גילת"
        },
        {
            "_id": 12816,
            "סמל_ישוב": 736,
            "שם_ישוב": "גילת ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ קרוונים"
        },
        {
            "_id": 12817,
            "סמל_ישוב": 262,
            "שם_ישוב": "גינוסר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גינוסר"
        },
        {
            "_id": 12818,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אל היער"
        },
        {
            "_id": 12819,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 12820,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גיניגר"
        },
        {
            "_id": 12821,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "דרך הגבעות"
        },
        {
            "_id": 12822,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "דרך הדשאים"
        },
        {
            "_id": 12823,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך החשמל"
        },
        {
            "_id": 12824,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך הכרמים"
        },
        {
            "_id": 12825,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך המפעל"
        },
        {
            "_id": 12826,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הראשונים"
        },
        {
            "_id": 12827,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך הרפת"
        },
        {
            "_id": 12828,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "האשכולית"
        },
        {
            "_id": 12829,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "החינוך המשותף"
        },
        {
            "_id": 12830,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הטבעונים"
        },
        {
            "_id": 12831,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 128,
            "שם_רחוב": "היער המכושף"
        },
        {
            "_id": 12832,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "המקומרים"
        },
        {
            "_id": 12833,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הפומלית"
        },
        {
            "_id": 12834,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 12835,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הצעירים"
        },
        {
            "_id": 12836,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "השכונה הדרומית"
        },
        {
            "_id": 12837,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 12838,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "חלוצי דגניה ג'"
        },
        {
            "_id": 12839,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "לינה כפרית"
        },
        {
            "_id": 12840,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "משמר הוולגה"
        },
        {
            "_id": 12841,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "עלי גבעה"
        },
        {
            "_id": 12842,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שביל האולפן"
        },
        {
            "_id": 12843,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שדרת הדקלים"
        },
        {
            "_id": 12844,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "שדרת הזיתים"
        },
        {
            "_id": 12845,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שיכון ל"
        },
        {
            "_id": 12846,
            "סמל_ישוב": 92,
            "שם_ישוב": "גיניגר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "שכונת הדר"
        },
        {
            "_id": 12847,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גינתון"
        },
        {
            "_id": 12848,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 12849,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 12850,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 12851,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 12852,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 12853,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 12854,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 12855,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 12856,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 12857,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 12858,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הפקאן"
        },
        {
            "_id": 12859,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 12860,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 12861,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 12862,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 12863,
            "סמל_ישוב": 863,
            "שם_ישוב": "גינתון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 12864,
            "סמל_ישוב": 1206,
            "שם_ישוב": "גיתה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גיתה"
        },
        {
            "_id": 12865,
            "סמל_ישוב": 3613,
            "שם_ישוב": "גיתית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גיתית"
        },
        {
            "_id": 12866,
            "סמל_ישוב": 393,
            "שם_ישוב": "גלאון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גלאון"
        },
        {
            "_id": 12867,
            "סמל_ישוב": 3606,
            "שם_ישוב": "גלגל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גלגל"
        },
        {
            "_id": 12868,
            "סמל_ישוב": 346,
            "שם_ישוב": "גליל ים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גליל ים"
        },
        {
            "_id": 12869,
            "סמל_ישוב": 369,
            "שם_ישוב": "גלעד (אבן יצחק) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גלעד (אבן יצחק)"
        },
        {
            "_id": 12870,
            "סמל_ישוב": 745,
            "שם_ישוב": "גמזו ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גמזו"
        },
        {
            "_id": 12871,
            "סמל_ישוב": 745,
            "שם_ישוב": "גמזו ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 12872,
            "סמל_ישוב": 745,
            "שם_ישוב": "גמזו ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 12873,
            "סמל_ישוב": 745,
            "שם_ישוב": "גמזו ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 12874,
            "סמל_ישוב": 745,
            "שם_ישוב": "גמזו ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 12875,
            "סמל_ישוב": 745,
            "שם_ישוב": "גמזו ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 12876,
            "סמל_ישוב": 745,
            "שם_ישוב": "גמזו ",
            "סמל_רחוב": 101,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 12877,
            "סמל_ישוב": 745,
            "שם_ישוב": "גמזו ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מעלות יהונתן"
        },
        {
            "_id": 12878,
            "סמל_ישוב": 1072,
            "שם_ישוב": "גן הדרום ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גן הדרום"
        },
        {
            "_id": 12879,
            "סמל_ישוב": 1072,
            "שם_ישוב": "גן הדרום ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 12880,
            "סמל_ישוב": 1072,
            "שם_ישוב": "גן הדרום ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 12881,
            "סמל_ישוב": 1072,
            "שם_ישוב": "גן הדרום ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 12882,
            "סמל_ישוב": 1072,
            "שם_ישוב": "גן הדרום ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 12883,
            "סמל_ישוב": 1072,
            "שם_ישוב": "גן הדרום ",
            "סמל_רחוב": 101,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 12884,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גן השומרון"
        },
        {
            "_id": 12885,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 12886,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 12887,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האשכולית"
        },
        {
            "_id": 12888,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 12889,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הבריכה"
        },
        {
            "_id": 12890,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 12891,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "החלוצים 1934"
        },
        {
            "_id": 12892,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "המדגרה"
        },
        {
            "_id": 12893,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "המסיק"
        },
        {
            "_id": 12894,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 12895,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הקציר"
        },
        {
            "_id": 12896,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 12897,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "כביש 6403"
        },
        {
            "_id": 12898,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 12899,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "כרמי זיתים"
        },
        {
            "_id": 12900,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "סמ האלונים"
        },
        {
            "_id": 12901,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 118,
            "שם_רחוב": "סמ החרוב"
        },
        {
            "_id": 12902,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 120,
            "שם_רחוב": "סמ המחלבה"
        },
        {
            "_id": 12903,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 121,
            "שם_רחוב": "סמ הפיקוס"
        },
        {
            "_id": 12904,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 122,
            "שם_רחוב": "עובדי אדמה"
        },
        {
            "_id": 12905,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 124,
            "שם_רחוב": "פינלנד"
        },
        {
            "_id": 12906,
            "סמל_ישוב": 225,
            "שם_ישוב": "גן השומרון ",
            "סמל_רחוב": 123,
            "שם_רחוב": "פרחי הדר"
        },
        {
            "_id": 12907,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גן חיים"
        },
        {
            "_id": 12908,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך המלך"
        },
        {
            "_id": 12909,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "האיריס"
        },
        {
            "_id": 12910,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 12911,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 12912,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 12913,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 12914,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 12915,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 12916,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 12917,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 12918,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 12919,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 12920,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "השושן"
        },
        {
            "_id": 12921,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 12922,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 12923,
            "סמל_ישוב": 239,
            "שם_ישוב": "גן חיים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 12924,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גן יאשיה"
        },
        {
            "_id": 12925,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך הפרדס"
        },
        {
            "_id": 12926,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 12927,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הגיא"
        },
        {
            "_id": 12928,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 12929,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 12930,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 12931,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "החורשה"
        },
        {
            "_id": 12932,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 12933,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 12934,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 12935,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "השדה"
        },
        {
            "_id": 12936,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 12937,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "נחל ראובן"
        },
        {
            "_id": 12938,
            "סמל_ישוב": 734,
            "שם_ישוב": "גן יאשיה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שדרת האקליפטוס"
        },
        {
            "_id": 12939,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 288,
            "שם_רחוב": "א. צ. גרינברג"
        },
        {
            "_id": 12940,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אביר יעקב"
        },
        {
            "_id": 12941,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אגדת דשא"
        },
        {
            "_id": 12942,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 12943,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אדמונית"
        },
        {
            "_id": 12944,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אהוד בן גרא"
        },
        {
            "_id": 12945,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 12946,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אורנית"
        },
        {
            "_id": 12947,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "אזור התעשיה"
        },
        {
            "_id": 12948,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 284,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 12949,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אחוזה"
        },
        {
            "_id": 12950,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "איילון"
        },
        {
            "_id": 12951,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "איריס"
        },
        {
            "_id": 12952,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 12953,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "אלופי צהל"
        },
        {
            "_id": 12954,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 12955,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אלכסנדר"
        },
        {
            "_id": 12956,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 356,
            "שם_רחוב": "אלעזר"
        },
        {
            "_id": 12957,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "אלף נשיקות"
        },
        {
            "_id": 12958,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלתרמן נתן"
        },
        {
            "_id": 12959,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "אמא אדמה"
        },
        {
            "_id": 12960,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 12961,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 12962,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "ארגמן"
        },
        {
            "_id": 12963,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אשכול"
        },
        {
            "_id": 12964,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 12965,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 283,
            "שם_רחוב": "בגין מנחם"
        },
        {
            "_id": 12966,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 286,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 12967,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "בלנקשטיין מורלי"
        },
        {
            "_id": 12968,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "בן גוריון"
        },
        {
            "_id": 12969,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "בר יוחאי"
        },
        {
            "_id": 12970,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 350,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 12971,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "ברקאי"
        },
        {
            "_id": 12972,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 12973,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "בשור"
        },
        {
            "_id": 12974,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "גבעת הסביונים"
        },
        {
            "_id": 12975,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 285,
            "שם_רחוב": "גולדברג לאה"
        },
        {
            "_id": 12976,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "גולן"
        },
        {
            "_id": 12977,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "גיבורים"
        },
        {
            "_id": 12978,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 12979,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "גן השקמים"
        },
        {
            "_id": 12980,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גן יבנה"
        },
        {
            "_id": 12981,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דר שמעון היימן"
        },
        {
            "_id": 12982,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 12983,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 12984,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "דלתון"
        },
        {
            "_id": 12985,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "דרך יצחק רבין"
        },
        {
            "_id": 12986,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "דרך ירושלים"
        },
        {
            "_id": 12987,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "דרך קנדה"
        },
        {
            "_id": 12988,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "דרך שתולים"
        },
        {
            "_id": 12989,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 358,
            "שם_רחוב": "האור"
        },
        {
            "_id": 12990,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 12991,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 12992,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 377,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 12993,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 12994,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 12995,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 280,
            "שם_רחוב": "הבר"
        },
        {
            "_id": 12996,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 12997,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "הגאולה"
        },
        {
            "_id": 12998,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 12999,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 295,
            "שם_רחוב": "הדגן"
        },
        {
            "_id": 13000,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 13001,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 13002,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "הדרים"
        },
        {
            "_id": 13003,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 13004,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 368,
            "שם_רחוב": "הודיה"
        },
        {
            "_id": 13005,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 13006,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 296,
            "שם_רחוב": "הזורע"
        },
        {
            "_id": 13007,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 289,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 13008,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 299,
            "שם_רחוב": "החמניה"
        },
        {
            "_id": 13009,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 13010,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 297,
            "שם_רחוב": "החציר"
        },
        {
            "_id": 13011,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 13012,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 13013,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "הטייסים"
        },
        {
            "_id": 13014,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 279,
            "שם_רחוב": "הטל"
        },
        {
            "_id": 13015,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 278,
            "שם_רחוב": "היורה"
        },
        {
            "_id": 13016,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "הישועה"
        },
        {
            "_id": 13017,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 367,
            "שם_רחוב": "הכד"
        },
        {
            "_id": 13018,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "הכישור"
        },
        {
            "_id": 13019,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "המגינים"
        },
        {
            "_id": 13020,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 291,
            "שם_רחוב": "המגל"
        },
        {
            "_id": 13021,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 369,
            "שם_רחוב": "המגן"
        },
        {
            "_id": 13022,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 298,
            "שם_רחוב": "המורג"
        },
        {
            "_id": 13023,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 13024,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "המלך דוד"
        },
        {
            "_id": 13025,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "המלך שלמה"
        },
        {
            "_id": 13026,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "המלקוש"
        },
        {
            "_id": 13027,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "המנור"
        },
        {
            "_id": 13028,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "המפרש"
        },
        {
            "_id": 13029,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "המשוט"
        },
        {
            "_id": 13030,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 287,
            "שם_רחוב": "המשוררת רחל"
        },
        {
            "_id": 13031,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 13032,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 366,
            "שם_רחוב": "הנס"
        },
        {
            "_id": 13033,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "הנצחון"
        },
        {
            "_id": 13034,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 13035,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הנשיא"
        },
        {
            "_id": 13036,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 13037,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "הסייפן"
        },
        {
            "_id": 13038,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "הסיירים"
        },
        {
            "_id": 13039,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "העוגן"
        },
        {
            "_id": 13040,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 13041,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 13042,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 13043,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 293,
            "שם_רחוב": "הערוגות"
        },
        {
            "_id": 13044,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "הפלדה"
        },
        {
            "_id": 13045,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "הפלך"
        },
        {
            "_id": 13046,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 13047,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "הפרח בגני"
        },
        {
            "_id": 13048,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "הצפורן"
        },
        {
            "_id": 13049,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "הקמה"
        },
        {
            "_id": 13050,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 281,
            "שם_רחוב": "הקציר"
        },
        {
            "_id": 13051,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "הר הצופים"
        },
        {
            "_id": 13052,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "הר סיני"
        },
        {
            "_id": 13053,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "הר ציון"
        },
        {
            "_id": 13054,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 13055,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 13056,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 13057,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 294,
            "שם_רחוב": "הרגב"
        },
        {
            "_id": 13058,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "הרמבם"
        },
        {
            "_id": 13059,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "הרעות"
        },
        {
            "_id": 13060,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 13061,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 13062,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 13063,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "השופטים"
        },
        {
            "_id": 13064,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "השושן"
        },
        {
            "_id": 13065,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 290,
            "שם_רחוב": "השיבולים"
        },
        {
            "_id": 13066,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 13067,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "השניים"
        },
        {
            "_id": 13068,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 13069,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 13070,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "התורן"
        },
        {
            "_id": 13071,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 292,
            "שם_רחוב": "התלמים"
        },
        {
            "_id": 13072,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "ויניפג"
        },
        {
            "_id": 13073,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "זיו"
        },
        {
            "_id": 13074,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 375,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 13075,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "חבר"
        },
        {
            "_id": 13076,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "חופים"
        },
        {
            "_id": 13077,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "חופית"
        },
        {
            "_id": 13078,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "חיים ויצמן"
        },
        {
            "_id": 13079,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "חלמיש"
        },
        {
            "_id": 13080,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "חמדה"
        },
        {
            "_id": 13081,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "חסידי אומות עולם"
        },
        {
            "_id": 13082,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 13083,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 362,
            "שם_רחוב": "חשמונאים"
        },
        {
            "_id": 13084,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "יבולים"
        },
        {
            "_id": 13085,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "יבנאי יוסף"
        },
        {
            "_id": 13086,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 360,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 13087,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 13088,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 352,
            "שם_רחוב": "יהודית"
        },
        {
            "_id": 13089,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 13090,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 351,
            "שם_רחוב": "יוחנן"
        },
        {
            "_id": 13091,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 354,
            "שם_רחוב": "יונתן"
        },
        {
            "_id": 13092,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "ינאי"
        },
        {
            "_id": 13093,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 13094,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "יעלים"
        },
        {
            "_id": 13095,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 13096,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "יקותיאל אדם"
        },
        {
            "_id": 13097,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 13098,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "ירקון"
        },
        {
            "_id": 13099,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "ישראל שטיין"
        },
        {
            "_id": 13100,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6000,
            "שם_רחוב": "כיכר הבנים"
        },
        {
            "_id": 13101,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר הרצל"
        },
        {
            "_id": 13102,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר זמיר"
        },
        {
            "_id": 13103,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר מירון"
        },
        {
            "_id": 13104,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר מלכי ישראל"
        },
        {
            "_id": 13105,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר עצמאות"
        },
        {
            "_id": 13106,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 13107,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 13108,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "כרמים"
        },
        {
            "_id": 13109,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 13110,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 13111,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 13112,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "לימון"
        },
        {
            "_id": 13113,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "לימור"
        },
        {
            "_id": 13114,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 357,
            "שם_רחוב": "לפיד"
        },
        {
            "_id": 13115,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "מגדים"
        },
        {
            "_id": 13116,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 365,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 13117,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "מור"
        },
        {
            "_id": 13118,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 13119,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 13120,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 363,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 13121,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 13122,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 364,
            "שם_רחוב": "מרים"
        },
        {
            "_id": 13123,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "מתנות קטנות"
        },
        {
            "_id": 13124,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 361,
            "שם_רחוב": "מתתיהו"
        },
        {
            "_id": 13125,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "נאות אשכול"
        },
        {
            "_id": 13126,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "נאות המכבים"
        },
        {
            "_id": 13127,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "נאות הפרחים"
        },
        {
            "_id": 13128,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "נאות יבנאי"
        },
        {
            "_id": 13129,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "נווה לימור"
        },
        {
            "_id": 13130,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 13131,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 13132,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "ניצנה"
        },
        {
            "_id": 13133,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "סמ התור"
        },
        {
            "_id": 13134,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "סמ התותחנים"
        },
        {
            "_id": 13135,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "סמ חטיבת גולני"
        },
        {
            "_id": 13136,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 13137,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 282,
            "שם_רחוב": "עגנון"
        },
        {
            "_id": 13138,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "עולי הגרדום"
        },
        {
            "_id": 13139,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "עופרה"
        },
        {
            "_id": 13140,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "עופרים"
        },
        {
            "_id": 13141,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "עמיחי יהודה"
        },
        {
            "_id": 13142,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 376,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 13143,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "פולג"
        },
        {
            "_id": 13144,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 13145,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "פרי גנך"
        },
        {
            "_id": 13146,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "צאלים"
        },
        {
            "_id": 13147,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 13148,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "צוללים"
        },
        {
            "_id": 13149,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "צור"
        },
        {
            "_id": 13150,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "צליל מיתר"
        },
        {
            "_id": 13151,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "צמח בר"
        },
        {
            "_id": 13152,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "צנחנים"
        },
        {
            "_id": 13153,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 13154,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "קדרון"
        },
        {
            "_id": 13155,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "קישון"
        },
        {
            "_id": 13156,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 13157,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "רביבים"
        },
        {
            "_id": 13158,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 13159,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 13160,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש חדש"
        },
        {
            "_id": 13161,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "ש נווה גן"
        },
        {
            "_id": 13162,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש נווה עובד"
        },
        {
            "_id": 13163,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש עובדים"
        },
        {
            "_id": 13164,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 13165,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 13166,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שבו"
        },
        {
            "_id": 13167,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 13168,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 13169,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "שורק"
        },
        {
            "_id": 13170,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "שזפון"
        },
        {
            "_id": 13171,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 378,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 13172,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "שייטת"
        },
        {
            "_id": 13173,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "שיר השירים"
        },
        {
            "_id": 13174,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ ב סוכנות"
        },
        {
            "_id": 13175,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ גבעת שמשון"
        },
        {
            "_id": 13176,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ הותיקים"
        },
        {
            "_id": 13177,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ המושבה"
        },
        {
            "_id": 13178,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "שכ מוטה גור"
        },
        {
            "_id": 13179,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ נאות הדרים"
        },
        {
            "_id": 13180,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ פועלים"
        },
        {
            "_id": 13181,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ שוטרים"
        },
        {
            "_id": 13182,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 379,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 13183,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 355,
            "שם_רחוב": "שלומציון"
        },
        {
            "_id": 13184,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "שליין זלמן"
        },
        {
            "_id": 13185,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "שמואל הנביא"
        },
        {
            "_id": 13186,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 359,
            "שם_רחוב": "שמעון"
        },
        {
            "_id": 13187,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "שמר נעמי"
        },
        {
            "_id": 13188,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "שקמה"
        },
        {
            "_id": 13189,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "שריון"
        },
        {
            "_id": 13190,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 13191,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "תורה ועבודה"
        },
        {
            "_id": 13192,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 13193,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 13194,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "תפוח"
        },
        {
            "_id": 13195,
            "סמל_ישוב": 166,
            "שם_ישוב": "גן יבנה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 13196,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 13197,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 13198,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 13199,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גן נר"
        },
        {
            "_id": 13200,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 13201,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הרב נורי דוד"
        },
        {
            "_id": 13202,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 13203,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 13204,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 13205,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 126,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 13206,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 13207,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 13208,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 13209,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 13210,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 13211,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 13212,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "נופר"
        },
        {
            "_id": 13213,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 13214,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 13215,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 13216,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 13217,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 13218,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 13219,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 13220,
            "סמל_ישוב": 1274,
            "שם_ישוב": "גן נר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 13221,
            "סמל_ישוב": 311,
            "שם_ישוב": "גן שורק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גן שורק"
        },
        {
            "_id": 13222,
            "סמל_ישוב": 144,
            "שם_ישוב": "גן שלמה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גן שלמה"
        },
        {
            "_id": 13223,
            "סמל_ישוב": 72,
            "שם_ישוב": "גן שמואל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גן שמואל"
        },
        {
            "_id": 13224,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גנות"
        },
        {
            "_id": 13225,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 13226,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 13227,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 13228,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 104,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 13229,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 13230,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 13231,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 13232,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 13233,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 109,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 13234,
            "סמל_ישוב": 836,
            "שם_ישוב": "גנות ",
            "סמל_רחוב": 110,
            "שם_רחוב": "התומר"
        },
        {
            "_id": 13235,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 13236,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גנות הדר"
        },
        {
            "_id": 13237,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האדמונית"
        },
        {
            "_id": 13238,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האירוסים"
        },
        {
            "_id": 13239,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הבסמת"
        },
        {
            "_id": 13240,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הדגניות"
        },
        {
            "_id": 13241,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 13242,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ההרדופים"
        },
        {
            "_id": 13243,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "החמניות"
        },
        {
            "_id": 13244,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 13245,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 13246,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "היקינטון"
        },
        {
            "_id": 13247,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 13248,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 13249,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 13250,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הסחלב"
        },
        {
            "_id": 13251,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הסיגליות"
        },
        {
            "_id": 13252,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הסייפן"
        },
        {
            "_id": 13253,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הסתוונית"
        },
        {
            "_id": 13254,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 13255,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הרקפות"
        },
        {
            "_id": 13256,
            "סמל_ישוב": 549,
            "שם_ישוב": "גנות הדר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "השושנים"
        },
        {
            "_id": 13257,
            "סמל_ישוב": 1103,
            "שם_ישוב": "גני הדר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גני הדר"
        },
        {
            "_id": 13258,
            "סמל_ישוב": 1103,
            "שם_ישוב": "גני הדר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 13259,
            "סמל_ישוב": 1103,
            "שם_ישוב": "גני הדר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 13260,
            "סמל_ישוב": 1103,
            "שם_ישוב": "גני הדר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 13261,
            "סמל_ישוב": 1103,
            "שם_ישוב": "גני הדר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 13262,
            "סמל_ישוב": 1103,
            "שם_ישוב": "גני הדר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 13263,
            "סמל_ישוב": 1103,
            "שם_ישוב": "גני הדר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סמטת האשכולית"
        },
        {
            "_id": 13264,
            "סמל_ישוב": 1103,
            "שם_ישוב": "גני הדר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "סמטת הלימון"
        },
        {
            "_id": 13265,
            "סמל_ישוב": 1103,
            "שם_ישוב": "גני הדר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סמטת הפומלית"
        },
        {
            "_id": 13266,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 13267,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גני טל"
        },
        {
            "_id": 13268,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 13269,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "האגם"
        },
        {
            "_id": 13270,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "החווה"
        },
        {
            "_id": 13271,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "המדרשה"
        },
        {
            "_id": 13272,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הפגודה"
        },
        {
            "_id": 13273,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "ורד"
        },
        {
            "_id": 13274,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "חוף הדקלים"
        },
        {
            "_id": 13275,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 13276,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר המייסדים"
        },
        {
            "_id": 13277,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "נאות קטיף"
        },
        {
            "_id": 13278,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נוריה"
        },
        {
            "_id": 13279,
            "סמל_ישוב": 1371,
            "שם_ישוב": "גני טל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "תפוח"
        },
        {
            "_id": 13280,
            "סמל_ישוב": 862,
            "שם_ישוב": "גני יוחנן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גני יוחנן"
        },
        {
            "_id": 13281,
            "סמל_ישוב": 862,
            "שם_ישוב": "גני יוחנן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 13282,
            "סמל_ישוב": 862,
            "שם_ישוב": "גני יוחנן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 13283,
            "סמל_ישוב": 862,
            "שם_ישוב": "גני יוחנן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 13284,
            "סמל_ישוב": 862,
            "שם_ישוב": "גני יוחנן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 13285,
            "סמל_ישוב": 862,
            "שם_ישוב": "גני יוחנן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 13286,
            "סמל_ישוב": 862,
            "שם_ישוב": "גני יוחנן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 13287,
            "סמל_ישוב": 862,
            "שם_ישוב": "גני יוחנן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 13288,
            "סמל_ישוב": 862,
            "שם_ישוב": "גני יוחנן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 13289,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אבי דוד"
        },
        {
            "_id": 13290,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אמרי בינה"
        },
        {
            "_id": 13291,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 118,
            "שם_רחוב": "בית יוסף"
        },
        {
            "_id": 13292,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 116,
            "שם_רחוב": "בן איש חי"
        },
        {
            "_id": 13293,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 119,
            "שם_רחוב": "בני בינה"
        },
        {
            "_id": 13294,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גני מודיעין"
        },
        {
            "_id": 13295,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 117,
            "שם_רחוב": "דברות משה"
        },
        {
            "_id": 13296,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 125,
            "שם_רחוב": "דעת תבונה"
        },
        {
            "_id": 13297,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 127,
            "שם_רחוב": "דרך חיים"
        },
        {
            "_id": 13298,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הרמבם"
        },
        {
            "_id": 13299,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 101,
            "שם_רחוב": "טורי זהב"
        },
        {
            "_id": 13300,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 111,
            "שם_רחוב": "יערות דבש"
        },
        {
            "_id": 13301,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 107,
            "שם_רחוב": "כף חיים"
        },
        {
            "_id": 13302,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 123,
            "שם_רחוב": "מהרי קאפח"
        },
        {
            "_id": 13303,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מהריץ"
        },
        {
            "_id": 13304,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 124,
            "שם_רחוב": "נחל מודיעין"
        },
        {
            "_id": 13305,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 112,
            "שם_רחוב": "פרי מגדים"
        },
        {
            "_id": 13306,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 121,
            "שם_רחוב": "קדושת הלוי"
        },
        {
            "_id": 13307,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 122,
            "שם_רחוב": "קול יהודה"
        },
        {
            "_id": 13308,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רמא"
        },
        {
            "_id": 13309,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 13310,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 13311,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ גני מודיעין"
        },
        {
            "_id": 13312,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שפת כהן"
        },
        {
            "_id": 13313,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שתילי זהב"
        },
        {
            "_id": 13314,
            "סמל_ישוב": 3823,
            "שם_ישוב": "גני מודיעין ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שתילי זיתים"
        },
        {
            "_id": 13315,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 13316,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ביער"
        },
        {
            "_id": 13317,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 104,
            "שם_רחוב": "בית העם"
        },
        {
            "_id": 13318,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גני עם"
        },
        {
            "_id": 13319,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 13320,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 13321,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 13322,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 13323,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 112,
            "שם_רחוב": "התות"
        },
        {
            "_id": 13324,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 107,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 13325,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ירוק עד"
        },
        {
            "_id": 13326,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 103,
            "שם_רחוב": "סביונים"
        },
        {
            "_id": 13327,
            "סמל_ישוב": 218,
            "שם_ישוב": "גני עם ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שירת השחרור"
        },
        {
            "_id": 13328,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 13329,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "בית שאן"
        },
        {
            "_id": 13330,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "בקעת הירדן"
        },
        {
            "_id": 13331,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "גבעון"
        },
        {
            "_id": 13332,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גבעת סביון"
        },
        {
            "_id": 13333,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "גבעת סביון החדשה"
        },
        {
            "_id": 13334,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גני תקווה"
        },
        {
            "_id": 13335,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דגניה"
        },
        {
            "_id": 13336,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "דרך אילות"
        },
        {
            "_id": 13337,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "דרך האגמים"
        },
        {
            "_id": 13338,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "דרך הבשמים"
        },
        {
            "_id": 13339,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "דרך ההדרים"
        },
        {
            "_id": 13340,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 13341,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "דרך המלך"
        },
        {
            "_id": 13342,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "דרך המשי"
        },
        {
            "_id": 13343,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "דרך העמק"
        },
        {
            "_id": 13344,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "דרך התמרים"
        },
        {
            "_id": 13345,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "דרך התקווה"
        },
        {
            "_id": 13346,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 13347,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 13348,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 13349,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 13350,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 13351,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 13352,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 13353,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 13354,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 13355,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "המצפה"
        },
        {
            "_id": 13356,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 13357,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 13358,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "הנשיא טרומן"
        },
        {
            "_id": 13359,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "העמקים"
        },
        {
            "_id": 13360,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 13361,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 13362,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "הר נבו"
        },
        {
            "_id": 13363,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הר סיני"
        },
        {
            "_id": 13364,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הרי יהודה"
        },
        {
            "_id": 13365,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "הרי ירושלים"
        },
        {
            "_id": 13366,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 13367,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "הרמה"
        },
        {
            "_id": 13368,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 13369,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "זאב"
        },
        {
            "_id": 13370,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "יודפת"
        },
        {
            "_id": 13371,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ים המלח"
        },
        {
            "_id": 13372,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 13373,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מרחביה"
        },
        {
            "_id": 13374,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "נוף סביון"
        },
        {
            "_id": 13375,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "סמ דביר"
        },
        {
            "_id": 13376,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "סמ הלבונה"
        },
        {
            "_id": 13377,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סמ השרון"
        },
        {
            "_id": 13378,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "סמ כינרת"
        },
        {
            "_id": 13379,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "סמ עין גנים"
        },
        {
            "_id": 13380,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "סמ עמק יזרעאל"
        },
        {
            "_id": 13381,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "סמ קדש"
        },
        {
            "_id": 13382,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "סמ תבור"
        },
        {
            "_id": 13383,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סמ תל חי"
        },
        {
            "_id": 13384,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "סמטת יובלים"
        },
        {
            "_id": 13385,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 13386,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "עין חנוך"
        },
        {
            "_id": 13387,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "עין שמש"
        },
        {
            "_id": 13388,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "עמק זבולון"
        },
        {
            "_id": 13389,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 13390,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "קדושי השואה"
        },
        {
            "_id": 13391,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "קדושת יום טוב"
        },
        {
            "_id": 13392,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "קישון"
        },
        {
            "_id": 13393,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "קרית ישמח משה"
        },
        {
            "_id": 13394,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "רבאון שלמה"
        },
        {
            "_id": 13395,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "רחבת אלון"
        },
        {
            "_id": 13396,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "רחבת גונן"
        },
        {
            "_id": 13397,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "רחבת דולב"
        },
        {
            "_id": 13398,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש חדש"
        },
        {
            "_id": 13399,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שדרת התקוות"
        },
        {
            "_id": 13400,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ בורוכוב"
        },
        {
            "_id": 13401,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ גנים"
        },
        {
            "_id": 13402,
            "סמל_ישוב": 229,
            "שם_ישוב": "גני תקווה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ יפה נוף"
        },
        {
            "_id": 13403,
            "סמל_ישוב": 842,
            "שם_ישוב": "געש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "געש"
        },
        {
            "_id": 13404,
            "סמל_ישוב": 463,
            "שם_ישוב": "געתון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "געתון"
        },
        {
            "_id": 13405,
            "סמל_ישוב": 39,
            "שם_ישוב": "גפן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 13406,
            "סמל_ישוב": 1129,
            "שם_ישוב": "גרופית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גרופית"
        },
        {
            "_id": 13407,
            "סמל_ישוב": 4022,
            "שם_ישוב": "גשור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גשור"
        },
        {
            "_id": 13408,
            "סמל_ישוב": 305,
            "שם_ישוב": "גשר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 13409,
            "סמל_ישוב": 305,
            "שם_ישוב": "גשר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גשר"
        },
        {
            "_id": 13410,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "ביס שדה אכזיב"
        },
        {
            "_id": 13411,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גשר הזיו"
        },
        {
            "_id": 13412,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 136,
            "שם_רחוב": "דור הבנים"
        },
        {
            "_id": 13413,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 127,
            "שם_רחוב": "דרך הטבק"
        },
        {
            "_id": 13414,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 160,
            "שם_רחוב": "דרך הקיבוץ"
        },
        {
            "_id": 13415,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דרך כליל החורש"
        },
        {
            "_id": 13416,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 137,
            "שם_רחוב": "הגבעה"
        },
        {
            "_id": 13417,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 138,
            "שם_רחוב": "המזכירות"
        },
        {
            "_id": 13418,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 139,
            "שם_רחוב": "המרכז"
        },
        {
            "_id": 13419,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 140,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 13420,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 141,
            "שם_רחוב": "חלזונות הגשם"
        },
        {
            "_id": 13421,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 131,
            "שם_רחוב": "משעול הברוש"
        },
        {
            "_id": 13422,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 132,
            "שם_רחוב": "נתיב הארז"
        },
        {
            "_id": 13423,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 135,
            "שם_רחוב": "נתיב הגפן"
        },
        {
            "_id": 13424,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 134,
            "שם_רחוב": "נתיב הדולב"
        },
        {
            "_id": 13425,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 133,
            "שם_רחוב": "נתיב ההרדוף"
        },
        {
            "_id": 13426,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 129,
            "שם_רחוב": "נתיב הזית"
        },
        {
            "_id": 13427,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 128,
            "שם_רחוב": "נתיב החרוב"
        },
        {
            "_id": 13428,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 119,
            "שם_רחוב": "נתיב היערה"
        },
        {
            "_id": 13429,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 114,
            "שם_רחוב": "נתיב הלוטם"
        },
        {
            "_id": 13430,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 115,
            "שם_רחוב": "נתיב המרגנית"
        },
        {
            "_id": 13431,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 116,
            "שם_רחוב": "נתיב הנרקיס"
        },
        {
            "_id": 13432,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 117,
            "שם_רחוב": "נתיב הסחלב"
        },
        {
            "_id": 13433,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 118,
            "שם_רחוב": "נתיב הערבה"
        },
        {
            "_id": 13434,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רחבת הבונים"
        },
        {
            "_id": 13435,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 143,
            "שם_רחוב": "רחבת הגרעינים"
        },
        {
            "_id": 13436,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 144,
            "שם_רחוב": "רחבת ההגשמה"
        },
        {
            "_id": 13437,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 145,
            "שם_רחוב": "רחבת המתנדבים"
        },
        {
            "_id": 13438,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 146,
            "שם_רחוב": "רחבת הצופים"
        },
        {
            "_id": 13439,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 147,
            "שם_רחוב": "רחבת פינת חי"
        },
        {
            "_id": 13440,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 148,
            "שם_רחוב": "שביל בית הערבה"
        },
        {
            "_id": 13441,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 149,
            "שם_רחוב": "שביל האיילה"
        },
        {
            "_id": 13442,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 150,
            "שם_רחוב": "שביל הוורקשופ"
        },
        {
            "_id": 13443,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 151,
            "שם_רחוב": "שביל החצב"
        },
        {
            "_id": 13444,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 152,
            "שם_רחוב": "שביל החצרן"
        },
        {
            "_id": 13445,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 153,
            "שם_רחוב": "שביל הלהבה"
        },
        {
            "_id": 13446,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 154,
            "שם_רחוב": "שביל המזכיר"
        },
        {
            "_id": 13447,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 155,
            "שם_רחוב": "שביל הניצנים"
        },
        {
            "_id": 13448,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 156,
            "שם_רחוב": "שביל הסדרן"
        },
        {
            "_id": 13449,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 157,
            "שם_רחוב": "שביל העופרים"
        },
        {
            "_id": 13450,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 158,
            "שם_רחוב": "שביל הענפים"
        },
        {
            "_id": 13451,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 159,
            "שם_רחוב": "שדרות השיקמים"
        },
        {
            "_id": 13452,
            "סמל_ישוב": 574,
            "שם_ישוב": "גשר הזיו ",
            "סמל_רחוב": 130,
            "שם_רחוב": "שדרת האורנים"
        },
        {
            "_id": 13453,
            "סמל_ישוב": 340,
            "שם_ישוב": "גת (קיבוץ) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גת (קיבוץ)"
        },
        {
            "_id": 13454,
            "סמל_ישוב": 128,
            "שם_ישוב": "גת רימון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אילנות"
        },
        {
            "_id": 13455,
            "סמל_ישוב": 128,
            "שם_ישוב": "גת רימון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "גת רימון"
        },
        {
            "_id": 13456,
            "סמל_ישוב": 128,
            "שם_ישוב": "גת רימון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 13457,
            "סמל_ישוב": 128,
            "שם_ישוב": "גת רימון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 13458,
            "סמל_ישוב": 128,
            "שם_ישוב": "גת רימון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 13459,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 484,
            "שם_רחוב": "אברהים אלח'ליל"
        },
        {
            "_id": 13460,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 427,
            "שם_רחוב": "אדיסון"
        },
        {
            "_id": 13461,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 449,
            "שם_רחוב": "אום כולתום"
        },
        {
            "_id": 13462,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 305,
            "שם_רחוב": "אוסלו"
        },
        {
            "_id": 13463,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 319,
            "שם_רחוב": "אוראנוס"
        },
        {
            "_id": 13464,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 477,
            "שם_רחוב": "אורכיד"
        },
        {
            "_id": 13465,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6761,
            "שם_רחוב": "אזור המסעדות"
        },
        {
            "_id": 13466,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6756,
            "שם_רחוב": "אזור התעשיה"
        },
        {
            "_id": 13467,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6755,
            "שם_רחוב": "אזור כביש עוקף"
        },
        {
            "_id": 13468,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 413,
            "שם_רחוב": "איינשטיין"
        },
        {
            "_id": 13469,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 386,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 13470,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אל ג'ליל"
        },
        {
            "_id": 13471,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 485,
            "שם_רחוב": "אל נביא יעקוב"
        },
        {
            "_id": 13472,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אל נזהא"
        },
        {
            "_id": 13473,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 486,
            "שם_רחוב": "אל סת נסב"
        },
        {
            "_id": 13474,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 362,
            "שם_רחוב": "אל סת שהואנה"
        },
        {
            "_id": 13475,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 268,
            "שם_רחוב": "אלאדאב"
        },
        {
            "_id": 13476,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 445,
            "שם_רחוב": "אלאדיב"
        },
        {
            "_id": 13477,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 253,
            "שם_רחוב": "אלאהאלי"
        },
        {
            "_id": 13478,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 429,
            "שם_רחוב": "אלאהראם"
        },
        {
            "_id": 13479,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 334,
            "שם_רחוב": "אלאול"
        },
        {
            "_id": 13480,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 359,
            "שם_רחוב": "אלאוסתאד'"
        },
        {
            "_id": 13481,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 272,
            "שם_רחוב": "אלאח'אא"
        },
        {
            "_id": 13482,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 276,
            "שם_רחוב": "אלאח'לאס"
        },
        {
            "_id": 13483,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 335,
            "שם_רחוב": "אלאחד"
        },
        {
            "_id": 13484,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 471,
            "שם_רחוב": "אלאחסאן"
        },
        {
            "_id": 13485,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 455,
            "שם_רחוב": "אלאמאנה"
        },
        {
            "_id": 13486,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 384,
            "שם_רחוב": "אלאמיר"
        },
        {
            "_id": 13487,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 279,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 13488,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 397,
            "שם_רחוב": "אלאנואר"
        },
        {
            "_id": 13489,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 389,
            "שם_רחוב": "אלאנסאנייה"
        },
        {
            "_id": 13490,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 494,
            "שם_רחוב": "אלאסיל"
        },
        {
            "_id": 13491,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 475,
            "שם_רחוב": "אלאסראא"
        },
        {
            "_id": 13492,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 316,
            "שם_רחוב": "אלארד'"
        },
        {
            "_id": 13493,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 261,
            "שם_רחוב": "אלארז"
        },
        {
            "_id": 13494,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 487,
            "שם_רחוב": "אלאשהאב"
        },
        {
            "_id": 13495,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 489,
            "שם_רחוב": "אלבאסל"
        },
        {
            "_id": 13496,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 351,
            "שם_רחוב": "אלבאשא"
        },
        {
            "_id": 13497,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 405,
            "שם_רחוב": "אלבדיע"
        },
        {
            "_id": 13498,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 482,
            "שם_רחוב": "אלבהאאיה"
        },
        {
            "_id": 13499,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 498,
            "שם_רחוב": "אלבהג'ה"
        },
        {
            "_id": 13500,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 367,
            "שם_רחוב": "אלביאדה"
        },
        {
            "_id": 13501,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 265,
            "שם_רחוב": "אלבלאן"
        },
        {
            "_id": 13502,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלבסאתין"
        },
        {
            "_id": 13503,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 310,
            "שם_רחוב": "אלברונז"
        },
        {
            "_id": 13504,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 452,
            "שם_רחוב": "אלג'דור"
        },
        {
            "_id": 13505,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 231,
            "שם_רחוב": "אלג'וז"
        },
        {
            "_id": 13506,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אלג'ולן"
        },
        {
            "_id": 13507,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אלג'ורי"
        },
        {
            "_id": 13508,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 396,
            "שם_רחוב": "אלג'מאל"
        },
        {
            "_id": 13509,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 457,
            "שם_רחוב": "אלג'עארה"
        },
        {
            "_id": 13510,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אלג'עסאס"
        },
        {
            "_id": 13511,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 312,
            "שם_רחוב": "אלד'הב"
        },
        {
            "_id": 13512,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 357,
            "שם_רחוב": "אלדוקטור"
        },
        {
            "_id": 13513,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אלדוראק"
        },
        {
            "_id": 13514,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 322,
            "שם_רחוב": "אלהאדי"
        },
        {
            "_id": 13515,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 336,
            "שם_רחוב": "אלואחד"
        },
        {
            "_id": 13516,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 333,
            "שם_רחוב": "אלואלי"
        },
        {
            "_id": 13517,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6760,
            "שם_רחוב": "אלוונסא"
        },
        {
            "_id": 13518,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלוורוד"
        },
        {
            "_id": 13519,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 441,
            "שם_רחוב": "אלותר"
        },
        {
            "_id": 13520,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלזהור"
        },
        {
            "_id": 13521,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 13522,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 474,
            "שם_רחוב": "אלזכאה"
        },
        {
            "_id": 13523,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 284,
            "שם_רחוב": "אלזנבק"
        },
        {
            "_id": 13524,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 394,
            "שם_רחוב": "אלזעפראן"
        },
        {
            "_id": 13525,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 400,
            "שם_רחוב": "אלזערור"
        },
        {
            "_id": 13526,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 291,
            "שם_רחוב": "אלזעתר"
        },
        {
            "_id": 13527,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 267,
            "שם_רחוב": "אלח'דמה"
        },
        {
            "_id": 13528,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אלח'ואלנד"
        },
        {
            "_id": 13529,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלח'לואת"
        },
        {
            "_id": 13530,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 472,
            "שם_רחוב": "אלחג'"
        },
        {
            "_id": 13531,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 348,
            "שם_רחוב": "אלחדאד"
        },
        {
            "_id": 13532,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 307,
            "שם_רחוב": "אלחואקיר"
        },
        {
            "_id": 13533,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אלחורש"
        },
        {
            "_id": 13534,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 381,
            "שם_רחוב": "אלחזורי"
        },
        {
            "_id": 13535,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אלחכם"
        },
        {
            "_id": 13536,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 376,
            "שם_רחוב": "אלחלאק'"
        },
        {
            "_id": 13537,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 326,
            "שם_רחוב": "אלחלים"
        },
        {
            "_id": 13538,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 258,
            "שם_רחוב": "אלחמאם"
        },
        {
            "_id": 13539,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 324,
            "שם_רחוב": "אלחמיד"
        },
        {
            "_id": 13540,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 259,
            "שם_רחוב": "אלחסון"
        },
        {
            "_id": 13541,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 356,
            "שם_רחוב": "אלטאלב"
        },
        {
            "_id": 13542,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 255,
            "שם_רחוב": "אלטוליפ"
        },
        {
            "_id": 13543,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 13544,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 283,
            "שם_רחוב": "אליאקות"
        },
        {
            "_id": 13545,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 365,
            "שם_רחוב": "אליעפורי"
        },
        {
            "_id": 13546,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 444,
            "שם_רחוב": "אלכאתב"
        },
        {
            "_id": 13547,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אלכביר"
        },
        {
            "_id": 13548,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 492,
            "שם_רחוב": "אלכוות'ר"
        },
        {
            "_id": 13549,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 323,
            "שם_רחוב": "אלכרים"
        },
        {
            "_id": 13550,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 406,
            "שם_רחוב": "אללביב"
        },
        {
            "_id": 13551,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אללוז"
        },
        {
            "_id": 13552,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 289,
            "שם_רחוב": "אלליימון"
        },
        {
            "_id": 13553,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 404,
            "שם_רחוב": "אלמאס"
        },
        {
            "_id": 13554,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 495,
            "שם_רחוב": "אלמג'ד"
        },
        {
            "_id": 13555,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 358,
            "שם_רחוב": "אלמודיר"
        },
        {
            "_id": 13556,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 379,
            "שם_רחוב": "אלמוהנדס"
        },
        {
            "_id": 13557,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלמוח'טארה"
        },
        {
            "_id": 13558,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 390,
            "שם_רחוב": "אלמוחאמי"
        },
        {
            "_id": 13559,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 269,
            "שם_רחוב": "אלמוסאוא"
        },
        {
            "_id": 13560,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 392,
            "שם_רחוב": "אלמועלם"
        },
        {
            "_id": 13561,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 318,
            "שם_רחוב": "אלמושתרי"
        },
        {
            "_id": 13562,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אלמותנבי"
        },
        {
            "_id": 13563,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 408,
            "שם_רחוב": "אלמזרעה"
        },
        {
            "_id": 13564,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 278,
            "שם_רחוב": "אלמחבה"
        },
        {
            "_id": 13565,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 302,
            "שם_רחוב": "אלמחפרה"
        },
        {
            "_id": 13566,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 453,
            "שם_רחוב": "אלמיזאן"
        },
        {
            "_id": 13567,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 332,
            "שם_רחוב": "אלמלק"
        },
        {
            "_id": 13568,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 435,
            "שם_רחוב": "אלמנארה"
        },
        {
            "_id": 13569,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלמנזול"
        },
        {
            "_id": 13570,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 285,
            "שם_רחוב": "אלמסק"
        },
        {
            "_id": 13571,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 476,
            "שם_רחוב": "אלמעראג'"
        },
        {
            "_id": 13572,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אלמערי"
        },
        {
            "_id": 13573,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 317,
            "שם_רחוב": "אלמריך"
        },
        {
            "_id": 13574,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 241,
            "שם_רחוב": "אלמשמש"
        },
        {
            "_id": 13575,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 194,
            "שם_רחוב": "אלמתן"
        },
        {
            "_id": 13576,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 439,
            "שם_רחוב": "אלנאי"
        },
        {
            "_id": 13577,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 342,
            "שם_רחוב": "אלנג'אר"
        },
        {
            "_id": 13578,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 383,
            "שם_רחוב": "אלנורס"
        },
        {
            "_id": 13579,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אלנזאזה"
        },
        {
            "_id": 13580,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 274,
            "שם_רחוב": "אלנח'ווה"
        },
        {
            "_id": 13581,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 402,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 13582,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 395,
            "שם_רחוב": "אלנחל"
        },
        {
            "_id": 13583,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 399,
            "שם_רחוב": "אלנענע"
        },
        {
            "_id": 13584,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אלנעסה"
        },
        {
            "_id": 13585,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 271,
            "שם_רחוב": "אלנק'אב"
        },
        {
            "_id": 13586,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 13587,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 275,
            "שם_רחוב": "אלסבאח"
        },
        {
            "_id": 13588,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 398,
            "שם_רחוב": "אלסבאר"
        },
        {
            "_id": 13589,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 309,
            "שם_רחוב": "אלסבור"
        },
        {
            "_id": 13590,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 361,
            "שם_רחוב": "אלסדאקה"
        },
        {
            "_id": 13591,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלסואניה"
        },
        {
            "_id": 13592,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 195,
            "שם_רחוב": "אלסווידה"
        },
        {
            "_id": 13593,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 193,
            "שם_רחוב": "אלסומאק"
        },
        {
            "_id": 13594,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלסוסן"
        },
        {
            "_id": 13595,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 483,
            "שם_רחוב": "אלסופייה"
        },
        {
            "_id": 13596,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 403,
            "שם_רחוב": "אלסופסאף"
        },
        {
            "_id": 13597,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 244,
            "שם_רחוב": "אלסופרג'ל"
        },
        {
            "_id": 13598,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 380,
            "שם_רחוב": "אלסחאפי"
        },
        {
            "_id": 13599,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אלסטח"
        },
        {
            "_id": 13600,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 411,
            "שם_רחוב": "אלסיאד"
        },
        {
            "_id": 13601,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 377,
            "שם_רחוב": "אלסיידלי"
        },
        {
            "_id": 13602,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 330,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 13603,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלסנדיאן"
        },
        {
            "_id": 13604,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 300,
            "שם_רחוב": "אלסנובר"
        },
        {
            "_id": 13605,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 238,
            "שם_רחוב": "אלסקר"
        },
        {
            "_id": 13606,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 450,
            "שם_רחוב": "אלסראט"
        },
        {
            "_id": 13607,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 260,
            "שם_רחוב": "אלסרו"
        },
        {
            "_id": 13608,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אלסריס"
        },
        {
            "_id": 13609,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 448,
            "שם_רחוב": "אלסת ח'ד'רה"
        },
        {
            "_id": 13610,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 447,
            "שם_רחוב": "אלסת סארה"
        },
        {
            "_id": 13611,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלע'אר"
        },
        {
            "_id": 13612,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 236,
            "שם_רחוב": "אלע'זאל"
        },
        {
            "_id": 13613,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 375,
            "שם_רחוב": "אלע'יידה"
        },
        {
            "_id": 13614,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 327,
            "שם_רחוב": "אלעדל"
        },
        {
            "_id": 13615,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 436,
            "שם_רחוב": "אלעוד"
        },
        {
            "_id": 13616,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 497,
            "שם_רחוב": "אלעזה"
        },
        {
            "_id": 13617,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 313,
            "שם_רחוב": "אלעזיז"
        },
        {
            "_id": 13618,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 281,
            "שם_רחוב": "אלעטור"
        },
        {
            "_id": 13619,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אלעלים"
        },
        {
            "_id": 13620,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 409,
            "שם_רחוב": "אלעמאמה"
        },
        {
            "_id": 13621,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אלענאב"
        },
        {
            "_id": 13622,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 263,
            "שם_רחוב": "אלענב"
        },
        {
            "_id": 13623,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 282,
            "שם_רחוב": "אלענבר"
        },
        {
            "_id": 13624,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 488,
            "שם_רחוב": "אלפארס"
        },
        {
            "_id": 13625,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 237,
            "שם_רחוב": "אלפהד"
        },
        {
            "_id": 13626,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אלפודה"
        },
        {
            "_id": 13627,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 442,
            "שם_רחוב": "אלפיאנו"
        },
        {
            "_id": 13628,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 443,
            "שם_רחוב": "אלפנון"
        },
        {
            "_id": 13629,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 490,
            "שם_רחוב": "אלפרדוס"
        },
        {
            "_id": 13630,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 280,
            "שם_רחוב": "אלק'דאא"
        },
        {
            "_id": 13631,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 382,
            "שם_רחוב": "אלק'נדול"
        },
        {
            "_id": 13632,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 372,
            "שם_רחוב": "אלקאדי"
        },
        {
            "_id": 13633,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 256,
            "שם_רחוב": "אלקורונפול"
        },
        {
            "_id": 13634,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 266,
            "שם_רחוב": "אלקטלב"
        },
        {
            "_id": 13635,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 438,
            "שם_רחוב": "אלקמאן"
        },
        {
            "_id": 13636,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 257,
            "שם_רחוב": "אלקרואן"
        },
        {
            "_id": 13637,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 243,
            "שם_רחוב": "אלקרז"
        },
        {
            "_id": 13638,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 496,
            "שם_רחוב": "אלראוודה"
        },
        {
            "_id": 13639,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 440,
            "שם_רחוב": "אלרבאבה"
        },
        {
            "_id": 13640,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אלרוחראח"
        },
        {
            "_id": 13641,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 264,
            "שם_רחוב": "אלרומאן"
        },
        {
            "_id": 13642,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 363,
            "שם_רחוב": "אלרוע'ב"
        },
        {
            "_id": 13643,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 329,
            "שם_רחוב": "אלרזאק"
        },
        {
            "_id": 13644,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אלרחים"
        },
        {
            "_id": 13645,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 491,
            "שם_רחוב": "אלרחיק"
        },
        {
            "_id": 13646,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 331,
            "שם_רחוב": "אלרחמאן"
        },
        {
            "_id": 13647,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 407,
            "שם_רחוב": "אלריאד"
        },
        {
            "_id": 13648,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 13649,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלרנדה"
        },
        {
            "_id": 13650,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 451,
            "שם_רחוב": "אלרסאלה"
        },
        {
            "_id": 13651,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 446,
            "שם_רחוב": "אלשאער"
        },
        {
            "_id": 13652,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 235,
            "שם_רחוב": "אלשברק"
        },
        {
            "_id": 13653,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אלשוף"
        },
        {
            "_id": 13654,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אלשיח' אלפאדל"
        },
        {
            "_id": 13655,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 420,
            "שם_רחוב": "אלשיך חמוד"
        },
        {
            "_id": 13656,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 458,
            "שם_רחוב": "אלשלאלה"
        },
        {
            "_id": 13657,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 234,
            "שם_רחוב": "אלתופאח"
        },
        {
            "_id": 13658,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 401,
            "שם_רחוב": "אלתות"
        },
        {
            "_id": 13659,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 262,
            "שם_רחוב": "אלתין"
        },
        {
            "_id": 13660,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 410,
            "שם_רחוב": "אלתלמיד'"
        },
        {
            "_id": 13661,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 288,
            "שם_רחוב": "אלתמר"
        },
        {
            "_id": 13662,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 270,
            "שם_רחוב": "אלתסאמח"
        },
        {
            "_id": 13663,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 273,
            "שם_רחוב": "אלתעאוון"
        },
        {
            "_id": 13664,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 454,
            "שם_רחוב": "אלתעיוש"
        },
        {
            "_id": 13665,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 343,
            "שם_רחוב": "אמסטרדם"
        },
        {
            "_id": 13666,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אסטנבול"
        },
        {
            "_id": 13667,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 180,
            "שם_רחוב": "אפלטון"
        },
        {
            "_id": 13668,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אריסטו"
        },
        {
            "_id": 13669,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 292,
            "שם_רחוב": "אשדוד"
        },
        {
            "_id": 13670,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אתונה"
        },
        {
            "_id": 13671,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 182,
            "שם_רחוב": "באב אלהווא"
        },
        {
            "_id": 13672,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 293,
            "שם_רחוב": "באר שבע"
        },
        {
            "_id": 13673,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 369,
            "שם_רחוב": "בהאא אלדין"
        },
        {
            "_id": 13674,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 350,
            "שם_רחוב": "בודפשט"
        },
        {
            "_id": 13675,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 247,
            "שם_רחוב": "בייג'ין"
        },
        {
            "_id": 13676,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 434,
            "שם_רחוב": "ביילסאן"
        },
        {
            "_id": 13677,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 254,
            "שם_רחוב": "ביירות"
        },
        {
            "_id": 13678,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 460,
            "שם_רחוב": "ביל גייטס"
        },
        {
            "_id": 13679,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 164,
            "שם_רחוב": "ביר דובל"
        },
        {
            "_id": 13680,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 479,
            "שם_רחוב": "בית אלדין"
        },
        {
            "_id": 13681,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 478,
            "שם_רחוב": "בנו מערוף"
        },
        {
            "_id": 13682,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 349,
            "שם_רחוב": "בריסל"
        },
        {
            "_id": 13683,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 340,
            "שם_רחוב": "ברלין"
        },
        {
            "_id": 13684,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ג'בל אלדרוז"
        },
        {
            "_id": 13685,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 430,
            "שם_רחוב": "ג'ולנאר"
        },
        {
            "_id": 13686,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 463,
            "שם_רחוב": "ג'ון לנון"
        },
        {
            "_id": 13687,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 467,
            "שם_רחוב": "גלילאו"
        },
        {
            "_id": 13688,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 414,
            "שם_רחוב": "גנדי"
        },
        {
            "_id": 13689,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6771,
            "שם_רחוב": "גרעין הכפר הישן"
        },
        {
            "_id": 13690,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 168,
            "שם_רחוב": "ד'הרת אלביר"
        },
        {
            "_id": 13691,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דאלית אל-כרמל"
        },
        {
            "_id": 13692,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 249,
            "שם_רחוב": "דבלין"
        },
        {
            "_id": 13693,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 421,
            "שם_רחוב": "דה וינצ'י"
        },
        {
            "_id": 13694,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 387,
            "שם_רחוב": "דימונה"
        },
        {
            "_id": 13695,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 480,
            "שם_רחוב": "דיר אלקמר"
        },
        {
            "_id": 13696,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 134,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 13697,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 468,
            "שם_רחוב": "הרמבם"
        },
        {
            "_id": 13698,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 385,
            "שם_רחוב": "הרצליה"
        },
        {
            "_id": 13699,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6758,
            "שם_רחוב": "השכונה המזרחית"
        },
        {
            "_id": 13700,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 156,
            "שם_רחוב": "ואדי אלפש"
        },
        {
            "_id": 13701,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 216,
            "שם_רחוב": "ואדי אלתים"
        },
        {
            "_id": 13702,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 347,
            "שם_רחוב": "ואדי עיסא"
        },
        {
            "_id": 13703,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 308,
            "שם_רחוב": "וינה"
        },
        {
            "_id": 13704,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 306,
            "שם_רחוב": "ורשה"
        },
        {
            "_id": 13705,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 320,
            "שם_רחוב": "זוחל"
        },
        {
            "_id": 13706,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 364,
            "שם_רחוב": "זקריא"
        },
        {
            "_id": 13707,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 304,
            "שם_רחוב": "ח'אתם סלימאן"
        },
        {
            "_id": 13708,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 388,
            "שם_רחוב": "ח'לת אלאעואר"
        },
        {
            "_id": 13709,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 178,
            "שם_רחוב": "ח'לת אלאעור"
        },
        {
            "_id": 13710,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 163,
            "שם_רחוב": "ח'לת אלג'מל"
        },
        {
            "_id": 13711,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 223,
            "שם_רחוב": "ח'לת אלקבלייה"
        },
        {
            "_id": 13712,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 177,
            "שם_רחוב": "ח'לת חמזה"
        },
        {
            "_id": 13713,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 167,
            "שם_רחוב": "ח'לת עלי"
        },
        {
            "_id": 13714,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 198,
            "שם_רחוב": "חאצביא"
        },
        {
            "_id": 13715,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 373,
            "שם_רחוב": "חולון"
        },
        {
            "_id": 13716,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 368,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 13717,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6751,
            "שם_רחוב": "חיילים משוחררים"
        },
        {
            "_id": 13718,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 250,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 13719,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "חלב"
        },
        {
            "_id": 13720,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 296,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 13721,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 469,
            "שם_רחוב": "טוליב"
        },
        {
            "_id": 13722,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 248,
            "שם_רחוב": "טוקיו"
        },
        {
            "_id": 13723,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 221,
            "שם_רחוב": "יד לבנים"
        },
        {
            "_id": 13724,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 360,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 13725,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 297,
            "שם_רחוב": "יפו"
        },
        {
            "_id": 13726,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 473,
            "שם_רחוב": "יצחק רבין"
        },
        {
            "_id": 13727,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 353,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 13728,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "כיכר 1"
        },
        {
            "_id": 13729,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "כיכר 2"
        },
        {
            "_id": 13730,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "כיכר 3"
        },
        {
            "_id": 13731,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "כיכר 4"
        },
        {
            "_id": 13732,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "כיכר 5"
        },
        {
            "_id": 13733,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "כיכר 6"
        },
        {
            "_id": 13734,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר דואר אלח'רבה"
        },
        {
            "_id": 13735,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 128,
            "שם_רחוב": "כמאל ג'ונבלאט"
        },
        {
            "_id": 13736,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 13737,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 352,
            "שם_רחוב": "לונדון"
        },
        {
            "_id": 13738,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 425,
            "שם_רחוב": "לינקולן"
        },
        {
            "_id": 13739,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 393,
            "שם_רחוב": "ליסבון"
        },
        {
            "_id": 13740,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 230,
            "שם_רחוב": "מדריד"
        },
        {
            "_id": 13741,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 345,
            "שם_רחוב": "מוסקוה"
        },
        {
            "_id": 13742,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 417,
            "שם_רחוב": "מחמד עלי"
        },
        {
            "_id": 13743,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 214,
            "שם_רחוב": "מלאת אלריש"
        },
        {
            "_id": 13744,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 415,
            "שם_רחוב": "מנדלה"
        },
        {
            "_id": 13745,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 423,
            "שם_רחוב": "מנחם בגין"
        },
        {
            "_id": 13746,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 301,
            "שם_רחוב": "מרג' אלנג'אס"
        },
        {
            "_id": 13747,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6770,
            "שם_רחוב": "מרכז הכפר"
        },
        {
            "_id": 13748,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 207,
            "שם_רחוב": "משה דיין"
        },
        {
            "_id": 13749,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 157,
            "שם_רחוב": "נג'מת אלסובח"
        },
        {
            "_id": 13750,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 277,
            "שם_רחוב": "נג'מת דוד"
        },
        {
            "_id": 13751,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 286,
            "שם_רחוב": "נהריה"
        },
        {
            "_id": 13752,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 229,
            "שם_רחוב": "נזזת אלע'יידא"
        },
        {
            "_id": 13753,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 251,
            "שם_רחוב": "ניו דלהי"
        },
        {
            "_id": 13754,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 354,
            "שם_רחוב": "ניו יורק"
        },
        {
            "_id": 13755,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 428,
            "שם_רחוב": "ניוטון"
        },
        {
            "_id": 13756,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 481,
            "שם_רחוב": "ניחא"
        },
        {
            "_id": 13757,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 321,
            "שם_רחוב": "נפטון"
        },
        {
            "_id": 13758,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 185,
            "שם_רחוב": "נקארה"
        },
        {
            "_id": 13759,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 432,
            "שם_רחוב": "נרדין"
        },
        {
            "_id": 13760,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 433,
            "שם_רחוב": "נרמין"
        },
        {
            "_id": 13761,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 366,
            "שם_רחוב": "סבלאן"
        },
        {
            "_id": 13762,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "סולטאן באשא אלאטרש"
        },
        {
            "_id": 13763,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 303,
            "שם_רחוב": "סופיה"
        },
        {
            "_id": 13764,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 210,
            "שם_רחוב": "סוקרטס"
        },
        {
            "_id": 13765,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 465,
            "שם_רחוב": "סטיב ג'ובס"
        },
        {
            "_id": 13766,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 374,
            "שם_רחוב": "סידנא אליא"
        },
        {
            "_id": 13767,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 371,
            "שם_רחוב": "סידנא שמס"
        },
        {
            "_id": 13768,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 183,
            "שם_רחוב": "סיח אלח'טיב"
        },
        {
            "_id": 13769,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 141,
            "שם_רחוב": "סמ 2"
        },
        {
            "_id": 13770,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 142,
            "שם_רחוב": "סמ 6"
        },
        {
            "_id": 13771,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 470,
            "שם_רחוב": "סמאר"
        },
        {
            "_id": 13772,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 206,
            "שם_רחוב": "סמטת אלביאדר"
        },
        {
            "_id": 13773,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 315,
            "שם_רחוב": "עוטארד"
        },
        {
            "_id": 13774,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 424,
            "שם_רחוב": "עזר וייצמן"
        },
        {
            "_id": 13775,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 124,
            "שם_רחוב": "עין אלקבליה"
        },
        {
            "_id": 13776,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 160,
            "שם_רחוב": "עין אלשמאליה"
        },
        {
            "_id": 13777,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 298,
            "שם_רחוב": "עכו"
        },
        {
            "_id": 13778,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 339,
            "שם_רחוב": "עמאן"
        },
        {
            "_id": 13779,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 294,
            "שם_רחוב": "ערד"
        },
        {
            "_id": 13780,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 190,
            "שם_רחוב": "עש אלע'וראב"
        },
        {
            "_id": 13781,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 143,
            "שם_רחוב": "פח'ר אלדין אלמעני"
        },
        {
            "_id": 13782,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 437,
            "שם_רחוב": "פיירוז"
        },
        {
            "_id": 13783,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 418,
            "שם_רחוב": "פיקאסו"
        },
        {
            "_id": 13784,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 189,
            "שם_רחוב": "פיתאע'ורס"
        },
        {
            "_id": 13785,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 466,
            "שם_רחוב": "פלה"
        },
        {
            "_id": 13786,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 337,
            "שם_רחוב": "פלוטו"
        },
        {
            "_id": 13787,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6753,
            "שם_רחוב": "פנינת הכרמל"
        },
        {
            "_id": 13788,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 378,
            "שם_רחוב": "פראג"
        },
        {
            "_id": 13789,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 426,
            "שם_רחוב": "פרויד"
        },
        {
            "_id": 13790,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 355,
            "שם_רחוב": "פריס"
        },
        {
            "_id": 13791,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 464,
            "שם_רחוב": "צ'פלין"
        },
        {
            "_id": 13792,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 422,
            "שם_רחוב": "צ'רצ'יל"
        },
        {
            "_id": 13793,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6754,
            "שם_רחוב": "צומת המוחרקה"
        },
        {
            "_id": 13794,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 412,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 13795,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 341,
            "שם_רחוב": "קהיר"
        },
        {
            "_id": 13796,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 295,
            "שם_רחוב": "קיסריה"
        },
        {
            "_id": 13797,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 416,
            "שם_רחוב": "קנדי"
        },
        {
            "_id": 13798,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 217,
            "שם_רחוב": "רבאע עודה"
        },
        {
            "_id": 13799,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רבעת אלג'וזה"
        },
        {
            "_id": 13800,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 431,
            "שם_רחוב": "רוז"
        },
        {
            "_id": 13801,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 344,
            "שם_רחוב": "רומא"
        },
        {
            "_id": 13802,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 459,
            "שם_רחוב": "רוקיטייה"
        },
        {
            "_id": 13803,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רח 20"
        },
        {
            "_id": 13804,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רח 30"
        },
        {
            "_id": 13805,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 127,
            "שם_רחוב": "רח 11"
        },
        {
            "_id": 13806,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 136,
            "שם_רחוב": "רח 31"
        },
        {
            "_id": 13807,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 131,
            "שם_רחוב": "רח 12"
        },
        {
            "_id": 13808,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רח 22"
        },
        {
            "_id": 13809,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 137,
            "שם_רחוב": "רח 32"
        },
        {
            "_id": 13810,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 139,
            "שם_רחוב": "רח 33"
        },
        {
            "_id": 13811,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רח 34"
        },
        {
            "_id": 13812,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 133,
            "שם_רחוב": "רח 5"
        },
        {
            "_id": 13813,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 135,
            "שם_רחוב": "רח 15"
        },
        {
            "_id": 13814,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח 35"
        },
        {
            "_id": 13815,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח 16"
        },
        {
            "_id": 13816,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח 26"
        },
        {
            "_id": 13817,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 130,
            "שם_רחוב": "רח 36"
        },
        {
            "_id": 13818,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רח 17"
        },
        {
            "_id": 13819,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רח 27"
        },
        {
            "_id": 13820,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רח 18"
        },
        {
            "_id": 13821,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רח 28"
        },
        {
            "_id": 13822,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 126,
            "שם_רחוב": "רח 19"
        },
        {
            "_id": 13823,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רח 29"
        },
        {
            "_id": 13824,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 287,
            "שם_רחוב": "רחובות"
        },
        {
            "_id": 13825,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "ש יד לבנים"
        },
        {
            "_id": 13826,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 461,
            "שם_רחוב": "שאגאל"
        },
        {
            "_id": 13827,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שוק אבו ענתר"
        },
        {
            "_id": 13828,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 391,
            "שם_רחוב": "שטוקהולם"
        },
        {
            "_id": 13829,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שיח' אמין טריף"
        },
        {
            "_id": 13830,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 239,
            "שם_רחוב": "שייח חסין אל עאבד"
        },
        {
            "_id": 13831,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6750,
            "שם_רחוב": "שיכונאת"
        },
        {
            "_id": 13832,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6795,
            "שם_רחוב": "שכ אלבסאתין"
        },
        {
            "_id": 13833,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ אלוורוד"
        },
        {
            "_id": 13834,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6792,
            "שם_רחוב": "שכ אלח'רבה"
        },
        {
            "_id": 13835,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6780,
            "שם_רחוב": "שכ אלקשקוש"
        },
        {
            "_id": 13836,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6781,
            "שם_רחוב": "שכ אלרנדה"
        },
        {
            "_id": 13837,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6762,
            "שם_רחוב": "שכ אסואניה"
        },
        {
            "_id": 13838,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6790,
            "שם_רחוב": "שכ ביר דוביל"
        },
        {
            "_id": 13839,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6752,
            "שם_רחוב": "שכ ואדי אלפש"
        },
        {
            "_id": 13840,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6782,
            "שם_רחוב": "שכ ח'לת אלג'מל"
        },
        {
            "_id": 13841,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6791,
            "שם_רחוב": "שכ ח'לת נצאר"
        },
        {
            "_id": 13842,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6793,
            "שם_רחוב": "שכ ח'לת עלי"
        },
        {
            "_id": 13843,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6794,
            "שם_רחוב": "שכ ט'הרת אלביר"
        },
        {
            "_id": 13844,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6765,
            "שם_רחוב": "שכ מרג' אלנג'אס"
        },
        {
            "_id": 13845,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ נג'מת אלצבאח"
        },
        {
            "_id": 13846,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6764,
            "שם_רחוב": "שכ עין אלשמאליה"
        },
        {
            "_id": 13847,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 6757,
            "שם_רחוב": "שכונת המוסלמים"
        },
        {
            "_id": 13848,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 462,
            "שם_רחוב": "שמעון פרס"
        },
        {
            "_id": 13849,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 228,
            "שם_רחוב": "שקיב ארסלאן"
        },
        {
            "_id": 13850,
            "סמל_ישוב": 494,
            "שם_ישוב": "דאלית אל-כרמל ",
            "סמל_רחוב": 299,
            "שם_רחוב": "תל אביב"
        },
        {
            "_id": 13851,
            "סמל_ישוב": 146,
            "שם_ישוב": "דבורה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דבורה"
        },
        {
            "_id": 13852,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "אבו בכר אלצדיק"
        },
        {
            "_id": 13853,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אבו ספיאן"
        },
        {
            "_id": 13854,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אבו עובידה"
        },
        {
            "_id": 13855,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אבו רומאנה"
        },
        {
            "_id": 13856,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אבן אלהית'ם"
        },
        {
            "_id": 13857,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אבן ג'וביר"
        },
        {
            "_id": 13858,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 13859,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 13860,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אכסאל"
        },
        {
            "_id": 13861,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אל אעמאר"
        },
        {
            "_id": 13862,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אל באבור"
        },
        {
            "_id": 13863,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אל ד'אהר"
        },
        {
            "_id": 13864,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אל ואד"
        },
        {
            "_id": 13865,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אל ח'יר"
        },
        {
            "_id": 13866,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אל חסן"
        },
        {
            "_id": 13867,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אל נאפורה"
        },
        {
            "_id": 13868,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "אלאדריסי"
        },
        {
            "_id": 13869,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "אלאח'וה"
        },
        {
            "_id": 13870,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלאחראש"
        },
        {
            "_id": 13871,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אלאמין"
        },
        {
            "_id": 13872,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 13873,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלאנדלס"
        },
        {
            "_id": 13874,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אלבארוני"
        },
        {
            "_id": 13875,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלבוח'ארי"
        },
        {
            "_id": 13876,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "אלבוסתאן"
        },
        {
            "_id": 13877,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלבידר"
        },
        {
            "_id": 13878,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלברקוק"
        },
        {
            "_id": 13879,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלג'ורה"
        },
        {
            "_id": 13880,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אלג'ורי"
        },
        {
            "_id": 13881,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלד'ראע"
        },
        {
            "_id": 13882,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אלדבה"
        },
        {
            "_id": 13883,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלדיר"
        },
        {
            "_id": 13884,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלהודא"
        },
        {
            "_id": 13885,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אלואחדה"
        },
        {
            "_id": 13886,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 100,
            "שם_רחוב": "אלזהראא"
        },
        {
            "_id": 13887,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלזיריקה"
        },
        {
            "_id": 13888,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 13889,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלזנבק"
        },
        {
            "_id": 13890,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלזעבליה"
        },
        {
            "_id": 13891,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אלח'ואריזמי"
        },
        {
            "_id": 13892,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 13893,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אלחדר"
        },
        {
            "_id": 13894,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אלחכים"
        },
        {
            "_id": 13895,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלטור"
        },
        {
            "_id": 13896,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 13897,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אליעקובי"
        },
        {
            "_id": 13898,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אלכינא"
        },
        {
            "_id": 13899,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלכנדי"
        },
        {
            "_id": 13900,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלכרים"
        },
        {
            "_id": 13901,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אלמאלח"
        },
        {
            "_id": 13902,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אלמג'יד"
        },
        {
            "_id": 13903,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלמג'נונה"
        },
        {
            "_id": 13904,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אלמחבה"
        },
        {
            "_id": 13905,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלמלעב"
        },
        {
            "_id": 13906,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלמסנסל"
        },
        {
            "_id": 13907,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אלמסעודי"
        },
        {
            "_id": 13908,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלמסרארה"
        },
        {
            "_id": 13909,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אלמעאסר"
        },
        {
            "_id": 13910,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלמראח"
        },
        {
            "_id": 13911,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלמרעוין"
        },
        {
            "_id": 13912,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלמרשקה"
        },
        {
            "_id": 13913,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלמתנבי"
        },
        {
            "_id": 13914,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אלנג'ארה"
        },
        {
            "_id": 13915,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלנוארס"
        },
        {
            "_id": 13916,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 13917,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אלנעים"
        },
        {
            "_id": 13918,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אלסאוסן"
        },
        {
            "_id": 13919,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלסאלח"
        },
        {
            "_id": 13920,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלסאפח"
        },
        {
            "_id": 13921,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "אלסביל"
        },
        {
            "_id": 13922,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלסהל"
        },
        {
            "_id": 13923,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלסעדי"
        },
        {
            "_id": 13924,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלסעדיה"
        },
        {
            "_id": 13925,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אלספליה"
        },
        {
            "_id": 13926,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלסרוו"
        },
        {
            "_id": 13927,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלעדל"
        },
        {
            "_id": 13928,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלעוג'ה"
        },
        {
            "_id": 13929,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלעטור"
        },
        {
            "_id": 13930,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 13931,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלעלאמאת"
        },
        {
            "_id": 13932,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אלעמארה"
        },
        {
            "_id": 13933,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלערקדה"
        },
        {
            "_id": 13934,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אלפאהום"
        },
        {
            "_id": 13935,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אלפארס"
        },
        {
            "_id": 13936,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אלפראבי"
        },
        {
            "_id": 13937,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אלפשארה"
        },
        {
            "_id": 13938,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "אלראודה"
        },
        {
            "_id": 13939,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אלראזי"
        },
        {
            "_id": 13940,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אלראס"
        },
        {
            "_id": 13941,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלראפידין"
        },
        {
            "_id": 13942,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אלרוסל"
        },
        {
            "_id": 13943,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אלרח'ם"
        },
        {
            "_id": 13944,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אלרידא"
        },
        {
            "_id": 13945,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "אלרשיד"
        },
        {
            "_id": 13946,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "אלשאפיעי"
        },
        {
            "_id": 13947,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "אלשהד"
        },
        {
            "_id": 13948,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלשראר"
        },
        {
            "_id": 13949,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אלתלה"
        },
        {
            "_id": 13950,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "אלתסאמוח"
        },
        {
            "_id": 13951,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלתעאון"
        },
        {
            "_id": 13952,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "ביר אלחסניאת"
        },
        {
            "_id": 13953,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "בלאל"
        },
        {
            "_id": 13954,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "ג'רש"
        },
        {
            "_id": 13955,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "דבורה"
        },
        {
            "_id": 13956,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דבוריה"
        },
        {
            "_id": 13957,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "ואדי אלדן"
        },
        {
            "_id": 13958,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "חוג'אג'"
        },
        {
            "_id": 13959,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "חיטין"
        },
        {
            "_id": 13960,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "מראח אלסריס"
        },
        {
            "_id": 13961,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "סלאח אלדין"
        },
        {
            "_id": 13962,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "עומר בן אלח'טאב"
        },
        {
            "_id": 13963,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "עין מאהל"
        },
        {
            "_id": 13964,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 13965,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "עתמאן בן עפאן"
        },
        {
            "_id": 13966,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "שיעין"
        },
        {
            "_id": 13967,
            "סמל_ישוב": 489,
            "שם_ישוב": "דבוריה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "שרחביל"
        },
        {
            "_id": 13968,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אגס"
        },
        {
            "_id": 13969,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 13970,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אירית"
        },
        {
            "_id": 13971,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 13972,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 13973,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 13974,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 144,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 13975,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 148,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 13976,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דביר"
        },
        {
            "_id": 13977,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 13978,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 13979,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך דולב"
        },
        {
            "_id": 13980,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך המייסדים"
        },
        {
            "_id": 13981,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 147,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 13982,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 142,
            "שם_רחוב": "זית"
        },
        {
            "_id": 13983,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 13984,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "חלמונית"
        },
        {
            "_id": 13985,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "חסידה"
        },
        {
            "_id": 13986,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 139,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 13987,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 13988,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 131,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 13989,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "יסעור"
        },
        {
            "_id": 13990,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "כחל"
        },
        {
            "_id": 13991,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 145,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 13992,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 138,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 13993,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 134,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 13994,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 13995,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 140,
            "שם_רחוב": "לוע הארי"
        },
        {
            "_id": 13996,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 136,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 13997,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "מגלן"
        },
        {
            "_id": 13998,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "נופר"
        },
        {
            "_id": 13999,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 129,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 14000,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 14001,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 14002,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 133,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 14003,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "סיקסק"
        },
        {
            "_id": 14004,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "עגור"
        },
        {
            "_id": 14005,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "פרג"
        },
        {
            "_id": 14006,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 137,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 14007,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 14008,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 14009,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 143,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 14010,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 14011,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שביל הגרעינים"
        },
        {
            "_id": 14012,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "שביל הסיפורים"
        },
        {
            "_id": 14013,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 126,
            "שם_רחוב": "שושן"
        },
        {
            "_id": 14014,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 14015,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 150,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 14016,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 141,
            "שם_רחוב": "שקמה"
        },
        {
            "_id": 14017,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 128,
            "שם_רחוב": "תורמוס"
        },
        {
            "_id": 14018,
            "סמל_ישוב": 849,
            "שם_ישוב": "דביר ",
            "סמל_רחוב": 149,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 14019,
            "סמל_ישוב": 407,
            "שם_ישוב": "דברת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דברת"
        },
        {
            "_id": 14020,
            "סמל_ישוב": 62,
            "שם_ישוב": "דגניה א' ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דגניה א'"
        },
        {
            "_id": 14021,
            "סמל_ישוב": 79,
            "שם_ישוב": "דגניה ב' ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דגניה ב'"
        },
        {
            "_id": 14022,
            "סמל_ישוב": 1067,
            "שם_ישוב": "דובב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דובב"
        },
        {
            "_id": 14023,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 14024,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 100,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 14025,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הראיה"
        },
        {
            "_id": 14026,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הרב גורן"
        },
        {
            "_id": 14027,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הרב ישראלי"
        },
        {
            "_id": 14028,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרב לוין"
        },
        {
            "_id": 14029,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הרב מרדכי אליהו"
        },
        {
            "_id": 14030,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרב נריה"
        },
        {
            "_id": 14031,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 14032,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 107,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 14033,
            "סמל_ישוב": 3747,
            "שם_ישוב": "דולב ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מעלה הכרמים"
        },
        {
            "_id": 14034,
            "סמל_ישוב": 738,
            "שם_ישוב": "דור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דור"
        },
        {
            "_id": 14035,
            "סמל_ישוב": 336,
            "שם_ישוב": "דורות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דורות"
        },
        {
            "_id": 14036,
            "סמל_ישוב": 475,
            "שם_ישוב": "דחי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דחי"
        },
        {
            "_id": 14037,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אבו בכר"
        },
        {
            "_id": 14038,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אבו תמאם"
        },
        {
            "_id": 14039,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 14040,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אבן חיאן"
        },
        {
            "_id": 14041,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 14042,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אלאח'טל"
        },
        {
            "_id": 14043,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלאנביאא"
        },
        {
            "_id": 14044,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 14045,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלביארה"
        },
        {
            "_id": 14046,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלבידר"
        },
        {
            "_id": 14047,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלברכה"
        },
        {
            "_id": 14048,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלג'בל"
        },
        {
            "_id": 14049,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אלג'לסה"
        },
        {
            "_id": 14050,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלדיר"
        },
        {
            "_id": 14051,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלדראג'"
        },
        {
            "_id": 14052,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלורוד"
        },
        {
            "_id": 14053,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אלזהור"
        },
        {
            "_id": 14054,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 14055,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלזנבק"
        },
        {
            "_id": 14056,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלזערור"
        },
        {
            "_id": 14057,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלח'אן"
        },
        {
            "_id": 14058,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלח'ד'ר"
        },
        {
            "_id": 14059,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלח'לה"
        },
        {
            "_id": 14060,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 14061,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 14062,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלכרום"
        },
        {
            "_id": 14063,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלכרימה"
        },
        {
            "_id": 14064,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אלכרם אלאחמר"
        },
        {
            "_id": 14065,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלמותנבי"
        },
        {
            "_id": 14066,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלמערי"
        },
        {
            "_id": 14067,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלמראח"
        },
        {
            "_id": 14068,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלנוקעה"
        },
        {
            "_id": 14069,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אלנס'ב אלטויל"
        },
        {
            "_id": 14070,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 14071,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלסבאט"
        },
        {
            "_id": 14072,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלסוכן"
        },
        {
            "_id": 14073,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלעורש"
        },
        {
            "_id": 14074,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 14075,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלעריד'"
        },
        {
            "_id": 14076,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אלקוס'יבה"
        },
        {
            "_id": 14077,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלקלעה"
        },
        {
            "_id": 14078,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלריאף"
        },
        {
            "_id": 14079,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 14080,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלשאפעי"
        },
        {
            "_id": 14081,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלשבאב"
        },
        {
            "_id": 14082,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלשועראא"
        },
        {
            "_id": 14083,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אלשיח' אלאסד"
        },
        {
            "_id": 14084,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלתות"
        },
        {
            "_id": 14085,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 166,
            "שם_רחוב": "בילאל אבן רבאח"
        },
        {
            "_id": 14086,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 120,
            "שם_רחוב": "ג'ורת ברק"
        },
        {
            "_id": 14087,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "גרעין ישן"
        },
        {
            "_id": 14088,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דייר אל-אסד"
        },
        {
            "_id": 14089,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 135,
            "שם_רחוב": "הארון אלרשיד"
        },
        {
            "_id": 14090,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 160,
            "שם_רחוב": "ח'אלד אבן אלוליד"
        },
        {
            "_id": 14091,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 161,
            "שם_רחוב": "טארק אבן זיאד"
        },
        {
            "_id": 14092,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 158,
            "שם_רחוב": "טה חוסין"
        },
        {
            "_id": 14093,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 124,
            "שם_רחוב": "כרם אלזאהר"
        },
        {
            "_id": 14094,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 6724,
            "שם_רחוב": "מגרשים"
        },
        {
            "_id": 14095,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 159,
            "שם_רחוב": "נג'יב מחפוז"
        },
        {
            "_id": 14096,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ס'לאח אלדין"
        },
        {
            "_id": 14097,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 132,
            "שם_רחוב": "עומר בן אלח'טאב"
        },
        {
            "_id": 14098,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 133,
            "שם_רחוב": "עלי אבן אבי טאלב"
        },
        {
            "_id": 14099,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 114,
            "שם_רחוב": "ראס אלחאכורה"
        },
        {
            "_id": 14100,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 149,
            "שם_רחוב": "רבאע בשארה"
        },
        {
            "_id": 14101,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "שכ אלביארה"
        },
        {
            "_id": 14102,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "שכ אלברכה"
        },
        {
            "_id": 14103,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "שכ אלח'דר"
        },
        {
            "_id": 14104,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "שכ אלעין"
        },
        {
            "_id": 14105,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "שכ אלעריד'"
        },
        {
            "_id": 14106,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "שכ אלקוס'יבה"
        },
        {
            "_id": 14107,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "שכ אלקלעה"
        },
        {
            "_id": 14108,
            "סמל_ישוב": 490,
            "שם_ישוב": "דייר אל-אסד ",
            "סמל_רחוב": 143,
            "שם_רחוב": "תופיק זיאד"
        },
        {
            "_id": 14109,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אבו בכר אל סדיק"
        },
        {
            "_id": 14110,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אבו דאג'ר"
        },
        {
            "_id": 14111,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אבו חנון"
        },
        {
            "_id": 14112,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 14113,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 14114,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אדוארד סעיד"
        },
        {
            "_id": 14115,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אל אמיר"
        },
        {
            "_id": 14116,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 193,
            "שם_רחוב": "אל אפנדי"
        },
        {
            "_id": 14117,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 178,
            "שם_רחוב": "אל בורג'"
        },
        {
            "_id": 14118,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אל ביארה"
        },
        {
            "_id": 14119,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אל בילאן"
        },
        {
            "_id": 14120,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אל ברכה אל ביד'אא"
        },
        {
            "_id": 14121,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 196,
            "שם_רחוב": "אל ג'דידה"
        },
        {
            "_id": 14122,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אל וורוד"
        },
        {
            "_id": 14123,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אל זהראא"
        },
        {
            "_id": 14124,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אל זידאני"
        },
        {
            "_id": 14125,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אל זיתון"
        },
        {
            "_id": 14126,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אל זנבק"
        },
        {
            "_id": 14127,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אל זעתר"
        },
        {
            "_id": 14128,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אל ח'לה אל ג'רבייה"
        },
        {
            "_id": 14129,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אל ח'לה אל שרקייה"
        },
        {
            "_id": 14130,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אל ח'רוב"
        },
        {
            "_id": 14131,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אל חאווז"
        },
        {
            "_id": 14132,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אל חארה אל תחתה"
        },
        {
            "_id": 14133,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אל חביב"
        },
        {
            "_id": 14134,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 192,
            "שם_רחוב": "אל חדיקה"
        },
        {
            "_id": 14135,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אל חוראן"
        },
        {
            "_id": 14136,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אל יאסמין"
        },
        {
            "_id": 14137,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אל ירמוק"
        },
        {
            "_id": 14138,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אל כרמל"
        },
        {
            "_id": 14139,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אל מאסיה"
        },
        {
            "_id": 14140,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אל מארג'"
        },
        {
            "_id": 14141,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אל מוסטפא"
        },
        {
            "_id": 14142,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אל מותנבי"
        },
        {
            "_id": 14143,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אל מטל"
        },
        {
            "_id": 14144,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אל מידאן"
        },
        {
            "_id": 14145,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אל מנטקה אל סנאעייה"
        },
        {
            "_id": 14146,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אל מנסור"
        },
        {
            "_id": 14147,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אל מרוש"
        },
        {
            "_id": 14148,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אל נאסרה"
        },
        {
            "_id": 14149,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אל נהד'ה"
        },
        {
            "_id": 14150,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אל נח'יל"
        },
        {
            "_id": 14151,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אל נסר"
        },
        {
            "_id": 14152,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אל נרג'ס"
        },
        {
            "_id": 14153,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אל סג'רה"
        },
        {
            "_id": 14154,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אל סעיד"
        },
        {
            "_id": 14155,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אל ספא"
        },
        {
            "_id": 14156,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אל עין"
        },
        {
            "_id": 14157,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אל ערב"
        },
        {
            "_id": 14158,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 218,
            "שם_רחוב": "אל עריד'"
        },
        {
            "_id": 14159,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אל פרדוס"
        },
        {
            "_id": 14160,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אל פרח"
        },
        {
            "_id": 14161,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אל צבאר"
        },
        {
            "_id": 14162,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אל קאדסיה"
        },
        {
            "_id": 14163,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אל קודס"
        },
        {
            "_id": 14164,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אל קלעה"
        },
        {
            "_id": 14165,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אל קראג'"
        },
        {
            "_id": 14166,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אל רביע"
        },
        {
            "_id": 14167,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אל רובעאן"
        },
        {
            "_id": 14168,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אל רחמאן"
        },
        {
            "_id": 14169,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אל ריחאן"
        },
        {
            "_id": 14170,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אל שועאע"
        },
        {
            "_id": 14171,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלאנדלוס"
        },
        {
            "_id": 14172,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלבוסתאן"
        },
        {
            "_id": 14173,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלבוסתנג'י"
        },
        {
            "_id": 14174,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 14175,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלבנאת"
        },
        {
            "_id": 14176,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אלברכה"
        },
        {
            "_id": 14177,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלח'וארזמי"
        },
        {
            "_id": 14178,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלח'ליל"
        },
        {
            "_id": 14179,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלחמדיה"
        },
        {
            "_id": 14180,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלמוג'ר"
        },
        {
            "_id": 14181,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלמערי"
        },
        {
            "_id": 14182,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלסהל"
        },
        {
            "_id": 14183,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלסופסאף"
        },
        {
            "_id": 14184,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 14185,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלעביר"
        },
        {
            "_id": 14186,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלראזי"
        },
        {
            "_id": 14187,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלרויס"
        },
        {
            "_id": 14188,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלרויסאת"
        },
        {
            "_id": 14189,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 194,
            "שם_רחוב": "אמיל חביבי"
        },
        {
            "_id": 14190,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 195,
            "שם_רחוב": "ארבע אל מודיר"
        },
        {
            "_id": 14191,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 159,
            "שם_רחוב": "ביסאן"
        },
        {
            "_id": 14192,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 138,
            "שם_רחוב": "ביר אל פראן"
        },
        {
            "_id": 14193,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 189,
            "שם_רחוב": "בלאל בן רבאח"
        },
        {
            "_id": 14194,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 123,
            "שם_רחוב": "ג'דר אלבלד"
        },
        {
            "_id": 14195,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 171,
            "שם_רחוב": "ד'אהר אל עומר"
        },
        {
            "_id": 14196,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דייר חנא"
        },
        {
            "_id": 14197,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הארון אלרשיד"
        },
        {
            "_id": 14198,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 14199,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 112,
            "שם_רחוב": "ח'לת אלח'סה"
        },
        {
            "_id": 14200,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 118,
            "שם_רחוב": "ח'לת אלעונוק"
        },
        {
            "_id": 14201,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 212,
            "שם_רחוב": "חזוה"
        },
        {
            "_id": 14202,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 155,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 14203,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 161,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 14204,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "חנא נקארה"
        },
        {
            "_id": 14205,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 175,
            "שם_רחוב": "טאהא חוסין"
        },
        {
            "_id": 14206,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 122,
            "שם_רחוב": "טארק בן זיאד"
        },
        {
            "_id": 14207,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 151,
            "שם_רחוב": "טולעת אל ח'מיסי"
        },
        {
            "_id": 14208,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 203,
            "שם_רחוב": "יאפה"
        },
        {
            "_id": 14209,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 139,
            "שם_רחוב": "כנעאן"
        },
        {
            "_id": 14210,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 126,
            "שם_רחוב": "כרם אבו סמרה"
        },
        {
            "_id": 14211,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 106,
            "שם_רחוב": "כרם איוב"
        },
        {
            "_id": 14212,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 205,
            "שם_רחוב": "כרם אל ספדי"
        },
        {
            "_id": 14213,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 124,
            "שם_רחוב": "כרם אלסביל"
        },
        {
            "_id": 14214,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 115,
            "שם_רחוב": "כרם אלשיח'"
        },
        {
            "_id": 14215,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 107,
            "שם_רחוב": "כרם ממלוק"
        },
        {
            "_id": 14216,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 127,
            "שם_רחוב": "כרם פרחאת"
        },
        {
            "_id": 14217,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 166,
            "שם_רחוב": "מי זיאדה"
        },
        {
            "_id": 14218,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 156,
            "שם_רחוב": "מערוף אל רסאפי"
        },
        {
            "_id": 14219,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 170,
            "שם_רחוב": "מר אליאס"
        },
        {
            "_id": 14220,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 169,
            "שם_רחוב": "מר יוחנא"
        },
        {
            "_id": 14221,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 204,
            "שם_רחוב": "נסב אלעין"
        },
        {
            "_id": 14222,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 109,
            "שם_רחוב": "סולטאן באשא"
        },
        {
            "_id": 14223,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 199,
            "שם_רחוב": "עכו"
        },
        {
            "_id": 14224,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 187,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 14225,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 186,
            "שם_רחוב": "עמאן"
        },
        {
            "_id": 14226,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 144,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 14227,
            "סמל_ישוב": 492,
            "שם_ישוב": "דייר חנא ",
            "סמל_רחוב": 197,
            "שם_רחוב": "קווירי"
        },
        {
            "_id": 14228,
            "סמל_ישוב": 493,
            "שם_ישוב": "דייר ראפאת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דייר ראפאת"
        },
        {
            "_id": 14229,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 285,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 14230,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 14231,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 452,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 14232,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 333,
            "שם_רחוב": "אדר"
        },
        {
            "_id": 14233,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 434,
            "שם_רחוב": "אוריאל פרץ"
        },
        {
            "_id": 14234,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 338,
            "שם_רחוב": "אזוב"
        },
        {
            "_id": 14235,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "אזור התעשייה"
        },
        {
            "_id": 14236,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 529,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 14237,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 345,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 14238,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 284,
            "שם_רחוב": "אלחריזי"
        },
        {
            "_id": 14239,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 440,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 14240,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 435,
            "שם_רחוב": "אלירז פרץ"
        },
        {
            "_id": 14241,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אמירים"
        },
        {
            "_id": 14242,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 356,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 14243,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 443,
            "שם_רחוב": "אמנון ליפקין שחק"
        },
        {
            "_id": 14244,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אסף"
        },
        {
            "_id": 14245,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "אף על פי כן"
        },
        {
            "_id": 14246,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 548,
            "שם_רחוב": "אפרסק"
        },
        {
            "_id": 14247,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אקליפטוס"
        },
        {
            "_id": 14248,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אקסודוס"
        },
        {
            "_id": 14249,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 439,
            "שם_רחוב": "ארז גרשטיין"
        },
        {
            "_id": 14250,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 337,
            "שם_רחוב": "אשחר"
        },
        {
            "_id": 14251,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אשכול"
        },
        {
            "_id": 14252,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "בבא סאלי"
        },
        {
            "_id": 14253,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 415,
            "שם_רחוב": "בית שערים"
        },
        {
            "_id": 14254,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 520,
            "שם_רחוב": "בנאי"
        },
        {
            "_id": 14255,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 14256,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 516,
            "שם_רחוב": "ברזל"
        },
        {
            "_id": 14257,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "ברנהיים"
        },
        {
            "_id": 14258,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 334,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 14259,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 339,
            "שם_רחוב": "בשמת"
        },
        {
            "_id": 14260,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "בת חן"
        },
        {
            "_id": 14261,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "גדיד"
        },
        {
            "_id": 14262,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 408,
            "שם_רחוב": "גמלא"
        },
        {
            "_id": 14263,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 537,
            "שם_רחוב": "דר חיים בוגרשוב"
        },
        {
            "_id": 14264,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 518,
            "שם_רחוב": "דר פליקס זנדמן"
        },
        {
            "_id": 14265,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 375,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 14266,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 539,
            "שם_רחוב": "דבורה עומר"
        },
        {
            "_id": 14267,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 547,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 14268,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "דוגית"
        },
        {
            "_id": 14269,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "דוד אלעזר"
        },
        {
            "_id": 14270,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 442,
            "שם_רחוב": "דוד מימון"
        },
        {
            "_id": 14271,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 14272,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 340,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 14273,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 538,
            "שם_רחוב": "דיזינגוף"
        },
        {
            "_id": 14274,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 278,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 14275,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דימונה"
        },
        {
            "_id": 14276,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 517,
            "שם_רחוב": "דלקן"
        },
        {
            "_id": 14277,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "דני"
        },
        {
            "_id": 14278,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 335,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 14279,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 433,
            "שם_רחוב": "דרור וינברג"
        },
        {
            "_id": 14280,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 321,
            "שם_רחוב": "דרך בגין"
        },
        {
            "_id": 14281,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "דרך ברלב"
        },
        {
            "_id": 14282,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "דרך גדולי התורה"
        },
        {
            "_id": 14283,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "דרך הצנחנים"
        },
        {
            "_id": 14284,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "דרך השלום"
        },
        {
            "_id": 14285,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "דרך חיים"
        },
        {
            "_id": 14286,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "דרך יצחק שמיר"
        },
        {
            "_id": 14287,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "דרך רבין"
        },
        {
            "_id": 14288,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 14289,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 14290,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "האיריס"
        },
        {
            "_id": 14291,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 14292,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 14293,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 14294,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 269,
            "שם_רחוב": "הבעל שם טוב"
        },
        {
            "_id": 14295,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 14296,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "הגבעה"
        },
        {
            "_id": 14297,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "הגיא"
        },
        {
            "_id": 14298,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 14299,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 438,
            "שם_רחוב": "הדס מלכא"
        },
        {
            "_id": 14300,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 437,
            "שם_רחוב": "הדר כהן"
        },
        {
            "_id": 14301,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 14302,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 14303,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 14304,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 14305,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 14306,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 549,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 14307,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "החיל האלמוני"
        },
        {
            "_id": 14308,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 14309,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "היונה"
        },
        {
            "_id": 14310,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "היורה"
        },
        {
            "_id": 14311,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 527,
            "שם_רחוב": "הכינור"
        },
        {
            "_id": 14312,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 14313,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 550,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 14314,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 14315,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "המכתש"
        },
        {
            "_id": 14316,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 14317,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "המלך אחז"
        },
        {
            "_id": 14318,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "המלך דוד"
        },
        {
            "_id": 14319,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "המלקוש"
        },
        {
            "_id": 14320,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 14321,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "המעלה"
        },
        {
            "_id": 14322,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "המעפיל"
        },
        {
            "_id": 14323,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "המרגנית"
        },
        {
            "_id": 14324,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 528,
            "שם_רחוב": "הנבל"
        },
        {
            "_id": 14325,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 14326,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "הסדנה"
        },
        {
            "_id": 14327,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "הסנהדרין"
        },
        {
            "_id": 14328,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 14329,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 14330,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 14331,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "הפועלים"
        },
        {
            "_id": 14332,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 14333,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 531,
            "שם_רחוב": "הפעמון"
        },
        {
            "_id": 14334,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 551,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 14335,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "הצאלה"
        },
        {
            "_id": 14336,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 14337,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 384,
            "שם_רחוב": "הר אדיר"
        },
        {
            "_id": 14338,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 365,
            "שם_רחוב": "הר ארבל"
        },
        {
            "_id": 14339,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 396,
            "שם_רחוב": "הר ארגמן"
        },
        {
            "_id": 14340,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 386,
            "שם_רחוב": "הר גבים"
        },
        {
            "_id": 14341,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 382,
            "שם_רחוב": "הר גולן"
        },
        {
            "_id": 14342,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 392,
            "שם_רחוב": "הר גילה"
        },
        {
            "_id": 14343,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 397,
            "שם_רחוב": "הר גריזים"
        },
        {
            "_id": 14344,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 388,
            "שם_רחוב": "הר דב"
        },
        {
            "_id": 14345,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 383,
            "שם_רחוב": "הר הנגב"
        },
        {
            "_id": 14346,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 391,
            "שם_רחוב": "הר הצופים"
        },
        {
            "_id": 14347,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 385,
            "שם_רחוב": "הר חזון"
        },
        {
            "_id": 14348,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 387,
            "שם_רחוב": "הר חרוז"
        },
        {
            "_id": 14349,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 363,
            "שם_רחוב": "הר חרמון"
        },
        {
            "_id": 14350,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 390,
            "שם_רחוב": "הר יהל"
        },
        {
            "_id": 14351,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 367,
            "שם_רחוב": "הר כנען"
        },
        {
            "_id": 14352,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 393,
            "שם_רחוב": "הר כפיר"
        },
        {
            "_id": 14353,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 369,
            "שם_רחוב": "הר כרכום"
        },
        {
            "_id": 14354,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 368,
            "שם_רחוב": "הר כרמל"
        },
        {
            "_id": 14355,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 357,
            "שם_רחוב": "הר מוריה"
        },
        {
            "_id": 14356,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 366,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 14357,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 398,
            "שם_רחוב": "הר נבו"
        },
        {
            "_id": 14358,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 389,
            "שם_רחוב": "הר נס"
        },
        {
            "_id": 14359,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הר סיני"
        },
        {
            "_id": 14360,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 395,
            "שם_רחוב": "הר עצמון"
        },
        {
            "_id": 14361,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 394,
            "שם_רחוב": "הר רביד"
        },
        {
            "_id": 14362,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 381,
            "שם_רחוב": "הר שגיא"
        },
        {
            "_id": 14363,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 380,
            "שם_רחוב": "הר שניר"
        },
        {
            "_id": 14364,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 364,
            "שם_רחוב": "הר תבור"
        },
        {
            "_id": 14365,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "הרב אבוחצירה"
        },
        {
            "_id": 14366,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 428,
            "שם_רחוב": "הרב אברהם אלמליח"
        },
        {
            "_id": 14367,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 525,
            "שם_רחוב": "הרב אברהם קוק"
        },
        {
            "_id": 14368,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 524,
            "שם_רחוב": "הרב אליעזר אבוחצירה"
        },
        {
            "_id": 14369,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "הרב אלנקוה"
        },
        {
            "_id": 14370,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "הרב טייב"
        },
        {
            "_id": 14371,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 526,
            "שם_רחוב": "הרב מרדכי אליהו"
        },
        {
            "_id": 14372,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 523,
            "שם_רחוב": "הרב עובדיה יוסף"
        },
        {
            "_id": 14373,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 522,
            "שם_רחוב": "הרבי מלובאויטש"
        },
        {
            "_id": 14374,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 341,
            "שם_רחוב": "הרדוף הנחלים"
        },
        {
            "_id": 14375,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 420,
            "שם_רחוב": "הרודיון"
        },
        {
            "_id": 14376,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 521,
            "שם_רחוב": "הריח"
        },
        {
            "_id": 14377,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 14378,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 296,
            "שם_רחוב": "הרכס"
        },
        {
            "_id": 14379,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 297,
            "שם_רחוב": "הרמה"
        },
        {
            "_id": 14380,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 14381,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "השבלים"
        },
        {
            "_id": 14382,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 298,
            "שם_רחוב": "השיא"
        },
        {
            "_id": 14383,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 14384,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "השיריון"
        },
        {
            "_id": 14385,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "השלוה"
        },
        {
            "_id": 14386,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 552,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 14387,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 14388,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 14389,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 14390,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "התפוצות"
        },
        {
            "_id": 14391,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "וילות טרומים"
        },
        {
            "_id": 14392,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "ורדימון יצחק"
        },
        {
            "_id": 14393,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 511,
            "שם_רחוב": "זוויתן"
        },
        {
            "_id": 14394,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 376,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 14395,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 500,
            "שם_רחוב": "חוות הגמלים כורנוב"
        },
        {
            "_id": 14396,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "חטיבת אלכסנדרוני"
        },
        {
            "_id": 14397,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "חטיבת גבעתי"
        },
        {
            "_id": 14398,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 14399,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "חטיבת הנגב"
        },
        {
            "_id": 14400,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "חטיבת הראל"
        },
        {
            "_id": 14401,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 412,
            "שם_רחוב": "חמת גדר"
        },
        {
            "_id": 14402,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "חנה סנש"
        },
        {
            "_id": 14403,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 346,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 14404,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 331,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 14405,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 519,
            "שם_רחוב": "חרט"
        },
        {
            "_id": 14406,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 505,
            "שם_רחוב": "חרמש"
        },
        {
            "_id": 14407,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 342,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 14408,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 513,
            "שם_רחוב": "חרש"
        },
        {
            "_id": 14409,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "טבנקין יצחק"
        },
        {
            "_id": 14410,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 343,
            "שם_רחוב": "טופח"
        },
        {
            "_id": 14411,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 557,
            "שם_רחוב": "טוראי דוד שירזי"
        },
        {
            "_id": 14412,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "טיילת הספורט"
        },
        {
            "_id": 14413,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "טיילת עמידר"
        },
        {
            "_id": 14414,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 291,
            "שם_רחוב": "יגור"
        },
        {
            "_id": 14415,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 286,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 14416,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 374,
            "שם_רחוב": "יואל"
        },
        {
            "_id": 14417,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 419,
            "שם_רחוב": "יודפת"
        },
        {
            "_id": 14418,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 436,
            "שם_רחוב": "יוני נתניהו"
        },
        {
            "_id": 14419,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 540,
            "שם_רחוב": "יצחק בשביס זינגר"
        },
        {
            "_id": 14420,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "יציאת אירופה"
        },
        {
            "_id": 14421,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 422,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 14422,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 371,
            "שם_רחוב": "ירמיה"
        },
        {
            "_id": 14423,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "ישעיה"
        },
        {
            "_id": 14424,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "כובשי אילת"
        },
        {
            "_id": 14425,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 403,
            "שם_רחוב": "כורזין"
        },
        {
            "_id": 14426,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "כיכר אבי שחר"
        },
        {
            "_id": 14427,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "כיכר גבי סבג"
        },
        {
            "_id": 14428,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "כיכר עוזי בלהקר"
        },
        {
            "_id": 14429,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר דנמרק"
        },
        {
            "_id": 14430,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר ז'בוטינסקי"
        },
        {
            "_id": 14431,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 325,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 14432,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 512,
            "שם_רחוב": "להב"
        },
        {
            "_id": 14433,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "לובטקין צביה"
        },
        {
            "_id": 14434,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 506,
            "שם_רחוב": "לום"
        },
        {
            "_id": 14435,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 332,
            "שם_רחוב": "ליבנה"
        },
        {
            "_id": 14436,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 536,
            "שם_רחוב": "לילינבלום"
        },
        {
            "_id": 14437,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 352,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 14438,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "מג המעפילים"
        },
        {
            "_id": 14439,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 281,
            "שם_רחוב": "מאיר בעל הנס"
        },
        {
            "_id": 14440,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 444,
            "שם_רחוב": "מבצע אביב נעורים"
        },
        {
            "_id": 14441,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "מבצע ברוש"
        },
        {
            "_id": 14442,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "מבצע חורב"
        },
        {
            "_id": 14443,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "מבצע יואב"
        },
        {
            "_id": 14444,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 448,
            "שם_רחוב": "מבצע ליטני"
        },
        {
            "_id": 14445,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 447,
            "שם_רחוב": "מבצע מנורה"
        },
        {
            "_id": 14446,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "מבצע עובדה"
        },
        {
            "_id": 14447,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 446,
            "שם_רחוב": "מבצע שלכת"
        },
        {
            "_id": 14448,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 445,
            "שם_רחוב": "מבצע שלמה"
        },
        {
            "_id": 14449,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 401,
            "שם_רחוב": "מבצר עכו"
        },
        {
            "_id": 14450,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 507,
            "שם_רחוב": "מגל"
        },
        {
            "_id": 14451,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 451,
            "שם_רחוב": "מגלן"
        },
        {
            "_id": 14452,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 509,
            "שם_רחוב": "מגנט"
        },
        {
            "_id": 14453,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "מדבר יהודה"
        },
        {
            "_id": 14454,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "מדבר סיני"
        },
        {
            "_id": 14455,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 14456,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "מולדה"
        },
        {
            "_id": 14457,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 418,
            "שם_רחוב": "מונפור"
        },
        {
            "_id": 14458,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "מורג"
        },
        {
            "_id": 14459,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 330,
            "שם_רחוב": "מורן החורש"
        },
        {
            "_id": 14460,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "מזור"
        },
        {
            "_id": 14461,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "מחנה עדי"
        },
        {
            "_id": 14462,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "מילרוד אליעזר"
        },
        {
            "_id": 14463,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 379,
            "שם_רחוב": "מלאכי"
        },
        {
            "_id": 14464,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מלכה הנרי"
        },
        {
            "_id": 14465,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 502,
            "שם_רחוב": "מסור"
        },
        {
            "_id": 14466,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "מעברה"
        },
        {
            "_id": 14467,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "מעון טללים"
        },
        {
            "_id": 14468,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 320,
            "שם_רחוב": "מעלה ההר"
        },
        {
            "_id": 14469,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 14470,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 347,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 14471,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "מרחבים"
        },
        {
            "_id": 14472,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 377,
            "שם_רחוב": "מרים הנביאה"
        },
        {
            "_id": 14473,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 14474,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 503,
            "שם_רחוב": "מרצע"
        },
        {
            "_id": 14475,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "משטרה"
        },
        {
            "_id": 14476,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "משעול ארז"
        },
        {
            "_id": 14477,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "משעול ההגנה"
        },
        {
            "_id": 14478,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "משעול החירות"
        },
        {
            "_id": 14479,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "משעול הסנונית"
        },
        {
            "_id": 14480,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "משעול הרעות"
        },
        {
            "_id": 14481,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "משעול התקוה"
        },
        {
            "_id": 14482,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "משעול התקומה"
        },
        {
            "_id": 14483,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "משעול חירם"
        },
        {
            "_id": 14484,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "משעול רגבים"
        },
        {
            "_id": 14485,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "משעול רוגל"
        },
        {
            "_id": 14486,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "משעול שלדג"
        },
        {
            "_id": 14487,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "מתתיהו הכהן"
        },
        {
            "_id": 14488,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "נאות הללי"
        },
        {
            "_id": 14489,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6535,
            "שם_רחוב": "נאות קטיף"
        },
        {
            "_id": 14490,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "נבטים"
        },
        {
            "_id": 14491,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "נגבה"
        },
        {
            "_id": 14492,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "נוה אביב"
        },
        {
            "_id": 14493,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "נוה חורש"
        },
        {
            "_id": 14494,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "נוה מדבר"
        },
        {
            "_id": 14495,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "נוה שלום"
        },
        {
            "_id": 14496,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 348,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 14497,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 372,
            "שם_רחוב": "נחום"
        },
        {
            "_id": 14498,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 532,
            "שם_רחוב": "נחלת בנימין"
        },
        {
            "_id": 14499,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "נטעים"
        },
        {
            "_id": 14500,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "ניצנה"
        },
        {
            "_id": 14501,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 515,
            "שם_רחוב": "נפח"
        },
        {
            "_id": 14502,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "נצנים"
        },
        {
            "_id": 14503,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "נצרים"
        },
        {
            "_id": 14504,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 295,
            "שם_רחוב": "נתיב הלה"
        },
        {
            "_id": 14505,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 282,
            "שם_רחוב": "נתיב הנחש"
        },
        {
            "_id": 14506,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "נתיב הצפורים"
        },
        {
            "_id": 14507,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "נתיב יותם"
        },
        {
            "_id": 14508,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 373,
            "שם_רחוב": "נתן הנביא"
        },
        {
            "_id": 14509,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 554,
            "שם_רחוב": "סאל עמנואל מורנו"
        },
        {
            "_id": 14510,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 14511,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 504,
            "שם_רחוב": "סדן"
        },
        {
            "_id": 14512,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 508,
            "שם_רחוב": "סולם"
        },
        {
            "_id": 14513,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 354,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 14514,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 326,
            "שם_רחוב": "סחלבן החורש"
        },
        {
            "_id": 14515,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "סטרומה"
        },
        {
            "_id": 14516,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 344,
            "שם_רחוב": "סיגלון"
        },
        {
            "_id": 14517,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 351,
            "שם_רחוב": "סיתונית"
        },
        {
            "_id": 14518,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "סלא איירין"
        },
        {
            "_id": 14519,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 556,
            "שם_רחוב": "סרן יובל נריה"
        },
        {
            "_id": 14520,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 555,
            "שם_רחוב": "סרן עודד אמיר"
        },
        {
            "_id": 14521,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "עבדת"
        },
        {
            "_id": 14522,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "עופר אברהם"
        },
        {
            "_id": 14523,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "עזרא ונחמיה"
        },
        {
            "_id": 14524,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "עיינות"
        },
        {
            "_id": 14525,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 423,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 14526,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "עלומים"
        },
        {
            "_id": 14527,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "עלית הנוער"
        },
        {
            "_id": 14528,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 378,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 14529,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "ערוגות"
        },
        {
            "_id": 14530,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 542,
            "שם_רחוב": "פ' שמואל יוסף עגנון"
        },
        {
            "_id": 14531,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6551,
            "שם_רחוב": "פארק תעשיה רותם"
        },
        {
            "_id": 14532,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 279,
            "שם_רחוב": "פונדק דרכים"
        },
        {
            "_id": 14533,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 501,
            "שם_רחוב": "פטיש"
        },
        {
            "_id": 14534,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 407,
            "שם_רחוב": "פטרה"
        },
        {
            "_id": 14535,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "פינטו חיים"
        },
        {
            "_id": 14536,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 514,
            "שם_רחוב": "פלדה"
        },
        {
            "_id": 14537,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 510,
            "שם_רחוב": "פלס"
        },
        {
            "_id": 14538,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 414,
            "שם_רחוב": "פקיעין"
        },
        {
            "_id": 14539,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 336,
            "שם_רחוב": "פרג"
        },
        {
            "_id": 14540,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 546,
            "שם_רחוב": "פרופ' אהרון צ'חנובר"
        },
        {
            "_id": 14541,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 543,
            "שם_רחוב": "פרופ' דן שכטמן"
        },
        {
            "_id": 14542,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 545,
            "שם_רחוב": "פרופ' דניאל כהנמן"
        },
        {
            "_id": 14543,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 544,
            "שם_רחוב": "פרופ' ישראל אומן"
        },
        {
            "_id": 14544,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 541,
            "שם_רחוב": "פרופ' עדה יונת"
        },
        {
            "_id": 14545,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "פרחי ארצנו"
        },
        {
            "_id": 14546,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 349,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 14547,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "צוקרמן יצחק רחבה"
        },
        {
            "_id": 14548,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 362,
            "שם_רחוב": "צורית"
        },
        {
            "_id": 14549,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 404,
            "שם_רחוב": "ציפורי"
        },
        {
            "_id": 14550,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 350,
            "שם_רחוב": "ציפורן"
        },
        {
            "_id": 14551,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 360,
            "שם_רחוב": "צפצפה"
        },
        {
            "_id": 14552,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 289,
            "שם_רחוב": "קארו יוסף"
        },
        {
            "_id": 14553,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "קדמה"
        },
        {
            "_id": 14554,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "קדש"
        },
        {
            "_id": 14555,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 293,
            "שם_רחוב": "קהילת בריטניה"
        },
        {
            "_id": 14556,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "קהילת העבריים"
        },
        {
            "_id": 14557,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 413,
            "שם_רחוב": "קומראן"
        },
        {
            "_id": 14558,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 327,
            "שם_רחוב": "קטלב"
        },
        {
            "_id": 14559,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 410,
            "שם_רחוב": "קיסריה"
        },
        {
            "_id": 14560,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 417,
            "שם_רחוב": "קלעת נמרוד"
        },
        {
            "_id": 14561,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 530,
            "שם_רחוב": "קלרינט"
        },
        {
            "_id": 14562,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 399,
            "שם_רחוב": "קניון פרץ סנטר"
        },
        {
            "_id": 14563,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "קציעות"
        },
        {
            "_id": 14564,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 409,
            "שם_רחוב": "קצרין"
        },
        {
            "_id": 14565,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "קריה למחקר גרעיני"
        },
        {
            "_id": 14566,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6555,
            "שם_רחוב": "קרית המלאכה"
        },
        {
            "_id": 14567,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6554,
            "שם_רחוב": "קרית העמל"
        },
        {
            "_id": 14568,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6553,
            "שם_רחוב": "קרית התעשיה"
        },
        {
            "_id": 14569,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "קרית חנוך"
        },
        {
            "_id": 14570,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 535,
            "שם_רחוב": "קרליבך"
        },
        {
            "_id": 14571,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 405,
            "שם_רחוב": "קרני חיטין"
        },
        {
            "_id": 14572,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 534,
            "שם_רחוב": "רבי נחמן מאומן"
        },
        {
            "_id": 14573,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 533,
            "שם_רחוב": "רוטשילד"
        },
        {
            "_id": 14574,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 432,
            "שם_רחוב": "רועי קליין"
        },
        {
            "_id": 14575,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 14576,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "רחבת אורון"
        },
        {
            "_id": 14577,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "רחבת האיסיים"
        },
        {
            "_id": 14578,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "רחבת האצל"
        },
        {
            "_id": 14579,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רחבת האשלג"
        },
        {
            "_id": 14580,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "רחבת הבנים"
        },
        {
            "_id": 14581,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "רחבת הלחי"
        },
        {
            "_id": 14582,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 292,
            "שם_רחוב": "רחבת הנחל"
        },
        {
            "_id": 14583,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "רחבת הסוכנות"
        },
        {
            "_id": 14584,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "רחבת הפלמח"
        },
        {
            "_id": 14585,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "רחבת יונתן"
        },
        {
            "_id": 14586,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 280,
            "שם_רחוב": "רחבת רמבם"
        },
        {
            "_id": 14587,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "רחבת רשי"
        },
        {
            "_id": 14588,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "רמת עופר"
        },
        {
            "_id": 14589,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 14590,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "ש 4 קומות"
        },
        {
            "_id": 14591,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש אסבסט א ב"
        },
        {
            "_id": 14592,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש אסבסט ג"
        },
        {
            "_id": 14593,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש בתי אבן"
        },
        {
            "_id": 14594,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "ש הנצחון"
        },
        {
            "_id": 14595,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "ש טרומיים"
        },
        {
            "_id": 14596,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "ש לדוגמה"
        },
        {
            "_id": 14597,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "ש קומותיים"
        },
        {
            "_id": 14598,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "ש קליפורניה"
        },
        {
            "_id": 14599,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "ש רכבת"
        },
        {
            "_id": 14600,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 14601,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 288,
            "שם_רחוב": "שבזי שלום"
        },
        {
            "_id": 14602,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "שבטי ישראל"
        },
        {
            "_id": 14603,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 14604,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שד גולדה מאיר"
        },
        {
            "_id": 14605,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שד יגאל אלון"
        },
        {
            "_id": 14606,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "שדה יצחק"
        },
        {
            "_id": 14607,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "שדרות אריאל שרון"
        },
        {
            "_id": 14608,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 450,
            "שם_רחוב": "שועלי מרום"
        },
        {
            "_id": 14609,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "שוק עירוני"
        },
        {
            "_id": 14610,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 355,
            "שם_רחוב": "שושן צחור"
        },
        {
            "_id": 14611,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שושנים"
        },
        {
            "_id": 14612,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "שטרן יאיר"
        },
        {
            "_id": 14613,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 406,
            "שם_רחוב": "שיבטה"
        },
        {
            "_id": 14614,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 329,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 14615,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 449,
            "שם_רחוב": "שייטת 13"
        },
        {
            "_id": 14616,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 361,
            "שם_רחוב": "שיקמה"
        },
        {
            "_id": 14617,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "שכ בנה ביתך"
        },
        {
            "_id": 14618,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "שכ בסט"
        },
        {
            "_id": 14619,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "שכ הגבעה"
        },
        {
            "_id": 14620,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "שכ הערבה"
        },
        {
            "_id": 14621,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "שכ הר נוף"
        },
        {
            "_id": 14622,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "שכ חכמי ישראל"
        },
        {
            "_id": 14623,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "שכ יוספטל"
        },
        {
            "_id": 14624,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6552,
            "שם_רחוב": "שכ כפר הסטודנטים"
        },
        {
            "_id": 14625,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "שכ מלכי ישראל"
        },
        {
            "_id": 14626,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "שכ ממשית"
        },
        {
            "_id": 14627,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6538,
            "שם_רחוב": "שכ מעלה נתניהו"
        },
        {
            "_id": 14628,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6537,
            "שם_רחוב": "שכ מעלה שחק"
        },
        {
            "_id": 14629,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "שכ נוה גן"
        },
        {
            "_id": 14630,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ נווה דוד"
        },
        {
            "_id": 14631,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ נופי מדבר"
        },
        {
            "_id": 14632,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "שכ פרחי ארצנו"
        },
        {
            "_id": 14633,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6534,
            "שם_רחוב": "שכ קדמה"
        },
        {
            "_id": 14634,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6545,
            "שם_רחוב": "שכ שבטי ישראל"
        },
        {
            "_id": 14635,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ שבעת המינים"
        },
        {
            "_id": 14636,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "שכ' השחר"
        },
        {
            "_id": 14637,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "שכון 3 קומות"
        },
        {
            "_id": 14638,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 287,
            "שם_רחוב": "שמעון בר יוחאי"
        },
        {
            "_id": 14639,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 441,
            "שם_רחוב": "שרה אהרונסון"
        },
        {
            "_id": 14640,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 553,
            "שם_רחוב": "תאל אביגדור קהלני"
        },
        {
            "_id": 14641,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 416,
            "שם_רחוב": "תל אפק"
        },
        {
            "_id": 14642,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 429,
            "שם_רחוב": "תל בית אל"
        },
        {
            "_id": 14643,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "תל גבעון"
        },
        {
            "_id": 14644,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 426,
            "שם_רחוב": "תל דן"
        },
        {
            "_id": 14645,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 400,
            "שם_רחוב": "תל חמה"
        },
        {
            "_id": 14646,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 424,
            "שם_רחוב": "תל לכיש"
        },
        {
            "_id": 14647,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 431,
            "שם_רחוב": "תל מגידו"
        },
        {
            "_id": 14648,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 430,
            "שם_רחוב": "תל מקנה"
        },
        {
            "_id": 14649,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 402,
            "שם_רחוב": "תל ערד"
        },
        {
            "_id": 14650,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 411,
            "שם_רחוב": "תל פורן"
        },
        {
            "_id": 14651,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 425,
            "שם_רחוב": "תל ציפורי"
        },
        {
            "_id": 14652,
            "סמל_ישוב": 2200,
            "שם_ישוב": "דימונה ",
            "סמל_רחוב": 427,
            "שם_רחוב": "תל קיסון"
        },
        {
            "_id": 14653,
            "סמל_ישוב": 2063,
            "שם_ישוב": "דישון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דישון"
        },
        {
            "_id": 14654,
            "סמל_ישוב": 300,
            "שם_ישוב": "דליה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 14655,
            "סמל_ישוב": 431,
            "שם_ישוב": "דלתון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דלתון"
        },
        {
            "_id": 14656,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אל ביידר"
        },
        {
            "_id": 14657,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אל ביר"
        },
        {
            "_id": 14658,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אל ג'וז"
        },
        {
            "_id": 14659,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אל וואדי"
        },
        {
            "_id": 14660,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אל ווסעה"
        },
        {
            "_id": 14661,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אל וורוד"
        },
        {
            "_id": 14662,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אל חלל"
        },
        {
            "_id": 14663,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אל חקורה"
        },
        {
            "_id": 14664,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אל חרוב"
        },
        {
            "_id": 14665,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אל מיג'אנה"
        },
        {
            "_id": 14666,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אל עגד"
        },
        {
            "_id": 14667,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אל עין"
        },
        {
            "_id": 14668,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אל רובעאן"
        },
        {
            "_id": 14669,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אל ת'ינה"
        },
        {
            "_id": 14670,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דמיידה"
        },
        {
            "_id": 14671,
            "סמל_ישוב": 1317,
            "שם_ישוב": "דמיידה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "כולת אל עג'ווה"
        },
        {
            "_id": 14672,
            "סמל_ישוב": 303,
            "שם_ישוב": "דן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דן"
        },
        {
            "_id": 14673,
            "סמל_ישוב": 302,
            "שם_ישוב": "דפנה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 14674,
            "סמל_ישוב": 302,
            "שם_ישוב": "דפנה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 14675,
            "סמל_ישוב": 1241,
            "שם_ישוב": "דקל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 14676,
            "סמל_ישוב": 1241,
            "שם_ישוב": "דקל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 14677,
            "סמל_ישוב": 1241,
            "שם_ישוב": "דקל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 14678,
            "סמל_ישוב": 1241,
            "שם_ישוב": "דקל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 14679,
            "סמל_ישוב": 1241,
            "שם_ישוב": "דקל ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "ש קרוונים"
        },
        {
            "_id": 14680,
            "סמל_ישוב": 1241,
            "שם_ישוב": "דקל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 14681,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "דריג'את"
        },
        {
            "_id": 14682,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רח 10"
        },
        {
            "_id": 14683,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רח 20"
        },
        {
            "_id": 14684,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 130,
            "שם_רחוב": "רח 30"
        },
        {
            "_id": 14685,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 101,
            "שם_רחוב": "רח 1"
        },
        {
            "_id": 14686,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רח 2"
        },
        {
            "_id": 14687,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רח 12"
        },
        {
            "_id": 14688,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רח 22"
        },
        {
            "_id": 14689,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רח 32"
        },
        {
            "_id": 14690,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רח 3"
        },
        {
            "_id": 14691,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח 4"
        },
        {
            "_id": 14692,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רח 14"
        },
        {
            "_id": 14693,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רח 24"
        },
        {
            "_id": 14694,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 134,
            "שם_רחוב": "רח 34"
        },
        {
            "_id": 14695,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רח 5"
        },
        {
            "_id": 14696,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רח 6"
        },
        {
            "_id": 14697,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רח 16"
        },
        {
            "_id": 14698,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 126,
            "שם_רחוב": "רח 26"
        },
        {
            "_id": 14699,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 136,
            "שם_רחוב": "רח 36"
        },
        {
            "_id": 14700,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רח 7"
        },
        {
            "_id": 14701,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רח 8"
        },
        {
            "_id": 14702,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח 18"
        },
        {
            "_id": 14703,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 128,
            "שם_רחוב": "רח 28"
        },
        {
            "_id": 14704,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 138,
            "שם_רחוב": "רח 38"
        },
        {
            "_id": 14705,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 109,
            "שם_רחוב": "רח 9"
        },
        {
            "_id": 14706,
            "סמל_ישוב": 1349,
            "שם_ישוב": "דריג'את ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ ב"
        },
        {
            "_id": 14707,
            "סמל_ישוב": 702,
            "שם_ישוב": "האון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "האון"
        },
        {
            "_id": 14708,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גלים"
        },
        {
            "_id": 14709,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 14710,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הבונים"
        },
        {
            "_id": 14711,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 14712,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הצדפים"
        },
        {
            "_id": 14713,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כרמל וים"
        },
        {
            "_id": 14714,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נוף ים"
        },
        {
            "_id": 14715,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "סמטת החצב"
        },
        {
            "_id": 14716,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סמטת הנרקיסים"
        },
        {
            "_id": 14717,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "סמטת הרקפות"
        },
        {
            "_id": 14718,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "סמטת נר הלילה"
        },
        {
            "_id": 14719,
            "סמל_ישוב": 675,
            "שם_ישוב": "הבונים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שונית"
        },
        {
            "_id": 14720,
            "סמל_ישוב": 356,
            "שם_ישוב": "הגושרים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון"
        },
        {
            "_id": 14721,
            "סמל_ישוב": 356,
            "שם_ישוב": "הגושרים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הגושרים"
        },
        {
            "_id": 14722,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 14723,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 14724,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 14725,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 14726,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הדר עם"
        },
        {
            "_id": 14727,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 14728,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ההולנדים"
        },
        {
            "_id": 14729,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הזורע"
        },
        {
            "_id": 14730,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 109,
            "שם_רחוב": "החריש"
        },
        {
            "_id": 14731,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 118,
            "שם_רחוב": "היבול"
        },
        {
            "_id": 14732,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הלימון"
        },
        {
            "_id": 14733,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 120,
            "שם_רחוב": "המסיק"
        },
        {
            "_id": 14734,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הנוטע"
        },
        {
            "_id": 14735,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 14736,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הקטיף"
        },
        {
            "_id": 14737,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הקציר"
        },
        {
            "_id": 14738,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 14739,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 121,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 14740,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 117,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 14741,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 114,
            "שם_רחוב": "זו הדרך"
        },
        {
            "_id": 14742,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 115,
            "שם_רחוב": "נבטים"
        },
        {
            "_id": 14743,
            "סמל_ישוב": 191,
            "שם_ישוב": "הדר עם ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שדרת הכפר"
        },
        {
            "_id": 14744,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 405,
            "שם_רחוב": "אביב"
        },
        {
            "_id": 14745,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 458,
            "שם_רחוב": "אדר"
        },
        {
            "_id": 14746,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 317,
            "שם_רחוב": "אהבה"
        },
        {
            "_id": 14747,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 438,
            "שם_רחוב": "אהובה עוזרי"
        },
        {
            "_id": 14748,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 298,
            "שם_רחוב": "אהוד בן גרא"
        },
        {
            "_id": 14749,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 534,
            "שם_רחוב": "אהרונסון אהרון"
        },
        {
            "_id": 14750,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "אזור תעשיה"
        },
        {
            "_id": 14751,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "אזור תעשיה נוה נאמן"
        },
        {
            "_id": 14752,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אחי דקר"
        },
        {
            "_id": 14753,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אחוה"
        },
        {
            "_id": 14754,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 510,
            "שם_רחוב": "איזמרגד"
        },
        {
            "_id": 14755,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 153,
            "שם_רחוב": "איילת השחר"
        },
        {
            "_id": 14756,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 459,
            "שם_רחוב": "אייר"
        },
        {
            "_id": 14757,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 336,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 14758,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 460,
            "שם_רחוב": "אלול"
        },
        {
            "_id": 14759,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אלוף הנצחון"
        },
        {
            "_id": 14760,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 319,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 14761,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 389,
            "שם_רחוב": "אלי מצד"
        },
        {
            "_id": 14762,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 560,
            "שם_רחוב": "אליהו הנביא"
        },
        {
            "_id": 14763,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלכסנדר הגדול"
        },
        {
            "_id": 14764,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 192,
            "שם_רחוב": "אלעזר"
        },
        {
            "_id": 14765,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 249,
            "שם_רחוב": "אלתרמן"
        },
        {
            "_id": 14766,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 550,
            "שם_רחוב": "אם הקבוצות"
        },
        {
            "_id": 14767,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אנה פרנק"
        },
        {
            "_id": 14768,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 312,
            "שם_רחוב": "אנצילביץ"
        },
        {
            "_id": 14769,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אנשי בראשית"
        },
        {
            "_id": 14770,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אנשי הכנסת הגדולה"
        },
        {
            "_id": 14771,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 344,
            "שם_רחוב": "אסירי ציון"
        },
        {
            "_id": 14772,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אסף הרופא"
        },
        {
            "_id": 14773,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אפק"
        },
        {
            "_id": 14774,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 383,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 14775,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 14776,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 357,
            "שם_רחוב": "אשכול"
        },
        {
            "_id": 14777,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 175,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 14778,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 122,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 14779,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 246,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 14780,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 173,
            "שם_רחוב": "ביח גן מגד"
        },
        {
            "_id": 14781,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 328,
            "שם_רחוב": "ביכורים"
        },
        {
            "_id": 14782,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 552,
            "שם_רחוב": "בית אורן"
        },
        {
            "_id": 14783,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 559,
            "שם_רחוב": "בית קמה"
        },
        {
            "_id": 14784,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 416,
            "שם_רחוב": "בן אבי איתמר"
        },
        {
            "_id": 14785,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "בן גוריון"
        },
        {
            "_id": 14786,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "בן גמלא יהושע"
        },
        {
            "_id": 14787,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 245,
            "שם_רחוב": "בן יהודה"
        },
        {
            "_id": 14788,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 537,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 14789,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 409,
            "שם_רחוב": "בן צבי יצחק"
        },
        {
            "_id": 14790,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 129,
            "שם_רחוב": "בני ברית"
        },
        {
            "_id": 14791,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 442,
            "שם_רחוב": "בצת"
        },
        {
            "_id": 14792,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 264,
            "שם_רחוב": "בר לב חיים"
        },
        {
            "_id": 14793,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 272,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 14794,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 417,
            "שם_רחוב": "בשביס זינגר"
        },
        {
            "_id": 14795,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 235,
            "שם_רחוב": "בת חן"
        },
        {
            "_id": 14796,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 388,
            "שם_רחוב": "גבעת התצפית"
        },
        {
            "_id": 14797,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 557,
            "שם_רחוב": "גברעם"
        },
        {
            "_id": 14798,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 486,
            "שם_רחוב": "גולדה מאיר"
        },
        {
            "_id": 14799,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 318,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 14800,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 268,
            "שם_רחוב": "גור מרדכי"
        },
        {
            "_id": 14801,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 124,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 14802,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 511,
            "שם_רחוב": "גזית"
        },
        {
            "_id": 14803,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 582,
            "שם_רחוב": "גיתית"
        },
        {
            "_id": 14804,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 349,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 14805,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 490,
            "שם_רחוב": "גלגל המזלות"
        },
        {
            "_id": 14806,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גני צבי"
        },
        {
            "_id": 14807,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 536,
            "שם_רחוב": "גרונר דב"
        },
        {
            "_id": 14808,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 275,
            "שם_רחוב": "גרניט"
        },
        {
            "_id": 14809,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 397,
            "שם_רחוב": "גשמי ברכה"
        },
        {
            "_id": 14810,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 452,
            "שם_רחוב": "דבורה"
        },
        {
            "_id": 14811,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 434,
            "שם_רחוב": "דגניה"
        },
        {
            "_id": 14812,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 454,
            "שם_רחוב": "דגנית"
        },
        {
            "_id": 14813,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 394,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 14814,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 263,
            "שם_רחוב": "דוד אלעזר"
        },
        {
            "_id": 14815,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 292,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 14816,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 284,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 14817,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 244,
            "שם_רחוב": "דור ההמשך"
        },
        {
            "_id": 14818,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 267,
            "שם_רחוב": "דורי יעקב"
        },
        {
            "_id": 14819,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 269,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 14820,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 358,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 14821,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 238,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 14822,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 436,
            "שם_רחוב": "דרך כפר הדר"
        },
        {
            "_id": 14823,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 301,
            "שם_רחוב": "דרך מגדיאל"
        },
        {
            "_id": 14824,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך רמתים"
        },
        {
            "_id": 14825,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 221,
            "שם_רחוב": "האגודה"
        },
        {
            "_id": 14826,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 512,
            "שם_רחוב": "האודם"
        },
        {
            "_id": 14827,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 392,
            "שם_רחוב": "האוכמניות"
        },
        {
            "_id": 14828,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 151,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 14829,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 343,
            "שם_רחוב": "האיחוד"
        },
        {
            "_id": 14830,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 180,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 14831,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 128,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 14832,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 256,
            "שם_רחוב": "האצל"
        },
        {
            "_id": 14833,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 218,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 14834,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 205,
            "שם_רחוב": "הבבלי"
        },
        {
            "_id": 14835,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 233,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 14836,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 402,
            "שם_רחוב": "הבנאי"
        },
        {
            "_id": 14837,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 421,
            "שם_רחוב": "הבנות"
        },
        {
            "_id": 14838,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 14839,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 211,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 14840,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 370,
            "שם_רחוב": "הבריח"
        },
        {
            "_id": 14841,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 227,
            "שם_רחוב": "הגאולה"
        },
        {
            "_id": 14842,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 333,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 14843,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 237,
            "שם_רחוב": "הגיא"
        },
        {
            "_id": 14844,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הגיבור"
        },
        {
            "_id": 14845,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 217,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 14846,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הגן"
        },
        {
            "_id": 14847,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 222,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 14848,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 210,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 14849,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 150,
            "שם_רחוב": "הגשר"
        },
        {
            "_id": 14850,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 234,
            "שם_רחוב": "הדסים"
        },
        {
            "_id": 14851,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 219,
            "שם_רחוב": "הדקלים"
        },
        {
            "_id": 14852,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 14853,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 360,
            "שם_רחוב": "הדרים"
        },
        {
            "_id": 14854,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 137,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 14855,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 225,
            "שם_רחוב": "ההכשרות"
        },
        {
            "_id": 14856,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 14857,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הוד השרון"
        },
        {
            "_id": 14858,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 14859,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 387,
            "שם_רחוב": "הזוהר"
        },
        {
            "_id": 14860,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 386,
            "שם_רחוב": "החומש"
        },
        {
            "_id": 14861,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 228,
            "שם_רחוב": "החייל"
        },
        {
            "_id": 14862,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 345,
            "שם_רחוב": "החלוצים"
        },
        {
            "_id": 14863,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 352,
            "שם_רחוב": "החקלאי"
        },
        {
            "_id": 14864,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 334,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 14865,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 200,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 14866,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 163,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 14867,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 165,
            "שם_רחוב": "הידיד"
        },
        {
            "_id": 14868,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 204,
            "שם_רחוב": "היובלים"
        },
        {
            "_id": 14869,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 119,
            "שם_רחוב": "היער"
        },
        {
            "_id": 14870,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 340,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 14871,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 274,
            "שם_רחוב": "הכהנים"
        },
        {
            "_id": 14872,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 187,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 14873,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 182,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 14874,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 216,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 14875,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 198,
            "שם_רחוב": "הכשרת הישוב"
        },
        {
            "_id": 14876,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 435,
            "שם_רחוב": "הלויים"
        },
        {
            "_id": 14877,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 261,
            "שם_רחוב": "הלחי"
        },
        {
            "_id": 14878,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 346,
            "שם_רחוב": "המגן"
        },
        {
            "_id": 14879,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 224,
            "שם_רחוב": "המדרגות"
        },
        {
            "_id": 14880,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 230,
            "שם_רחוב": "המוסדות"
        },
        {
            "_id": 14881,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 252,
            "שם_רחוב": "המחתרות"
        },
        {
            "_id": 14882,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 220,
            "שם_רחוב": "המיסד"
        },
        {
            "_id": 14883,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 164,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 14884,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 240,
            "שם_רחוב": "המכונאי"
        },
        {
            "_id": 14885,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 143,
            "שם_רחוב": "המנחם"
        },
        {
            "_id": 14886,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 362,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 14887,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 214,
            "שם_רחוב": "המעבר"
        },
        {
            "_id": 14888,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 335,
            "שם_רחוב": "המעגל"
        },
        {
            "_id": 14889,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 236,
            "שם_רחוב": "המעלה"
        },
        {
            "_id": 14890,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 213,
            "שם_רחוב": "המעלות"
        },
        {
            "_id": 14891,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 361,
            "שם_רחוב": "המעפיל"
        },
        {
            "_id": 14892,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 363,
            "שם_רחוב": "המקשר"
        },
        {
            "_id": 14893,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 342,
            "שם_רחוב": "המתנחלים"
        },
        {
            "_id": 14894,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 339,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 14895,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 401,
            "שם_רחוב": "הנגר"
        },
        {
            "_id": 14896,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 170,
            "שם_רחוב": "הנוטרים"
        },
        {
            "_id": 14897,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 324,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 14898,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 14899,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 243,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 14900,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 374,
            "שם_רחוב": "הסדן"
        },
        {
            "_id": 14901,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 135,
            "שם_רחוב": "הסוכנות"
        },
        {
            "_id": 14902,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 348,
            "שם_רחוב": "הסרגל"
        },
        {
            "_id": 14903,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 146,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 14904,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 325,
            "שם_רחוב": "העובד הציוני"
        },
        {
            "_id": 14905,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 215,
            "שם_רחוב": "העליות"
        },
        {
            "_id": 14906,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 14907,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 195,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 14908,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 231,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 14909,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 375,
            "שם_רחוב": "הפטיש"
        },
        {
            "_id": 14910,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 391,
            "שם_רחוב": "הפטל"
        },
        {
            "_id": 14911,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 14912,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 350,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 14913,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 190,
            "שם_רחוב": "הפרסה"
        },
        {
            "_id": 14914,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הצבי"
        },
        {
            "_id": 14915,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 189,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 14916,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 351,
            "שם_רחוב": "הצברים"
        },
        {
            "_id": 14917,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 194,
            "שם_רחוב": "הציונות"
        },
        {
            "_id": 14918,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 376,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 14919,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 412,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 14920,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 384,
            "שם_רחוב": "הר שגיא"
        },
        {
            "_id": 14921,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 141,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 14922,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 181,
            "שם_רחוב": "הרב ביטון"
        },
        {
            "_id": 14923,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 188,
            "שם_רחוב": "הרב שלום נגר"
        },
        {
            "_id": 14924,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 461,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 14925,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 338,
            "שם_רחוב": "הרמה"
        },
        {
            "_id": 14926,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 488,
            "שם_רחוב": "הרצוג חיים"
        },
        {
            "_id": 14927,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 315,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 14928,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 364,
            "שם_רחוב": "הרצפלד אברהם"
        },
        {
            "_id": 14929,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 159,
            "שם_רחוב": "הרקון"
        },
        {
            "_id": 14930,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 176,
            "שם_רחוב": "הרשות"
        },
        {
            "_id": 14931,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 166,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 14932,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 226,
            "שם_רחוב": "השושנים"
        },
        {
            "_id": 14933,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 365,
            "שם_רחוב": "השחר"
        },
        {
            "_id": 14934,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 483,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 14935,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 123,
            "שם_רחוב": "השלום"
        },
        {
            "_id": 14936,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 118,
            "שם_רחוב": "השלושה"
        },
        {
            "_id": 14937,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "השקמים"
        },
        {
            "_id": 14938,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 355,
            "שם_רחוב": "התחיה"
        },
        {
            "_id": 14939,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 144,
            "שם_רחוב": "התכלת"
        },
        {
            "_id": 14940,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התלמיד"
        },
        {
            "_id": 14941,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 155,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 14942,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 393,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 14943,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התקומה"
        },
        {
            "_id": 14944,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 411,
            "שם_רחוב": "וייצמן חיים"
        },
        {
            "_id": 14945,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 199,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 14946,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 440,
            "שם_רחוב": "זוויתן"
        },
        {
            "_id": 14947,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 533,
            "שם_רחוב": "זייד אלכסנדר"
        },
        {
            "_id": 14948,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 424,
            "שם_רחוב": "זלדה"
        },
        {
            "_id": 14949,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 280,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 14950,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 310,
            "שם_רחוב": "זקיף"
        },
        {
            "_id": 14951,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 462,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 14952,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 463,
            "שם_רחוב": "חודשי השנה"
        },
        {
            "_id": 14953,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 285,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 14954,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 513,
            "שם_רחוב": "חולות נודדים"
        },
        {
            "_id": 14955,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 258,
            "שם_רחוב": "חטיבה שבע"
        },
        {
            "_id": 14956,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 168,
            "שם_רחוב": "חטיבת גבעתי"
        },
        {
            "_id": 14957,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 304,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 14958,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 257,
            "שם_רחוב": "חטיבת הראל"
        },
        {
            "_id": 14959,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 260,
            "שם_רחוב": "חטיבת יפתח"
        },
        {
            "_id": 14960,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 514,
            "שם_רחוב": "חלוקי נחל"
        },
        {
            "_id": 14961,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 589,
            "שם_רחוב": "חליל"
        },
        {
            "_id": 14962,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 277,
            "שם_רחוב": "חלמיש"
        },
        {
            "_id": 14963,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 484,
            "שם_רחוב": "חמנית"
        },
        {
            "_id": 14964,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 308,
            "שם_רחוב": "חנקין"
        },
        {
            "_id": 14965,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 464,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 14966,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 586,
            "שם_רחוב": "חצוצרה"
        },
        {
            "_id": 14967,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 457,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 14968,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 465,
            "שם_רחוב": "חשוון"
        },
        {
            "_id": 14969,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 466,
            "שם_רחוב": "טבת"
        },
        {
            "_id": 14970,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 287,
            "שם_רחוב": "טווס"
        },
        {
            "_id": 14971,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 515,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 14972,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 366,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 14973,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 247,
            "שם_רחוב": "טשרניחובסקי"
        },
        {
            "_id": 14974,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 248,
            "שם_רחוב": "י ל פרץ"
        },
        {
            "_id": 14975,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 379,
            "שם_רחוב": "ידידות"
        },
        {
            "_id": 14976,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 266,
            "שם_רחוב": "ידין יגאל"
        },
        {
            "_id": 14977,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 197,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 14978,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 196,
            "שם_רחוב": "יהונתן"
        },
        {
            "_id": 14979,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 297,
            "שם_רחוב": "יהושע בן נון"
        },
        {
            "_id": 14980,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 516,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 14981,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 183,
            "שם_רחוב": "יוחנן"
        },
        {
            "_id": 14982,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 429,
            "שם_רחוב": "יונה וולך"
        },
        {
            "_id": 14983,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 291,
            "שם_רחוב": "יונק הדבש"
        },
        {
            "_id": 14984,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 138,
            "שם_רחוב": "יורדי הים"
        },
        {
            "_id": 14985,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 563,
            "שם_רחוב": "יחזקאל"
        },
        {
            "_id": 14986,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 306,
            "שם_רחוב": "יסוד המעלה"
        },
        {
            "_id": 14987,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 467,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 14988,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 532,
            "שם_רחוב": "יצחק שדה"
        },
        {
            "_id": 14989,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 468,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 14990,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 207,
            "שם_רחוב": "ירושלמי"
        },
        {
            "_id": 14991,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 445,
            "שם_רחוב": "ירמוך"
        },
        {
            "_id": 14992,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 562,
            "שם_רחוב": "ירמיהו"
        },
        {
            "_id": 14993,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ישורון"
        },
        {
            "_id": 14994,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 564,
            "שם_רחוב": "ישעיהו"
        },
        {
            "_id": 14995,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 517,
            "שם_רחוב": "ישפה"
        },
        {
            "_id": 14996,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 172,
            "שם_רחוב": "ישראלי הרופא"
        },
        {
            "_id": 14997,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 229,
            "שם_רחוב": "כוכבן"
        },
        {
            "_id": 14998,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 448,
            "שם_רחוב": "כזיב"
        },
        {
            "_id": 14999,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "כיכר ותיקי העיר"
        },
        {
            "_id": 15000,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 584,
            "שם_רחוב": "כינור"
        },
        {
            "_id": 15001,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר אוסישקין"
        },
        {
            "_id": 15002,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר המגינים"
        },
        {
            "_id": 15003,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר טוביה"
        },
        {
            "_id": 15004,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 341,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 15005,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 469,
            "שם_רחוב": "כסלו"
        },
        {
            "_id": 15006,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "כפר הדר"
        },
        {
            "_id": 15007,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 555,
            "שם_רחוב": "כפר מנחם"
        },
        {
            "_id": 15008,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 232,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 15009,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 270,
            "שם_רחוב": "לב השכונה"
        },
        {
            "_id": 15010,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 470,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 15011,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 407,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 15012,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 265,
            "שם_רחוב": "לסקוב חיים"
        },
        {
            "_id": 15013,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 518,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 15014,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 415,
            "שם_רחוב": "מאנגר איציק"
        },
        {
            "_id": 15015,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 398,
            "שם_רחוב": "מבוא חמה"
        },
        {
            "_id": 15016,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 403,
            "שם_רחוב": "מבוא קדם"
        },
        {
            "_id": 15017,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 378,
            "שם_רחוב": "מבוי החרש"
        },
        {
            "_id": 15018,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 337,
            "שם_רחוב": "מבצע משה"
        },
        {
            "_id": 15019,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 322,
            "שם_רחוב": "מבצע קדש"
        },
        {
            "_id": 15020,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 331,
            "שם_רחוב": "מבצע שלמה"
        },
        {
            "_id": 15021,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "מגדיאל"
        },
        {
            "_id": 15022,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 162,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 15023,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 367,
            "שם_רחוב": "מולדת"
        },
        {
            "_id": 15024,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 323,
            "שם_רחוב": "מוסד מוסנזון"
        },
        {
            "_id": 15025,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 212,
            "שם_רחוב": "מורדי הגטאות"
        },
        {
            "_id": 15026,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 380,
            "שם_רחוב": "מורשת"
        },
        {
            "_id": 15027,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 491,
            "שם_רחוב": "מזל אריה"
        },
        {
            "_id": 15028,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 509,
            "שם_רחוב": "מזל בתולה"
        },
        {
            "_id": 15029,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 492,
            "שם_רחוב": "מזל גדי"
        },
        {
            "_id": 15030,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 493,
            "שם_רחוב": "מזל דגים"
        },
        {
            "_id": 15031,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 494,
            "שם_רחוב": "מזל דלי"
        },
        {
            "_id": 15032,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 495,
            "שם_רחוב": "מזל טלה"
        },
        {
            "_id": 15033,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 496,
            "שם_רחוב": "מזל מאזניים"
        },
        {
            "_id": 15034,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 497,
            "שם_רחוב": "מזל עקרב"
        },
        {
            "_id": 15035,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 498,
            "שם_רחוב": "מזל קשת"
        },
        {
            "_id": 15036,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 499,
            "שם_רחוב": "מזל שור"
        },
        {
            "_id": 15037,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 500,
            "שם_רחוב": "מזל תאומים"
        },
        {
            "_id": 15038,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 455,
            "שם_רחוב": "מיטל"
        },
        {
            "_id": 15039,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 590,
            "שם_רחוב": "מיתר"
        },
        {
            "_id": 15040,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 206,
            "שם_רחוב": "מנוחה ונחלה"
        },
        {
            "_id": 15041,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 580,
            "שם_רחוב": "מנצח"
        },
        {
            "_id": 15042,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 309,
            "שם_רחוב": "מעיני"
        },
        {
            "_id": 15043,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 593,
            "שם_רחוב": "מפוחית"
        },
        {
            "_id": 15044,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 369,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 15045,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 447,
            "שם_רחוב": "מצובה"
        },
        {
            "_id": 15046,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 592,
            "שם_רחוב": "מצילתיים"
        },
        {
            "_id": 15047,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 356,
            "שם_רחוב": "מרגוע"
        },
        {
            "_id": 15048,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 293,
            "שם_רחוב": "מרגלית"
        },
        {
            "_id": 15049,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 368,
            "שם_רחוב": "מרחביה"
        },
        {
            "_id": 15050,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 157,
            "שם_רחוב": "מרים החשמונאית"
        },
        {
            "_id": 15051,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "משאבים"
        },
        {
            "_id": 15052,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 446,
            "שם_רחוב": "משושים"
        },
        {
            "_id": 15053,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 171,
            "שם_רחוב": "משעול הגפנים"
        },
        {
            "_id": 15054,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 332,
            "שם_רחוב": "מתתיהו"
        },
        {
            "_id": 15055,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 433,
            "שם_רחוב": "נופית"
        },
        {
            "_id": 15056,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 519,
            "שם_רחוב": "נופך"
        },
        {
            "_id": 15057,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 471,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 15058,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 507,
            "שם_רחוב": "נחל בשור"
        },
        {
            "_id": 15059,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 501,
            "שם_רחוב": "נחל דליות"
        },
        {
            "_id": 15060,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 426,
            "שם_רחוב": "נחל הדס"
        },
        {
            "_id": 15061,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 428,
            "שם_רחוב": "נחל הדר"
        },
        {
            "_id": 15062,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 427,
            "שם_רחוב": "נחל ירקון"
        },
        {
            "_id": 15063,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 502,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 15064,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 503,
            "שם_רחוב": "נחל פרצים"
        },
        {
            "_id": 15065,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 506,
            "שם_רחוב": "נחל צין"
        },
        {
            "_id": 15066,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 425,
            "שם_רחוב": "נחל קנה"
        },
        {
            "_id": 15067,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 288,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 15068,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 382,
            "שם_רחוב": "נחשון"
        },
        {
            "_id": 15069,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 347,
            "שם_רחוב": "נטעים"
        },
        {
            "_id": 15070,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 329,
            "שם_רחוב": "ניצנים"
        },
        {
            "_id": 15071,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 201,
            "שם_רחוב": "נעורים"
        },
        {
            "_id": 15072,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 439,
            "שם_רחוב": "נעמי שמר"
        },
        {
            "_id": 15073,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 307,
            "שם_רחוב": "נצח ישראל"
        },
        {
            "_id": 15074,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 209,
            "שם_רחוב": "סאלד הנריאטה"
        },
        {
            "_id": 15075,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 311,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 15076,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 422,
            "שם_רחוב": "סטפניה"
        },
        {
            "_id": 15077,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 473,
            "שם_רחוב": "סיון"
        },
        {
            "_id": 15078,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 167,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 15079,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 474,
            "שם_רחוב": "סיתונית"
        },
        {
            "_id": 15080,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 432,
            "שם_רחוב": "סמ אבני דרך"
        },
        {
            "_id": 15081,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 184,
            "שם_רחוב": "סמ אגוז"
        },
        {
            "_id": 15082,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 395,
            "שם_רחוב": "סמ אדמונית"
        },
        {
            "_id": 15083,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 410,
            "שם_רחוב": "סמ אילת"
        },
        {
            "_id": 15084,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "סמ בית הבד"
        },
        {
            "_id": 15085,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 359,
            "שם_רחוב": "סמ האלון"
        },
        {
            "_id": 15086,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 485,
            "שם_רחוב": "סמ האקליפטוס"
        },
        {
            "_id": 15087,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 134,
            "שם_רחוב": "סמ האתרוג"
        },
        {
            "_id": 15088,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 139,
            "שם_רחוב": "סמ הדבש"
        },
        {
            "_id": 15089,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 142,
            "שם_רחוב": "סמ הזית"
        },
        {
            "_id": 15090,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 136,
            "שם_רחוב": "סמ החיטה"
        },
        {
            "_id": 15091,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סמ היח"
        },
        {
            "_id": 15092,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 154,
            "שם_רחוב": "סמ הירק"
        },
        {
            "_id": 15093,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 133,
            "שם_רחוב": "סמ הלולב"
        },
        {
            "_id": 15094,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 420,
            "שם_רחוב": "סמ הר גילה"
        },
        {
            "_id": 15095,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 132,
            "שם_רחוב": "סמ הרימון"
        },
        {
            "_id": 15096,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 130,
            "שם_רחוב": "סמ השעורה"
        },
        {
            "_id": 15097,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 174,
            "שם_רחוב": "סמ השריג"
        },
        {
            "_id": 15098,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 131,
            "שם_רחוב": "סמ התאנה"
        },
        {
            "_id": 15099,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 430,
            "שם_רחוב": "סמ נוגה"
        },
        {
            "_id": 15100,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 259,
            "שם_רחוב": "סמ נילי"
        },
        {
            "_id": 15101,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 381,
            "שם_רחוב": "סמ תירוש"
        },
        {
            "_id": 15102,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 253,
            "שם_רחוב": "סמטת אבן"
        },
        {
            "_id": 15103,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 326,
            "שם_רחוב": "סמטת הרינה"
        },
        {
            "_id": 15104,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 281,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 15105,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 530,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 15106,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 208,
            "שם_רחוב": "סעדיה גאון"
        },
        {
            "_id": 15107,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 278,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 15108,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 594,
            "שם_רחוב": "סקסופון"
        },
        {
            "_id": 15109,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 406,
            "שם_רחוב": "סתיו"
        },
        {
            "_id": 15110,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 250,
            "שם_רחוב": "עגנון"
        },
        {
            "_id": 15111,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 583,
            "שם_רחוב": "עוגב"
        },
        {
            "_id": 15112,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 489,
            "שם_רחוב": "עזר וייצמן"
        },
        {
            "_id": 15113,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 558,
            "שם_רחוב": "עין דור"
        },
        {
            "_id": 15114,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "עין חי"
        },
        {
            "_id": 15115,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 475,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 15116,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 377,
            "שם_רחוב": "עלית הנוער"
        },
        {
            "_id": 15117,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 437,
            "שם_רחוב": "ענת גוב"
        },
        {
            "_id": 15118,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 451,
            "שם_רחוב": "עקלתון"
        },
        {
            "_id": 15119,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 371,
            "שם_רחוב": "פדויים"
        },
        {
            "_id": 15120,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 535,
            "שם_רחוב": "פיינברג אבשלום"
        },
        {
            "_id": 15121,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 169,
            "שם_רחוב": "פלמח"
        },
        {
            "_id": 15122,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 418,
            "שם_רחוב": "פן אלכסנדר"
        },
        {
            "_id": 15123,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 587,
            "שם_רחוב": "פסנתר"
        },
        {
            "_id": 15124,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 476,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 15125,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 477,
            "שם_רחוב": "פרג"
        },
        {
            "_id": 15126,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 289,
            "שם_רחוב": "פשוש"
        },
        {
            "_id": 15127,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 588,
            "שם_רחוב": "צ'לו"
        },
        {
            "_id": 15128,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 202,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 15129,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 295,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 15130,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 276,
            "שם_רחוב": "צור"
        },
        {
            "_id": 15131,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 520,
            "שם_רחוב": "צורן"
        },
        {
            "_id": 15132,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 585,
            "שם_רחוב": "צליל"
        },
        {
            "_id": 15133,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 444,
            "שם_רחוב": "צלמון"
        },
        {
            "_id": 15134,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 305,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 15135,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 316,
            "שם_רחוב": "קורצ'אק יאנוש"
        },
        {
            "_id": 15136,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 556,
            "שם_רחוב": "קיבוץ דליה"
        },
        {
            "_id": 15137,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 553,
            "שם_רחוב": "קיבוץ יסודות"
        },
        {
            "_id": 15138,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 441,
            "שם_רחוב": "קישון"
        },
        {
            "_id": 15139,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 487,
            "שם_רחוב": "קציר אפריים"
        },
        {
            "_id": 15140,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 423,
            "שם_רחוב": "רונית אלקבץ"
        },
        {
            "_id": 15141,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 478,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 15142,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 538,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 15143,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 373,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 15144,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "רמות מנחם"
        },
        {
            "_id": 15145,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 177,
            "שם_רחוב": "רמז"
        },
        {
            "_id": 15146,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 152,
            "שם_רחוב": "רעות"
        },
        {
            "_id": 15147,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 372,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 15148,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 385,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 15149,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "ש בלוקונים"
        },
        {
            "_id": 15150,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש גיורא"
        },
        {
            "_id": 15151,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש גיל עמל"
        },
        {
            "_id": 15152,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש מזרחי"
        },
        {
            "_id": 15153,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש נוה הדר"
        },
        {
            "_id": 15154,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "ש נוה נאמן"
        },
        {
            "_id": 15155,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "ש נוה עובד"
        },
        {
            "_id": 15156,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "ש סולל בונה"
        },
        {
            "_id": 15157,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "ש עמידר צריפים"
        },
        {
            "_id": 15158,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "ש עממי"
        },
        {
            "_id": 15159,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש פועלים א"
        },
        {
            "_id": 15160,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש פועלים ב"
        },
        {
            "_id": 15161,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "ש פתוח"
        },
        {
            "_id": 15162,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "ש רמת הדר"
        },
        {
            "_id": 15163,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "ש רסקו"
        },
        {
            "_id": 15164,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "ש שרת"
        },
        {
            "_id": 15165,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "ש תימנים מגדיאל"
        },
        {
            "_id": 15166,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 299,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 15167,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 521,
            "שם_רחוב": "שבו"
        },
        {
            "_id": 15168,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 479,
            "שם_רחוב": "שבט"
        },
        {
            "_id": 15169,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 353,
            "שם_רחוב": "שבי ציון"
        },
        {
            "_id": 15170,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 399,
            "שם_רחוב": "שביל החלב"
        },
        {
            "_id": 15171,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 193,
            "שם_רחוב": "שביל התיכון"
        },
        {
            "_id": 15172,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 140,
            "שם_רחוב": "שביל התפוזים"
        },
        {
            "_id": 15173,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 431,
            "שם_רחוב": "שבתאי"
        },
        {
            "_id": 15174,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 178,
            "שם_רחוב": "שד האוטהקר"
        },
        {
            "_id": 15175,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 408,
            "שם_רחוב": "שד הנשיאים"
        },
        {
            "_id": 15176,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 413,
            "שם_רחוב": "שזר זלמן"
        },
        {
            "_id": 15177,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 321,
            "שם_רחוב": "שחל"
        },
        {
            "_id": 15178,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 279,
            "שם_רחוב": "שחם"
        },
        {
            "_id": 15179,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 282,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 15180,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 271,
            "שם_רחוב": "שטיין"
        },
        {
            "_id": 15181,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 531,
            "שם_רחוב": "שטרן אברהם"
        },
        {
            "_id": 15182,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 396,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 15183,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 148,
            "שם_רחוב": "שילה"
        },
        {
            "_id": 15184,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "שכ הכשרת הקיבוצים"
        },
        {
            "_id": 15185,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "שכ הנביאים"
        },
        {
            "_id": 15186,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "שכ העובד הציוני"
        },
        {
            "_id": 15187,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "שכ מלכים ושופטים"
        },
        {
            "_id": 15188,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "שכ רמתיים"
        },
        {
            "_id": 15189,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "שכון חדש"
        },
        {
            "_id": 15190,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 283,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 15191,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 254,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 15192,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 158,
            "שם_רחוב": "שלומציון"
        },
        {
            "_id": 15193,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 294,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 15194,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 296,
            "שם_רחוב": "שמגר בן ענת"
        },
        {
            "_id": 15195,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 561,
            "שם_רחוב": "שמואל הנביא"
        },
        {
            "_id": 15196,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 313,
            "שם_רחוב": "שמיר"
        },
        {
            "_id": 15197,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 160,
            "שם_רחוב": "שמעון הצדיק"
        },
        {
            "_id": 15198,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 443,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 15199,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 390,
            "שם_רחוב": "שנת היובל"
        },
        {
            "_id": 15200,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 551,
            "שם_רחוב": "שער העמקים"
        },
        {
            "_id": 15201,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 320,
            "שם_רחוב": "שפרינצק"
        },
        {
            "_id": 15202,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 286,
            "שם_רחוב": "שקנאי"
        },
        {
            "_id": 15203,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 185,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 15204,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 302,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 15205,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 354,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 15206,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 581,
            "שם_רחוב": "תווים"
        },
        {
            "_id": 15207,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 591,
            "שם_רחוב": "תוף"
        },
        {
            "_id": 15208,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 290,
            "שם_רחוב": "תור"
        },
        {
            "_id": 15209,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 223,
            "שם_רחוב": "תל דן"
        },
        {
            "_id": 15210,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 554,
            "שם_רחוב": "תל יצחק"
        },
        {
            "_id": 15211,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 481,
            "שם_רחוב": "תמוז"
        },
        {
            "_id": 15212,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 242,
            "שם_רחוב": "תרפה"
        },
        {
            "_id": 15213,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 523,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 15214,
            "סמל_ישוב": 9700,
            "שם_ישוב": "הוד השרון ",
            "סמל_רחוב": 482,
            "שם_רחוב": "תשרי"
        },
        {
            "_id": 15215,
            "סמל_ישוב": 726,
            "שם_ישוב": "הודיה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הודיה"
        },
        {
            "_id": 15216,
            "סמל_ישוב": 1322,
            "שם_ישוב": "הודיות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הודיות"
        },
        {
            "_id": 15217,
            "סמל_ישוב": 1169,
            "שם_ישוב": "הוואשלה (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הוואשלה (שבט)"
        },
        {
            "_id": 15218,
            "סמל_ישוב": 956,
            "שם_ישוב": "הוזייל (שבט) ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אבו זאיד"
        },
        {
            "_id": 15219,
            "סמל_ישוב": 956,
            "שם_ישוב": "הוזייל (שבט) ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אל טורי"
        },
        {
            "_id": 15220,
            "סמל_ישוב": 956,
            "שם_ישוב": "הוזייל (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הוזייל (שבט)"
        },
        {
            "_id": 15221,
            "סמל_ישוב": 956,
            "שם_ישוב": "הוזייל (שבט) ",
            "סמל_רחוב": 104,
            "שם_רחוב": "סקר הזיל"
        },
        {
            "_id": 15222,
            "סמל_ישוב": 956,
            "שם_ישוב": "הוזייל (שבט) ",
            "סמל_רחוב": 101,
            "שם_רחוב": "עי נחל גרר"
        },
        {
            "_id": 15223,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בדולח"
        },
        {
            "_id": 15224,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "גדיד"
        },
        {
            "_id": 15225,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "גן-אור"
        },
        {
            "_id": 15226,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גני טל"
        },
        {
            "_id": 15227,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דוגית"
        },
        {
            "_id": 15228,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הושעיה"
        },
        {
            "_id": 15229,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "כפר דרום"
        },
        {
            "_id": 15230,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מורג"
        },
        {
            "_id": 15231,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "נצר חזני"
        },
        {
            "_id": 15232,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נצרים"
        },
        {
            "_id": 15233,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "פאת השדה"
        },
        {
            "_id": 15234,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "קטיף"
        },
        {
            "_id": 15235,
            "סמל_ישוב": 1186,
            "שם_ישוב": "הושעיה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שירת הים"
        },
        {
            "_id": 15236,
            "סמל_ישוב": 250,
            "שם_ישוב": "הזורע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הזורע"
        },
        {
            "_id": 15237,
            "סמל_ישוב": 307,
            "שם_ישוב": "הזורעים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הזורעים"
        },
        {
            "_id": 15238,
            "סמל_ישוב": 434,
            "שם_ישוב": "החותרים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "החותרים"
        },
        {
            "_id": 15239,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "גבעת הטרשים"
        },
        {
            "_id": 15240,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך בית עלמין"
        },
        {
            "_id": 15241,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך הבוצרים"
        },
        {
            "_id": 15242,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דרך הזורעים"
        },
        {
            "_id": 15243,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דרך החולבים"
        },
        {
            "_id": 15244,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דרך הכורמים"
        },
        {
            "_id": 15245,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 112,
            "שם_רחוב": "דרך המוסקים"
        },
        {
            "_id": 15246,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך הרועות"
        },
        {
            "_id": 15247,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 114,
            "שם_רחוב": "דרך השדות"
        },
        {
            "_id": 15248,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך השותלות"
        },
        {
            "_id": 15249,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הגפנים"
        },
        {
            "_id": 15250,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הזיתים"
        },
        {
            "_id": 15251,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 15252,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "היוגב"
        },
        {
            "_id": 15253,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הנוטעות"
        },
        {
            "_id": 15254,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הקוצר"
        },
        {
            "_id": 15255,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הראשונות"
        },
        {
            "_id": 15256,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 116,
            "שם_רחוב": "השיבולים"
        },
        {
            "_id": 15257,
            "סמל_ישוב": 684,
            "שם_ישוב": "היוגב ",
            "סמל_רחוב": 115,
            "שם_רחוב": "השקדיות"
        },
        {
            "_id": 15258,
            "סמל_ישוב": 1208,
            "שם_ישוב": "הילה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 15259,
            "סמל_ישוב": 1208,
            "שם_ישוב": "הילה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 15260,
            "סמל_ישוב": 1208,
            "שם_ישוב": "הילה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גומא"
        },
        {
            "_id": 15261,
            "סמל_ישוב": 1208,
            "שם_ישוב": "הילה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 15262,
            "סמל_ישוב": 1208,
            "שם_ישוב": "הילה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 15263,
            "סמל_ישוב": 1208,
            "שם_ישוב": "הילה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הילה"
        },
        {
            "_id": 15264,
            "סמל_ישוב": 1208,
            "שם_ישוב": "הילה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מעלה ההר"
        },
        {
            "_id": 15265,
            "סמל_ישוב": 377,
            "שם_ישוב": "המעפיל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "המעפיל"
        },
        {
            "_id": 15266,
            "סמל_ישוב": 677,
            "שם_ישוב": "הסוללים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הסוללים"
        },
        {
            "_id": 15267,
            "סמל_ישוב": 423,
            "שם_ישוב": "העוגן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "העוגן"
        },
        {
            "_id": 15268,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 15269,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 15270,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 15271,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 15272,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הגיא"
        },
        {
            "_id": 15273,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 15274,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הר אדר"
        },
        {
            "_id": 15275,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 15276,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "מבוא האגוז"
        },
        {
            "_id": 15277,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 132,
            "שם_רחוב": "מבוא האור"
        },
        {
            "_id": 15278,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "מבוא הארז"
        },
        {
            "_id": 15279,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "מבוא הבוסתן"
        },
        {
            "_id": 15280,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מבוא הגפן"
        },
        {
            "_id": 15281,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מבוא ההדס"
        },
        {
            "_id": 15282,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "מבוא הזית"
        },
        {
            "_id": 15283,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 130,
            "שם_רחוב": "מבוא החורש"
        },
        {
            "_id": 15284,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "מבוא החלילים"
        },
        {
            "_id": 15285,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "מבוא החרוב"
        },
        {
            "_id": 15286,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 129,
            "שם_רחוב": "מבוא היער"
        },
        {
            "_id": 15287,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "מבוא הכפר"
        },
        {
            "_id": 15288,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מבוא הכרמים"
        },
        {
            "_id": 15289,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 131,
            "שם_רחוב": "מבוא הלבונה"
        },
        {
            "_id": 15290,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "מבוא המטע"
        },
        {
            "_id": 15291,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "מבוא הנטף"
        },
        {
            "_id": 15292,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "מבוא הפסגה"
        },
        {
            "_id": 15293,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 128,
            "שם_רחוב": "מבוא הרועים"
        },
        {
            "_id": 15294,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מבוא הרימון"
        },
        {
            "_id": 15295,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 126,
            "שם_רחוב": "מבוא הרכס"
        },
        {
            "_id": 15296,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "מבוא התאנה"
        },
        {
            "_id": 15297,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מבוא התומר"
        },
        {
            "_id": 15298,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "נוף הרים"
        },
        {
            "_id": 15299,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "שביל השלושה"
        },
        {
            "_id": 15300,
            "סמל_ישוב": 3769,
            "שם_ישוב": "הר אדר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "שד הראל"
        },
        {
            "_id": 15301,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך ההר"
        },
        {
            "_id": 15302,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 15303,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 15304,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 15305,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הליבנה"
        },
        {
            "_id": 15306,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הקטלב"
        },
        {
            "_id": 15307,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הר גילה"
        },
        {
            "_id": 15308,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 15309,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "יורם פיצ'י בן מאיר"
        },
        {
            "_id": 15310,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "משעול החלמונית"
        },
        {
            "_id": 15311,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 100,
            "שם_רחוב": "משעול החצב"
        },
        {
            "_id": 15312,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "משעול המרווה"
        },
        {
            "_id": 15313,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "משעול הסלעית"
        },
        {
            "_id": 15314,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "משעול העיט הזהוב"
        },
        {
            "_id": 15315,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "משעול העפרוני"
        },
        {
            "_id": 15316,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "משעול הצופית"
        },
        {
            "_id": 15317,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "משעול הקורנית"
        },
        {
            "_id": 15318,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "משעול הקיכלי"
        },
        {
            "_id": 15319,
            "סמל_ישוב": 3603,
            "שם_ישוב": "הר גילה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "משעול כליל החורש"
        },
        {
            "_id": 15320,
            "סמל_ישוב": 1261,
            "שם_ישוב": "הר עמשא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הר עמשא"
        },
        {
            "_id": 15321,
            "סמל_ישוב": 464,
            "שם_ישוב": "הראל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הראל"
        },
        {
            "_id": 15322,
            "סמל_ישוב": 1249,
            "שם_ישוב": "הרדוף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 15323,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אבוקה"
        },
        {
            "_id": 15324,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 482,
            "שם_רחוב": "אבטליון"
        },
        {
            "_id": 15325,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 496,
            "שם_רחוב": "אביב גל"
        },
        {
            "_id": 15326,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 754,
            "שם_רחוב": "אביגיל"
        },
        {
            "_id": 15327,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 500,
            "שם_רחוב": "אבן אודם"
        },
        {
            "_id": 15328,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 541,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 15329,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 542,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 15330,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 543,
            "שם_רחוב": "אבן שפרוט"
        },
        {
            "_id": 15331,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 544,
            "שם_רחוב": "אבן תיבון"
        },
        {
            "_id": 15332,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 953,
            "שם_רחוב": "אבניים"
        },
        {
            "_id": 15333,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 755,
            "שם_רחוב": "אבנר"
        },
        {
            "_id": 15334,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 451,
            "שם_רחוב": "אברבנאל"
        },
        {
            "_id": 15335,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 750,
            "שם_רחוב": "אגדת דשא"
        },
        {
            "_id": 15336,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אגס"
        },
        {
            "_id": 15337,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 590,
            "שם_רחוב": "אגרת תימן"
        },
        {
            "_id": 15338,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 388,
            "שם_רחוב": "אהבת ציון"
        },
        {
            "_id": 15339,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 812,
            "שם_רחוב": "אהרונסון דשה"
        },
        {
            "_id": 15340,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 873,
            "שם_רחוב": "אוטה וליש"
        },
        {
            "_id": 15341,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 586,
            "שם_רחוב": "אונקלוס"
        },
        {
            "_id": 15342,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 419,
            "שם_רחוב": "אוסישקין"
        },
        {
            "_id": 15343,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 619,
            "שם_רחוב": "אופיר"
        },
        {
            "_id": 15344,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 637,
            "שם_רחוב": "אופק אוריאל"
        },
        {
            "_id": 15345,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 598,
            "שם_רחוב": "אוצר הצמחים"
        },
        {
            "_id": 15346,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 832,
            "שם_רחוב": "אור"
        },
        {
            "_id": 15347,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 445,
            "שם_רחוב": "אור החיים"
        },
        {
            "_id": 15348,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 372,
            "שם_רחוב": "אזר"
        },
        {
            "_id": 15349,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "אזור התעשיה"
        },
        {
            "_id": 15350,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6561,
            "שם_רחוב": "אזור פתוח"
        },
        {
            "_id": 15351,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 629,
            "שם_רחוב": "אזור שפת הים"
        },
        {
            "_id": 15352,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "אזור תעשיה חדש"
        },
        {
            "_id": 15353,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 674,
            "שם_רחוב": "אחי אילת"
        },
        {
            "_id": 15354,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 675,
            "שם_רחוב": "אחי דקר"
        },
        {
            "_id": 15355,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 413,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 15356,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אחווה"
        },
        {
            "_id": 15357,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 613,
            "שם_רחוב": "אייבי נתן"
        },
        {
            "_id": 15358,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 580,
            "שם_רחוב": "איילת חן"
        },
        {
            "_id": 15359,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 814,
            "שם_רחוב": "איסר הראל"
        },
        {
            "_id": 15360,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 855,
            "שם_רחוב": "אירוסים"
        },
        {
            "_id": 15361,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 642,
            "שם_רחוב": "אל על"
        },
        {
            "_id": 15362,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 676,
            "שם_רחוב": "אלברט איינשטיין"
        },
        {
            "_id": 15363,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 562,
            "שם_רחוב": "אלדד הדני"
        },
        {
            "_id": 15364,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 399,
            "שם_רחוב": "אלומות"
        },
        {
            "_id": 15365,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 504,
            "שם_רחוב": "אלוף אלון יגאל"
        },
        {
            "_id": 15366,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 810,
            "שם_רחוב": "אלחריזי"
        },
        {
            "_id": 15367,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 803,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 15368,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 765,
            "שם_רחוב": "אלכסנדר ינאי"
        },
        {
            "_id": 15369,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1386,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 15370,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלן טיורינג"
        },
        {
            "_id": 15371,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 591,
            "שם_רחוב": "אלפסי"
        },
        {
            "_id": 15372,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 567,
            "שם_רחוב": "אלקלעי"
        },
        {
            "_id": 15373,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 317,
            "שם_רחוב": "אלרואי דוד"
        },
        {
            "_id": 15374,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 464,
            "שם_רחוב": "אלתרמן נתן"
        },
        {
            "_id": 15375,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 360,
            "שם_רחוב": "אמירים"
        },
        {
            "_id": 15376,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 671,
            "שם_רחוב": "אנדה"
        },
        {
            "_id": 15377,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 490,
            "שם_רחוב": "אנה פרנק"
        },
        {
            "_id": 15378,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 550,
            "שם_רחוב": "אנילביץ"
        },
        {
            "_id": 15379,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 952,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 15380,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 560,
            "שם_רחוב": "אסירי ציון"
        },
        {
            "_id": 15381,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 625,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 15382,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 829,
            "שם_רחוב": "אסתר ראב"
        },
        {
            "_id": 15383,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אף על פי כן"
        },
        {
            "_id": 15384,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1382,
            "שם_רחוב": "אפיק"
        },
        {
            "_id": 15385,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 767,
            "שם_רחוב": "אפרים"
        },
        {
            "_id": 15386,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אפרסמון"
        },
        {
            "_id": 15387,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 678,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 15388,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 790,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 15389,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 867,
            "שם_רחוב": "ארגוב סשה"
        },
        {
            "_id": 15390,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 575,
            "שם_רחוב": "אריאל"
        },
        {
            "_id": 15391,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 833,
            "שם_רחוב": "אריאל שרון"
        },
        {
            "_id": 15392,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אריה זייף"
        },
        {
            "_id": 15393,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 845,
            "שם_רחוב": "אריק איינשטיין"
        },
        {
            "_id": 15394,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 656,
            "שם_רחוב": "אריק לביא"
        },
        {
            "_id": 15395,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 15396,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 824,
            "שם_רחוב": "ארן זלמן"
        },
        {
            "_id": 15397,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 350,
            "שם_רחוב": "ארנסט וסוזי וודק"
        },
        {
            "_id": 15398,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 876,
            "שם_רחוב": "ארץ צבי"
        },
        {
            "_id": 15399,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1383,
            "שם_רחוב": "אשד"
        },
        {
            "_id": 15400,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 335,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 15401,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 858,
            "שם_רחוב": "בבה אידלסון"
        },
        {
            "_id": 15402,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 738,
            "שם_רחוב": "בובר מרטין"
        },
        {
            "_id": 15403,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 578,
            "שם_רחוב": "בוסל"
        },
        {
            "_id": 15404,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 830,
            "שם_רחוב": "בוקר"
        },
        {
            "_id": 15405,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 15406,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 820,
            "שם_רחוב": "בורלא יהודה"
        },
        {
            "_id": 15407,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 459,
            "שם_רחוב": "בורר יהודה"
        },
        {
            "_id": 15408,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 620,
            "שם_רחוב": "בזל"
        },
        {
            "_id": 15409,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 15410,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 514,
            "שם_רחוב": "בילינסון"
        },
        {
            "_id": 15411,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 721,
            "שם_רחוב": "בית אבות סרינה"
        },
        {
            "_id": 15412,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 847,
            "שם_רחוב": "בית חלומותי"
        },
        {
            "_id": 15413,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 352,
            "שם_רחוב": "ביתר"
        },
        {
            "_id": 15414,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 737,
            "שם_רחוב": "בלפור"
        },
        {
            "_id": 15415,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 764,
            "שם_רחוב": "בן אליעזר אריה"
        },
        {
            "_id": 15416,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "בן גוריון"
        },
        {
            "_id": 15417,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 418,
            "שם_רחוב": "בן יהודה"
        },
        {
            "_id": 15418,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 689,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 15419,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 816,
            "שם_רחוב": "בן סרוק מנחם"
        },
        {
            "_id": 15420,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 461,
            "שם_רחוב": "בן שלום מאיר"
        },
        {
            "_id": 15421,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 513,
            "שם_רחוב": "בני בנימין"
        },
        {
            "_id": 15422,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 574,
            "שם_רחוב": "בצלאל"
        },
        {
            "_id": 15423,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 401,
            "שם_רחוב": "בר אילן"
        },
        {
            "_id": 15424,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 357,
            "שם_רחוב": "בר יוחאי"
        },
        {
            "_id": 15425,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 15426,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 854,
            "שם_רחוב": "בראון"
        },
        {
            "_id": 15427,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 506,
            "שם_רחוב": "ברוך"
        },
        {
            "_id": 15428,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 498,
            "שם_רחוב": "ברית עולם"
        },
        {
            "_id": 15429,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 801,
            "שם_רחוב": "ברכה צפירה"
        },
        {
            "_id": 15430,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 15431,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 450,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 15432,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 903,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 15433,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 566,
            "שם_רחוב": "ברש אשר"
        },
        {
            "_id": 15434,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 756,
            "שם_רחוב": "בת שבע"
        },
        {
            "_id": 15435,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 655,
            "שם_רחוב": "ג'ו עמר"
        },
        {
            "_id": 15436,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "גבורי עציון"
        },
        {
            "_id": 15437,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 443,
            "שם_רחוב": "גבעת החלומות"
        },
        {
            "_id": 15438,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 666,
            "שם_רחוב": "גבעת הסופר"
        },
        {
            "_id": 15439,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "גבעת הפרחים"
        },
        {
            "_id": 15440,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 672,
            "שם_רחוב": "גבעת התחמושת"
        },
        {
            "_id": 15441,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 837,
            "שם_רחוב": "גדעון האוזנר"
        },
        {
            "_id": 15442,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 665,
            "שם_רחוב": "גדעון השופט"
        },
        {
            "_id": 15443,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 653,
            "שם_רחוב": "גובר רבקה"
        },
        {
            "_id": 15444,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 597,
            "שם_רחוב": "גולדברג לאה"
        },
        {
            "_id": 15445,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 901,
            "שם_רחוב": "גולדה מאיר"
        },
        {
            "_id": 15446,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 522,
            "שם_רחוב": "גולומב אליהו"
        },
        {
            "_id": 15447,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "גורדון א ד"
        },
        {
            "_id": 15448,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "גיל"
        },
        {
            "_id": 15449,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 471,
            "שם_רחוב": "גינת רפול"
        },
        {
            "_id": 15450,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 486,
            "שם_רחוב": "גיסין"
        },
        {
            "_id": 15451,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 785,
            "שם_רחוב": "גיתית"
        },
        {
            "_id": 15452,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 709,
            "שם_רחוב": "גלגל הפלדה"
        },
        {
            "_id": 15453,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 649,
            "שם_רחוב": "גלי תכלת"
        },
        {
            "_id": 15454,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 344,
            "שם_רחוב": "גלילות"
        },
        {
            "_id": 15455,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "גני הרצליה"
        },
        {
            "_id": 15456,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 679,
            "שם_רחוב": "גרונר דב"
        },
        {
            "_id": 15457,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 825,
            "שם_רחוב": "גרינברג אורי צבי"
        },
        {
            "_id": 15458,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "גרמה זכריה"
        },
        {
            "_id": 15459,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 466,
            "שם_רחוב": "גת"
        },
        {
            "_id": 15460,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1389,
            "שם_רחוב": "דר צבי דינשטיין"
        },
        {
            "_id": 15461,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 839,
            "שם_רחוב": "דבורה עומר"
        },
        {
            "_id": 15462,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 519,
            "שם_רחוב": "דגניה"
        },
        {
            "_id": 15463,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 15464,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 769,
            "שם_רחוב": "דובנוב"
        },
        {
            "_id": 15465,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "דוד אלעזר"
        },
        {
            "_id": 15466,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 623,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 15467,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 380,
            "שם_רחוב": "דודאים"
        },
        {
            "_id": 15468,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 492,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 15469,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 397,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 15470,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 811,
            "שם_רחוב": "דון יוסף הנשיא"
        },
        {
            "_id": 15471,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 635,
            "שם_רחוב": "דורי"
        },
        {
            "_id": 15472,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "דיור עממי"
        },
        {
            "_id": 15473,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 348,
            "שם_רחוב": "דינור בן ציון"
        },
        {
            "_id": 15474,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "דיצה"
        },
        {
            "_id": 15475,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 576,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 15476,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 815,
            "שם_רחוב": "דליה רביקוביץ"
        },
        {
            "_id": 15477,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 577,
            "שם_רחוב": "דן"
        },
        {
            "_id": 15478,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 643,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 15479,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 491,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 15480,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 800,
            "שם_רחוב": "דרך אלטנוילנד"
        },
        {
            "_id": 15481,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 775,
            "שם_רחוב": "דרך דיין משה"
        },
        {
            "_id": 15482,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 549,
            "שם_רחוב": "דרך ירושלים"
        },
        {
            "_id": 15483,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "דרך רמת השרון"
        },
        {
            "_id": 15484,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 630,
            "שם_רחוב": "דרך תל אביב"
        },
        {
            "_id": 15485,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 554,
            "שם_רחוב": "ה באייר"
        },
        {
            "_id": 15486,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 636,
            "שם_רחוב": "האבקליפטוס"
        },
        {
            "_id": 15487,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 692,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 15488,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "האוניברסיטה"
        },
        {
            "_id": 15489,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 599,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 15490,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 558,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 15491,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 851,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 15492,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 693,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 15493,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 817,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 15494,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 15495,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 694,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 15496,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 609,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 15497,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 476,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 15498,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 488,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 15499,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 416,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 15500,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 331,
            "שם_רחוב": "הבעל שם טוב"
        },
        {
            "_id": 15501,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 611,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 15502,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 530,
            "שם_רחוב": "הבריגדה היהודית"
        },
        {
            "_id": 15503,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 951,
            "שם_רחוב": "הבשור"
        },
        {
            "_id": 15504,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הבשן"
        },
        {
            "_id": 15505,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 334,
            "שם_רחוב": "הגאון מוילנה"
        },
        {
            "_id": 15506,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 748,
            "שם_רחוב": "הגבורה"
        },
        {
            "_id": 15507,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 551,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 15508,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "הגדעונים"
        },
        {
            "_id": 15509,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 328,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 15510,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 414,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 15511,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1385,
            "שם_רחוב": "הגעש"
        },
        {
            "_id": 15512,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 607,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 15513,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 338,
            "שם_רחוב": "הדסה"
        },
        {
            "_id": 15514,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 478,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 15515,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 502,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 15516,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 503,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 15517,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 531,
            "שם_רחוב": "ההדסים"
        },
        {
            "_id": 15518,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 15519,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 412,
            "שם_רחוב": "הואדי"
        },
        {
            "_id": 15520,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 533,
            "שם_רחוב": "הוז דב"
        },
        {
            "_id": 15521,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 408,
            "שם_רחוב": "הולצברג שמחה"
        },
        {
            "_id": 15522,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 762,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 15523,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 458,
            "שם_רחוב": "הזוהר"
        },
        {
            "_id": 15524,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 499,
            "שם_רחוב": "הזז חיים"
        },
        {
            "_id": 15525,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 525,
            "שם_רחוב": "הזיתים"
        },
        {
            "_id": 15526,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 400,
            "שם_רחוב": "הזמר העברי"
        },
        {
            "_id": 15527,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 866,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 15528,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 715,
            "שם_רחוב": "החושלים"
        },
        {
            "_id": 15529,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "החיצים"
        },
        {
            "_id": 15530,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 390,
            "שם_רחוב": "החלום ופשרו"
        },
        {
            "_id": 15531,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 509,
            "שם_רחוב": "החלוץ"
        },
        {
            "_id": 15532,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1381,
            "שם_רחוב": "החליל"
        },
        {
            "_id": 15533,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 465,
            "שם_רחוב": "החנית"
        },
        {
            "_id": 15534,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 695,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 15535,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 696,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 15536,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 327,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 15537,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 470,
            "שם_רחוב": "החרמש"
        },
        {
            "_id": 15538,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 697,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 15539,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 391,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 15540,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "הטייסים"
        },
        {
            "_id": 15541,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 351,
            "שם_רחוב": "היא"
        },
        {
            "_id": 15542,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 463,
            "שם_רחוב": "הידיד"
        },
        {
            "_id": 15543,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "היורה"
        },
        {
            "_id": 15544,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 646,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 15545,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 484,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 15546,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 431,
            "שם_רחוב": "הירש"
        },
        {
            "_id": 15547,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 736,
            "שם_רחוב": "היתד"
        },
        {
            "_id": 15548,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 698,
            "שם_רחוב": "הכג"
        },
        {
            "_id": 15549,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 430,
            "שם_רחוב": "הכוזרי"
        },
        {
            "_id": 15550,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1394,
            "שם_רחוב": "הכינור"
        },
        {
            "_id": 15551,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 555,
            "שם_רחוב": "הכנסת"
        },
        {
            "_id": 15552,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 489,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 15553,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 329,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 15554,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 647,
            "שם_רחוב": "הליבנה"
        },
        {
            "_id": 15555,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 662,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 15556,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 433,
            "שם_רחוב": "הלל"
        },
        {
            "_id": 15557,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 703,
            "שם_רחוב": "הלני המלכה"
        },
        {
            "_id": 15558,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 634,
            "שם_רחוב": "המגינים"
        },
        {
            "_id": 15559,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 740,
            "שם_רחוב": "המדע"
        },
        {
            "_id": 15560,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 734,
            "שם_רחוב": "המדרון"
        },
        {
            "_id": 15561,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "המלקוש"
        },
        {
            "_id": 15562,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 604,
            "שם_רחוב": "המנהרה"
        },
        {
            "_id": 15563,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 714,
            "שם_רחוב": "המנופים"
        },
        {
            "_id": 15564,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 661,
            "שם_רחוב": "המסילה"
        },
        {
            "_id": 15565,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 702,
            "שם_רחוב": "המעבר"
        },
        {
            "_id": 15566,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 535,
            "שם_רחוב": "המעיין"
        },
        {
            "_id": 15567,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 660,
            "שם_רחוב": "המעלות"
        },
        {
            "_id": 15568,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 652,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 15569,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1384,
            "שם_רחוב": "המפל"
        },
        {
            "_id": 15570,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 799,
            "שם_רחוב": "המפרש"
        },
        {
            "_id": 15571,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 363,
            "שם_רחוב": "המקובלים"
        },
        {
            "_id": 15572,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 628,
            "שם_רחוב": "המרגנית"
        },
        {
            "_id": 15573,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 752,
            "שם_רחוב": "המרי"
        },
        {
            "_id": 15574,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 339,
            "שם_רחוב": "המתמיד"
        },
        {
            "_id": 15575,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 444,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 15576,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 534,
            "שם_רחוב": "הנדיב"
        },
        {
            "_id": 15577,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 456,
            "שם_רחוב": "הנוטרים"
        },
        {
            "_id": 15578,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 680,
            "שם_רחוב": "הנוריות"
        },
        {
            "_id": 15579,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 585,
            "שם_רחוב": "הניצנים"
        },
        {
            "_id": 15580,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 480,
            "שם_רחוב": "הנעורים"
        },
        {
            "_id": 15581,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 681,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 15582,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 608,
            "שם_רחוב": "הנשיא יצחק בן צבי"
        },
        {
            "_id": 15583,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 407,
            "שם_רחוב": "הס"
        },
        {
            "_id": 15584,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 707,
            "שם_רחוב": "הסדנאות"
        },
        {
            "_id": 15585,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "הסיגליות"
        },
        {
            "_id": 15586,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 699,
            "שם_רחוב": "הסייפנים"
        },
        {
            "_id": 15587,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 797,
            "שם_רחוב": "העוגן"
        },
        {
            "_id": 15588,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 955,
            "שם_רחוב": "העופר"
        },
        {
            "_id": 15589,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 722,
            "שם_רחוב": "העירית"
        },
        {
            "_id": 15590,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 479,
            "שם_רחוב": "העלומים"
        },
        {
            "_id": 15591,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 521,
            "שם_רחוב": "העליה השניה"
        },
        {
            "_id": 15592,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 421,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 15593,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 477,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 15594,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 606,
            "שם_רחוב": "הפועל"
        },
        {
            "_id": 15595,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 843,
            "שם_רחוב": "הפיוס"
        },
        {
            "_id": 15596,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 429,
            "שם_רחוב": "הפרדסים"
        },
        {
            "_id": 15597,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 852,
            "שם_רחוב": "הפרח בגני"
        },
        {
            "_id": 15598,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 548,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 15599,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הפרטיזנים"
        },
        {
            "_id": 15600,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 645,
            "שם_רחוב": "הפרסה"
        },
        {
            "_id": 15601,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 529,
            "שם_רחוב": "הצברים"
        },
        {
            "_id": 15602,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 798,
            "שם_רחוב": "הצדף"
        },
        {
            "_id": 15603,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 757,
            "שם_רחוב": "הצנחן אבא"
        },
        {
            "_id": 15604,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 15605,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 528,
            "שם_רחוב": "הצפצפות"
        },
        {
            "_id": 15606,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 615,
            "שם_רחוב": "הקדמה"
        },
        {
            "_id": 15607,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 739,
            "שם_רחוב": "הקוממיות"
        },
        {
            "_id": 15608,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 648,
            "שם_רחוב": "הקונגרס הציוני"
        },
        {
            "_id": 15609,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 485,
            "שם_רחוב": "הקוצרים"
        },
        {
            "_id": 15610,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 497,
            "שם_רחוב": "הקטיף"
        },
        {
            "_id": 15611,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 325,
            "שם_רחוב": "הקסם"
        },
        {
            "_id": 15612,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1379,
            "שם_רחוב": "הקרן"
        },
        {
            "_id": 15613,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 420,
            "שם_רחוב": "הקרן הקימת"
        },
        {
            "_id": 15614,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הר המוריה"
        },
        {
            "_id": 15615,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 373,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 15616,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הר נבו"
        },
        {
            "_id": 15617,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הר סיני"
        },
        {
            "_id": 15618,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הר עצמון"
        },
        {
            "_id": 15619,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הר שומרון"
        },
        {
            "_id": 15620,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 570,
            "שם_רחוב": "הראובני"
        },
        {
            "_id": 15621,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 508,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 15622,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 467,
            "שם_רחוב": "הרב גורן"
        },
        {
            "_id": 15623,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 437,
            "שם_רחוב": "הרב הלפרין מרדכי"
        },
        {
            "_id": 15624,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "הרב יוסף סאסי"
        },
        {
            "_id": 15625,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 472,
            "שם_רחוב": "הרב יעקב אבו חצירא"
        },
        {
            "_id": 15626,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 805,
            "שם_רחוב": "הרב מימון"
        },
        {
            "_id": 15627,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 375,
            "שם_רחוב": "הרב ניסים יצחק"
        },
        {
            "_id": 15628,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 871,
            "שם_רחוב": "הרב עדין שטיינזלץ"
        },
        {
            "_id": 15629,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 836,
            "שם_רחוב": "הרב עובדיה יוסף"
        },
        {
            "_id": 15630,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 320,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 15631,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 602,
            "שם_רחוב": "הרב פישמן"
        },
        {
            "_id": 15632,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 547,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 15633,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 872,
            "שם_רחוב": "הרב ש. צ. דוידוביץ'"
        },
        {
            "_id": 15634,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 853,
            "שם_רחוב": "הרדופים"
        },
        {
            "_id": 15635,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 723,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 15636,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הרי גולן"
        },
        {
            "_id": 15637,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הרי גלעד"
        },
        {
            "_id": 15638,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 603,
            "שם_רחוב": "הרכבת"
        },
        {
            "_id": 15639,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 527,
            "שם_רחוב": "הרמונים"
        },
        {
            "_id": 15640,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 556,
            "שם_רחוב": "הרצוג"
        },
        {
            "_id": 15641,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הרצליה"
        },
        {
            "_id": 15642,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6562,
            "שם_רחוב": "הרצליה ב"
        },
        {
            "_id": 15643,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 778,
            "שם_רחוב": "הרצפלד אברהם"
        },
        {
            "_id": 15644,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "הרקפות"
        },
        {
            "_id": 15645,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 441,
            "שם_רחוב": "השולמית"
        },
        {
            "_id": 15646,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 557,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 15647,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 795,
            "שם_רחוב": "השונית"
        },
        {
            "_id": 15648,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 362,
            "שם_רחוב": "השופטים"
        },
        {
            "_id": 15649,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 658,
            "שם_רחוב": "השושנים"
        },
        {
            "_id": 15650,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 865,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 15651,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 526,
            "שם_רחוב": "השקדים"
        },
        {
            "_id": 15652,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 724,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 15653,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 402,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 15654,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 610,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 15655,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 326,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 15656,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 823,
            "שם_רחוב": "התדהר"
        },
        {
            "_id": 15657,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 725,
            "שם_רחוב": "התומר"
        },
        {
            "_id": 15658,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1378,
            "שם_רחוב": "התזמורת"
        },
        {
            "_id": 15659,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 361,
            "שם_רחוב": "התנאים"
        },
        {
            "_id": 15660,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "התקוה"
        },
        {
            "_id": 15661,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 434,
            "שם_רחוב": "וולפסון"
        },
        {
            "_id": 15662,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 789,
            "שם_רחוב": "ויזנטל שמעון"
        },
        {
            "_id": 15663,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 439,
            "שם_רחוב": "ויינשנק יחזקאל"
        },
        {
            "_id": 15664,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 874,
            "שם_רחוב": "ויליאם הכלר"
        },
        {
            "_id": 15665,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 616,
            "שם_רחוב": "וינגייט"
        },
        {
            "_id": 15666,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 404,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 15667,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 516,
            "שם_רחוב": "ויתקין"
        },
        {
            "_id": 15668,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 368,
            "שם_רחוב": "ורבורג אוטו"
        },
        {
            "_id": 15669,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 826,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 15670,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 468,
            "שם_רחוב": "זאב"
        },
        {
            "_id": 15671,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 706,
            "שם_רחוב": "זבולון"
        },
        {
            "_id": 15672,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 726,
            "שם_רחוב": "זהר טל"
        },
        {
            "_id": 15673,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "זיסו"
        },
        {
            "_id": 15674,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 657,
            "שם_רחוב": "זמנהוף"
        },
        {
            "_id": 15675,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 877,
            "שם_רחוב": "זרעי קיץ"
        },
        {
            "_id": 15676,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 355,
            "שם_רחוב": "חבד"
        },
        {
            "_id": 15677,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 617,
            "שם_רחוב": "חבצלת השרון"
        },
        {
            "_id": 15678,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 415,
            "שם_רחוב": "חברון"
        },
        {
            "_id": 15679,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "חדוה"
        },
        {
            "_id": 15680,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "חובבי ציון"
        },
        {
            "_id": 15681,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 592,
            "שם_רחוב": "חובות הלבבות"
        },
        {
            "_id": 15682,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 494,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 15683,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 376,
            "שם_רחוב": "חומה ומגדל"
        },
        {
            "_id": 15684,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "חוני המעגל"
        },
        {
            "_id": 15685,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 784,
            "שם_רחוב": "חורב"
        },
        {
            "_id": 15686,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 622,
            "שם_רחוב": "חזקיהו המלך"
        },
        {
            "_id": 15687,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 875,
            "שם_רחוב": "חיים תנעמי"
        },
        {
            "_id": 15688,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 783,
            "שם_רחוב": "חירם"
        },
        {
            "_id": 15689,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 848,
            "שם_רחוב": "חנה מרון"
        },
        {
            "_id": 15690,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 640,
            "שם_רחוב": "חנה רובינא"
        },
        {
            "_id": 15691,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 460,
            "שם_רחוב": "חנקין"
        },
        {
            "_id": 15692,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 349,
            "שם_רחוב": "חפצי בה"
        },
        {
            "_id": 15693,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 904,
            "שם_רחוב": "חרש"
        },
        {
            "_id": 15694,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 332,
            "שם_רחוב": "חתם סופר"
        },
        {
            "_id": 15695,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 583,
            "שם_רחוב": "טאובר אמה"
        },
        {
            "_id": 15696,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 819,
            "שם_רחוב": "טביב מרדכי"
        },
        {
            "_id": 15697,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 367,
            "שם_רחוב": "טבנקין יצחק"
        },
        {
            "_id": 15698,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 793,
            "שם_רחוב": "טיילת חיים הרצוג"
        },
        {
            "_id": 15699,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 880,
            "שם_רחוב": "טיפת מזל"
        },
        {
            "_id": 15700,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 732,
            "שם_רחוב": "טיראן"
        },
        {
            "_id": 15701,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "טנא"
        },
        {
            "_id": 15702,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 364,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 15703,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 512,
            "שם_רחוב": "טשרניחובסקי"
        },
        {
            "_id": 15704,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 559,
            "שם_רחוב": "יבנה"
        },
        {
            "_id": 15705,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 426,
            "שם_רחוב": "יבניאלי"
        },
        {
            "_id": 15706,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1392,
            "שם_רחוב": "יגאל בשן"
        },
        {
            "_id": 15707,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 712,
            "שם_רחוב": "יד חרוצים"
        },
        {
            "_id": 15708,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 779,
            "שם_רחוב": "ידין יגאל"
        },
        {
            "_id": 15709,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 745,
            "שם_רחוב": "יהדות הדממה"
        },
        {
            "_id": 15710,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 539,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 15711,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 766,
            "שם_רחוב": "יהודה המכבי"
        },
        {
            "_id": 15712,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 342,
            "שם_רחוב": "יהודה הנשיא"
        },
        {
            "_id": 15713,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 742,
            "שם_רחוב": "יהונתן"
        },
        {
            "_id": 15714,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 704,
            "שם_רחוב": "יהושע בן נון"
        },
        {
            "_id": 15715,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 763,
            "שם_רחוב": "יהושפט המלך"
        },
        {
            "_id": 15716,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 864,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 15717,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 743,
            "שם_רחוב": "יואב"
        },
        {
            "_id": 15718,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 700,
            "שם_רחוב": "יודפת"
        },
        {
            "_id": 15719,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 711,
            "שם_רחוב": "יוחנן הסנדלר"
        },
        {
            "_id": 15720,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1387,
            "שם_רחוב": "יוסי שריד"
        },
        {
            "_id": 15721,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 716,
            "שם_רחוב": "יורדי ים"
        },
        {
            "_id": 15722,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 741,
            "שם_רחוב": "יותם"
        },
        {
            "_id": 15723,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "יחיעם"
        },
        {
            "_id": 15724,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 510,
            "שם_רחוב": "ילג"
        },
        {
            "_id": 15725,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 870,
            "שם_רחוב": "ילן"
        },
        {
            "_id": 15726,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 869,
            "שם_רחוב": "ינאית"
        },
        {
            "_id": 15727,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 524,
            "שם_רחוב": "יסוד המעלה"
        },
        {
            "_id": 15728,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 589,
            "שם_רחוב": "יעבץ"
        },
        {
            "_id": 15729,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 758,
            "שם_רחוב": "יעל"
        },
        {
            "_id": 15730,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 396,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 15731,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 505,
            "שם_רחוב": "יפה אריה לייב"
        },
        {
            "_id": 15732,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 846,
            "שם_רחוב": "יפה ירקוני"
        },
        {
            "_id": 15733,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 374,
            "שם_רחוב": "יפהר פסח"
        },
        {
            "_id": 15734,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 772,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 15735,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "יצחק שדה"
        },
        {
            "_id": 15736,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 835,
            "שם_רחוב": "יצחק שמיר"
        },
        {
            "_id": 15737,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "יציאת אירופה"
        },
        {
            "_id": 15738,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 385,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 15739,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 781,
            "שם_רחוב": "ירמיהו"
        },
        {
            "_id": 15740,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 369,
            "שם_רחוב": "ישעיהו ישראל"
        },
        {
            "_id": 15741,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 553,
            "שם_רחוב": "כט בנובמבר"
        },
        {
            "_id": 15742,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 593,
            "שם_רחוב": "כבוש העבודה"
        },
        {
            "_id": 15743,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 452,
            "שם_רחוב": "כוכב"
        },
        {
            "_id": 15744,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6017,
            "שם_רחוב": "ככר אובה אקלה"
        },
        {
            "_id": 15745,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר דגון יואב"
        },
        {
            "_id": 15746,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר דה שליט"
        },
        {
            "_id": 15747,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6013,
            "שם_רחוב": "ככר האוזנר גדעון"
        },
        {
            "_id": 15748,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "ככר המתנדבים"
        },
        {
            "_id": 15749,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר הנס פריץ"
        },
        {
            "_id": 15750,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6010,
            "שם_רחוב": "ככר הנשיא"
        },
        {
            "_id": 15751,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר הציונות"
        },
        {
            "_id": 15752,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6016,
            "שם_רחוב": "ככר יעל רום"
        },
        {
            "_id": 15753,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6012,
            "שם_רחוב": "ככר יקירי העיר"
        },
        {
            "_id": 15754,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6011,
            "שם_רחוב": "ככר לוחמות השואה"
        },
        {
            "_id": 15755,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6009,
            "שם_רחוב": "ככר מודעי"
        },
        {
            "_id": 15756,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "ככר מיכאלי"
        },
        {
            "_id": 15757,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "ככר סחארוב"
        },
        {
            "_id": 15758,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר עובד בן עמי"
        },
        {
            "_id": 15759,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6015,
            "שם_רחוב": "ככר פאבל פרנקל"
        },
        {
            "_id": 15760,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6014,
            "שם_רחוב": "ככר רוטרי"
        },
        {
            "_id": 15761,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 393,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 15762,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 563,
            "שם_רחוב": "כנפי נשרים"
        },
        {
            "_id": 15763,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 520,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 15764,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 323,
            "שם_רחוב": "כסופים"
        },
        {
            "_id": 15765,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6578,
            "שם_רחוב": "כפר הדייגים"
        },
        {
            "_id": 15766,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "כצנלסון"
        },
        {
            "_id": 15767,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 768,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 15768,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 340,
            "שם_רחוב": "לביא שמעון"
        },
        {
            "_id": 15769,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 371,
            "שם_רחוב": "לובטקין צביה"
        },
        {
            "_id": 15770,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 387,
            "שם_רחוב": "לוטוס"
        },
        {
            "_id": 15771,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 794,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 15772,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 760,
            "שם_רחוב": "לוי אשכול"
        },
        {
            "_id": 15773,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 405,
            "שם_רחוב": "לוין שמעון זאב"
        },
        {
            "_id": 15774,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 683,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 15775,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 701,
            "שם_רחוב": "ליבוביץ ישעיהו"
        },
        {
            "_id": 15776,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 595,
            "שם_רחוב": "ליברמן ישראל"
        },
        {
            "_id": 15777,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "ליל הגשרים"
        },
        {
            "_id": 15778,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "ליפסקי"
        },
        {
            "_id": 15779,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 956,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 15780,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 821,
            "שם_רחוב": "למדן יצחק"
        },
        {
            "_id": 15781,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 863,
            "שם_רחוב": "לסקוב חיים"
        },
        {
            "_id": 15782,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 579,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 15783,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 808,
            "שם_רחוב": "מאיר אריאל"
        },
        {
            "_id": 15784,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "מאיר דגן"
        },
        {
            "_id": 15785,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 727,
            "שם_רחוב": "מאנגר"
        },
        {
            "_id": 15786,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 770,
            "שם_רחוב": "מאפו"
        },
        {
            "_id": 15787,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 957,
            "שם_רחוב": "מארל"
        },
        {
            "_id": 15788,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "מבצע קדש"
        },
        {
            "_id": 15789,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 427,
            "שם_רחוב": "מגיני נגבה"
        },
        {
            "_id": 15790,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 532,
            "שם_רחוב": "מגן דוד"
        },
        {
            "_id": 15791,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 705,
            "שם_רחוב": "מדינת היהודים"
        },
        {
            "_id": 15792,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 600,
            "שם_רחוב": "מהרל"
        },
        {
            "_id": 15793,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 438,
            "שם_רחוב": "מובשוביץ בנימין"
        },
        {
            "_id": 15794,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 719,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 15795,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 422,
            "שם_רחוב": "מוהליבר"
        },
        {
            "_id": 15796,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1393,
            "שם_רחוב": "מוטה גור"
        },
        {
            "_id": 15797,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "מולדת"
        },
        {
            "_id": 15798,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 809,
            "שם_רחוב": "מולכו שלמה"
        },
        {
            "_id": 15799,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 684,
            "שם_רחוב": "מונטיפיורי"
        },
        {
            "_id": 15800,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 733,
            "שם_רחוב": "מוצקין"
        },
        {
            "_id": 15801,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 668,
            "שם_רחוב": "מורדות ים"
        },
        {
            "_id": 15802,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "מורדי הגטאות"
        },
        {
            "_id": 15803,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "מורי עופרי"
        },
        {
            "_id": 15804,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "מוריס לחיאני"
        },
        {
            "_id": 15805,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 379,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 15806,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 428,
            "שם_רחוב": "מזאה"
        },
        {
            "_id": 15807,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 731,
            "שם_רחוב": "מיכל"
        },
        {
            "_id": 15808,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 806,
            "שם_רחוב": "מלכי יהודה"
        },
        {
            "_id": 15809,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 802,
            "שם_רחוב": "מלכי ישראל"
        },
        {
            "_id": 15810,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "מלכין שרה"
        },
        {
            "_id": 15811,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 804,
            "שם_רחוב": "מנדלבלט צבי"
        },
        {
            "_id": 15812,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 685,
            "שם_רחוב": "מנדלי מוכר ספרים"
        },
        {
            "_id": 15813,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "מנורה"
        },
        {
            "_id": 15814,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6563,
            "שם_רחוב": "מע גלילות"
        },
        {
            "_id": 15815,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6564,
            "שם_רחוב": "מע נוף ים"
        },
        {
            "_id": 15816,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "מעונות שרה"
        },
        {
            "_id": 15817,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 857,
            "שם_רחוב": "מעלה חנוך"
        },
        {
            "_id": 15818,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 773,
            "שם_רחוב": "מעלה יהודית"
        },
        {
            "_id": 15819,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 780,
            "שם_רחוב": "מעלה נחמה"
        },
        {
            "_id": 15820,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מעפילי מרוקו"
        },
        {
            "_id": 15821,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 749,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 15822,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 546,
            "שם_רחוב": "מצפה ים"
        },
        {
            "_id": 15823,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 389,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 15824,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 395,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 15825,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "מרזוק"
        },
        {
            "_id": 15826,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 518,
            "שם_רחוב": "מרחביה"
        },
        {
            "_id": 15827,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 771,
            "שם_רחוב": "מרים החשמונאית"
        },
        {
            "_id": 15828,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 8001,
            "שם_רחוב": "מרינה הרצליה"
        },
        {
            "_id": 15829,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 632,
            "שם_רחוב": "מרכוס דוד"
        },
        {
            "_id": 15830,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 341,
            "שם_רחוב": "משה"
        },
        {
            "_id": 15831,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "משה ארנס"
        },
        {
            "_id": 15832,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 710,
            "שם_רחוב": "משכית"
        },
        {
            "_id": 15833,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 860,
            "שם_רחוב": "משעול כרמי"
        },
        {
            "_id": 15834,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "משעול משה"
        },
        {
            "_id": 15835,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 346,
            "שם_רחוב": "משעול שמואל עוזרי"
        },
        {
            "_id": 15836,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "משק עזרא"
        },
        {
            "_id": 15837,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 746,
            "שם_רחוב": "מתתיהו"
        },
        {
            "_id": 15838,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6565,
            "שם_רחוב": "נאות הדקלים"
        },
        {
            "_id": 15839,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 596,
            "שם_רחוב": "נבו יוסף"
        },
        {
            "_id": 15840,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "נג'ר יצחק"
        },
        {
            "_id": 15841,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 840,
            "שם_רחוב": "נדבה יוסף"
        },
        {
            "_id": 15842,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 453,
            "שם_רחוב": "נוגה"
        },
        {
            "_id": 15843,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "נוה אמירים"
        },
        {
            "_id": 15844,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6566,
            "שם_רחוב": "נוה נמרוד"
        },
        {
            "_id": 15845,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6542,
            "שם_רחוב": "נוה פועלים"
        },
        {
            "_id": 15846,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 669,
            "שם_רחוב": "נוף שדמות"
        },
        {
            "_id": 15847,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 377,
            "שם_רחוב": "נופר"
        },
        {
            "_id": 15848,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 601,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 15849,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 356,
            "שם_רחוב": "נורוק"
        },
        {
            "_id": 15850,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6543,
            "שם_רחוב": "נחלת עדה"
        },
        {
            "_id": 15851,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 659,
            "שם_רחוב": "נחמה הנדל"
        },
        {
            "_id": 15852,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 448,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 15853,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 782,
            "שם_רחוב": "נחשון"
        },
        {
            "_id": 15854,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 728,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 15855,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 787,
            "שם_רחוב": "ניר"
        },
        {
            "_id": 15856,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 572,
            "שם_רחוב": "נצח ישראל"
        },
        {
            "_id": 15857,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 673,
            "שם_רחוב": "נתיב הלה"
        },
        {
            "_id": 15858,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 729,
            "שם_רחוב": "נתיבות"
        },
        {
            "_id": 15859,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "נתן אלבז"
        },
        {
            "_id": 15860,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 788,
            "שם_רחוב": "נתניהו יוני"
        },
        {
            "_id": 15861,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 384,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 15862,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 594,
            "שם_רחוב": "סולץ אלכסנדר"
        },
        {
            "_id": 15863,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 473,
            "שם_רחוב": "סומסום"
        },
        {
            "_id": 15864,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 406,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 15865,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 686,
            "שם_רחוב": "סטרומה"
        },
        {
            "_id": 15866,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 774,
            "שם_רחוב": "סידני עלי"
        },
        {
            "_id": 15867,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 677,
            "שם_רחוב": "סירני"
        },
        {
            "_id": 15868,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 411,
            "שם_רחוב": "סירקין"
        },
        {
            "_id": 15869,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 822,
            "שם_רחוב": "סמ בני ברית"
        },
        {
            "_id": 15870,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 761,
            "שם_רחוב": "סמ ברנדיס"
        },
        {
            "_id": 15871,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 440,
            "שם_רחוב": "סמ דגן"
        },
        {
            "_id": 15872,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 581,
            "שם_רחוב": "סמ זיו אור"
        },
        {
            "_id": 15873,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "סמ יפת בן זכריה מגד"
        },
        {
            "_id": 15874,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 641,
            "שם_רחוב": "סמ מקדש מלך"
        },
        {
            "_id": 15875,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 347,
            "שם_רחוב": "סמ נוף ילדות"
        },
        {
            "_id": 15876,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "סמ ניסנוב"
        },
        {
            "_id": 15877,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 392,
            "שם_רחוב": "סמ עינב"
        },
        {
            "_id": 15878,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 343,
            "שם_רחוב": "סמ צמרות"
        },
        {
            "_id": 15879,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 358,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 15880,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 582,
            "שם_רחוב": "סמילנסקי משה"
        },
        {
            "_id": 15881,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 457,
            "שם_רחוב": "סנה משה"
        },
        {
            "_id": 15882,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "סנהדרין"
        },
        {
            "_id": 15883,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 495,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 15884,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 744,
            "שם_רחוב": "סנפיר"
        },
        {
            "_id": 15885,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 324,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 15886,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 318,
            "שם_רחוב": "סעדיה גאון"
        },
        {
            "_id": 15887,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 902,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 15888,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 475,
            "שם_רחוב": "עגנון"
        },
        {
            "_id": 15889,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "עדה לאבלייס"
        },
        {
            "_id": 15890,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 571,
            "שם_רחוב": "עדס"
        },
        {
            "_id": 15891,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 359,
            "שם_רחוב": "עוזי חיטמן"
        },
        {
            "_id": 15892,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 718,
            "שם_רחוב": "עוזיהו המלך"
        },
        {
            "_id": 15893,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 336,
            "שם_רחוב": "עולי בבל"
        },
        {
            "_id": 15894,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 777,
            "שם_רחוב": "עולי הגרדום"
        },
        {
            "_id": 15895,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 449,
            "שם_רחוב": "עזרא הסופר"
        },
        {
            "_id": 15896,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 878,
            "שם_רחוב": "עטור מצחך"
        },
        {
            "_id": 15897,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 455,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 15898,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 398,
            "שם_רחוב": "עינב"
        },
        {
            "_id": 15899,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1388,
            "שם_רחוב": "עמוס עוז"
        },
        {
            "_id": 15900,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "עמיאל"
        },
        {
            "_id": 15901,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 827,
            "שם_רחוב": "עמיר"
        },
        {
            "_id": 15902,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 15903,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 879,
            "שם_רחוב": "עץ השדה"
        },
        {
            "_id": 15904,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 786,
            "שם_רחוב": "ערוגות"
        },
        {
            "_id": 15905,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "ערמונים"
        },
        {
            "_id": 15906,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 573,
            "שם_רחוב": "עשרת הרוגי מלכות"
        },
        {
            "_id": 15907,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 345,
            "שם_רחוב": "פולג"
        },
        {
            "_id": 15908,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "פוסטר הרולד"
        },
        {
            "_id": 15909,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "פועלי ציון"
        },
        {
            "_id": 15910,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "פורצי הדרך"
        },
        {
            "_id": 15911,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 687,
            "שם_רחוב": "פטריה"
        },
        {
            "_id": 15912,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "פינס"
        },
        {
            "_id": 15913,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 540,
            "שם_רחוב": "פינסקר"
        },
        {
            "_id": 15914,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 501,
            "שם_רחוב": "פלמח"
        },
        {
            "_id": 15915,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "פנקס"
        },
        {
            "_id": 15916,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 842,
            "שם_רחוב": "פנת חיים"
        },
        {
            "_id": 15917,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 366,
            "שם_רחוב": "פסמן"
        },
        {
            "_id": 15918,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 15919,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 849,
            "שם_רחוב": "פעמי אביב"
        },
        {
            "_id": 15920,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 378,
            "שם_רחוב": "פרגים"
        },
        {
            "_id": 15921,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "פרץ"
        },
        {
            "_id": 15922,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 330,
            "שם_רחוב": "פתח תקווה"
        },
        {
            "_id": 15923,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 386,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 15924,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 454,
            "שם_רחוב": "צדק"
        },
        {
            "_id": 15925,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 15926,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 493,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 15927,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 841,
            "שם_רחוב": "צוקרמן יצחק"
        },
        {
            "_id": 15928,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1391,
            "שם_רחוב": "ציוני לוב"
        },
        {
            "_id": 15929,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 791,
            "שם_רחוב": "ציפורי"
        },
        {
            "_id": 15930,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 735,
            "שם_רחוב": "צלע דרום"
        },
        {
            "_id": 15931,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 670,
            "שם_רחוב": "צלע צפון"
        },
        {
            "_id": 15932,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "צמרות"
        },
        {
            "_id": 15933,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "צעירי ציון"
        },
        {
            "_id": 15934,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 447,
            "שם_רחוב": "קארו יוסף"
        },
        {
            "_id": 15935,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 15936,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 664,
            "שם_רחוב": "קדושי השואה"
        },
        {
            "_id": 15937,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "קדימה"
        },
        {
            "_id": 15938,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 517,
            "שם_רחוב": "קדיש לוז"
        },
        {
            "_id": 15939,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 423,
            "שם_רחוב": "קהילת ציון"
        },
        {
            "_id": 15940,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 621,
            "שם_רחוב": "קורן"
        },
        {
            "_id": 15941,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 523,
            "שם_רחוב": "קורצ'אק"
        },
        {
            "_id": 15942,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 654,
            "שם_רחוב": "קישון אפרים"
        },
        {
            "_id": 15943,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 688,
            "שם_רחוב": "קלוזנר"
        },
        {
            "_id": 15944,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 507,
            "שם_רחוב": "קלישר"
        },
        {
            "_id": 15945,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 862,
            "שם_רחוב": "קמין ברוך"
        },
        {
            "_id": 15946,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 612,
            "שם_רחוב": "קפלן"
        },
        {
            "_id": 15947,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 515,
            "שם_רחוב": "קפלנסקי"
        },
        {
            "_id": 15948,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 639,
            "שם_רחוב": "קציר אהרון"
        },
        {
            "_id": 15949,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6552,
            "שם_רחוב": "קרית רבין"
        },
        {
            "_id": 15950,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 627,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 15951,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 365,
            "שם_רחוב": "קרת"
        },
        {
            "_id": 15952,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 844,
            "שם_רחוב": "ראל ליפקין שחק"
        },
        {
            "_id": 15953,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 319,
            "שם_רחוב": "ראל משה לוי"
        },
        {
            "_id": 15954,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 424,
            "שם_רחוב": "ראש פינה"
        },
        {
            "_id": 15955,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 425,
            "שם_רחוב": "ראשון לציון"
        },
        {
            "_id": 15956,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 333,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 15957,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "רביבים"
        },
        {
            "_id": 15958,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "רבן גמליאל"
        },
        {
            "_id": 15959,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 813,
            "שם_רחוב": "רבנו תם"
        },
        {
            "_id": 15960,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 487,
            "שם_רחוב": "רגבים"
        },
        {
            "_id": 15961,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 859,
            "שם_רחוב": "רוזה גינוסר"
        },
        {
            "_id": 15962,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 776,
            "שם_רחוב": "רוזן פנחס"
        },
        {
            "_id": 15963,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 321,
            "שם_רחוב": "רוחמה"
        },
        {
            "_id": 15964,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 410,
            "שם_רחוב": "רופין"
        },
        {
            "_id": 15965,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 807,
            "שם_רחוב": "רות"
        },
        {
            "_id": 15966,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 15967,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 834,
            "שם_רחוב": "רחבעם זאבי"
        },
        {
            "_id": 15968,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רחל"
        },
        {
            "_id": 15969,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 638,
            "שם_רחוב": "רחל המשוררת"
        },
        {
            "_id": 15970,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 394,
            "שם_רחוב": "ריחן"
        },
        {
            "_id": 15971,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 403,
            "שם_רחוב": "ריינס"
        },
        {
            "_id": 15972,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 682,
            "שם_רחוב": "רייק חביבה"
        },
        {
            "_id": 15973,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "רינה"
        },
        {
            "_id": 15974,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "רכב ישראל"
        },
        {
            "_id": 15975,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 417,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 15976,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רמז"
        },
        {
            "_id": 15977,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 818,
            "שם_רחוב": "רמחל"
        },
        {
            "_id": 15978,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "רמת אליהו"
        },
        {
            "_id": 15979,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 442,
            "שם_רחוב": "רנק"
        },
        {
            "_id": 15980,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רפי וקנין"
        },
        {
            "_id": 15981,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 537,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 15982,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 483,
            "שם_רחוב": "רשבם"
        },
        {
            "_id": 15983,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש אזבסטונים"
        },
        {
            "_id": 15984,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "ש אחים"
        },
        {
            "_id": 15985,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6567,
            "שם_רחוב": "ש אכדיה"
        },
        {
            "_id": 15986,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6568,
            "שם_רחוב": "ש אמריקאים"
        },
        {
            "_id": 15987,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6569,
            "שם_רחוב": "ש אקדמאים"
        },
        {
            "_id": 15988,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6544,
            "שם_רחוב": "ש בטיש"
        },
        {
            "_id": 15989,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש בלוקונים"
        },
        {
            "_id": 15990,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6545,
            "שם_רחוב": "ש גן רשל"
        },
        {
            "_id": 15991,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "ש הדרום"
        },
        {
            "_id": 15992,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "ש הפומז"
        },
        {
            "_id": 15993,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6546,
            "שם_רחוב": "ש התימנים"
        },
        {
            "_id": 15994,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6547,
            "שם_רחוב": "ש ויצמן"
        },
        {
            "_id": 15995,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "ש חדש רמת השרון"
        },
        {
            "_id": 15996,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6570,
            "שם_רחוב": "ש חיילים משוחררים"
        },
        {
            "_id": 15997,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש יד התשעה"
        },
        {
            "_id": 15998,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "ש לומיר"
        },
        {
            "_id": 15999,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש מפדה אזרחי"
        },
        {
            "_id": 16000,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "ש משהב"
        },
        {
            "_id": 16001,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש משכנות"
        },
        {
            "_id": 16002,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "ש נוה ישראל"
        },
        {
            "_id": 16003,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש נוה עמל"
        },
        {
            "_id": 16004,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש נוה עמל אזבסט"
        },
        {
            "_id": 16005,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6571,
            "שם_רחוב": "ש נוה תמר"
        },
        {
            "_id": 16006,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6572,
            "שם_רחוב": "ש נוף ים"
        },
        {
            "_id": 16007,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6580,
            "שם_רחוב": "ש עולים"
        },
        {
            "_id": 16008,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "ש עממי"
        },
        {
            "_id": 16009,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6573,
            "שם_רחוב": "ש פרוגרסיבים"
        },
        {
            "_id": 16010,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש ציונים כלליים"
        },
        {
            "_id": 16011,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "ש צריפים שוודיים"
        },
        {
            "_id": 16012,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "ש רוגובין"
        },
        {
            "_id": 16013,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6574,
            "שם_רחוב": "ש רמת ים"
        },
        {
            "_id": 16014,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6549,
            "שם_רחוב": "ש רסקו"
        },
        {
            "_id": 16015,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 633,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 16016,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 730,
            "שם_רחוב": "שארית הפליטה"
        },
        {
            "_id": 16017,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 536,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 16018,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 650,
            "שם_רחוב": "שבט מנשה"
        },
        {
            "_id": 16019,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 322,
            "שם_רחוב": "שבטי ישראל"
        },
        {
            "_id": 16020,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 382,
            "שם_רחוב": "שביל האדמית"
        },
        {
            "_id": 16021,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 663,
            "שם_רחוב": "שביל היסמין"
        },
        {
            "_id": 16022,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "שביל הרב שמן יוסף"
        },
        {
            "_id": 16023,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 381,
            "שם_רחוב": "שביל השיטה"
        },
        {
            "_id": 16024,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 538,
            "שם_רחוב": "שבעת הכוכבים"
        },
        {
            "_id": 16025,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 713,
            "שם_רחוב": "שד אבא אבן"
        },
        {
            "_id": 16026,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 383,
            "שם_רחוב": "שד אמנון ותמר"
        },
        {
            "_id": 16027,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 792,
            "שם_רחוב": "שד בגין מנחם"
        },
        {
            "_id": 16028,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 511,
            "שם_רחוב": "שד חן"
        },
        {
            "_id": 16029,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 409,
            "שם_רחוב": "שד לנצט יעקב"
        },
        {
            "_id": 16030,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 584,
            "שם_רחוב": "שד מיכאלי בן ציון"
        },
        {
            "_id": 16031,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 614,
            "שם_רחוב": "שד נוה עובד"
        },
        {
            "_id": 16032,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 446,
            "שם_רחוב": "שדל"
        },
        {
            "_id": 16033,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 618,
            "שם_רחוב": "שד' אלי לנדאו"
        },
        {
            "_id": 16034,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 587,
            "שם_רחוב": "שדה התעופה"
        },
        {
            "_id": 16035,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 651,
            "שם_רחוב": "שדרות התשעים ושלש"
        },
        {
            "_id": 16036,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "שוחט ישראל"
        },
        {
            "_id": 16037,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 462,
            "שם_רחוב": "שוידלסון"
        },
        {
            "_id": 16038,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1390,
            "שם_רחוב": "שולמית כהן-קישיק"
        },
        {
            "_id": 16039,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 667,
            "שם_רחוב": "שומרון דן"
        },
        {
            "_id": 16040,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 474,
            "שם_רחוב": "שושנה דמארי"
        },
        {
            "_id": 16041,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 753,
            "שם_רחוב": "שזר"
        },
        {
            "_id": 16042,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "שחל"
        },
        {
            "_id": 16043,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 831,
            "שם_רחוב": "שחרית"
        },
        {
            "_id": 16044,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 850,
            "שם_רחוב": "שטורמן"
        },
        {
            "_id": 16045,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 354,
            "שם_רחוב": "שטרן אברהם"
        },
        {
            "_id": 16046,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 469,
            "שם_רחוב": "שיר הרעות"
        },
        {
            "_id": 16047,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 315,
            "שם_רחוב": "שירת גאולים"
        },
        {
            "_id": 16048,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "שירת הזמיר"
        },
        {
            "_id": 16049,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6555,
            "שם_רחוב": "שכ גבעת הסופר"
        },
        {
            "_id": 16050,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "שכ הפועלים"
        },
        {
            "_id": 16051,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6582,
            "שם_רחוב": "שכ הרצליה פיתוח"
        },
        {
            "_id": 16052,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6575,
            "שם_רחוב": "שכ חילים משוחררים"
        },
        {
            "_id": 16053,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6576,
            "שם_רחוב": "שכ כפר רשף"
        },
        {
            "_id": 16054,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "שכ מזרחי"
        },
        {
            "_id": 16055,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "שכ מרכז"
        },
        {
            "_id": 16056,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6581,
            "שם_רחוב": "שכ נווה עובד"
        },
        {
            "_id": 16057,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6551,
            "שם_רחוב": "שכ נחלת ישראל"
        },
        {
            "_id": 16058,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ צמרות"
        },
        {
            "_id": 16059,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6577,
            "שם_רחוב": "שכ תשליב"
        },
        {
            "_id": 16060,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכון גורדון"
        },
        {
            "_id": 16061,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 624,
            "שם_רחוב": "שלוה"
        },
        {
            "_id": 16062,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 690,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 16063,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 838,
            "שם_רחוב": "שלום רוזנפלד"
        },
        {
            "_id": 16064,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 631,
            "שם_רחוב": "שלומציון המלכה"
        },
        {
            "_id": 16065,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 759,
            "שם_רחוב": "שלונסקי"
        },
        {
            "_id": 16066,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 626,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 16067,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 435,
            "שם_רחוב": "שמאי"
        },
        {
            "_id": 16068,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 568,
            "שם_רחוב": "שמואל הנגיד"
        },
        {
            "_id": 16069,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 588,
            "שם_רחוב": "שמיר משה"
        },
        {
            "_id": 16070,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 564,
            "שם_רחוב": "שמעוני דוד"
        },
        {
            "_id": 16071,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 481,
            "שם_רחוב": "שמעיה"
        },
        {
            "_id": 16072,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 828,
            "שם_רחוב": "שמר נעמי"
        },
        {
            "_id": 16073,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 432,
            "שם_רחוב": "שמשון הגבור"
        },
        {
            "_id": 16074,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 565,
            "שם_רחוב": "שניאור זלמן"
        },
        {
            "_id": 16075,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 708,
            "שם_רחוב": "שנקר אריה"
        },
        {
            "_id": 16076,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 605,
            "שם_רחוב": "שער הים"
        },
        {
            "_id": 16077,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 751,
            "שם_רחוב": "שפיים"
        },
        {
            "_id": 16078,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 691,
            "שם_רחוב": "שפינוזה"
        },
        {
            "_id": 16079,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 436,
            "שם_רחוב": "שפירא צבי"
        },
        {
            "_id": 16080,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 644,
            "שם_רחוב": "שפרינצק"
        },
        {
            "_id": 16081,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 868,
            "שם_רחוב": "שרה"
        },
        {
            "_id": 16082,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 561,
            "שם_רחוב": "שרעבי"
        },
        {
            "_id": 16083,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 747,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 16084,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 1380,
            "שם_רחוב": "תוף מרים"
        },
        {
            "_id": 16085,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 569,
            "שם_רחוב": "תור הזהב"
        },
        {
            "_id": 16086,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 552,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 16087,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 16088,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 6579,
            "שם_רחוב": "תעש"
        },
        {
            "_id": 16089,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "תפוח"
        },
        {
            "_id": 16090,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 856,
            "שם_רחוב": "תפוח חינני"
        },
        {
            "_id": 16091,
            "סמל_ישוב": 6400,
            "שם_ישוב": "הרצליה ",
            "סמל_רחוב": 954,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 16092,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 16093,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 16094,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הארבל"
        },
        {
            "_id": 16095,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 114,
            "שם_רחוב": "המוביל"
        },
        {
            "_id": 16096,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "הררית"
        },
        {
            "_id": 16097,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 108,
            "שם_רחוב": "יונק הדבש"
        },
        {
            "_id": 16098,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 101,
            "שם_רחוב": "יחד"
        },
        {
            "_id": 16099,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 113,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 16100,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 115,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 16101,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 102,
            "שם_רחוב": "נטופה"
        },
        {
            "_id": 16102,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 106,
            "שם_רחוב": "סמטת החרמון"
        },
        {
            "_id": 16103,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סמטת הים"
        },
        {
            "_id": 16104,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 109,
            "שם_רחוב": "עגורים"
        },
        {
            "_id": 16105,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 112,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 16106,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רכס מירון"
        },
        {
            "_id": 16107,
            "סמל_ישוב": 1203,
            "שם_ישוב": "הררית ",
            "סמל_רחוב": 110,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 16108,
            "סמל_ישוב": 3639,
            "שם_ישוב": "ורד יריחו ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ורד יריחו"
        },
        {
            "_id": 16109,
            "סמל_ישוב": 1133,
            "שם_ישוב": "ורדון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ורדון"
        },
        {
            "_id": 16110,
            "סמל_ישוב": 2742,
            "שם_ישוב": "זבארגה (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זבארגה (שבט)"
        },
        {
            "_id": 16111,
            "סמל_ישוב": 2742,
            "שם_ישוב": "זבארגה (שבט) ",
            "סמל_רחוב": 102,
            "שם_רחוב": "תל אל מלח"
        },
        {
            "_id": 16112,
            "סמל_ישוב": 2742,
            "שם_ישוב": "זבארגה (שבט) ",
            "סמל_רחוב": 101,
            "שם_רחוב": "תל ערד"
        },
        {
            "_id": 16113,
            "סמל_ישוב": 815,
            "שם_ישוב": "זבדיאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זבדיאל"
        },
        {
            "_id": 16114,
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 16115,
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 16116,
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 16117,
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 16118,
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זוהר"
        },
        {
            "_id": 16119,
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "זית"
        },
        {
            "_id": 16120,
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 16121,
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 16122,
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 16123,
            "סמל_ישוב": 44,
            "שם_ישוב": "זוהר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 16124,
            "סמל_ישוב": 584,
            "שם_ישוב": "זיקים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זיקים"
        },
        {
            "_id": 16125,
            "סמל_ישוב": 788,
            "שם_ישוב": "זיתן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 16126,
            "סמל_ישוב": 788,
            "שם_ישוב": "זיתן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 16127,
            "סמל_ישוב": 788,
            "שם_ישוב": "זיתן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 16128,
            "סמל_ישוב": 788,
            "שם_ישוב": "זיתן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "השלושה"
        },
        {
            "_id": 16129,
            "סמל_ישוב": 788,
            "שם_ישוב": "זיתן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זיתן"
        },
        {
            "_id": 16130,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 307,
            "שם_רחוב": "אגס"
        },
        {
            "_id": 16131,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 16132,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אחי אילת"
        },
        {
            "_id": 16133,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "אחוזת נילי"
        },
        {
            "_id": 16134,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 192,
            "שם_רחוב": "איילת השחר"
        },
        {
            "_id": 16135,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 291,
            "שם_רחוב": "אלומות"
        },
        {
            "_id": 16136,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלון יגאל"
        },
        {
            "_id": 16137,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אלונים"
        },
        {
            "_id": 16138,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 16139,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 285,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 16140,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 260,
            "שם_רחוב": "אף על פי כן"
        },
        {
            "_id": 16141,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 309,
            "שם_רחוב": "אפרסק"
        },
        {
            "_id": 16142,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 16143,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 267,
            "שם_רחוב": "אקסודוס"
        },
        {
            "_id": 16144,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אשכול"
        },
        {
            "_id": 16145,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 297,
            "שם_רחוב": "בוסתן"
        },
        {
            "_id": 16146,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 289,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 16147,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 134,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 16148,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 129,
            "שם_רחוב": "בית הבד"
        },
        {
            "_id": 16149,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 197,
            "שם_רחוב": "בית צורי אליהו"
        },
        {
            "_id": 16150,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 231,
            "שם_רחוב": "בית צידה"
        },
        {
            "_id": 16151,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 171,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 16152,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 238,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 16153,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 143,
            "שם_רחוב": "גאולים"
        },
        {
            "_id": 16154,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "גבעת עדן"
        },
        {
            "_id": 16155,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 294,
            "שם_רחוב": "גדיש"
        },
        {
            "_id": 16156,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 198,
            "שם_רחוב": "גולומב אליהו"
        },
        {
            "_id": 16157,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 266,
            "שם_רחוב": "גורן"
        },
        {
            "_id": 16158,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 232,
            "שם_רחוב": "גזית"
        },
        {
            "_id": 16159,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 273,
            "שם_רחוב": "דבורה"
        },
        {
            "_id": 16160,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 133,
            "שם_רחוב": "דורות"
        },
        {
            "_id": 16161,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 288,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 16162,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 224,
            "שם_רחוב": "דרך אבשלום"
        },
        {
            "_id": 16163,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 184,
            "שם_רחוב": "דרך אהרן"
        },
        {
            "_id": 16164,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 166,
            "שם_רחוב": "דרך הגרנות"
        },
        {
            "_id": 16165,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 131,
            "שם_רחוב": "דרך היקב"
        },
        {
            "_id": 16166,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 314,
            "שם_רחוב": "דרך הרכבת"
        },
        {
            "_id": 16167,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 174,
            "שם_רחוב": "דרך השיטה"
        },
        {
            "_id": 16168,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 172,
            "שם_רחוב": "דרך פינלס"
        },
        {
            "_id": 16169,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 183,
            "שם_רחוב": "דרך שרה"
        },
        {
            "_id": 16170,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 136,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 16171,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 254,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 16172,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 252,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 16173,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הברושים"
        },
        {
            "_id": 16174,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 16175,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הגדעונים"
        },
        {
            "_id": 16176,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 16177,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 220,
            "שם_רחוב": "הגרניט"
        },
        {
            "_id": 16178,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 16179,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 195,
            "שם_רחוב": "הדגן"
        },
        {
            "_id": 16180,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הדובדבן"
        },
        {
            "_id": 16181,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 203,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 16182,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 293,
            "שם_רחוב": "הדרים"
        },
        {
            "_id": 16183,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 158,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 16184,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 193,
            "שם_רחוב": "הזוהר"
        },
        {
            "_id": 16185,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 135,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 16186,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 109,
            "שם_רחוב": "החיל"
        },
        {
            "_id": 16187,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 246,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 16188,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 306,
            "שם_רחוב": "החינוך העברי"
        },
        {
            "_id": 16189,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 209,
            "שם_רחוב": "החלוצים"
        },
        {
            "_id": 16190,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 216,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 16191,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 138,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 16192,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 119,
            "שם_רחוב": "היין"
        },
        {
            "_id": 16193,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 190,
            "שם_רחוב": "הכובשים"
        },
        {
            "_id": 16194,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 214,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 16195,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 215,
            "שם_רחוב": "הכרכום"
        },
        {
            "_id": 16196,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 189,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 16197,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 185,
            "שם_רחוב": "הלל יפה"
        },
        {
            "_id": 16198,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 122,
            "שם_רחוב": "המגינים"
        },
        {
            "_id": 16199,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 159,
            "שם_רחוב": "המדע"
        },
        {
            "_id": 16200,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 121,
            "שם_רחוב": "המיסדים"
        },
        {
            "_id": 16201,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 177,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 16202,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הנדיב"
        },
        {
            "_id": 16203,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 248,
            "שם_רחוב": "הנוטרים"
        },
        {
            "_id": 16204,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 213,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 16205,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הנשיא"
        },
        {
            "_id": 16206,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 242,
            "שם_רחוב": "הסוכה"
        },
        {
            "_id": 16207,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 217,
            "שם_רחוב": "הסתונית"
        },
        {
            "_id": 16208,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 141,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 16209,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 130,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 16210,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 178,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 16211,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 243,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 16212,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 188,
            "שם_רחוב": "הפדות"
        },
        {
            "_id": 16213,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 241,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 16214,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 253,
            "שם_רחוב": "הפנינה"
        },
        {
            "_id": 16215,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 16216,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 304,
            "שם_רחוב": "הקונגרס הציוני"
        },
        {
            "_id": 16217,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 222,
            "שם_רחוב": "הרב עזריאלי"
        },
        {
            "_id": 16218,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 139,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 16219,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 276,
            "שם_רחוב": "הרמבם"
        },
        {
            "_id": 16220,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 16221,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 212,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 16222,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 244,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 16223,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 250,
            "שם_רחוב": "השופטים"
        },
        {
            "_id": 16224,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "השזיף"
        },
        {
            "_id": 16225,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 265,
            "שם_רחוב": "השלושה"
        },
        {
            "_id": 16226,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 256,
            "שם_רחוב": "השמורה"
        },
        {
            "_id": 16227,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 116,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 16228,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 163,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 16229,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 164,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 16230,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 137,
            "שם_רחוב": "התומר"
        },
        {
            "_id": 16231,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 313,
            "שם_רחוב": "התוף"
        },
        {
            "_id": 16232,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 310,
            "שם_רחוב": "התזמורת"
        },
        {
            "_id": 16233,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 148,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 16234,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 162,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 16235,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 274,
            "שם_רחוב": "התקומה"
        },
        {
            "_id": 16236,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 153,
            "שם_רחוב": "וינגייט"
        },
        {
            "_id": 16237,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 132,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 16238,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 247,
            "שם_רחוב": "זייד אלכסנדר"
        },
        {
            "_id": 16239,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זכרון יעקב"
        },
        {
            "_id": 16240,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 287,
            "שם_רחוב": "חוגלה"
        },
        {
            "_id": 16241,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 186,
            "שם_רחוב": "חווה חקלאית"
        },
        {
            "_id": 16242,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "חוות הברון"
        },
        {
            "_id": 16243,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 284,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 16244,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 160,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 16245,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 196,
            "שם_רחוב": "חכים אליהו"
        },
        {
            "_id": 16246,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 281,
            "שם_רחוב": "חסידה"
        },
        {
            "_id": 16247,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 300,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 16248,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 221,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 16249,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 230,
            "שם_רחוב": "טיילת הברון"
        },
        {
            "_id": 16250,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 140,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 16251,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 201,
            "שם_רחוב": "יאיר"
        },
        {
            "_id": 16252,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 240,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 16253,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 16254,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 114,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 16255,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 251,
            "שם_רחוב": "יצחק שדה"
        },
        {
            "_id": 16256,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 152,
            "שם_רחוב": "יציאת אירופה"
        },
        {
            "_id": 16257,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 118,
            "שם_רחוב": "כביש ארצי"
        },
        {
            "_id": 16258,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 298,
            "שם_רחוב": "כט בנובמבר"
        },
        {
            "_id": 16259,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 311,
            "שם_רחוב": "כינור"
        },
        {
            "_id": 16260,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר אלכסנדרוני"
        },
        {
            "_id": 16261,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר הבנים"
        },
        {
            "_id": 16262,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר הבריגדה"
        },
        {
            "_id": 16263,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר הראשונים"
        },
        {
            "_id": 16264,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר פלוגת עורב"
        },
        {
            "_id": 16265,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 167,
            "שם_רחוב": "כנפי נשרים"
        },
        {
            "_id": 16266,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 233,
            "שם_רחוב": "לאה"
        },
        {
            "_id": 16267,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 176,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 16268,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 308,
            "שם_רחוב": "לימון"
        },
        {
            "_id": 16269,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 228,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 16270,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 301,
            "שם_רחוב": "מגל"
        },
        {
            "_id": 16271,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 263,
            "שם_רחוב": "מולדת"
        },
        {
            "_id": 16272,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 321,
            "שם_רחוב": "מוסקט"
        },
        {
            "_id": 16273,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "מייש"
        },
        {
            "_id": 16274,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 275,
            "שם_רחוב": "מלכי יהודה"
        },
        {
            "_id": 16275,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 173,
            "שם_רחוב": "מלכי ישראל"
        },
        {
            "_id": 16276,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 292,
            "שם_רחוב": "מסיק"
        },
        {
            "_id": 16277,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 161,
            "שם_רחוב": "מעלה הכרמל"
        },
        {
            "_id": 16278,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 120,
            "שם_רחוב": "מעלה הראשונים"
        },
        {
            "_id": 16279,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 200,
            "שם_רחוב": "מעלה מנחם"
        },
        {
            "_id": 16280,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 262,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 16281,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 187,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 16282,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מרגוע"
        },
        {
            "_id": 16283,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "מרכז המושבה"
        },
        {
            "_id": 16284,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 318,
            "שם_רחוב": "מרלו"
        },
        {
            "_id": 16285,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 156,
            "שם_רחוב": "משעול הגן"
        },
        {
            "_id": 16286,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 257,
            "שם_רחוב": "משעול מתניה"
        },
        {
            "_id": 16287,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 261,
            "שם_רחוב": "נגבה"
        },
        {
            "_id": 16288,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "נוה הברון"
        },
        {
            "_id": 16289,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "נוה רמז"
        },
        {
            "_id": 16290,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "נוה שלו"
        },
        {
            "_id": 16291,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "נוה שרת"
        },
        {
            "_id": 16292,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 211,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 16293,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 16294,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 150,
            "שם_רחוב": "סטרומה"
        },
        {
            "_id": 16295,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 207,
            "שם_רחוב": "סמ האלה"
        },
        {
            "_id": 16296,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 146,
            "שם_רחוב": "סמ הבאר"
        },
        {
            "_id": 16297,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 272,
            "שם_רחוב": "סמ הנרקיס"
        },
        {
            "_id": 16298,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 168,
            "שם_רחוב": "סמ הרוקח"
        },
        {
            "_id": 16299,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 279,
            "שם_רחוב": "סמ הרשש"
        },
        {
            "_id": 16300,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 147,
            "שם_רחוב": "סמ השאיבה"
        },
        {
            "_id": 16301,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 271,
            "שם_רחוב": "סמ השקד"
        },
        {
            "_id": 16302,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 299,
            "שם_רחוב": "סמ חורשן"
        },
        {
            "_id": 16303,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 127,
            "שם_רחוב": "סמ יעבץ"
        },
        {
            "_id": 16304,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 255,
            "שם_רחוב": "סמ נוף ים"
        },
        {
            "_id": 16305,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 155,
            "שם_רחוב": "סמ תרמב"
        },
        {
            "_id": 16306,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 258,
            "שם_רחוב": "סמ תרשיש"
        },
        {
            "_id": 16307,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 259,
            "שם_רחוב": "סמטת אף על פי כן"
        },
        {
            "_id": 16308,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 305,
            "שם_רחוב": "סמטת הגרנות"
        },
        {
            "_id": 16309,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 154,
            "שם_רחוב": "סמטת סטרומה"
        },
        {
            "_id": 16310,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 325,
            "שם_רחוב": "סמיון"
        },
        {
            "_id": 16311,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 169,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 16312,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 268,
            "שם_רחוב": "ספינת אגוז"
        },
        {
            "_id": 16313,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 239,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 16314,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 286,
            "שם_רחוב": "עגור"
        },
        {
            "_id": 16315,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 290,
            "שם_רחוב": "עורבני"
        },
        {
            "_id": 16316,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 320,
            "שם_רחוב": "ענבים"
        },
        {
            "_id": 16317,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 237,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 16318,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 111,
            "שם_רחוב": "עציון"
        },
        {
            "_id": 16319,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 204,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 16320,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 278,
            "שם_רחוב": "פעמי תימן"
        },
        {
            "_id": 16321,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 181,
            "שם_רחוב": "פרנק אנה"
        },
        {
            "_id": 16322,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 282,
            "שם_רחוב": "פשוש"
        },
        {
            "_id": 16323,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 175,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 16324,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 283,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 16325,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 229,
            "שם_רחוב": "צור"
        },
        {
            "_id": 16326,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 145,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 16327,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 319,
            "שם_רחוב": "קברנה"
        },
        {
            "_id": 16328,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 170,
            "שם_רחוב": "קדושי השואה"
        },
        {
            "_id": 16329,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 264,
            "שם_רחוב": "קדמה"
        },
        {
            "_id": 16330,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 115,
            "שם_רחוב": "קדש"
        },
        {
            "_id": 16331,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 296,
            "שם_רחוב": "קציר"
        },
        {
            "_id": 16332,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 324,
            "שם_רחוב": "קריניאן"
        },
        {
            "_id": 16333,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 191,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 16334,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 312,
            "שם_רחוב": "קרן יער"
        },
        {
            "_id": 16335,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 199,
            "שם_רחוב": "ראובן"
        },
        {
            "_id": 16336,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 236,
            "שם_רחוב": "רבקה"
        },
        {
            "_id": 16337,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 234,
            "שם_רחוב": "רחל"
        },
        {
            "_id": 16338,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 326,
            "שם_רחוב": "ריזלינג"
        },
        {
            "_id": 16339,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 180,
            "שם_רחוב": "רייק חביבה"
        },
        {
            "_id": 16340,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רמז"
        },
        {
            "_id": 16341,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "רמת צבי"
        },
        {
            "_id": 16342,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש צר עמידר"
        },
        {
            "_id": 16343,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 277,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 16344,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 144,
            "שם_רחוב": "שבי ציון"
        },
        {
            "_id": 16345,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 210,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 16346,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 225,
            "שם_רחוב": "שד יצחק רבין"
        },
        {
            "_id": 16347,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 128,
            "שם_רחוב": "שד נילי"
        },
        {
            "_id": 16348,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 280,
            "שם_רחוב": "שדרת מעוף הציפור"
        },
        {
            "_id": 16349,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 218,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 16350,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 295,
            "שם_רחוב": "שיבולת"
        },
        {
            "_id": 16351,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 322,
            "שם_רחוב": "שיראז"
        },
        {
            "_id": 16352,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 270,
            "שם_רחוב": "שירה"
        },
        {
            "_id": 16353,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 227,
            "שם_רחוב": "שירת הים"
        },
        {
            "_id": 16354,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ גבעת זמארין"
        },
        {
            "_id": 16355,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ החורש"
        },
        {
            "_id": 16356,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "שכ השמורה"
        },
        {
            "_id": 16357,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ יעקב"
        },
        {
            "_id": 16358,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ מול היקב"
        },
        {
            "_id": 16359,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 223,
            "שם_רחוב": "שכטר אבא"
        },
        {
            "_id": 16360,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 302,
            "שם_רחוב": "שלף"
        },
        {
            "_id": 16361,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 194,
            "שם_רחוב": "שעורה"
        },
        {
            "_id": 16362,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 323,
            "שם_רחוב": "שרדונה"
        },
        {
            "_id": 16363,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 249,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 16364,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 205,
            "שם_רחוב": "תאשור"
        },
        {
            "_id": 16365,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 206,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 16366,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 303,
            "שם_רחוב": "תות"
        },
        {
            "_id": 16367,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 208,
            "שם_רחוב": "תחנת הרכבת"
        },
        {
            "_id": 16368,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 235,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 16369,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 151,
            "שם_רחוב": "תרמב"
        },
        {
            "_id": 16370,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "תרעב"
        },
        {
            "_id": 16371,
            "סמל_ישוב": 9300,
            "שם_ישוב": "זכרון יעקב ",
            "סמל_רחוב": 245,
            "שם_רחוב": "תשח"
        },
        {
            "_id": 16372,
            "סמל_ישוב": 799,
            "שם_ישוב": "זכריה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 16373,
            "סמל_ישוב": 799,
            "שם_ישוב": "זכריה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 16374,
            "סמל_ישוב": 799,
            "שם_ישוב": "זכריה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 16375,
            "סמל_ישוב": 799,
            "שם_ישוב": "זכריה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 16376,
            "סמל_ישוב": 799,
            "שם_ישוב": "זכריה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זכריה"
        },
        {
            "_id": 16377,
            "סמל_ישוב": 1290,
            "שם_ישוב": "זמר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אבתאן"
        },
        {
            "_id": 16378,
            "סמל_ישוב": 1290,
            "שם_ישוב": "זמר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ביר א סכה"
        },
        {
            "_id": 16379,
            "סמל_ישוב": 1290,
            "שם_ישוב": "זמר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זמר"
        },
        {
            "_id": 16380,
            "סמל_ישוב": 1290,
            "שם_ישוב": "זמר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "מרג'ה"
        },
        {
            "_id": 16381,
            "סמל_ישוב": 1065,
            "שם_ישוב": "זמרת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זמרת"
        },
        {
            "_id": 16382,
            "סמל_ישוב": 816,
            "שם_ישוב": "זנוח ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זנוח"
        },
        {
            "_id": 16383,
            "סמל_ישוב": 2064,
            "שם_ישוב": "זרועה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זרועה"
        },
        {
            "_id": 16384,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 237,
            "שם_רחוב": "אבו בכר אלסדיק"
        },
        {
            "_id": 16385,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אבן אלהית'ם"
        },
        {
            "_id": 16386,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 342,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 16387,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 353,
            "שם_רחוב": "אחמד בן חנבל"
        },
        {
            "_id": 16388,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 347,
            "שם_רחוב": "אחמד שאוקי"
        },
        {
            "_id": 16389,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 287,
            "שם_רחוב": "אחסבאני"
        },
        {
            "_id": 16390,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אטלס"
        },
        {
            "_id": 16391,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 362,
            "שם_רחוב": "איבן סינא"
        },
        {
            "_id": 16392,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 354,
            "שם_רחוב": "איבן רושד"
        },
        {
            "_id": 16393,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 324,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 16394,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 273,
            "שם_רחוב": "אישראק"
        },
        {
            "_id": 16395,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אלאא"
        },
        {
            "_id": 16396,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 336,
            "שם_רחוב": "אלאהראם"
        },
        {
            "_id": 16397,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלאופוק"
        },
        {
            "_id": 16398,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 231,
            "שם_רחוב": "אלאחנף בן קייס"
        },
        {
            "_id": 16399,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 244,
            "שם_רחוב": "אלאיח'וה"
        },
        {
            "_id": 16400,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אלאימאן"
        },
        {
            "_id": 16401,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אלאמין"
        },
        {
            "_id": 16402,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 302,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 16403,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלאמראא"
        },
        {
            "_id": 16404,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אלאנדלוס"
        },
        {
            "_id": 16405,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אלאנואר"
        },
        {
            "_id": 16406,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלאסוד"
        },
        {
            "_id": 16407,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 365,
            "שם_רחוב": "אלאסראא"
        },
        {
            "_id": 16408,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 241,
            "שם_רחוב": "אלאסתקלאל"
        },
        {
            "_id": 16409,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 309,
            "שם_רחוב": "אלאערב"
        },
        {
            "_id": 16410,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אלבואדי"
        },
        {
            "_id": 16411,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 259,
            "שם_רחוב": "אלבוח'ארי"
        },
        {
            "_id": 16412,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אלבוראק"
        },
        {
            "_id": 16413,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אלבטום"
        },
        {
            "_id": 16414,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלבידאא"
        },
        {
            "_id": 16415,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 333,
            "שם_רחוב": "אלבלח"
        },
        {
            "_id": 16416,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלבסאטין"
        },
        {
            "_id": 16417,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 330,
            "שם_רחוב": "אלבתראא"
        },
        {
            "_id": 16418,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 322,
            "שם_רחוב": "אלג'ולאן"
        },
        {
            "_id": 16419,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 293,
            "שם_רחוב": "אלג'זירה"
        },
        {
            "_id": 16420,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלג'ליל"
        },
        {
            "_id": 16421,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלג'נאת"
        },
        {
            "_id": 16422,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 301,
            "שם_רחוב": "אלג'נה"
        },
        {
            "_id": 16423,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 292,
            "שם_רחוב": "אלדוחה"
        },
        {
            "_id": 16424,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלהד'בה"
        },
        {
            "_id": 16425,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 275,
            "שם_רחוב": "אלהייב"
        },
        {
            "_id": 16426,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 226,
            "שם_רחוב": "אלואדי"
        },
        {
            "_id": 16427,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 316,
            "שם_רחוב": "אלווטוס"
        },
        {
            "_id": 16428,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 271,
            "שם_רחוב": "אלוורוד"
        },
        {
            "_id": 16429,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלוערה"
        },
        {
            "_id": 16430,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 343,
            "שם_רחוב": "אלופאא"
        },
        {
            "_id": 16431,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 238,
            "שם_רחוב": "אלזהראא"
        },
        {
            "_id": 16432,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלזוהרה"
        },
        {
            "_id": 16433,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 373,
            "שם_רחוב": "אלזומורד"
        },
        {
            "_id": 16434,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלזופה"
        },
        {
            "_id": 16435,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 297,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 16436,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 320,
            "שם_רחוב": "אלזמאן"
        },
        {
            "_id": 16437,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 367,
            "שם_רחוב": "אלזנבק"
        },
        {
            "_id": 16438,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלזעתר"
        },
        {
            "_id": 16439,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלח'ד'ראא"
        },
        {
            "_id": 16440,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 363,
            "שם_רחוב": "אלח'ואריזמי"
        },
        {
            "_id": 16441,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלח'יראת"
        },
        {
            "_id": 16442,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 334,
            "שם_רחוב": "אלח'ליל"
        },
        {
            "_id": 16443,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 279,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 16444,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלחרמון"
        },
        {
            "_id": 16445,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלטור"
        },
        {
            "_id": 16446,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אלירקון"
        },
        {
            "_id": 16447,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 289,
            "שם_רחוב": "אלכות'ר"
        },
        {
            "_id": 16448,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אלכנסת"
        },
        {
            "_id": 16449,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אלכרום"
        },
        {
            "_id": 16450,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 305,
            "שם_רחוב": "אלכרמה"
        },
        {
            "_id": 16451,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 254,
            "שם_רחוב": "אללוז"
        },
        {
            "_id": 16452,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 264,
            "שם_רחוב": "אללולו"
        },
        {
            "_id": 16453,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 361,
            "שם_רחוב": "אלמאמון"
        },
        {
            "_id": 16454,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלמדינה"
        },
        {
            "_id": 16455,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 277,
            "שם_רחוב": "אלמדרסה"
        },
        {
            "_id": 16456,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 340,
            "שם_רחוב": "אלמהא"
        },
        {
            "_id": 16457,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 295,
            "שם_רחוב": "אלמוח'תאר"
        },
        {
            "_id": 16458,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלמולוק"
        },
        {
            "_id": 16459,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 332,
            "שם_רחוב": "אלמועלם"
        },
        {
            "_id": 16460,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 359,
            "שם_רחוב": "אלמועתסם"
        },
        {
            "_id": 16461,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 228,
            "שם_רחוב": "אלמות'נא"
        },
        {
            "_id": 16462,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 218,
            "שם_רחוב": "אלמלול"
        },
        {
            "_id": 16463,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אלמנארה"
        },
        {
            "_id": 16464,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 286,
            "שם_רחוב": "אלמראעי"
        },
        {
            "_id": 16465,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלמרג' אלאח'ד'ר"
        },
        {
            "_id": 16466,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלנאסרה"
        },
        {
            "_id": 16467,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אלנבי מוסא"
        },
        {
            "_id": 16468,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אלנביא סאלח"
        },
        {
            "_id": 16469,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 197,
            "שם_רחוב": "אלנדים"
        },
        {
            "_id": 16470,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלנואר"
        },
        {
            "_id": 16471,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אלנועמאן"
        },
        {
            "_id": 16472,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 249,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 16473,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אלנורס"
        },
        {
            "_id": 16474,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אלניזק"
        },
        {
            "_id": 16475,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 338,
            "שם_רחוב": "אלניל"
        },
        {
            "_id": 16476,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלנימר"
        },
        {
            "_id": 16477,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אלנסמאת"
        },
        {
            "_id": 16478,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 285,
            "שם_רחוב": "אלנענע"
        },
        {
            "_id": 16479,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלנקב"
        },
        {
            "_id": 16480,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 299,
            "שם_רחוב": "אלנרג'יס"
        },
        {
            "_id": 16481,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 307,
            "שם_רחוב": "אלסאדה"
        },
        {
            "_id": 16482,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אלסאהרה"
        },
        {
            "_id": 16483,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אלסוקור"
        },
        {
            "_id": 16484,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 16485,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אלסנדיאן"
        },
        {
            "_id": 16486,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 281,
            "שם_רחוב": "אלספאא"
        },
        {
            "_id": 16487,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אלספוריה"
        },
        {
            "_id": 16488,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 319,
            "שם_רחוב": "אלסרג'"
        },
        {
            "_id": 16489,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלסרו"
        },
        {
            "_id": 16490,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אלסריס"
        },
        {
            "_id": 16491,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 195,
            "שם_רחוב": "אלע'ד'נפר"
        },
        {
            "_id": 16492,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 193,
            "שם_רחוב": "אלע'דיר"
        },
        {
            "_id": 16493,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אלע'פארי"
        },
        {
            "_id": 16494,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלעאלי"
        },
        {
            "_id": 16495,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 276,
            "שם_רחוב": "אלעולא"
        },
        {
            "_id": 16496,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלעז"
        },
        {
            "_id": 16497,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אלעיין"
        },
        {
            "_id": 16498,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 257,
            "שם_רחוב": "אלעיקאב"
        },
        {
            "_id": 16499,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אלעליק"
        },
        {
            "_id": 16500,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אלעקיק"
        },
        {
            "_id": 16501,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלערב"
        },
        {
            "_id": 16502,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 329,
            "שם_רחוב": "אלפוראת"
        },
        {
            "_id": 16503,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלפיחאא"
        },
        {
            "_id": 16504,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אלפראבי"
        },
        {
            "_id": 16505,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אלקאדסיה"
        },
        {
            "_id": 16506,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 341,
            "שם_רחוב": "אלקבס"
        },
        {
            "_id": 16507,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 265,
            "שם_רחוב": "אלקודס"
        },
        {
            "_id": 16508,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אלקירואן"
        },
        {
            "_id": 16509,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלקעקאע בן עמר"
        },
        {
            "_id": 16510,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלראביה"
        },
        {
            "_id": 16511,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אלרד'ואן"
        },
        {
            "_id": 16512,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 372,
            "שם_רחוב": "אלרואבי"
        },
        {
            "_id": 16513,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלרואד"
        },
        {
            "_id": 16514,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 294,
            "שם_רחוב": "אלריאד'"
        },
        {
            "_id": 16515,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 368,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 16516,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 360,
            "שם_רחוב": "אלרשיד"
        },
        {
            "_id": 16517,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלשאטאא"
        },
        {
            "_id": 16518,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אלשאם"
        },
        {
            "_id": 16519,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 247,
            "שם_רחוב": "אלשורוק"
        },
        {
            "_id": 16520,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 375,
            "שם_רחוב": "אלת'וריא"
        },
        {
            "_id": 16521,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 263,
            "שם_רחוב": "אלתאח'י"
        },
        {
            "_id": 16522,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 308,
            "שם_רחוב": "אלתוראת'"
        },
        {
            "_id": 16523,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלתות"
        },
        {
            "_id": 16524,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 306,
            "שם_רחוב": "אלתין"
        },
        {
            "_id": 16525,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 355,
            "שם_רחוב": "אלתקדום"
        },
        {
            "_id": 16526,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 310,
            "שם_רחוב": "אנואר סאדאת"
        },
        {
            "_id": 16527,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אנס בן מאלק"
        },
        {
            "_id": 16528,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 248,
            "שם_רחוב": "אנסאם"
        },
        {
            "_id": 16529,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אסיא"
        },
        {
            "_id": 16530,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 284,
            "שם_רחוב": "אסיל"
        },
        {
            "_id": 16531,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 291,
            "שם_רחוב": "אע'סאן"
        },
        {
            "_id": 16532,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 253,
            "שם_רחוב": "אריאם"
        },
        {
            "_id": 16533,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 366,
            "שם_רחוב": "אריג'"
        },
        {
            "_id": 16534,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אשדוד"
        },
        {
            "_id": 16535,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 138,
            "שם_רחוב": "בדר"
        },
        {
            "_id": 16536,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 139,
            "שם_רחוב": "בילאל בן רבאח"
        },
        {
            "_id": 16537,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 369,
            "שם_רחוב": "בילסאן"
        },
        {
            "_id": 16538,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 262,
            "שם_רחוב": "ביסאן"
        },
        {
            "_id": 16539,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 212,
            "שם_רחוב": "בירות"
        },
        {
            "_id": 16540,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 151,
            "שם_רחוב": "בית לחם"
        },
        {
            "_id": 16541,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 282,
            "שם_רחוב": "בסמאן"
        },
        {
            "_id": 16542,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 179,
            "שם_רחוב": "בר לב"
        },
        {
            "_id": 16543,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "ג'ואמיס"
        },
        {
            "_id": 16544,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 317,
            "שם_רחוב": "ג'ולאני"
        },
        {
            "_id": 16545,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 323,
            "שם_רחוב": "ג'ומעה"
        },
        {
            "_id": 16546,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 141,
            "שם_רחוב": "ג'נא"
        },
        {
            "_id": 16547,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 250,
            "שם_רחוב": "ג'נאן"
        },
        {
            "_id": 16548,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "גזאלין"
        },
        {
            "_id": 16549,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "גריפאת"
        },
        {
            "_id": 16550,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 143,
            "שם_רחוב": "ד'יאא"
        },
        {
            "_id": 16551,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 190,
            "שם_רחוב": "דאפר"
        },
        {
            "_id": 16552,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 159,
            "שם_רחוב": "דימה"
        },
        {
            "_id": 16553,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 220,
            "שם_רחוב": "האבאנה"
        },
        {
            "_id": 16554,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 202,
            "שם_רחוב": "האג'ר"
        },
        {
            "_id": 16555,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 300,
            "שם_רחוב": "האדאס"
        },
        {
            "_id": 16556,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 148,
            "שם_רחוב": "הגיפן"
        },
        {
            "_id": 16557,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "היב אבו סיאח"
        },
        {
            "_id": 16558,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 177,
            "שם_רחוב": "המוסך"
        },
        {
            "_id": 16559,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 352,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 16560,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 236,
            "שם_רחוב": "ואאל"
        },
        {
            "_id": 16561,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 198,
            "שם_רחוב": "זאיד"
        },
        {
            "_id": 16562,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 242,
            "שם_רחוב": "זהראן"
        },
        {
            "_id": 16563,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 183,
            "שם_רחוב": "זהרת אלמדאאן"
        },
        {
            "_id": 16564,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 135,
            "שם_רחוב": "זמזם"
        },
        {
            "_id": 16565,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זרזיר"
        },
        {
            "_id": 16566,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 351,
            "שם_רחוב": "ח'אלד בן אלוליד"
        },
        {
            "_id": 16567,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 335,
            "שם_רחוב": "חאתם אלטאאי"
        },
        {
            "_id": 16568,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 239,
            "שם_רחוב": "חדיג'ה בנת ח'וילד"
        },
        {
            "_id": 16569,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 161,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 16570,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 325,
            "שם_רחוב": "חסן בן ת'אבת"
        },
        {
            "_id": 16571,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 196,
            "שם_רחוב": "חפסה"
        },
        {
            "_id": 16572,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 174,
            "שם_רחוב": "טבעון"
        },
        {
            "_id": 16573,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 346,
            "שם_רחוב": "יאמין"
        },
        {
            "_id": 16574,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 191,
            "שם_רחוב": "יגאל אלון"
        },
        {
            "_id": 16575,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 204,
            "שם_רחוב": "יוסף אלסדיק"
        },
        {
            "_id": 16576,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 344,
            "שם_רחוב": "יזן"
        },
        {
            "_id": 16577,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 167,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 16578,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 194,
            "שם_רחוב": "יפא"
        },
        {
            "_id": 16579,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "יפעאת"
        },
        {
            "_id": 16580,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 269,
            "שם_רחוב": "יצחק רבין"
        },
        {
            "_id": 16581,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 304,
            "שם_רחוב": "ית'ריב"
        },
        {
            "_id": 16582,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 331,
            "שם_רחוב": "כאוכב"
        },
        {
            "_id": 16583,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 192,
            "שם_רחוב": "כנאנה"
        },
        {
            "_id": 16584,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 211,
            "שם_רחוב": "לובנאן"
        },
        {
            "_id": 16585,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "לוגיין"
        },
        {
            "_id": 16586,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 181,
            "שם_רחוב": "לימאן"
        },
        {
            "_id": 16587,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 339,
            "שם_רחוב": "מאאריב"
        },
        {
            "_id": 16588,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "מאריה אלקבטיה"
        },
        {
            "_id": 16589,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 224,
            "שם_רחוב": "מוטה גור"
        },
        {
            "_id": 16590,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 243,
            "שם_רחוב": "מוסלים"
        },
        {
            "_id": 16591,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 261,
            "שם_רחוב": "מוראד"
        },
        {
            "_id": 16592,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מזאריב"
        },
        {
            "_id": 16593,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 312,
            "שם_רחוב": "מנחם בגין"
        },
        {
            "_id": 16594,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 283,
            "שם_רחוב": "מנסור"
        },
        {
            "_id": 16595,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 321,
            "שם_רחוב": "מסאדה"
        },
        {
            "_id": 16596,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 288,
            "שם_רחוב": "מסג'ד אלהודא"
        },
        {
            "_id": 16597,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "מרג' בן עאמר"
        },
        {
            "_id": 16598,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 170,
            "שם_רחוב": "משה דיין"
        },
        {
            "_id": 16599,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 234,
            "שם_רחוב": "נג'ד"
        },
        {
            "_id": 16600,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 370,
            "שם_רחוב": "נהלאל"
        },
        {
            "_id": 16601,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 201,
            "שם_רחוב": "ניסאן"
        },
        {
            "_id": 16602,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 203,
            "שם_רחוב": "סארה"
        },
        {
            "_id": 16603,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 171,
            "שם_רחוב": "סאריה"
        },
        {
            "_id": 16604,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 199,
            "שם_רחוב": "סדים"
        },
        {
            "_id": 16605,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 142,
            "שם_רחוב": "סהארה"
        },
        {
            "_id": 16606,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 280,
            "שם_רחוב": "סולטאן באשא"
        },
        {
            "_id": 16607,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 371,
            "שם_רחוב": "סונדוס"
        },
        {
            "_id": 16608,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 126,
            "שם_רחוב": "סינאא"
        },
        {
            "_id": 16609,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 229,
            "שם_רחוב": "סלים אלת'אני"
        },
        {
            "_id": 16610,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 274,
            "שם_רחוב": "סמא"
        },
        {
            "_id": 16611,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 154,
            "שם_רחוב": "סעד בן וקאס"
        },
        {
            "_id": 16612,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 235,
            "שם_רחוב": "סעיד בן זייד"
        },
        {
            "_id": 16613,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 168,
            "שם_רחוב": "סעסע"
        },
        {
            "_id": 16614,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 348,
            "שם_רחוב": "ע'די"
        },
        {
            "_id": 16615,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 350,
            "שם_רחוב": "עאמר בן אלג'ראח"
        },
        {
            "_id": 16616,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 152,
            "שם_רחוב": "עאתכה"
        },
        {
            "_id": 16617,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 255,
            "שם_רחוב": "עדן"
        },
        {
            "_id": 16618,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 272,
            "שם_רחוב": "עוטרה"
        },
        {
            "_id": 16619,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 178,
            "שם_רחוב": "עומר בן אלח'טאב"
        },
        {
            "_id": 16620,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 345,
            "שם_רחוב": "עות'מאן בן עפאן"
        },
        {
            "_id": 16621,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "עיאדאת"
        },
        {
            "_id": 16622,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 166,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 16623,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 184,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 16624,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 153,
            "שם_רחוב": "עמאר בן יאסר"
        },
        {
            "_id": 16625,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ערב אלמזאריב"
        },
        {
            "_id": 16626,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 182,
            "שם_רחוב": "עתלית"
        },
        {
            "_id": 16627,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 313,
            "שם_רחוב": "פואד בן אליעזר"
        },
        {
            "_id": 16628,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 337,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 16629,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 163,
            "שם_רחוב": "קוביס"
        },
        {
            "_id": 16630,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 278,
            "שם_רחוב": "קוטוף"
        },
        {
            "_id": 16631,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 223,
            "שם_רחוב": "קות'באן"
        },
        {
            "_id": 16632,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 260,
            "שם_רחוב": "רד'ואן"
        },
        {
            "_id": 16633,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 146,
            "שם_רחוב": "ריאן"
        },
        {
            "_id": 16634,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 374,
            "שם_רחוב": "רנד"
        },
        {
            "_id": 16635,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 266,
            "שם_רחוב": "רע'דאן"
        },
        {
            "_id": 16636,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 256,
            "שם_רחוב": "שמוס"
        },
        {
            "_id": 16637,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 298,
            "שם_רחוב": "שמעון פרס"
        },
        {
            "_id": 16638,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 296,
            "שם_רחוב": "שרון"
        },
        {
            "_id": 16639,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 326,
            "שם_רחוב": "תאג'"
        },
        {
            "_id": 16640,
            "סמל_ישוב": 975,
            "שם_ישוב": "זרזיר ",
            "סמל_רחוב": 180,
            "שם_רחוב": "תימאא"
        },
        {
            "_id": 16641,
            "סמל_ישוב": 818,
            "שם_ישוב": "זרחיה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "זרחיה"
        },
        {
            "_id": 16642,
            "סמל_ישוב": 1321,
            "שם_ישוב": "ח'ואלד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ח'ואלד"
        },
        {
            "_id": 16643,
            "סמל_ישוב": 986,
            "שם_ישוב": "ח'ואלד (שבט) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ח'ואלד (שבט)"
        },
        {
            "_id": 16644,
            "סמל_ישוב": 235,
            "שם_ישוב": "חבצלת השרון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אולפן עקיבא"
        },
        {
            "_id": 16645,
            "סמל_ישוב": 235,
            "שם_ישוב": "חבצלת השרון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך הבנים"
        },
        {
            "_id": 16646,
            "סמל_ישוב": 235,
            "שם_ישוב": "חבצלת השרון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 16647,
            "סמל_ישוב": 235,
            "שם_ישוב": "חבצלת השרון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 16648,
            "סמל_ישוב": 235,
            "שם_ישוב": "חבצלת השרון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 16649,
            "סמל_ישוב": 235,
            "שם_ישוב": "חבצלת השרון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הפרדסים"
        },
        {
            "_id": 16650,
            "סמל_ישוב": 235,
            "שם_ישוב": "חבצלת השרון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חבצלת השרון"
        },
        {
            "_id": 16651,
            "סמל_ישוב": 235,
            "שם_ישוב": "חבצלת השרון ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "נוה הדר"
        },
        {
            "_id": 16652,
            "סמל_ישוב": 235,
            "שם_ישוב": "חבצלת השרון ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש רסקו וילות"
        },
        {
            "_id": 16653,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 16654,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הבז"
        },
        {
            "_id": 16655,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 16656,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הדוכיפת"
        },
        {
            "_id": 16657,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 16658,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "החוחית"
        },
        {
            "_id": 16659,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "היונה"
        },
        {
            "_id": 16660,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הינשוף"
        },
        {
            "_id": 16661,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 16662,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "המגלן"
        },
        {
            "_id": 16663,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הסיקסק"
        },
        {
            "_id": 16664,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "העורב"
        },
        {
            "_id": 16665,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הצופית"
        },
        {
            "_id": 16666,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 16667,
            "סמל_ישוב": 1110,
            "שם_ישוב": "חבר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חבר"
        },
        {
            "_id": 16668,
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "אדמות ישי"
        },
        {
            "_id": 16669,
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית הדסה"
        },
        {
            "_id": 16670,
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "בית חסון"
        },
        {
            "_id": 16671,
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "בית קסטל"
        },
        {
            "_id": 16672,
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "בית רומנו"
        },
        {
            "_id": 16673,
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "בית שניאורסון"
        },
        {
            "_id": 16674,
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הרובע היהודי"
        },
        {
            "_id": 16675,
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חברון"
        },
        {
            "_id": 16676,
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "כולל אברהם אבינו"
        },
        {
            "_id": 16677,
            "סמל_ישוב": 3400,
            "שם_ישוב": "חברון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "תל רומיידה"
        },
        {
            "_id": 16678,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 16679,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 202,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 16680,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 108,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 16681,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 16682,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הסולטן"
        },
        {
            "_id": 16683,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 16684,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 201,
            "שם_רחוב": "השלדג"
        },
        {
            "_id": 16685,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חגור"
        },
        {
            "_id": 16686,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 103,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 16687,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 104,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 16688,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 203,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 16689,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 16690,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 205,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 16691,
            "סמל_ישוב": 717,
            "שם_ישוב": "חגור ",
            "סמל_רחוב": 204,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 16692,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 16693,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 104,
            "שם_רחוב": "ארץ יהודה"
        },
        {
            "_id": 16694,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך האבות"
        },
        {
            "_id": 16695,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 16696,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 110,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 16697,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 112,
            "שם_רחוב": "מעלה חגי"
        },
        {
            "_id": 16698,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נחל אשכול"
        },
        {
            "_id": 16699,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 102,
            "שם_רחוב": "נחל הרדוף"
        },
        {
            "_id": 16700,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נחל חבר"
        },
        {
            "_id": 16701,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 106,
            "שם_רחוב": "נחל לכיש"
        },
        {
            "_id": 16702,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 101,
            "שם_רחוב": "נחל שקמה"
        },
        {
            "_id": 16703,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 109,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 16704,
            "סמל_ישוב": 3764,
            "שם_ישוב": "חגי ",
            "סמל_רחוב": 108,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 16705,
            "סמל_ישוב": 205,
            "שם_ישוב": "חגלה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חגלה"
        },
        {
            "_id": 16706,
            "סמל_ישוב": 205,
            "שם_ישוב": "חגלה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "פרח בר"
        },
        {
            "_id": 16707,
            "סמל_ישוב": 4026,
            "שם_ישוב": "חד-נס ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חד-נס"
        },
        {
            "_id": 16708,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 16709,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 110,
            "שם_רחוב": "בשמת"
        },
        {
            "_id": 16710,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 16711,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 102,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 16712,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 16713,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 16714,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 16715,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הסייפן"
        },
        {
            "_id": 16716,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 16717,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 16718,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 103,
            "שם_רחוב": "השושן"
        },
        {
            "_id": 16719,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חדיד"
        },
        {
            "_id": 16720,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 113,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 16721,
            "סמל_ישוב": 618,
            "שם_ישוב": "חדיד ",
            "סמל_רחוב": 108,
            "שם_רחוב": "עין התכלת"
        },
        {
            "_id": 16722,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 615,
            "שם_רחוב": "אביאל"
        },
        {
            "_id": 16723,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 722,
            "שם_רחוב": "אבשלום"
        },
        {
            "_id": 16724,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 560,
            "שם_רחוב": "אדני פז"
        },
        {
            "_id": 16725,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 427,
            "שם_רחוב": "אהרונוביץ"
        },
        {
            "_id": 16726,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 450,
            "שם_רחוב": "אהרונסון"
        },
        {
            "_id": 16727,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 408,
            "שם_רחוב": "אוסישקין"
        },
        {
            "_id": 16728,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 668,
            "שם_רחוב": "אופירה"
        },
        {
            "_id": 16729,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6560,
            "שם_רחוב": "אזבסט אליעזר"
        },
        {
            "_id": 16730,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "אזור תעשייה"
        },
        {
            "_id": 16731,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 701,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 16732,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 906,
            "שם_רחוב": "אחוזת בית"
        },
        {
            "_id": 16733,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6598,
            "שם_רחוב": "אחוזת דניה"
        },
        {
            "_id": 16734,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אחימאיר אבא"
        },
        {
            "_id": 16735,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 879,
            "שם_רחוב": "אידלווין"
        },
        {
            "_id": 16736,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 872,
            "שם_רחוב": "אייל הכרמל"
        },
        {
            "_id": 16737,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 954,
            "שם_רחוב": "איילון"
        },
        {
            "_id": 16738,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 890,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 16739,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "אכזיב"
        },
        {
            "_id": 16740,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 460,
            "שם_רחוב": "אלון יגאל"
        },
        {
            "_id": 16741,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "אלונה"
        },
        {
            "_id": 16742,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 740,
            "שם_רחוב": "אלוף יקותיאל אדם"
        },
        {
            "_id": 16743,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 511,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 16744,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 400,
            "שם_רחוב": "אלעזר דוד"
        },
        {
            "_id": 16745,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "אסטוריה מאיר"
        },
        {
            "_id": 16746,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 738,
            "שם_רחוב": "אסירי ציון"
        },
        {
            "_id": 16747,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 561,
            "שם_רחוב": "אפיקי מים"
        },
        {
            "_id": 16748,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 907,
            "שם_רחוב": "אפעל"
        },
        {
            "_id": 16749,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 451,
            "שם_רחוב": "אפשטיין משה מרדכי"
        },
        {
            "_id": 16750,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 16751,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 562,
            "שם_רחוב": "ארזי הלבנון"
        },
        {
            "_id": 16752,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 16753,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 835,
            "שם_רחוב": "אשבל"
        },
        {
            "_id": 16754,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 819,
            "שם_רחוב": "אשכול לוי"
        },
        {
            "_id": 16755,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 581,
            "שם_רחוב": "באר אורה"
        },
        {
            "_id": 16756,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 669,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 16757,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 434,
            "שם_רחוב": "בוארון ברוך"
        },
        {
            "_id": 16758,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 734,
            "שם_רחוב": "בוטקובסקי"
        },
        {
            "_id": 16759,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 16760,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 409,
            "שם_רחוב": "בזק"
        },
        {
            "_id": 16761,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 417,
            "שם_רחוב": "ביח הלל יפה"
        },
        {
            "_id": 16762,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 713,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 16763,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 407,
            "שם_רחוב": "בילינסון"
        },
        {
            "_id": 16764,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "ביס חקלאי עירון"
        },
        {
            "_id": 16765,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 892,
            "שם_רחוב": "בלקינד ישראל"
        },
        {
            "_id": 16766,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "בן אליעזר אריה"
        },
        {
            "_id": 16767,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 898,
            "שם_רחוב": "בן יהודה"
        },
        {
            "_id": 16768,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 897,
            "שם_רחוב": "בן עמי"
        },
        {
            "_id": 16769,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 820,
            "שם_רחוב": "בן צבי יצחק"
        },
        {
            "_id": 16770,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 952,
            "שם_רחוב": "בניאס"
        },
        {
            "_id": 16771,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "בעלי המלאכה"
        },
        {
            "_id": 16772,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 655,
            "שם_רחוב": "בר אילן מאיר"
        },
        {
            "_id": 16773,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 506,
            "שם_רחוב": "ברזילי"
        },
        {
            "_id": 16774,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 488,
            "שם_רחוב": "ברכת עטה"
        },
        {
            "_id": 16775,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "ברמן דוד"
        },
        {
            "_id": 16776,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 707,
            "שם_רחוב": "ברנדיס"
        },
        {
            "_id": 16777,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 411,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 16778,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "גבעת בוסל"
        },
        {
            "_id": 16779,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "גבעת בילו א"
        },
        {
            "_id": 16780,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "גבעת בילו ב"
        },
        {
            "_id": 16781,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 563,
            "שם_רחוב": "גבעת הלבונה"
        },
        {
            "_id": 16782,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 703,
            "שם_רחוב": "גבעת המורה"
        },
        {
            "_id": 16783,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 670,
            "שם_רחוב": "גדור"
        },
        {
            "_id": 16784,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 908,
            "שם_רחוב": "גדות"
        },
        {
            "_id": 16785,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 752,
            "שם_רחוב": "גולדה מאיר"
        },
        {
            "_id": 16786,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "גולדנברג"
        },
        {
            "_id": 16787,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "גולומב אליהו"
        },
        {
            "_id": 16788,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 413,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 16789,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 459,
            "שם_רחוב": "גורדון א ד"
        },
        {
            "_id": 16790,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 955,
            "שם_רחוב": "גוש חלב"
        },
        {
            "_id": 16791,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 719,
            "שם_רחוב": "גוש עציון"
        },
        {
            "_id": 16792,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 909,
            "שם_רחוב": "גיבתון"
        },
        {
            "_id": 16793,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 589,
            "שם_רחוב": "גיורא צחור"
        },
        {
            "_id": 16794,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 850,
            "שם_רחוב": "גלוזגל"
        },
        {
            "_id": 16795,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "גלוסקא"
        },
        {
            "_id": 16796,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6597,
            "שם_רחוב": "גלי חוף"
        },
        {
            "_id": 16797,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 956,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 16798,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 491,
            "שם_רחוב": "גמלא"
        },
        {
            "_id": 16799,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 582,
            "שם_רחוב": "גן שמואל"
        },
        {
            "_id": 16800,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6567,
            "שם_רחוב": "גני ברנדיס"
        },
        {
            "_id": 16801,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6561,
            "שם_רחוב": "גני חדרה"
        },
        {
            "_id": 16802,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 875,
            "שם_רחוב": "גפנוביץ"
        },
        {
            "_id": 16803,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 910,
            "שם_רחוב": "גרופית"
        },
        {
            "_id": 16804,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 452,
            "שם_רחוב": "גרינבוים יצחק"
        },
        {
            "_id": 16805,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 957,
            "שם_רחוב": "גרנות"
        },
        {
            "_id": 16806,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "דר אביאור"
        },
        {
            "_id": 16807,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 750,
            "שם_רחוב": "דר ברין"
        },
        {
            "_id": 16808,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 533,
            "שם_רחוב": "דר דן ויליאם"
        },
        {
            "_id": 16809,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 576,
            "שם_רחוב": "דב לאוטמן"
        },
        {
            "_id": 16810,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 830,
            "שם_רחוב": "דבורה משבץ"
        },
        {
            "_id": 16811,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "דגניה"
        },
        {
            "_id": 16812,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 583,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 16813,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 880,
            "שם_רחוב": "דולינסקי"
        },
        {
            "_id": 16814,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 453,
            "שם_רחוב": "דורי יעקב"
        },
        {
            "_id": 16815,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 498,
            "שם_רחוב": "דורשי ציון"
        },
        {
            "_id": 16816,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 476,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 16817,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 855,
            "שם_רחוב": "דינארד"
        },
        {
            "_id": 16818,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "דלתון"
        },
        {
            "_id": 16819,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 958,
            "שם_רחוב": "דקל הדום"
        },
        {
            "_id": 16820,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך בית אליעזר"
        },
        {
            "_id": 16821,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 539,
            "שם_רחוב": "דרך הרכבת"
        },
        {
            "_id": 16822,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 1009,
            "שם_רחוב": "דרך נחל עירון"
        },
        {
            "_id": 16823,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 521,
            "שם_רחוב": "ה באייר"
        },
        {
            "_id": 16824,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 840,
            "שם_רחוב": "האבוקדו"
        },
        {
            "_id": 16825,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 16826,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 496,
            "שם_רחוב": "האגמון"
        },
        {
            "_id": 16827,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 912,
            "שם_רחוב": "האדמה"
        },
        {
            "_id": 16828,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 959,
            "שם_רחוב": "האדמונית"
        },
        {
            "_id": 16829,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 960,
            "שם_רחוב": "האדר"
        },
        {
            "_id": 16830,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 299,
            "שם_רחוב": "האומן"
        },
        {
            "_id": 16831,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 1001,
            "שם_רחוב": "האור"
        },
        {
            "_id": 16832,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 605,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 16833,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 671,
            "שם_רחוב": "האושר"
        },
        {
            "_id": 16834,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 961,
            "שם_רחוב": "האזוב"
        },
        {
            "_id": 16835,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 672,
            "שם_רחוב": "האחו"
        },
        {
            "_id": 16836,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 673,
            "שם_רחוב": "האחוה"
        },
        {
            "_id": 16837,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 837,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 16838,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 753,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 16839,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 16840,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 913,
            "שם_רחוב": "האלומה"
        },
        {
            "_id": 16841,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 723,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 16842,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 914,
            "שם_רחוב": "האלונים"
        },
        {
            "_id": 16843,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 643,
            "שם_רחוב": "האלמוג"
        },
        {
            "_id": 16844,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 531,
            "שם_רחוב": "האמוראים"
        },
        {
            "_id": 16845,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 642,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 16846,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 915,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 16847,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 962,
            "שם_רחוב": "האפרסמון"
        },
        {
            "_id": 16848,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 16849,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 650,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 16850,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 846,
            "שם_רחוב": "הארבל"
        },
        {
            "_id": 16851,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 490,
            "שם_רחוב": "הארגמן"
        },
        {
            "_id": 16852,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 724,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 16853,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 848,
            "שם_רחוב": "האשד"
        },
        {
            "_id": 16854,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האשור"
        },
        {
            "_id": 16855,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 799,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 16856,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 725,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 16857,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 1002,
            "שם_רחוב": "הבאר"
        },
        {
            "_id": 16858,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 916,
            "שם_רחוב": "הבונים"
        },
        {
            "_id": 16859,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 1003,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 16860,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 917,
            "שם_רחוב": "הבזלת"
        },
        {
            "_id": 16861,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 619,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 16862,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 918,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 16863,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 674,
            "שם_רחוב": "הבקעה"
        },
        {
            "_id": 16864,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 726,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 16865,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 516,
            "שם_רחוב": "הבריגדה היהודית"
        },
        {
            "_id": 16866,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 919,
            "שם_רחוב": "הבשמים"
        },
        {
            "_id": 16867,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 963,
            "שם_רחוב": "הבשמת"
        },
        {
            "_id": 16868,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 641,
            "שם_רחוב": "הבשן"
        },
        {
            "_id": 16869,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 609,
            "שם_רחוב": "הגבורים"
        },
        {
            "_id": 16870,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 517,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 16871,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 847,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 16872,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 921,
            "שם_רחוב": "הגושרים"
        },
        {
            "_id": 16873,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 675,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 16874,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 513,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 16875,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 770,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 16876,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 651,
            "שם_רחוב": "הגרא"
        },
        {
            "_id": 16877,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 922,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 16878,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 785,
            "שם_רחוב": "הגתית"
        },
        {
            "_id": 16879,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 923,
            "שם_רחוב": "הדביר"
        },
        {
            "_id": 16880,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 693,
            "שם_רחוב": "הדבש"
        },
        {
            "_id": 16881,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 822,
            "שם_רחוב": "הדגן"
        },
        {
            "_id": 16882,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 564,
            "שם_רחוב": "הדודאים"
        },
        {
            "_id": 16883,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 613,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 16884,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 640,
            "שם_רחוב": "הדייגים"
        },
        {
            "_id": 16885,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 793,
            "שם_רחוב": "הדליה"
        },
        {
            "_id": 16886,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 754,
            "שם_רחוב": "הדפנה"
        },
        {
            "_id": 16887,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 16888,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 639,
            "שם_רחוב": "הדרדר"
        },
        {
            "_id": 16889,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 762,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 16890,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 803,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 16891,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 817,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 16892,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 600,
            "שם_רחוב": "ההדר"
        },
        {
            "_id": 16893,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 454,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 16894,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 676,
            "שם_רחוב": "ההרדוף"
        },
        {
            "_id": 16895,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 755,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 16896,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "הזגג"
        },
        {
            "_id": 16897,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 584,
            "שם_רחוב": "הזוגן"
        },
        {
            "_id": 16898,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "הזוויתן"
        },
        {
            "_id": 16899,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 732,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 16900,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 924,
            "שם_רחוב": "הזכוכית"
        },
        {
            "_id": 16901,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 763,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 16902,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 764,
            "שם_רחוב": "החוחית"
        },
        {
            "_id": 16903,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 495,
            "שם_רחוב": "החולית"
        },
        {
            "_id": 16904,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 638,
            "שם_רחוב": "החופית"
        },
        {
            "_id": 16905,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 756,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 16906,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 652,
            "שם_רחוב": "החידא"
        },
        {
            "_id": 16907,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 925,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 16908,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 849,
            "שם_רחוב": "החיננית"
        },
        {
            "_id": 16909,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 534,
            "שם_רחוב": "החלוץ"
        },
        {
            "_id": 16910,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 787,
            "שם_רחוב": "החליל"
        },
        {
            "_id": 16911,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 926,
            "שם_רחוב": "החלמיש"
        },
        {
            "_id": 16912,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 585,
            "שם_רחוב": "החסידה"
        },
        {
            "_id": 16913,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 637,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 16914,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 298,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 16915,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 553,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 16916,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "הטייסים"
        },
        {
            "_id": 16917,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 765,
            "שם_רחוב": "היונה"
        },
        {
            "_id": 16918,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 297,
            "שם_רחוב": "היוצרים"
        },
        {
            "_id": 16919,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 766,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 16920,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 636,
            "שם_רחוב": "היסעור"
        },
        {
            "_id": 16921,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 967,
            "שם_רחוב": "היערה"
        },
        {
            "_id": 16922,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 269,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 16923,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 520,
            "שם_רחוב": "הירקון"
        },
        {
            "_id": 16924,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 757,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 16925,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 789,
            "שם_רחוב": "הכנור"
        },
        {
            "_id": 16926,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 415,
            "שם_רחוב": "הכנסת"
        },
        {
            "_id": 16927,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 677,
            "שם_רחוב": "הכנרת"
        },
        {
            "_id": 16928,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 432,
            "שם_רחוב": "הכף גימל"
        },
        {
            "_id": 16929,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 823,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 16930,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 565,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 16931,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 540,
            "שם_רחוב": "הלה"
        },
        {
            "_id": 16932,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 494,
            "שם_רחוב": "הלבנית"
        },
        {
            "_id": 16933,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 635,
            "שם_רחוב": "הלגונה"
        },
        {
            "_id": 16934,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 792,
            "שם_רחוב": "הלוטם"
        },
        {
            "_id": 16935,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 882,
            "שם_רחוב": "הלוי"
        },
        {
            "_id": 16936,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הליבנה"
        },
        {
            "_id": 16937,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 964,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 16938,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 834,
            "שם_רחוב": "הלימון"
        },
        {
            "_id": 16939,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 603,
            "שם_רחוב": "הלל יפה"
        },
        {
            "_id": 16940,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 927,
            "שם_רחוב": "הלשם"
        },
        {
            "_id": 16941,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 431,
            "שם_רחוב": "המגינים"
        },
        {
            "_id": 16942,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "המולדת"
        },
        {
            "_id": 16943,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 709,
            "שם_רחוב": "המורן"
        },
        {
            "_id": 16944,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "המושבה"
        },
        {
            "_id": 16945,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 653,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 16946,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 586,
            "שם_רחוב": "המכללה"
        },
        {
            "_id": 16947,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 712,
            "שם_רחוב": "המכתשים"
        },
        {
            "_id": 16948,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 296,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 16949,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 519,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 16950,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 399,
            "שם_רחוב": "המקשר"
        },
        {
            "_id": 16951,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 532,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 16952,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 788,
            "שם_רחוב": "הנבל"
        },
        {
            "_id": 16953,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 526,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 16954,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 791,
            "שם_רחוב": "הנגינות"
        },
        {
            "_id": 16955,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 773,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 16956,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הנחלים"
        },
        {
            "_id": 16957,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 968,
            "שם_רחוב": "הנעמן"
        },
        {
            "_id": 16958,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 541,
            "שם_רחוב": "הנציב"
        },
        {
            "_id": 16959,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 884,
            "שם_רחוב": "הנקין"
        },
        {
            "_id": 16960,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 758,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 16961,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 606,
            "שם_רחוב": "הנשיא ויצמן"
        },
        {
            "_id": 16962,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 634,
            "שם_רחוב": "הנשר"
        },
        {
            "_id": 16963,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "הס משה"
        },
        {
            "_id": 16964,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 493,
            "שם_רחוב": "הסהרון"
        },
        {
            "_id": 16965,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 736,
            "שם_רחוב": "הסיגלית"
        },
        {
            "_id": 16966,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 871,
            "שם_רחוב": "הסלע האדום"
        },
        {
            "_id": 16967,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 728,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 16968,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 587,
            "שם_רחוב": "העגור"
        },
        {
            "_id": 16969,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 786,
            "שם_רחוב": "העוגב"
        },
        {
            "_id": 16970,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 514,
            "שם_רחוב": "העיר העתיקה"
        },
        {
            "_id": 16971,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 616,
            "שם_רחוב": "העליה הראשונה"
        },
        {
            "_id": 16972,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "העליה השניה"
        },
        {
            "_id": 16973,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 930,
            "שם_רחוב": "הענבר"
        },
        {
            "_id": 16974,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 804,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 16975,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 729,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 16976,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 854,
            "שם_רחוב": "הפיקוס"
        },
        {
            "_id": 16977,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 824,
            "שם_רחוב": "הפלג"
        },
        {
            "_id": 16978,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 811,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 16979,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 833,
            "שם_רחוב": "הפקאן"
        },
        {
            "_id": 16980,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 832,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 16981,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 842,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 16982,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 905,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 16983,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 566,
            "שם_רחוב": "הצוקים"
        },
        {
            "_id": 16984,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 678,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 16985,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 759,
            "שם_רחוב": "הצפורן"
        },
        {
            "_id": 16986,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 710,
            "שם_רחוב": "הצפצפה"
        },
        {
            "_id": 16987,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 836,
            "שם_רחוב": "הק"
        },
        {
            "_id": 16988,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 456,
            "שם_רחוב": "הקומונה החדרתית"
        },
        {
            "_id": 16989,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 527,
            "שם_רחוב": "הקונגרס"
        },
        {
            "_id": 16990,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 704,
            "שם_רחוב": "הקורמורן"
        },
        {
            "_id": 16991,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 825,
            "שם_רחוב": "הקידה"
        },
        {
            "_id": 16992,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 567,
            "שם_רחוב": "הקינמון"
        },
        {
            "_id": 16993,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 893,
            "שם_רחוב": "הקמה"
        },
        {
            "_id": 16994,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 965,
            "שם_רחוב": "הקנה"
        },
        {
            "_id": 16995,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 931,
            "שם_רחוב": "הר אביטל"
        },
        {
            "_id": 16996,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "הר בנטל"
        },
        {
            "_id": 16997,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 932,
            "שם_רחוב": "הר הנגב"
        },
        {
            "_id": 16998,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 947,
            "שם_רחוב": "הר חורשן"
        },
        {
            "_id": 16999,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 903,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 17000,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 815,
            "שם_רחוב": "הר סיני"
        },
        {
            "_id": 17001,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 667,
            "שם_רחוב": "הר צין"
        },
        {
            "_id": 17002,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 525,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 17003,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 654,
            "שם_רחוב": "הרב אבו חצירה"
        },
        {
            "_id": 17004,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 700,
            "שם_רחוב": "הרב אורנשטיין"
        },
        {
            "_id": 17005,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 554,
            "שם_רחוב": "הרב בוזגלו"
        },
        {
            "_id": 17006,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 512,
            "שם_רחוב": "הרב דוד משתה"
        },
        {
            "_id": 17007,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 625,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 17008,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 550,
            "שם_רחוב": "הרב טייב חי"
        },
        {
            "_id": 17009,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הרב מוהליבר"
        },
        {
            "_id": 17010,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "הרב מימון יהודה"
        },
        {
            "_id": 17011,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 552,
            "שם_רחוב": "הרב ניסים"
        },
        {
            "_id": 17012,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "הרב עובדיה יוסף"
        },
        {
            "_id": 17013,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 626,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 17014,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 17015,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 590,
            "שם_רחוב": "הרבי מילובביץ"
        },
        {
            "_id": 17016,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 602,
            "שם_רחוב": "הרברט סמואל"
        },
        {
            "_id": 17017,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 492,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 17018,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 575,
            "שם_רחוב": "הרי בשמים"
        },
        {
            "_id": 17019,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 806,
            "שם_רחוב": "הרימונים"
        },
        {
            "_id": 17020,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 461,
            "שם_רחוב": "הרמבן"
        },
        {
            "_id": 17021,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 601,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 17022,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 760,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 17023,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "הרשש"
        },
        {
            "_id": 17024,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 499,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 17025,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 809,
            "שם_רחוב": "השושנים"
        },
        {
            "_id": 17026,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 633,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 17027,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 826,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 17028,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 730,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 17029,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 632,
            "שם_רחוב": "השלדג"
        },
        {
            "_id": 17030,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 679,
            "שם_רחוב": "השלום"
        },
        {
            "_id": 17031,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 843,
            "שם_רחוב": "השלכת"
        },
        {
            "_id": 17032,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 790,
            "שם_רחוב": "השמינית"
        },
        {
            "_id": 17033,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 933,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 17034,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 731,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 17035,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 800,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 17036,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 535,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 17037,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 813,
            "שם_רחוב": "התאנים"
        },
        {
            "_id": 17038,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 1004,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 17039,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 761,
            "שם_רחוב": "התדהר"
        },
        {
            "_id": 17040,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 827,
            "שם_רחוב": "התות"
        },
        {
            "_id": 17041,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 680,
            "שם_רחוב": "התחיה"
        },
        {
            "_id": 17042,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 859,
            "שם_רחוב": "התמסח"
        },
        {
            "_id": 17043,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 805,
            "שם_רחוב": "התמרים"
        },
        {
            "_id": 17044,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 767,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 17045,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 939,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 17046,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 681,
            "שם_רחוב": "התקוה"
        },
        {
            "_id": 17047,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 878,
            "שם_רחוב": "וולפברג"
        },
        {
            "_id": 17048,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 741,
            "שם_רחוב": "וידרקר יצחק"
        },
        {
            "_id": 17049,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 887,
            "שם_רחוב": "וילנסקי"
        },
        {
            "_id": 17050,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "ויתקין"
        },
        {
            "_id": 17051,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 702,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 17052,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 889,
            "שם_רחוב": "זודקביץ דב"
        },
        {
            "_id": 17053,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 888,
            "שם_רחוב": "זולוטרוב"
        },
        {
            "_id": 17054,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 568,
            "שם_רחוב": "חבצלת השרון"
        },
        {
            "_id": 17055,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 780,
            "שם_רחוב": "חברת עזרא"
        },
        {
            "_id": 17056,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 569,
            "שם_רחוב": "חגוי הסלע"
        },
        {
            "_id": 17057,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חדרה"
        },
        {
            "_id": 17058,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6566,
            "שם_רחוב": "חדרה הירוקה"
        },
        {
            "_id": 17059,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "חדרה הצעירה"
        },
        {
            "_id": 17060,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6599,
            "שם_רחוב": "חדרה מזרח"
        },
        {
            "_id": 17061,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "חובבי ציון"
        },
        {
            "_id": 17062,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 570,
            "שם_רחוב": "חוט השני"
        },
        {
            "_id": 17063,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 682,
            "שם_רחוב": "חולתה"
        },
        {
            "_id": 17064,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 948,
            "שם_רחוב": "חומה ומגדל"
        },
        {
            "_id": 17065,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 436,
            "שם_רחוב": "חוף הכרמל"
        },
        {
            "_id": 17066,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "חורשת טל"
        },
        {
            "_id": 17067,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 920,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 17068,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 737,
            "שם_רחוב": "חזני מיכאל"
        },
        {
            "_id": 17069,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 623,
            "שם_רחוב": "חטיבת אלכסנדרוני"
        },
        {
            "_id": 17070,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 768,
            "שם_רחוב": "חטיבת גבעתי"
        },
        {
            "_id": 17071,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 821,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 17072,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 1005,
            "שם_רחוב": "חטיבת הנחל"
        },
        {
            "_id": 17073,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 769,
            "שם_רחוב": "חטיבת הראל"
        },
        {
            "_id": 17074,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "חטיבת כרמלי"
        },
        {
            "_id": 17075,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "חטיבת עודד"
        },
        {
            "_id": 17076,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "חטיבת עציוני"
        },
        {
            "_id": 17077,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 940,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 17078,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 867,
            "שם_רחוב": "חלוצה"
        },
        {
            "_id": 17079,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 873,
            "שם_רחוב": "חניתה"
        },
        {
            "_id": 17080,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "חנקין יהושע"
        },
        {
            "_id": 17081,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 812,
            "שם_רחוב": "חרובים"
        },
        {
            "_id": 17082,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 17083,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "טביב"
        },
        {
            "_id": 17084,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "טיומקין"
        },
        {
            "_id": 17085,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 689,
            "שם_רחוב": "טרבלוס"
        },
        {
            "_id": 17086,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 17087,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 465,
            "שם_רחוב": "טשרניחובסקי שאול"
        },
        {
            "_id": 17088,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "יבניאלי"
        },
        {
            "_id": 17089,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 801,
            "שם_רחוב": "יגאל"
        },
        {
            "_id": 17090,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 478,
            "שם_רחוב": "ידין יגאל"
        },
        {
            "_id": 17091,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 17092,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 538,
            "שם_רחוב": "יהודי פקיעין"
        },
        {
            "_id": 17093,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "יונת הסלע"
        },
        {
            "_id": 17094,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 556,
            "שם_רחוב": "יוסף בוסקילה"
        },
        {
            "_id": 17095,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 588,
            "שם_רחוב": "יחיעם"
        },
        {
            "_id": 17096,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 666,
            "שם_רחוב": "ים המלח"
        },
        {
            "_id": 17097,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 683,
            "שם_רחוב": "ים סוף"
        },
        {
            "_id": 17098,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "ינאי המלך"
        },
        {
            "_id": 17099,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 735,
            "שם_רחוב": "יעקב רבונה"
        },
        {
            "_id": 17100,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6568,
            "שם_רחוב": "יער חדרה"
        },
        {
            "_id": 17101,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 941,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 17102,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 694,
            "שם_רחוב": "יפת בן יוסף"
        },
        {
            "_id": 17103,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 808,
            "שם_רחוב": "יציאת אירופה"
        },
        {
            "_id": 17104,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 17105,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 551,
            "שם_רחוב": "ישי"
        },
        {
            "_id": 17106,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 692,
            "שם_רחוב": "ישיבת בית שמואל"
        },
        {
            "_id": 17107,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "ישעיהו"
        },
        {
            "_id": 17108,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 656,
            "שם_רחוב": "כח באייר"
        },
        {
            "_id": 17109,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 802,
            "שם_רחוב": "כט בנובמבר"
        },
        {
            "_id": 17110,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 418,
            "שם_רחוב": "כביש מס' 2"
        },
        {
            "_id": 17111,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 419,
            "שם_רחוב": "כביש מס' 4"
        },
        {
            "_id": 17112,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 412,
            "שם_רחוב": "כהן אוסקר"
        },
        {
            "_id": 17113,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר עמל"
        },
        {
            "_id": 17114,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 891,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 17115,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 899,
            "שם_רחוב": "כספי"
        },
        {
            "_id": 17116,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6601,
            "שם_רחוב": "כפר הים"
        },
        {
            "_id": 17117,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "כפר יוליאנה"
        },
        {
            "_id": 17118,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 421,
            "שם_רחוב": "כצנלסון ברל"
        },
        {
            "_id": 17119,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 831,
            "שם_רחוב": "לבוצקין"
        },
        {
            "_id": 17120,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 627,
            "שם_רחוב": "לבזובסקי"
        },
        {
            "_id": 17121,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 857,
            "שם_רחוב": "לוברסקי"
        },
        {
            "_id": 17122,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 886,
            "שם_רחוב": "לויטס"
        },
        {
            "_id": 17123,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 657,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 17124,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 852,
            "שם_רחוב": "ליובין"
        },
        {
            "_id": 17125,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "לילינבלום"
        },
        {
            "_id": 17126,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 571,
            "שם_רחוב": "לכה דודי"
        },
        {
            "_id": 17127,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 17128,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 477,
            "שם_רחוב": "לסקוב חיים"
        },
        {
            "_id": 17129,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 414,
            "שם_רחוב": "לרמן"
        },
        {
            "_id": 17130,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 464,
            "שם_רחוב": "מאפו אברהם"
        },
        {
            "_id": 17131,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 542,
            "שם_רחוב": "מבצע דני"
        },
        {
            "_id": 17132,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 658,
            "שם_רחוב": "מבצע חורב"
        },
        {
            "_id": 17133,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 543,
            "שם_רחוב": "מבצע חירם"
        },
        {
            "_id": 17134,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 544,
            "שם_רחוב": "מבצע יואב"
        },
        {
            "_id": 17135,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 659,
            "שם_רחוב": "מבצע יונתן"
        },
        {
            "_id": 17136,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 545,
            "שם_רחוב": "מבצע נחשון"
        },
        {
            "_id": 17137,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 546,
            "שם_רחוב": "מבצע עובדה"
        },
        {
            "_id": 17138,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 522,
            "שם_רחוב": "מבצע עזרא"
        },
        {
            "_id": 17139,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 660,
            "שם_רחוב": "מבצע עשר המכות"
        },
        {
            "_id": 17140,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 529,
            "שם_רחוב": "מבצע קדש"
        },
        {
            "_id": 17141,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מדורסקי"
        },
        {
            "_id": 17142,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 969,
            "שם_רחוב": "מדעים ויהדות"
        },
        {
            "_id": 17143,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "מהרל"
        },
        {
            "_id": 17144,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "מהריץ"
        },
        {
            "_id": 17145,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 416,
            "שם_רחוב": "מוסד תלפיות"
        },
        {
            "_id": 17146,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 810,
            "שם_רחוב": "מורדי הגטאות"
        },
        {
            "_id": 17147,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "מייזר יוסף"
        },
        {
            "_id": 17148,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 883,
            "שם_רחוב": "מילנר"
        },
        {
            "_id": 17149,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 628,
            "שם_רחוב": "מלחמת יום הכפורים"
        },
        {
            "_id": 17150,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 607,
            "שם_רחוב": "מלחמת ששת הימים"
        },
        {
            "_id": 17151,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 523,
            "שם_רחוב": "מלכי ישראל"
        },
        {
            "_id": 17152,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 863,
            "שם_רחוב": "ממשית"
        },
        {
            "_id": 17153,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "מנאי"
        },
        {
            "_id": 17154,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 684,
            "שם_רחוב": "מנרה"
        },
        {
            "_id": 17155,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6534,
            "שם_רחוב": "מעברת ברנדיס"
        },
        {
            "_id": 17156,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 647,
            "שם_רחוב": "מצפה ים"
        },
        {
            "_id": 17157,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 720,
            "שם_רחוב": "מקוה ישראל"
        },
        {
            "_id": 17158,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 901,
            "שם_רחוב": "מקלף מרדכי"
        },
        {
            "_id": 17159,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 661,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 17160,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 844,
            "שם_רחוב": "מרגלית"
        },
        {
            "_id": 17161,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 537,
            "שם_רחוב": "מרדכי קוסטליץ"
        },
        {
            "_id": 17162,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 970,
            "שם_רחוב": "מרכז הקליטה"
        },
        {
            "_id": 17163,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 685,
            "שם_רחוב": "משאבי שדה"
        },
        {
            "_id": 17164,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 942,
            "שם_רחוב": "משגב עם"
        },
        {
            "_id": 17165,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 528,
            "שם_רחוב": "משה פסטרנק"
        },
        {
            "_id": 17166,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 972,
            "שם_רחוב": "משמר הגבול"
        },
        {
            "_id": 17167,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 442,
            "שם_רחוב": "משמר הים"
        },
        {
            "_id": 17168,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "משק הפועלות"
        },
        {
            "_id": 17169,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6580,
            "שם_רחוב": "מתחם העירייה"
        },
        {
            "_id": 17170,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 1006,
            "שם_רחוב": "נאות הכיכר"
        },
        {
            "_id": 17171,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6595,
            "שם_רחוב": "נאות רם"
        },
        {
            "_id": 17172,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6600,
            "שם_רחוב": "נאות שקד"
        },
        {
            "_id": 17173,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 772,
            "שם_רחוב": "נהלל"
        },
        {
            "_id": 17174,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 839,
            "שם_רחוב": "נהרונית"
        },
        {
            "_id": 17175,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 966,
            "שם_רחוב": "נוב"
        },
        {
            "_id": 17176,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6559,
            "שם_רחוב": "נוה ברנדיס"
        },
        {
            "_id": 17177,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 428,
            "שם_רחוב": "נוה כפרי"
        },
        {
            "_id": 17178,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "נווה הדרים"
        },
        {
            "_id": 17179,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 814,
            "שם_רחוב": "נווה חורש"
        },
        {
            "_id": 17180,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 648,
            "שם_רחוב": "נווה ים"
        },
        {
            "_id": 17181,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 708,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 17182,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 851,
            "שם_רחוב": "נחומובסקי"
        },
        {
            "_id": 17183,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 865,
            "שם_רחוב": "נחל כזיב"
        },
        {
            "_id": 17184,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 943,
            "שם_רחוב": "נחל פולג"
        },
        {
            "_id": 17185,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 860,
            "שם_רחוב": "נחל פרת"
        },
        {
            "_id": 17186,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 861,
            "שם_רחוב": "נחל צין"
        },
        {
            "_id": 17187,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "נחל רמון"
        },
        {
            "_id": 17188,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "נחל שורק"
        },
        {
            "_id": 17189,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "נחשונים"
        },
        {
            "_id": 17190,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 429,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 17191,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 718,
            "שם_רחוב": "ניצנה"
        },
        {
            "_id": 17192,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 864,
            "שם_רחוב": "ניצנים"
        },
        {
            "_id": 17193,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 686,
            "שם_רחוב": "נערן"
        },
        {
            "_id": 17194,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 862,
            "שם_רחוב": "נתיבות"
        },
        {
            "_id": 17195,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 881,
            "שם_רחוב": "סגל"
        },
        {
            "_id": 17196,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "סולד הנרייטה"
        },
        {
            "_id": 17197,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 895,
            "שם_רחוב": "סוסקין"
        },
        {
            "_id": 17198,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 885,
            "שם_רחוב": "סלוצקין"
        },
        {
            "_id": 17199,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 618,
            "שם_רחוב": "סמ החאן"
        },
        {
            "_id": 17200,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "סמ הרב רוקח"
        },
        {
            "_id": 17201,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 743,
            "שם_רחוב": "סמ זבוטינסקי"
        },
        {
            "_id": 17202,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 622,
            "שם_רחוב": "סמ יהודית"
        },
        {
            "_id": 17203,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 614,
            "שם_רחוב": "סמ יובל"
        },
        {
            "_id": 17204,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "סמ לוי שושנה"
        },
        {
            "_id": 17205,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 621,
            "שם_רחוב": "סמ מיכאל"
        },
        {
            "_id": 17206,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 572,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 17207,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 549,
            "שם_רחוב": "סמולניק"
        },
        {
            "_id": 17208,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 721,
            "שם_רחוב": "סמילנסקי"
        },
        {
            "_id": 17209,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 751,
            "שם_רחוב": "סמסונוב"
        },
        {
            "_id": 17210,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 953,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 17211,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 727,
            "שם_רחוב": "ספיר יוסף"
        },
        {
            "_id": 17212,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 463,
            "שם_רחוב": "עגנון"
        },
        {
            "_id": 17213,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 841,
            "שם_רחוב": "עובדיה בן שלום"
        },
        {
            "_id": 17214,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 662,
            "שם_רחוב": "עולי הגרדום"
        },
        {
            "_id": 17215,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 869,
            "שם_רחוב": "עין ארובות"
        },
        {
            "_id": 17216,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "עין בוקק"
        },
        {
            "_id": 17217,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 868,
            "שם_רחוב": "עין גב"
        },
        {
            "_id": 17218,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 573,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 17219,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 646,
            "שם_רחוב": "עין גנים"
        },
        {
            "_id": 17220,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 691,
            "שם_רחוב": "עין הדבש"
        },
        {
            "_id": 17221,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6602,
            "שם_רחוב": "עין הים"
        },
        {
            "_id": 17222,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 816,
            "שם_רחוב": "עין זיוון"
        },
        {
            "_id": 17223,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "עין עבדת"
        },
        {
            "_id": 17224,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "עינב לוי"
        },
        {
            "_id": 17225,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 828,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 17226,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "על כנפי נשרים"
        },
        {
            "_id": 17227,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 665,
            "שם_רחוב": "עלית הנוער"
        },
        {
            "_id": 17228,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 435,
            "שם_רחוב": "עמק יזרעאל"
        },
        {
            "_id": 17229,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "עמרם אברהם"
        },
        {
            "_id": 17230,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 829,
            "שם_רחוב": "עצמון"
        },
        {
            "_id": 17231,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 617,
            "שם_רחוב": "ער אציל"
        },
        {
            "_id": 17232,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 574,
            "שם_רחוב": "ערוגות הבושם"
        },
        {
            "_id": 17233,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 733,
            "שם_רחוב": "פיינברג"
        },
        {
            "_id": 17234,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 900,
            "שם_רחוב": "פייקרש"
        },
        {
            "_id": 17235,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 620,
            "שם_רחוב": "פינסקר"
        },
        {
            "_id": 17236,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "פיקא"
        },
        {
            "_id": 17237,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 457,
            "שם_רחוב": "פלד אשר"
        },
        {
            "_id": 17238,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 608,
            "שם_רחוב": "פלטרין"
        },
        {
            "_id": 17239,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 983,
            "שם_רחוב": "פרופ' אברהם הרשקו"
        },
        {
            "_id": 17240,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 981,
            "שם_רחוב": "פרופ' אהרן צ'חנובר"
        },
        {
            "_id": 17241,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 982,
            "שם_רחוב": "פרופ' דן שכטמן"
        },
        {
            "_id": 17242,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 980,
            "שם_רחוב": "פרופ' משה אדד"
        },
        {
            "_id": 17243,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "פרידלנדר"
        },
        {
            "_id": 17244,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 580,
            "שם_רחוב": "פרנק"
        },
        {
            "_id": 17245,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 774,
            "שם_רחוב": "פרנקל יצחק"
        },
        {
            "_id": 17246,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 462,
            "שם_רחוב": "פרץ"
        },
        {
            "_id": 17247,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 944,
            "שם_רחוב": "צאלים"
        },
        {
            "_id": 17248,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 838,
            "שם_רחוב": "צבר"
        },
        {
            "_id": 17249,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 17250,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 430,
            "שם_רחוב": "צידקוב"
        },
        {
            "_id": 17251,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "ציפורי"
        },
        {
            "_id": 17252,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "צעירי שלום"
        },
        {
            "_id": 17253,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 497,
            "שם_רחוב": "צפרה"
        },
        {
            "_id": 17254,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 945,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 17255,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "קארו יוסף"
        },
        {
            "_id": 17256,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 807,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 17257,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 649,
            "שם_רחוב": "קדימה"
        },
        {
            "_id": 17258,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "קוטלר"
        },
        {
            "_id": 17259,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "קומבה"
        },
        {
            "_id": 17260,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6596,
            "שם_רחוב": "קידמת ים"
        },
        {
            "_id": 17261,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 547,
            "שם_רחוב": "קלז"
        },
        {
            "_id": 17262,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "קנטרזי"
        },
        {
            "_id": 17263,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 1011,
            "שם_רחוב": "קניון לב חדרה"
        },
        {
            "_id": 17264,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "קסטל"
        },
        {
            "_id": 17265,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 515,
            "שם_רחוב": "קרן קיימת לישראל"
        },
        {
            "_id": 17266,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 845,
            "שם_רחוב": "קשת"
        },
        {
            "_id": 17267,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "ראובן הכהן"
        },
        {
            "_id": 17268,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 440,
            "שם_רחוב": "ראש הנקרה"
        },
        {
            "_id": 17269,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 950,
            "שם_רחוב": "רבין יצחק"
        },
        {
            "_id": 17270,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 858,
            "שם_רחוב": "רבינוביץ"
        },
        {
            "_id": 17271,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 894,
            "שם_רחוב": "רודין"
        },
        {
            "_id": 17272,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "רוטמן"
        },
        {
            "_id": 17273,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "רון"
        },
        {
            "_id": 17274,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 902,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 17275,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "רחש"
        },
        {
            "_id": 17276,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 874,
            "שם_רחוב": "ריחן"
        },
        {
            "_id": 17277,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 771,
            "שם_רחוב": "רם"
        },
        {
            "_id": 17278,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 604,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 17279,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 455,
            "שם_רחוב": "רמחל"
        },
        {
            "_id": 17280,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 433,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 17281,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 663,
            "שם_רחוב": "רשבם"
        },
        {
            "_id": 17282,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6581,
            "שם_רחוב": "ש אזורים"
        },
        {
            "_id": 17283,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6535,
            "שם_רחוב": "ש אזרחי"
        },
        {
            "_id": 17284,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6570,
            "שם_רחוב": "ש אלי כהן"
        },
        {
            "_id": 17285,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש בוטקובסקי"
        },
        {
            "_id": 17286,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש בית אליעזר"
        },
        {
            "_id": 17287,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש בני עקיבא"
        },
        {
            "_id": 17288,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6582,
            "שם_רחוב": "ש האמוראים"
        },
        {
            "_id": 17289,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש הזיתים"
        },
        {
            "_id": 17290,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "ש ותיק רח ה"
        },
        {
            "_id": 17291,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6537,
            "שם_רחוב": "ש ותיק רח ו"
        },
        {
            "_id": 17292,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6538,
            "שם_רחוב": "ש ותיק רח ז"
        },
        {
            "_id": 17293,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש חדש"
        },
        {
            "_id": 17294,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6583,
            "שם_רחוב": "ש יד שמואל"
        },
        {
            "_id": 17295,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6539,
            "שם_רחוב": "ש יוספטל"
        },
        {
            "_id": 17296,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש לוינסון"
        },
        {
            "_id": 17297,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "ש מאוריציוס"
        },
        {
            "_id": 17298,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "ש נוה צבי"
        },
        {
            "_id": 17299,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "ש סלע"
        },
        {
            "_id": 17300,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6542,
            "שם_רחוב": "ש עובדים"
        },
        {
            "_id": 17301,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6584,
            "שם_רחוב": "ש עולי סין"
        },
        {
            "_id": 17302,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6543,
            "שם_רחוב": "ש עולים חדש"
        },
        {
            "_id": 17303,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6544,
            "שם_רחוב": "ש עמל א"
        },
        {
            "_id": 17304,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6545,
            "שם_רחוב": "ש עמל ב"
        },
        {
            "_id": 17305,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6546,
            "שם_רחוב": "ש פאר"
        },
        {
            "_id": 17306,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6563,
            "שם_רחוב": "ש רמבם"
        },
        {
            "_id": 17307,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6564,
            "שם_רחוב": "ש רמבם חדש"
        },
        {
            "_id": 17308,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "ש רסקו"
        },
        {
            "_id": 17309,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "שאר ישוב"
        },
        {
            "_id": 17310,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "שבזי שלום"
        },
        {
            "_id": 17311,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 524,
            "שם_רחוב": "שבטי ישראל"
        },
        {
            "_id": 17312,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 856,
            "שם_רחוב": "שגרגרוצקי"
        },
        {
            "_id": 17313,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 971,
            "שם_רחוב": "שד אלכסנדר"
        },
        {
            "_id": 17314,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 644,
            "שם_רחוב": "שד בגין מנחם"
        },
        {
            "_id": 17315,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 705,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 17316,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 911,
            "שם_רחוב": "שד האביב"
        },
        {
            "_id": 17317,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 536,
            "שם_רחוב": "שד מינסוטה"
        },
        {
            "_id": 17318,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 611,
            "שם_רחוב": "שד רוטשילד"
        },
        {
            "_id": 17319,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 690,
            "שם_רחוב": "שד רחבעם זאבי"
        },
        {
            "_id": 17320,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 946,
            "שם_רחוב": "שדה בוקר"
        },
        {
            "_id": 17321,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 458,
            "שם_רחוב": "שדה יצחק"
        },
        {
            "_id": 17322,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "שדרות כושי עפגין"
        },
        {
            "_id": 17323,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 1007,
            "שם_רחוב": "שומרי החומות"
        },
        {
            "_id": 17324,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 548,
            "שם_רחוב": "שועלי שמשון"
        },
        {
            "_id": 17325,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 1008,
            "שם_רחוב": "שושנת העמקים"
        },
        {
            "_id": 17326,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 742,
            "שם_רחוב": "שזר זלמן"
        },
        {
            "_id": 17327,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 664,
            "שם_רחוב": "שחל"
        },
        {
            "_id": 17328,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 853,
            "שם_רחוב": "שטרנין"
        },
        {
            "_id": 17329,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 711,
            "שם_רחוב": "שיבטה"
        },
        {
            "_id": 17330,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 518,
            "שם_רחוב": "שיבת ציון"
        },
        {
            "_id": 17331,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6585,
            "שם_רחוב": "שכ אגרובנק"
        },
        {
            "_id": 17332,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6586,
            "שם_רחוב": "שכ אולגה א"
        },
        {
            "_id": 17333,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6587,
            "שם_רחוב": "שכ אולגה ב"
        },
        {
            "_id": 17334,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6588,
            "שם_רחוב": "שכ אולגה ג"
        },
        {
            "_id": 17335,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6589,
            "שם_רחוב": "שכ אולגה ד"
        },
        {
            "_id": 17336,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6590,
            "שם_רחוב": "שכ אולגה צריפים"
        },
        {
            "_id": 17337,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6547,
            "שם_רחוב": "שכ אלרם"
        },
        {
            "_id": 17338,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6591,
            "שם_רחוב": "שכ אסבסט אולגה"
        },
        {
            "_id": 17339,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6548,
            "שם_רחוב": "שכ אפרים"
        },
        {
            "_id": 17340,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6549,
            "שם_רחוב": "שכ ביאליק"
        },
        {
            "_id": 17341,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6592,
            "שם_רחוב": "שכ בן גוריון"
        },
        {
            "_id": 17342,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "שכ ברנדיס"
        },
        {
            "_id": 17343,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ גני אלון"
        },
        {
            "_id": 17344,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6551,
            "שם_רחוב": "שכ האוצר"
        },
        {
            "_id": 17345,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6552,
            "שם_רחוב": "שכ הגורן"
        },
        {
            "_id": 17346,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ הפועל המזרחי"
        },
        {
            "_id": 17347,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6593,
            "שם_רחוב": "שכ ויצמן"
        },
        {
            "_id": 17348,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6603,
            "שם_רחוב": "שכ חופים"
        },
        {
            "_id": 17349,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ חפצי בה"
        },
        {
            "_id": 17350,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "שכ יצחק"
        },
        {
            "_id": 17351,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6575,
            "שם_רחוב": "שכ מרכז"
        },
        {
            "_id": 17352,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ נוה אליעזר"
        },
        {
            "_id": 17353,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "שכ נוה חיים"
        },
        {
            "_id": 17354,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6553,
            "שם_רחוב": "שכ נוה עובד"
        },
        {
            "_id": 17355,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6554,
            "שם_רחוב": "שכ נוה עובד א"
        },
        {
            "_id": 17356,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6555,
            "שם_רחוב": "שכ נוה עובד ב"
        },
        {
            "_id": 17357,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6556,
            "שם_רחוב": "שכ נוה עובד ג"
        },
        {
            "_id": 17358,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6604,
            "שם_רחוב": "שכ נווה חורש"
        },
        {
            "_id": 17359,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6557,
            "שם_רחוב": "שכ נחליאל"
        },
        {
            "_id": 17360,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6565,
            "שם_רחוב": "שכ ניסן"
        },
        {
            "_id": 17361,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "שכ שלום"
        },
        {
            "_id": 17362,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "שכ שלמה"
        },
        {
            "_id": 17363,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6558,
            "שם_רחוב": "שכ שמשון"
        },
        {
            "_id": 17364,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6594,
            "שם_רחוב": "שכון גזית"
        },
        {
            "_id": 17365,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "שכון יוגוסלבי"
        },
        {
            "_id": 17366,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "שכונת הפארק"
        },
        {
            "_id": 17367,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 555,
            "שם_רחוב": "שלהבית"
        },
        {
            "_id": 17368,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 818,
            "שם_רחוב": "שלמה"
        },
        {
            "_id": 17369,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 949,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 17370,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "שמואלזון"
        },
        {
            "_id": 17371,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 630,
            "שם_רחוב": "שמידט"
        },
        {
            "_id": 17372,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 500,
            "שם_רחוב": "שמעון פרס"
        },
        {
            "_id": 17373,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 422,
            "שם_רחוב": "שמעוני דוד"
        },
        {
            "_id": 17374,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "שניאורסון"
        },
        {
            "_id": 17375,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 17376,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 876,
            "שם_רחוב": "שפאק"
        },
        {
            "_id": 17377,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 631,
            "שם_רחוב": "שפירא"
        },
        {
            "_id": 17378,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 645,
            "שם_רחוב": "שפירא חיים משה"
        },
        {
            "_id": 17379,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "שפרינצק יוסף"
        },
        {
            "_id": 17380,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 866,
            "שם_רחוב": "שקמונה"
        },
        {
            "_id": 17381,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 877,
            "שם_רחוב": "שקצר"
        },
        {
            "_id": 17382,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 781,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 17383,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 420,
            "שם_רחוב": "תחנת הכח"
        },
        {
            "_id": 17384,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "תחנת הרכבת מזרח"
        },
        {
            "_id": 17385,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 687,
            "שם_רחוב": "תל דור"
        },
        {
            "_id": 17386,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 688,
            "שם_רחוב": "תל דן"
        },
        {
            "_id": 17387,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 870,
            "שם_רחוב": "תל חצור"
        },
        {
            "_id": 17388,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "תמנע"
        },
        {
            "_id": 17389,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 530,
            "שם_רחוב": "תנאים"
        },
        {
            "_id": 17390,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "תעשייה"
        },
        {
            "_id": 17391,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "תרמב"
        },
        {
            "_id": 17392,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 612,
            "שם_רחוב": "תרנא"
        },
        {
            "_id": 17393,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "תרעב"
        },
        {
            "_id": 17394,
            "סמל_ישוב": 6500,
            "שם_ישוב": "חדרה ",
            "סמל_רחוב": 441,
            "שם_רחוב": "תרצה"
        },
        {
            "_id": 17395,
            "סמל_ישוב": 948,
            "שם_ישוב": "חוג'ייראת (ד'הרה) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חוג'ייראת (ד'הרה)"
        },
        {
            "_id": 17396,
            "סמל_ישוב": 160,
            "שם_ישוב": "חולדה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חולדה"
        },
        {
            "_id": 17397,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 552,
            "שם_רחוב": "אביבים"
        },
        {
            "_id": 17398,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 981,
            "שם_רחוב": "אבידן שמעון"
        },
        {
            "_id": 17399,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 534,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 17400,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 726,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 17401,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אבנר"
        },
        {
            "_id": 17402,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 742,
            "שם_רחוב": "אברבנאל"
        },
        {
            "_id": 17403,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 231,
            "שם_רחוב": "אבשלום"
        },
        {
            "_id": 17404,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 774,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 17405,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 841,
            "שם_רחוב": "אדם יקותיאל"
        },
        {
            "_id": 17406,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אהוד"
        },
        {
            "_id": 17407,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 554,
            "שם_רחוב": "אהל שרה"
        },
        {
            "_id": 17408,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 901,
            "שם_רחוב": "אהרונוביץ"
        },
        {
            "_id": 17409,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 419,
            "שם_רחוב": "אוסישקין"
        },
        {
            "_id": 17410,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 933,
            "שם_רחוב": "אופיר"
        },
        {
            "_id": 17411,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אורי אלמוג"
        },
        {
            "_id": 17412,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 744,
            "שם_רחוב": "אורים"
        },
        {
            "_id": 17413,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 421,
            "שם_רחוב": "אזר"
        },
        {
            "_id": 17414,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "אזור תעשיה ב"
        },
        {
            "_id": 17415,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "אזור תעשייה א"
        },
        {
            "_id": 17416,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "אזור תעשייה רסקו ב"
        },
        {
            "_id": 17417,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 828,
            "שם_רחוב": "אחי דקר"
        },
        {
            "_id": 17418,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 712,
            "שם_רחוב": "אחד במאי"
        },
        {
            "_id": 17419,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 402,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 17420,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 875,
            "שם_רחוב": "אילון פנחס"
        },
        {
            "_id": 17421,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 911,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 17422,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אימבר"
        },
        {
            "_id": 17423,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 609,
            "שם_רחוב": "איריס"
        },
        {
            "_id": 17424,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 941,
            "שם_רחוב": "אלופי צהל"
        },
        {
            "_id": 17425,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 535,
            "שם_רחוב": "אלחריזי"
        },
        {
            "_id": 17426,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 753,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 17427,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1107,
            "שם_רחוב": "אליהו הנביא"
        },
        {
            "_id": 17428,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אלישע"
        },
        {
            "_id": 17429,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 324,
            "שם_רחוב": "אלעזר דוד"
        },
        {
            "_id": 17430,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 541,
            "שם_רחוב": "אלקלעי"
        },
        {
            "_id": 17431,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 550,
            "שם_רחוב": "אלרואי"
        },
        {
            "_id": 17432,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 916,
            "שם_רחוב": "אנילביץ"
        },
        {
            "_id": 17433,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 370,
            "שם_רחוב": "אסירי ציון"
        },
        {
            "_id": 17434,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 914,
            "שם_רחוב": "אפעל"
        },
        {
            "_id": 17435,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 603,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 17436,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 811,
            "שם_רחוב": "ארבע ארצות"
        },
        {
            "_id": 17437,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 765,
            "שם_רחוב": "ארגוב מאיר"
        },
        {
            "_id": 17438,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אריק אינשטיין"
        },
        {
            "_id": 17439,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1030,
            "שם_רחוב": "אריק לביא"
        },
        {
            "_id": 17440,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 708,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 17441,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 792,
            "שם_רחוב": "ארליך שמחה"
        },
        {
            "_id": 17442,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 761,
            "שם_רחוב": "ארן זלמן"
        },
        {
            "_id": 17443,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אשתאול"
        },
        {
            "_id": 17444,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 952,
            "שם_רחוב": "בובר מרטין"
        },
        {
            "_id": 17445,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 701,
            "שם_רחוב": "בוסל"
        },
        {
            "_id": 17446,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 925,
            "שם_רחוב": "בוסתנאי"
        },
        {
            "_id": 17447,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 909,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 17448,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 510,
            "שם_רחוב": "בזל"
        },
        {
            "_id": 17449,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 638,
            "שם_רחוב": "ביח וולפסון"
        },
        {
            "_id": 17450,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 503,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 17451,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 409,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 17452,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 721,
            "שם_רחוב": "בילינסון"
        },
        {
            "_id": 17453,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 948,
            "שם_רחוב": "בית לחם"
        },
        {
            "_id": 17454,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 320,
            "שם_רחוב": "בית שערים"
        },
        {
            "_id": 17455,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 213,
            "שם_רחוב": "בלבן"
        },
        {
            "_id": 17456,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 720,
            "שם_רחוב": "בלום לאון"
        },
        {
            "_id": 17457,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 758,
            "שם_רחוב": "בן אליעזר אריה"
        },
        {
            "_id": 17458,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 282,
            "שם_רחוב": "בן הלל"
        },
        {
            "_id": 17459,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 423,
            "שם_רחוב": "בן יהודה"
        },
        {
            "_id": 17460,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 279,
            "שם_רחוב": "בן עמרם"
        },
        {
            "_id": 17461,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 327,
            "שם_רחוב": "בן-נון יוחאי"
        },
        {
            "_id": 17462,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 364,
            "שם_רחוב": "בני משה"
        },
        {
            "_id": 17463,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 234,
            "שם_רחוב": "בעל מחשבות"
        },
        {
            "_id": 17464,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 741,
            "שם_רחוב": "בעל שם טוב"
        },
        {
            "_id": 17465,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 306,
            "שם_רחוב": "בצלאל"
        },
        {
            "_id": 17466,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 954,
            "שם_רחוב": "בר אילן"
        },
        {
            "_id": 17467,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 303,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 17468,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 705,
            "שם_רחוב": "ברדיצ'בסקי"
        },
        {
            "_id": 17469,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 257,
            "שם_רחוב": "ברודרזון"
        },
        {
            "_id": 17470,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 545,
            "שם_רחוב": "ברזביל"
        },
        {
            "_id": 17471,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 762,
            "שם_רחוב": "ברזילי ישראל"
        },
        {
            "_id": 17472,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1026,
            "שם_רחוב": "ברטונוב דבורה"
        },
        {
            "_id": 17473,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 986,
            "שם_רחוב": "ברלב חיים"
        },
        {
            "_id": 17474,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 305,
            "שם_רחוב": "ברנדיס"
        },
        {
            "_id": 17475,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 522,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 17476,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 763,
            "שם_רחוב": "ברנשטיין פרץ"
        },
        {
            "_id": 17477,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 759,
            "שם_רחוב": "ברץ יוסף"
        },
        {
            "_id": 17478,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 235,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 17479,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 356,
            "שם_רחוב": "ברקאי מיכאל"
        },
        {
            "_id": 17480,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 610,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 17481,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 970,
            "שם_רחוב": "ברקת ראובן"
        },
        {
            "_id": 17482,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 317,
            "שם_רחוב": "גאולים"
        },
        {
            "_id": 17483,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 635,
            "שם_רחוב": "גבעה 69"
        },
        {
            "_id": 17484,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 827,
            "שם_רחוב": "גבעת התחמושת"
        },
        {
            "_id": 17485,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6546,
            "שם_רחוב": "גבעת תל גבורים"
        },
        {
            "_id": 17486,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 632,
            "שם_רחוב": "גבעתי"
        },
        {
            "_id": 17487,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 228,
            "שם_רחוב": "גדעון"
        },
        {
            "_id": 17488,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 969,
            "שם_רחוב": "גולדה מאיר"
        },
        {
            "_id": 17489,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 964,
            "שם_רחוב": "גולדשטיין פרץ"
        },
        {
            "_id": 17490,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 732,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 17491,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 910,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 17492,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 722,
            "שם_רחוב": "גוש חלב"
        },
        {
            "_id": 17493,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 633,
            "שם_רחוב": "גוש עציון"
        },
        {
            "_id": 17494,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 352,
            "שם_רחוב": "גלילי ישראל"
        },
        {
            "_id": 17495,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 997,
            "שם_רחוב": "גלעדי אהרון"
        },
        {
            "_id": 17496,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 915,
            "שם_רחוב": "גנסין"
        },
        {
            "_id": 17497,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 755,
            "שם_רחוב": "גרינבוים"
        },
        {
            "_id": 17498,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 798,
            "שם_רחוב": "גרינברג אורי צבי"
        },
        {
            "_id": 17499,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 216,
            "שם_רחוב": "גרץ צבי"
        },
        {
            "_id": 17500,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 752,
            "שם_רחוב": "דר כהן היינץ"
        },
        {
            "_id": 17501,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 118,
            "שם_רחוב": "דר פליקס זנדמן"
        },
        {
            "_id": 17502,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 210,
            "שם_רחוב": "דר קפלינסקי נח"
        },
        {
            "_id": 17503,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 207,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 17504,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 212,
            "שם_רחוב": "דבורה עומר"
        },
        {
            "_id": 17505,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 735,
            "שם_רחוב": "דגניה"
        },
        {
            "_id": 17506,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 958,
            "שם_רחוב": "דה שליט עמוס"
        },
        {
            "_id": 17507,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 211,
            "שם_רחוב": "דובנוב"
        },
        {
            "_id": 17508,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 836,
            "שם_רחוב": "דולפין"
        },
        {
            "_id": 17509,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 974,
            "שם_רחוב": "דורי יעקב"
        },
        {
            "_id": 17510,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 796,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 17511,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 626,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 17512,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1020,
            "שם_רחוב": "דמארי שושנה"
        },
        {
            "_id": 17513,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 738,
            "שם_רחוב": "דן שומרון"
        },
        {
            "_id": 17514,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 236,
            "שם_רחוב": "דניאל"
        },
        {
            "_id": 17515,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 998,
            "שם_רחוב": "דרורי אמיר"
        },
        {
            "_id": 17516,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 785,
            "שם_רחוב": "דרך אלון יגאל"
        },
        {
            "_id": 17517,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 831,
            "שם_רחוב": "דרך בית העלמין"
        },
        {
            "_id": 17518,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 150,
            "שם_רחוב": "דרך הביטחון"
        },
        {
            "_id": 17519,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך השבעה"
        },
        {
            "_id": 17520,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 436,
            "שם_רחוב": "דרך עזה"
        },
        {
            "_id": 17521,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 515,
            "שם_רחוב": "ה' באייר"
        },
        {
            "_id": 17522,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 729,
            "שם_רחוב": "האבות"
        },
        {
            "_id": 17523,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האורגים"
        },
        {
            "_id": 17524,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 924,
            "שם_רחוב": "האחוה"
        },
        {
            "_id": 17525,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 128,
            "שם_רחוב": "האיזמל"
        },
        {
            "_id": 17526,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 994,
            "שם_רחוב": "האלוף אהרון יריב"
        },
        {
            "_id": 17527,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 372,
            "שם_רחוב": "האלוף תמרי נחמיה"
        },
        {
            "_id": 17528,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 806,
            "שם_רחוב": "האמוראים"
        },
        {
            "_id": 17529,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 238,
            "שם_רחוב": "האנקור"
        },
        {
            "_id": 17530,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 537,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 17531,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 347,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 17532,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 340,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 17533,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 291,
            "שם_רחוב": "הבוכנות"
        },
        {
            "_id": 17534,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הבנאי"
        },
        {
            "_id": 17535,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 616,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 17536,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 824,
            "שם_רחוב": "הבקעה"
        },
        {
            "_id": 17537,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 239,
            "שם_רחוב": "הברבור"
        },
        {
            "_id": 17538,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 341,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 17539,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 809,
            "שם_רחוב": "הגאונים"
        },
        {
            "_id": 17540,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הגביש"
        },
        {
            "_id": 17541,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 611,
            "שם_רחוב": "הגבעול"
        },
        {
            "_id": 17542,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 428,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 17543,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 366,
            "שם_רחוב": "הגדנעים"
        },
        {
            "_id": 17544,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 507,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 17545,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 502,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 17546,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 501,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 17547,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 318,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 17548,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 743,
            "שם_רחוב": "הגרא"
        },
        {
            "_id": 17549,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 652,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 17550,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 241,
            "שם_רחוב": "הדייה"
        },
        {
            "_id": 17551,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1005,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 17552,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 936,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 17553,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 613,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 17554,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 242,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 17555,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 431,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 17556,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 304,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 17557,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 922,
            "שם_רחוב": "הוגו ארנסט"
        },
        {
            "_id": 17558,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 930,
            "שם_רחוב": "הופיין"
        },
        {
            "_id": 17559,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הזורע"
        },
        {
            "_id": 17560,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 654,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 17561,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 243,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 17562,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 604,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 17563,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 244,
            "שם_רחוב": "החגלה"
        },
        {
            "_id": 17564,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 245,
            "שם_רחוב": "החוחית"
        },
        {
            "_id": 17565,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "החופר"
        },
        {
            "_id": 17566,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 129,
            "שם_רחוב": "החורטים"
        },
        {
            "_id": 17567,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 904,
            "שם_רחוב": "החיל"
        },
        {
            "_id": 17568,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 627,
            "שם_רחוב": "החים"
        },
        {
            "_id": 17569,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 628,
            "שם_רחוב": "החיש"
        },
        {
            "_id": 17570,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 650,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 17571,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 905,
            "שם_רחוב": "החלוץ"
        },
        {
            "_id": 17572,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 815,
            "שם_רחוב": "החמניה"
        },
        {
            "_id": 17573,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 246,
            "שם_רחוב": "החמריה"
        },
        {
            "_id": 17574,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 247,
            "שם_רחוב": "החסידה"
        },
        {
            "_id": 17575,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 605,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 17576,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 542,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 17577,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 991,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 17578,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 328,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 17579,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 532,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 17580,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 248,
            "שם_רחוב": "הטווס"
        },
        {
            "_id": 17581,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 634,
            "שם_רחוב": "הטייסים"
        },
        {
            "_id": 17582,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 906,
            "שם_רחוב": "היד"
        },
        {
            "_id": 17583,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "היובל"
        },
        {
            "_id": 17584,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 130,
            "שם_רחוב": "היוגב"
        },
        {
            "_id": 17585,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 125,
            "שם_רחוב": "היוצר"
        },
        {
            "_id": 17586,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 653,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 17587,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 287,
            "שם_רחוב": "היתד"
        },
        {
            "_id": 17588,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 249,
            "שם_רחוב": "הכחל"
        },
        {
            "_id": 17589,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הכישור"
        },
        {
            "_id": 17590,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 319,
            "שם_רחוב": "הכנסת"
        },
        {
            "_id": 17591,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 937,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 17592,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הלהב"
        },
        {
            "_id": 17593,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 514,
            "שם_רחוב": "הלוחמים"
        },
        {
            "_id": 17594,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 818,
            "שם_רחוב": "המגיד"
        },
        {
            "_id": 17595,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 410,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 17596,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "המכתש"
        },
        {
            "_id": 17597,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 17598,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 121,
            "שם_רחוב": "המנור"
        },
        {
            "_id": 17599,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 713,
            "שם_רחוב": "המעורר"
        },
        {
            "_id": 17600,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 126,
            "שם_רחוב": "המעין"
        },
        {
            "_id": 17601,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 529,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 17602,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 601,
            "שם_רחוב": "המצודה"
        },
        {
            "_id": 17603,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 993,
            "שם_רחוב": "המרגנית"
        },
        {
            "_id": 17604,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "המרכבה"
        },
        {
            "_id": 17605,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "המשביר"
        },
        {
            "_id": 17606,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 285,
            "שם_רחוב": "המשור"
        },
        {
            "_id": 17607,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 934,
            "שם_רחוב": "המתמיד"
        },
        {
            "_id": 17608,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 365,
            "שם_רחוב": "המתנדבים"
        },
        {
            "_id": 17609,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 17610,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הנגר"
        },
        {
            "_id": 17611,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 418,
            "שם_רחוב": "הנוטרים"
        },
        {
            "_id": 17612,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הנחושת"
        },
        {
            "_id": 17613,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 250,
            "שם_רחוב": "הנחליאלי"
        },
        {
            "_id": 17614,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 607,
            "שם_רחוב": "הניצנים"
        },
        {
            "_id": 17615,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הנפח"
        },
        {
            "_id": 17616,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 724,
            "שם_רחוב": "הנשיאים"
        },
        {
            "_id": 17617,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 229,
            "שם_רחוב": "הנשר"
        },
        {
            "_id": 17618,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 339,
            "שם_רחוב": "הס משה"
        },
        {
            "_id": 17619,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 808,
            "שם_רחוב": "הסבוראים"
        },
        {
            "_id": 17620,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 289,
            "שם_רחוב": "הסדן"
        },
        {
            "_id": 17621,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הסדנה"
        },
        {
            "_id": 17622,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הסולל"
        },
        {
            "_id": 17623,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 360,
            "שם_רחוב": "הסיגלון"
        },
        {
            "_id": 17624,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 371,
            "שם_רחוב": "הסיירת"
        },
        {
            "_id": 17625,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 251,
            "שם_רחוב": "הסלען"
        },
        {
            "_id": 17626,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 807,
            "שם_רחוב": "הסנהדרין"
        },
        {
            "_id": 17627,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 252,
            "שם_רחוב": "הסנונית"
        },
        {
            "_id": 17628,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הסתת"
        },
        {
            "_id": 17629,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 803,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 17630,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 253,
            "שם_רחוב": "העגור"
        },
        {
            "_id": 17631,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 931,
            "שם_רחוב": "העוגן"
        },
        {
            "_id": 17632,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 907,
            "שם_רחוב": "העליה השניה"
        },
        {
            "_id": 17633,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 254,
            "שם_רחוב": "העפרוני"
        },
        {
            "_id": 17634,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 321,
            "שם_רחוב": "העצמון"
        },
        {
            "_id": 17635,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 817,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 17636,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 631,
            "שם_רחוב": "הפורצים"
        },
        {
            "_id": 17637,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הפלד"
        },
        {
            "_id": 17638,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 417,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 17639,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 608,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 17640,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 255,
            "שם_רחוב": "הצופית"
        },
        {
            "_id": 17641,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הצורף"
        },
        {
            "_id": 17642,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 912,
            "שם_רחוב": "הציונות"
        },
        {
            "_id": 17643,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 429,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 17644,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 606,
            "שם_רחוב": "הצפורן"
        },
        {
            "_id": 17645,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 719,
            "שם_רחוב": "הצפירה"
        },
        {
            "_id": 17646,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 322,
            "שם_רחוב": "הקונגרס"
        },
        {
            "_id": 17647,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 256,
            "שם_רחוב": "הקוקיה"
        },
        {
            "_id": 17648,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 536,
            "שם_רחוב": "הקליר אלעזר"
        },
        {
            "_id": 17649,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 821,
            "שם_רחוב": "הר הצופים"
        },
        {
            "_id": 17650,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 520,
            "שם_רחוב": "הראובני"
        },
        {
            "_id": 17651,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 621,
            "שם_רחוב": "הראל"
        },
        {
            "_id": 17652,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 973,
            "שם_רחוב": "הרב באזוב דוד"
        },
        {
            "_id": 17653,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 442,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 17654,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 810,
            "שם_רחוב": "הרב ניסים יצחק"
        },
        {
            "_id": 17655,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 407,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 17656,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1008,
            "שם_רחוב": "הרדוף הנחלים"
        },
        {
            "_id": 17657,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הרוקמים"
        },
        {
            "_id": 17658,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 345,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 17659,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 342,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 17660,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 711,
            "שם_רחוב": "הרצפלד"
        },
        {
            "_id": 17661,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 602,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 17662,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 523,
            "שם_רחוב": "הררי"
        },
        {
            "_id": 17663,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 134,
            "שם_רחוב": "השואבים"
        },
        {
            "_id": 17664,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 439,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 17665,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 504,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 17666,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 204,
            "שם_רחוב": "השופטים"
        },
        {
            "_id": 17667,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 259,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 17668,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 311,
            "שם_רחוב": "השחר"
        },
        {
            "_id": 17669,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 343,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 17670,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 823,
            "שם_רחוב": "השילוח"
        },
        {
            "_id": 17671,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 369,
            "שם_רחוב": "השמיר"
        },
        {
            "_id": 17672,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 292,
            "שם_רחוב": "השסתומים"
        },
        {
            "_id": 17673,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 651,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 17674,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 362,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 17675,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 258,
            "שם_רחוב": "השקנאי"
        },
        {
            "_id": 17676,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 408,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 17677,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 368,
            "שם_רחוב": "השריון"
        },
        {
            "_id": 17678,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 624,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 17679,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 349,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 17680,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 348,
            "שם_רחוב": "התומר"
        },
        {
            "_id": 17681,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 260,
            "שם_רחוב": "התור"
        },
        {
            "_id": 17682,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 642,
            "שם_רחוב": "התותחנים"
        },
        {
            "_id": 17683,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 357,
            "שם_רחוב": "התחיה"
        },
        {
            "_id": 17684,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 805,
            "שם_רחוב": "התנאים"
        },
        {
            "_id": 17685,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 825,
            "שם_רחוב": "התעלה"
        },
        {
            "_id": 17686,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 932,
            "שם_רחוב": "התקומה"
        },
        {
            "_id": 17687,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 337,
            "שם_רחוב": "וולפסון"
        },
        {
            "_id": 17688,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 760,
            "שם_רחוב": "וולקני יצחק"
        },
        {
            "_id": 17689,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 702,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 17690,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 919,
            "שם_רחוב": "ויתקין"
        },
        {
            "_id": 17691,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1001,
            "שם_רחוב": "ולנברג ראול"
        },
        {
            "_id": 17692,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 307,
            "שם_רחוב": "ורבורג"
        },
        {
            "_id": 17693,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 416,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 17694,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 737,
            "שם_רחוב": "ז'ן ז'ורס"
        },
        {
            "_id": 17695,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 548,
            "שם_רחוב": "זאבי רחבעם"
        },
        {
            "_id": 17696,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 278,
            "שם_רחוב": "זבולון"
        },
        {
            "_id": 17697,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1029,
            "שם_רחוב": "זוהר מרים"
        },
        {
            "_id": 17698,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 284,
            "שם_רחוב": "זכריה"
        },
        {
            "_id": 17699,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 553,
            "שם_רחוב": "זכריה הרופא"
        },
        {
            "_id": 17700,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 717,
            "שם_רחוב": "זמנהוף"
        },
        {
            "_id": 17701,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 812,
            "שם_רחוב": "זרובבל"
        },
        {
            "_id": 17702,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 935,
            "שם_רחוב": "חבד"
        },
        {
            "_id": 17703,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 272,
            "שם_רחוב": "חבקוק"
        },
        {
            "_id": 17704,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 277,
            "שם_רחוב": "חברון"
        },
        {
            "_id": 17705,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 273,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 17706,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 274,
            "שם_רחוב": "חובב"
        },
        {
            "_id": 17707,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 509,
            "שם_רחוב": "חובבי ציון"
        },
        {
            "_id": 17708,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חולון"
        },
        {
            "_id": 17709,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6552,
            "שם_רחוב": "חולון הירוקה"
        },
        {
            "_id": 17710,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6545,
            "שם_רחוב": "חולון הצעירה"
        },
        {
            "_id": 17711,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 704,
            "שם_רחוב": "חומה ומגדל"
        },
        {
            "_id": 17712,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 622,
            "שם_רחוב": "חזית חמש"
        },
        {
            "_id": 17713,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1023,
            "שם_רחוב": "חיטמן עוזי"
        },
        {
            "_id": 17714,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 294,
            "שם_רחוב": "חיים חפר"
        },
        {
            "_id": 17715,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 298,
            "שם_רחוב": "חיים שרון"
        },
        {
            "_id": 17716,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 740,
            "שם_רחוב": "חכמי ישראל"
        },
        {
            "_id": 17717,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 819,
            "שם_רחוב": "חלץ"
        },
        {
            "_id": 17718,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1120,
            "שם_רחוב": "חניון אוטובוסים"
        },
        {
            "_id": 17719,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1101,
            "שם_רחוב": "חנניה"
        },
        {
            "_id": 17720,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 415,
            "שם_רחוב": "חנקין"
        },
        {
            "_id": 17721,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 643,
            "שם_רחוב": "חסדאי"
        },
        {
            "_id": 17722,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 832,
            "שם_רחוב": "חצבה"
        },
        {
            "_id": 17723,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 747,
            "שם_רחוב": "חצרים"
        },
        {
            "_id": 17724,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 351,
            "שם_רחוב": "טבנקין יצחק"
        },
        {
            "_id": 17725,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 218,
            "שם_רחוב": "טהון"
        },
        {
            "_id": 17726,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 438,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 17727,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 733,
            "שם_רחוב": "טשרניחובסקי"
        },
        {
            "_id": 17728,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 715,
            "שם_רחוב": "יא באדר"
        },
        {
            "_id": 17729,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 361,
            "שם_רחוב": "יא הספורטאים"
        },
        {
            "_id": 17730,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 276,
            "שם_רחוב": "יאיר הגלעדי"
        },
        {
            "_id": 17731,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 714,
            "שם_רחוב": "יבנה"
        },
        {
            "_id": 17732,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 771,
            "שם_רחוב": "יבניאלי שמואל"
        },
        {
            "_id": 17733,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 338,
            "שם_רחוב": "יד לגולה"
        },
        {
            "_id": 17734,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 323,
            "שם_רחוב": "ידין יגאל"
        },
        {
            "_id": 17735,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 517,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 17736,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 835,
            "שם_רחוב": "יהודה הנשיא"
        },
        {
            "_id": 17737,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 135,
            "שם_רחוב": "יהודה מקוגן"
        },
        {
            "_id": 17738,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1102,
            "שם_רחוב": "יהושפט"
        },
        {
            "_id": 17739,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 261,
            "שם_רחוב": "יואב"
        },
        {
            "_id": 17740,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1105,
            "שם_רחוב": "יואל"
        },
        {
            "_id": 17741,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 962,
            "שם_רחוב": "יום הכיפורים"
        },
        {
            "_id": 17742,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 288,
            "שם_רחוב": "יונה"
        },
        {
            "_id": 17743,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 280,
            "שם_רחוב": "יוסי בנאי"
        },
        {
            "_id": 17744,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 527,
            "שם_רחוב": "יוסף הנשיא"
        },
        {
            "_id": 17745,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 262,
            "שם_רחוב": "יותם"
        },
        {
            "_id": 17746,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 223,
            "שם_רחוב": "יחזקאל"
        },
        {
            "_id": 17747,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 801,
            "שם_רחוב": "יטבתה"
        },
        {
            "_id": 17748,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 734,
            "שם_רחוב": "ילג"
        },
        {
            "_id": 17749,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 330,
            "שם_רחוב": "יסוד המעלה"
        },
        {
            "_id": 17750,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 217,
            "שם_רחוב": "יעבץ"
        },
        {
            "_id": 17751,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 263,
            "שם_רחוב": "יעל"
        },
        {
            "_id": 17752,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 297,
            "שם_רחוב": "יעקב פרי (פרולוב)"
        },
        {
            "_id": 17753,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 980,
            "שם_רחוב": "יפה אברהם"
        },
        {
            "_id": 17754,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 772,
            "שם_רחוב": "יפה אליעזר"
        },
        {
            "_id": 17755,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 617,
            "שם_רחוב": "יפו"
        },
        {
            "_id": 17756,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 264,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 17757,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 531,
            "שם_רחוב": "יצחק הלוי"
        },
        {
            "_id": 17758,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 950,
            "שם_רחוב": "יריחו"
        },
        {
            "_id": 17759,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 202,
            "שם_רחוב": "ירמיהו"
        },
        {
            "_id": 17760,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1021,
            "שם_רחוב": "ירקוני יפה"
        },
        {
            "_id": 17761,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 222,
            "שם_רחוב": "ישעיהו"
        },
        {
            "_id": 17762,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 767,
            "שם_רחוב": "ישראלי בן ציון"
        },
        {
            "_id": 17763,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר בארי"
        },
        {
            "_id": 17764,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "ככר הלוחמים"
        },
        {
            "_id": 17765,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר המוסדות"
        },
        {
            "_id": 17766,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר השבעה"
        },
        {
            "_id": 17767,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר השלושה"
        },
        {
            "_id": 17768,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "ככר ויצמן"
        },
        {
            "_id": 17769,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר סטרומה"
        },
        {
            "_id": 17770,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "ככר סירן"
        },
        {
            "_id": 17771,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 612,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 17772,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 331,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 17773,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 754,
            "שם_רחוב": "כפר גלעדי"
        },
        {
            "_id": 17774,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 921,
            "שם_רחוב": "לבון פנחס"
        },
        {
            "_id": 17775,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 630,
            "שם_רחוב": "לוחמי הנגב"
        },
        {
            "_id": 17776,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 837,
            "שם_רחוב": "לויתן"
        },
        {
            "_id": 17777,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 639,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 17778,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1007,
            "שם_רחוב": "ליבנה"
        },
        {
            "_id": 17779,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 404,
            "שם_רחוב": "לילינבלום"
        },
        {
            "_id": 17780,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 718,
            "שם_רחוב": "ליסין"
        },
        {
            "_id": 17781,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 506,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 17782,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 797,
            "שם_רחוב": "לנדאו חיים"
        },
        {
            "_id": 17783,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 716,
            "שם_רחוב": "לסל"
        },
        {
            "_id": 17784,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 794,
            "שם_רחוב": "לסקוב חיים"
        },
        {
            "_id": 17785,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 312,
            "שם_רחוב": "מאפו"
        },
        {
            "_id": 17786,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 620,
            "שם_רחוב": "מבצע סיני"
        },
        {
            "_id": 17787,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 826,
            "שם_רחוב": "מגידו"
        },
        {
            "_id": 17788,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 730,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 17789,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 333,
            "שם_רחוב": "מוהליבר"
        },
        {
            "_id": 17790,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1110,
            "שם_רחוב": "מוזיאון רכב הסטורי"
        },
        {
            "_id": 17791,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 990,
            "שם_רחוב": "מוטה גור"
        },
        {
            "_id": 17792,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 521,
            "שם_רחוב": "מולכו שלמה"
        },
        {
            "_id": 17793,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 516,
            "שם_רחוב": "מונטיפיורי"
        },
        {
            "_id": 17794,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 301,
            "שם_רחוב": "מוצקין"
        },
        {
            "_id": 17795,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 908,
            "שם_רחוב": "מורדי הגטאות"
        },
        {
            "_id": 17796,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 508,
            "שם_רחוב": "מזאה"
        },
        {
            "_id": 17797,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 967,
            "שם_רחוב": "מטולה"
        },
        {
            "_id": 17798,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 265,
            "שם_רחוב": "מיכה"
        },
        {
            "_id": 17799,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 773,
            "שם_רחוב": "מילגרום"
        },
        {
            "_id": 17800,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 444,
            "שם_רחוב": "מימון"
        },
        {
            "_id": 17801,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 290,
            "שם_רחוב": "מישאל"
        },
        {
            "_id": 17802,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 546,
            "שם_רחוב": "מכס וג'סי כהן"
        },
        {
            "_id": 17803,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 266,
            "שם_רחוב": "מלאכי"
        },
        {
            "_id": 17804,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 434,
            "שם_רחוב": "מנדלי מוכר ספרים"
        },
        {
            "_id": 17805,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 335,
            "שם_רחוב": "מנדלסון"
        },
        {
            "_id": 17806,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 131,
            "שם_רחוב": "מנוח"
        },
        {
            "_id": 17807,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1022,
            "שם_רחוב": "מנור אהוד"
        },
        {
            "_id": 17808,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 814,
            "שם_רחוב": "מסילת העולים"
        },
        {
            "_id": 17809,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 963,
            "שם_רחוב": "מעלות"
        },
        {
            "_id": 17810,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 942,
            "שם_רחוב": "מפרץ שלמה"
        },
        {
            "_id": 17811,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 426,
            "שם_רחוב": "מקוה ישראל"
        },
        {
            "_id": 17812,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 799,
            "שם_רחוב": "מקלף מרדכי"
        },
        {
            "_id": 17813,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 226,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 17814,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 219,
            "שם_רחוב": "מרדכי שפיגל"
        },
        {
            "_id": 17815,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1106,
            "שם_רחוב": "מרים הנביאה"
        },
        {
            "_id": 17816,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1050,
            "שם_רחוב": "מרכז רפואי וולפסון"
        },
        {
            "_id": 17817,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 405,
            "שם_רחוב": "מרקובצקי"
        },
        {
            "_id": 17818,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 200,
            "שם_רחוב": "משה רינת"
        },
        {
            "_id": 17819,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 445,
            "שם_רחוב": "משמר הירדן"
        },
        {
            "_id": 17820,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 784,
            "שם_רחוב": "משעול דר"
        },
        {
            "_id": 17821,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 788,
            "שם_רחוב": "משעול הבדולח"
        },
        {
            "_id": 17822,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 783,
            "שם_רחוב": "משעול הטופז"
        },
        {
            "_id": 17823,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 781,
            "שם_רחוב": "משעול היהלום"
        },
        {
            "_id": 17824,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 789,
            "שם_רחוב": "משעול הלשם"
        },
        {
            "_id": 17825,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 782,
            "שם_רחוב": "משעול הנופך"
        },
        {
            "_id": 17826,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 790,
            "שם_רחוב": "משעול הספירים"
        },
        {
            "_id": 17827,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 787,
            "שם_רחוב": "משעול הפז"
        },
        {
            "_id": 17828,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 851,
            "שם_רחוב": "משעול השוהם"
        },
        {
            "_id": 17829,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 649,
            "שם_רחוב": "משעול השקד"
        },
        {
            "_id": 17830,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 786,
            "שם_רחוב": "משעול התרשיש"
        },
        {
            "_id": 17831,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 636,
            "שם_רחוב": "משעול יוסף יהונתן"
        },
        {
            "_id": 17832,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 852,
            "שם_רחוב": "משעול ענבר"
        },
        {
            "_id": 17833,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6600,
            "שם_רחוב": "מתחם חולות א"
        },
        {
            "_id": 17834,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6601,
            "שם_רחוב": "מתחם חולות ב"
        },
        {
            "_id": 17835,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6603,
            "שם_רחוב": "מתחם חולות ד"
        },
        {
            "_id": 17836,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "נאות יהודית"
        },
        {
            "_id": 17837,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "נאות שושנים"
        },
        {
            "_id": 17838,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 703,
            "שם_רחוב": "נג'ארה ישראל"
        },
        {
            "_id": 17839,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 367,
            "שם_רחוב": "נהריה"
        },
        {
            "_id": 17840,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 358,
            "שם_רחוב": "נובידבור"
        },
        {
            "_id": 17841,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "נוה ארזים"
        },
        {
            "_id": 17842,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6565,
            "שם_רחוב": "נווה רמז"
        },
        {
            "_id": 17843,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 293,
            "שם_רחוב": "נועה אשכול"
        },
        {
            "_id": 17844,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 427,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 17845,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1006,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 17846,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 267,
            "שם_רחוב": "נחום"
        },
        {
            "_id": 17847,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 551,
            "שם_רחוב": "נחלת שלום"
        },
        {
            "_id": 17848,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 205,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 17849,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 618,
            "שם_רחוב": "נחשון"
        },
        {
            "_id": 17850,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 430,
            "שם_רחוב": "נטר קרל"
        },
        {
            "_id": 17851,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 424,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 17852,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 220,
            "שם_רחוב": "ניסנבאום"
        },
        {
            "_id": 17853,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 413,
            "שם_רחוב": "נס ציונה"
        },
        {
            "_id": 17854,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 710,
            "שם_רחוב": "נפחא"
        },
        {
            "_id": 17855,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 985,
            "שם_רחוב": "נרקיס עוזי"
        },
        {
            "_id": 17856,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 329,
            "שם_רחוב": "סאלק"
        },
        {
            "_id": 17857,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 353,
            "שם_רחוב": "סבידור מנחם"
        },
        {
            "_id": 17858,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 615,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 17859,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 519,
            "שם_רחוב": "סולד הנריטה"
        },
        {
            "_id": 17860,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 401,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 17861,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 903,
            "שם_רחוב": "סירני"
        },
        {
            "_id": 17862,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 902,
            "שם_רחוב": "סירקין"
        },
        {
            "_id": 17863,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 995,
            "שם_רחוב": "סמ הניסנית"
        },
        {
            "_id": 17864,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 992,
            "שם_רחוב": "סמ הרוזמרין"
        },
        {
            "_id": 17865,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 310,
            "שם_רחוב": "סמולנסקין"
        },
        {
            "_id": 17866,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 987,
            "שם_רחוב": "סמטת הרעות"
        },
        {
            "_id": 17867,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 988,
            "שם_רחוב": "סמטת השלום"
        },
        {
            "_id": 17868,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 972,
            "שם_רחוב": "סנה משה"
        },
        {
            "_id": 17869,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 425,
            "שם_רחוב": "סנש"
        },
        {
            "_id": 17870,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 530,
            "שם_רחוב": "סעדיה גאון"
        },
        {
            "_id": 17871,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 332,
            "שם_רחוב": "ספיר יוסף"
        },
        {
            "_id": 17872,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 645,
            "שם_רחוב": "סרלין יוסף"
        },
        {
            "_id": 17873,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 955,
            "שם_רחוב": "עגנון שי"
        },
        {
            "_id": 17874,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 268,
            "שם_רחוב": "עובדיה"
        },
        {
            "_id": 17875,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 355,
            "שם_רחוב": "עוזיאל"
        },
        {
            "_id": 17876,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 206,
            "שם_רחוב": "עזרא"
        },
        {
            "_id": 17877,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1104,
            "שם_רחוב": "עזריה"
        },
        {
            "_id": 17878,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 961,
            "שם_רחוב": "עטרות"
        },
        {
            "_id": 17879,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 802,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 17880,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 816,
            "שם_רחוב": "עין יהב"
        },
        {
            "_id": 17881,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1010,
            "שם_רחוב": "עירית גדולה"
        },
        {
            "_id": 17882,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 225,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 17883,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 120,
            "שם_רחוב": "עמינדב"
        },
        {
            "_id": 17884,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 913,
            "שם_רחוב": "עמל"
        },
        {
            "_id": 17885,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 619,
            "שם_רחוב": "עמק אילון"
        },
        {
            "_id": 17886,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 965,
            "שם_רחוב": "עמק דותן"
        },
        {
            "_id": 17887,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 411,
            "שם_רחוב": "עמק יזרעאל"
        },
        {
            "_id": 17888,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 296,
            "שם_רחוב": "עפרה חזה"
        },
        {
            "_id": 17889,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 344,
            "שם_רחוב": "פבריגט אנריקו"
        },
        {
            "_id": 17890,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 363,
            "שם_רחוב": "פדויי השבי"
        },
        {
            "_id": 17891,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 996,
            "שם_רחוב": "פהר אילונה"
        },
        {
            "_id": 17892,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1024,
            "שם_רחוב": "פורת אורנה"
        },
        {
            "_id": 17893,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 433,
            "שם_רחוב": "פיארברג"
        },
        {
            "_id": 17894,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 756,
            "שם_רחוב": "פייר קניג"
        },
        {
            "_id": 17895,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 751,
            "שם_רחוב": "פיכמן"
        },
        {
            "_id": 17896,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 623,
            "שם_רחוב": "פילדלפיה"
        },
        {
            "_id": 17897,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 309,
            "שם_רחוב": "פינסקר"
        },
        {
            "_id": 17898,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 441,
            "שם_רחוב": "פנחס ספיר"
        },
        {
            "_id": 17899,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 422,
            "שם_רחוב": "פרוג"
        },
        {
            "_id": 17900,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 209,
            "שם_רחוב": "פרופ' שור"
        },
        {
            "_id": 17901,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 403,
            "שם_רחוב": "פרישמן"
        },
        {
            "_id": 17902,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 227,
            "שם_רחוב": "פרנק אנה"
        },
        {
            "_id": 17903,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 437,
            "שם_רחוב": "פרץ"
        },
        {
            "_id": 17904,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 414,
            "שם_רחוב": "פתח תקוה"
        },
        {
            "_id": 17905,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 511,
            "שם_רחוב": "צ'לנוב"
        },
        {
            "_id": 17906,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 748,
            "שם_רחוב": "צאלים"
        },
        {
            "_id": 17907,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 736,
            "שם_רחוב": "צבי תדמור"
        },
        {
            "_id": 17908,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 793,
            "שם_רחוב": "צדוק דוד"
        },
        {
            "_id": 17909,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 739,
            "שם_רחוב": "צעירי מזרח"
        },
        {
            "_id": 17910,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 269,
            "שם_רחוב": "צפניה"
        },
        {
            "_id": 17911,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 966,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 17912,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 281,
            "שם_רחוב": "צרעה"
        },
        {
            "_id": 17913,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 302,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 17914,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 326,
            "שם_רחוב": "קדושי בלזן"
        },
        {
            "_id": 17915,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 750,
            "שם_רחוב": "קדושי קהיר"
        },
        {
            "_id": 17916,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 354,
            "שם_רחוב": "קדיש לוז"
        },
        {
            "_id": 17917,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 960,
            "שם_רחוב": "קדמן"
        },
        {
            "_id": 17918,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 830,
            "שם_רחוב": "קהילת וילנה"
        },
        {
            "_id": 17919,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 829,
            "שם_רחוב": "קהילת ורשה"
        },
        {
            "_id": 17920,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 270,
            "שם_רחוב": "קוהלת"
        },
        {
            "_id": 17921,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 214,
            "שם_רחוב": "קורצ'ק"
        },
        {
            "_id": 17922,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1027,
            "שם_רחוב": "קישון אפרים"
        },
        {
            "_id": 17923,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 951,
            "שם_רחוב": "קלויזנר"
        },
        {
            "_id": 17924,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 237,
            "שם_רחוב": "קלומל"
        },
        {
            "_id": 17925,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 420,
            "שם_רחוב": "קלישר"
        },
        {
            "_id": 17926,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1028,
            "שם_רחוב": "קניג ליא"
        },
        {
            "_id": 17927,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 525,
            "שם_רחוב": "קפאח"
        },
        {
            "_id": 17928,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 513,
            "שם_רחוב": "קפלן"
        },
        {
            "_id": 17929,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 766,
            "שם_רחוב": "קפלנסקי"
        },
        {
            "_id": 17930,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 957,
            "שם_רחוב": "קציר אהרון"
        },
        {
            "_id": 17931,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 917,
            "שם_רחוב": "קצנלסון יצחק"
        },
        {
            "_id": 17932,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 406,
            "שם_רחוב": "קראוזה"
        },
        {
            "_id": 17933,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6602,
            "שם_רחוב": "קרית יצחק רבין"
        },
        {
            "_id": 17934,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6585,
            "שם_רחוב": "קרית מיכה"
        },
        {
            "_id": 17935,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6555,
            "שם_רחוב": "קרית עבודה"
        },
        {
            "_id": 17936,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6604,
            "שם_רחוב": "קרית פנחס אילון"
        },
        {
            "_id": 17937,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 968,
            "שם_רחוב": "קרית שמונה"
        },
        {
            "_id": 17938,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 625,
            "שם_רחוב": "קריתי"
        },
        {
            "_id": 17939,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 947,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 17940,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 946,
            "שם_רחוב": "קרן קיימת"
        },
        {
            "_id": 17941,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 791,
            "שם_רחוב": "קרסל גצל"
        },
        {
            "_id": 17942,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 325,
            "שם_רחוב": "ראש פנה"
        },
        {
            "_id": 17943,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 412,
            "שם_רחוב": "ראשון לציון"
        },
        {
            "_id": 17944,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 308,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 17945,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 984,
            "שם_רחוב": "רבין יצחק"
        },
        {
            "_id": 17946,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 795,
            "שם_רחוב": "רבינוביץ יהושע"
        },
        {
            "_id": 17947,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1031,
            "שם_רחוב": "רוזנבלום יאיר"
        },
        {
            "_id": 17948,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 512,
            "שם_רחוב": "רוטשילד"
        },
        {
            "_id": 17949,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 315,
            "שם_רחוב": "רום משה"
        },
        {
            "_id": 17950,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 334,
            "שם_רחוב": "רופין"
        },
        {
            "_id": 17951,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 764,
            "שם_רחוב": "רותר ולטר"
        },
        {
            "_id": 17952,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 928,
            "שם_רחוב": "רח ג אזור"
        },
        {
            "_id": 17953,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 614,
            "שם_רחוב": "רח ו תל גבורים"
        },
        {
            "_id": 17954,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 224,
            "שם_רחוב": "רח ט"
        },
        {
            "_id": 17955,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 820,
            "שם_רחוב": "רח כג"
        },
        {
            "_id": 17956,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 776,
            "שם_רחוב": "רח כד"
        },
        {
            "_id": 17957,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 728,
            "שם_רחוב": "רחל"
        },
        {
            "_id": 17958,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 336,
            "שם_רחוב": "ריינס"
        },
        {
            "_id": 17959,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 971,
            "שם_רחוב": "רייק חביבה"
        },
        {
            "_id": 17960,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 283,
            "שם_רחוב": "רינגלבלום"
        },
        {
            "_id": 17961,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 533,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 17962,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 918,
            "שם_רחוב": "רמז"
        },
        {
            "_id": 17963,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 709,
            "שם_רחוב": "רמחל"
        },
        {
            "_id": 17964,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 943,
            "שם_רחוב": "רמת הגולן"
        },
        {
            "_id": 17965,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 999,
            "שם_רחוב": "רפאל איתן"
        },
        {
            "_id": 17966,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 524,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 17967,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6547,
            "שם_רחוב": "ש אביב"
        },
        {
            "_id": 17968,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "ש אזבסטונים"
        },
        {
            "_id": 17969,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6576,
            "שם_רחוב": "ש אקדמאים"
        },
        {
            "_id": 17970,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "ש בטיש"
        },
        {
            "_id": 17971,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6586,
            "שם_רחוב": "ש ג'סי כהן"
        },
        {
            "_id": 17972,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "ש ההסתדרות"
        },
        {
            "_id": 17973,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "ש השומרונים"
        },
        {
            "_id": 17974,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6587,
            "שם_רחוב": "ש חסכון"
        },
        {
            "_id": 17975,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "ש מומחים"
        },
        {
            "_id": 17976,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "ש מפדה אזרחי"
        },
        {
            "_id": 17977,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6561,
            "שם_רחוב": "ש נאות רחל"
        },
        {
            "_id": 17978,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6548,
            "שם_רחוב": "ש עולה חדש"
        },
        {
            "_id": 17979,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6549,
            "שם_רחוב": "ש עולים תל גבורים"
        },
        {
            "_id": 17980,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 17981,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "ש פרוגרסיבים"
        },
        {
            "_id": 17982,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6589,
            "שם_רחוב": "ש קליפורניה"
        },
        {
            "_id": 17983,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "ש רסקו ב"
        },
        {
            "_id": 17984,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "ש רסקו ג"
        },
        {
            "_id": 17985,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "ש תל גבורים"
        },
        {
            "_id": 17986,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 518,
            "שם_רחוב": "שץ צבי"
        },
        {
            "_id": 17987,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 982,
            "שם_רחוב": "שאלתיאל דוד"
        },
        {
            "_id": 17988,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 727,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 17989,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 746,
            "שם_רחוב": "שבטה"
        },
        {
            "_id": 17990,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 923,
            "שם_רחוב": "שבטי ישראל"
        },
        {
            "_id": 17991,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 920,
            "שם_רחוב": "שביל א.ד.ג."
        },
        {
            "_id": 17992,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 983,
            "שם_רחוב": "שד בגין מנחם"
        },
        {
            "_id": 17993,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 800,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 17994,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 725,
            "שם_רחוב": "שד הוז דב"
        },
        {
            "_id": 17995,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 749,
            "שם_רחוב": "שד יוספטל"
        },
        {
            "_id": 17996,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 316,
            "שם_רחוב": "שד ירושלים"
        },
        {
            "_id": 17997,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 637,
            "שם_רחוב": "שד לוי אשכול"
        },
        {
            "_id": 17998,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 505,
            "שם_רחוב": "שד קוגל"
        },
        {
            "_id": 17999,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 540,
            "שם_רחוב": "שדל"
        },
        {
            "_id": 18000,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 745,
            "שם_רחוב": "שדה בוקר"
        },
        {
            "_id": 18001,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 543,
            "שם_רחוב": "שדה יצחק"
        },
        {
            "_id": 18002,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 770,
            "שם_רחוב": "שוחט מניה וישראל"
        },
        {
            "_id": 18003,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 350,
            "שם_רחוב": "שטרוק"
        },
        {
            "_id": 18004,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 813,
            "שם_רחוב": "שיבת ציון"
        },
        {
            "_id": 18005,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שיכון אגרובנק"
        },
        {
            "_id": 18006,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "שיכון עממי"
        },
        {
            "_id": 18007,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 215,
            "שם_רחוב": "שיפר"
        },
        {
            "_id": 18008,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6595,
            "שם_רחוב": "שכ גבעת חולון"
        },
        {
            "_id": 18009,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6551,
            "שם_רחוב": "שכ גרין"
        },
        {
            "_id": 18010,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6584,
            "שם_רחוב": "שכ הקרוונים"
        },
        {
            "_id": 18011,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6596,
            "שם_רחוב": "שכ מולדת"
        },
        {
            "_id": 18012,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6597,
            "שם_רחוב": "שכ קרית בן גוריון"
        },
        {
            "_id": 18013,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ קרית שרת"
        },
        {
            "_id": 18014,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6577,
            "שם_רחוב": "שכון עירוני ג"
        },
        {
            "_id": 18015,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 6553,
            "שם_רחוב": "שכונת עם"
        },
        {
            "_id": 18016,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 346,
            "שם_רחוב": "שכטר"
        },
        {
            "_id": 18017,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 435,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 18018,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 271,
            "שם_רחוב": "שמואל"
        },
        {
            "_id": 18019,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 526,
            "שם_רחוב": "שמואל הנגיד"
        },
        {
            "_id": 18020,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 359,
            "שם_רחוב": "שמורק"
        },
        {
            "_id": 18021,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 731,
            "שם_רחוב": "שמעוני"
        },
        {
            "_id": 18022,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1103,
            "שם_רחוב": "שמעיה"
        },
        {
            "_id": 18023,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1025,
            "שם_רחוב": "שמר נעמי"
        },
        {
            "_id": 18024,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 313,
            "שם_רחוב": "שמריהו לוין"
        },
        {
            "_id": 18025,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 275,
            "שם_רחוב": "שמשון"
        },
        {
            "_id": 18026,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 706,
            "שם_רחוב": "שנקר"
        },
        {
            "_id": 18027,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 940,
            "שם_רחוב": "שער האריות"
        },
        {
            "_id": 18028,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 629,
            "שם_רחוב": "שער הגיא"
        },
        {
            "_id": 18029,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 949,
            "שם_רחוב": "שער הפרחים"
        },
        {
            "_id": 18030,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 959,
            "שם_רחוב": "שער ציון"
        },
        {
            "_id": 18031,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 956,
            "שם_רחוב": "שפירא חיים משה"
        },
        {
            "_id": 18032,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 432,
            "שם_רחוב": "שפירא צבי"
        },
        {
            "_id": 18033,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 443,
            "שם_רחוב": "שפרינצק"
        },
        {
            "_id": 18034,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 295,
            "שם_רחוב": "שרה לוי תנאי"
        },
        {
            "_id": 18035,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 944,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 18036,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 822,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 18037,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 723,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 18038,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 119,
            "שם_רחוב": "תמנע"
        },
        {
            "_id": 18039,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 1009,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 18040,
            "סמל_ישוב": 6600,
            "שם_ישוב": "חולון ",
            "סמל_רחוב": 707,
            "שם_רחוב": "תרצו"
        },
        {
            "_id": 18041,
            "סמל_ישוב": 1239,
            "שם_ישוב": "חולית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חולית"
        },
        {
            "_id": 18042,
            "סמל_ישוב": 253,
            "שם_ישוב": "חולתה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 18043,
            "סמל_ישוב": 253,
            "שם_ישוב": "חולתה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חולתה"
        },
        {
            "_id": 18044,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ביתר"
        },
        {
            "_id": 18045,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 115,
            "שם_רחוב": "האגם"
        },
        {
            "_id": 18046,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 121,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 18047,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 120,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 18048,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 117,
            "שם_רחוב": "האלונים"
        },
        {
            "_id": 18049,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 18050,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הברושים"
        },
        {
            "_id": 18051,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 18052,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הזיתים"
        },
        {
            "_id": 18053,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "החקלאים"
        },
        {
            "_id": 18054,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 116,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 18055,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 18056,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 18057,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הערבות"
        },
        {
            "_id": 18058,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 18059,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 123,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 18060,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חוסן"
        },
        {
            "_id": 18061,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "יהדות מרוקו"
        },
        {
            "_id": 18062,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 118,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 18063,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 124,
            "שם_רחוב": "מורן החורש"
        },
        {
            "_id": 18064,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "עולי סין"
        },
        {
            "_id": 18065,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שביל חרות"
        },
        {
            "_id": 18066,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שביל קקל"
        },
        {
            "_id": 18067,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "שלום שארלי שלוש"
        },
        {
            "_id": 18068,
            "סמל_ישוב": 662,
            "שם_ישוב": "חוסן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שלמה בן יוסף"
        },
        {
            "_id": 18069,
            "סמל_ישוב": 1332,
            "שם_ישוב": "חוסנייה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חוסנייה"
        },
        {
            "_id": 18070,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 18071,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 109,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 18072,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הסנונית"
        },
        {
            "_id": 18073,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הצדף"
        },
        {
            "_id": 18074,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 103,
            "שם_רחוב": "השלדג"
        },
        {
            "_id": 18075,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 110,
            "שם_רחוב": "השקמים"
        },
        {
            "_id": 18076,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חופית"
        },
        {
            "_id": 18077,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 104,
            "שם_רחוב": "כוכב הים"
        },
        {
            "_id": 18078,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מורד הנחל"
        },
        {
            "_id": 18079,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שונית"
        },
        {
            "_id": 18080,
            "סמל_ישוב": 115,
            "שם_ישוב": "חופית ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 18081,
            "סמל_ישוב": 374,
            "שם_ישוב": "חוקוק ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 18082,
            "סמל_ישוב": 374,
            "שם_ישוב": "חוקוק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חוקוק"
        },
        {
            "_id": 18083,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אבו בקר אלצדיק"
        },
        {
            "_id": 18084,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אבו חאמד אלע'זאלי"
        },
        {
            "_id": 18085,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אבו סמארה"
        },
        {
            "_id": 18086,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אבו עביידה"
        },
        {
            "_id": 18087,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 18088,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 18089,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אחמד בן חנבל"
        },
        {
            "_id": 18090,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "איבן תאימייה"
        },
        {
            "_id": 18091,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אל חוארזמי"
        },
        {
            "_id": 18092,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אלאבדאע"
        },
        {
            "_id": 18093,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אלאהראם"
        },
        {
            "_id": 18094,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אלאח'אא"
        },
        {
            "_id": 18095,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אלאח'וה"
        },
        {
            "_id": 18096,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אלאח'לאס"
        },
        {
            "_id": 18097,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלאחסאן"
        },
        {
            "_id": 18098,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 18099,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלאנדלוס"
        },
        {
            "_id": 18100,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "אלאנואר"
        },
        {
            "_id": 18101,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אלאסראא"
        },
        {
            "_id": 18102,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלבוראק"
        },
        {
            "_id": 18103,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 18104,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלבירוני"
        },
        {
            "_id": 18105,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "אלג'אמע"
        },
        {
            "_id": 18106,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "אלג'זירה"
        },
        {
            "_id": 18107,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלג'מאמה"
        },
        {
            "_id": 18108,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "אלדוחא"
        },
        {
            "_id": 18109,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלהג'רה"
        },
        {
            "_id": 18110,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלהודא"
        },
        {
            "_id": 18111,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלואחה"
        },
        {
            "_id": 18112,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלופאא"
        },
        {
            "_id": 18113,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אלזהור"
        },
        {
            "_id": 18114,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלזהראא"
        },
        {
            "_id": 18115,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 18116,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלזעתר"
        },
        {
            "_id": 18117,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אלח'טאב"
        },
        {
            "_id": 18118,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אלח'ליל"
        },
        {
            "_id": 18119,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "אלח'נסאא"
        },
        {
            "_id": 18120,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אלחג'"
        },
        {
            "_id": 18121,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלחוסין בן עלי"
        },
        {
            "_id": 18122,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלחיאה"
        },
        {
            "_id": 18123,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלחכמה"
        },
        {
            "_id": 18124,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "אלחריה"
        },
        {
            "_id": 18125,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלחרם"
        },
        {
            "_id": 18126,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אלטואחין"
        },
        {
            "_id": 18127,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אלטויל"
        },
        {
            "_id": 18128,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אלטיבה"
        },
        {
            "_id": 18129,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלירמוק"
        },
        {
            "_id": 18130,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אלכרם"
        },
        {
            "_id": 18131,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלכרמה"
        },
        {
            "_id": 18132,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלמג'ד"
        },
        {
            "_id": 18133,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלמדינה"
        },
        {
            "_id": 18134,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אלמהדי"
        },
        {
            "_id": 18135,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלמיזאן"
        },
        {
            "_id": 18136,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "אלמנארה"
        },
        {
            "_id": 18137,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אלמנסור"
        },
        {
            "_id": 18138,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלמעתסם"
        },
        {
            "_id": 18139,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אלמרוה"
        },
        {
            "_id": 18140,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "אלמריך"
        },
        {
            "_id": 18141,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אלמתנבי"
        },
        {
            "_id": 18142,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלנג'את"
        },
        {
            "_id": 18143,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 18144,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 18145,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "אלנעים"
        },
        {
            "_id": 18146,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אלסביל"
        },
        {
            "_id": 18147,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלסבר"
        },
        {
            "_id": 18148,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 18149,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "אלסנאעה"
        },
        {
            "_id": 18150,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלספאא"
        },
        {
            "_id": 18151,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלעבאס"
        },
        {
            "_id": 18152,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלעטאא"
        },
        {
            "_id": 18153,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 18154,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "אלעמאל"
        },
        {
            "_id": 18155,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלעקאד"
        },
        {
            "_id": 18156,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "אלעקבה"
        },
        {
            "_id": 18157,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אלערב"
        },
        {
            "_id": 18158,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אלפוראת"
        },
        {
            "_id": 18159,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלפרוק"
        },
        {
            "_id": 18160,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלפרקאן"
        },
        {
            "_id": 18161,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אלפתח"
        },
        {
            "_id": 18162,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "אלקאהרה"
        },
        {
            "_id": 18163,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלקדסייה"
        },
        {
            "_id": 18164,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלקודס"
        },
        {
            "_id": 18165,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "אלקירואן"
        },
        {
            "_id": 18166,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אלקלעה"
        },
        {
            "_id": 18167,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "אלקסר"
        },
        {
            "_id": 18168,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "אלראיה"
        },
        {
            "_id": 18169,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "אלריבאט"
        },
        {
            "_id": 18170,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 18171,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלרמאן"
        },
        {
            "_id": 18172,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אלשאם"
        },
        {
            "_id": 18173,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלשאפעי"
        },
        {
            "_id": 18174,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלשלאלה"
        },
        {
            "_id": 18175,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "אלשריף"
        },
        {
            "_id": 18176,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "אלת'קאפה"
        },
        {
            "_id": 18177,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלתין"
        },
        {
            "_id": 18178,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אלתעאון"
        },
        {
            "_id": 18179,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלתקוא"
        },
        {
            "_id": 18180,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אם אלקורא"
        },
        {
            "_id": 18181,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "בגדאד"
        },
        {
            "_id": 18182,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "בדר"
        },
        {
            "_id": 18183,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "ביסאן"
        },
        {
            "_id": 18184,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "בית אלמקדס"
        },
        {
            "_id": 18185,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "ג'בל אלזיתון"
        },
        {
            "_id": 18186,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "דג'לה"
        },
        {
            "_id": 18187,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "הארון אלרשיד"
        },
        {
            "_id": 18188,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "זמזם"
        },
        {
            "_id": 18189,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חורה"
        },
        {
            "_id": 18190,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 18191,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 18192,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "חמזה"
        },
        {
            "_id": 18193,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "חסן אלבצרי"
        },
        {
            "_id": 18194,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "טארק בן זיאד"
        },
        {
            "_id": 18195,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 18196,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "יפו"
        },
        {
            "_id": 18197,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "מאלק"
        },
        {
            "_id": 18198,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "מוזדלפה"
        },
        {
            "_id": 18199,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מועד בן ג'בל"
        },
        {
            "_id": 18200,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "מראקש"
        },
        {
            "_id": 18201,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "סלאח אלדין"
        },
        {
            "_id": 18202,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "סלמאן אלפארסי"
        },
        {
            "_id": 18203,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "סעד בן אבי ווקאס"
        },
        {
            "_id": 18204,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "עבדאלה בן רוחה"
        },
        {
            "_id": 18205,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "עומר אל מוח'תאר"
        },
        {
            "_id": 18206,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "עין סארה"
        },
        {
            "_id": 18207,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 18208,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "עסקלאן"
        },
        {
            "_id": 18209,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "ערפה"
        },
        {
            "_id": 18210,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רג'ב"
        },
        {
            "_id": 18211,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6702,
            "שם_רחוב": "שכ 1"
        },
        {
            "_id": 18212,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6716,
            "שם_רחוב": "שכ 11"
        },
        {
            "_id": 18213,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "שכ 2"
        },
        {
            "_id": 18214,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6717,
            "שם_רחוב": "שכ 12"
        },
        {
            "_id": 18215,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6703,
            "שם_רחוב": "שכ 3"
        },
        {
            "_id": 18216,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "שכ 13"
        },
        {
            "_id": 18217,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6704,
            "שם_רחוב": "שכ 4"
        },
        {
            "_id": 18218,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6718,
            "שם_רחוב": "שכ 14"
        },
        {
            "_id": 18219,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6705,
            "שם_רחוב": "שכ 5"
        },
        {
            "_id": 18220,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6715,
            "שם_רחוב": "שכ 15"
        },
        {
            "_id": 18221,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6706,
            "שם_רחוב": "שכ 6"
        },
        {
            "_id": 18222,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6719,
            "שם_רחוב": "שכ 16"
        },
        {
            "_id": 18223,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6707,
            "שם_רחוב": "שכ 7"
        },
        {
            "_id": 18224,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "שכ 17"
        },
        {
            "_id": 18225,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "שכ 8"
        },
        {
            "_id": 18226,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "שכ 18"
        },
        {
            "_id": 18227,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6709,
            "שם_רחוב": "שכ 9"
        },
        {
            "_id": 18228,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "שכ 19"
        },
        {
            "_id": 18229,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 6701,
            "שם_רחוב": "שכ יסמין"
        },
        {
            "_id": 18230,
            "סמל_ישוב": 1303,
            "שם_ישוב": "חורה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "שמס"
        },
        {
            "_id": 18231,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אבו נמלה"
        },
        {
            "_id": 18232,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אלאורג'ואן"
        },
        {
            "_id": 18233,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלאח'ווה"
        },
        {
            "_id": 18234,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אלאחסאן"
        },
        {
            "_id": 18235,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אלאסד"
        },
        {
            "_id": 18236,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלאפראח"
        },
        {
            "_id": 18237,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלאקחוואן"
        },
        {
            "_id": 18238,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלארז"
        },
        {
            "_id": 18239,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלבוסתן"
        },
        {
            "_id": 18240,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אלביאדה"
        },
        {
            "_id": 18241,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 194,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 18242,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אלבירקה"
        },
        {
            "_id": 18243,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 178,
            "שם_רחוב": "אלבלסם"
        },
        {
            "_id": 18244,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלבנפסג'"
        },
        {
            "_id": 18245,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלברקוק"
        },
        {
            "_id": 18246,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אלג'וורדה"
        },
        {
            "_id": 18247,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אלג'ולאן"
        },
        {
            "_id": 18248,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלג'ורי"
        },
        {
            "_id": 18249,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אלג'ליל"
        },
        {
            "_id": 18250,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 197,
            "שם_רחוב": "אלדבשה"
        },
        {
            "_id": 18251,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלדוואלי"
        },
        {
            "_id": 18252,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אלדפלה"
        },
        {
            "_id": 18253,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 18254,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלזנבק'"
        },
        {
            "_id": 18255,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלזעפראן"
        },
        {
            "_id": 18256,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלזערור"
        },
        {
            "_id": 18257,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אלזעתר"
        },
        {
            "_id": 18258,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלח'וזאמה"
        },
        {
            "_id": 18259,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלח'לווה"
        },
        {
            "_id": 18260,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 218,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 18261,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 192,
            "שם_רחוב": "אלחור"
        },
        {
            "_id": 18262,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 193,
            "שם_רחוב": "אלחלאל"
        },
        {
            "_id": 18263,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלחנדור"
        },
        {
            "_id": 18264,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אלטויל"
        },
        {
            "_id": 18265,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 18266,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אלכאוכב"
        },
        {
            "_id": 18267,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אלכתאב"
        },
        {
            "_id": 18268,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אללוטוס"
        },
        {
            "_id": 18269,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלמדרסה"
        },
        {
            "_id": 18270,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אלמוואבר"
        },
        {
            "_id": 18271,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אלמוסתקבל"
        },
        {
            "_id": 18272,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אלמחג'ר"
        },
        {
            "_id": 18273,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אלמטר"
        },
        {
            "_id": 18274,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אלמנארה"
        },
        {
            "_id": 18275,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלמסיל"
        },
        {
            "_id": 18276,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 212,
            "שם_רחוב": "אלמסקה"
        },
        {
            "_id": 18277,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלמע'אריק"
        },
        {
            "_id": 18278,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלמעאסר"
        },
        {
            "_id": 18279,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלמעסרה"
        },
        {
            "_id": 18280,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אלמערפה"
        },
        {
            "_id": 18281,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלמרג'"
        },
        {
            "_id": 18282,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלנביא סבלאן"
        },
        {
            "_id": 18283,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אלנג'אח"
        },
        {
            "_id": 18284,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אלנוורס"
        },
        {
            "_id": 18285,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 18286,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 18287,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלנמר"
        },
        {
            "_id": 18288,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלנסר"
        },
        {
            "_id": 18289,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אלנענע"
        },
        {
            "_id": 18290,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלנעסה"
        },
        {
            "_id": 18291,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 18292,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלסאוסן"
        },
        {
            "_id": 18293,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אלסדאקה"
        },
        {
            "_id": 18294,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אלסוואנה"
        },
        {
            "_id": 18295,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלסנדיאן"
        },
        {
            "_id": 18296,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלסעאדה"
        },
        {
            "_id": 18297,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אלספאי"
        },
        {
            "_id": 18298,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלסקר"
        },
        {
            "_id": 18299,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אלע'אר"
        },
        {
            "_id": 18300,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלע'זאל"
        },
        {
            "_id": 18301,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אלעולום"
        },
        {
            "_id": 18302,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אלעקבאת"
        },
        {
            "_id": 18303,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלעריד"
        },
        {
            "_id": 18304,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלערפאן"
        },
        {
            "_id": 18305,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלפג'ר"
        },
        {
            "_id": 18306,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלקנדול"
        },
        {
            "_id": 18307,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלקרונפל"
        },
        {
            "_id": 18308,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אלקרז"
        },
        {
            "_id": 18309,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלרביע"
        },
        {
            "_id": 18310,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 195,
            "שם_רחוב": "אלרחאל"
        },
        {
            "_id": 18311,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 18312,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אלשוף"
        },
        {
            "_id": 18313,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אלשמס"
        },
        {
            "_id": 18314,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלשקאק"
        },
        {
            "_id": 18315,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אלת'קאפה"
        },
        {
            "_id": 18316,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 183,
            "שם_רחוב": "אלתוראת'"
        },
        {
            "_id": 18317,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 182,
            "שם_רחוב": "באב אלדאר"
        },
        {
            "_id": 18318,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 121,
            "שם_רחוב": "בית אלעיד"
        },
        {
            "_id": 18319,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 150,
            "שם_רחוב": "בריקת עלי"
        },
        {
            "_id": 18320,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 166,
            "שם_רחוב": "ג'בילאת עאמר"
        },
        {
            "_id": 18321,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דרב אלעין"
        },
        {
            "_id": 18322,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 176,
            "שם_רחוב": "דריג'"
        },
        {
            "_id": 18323,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 184,
            "שם_רחוב": "זקאק אלאדיאן"
        },
        {
            "_id": 18324,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 145,
            "שם_רחוב": "ח'לת אבו תלוג'"
        },
        {
            "_id": 18325,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 152,
            "שם_רחוב": "ח'לת אלזיואן"
        },
        {
            "_id": 18326,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 180,
            "שם_רחוב": "ח'לת משחרה"
        },
        {
            "_id": 18327,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 209,
            "שם_רחוב": "חוארתה"
        },
        {
            "_id": 18328,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חורפיש"
        },
        {
            "_id": 18329,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 149,
            "שם_רחוב": "חקול אלח'שבה"
        },
        {
            "_id": 18330,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 123,
            "שם_רחוב": "טריק אלקדומה"
        },
        {
            "_id": 18331,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 196,
            "שם_רחוב": "כסאר שרייף"
        },
        {
            "_id": 18332,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 186,
            "שם_רחוב": "כרום אלשמאלה"
        },
        {
            "_id": 18333,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 133,
            "שם_רחוב": "כרם אלורד"
        },
        {
            "_id": 18334,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 214,
            "שם_רחוב": "כרם אלסומאק"
        },
        {
            "_id": 18335,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 224,
            "שם_רחוב": "כרם אלענב"
        },
        {
            "_id": 18336,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 225,
            "שם_רחוב": "מיאל אלשמס"
        },
        {
            "_id": 18337,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 143,
            "שם_רחוב": "נקארת עבוד"
        },
        {
            "_id": 18338,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 136,
            "שם_רחוב": "סולטאן באשא אלאטרש"
        },
        {
            "_id": 18339,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 116,
            "שם_רחוב": "עאבר חורפיש 89"
        },
        {
            "_id": 18340,
            "סמל_ישוב": 496,
            "שם_ישוב": "חורפיש ",
            "סמל_רחוב": 201,
            "שם_רחוב": "עדאפר"
        },
        {
            "_id": 18341,
            "סמל_ישוב": 355,
            "שם_ישוב": "חורשים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חורשים"
        },
        {
            "_id": 18342,
            "סמל_ישוב": 1047,
            "שם_ישוב": "חזון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חזון"
        },
        {
            "_id": 18343,
            "סמל_ישוב": 219,
            "שם_ישוב": "חיבת ציון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אודים"
        },
        {
            "_id": 18344,
            "סמל_ישוב": 219,
            "שם_ישוב": "חיבת ציון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אל הפלחה"
        },
        {
            "_id": 18345,
            "סמל_ישוב": 219,
            "שם_ישוב": "חיבת ציון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 18346,
            "סמל_ישוב": 219,
            "שם_ישוב": "חיבת ציון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המגשימים"
        },
        {
            "_id": 18347,
            "סמל_ישוב": 219,
            "שם_ישוב": "חיבת ציון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הסילו"
        },
        {
            "_id": 18348,
            "סמל_ישוב": 219,
            "שם_ישוב": "חיבת ציון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 18349,
            "סמל_ישוב": 219,
            "שם_ישוב": "חיבת ציון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חיבת ציון"
        },
        {
            "_id": 18350,
            "סמל_ישוב": 219,
            "שם_ישוב": "חיבת ציון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "נוף השדות"
        },
        {
            "_id": 18351,
            "סמל_ישוב": 219,
            "שם_ישוב": "חיבת ציון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "נוף חפר"
        },
        {
            "_id": 18352,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 18353,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אגס"
        },
        {
            "_id": 18354,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 18355,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 18356,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 18357,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אקליפטוס"
        },
        {
            "_id": 18358,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 18359,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 164,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 18360,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גולני"
        },
        {
            "_id": 18361,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 159,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 18362,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 112,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 18363,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 148,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 18364,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הגנא"
        },
        {
            "_id": 18365,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 151,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 18366,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הטייסים"
        },
        {
            "_id": 18367,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 128,
            "שם_רחוב": "המוסד"
        },
        {
            "_id": 18368,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 150,
            "שם_רחוב": "ורד"
        },
        {
            "_id": 18369,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 154,
            "שם_רחוב": "זהבית"
        },
        {
            "_id": 18370,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 139,
            "שם_רחוב": "זית"
        },
        {
            "_id": 18371,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 117,
            "שם_רחוב": "ח. גבעתי"
        },
        {
            "_id": 18372,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ח. הנחל"
        },
        {
            "_id": 18373,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 122,
            "שם_רחוב": "ח. כפיר"
        },
        {
            "_id": 18374,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 118,
            "שם_רחוב": "ח. צנחנים"
        },
        {
            "_id": 18375,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 143,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 18376,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 120,
            "שם_רחוב": "חטיבה 7"
        },
        {
            "_id": 18377,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חיננית"
        },
        {
            "_id": 18378,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 149,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 18379,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "טל מנשה"
        },
        {
            "_id": 18380,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 108,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 18381,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 165,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 18382,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 134,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 18383,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 146,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 18384,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 162,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 18385,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 152,
            "שם_רחוב": "לימון"
        },
        {
            "_id": 18386,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 126,
            "שם_רחוב": "מגב"
        },
        {
            "_id": 18387,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מגלן"
        },
        {
            "_id": 18388,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 123,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 18389,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 127,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 18390,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 125,
            "שם_רחוב": "מייתר"
        },
        {
            "_id": 18391,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 145,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 18392,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 141,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 18393,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 137,
            "שם_רחוב": "נירית"
        },
        {
            "_id": 18394,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 133,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 18395,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 115,
            "שם_רחוב": "ס.חרוב"
        },
        {
            "_id": 18396,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 129,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 18397,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 155,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 18398,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 163,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 18399,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סיירת מטכל"
        },
        {
            "_id": 18400,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 109,
            "שם_רחוב": "עוקץ"
        },
        {
            "_id": 18401,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 144,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 18402,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 116,
            "שם_רחוב": "פ. שריון"
        },
        {
            "_id": 18403,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 166,
            "שם_רחוב": "פקאן"
        },
        {
            "_id": 18404,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 138,
            "שם_רחוב": "פרג"
        },
        {
            "_id": 18405,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 140,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 18406,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 103,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 18407,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 121,
            "שם_רחוב": "קרקל"
        },
        {
            "_id": 18408,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 18409,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 131,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 18410,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שייטת 13"
        },
        {
            "_id": 18411,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 113,
            "שם_רחוב": "שייטת 7"
        },
        {
            "_id": 18412,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 18413,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 114,
            "שם_רחוב": "שמונה מאתיים"
        },
        {
            "_id": 18414,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 158,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 18415,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 142,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 18416,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 157,
            "שם_רחוב": "תות"
        },
        {
            "_id": 18417,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 160,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 18418,
            "סמל_ישוב": 3643,
            "שם_ישוב": "חיננית ",
            "סמל_רחוב": 153,
            "שם_רחוב": "תפוז"
        },
        {
            "_id": 18419,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 385,
            "שם_רחוב": "א סלט"
        },
        {
            "_id": 18420,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "את מפרץ(צפון-מזרח)"
        },
        {
            "_id": 18421,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "את מפרץ(צפון-מערב)"
        },
        {
            "_id": 18422,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 543,
            "שם_רחוב": "אבדימי"
        },
        {
            "_id": 18423,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 655,
            "שם_רחוב": "אביגיל"
        },
        {
            "_id": 18424,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אביר מרק"
        },
        {
            "_id": 18425,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 437,
            "שם_רחוב": "אבן אל אתיר"
        },
        {
            "_id": 18426,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 805,
            "שם_רחוב": "אבן אל מוקפע"
        },
        {
            "_id": 18427,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 18428,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 863,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 18429,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 849,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 18430,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 870,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 18431,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 522,
            "שם_רחוב": "אבנר"
        },
        {
            "_id": 18432,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אברבנאל"
        },
        {
            "_id": 18433,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1246,
            "שם_רחוב": "אברהם גינזבורג"
        },
        {
            "_id": 18434,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 682,
            "שם_רחוב": "אבשלום"
        },
        {
            "_id": 18435,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1219,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 18436,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 709,
            "שם_רחוב": "אגמון"
        },
        {
            "_id": 18437,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1045,
            "שם_רחוב": "אדם הכהן"
        },
        {
            "_id": 18438,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "אדיסון"
        },
        {
            "_id": 18439,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 778,
            "שם_רחוב": "אדמונד פלג"
        },
        {
            "_id": 18440,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "אדמות יגור"
        },
        {
            "_id": 18441,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 967,
            "שם_רחוב": "אהבת ישראל"
        },
        {
            "_id": 18442,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 911,
            "שם_רחוב": "אהבת ציון"
        },
        {
            "_id": 18443,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 629,
            "שם_רחוב": "אהוד"
        },
        {
            "_id": 18444,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1257,
            "שם_רחוב": "אהוד מנור"
        },
        {
            "_id": 18445,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1186,
            "שם_רחוב": "אהרון (רוזנפלד)"
        },
        {
            "_id": 18446,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אהרונוביץ יוסף"
        },
        {
            "_id": 18447,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 485,
            "שם_רחוב": "אהרונסון"
        },
        {
            "_id": 18448,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 756,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 18449,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 429,
            "שם_רחוב": "אוחנה נסים הרב"
        },
        {
            "_id": 18450,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 656,
            "שם_רחוב": "אוליפנט"
        },
        {
            "_id": 18451,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 347,
            "שם_רחוב": "אומיה"
        },
        {
            "_id": 18452,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1155,
            "שם_רחוב": "אוניברסיטת חיפה"
        },
        {
            "_id": 18453,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 994,
            "שם_רחוב": "אוסטרובסקי ברוך"
        },
        {
            "_id": 18454,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אופיר"
        },
        {
            "_id": 18455,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1505,
            "שם_רחוב": "אור החיים"
        },
        {
            "_id": 18456,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1066,
            "שם_רחוב": "אורבך אליהו"
        },
        {
            "_id": 18457,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 278,
            "שם_רחוב": "אורט"
        },
        {
            "_id": 18458,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1247,
            "שם_רחוב": "אורי וינברג"
        },
        {
            "_id": 18459,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1388,
            "שם_רחוב": "אורלנד יעקב"
        },
        {
            "_id": 18460,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 763,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 18461,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 903,
            "שם_רחוב": "אושה"
        },
        {
            "_id": 18462,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1049,
            "שם_רחוב": "אזר"
        },
        {
            "_id": 18463,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1399,
            "שם_רחוב": "אזולאי יעקב השופט"
        },
        {
            "_id": 18464,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "אזור ביח פלימן"
        },
        {
            "_id": 18465,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "אזור מלאכה ומסחר"
        },
        {
            "_id": 18466,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "אזור שמן"
        },
        {
            "_id": 18467,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "אזור תעשייה"
        },
        {
            "_id": 18468,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אחי אילת"
        },
        {
            "_id": 18469,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 846,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 18470,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 502,
            "שם_רחוב": "אחוה"
        },
        {
            "_id": 18471,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 504,
            "שם_רחוב": "אחז"
        },
        {
            "_id": 18472,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 407,
            "שם_רחוב": "אטוגראי"
        },
        {
            "_id": 18473,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אידלסון בבה"
        },
        {
            "_id": 18474,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 604,
            "שם_רחוב": "אידר"
        },
        {
            "_id": 18475,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 971,
            "שם_רחוב": "איטליה"
        },
        {
            "_id": 18476,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 624,
            "שם_רחוב": "אילון"
        },
        {
            "_id": 18477,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 653,
            "שם_רחוב": "אילות"
        },
        {
            "_id": 18478,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 698,
            "שם_רחוב": "אילנות"
        },
        {
            "_id": 18479,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1023,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 18480,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 883,
            "שם_רחוב": "אימבר נפתלי"
        },
        {
            "_id": 18481,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1041,
            "שם_רחוב": "אינטרנשיונל"
        },
        {
            "_id": 18482,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 608,
            "שם_רחוב": "אינשטיין אלברט"
        },
        {
            "_id": 18483,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1121,
            "שם_רחוב": "איצקוביטש"
        },
        {
            "_id": 18484,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 960,
            "שם_רחוב": "איצקוביץ שמואל"
        },
        {
            "_id": 18485,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 719,
            "שם_רחוב": "איריס"
        },
        {
            "_id": 18486,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 412,
            "שם_רחוב": "אירן"
        },
        {
            "_id": 18487,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 377,
            "שם_רחוב": "איתין יהודה"
        },
        {
            "_id": 18488,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 693,
            "שם_רחוב": "איתנים"
        },
        {
            "_id": 18489,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 501,
            "שם_רחוב": "אל אדריסי"
        },
        {
            "_id": 18490,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 408,
            "שם_רחוב": "אל אספהני"
        },
        {
            "_id": 18491,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 340,
            "שם_רחוב": "אל בוכתורי"
        },
        {
            "_id": 18492,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 804,
            "שם_רחוב": "אל גזאלי"
        },
        {
            "_id": 18493,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 322,
            "שם_רחוב": "אל הילאל"
        },
        {
            "_id": 18494,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 397,
            "שם_רחוב": "אל חרירי"
        },
        {
            "_id": 18495,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1003,
            "שם_רחוב": "אל כנסא"
        },
        {
            "_id": 18496,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 696,
            "שם_רחוב": "אל מוהדי"
        },
        {
            "_id": 18497,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 379,
            "שם_רחוב": "אל מורינה"
        },
        {
            "_id": 18498,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 803,
            "שם_רחוב": "אל מותנבי"
        },
        {
            "_id": 18499,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 441,
            "שם_רחוב": "אל מערי"
        },
        {
            "_id": 18500,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אל מרין"
        },
        {
            "_id": 18501,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 434,
            "שם_רחוב": "אל עריש"
        },
        {
            "_id": 18502,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 390,
            "שם_רחוב": "אל עתיקה"
        },
        {
            "_id": 18503,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 405,
            "שם_רחוב": "אל פראבי"
        },
        {
            "_id": 18504,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1169,
            "שם_רחוב": "אלבז נתן"
        },
        {
            "_id": 18505,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלונים"
        },
        {
            "_id": 18506,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 658,
            "שם_רחוב": "אלחנן יצחק"
        },
        {
            "_id": 18507,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 18508,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 959,
            "שם_רחוב": "אליהו הנביא"
        },
        {
            "_id": 18509,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 549,
            "שם_רחוב": "אלישע"
        },
        {
            "_id": 18510,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 679,
            "שם_רחוב": "אלכסנדר ינאי"
        },
        {
            "_id": 18511,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 574,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 18512,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 996,
            "שם_רחוב": "אלעזר דוד ראל"
        },
        {
            "_id": 18513,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 921,
            "שם_רחוב": "אלפסי"
        },
        {
            "_id": 18514,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 333,
            "שם_רחוב": "אלקלעי הרב"
        },
        {
            "_id": 18515,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1397,
            "שם_רחוב": "אלתר אליעזר"
        },
        {
            "_id": 18516,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 800,
            "שם_רחוב": "אלתרמן נתן"
        },
        {
            "_id": 18517,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 869,
            "שם_רחוב": "אמיר"
        },
        {
            "_id": 18518,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1222,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 18519,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 785,
            "שם_רחוב": "אמציה המלך"
        },
        {
            "_id": 18520,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 422,
            "שם_רחוב": "אנגל יואל"
        },
        {
            "_id": 18521,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 480,
            "שם_רחוב": "אנגל יונה דר"
        },
        {
            "_id": 18522,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 995,
            "שם_רחוב": "אנדה עמיר"
        },
        {
            "_id": 18523,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אנה פרנק"
        },
        {
            "_id": 18524,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 784,
            "שם_רחוב": "אנטוורפן"
        },
        {
            "_id": 18525,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "אני מאמין"
        },
        {
            "_id": 18526,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 414,
            "שם_רחוב": "אנילביץ מרדכי"
        },
        {
            "_id": 18527,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 497,
            "שם_רחוב": "אנצ'ו סירני"
        },
        {
            "_id": 18528,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 579,
            "שם_רחוב": "אסא המלך"
        },
        {
            "_id": 18529,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 454,
            "שם_רחוב": "אסף דוד הרב"
        },
        {
            "_id": 18530,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1503,
            "שם_רחוב": "אספיס אברהם"
        },
        {
            "_id": 18531,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 600,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 18532,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 592,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 18533,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 648,
            "שם_רחוב": "ארגמן"
        },
        {
            "_id": 18534,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1179,
            "שם_רחוב": "ארזי יהודה"
        },
        {
            "_id": 18535,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1390,
            "שם_רחוב": "אריה (לובה) אליאב"
        },
        {
            "_id": 18536,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 823,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 18537,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1235,
            "שם_רחוב": "ארליך דוד פרופ'"
        },
        {
            "_id": 18538,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 438,
            "שם_רחוב": "ארמן נחום"
        },
        {
            "_id": 18539,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "ארן זלמן"
        },
        {
            "_id": 18540,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 948,
            "שם_רחוב": "ארנון"
        },
        {
            "_id": 18541,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "ארץ הצבי"
        },
        {
            "_id": 18542,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 705,
            "שם_רחוב": "אשכולות"
        },
        {
            "_id": 18543,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1024,
            "שם_רחוב": "אשר"
        },
        {
            "_id": 18544,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1249,
            "שם_רחוב": "אשר עלמני"
        },
        {
            "_id": 18545,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 791,
            "שם_רחוב": "באבל יצחק"
        },
        {
            "_id": 18546,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 295,
            "שם_רחוב": "בהרב יקותיאל"
        },
        {
            "_id": 18547,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1167,
            "שם_רחוב": "בובר מרטין"
        },
        {
            "_id": 18548,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 787,
            "שם_רחוב": "בודנהיימר מקס דר"
        },
        {
            "_id": 18549,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "בונה אליעזר"
        },
        {
            "_id": 18550,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "בוסל יוסף"
        },
        {
            "_id": 18551,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 673,
            "שם_רחוב": "בועז"
        },
        {
            "_id": 18552,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1037,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 18553,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1096,
            "שם_רחוב": "בורלא יהודה"
        },
        {
            "_id": 18554,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 864,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 18555,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 418,
            "שם_רחוב": "ביילהארץ פרידריך"
        },
        {
            "_id": 18556,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 594,
            "שם_רחוב": "בייליס מנחם מנדל"
        },
        {
            "_id": 18557,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 643,
            "שם_רחוב": "ביכורים"
        },
        {
            "_id": 18558,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 18559,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "בילינסון"
        },
        {
            "_id": 18560,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 344,
            "שם_רחוב": "ביר אל יזק"
        },
        {
            "_id": 18561,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 795,
            "שם_רחוב": "בירם דר"
        },
        {
            "_id": 18562,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 727,
            "שם_רחוב": "בית אל"
        },
        {
            "_id": 18563,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "בית אלפא"
        },
        {
            "_id": 18564,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 741,
            "שם_רחוב": "בית לחם"
        },
        {
            "_id": 18565,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "בית עולים בת גלים"
        },
        {
            "_id": 18566,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 332,
            "שם_רחוב": "בית שאן"
        },
        {
            "_id": 18567,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 934,
            "שם_רחוב": "ביתר"
        },
        {
            "_id": 18568,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 281,
            "שם_רחוב": "בל אלכסנדר גרהם"
        },
        {
            "_id": 18569,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 519,
            "שם_רחוב": "בלהה"
        },
        {
            "_id": 18570,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 575,
            "שם_רחוב": "בלוך ארנסט"
        },
        {
            "_id": 18571,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 958,
            "שם_רחוב": "בלום לאון"
        },
        {
            "_id": 18572,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1218,
            "שם_רחוב": "בליטנטל משה"
        },
        {
            "_id": 18573,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 825,
            "שם_רחוב": "בלפור"
        },
        {
            "_id": 18574,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1055,
            "שם_רחוב": "במסילה"
        },
        {
            "_id": 18575,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1058,
            "שם_רחוב": "בן ארצי"
        },
        {
            "_id": 18576,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 936,
            "שם_רחוב": "בן בבא"
        },
        {
            "_id": 18577,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 966,
            "שם_רחוב": "בן הרוש דוד"
        },
        {
            "_id": 18578,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 920,
            "שם_רחוב": "בן זכאי"
        },
        {
            "_id": 18579,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 838,
            "שם_רחוב": "בן יהודה"
        },
        {
            "_id": 18580,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 18581,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 468,
            "שם_רחוב": "בן יעקב צבי"
        },
        {
            "_id": 18582,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "בן עדיה שמואל"
        },
        {
            "_id": 18583,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "בן עמי"
        },
        {
            "_id": 18584,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "בן צבי יצחק"
        },
        {
            "_id": 18585,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 980,
            "שם_רחוב": "בן צור שמואל"
        },
        {
            "_id": 18586,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1054,
            "שם_רחוב": "בן ציון שמחה"
        },
        {
            "_id": 18587,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1089,
            "שם_רחוב": "בן שבת שמואל"
        },
        {
            "_id": 18588,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 842,
            "שם_רחוב": "בן שמן"
        },
        {
            "_id": 18589,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 470,
            "שם_רחוב": "בני ברית"
        },
        {
            "_id": 18590,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1199,
            "שם_רחוב": "בנימין זאב בנדיקט"
        },
        {
            "_id": 18591,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1094,
            "שם_רחוב": "בנין זאב"
        },
        {
            "_id": 18592,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "בעל התניא"
        },
        {
            "_id": 18593,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 913,
            "שם_רחוב": "בעל שם טוב"
        },
        {
            "_id": 18594,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "בעלי מלאכה"
        },
        {
            "_id": 18595,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 933,
            "שם_רחוב": "בצלאל"
        },
        {
            "_id": 18596,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 411,
            "שם_רחוב": "בצרה"
        },
        {
            "_id": 18597,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "בר אילן"
        },
        {
            "_id": 18598,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 822,
            "שם_רחוב": "בר גיורא"
        },
        {
            "_id": 18599,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "בר יהודה ישראל"
        },
        {
            "_id": 18600,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 979,
            "שם_רחוב": "בר יוחאי"
        },
        {
            "_id": 18601,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 935,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 18602,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 569,
            "שם_רחוב": "בר רב האי דוד"
        },
        {
            "_id": 18603,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1144,
            "שם_רחוב": "ברגמן יעקב"
        },
        {
            "_id": 18604,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "ברדיצב אבא"
        },
        {
            "_id": 18605,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "ברודסקי זאב"
        },
        {
            "_id": 18606,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 858,
            "שם_רחוב": "ברוואלד"
        },
        {
            "_id": 18607,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 446,
            "שם_רחוב": "ברון הירש"
        },
        {
            "_id": 18608,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 888,
            "שם_רחוב": "ברוריה"
        },
        {
            "_id": 18609,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 943,
            "שם_רחוב": "ברזילי"
        },
        {
            "_id": 18610,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1180,
            "שם_רחוב": "ברכת משה"
        },
        {
            "_id": 18611,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1051,
            "שם_רחוב": "ברל כצנלסון"
        },
        {
            "_id": 18612,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1001,
            "שם_רחוב": "ברלב חיים"
        },
        {
            "_id": 18613,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 717,
            "שם_רחוב": "ברנדייס"
        },
        {
            "_id": 18614,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 770,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 18615,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 491,
            "שם_רחוב": "ברנשטיין פרץ"
        },
        {
            "_id": 18616,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 18617,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1079,
            "שם_רחוב": "בשור"
        },
        {
            "_id": 18618,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1410,
            "שם_רחוב": "בת חן"
        },
        {
            "_id": 18619,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "בתי הזיקוק"
        },
        {
            "_id": 18620,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 324,
            "שם_רחוב": "ג'ובראן סלים"
        },
        {
            "_id": 18621,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 639,
            "שם_רחוב": "ג'ורג' אליוט"
        },
        {
            "_id": 18622,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 388,
            "שם_רחוב": "ג'למה"
        },
        {
            "_id": 18623,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 919,
            "שם_רחוב": "גאולה"
        },
        {
            "_id": 18624,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 495,
            "שם_רחוב": "גאולי תימן"
        },
        {
            "_id": 18625,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 630,
            "שם_רחוב": "גבעון"
        },
        {
            "_id": 18626,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 605,
            "שם_רחוב": "גבעת דאונס"
        },
        {
            "_id": 18627,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "גבעת זמר"
        },
        {
            "_id": 18628,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 384,
            "שם_רחוב": "גדוד 22"
        },
        {
            "_id": 18629,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1077,
            "שם_רחוב": "גדליהו"
        },
        {
            "_id": 18630,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 514,
            "שם_רחוב": "גדנע"
        },
        {
            "_id": 18631,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 839,
            "שם_רחוב": "גדעון"
        },
        {
            "_id": 18632,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 782,
            "שם_רחוב": "גולדברג לאה"
        },
        {
            "_id": 18633,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1466,
            "שם_רחוב": "גולדמן נחום"
        },
        {
            "_id": 18634,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "גולדפאדן אברהם"
        },
        {
            "_id": 18635,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1084,
            "שם_רחוב": "גולן שמחה"
        },
        {
            "_id": 18636,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 993,
            "שם_רחוב": "גור יהודה"
        },
        {
            "_id": 18637,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1036,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 18638,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "גורדוניה"
        },
        {
            "_id": 18639,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1506,
            "שם_רחוב": "גורן שלמה הרב"
        },
        {
            "_id": 18640,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 884,
            "שם_רחוב": "גוש חלב"
        },
        {
            "_id": 18641,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1004,
            "שם_רחוב": "גוש עציון"
        },
        {
            "_id": 18642,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "גזאל"
        },
        {
            "_id": 18643,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 496,
            "שם_רחוב": "גיבורי גיטו ורשה"
        },
        {
            "_id": 18644,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 351,
            "שם_רחוב": "גירני"
        },
        {
            "_id": 18645,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 697,
            "שם_רחוב": "גלאל אל דין"
        },
        {
            "_id": 18646,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1033,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 18647,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 675,
            "שם_רחוב": "גלגל"
        },
        {
            "_id": 18648,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1387,
            "שם_רחוב": "גלוברמן יהושע"
        },
        {
            "_id": 18649,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 461,
            "שם_רחוב": "גן אלי כהן"
        },
        {
            "_id": 18650,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1016,
            "שם_רחוב": "גן הבהאים"
        },
        {
            "_id": 18651,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1056,
            "שם_רחוב": "גנסין"
        },
        {
            "_id": 18652,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1504,
            "שם_רחוב": "גץ יצחק"
        },
        {
            "_id": 18653,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 762,
            "שם_רחוב": "גרינבוים יצחק"
        },
        {
            "_id": 18654,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 469,
            "שם_רחוב": "גרינברג אורי צבי"
        },
        {
            "_id": 18655,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 474,
            "שם_רחוב": "גרינשפון הרצל"
        },
        {
            "_id": 18656,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1139,
            "שם_רחוב": "גרנד קניון"
        },
        {
            "_id": 18657,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 4059,
            "שם_רחוב": "גשר פז"
        },
        {
            "_id": 18658,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 613,
            "שם_רחוב": "גת"
        },
        {
            "_id": 18659,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 500,
            "שם_רחוב": "דאהר אל עמר"
        },
        {
            "_id": 18660,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 889,
            "שם_רחוב": "דבורה"
        },
        {
            "_id": 18661,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1092,
            "שם_רחוב": "דובנוב"
        },
        {
            "_id": 18662,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1140,
            "שם_רחוב": "דובר שלום"
        },
        {
            "_id": 18663,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 373,
            "שם_רחוב": "דוברין נחום"
        },
        {
            "_id": 18664,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 507,
            "שם_רחוב": "דוגית"
        },
        {
            "_id": 18665,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 559,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 18666,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1413,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 18667,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1002,
            "שם_רחוב": "דולצ'ין אריה"
        },
        {
            "_id": 18668,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 758,
            "שם_רחוב": "דונה גרציה"
        },
        {
            "_id": 18669,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1160,
            "שם_רחוב": "דוסטרובסקי אליעזר"
        },
        {
            "_id": 18670,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1039,
            "שם_רחוב": "דורות"
        },
        {
            "_id": 18671,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1099,
            "שם_רחוב": "דורי יעקב ראל"
        },
        {
            "_id": 18672,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 622,
            "שם_רחוב": "דישראלי"
        },
        {
            "_id": 18673,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 457,
            "שם_רחוב": "דנה יוסף הרב"
        },
        {
            "_id": 18674,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 862,
            "שם_רחוב": "דניאל"
        },
        {
            "_id": 18675,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 768,
            "שם_רחוב": "דניה"
        },
        {
            "_id": 18676,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "דנינו אברהם"
        },
        {
            "_id": 18677,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 723,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 18678,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 510,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 18679,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 732,
            "שם_רחוב": "דרייפוס"
        },
        {
            "_id": 18680,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 512,
            "שם_רחוב": "דרך אלנבי"
        },
        {
            "_id": 18681,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1242,
            "שם_רחוב": "דרך אריאל שרון"
        },
        {
            "_id": 18682,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 288,
            "שם_רחוב": "דרך דיין משה"
        },
        {
            "_id": 18683,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 690,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 18684,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "דרך העמקים"
        },
        {
            "_id": 18685,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 348,
            "שם_רחוב": "דרך העצמאות"
        },
        {
            "_id": 18686,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1035,
            "שם_רחוב": "דרך חנקין"
        },
        {
            "_id": 18687,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1012,
            "שם_רחוב": "דרך יד לבנים"
        },
        {
            "_id": 18688,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 367,
            "שם_רחוב": "דרך ידין יגאל"
        },
        {
            "_id": 18689,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 354,
            "שם_רחוב": "דרך יפו"
        },
        {
            "_id": 18690,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 603,
            "שם_רחוב": "דרך כרמל משה"
        },
        {
            "_id": 18691,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1250,
            "שם_רחוב": "דרך נחל הגיבורים"
        },
        {
            "_id": 18692,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 801,
            "שם_רחוב": "דרך סטלה מריס"
        },
        {
            "_id": 18693,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "דרך צייזל ירוחם"
        },
        {
            "_id": 18694,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 554,
            "שם_רחוב": "דרך צרפת"
        },
        {
            "_id": 18695,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 473,
            "שם_רחוב": "דרך רודיק שמעון"
        },
        {
            "_id": 18696,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 662,
            "שם_רחוב": "דרך רופין"
        },
        {
            "_id": 18697,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "האבות"
        },
        {
            "_id": 18698,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 751,
            "שם_רחוב": "האג"
        },
        {
            "_id": 18699,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 963,
            "שם_רחוב": "האומן"
        },
        {
            "_id": 18700,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 364,
            "שם_רחוב": "האיטלקים"
        },
        {
            "_id": 18701,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 985,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 18702,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 595,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 18703,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "האמהות"
        },
        {
            "_id": 18704,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "האמונה"
        },
        {
            "_id": 18705,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "האמוראים"
        },
        {
            "_id": 18706,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 917,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 18707,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "האצטדיון"
        },
        {
            "_id": 18708,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1101,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 18709,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 18710,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 984,
            "שם_רחוב": "האשחר"
        },
        {
            "_id": 18711,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 18712,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "האשלג"
        },
        {
            "_id": 18713,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "הבונים החפשיים"
        },
        {
            "_id": 18714,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1251,
            "שם_רחוב": "הבישוף חג'אר"
        },
        {
            "_id": 18715,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "הבניה"
        },
        {
            "_id": 18716,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1021,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 18717,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 355,
            "שם_רחוב": "הבנקים"
        },
        {
            "_id": 18718,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 555,
            "שם_רחוב": "הבסטיליה"
        },
        {
            "_id": 18719,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1029,
            "שם_רחוב": "הבריכה"
        },
        {
            "_id": 18720,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "הבשן"
        },
        {
            "_id": 18721,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הגאונים"
        },
        {
            "_id": 18722,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "הגבורים"
        },
        {
            "_id": 18723,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 292,
            "שם_רחוב": "הגביש"
        },
        {
            "_id": 18724,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 18725,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 476,
            "שם_רחוב": "הגומא"
        },
        {
            "_id": 18726,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1020,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 18727,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 415,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 18728,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 413,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 18729,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "הדגן"
        },
        {
            "_id": 18730,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 783,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 18731,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 285,
            "שם_רחוב": "הדייגים"
        },
        {
            "_id": 18732,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1206,
            "שם_רחוב": "הדסה"
        },
        {
            "_id": 18733,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 706,
            "שם_רחוב": "הדסים"
        },
        {
            "_id": 18734,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "הדף היומי"
        },
        {
            "_id": 18735,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 873,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 18736,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6618,
            "שם_רחוב": "הדר הכרמל"
        },
        {
            "_id": 18737,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "הדשנים"
        },
        {
            "_id": 18738,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 552,
            "שם_רחוב": "ההגה"
        },
        {
            "_id": 18739,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 393,
            "שם_רחוב": "הואדי"
        },
        {
            "_id": 18740,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1207,
            "שם_רחוב": "הוברמן ברוניסלב"
        },
        {
            "_id": 18741,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 2675,
            "שם_רחוב": "הוד"
        },
        {
            "_id": 18742,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 766,
            "שם_רחוב": "הויסמנס קמיל"
        },
        {
            "_id": 18743,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 755,
            "שם_רחוב": "הולנד"
        },
        {
            "_id": 18744,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 749,
            "שם_רחוב": "הולצברג שמחה"
        },
        {
            "_id": 18745,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 685,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 18746,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "הותיקים"
        },
        {
            "_id": 18747,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1216,
            "שם_רחוב": "הזז חיים"
        },
        {
            "_id": 18748,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 523,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 18749,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 618,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 18750,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 18751,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1189,
            "שם_רחוב": "החוף הדרומי"
        },
        {
            "_id": 18752,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 511,
            "שם_רחוב": "החותרים"
        },
        {
            "_id": 18753,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 528,
            "שם_רחוב": "החיל"
        },
        {
            "_id": 18754,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1502,
            "שם_רחוב": "החידא"
        },
        {
            "_id": 18755,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 296,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 18756,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 987,
            "שם_רחוב": "החיננית"
        },
        {
            "_id": 18757,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 859,
            "שם_רחוב": "החלוץ"
        },
        {
            "_id": 18758,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "החרושת"
        },
        {
            "_id": 18759,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 294,
            "שם_רחוב": "החרמש"
        },
        {
            "_id": 18760,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 18761,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 815,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 18762,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1015,
            "שם_רחוב": "החשמל"
        },
        {
            "_id": 18763,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 432,
            "שם_רחוב": "הטחנות הגדולות"
        },
        {
            "_id": 18764,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 659,
            "שם_רחוב": "הטנא"
        },
        {
            "_id": 18765,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 743,
            "שם_רחוב": "היובל"
        },
        {
            "_id": 18766,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 286,
            "שם_רחוב": "היוצק"
        },
        {
            "_id": 18767,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "הים התיכון"
        },
        {
            "_id": 18768,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 456,
            "שם_רחוב": "הינדס מתתיהו"
        },
        {
            "_id": 18769,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "היצירה"
        },
        {
            "_id": 18770,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1008,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 18771,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 420,
            "שם_רחוב": "הירוק"
        },
        {
            "_id": 18772,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "הירמוך"
        },
        {
            "_id": 18773,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 667,
            "שם_רחוב": "הירקון"
        },
        {
            "_id": 18774,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 530,
            "שם_רחוב": "הכג"
        },
        {
            "_id": 18775,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 346,
            "שם_רחוב": "הכנסיות"
        },
        {
            "_id": 18776,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1065,
            "שם_רחוב": "הכשרת היישוב"
        },
        {
            "_id": 18777,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 983,
            "שם_רחוב": "הליבנה"
        },
        {
            "_id": 18778,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1133,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 18779,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 810,
            "שם_רחוב": "הלל"
        },
        {
            "_id": 18780,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1227,
            "שם_רחוב": "הללויה"
        },
        {
            "_id": 18781,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 565,
            "שם_רחוב": "הלפרן ירמיהו"
        },
        {
            "_id": 18782,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1182,
            "שם_רחוב": "המאירי אביגדור"
        },
        {
            "_id": 18783,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "המולדת"
        },
        {
            "_id": 18784,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "המוסכים"
        },
        {
            "_id": 18785,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1086,
            "שם_רחוב": "המחנך נפחא"
        },
        {
            "_id": 18786,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 646,
            "שם_רחוב": "המים"
        },
        {
            "_id": 18787,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 922,
            "שם_רחוב": "המימוני"
        },
        {
            "_id": 18788,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 428,
            "שם_רחוב": "המכס"
        },
        {
            "_id": 18789,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 448,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 18790,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 18791,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "המסורת"
        },
        {
            "_id": 18792,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 2511,
            "שם_רחוב": "המסילה"
        },
        {
            "_id": 18793,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 345,
            "שם_רחוב": "המעלות"
        },
        {
            "_id": 18794,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 508,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 18795,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 686,
            "שם_רחוב": "המערות"
        },
        {
            "_id": 18796,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "המפוח"
        },
        {
            "_id": 18797,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 999,
            "שם_רחוב": "המפרי יוברט"
        },
        {
            "_id": 18798,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 376,
            "שם_רחוב": "המרוניטים"
        },
        {
            "_id": 18799,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 293,
            "שם_רחוב": "המשביר"
        },
        {
            "_id": 18800,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 425,
            "שם_רחוב": "המשטרה"
        },
        {
            "_id": 18801,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 845,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 18802,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 950,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 18803,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 382,
            "שם_רחוב": "הנגיד"
        },
        {
            "_id": 18804,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "הנוטר"
        },
        {
            "_id": 18805,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 479,
            "שם_רחוב": "הנופר"
        },
        {
            "_id": 18806,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 607,
            "שם_רחוב": "הנטקה"
        },
        {
            "_id": 18807,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "הנמל"
        },
        {
            "_id": 18808,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "הנפח"
        },
        {
            "_id": 18809,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "הנציב"
        },
        {
            "_id": 18810,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 704,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 18811,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 715,
            "שם_רחוב": "הנשר"
        },
        {
            "_id": 18812,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 818,
            "שם_רחוב": "הס"
        },
        {
            "_id": 18813,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 817,
            "שם_רחוב": "הסביונים"
        },
        {
            "_id": 18814,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 289,
            "שם_רחוב": "הסדנה"
        },
        {
            "_id": 18815,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "הסולל"
        },
        {
            "_id": 18816,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1061,
            "שם_רחוב": "הסחלב"
        },
        {
            "_id": 18817,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 701,
            "שם_רחוב": "הסנה"
        },
        {
            "_id": 18818,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "הסנונית"
        },
        {
            "_id": 18819,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 642,
            "שם_רחוב": "הספורט"
        },
        {
            "_id": 18820,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 427,
            "שם_רחוב": "הסראיה"
        },
        {
            "_id": 18821,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 18822,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 553,
            "שם_רחוב": "העוגן"
        },
        {
            "_id": 18823,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6603,
            "שם_רחוב": "העיר התחתית"
        },
        {
            "_id": 18824,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 526,
            "שם_רחוב": "העליה השניה"
        },
        {
            "_id": 18825,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "העמלים"
        },
        {
            "_id": 18826,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 824,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 18827,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1178,
            "שם_רחוב": "הערמונים"
        },
        {
            "_id": 18828,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "העתיד"
        },
        {
            "_id": 18829,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "הפוסקים"
        },
        {
            "_id": 18830,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 905,
            "שם_רחוב": "הפועל"
        },
        {
            "_id": 18831,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "הפלוגות"
        },
        {
            "_id": 18832,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 287,
            "שם_רחוב": "הפלס"
        },
        {
            "_id": 18833,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 793,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 18834,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 513,
            "שם_רחוב": "הפרטיזנים"
        },
        {
            "_id": 18835,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1214,
            "שם_רחוב": "הפרסה"
        },
        {
            "_id": 18836,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 409,
            "שם_רחוב": "הפרסים"
        },
        {
            "_id": 18837,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1414,
            "שם_רחוב": "הפשוש"
        },
        {
            "_id": 18838,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1142,
            "שם_רחוב": "הצדף"
        },
        {
            "_id": 18839,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1168,
            "שם_רחוב": "הצוללת דקר"
        },
        {
            "_id": 18840,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 688,
            "שם_רחוב": "הצופים"
        },
        {
            "_id": 18841,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 812,
            "שם_רחוב": "הצלבנים"
        },
        {
            "_id": 18842,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 633,
            "שם_רחוב": "הצלפים"
        },
        {
            "_id": 18843,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1072,
            "שם_רחוב": "הצפירה"
        },
        {
            "_id": 18844,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "הקבוצים"
        },
        {
            "_id": 18845,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הקונגרס"
        },
        {
            "_id": 18846,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "הקורנס"
        },
        {
            "_id": 18847,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 323,
            "שם_רחוב": "הקטר"
        },
        {
            "_id": 18848,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "הקיטור"
        },
        {
            "_id": 18849,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "הקישון"
        },
        {
            "_id": 18850,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 527,
            "שם_רחוב": "הקלעים"
        },
        {
            "_id": 18851,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 797,
            "שם_רחוב": "הקשת"
        },
        {
            "_id": 18852,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 772,
            "שם_רחוב": "הראל"
        },
        {
            "_id": 18853,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 18854,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 988,
            "שם_רחוב": "הרב אנקואה רפאל"
        },
        {
            "_id": 18855,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 886,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 18856,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6715,
            "שם_רחוב": "הרב שאר ישוב כהן"
        },
        {
            "_id": 18857,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 699,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 18858,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "הרון אל רשיד"
        },
        {
            "_id": 18859,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 610,
            "שם_רחוב": "הרופא"
        },
        {
            "_id": 18860,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 986,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 18861,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "הריף"
        },
        {
            "_id": 18862,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 443,
            "שם_רחוב": "הרמן צבי"
        },
        {
            "_id": 18863,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 279,
            "שם_רחוב": "הרץ היינריך"
        },
        {
            "_id": 18864,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 856,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 18865,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 850,
            "שם_רחוב": "הרצליה"
        },
        {
            "_id": 18866,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "הרצפלד אברהם"
        },
        {
            "_id": 18867,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 703,
            "שם_רחוב": "הרקפות"
        },
        {
            "_id": 18868,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 18869,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 572,
            "שם_רחוב": "השופט שלום קסאן"
        },
        {
            "_id": 18870,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 544,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 18871,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 830,
            "שם_רחוב": "השחר"
        },
        {
            "_id": 18872,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "השיירה"
        },
        {
            "_id": 18873,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "השיש"
        },
        {
            "_id": 18874,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1075,
            "שם_רחוב": "השל יהושע פרופ'"
        },
        {
            "_id": 18875,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 931,
            "שם_רחוב": "השלוח"
        },
        {
            "_id": 18876,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 837,
            "שם_רחוב": "השלום"
        },
        {
            "_id": 18877,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 18878,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 538,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 18879,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 18880,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 957,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 18881,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 683,
            "שם_רחוב": "התור"
        },
        {
            "_id": 18882,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 551,
            "שם_רחוב": "התורן"
        },
        {
            "_id": 18883,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1025,
            "שם_רחוב": "התיכון"
        },
        {
            "_id": 18884,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 754,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 18885,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "התנאים"
        },
        {
            "_id": 18886,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 503,
            "שם_רחוב": "התקוה"
        },
        {
            "_id": 18887,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "התקומה"
        },
        {
            "_id": 18888,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 718,
            "שם_רחוב": "התשבי"
        },
        {
            "_id": 18889,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1000,
            "שם_רחוב": "התשעה"
        },
        {
            "_id": 18890,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 383,
            "שם_רחוב": "התשעים ושלוש"
        },
        {
            "_id": 18891,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "ואדי סליב"
        },
        {
            "_id": 18892,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "ואדי רושמיה"
        },
        {
            "_id": 18893,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 636,
            "שם_רחוב": "וודג'ווד"
        },
        {
            "_id": 18894,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 283,
            "שם_רחוב": "ווט ג'יימס"
        },
        {
            "_id": 18895,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1105,
            "שם_רחוב": "ווטסון ליונל"
        },
        {
            "_id": 18896,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 644,
            "שם_רחוב": "וולפסון"
        },
        {
            "_id": 18897,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 961,
            "שם_רחוב": "וייז סטפן"
        },
        {
            "_id": 18898,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 975,
            "שם_רחוב": "ויינשל אברהם דר"
        },
        {
            "_id": 18899,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "וילבוש נחום"
        },
        {
            "_id": 18900,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1392,
            "שם_רחוב": "וילנאי זאב"
        },
        {
            "_id": 18901,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 831,
            "שם_רחוב": "וילנסקי"
        },
        {
            "_id": 18902,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1106,
            "שם_רחוב": "וינר יוסף"
        },
        {
            "_id": 18903,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1202,
            "שם_רחוב": "ויצו"
        },
        {
            "_id": 18904,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 734,
            "שם_רחוב": "ויקטור הוגו"
        },
        {
            "_id": 18905,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 611,
            "שם_רחוב": "ויתקין יוסף"
        },
        {
            "_id": 18906,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1450,
            "שם_רחוב": "ולנברג ראול"
        },
        {
            "_id": 18907,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 657,
            "שם_רחוב": "ורדיה"
        },
        {
            "_id": 18908,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 556,
            "שם_רחוב": "ז'ורס"
        },
        {
            "_id": 18909,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 738,
            "שם_רחוב": "זאב (זבוטינסקי)"
        },
        {
            "_id": 18910,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1124,
            "שם_רחוב": "זאב הים"
        },
        {
            "_id": 18911,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1134,
            "שם_רחוב": "זאב פרוש"
        },
        {
            "_id": 18912,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 941,
            "שם_רחוב": "זבולון"
        },
        {
            "_id": 18913,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 2674,
            "שם_רחוב": "זוהר"
        },
        {
            "_id": 18914,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 733,
            "שם_רחוב": "זולא אמיל"
        },
        {
            "_id": 18915,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1141,
            "שם_רחוב": "זורע מאיר (זרו)"
        },
        {
            "_id": 18916,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 707,
            "שם_רחוב": "זיוונית"
        },
        {
            "_id": 18917,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "זייד אלכסנדר"
        },
        {
            "_id": 18918,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 515,
            "שם_רחוב": "זילפה"
        },
        {
            "_id": 18919,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1252,
            "שם_רחוב": "זימנא נחום דר"
        },
        {
            "_id": 18920,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1125,
            "שם_רחוב": "זינגר מנדל"
        },
        {
            "_id": 18921,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 426,
            "שם_רחוב": "זיסו א ל"
        },
        {
            "_id": 18922,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1009,
            "שם_רחוב": "זמכשרי"
        },
        {
            "_id": 18923,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 775,
            "שם_רחוב": "זמנהוף"
        },
        {
            "_id": 18924,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 663,
            "שם_רחוב": "זרובבל"
        },
        {
            "_id": 18925,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1507,
            "שם_רחוב": "חבד"
        },
        {
            "_id": 18926,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 969,
            "שם_רחוב": "חבובה אליהו"
        },
        {
            "_id": 18927,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1040,
            "שם_רחוב": "חביבה רייך"
        },
        {
            "_id": 18928,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 976,
            "שם_רחוב": "חבס הכוהן ברכה"
        },
        {
            "_id": 18929,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1183,
            "שם_רחוב": "חבקוק"
        },
        {
            "_id": 18930,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 840,
            "שם_רחוב": "חברון"
        },
        {
            "_id": 18931,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 398,
            "שם_רחוב": "חדאד"
        },
        {
            "_id": 18932,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 945,
            "שם_רחוב": "חדרה"
        },
        {
            "_id": 18933,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1412,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 18934,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1060,
            "שם_רחוב": "חולדה"
        },
        {
            "_id": 18935,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "חומה ומגדל"
        },
        {
            "_id": 18936,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1010,
            "שם_רחוב": "חוסן"
        },
        {
            "_id": 18937,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1188,
            "שם_רחוב": "חוף בת גלים"
        },
        {
            "_id": 18938,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 997,
            "שם_רחוב": "חוף דדו"
        },
        {
            "_id": 18939,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 591,
            "שם_רחוב": "חוף הכרמל"
        },
        {
            "_id": 18940,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 779,
            "שם_רחוב": "חוף השנהב"
        },
        {
            "_id": 18941,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1187,
            "שם_רחוב": "חוף זמיר"
        },
        {
            "_id": 18942,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 400,
            "שם_רחוב": "חוף קרית חיים"
        },
        {
            "_id": 18943,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1210,
            "שם_רחוב": "חוצות המפרץ"
        },
        {
            "_id": 18944,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 601,
            "שם_רחוב": "חורב"
        },
        {
            "_id": 18945,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 721,
            "שם_רחוב": "חורשה"
        },
        {
            "_id": 18946,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1264,
            "שם_רחוב": "חושן"
        },
        {
            "_id": 18947,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1005,
            "שם_רחוב": "חזן יעקב"
        },
        {
            "_id": 18948,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 582,
            "שם_רחוב": "חזקיהו המלך"
        },
        {
            "_id": 18949,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 18950,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1093,
            "שם_רחוב": "חטיבת כרמלי"
        },
        {
            "_id": 18951,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 799,
            "שם_רחוב": "חטיבת עודד"
        },
        {
            "_id": 18952,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1267,
            "שם_רחוב": "חיה תומא"
        },
        {
            "_id": 18953,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 990,
            "שם_רחוב": "חיות פרץ"
        },
        {
            "_id": 18954,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 895,
            "שם_רחוב": "חיים"
        },
        {
            "_id": 18955,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1262,
            "שם_רחוב": "חיים קסטן"
        },
        {
            "_id": 18956,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 537,
            "שם_רחוב": "חיל הים"
        },
        {
            "_id": 18957,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 18958,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "חירם"
        },
        {
            "_id": 18959,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 596,
            "שם_רחוב": "חכים אליהו"
        },
        {
            "_id": 18960,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "חכמי ישראל"
        },
        {
            "_id": 18961,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "חלוצי התעשיה"
        },
        {
            "_id": 18962,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1062,
            "שם_רחוב": "חליסה רח א"
        },
        {
            "_id": 18963,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1224,
            "שם_רחוב": "חלמיש"
        },
        {
            "_id": 18964,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "חלץ"
        },
        {
            "_id": 18965,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 341,
            "שם_רחוב": "חמאם אל פשה"
        },
        {
            "_id": 18966,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 2678,
            "שם_רחוב": "חמדה"
        },
        {
            "_id": 18967,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 680,
            "שם_רחוב": "חנה"
        },
        {
            "_id": 18968,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1022,
            "שם_רחוב": "חניתה"
        },
        {
            "_id": 18969,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1396,
            "שם_רחוב": "חסידי אומות העולם"
        },
        {
            "_id": 18970,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 865,
            "שם_רחוב": "חסן שוקרי"
        },
        {
            "_id": 18971,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "חפצי בה"
        },
        {
            "_id": 18972,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1389,
            "שם_רחוב": "חצוצרה בוואדי"
        },
        {
            "_id": 18973,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6580,
            "שם_רחוב": "חצרות כרמל"
        },
        {
            "_id": 18974,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "חרב לאת"
        },
        {
            "_id": 18975,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 711,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 18976,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 932,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 18977,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 380,
            "שם_רחוב": "חשבון"
        },
        {
            "_id": 18978,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 464,
            "שם_רחוב": "חת נחום"
        },
        {
            "_id": 18979,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 319,
            "שם_רחוב": "טאריק"
        },
        {
            "_id": 18980,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1150,
            "שם_רחוב": "טבנקין יצחק"
        },
        {
            "_id": 18981,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 836,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 18982,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 973,
            "שם_רחוב": "טברסקי ג'ניה"
        },
        {
            "_id": 18983,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 481,
            "שם_רחוב": "טהון יעקב"
        },
        {
            "_id": 18984,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 583,
            "שם_רחוב": "טובי תופיק"
        },
        {
            "_id": 18985,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "טובים"
        },
        {
            "_id": 18986,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1201,
            "שם_רחוב": "טולצ'ינסקי ברוך"
        },
        {
            "_id": 18987,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 494,
            "שם_רחוב": "טוסקניני ארתור"
        },
        {
            "_id": 18988,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1234,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 18989,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1512,
            "שם_רחוב": "טיילת אריה גוראל"
        },
        {
            "_id": 18990,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1513,
            "שם_רחוב": "טיילת חולדה גורביץ"
        },
        {
            "_id": 18991,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "טרומן"
        },
        {
            "_id": 18992,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 726,
            "שם_רחוב": "טשרניחובסקי"
        },
        {
            "_id": 18993,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1200,
            "שם_רחוב": "יא הספורטאים"
        },
        {
            "_id": 18994,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "יבנאלי שמואל"
        },
        {
            "_id": 18995,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 395,
            "שם_רחוב": "יבנה"
        },
        {
            "_id": 18996,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1391,
            "שם_רחוב": "יגאל אלון"
        },
        {
            "_id": 18997,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1067,
            "שם_רחוב": "יגור"
        },
        {
            "_id": 18998,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "יגיע כפיים"
        },
        {
            "_id": 18999,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 577,
            "שם_רחוב": "יהואש המלך"
        },
        {
            "_id": 19000,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 19001,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1148,
            "שם_רחוב": "יהודה עמיחי"
        },
        {
            "_id": 19002,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1132,
            "שם_רחוב": "יהודית"
        },
        {
            "_id": 19003,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1260,
            "שם_רחוב": "יהורם גאון"
        },
        {
            "_id": 19004,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 899,
            "שם_רחוב": "יהושע"
        },
        {
            "_id": 19005,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 563,
            "שם_רחוב": "יהושפט המלך"
        },
        {
            "_id": 19006,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 548,
            "שם_רחוב": "יואב"
        },
        {
            "_id": 19007,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 857,
            "שם_רחוב": "יואל"
        },
        {
            "_id": 19008,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 315,
            "שם_רחוב": "יודפת"
        },
        {
            "_id": 19009,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1082,
            "שם_רחוב": "יוחאי בן-נון"
        },
        {
            "_id": 19010,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 366,
            "שם_רחוב": "יוחנן הקדוש"
        },
        {
            "_id": 19011,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 661,
            "שם_רחוב": "יוכבד"
        },
        {
            "_id": 19012,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "יוליוס סימון"
        },
        {
            "_id": 19013,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 853,
            "שם_רחוב": "יונה"
        },
        {
            "_id": 19014,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 539,
            "שם_רחוב": "יונתן"
        },
        {
            "_id": 19015,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1268,
            "שם_רחוב": "יונתן (ג'וני) הרדי"
        },
        {
            "_id": 19016,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1258,
            "שם_רחוב": "יוסי בנאי"
        },
        {
            "_id": 19017,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "יוסיפון"
        },
        {
            "_id": 19018,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 955,
            "שם_רחוב": "יוסף (הגדם)"
        },
        {
            "_id": 19019,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1153,
            "שם_רחוב": "יורדי הים"
        },
        {
            "_id": 19020,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 628,
            "שם_רחוב": "יותם"
        },
        {
            "_id": 19021,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1042,
            "שם_רחוב": "יחזקאל"
        },
        {
            "_id": 19022,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 880,
            "שם_רחוב": "יחיאל"
        },
        {
            "_id": 19023,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "יחיעם"
        },
        {
            "_id": 19024,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 944,
            "שם_רחוב": "ילג"
        },
        {
            "_id": 19025,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 947,
            "שם_רחוב": "ילג דרומית"
        },
        {
            "_id": 19026,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 946,
            "שם_רחוב": "ילג צפונית"
        },
        {
            "_id": 19027,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1151,
            "שם_רחוב": "ילדי טהרן"
        },
        {
            "_id": 19028,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1154,
            "שם_רחוב": "ינקולוביץ חיים"
        },
        {
            "_id": 19029,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 517,
            "שם_רחוב": "יעל"
        },
        {
            "_id": 19030,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1198,
            "שם_רחוב": "יעקב שחר"
        },
        {
            "_id": 19031,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 606,
            "שם_רחוב": "יערות"
        },
        {
            "_id": 19032,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1394,
            "שם_רחוב": "יערי מאיר"
        },
        {
            "_id": 19033,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 904,
            "שם_רחוב": "יפה הלל"
        },
        {
            "_id": 19034,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 757,
            "שם_רחוב": "יפה ושמואל שכטר"
        },
        {
            "_id": 19035,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1256,
            "שם_רחוב": "יפה ירקוני"
        },
        {
            "_id": 19036,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 813,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 19037,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 2679,
            "שם_רחוב": "יפעה"
        },
        {
            "_id": 19038,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 664,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 19039,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 748,
            "שם_רחוב": "יציאת אירופה"
        },
        {
            "_id": 19040,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "יציב יצחק"
        },
        {
            "_id": 19041,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 392,
            "שם_רחוב": "יקוט"
        },
        {
            "_id": 19042,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1465,
            "שם_רחוב": "יקינתון"
        },
        {
            "_id": 19043,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1261,
            "שם_רחוב": "ירדנה ארזי"
        },
        {
            "_id": 19044,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 896,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 19045,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1087,
            "שם_רחוב": "ירושלמי אליעזר דר"
        },
        {
            "_id": 19046,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 637,
            "שם_רחוב": "ישורון"
        },
        {
            "_id": 19047,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 740,
            "שם_רחוב": "ישעיהו"
        },
        {
            "_id": 19048,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1269,
            "שם_רחוב": "ישעיהו (שייע) שווגר"
        },
        {
            "_id": 19049,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "ישראלי בן ציון"
        },
        {
            "_id": 19050,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 789,
            "שם_רחוב": "כץ יאיר"
        },
        {
            "_id": 19051,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "כץ יעקב הרב"
        },
        {
            "_id": 19052,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "כאהן יעקב"
        },
        {
            "_id": 19053,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 692,
            "שם_רחוב": "כבירים"
        },
        {
            "_id": 19054,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כברי"
        },
        {
            "_id": 19055,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 484,
            "שם_רחוב": "כגן רחל"
        },
        {
            "_id": 19056,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 981,
            "שם_רחוב": "כהן ברוך"
        },
        {
            "_id": 19057,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 386,
            "שם_רחוב": "כורי"
        },
        {
            "_id": 19058,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 819,
            "שם_רחוב": "כורש"
        },
        {
            "_id": 19059,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 350,
            "שם_רחוב": "כטיב"
        },
        {
            "_id": 19060,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "כיאט"
        },
        {
            "_id": 19061,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6075,
            "שם_רחוב": "כיכר 6075"
        },
        {
            "_id": 19062,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6085,
            "שם_רחוב": "כיכר 6085"
        },
        {
            "_id": 19063,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6098,
            "שם_רחוב": "כיכר 6098"
        },
        {
            "_id": 19064,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6045,
            "שם_רחוב": "כיכר אבי רן"
        },
        {
            "_id": 19065,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6050,
            "שם_רחוב": "כיכר אדם פיש"
        },
        {
            "_id": 19066,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6042,
            "שם_רחוב": "כיכר אורי זוהר"
        },
        {
            "_id": 19067,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6078,
            "שם_רחוב": "כיכר אמי נתר"
        },
        {
            "_id": 19068,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6066,
            "שם_רחוב": "כיכר אריה בן צבי"
        },
        {
            "_id": 19069,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6073,
            "שם_רחוב": "כיכר בוטרוס אבו מנה"
        },
        {
            "_id": 19070,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6077,
            "שם_רחוב": "כיכר בן ציון רייפר"
        },
        {
            "_id": 19071,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6116,
            "שם_רחוב": "כיכר ברוך רפפורט"
        },
        {
            "_id": 19072,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6148,
            "שם_רחוב": "כיכר גיורא פישר"
        },
        {
            "_id": 19073,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6053,
            "שם_רחוב": "כיכר דר אלי נחמיאס"
        },
        {
            "_id": 19074,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6063,
            "שם_רחוב": "כיכר דר אריך לב"
        },
        {
            "_id": 19075,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6091,
            "שם_רחוב": "כיכר דנמרק"
        },
        {
            "_id": 19076,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6092,
            "שם_רחוב": "כיכר הבורג'"
        },
        {
            "_id": 19077,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6041,
            "שם_רחוב": "כיכר הפיליפינים"
        },
        {
            "_id": 19078,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6117,
            "שם_רחוב": "כיכר ואצלב האוול"
        },
        {
            "_id": 19079,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6093,
            "שם_רחוב": "כיכר זאהי כרכבי"
        },
        {
            "_id": 19080,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6112,
            "שם_רחוב": "כיכר זינגר יוסף"
        },
        {
            "_id": 19081,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6143,
            "שם_רחוב": "כיכר חביב"
        },
        {
            "_id": 19082,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6072,
            "שם_רחוב": "כיכר חדוה שפיגל"
        },
        {
            "_id": 19083,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6055,
            "שם_רחוב": "כיכר חתני פרס נובל"
        },
        {
            "_id": 19084,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6052,
            "שם_רחוב": "כיכר טוביה פרידמן"
        },
        {
            "_id": 19085,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6065,
            "שם_רחוב": "כיכר יוסף קרני"
        },
        {
            "_id": 19086,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6146,
            "שם_רחוב": "כיכר יצחק חופי"
        },
        {
            "_id": 19087,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6058,
            "שם_רחוב": "כיכר להקת הגבעטרון"
        },
        {
            "_id": 19088,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6059,
            "שם_רחוב": "כיכר להקת הדודאים"
        },
        {
            "_id": 19089,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6062,
            "שם_רחוב": "כיכר להקת חלב ודבש"
        },
        {
            "_id": 19090,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6061,
            "שם_רחוב": "כיכר להקת כוורת"
        },
        {
            "_id": 19091,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6060,
            "שם_רחוב": "כיכר להקת משינה"
        },
        {
            "_id": 19092,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6069,
            "שם_רחוב": "כיכר לוי שבואלי"
        },
        {
            "_id": 19093,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6051,
            "שם_רחוב": "כיכר ליאו גולדהמר"
        },
        {
            "_id": 19094,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6054,
            "שם_רחוב": "כיכר מרדכי מנו"
        },
        {
            "_id": 19095,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6064,
            "שם_רחוב": "כיכר מרדכי שטיינברג"
        },
        {
            "_id": 19096,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6076,
            "שם_רחוב": "כיכר משה ורטמן"
        },
        {
            "_id": 19097,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6132,
            "שם_רחוב": "כיכר נפתלי אילתי"
        },
        {
            "_id": 19098,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6151,
            "שם_רחוב": "כיכר עמנואל זמיר"
        },
        {
            "_id": 19099,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6150,
            "שם_רחוב": "כיכר פאפא"
        },
        {
            "_id": 19100,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6108,
            "שם_רחוב": "כיכר פרימו לוי"
        },
        {
            "_id": 19101,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6070,
            "שם_רחוב": "כיכר קיצס וכהן"
        },
        {
            "_id": 19102,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6115,
            "שם_רחוב": "כיכר רובי"
        },
        {
            "_id": 19103,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6044,
            "שם_רחוב": "כיכר רוטרי חיפה"
        },
        {
            "_id": 19104,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6074,
            "שם_רחוב": "כיכר רומניה"
        },
        {
            "_id": 19105,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6056,
            "שם_רחוב": "כיכר רות מושכל"
        },
        {
            "_id": 19106,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6067,
            "שם_רחוב": "כיכר שלמה ג'מוס"
        },
        {
            "_id": 19107,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6057,
            "שם_רחוב": "כיכר שלמה וול"
        },
        {
            "_id": 19108,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6034,
            "שם_רחוב": "כיכר שמואל טנקוס"
        },
        {
            "_id": 19109,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6033,
            "שם_רחוב": "ככר אגמי אורי"
        },
        {
            "_id": 19110,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6046,
            "שם_רחוב": "ככר אונסקו"
        },
        {
            "_id": 19111,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6020,
            "שם_רחוב": "ככר אליזבט"
        },
        {
            "_id": 19112,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6016,
            "שם_רחוב": "ככר אמיל גרינצוויג"
        },
        {
            "_id": 19113,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6099,
            "שם_רחוב": "ככר אמיל חביבי"
        },
        {
            "_id": 19114,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר אמפא"
        },
        {
            "_id": 19115,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6036,
            "שם_רחוב": "ככר אנואר סאדאת"
        },
        {
            "_id": 19116,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6087,
            "שם_רחוב": "ככר בולגריה"
        },
        {
            "_id": 19117,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6021,
            "שם_רחוב": "ככר בני אחוזה"
        },
        {
            "_id": 19118,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6088,
            "שם_רחוב": "ככר ברנר מילה"
        },
        {
            "_id": 19119,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6096,
            "שם_רחוב": "ככר ג'רלד פרדמן"
        },
        {
            "_id": 19120,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר דוד"
        },
        {
            "_id": 19121,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6022,
            "שם_רחוב": "ככר דוד הכהן"
        },
        {
            "_id": 19122,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6080,
            "שם_רחוב": "ככר האוניברסיטה"
        },
        {
            "_id": 19123,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר הגבורה"
        },
        {
            "_id": 19124,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר ההגנה"
        },
        {
            "_id": 19125,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר היינה"
        },
        {
            "_id": 19126,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6081,
            "שם_רחוב": "ככר הכט ראובן"
        },
        {
            "_id": 19127,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6024,
            "שם_רחוב": "ככר הלייט"
        },
        {
            "_id": 19128,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6083,
            "שם_רחוב": "ככר המר זבולון"
        },
        {
            "_id": 19129,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6023,
            "שם_רחוב": "ככר הנדריק סרברוב"
        },
        {
            "_id": 19130,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6040,
            "שם_רחוב": "ככר הפיליפינים"
        },
        {
            "_id": 19131,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6071,
            "שם_רחוב": "ככר הרב עובדיה יוסף"
        },
        {
            "_id": 19132,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "ככר הרברט סמואל"
        },
        {
            "_id": 19133,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "ככר הרקפות"
        },
        {
            "_id": 19134,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6090,
            "שם_רחוב": "ככר וידרא נפתלי"
        },
        {
            "_id": 19135,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "ככר זיו"
        },
        {
            "_id": 19136,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6089,
            "שם_רחוב": "ככר זכאי אברהם"
        },
        {
            "_id": 19137,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6000,
            "שם_רחוב": "ככר זלטין"
        },
        {
            "_id": 19138,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6025,
            "שם_רחוב": "ככר חיל הרפואה"
        },
        {
            "_id": 19139,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6103,
            "שם_רחוב": "ככר חיפה - בוסטון"
        },
        {
            "_id": 19140,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6026,
            "שם_רחוב": "ככר יד לבנים"
        },
        {
            "_id": 19141,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6035,
            "שם_רחוב": "ככר ישעיהו לייבוביץ"
        },
        {
            "_id": 19142,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6068,
            "שם_רחוב": "ככר כהן דוד"
        },
        {
            "_id": 19143,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6027,
            "שם_רחוב": "ככר כיאט"
        },
        {
            "_id": 19144,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6009,
            "שם_רחוב": "ככר מאירהוף"
        },
        {
            "_id": 19145,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6039,
            "שם_רחוב": "ככר מוסינזון יגאל"
        },
        {
            "_id": 19146,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6019,
            "שם_רחוב": "ככר מינה"
        },
        {
            "_id": 19147,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6028,
            "שם_רחוב": "ככר מסריק"
        },
        {
            "_id": 19148,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6017,
            "שם_רחוב": "ככר מעפילי קפריסין"
        },
        {
            "_id": 19149,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6010,
            "שם_רחוב": "ככר סולל בונה"
        },
        {
            "_id": 19150,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6011,
            "שם_רחוב": "ככר ספקטור"
        },
        {
            "_id": 19151,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6015,
            "שם_רחוב": "ככר עולי הגרדום"
        },
        {
            "_id": 19152,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6047,
            "שם_רחוב": "ככר עציוני משה"
        },
        {
            "_id": 19153,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6037,
            "שם_רחוב": "ככר פדרמן יקותיאל"
        },
        {
            "_id": 19154,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6149,
            "שם_רחוב": "ככר פול ומאי אריאלי"
        },
        {
            "_id": 19155,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6030,
            "שם_רחוב": "ככר פייצל"
        },
        {
            "_id": 19156,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6012,
            "שם_רחוב": "ככר פלומר"
        },
        {
            "_id": 19157,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6013,
            "שם_רחוב": "ככר פריס"
        },
        {
            "_id": 19158,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6029,
            "שם_רחוב": "ככר צייזל"
        },
        {
            "_id": 19159,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6031,
            "שם_רחוב": "ככר צים"
        },
        {
            "_id": 19160,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6014,
            "שם_רחוב": "ככר קפלנסקי"
        },
        {
            "_id": 19161,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6038,
            "שם_רחוב": "ככר שבבו שושנה"
        },
        {
            "_id": 19162,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6043,
            "שם_רחוב": "ככר שמידט אנטון"
        },
        {
            "_id": 19163,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6032,
            "שם_רחוב": "ככר שפירא יצחק"
        },
        {
            "_id": 19164,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 736,
            "שם_רחוב": "כל ישראל חברים"
        },
        {
            "_id": 19165,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1172,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 19166,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 691,
            "שם_רחוב": "כלניות"
        },
        {
            "_id": 19167,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1158,
            "שם_רחוב": "כלפון הרב"
        },
        {
            "_id": 19168,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 906,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 19169,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 540,
            "שם_רחוב": "כספי יעקב"
        },
        {
            "_id": 19170,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1103,
            "שם_רחוב": "כספרי דר"
        },
        {
            "_id": 19171,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6615,
            "שם_רחוב": "כפר כבביר"
        },
        {
            "_id": 19172,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1117,
            "שם_רחוב": "כצמן משה"
        },
        {
            "_id": 19173,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 471,
            "שם_רחוב": "כרוך אריה"
        },
        {
            "_id": 19174,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 788,
            "שם_רחוב": "כרמי"
        },
        {
            "_id": 19175,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 731,
            "שם_רחוב": "כרמיה"
        },
        {
            "_id": 19176,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6613,
            "שם_רחוב": "כרמל מערבי"
        },
        {
            "_id": 19177,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6630,
            "שם_רחוב": "כרמל מרכזי"
        },
        {
            "_id": 19178,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6612,
            "שם_רחוב": "כרמל צפוני"
        },
        {
            "_id": 19179,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 677,
            "שם_רחוב": "לאה"
        },
        {
            "_id": 19180,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 720,
            "שם_רחוב": "לאונרדו דה וינצי"
        },
        {
            "_id": 19181,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 489,
            "שם_רחוב": "לב המפרץ"
        },
        {
            "_id": 19182,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 465,
            "שם_רחוב": "לבון פנחס"
        },
        {
            "_id": 19183,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 894,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 19184,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 402,
            "שם_רחוב": "לבונטין"
        },
        {
            "_id": 19185,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "לביא שלמה"
        },
        {
            "_id": 19186,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1501,
            "שם_רחוב": "לבנון ישראל"
        },
        {
            "_id": 19187,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 938,
            "שם_רחוב": "לברי מרק"
        },
        {
            "_id": 19188,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 878,
            "שם_רחוב": "לוד"
        },
        {
            "_id": 19189,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 417,
            "שם_רחוב": "לוחמי הגטאות"
        },
        {
            "_id": 19190,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1116,
            "שם_רחוב": "לוחמי חרות ישראל"
        },
        {
            "_id": 19191,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 649,
            "שם_רחוב": "לוטוס"
        },
        {
            "_id": 19192,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "לוי יוסף"
        },
        {
            "_id": 19193,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "לוי יצחק"
        },
        {
            "_id": 19194,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "לוין אריה הרב"
        },
        {
            "_id": 19195,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1401,
            "שם_רחוב": "לוין גוטל"
        },
        {
            "_id": 19196,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 882,
            "שם_רחוב": "לונץ"
        },
        {
            "_id": 19197,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 535,
            "שם_רחוב": "לוץ שרל"
        },
        {
            "_id": 19198,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 927,
            "שם_רחוב": "לוצטו"
        },
        {
            "_id": 19199,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1088,
            "שם_רחוב": "לזרוס אמה"
        },
        {
            "_id": 19200,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 774,
            "שם_רחוב": "ליבריה"
        },
        {
            "_id": 19201,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "ליויק"
        },
        {
            "_id": 19202,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 626,
            "שם_רחוב": "ליטאניס"
        },
        {
            "_id": 19203,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "לייב יפה"
        },
        {
            "_id": 19204,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 998,
            "שם_רחוב": "לינדנשטראוס אהרון"
        },
        {
            "_id": 19205,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 593,
            "שם_רחוב": "לינקולן אברהם"
        },
        {
            "_id": 19206,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 378,
            "שם_רחוב": "ליפשיץ יוסף"
        },
        {
            "_id": 19207,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 612,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 19208,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1163,
            "שם_רחוב": "לני"
        },
        {
            "_id": 19209,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1120,
            "שם_רחוב": "לנדאו מיכאל דר"
        },
        {
            "_id": 19210,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 524,
            "שם_רחוב": "לסין אברהם"
        },
        {
            "_id": 19211,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 467,
            "שם_רחוב": "לסקוב חיים ראל"
        },
        {
            "_id": 19212,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 447,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 19213,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 359,
            "שם_רחוב": "מאי"
        },
        {
            "_id": 19214,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 362,
            "שם_רחוב": "מאיר (רוטברג)"
        },
        {
            "_id": 19215,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 483,
            "שם_רחוב": "מאיר גולדה"
        },
        {
            "_id": 19216,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1115,
            "שם_רחוב": "מאירוביץ צבי"
        },
        {
            "_id": 19217,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "מאלי יעקב משה"
        },
        {
            "_id": 19218,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 615,
            "שם_רחוב": "מאפו"
        },
        {
            "_id": 19219,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 794,
            "שם_רחוב": "מאציני"
        },
        {
            "_id": 19220,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1229,
            "שם_רחוב": "מבוא הכרמל"
        },
        {
            "_id": 19221,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1231,
            "שם_רחוב": "מבוא חיפה"
        },
        {
            "_id": 19222,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 991,
            "שם_רחוב": "מבצע יונתן"
        },
        {
            "_id": 19223,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "מבצע קדש"
        },
        {
            "_id": 19224,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 571,
            "שם_רחוב": "מג'דלאני ג'אן"
        },
        {
            "_id": 19225,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1471,
            "שם_רחוב": "מגדל אור"
        },
        {
            "_id": 19226,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 576,
            "שם_רחוב": "מגורשי ספרד"
        },
        {
            "_id": 19227,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 640,
            "שם_רחוב": "מגידו"
        },
        {
            "_id": 19228,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1043,
            "שם_רחוב": "מד אברהם"
        },
        {
            "_id": 19229,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 342,
            "שם_רחוב": "מד אירביד"
        },
        {
            "_id": 19230,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 573,
            "שם_רחוב": "מד אלגביש יוסף"
        },
        {
            "_id": 19231,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1126,
            "שם_רחוב": "מד בוניאק ראובן"
        },
        {
            "_id": 19232,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 809,
            "שם_רחוב": "מד בית שערים"
        },
        {
            "_id": 19233,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 952,
            "שם_רחוב": "מד בני אור"
        },
        {
            "_id": 19234,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1109,
            "שם_רחוב": "מד בת שבע"
        },
        {
            "_id": 19235,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 814,
            "שם_רחוב": "מד גדרה"
        },
        {
            "_id": 19236,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 972,
            "שם_רחוב": "מד גלעד"
        },
        {
            "_id": 19237,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 829,
            "שם_רחוב": "מד גמלא"
        },
        {
            "_id": 19238,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 926,
            "שם_רחוב": "מד דוניה טוביה"
        },
        {
            "_id": 19239,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 404,
            "שם_רחוב": "מד דור"
        },
        {
            "_id": 19240,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1177,
            "שם_רחוב": "מד החרצית"
        },
        {
            "_id": 19241,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 940,
            "שם_רחוב": "מד הלפרט נפתלי"
        },
        {
            "_id": 19242,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1017,
            "שם_רחוב": "מד המוכתר צבי"
        },
        {
            "_id": 19243,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 867,
            "שם_רחוב": "מד הנביאים"
        },
        {
            "_id": 19244,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 908,
            "שם_רחוב": "מד הקנאים"
        },
        {
            "_id": 19245,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 968,
            "שם_רחוב": "מד הרצל"
        },
        {
            "_id": 19246,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 939,
            "שם_רחוב": "מד השלוח צפון"
        },
        {
            "_id": 19247,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1111,
            "שם_רחוב": "מד זכריה"
        },
        {
            "_id": 19248,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 780,
            "שם_רחוב": "מד זרובבל"
        },
        {
            "_id": 19249,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1110,
            "שם_רחוב": "מד חגית"
        },
        {
            "_id": 19250,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 330,
            "שם_רחוב": "מד חגלה"
        },
        {
            "_id": 19251,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1112,
            "שם_רחוב": "מד חצב"
        },
        {
            "_id": 19252,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 925,
            "שם_רחוב": "מד חרות"
        },
        {
            "_id": 19253,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 833,
            "שם_רחוב": "מד מודיעין"
        },
        {
            "_id": 19254,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 929,
            "שם_רחוב": "מד מחנות"
        },
        {
            "_id": 19255,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 321,
            "שם_רחוב": "מד מילר נחום"
        },
        {
            "_id": 19256,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 536,
            "שם_רחוב": "מד מעלה הדקר"
        },
        {
            "_id": 19257,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1108,
            "שם_רחוב": "מד מרים"
        },
        {
            "_id": 19258,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 430,
            "שם_רחוב": "מד נוזהה ב"
        },
        {
            "_id": 19259,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 956,
            "שם_רחוב": "מד נפתלי"
        },
        {
            "_id": 19260,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 339,
            "שם_רחוב": "מד עגלון"
        },
        {
            "_id": 19261,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 923,
            "שם_רחוב": "מד עמנואל"
        },
        {
            "_id": 19262,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1127,
            "שם_רחוב": "מד פנחס כהן"
        },
        {
            "_id": 19263,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 832,
            "שם_רחוב": "מד פקיעין"
        },
        {
            "_id": 19264,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1146,
            "שם_רחוב": "מד צמח צדיק"
        },
        {
            "_id": 19265,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 406,
            "שם_רחוב": "מד רשפון"
        },
        {
            "_id": 19266,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 317,
            "שם_רחוב": "מד שוקרי"
        },
        {
            "_id": 19267,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 821,
            "שם_רחוב": "מד שפינוזה"
        },
        {
            "_id": 19268,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 318,
            "שם_רחוב": "מד תימן"
        },
        {
            "_id": 19269,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "מוהליבר"
        },
        {
            "_id": 19270,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 820,
            "שם_רחוב": "מונטיפיורי"
        },
        {
            "_id": 19271,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 742,
            "שם_רחוב": "מור"
        },
        {
            "_id": 19272,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 660,
            "שם_רחוב": "מורג"
        },
        {
            "_id": 19273,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 458,
            "שם_רחוב": "מורד היסמין"
        },
        {
            "_id": 19274,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1063,
            "שם_רחוב": "מורדות נוה שאנן"
        },
        {
            "_id": 19275,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1171,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 19276,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "מושלי יעקב"
        },
        {
            "_id": 19277,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1509,
            "שם_רחוב": "מושקא חיה"
        },
        {
            "_id": 19278,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 4061,
            "שם_רחוב": "מחלף גדוד 21"
        },
        {
            "_id": 19279,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 4067,
            "שם_רחוב": "מחלף ההסתדרות"
        },
        {
            "_id": 19280,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 4068,
            "שם_רחוב": "מחלף הקישון"
        },
        {
            "_id": 19281,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 708,
            "שם_רחוב": "מחניים"
        },
        {
            "_id": 19282,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 647,
            "שם_רחוב": "מייבלום דורון"
        },
        {
            "_id": 19283,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 619,
            "שם_רחוב": "מיכל המשורר"
        },
        {
            "_id": 19284,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 930,
            "שם_רחוב": "מיכאל"
        },
        {
            "_id": 19285,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "מיכואלס שלמה"
        },
        {
            "_id": 19286,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 331,
            "שם_רחוב": "מילכה"
        },
        {
            "_id": 19287,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 558,
            "שם_רחוב": "מילסון יוסף צבי"
        },
        {
            "_id": 19288,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1073,
            "שם_רחוב": "מימון הרב"
        },
        {
            "_id": 19289,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "מכון הטיהור"
        },
        {
            "_id": 19290,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 753,
            "שם_רחוב": "מכון זואולוגי"
        },
        {
            "_id": 19291,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 369,
            "שם_רחוב": "מכליס משה אהרון"
        },
        {
            "_id": 19292,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1046,
            "שם_רחוב": "מלל"
        },
        {
            "_id": 19293,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 892,
            "שם_רחוב": "מלון גלרי"
        },
        {
            "_id": 19294,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 928,
            "שם_רחוב": "מלון מגדל חיפה"
        },
        {
            "_id": 19295,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1184,
            "שם_רחוב": "מלון מרידיאן"
        },
        {
            "_id": 19296,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 902,
            "שם_רחוב": "מלצ'ט"
        },
        {
            "_id": 19297,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "מנגר איציק"
        },
        {
            "_id": 19298,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1050,
            "שם_רחוב": "מנדלי מוכר ספרים"
        },
        {
            "_id": 19299,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 455,
            "שם_רחוב": "מנדלסון דבורה"
        },
        {
            "_id": 19300,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1300,
            "שם_רחוב": "מנהרות הכרמל"
        },
        {
            "_id": 19301,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 887,
            "שם_רחוב": "מנחם"
        },
        {
            "_id": 19302,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 670,
            "שם_רחוב": "מנילה"
        },
        {
            "_id": 19303,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 363,
            "שם_רחוב": "מנצור"
        },
        {
            "_id": 19304,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1266,
            "שם_רחוב": "מנשה קדישמן"
        },
        {
            "_id": 19305,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 834,
            "שם_רחוב": "מסדה"
        },
        {
            "_id": 19306,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 433,
            "שם_רחוב": "מסילת הברזל"
        },
        {
            "_id": 19307,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1185,
            "שם_רחוב": "מסילת ישרים"
        },
        {
            "_id": 19308,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 8004,
            "שם_רחוב": "מספנות ישראל"
        },
        {
            "_id": 19309,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6590,
            "שם_רחוב": "מע אחוזה"
        },
        {
            "_id": 19310,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6551,
            "שם_רחוב": "מע ק אליהו"
        },
        {
            "_id": 19311,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "מע ק שמואל"
        },
        {
            "_id": 19312,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 668,
            "שם_רחוב": "מעברות"
        },
        {
            "_id": 19313,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 498,
            "שם_רחוב": "מעון אבא חושי"
        },
        {
            "_id": 19314,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "מעון אקדמאים"
        },
        {
            "_id": 19315,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 499,
            "שם_רחוב": "מעון קלמן לוין"
        },
        {
            "_id": 19316,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 645,
            "שם_רחוב": "מעין"
        },
        {
            "_id": 19317,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 338,
            "שם_רחוב": "מעלה השחרור"
        },
        {
            "_id": 19318,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1230,
            "שם_רחוב": "מעלות אל עבאסי עסאם"
        },
        {
            "_id": 19319,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1138,
            "שם_רחוב": "מעלות בוגלה יונה"
        },
        {
            "_id": 19320,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1353,
            "שם_רחוב": "מעלות גרונר דב"
        },
        {
            "_id": 19321,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 750,
            "שם_רחוב": "מעלות הבוצרים"
        },
        {
            "_id": 19322,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1130,
            "שם_רחוב": "מעלות חתם סופר"
        },
        {
            "_id": 19323,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1104,
            "שם_רחוב": "מעלות ישכיל"
        },
        {
            "_id": 19324,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "מצובה"
        },
        {
            "_id": 19325,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1030,
            "שם_רחוב": "מצפה"
        },
        {
            "_id": 19326,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 965,
            "שם_רחוב": "מקור ברוך"
        },
        {
            "_id": 19327,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "מקלף מרדכי ראל"
        },
        {
            "_id": 19328,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 567,
            "שם_רחוב": "מרגולין פנחס"
        },
        {
            "_id": 19329,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 671,
            "שם_רחוב": "מרגלית"
        },
        {
            "_id": 19330,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1223,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 19331,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "מרחביה"
        },
        {
            "_id": 19332,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 744,
            "שם_רחוב": "מרכוס דוד"
        },
        {
            "_id": 19333,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 962,
            "שם_רחוב": "מרכוס הרב"
        },
        {
            "_id": 19334,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 747,
            "שם_רחוב": "מרכז הנופש"
        },
        {
            "_id": 19335,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1208,
            "שם_רחוב": "מרכז קסטרא"
        },
        {
            "_id": 19336,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 8011,
            "שם_רחוב": "מרכז תעשיות מדע"
        },
        {
            "_id": 19337,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1301,
            "שם_רחוב": "מרמרי"
        },
        {
            "_id": 19338,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 786,
            "שם_רחוב": "מרסיי"
        },
        {
            "_id": 19339,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 282,
            "שם_רחוב": "מרקוני גוליאלמו"
        },
        {
            "_id": 19340,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 599,
            "שם_רחוב": "משאש הרב"
        },
        {
            "_id": 19341,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "משה חיים שפירא"
        },
        {
            "_id": 19342,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "משטרת קרית חיים"
        },
        {
            "_id": 19343,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 638,
            "שם_רחוב": "משמר הגבול"
        },
        {
            "_id": 19344,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "משמר העמק"
        },
        {
            "_id": 19345,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1255,
            "שם_רחוב": "משעול יהודית ומאיר"
        },
        {
            "_id": 19346,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1228,
            "שם_רחוב": "משעול תורת חיים"
        },
        {
            "_id": 19347,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "מתחם האיצטדיון"
        },
        {
            "_id": 19348,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "מתחם מיכלי הנפט"
        },
        {
            "_id": 19349,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 365,
            "שם_רחוב": "נאמנים"
        },
        {
            "_id": 19350,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 335,
            "שם_רחוב": "נג'ד"
        },
        {
            "_id": 19351,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1181,
            "שם_רחוב": "נדב יצחק"
        },
        {
            "_id": 19352,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 855,
            "שם_רחוב": "נהון יצחק"
        },
        {
            "_id": 19353,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 541,
            "שם_רחוב": "נהלל"
        },
        {
            "_id": 19354,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 291,
            "שם_רחוב": "נהריים"
        },
        {
            "_id": 19355,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 678,
            "שם_רחוב": "נוגה"
        },
        {
            "_id": 19356,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1145,
            "שם_רחוב": "נוה גנים"
        },
        {
            "_id": 19357,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6575,
            "שם_רחוב": "נוה דוד"
        },
        {
            "_id": 19358,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 2676,
            "שם_רחוב": "נוי"
        },
        {
            "_id": 19359,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 951,
            "שם_רחוב": "נועה"
        },
        {
            "_id": 19360,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 2677,
            "שם_רחוב": "נועם"
        },
        {
            "_id": 19361,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "נוף המפרץ"
        },
        {
            "_id": 19362,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 885,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 19363,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 790,
            "שם_רחוב": "נורווגיה"
        },
        {
            "_id": 19364,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1176,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 19365,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 520,
            "שם_רחוב": "נחל"
        },
        {
            "_id": 19366,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 542,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 19367,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1113,
            "שם_רחוב": "נחמיאס יוסף"
        },
        {
            "_id": 19368,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1027,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 19369,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 665,
            "שם_רחוב": "נחשון"
        },
        {
            "_id": 19370,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1076,
            "שם_רחוב": "נטר קרל"
        },
        {
            "_id": 19371,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 280,
            "שם_רחוב": "ניוטון אייזיק"
        },
        {
            "_id": 19372,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1157,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 19373,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1500,
            "שם_רחוב": "ניסים יצחק הרב"
        },
        {
            "_id": 19374,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1090,
            "שם_רחוב": "ניסנבוים"
        },
        {
            "_id": 19375,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 716,
            "שם_רחוב": "ניצנים"
        },
        {
            "_id": 19376,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 8003,
            "שם_רחוב": "נמל הדיג"
        },
        {
            "_id": 19377,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 8002,
            "שם_רחוב": "נמל הקישון"
        },
        {
            "_id": 19378,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 8001,
            "שם_רחוב": "נמל חיפה"
        },
        {
            "_id": 19379,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1018,
            "שם_רחוב": "נמרי דוד"
        },
        {
            "_id": 19380,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1162,
            "שם_רחוב": "נעמת"
        },
        {
            "_id": 19381,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 681,
            "שם_רחוב": "נעמי"
        },
        {
            "_id": 19382,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1386,
            "שם_רחוב": "נעמי שמר"
        },
        {
            "_id": 19383,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 964,
            "שם_רחוב": "נתיב אליעזר"
        },
        {
            "_id": 19384,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 761,
            "שם_רחוב": "נתיב אפקים"
        },
        {
            "_id": 19385,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1204,
            "שם_רחוב": "נתיב האור"
        },
        {
            "_id": 19386,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "נתיב האתרוג"
        },
        {
            "_id": 19387,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "נתיב ההדס"
        },
        {
            "_id": 19388,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "נתיב הישיבה"
        },
        {
            "_id": 19389,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "נתיב הלולב"
        },
        {
            "_id": 19390,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "נתיב העולים"
        },
        {
            "_id": 19391,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "נתיב הערבה"
        },
        {
            "_id": 19392,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1019,
            "שם_רחוב": "נתיב חן"
        },
        {
            "_id": 19393,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "נתיב שדר"
        },
        {
            "_id": 19394,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 442,
            "שם_רחוב": "נתיבי המפרץ"
        },
        {
            "_id": 19395,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 444,
            "שם_רחוב": "נתנזון"
        },
        {
            "_id": 19396,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 492,
            "שם_רחוב": "סבידור מנחם"
        },
        {
            "_id": 19397,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6611,
            "שם_רחוב": "סביוני הכרמל"
        },
        {
            "_id": 19398,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 714,
            "שם_רחוב": "סגליות"
        },
        {
            "_id": 19399,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 584,
            "שם_רחוב": "סוטין חיים"
        },
        {
            "_id": 19400,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 724,
            "שם_רחוב": "סוכות"
        },
        {
            "_id": 19401,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 916,
            "שם_רחוב": "סולד הנרייטה"
        },
        {
            "_id": 19402,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 881,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 19403,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 977,
            "שם_רחוב": "סורוקה משה"
        },
        {
            "_id": 19404,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 564,
            "שם_רחוב": "סחרוב אנדרה"
        },
        {
            "_id": 19405,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 581,
            "שם_רחוב": "סטרומה"
        },
        {
            "_id": 19406,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1467,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 19407,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1074,
            "שם_רחוב": "סילבר אבא הלל"
        },
        {
            "_id": 19408,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 358,
            "שם_רחוב": "סיליזיאן"
        },
        {
            "_id": 19409,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 875,
            "שם_רחוב": "סירקין"
        },
        {
            "_id": 19410,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1393,
            "שם_רחוב": "סכנין אברהם"
        },
        {
            "_id": 19411,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1170,
            "שם_רחוב": "סלוודור"
        },
        {
            "_id": 19412,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1007,
            "שם_רחוב": "סלומון יעקב"
        },
        {
            "_id": 19413,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "סלמאן"
        },
        {
            "_id": 19414,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1217,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 19415,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 617,
            "שם_רחוב": "סמ אהרון"
        },
        {
            "_id": 19416,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 449,
            "שם_רחוב": "סמ אוריין מאיר"
        },
        {
            "_id": 19417,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 320,
            "שם_רחוב": "סמ אני מאמין"
        },
        {
            "_id": 19418,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 401,
            "שם_רחוב": "סמ אשקלון"
        },
        {
            "_id": 19419,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 534,
            "שם_רחוב": "סמ בנימין"
        },
        {
            "_id": 19420,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 953,
            "שם_רחוב": "סמ ברק"
        },
        {
            "_id": 19421,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 760,
            "שם_רחוב": "סמ דן"
        },
        {
            "_id": 19422,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 349,
            "שם_רחוב": "סמ הבוסתן"
        },
        {
            "_id": 19423,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 532,
            "שם_רחוב": "סמ הוז דב"
        },
        {
            "_id": 19424,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 450,
            "שם_רחוב": "סמ הזגג"
        },
        {
            "_id": 19425,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 290,
            "שם_רחוב": "סמ הירמוך"
        },
        {
            "_id": 19426,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 477,
            "שם_רחוב": "סמ הכנסיה"
        },
        {
            "_id": 19427,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 796,
            "שם_רחוב": "סמ הכרמים"
        },
        {
            "_id": 19428,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 868,
            "שם_רחוב": "סמ הנביאים"
        },
        {
            "_id": 19429,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 343,
            "שם_רחוב": "סמ העמים"
        },
        {
            "_id": 19430,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 435,
            "שם_רחוב": "סמ העצמאות"
        },
        {
            "_id": 19431,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 529,
            "שם_רחוב": "סמ הצנחנים"
        },
        {
            "_id": 19432,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 423,
            "שם_רחוב": "סמ הרכבת"
        },
        {
            "_id": 19433,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 327,
            "שם_רחוב": "סמ ואדי סליב"
        },
        {
            "_id": 19434,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1352,
            "שם_רחוב": "סמ חג'אג'"
        },
        {
            "_id": 19435,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1205,
            "שם_רחוב": "סמ יהודה"
        },
        {
            "_id": 19436,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 533,
            "שם_רחוב": "סמ מונש"
        },
        {
            "_id": 19437,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 806,
            "שם_רחוב": "סמ מוסול"
        },
        {
            "_id": 19438,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 826,
            "שם_רחוב": "סמ מירון"
        },
        {
            "_id": 19439,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 431,
            "שם_רחוב": "סמ נוזהה"
        },
        {
            "_id": 19440,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 712,
            "שם_רחוב": "סמ ספרון"
        },
        {
            "_id": 19441,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 730,
            "שם_רחוב": "סמ עופרים"
        },
        {
            "_id": 19442,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 368,
            "שם_רחוב": "סמ עזה"
        },
        {
            "_id": 19443,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 871,
            "שם_רחוב": "סמ עתלית"
        },
        {
            "_id": 19444,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 375,
            "שם_רחוב": "סמ פליק"
        },
        {
            "_id": 19445,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 396,
            "שם_רחוב": "סמ צור"
        },
        {
            "_id": 19446,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 861,
            "שם_רחוב": "סמ צידון"
        },
        {
            "_id": 19447,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 374,
            "שם_רחוב": "סמ רובין"
        },
        {
            "_id": 19448,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 835,
            "שם_רחוב": "סמ שמואל"
        },
        {
            "_id": 19449,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 713,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 19450,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 609,
            "שם_רחוב": "סמולנסקין"
        },
        {
            "_id": 19451,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1212,
            "שם_רחוב": "סמטת מטמון"
        },
        {
            "_id": 19452,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1211,
            "שם_רחוב": "סמטת ממון"
        },
        {
            "_id": 19453,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1165,
            "שם_רחוב": "סנה משה"
        },
        {
            "_id": 19454,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 410,
            "שם_רחוב": "סנט לוקס"
        },
        {
            "_id": 19455,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 914,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 19456,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 598,
            "שם_רחוב": "סעדיה גאון"
        },
        {
            "_id": 19457,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1232,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 19458,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1221,
            "שם_רחוב": "סתוונית"
        },
        {
            "_id": 19459,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 802,
            "שם_רחוב": "עבאס"
        },
        {
            "_id": 19460,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 585,
            "שם_רחוב": "עגנון שי"
        },
        {
            "_id": 19461,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 516,
            "שם_רחוב": "עדה"
        },
        {
            "_id": 19462,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 739,
            "שם_רחוב": "עדולם"
        },
        {
            "_id": 19463,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 729,
            "שם_רחוב": "עובדיה"
        },
        {
            "_id": 19464,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 695,
            "שם_רחוב": "עודה נדה"
        },
        {
            "_id": 19465,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 560,
            "שם_רחוב": "עוזיהו המלך"
        },
        {
            "_id": 19466,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 866,
            "שם_רחוב": "עומר אל כיאם"
        },
        {
            "_id": 19467,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 325,
            "שם_רחוב": "עומר אל כתאב"
        },
        {
            "_id": 19468,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1011,
            "שם_רחוב": "עוספיה"
        },
        {
            "_id": 19469,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1354,
            "שם_רחוב": "עזרא"
        },
        {
            "_id": 19470,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 722,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 19471,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 357,
            "שם_רחוב": "עין דור"
        },
        {
            "_id": 19472,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6576,
            "שם_רחוב": "עין הים"
        },
        {
            "_id": 19473,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1137,
            "שם_רחוב": "עירם צילה"
        },
        {
            "_id": 19474,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 436,
            "שם_רחוב": "עליית הנוער"
        },
        {
            "_id": 19475,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 334,
            "שם_רחוב": "עמון"
        },
        {
            "_id": 19476,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 860,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 19477,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1100,
            "שם_רחוב": "עמיקם ישראל"
        },
        {
            "_id": 19478,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1245,
            "שם_רחוב": "עמירם חרלף"
        },
        {
            "_id": 19479,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 518,
            "שם_רחוב": "עמל"
        },
        {
            "_id": 19480,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 844,
            "שם_רחוב": "עמק הזיתים"
        },
        {
            "_id": 19481,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1233,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 19482,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 737,
            "שם_רחוב": "ענתות"
        },
        {
            "_id": 19483,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 361,
            "שם_רחוב": "עספור"
        },
        {
            "_id": 19484,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1259,
            "שם_רחוב": "עפרה חזה"
        },
        {
            "_id": 19485,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 587,
            "שם_רחוב": "עפרון דר"
        },
        {
            "_id": 19486,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 924,
            "שם_רחוב": "עצמון"
        },
        {
            "_id": 19487,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 329,
            "שם_רחוב": "עקבה"
        },
        {
            "_id": 19488,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 879,
            "שם_רחוב": "עקרון"
        },
        {
            "_id": 19489,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1080,
            "שם_רחוב": "ערד"
        },
        {
            "_id": 19490,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "עשת אביגדור"
        },
        {
            "_id": 19491,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1014,
            "שם_רחוב": "פאר"
        },
        {
            "_id": 19492,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 897,
            "שם_רחוב": "פבזנר"
        },
        {
            "_id": 19493,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "פולד ברכה"
        },
        {
            "_id": 19494,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 808,
            "שם_רחוב": "פועה"
        },
        {
            "_id": 19495,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 475,
            "שם_רחוב": "פורטונה עמנואל"
        },
        {
            "_id": 19496,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 982,
            "שם_רחוב": "פז סעדיה"
        },
        {
            "_id": 19497,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1203,
            "שם_רחוב": "פטל"
        },
        {
            "_id": 19498,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 352,
            "שם_רחוב": "פטריה"
        },
        {
            "_id": 19499,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1052,
            "שם_רחוב": "פיארברג"
        },
        {
            "_id": 19500,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 769,
            "שם_רחוב": "פייגין צבי"
        },
        {
            "_id": 19501,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1107,
            "שם_רחוב": "פייזר דר"
        },
        {
            "_id": 19502,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "פייצל"
        },
        {
            "_id": 19503,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1081,
            "שם_רחוב": "פיכמן יעקב"
        },
        {
            "_id": 19504,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 792,
            "שם_רחוב": "פינלנד"
        },
        {
            "_id": 19505,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 651,
            "שם_רחוב": "פינסקי דוד"
        },
        {
            "_id": 19506,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1032,
            "שם_רחוב": "פינסקר"
        },
        {
            "_id": 19507,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 568,
            "שם_רחוב": "פינת גן"
        },
        {
            "_id": 19508,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 621,
            "שם_רחוב": "פיקא"
        },
        {
            "_id": 19509,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "פישמן עדה (מיימון)"
        },
        {
            "_id": 19510,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 767,
            "שם_רחוב": "פלג פרנק"
        },
        {
            "_id": 19511,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "פלים"
        },
        {
            "_id": 19512,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 974,
            "שם_רחוב": "פלימן משה"
        },
        {
            "_id": 19513,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 632,
            "שם_רחוב": "פלמח"
        },
        {
            "_id": 19514,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1071,
            "שם_רחוב": "פנינה"
        },
        {
            "_id": 19515,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 854,
            "שם_רחוב": "פנת בן יהודה"
        },
        {
            "_id": 19516,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 843,
            "שם_רחוב": "פנת בן שמן"
        },
        {
            "_id": 19517,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 942,
            "שם_רחוב": "פנת הרצל"
        },
        {
            "_id": 19518,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 890,
            "שם_רחוב": "פנת לונץ"
        },
        {
            "_id": 19519,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 876,
            "שם_רחוב": "פנת שפירא"
        },
        {
            "_id": 19520,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 746,
            "שם_רחוב": "פסטר לואי"
        },
        {
            "_id": 19521,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 381,
            "שם_רחוב": "פקדון"
        },
        {
            "_id": 19522,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1053,
            "שם_רחוב": "פרוג"
        },
        {
            "_id": 19523,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 614,
            "שם_רחוב": "פרויד"
        },
        {
            "_id": 19524,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1508,
            "שם_רחוב": "פרומר דב"
        },
        {
            "_id": 19525,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 937,
            "שם_רחוב": "פרידמן שמעון דר"
        },
        {
            "_id": 19526,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 898,
            "שם_רחוב": "פרישמן"
        },
        {
            "_id": 19527,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 284,
            "שם_רחוב": "פרנץ לוי"
        },
        {
            "_id": 19528,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1248,
            "שם_רחוב": "פרץ זאב אטקס"
        },
        {
            "_id": 19529,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 394,
            "שם_רחוב": "פרץ י. ל."
        },
        {
            "_id": 19530,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1097,
            "שם_רחוב": "פרץ מרקיש"
        },
        {
            "_id": 19531,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1034,
            "שם_רחוב": "פת יעקב"
        },
        {
            "_id": 19532,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 4045,
            "שם_רחוב": "צ'ק פוסט"
        },
        {
            "_id": 19533,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1152,
            "שם_רחוב": "צ'רניאבסקי אהרון"
        },
        {
            "_id": 19534,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 459,
            "שם_רחוב": "צביה ויצחק"
        },
        {
            "_id": 19535,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1175,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 19536,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 765,
            "שם_רחוב": "צבר"
        },
        {
            "_id": 19537,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 674,
            "שם_רחוב": "צדקיהו"
        },
        {
            "_id": 19538,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 509,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 19539,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 389,
            "שם_רחוב": "צהיון"
        },
        {
            "_id": 19540,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1411,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 19541,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1026,
            "שם_רחוב": "ציון"
        },
        {
            "_id": 19542,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "ציזלינג אהרון"
        },
        {
            "_id": 19543,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "צייטלין אהרון"
        },
        {
            "_id": 19544,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 521,
            "שם_רחוב": "צילה"
        },
        {
            "_id": 19545,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 851,
            "שם_רחוב": "ציפורי"
        },
        {
            "_id": 19546,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "ציפר"
        },
        {
            "_id": 19547,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 562,
            "שם_רחוב": "ציקלג"
        },
        {
            "_id": 19548,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 759,
            "שם_רחוב": "צלמונה"
        },
        {
            "_id": 19549,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1123,
            "שם_רחוב": "צפורה זייד"
        },
        {
            "_id": 19550,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 654,
            "שם_רחוב": "צפרירים"
        },
        {
            "_id": 19551,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 841,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 19552,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 597,
            "שם_רחוב": "קאודרס בן ציון דר"
        },
        {
            "_id": 19553,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1119,
            "שם_רחוב": "קארו יוסף הרב"
        },
        {
            "_id": 19554,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 19555,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 440,
            "שם_רחוב": "קדושי בגדד"
        },
        {
            "_id": 19556,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 588,
            "שם_רחוב": "קדושי דמשק"
        },
        {
            "_id": 19557,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "קדושי השואה"
        },
        {
            "_id": 19558,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 460,
            "שם_רחוב": "קדושי יאסי"
        },
        {
            "_id": 19559,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 684,
            "שם_רחוב": "קדימה"
        },
        {
            "_id": 19560,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1385,
            "שם_רחוב": "קדיש לוז"
        },
        {
            "_id": 19561,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 672,
            "שם_רחוב": "קדרון"
        },
        {
            "_id": 19562,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 371,
            "שם_רחוב": "קהילת סלוניקי"
        },
        {
            "_id": 19563,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 992,
            "שם_רחוב": "קויפמן יחזקאל פרופ'"
        },
        {
            "_id": 19564,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "קול מבשר"
        },
        {
            "_id": 19565,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1044,
            "שם_רחוב": "קומוי נתן"
        },
        {
            "_id": 19566,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1460,
            "שם_רחוב": "קוסטה ריקה"
        },
        {
            "_id": 19567,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1220,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 19568,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 771,
            "שם_רחוב": "קורצ'אק יאנוש"
        },
        {
            "_id": 19569,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1398,
            "שם_רחוב": "קושניר יוסף"
        },
        {
            "_id": 19570,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 372,
            "שם_רחוב": "קיזרמן נתן"
        },
        {
            "_id": 19571,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1114,
            "שם_רחוב": "קיסרי אורי"
        },
        {
            "_id": 19572,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 391,
            "שם_רחוב": "קיסריה"
        },
        {
            "_id": 19573,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 424,
            "שם_רחוב": "קישלה"
        },
        {
            "_id": 19574,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 531,
            "שם_רחוב": "קלח"
        },
        {
            "_id": 19575,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1095,
            "שם_רחוב": "קליבנוב"
        },
        {
            "_id": 19576,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1047,
            "שם_רחוב": "קלישר הרב"
        },
        {
            "_id": 19577,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 650,
            "שם_רחוב": "קלר"
        },
        {
            "_id": 19578,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1102,
            "שם_רחוב": "קניאל יהושע הרב"
        },
        {
            "_id": 19579,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 907,
            "שם_רחוב": "קניג פייר גנרל"
        },
        {
            "_id": 19580,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1209,
            "שם_רחוב": "קניון חיפה"
        },
        {
            "_id": 19581,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1190,
            "שם_רחוב": "קסטרא"
        },
        {
            "_id": 19582,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 848,
            "שם_רחוב": "קסל"
        },
        {
            "_id": 19583,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1156,
            "שם_רחוב": "קפטן סטיב"
        },
        {
            "_id": 19584,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "קפלן אלישבע"
        },
        {
            "_id": 19585,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 269,
            "שם_רחוב": "קצנשטיין אריה"
        },
        {
            "_id": 19586,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 452,
            "שם_רחוב": "קריב אברהם"
        },
        {
            "_id": 19587,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 627,
            "שם_רחוב": "קריית ספר"
        },
        {
            "_id": 19588,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6552,
            "שם_רחוב": "קרית אליעזר"
        },
        {
            "_id": 19589,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6706,
            "שם_רחוב": "קרית הטכניון"
        },
        {
            "_id": 19590,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6601,
            "שם_רחוב": "קרית חיים מזרחית"
        },
        {
            "_id": 19591,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6602,
            "שם_רחוב": "קרית חיים מערבית"
        },
        {
            "_id": 19592,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "קרית חשמל"
        },
        {
            "_id": 19593,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6604,
            "שם_רחוב": "קרית רבין"
        },
        {
            "_id": 19594,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 798,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 19595,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "קרן קיימת לישראל"
        },
        {
            "_id": 19596,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "קרפל דר"
        },
        {
            "_id": 19597,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1215,
            "שם_רחוב": "קשטן מיכאל"
        },
        {
            "_id": 19598,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 387,
            "שם_רחוב": "ראהבת"
        },
        {
            "_id": 19599,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1083,
            "שם_רחוב": "ראם פנחס"
        },
        {
            "_id": 19600,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1128,
            "שם_רחוב": "ראש מיה"
        },
        {
            "_id": 19601,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 949,
            "שם_רחוב": "ראש פינה"
        },
        {
            "_id": 19602,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 891,
            "שם_רחוב": "רבוה"
        },
        {
            "_id": 19603,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 580,
            "שם_רחוב": "רבי יהודה הנשיא"
        },
        {
            "_id": 19604,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "רבי יוחנן הסנדלר"
        },
        {
            "_id": 19605,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 918,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 19606,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1159,
            "שם_רחוב": "רבין אסתר דר"
        },
        {
            "_id": 19607,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 776,
            "שם_רחוב": "רביצקי אימרה"
        },
        {
            "_id": 19608,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 735,
            "שם_רחוב": "רבנו תם"
        },
        {
            "_id": 19609,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 416,
            "שם_רחוב": "רדק"
        },
        {
            "_id": 19610,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1243,
            "שם_רחוב": "רובי שפירא"
        },
        {
            "_id": 19611,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 482,
            "שם_רחוב": "רובינא חנה"
        },
        {
            "_id": 19612,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1164,
            "שם_רחוב": "רובינשטיין יצחק הרב"
        },
        {
            "_id": 19613,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 557,
            "שם_רחוב": "רודן"
        },
        {
            "_id": 19614,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 462,
            "שם_רחוב": "רודנר עקיבא הרב"
        },
        {
            "_id": 19615,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 297,
            "שם_רחוב": "רוזוב שמואל"
        },
        {
            "_id": 19616,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 451,
            "שם_רחוב": "רוזן פנחס"
        },
        {
            "_id": 19617,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1192,
            "שם_רחוב": "רוטנברג פנחס ואברהם"
        },
        {
            "_id": 19618,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 676,
            "שם_רחוב": "רות"
        },
        {
            "_id": 19619,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1006,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 19620,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 326,
            "שם_רחוב": "רח 580"
        },
        {
            "_id": 19621,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 472,
            "שם_רחוב": "רח 2611"
        },
        {
            "_id": 19622,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1193,
            "שם_רחוב": "רח 2622"
        },
        {
            "_id": 19623,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 893,
            "שם_רחוב": "רח 822"
        },
        {
            "_id": 19624,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1225,
            "שם_רחוב": "רח 2632"
        },
        {
            "_id": 19625,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1194,
            "שם_רחוב": "רח 2623"
        },
        {
            "_id": 19626,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 2653,
            "שם_רחוב": "רח 2653"
        },
        {
            "_id": 19627,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1236,
            "שם_רחוב": "רח 564"
        },
        {
            "_id": 19628,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1196,
            "שם_רחוב": "רח 2625"
        },
        {
            "_id": 19629,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 2635,
            "שם_רחוב": "רח 2635"
        },
        {
            "_id": 19630,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1237,
            "שם_רחוב": "רח 735"
        },
        {
            "_id": 19631,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "רח 665"
        },
        {
            "_id": 19632,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 589,
            "שם_רחוב": "רח 9016"
        },
        {
            "_id": 19633,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 299,
            "שם_רחוב": "רח 666"
        },
        {
            "_id": 19634,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 570,
            "שם_רחוב": "רח 586"
        },
        {
            "_id": 19635,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1098,
            "שם_רחוב": "רח 547"
        },
        {
            "_id": 19636,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 486,
            "שם_רחוב": "רח 2647"
        },
        {
            "_id": 19637,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 487,
            "שם_רחוב": "רח 2648"
        },
        {
            "_id": 19638,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1013,
            "שם_רחוב": "רח 2588"
        },
        {
            "_id": 19639,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "רח 688"
        },
        {
            "_id": 19640,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 2659,
            "שם_רחוב": "רח 2659"
        },
        {
            "_id": 19641,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "רח 689"
        },
        {
            "_id": 19642,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "רח לו"
        },
        {
            "_id": 19643,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "רח לח"
        },
        {
            "_id": 19644,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "רח מא"
        },
        {
            "_id": 19645,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "רח מו"
        },
        {
            "_id": 19646,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רח מח"
        },
        {
            "_id": 19647,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רח נא"
        },
        {
            "_id": 19648,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רח נד"
        },
        {
            "_id": 19649,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח נו"
        },
        {
            "_id": 19650,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רח נט"
        },
        {
            "_id": 19651,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רח סא"
        },
        {
            "_id": 19652,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח סג"
        },
        {
            "_id": 19653,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רח סה"
        },
        {
            "_id": 19654,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רח סז"
        },
        {
            "_id": 19655,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6048,
            "שם_רחוב": "רחבת הטמפלרים"
        },
        {
            "_id": 19656,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6049,
            "שם_רחוב": "רחבת המחוללים"
        },
        {
            "_id": 19657,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6018,
            "שם_רחוב": "רחבת כמיל שחאדה"
        },
        {
            "_id": 19658,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 669,
            "שם_רחוב": "רחל"
        },
        {
            "_id": 19659,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 525,
            "שם_רחוב": "רחף"
        },
        {
            "_id": 19660,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1091,
            "שם_רחוב": "רטנר יוחנן פרופ'"
        },
        {
            "_id": 19661,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "ריינס הרב"
        },
        {
            "_id": 19662,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 710,
            "שם_רחוב": "רימונים"
        },
        {
            "_id": 19663,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 445,
            "שם_רחוב": "רינה"
        },
        {
            "_id": 19664,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 909,
            "שם_רחוב": "רמבן"
        },
        {
            "_id": 19665,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6607,
            "שם_רחוב": "רמות ספיר"
        },
        {
            "_id": 19666,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6610,
            "שם_רחוב": "רמות רמז"
        },
        {
            "_id": 19667,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6708,
            "שם_רחוב": "רמת אלון"
        },
        {
            "_id": 19668,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6596,
            "שם_רחוב": "רמת אלמוגי"
        },
        {
            "_id": 19669,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6595,
            "שם_רחוב": "רמת אשכול"
        },
        {
            "_id": 19670,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6597,
            "שם_רחוב": "רמת בגין"
        },
        {
            "_id": 19671,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6599,
            "שם_רחוב": "רמת בן גוריון"
        },
        {
            "_id": 19672,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6592,
            "שם_רחוב": "רמת גולדה"
        },
        {
            "_id": 19673,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6660,
            "שם_רחוב": "רמת גוראל"
        },
        {
            "_id": 19674,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6614,
            "שם_רחוב": "רמת התשבי"
        },
        {
            "_id": 19675,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6609,
            "שם_רחוב": "רמת חן"
        },
        {
            "_id": 19676,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 620,
            "שם_רחוב": "רנק"
        },
        {
            "_id": 19677,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 694,
            "שם_רחוב": "רענן"
        },
        {
            "_id": 19678,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 910,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 19679,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש אנגלו סכסים"
        },
        {
            "_id": 19680,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש אסבסט קרית חיים"
        },
        {
            "_id": 19681,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש אקדמאים"
        },
        {
            "_id": 19682,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6675,
            "שם_רחוב": "ש בולגרים חל"
        },
        {
            "_id": 19683,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6593,
            "שם_רחוב": "ש הוד הכרמל"
        },
        {
            "_id": 19684,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6554,
            "שם_רחוב": "ש ח משוח בת גלים"
        },
        {
            "_id": 19685,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש חדש קרית שמואל"
        },
        {
            "_id": 19686,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש חילים משוחררים"
        },
        {
            "_id": 19687,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "ש משכנות ק שמואל"
        },
        {
            "_id": 19688,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6676,
            "שם_רחוב": "ש נוה יוסף"
        },
        {
            "_id": 19689,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "ש סוכנות ק שמואל"
        },
        {
            "_id": 19690,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "ש ערוני מפרץ"
        },
        {
            "_id": 19691,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6557,
            "שם_רחוב": "ש צהל"
        },
        {
            "_id": 19692,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "ש קרית שמואל ב"
        },
        {
            "_id": 19693,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6577,
            "שם_רחוב": "ש קרית שפרינצק"
        },
        {
            "_id": 19694,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6650,
            "שם_רחוב": "ש רמת גאולה"
        },
        {
            "_id": 19695,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6636,
            "שם_רחוב": "ש רמת הדר"
        },
        {
            "_id": 19696,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6651,
            "שם_רחוב": "ש רמת ויזניץ"
        },
        {
            "_id": 19697,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6625,
            "שם_רחוב": "ש רמת שאול"
        },
        {
            "_id": 19698,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 493,
            "שם_רחוב": "שאגאל מארק"
        },
        {
            "_id": 19699,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1028,
            "שם_רחוב": "שאול"
        },
        {
            "_id": 19700,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 578,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 19701,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "שאר ישוב"
        },
        {
            "_id": 19702,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 978,
            "שם_רחוב": "שארית הפליטה"
        },
        {
            "_id": 19703,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 773,
            "שם_רחוב": "שבדיה"
        },
        {
            "_id": 19704,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1118,
            "שם_רחוב": "שבזי שלום"
        },
        {
            "_id": 19705,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "שבטי ישראל"
        },
        {
            "_id": 19706,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 777,
            "שם_רחוב": "שביל אהבת שלום"
        },
        {
            "_id": 19707,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 478,
            "שם_רחוב": "שביל חנא נקארה"
        },
        {
            "_id": 19708,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "שביל פומרוק משה"
        },
        {
            "_id": 19709,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1122,
            "שם_רחוב": "שביל רבקה"
        },
        {
            "_id": 19710,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "שבעת המינים"
        },
        {
            "_id": 19711,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 403,
            "שם_רחוב": "שבתאי לוי"
        },
        {
            "_id": 19712,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 602,
            "שם_רחוב": "שד אבא חושי"
        },
        {
            "_id": 19713,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 399,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 19714,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 546,
            "שם_רחוב": "שד בת גלים"
        },
        {
            "_id": 19715,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 816,
            "שם_רחוב": "שד גולומב אליהו"
        },
        {
            "_id": 19716,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "שד דגניה"
        },
        {
            "_id": 19717,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 652,
            "שם_רחוב": "שד הברושים"
        },
        {
            "_id": 19718,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1161,
            "שם_רחוב": "שד ההגנה"
        },
        {
            "_id": 19719,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "שד ההסתדרות"
        },
        {
            "_id": 19720,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1038,
            "שם_רחוב": "שד הלה"
        },
        {
            "_id": 19721,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 360,
            "שם_רחוב": "שד המגינים"
        },
        {
            "_id": 19722,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 550,
            "שם_רחוב": "שד המתנדבים"
        },
        {
            "_id": 19723,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 666,
            "שם_רחוב": "שד הנדיב"
        },
        {
            "_id": 19724,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 635,
            "שם_רחוב": "שד הנשיא"
        },
        {
            "_id": 19725,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 687,
            "שם_רחוב": "שד הצבי"
        },
        {
            "_id": 19726,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 811,
            "שם_רחוב": "שד הציונות"
        },
        {
            "_id": 19727,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "שד הראה"
        },
        {
            "_id": 19728,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 912,
            "שם_רחוב": "שד וינגיט"
        },
        {
            "_id": 19729,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "שד ורבורג"
        },
        {
            "_id": 19730,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1031,
            "שם_רחוב": "שד טרומפלדור"
        },
        {
            "_id": 19731,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 689,
            "שם_רחוב": "שד יצחק"
        },
        {
            "_id": 19732,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 625,
            "שם_רחוב": "שד מוריה"
        },
        {
            "_id": 19733,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "שד מחל"
        },
        {
            "_id": 19734,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 623,
            "שם_רחוב": "שד סיני"
        },
        {
            "_id": 19735,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 915,
            "שם_רחוב": "שד קיש"
        },
        {
            "_id": 19736,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 586,
            "שם_רחוב": "שד קרית אליעזר"
        },
        {
            "_id": 19737,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1135,
            "שם_רחוב": "שד רוז"
        },
        {
            "_id": 19738,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 505,
            "שם_רחוב": "שד רוטשילד"
        },
        {
            "_id": 19739,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1057,
            "שם_רחוב": "שד רות הכהן"
        },
        {
            "_id": 19740,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1136,
            "שם_רחוב": "שד שיקגו"
        },
        {
            "_id": 19741,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1197,
            "שם_רחוב": "שד' אפרים קציר"
        },
        {
            "_id": 19742,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1511,
            "שם_רחוב": "שד' מייק ברנט"
        },
        {
            "_id": 19743,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1240,
            "שם_רחוב": "שד' עזר וייצמן"
        },
        {
            "_id": 19744,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שדה בוקר"
        },
        {
            "_id": 19745,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6534,
            "שם_רחוב": "שדה התעופה"
        },
        {
            "_id": 19746,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 421,
            "שם_רחוב": "שדה יצחק"
        },
        {
            "_id": 19747,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1239,
            "שם_רחוב": "שדרות הדקלים"
        },
        {
            "_id": 19748,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1238,
            "שם_רחוב": "שהם צבי"
        },
        {
            "_id": 19749,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 954,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 19750,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 490,
            "שם_רחוב": "שוויצר אלברט דר"
        },
        {
            "_id": 19751,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 419,
            "שם_רחוב": "שומאכר גוטליב"
        },
        {
            "_id": 19752,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 725,
            "שם_רחוב": "שומרון"
        },
        {
            "_id": 19753,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 566,
            "שם_רחוב": "שונית"
        },
        {
            "_id": 19754,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 728,
            "שם_רחוב": "שונמית"
        },
        {
            "_id": 19755,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 989,
            "שם_רחוב": "שופמן גרשון"
        },
        {
            "_id": 19756,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1263,
            "שם_רחוב": "שושנה דמארי"
        },
        {
            "_id": 19757,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 702,
            "שם_רחוב": "שושנת הכרמל"
        },
        {
            "_id": 19758,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 453,
            "שם_רחוב": "שזר זלמן"
        },
        {
            "_id": 19759,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1166,
            "שם_רחוב": "שחאדה שלח"
        },
        {
            "_id": 19760,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1226,
            "שם_רחוב": "שחם"
        },
        {
            "_id": 19761,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "שטיין ליאון"
        },
        {
            "_id": 19762,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 970,
            "שם_רחוב": "שטרן יאיר"
        },
        {
            "_id": 19763,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "שטרן יוסף"
        },
        {
            "_id": 19764,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 337,
            "שם_רחוב": "שיבת ציון"
        },
        {
            "_id": 19765,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "שיטרית בכור"
        },
        {
            "_id": 19766,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 872,
            "שם_רחוב": "שימקין דר"
        },
        {
            "_id": 19767,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1068,
            "שם_רחוב": "שינדלר אוסקר"
        },
        {
            "_id": 19768,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "שכ אחוזה"
        },
        {
            "_id": 19769,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6555,
            "שם_רחוב": "שכ גאולה"
        },
        {
            "_id": 19770,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6558,
            "שם_רחוב": "שכ החוף השקט"
        },
        {
            "_id": 19771,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "שכ המגינים"
        },
        {
            "_id": 19772,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "שכ המושבה הגרמנית"
        },
        {
            "_id": 19773,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ הרכבת"
        },
        {
            "_id": 19774,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ הרצליה"
        },
        {
            "_id": 19775,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6652,
            "שם_רחוב": "שכ ואדי טינה"
        },
        {
            "_id": 19776,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "שכ ואדי ניסנאס"
        },
        {
            "_id": 19777,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6578,
            "שם_רחוב": "שכ ואדי שיאח"
        },
        {
            "_id": 19778,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6608,
            "שם_רחוב": "שכ ורדיה"
        },
        {
            "_id": 19779,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6589,
            "שם_רחוב": "שכ זיו"
        },
        {
            "_id": 19780,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6677,
            "שם_רחוב": "שכ חליסא"
        },
        {
            "_id": 19781,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6553,
            "שם_רחוב": "שכ יזרעאליה"
        },
        {
            "_id": 19782,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "שכ יצחק נבון"
        },
        {
            "_id": 19783,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6579,
            "שם_רחוב": "שכ כפר סמיר"
        },
        {
            "_id": 19784,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "שכ כרמל צרפתי"
        },
        {
            "_id": 19785,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6605,
            "שם_רחוב": "שכ כרמליה"
        },
        {
            "_id": 19786,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6556,
            "שם_רחוב": "שכ מרכז הדר"
        },
        {
            "_id": 19787,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "שכ נאות פרס"
        },
        {
            "_id": 19788,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6674,
            "שם_רחוב": "שכ נווה גנים"
        },
        {
            "_id": 19789,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "שכ נווה שאנן"
        },
        {
            "_id": 19790,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "שכ נחלה"
        },
        {
            "_id": 19791,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "שכ קרית אליהו"
        },
        {
            "_id": 19792,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ קרית חיים"
        },
        {
            "_id": 19793,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "שכ קרית שמואל"
        },
        {
            "_id": 19794,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6606,
            "שם_רחוב": "שכ רוממה"
        },
        {
            "_id": 19795,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6616,
            "שם_רחוב": "שכ שמבור"
        },
        {
            "_id": 19796,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6598,
            "שם_רחוב": "שכ שער העליה"
        },
        {
            "_id": 19797,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "שכ תל עמל"
        },
        {
            "_id": 19798,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 631,
            "שם_רחוב": "שכטר יוסף דר"
        },
        {
            "_id": 19799,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1069,
            "שם_רחוב": "שלג"
        },
        {
            "_id": 19800,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1254,
            "שם_רחוב": "שלהוב ג'מיל"
        },
        {
            "_id": 19801,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1048,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 19802,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 590,
            "שם_רחוב": "שלומציון המלכה"
        },
        {
            "_id": 19803,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1395,
            "שם_רחוב": "שלון"
        },
        {
            "_id": 19804,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 781,
            "שם_רחוב": "שלונסקי"
        },
        {
            "_id": 19805,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 466,
            "שם_רחוב": "שלוסברג שמואל דר"
        },
        {
            "_id": 19806,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 561,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 19807,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 827,
            "שם_רחוב": "שמאי"
        },
        {
            "_id": 19808,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 828,
            "שם_רחוב": "שמואל"
        },
        {
            "_id": 19809,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1070,
            "שם_רחוב": "שמעוני לייב"
        },
        {
            "_id": 19810,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 847,
            "שם_רחוב": "שמריהו לוין"
        },
        {
            "_id": 19811,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 877,
            "שם_רחוב": "שמש"
        },
        {
            "_id": 19812,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 634,
            "שם_רחוב": "שמשון"
        },
        {
            "_id": 19813,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1059,
            "שם_רחוב": "שניאור זלמן"
        },
        {
            "_id": 19814,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 900,
            "שם_רחוב": "שניים בנובמבר"
        },
        {
            "_id": 19815,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "שנקר אריה"
        },
        {
            "_id": 19816,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 700,
            "שם_רחוב": "שער הגיא"
        },
        {
            "_id": 19817,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 641,
            "שם_רחוב": "שער הלבנון"
        },
        {
            "_id": 19818,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1085,
            "שם_רחוב": "שער פלמר"
        },
        {
            "_id": 19819,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 874,
            "שם_רחוב": "שפירא"
        },
        {
            "_id": 19820,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 807,
            "שם_רחוב": "שפרה"
        },
        {
            "_id": 19821,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 852,
            "שם_רחוב": "שפרעם"
        },
        {
            "_id": 19822,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 764,
            "שם_רחוב": "שקמה"
        },
        {
            "_id": 19823,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 547,
            "שם_רחוב": "שקמונה"
        },
        {
            "_id": 19824,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 356,
            "שם_רחוב": "שרה"
        },
        {
            "_id": 19825,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1129,
            "שם_רחוב": "שרעבי שלום רבי"
        },
        {
            "_id": 19826,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 19827,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 19828,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 463,
            "שם_רחוב": "תאדל"
        },
        {
            "_id": 19829,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1174,
            "שם_רחוב": "תאשור"
        },
        {
            "_id": 19830,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1470,
            "שם_רחוב": "תבואות הארץ"
        },
        {
            "_id": 19831,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1173,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 19832,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "תובל"
        },
        {
            "_id": 19833,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1143,
            "שם_רחוב": "תומא אמיל"
        },
        {
            "_id": 19834,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "תורה ועבודה"
        },
        {
            "_id": 19835,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 545,
            "שם_רחוב": "תחנה מרכזית"
        },
        {
            "_id": 19836,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 439,
            "שם_רחוב": "תחנת הכרמל"
        },
        {
            "_id": 19837,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 506,
            "שם_רחוב": "תל אביב"
        },
        {
            "_id": 19838,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 901,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 19839,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 616,
            "שם_רחוב": "תל מאנה"
        },
        {
            "_id": 19840,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1064,
            "שם_רחוב": "תל עמל"
        },
        {
            "_id": 19841,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 6594,
            "שם_רחוב": "תל שקמונה"
        },
        {
            "_id": 19842,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 1078,
            "שם_רחוב": "תענך"
        },
        {
            "_id": 19843,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "תריג"
        },
        {
            "_id": 19844,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 336,
            "שם_רחוב": "תרצה"
        },
        {
            "_id": 19845,
            "סמל_ישוב": 4000,
            "שם_ישוב": "חיפה ",
            "סמל_רחוב": 298,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 19846,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 114,
            "שם_רחוב": "בת-חן"
        },
        {
            "_id": 19847,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 123,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 19848,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך המייסדים"
        },
        {
            "_id": 19849,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 124,
            "שם_רחוב": "דרך הפרדס"
        },
        {
            "_id": 19850,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דרך השדות"
        },
        {
            "_id": 19851,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דרך השקמים"
        },
        {
            "_id": 19852,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 112,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 19853,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 109,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 19854,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 105,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 19855,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הבאר"
        },
        {
            "_id": 19856,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 19857,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 108,
            "שם_רחוב": "ההרחבה"
        },
        {
            "_id": 19858,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המשתלה"
        },
        {
            "_id": 19859,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הפיקוס"
        },
        {
            "_id": 19860,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 19861,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 19862,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 111,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 19863,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 118,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 19864,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חירות"
        },
        {
            "_id": 19865,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 117,
            "שם_רחוב": "נוף"
        },
        {
            "_id": 19866,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 122,
            "שם_רחוב": "סמ החצב"
        },
        {
            "_id": 19867,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 121,
            "שם_רחוב": "סמ הכלנית"
        },
        {
            "_id": 19868,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 120,
            "שם_רחוב": "סמ העירית"
        },
        {
            "_id": 19869,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 113,
            "שם_רחוב": "עין התכלת"
        },
        {
            "_id": 19870,
            "סמל_ישוב": 162,
            "שם_ישוב": "חירות ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ בנים"
        },
        {
            "_id": 19871,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ארזים"
        },
        {
            "_id": 19872,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 127,
            "שם_רחוב": "גינת אגוז"
        },
        {
            "_id": 19873,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 103,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 19874,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גנים"
        },
        {
            "_id": 19875,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הבאר"
        },
        {
            "_id": 19876,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הדודאים"
        },
        {
            "_id": 19877,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ההרים"
        },
        {
            "_id": 19878,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 19879,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הכפר הסקנדינבי"
        },
        {
            "_id": 19880,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 109,
            "שם_רחוב": "חבצלת השרון"
        },
        {
            "_id": 19881,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 110,
            "שם_רחוב": "חגווי הסלע"
        },
        {
            "_id": 19882,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 111,
            "שם_רחוב": "חוט השני"
        },
        {
            "_id": 19883,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חלוץ"
        },
        {
            "_id": 19884,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 112,
            "שם_רחוב": "כרמי"
        },
        {
            "_id": 19885,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מור ולבונה"
        },
        {
            "_id": 19886,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מים חיים"
        },
        {
            "_id": 19887,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 122,
            "שם_רחוב": "משכנות הרועים"
        },
        {
            "_id": 19888,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 115,
            "שם_רחוב": "ניצנים"
        },
        {
            "_id": 19889,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 116,
            "שם_רחוב": "סמטת מחמדים"
        },
        {
            "_id": 19890,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 117,
            "שם_רחוב": "סמטת שיר השירים"
        },
        {
            "_id": 19891,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 118,
            "שם_רחוב": "עצי היער"
        },
        {
            "_id": 19892,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ערוגות הבושם"
        },
        {
            "_id": 19893,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 120,
            "שם_רחוב": "פלח הרימון"
        },
        {
            "_id": 19894,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 121,
            "שם_רחוב": "פרי מגדים"
        },
        {
            "_id": 19895,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רסיסי לילה"
        },
        {
            "_id": 19896,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 124,
            "שם_רחוב": "שושנת העמקים"
        },
        {
            "_id": 19897,
            "סמל_ישוב": 1272,
            "שם_ישוב": "חלוץ ",
            "סמל_רחוב": 125,
            "שם_רחוב": "תלפיות"
        },
        {
            "_id": 19898,
            "סמל_ישוב": 820,
            "שם_ישוב": "חלץ ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חלץ"
        },
        {
            "_id": 19899,
            "סמל_ישוב": 993,
            "שם_ישוב": "חמאם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חמאם"
        },
        {
            "_id": 19900,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 19901,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 19902,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 103,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 19903,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הנחלים"
        },
        {
            "_id": 19904,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 106,
            "שם_רחוב": "העומר"
        },
        {
            "_id": 19905,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 19906,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 19907,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 19908,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חמד"
        },
        {
            "_id": 19909,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 119,
            "שם_רחוב": "נחל איילון"
        },
        {
            "_id": 19910,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 109,
            "שם_רחוב": "נחל ארבל"
        },
        {
            "_id": 19911,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 117,
            "שם_רחוב": "נחל גבעות"
        },
        {
            "_id": 19912,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 115,
            "שם_רחוב": "נחל דולב"
        },
        {
            "_id": 19913,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 118,
            "שם_רחוב": "נחל דן"
        },
        {
            "_id": 19914,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נחל הבשור"
        },
        {
            "_id": 19915,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 116,
            "שם_רחוב": "נחל הירדן"
        },
        {
            "_id": 19916,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 114,
            "שם_רחוב": "נחל זרי הדפנה"
        },
        {
            "_id": 19917,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 108,
            "שם_רחוב": "נחל חרמון"
        },
        {
            "_id": 19918,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נחל שפירים"
        },
        {
            "_id": 19919,
            "סמל_ישוב": 801,
            "שם_ישוב": "חמד ",
            "סמל_רחוב": 110,
            "שם_רחוב": "נחל תבור"
        },
        {
            "_id": 19920,
            "סמל_ישוב": 343,
            "שם_ישוב": "חמדיה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 19921,
            "סמל_ישוב": 343,
            "שם_ישוב": "חמדיה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חמדיה"
        },
        {
            "_id": 19922,
            "סמל_ישוב": 3646,
            "שם_ישוב": "חמדת ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 19923,
            "סמל_ישוב": 3646,
            "שם_ישוב": "חמדת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 19924,
            "סמל_ישוב": 3646,
            "שם_ישוב": "חמדת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 19925,
            "סמל_ישוב": 3646,
            "שם_ישוב": "חמדת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חמדת"
        },
        {
            "_id": 19926,
            "סמל_ישוב": 3646,
            "שם_ישוב": "חמדת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "כביר"
        },
        {
            "_id": 19927,
            "סמל_ישוב": 3646,
            "שם_ישוב": "חמדת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "מעבר יבוק"
        },
        {
            "_id": 19928,
            "סמל_ישוב": 3646,
            "שם_ישוב": "חמדת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "משעול גוש קטיף"
        },
        {
            "_id": 19929,
            "סמל_ישוב": 3609,
            "שם_ישוב": "חמרה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חמרה"
        },
        {
            "_id": 19930,
            "סמל_ישוב": 807,
            "שם_ישוב": "חניאל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 19931,
            "סמל_ישוב": 807,
            "שם_ישוב": "חניאל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגבעה"
        },
        {
            "_id": 19932,
            "סמל_ישוב": 807,
            "שם_ישוב": "חניאל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הגיא"
        },
        {
            "_id": 19933,
            "סמל_ישוב": 807,
            "שם_ישוב": "חניאל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 19934,
            "סמל_ישוב": 807,
            "שם_ישוב": "חניאל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 19935,
            "סמל_ישוב": 807,
            "שם_ישוב": "חניאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חניאל"
        },
        {
            "_id": 19936,
            "סמל_ישוב": 807,
            "שם_ישוב": "חניאל ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר אורנים"
        },
        {
            "_id": 19937,
            "סמל_ישוב": 807,
            "שם_ישוב": "חניאל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סמ הבאר"
        },
        {
            "_id": 19938,
            "סמל_ישוב": 807,
            "שם_ישוב": "חניאל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "סמ הפרדס"
        },
        {
            "_id": 19939,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אולפן קבוץ"
        },
        {
            "_id": 19940,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך גן עדן"
        },
        {
            "_id": 19941,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך הבריכה"
        },
        {
            "_id": 19942,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך הזיתים"
        },
        {
            "_id": 19943,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך השער המזרחי"
        },
        {
            "_id": 19944,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך חומה ומגדל"
        },
        {
            "_id": 19945,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך מגדל המים"
        },
        {
            "_id": 19946,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך עין חור"
        },
        {
            "_id": 19947,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חניתה"
        },
        {
            "_id": 19948,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מורד הגיא"
        },
        {
            "_id": 19949,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מעלה דורבנים"
        },
        {
            "_id": 19950,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "מעלה הגבעה"
        },
        {
            "_id": 19951,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שביל אלון"
        },
        {
            "_id": 19952,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "שביל ברוש"
        },
        {
            "_id": 19953,
            "סמל_ישוב": 280,
            "שם_ישוב": "חניתה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "שביל קטלב"
        },
        {
            "_id": 19954,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "איבי הנחל"
        },
        {
            "_id": 19955,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אמת המים"
        },
        {
            "_id": 19956,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "באר מרים"
        },
        {
            "_id": 19957,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "גשמי ברכה"
        },
        {
            "_id": 19958,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך האגם"
        },
        {
            "_id": 19959,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דרך נאות מדבר"
        },
        {
            "_id": 19960,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חנתון"
        },
        {
            "_id": 19961,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "טל ומטר"
        },
        {
            "_id": 19962,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "יובל"
        },
        {
            "_id": 19963,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מעין"
        },
        {
            "_id": 19964,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "משעול האפיק"
        },
        {
            "_id": 19965,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נווה גנים"
        },
        {
            "_id": 19966,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "פלג"
        },
        {
            "_id": 19967,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רביבים"
        },
        {
            "_id": 19968,
            "סמל_ישוב": 1257,
            "שם_ישוב": "חנתון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שירת הים"
        },
        {
            "_id": 19969,
            "סמל_ישוב": 4005,
            "שם_ישוב": "חספין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חספין"
        },
        {
            "_id": 19970,
            "סמל_ישוב": 363,
            "שם_ישוב": "חפץ חיים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חפץ חיים"
        },
        {
            "_id": 19971,
            "סמל_ישוב": 363,
            "שם_ישוב": "חפץ חיים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 19972,
            "סמל_ישוב": 90,
            "שם_ישוב": "חפצי-בה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חפצי-בה"
        },
        {
            "_id": 19973,
            "סמל_ישוב": 700,
            "שם_ישוב": "חצב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 19974,
            "סמל_ישוב": 13,
            "שם_ישוב": "חצבה ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "ביס שדה חצבה"
        },
        {
            "_id": 19975,
            "סמל_ישוב": 13,
            "שם_ישוב": "חצבה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חצבה"
        },
        {
            "_id": 19976,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אבני נחל"
        },
        {
            "_id": 19977,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "אזור התעשייה"
        },
        {
            "_id": 19978,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אלימלך נועם"
        },
        {
            "_id": 19979,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 19980,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 115,
            "שם_רחוב": "בן גוריון"
        },
        {
            "_id": 19981,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 127,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 19982,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 201,
            "שם_רחוב": "בן ציון"
        },
        {
            "_id": 19983,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 120,
            "שם_רחוב": "בנה ביתך"
        },
        {
            "_id": 19984,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 19985,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך הבנים"
        },
        {
            "_id": 19986,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך החולה"
        },
        {
            "_id": 19987,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 205,
            "שם_רחוב": "דרך קקל"
        },
        {
            "_id": 19988,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 126,
            "שם_רחוב": "דרך רבין"
        },
        {
            "_id": 19989,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 133,
            "שם_רחוב": "האגס"
        },
        {
            "_id": 19990,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 183,
            "שם_רחוב": "האומן"
        },
        {
            "_id": 19991,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 187,
            "שם_רחוב": "האורג"
        },
        {
            "_id": 19992,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 135,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 19993,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 159,
            "שם_רחוב": "האירוס"
        },
        {
            "_id": 19994,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 198,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 19995,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 144,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 19996,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 181,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 19997,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 178,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 19998,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 182,
            "שם_רחוב": "האשחר"
        },
        {
            "_id": 19999,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 20000,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 20001,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 188,
            "שם_רחוב": "הבנאי"
        },
        {
            "_id": 20002,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 152,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 20003,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 171,
            "שם_רחוב": "הברושים"
        },
        {
            "_id": 20004,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 20005,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 139,
            "שם_רחוב": "הדובדבן"
        },
        {
            "_id": 20006,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 196,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 20007,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 154,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 20008,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 204,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 20009,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 163,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 20010,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 189,
            "שם_רחוב": "הזגג"
        },
        {
            "_id": 20011,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 134,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 20012,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 108,
            "שם_רחוב": "החסידה"
        },
        {
            "_id": 20013,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 150,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 20014,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 190,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 20015,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 157,
            "שם_רחוב": "היקינטון"
        },
        {
            "_id": 20016,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 143,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 20017,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 131,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 20018,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 184,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 20019,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 186,
            "שם_רחוב": "הנגר"
        },
        {
            "_id": 20020,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 202,
            "שם_רחוב": "הנחליאלי"
        },
        {
            "_id": 20021,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 185,
            "שם_רחוב": "הנפח"
        },
        {
            "_id": 20022,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הנשיא ויצמן"
        },
        {
            "_id": 20023,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הנשר"
        },
        {
            "_id": 20024,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 158,
            "שם_רחוב": "הסחלב"
        },
        {
            "_id": 20025,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 194,
            "שם_רחוב": "הסנונית"
        },
        {
            "_id": 20026,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 160,
            "שם_רחוב": "הסתונית"
        },
        {
            "_id": 20027,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 129,
            "שם_רחוב": "העינב"
        },
        {
            "_id": 20028,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 162,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 20029,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 180,
            "שם_רחוב": "הפיקוס"
        },
        {
            "_id": 20030,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 193,
            "שם_רחוב": "הפשוש"
        },
        {
            "_id": 20031,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 142,
            "שם_רחוב": "הצפצפה"
        },
        {
            "_id": 20032,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 197,
            "שם_רחוב": "הקטלב"
        },
        {
            "_id": 20033,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הרב מורגנשטרן"
        },
        {
            "_id": 20034,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 146,
            "שם_רחוב": "הרב פלדמן משה זאב"
        },
        {
            "_id": 20035,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 20036,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 20037,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 20038,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 136,
            "שם_רחוב": "השזיף"
        },
        {
            "_id": 20039,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 192,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 20040,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 151,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 20041,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 122,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 20042,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 128,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 20043,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 137,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 20044,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "חוות ינאי"
        },
        {
            "_id": 20045,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 102,
            "שם_רחוב": "חוני המעגל"
        },
        {
            "_id": 20046,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 147,
            "שם_רחוב": "חזון ישראל"
        },
        {
            "_id": 20047,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 119,
            "שם_רחוב": "חצור א"
        },
        {
            "_id": 20048,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חצור הגלילית"
        },
        {
            "_id": 20049,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 145,
            "שם_רחוב": "טעם הצבי"
        },
        {
            "_id": 20050,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 179,
            "שם_רחוב": "יונתן בן עוזיאל"
        },
        {
            "_id": 20051,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 116,
            "שם_רחוב": "יצחק שמיר"
        },
        {
            "_id": 20052,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 138,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 20053,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 167,
            "שם_רחוב": "יששכר בני"
        },
        {
            "_id": 20054,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 199,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 20055,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 203,
            "שם_רחוב": "לוי אשכול"
        },
        {
            "_id": 20056,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 175,
            "שם_רחוב": "מבוא התנאים"
        },
        {
            "_id": 20057,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 195,
            "שם_רחוב": "מבוא מצליח"
        },
        {
            "_id": 20058,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 206,
            "שם_רחוב": "מבוא שלמה"
        },
        {
            "_id": 20059,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 169,
            "שם_רחוב": "מנחם"
        },
        {
            "_id": 20060,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מנחם בגין"
        },
        {
            "_id": 20061,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 164,
            "שם_רחוב": "מעלה הקרייה"
        },
        {
            "_id": 20062,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "נוה הרים"
        },
        {
            "_id": 20063,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "נוף המושבה"
        },
        {
            "_id": 20064,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 148,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 20065,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 140,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 20066,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 165,
            "שם_רחוב": "נתיבות שלום"
        },
        {
            "_id": 20067,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 141,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 20068,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 101,
            "שם_רחוב": "סולד הנריטה"
        },
        {
            "_id": 20069,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 110,
            "שם_רחוב": "סמ הכרכום"
        },
        {
            "_id": 20070,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 172,
            "שם_רחוב": "קדושת לוי"
        },
        {
            "_id": 20071,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "קריה חסידית"
        },
        {
            "_id": 20072,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "קרית בלוך"
        },
        {
            "_id": 20073,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "קרית החנוך"
        },
        {
            "_id": 20074,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 174,
            "שם_רחוב": "רבי טרפון"
        },
        {
            "_id": 20075,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 177,
            "שם_רחוב": "רבי מאיר בעל הנס"
        },
        {
            "_id": 20076,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 176,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 20077,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "רובע מרכז"
        },
        {
            "_id": 20078,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 20079,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רמון אילן"
        },
        {
            "_id": 20080,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 20081,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 118,
            "שם_רחוב": "שד הרב עזיזי דיעי"
        },
        {
            "_id": 20082,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ ארקיע"
        },
        {
            "_id": 20083,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ אשטרום"
        },
        {
            "_id": 20084,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ בנה ביתך"
        },
        {
            "_id": 20085,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ הבוסתן"
        },
        {
            "_id": 20086,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ הכרמים"
        },
        {
            "_id": 20087,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ זאב"
        },
        {
            "_id": 20088,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 168,
            "שם_רחוב": "שם שמואל"
        },
        {
            "_id": 20089,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 156,
            "שם_רחוב": "שער הרחמים"
        },
        {
            "_id": 20090,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 173,
            "שם_רחוב": "שפירא מאיר"
        },
        {
            "_id": 20091,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 113,
            "שם_רחוב": "שפרינצק"
        },
        {
            "_id": 20092,
            "סמל_ישוב": 2034,
            "שם_ישוב": "חצור הגלילית ",
            "סמל_רחוב": 123,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 20093,
            "סמל_ישוב": 406,
            "שם_ישוב": "חצור-אשדוד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חצור-אשדוד"
        },
        {
            "_id": 20094,
            "סמל_ישוב": 397,
            "שם_ישוב": "חצרים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חצרים"
        },
        {
            "_id": 20095,
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "בית ספר תום"
        },
        {
            "_id": 20096,
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 20097,
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 20098,
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 20099,
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 20100,
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 20101,
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 20102,
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 20103,
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 20104,
            "סמל_ישוב": 422,
            "שם_ישוב": "חרב לאת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חרב לאת"
        },
        {
            "_id": 20105,
            "סמל_ישוב": 1024,
            "שם_ישוב": "חרוצים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 20106,
            "סמל_ישוב": 1024,
            "שם_ישוב": "חרוצים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 20107,
            "סמל_ישוב": 1024,
            "שם_ישוב": "חרוצים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 20108,
            "סמל_ישוב": 1024,
            "שם_ישוב": "חרוצים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 20109,
            "סמל_ישוב": 1024,
            "שם_ישוב": "חרוצים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 20110,
            "סמל_ישוב": 1024,
            "שם_ישוב": "חרוצים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 20111,
            "סמל_ישוב": 1024,
            "שם_ישוב": "חרוצים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 20112,
            "סמל_ישוב": 1024,
            "שם_ישוב": "חרוצים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חרוצים"
        },
        {
            "_id": 20113,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אביטל"
        },
        {
            "_id": 20114,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "אבני חן"
        },
        {
            "_id": 20115,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אדום"
        },
        {
            "_id": 20116,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 310,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 20117,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 20118,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 344,
            "שם_רחוב": "אחדות"
        },
        {
            "_id": 20119,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 327,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 20120,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 20121,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 324,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 20122,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 326,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 20123,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 321,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 20124,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 306,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 20125,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 110,
            "שם_רחוב": "גמלא"
        },
        {
            "_id": 20126,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 107,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 20127,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 322,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 20128,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 126,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 20129,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 303,
            "שם_רחוב": "דרך ארץ"
        },
        {
            "_id": 20130,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 20131,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 20132,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 350,
            "שם_רחוב": "החלומות"
        },
        {
            "_id": 20133,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 341,
            "שם_רחוב": "הקהילה"
        },
        {
            "_id": 20134,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הר תבור"
        },
        {
            "_id": 20135,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 352,
            "שם_רחוב": "השראה"
        },
        {
            "_id": 20136,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 354,
            "שם_רחוב": "התחדשות"
        },
        {
            "_id": 20137,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 353,
            "שם_רחוב": "התמדה"
        },
        {
            "_id": 20138,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 323,
            "שם_רחוב": "זית"
        },
        {
            "_id": 20139,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 343,
            "שם_רחוב": "חברותא"
        },
        {
            "_id": 20140,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 313,
            "שם_רחוב": "חושן"
        },
        {
            "_id": 20141,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חריש"
        },
        {
            "_id": 20142,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 308,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 20143,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 307,
            "שם_רחוב": "טורקיז"
        },
        {
            "_id": 20144,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 342,
            "שם_רחוב": "יחד"
        },
        {
            "_id": 20145,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 332,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 20146,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 331,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 20147,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 336,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 20148,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 335,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 20149,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 309,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 20150,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 311,
            "שם_רחוב": "מרגלית"
        },
        {
            "_id": 20151,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 333,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 20152,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 338,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 20153,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 337,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 20154,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 305,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 20155,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 312,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 20156,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 325,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 20157,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 347,
            "שם_רחוב": "ערבות"
        },
        {
            "_id": 20158,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 328,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 20159,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 334,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 20160,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 351,
            "שם_רחוב": "קידמה"
        },
        {
            "_id": 20161,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 314,
            "שם_רחוב": "רובין"
        },
        {
            "_id": 20162,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 20163,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 345,
            "שם_רחוב": "רעות"
        },
        {
            "_id": 20164,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 339,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 20165,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 330,
            "שם_רחוב": "שדרות האחווה"
        },
        {
            "_id": 20166,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 340,
            "שם_רחוב": "שדרות ההגשמה"
        },
        {
            "_id": 20167,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 304,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 20168,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 346,
            "שם_רחוב": "שותפות"
        },
        {
            "_id": 20169,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "שכונת בצוותא"
        },
        {
            "_id": 20170,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכונת הפרחים"
        },
        {
            "_id": 20171,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכונת מעוף"
        },
        {
            "_id": 20172,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 329,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 20173,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 320,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 20174,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 114,
            "שם_רחוב": "תמנע"
        },
        {
            "_id": 20175,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 120,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 20176,
            "סמל_ישוב": 1247,
            "שם_ישוב": "חריש ",
            "סמל_רחוב": 355,
            "שם_רחוב": "תנופה"
        },
        {
            "_id": 20177,
            "סמל_ישוב": 3717,
            "שם_ישוב": "חרמש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חרמש"
        },
        {
            "_id": 20178,
            "סמל_ישוב": 1209,
            "שם_ישוב": "חרשים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הדולינה"
        },
        {
            "_id": 20179,
            "סמל_ישוב": 1209,
            "שם_ישוב": "חרשים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הטאבון"
        },
        {
            "_id": 20180,
            "סמל_ישוב": 1209,
            "שם_ישוב": "חרשים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הסלמנדרה"
        },
        {
            "_id": 20181,
            "סמל_ישוב": 1209,
            "שם_ישוב": "חרשים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הענן"
        },
        {
            "_id": 20182,
            "סמל_ישוב": 1209,
            "שם_ישוב": "חרשים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הקטלב"
        },
        {
            "_id": 20183,
            "סמל_ישוב": 1209,
            "שם_ישוב": "חרשים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חרשים"
        },
        {
            "_id": 20184,
            "סמל_ישוב": 1209,
            "שם_ישוב": "חרשים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 20185,
            "סמל_ישוב": 1209,
            "שם_ישוב": "חרשים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שביל הגנים"
        },
        {
            "_id": 20186,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 20187,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הארבל"
        },
        {
            "_id": 20188,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 20189,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הדגן"
        },
        {
            "_id": 20190,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 20191,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 20192,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 20193,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "היצהר"
        },
        {
            "_id": 20194,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 20195,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הלבונה"
        },
        {
            "_id": 20196,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "המור"
        },
        {
            "_id": 20197,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הצור"
        },
        {
            "_id": 20198,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הר נבו"
        },
        {
            "_id": 20199,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 20200,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 20201,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 20202,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 20203,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 20204,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "חשמונאים"
        },
        {
            "_id": 20205,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 126,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 20206,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 129,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 20207,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 127,
            "שם_רחוב": "מעלה הגלבוע"
        },
        {
            "_id": 20208,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "מעלה מירון"
        },
        {
            "_id": 20209,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "משעול הכרמים"
        },
        {
            "_id": 20210,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "משעול השקמים"
        },
        {
            "_id": 20211,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נטף"
        },
        {
            "_id": 20212,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "עץ הדר"
        },
        {
            "_id": 20213,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "עצמון"
        },
        {
            "_id": 20214,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "ערבי נחל"
        },
        {
            "_id": 20215,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "רמת מודיעין"
        },
        {
            "_id": 20216,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 128,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 20217,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "שיאון"
        },
        {
            "_id": 20218,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 130,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 20219,
            "סמל_ישוב": 3770,
            "שם_ישוב": "חשמונאים ",
            "סמל_רחוב": 125,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 20220,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 440,
            "שם_רחוב": "אבו חצירה"
        },
        {
            "_id": 20221,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 481,
            "שם_רחוב": "אבולעפיה"
        },
        {
            "_id": 20222,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אדרעי מאיר"
        },
        {
            "_id": 20223,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 435,
            "שם_רחוב": "אהבת ציון"
        },
        {
            "_id": 20224,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אהל יעקב"
        },
        {
            "_id": 20225,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "אוסישקין"
        },
        {
            "_id": 20226,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 482,
            "שם_רחוב": "אור חיים"
        },
        {
            "_id": 20227,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 469,
            "שם_רחוב": "אורנים"
        },
        {
            "_id": 20228,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6538,
            "שם_רחוב": "אזור תעשיה ומלאכה"
        },
        {
            "_id": 20229,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6537,
            "שם_רחוב": "אזור תעשיה רמת אגוז"
        },
        {
            "_id": 20230,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 20231,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "אחוה"
        },
        {
            "_id": 20232,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אחוזת בית"
        },
        {
            "_id": 20233,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "איחוד הצלה"
        },
        {
            "_id": 20234,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 500,
            "שם_רחוב": "איילה"
        },
        {
            "_id": 20235,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 20236,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 517,
            "שם_רחוב": "אירוסים"
        },
        {
            "_id": 20237,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 521,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 20238,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 20239,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 483,
            "שם_רחוב": "אלון יגאל"
        },
        {
            "_id": 20240,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלונים"
        },
        {
            "_id": 20241,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אלחדיף"
        },
        {
            "_id": 20242,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 462,
            "שם_רחוב": "אלעזר דוד"
        },
        {
            "_id": 20243,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 442,
            "שם_רחוב": "אלפסי"
        },
        {
            "_id": 20244,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 20245,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 20246,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 355,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 20247,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 20248,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 20249,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 484,
            "שם_רחוב": "ארזים"
        },
        {
            "_id": 20250,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "ארליך"
        },
        {
            "_id": 20251,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "בהלול ציון"
        },
        {
            "_id": 20252,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 20253,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "ביבאס"
        },
        {
            "_id": 20254,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 485,
            "שם_רחוב": "בית הלל"
        },
        {
            "_id": 20255,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "בית חולים"
        },
        {
            "_id": 20256,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 468,
            "שם_רחוב": "בן אליעזר אריה"
        },
        {
            "_id": 20257,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "בן זכאי"
        },
        {
            "_id": 20258,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 444,
            "שם_רחוב": "בן צבי יצחק"
        },
        {
            "_id": 20259,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "בר גיורא"
        },
        {
            "_id": 20260,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 323,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 20261,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "ברוך בן טובים"
        },
        {
            "_id": 20262,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "ברוריה"
        },
        {
            "_id": 20263,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 20264,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 451,
            "שם_רחוב": "ברץ"
        },
        {
            "_id": 20265,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "גולדה מאיר"
        },
        {
            "_id": 20266,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 20267,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "גולני"
        },
        {
            "_id": 20268,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 20269,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "גוש חלב"
        },
        {
            "_id": 20270,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "גוש עציון"
        },
        {
            "_id": 20271,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "גינוסר"
        },
        {
            "_id": 20272,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 20273,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "דגניה"
        },
        {
            "_id": 20274,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דהאן שמעון"
        },
        {
            "_id": 20275,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 501,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 20276,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "דוד דנילוף"
        },
        {
            "_id": 20277,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 325,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 20278,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "דון יוסף"
        },
        {
            "_id": 20279,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "דונה גרציה"
        },
        {
            "_id": 20280,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "דרך גדוד ברק"
        },
        {
            "_id": 20281,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 328,
            "שם_רחוב": "דרך הגבורה"
        },
        {
            "_id": 20282,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "דרך הציונות"
        },
        {
            "_id": 20283,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "דרך מנחם בגין"
        },
        {
            "_id": 20284,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 290,
            "שם_רחוב": "דרך מרן"
        },
        {
            "_id": 20285,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "דרך רונן"
        },
        {
            "_id": 20286,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 438,
            "שם_רחוב": "האבות"
        },
        {
            "_id": 20287,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "האמהות"
        },
        {
            "_id": 20288,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 20289,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 480,
            "שם_רחוב": "הבורג"
        },
        {
            "_id": 20290,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 20291,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 20292,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "הגיבורים"
        },
        {
            "_id": 20293,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 20294,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 20295,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 486,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 20296,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 467,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 20297,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 511,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 20298,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הוז דב"
        },
        {
            "_id": 20299,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "הופיין"
        },
        {
            "_id": 20300,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "הורדוס"
        },
        {
            "_id": 20301,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 20302,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 496,
            "שם_רחוב": "החרושת"
        },
        {
            "_id": 20303,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 20304,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 318,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 20305,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 478,
            "שם_רחוב": "החשמל"
        },
        {
            "_id": 20306,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "הים"
        },
        {
            "_id": 20307,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 20308,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "הירקון"
        },
        {
            "_id": 20309,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 20310,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 474,
            "שם_רחוב": "המאור"
        },
        {
            "_id": 20311,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 493,
            "שם_רחוב": "המברג"
        },
        {
            "_id": 20312,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "המגינים"
        },
        {
            "_id": 20313,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "המטבעות"
        },
        {
            "_id": 20314,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 487,
            "שם_רחוב": "המכבים"
        },
        {
            "_id": 20315,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 488,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 20316,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 494,
            "שם_רחוב": "המשור"
        },
        {
            "_id": 20317,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "המשטרה"
        },
        {
            "_id": 20318,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "המשיח"
        },
        {
            "_id": 20319,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 436,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 20320,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "הנוטר"
        },
        {
            "_id": 20321,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "הנוצרים"
        },
        {
            "_id": 20322,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 477,
            "שם_רחוב": "הניאון"
        },
        {
            "_id": 20323,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "הנצחון"
        },
        {
            "_id": 20324,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 518,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 20325,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 445,
            "שם_רחוב": "הנשיא ויצמן"
        },
        {
            "_id": 20326,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "העיר העתיקה"
        },
        {
            "_id": 20327,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 20328,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "העמקים"
        },
        {
            "_id": 20329,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 20330,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 20331,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 495,
            "שם_רחוב": "הפנס"
        },
        {
            "_id": 20332,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 20333,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 20334,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 455,
            "שם_רחוב": "הקדושים"
        },
        {
            "_id": 20335,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "הקישון"
        },
        {
            "_id": 20336,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 463,
            "שם_רחוב": "הקרן"
        },
        {
            "_id": 20337,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "הר השקדים"
        },
        {
            "_id": 20338,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 291,
            "שם_רחוב": "הרב אליישיב"
        },
        {
            "_id": 20339,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הרב ועקנין"
        },
        {
            "_id": 20340,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "הרב חיים סמדג'א"
        },
        {
            "_id": 20341,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "הרב חיים שפר"
        },
        {
            "_id": 20342,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "הרב כהן משה"
        },
        {
            "_id": 20343,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "הרב מסעוד מלול"
        },
        {
            "_id": 20344,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 443,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 20345,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 489,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 20346,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "הרב שך"
        },
        {
            "_id": 20347,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 497,
            "שם_רחוב": "הרצוג"
        },
        {
            "_id": 20348,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 20349,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 324,
            "שם_רחוב": "השופטים"
        },
        {
            "_id": 20350,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 20351,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "השילוח"
        },
        {
            "_id": 20352,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "השלה"
        },
        {
            "_id": 20353,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "השלום"
        },
        {
            "_id": 20354,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 269,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 20355,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 20356,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "התנא נחום"
        },
        {
            "_id": 20357,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "התנאים"
        },
        {
            "_id": 20358,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 20359,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "וינגייט"
        },
        {
            "_id": 20360,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "ורנר"
        },
        {
            "_id": 20361,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 20362,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "זיידל"
        },
        {
            "_id": 20363,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 20364,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "חברון"
        },
        {
            "_id": 20365,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 472,
            "שם_רחוב": "חוף גיא"
        },
        {
            "_id": 20366,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 453,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 20367,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 452,
            "שם_רחוב": "חייא ובניו"
        },
        {
            "_id": 20368,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 502,
            "שם_רחוב": "חלמונית"
        },
        {
            "_id": 20369,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 503,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 20370,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 513,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 20371,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "טבור הארץ"
        },
        {
            "_id": 20372,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 20373,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6535,
            "שם_רחוב": "טבריה עילית"
        },
        {
            "_id": 20374,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "טולידנו"
        },
        {
            "_id": 20375,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "טיילת יגאל אלון"
        },
        {
            "_id": 20376,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "טל"
        },
        {
            "_id": 20377,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 464,
            "שם_רחוב": "טמסות חיים"
        },
        {
            "_id": 20378,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 20379,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 20380,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "יהודה הנשיא"
        },
        {
            "_id": 20381,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "יוסיפוס"
        },
        {
            "_id": 20382,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 522,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 20383,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 447,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 20384,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 430,
            "שם_רחוב": "יפתח הגלעדי"
        },
        {
            "_id": 20385,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 448,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 20386,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "ישעיהו"
        },
        {
            "_id": 20387,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 454,
            "שם_רחוב": "כהנא"
        },
        {
            "_id": 20388,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 490,
            "שם_רחוב": "כוכב"
        },
        {
            "_id": 20389,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "כיח"
        },
        {
            "_id": 20390,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר העצמאות"
        },
        {
            "_id": 20391,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר ז'בוטינסקי"
        },
        {
            "_id": 20392,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 498,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 20393,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 466,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 20394,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 515,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 20395,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "לבנה"
        },
        {
            "_id": 20396,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 473,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 20397,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 479,
            "שם_רחוב": "לוי אשכול"
        },
        {
            "_id": 20398,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "לולב"
        },
        {
            "_id": 20399,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 20400,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "לימונית"
        },
        {
            "_id": 20401,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 369,
            "שם_רחוב": "מבצע אביב נעורים"
        },
        {
            "_id": 20402,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 358,
            "שם_רחוב": "מבצע בן עמי"
        },
        {
            "_id": 20403,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 359,
            "שם_רחוב": "מבצע ברוש"
        },
        {
            "_id": 20404,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 360,
            "שם_רחוב": "מבצע דני"
        },
        {
            "_id": 20405,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 365,
            "שם_רחוב": "מבצע חולה"
        },
        {
            "_id": 20406,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 361,
            "שם_רחוב": "מבצע חורב"
        },
        {
            "_id": 20407,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "מבצע כחול וחום"
        },
        {
            "_id": 20408,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 366,
            "שם_רחוב": "מבצע כחל"
        },
        {
            "_id": 20409,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 357,
            "שם_רחוב": "מבצע מכבי"
        },
        {
            "_id": 20410,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 356,
            "שם_רחוב": "מבצע נחשון"
        },
        {
            "_id": 20411,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 367,
            "שם_רחוב": "מבצע נקר"
        },
        {
            "_id": 20412,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 368,
            "שם_רחוב": "מבצע סבנה"
        },
        {
            "_id": 20413,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 363,
            "שם_רחוב": "מבצע סנונית"
        },
        {
            "_id": 20414,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 362,
            "שם_רחוב": "מבצע עובדה"
        },
        {
            "_id": 20415,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 401,
            "שם_רחוב": "מבצע צוק איתן"
        },
        {
            "_id": 20416,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 364,
            "שם_רחוב": "מבצע שלדג"
        },
        {
            "_id": 20417,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "מגלן"
        },
        {
            "_id": 20418,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 20419,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "מור"
        },
        {
            "_id": 20420,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "מורדות טבריה"
        },
        {
            "_id": 20421,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 20422,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "מיכאל"
        },
        {
            "_id": 20423,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 470,
            "שם_רחוב": "מינו"
        },
        {
            "_id": 20424,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מלון ארנון"
        },
        {
            "_id": 20425,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "מלון וושינגטון"
        },
        {
            "_id": 20426,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "מלון טבריה"
        },
        {
            "_id": 20427,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 475,
            "שם_רחוב": "מלון רסיטל"
        },
        {
            "_id": 20428,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "מסדה"
        },
        {
            "_id": 20429,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 512,
            "שם_רחוב": "מעלה קקל"
        },
        {
            "_id": 20430,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 327,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 20431,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "מצפה כנרת"
        },
        {
            "_id": 20432,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 514,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 20433,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "מרכז בעלי מלאכה"
        },
        {
            "_id": 20434,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 20435,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 431,
            "שם_רחוב": "נבון יצחק"
        },
        {
            "_id": 20436,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 432,
            "שם_רחוב": "נבות הכרמלי"
        },
        {
            "_id": 20437,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "נוף כנרת"
        },
        {
            "_id": 20438,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 516,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 20439,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 450,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 20440,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "נחמני"
        },
        {
            "_id": 20441,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ניברג"
        },
        {
            "_id": 20442,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 504,
            "שם_רחוב": "סביונים"
        },
        {
            "_id": 20443,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 20444,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 441,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 20445,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 523,
            "שם_רחוב": "סמ ציפורי"
        },
        {
            "_id": 20446,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 459,
            "שם_רחוב": "סנש חנה"
        },
        {
            "_id": 20447,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 449,
            "שם_רחוב": "עזרא"
        },
        {
            "_id": 20448,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 465,
            "שם_רחוב": "עטר חיים"
        },
        {
            "_id": 20449,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 429,
            "שם_רחוב": "עלית"
        },
        {
            "_id": 20450,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 20451,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "עצמון"
        },
        {
            "_id": 20452,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 330,
            "שם_רחוב": "פועלי ציון"
        },
        {
            "_id": 20453,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "פיקא"
        },
        {
            "_id": 20454,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 510,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 20455,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 476,
            "שם_רחוב": "צבעונית"
        },
        {
            "_id": 20456,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "ציפורי"
        },
        {
            "_id": 20457,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 20458,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 20459,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 520,
            "שם_רחוב": "קיסוס"
        },
        {
            "_id": 20460,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "קפלנסקי"
        },
        {
            "_id": 20461,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "קרית משה"
        },
        {
            "_id": 20462,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "קרית צאנז"
        },
        {
            "_id": 20463,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "רבי יוסף קארו"
        },
        {
            "_id": 20464,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "רבי מאיר"
        },
        {
            "_id": 20465,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 336,
            "שם_רחוב": "רוטשילד"
        },
        {
            "_id": 20466,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 505,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 20467,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 460,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 20468,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 331,
            "שם_רחוב": "רח 110"
        },
        {
            "_id": 20469,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "רח 411"
        },
        {
            "_id": 20470,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "רח 522"
        },
        {
            "_id": 20471,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 492,
            "שם_רחוב": "רח 503"
        },
        {
            "_id": 20472,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 332,
            "שם_רחוב": "רח 115"
        },
        {
            "_id": 20473,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 354,
            "שם_רחוב": "רח 526"
        },
        {
            "_id": 20474,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 471,
            "שם_רחוב": "רח 539"
        },
        {
            "_id": 20475,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "רחבעם זאבי (גנדי)"
        },
        {
            "_id": 20476,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "רחל"
        },
        {
            "_id": 20477,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 329,
            "שם_רחוב": "רמחל"
        },
        {
            "_id": 20478,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "רמת אגוז"
        },
        {
            "_id": 20479,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "רמת בן גוריון"
        },
        {
            "_id": 20480,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "רמת טבריה"
        },
        {
            "_id": 20481,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "רמת כנרת"
        },
        {
            "_id": 20482,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 456,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 20483,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 20484,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש הרמבם"
        },
        {
            "_id": 20485,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש ותיקים"
        },
        {
            "_id": 20486,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש חסכון ד"
        },
        {
            "_id": 20487,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש מווארס"
        },
        {
            "_id": 20488,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "ש נוה חן"
        },
        {
            "_id": 20489,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "ש סולל בונה"
        },
        {
            "_id": 20490,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "ש עובדים"
        },
        {
            "_id": 20491,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "ש עממי"
        },
        {
            "_id": 20492,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "ש עץ חיים"
        },
        {
            "_id": 20493,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "ש פלוס 200"
        },
        {
            "_id": 20494,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "ש ציונים כלליים"
        },
        {
            "_id": 20495,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "ש קליפורניה"
        },
        {
            "_id": 20496,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "ש רמז"
        },
        {
            "_id": 20497,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 446,
            "שם_רחוב": "שבטי ישראל"
        },
        {
            "_id": 20498,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 499,
            "שם_רחוב": "שביט"
        },
        {
            "_id": 20499,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "שביל הכפיר"
        },
        {
            "_id": 20500,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "שביל הלביא"
        },
        {
            "_id": 20501,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 458,
            "שם_רחוב": "שד אלנטאון"
        },
        {
            "_id": 20502,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שד הרצל"
        },
        {
            "_id": 20503,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 457,
            "שם_רחוב": "שד ספיר"
        },
        {
            "_id": 20504,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "שד קפלן אליעזר"
        },
        {
            "_id": 20505,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שד קקל"
        },
        {
            "_id": 20506,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שדה יצחק"
        },
        {
            "_id": 20507,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 461,
            "שם_רחוב": "שזר זלמן"
        },
        {
            "_id": 20508,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 506,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 20509,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 507,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 20510,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 326,
            "שם_רחוב": "שיטרית"
        },
        {
            "_id": 20511,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "שכ אחווה"
        },
        {
            "_id": 20512,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "שכ אחוזת כנרת"
        },
        {
            "_id": 20513,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "שכ גני חמת"
        },
        {
            "_id": 20514,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "שכ דון יוסף הנשיא"
        },
        {
            "_id": 20515,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "שכ ההר"
        },
        {
            "_id": 20516,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "שכ מימוניה"
        },
        {
            "_id": 20517,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "שכ מנורה"
        },
        {
            "_id": 20518,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "שכ נוה הדר"
        },
        {
            "_id": 20519,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "שכ קרית שמואל"
        },
        {
            "_id": 20520,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "שכ רבי עקיבא"
        },
        {
            "_id": 20521,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "שכ רווקיה"
        },
        {
            "_id": 20522,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "שכ רמת טבריה א'"
        },
        {
            "_id": 20523,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6542,
            "שם_רחוב": "שכ רמת טבריה ג'"
        },
        {
            "_id": 20524,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6539,
            "שם_רחוב": "שכ שרת"
        },
        {
            "_id": 20525,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "שכ' בית וגן"
        },
        {
            "_id": 20526,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "שכון א"
        },
        {
            "_id": 20527,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "שכון ג"
        },
        {
            "_id": 20528,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "שכם"
        },
        {
            "_id": 20529,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 20530,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 322,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 20531,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "שמואל הנגיד"
        },
        {
            "_id": 20532,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 437,
            "שם_רחוב": "שפירא משה"
        },
        {
            "_id": 20533,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 400,
            "שם_רחוב": "שפע חיים"
        },
        {
            "_id": 20534,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "שפת הים"
        },
        {
            "_id": 20535,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 508,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 20536,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 439,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 20537,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "תג'ר"
        },
        {
            "_id": 20538,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "תהילה"
        },
        {
            "_id": 20539,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 20540,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 519,
            "שם_רחוב": "תלתן"
        },
        {
            "_id": 20541,
            "סמל_ישוב": 6700,
            "שם_ישוב": "טבריה ",
            "סמל_רחוב": 509,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 20542,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אבו בקר אלסדיק"
        },
        {
            "_id": 20543,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 20544,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 20545,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אל-ח'נסאא"
        },
        {
            "_id": 20546,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אל-נח'ל"
        },
        {
            "_id": 20547,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אל-רביע"
        },
        {
            "_id": 20548,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אל-רומאן"
        },
        {
            "_id": 20549,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 20550,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אלאנדלוס"
        },
        {
            "_id": 20551,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלאסיל"
        },
        {
            "_id": 20552,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלאקסא"
        },
        {
            "_id": 20553,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 20554,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלג'ולאן"
        },
        {
            "_id": 20555,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלג'ליל"
        },
        {
            "_id": 20556,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלגוזלאן"
        },
        {
            "_id": 20557,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלהודא"
        },
        {
            "_id": 20558,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלהייב"
        },
        {
            "_id": 20559,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלוורוד"
        },
        {
            "_id": 20560,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלזוהור"
        },
        {
            "_id": 20561,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלזייתון"
        },
        {
            "_id": 20562,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלח'טאב"
        },
        {
            "_id": 20563,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אלח'ליל"
        },
        {
            "_id": 20564,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 20565,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלחולה"
        },
        {
            "_id": 20566,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלחסיד"
        },
        {
            "_id": 20567,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלכרום"
        },
        {
            "_id": 20568,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלמג'ד"
        },
        {
            "_id": 20569,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלמותנבי"
        },
        {
            "_id": 20570,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אלמנארה"
        },
        {
            "_id": 20571,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלנוורס"
        },
        {
            "_id": 20572,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 20573,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 20574,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 20575,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלסנדיאן"
        },
        {
            "_id": 20576,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אלספא"
        },
        {
            "_id": 20577,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 20578,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלפארוק"
        },
        {
            "_id": 20579,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלקודס"
        },
        {
            "_id": 20580,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלרחמה"
        },
        {
            "_id": 20581,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלריחאנה"
        },
        {
            "_id": 20582,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלרשיד"
        },
        {
            "_id": 20583,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלשאם"
        },
        {
            "_id": 20584,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלשאפעי"
        },
        {
            "_id": 20585,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלשייך"
        },
        {
            "_id": 20586,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "בילאל"
        },
        {
            "_id": 20587,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "בניאס"
        },
        {
            "_id": 20588,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "ג'בראן ח'ליל ג'בראן"
        },
        {
            "_id": 20589,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ח'אלד בן אלוליד"
        },
        {
            "_id": 20590,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "חאתם אלטאאי"
        },
        {
            "_id": 20591,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "חמזה"
        },
        {
            "_id": 20592,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טובא-זנגריה"
        },
        {
            "_id": 20593,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "יגאל אלון"
        },
        {
            "_id": 20594,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "מורג'אן"
        },
        {
            "_id": 20595,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "מכה"
        },
        {
            "_id": 20596,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "נהר אלאורדן"
        },
        {
            "_id": 20597,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "סלאח אלדין"
        },
        {
            "_id": 20598,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "עומר אלמוכתאר"
        },
        {
            "_id": 20599,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "עות'מאן"
        },
        {
            "_id": 20600,
            "סמל_ישוב": 962,
            "שם_ישוב": "טובא-זנגריה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "עין טובא"
        },
        {
            "_id": 20601,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אבו בכר"
        },
        {
            "_id": 20602,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 20603,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 20604,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 20605,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלאנדלוס"
        },
        {
            "_id": 20606,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלאנואר"
        },
        {
            "_id": 20607,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 20608,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלבלאט"
        },
        {
            "_id": 20609,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלבלוט"
        },
        {
            "_id": 20610,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אלבסאתין"
        },
        {
            "_id": 20611,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלג'בל"
        },
        {
            "_id": 20612,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלג'זאלי"
        },
        {
            "_id": 20613,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אלדואויר"
        },
        {
            "_id": 20614,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אלדלה"
        },
        {
            "_id": 20615,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אלדמנה"
        },
        {
            "_id": 20616,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אלהודא"
        },
        {
            "_id": 20617,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלואדי"
        },
        {
            "_id": 20618,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלווקף"
        },
        {
            "_id": 20619,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלזהראא"
        },
        {
            "_id": 20620,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 20621,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלזקאק"
        },
        {
            "_id": 20622,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 20623,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלחאקורה"
        },
        {
            "_id": 20624,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלחדיקה"
        },
        {
            "_id": 20625,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלטבראני"
        },
        {
            "_id": 20626,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אלכורום"
        },
        {
            "_id": 20627,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלכרם"
        },
        {
            "_id": 20628,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אלמולוק"
        },
        {
            "_id": 20629,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלמותנבי"
        },
        {
            "_id": 20630,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלמחבה"
        },
        {
            "_id": 20631,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלמטל"
        },
        {
            "_id": 20632,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלמלק"
        },
        {
            "_id": 20633,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלמנאשר"
        },
        {
            "_id": 20634,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלמנזול"
        },
        {
            "_id": 20635,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלמסאקב"
        },
        {
            "_id": 20636,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלמעסרה"
        },
        {
            "_id": 20637,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלמקיל"
        },
        {
            "_id": 20638,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלמתנזה"
        },
        {
            "_id": 20639,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 20640,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלסוק"
        },
        {
            "_id": 20641,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אלסלם"
        },
        {
            "_id": 20642,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלסנדיאן"
        },
        {
            "_id": 20643,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלספא"
        },
        {
            "_id": 20644,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלסריס"
        },
        {
            "_id": 20645,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אלעודה"
        },
        {
            "_id": 20646,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלעולא"
        },
        {
            "_id": 20647,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלעסיליאת"
        },
        {
            "_id": 20648,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלפאראבי"
        },
        {
            "_id": 20649,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלפארוק"
        },
        {
            "_id": 20650,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלפרדוס"
        },
        {
            "_id": 20651,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלפרח"
        },
        {
            "_id": 20652,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלקודס"
        },
        {
            "_id": 20653,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלקנדול"
        },
        {
            "_id": 20654,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלרביע"
        },
        {
            "_id": 20655,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלרוודה"
        },
        {
            "_id": 20656,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלרום"
        },
        {
            "_id": 20657,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 20658,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אלרמאנה"
        },
        {
            "_id": 20659,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלרשיד"
        },
        {
            "_id": 20660,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלשאפעי"
        },
        {
            "_id": 20661,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלשיח' פרג'"
        },
        {
            "_id": 20662,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלתותה"
        },
        {
            "_id": 20663,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אסלאם"
        },
        {
            "_id": 20664,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 165,
            "שם_רחוב": "בירות"
        },
        {
            "_id": 20665,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 118,
            "שם_רחוב": "דואר אלפלק"
        },
        {
            "_id": 20666,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דרב אלברג'"
        },
        {
            "_id": 20667,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 176,
            "שם_רחוב": "דרב אלחטיניה"
        },
        {
            "_id": 20668,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 133,
            "שם_רחוב": "דרב אלכניסה"
        },
        {
            "_id": 20669,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 160,
            "שם_רחוב": "דרב אלמסרב"
        },
        {
            "_id": 20670,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 140,
            "שם_רחוב": "דרב אלנאסרה"
        },
        {
            "_id": 20671,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 147,
            "שם_רחוב": "דרב אלסראר"
        },
        {
            "_id": 20672,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 130,
            "שם_רחוב": "דרב עכא"
        },
        {
            "_id": 20673,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טורעאן"
        },
        {
            "_id": 20674,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מחמוד דרויש"
        },
        {
            "_id": 20675,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 172,
            "שם_רחוב": "סידי סלימאן"
        },
        {
            "_id": 20676,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סלאח אלדין"
        },
        {
            "_id": 20677,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 154,
            "שם_רחוב": "עוטרא"
        },
        {
            "_id": 20678,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "שכ אלדלהם"
        },
        {
            "_id": 20679,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6724,
            "שם_רחוב": "שכ אלחמראא'"
        },
        {
            "_id": 20680,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "שכ אלטבראני"
        },
        {
            "_id": 20681,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "שכ אלכרום אלג'רביה"
        },
        {
            "_id": 20682,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6734,
            "שם_רחוב": "שכ אלמליק"
        },
        {
            "_id": 20683,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "שכ אלמנאשר"
        },
        {
            "_id": 20684,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "שכ אלמנזלה"
        },
        {
            "_id": 20685,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "שכ אלסראר"
        },
        {
            "_id": 20686,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "שכ אלעיסיליאת"
        },
        {
            "_id": 20687,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "שכ אלעמארה"
        },
        {
            "_id": 20688,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "שכ אלרמאנה"
        },
        {
            "_id": 20689,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6733,
            "שם_רחוב": "שכ אלתותי"
        },
        {
            "_id": 20690,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "שכ בית אלרסיף"
        },
        {
            "_id": 20691,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6735,
            "שם_רחוב": "שכ ד'הר אלשעירה"
        },
        {
            "_id": 20692,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "שכ ואדי אלעין"
        },
        {
            "_id": 20693,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6725,
            "שם_רחוב": "שכ ווסט אלבלד"
        },
        {
            "_id": 20694,
            "סמל_ישוב": 498,
            "שם_ישוב": "טורעאן ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "שכ קבר אלעבד"
        },
        {
            "_id": 20695,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "אבו אל עקארב"
        },
        {
            "_id": 20696,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6760,
            "שם_רחוב": "אזור הקאנטרי"
        },
        {
            "_id": 20697,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6781,
            "שם_רחוב": "אזור תעשייה"
        },
        {
            "_id": 20698,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "אלבאטן"
        },
        {
            "_id": 20699,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "אלח'לה אלשמאליה"
        },
        {
            "_id": 20700,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6773,
            "שם_רחוב": "אלסלילמה"
        },
        {
            "_id": 20701,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6770,
            "שם_רחוב": "אלסמכה"
        },
        {
            "_id": 20702,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "אלקינה-שכונה מזרחית"
        },
        {
            "_id": 20703,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6750,
            "שם_רחוב": "אלראס"
        },
        {
            "_id": 20704,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6771,
            "שם_רחוב": "אלשל"
        },
        {
            "_id": 20705,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "אתר אשפה מזרחי"
        },
        {
            "_id": 20706,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6782,
            "שם_רחוב": "ג'למה"
        },
        {
            "_id": 20707,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "גרעין-העיר העתיקה"
        },
        {
            "_id": 20708,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6774,
            "שם_רחוב": "ואד אלאסד"
        },
        {
            "_id": 20709,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6772,
            "שם_רחוב": "ואד חמדאן"
        },
        {
            "_id": 20710,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טייבה"
        },
        {
            "_id": 20711,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6761,
            "שם_רחוב": "כרם אלג'באלי המזרחי"
        },
        {
            "_id": 20712,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6762,
            "שם_רחוב": "כרם אלג'באלי המערבי"
        },
        {
            "_id": 20713,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "פרדיסיה"
        },
        {
            "_id": 20714,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "רוק"
        },
        {
            "_id": 20715,
            "סמל_ישוב": 2730,
            "שם_ישוב": "טייבה ",
            "סמל_רחוב": 6780,
            "שם_רחוב": "שכונת הבדואים"
        },
        {
            "_id": 20716,
            "סמל_ישוב": 497,
            "שם_ישוב": "טייבה (בעמק) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טייבה (בעמק)"
        },
        {
            "_id": 20717,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "א-ד'הב"
        },
        {
            "_id": 20718,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 323,
            "שם_רחוב": "א-דואלי"
        },
        {
            "_id": 20719,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "א-דוחא"
        },
        {
            "_id": 20720,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "א-דורי"
        },
        {
            "_id": 20721,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 318,
            "שם_רחוב": "א-דינאוורי"
        },
        {
            "_id": 20722,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "א-זאנבק"
        },
        {
            "_id": 20723,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "א-זאעפראן"
        },
        {
            "_id": 20724,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "א-זאעתר"
        },
        {
            "_id": 20725,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 387,
            "שם_רחוב": "א-זבידייאת"
        },
        {
            "_id": 20726,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "א-זהראא"
        },
        {
            "_id": 20727,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 319,
            "שם_רחוב": "א-זהראווי"
        },
        {
            "_id": 20728,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 386,
            "שם_רחוב": "א-זהראניאת"
        },
        {
            "_id": 20729,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "א-זוהור"
        },
        {
            "_id": 20730,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "א-זייתון"
        },
        {
            "_id": 20731,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 398,
            "שם_רחוב": "א-טאבון"
        },
        {
            "_id": 20732,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 320,
            "שם_רחוב": "א-טברי"
        },
        {
            "_id": 20733,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 373,
            "שם_רחוב": "א-טור"
        },
        {
            "_id": 20734,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 347,
            "שם_רחוב": "א-לוד"
        },
        {
            "_id": 20735,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "א-ליימון"
        },
        {
            "_id": 20736,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "א-נאהדה"
        },
        {
            "_id": 20737,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "א-נאוורס"
        },
        {
            "_id": 20738,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 368,
            "שם_רחוב": "א-נאס'ירה"
        },
        {
            "_id": 20739,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 269,
            "שם_רחוב": "א-נבעה"
        },
        {
            "_id": 20740,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "א-נג'אח"
        },
        {
            "_id": 20741,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "א-נוג'ום"
        },
        {
            "_id": 20742,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "א-נור"
        },
        {
            "_id": 20743,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "א-נח'יל"
        },
        {
            "_id": 20744,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "א-נידאל"
        },
        {
            "_id": 20745,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "א-נס'ר"
        },
        {
            "_id": 20746,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "א-נקב"
        },
        {
            "_id": 20747,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "א-נרג'יס"
        },
        {
            "_id": 20748,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "א-ס'בר"
        },
        {
            "_id": 20749,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 366,
            "שם_רחוב": "א-ס'בראת"
        },
        {
            "_id": 20750,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "א-ס'נאוובר"
        },
        {
            "_id": 20751,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "א-ס'פא"
        },
        {
            "_id": 20752,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "א-ס'פסאף"
        },
        {
            "_id": 20753,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "א-סאווסן"
        },
        {
            "_id": 20754,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "א-סאנאבל"
        },
        {
            "_id": 20755,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "א-סאראיא"
        },
        {
            "_id": 20756,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "א-סונונו"
        },
        {
            "_id": 20757,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 394,
            "שם_רחוב": "א-סוק"
        },
        {
            "_id": 20758,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "א-סינדיאן"
        },
        {
            "_id": 20759,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "א-סיראג'"
        },
        {
            "_id": 20760,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "א-סלאם"
        },
        {
            "_id": 20761,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "א-סעאדה"
        },
        {
            "_id": 20762,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "א-ראוודה"
        },
        {
            "_id": 20763,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "א-ראזי"
        },
        {
            "_id": 20764,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "א-רביע"
        },
        {
            "_id": 20765,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "א-רג'אא"
        },
        {
            "_id": 20766,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 359,
            "שם_רחוב": "א-רוזייה"
        },
        {
            "_id": 20767,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 360,
            "שם_רחוב": "א-רוחה"
        },
        {
            "_id": 20768,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "א-רומאן"
        },
        {
            "_id": 20769,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 282,
            "שם_רחוב": "א-רחיק"
        },
        {
            "_id": 20770,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "א-רייאן"
        },
        {
            "_id": 20771,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "א-רייחאן"
        },
        {
            "_id": 20772,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 346,
            "שם_רחוב": "א-רמלה"
        },
        {
            "_id": 20773,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "א-שאהין"
        },
        {
            "_id": 20774,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 361,
            "שם_רחוב": "א-שאם"
        },
        {
            "_id": 20775,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 289,
            "שם_רחוב": "א-שאפיעי"
        },
        {
            "_id": 20776,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "א-שהיד מחמוד א-שארה"
        },
        {
            "_id": 20777,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "א-שוהדא"
        },
        {
            "_id": 20778,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "א-שורוק"
        },
        {
            "_id": 20779,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "א-שמס"
        },
        {
            "_id": 20780,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "א-תופאח"
        },
        {
            "_id": 20781,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "א-תות"
        },
        {
            "_id": 20782,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "א-תין"
        },
        {
            "_id": 20783,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 389,
            "שם_רחוב": "א-תלול"
        },
        {
            "_id": 20784,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "א-תמר"
        },
        {
            "_id": 20785,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אבו בכר א-ס'דיק"
        },
        {
            "_id": 20786,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 292,
            "שם_רחוב": "אבו הוריירה"
        },
        {
            "_id": 20787,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 324,
            "שם_רחוב": "אבו חניפה"
        },
        {
            "_id": 20788,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 291,
            "שם_רחוב": "אבו מוסא אל-אשערי"
        },
        {
            "_id": 20789,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6751,
            "שם_רחוב": "אבו סלאח"
        },
        {
            "_id": 20790,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אבו עוביידה"
        },
        {
            "_id": 20791,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "אבן א-נפיס"
        },
        {
            "_id": 20792,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "אבן אל-את'יר"
        },
        {
            "_id": 20793,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אבן אל-ביטאר"
        },
        {
            "_id": 20794,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אבן אל-היית'ם"
        },
        {
            "_id": 20795,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "אבן אל-קיים"
        },
        {
            "_id": 20796,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אבן בטוטה"
        },
        {
            "_id": 20797,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 20798,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אבן חאן"
        },
        {
            "_id": 20799,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "אבן חזם אל-אנדלוסי"
        },
        {
            "_id": 20800,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אבן חנבל"
        },
        {
            "_id": 20801,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "אבן כת'יר"
        },
        {
            "_id": 20802,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 286,
            "שם_רחוב": "אבן מאליכ"
        },
        {
            "_id": 20803,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 285,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 20804,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 20805,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 284,
            "שם_רחוב": "אבן תיימייה"
        },
        {
            "_id": 20806,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אד'אר"
        },
        {
            "_id": 20807,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אוחוד"
        },
        {
            "_id": 20808,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 293,
            "שם_רחוב": "אוסאמה בן זייד"
        },
        {
            "_id": 20809,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 508,
            "שם_רחוב": "אחמד אבו ח'יט"
        },
        {
            "_id": 20810,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 528,
            "שם_רחוב": "אחמד אלחוראני"
        },
        {
            "_id": 20811,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 511,
            "שם_רחוב": "אחמד וח'ליל כיכאן"
        },
        {
            "_id": 20812,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 512,
            "שם_רחוב": "אחמד חאמד דעאס"
        },
        {
            "_id": 20813,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 516,
            "שם_רחוב": "אחמד סעיד קשוע"
        },
        {
            "_id": 20814,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 521,
            "שם_רחוב": "אחמד עיראקי"
        },
        {
            "_id": 20815,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 326,
            "שם_רחוב": "אחמד שאווקי"
        },
        {
            "_id": 20816,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "איאר"
        },
        {
            "_id": 20817,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "איבראהים טוקאן"
        },
        {
            "_id": 20818,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "איילול"
        },
        {
            "_id": 20819,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אל- פירדאוס"
        },
        {
            "_id": 20820,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 315,
            "שם_רחוב": "אל-אדריסי"
        },
        {
            "_id": 20821,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אל-אוקחוואן"
        },
        {
            "_id": 20822,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "אל-אורג'וואן"
        },
        {
            "_id": 20823,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אל-אחלאם"
        },
        {
            "_id": 20824,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אל-אחראר"
        },
        {
            "_id": 20825,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אל-איחסאן"
        },
        {
            "_id": 20826,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אל-איסראא"
        },
        {
            "_id": 20827,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 348,
            "שם_רחוב": "אל-איעדאדייה"
        },
        {
            "_id": 20828,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אל-אמאנה"
        },
        {
            "_id": 20829,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אל-אמל"
        },
        {
            "_id": 20830,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אל-אנוואר"
        },
        {
            "_id": 20831,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אל-אנס'אר"
        },
        {
            "_id": 20832,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "אל-אס'מעי"
        },
        {
            "_id": 20833,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 341,
            "שם_רחוב": "אל-אקס'א"
        },
        {
            "_id": 20834,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אל-ארד"
        },
        {
            "_id": 20835,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "אל-ארז"
        },
        {
            "_id": 20836,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 354,
            "שם_רחוב": "אל-באניאס"
        },
        {
            "_id": 20837,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "אל-באשיק"
        },
        {
            "_id": 20838,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אל-בהג'ה"
        },
        {
            "_id": 20839,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אל-בוסתאן"
        },
        {
            "_id": 20840,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אל-בוראק"
        },
        {
            "_id": 20841,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אל-בורדה"
        },
        {
            "_id": 20842,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 278,
            "שם_רחוב": "אל-בורתוקאל"
        },
        {
            "_id": 20843,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 378,
            "שם_רחוב": "אל-בטוף"
        },
        {
            "_id": 20844,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 342,
            "שם_רחוב": "אל-ביאדר"
        },
        {
            "_id": 20845,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 343,
            "שם_רחוב": "אל-בייארה"
        },
        {
            "_id": 20846,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 317,
            "שם_רחוב": "אל-ביירוני"
        },
        {
            "_id": 20847,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 391,
            "שם_רחוב": "אל-ביר"
        },
        {
            "_id": 20848,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 279,
            "שם_רחוב": "אל-בלוט"
        },
        {
            "_id": 20849,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אל-בנפסג'"
        },
        {
            "_id": 20850,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 355,
            "שם_רחוב": "אל-בס'רה"
        },
        {
            "_id": 20851,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "אל-ברקוק"
        },
        {
            "_id": 20852,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 396,
            "שם_רחוב": "אל-ג'וב"
        },
        {
            "_id": 20853,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אל-ג'וד"
        },
        {
            "_id": 20854,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אל-ג'וז"
        },
        {
            "_id": 20855,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 357,
            "שם_רחוב": "אל-ג'ולאן"
        },
        {
            "_id": 20856,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "אל-ג'ומיז"
        },
        {
            "_id": 20857,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אל-ג'ומעה"
        },
        {
            "_id": 20858,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אל-ג'ורי"
        },
        {
            "_id": 20859,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 356,
            "שם_רחוב": "אל-ג'זאר"
        },
        {
            "_id": 20860,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 344,
            "שם_רחוב": "אל-ג'ליל"
        },
        {
            "_id": 20861,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "אל-ג'סר"
        },
        {
            "_id": 20862,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 372,
            "שם_רחוב": "אל-ג'רמק"
        },
        {
            "_id": 20863,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "אל-הודא"
        },
        {
            "_id": 20864,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אל-הילאל"
        },
        {
            "_id": 20865,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אל-וואחה"
        },
        {
            "_id": 20866,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "אל-וורוד"
        },
        {
            "_id": 20867,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "אל-וורוואר"
        },
        {
            "_id": 20868,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 288,
            "שם_רחוב": "אל-ח'וואריזמי"
        },
        {
            "_id": 20869,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 345,
            "שם_רחוב": "אל-ח'ליל"
        },
        {
            "_id": 20870,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אל-ח'מיס"
        },
        {
            "_id": 20871,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 287,
            "שם_רחוב": "אל-ח'נסאא"
        },
        {
            "_id": 20872,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "אל-ח'רוב"
        },
        {
            "_id": 20873,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 358,
            "שם_רחוב": "אל-חאס'באני"
        },
        {
            "_id": 20874,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 336,
            "שם_רחוב": "אל-חג'אג' בן יוסף"
        },
        {
            "_id": 20875,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אל-חורייה"
        },
        {
            "_id": 20876,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 393,
            "שם_רחוב": "אל-חימה"
        },
        {
            "_id": 20877,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 388,
            "שם_רחוב": "אל-חלפה"
        },
        {
            "_id": 20878,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אל-חמוויין"
        },
        {
            "_id": 20879,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אל-חנאן"
        },
        {
            "_id": 20880,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "אל-חסון"
        },
        {
            "_id": 20881,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אל-חפאיר"
        },
        {
            "_id": 20882,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אל-חק"
        },
        {
            "_id": 20883,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "אל-יאסמין"
        },
        {
            "_id": 20884,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "אל-ימאמה"
        },
        {
            "_id": 20885,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "אל-כינא"
        },
        {
            "_id": 20886,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 365,
            "שם_רחוב": "אל-כינאיאת"
        },
        {
            "_id": 20887,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 280,
            "שם_רחוב": "אל-כנארי"
        },
        {
            "_id": 20888,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 406,
            "שם_רחוב": "אל-כראמה"
        },
        {
            "_id": 20889,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אל-כרום"
        },
        {
            "_id": 20890,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "אל-כרז"
        },
        {
            "_id": 20891,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אל-כרם"
        },
        {
            "_id": 20892,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 408,
            "שם_רחוב": "אל-כרמה"
        },
        {
            "_id": 20893,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 364,
            "שם_רחוב": "אל-כרמל"
        },
        {
            "_id": 20894,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "אל-לאוז"
        },
        {
            "_id": 20895,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אל-מאמון"
        },
        {
            "_id": 20896,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אל-מארווה"
        },
        {
            "_id": 20897,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אל-מג'ד"
        },
        {
            "_id": 20898,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אל-מדינה"
        },
        {
            "_id": 20899,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אל-מוודא"
        },
        {
            "_id": 20900,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אל-מוראבטון"
        },
        {
            "_id": 20901,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 390,
            "שם_רחוב": "אל-מורג'אן"
        },
        {
            "_id": 20902,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אל-מחבה"
        },
        {
            "_id": 20903,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 367,
            "שם_רחוב": "אל-מחטה"
        },
        {
            "_id": 20904,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 399,
            "שם_רחוב": "אל-מיחראב"
        },
        {
            "_id": 20905,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 403,
            "שם_רחוב": "אל-מיחראת"
        },
        {
            "_id": 20906,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אל-מינא"
        },
        {
            "_id": 20907,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אל-מיסק"
        },
        {
            "_id": 20908,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אל-מנארה"
        },
        {
            "_id": 20909,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 397,
            "שם_רחוב": "אל-מנג'ל"
        },
        {
            "_id": 20910,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אל-מסג'ד"
        },
        {
            "_id": 20911,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אל-מערי"
        },
        {
            "_id": 20912,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 401,
            "שם_רחוב": "אל-מרח"
        },
        {
            "_id": 20913,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אל-משרוע"
        },
        {
            "_id": 20914,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אל-ע'אבה"
        },
        {
            "_id": 20915,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 395,
            "שם_רחוב": "אל-ע'אר"
        },
        {
            "_id": 20916,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 363,
            "שם_רחוב": "אל-ע'ור"
        },
        {
            "_id": 20917,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 321,
            "שם_רחוב": "אל-ע'זאלי"
        },
        {
            "_id": 20918,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 402,
            "שם_רחוב": "אל-ע'רבאל"
        },
        {
            "_id": 20919,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "אל-עאווסאג'"
        },
        {
            "_id": 20920,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 362,
            "שם_רחוב": "אל-עג'מי"
        },
        {
            "_id": 20921,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אל-עדל"
        },
        {
            "_id": 20922,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אל-עולום"
        },
        {
            "_id": 20923,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אל-עומאל"
        },
        {
            "_id": 20924,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "אל-עומדה"
        },
        {
            "_id": 20925,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אל-עומראן"
        },
        {
            "_id": 20926,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אל-עומרייה"
        },
        {
            "_id": 20927,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 376,
            "שם_רחוב": "אל-עין"
        },
        {
            "_id": 20928,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 407,
            "שם_רחוב": "אל-עינאב"
        },
        {
            "_id": 20929,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 410,
            "שם_רחוב": "אל-עינב"
        },
        {
            "_id": 20930,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אל-עיראקיין"
        },
        {
            "_id": 20931,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אל-ענבר"
        },
        {
            "_id": 20932,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 322,
            "שם_רחוב": "אל-פאראבי"
        },
        {
            "_id": 20933,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 281,
            "שם_רחוב": "אל-פול"
        },
        {
            "_id": 20934,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אל-פלאחין"
        },
        {
            "_id": 20935,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אל-קאדסייה"
        },
        {
            "_id": 20936,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אל-קאהרה"
        },
        {
            "_id": 20937,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אל-קאות'ר"
        },
        {
            "_id": 20938,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אל-קודס"
        },
        {
            "_id": 20939,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 283,
            "שם_רחוב": "אל-קונדיל"
        },
        {
            "_id": 20940,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "אל-קורונפול"
        },
        {
            "_id": 20941,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אל-קלם"
        },
        {
            "_id": 20942,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "אל-קמח"
        },
        {
            "_id": 20943,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6760,
            "שם_רחוב": "אלמשכוע"
        },
        {
            "_id": 20944,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אסיא"
        },
        {
            "_id": 20945,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "אריחא"
        },
        {
            "_id": 20946,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "באב א-נבי דאווד"
        },
        {
            "_id": 20947,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "באב א-סאהירה"
        },
        {
            "_id": 20948,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "באב אל-אסבאט"
        },
        {
            "_id": 20949,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "באב אל-ג'דיד"
        },
        {
            "_id": 20950,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "באב אל-ח'אן"
        },
        {
            "_id": 20951,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "באב אל-ח'ליל"
        },
        {
            "_id": 20952,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "באב אל-מע'ארבה"
        },
        {
            "_id": 20953,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "באב אל-עמוד"
        },
        {
            "_id": 20954,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "באדר"
        },
        {
            "_id": 20955,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 349,
            "שם_רחוב": "באקה אל-חטב"
        },
        {
            "_id": 20956,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "ביירות"
        },
        {
            "_id": 20957,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "בילאל בן רבאח"
        },
        {
            "_id": 20958,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 371,
            "שם_רחוב": "ביסאן"
        },
        {
            "_id": 20959,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 333,
            "שם_רחוב": "בלקיס"
        },
        {
            "_id": 20960,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 384,
            "שם_רחוב": "בני ס'עב"
        },
        {
            "_id": 20961,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "בע'דאד"
        },
        {
            "_id": 20962,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 294,
            "שם_רחוב": "ג'אבר בן חייאן"
        },
        {
            "_id": 20963,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 518,
            "שם_רחוב": "ג'אבר סולטאן"
        },
        {
            "_id": 20964,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 295,
            "שם_רחוב": "ג'ובראן ח'ליל"
        },
        {
            "_id": 20965,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6780,
            "שם_רחוב": "ג'ורת קשוע"
        },
        {
            "_id": 20966,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 375,
            "שם_רחוב": "ג'ינין"
        },
        {
            "_id": 20967,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 404,
            "שם_רחוב": "ג'ירזים"
        },
        {
            "_id": 20968,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 337,
            "שם_רחוב": "ג'עפר בן אבי טאלב"
        },
        {
            "_id": 20969,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 519,
            "שם_רחוב": "ד'יאב סולטאני"
        },
        {
            "_id": 20970,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 526,
            "שם_רחוב": "דאוד אחמד ריזק"
        },
        {
            "_id": 20971,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "דאוד ראזק"
        },
        {
            "_id": 20972,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6770,
            "שם_רחוב": "דאמו"
        },
        {
            "_id": 20973,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "דימשק"
        },
        {
            "_id": 20974,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך א-סולטאני"
        },
        {
            "_id": 20975,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "דרך אל-בסה"
        },
        {
            "_id": 20976,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 400,
            "שם_רחוב": "האג'ר"
        },
        {
            "_id": 20977,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "הארון א-רשיד"
        },
        {
            "_id": 20978,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "העיר העתיקה"
        },
        {
            "_id": 20979,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 297,
            "שם_רחוב": "זייד בן חארית'ה"
        },
        {
            "_id": 20980,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "זמזם"
        },
        {
            "_id": 20981,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 296,
            "שם_רחוב": "ח'אלד בן אל-ווליד"
        },
        {
            "_id": 20982,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 514,
            "שם_רחוב": "ח'ור אבו עייאד"
        },
        {
            "_id": 20983,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 525,
            "שם_רחוב": "ח'ליל עבד אלחק נאסר"
        },
        {
            "_id": 20984,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 515,
            "שם_רחוב": "חאנותה"
        },
        {
            "_id": 20985,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 339,
            "שם_רחוב": "חאפט איברהים"
        },
        {
            "_id": 20986,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "חור א-נדא"
        },
        {
            "_id": 20987,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6750,
            "שם_רחוב": "חור אבו דקר"
        },
        {
            "_id": 20988,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "חור אלנדא"
        },
        {
            "_id": 20989,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6781,
            "שם_רחוב": "חור לס"
        },
        {
            "_id": 20990,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "חיטין"
        },
        {
            "_id": 20991,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 350,
            "שם_רחוב": "חייפא"
        },
        {
            "_id": 20992,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "חיראא"
        },
        {
            "_id": 20993,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "חמזה"
        },
        {
            "_id": 20994,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 405,
            "שם_רחוב": "חנטלה"
        },
        {
            "_id": 20995,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 527,
            "שם_רחוב": "חסין מוסטפא אלג'מאל"
        },
        {
            "_id": 20996,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 331,
            "שם_רחוב": "טאהא חסיין"
        },
        {
            "_id": 20997,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 330,
            "שם_רחוב": "טארק בן זיאד"
        },
        {
            "_id": 20998,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "טארק עבד אל-חי"
        },
        {
            "_id": 20999,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 379,
            "שם_רחוב": "טברייא"
        },
        {
            "_id": 21000,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "טייבה"
        },
        {
            "_id": 21001,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טירה"
        },
        {
            "_id": 21002,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "יאפא"
        },
        {
            "_id": 21003,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 383,
            "שם_רחוב": "יבוס"
        },
        {
            "_id": 21004,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "כרם אלזיתון"
        },
        {
            "_id": 21005,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 524,
            "שם_רחוב": "לוטפי מוחמד סולטאן"
        },
        {
            "_id": 21006,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 340,
            "שם_רחוב": "לוקמאן אל-חכים"
        },
        {
            "_id": 21007,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 335,
            "שם_רחוב": "מאי זיאדה"
        },
        {
            "_id": 21008,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "מואתה"
        },
        {
            "_id": 21009,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 385,
            "שם_רחוב": "מוואריס א-ד'הב"
        },
        {
            "_id": 21010,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "מוחמד אל-פאתח"
        },
        {
            "_id": 21011,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 334,
            "שם_רחוב": "מוס'עב בן עומייר"
        },
        {
            "_id": 21012,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 507,
            "שם_רחוב": "מחמוד בשארה"
        },
        {
            "_id": 21013,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 522,
            "שם_רחוב": "מחמוד חסין קאסם"
        },
        {
            "_id": 21014,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "מחמוד טאהא אלע'זאוי"
        },
        {
            "_id": 21015,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "מכה"
        },
        {
            "_id": 21016,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "מקורות"
        },
        {
            "_id": 21017,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6741,
            "שם_רחוב": "מרכז העיר"
        },
        {
            "_id": 21018,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 352,
            "שם_רחוב": "נאבליס"
        },
        {
            "_id": 21019,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 338,
            "שם_רחוב": "נג'יב מחפוט"
        },
        {
            "_id": 21020,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 529,
            "שם_רחוב": "נזיה מטר"
        },
        {
            "_id": 21021,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "ניסאן"
        },
        {
            "_id": 21022,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 298,
            "שם_רחוב": "ס'לאח א-דין"
        },
        {
            "_id": 21023,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 409,
            "שם_רחוב": "ס'נעאא"
        },
        {
            "_id": 21024,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 369,
            "שם_רחוב": "ס'פד"
        },
        {
            "_id": 21025,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "סייף אל-דין קוטוז"
        },
        {
            "_id": 21026,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 411,
            "שם_רחוב": "סינאא"
        },
        {
            "_id": 21027,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 329,
            "שם_רחוב": "סלמאן אל-פארסי"
        },
        {
            "_id": 21028,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 328,
            "שם_רחוב": "סעד בן מועאד'"
        },
        {
            "_id": 21029,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 520,
            "שם_רחוב": "סעיד סולטאני"
        },
        {
            "_id": 21030,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 382,
            "שם_רחוב": "ע'זה"
        },
        {
            "_id": 21031,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 510,
            "שם_רחוב": "עבד א-רחמאן קדארה"
        },
        {
            "_id": 21032,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 509,
            "שם_רחוב": "עבד אל-חאפיט פדילי"
        },
        {
            "_id": 21033,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "עומר בן אל-ח'טאב"
        },
        {
            "_id": 21034,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 517,
            "שם_רחוב": "עוקאב אחמד קשוע"
        },
        {
            "_id": 21035,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 299,
            "שם_רחוב": "עות'מאן בן עפאן"
        },
        {
            "_id": 21036,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 374,
            "שם_רחוב": "עיבאל"
        },
        {
            "_id": 21037,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "עין ג'אלוד"
        },
        {
            "_id": 21038,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 381,
            "שם_רחוב": "עין כארם"
        },
        {
            "_id": 21039,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 380,
            "שם_רחוב": "עכא"
        },
        {
            "_id": 21040,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 21041,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "עמאר בן יאסר"
        },
        {
            "_id": 21042,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "עמרו בן אל-עאס'"
        },
        {
            "_id": 21043,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "עס'א א-ראעי"
        },
        {
            "_id": 21044,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6771,
            "שם_רחוב": "עקפה"
        },
        {
            "_id": 21045,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "ערפה"
        },
        {
            "_id": 21046,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "פאטמה אל-הודהוד"
        },
        {
            "_id": 21047,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 332,
            "שם_רחוב": "פיירוז"
        },
        {
            "_id": 21048,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "קיבאא"
        },
        {
            "_id": 21049,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 392,
            "שם_רחוב": "קיסריה"
        },
        {
            "_id": 21050,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "קלקיליה"
        },
        {
            "_id": 21051,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 377,
            "שם_רחוב": "ראם אללה"
        },
        {
            "_id": 21052,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 351,
            "שם_רחוב": "ראס עאמר"
        },
        {
            "_id": 21053,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 448,
            "שם_רחוב": "רח 9310"
        },
        {
            "_id": 21054,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 500,
            "שם_רחוב": "רח 9410"
        },
        {
            "_id": 21055,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 436,
            "שם_רחוב": "רח 9420"
        },
        {
            "_id": 21056,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 463,
            "שם_רחוב": "רח 9230"
        },
        {
            "_id": 21057,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 472,
            "שם_רחוב": "רח 9240"
        },
        {
            "_id": 21058,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 443,
            "שם_רחוב": "רח 9340"
        },
        {
            "_id": 21059,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 451,
            "שם_רחוב": "רח 9440"
        },
        {
            "_id": 21060,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 502,
            "שם_רחוב": "רח 9450"
        },
        {
            "_id": 21061,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 428,
            "שם_רחוב": "רח 9180"
        },
        {
            "_id": 21062,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 483,
            "שם_רחוב": "רח 9011"
        },
        {
            "_id": 21063,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 465,
            "שם_רחוב": "רח 9221"
        },
        {
            "_id": 21064,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 454,
            "שם_רחוב": "רח 9421"
        },
        {
            "_id": 21065,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 460,
            "שם_רחוב": "רח 9231"
        },
        {
            "_id": 21066,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 444,
            "שם_רחוב": "רח 9341"
        },
        {
            "_id": 21067,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 422,
            "שם_רחוב": "רח 9161"
        },
        {
            "_id": 21068,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 456,
            "שם_רחוב": "רח 9171"
        },
        {
            "_id": 21069,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 489,
            "שם_רחוב": "רח 9302"
        },
        {
            "_id": 21070,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 450,
            "שם_רחוב": "רח 9402"
        },
        {
            "_id": 21071,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 476,
            "שם_רחוב": "רח 9012"
        },
        {
            "_id": 21072,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 412,
            "שם_רחוב": "רח 9312"
        },
        {
            "_id": 21073,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 439,
            "שם_רחוב": "רח 9322"
        },
        {
            "_id": 21074,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 434,
            "שם_רחוב": "רח 9422"
        },
        {
            "_id": 21075,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 459,
            "שם_רחוב": "רח 9232"
        },
        {
            "_id": 21076,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 432,
            "שם_רחוב": "רח 9152"
        },
        {
            "_id": 21077,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 482,
            "שם_רחוב": "רח 9262"
        },
        {
            "_id": 21078,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 425,
            "שם_רחוב": "רח 9172"
        },
        {
            "_id": 21079,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 427,
            "שם_רחוב": "רח 9182"
        },
        {
            "_id": 21080,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 421,
            "שם_רחוב": "רח 9392"
        },
        {
            "_id": 21081,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 415,
            "שם_רחוב": "רח 9313"
        },
        {
            "_id": 21082,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 495,
            "שם_רחוב": "רח 9423"
        },
        {
            "_id": 21083,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 493,
            "שם_רחוב": "רח 9433"
        },
        {
            "_id": 21084,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 416,
            "שם_רחוב": "רח 9043"
        },
        {
            "_id": 21085,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 464,
            "שם_רחוב": "רח 9393"
        },
        {
            "_id": 21086,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 414,
            "שם_רחוב": "רח 9404"
        },
        {
            "_id": 21087,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 485,
            "שם_רחוב": "רח 9014"
        },
        {
            "_id": 21088,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 501,
            "שם_רחוב": "רח 9314"
        },
        {
            "_id": 21089,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 435,
            "שם_רחוב": "רח 9324"
        },
        {
            "_id": 21090,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 413,
            "שם_רחוב": "רח 9044"
        },
        {
            "_id": 21091,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 417,
            "שם_רחוב": "רח 9344"
        },
        {
            "_id": 21092,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 469,
            "שם_רחוב": "רח 9444"
        },
        {
            "_id": 21093,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 497,
            "שם_רחוב": "רח 9054"
        },
        {
            "_id": 21094,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 446,
            "שם_רחוב": "רח 9305"
        },
        {
            "_id": 21095,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 452,
            "שם_רחוב": "רח 9405"
        },
        {
            "_id": 21096,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 478,
            "שם_רחוב": "רח 9015"
        },
        {
            "_id": 21097,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 453,
            "שם_רחוב": "רח 9415"
        },
        {
            "_id": 21098,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 503,
            "שם_רחוב": "רח 9025"
        },
        {
            "_id": 21099,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 481,
            "שם_רחוב": "רח 9325"
        },
        {
            "_id": 21100,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 504,
            "שם_רחוב": "רח 9435"
        },
        {
            "_id": 21101,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 426,
            "שם_רחוב": "רח 9445"
        },
        {
            "_id": 21102,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 491,
            "שם_רחוב": "רח 9295"
        },
        {
            "_id": 21103,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 474,
            "שם_רחוב": "רח 9006"
        },
        {
            "_id": 21104,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 470,
            "שם_רחוב": "רח 9206"
        },
        {
            "_id": 21105,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 431,
            "שם_רחוב": "רח 9406"
        },
        {
            "_id": 21106,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 479,
            "שם_רחוב": "רח 9016"
        },
        {
            "_id": 21107,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 471,
            "שם_רחוב": "רח 9216"
        },
        {
            "_id": 21108,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 467,
            "שם_רחוב": "רח 9416"
        },
        {
            "_id": 21109,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 441,
            "שם_רחוב": "רח 9326"
        },
        {
            "_id": 21110,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 496,
            "שם_רחוב": "רח 9426"
        },
        {
            "_id": 21111,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 419,
            "שם_רחוב": "רח 9436"
        },
        {
            "_id": 21112,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 461,
            "שם_רחוב": "רח 9446"
        },
        {
            "_id": 21113,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 424,
            "שם_רחוב": "רח 9396"
        },
        {
            "_id": 21114,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 475,
            "שם_רחוב": "רח 9007"
        },
        {
            "_id": 21115,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 480,
            "שם_רחוב": "רח 9017"
        },
        {
            "_id": 21116,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 499,
            "שם_רחוב": "רח 9317"
        },
        {
            "_id": 21117,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 440,
            "שם_רחוב": "רח 9327"
        },
        {
            "_id": 21118,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 429,
            "שם_רחוב": "רח 9427"
        },
        {
            "_id": 21119,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 442,
            "שם_רחוב": "רח 9347"
        },
        {
            "_id": 21120,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 490,
            "שם_רחוב": "רח 9447"
        },
        {
            "_id": 21121,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 458,
            "שם_רחוב": "רח 9167"
        },
        {
            "_id": 21122,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 437,
            "שם_רחוב": "רח 9008"
        },
        {
            "_id": 21123,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 488,
            "שם_רחוב": "רח 9018"
        },
        {
            "_id": 21124,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 462,
            "שם_רחוב": "רח 9228"
        },
        {
            "_id": 21125,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 473,
            "שם_רחוב": "רח 9238"
        },
        {
            "_id": 21126,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 445,
            "שם_רחוב": "רח 9338"
        },
        {
            "_id": 21127,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 433,
            "שם_רחוב": "רח 9148"
        },
        {
            "_id": 21128,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 505,
            "שם_רחוב": "רח 9348"
        },
        {
            "_id": 21129,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 420,
            "שם_רחוב": "רח 9448"
        },
        {
            "_id": 21130,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 423,
            "שם_רחוב": "רח 9178"
        },
        {
            "_id": 21131,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 486,
            "שם_רחוב": "רח 9278"
        },
        {
            "_id": 21132,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 418,
            "שם_רחוב": "רח 9188"
        },
        {
            "_id": 21133,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 438,
            "שם_רחוב": "רח 9009"
        },
        {
            "_id": 21134,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 487,
            "שם_רחוב": "רח 9019"
        },
        {
            "_id": 21135,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 466,
            "שם_רחוב": "רח 9219"
        },
        {
            "_id": 21136,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 494,
            "שם_רחוב": "רח 9319"
        },
        {
            "_id": 21137,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 492,
            "שם_רחוב": "רח 9419"
        },
        {
            "_id": 21138,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 447,
            "שם_רחוב": "רח 9339"
        },
        {
            "_id": 21139,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 468,
            "שם_רחוב": "רח 9439"
        },
        {
            "_id": 21140,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 498,
            "שם_רחוב": "רח 9049"
        },
        {
            "_id": 21141,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 430,
            "שם_רחוב": "רח 9159"
        },
        {
            "_id": 21142,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 506,
            "שם_רחוב": "רח 9459"
        },
        {
            "_id": 21143,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 455,
            "שם_רחוב": "רח 9169"
        },
        {
            "_id": 21144,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 449,
            "שם_רחוב": "רח 9079"
        },
        {
            "_id": 21145,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 484,
            "שם_רחוב": "רח 9279"
        },
        {
            "_id": 21146,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 457,
            "שם_רחוב": "רח 9399"
        },
        {
            "_id": 21147,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "רמדאן"
        },
        {
            "_id": 21148,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "שכונה מזרחית"
        },
        {
            "_id": 21149,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "שעבאן"
        },
        {
            "_id": 21150,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "שקאאיק א-נועמאן"
        },
        {
            "_id": 21151,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "שרחביל בן חסנה"
        },
        {
            "_id": 21152,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 327,
            "שם_רחוב": "תאופיק זייאד"
        },
        {
            "_id": 21153,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "תבוכ"
        },
        {
            "_id": 21154,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "תוניס"
        },
        {
            "_id": 21155,
            "סמל_ישוב": 2720,
            "שם_ישוב": "טירה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "תשרין"
        },
        {
            "_id": 21156,
            "סמל_ישוב": 663,
            "שם_ישוב": "טירת יהודה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טירת יהודה"
        },
        {
            "_id": 21157,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "אזור התעשיה"
        },
        {
            "_id": 21158,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "איילת הכרמל"
        },
        {
            "_id": 21159,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 449,
            "שם_רחוב": "אלבז נתן"
        },
        {
            "_id": 21160,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 455,
            "שם_רחוב": "אליאס כרמל"
        },
        {
            "_id": 21161,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 21162,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 21163,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אריאל שרון"
        },
        {
            "_id": 21164,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אתגר"
        },
        {
            "_id": 21165,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 21166,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 310,
            "שם_רחוב": "בן צבי"
        },
        {
            "_id": 21167,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 445,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 21168,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 454,
            "שם_רחוב": "גיורא"
        },
        {
            "_id": 21169,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דולפין"
        },
        {
            "_id": 21170,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דקר"
        },
        {
            "_id": 21171,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 329,
            "שם_רחוב": "דרך נחל גלים"
        },
        {
            "_id": 21172,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 135,
            "שם_רחוב": "האגמית"
        },
        {
            "_id": 21173,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 326,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 21174,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 325,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 21175,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 133,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 21176,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 453,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 21177,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 323,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 21178,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 131,
            "שם_רחוב": "הגל"
        },
        {
            "_id": 21179,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 320,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 21180,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 423,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 21181,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 21182,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 321,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 21183,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 322,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 21184,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 136,
            "שם_רחוב": "היסעור"
        },
        {
            "_id": 21185,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "היצירה"
        },
        {
            "_id": 21186,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הכלניות"
        },
        {
            "_id": 21187,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 210,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 21188,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 404,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 21189,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "המרכז לבריאות הנפש"
        },
        {
            "_id": 21190,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 21191,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 208,
            "שם_רחוב": "הסדנא"
        },
        {
            "_id": 21192,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 134,
            "שם_רחוב": "הסייפן"
        },
        {
            "_id": 21193,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 137,
            "שם_רחוב": "העגור"
        },
        {
            "_id": 21194,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 425,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 21195,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 211,
            "שם_רחוב": "הפטיש"
        },
        {
            "_id": 21196,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 409,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 21197,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 448,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 21198,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 452,
            "שם_רחוב": "הרב ברזני סולימן"
        },
        {
            "_id": 21199,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 440,
            "שם_רחוב": "הרב וינרוב"
        },
        {
            "_id": 21200,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 437,
            "שם_רחוב": "הרב זרביב"
        },
        {
            "_id": 21201,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 451,
            "שם_רחוב": "הרב מרדכי משה"
        },
        {
            "_id": 21202,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 435,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 21203,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 460,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 21204,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 324,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 21205,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 434,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 21206,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 132,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 21207,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 418,
            "שם_רחוב": "השחרור"
        },
        {
            "_id": 21208,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 138,
            "שם_רחוב": "השלדג"
        },
        {
            "_id": 21209,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 456,
            "שם_רחוב": "השריון"
        },
        {
            "_id": 21210,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 450,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 21211,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 439,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 21212,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 461,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 21213,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 21214,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 212,
            "שם_רחוב": "חת נחום"
        },
        {
            "_id": 21215,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טירת כרמל"
        },
        {
            "_id": 21216,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "יא הספורטאים"
        },
        {
            "_id": 21217,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 202,
            "שם_רחוב": "יוזמה"
        },
        {
            "_id": 21218,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "יצחק שמיר"
        },
        {
            "_id": 21219,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 458,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 21220,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 441,
            "שם_רחוב": "כג יורדי הסירה"
        },
        {
            "_id": 21221,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר דקר"
        },
        {
            "_id": 21222,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר השריון"
        },
        {
            "_id": 21223,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר יא הספורטאים"
        },
        {
            "_id": 21224,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר ירושלים"
        },
        {
            "_id": 21225,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "כפר טירה"
        },
        {
            "_id": 21226,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 205,
            "שם_רחוב": "כרמלים"
        },
        {
            "_id": 21227,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 123,
            "שם_רחוב": "לאה גולדברג"
        },
        {
            "_id": 21228,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 443,
            "שם_רחוב": "לוי אשכול"
        },
        {
            "_id": 21229,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 21230,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 447,
            "שם_רחוב": "לניאדו עזרא"
        },
        {
            "_id": 21231,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 433,
            "שם_רחוב": "מוצקין"
        },
        {
            "_id": 21232,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 431,
            "שם_רחוב": "מלחמת ששת הימים"
        },
        {
            "_id": 21233,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "מע דרומית"
        },
        {
            "_id": 21234,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "מע צפונית א"
        },
        {
            "_id": 21235,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "מע צפונית ג"
        },
        {
            "_id": 21236,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 471,
            "שם_רחוב": "מעלה נוף"
        },
        {
            "_id": 21237,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 119,
            "שם_רחוב": "מפרש"
        },
        {
            "_id": 21238,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "מרים ילן שטקליס"
        },
        {
            "_id": 21239,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "נאות כרמל"
        },
        {
            "_id": 21240,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "נוה אליאס"
        },
        {
            "_id": 21241,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "נווה גלים"
        },
        {
            "_id": 21242,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 430,
            "שם_רחוב": "נורי דוד"
        },
        {
            "_id": 21243,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 21244,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 100,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 21245,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "סיגלית"
        },
        {
            "_id": 21246,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 305,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 21247,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 426,
            "שם_רחוב": "סמ ההגנה 2"
        },
        {
            "_id": 21248,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 307,
            "שם_רחוב": "סמ המעפיל 10"
        },
        {
            "_id": 21249,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 401,
            "שם_רחוב": "סמ המעפיל 1"
        },
        {
            "_id": 21250,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 406,
            "שם_רחוב": "סמ המעפיל 4"
        },
        {
            "_id": 21251,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 301,
            "שם_רחוב": "סמ המעפיל 7"
        },
        {
            "_id": 21252,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 411,
            "שם_רחוב": "סמ המעפיל 8"
        },
        {
            "_id": 21253,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 410,
            "שם_רחוב": "סמ המעפיל 9"
        },
        {
            "_id": 21254,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 428,
            "שם_רחוב": "סמ הפלמח 1"
        },
        {
            "_id": 21255,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 414,
            "שם_רחוב": "סמ הפלמח 5"
        },
        {
            "_id": 21256,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 413,
            "שם_רחוב": "סמ הפלמח 6"
        },
        {
            "_id": 21257,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 306,
            "שם_רחוב": "סמ הפלמח 8"
        },
        {
            "_id": 21258,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 462,
            "שם_רחוב": "סמ הרדוף"
        },
        {
            "_id": 21259,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 209,
            "שם_רחוב": "סמ כרמל"
        },
        {
            "_id": 21260,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 417,
            "שם_רחוב": "סמ עליה ב 2"
        },
        {
            "_id": 21261,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 444,
            "שם_רחוב": "סמ שיטרית"
        },
        {
            "_id": 21262,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 457,
            "שם_רחוב": "עגנון שי"
        },
        {
            "_id": 21263,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "עוגן"
        },
        {
            "_id": 21264,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 206,
            "שם_רחוב": "עוצמה"
        },
        {
            "_id": 21265,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 415,
            "שם_רחוב": "עליה ב"
        },
        {
            "_id": 21266,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "צדף"
        },
        {
            "_id": 21267,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 207,
            "שם_רחוב": "קדמה"
        },
        {
            "_id": 21268,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 432,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 21269,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 446,
            "שם_רחוב": "רזיאל"
        },
        {
            "_id": 21270,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רחל המשוררת"
        },
        {
            "_id": 21271,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 436,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 21272,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "רמת בגין"
        },
        {
            "_id": 21273,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש אגש"
        },
        {
            "_id": 21274,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש אסבסטונים"
        },
        {
            "_id": 21275,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש בן צבי"
        },
        {
            "_id": 21276,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש גיורא"
        },
        {
            "_id": 21277,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש ויצמן"
        },
        {
            "_id": 21278,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "ש כהן"
        },
        {
            "_id": 21279,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "ש ממשלתי"
        },
        {
            "_id": 21280,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 21281,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "ש שיטרית"
        },
        {
            "_id": 21282,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "ש שרת"
        },
        {
            "_id": 21283,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 130,
            "שם_רחוב": "שדרות מנחם בגין"
        },
        {
            "_id": 21284,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שונית"
        },
        {
            "_id": 21285,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ ביאליק"
        },
        {
            "_id": 21286,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "שכ ברנר"
        },
        {
            "_id": 21287,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "שכ גלי כרמל"
        },
        {
            "_id": 21288,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "שכ הפרחים"
        },
        {
            "_id": 21289,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "שכ כלניות"
        },
        {
            "_id": 21290,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "שכ מעלה נוף"
        },
        {
            "_id": 21291,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "שכ רמבם"
        },
        {
            "_id": 21292,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "שכ שזר"
        },
        {
            "_id": 21293,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "שכונת אשכול"
        },
        {
            "_id": 21294,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 213,
            "שם_רחוב": "שלמה גולד"
        },
        {
            "_id": 21295,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 438,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 21296,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "תורן"
        },
        {
            "_id": 21297,
            "סמל_ישוב": 2100,
            "שם_ישוב": "טירת כרמל ",
            "סמל_רחוב": 203,
            "שם_רחוב": "תנופה"
        },
        {
            "_id": 21298,
            "סמל_ישוב": 268,
            "שם_ישוב": "טירת צבי ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אולפן קיבוץ"
        },
        {
            "_id": 21299,
            "סמל_ישוב": 268,
            "שם_ישוב": "טירת צבי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טירת צבי"
        },
        {
            "_id": 21300,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הכנרת"
        },
        {
            "_id": 21301,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "המייסדות והמייסדים"
        },
        {
            "_id": 21302,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טל שחר"
        },
        {
            "_id": 21303,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "נהר הירדן"
        },
        {
            "_id": 21304,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נחל איילון"
        },
        {
            "_id": 21305,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נחל ארנון"
        },
        {
            "_id": 21306,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נחל דן"
        },
        {
            "_id": 21307,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "נחל הבשור"
        },
        {
            "_id": 21308,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "נחל לכיש"
        },
        {
            "_id": 21309,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "נחל פארן"
        },
        {
            "_id": 21310,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "נחל קדרון"
        },
        {
            "_id": 21311,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נחל שורק"
        },
        {
            "_id": 21312,
            "סמל_ישוב": 462,
            "שם_ישוב": "טל שחר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "נחל שניר"
        },
        {
            "_id": 21313,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 21314,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 21315,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 21316,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האילן"
        },
        {
            "_id": 21317,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 21318,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 21319,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 21320,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 21321,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 21322,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 21323,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 21324,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 21325,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 21326,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טל-אל"
        },
        {
            "_id": 21327,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 21328,
            "סמל_ישוב": 1181,
            "שם_ישוב": "טל-אל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 21329,
            "סמל_ישוב": 1177,
            "שם_ישוב": "טללים ",
            "סמל_רחוב": 7002,
            "שם_רחוב": "חוות זוהר המדבר"
        },
        {
            "_id": 21330,
            "סמל_ישוב": 1177,
            "שם_ישוב": "טללים ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "חוות מתנת מדבר"
        },
        {
            "_id": 21331,
            "סמל_ישוב": 1177,
            "שם_ישוב": "טללים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טללים"
        },
        {
            "_id": 21332,
            "סמל_ישוב": 1177,
            "שם_ישוב": "טללים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 21333,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 21334,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 21335,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 21336,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אריאל"
        },
        {
            "_id": 21337,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 146,
            "שם_רחוב": "ארץ ימיני"
        },
        {
            "_id": 21338,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 148,
            "שם_רחוב": "ארץ צוף"
        },
        {
            "_id": 21339,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 143,
            "שם_רחוב": "ארץ שלישה"
        },
        {
            "_id": 21340,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 144,
            "שם_רחוב": "ארץ שעלים"
        },
        {
            "_id": 21341,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אשכול"
        },
        {
            "_id": 21342,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אשת לפידות"
        },
        {
            "_id": 21343,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 150,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 21344,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 141,
            "שם_רחוב": "ברכת הבנים"
        },
        {
            "_id": 21345,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 139,
            "שם_רחוב": "ברכת שמים"
        },
        {
            "_id": 21346,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 129,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 21347,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 135,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 21348,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 155,
            "שם_רחוב": "דרך אביה"
        },
        {
            "_id": 21349,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 160,
            "שם_רחוב": "דרך הרי בשמים"
        },
        {
            "_id": 21350,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 131,
            "שם_רחוב": "דרך שילה"
        },
        {
            "_id": 21351,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 132,
            "שם_רחוב": "האופניים"
        },
        {
            "_id": 21352,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 21353,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 21354,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 21355,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 151,
            "שם_רחוב": "היער"
        },
        {
            "_id": 21356,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 21357,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 170,
            "שם_רחוב": "הניצנים"
        },
        {
            "_id": 21358,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 21359,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 137,
            "שם_רחוב": "הר אפרים"
        },
        {
            "_id": 21360,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 163,
            "שם_רחוב": "הר המור"
        },
        {
            "_id": 21361,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הר כרכום"
        },
        {
            "_id": 21362,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הראל"
        },
        {
            "_id": 21363,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הרואה"
        },
        {
            "_id": 21364,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 167,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 21365,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 166,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 21366,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 21367,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 164,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 21368,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 128,
            "שם_רחוב": "זית"
        },
        {
            "_id": 21369,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 140,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 21370,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טלמון"
        },
        {
            "_id": 21371,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 120,
            "שם_רחוב": "יהושע בן נון"
        },
        {
            "_id": 21372,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 21373,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 134,
            "שם_רחוב": "לבנה"
        },
        {
            "_id": 21374,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "לבנון"
        },
        {
            "_id": 21375,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 136,
            "שם_רחוב": "לוז"
        },
        {
            "_id": 21376,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 145,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 21377,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 119,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 21378,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 169,
            "שם_רחוב": "מים חיים"
        },
        {
            "_id": 21379,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 147,
            "שם_רחוב": "מרוה"
        },
        {
            "_id": 21380,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 165,
            "שם_רחוב": "משעול הכרמים"
        },
        {
            "_id": 21381,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 162,
            "שם_רחוב": "משעול צרור המור"
        },
        {
            "_id": 21382,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "נווה"
        },
        {
            "_id": 21383,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נחל פרת"
        },
        {
            "_id": 21384,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "נחל צין"
        },
        {
            "_id": 21385,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "נחל שניר"
        },
        {
            "_id": 21386,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 125,
            "שם_רחוב": "נחלי געש"
        },
        {
            "_id": 21387,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "נריה"
        },
        {
            "_id": 21388,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 21389,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 138,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 21390,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 133,
            "שם_רחוב": "ערמון"
        },
        {
            "_id": 21391,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 168,
            "שם_רחוב": "פרי מגדים"
        },
        {
            "_id": 21392,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 142,
            "שם_רחוב": "קידה"
        },
        {
            "_id": 21393,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 127,
            "שם_רחוב": "רמתיים צופים"
        },
        {
            "_id": 21394,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 121,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 21395,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 161,
            "שם_רחוב": "שיר השירים"
        },
        {
            "_id": 21396,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכונת חרשה"
        },
        {
            "_id": 21397,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "שכונת טלמון א'"
        },
        {
            "_id": 21398,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכונת כרם רעים"
        },
        {
            "_id": 21399,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכונת נוה טלמון"
        },
        {
            "_id": 21400,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 149,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 21401,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 124,
            "שם_רחוב": "תומר דבורה"
        },
        {
            "_id": 21402,
            "סמל_ישוב": 3788,
            "שם_ישוב": "טלמון ",
            "סמל_רחוב": 126,
            "שם_רחוב": "תמנה"
        },
        {
            "_id": 21403,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 335,
            "שם_רחוב": "א-זערור"
        },
        {
            "_id": 21404,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "א-טאבון"
        },
        {
            "_id": 21405,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "א-טאחונה"
        },
        {
            "_id": 21406,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 347,
            "שם_רחוב": "א-טברי"
        },
        {
            "_id": 21407,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "א-טיאר"
        },
        {
            "_id": 21408,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "א-טיון"
        },
        {
            "_id": 21409,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 425,
            "שם_רחוב": "א-לימון"
        },
        {
            "_id": 21410,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "א-נאטור"
        },
        {
            "_id": 21411,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 429,
            "שם_רחוב": "א-נבעה"
        },
        {
            "_id": 21412,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 414,
            "שם_רחוב": "א-נג'ארין"
        },
        {
            "_id": 21413,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 435,
            "שם_רחוב": "א-נגד"
        },
        {
            "_id": 21414,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "א-נהר"
        },
        {
            "_id": 21415,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 369,
            "שם_רחוב": "א-נזאזיאת"
        },
        {
            "_id": 21416,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 384,
            "שם_רחוב": "א-נחל"
        },
        {
            "_id": 21417,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 281,
            "שם_רחוב": "א-נסמה"
        },
        {
            "_id": 21418,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "א-נסר"
        },
        {
            "_id": 21419,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "א-נענע"
        },
        {
            "_id": 21420,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 432,
            "שם_רחוב": "א-נרג'ס"
        },
        {
            "_id": 21421,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "א-ס'חאבה"
        },
        {
            "_id": 21422,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 315,
            "שם_רחוב": "א-סאלחין"
        },
        {
            "_id": 21423,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 449,
            "שם_רחוב": "א-סבאר"
        },
        {
            "_id": 21424,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "א-סבארין"
        },
        {
            "_id": 21425,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 339,
            "שם_רחוב": "א-סדאקה"
        },
        {
            "_id": 21426,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "א-סומאק"
        },
        {
            "_id": 21427,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "א-סונדס"
        },
        {
            "_id": 21428,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 379,
            "שם_רחוב": "א-סוסן"
        },
        {
            "_id": 21429,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 318,
            "שם_רחוב": "א-סיידה עאישה"
        },
        {
            "_id": 21430,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 294,
            "שם_רחוב": "א-סיכה"
        },
        {
            "_id": 21431,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 295,
            "שם_רחוב": "א-סמאחה"
        },
        {
            "_id": 21432,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 374,
            "שם_רחוב": "א-סנובר"
        },
        {
            "_id": 21433,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 338,
            "שם_רחוב": "א-סעאדה"
        },
        {
            "_id": 21434,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "א-ספוח"
        },
        {
            "_id": 21435,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 343,
            "שם_רחוב": "א-ספסאף"
        },
        {
            "_id": 21436,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "א-סקור"
        },
        {
            "_id": 21437,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 407,
            "שם_רחוב": "א-סרווה"
        },
        {
            "_id": 21438,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "א-ראפעי"
        },
        {
            "_id": 21439,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 344,
            "שם_רחוב": "א-רביע"
        },
        {
            "_id": 21440,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 436,
            "שם_רחוב": "א-רוואבי"
        },
        {
            "_id": 21441,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 376,
            "שם_רחוב": "א-רחמה"
        },
        {
            "_id": 21442,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "א-ריבאט"
        },
        {
            "_id": 21443,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "א-ריף"
        },
        {
            "_id": 21444,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 450,
            "שם_רחוב": "א-רמאל"
        },
        {
            "_id": 21445,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 282,
            "שם_רחוב": "א-שאם"
        },
        {
            "_id": 21446,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 396,
            "שם_רחוב": "א-שארע אל אחד'ר"
        },
        {
            "_id": 21447,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 447,
            "שם_רחוב": "א-שג'רה"
        },
        {
            "_id": 21448,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 442,
            "שם_רחוב": "א-שוואטיא"
        },
        {
            "_id": 21449,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "א-שועראא"
        },
        {
            "_id": 21450,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 397,
            "שם_רחוב": "א-שוק אל-בלדי"
        },
        {
            "_id": 21451,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 427,
            "שם_רחוב": "א-שחרורה"
        },
        {
            "_id": 21452,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 296,
            "שם_רחוב": "א-שיח' רסלאן"
        },
        {
            "_id": 21453,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 341,
            "שם_רחוב": "א-שפאא"
        },
        {
            "_id": 21454,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 387,
            "שם_רחוב": "א-שראע"
        },
        {
            "_id": 21455,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 357,
            "שם_רחוב": "א-שרוק"
        },
        {
            "_id": 21456,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "א-שריף"
        },
        {
            "_id": 21457,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "א-תורקמאן"
        },
        {
            "_id": 21458,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 389,
            "שם_רחוב": "אבו אל קאבאר"
        },
        {
            "_id": 21459,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "אבו אל-רמאן"
        },
        {
            "_id": 21460,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "אבו בכר א-סדיק"
        },
        {
            "_id": 21461,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אבו הוריירה"
        },
        {
            "_id": 21462,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אבו זר אל-גפארי"
        },
        {
            "_id": 21463,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 283,
            "שם_רחוב": "אבו סלמה"
        },
        {
            "_id": 21464,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6750,
            "שם_רחוב": "אבו רומאן"
        },
        {
            "_id": 21465,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אבו-עמאר"
        },
        {
            "_id": 21466,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "אבן אל-ביטאר"
        },
        {
            "_id": 21467,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 356,
            "שם_רחוב": "אבן אל-היית'ם"
        },
        {
            "_id": 21468,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אבן אל-נפיס"
        },
        {
            "_id": 21469,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 391,
            "שם_רחוב": "אבן אל-רומי"
        },
        {
            "_id": 21470,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אבן בטוטה"
        },
        {
            "_id": 21471,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אבן זיידון"
        },
        {
            "_id": 21472,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 21473,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "אבן חיזמה"
        },
        {
            "_id": 21474,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 21475,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "אבן פראס אל-חמדאני"
        },
        {
            "_id": 21476,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 317,
            "שם_רחוב": "אבן קת'יר"
        },
        {
            "_id": 21477,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 411,
            "שם_רחוב": "אבן ריחאן"
        },
        {
            "_id": 21478,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 446,
            "שם_רחוב": "אבן רשד"
        },
        {
            "_id": 21479,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אוחוד"
        },
        {
            "_id": 21480,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אום כולתום"
        },
        {
            "_id": 21481,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "אזמיר"
        },
        {
            "_id": 21482,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אחמד בן בלה"
        },
        {
            "_id": 21483,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 336,
            "שם_רחוב": "איוב"
        },
        {
            "_id": 21484,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אל בורואה"
        },
        {
            "_id": 21485,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 326,
            "שם_רחוב": "אל בירה"
        },
        {
            "_id": 21486,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אל מעראג"
        },
        {
            "_id": 21487,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אל קוואפל"
        },
        {
            "_id": 21488,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 350,
            "שם_רחוב": "אל רויס"
        },
        {
            "_id": 21489,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 284,
            "שם_רחוב": "אל-אבריק"
        },
        {
            "_id": 21490,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אל-אודבאא"
        },
        {
            "_id": 21491,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אל-אח'וה"
        },
        {
            "_id": 21492,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 464,
            "שם_רחוב": "אל-אימאם אבו חניפה"
        },
        {
            "_id": 21493,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "אל-אימאם אבן חנבל"
        },
        {
            "_id": 21494,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "אל-אמאנה"
        },
        {
            "_id": 21495,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 319,
            "שם_רחוב": "אל-אמין"
        },
        {
            "_id": 21496,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "אל-אמל"
        },
        {
            "_id": 21497,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אל-אנבאר"
        },
        {
            "_id": 21498,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 322,
            "שם_רחוב": "אל-אנדלוס"
        },
        {
            "_id": 21499,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אל-אקפאל"
        },
        {
            "_id": 21500,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 430,
            "שם_רחוב": "אל-אקצא"
        },
        {
            "_id": 21501,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 441,
            "שם_רחוב": "אל-ארז"
        },
        {
            "_id": 21502,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 415,
            "שם_רחוב": "אל-אשקר"
        },
        {
            "_id": 21503,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 367,
            "שם_רחוב": "אל-באז"
        },
        {
            "_id": 21504,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 448,
            "שם_רחוב": "אל-בואדי"
        },
        {
            "_id": 21505,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 437,
            "שם_רחוב": "אל-בוחתרי"
        },
        {
            "_id": 21506,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אל-בונדקייה"
        },
        {
            "_id": 21507,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אל-בוסתאן"
        },
        {
            "_id": 21508,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אל-בח'ארי"
        },
        {
            "_id": 21509,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אל-בטם"
        },
        {
            "_id": 21510,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 362,
            "שם_רחוב": "אל-ביאדר"
        },
        {
            "_id": 21511,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 366,
            "שם_רחוב": "אל-ביאן"
        },
        {
            "_id": 21512,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אל-ביידר"
        },
        {
            "_id": 21513,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 371,
            "שם_רחוב": "אל-בירוני"
        },
        {
            "_id": 21514,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 393,
            "שם_רחוב": "אל-בלאבל"
        },
        {
            "_id": 21515,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 378,
            "שם_רחוב": "אל-בלאן"
        },
        {
            "_id": 21516,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אל-בלדיה"
        },
        {
            "_id": 21517,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 285,
            "שם_רחוב": "אל-בסמה"
        },
        {
            "_id": 21518,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אל-בצרה"
        },
        {
            "_id": 21519,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אל-ג'בל"
        },
        {
            "_id": 21520,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 426,
            "שם_רחוב": "אל-ג'וז"
        },
        {
            "_id": 21521,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אל-ג'ולאן"
        },
        {
            "_id": 21522,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אל-גאר"
        },
        {
            "_id": 21523,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "אל-גזאלי"
        },
        {
            "_id": 21524,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 390,
            "שם_רחוב": "אל-גית'"
        },
        {
            "_id": 21525,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "אל-גמיז"
        },
        {
            "_id": 21526,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 381,
            "שם_רחוב": "אל-גרוב"
        },
        {
            "_id": 21527,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אל-דוירי"
        },
        {
            "_id": 21528,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 404,
            "שם_רחוב": "אל-דריג'את"
        },
        {
            "_id": 21529,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "אל-הדבה"
        },
        {
            "_id": 21530,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 431,
            "שם_רחוב": "אל-הודא"
        },
        {
            "_id": 21531,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אל-הנא"
        },
        {
            "_id": 21532,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אל-הרבה"
        },
        {
            "_id": 21533,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 368,
            "שם_רחוב": "אל-ודיאן"
        },
        {
            "_id": 21534,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "אל-וחדה"
        },
        {
            "_id": 21535,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 340,
            "שם_רחוב": "אל-ורוד"
        },
        {
            "_id": 21536,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אל-זיב"
        },
        {
            "_id": 21537,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 420,
            "שם_רחוב": "אל-זייתונה"
        },
        {
            "_id": 21538,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אל-זינה"
        },
        {
            "_id": 21539,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 422,
            "שם_רחוב": "אל-זמפק"
        },
        {
            "_id": 21540,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אל-זעפראן"
        },
        {
            "_id": 21541,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 402,
            "שם_רחוב": "אל-זראעיה"
        },
        {
            "_id": 21542,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אל-ח'דר"
        },
        {
            "_id": 21543,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אל-ח'וארזמי"
        },
        {
            "_id": 21544,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אל-ח'יר"
        },
        {
            "_id": 21545,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 445,
            "שם_רחוב": "אל-ח'לדיה"
        },
        {
            "_id": 21546,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "אל-ח'ליל"
        },
        {
            "_id": 21547,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אל-ח'ליפה"
        },
        {
            "_id": 21548,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אל-ח'נדק"
        },
        {
            "_id": 21549,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אל-ח'רוב"
        },
        {
            "_id": 21550,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 462,
            "שם_רחוב": "אל-חבלי"
        },
        {
            "_id": 21551,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אל-חג'אג'"
        },
        {
            "_id": 21552,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 372,
            "שם_רחוב": "אל-חג'אז"
        },
        {
            "_id": 21553,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אל-חג'ל"
        },
        {
            "_id": 21554,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 412,
            "שם_רחוב": "אל-חדאדין"
        },
        {
            "_id": 21555,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 348,
            "שם_רחוב": "אל-חדיקה"
        },
        {
            "_id": 21556,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 399,
            "שם_רחוב": "אל-חוז'אן"
        },
        {
            "_id": 21557,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 286,
            "שם_רחוב": "אל-חולה"
        },
        {
            "_id": 21558,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 409,
            "שם_רחוב": "אל-חור"
        },
        {
            "_id": 21559,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אל-חיאה"
        },
        {
            "_id": 21560,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 287,
            "שם_רחוב": "אל-חיס'ן"
        },
        {
            "_id": 21561,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 424,
            "שם_רחוב": "אל-חלאג'"
        },
        {
            "_id": 21562,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אל-חלאל"
        },
        {
            "_id": 21563,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 453,
            "שם_רחוב": "אל-חמאם"
        },
        {
            "_id": 21564,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אל-חמרה"
        },
        {
            "_id": 21565,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 454,
            "שם_רחוב": "אל-חקמה"
        },
        {
            "_id": 21566,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 342,
            "שם_רחוב": "אל-חריר"
        },
        {
            "_id": 21567,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 297,
            "שם_רחוב": "אל-טאקה"
        },
        {
            "_id": 21568,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אל-ימאמה"
        },
        {
            "_id": 21569,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אל-ירמוך"
        },
        {
            "_id": 21570,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אל-כופי"
        },
        {
            "_id": 21571,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 288,
            "שם_רחוב": "אל-כראמה"
        },
        {
            "_id": 21572,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אל-כרכ"
        },
        {
            "_id": 21573,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 298,
            "שם_רחוב": "אל-לוז"
        },
        {
            "_id": 21574,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אל-לולואה"
        },
        {
            "_id": 21575,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 323,
            "שם_רחוב": "אל-מאמון"
        },
        {
            "_id": 21576,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אל-מג'נה"
        },
        {
            "_id": 21577,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 452,
            "שם_רחוב": "אל-מגד"
        },
        {
            "_id": 21578,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 394,
            "שם_רחוב": "אל-מדרסה א-סנאעיה"
        },
        {
            "_id": 21579,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אל-מהג'ור"
        },
        {
            "_id": 21580,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 289,
            "שם_רחוב": "אל-מהדי"
        },
        {
            "_id": 21581,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אל-מואפק באללה"
        },
        {
            "_id": 21582,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 460,
            "שם_רחוב": "אל-מוכתאר"
        },
        {
            "_id": 21583,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אל-מונתבי"
        },
        {
            "_id": 21584,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 421,
            "שם_רחוב": "אל-מונתזה"
        },
        {
            "_id": 21585,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "אל-מוס'ול"
        },
        {
            "_id": 21586,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אל-מטל"
        },
        {
            "_id": 21587,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 377,
            "שם_רחוב": "אל-מירמיה"
        },
        {
            "_id": 21588,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "אל-מל"
        },
        {
            "_id": 21589,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 363,
            "שם_רחוב": "אל-מלכ סולימאן"
        },
        {
            "_id": 21590,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 395,
            "שם_רחוב": "אל-מלעב אל-בלדי"
        },
        {
            "_id": 21591,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "אל-מנארה"
        },
        {
            "_id": 21592,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 406,
            "שם_רחוב": "אל-מנג'ל"
        },
        {
            "_id": 21593,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אל-מנדוב"
        },
        {
            "_id": 21594,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אל-מנהל"
        },
        {
            "_id": 21595,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 400,
            "שם_רחוב": "אל-מנטקה א-סנאעיה"
        },
        {
            "_id": 21596,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 324,
            "שם_רחוב": "אל-מנפלוטי"
        },
        {
            "_id": 21597,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 346,
            "שם_רחוב": "אל-מסארב"
        },
        {
            "_id": 21598,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 451,
            "שם_רחוב": "אל-מסלה"
        },
        {
            "_id": 21599,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אל-מסלוקה"
        },
        {
            "_id": 21600,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "אל-מראח"
        },
        {
            "_id": 21601,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אל-מראעי"
        },
        {
            "_id": 21602,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "אל-סולטאן"
        },
        {
            "_id": 21603,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 364,
            "שם_רחוב": "אל-סעבה"
        },
        {
            "_id": 21604,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "אל-עאמוד"
        },
        {
            "_id": 21605,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 299,
            "שם_רחוב": "אל-עוד"
        },
        {
            "_id": 21606,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אל-עומאל"
        },
        {
            "_id": 21607,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 458,
            "שם_רחוב": "אל-עוסאג'"
        },
        {
            "_id": 21608,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אל-עוקדה"
        },
        {
            "_id": 21609,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 380,
            "שם_רחוב": "אל-עטא'א"
        },
        {
            "_id": 21610,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 459,
            "שם_רחוב": "אל-עטאר"
        },
        {
            "_id": 21611,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "אל-עיאדה"
        },
        {
            "_id": 21612,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אל-עין"
        },
        {
            "_id": 21613,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אל-עמראן"
        },
        {
            "_id": 21614,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אל-ענאב"
        },
        {
            "_id": 21615,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אל-עסל"
        },
        {
            "_id": 21616,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 434,
            "שם_רחוב": "אל-עראק"
        },
        {
            "_id": 21617,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אל-ערוס"
        },
        {
            "_id": 21618,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 337,
            "שם_רחוב": "אל-עתול"
        },
        {
            "_id": 21619,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "אל-פאנוס"
        },
        {
            "_id": 21620,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 349,
            "שם_רחוב": "אל-פוראט"
        },
        {
            "_id": 21621,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 385,
            "שם_רחוב": "אל-פח'אר"
        },
        {
            "_id": 21622,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 365,
            "שם_רחוב": "אל-פראקן"
        },
        {
            "_id": 21623,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 438,
            "שם_רחוב": "אל-פרזדק"
        },
        {
            "_id": 21624,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אל-קאדסיה"
        },
        {
            "_id": 21625,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 405,
            "שם_רחוב": "אל-קואשף"
        },
        {
            "_id": 21626,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "אל-קובה"
        },
        {
            "_id": 21627,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 401,
            "שם_רחוב": "אל-קודס"
        },
        {
            "_id": 21628,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "אל-קוס"
        },
        {
            "_id": 21629,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 408,
            "שם_רחוב": "אל-קורונפל"
        },
        {
            "_id": 21630,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 386,
            "שם_רחוב": "אל-קלם"
        },
        {
            "_id": 21631,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אל-קנאטר"
        },
        {
            "_id": 21632,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "אל-קנדיל"
        },
        {
            "_id": 21633,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 373,
            "שם_רחוב": "אל-קסאיר"
        },
        {
            "_id": 21634,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 417,
            "שם_רחוב": "אל-קסארה"
        },
        {
            "_id": 21635,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 413,
            "שם_רחוב": "אל-קראג'את"
        },
        {
            "_id": 21636,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 443,
            "שם_רחוב": "אל-קרום"
        },
        {
            "_id": 21637,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "אל-קרם"
        },
        {
            "_id": 21638,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "אל-קרמה"
        },
        {
            "_id": 21639,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אל-ראביה"
        },
        {
            "_id": 21640,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אל-ראבעין"
        },
        {
            "_id": 21641,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 383,
            "שם_רחוב": "אל-ראוי"
        },
        {
            "_id": 21642,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אל-ראזי"
        },
        {
            "_id": 21643,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 375,
            "שם_רחוב": "אל-ראשדין"
        },
        {
            "_id": 21644,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "אל-רבאבה"
        },
        {
            "_id": 21645,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אל-רדואן"
        },
        {
            "_id": 21646,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 361,
            "שם_רחוב": "אל-רודה"
        },
        {
            "_id": 21647,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "אל-רום"
        },
        {
            "_id": 21648,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אל-רזק"
        },
        {
            "_id": 21649,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אל-רחיק"
        },
        {
            "_id": 21650,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אל-רחמאן"
        },
        {
            "_id": 21651,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אל-ריאד"
        },
        {
            "_id": 21652,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "אל-ריחאן"
        },
        {
            "_id": 21653,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אל-רים"
        },
        {
            "_id": 21654,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 444,
            "שם_רחוב": "אל-תקוה"
        },
        {
            "_id": 21655,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 327,
            "שם_רחוב": "אלארקם"
        },
        {
            "_id": 21656,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלביאתי"
        },
        {
            "_id": 21657,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אלבידאא"
        },
        {
            "_id": 21658,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6751,
            "שם_רחוב": "אלביק"
        },
        {
            "_id": 21659,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "אלהינד"
        },
        {
            "_id": 21660,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "אלהרובה"
        },
        {
            "_id": 21661,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6741,
            "שם_רחוב": "אלחג'ארה"
        },
        {
            "_id": 21662,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אלמוהלהל"
        },
        {
            "_id": 21663,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "אלפאראבי"
        },
        {
            "_id": 21664,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 358,
            "שם_רחוב": "אם אל-נעים"
        },
        {
            "_id": 21665,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "אמנה בנת אבי והב"
        },
        {
            "_id": 21666,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "אסמאא בנת אבי בקר"
        },
        {
            "_id": 21667,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "אפאק"
        },
        {
            "_id": 21668,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "ארביל"
        },
        {
            "_id": 21669,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 419,
            "שם_רחוב": "ארד-כנאנה"
        },
        {
            "_id": 21670,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "באב אל-ג'דידי"
        },
        {
            "_id": 21671,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "באב אל-זאויה"
        },
        {
            "_id": 21672,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6754,
            "שם_רחוב": "באב אלנסב"
        },
        {
            "_id": 21673,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "בדר"
        },
        {
            "_id": 21674,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "ביירות"
        },
        {
            "_id": 21675,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 439,
            "שם_רחוב": "ביסאן"
        },
        {
            "_id": 21676,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "ביר אל-ספרה"
        },
        {
            "_id": 21677,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "בירון"
        },
        {
            "_id": 21678,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "בלאל אבן רבאח"
        },
        {
            "_id": 21679,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "בלקיס"
        },
        {
            "_id": 21680,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "ברברה"
        },
        {
            "_id": 21681,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 328,
            "שם_רחוב": "ג'אבר אבן חיאן"
        },
        {
            "_id": 21682,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "ג'אמע אל-עין"
        },
        {
            "_id": 21683,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "ג'בראן ח'ליל ג'בראן"
        },
        {
            "_id": 21684,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 329,
            "שם_רחוב": "ג'רנאטה"
        },
        {
            "_id": 21685,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "דאר אל-סלאם"
        },
        {
            "_id": 21686,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 440,
            "שם_רחוב": "הושה"
        },
        {
            "_id": 21687,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "המסגד הישן"
        },
        {
            "_id": 21688,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "השכונה העליונה"
        },
        {
            "_id": 21689,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 403,
            "שם_רחוב": "ואדי אל דריג'את"
        },
        {
            "_id": 21690,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 279,
            "שם_רחוב": "ואדי אל-סחליאן"
        },
        {
            "_id": 21691,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "ואדי אלמאס"
        },
        {
            "_id": 21692,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "ואדי אלעין"
        },
        {
            "_id": 21693,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "ואדי המאם"
        },
        {
            "_id": 21694,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "ז'אהר אל-עומר"
        },
        {
            "_id": 21695,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6752,
            "שם_רחוב": "זיאדנה"
        },
        {
            "_id": 21696,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 278,
            "שם_רחוב": "זריאב"
        },
        {
            "_id": 21697,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "ח'אלד אבן אל-וליד"
        },
        {
            "_id": 21698,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "ח'דיג'ה בנת ח'וילד"
        },
        {
            "_id": 21699,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6753,
            "שם_רחוב": "ח'לאיל אלנוואר"
        },
        {
            "_id": 21700,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 320,
            "שם_רחוב": "ח'לת אל גזאל"
        },
        {
            "_id": 21701,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 418,
            "שם_רחוב": "ח'לת אל-טירה"
        },
        {
            "_id": 21702,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "ח'לת אל-נור"
        },
        {
            "_id": 21703,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 461,
            "שם_רחוב": "ח'לת אל-ראעי"
        },
        {
            "_id": 21704,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6773,
            "שם_רחוב": "ח'לת אלסבעה"
        },
        {
            "_id": 21705,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6774,
            "שם_רחוב": "ח'לת אלע'זאל"
        },
        {
            "_id": 21706,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "ח'לת סלמה"
        },
        {
            "_id": 21707,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6770,
            "שם_רחוב": "ח'לת שריף"
        },
        {
            "_id": 21708,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 398,
            "שם_רחוב": "חבאל אל-ביר"
        },
        {
            "_id": 21709,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "חוראן"
        },
        {
            "_id": 21710,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 433,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 21711,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 21712,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "חמזה"
        },
        {
            "_id": 21713,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 423,
            "שם_רחוב": "חסון"
        },
        {
            "_id": 21714,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "חפס'ה בנת עומר"
        },
        {
            "_id": 21715,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "טאהא חסין"
        },
        {
            "_id": 21716,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "טארק בן זיאד"
        },
        {
            "_id": 21717,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 416,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 21718,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 352,
            "שם_רחוב": "טייבה"
        },
        {
            "_id": 21719,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טמרה"
        },
        {
            "_id": 21720,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 345,
            "שם_רחוב": "טראבלוס"
        },
        {
            "_id": 21721,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 354,
            "שם_רחוב": "טשקנד"
        },
        {
            "_id": 21722,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "כוכב אל-הוואא"
        },
        {
            "_id": 21723,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 291,
            "שם_רחוב": "מאריה אל-קבטייה"
        },
        {
            "_id": 21724,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "מואתה"
        },
        {
            "_id": 21725,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "מטיראן"
        },
        {
            "_id": 21726,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "מי זיאדה"
        },
        {
            "_id": 21727,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "מלאבאס"
        },
        {
            "_id": 21728,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "מעאד בן ג'בל"
        },
        {
            "_id": 21729,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "מראח אל-גזלאן"
        },
        {
            "_id": 21730,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6760,
            "שם_רחוב": "מרכז העיר"
        },
        {
            "_id": 21731,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "נג'ראן"
        },
        {
            "_id": 21732,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 330,
            "שם_רחוב": "נהר אלאסכנדר"
        },
        {
            "_id": 21733,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 331,
            "שם_רחוב": "נהר אלעאסי"
        },
        {
            "_id": 21734,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "נהר הירדן"
        },
        {
            "_id": 21735,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 428,
            "שם_רחוב": "ניסאן"
        },
        {
            "_id": 21736,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "נסר אלדין"
        },
        {
            "_id": 21737,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "סבאא"
        },
        {
            "_id": 21738,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "סייד מקאווי"
        },
        {
            "_id": 21739,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "סלאח אל דין"
        },
        {
            "_id": 21740,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 410,
            "שם_רחוב": "סלמאן אל-פארסי"
        },
        {
            "_id": 21741,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 388,
            "שם_רחוב": "סמרקנד"
        },
        {
            "_id": 21742,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "סנעאא"
        },
        {
            "_id": 21743,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 359,
            "שם_רחוב": "ספאד"
        },
        {
            "_id": 21744,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "סקינה בנת אל-חסן"
        },
        {
            "_id": 21745,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "עומר אבן אלן אלחטאב"
        },
        {
            "_id": 21746,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 455,
            "שם_רחוב": "עומר אל-ח'יאם"
        },
        {
            "_id": 21747,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "עותמן אבן עפאן"
        },
        {
            "_id": 21748,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "עין אל-שמס"
        },
        {
            "_id": 21749,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "עירייה"
        },
        {
            "_id": 21750,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "עלי אבן אבי טאלב"
        },
        {
            "_id": 21751,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "עמאן"
        },
        {
            "_id": 21752,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "עמאר אבן יאסר"
        },
        {
            "_id": 21753,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 456,
            "שם_רחוב": "עסא אל-ראעי"
        },
        {
            "_id": 21754,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 334,
            "שם_רחוב": "עסקלאן"
        },
        {
            "_id": 21755,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 269,
            "שם_רחוב": "עקאז'"
        },
        {
            "_id": 21756,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 332,
            "שם_רחוב": "ערב אלספאא"
        },
        {
            "_id": 21757,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 321,
            "שם_רחוב": "פאטמה אל-זהראא"
        },
        {
            "_id": 21758,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "צור"
        },
        {
            "_id": 21759,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 351,
            "שם_רחוב": "צידון"
        },
        {
            "_id": 21760,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "ק'סר הישאם"
        },
        {
            "_id": 21761,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "קינדא"
        },
        {
            "_id": 21762,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 280,
            "שם_רחוב": "קלעת אל-פורסאן"
        },
        {
            "_id": 21763,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 463,
            "שם_רחוב": "קרטאג'"
        },
        {
            "_id": 21764,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "ראביעה אל-עדאוויה"
        },
        {
            "_id": 21765,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 292,
            "שם_רחוב": "ראס אל-אקרע"
        },
        {
            "_id": 21766,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "ראס אלעין"
        },
        {
            "_id": 21767,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "רבעאן סויד"
        },
        {
            "_id": 21768,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 293,
            "שם_רחוב": "שארע אעבלין"
        },
        {
            "_id": 21769,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "שכ אל חמרה"
        },
        {
            "_id": 21770,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "שכ אל-ג'בל"
        },
        {
            "_id": 21771,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6780,
            "שם_רחוב": "שכ אל-דריג'את"
        },
        {
            "_id": 21772,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6771,
            "שם_רחוב": "שכ אל-ורוד"
        },
        {
            "_id": 21773,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "שכ אל-מסלוקה"
        },
        {
            "_id": 21774,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6742,
            "שם_רחוב": "שכ ואדי המאם"
        },
        {
            "_id": 21775,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 6772,
            "שם_רחוב": "שכונה צפונית"
        },
        {
            "_id": 21776,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "תל אל-שוק"
        },
        {
            "_id": 21777,
            "סמל_ישוב": 8900,
            "שם_ישוב": "טמרה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "תשרין"
        },
        {
            "_id": 21778,
            "סמל_ישוב": 547,
            "שם_ישוב": "טמרה (יזרעאל) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טמרה (יזרעאל)"
        },
        {
            "_id": 21779,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אזוב"
        },
        {
            "_id": 21780,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האופק"
        },
        {
            "_id": 21781,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הגבעה"
        },
        {
            "_id": 21782,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 112,
            "שם_רחוב": "החורשה"
        },
        {
            "_id": 21783,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 21784,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 21785,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 110,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 21786,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טנא"
        },
        {
            "_id": 21787,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 21788,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 113,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 21789,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "עומרים"
        },
        {
            "_id": 21790,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 106,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 21791,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 105,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 21792,
            "סמל_ישוב": 3743,
            "שם_ישוב": "טנא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רכסים"
        },
        {
            "_id": 21793,
            "סמל_ישוב": 1214,
            "שם_ישוב": "טפחות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "טפחות"
        },
        {
            "_id": 21794,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 122,
            "שם_רחוב": "א-זוהור"
        },
        {
            "_id": 21795,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 176,
            "שם_רחוב": "א-זעתר"
        },
        {
            "_id": 21796,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 178,
            "שם_רחוב": "א-לוויזה"
        },
        {
            "_id": 21797,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 207,
            "שם_רחוב": "א-נג'מה"
        },
        {
            "_id": 21798,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 204,
            "שם_רחוב": "א-ס'ופיר"
        },
        {
            "_id": 21799,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 175,
            "שם_רחוב": "א-סהל"
        },
        {
            "_id": 21800,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 184,
            "שם_רחוב": "א-סוסנה"
        },
        {
            "_id": 21801,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 183,
            "שם_רחוב": "א-סופסאף"
        },
        {
            "_id": 21802,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 188,
            "שם_רחוב": "א-סחלב"
        },
        {
            "_id": 21803,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 190,
            "שם_רחוב": "א-רביע"
        },
        {
            "_id": 21804,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 195,
            "שם_רחוב": "א-שג'רה"
        },
        {
            "_id": 21805,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 192,
            "שם_רחוב": "א-שוקה"
        },
        {
            "_id": 21806,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אל-אפראח"
        },
        {
            "_id": 21807,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אל-אקחוואן"
        },
        {
            "_id": 21808,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אל-ארז"
        },
        {
            "_id": 21809,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אל-באשק"
        },
        {
            "_id": 21810,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אל-בלאבל"
        },
        {
            "_id": 21811,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 180,
            "שם_רחוב": "אל-ברקוק"
        },
        {
            "_id": 21812,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אל-ג'ורי"
        },
        {
            "_id": 21813,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אל-הודהוד"
        },
        {
            "_id": 21814,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אל-וורוד"
        },
        {
            "_id": 21815,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אל-ח'אבה"
        },
        {
            "_id": 21816,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אל-ח'לווה"
        },
        {
            "_id": 21817,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אל-ח'פאש"
        },
        {
            "_id": 21818,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אל-חאס'ל"
        },
        {
            "_id": 21819,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 194,
            "שם_רחוב": "אל-חארה"
        },
        {
            "_id": 21820,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אל-חג'ל"
        },
        {
            "_id": 21821,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 197,
            "שם_רחוב": "אל-חסון"
        },
        {
            "_id": 21822,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 199,
            "שם_רחוב": "אל-ימאמה"
        },
        {
            "_id": 21823,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אל-מארס"
        },
        {
            "_id": 21824,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 203,
            "שם_רחוב": "אל-מוח'תאר"
        },
        {
            "_id": 21825,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אל-מיזאן"
        },
        {
            "_id": 21826,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אל-מרג'"
        },
        {
            "_id": 21827,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אל-מרוש"
        },
        {
            "_id": 21828,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אל-ע'זאל"
        },
        {
            "_id": 21829,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אל-עיקאב"
        },
        {
            "_id": 21830,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אל-פול"
        },
        {
            "_id": 21831,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אל-קרנפול"
        },
        {
            "_id": 21832,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלאס"
        },
        {
            "_id": 21833,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלבבור"
        },
        {
            "_id": 21834,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלביאד'"
        },
        {
            "_id": 21835,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 21836,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלבלוט"
        },
        {
            "_id": 21837,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלג'וז"
        },
        {
            "_id": 21838,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלג'נאנין"
        },
        {
            "_id": 21839,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלדוואלי"
        },
        {
            "_id": 21840,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלדוואר"
        },
        {
            "_id": 21841,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלואדי"
        },
        {
            "_id": 21842,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלזייתון"
        },
        {
            "_id": 21843,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלזנבקה"
        },
        {
            "_id": 21844,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלזערור"
        },
        {
            "_id": 21845,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלזקאקאת"
        },
        {
            "_id": 21846,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אלח'ואח'את"
        },
        {
            "_id": 21847,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אלח'לה"
        },
        {
            "_id": 21848,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלח'לוה"
        },
        {
            "_id": 21849,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 21850,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "אלחארה אלווסטאניה"
        },
        {
            "_id": 21851,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "אלחארה אלע'רביה"
        },
        {
            "_id": 21852,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "אלחארה אלפוקה"
        },
        {
            "_id": 21853,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "אלחארה אלקדימה"
        },
        {
            "_id": 21854,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "אלחארה אלשמאלייה"
        },
        {
            "_id": 21855,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "אלחארה אלתחתה"
        },
        {
            "_id": 21856,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלחבאק'"
        },
        {
            "_id": 21857,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלחג'לה"
        },
        {
            "_id": 21858,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלחמדייאת"
        },
        {
            "_id": 21859,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלטווס"
        },
        {
            "_id": 21860,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אלטוק"
        },
        {
            "_id": 21861,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלטיון"
        },
        {
            "_id": 21862,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 21863,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלכנאר"
        },
        {
            "_id": 21864,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלכרום"
        },
        {
            "_id": 21865,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אלכרם"
        },
        {
            "_id": 21866,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אללוז"
        },
        {
            "_id": 21867,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אללוטוס"
        },
        {
            "_id": 21868,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אללילך"
        },
        {
            "_id": 21869,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלמידאן"
        },
        {
            "_id": 21870,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלמיראמיה"
        },
        {
            "_id": 21871,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אלמעסרה"
        },
        {
            "_id": 21872,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלנאטור"
        },
        {
            "_id": 21873,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלנוורס"
        },
        {
            "_id": 21874,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 21875,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 21876,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלנח'לה"
        },
        {
            "_id": 21877,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלנסר"
        },
        {
            "_id": 21878,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 21879,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלסומאק"
        },
        {
            "_id": 21880,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אלסידאויה"
        },
        {
            "_id": 21881,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אלסנדיאנה"
        },
        {
            "_id": 21882,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלסנונו"
        },
        {
            "_id": 21883,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלסקר"
        },
        {
            "_id": 21884,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלסרווה"
        },
        {
            "_id": 21885,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלענב"
        },
        {
            "_id": 21886,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלעקד"
        },
        {
            "_id": 21887,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלרומאן"
        },
        {
            "_id": 21888,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלשחרור"
        },
        {
            "_id": 21889,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלשק'אק'"
        },
        {
            "_id": 21890,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלת'נייה"
        },
        {
            "_id": 21891,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלתינה"
        },
        {
            "_id": 21892,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 210,
            "שם_רחוב": "באב א-דאר"
        },
        {
            "_id": 21893,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 149,
            "שם_רחוב": "ביר אלקלאע"
        },
        {
            "_id": 21894,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 146,
            "שם_רחוב": "ברכת אלבלד"
        },
        {
            "_id": 21895,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 162,
            "שם_רחוב": "ג'בר רהיג'"
        },
        {
            "_id": 21896,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ג'ת (בגליל)"
        },
        {
            "_id": 21897,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 150,
            "שם_רחוב": "דרב אלחאסל"
        },
        {
            "_id": 21898,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 142,
            "שם_רחוב": "דרב אלסאחל"
        },
        {
            "_id": 21899,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 191,
            "שם_רחוב": "דרומה"
        },
        {
            "_id": 21900,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "חארת אלברכה"
        },
        {
            "_id": 21901,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "חארת אלג'ונוד"
        },
        {
            "_id": 21902,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "חארת אלח'לאל"
        },
        {
            "_id": 21903,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "חארת אלכראג'"
        },
        {
            "_id": 21904,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "חארת אלמחפרה"
        },
        {
            "_id": 21905,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "חארת אלמיסה"
        },
        {
            "_id": 21906,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "חארת אלמרוש"
        },
        {
            "_id": 21907,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "חארת אלנמרה"
        },
        {
            "_id": 21908,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "חארת אלעריד'"
        },
        {
            "_id": 21909,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "חארת אלשכרה"
        },
        {
            "_id": 21910,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "חארת דארומה"
        },
        {
            "_id": 21911,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "חארת מלונה"
        },
        {
            "_id": 21912,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "חארת קבר פאעור"
        },
        {
            "_id": 21913,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "יאנוח"
        },
        {
            "_id": 21914,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יאנוח-ג'ת"
        },
        {
            "_id": 21915,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 214,
            "שם_רחוב": "כליל אג'בל"
        },
        {
            "_id": 21916,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 137,
            "שם_רחוב": "כסאר עמיס"
        },
        {
            "_id": 21917,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 144,
            "שם_רחוב": "כרם אלדאר"
        },
        {
            "_id": 21918,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 145,
            "שם_רחוב": "כרם אלקעקור"
        },
        {
            "_id": 21919,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 153,
            "שם_רחוב": "מחמד סעד"
        },
        {
            "_id": 21920,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 202,
            "שם_רחוב": "מלונה"
        },
        {
            "_id": 21921,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 196,
            "שם_רחוב": "מראח א-דינאר"
        },
        {
            "_id": 21922,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 212,
            "שם_רחוב": "מראח אל-עג'אל"
        },
        {
            "_id": 21923,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 185,
            "שם_רחוב": "נג'מת אל-מג'ד"
        },
        {
            "_id": 21924,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 193,
            "שם_רחוב": "סידי בו ערוס"
        },
        {
            "_id": 21925,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 147,
            "שם_רחוב": "סתי שמסה"
        },
        {
            "_id": 21926,
            "סמל_ישוב": 1295,
            "שם_ישוב": "יאנוח-ג'ת ",
            "סמל_רחוב": 186,
            "שם_רחוב": "עיבאד א-שמס"
        },
        {
            "_id": 21927,
            "סמל_ישוב": 1232,
            "שם_ישוב": "יבול ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יבול"
        },
        {
            "_id": 21928,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 21929,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 149,
            "שם_רחוב": "איזדרכת"
        },
        {
            "_id": 21930,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 178,
            "שם_רחוב": "אירוס הגליל"
        },
        {
            "_id": 21931,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 21932,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 21933,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "בית אבות יוקרה"
        },
        {
            "_id": 21934,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "בית גן"
        },
        {
            "_id": 21935,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 131,
            "שם_רחוב": "בית וגן"
        },
        {
            "_id": 21936,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "בן גוריון"
        },
        {
            "_id": 21937,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 176,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 21938,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 138,
            "שם_רחוב": "גרעין מסדה"
        },
        {
            "_id": 21939,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 171,
            "שם_רחוב": "דגן"
        },
        {
            "_id": 21940,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 141,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 21941,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 154,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 21942,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 175,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 21943,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 136,
            "שם_רחוב": "דרך הבקעה"
        },
        {
            "_id": 21944,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 140,
            "שם_רחוב": "דרך ההר"
        },
        {
            "_id": 21945,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "דרך המושבות"
        },
        {
            "_id": 21946,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 21947,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 145,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 21948,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 169,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 21949,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 165,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 21950,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 21951,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "המיסדים"
        },
        {
            "_id": 21952,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 164,
            "שם_רחוב": "המעיין"
        },
        {
            "_id": 21953,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 134,
            "שם_רחוב": "המרגנית"
        },
        {
            "_id": 21954,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הנוטרים"
        },
        {
            "_id": 21955,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 142,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 21956,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 143,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 21957,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 144,
            "שם_רחוב": "הרופאים"
        },
        {
            "_id": 21958,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 21959,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 156,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 21960,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 137,
            "שם_רחוב": "חיים לבקוב"
        },
        {
            "_id": 21961,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 160,
            "שם_רחוב": "חלוצי תימן"
        },
        {
            "_id": 21962,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 174,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 21963,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 127,
            "שם_רחוב": "חרציות"
        },
        {
            "_id": 21964,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 172,
            "שם_רחוב": "טייסת הגליל"
        },
        {
            "_id": 21965,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יבנאל"
        },
        {
            "_id": 21966,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 139,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 21967,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 123,
            "שם_רחוב": "ישראל שרפמן"
        },
        {
            "_id": 21968,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 146,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 21969,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 168,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 21970,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 152,
            "שם_רחוב": "מבצע מייקלברג"
        },
        {
            "_id": 21971,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 170,
            "שם_רחוב": "מגל"
        },
        {
            "_id": 21972,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 167,
            "שם_רחוב": "מורג"
        },
        {
            "_id": 21973,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "מעברת יבנאל"
        },
        {
            "_id": 21974,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 124,
            "שם_רחוב": "מעלה הגפן"
        },
        {
            "_id": 21975,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 148,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 21976,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "משמר השלושה"
        },
        {
            "_id": 21977,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 177,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 21978,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "נפתלי"
        },
        {
            "_id": 21979,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 126,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 21980,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 153,
            "שם_רחוב": "סיתוונית"
        },
        {
            "_id": 21981,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 21982,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 163,
            "שם_רחוב": "סמטת הזית"
        },
        {
            "_id": 21983,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 161,
            "שם_רחוב": "סמטת השקד"
        },
        {
            "_id": 21984,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 162,
            "שם_רחוב": "סמטת התמר"
        },
        {
            "_id": 21985,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 151,
            "שם_רחוב": "עומר"
        },
        {
            "_id": 21986,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 147,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 21987,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 135,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 21988,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 155,
            "שם_רחוב": "צמרת יבנאל"
        },
        {
            "_id": 21989,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 21990,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש עולים"
        },
        {
            "_id": 21991,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 21992,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 173,
            "שם_רחוב": "שיבולת"
        },
        {
            "_id": 21993,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 157,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 21994,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 166,
            "שם_רחוב": "שיקמה"
        },
        {
            "_id": 21995,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ התימנים"
        },
        {
            "_id": 21996,
            "סמל_ישוב": 46,
            "שם_ישוב": "יבנאל ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ ישראל שרפמן"
        },
        {
            "_id": 21997,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 367,
            "שם_רחוב": "אבוקדו"
        },
        {
            "_id": 21998,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אביבית"
        },
        {
            "_id": 21999,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 22000,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "אגמון"
        },
        {
            "_id": 22001,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אגס"
        },
        {
            "_id": 22002,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 345,
            "שם_רחוב": "אהובה עוזרי"
        },
        {
            "_id": 22003,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 22004,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אזוב"
        },
        {
            "_id": 22005,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "אזור המלאכה"
        },
        {
            "_id": 22006,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "אזור התעשיה"
        },
        {
            "_id": 22007,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אטד"
        },
        {
            "_id": 22008,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 22009,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 368,
            "שם_רחוב": "אירוס הארגמן"
        },
        {
            "_id": 22010,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 22011,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלעזר דוד"
        },
        {
            "_id": 22012,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "אפרסמון"
        },
        {
            "_id": 22013,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 22014,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 22015,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 369,
            "שם_רחוב": "אשכולית"
        },
        {
            "_id": 22016,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "אתרוג"
        },
        {
            "_id": 22017,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 22018,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 326,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 22019,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "גבורי ישראל"
        },
        {
            "_id": 22020,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "גבעה"
        },
        {
            "_id": 22021,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "גומא"
        },
        {
            "_id": 22022,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 327,
            "שם_רחוב": "גזית"
        },
        {
            "_id": 22023,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "גיבורי החיל"
        },
        {
            "_id": 22024,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "גלית"
        },
        {
            "_id": 22025,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 328,
            "שם_רחוב": "גרנית"
        },
        {
            "_id": 22026,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 341,
            "שם_רחוב": "דבורה עומר"
        },
        {
            "_id": 22027,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 22028,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 279,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 22029,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 387,
            "שם_רחוב": "דולפין"
        },
        {
            "_id": 22030,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 352,
            "שם_רחוב": "דותן"
        },
        {
            "_id": 22031,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 344,
            "שם_רחוב": "דליה רביקוביץ'"
        },
        {
            "_id": 22032,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "דם המכבים"
        },
        {
            "_id": 22033,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 351,
            "שם_רחוב": "דן"
        },
        {
            "_id": 22034,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 22035,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "דרך חג'ג' אהרון"
        },
        {
            "_id": 22036,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "דרך צבי גוב ארי"
        },
        {
            "_id": 22037,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "האומן"
        },
        {
            "_id": 22038,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "האורגים"
        },
        {
            "_id": 22039,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 22040,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "האיריס"
        },
        {
            "_id": 22041,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 22042,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 393,
            "שם_רחוב": "האלמוג"
        },
        {
            "_id": 22043,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "האמוראים"
        },
        {
            "_id": 22044,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 22045,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "הארבל"
        },
        {
            "_id": 22046,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 22047,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 22048,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "הבשן"
        },
        {
            "_id": 22049,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 22050,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 22051,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 22052,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 22053,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 22054,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "הגרי זכריה"
        },
        {
            "_id": 22055,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 380,
            "שם_רחוב": "הדוגית"
        },
        {
            "_id": 22056,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 379,
            "שם_רחוב": "הדייגים"
        },
        {
            "_id": 22057,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 22058,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 22059,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 22060,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 280,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 22061,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 22062,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 22063,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 22064,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 22065,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 22066,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 22067,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "הירמוך"
        },
        {
            "_id": 22068,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 353,
            "שם_רחוב": "הירקון"
        },
        {
            "_id": 22069,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 22070,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 22071,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 22072,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "המיסב"
        },
        {
            "_id": 22073,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 383,
            "שם_רחוב": "המלחים"
        },
        {
            "_id": 22074,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "המסילה"
        },
        {
            "_id": 22075,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 376,
            "שם_רחוב": "המפרש"
        },
        {
            "_id": 22076,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 377,
            "שם_רחוב": "המשוט"
        },
        {
            "_id": 22077,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 22078,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 22079,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 22080,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 385,
            "שם_רחוב": "הנחשול"
        },
        {
            "_id": 22081,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 388,
            "שם_רחוב": "הנמל"
        },
        {
            "_id": 22082,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 22083,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "הנשיאים"
        },
        {
            "_id": 22084,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "הנשר"
        },
        {
            "_id": 22085,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 22086,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הסופרים"
        },
        {
            "_id": 22087,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 386,
            "שם_רחוב": "הסיפון"
        },
        {
            "_id": 22088,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 395,
            "שם_רחוב": "הסירה"
        },
        {
            "_id": 22089,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 22090,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 278,
            "שם_רחוב": "העגור"
        },
        {
            "_id": 22091,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 392,
            "שם_רחוב": "העוגן"
        },
        {
            "_id": 22092,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "העמל"
        },
        {
            "_id": 22093,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 22094,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 22095,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "הפרת"
        },
        {
            "_id": 22096,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 22097,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 381,
            "שם_רחוב": "הצדף"
        },
        {
            "_id": 22098,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "הקישון"
        },
        {
            "_id": 22099,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "הר הזיתים"
        },
        {
            "_id": 22100,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 22101,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "הר נבו"
        },
        {
            "_id": 22102,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "הר סיני"
        },
        {
            "_id": 22103,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 22104,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "הרב אבוחצירא"
        },
        {
            "_id": 22105,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "הרב אברהם אשטמקר"
        },
        {
            "_id": 22106,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "הרב בוכריס"
        },
        {
            "_id": 22107,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 22108,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 340,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 22109,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 382,
            "שם_רחוב": "הרשת"
        },
        {
            "_id": 22110,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 22111,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 394,
            "שם_רחוב": "השונית"
        },
        {
            "_id": 22112,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "השופטים"
        },
        {
            "_id": 22113,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "השושנים"
        },
        {
            "_id": 22114,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 22115,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 378,
            "שם_רחוב": "השייטים"
        },
        {
            "_id": 22116,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "השפלה"
        },
        {
            "_id": 22117,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 22118,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 22119,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 22120,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 22121,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 22122,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 375,
            "שם_רחוב": "התורן"
        },
        {
            "_id": 22123,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "התנאים"
        },
        {
            "_id": 22124,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "ורד"
        },
        {
            "_id": 22125,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 22126,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "זהבית"
        },
        {
            "_id": 22127,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "חבלבל"
        },
        {
            "_id": 22128,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 365,
            "שם_רחוב": "חבצלת החוף"
        },
        {
            "_id": 22129,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "חטיבת גבעתי"
        },
        {
            "_id": 22130,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 281,
            "שם_רחוב": "חידקל"
        },
        {
            "_id": 22131,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 389,
            "שם_רחוב": "חירות"
        },
        {
            "_id": 22132,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "חלמונית"
        },
        {
            "_id": 22133,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 288,
            "שם_רחוב": "חמנית"
        },
        {
            "_id": 22134,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 22135,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 22136,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 22137,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 22138,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יבנה"
        },
        {
            "_id": 22139,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 329,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 22140,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 287,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 22141,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 22142,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "כוכבית"
        },
        {
            "_id": 22143,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "כחלית"
        },
        {
            "_id": 22144,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 384,
            "שם_רחוב": "לויתן"
        },
        {
            "_id": 22145,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "לוע הארי"
        },
        {
            "_id": 22146,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "לימון"
        },
        {
            "_id": 22147,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 330,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 22148,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מבצע דני"
        },
        {
            "_id": 22149,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "מבצע חירם"
        },
        {
            "_id": 22150,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מבצע יואב"
        },
        {
            "_id": 22151,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "מבצע יונתן"
        },
        {
            "_id": 22152,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מבצע עובדה"
        },
        {
            "_id": 22153,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "מבצע קדש"
        },
        {
            "_id": 22154,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "מבצעי תשח"
        },
        {
            "_id": 22155,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 22156,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 22157,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "מלכי יהודה"
        },
        {
            "_id": 22158,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "מלכי ישראל"
        },
        {
            "_id": 22159,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 22160,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "מרכז מסחרי"
        },
        {
            "_id": 22161,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "משעול בוהיניה"
        },
        {
            "_id": 22162,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "משעול האדר"
        },
        {
            "_id": 22163,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 298,
            "שם_רחוב": "משעול האלמוגן"
        },
        {
            "_id": 22164,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 292,
            "שם_רחוב": "משעול האפרסק"
        },
        {
            "_id": 22165,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 317,
            "שם_רחוב": "משעול הגויאבה"
        },
        {
            "_id": 22166,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "משעול ההדרים"
        },
        {
            "_id": 22167,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 295,
            "שם_רחוב": "משעול החבוש"
        },
        {
            "_id": 22168,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "משעול הכרמים"
        },
        {
            "_id": 22169,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 299,
            "שם_רחוב": "משעול הלבנה"
        },
        {
            "_id": 22170,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "משעול המנגו"
        },
        {
            "_id": 22171,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 293,
            "שם_רחוב": "משעול הפיקוס"
        },
        {
            "_id": 22172,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "משעול הפקאן"
        },
        {
            "_id": 22173,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "משעול הצבר"
        },
        {
            "_id": 22174,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 291,
            "שם_רחוב": "משעול הצפצפה"
        },
        {
            "_id": 22175,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "משעול הקוקוס"
        },
        {
            "_id": 22176,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 290,
            "שם_רחוב": "משעול התדהר"
        },
        {
            "_id": 22177,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "משעול התות"
        },
        {
            "_id": 22178,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 315,
            "שם_רחוב": "משעול התפוז"
        },
        {
            "_id": 22179,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 294,
            "שם_רחוב": "משעול התפוח"
        },
        {
            "_id": 22180,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 296,
            "שם_רחוב": "משעול כליל החורש"
        },
        {
            "_id": 22181,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "משעול סהרון"
        },
        {
            "_id": 22182,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "משעול סיגלון"
        },
        {
            "_id": 22183,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "משעול קטלב"
        },
        {
            "_id": 22184,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "משעול תאשור"
        },
        {
            "_id": 22185,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "נאות אשכול"
        },
        {
            "_id": 22186,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "נאות בגין"
        },
        {
            "_id": 22187,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "נאות בן גוריון"
        },
        {
            "_id": 22188,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "נאות רבין"
        },
        {
            "_id": 22189,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "נאות שזר"
        },
        {
            "_id": 22190,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "נהרונית"
        },
        {
            "_id": 22191,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "נופר"
        },
        {
            "_id": 22192,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 22193,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "נזמית"
        },
        {
            "_id": 22194,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 361,
            "שם_רחוב": "נחל חריף"
        },
        {
            "_id": 22195,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 362,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 22196,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 363,
            "שם_רחוב": "נחל פולג"
        },
        {
            "_id": 22197,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 364,
            "שם_רחוב": "נחל שניר"
        },
        {
            "_id": 22198,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 22199,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 286,
            "שם_רחוב": "ניצנית"
        },
        {
            "_id": 22200,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 343,
            "שם_רחוב": "נעמי שמר"
        },
        {
            "_id": 22201,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "נתיב הספורט"
        },
        {
            "_id": 22202,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 348,
            "שם_רחוב": "נתן אלתרמן"
        },
        {
            "_id": 22203,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 22204,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 284,
            "שם_רחוב": "סיגל"
        },
        {
            "_id": 22205,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 22206,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "סמטת האנפה"
        },
        {
            "_id": 22207,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 391,
            "שם_רחוב": "סנפיר"
        },
        {
            "_id": 22208,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 331,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 22209,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "סתוונית"
        },
        {
            "_id": 22210,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "עדעד"
        },
        {
            "_id": 22211,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 349,
            "שם_רחוב": "עוזי חיטמן"
        },
        {
            "_id": 22212,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "עולי הגרדום"
        },
        {
            "_id": 22213,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "עולש"
        },
        {
            "_id": 22214,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 22215,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 283,
            "שם_רחוב": "ענבל"
        },
        {
            "_id": 22216,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "ערמון"
        },
        {
            "_id": 22217,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "ערער"
        },
        {
            "_id": 22218,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 282,
            "שם_רחוב": "פארן"
        },
        {
            "_id": 22219,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "פטל"
        },
        {
            "_id": 22220,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 22221,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "פרג"
        },
        {
            "_id": 22222,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 269,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 22223,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 289,
            "שם_רחוב": "צורית"
        },
        {
            "_id": 22224,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "צלף"
        },
        {
            "_id": 22225,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "צפורן"
        },
        {
            "_id": 22226,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "קדושי קהיר"
        },
        {
            "_id": 22227,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 285,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 22228,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "קינמון"
        },
        {
            "_id": 22229,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 346,
            "שם_רחוב": "רבי יהודה הלוי"
        },
        {
            "_id": 22230,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 371,
            "שם_רחוב": "רוזמרין"
        },
        {
            "_id": 22231,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 22232,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רח ד"
        },
        {
            "_id": 22233,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רח ה"
        },
        {
            "_id": 22234,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רח יב"
        },
        {
            "_id": 22235,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח יד"
        },
        {
            "_id": 22236,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "רח יז"
        },
        {
            "_id": 22237,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 342,
            "שם_רחוב": "רחל המשוררת"
        },
        {
            "_id": 22238,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 370,
            "שם_רחוב": "ריחן"
        },
        {
            "_id": 22239,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 22240,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "רמות בן צבי"
        },
        {
            "_id": 22241,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "רמות ויצמן"
        },
        {
            "_id": 22242,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 22243,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "ש הפועלים"
        },
        {
            "_id": 22244,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "ש חולות"
        },
        {
            "_id": 22245,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "ש טרומיים"
        },
        {
            "_id": 22246,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש יבנית"
        },
        {
            "_id": 22247,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש נוה עובד"
        },
        {
            "_id": 22248,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 347,
            "שם_רחוב": "שי עגנון"
        },
        {
            "_id": 22249,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 22250,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 366,
            "שם_רחוב": "שד גן רווה"
        },
        {
            "_id": 22251,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "שד דואני"
        },
        {
            "_id": 22252,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "שד הסנהדרין"
        },
        {
            "_id": 22253,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 390,
            "שם_רחוב": "שד הציונות"
        },
        {
            "_id": 22254,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "שד ירושלים"
        },
        {
            "_id": 22255,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 332,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 22256,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "שזיף"
        },
        {
            "_id": 22257,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "שידלובסקי"
        },
        {
            "_id": 22258,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 22259,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ הארבע מאות"
        },
        {
            "_id": 22260,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "שכ נוה אילן"
        },
        {
            "_id": 22261,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "שכ צהל"
        },
        {
            "_id": 22262,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ רכבת"
        },
        {
            "_id": 22263,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "שנף"
        },
        {
            "_id": 22264,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "תורמוס"
        },
        {
            "_id": 22265,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "תלתן"
        },
        {
            "_id": 22266,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 22267,
            "סמל_ישוב": 2660,
            "שם_ישוב": "יבנה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "תנועות הנוער"
        },
        {
            "_id": 22268,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אולפן קיבוץ"
        },
        {
            "_id": 22269,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 22270,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 144,
            "שם_רחוב": "דרך אחווה"
        },
        {
            "_id": 22271,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 148,
            "שם_רחוב": "דרך הטווס"
        },
        {
            "_id": 22272,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 138,
            "שם_רחוב": "דרך הכרכום"
        },
        {
            "_id": 22273,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 123,
            "שם_רחוב": "דרך הכרם"
        },
        {
            "_id": 22274,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך הכרמל"
        },
        {
            "_id": 22275,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 143,
            "שם_רחוב": "דרך הלולים"
        },
        {
            "_id": 22276,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 129,
            "שם_רחוב": "דרך הלימון"
        },
        {
            "_id": 22277,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 146,
            "שם_רחוב": "דרך המשתלה"
        },
        {
            "_id": 22278,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 149,
            "שם_רחוב": "דרך הפרדס"
        },
        {
            "_id": 22279,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 152,
            "שם_רחוב": "דרך הקישון"
        },
        {
            "_id": 22280,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 114,
            "שם_רחוב": "דרך הראשונים"
        },
        {
            "_id": 22281,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 122,
            "שם_רחוב": "דרך הרכבת"
        },
        {
            "_id": 22282,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 150,
            "שם_רחוב": "דרך התעשייה"
        },
        {
            "_id": 22283,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 145,
            "שם_רחוב": "דרך חדר האוכל"
        },
        {
            "_id": 22284,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 147,
            "שם_רחוב": "דרך יד למגינים"
        },
        {
            "_id": 22285,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 112,
            "שם_רחוב": "דרך עמק זבולון"
        },
        {
            "_id": 22286,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 141,
            "שם_רחוב": "דרך שומר המסילה"
        },
        {
            "_id": 22287,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יגור"
        },
        {
            "_id": 22288,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 153,
            "שם_רחוב": "כביש ההר הירוק"
        },
        {
            "_id": 22289,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 113,
            "שם_רחוב": "כביש השלום"
        },
        {
            "_id": 22290,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 151,
            "שם_רחוב": "כביש כפר חסידים"
        },
        {
            "_id": 22291,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 118,
            "שם_רחוב": "מעלה הוואדי"
        },
        {
            "_id": 22292,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 120,
            "שם_רחוב": "מעלה המצוקים"
        },
        {
            "_id": 22293,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 116,
            "שם_רחוב": "סמ' ההר הקירח"
        },
        {
            "_id": 22294,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 121,
            "שם_רחוב": "סמ' השקדיה"
        },
        {
            "_id": 22295,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 127,
            "שם_רחוב": "שביל האגוז"
        },
        {
            "_id": 22296,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 124,
            "שם_רחוב": "שביל האיזדרכת"
        },
        {
            "_id": 22297,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 130,
            "שם_רחוב": "שביל האנפה"
        },
        {
            "_id": 22298,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 125,
            "שם_רחוב": "שביל האקליפטוס"
        },
        {
            "_id": 22299,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שביל הברושים"
        },
        {
            "_id": 22300,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 136,
            "שם_רחוב": "שביל הגבעות"
        },
        {
            "_id": 22301,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 131,
            "שם_רחוב": "שביל הדוכיפת"
        },
        {
            "_id": 22302,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שביל החיטה"
        },
        {
            "_id": 22303,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 142,
            "שם_רחוב": "שביל החלב"
        },
        {
            "_id": 22304,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 155,
            "שם_רחוב": "שביל היקינתון"
        },
        {
            "_id": 22305,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 140,
            "שם_רחוב": "שביל הכלנית"
        },
        {
            "_id": 22306,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 117,
            "שם_רחוב": "שביל הכתר"
        },
        {
            "_id": 22307,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 154,
            "שם_רחוב": "שביל הנוריות"
        },
        {
            "_id": 22308,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 133,
            "שם_רחוב": "שביל הנחליאלי"
        },
        {
            "_id": 22309,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 119,
            "שם_רחוב": "שביל הנרקיסים"
        },
        {
            "_id": 22310,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שביל הסילו"
        },
        {
            "_id": 22311,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 115,
            "שם_רחוב": "שביל הסכר"
        },
        {
            "_id": 22312,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שביל הסליק"
        },
        {
            "_id": 22313,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 128,
            "שם_רחוב": "שביל הסנדלריה"
        },
        {
            "_id": 22314,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 139,
            "שם_רחוב": "שביל הסתוונית"
        },
        {
            "_id": 22315,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 132,
            "שם_רחוב": "שביל העפרוני"
        },
        {
            "_id": 22316,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 135,
            "שם_רחוב": "שביל הפיקוסים"
        },
        {
            "_id": 22317,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 110,
            "שם_רחוב": "שביל הצאלון"
        },
        {
            "_id": 22318,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 126,
            "שם_רחוב": "שביל השיקמה"
        },
        {
            "_id": 22319,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 134,
            "שם_רחוב": "שביל השלדג"
        },
        {
            "_id": 22320,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שביל התאנה"
        },
        {
            "_id": 22321,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שביל התלתן"
        },
        {
            "_id": 22322,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שביל מגדל המים"
        },
        {
            "_id": 22323,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "שכ' גבעת המכוורת"
        },
        {
            "_id": 22324,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכונת בלוקונים"
        },
        {
            "_id": 22325,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכונת האיזדרכת"
        },
        {
            "_id": 22326,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכונת ההר"
        },
        {
            "_id": 22327,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכונת הכביש"
        },
        {
            "_id": 22328,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכונת הכרם"
        },
        {
            "_id": 22329,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכונת הכרמל"
        },
        {
            "_id": 22330,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכונת הלול"
        },
        {
            "_id": 22331,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכונת המשתלה"
        },
        {
            "_id": 22332,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכונת הנהח"
        },
        {
            "_id": 22333,
            "סמל_ישוב": 96,
            "שם_ישוב": "יגור ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכונת הסילו"
        },
        {
            "_id": 22334,
            "סמל_ישוב": 798,
            "שם_ישוב": "יגל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 22335,
            "סמל_ישוב": 798,
            "שם_ישוב": "יגל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 22336,
            "סמל_ישוב": 798,
            "שם_ישוב": "יגל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הלימון"
        },
        {
            "_id": 22337,
            "סמל_ישוב": 798,
            "שם_ישוב": "יגל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יגל"
        },
        {
            "_id": 22338,
            "סמל_ישוב": 798,
            "שם_ישוב": "יגל ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר חוני"
        },
        {
            "_id": 22339,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 22340,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אפיקים"
        },
        {
            "_id": 22341,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 143,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 22342,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 114,
            "שם_רחוב": "גדרון"
        },
        {
            "_id": 22343,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דיה"
        },
        {
            "_id": 22344,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 137,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 22345,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דרך האבות"
        },
        {
            "_id": 22346,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דרך יונתן"
        },
        {
            "_id": 22347,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 124,
            "שם_רחוב": "דרך מיכה"
        },
        {
            "_id": 22348,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 134,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 22349,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 22350,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הושע"
        },
        {
            "_id": 22351,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הנחלים"
        },
        {
            "_id": 22352,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הראל"
        },
        {
            "_id": 22353,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 133,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 22354,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 138,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 22355,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 139,
            "שם_רחוב": "השלו"
        },
        {
            "_id": 22356,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 132,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 22357,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ורדית"
        },
        {
            "_id": 22358,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 112,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 22359,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 110,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 22360,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 119,
            "שם_רחוב": "חנה למדן"
        },
        {
            "_id": 22361,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יד בנימין"
        },
        {
            "_id": 22362,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 109,
            "שם_רחוב": "יסעור"
        },
        {
            "_id": 22363,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 103,
            "שם_רחוב": "יעלה"
        },
        {
            "_id": 22364,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 108,
            "שם_רחוב": "כחל"
        },
        {
            "_id": 22365,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 140,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 22366,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 115,
            "שם_רחוב": "מגלן"
        },
        {
            "_id": 22367,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 142,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 22368,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 141,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 22369,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 121,
            "שם_רחוב": "מעלה עמוס"
        },
        {
            "_id": 22370,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 144,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 22371,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 22372,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 149,
            "שם_רחוב": "נשר"
        },
        {
            "_id": 22373,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 146,
            "שם_רחוב": "סמ גלבוע"
        },
        {
            "_id": 22374,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סמ חגי"
        },
        {
            "_id": 22375,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 125,
            "שם_רחוב": "סמ יואל"
        },
        {
            "_id": 22376,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 122,
            "שם_רחוב": "סמ יונה"
        },
        {
            "_id": 22377,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 147,
            "שם_רחוב": "סמ עמשא"
        },
        {
            "_id": 22378,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 148,
            "שם_רחוב": "סמ עצמון"
        },
        {
            "_id": 22379,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 131,
            "שם_רחוב": "סמ שקד"
        },
        {
            "_id": 22380,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 106,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 22381,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 145,
            "שם_רחוב": "סער"
        },
        {
            "_id": 22382,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 104,
            "שם_רחוב": "עגור"
        },
        {
            "_id": 22383,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 101,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 22384,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ נירים"
        },
        {
            "_id": 22385,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ נתיבה"
        },
        {
            "_id": 22386,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "שכ עידית"
        },
        {
            "_id": 22387,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ שדות"
        },
        {
            "_id": 22388,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ שקמים"
        },
        {
            "_id": 22389,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ תמנה"
        },
        {
            "_id": 22390,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ תמר"
        },
        {
            "_id": 22391,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 22392,
            "סמל_ישוב": 577,
            "שם_ישוב": "יד בנימין ",
            "סמל_רחוב": 128,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 22393,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אבן הפינה"
        },
        {
            "_id": 22394,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אור העולם"
        },
        {
            "_id": 22395,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרועה הטוב"
        },
        {
            "_id": 22396,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יד השמונה"
        },
        {
            "_id": 22397,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "מגדל עוז"
        },
        {
            "_id": 22398,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מים חיים"
        },
        {
            "_id": 22399,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נגה השחר"
        },
        {
            "_id": 22400,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "סלע איתן"
        },
        {
            "_id": 22401,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "עמנו אל"
        },
        {
            "_id": 22402,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "צור ישראל"
        },
        {
            "_id": 22403,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שמש ומגן"
        },
        {
            "_id": 22404,
            "סמל_ישוב": 1134,
            "שם_ישוב": "יד השמונה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שר שלום"
        },
        {
            "_id": 22405,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אגדת דשא"
        },
        {
            "_id": 22406,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "גנים"
        },
        {
            "_id": 22407,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "האבוקדו"
        },
        {
            "_id": 22408,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הבמבוק"
        },
        {
            "_id": 22409,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 22410,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "הכיכר האדומה"
        },
        {
            "_id": 22411,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "המטע"
        },
        {
            "_id": 22412,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 22413,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 22414,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 22415,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הנחלאים"
        },
        {
            "_id": 22416,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 22417,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הצריף האדום"
        },
        {
            "_id": 22418,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 22419,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "השקמים"
        },
        {
            "_id": 22420,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "התות"
        },
        {
            "_id": 22421,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "חומש"
        },
        {
            "_id": 22422,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "חורשת האקליפטוס"
        },
        {
            "_id": 22423,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "חנה סנש"
        },
        {
            "_id": 22424,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יד חנה"
        },
        {
            "_id": 22425,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "כדים"
        },
        {
            "_id": 22426,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "סמטת אף על פי כן"
        },
        {
            "_id": 22427,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סמטת הואדי"
        },
        {
            "_id": 22428,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "סמטת החרוב"
        },
        {
            "_id": 22429,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סמטת הפרחים"
        },
        {
            "_id": 22430,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "סמטת שביל החלב"
        },
        {
            "_id": 22431,
            "סמל_ישוב": 758,
            "שם_ישוב": "יד חנה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "סמטת תיבת נח"
        },
        {
            "_id": 22432,
            "סמל_ישוב": 358,
            "שם_ישוב": "יד מרדכי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יד מרדכי"
        },
        {
            "_id": 22433,
            "סמל_ישוב": 775,
            "שם_ישוב": "יד נתן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 22434,
            "סמל_ישוב": 775,
            "שם_ישוב": "יד נתן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הוורד"
        },
        {
            "_id": 22435,
            "סמל_ישוב": 775,
            "שם_ישוב": "יד נתן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 22436,
            "סמל_ישוב": 775,
            "שם_ישוב": "יד נתן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 22437,
            "סמל_ישוב": 775,
            "שם_ישוב": "יד נתן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יד נתן"
        },
        {
            "_id": 22438,
            "סמל_ישוב": 775,
            "שם_ישוב": "יד נתן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "משעול הגורן"
        },
        {
            "_id": 22439,
            "סמל_ישוב": 775,
            "שם_ישוב": "יד נתן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שדרת האקליפטוסים"
        },
        {
            "_id": 22440,
            "סמל_ישוב": 64,
            "שם_ישוב": "יד רמבם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יד רמבם"
        },
        {
            "_id": 22441,
            "סמל_ישוב": 1144,
            "שם_ישוב": "ידידה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ידידה"
        },
        {
            "_id": 22442,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 22443,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אברבנאל"
        },
        {
            "_id": 22444,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3112,
            "שם_רחוב": "אגוז נוה אפרים"
        },
        {
            "_id": 22445,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אור יצחק ישיבה"
        },
        {
            "_id": 22446,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "אזור תעשייה"
        },
        {
            "_id": 22447,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אלטלף אברהם"
        },
        {
            "_id": 22448,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 22449,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 192,
            "שם_רחוב": "אלעזר דוד"
        },
        {
            "_id": 22450,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלפרט"
        },
        {
            "_id": 22451,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אנילביץ"
        },
        {
            "_id": 22452,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 190,
            "שם_רחוב": "ארבעת המינים"
        },
        {
            "_id": 22453,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אריה בן אליעזר"
        },
        {
            "_id": 22454,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 121,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 22455,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אשכול"
        },
        {
            "_id": 22456,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אשכנזי"
        },
        {
            "_id": 22457,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 120,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 22458,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 177,
            "שם_רחוב": "ביאקובסקי"
        },
        {
            "_id": 22459,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 165,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 22460,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 194,
            "שם_רחוב": "בן גוריון דוד"
        },
        {
            "_id": 22461,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "בן צבי"
        },
        {
            "_id": 22462,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 164,
            "שם_רחוב": "בר סימנטוב יעקב"
        },
        {
            "_id": 22463,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 161,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 22464,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3104,
            "שם_רחוב": "ברקת נוה אפרים"
        },
        {
            "_id": 22465,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 185,
            "שם_רחוב": "גולדה מאיר"
        },
        {
            "_id": 22466,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 118,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 22467,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 212,
            "שם_רחוב": "גירון אברהם"
        },
        {
            "_id": 22468,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 214,
            "שם_רחוב": "דר שישקש חיים"
        },
        {
            "_id": 22469,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 186,
            "שם_רחוב": "דרך דיין משה"
        },
        {
            "_id": 22470,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 188,
            "שם_רחוב": "דרך החורש"
        },
        {
            "_id": 22471,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 126,
            "שם_רחוב": "דרך העצמאות"
        },
        {
            "_id": 22472,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3102,
            "שם_רחוב": "האלמוג נוה אפרים"
        },
        {
            "_id": 22473,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 195,
            "שם_רחוב": "האצל"
        },
        {
            "_id": 22474,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3103,
            "שם_רחוב": "האתרוג נוה אפרים"
        },
        {
            "_id": 22475,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 22476,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3106,
            "שם_רחוב": "הגפן נוה אפרים"
        },
        {
            "_id": 22477,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 234,
            "שם_רחוב": "הדגנים"
        },
        {
            "_id": 22478,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3107,
            "שם_רחוב": "הדר נוה אפרים"
        },
        {
            "_id": 22479,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 181,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 22480,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 171,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 22481,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 196,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 22482,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 236,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 22483,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 209,
            "שם_רחוב": "החרושת"
        },
        {
            "_id": 22484,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 198,
            "שם_רחוב": "היובל"
        },
        {
            "_id": 22485,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 22486,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 22487,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 213,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 22488,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 139,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 22489,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 246,
            "שם_רחוב": "המר זבולון"
        },
        {
            "_id": 22490,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 169,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 22491,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3108,
            "שם_רחוב": "הפנינים נוה אפרים"
        },
        {
            "_id": 22492,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 138,
            "שם_רחוב": "הקונגרס הציוני"
        },
        {
            "_id": 22493,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 215,
            "שם_רחוב": "הרב חיים סינואני"
        },
        {
            "_id": 22494,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 173,
            "שם_רחוב": "הרואה"
        },
        {
            "_id": 22495,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3109,
            "שם_רחוב": "הרימון נוה אפרים"
        },
        {
            "_id": 22496,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 200,
            "שם_רחוב": "הרצוג חיים"
        },
        {
            "_id": 22497,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 22498,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 184,
            "שם_רחוב": "השלום"
        },
        {
            "_id": 22499,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 159,
            "שם_רחוב": "השלושה"
        },
        {
            "_id": 22500,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 211,
            "שם_רחוב": "התעשייה"
        },
        {
            "_id": 22501,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 166,
            "שם_רחוב": "וינהויז זאב"
        },
        {
            "_id": 22502,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 22503,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 168,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 22504,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 154,
            "שם_רחוב": "חזנוביץ"
        },
        {
            "_id": 22505,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 241,
            "שם_רחוב": "חיטה"
        },
        {
            "_id": 22506,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "חסיד משה"
        },
        {
            "_id": 22507,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 135,
            "שם_רחוב": "חתוכה סעדיה"
        },
        {
            "_id": 22508,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 142,
            "שם_רחוב": "טורצ'ין ניסן"
        },
        {
            "_id": 22509,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 197,
            "שם_רחוב": "טלבי יוסף"
        },
        {
            "_id": 22510,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "טננבוים"
        },
        {
            "_id": 22511,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 162,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 22512,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יהוד-מונוסון"
        },
        {
            "_id": 22513,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 136,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 22514,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3111,
            "שם_רחוב": "יהלום נוה אפרים"
        },
        {
            "_id": 22515,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 128,
            "שם_רחוב": "יונה"
        },
        {
            "_id": 22516,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 170,
            "שם_רחוב": "יוספטל גיורא"
        },
        {
            "_id": 22517,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 140,
            "שם_רחוב": "יצחק שדה"
        },
        {
            "_id": 22518,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 183,
            "שם_רחוב": "כהן רם"
        },
        {
            "_id": 22519,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר יצחק שדה"
        },
        {
            "_id": 22520,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 155,
            "שם_רחוב": "כצנלסון"
        },
        {
            "_id": 22521,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "לוחמי הגיטאות"
        },
        {
            "_id": 22522,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 228,
            "שם_רחוב": "מבוא אילן"
        },
        {
            "_id": 22523,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 230,
            "שם_רחוב": "מבוא אלה"
        },
        {
            "_id": 22524,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 232,
            "שם_רחוב": "מבוא אתרוג"
        },
        {
            "_id": 22525,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 221,
            "שם_רחוב": "מבוא ברוניקה"
        },
        {
            "_id": 22526,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 199,
            "שם_רחוב": "מבוא ברוש"
        },
        {
            "_id": 22527,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 191,
            "שם_רחוב": "מבוא גומא"
        },
        {
            "_id": 22528,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 229,
            "שם_רחוב": "מבוא דולב"
        },
        {
            "_id": 22529,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 222,
            "שם_רחוב": "מבוא דליה"
        },
        {
            "_id": 22530,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 223,
            "שם_רחוב": "מבוא האירוס"
        },
        {
            "_id": 22531,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 231,
            "שם_רחוב": "מבוא הדס"
        },
        {
            "_id": 22532,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 233,
            "שם_רחוב": "מבוא הרדוף"
        },
        {
            "_id": 22533,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 244,
            "שם_רחוב": "מבוא ורבנה"
        },
        {
            "_id": 22534,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 224,
            "שם_רחוב": "מבוא זהבית"
        },
        {
            "_id": 22535,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 225,
            "שם_רחוב": "מבוא חבצלת"
        },
        {
            "_id": 22536,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 226,
            "שם_רחוב": "מבוא טופח"
        },
        {
            "_id": 22537,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 227,
            "שם_רחוב": "מבוא יסמין"
        },
        {
            "_id": 22538,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 220,
            "שם_רחוב": "מבוא לולב"
        },
        {
            "_id": 22539,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 237,
            "שם_רחוב": "מבוא שיבולת"
        },
        {
            "_id": 22540,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "מוהליבר"
        },
        {
            "_id": 22541,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 174,
            "שם_רחוב": "מוצקין"
        },
        {
            "_id": 22542,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 163,
            "שם_רחוב": "מלון אויה"
        },
        {
            "_id": 22543,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מלמד"
        },
        {
            "_id": 22544,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 131,
            "שם_רחוב": "מקלב אורי"
        },
        {
            "_id": 22545,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 143,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 22546,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 137,
            "שם_רחוב": "מרכוס דוד"
        },
        {
            "_id": 22547,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 205,
            "שם_רחוב": "נבון"
        },
        {
            "_id": 22548,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "נווה מונוסון"
        },
        {
            "_id": 22549,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 157,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 22550,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 22551,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 182,
            "שם_רחוב": "נתניהו יונתן"
        },
        {
            "_id": 22552,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סוקניק"
        },
        {
            "_id": 22553,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 152,
            "שם_רחוב": "סירקין"
        },
        {
            "_id": 22554,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3118,
            "שם_רחוב": "סמ אגוז נוה אפרים"
        },
        {
            "_id": 22555,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3119,
            "שם_רחוב": "סמ הפנינים נוהאפרים"
        },
        {
            "_id": 22556,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "סמ ויצמן"
        },
        {
            "_id": 22557,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 122,
            "שם_רחוב": "סנש"
        },
        {
            "_id": 22558,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3110,
            "שם_רחוב": "ספיר נוה אפרים"
        },
        {
            "_id": 22559,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3101,
            "שם_רחוב": "עינבר נוה אפרים"
        },
        {
            "_id": 22560,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 124,
            "שם_רחוב": "עץ האפרסק"
        },
        {
            "_id": 22561,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 238,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 22562,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 189,
            "שם_רחוב": "פרחי הבר"
        },
        {
            "_id": 22563,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 145,
            "שם_רחוב": "צבי ישי"
        },
        {
            "_id": 22564,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 180,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 22565,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 150,
            "שם_רחוב": "צוקרמן צביה ויצחק"
        },
        {
            "_id": 22566,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3117,
            "שם_רחוב": "צנובר נוה אפרים"
        },
        {
            "_id": 22567,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 132,
            "שם_רחוב": "קדושי מצרים"
        },
        {
            "_id": 22568,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "קפלן"
        },
        {
            "_id": 22569,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "קפלנסקי"
        },
        {
            "_id": 22570,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "קרית ביאליסטוק"
        },
        {
            "_id": 22571,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "קרית הסביונים"
        },
        {
            "_id": 22572,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 22573,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רמז"
        },
        {
            "_id": 22574,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש בית שמואל"
        },
        {
            "_id": 22575,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש נוה עובד"
        },
        {
            "_id": 22576,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש רסקו"
        },
        {
            "_id": 22577,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 158,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 22578,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 187,
            "שם_רחוב": "שד בגין"
        },
        {
            "_id": 22579,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3113,
            "שם_רחוב": "שוהם נוה אפרים"
        },
        {
            "_id": 22580,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 167,
            "שם_רחוב": "שזר"
        },
        {
            "_id": 22581,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 156,
            "שם_רחוב": "שיבת ציון"
        },
        {
            "_id": 22582,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 193,
            "שם_רחוב": "שיטרית בכור"
        },
        {
            "_id": 22583,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 240,
            "שם_רחוב": "שיפון"
        },
        {
            "_id": 22584,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3114,
            "שם_רחוב": "שנהב נוה אפרים"
        },
        {
            "_id": 22585,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 239,
            "שם_רחוב": "שעורה"
        },
        {
            "_id": 22586,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 178,
            "שם_רחוב": "שפירא משה חיים"
        },
        {
            "_id": 22587,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 151,
            "שם_רחוב": "שצ'ופק מרים"
        },
        {
            "_id": 22588,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3115,
            "שם_רחוב": "שקד נוה אפרים"
        },
        {
            "_id": 22589,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 175,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 22590,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 201,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 22591,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 243,
            "שם_רחוב": "תאשור"
        },
        {
            "_id": 22592,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 242,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 22593,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3116,
            "שם_רחוב": "תמר נוה אפרים"
        },
        {
            "_id": 22594,
            "סמל_ישוב": 9400,
            "שם_ישוב": "יהוד-מונוסון ",
            "סמל_רחוב": 3105,
            "שם_רחוב": "תרשיש נוה אפרים"
        },
        {
            "_id": 22595,
            "סמל_ישוב": 1158,
            "שם_ישוב": "יהל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 22596,
            "סמל_ישוב": 1158,
            "שם_ישוב": "יהל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יהל"
        },
        {
            "_id": 22597,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דן"
        },
        {
            "_id": 22598,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך המייסדים"
        },
        {
            "_id": 22599,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דרך המעכה"
        },
        {
            "_id": 22600,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "האנקור"
        },
        {
            "_id": 22601,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 22602,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 22603,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הדוכיפת"
        },
        {
            "_id": 22604,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ההרדוף"
        },
        {
            "_id": 22605,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 22606,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 120,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 22607,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "העפרוני"
        },
        {
            "_id": 22608,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 22609,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הצופית"
        },
        {
            "_id": 22610,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 22611,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 22612,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 22613,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "התורמוס"
        },
        {
            "_id": 22614,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 22615,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יובל"
        },
        {
            "_id": 22616,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "משעול היובלים"
        },
        {
            "_id": 22617,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 123,
            "שם_רחוב": "משעול הציפורים"
        },
        {
            "_id": 22618,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "עיון"
        },
        {
            "_id": 22619,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שיאון"
        },
        {
            "_id": 22620,
            "סמל_ישוב": 2009,
            "שם_ישוב": "יובל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 22621,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 126,
            "שם_רחוב": "חורשת טל"
        },
        {
            "_id": 22622,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יובלים"
        },
        {
            "_id": 22623,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "מעיינות"
        },
        {
            "_id": 22624,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מעין היצירה"
        },
        {
            "_id": 22625,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "נחל אורן"
        },
        {
            "_id": 22626,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נחל בצת"
        },
        {
            "_id": 22627,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נחל ברקן"
        },
        {
            "_id": 22628,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "נחל געתון"
        },
        {
            "_id": 22629,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נחל דן"
        },
        {
            "_id": 22630,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "נחל הארי"
        },
        {
            "_id": 22631,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "נחל הוד"
        },
        {
            "_id": 22632,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "נחל זויתן"
        },
        {
            "_id": 22633,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "נחלים"
        },
        {
            "_id": 22634,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "עין ורדית"
        },
        {
            "_id": 22635,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 130,
            "שם_רחוב": "רח 1"
        },
        {
            "_id": 22636,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 131,
            "שם_רחוב": "רח 2"
        },
        {
            "_id": 22637,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 129,
            "שם_רחוב": "שביל אדמונית"
        },
        {
            "_id": 22638,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "שביל איריס"
        },
        {
            "_id": 22639,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "שביל אלה"
        },
        {
            "_id": 22640,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "שביל אלון"
        },
        {
            "_id": 22641,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שביל ארז"
        },
        {
            "_id": 22642,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "שביל ברוש"
        },
        {
            "_id": 22643,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "שביל גביעונית"
        },
        {
            "_id": 22644,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "שביל גדילן"
        },
        {
            "_id": 22645,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שביל גומא"
        },
        {
            "_id": 22646,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "שביל גפן"
        },
        {
            "_id": 22647,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 128,
            "שם_רחוב": "שביל גפנית"
        },
        {
            "_id": 22648,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "שביל הדס"
        },
        {
            "_id": 22649,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "שביל הרדוף"
        },
        {
            "_id": 22650,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "שביל ורד"
        },
        {
            "_id": 22651,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 125,
            "שם_רחוב": "שביל זית"
        },
        {
            "_id": 22652,
            "סמל_ישוב": 1226,
            "שם_ישוב": "יובלים ",
            "סמל_רחוב": 127,
            "שם_רחוב": "שביל חרוב"
        },
        {
            "_id": 22653,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 112,
            "שם_רחוב": "בית העלמין"
        },
        {
            "_id": 22654,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 113,
            "שם_רחוב": "האומנים"
        },
        {
            "_id": 22655,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הבאר"
        },
        {
            "_id": 22656,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 22657,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הברושים"
        },
        {
            "_id": 22658,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הוורדים"
        },
        {
            "_id": 22659,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "המורה"
        },
        {
            "_id": 22660,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המעגל"
        },
        {
            "_id": 22661,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "המפעל"
        },
        {
            "_id": 22662,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "המצפה"
        },
        {
            "_id": 22663,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "המרכז"
        },
        {
            "_id": 22664,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "העלייה"
        },
        {
            "_id": 22665,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 22666,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הפרסה"
        },
        {
            "_id": 22667,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הר עצמון"
        },
        {
            "_id": 22668,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "התל"
        },
        {
            "_id": 22669,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יודפת"
        },
        {
            "_id": 22670,
            "סמל_ישוב": 1112,
            "שם_ישוב": "יודפת ",
            "סמל_רחוב": 114,
            "שם_רחוב": "משמרת מימין"
        },
        {
            "_id": 22671,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אביטל"
        },
        {
            "_id": 22672,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 22673,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אל על"
        },
        {
            "_id": 22674,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 22675,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 22676,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "בשנית"
        },
        {
            "_id": 22677,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "גמלא"
        },
        {
            "_id": 22678,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דליות"
        },
        {
            "_id": 22679,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דרך המייסדים"
        },
        {
            "_id": 22680,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 121,
            "שם_רחוב": "דרך הממשיכים"
        },
        {
            "_id": 22681,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 22682,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הדורבן"
        },
        {
            "_id": 22683,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 22684,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 22685,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יונתן"
        },
        {
            "_id": 22686,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 117,
            "שם_רחוב": "כחל"
        },
        {
            "_id": 22687,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 115,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 22688,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "כרמים"
        },
        {
            "_id": 22689,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 119,
            "שם_רחוב": "עיט"
        },
        {
            "_id": 22690,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 22691,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שפת הנחל"
        },
        {
            "_id": 22692,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 122,
            "שם_רחוב": "תל פארג'"
        },
        {
            "_id": 22693,
            "סמל_ישוב": 4007,
            "שם_ישוב": "יונתן ",
            "סמל_רחוב": 114,
            "שם_רחוב": "תלתן"
        },
        {
            "_id": 22694,
            "סמל_ישוב": 803,
            "שם_ישוב": "יושיביה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יושיביה"
        },
        {
            "_id": 22695,
            "סמל_ישוב": 452,
            "שם_ישוב": "יזרעאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יזרעאל"
        },
        {
            "_id": 22696,
            "סמל_ישוב": 409,
            "שם_ישוב": "יחיעם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יחיעם"
        },
        {
            "_id": 22697,
            "סמל_ישוב": 866,
            "שם_ישוב": "יטבתה ",
            "סמל_רחוב": 7000,
            "שם_רחוב": "חי בר"
        },
        {
            "_id": 22698,
            "סמל_ישוב": 866,
            "שם_ישוב": "יטבתה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יטבתה"
        },
        {
            "_id": 22699,
            "סמל_ישוב": 3607,
            "שם_ישוב": "ייטב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ייטב"
        },
        {
            "_id": 22700,
            "סמל_ישוב": 811,
            "שם_ישוב": "יכיני ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יכיני"
        },
        {
            "_id": 22701,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך החיטה"
        },
        {
            "_id": 22702,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך השעורה"
        },
        {
            "_id": 22703,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 22704,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 121,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 22705,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 22706,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הדפנה"
        },
        {
            "_id": 22707,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 22708,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 22709,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הלוטם"
        },
        {
            "_id": 22710,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 22711,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 119,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 22712,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 116,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 22713,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 120,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 22714,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ינוב"
        },
        {
            "_id": 22715,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מבוא הגפן"
        },
        {
            "_id": 22716,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מורד התאנה"
        },
        {
            "_id": 22717,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מעלה הרימון"
        },
        {
            "_id": 22718,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נתיב הזית"
        },
        {
            "_id": 22719,
            "סמל_ישוב": 753,
            "שם_ישוב": "ינוב ",
            "סמל_רחוב": 117,
            "שם_רחוב": "שד התמר"
        },
        {
            "_id": 22720,
            "סמל_ישוב": 2011,
            "שם_ישוב": "ינון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ינון"
        },
        {
            "_id": 22721,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך השמורה"
        },
        {
            "_id": 22722,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האגם"
        },
        {
            "_id": 22723,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 22724,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האלונים"
        },
        {
            "_id": 22725,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "האשכולית"
        },
        {
            "_id": 22726,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 22727,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 22728,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 22729,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 22730,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "החולה"
        },
        {
            "_id": 22731,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החלוצים"
        },
        {
            "_id": 22732,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 22733,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "המגדל"
        },
        {
            "_id": 22734,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "המזח"
        },
        {
            "_id": 22735,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 22736,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הפרדסים"
        },
        {
            "_id": 22737,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הרימונים"
        },
        {
            "_id": 22738,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "השזיף"
        },
        {
            "_id": 22739,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 22740,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 22741,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 22742,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 22743,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יסוד המעלה"
        },
        {
            "_id": 22744,
            "סמל_ישוב": 29,
            "שם_ישוב": "יסוד המעלה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "ש האגם"
        },
        {
            "_id": 22745,
            "סמל_ישוב": 440,
            "שם_ישוב": "יסודות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יסודות"
        },
        {
            "_id": 22746,
            "סמל_ישוב": 575,
            "שם_ישוב": "יסעור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יסעור"
        },
        {
            "_id": 22747,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלת המסטיק"
        },
        {
            "_id": 22748,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 106,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 22749,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יעד"
        },
        {
            "_id": 22750,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מלא כרימון"
        },
        {
            "_id": 22751,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 102,
            "שם_רחוב": "נוף יבור"
        },
        {
            "_id": 22752,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "נחל חילזון"
        },
        {
            "_id": 22753,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 110,
            "שם_רחוב": "קו הרכס"
        },
        {
            "_id": 22754,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ראש זית"
        },
        {
            "_id": 22755,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רוח ים"
        },
        {
            "_id": 22756,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רוח מזרחית"
        },
        {
            "_id": 22757,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שירת הנוער"
        },
        {
            "_id": 22758,
            "סמל_ישוב": 1138,
            "שם_ישוב": "יעד ",
            "סמל_רחוב": 107,
            "שם_רחוב": "תריסר ברושים"
        },
        {
            "_id": 22759,
            "סמל_ישוב": 1117,
            "שם_ישוב": "יעל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יעל"
        },
        {
            "_id": 22760,
            "סמל_ישוב": 1044,
            "שם_ישוב": "יעף ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 22761,
            "סמל_ישוב": 1044,
            "שם_ישוב": "יעף ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ינשוף"
        },
        {
            "_id": 22762,
            "סמל_ישוב": 1044,
            "שם_ישוב": "יעף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יעף"
        },
        {
            "_id": 22763,
            "סמל_ישוב": 1044,
            "שם_ישוב": "יעף ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 22764,
            "סמל_ישוב": 1044,
            "שם_ישוב": "יעף ",
            "סמל_רחוב": 101,
            "שם_רחוב": "עגור"
        },
        {
            "_id": 22765,
            "סמל_ישוב": 1044,
            "שם_ישוב": "יעף ",
            "סמל_רחוב": 103,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 22766,
            "סמל_ישוב": 1044,
            "שם_ישוב": "יעף ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 22767,
            "סמל_ישוב": 795,
            "שם_ישוב": "יערה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 22768,
            "סמל_ישוב": 795,
            "שם_ישוב": "יערה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 22769,
            "סמל_ישוב": 795,
            "שם_ישוב": "יערה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 22770,
            "סמל_ישוב": 795,
            "שם_ישוב": "יערה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 22771,
            "סמל_ישוב": 795,
            "שם_ישוב": "יערה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 22772,
            "סמל_ישוב": 795,
            "שם_ישוב": "יערה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 22773,
            "סמל_ישוב": 795,
            "שם_ישוב": "יערה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 22774,
            "סמל_ישוב": 795,
            "שם_ישוב": "יערה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 22775,
            "סמל_ישוב": 795,
            "שם_ישוב": "יערה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 22776,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "אלאלמאני"
        },
        {
            "_id": 22777,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 22778,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 22779,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "אלבלד"
        },
        {
            "_id": 22780,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "אלג'בל"
        },
        {
            "_id": 22781,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "אלחבאא'ל"
        },
        {
            "_id": 22782,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "אללקלום"
        },
        {
            "_id": 22783,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "אלמדארס"
        },
        {
            "_id": 22784,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "אלסירה"
        },
        {
            "_id": 22785,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 22786,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6741,
            "שם_רחוב": "אלעמאא'ר"
        },
        {
            "_id": 22787,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "אלשמאלי"
        },
        {
            "_id": 22788,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6752,
            "שם_רחוב": "אם אלעד'אם"
        },
        {
            "_id": 22789,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "ואדי אלח'ארג'י"
        },
        {
            "_id": 22790,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יפיע"
        },
        {
            "_id": 22791,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "מאר יעקוב"
        },
        {
            "_id": 22792,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6742,
            "שם_רחוב": "מנזל אלנור"
        },
        {
            "_id": 22793,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6750,
            "שם_רחוב": "מראח אלע'זלאן"
        },
        {
            "_id": 22794,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 6751,
            "שם_רחוב": "ראס אסמאעיל"
        },
        {
            "_id": 22795,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 300,
            "שם_רחוב": "רח 300"
        },
        {
            "_id": 22796,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 800,
            "שם_רחוב": "רח 800"
        },
        {
            "_id": 22797,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רח 110"
        },
        {
            "_id": 22798,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 210,
            "שם_רחוב": "רח 210"
        },
        {
            "_id": 22799,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 310,
            "שם_רחוב": "רח 310"
        },
        {
            "_id": 22800,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 410,
            "שם_רחוב": "רח 410"
        },
        {
            "_id": 22801,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 510,
            "שם_רחוב": "רח 510"
        },
        {
            "_id": 22802,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 610,
            "שם_רחוב": "רח 610"
        },
        {
            "_id": 22803,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 710,
            "שם_רחוב": "רח 710"
        },
        {
            "_id": 22804,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 810,
            "שם_רחוב": "רח 810"
        },
        {
            "_id": 22805,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רח 120"
        },
        {
            "_id": 22806,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 220,
            "שם_רחוב": "רח 220"
        },
        {
            "_id": 22807,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 320,
            "שם_רחוב": "רח 320"
        },
        {
            "_id": 22808,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 420,
            "שם_רחוב": "רח 420"
        },
        {
            "_id": 22809,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 520,
            "שם_רחוב": "רח 520"
        },
        {
            "_id": 22810,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 620,
            "שם_רחוב": "רח 620"
        },
        {
            "_id": 22811,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 130,
            "שם_רחוב": "רח 130"
        },
        {
            "_id": 22812,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 230,
            "שם_רחוב": "רח 230"
        },
        {
            "_id": 22813,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 330,
            "שם_רחוב": "רח 330"
        },
        {
            "_id": 22814,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 430,
            "שם_רחוב": "רח 430"
        },
        {
            "_id": 22815,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 140,
            "שם_רחוב": "רח 140"
        },
        {
            "_id": 22816,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 240,
            "שם_רחוב": "רח 240"
        },
        {
            "_id": 22817,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 250,
            "שם_רחוב": "רח 250"
        },
        {
            "_id": 22818,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 101,
            "שם_רחוב": "רח 101"
        },
        {
            "_id": 22819,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 201,
            "שם_רחוב": "רח 201"
        },
        {
            "_id": 22820,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 301,
            "שם_רחוב": "רח 301"
        },
        {
            "_id": 22821,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 401,
            "שם_רחוב": "רח 401"
        },
        {
            "_id": 22822,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 501,
            "שם_רחוב": "רח 501"
        },
        {
            "_id": 22823,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 601,
            "שם_רחוב": "רח 601"
        },
        {
            "_id": 22824,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 701,
            "שם_רחוב": "רח 701"
        },
        {
            "_id": 22825,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 801,
            "שם_רחוב": "רח 801"
        },
        {
            "_id": 22826,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רח 111"
        },
        {
            "_id": 22827,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 211,
            "שם_רחוב": "רח 211"
        },
        {
            "_id": 22828,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 311,
            "שם_רחוב": "רח 311"
        },
        {
            "_id": 22829,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 411,
            "שם_רחוב": "רח 411"
        },
        {
            "_id": 22830,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 511,
            "שם_רחוב": "רח 511"
        },
        {
            "_id": 22831,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 611,
            "שם_רחוב": "רח 611"
        },
        {
            "_id": 22832,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 711,
            "שם_רחוב": "רח 711"
        },
        {
            "_id": 22833,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 811,
            "שם_רחוב": "רח 811"
        },
        {
            "_id": 22834,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 121,
            "שם_רחוב": "רח 121"
        },
        {
            "_id": 22835,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 221,
            "שם_רחוב": "רח 221"
        },
        {
            "_id": 22836,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 321,
            "שם_רחוב": "רח 321"
        },
        {
            "_id": 22837,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 421,
            "שם_רחוב": "רח 421"
        },
        {
            "_id": 22838,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 521,
            "שם_רחוב": "רח 521"
        },
        {
            "_id": 22839,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 621,
            "שם_רחוב": "רח 621"
        },
        {
            "_id": 22840,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 131,
            "שם_רחוב": "רח 131"
        },
        {
            "_id": 22841,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 231,
            "שם_רחוב": "רח 231"
        },
        {
            "_id": 22842,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 331,
            "שם_רחוב": "רח 331"
        },
        {
            "_id": 22843,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 631,
            "שם_רחוב": "רח 631"
        },
        {
            "_id": 22844,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 141,
            "שם_רחוב": "רח 141"
        },
        {
            "_id": 22845,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 241,
            "שם_רחוב": "רח 241"
        },
        {
            "_id": 22846,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 251,
            "שם_רחוב": "רח 251"
        },
        {
            "_id": 22847,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רח 102"
        },
        {
            "_id": 22848,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 202,
            "שם_רחוב": "רח 202"
        },
        {
            "_id": 22849,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 302,
            "שם_רחוב": "רח 302"
        },
        {
            "_id": 22850,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 402,
            "שם_רחוב": "רח 402"
        },
        {
            "_id": 22851,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 502,
            "שם_רחוב": "רח 502"
        },
        {
            "_id": 22852,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 602,
            "שם_רחוב": "רח 602"
        },
        {
            "_id": 22853,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 702,
            "שם_רחוב": "רח 702"
        },
        {
            "_id": 22854,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 802,
            "שם_רחוב": "רח 802"
        },
        {
            "_id": 22855,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רח 112"
        },
        {
            "_id": 22856,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 212,
            "שם_רחוב": "רח 212"
        },
        {
            "_id": 22857,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 312,
            "שם_רחוב": "רח 312"
        },
        {
            "_id": 22858,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 412,
            "שם_רחוב": "רח 412"
        },
        {
            "_id": 22859,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 512,
            "שם_רחוב": "רח 512"
        },
        {
            "_id": 22860,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 612,
            "שם_רחוב": "רח 612"
        },
        {
            "_id": 22861,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 712,
            "שם_רחוב": "רח 712"
        },
        {
            "_id": 22862,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 812,
            "שם_רחוב": "רח 812"
        },
        {
            "_id": 22863,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רח 122"
        },
        {
            "_id": 22864,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 222,
            "שם_רחוב": "רח 222"
        },
        {
            "_id": 22865,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 322,
            "שם_רחוב": "רח 322"
        },
        {
            "_id": 22866,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 422,
            "שם_רחוב": "רח 422"
        },
        {
            "_id": 22867,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 522,
            "שם_רחוב": "רח 522"
        },
        {
            "_id": 22868,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 622,
            "שם_רחוב": "רח 622"
        },
        {
            "_id": 22869,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רח 132"
        },
        {
            "_id": 22870,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 232,
            "שם_רחוב": "רח 232"
        },
        {
            "_id": 22871,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 332,
            "שם_רחוב": "רח 332"
        },
        {
            "_id": 22872,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 632,
            "שם_רחוב": "רח 632"
        },
        {
            "_id": 22873,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רח 142"
        },
        {
            "_id": 22874,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 242,
            "שם_רחוב": "רח 242"
        },
        {
            "_id": 22875,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 252,
            "שם_רחוב": "רח 252"
        },
        {
            "_id": 22876,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רח 103"
        },
        {
            "_id": 22877,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 203,
            "שם_רחוב": "רח 203"
        },
        {
            "_id": 22878,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 303,
            "שם_רחוב": "רח 303"
        },
        {
            "_id": 22879,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 403,
            "שם_רחוב": "רח 403"
        },
        {
            "_id": 22880,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 503,
            "שם_רחוב": "רח 503"
        },
        {
            "_id": 22881,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 603,
            "שם_רחוב": "רח 603"
        },
        {
            "_id": 22882,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 703,
            "שם_רחוב": "רח 703"
        },
        {
            "_id": 22883,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 803,
            "שם_רחוב": "רח 803"
        },
        {
            "_id": 22884,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רח 113"
        },
        {
            "_id": 22885,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רח 213"
        },
        {
            "_id": 22886,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 313,
            "שם_רחוב": "רח 313"
        },
        {
            "_id": 22887,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 413,
            "שם_רחוב": "רח 413"
        },
        {
            "_id": 22888,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 513,
            "שם_רחוב": "רח 513"
        },
        {
            "_id": 22889,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 613,
            "שם_רחוב": "רח 613"
        },
        {
            "_id": 22890,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 713,
            "שם_רחוב": "רח 713"
        },
        {
            "_id": 22891,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 813,
            "שם_רחוב": "רח 813"
        },
        {
            "_id": 22892,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רח 123"
        },
        {
            "_id": 22893,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 223,
            "שם_רחוב": "רח 223"
        },
        {
            "_id": 22894,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 323,
            "שם_רחוב": "רח 323"
        },
        {
            "_id": 22895,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 423,
            "שם_רחוב": "רח 423"
        },
        {
            "_id": 22896,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 523,
            "שם_רחוב": "רח 523"
        },
        {
            "_id": 22897,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 623,
            "שם_רחוב": "רח 623"
        },
        {
            "_id": 22898,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 133,
            "שם_רחוב": "רח 133"
        },
        {
            "_id": 22899,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 233,
            "שם_רחוב": "רח 233"
        },
        {
            "_id": 22900,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 333,
            "שם_רחוב": "רח 333"
        },
        {
            "_id": 22901,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 633,
            "שם_רחוב": "רח 633"
        },
        {
            "_id": 22902,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 243,
            "שם_רחוב": "רח 243"
        },
        {
            "_id": 22903,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 253,
            "שם_רחוב": "רח 253"
        },
        {
            "_id": 22904,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח 104"
        },
        {
            "_id": 22905,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 204,
            "שם_רחוב": "רח 204"
        },
        {
            "_id": 22906,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 304,
            "שם_רחוב": "רח 304"
        },
        {
            "_id": 22907,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 404,
            "שם_רחוב": "רח 404"
        },
        {
            "_id": 22908,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 504,
            "שם_רחוב": "רח 504"
        },
        {
            "_id": 22909,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 604,
            "שם_רחוב": "רח 604"
        },
        {
            "_id": 22910,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 704,
            "שם_רחוב": "רח 704"
        },
        {
            "_id": 22911,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 804,
            "שם_רחוב": "רח 804"
        },
        {
            "_id": 22912,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רח 114"
        },
        {
            "_id": 22913,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 214,
            "שם_רחוב": "רח 214"
        },
        {
            "_id": 22914,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 314,
            "שם_רחוב": "רח 314"
        },
        {
            "_id": 22915,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 414,
            "שם_רחוב": "רח 414"
        },
        {
            "_id": 22916,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 514,
            "שם_רחוב": "רח 514"
        },
        {
            "_id": 22917,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 614,
            "שם_רחוב": "רח 614"
        },
        {
            "_id": 22918,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 814,
            "שם_רחוב": "רח 814"
        },
        {
            "_id": 22919,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רח 124"
        },
        {
            "_id": 22920,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 224,
            "שם_רחוב": "רח 224"
        },
        {
            "_id": 22921,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 324,
            "שם_רחוב": "רח 324"
        },
        {
            "_id": 22922,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 424,
            "שם_רחוב": "רח 424"
        },
        {
            "_id": 22923,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 524,
            "שם_רחוב": "רח 524"
        },
        {
            "_id": 22924,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 624,
            "שם_רחוב": "רח 624"
        },
        {
            "_id": 22925,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 134,
            "שם_רחוב": "רח 134"
        },
        {
            "_id": 22926,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 234,
            "שם_רחוב": "רח 234"
        },
        {
            "_id": 22927,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 334,
            "שם_רחוב": "רח 334"
        },
        {
            "_id": 22928,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 634,
            "שם_רחוב": "רח 634"
        },
        {
            "_id": 22929,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 254,
            "שם_רחוב": "רח 254"
        },
        {
            "_id": 22930,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רח 105"
        },
        {
            "_id": 22931,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 205,
            "שם_רחוב": "רח 205"
        },
        {
            "_id": 22932,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 305,
            "שם_רחוב": "רח 305"
        },
        {
            "_id": 22933,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 405,
            "שם_רחוב": "רח 405"
        },
        {
            "_id": 22934,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 505,
            "שם_רחוב": "רח 505"
        },
        {
            "_id": 22935,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 605,
            "שם_רחוב": "רח 605"
        },
        {
            "_id": 22936,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 705,
            "שם_רחוב": "רח 705"
        },
        {
            "_id": 22937,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 805,
            "שם_רחוב": "רח 805"
        },
        {
            "_id": 22938,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח 115"
        },
        {
            "_id": 22939,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 215,
            "שם_רחוב": "רח 215"
        },
        {
            "_id": 22940,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 315,
            "שם_רחוב": "רח 315"
        },
        {
            "_id": 22941,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 515,
            "שם_רחוב": "רח 515"
        },
        {
            "_id": 22942,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 615,
            "שם_רחוב": "רח 615"
        },
        {
            "_id": 22943,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 815,
            "שם_רחוב": "רח 815"
        },
        {
            "_id": 22944,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רח 125"
        },
        {
            "_id": 22945,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 225,
            "שם_רחוב": "רח 225"
        },
        {
            "_id": 22946,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 325,
            "שם_רחוב": "רח 325"
        },
        {
            "_id": 22947,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 425,
            "שם_רחוב": "רח 425"
        },
        {
            "_id": 22948,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 525,
            "שם_רחוב": "רח 525"
        },
        {
            "_id": 22949,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 625,
            "שם_רחוב": "רח 625"
        },
        {
            "_id": 22950,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 135,
            "שם_רחוב": "רח 135"
        },
        {
            "_id": 22951,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 235,
            "שם_רחוב": "רח 235"
        },
        {
            "_id": 22952,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 335,
            "שם_רחוב": "רח 335"
        },
        {
            "_id": 22953,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 635,
            "שם_רחוב": "רח 635"
        },
        {
            "_id": 22954,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 245,
            "שם_רחוב": "רח 245"
        },
        {
            "_id": 22955,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 255,
            "שם_רחוב": "רח 255"
        },
        {
            "_id": 22956,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רח 106"
        },
        {
            "_id": 22957,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 206,
            "שם_רחוב": "רח 206"
        },
        {
            "_id": 22958,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 306,
            "שם_רחוב": "רח 306"
        },
        {
            "_id": 22959,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 406,
            "שם_רחוב": "רח 406"
        },
        {
            "_id": 22960,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 506,
            "שם_רחוב": "רח 506"
        },
        {
            "_id": 22961,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 606,
            "שם_רחוב": "רח 606"
        },
        {
            "_id": 22962,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 706,
            "שם_רחוב": "רח 706"
        },
        {
            "_id": 22963,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 806,
            "שם_רחוב": "רח 806"
        },
        {
            "_id": 22964,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רח 116"
        },
        {
            "_id": 22965,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 216,
            "שם_רחוב": "רח 216"
        },
        {
            "_id": 22966,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 316,
            "שם_רחוב": "רח 316"
        },
        {
            "_id": 22967,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 416,
            "שם_רחוב": "רח 416"
        },
        {
            "_id": 22968,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 516,
            "שם_רחוב": "רח 516"
        },
        {
            "_id": 22969,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 616,
            "שם_רחוב": "רח 616"
        },
        {
            "_id": 22970,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 816,
            "שם_רחוב": "רח 816"
        },
        {
            "_id": 22971,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 126,
            "שם_רחוב": "רח 126"
        },
        {
            "_id": 22972,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 226,
            "שם_רחוב": "רח 226"
        },
        {
            "_id": 22973,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 326,
            "שם_רחוב": "רח 326"
        },
        {
            "_id": 22974,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 426,
            "שם_רחוב": "רח 426"
        },
        {
            "_id": 22975,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 626,
            "שם_רחוב": "רח 626"
        },
        {
            "_id": 22976,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 136,
            "שם_רחוב": "רח 136"
        },
        {
            "_id": 22977,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 236,
            "שם_רחוב": "רח 236"
        },
        {
            "_id": 22978,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 336,
            "שם_רחוב": "רח 336"
        },
        {
            "_id": 22979,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 246,
            "שם_רחוב": "רח 246"
        },
        {
            "_id": 22980,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 256,
            "שם_רחוב": "רח 256"
        },
        {
            "_id": 22981,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רח 107"
        },
        {
            "_id": 22982,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 207,
            "שם_רחוב": "רח 207"
        },
        {
            "_id": 22983,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 307,
            "שם_רחוב": "רח 307"
        },
        {
            "_id": 22984,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 507,
            "שם_רחוב": "רח 507"
        },
        {
            "_id": 22985,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 607,
            "שם_רחוב": "רח 607"
        },
        {
            "_id": 22986,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 707,
            "שם_רחוב": "רח 707"
        },
        {
            "_id": 22987,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 807,
            "שם_רחוב": "רח 807"
        },
        {
            "_id": 22988,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רח 117"
        },
        {
            "_id": 22989,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 217,
            "שם_רחוב": "רח 217"
        },
        {
            "_id": 22990,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 317,
            "שם_רחוב": "רח 317"
        },
        {
            "_id": 22991,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 417,
            "שם_רחוב": "רח 417"
        },
        {
            "_id": 22992,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 517,
            "שם_רחוב": "רח 517"
        },
        {
            "_id": 22993,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 617,
            "שם_רחוב": "רח 617"
        },
        {
            "_id": 22994,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 817,
            "שם_רחוב": "רח 817"
        },
        {
            "_id": 22995,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 127,
            "שם_רחוב": "רח 127"
        },
        {
            "_id": 22996,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 227,
            "שם_רחוב": "רח 227"
        },
        {
            "_id": 22997,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 327,
            "שם_רחוב": "רח 327"
        },
        {
            "_id": 22998,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 427,
            "שם_רחוב": "רח 427"
        },
        {
            "_id": 22999,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 627,
            "שם_רחוב": "רח 627"
        },
        {
            "_id": 23000,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 137,
            "שם_רחוב": "רח 137"
        },
        {
            "_id": 23001,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 237,
            "שם_רחוב": "רח 237"
        },
        {
            "_id": 23002,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 247,
            "שם_רחוב": "רח 247"
        },
        {
            "_id": 23003,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 257,
            "שם_רחוב": "רח 257"
        },
        {
            "_id": 23004,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רח 108"
        },
        {
            "_id": 23005,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 208,
            "שם_רחוב": "רח 208"
        },
        {
            "_id": 23006,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 308,
            "שם_רחוב": "רח 308"
        },
        {
            "_id": 23007,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 408,
            "שם_רחוב": "רח 408"
        },
        {
            "_id": 23008,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 508,
            "שם_רחוב": "רח 508"
        },
        {
            "_id": 23009,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 608,
            "שם_רחוב": "רח 608"
        },
        {
            "_id": 23010,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 708,
            "שם_רחוב": "רח 708"
        },
        {
            "_id": 23011,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 808,
            "שם_רחוב": "רח 808"
        },
        {
            "_id": 23012,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח 118"
        },
        {
            "_id": 23013,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 218,
            "שם_רחוב": "רח 218"
        },
        {
            "_id": 23014,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 318,
            "שם_רחוב": "רח 318"
        },
        {
            "_id": 23015,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 418,
            "שם_רחוב": "רח 418"
        },
        {
            "_id": 23016,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 518,
            "שם_רחוב": "רח 518"
        },
        {
            "_id": 23017,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 618,
            "שם_רחוב": "רח 618"
        },
        {
            "_id": 23018,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 818,
            "שם_רחוב": "רח 818"
        },
        {
            "_id": 23019,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 128,
            "שם_רחוב": "רח 128"
        },
        {
            "_id": 23020,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 228,
            "שם_רחוב": "רח 228"
        },
        {
            "_id": 23021,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 328,
            "שם_רחוב": "רח 328"
        },
        {
            "_id": 23022,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 428,
            "שם_רחוב": "רח 428"
        },
        {
            "_id": 23023,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 628,
            "שם_רחוב": "רח 628"
        },
        {
            "_id": 23024,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 138,
            "שם_רחוב": "רח 138"
        },
        {
            "_id": 23025,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 238,
            "שם_רחוב": "רח 238"
        },
        {
            "_id": 23026,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 248,
            "שם_רחוב": "רח 248"
        },
        {
            "_id": 23027,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 258,
            "שם_רחוב": "רח 258"
        },
        {
            "_id": 23028,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 109,
            "שם_רחוב": "רח 109"
        },
        {
            "_id": 23029,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רח 209"
        },
        {
            "_id": 23030,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 309,
            "שם_רחוב": "רח 309"
        },
        {
            "_id": 23031,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 409,
            "שם_רחוב": "רח 409"
        },
        {
            "_id": 23032,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 509,
            "שם_רחוב": "רח 509"
        },
        {
            "_id": 23033,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 609,
            "שם_רחוב": "רח 609"
        },
        {
            "_id": 23034,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 709,
            "שם_רחוב": "רח 709"
        },
        {
            "_id": 23035,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 809,
            "שם_רחוב": "רח 809"
        },
        {
            "_id": 23036,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח 119"
        },
        {
            "_id": 23037,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 219,
            "שם_רחוב": "רח 219"
        },
        {
            "_id": 23038,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 319,
            "שם_רחוב": "רח 319"
        },
        {
            "_id": 23039,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 419,
            "שם_רחוב": "רח 419"
        },
        {
            "_id": 23040,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 519,
            "שם_רחוב": "רח 519"
        },
        {
            "_id": 23041,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 619,
            "שם_רחוב": "רח 619"
        },
        {
            "_id": 23042,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 819,
            "שם_רחוב": "רח 819"
        },
        {
            "_id": 23043,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 129,
            "שם_רחוב": "רח 129"
        },
        {
            "_id": 23044,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 229,
            "שם_רחוב": "רח 229"
        },
        {
            "_id": 23045,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 329,
            "שם_רחוב": "רח 329"
        },
        {
            "_id": 23046,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 429,
            "שם_רחוב": "רח 429"
        },
        {
            "_id": 23047,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 629,
            "שם_רחוב": "רח 629"
        },
        {
            "_id": 23048,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 239,
            "שם_רחוב": "רח 239"
        },
        {
            "_id": 23049,
            "סמל_ישוב": 499,
            "שם_ישוב": "יפיע ",
            "סמל_רחוב": 249,
            "שם_רחוב": "רח 249"
        },
        {
            "_id": 23050,
            "סמל_ישוב": 3566,
            "שם_ישוב": "יפית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יפית"
        },
        {
            "_id": 23051,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 23052,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דרך החצר"
        },
        {
            "_id": 23053,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך המשק"
        },
        {
            "_id": 23054,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך ראשית ההתיישבות"
        },
        {
            "_id": 23055,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הגיתות"
        },
        {
            "_id": 23056,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 23057,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 23058,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הסלע"
        },
        {
            "_id": 23059,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 105,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 23060,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 23061,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הצריפים"
        },
        {
            "_id": 23062,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 23063,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 117,
            "שם_רחוב": "השדה"
        },
        {
            "_id": 23064,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 113,
            "שם_רחוב": "השרון-גבת"
        },
        {
            "_id": 23065,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יפעת"
        },
        {
            "_id": 23066,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 114,
            "שם_רחוב": "מעלה הגבעה"
        },
        {
            "_id": 23067,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שביל החלב"
        },
        {
            "_id": 23068,
            "סמל_ישוב": 134,
            "שם_ישוב": "יפעת ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שלושת הברושים"
        },
        {
            "_id": 23069,
            "סמל_ישוב": 453,
            "שם_ישוב": "יפתח ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 23070,
            "סמל_ישוב": 453,
            "שם_ישוב": "יפתח ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 23071,
            "סמל_ישוב": 3749,
            "שם_ישוב": "יצהר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יצהר"
        },
        {
            "_id": 23072,
            "סמל_ישוב": 759,
            "שם_ישוב": "יציץ ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 23073,
            "סמל_ישוב": 759,
            "שם_ישוב": "יציץ ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 23074,
            "סמל_ישוב": 759,
            "שם_ישוב": "יציץ ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 23075,
            "סמל_ישוב": 759,
            "שם_ישוב": "יציץ ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 23076,
            "סמל_ישוב": 759,
            "שם_ישוב": "יציץ ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 23077,
            "סמל_ישוב": 759,
            "שם_ישוב": "יציץ ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 23078,
            "סמל_ישוב": 759,
            "שם_ישוב": "יציץ ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יציץ"
        },
        {
            "_id": 23079,
            "סמל_ישוב": 759,
            "שם_ישוב": "יציץ ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סמ הדקל"
        },
        {
            "_id": 23080,
            "סמל_ישוב": 759,
            "שם_ישוב": "יציץ ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סמ התפוז"
        },
        {
            "_id": 23081,
            "סמל_ישוב": 417,
            "שם_ישוב": "יקום ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יקום"
        },
        {
            "_id": 23082,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "דליות"
        },
        {
            "_id": 23083,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דרך הצבי"
        },
        {
            "_id": 23084,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הגיתית"
        },
        {
            "_id": 23085,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 23086,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 23087,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 23088,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "החליל"
        },
        {
            "_id": 23089,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הכינור"
        },
        {
            "_id": 23090,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הנבל"
        },
        {
            "_id": 23091,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הפעמון"
        },
        {
            "_id": 23092,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 23093,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 23094,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 126,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 23095,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יקיר"
        },
        {
            "_id": 23096,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "מבוא חמה"
        },
        {
            "_id": 23097,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "מעלה השחר"
        },
        {
            "_id": 23098,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "משעול החורש"
        },
        {
            "_id": 23099,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "משעול התקוה"
        },
        {
            "_id": 23100,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "נהר הירדן"
        },
        {
            "_id": 23101,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נחל דולב"
        },
        {
            "_id": 23102,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "נחל דותן"
        },
        {
            "_id": 23103,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נחל הבשור"
        },
        {
            "_id": 23104,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נחל עוז"
        },
        {
            "_id": 23105,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "נחל צין"
        },
        {
            "_id": 23106,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נחל שילה"
        },
        {
            "_id": 23107,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "נחל תרצה"
        },
        {
            "_id": 23108,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 23109,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "שד ראשונים"
        },
        {
            "_id": 23110,
            "סמל_ישוב": 3647,
            "שם_ישוב": "יקיר ",
            "סמל_רחוב": 128,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 23111,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אגרא"
        },
        {
            "_id": 23112,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 102,
            "שם_רחוב": "גבעת המגדל"
        },
        {
            "_id": 23113,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך העמק"
        },
        {
            "_id": 23114,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 23115,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגיא"
        },
        {
            "_id": 23116,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 23117,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 108,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 23118,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יקנעם (מושבה)"
        },
        {
            "_id": 23119,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 109,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 23120,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 116,
            "שם_רחוב": "מעלה הבנים"
        },
        {
            "_id": 23121,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מעלה הגבעה"
        },
        {
            "_id": 23122,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מעלה הרקפות"
        },
        {
            "_id": 23123,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 112,
            "שם_רחוב": "נוף יזרעאל"
        },
        {
            "_id": 23124,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נחל קרת"
        },
        {
            "_id": 23125,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 114,
            "שם_רחוב": "נתיב הנוטרים"
        },
        {
            "_id": 23126,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 115,
            "שם_רחוב": "שביל החלב"
        },
        {
            "_id": 23127,
            "סמל_ישוב": 241,
            "שם_ישוב": "יקנעם (מושבה) ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שד הראשונים"
        },
        {
            "_id": 23128,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אביטל"
        },
        {
            "_id": 23129,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אורטל"
        },
        {
            "_id": 23130,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "אזור המלאכה"
        },
        {
            "_id": 23131,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אכזיב"
        },
        {
            "_id": 23132,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 250,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 23133,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 23134,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אפון"
        },
        {
            "_id": 23135,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אפק"
        },
        {
            "_id": 23136,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 201,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 23137,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 241,
            "שם_רחוב": "בנטל"
        },
        {
            "_id": 23138,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "גבעת האלונים"
        },
        {
            "_id": 23139,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "גבעת יערה"
        },
        {
            "_id": 23140,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "גבעת כלניות"
        },
        {
            "_id": 23141,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 219,
            "שם_רחוב": "גולן"
        },
        {
            "_id": 23142,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 226,
            "שם_רחוב": "גונן"
        },
        {
            "_id": 23143,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 252,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 23144,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "גני תעשיה"
        },
        {
            "_id": 23145,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 239,
            "שם_רחוב": "דליות"
        },
        {
            "_id": 23146,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 237,
            "שם_רחוב": "דן"
        },
        {
            "_id": 23147,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 180,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 23148,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 269,
            "שם_רחוב": "דרך הארגמן"
        },
        {
            "_id": 23149,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 268,
            "שם_רחוב": "דרך היער"
        },
        {
            "_id": 23150,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דרך העמק"
        },
        {
            "_id": 23151,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 249,
            "שם_רחוב": "האגם"
        },
        {
            "_id": 23152,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 164,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 23153,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 158,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 23154,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 227,
            "שם_רחוב": "האילה"
        },
        {
            "_id": 23155,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 174,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 23156,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 125,
            "שם_רחוב": "האיריסים"
        },
        {
            "_id": 23157,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 127,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 23158,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 154,
            "שם_רחוב": "האלונים"
        },
        {
            "_id": 23159,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 264,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 23160,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 176,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 23161,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 229,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 23162,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 260,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 23163,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 235,
            "שם_רחוב": "הבניאס"
        },
        {
            "_id": 23164,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 159,
            "שם_רחוב": "הברושים"
        },
        {
            "_id": 23165,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 251,
            "שם_רחוב": "הבשן"
        },
        {
            "_id": 23166,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 163,
            "שם_רחוב": "הגבורים"
        },
        {
            "_id": 23167,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 261,
            "שם_רחוב": "הגיא"
        },
        {
            "_id": 23168,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 253,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 23169,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 263,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 23170,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 171,
            "שם_רחוב": "הדודאים"
        },
        {
            "_id": 23171,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 170,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 23172,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 175,
            "שם_רחוב": "ההרדופים"
        },
        {
            "_id": 23173,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 23174,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 166,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 23175,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 262,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 23176,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 182,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 23177,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 236,
            "שם_רחוב": "החצבני"
        },
        {
            "_id": 23178,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 225,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 23179,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 192,
            "שם_רחוב": "היזמה"
        },
        {
            "_id": 23180,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 116,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 23181,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 134,
            "שם_רחוב": "היצירה"
        },
        {
            "_id": 23182,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 177,
            "שם_רחוב": "היקינטון"
        },
        {
            "_id": 23183,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הכלניות"
        },
        {
            "_id": 23184,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 23185,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 133,
            "שם_רחוב": "המדע"
        },
        {
            "_id": 23186,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 233,
            "שם_רחוב": "המיצר"
        },
        {
            "_id": 23187,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 131,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 23188,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 247,
            "שם_רחוב": "המפל"
        },
        {
            "_id": 23189,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 248,
            "שם_רחוב": "המצוק"
        },
        {
            "_id": 23190,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הנוריות"
        },
        {
            "_id": 23191,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 23192,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 172,
            "שם_רחוב": "הסחלבים"
        },
        {
            "_id": 23193,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 194,
            "שם_רחוב": "העוצמה"
        },
        {
            "_id": 23194,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 173,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 23195,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 135,
            "שם_רחוב": "העתיד"
        },
        {
            "_id": 23196,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 265,
            "שם_רחוב": "הפטל"
        },
        {
            "_id": 23197,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 254,
            "שם_רחוב": "הצופים"
        },
        {
            "_id": 23198,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 193,
            "שם_רחוב": "הצמיחה"
        },
        {
            "_id": 23199,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הצפורנים"
        },
        {
            "_id": 23200,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 191,
            "שם_רחוב": "הקידמה"
        },
        {
            "_id": 23201,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 151,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 23202,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 157,
            "שם_רחוב": "הרקפות"
        },
        {
            "_id": 23203,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 161,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 23204,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 152,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 23205,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 156,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 23206,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 153,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 23207,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 255,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 23208,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 160,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 23209,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 190,
            "שם_רחוב": "התנופה"
        },
        {
            "_id": 23210,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 130,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 23211,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "ורד העמקים"
        },
        {
            "_id": 23212,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 228,
            "שם_רחוב": "חורשת טל"
        },
        {
            "_id": 23213,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 121,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 23214,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 256,
            "שם_רחוב": "יודפת"
        },
        {
            "_id": 23215,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 224,
            "שם_רחוב": "יער אודם"
        },
        {
            "_id": 23216,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 162,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 23217,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יקנעם עילית"
        },
        {
            "_id": 23218,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 234,
            "שם_רחוב": "ירדן"
        },
        {
            "_id": 23219,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 232,
            "שם_רחוב": "ירמוך"
        },
        {
            "_id": 23220,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 165,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 23221,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 243,
            "שם_רחוב": "ליטאני"
        },
        {
            "_id": 23222,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 129,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 23223,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 178,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 23224,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 221,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 23225,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 266,
            "שם_רחוב": "מעיין"
        },
        {
            "_id": 23226,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 120,
            "שם_רחוב": "מעלה הבנים"
        },
        {
            "_id": 23227,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 126,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 23228,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 230,
            "שם_רחוב": "נופר"
        },
        {
            "_id": 23229,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 245,
            "שם_רחוב": "נחל הבשור"
        },
        {
            "_id": 23230,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 246,
            "שם_רחוב": "נחל השניים"
        },
        {
            "_id": 23231,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 244,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 23232,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 217,
            "שם_רחוב": "נחל שורק"
        },
        {
            "_id": 23233,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 181,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 23234,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 122,
            "שם_רחוב": "סיגלית"
        },
        {
            "_id": 23235,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 257,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 23236,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 124,
            "שם_רחוב": "סמטת המייסדים"
        },
        {
            "_id": 23237,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סמטת הראשונים"
        },
        {
            "_id": 23238,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 259,
            "שם_רחוב": "עמק השלום"
        },
        {
            "_id": 23239,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "פארק תעשיה יתמ"
        },
        {
            "_id": 23240,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 128,
            "שם_רחוב": "פעמונית"
        },
        {
            "_id": 23241,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 155,
            "שם_רחוב": "צאלים"
        },
        {
            "_id": 23242,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "קרית חינוך"
        },
        {
            "_id": 23243,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 179,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 23244,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "רמת יקנעם"
        },
        {
            "_id": 23245,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "ש וילות"
        },
        {
            "_id": 23246,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש חדש מערבי"
        },
        {
            "_id": 23247,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "ש נוף העמק"
        },
        {
            "_id": 23248,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 23249,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש קוטג'"
        },
        {
            "_id": 23250,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 210,
            "שם_רחוב": "שד יצחק רבין"
        },
        {
            "_id": 23251,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 267,
            "שם_רחוב": "שד נשיאי ישראל"
        },
        {
            "_id": 23252,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 258,
            "שם_רחוב": "שדרות היובל"
        },
        {
            "_id": 23253,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 218,
            "שם_רחוב": "שומרון"
        },
        {
            "_id": 23254,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 238,
            "שם_רחוב": "שיאון"
        },
        {
            "_id": 23255,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "שכ גבעת השיטה"
        },
        {
            "_id": 23256,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "שכ המושבה החדשה"
        },
        {
            "_id": 23257,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "שכ מרכז"
        },
        {
            "_id": 23258,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "שכ שער הגיא"
        },
        {
            "_id": 23259,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכון א דרום"
        },
        {
            "_id": 23260,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכון א צפון"
        },
        {
            "_id": 23261,
            "סמל_ישוב": 240,
            "שם_ישוב": "יקנעם עילית ",
            "סמל_רחוב": 231,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 23262,
            "סמל_ישוב": 623,
            "שם_ישוב": "יראון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 23263,
            "סמל_ישוב": 623,
            "שם_ישוב": "יראון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יראון"
        },
        {
            "_id": 23264,
            "סמל_ישוב": 2026,
            "שם_ישוב": "ירדנה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ירדנה"
        },
        {
            "_id": 23265,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אבו חצירה"
        },
        {
            "_id": 23266,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 23267,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 307,
            "שם_רחוב": "אבן-שפרוט"
        },
        {
            "_id": 23268,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 319,
            "שם_רחוב": "אברהם"
        },
        {
            "_id": 23269,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אגמית"
        },
        {
            "_id": 23270,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אהוד מנור"
        },
        {
            "_id": 23271,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "אזור תעשייה זעירה"
        },
        {
            "_id": 23272,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "אזור תעשייה מזרח"
        },
        {
            "_id": 23273,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "אזור תעשייה מערב"
        },
        {
            "_id": 23274,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 306,
            "שם_רחוב": "אל-חריזי"
        },
        {
            "_id": 23275,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אליהו הנביא"
        },
        {
            "_id": 23276,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלכחייל"
        },
        {
            "_id": 23277,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אמא שלום"
        },
        {
            "_id": 23278,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 23279,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 230,
            "שם_רחוב": "אסימוב"
        },
        {
            "_id": 23280,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אשר"
        },
        {
            "_id": 23281,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 202,
            "שם_רחוב": "בגין"
        },
        {
            "_id": 23282,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 138,
            "שם_רחוב": "בורנשטיין צבי"
        },
        {
            "_id": 23283,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 128,
            "שם_רחוב": "בית אשל"
        },
        {
            "_id": 23284,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 229,
            "שם_רחוב": "בן לברט"
        },
        {
            "_id": 23285,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 308,
            "שם_רחוב": "בן-סרוק"
        },
        {
            "_id": 23286,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 309,
            "שם_רחוב": "בן-עטר"
        },
        {
            "_id": 23287,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "בנה ביתך"
        },
        {
            "_id": 23288,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 216,
            "שם_רחוב": "בנימין"
        },
        {
            "_id": 23289,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 23290,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 310,
            "שם_רחוב": "בר-יוחאי"
        },
        {
            "_id": 23291,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 312,
            "שם_רחוב": "ברוריה"
        },
        {
            "_id": 23292,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 246,
            "שם_רחוב": "גב זרחן"
        },
        {
            "_id": 23293,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 249,
            "שם_רחוב": "גב חולית"
        },
        {
            "_id": 23294,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 239,
            "שם_רחוב": "גב ימין"
        },
        {
            "_id": 23295,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 243,
            "שם_רחוב": "גב עפרן"
        },
        {
            "_id": 23296,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 244,
            "שם_רחוב": "גב צרור"
        },
        {
            "_id": 23297,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 105,
            "שם_רחוב": "גבולות"
        },
        {
            "_id": 23298,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 221,
            "שם_רחוב": "גד"
        },
        {
            "_id": 23299,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 301,
            "שם_רחוב": "גולדברג לאה"
        },
        {
            "_id": 23300,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 315,
            "שם_רחוב": "גולדה מאיר"
        },
        {
            "_id": 23301,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 140,
            "שם_רחוב": "גולן"
        },
        {
            "_id": 23302,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 103,
            "שם_רחוב": "גוש עציון"
        },
        {
            "_id": 23303,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 147,
            "שם_רחוב": "גיבתון"
        },
        {
            "_id": 23304,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 144,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 23305,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 314,
            "שם_רחוב": "דונה גרציה"
        },
        {
            "_id": 23306,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 188,
            "שם_רחוב": "דינה"
        },
        {
            "_id": 23307,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 218,
            "שם_רחוב": "דן"
        },
        {
            "_id": 23308,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 160,
            "שם_רחוב": "האופק"
        },
        {
            "_id": 23309,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הבילויים"
        },
        {
            "_id": 23310,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 161,
            "שם_רחוב": "הברק"
        },
        {
            "_id": 23311,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 151,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 23312,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 136,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 23313,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 139,
            "שם_רחוב": "ההדסים"
        },
        {
            "_id": 23314,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 211,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 23315,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 121,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 23316,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 131,
            "שם_רחוב": "הי הכהן"
        },
        {
            "_id": 23317,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 162,
            "שם_רחוב": "הלבנה"
        },
        {
            "_id": 23318,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 157,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 23319,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 163,
            "שם_רחוב": "המרום"
        },
        {
            "_id": 23320,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 304,
            "שם_רחוב": "המשוררת"
        },
        {
            "_id": 23321,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 23322,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 316,
            "שם_רחוב": "הנרייטה סאלד"
        },
        {
            "_id": 23323,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 164,
            "שם_רחוב": "הסהר"
        },
        {
            "_id": 23324,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 23325,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 165,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 23326,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 166,
            "שם_רחוב": "הקשת"
        },
        {
            "_id": 23327,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 23328,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 23329,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הרמבם"
        },
        {
            "_id": 23330,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 23331,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 167,
            "שם_רחוב": "השחר"
        },
        {
            "_id": 23332,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 152,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 23333,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 300,
            "שם_רחוב": "ויקי שירן"
        },
        {
            "_id": 23334,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 224,
            "שם_רחוב": "זבולון"
        },
        {
            "_id": 23335,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 317,
            "שם_רחוב": "זוהרה אלפסייה"
        },
        {
            "_id": 23336,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 326,
            "שם_רחוב": "זלדה"
        },
        {
            "_id": 23337,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 119,
            "שם_רחוב": "חבקוק הנביא"
        },
        {
            "_id": 23338,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 129,
            "שם_רחוב": "חובבי ציון"
        },
        {
            "_id": 23339,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 125,
            "שם_רחוב": "חטיבת הנגב"
        },
        {
            "_id": 23340,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 305,
            "שם_רחוב": "חידא"
        },
        {
            "_id": 23341,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 235,
            "שם_רחוב": "חמה"
        },
        {
            "_id": 23342,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 126,
            "שם_רחוב": "חשמונאים"
        },
        {
            "_id": 23343,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 214,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 23344,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 330,
            "שם_רחוב": "יהודה עמיחי"
        },
        {
            "_id": 23345,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 215,
            "שם_רחוב": "יוסף"
        },
        {
            "_id": 23346,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 133,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 23347,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 323,
            "שם_רחוב": "יעקב"
        },
        {
            "_id": 23348,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 321,
            "שם_רחוב": "יצחק"
        },
        {
            "_id": 23349,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 207,
            "שם_רחוב": "יצחק בשביס זינגר"
        },
        {
            "_id": 23350,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 106,
            "שם_רחוב": "יצחק שדה"
        },
        {
            "_id": 23351,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ירוחם"
        },
        {
            "_id": 23352,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 223,
            "שם_רחוב": "יששכר"
        },
        {
            "_id": 23353,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 325,
            "שם_רחוב": "לאה"
        },
        {
            "_id": 23354,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 217,
            "שם_רחוב": "לוי"
        },
        {
            "_id": 23355,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 231,
            "שם_רחוב": "מאדים"
        },
        {
            "_id": 23356,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 175,
            "שם_רחוב": "מבוא נחל מטמון"
        },
        {
            "_id": 23357,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 209,
            "שם_רחוב": "מנדל"
        },
        {
            "_id": 23358,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 180,
            "שם_רחוב": "מעגל האירוס"
        },
        {
            "_id": 23359,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 153,
            "שם_רחוב": "מעגל האתרוג"
        },
        {
            "_id": 23360,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 154,
            "שם_רחוב": "מעגל הגפן"
        },
        {
            "_id": 23361,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 190,
            "שם_רחוב": "מעגל הזוגן"
        },
        {
            "_id": 23362,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 181,
            "שם_רחוב": "מעגל החלמונית"
        },
        {
            "_id": 23363,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 191,
            "שם_רחוב": "מעגל החצב"
        },
        {
            "_id": 23364,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 192,
            "שם_רחוב": "מעגל היפרוק"
        },
        {
            "_id": 23365,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 193,
            "שם_רחוב": "מעגל הכלנית"
        },
        {
            "_id": 23366,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 194,
            "שם_רחוב": "מעגל הנורית"
        },
        {
            "_id": 23367,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 195,
            "שם_רחוב": "מעגל הסתוונית"
        },
        {
            "_id": 23368,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 183,
            "שם_רחוב": "מעגל העירית"
        },
        {
            "_id": 23369,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 155,
            "שם_רחוב": "מעגל הערבה"
        },
        {
            "_id": 23370,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 196,
            "שם_רחוב": "מעגל הפרג"
        },
        {
            "_id": 23371,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 198,
            "שם_רחוב": "מעגל הצבעוני"
        },
        {
            "_id": 23372,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 184,
            "שם_רחוב": "מעגל הרותם"
        },
        {
            "_id": 23373,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 156,
            "שם_רחוב": "מעגל הרימון"
        },
        {
            "_id": 23374,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "נאות הדר"
        },
        {
            "_id": 23375,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 233,
            "שם_רחוב": "נגה"
        },
        {
            "_id": 23376,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "נווה נוף"
        },
        {
            "_id": 23377,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "נווה עמק"
        },
        {
            "_id": 23378,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "נוף מדבר"
        },
        {
            "_id": 23379,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 171,
            "שם_רחוב": "נחל אבנון"
        },
        {
            "_id": 23380,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 178,
            "שם_רחוב": "נחל דוד"
        },
        {
            "_id": 23381,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 158,
            "שם_רחוב": "נחל הבשור"
        },
        {
            "_id": 23382,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 172,
            "שם_רחוב": "נחל חתירה"
        },
        {
            "_id": 23383,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 176,
            "שם_רחוב": "נחל יעלים"
        },
        {
            "_id": 23384,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 186,
            "שם_רחוב": "נחל עין בוקק"
        },
        {
            "_id": 23385,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 179,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 23386,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 159,
            "שם_רחוב": "נחל צאלים"
        },
        {
            "_id": 23387,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 170,
            "שם_רחוב": "נחל צין"
        },
        {
            "_id": 23388,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 177,
            "שם_רחוב": "נחל שועלים"
        },
        {
            "_id": 23389,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 205,
            "שם_רחוב": "נלי זקס"
        },
        {
            "_id": 23390,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 302,
            "שם_רחוב": "נעמי שמר"
        },
        {
            "_id": 23391,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 219,
            "שם_רחוב": "נפתלי"
        },
        {
            "_id": 23392,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 318,
            "שם_רחוב": "סוליקה חגואל"
        },
        {
            "_id": 23393,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 124,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 23394,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 142,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 23395,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 174,
            "שם_רחוב": "סמ נחל חביון"
        },
        {
            "_id": 23396,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 173,
            "שם_רחוב": "סמ נחל ממשית"
        },
        {
            "_id": 23397,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 182,
            "שם_רחוב": "סמטת הנמר"
        },
        {
            "_id": 23398,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 197,
            "שם_רחוב": "סמטת הצבוע"
        },
        {
            "_id": 23399,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 199,
            "שם_רחוב": "סמטת הקרקל"
        },
        {
            "_id": 23400,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 200,
            "שם_רחוב": "סמטת השועל"
        },
        {
            "_id": 23401,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 185,
            "שם_רחוב": "סמטת התן"
        },
        {
            "_id": 23402,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 134,
            "שם_רחוב": "סנקר אשר"
        },
        {
            "_id": 23403,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 204,
            "שם_רחוב": "עגנון"
        },
        {
            "_id": 23404,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 116,
            "שם_רחוב": "עזרא הסופר"
        },
        {
            "_id": 23405,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 248,
            "שם_רחוב": "עין זיק"
        },
        {
            "_id": 23406,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 242,
            "שם_רחוב": "עין חווה"
        },
        {
            "_id": 23407,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 236,
            "שם_רחוב": "עין ירקעם"
        },
        {
            "_id": 23408,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 238,
            "שם_רחוב": "עין מור"
        },
        {
            "_id": 23409,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 212,
            "שם_רחוב": "עין סהרונים"
        },
        {
            "_id": 23410,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 240,
            "שם_רחוב": "עין עבדת"
        },
        {
            "_id": 23411,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 237,
            "שם_רחוב": "עין עקב"
        },
        {
            "_id": 23412,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 247,
            "שם_רחוב": "עין פרת"
        },
        {
            "_id": 23413,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 245,
            "שם_רחוב": "עין צפית"
        },
        {
            "_id": 23414,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 241,
            "שם_רחוב": "עין שביב"
        },
        {
            "_id": 23415,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 111,
            "שם_רחוב": "עמוס הנביא"
        },
        {
            "_id": 23416,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 143,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 23417,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 206,
            "שם_רחוב": "פנחס נבון"
        },
        {
            "_id": 23418,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 203,
            "שם_רחוב": "פרס"
        },
        {
            "_id": 23419,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 149,
            "שם_רחוב": "קנית"
        },
        {
            "_id": 23420,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "קרית אגיס"
        },
        {
            "_id": 23421,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 213,
            "שם_רחוב": "ראובן"
        },
        {
            "_id": 23422,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 327,
            "שם_רחוב": "רבי דוד בוזגלו"
        },
        {
            "_id": 23423,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רבי דוד מלול"
        },
        {
            "_id": 23424,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 137,
            "שם_רחוב": "רבי כדיר בוכריס"
        },
        {
            "_id": 23425,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 189,
            "שם_רחוב": "רבין"
        },
        {
            "_id": 23426,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 322,
            "שם_רחוב": "רבקה"
        },
        {
            "_id": 23427,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 234,
            "שם_רחוב": "רהב"
        },
        {
            "_id": 23428,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 208,
            "שם_רחוב": "רוזלין זוסמן יאלו"
        },
        {
            "_id": 23429,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 324,
            "שם_רחוב": "רחל"
        },
        {
            "_id": 23430,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 225,
            "שם_רחוב": "ריהל"
        },
        {
            "_id": 23431,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 150,
            "שם_רחוב": "ריטה לוי"
        },
        {
            "_id": 23432,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 168,
            "שם_רחוב": "רכסים"
        },
        {
            "_id": 23433,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 228,
            "שם_רחוב": "רסג"
        },
        {
            "_id": 23434,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 201,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 23435,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 226,
            "שם_רחוב": "רשבג"
        },
        {
            "_id": 23436,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 232,
            "שם_רחוב": "שבתאי"
        },
        {
            "_id": 23437,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 210,
            "שם_רחוב": "שד ורדימון"
        },
        {
            "_id": 23438,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 187,
            "שם_רחוב": "שולמית"
        },
        {
            "_id": 23439,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ אלי כהן"
        },
        {
            "_id": 23440,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ בן גוריון"
        },
        {
            "_id": 23441,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ יונתן"
        },
        {
            "_id": 23442,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ שקד"
        },
        {
            "_id": 23443,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 313,
            "שם_רחוב": "שלומציון"
        },
        {
            "_id": 23444,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 227,
            "שם_רחוב": "שמואל הנגיד"
        },
        {
            "_id": 23445,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 123,
            "שם_רחוב": "שמחוני אסף"
        },
        {
            "_id": 23446,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 220,
            "שם_רחוב": "שמעון"
        },
        {
            "_id": 23447,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 320,
            "שם_רחוב": "שרה"
        },
        {
            "_id": 23448,
            "סמל_ישוב": 831,
            "שם_ישוב": "ירוחם ",
            "סמל_רחוב": 146,
            "שם_רחוב": "תמירון"
        },
        {
            "_id": 23449,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5658,
            "שם_רחוב": "א דרג'י"
        },
        {
            "_id": 23450,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4213,
            "שם_רחוב": "א זוהור"
        },
        {
            "_id": 23451,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4168,
            "שם_רחוב": "א טברי"
        },
        {
            "_id": 23452,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5282,
            "שם_רחוב": "א טברי סמ2"
        },
        {
            "_id": 23453,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4138,
            "שם_רחוב": "א טור"
        },
        {
            "_id": 23454,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4382,
            "שם_רחוב": "א נהאדה"
        },
        {
            "_id": 23455,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5660,
            "שם_רחוב": "א נח'יל"
        },
        {
            "_id": 23456,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4380,
            "שם_רחוב": "א נתר"
        },
        {
            "_id": 23457,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5251,
            "שם_רחוב": "א נתר סמ2"
        },
        {
            "_id": 23458,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5255,
            "שם_רחוב": "א נתר סמ4"
        },
        {
            "_id": 23459,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5256,
            "שם_רחוב": "א נתר סמ8"
        },
        {
            "_id": 23460,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5257,
            "שם_רחוב": "א סאפא סמ11"
        },
        {
            "_id": 23461,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4605,
            "שם_רחוב": "א סיוטי"
        },
        {
            "_id": 23462,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4207,
            "שם_רחוב": "א סלייב"
        },
        {
            "_id": 23463,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4022,
            "שם_רחוב": "א סעדייה"
        },
        {
            "_id": 23464,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4073,
            "שם_רחוב": "א רוסול"
        },
        {
            "_id": 23465,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5586,
            "שם_רחוב": "א שאבי"
        },
        {
            "_id": 23466,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5105,
            "שם_רחוב": "א שאבי סמ1"
        },
        {
            "_id": 23467,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4053,
            "שם_רחוב": "א שארף"
        },
        {
            "_id": 23468,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5605,
            "שם_רחוב": "א שיפא סמ2"
        },
        {
            "_id": 23469,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4250,
            "שם_רחוב": "א שמאעה"
        },
        {
            "_id": 23470,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5541,
            "שם_רחוב": "א-זאויה אלהינדיה"
        },
        {
            "_id": 23471,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4176,
            "שם_רחוב": "אאל אל-בית"
        },
        {
            "_id": 23472,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5420,
            "שם_רחוב": "אאל אל-בית סמ10"
        },
        {
            "_id": 23473,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5397,
            "שם_רחוב": "אאל אל-בית סמ1"
        },
        {
            "_id": 23474,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5398,
            "שם_רחוב": "אאל אל-בית סמ2"
        },
        {
            "_id": 23475,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5399,
            "שם_רחוב": "אאל אל-בית סמ4"
        },
        {
            "_id": 23476,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5418,
            "שם_רחוב": "אאל אל-בית סמ6"
        },
        {
            "_id": 23477,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5419,
            "שם_רחוב": "אאל אל-בית סמ8"
        },
        {
            "_id": 23478,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5828,
            "שם_רחוב": "אאל טלב"
        },
        {
            "_id": 23479,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5829,
            "שם_רחוב": "אאל טלב סמ1"
        },
        {
            "_id": 23480,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1002,
            "שם_רחוב": "אבא אבן"
        },
        {
            "_id": 23481,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3002,
            "שם_רחוב": "אבא אחימאיר"
        },
        {
            "_id": 23482,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3058,
            "שם_רחוב": "אבא ברדיצב"
        },
        {
            "_id": 23483,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1328,
            "שם_רחוב": "אבא חלקיה"
        },
        {
            "_id": 23484,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3141,
            "שם_רחוב": "אבו אל-ליל"
        },
        {
            "_id": 23485,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4667,
            "שם_רחוב": "אבו אלעלא אל מערי"
        },
        {
            "_id": 23486,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5578,
            "שם_רחוב": "אבו אלקסם אשאבי"
        },
        {
            "_id": 23487,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4424,
            "שם_רחוב": "אבו בכר א סדיק"
        },
        {
            "_id": 23488,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5082,
            "שם_רחוב": "אבו בכר א סדיק סמ1"
        },
        {
            "_id": 23489,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4152,
            "שם_רחוב": "אבו גנאם"
        },
        {
            "_id": 23490,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4729,
            "שם_רחוב": "אבו ד'ר אלע'פארי"
        },
        {
            "_id": 23491,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5244,
            "שם_רחוב": "אבו ד'ר ע'פארי סמ2"
        },
        {
            "_id": 23492,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5246,
            "שם_רחוב": "אבו ד'ר ע'פארי סמ3"
        },
        {
            "_id": 23493,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5245,
            "שם_רחוב": "אבו ד'ר ע'פארי סמ5"
        },
        {
            "_id": 23494,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5421,
            "שם_רחוב": "אבו ד'ר ע'פארי סמ7"
        },
        {
            "_id": 23495,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5763,
            "שם_רחוב": "אבו דוויח"
        },
        {
            "_id": 23496,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5764,
            "שם_רחוב": "אבו דוויח סמ2"
        },
        {
            "_id": 23497,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5765,
            "שם_רחוב": "אבו דוויח סמ4"
        },
        {
            "_id": 23498,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5766,
            "שם_רחוב": "אבו דוויח סמ6"
        },
        {
            "_id": 23499,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5137,
            "שם_רחוב": "אבו הוריירה סמ2"
        },
        {
            "_id": 23500,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5138,
            "שם_רחוב": "אבו הוריירה סמ4"
        },
        {
            "_id": 23501,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3218,
            "שם_רחוב": "אבו זיד אל-הילאל"
        },
        {
            "_id": 23502,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4920,
            "שם_רחוב": "אבו חאמד"
        },
        {
            "_id": 23503,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5248,
            "שם_רחוב": "אבו חאמד אל-ע'זאלי"
        },
        {
            "_id": 23504,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4921,
            "שם_רחוב": "אבו חאמד סמ1"
        },
        {
            "_id": 23505,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4692,
            "שם_רחוב": "אבו מאדי"
        },
        {
            "_id": 23506,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5046,
            "שם_רחוב": "אבו מאדי סמ1"
        },
        {
            "_id": 23507,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5047,
            "שם_רחוב": "אבו מאדי סמ3"
        },
        {
            "_id": 23508,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5424,
            "שם_רחוב": "אבו ע'נים"
        },
        {
            "_id": 23509,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5430,
            "שם_רחוב": "אבו ע'נים סמ10"
        },
        {
            "_id": 23510,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5425,
            "שם_רחוב": "אבו ע'נים סמ1"
        },
        {
            "_id": 23511,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5426,
            "שם_רחוב": "אבו ע'נים סמ3"
        },
        {
            "_id": 23512,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5427,
            "שם_רחוב": "אבו ע'נים סמ5"
        },
        {
            "_id": 23513,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5428,
            "שם_רחוב": "אבו ע'נים סמ7"
        },
        {
            "_id": 23514,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5429,
            "שם_רחוב": "אבו ע'נים סמ8"
        },
        {
            "_id": 23515,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4195,
            "שם_רחוב": "אבו עוביידה"
        },
        {
            "_id": 23516,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5433,
            "שם_רחוב": "אבו עוביידה סמ10"
        },
        {
            "_id": 23517,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5437,
            "שם_רחוב": "אבו עוביידה סמ20"
        },
        {
            "_id": 23518,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5438,
            "שם_רחוב": "אבו עוביידה סמ21"
        },
        {
            "_id": 23519,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5431,
            "שם_רחוב": "אבו עוביידה סמ2"
        },
        {
            "_id": 23520,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5434,
            "שם_רחוב": "אבו עוביידה סמ12"
        },
        {
            "_id": 23521,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5439,
            "שם_רחוב": "אבו עוביידה סמ22"
        },
        {
            "_id": 23522,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5432,
            "שם_רחוב": "אבו עוביידה סמ4"
        },
        {
            "_id": 23523,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5440,
            "שם_רחוב": "אבו עוביידה סמ24"
        },
        {
            "_id": 23524,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5441,
            "שם_רחוב": "אבו עוביידה סמ26"
        },
        {
            "_id": 23525,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5435,
            "שם_רחוב": "אבו עוביידה סמ17"
        },
        {
            "_id": 23526,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5436,
            "שם_רחוב": "אבו עוביידה סמ19"
        },
        {
            "_id": 23527,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4620,
            "שם_רחוב": "אבו פירס אל חמדני"
        },
        {
            "_id": 23528,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5505,
            "שם_רחוב": "אבו פלאח"
        },
        {
            "_id": 23529,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3112,
            "שם_רחוב": "אבו צוואנה"
        },
        {
            "_id": 23530,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5701,
            "שם_רחוב": "אבו רביע"
        },
        {
            "_id": 23531,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4699,
            "שם_רחוב": "אבו רוזאמה"
        },
        {
            "_id": 23532,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4724,
            "שם_רחוב": "אבו ריאלה"
        },
        {
            "_id": 23533,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3229,
            "שם_רחוב": "אבו ריאלה סמ2"
        },
        {
            "_id": 23534,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5290,
            "שם_רחוב": "אבו ריאלה סמ4"
        },
        {
            "_id": 23535,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4662,
            "שם_רחוב": "אבו תמאם"
        },
        {
            "_id": 23536,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 686,
            "שם_רחוב": "אבולעפיה"
        },
        {
            "_id": 23537,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1762,
            "שם_רחוב": "אבטליון"
        },
        {
            "_id": 23538,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1137,
            "שם_רחוב": "אבי יונה מיכאל"
        },
        {
            "_id": 23539,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4586,
            "שם_רחוב": "אביגד נחמן"
        },
        {
            "_id": 23540,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 422,
            "שם_רחוב": "אביגדורי"
        },
        {
            "_id": 23541,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1890,
            "שם_רחוב": "אביגור שאול"
        },
        {
            "_id": 23542,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1725,
            "שם_רחוב": "אביגיל"
        },
        {
            "_id": 23543,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 463,
            "שם_רחוב": "אבידע"
        },
        {
            "_id": 23544,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2020,
            "שם_רחוב": "אבידר יוסף"
        },
        {
            "_id": 23545,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1093,
            "שם_רחוב": "אביזוהר"
        },
        {
            "_id": 23546,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1796,
            "שם_רחוב": "אביטל"
        },
        {
            "_id": 23547,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אבינדב"
        },
        {
            "_id": 23548,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 374,
            "שם_רחוב": "אבינועם ילין"
        },
        {
            "_id": 23549,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1403,
            "שם_רחוב": "אביעד"
        },
        {
            "_id": 23550,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4474,
            "שם_רחוב": "אביעזר"
        },
        {
            "_id": 23551,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3015,
            "שם_רחוב": "אביר יעקב"
        },
        {
            "_id": 23552,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1642,
            "שם_רחוב": "אבישי"
        },
        {
            "_id": 23553,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 735,
            "שם_רחוב": "אבישר"
        },
        {
            "_id": 23554,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אביתר הכהן"
        },
        {
            "_id": 23555,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 464,
            "שם_רחוב": "אבל פן"
        },
        {
            "_id": 23556,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1754,
            "שם_רחוב": "אבלס ולטר"
        },
        {
            "_id": 23557,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5396,
            "שם_רחוב": "אבן אבו טאלב סמ1"
        },
        {
            "_id": 23558,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4419,
            "שם_רחוב": "אבן בטוטה"
        },
        {
            "_id": 23559,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4199,
            "שם_רחוב": "אבן ג'ובייר"
        },
        {
            "_id": 23560,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4076,
            "שם_רחוב": "אבן ג'ראח"
        },
        {
            "_id": 23561,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 810,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 23562,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 389,
            "שם_רחוב": "אבן האזל"
        },
        {
            "_id": 23563,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4165,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 23564,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3294,
            "שם_רחוב": "אבן חארת'ה"
        },
        {
            "_id": 23565,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3302,
            "שם_רחוב": "אבן חארת'ה סמ10"
        },
        {
            "_id": 23566,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3295,
            "שם_רחוב": "אבן חארת'ה סמ1"
        },
        {
            "_id": 23567,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3296,
            "שם_רחוב": "אבן חארת'ה סמ2"
        },
        {
            "_id": 23568,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3303,
            "שם_רחוב": "אבן חארת'ה סמ12"
        },
        {
            "_id": 23569,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3297,
            "שם_רחוב": "אבן חארת'ה סמ3"
        },
        {
            "_id": 23570,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3298,
            "שם_רחוב": "אבן חארת'ה סמ5"
        },
        {
            "_id": 23571,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3299,
            "שם_רחוב": "אבן חארת'ה סמ6"
        },
        {
            "_id": 23572,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3300,
            "שם_רחוב": "אבן חארת'ה סמ7"
        },
        {
            "_id": 23573,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3301,
            "שם_רחוב": "אבן חארת'ה סמ8"
        },
        {
            "_id": 23574,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4422,
            "שם_רחוב": "אבן חווקל"
        },
        {
            "_id": 23575,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4354,
            "שם_רחוב": "אבן חמאם"
        },
        {
            "_id": 23576,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4099,
            "שם_רחוב": "אבן טולון"
        },
        {
            "_id": 23577,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אבן יהושע"
        },
        {
            "_id": 23578,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 430,
            "שם_רחוב": "אבן ישראל"
        },
        {
            "_id": 23579,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4745,
            "שם_רחוב": "אבן מסעוד"
        },
        {
            "_id": 23580,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4619,
            "שם_רחוב": "אבן מרואן"
        },
        {
            "_id": 23581,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4166,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 23582,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 652,
            "שם_רחוב": "אבן ספיר"
        },
        {
            "_id": 23583,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 806,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 23584,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4634,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 23585,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5123,
            "שם_רחוב": "אבן רושד סמ1"
        },
        {
            "_id": 23586,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4478,
            "שם_רחוב": "אבן שמואל"
        },
        {
            "_id": 23587,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 726,
            "שם_רחוב": "אבן שפרוט"
        },
        {
            "_id": 23588,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1672,
            "שם_רחוב": "אבנר בן נר"
        },
        {
            "_id": 23589,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1821,
            "שם_רחוב": "אבנר גרשון"
        },
        {
            "_id": 23590,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4746,
            "שם_רחוב": "אבראהים בן אדהם"
        },
        {
            "_id": 23591,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 803,
            "שם_רחוב": "אברבנאל"
        },
        {
            "_id": 23592,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 539,
            "שם_רחוב": "אברהם ורדיגר"
        },
        {
            "_id": 23593,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1639,
            "שם_רחוב": "אברהם כחילה"
        },
        {
            "_id": 23594,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1551,
            "שם_רחוב": "אברהם מ.קירשנבוים"
        },
        {
            "_id": 23595,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אברהם מסלונים"
        },
        {
            "_id": 23596,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5160,
            "שם_רחוב": "אג'יאל"
        },
        {
            "_id": 23597,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אגודת ספורט אליצור"
        },
        {
            "_id": 23598,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1454,
            "שם_רחוב": "אגודת ספורט אסא"
        },
        {
            "_id": 23599,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1451,
            "שם_רחוב": "אגודת ספורט ביתר"
        },
        {
            "_id": 23600,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1452,
            "שם_רחוב": "אגודת ספורט הפועל"
        },
        {
            "_id": 23601,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1455,
            "שם_רחוב": "אגודת ספורט מכבי"
        },
        {
            "_id": 23602,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4559,
            "שם_רחוב": "אגמון"
        },
        {
            "_id": 23603,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1173,
            "שם_רחוב": "אגמון אברהם"
        },
        {
            "_id": 23604,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1159,
            "שם_רחוב": "אגסי שמעון"
        },
        {
            "_id": 23605,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1522,
            "שם_רחוב": "אגרון"
        },
        {
            "_id": 23606,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4960,
            "שם_רחוב": "אגרות משה"
        },
        {
            "_id": 23607,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 613,
            "שם_רחוב": "אגריפס"
        },
        {
            "_id": 23608,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 521,
            "שם_רחוב": "אדהאן יחייא"
        },
        {
            "_id": 23609,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3650,
            "שם_רחוב": "אדיב אסעאף"
        },
        {
            "_id": 23610,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אדלמן"
        },
        {
            "_id": 23611,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 472,
            "שם_רחוב": "אדלר שאול"
        },
        {
            "_id": 23612,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5403,
            "שם_רחוב": "אדם"
        },
        {
            "_id": 23613,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 175,
            "שם_רחוב": "אדמון"
        },
        {
            "_id": 23614,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1637,
            "שם_רחוב": "אדמונד סחייק"
        },
        {
            "_id": 23615,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1534,
            "שם_רחוב": "אדמוני"
        },
        {
            "_id": 23616,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אדניהו הכהן"
        },
        {
            "_id": 23617,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1764,
            "שם_רחוב": "אהוד"
        },
        {
            "_id": 23618,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 680,
            "שם_רחוב": "אהל משה"
        },
        {
            "_id": 23619,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 602,
            "שם_רחוב": "אהל שלמה"
        },
        {
            "_id": 23620,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אהלי יוסף"
        },
        {
            "_id": 23621,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 939,
            "שם_רחוב": "אהליאב"
        },
        {
            "_id": 23622,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 269,
            "שם_רחוב": "אהרון (שעשוע) עזרא"
        },
        {
            "_id": 23623,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1939,
            "שם_רחוב": "אהרון כהן"
        },
        {
            "_id": 23624,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1180,
            "שם_רחוב": "אהרונוב"
        },
        {
            "_id": 23625,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1428,
            "שם_רחוב": "אהרוני"
        },
        {
            "_id": 23626,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4819,
            "שם_רחוב": "אהרנפלד נחום"
        },
        {
            "_id": 23627,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4577,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 23628,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4903,
            "שם_רחוב": "אוהל יהושע"
        },
        {
            "_id": 23629,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3036,
            "שם_רחוב": "אולברייט ויליאם"
        },
        {
            "_id": 23630,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1504,
            "שם_רחוב": "אוליפנט"
        },
        {
            "_id": 23631,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1749,
            "שם_רחוב": "אוליצור"
        },
        {
            "_id": 23632,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1299,
            "שם_רחוב": "אולשוונגר"
        },
        {
            "_id": 23633,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4498,
            "שם_רחוב": "אולשן יצחק"
        },
        {
            "_id": 23634,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אום אל דראג"
        },
        {
            "_id": 23635,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5147,
            "שם_רחוב": "אום אל עמד"
        },
        {
            "_id": 23636,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4708,
            "שם_רחוב": "אום אל עצאפיר"
        },
        {
            "_id": 23637,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4930,
            "שם_רחוב": "אום אל עצאפיר סמ1"
        },
        {
            "_id": 23638,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4931,
            "שם_רחוב": "אום אל עצאפיר סמ2"
        },
        {
            "_id": 23639,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5291,
            "שם_רחוב": "אום אל עצאפיר סמ3"
        },
        {
            "_id": 23640,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4733,
            "שם_רחוב": "אום אל צאמד"
        },
        {
            "_id": 23641,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4747,
            "שם_רחוב": "אום אלמואמנין"
        },
        {
            "_id": 23642,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4163,
            "שם_רחוב": "אום הרון"
        },
        {
            "_id": 23643,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4221,
            "שם_רחוב": "אום טובא"
        },
        {
            "_id": 23644,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4748,
            "שם_רחוב": "אום כולתום"
        },
        {
            "_id": 23645,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4235,
            "שם_רחוב": "אום ליסון"
        },
        {
            "_id": 23646,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5442,
            "שם_רחוב": "אום ליסון סמ1"
        },
        {
            "_id": 23647,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5443,
            "שם_רחוב": "אום ליסון סמ2"
        },
        {
            "_id": 23648,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5444,
            "שם_רחוב": "אום ליסון סמ3"
        },
        {
            "_id": 23649,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5445,
            "שם_רחוב": "אום ליסון סמ4"
        },
        {
            "_id": 23650,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5446,
            "שם_רחוב": "אום ליסון סמ6"
        },
        {
            "_id": 23651,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5447,
            "שם_רחוב": "אום ליסון סמ8"
        },
        {
            "_id": 23652,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 903,
            "שם_רחוב": "אונקלוס"
        },
        {
            "_id": 23653,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 714,
            "שם_רחוב": "אוסישקין"
        },
        {
            "_id": 23654,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 253,
            "שם_רחוב": "אופירה"
        },
        {
            "_id": 23655,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5021,
            "שם_רחוב": "אוצר הגאונים"
        },
        {
            "_id": 23656,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אוצר הספרים"
        },
        {
            "_id": 23657,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אור אלחנן"
        },
        {
            "_id": 23658,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4527,
            "שם_רחוב": "אור החיים"
        },
        {
            "_id": 23659,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אורבך אפרים"
        },
        {
            "_id": 23660,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1231,
            "שם_רחוב": "אורוגואי"
        },
        {
            "_id": 23661,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1545,
            "שם_רחוב": "אורי אורבך"
        },
        {
            "_id": 23662,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3037,
            "שם_רחוב": "אורי בן ארי"
        },
        {
            "_id": 23663,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 183,
            "שם_רחוב": "אוריאל"
        },
        {
            "_id": 23664,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5610,
            "שם_רחוב": "אוריאלי זכריהו"
        },
        {
            "_id": 23665,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 382,
            "שם_רחוב": "אורנשטיין"
        },
        {
            "_id": 23666,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 711,
            "שם_רחוב": "אושא"
        },
        {
            "_id": 23667,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4701,
            "שם_רחוב": "אז תעש הר החוצבים"
        },
        {
            "_id": 23668,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6594,
            "שם_רחוב": "אז תעשיה גב שאול"
        },
        {
            "_id": 23669,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4132,
            "שם_רחוב": "אז תעשיה ואדי גוז"
        },
        {
            "_id": 23670,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 929,
            "שם_רחוב": "אזולאי עקיבא"
        },
        {
            "_id": 23671,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6905,
            "שם_רחוב": "אזור תעשיה עטרות"
        },
        {
            "_id": 23672,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6910,
            "שם_רחוב": "אזור תעשיה רוממה"
        },
        {
            "_id": 23673,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6539,
            "שם_רחוב": "אזור תעשיה תלפיות"
        },
        {
            "_id": 23674,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6901,
            "שם_רחוב": "אזור תעשייה ענתות"
        },
        {
            "_id": 23675,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6907,
            "שם_רחוב": "אזור תעשייה שעפט"
        },
        {
            "_id": 23676,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4273,
            "שם_רחוב": "אח'ואן אל צפא"
        },
        {
            "_id": 23677,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1511,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 23678,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5858,
            "שם_רחוב": "אחוזת נוף"
        },
        {
            "_id": 23679,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אחינעם"
        },
        {
            "_id": 23680,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4560,
            "שם_רחוב": "אחלמה"
        },
        {
            "_id": 23681,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4749,
            "שם_רחוב": "אחמד אבן חנבל"
        },
        {
            "_id": 23682,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4925,
            "שם_רחוב": "אחמד אבן חנבל סמ 1"
        },
        {
            "_id": 23683,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4926,
            "שם_רחוב": "אחמד אבן חנבל סמ2"
        },
        {
            "_id": 23684,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4927,
            "שם_רחוב": "אחמד אבן חנבל סמ3"
        },
        {
            "_id": 23685,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5292,
            "שם_רחוב": "אחמד אסעד אל דארי"
        },
        {
            "_id": 23686,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5293,
            "שם_רחוב": "אחמד אסעד דארי סמ1"
        },
        {
            "_id": 23687,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5294,
            "שם_רחוב": "אחמד אסעד דארי סמ3"
        },
        {
            "_id": 23688,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5299,
            "שם_רחוב": "אחמד אסעד דארי סמ4"
        },
        {
            "_id": 23689,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5306,
            "שם_רחוב": "אחמד אסעד דארי סמ5"
        },
        {
            "_id": 23690,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5295,
            "שם_רחוב": "אחמד אסעד דארי סמ6"
        },
        {
            "_id": 23691,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5296,
            "שם_רחוב": "אחמד אסעד דארי סמ7"
        },
        {
            "_id": 23692,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5297,
            "שם_רחוב": "אחמד אסעד דארי סמ8"
        },
        {
            "_id": 23693,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5298,
            "שם_רחוב": "אחמד אסעד דארי סמ9"
        },
        {
            "_id": 23694,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3127,
            "שם_רחוב": "אחמד עדילה"
        },
        {
            "_id": 23695,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4750,
            "שם_רחוב": "אחמד עלי"
        },
        {
            "_id": 23696,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5307,
            "שם_רחוב": "אחמד עלי סמ1"
        },
        {
            "_id": 23697,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5308,
            "שם_רחוב": "אחמד עלי סמ2"
        },
        {
            "_id": 23698,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5309,
            "שם_רחוב": "אחמד עלי סמ4"
        },
        {
            "_id": 23699,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5311,
            "שם_רחוב": "אחמד עלי סמ6"
        },
        {
            "_id": 23700,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5312,
            "שם_רחוב": "אחמד עלי סמ8"
        },
        {
            "_id": 23701,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4666,
            "שם_רחוב": "אחמד שאוקי"
        },
        {
            "_id": 23702,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5144,
            "שם_רחוב": "אחמד שאוקי סמ3"
        },
        {
            "_id": 23703,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5145,
            "שם_רחוב": "אחמד שאוקי סמ5"
        },
        {
            "_id": 23704,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5146,
            "שם_רחוב": "אחמד שאוקי סמ8"
        },
        {
            "_id": 23705,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1220,
            "שם_רחוב": "אטינגר"
        },
        {
            "_id": 23706,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4597,
            "שם_רחוב": "איג אלכסנדר"
        },
        {
            "_id": 23707,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4479,
            "שם_רחוב": "אידלזון אברהם"
        },
        {
            "_id": 23708,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1229,
            "שם_רחוב": "אידר"
        },
        {
            "_id": 23709,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4285,
            "שם_רחוב": "איחוד הכפר"
        },
        {
            "_id": 23710,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1453,
            "שם_רחוב": "איילון דוד"
        },
        {
            "_id": 23711,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4569,
            "שם_רחוב": "אילה"
        },
        {
            "_id": 23712,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 326,
            "שם_רחוב": "אילן מרדכי צבי"
        },
        {
            "_id": 23713,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 669,
            "שם_רחוב": "אילת"
        },
        {
            "_id": 23714,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4169,
            "שם_רחוב": "אימרו אל קיס"
        },
        {
            "_id": 23715,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5373,
            "שם_רחוב": "אימרו אל קיס סמ2"
        },
        {
            "_id": 23716,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5346,
            "שם_רחוב": "אימרו אל קיס סמ6"
        },
        {
            "_id": 23717,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1110,
            "שם_רחוב": "אינשטיין"
        },
        {
            "_id": 23718,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4646,
            "שם_רחוב": "איסחאק אל מווסילי"
        },
        {
            "_id": 23719,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5534,
            "שם_רחוב": "איסלייב"
        },
        {
            "_id": 23720,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1285,
            "שם_רחוב": "איסלנד"
        },
        {
            "_id": 23721,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5532,
            "שם_רחוב": "איסעאף נשאשיבי"
        },
        {
            "_id": 23722,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "איצטדיון טדי"
        },
        {
            "_id": 23723,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1802,
            "שם_רחוב": "איש מצליח"
        },
        {
            "_id": 23724,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1040,
            "שם_רחוב": "איש שלום מרדכי"
        },
        {
            "_id": 23725,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 825,
            "שם_רחוב": "איתמר בן אבי"
        },
        {
            "_id": 23726,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4652,
            "שם_רחוב": "איתן"
        },
        {
            "_id": 23727,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4612,
            "שם_רחוב": "אל אדריסי"
        },
        {
            "_id": 23728,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5374,
            "שם_רחוב": "אל אזאעה"
        },
        {
            "_id": 23729,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5375,
            "שם_רחוב": "אל אזאעה סמ2"
        },
        {
            "_id": 23730,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4410,
            "שם_רחוב": "אל אח'טל"
        },
        {
            "_id": 23731,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5018,
            "שם_רחוב": "אל אחיאא"
        },
        {
            "_id": 23732,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5040,
            "שם_רחוב": "אל אחילאס"
        },
        {
            "_id": 23733,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5041,
            "שם_רחוב": "אל אחתראם"
        },
        {
            "_id": 23734,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5043,
            "שם_רחוב": "אל אחתראם סמ1"
        },
        {
            "_id": 23735,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5365,
            "שם_רחוב": "אל אחתראם סמ5"
        },
        {
            "_id": 23736,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5044,
            "שם_רחוב": "אל אחתראם סמ15"
        },
        {
            "_id": 23737,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5042,
            "שם_רחוב": "אל אחתראם סמ17"
        },
        {
            "_id": 23738,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5045,
            "שם_רחוב": "אל אחתראם סמ9"
        },
        {
            "_id": 23739,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5028,
            "שם_רחוב": "אל איאם"
        },
        {
            "_id": 23740,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5032,
            "שם_רחוב": "אל איאם סמ5"
        },
        {
            "_id": 23741,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2029,
            "שם_רחוב": "אל איאם סמ6"
        },
        {
            "_id": 23742,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5031,
            "שם_רחוב": "אל איאם סמ7"
        },
        {
            "_id": 23743,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5030,
            "שם_רחוב": "אל איאם סמ8"
        },
        {
            "_id": 23744,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4050,
            "שם_רחוב": "אל אימאב מלק"
        },
        {
            "_id": 23745,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4686,
            "שם_רחוב": "אל אימאם מוסלים"
        },
        {
            "_id": 23746,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5038,
            "שם_רחוב": "אל אימאם מוסלים סמ4"
        },
        {
            "_id": 23747,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5039,
            "שם_רחוב": "אל אימאם מוסלים סמ6"
        },
        {
            "_id": 23748,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5513,
            "שם_רחוב": "אל אעואר"
        },
        {
            "_id": 23749,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4051,
            "שם_רחוב": "אל אפדלייה"
        },
        {
            "_id": 23750,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4639,
            "שם_רחוב": "אל אצמעי"
        },
        {
            "_id": 23751,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5109,
            "שם_רחוב": "אל אצמעי סמ10"
        },
        {
            "_id": 23752,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5110,
            "שם_רחוב": "אל אצמעי סמ4"
        },
        {
            "_id": 23753,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5111,
            "שם_רחוב": "אל אצמעי סמ6"
        },
        {
            "_id": 23754,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5112,
            "שם_רחוב": "אל אצמעי סמ8"
        },
        {
            "_id": 23755,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4125,
            "שם_רחוב": "אל אקרוב"
        },
        {
            "_id": 23756,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4631,
            "שם_רחוב": "אל ארדשיר"
        },
        {
            "_id": 23757,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5280,
            "שם_רחוב": "אל ארז"
        },
        {
            "_id": 23758,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5281,
            "שם_רחוב": "אל ארז סמ2"
        },
        {
            "_id": 23759,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4085,
            "שם_רחוב": "אל ארמן"
        },
        {
            "_id": 23760,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5531,
            "שם_רחוב": "אל באסי"
        },
        {
            "_id": 23761,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 9477,
            "שם_רחוב": "אל בארודי"
        },
        {
            "_id": 23762,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4940,
            "שם_רחוב": "אל בארודי סמ4"
        },
        {
            "_id": 23763,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4941,
            "שם_רחוב": "אל בארודי סמ6"
        },
        {
            "_id": 23764,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4942,
            "שם_רחוב": "אל בארודי סמ8"
        },
        {
            "_id": 23765,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5258,
            "שם_רחוב": "אל בדריה"
        },
        {
            "_id": 23766,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5259,
            "שם_רחוב": "אל בדריה סמ2"
        },
        {
            "_id": 23767,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 593,
            "שם_רחוב": "אל בואסל"
        },
        {
            "_id": 23768,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5519,
            "שם_רחוב": "אל בובריה"
        },
        {
            "_id": 23769,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5161,
            "שם_רחוב": "אל בוח'ארי"
        },
        {
            "_id": 23770,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4661,
            "שם_רחוב": "אל בוחתורי"
        },
        {
            "_id": 23771,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5283,
            "שם_רחוב": "אל בוטום"
        },
        {
            "_id": 23772,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4381,
            "שם_רחוב": "אל בוטמה"
        },
        {
            "_id": 23773,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5455,
            "שם_רחוב": "אל בוטמה סמ1"
        },
        {
            "_id": 23774,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4018,
            "שם_רחוב": "אל בוסטמי"
        },
        {
            "_id": 23775,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4761,
            "שם_רחוב": "אל בוסתאן"
        },
        {
            "_id": 23776,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5321,
            "שם_רחוב": "אל בוסתאן סמ10"
        },
        {
            "_id": 23777,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3231,
            "שם_רחוב": "אל בוסתאן סמ11"
        },
        {
            "_id": 23778,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5313,
            "שם_רחוב": "אל בוסתאן סמ2"
        },
        {
            "_id": 23779,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5322,
            "שם_רחוב": "אל בוסתאן סמ12"
        },
        {
            "_id": 23780,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3230,
            "שם_רחוב": "אל בוסתאן סמ3"
        },
        {
            "_id": 23781,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5323,
            "שם_רחוב": "אל בוסתאן סמ13"
        },
        {
            "_id": 23782,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5314,
            "שם_רחוב": "אל בוסתאן סמ4"
        },
        {
            "_id": 23783,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5315,
            "שם_רחוב": "אל בוסתאן סמ5"
        },
        {
            "_id": 23784,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5324,
            "שם_רחוב": "אל בוסתאן סמ15"
        },
        {
            "_id": 23785,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5316,
            "שם_רחוב": "אל בוסתאן סמ6"
        },
        {
            "_id": 23786,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5317,
            "שם_רחוב": "אל בוסתאן סמ7"
        },
        {
            "_id": 23787,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3232,
            "שם_רחוב": "אל בוסתאן סמ17"
        },
        {
            "_id": 23788,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5318,
            "שם_רחוב": "אל בוסתאן סמ8"
        },
        {
            "_id": 23789,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5319,
            "שם_רחוב": "אל בוסתאן סמ9"
        },
        {
            "_id": 23790,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4049,
            "שם_רחוב": "אל בורק"
        },
        {
            "_id": 23791,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5284,
            "שם_רחוב": "אל בורתקאל"
        },
        {
            "_id": 23792,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5285,
            "שם_רחוב": "אל בורתקאל סמ3"
        },
        {
            "_id": 23793,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3356,
            "שם_רחוב": "אל בטן"
        },
        {
            "_id": 23794,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4916,
            "שם_רחוב": "אל ביאדר"
        },
        {
            "_id": 23795,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5325,
            "שם_רחוב": "אל ביאדר סמ1"
        },
        {
            "_id": 23796,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4122,
            "שם_רחוב": "אל ביידר"
        },
        {
            "_id": 23797,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5874,
            "שם_רחוב": "אל ביידר סמ1"
        },
        {
            "_id": 23798,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5616,
            "שם_רחוב": "אל בלוטה סמ2"
        },
        {
            "_id": 23799,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4983,
            "שם_רחוב": "אל בלח"
        },
        {
            "_id": 23800,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5286,
            "שם_רחוב": "אל בללוט"
        },
        {
            "_id": 23801,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5287,
            "שם_רחוב": "אל בללוט סמ1"
        },
        {
            "_id": 23802,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4670,
            "שם_רחוב": "אל בניאס"
        },
        {
            "_id": 23803,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5065,
            "שם_רחוב": "אל בניאס סמ2"
        },
        {
            "_id": 23804,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5260,
            "שם_רחוב": "אל בראעם"
        },
        {
            "_id": 23805,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5538,
            "שם_רחוב": "אל ג'בל אל ג'דיד"
        },
        {
            "_id": 23806,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5775,
            "שם_רחוב": "אל ג'דיד"
        },
        {
            "_id": 23807,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5776,
            "שם_רחוב": "אל ג'דיד סמ1"
        },
        {
            "_id": 23808,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5777,
            "שם_רחוב": "אל ג'דיד סמ2"
        },
        {
            "_id": 23809,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5778,
            "שם_רחוב": "אל ג'דיד סמ3"
        },
        {
            "_id": 23810,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5779,
            "שם_רחוב": "אל ג'דיד סמ4"
        },
        {
            "_id": 23811,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5780,
            "שם_רחוב": "אל ג'דיד סמ5"
        },
        {
            "_id": 23812,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5781,
            "שם_רחוב": "אל ג'דיד סמ6"
        },
        {
            "_id": 23813,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5782,
            "שם_רחוב": "אל ג'דיד סמ7"
        },
        {
            "_id": 23814,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5783,
            "שם_רחוב": "אל ג'דיד סמ9"
        },
        {
            "_id": 23815,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4693,
            "שם_רחוב": "אל ג'וזה"
        },
        {
            "_id": 23816,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5539,
            "שם_רחוב": "אל ג'ורון"
        },
        {
            "_id": 23817,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5261,
            "שם_רחוב": "אל ג'מעיה"
        },
        {
            "_id": 23818,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5264,
            "שם_רחוב": "אל ג'מעיה אל תחתא"
        },
        {
            "_id": 23819,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5265,
            "שם_רחוב": "אל ג'סר"
        },
        {
            "_id": 23820,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4240,
            "שם_רחוב": "אל גאמע"
        },
        {
            "_id": 23821,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4071,
            "שם_רחוב": "אל גבשה"
        },
        {
            "_id": 23822,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5162,
            "שם_רחוב": "אל גדוד"
        },
        {
            "_id": 23823,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5163,
            "שם_רחוב": "אל גדוד סמ3"
        },
        {
            "_id": 23824,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5164,
            "שם_רחוב": "אל גדוד סמ4"
        },
        {
            "_id": 23825,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5165,
            "שם_רחוב": "אל גדוד סמ7"
        },
        {
            "_id": 23826,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4255,
            "שם_רחוב": "אל גול"
        },
        {
            "_id": 23827,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5340,
            "שם_רחוב": "אל דאליה"
        },
        {
            "_id": 23828,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4737,
            "שם_רחוב": "אל דהר"
        },
        {
            "_id": 23829,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5133,
            "שם_רחוב": "אל דהר סמ3"
        },
        {
            "_id": 23830,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4391,
            "שם_רחוב": "אל דוואר אל אוול"
        },
        {
            "_id": 23831,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4934,
            "שם_רחוב": "אל דמיר"
        },
        {
            "_id": 23832,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4935,
            "שם_רחוב": "אל דמיר סמ2"
        },
        {
            "_id": 23833,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4936,
            "שם_רחוב": "אל דמיר סמ3"
        },
        {
            "_id": 23834,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4937,
            "שם_רחוב": "אל דמיר סמ5"
        },
        {
            "_id": 23835,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4938,
            "שם_רחוב": "אל דמיר סמ7"
        },
        {
            "_id": 23836,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4939,
            "שם_רחוב": "אל דמיר סמ8"
        },
        {
            "_id": 23837,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5341,
            "שם_רחוב": "אל דרראקה"
        },
        {
            "_id": 23838,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5342,
            "שם_רחוב": "אל דרראקה סמ2"
        },
        {
            "_id": 23839,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5343,
            "שם_רחוב": "אל דרראקה סמ3"
        },
        {
            "_id": 23840,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4151,
            "שם_רחוב": "אל הדבה"
        },
        {
            "_id": 23841,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5023,
            "שם_רחוב": "אל הודא"
        },
        {
            "_id": 23842,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5024,
            "שם_רחוב": "אל הודא סמ11"
        },
        {
            "_id": 23843,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5025,
            "שם_רחוב": "אל הודא סמ3"
        },
        {
            "_id": 23844,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5026,
            "שם_רחוב": "אל הודא סמ7"
        },
        {
            "_id": 23845,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5027,
            "שם_רחוב": "אל הודא סמ8"
        },
        {
            "_id": 23846,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4738,
            "שם_רחוב": "אל הילאל"
        },
        {
            "_id": 23847,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4004,
            "שם_רחוב": "אל ואד"
        },
        {
            "_id": 23848,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5266,
            "שם_רחוב": "אל ווחדה"
        },
        {
            "_id": 23849,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5456,
            "שם_רחוב": "אל ווחדה סמ2"
        },
        {
            "_id": 23850,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5457,
            "שם_רחוב": "אל ווחדה סמ3"
        },
        {
            "_id": 23851,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4399,
            "שם_רחוב": "אל וולאג'ה"
        },
        {
            "_id": 23852,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4735,
            "שם_רחוב": "אל ווער"
        },
        {
            "_id": 23853,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4763,
            "שם_רחוב": "אל ורד"
        },
        {
            "_id": 23854,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5149,
            "שם_רחוב": "אל זהור"
        },
        {
            "_id": 23855,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5150,
            "שם_רחוב": "אל זהור סמ1"
        },
        {
            "_id": 23856,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5171,
            "שם_רחוב": "אל זהור סמ2"
        },
        {
            "_id": 23857,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5617,
            "שם_רחוב": "אל זיתון סמ2"
        },
        {
            "_id": 23858,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5345,
            "שם_רחוב": "אל זמבק"
        },
        {
            "_id": 23859,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5580,
            "שם_רחוב": "אל ח'נסה"
        },
        {
            "_id": 23860,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4671,
            "שם_רחוב": "אל חאלסה"
        },
        {
            "_id": 23861,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5504,
            "שם_רחוב": "אל חארג'ה"
        },
        {
            "_id": 23862,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5122,
            "שם_רחוב": "אל חג'אג' בן יוסף"
        },
        {
            "_id": 23863,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5139,
            "שם_רחוב": "אל חג'אג' סמ10"
        },
        {
            "_id": 23864,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5140,
            "שם_רחוב": "אל חג'אג' סמ4"
        },
        {
            "_id": 23865,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5141,
            "שם_רחוב": "אל חג'אג' סמ5"
        },
        {
            "_id": 23866,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4665,
            "שם_רחוב": "אל חגאג בן יוסף"
        },
        {
            "_id": 23867,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5327,
            "שם_רחוב": "אל חיג'אר"
        },
        {
            "_id": 23868,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5328,
            "שם_רחוב": "אל חיג'אר סמ2"
        },
        {
            "_id": 23869,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3233,
            "שם_רחוב": "אל חיג'אר סמ4"
        },
        {
            "_id": 23870,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5062,
            "שם_רחוב": "אל חכמה"
        },
        {
            "_id": 23871,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4408,
            "שם_רחוב": "אל חמווי"
        },
        {
            "_id": 23872,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4218,
            "שם_רחוב": "אל חניה"
        },
        {
            "_id": 23873,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4407,
            "שם_רחוב": "אל חסבה"
        },
        {
            "_id": 23874,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4042,
            "שם_רחוב": "אל חקארי"
        },
        {
            "_id": 23875,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4141,
            "שם_רחוב": "אל חרדוב"
        },
        {
            "_id": 23876,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3067,
            "שם_רחוב": "אל חרדוב סמ10"
        },
        {
            "_id": 23877,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3046,
            "שם_רחוב": "אל חרדוב סמ1"
        },
        {
            "_id": 23878,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3047,
            "שם_רחוב": "אל חרדוב סמ2"
        },
        {
            "_id": 23879,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3068,
            "שם_רחוב": "אל חרדוב סמ12"
        },
        {
            "_id": 23880,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3048,
            "שם_רחוב": "אל חרדוב סמ3"
        },
        {
            "_id": 23881,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3115,
            "שם_רחוב": "אל חרדוב סמ13"
        },
        {
            "_id": 23882,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3049,
            "שם_רחוב": "אל חרדוב סמ4"
        },
        {
            "_id": 23883,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3050,
            "שם_רחוב": "אל חרדוב סמ5"
        },
        {
            "_id": 23884,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3063,
            "שם_רחוב": "אל חרדוב סמ6"
        },
        {
            "_id": 23885,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3116,
            "שם_רחוב": "אל חרדוב סמ16"
        },
        {
            "_id": 23886,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3064,
            "שם_רחוב": "אל חרדוב סמ7"
        },
        {
            "_id": 23887,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3065,
            "שם_רחוב": "אל חרדוב סמ8"
        },
        {
            "_id": 23888,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3066,
            "שם_רחוב": "אל חרדוב סמ9"
        },
        {
            "_id": 23889,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4694,
            "שם_רחוב": "אל חרמי שוקרי"
        },
        {
            "_id": 23890,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5267,
            "שם_רחוב": "אל טאחונה"
        },
        {
            "_id": 23891,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5268,
            "שם_רחוב": "אל טואל"
        },
        {
            "_id": 23892,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5269,
            "שם_רחוב": "אל טואל סמ1"
        },
        {
            "_id": 23893,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5606,
            "שם_רחוב": "אל טנטור סמ1"
        },
        {
            "_id": 23894,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4139,
            "שם_רחוב": "אל כאלה"
        },
        {
            "_id": 23895,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3129,
            "שם_רחוב": "אל כאלה סמ1"
        },
        {
            "_id": 23896,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3130,
            "שם_רחוב": "אל כאלה סמ2"
        },
        {
            "_id": 23897,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3019,
            "שם_רחוב": "אל כאלה סמ3"
        },
        {
            "_id": 23898,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3131,
            "שם_רחוב": "אל כאלה סמ4"
        },
        {
            "_id": 23899,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3021,
            "שם_רחוב": "אל כאלה סמ5"
        },
        {
            "_id": 23900,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3022,
            "שם_רחוב": "אל כאלה סמ7"
        },
        {
            "_id": 23901,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3039,
            "שם_רחוב": "אל כאלה סמ9"
        },
        {
            "_id": 23902,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5579,
            "שם_רחוב": "אל כליף הישאם"
        },
        {
            "_id": 23903,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4627,
            "שם_רחוב": "אל מאמון"
        },
        {
            "_id": 23904,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5008,
            "שם_רחוב": "אל מאמון סמ10"
        },
        {
            "_id": 23905,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5029,
            "שם_רחוב": "אל מאמון סמ4"
        },
        {
            "_id": 23906,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5048,
            "שם_רחוב": "אל מאמון סמ6"
        },
        {
            "_id": 23907,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5332,
            "שם_רחוב": "אל מדארס סמ4"
        },
        {
            "_id": 23908,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4727,
            "שם_רחוב": "אל מדוורה"
        },
        {
            "_id": 23909,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4386,
            "שם_רחוב": "אל מדרסה"
        },
        {
            "_id": 23910,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4001,
            "שם_רחוב": "אל מוגהידין"
        },
        {
            "_id": 23911,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4112,
            "שם_רחוב": "אל מוגראר"
        },
        {
            "_id": 23912,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5068,
            "שם_רחוב": "אל מוודה"
        },
        {
            "_id": 23913,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4697,
            "שם_רחוב": "אל מוטרן גבריל"
        },
        {
            "_id": 23914,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4052,
            "שם_רחוב": "אל מונאדילין"
        },
        {
            "_id": 23915,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5004,
            "שם_רחוב": "אל מונזר"
        },
        {
            "_id": 23916,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5005,
            "שם_רחוב": "אל מונזר סמ6"
        },
        {
            "_id": 23917,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4839,
            "שם_רחוב": "אל מונטאר"
        },
        {
            "_id": 23918,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5703,
            "שם_רחוב": "אל מונטאר סמ2"
        },
        {
            "_id": 23919,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3355,
            "שם_רחוב": "אל מונטאר סמ4"
        },
        {
            "_id": 23920,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5271,
            "שם_רחוב": "אל מוסתשפא"
        },
        {
            "_id": 23921,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5803,
            "שם_רחוב": "אל מועזמיה"
        },
        {
            "_id": 23922,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4644,
            "שם_רחוב": "אל מועתסם"
        },
        {
            "_id": 23923,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4271,
            "שם_רחוב": "אל מוקדסי"
        },
        {
            "_id": 23924,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5349,
            "שם_רחוב": "אל מוקדסי סמ10"
        },
        {
            "_id": 23925,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5377,
            "שם_רחוב": "אל מוקדסי סמ1"
        },
        {
            "_id": 23926,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5366,
            "שם_רחוב": "אל מוקדסי סמ2"
        },
        {
            "_id": 23927,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5347,
            "שם_רחוב": "אל מוקדסי סמ12"
        },
        {
            "_id": 23928,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5350,
            "שם_רחוב": "אל מוקדסי סמ4"
        },
        {
            "_id": 23929,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5344,
            "שם_רחוב": "אל מוקדסי סמ6"
        },
        {
            "_id": 23930,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5348,
            "שם_רחוב": "אל מוקדסי סמ8"
        },
        {
            "_id": 23931,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3600,
            "שם_רחוב": "אל מותנבי"
        },
        {
            "_id": 23932,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4946,
            "שם_רחוב": "אל מחבה"
        },
        {
            "_id": 23933,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5273,
            "שם_רחוב": "אל מיד'רא"
        },
        {
            "_id": 23934,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4225,
            "שם_רחוב": "אל מישמיש"
        },
        {
            "_id": 23935,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4092,
            "שם_רחוב": "אל מלך"
        },
        {
            "_id": 23936,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5274,
            "שם_רחוב": "אל מנארה"
        },
        {
            "_id": 23937,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5275,
            "שם_רחוב": "אל מנארה סמ1"
        },
        {
            "_id": 23938,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5585,
            "שם_רחוב": "אל מנסוריה"
        },
        {
            "_id": 23939,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4700,
            "שם_רחוב": "אל מסגיד"
        },
        {
            "_id": 23940,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4736,
            "שם_רחוב": "אל מצאנע"
        },
        {
            "_id": 23941,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5520,
            "שם_רחוב": "אל מקברה"
        },
        {
            "_id": 23942,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4855,
            "שם_רחוב": "אל מקפה"
        },
        {
            "_id": 23943,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5124,
            "שם_רחוב": "אל נאדר"
        },
        {
            "_id": 23944,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5086,
            "שם_רחוב": "אל נג'אמה"
        },
        {
            "_id": 23945,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5087,
            "שם_רחוב": "אל נג'אמה סמ3"
        },
        {
            "_id": 23946,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5088,
            "שם_רחוב": "אל נג'אמה סמ5"
        },
        {
            "_id": 23947,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5089,
            "שם_רחוב": "אל נג'אמה סמ7"
        },
        {
            "_id": 23948,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5536,
            "שם_רחוב": "אל נרג'ס"
        },
        {
            "_id": 23949,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5683,
            "שם_רחוב": "אל נרג'ס סמ1"
        },
        {
            "_id": 23950,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5625,
            "שם_רחוב": "אל סאפח"
        },
        {
            "_id": 23951,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5628,
            "שם_רחוב": "אל סאפח סמ3"
        },
        {
            "_id": 23952,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5629,
            "שם_רחוב": "אל סאפח סמ4"
        },
        {
            "_id": 23953,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4731,
            "שם_רחוב": "אל סהל"
        },
        {
            "_id": 23954,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4074,
            "שם_רחוב": "אל סיידה"
        },
        {
            "_id": 23955,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5277,
            "שם_רחוב": "אל סיל"
        },
        {
            "_id": 23956,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5278,
            "שם_רחוב": "אל סילם"
        },
        {
            "_id": 23957,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5067,
            "שם_רחוב": "אל סלאח"
        },
        {
            "_id": 23958,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1292,
            "שם_רחוב": "אל סלוודור"
        },
        {
            "_id": 23959,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4012,
            "שם_רחוב": "אל סלחייה"
        },
        {
            "_id": 23960,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4232,
            "שם_רחוב": "אל סלעה"
        },
        {
            "_id": 23961,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4993,
            "שם_רחוב": "אל סמאח"
        },
        {
            "_id": 23962,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4994,
            "שם_רחוב": "אל סמאח סמ2"
        },
        {
            "_id": 23963,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4687,
            "שם_רחוב": "אל ספדי אחמד"
        },
        {
            "_id": 23964,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4742,
            "שם_רחוב": "אל ע'רבי"
        },
        {
            "_id": 23965,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4995,
            "שם_רחוב": "אל ע'רבי סמ1"
        },
        {
            "_id": 23966,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4996,
            "שם_רחוב": "אל ע'רבי סמ5"
        },
        {
            "_id": 23967,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4716,
            "שם_רחוב": "אל עאזי איברהים"
        },
        {
            "_id": 23968,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4642,
            "שם_רחוב": "אל עבאס"
        },
        {
            "_id": 23969,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5512,
            "שם_רחוב": "אל עבאסייה"
        },
        {
            "_id": 23970,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5838,
            "שם_רחוב": "אל עבאסייה סמ2"
        },
        {
            "_id": 23971,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5839,
            "שם_רחוב": "אל עבאסייה סמ4"
        },
        {
            "_id": 23972,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4013,
            "שם_רחוב": "אל עומרי"
        },
        {
            "_id": 23973,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5279,
            "שם_רחוב": "אל עזזה"
        },
        {
            "_id": 23974,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5085,
            "שם_רחוב": "אל עטאא"
        },
        {
            "_id": 23975,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5518,
            "שם_רחוב": "אל עין"
        },
        {
            "_id": 23976,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4717,
            "שם_רחוב": "אל עמר דהאר"
        },
        {
            "_id": 23977,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4663,
            "שם_רחוב": "אל פאראבי"
        },
        {
            "_id": 23978,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5081,
            "שם_רחוב": "אל פאראבי סמ1"
        },
        {
            "_id": 23979,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5049,
            "שם_רחוב": "אל פאראבי סמ3"
        },
        {
            "_id": 23980,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4130,
            "שם_רחוב": "אל קאע"
        },
        {
            "_id": 23981,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4006,
            "שם_רחוב": "אל קדיסיה"
        },
        {
            "_id": 23982,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4695,
            "שם_רחוב": "אל קובטיה מרים"
        },
        {
            "_id": 23983,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4420,
            "שם_רחוב": "אל קובסא"
        },
        {
            "_id": 23984,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4107,
            "שם_רחוב": "אל קוליה"
        },
        {
            "_id": 23985,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4641,
            "שם_רחוב": "אל קינדי"
        },
        {
            "_id": 23986,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4041,
            "שם_רחוב": "אל קירמי"
        },
        {
            "_id": 23987,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4984,
            "שם_רחוב": "אל קמר"
        },
        {
            "_id": 23988,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4987,
            "שם_רחוב": "אל קמר סמ4"
        },
        {
            "_id": 23989,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4986,
            "שם_רחוב": "אל קמר סמ6"
        },
        {
            "_id": 23990,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4988,
            "שם_רחוב": "אל קמר סמ8"
        },
        {
            "_id": 23991,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4123,
            "שם_רחוב": "אל קסילה"
        },
        {
            "_id": 23992,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4989,
            "שם_רחוב": "אל קסר"
        },
        {
            "_id": 23993,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4398,
            "שם_רחוב": "אל קצאיל"
        },
        {
            "_id": 23994,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4698,
            "שם_רחוב": "אל קרמי אבו סלמה"
        },
        {
            "_id": 23995,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4718,
            "שם_רחוב": "אל ראוידה"
        },
        {
            "_id": 23996,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4249,
            "שם_רחוב": "אל ראס"
        },
        {
            "_id": 23997,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4719,
            "שם_רחוב": "אל שאפעיה זיינב"
        },
        {
            "_id": 23998,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5036,
            "שם_רחוב": "אל שוקר"
        },
        {
            "_id": 23999,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4384,
            "שם_רחוב": "אל שיפא"
        },
        {
            "_id": 24000,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5037,
            "שם_רחוב": "אל תעאוון"
        },
        {
            "_id": 24001,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5770,
            "שם_רחוב": "אל-אאמה"
        },
        {
            "_id": 24002,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5771,
            "שם_רחוב": "אל-אאמה סמ1"
        },
        {
            "_id": 24003,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5772,
            "שם_רחוב": "אל-אאמה סמ2"
        },
        {
            "_id": 24004,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5773,
            "שם_רחוב": "אל-אאמה סמ3"
        },
        {
            "_id": 24005,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5774,
            "שם_רחוב": "אל-אביאד"
        },
        {
            "_id": 24006,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3170,
            "שם_רחוב": "אל-אביאד סמ11"
        },
        {
            "_id": 24007,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3171,
            "שם_רחוב": "אל-אביאד סמ13"
        },
        {
            "_id": 24008,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3167,
            "שם_רחוב": "אל-אביאד סמ5"
        },
        {
            "_id": 24009,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3172,
            "שם_רחוב": "אל-אביאד סמ15"
        },
        {
            "_id": 24010,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3168,
            "שם_רחוב": "אל-אביאד סמ7"
        },
        {
            "_id": 24011,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3169,
            "שם_רחוב": "אל-אביאד סמ9"
        },
        {
            "_id": 24012,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3069,
            "שם_רחוב": "אל-אוסתאד' תמאם"
        },
        {
            "_id": 24013,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3113,
            "שם_רחוב": "אל-אוסתאד' תמאם סמ2"
        },
        {
            "_id": 24014,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3114,
            "שם_רחוב": "אל-אוסתאד' תמאם סמ3"
        },
        {
            "_id": 24015,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3070,
            "שם_רחוב": "אל-אוסתאד' תמאם סמ4"
        },
        {
            "_id": 24016,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3071,
            "שם_רחוב": "אל-אוסתאד' תמאם סמ5"
        },
        {
            "_id": 24017,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3072,
            "שם_רחוב": "אל-אוסתאד' תמאם סמ6"
        },
        {
            "_id": 24018,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3073,
            "שם_רחוב": "אל-אוסתאד' תמאם סמ7"
        },
        {
            "_id": 24019,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3074,
            "שם_רחוב": "אל-אוסתאד' תמאם סמ9"
        },
        {
            "_id": 24020,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5627,
            "שם_רחוב": "אל-אמג'אד"
        },
        {
            "_id": 24021,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5639,
            "שם_רחוב": "אל-אמג'אד סמ2"
        },
        {
            "_id": 24022,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5666,
            "שם_רחוב": "אל-אמג'אד סמ4"
        },
        {
            "_id": 24023,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5667,
            "שם_רחוב": "אל-אמג'אד סמ6"
        },
        {
            "_id": 24024,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5668,
            "שם_רחוב": "אל-אמג'אד סמ8"
        },
        {
            "_id": 24025,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3128,
            "שם_רחוב": "אל-אמינה"
        },
        {
            "_id": 24026,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3173,
            "שם_רחוב": "אל-אנסאר"
        },
        {
            "_id": 24027,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3174,
            "שם_רחוב": "אל-אנסאר סמ3"
        },
        {
            "_id": 24028,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3175,
            "שם_רחוב": "אל-אנסאר סמ4"
        },
        {
            "_id": 24029,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3176,
            "שם_רחוב": "אל-אנסאר סמ6"
        },
        {
            "_id": 24030,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4167,
            "שם_רחוב": "אל-אספהאני"
        },
        {
            "_id": 24031,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5615,
            "שם_רחוב": "אל-ביר"
        },
        {
            "_id": 24032,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5194,
            "שם_רחוב": "אל-ביר אל-שרקי"
        },
        {
            "_id": 24033,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5486,
            "שם_רחוב": "אל-ביר אל-שרקי סמ4"
        },
        {
            "_id": 24034,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5491,
            "שם_רחוב": "אל-ביר אל-שרקי סמ5"
        },
        {
            "_id": 24035,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5492,
            "שם_רחוב": "אל-ביר אל-שרקי סמ7"
        },
        {
            "_id": 24036,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5493,
            "שם_רחוב": "אל-ביר אל-שרקי סמ9"
        },
        {
            "_id": 24037,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5174,
            "שם_רחוב": "אל-בירכה"
        },
        {
            "_id": 24038,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5494,
            "שם_רחוב": "אל-בית אל-עתיק"
        },
        {
            "_id": 24039,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5537,
            "שם_רחוב": "אל-בלוטה"
        },
        {
            "_id": 24040,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5495,
            "שם_רחוב": "אל-ברא'"
        },
        {
            "_id": 24041,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5496,
            "שם_רחוב": "אל-ברא' סמ1"
        },
        {
            "_id": 24042,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5497,
            "שם_רחוב": "אל-ברא' סמ3"
        },
        {
            "_id": 24043,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5498,
            "שם_רחוב": "אל-ברא' סמ4"
        },
        {
            "_id": 24044,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3152,
            "שם_רחוב": "אל-ג'בל"
        },
        {
            "_id": 24045,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3153,
            "שם_רחוב": "אל-ג'בל סמ3"
        },
        {
            "_id": 24046,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5784,
            "שם_רחוב": "אל-היל"
        },
        {
            "_id": 24047,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5785,
            "שם_רחוב": "אל-היל סמ1"
        },
        {
            "_id": 24048,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5786,
            "שם_רחוב": "אל-היל סמ3"
        },
        {
            "_id": 24049,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5787,
            "שם_רחוב": "אל-היל סמ5"
        },
        {
            "_id": 24050,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5788,
            "שם_רחוב": "אל-היל סמ6"
        },
        {
            "_id": 24051,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5789,
            "שם_רחוב": "אל-היל סמ7"
        },
        {
            "_id": 24052,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5790,
            "שם_רחוב": "אל-הרם"
        },
        {
            "_id": 24053,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5791,
            "שם_רחוב": "אל-הרם סמ1"
        },
        {
            "_id": 24054,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5792,
            "שם_רחוב": "אל-הרם סמ2"
        },
        {
            "_id": 24055,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5589,
            "שם_רחוב": "אל-וועד"
        },
        {
            "_id": 24056,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3137,
            "שם_רחוב": "אל-ופא'"
        },
        {
            "_id": 24057,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3138,
            "שם_רחוב": "אל-ופא' סמ1"
        },
        {
            "_id": 24058,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3139,
            "שם_רחוב": "אל-ופא' סמ4"
        },
        {
            "_id": 24059,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5499,
            "שם_רחוב": "אל-זאוויה"
        },
        {
            "_id": 24060,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4615,
            "שם_רחוב": "אל-זהרא'"
        },
        {
            "_id": 24061,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4389,
            "שם_רחוב": "אל-זיתון"
        },
        {
            "_id": 24062,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5458,
            "שם_רחוב": "אל-זנבק"
        },
        {
            "_id": 24063,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5500,
            "שם_רחוב": "אל-חאמד"
        },
        {
            "_id": 24064,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5793,
            "שם_רחוב": "אל-חארה שרקייה"
        },
        {
            "_id": 24065,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5794,
            "שם_רחוב": "אל-חארה שרקייה סמ1"
        },
        {
            "_id": 24066,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5795,
            "שם_רחוב": "אל-חארה שרקייה סמ2"
        },
        {
            "_id": 24067,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5796,
            "שם_רחוב": "אל-חארה שרקייה סמ3"
        },
        {
            "_id": 24068,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5797,
            "שם_רחוב": "אל-חארה שרקייה סמ4"
        },
        {
            "_id": 24069,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5798,
            "שם_רחוב": "אל-חארה שרקייה סמ5"
        },
        {
            "_id": 24070,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5799,
            "שם_רחוב": "אל-חארה שרקייה סמ6"
        },
        {
            "_id": 24071,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5502,
            "שם_רחוב": "אל-חג'אב"
        },
        {
            "_id": 24072,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5800,
            "שם_רחוב": "אל-חווא"
        },
        {
            "_id": 24073,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5809,
            "שם_רחוב": "אל-חווא סמ1"
        },
        {
            "_id": 24074,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5596,
            "שם_רחוב": "אל-חווארה"
        },
        {
            "_id": 24075,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3234,
            "שם_רחוב": "אל-חיאה"
        },
        {
            "_id": 24076,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3239,
            "שם_רחוב": "אל-חיאה סמ10"
        },
        {
            "_id": 24077,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3235,
            "שם_רחוב": "אל-חיאה סמ1"
        },
        {
            "_id": 24078,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3236,
            "שם_רחוב": "אל-חיאה סמ2"
        },
        {
            "_id": 24079,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3237,
            "שם_רחוב": "אל-חיאה סמ4"
        },
        {
            "_id": 24080,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3238,
            "שם_רחוב": "אל-חיאה סמ6"
        },
        {
            "_id": 24081,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5326,
            "שם_רחוב": "אל-חיאה סמ8"
        },
        {
            "_id": 24082,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4838,
            "שם_רחוב": "אל-חראיק"
        },
        {
            "_id": 24083,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5329,
            "שם_רחוב": "אל-חראיק סמ1"
        },
        {
            "_id": 24084,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5330,
            "שם_רחוב": "אל-חראיק סמ3"
        },
        {
            "_id": 24085,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4274,
            "שם_רחוב": "אל-חרירי"
        },
        {
            "_id": 24086,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5376,
            "שם_רחוב": "אל-חרירי סמ1"
        },
        {
            "_id": 24087,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5459,
            "שם_רחוב": "אל-ט'הרה"
        },
        {
            "_id": 24088,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5464,
            "שם_רחוב": "אל-ט'הרה סמ1"
        },
        {
            "_id": 24089,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5465,
            "שם_רחוב": "אל-ט'הרה סמ2"
        },
        {
            "_id": 24090,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5466,
            "שם_רחוב": "אל-ט'הרה סמ3"
        },
        {
            "_id": 24091,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5467,
            "שם_רחוב": "אל-ט'הרה סמ4"
        },
        {
            "_id": 24092,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5468,
            "שם_רחוב": "אל-ט'הרה סמ7"
        },
        {
            "_id": 24093,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5597,
            "שם_רחוב": "אל-טנטור"
        },
        {
            "_id": 24094,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5470,
            "שם_רחוב": "אל-טנטור סמ1"
        },
        {
            "_id": 24095,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5598,
            "שם_רחוב": "אל-טפטאף"
        },
        {
            "_id": 24096,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4200,
            "שם_רחוב": "אל-יעקובי"
        },
        {
            "_id": 24097,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5877,
            "שם_רחוב": "אל-כורמא'"
        },
        {
            "_id": 24098,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5887,
            "שם_רחוב": "אל-כורמא' סמ10"
        },
        {
            "_id": 24099,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5878,
            "שם_רחוב": "אל-כורמא' סמ1"
        },
        {
            "_id": 24100,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5879,
            "שם_רחוב": "אל-כורמא' סמ2"
        },
        {
            "_id": 24101,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5880,
            "שם_רחוב": "אל-כורמא' סמ3"
        },
        {
            "_id": 24102,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5881,
            "שם_רחוב": "אל-כורמא' סמ4"
        },
        {
            "_id": 24103,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5882,
            "שם_רחוב": "אל-כורמא' סמ5"
        },
        {
            "_id": 24104,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5883,
            "שם_רחוב": "אל-כורמא' סמ6"
        },
        {
            "_id": 24105,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5884,
            "שם_רחוב": "אל-כורמא' סמ7"
        },
        {
            "_id": 24106,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5885,
            "שם_רחוב": "אל-כורמא' סמ8"
        },
        {
            "_id": 24107,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5886,
            "שם_רחוב": "אל-כורמא' סמ9"
        },
        {
            "_id": 24108,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5469,
            "שם_רחוב": "אל-לימון"
        },
        {
            "_id": 24109,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3287,
            "שם_רחוב": "אל-מא'ד'ון"
        },
        {
            "_id": 24110,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3292,
            "שם_רחוב": "אל-מא'ד'ון סמ10"
        },
        {
            "_id": 24111,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3293,
            "שם_רחוב": "אל-מא'ד'ון סמ12"
        },
        {
            "_id": 24112,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3288,
            "שם_רחוב": "אל-מא'ד'ון סמ3"
        },
        {
            "_id": 24113,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3289,
            "שם_רחוב": "אל-מא'ד'ון סמ5"
        },
        {
            "_id": 24114,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3290,
            "שם_רחוב": "אל-מא'ד'ון סמ6"
        },
        {
            "_id": 24115,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3291,
            "שם_רחוב": "אל-מא'ד'ון סמ8"
        },
        {
            "_id": 24116,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3240,
            "שם_רחוב": "אל-מג'ד"
        },
        {
            "_id": 24117,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3304,
            "שם_רחוב": "אל-מג'לס"
        },
        {
            "_id": 24118,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3305,
            "שם_רחוב": "אל-מג'לס סמ1"
        },
        {
            "_id": 24119,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3306,
            "שם_רחוב": "אל-מג'לס סמ2"
        },
        {
            "_id": 24120,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3307,
            "שם_רחוב": "אל-מג'לס סמ4"
        },
        {
            "_id": 24121,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5700,
            "שם_רחוב": "אל-מהאג'רין"
        },
        {
            "_id": 24122,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5702,
            "שם_רחוב": "אל-מהאג'רין סמ2"
        },
        {
            "_id": 24123,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4020,
            "שם_רחוב": "אל-מוולוויה"
        },
        {
            "_id": 24124,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3308,
            "שם_רחוב": "אל-מוח'תאר"
        },
        {
            "_id": 24125,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5272,
            "שם_רחוב": "אל-מוטלה"
        },
        {
            "_id": 24126,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5813,
            "שם_רחוב": "אל-מונתזה"
        },
        {
            "_id": 24127,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5819,
            "שם_רחוב": "אל-מונתזה סמ10"
        },
        {
            "_id": 24128,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5814,
            "שם_רחוב": "אל-מונתזה סמ1"
        },
        {
            "_id": 24129,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5815,
            "שם_רחוב": "אל-מונתזה סמ2"
        },
        {
            "_id": 24130,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5820,
            "שם_רחוב": "אל-מונתזה סמ12"
        },
        {
            "_id": 24131,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5816,
            "שם_רחוב": "אל-מונתזה סמ4"
        },
        {
            "_id": 24132,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5817,
            "שם_רחוב": "אל-מונתזה סמ6"
        },
        {
            "_id": 24133,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5818,
            "שם_רחוב": "אל-מונתזה סמ8"
        },
        {
            "_id": 24134,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4086,
            "שם_רחוב": "אל-מוסתווסיף"
        },
        {
            "_id": 24135,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5620,
            "שם_רחוב": "אל-מזאר"
        },
        {
            "_id": 24136,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5175,
            "שם_רחוב": "אל-מחאג'ר"
        },
        {
            "_id": 24137,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5687,
            "שם_רחוב": "אל-מחאג'ר סמ1"
        },
        {
            "_id": 24138,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5197,
            "שם_רחוב": "אל-מחאג'ר סמ3"
        },
        {
            "_id": 24139,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5688,
            "שם_רחוב": "אל-מחאג'ר סמ4"
        },
        {
            "_id": 24140,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5689,
            "שם_רחוב": "אל-מחאג'ר סמ5"
        },
        {
            "_id": 24141,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5690,
            "שם_רחוב": "אל-מחאג'ר סמ6"
        },
        {
            "_id": 24142,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3310,
            "שם_רחוב": "אל-מטאר"
        },
        {
            "_id": 24143,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3311,
            "שם_רחוב": "אל-מטאר סמ1"
        },
        {
            "_id": 24144,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3312,
            "שם_רחוב": "אל-מטאר סמ3"
        },
        {
            "_id": 24145,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3313,
            "שם_רחוב": "אל-מטאר סמ5"
        },
        {
            "_id": 24146,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5607,
            "שם_רחוב": "אל-מיישה"
        },
        {
            "_id": 24147,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3157,
            "שם_רחוב": "אל-מיעראג'"
        },
        {
            "_id": 24148,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3158,
            "שם_רחוב": "אל-מיעראג' סמ1"
        },
        {
            "_id": 24149,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3159,
            "שם_רחוב": "אל-מיעראג' סמ3"
        },
        {
            "_id": 24150,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3160,
            "שם_רחוב": "אל-מיעראג' סמ4"
        },
        {
            "_id": 24151,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3161,
            "שם_רחוב": "אל-מיעראג' סמ6"
        },
        {
            "_id": 24152,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3162,
            "שם_רחוב": "אל-מיעראג' סמ7"
        },
        {
            "_id": 24153,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5276,
            "שם_רחוב": "אל-מנג'ל"
        },
        {
            "_id": 24154,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4613,
            "שם_רחוב": "אל-מסעודי"
        },
        {
            "_id": 24155,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5378,
            "שם_רחוב": "אל-מסעודי סמ1"
        },
        {
            "_id": 24156,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5691,
            "שם_רחוב": "אל-מעאני"
        },
        {
            "_id": 24157,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3314,
            "שם_רחוב": "אל-מעהד"
        },
        {
            "_id": 24158,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3315,
            "שם_רחוב": "אל-מעהד סמ1"
        },
        {
            "_id": 24159,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3320,
            "שם_רחוב": "אל-מעהד סמ11"
        },
        {
            "_id": 24160,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3316,
            "שם_רחוב": "אל-מעהד סמ3"
        },
        {
            "_id": 24161,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3317,
            "שם_רחוב": "אל-מעהד סמ5"
        },
        {
            "_id": 24162,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3318,
            "שם_רחוב": "אל-מעהד סמ7"
        },
        {
            "_id": 24163,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3319,
            "שם_רחוב": "אל-מעהד סמ9"
        },
        {
            "_id": 24164,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5692,
            "שם_רחוב": "אל-מעני סמ1"
        },
        {
            "_id": 24165,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5177,
            "שם_רחוב": "אל-מקפה סמ10"
        },
        {
            "_id": 24166,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5176,
            "שם_רחוב": "אל-מקפה סמ1"
        },
        {
            "_id": 24167,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5178,
            "שם_רחוב": "אל-מקפה סמ12"
        },
        {
            "_id": 24168,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5179,
            "שם_רחוב": "אל-מקפה סמ14"
        },
        {
            "_id": 24169,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5181,
            "שם_רחוב": "אל-מקפה סמ5"
        },
        {
            "_id": 24170,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5184,
            "שם_רחוב": "אל-מקפה סמ6"
        },
        {
            "_id": 24171,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5180,
            "שם_רחוב": "אל-מקפה סמ16"
        },
        {
            "_id": 24172,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5186,
            "שם_רחוב": "אל-מקפה סמ8"
        },
        {
            "_id": 24173,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5187,
            "שם_רחוב": "אל-מקפה סמ9"
        },
        {
            "_id": 24174,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5669,
            "שם_רחוב": "אל-מראבטין"
        },
        {
            "_id": 24175,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5671,
            "שם_רחוב": "אל-מראבטין סמ1"
        },
        {
            "_id": 24176,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5670,
            "שם_רחוב": "אל-מראבטין סמ2"
        },
        {
            "_id": 24177,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5672,
            "שם_רחוב": "אל-מראבטין סמ3"
        },
        {
            "_id": 24178,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5673,
            "שם_רחוב": "אל-מראבטין סמ4"
        },
        {
            "_id": 24179,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5674,
            "שם_רחוב": "אל-מראבטין סמ5"
        },
        {
            "_id": 24180,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5675,
            "שם_רחוב": "אל-מראבטין סמ6"
        },
        {
            "_id": 24181,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5693,
            "שם_רחוב": "אל-מראבטין סמ7"
        },
        {
            "_id": 24182,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5821,
            "שם_רחוב": "אל-מרוג'"
        },
        {
            "_id": 24183,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5822,
            "שם_רחוב": "אל-מרוג' סמ1"
        },
        {
            "_id": 24184,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5823,
            "שם_רחוב": "אל-מרוג' סמ2"
        },
        {
            "_id": 24185,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5824,
            "שם_רחוב": "אל-מרוג' סמ3"
        },
        {
            "_id": 24186,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5825,
            "שם_רחוב": "אל-מרוג' סמ4"
        },
        {
            "_id": 24187,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5826,
            "שם_רחוב": "אל-מרוג' סמ5"
        },
        {
            "_id": 24188,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5827,
            "שם_רחוב": "אל-מרוג' סמ6"
        },
        {
            "_id": 24189,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3321,
            "שם_רחוב": "אל-מרכז אל-ג'מאהירי"
        },
        {
            "_id": 24190,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4929,
            "שם_רחוב": "אל-משאהד"
        },
        {
            "_id": 24191,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5189,
            "שם_רחוב": "אל-משאהד סמ1"
        },
        {
            "_id": 24192,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5694,
            "שם_רחוב": "אל-משאהד סמ2"
        },
        {
            "_id": 24193,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5188,
            "שם_רחוב": "אל-משאהד סמ3"
        },
        {
            "_id": 24194,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5190,
            "שם_רחוב": "אל-משאהד סמ4"
        },
        {
            "_id": 24195,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5695,
            "שם_רחוב": "אל-משאהד סמ5"
        },
        {
            "_id": 24196,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5191,
            "שם_רחוב": "אל-משאהד סמ6"
        },
        {
            "_id": 24197,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5696,
            "שם_רחוב": "אל-משאהד סמ7"
        },
        {
            "_id": 24198,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5697,
            "שם_רחוב": "אל-משאהד סמ8"
        },
        {
            "_id": 24199,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5379,
            "שם_רחוב": "אל-משרק"
        },
        {
            "_id": 24200,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5380,
            "שם_רחוב": "אל-משרק סמ1"
        },
        {
            "_id": 24201,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5381,
            "שם_רחוב": "אל-משרק סמ2"
        },
        {
            "_id": 24202,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5830,
            "שם_רחוב": "אל-נאדי"
        },
        {
            "_id": 24203,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5831,
            "שם_רחוב": "אל-נאדי סמ1"
        },
        {
            "_id": 24204,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5832,
            "שם_רחוב": "אל-נאדי סמ3"
        },
        {
            "_id": 24205,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5833,
            "שם_רחוב": "אל-נאדי סמ5"
        },
        {
            "_id": 24206,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5621,
            "שם_רחוב": "אל-נבי יונס"
        },
        {
            "_id": 24207,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5382,
            "שם_רחוב": "אל-נג'ום"
        },
        {
            "_id": 24208,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5698,
            "שם_רחוב": "אל-נובלא'"
        },
        {
            "_id": 24209,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5699,
            "שם_רחוב": "אל-נובלא' סמ2"
        },
        {
            "_id": 24210,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5840,
            "שם_רחוב": "אל-נוורס"
        },
        {
            "_id": 24211,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5834,
            "שם_רחוב": "אל-נפל"
        },
        {
            "_id": 24212,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5835,
            "שם_רחוב": "אל-נפל סמ1"
        },
        {
            "_id": 24213,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5836,
            "שם_רחוב": "אל-נפל סמ2"
        },
        {
            "_id": 24214,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5622,
            "שם_רחוב": "אל-נת'יר סמ2"
        },
        {
            "_id": 24215,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5623,
            "שם_רחוב": "אל-נת'יר סמ4"
        },
        {
            "_id": 24216,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5624,
            "שם_רחוב": "אל-נת'יר סמ6"
        },
        {
            "_id": 24217,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3163,
            "שם_רחוב": "אל-סאלחין"
        },
        {
            "_id": 24218,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3164,
            "שם_רחוב": "אל-סאלחין סמ2"
        },
        {
            "_id": 24219,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3165,
            "שם_רחוב": "אל-סאלחין סמ3"
        },
        {
            "_id": 24220,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3166,
            "שם_רחוב": "אל-סאלחין סמ5"
        },
        {
            "_id": 24221,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5704,
            "שם_רחוב": "אל-סביל"
        },
        {
            "_id": 24222,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5706,
            "שם_רחוב": "אל-סביל סמ2"
        },
        {
            "_id": 24223,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5707,
            "שם_רחוב": "אל-סביל סמ3"
        },
        {
            "_id": 24224,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5708,
            "שם_רחוב": "אל-סביל סמ4"
        },
        {
            "_id": 24225,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5709,
            "שם_רחוב": "אל-סביל סמ5"
        },
        {
            "_id": 24226,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5705,
            "שם_רחוב": "אל-סביל סמ6"
        },
        {
            "_id": 24227,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3023,
            "שם_רחוב": "אל-סהל אל-ג'דיד"
        },
        {
            "_id": 24228,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3076,
            "שם_רחוב": "אל-סהל אל-ג'דיד סמ2"
        },
        {
            "_id": 24229,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3077,
            "שם_רחוב": "אל-סהל אל-ג'דיד סמ4"
        },
        {
            "_id": 24230,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3078,
            "שם_רחוב": "אל-סהל אל-ג'דיד סמ5"
        },
        {
            "_id": 24231,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3117,
            "שם_רחוב": "אל-סהל אל-ג'דיד סמ6"
        },
        {
            "_id": 24232,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3079,
            "שם_רחוב": "אל-סהל אל-ג'דיד סמ7"
        },
        {
            "_id": 24233,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3118,
            "שם_רחוב": "אל-סהל אל-ג'דיד סמ8"
        },
        {
            "_id": 24234,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3075,
            "שם_רחוב": "אל-סהל אל-ג'דידסמ10"
        },
        {
            "_id": 24235,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4259,
            "שם_רחוב": "אל-סוויח"
        },
        {
            "_id": 24236,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3177,
            "שם_רחוב": "אל-סוויח סמ3"
        },
        {
            "_id": 24237,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5837,
            "שם_רחוב": "אל-סווסנה"
        },
        {
            "_id": 24238,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5710,
            "שם_רחוב": "אל-סולטאן"
        },
        {
            "_id": 24239,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5711,
            "שם_רחוב": "אל-סולטאן סמ1"
        },
        {
            "_id": 24240,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5712,
            "שם_רחוב": "אל-סולטאן סמ2"
        },
        {
            "_id": 24241,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5713,
            "שם_רחוב": "אל-סולטאן סמ4"
        },
        {
            "_id": 24242,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5714,
            "שם_רחוב": "אל-סולטאן סמ5"
        },
        {
            "_id": 24243,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5715,
            "שם_רחוב": "אל-סולטאן סמ6"
        },
        {
            "_id": 24244,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5472,
            "שם_רחוב": "אל-סולם"
        },
        {
            "_id": 24245,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5192,
            "שם_רחוב": "אל-סידר"
        },
        {
            "_id": 24246,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5471,
            "שם_רחוב": "אל-סלאם"
        },
        {
            "_id": 24247,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5716,
            "שם_רחוב": "אל-עדל"
        },
        {
            "_id": 24248,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5717,
            "שם_רחוב": "אל-עדל סמ1"
        },
        {
            "_id": 24249,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5718,
            "שם_רחוב": "אל-עדל סמ5"
        },
        {
            "_id": 24250,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5719,
            "שם_רחוב": "אל-עדל סמ7"
        },
        {
            "_id": 24251,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3178,
            "שם_רחוב": "אל-עהד"
        },
        {
            "_id": 24252,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3179,
            "שם_רחוב": "אל-עהד סמ2"
        },
        {
            "_id": 24253,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5383,
            "שם_רחוב": "אל-עולמא"
        },
        {
            "_id": 24254,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5720,
            "שם_רחוב": "אל-עומרי"
        },
        {
            "_id": 24255,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5721,
            "שם_רחוב": "אל-עומרי סמ3"
        },
        {
            "_id": 24256,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5841,
            "שם_רחוב": "אל-עומריין"
        },
        {
            "_id": 24257,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5842,
            "שם_רחוב": "אל-עומריין סמ1"
        },
        {
            "_id": 24258,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5843,
            "שם_רחוב": "אל-עומריין סמ2"
        },
        {
            "_id": 24259,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5844,
            "שם_רחוב": "אל-עשרק"
        },
        {
            "_id": 24260,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5845,
            "שם_רחוב": "אל-עשרק סמ1"
        },
        {
            "_id": 24261,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5846,
            "שם_רחוב": "אל-עשרק סמ3"
        },
        {
            "_id": 24262,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4722,
            "שם_רחוב": "אל-פארוק"
        },
        {
            "_id": 24263,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5847,
            "שם_רחוב": "אל-פארוק סמ1"
        },
        {
            "_id": 24264,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5848,
            "שם_רחוב": "אל-פארוק סמ2"
        },
        {
            "_id": 24265,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5849,
            "שם_רחוב": "אל-פארוק סמ3"
        },
        {
            "_id": 24266,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3184,
            "שם_רחוב": "אל-פאתח"
        },
        {
            "_id": 24267,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3322,
            "שם_רחוב": "אל-פורקאן"
        },
        {
            "_id": 24268,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5850,
            "שם_רחוב": "אל-פרסאן"
        },
        {
            "_id": 24269,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5851,
            "שם_רחוב": "אל-פרסאן סמ2"
        },
        {
            "_id": 24270,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5852,
            "שם_רחוב": "אל-פרסאן סמ3"
        },
        {
            "_id": 24271,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5853,
            "שם_רחוב": "אל-פרסאן סמ4"
        },
        {
            "_id": 24272,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5854,
            "שם_רחוב": "אל-פרסאן סמ6"
        },
        {
            "_id": 24273,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5855,
            "שם_רחוב": "אל-פרסאן סמ8"
        },
        {
            "_id": 24274,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5856,
            "שם_רחוב": "אל-צלעה"
        },
        {
            "_id": 24275,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5857,
            "שם_רחוב": "אל-צלעה סמ1"
        },
        {
            "_id": 24276,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5859,
            "שם_רחוב": "אל-צלעה סמ2"
        },
        {
            "_id": 24277,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5860,
            "שם_רחוב": "אל-צלעה סמ3"
        },
        {
            "_id": 24278,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5861,
            "שם_רחוב": "אל-צלעה סמ4"
        },
        {
            "_id": 24279,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5862,
            "שם_רחוב": "אל-צלעה סמ6"
        },
        {
            "_id": 24280,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5863,
            "שם_רחוב": "אל-צלעה סמ8"
        },
        {
            "_id": 24281,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4358,
            "שם_רחוב": "אל-צפא"
        },
        {
            "_id": 24282,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5473,
            "שם_רחוב": "אל-צפא סמ2"
        },
        {
            "_id": 24283,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5632,
            "שם_רחוב": "אל-צפא סמ4"
        },
        {
            "_id": 24284,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5722,
            "שם_רחוב": "אל-קאדי אל-פאדל"
        },
        {
            "_id": 24285,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5600,
            "שם_רחוב": "אל-קאעה"
        },
        {
            "_id": 24286,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5474,
            "שם_רחוב": "אל-קאעה סמ1"
        },
        {
            "_id": 24287,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3080,
            "שם_רחוב": "אל-קימה"
        },
        {
            "_id": 24288,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4383,
            "שם_רחוב": "אל-קלעה"
        },
        {
            "_id": 24289,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5633,
            "שם_רחוב": "אל-קצר"
        },
        {
            "_id": 24290,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4377,
            "שם_רחוב": "אל-קש"
        },
        {
            "_id": 24291,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5635,
            "שם_רחוב": "אל-ראביא"
        },
        {
            "_id": 24292,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5636,
            "שם_רחוב": "אל-ראביא סמ2"
        },
        {
            "_id": 24293,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3241,
            "שם_רחוב": "אל-רוא'יה"
        },
        {
            "_id": 24294,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3242,
            "שם_רחוב": "אל-רוא'יה סמ1"
        },
        {
            "_id": 24295,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3243,
            "שם_רחוב": "אל-רוא'יה סמ3"
        },
        {
            "_id": 24296,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3244,
            "שם_רחוב": "אל-רוא'יה סמ4"
        },
        {
            "_id": 24297,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3245,
            "שם_רחוב": "אל-רוא'יה סמ5"
        },
        {
            "_id": 24298,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4366,
            "שם_רחוב": "אל-רחמה"
        },
        {
            "_id": 24299,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5638,
            "שם_רחוב": "אל-רחמה סמ2"
        },
        {
            "_id": 24300,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5483,
            "שם_רחוב": "אל-ריחאן"
        },
        {
            "_id": 24301,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5193,
            "שם_רחוב": "אל-רסם"
        },
        {
            "_id": 24302,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5723,
            "שם_רחוב": "אל-רסם סמ2"
        },
        {
            "_id": 24303,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5724,
            "שם_רחוב": "אל-רסם סמ4"
        },
        {
            "_id": 24304,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5875,
            "שם_רחוב": "אל-שהד"
        },
        {
            "_id": 24305,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5876,
            "שם_רחוב": "אל-שהד סמ1"
        },
        {
            "_id": 24306,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4150,
            "שם_רחוב": "אל-שיאח"
        },
        {
            "_id": 24307,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5583,
            "שם_רחוב": "אל-שיח' ענבר"
        },
        {
            "_id": 24308,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3107,
            "שם_רחוב": "אל-שיח' ענבר סמ10"
        },
        {
            "_id": 24309,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3111,
            "שם_רחוב": "אל-שיח' ענבר סמ11"
        },
        {
            "_id": 24310,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3104,
            "שם_רחוב": "אל-שיח' ענבר סמ2"
        },
        {
            "_id": 24311,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3108,
            "שם_רחוב": "אל-שיח' ענבר סמ12"
        },
        {
            "_id": 24312,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3105,
            "שם_רחוב": "אל-שיח' ענבר סמ6"
        },
        {
            "_id": 24313,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3109,
            "שם_רחוב": "אל-שיח' ענבר סמ7"
        },
        {
            "_id": 24314,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3106,
            "שם_רחוב": "אל-שיח' ענבר סמ8"
        },
        {
            "_id": 24315,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3110,
            "שם_רחוב": "אל-שיח' ענבר סמ9"
        },
        {
            "_id": 24316,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5864,
            "שם_רחוב": "אל-ת'ורי"
        },
        {
            "_id": 24317,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5873,
            "שם_רחוב": "אל-ת'ורי סמ10"
        },
        {
            "_id": 24318,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5865,
            "שם_רחוב": "אל-ת'ורי סמ2"
        },
        {
            "_id": 24319,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5866,
            "שם_רחוב": "אל-ת'ורי סמ3"
        },
        {
            "_id": 24320,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5867,
            "שם_רחוב": "אל-ת'ורי סמ4"
        },
        {
            "_id": 24321,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5868,
            "שם_רחוב": "אל-ת'ורי סמ5"
        },
        {
            "_id": 24322,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5869,
            "שם_רחוב": "אל-ת'ורי סמ6"
        },
        {
            "_id": 24323,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5870,
            "שם_רחוב": "אל-ת'ורי סמ7"
        },
        {
            "_id": 24324,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5871,
            "שם_רחוב": "אל-ת'ורי סמ8"
        },
        {
            "_id": 24325,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5872,
            "שם_רחוב": "אל-ת'ורי סמ9"
        },
        {
            "_id": 24326,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5484,
            "שם_רחוב": "אל-תווחיד סמ1"
        },
        {
            "_id": 24327,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5485,
            "שם_רחוב": "אל-תווחיד סמ2"
        },
        {
            "_id": 24328,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4891,
            "שם_רחוב": "אל-תין אל-ע'רבי"
        },
        {
            "_id": 24329,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2346,
            "שם_רחוב": "אל-תסאמח"
        },
        {
            "_id": 24330,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3247,
            "שם_רחוב": "אל-תסאמח סמ2"
        },
        {
            "_id": 24331,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3248,
            "שם_רחוב": "אל-תסאמח סמ4"
        },
        {
            "_id": 24332,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5725,
            "שם_רחוב": "אל-תראב"
        },
        {
            "_id": 24333,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4856,
            "שם_רחוב": "אלאימאן"
        },
        {
            "_id": 24334,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5125,
            "שם_רחוב": "אלאסראא"
        },
        {
            "_id": 24335,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5479,
            "שם_רחוב": "אלבו יוסף"
        },
        {
            "_id": 24336,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4857,
            "שם_רחוב": "אלביאן"
        },
        {
            "_id": 24337,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4858,
            "שם_רחוב": "אלבירוני"
        },
        {
            "_id": 24338,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5007,
            "שם_רחוב": "אלבירוני סמ10"
        },
        {
            "_id": 24339,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5100,
            "שם_רחוב": "אלבירוני סמ4"
        },
        {
            "_id": 24340,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4978,
            "שם_רחוב": "אלבירוני סמ8"
        },
        {
            "_id": 24341,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1731,
            "שם_רחוב": "אלבק חנוך"
        },
        {
            "_id": 24342,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 346,
            "שם_רחוב": "אלגזי"
        },
        {
            "_id": 24343,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4859,
            "שם_רחוב": "אלגניד"
        },
        {
            "_id": 24344,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4864,
            "שם_רחוב": "אלהג'רה"
        },
        {
            "_id": 24345,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5000,
            "שם_רחוב": "אלהיג'רה סמ1"
        },
        {
            "_id": 24346,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4376,
            "שם_רחוב": "אלוני שחר"
        },
        {
            "_id": 24347,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1168,
            "שם_רחוב": "אלוף יוחאי בן נון"
        },
        {
            "_id": 24348,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1125,
            "שם_רחוב": "אלוף שאלתיאל"
        },
        {
            "_id": 24349,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1425,
            "שם_רחוב": "אלוף שמחוני"
        },
        {
            "_id": 24350,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4865,
            "שם_רחוב": "אלזבייר בן אלעוואם"
        },
        {
            "_id": 24351,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1120,
            "שם_רחוב": "אלזה"
        },
        {
            "_id": 24352,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4866,
            "שם_רחוב": "אלזיתונה"
        },
        {
            "_id": 24353,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5166,
            "שם_רחוב": "אלזיתונה סמ10"
        },
        {
            "_id": 24354,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5167,
            "שם_רחוב": "אלזיתונה סמ11"
        },
        {
            "_id": 24355,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5168,
            "שם_רחוב": "אלזיתונה סמ12"
        },
        {
            "_id": 24356,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5095,
            "שם_רחוב": "אלזיתונה סמ5"
        },
        {
            "_id": 24357,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5169,
            "שם_רחוב": "אלזיתונה סמ7"
        },
        {
            "_id": 24358,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5363,
            "שם_רחוב": "אלזיתונה סמ8"
        },
        {
            "_id": 24359,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5170,
            "שם_רחוב": "אלזיתונה סמ9"
        },
        {
            "_id": 24360,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5093,
            "שם_רחוב": "אלח'יר"
        },
        {
            "_id": 24361,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3357,
            "שם_רחוב": "אלחארה אלווסטא"
        },
        {
            "_id": 24362,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3358,
            "שם_רחוב": "אלחארה אלווסטא סמ2"
        },
        {
            "_id": 24363,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3359,
            "שם_רחוב": "אלחארה אלווסטא סמ4"
        },
        {
            "_id": 24364,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3360,
            "שם_רחוב": "אלחארה אלווסטא סמ8"
        },
        {
            "_id": 24365,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4867,
            "שם_רחוב": "אלחדיביה"
        },
        {
            "_id": 24366,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5064,
            "שם_רחוב": "אלחדיביה סמ3"
        },
        {
            "_id": 24367,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5066,
            "שם_רחוב": "אלחדיביה סמ5"
        },
        {
            "_id": 24368,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 811,
            "שם_רחוב": "אלחריזי"
        },
        {
            "_id": 24369,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4774,
            "שם_רחוב": "אלטמן אריה"
        },
        {
            "_id": 24370,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1676,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 24371,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 928,
            "שם_רחוב": "אליאב יעקב"
        },
        {
            "_id": 24372,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3017,
            "שם_רחוב": "אליאך יוסף"
        },
        {
            "_id": 24373,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1844,
            "שם_רחוב": "אליאל דרור"
        },
        {
            "_id": 24374,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1519,
            "שם_רחוב": "אליאשברג"
        },
        {
            "_id": 24375,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1600,
            "שם_רחוב": "אליהו איקו חוצ'ה"
        },
        {
            "_id": 24376,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 615,
            "שם_רחוב": "אליהו יעקב בנאי"
        },
        {
            "_id": 24377,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1529,
            "שם_רחוב": "אליוט"
        },
        {
            "_id": 24378,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4488,
            "שם_רחוב": "אלימלך מלז'ינסק"
        },
        {
            "_id": 24379,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1318,
            "שם_רחוב": "אליעזר הגדול"
        },
        {
            "_id": 24380,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 270,
            "שם_רחוב": "אליעזר שילוני"
        },
        {
            "_id": 24381,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 400,
            "שם_רחוב": "אליעזרוב"
        },
        {
            "_id": 24382,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 234,
            "שם_רחוב": "אליפז"
        },
        {
            "_id": 24383,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4836,
            "שם_רחוב": "אליצור יהודה"
        },
        {
            "_id": 24384,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4868,
            "שם_רחוב": "אלירמוך"
        },
        {
            "_id": 24385,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 212,
            "שם_רחוב": "אלישיב"
        },
        {
            "_id": 24386,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 510,
            "שם_רחוב": "אלישע"
        },
        {
            "_id": 24387,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 418,
            "שם_רחוב": "אלישר"
        },
        {
            "_id": 24388,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4869,
            "שם_רחוב": "אלכארמה"
        },
        {
            "_id": 24389,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4691,
            "שם_רחוב": "אלכסנדר אל ח'ורי"
        },
        {
            "_id": 24390,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1311,
            "שם_רחוב": "אלכסנדריון"
        },
        {
            "_id": 24391,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5195,
            "שם_רחוב": "אלמדינה מונורה"
        },
        {
            "_id": 24392,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5369,
            "שם_רחוב": "אלמדינה מונורה סמ5"
        },
        {
            "_id": 24393,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5686,
            "שם_רחוב": "אלמדינה מונורה סמ15"
        },
        {
            "_id": 24394,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5370,
            "שם_רחוב": "אלמדינה מונורה סמ6"
        },
        {
            "_id": 24395,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5371,
            "שם_רחוב": "אלמדינה מונורה סמ7"
        },
        {
            "_id": 24396,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5372,
            "שם_רחוב": "אלמדינה מונורה סמ8"
        },
        {
            "_id": 24397,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5685,
            "שם_רחוב": "אלמדינה מונורה סמ9"
        },
        {
            "_id": 24398,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4576,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 24399,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3309,
            "שם_רחוב": "אלמוח'תאר סמ1"
        },
        {
            "_id": 24400,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4872,
            "שם_רחוב": "אלמות'ינא בן חארת'א"
        },
        {
            "_id": 24401,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4873,
            "שם_רחוב": "אלמזדלפה"
        },
        {
            "_id": 24402,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1367,
            "שם_רחוב": "אלמליח אברהם"
        },
        {
            "_id": 24403,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4014,
            "שם_רחוב": "אלמלכ אלמועט'ם עיסא"
        },
        {
            "_id": 24404,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4874,
            "שם_רחוב": "אלמסק"
        },
        {
            "_id": 24405,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4875,
            "שם_רחוב": "אלמרוחה"
        },
        {
            "_id": 24406,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5075,
            "שם_רחוב": "אלמרוחה סמ10"
        },
        {
            "_id": 24407,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5076,
            "שם_רחוב": "אלמרוחה סמ11"
        },
        {
            "_id": 24408,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5077,
            "שם_רחוב": "אלמרוחה סמ12"
        },
        {
            "_id": 24409,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5071,
            "שם_רחוב": "אלמרוחה סמ3"
        },
        {
            "_id": 24410,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5078,
            "שם_רחוב": "אלמרוחה סמ13"
        },
        {
            "_id": 24411,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5079,
            "שם_רחוב": "אלמרוחה סמ14"
        },
        {
            "_id": 24412,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5070,
            "שם_רחוב": "אלמרוחה סמ5"
        },
        {
            "_id": 24413,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5080,
            "שם_רחוב": "אלמרוחה סמ15"
        },
        {
            "_id": 24414,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5072,
            "שם_רחוב": "אלמרוחה סמ7"
        },
        {
            "_id": 24415,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5073,
            "שם_רחוב": "אלמרוחה סמ8"
        },
        {
            "_id": 24416,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5074,
            "שם_רחוב": "אלמרוחה סמ9"
        },
        {
            "_id": 24417,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4876,
            "שם_רחוב": "אלמרכז"
        },
        {
            "_id": 24418,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4877,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 24419,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5090,
            "שם_רחוב": "אלנור סמ4"
        },
        {
            "_id": 24420,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4878,
            "שם_רחוב": "אלסבר"
        },
        {
            "_id": 24421,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4879,
            "שם_רחוב": "אלסדאקה"
        },
        {
            "_id": 24422,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5033,
            "שם_רחוב": "אלסדאקה סמ10"
        },
        {
            "_id": 24423,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5034,
            "שם_רחוב": "אלסדאקה סמ12"
        },
        {
            "_id": 24424,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5035,
            "שם_רחוב": "אלסדאקה סמ5"
        },
        {
            "_id": 24425,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3120,
            "שם_רחוב": "אלסהל אלאח'דר"
        },
        {
            "_id": 24426,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3125,
            "שם_רחוב": "אלסהל אלאח'דר סמ10"
        },
        {
            "_id": 24427,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3121,
            "שם_רחוב": "אלסהל אלאח'דר סמ2"
        },
        {
            "_id": 24428,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3126,
            "שם_רחוב": "אלסהל אלאח'דר סמ12"
        },
        {
            "_id": 24429,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3122,
            "שם_רחוב": "אלסהל אלאח'דר סמ4"
        },
        {
            "_id": 24430,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3123,
            "שם_רחוב": "אלסהל אלאח'דר סמ6"
        },
        {
            "_id": 24431,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3124,
            "שם_רחוב": "אלסהל אלאח'דר סמ7"
        },
        {
            "_id": 24432,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4880,
            "שם_רחוב": "אלסנאבל"
        },
        {
            "_id": 24433,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4881,
            "שם_רחוב": "אלסעאדה"
        },
        {
            "_id": 24434,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4648,
            "שם_רחוב": "אלעזר דוד"
        },
        {
            "_id": 24435,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1323,
            "שם_רחוב": "אלעזר המודעי"
        },
        {
            "_id": 24436,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 339,
            "שם_רחוב": "אלעזר המכבי"
        },
        {
            "_id": 24437,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5120,
            "שם_רחוב": "אלעלם"
        },
        {
            "_id": 24438,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5121,
            "שם_רחוב": "אלעלם סמ3"
        },
        {
            "_id": 24439,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5054,
            "שם_רחוב": "אלעקבה"
        },
        {
            "_id": 24440,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5059,
            "שם_רחוב": "אלעקבה סמ2"
        },
        {
            "_id": 24441,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5056,
            "שם_רחוב": "אלעקבה סמ5"
        },
        {
            "_id": 24442,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5055,
            "שם_רחוב": "אלעקבה סמ7"
        },
        {
            "_id": 24443,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5057,
            "שם_רחוב": "אלעקבה סמ8"
        },
        {
            "_id": 24444,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלעשה"
        },
        {
            "_id": 24445,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4882,
            "שם_רחוב": "אלפג'ר"
        },
        {
            "_id": 24446,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 332,
            "שם_רחוב": "אלפנדרי"
        },
        {
            "_id": 24447,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 812,
            "שם_רחוב": "אלפסי"
        },
        {
            "_id": 24448,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4883,
            "שם_רחוב": "אלפרדוס"
        },
        {
            "_id": 24449,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 931,
            "שם_רחוב": "אלקבץ"
        },
        {
            "_id": 24450,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4884,
            "שם_רחוב": "אלקות'ר"
        },
        {
            "_id": 24451,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4331,
            "שם_רחוב": "אלקחי מרדכי"
        },
        {
            "_id": 24452,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1507,
            "שם_רחוב": "אלקלעי"
        },
        {
            "_id": 24453,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 315,
            "שם_רחוב": "אלקנה"
        },
        {
            "_id": 24454,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4885,
            "שם_רחוב": "אלקעקאע"
        },
        {
            "_id": 24455,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4602,
            "שם_רחוב": "אלראזי"
        },
        {
            "_id": 24456,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4886,
            "שם_רחוב": "אלרדואן"
        },
        {
            "_id": 24457,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5092,
            "שם_רחוב": "אלרדואן סמ3"
        },
        {
            "_id": 24458,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1628,
            "שם_רחוב": "אלרואי דוד"
        },
        {
            "_id": 24459,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4887,
            "שם_רחוב": "אלרסאלה"
        },
        {
            "_id": 24460,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5126,
            "שם_רחוב": "אלרסאלה סמ3"
        },
        {
            "_id": 24461,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 368,
            "שם_רחוב": "אלשיך"
        },
        {
            "_id": 24462,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4889,
            "שם_רחוב": "אלשימאא"
        },
        {
            "_id": 24463,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5726,
            "שם_רחוב": "אלתון"
        },
        {
            "_id": 24464,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5727,
            "שם_רחוב": "אלתון סמ2"
        },
        {
            "_id": 24465,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5728,
            "שם_רחוב": "אלתון סמ4"
        },
        {
            "_id": 24466,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5729,
            "שם_רחוב": "אלתון סמ5"
        },
        {
            "_id": 24467,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5730,
            "שם_רחוב": "אלתון סמ7"
        },
        {
            "_id": 24468,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4890,
            "שם_רחוב": "אלתין"
        },
        {
            "_id": 24469,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5143,
            "שם_רחוב": "אלתקווא"
        },
        {
            "_id": 24470,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5480,
            "שם_רחוב": "אם הבנים"
        },
        {
            "_id": 24471,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1464,
            "שם_רחוב": "אמא שלום"
        },
        {
            "_id": 24472,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5423,
            "שם_רחוב": "אמאם אבו חניפה סמ1"
        },
        {
            "_id": 24473,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4668,
            "שם_רחוב": "אמאם אלבוח'ארי"
        },
        {
            "_id": 24474,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5364,
            "שם_רחוב": "אמאם אלבוח'ארי סמ3"
        },
        {
            "_id": 24475,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אמדורסקי ירחמיאל"
        },
        {
            "_id": 24476,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1530,
            "שם_רחוב": "אמיל בוטה"
        },
        {
            "_id": 24477,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1653,
            "שם_רחוב": "אמיל זולא"
        },
        {
            "_id": 24478,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1033,
            "שם_רחוב": "אמיר"
        },
        {
            "_id": 24479,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3226,
            "שם_רחוב": "אמיר דרורי"
        },
        {
            "_id": 24480,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 487,
            "שם_רחוב": "אמנון ליפקין-שחק"
        },
        {
            "_id": 24481,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1633,
            "שם_רחוב": "אמציה"
        },
        {
            "_id": 24482,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 986,
            "שם_רחוב": "אמרי בינה"
        },
        {
            "_id": 24483,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3034,
            "שם_רחוב": "אמרי חיים"
        },
        {
            "_id": 24484,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 284,
            "שם_רחוב": "אמרי נועם"
        },
        {
            "_id": 24485,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4780,
            "שם_רחוב": "אמרי קלמן"
        },
        {
            "_id": 24486,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3275,
            "שם_רחוב": "אמת המים"
        },
        {
            "_id": 24487,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אמת ליעקב"
        },
        {
            "_id": 24488,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5410,
            "שם_רחוב": "אנוסי משהד"
        },
        {
            "_id": 24489,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4720,
            "שם_רחוב": "אנטון שוקרי לורנס"
        },
        {
            "_id": 24490,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5801,
            "שם_רחוב": "אנטוניה"
        },
        {
            "_id": 24491,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 742,
            "שם_רחוב": "אנטוקולסקי מרדכי"
        },
        {
            "_id": 24492,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1316,
            "שם_רחוב": "אנטיגנוס"
        },
        {
            "_id": 24493,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1206,
            "שם_רחוב": "אנילביץ"
        },
        {
            "_id": 24494,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4734,
            "שם_רחוב": "אנסארי"
        },
        {
            "_id": 24495,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4625,
            "שם_רחוב": "אנסארי חסן בן ת'אבת"
        },
        {
            "_id": 24496,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1635,
            "שם_רחוב": "אסא"
        },
        {
            "_id": 24497,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4248,
            "שם_רחוב": "אסבעקאת"
        },
        {
            "_id": 24498,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5001,
            "שם_רחוב": "אסטורה"
        },
        {
            "_id": 24499,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4454,
            "שם_רחוב": "אסירי ציון"
        },
        {
            "_id": 24500,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 267,
            "שם_רחוב": "אסתר בר חיים"
        },
        {
            "_id": 24501,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1790,
            "שם_רחוב": "אסתר המלכה"
        },
        {
            "_id": 24502,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4888,
            "שם_רחוב": "אע'ביתה"
        },
        {
            "_id": 24503,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5254,
            "שם_רחוב": "אע'ביתה סמ2"
        },
        {
            "_id": 24504,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5731,
            "שם_רחוב": "אע'זייל"
        },
        {
            "_id": 24505,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5732,
            "שם_רחוב": "אע'זייל סמ1"
        },
        {
            "_id": 24506,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5733,
            "שם_רחוב": "אע'זייל סמ3"
        },
        {
            "_id": 24507,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5734,
            "שם_רחוב": "אע'זייל סמ6"
        },
        {
            "_id": 24508,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 287,
            "שם_רחוב": "אעלה בתמר"
        },
        {
            "_id": 24509,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 821,
            "שם_רחוב": "אפודי"
        },
        {
            "_id": 24510,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1772,
            "שם_רחוב": "אפרים"
        },
        {
            "_id": 24511,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4178,
            "שם_רחוב": "אפרסמון"
        },
        {
            "_id": 24512,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1735,
            "שם_רחוב": "אפרתה"
        },
        {
            "_id": 24513,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1230,
            "שם_רחוב": "אפשטיין יעקב נחום"
        },
        {
            "_id": 24514,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1244,
            "שם_רחוב": "אפשטיין יצחק"
        },
        {
            "_id": 24515,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4315,
            "שם_רחוב": "אצל"
        },
        {
            "_id": 24516,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 324,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 24517,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 857,
            "שם_רחוב": "ארבעת המינים"
        },
        {
            "_id": 24518,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1440,
            "שם_רחוב": "ארגוב אליעזר"
        },
        {
            "_id": 24519,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4119,
            "שם_רחוב": "ארד א סמר"
        },
        {
            "_id": 24520,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4206,
            "שם_רחוב": "ארד אל עקבה"
        },
        {
            "_id": 24521,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1811,
            "שם_רחוב": "ארונה היבוסי"
        },
        {
            "_id": 24522,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 261,
            "שם_רחוב": "ארזי הבירה"
        },
        {
            "_id": 24523,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4812,
            "שם_רחוב": "ארזי ראובן"
        },
        {
            "_id": 24524,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4489,
            "שם_רחוב": "ארי במסתרים"
        },
        {
            "_id": 24525,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 917,
            "שם_רחוב": "אריאל"
        },
        {
            "_id": 24526,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1900,
            "שם_רחוב": "אריאל שרון"
        },
        {
            "_id": 24527,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 419,
            "שם_רחוב": "אריסטובלוס"
        },
        {
            "_id": 24528,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1339,
            "שם_רחוב": "אריסטיאס"
        },
        {
            "_id": 24529,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 813,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 24530,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1188,
            "שם_רחוב": "ארנון יעקב"
        },
        {
            "_id": 24531,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6784,
            "שם_רחוב": "ארנונה הצעירה"
        },
        {
            "_id": 24532,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1392,
            "שם_רחוב": "ארסט אברהם"
        },
        {
            "_id": 24533,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 236,
            "שם_רחוב": "ארץ חפץ"
        },
        {
            "_id": 24534,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5411,
            "שם_רחוב": "ארציאלי חוה"
        },
        {
            "_id": 24535,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4090,
            "שם_רחוב": "אררט"
        },
        {
            "_id": 24536,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4455,
            "שם_רחוב": "אשכולי אהרון"
        },
        {
            "_id": 24537,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אשכנזי"
        },
        {
            "_id": 24538,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1789,
            "שם_רחוב": "אשר"
        },
        {
            "_id": 24539,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 366,
            "שם_רחוב": "אשתורי הפרחי"
        },
        {
            "_id": 24540,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 402,
            "שם_רחוב": "אתיופיה"
        },
        {
            "_id": 24541,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "ב אונגר מאה שערים"
        },
        {
            "_id": 24542,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4109,
            "שם_רחוב": "ב חנינה קלנדיה"
        },
        {
            "_id": 24543,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 518,
            "שם_רחוב": "ב טנוס מרכז מסחרי"
        },
        {
            "_id": 24544,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "ב מילנר שכ ב"
        },
        {
            "_id": 24545,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "ב ניטין מאה שערים"
        },
        {
            "_id": 24546,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "ב סטואר מאה שערים"
        },
        {
            "_id": 24547,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "ב רוט מאה שערים"
        },
        {
            "_id": 24548,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4153,
            "שם_רחוב": "באב א זהרה"
        },
        {
            "_id": 24549,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4253,
            "שם_רחוב": "באב א מגרבה"
        },
        {
            "_id": 24550,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4173,
            "שם_רחוב": "באב א סיפה"
        },
        {
            "_id": 24551,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4032,
            "שם_רחוב": "באב אל חדיד"
        },
        {
            "_id": 24552,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4003,
            "שם_רחוב": "באב אל עמוד"
        },
        {
            "_id": 24553,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1893,
            "שם_רחוב": "באום שלמה"
        },
        {
            "_id": 24554,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4477,
            "שם_רחוב": "באזוב דוד"
        },
        {
            "_id": 24555,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1665,
            "שם_רחוב": "באכר זאב"
        },
        {
            "_id": 24556,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1054,
            "שם_רחוב": "באר מים חיים"
        },
        {
            "_id": 24557,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 654,
            "שם_רחוב": "באר שבע"
        },
        {
            "_id": 24558,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1678,
            "שם_רחוב": "בארות יצחק"
        },
        {
            "_id": 24559,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 447,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 24560,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4005,
            "שם_רחוב": "בב חוטה"
        },
        {
            "_id": 24561,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4844,
            "שם_רחוב": "בבלי חנה"
        },
        {
            "_id": 24562,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1671,
            "שם_רחוב": "בג'יו חיים"
        },
        {
            "_id": 24563,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5602,
            "שם_רחוב": "בדור"
        },
        {
            "_id": 24564,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4740,
            "שם_רחוב": "בדר"
        },
        {
            "_id": 24565,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5069,
            "שם_רחוב": "בדר סמ4"
        },
        {
            "_id": 24566,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4198,
            "שם_רחוב": "בהא אל-דין"
        },
        {
            "_id": 24567,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 180,
            "שם_רחוב": "בהרן שלמה זלמן"
        },
        {
            "_id": 24568,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4447,
            "שם_רחוב": "בובליק גדליה"
        },
        {
            "_id": 24569,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4359,
            "שם_רחוב": "בובר מרטין"
        },
        {
            "_id": 24570,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1221,
            "שם_רחוב": "בודנהימר"
        },
        {
            "_id": 24571,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1869,
            "שם_רחוב": "בוזגלו אשריאל"
        },
        {
            "_id": 24572,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1237,
            "שם_רחוב": "בוליביה"
        },
        {
            "_id": 24573,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1882,
            "שם_רחוב": "בונהונטורה אנצ'ו"
        },
        {
            "_id": 24574,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4504,
            "שם_רחוב": "בוני החומה"
        },
        {
            "_id": 24575,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1619,
            "שם_רחוב": "בוסתנאי"
        },
        {
            "_id": 24576,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1779,
            "שם_רחוב": "בועז"
        },
        {
            "_id": 24577,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 288,
            "שם_רחוב": "בועז הבבלי"
        },
        {
            "_id": 24578,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4951,
            "שם_רחוב": "בוקסבאום מרדכי"
        },
        {
            "_id": 24579,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4260,
            "שם_רחוב": "בורג אל פדי"
        },
        {
            "_id": 24580,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4011,
            "שם_רחוב": "בורג לקלק"
        },
        {
            "_id": 24581,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1205,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 24582,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1116,
            "שם_רחוב": "בורלא יהודה"
        },
        {
            "_id": 24583,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4562,
            "שם_רחוב": "בושם"
        },
        {
            "_id": 24584,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1000,
            "שם_רחוב": "בזל"
        },
        {
            "_id": 24585,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1132,
            "שם_רחוב": "בזק בצלאל"
        },
        {
            "_id": 24586,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1850,
            "שם_רחוב": "בטיש שמעון"
        },
        {
            "_id": 24587,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4204,
            "שם_רחוב": "בטן אל-הווא"
        },
        {
            "_id": 24588,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3183,
            "שם_רחוב": "בטן אל-הווא סמ10"
        },
        {
            "_id": 24589,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3180,
            "שם_רחוב": "בטן אל-הווא סמ3"
        },
        {
            "_id": 24590,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3366,
            "שם_רחוב": "בטן אל-הווא סמ4"
        },
        {
            "_id": 24591,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3367,
            "שם_רחוב": "בטן אל-הווא סמ6"
        },
        {
            "_id": 24592,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3181,
            "שם_רחוב": "בטן אל-הווא סמ7"
        },
        {
            "_id": 24593,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3182,
            "שם_רחוב": "בטן אל-הווא סמ8"
        },
        {
            "_id": 24594,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 731,
            "שם_רחוב": "בי רב יעקב"
        },
        {
            "_id": 24595,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4705,
            "שם_רחוב": "ביח חמדת הרים"
        },
        {
            "_id": 24596,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 953,
            "שם_רחוב": "ביח עזרת נשים"
        },
        {
            "_id": 24597,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1920,
            "שם_רחוב": "ביח שערי צדק"
        },
        {
            "_id": 24598,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1459,
            "שם_רחוב": "ביס למדעים ואומנוי"
        },
        {
            "_id": 24599,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1035,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 24600,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 471,
            "שם_רחוב": "ביאנקיני"
        },
        {
            "_id": 24601,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 685,
            "שם_רחוב": "ביבאס"
        },
        {
            "_id": 24602,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4427,
            "שם_רחוב": "ביבארס"
        },
        {
            "_id": 24603,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5508,
            "שם_רחוב": "בידון"
        },
        {
            "_id": 24604,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2008,
            "שם_רחוב": "ביח אלין"
        },
        {
            "_id": 24605,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 892,
            "שם_רחוב": "ביח כפר שאול"
        },
        {
            "_id": 24606,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4136,
            "שם_רחוב": "ביח סנט גון"
        },
        {
            "_id": 24607,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1121,
            "שם_רחוב": "בייט שמואל"
        },
        {
            "_id": 24608,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5478,
            "שם_רחוב": "בייליס מנדל"
        },
        {
            "_id": 24609,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1606,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 24610,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4904,
            "שם_רחוב": "בינת יששכר"
        },
        {
            "_id": 24611,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4673,
            "שם_רחוב": "ביסאן"
        },
        {
            "_id": 24612,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 478,
            "שם_רחוב": "ביצ'אצ'ו אברהם"
        },
        {
            "_id": 24613,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1254,
            "שם_רחוב": "ביצור יהושע"
        },
        {
            "_id": 24614,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5640,
            "שם_רחוב": "ביר אבו ח'שבה"
        },
        {
            "_id": 24615,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5641,
            "שם_רחוב": "ביר אבו ח'שבה סמ1"
        },
        {
            "_id": 24616,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5642,
            "שם_רחוב": "ביר אבו ח'שבה סמ3"
        },
        {
            "_id": 24617,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4256,
            "שם_רחוב": "ביר איוב"
        },
        {
            "_id": 24618,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5588,
            "שם_רחוב": "ביר אל אסביל"
        },
        {
            "_id": 24619,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4743,
            "שם_רחוב": "ביר אל סביל"
        },
        {
            "_id": 24620,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5487,
            "שם_רחוב": "ביר אל-חמראה'"
        },
        {
            "_id": 24621,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4892,
            "שם_רחוב": "ביר אל-כורום"
        },
        {
            "_id": 24622,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5198,
            "שם_רחוב": "ביר אל-כורום סמ1"
        },
        {
            "_id": 24623,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5202,
            "שם_רחוב": "ביר אל-כורום סמ2"
        },
        {
            "_id": 24624,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5199,
            "שם_רחוב": "ביר אל-כורום סמ3"
        },
        {
            "_id": 24625,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5200,
            "שם_רחוב": "ביר אל-כורום סמ5"
        },
        {
            "_id": 24626,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4893,
            "שם_רחוב": "ביר אל-עקבה"
        },
        {
            "_id": 24627,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5205,
            "שם_רחוב": "ביר אל-עקבה סמ1"
        },
        {
            "_id": 24628,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4894,
            "שם_רחוב": "ביר אלזחלוק"
        },
        {
            "_id": 24629,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5204,
            "שם_רחוב": "ביר אלזחלוק סמ2"
        },
        {
            "_id": 24630,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4226,
            "שם_רחוב": "ביר מנסור"
        },
        {
            "_id": 24631,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4715,
            "שם_רחוב": "ביר סאלם"
        },
        {
            "_id": 24632,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5338,
            "שם_רחוב": "ביר סאלם סמ11"
        },
        {
            "_id": 24633,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5333,
            "שם_רחוב": "ביר סאלם סמ4"
        },
        {
            "_id": 24634,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5334,
            "שם_רחוב": "ביר סאלם סמ6"
        },
        {
            "_id": 24635,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5335,
            "שם_רחוב": "ביר סאלם סמ7"
        },
        {
            "_id": 24636,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5336,
            "שם_רחוב": "ביר סאלם סמ8"
        },
        {
            "_id": 24637,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5337,
            "שם_רחוב": "ביר סאלם סמ9"
        },
        {
            "_id": 24638,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4212,
            "שם_רחוב": "ביר עונה"
        },
        {
            "_id": 24639,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4707,
            "שם_רחוב": "ביר עמדך"
        },
        {
            "_id": 24640,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1253,
            "שם_רחוב": "בירן שושנה"
        },
        {
            "_id": 24641,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 356,
            "שם_רחוב": "בירנבאום"
        },
        {
            "_id": 24642,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4367,
            "שם_רחוב": "בית אל"
        },
        {
            "_id": 24643,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1604,
            "שם_רחוב": "בית אשל"
        },
        {
            "_id": 24644,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4215,
            "שם_רחוב": "בית גאלה"
        },
        {
            "_id": 24645,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1283,
            "שם_רחוב": "בית גיורא"
        },
        {
            "_id": 24646,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3276,
            "שם_רחוב": "בית האבן"
        },
        {
            "_id": 24647,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 895,
            "שם_רחוב": "בית הדפוס"
        },
        {
            "_id": 24648,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5590,
            "שם_רחוב": "בית הטירה"
        },
        {
            "_id": 24649,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1859,
            "שם_רחוב": "בית היוצר"
        },
        {
            "_id": 24650,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1036,
            "שם_רחוב": "בית הכרם"
        },
        {
            "_id": 24651,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5592,
            "שם_רחוב": "בית הכרמל"
        },
        {
            "_id": 24652,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 527,
            "שם_רחוב": "בית הלחמי"
        },
        {
            "_id": 24653,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5594,
            "שם_רחוב": "בית המעיין"
        },
        {
            "_id": 24654,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1750,
            "שם_רחוב": "בית הערבה"
        },
        {
            "_id": 24655,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5591,
            "שם_רחוב": "בית הצופה"
        },
        {
            "_id": 24656,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5593,
            "שם_רחוב": "בית הקרן"
        },
        {
            "_id": 24657,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5595,
            "שם_רחוב": "בית הקשתות"
        },
        {
            "_id": 24658,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4507,
            "שם_רחוב": "בית השואבה"
        },
        {
            "_id": 24659,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1058,
            "שם_רחוב": "בית וגן"
        },
        {
            "_id": 24660,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1828,
            "שם_רחוב": "בית חגלה"
        },
        {
            "_id": 24661,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4106,
            "שם_רחוב": "בית חנינא החדשה"
        },
        {
            "_id": 24662,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 399,
            "שם_רחוב": "בית טובי העיר"
        },
        {
            "_id": 24663,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 622,
            "שם_רחוב": "בית יעקב"
        },
        {
            "_id": 24664,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2202,
            "שם_רחוב": "בית יצחק"
        },
        {
            "_id": 24665,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "בית ישראל"
        },
        {
            "_id": 24666,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4403,
            "שם_רחוב": "בית פאג'י"
        },
        {
            "_id": 24667,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3119,
            "שם_רחוב": "בית פאג'י סמ2"
        },
        {
            "_id": 24668,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 673,
            "שם_רחוב": "בית צור"
        },
        {
            "_id": 24669,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4334,
            "שם_רחוב": "בית צורי אליהו"
        },
        {
            "_id": 24670,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4201,
            "שם_רחוב": "בית צפפה"
        },
        {
            "_id": 24671,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4353,
            "שם_רחוב": "בית קנדה"
        },
        {
            "_id": 24672,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 971,
            "שם_רחוב": "בית שערים"
        },
        {
            "_id": 24673,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1737,
            "שם_רחוב": "ביתר"
        },
        {
            "_id": 24674,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 638,
            "שם_רחוב": "בכר נסים"
        },
        {
            "_id": 24675,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4341,
            "שם_רחוב": "בלבן מאיר"
        },
        {
            "_id": 24676,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4414,
            "שם_רחוב": "בלד אל קדימה"
        },
        {
            "_id": 24677,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4126,
            "שם_רחוב": "בלדיה"
        },
        {
            "_id": 24678,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2011,
            "שם_רחוב": "בלומנפלד"
        },
        {
            "_id": 24679,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 145,
            "שם_רחוב": "בלזר יצחק"
        },
        {
            "_id": 24680,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 351,
            "שם_רחוב": "בלילוס"
        },
        {
            "_id": 24681,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 168,
            "שם_רחוב": "בלכר נחום"
        },
        {
            "_id": 24682,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 833,
            "שם_רחוב": "בלפור"
        },
        {
            "_id": 24683,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4554,
            "שם_רחוב": "בן אליעזר אריה"
        },
        {
            "_id": 24684,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4426,
            "שם_רחוב": "בן אלעס עמר"
        },
        {
            "_id": 24685,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1374,
            "שם_רחוב": "בן בבא"
        },
        {
            "_id": 24686,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1680,
            "שם_רחוב": "בן גבריאל"
        },
        {
            "_id": 24687,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4636,
            "שם_רחוב": "בן גוביל מעאדי"
        },
        {
            "_id": 24688,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1320,
            "שם_רחוב": "בן גמלא יהושע"
        },
        {
            "_id": 24689,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1432,
            "שם_רחוב": "בן דב יעקב"
        },
        {
            "_id": 24690,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1388,
            "שם_רחוב": "בן דוסא"
        },
        {
            "_id": 24691,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1883,
            "שם_רחוב": "בן דור יצחק"
        },
        {
            "_id": 24692,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 435,
            "שם_רחוב": "בן הלל"
        },
        {
            "_id": 24693,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4461,
            "שם_רחוב": "בן זאב ישראל"
        },
        {
            "_id": 24694,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1894,
            "שם_רחוב": "בן חמו שמעון"
        },
        {
            "_id": 24695,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1411,
            "שם_רחוב": "בן חנן מיכאל"
        },
        {
            "_id": 24696,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1382,
            "שם_רחוב": "בן חפץ טוביה"
        },
        {
            "_id": 24697,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1371,
            "שם_רחוב": "בן טבאי"
        },
        {
            "_id": 24698,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1310,
            "שם_רחוב": "בן יאיר אלעזר"
        },
        {
            "_id": 24699,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 426,
            "שם_רחוב": "בן יהודה"
        },
        {
            "_id": 24700,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4329,
            "שם_רחוב": "בן יוסף שלמה"
        },
        {
            "_id": 24701,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4585,
            "שם_רחוב": "בן יחזקאל מרדכי"
        },
        {
            "_id": 24702,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1784,
            "שם_רחוב": "בן יפונה"
        },
        {
            "_id": 24703,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 459,
            "שם_רחוב": "בן ישראל מנשה"
        },
        {
            "_id": 24704,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 820,
            "שם_רחוב": "בן לברט"
        },
        {
            "_id": 24705,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 700,
            "שם_רחוב": "בן נריה ברוך"
        },
        {
            "_id": 24706,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 408,
            "שם_רחוב": "בן סירא"
        },
        {
            "_id": 24707,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 819,
            "שם_רחוב": "בן סרוק"
        },
        {
            "_id": 24708,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4281,
            "שם_רחוב": "בן עדיה שמואל"
        },
        {
            "_id": 24709,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5391,
            "שם_רחוב": "בן עדיה שמואל סמ6"
        },
        {
            "_id": 24710,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 905,
            "שם_רחוב": "בן עוזיאל"
        },
        {
            "_id": 24711,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1812,
            "שם_רחוב": "בן עזאי"
        },
        {
            "_id": 24712,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4470,
            "שם_רחוב": "בן עטר"
        },
        {
            "_id": 24713,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 136,
            "שם_רחוב": "בן עמרם"
        },
        {
            "_id": 24714,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1086,
            "שם_רחוב": "בן ציון"
        },
        {
            "_id": 24715,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4779,
            "שם_רחוב": "בן ציון נתניהו"
        },
        {
            "_id": 24716,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1824,
            "שם_רחוב": "בן קהת עמרם"
        },
        {
            "_id": 24717,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4640,
            "שם_רחוב": "בן רבאח בילאל"
        },
        {
            "_id": 24718,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4425,
            "שם_רחוב": "בן שדאד ענטרה"
        },
        {
            "_id": 24719,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1523,
            "שם_רחוב": "בן שמעון דוד"
        },
        {
            "_id": 24720,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4895,
            "שם_רחוב": "בנאת אבו בכר"
        },
        {
            "_id": 24721,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5206,
            "שם_רחוב": "בנאת אבו בכר סמ1"
        },
        {
            "_id": 24722,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5207,
            "שם_רחוב": "בנאת אבו בכר סמ2"
        },
        {
            "_id": 24723,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5208,
            "שם_רחוב": "בנאת אבו בכר סמ4"
        },
        {
            "_id": 24724,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5209,
            "שם_רחוב": "בנאת אבו בכר סמ6"
        },
        {
            "_id": 24725,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5210,
            "שם_רחוב": "בנאת אבו בכר סמ8"
        },
        {
            "_id": 24726,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6851,
            "שם_רחוב": "בנה ביתך גילה"
        },
        {
            "_id": 24727,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6797,
            "שם_רחוב": "בנה ביתך רמות"
        },
        {
            "_id": 24728,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3552,
            "שם_רחוב": "בנטויץ"
        },
        {
            "_id": 24729,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4609,
            "שם_רחוב": "בני אומייה"
        },
        {
            "_id": 24730,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5351,
            "שם_רחוב": "בני אומייה סמ1"
        },
        {
            "_id": 24731,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5358,
            "שם_רחוב": "בני אומייה סמ3"
        },
        {
            "_id": 24732,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 403,
            "שם_רחוב": "בני ברית"
        },
        {
            "_id": 24733,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1373,
            "שם_רחוב": "בני בתירא"
        },
        {
            "_id": 24734,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1644,
            "שם_רחוב": "בניהו"
        },
        {
            "_id": 24735,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1712,
            "שם_רחוב": "בנימין"
        },
        {
            "_id": 24736,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 499,
            "שם_רחוב": "בנימין (בני) אלון"
        },
        {
            "_id": 24737,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 817,
            "שם_רחוב": "בנימין מטודלה"
        },
        {
            "_id": 24738,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1181,
            "שם_רחוב": "בנק ישראל"
        },
        {
            "_id": 24739,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4985,
            "שם_רחוב": "בסאן יצחק"
        },
        {
            "_id": 24740,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1390,
            "שם_רחוב": "בעהם אריה"
        },
        {
            "_id": 24741,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2102,
            "שם_רחוב": "בעל אור שמח"
        },
        {
            "_id": 24742,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 996,
            "שם_רחוב": "בעל השאילתות"
        },
        {
            "_id": 24743,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 165,
            "שם_רחוב": "בעל התניא"
        },
        {
            "_id": 24744,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1835,
            "שם_רחוב": "בעלי מלאכה"
        },
        {
            "_id": 24745,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 643,
            "שם_רחוב": "בצלאל"
        },
        {
            "_id": 24746,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4778,
            "שם_רחוב": "בצראווי בצלאל"
        },
        {
            "_id": 24747,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "בק"
        },
        {
            "_id": 24748,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4369,
            "שם_רחוב": "בקור חולים"
        },
        {
            "_id": 24749,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 891,
            "שם_רחוב": "בקי"
        },
        {
            "_id": 24750,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4820,
            "שם_רחוב": "בר און אורי"
        },
        {
            "_id": 24751,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 301,
            "שם_רחוב": "בר אילן"
        },
        {
            "_id": 24752,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 333,
            "שם_רחוב": "בר גיורא"
        },
        {
            "_id": 24753,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 729,
            "שם_רחוב": "בר זכאי ישעיה"
        },
        {
            "_id": 24754,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1361,
            "שם_רחוב": "בר יוחאי"
        },
        {
            "_id": 24755,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3004,
            "שם_רחוב": "בר יקר דב"
        },
        {
            "_id": 24756,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 249,
            "שם_רחוב": "בר כוכבא"
        },
        {
            "_id": 24757,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1895,
            "שם_רחוב": "בר לביא רפאל"
        },
        {
            "_id": 24758,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1536,
            "שם_רחוב": "בר ניסן"
        },
        {
            "_id": 24759,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4497,
            "שם_רחוב": "בראון אליהו"
        },
        {
            "_id": 24760,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1157,
            "שם_רחוב": "בראנד אהרון"
        },
        {
            "_id": 24761,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 316,
            "שם_רחוב": "בראשי זלמן"
        },
        {
            "_id": 24762,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1091,
            "שם_רחוב": "ברגמן אליעזר"
        },
        {
            "_id": 24763,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1304,
            "שם_רחוב": "ברגר"
        },
        {
            "_id": 24764,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4788,
            "שם_רחוב": "ברדנוב ירמיהו"
        },
        {
            "_id": 24765,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1144,
            "שם_רחוב": "ברודצקי"
        },
        {
            "_id": 24766,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 956,
            "שם_רחוב": "ברוזה שמואל"
        },
        {
            "_id": 24767,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1057,
            "שם_רחוב": "ברויאר יצחק"
        },
        {
            "_id": 24768,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 624,
            "שם_רחוב": "ברוכוף משיח"
        },
        {
            "_id": 24769,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5461,
            "שם_רחוב": "ברוכי יהושע"
        },
        {
            "_id": 24770,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2051,
            "שם_רחוב": "ברוכים שמואל"
        },
        {
            "_id": 24771,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4845,
            "שם_רחוב": "ברוסקינה מאשה"
        },
        {
            "_id": 24772,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1326,
            "שם_רחוב": "ברוריה"
        },
        {
            "_id": 24773,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1268,
            "שם_רחוב": "ברזיל"
        },
        {
            "_id": 24774,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1799,
            "שם_רחוב": "ברזילי"
        },
        {
            "_id": 24775,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4336,
            "שם_רחוב": "ברזני משה"
        },
        {
            "_id": 24776,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1027,
            "שם_רחוב": "ברכיהו"
        },
        {
            "_id": 24777,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4967,
            "שם_רחוב": "ברכת אברהם"
        },
        {
            "_id": 24778,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1347,
            "שם_רחוב": "ברל לוקר"
        },
        {
            "_id": 24779,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4822,
            "שם_רחוב": "ברנדויין שמואל"
        },
        {
            "_id": 24780,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 320,
            "שם_רחוב": "ברנדיס"
        },
        {
            "_id": 24781,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1154,
            "שם_רחוב": "ברנט זרח"
        },
        {
            "_id": 24782,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1335,
            "שם_רחוב": "ברניקי"
        },
        {
            "_id": 24783,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1512,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 24784,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1359,
            "שם_רחוב": "ברנשטיין פרץ"
        },
        {
            "_id": 24785,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1770,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 24786,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4510,
            "שם_רחוב": "ברקאי"
        },
        {
            "_id": 24787,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4030,
            "שם_רחוב": "ברקוק"
        },
        {
            "_id": 24788,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4476,
            "שם_רחוב": "ברקלי שאול"
        },
        {
            "_id": 24789,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4567,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 24790,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1931,
            "שם_רחוב": "ברש מנחם רועי"
        },
        {
            "_id": 24791,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1730,
            "שם_רחוב": "בת שבע"
        },
        {
            "_id": 24792,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 694,
            "שם_רחוב": "בתי ברוידה"
        },
        {
            "_id": 24793,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 377,
            "שם_רחוב": "בתי הורנשטין"
        },
        {
            "_id": 24794,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 186,
            "שם_רחוב": "בתי ויטנברג"
        },
        {
            "_id": 24795,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 182,
            "שם_רחוב": "בתי ורשא"
        },
        {
            "_id": 24796,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 456,
            "שם_רחוב": "בתי מונקץ"
        },
        {
            "_id": 24797,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4361,
            "שם_רחוב": "בתי מחסה"
        },
        {
            "_id": 24798,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 692,
            "שם_רחוב": "בתי מינסק"
        },
        {
            "_id": 24799,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 927,
            "שם_רחוב": "בתי פרבשטין"
        },
        {
            "_id": 24800,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 699,
            "שם_רחוב": "בתי רנד סלנט"
        },
        {
            "_id": 24801,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6865,
            "שם_רחוב": "בתיר"
        },
        {
            "_id": 24802,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4896,
            "שם_רחוב": "ג'אבר בן חייאן"
        },
        {
            "_id": 24803,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5211,
            "שם_רחוב": "ג'אבר בן חייאן סמ1"
        },
        {
            "_id": 24804,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5212,
            "שם_רחוב": "ג'אבר בן חייאן סמ2"
        },
        {
            "_id": 24805,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5213,
            "שם_רחוב": "ג'אבר בן חייאן סמ4"
        },
        {
            "_id": 24806,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5888,
            "שם_רחוב": "ג'אמע אל-זאויה"
        },
        {
            "_id": 24807,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5889,
            "שם_רחוב": "ג'אמע אל-זאויה סמ1"
        },
        {
            "_id": 24808,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5890,
            "שם_רחוב": "ג'אמע אל-זאויה סמ2"
        },
        {
            "_id": 24809,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5891,
            "שם_רחוב": "ג'אמע אל-זאויה סמ4"
        },
        {
            "_id": 24810,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5892,
            "שם_רחוב": "ג'אמע אל-זאויה סמ6"
        },
        {
            "_id": 24811,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4387,
            "שם_רחוב": "ג'בל אל ג'דיד"
        },
        {
            "_id": 24812,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4223,
            "שם_רחוב": "ג'בל מוכבר"
        },
        {
            "_id": 24813,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5214,
            "שם_רחוב": "ג'דה"
        },
        {
            "_id": 24814,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5215,
            "שם_רחוב": "ג'דה סמ1"
        },
        {
            "_id": 24815,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5152,
            "שם_רחוב": "ג'ובראן ח'ליל"
        },
        {
            "_id": 24816,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5154,
            "שם_רחוב": "ג'ובראן ח'ליל סמ5"
        },
        {
            "_id": 24817,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5155,
            "שם_רחוב": "ג'ובראן ח'ליל סמ6"
        },
        {
            "_id": 24818,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4651,
            "שם_רחוב": "ג'ורג' אדם סמית"
        },
        {
            "_id": 24819,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4475,
            "שם_רחוב": "ג'ינאו מאיר"
        },
        {
            "_id": 24820,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5900,
            "שם_רחוב": "ג'עביץ"
        },
        {
            "_id": 24821,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5901,
            "שם_רחוב": "ג'עביץ סמ1"
        },
        {
            "_id": 24822,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5902,
            "שם_רחוב": "ג'עביץ סמ2"
        },
        {
            "_id": 24823,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4696,
            "שם_רחוב": "ג'עפר א-סאדק"
        },
        {
            "_id": 24824,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 609,
            "שם_רחוב": "גאון"
        },
        {
            "_id": 24825,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1345,
            "שם_רחוב": "גאוני יעקב"
        },
        {
            "_id": 24826,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4131,
            "שם_רחוב": "גאמע עבדין"
        },
        {
            "_id": 24827,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4800,
            "שם_רחוב": "גבירצמן משה"
        },
        {
            "_id": 24828,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4142,
            "שם_רחוב": "גבל א טור"
        },
        {
            "_id": 24829,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4233,
            "שם_רחוב": "גבל אטרש"
        },
        {
            "_id": 24830,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4114,
            "שם_רחוב": "גבל אל רויס"
        },
        {
            "_id": 24831,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4203,
            "שם_רחוב": "גבל קטן"
        },
        {
            "_id": 24832,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 655,
            "שם_רחוב": "גבע"
        },
        {
            "_id": 24833,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 663,
            "שם_רחוב": "גבעון"
        },
        {
            "_id": 24834,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "גבעת אורן"
        },
        {
            "_id": 24835,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6536,
            "שם_רחוב": "גבעת אורנים"
        },
        {
            "_id": 24836,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6635,
            "שם_רחוב": "גבעת בית הכרם"
        },
        {
            "_id": 24837,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6808,
            "שם_רחוב": "גבעת בנימין"
        },
        {
            "_id": 24838,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6580,
            "שם_רחוב": "גבעת הארבעה"
        },
        {
            "_id": 24839,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1465,
            "שם_רחוב": "גבעת היונים"
        },
        {
            "_id": 24840,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גבעת המבתר"
        },
        {
            "_id": 24841,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6860,
            "שם_רחוב": "גבעת המטוס"
        },
        {
            "_id": 24842,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4288,
            "שם_רחוב": "גבעת התחמושת"
        },
        {
            "_id": 24843,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6760,
            "שם_רחוב": "גבעת חנניה"
        },
        {
            "_id": 24844,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6699,
            "שם_רחוב": "גבעת משואה"
        },
        {
            "_id": 24845,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6807,
            "שם_רחוב": "גבעת עומר"
        },
        {
            "_id": 24846,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6852,
            "שם_רחוב": "גבעת קנדה"
        },
        {
            "_id": 24847,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1143,
            "שם_רחוב": "גבעת שאול"
        },
        {
            "_id": 24848,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "גבעת שהין"
        },
        {
            "_id": 24849,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6820,
            "שם_רחוב": "גבעת שפירא"
        },
        {
            "_id": 24850,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4813,
            "שם_רחוב": "גבריהו חיים"
        },
        {
            "_id": 24851,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1785,
            "שם_רחוב": "גד"
        },
        {
            "_id": 24852,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4764,
            "שם_רחוב": "גדוד החמישי"
        },
        {
            "_id": 24853,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4768,
            "שם_רחוב": "גדוד חרמש"
        },
        {
            "_id": 24854,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4755,
            "שם_רחוב": "גדוד מכמש"
        },
        {
            "_id": 24855,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4228,
            "שם_רחוב": "גדירי"
        },
        {
            "_id": 24856,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1759,
            "שם_רחוב": "גדליה"
        },
        {
            "_id": 24857,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1674,
            "שם_רחוב": "גדליהו אלון"
        },
        {
            "_id": 24858,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1774,
            "שם_רחוב": "גדעון"
        },
        {
            "_id": 24859,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 954,
            "שם_רחוב": "גדרה"
        },
        {
            "_id": 24860,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1241,
            "שם_רחוב": "גואטמאלה"
        },
        {
            "_id": 24861,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 290,
            "שם_רחוב": "גואל צדק"
        },
        {
            "_id": 24862,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1439,
            "שם_רחוב": "גוגה ומוריס רג'ואן"
        },
        {
            "_id": 24863,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2151,
            "שם_רחוב": "גוטליב מאוריציו"
        },
        {
            "_id": 24864,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4803,
            "שם_רחוב": "גוייטין דוד"
        },
        {
            "_id": 24865,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 129,
            "שם_רחוב": "גול בן ציון"
        },
        {
            "_id": 24866,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4343,
            "שם_רחוב": "גולאק אשר"
        },
        {
            "_id": 24867,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1404,
            "שם_רחוב": "גולד"
        },
        {
            "_id": 24868,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4345,
            "שם_רחוב": "גולדברג לאה"
        },
        {
            "_id": 24869,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1307,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 24870,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3052,
            "שם_רחוב": "גולי קניה"
        },
        {
            "_id": 24871,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6715,
            "שם_רחוב": "גונן א"
        },
        {
            "_id": 24872,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6716,
            "שם_רחוב": "גונן ב"
        },
        {
            "_id": 24873,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6717,
            "שם_רחוב": "גונן ג"
        },
        {
            "_id": 24874,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6718,
            "שם_רחוב": "גונן ד"
        },
        {
            "_id": 24875,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6719,
            "שם_רחוב": "גונן ה"
        },
        {
            "_id": 24876,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "גונן ו"
        },
        {
            "_id": 24877,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "גונן ח"
        },
        {
            "_id": 24878,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "גונן ט"
        },
        {
            "_id": 24879,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1210,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 24880,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 163,
            "שם_רחוב": "גוש 60 מוסררה"
        },
        {
            "_id": 24881,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 411,
            "שם_רחוב": "גוש 51 מוסררה"
        },
        {
            "_id": 24882,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 516,
            "שם_רחוב": "גוש 33 מרכז מסחרי"
        },
        {
            "_id": 24883,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 504,
            "שם_רחוב": "גוש 53 מוסררה"
        },
        {
            "_id": 24884,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 503,
            "שם_רחוב": "גוש 54 מוסררה"
        },
        {
            "_id": 24885,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 502,
            "שם_רחוב": "גוש 59 מוסררה"
        },
        {
            "_id": 24886,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 672,
            "שם_רחוב": "גזר"
        },
        {
            "_id": 24887,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3265,
            "שם_רחוב": "גיא בן הינום"
        },
        {
            "_id": 24888,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4689,
            "שם_רחוב": "גידין"
        },
        {
            "_id": 24889,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4582,
            "שם_רחוב": "גיזה"
        },
        {
            "_id": 24890,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1722,
            "שם_רחוב": "גיחון"
        },
        {
            "_id": 24891,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6559,
            "שם_רחוב": "גילה-צפון מערב"
        },
        {
            "_id": 24892,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3260,
            "שם_רחוב": "גינת אגוז"
        },
        {
            "_id": 24893,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4818,
            "שם_רחוב": "גל"
        },
        {
            "_id": 24894,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1385,
            "שם_רחוב": "גלבר"
        },
        {
            "_id": 24895,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5010,
            "שם_רחוב": "גליק נלסון"
        },
        {
            "_id": 24896,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4362,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 24897,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1733,
            "שם_רחוב": "גלעדי"
        },
        {
            "_id": 24898,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4852,
            "שם_רחוב": "גלעינית"
        },
        {
            "_id": 24899,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1870,
            "שם_רחוב": "גלרנטר מנחם"
        },
        {
            "_id": 24900,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 216,
            "שם_רחוב": "גמול"
        },
        {
            "_id": 24901,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4346,
            "שם_רחוב": "גמזון ראובן"
        },
        {
            "_id": 24902,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4104,
            "שם_רחוב": "גמע אל קדים"
        },
        {
            "_id": 24903,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1460,
            "שם_רחוב": "גן החיות התנכי"
        },
        {
            "_id": 24904,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 800,
            "שם_רחוב": "גן הכוזרי"
        },
        {
            "_id": 24905,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1905,
            "שם_רחוב": "גן העשרים"
        },
        {
            "_id": 24906,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6776,
            "שם_רחוב": "גני קטמון"
        },
        {
            "_id": 24907,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1787,
            "שם_רחוב": "גנרל קניג פייר"
        },
        {
            "_id": 24908,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1679,
            "שם_רחוב": "גסטר משה"
        },
        {
            "_id": 24909,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4690,
            "שם_רחוב": "גסר א-נסף"
        },
        {
            "_id": 24910,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1344,
            "שם_רחוב": "גפני שמחה"
        },
        {
            "_id": 24911,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4418,
            "שם_רחוב": "גראללה חוסם א דין"
        },
        {
            "_id": 24912,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1495,
            "שם_רחוב": "גראנאדוס"
        },
        {
            "_id": 24913,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 461,
            "שם_רחוב": "גרוזנברג"
        },
        {
            "_id": 24914,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4242,
            "שם_רחוב": "גרון אל חומוס"
        },
        {
            "_id": 24915,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4328,
            "שם_רחוב": "גרונר דב"
        },
        {
            "_id": 24916,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5482,
            "שם_רחוב": "גרוס זאב"
        },
        {
            "_id": 24917,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1274,
            "שם_רחוב": "גרוסמן"
        },
        {
            "_id": 24918,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 948,
            "שם_רחוב": "גרייבסקי פנחס"
        },
        {
            "_id": 24919,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4445,
            "שם_רחוב": "גרינברג חיים"
        },
        {
            "_id": 24920,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1276,
            "שם_רחוב": "גרינולד"
        },
        {
            "_id": 24921,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4846,
            "שם_רחוב": "גריניגר פאול"
        },
        {
            "_id": 24922,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4357,
            "שם_רחוב": "גרינשפן הרשל"
        },
        {
            "_id": 24923,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4065,
            "שם_רחוב": "גריק פטריאכט"
        },
        {
            "_id": 24924,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4841,
            "שם_רחוב": "גרמי ציון"
        },
        {
            "_id": 24925,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1111,
            "שם_רחוב": "גרנות"
        },
        {
            "_id": 24926,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1657,
            "שם_רחוב": "גרץ צבי"
        },
        {
            "_id": 24927,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4805,
            "שם_רחוב": "גרשון מאיר"
        },
        {
            "_id": 24928,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 384,
            "שם_רחוב": "גשר האלוף יוסף גבע"
        },
        {
            "_id": 24929,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 385,
            "שם_רחוב": "גשר החיים"
        },
        {
            "_id": 24930,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1006,
            "שם_רחוב": "גת"
        },
        {
            "_id": 24931,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4145,
            "שם_רחוב": "גת שמנים"
        },
        {
            "_id": 24932,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1001,
            "שם_רחוב": "דר גבי אשכר"
        },
        {
            "_id": 24933,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1003,
            "שם_רחוב": "דר יוסף בורג"
        },
        {
            "_id": 24934,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6104,
            "שם_רחוב": "דר יצחק מוסקוביץ"
        },
        {
            "_id": 24935,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1233,
            "שם_רחוב": "דר מלכה שפיגל"
        },
        {
            "_id": 24936,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5216,
            "שם_רחוב": "ד'את אל-נטאקין"
        },
        {
            "_id": 24937,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5217,
            "שם_רחוב": "ד'את אל-נטאקין סמ1"
        },
        {
            "_id": 24938,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5352,
            "שם_רחוב": "ד'ו אלנוריין"
        },
        {
            "_id": 24939,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5353,
            "שם_רחוב": "ד'ו אלנוריין סמ2"
        },
        {
            "_id": 24940,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5354,
            "שם_רחוב": "ד'ו אלנוריין סמ3"
        },
        {
            "_id": 24941,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4721,
            "שם_רחוב": "דאחית אל מועלמין"
        },
        {
            "_id": 24942,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5657,
            "שם_רחוב": "דאר א סלאם"
        },
        {
            "_id": 24943,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4417,
            "שם_רחוב": "דאר חמד"
        },
        {
            "_id": 24944,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1303,
            "שם_רחוב": "דב הוז"
        },
        {
            "_id": 24945,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5528,
            "שם_רחוב": "דבה"
        },
        {
            "_id": 24946,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3081,
            "שם_רחוב": "דבה סמ2"
        },
        {
            "_id": 24947,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3082,
            "שם_רחוב": "דבה סמ4"
        },
        {
            "_id": 24948,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 401,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 24949,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 670,
            "שם_רחוב": "דביר"
        },
        {
            "_id": 24950,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 993,
            "שם_רחוב": "דברי חיים"
        },
        {
            "_id": 24951,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1100,
            "שם_רחוב": "דברי ירוחם"
        },
        {
            "_id": 24952,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4571,
            "שם_רחוב": "דבש"
        },
        {
            "_id": 24953,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1092,
            "שם_רחוב": "דגל ראובן"
        },
        {
            "_id": 24954,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4578,
            "שם_רחוב": "דגן"
        },
        {
            "_id": 24955,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1063,
            "שם_רחוב": "דגניה"
        },
        {
            "_id": 24956,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4448,
            "שם_רחוב": "דה הז יעקב"
        },
        {
            "_id": 24957,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4584,
            "שם_רחוב": "דה לימה נחמיה"
        },
        {
            "_id": 24958,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1203,
            "שם_רחוב": "דה-רוסי"
        },
        {
            "_id": 24959,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1266,
            "שם_רחוב": "דהומיי"
        },
        {
            "_id": 24960,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4711,
            "שם_רחוב": "דהרה"
        },
        {
            "_id": 24961,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 394,
            "שם_רחוב": "דובב מישרים"
        },
        {
            "_id": 24962,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1195,
            "שם_רחוב": "דובדבני ברוך"
        },
        {
            "_id": 24963,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1629,
            "שם_רחוב": "דובנוב"
        },
        {
            "_id": 24964,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4902,
            "שם_רחוב": "דובר שלום"
        },
        {
            "_id": 24965,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5022,
            "שם_רחוב": "דוגה שלמה"
        },
        {
            "_id": 24966,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4056,
            "שם_רחוב": "דוד"
        },
        {
            "_id": 24967,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4704,
            "שם_רחוב": "דוד (העיר העתיקה)"
        },
        {
            "_id": 24968,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 130,
            "שם_רחוב": "דוד החזן"
        },
        {
            "_id": 24969,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1528,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 24970,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1904,
            "שם_רחוב": "דוד מרדכי מאיר"
        },
        {
            "_id": 24971,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3140,
            "שם_רחוב": "דוואר אל-נח'יל"
        },
        {
            "_id": 24972,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4241,
            "שם_רחוב": "דוויאת"
        },
        {
            "_id": 24973,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1112,
            "שם_רחוב": "דוידסון"
        },
        {
            "_id": 24974,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1291,
            "שם_רחוב": "דולצ'ין"
        },
        {
            "_id": 24975,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 275,
            "שם_רחוב": "דונה גרציה"
        },
        {
            "_id": 24976,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 420,
            "שם_רחוב": "דונואס"
        },
        {
            "_id": 24977,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1831,
            "שם_רחוב": "דוסטרובסקי אריה"
        },
        {
            "_id": 24978,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1331,
            "שם_רחוב": "דוסתאי"
        },
        {
            "_id": 24979,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1664,
            "שם_רחוב": "דור ודורשיו"
        },
        {
            "_id": 24980,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 439,
            "שם_רחוב": "דורות ראשונים"
        },
        {
            "_id": 24981,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 397,
            "שם_רחוב": "דורש טוב"
        },
        {
            "_id": 24982,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 294,
            "שם_רחוב": "דינה"
        },
        {
            "_id": 24983,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 736,
            "שם_רחוב": "דינוביץ גיטל"
        },
        {
            "_id": 24984,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1069,
            "שם_רחוב": "דינור בן ציון"
        },
        {
            "_id": 24985,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1209,
            "שם_רחוב": "דיניץ שמחה"
        },
        {
            "_id": 24986,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 728,
            "שם_רחוב": "דיסקין"
        },
        {
            "_id": 24987,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4245,
            "שם_רחוב": "דיר אבו טור"
        },
        {
            "_id": 24988,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4174,
            "שם_רחוב": "דיר אל טפל"
        },
        {
            "_id": 24989,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3200,
            "שם_רחוב": "דיר אל סיריאן"
        },
        {
            "_id": 24990,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5218,
            "שם_רחוב": "דיר אל עמוד סמ10"
        },
        {
            "_id": 24991,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3185,
            "שם_רחוב": "דיר אל-סנה"
        },
        {
            "_id": 24992,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3186,
            "שם_רחוב": "דיר אל-סנה סמ1"
        },
        {
            "_id": 24993,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3187,
            "שם_רחוב": "דיר אל-סנה סמ2"
        },
        {
            "_id": 24994,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3188,
            "שם_רחוב": "דיר אל-סנה סמ3"
        },
        {
            "_id": 24995,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3189,
            "שם_רחוב": "דיר אל-סנה סמ4"
        },
        {
            "_id": 24996,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4897,
            "שם_רחוב": "דיר אל-עאמוד"
        },
        {
            "_id": 24997,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5219,
            "שם_רחוב": "דיר אל-עאמוד סמ2"
        },
        {
            "_id": 24998,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5220,
            "שם_רחוב": "דיר אל-עאמוד סמ4"
        },
        {
            "_id": 24999,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5222,
            "שם_רחוב": "דיר אל-עאמוד סמ6"
        },
        {
            "_id": 25000,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4208,
            "שם_רחוב": "דיר טנטורה"
        },
        {
            "_id": 25001,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4105,
            "שם_רחוב": "דיר כרמיזן"
        },
        {
            "_id": 25002,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4216,
            "שם_רחוב": "דיר קירמיזן"
        },
        {
            "_id": 25003,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1505,
            "שם_רחוב": "דישראלי"
        },
        {
            "_id": 25004,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5462,
            "שם_רחוב": "דלה פרגולה"
        },
        {
            "_id": 25005,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 211,
            "שם_רחוב": "דלמן"
        },
        {
            "_id": 25006,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 662,
            "שם_רחוב": "דלתון"
        },
        {
            "_id": 25007,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4064,
            "שם_רחוב": "דמיטריוס הקדוש"
        },
        {
            "_id": 25008,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1783,
            "שם_רחוב": "דן"
        },
        {
            "_id": 25009,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1543,
            "שם_רחוב": "דני אנג'ל"
        },
        {
            "_id": 25010,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 507,
            "שם_רחוב": "דניאל"
        },
        {
            "_id": 25011,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1499,
            "שם_רחוב": "דסקל"
        },
        {
            "_id": 25012,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4055,
            "שם_רחוב": "דרג א טבוני"
        },
        {
            "_id": 25013,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 465,
            "שם_רחוב": "דרום"
        },
        {
            "_id": 25014,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4950,
            "שם_רחוב": "דרוק שלמה זלמן"
        },
        {
            "_id": 25015,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4332,
            "שם_רחוב": "דרזנר יחיאל"
        },
        {
            "_id": 25016,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5530,
            "שם_רחוב": "דרך א זעים"
        },
        {
            "_id": 25017,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5767,
            "שם_רחוב": "דרך אבו-ת'ור"
        },
        {
            "_id": 25018,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5768,
            "שם_רחוב": "דרך אבו-ת'ור סמ2"
        },
        {
            "_id": 25019,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5769,
            "שם_רחוב": "דרך אבו-ת'ור סמ4"
        },
        {
            "_id": 25020,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 535,
            "שם_רחוב": "דרך אהרון חיים כהן"
        },
        {
            "_id": 25021,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5384,
            "שם_רחוב": "דרך אל-צוואנה"
        },
        {
            "_id": 25022,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5385,
            "שם_רחוב": "דרך אל-צוואנה סמ1"
        },
        {
            "_id": 25023,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5386,
            "שם_רחוב": "דרך אל-צוואנה סמ2"
        },
        {
            "_id": 25024,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5387,
            "שם_רחוב": "דרך אל-צוואנה סמ3"
        },
        {
            "_id": 25025,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5388,
            "שם_רחוב": "דרך אל-צוואנה סמ4"
        },
        {
            "_id": 25026,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5389,
            "שם_רחוב": "דרך אל-צוואנה סמ5"
        },
        {
            "_id": 25027,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5390,
            "שם_רחוב": "דרך אל-צוואנה סמ7"
        },
        {
            "_id": 25028,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3217,
            "שם_רחוב": "דרך אל-שיאח"
        },
        {
            "_id": 25029,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3132,
            "שם_רחוב": "דרך אל-שיאח סמ1"
        },
        {
            "_id": 25030,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3133,
            "שם_רחוב": "דרך אל-שיאח סמ2"
        },
        {
            "_id": 25031,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3134,
            "שם_רחוב": "דרך אל-שיאח סמ3"
        },
        {
            "_id": 25032,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3135,
            "שם_רחוב": "דרך אל-שיאח סמ6"
        },
        {
            "_id": 25033,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3136,
            "שם_רחוב": "דרך אל-שיאח סמ7"
        },
        {
            "_id": 25034,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1167,
            "שם_רחוב": "דרך אלישר"
        },
        {
            "_id": 25035,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1105,
            "שם_רחוב": "דרך בגין"
        },
        {
            "_id": 25036,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4744,
            "שם_רחוב": "דרך ביר נבאלה"
        },
        {
            "_id": 25037,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4632,
            "שם_רחוב": "דרך בית חורון"
        },
        {
            "_id": 25038,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5127,
            "שם_רחוב": "דרך בית חנינא"
        },
        {
            "_id": 25039,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1753,
            "שם_רחוב": "דרך בית לחם"
        },
        {
            "_id": 25040,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1362,
            "שם_רחוב": "דרך בנבנישתי דוד"
        },
        {
            "_id": 25041,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1466,
            "שם_רחוב": "דרך בנימינה"
        },
        {
            "_id": 25042,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4113,
            "שם_רחוב": "דרך בר-לב"
        },
        {
            "_id": 25043,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5893,
            "שם_רחוב": "דרך ג'בל מוכבר"
        },
        {
            "_id": 25044,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5894,
            "שם_רחוב": "דרך ג'בל מוכבר סמ1"
        },
        {
            "_id": 25045,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5899,
            "שם_רחוב": "דרך ג'בל מוכבר סמ11"
        },
        {
            "_id": 25046,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5895,
            "שם_רחוב": "דרך ג'בל מוכבר סמ3"
        },
        {
            "_id": 25047,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5896,
            "שם_רחוב": "דרך ג'בל מוכבר סמ5"
        },
        {
            "_id": 25048,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5897,
            "שם_רחוב": "דרך ג'בל מוכבר סמ6"
        },
        {
            "_id": 25049,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5898,
            "שם_רחוב": "דרך ג'בל מוכבר סמ9"
        },
        {
            "_id": 25050,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1729,
            "שם_רחוב": "דרך גדוד 68"
        },
        {
            "_id": 25051,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1469,
            "שם_רחוב": "דרך האחיות"
        },
        {
            "_id": 25052,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1493,
            "שם_רחוב": "דרך האילנות"
        },
        {
            "_id": 25053,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1540,
            "שם_רחוב": "דרך הבוצר"
        },
        {
            "_id": 25054,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3369,
            "שם_רחוב": "דרך הגן"
        },
        {
            "_id": 25055,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3370,
            "שם_רחוב": "דרך הגן סמ2"
        },
        {
            "_id": 25056,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3371,
            "שם_רחוב": "דרך הגן סמ3"
        },
        {
            "_id": 25057,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5300,
            "שם_רחוב": "דרך החורש"
        },
        {
            "_id": 25058,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1801,
            "שם_רחוב": "דרך הטיילת"
        },
        {
            "_id": 25059,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1538,
            "שם_רחוב": "דרך הטרסות"
        },
        {
            "_id": 25060,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3373,
            "שם_רחוב": "דרך הכדים"
        },
        {
            "_id": 25061,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1467,
            "שם_רחוב": "דרך הסלע"
        },
        {
            "_id": 25062,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4339,
            "שם_רחוב": "דרך העופל"
        },
        {
            "_id": 25063,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3258,
            "שם_רחוב": "דרך הפטרול"
        },
        {
            "_id": 25064,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4177,
            "שם_רחוב": "דרך הר הזיתים"
        },
        {
            "_id": 25065,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1541,
            "שם_רחוב": "דרך השומרה"
        },
        {
            "_id": 25066,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1103,
            "שם_רחוב": "דרך השופט מאיר שמגר"
        },
        {
            "_id": 25067,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3800,
            "שם_רחוב": "דרך השילוח"
        },
        {
            "_id": 25068,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1179,
            "שם_רחוב": "דרך השלוה"
        },
        {
            "_id": 25069,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1470,
            "שם_רחוב": "דרך התצפית"
        },
        {
            "_id": 25070,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5359,
            "שם_רחוב": "דרך ואדי אל ג'וז"
        },
        {
            "_id": 25071,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1128,
            "שם_רחוב": "דרך וולפסון דוד"
        },
        {
            "_id": 25072,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4456,
            "שם_רחוב": "דרך זלאטע כהן"
        },
        {
            "_id": 25073,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1714,
            "שם_רחוב": "דרך חברון"
        },
        {
            "_id": 25074,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4728,
            "שם_רחוב": "דרך חיזמא סמ3"
        },
        {
            "_id": 25075,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5091,
            "שם_רחוב": "דרך חיזמא סמ4"
        },
        {
            "_id": 25076,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4932,
            "שם_רחוב": "דרך חיזמא סמ7"
        },
        {
            "_id": 25077,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4933,
            "שם_רחוב": "דרך חיזמא סמ9"
        },
        {
            "_id": 25078,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4135,
            "שם_רחוב": "דרך חיזמה"
        },
        {
            "_id": 25079,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 540,
            "שם_רחוב": "דרך חיים גורי"
        },
        {
            "_id": 25080,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4146,
            "שם_רחוב": "דרך יריחו"
        },
        {
            "_id": 25081,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3143,
            "שם_רחוב": "דרך יריחו סמ12"
        },
        {
            "_id": 25082,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3142,
            "שם_רחוב": "דרך יריחו סמ6"
        },
        {
            "_id": 25083,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3323,
            "שם_רחוב": "דרך כפר עקב"
        },
        {
            "_id": 25084,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3324,
            "שם_רחוב": "דרך כפר עקב סמ1"
        },
        {
            "_id": 25085,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3325,
            "שם_רחוב": "דרך כפר עקב סמ2"
        },
        {
            "_id": 25086,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3326,
            "שם_רחוב": "דרך כפר עקב סמ3"
        },
        {
            "_id": 25087,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3327,
            "שם_רחוב": "דרך כפר עקב סמ4"
        },
        {
            "_id": 25088,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3328,
            "שם_רחוב": "דרך כפר עקב סמ5"
        },
        {
            "_id": 25089,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1218,
            "שם_רחוב": "דרך כרמית"
        },
        {
            "_id": 25090,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1346,
            "שם_רחוב": "דרך מודעי יצחק"
        },
        {
            "_id": 25091,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 485,
            "שם_רחוב": "דרך מוך"
        },
        {
            "_id": 25092,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1240,
            "שם_רחוב": "דרך מן קלמן"
        },
        {
            "_id": 25093,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1216,
            "שם_רחוב": "דרך מנחת"
        },
        {
            "_id": 25094,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "דרך מעלה אדמים"
        },
        {
            "_id": 25095,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1864,
            "שם_רחוב": "דרך משה ברעם"
        },
        {
            "_id": 25096,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4685,
            "שם_רחוב": "דרך נבי סמואל"
        },
        {
            "_id": 25097,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5529,
            "שם_רחוב": "דרך ע'אבה פוקא"
        },
        {
            "_id": 25098,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4626,
            "שם_רחוב": "דרך עזמות"
        },
        {
            "_id": 25099,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4801,
            "שם_רחוב": "דרך ענתות"
        },
        {
            "_id": 25100,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3950,
            "שם_רחוב": "דרך צור באהר"
        },
        {
            "_id": 25101,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1252,
            "שם_רחוב": "דרך קוליץ"
        },
        {
            "_id": 25102,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1062,
            "שם_רחוב": "דרך קרית יובל"
        },
        {
            "_id": 25103,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4128,
            "שם_רחוב": "דרך ראמאללה"
        },
        {
            "_id": 25104,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3282,
            "שם_רחוב": "דרך ראמאללה סמ100"
        },
        {
            "_id": 25105,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3286,
            "שם_רחוב": "דרך ראמאללה סמ120"
        },
        {
            "_id": 25106,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4990,
            "שם_רחוב": "דרך ראמאללה סמ1"
        },
        {
            "_id": 25107,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3283,
            "שם_רחוב": "דרך ראמאללה סמ111"
        },
        {
            "_id": 25108,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4991,
            "שם_רחוב": "דרך ראמאללה סמ2"
        },
        {
            "_id": 25109,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3284,
            "שם_רחוב": "דרך ראמאללה סמ112"
        },
        {
            "_id": 25110,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4992,
            "שם_רחוב": "דרך ראמאללה סמ4"
        },
        {
            "_id": 25111,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3285,
            "שם_רחוב": "דרך ראמאללה סמ116"
        },
        {
            "_id": 25112,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1106,
            "שם_רחוב": "דרך רבין"
        },
        {
            "_id": 25113,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1101,
            "שם_רחוב": "דרך רופין"
        },
        {
            "_id": 25114,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1409,
            "שם_רחוב": "דרך שולוב אהרון"
        },
        {
            "_id": 25115,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5128,
            "שם_רחוב": "דרך שועפאט"
        },
        {
            "_id": 25116,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5134,
            "שם_רחוב": "דרך שועפאט סמ10"
        },
        {
            "_id": 25117,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5114,
            "שם_רחוב": "דרך שועפאט סמ2"
        },
        {
            "_id": 25118,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5115,
            "שם_רחוב": "דרך שועפאט סמ4"
        },
        {
            "_id": 25119,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5116,
            "שם_רחוב": "דרך שועפאט סמ5"
        },
        {
            "_id": 25120,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5135,
            "שם_רחוב": "דרך שועפאט סמ6"
        },
        {
            "_id": 25121,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5118,
            "שם_רחוב": "דרך שועפאט סמ9"
        },
        {
            "_id": 25122,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1468,
            "שם_רחוב": "דרך שורק"
        },
        {
            "_id": 25123,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4162,
            "שם_רחוב": "דרך שכם"
        },
        {
            "_id": 25124,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5083,
            "שם_רחוב": "דרך שכם סמ5"
        },
        {
            "_id": 25125,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5119,
            "שם_רחוב": "דרך שעפאט סמ3"
        },
        {
            "_id": 25126,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5113,
            "שם_רחוב": "דרך שעפאט סמ14"
        },
        {
            "_id": 25127,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5117,
            "שם_רחוב": "דרך שעפאט סמ8"
        },
        {
            "_id": 25128,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1249,
            "שם_רחוב": "האביבית"
        },
        {
            "_id": 25129,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 626,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 25130,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1152,
            "שם_רחוב": "האדמור מבויאן"
        },
        {
            "_id": 25131,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1163,
            "שם_רחוב": "האדמור מבוסטון"
        },
        {
            "_id": 25132,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3031,
            "שם_רחוב": "האדמור מבעלז"
        },
        {
            "_id": 25133,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3030,
            "שם_רחוב": "האדמור מגור"
        },
        {
            "_id": 25134,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3032,
            "שם_רחוב": "האדמור מויז'ניץ"
        },
        {
            "_id": 25135,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4958,
            "שם_רחוב": "האדמור מלובביץ"
        },
        {
            "_id": 25136,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4961,
            "שם_רחוב": "האדמור מסדיגורה"
        },
        {
            "_id": 25137,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1158,
            "שם_רחוב": "האדמור מרוז'ין"
        },
        {
            "_id": 25138,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 203,
            "שם_רחוב": "האדמורים ליינער"
        },
        {
            "_id": 25139,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 997,
            "שם_רחוב": "האדריכל"
        },
        {
            "_id": 25140,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 827,
            "שם_רחוב": "האדרת"
        },
        {
            "_id": 25141,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1170,
            "שם_רחוב": "האוזנר גדעון"
        },
        {
            "_id": 25142,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1839,
            "שם_רחוב": "האומן"
        },
        {
            "_id": 25143,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 896,
            "שם_רחוב": "האופה"
        },
        {
            "_id": 25144,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 918,
            "שם_רחוב": "האור"
        },
        {
            "_id": 25145,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1376,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 25146,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4547,
            "שם_רחוב": "האזוב"
        },
        {
            "_id": 25147,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1193,
            "שם_רחוב": "האחוה"
        },
        {
            "_id": 25148,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1294,
            "שם_רחוב": "האחות זלמה"
        },
        {
            "_id": 25149,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4535,
            "שם_רחוב": "האחות יהודית"
        },
        {
            "_id": 25150,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4063,
            "שם_רחוב": "האחים"
        },
        {
            "_id": 25151,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4583,
            "שם_רחוב": "האחים להרן"
        },
        {
            "_id": 25152,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1352,
            "שם_רחוב": "האייל"
        },
        {
            "_id": 25153,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2010,
            "שם_רחוב": "האירית"
        },
        {
            "_id": 25154,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4786,
            "שם_רחוב": "האלוף יקותיאל אדם"
        },
        {
            "_id": 25155,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4762,
            "שם_רחוב": "האלוף עוזי נרקיס"
        },
        {
            "_id": 25156,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4664,
            "שם_רחוב": "האמאם אבו הוריירה"
        },
        {
            "_id": 25157,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4607,
            "שם_רחוב": "האמאם אבו חניפה"
        },
        {
            "_id": 25158,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4608,
            "שם_רחוב": "האמאם אלחנבלי"
        },
        {
            "_id": 25159,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5356,
            "שם_רחוב": "האמאם אלחנבלי סמ1"
        },
        {
            "_id": 25160,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5357,
            "שם_רחוב": "האמאם אלחנבלי סמ2"
        },
        {
            "_id": 25161,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4604,
            "שם_רחוב": "האמאם אלמלכי"
        },
        {
            "_id": 25162,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5810,
            "שם_רחוב": "האמאם אלמלכי סמ1"
        },
        {
            "_id": 25163,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5811,
            "שם_רחוב": "האמאם אלמלכי סמ2"
        },
        {
            "_id": 25164,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5812,
            "שם_רחוב": "האמאם אלמלכי סמ3"
        },
        {
            "_id": 25165,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4606,
            "שם_רחוב": "האמאם שאפעי"
        },
        {
            "_id": 25166,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5096,
            "שם_רחוב": "האמאם שאפעי סמ3"
        },
        {
            "_id": 25167,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 520,
            "שם_רחוב": "האמונה"
        },
        {
            "_id": 25168,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1248,
            "שם_רחוב": "האמוראים"
        },
        {
            "_id": 25169,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 360,
            "שם_רחוב": "האמרכלים"
        },
        {
            "_id": 25170,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4542,
            "שם_רחוב": "האנפה"
        },
        {
            "_id": 25171,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 627,
            "שם_רחוב": "האפרסק"
        },
        {
            "_id": 25172,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 730,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 25173,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 830,
            "שם_רחוב": "הארי"
        },
        {
            "_id": 25174,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4758,
            "שם_רחוב": "הארבעה"
        },
        {
            "_id": 25175,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 938,
            "שם_רחוב": "הארגמן"
        },
        {
            "_id": 25176,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4320,
            "שם_רחוב": "הארון אל-רשיד"
        },
        {
            "_id": 25177,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1048,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 25178,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 633,
            "שם_רחוב": "הארנון"
        },
        {
            "_id": 25179,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4089,
            "שם_רחוב": "האשורים"
        },
        {
            "_id": 25180,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 619,
            "שם_רחוב": "האשכול"
        },
        {
            "_id": 25181,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1377,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 25182,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1940,
            "שם_רחוב": "הבבא סאלי"
        },
        {
            "_id": 25183,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4067,
            "שם_רחוב": "הבורסקאים"
        },
        {
            "_id": 25184,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4515,
            "שם_רחוב": "הביכורים"
        },
        {
            "_id": 25185,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1028,
            "שם_רחוב": "הבנאי"
        },
        {
            "_id": 25186,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 155,
            "שם_רחוב": "הבעשט"
        },
        {
            "_id": 25187,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1013,
            "שם_רחוב": "הברון הירש"
        },
        {
            "_id": 25188,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1709,
            "שם_רחוב": "הבריכה"
        },
        {
            "_id": 25189,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 392,
            "שם_רחוב": "הגאון מטורדא"
        },
        {
            "_id": 25190,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 727,
            "שם_רחוב": "הגאונים"
        },
        {
            "_id": 25191,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1624,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 25192,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1612,
            "שם_רחוב": "הגדנע"
        },
        {
            "_id": 25193,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4541,
            "שם_רחוב": "הגומא"
        },
        {
            "_id": 25194,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1032,
            "שם_רחוב": "הגיא"
        },
        {
            "_id": 25195,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 679,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 25196,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1858,
            "שם_רחוב": "הגלגל"
        },
        {
            "_id": 25197,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 648,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 25198,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1463,
            "שם_רחוב": "הגן הטכנולוגי"
        },
        {
            "_id": 25199,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4539,
            "שם_רחוב": "הגננת"
        },
        {
            "_id": 25200,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4573,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 25201,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 716,
            "שם_רחוב": "הגרא"
        },
        {
            "_id": 25202,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4766,
            "שם_רחוב": "הגשר החי"
        },
        {
            "_id": 25203,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4519,
            "שם_רחוב": "הגתית"
        },
        {
            "_id": 25204,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 634,
            "שם_רחוב": "הדאיה"
        },
        {
            "_id": 25205,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1356,
            "שם_רחוב": "הדב"
        },
        {
            "_id": 25206,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4563,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 25207,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1351,
            "שם_רחוב": "הדישון"
        },
        {
            "_id": 25208,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5448,
            "שם_רחוב": "הדמומית"
        },
        {
            "_id": 25209,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5016,
            "שם_רחוב": "הדף היומי"
        },
        {
            "_id": 25210,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1378,
            "שם_רחוב": "הדפנה"
        },
        {
            "_id": 25211,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 621,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 25212,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5903,
            "שם_רחוב": "הדרך האמריקאית"
        },
        {
            "_id": 25213,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5913,
            "שם_רחוב": "הדרך האמריקאית סמ10"
        },
        {
            "_id": 25214,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5920,
            "שם_רחוב": "הדרך האמריקאית סמ20"
        },
        {
            "_id": 25215,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5904,
            "שם_רחוב": "הדרך האמריקאית סמ1"
        },
        {
            "_id": 25216,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5914,
            "שם_רחוב": "הדרך האמריקאית סמ11"
        },
        {
            "_id": 25217,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5905,
            "שם_רחוב": "הדרך האמריקאית סמ2"
        },
        {
            "_id": 25218,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5915,
            "שם_רחוב": "הדרך האמריקאית סמ12"
        },
        {
            "_id": 25219,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5906,
            "שם_רחוב": "הדרך האמריקאית סמ3"
        },
        {
            "_id": 25220,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5916,
            "שם_רחוב": "הדרך האמריקאית סמ13"
        },
        {
            "_id": 25221,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5907,
            "שם_רחוב": "הדרך האמריקאית סמ4"
        },
        {
            "_id": 25222,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6917,
            "שם_רחוב": "הדרך האמריקאית סמ14"
        },
        {
            "_id": 25223,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5908,
            "שם_רחוב": "הדרך האמריקאית סמ5"
        },
        {
            "_id": 25224,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5909,
            "שם_רחוב": "הדרך האמריקאית סמ6"
        },
        {
            "_id": 25225,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5918,
            "שם_רחוב": "הדרך האמריקאית סמ16"
        },
        {
            "_id": 25226,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5910,
            "שם_רחוב": "הדרך האמריקאית סמ7"
        },
        {
            "_id": 25227,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5911,
            "שם_רחוב": "הדרך האמריקאית סמ8"
        },
        {
            "_id": 25228,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5919,
            "שם_רחוב": "הדרך האמריקאית סמ18"
        },
        {
            "_id": 25229,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5912,
            "שם_רחוב": "הדרך האמריקאית סמ9"
        },
        {
            "_id": 25230,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4587,
            "שם_רחוב": "ההבטחה"
        },
        {
            "_id": 25231,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4316,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 25232,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1379,
            "שם_רחוב": "ההדסים"
        },
        {
            "_id": 25233,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 434,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 25234,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5310,
            "שם_רחוב": "ההעברה"
        },
        {
            "_id": 25235,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5453,
            "שם_רחוב": "ההרדוף"
        },
        {
            "_id": 25236,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3057,
            "שם_רחוב": "ההתנדבויות"
        },
        {
            "_id": 25237,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4860,
            "שם_רחוב": "הולצברג שמחה"
        },
        {
            "_id": 25238,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1430,
            "שם_רחוב": "הועד הלאומי"
        },
        {
            "_id": 25239,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4594,
            "שם_רחוב": "הופיין אליעזר"
        },
        {
            "_id": 25240,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 195,
            "שם_רחוב": "הורביץ"
        },
        {
            "_id": 25241,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 416,
            "שם_רחוב": "הורקנוס יוחנן"
        },
        {
            "_id": 25242,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1334,
            "שם_רחוב": "הורקניה"
        },
        {
            "_id": 25243,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 302,
            "שם_רחוב": "הושע"
        },
        {
            "_id": 25244,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4618,
            "שם_רחוב": "הותיקן"
        },
        {
            "_id": 25245,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4854,
            "שם_רחוב": "הזהבית"
        },
        {
            "_id": 25246,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 337,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 25247,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1060,
            "שם_רחוב": "הזכרון"
        },
        {
            "_id": 25248,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1084,
            "שם_רחוב": "הזמיר"
        },
        {
            "_id": 25249,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4532,
            "שם_רחוב": "החבוש"
        },
        {
            "_id": 25250,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 415,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 25251,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5807,
            "שם_רחוב": "החוש"
        },
        {
            "_id": 25252,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 511,
            "שם_רחוב": "החומה השלישית"
        },
        {
            "_id": 25253,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1030,
            "שם_רחוב": "החוצב"
        },
        {
            "_id": 25254,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3060,
            "שם_רחוב": "החיל"
        },
        {
            "_id": 25255,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1616,
            "שם_רחוב": "החים"
        },
        {
            "_id": 25256,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1613,
            "שם_רחוב": "החיש"
        },
        {
            "_id": 25257,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1055,
            "שם_רחוב": "החידא"
        },
        {
            "_id": 25258,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1017,
            "שם_רחוב": "החלוץ"
        },
        {
            "_id": 25259,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1256,
            "שם_רחוב": "החלמית"
        },
        {
            "_id": 25260,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1288,
            "שם_רחוב": "החמניה"
        },
        {
            "_id": 25261,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5651,
            "שם_רחוב": "החנויות"
        },
        {
            "_id": 25262,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4556,
            "שם_רחוב": "החסידה"
        },
        {
            "_id": 25263,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4518,
            "שם_רחוב": "החצוצרות"
        },
        {
            "_id": 25264,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 614,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 25265,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1863,
            "שם_רחוב": "החרושת"
        },
        {
            "_id": 25266,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 653,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 25267,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1279,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 25268,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 257,
            "שם_רחוב": "החרש"
        },
        {
            "_id": 25269,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 338,
            "שם_רחוב": "החשמונאים"
        },
        {
            "_id": 25270,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 336,
            "שם_רחוב": "הטורים"
        },
        {
            "_id": 25271,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1713,
            "שם_רחוב": "הטחנה"
        },
        {
            "_id": 25272,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1410,
            "שם_רחוב": "הטייסים"
        },
        {
            "_id": 25273,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 637,
            "שם_רחוב": "היבוק"
        },
        {
            "_id": 25274,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4528,
            "שם_רחוב": "היהודים"
        },
        {
            "_id": 25275,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4678,
            "שם_רחוב": "היוצק"
        },
        {
            "_id": 25276,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4649,
            "שם_רחוב": "היזמה"
        },
        {
            "_id": 25277,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1702,
            "שם_רחוב": "היינריך היינה"
        },
        {
            "_id": 25278,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1007,
            "שם_רחוב": "היכל המשפט"
        },
        {
            "_id": 25279,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1647,
            "שם_רחוב": "הילדסהיימר עזריאל"
        },
        {
            "_id": 25280,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5355,
            "שם_רחוב": "הינד אל חוסייני"
        },
        {
            "_id": 25281,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5392,
            "שם_רחוב": "הינד אל חוסייני סמ2"
        },
        {
            "_id": 25282,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1456,
            "שם_רחוב": "היען"
        },
        {
            "_id": 25283,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4677,
            "שם_רחוב": "היצירה"
        },
        {
            "_id": 25284,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1734,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 25285,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 636,
            "שם_רחוב": "הירמוך"
        },
        {
            "_id": 25286,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 715,
            "שם_רחוב": "הירשנברג"
        },
        {
            "_id": 25287,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 308,
            "שם_רחוב": "הישיבה"
        },
        {
            "_id": 25288,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4394,
            "שם_רחוב": "הכביש להר חומה"
        },
        {
            "_id": 25289,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4396,
            "שם_רחוב": "הכורכים"
        },
        {
            "_id": 25290,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5806,
            "שם_רחוב": "הכותל"
        },
        {
            "_id": 25291,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5567,
            "שם_רחוב": "הכותל הקטן"
        },
        {
            "_id": 25292,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4095,
            "שם_רחוב": "הכלדיים"
        },
        {
            "_id": 25293,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1267,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 25294,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4526,
            "שם_רחוב": "הכנור"
        },
        {
            "_id": 25295,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4072,
            "שם_רחוב": "הכנסיות"
        },
        {
            "_id": 25296,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 171,
            "שם_רחוב": "הכנסת אורחים"
        },
        {
            "_id": 25297,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1354,
            "שם_רחוב": "הכפיר"
        },
        {
            "_id": 25298,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4057,
            "שם_רחוב": "הכרי"
        },
        {
            "_id": 25299,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1280,
            "שם_רחוב": "הכרכום"
        },
        {
            "_id": 25300,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 676,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 25301,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1607,
            "שם_רחוב": "הלה"
        },
        {
            "_id": 25302,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1516,
            "שם_רחוב": "הלב העברי"
        },
        {
            "_id": 25303,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 702,
            "שם_רחוב": "הלבנון"
        },
        {
            "_id": 25304,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4552,
            "שם_רחוב": "הלוט"
        },
        {
            "_id": 25305,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1022,
            "שם_רחוב": "הלוי אליעזר"
        },
        {
            "_id": 25306,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4702,
            "שם_רחוב": "הלוי שלמה (מומו)"
        },
        {
            "_id": 25307,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4385,
            "שם_רחוב": "הלטינים"
        },
        {
            "_id": 25308,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2006,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 25309,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 432,
            "שם_רחוב": "הלל"
        },
        {
            "_id": 25310,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1269,
            "שם_רחוב": "הלמן"
        },
        {
            "_id": 25311,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 404,
            "שם_רחוב": "הלני המלכה"
        },
        {
            "_id": 25312,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5921,
            "שם_רחוב": "הלסה"
        },
        {
            "_id": 25313,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5922,
            "שם_רחוב": "הלסה סמ1"
        },
        {
            "_id": 25314,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5923,
            "שם_רחוב": "הלסה סמ3"
        },
        {
            "_id": 25315,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5924,
            "שם_רחוב": "הלסה סמ4"
        },
        {
            "_id": 25316,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5925,
            "שם_רחוב": "הלסה סמ5"
        },
        {
            "_id": 25317,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5926,
            "שם_רחוב": "הלסה סמ6"
        },
        {
            "_id": 25318,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1652,
            "שם_רחוב": "הלפרין ישראל"
        },
        {
            "_id": 25319,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1423,
            "שם_רחוב": "הלר חיים"
        },
        {
            "_id": 25320,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 944,
            "שם_רחוב": "המג"
        },
        {
            "_id": 25321,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 919,
            "שם_רחוב": "המאסף"
        },
        {
            "_id": 25322,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 369,
            "שם_רחוב": "המביט"
        },
        {
            "_id": 25323,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5535,
            "שם_רחוב": "המבריא"
        },
        {
            "_id": 25324,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1711,
            "שם_רחוב": "המבשר"
        },
        {
            "_id": 25325,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1706,
            "שם_רחוב": "המגדל"
        },
        {
            "_id": 25326,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1650,
            "שם_רחוב": "המגיד"
        },
        {
            "_id": 25327,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4395,
            "שם_רחוב": "המדפיסים"
        },
        {
            "_id": 25328,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 651,
            "שם_רחוב": "המדרגות"
        },
        {
            "_id": 25329,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1941,
            "שם_רחוב": "המהרל מפראג"
        },
        {
            "_id": 25330,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 910,
            "שם_רחוב": "המהרמ מרוטנבורג"
        },
        {
            "_id": 25331,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5320,
            "שם_רחוב": "המובילים"
        },
        {
            "_id": 25332,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 920,
            "שם_רחוב": "המון"
        },
        {
            "_id": 25333,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1793,
            "שם_רחוב": "המוסכים"
        },
        {
            "_id": 25334,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4179,
            "שם_רחוב": "המור"
        },
        {
            "_id": 25335,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1044,
            "שם_רחוב": "המורה"
        },
        {
            "_id": 25336,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1846,
            "שם_רחוב": "המזרחן"
        },
        {
            "_id": 25337,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5460,
            "שם_רחוב": "המחנכת"
        },
        {
            "_id": 25338,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2013,
            "שם_רחוב": "המחתרות"
        },
        {
            "_id": 25339,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 495,
            "שם_רחוב": "המטבע היהודי"
        },
        {
            "_id": 25340,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1025,
            "שם_רחוב": "המיסדים"
        },
        {
            "_id": 25341,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1825,
            "שם_רחוב": "המישור"
        },
        {
            "_id": 25342,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4511,
            "שם_רחוב": "המלאך"
        },
        {
            "_id": 25343,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1718,
            "שם_רחוב": "המלאך בלבן"
        },
        {
            "_id": 25344,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 256,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 25345,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1061,
            "שם_רחוב": "המלבים"
        },
        {
            "_id": 25346,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1651,
            "שם_רחוב": "המליץ"
        },
        {
            "_id": 25347,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 429,
            "שם_רחוב": "המלך ג'ורג'"
        },
        {
            "_id": 25348,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4028,
            "שם_רחוב": "המלך פייצל"
        },
        {
            "_id": 25349,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1162,
            "שם_רחוב": "המלמד"
        },
        {
            "_id": 25350,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4502,
            "שם_רחוב": "הממונה"
        },
        {
            "_id": 25351,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1888,
            "שם_רחוב": "הממציא"
        },
        {
            "_id": 25352,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5540,
            "שם_רחוב": "המנזר האתיופי"
        },
        {
            "_id": 25353,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4077,
            "שם_רחוב": "המנזר היווני"
        },
        {
            "_id": 25354,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5575,
            "שם_רחוב": "המסגד"
        },
        {
            "_id": 25355,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 197,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 25356,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1380,
            "שם_רחוב": "המעיין"
        },
        {
            "_id": 25357,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 448,
            "שם_רחוב": "המעלות"
        },
        {
            "_id": 25358,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1421,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 25359,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1533,
            "שם_רחוב": "המערבים"
        },
        {
            "_id": 25360,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3263,
            "שם_רחוב": "המערות"
        },
        {
            "_id": 25361,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1832,
            "שם_רחוב": "המפקד"
        },
        {
            "_id": 25362,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5927,
            "שם_רחוב": "המפקד סמ1"
        },
        {
            "_id": 25363,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5928,
            "שם_רחוב": "המפקד סמ3"
        },
        {
            "_id": 25364,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5929,
            "שם_רחוב": "המפקד סמ5"
        },
        {
            "_id": 25365,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5930,
            "שם_רחוב": "המפקד סמ7"
        },
        {
            "_id": 25366,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1705,
            "שם_רחוב": "המצודה"
        },
        {
            "_id": 25367,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1615,
            "שם_רחוב": "המצור"
        },
        {
            "_id": 25368,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4524,
            "שם_רחוב": "המצילתיים"
        },
        {
            "_id": 25369,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4506,
            "שם_רחוב": "המקובלים"
        },
        {
            "_id": 25370,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1177,
            "שם_רחוב": "המר זבולון"
        },
        {
            "_id": 25371,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5501,
            "שם_רחוב": "המרפא"
        },
        {
            "_id": 25372,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1614,
            "שם_רחוב": "המשורינים"
        },
        {
            "_id": 25373,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4465,
            "שם_רחוב": "המשורר אצג"
        },
        {
            "_id": 25374,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4503,
            "שם_רחוב": "המשוררים"
        },
        {
            "_id": 25375,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1047,
            "שם_רחוב": "המשוררת"
        },
        {
            "_id": 25376,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4683,
            "שם_רחוב": "המשוררת זלדה"
        },
        {
            "_id": 25377,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1710,
            "שם_רחוב": "המשלט"
        },
        {
            "_id": 25378,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4290,
            "שם_רחוב": "המשעול האחרון"
        },
        {
            "_id": 25379,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 973,
            "שם_רחוב": "המתנחלים בהר"
        },
        {
            "_id": 25380,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 348,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 25381,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4522,
            "שם_רחוב": "הנבל"
        },
        {
            "_id": 25382,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4182,
            "שם_רחוב": "הנגבי שבתאי"
        },
        {
            "_id": 25383,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2506,
            "שם_רחוב": "הנגר"
        },
        {
            "_id": 25384,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1302,
            "שם_רחוב": "הנוטרים"
        },
        {
            "_id": 25385,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4321,
            "שם_רחוב": "הנוצרים"
        },
        {
            "_id": 25386,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1255,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 25387,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4680,
            "שם_רחוב": "הנחושת"
        },
        {
            "_id": 25388,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4308,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 25389,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1207,
            "שם_רחוב": "הנטקה"
        },
        {
            "_id": 25390,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4757,
            "שם_רחוב": "הניידות"
        },
        {
            "_id": 25391,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1102,
            "שם_רחוב": "הניצן"
        },
        {
            "_id": 25392,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1457,
            "שם_רחוב": "הנמר"
        },
        {
            "_id": 25393,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 691,
            "שם_רחוב": "הנציב"
        },
        {
            "_id": 25394,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1282,
            "שם_רחוב": "הנרד"
        },
        {
            "_id": 25395,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 828,
            "שם_רחוב": "הנשיא"
        },
        {
            "_id": 25396,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1172,
            "שם_רחוב": "הנשיא השישי"
        },
        {
            "_id": 25397,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1524,
            "שם_רחוב": "הס משה"
        },
        {
            "_id": 25398,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1260,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 25399,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1836,
            "שם_רחוב": "הסדנא"
        },
        {
            "_id": 25400,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5571,
            "שם_רחוב": "הסהר"
        },
        {
            "_id": 25401,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1031,
            "שם_רחוב": "הסוללים"
        },
        {
            "_id": 25402,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1043,
            "שם_רחוב": "הסופר"
        },
        {
            "_id": 25403,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 468,
            "שם_רחוב": "הסורג"
        },
        {
            "_id": 25404,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1261,
            "שם_רחוב": "הסחלב"
        },
        {
            "_id": 25405,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4850,
            "שם_רחוב": "הסיגלית"
        },
        {
            "_id": 25406,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4782,
            "שם_רחוב": "הסייר"
        },
        {
            "_id": 25407,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4765,
            "שם_רחוב": "הסיירת הירושלמית"
        },
        {
            "_id": 25408,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1257,
            "שם_רחוב": "הסיפן"
        },
        {
            "_id": 25409,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 822,
            "שם_רחוב": "הסלח"
        },
        {
            "_id": 25410,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 210,
            "שם_רחוב": "הסנהדרין"
        },
        {
            "_id": 25411,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4851,
            "שם_רחוב": "הסתונית"
        },
        {
            "_id": 25412,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1029,
            "שם_רחוב": "הסתת"
        },
        {
            "_id": 25413,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 506,
            "שם_רחוב": "העח"
        },
        {
            "_id": 25414,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4521,
            "שם_רחוב": "העוגב"
        },
        {
            "_id": 25415,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4514,
            "שם_רחוב": "העומר"
        },
        {
            "_id": 25416,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4681,
            "שם_רחוב": "העופרת"
        },
        {
            "_id": 25417,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1494,
            "שם_רחוב": "העטלף"
        },
        {
            "_id": 25418,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4703,
            "שם_רחוב": "העיר העתיקה"
        },
        {
            "_id": 25419,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5451,
            "שם_רחוב": "העירית"
        },
        {
            "_id": 25420,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1090,
            "שם_רחוב": "העלוי"
        },
        {
            "_id": 25421,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1122,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 25422,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 144,
            "שם_רחוב": "העמלים"
        },
        {
            "_id": 25423,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5404,
            "שם_רחוב": "העסקן"
        },
        {
            "_id": 25424,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 628,
            "שם_רחוב": "הערמונים"
        },
        {
            "_id": 25425,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3259,
            "שם_רחוב": "העשור"
        },
        {
            "_id": 25426,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1608,
            "שם_רחוב": "הפורצים"
        },
        {
            "_id": 25427,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5804,
            "שם_רחוב": "הפטרארכיה הארמנית"
        },
        {
            "_id": 25428,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3025,
            "שם_רחוב": "הפטריארכיה הקופטית"
        },
        {
            "_id": 25429,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 890,
            "שם_רחוב": "הפלאה"
        },
        {
            "_id": 25430,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1630,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 25431,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 537,
            "שם_רחוב": "הפנתרים השחורים"
        },
        {
            "_id": 25432,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1056,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 25433,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3054,
            "שם_רחוב": "הפרטיזנים"
        },
        {
            "_id": 25434,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1860,
            "שם_רחוב": "הפרסה"
        },
        {
            "_id": 25435,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 916,
            "שם_רחוב": "הצבי"
        },
        {
            "_id": 25436,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4853,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 25437,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4572,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 25438,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4463,
            "שם_רחוב": "הצדיק מטשכנוב"
        },
        {
            "_id": 25439,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4975,
            "שם_רחוב": "הצדיק משטפנשט"
        },
        {
            "_id": 25440,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4193,
            "שם_רחוב": "הצוף"
        },
        {
            "_id": 25441,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1296,
            "שם_רחוב": "הציונות"
        },
        {
            "_id": 25442,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1845,
            "שם_רחוב": "הצייר"
        },
        {
            "_id": 25443,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1496,
            "שם_רחוב": "הצייר יוסי"
        },
        {
            "_id": 25444,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1535,
            "שם_רחוב": "הצלם רחמים"
        },
        {
            "_id": 25445,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2002,
            "שם_רחוב": "הצלף"
        },
        {
            "_id": 25446,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 525,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 25447,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5452,
            "שם_רחוב": "הצפורן"
        },
        {
            "_id": 25448,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1627,
            "שם_רחוב": "הצפירה"
        },
        {
            "_id": 25449,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1381,
            "שם_רחוב": "הצפצפה"
        },
        {
            "_id": 25450,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1160,
            "שם_רחוב": "הקבלן"
        },
        {
            "_id": 25451,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4464,
            "שם_רחוב": "הקונגרס הציוני"
        },
        {
            "_id": 25452,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5802,
            "שם_רחוב": "הקופטים"
        },
        {
            "_id": 25453,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4548,
            "שם_רחוב": "הקורא"
        },
        {
            "_id": 25454,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 724,
            "שם_רחוב": "הקליר"
        },
        {
            "_id": 25455,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5572,
            "שם_רחוב": "הקמרונות"
        },
        {
            "_id": 25456,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4551,
            "שם_רחוב": "הקנה"
        },
        {
            "_id": 25457,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1834,
            "שם_רחוב": "הקצין סילבר"
        },
        {
            "_id": 25458,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4265,
            "שם_רחוב": "הקראים"
        },
        {
            "_id": 25459,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5576,
            "שם_רחוב": "הקרדו"
        },
        {
            "_id": 25460,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1141,
            "שם_רחוב": "הקריה רוממה"
        },
        {
            "_id": 25461,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 801,
            "שם_רחוב": "הקרן הקיימת"
        },
        {
            "_id": 25462,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4120,
            "שם_רחוב": "הר הבית"
        },
        {
            "_id": 25463,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4144,
            "שם_רחוב": "הר הזיתים"
        },
        {
            "_id": 25464,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1912,
            "שם_רחוב": "הר הזכרון"
        },
        {
            "_id": 25465,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3277,
            "שם_רחוב": "הר המשחה"
        },
        {
            "_id": 25466,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6821,
            "שם_רחוב": "הר הצופים"
        },
        {
            "_id": 25467,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6824,
            "שם_רחוב": "הר הצופים אידלסון"
        },
        {
            "_id": 25468,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6825,
            "שם_רחוב": "הר הצופים רזניק"
        },
        {
            "_id": 25469,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6823,
            "שם_רחוב": "הר הצופים ש אסבסט"
        },
        {
            "_id": 25470,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1072,
            "שם_רחוב": "הר הרצל"
        },
        {
            "_id": 25471,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "הר חרת"
        },
        {
            "_id": 25472,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6826,
            "שם_רחוב": "הר צופים גולדסמיט"
        },
        {
            "_id": 25473,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1715,
            "שם_רחוב": "הר ציון"
        },
        {
            "_id": 25474,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6862,
            "שם_רחוב": "הר שלמון"
        },
        {
            "_id": 25475,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הראם"
        },
        {
            "_id": 25476,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 932,
            "שם_רחוב": "הראובני דוד"
        },
        {
            "_id": 25477,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 231,
            "שם_רחוב": "הרב אבא שאול"
        },
        {
            "_id": 25478,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2201,
            "שם_רחוב": "הרב אבוהב"
        },
        {
            "_id": 25479,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1156,
            "שם_רחוב": "הרב אבן דנן"
        },
        {
            "_id": 25480,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3000,
            "שם_רחוב": "הרב אברהם זכות"
        },
        {
            "_id": 25481,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 548,
            "שם_רחוב": "הרב אברהם זקן"
        },
        {
            "_id": 25482,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 378,
            "שם_רחוב": "הרב אברהם חזן"
        },
        {
            "_id": 25483,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3038,
            "שם_רחוב": "הרב אברהם רביץ"
        },
        {
            "_id": 25484,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 992,
            "שם_רחוב": "הרב אברהם שפירא"
        },
        {
            "_id": 25485,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 370,
            "שם_רחוב": "הרב אברמסקי"
        },
        {
            "_id": 25486,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 424,
            "שם_רחוב": "הרב אגן"
        },
        {
            "_id": 25487,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4451,
            "שם_רחוב": "הרב אונטרמן"
        },
        {
            "_id": 25488,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5019,
            "שם_רחוב": "הרב אור שרגא"
        },
        {
            "_id": 25489,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 241,
            "שם_רחוב": "הרב אטון בן ציון"
        },
        {
            "_id": 25490,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2101,
            "שם_רחוב": "הרב אלבוחר חיים"
        },
        {
            "_id": 25491,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1814,
            "שם_רחוב": "הרב אלטשולר דוד"
        },
        {
            "_id": 25492,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1935,
            "שם_רחוב": "הרב אליעזר דיאבילה"
        },
        {
            "_id": 25493,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1171,
            "שם_רחוב": "הרב אליעזרי"
        },
        {
            "_id": 25494,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4963,
            "שם_רחוב": "הרב אלמושנינו"
        },
        {
            "_id": 25495,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4969,
            "שם_רחוב": "הרב אלנקווה"
        },
        {
            "_id": 25496,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6111,
            "שם_רחוב": "הרב אלעזר גלבשטיין"
        },
        {
            "_id": 25497,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1149,
            "שם_רחוב": "הרב אנגל וידאל"
        },
        {
            "_id": 25498,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 147,
            "שם_רחוב": "הרב אשלג"
        },
        {
            "_id": 25499,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 484,
            "שם_רחוב": "הרב בוקשפן"
        },
        {
            "_id": 25500,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1930,
            "שם_רחוב": "הרב בינה אריה"
        },
        {
            "_id": 25501,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הרב בלוי"
        },
        {
            "_id": 25502,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 229,
            "שם_רחוב": "הרב בנימין צביאלי"
        },
        {
            "_id": 25503,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 835,
            "שם_רחוב": "הרב ברודי"
        },
        {
            "_id": 25504,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 481,
            "שם_רחוב": "הרב בריכטא מנדל"
        },
        {
            "_id": 25505,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4959,
            "שם_רחוב": "הרב ברים"
        },
        {
            "_id": 25506,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 823,
            "שם_רחוב": "הרב ברלין"
        },
        {
            "_id": 25507,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4574,
            "שם_רחוב": "הרב ברמן יעקב"
        },
        {
            "_id": 25508,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4954,
            "שם_רחוב": "הרב גולדקנופף"
        },
        {
            "_id": 25509,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4372,
            "שם_רחוב": "הרב גורן שלמה"
        },
        {
            "_id": 25510,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 393,
            "שם_רחוב": "הרב גרוסברג חנוך"
        },
        {
            "_id": 25511,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 747,
            "שם_רחוב": "הרב דוד פוברסקי"
        },
        {
            "_id": 25512,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3024,
            "שם_רחוב": "הרב דוד שניאורסון"
        },
        {
            "_id": 25513,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 743,
            "שם_רחוב": "הרב דילרוזה חיים"
        },
        {
            "_id": 25514,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 265,
            "שם_רחוב": "הרב הומינר"
        },
        {
            "_id": 25515,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4956,
            "שם_רחוב": "הרב הירשלר"
        },
        {
            "_id": 25516,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4441,
            "שם_רחוב": "הרב הרץ יוסף צבי"
        },
        {
            "_id": 25517,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1422,
            "שם_רחוב": "הרב הרצוג"
        },
        {
            "_id": 25518,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 710,
            "שם_רחוב": "הרב והרבנית קאפח"
        },
        {
            "_id": 25519,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1898,
            "שם_רחוב": "הרב ויינגרטן אברהם"
        },
        {
            "_id": 25520,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 740,
            "שם_רחוב": "הרב וילהלם"
        },
        {
            "_id": 25521,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3016,
            "שם_רחוב": "הרב וינוגרד"
        },
        {
            "_id": 25522,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4955,
            "שם_רחוב": "הרב ז'ולטי בצלאל"
        },
        {
            "_id": 25523,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3014,
            "שם_רחוב": "הרב זווין"
        },
        {
            "_id": 25524,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4952,
            "שם_רחוב": "הרב חדש מאיר"
        },
        {
            "_id": 25525,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1016,
            "שם_רחוב": "הרב חוויתה כהן רחמי"
        },
        {
            "_id": 25526,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 273,
            "שם_רחוב": "הרב חיים שאול דוויק"
        },
        {
            "_id": 25527,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 138,
            "שם_רחוב": "הרב חכם שמעון"
        },
        {
            "_id": 25528,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 836,
            "שם_רחוב": "הרב חן"
        },
        {
            "_id": 25529,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1942,
            "שם_רחוב": "הרב חסדאי קרשקש"
        },
        {
            "_id": 25530,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4968,
            "שם_רחוב": "הרב טולדנו"
        },
        {
            "_id": 25531,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1070,
            "שם_רחוב": "הרב טייב חי"
        },
        {
            "_id": 25532,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3029,
            "שם_רחוב": "הרב יאדלר בן ציון"
        },
        {
            "_id": 25533,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1648,
            "שם_רחוב": "הרב יהודה עמיטל"
        },
        {
            "_id": 25534,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 967,
            "שם_רחוב": "הרב יוסף גבאי"
        },
        {
            "_id": 25535,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 498,
            "שם_רחוב": "הרב יחיא שניאור"
        },
        {
            "_id": 25536,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 639,
            "שם_רחוב": "הרב יצחק בראשי"
        },
        {
            "_id": 25537,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1808,
            "שם_רחוב": "הרב ישעיהו דולגין"
        },
        {
            "_id": 25538,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1804,
            "שם_רחוב": "הרב כדורי יצחק"
        },
        {
            "_id": 25539,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4957,
            "שם_רחוב": "הרב כהנמן"
        },
        {
            "_id": 25540,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1884,
            "שם_רחוב": "הרב כלפון משה הכהן"
        },
        {
            "_id": 25541,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4962,
            "שם_רחוב": "הרב לופיאן"
        },
        {
            "_id": 25542,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1663,
            "שם_רחוב": "הרב מוהליבר שמואל"
        },
        {
            "_id": 25543,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4976,
            "שם_רחוב": "הרב מוצפי יעקב"
        },
        {
            "_id": 25544,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1191,
            "שם_רחוב": "הרב מוצפי סלמן"
        },
        {
            "_id": 25545,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1068,
            "שם_רחוב": "הרב מימון"
        },
        {
            "_id": 25546,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1932,
            "שם_רחוב": "הרב מן ההר"
        },
        {
            "_id": 25547,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1549,
            "שם_רחוב": "הרב מנחם בצרי"
        },
        {
            "_id": 25548,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1542,
            "שם_רחוב": "הרב מניטו"
        },
        {
            "_id": 25549,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6110,
            "שם_רחוב": "הרב מרדכי פירון"
        },
        {
            "_id": 25550,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1758,
            "שם_רחוב": "הרב משה בן טוב"
        },
        {
            "_id": 25551,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1552,
            "שם_רחוב": "הרב משה צבי נריה"
        },
        {
            "_id": 25552,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הרב נאה"
        },
        {
            "_id": 25553,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 981,
            "שם_רחוב": "הרב נורוק"
        },
        {
            "_id": 25554,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1887,
            "שם_רחוב": "הרב ניסים יצחק"
        },
        {
            "_id": 25555,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 319,
            "שם_רחוב": "הרב ננס אליעזר"
        },
        {
            "_id": 25556,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 173,
            "שם_רחוב": "הרב סלנט"
        },
        {
            "_id": 25557,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 141,
            "שם_רחוב": "הרב עדני"
        },
        {
            "_id": 25558,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1059,
            "שם_רחוב": "הרב עוזיאל"
        },
        {
            "_id": 25559,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 909,
            "שם_רחוב": "הרב עזרן ניסים"
        },
        {
            "_id": 25560,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 737,
            "שם_רחוב": "הרב עלוואן שבתי"
        },
        {
            "_id": 25561,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1011,
            "שם_רחוב": "הרב עמיאל"
        },
        {
            "_id": 25562,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 712,
            "שם_רחוב": "הרב עראקי חיים"
        },
        {
            "_id": 25563,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4491,
            "שם_רחוב": "הרב פורת ישראל"
        },
        {
            "_id": 25564,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4965,
            "שם_רחוב": "הרב פטאל"
        },
        {
            "_id": 25565,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5533,
            "שם_רחוב": "הרב פלקסר יצחק"
        },
        {
            "_id": 25566,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3006,
            "שם_רחוב": "הרב פניז'ל"
        },
        {
            "_id": 25567,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1064,
            "שם_רחוב": "הרב פרנק"
        },
        {
            "_id": 25568,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1024,
            "שם_רחוב": "הרב צבי יהודה"
        },
        {
            "_id": 25569,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 272,
            "שם_רחוב": "הרב צדקה חוצ'ין"
        },
        {
            "_id": 25570,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4832,
            "שם_רחוב": "הרב קהן חיים"
        },
        {
            "_id": 25571,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1065,
            "שם_רחוב": "הרב קוסובסקי"
        },
        {
            "_id": 25572,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 421,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 25573,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4966,
            "שם_רחוב": "הרב קלונימוס"
        },
        {
            "_id": 25574,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4970,
            "שם_רחוב": "הרב קלכהיים עוזי"
        },
        {
            "_id": 25575,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1155,
            "שם_רחוב": "הרב קצנלבוגן רפאל"
        },
        {
            "_id": 25576,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1161,
            "שם_רחוב": "הרב רבין חזקיהו"
        },
        {
            "_id": 25577,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4487,
            "שם_רחוב": "הרב רובין"
        },
        {
            "_id": 25578,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1150,
            "שם_רחוב": "הרב רוזנטל דוד"
        },
        {
            "_id": 25579,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1300,
            "שם_רחוב": "הרב ריינס"
        },
        {
            "_id": 25580,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1080,
            "שם_רחוב": "הרב שאג"
        },
        {
            "_id": 25581,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 983,
            "שם_רחוב": "הרב שאולזון"
        },
        {
            "_id": 25582,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5003,
            "שם_רחוב": "הרב שאולי"
        },
        {
            "_id": 25583,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4469,
            "שם_רחוב": "הרב שבתאי חזקיה"
        },
        {
            "_id": 25584,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4486,
            "שם_רחוב": "הרב שיף אברהם"
        },
        {
            "_id": 25585,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 492,
            "שם_רחוב": "הרב שך"
        },
        {
            "_id": 25586,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3998,
            "שם_רחוב": "הרב שלום משאש"
        },
        {
            "_id": 25587,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 601,
            "שם_רחוב": "הרב שלמה וולבה"
        },
        {
            "_id": 25588,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 924,
            "שם_רחוב": "הרב שלמה וורטהיימר"
        },
        {
            "_id": 25589,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 616,
            "שם_רחוב": "הרב שמואל ברוך"
        },
        {
            "_id": 25590,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2009,
            "שם_רחוב": "הרב שמואל נתן"
        },
        {
            "_id": 25591,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1164,
            "שם_רחוב": "הרב שפרבר דוד"
        },
        {
            "_id": 25592,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1239,
            "שם_רחוב": "הרב שפרן"
        },
        {
            "_id": 25593,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4443,
            "שם_רחוב": "הרב שרייבר ישראל"
        },
        {
            "_id": 25594,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1391,
            "שם_רחוב": "הרב שרים יוסף"
        },
        {
            "_id": 25595,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 988,
            "שם_רחוב": "הרב שרירא"
        },
        {
            "_id": 25596,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 538,
            "שם_רחוב": "הרבנית חיה מושקא"
        },
        {
            "_id": 25597,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 381,
            "שם_רחוב": "הרדבז"
        },
        {
            "_id": 25598,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4430,
            "שם_רחוב": "הרואה"
        },
        {
            "_id": 25599,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6870,
            "שם_רחוב": "הרובע היהודי"
        },
        {
            "_id": 25600,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4323,
            "שם_רחוב": "הרובע הנוצרי"
        },
        {
            "_id": 25601,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4550,
            "שם_רחוב": "הרוסמרין"
        },
        {
            "_id": 25602,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 289,
            "שם_רחוב": "הרחמים"
        },
        {
            "_id": 25603,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5503,
            "שם_רחוב": "הרטום"
        },
        {
            "_id": 25604,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1837,
            "שם_רחוב": "הרכב"
        },
        {
            "_id": 25605,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1502,
            "שם_רחוב": "הרכבי"
        },
        {
            "_id": 25606,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1797,
            "שם_רחוב": "הרכבים"
        },
        {
            "_id": 25607,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1767,
            "שם_רחוב": "הרכבת"
        },
        {
            "_id": 25608,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2111,
            "שם_רחוב": "הרליץ יוסף"
        },
        {
            "_id": 25609,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 805,
            "שם_רחוב": "הרן"
        },
        {
            "_id": 25610,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1270,
            "שם_רחוב": "הרפוב הדומיניקנית"
        },
        {
            "_id": 25611,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1186,
            "שם_רחוב": "הרפז ניסן"
        },
        {
            "_id": 25612,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 188,
            "שם_רחוב": "הרקח"
        },
        {
            "_id": 25613,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 923,
            "שם_רחוב": "הרקמה"
        },
        {
            "_id": 25614,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1258,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 25615,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4842,
            "שם_רחוב": "הררי רפול"
        },
        {
            "_id": 25616,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1081,
            "שם_רחוב": "הרשדם"
        },
        {
            "_id": 25617,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1822,
            "שם_רחוב": "השגריר ארגוב שלמה"
        },
        {
            "_id": 25618,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1301,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 25619,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1458,
            "שם_רחוב": "השועל"
        },
        {
            "_id": 25620,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4505,
            "שם_רחוב": "השוערים"
        },
        {
            "_id": 25621,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1517,
            "שם_רחוב": "השופט בנימין"
        },
        {
            "_id": 25622,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1190,
            "שם_רחוב": "השופט חיים כהן"
        },
        {
            "_id": 25623,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4523,
            "שם_רחוב": "השופר"
        },
        {
            "_id": 25624,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 629,
            "שם_רחוב": "השזיף"
        },
        {
            "_id": 25625,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1609,
            "שם_רחוב": "השיירות"
        },
        {
            "_id": 25626,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1826,
            "שם_רחוב": "השילוח"
        },
        {
            "_id": 25627,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4817,
            "שם_רחוב": "השיריונאי"
        },
        {
            "_id": 25628,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4540,
            "שם_רחוב": "השיש"
        },
        {
            "_id": 25629,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4754,
            "שם_רחוב": "השישה עשר"
        },
        {
            "_id": 25630,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 721,
            "שם_רחוב": "השלה"
        },
        {
            "_id": 25631,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3027,
            "שם_רחוב": "השלום והאחדות"
        },
        {
            "_id": 25632,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4546,
            "שם_רחוב": "השליו"
        },
        {
            "_id": 25633,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5570,
            "שם_רחוב": "השליחים"
        },
        {
            "_id": 25634,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1287,
            "שם_רחוב": "השלכת"
        },
        {
            "_id": 25635,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4034,
            "שם_רחוב": "השלשלת"
        },
        {
            "_id": 25636,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4520,
            "שם_רחוב": "השמינית"
        },
        {
            "_id": 25637,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4061,
            "שם_רחוב": "השער החדש"
        },
        {
            "_id": 25638,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 623,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 25639,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 620,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 25640,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4545,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 25641,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 701,
            "שם_רחוב": "התבור"
        },
        {
            "_id": 25642,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5463,
            "שם_רחוב": "התבלין"
        },
        {
            "_id": 25643,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1089,
            "שם_רחוב": "התומר"
        },
        {
            "_id": 25644,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4525,
            "שם_רחוב": "התופים"
        },
        {
            "_id": 25645,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 630,
            "שם_רחוב": "התות"
        },
        {
            "_id": 25646,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4783,
            "שם_רחוב": "התותחן"
        },
        {
            "_id": 25647,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 818,
            "שם_רחוב": "התיבונים"
        },
        {
            "_id": 25648,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 940,
            "שם_רחוב": "התכלת"
        },
        {
            "_id": 25649,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1437,
            "שם_רחוב": "התלתן"
        },
        {
            "_id": 25650,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4508,
            "שם_רחוב": "התמיד"
        },
        {
            "_id": 25651,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4679,
            "שם_רחוב": "התמרוקים"
        },
        {
            "_id": 25652,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 739,
            "שם_רחוב": "התנאים"
        },
        {
            "_id": 25653,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1847,
            "שם_רחוב": "התנופה"
        },
        {
            "_id": 25654,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4164,
            "שם_רחוב": "התנחלות מוריה"
        },
        {
            "_id": 25655,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1840,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 25656,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 631,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 25657,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1703,
            "שם_רחוב": "התקוה"
        },
        {
            "_id": 25658,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1415,
            "שם_רחוב": "התקופה"
        },
        {
            "_id": 25659,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5172,
            "שם_רחוב": "ו.אום אל עמיד סמ1"
        },
        {
            "_id": 25660,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5173,
            "שם_רחוב": "ו.אום אל עמיד סמ2"
        },
        {
            "_id": 25661,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5238,
            "שם_רחוב": "ו.אום אל עמיד סמ5"
        },
        {
            "_id": 25662,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5243,
            "שם_רחוב": "ו.אום אל עמיד סמ6"
        },
        {
            "_id": 25663,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5653,
            "שם_רחוב": "ואדי אום אל עמיד"
        },
        {
            "_id": 25664,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4129,
            "שם_רחוב": "ואדי אל ג'וז"
        },
        {
            "_id": 25665,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5654,
            "שם_רחוב": "ואדי אל ח'לף"
        },
        {
            "_id": 25666,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5108,
            "שם_רחוב": "ואדי אל ח'לף סמ2"
        },
        {
            "_id": 25667,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4898,
            "שם_רחוב": "ואדי אל חומוס"
        },
        {
            "_id": 25668,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4230,
            "שם_רחוב": "ואדי אל נאר"
        },
        {
            "_id": 25669,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5510,
            "שם_רחוב": "ואדי אל רבאבה"
        },
        {
            "_id": 25670,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4411,
            "שם_רחוב": "ואדי התאומים"
        },
        {
            "_id": 25671,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4252,
            "שם_רחוב": "ואדי חילווה"
        },
        {
            "_id": 25672,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3267,
            "שם_רחוב": "ואדי חילווה סמ1"
        },
        {
            "_id": 25673,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3268,
            "שם_רחוב": "ואדי חילווה סמ2"
        },
        {
            "_id": 25674,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3269,
            "שם_רחוב": "ואדי חילווה סמ4"
        },
        {
            "_id": 25675,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3270,
            "שם_רחוב": "ואדי חילווה סמ6"
        },
        {
            "_id": 25676,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5515,
            "שם_רחוב": "ואדי יצול"
        },
        {
            "_id": 25677,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5932,
            "שם_רחוב": "ואדי יצול סמ1"
        },
        {
            "_id": 25678,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5933,
            "שם_רחוב": "ואדי יצול סמ2"
        },
        {
            "_id": 25679,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5934,
            "שם_רחוב": "ואדי יצול סמ3"
        },
        {
            "_id": 25680,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5935,
            "שם_רחוב": "ואדי יצול סמ4"
        },
        {
            "_id": 25681,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5936,
            "שם_רחוב": "ואדי יצול סמ5"
        },
        {
            "_id": 25682,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5937,
            "שם_רחוב": "ואדי יצול סמ6"
        },
        {
            "_id": 25683,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5938,
            "שם_רחוב": "ואדי יצול סמ7"
        },
        {
            "_id": 25684,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5939,
            "שם_רחוב": "ואדי יצול סמ8"
        },
        {
            "_id": 25685,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5940,
            "שם_רחוב": "ואדי יצול סמ9"
        },
        {
            "_id": 25686,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4158,
            "שם_רחוב": "ואדי מואזפין"
        },
        {
            "_id": 25687,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4400,
            "שם_רחוב": "ואדי עבדאללה"
        },
        {
            "_id": 25688,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3144,
            "שם_רחוב": "ואדי עבדאללה סמ1"
        },
        {
            "_id": 25689,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3145,
            "שם_רחוב": "ואדי עבדאללה סמ3"
        },
        {
            "_id": 25690,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3146,
            "שם_רחוב": "ואדי עבדאללה סמ4"
        },
        {
            "_id": 25691,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3147,
            "שם_רחוב": "ואדי עבדאללה סמ5"
        },
        {
            "_id": 25692,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3148,
            "שם_רחוב": "ואדי עבדאללה סמ6"
        },
        {
            "_id": 25693,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4147,
            "שם_רחוב": "ואדי קדום"
        },
        {
            "_id": 25694,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3196,
            "שם_רחוב": "ואדי קדום סמ10"
        },
        {
            "_id": 25695,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3190,
            "שם_רחוב": "ואדי קדום סמ2"
        },
        {
            "_id": 25696,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3191,
            "שם_רחוב": "ואדי קדום סמ3"
        },
        {
            "_id": 25697,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3192,
            "שם_רחוב": "ואדי קדום סמ4"
        },
        {
            "_id": 25698,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3193,
            "שם_רחוב": "ואדי קדום סמ5"
        },
        {
            "_id": 25699,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3194,
            "שם_רחוב": "ואדי קדום סמ6"
        },
        {
            "_id": 25700,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3195,
            "שם_רחוב": "ואדי קדום סמ8"
        },
        {
            "_id": 25701,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5511,
            "שם_רחוב": "ואדי קדרון"
        },
        {
            "_id": 25702,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1661,
            "שם_רחוב": "וודגווד"
        },
        {
            "_id": 25703,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1246,
            "שם_רחוב": "וולטה העלית"
        },
        {
            "_id": 25704,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 912,
            "שם_רחוב": "וולך"
        },
        {
            "_id": 25705,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4617,
            "שם_רחוב": "וורן צארלס"
        },
        {
            "_id": 25706,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4002,
            "שם_רחוב": "ויה דולורוזה"
        },
        {
            "_id": 25707,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 906,
            "שם_רחוב": "ויטל חיים"
        },
        {
            "_id": 25708,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1109,
            "שם_רחוב": "וייז שמואל"
        },
        {
            "_id": 25709,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1082,
            "שם_רחוב": "וייסברג חיים"
        },
        {
            "_id": 25710,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 893,
            "שם_רחוב": "וייץ יוסף"
        },
        {
            "_id": 25711,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1015,
            "שם_רחוב": "וילנאי זאב"
        },
        {
            "_id": 25712,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1660,
            "שם_רחוב": "וינדהם דידס"
        },
        {
            "_id": 25713,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1744,
            "שם_רחוב": "וינר אשר"
        },
        {
            "_id": 25714,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4338,
            "שם_רחוב": "ויס יעקב"
        },
        {
            "_id": 25715,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1071,
            "שם_רחוב": "ויסמן ליאו"
        },
        {
            "_id": 25716,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3010,
            "שם_רחוב": "ויקטור ויוליוס"
        },
        {
            "_id": 25717,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4493,
            "שם_רחוב": "ויתקון אלפרד"
        },
        {
            "_id": 25718,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1178,
            "שם_רחוב": "ולוס"
        },
        {
            "_id": 25719,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 605,
            "שם_רחוב": "ולירו"
        },
        {
            "_id": 25720,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 517,
            "שם_רחוב": "ולנברג ראול"
        },
        {
            "_id": 25721,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4485,
            "שם_רחוב": "ולנשטיין"
        },
        {
            "_id": 25722,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4802,
            "שם_רחוב": "ונטורה משה"
        },
        {
            "_id": 25723,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4611,
            "שם_רחוב": "ונסן לואי"
        },
        {
            "_id": 25724,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3018,
            "שם_רחוב": "וסרמן פנחס"
        },
        {
            "_id": 25725,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4483,
            "שם_רחוב": "ועד ארבע הארצות"
        },
        {
            "_id": 25726,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1217,
            "שם_רחוב": "ורבורג"
        },
        {
            "_id": 25727,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4180,
            "שם_רחוב": "ורדינון"
        },
        {
            "_id": 25728,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 193,
            "שם_רחוב": "ורהפטיג זרח"
        },
        {
            "_id": 25729,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1896,
            "שם_רחוב": "ורשבסקי אריה"
        },
        {
            "_id": 25730,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1526,
            "שם_רחוב": "ושינגטון"
        },
        {
            "_id": 25731,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1741,
            "שם_רחוב": "ושיץ"
        },
        {
            "_id": 25732,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1514,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 25733,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 266,
            "שם_רחוב": "זאב לב"
        },
        {
            "_id": 25734,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1782,
            "שם_רחוב": "זבולון"
        },
        {
            "_id": 25735,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4370,
            "שם_רחוב": "זהורית"
        },
        {
            "_id": 25736,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5931,
            "שם_רחוב": "זהרת אל-צבאח"
        },
        {
            "_id": 25737,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5941,
            "שם_רחוב": "זהרת אל-צבאח סמ1"
        },
        {
            "_id": 25738,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5942,
            "שם_רחוב": "זהרת אל-צבאח סמ2"
        },
        {
            "_id": 25739,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5943,
            "שם_רחוב": "זהרת אל-צבאח סמ4"
        },
        {
            "_id": 25740,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1042,
            "שם_רחוב": "זוטא"
        },
        {
            "_id": 25741,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4009,
            "שם_רחוב": "זויה א הונוד"
        },
        {
            "_id": 25742,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 143,
            "שם_רחוב": "זוננפלד"
        },
        {
            "_id": 25743,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1182,
            "שם_רחוב": "זוסמן"
        },
        {
            "_id": 25744,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4026,
            "שם_רחוב": "זוקוק א חילל"
        },
        {
            "_id": 25745,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "זיו יוסף"
        },
        {
            "_id": 25746,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3220,
            "שם_רחוב": "זייד בן חארת'ה"
        },
        {
            "_id": 25747,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3221,
            "שם_רחוב": "זייד בן חארת'ה סמ2"
        },
        {
            "_id": 25748,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3222,
            "שם_רחוב": "זייד בן חארת'ה סמ4"
        },
        {
            "_id": 25749,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3223,
            "שם_רחוב": "זייד בן חארת'ה סמ6"
        },
        {
            "_id": 25750,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4404,
            "שם_רחוב": "זייד ת'אבת"
        },
        {
            "_id": 25751,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1383,
            "שם_רחוב": "זילברג משה"
        },
        {
            "_id": 25752,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4781,
            "שם_רחוב": "זילברשטיין"
        },
        {
            "_id": 25753,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 987,
            "שם_רחוב": "זית רענן"
        },
        {
            "_id": 25754,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4134,
            "שם_רחוב": "זכלה"
        },
        {
            "_id": 25755,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 675,
            "שם_רחוב": "זכרון טוביה"
        },
        {
            "_id": 25756,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 964,
            "שם_רחוב": "זכרון יעקב"
        },
        {
            "_id": 25757,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 310,
            "שם_רחוב": "זכריה"
        },
        {
            "_id": 25758,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 132,
            "שם_רחוב": "זכריה הרופא"
        },
        {
            "_id": 25759,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 224,
            "שם_רחוב": "זלמן צורף"
        },
        {
            "_id": 25760,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1934,
            "שם_רחוב": "זלץ נתן"
        },
        {
            "_id": 25761,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4815,
            "שם_רחוב": "זלצברגר מכבי"
        },
        {
            "_id": 25762,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 467,
            "שם_רחוב": "זמורה"
        },
        {
            "_id": 25763,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5129,
            "שם_רחוב": "זמזם"
        },
        {
            "_id": 25764,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1525,
            "שם_רחוב": "זמנהוף"
        },
        {
            "_id": 25765,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2012,
            "שם_רחוב": "זנגויל"
        },
        {
            "_id": 25766,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4413,
            "שם_רחוב": "זעיין"
        },
        {
            "_id": 25767,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5249,
            "שם_רחוב": "זעקוקה"
        },
        {
            "_id": 25768,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5250,
            "שם_רחוב": "זעקוקה סמ1"
        },
        {
            "_id": 25769,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6251,
            "שם_רחוב": "זעקוקה סמ2"
        },
        {
            "_id": 25770,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5252,
            "שם_רחוב": "זעקוקה סמ3"
        },
        {
            "_id": 25771,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5253,
            "שם_רחוב": "זעקוקה סמ4"
        },
        {
            "_id": 25772,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4236,
            "שם_רחוב": "זעקיקה"
        },
        {
            "_id": 25773,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3246,
            "שם_רחוב": "זערורה"
        },
        {
            "_id": 25774,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3249,
            "שם_רחוב": "זערורה סמ1"
        },
        {
            "_id": 25775,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3250,
            "שם_רחוב": "זערורה סמ3"
        },
        {
            "_id": 25776,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 239,
            "שם_רחוב": "זקס משה"
        },
        {
            "_id": 25777,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4816,
            "שם_רחוב": "זר מרדכי"
        },
        {
            "_id": 25778,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1778,
            "שם_רחוב": "זרובבל"
        },
        {
            "_id": 25779,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4596,
            "שם_רחוב": "זרחי ישראל"
        },
        {
            "_id": 25780,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5020,
            "שם_רחוב": "זריצקי דוד"
        },
        {
            "_id": 25781,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 493,
            "שם_רחוב": "זרע שמשון"
        },
        {
            "_id": 25782,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4272,
            "שם_רחוב": "ח'אלד אבן אל ואליד"
        },
        {
            "_id": 25783,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4767,
            "שם_רחוב": "ח'ולפא' ראשדין"
        },
        {
            "_id": 25784,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5367,
            "שם_רחוב": "ח'ולפא' ראשדין סמ1"
        },
        {
            "_id": 25785,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5368,
            "שם_רחוב": "ח'ולפא' ראשדין סמ2"
        },
        {
            "_id": 25786,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4140,
            "שם_רחוב": "ח'ילת אל עין"
        },
        {
            "_id": 25787,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4647,
            "שם_רחוב": "ח'ליל א סכאכיני"
        },
        {
            "_id": 25788,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4096,
            "שם_רחוב": "ח'ליל אלח'אלדי"
        },
        {
            "_id": 25789,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5156,
            "שם_רחוב": "ח'ליל סכאכיני סמ11"
        },
        {
            "_id": 25790,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4979,
            "שם_רחוב": "ח'ליל סכאכיני סמ3"
        },
        {
            "_id": 25791,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4980,
            "שם_רחוב": "ח'ליל סכאכיני סמ5"
        },
        {
            "_id": 25792,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4981,
            "שם_רחוב": "ח'ליל סכאכיני סמ7"
        },
        {
            "_id": 25793,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4982,
            "שם_רחוב": "ח'ליל סכאכיני סמ9"
        },
        {
            "_id": 25794,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3083,
            "שם_רחוב": "ח'לת אל עין סמ1"
        },
        {
            "_id": 25795,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3084,
            "שם_רחוב": "ח'לת אל עין סמ2"
        },
        {
            "_id": 25796,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3085,
            "שם_רחוב": "ח'לת אל עין סמ3"
        },
        {
            "_id": 25797,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3086,
            "שם_רחוב": "ח'לת אל עין סמ4"
        },
        {
            "_id": 25798,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3087,
            "שם_רחוב": "ח'לת אל עין סמ5"
        },
        {
            "_id": 25799,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3088,
            "שם_רחוב": "ח'לת אל עין סמ7"
        },
        {
            "_id": 25800,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5655,
            "שם_רחוב": "ח'לת משעל"
        },
        {
            "_id": 25801,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4947,
            "שם_רחוב": "ח'לת משעל סמ10"
        },
        {
            "_id": 25802,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4949,
            "שם_רחוב": "ח'לת משעל סמ8"
        },
        {
            "_id": 25803,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4977,
            "שם_רחוב": "ח'לת משעל סמ9"
        },
        {
            "_id": 25804,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5587,
            "שם_רחוב": "ח'לת סינאד"
        },
        {
            "_id": 25805,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5053,
            "שם_רחוב": "ח'לת סינאד סמ4"
        },
        {
            "_id": 25806,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5052,
            "שם_רחוב": "ח'לת סינאד סמ6"
        },
        {
            "_id": 25807,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4710,
            "שם_רחוב": "ח'רובה"
        },
        {
            "_id": 25808,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5400,
            "שם_רחוב": "ח'רובה סמ5"
        },
        {
            "_id": 25809,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4231,
            "שם_רחוב": "חאלת אל לוזה"
        },
        {
            "_id": 25810,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5542,
            "שם_רחוב": "חאן אלסולטאן"
        },
        {
            "_id": 25811,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5577,
            "שם_רחוב": "חאפז איבראהים"
        },
        {
            "_id": 25812,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5107,
            "שם_רחוב": "חאפז איבראהים סמ3"
        },
        {
            "_id": 25813,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3149,
            "שם_רחוב": "חארת אל-נצארה"
        },
        {
            "_id": 25814,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3150,
            "שם_רחוב": "חארת אל-נצארה סמ1"
        },
        {
            "_id": 25815,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3151,
            "שם_רחוב": "חארת אל-נצארה סמ2"
        },
        {
            "_id": 25816,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4293,
            "שם_רחוב": "חאתם א טאי"
        },
        {
            "_id": 25817,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4365,
            "שם_רחוב": "חבד"
        },
        {
            "_id": 25818,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4726,
            "שם_רחוב": "חבאיל אל ערב"
        },
        {
            "_id": 25819,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5339,
            "שם_רחוב": "חבאיל אל ערב סמ2"
        },
        {
            "_id": 25820,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4326,
            "שם_רחוב": "חביב אבשלום"
        },
        {
            "_id": 25821,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2007,
            "שם_רחוב": "חביב חיים"
        },
        {
            "_id": 25822,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 375,
            "שם_רחוב": "חבקוק"
        },
        {
            "_id": 25823,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3045,
            "שם_רחוב": "חבר"
        },
        {
            "_id": 25824,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 170,
            "שם_רחוב": "חברת משניות"
        },
        {
            "_id": 25825,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 167,
            "שם_רחוב": "חברת שס"
        },
        {
            "_id": 25826,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 166,
            "שם_רחוב": "חברת תהילים"
        },
        {
            "_id": 25827,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 134,
            "שם_רחוב": "חבשוש"
        },
        {
            "_id": 25828,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4160,
            "שם_רחוב": "חגאזי"
        },
        {
            "_id": 25829,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 307,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 25830,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 352,
            "שם_רחוב": "חגיז משה"
        },
        {
            "_id": 25831,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1198,
            "שם_רחוב": "חובב משה"
        },
        {
            "_id": 25832,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1506,
            "שם_רחוב": "חובבי ציון"
        },
        {
            "_id": 25833,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4412,
            "שם_רחוב": "חוואש"
        },
        {
            "_id": 25834,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1721,
            "שם_רחוב": "חוות הלמוד"
        },
        {
            "_id": 25835,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 509,
            "שם_רחוב": "חולדה הנביאה"
        },
        {
            "_id": 25836,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1471,
            "שם_רחוב": "חומת הצלפים"
        },
        {
            "_id": 25837,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4310,
            "שם_רחוב": "חומת נחמיה"
        },
        {
            "_id": 25838,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6861,
            "שם_רחוב": "חומת שמואל"
        },
        {
            "_id": 25839,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 176,
            "שם_רחוב": "חוני המעגל"
        },
        {
            "_id": 25840,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4322,
            "שם_רחוב": "חוש א שוויש"
        },
        {
            "_id": 25841,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5543,
            "שם_רחוב": "חוש אבו פרחה"
        },
        {
            "_id": 25842,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3202,
            "שם_רחוב": "חוש אל-אעור"
        },
        {
            "_id": 25843,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3203,
            "שם_רחוב": "חוש אל-אעור סמ1"
        },
        {
            "_id": 25844,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3204,
            "שם_רחוב": "חוש אל-אעור סמ3"
        },
        {
            "_id": 25845,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3205,
            "שם_רחוב": "חוש אל-אעור סמ4"
        },
        {
            "_id": 25846,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5544,
            "שם_רחוב": "חוש אלבאן"
        },
        {
            "_id": 25847,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5545,
            "שם_רחוב": "חוש אלבחארי"
        },
        {
            "_id": 25848,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5546,
            "שם_רחוב": "חוש אלבטש"
        },
        {
            "_id": 25849,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5547,
            "שם_רחוב": "חוש אלבסטי"
        },
        {
            "_id": 25850,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5548,
            "שם_רחוב": "חוש אלוארי"
        },
        {
            "_id": 25851,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5550,
            "שם_רחוב": "חוש אלזארה"
        },
        {
            "_id": 25852,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5549,
            "שם_רחוב": "חוש אלזבאדי"
        },
        {
            "_id": 25853,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5551,
            "שם_רחוב": "חוש אלחטיב"
        },
        {
            "_id": 25854,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5552,
            "שם_רחוב": "חוש אלחילו"
        },
        {
            "_id": 25855,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5553,
            "שם_רחוב": "חוש אלמוקת"
        },
        {
            "_id": 25856,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5554,
            "שם_רחוב": "חוש אלמסעודי"
        },
        {
            "_id": 25857,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5555,
            "שם_רחוב": "חוש אלנחלה"
        },
        {
            "_id": 25858,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5556,
            "שם_רחוב": "חוש אלעג'לוני"
        },
        {
            "_id": 25859,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5557,
            "שם_רחוב": "חוש אלעדס"
        },
        {
            "_id": 25860,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5558,
            "שם_רחוב": "חוש אלקרקי"
        },
        {
            "_id": 25861,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5559,
            "שם_רחוב": "חוש אנצארי"
        },
        {
            "_id": 25862,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4899,
            "שם_רחוב": "חוש דבש"
        },
        {
            "_id": 25863,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5560,
            "שם_רחוב": "חוש דודו"
        },
        {
            "_id": 25864,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5561,
            "שם_רחוב": "חוש דקידק"
        },
        {
            "_id": 25865,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5562,
            "שם_רחוב": "חוש כרמי"
        },
        {
            "_id": 25866,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5563,
            "שם_רחוב": "חוש מונה"
        },
        {
            "_id": 25867,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5516,
            "שם_רחוב": "חוש מראע'ה"
        },
        {
            "_id": 25868,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5564,
            "שם_רחוב": "חוש משעל"
        },
        {
            "_id": 25869,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5565,
            "שם_רחוב": "חוש סריחאן"
        },
        {
            "_id": 25870,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5566,
            "שם_רחוב": "חוש שאהין"
        },
        {
            "_id": 25871,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1341,
            "שם_רחוב": "חות הנוער הציוני"
        },
        {
            "_id": 25872,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4953,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 25873,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1066,
            "שם_רחוב": "חזון ציון"
        },
        {
            "_id": 25874,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 187,
            "שם_רחוב": "חזנוביץ"
        },
        {
            "_id": 25875,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1601,
            "שם_רחוב": "חזקיהו המלך"
        },
        {
            "_id": 25876,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4769,
            "שם_רחוב": "חטיבת גבעתי"
        },
        {
            "_id": 25877,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 248,
            "שם_רחוב": "חטיבת הראל"
        },
        {
            "_id": 25878,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1833,
            "שם_רחוב": "חטיבת ירושלים"
        },
        {
            "_id": 25879,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4094,
            "שם_רחוב": "חטיבת עציוני"
        },
        {
            "_id": 25880,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3361,
            "שם_רחוב": "חי אל נעמאן"
        },
        {
            "_id": 25881,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3362,
            "שם_רחוב": "חי אל נעמאן סמ3"
        },
        {
            "_id": 25882,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5951,
            "שם_רחוב": "חי אל-מדארס"
        },
        {
            "_id": 25883,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5960,
            "שם_רחוב": "חי אל-מדארס סמ10"
        },
        {
            "_id": 25884,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5952,
            "שם_רחוב": "חי אל-מדארס סמ1"
        },
        {
            "_id": 25885,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5961,
            "שם_רחוב": "חי אל-מדארס סמ11"
        },
        {
            "_id": 25886,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5953,
            "שם_רחוב": "חי אל-מדארס סמ3"
        },
        {
            "_id": 25887,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5962,
            "שם_רחוב": "חי אל-מדארס סמ13"
        },
        {
            "_id": 25888,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5954,
            "שם_רחוב": "חי אל-מדארס סמ4"
        },
        {
            "_id": 25889,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5955,
            "שם_רחוב": "חי אל-מדארס סמ5"
        },
        {
            "_id": 25890,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5963,
            "שם_רחוב": "חי אל-מדארס סמ15"
        },
        {
            "_id": 25891,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5956,
            "שם_רחוב": "חי אל-מדארס סמ6"
        },
        {
            "_id": 25892,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5957,
            "שם_רחוב": "חי אל-מדארס סמ7"
        },
        {
            "_id": 25893,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5958,
            "שם_רחוב": "חי אל-מדארס סמ8"
        },
        {
            "_id": 25894,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5959,
            "שם_רחוב": "חי אל-מדארס סמ9"
        },
        {
            "_id": 25895,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3363,
            "שם_רחוב": "חי אלנעמאן סמ4"
        },
        {
            "_id": 25896,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3364,
            "שם_רחוב": "חי אלנעמאן סמ5"
        },
        {
            "_id": 25897,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3365,
            "שם_רחוב": "חי אלנעמאן סמ7"
        },
        {
            "_id": 25898,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5944,
            "שם_רחוב": "חי בשיר"
        },
        {
            "_id": 25899,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5945,
            "שם_רחוב": "חי בשיר סמ1"
        },
        {
            "_id": 25900,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5946,
            "שם_רחוב": "חי בשיר סמ2"
        },
        {
            "_id": 25901,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5947,
            "שם_רחוב": "חי בשיר סמ3"
        },
        {
            "_id": 25902,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5948,
            "שם_רחוב": "חי בשיר סמ4"
        },
        {
            "_id": 25903,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5949,
            "שם_רחוב": "חי בשיר סמ6"
        },
        {
            "_id": 25904,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5950,
            "שם_רחוב": "חי בשיר סמ8"
        },
        {
            "_id": 25905,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3329,
            "שם_רחוב": "חי זע'ייר"
        },
        {
            "_id": 25906,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3330,
            "שם_רחוב": "חי זע'ייר סמ1"
        },
        {
            "_id": 25907,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3331,
            "שם_רחוב": "חי זע'ייר סמ2"
        },
        {
            "_id": 25908,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3332,
            "שם_רחוב": "חי זע'ייר סמ3"
        },
        {
            "_id": 25909,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3333,
            "שם_רחוב": "חי זע'ייר סמ4"
        },
        {
            "_id": 25910,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3334,
            "שם_רחוב": "חי זע'ייר סמ6"
        },
        {
            "_id": 25911,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3274,
            "שם_רחוב": "חי ציאם"
        },
        {
            "_id": 25912,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3273,
            "שם_רחוב": "חי קראעין"
        },
        {
            "_id": 25913,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 137,
            "שם_רחוב": "חיבר"
        },
        {
            "_id": 25914,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 181,
            "שם_רחוב": "חיי אדם"
        },
        {
            "_id": 25915,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 220,
            "שם_רחוב": "חיים דוד הלוי"
        },
        {
            "_id": 25916,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 149,
            "שם_רחוב": "חיים עוזר"
        },
        {
            "_id": 25917,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4756,
            "שם_רחוב": "חיל האויר"
        },
        {
            "_id": 25918,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3949,
            "שם_רחוב": "חיל המודיעין"
        },
        {
            "_id": 25919,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4110,
            "שם_רחוב": "חיל הנדסה"
        },
        {
            "_id": 25920,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4770,
            "שם_רחוב": "חיל הקשר"
        },
        {
            "_id": 25921,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4771,
            "שם_רחוב": "חיל חמוש"
        },
        {
            "_id": 25922,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1618,
            "שם_רחוב": "חיל נשים"
        },
        {
            "_id": 25923,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 911,
            "שם_רחוב": "חירם"
        },
        {
            "_id": 25924,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4333,
            "שם_רחוב": "חכים אליהו"
        },
        {
            "_id": 25925,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1281,
            "שם_רחוב": "חכם אברהם"
        },
        {
            "_id": 25926,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 242,
            "שם_רחוב": "חכם עטיה עזרא"
        },
        {
            "_id": 25927,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 642,
            "שם_רחוב": "חכם שלום"
        },
        {
            "_id": 25928,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1196,
            "שם_רחוב": "חכמי יוסף"
        },
        {
            "_id": 25929,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 154,
            "שם_רחוב": "חכמי לובלין"
        },
        {
            "_id": 25930,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4214,
            "שם_רחוב": "חלאת חממה"
        },
        {
            "_id": 25931,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 674,
            "שם_רחוב": "חלחול"
        },
        {
            "_id": 25932,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5224,
            "שם_רחוב": "חלימה אל-סעדייה"
        },
        {
            "_id": 25933,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5225,
            "שם_רחוב": "חלימה אל-סעדייה סמ1"
        },
        {
            "_id": 25934,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5226,
            "שם_רחוב": "חלימה אל-סעדייה סמ2"
        },
        {
            "_id": 25935,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1827,
            "שם_רחוב": "חלץ"
        },
        {
            "_id": 25936,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 292,
            "שם_רחוב": "חלקת מחוקק"
        },
        {
            "_id": 25937,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4237,
            "שם_רחוב": "חלת גיריס"
        },
        {
            "_id": 25938,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5527,
            "שם_רחוב": "חמדאן עאיד"
        },
        {
            "_id": 25939,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3089,
            "שם_רחוב": "חמוש"
        },
        {
            "_id": 25940,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3090,
            "שם_רחוב": "חמוש סמ1"
        },
        {
            "_id": 25941,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3091,
            "שם_רחוב": "חמוש סמ3"
        },
        {
            "_id": 25942,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3092,
            "שם_רחוב": "חמוש סמ5"
        },
        {
            "_id": 25943,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 317,
            "שם_רחוב": "חנה"
        },
        {
            "_id": 25944,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1235,
            "שם_רחוב": "חנה רחל מלודמיר"
        },
        {
            "_id": 25945,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 279,
            "שם_רחוב": "חנינא שיף"
        },
        {
            "_id": 25946,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 174,
            "שם_רחוב": "חנן"
        },
        {
            "_id": 25947,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1363,
            "שם_רחוב": "חננאל"
        },
        {
            "_id": 25948,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1641,
            "שם_רחוב": "חנניה"
        },
        {
            "_id": 25949,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 178,
            "שם_רחוב": "חסד לאברהם"
        },
        {
            "_id": 25950,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1322,
            "שם_רחוב": "חסדא"
        },
        {
            "_id": 25951,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 412,
            "שם_רחוב": "חסון עוזי"
        },
        {
            "_id": 25952,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1259,
            "שם_רחוב": "חסידוב"
        },
        {
            "_id": 25953,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 218,
            "שם_רחוב": "חסידי קרלין"
        },
        {
            "_id": 25954,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 344,
            "שם_רחוב": "חפץ חיים"
        },
        {
            "_id": 25955,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 947,
            "שם_רחוב": "חפצדי נחום"
        },
        {
            "_id": 25956,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 649,
            "שם_רחוב": "חצור"
        },
        {
            "_id": 25957,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4097,
            "שם_רחוב": "חצר חלבי"
        },
        {
            "_id": 25958,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1810,
            "שם_רחוב": "חצרון"
        },
        {
            "_id": 25959,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1903,
            "שם_רחוב": "חקלאי זאב"
        },
        {
            "_id": 25960,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4244,
            "שם_רחוב": "חרבת ב סחור"
        },
        {
            "_id": 25961,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4224,
            "שם_רחוב": "חרבת סאלח"
        },
        {
            "_id": 25962,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 826,
            "שם_רחוב": "חרלפ"
        },
        {
            "_id": 25963,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1841,
            "שם_רחוב": "חרשי ברזל"
        },
        {
            "_id": 25964,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4406,
            "שם_רחוב": "חרת א בוס"
        },
        {
            "_id": 25965,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4405,
            "שם_רחוב": "חרת א תחתה"
        },
        {
            "_id": 25966,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 460,
            "שם_רחוב": "חשין"
        },
        {
            "_id": 25967,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 725,
            "שם_רחוב": "חתם סופר"
        },
        {
            "_id": 25968,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5488,
            "שם_רחוב": "טאבליא"
        },
        {
            "_id": 25969,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4675,
            "שם_רחוב": "טאלוק"
        },
        {
            "_id": 25970,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4209,
            "שם_רחוב": "טבאליא"
        },
        {
            "_id": 25971,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1329,
            "שם_רחוב": "טבי"
        },
        {
            "_id": 25972,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3003,
            "שם_רחוב": "טבנקין יצחק"
        },
        {
            "_id": 25973,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 704,
            "שם_רחוב": "טבריה"
        },
        {
            "_id": 25974,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1879,
            "שם_רחוב": "טדסקי גד"
        },
        {
            "_id": 25975,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4630,
            "שם_רחוב": "טהא חוסיין"
        },
        {
            "_id": 25976,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4945,
            "שם_רחוב": "טהא חוסיין סמ3"
        },
        {
            "_id": 25977,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1224,
            "שם_רחוב": "טהון"
        },
        {
            "_id": 25978,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1073,
            "שם_רחוב": "טוב משה"
        },
        {
            "_id": 25979,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1312,
            "שם_רחוב": "טוביה"
        },
        {
            "_id": 25980,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4616,
            "שם_רחוב": "טובלר"
        },
        {
            "_id": 25981,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4798,
            "שם_רחוב": "טוניק יצחק"
        },
        {
            "_id": 25982,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4600,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 25983,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1293,
            "שם_רחוב": "טור סיני"
        },
        {
            "_id": 25984,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 902,
            "שם_רחוב": "טור סיני זאב"
        },
        {
            "_id": 25985,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1707,
            "שם_רחוב": "טורא"
        },
        {
            "_id": 25986,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1200,
            "שם_רחוב": "טחורש כתריאל"
        },
        {
            "_id": 25987,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1427,
            "שם_רחוב": "טיומקין מרדכי"
        },
        {
            "_id": 25988,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 500,
            "שם_רחוב": "טיילת ג'ימס מקדונלד"
        },
        {
            "_id": 25989,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1865,
            "שם_רחוב": "טיילת גבריאל"
        },
        {
            "_id": 25990,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3266,
            "שם_רחוב": "טיילת גיא בן הינום"
        },
        {
            "_id": 25991,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1867,
            "שם_רחוב": "טיילת האז"
        },
        {
            "_id": 25992,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3272,
            "שם_רחוב": "טיילת עיר שלם"
        },
        {
            "_id": 25993,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3219,
            "שם_רחוב": "טיילת עמק צורים"
        },
        {
            "_id": 25994,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 455,
            "שם_רחוב": "טיכו"
        },
        {
            "_id": 25995,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5735,
            "שם_רחוב": "טלחה"
        },
        {
            "_id": 25996,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4436,
            "שם_רחוב": "טללים"
        },
        {
            "_id": 25997,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4900,
            "שם_רחוב": "טלעת אלמשהאד"
        },
        {
            "_id": 25998,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 225,
            "שם_רחוב": "טרודי בירגר"
        },
        {
            "_id": 25999,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4439,
            "שם_רחוב": "טרומן הרי"
        },
        {
            "_id": 26000,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 452,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 26001,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5331,
            "שם_רחוב": "טריק אל-מדארס"
        },
        {
            "_id": 26002,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3251,
            "שם_רחוב": "טריק אל-מדארס סמ1"
        },
        {
            "_id": 26003,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3252,
            "שם_רחוב": "טריק אל-מדארס סמ2"
        },
        {
            "_id": 26004,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1405,
            "שם_רחוב": "טשרניחובסקי"
        },
        {
            "_id": 26005,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1771,
            "שם_רחוב": "יאיר"
        },
        {
            "_id": 26006,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4291,
            "שם_רחוב": "יאקות אל חמווי"
        },
        {
            "_id": 26007,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4459,
            "שם_רחוב": "יגאל"
        },
        {
            "_id": 26008,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 383,
            "שם_רחוב": "יגיע כפים"
        },
        {
            "_id": 26009,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3279,
            "שם_רחוב": "יד אבשלום"
        },
        {
            "_id": 26010,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1911,
            "שם_רחוב": "יד ושם"
        },
        {
            "_id": 26011,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1842,
            "שם_רחוב": "יד חרוצים"
        },
        {
            "_id": 26012,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1603,
            "שם_רחוב": "יד מרדכי"
        },
        {
            "_id": 26013,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 946,
            "שם_רחוב": "יד שלמה"
        },
        {
            "_id": 26014,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4773,
            "שם_רחוב": "ידין יגאל"
        },
        {
            "_id": 26015,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1643,
            "שם_רחוב": "יהואש"
        },
        {
            "_id": 26016,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1757,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 26017,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4501,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 26018,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 341,
            "שם_רחוב": "יהודה המכבי"
        },
        {
            "_id": 26019,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1337,
            "שם_רחוב": "יהודה הנשיא"
        },
        {
            "_id": 26020,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 100,
            "שם_רחוב": "יהודה שפירא"
        },
        {
            "_id": 26021,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 347,
            "שם_רחוב": "יהודית"
        },
        {
            "_id": 26022,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 255,
            "שם_רחוב": "יהויריב"
        },
        {
            "_id": 26023,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1631,
            "שם_רחוב": "יהושע בן נון"
        },
        {
            "_id": 26024,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4777,
            "שם_רחוב": "יהושע יעקב"
        },
        {
            "_id": 26025,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 157,
            "שם_רחוב": "יהושפט"
        },
        {
            "_id": 26026,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4566,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 26027,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1645,
            "שם_רחוב": "יואב"
        },
        {
            "_id": 26028,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "יואל"
        },
        {
            "_id": 26029,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1908,
            "שם_רחוב": "יובב"
        },
        {
            "_id": 26030,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 223,
            "שם_רחוב": "יובל נאמן"
        },
        {
            "_id": 26031,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1321,
            "שם_רחוב": "יוחנן בן זכאי"
        },
        {
            "_id": 26032,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 363,
            "שם_רחוב": "יוחנן הסנדלר"
        },
        {
            "_id": 26033,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 413,
            "שם_רחוב": "יוחנן מגוש חלב"
        },
        {
            "_id": 26034,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1806,
            "שם_רחוב": "יוכבד"
        },
        {
            "_id": 26035,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 519,
            "שם_רחוב": "יוליאנוס קיסר"
        },
        {
            "_id": 26036,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 541,
            "שם_רחוב": "יוליה ורומן שפניוק"
        },
        {
            "_id": 26037,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 306,
            "שם_רחוב": "יונה"
        },
        {
            "_id": 26038,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1638,
            "שם_רחוב": "יונתן"
        },
        {
            "_id": 26039,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4553,
            "שם_רחוב": "יוסטמן משה"
        },
        {
            "_id": 26040,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1314,
            "שם_רחוב": "יוסי בן יועזר"
        },
        {
            "_id": 26041,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 322,
            "שם_רחוב": "יוסף בן מתתיהו"
        },
        {
            "_id": 26042,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 544,
            "שם_רחוב": "יוסף גוטפרוינד"
        },
        {
            "_id": 26043,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 335,
            "שם_רחוב": "יוסף הנשיא"
        },
        {
            "_id": 26044,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 297,
            "שם_רחוב": "יוסף ויטלזון"
        },
        {
            "_id": 26045,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 664,
            "שם_רחוב": "יוסף חיים"
        },
        {
            "_id": 26046,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3197,
            "שם_רחוב": "יוסף פתיחה"
        },
        {
            "_id": 26047,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3198,
            "שם_רחוב": "יוסף פתיחה סמ1"
        },
        {
            "_id": 26048,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3199,
            "שם_רחוב": "יוסף פתיחה סמ2"
        },
        {
            "_id": 26049,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4808,
            "שם_רחוב": "יוספטל גיורא"
        },
        {
            "_id": 26050,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1617,
            "שם_רחוב": "יורדי הסירה"
        },
        {
            "_id": 26051,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 285,
            "שם_רחוב": "יורם ורונה קרן"
        },
        {
            "_id": 26052,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1343,
            "שם_רחוב": "יורם רונן"
        },
        {
            "_id": 26053,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4806,
            "שם_רחוב": "יושע מאיר"
        },
        {
            "_id": 26054,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1632,
            "שם_רחוב": "יותם"
        },
        {
            "_id": 26055,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 697,
            "שם_רחוב": "יזרעאל"
        },
        {
            "_id": 26056,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "יחזקאל"
        },
        {
            "_id": 26057,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1395,
            "שם_רחוב": "יחיל חיים"
        },
        {
            "_id": 26058,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1130,
            "שם_רחוב": "ייבין יהושע"
        },
        {
            "_id": 26059,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 470,
            "שם_רחוב": "ילון חנוך"
        },
        {
            "_id": 26060,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 376,
            "שם_רחוב": "ילין דוד"
        },
        {
            "_id": 26061,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1817,
            "שם_רחוב": "ים המלח"
        },
        {
            "_id": 26062,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4279,
            "שם_רחוב": "ים סוף"
        },
        {
            "_id": 26063,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1497,
            "שם_רחוב": "ימימה"
        },
        {
            "_id": 26064,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 935,
            "שם_רחוב": "ימין אבות"
        },
        {
            "_id": 26065,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1701,
            "שם_רחוב": "ימין משה"
        },
        {
            "_id": 26066,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 414,
            "שם_רחוב": "ינאי"
        },
        {
            "_id": 26067,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5405,
            "שם_רחוב": "ינובסקי דניאל"
        },
        {
            "_id": 26068,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3553,
            "שם_רחוב": "יסקי חיים"
        },
        {
            "_id": 26069,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 438,
            "שם_רחוב": "יעבץ"
        },
        {
            "_id": 26070,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1765,
            "שם_רחוב": "יעל"
        },
        {
            "_id": 26071,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2129,
            "שם_רחוב": "יעקב אלעזר"
        },
        {
            "_id": 26072,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1933,
            "שם_רחוב": "יעקב ונחמה אבן חן"
        },
        {
            "_id": 26073,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 354,
            "שם_רחוב": "יעקב מאיר"
        },
        {
            "_id": 26074,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 395,
            "שם_רחוב": "יעקבזון"
        },
        {
            "_id": 26075,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1094,
            "שם_רחוב": "יער ירושלים"
        },
        {
            "_id": 26076,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4537,
            "שם_רחוב": "יערי אברהם"
        },
        {
            "_id": 26077,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1051,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 26078,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 529,
            "שם_רחוב": "יפה עינים"
        },
        {
            "_id": 26079,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4543,
            "שם_רחוב": "יפה רום"
        },
        {
            "_id": 26080,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4799,
            "שם_רחוב": "יפה שמואל"
        },
        {
            "_id": 26081,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 263,
            "שם_רחוב": "יפהן אברהם"
        },
        {
            "_id": 26082,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 612,
            "שם_רחוב": "יפו"
        },
        {
            "_id": 26083,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1768,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 26084,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4579,
            "שם_רחוב": "יצהר"
        },
        {
            "_id": 26085,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1520,
            "שם_רחוב": "יצחק אלחנן"
        },
        {
            "_id": 26086,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 547,
            "שם_רחוב": "יצחק ארנסט נבנצל"
        },
        {
            "_id": 26087,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1537,
            "שם_רחוב": "יצחק בן נחום"
        },
        {
            "_id": 26088,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4352,
            "שם_רחוב": "יצחק הנדיב"
        },
        {
            "_id": 26089,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 496,
            "שם_רחוב": "יצחק יעקב לויכטר"
        },
        {
            "_id": 26090,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 192,
            "שם_רחוב": "יצחק שמיר"
        },
        {
            "_id": 26091,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3042,
            "שם_רחוב": "יציאת אירופה"
        },
        {
            "_id": 26092,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4650,
            "שם_רחוב": "יציב"
        },
        {
            "_id": 26093,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 213,
            "שם_רחוב": "יקים"
        },
        {
            "_id": 26094,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5449,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 26095,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 647,
            "שם_רחוב": "יראון"
        },
        {
            "_id": 26096,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 26097,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 937,
            "שם_רחוב": "ירמיהו"
        },
        {
            "_id": 26098,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 127,
            "שם_רחוב": "ישא ברכה"
        },
        {
            "_id": 26099,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 169,
            "שם_רחוב": "ישועת יעקב"
        },
        {
            "_id": 26100,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1719,
            "שם_רחוב": "ישי"
        },
        {
            "_id": 26101,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1855,
            "שם_רחוב": "ישיבת איתרי"
        },
        {
            "_id": 26102,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 635,
            "שם_רחוב": "ישיבת בעלז"
        },
        {
            "_id": 26103,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4292,
            "שם_רחוב": "ישיבת הכותל"
        },
        {
            "_id": 26104,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 457,
            "שם_רחוב": "ישיבת מרכז הרב"
        },
        {
            "_id": 26105,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 531,
            "שם_רחוב": "ישמח מלך"
        },
        {
            "_id": 26106,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 349,
            "שם_רחוב": "ישעיהו"
        },
        {
            "_id": 26107,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 359,
            "שם_רחוב": "ישעיהו פרס"
        },
        {
            "_id": 26108,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1875,
            "שם_רחוב": "ישראל אלדד"
        },
        {
            "_id": 26109,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 718,
            "שם_רחוב": "ישראלס"
        },
        {
            "_id": 26110,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1781,
            "שם_רחוב": "יששכר"
        },
        {
            "_id": 26111,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5736,
            "שם_רחוב": "יתרב"
        },
        {
            "_id": 26112,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5737,
            "שם_רחוב": "יתרב סמ1"
        },
        {
            "_id": 26113,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4655,
            "שם_רחוב": "יתרון"
        },
        {
            "_id": 26114,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 295,
            "שם_רחוב": "כח באייר"
        },
        {
            "_id": 26115,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1165,
            "שם_רחוב": "כביש מס 7"
        },
        {
            "_id": 26116,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4416,
            "שם_רחוב": "כביש מס' 60"
        },
        {
            "_id": 26117,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 358,
            "שם_רחוב": "כהן אפרים"
        },
        {
            "_id": 26118,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 720,
            "שם_רחוב": "כהנוב"
        },
        {
            "_id": 26119,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1620,
            "שם_רחוב": "כובשי קטמון"
        },
        {
            "_id": 26120,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 644,
            "שם_רחוב": "כורזים"
        },
        {
            "_id": 26121,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 409,
            "שם_רחוב": "כורש"
        },
        {
            "_id": 26122,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1623,
            "שם_רחוב": "כט' בנובמבר"
        },
        {
            "_id": 26123,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 391,
            "שם_רחוב": "כי טוב"
        },
        {
            "_id": 26124,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 618,
            "שם_רחוב": "כיח"
        },
        {
            "_id": 26125,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6114,
            "שם_רחוב": "כיכר אליה קהואג'יאן"
        },
        {
            "_id": 26126,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6071,
            "שם_רחוב": "כיכר אליקים בן מנחם"
        },
        {
            "_id": 26127,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6088,
            "שם_רחוב": "כיכר אנדרה שוראקי"
        },
        {
            "_id": 26128,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6085,
            "שם_רחוב": "כיכר ארצות הברית"
        },
        {
            "_id": 26129,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6073,
            "שם_רחוב": "כיכר גוש קטיף"
        },
        {
            "_id": 26130,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6069,
            "שם_רחוב": "כיכר גינטר פרידלנדר"
        },
        {
            "_id": 26131,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6076,
            "שם_רחוב": "כיכר גפן"
        },
        {
            "_id": 26132,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6093,
            "שם_רחוב": "כיכר דב שפרלינג"
        },
        {
            "_id": 26133,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6113,
            "שם_רחוב": "כיכר דבורה דוסט"
        },
        {
            "_id": 26134,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6098,
            "שם_רחוב": "כיכר הגבורה"
        },
        {
            "_id": 26135,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6089,
            "שם_רחוב": "כיכר הלוחם הדרוזי"
        },
        {
            "_id": 26136,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6252,
            "שם_רחוב": "כיכר העלוי מטולוצין"
        },
        {
            "_id": 26137,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6108,
            "שם_רחוב": "כיכר הרב דב לוי"
        },
        {
            "_id": 26138,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6115,
            "שם_רחוב": "כיכר הרב זילברמן"
        },
        {
            "_id": 26139,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6082,
            "שם_רחוב": "כיכר הרב יהודה צדקה"
        },
        {
            "_id": 26140,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6103,
            "שם_רחוב": "כיכר השולמית"
        },
        {
            "_id": 26141,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6102,
            "שם_רחוב": "כיכר ויקטור שם טוב"
        },
        {
            "_id": 26142,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6078,
            "שם_רחוב": "כיכר זית"
        },
        {
            "_id": 26143,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6075,
            "שם_רחוב": "כיכר חיטה"
        },
        {
            "_id": 26144,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6087,
            "שם_רחוב": "כיכר חנדלי"
        },
        {
            "_id": 26145,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6107,
            "שם_רחוב": "כיכר יהודה עמיחי"
        },
        {
            "_id": 26146,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6063,
            "שם_רחוב": "כיכר יהודה קיל"
        },
        {
            "_id": 26147,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6083,
            "שם_רחוב": "כיכר יהושע זלטר"
        },
        {
            "_id": 26148,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6086,
            "שם_רחוב": "כיכר יוסי בנאי"
        },
        {
            "_id": 26149,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6211,
            "שם_רחוב": "כיכר יוסי קירמה"
        },
        {
            "_id": 26150,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6118,
            "שם_רחוב": "כיכר יוסף ויסברג"
        },
        {
            "_id": 26151,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6106,
            "שם_רחוב": "כיכר יוסקה שפירא"
        },
        {
            "_id": 26152,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6072,
            "שם_רחוב": "כיכר יעקב גיל"
        },
        {
            "_id": 26153,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6081,
            "שם_רחוב": "כיכר יעקב כץ"
        },
        {
            "_id": 26154,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6105,
            "שם_רחוב": "כיכר לוחמי האש"
        },
        {
            "_id": 26155,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6092,
            "שם_רחוב": "כיכר מאיר מדן"
        },
        {
            "_id": 26156,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6070,
            "שם_רחוב": "כיכר מארק שאגאל"
        },
        {
            "_id": 26157,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6055,
            "שם_רחוב": "כיכר מרדכי חאיק"
        },
        {
            "_id": 26158,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6117,
            "שם_רחוב": "כיכר מרדכי רענן"
        },
        {
            "_id": 26159,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6109,
            "שם_רחוב": "כיכר משה יפה"
        },
        {
            "_id": 26160,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6112,
            "שם_רחוב": "כיכר משפחת היימן"
        },
        {
            "_id": 26161,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6061,
            "שם_רחוב": "כיכר נח(הנריק)פלוג"
        },
        {
            "_id": 26162,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6094,
            "שם_רחוב": "כיכר סוזה מנדס"
        },
        {
            "_id": 26163,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6084,
            "שם_רחוב": "כיכר סוללי הבירה"
        },
        {
            "_id": 26164,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6068,
            "שם_רחוב": "כיכר פאול רויטמן"
        },
        {
            "_id": 26165,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6116,
            "שם_רחוב": "כיכר פלורה ששון"
        },
        {
            "_id": 26166,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6077,
            "שם_רחוב": "כיכר רימון"
        },
        {
            "_id": 26167,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6096,
            "שם_רחוב": "כיכר רפי וקנין"
        },
        {
            "_id": 26168,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6097,
            "שם_רחוב": "כיכר שושנה קלינר"
        },
        {
            "_id": 26169,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6079,
            "שם_רחוב": "כיכר שעורה"
        },
        {
            "_id": 26170,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6099,
            "שם_רחוב": "כיכר שרה קמינקר"
        },
        {
            "_id": 26171,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6074,
            "שם_רחוב": "כיכר תאנה"
        },
        {
            "_id": 26172,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6080,
            "שם_רחוב": "כיכר תמר"
        },
        {
            "_id": 26173,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6032,
            "שם_רחוב": "ככר אברהם אבן שושן"
        },
        {
            "_id": 26174,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6034,
            "שם_רחוב": "ככר אברהם יצחק הל-א"
        },
        {
            "_id": 26175,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6035,
            "שם_רחוב": "ככר אהבת ישראל"
        },
        {
            "_id": 26176,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6036,
            "שם_רחוב": "ככר אהרון ליפשיץ"
        },
        {
            "_id": 26177,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "ככר אוסטר"
        },
        {
            "_id": 26178,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6037,
            "שם_רחוב": "ככר אורד ויינגייט"
        },
        {
            "_id": 26179,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6038,
            "שם_רחוב": "ככר אזרח מור"
        },
        {
            "_id": 26180,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "ככר אל גזאלי"
        },
        {
            "_id": 26181,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6101,
            "שם_רחוב": "ככר אל מנטאר"
        },
        {
            "_id": 26182,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6039,
            "שם_רחוב": "ככר אליהו אלישר"
        },
        {
            "_id": 26183,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6040,
            "שם_רחוב": "ככר אליהו ארמוזה"
        },
        {
            "_id": 26184,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6041,
            "שם_רחוב": "ככר אלכסנדר היידן"
        },
        {
            "_id": 26185,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "ככר אלנבי"
        },
        {
            "_id": 26186,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6042,
            "שם_רחוב": "ככר אמרי שפר"
        },
        {
            "_id": 26187,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6028,
            "שם_רחוב": "ככר אנה טיכו"
        },
        {
            "_id": 26188,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6043,
            "שם_רחוב": "ככר אפרים די זהב"
        },
        {
            "_id": 26189,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6044,
            "שם_רחוב": "ככר אקצין בנימין"
        },
        {
            "_id": 26190,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6045,
            "שם_רחוב": "ככר ארוין שאול שומר"
        },
        {
            "_id": 26191,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6056,
            "שם_רחוב": "ככר אריה ברוכיאלי"
        },
        {
            "_id": 26192,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6047,
            "שם_רחוב": "ככר אריה זקס"
        },
        {
            "_id": 26193,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6049,
            "שם_רחוב": "ככר ארמין שוורץ"
        },
        {
            "_id": 26194,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "ככר ארן זלמן"
        },
        {
            "_id": 26195,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6050,
            "שם_רחוב": "ככר ארצי שרמן"
        },
        {
            "_id": 26196,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6065,
            "שם_רחוב": "ככר אשבל דב"
        },
        {
            "_id": 26197,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6051,
            "שם_רחוב": "ככר בולגריה"
        },
        {
            "_id": 26198,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6052,
            "שם_רחוב": "ככר בוסטון"
        },
        {
            "_id": 26199,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6053,
            "שם_רחוב": "ככר בית החיים"
        },
        {
            "_id": 26200,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6026,
            "שם_רחוב": "ככר בלומנטל"
        },
        {
            "_id": 26201,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6054,
            "שם_רחוב": "ככר בן ציון גיני"
        },
        {
            "_id": 26202,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6046,
            "שם_רחוב": "ככר בנימין שחור"
        },
        {
            "_id": 26203,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6057,
            "שם_רחוב": "ככר בעלזא"
        },
        {
            "_id": 26204,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר בר כוכבא"
        },
        {
            "_id": 26205,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6058,
            "שם_רחוב": "ככר ברוך שוחטמן"
        },
        {
            "_id": 26206,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6059,
            "שם_רחוב": "ככר בתי מחסה"
        },
        {
            "_id": 26207,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "ככר ג'ורג' מיני"
        },
        {
            "_id": 26208,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6060,
            "שם_רחוב": "ככר גאלה גליקסיון"
        },
        {
            "_id": 26209,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6062,
            "שם_רחוב": "ככר גדוד בית חורון"
        },
        {
            "_id": 26210,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "ככר גדוד מוריה"
        },
        {
            "_id": 26211,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "ככר גולי קפריסין"
        },
        {
            "_id": 26212,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6064,
            "שם_רחוב": "ככר גיורא לוטן"
        },
        {
            "_id": 26213,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6009,
            "שם_רחוב": "ככר גייקובסון אדי"
        },
        {
            "_id": 26214,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6010,
            "שם_רחוב": "ככר דוד הנבי"
        },
        {
            "_id": 26215,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6029,
            "שם_רחוב": "ככר דוד מרדכי מאיר"
        },
        {
            "_id": 26216,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6066,
            "שם_רחוב": "ככר דוד(דאצא)אלקלעי"
        },
        {
            "_id": 26217,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6011,
            "שם_רחוב": "ככר דניה"
        },
        {
            "_id": 26218,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6012,
            "שם_רחוב": "ככר הארמני"
        },
        {
            "_id": 26219,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6031,
            "שם_רחוב": "ככר החורבה"
        },
        {
            "_id": 26220,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6067,
            "שם_רחוב": "ככר הרב מאיר יהודה"
        },
        {
            "_id": 26221,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6013,
            "שם_רחוב": "ככר הרב קוק"
        },
        {
            "_id": 26222,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6014,
            "שם_רחוב": "ככר מגנס"
        },
        {
            "_id": 26223,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6015,
            "שם_רחוב": "ככר נורדאו"
        },
        {
            "_id": 26224,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6016,
            "שם_רחוב": "ככר סיירת אגוז"
        },
        {
            "_id": 26225,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6017,
            "שם_רחוב": "ככר סיירת חרוב"
        },
        {
            "_id": 26226,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6018,
            "שם_רחוב": "ככר סיירת שקד"
        },
        {
            "_id": 26227,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6019,
            "שם_רחוב": "ככר ספרא"
        },
        {
            "_id": 26228,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6100,
            "שם_רחוב": "ככר ע'זיל"
        },
        {
            "_id": 26229,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6020,
            "שם_רחוב": "ככר עומר כתב"
        },
        {
            "_id": 26230,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6021,
            "שם_רחוב": "ככר פלומר"
        },
        {
            "_id": 26231,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6091,
            "שם_רחוב": "ככר פרופ' עלי דיויס"
        },
        {
            "_id": 26232,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6022,
            "שם_רחוב": "ככר צהל"
        },
        {
            "_id": 26233,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6025,
            "שם_רחוב": "ככר ציון"
        },
        {
            "_id": 26234,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6027,
            "שם_רחוב": "ככר צימוקי אריה"
        },
        {
            "_id": 26235,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6030,
            "שם_רחוב": "ככר קליינברגר יצחק"
        },
        {
            "_id": 26236,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6024,
            "שם_רחוב": "ככר ראובן רובין"
        },
        {
            "_id": 26237,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6023,
            "שם_רחוב": "ככר רמז"
        },
        {
            "_id": 26238,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6033,
            "שם_רחוב": "ככראברהם הלפרין"
        },
        {
            "_id": 26239,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6000,
            "שם_רחוב": "ככרולירו חיים אהרון"
        },
        {
            "_id": 26240,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5450,
            "שם_רחוב": "כמון"
        },
        {
            "_id": 26241,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 158,
            "שם_רחוב": "כנסת מרדכי"
        },
        {
            "_id": 26242,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 995,
            "שם_רחוב": "כנפי נשרים"
        },
        {
            "_id": 26243,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 325,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 26244,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4824,
            "שם_רחוב": "כסא רחמים"
        },
        {
            "_id": 26245,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1727,
            "שם_רחוב": "כספי מרדכי"
        },
        {
            "_id": 26246,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1818,
            "שם_רחוב": "כף החיים"
        },
        {
            "_id": 26247,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5643,
            "שם_רחוב": "כף מרים"
        },
        {
            "_id": 26248,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 646,
            "שם_רחוב": "כפר ברעם"
        },
        {
            "_id": 26249,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6726,
            "שם_רחוב": "כפר דוד"
        },
        {
            "_id": 26250,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 221,
            "שם_רחוב": "כפר השלוח"
        },
        {
            "_id": 26251,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4210,
            "שם_רחוב": "כפר חסאן"
        },
        {
            "_id": 26252,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 327,
            "שם_רחוב": "כפר נחום"
        },
        {
            "_id": 26253,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3011,
            "שם_רחוב": "כפר עברי"
        },
        {
            "_id": 26254,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1746,
            "שם_רחוב": "כפר עציון"
        },
        {
            "_id": 26255,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1251,
            "שם_רחוב": "כפר שלמא"
        },
        {
            "_id": 26256,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 524,
            "שם_רחוב": "כץ מיכל"
        },
        {
            "_id": 26257,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4137,
            "שם_רחוב": "כרם אל מופתי"
        },
        {
            "_id": 26258,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4157,
            "שם_רחוב": "כרם אל עלמי"
        },
        {
            "_id": 26259,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3093,
            "שם_רחוב": "כרם אל-הדרה"
        },
        {
            "_id": 26260,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3094,
            "שם_רחוב": "כרם אל-הדרה סמ5"
        },
        {
            "_id": 26261,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5136,
            "שם_רחוב": "כרם אנג'אץ"
        },
        {
            "_id": 26262,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4133,
            "שם_רחוב": "כרם ארסאס"
        },
        {
            "_id": 26263,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5288,
            "שם_רחוב": "כרם וולי"
        },
        {
            "_id": 26264,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3374,
            "שם_רחוב": "כרם זיתים"
        },
        {
            "_id": 26265,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4402,
            "שם_רחוב": "כרם חלה"
        },
        {
            "_id": 26266,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4149,
            "שם_רחוב": "כרם כאלף"
        },
        {
            "_id": 26267,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4401,
            "שם_רחוב": "כרם כניף"
        },
        {
            "_id": 26268,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5517,
            "שם_רחוב": "כרם קמר"
        },
        {
            "_id": 26269,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1038,
            "שם_רחוב": "כרמון"
        },
        {
            "_id": 26270,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1659,
            "שם_רחוב": "כרמיה יצחק"
        },
        {
            "_id": 26271,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5201,
            "שם_רחוב": "כרפס"
        },
        {
            "_id": 26272,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 991,
            "שם_רחוב": "כתב סופר"
        },
        {
            "_id": 26273,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4480,
            "שם_רחוב": "לב שטרן"
        },
        {
            "_id": 26274,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4181,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 26275,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 532,
            "שם_רחוב": "לבוקר רנה"
        },
        {
            "_id": 26276,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 373,
            "שם_רחוב": "לבוש מרדכי"
        },
        {
            "_id": 26277,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5481,
            "שם_רחוב": "לביא תיאודור"
        },
        {
            "_id": 26278,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3008,
            "שם_רחוב": "לבנה אליעזר"
        },
        {
            "_id": 26279,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4789,
            "שם_רחוב": "לבנון צבי"
        },
        {
            "_id": 26280,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 955,
            "שם_רחוב": "לודביג בלום"
        },
        {
            "_id": 26281,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4313,
            "שם_רחוב": "לוחמי הגטאות"
        },
        {
            "_id": 26282,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4368,
            "שם_רחוב": "לוחמי הרובע בתשח"
        },
        {
            "_id": 26283,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 268,
            "שם_רחוב": "לוטה כהן"
        },
        {
            "_id": 26284,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1780,
            "שם_רחוב": "לוי"
        },
        {
            "_id": 26285,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 251,
            "שם_רחוב": "לוי יצחק מברדיצב"
        },
        {
            "_id": 26286,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1655,
            "שם_רחוב": "לויד גורג"
        },
        {
            "_id": 26287,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1214,
            "שם_רחוב": "לוין שמריהו"
        },
        {
            "_id": 26288,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 440,
            "שם_רחוב": "לונץ משה"
        },
        {
            "_id": 26289,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1792,
            "שם_רחוב": "לופו שמואל"
        },
        {
            "_id": 26290,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1088,
            "שם_רחוב": "לופס אליהו"
        },
        {
            "_id": 26291,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3009,
            "שם_רחוב": "לוץ צארלס"
        },
        {
            "_id": 26292,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1805,
            "שם_רחוב": "לוצאטי לואג'י"
        },
        {
            "_id": 26293,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1742,
            "שם_רחוב": "לוריא"
        },
        {
            "_id": 26294,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 968,
            "שם_רחוב": "לורך נתנאל"
        },
        {
            "_id": 26295,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4324,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 26296,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4066,
            "שם_רחוב": "לטין פטריאכט"
        },
        {
            "_id": 26297,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4843,
            "שם_רחוב": "ליאון בן ציון"
        },
        {
            "_id": 26298,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4833,
            "שם_רחוב": "ליבני איתן"
        },
        {
            "_id": 26299,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 387,
            "שם_רחוב": "ליה ון ליר"
        },
        {
            "_id": 26300,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 142,
            "שם_רחוב": "לייב דיין"
        },
        {
            "_id": 26301,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2502,
            "שם_רחוב": "לייב הורביץ לומזה"
        },
        {
            "_id": 26302,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1740,
            "שם_רחוב": "לייב יפה"
        },
        {
            "_id": 26303,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1394,
            "שם_רחוב": "לייבוביץ"
        },
        {
            "_id": 26304,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4790,
            "שם_רחוב": "ליכטמן אברהם דוד"
        },
        {
            "_id": 26305,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1891,
            "שם_רחוב": "ליכטנשטיין"
        },
        {
            "_id": 26306,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 960,
            "שם_רחוב": "ליליאן"
        },
        {
            "_id": 26307,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1521,
            "שם_רחוב": "לינקולן"
        },
        {
            "_id": 26308,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4442,
            "שם_רחוב": "ליפסקי לואי"
        },
        {
            "_id": 26309,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1885,
            "שם_רחוב": "ליפצ'ין סול"
        },
        {
            "_id": 26310,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1853,
            "שם_רחוב": "ליפשיץ נחום"
        },
        {
            "_id": 26311,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4570,
            "שם_רחוב": "ליש"
        },
        {
            "_id": 26312,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 666,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 26313,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3556,
            "שם_רחוב": "למפל הדסה"
        },
        {
            "_id": 26314,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 913,
            "שם_רחוב": "למפרונטי"
        },
        {
            "_id": 26315,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1874,
            "שם_רחוב": "לנגר יחיאל"
        },
        {
            "_id": 26316,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 190,
            "שם_רחוב": "לנדנר"
        },
        {
            "_id": 26317,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1877,
            "שם_רחוב": "לנקין אליהו"
        },
        {
            "_id": 26318,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 150,
            "שם_רחוב": "לפידות"
        },
        {
            "_id": 26319,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4599,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 26320,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 162,
            "שם_רחוב": "מאה שערים"
        },
        {
            "_id": 26321,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4688,
            "שם_רחוב": "מאי זיאדה"
        },
        {
            "_id": 26322,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1298,
            "שם_רחוב": "מאיר אבנר"
        },
        {
            "_id": 26323,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3999,
            "שם_רחוב": "מאיר וייס"
        },
        {
            "_id": 26324,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 536,
            "שם_רחוב": "מאיר ולנה טוביאנסקי"
        },
        {
            "_id": 26325,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1527,
            "שם_רחוב": "מאפו"
        },
        {
            "_id": 26326,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1067,
            "שם_רחוב": "מבוא אורי אבא"
        },
        {
            "_id": 26327,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3055,
            "שם_רחוב": "מבוא אחישר"
        },
        {
            "_id": 26328,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 549,
            "שם_רחוב": "מבוא אלכס ביין"
        },
        {
            "_id": 26329,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2504,
            "שם_רחוב": "מבוא בייניש סלנט"
        },
        {
            "_id": 26330,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 474,
            "שם_רחוב": "מבוא בית דוד"
        },
        {
            "_id": 26331,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 473,
            "שם_רחוב": "מבוא בית הכנסת"
        },
        {
            "_id": 26332,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 462,
            "שם_רחוב": "מבוא בן ברוך"
        },
        {
            "_id": 26333,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1450,
            "שם_רחוב": "מבוא בנין"
        },
        {
            "_id": 26334,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4190,
            "שם_רחוב": "מבוא בשמת"
        },
        {
            "_id": 26335,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 277,
            "שם_רחוב": "מבוא דוד רזניק"
        },
        {
            "_id": 26336,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4319,
            "שם_רחוב": "מבוא די זהב"
        },
        {
            "_id": 26337,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3051,
            "שם_רחוב": "מבוא דקר"
        },
        {
            "_id": 26338,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 851,
            "שם_רחוב": "מבוא האתרוג"
        },
        {
            "_id": 26339,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4973,
            "שם_רחוב": "מבוא הברכה"
        },
        {
            "_id": 26340,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4186,
            "שם_רחוב": "מבוא הדודאים"
        },
        {
            "_id": 26341,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4974,
            "שם_רחוב": "מבוא החוסן"
        },
        {
            "_id": 26342,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 852,
            "שם_רחוב": "מבוא החיטה"
        },
        {
            "_id": 26343,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 853,
            "שם_רחוב": "מבוא הלולב"
        },
        {
            "_id": 26344,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4312,
            "שם_רחוב": "מבוא המאבק"
        },
        {
            "_id": 26345,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 449,
            "שם_רחוב": "מבוא המתמיד"
        },
        {
            "_id": 26346,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1397,
            "שם_רחוב": "מבוא העולה"
        },
        {
            "_id": 26347,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3053,
            "שם_רחוב": "מבוא העשרה"
        },
        {
            "_id": 26348,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4184,
            "שם_רחוב": "מבוא הצרי"
        },
        {
            "_id": 26349,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4183,
            "שם_רחוב": "מבוא הקנמון"
        },
        {
            "_id": 26350,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4301,
            "שם_רחוב": "מבוא הר שפר"
        },
        {
            "_id": 26351,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3059,
            "שם_רחוב": "מבוא הררי"
        },
        {
            "_id": 26352,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 854,
            "שם_רחוב": "מבוא השעורה"
        },
        {
            "_id": 26353,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1477,
            "שם_רחוב": "מבוא השער"
        },
        {
            "_id": 26354,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4344,
            "שם_רחוב": "מבוא חיות פרץ"
        },
        {
            "_id": 26355,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4266,
            "שם_רחוב": "מבוא חיי עולם"
        },
        {
            "_id": 26356,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2501,
            "שם_רחוב": "מבוא חיים מקובנה"
        },
        {
            "_id": 26357,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4303,
            "שם_רחוב": "מבוא חצרות"
        },
        {
            "_id": 26358,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1625,
            "שם_רחוב": "מבוא יורם"
        },
        {
            "_id": 26359,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 713,
            "שם_רחוב": "מבוא יזהר"
        },
        {
            "_id": 26360,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4298,
            "שם_רחוב": "מבוא יטבתה"
        },
        {
            "_id": 26361,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2503,
            "שם_רחוב": "מבוא ילין יהושע"
        },
        {
            "_id": 26362,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4189,
            "שם_רחוב": "מבוא יסמין"
        },
        {
            "_id": 26363,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1079,
            "שם_רחוב": "מבוא יצחק"
        },
        {
            "_id": 26364,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4294,
            "שם_רחוב": "מבוא לבנה"
        },
        {
            "_id": 26365,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1417,
            "שם_רחוב": "מבוא מידב"
        },
        {
            "_id": 26366,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4299,
            "שם_רחוב": "מבוא נחל צין"
        },
        {
            "_id": 26367,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4305,
            "שם_רחוב": "מבוא נחליאל"
        },
        {
            "_id": 26368,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4188,
            "שם_רחוב": "מבוא נטף"
        },
        {
            "_id": 26369,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 204,
            "שם_רחוב": "מבוא סם שפיגל"
        },
        {
            "_id": 26370,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4300,
            "שם_רחוב": "מבוא עברונה"
        },
        {
            "_id": 26371,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4314,
            "שם_רחוב": "מבוא פל ים"
        },
        {
            "_id": 26372,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4348,
            "שם_רחוב": "מבוא פלג אדמונד"
        },
        {
            "_id": 26373,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 855,
            "שם_רחוב": "מבוא פרי האדמה"
        },
        {
            "_id": 26374,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 856,
            "שם_רחוב": "מבוא פרי העץ"
        },
        {
            "_id": 26375,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1104,
            "שם_רחוב": "מבוא פרנקל שלמה"
        },
        {
            "_id": 26376,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 543,
            "שם_רחוב": "מבוא ציפי מלכוב"
        },
        {
            "_id": 26377,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4297,
            "שם_רחוב": "מבוא צלמונה"
        },
        {
            "_id": 26378,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4187,
            "שם_רחוב": "מבוא קטורה"
        },
        {
            "_id": 26379,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4192,
            "שם_רחוב": "מבוא קידה"
        },
        {
            "_id": 26380,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4185,
            "שם_רחוב": "מבוא קציעה"
        },
        {
            "_id": 26381,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 262,
            "שם_רחוב": "מבוא רוזנטל אברהם"
        },
        {
            "_id": 26382,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1313,
            "שם_רחוב": "מבוא רוזנשטיין דב"
        },
        {
            "_id": 26383,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4304,
            "שם_רחוב": "מבוא רפידים"
        },
        {
            "_id": 26384,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4835,
            "שם_רחוב": "מבוא שלום בנימין"
        },
        {
            "_id": 26385,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4191,
            "שם_רחוב": "מבוא שרף"
        },
        {
            "_id": 26386,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4302,
            "שם_רחוב": "מבוא תמנע"
        },
        {
            "_id": 26387,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1610,
            "שם_רחוב": "מבצע קדש"
        },
        {
            "_id": 26388,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1189,
            "שם_רחוב": "מבקר המדינה"
        },
        {
            "_id": 26389,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1049,
            "שם_רחוב": "מגדים"
        },
        {
            "_id": 26390,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1317,
            "שם_רחוב": "מגדל נופים"
        },
        {
            "_id": 26391,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 258,
            "שם_רחוב": "מגדלי נחום"
        },
        {
            "_id": 26392,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 901,
            "שם_רחוב": "מגיני ירושלים"
        },
        {
            "_id": 26393,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 260,
            "שם_רחוב": "מגן האלף"
        },
        {
            "_id": 26394,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 458,
            "שם_רחוב": "מגרש הרוסים"
        },
        {
            "_id": 26395,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4282,
            "שם_רחוב": "מדבר סיני"
        },
        {
            "_id": 26396,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1474,
            "שם_רחוב": "מדרגות גן עדן"
        },
        {
            "_id": 26397,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1448,
            "שם_רחוב": "מדרגות האסיף"
        },
        {
            "_id": 26398,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1476,
            "שם_רחוב": "מדרגות הביקור"
        },
        {
            "_id": 26399,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1480,
            "שם_רחוב": "מדרגות הבציר"
        },
        {
            "_id": 26400,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1472,
            "שם_רחוב": "מדרגות הכפר"
        },
        {
            "_id": 26401,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1447,
            "שם_רחוב": "מדרגות המסיק"
        },
        {
            "_id": 26402,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1478,
            "שם_רחוב": "מדרגות הרומאים"
        },
        {
            "_id": 26403,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1445,
            "שם_רחוב": "מדרגות מיץ פטל"
        },
        {
            "_id": 26404,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 980,
            "שם_רחוב": "מדרשה לתזונה"
        },
        {
            "_id": 26405,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4428,
            "שם_רחוב": "מוג'יר א דין"
        },
        {
            "_id": 26406,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 323,
            "שם_רחוב": "מודיעין"
        },
        {
            "_id": 26407,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1147,
            "שם_רחוב": "מוזיאון ישראל"
        },
        {
            "_id": 26408,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4156,
            "שם_רחוב": "מוזיאון רוקפלר"
        },
        {
            "_id": 26409,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1857,
            "שם_רחוב": "מוזס זיגפריד"
        },
        {
            "_id": 26410,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4906,
            "שם_רחוב": "מוחמד אל פאתח"
        },
        {
            "_id": 26411,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5097,
            "שם_רחוב": "מוחמד אל פאתח סמ1"
        },
        {
            "_id": 26412,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5098,
            "שם_רחוב": "מוחמד אל פאתח סמ2"
        },
        {
            "_id": 26413,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5753,
            "שם_רחוב": "מוחמד חמדן רבאיעה"
        },
        {
            "_id": 26414,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4270,
            "שם_רחוב": "מוטה"
        },
        {
            "_id": 26415,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6209,
            "שם_רחוב": "מוכתאר דאוד עוויסאת"
        },
        {
            "_id": 26416,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 832,
            "שם_רחוב": "מולכו"
        },
        {
            "_id": 26417,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 405,
            "שם_רחוב": "מונבז"
        },
        {
            "_id": 26418,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4908,
            "שם_רחוב": "מוניר חסאן"
        },
        {
            "_id": 26419,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3253,
            "שם_רחוב": "מוניר חסאן סמ1"
        },
        {
            "_id": 26420,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3254,
            "שם_רחוב": "מוניר חסאן סמ3"
        },
        {
            "_id": 26421,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3255,
            "שם_רחוב": "מוניר חסאן סמ4"
        },
        {
            "_id": 26422,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4674,
            "שם_רחוב": "מוסא סייק"
        },
        {
            "_id": 26423,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1675,
            "שם_רחוב": "מוסד אילין"
        },
        {
            "_id": 26424,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "מוסיוף שלמה"
        },
        {
            "_id": 26425,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5247,
            "שם_רחוב": "מוסעב בן עומיר סמ1"
        },
        {
            "_id": 26426,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6680,
            "שם_רחוב": "מוסקוביה"
        },
        {
            "_id": 26427,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 7002,
            "שם_רחוב": "מועדון הרכיבה"
        },
        {
            "_id": 26428,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1438,
            "שם_רחוב": "מועלם שמעון נסים"
        },
        {
            "_id": 26429,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6627,
            "שם_רחוב": "מוצא תחתית"
        },
        {
            "_id": 26430,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5506,
            "שם_רחוב": "מוצעב בן עומייר"
        },
        {
            "_id": 26431,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5684,
            "שם_רחוב": "מוצעב בן עומייר סמ3"
        },
        {
            "_id": 26432,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1222,
            "שם_רחוב": "מוצקין אריה"
        },
        {
            "_id": 26433,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 475,
            "שם_רחוב": "מוצרי מכבי"
        },
        {
            "_id": 26434,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4450,
            "שם_רחוב": "מורגנטאו הנרי"
        },
        {
            "_id": 26435,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6655,
            "שם_רחוב": "מורדות בית וגן"
        },
        {
            "_id": 26436,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 915,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 26437,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4082,
            "שם_רחוב": "מוריסטן"
        },
        {
            "_id": 26438,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4155,
            "שם_רחוב": "מושבה אמריקנית"
        },
        {
            "_id": 26439,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 734,
            "שם_רחוב": "מזור"
        },
        {
            "_id": 26440,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 428,
            "שם_רחוב": "מזיא אהרן"
        },
        {
            "_id": 26441,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 678,
            "שם_רחוב": "מזכרת משה"
        },
        {
            "_id": 26442,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4792,
            "שם_רחוב": "מזל אריה"
        },
        {
            "_id": 26443,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4861,
            "שם_רחוב": "מזל גדי"
        },
        {
            "_id": 26444,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4862,
            "שם_רחוב": "מזל דלי"
        },
        {
            "_id": 26445,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4793,
            "שם_רחוב": "מזל טלה"
        },
        {
            "_id": 26446,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4795,
            "שם_רחוב": "מזל מאזנים"
        },
        {
            "_id": 26447,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4796,
            "שם_רחוב": "מזל קשת"
        },
        {
            "_id": 26448,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4794,
            "שם_רחוב": "מזל שור"
        },
        {
            "_id": 26449,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4828,
            "שם_רחוב": "מזל תאומים"
        },
        {
            "_id": 26450,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3020,
            "שם_רחוב": "מזר בנימין"
        },
        {
            "_id": 26451,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 159,
            "שם_רחוב": "מזרחי יוסף"
        },
        {
            "_id": 26452,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 245,
            "שם_רחוב": "מזרחי מישאל"
        },
        {
            "_id": 26453,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4351,
            "שם_רחוב": "מחל"
        },
        {
            "_id": 26454,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3352,
            "שם_רחוב": "מחג'רת עתמה"
        },
        {
            "_id": 26455,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3353,
            "שם_רחוב": "מחג'רת עתמה סמ2"
        },
        {
            "_id": 26456,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3354,
            "שם_רחוב": "מחג'רת עתמה סמ3"
        },
        {
            "_id": 26457,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 254,
            "שם_רחוב": "מחוזא"
        },
        {
            "_id": 26458,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4909,
            "שם_רחוב": "מחיסן"
        },
        {
            "_id": 26459,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5406,
            "שם_רחוב": "מחיסן סמ1"
        },
        {
            "_id": 26460,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5407,
            "שם_רחוב": "מחיסן סמ2"
        },
        {
            "_id": 26461,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5409,
            "שם_רחוב": "מחיסן סמ3"
        },
        {
            "_id": 26462,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3349,
            "שם_רחוב": "מחיסן סמ5"
        },
        {
            "_id": 26463,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1611,
            "שם_רחוב": "מחלקי המים"
        },
        {
            "_id": 26464,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4027,
            "שם_רחוב": "מחמדיה"
        },
        {
            "_id": 26465,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3206,
            "שם_רחוב": "מחמוד ברבר"
        },
        {
            "_id": 26466,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6571,
            "שם_רחוב": "מחנה יהודה"
        },
        {
            "_id": 26467,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 194,
            "שם_רחוב": "מחנה יצחק"
        },
        {
            "_id": 26468,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4267,
            "שם_רחוב": "מחנה פליטים א ראם"
        },
        {
            "_id": 26469,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4103,
            "שם_רחוב": "מחנה פליטים קלנדיה"
        },
        {
            "_id": 26470,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4102,
            "שם_רחוב": "מחנה פליטים שעפט"
        },
        {
            "_id": 26471,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6629,
            "שם_רחוב": "מחנה שנלר"
        },
        {
            "_id": 26472,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מחניים"
        },
        {
            "_id": 26473,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 606,
            "שם_רחוב": "מיוחס"
        },
        {
            "_id": 26474,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 526,
            "שם_רחוב": "מיטיב נגן"
        },
        {
            "_id": 26475,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1113,
            "שם_רחוב": "מיכאלסון"
        },
        {
            "_id": 26476,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 309,
            "שם_רחוב": "מיכה"
        },
        {
            "_id": 26477,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מיכל"
        },
        {
            "_id": 26478,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2505,
            "שם_רחוב": "מיכל הכהן"
        },
        {
            "_id": 26479,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1098,
            "שם_רחוב": "מיכלין"
        },
        {
            "_id": 26480,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4484,
            "שם_רחוב": "מינץ"
        },
        {
            "_id": 26481,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1123,
            "שם_רחוב": "מינצברג"
        },
        {
            "_id": 26482,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1275,
            "שם_רחוב": "מיס לנדאו"
        },
        {
            "_id": 26483,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4284,
            "שם_רחוב": "מיצרי טירן"
        },
        {
            "_id": 26484,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 331,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 26485,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4482,
            "שם_רחוב": "מירסקי יצחק"
        },
        {
            "_id": 26486,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1640,
            "שם_רחוב": "מישאל"
        },
        {
            "_id": 26487,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5738,
            "שם_רחוב": "מכה"
        },
        {
            "_id": 26488,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5739,
            "שם_רחוב": "מכה סמ1"
        },
        {
            "_id": 26489,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1133,
            "שם_רחוב": "מכון לב"
        },
        {
            "_id": 26490,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1319,
            "שם_רחוב": "מכור"
        },
        {
            "_id": 26491,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4971,
            "שם_רחוב": "מכלוף עידן"
        },
        {
            "_id": 26492,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1169,
            "שם_רחוב": "מכללת ירושלים לבנות"
        },
        {
            "_id": 26493,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1250,
            "שם_רחוב": "מכסיקו"
        },
        {
            "_id": 26494,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 304,
            "שם_רחוב": "מלאכי"
        },
        {
            "_id": 26495,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1815,
            "שם_רחוב": "מלון גני יהודה"
        },
        {
            "_id": 26496,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1816,
            "שם_רחוב": "מלון דיפלומט"
        },
        {
            "_id": 26497,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3061,
            "שם_רחוב": "מלון הייאט ריג'נסי"
        },
        {
            "_id": 26498,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1748,
            "שם_רחוב": "מלון זוהר"
        },
        {
            "_id": 26499,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 477,
            "שם_רחוב": "מלון טירת בת שבע"
        },
        {
            "_id": 26500,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 433,
            "שם_רחוב": "מלון מגדל העיר"
        },
        {
            "_id": 26501,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 914,
            "שם_רחוב": "מלון רם"
        },
        {
            "_id": 26502,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2050,
            "שם_רחוב": "מלון רמת תמיר"
        },
        {
            "_id": 26503,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3062,
            "שם_רחוב": "מלון שער יפו"
        },
        {
            "_id": 26504,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1708,
            "שם_רחוב": "מלכי"
        },
        {
            "_id": 26505,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 305,
            "שם_רחוב": "מלכי ישראל"
        },
        {
            "_id": 26506,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4269,
            "שם_רחוב": "מלכי צדק"
        },
        {
            "_id": 26507,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1668,
            "שם_רחוב": "מלל"
        },
        {
            "_id": 26508,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1107,
            "שם_רחוב": "מלצר פיבל"
        },
        {
            "_id": 26509,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 454,
            "שם_רחוב": "ממילא"
        },
        {
            "_id": 26510,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2003,
            "שם_רחוב": "מן"
        },
        {
            "_id": 26511,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 497,
            "שם_רחוב": "מנדל (ישראל) קפלן"
        },
        {
            "_id": 26512,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1515,
            "שם_רחוב": "מנדלי מוכר ספרים"
        },
        {
            "_id": 26513,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4473,
            "שם_רחוב": "מנדלסון אריק"
        },
        {
            "_id": 26514,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1510,
            "שם_רחוב": "מנה"
        },
        {
            "_id": 26515,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 451,
            "שם_רחוב": "מנורה"
        },
        {
            "_id": 26516,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4849,
            "שם_רחוב": "מנורי"
        },
        {
            "_id": 26517,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4091,
            "שם_רחוב": "מנזר המארונים"
        },
        {
            "_id": 26518,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1669,
            "שם_רחוב": "מנזר סן סימון"
        },
        {
            "_id": 26519,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 329,
            "שם_רחוב": "מנחם"
        },
        {
            "_id": 26520,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 490,
            "שם_רחוב": "מנחם ירושלים"
        },
        {
            "_id": 26521,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1151,
            "שם_רחוב": "מנחם מנדל משקלוב"
        },
        {
            "_id": 26522,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4907,
            "שם_רחוב": "מנחת יצחק"
        },
        {
            "_id": 26523,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4010,
            "שם_רחוב": "מנטקה א חמרה"
        },
        {
            "_id": 26524,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 625,
            "שם_רחוב": "מני אליהו"
        },
        {
            "_id": 26525,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1776,
            "שם_רחוב": "מנשה"
        },
        {
            "_id": 26526,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4581,
            "שם_רחוב": "מס ראובן"
        },
        {
            "_id": 26527,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5599,
            "שם_רחוב": "מסורת אל-מיה"
        },
        {
            "_id": 26528,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5646,
            "שם_רחוב": "מסורת אל-מיה סמ1"
        },
        {
            "_id": 26529,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 689,
            "שם_רחוב": "מסילת ישרים"
        },
        {
            "_id": 26530,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4446,
            "שם_רחוב": "מסליאנסקי צבי"
        },
        {
            "_id": 26531,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5229,
            "שם_רחוב": "מסעדה"
        },
        {
            "_id": 26532,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5740,
            "שם_רחוב": "מסעדה סמ1"
        },
        {
            "_id": 26533,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5741,
            "שם_רחוב": "מסעדה סמ2"
        },
        {
            "_id": 26534,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5742,
            "שם_רחוב": "מסעדה סמ3"
        },
        {
            "_id": 26535,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5743,
            "שם_רחוב": "מסעדה סמ4"
        },
        {
            "_id": 26536,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1656,
            "שם_רחוב": "מסריק"
        },
        {
            "_id": 26537,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6775,
            "שם_רחוב": "מע תלפיות"
        },
        {
            "_id": 26538,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1547,
            "שם_רחוב": "מעבר אהרן אליאס"
        },
        {
            "_id": 26539,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4277,
            "שם_רחוב": "מעבר המיתלה"
        },
        {
            "_id": 26540,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 745,
            "שם_רחוב": "מעבר הראבד"
        },
        {
            "_id": 26541,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 443,
            "שם_רחוב": "מעבר מזרחי עזרא"
        },
        {
            "_id": 26542,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1041,
            "שם_רחוב": "מעגל בית המדרש"
        },
        {
            "_id": 26543,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1475,
            "שם_רחוב": "מעגל הניקבה"
        },
        {
            "_id": 26544,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4342,
            "שם_רחוב": "מעגלות הרב פרדס"
        },
        {
            "_id": 26545,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3026,
            "שם_רחוב": "מעגלי הרים לוין"
        },
        {
            "_id": 26546,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1366,
            "שם_רחוב": "מעגלי יבנה"
        },
        {
            "_id": 26547,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4438,
            "שם_רחוב": "מעוז"
        },
        {
            "_id": 26548,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 804,
            "שם_רחוב": "מעוז עובד א"
        },
        {
            "_id": 26549,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 671,
            "שם_רחוב": "מעון"
        },
        {
            "_id": 26550,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1353,
            "שם_רחוב": "מעון אנגלסקי"
        },
        {
            "_id": 26551,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4658,
            "שם_רחוב": "מעון דליה"
        },
        {
            "_id": 26552,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1819,
            "שם_רחוב": "מעון תלפיות"
        },
        {
            "_id": 26553,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1115,
            "שם_רחוב": "מעונות בזק"
        },
        {
            "_id": 26554,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5650,
            "שם_רחוב": "מעונות ברונפמן"
        },
        {
            "_id": 26555,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1234,
            "שם_רחוב": "מעלה אהרון ראובני"
        },
        {
            "_id": 26556,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4595,
            "שם_רחוב": "מעלה אורנים"
        },
        {
            "_id": 26557,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5568,
            "שם_רחוב": "מעלה אלחנקה"
        },
        {
            "_id": 26558,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 293,
            "שם_רחוב": "מעלה דרגה"
        },
        {
            "_id": 26559,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1272,
            "שם_רחוב": "מעלה האכסניה"
        },
        {
            "_id": 26560,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1479,
            "שם_רחוב": "מעלה הבוסתן"
        },
        {
            "_id": 26561,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1906,
            "שם_רחוב": "מעלה הברושים"
        },
        {
            "_id": 26562,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3280,
            "שם_רחוב": "מעלה החאלבים"
        },
        {
            "_id": 26563,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1174,
            "שם_רחוב": "מעלה הילמן"
        },
        {
            "_id": 26564,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5569,
            "שם_רחוב": "מעלה המוגרבים"
        },
        {
            "_id": 26565,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1473,
            "שם_רחוב": "מעלה המועצה"
        },
        {
            "_id": 26566,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1418,
            "שם_רחוב": "מעלה הצליינים"
        },
        {
            "_id": 26567,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1444,
            "שם_רחוב": "מעלה הצניר"
        },
        {
            "_id": 26568,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1449,
            "שם_רחוב": "מעלה הקיסוס"
        },
        {
            "_id": 26569,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1412,
            "שם_רחוב": "מעלה השבשבת"
        },
        {
            "_id": 26570,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4512,
            "שם_רחוב": "מעלה השלום"
        },
        {
            "_id": 26571,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3271,
            "שם_רחוב": "מעלה השלום סמ1"
        },
        {
            "_id": 26572,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1355,
            "שם_רחוב": "מעלה זאב"
        },
        {
            "_id": 26573,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4730,
            "שם_רחוב": "מעלה זיתים"
        },
        {
            "_id": 26574,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4468,
            "שם_רחוב": "מעלה כגן הלנה"
        },
        {
            "_id": 26575,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1443,
            "שם_רחוב": "מעלה מוזס"
        },
        {
            "_id": 26576,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 291,
            "שם_רחוב": "מעלה תימן"
        },
        {
            "_id": 26577,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4115,
            "שם_רחוב": "מעלופיה"
        },
        {
            "_id": 26578,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 286,
            "שם_רחוב": "מעלות אהל שלמה"
        },
        {
            "_id": 26579,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1124,
            "שם_רחוב": "מעלות אלפיה"
        },
        {
            "_id": 26580,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6835,
            "שם_רחוב": "מעלות אלרם"
        },
        {
            "_id": 26581,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1139,
            "שם_רחוב": "מעלות אפרים זלמן"
        },
        {
            "_id": 26582,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1700,
            "שם_רחוב": "מעלות בדאהב יצחק"
        },
        {
            "_id": 26583,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1830,
            "שם_רחוב": "מעלות בני"
        },
        {
            "_id": 26584,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3033,
            "שם_רחוב": "מעלות דושינסקי"
        },
        {
            "_id": 26585,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "מעלות דפנה"
        },
        {
            "_id": 26586,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 379,
            "שם_רחוב": "מעלות הדר והדס"
        },
        {
            "_id": 26587,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5805,
            "שם_רחוב": "מעלות המדרשה"
        },
        {
            "_id": 26588,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1074,
            "שם_רחוב": "מעלות המחנך"
        },
        {
            "_id": 26589,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4008,
            "שם_רחוב": "מעלות הנזירות"
        },
        {
            "_id": 26590,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 837,
            "שם_רחוב": "מעלות הרב אטינגר"
        },
        {
            "_id": 26591,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5808,
            "שם_רחוב": "מעלות הרב גץ"
        },
        {
            "_id": 26592,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 246,
            "שם_רחוב": "מעלות הרב מרודניק"
        },
        {
            "_id": 26593,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1909,
            "שם_רחוב": "מעלות הרב נוריאל"
        },
        {
            "_id": 26594,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5525,
            "שם_רחוב": "מעלות זע'ל"
        },
        {
            "_id": 26595,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5012,
            "שם_רחוב": "מעלות חדוה"
        },
        {
            "_id": 26596,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2130,
            "שם_רחוב": "מעלות חנן פורת"
        },
        {
            "_id": 26597,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4437,
            "שם_רחוב": "מעלות כיסופים"
        },
        {
            "_id": 26598,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1431,
            "שם_רחוב": "מעלות לגרלף סלמה"
        },
        {
            "_id": 26599,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5015,
            "שם_רחוב": "מעלות מועדים"
        },
        {
            "_id": 26600,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6837,
            "שם_רחוב": "מעלות מוריה"
        },
        {
            "_id": 26601,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3372,
            "שם_רחוב": "מעלות מי השילוח"
        },
        {
            "_id": 26602,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3262,
            "שם_רחוב": "מעלות מעיין הגיחון"
        },
        {
            "_id": 26603,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1370,
            "שם_רחוב": "מעלות סימון"
        },
        {
            "_id": 26604,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5509,
            "שם_רחוב": "מעלות סמרין"
        },
        {
            "_id": 26605,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1243,
            "שם_רחוב": "מעלות סנש חנה"
        },
        {
            "_id": 26606,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1429,
            "שם_רחוב": "מעלות עבאדי יצחק"
        },
        {
            "_id": 26607,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5523,
            "שם_רחוב": "מעלות עדילה"
        },
        {
            "_id": 26608,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4375,
            "שם_רחוב": "מעלות עיר דוד"
        },
        {
            "_id": 26609,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5014,
            "שם_רחוב": "מעלות פדויים"
        },
        {
            "_id": 26610,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1197,
            "שם_רחוב": "מעלות פרנקל אליהו"
        },
        {
            "_id": 26611,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1140,
            "שם_רחוב": "מעלות צבי לצדיק"
        },
        {
            "_id": 26612,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 834,
            "שם_רחוב": "מעלות ריבלין משה"
        },
        {
            "_id": 26613,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5013,
            "שם_רחוב": "מעלות רננים"
        },
        {
            "_id": 26614,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 546,
            "שם_רחוב": "מעלות שיר חג'אג'"
        },
        {
            "_id": 26615,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1424,
            "שם_רחוב": "מעלות שנהר"
        },
        {
            "_id": 26616,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4154,
            "שם_רחוב": "מעלות שער הפרחים"
        },
        {
            "_id": 26617,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1148,
            "שם_רחוב": "מעלות שר שלום"
        },
        {
            "_id": 26618,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4530,
            "שם_רחוב": "מעמדות ישראל"
        },
        {
            "_id": 26619,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4388,
            "שם_רחוב": "מעמל אל תלג'"
        },
        {
            "_id": 26620,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 958,
            "שם_רחוב": "מענה שימחה"
        },
        {
            "_id": 26621,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3278,
            "שם_רחוב": "מערת הנביאים"
        },
        {
            "_id": 26622,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4654,
            "שם_רחוב": "מעש"
        },
        {
            "_id": 26623,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1838,
            "שם_רחוב": "מעשה חושב"
        },
        {
            "_id": 26624,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4653,
            "שם_רחוב": "מפעלות"
        },
        {
            "_id": 26625,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4289,
            "שם_רחוב": "מפרץ שלמה"
        },
        {
            "_id": 26626,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1752,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 26627,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 656,
            "שם_רחוב": "מצפה"
        },
        {
            "_id": 26628,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 486,
            "שם_רחוב": "מצפה בחורים"
        },
        {
            "_id": 26629,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6857,
            "שם_רחוב": "מצפה גילה"
        },
        {
            "_id": 26630,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1142,
            "שם_רחוב": "מצפה מנחם"
        },
        {
            "_id": 26631,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3281,
            "שם_רחוב": "מקדם לעיר"
        },
        {
            "_id": 26632,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4318,
            "שם_רחוב": "מקוה ישראל"
        },
        {
            "_id": 26633,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1794,
            "שם_רחוב": "מקור חיים"
        },
        {
            "_id": 26634,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 957,
            "שם_רחוב": "מקלף אריה"
        },
        {
            "_id": 26635,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5063,
            "שם_רחוב": "מקסימוס צאייג"
        },
        {
            "_id": 26636,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4243,
            "שם_רחוב": "מר אליאס"
        },
        {
            "_id": 26637,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4672,
            "שם_רחוב": "מרג' אבן עאמר"
        },
        {
            "_id": 26638,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4943,
            "שם_רחוב": "מרג' אבן עאמר סמ4"
        },
        {
            "_id": 26639,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5656,
            "שם_רחוב": "מרג' אל מהור"
        },
        {
            "_id": 26640,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5103,
            "שם_רחוב": "מרג' אל מהור סמ5"
        },
        {
            "_id": 26641,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5104,
            "שם_רחוב": "מרג' אל מהור סמ7"
        },
        {
            "_id": 26642,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1306,
            "שם_רחוב": "מרגולין"
        },
        {
            "_id": 26643,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4415,
            "שם_רחוב": "מרגיל מחוור"
        },
        {
            "_id": 26644,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4544,
            "שם_רחוב": "מרגלית"
        },
        {
            "_id": 26645,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1544,
            "שם_רחוב": "מרדכי ג'נאשוילי"
        },
        {
            "_id": 26646,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1756,
            "שם_רחוב": "מרדכי היהודי"
        },
        {
            "_id": 26647,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1185,
            "שם_רחוב": "מרה"
        },
        {
            "_id": 26648,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5454,
            "שם_רחוב": "מרוה"
        },
        {
            "_id": 26649,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1095,
            "שם_רחוב": "מרום ציון"
        },
        {
            "_id": 26650,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4355,
            "שם_רחוב": "מרזוק משה"
        },
        {
            "_id": 26651,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1848,
            "שם_רחוב": "מרטון יחזקאל"
        },
        {
            "_id": 26652,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1500,
            "שם_רחוב": "מרטין לותר קינג"
        },
        {
            "_id": 26653,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4825,
            "שם_רחוב": "מרידור אליהו"
        },
        {
            "_id": 26654,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1760,
            "שם_רחוב": "מרים החשמונאית"
        },
        {
            "_id": 26655,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1899,
            "שם_רחוב": "מרינוב חיים"
        },
        {
            "_id": 26656,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1503,
            "שם_רחוב": "מרכוס"
        },
        {
            "_id": 26657,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6554,
            "שם_רחוב": "מרכז (מגרש הרוסים)"
        },
        {
            "_id": 26658,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6555,
            "שם_רחוב": "מרכז (מרכז כלל)"
        },
        {
            "_id": 26659,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6553,
            "שם_רחוב": "מרכז העיר"
        },
        {
            "_id": 26660,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4575,
            "שם_רחוב": "מרכז מסחרי גילה"
        },
        {
            "_id": 26661,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1271,
            "שם_רחוב": "מרכז מסחרי ק יובל"
        },
        {
            "_id": 26662,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 897,
            "שם_רחוב": "מרכז ספיר"
        },
        {
            "_id": 26663,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4531,
            "שם_רחוב": "מרכז קליטה גילה"
        },
        {
            "_id": 26664,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1907,
            "שם_רחוב": "מרן הגריש אלישיב"
        },
        {
            "_id": 26665,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6090,
            "שם_רחוב": "מרן הרב עובדיה יוסף"
        },
        {
            "_id": 26666,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1436,
            "שם_רחוב": "מרץ דוד"
        },
        {
            "_id": 26667,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1245,
            "שם_רחוב": "מרקו ברוך"
        },
        {
            "_id": 26668,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 282,
            "שם_רחוב": "מרתה במברגר"
        },
        {
            "_id": 26669,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4732,
            "שם_רחוב": "משאריף"
        },
        {
            "_id": 26670,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5130,
            "שם_רחוב": "משאריף סמ5"
        },
        {
            "_id": 26671,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1434,
            "שם_רחוב": "משאת משה"
        },
        {
            "_id": 26672,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4364,
            "שם_רחוב": "משגב לדך"
        },
        {
            "_id": 26673,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4751,
            "שם_רחוב": "משה (מוסא) פלד"
        },
        {
            "_id": 26674,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 198,
            "שם_רחוב": "משה ואסתר לעזער"
        },
        {
            "_id": 26675,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 244,
            "שם_רחוב": "משה קראוס"
        },
        {
            "_id": 26676,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 274,
            "שם_רחוב": "משה ששון"
        },
        {
            "_id": 26677,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1745,
            "שם_רחוב": "משואות יצחק"
        },
        {
            "_id": 26678,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4760,
            "שם_רחוב": "משטרת הישובים"
        },
        {
            "_id": 26679,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1046,
            "שם_רחוב": "משיב מנחם"
        },
        {
            "_id": 26680,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 840,
            "שם_רחוב": "משכן נשיאי ישראל"
        },
        {
            "_id": 26681,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1076,
            "שם_רחוב": "משכן שילה"
        },
        {
            "_id": 26682,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 688,
            "שם_רחוב": "משכנות"
        },
        {
            "_id": 26683,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4660,
            "שם_רחוב": "משכנות השלום"
        },
        {
            "_id": 26684,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6761,
            "שם_רחוב": "משכנות שאננים"
        },
        {
            "_id": 26685,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1728,
            "שם_רחוב": "משמיע שלום"
        },
        {
            "_id": 26686,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4278,
            "שם_רחוב": "משמר הגבול"
        },
        {
            "_id": 26687,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1602,
            "שם_רחוב": "משמר העם"
        },
        {
            "_id": 26688,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 505,
            "שם_רחוב": "משמרות"
        },
        {
            "_id": 26689,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4529,
            "שם_רחוב": "משמרות הכהונה"
        },
        {
            "_id": 26690,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 922,
            "שם_רחוב": "משמרת חיים"
        },
        {
            "_id": 26691,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 476,
            "שם_רחוב": "משעול הגבורה"
        },
        {
            "_id": 26692,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5305,
            "שם_רחוב": "משעול הדקלים"
        },
        {
            "_id": 26693,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4590,
            "שם_רחוב": "משעול ההדס"
        },
        {
            "_id": 26694,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5302,
            "שם_רחוב": "משעול היערה"
        },
        {
            "_id": 26695,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4591,
            "שם_רחוב": "משעול הכתרון"
        },
        {
            "_id": 26696,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 489,
            "שם_רחוב": "משעול הלוחמת אסתר"
        },
        {
            "_id": 26697,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5301,
            "שם_רחוב": "משעול המגלית"
        },
        {
            "_id": 26698,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5303,
            "שם_רחוב": "משעול הקורטם"
        },
        {
            "_id": 26699,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4589,
            "שם_רחוב": "משעול הקורנית"
        },
        {
            "_id": 26700,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4593,
            "שם_רחוב": "משעול הרותם"
        },
        {
            "_id": 26701,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 276,
            "שם_רחוב": "משעול זהב מרדכי"
        },
        {
            "_id": 26702,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4592,
            "שם_רחוב": "משעול מורן"
        },
        {
            "_id": 26703,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5304,
            "שם_רחוב": "משעול עוזרד"
        },
        {
            "_id": 26704,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4492,
            "שם_רחוב": "משפט דרייפוס"
        },
        {
            "_id": 26705,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6595,
            "שם_רחוב": "מתחם הולילנד"
        },
        {
            "_id": 26706,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6557,
            "שם_רחוב": "מתחם ימקא"
        },
        {
            "_id": 26707,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1769,
            "שם_רחוב": "מתתיהו"
        },
        {
            "_id": 26708,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 534,
            "שם_רחוב": "נאות דוד"
        },
        {
            "_id": 26709,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 533,
            "שם_רחוב": "נאות דשא"
        },
        {
            "_id": 26710,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 681,
            "שם_רחוב": "נבו"
        },
        {
            "_id": 26711,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 610,
            "שם_רחוב": "נבון"
        },
        {
            "_id": 26712,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4622,
            "שם_רחוב": "נבי שועייב"
        },
        {
            "_id": 26713,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 930,
            "שם_רחוב": "נג'ארה"
        },
        {
            "_id": 26714,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 592,
            "שם_רחוב": "נג'מת אל קדס"
        },
        {
            "_id": 26715,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1605,
            "שם_רחוב": "נגבה"
        },
        {
            "_id": 26716,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4804,
            "שם_רחוב": "נדבה יוסף"
        },
        {
            "_id": 26717,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3056,
            "שם_רחוב": "נהגי הפרדות"
        },
        {
            "_id": 26718,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1295,
            "שם_רחוב": "נהוראי"
        },
        {
            "_id": 26719,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 641,
            "שם_רחוב": "נהר פרת"
        },
        {
            "_id": 26720,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 658,
            "שם_רחוב": "נוב"
        },
        {
            "_id": 26721,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1357,
            "שם_רחוב": "נובומיסקי משה"
        },
        {
            "_id": 26722,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6678,
            "שם_רחוב": "נוה גרנות"
        },
        {
            "_id": 26723,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6570,
            "שם_רחוב": "נוה צדק"
        },
        {
            "_id": 26724,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6645,
            "שם_רחוב": "נוה שכטר"
        },
        {
            "_id": 26725,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1166,
            "שם_רחוב": "נווה שאנן"
        },
        {
            "_id": 26726,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2021,
            "שם_רחוב": "נונייז בנג'מין"
        },
        {
            "_id": 26727,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5157,
            "שם_רחוב": "נוסייבה"
        },
        {
            "_id": 26728,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6855,
            "שם_רחוב": "נוף גילה"
        },
        {
            "_id": 26729,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1099,
            "שם_רחוב": "נוף הרים"
        },
        {
            "_id": 26730,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6856,
            "שם_רחוב": "נוף ירושלים"
        },
        {
            "_id": 26731,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6791,
            "שם_רחוב": "נוף רמות"
        },
        {
            "_id": 26732,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 228,
            "שם_רחוב": "נופי מדבר"
        },
        {
            "_id": 26733,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4564,
            "שם_רחוב": "נופך"
        },
        {
            "_id": 26734,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5006,
            "שם_רחוב": "נור אל הודא"
        },
        {
            "_id": 26735,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4268,
            "שם_רחוב": "נור אל-דין"
        },
        {
            "_id": 26736,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5661,
            "שם_רחוב": "נוראל הוודא"
        },
        {
            "_id": 26737,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 959,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 26738,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5524,
            "שם_רחוב": "נזלת אבו סוואי"
        },
        {
            "_id": 26739,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3225,
            "שם_רחוב": "נזלת אבו סוואי סמ2"
        },
        {
            "_id": 26740,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3208,
            "שם_רחוב": "נזלת אבו-סוואי סמ2"
        },
        {
            "_id": 26741,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1126,
            "שם_רחוב": "נזר דוד"
        },
        {
            "_id": 26742,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 312,
            "שם_רחוב": "נחום"
        },
        {
            "_id": 26743,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 177,
            "שם_רחוב": "נחום איש גמזו"
        },
        {
            "_id": 26744,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3227,
            "שם_רחוב": "נחלת יצחק"
        },
        {
            "_id": 26745,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 205,
            "שם_רחוב": "נחלת צבי"
        },
        {
            "_id": 26746,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 719,
            "שם_רחוב": "נחלת צדוק"
        },
        {
            "_id": 26747,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 444,
            "שם_רחוב": "נחלת שבעה"
        },
        {
            "_id": 26748,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4373,
            "שם_רחוב": "נחמה"
        },
        {
            "_id": 26749,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4516,
            "שם_רחוב": "נחמו"
        },
        {
            "_id": 26750,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 313,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 26751,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2016,
            "שם_רחוב": "נחמיה מנשה"
        },
        {
            "_id": 26752,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1723,
            "שם_רחוב": "נחשון"
        },
        {
            "_id": 26753,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4317,
            "שם_רחוב": "נטר"
        },
        {
            "_id": 26754,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 989,
            "שם_רחוב": "נטרונאי גאון"
        },
        {
            "_id": 26755,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4787,
            "שם_רחוב": "ניב דוד"
        },
        {
            "_id": 26756,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5393,
            "שם_רחוב": "ניהאד אבו ע'רבייה"
        },
        {
            "_id": 26757,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6646,
            "שם_רחוב": "ניות"
        },
        {
            "_id": 26758,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1208,
            "שם_רחוב": "ניידיץ"
        },
        {
            "_id": 26759,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3013,
            "שם_רחוב": "ניימן שמואל"
        },
        {
            "_id": 26760,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1670,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 26761,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1247,
            "שם_רחוב": "ניסן אברהם"
        },
        {
            "_id": 26762,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1225,
            "שם_רחוב": "ניסנבאום"
        },
        {
            "_id": 26763,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4826,
            "שם_רחוב": "ניקובה רינה"
        },
        {
            "_id": 26764,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1338,
            "שם_רחוב": "ניקנור"
        },
        {
            "_id": 26765,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1286,
            "שם_רחוב": "ניקרגואה"
        },
        {
            "_id": 26766,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1849,
            "שם_רחוב": "נכון שלמה"
        },
        {
            "_id": 26767,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 530,
            "שם_רחוב": "נעים זמירות"
        },
        {
            "_id": 26768,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5522,
            "שם_רחוב": "נעמאן"
        },
        {
            "_id": 26769,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1726,
            "שם_רחוב": "נעמי"
        },
        {
            "_id": 26770,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 365,
            "שם_רחוב": "נפחא"
        },
        {
            "_id": 26771,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1775,
            "שם_רחוב": "נפתלי"
        },
        {
            "_id": 26772,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 200,
            "שם_רחוב": "נציבין"
        },
        {
            "_id": 26773,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1332,
            "שם_רחוב": "נקדימון"
        },
        {
            "_id": 26774,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4327,
            "שם_רחוב": "נקר מאיר"
        },
        {
            "_id": 26775,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 650,
            "שם_רחוב": "נרבתה"
        },
        {
            "_id": 26776,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5017,
            "שם_רחוב": "נרות שבת"
        },
        {
            "_id": 26777,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 703,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 26778,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4557,
            "שם_רחוב": "נשר"
        },
        {
            "_id": 26779,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1340,
            "שם_רחוב": "נתאי הארבלי"
        },
        {
            "_id": 26780,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4791,
            "שם_רחוב": "נתיב המזלות"
        },
        {
            "_id": 26781,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1413,
            "שם_רחוב": "נתיב זהרה"
        },
        {
            "_id": 26782,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 738,
            "שם_רחוב": "נתיב יצחק"
        },
        {
            "_id": 26783,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1012,
            "שם_רחוב": "נתיבות משנה"
        },
        {
            "_id": 26784,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5476,
            "שם_רחוב": "נתיבי עם"
        },
        {
            "_id": 26785,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 508,
            "שם_רחוב": "נתן הנביא"
        },
        {
            "_id": 26786,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4829,
            "שם_רחוב": "נתנזון איסר"
        },
        {
            "_id": 26787,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4124,
            "שם_רחוב": "סאחת אל בלד"
        },
        {
            "_id": 26788,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5977,
            "שם_רחוב": "סאלם אל-ראס"
        },
        {
            "_id": 26789,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5978,
            "שם_רחוב": "סאלם אל-ראס סמ1"
        },
        {
            "_id": 26790,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5979,
            "שם_רחוב": "סאלם אל-ראס סמ2"
        },
        {
            "_id": 26791,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4848,
            "שם_רחוב": "סגולת ישראל"
        },
        {
            "_id": 26792,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4494,
            "שם_רחוב": "סגל משה צבי"
        },
        {
            "_id": 26793,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1008,
            "שם_רחוב": "סגן כהן מאיר ומיכאל"
        },
        {
            "_id": 26794,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4811,
            "שם_רחוב": "סדן דב"
        },
        {
            "_id": 26795,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4258,
            "שם_רחוב": "סהל אל עבדאן"
        },
        {
            "_id": 26796,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4222,
            "שם_רחוב": "סוואחירה"
        },
        {
            "_id": 26797,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4093,
            "שם_רחוב": "סוויקאת עלון"
        },
        {
            "_id": 26798,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1673,
            "שם_רחוב": "סוזן דניאל-נטף"
        },
        {
            "_id": 26799,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 690,
            "שם_רחוב": "סוכת שלום"
        },
        {
            "_id": 26800,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1215,
            "שם_רחוב": "סולד הנריטה"
        },
        {
            "_id": 26801,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 357,
            "שם_רחוב": "סולובייציק"
        },
        {
            "_id": 26802,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4944,
            "שם_רחוב": "סולטאן קוטוז"
        },
        {
            "_id": 26803,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4275,
            "שם_רחוב": "סולטן סולימאן"
        },
        {
            "_id": 26804,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4739,
            "שם_רחוב": "סולטן קוטז"
        },
        {
            "_id": 26805,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4432,
            "שם_רחוב": "סולם יעקב"
        },
        {
            "_id": 26806,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 667,
            "שם_רחוב": "סומך עובדיה"
        },
        {
            "_id": 26807,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1342,
            "שם_רחוב": "סופר מרדכי"
        },
        {
            "_id": 26808,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4083,
            "שם_רחוב": "סוק אבטימוס"
        },
        {
            "_id": 26809,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4039,
            "שם_רחוב": "סוק אל בשורה"
        },
        {
            "_id": 26810,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4087,
            "שם_רחוב": "סוק אל חוסור"
        },
        {
            "_id": 26811,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4037,
            "שם_רחוב": "סוק אל לחמין"
        },
        {
            "_id": 26812,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4036,
            "שם_רחוב": "סוק אל עטרין"
        },
        {
            "_id": 26813,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4033,
            "שם_רחוב": "סוק אל קטנין"
        },
        {
            "_id": 26814,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4035,
            "שם_רחוב": "סוק חן א זית"
        },
        {
            "_id": 26815,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1518,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 26816,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3040,
            "שם_רחוב": "סוקניק אליעזר"
        },
        {
            "_id": 26817,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 259,
            "שם_רחוב": "סורא"
        },
        {
            "_id": 26818,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 952,
            "שם_רחוב": "סורוצקין"
        },
        {
            "_id": 26819,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 479,
            "שם_רחוב": "סטוינובסקי רבקה"
        },
        {
            "_id": 26820,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1176,
            "שם_רחוב": "סיוון עמירם"
        },
        {
            "_id": 26821,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4453,
            "שם_רחוב": "סיון שלום"
        },
        {
            "_id": 26822,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1135,
            "שם_רחוב": "סיימון אריה"
        },
        {
            "_id": 26823,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4759,
            "שם_רחוב": "סיירת גולני"
        },
        {
            "_id": 26824,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4440,
            "שם_רחוב": "סילבר אבא הלל"
        },
        {
            "_id": 26825,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4251,
            "שם_רחוב": "סילוואן"
        },
        {
            "_id": 26826,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1037,
            "שם_רחוב": "סילמן"
        },
        {
            "_id": 26827,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1202,
            "שם_רחוב": "סימן טוב יעקב"
        },
        {
            "_id": 26828,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1226,
            "שם_רחוב": "סירני"
        },
        {
            "_id": 26829,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1902,
            "שם_רחוב": "סירקיס דניאל"
        },
        {
            "_id": 26830,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4712,
            "שם_רחוב": "סירת אל חג'ה"
        },
        {
            "_id": 26831,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3350,
            "שם_רחוב": "סירת אל חג'ה סמ1"
        },
        {
            "_id": 26832,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3351,
            "שם_רחוב": "סירת אל חג'ה סמ2"
        },
        {
            "_id": 26833,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4088,
            "שם_רחוב": "סית אמינה"
        },
        {
            "_id": 26834,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5489,
            "שם_רחוב": "סכת אל-חדיד"
        },
        {
            "_id": 26835,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4100,
            "שם_רחוב": "סלאח אל-דין"
        },
        {
            "_id": 26836,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5394,
            "שם_רחוב": "סלאח אל-דין סמ1"
        },
        {
            "_id": 26837,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5395,
            "שם_רחוב": "סלאח אל-דין סמ3"
        },
        {
            "_id": 26838,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4363,
            "שם_רחוב": "סלומון אלפרד"
        },
        {
            "_id": 26839,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 442,
            "שם_רחוב": "סלומון יואל משה"
        },
        {
            "_id": 26840,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5581,
            "שם_רחוב": "סלמאן אל פרסי"
        },
        {
            "_id": 26841,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5744,
            "שם_רחוב": "סלמאן אל-פארסי"
        },
        {
            "_id": 26842,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5745,
            "שם_רחוב": "סלמאן אל-פארסי סמ1"
        },
        {
            "_id": 26843,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5746,
            "שם_רחוב": "סלמאן אל-פארסי סמ2"
        },
        {
            "_id": 26844,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3095,
            "שם_רחוב": "סלמאן אל-פרסי סמ1"
        },
        {
            "_id": 26845,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3096,
            "שם_רחוב": "סלמאן אל-פרסי סמ3"
        },
        {
            "_id": 26846,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3097,
            "שם_רחוב": "סלמאן אל-פרסי סמ4"
        },
        {
            "_id": 26847,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3098,
            "שם_רחוב": "סלמאן אל-פרסי סמ6"
        },
        {
            "_id": 26848,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3099,
            "שם_רחוב": "סלמאן אל-פרסי סמ8"
        },
        {
            "_id": 26849,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 640,
            "שם_רחוב": "סלמן אליהו"
        },
        {
            "_id": 26850,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1187,
            "שם_רחוב": "סלמן יעקב"
        },
        {
            "_id": 26851,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 824,
            "שם_רחוב": "סמ ברלין"
        },
        {
            "_id": 26852,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 687,
            "שם_רחוב": "סמ בתי גורל"
        },
        {
            "_id": 26853,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1483,
            "שם_רחוב": "סמ הבאר"
        },
        {
            "_id": 26854,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1482,
            "שם_רחוב": "סמ הגבעה"
        },
        {
            "_id": 26855,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1486,
            "שם_רחוב": "סמ היין"
        },
        {
            "_id": 26856,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1485,
            "שם_רחוב": "סמ היקב"
        },
        {
            "_id": 26857,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1484,
            "שם_רחוב": "סמ העורב"
        },
        {
            "_id": 26858,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5652,
            "שם_רחוב": "סמ צלאחיה"
        },
        {
            "_id": 26859,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1654,
            "שם_רחוב": "סמאטס"
        },
        {
            "_id": 26860,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3264,
            "שם_רחוב": "סמבוסקי"
        },
        {
            "_id": 26861,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1052,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 26862,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 437,
            "שם_רחוב": "סמואל"
        },
        {
            "_id": 26863,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1513,
            "שם_רחוב": "סמולנסקין"
        },
        {
            "_id": 26864,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 693,
            "שם_רחוב": "סמטת אוהבי ציון"
        },
        {
            "_id": 26865,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1419,
            "שם_רחוב": "סמטת הפיקוס"
        },
        {
            "_id": 26866,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 280,
            "שם_רחוב": "סמטת נהר שלום"
        },
        {
            "_id": 26867,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1788,
            "שם_רחוב": "סמטת ספראי"
        },
        {
            "_id": 26868,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4706,
            "שם_רחוב": "סמיר אמיס"
        },
        {
            "_id": 26869,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3335,
            "שם_רחוב": "סמיר אמיס סמ1"
        },
        {
            "_id": 26870,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3336,
            "שם_רחוב": "סמיר אמיס סמ2"
        },
        {
            "_id": 26871,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3337,
            "שם_רחוב": "סמיר אמיס סמ3"
        },
        {
            "_id": 26872,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3338,
            "שם_רחוב": "סמיר אמיס סמ7"
        },
        {
            "_id": 26873,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1349,
            "שם_רחוב": "סן מרטין"
        },
        {
            "_id": 26874,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 523,
            "שם_רחוב": "סן פול"
        },
        {
            "_id": 26875,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3007,
            "שם_רחוב": "סנה משה"
        },
        {
            "_id": 26876,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "סנהדריה מורחבת"
        },
        {
            "_id": 26877,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4549,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 26878,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4307,
            "שם_רחוב": "סנט ג'ורג'"
        },
        {
            "_id": 26879,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4079,
            "שם_רחוב": "סנט גורגס"
        },
        {
            "_id": 26880,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4058,
            "שם_רחוב": "סנט גיימס"
        },
        {
            "_id": 26881,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4080,
            "שם_רחוב": "סנט הלנה"
        },
        {
            "_id": 26882,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4084,
            "שם_רחוב": "סנט מרקס"
        },
        {
            "_id": 26883,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4068,
            "שם_רחוב": "סנט פטר"
        },
        {
            "_id": 26884,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4069,
            "שם_רחוב": "סנט פרנציס"
        },
        {
            "_id": 26885,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 815,
            "שם_רחוב": "סעדיה גאון"
        },
        {
            "_id": 26886,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4211,
            "שם_רחוב": "ספח"
        },
        {
            "_id": 26887,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5507,
            "שם_רחוב": "סראחן"
        },
        {
            "_id": 26888,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5969,
            "שם_רחוב": "סרור"
        },
        {
            "_id": 26889,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5970,
            "שם_רחוב": "סרור סמ1"
        },
        {
            "_id": 26890,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1433,
            "שם_רחוב": "סרנא יחזקאל"
        },
        {
            "_id": 26891,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1420,
            "שם_רחוב": "סתר המדרגה"
        },
        {
            "_id": 26892,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5747,
            "שם_רחוב": "ע'סאן"
        },
        {
            "_id": 26893,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4262,
            "שם_רחוב": "עאוגנה"
        },
        {
            "_id": 26894,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4219,
            "שם_רחוב": "עאידה"
        },
        {
            "_id": 26895,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4910,
            "שם_רחוב": "עבאדה בן אלסאמת"
        },
        {
            "_id": 26896,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4247,
            "שם_רחוב": "עבאסיה"
        },
        {
            "_id": 26897,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4645,
            "שם_רחוב": "עבד א-רחמאן דג'אני"
        },
        {
            "_id": 26898,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4628,
            "שם_רחוב": "עבד אל חמיד שומאן"
        },
        {
            "_id": 26899,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4638,
            "שם_רחוב": "עבד אל-עזיז"
        },
        {
            "_id": 26900,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5748,
            "שם_רחוב": "עבד אל-עזיז סמ1"
        },
        {
            "_id": 26901,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3339,
            "שם_רחוב": "עבדאללה בן עבאס"
        },
        {
            "_id": 26902,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3340,
            "שם_רחוב": "עבדאללה בן עבאס סמ2"
        },
        {
            "_id": 26903,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3341,
            "שם_רחוב": "עבדאללה בן עבאס סמ3"
        },
        {
            "_id": 26904,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3342,
            "שם_רחוב": "עבדאללה בן עבאס סמ4"
        },
        {
            "_id": 26905,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3343,
            "שם_רחוב": "עבדאללה בן עבאס סמ7"
        },
        {
            "_id": 26906,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5972,
            "שם_רחוב": "עבדו"
        },
        {
            "_id": 26907,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5973,
            "שם_רחוב": "עבדו סמ1"
        },
        {
            "_id": 26908,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4234,
            "שם_רחוב": "עבדיה"
        },
        {
            "_id": 26909,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5158,
            "שם_רחוב": "עבדלחמיד שומאן סמ5"
        },
        {
            "_id": 26910,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5159,
            "שם_רחוב": "עבדלחמיד שומאן סמ7"
        },
        {
            "_id": 26911,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 219,
            "שם_רחוב": "עבודת ישראל"
        },
        {
            "_id": 26912,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 512,
            "שם_רחוב": "עדו הנביא"
        },
        {
            "_id": 26913,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4831,
            "שם_רחוב": "עדן שמואל"
        },
        {
            "_id": 26914,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1238,
            "שם_רחוב": "עוד יעקב רובין"
        },
        {
            "_id": 26915,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1724,
            "שם_רחוב": "עובד"
        },
        {
            "_id": 26916,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 318,
            "שם_רחוב": "עובדיה"
        },
        {
            "_id": 26917,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 831,
            "שם_רחוב": "עובדיה מברטנורה"
        },
        {
            "_id": 26918,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5514,
            "שם_רחוב": "עודה"
        },
        {
            "_id": 26919,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5526,
            "שם_רחוב": "עוויס"
        },
        {
            "_id": 26920,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3156,
            "שם_רחוב": "עוויס סמ2"
        },
        {
            "_id": 26921,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4227,
            "שם_רחוב": "עוויסת"
        },
        {
            "_id": 26922,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1634,
            "שם_רחוב": "עוזיה"
        },
        {
            "_id": 26923,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4325,
            "שם_רחוב": "עולי הגרדום"
        },
        {
            "_id": 26924,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4509,
            "שם_רחוב": "עולי הרגל"
        },
        {
            "_id": 26925,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4175,
            "שם_רחוב": "עומר אבן אל-עאס"
        },
        {
            "_id": 26926,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5981,
            "שם_רחוב": "עומר אבן אלעאס סמ1"
        },
        {
            "_id": 26927,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5987,
            "שם_רחוב": "עומר אבן אלעאס סמ11"
        },
        {
            "_id": 26928,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5982,
            "שם_רחוב": "עומר אבן אלעאס סמ3"
        },
        {
            "_id": 26929,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5988,
            "שם_רחוב": "עומר אבן אלעאס סמ13"
        },
        {
            "_id": 26930,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5983,
            "שם_רחוב": "עומר אבן אלעאס סמ4"
        },
        {
            "_id": 26931,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5984,
            "שם_רחוב": "עומר אבן אלעאס סמ5"
        },
        {
            "_id": 26932,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5989,
            "שם_רחוב": "עומר אבן אלעאס סמ15"
        },
        {
            "_id": 26933,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5985,
            "שם_רחוב": "עומר אבן אלעאס סמ7"
        },
        {
            "_id": 26934,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5986,
            "שם_רחוב": "עומר אבן אלעאס סמ9"
        },
        {
            "_id": 26935,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4060,
            "שם_רחוב": "עומר אבן כתב"
        },
        {
            "_id": 26936,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4629,
            "שם_רחוב": "עומר אל ח'יאם"
        },
        {
            "_id": 26937,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5974,
            "שם_רחוב": "עומר אל-פארוק"
        },
        {
            "_id": 26938,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5975,
            "שם_רחוב": "עומר אל-פארוק סמ2"
        },
        {
            "_id": 26939,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5976,
            "שם_רחוב": "עומר אל-פארוק סמ3"
        },
        {
            "_id": 26940,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 172,
            "שם_רחוב": "עונג שבת"
        },
        {
            "_id": 26941,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5659,
            "שם_רחוב": "עוקבה בן נאפע"
        },
        {
            "_id": 26942,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4423,
            "שם_רחוב": "עות'מן בן עפאן"
        },
        {
            "_id": 26943,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5360,
            "שם_רחוב": "עות'מן בן עפאן סמ6"
        },
        {
            "_id": 26944,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4356,
            "שם_רחוב": "עזאר שמואל"
        },
        {
            "_id": 26945,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 808,
            "שם_רחוב": "עזה"
        },
        {
            "_id": 26946,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 371,
            "שם_רחוב": "עזר יולדות"
        },
        {
            "_id": 26947,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 126,
            "שם_רחוב": "עזרא"
        },
        {
            "_id": 26948,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1138,
            "שם_רחוב": "עזריאל"
        },
        {
            "_id": 26949,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1646,
            "שם_רחוב": "עזריה"
        },
        {
            "_id": 26950,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 425,
            "שם_רחוב": "עזרת ישראל"
        },
        {
            "_id": 26951,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 390,
            "שם_רחוב": "עזרת תורה"
        },
        {
            "_id": 26952,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5230,
            "שם_רחוב": "עטון"
        },
        {
            "_id": 26953,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4534,
            "שם_רחוב": "עטרות"
        },
        {
            "_id": 26954,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4116,
            "שם_רחוב": "עין אום שריט"
        },
        {
            "_id": 26955,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4263,
            "שם_רחוב": "עין אל-לוזה"
        },
        {
            "_id": 26956,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3209,
            "שם_רחוב": "עין אל-לוזה סמ1"
        },
        {
            "_id": 26957,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3210,
            "שם_רחוב": "עין אל-לוזה סמ3"
        },
        {
            "_id": 26958,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3224,
            "שם_רחוב": "עין אל-לוזה סמ4"
        },
        {
            "_id": 26959,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3211,
            "שם_רחוב": "עין אל-לוזה סמ5"
        },
        {
            "_id": 26960,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3212,
            "שם_רחוב": "עין אל-לוזה סמ7"
        },
        {
            "_id": 26961,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3213,
            "שם_רחוב": "עין אל-לוזה סמ9"
        },
        {
            "_id": 26962,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1736,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 26963,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4217,
            "שם_רחוב": "עין חנטש"
        },
        {
            "_id": 26964,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 164,
            "שם_רחוב": "עין יעקב"
        },
        {
            "_id": 26965,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1401,
            "שם_רחוב": "עין כרם"
        },
        {
            "_id": 26966,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1717,
            "שם_רחוב": "עין רוגל"
        },
        {
            "_id": 26967,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5980,
            "שם_רחוב": "עין רוגל סמ1"
        },
        {
            "_id": 26968,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 683,
            "שם_רחוב": "עינים למשפט"
        },
        {
            "_id": 26969,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4121,
            "שם_רחוב": "עיסוויה"
        },
        {
            "_id": 26970,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6875,
            "שם_רחוב": "עיר דוד"
        },
        {
            "_id": 26971,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5131,
            "שם_רחוב": "עכרמה בן אבי ג'הל"
        },
        {
            "_id": 26972,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5142,
            "שם_רחוב": "עכרמה בן ג'הל סמ1"
        },
        {
            "_id": 26973,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4374,
            "שם_רחוב": "עלר"
        },
        {
            "_id": 26974,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4031,
            "שם_רחוב": "עלא דין"
        },
        {
            "_id": 26975,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4196,
            "שם_רחוב": "עלי אבן אבו טאלב"
        },
        {
            "_id": 26976,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 314,
            "שם_רחוב": "עלי הכהן"
        },
        {
            "_id": 26977,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5992,
            "שם_רחוב": "עלי חג'אזי"
        },
        {
            "_id": 26978,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5289,
            "שם_רחוב": "עלי משעל"
        },
        {
            "_id": 26979,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 431,
            "שם_רחוב": "עליאש"
        },
        {
            "_id": 26980,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4457,
            "שם_רחוב": "עלית הנוער"
        },
        {
            "_id": 26981,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 645,
            "שם_רחוב": "עלמא"
        },
        {
            "_id": 26982,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 898,
            "שם_רחוב": "עם ועולמו"
        },
        {
            "_id": 26983,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 311,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 26984,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1720,
            "שם_רחוב": "עמינדב"
        },
        {
            "_id": 26985,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4657,
            "שם_רחוב": "עמל"
        },
        {
            "_id": 26986,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1236,
            "שם_רחוב": "עמנואל זיסמן"
        },
        {
            "_id": 26987,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1662,
            "שם_רחוב": "עמנואל נח"
        },
        {
            "_id": 26988,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6628,
            "שם_רחוב": "עמק הארזים"
        },
        {
            "_id": 26989,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1487,
            "שם_רחוב": "עמק התימנים"
        },
        {
            "_id": 26990,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1087,
            "שם_רחוב": "עמק ציון"
        },
        {
            "_id": 26991,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1636,
            "שם_רחוב": "עמק רפאים"
        },
        {
            "_id": 26992,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 904,
            "שם_רחוב": "עמרם גאון"
        },
        {
            "_id": 26993,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1910,
            "שם_רחוב": "עמשא"
        },
        {
            "_id": 26994,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4633,
            "שם_רחוב": "ענאתה החדשה"
        },
        {
            "_id": 26995,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 717,
            "שם_רחוב": "ענתבי"
        },
        {
            "_id": 26996,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 661,
            "שם_רחוב": "ענתות"
        },
        {
            "_id": 26997,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 238,
            "שם_רחוב": "עץ הדר"
        },
        {
            "_id": 26998,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 617,
            "שם_רחוב": "עץ חיים"
        },
        {
            "_id": 26999,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4296,
            "שם_רחוב": "עציון גבר"
        },
        {
            "_id": 27000,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4676,
            "שם_רחוב": "עצמה"
        },
        {
            "_id": 27001,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4048,
            "שם_רחוב": "עק אבו מדין"
        },
        {
            "_id": 27002,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4025,
            "שם_רחוב": "עק אל אסאלה"
        },
        {
            "_id": 27003,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4043,
            "שם_רחוב": "עק אל חלידיה"
        },
        {
            "_id": 27004,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4045,
            "שם_רחוב": "עק דאר בריק"
        },
        {
            "_id": 27005,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4078,
            "שם_רחוב": "עק חן א עקבת"
        },
        {
            "_id": 27006,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4021,
            "שם_רחוב": "עק שיח ריחאן"
        },
        {
            "_id": 27007,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4075,
            "שם_רחוב": "עקבת א בטיח"
        },
        {
            "_id": 27008,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4070,
            "שם_רחוב": "עקבת א חנקה"
        },
        {
            "_id": 27009,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4016,
            "שם_רחוב": "עקבת א מופתי"
        },
        {
            "_id": 27010,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4117,
            "שם_רחוב": "עקבת א נאצר"
        },
        {
            "_id": 27011,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4040,
            "שם_רחוב": "עקבת א סראיה"
        },
        {
            "_id": 27012,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4047,
            "שם_רחוב": "עקבת א תות"
        },
        {
            "_id": 27013,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5490,
            "שם_רחוב": "עקבת אל-ג'בל"
        },
        {
            "_id": 27014,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4007,
            "שם_רחוב": "עקבת דרוויש"
        },
        {
            "_id": 27015,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4015,
            "שם_רחוב": "עקבת חב רומן"
        },
        {
            "_id": 27016,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4143,
            "שם_רחוב": "עקבת סוואנה"
        },
        {
            "_id": 27017,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4054,
            "שם_רחוב": "עקבת ע נים"
        },
        {
            "_id": 27018,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4024,
            "שם_רחוב": "עקבת ריסאס"
        },
        {
            "_id": 27019,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4019,
            "שם_רחוב": "עקבת שאדד"
        },
        {
            "_id": 27020,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4017,
            "שם_רחוב": "עקבת שיח חסן"
        },
        {
            "_id": 27021,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4023,
            "שם_רחוב": "עקבת שיח לולו"
        },
        {
            "_id": 27022,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4044,
            "שם_רחוב": "עקבת תקיה"
        },
        {
            "_id": 27023,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1442,
            "שם_רחוב": "עקיבא כוס בינה"
        },
        {
            "_id": 27024,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4709,
            "שם_רחוב": "עראק אל טאירה"
        },
        {
            "_id": 27025,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5408,
            "שם_רחוב": "עראק אל טאירה סמ2"
        },
        {
            "_id": 27026,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3044,
            "שם_רחוב": "ערד"
        },
        {
            "_id": 27027,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3257,
            "שם_רחוב": "ערוגות הבושם"
        },
        {
            "_id": 27028,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5749,
            "שם_רחוב": "ערפה"
        },
        {
            "_id": 27029,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5750,
            "שם_רחוב": "ערפה סמ3"
        },
        {
            "_id": 27030,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1732,
            "שם_רחוב": "עשהאל"
        },
        {
            "_id": 27031,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1766,
            "שם_רחוב": "עתניאל"
        },
        {
            "_id": 27032,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4837,
            "שם_רחוב": "פאגלין עמיחי"
        },
        {
            "_id": 27033,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 250,
            "שם_רחוב": "פאול פרנקל"
        },
        {
            "_id": 27034,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5231,
            "שם_רחוב": "פאטמה אל-זהרא'"
        },
        {
            "_id": 27035,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4306,
            "שם_רחוב": "פארן"
        },
        {
            "_id": 27036,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 372,
            "שם_רחוב": "פאת השולחן"
        },
        {
            "_id": 27037,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4814,
            "שם_רחוב": "פגיס דן"
        },
        {
            "_id": 27038,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4784,
            "שם_רחוב": "פדרמן דוד"
        },
        {
            "_id": 27039,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "פולונסקי"
        },
        {
            "_id": 27040,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4538,
            "שם_רחוב": "פולוצקי יעקב"
        },
        {
            "_id": 27041,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 264,
            "שם_רחוב": "פומבדיתא"
        },
        {
            "_id": 27042,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5573,
            "שם_רחוב": "פונדק ההודים"
        },
        {
            "_id": 27043,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5002,
            "שם_רחוב": "פונט ישראל"
        },
        {
            "_id": 27044,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 972,
            "שם_רחוב": "פועה"
        },
        {
            "_id": 27045,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1843,
            "שם_רחוב": "פועלי צדק"
        },
        {
            "_id": 27046,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2014,
            "שם_רחוב": "פורת לאה"
        },
        {
            "_id": 27047,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4775,
            "שם_רחוב": "פזנר חיים"
        },
        {
            "_id": 27048,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 406,
            "שם_רחוב": "פטרושקה שבתאי"
        },
        {
            "_id": 27049,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3043,
            "שם_רחוב": "פטריה"
        },
        {
            "_id": 27050,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1658,
            "שם_רחוב": "פטרסון"
        },
        {
            "_id": 27051,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3555,
            "שם_רחוב": "פייבל"
        },
        {
            "_id": 27052,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4669,
            "שם_רחוב": "פיידי אל עלמי"
        },
        {
            "_id": 27053,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4997,
            "שם_רחוב": "פיידי אל עלמי סמ1"
        },
        {
            "_id": 27054,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4998,
            "שם_רחוב": "פיידי אל עלמי סמ3"
        },
        {
            "_id": 27055,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4999,
            "שם_רחוב": "פיידי אל עלמי סמ5"
        },
        {
            "_id": 27056,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4337,
            "שם_רחוב": "פיינשטיין"
        },
        {
            "_id": 27057,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4286,
            "שם_רחוב": "פייר ואן פאסן"
        },
        {
            "_id": 27058,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4610,
            "שם_רחוב": "פיירוטי"
        },
        {
            "_id": 27059,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1416,
            "שם_רחוב": "פיכמן"
        },
        {
            "_id": 27060,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 396,
            "שם_רחוב": "פין גיימס"
        },
        {
            "_id": 27061,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1393,
            "שם_רחוב": "פינלס שמואל"
        },
        {
            "_id": 27062,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 345,
            "שם_רחוב": "פינס"
        },
        {
            "_id": 27063,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1501,
            "שם_רחוב": "פינסקר"
        },
        {
            "_id": 27064,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 482,
            "שם_רחוב": "פינת סעדיה מרציאנו"
        },
        {
            "_id": 27065,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1010,
            "שם_רחוב": "פיק חיים"
        },
        {
            "_id": 27066,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1889,
            "שם_רחוב": "פיקרד לאו"
        },
        {
            "_id": 27067,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "פישל אהרן"
        },
        {
            "_id": 27068,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1396,
            "שם_רחוב": "פישר מוריס"
        },
        {
            "_id": 27069,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1704,
            "שם_רחוב": "פלא יועץ"
        },
        {
            "_id": 27070,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1876,
            "שם_רחוב": "פלג שניאור"
        },
        {
            "_id": 27071,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4776,
            "שם_רחוב": "פלוגת הטנקים"
        },
        {
            "_id": 27072,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4311,
            "שם_רחוב": "פלוגת הכותל"
        },
        {
            "_id": 27073,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1199,
            "שם_רחוב": "פלומבו"
        },
        {
            "_id": 27074,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1871,
            "שם_רחוב": "פלוסר דוד"
        },
        {
            "_id": 27075,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1228,
            "שם_רחוב": "פלורנטין"
        },
        {
            "_id": 27076,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1866,
            "שם_רחוב": "פלט"
        },
        {
            "_id": 27077,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1892,
            "שם_רחוב": "פלק זאב"
        },
        {
            "_id": 27078,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3214,
            "שם_רחוב": "פנורמה"
        },
        {
            "_id": 27079,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 965,
            "שם_רחוב": "פנים מאירות"
        },
        {
            "_id": 27080,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1284,
            "שם_רחוב": "פנימית כרמית"
        },
        {
            "_id": 27081,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 985,
            "שם_רחוב": "פנינה"
        },
        {
            "_id": 27082,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1278,
            "שם_רחוב": "פנמה"
        },
        {
            "_id": 27083,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1426,
            "שם_רחוב": "פנקס"
        },
        {
            "_id": 27084,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6805,
            "שם_רחוב": "פסגת זאב"
        },
        {
            "_id": 27085,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6811,
            "שם_רחוב": "פסגת זאב מזרח"
        },
        {
            "_id": 27086,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6810,
            "שם_רחוב": "פסגת זאב מערב"
        },
        {
            "_id": 27087,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6812,
            "שם_רחוב": "פסגת זאב צפון"
        },
        {
            "_id": 27088,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6798,
            "שם_רחוב": "פסגת רמות"
        },
        {
            "_id": 27089,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1277,
            "שם_רחוב": "פסח חברוני"
        },
        {
            "_id": 27090,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4170,
            "שם_רחוב": "פקוד מרכז"
        },
        {
            "_id": 27091,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 330,
            "שם_רחוב": "פקיעין"
        },
        {
            "_id": 27092,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 423,
            "שם_רחוב": "פראג יצחק"
        },
        {
            "_id": 27093,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4682,
            "שם_רחוב": "פראוור יהושע"
        },
        {
            "_id": 27094,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1023,
            "שם_רחוב": "פרבשטיין"
        },
        {
            "_id": 27095,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3261,
            "שם_רחוב": "פרדס רימונים"
        },
        {
            "_id": 27096,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 417,
            "שם_רחוב": "פרומקין"
        },
        {
            "_id": 27097,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1129,
            "שם_רחוב": "פרופ רקח"
        },
        {
            "_id": 27098,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1550,
            "שם_רחוב": "פרופ' אבנר חי שאקי"
        },
        {
            "_id": 27099,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3035,
            "שם_רחוב": "פרופ' אנריקה פברגט"
        },
        {
            "_id": 27100,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 278,
            "שם_רחוב": "פרופ' גרשון שקד"
        },
        {
            "_id": 27101,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 491,
            "שם_רחוב": "פרופ' זאב בן חיים"
        },
        {
            "_id": 27102,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 296,
            "שם_רחוב": "פרופ' יום טוב עסיס"
        },
        {
            "_id": 27103,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 550,
            "שם_רחוב": "פרופ' יעקב נאמן"
        },
        {
            "_id": 27104,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 281,
            "שם_רחוב": "פרופ' מנדי רודן"
        },
        {
            "_id": 27105,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 300,
            "שם_רחוב": "פרופ' מנשה הראל"
        },
        {
            "_id": 27106,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 299,
            "שם_רחוב": "פרופ' עזרה זהר"
        },
        {
            "_id": 27107,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1194,
            "שם_רחוב": "פרופ' צבי נוימן"
        },
        {
            "_id": 27108,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1192,
            "שם_רחוב": "פרופ' רענן ויץ"
        },
        {
            "_id": 27109,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4466,
            "שם_רחוב": "פרופס"
        },
        {
            "_id": 27110,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 723,
            "שם_רחוב": "פרוש"
        },
        {
            "_id": 27111,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 131,
            "שם_רחוב": "פרחי חיים"
        },
        {
            "_id": 27112,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1050,
            "שם_רחוב": "פרחי חן"
        },
        {
            "_id": 27113,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 343,
            "שם_רחוב": "פרי חדש"
        },
        {
            "_id": 27114,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4659,
            "שם_רחוב": "פרי עמל"
        },
        {
            "_id": 27115,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4834,
            "שם_רחוב": "פרידלר יואל"
        },
        {
            "_id": 27116,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 153,
            "שם_רחוב": "פרידמן"
        },
        {
            "_id": 27117,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1881,
            "שם_רחוב": "פרימו לוי"
        },
        {
            "_id": 27118,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1682,
            "שם_רחוב": "פריר רחה"
        },
        {
            "_id": 27119,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1408,
            "שם_רחוב": "פרישמן דוד"
        },
        {
            "_id": 27120,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4847,
            "שם_רחוב": "פרל וויליאם"
        },
        {
            "_id": 27121,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1358,
            "שם_רחוב": "פרלשטיין"
        },
        {
            "_id": 27122,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1153,
            "שם_רחוב": "פרנס"
        },
        {
            "_id": 27123,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4444,
            "שם_רחוב": "פרנקפורטר פליקס"
        },
        {
            "_id": 27124,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1829,
            "שם_רחוב": "פרץ"
        },
        {
            "_id": 27125,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1175,
            "שם_רחוב": "פררה אברהם"
        },
        {
            "_id": 27126,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1308,
            "שם_רחוב": "פת יעקב"
        },
        {
            "_id": 27127,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 237,
            "שם_רחוב": "פתוחי חותם"
        },
        {
            "_id": 27128,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 925,
            "שם_רחוב": "פתח תקוה"
        },
        {
            "_id": 27129,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 214,
            "שם_רחוב": "פתחיה"
        },
        {
            "_id": 27130,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5521,
            "שם_רחוב": "פתיחה"
        },
        {
            "_id": 27131,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3028,
            "שם_רחוב": "פתייה יהודה"
        },
        {
            "_id": 27132,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6543,
            "שם_רחוב": "צ'יאונה סוגיהארה"
        },
        {
            "_id": 27133,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1227,
            "שם_רחוב": "צ'ילה"
        },
        {
            "_id": 27134,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1213,
            "שם_רחוב": "צ'לנוב"
        },
        {
            "_id": 27135,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4621,
            "שם_רחוב": "צאייג מקסימום"
        },
        {
            "_id": 27136,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4561,
            "שם_רחוב": "צאלים"
        },
        {
            "_id": 27137,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5990,
            "שם_רחוב": "צבאח אל-מג'ד"
        },
        {
            "_id": 27138,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5991,
            "שם_רחוב": "צבאח אל-מג'ד סמ1"
        },
        {
            "_id": 27139,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4565,
            "שם_רחוב": "צביה ויצחק"
        },
        {
            "_id": 27140,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1681,
            "שם_רחוב": "צדקיהו"
        },
        {
            "_id": 27141,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 139,
            "שם_רחוב": "צהרי יחיא"
        },
        {
            "_id": 27142,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6253,
            "שם_רחוב": "צומת מגני חיים"
        },
        {
            "_id": 27143,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4458,
            "שם_רחוב": "צונדק"
        },
        {
            "_id": 27144,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 665,
            "שם_רחוב": "צוער"
        },
        {
            "_id": 27145,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1488,
            "שם_רחוב": "צוקי הישועה"
        },
        {
            "_id": 27146,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3005,
            "שם_רחוב": "צוקרמן ברוך"
        },
        {
            "_id": 27147,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4220,
            "שם_רחוב": "צור בחר"
        },
        {
            "_id": 27148,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1289,
            "שם_רחוב": "צור יעקב"
        },
        {
            "_id": 27149,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5993,
            "שם_רחוב": "צורי"
        },
        {
            "_id": 27150,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5994,
            "שם_רחוב": "צורי סמ1"
        },
        {
            "_id": 27151,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1348,
            "שם_רחוב": "צייטלין"
        },
        {
            "_id": 27152,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1078,
            "שם_רחוב": "צמח שלמה"
        },
        {
            "_id": 27153,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6787,
            "שם_רחוב": "צמרת אלנבי"
        },
        {
            "_id": 27154,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6762,
            "שם_רחוב": "צפון תלפיות"
        },
        {
            "_id": 27155,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1823,
            "שם_רחוב": "צפורה"
        },
        {
            "_id": 27156,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 708,
            "שם_רחוב": "צפורי"
        },
        {
            "_id": 27157,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1384,
            "שם_רחוב": "צפירה ברכה"
        },
        {
            "_id": 27158,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 303,
            "שם_רחוב": "צפניה"
        },
        {
            "_id": 27159,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4433,
            "שם_רחוב": "צפרירים"
        },
        {
            "_id": 27160,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 707,
            "שם_רחוב": "צפת"
        },
        {
            "_id": 27161,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1851,
            "שם_רחוב": "צרויה"
        },
        {
            "_id": 27162,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1795,
            "שם_רחוב": "צרת"
        },
        {
            "_id": 27163,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1118,
            "שם_רחוב": "קאסוטו"
        },
        {
            "_id": 27164,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4643,
            "שם_רחוב": "קאעב בן זוהיר"
        },
        {
            "_id": 27165,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 135,
            "שם_רחוב": "קאפח"
        },
        {
            "_id": 27166,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "קארו יוסף"
        },
        {
            "_id": 27167,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 386,
            "שם_רחוב": "קב ונקי"
        },
        {
            "_id": 27168,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1809,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 27169,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1045,
            "שם_רחוב": "קבק"
        },
        {
            "_id": 27170,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4347,
            "שם_רחוב": "קדושי בבל"
        },
        {
            "_id": 27171,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1350,
            "שם_רחוב": "קדושי סטרומה"
        },
        {
            "_id": 27172,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1800,
            "שם_רחוב": "קדושי סלוניקי"
        },
        {
            "_id": 27173,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4905,
            "שם_רחוב": "קדושת אהרון"
        },
        {
            "_id": 27174,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4467,
            "שם_רחוב": "קדימה"
        },
        {
            "_id": 27175,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1369,
            "שם_רחוב": "קדיש לוז"
        },
        {
            "_id": 27176,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3215,
            "שם_רחוב": "קדמת ציון"
        },
        {
            "_id": 27177,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4964,
            "שם_רחוב": "קהילות יעקב"
        },
        {
            "_id": 27178,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4471,
            "שם_רחוב": "קהילות שום"
        },
        {
            "_id": 27179,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4495,
            "שם_רחוב": "קהילת אונגוואר"
        },
        {
            "_id": 27180,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 943,
            "שם_רחוב": "קהתי פנחס"
        },
        {
            "_id": 27181,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 934,
            "שם_רחוב": "קואנקה"
        },
        {
            "_id": 27182,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1364,
            "שם_רחוב": "קובובי"
        },
        {
            "_id": 27183,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4108,
            "שם_רחוב": "קוביבה"
        },
        {
            "_id": 27184,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4533,
            "שם_רחוב": "קוברסקי חיים"
        },
        {
            "_id": 27185,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "קוטגים גונן ט"
        },
        {
            "_id": 27186,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 966,
            "שם_רחוב": "קוטלר"
        },
        {
            "_id": 27187,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4490,
            "שם_רחוב": "קוטשר יחזקאל"
        },
        {
            "_id": 27188,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4536,
            "שם_רחוב": "קויפמן יחזקאל"
        },
        {
            "_id": 27189,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1085,
            "שם_רחוב": "קול משה"
        },
        {
            "_id": 27190,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1265,
            "שם_רחוב": "קולומביה"
        },
        {
            "_id": 27191,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 217,
            "שם_רחוב": "קונדר"
        },
        {
            "_id": 27192,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2004,
            "שם_רחוב": "קוסטה ריקה"
        },
        {
            "_id": 27193,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1738,
            "שם_רחוב": "קורא הדורות"
        },
        {
            "_id": 27194,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 933,
            "שם_רחוב": "קורדובירו"
        },
        {
            "_id": 27195,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1117,
            "שם_רחוב": "קורות העיתים"
        },
        {
            "_id": 27196,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1897,
            "שם_רחוב": "קורן אליהו"
        },
        {
            "_id": 27197,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1878,
            "שם_רחוב": "קורץ"
        },
        {
            "_id": 27198,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1273,
            "שם_רחוב": "קורצאק"
        },
        {
            "_id": 27199,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4499,
            "שם_רחוב": "קושניר ניסן"
        },
        {
            "_id": 27200,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4062,
            "שם_רחוב": "קזה נובה"
        },
        {
            "_id": 27201,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4397,
            "שם_רחוב": "קיס נעמי"
        },
        {
            "_id": 27202,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4911,
            "שם_רחוב": "קיסאן"
        },
        {
            "_id": 27203,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5234,
            "שם_רחוב": "קיסאן סמ1"
        },
        {
            "_id": 27204,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5235,
            "שם_רחוב": "קיסאן סמ11"
        },
        {
            "_id": 27205,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2538,
            "שם_רחוב": "קיסאן סמ3"
        },
        {
            "_id": 27206,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5237,
            "שם_רחוב": "קיסאן סמ13"
        },
        {
            "_id": 27207,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5236,
            "שם_רחוב": "קיסאן סמ4"
        },
        {
            "_id": 27208,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5239,
            "שם_רחוב": "קיסאן סמ5"
        },
        {
            "_id": 27209,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5678,
            "שם_רחוב": "קיסאן סמ6"
        },
        {
            "_id": 27210,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5240,
            "שם_רחוב": "קיסאן סמ7"
        },
        {
            "_id": 27211,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5241,
            "שם_רחוב": "קיסאן סמ8"
        },
        {
            "_id": 27212,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5242,
            "שם_רחוב": "קיסאן סמ9"
        },
        {
            "_id": 27213,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4229,
            "שם_רחוב": "קיר אל פאר"
        },
        {
            "_id": 27214,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1747,
            "שם_רחוב": "קלוזנר יוסף"
        },
        {
            "_id": 27215,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1667,
            "שם_רחוב": "קליין"
        },
        {
            "_id": 27216,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1223,
            "שם_רחוב": "קליינמן"
        },
        {
            "_id": 27217,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 976,
            "שם_רחוב": "קלימקר דב"
        },
        {
            "_id": 27218,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2001,
            "שם_רחוב": "קלנר אריה"
        },
        {
            "_id": 27219,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1336,
            "שם_רחוב": "קלעי חנוך"
        },
        {
            "_id": 27220,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4601,
            "שם_רחוב": "קלרמון גנו"
        },
        {
            "_id": 27221,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4598,
            "שם_רחוב": "קמזון יעקב דוד"
        },
        {
            "_id": 27222,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1365,
            "שם_רחוב": "קמחי דב"
        },
        {
            "_id": 27223,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6654,
            "שם_רחוב": "קמפוס גבעת רם"
        },
        {
            "_id": 27224,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6827,
            "שם_רחוב": "קמפוס הר הצופים"
        },
        {
            "_id": 27225,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1309,
            "שם_רחוב": "קנאי הגליל"
        },
        {
            "_id": 27226,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5971,
            "שם_רחוב": "קנבר"
        },
        {
            "_id": 27227,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5965,
            "שם_רחוב": "קנבר סמ1"
        },
        {
            "_id": 27228,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5966,
            "שם_רחוב": "קנבר סמ2"
        },
        {
            "_id": 27229,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5967,
            "שם_רחוב": "קנבר סמ3"
        },
        {
            "_id": 27230,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5968,
            "שם_רחוב": "קנבר סמ4"
        },
        {
            "_id": 27231,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4046,
            "שם_רחוב": "קנטר חדאיר"
        },
        {
            "_id": 27232,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1461,
            "שם_רחוב": "קניון מלחה"
        },
        {
            "_id": 27233,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4713,
            "שם_רחוב": "קסילה"
        },
        {
            "_id": 27234,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4148,
            "שם_רחוב": "קע אל חארה"
        },
        {
            "_id": 27235,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1108,
            "שם_רחוב": "קפלן"
        },
        {
            "_id": 27236,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4409,
            "שם_רחוב": "קצה אל רצול"
        },
        {
            "_id": 27237,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4912,
            "שם_רחוב": "קצילה"
        },
        {
            "_id": 27238,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4360,
            "שם_רחוב": "קציר אהרון"
        },
        {
            "_id": 27239,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1406,
            "שם_רחוב": "קצנלסון"
        },
        {
            "_id": 27240,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2005,
            "שם_רחוב": "קראוס גרטרוד"
        },
        {
            "_id": 27241,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5751,
            "שם_רחוב": "קרטבה"
        },
        {
            "_id": 27242,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5752,
            "שם_רחוב": "קרטבה סמ1"
        },
        {
            "_id": 27243,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 514,
            "שם_רחוב": "קריב יצחק"
        },
        {
            "_id": 27244,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "קרית אגי"
        },
        {
            "_id": 27245,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6613,
            "שם_רחוב": "קרית איתרי"
        },
        {
            "_id": 27246,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "קרית אריה"
        },
        {
            "_id": 27247,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6647,
            "שם_רחוב": "קרית בן גוריון"
        },
        {
            "_id": 27248,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6614,
            "שם_רחוב": "קרית בעלזא"
        },
        {
            "_id": 27249,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6661,
            "שם_רחוב": "קרית האוניברסיטה"
        },
        {
            "_id": 27250,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6686,
            "שם_רחוב": "קרית הדסה"
        },
        {
            "_id": 27251,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6698,
            "שם_רחוב": "קרית היובל"
        },
        {
            "_id": 27252,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6782,
            "שם_רחוב": "קרית המוסכים"
        },
        {
            "_id": 27253,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4623,
            "שם_רחוב": "קרית הממשלה ש גרח"
        },
        {
            "_id": 27254,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6560,
            "שם_רחוב": "קרית וולפסון"
        },
        {
            "_id": 27255,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6623,
            "שם_רחוב": "קרית ויזניץ"
        },
        {
            "_id": 27256,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6830,
            "שם_רחוב": "קרית מדע"
        },
        {
            "_id": 27257,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6768,
            "שם_רחוב": "קרית מוריה"
        },
        {
            "_id": 27258,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6605,
            "שם_רחוב": "קרית מטרסדורף"
        },
        {
            "_id": 27259,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6704,
            "שם_רחוב": "קרית מנחם"
        },
        {
            "_id": 27260,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1004,
            "שם_רחוב": "קרית משה"
        },
        {
            "_id": 27261,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 600,
            "שם_רחוב": "קרית עמנואל מורנו"
        },
        {
            "_id": 27262,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6814,
            "שם_רחוב": "קרית קמניץ"
        },
        {
            "_id": 27263,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1739,
            "שם_רחוב": "קרליבך"
        },
        {
            "_id": 27264,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1211,
            "שם_רחוב": "קרמינצקי"
        },
        {
            "_id": 27265,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1508,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 27266,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4472,
            "שם_רחוב": "קרני יהודה"
        },
        {
            "_id": 27267,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4684,
            "שם_רחוב": "קרניבד רפאל"
        },
        {
            "_id": 27268,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4059,
            "שם_רחוב": "קרקי"
        },
        {
            "_id": 27269,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4330,
            "שם_רחוב": "קשאני אליעזר"
        },
        {
            "_id": 27270,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 488,
            "שם_רחוב": "ראל משה לוי"
        },
        {
            "_id": 27271,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5584,
            "שם_רחוב": "ראבעה אל עדוויה"
        },
        {
            "_id": 27272,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3228,
            "שם_רחוב": "ראבעה אל עדוויה סמ8"
        },
        {
            "_id": 27273,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3100,
            "שם_רחוב": "ראבעה אל-עדוויה סמ2"
        },
        {
            "_id": 27274,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3101,
            "שם_רחוב": "ראבעה אל-עדוויה סמ4"
        },
        {
            "_id": 27275,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3102,
            "שם_רחוב": "ראבעה אל-עדוויה סמ6"
        },
        {
            "_id": 27276,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3103,
            "שם_רחוב": "ראבעה אל-עדוויה סמ7"
        },
        {
            "_id": 27277,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1777,
            "שם_רחוב": "ראובן"
        },
        {
            "_id": 27278,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1242,
            "שם_רחוב": "ראובן מנשה רביע"
        },
        {
            "_id": 27279,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1097,
            "שם_רחוב": "ראט משולם"
        },
        {
            "_id": 27280,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 227,
            "שם_רחוב": "ראם אליהו"
        },
        {
            "_id": 27281,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4261,
            "שם_רחוב": "ראס אל בושתן"
        },
        {
            "_id": 27282,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4913,
            "שם_רחוב": "ראס אל ג'אמע"
        },
        {
            "_id": 27283,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5412,
            "שם_רחוב": "ראס אל ג'אמע סמ3"
        },
        {
            "_id": 27284,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4127,
            "שם_רחוב": "ראס אל גאמע"
        },
        {
            "_id": 27285,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5662,
            "שם_רחוב": "ראס אל טוויל"
        },
        {
            "_id": 27286,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5094,
            "שם_רחוב": "ראס אל טוויל סמ5"
        },
        {
            "_id": 27287,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4390,
            "שם_רחוב": "ראס אל טלעה"
        },
        {
            "_id": 27288,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1854,
            "שם_רחוב": "ראס אל נתר"
        },
        {
            "_id": 27289,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4254,
            "שם_רחוב": "ראס אל עמוד"
        },
        {
            "_id": 27290,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4239,
            "שם_רחוב": "ראס אל עקבה"
        },
        {
            "_id": 27291,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5647,
            "שם_רחוב": "ראס אל-ג'בל"
        },
        {
            "_id": 27292,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5648,
            "שם_רחוב": "ראס אל-טלעה"
        },
        {
            "_id": 27293,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4624,
            "שם_רחוב": "ראס חמיס"
        },
        {
            "_id": 27294,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4257,
            "שם_רחוב": "ראס כובסא"
        },
        {
            "_id": 27295,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3216,
            "שם_רחוב": "ראס כובסא סמ2"
        },
        {
            "_id": 27296,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4714,
            "שם_רחוב": "ראס סאלם"
        },
        {
            "_id": 27297,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5413,
            "שם_רחוב": "ראס סאלם סמ1"
        },
        {
            "_id": 27298,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5414,
            "שם_רחוב": "ראס סאלם סמ3"
        },
        {
            "_id": 27299,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5415,
            "שם_רחוב": "ראס סאלם סמ4"
        },
        {
            "_id": 27300,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5416,
            "שם_רחוב": "ראס סאלם סמ5"
        },
        {
            "_id": 27301,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5417,
            "שם_רחוב": "ראס סאלם סמ7"
        },
        {
            "_id": 27302,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4723,
            "שם_רחוב": "ראס שחאדה"
        },
        {
            "_id": 27303,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4429,
            "שם_רחוב": "ראע'ב נשאשיבי"
        },
        {
            "_id": 27304,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5084,
            "שם_רחוב": "ראע'ב נשאשיבי סמ2"
        },
        {
            "_id": 27305,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5102,
            "שם_רחוב": "ראע'ב נשאשיבי סמ4"
        },
        {
            "_id": 27306,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6809,
            "שם_רחוב": "ראש אמיר"
        },
        {
            "_id": 27307,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 963,
            "שם_רחוב": "ראש פנה"
        },
        {
            "_id": 27308,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 962,
            "שם_רחוב": "ראשון לציון"
        },
        {
            "_id": 27309,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 367,
            "שם_רחוב": "ראשית חכמה"
        },
        {
            "_id": 27310,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4772,
            "שם_רחוב": "רב אלוף לסקוב"
        },
        {
            "_id": 27311,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1398,
            "שם_רחוב": "רב אשי"
        },
        {
            "_id": 27312,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 990,
            "שם_רחוב": "רב האי גאון"
        },
        {
            "_id": 27313,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5401,
            "שם_רחוב": "רב החובל"
        },
        {
            "_id": 27314,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 247,
            "שם_רחוב": "רב עבוד חיים שאול"
        },
        {
            "_id": 27315,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1014,
            "שם_רחוב": "רב צעיר"
        },
        {
            "_id": 27316,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5759,
            "שם_רחוב": "רבאיעה סמ10"
        },
        {
            "_id": 27317,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5754,
            "שם_רחוב": "רבאיעה סמ2"
        },
        {
            "_id": 27318,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5760,
            "שם_רחוב": "רבאיעה סמ12"
        },
        {
            "_id": 27319,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5755,
            "שם_רחוב": "רבאיעה סמ3"
        },
        {
            "_id": 27320,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5756,
            "שם_רחוב": "רבאיעה סמ4"
        },
        {
            "_id": 27321,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5757,
            "שם_רחוב": "רבאיעה סמ6"
        },
        {
            "_id": 27322,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5758,
            "שם_רחוב": "רבאיעה סמ8"
        },
        {
            "_id": 27323,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1743,
            "שם_רחוב": "רבדים"
        },
        {
            "_id": 27324,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 207,
            "שם_רחוב": "רבי אברהם נאה"
        },
        {
            "_id": 27325,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 684,
            "שם_רחוב": "רבי אריה"
        },
        {
            "_id": 27326,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1018,
            "שם_רחוב": "רבי בנימין"
        },
        {
            "_id": 27327,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1325,
            "שם_רחוב": "רבי זעירא"
        },
        {
            "_id": 27328,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1333,
            "שם_רחוב": "רבי חייא"
        },
        {
            "_id": 27329,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1330,
            "שם_רחוב": "רבי חלפתא"
        },
        {
            "_id": 27330,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1387,
            "שם_רחוב": "רבי חנינא"
        },
        {
            "_id": 27331,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1372,
            "שם_רחוב": "רבי טרפון"
        },
        {
            "_id": 27332,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1327,
            "שם_רחוב": "רבי מאיר"
        },
        {
            "_id": 27333,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 199,
            "שם_רחוב": "רבי נחמן מברסלב"
        },
        {
            "_id": 27334,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 445,
            "שם_רחוב": "רבי עקיבא"
        },
        {
            "_id": 27335,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1360,
            "שם_רחוב": "רבי צדוק"
        },
        {
            "_id": 27336,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 542,
            "שם_רחוב": "רבי רפאל אנקווה"
        },
        {
            "_id": 27337,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 201,
            "שם_רחוב": "רבי שלמה"
        },
        {
            "_id": 27338,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1386,
            "שם_רחוב": "רבי תנחומא"
        },
        {
            "_id": 27339,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4434,
            "שם_רחוב": "רביבים"
        },
        {
            "_id": 27340,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1219,
            "שם_רחוב": "רבינוביץ אהרון"
        },
        {
            "_id": 27341,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 744,
            "שם_רחוב": "רבן זאב"
        },
        {
            "_id": 27342,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "רבנו גרשום"
        },
        {
            "_id": 27343,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1856,
            "שם_רחוב": "רבנו פוליטי"
        },
        {
            "_id": 27344,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 353,
            "שם_רחוב": "רבנו תם"
        },
        {
            "_id": 27345,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1786,
            "שם_רחוב": "רבקה"
        },
        {
            "_id": 27346,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 814,
            "שם_רחוב": "רדק"
        },
        {
            "_id": 27347,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4349,
            "שם_רחוב": "רובוביץ אלכסנדר"
        },
        {
            "_id": 27348,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1264,
            "שם_רחוב": "רובינשטיין"
        },
        {
            "_id": 27349,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4421,
            "שם_רחוב": "רובע מוסלמי"
        },
        {
            "_id": 27350,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 545,
            "שם_רחוב": "רוז'ני יהודא ורחל"
        },
        {
            "_id": 27351,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1666,
            "שם_רחוב": "רוזאניס"
        },
        {
            "_id": 27352,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1400,
            "שם_רחוב": "רוזן פנחס"
        },
        {
            "_id": 27353,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4496,
            "שם_רחוב": "רוזנבלט"
        },
        {
            "_id": 27354,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 240,
            "שם_רחוב": "רוזנהים יעקב"
        },
        {
            "_id": 27355,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1375,
            "שם_רחוב": "רוזנפלד"
        },
        {
            "_id": 27356,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 829,
            "שם_רחוב": "רוטנברג"
        },
        {
            "_id": 27357,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1114,
            "שם_רחוב": "רוטשילד"
        },
        {
            "_id": 27358,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 942,
            "שם_רחוב": "רוממה"
        },
        {
            "_id": 27359,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6615,
            "שם_רחוב": "רוממה עילית"
        },
        {
            "_id": 27360,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4171,
            "שם_רחוב": "רומני"
        },
        {
            "_id": 27361,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 528,
            "שם_רחוב": "רועה צאן"
        },
        {
            "_id": 27362,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 156,
            "שם_רחוב": "רוקח שמעון"
        },
        {
            "_id": 27363,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1649,
            "שם_רחוב": "רות"
        },
        {
            "_id": 27364,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5601,
            "שם_רחוב": "רז יעקב"
        },
        {
            "_id": 27365,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4335,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 27366,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1943,
            "שם_רחוב": "רזיאל נאור אסתר"
        },
        {
            "_id": 27367,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4871,
            "שם_רחוב": "רח 8070"
        },
        {
            "_id": 27368,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4870,
            "שם_רחוב": "רח 8080"
        },
        {
            "_id": 27369,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6565,
            "שם_רחוב": "רחביה"
        },
        {
            "_id": 27370,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 388,
            "שם_רחוב": "רחבת הטירונים"
        },
        {
            "_id": 27371,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 746,
            "שם_רחוב": "רחבת חביב שמעוני"
        },
        {
            "_id": 27372,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 480,
            "שם_רחוב": "רחבת חיים שלום הלוי"
        },
        {
            "_id": 27373,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4371,
            "שם_רחוב": "רחבת יהודה חסיד"
        },
        {
            "_id": 27374,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6048,
            "שם_רחוב": "רחבת רבינוביץ"
        },
        {
            "_id": 27375,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רחובות הבוכרים"
        },
        {
            "_id": 27376,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1621,
            "שם_רחוב": "רחל אמנו"
        },
        {
            "_id": 27377,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4797,
            "שם_רחוב": "רחמילביץ משה"
        },
        {
            "_id": 27378,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 469,
            "שם_רחוב": "ריבלין אליעזר"
        },
        {
            "_id": 27379,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 441,
            "שם_רחוב": "ריבלין יוסף"
        },
        {
            "_id": 27380,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 152,
            "שם_רחוב": "רייכמן"
        },
        {
            "_id": 27381,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 838,
            "שם_רחוב": "רייק חביבה"
        },
        {
            "_id": 27382,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1290,
            "שם_רחוב": "רינגלבלום"
        },
        {
            "_id": 27383,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1324,
            "שם_רחוב": "ריש לקיש"
        },
        {
            "_id": 27384,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6864,
            "שם_רחוב": "רכס לבן"
        },
        {
            "_id": 27385,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 362,
            "שם_רחוב": "רלבח"
        },
        {
            "_id": 27386,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 809,
            "שם_רחוב": "רמבן"
        },
        {
            "_id": 27387,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5402,
            "שם_רחוב": "רמבה אייזיק"
        },
        {
            "_id": 27388,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4914,
            "שם_רחוב": "רמדאן"
        },
        {
            "_id": 27389,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5099,
            "שם_רחוב": "רמדאן סמ1"
        },
        {
            "_id": 27390,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 660,
            "שם_רחוב": "רמה"
        },
        {
            "_id": 27391,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1131,
            "שם_רחוב": "רמון יוסף צבי"
        },
        {
            "_id": 27392,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6792,
            "שם_רחוב": "רמות אלון"
        },
        {
            "_id": 27393,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6795,
            "שם_רחוב": "רמות ב"
        },
        {
            "_id": 27394,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6796,
            "שם_רחוב": "רמות ג"
        },
        {
            "_id": 27395,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6794,
            "שם_רחוב": "רמות ד"
        },
        {
            "_id": 27396,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6793,
            "שם_רחוב": "רמות פולין"
        },
        {
            "_id": 27397,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6770,
            "שם_רחוב": "רמות רחל"
        },
        {
            "_id": 27398,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1868,
            "שם_רחוב": "רמז דוד"
        },
        {
            "_id": 27399,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6799,
            "שם_רחוב": "רמת איילות"
        },
        {
            "_id": 27400,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6652,
            "שם_רחוב": "רמת בית הכרם"
        },
        {
            "_id": 27401,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4280,
            "שם_רחוב": "רמת הגולן"
        },
        {
            "_id": 27402,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 974,
            "שם_רחוב": "רמת מוצא"
        },
        {
            "_id": 27403,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6800,
            "שם_רחוב": "רמת שלמה"
        },
        {
            "_id": 27404,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6656,
            "שם_רחוב": "רמת שרת"
        },
        {
            "_id": 27405,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1873,
            "שם_רחוב": "רפאל יצחק"
        },
        {
            "_id": 27406,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 677,
            "שם_רחוב": "רפאל עזרא"
        },
        {
            "_id": 27407,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 705,
            "שם_רחוב": "רפאלי"
        },
        {
            "_id": 27408,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 160,
            "שם_רחוב": "רפפורט"
        },
        {
            "_id": 27409,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 133,
            "שם_רחוב": "רצאבי"
        },
        {
            "_id": 27410,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3041,
            "שם_רחוב": "רקם"
        },
        {
            "_id": 27411,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5011,
            "שם_רחוב": "רקנטי אברהם"
        },
        {
            "_id": 27412,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 328,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 27413,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 802,
            "שם_רחוב": "רשבא"
        },
        {
            "_id": 27414,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1315,
            "שם_רחוב": "רשבג"
        },
        {
            "_id": 27415,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 334,
            "שם_רחוב": "רשבם"
        },
        {
            "_id": 27416,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6609,
            "שם_רחוב": "ש אוריאל"
        },
        {
            "_id": 27417,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6690,
            "שם_רחוב": "ש אזב ק יובל"
        },
        {
            "_id": 27418,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6780,
            "שם_רחוב": "ש אזב תלפיות"
        },
        {
            "_id": 27419,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6537,
            "שם_רחוב": "ש אחווה"
        },
        {
            "_id": 27420,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6691,
            "שם_רחוב": "ש אלחנני"
        },
        {
            "_id": 27421,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6651,
            "שם_רחוב": "ש אלף גב רם"
        },
        {
            "_id": 27422,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6616,
            "שם_רחוב": "ש ברוצקוס"
        },
        {
            "_id": 27423,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6671,
            "שם_רחוב": "ש בתי אבן"
        },
        {
            "_id": 27424,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6769,
            "שם_רחוב": "ש דיסקין"
        },
        {
            "_id": 27425,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6617,
            "שם_רחוב": "ש דיסקין רוממה"
        },
        {
            "_id": 27426,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6752,
            "שם_רחוב": "ש הגנה טלביה"
        },
        {
            "_id": 27427,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6642,
            "שם_רחוב": "ש הריאל"
        },
        {
            "_id": 27428,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6643,
            "שם_רחוב": "ש ותיק ק משה"
        },
        {
            "_id": 27429,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6692,
            "שם_רחוב": "ש ותיקים ק יובל"
        },
        {
            "_id": 27430,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6693,
            "שם_רחוב": "ש זוג ק יובל"
        },
        {
            "_id": 27431,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6781,
            "שם_רחוב": "ש זוג תלפיות"
        },
        {
            "_id": 27432,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6644,
            "שם_רחוב": "ש ח משוחררים"
        },
        {
            "_id": 27433,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6590,
            "שם_רחוב": "ש חבד"
        },
        {
            "_id": 27434,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6593,
            "שם_רחוב": "ש חסדוף"
        },
        {
            "_id": 27435,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6658,
            "שם_רחוב": "ש חסכון בית וגן"
        },
        {
            "_id": 27436,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6735,
            "שם_רחוב": "ש חסכון פלמח"
        },
        {
            "_id": 27437,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "ש יוספטל"
        },
        {
            "_id": 27438,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6696,
            "שם_רחוב": "ש מומחים ק יובל"
        },
        {
            "_id": 27439,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6880,
            "שם_רחוב": "ש מורים בית הכרם"
        },
        {
            "_id": 27440,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "ש מורשה"
        },
        {
            "_id": 27441,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6606,
            "שם_רחוב": "ש מי נפתוח"
        },
        {
            "_id": 27442,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6881,
            "שם_רחוב": "ש מימון"
        },
        {
            "_id": 27443,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6697,
            "שם_רחוב": "ש מפונים ק יובל"
        },
        {
            "_id": 27444,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "ש מפונים ש הנביא"
        },
        {
            "_id": 27445,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6736,
            "שם_רחוב": "ש משרד החוץ"
        },
        {
            "_id": 27446,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6626,
            "שם_רחוב": "ש נוה הבירה"
        },
        {
            "_id": 27447,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6882,
            "שם_רחוב": "ש נוה הקריה"
        },
        {
            "_id": 27448,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6806,
            "שם_רחוב": "ש נוה יעקב"
        },
        {
            "_id": 27449,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6660,
            "שם_רחוב": "ש נוה עוזר"
        },
        {
            "_id": 27450,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6648,
            "שם_רחוב": "ש נוה שאנן"
        },
        {
            "_id": 27451,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6653,
            "שם_רחוב": "ש סטודנט גבעת רם"
        },
        {
            "_id": 27452,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6771,
            "שם_רחוב": "ש סטודנטים אלנבי"
        },
        {
            "_id": 27453,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6672,
            "שם_רחוב": "ש סטודנטים ק יובל"
        },
        {
            "_id": 27454,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6772,
            "שם_רחוב": "ש סטון גונן"
        },
        {
            "_id": 27455,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6673,
            "שם_רחוב": "ש סלע ק יובל"
        },
        {
            "_id": 27456,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6724,
            "שם_רחוב": "ש סן סימון"
        },
        {
            "_id": 27457,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6674,
            "שם_רחוב": "ש עובד ק יבל"
        },
        {
            "_id": 27458,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6884,
            "שם_רחוב": "ש עובדי המכס"
        },
        {
            "_id": 27459,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6737,
            "שם_רחוב": "ש עובדי מדינה"
        },
        {
            "_id": 27460,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6619,
            "שם_רחוב": "ש עובדים רוממה"
        },
        {
            "_id": 27461,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6783,
            "שם_רחוב": "ש עובדים תלפיות"
        },
        {
            "_id": 27462,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6890,
            "שם_רחוב": "ש עולים רוממה"
        },
        {
            "_id": 27463,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6891,
            "שם_רחוב": "ש עמידר רוממה"
        },
        {
            "_id": 27464,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6675,
            "שם_רחוב": "ש עממי אורגו"
        },
        {
            "_id": 27465,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6885,
            "שם_רחוב": "ש עממי ב כרם"
        },
        {
            "_id": 27466,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6676,
            "שם_רחוב": "ש עממי גואטמלה"
        },
        {
            "_id": 27467,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6785,
            "שם_רחוב": "ש עממי חברון"
        },
        {
            "_id": 27468,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6677,
            "שם_רחוב": "ש עממי קרית יובל"
        },
        {
            "_id": 27469,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6786,
            "שם_רחוב": "ש עממי תלפיות"
        },
        {
            "_id": 27470,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6659,
            "שם_רחוב": "ש פאגי בית וגן"
        },
        {
            "_id": 27471,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "ש פאגי סנהדריה"
        },
        {
            "_id": 27472,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6625,
            "שם_רחוב": "ש פגי ג שאול"
        },
        {
            "_id": 27473,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6574,
            "שם_רחוב": "ש פומז בצלאל"
        },
        {
            "_id": 27474,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6725,
            "שם_רחוב": "ש פת"
        },
        {
            "_id": 27475,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6763,
            "שם_רחוב": "ש צבא קבע אלנבי"
        },
        {
            "_id": 27476,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6822,
            "שם_רחוב": "ש צמרת הבירה"
        },
        {
            "_id": 27477,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "ש קואפרטיבים"
        },
        {
            "_id": 27478,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6850,
            "שם_רחוב": "ש קוטג גילה"
        },
        {
            "_id": 27479,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6751,
            "שם_רחוב": "ש קוממיות"
        },
        {
            "_id": 27480,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6607,
            "שם_רחוב": "ש קרית צאנז"
        },
        {
            "_id": 27481,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6608,
            "שם_רחוב": "ש רבנים"
        },
        {
            "_id": 27482,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש רמת אשכול"
        },
        {
            "_id": 27483,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6657,
            "שם_רחוב": "ש רמת דניה"
        },
        {
            "_id": 27484,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6620,
            "שם_רחוב": "ש רמת מוצא"
        },
        {
            "_id": 27485,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "ש רסקו"
        },
        {
            "_id": 27486,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6700,
            "שם_רחוב": "ש רסקו קרית מנחם"
        },
        {
            "_id": 27487,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "ש שמואל הנביא"
        },
        {
            "_id": 27488,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6637,
            "שם_רחוב": "ש שערי אפרים"
        },
        {
            "_id": 27489,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3012,
            "שם_רחוב": "שאדיקר נחום"
        },
        {
            "_id": 27490,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 27491,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 243,
            "שם_רחוב": "שאול ליברמן"
        },
        {
            "_id": 27492,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4637,
            "שם_רחוב": "שאקר באדר"
        },
        {
            "_id": 27493,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4588,
            "שם_רחוב": "שאר ישוב"
        },
        {
            "_id": 27494,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4568,
            "שם_רחוב": "שבו"
        },
        {
            "_id": 27495,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4517,
            "שם_רחוב": "שבות"
        },
        {
            "_id": 27496,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 709,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 27497,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 501,
            "שם_רחוב": "שבטי ישראל"
        },
        {
            "_id": 27498,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1813,
            "שם_רחוב": "שביבי אש"
        },
        {
            "_id": 27499,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1862,
            "שם_רחוב": "שביל האריה"
        },
        {
            "_id": 27500,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1490,
            "שם_רחוב": "שביל החומה"
        },
        {
            "_id": 27501,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5574,
            "שם_רחוב": "שביל החסידות"
        },
        {
            "_id": 27502,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1861,
            "שם_רחוב": "שביל הטובה"
        },
        {
            "_id": 27503,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1539,
            "שם_רחוב": "שביל היוגב"
        },
        {
            "_id": 27504,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1491,
            "שם_רחוב": "שביל הצופית"
        },
        {
            "_id": 27505,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1489,
            "שם_רחוב": "שביל הצוקים"
        },
        {
            "_id": 27506,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1492,
            "שם_רחוב": "שביל השיבולים"
        },
        {
            "_id": 27507,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 961,
            "שם_רחוב": "שבע קהלות"
        },
        {
            "_id": 27508,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 632,
            "שם_רחוב": "שבת צדק"
        },
        {
            "_id": 27509,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4741,
            "שם_רחוב": "שג'רת אל דור"
        },
        {
            "_id": 27510,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 941,
            "שם_רחוב": "שד בן גוריון"
        },
        {
            "_id": 27511,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 807,
            "שם_רחוב": "שד בן מימון"
        },
        {
            "_id": 27512,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 733,
            "שם_רחוב": "שד בן צבי יצחק"
        },
        {
            "_id": 27513,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2052,
            "שם_רחוב": "שד גבעת משה"
        },
        {
            "_id": 27514,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4452,
            "שם_רחוב": "שד גולדה מאיר"
        },
        {
            "_id": 27515,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1435,
            "שם_רחוב": "שד דב יוסף"
        },
        {
            "_id": 27516,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4753,
            "שם_רחוב": "שד דיין משה"
        },
        {
            "_id": 27517,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1183,
            "שם_רחוב": "שד האומה"
        },
        {
            "_id": 27518,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4393,
            "שם_רחוב": "שד האוניברסיטה"
        },
        {
            "_id": 27519,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 998,
            "שם_רחוב": "שד הזז חיים"
        },
        {
            "_id": 27520,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1020,
            "שם_רחוב": "שד המאירי"
        },
        {
            "_id": 27521,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1021,
            "שם_רחוב": "שד הרצל"
        },
        {
            "_id": 27522,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1077,
            "שם_רחוב": "שד ויצמן"
        },
        {
            "_id": 27523,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4276,
            "שם_רחוב": "שד לוי אשכול"
        },
        {
            "_id": 27524,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1886,
            "שם_רחוב": "שד מאיר שמואל"
        },
        {
            "_id": 27525,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1145,
            "שם_רחוב": "שד מגנס"
        },
        {
            "_id": 27526,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3001,
            "שם_רחוב": "שד נוה יעקב"
        },
        {
            "_id": 27527,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4752,
            "שם_רחוב": "שד סיירת דוכיפת"
        },
        {
            "_id": 27528,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 659,
            "שם_רחוב": "שד עין צורים"
        },
        {
            "_id": 27529,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4378,
            "שם_רחוב": "שד פניגר יצחק"
        },
        {
            "_id": 27530,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4340,
            "שם_רחוב": "שד צ'רצ'יל"
        },
        {
            "_id": 27531,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1880,
            "שם_רחוב": "שד רותי ברעם"
        },
        {
            "_id": 27532,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1389,
            "שם_רחוב": "שד שי עגנון"
        },
        {
            "_id": 27533,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 970,
            "שם_רחוב": "שד שזר"
        },
        {
            "_id": 27534,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4392,
            "שם_רחוב": "שד שיירת הר הצופים"
        },
        {
            "_id": 27535,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1368,
            "שם_רחוב": "שד שרת משה"
        },
        {
            "_id": 27536,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 604,
            "שם_רחוב": "שדל"
        },
        {
            "_id": 27537,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4915,
            "שם_רחוב": "שדאד בן אוס"
        },
        {
            "_id": 27538,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5132,
            "שם_רחוב": "שדאד בן אוס סמ1"
        },
        {
            "_id": 27539,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 361,
            "שם_רחוב": "שדה חמד"
        },
        {
            "_id": 27540,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1305,
            "שם_רחוב": "שדה יצחק"
        },
        {
            "_id": 27541,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1755,
            "שם_רחוב": "שדמי נחום"
        },
        {
            "_id": 27542,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 926,
            "שם_רחוב": "שדרות אריאל טמס"
        },
        {
            "_id": 27543,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1852,
            "שם_רחוב": "שדרת בלומפילד"
        },
        {
            "_id": 27544,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1134,
            "שם_רחוב": "שדרת המוזיאונים"
        },
        {
            "_id": 27545,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4481,
            "שם_רחוב": "שדרת רמות"
        },
        {
            "_id": 27546,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5101,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 27547,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4821,
            "שם_רחוב": "שוורצברד שלום"
        },
        {
            "_id": 27548,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4840,
            "שם_רחוב": "שוחט מניה"
        },
        {
            "_id": 27549,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1019,
            "שם_רחוב": "שוכמן ישראל"
        },
        {
            "_id": 27550,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 355,
            "שם_רחוב": "שולאל יצחק"
        },
        {
            "_id": 27551,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 682,
            "שם_רחוב": "שומרון"
        },
        {
            "_id": 27552,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 209,
            "שם_רחוב": "שומרי אמונים"
        },
        {
            "_id": 27553,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3554,
            "שם_רחוב": "שומרי ההר"
        },
        {
            "_id": 27554,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4513,
            "שם_רחוב": "שוני הלכות"
        },
        {
            "_id": 27555,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4101,
            "שם_רחוב": "שועפאט"
        },
        {
            "_id": 27556,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1626,
            "שם_רחוב": "שופן"
        },
        {
            "_id": 27557,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4081,
            "שם_רחוב": "שוק הצבעים"
        },
        {
            "_id": 27558,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4038,
            "שם_רחוב": "שוק הצורפים"
        },
        {
            "_id": 27559,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4194,
            "שם_רחוב": "שוקן זלמן"
        },
        {
            "_id": 27560,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4460,
            "שם_רחוב": "שור משה"
        },
        {
            "_id": 27561,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 608,
            "שם_רחוב": "שורץ"
        },
        {
            "_id": 27562,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 410,
            "שם_רחוב": "שושן"
        },
        {
            "_id": 27563,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1009,
            "שם_רחוב": "שושנה"
        },
        {
            "_id": 27564,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4309,
            "שם_רחוב": "שושנים"
        },
        {
            "_id": 27565,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1232,
            "שם_רחוב": "שותא רוסתאבלי"
        },
        {
            "_id": 27566,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1716,
            "שם_רחוב": "שזכ"
        },
        {
            "_id": 27567,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1402,
            "שם_רחוב": "שחל"
        },
        {
            "_id": 27568,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 453,
            "שם_רחוב": "שחם מאיר"
        },
        {
            "_id": 27569,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1039,
            "שם_רחוב": "שחר"
        },
        {
            "_id": 27570,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1096,
            "שם_רחוב": "שחראי"
        },
        {
            "_id": 27571,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1136,
            "שם_רחוב": "שטיינברג יחיאל"
        },
        {
            "_id": 27572,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 894,
            "שם_רחוב": "שטנר"
        },
        {
            "_id": 27573,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 350,
            "שם_רחוב": "שטראוס"
        },
        {
            "_id": 27574,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5475,
            "שם_רחוב": "שטרוק"
        },
        {
            "_id": 27575,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1297,
            "שם_רחוב": "שטרן אברהם"
        },
        {
            "_id": 27576,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1820,
            "שם_רחוב": "שטרן יורי"
        },
        {
            "_id": 27577,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4462,
            "שם_רחוב": "שי"
        },
        {
            "_id": 27578,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4431,
            "שם_רחוב": "שיבת ציון"
        },
        {
            "_id": 27579,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4246,
            "שם_רחוב": "שיח אחמד א ס"
        },
        {
            "_id": 27580,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4197,
            "שם_רחוב": "שיח מוחמד א-צאלח"
        },
        {
            "_id": 27581,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4238,
            "שם_רחוב": "שיח סואן"
        },
        {
            "_id": 27582,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4161,
            "שם_רחוב": "שיח סעדוסעיד"
        },
        {
            "_id": 27583,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4603,
            "שם_רחוב": "שייח אל כאלדי"
        },
        {
            "_id": 27584,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5649,
            "שם_רחוב": "שייח' ג'אבר"
        },
        {
            "_id": 27585,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4118,
            "שם_רחוב": "שייח' ג'ראח"
        },
        {
            "_id": 27586,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4295,
            "שם_רחוב": "שייח' מחמוד"
        },
        {
            "_id": 27587,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 283,
            "שם_רחוב": "שיירת עטרות"
        },
        {
            "_id": 27588,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 657,
            "שם_רחוב": "שילה"
        },
        {
            "_id": 27589,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1026,
            "שם_רחוב": "שילר"
        },
        {
            "_id": 27590,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4614,
            "שם_רחוב": "שיק קונרד"
        },
        {
            "_id": 27591,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3256,
            "שם_רחוב": "שיר למעלות"
        },
        {
            "_id": 27592,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1498,
            "שם_רחוב": "שיר לשלמה"
        },
        {
            "_id": 27593,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 741,
            "שם_רחוב": "שיריזלי"
        },
        {
            "_id": 27594,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1944,
            "שם_רחוב": "שירת אהרן"
        },
        {
            "_id": 27595,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4435,
            "שם_רחוב": "שירת הים"
        },
        {
            "_id": 27596,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 271,
            "שם_רחוב": "שירת חנה"
        },
        {
            "_id": 27597,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6764,
            "שם_רחוב": "שכ א טור 19"
        },
        {
            "_id": 27598,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6765,
            "שם_רחוב": "שכ אבו תור גוש 18"
        },
        {
            "_id": 27599,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6585,
            "שם_רחוב": "שכ אוהל משה"
        },
        {
            "_id": 27600,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6564,
            "שם_רחוב": "שכ אוהל שלמה"
        },
        {
            "_id": 27601,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ ארזי הבירה"
        },
        {
            "_id": 27602,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6777,
            "שם_רחוב": "שכ ארנונה"
        },
        {
            "_id": 27603,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6902,
            "שם_רחוב": "שכ בטאנה"
        },
        {
            "_id": 27604,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "שכ בית הכרם"
        },
        {
            "_id": 27605,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6666,
            "שם_רחוב": "שכ בית וגן"
        },
        {
            "_id": 27606,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכ בית ישראל"
        },
        {
            "_id": 27607,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1798,
            "שם_רחוב": "שכ בית צפפה"
        },
        {
            "_id": 27608,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6773,
            "שם_רחוב": "שכ בקעה"
        },
        {
            "_id": 27609,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6586,
            "שם_רחוב": "שכ בתי ברוידא"
        },
        {
            "_id": 27610,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ גאולה"
        },
        {
            "_id": 27611,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6766,
            "שם_רחוב": "שכ גאולים"
        },
        {
            "_id": 27612,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6733,
            "שם_רחוב": "שכ גב מרדכי"
        },
        {
            "_id": 27613,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6621,
            "שם_רחוב": "שכ גב שאול א"
        },
        {
            "_id": 27614,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6622,
            "שם_רחוב": "שכ גב שאול ב"
        },
        {
            "_id": 27615,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ גבעת הורדים"
        },
        {
            "_id": 27616,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6649,
            "שם_רחוב": "שכ גבעת רם"
        },
        {
            "_id": 27617,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6624,
            "שם_רחוב": "שכ גבעת שאול"
        },
        {
            "_id": 27618,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "שכ הבוכרים"
        },
        {
            "_id": 27619,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "שכ הגיחון"
        },
        {
            "_id": 27620,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6534,
            "שם_רחוב": "שכ המושבה הגרמנית"
        },
        {
            "_id": 27621,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6741,
            "שם_רחוב": "שכ המושבה היונית"
        },
        {
            "_id": 27622,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6638,
            "שם_רחוב": "שכ המקשר א"
        },
        {
            "_id": 27623,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6650,
            "שם_רחוב": "שכ המקשר ב"
        },
        {
            "_id": 27624,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6639,
            "שם_רחוב": "שכ המקשר ג"
        },
        {
            "_id": 27625,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "שכ הפועלים"
        },
        {
            "_id": 27626,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6640,
            "שם_רחוב": "שכ הר נוף"
        },
        {
            "_id": 27627,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6551,
            "שם_רחוב": "שכ וולג'ה"
        },
        {
            "_id": 27628,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6583,
            "שם_רחוב": "שכ זכרון טוביה"
        },
        {
            "_id": 27629,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6572,
            "שם_רחוב": "שכ זכרון יוסף"
        },
        {
            "_id": 27630,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6591,
            "שם_רחוב": "שכ זכרון משה"
        },
        {
            "_id": 27631,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6552,
            "שם_רחוב": "שכ ח'רבת עטארה"
        },
        {
            "_id": 27632,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6903,
            "שם_רחוב": "שכ חבאיל חמדן"
        },
        {
            "_id": 27633,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6816,
            "שם_רחוב": "שכ יגיע כפיים"
        },
        {
            "_id": 27634,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6767,
            "שם_רחוב": "שכ ימין משה"
        },
        {
            "_id": 27635,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6636,
            "שם_רחוב": "שכ יפה נוף"
        },
        {
            "_id": 27636,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6573,
            "שם_רחוב": "שכ כנסת א"
        },
        {
            "_id": 27637,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6575,
            "שם_רחוב": "שכ כנסת ב"
        },
        {
            "_id": 27638,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6576,
            "שם_רחוב": "שכ כנסת ג"
        },
        {
            "_id": 27639,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4111,
            "שם_רחוב": "שכ כפר עקב"
        },
        {
            "_id": 27640,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6818,
            "שם_רחוב": "שכ כפר שאול"
        },
        {
            "_id": 27641,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6592,
            "שם_רחוב": "שכ כרם"
        },
        {
            "_id": 27642,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "שכ כרם אברהם"
        },
        {
            "_id": 27643,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6892,
            "שם_רחוב": "שכ ליפתא תחתית"
        },
        {
            "_id": 27644,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ מאה שערים"
        },
        {
            "_id": 27645,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6584,
            "שם_רחוב": "שכ מזכרת משה"
        },
        {
            "_id": 27646,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6904,
            "שם_רחוב": "שכ מחג'רת עתמה"
        },
        {
            "_id": 27647,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6550,
            "שם_רחוב": "שכ מחנה ישראל"
        },
        {
            "_id": 27648,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ מחניים"
        },
        {
            "_id": 27649,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6701,
            "שם_רחוב": "שכ מנחת"
        },
        {
            "_id": 27650,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6540,
            "שם_רחוב": "שכ מסעודיה"
        },
        {
            "_id": 27651,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6705,
            "שם_רחוב": "שכ מצפה מנחם"
        },
        {
            "_id": 27652,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "שכ מקור ברוך"
        },
        {
            "_id": 27653,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6778,
            "שם_רחוב": "שכ מקור חיים"
        },
        {
            "_id": 27654,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6840,
            "שם_רחוב": "שכ נוף ציון"
        },
        {
            "_id": 27655,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "שכ נחלאות"
        },
        {
            "_id": 27656,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6579,
            "שם_רחוב": "שכ נחלת אחים"
        },
        {
            "_id": 27657,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6569,
            "שם_רחוב": "שכ נחלת ישראל"
        },
        {
            "_id": 27658,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6566,
            "שם_רחוב": "שכ נחלת צדוק"
        },
        {
            "_id": 27659,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6567,
            "שם_רחוב": "שכ נחלת ציון"
        },
        {
            "_id": 27660,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "שכ נחלת שבעה"
        },
        {
            "_id": 27661,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6578,
            "שם_רחוב": "שכ נחלת שמעון"
        },
        {
            "_id": 27662,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "שכ סנהדריה"
        },
        {
            "_id": 27663,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ עזרת תורה"
        },
        {
            "_id": 27664,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6685,
            "שם_רחוב": "שכ עין כרם"
        },
        {
            "_id": 27665,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6687,
            "שם_רחוב": "שכ עין כרם א"
        },
        {
            "_id": 27666,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6688,
            "שם_רחוב": "שכ עין כרם ב"
        },
        {
            "_id": 27667,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6689,
            "שם_רחוב": "שכ עין כרם ד"
        },
        {
            "_id": 27668,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6670,
            "שם_רחוב": "שכ עיר גנים א"
        },
        {
            "_id": 27669,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6702,
            "שם_רחוב": "שכ עיר גנים ב"
        },
        {
            "_id": 27670,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6703,
            "שם_רחוב": "שכ עיר גנים ג"
        },
        {
            "_id": 27671,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6535,
            "שם_רחוב": "שכ עמק רפאים"
        },
        {
            "_id": 27672,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6610,
            "שם_רחוב": "שכ עץ חיים"
        },
        {
            "_id": 27673,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6906,
            "שם_רחוב": "שכ עקבת סוואנה"
        },
        {
            "_id": 27674,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6611,
            "שם_רחוב": "שכ קומונה"
        },
        {
            "_id": 27675,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "שכ קטמון הישנה"
        },
        {
            "_id": 27676,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6641,
            "שם_רחוב": "שכ קרית משה"
        },
        {
            "_id": 27677,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "שכ קרית שמואל"
        },
        {
            "_id": 27678,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6630,
            "שם_רחוב": "שכ רוחמה"
        },
        {
            "_id": 27679,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6612,
            "שם_רחוב": "שכ רוממה"
        },
        {
            "_id": 27680,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6863,
            "שם_רחוב": "שכ רמת הדסה"
        },
        {
            "_id": 27681,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6568,
            "שם_רחוב": "שכ שבת צדק"
        },
        {
            "_id": 27682,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4172,
            "שם_רחוב": "שכ שדה תעופה עטרות"
        },
        {
            "_id": 27683,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6893,
            "שם_רחוב": "שכ שיך באדר"
        },
        {
            "_id": 27684,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6774,
            "שם_רחוב": "שכ שמעה"
        },
        {
            "_id": 27685,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ שערי חסד"
        },
        {
            "_id": 27686,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6577,
            "שם_רחוב": "שכ שערי ים"
        },
        {
            "_id": 27687,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6538,
            "שם_רחוב": "שכ שערי פינה"
        },
        {
            "_id": 27688,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "שכ תל ארזה"
        },
        {
            "_id": 27689,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6779,
            "שם_רחוב": "שכ תלפיות"
        },
        {
            "_id": 27690,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6853,
            "שם_רחוב": "שכון גילה"
        },
        {
            "_id": 27691,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6900,
            "שם_רחוב": "שכוני נוסייבה"
        },
        {
            "_id": 27692,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4725,
            "שם_רחוב": "שכונת השלום"
        },
        {
            "_id": 27693,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3368,
            "שם_רחוב": "שכונת התימנים"
        },
        {
            "_id": 27694,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4449,
            "שם_רחוב": "שכטמן יוסף"
        },
        {
            "_id": 27695,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1034,
            "שם_רחוב": "שלאין"
        },
        {
            "_id": 27696,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 2015,
            "שם_רחוב": "שלו יצחק"
        },
        {
            "_id": 27697,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1872,
            "שם_רחוב": "שלום הגליל"
        },
        {
            "_id": 27698,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4830,
            "שם_רחוב": "שלום וצדק"
        },
        {
            "_id": 27699,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1791,
            "שם_רחוב": "שלום יהודה"
        },
        {
            "_id": 27700,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1509,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 27701,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 450,
            "שם_רחוב": "שלומציון"
        },
        {
            "_id": 27702,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1532,
            "שם_רחוב": "שלוש"
        },
        {
            "_id": 27703,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1075,
            "שם_רחוב": "שלם נתן"
        },
        {
            "_id": 27704,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4810,
            "שם_רחוב": "שלמה"
        },
        {
            "_id": 27705,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1441,
            "שם_רחוב": "שלמה בן פתחיה הלוי"
        },
        {
            "_id": 27706,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 513,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 27707,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 436,
            "שם_רחוב": "שמאי"
        },
        {
            "_id": 27708,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 515,
            "שם_רחוב": "שמאע אליהו"
        },
        {
            "_id": 27709,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 907,
            "שם_רחוב": "שמגר"
        },
        {
            "_id": 27710,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1548,
            "שם_רחוב": "שמואל אבן אור"
        },
        {
            "_id": 27711,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שמואל הנביא"
        },
        {
            "_id": 27712,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 427,
            "שם_רחוב": "שמואל הנגיד"
        },
        {
            "_id": 27713,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1262,
            "שם_רחוב": "שמורק"
        },
        {
            "_id": 27714,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1807,
            "שם_רחוב": "שמחת כהן"
        },
        {
            "_id": 27715,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1119,
            "שם_רחוב": "שמי יצחק"
        },
        {
            "_id": 27716,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4580,
            "שם_רחוב": "שמיר"
        },
        {
            "_id": 27717,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4000,
            "שם_רחוב": "שמיר משה"
        },
        {
            "_id": 27718,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4098,
            "שם_רחוב": "שמס א דין אסיוטי"
        },
        {
            "_id": 27719,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5361,
            "שם_רחוב": "שמס אדין אסיוטי סמ1"
        },
        {
            "_id": 27720,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5362,
            "שם_רחוב": "שמס אדין אסיוטי סמ3"
        },
        {
            "_id": 27721,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1773,
            "שם_רחוב": "שמעון"
        },
        {
            "_id": 27722,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 407,
            "שם_רחוב": "שמעון בן שטח"
        },
        {
            "_id": 27723,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 342,
            "שם_רחוב": "שמעון המכבי"
        },
        {
            "_id": 27724,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 235,
            "שם_רחוב": "שמעון הצדיק"
        },
        {
            "_id": 27725,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 494,
            "שם_רחוב": "שמעון מישורי"
        },
        {
            "_id": 27726,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1407,
            "שם_רחוב": "שמעוני"
        },
        {
            "_id": 27727,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1761,
            "שם_רחוב": "שמעיה"
        },
        {
            "_id": 27728,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 215,
            "שם_רחוב": "שמריה"
        },
        {
            "_id": 27729,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1763,
            "שם_רחוב": "שמשון"
        },
        {
            "_id": 27730,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1146,
            "שם_רחוב": "שמשון רפאל הירש"
        },
        {
            "_id": 27731,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4500,
            "שם_רחוב": "שנברג יוסף"
        },
        {
            "_id": 27732,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4379,
            "שם_רחוב": "שני יוסף"
        },
        {
            "_id": 27733,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1127,
            "שם_רחוב": "שניאור"
        },
        {
            "_id": 27734,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 999,
            "שם_רחוב": "שנירר שרה"
        },
        {
            "_id": 27735,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1677,
            "שם_רחוב": "שסקין יעקב"
        },
        {
            "_id": 27736,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5060,
            "שם_רחוב": "שעאבאן"
        },
        {
            "_id": 27737,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4863,
            "שם_רחוב": "שעאבאן סמ3"
        },
        {
            "_id": 27738,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5061,
            "שם_רחוב": "שעאבאן סמ4"
        },
        {
            "_id": 27739,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5582,
            "שם_רחוב": "שער האריות"
        },
        {
            "_id": 27740,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4029,
            "שם_רחוב": "שער הע'ואנמה"
        },
        {
            "_id": 27741,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3201,
            "שם_רחוב": "שער ציון"
        },
        {
            "_id": 27742,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4159,
            "שם_רחוב": "שער שכם"
        },
        {
            "_id": 27743,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 722,
            "שם_רחוב": "שערי חסד"
        },
        {
            "_id": 27744,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 899,
            "שם_רחוב": "שערי ירושלים"
        },
        {
            "_id": 27745,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 185,
            "שם_רחוב": "שערי משה"
        },
        {
            "_id": 27746,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 151,
            "שם_רחוב": "שערי פנה"
        },
        {
            "_id": 27747,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 603,
            "שם_רחוב": "שערי צדק"
        },
        {
            "_id": 27748,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 161,
            "שם_רחוב": "שערי שמיים"
        },
        {
            "_id": 27749,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1901,
            "שם_רחוב": "שערי תורה"
        },
        {
            "_id": 27750,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 252,
            "שם_רחוב": "שפיצר"
        },
        {
            "_id": 27751,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1212,
            "שם_רחוב": "שפירא צבי"
        },
        {
            "_id": 27752,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 398,
            "שם_רחוב": "שפע חיים"
        },
        {
            "_id": 27753,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 936,
            "שם_רחוב": "שפרה"
        },
        {
            "_id": 27754,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 706,
            "שם_רחוב": "שפרעם"
        },
        {
            "_id": 27755,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 607,
            "שם_רחוב": "שפת אמת"
        },
        {
            "_id": 27756,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 446,
            "שם_רחוב": "שץ"
        },
        {
            "_id": 27757,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5679,
            "שם_רחוב": "שקאאיק אל-נעמאן"
        },
        {
            "_id": 27758,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5680,
            "שם_רחוב": "שקאאיק אל-נעמאן סמ1"
        },
        {
            "_id": 27759,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5681,
            "שם_רחוב": "שקאאיק אל-נעמאן סמ3"
        },
        {
            "_id": 27760,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5682,
            "שם_רחוב": "שקאאיק אל-נעמאן סמ5"
        },
        {
            "_id": 27761,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1399,
            "שם_רחוב": "שקי אינו"
        },
        {
            "_id": 27762,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5964,
            "שם_רחוב": "שקיראת"
        },
        {
            "_id": 27763,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4972,
            "שם_רחוב": "שרבני יהושע"
        },
        {
            "_id": 27764,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4287,
            "שם_רחוב": "שרגאי זלמן"
        },
        {
            "_id": 27765,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1546,
            "שם_רחוב": "שרה הקטנה (לבני)"
        },
        {
            "_id": 27766,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 230,
            "שם_רחוב": "שרה לוי תנאי"
        },
        {
            "_id": 27767,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 483,
            "שם_רחוב": "שרה עזריהו"
        },
        {
            "_id": 27768,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 298,
            "שם_רחוב": "שרה קנוט"
        },
        {
            "_id": 27769,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1263,
            "שם_רחוב": "שרון"
        },
        {
            "_id": 27770,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 340,
            "שם_רחוב": "שרי ישראל"
        },
        {
            "_id": 27771,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5477,
            "שם_רחוב": "שרי ראובן"
        },
        {
            "_id": 27772,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 816,
            "שם_רחוב": "שריון יצחק"
        },
        {
            "_id": 27773,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1184,
            "שם_רחוב": "שרייבום יעקב"
        },
        {
            "_id": 27774,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 364,
            "שם_רחוב": "שרעבי שלום"
        },
        {
            "_id": 27775,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4807,
            "שם_רחוב": "שרף זאב"
        },
        {
            "_id": 27776,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4202,
            "שם_רחוב": "שרפאת"
        },
        {
            "_id": 27777,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5663,
            "שם_רחוב": "שרפאת סמ3"
        },
        {
            "_id": 27778,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5664,
            "שם_רחוב": "שרפאת סמ6"
        },
        {
            "_id": 27779,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5665,
            "שם_רחוב": "שרפאת סמ8"
        },
        {
            "_id": 27780,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4205,
            "שם_רחוב": "שרקיה אל בלד"
        },
        {
            "_id": 27781,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4283,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 27782,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4827,
            "שם_רחוב": "תבין אלי"
        },
        {
            "_id": 27783,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4823,
            "שם_רחוב": "תג'ר שלמה ומשה"
        },
        {
            "_id": 27784,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4350,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 27785,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 908,
            "שם_רחוב": "תובל"
        },
        {
            "_id": 27786,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1201,
            "שם_רחוב": "תוסיה כהן"
        },
        {
            "_id": 27787,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 196,
            "שם_רחוב": "תוספות יום טוב"
        },
        {
            "_id": 27788,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4656,
            "שם_רחוב": "תוצרת"
        },
        {
            "_id": 27789,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1083,
            "שם_רחוב": "תורה ועבודה"
        },
        {
            "_id": 27790,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 921,
            "שם_רחוב": "תורה מציון"
        },
        {
            "_id": 27791,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4809,
            "שם_רחוב": "תורן חיים"
        },
        {
            "_id": 27792,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1531,
            "שם_רחוב": "תורת חיים"
        },
        {
            "_id": 27793,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 994,
            "שם_רחוב": "תורת חסד"
        },
        {
            "_id": 27794,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 321,
            "שם_רחוב": "תחכמוני"
        },
        {
            "_id": 27795,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1005,
            "שם_רחוב": "תחנה מרכזית"
        },
        {
            "_id": 27796,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 206,
            "שם_רחוב": "תימא"
        },
        {
            "_id": 27797,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4558,
            "שם_רחוב": "תירוש"
        },
        {
            "_id": 27798,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 945,
            "שם_רחוב": "תכלת מרדכי"
        },
        {
            "_id": 27799,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6558,
            "שם_רחוב": "תל אל פול"
        },
        {
            "_id": 27800,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1622,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 27801,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1204,
            "שם_רחוב": "תלמה ילין"
        },
        {
            "_id": 27802,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 128,
            "שם_רחוב": "תלמודי אברהם"
        },
        {
            "_id": 27803,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 6836,
            "שם_רחוב": "תלפיות מזרח"
        },
        {
            "_id": 27804,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3344,
            "שם_רחוב": "תמים אל-דארי"
        },
        {
            "_id": 27805,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3345,
            "שם_רחוב": "תמים אל-דארי סמ3"
        },
        {
            "_id": 27806,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3346,
            "שם_רחוב": "תמים אל-דארי סמ5"
        },
        {
            "_id": 27807,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3347,
            "שם_רחוב": "תמים אל-דארי סמ7"
        },
        {
            "_id": 27808,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 3348,
            "שם_רחוב": "תמים אל-דארי סמ9"
        },
        {
            "_id": 27809,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4785,
            "שם_רחוב": "תמיר שמואל"
        },
        {
            "_id": 27810,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4264,
            "שם_רחוב": "תפארת ירושלים"
        },
        {
            "_id": 27811,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4901,
            "שם_רחוב": "תפארת ישראל"
        },
        {
            "_id": 27812,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 668,
            "שם_רחוב": "תקוע"
        },
        {
            "_id": 27813,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5761,
            "שם_רחוב": "תקי אל-דין"
        },
        {
            "_id": 27814,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 5762,
            "שם_רחוב": "תקי אל-דין סמ1"
        },
        {
            "_id": 27815,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 140,
            "שם_רחוב": "תרמב"
        },
        {
            "_id": 27816,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 1053,
            "שם_רחוב": "תרצה"
        },
        {
            "_id": 27817,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 4555,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 27818,
            "סמל_ישוב": 3000,
            "שם_ישוב": "ירושלים ",
            "סמל_רחוב": 732,
            "שם_רחוב": "תשבץ"
        },
        {
            "_id": 27819,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 27820,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 27821,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הדרור"
        },
        {
            "_id": 27822,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 106,
            "שם_רחוב": "השחף"
        },
        {
            "_id": 27823,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 27824,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 109,
            "שם_רחוב": "חסידה"
        },
        {
            "_id": 27825,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ירחיב"
        },
        {
            "_id": 27826,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 27827,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "סמ חופית"
        },
        {
            "_id": 27828,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 114,
            "שם_רחוב": "סמ צופית"
        },
        {
            "_id": 27829,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 27830,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 107,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 27831,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 108,
            "שם_רחוב": "פשוש"
        },
        {
            "_id": 27832,
            "סמל_ישוב": 718,
            "שם_ישוב": "ירחיב ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שקנאי"
        },
        {
            "_id": 27833,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "אזור התעשייה"
        },
        {
            "_id": 27834,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 441,
            "שם_רחוב": "אל-תיג'ארה"
        },
        {
            "_id": 27835,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 443,
            "שם_רחוב": "אלאח'וה"
        },
        {
            "_id": 27836,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 27837,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אלאסיל"
        },
        {
            "_id": 27838,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 164,
            "שם_רחוב": "אלאשלול"
        },
        {
            "_id": 27839,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 226,
            "שם_רחוב": "אלבאבור"
        },
        {
            "_id": 27840,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 332,
            "שם_רחוב": "אלבאט"
        },
        {
            "_id": 27841,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 194,
            "שם_רחוב": "אלבוסתאן"
        },
        {
            "_id": 27842,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלביאדה"
        },
        {
            "_id": 27843,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 339,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 27844,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלביר אלגארבי"
        },
        {
            "_id": 27845,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלברקה"
        },
        {
            "_id": 27846,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלברקוק"
        },
        {
            "_id": 27847,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אלג'וז"
        },
        {
            "_id": 27848,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 423,
            "שם_רחוב": "אלג'ורי"
        },
        {
            "_id": 27849,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 442,
            "שם_רחוב": "אלג'ליל"
        },
        {
            "_id": 27850,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אלדבה"
        },
        {
            "_id": 27851,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 452,
            "שם_רחוב": "אלדידבה"
        },
        {
            "_id": 27852,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלהדאף"
        },
        {
            "_id": 27853,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אלהלאל"
        },
        {
            "_id": 27854,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלהרם"
        },
        {
            "_id": 27855,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 229,
            "שם_רחוב": "אלודיאן"
        },
        {
            "_id": 27856,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלורוד"
        },
        {
            "_id": 27857,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 438,
            "שם_רחוב": "אלזוהור"
        },
        {
            "_id": 27858,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 27859,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 224,
            "שם_רחוב": "אלזקאק"
        },
        {
            "_id": 27860,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אלח'לוה"
        },
        {
            "_id": 27861,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אלח'רבה"
        },
        {
            "_id": 27862,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 421,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 27863,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלחארה"
        },
        {
            "_id": 27864,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלחדב"
        },
        {
            "_id": 27865,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אלחדיקה"
        },
        {
            "_id": 27866,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלחדית'ה"
        },
        {
            "_id": 27867,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אלחמאם"
        },
        {
            "_id": 27868,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 411,
            "שם_רחוב": "אלחרארי"
        },
        {
            "_id": 27869,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 428,
            "שם_רחוב": "אלחריקה"
        },
        {
            "_id": 27870,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "אלחשנה"
        },
        {
            "_id": 27871,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 27872,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 445,
            "שם_רחוב": "אלכרום"
        },
        {
            "_id": 27873,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "אלכרם"
        },
        {
            "_id": 27874,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלמבסלה"
        },
        {
            "_id": 27875,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלמדאורה"
        },
        {
            "_id": 27876,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 225,
            "שם_רחוב": "אלמובארכה"
        },
        {
            "_id": 27877,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלמזאריב"
        },
        {
            "_id": 27878,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 383,
            "שם_רחוב": "אלמזארע"
        },
        {
            "_id": 27879,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 341,
            "שם_רחוב": "אלמעון"
        },
        {
            "_id": 27880,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 456,
            "שם_רחוב": "אלנאפורה"
        },
        {
            "_id": 27881,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 439,
            "שם_רחוב": "אלנהדה"
        },
        {
            "_id": 27882,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 27883,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלנסבי"
        },
        {
            "_id": 27884,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אלנסיג'"
        },
        {
            "_id": 27885,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 27886,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלסדאקה"
        },
        {
            "_id": 27887,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 449,
            "שם_רחוב": "אלסהלאת"
        },
        {
            "_id": 27888,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אלסואדייה"
        },
        {
            "_id": 27889,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 408,
            "שם_רחוב": "אלסינאעה"
        },
        {
            "_id": 27890,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 455,
            "שם_רחוב": "אלסראיס"
        },
        {
            "_id": 27891,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 429,
            "שם_רחוב": "אלסרו"
        },
        {
            "_id": 27892,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "אלסריסה"
        },
        {
            "_id": 27893,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 376,
            "שם_רחוב": "אלעאמר"
        },
        {
            "_id": 27894,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 448,
            "שם_רחוב": "אלעלום"
        },
        {
            "_id": 27895,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אלעמארייה"
        },
        {
            "_id": 27896,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אלענקור"
        },
        {
            "_id": 27897,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 330,
            "שם_רחוב": "אלעקדה"
        },
        {
            "_id": 27898,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלפיג'ן"
        },
        {
            "_id": 27899,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 410,
            "שם_רחוב": "אלקריה אלריאדיה"
        },
        {
            "_id": 27900,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלרביע"
        },
        {
            "_id": 27901,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 358,
            "שם_רחוב": "אלרהואת"
        },
        {
            "_id": 27902,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 412,
            "שם_רחוב": "אלרודאת"
        },
        {
            "_id": 27903,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 356,
            "שם_רחוב": "אלרוייס"
        },
        {
            "_id": 27904,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלשארע אשמאלי"
        },
        {
            "_id": 27905,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 6733,
            "שם_רחוב": "אלשכונאת"
        },
        {
            "_id": 27906,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 440,
            "שם_רחוב": "אלתאח'ים"
        },
        {
            "_id": 27907,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 396,
            "שם_רחוב": "ג'ורן אלסרסור"
        },
        {
            "_id": 27908,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 6735,
            "שם_רחוב": "ג'ורן אלצרצור"
        },
        {
            "_id": 27909,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 353,
            "שם_רחוב": "דרב אבו סנאן"
        },
        {
            "_id": 27910,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 203,
            "שם_רחוב": "דרב סאפאד"
        },
        {
            "_id": 27911,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 204,
            "שם_רחוב": "ואדי אום עאמר"
        },
        {
            "_id": 27912,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 437,
            "שם_רחוב": "ואדי סמאק"
        },
        {
            "_id": 27913,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 381,
            "שם_רחוב": "ח'אלית אלעדס"
        },
        {
            "_id": 27914,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 227,
            "שם_רחוב": "ח'אלית אלתות"
        },
        {
            "_id": 27915,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 192,
            "שם_רחוב": "ח'לואת אלרוג'אב"
        },
        {
            "_id": 27916,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 141,
            "שם_רחוב": "ח'לת אלמשמש"
        },
        {
            "_id": 27917,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 328,
            "שם_רחוב": "חכורת חרב"
        },
        {
            "_id": 27918,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 325,
            "שם_רחוב": "יוסף אלצדיק"
        },
        {
            "_id": 27919,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ירכא"
        },
        {
            "_id": 27920,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 182,
            "שם_רחוב": "כרם חנא"
        },
        {
            "_id": 27921,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 180,
            "שם_רחוב": "מראח זיאן"
        },
        {
            "_id": 27922,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "מרכז הכפר"
        },
        {
            "_id": 27923,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 6734,
            "שם_רחוב": "משפחת קדמאני"
        },
        {
            "_id": 27924,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 447,
            "שם_רחוב": "נביא אלח'דר"
        },
        {
            "_id": 27925,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 335,
            "שם_רחוב": "סידנא אבו אסראיא"
        },
        {
            "_id": 27926,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 190,
            "שם_רחוב": "סנובר אלג'או"
        },
        {
            "_id": 27927,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 393,
            "שם_רחוב": "עריד אלשאמס"
        },
        {
            "_id": 27928,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 179,
            "שם_רחוב": "ראס אלאע'בר"
        },
        {
            "_id": 27929,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "ראס אלמטל"
        },
        {
            "_id": 27930,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 409,
            "שם_רחוב": "רח 100"
        },
        {
            "_id": 27931,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 361,
            "שם_רחוב": "רח 200"
        },
        {
            "_id": 27932,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 388,
            "שם_רחוב": "רח 110"
        },
        {
            "_id": 27933,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 349,
            "שם_רחוב": "רח 210"
        },
        {
            "_id": 27934,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 210,
            "שם_רחוב": "רח 120"
        },
        {
            "_id": 27935,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 420,
            "שם_רחוב": "רח 220"
        },
        {
            "_id": 27936,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 230,
            "שם_רחוב": "רח 130"
        },
        {
            "_id": 27937,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 402,
            "שם_רחוב": "רח 230"
        },
        {
            "_id": 27938,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 171,
            "שם_רחוב": "רח 140"
        },
        {
            "_id": 27939,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 431,
            "שם_רחוב": "רח 240"
        },
        {
            "_id": 27940,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 183,
            "שם_רחוב": "רח 150"
        },
        {
            "_id": 27941,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 354,
            "שם_רחוב": "רח 250"
        },
        {
            "_id": 27942,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 197,
            "שם_רחוב": "רח 160"
        },
        {
            "_id": 27943,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 153,
            "שם_רחוב": "רח 260"
        },
        {
            "_id": 27944,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רח 170"
        },
        {
            "_id": 27945,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 127,
            "שם_רחוב": "רח 180"
        },
        {
            "_id": 27946,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 337,
            "שם_רחוב": "רח 190"
        },
        {
            "_id": 27947,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 384,
            "שם_רחוב": "רח 101"
        },
        {
            "_id": 27948,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רח 201"
        },
        {
            "_id": 27949,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 389,
            "שם_רחוב": "רח 111"
        },
        {
            "_id": 27950,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 350,
            "שם_רחוב": "רח 211"
        },
        {
            "_id": 27951,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 211,
            "שם_רחוב": "רח 121"
        },
        {
            "_id": 27952,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 370,
            "שם_רחוב": "רח 221"
        },
        {
            "_id": 27953,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 231,
            "שם_רחוב": "רח 131"
        },
        {
            "_id": 27954,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 403,
            "שם_רחוב": "רח 231"
        },
        {
            "_id": 27955,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 172,
            "שם_רחוב": "רח 141"
        },
        {
            "_id": 27956,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 404,
            "שם_רחוב": "רח 241"
        },
        {
            "_id": 27957,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 184,
            "שם_רחוב": "רח 151"
        },
        {
            "_id": 27958,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 355,
            "שם_רחוב": "רח 251"
        },
        {
            "_id": 27959,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 198,
            "שם_רחוב": "רח 161"
        },
        {
            "_id": 27960,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 379,
            "שם_רחוב": "רח 261"
        },
        {
            "_id": 27961,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רח 171"
        },
        {
            "_id": 27962,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 128,
            "שם_רחוב": "רח 181"
        },
        {
            "_id": 27963,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 338,
            "שם_רחוב": "רח 191"
        },
        {
            "_id": 27964,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 385,
            "שם_רחוב": "רח 102"
        },
        {
            "_id": 27965,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 143,
            "שם_רחוב": "רח 202"
        },
        {
            "_id": 27966,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 390,
            "שם_רחוב": "רח 112"
        },
        {
            "_id": 27967,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 362,
            "שם_רחוב": "רח 212"
        },
        {
            "_id": 27968,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 212,
            "שם_רחוב": "רח 122"
        },
        {
            "_id": 27969,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 371,
            "שם_רחוב": "רח 222"
        },
        {
            "_id": 27970,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 324,
            "שם_רחוב": "רח 132"
        },
        {
            "_id": 27971,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 422,
            "שם_רחוב": "רח 232"
        },
        {
            "_id": 27972,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 173,
            "שם_רחוב": "רח 142"
        },
        {
            "_id": 27973,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 405,
            "שם_רחוב": "רח 242"
        },
        {
            "_id": 27974,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 185,
            "שם_רחוב": "רח 152"
        },
        {
            "_id": 27975,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 357,
            "שם_רחוב": "רח 252"
        },
        {
            "_id": 27976,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 199,
            "שם_רחוב": "רח 162"
        },
        {
            "_id": 27977,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 380,
            "שם_רחוב": "רח 262"
        },
        {
            "_id": 27978,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח 172"
        },
        {
            "_id": 27979,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 129,
            "שם_רחוב": "רח 182"
        },
        {
            "_id": 27980,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 340,
            "שם_רחוב": "רח 192"
        },
        {
            "_id": 27981,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 386,
            "שם_רחוב": "רח 103"
        },
        {
            "_id": 27982,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 144,
            "שם_רחוב": "רח 203"
        },
        {
            "_id": 27983,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 418,
            "שם_רחוב": "רח 113"
        },
        {
            "_id": 27984,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 363,
            "שם_רחוב": "רח 213"
        },
        {
            "_id": 27985,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רח 123"
        },
        {
            "_id": 27986,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 372,
            "שם_רחוב": "רח 223"
        },
        {
            "_id": 27987,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 326,
            "שם_רחוב": "רח 133"
        },
        {
            "_id": 27988,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 424,
            "שם_רחוב": "רח 233"
        },
        {
            "_id": 27989,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 174,
            "שם_רחוב": "רח 143"
        },
        {
            "_id": 27990,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 406,
            "שם_רחוב": "רח 243"
        },
        {
            "_id": 27991,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 186,
            "שם_רחוב": "רח 153"
        },
        {
            "_id": 27992,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 205,
            "שם_רחוב": "רח 253"
        },
        {
            "_id": 27993,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רח 163"
        },
        {
            "_id": 27994,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 433,
            "שם_רחוב": "רח 263"
        },
        {
            "_id": 27995,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח 173"
        },
        {
            "_id": 27996,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רח 183"
        },
        {
            "_id": 27997,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 342,
            "שם_רחוב": "רח 193"
        },
        {
            "_id": 27998,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 387,
            "שם_רחוב": "רח 104"
        },
        {
            "_id": 27999,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 146,
            "שם_רחוב": "רח 204"
        },
        {
            "_id": 28000,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 391,
            "שם_רחוב": "רח 114"
        },
        {
            "_id": 28001,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 364,
            "שם_רחוב": "רח 214"
        },
        {
            "_id": 28002,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 214,
            "שם_רחוב": "רח 124"
        },
        {
            "_id": 28003,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 373,
            "שם_רחוב": "רח 224"
        },
        {
            "_id": 28004,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 451,
            "שם_רחוב": "רח 134"
        },
        {
            "_id": 28005,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 425,
            "שם_רחוב": "רח 234"
        },
        {
            "_id": 28006,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 175,
            "שם_רחוב": "רח 144"
        },
        {
            "_id": 28007,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 407,
            "שם_רחוב": "רח 244"
        },
        {
            "_id": 28008,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 187,
            "שם_רחוב": "רח 154"
        },
        {
            "_id": 28009,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 206,
            "שם_רחוב": "רח 254"
        },
        {
            "_id": 28010,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רח 164"
        },
        {
            "_id": 28011,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 382,
            "שם_רחוב": "רח 264"
        },
        {
            "_id": 28012,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח 174"
        },
        {
            "_id": 28013,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 133,
            "שם_רחוב": "רח 184"
        },
        {
            "_id": 28014,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 343,
            "שם_רחוב": "רח 194"
        },
        {
            "_id": 28015,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 413,
            "שם_רחוב": "רח 105"
        },
        {
            "_id": 28016,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 347,
            "שם_רחוב": "רח 205"
        },
        {
            "_id": 28017,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 419,
            "שם_רחוב": "רח 115"
        },
        {
            "_id": 28018,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 365,
            "שם_רחוב": "רח 215"
        },
        {
            "_id": 28019,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 217,
            "שם_רחוב": "רח 125"
        },
        {
            "_id": 28020,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 397,
            "שם_רחוב": "רח 225"
        },
        {
            "_id": 28021,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 331,
            "שם_רחוב": "רח 135"
        },
        {
            "_id": 28022,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 426,
            "שם_רחוב": "רח 235"
        },
        {
            "_id": 28023,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 176,
            "שם_רחוב": "רח 145"
        },
        {
            "_id": 28024,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 374,
            "שם_רחוב": "רח 245"
        },
        {
            "_id": 28025,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 189,
            "שם_רחוב": "רח 155"
        },
        {
            "_id": 28026,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 150,
            "שם_רחוב": "רח 255"
        },
        {
            "_id": 28027,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח 165"
        },
        {
            "_id": 28028,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 434,
            "שם_רחוב": "רח 265"
        },
        {
            "_id": 28029,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רח 175"
        },
        {
            "_id": 28030,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 138,
            "שם_רחוב": "רח 185"
        },
        {
            "_id": 28031,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 344,
            "שם_רחוב": "רח 195"
        },
        {
            "_id": 28032,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 414,
            "שם_רחוב": "רח 106"
        },
        {
            "_id": 28033,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 348,
            "שם_רחוב": "רח 206"
        },
        {
            "_id": 28034,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רח 116"
        },
        {
            "_id": 28035,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 366,
            "שם_רחוב": "רח 216"
        },
        {
            "_id": 28036,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 218,
            "שם_רחוב": "רח 126"
        },
        {
            "_id": 28037,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 398,
            "שם_רחוב": "רח 226"
        },
        {
            "_id": 28038,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 163,
            "שם_רחוב": "רח 136"
        },
        {
            "_id": 28039,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 427,
            "שם_רחוב": "רח 236"
        },
        {
            "_id": 28040,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 177,
            "שם_רחוב": "רח 146"
        },
        {
            "_id": 28041,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 201,
            "שם_רחוב": "רח 246"
        },
        {
            "_id": 28042,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 191,
            "שם_רחוב": "רח 156"
        },
        {
            "_id": 28043,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 151,
            "שם_רחוב": "רח 256"
        },
        {
            "_id": 28044,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רח 166"
        },
        {
            "_id": 28045,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 435,
            "שם_רחוב": "רח 266"
        },
        {
            "_id": 28046,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 121,
            "שם_רחוב": "רח 176"
        },
        {
            "_id": 28047,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 139,
            "שם_רחוב": "רח 186"
        },
        {
            "_id": 28048,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 345,
            "שם_רחוב": "רח 196"
        },
        {
            "_id": 28049,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 415,
            "שם_רחוב": "רח 107"
        },
        {
            "_id": 28050,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 147,
            "שם_רחוב": "רח 207"
        },
        {
            "_id": 28051,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 392,
            "שם_רחוב": "רח 117"
        },
        {
            "_id": 28052,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 367,
            "שם_רחוב": "רח 217"
        },
        {
            "_id": 28053,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 219,
            "שם_רחוב": "רח 127"
        },
        {
            "_id": 28054,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 399,
            "שם_רחוב": "רח 227"
        },
        {
            "_id": 28055,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 165,
            "שם_רחוב": "רח 137"
        },
        {
            "_id": 28056,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 430,
            "שם_רחוב": "רח 237"
        },
        {
            "_id": 28057,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 178,
            "שם_רחוב": "רח 147"
        },
        {
            "_id": 28058,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 351,
            "שם_רחוב": "רח 247"
        },
        {
            "_id": 28059,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 193,
            "שם_רחוב": "רח 157"
        },
        {
            "_id": 28060,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 375,
            "שם_רחוב": "רח 257"
        },
        {
            "_id": 28061,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רח 167"
        },
        {
            "_id": 28062,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 436,
            "שם_רחוב": "רח 267"
        },
        {
            "_id": 28063,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רח 177"
        },
        {
            "_id": 28064,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 333,
            "שם_רחוב": "רח 187"
        },
        {
            "_id": 28065,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 346,
            "שם_רחוב": "רח 197"
        },
        {
            "_id": 28066,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 416,
            "שם_רחוב": "רח 108"
        },
        {
            "_id": 28067,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 148,
            "שם_רחוב": "רח 208"
        },
        {
            "_id": 28068,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 394,
            "שם_רחוב": "רח 118"
        },
        {
            "_id": 28069,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 368,
            "שם_רחוב": "רח 218"
        },
        {
            "_id": 28070,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 221,
            "שם_רחוב": "רח 128"
        },
        {
            "_id": 28071,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 400,
            "שם_רחוב": "רח 228"
        },
        {
            "_id": 28072,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 166,
            "שם_רחוב": "רח 138"
        },
        {
            "_id": 28073,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 444,
            "שם_רחוב": "רח 238"
        },
        {
            "_id": 28074,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 202,
            "שם_רחוב": "רח 248"
        },
        {
            "_id": 28075,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 195,
            "שם_רחוב": "רח 158"
        },
        {
            "_id": 28076,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 377,
            "שם_רחוב": "רח 258"
        },
        {
            "_id": 28077,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 200,
            "שם_רחוב": "רח 168"
        },
        {
            "_id": 28078,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רח 178"
        },
        {
            "_id": 28079,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 334,
            "שם_רחוב": "רח 188"
        },
        {
            "_id": 28080,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 359,
            "שם_רחוב": "רח 198"
        },
        {
            "_id": 28081,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 417,
            "שם_רחוב": "רח 109"
        },
        {
            "_id": 28082,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 149,
            "שם_רחוב": "רח 209"
        },
        {
            "_id": 28083,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 395,
            "שם_רחוב": "רח 119"
        },
        {
            "_id": 28084,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 369,
            "שם_רחוב": "רח 219"
        },
        {
            "_id": 28085,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 222,
            "שם_רחוב": "רח 129"
        },
        {
            "_id": 28086,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 401,
            "שם_רחוב": "רח 229"
        },
        {
            "_id": 28087,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 167,
            "שם_רחוב": "רח 139"
        },
        {
            "_id": 28088,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 446,
            "שם_רחוב": "רח 239"
        },
        {
            "_id": 28089,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 181,
            "שם_רחוב": "רח 149"
        },
        {
            "_id": 28090,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 432,
            "שם_רחוב": "רח 249"
        },
        {
            "_id": 28091,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 196,
            "שם_רחוב": "רח 159"
        },
        {
            "_id": 28092,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 378,
            "שם_רחוב": "רח 259"
        },
        {
            "_id": 28093,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 109,
            "שם_רחוב": "רח 169"
        },
        {
            "_id": 28094,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רח 179"
        },
        {
            "_id": 28095,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 336,
            "שם_רחוב": "רח 189"
        },
        {
            "_id": 28096,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 360,
            "שם_רחוב": "רח 199"
        },
        {
            "_id": 28097,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 329,
            "שם_רחוב": "שארע אלבלאט"
        },
        {
            "_id": 28098,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שארע אלעין"
        },
        {
            "_id": 28099,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 158,
            "שם_רחוב": "שארע אלשואחיט"
        },
        {
            "_id": 28100,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 228,
            "שם_רחוב": "שארע אסבר"
        },
        {
            "_id": 28101,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 232,
            "שם_רחוב": "שיח' יוסף גדבאן"
        },
        {
            "_id": 28102,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 453,
            "שם_רחוב": "שיח' מחמד מעדי"
        },
        {
            "_id": 28103,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 327,
            "שם_רחוב": "שיח' עלי אלפארס"
        },
        {
            "_id": 28104,
            "סמל_ישוב": 502,
            "שם_ישוב": "ירכא ",
            "סמל_רחוב": 450,
            "שם_רחוב": "תלת אסקנדר"
        },
        {
            "_id": 28105,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "בית העם"
        },
        {
            "_id": 28106,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך הברוש"
        },
        {
            "_id": 28107,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הפקאן"
        },
        {
            "_id": 28108,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך מייסדי ירקונה"
        },
        {
            "_id": 28109,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 28110,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 28111,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 28112,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 28113,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 28114,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הצאלון"
        },
        {
            "_id": 28115,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 28116,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ירוק עד"
        },
        {
            "_id": 28117,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ירקונה"
        },
        {
            "_id": 28118,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "סביונים"
        },
        {
            "_id": 28119,
            "סמל_ישוב": 183,
            "שם_ישוב": "ירקונה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שירת השחרור"
        },
        {
            "_id": 28120,
            "סמל_ישוב": 916,
            "שם_ישוב": "ישע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ישע"
        },
        {
            "_id": 28121,
            "סמל_ישוב": 805,
            "שם_ישוב": "ישעי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ישעי"
        },
        {
            "_id": 28122,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 28123,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 28124,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דן"
        },
        {
            "_id": 28125,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 105,
            "שם_רחוב": "חרוד"
        },
        {
            "_id": 28126,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ירקון"
        },
        {
            "_id": 28127,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "ישרש"
        },
        {
            "_id": 28128,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 106,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 28129,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 101,
            "שם_רחוב": "נ. הירדן"
        },
        {
            "_id": 28130,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 109,
            "שם_רחוב": "נעמן"
        },
        {
            "_id": 28131,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 28132,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 108,
            "שם_רחוב": "פולג"
        },
        {
            "_id": 28133,
            "סמל_ישוב": 828,
            "שם_ישוב": "ישרש ",
            "סמל_רחוב": 111,
            "שם_רחוב": "קישון"
        },
        {
            "_id": 28134,
            "סמל_ישוב": 1227,
            "שם_ישוב": "יתד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ארזים"
        },
        {
            "_id": 28135,
            "סמל_ישוב": 1227,
            "שם_ישוב": "יתד ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הדסים"
        },
        {
            "_id": 28136,
            "סמל_ישוב": 1227,
            "שם_ישוב": "יתד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "יתד"
        },
        {
            "_id": 28137,
            "סמל_ישוב": 1227,
            "שם_ישוב": "יתד ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 28138,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אבו אלופא"
        },
        {
            "_id": 28139,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אבו בכר אלסדיק"
        },
        {
            "_id": 28140,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אבו חאמד אלג'זאלי"
        },
        {
            "_id": 28141,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אבו חדיפה אלנעמאן"
        },
        {
            "_id": 28142,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אבן אלהיתם"
        },
        {
            "_id": 28143,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אבן אלנפיס"
        },
        {
            "_id": 28144,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 28145,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 28146,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 28147,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אלאזהר"
        },
        {
            "_id": 28148,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 28149,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלאפק"
        },
        {
            "_id": 28150,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלבידר"
        },
        {
            "_id": 28151,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אלביר"
        },
        {
            "_id": 28152,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלבראעם"
        },
        {
            "_id": 28153,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלג'אביה"
        },
        {
            "_id": 28154,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלג'בל"
        },
        {
            "_id": 28155,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אלג'ליל"
        },
        {
            "_id": 28156,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אלג'רבאל"
        },
        {
            "_id": 28157,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלדיעה"
        },
        {
            "_id": 28158,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלדלה"
        },
        {
            "_id": 28159,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 154,
            "שם_רחוב": "אלואדי"
        },
        {
            "_id": 28160,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלורוד"
        },
        {
            "_id": 28161,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 28162,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אלחאג'"
        },
        {
            "_id": 28163,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלחדיקה"
        },
        {
            "_id": 28164,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלחוואז"
        },
        {
            "_id": 28165,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 173,
            "שם_רחוב": "אלחיר"
        },
        {
            "_id": 28166,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלטאחונה"
        },
        {
            "_id": 28167,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלכוארזמי"
        },
        {
            "_id": 28168,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלכימה"
        },
        {
            "_id": 28169,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלכנסאא"
        },
        {
            "_id": 28170,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אלכסוף"
        },
        {
            "_id": 28171,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 174,
            "שם_רחוב": "אלכרובה"
        },
        {
            "_id": 28172,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 160,
            "שם_רחוב": "אלמדור"
        },
        {
            "_id": 28173,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלמחראת"
        },
        {
            "_id": 28174,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלמנבר"
        },
        {
            "_id": 28175,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אלמנג'ל"
        },
        {
            "_id": 28176,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלמסנע"
        },
        {
            "_id": 28177,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלמסרב"
        },
        {
            "_id": 28178,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלמעסרה"
        },
        {
            "_id": 28179,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלמרג'"
        },
        {
            "_id": 28180,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלנהדה"
        },
        {
            "_id": 28181,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 28182,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אלנכלה"
        },
        {
            "_id": 28183,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלנקארה"
        },
        {
            "_id": 28184,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 28185,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אלסאלם"
        },
        {
            "_id": 28186,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלסהל"
        },
        {
            "_id": 28187,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלסנדיאנה"
        },
        {
            "_id": 28188,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלסראג'"
        },
        {
            "_id": 28189,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אלעריד"
        },
        {
            "_id": 28190,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אלקדס"
        },
        {
            "_id": 28191,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלקנדיל"
        },
        {
            "_id": 28192,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלראזי"
        },
        {
            "_id": 28193,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלריחאנה"
        },
        {
            "_id": 28194,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלשאפערי"
        },
        {
            "_id": 28195,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלשיך רומי"
        },
        {
            "_id": 28196,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 102,
            "שם_רחוב": "בילאל בן רבאח"
        },
        {
            "_id": 28197,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 166,
            "שם_רחוב": "ג'זה"
        },
        {
            "_id": 28198,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ח'אלד בן אלואליד"
        },
        {
            "_id": 28199,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 113,
            "שם_רחוב": "ח'ליל אלסכאכיני"
        },
        {
            "_id": 28200,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 141,
            "שם_רחוב": "חג'ר אלנקר"
        },
        {
            "_id": 28201,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 162,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 28202,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 164,
            "שם_רחוב": "יאפא"
        },
        {
            "_id": 28203,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כאבול"
        },
        {
            "_id": 28204,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מי זיאדה"
        },
        {
            "_id": 28205,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 138,
            "שם_רחוב": "מרח אלג'זלאן"
        },
        {
            "_id": 28206,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סויה"
        },
        {
            "_id": 28207,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סידנא אלח'דר"
        },
        {
            "_id": 28208,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 142,
            "שם_רחוב": "עבאס אלעקאד"
        },
        {
            "_id": 28209,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 165,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 28210,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 104,
            "שם_רחוב": "עמר בן אלח'טאב"
        },
        {
            "_id": 28211,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 175,
            "שם_רחוב": "שקאנה"
        },
        {
            "_id": 28212,
            "סמל_ישוב": 504,
            "שם_ישוב": "כאבול ",
            "סמל_רחוב": 148,
            "שם_רחוב": "תוופיק זיאד"
        },
        {
            "_id": 28213,
            "סמל_ישוב": 505,
            "שם_ישוב": "כאוכב אבו אל-היג'א ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כאוכב אבו אל-היג'א"
        },
        {
            "_id": 28214,
            "סמל_ישוב": 576,
            "שם_ישוב": "כברי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כברי"
        },
        {
            "_id": 28215,
            "סמל_ישוב": 371,
            "שם_ישוב": "כדורי ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "אלון התבור"
        },
        {
            "_id": 28216,
            "סמל_ישוב": 371,
            "שם_ישוב": "כדורי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כדורי"
        },
        {
            "_id": 28217,
            "סמל_ישוב": 1338,
            "שם_ישוב": "כדיתה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כדיתה"
        },
        {
            "_id": 28218,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "בית הבד"
        },
        {
            "_id": 28219,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך המשואות"
        },
        {
            "_id": 28220,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך יריחו"
        },
        {
            "_id": 28221,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "האחזות נחל"
        },
        {
            "_id": 28222,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הביכורים"
        },
        {
            "_id": 28223,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 28224,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 28225,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 28226,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 128,
            "שם_רחוב": "היין"
        },
        {
            "_id": 28227,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "היקב"
        },
        {
            "_id": 28228,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 28229,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "המנורה"
        },
        {
            "_id": 28230,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "המסיק"
        },
        {
            "_id": 28231,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הנסכים"
        },
        {
            "_id": 28232,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "הר חצור"
        },
        {
            "_id": 28233,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הר קידה"
        },
        {
            "_id": 28234,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 28235,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "יב באלול"
        },
        {
            "_id": 28236,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 115,
            "שם_רחוב": "יצהר"
        },
        {
            "_id": 28237,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כוכב השחר"
        },
        {
            "_id": 28238,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 120,
            "שם_רחוב": "כתית"
        },
        {
            "_id": 28239,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מול נבו"
        },
        {
            "_id": 28240,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 117,
            "שם_רחוב": "מנחות"
        },
        {
            "_id": 28241,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 119,
            "שם_רחוב": "משכן שילה"
        },
        {
            "_id": 28242,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נוף הירדן"
        },
        {
            "_id": 28243,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "נחל ייטב"
        },
        {
            "_id": 28244,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "נחלת אפרים"
        },
        {
            "_id": 28245,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 126,
            "שם_רחוב": "נר התמיד"
        },
        {
            "_id": 28246,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 130,
            "שם_רחוב": "סלע הרימון"
        },
        {
            "_id": 28247,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ענבי הגפן"
        },
        {
            "_id": 28248,
            "סמל_ישוב": 3564,
            "שם_ישוב": "כוכב השחר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "עת הזמיר"
        },
        {
            "_id": 28249,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3103,
            "שם_רחוב": "אבני החושן צור יגאל"
        },
        {
            "_id": 28250,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3104,
            "שם_רחוב": "אבני חן צור יגאל"
        },
        {
            "_id": 28251,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 28252,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3130,
            "שם_רחוב": "אגמית צור יגאל"
        },
        {
            "_id": 28253,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3105,
            "שם_רחוב": "אודם צור יגאל"
        },
        {
            "_id": 28254,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 142,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 28255,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 28256,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 28257,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3180,
            "שם_רחוב": "אנקור צור יגאל"
        },
        {
            "_id": 28258,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3194,
            "שם_רחוב": "אפק"
        },
        {
            "_id": 28259,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3129,
            "שם_רחוב": "ארבל צור יגאל"
        },
        {
            "_id": 28260,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 145,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 28261,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 28262,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 138,
            "שם_רחוב": "בארותיים"
        },
        {
            "_id": 28263,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 137,
            "שם_רחוב": "בארי"
        },
        {
            "_id": 28264,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3158,
            "שם_רחוב": "בזלת צור יגאל"
        },
        {
            "_id": 28265,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3140,
            "שם_רחוב": "בזק צור יגאל"
        },
        {
            "_id": 28266,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 136,
            "שם_רחוב": "בירנית"
        },
        {
            "_id": 28267,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 131,
            "שם_רחוב": "בית אל"
        },
        {
            "_id": 28268,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3195,
            "שם_רחוב": "בית אלפא"
        },
        {
            "_id": 28269,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3150,
            "שם_רחוב": "ברוש צור יגאל"
        },
        {
            "_id": 28270,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 135,
            "שם_רחוב": "ברעם"
        },
        {
            "_id": 28271,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 134,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 28272,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 133,
            "שם_רחוב": "ברקאי"
        },
        {
            "_id": 28273,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3106,
            "שם_רחוב": "ברקת צור יגאל"
        },
        {
            "_id": 28274,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3141,
            "שם_רחוב": "בשור צור יגאל"
        },
        {
            "_id": 28275,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 132,
            "שם_רחוב": "בת שלמה"
        },
        {
            "_id": 28276,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3190,
            "שם_רחוב": "גבעון צור יגאל"
        },
        {
            "_id": 28277,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 124,
            "שם_רחוב": "גולן"
        },
        {
            "_id": 28278,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3196,
            "שם_רחוב": "גזר"
        },
        {
            "_id": 28279,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 125,
            "שם_רחוב": "גילה"
        },
        {
            "_id": 28280,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 123,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 28281,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3191,
            "שם_רחוב": "גלגל צור יגאל"
        },
        {
            "_id": 28282,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 127,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 28283,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 122,
            "שם_רחוב": "גמלא"
        },
        {
            "_id": 28284,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 121,
            "שם_רחוב": "גריזים"
        },
        {
            "_id": 28285,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3156,
            "שם_רחוב": "גרניט צור יגאל"
        },
        {
            "_id": 28286,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דבורה"
        },
        {
            "_id": 28287,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3197,
            "שם_רחוב": "דביר"
        },
        {
            "_id": 28288,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 28289,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דור"
        },
        {
            "_id": 28290,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דותן"
        },
        {
            "_id": 28291,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דיה"
        },
        {
            "_id": 28292,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דישון"
        },
        {
            "_id": 28293,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 28294,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דליות"
        },
        {
            "_id": 28295,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דלתון"
        },
        {
            "_id": 28296,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דן"
        },
        {
            "_id": 28297,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 112,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 28298,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3169,
            "שם_רחוב": "דפנה צור יגאל"
        },
        {
            "_id": 28299,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 114,
            "שם_רחוב": "דקלה"
        },
        {
            "_id": 28300,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דרגות"
        },
        {
            "_id": 28301,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3181,
            "שם_רחוב": "דרור צור יגאל"
        },
        {
            "_id": 28302,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 139,
            "שם_רחוב": "דרך הגבעה"
        },
        {
            "_id": 28303,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 201,
            "שם_רחוב": "דרך הפרחים"
        },
        {
            "_id": 28304,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3135,
            "שם_רחוב": "האילנות צור יגאל"
        },
        {
            "_id": 28305,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3160,
            "שם_רחוב": "האירוס צור יגאל"
        },
        {
            "_id": 28306,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 218,
            "שם_רחוב": "האיריס"
        },
        {
            "_id": 28307,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 219,
            "שם_רחוב": "הברקן"
        },
        {
            "_id": 28308,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 220,
            "שם_רחוב": "הגומא"
        },
        {
            "_id": 28309,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 221,
            "שם_רחוב": "הדודאים"
        },
        {
            "_id": 28310,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 209,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 28311,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 153,
            "שם_רחוב": "ההר"
        },
        {
            "_id": 28312,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 208,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 28313,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 156,
            "שם_רחוב": "הים"
        },
        {
            "_id": 28314,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 207,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 28315,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 206,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 28316,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 151,
            "שם_רחוב": "הכנרות"
        },
        {
            "_id": 28317,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 205,
            "שם_רחוב": "הכרכום"
        },
        {
            "_id": 28318,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 210,
            "שם_רחוב": "הלוטם"
        },
        {
            "_id": 28319,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 154,
            "שם_רחוב": "המדבר"
        },
        {
            "_id": 28320,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 157,
            "שם_רחוב": "המישר"
        },
        {
            "_id": 28321,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 152,
            "שם_רחוב": "המכתש"
        },
        {
            "_id": 28322,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 155,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 28323,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 203,
            "שם_רחוב": "הנורית"
        },
        {
            "_id": 28324,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 202,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 28325,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 216,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 28326,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 215,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 28327,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3123,
            "שם_רחוב": "הר נבו צור יגאל"
        },
        {
            "_id": 28328,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3168,
            "שם_רחוב": "הרדוף צור יגאל"
        },
        {
            "_id": 28329,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3198,
            "שם_רחוב": "הרודיון"
        },
        {
            "_id": 28330,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3128,
            "שם_רחוב": "הרכסים צור יגאל"
        },
        {
            "_id": 28331,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 214,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 28332,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3131,
            "שם_רחוב": "השחף צור יגאל"
        },
        {
            "_id": 28333,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 213,
            "שם_רחוב": "השפלה"
        },
        {
            "_id": 28334,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 212,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 28335,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3142,
            "שם_רחוב": "זויתן צור יגאל"
        },
        {
            "_id": 28336,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3182,
            "שם_רחוב": "זמיר צור יגאל"
        },
        {
            "_id": 28337,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3109,
            "שם_רחוב": "חלוקי נחל צור יגאל"
        },
        {
            "_id": 28338,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3159,
            "שם_רחוב": "חלמיש צור יגאל"
        },
        {
            "_id": 28339,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3199,
            "שם_רחוב": "חצור"
        },
        {
            "_id": 28340,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3151,
            "שם_רחוב": "חרוב צור יגאל"
        },
        {
            "_id": 28341,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3108,
            "שם_רחוב": "טופז צור יגאל"
        },
        {
            "_id": 28342,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3155,
            "שם_רחוב": "יהלום צור יגאל"
        },
        {
            "_id": 28343,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3167,
            "שם_רחוב": "יסמין צור יגאל"
        },
        {
            "_id": 28344,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3172,
            "שם_רחוב": "יערה צור יגאל"
        },
        {
            "_id": 28345,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3200,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 28346,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3120,
            "שם_רחוב": "כרמל צור יגאל"
        },
        {
            "_id": 28347,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 211,
            "שם_רחוב": "לב הישוב"
        },
        {
            "_id": 28348,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3122,
            "שם_רחוב": "ליבנים צור יגאל"
        },
        {
            "_id": 28349,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3201,
            "שם_רחוב": "לכיש"
        },
        {
            "_id": 28350,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3110,
            "שם_רחוב": "לשם צור יגאל"
        },
        {
            "_id": 28351,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3170,
            "שם_רחוב": "מורן צור יגאל"
        },
        {
            "_id": 28352,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3202,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 28353,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "מצפה ספיר צור יגאל"
        },
        {
            "_id": 28354,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3161,
            "שם_רחוב": "מרגלית צור יגאל"
        },
        {
            "_id": 28355,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3124,
            "שם_רחוב": "נוף הרים צור יגאל"
        },
        {
            "_id": 28356,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3162,
            "שם_רחוב": "נופר צור יגאל"
        },
        {
            "_id": 28357,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3143,
            "שם_רחוב": "נחשון צור יגאל"
        },
        {
            "_id": 28358,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3144,
            "שם_רחוב": "נטפים צור יגאל"
        },
        {
            "_id": 28359,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3203,
            "שם_רחוב": "ניצנה"
        },
        {
            "_id": 28360,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3204,
            "שם_רחוב": "סוסיא"
        },
        {
            "_id": 28361,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3163,
            "שם_רחוב": "סייפן צור יגאל"
        },
        {
            "_id": 28362,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3164,
            "שם_רחוב": "סיתוונית צור יגאל"
        },
        {
            "_id": 28363,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3183,
            "שם_רחוב": "סנונית צור יגאל"
        },
        {
            "_id": 28364,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3107,
            "שם_רחוב": "ספיר צור יגאל"
        },
        {
            "_id": 28365,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3184,
            "שם_רחוב": "עגור צור יגאל"
        },
        {
            "_id": 28366,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3145,
            "שם_רחוב": "עובדת צור יגאל"
        },
        {
            "_id": 28367,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3125,
            "שם_רחוב": "עמק דותן צור יגאל"
        },
        {
            "_id": 28368,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3192,
            "שם_רחוב": "עמק האלה צור יגאל"
        },
        {
            "_id": 28369,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3193,
            "שם_רחוב": "ענתות צור יגאל"
        },
        {
            "_id": 28370,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3185,
            "שם_רחוב": "עפרוני צור יגאל"
        },
        {
            "_id": 28371,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3126,
            "שם_רחוב": "עצמון צור יגאל"
        },
        {
            "_id": 28372,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3121,
            "שם_רחוב": "פסגות צור יגאל"
        },
        {
            "_id": 28373,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "צור יגאל"
        },
        {
            "_id": 28374,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3157,
            "שם_רחוב": "צור צור יגאל"
        },
        {
            "_id": 28375,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3205,
            "שם_רחוב": "ציפורי"
        },
        {
            "_id": 28376,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3127,
            "שם_רחוב": "צרעה צור יגאל"
        },
        {
            "_id": 28377,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3146,
            "שם_רחוב": "קדרון צור יגאל"
        },
        {
            "_id": 28378,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3173,
            "שם_רחוב": "קורנית צור יגאל"
        },
        {
            "_id": 28379,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3206,
            "שם_רחוב": "קיסריה"
        },
        {
            "_id": 28380,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3132,
            "שם_רחוב": "רותם צור יגאל"
        },
        {
            "_id": 28381,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3165,
            "שם_רחוב": "רקפת צור יגאל"
        },
        {
            "_id": 28382,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3133,
            "שם_רחוב": "שד הדקלים צור יגאל"
        },
        {
            "_id": 28383,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שדרות הארץ"
        },
        {
            "_id": 28384,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3111,
            "שם_רחוב": "שוהם צור יגאל"
        },
        {
            "_id": 28385,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3102,
            "שם_רחוב": "שורק צור יגאל"
        },
        {
            "_id": 28386,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3166,
            "שם_רחוב": "שושן צחור צור יגאל"
        },
        {
            "_id": 28387,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3152,
            "שם_רחוב": "שיטה צור יגאל"
        },
        {
            "_id": 28388,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3134,
            "שם_רחוב": "שניר צור יגאל"
        },
        {
            "_id": 28389,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3153,
            "שם_רחוב": "שקד צור יגאל"
        },
        {
            "_id": 28390,
            "סמל_ישוב": 1224,
            "שם_ישוב": "כוכב יאיר ",
            "סמל_רחוב": 3136,
            "שם_רחוב": "תבור צור יגאל"
        },
        {
            "_id": 28391,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אבני חפץ"
        },
        {
            "_id": 28392,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 208,
            "שם_רחוב": "אגן הסהר"
        },
        {
            "_id": 28393,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אדמורי בעלז"
        },
        {
            "_id": 28394,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אדמורי צאנז"
        },
        {
            "_id": 28395,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אוהל דוד"
        },
        {
            "_id": 28396,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אור חדש"
        },
        {
            "_id": 28397,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אפיקי מים"
        },
        {
            "_id": 28398,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 119,
            "שם_רחוב": "בית יוסף"
        },
        {
            "_id": 28399,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "גבעת הברכה"
        },
        {
            "_id": 28400,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 207,
            "שם_רחוב": "גינת אגוז"
        },
        {
            "_id": 28401,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 204,
            "שם_רחוב": "דבש וחלב"
        },
        {
            "_id": 28402,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 227,
            "שם_רחוב": "דרך כוכב יעקב"
        },
        {
            "_id": 28403,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 202,
            "שם_רחוב": "הר המור"
        },
        {
            "_id": 28404,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הרמבן"
        },
        {
            "_id": 28405,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 201,
            "שם_רחוב": "חגווי הסלע"
        },
        {
            "_id": 28406,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "חזון איש"
        },
        {
            "_id": 28407,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 224,
            "שם_רחוב": "חסדי אבות"
        },
        {
            "_id": 28408,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "חפץ חיים"
        },
        {
            "_id": 28409,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 121,
            "שם_רחוב": "חתם סופר"
        },
        {
            "_id": 28410,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 214,
            "שם_רחוב": "טיילת ערוגות הבושם"
        },
        {
            "_id": 28411,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 210,
            "שם_רחוב": "טירת כסף"
        },
        {
            "_id": 28412,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 223,
            "שם_רחוב": "טל אורות"
        },
        {
            "_id": 28413,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 124,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 28414,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כוכב יעקב"
        },
        {
            "_id": 28415,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 125,
            "שם_רחוב": "לב בנים"
        },
        {
            "_id": 28416,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 118,
            "שם_רחוב": "מאור השמש"
        },
        {
            "_id": 28417,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 225,
            "שם_רחוב": "מבשר טוב"
        },
        {
            "_id": 28418,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 216,
            "שם_רחוב": "מים חיים"
        },
        {
            "_id": 28419,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 226,
            "שם_רחוב": "מעייני הישועה"
        },
        {
            "_id": 28420,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 211,
            "שם_רחוב": "מעלה בית אל"
        },
        {
            "_id": 28421,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מעלות החוכמה"
        },
        {
            "_id": 28422,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 215,
            "שם_רחוב": "משכנות הרועים"
        },
        {
            "_id": 28423,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 221,
            "שם_רחוב": "נהר שלום"
        },
        {
            "_id": 28424,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 113,
            "שם_רחוב": "נתיב אלעזר"
        },
        {
            "_id": 28425,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 114,
            "שם_רחוב": "עטרת צבי"
        },
        {
            "_id": 28426,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 206,
            "שם_רחוב": "עצי הלבנון"
        },
        {
            "_id": 28427,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 205,
            "שם_רחוב": "פרדס רימונים"
        },
        {
            "_id": 28428,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 209,
            "שם_רחוב": "פרי מגדים"
        },
        {
            "_id": 28429,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 115,
            "שם_רחוב": "צמח צדיק"
        },
        {
            "_id": 28430,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 219,
            "שם_רחוב": "צמח צדק"
        },
        {
            "_id": 28431,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 123,
            "שם_רחוב": "קדושת לוי"
        },
        {
            "_id": 28432,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 203,
            "שם_רחוב": "קול התור"
        },
        {
            "_id": 28433,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 220,
            "שם_רחוב": "שד אביר יעקב"
        },
        {
            "_id": 28434,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 222,
            "שם_רחוב": "שיבת ציון"
        },
        {
            "_id": 28435,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 218,
            "שם_רחוב": "שירה חדשה"
        },
        {
            "_id": 28436,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ שומר אמונים"
        },
        {
            "_id": 28437,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ שיר השירים"
        },
        {
            "_id": 28438,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 126,
            "שם_רחוב": "שלום בנייך"
        },
        {
            "_id": 28439,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 212,
            "שם_רחוב": "שמחת עולם"
        },
        {
            "_id": 28440,
            "סמל_ישוב": 3779,
            "שם_ישוב": "כוכב יעקב ",
            "סמל_רחוב": 109,
            "שם_רחוב": "תורה מציון"
        },
        {
            "_id": 28441,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 28442,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 28443,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 28444,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 28445,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "העינב"
        },
        {
            "_id": 28446,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 28447,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 28448,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 28449,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 28450,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "התבואה"
        },
        {
            "_id": 28451,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 28452,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "זמורה"
        },
        {
            "_id": 28453,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כוכב מיכאל"
        },
        {
            "_id": 28454,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר הראשונים"
        },
        {
            "_id": 28455,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 28456,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ הגפן"
        },
        {
            "_id": 28457,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ הרימון"
        },
        {
            "_id": 28458,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ התאנה"
        },
        {
            "_id": 28459,
            "סמל_ישוב": 824,
            "שם_ישוב": "כוכב מיכאל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שריג"
        },
        {
            "_id": 28460,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 28461,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 28462,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 28463,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 28464,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 28465,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "גולן"
        },
        {
            "_id": 28466,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "גליל"
        },
        {
            "_id": 28467,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "גמלא"
        },
        {
            "_id": 28468,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 28469,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דליות"
        },
        {
            "_id": 28470,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 28471,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "זוויתן"
        },
        {
            "_id": 28472,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "חות ורד הגליל"
        },
        {
            "_id": 28473,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ירדן"
        },
        {
            "_id": 28474,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כורזים"
        },
        {
            "_id": 28475,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 28476,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מעוף"
        },
        {
            "_id": 28477,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שיאון"
        },
        {
            "_id": 28478,
            "סמל_ישוב": 1252,
            "שם_ישוב": "כורזים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 28479,
            "סמל_ישוב": 1210,
            "שם_ישוב": "כחל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 28480,
            "סמל_ישוב": 1210,
            "שם_ישוב": "כחל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 28481,
            "סמל_ישוב": 1210,
            "שם_ישוב": "כחל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 28482,
            "סמל_ישוב": 1210,
            "שם_ישוב": "כחל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הר-כור"
        },
        {
            "_id": 28483,
            "סמל_ישוב": 1210,
            "שם_ישוב": "כחל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "זית"
        },
        {
            "_id": 28484,
            "סמל_ישוב": 1210,
            "שם_ישוב": "כחל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "ירדן"
        },
        {
            "_id": 28485,
            "סמל_ישוב": 1210,
            "שם_ישוב": "כחל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כחל"
        },
        {
            "_id": 28486,
            "סמל_ישוב": 1210,
            "שם_ישוב": "כחל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כינרת"
        },
        {
            "_id": 28487,
            "סמל_ישוב": 1210,
            "שם_ישוב": "כחל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 28488,
            "סמל_ישוב": 1367,
            "שם_ישוב": "כחלה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כחלה"
        },
        {
            "_id": 28489,
            "סמל_ישוב": 840,
            "שם_ישוב": "כיסופים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 28490,
            "סמל_ישוב": 840,
            "שם_ישוב": "כיסופים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כיסופים"
        },
        {
            "_id": 28491,
            "סמל_ישוב": 1153,
            "שם_ישוב": "כישור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כישור"
        },
        {
            "_id": 28492,
            "סמל_ישוב": 1153,
            "שם_ישוב": "כישור ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "כישורית"
        },
        {
            "_id": 28493,
            "סמל_ישוב": 1183,
            "שם_ישוב": "כליל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כליל"
        },
        {
            "_id": 28494,
            "סמל_ישוב": 1229,
            "שם_ישוב": "כלנית ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 28495,
            "סמל_ישוב": 1331,
            "שם_ישוב": "כמאנה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כמאנה"
        },
        {
            "_id": 28496,
            "סמל_ישוב": 1291,
            "שם_ישוב": "כמהין ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 28497,
            "סמל_ישוב": 1291,
            "שם_ישוב": "כמהין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כמהין"
        },
        {
            "_id": 28498,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הגבעה הצפונית"
        },
        {
            "_id": 28499,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 28500,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הזריחה"
        },
        {
            "_id": 28501,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 28502,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "המצוק"
        },
        {
            "_id": 28503,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "המצפור"
        },
        {
            "_id": 28504,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "המרעה"
        },
        {
            "_id": 28505,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 28506,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כמון"
        },
        {
            "_id": 28507,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "כרמי זיתים"
        },
        {
            "_id": 28508,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מגדל המים"
        },
        {
            "_id": 28509,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נוף ים"
        },
        {
            "_id": 28510,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "פרשת מים"
        },
        {
            "_id": 28511,
            "סמל_ישוב": 1201,
            "שם_ישוב": "כמון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שעון השמש"
        },
        {
            "_id": 28512,
            "סמל_ישוב": 2006,
            "שם_ישוב": "כנות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כנות"
        },
        {
            "_id": 28513,
            "סמל_ישוב": 4028,
            "שם_ישוב": "כנף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כנף"
        },
        {
            "_id": 28514,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גבעת הזיתים"
        },
        {
            "_id": 28515,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך הבזלת"
        },
        {
            "_id": 28516,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך הזיתים"
        },
        {
            "_id": 28517,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הכלניות"
        },
        {
            "_id": 28518,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 125,
            "שם_רחוב": "דרך המעיין"
        },
        {
            "_id": 28519,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 126,
            "שם_רחוב": "האגם"
        },
        {
            "_id": 28520,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האומנים"
        },
        {
            "_id": 28521,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 28522,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 28523,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 109,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 28524,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 123,
            "שם_רחוב": "המסיק"
        },
        {
            "_id": 28525,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 115,
            "שם_רחוב": "המצפה"
        },
        {
            "_id": 28526,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הפלג"
        },
        {
            "_id": 28527,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 28528,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 28529,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 108,
            "שם_רחוב": "חוף הירדן"
        },
        {
            "_id": 28530,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 119,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 28531,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 122,
            "שם_רחוב": "יצהר"
        },
        {
            "_id": 28532,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כנרת (מושבה)"
        },
        {
            "_id": 28533,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 121,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 28534,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 112,
            "שם_רחוב": "מעלה הבריכה"
        },
        {
            "_id": 28535,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 120,
            "שם_רחוב": "מעלה ההר"
        },
        {
            "_id": 28536,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נוף הים"
        },
        {
            "_id": 28537,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 117,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 28538,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 118,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 28539,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 111,
            "שם_רחוב": "סמטת הארמון"
        },
        {
            "_id": 28540,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 113,
            "שם_רחוב": "סמטת המדרגות"
        },
        {
            "_id": 28541,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 114,
            "שם_רחוב": "סמטת המוזיאון"
        },
        {
            "_id": 28542,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 127,
            "שם_רחוב": "סמטת הנחל"
        },
        {
            "_id": 28543,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רחל"
        },
        {
            "_id": 28544,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ הכלניות"
        },
        {
            "_id": 28545,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ המעיין"
        },
        {
            "_id": 28546,
            "סמל_ישוב": 63,
            "שם_ישוב": "כנרת (מושבה) ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ' הבזלת"
        },
        {
            "_id": 28547,
            "סמל_ישוב": 57,
            "שם_ישוב": "כנרת (קבוצה) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כנרת (קבוצה)"
        },
        {
            "_id": 28548,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כסיפה"
        },
        {
            "_id": 28549,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6726,
            "שם_רחוב": "שכ 20"
        },
        {
            "_id": 28550,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6703,
            "שם_רחוב": "שכ 30"
        },
        {
            "_id": 28551,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "שכ 40"
        },
        {
            "_id": 28552,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6727,
            "שם_רחוב": "שכ 80"
        },
        {
            "_id": 28553,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "שכ 11"
        },
        {
            "_id": 28554,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "שכ 21"
        },
        {
            "_id": 28555,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6704,
            "שם_רחוב": "שכ 31"
        },
        {
            "_id": 28556,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6709,
            "שם_רחוב": "שכ 41"
        },
        {
            "_id": 28557,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "שכ 12"
        },
        {
            "_id": 28558,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "שכ 22"
        },
        {
            "_id": 28559,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "שכ 32"
        },
        {
            "_id": 28560,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6705,
            "שם_רחוב": "שכ 42"
        },
        {
            "_id": 28561,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6715,
            "שם_רחוב": "שכ 13"
        },
        {
            "_id": 28562,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6724,
            "שם_רחוב": "שכ 23"
        },
        {
            "_id": 28563,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "שכ 33"
        },
        {
            "_id": 28564,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6706,
            "שם_רחוב": "שכ 43"
        },
        {
            "_id": 28565,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6716,
            "שם_רחוב": "שכ 14"
        },
        {
            "_id": 28566,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6701,
            "שם_רחוב": "שכ 24"
        },
        {
            "_id": 28567,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6717,
            "שם_רחוב": "שכ 15"
        },
        {
            "_id": 28568,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6702,
            "שם_רחוב": "שכ 25"
        },
        {
            "_id": 28569,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6707,
            "שם_רחוב": "שכ 45"
        },
        {
            "_id": 28570,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6718,
            "שם_רחוב": "שכ 16"
        },
        {
            "_id": 28571,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6725,
            "שם_רחוב": "שכ 36"
        },
        {
            "_id": 28572,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6708,
            "שם_רחוב": "שכ 46"
        },
        {
            "_id": 28573,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6719,
            "שם_רחוב": "שכ 17"
        },
        {
            "_id": 28574,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "שכ 18"
        },
        {
            "_id": 28575,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "שכ 19"
        },
        {
            "_id": 28576,
            "סמל_ישוב": 1059,
            "שם_ישוב": "כסיפה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שקאאיק א-נועמאן"
        },
        {
            "_id": 28577,
            "סמל_ישוב": 859,
            "שם_ישוב": "כסלון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כסלון"
        },
        {
            "_id": 28578,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כסרא"
        },
        {
            "_id": 28579,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כסרא-סמיע"
        },
        {
            "_id": 28580,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 102,
            "שם_רחוב": "כפר סמיע"
        },
        {
            "_id": 28581,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 192,
            "שם_רחוב": "רח 10"
        },
        {
            "_id": 28582,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 231,
            "שם_רחוב": "רח 1310"
        },
        {
            "_id": 28583,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 246,
            "שם_רחוב": "רח 1410"
        },
        {
            "_id": 28584,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 187,
            "שם_רחוב": "רח 710"
        },
        {
            "_id": 28585,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 285,
            "שם_רחוב": "רח 1910"
        },
        {
            "_id": 28586,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 100,
            "שם_רחוב": "רח 1"
        },
        {
            "_id": 28587,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 193,
            "שם_רחוב": "רח 1001"
        },
        {
            "_id": 28588,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 300,
            "שם_רחוב": "רח 2001"
        },
        {
            "_id": 28589,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רח 101"
        },
        {
            "_id": 28590,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רח 201"
        },
        {
            "_id": 28591,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 210,
            "שם_רחוב": "רח 1201"
        },
        {
            "_id": 28592,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רח 401"
        },
        {
            "_id": 28593,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 136,
            "שם_רחוב": "רח 501"
        },
        {
            "_id": 28594,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 249,
            "שם_רחוב": "רח 1501"
        },
        {
            "_id": 28595,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 162,
            "שם_רחוב": "רח 601"
        },
        {
            "_id": 28596,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 263,
            "שם_רחוב": "רח 1701"
        },
        {
            "_id": 28597,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 270,
            "שם_רחוב": "רח 1801"
        },
        {
            "_id": 28598,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 290,
            "שם_רחוב": "רח 1901"
        },
        {
            "_id": 28599,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 202,
            "שם_רחוב": "רח 11"
        },
        {
            "_id": 28600,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 232,
            "שם_רחוב": "רח 1311"
        },
        {
            "_id": 28601,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 247,
            "שם_רחוב": "רח 1411"
        },
        {
            "_id": 28602,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 172,
            "שם_רחוב": "רח 611"
        },
        {
            "_id": 28603,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 283,
            "שם_רחוב": "רח 1911"
        },
        {
            "_id": 28604,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 303,
            "שם_רחוב": "רח 21"
        },
        {
            "_id": 28605,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 279,
            "שם_רחוב": "רח 19121"
        },
        {
            "_id": 28606,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 298,
            "שם_רחוב": "רח 191221"
        },
        {
            "_id": 28607,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 160,
            "שם_רחוב": "רח 51721"
        },
        {
            "_id": 28608,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 295,
            "שם_רחוב": "רח 19131"
        },
        {
            "_id": 28609,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 141,
            "שם_רחוב": "רח 5041"
        },
        {
            "_id": 28610,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 268,
            "שם_רחוב": "רח 17041"
        },
        {
            "_id": 28611,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 155,
            "שם_רחוב": "רח 51641"
        },
        {
            "_id": 28612,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 257,
            "שם_רחוב": "רח 86551"
        },
        {
            "_id": 28613,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 151,
            "שם_רחוב": "רח 5161"
        },
        {
            "_id": 28614,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 158,
            "שם_רחוב": "רח 5171"
        },
        {
            "_id": 28615,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 121,
            "שם_רחוב": "רח 2091"
        },
        {
            "_id": 28616,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 288,
            "שם_רחוב": "רח 19091"
        },
        {
            "_id": 28617,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 109,
            "שם_רחוב": "רח 2"
        },
        {
            "_id": 28618,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 194,
            "שם_רחוב": "רח 1002"
        },
        {
            "_id": 28619,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 301,
            "שם_רחוב": "רח 2002"
        },
        {
            "_id": 28620,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח 102"
        },
        {
            "_id": 28621,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 204,
            "שם_רחוב": "רח 1102"
        },
        {
            "_id": 28622,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רח 202"
        },
        {
            "_id": 28623,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רח 402"
        },
        {
            "_id": 28624,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 163,
            "שם_רחוב": "רח 602"
        },
        {
            "_id": 28625,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 179,
            "שם_רחוב": "רח 702"
        },
        {
            "_id": 28626,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 264,
            "שם_רחוב": "רח 1702"
        },
        {
            "_id": 28627,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 271,
            "שם_רחוב": "רח 1802"
        },
        {
            "_id": 28628,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 291,
            "שם_רחוב": "רח 1902"
        },
        {
            "_id": 28629,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רח 12"
        },
        {
            "_id": 28630,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 233,
            "שם_רחוב": "רח 1312"
        },
        {
            "_id": 28631,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 292,
            "שם_רחוב": "רח 1912"
        },
        {
            "_id": 28632,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 293,
            "שם_רחוב": "רח 19122"
        },
        {
            "_id": 28633,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 223,
            "שם_רחוב": "רח 1032"
        },
        {
            "_id": 28634,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 296,
            "שם_רחוב": "רח 19132"
        },
        {
            "_id": 28635,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 156,
            "שם_רחוב": "רח 51642"
        },
        {
            "_id": 28636,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 258,
            "שם_רחוב": "רח 86552"
        },
        {
            "_id": 28637,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 152,
            "שם_רחוב": "רח 5162"
        },
        {
            "_id": 28638,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 159,
            "שם_רחוב": "רח 5172"
        },
        {
            "_id": 28639,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רח 3"
        },
        {
            "_id": 28640,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 195,
            "שם_רחוב": "רח 1003"
        },
        {
            "_id": 28641,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 302,
            "שם_רחוב": "רח 2003"
        },
        {
            "_id": 28642,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רח 103"
        },
        {
            "_id": 28643,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 211,
            "שם_רחוב": "רח 1203"
        },
        {
            "_id": 28644,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 126,
            "שם_רחוב": "רח 403"
        },
        {
            "_id": 28645,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 250,
            "שם_רחוב": "רח 1503"
        },
        {
            "_id": 28646,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 180,
            "שם_רחוב": "רח 703"
        },
        {
            "_id": 28647,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 265,
            "שם_רחוב": "רח 1703"
        },
        {
            "_id": 28648,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 272,
            "שם_רחוב": "רח 1803"
        },
        {
            "_id": 28649,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 281,
            "שם_רחוב": "רח 1903"
        },
        {
            "_id": 28650,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 221,
            "שם_רחוב": "רח 13"
        },
        {
            "_id": 28651,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 190,
            "שם_רחוב": "רח 713"
        },
        {
            "_id": 28652,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 294,
            "שם_רחוב": "רח 1913"
        },
        {
            "_id": 28653,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 297,
            "שם_רחוב": "רח 19133"
        },
        {
            "_id": 28654,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 260,
            "שם_רחוב": "רח 86553"
        },
        {
            "_id": 28655,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 153,
            "שם_רחוב": "רח 5163"
        },
        {
            "_id": 28656,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רח 4"
        },
        {
            "_id": 28657,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רח 104"
        },
        {
            "_id": 28658,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 206,
            "שם_רחוב": "רח 1104"
        },
        {
            "_id": 28659,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רח 204"
        },
        {
            "_id": 28660,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 212,
            "שם_רחוב": "רח 1204"
        },
        {
            "_id": 28661,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 139,
            "שם_רחוב": "רח 504"
        },
        {
            "_id": 28662,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 251,
            "שם_רחוב": "רח 1504"
        },
        {
            "_id": 28663,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 165,
            "שם_רחוב": "רח 604"
        },
        {
            "_id": 28664,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 266,
            "שם_רחוב": "רח 1704"
        },
        {
            "_id": 28665,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 280,
            "שם_רחוב": "רח 1904"
        },
        {
            "_id": 28666,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 237,
            "שם_רחוב": "רח 14"
        },
        {
            "_id": 28667,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 148,
            "שם_רחוב": "רח 514"
        },
        {
            "_id": 28668,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 259,
            "שם_רחוב": "רח 86554"
        },
        {
            "_id": 28669,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 154,
            "שם_רחוב": "רח 5164"
        },
        {
            "_id": 28670,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 135,
            "שם_רחוב": "רח 5"
        },
        {
            "_id": 28671,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רח 105"
        },
        {
            "_id": 28672,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 207,
            "שם_רחוב": "רח 1105"
        },
        {
            "_id": 28673,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רח 205"
        },
        {
            "_id": 28674,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רח 1205"
        },
        {
            "_id": 28675,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 127,
            "שם_רחוב": "רח 405"
        },
        {
            "_id": 28676,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 241,
            "שם_רחוב": "רח 1405"
        },
        {
            "_id": 28677,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 140,
            "שם_רחוב": "רח 505"
        },
        {
            "_id": 28678,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 267,
            "שם_רחוב": "רח 1705"
        },
        {
            "_id": 28679,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 274,
            "שם_רחוב": "רח 1805"
        },
        {
            "_id": 28680,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 282,
            "שם_רחוב": "רח 1905"
        },
        {
            "_id": 28681,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 248,
            "שם_רחוב": "רח 15"
        },
        {
            "_id": 28682,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 236,
            "שם_רחוב": "רח 1315"
        },
        {
            "_id": 28683,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 149,
            "שם_רחוב": "רח 515"
        },
        {
            "_id": 28684,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 176,
            "שם_רחוב": "רח 615"
        },
        {
            "_id": 28685,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 256,
            "שם_רחוב": "רח 86555"
        },
        {
            "_id": 28686,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 161,
            "שם_רחוב": "רח 6"
        },
        {
            "_id": 28687,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 198,
            "שם_רחוב": "רח 1006"
        },
        {
            "_id": 28688,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רח 106"
        },
        {
            "_id": 28689,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 208,
            "שם_רחוב": "רח 1106"
        },
        {
            "_id": 28690,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח 206"
        },
        {
            "_id": 28691,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 214,
            "שם_רחוב": "רח 1206"
        },
        {
            "_id": 28692,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 242,
            "שם_רחוב": "רח 1406"
        },
        {
            "_id": 28693,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 167,
            "שם_רחוב": "רח 606"
        },
        {
            "_id": 28694,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 284,
            "שם_רחוב": "רח 1906"
        },
        {
            "_id": 28695,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 150,
            "שם_רחוב": "רח 516"
        },
        {
            "_id": 28696,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 261,
            "שם_רחוב": "רח 86556"
        },
        {
            "_id": 28697,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 199,
            "שם_רחוב": "רח 1007"
        },
        {
            "_id": 28698,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רח 207"
        },
        {
            "_id": 28699,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 215,
            "שם_רחוב": "רח 1207"
        },
        {
            "_id": 28700,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 129,
            "שם_רחוב": "רח 407"
        },
        {
            "_id": 28701,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 243,
            "שם_רחוב": "רח 1407"
        },
        {
            "_id": 28702,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 184,
            "שם_רחוב": "רח 707"
        },
        {
            "_id": 28703,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 286,
            "שם_רחוב": "רח 1907"
        },
        {
            "_id": 28704,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 262,
            "שם_רחוב": "רח 17"
        },
        {
            "_id": 28705,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 200,
            "שם_רחוב": "רח 1008"
        },
        {
            "_id": 28706,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רח 208"
        },
        {
            "_id": 28707,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 216,
            "שם_רחוב": "רח 1208"
        },
        {
            "_id": 28708,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 229,
            "שם_רחוב": "רח 1308"
        },
        {
            "_id": 28709,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 244,
            "שם_רחוב": "רח 1408"
        },
        {
            "_id": 28710,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רח 508"
        },
        {
            "_id": 28711,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 185,
            "שם_רחוב": "רח 708"
        },
        {
            "_id": 28712,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 289,
            "שם_רחוב": "רח 1908"
        },
        {
            "_id": 28713,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 269,
            "שם_רחוב": "רח 18"
        },
        {
            "_id": 28714,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח 209"
        },
        {
            "_id": 28715,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 245,
            "שם_רחוב": "רח 1409"
        },
        {
            "_id": 28716,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 143,
            "שם_רחוב": "רח 509"
        },
        {
            "_id": 28717,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 170,
            "שם_רחוב": "רח 609"
        },
        {
            "_id": 28718,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 186,
            "שם_רחוב": "רח 709"
        },
        {
            "_id": 28719,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 287,
            "שם_רחוב": "רח 1909"
        },
        {
            "_id": 28720,
            "סמל_ישוב": 1296,
            "שם_ישוב": "כסרא-סמיע ",
            "סמל_רחוב": 276,
            "שם_רחוב": "רח 19"
        },
        {
            "_id": 28721,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אבו בכר אלסדיק"
        },
        {
            "_id": 28722,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אבו-נסור"
        },
        {
            "_id": 28723,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלבוטמה"
        },
        {
            "_id": 28724,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלג'אמע"
        },
        {
            "_id": 28725,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלזהור"
        },
        {
            "_id": 28726,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלזייתון"
        },
        {
            "_id": 28727,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אלחנוה"
        },
        {
            "_id": 28728,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלטואחין"
        },
        {
            "_id": 28729,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלטף"
        },
        {
            "_id": 28730,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 28731,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלכלה"
        },
        {
            "_id": 28732,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלמלעב"
        },
        {
            "_id": 28733,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 28734,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 28735,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלסדרה"
        },
        {
            "_id": 28736,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלסודאני"
        },
        {
            "_id": 28737,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלסנובר"
        },
        {
            "_id": 28738,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלסריס"
        },
        {
            "_id": 28739,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלעאסי"
        },
        {
            "_id": 28740,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 28741,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלראס"
        },
        {
            "_id": 28742,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלרחמה"
        },
        {
            "_id": 28743,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלתלה"
        },
        {
            "_id": 28744,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "ח'ראמה"
        },
        {
            "_id": 28745,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "חג'אג'רה"
        },
        {
            "_id": 28746,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "טבאש"
        },
        {
            "_id": 28747,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "כעביה"
        },
        {
            "_id": 28748,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כעביה-טבאש-חג'אג'רה"
        },
        {
            "_id": 28749,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "עין אם עלי"
        },
        {
            "_id": 28750,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "רח 100"
        },
        {
            "_id": 28751,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "רח 10"
        },
        {
            "_id": 28752,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "רח 110"
        },
        {
            "_id": 28753,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "רח 20"
        },
        {
            "_id": 28754,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "רח 120"
        },
        {
            "_id": 28755,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 185,
            "שם_רחוב": "רח 130"
        },
        {
            "_id": 28756,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "רח 140"
        },
        {
            "_id": 28757,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "רח 50"
        },
        {
            "_id": 28758,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "רח 150"
        },
        {
            "_id": 28759,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 245,
            "שם_רחוב": "רח 60"
        },
        {
            "_id": 28760,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "רח 160"
        },
        {
            "_id": 28761,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "רח 70"
        },
        {
            "_id": 28762,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "רח 270"
        },
        {
            "_id": 28763,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 146,
            "שם_רחוב": "רח 80"
        },
        {
            "_id": 28764,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "רח 180"
        },
        {
            "_id": 28765,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "רח 1"
        },
        {
            "_id": 28766,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "רח 101"
        },
        {
            "_id": 28767,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "רח 11"
        },
        {
            "_id": 28768,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "רח 111"
        },
        {
            "_id": 28769,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "רח 21"
        },
        {
            "_id": 28770,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "רח 121"
        },
        {
            "_id": 28771,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "רח 131"
        },
        {
            "_id": 28772,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "רח 141"
        },
        {
            "_id": 28773,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רח 51"
        },
        {
            "_id": 28774,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "רח 151"
        },
        {
            "_id": 28775,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 246,
            "שם_רחוב": "רח 61"
        },
        {
            "_id": 28776,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "רח 161"
        },
        {
            "_id": 28777,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "רח 71"
        },
        {
            "_id": 28778,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "רח 171"
        },
        {
            "_id": 28779,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "רח 81"
        },
        {
            "_id": 28780,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "רח 181"
        },
        {
            "_id": 28781,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "רח 2"
        },
        {
            "_id": 28782,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "רח 102"
        },
        {
            "_id": 28783,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "רח 12"
        },
        {
            "_id": 28784,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "רח 112"
        },
        {
            "_id": 28785,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "רח 22"
        },
        {
            "_id": 28786,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "רח 122"
        },
        {
            "_id": 28787,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "רח 132"
        },
        {
            "_id": 28788,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "רח 142"
        },
        {
            "_id": 28789,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 143,
            "שם_רחוב": "רח 52"
        },
        {
            "_id": 28790,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "רח 152"
        },
        {
            "_id": 28791,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 247,
            "שם_רחוב": "רח 62"
        },
        {
            "_id": 28792,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "רח 72"
        },
        {
            "_id": 28793,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "רח 172"
        },
        {
            "_id": 28794,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 147,
            "שם_רחוב": "רח 82"
        },
        {
            "_id": 28795,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "רח 182"
        },
        {
            "_id": 28796,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "רח 3"
        },
        {
            "_id": 28797,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "רח 103"
        },
        {
            "_id": 28798,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רח 13"
        },
        {
            "_id": 28799,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "רח 113"
        },
        {
            "_id": 28800,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "רח 123"
        },
        {
            "_id": 28801,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "רח 133"
        },
        {
            "_id": 28802,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "רח 143"
        },
        {
            "_id": 28803,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 144,
            "שם_רחוב": "רח 53"
        },
        {
            "_id": 28804,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רח 153"
        },
        {
            "_id": 28805,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "רח 163"
        },
        {
            "_id": 28806,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "רח 73"
        },
        {
            "_id": 28807,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "רח 173"
        },
        {
            "_id": 28808,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 148,
            "שם_רחוב": "רח 83"
        },
        {
            "_id": 28809,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "רח 4"
        },
        {
            "_id": 28810,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "רח 104"
        },
        {
            "_id": 28811,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "רח 14"
        },
        {
            "_id": 28812,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "רח 114"
        },
        {
            "_id": 28813,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "רח 124"
        },
        {
            "_id": 28814,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 189,
            "שם_רחוב": "רח 134"
        },
        {
            "_id": 28815,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "רח 154"
        },
        {
            "_id": 28816,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 248,
            "שם_רחוב": "רח 64"
        },
        {
            "_id": 28817,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "רח 164"
        },
        {
            "_id": 28818,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "רח 74"
        },
        {
            "_id": 28819,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "רח 174"
        },
        {
            "_id": 28820,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 149,
            "שם_רחוב": "רח 84"
        },
        {
            "_id": 28821,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "רח 105"
        },
        {
            "_id": 28822,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "רח 15"
        },
        {
            "_id": 28823,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "רח 115"
        },
        {
            "_id": 28824,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "רח 125"
        },
        {
            "_id": 28825,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "רח 135"
        },
        {
            "_id": 28826,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "רח 145"
        },
        {
            "_id": 28827,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 145,
            "שם_רחוב": "רח 55"
        },
        {
            "_id": 28828,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 249,
            "שם_רחוב": "רח 65"
        },
        {
            "_id": 28829,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "רח 165"
        },
        {
            "_id": 28830,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "רח 75"
        },
        {
            "_id": 28831,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "רח 175"
        },
        {
            "_id": 28832,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "רח 106"
        },
        {
            "_id": 28833,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "רח 16"
        },
        {
            "_id": 28834,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "רח 116"
        },
        {
            "_id": 28835,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "רח 126"
        },
        {
            "_id": 28836,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 191,
            "שם_רחוב": "רח 136"
        },
        {
            "_id": 28837,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "רח 146"
        },
        {
            "_id": 28838,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "רח 56"
        },
        {
            "_id": 28839,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "רח 156"
        },
        {
            "_id": 28840,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 250,
            "שם_רחוב": "רח 66"
        },
        {
            "_id": 28841,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "רח 76"
        },
        {
            "_id": 28842,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "רח 176"
        },
        {
            "_id": 28843,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "רח 86"
        },
        {
            "_id": 28844,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "רח 7"
        },
        {
            "_id": 28845,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "רח 107"
        },
        {
            "_id": 28846,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "רח 17"
        },
        {
            "_id": 28847,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "רח 117"
        },
        {
            "_id": 28848,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "רח 127"
        },
        {
            "_id": 28849,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 192,
            "שם_רחוב": "רח 137"
        },
        {
            "_id": 28850,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "רח 147"
        },
        {
            "_id": 28851,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "רח 57"
        },
        {
            "_id": 28852,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רח 157"
        },
        {
            "_id": 28853,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "רח 67"
        },
        {
            "_id": 28854,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "רח 167"
        },
        {
            "_id": 28855,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "רח 77"
        },
        {
            "_id": 28856,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "רח 177"
        },
        {
            "_id": 28857,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "רח 8"
        },
        {
            "_id": 28858,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "רח 108"
        },
        {
            "_id": 28859,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "רח 18"
        },
        {
            "_id": 28860,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "רח 118"
        },
        {
            "_id": 28861,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 183,
            "שם_רחוב": "רח 128"
        },
        {
            "_id": 28862,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 193,
            "שם_רחוב": "רח 138"
        },
        {
            "_id": 28863,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "רח 148"
        },
        {
            "_id": 28864,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "רח 58"
        },
        {
            "_id": 28865,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "רח 158"
        },
        {
            "_id": 28866,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "רח 68"
        },
        {
            "_id": 28867,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "רח 168"
        },
        {
            "_id": 28868,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "רח 78"
        },
        {
            "_id": 28869,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "רח 178"
        },
        {
            "_id": 28870,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "רח 9"
        },
        {
            "_id": 28871,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "רח 109"
        },
        {
            "_id": 28872,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "רח 19"
        },
        {
            "_id": 28873,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "רח 119"
        },
        {
            "_id": 28874,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 184,
            "שם_רחוב": "רח 129"
        },
        {
            "_id": 28875,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 194,
            "שם_רחוב": "רח 139"
        },
        {
            "_id": 28876,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "רח 149"
        },
        {
            "_id": 28877,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "רח 59"
        },
        {
            "_id": 28878,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "רח 159"
        },
        {
            "_id": 28879,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "רח 69"
        },
        {
            "_id": 28880,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "רח 169"
        },
        {
            "_id": 28881,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "רח 79"
        },
        {
            "_id": 28882,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "רח 179"
        },
        {
            "_id": 28883,
            "סמל_ישוב": 978,
            "שם_ישוב": "כעביה-טבאש-חג'אג'רה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "רח 199"
        },
        {
            "_id": 28884,
            "סמל_ישוב": 857,
            "שם_ישוב": "כפר אביב ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 28885,
            "סמל_ישוב": 857,
            "שם_ישוב": "כפר אביב ",
            "סמל_רחוב": 101,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 28886,
            "סמל_ישוב": 857,
            "שם_ישוב": "כפר אביב ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 28887,
            "סמל_ישוב": 857,
            "שם_ישוב": "כפר אביב ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 28888,
            "סמל_ישוב": 857,
            "שם_ישוב": "כפר אביב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר אביב"
        },
        {
            "_id": 28889,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "ארץ בנימין"
        },
        {
            "_id": 28890,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 127,
            "שם_רחוב": "ארץ הצבי"
        },
        {
            "_id": 28891,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 137,
            "שם_רחוב": "ארץ חיטה"
        },
        {
            "_id": 28892,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 158,
            "שם_רחוב": "ארץ קדם"
        },
        {
            "_id": 28893,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "בורות המים"
        },
        {
            "_id": 28894,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "גבול יהודה"
        },
        {
            "_id": 28895,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 142,
            "שם_רחוב": "דבש התאנה"
        },
        {
            "_id": 28896,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך אלון"
        },
        {
            "_id": 28897,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 143,
            "שם_רחוב": "דרך החיים"
        },
        {
            "_id": 28898,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "דרך המדבר"
        },
        {
            "_id": 28899,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 133,
            "שם_רחוב": "דרך המשמר"
        },
        {
            "_id": 28900,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "דרך הצופים"
        },
        {
            "_id": 28901,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך פרת"
        },
        {
            "_id": 28902,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "האוג"
        },
        {
            "_id": 28903,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 152,
            "שם_רחוב": "האופק"
        },
        {
            "_id": 28904,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 131,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 28905,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 151,
            "שם_רחוב": "הבקעה"
        },
        {
            "_id": 28906,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 28907,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 28908,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "המעיין"
        },
        {
            "_id": 28909,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 154,
            "שם_רחוב": "המצוק"
        },
        {
            "_id": 28910,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 150,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 28911,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הסלע"
        },
        {
            "_id": 28912,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 155,
            "שם_רחוב": "הצבאים"
        },
        {
            "_id": 28913,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הצור"
        },
        {
            "_id": 28914,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 156,
            "שם_רחוב": "הקשת"
        },
        {
            "_id": 28915,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 28916,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 125,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 28917,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 130,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 28918,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "חגווי הסלע"
        },
        {
            "_id": 28919,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 138,
            "שם_רחוב": "חמדת השקד"
        },
        {
            "_id": 28920,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 139,
            "שם_רחוב": "כפות תמרים"
        },
        {
            "_id": 28921,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר אדומים"
        },
        {
            "_id": 28922,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 129,
            "שם_רחוב": "מבוא ירושלים"
        },
        {
            "_id": 28923,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 162,
            "שם_רחוב": "מי באר"
        },
        {
            "_id": 28924,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "מי מבוע"
        },
        {
            "_id": 28925,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 160,
            "שם_רחוב": "מצפה כרמים"
        },
        {
            "_id": 28926,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 28927,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 132,
            "שם_רחוב": "משכנות הרועים"
        },
        {
            "_id": 28928,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "משעול החלמיש"
        },
        {
            "_id": 28929,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 135,
            "שם_רחוב": "מתק חרוב"
        },
        {
            "_id": 28930,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "נוף בראשית"
        },
        {
            "_id": 28931,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "נופי פרת"
        },
        {
            "_id": 28932,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נחל אשלים"
        },
        {
            "_id": 28933,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 28934,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "נחל צאלים"
        },
        {
            "_id": 28935,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נחל קדם"
        },
        {
            "_id": 28936,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 159,
            "שם_רחוב": "ספר המדבר"
        },
        {
            "_id": 28937,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "סתר המדרגה"
        },
        {
            "_id": 28938,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 161,
            "שם_רחוב": "עיינות צוקים"
        },
        {
            "_id": 28939,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "עם אחד"
        },
        {
            "_id": 28940,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 157,
            "שם_רחוב": "ערבות הנחל"
        },
        {
            "_id": 28941,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 128,
            "שם_רחוב": "ערבות מואב"
        },
        {
            "_id": 28942,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 136,
            "שם_רחוב": "פלח הרימון"
        },
        {
            "_id": 28943,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 144,
            "שם_רחוב": "פרי הגפן"
        },
        {
            "_id": 28944,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 134,
            "שם_רחוב": "צמח בר"
        },
        {
            "_id": 28945,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 140,
            "שם_רחוב": "שירת העשבים"
        },
        {
            "_id": 28946,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ אלון"
        },
        {
            "_id": 28947,
            "סמל_ישוב": 3638,
            "שם_ישוב": "כפר אדומים ",
            "סמל_רחוב": 141,
            "שם_רחוב": "שתילי הזית"
        },
        {
            "_id": 28948,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "ארץ קדם"
        },
        {
            "_id": 28949,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך שמשון"
        },
        {
            "_id": 28950,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הבז האדום"
        },
        {
            "_id": 28951,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הגתות"
        },
        {
            "_id": 28952,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "החיוואי"
        },
        {
            "_id": 28953,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הירגזי"
        },
        {
            "_id": 28954,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הכחל"
        },
        {
            "_id": 28955,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "יערת הדבש"
        },
        {
            "_id": 28956,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "כפיר אריה"
        },
        {
            "_id": 28957,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר אוריה"
        },
        {
            "_id": 28958,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "כרם השועלים"
        },
        {
            "_id": 28959,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "כרמי תמנתה"
        },
        {
            "_id": 28960,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מעלה אוריה"
        },
        {
            "_id": 28961,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סלע עיטם"
        },
        {
            "_id": 28962,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "קציר חיטים"
        },
        {
            "_id": 28963,
            "סמל_ישוב": 364,
            "שם_ישוב": "כפר אוריה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שבט דן"
        },
        {
            "_id": 28964,
            "סמל_ישוב": 690,
            "שם_ישוב": "כפר אחים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר אחים"
        },
        {
            "_id": 28965,
            "סמל_ישוב": 690,
            "שם_ישוב": "כפר אחים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שכ קרוונים"
        },
        {
            "_id": 28966,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אושה"
        },
        {
            "_id": 28967,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 28968,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 102,
            "שם_רחוב": "החקלאים"
        },
        {
            "_id": 28969,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הכלניות"
        },
        {
            "_id": 28970,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר ביאליק"
        },
        {
            "_id": 28971,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 110,
            "שם_רחוב": "לנדאור"
        },
        {
            "_id": 28972,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מוסד אהבה"
        },
        {
            "_id": 28973,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 104,
            "שם_רחוב": "מיכאל"
        },
        {
            "_id": 28974,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סולד הנריאטה"
        },
        {
            "_id": 28975,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 113,
            "שם_רחוב": "עמיהוד"
        },
        {
            "_id": 28976,
            "סמל_ישוב": 220,
            "שם_ישוב": "כפר ביאליק ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שד ירושלים"
        },
        {
            "_id": 28977,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגבעה"
        },
        {
            "_id": 28978,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הדבש"
        },
        {
            "_id": 28979,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 28980,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 104,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 28981,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 28982,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 118,
            "שם_רחוב": "המחרשה"
        },
        {
            "_id": 28983,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 107,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 28984,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הפעמון"
        },
        {
            "_id": 28985,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הפקאן"
        },
        {
            "_id": 28986,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הפרדסים"
        },
        {
            "_id": 28987,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 28988,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 112,
            "שם_רחוב": "השעורה"
        },
        {
            "_id": 28989,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 113,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 28990,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 106,
            "שם_רחוב": "חבורת הדרום"
        },
        {
            "_id": 28991,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר בילו"
        },
        {
            "_id": 28992,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 101,
            "שם_רחוב": "סמ גפן"
        },
        {
            "_id": 28993,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 117,
            "שם_רחוב": "סמ חיטה"
        },
        {
            "_id": 28994,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 116,
            "שם_רחוב": "סמ רימון"
        },
        {
            "_id": 28995,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 115,
            "שם_רחוב": "סמ תמר"
        },
        {
            "_id": 28996,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שיכון בנים"
        },
        {
            "_id": 28997,
            "סמל_ישוב": 177,
            "שם_ישוב": "כפר בילו ",
            "סמל_רחוב": 114,
            "שם_רחוב": "תלמים"
        },
        {
            "_id": 28998,
            "סמל_ישוב": 357,
            "שם_ישוב": "כפר בלום ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר בלום"
        },
        {
            "_id": 28999,
            "סמל_ישוב": 2010,
            "שם_ישוב": "כפר בן נון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 29000,
            "סמל_ישוב": 2010,
            "שם_ישוב": "כפר בן נון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 29001,
            "סמל_ישוב": 2010,
            "שם_ישוב": "כפר בן נון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 29002,
            "סמל_ישוב": 2010,
            "שם_ישוב": "כפר בן נון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 29003,
            "סמל_ישוב": 2010,
            "שם_ישוב": "כפר בן נון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 29004,
            "סמל_ישוב": 2010,
            "שם_ישוב": "כפר בן נון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר בן נון"
        },
        {
            "_id": 29005,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אבו עובדיה"
        },
        {
            "_id": 29006,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 29007,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אבן סינא"
        },
        {
            "_id": 29008,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 29009,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלאקסא"
        },
        {
            "_id": 29010,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלבוסתאן"
        },
        {
            "_id": 29011,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלבוראק"
        },
        {
            "_id": 29012,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלביאן"
        },
        {
            "_id": 29013,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלג'זאלי"
        },
        {
            "_id": 29014,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלהודא"
        },
        {
            "_id": 29015,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אלוואדי"
        },
        {
            "_id": 29016,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלוויאם"
        },
        {
            "_id": 29017,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלזהרא"
        },
        {
            "_id": 29018,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 29019,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 122,
            "שם_רחוב": "אלחרם"
        },
        {
            "_id": 29020,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אלירמוק"
        },
        {
            "_id": 29021,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלמדינה"
        },
        {
            "_id": 29022,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלמנארה"
        },
        {
            "_id": 29023,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 29024,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 29025,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 138,
            "שם_רחוב": "אלפוראת"
        },
        {
            "_id": 29026,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אלקובה"
        },
        {
            "_id": 29027,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלקודס"
        },
        {
            "_id": 29028,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אלראזי"
        },
        {
            "_id": 29029,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אלרבאט"
        },
        {
            "_id": 29030,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלרוודה"
        },
        {
            "_id": 29031,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אלרחמה"
        },
        {
            "_id": 29032,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אלשאם"
        },
        {
            "_id": 29033,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 134,
            "שם_רחוב": "בגדאד"
        },
        {
            "_id": 29034,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 135,
            "שם_רחוב": "בדר"
        },
        {
            "_id": 29035,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 140,
            "שם_רחוב": "בילאל בן רבאח"
        },
        {
            "_id": 29036,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 139,
            "שם_רחוב": "דג'לה"
        },
        {
            "_id": 29037,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 116,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 29038,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 107,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 29039,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 108,
            "שם_רחוב": "יפו"
        },
        {
            "_id": 29040,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר ברא"
        },
        {
            "_id": 29041,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 118,
            "שם_רחוב": "מכה"
        },
        {
            "_id": 29042,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 136,
            "שם_רחוב": "עומר אלמוכתאר"
        },
        {
            "_id": 29043,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 109,
            "שם_רחוב": "עכו"
        },
        {
            "_id": 29044,
            "סמל_ישוב": 633,
            "שם_ישוב": "כפר ברא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "צלאח אלדין"
        },
        {
            "_id": 29045,
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אגמית"
        },
        {
            "_id": 29046,
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך ",
            "סמל_רחוב": 106,
            "שם_רחוב": "חופית"
        },
        {
            "_id": 29047,
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר ברוך"
        },
        {
            "_id": 29048,
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך ",
            "סמל_רחוב": 101,
            "שם_רחוב": "לבנית"
        },
        {
            "_id": 29049,
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מגלן"
        },
        {
            "_id": 29050,
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 29051,
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סיקסק"
        },
        {
            "_id": 29052,
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך ",
            "סמל_רחוב": 103,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 29053,
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך ",
            "סמל_רחוב": 102,
            "שם_רחוב": "עיטם"
        },
        {
            "_id": 29054,
            "סמל_ישוב": 132,
            "שם_ישוב": "כפר ברוך ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שקנאי"
        },
        {
            "_id": 29055,
            "סמל_ישוב": 106,
            "שם_ישוב": "כפר גדעון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 29056,
            "סמל_ישוב": 106,
            "שם_ישוב": "כפר גדעון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 29057,
            "סמל_ישוב": 106,
            "שם_ישוב": "כפר גדעון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "זית"
        },
        {
            "_id": 29058,
            "סמל_ישוב": 106,
            "שם_ישוב": "כפר גדעון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "חיטה"
        },
        {
            "_id": 29059,
            "סמל_ישוב": 106,
            "שם_ישוב": "כפר גדעון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר גדעון"
        },
        {
            "_id": 29060,
            "סמל_ישוב": 106,
            "שם_ישוב": "כפר גדעון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 29061,
            "סמל_ישוב": 106,
            "שם_ישוב": "כפר גדעון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שעורה"
        },
        {
            "_id": 29062,
            "סמל_ישוב": 106,
            "שם_ישוב": "כפר גדעון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 29063,
            "סמל_ישוב": 106,
            "שם_ישוב": "כפר גדעון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 29064,
            "סמל_ישוב": 427,
            "שם_ישוב": "כפר גלים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר גלים"
        },
        {
            "_id": 29065,
            "סמל_ישוב": 310,
            "שם_ישוב": "כפר גליקסון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר גליקסון"
        },
        {
            "_id": 29066,
            "סמל_ישוב": 76,
            "שם_ישוב": "כפר גלעדי ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון"
        },
        {
            "_id": 29067,
            "סמל_ישוב": 76,
            "שם_ישוב": "כפר גלעדי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר גלעדי"
        },
        {
            "_id": 29068,
            "סמל_ישוב": 76,
            "שם_ישוב": "כפר גלעדי ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 29069,
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 29070,
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 29071,
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "זית"
        },
        {
            "_id": 29072,
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 29073,
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר דניאל"
        },
        {
            "_id": 29074,
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מייסדים"
        },
        {
            "_id": 29075,
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 29076,
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 29077,
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 29078,
            "סמל_ישוב": 707,
            "שם_ישוב": "כפר דניאל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 29079,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אהוד"
        },
        {
            "_id": 29080,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האופק"
        },
        {
            "_id": 29081,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 29082,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הגבעה"
        },
        {
            "_id": 29083,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 29084,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "היער"
        },
        {
            "_id": 29085,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "המנורה"
        },
        {
            "_id": 29086,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "המעיין"
        },
        {
            "_id": 29087,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 29088,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 29089,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הרכס"
        },
        {
            "_id": 29090,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 29091,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "יפה נוף"
        },
        {
            "_id": 29092,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר האורנים"
        },
        {
            "_id": 29093,
            "סמל_ישוב": 3796,
            "שם_ישוב": "כפר האורנים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "נחל מודיעין"
        },
        {
            "_id": 29094,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אילה"
        },
        {
            "_id": 29095,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אפיק"
        },
        {
            "_id": 29096,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אשחר"
        },
        {
            "_id": 29097,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 107,
            "שם_רחוב": "גורדוניה"
        },
        {
            "_id": 29098,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 29099,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 108,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 29100,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 114,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 29101,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 105,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 29102,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר החורש"
        },
        {
            "_id": 29103,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 103,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 29104,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 121,
            "שם_רחוב": "לאן"
        },
        {
            "_id": 29105,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מגורונים"
        },
        {
            "_id": 29106,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 116,
            "שם_רחוב": "מהלך"
        },
        {
            "_id": 29107,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מכבי הצעיר"
        },
        {
            "_id": 29108,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 111,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 29109,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 112,
            "שם_רחוב": "עופר"
        },
        {
            "_id": 29110,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 117,
            "שם_רחוב": "עמיעז"
        },
        {
            "_id": 29111,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 119,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 29112,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 110,
            "שם_רחוב": "צבר"
        },
        {
            "_id": 29113,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 120,
            "שם_רחוב": "קינן"
        },
        {
            "_id": 29114,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 29115,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 122,
            "שם_רחוב": "שש"
        },
        {
            "_id": 29116,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 123,
            "שם_רחוב": "תוכי נשר"
        },
        {
            "_id": 29117,
            "סמל_ישוב": 192,
            "שם_ישוב": "כפר החורש ",
            "סמל_רחוב": 115,
            "שם_רחוב": "תרצה"
        },
        {
            "_id": 29118,
            "סמל_ישוב": 254,
            "שם_ישוב": "כפר המכבי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר המכבי"
        },
        {
            "_id": 29119,
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 29120,
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד ",
            "סמל_רחוב": 105,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 29121,
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 29122,
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 29123,
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 29124,
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 29125,
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 29126,
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד ",
            "סמל_רחוב": 108,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 29127,
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד ",
            "סמל_רחוב": 109,
            "שם_רחוב": "השיקמה"
        },
        {
            "_id": 29128,
            "סמל_ישוב": 582,
            "שם_ישוב": "כפר הנגיד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר הנגיד"
        },
        {
            "_id": 29129,
            "סמל_ישוב": 890,
            "שם_ישוב": "כפר הנוער הדתי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר הנוער הדתי"
        },
        {
            "_id": 29130,
            "סמל_ישוב": 443,
            "שם_ישוב": "כפר הנשיא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר הנשיא"
        },
        {
            "_id": 29131,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ארגון יזרעאל"
        },
        {
            "_id": 29132,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך ההרחבה"
        },
        {
            "_id": 29133,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך ההתיישבות"
        },
        {
            "_id": 29134,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך השדות"
        },
        {
            "_id": 29135,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 114,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 29136,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 29137,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 29138,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הבריכה"
        },
        {
            "_id": 29139,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 29140,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 29141,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הזיתים"
        },
        {
            "_id": 29142,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 29143,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 105,
            "שם_רחוב": "המחרשה"
        },
        {
            "_id": 29144,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 108,
            "שם_רחוב": "העמדה"
        },
        {
            "_id": 29145,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הקוצר"
        },
        {
            "_id": 29146,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הקטיף"
        },
        {
            "_id": 29147,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 107,
            "שם_רחוב": "השדרה"
        },
        {
            "_id": 29148,
            "סמל_ישוב": 187,
            "שם_ישוב": "כפר הס ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר הס"
        },
        {
            "_id": 29149,
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הראה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "בית חזון"
        },
        {
            "_id": 29150,
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הראה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך תורה ועבודה"
        },
        {
            "_id": 29151,
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הראה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הישיבה"
        },
        {
            "_id": 29152,
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הראה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 29153,
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הראה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 29154,
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הראה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הרב צוקרמן"
        },
        {
            "_id": 29155,
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הראה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "חזון"
        },
        {
            "_id": 29156,
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הראה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ישיבת בני עקיבא"
        },
        {
            "_id": 29157,
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הראה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר הראה"
        },
        {
            "_id": 29158,
            "סמל_ישוב": 217,
            "שם_ישוב": "כפר הראה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "משעול הגבעה"
        },
        {
            "_id": 29159,
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הריף ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 29160,
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הריף ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 29161,
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הריף ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "הרחבה"
        },
        {
            "_id": 29162,
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הריף ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 29163,
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הריף ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 29164,
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הריף ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 29165,
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הריף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר הריף"
        },
        {
            "_id": 29166,
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הריף ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סמטת החרוב"
        },
        {
            "_id": 29167,
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הריף ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סמטת התאנה"
        },
        {
            "_id": 29168,
            "סמל_ישוב": 888,
            "שם_ישוב": "כפר הריף ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שדרת האילנות"
        },
        {
            "_id": 29169,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אכסנית נוער"
        },
        {
            "_id": 29170,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 122,
            "שם_רחוב": "דרך האי"
        },
        {
            "_id": 29171,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 125,
            "שם_רחוב": "דרך הבאר"
        },
        {
            "_id": 29172,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דרך החול"
        },
        {
            "_id": 29173,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 124,
            "שם_רחוב": "דרך הכפר"
        },
        {
            "_id": 29174,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דרך הנחל"
        },
        {
            "_id": 29175,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 129,
            "שם_רחוב": "דרך מול הים"
        },
        {
            "_id": 29176,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הדואר"
        },
        {
            "_id": 29177,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הדוגית"
        },
        {
            "_id": 29178,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הזנב"
        },
        {
            "_id": 29179,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הלגונה"
        },
        {
            "_id": 29180,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 105,
            "שם_רחוב": "המגדלור"
        },
        {
            "_id": 29181,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 106,
            "שם_רחוב": "המזח"
        },
        {
            "_id": 29182,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 127,
            "שם_רחוב": "המחלבה"
        },
        {
            "_id": 29183,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 107,
            "שם_רחוב": "המפרש"
        },
        {
            "_id": 29184,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הסיפון"
        },
        {
            "_id": 29185,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הסירה"
        },
        {
            "_id": 29186,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 102,
            "שם_רחוב": "העוגן"
        },
        {
            "_id": 29187,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הצדף"
        },
        {
            "_id": 29188,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הקונכיה"
        },
        {
            "_id": 29189,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 29190,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 112,
            "שם_רחוב": "השונית"
        },
        {
            "_id": 29191,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 123,
            "שם_רחוב": "כביש עוקף דרום"
        },
        {
            "_id": 29192,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר ויתקין"
        },
        {
            "_id": 29193,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 128,
            "שם_רחוב": "משעול גנים"
        },
        {
            "_id": 29194,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 130,
            "שם_רחוב": "משעול הפרדסים"
        },
        {
            "_id": 29195,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 131,
            "שם_רחוב": "סמ המרפאה"
        },
        {
            "_id": 29196,
            "סמל_ישוב": 190,
            "שם_ישוב": "כפר ויתקין ",
            "סמל_רחוב": 117,
            "שם_רחוב": "סמטת הכד"
        },
        {
            "_id": 29197,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הבאר"
        },
        {
            "_id": 29198,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 29199,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הגן"
        },
        {
            "_id": 29200,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ההרחבה"
        },
        {
            "_id": 29201,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הזורעים"
        },
        {
            "_id": 29202,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 107,
            "שם_רחוב": "המחלבה"
        },
        {
            "_id": 29203,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 108,
            "שם_רחוב": "המחרשה"
        },
        {
            "_id": 29204,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 109,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 29205,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הממשיכים"
        },
        {
            "_id": 29206,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 111,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 29207,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר ורבורג"
        },
        {
            "_id": 29208,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 120,
            "שם_רחוב": "סמ האפרסמון"
        },
        {
            "_id": 29209,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 121,
            "שם_רחוב": "סמ האפרסק"
        },
        {
            "_id": 29210,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 122,
            "שם_רחוב": "סמ הגפן"
        },
        {
            "_id": 29211,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סמ הדגן"
        },
        {
            "_id": 29212,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 124,
            "שם_רחוב": "סמ הזית"
        },
        {
            "_id": 29213,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 125,
            "שם_רחוב": "סמ החיטה"
        },
        {
            "_id": 29214,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 126,
            "שם_רחוב": "סמ החרוב"
        },
        {
            "_id": 29215,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 127,
            "שם_רחוב": "סמ הרימון"
        },
        {
            "_id": 29216,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 128,
            "שם_רחוב": "סמ השיבולת"
        },
        {
            "_id": 29217,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 129,
            "שם_רחוב": "סמ השעורה"
        },
        {
            "_id": 29218,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 130,
            "שם_רחוב": "סמ התאנה"
        },
        {
            "_id": 29219,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 131,
            "שם_רחוב": "סמ התבואה"
        },
        {
            "_id": 29220,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 132,
            "שם_רחוב": "סמ התפוח"
        },
        {
            "_id": 29221,
            "סמל_ישוב": 320,
            "שם_ישוב": "כפר ורבורג ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שכ קרוונים"
        },
        {
            "_id": 29222,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אבני החושן"
        },
        {
            "_id": 29223,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אדיר"
        },
        {
            "_id": 29224,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 29225,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 29226,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 29227,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 142,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 29228,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אשכר"
        },
        {
            "_id": 29229,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 194,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 29230,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 180,
            "שם_רחוב": "בשור"
        },
        {
            "_id": 29231,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 144,
            "שם_רחוב": "גומא"
        },
        {
            "_id": 29232,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "גל"
        },
        {
            "_id": 29233,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 160,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 29234,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 176,
            "שם_רחוב": "געתון"
        },
        {
            "_id": 29235,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 133,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 29236,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 172,
            "שם_רחוב": "דישון"
        },
        {
            "_id": 29237,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 29238,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 156,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 29239,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 136,
            "שם_רחוב": "דרך הגליל"
        },
        {
            "_id": 29240,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 126,
            "שם_רחוב": "דרך ההר"
        },
        {
            "_id": 29241,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 186,
            "שם_רחוב": "דרך הורדים"
        },
        {
            "_id": 29242,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 29243,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 29244,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 29245,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 29246,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הדולב"
        },
        {
            "_id": 29247,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 148,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 29248,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 29249,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 29250,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 143,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 29251,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 174,
            "שם_רחוב": "זויתן"
        },
        {
            "_id": 29252,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 130,
            "שם_רחוב": "זית"
        },
        {
            "_id": 29253,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 169,
            "שם_רחוב": "זמורה"
        },
        {
            "_id": 29254,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 155,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 29255,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 150,
            "שם_רחוב": "חבצלת"
        },
        {
            "_id": 29256,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 153,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 29257,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 128,
            "שם_רחוב": "חולית"
        },
        {
            "_id": 29258,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 134,
            "שם_רחוב": "חיטה"
        },
        {
            "_id": 29259,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 125,
            "שם_רחוב": "חירם"
        },
        {
            "_id": 29260,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 29261,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 29262,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 190,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 29263,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 29264,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 149,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 29265,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 173,
            "שם_רחוב": "ירדן"
        },
        {
            "_id": 29266,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 171,
            "שם_רחוב": "כזיב"
        },
        {
            "_id": 29267,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר ורדים"
        },
        {
            "_id": 29268,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 168,
            "שם_רחוב": "כרם"
        },
        {
            "_id": 29269,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 147,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 29270,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 167,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 29271,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 141,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 29272,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 195,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 29273,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 177,
            "שם_רחוב": "מוסקט"
        },
        {
            "_id": 29274,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 166,
            "שם_רחוב": "מור"
        },
        {
            "_id": 29275,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 29276,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 161,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 29277,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 29278,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "משעול הכלנית"
        },
        {
            "_id": 29279,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "משעול הכרכום"
        },
        {
            "_id": 29280,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "משעול הנורית"
        },
        {
            "_id": 29281,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "משעול הנרקיס"
        },
        {
            "_id": 29282,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "משעול הסחלב"
        },
        {
            "_id": 29283,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "משעול הרקפת"
        },
        {
            "_id": 29284,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 146,
            "שם_רחוב": "משעול ורד"
        },
        {
            "_id": 29285,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 189,
            "שם_רחוב": "נופך"
        },
        {
            "_id": 29286,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 175,
            "שם_רחוב": "נעמן"
        },
        {
            "_id": 29287,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 170,
            "שם_רחוב": "סער"
        },
        {
            "_id": 29288,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 192,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 29289,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 181,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 29290,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 154,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 29291,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 145,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 29292,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 184,
            "שם_רחוב": "ערוגות"
        },
        {
            "_id": 29293,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 185,
            "שם_רחוב": "פארן"
        },
        {
            "_id": 29294,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 183,
            "שם_רחוב": "צאלים"
        },
        {
            "_id": 29295,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 182,
            "שם_רחוב": "צין"
        },
        {
            "_id": 29296,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 165,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 29297,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 29298,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 164,
            "שם_רחוב": "ריחן"
        },
        {
            "_id": 29299,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 29300,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 188,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 29301,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 127,
            "שם_רחוב": "שונית"
        },
        {
            "_id": 29302,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 163,
            "שם_רחוב": "שמיר"
        },
        {
            "_id": 29303,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 29304,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 151,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 29305,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 178,
            "שם_רחוב": "שריג"
        },
        {
            "_id": 29306,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 131,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 29307,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 152,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 29308,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 135,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 29309,
            "סמל_ישוב": 1263,
            "שם_ישוב": "כפר ורדים ",
            "סמל_רחוב": 193,
            "שם_רחוב": "תרשיש"
        },
        {
            "_id": 29310,
            "סמל_ישוב": 1325,
            "שם_ישוב": "כפר זוהרים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר זוהרים"
        },
        {
            "_id": 29311,
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך השלום"
        },
        {
            "_id": 29312,
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האירוסים"
        },
        {
            "_id": 29313,
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 29314,
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ההדסים"
        },
        {
            "_id": 29315,
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 29316,
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "כוכב יאיר"
        },
        {
            "_id": 29317,
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר זיתים"
        },
        {
            "_id": 29318,
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "משעול הזית"
        },
        {
            "_id": 29319,
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "נוף הצוק"
        },
        {
            "_id": 29320,
            "סמל_ישוב": 786,
            "שם_ישוב": "כפר זיתים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 29321,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אגרות קודש"
        },
        {
            "_id": 29322,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אדמור האמצעי"
        },
        {
            "_id": 29323,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אדמור הזקן"
        },
        {
            "_id": 29324,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אדמור הצמח-צדק"
        },
        {
            "_id": 29325,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אדמור הרייצ"
        },
        {
            "_id": 29326,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אדמור הרשב"
        },
        {
            "_id": 29327,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אדמור מהרש"
        },
        {
            "_id": 29328,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אור התורה"
        },
        {
            "_id": 29329,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אמרי בינה"
        },
        {
            "_id": 29330,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 138,
            "שם_רחוב": "באתי לגני"
        },
        {
            "_id": 29331,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דבר מלכות"
        },
        {
            "_id": 29332,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 134,
            "שם_רחוב": "דידן נצח"
        },
        {
            "_id": 29333,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך חיים"
        },
        {
            "_id": 29334,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך מצותיך"
        },
        {
            "_id": 29335,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הבעל שם טוב"
        },
        {
            "_id": 29336,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 120,
            "שם_רחוב": "הגאולה"
        },
        {
            "_id": 29337,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 130,
            "שם_רחוב": "המגיד ממעזריטש"
        },
        {
            "_id": 29338,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 131,
            "שם_רחוב": "הרב מרדכי ש. אשכנזי"
        },
        {
            "_id": 29339,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הרב נחום טרבניק"
        },
        {
            "_id": 29340,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 124,
            "שם_רחוב": "הרב שניאור ז גורליק"
        },
        {
            "_id": 29341,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הרבי מליובאוויטש"
        },
        {
            "_id": 29342,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 141,
            "שם_רחוב": "הרבנית חיה מושקא"
        },
        {
            "_id": 29343,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הרבנית חנה"
        },
        {
            "_id": 29344,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 121,
            "שם_רחוב": "השליחות"
        },
        {
            "_id": 29345,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 142,
            "שם_רחוב": "חנוך לנער"
        },
        {
            "_id": 29346,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "כיכר מבצע חינוך"
        },
        {
            "_id": 29347,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "כיכר מבצע צדקה"
        },
        {
            "_id": 29348,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "כיכר מבצע תורה"
        },
        {
            "_id": 29349,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "כיכר מבצע תפילין"
        },
        {
            "_id": 29350,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר נרות שבת קודש"
        },
        {
            "_id": 29351,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר חבד"
        },
        {
            "_id": 29352,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 143,
            "שם_רחוב": "לוח היום יום"
        },
        {
            "_id": 29353,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 111,
            "שם_רחוב": "לימוד החסידות"
        },
        {
            "_id": 29354,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 116,
            "שם_רחוב": "לקוטי דיבורים"
        },
        {
            "_id": 29355,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 110,
            "שם_רחוב": "לקוטי שיחות"
        },
        {
            "_id": 29356,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 139,
            "שם_רחוב": "לקוטי תורה"
        },
        {
            "_id": 29357,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 135,
            "שם_רחוב": "מעלה 770"
        },
        {
            "_id": 29358,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סו"
        },
        {
            "_id": 29359,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 115,
            "שם_רחוב": "ספר הזכרונות"
        },
        {
            "_id": 29360,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 137,
            "שם_רחוב": "ספר התניא"
        },
        {
            "_id": 29361,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 106,
            "שם_רחוב": "עב"
        },
        {
            "_id": 29362,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 118,
            "שם_רחוב": "עטרת ראש"
        },
        {
            "_id": 29363,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 102,
            "שם_רחוב": "עץ החיים"
        },
        {
            "_id": 29364,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 133,
            "שם_רחוב": "צעירי חבד"
        },
        {
            "_id": 29365,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רבי לוי יצחק"
        },
        {
            "_id": 29366,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שיכונים חדשים"
        },
        {
            "_id": 29367,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "שכ לוי יצחק"
        },
        {
            "_id": 29368,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שערי אורה"
        },
        {
            "_id": 29369,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שערי תשובה"
        },
        {
            "_id": 29370,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "תוחלת"
        },
        {
            "_id": 29371,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 136,
            "שם_רחוב": "תורת חיים"
        },
        {
            "_id": 29372,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 145,
            "שם_רחוב": "תורת מנחם"
        },
        {
            "_id": 29373,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 104,
            "שם_רחוב": "תורת שלום"
        },
        {
            "_id": 29374,
            "סמל_ישוב": 696,
            "שם_ישוב": "כפר חבד ",
            "סמל_רחוב": 113,
            "שם_רחוב": "תורת שמואל"
        },
        {
            "_id": 29375,
            "סמל_ישוב": 609,
            "שם_ישוב": "כפר חושן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר חושן"
        },
        {
            "_id": 29376,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 111,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 29377,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 29378,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 29379,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 29380,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האסיף"
        },
        {
            "_id": 29381,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 29382,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 29383,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 113,
            "שם_רחוב": "החיטה"
        },
        {
            "_id": 29384,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 115,
            "שם_רחוב": "החמניה"
        },
        {
            "_id": 29385,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 124,
            "שם_רחוב": "המגל"
        },
        {
            "_id": 29386,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הקוצר"
        },
        {
            "_id": 29387,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 119,
            "שם_רחוב": "השדה"
        },
        {
            "_id": 29388,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 125,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 29389,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 117,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 29390,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר חיטים"
        },
        {
            "_id": 29391,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 121,
            "שם_רחוב": "מגדל המים"
        },
        {
            "_id": 29392,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 116,
            "שם_רחוב": "נבטים"
        },
        {
            "_id": 29393,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 104,
            "שם_רחוב": "נביעות"
        },
        {
            "_id": 29394,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רימונים"
        },
        {
            "_id": 29395,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שיבולים"
        },
        {
            "_id": 29396,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שעורה"
        },
        {
            "_id": 29397,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 123,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 29398,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שקדיה"
        },
        {
            "_id": 29399,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 109,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 29400,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 114,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 29401,
            "סמל_ישוב": 255,
            "שם_ישוב": "כפר חיטים ",
            "סמל_רחוב": 122,
            "שם_רחוב": "תנובת השדה"
        },
        {
            "_id": 29402,
            "סמל_ישוב": 193,
            "שם_ישוב": "כפר חיים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר חיים"
        },
        {
            "_id": 29403,
            "סמל_ישוב": 1297,
            "שם_ישוב": "כפר חנניה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר חנניה"
        },
        {
            "_id": 29404,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 109,
            "שם_רחוב": "בית קמה"
        },
        {
            "_id": 29405,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 113,
            "שם_רחוב": "גבורי התהילה"
        },
        {
            "_id": 29406,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 105,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 29407,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הפועל המזרחי"
        },
        {
            "_id": 29408,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 106,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 29409,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר חסידים א'"
        },
        {
            "_id": 29410,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 107,
            "שם_רחוב": "נחל קדומים"
        },
        {
            "_id": 29411,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 108,
            "שם_רחוב": "נחלת יעקב"
        },
        {
            "_id": 29412,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 111,
            "שם_רחוב": "עבודת ישראל"
        },
        {
            "_id": 29413,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 112,
            "שם_רחוב": "עלית הנוער"
        },
        {
            "_id": 29414,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש המזרחי"
        },
        {
            "_id": 29415,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "ש משכנות"
        },
        {
            "_id": 29416,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 29417,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש רסקו"
        },
        {
            "_id": 29418,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שערי תורה"
        },
        {
            "_id": 29419,
            "סמל_ישוב": 112,
            "שם_ישוב": "כפר חסידים א' ",
            "סמל_רחוב": 115,
            "שם_רחוב": "תל רגב"
        },
        {
            "_id": 29420,
            "סמל_ישוב": 889,
            "שם_ישוב": "כפר חסידים ב' ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר חסידים ב'"
        },
        {
            "_id": 29421,
            "סמל_ישוב": 4004,
            "שם_ישוב": "כפר חרוב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר חרוב"
        },
        {
            "_id": 29422,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אקליפטוס"
        },
        {
            "_id": 29423,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 29424,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 29425,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 29426,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 29427,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 29428,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 29429,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 105,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 29430,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 109,
            "שם_רחוב": "העינב"
        },
        {
            "_id": 29431,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "הרחבה"
        },
        {
            "_id": 29432,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 29433,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 110,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 29434,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 107,
            "שם_רחוב": "זמורות"
        },
        {
            "_id": 29435,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר טרומן"
        },
        {
            "_id": 29436,
            "סמל_ישוב": 673,
            "שם_ישוב": "כפר טרומן ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שריגים"
        },
        {
            "_id": 29437,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר יאסיף"
        },
        {
            "_id": 29438,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 102,
            "שם_רחוב": "רח 100"
        },
        {
            "_id": 29439,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 202,
            "שם_רחוב": "רח 200"
        },
        {
            "_id": 29440,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רח 110"
        },
        {
            "_id": 29441,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 212,
            "שם_רחוב": "רח 210"
        },
        {
            "_id": 29442,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 122,
            "שם_רחוב": "רח 120"
        },
        {
            "_id": 29443,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 218,
            "שם_רחוב": "רח 220"
        },
        {
            "_id": 29444,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 132,
            "שם_רחוב": "רח 130"
        },
        {
            "_id": 29445,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 223,
            "שם_רחוב": "רח 230"
        },
        {
            "_id": 29446,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 142,
            "שם_רחוב": "רח 140"
        },
        {
            "_id": 29447,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 152,
            "שם_רחוב": "רח 150"
        },
        {
            "_id": 29448,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 162,
            "שם_רחוב": "רח 160"
        },
        {
            "_id": 29449,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 101,
            "שם_רחוב": "רח 70"
        },
        {
            "_id": 29450,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 172,
            "שם_רחוב": "רח 170"
        },
        {
            "_id": 29451,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 182,
            "שם_רחוב": "רח 180"
        },
        {
            "_id": 29452,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 192,
            "שם_רחוב": "רח 190"
        },
        {
            "_id": 29453,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 103,
            "שם_רחוב": "רח 101"
        },
        {
            "_id": 29454,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 203,
            "שם_רחוב": "רח 201"
        },
        {
            "_id": 29455,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רח 111"
        },
        {
            "_id": 29456,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 213,
            "שם_רחוב": "רח 211"
        },
        {
            "_id": 29457,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רח 121"
        },
        {
            "_id": 29458,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 133,
            "שם_רחוב": "רח 131"
        },
        {
            "_id": 29459,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 143,
            "שם_רחוב": "רח 141"
        },
        {
            "_id": 29460,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 153,
            "שם_רחוב": "רח 151"
        },
        {
            "_id": 29461,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 163,
            "שם_רחוב": "רח 161"
        },
        {
            "_id": 29462,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 173,
            "שם_רחוב": "רח 171"
        },
        {
            "_id": 29463,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 183,
            "שם_רחוב": "רח 181"
        },
        {
            "_id": 29464,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 193,
            "שם_רחוב": "רח 191"
        },
        {
            "_id": 29465,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רח 102"
        },
        {
            "_id": 29466,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 204,
            "שם_רחוב": "רח 202"
        },
        {
            "_id": 29467,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 114,
            "שם_רחוב": "רח 112"
        },
        {
            "_id": 29468,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 214,
            "שם_רחוב": "רח 212"
        },
        {
            "_id": 29469,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 124,
            "שם_רחוב": "רח 122"
        },
        {
            "_id": 29470,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 219,
            "שם_רחוב": "רח 222"
        },
        {
            "_id": 29471,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 134,
            "שם_רחוב": "רח 132"
        },
        {
            "_id": 29472,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 144,
            "שם_רחוב": "רח 142"
        },
        {
            "_id": 29473,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 154,
            "שם_רחוב": "רח 152"
        },
        {
            "_id": 29474,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 164,
            "שם_רחוב": "רח 162"
        },
        {
            "_id": 29475,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 174,
            "שם_רחוב": "רח 172"
        },
        {
            "_id": 29476,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 184,
            "שם_רחוב": "רח 182"
        },
        {
            "_id": 29477,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 194,
            "שם_רחוב": "רח 192"
        },
        {
            "_id": 29478,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 105,
            "שם_רחוב": "רח 103"
        },
        {
            "_id": 29479,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 205,
            "שם_רחוב": "רח 203"
        },
        {
            "_id": 29480,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רח 113"
        },
        {
            "_id": 29481,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 125,
            "שם_רחוב": "רח 123"
        },
        {
            "_id": 29482,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 135,
            "שם_רחוב": "רח 133"
        },
        {
            "_id": 29483,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 145,
            "שם_רחוב": "רח 143"
        },
        {
            "_id": 29484,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 155,
            "שם_רחוב": "רח 153"
        },
        {
            "_id": 29485,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 165,
            "שם_רחוב": "רח 163"
        },
        {
            "_id": 29486,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 175,
            "שם_רחוב": "רח 173"
        },
        {
            "_id": 29487,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 185,
            "שם_רחוב": "רח 183"
        },
        {
            "_id": 29488,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 195,
            "שם_רחוב": "רח 193"
        },
        {
            "_id": 29489,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 106,
            "שם_רחוב": "רח 104"
        },
        {
            "_id": 29490,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 206,
            "שם_רחוב": "רח 204"
        },
        {
            "_id": 29491,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 116,
            "שם_רחוב": "רח 114"
        },
        {
            "_id": 29492,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 215,
            "שם_רחוב": "רח 214"
        },
        {
            "_id": 29493,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 126,
            "שם_רחוב": "רח 124"
        },
        {
            "_id": 29494,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 220,
            "שם_רחוב": "רח 224"
        },
        {
            "_id": 29495,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 136,
            "שם_רחוב": "רח 134"
        },
        {
            "_id": 29496,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 146,
            "שם_רחוב": "רח 144"
        },
        {
            "_id": 29497,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 156,
            "שם_רחוב": "רח 154"
        },
        {
            "_id": 29498,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 166,
            "שם_רחוב": "רח 164"
        },
        {
            "_id": 29499,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 176,
            "שם_רחוב": "רח 174"
        },
        {
            "_id": 29500,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 186,
            "שם_רחוב": "רח 184"
        },
        {
            "_id": 29501,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 196,
            "שם_רחוב": "רח 194"
        },
        {
            "_id": 29502,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רח 105"
        },
        {
            "_id": 29503,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 207,
            "שם_רחוב": "רח 205"
        },
        {
            "_id": 29504,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 117,
            "שם_רחוב": "רח 115"
        },
        {
            "_id": 29505,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 127,
            "שם_רחוב": "רח 125"
        },
        {
            "_id": 29506,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 137,
            "שם_רחוב": "רח 135"
        },
        {
            "_id": 29507,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 147,
            "שם_רחוב": "רח 145"
        },
        {
            "_id": 29508,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 157,
            "שם_רחוב": "רח 155"
        },
        {
            "_id": 29509,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 167,
            "שם_רחוב": "רח 165"
        },
        {
            "_id": 29510,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 177,
            "שם_רחוב": "רח 175"
        },
        {
            "_id": 29511,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 187,
            "שם_רחוב": "רח 185"
        },
        {
            "_id": 29512,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 197,
            "שם_רחוב": "רח 195"
        },
        {
            "_id": 29513,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 108,
            "שם_רחוב": "רח 106"
        },
        {
            "_id": 29514,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 208,
            "שם_רחוב": "רח 206"
        },
        {
            "_id": 29515,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רח 116"
        },
        {
            "_id": 29516,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 216,
            "שם_רחוב": "רח 216"
        },
        {
            "_id": 29517,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 128,
            "שם_רחוב": "רח 126"
        },
        {
            "_id": 29518,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 221,
            "שם_רחוב": "רח 226"
        },
        {
            "_id": 29519,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 138,
            "שם_רחוב": "רח 136"
        },
        {
            "_id": 29520,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 148,
            "שם_רחוב": "רח 146"
        },
        {
            "_id": 29521,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 158,
            "שם_רחוב": "רח 156"
        },
        {
            "_id": 29522,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 168,
            "שם_רחוב": "רח 166"
        },
        {
            "_id": 29523,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 178,
            "שם_רחוב": "רח 176"
        },
        {
            "_id": 29524,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 188,
            "שם_רחוב": "רח 186"
        },
        {
            "_id": 29525,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 198,
            "שם_רחוב": "רח 196"
        },
        {
            "_id": 29526,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 109,
            "שם_רחוב": "רח 107"
        },
        {
            "_id": 29527,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 209,
            "שם_רחוב": "רח 207"
        },
        {
            "_id": 29528,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 118,
            "שם_רחוב": "רח 117"
        },
        {
            "_id": 29529,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 129,
            "שם_רחוב": "רח 127"
        },
        {
            "_id": 29530,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 139,
            "שם_רחוב": "רח 137"
        },
        {
            "_id": 29531,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 149,
            "שם_רחוב": "רח 147"
        },
        {
            "_id": 29532,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 159,
            "שם_רחוב": "רח 157"
        },
        {
            "_id": 29533,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 169,
            "שם_רחוב": "רח 167"
        },
        {
            "_id": 29534,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 179,
            "שם_רחוב": "רח 177"
        },
        {
            "_id": 29535,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 189,
            "שם_רחוב": "רח 187"
        },
        {
            "_id": 29536,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 199,
            "שם_רחוב": "רח 197"
        },
        {
            "_id": 29537,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 110,
            "שם_רחוב": "רח 108"
        },
        {
            "_id": 29538,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 210,
            "שם_רחוב": "רח 208"
        },
        {
            "_id": 29539,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 120,
            "שם_רחוב": "רח 118"
        },
        {
            "_id": 29540,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 217,
            "שם_רחוב": "רח 218"
        },
        {
            "_id": 29541,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 130,
            "שם_רחוב": "רח 128"
        },
        {
            "_id": 29542,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 222,
            "שם_רחוב": "רח 228"
        },
        {
            "_id": 29543,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 140,
            "שם_רחוב": "רח 138"
        },
        {
            "_id": 29544,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 150,
            "שם_רחוב": "רח 148"
        },
        {
            "_id": 29545,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 160,
            "שם_רחוב": "רח 158"
        },
        {
            "_id": 29546,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 170,
            "שם_רחוב": "רח 168"
        },
        {
            "_id": 29547,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 180,
            "שם_רחוב": "רח 178"
        },
        {
            "_id": 29548,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 190,
            "שם_רחוב": "רח 188"
        },
        {
            "_id": 29549,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 200,
            "שם_רחוב": "רח 198"
        },
        {
            "_id": 29550,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 111,
            "שם_רחוב": "רח 109"
        },
        {
            "_id": 29551,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 211,
            "שם_רחוב": "רח 209"
        },
        {
            "_id": 29552,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 121,
            "שם_רחוב": "רח 119"
        },
        {
            "_id": 29553,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 131,
            "שם_רחוב": "רח 129"
        },
        {
            "_id": 29554,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 141,
            "שם_רחוב": "רח 139"
        },
        {
            "_id": 29555,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 151,
            "שם_רחוב": "רח 149"
        },
        {
            "_id": 29556,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 161,
            "שם_רחוב": "רח 159"
        },
        {
            "_id": 29557,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 171,
            "שם_רחוב": "רח 169"
        },
        {
            "_id": 29558,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 181,
            "שם_רחוב": "רח 179"
        },
        {
            "_id": 29559,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 191,
            "שם_רחוב": "רח 189"
        },
        {
            "_id": 29560,
            "סמל_ישוב": 507,
            "שם_ישוב": "כפר יאסיף ",
            "סמל_רחוב": 201,
            "שם_רחוב": "רח 199"
        },
        {
            "_id": 29561,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אביחיל"
        },
        {
            "_id": 29562,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "בית האריזה"
        },
        {
            "_id": 29563,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "בני גאולים"
        },
        {
            "_id": 29564,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "דרך המשולש"
        },
        {
            "_id": 29565,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 29566,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 29567,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הזורע"
        },
        {
            "_id": 29568,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 29569,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הפיקוסים"
        },
        {
            "_id": 29570,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 29571,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הפרסה"
        },
        {
            "_id": 29572,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 29573,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "חישתיל"
        },
        {
            "_id": 29574,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר ידידיה"
        },
        {
            "_id": 29575,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מול הים"
        },
        {
            "_id": 29576,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "על עבודת האדמה"
        },
        {
            "_id": 29577,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "עמק חפר"
        },
        {
            "_id": 29578,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "צבעוני השרון"
        },
        {
            "_id": 29579,
            "סמל_ישוב": 233,
            "שם_ישוב": "כפר ידידיה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שער הכפר"
        },
        {
            "_id": 29580,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 29581,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 29582,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אסיף"
        },
        {
            "_id": 29583,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 126,
            "שם_רחוב": "בעלי המלאכה"
        },
        {
            "_id": 29584,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 117,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 29585,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 118,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 29586,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 115,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 29587,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך בית העם"
        },
        {
            "_id": 29588,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך המייסדים"
        },
        {
            "_id": 29589,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך הפרדס"
        },
        {
            "_id": 29590,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך הרכבת"
        },
        {
            "_id": 29591,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך התבואה"
        },
        {
            "_id": 29592,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 112,
            "שם_רחוב": "דרך התיישבות בנים"
        },
        {
            "_id": 29593,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 29594,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הגנים"
        },
        {
            "_id": 29595,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הדקלים"
        },
        {
            "_id": 29596,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 116,
            "שם_רחוב": "זית"
        },
        {
            "_id": 29597,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר יהושע"
        },
        {
            "_id": 29598,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 125,
            "שם_רחוב": "מגל"
        },
        {
            "_id": 29599,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 123,
            "שם_רחוב": "סמטת החיטה"
        },
        {
            "_id": 29600,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 109,
            "שם_רחוב": "סמטת התמר"
        },
        {
            "_id": 29601,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 111,
            "שם_רחוב": "סמטת חרמש"
        },
        {
            "_id": 29602,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 124,
            "שם_רחוב": "עומר"
        },
        {
            "_id": 29603,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 120,
            "שם_רחוב": "פקאן"
        },
        {
            "_id": 29604,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 122,
            "שם_רחוב": "קציר"
        },
        {
            "_id": 29605,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 119,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 29606,
            "סמל_ישוב": 140,
            "שם_ישוב": "כפר יהושע ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שדרת מגדל המים"
        },
        {
            "_id": 29607,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 29608,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 305,
            "שם_רחוב": "אהבת אדם"
        },
        {
            "_id": 29609,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אהוד מנור"
        },
        {
            "_id": 29610,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 275,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 29611,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אחי אילת"
        },
        {
            "_id": 29612,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אידה יהודית זידלר"
        },
        {
            "_id": 29613,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 187,
            "שם_רחוב": "איזמרגד"
        },
        {
            "_id": 29614,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אילות"
        },
        {
            "_id": 29615,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אלי כהן"
        },
        {
            "_id": 29616,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלמוגן"
        },
        {
            "_id": 29617,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלפסי"
        },
        {
            "_id": 29618,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 261,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 29619,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 186,
            "שם_רחוב": "ארגמן"
        },
        {
            "_id": 29620,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 300,
            "שם_רחוב": "אריאל שרון"
        },
        {
            "_id": 29621,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אשוח"
        },
        {
            "_id": 29622,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 239,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 29623,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 280,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 29624,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "בית סוכנות"
        },
        {
            "_id": 29625,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "בנה ביתך א"
        },
        {
            "_id": 29626,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 267,
            "שם_רחוב": "בנטל"
        },
        {
            "_id": 29627,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 273,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 29628,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 210,
            "שם_רחוב": "גבורי ישראל"
        },
        {
            "_id": 29629,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "גבעת האלונים"
        },
        {
            "_id": 29630,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 173,
            "שם_רחוב": "גבעתי"
        },
        {
            "_id": 29631,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 174,
            "שם_רחוב": "גולני"
        },
        {
            "_id": 29632,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 117,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 29633,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 281,
            "שם_רחוב": "גזית"
        },
        {
            "_id": 29634,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 139,
            "שם_רחוב": "גילה"
        },
        {
            "_id": 29635,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 277,
            "שם_רחוב": "גן"
        },
        {
            "_id": 29636,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 276,
            "שם_רחוב": "גרניט"
        },
        {
            "_id": 29637,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דבורה פישר"
        },
        {
            "_id": 29638,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 200,
            "שם_רחוב": "דוד בן גוריון"
        },
        {
            "_id": 29639,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 237,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 29640,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 120,
            "שם_רחוב": "דן"
        },
        {
            "_id": 29641,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 118,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 29642,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 309,
            "שם_רחוב": "האהבה"
        },
        {
            "_id": 29643,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 302,
            "שם_רחוב": "האומץ"
        },
        {
            "_id": 29644,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 303,
            "שם_רחוב": "האור"
        },
        {
            "_id": 29645,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 242,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 29646,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 306,
            "שם_רחוב": "האושר"
        },
        {
            "_id": 29647,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 312,
            "שם_רחוב": "האחדות"
        },
        {
            "_id": 29648,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 313,
            "שם_רחוב": "האחווה"
        },
        {
            "_id": 29649,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 134,
            "שם_רחוב": "האיריס"
        },
        {
            "_id": 29650,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 241,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 29651,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 229,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 29652,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 235,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 29653,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 176,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 29654,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 227,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 29655,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 264,
            "שם_רחוב": "הבשן"
        },
        {
            "_id": 29656,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 257,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 29657,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 255,
            "שם_רחוב": "הגלבוע"
        },
        {
            "_id": 29658,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 251,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 29659,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 256,
            "שם_רחוב": "הגלעד"
        },
        {
            "_id": 29660,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 220,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 29661,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 136,
            "שם_רחוב": "הדודאים"
        },
        {
            "_id": 29662,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 221,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 29663,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 215,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 29664,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 230,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 29665,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 124,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 29666,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 304,
            "שם_רחוב": "ההודיה"
        },
        {
            "_id": 29667,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 137,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 29668,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 240,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 29669,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 131,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 29670,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 317,
            "שם_רחוב": "החוסן"
        },
        {
            "_id": 29671,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 126,
            "שם_רחוב": "החצב"
        },
        {
            "_id": 29672,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 234,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 29673,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 130,
            "שם_רחוב": "החרצית"
        },
        {
            "_id": 29674,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 314,
            "שם_רחוב": "הידידות"
        },
        {
            "_id": 29675,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 133,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 29676,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 254,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 29677,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הירמוך"
        },
        {
            "_id": 29678,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הירקון"
        },
        {
            "_id": 29679,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 123,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 29680,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 258,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 29681,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 114,
            "שם_רחוב": "הלה"
        },
        {
            "_id": 29682,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 190,
            "שם_רחוב": "הלוטם"
        },
        {
            "_id": 29683,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 212,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 29684,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 132,
            "שם_רחוב": "המרגנית"
        },
        {
            "_id": 29685,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 310,
            "שם_רחוב": "המשאלות"
        },
        {
            "_id": 29686,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 252,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 29687,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 208,
            "שם_רחוב": "הנוער"
        },
        {
            "_id": 29688,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 125,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 29689,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 135,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 29690,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 308,
            "שם_רחוב": "העוצמה"
        },
        {
            "_id": 29691,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 213,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 29692,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 127,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 29693,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 204,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 29694,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 233,
            "שם_רחוב": "הערבה"
        },
        {
            "_id": 29695,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 182,
            "שם_רחוב": "הפקאן"
        },
        {
            "_id": 29696,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 318,
            "שם_רחוב": "הפריחה"
        },
        {
            "_id": 29697,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 226,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 29698,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 205,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 29699,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 138,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 29700,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 316,
            "שם_רחוב": "הרוגע"
        },
        {
            "_id": 29701,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 222,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 29702,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 315,
            "שם_רחוב": "הרעות"
        },
        {
            "_id": 29703,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 216,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 29704,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 29705,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 266,
            "שם_רחוב": "השומרון"
        },
        {
            "_id": 29706,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 140,
            "שם_רחוב": "השושנים"
        },
        {
            "_id": 29707,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 232,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 29708,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 311,
            "שם_רחוב": "השלווה"
        },
        {
            "_id": 29709,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 301,
            "שם_רחוב": "השמחה"
        },
        {
            "_id": 29710,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 307,
            "שם_רחוב": "השפע"
        },
        {
            "_id": 29711,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 223,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 29712,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 253,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 29713,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 219,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 29714,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 228,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 29715,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 224,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 29716,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 218,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 29717,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 188,
            "שם_רחוב": "חושן"
        },
        {
            "_id": 29718,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 195,
            "שם_רחוב": "חנה רובינא"
        },
        {
            "_id": 29719,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 260,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 29720,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 279,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 29721,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 209,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 29722,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "יהודית ריכטר"
        },
        {
            "_id": 29723,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 211,
            "שם_רחוב": "יחזקאל"
        },
        {
            "_id": 29724,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 129,
            "שם_רחוב": "יפתח"
        },
        {
            "_id": 29725,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 271,
            "שם_רחוב": "יקינטון"
        },
        {
            "_id": 29726,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "ככר בר כוכבא"
        },
        {
            "_id": 29727,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 268,
            "שם_רחוב": "כנען"
        },
        {
            "_id": 29728,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר יונה"
        },
        {
            "_id": 29729,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "לאה גוטליב"
        },
        {
            "_id": 29730,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "לב הכפר"
        },
        {
            "_id": 29731,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מוצקין"
        },
        {
            "_id": 29732,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 259,
            "שם_רחוב": "מוריה"
        },
        {
            "_id": 29733,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 243,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 29734,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 262,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 29735,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 116,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 29736,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 265,
            "שם_רחוב": "נבו"
        },
        {
            "_id": 29737,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "נווה ניצנים"
        },
        {
            "_id": 29738,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 150,
            "שם_רחוב": "נחל בשור"
        },
        {
            "_id": 29739,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 151,
            "שם_רחוב": "נחל גלים"
        },
        {
            "_id": 29740,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 152,
            "שם_רחוב": "נחל געתון"
        },
        {
            "_id": 29741,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 153,
            "שם_רחוב": "נחל דליות"
        },
        {
            "_id": 29742,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 154,
            "שם_רחוב": "נחל דלתון"
        },
        {
            "_id": 29743,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 155,
            "שם_רחוב": "נחל זוויתן"
        },
        {
            "_id": 29744,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 180,
            "שם_רחוב": "נחל חלמיש"
        },
        {
            "_id": 29745,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 156,
            "שם_רחוב": "נחל חרוד"
        },
        {
            "_id": 29746,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 157,
            "שם_רחוב": "נחל כזיב"
        },
        {
            "_id": 29747,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 158,
            "שם_רחוב": "נחל מישר"
        },
        {
            "_id": 29748,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 159,
            "שם_רחוב": "נחל משושים"
        },
        {
            "_id": 29749,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 160,
            "שם_רחוב": "נחל נקרות"
        },
        {
            "_id": 29750,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 179,
            "שם_רחוב": "נחל ערוגות"
        },
        {
            "_id": 29751,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 161,
            "שם_רחוב": "נחל פולג"
        },
        {
            "_id": 29752,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 162,
            "שם_רחוב": "נחל צין"
        },
        {
            "_id": 29753,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 163,
            "שם_רחוב": "נחל קישון"
        },
        {
            "_id": 29754,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 164,
            "שם_רחוב": "נחל קמה"
        },
        {
            "_id": 29755,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 165,
            "שם_רחוב": "נחל רודד"
        },
        {
            "_id": 29756,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 166,
            "שם_רחוב": "נחל שגיא"
        },
        {
            "_id": 29757,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 167,
            "שם_רחוב": "נחל שובל"
        },
        {
            "_id": 29758,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 168,
            "שם_רחוב": "נחל שורק"
        },
        {
            "_id": 29759,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 169,
            "שם_רחוב": "נחל תנינים"
        },
        {
            "_id": 29760,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 199,
            "שם_רחוב": "נעמי שמר"
        },
        {
            "_id": 29761,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 203,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 29762,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "סיגלון"
        },
        {
            "_id": 29763,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 178,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 29764,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 177,
            "שם_רחוב": "סמ דן"
        },
        {
            "_id": 29765,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 141,
            "שם_רחוב": "סמ נורית"
        },
        {
            "_id": 29766,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 274,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 29767,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "עולי הגרדום"
        },
        {
            "_id": 29768,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 170,
            "שם_רחוב": "פלמח"
        },
        {
            "_id": 29769,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 231,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 29770,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 270,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 29771,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 282,
            "שם_רחוב": "צור"
        },
        {
            "_id": 29772,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 236,
            "שם_רחוב": "צלף"
        },
        {
            "_id": 29773,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "צמרות השרון"
        },
        {
            "_id": 29774,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 171,
            "שם_רחוב": "צנחנים"
        },
        {
            "_id": 29775,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 206,
            "שם_רחוב": "רבי אלימלך"
        },
        {
            "_id": 29776,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רות דיין"
        },
        {
            "_id": 29777,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 238,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 29778,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 115,
            "שם_רחוב": "רזיאל"
        },
        {
            "_id": 29779,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 113,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 29780,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש חדש"
        },
        {
            "_id": 29781,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 207,
            "שם_רחוב": "שד הדקלים"
        },
        {
            "_id": 29782,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שד מנחם בגין"
        },
        {
            "_id": 29783,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 214,
            "שם_רחוב": "שד פישר מוריס"
        },
        {
            "_id": 29784,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 175,
            "שם_רחוב": "שד רבין"
        },
        {
            "_id": 29785,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 278,
            "שם_רחוב": "שהם"
        },
        {
            "_id": 29786,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 197,
            "שם_רחוב": "שושנה דמארי"
        },
        {
            "_id": 29787,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 244,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 29788,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 196,
            "שם_רחוב": "שייקה אופיר"
        },
        {
            "_id": 29789,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ אלי כהן"
        },
        {
            "_id": 29790,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ המושבה"
        },
        {
            "_id": 29791,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ קרן היסוד"
        },
        {
            "_id": 29792,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכון א"
        },
        {
            "_id": 29793,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "שכון ב"
        },
        {
            "_id": 29794,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכון טרומי"
        },
        {
            "_id": 29795,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 225,
            "שם_רחוב": "שקמה"
        },
        {
            "_id": 29796,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 217,
            "שם_רחוב": "שרת"
        },
        {
            "_id": 29797,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 263,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 29798,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 272,
            "שם_רחוב": "תורמוס"
        },
        {
            "_id": 29799,
            "סמל_ישוב": 168,
            "שם_ישוב": "כפר יונה ",
            "סמל_רחוב": 172,
            "שם_רחוב": "תותחנים"
        },
        {
            "_id": 29800,
            "סמל_ישוב": 85,
            "שם_ישוב": "כפר יחזקאל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "העיגול"
        },
        {
            "_id": 29801,
            "סמל_ישוב": 85,
            "שם_ישוב": "כפר יחזקאל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הרחבה"
        },
        {
            "_id": 29802,
            "סמל_ישוב": 85,
            "שם_ישוב": "כפר יחזקאל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הרכבת"
        },
        {
            "_id": 29803,
            "סמל_ישוב": 85,
            "שם_ישוב": "כפר יחזקאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר יחזקאל"
        },
        {
            "_id": 29804,
            "סמל_ישוב": 85,
            "שם_ישוב": "כפר יחזקאל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "מנחם"
        },
        {
            "_id": 29805,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דרך הגבעה"
        },
        {
            "_id": 29806,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך הגת"
        },
        {
            "_id": 29807,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דרך הנירים"
        },
        {
            "_id": 29808,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 107,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 29809,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הבאר"
        },
        {
            "_id": 29810,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 113,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 29811,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 29812,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 110,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 29813,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הכרמים"
        },
        {
            "_id": 29814,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הנחל"
        },
        {
            "_id": 29815,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הפרדסים"
        },
        {
            "_id": 29816,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 29817,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 29818,
            "סמל_ישוב": 170,
            "שם_ישוב": "כפר יעבץ ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר יעבץ"
        },
        {
            "_id": 29819,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 152,
            "שם_רחוב": "אבזאך"
        },
        {
            "_id": 29820,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 100,
            "שם_רחוב": "אדיגה"
        },
        {
            "_id": 29821,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אה נאפה"
        },
        {
            "_id": 29822,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אושחה מאפ"
        },
        {
            "_id": 29823,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אזאת"
        },
        {
            "_id": 29824,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אזה"
        },
        {
            "_id": 29825,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אלברוס"
        },
        {
            "_id": 29826,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 182,
            "שם_רחוב": "אנה"
        },
        {
            "_id": 29827,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אפאס"
        },
        {
            "_id": 29828,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אפיפס"
        },
        {
            "_id": 29829,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אפץ'"
        },
        {
            "_id": 29830,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 112,
            "שם_רחוב": "באשתאש"
        },
        {
            "_id": 29831,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 161,
            "שם_רחוב": "באתר"
        },
        {
            "_id": 29832,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 153,
            "שם_רחוב": "בז'אדור'"
        },
        {
            "_id": 29833,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 162,
            "שם_רחוב": "בלאנה"
        },
        {
            "_id": 29834,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 163,
            "שם_רחוב": "בלם"
        },
        {
            "_id": 29835,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 146,
            "שם_רחוב": "בסלאנאי"
        },
        {
            "_id": 29836,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 108,
            "שם_רחוב": "ג'אמיה"
        },
        {
            "_id": 29837,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 171,
            "שם_רחוב": "גואשה"
        },
        {
            "_id": 29838,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 166,
            "שם_רחוב": "גומאף"
        },
        {
            "_id": 29839,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 172,
            "שם_רחוב": "גונס"
        },
        {
            "_id": 29840,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 178,
            "שם_רחוב": "גורת"
        },
        {
            "_id": 29841,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 126,
            "שם_רחוב": "דאה"
        },
        {
            "_id": 29842,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 168,
            "שם_רחוב": "דאנה"
        },
        {
            "_id": 29843,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 174,
            "שם_רחוב": "דאריי"
        },
        {
            "_id": 29844,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 181,
            "שם_רחוב": "דה'ווס"
        },
        {
            "_id": 29845,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 133,
            "שם_רחוב": "וואר'ה"
        },
        {
            "_id": 29846,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 157,
            "שם_רחוב": "ווביח"
        },
        {
            "_id": 29847,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 165,
            "שם_רחוב": "ווס"
        },
        {
            "_id": 29848,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 164,
            "שם_רחוב": "ווספס"
        },
        {
            "_id": 29849,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 138,
            "שם_רחוב": "וופש"
        },
        {
            "_id": 29850,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 115,
            "שם_רחוב": "זאפאצה"
        },
        {
            "_id": 29851,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 187,
            "שם_רחוב": "זאפה"
        },
        {
            "_id": 29852,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "חאם רוג"
        },
        {
            "_id": 29853,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 137,
            "שם_רחוב": "חאן רוג"
        },
        {
            "_id": 29854,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 111,
            "שם_רחוב": "חאנל'אשה"
        },
        {
            "_id": 29855,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 130,
            "שם_רחוב": "חאצ'ה"
        },
        {
            "_id": 29856,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 147,
            "שם_רחוב": "חאקוץ"
        },
        {
            "_id": 29857,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 131,
            "שם_רחוב": "חתוקאי"
        },
        {
            "_id": 29858,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 150,
            "שם_רחוב": "טוואפסה"
        },
        {
            "_id": 29859,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 156,
            "שם_רחוב": "יג'ירקואי"
        },
        {
            "_id": 29860,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "כיכר ביברס"
        },
        {
            "_id": 29861,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר קטז"
        },
        {
            "_id": 29862,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר כמא"
        },
        {
            "_id": 29863,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 134,
            "שם_רחוב": "ל'אמיג'"
        },
        {
            "_id": 29864,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 154,
            "שם_רחוב": "לאר'ה נאק'ה"
        },
        {
            "_id": 29865,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 144,
            "שם_רחוב": "מאז דוג"
        },
        {
            "_id": 29866,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 110,
            "שם_רחוב": "מאזה"
        },
        {
            "_id": 29867,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 198,
            "שם_רחוב": "מאזה נף"
        },
        {
            "_id": 29868,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 155,
            "שם_רחוב": "מאיקופ"
        },
        {
            "_id": 29869,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 149,
            "שם_רחוב": "מאמחיש"
        },
        {
            "_id": 29870,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 158,
            "שם_רחוב": "מאמר"
        },
        {
            "_id": 29871,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 188,
            "שם_רחוב": "מאפה"
        },
        {
            "_id": 29872,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 123,
            "שם_רחוב": "מארסה"
        },
        {
            "_id": 29873,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 124,
            "שם_רחוב": "מארקאווא"
        },
        {
            "_id": 29874,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 189,
            "שם_רחוב": "מזנף"
        },
        {
            "_id": 29875,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 191,
            "שם_רחוב": "מזפס"
        },
        {
            "_id": 29876,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 173,
            "שם_רחוב": "נאבזה"
        },
        {
            "_id": 29877,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 186,
            "שם_רחוב": "נאואס"
        },
        {
            "_id": 29878,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 185,
            "שם_רחוב": "נאלמס"
        },
        {
            "_id": 29879,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 135,
            "שם_רחוב": "נאלצ'יק"
        },
        {
            "_id": 29880,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 136,
            "שם_רחוב": "נאף"
        },
        {
            "_id": 29881,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 193,
            "שם_רחוב": "נאפה"
        },
        {
            "_id": 29882,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 179,
            "שם_רחוב": "נארת"
        },
        {
            "_id": 29883,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 125,
            "שם_רחוב": "נאשה"
        },
        {
            "_id": 29884,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 175,
            "שם_רחוב": "נגוף"
        },
        {
            "_id": 29885,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 116,
            "שם_רחוב": "נסה"
        },
        {
            "_id": 29886,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 180,
            "שם_רחוב": "נפנה"
        },
        {
            "_id": 29887,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 167,
            "שם_רחוב": "נרזאן"
        },
        {
            "_id": 29888,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 176,
            "שם_רחוב": "נשאן"
        },
        {
            "_id": 29889,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 128,
            "שם_רחוב": "נתכואי"
        },
        {
            "_id": 29890,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 118,
            "שם_רחוב": "סאיה"
        },
        {
            "_id": 29891,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 114,
            "שם_רחוב": "סאנה"
        },
        {
            "_id": 29892,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 141,
            "שם_רחוב": "סווסרק'וואה"
        },
        {
            "_id": 29893,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 103,
            "שם_רחוב": "ספסף"
        },
        {
            "_id": 29894,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 177,
            "שם_רחוב": "פאסה"
        },
        {
            "_id": 29895,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 196,
            "שם_רחוב": "פאשה"
        },
        {
            "_id": 29896,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 122,
            "שם_רחוב": "פחאצ'אצ'"
        },
        {
            "_id": 29897,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 127,
            "שם_רחוב": "פכאח רוג"
        },
        {
            "_id": 29898,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 105,
            "שם_רחוב": "פכה צ'אסה"
        },
        {
            "_id": 29899,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 121,
            "שם_רחוב": "פסא"
        },
        {
            "_id": 29900,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 183,
            "שם_רחוב": "פסארש"
        },
        {
            "_id": 29901,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 194,
            "שם_רחוב": "פסוור"
        },
        {
            "_id": 29902,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 195,
            "שם_רחוב": "פסנה"
        },
        {
            "_id": 29903,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 184,
            "שם_רחוב": "פסנפש"
        },
        {
            "_id": 29904,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 142,
            "שם_רחוב": "פשא"
        },
        {
            "_id": 29905,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 132,
            "שם_רחוב": "פשינה"
        },
        {
            "_id": 29906,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 129,
            "שם_רחוב": "צ'אם רוג"
        },
        {
            "_id": 29907,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 109,
            "שם_רחוב": "צ'ארקה"
        },
        {
            "_id": 29908,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 139,
            "שם_רחוב": "צ'מגוי"
        },
        {
            "_id": 29909,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 120,
            "שם_רחוב": "ציה"
        },
        {
            "_id": 29910,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 140,
            "שם_רחוב": "קברטאי"
        },
        {
            "_id": 29911,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 197,
            "שם_רחוב": "קפצ'ה"
        },
        {
            "_id": 29912,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רוגזה"
        },
        {
            "_id": 29913,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 169,
            "שם_רחוב": "שאבזה"
        },
        {
            "_id": 29914,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שאוו"
        },
        {
            "_id": 29915,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שאפסור'"
        },
        {
            "_id": 29916,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 192,
            "שם_רחוב": "שוו"
        },
        {
            "_id": 29917,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 160,
            "שם_רחוב": "תאמה"
        },
        {
            "_id": 29918,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 113,
            "שם_רחוב": "תאמה רידז"
        },
        {
            "_id": 29919,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 106,
            "שם_רחוב": "תיר'ה"
        },
        {
            "_id": 29920,
            "סמל_ישוב": 508,
            "שם_ישוב": "כפר כמא ",
            "סמל_רחוב": 190,
            "שם_רחוב": "תמר"
        },
        {
            "_id": 29921,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 202,
            "שם_רחוב": "א-דוואלי"
        },
        {
            "_id": 29922,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 207,
            "שם_רחוב": "א-זנבק"
        },
        {
            "_id": 29923,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 195,
            "שם_רחוב": "א-זקוקיא"
        },
        {
            "_id": 29924,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 239,
            "שם_רחוב": "א-לוז"
        },
        {
            "_id": 29925,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 234,
            "שם_רחוב": "א-נור"
        },
        {
            "_id": 29926,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 209,
            "שם_רחוב": "א-נח'יל"
        },
        {
            "_id": 29927,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 216,
            "שם_רחוב": "א-נחאתין"
        },
        {
            "_id": 29928,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 237,
            "שם_רחוב": "א-נרג'ס"
        },
        {
            "_id": 29929,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 249,
            "שם_רחוב": "א-סוסנה"
        },
        {
            "_id": 29930,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 224,
            "שם_רחוב": "א-סנאבל"
        },
        {
            "_id": 29931,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 208,
            "שם_רחוב": "א-סרוואת"
        },
        {
            "_id": 29932,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 235,
            "שם_רחוב": "א-סת ראדייה"
        },
        {
            "_id": 29933,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 218,
            "שם_רחוב": "א-ראהבה ג'ובינה"
        },
        {
            "_id": 29934,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 250,
            "שם_רחוב": "א-רחמה"
        },
        {
            "_id": 29935,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 217,
            "שם_רחוב": "א-ריאחין"
        },
        {
            "_id": 29936,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 230,
            "שם_רחוב": "א-שאם"
        },
        {
            "_id": 29937,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 193,
            "שם_רחוב": "א-שג'רה"
        },
        {
            "_id": 29938,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 168,
            "שם_רחוב": "א-תין"
        },
        {
            "_id": 29939,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 176,
            "שם_רחוב": "אבו בכר"
        },
        {
            "_id": 29940,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אבו קאסם א-שאבי"
        },
        {
            "_id": 29941,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "אזור המועצה"
        },
        {
            "_id": 29942,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6715,
            "שם_רחוב": "אזור התעשייה"
        },
        {
            "_id": 29943,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 184,
            "שם_רחוב": "איבן סינא"
        },
        {
            "_id": 29944,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 121,
            "שם_רחוב": "אישראקה"
        },
        {
            "_id": 29945,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6744,
            "שם_רחוב": "אל ביאדה"
        },
        {
            "_id": 29946,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 260,
            "שם_רחוב": "אל-אמין"
        },
        {
            "_id": 29947,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 229,
            "שם_רחוב": "אל-אנדלוס"
        },
        {
            "_id": 29948,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אל-בטריק"
        },
        {
            "_id": 29949,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 238,
            "שם_רחוב": "אל-ג'ורי"
        },
        {
            "_id": 29950,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אל-ח'ורי יוסף"
        },
        {
            "_id": 29951,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אל-ח'נסאא"
        },
        {
            "_id": 29952,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אל-ח'רובה"
        },
        {
            "_id": 29953,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אל-חאווז"
        },
        {
            "_id": 29954,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אל-חוטיני"
        },
        {
            "_id": 29955,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אל-חיכמה"
        },
        {
            "_id": 29956,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 183,
            "שם_רחוב": "אל-יאסמין"
        },
        {
            "_id": 29957,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 206,
            "שם_רחוב": "אל-ירמוך"
        },
        {
            "_id": 29958,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 261,
            "שם_רחוב": "אל-כרם"
        },
        {
            "_id": 29959,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אל-כרם א-שימאלי"
        },
        {
            "_id": 29960,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אל-מואזין"
        },
        {
            "_id": 29961,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 199,
            "שם_רחוב": "אל-מוטאלב"
        },
        {
            "_id": 29962,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אל-מוטראן חג'אר"
        },
        {
            "_id": 29963,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אל-מוס'לא"
        },
        {
            "_id": 29964,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 251,
            "שם_רחוב": "אל-מוסחראתי"
        },
        {
            "_id": 29965,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 257,
            "שם_רחוב": "אל-מועלם ג'אבר"
        },
        {
            "_id": 29966,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אל-מוקדסי"
        },
        {
            "_id": 29967,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 177,
            "שם_רחוב": "אל-מח'אתיר"
        },
        {
            "_id": 29968,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אל-מטל"
        },
        {
            "_id": 29969,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אל-מנזול"
        },
        {
            "_id": 29970,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אל-ע'זאלי"
        },
        {
            "_id": 29971,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אל-עג'מי"
        },
        {
            "_id": 29972,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 248,
            "שם_רחוב": "אל-פול"
        },
        {
            "_id": 29973,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אל-פרדוס"
        },
        {
            "_id": 29974,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אל-קלעה"
        },
        {
            "_id": 29975,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "אלח'לה"
        },
        {
            "_id": 29976,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6753,
            "שם_רחוב": "אלמסאלה"
        },
        {
            "_id": 29977,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "אלנקארה"
        },
        {
            "_id": 29978,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6750,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 29979,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "אם דלהם"
        },
        {
            "_id": 29980,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 243,
            "שם_רחוב": "אמיל חביבי"
        },
        {
            "_id": 29981,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6743,
            "שם_רחוב": "ביס יסודי ג'"
        },
        {
            "_id": 29982,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 178,
            "שם_רחוב": "בלאן"
        },
        {
            "_id": 29983,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 192,
            "שם_רחוב": "ג'ורת אל-ביר"
        },
        {
            "_id": 29984,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 189,
            "שם_רחוב": "ג'לנאר"
        },
        {
            "_id": 29985,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 158,
            "שם_רחוב": "גובראן ח'ליל גובראן"
        },
        {
            "_id": 29986,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 245,
            "שם_רחוב": "גרנטה"
        },
        {
            "_id": 29987,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 210,
            "שם_רחוב": "דבת אל-ח'מאיסי"
        },
        {
            "_id": 29988,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 123,
            "שם_רחוב": "דרב א-זעתר"
        },
        {
            "_id": 29989,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרב א-סוק"
        },
        {
            "_id": 29990,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "העיר העתיקה(אלבלד)"
        },
        {
            "_id": 29991,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 236,
            "שם_רחוב": "זקאק אל-עג'יבה"
        },
        {
            "_id": 29992,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 222,
            "שם_רחוב": "ח'אלד בן אל-וליד"
        },
        {
            "_id": 29993,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 247,
            "שם_רחוב": "ח'לת אל-ג'וז"
        },
        {
            "_id": 29994,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 117,
            "שם_רחוב": "ח'לת אל-עואודה"
        },
        {
            "_id": 29995,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 111,
            "שם_רחוב": "ח'לת חמדאן"
        },
        {
            "_id": 29996,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 197,
            "שם_רחוב": "ח'לת ס'קר"
        },
        {
            "_id": 29997,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 240,
            "שם_רחוב": "טארק בן זיאד"
        },
        {
            "_id": 29998,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 231,
            "שם_רחוב": "טברייה"
        },
        {
            "_id": 29999,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 258,
            "שם_רחוב": "יאפא"
        },
        {
            "_id": 30000,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 241,
            "שם_רחוב": "כמאל אל-יוסף"
        },
        {
            "_id": 30001,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 167,
            "שם_רחוב": "כעמו"
        },
        {
            "_id": 30002,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר כנא"
        },
        {
            "_id": 30003,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 122,
            "שם_רחוב": "מאי זיאדה"
        },
        {
            "_id": 30004,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 186,
            "שם_רחוב": "מארי ס'פורי"
        },
        {
            "_id": 30005,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 190,
            "שם_רחוב": "מאריה אל-קיבטיה"
        },
        {
            "_id": 30006,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 174,
            "שם_רחוב": "מטר"
        },
        {
            "_id": 30007,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 166,
            "שם_רחוב": "מנזיל"
        },
        {
            "_id": 30008,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 173,
            "שם_רחוב": "מנזיל עראר"
        },
        {
            "_id": 30009,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 220,
            "שם_רחוב": "סאחת אל-מוח'תאר"
        },
        {
            "_id": 30010,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 108,
            "שם_רחוב": "סירין"
        },
        {
            "_id": 30011,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 223,
            "שם_רחוב": "סמעאן אל-כנאוי"
        },
        {
            "_id": 30012,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 137,
            "שם_רחוב": "עומר איבן אל-ח'טאב"
        },
        {
            "_id": 30013,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 141,
            "שם_רחוב": "עומר בן עבד אל-עזיז"
        },
        {
            "_id": 30014,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 162,
            "שם_רחוב": "עות'מאן איבן עפאן"
        },
        {
            "_id": 30015,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 233,
            "שם_רחוב": "עלי אל-פארס"
        },
        {
            "_id": 30016,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 138,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 30017,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 204,
            "שם_רחוב": "עס'א א-ראעי"
        },
        {
            "_id": 30018,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 201,
            "שם_רחוב": "ערב א-ס'בח"
        },
        {
            "_id": 30019,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 154,
            "שם_רחוב": "צלאח א-דין"
        },
        {
            "_id": 30020,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 124,
            "שם_רחוב": "קאנא אל-ג'ליל"
        },
        {
            "_id": 30021,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שארע א-זהראא"
        },
        {
            "_id": 30022,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 152,
            "שם_רחוב": "שארע א-נקארה"
        },
        {
            "_id": 30023,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 132,
            "שם_רחוב": "שארע א-ס'באר"
        },
        {
            "_id": 30024,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 157,
            "שם_רחוב": "שארע א-סקי"
        },
        {
            "_id": 30025,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 160,
            "שם_רחוב": "שארע א-רומאן"
        },
        {
            "_id": 30026,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 164,
            "שם_רחוב": "שארע א-שיח' סאלם"
        },
        {
            "_id": 30027,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 139,
            "שם_רחוב": "שארע אום דלהם"
        },
        {
            "_id": 30028,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 135,
            "שם_רחוב": "שארע אום זיתונה"
        },
        {
            "_id": 30029,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 146,
            "שם_רחוב": "שארע איבן רושד"
        },
        {
            "_id": 30030,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 155,
            "שם_רחוב": "שארע אל-בסאתין"
        },
        {
            "_id": 30031,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 253,
            "שם_רחוב": "שארע אל-ג'מאקיה"
        },
        {
            "_id": 30032,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 150,
            "שם_רחוב": "שארע אל-חמרייה"
        },
        {
            "_id": 30033,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שארע אל-כנאאיס"
        },
        {
            "_id": 30034,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 242,
            "שם_רחוב": "שארע אל-מחדר"
        },
        {
            "_id": 30035,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 145,
            "שם_רחוב": "שארע אל-מלעב"
        },
        {
            "_id": 30036,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 127,
            "שם_רחוב": "שארע אל-מעאלי"
        },
        {
            "_id": 30037,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 130,
            "שם_רחוב": "שארע אל-מעאס'ר"
        },
        {
            "_id": 30038,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 126,
            "שם_רחוב": "שארע אל-משאווח"
        },
        {
            "_id": 30039,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 136,
            "שם_רחוב": "שארע אל-עבאהר"
        },
        {
            "_id": 30040,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 256,
            "שם_רחוב": "שארע אל-קנדול"
        },
        {
            "_id": 30041,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 175,
            "שם_רחוב": "שארע אלבלד אלקדימה"
        },
        {
            "_id": 30042,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 148,
            "שם_רחוב": "שארע ביארכ אל-ח'יל"
        },
        {
            "_id": 30043,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 143,
            "שם_רחוב": "שארע ג'בל ח'וויח'ה"
        },
        {
            "_id": 30044,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 119,
            "שם_רחוב": "שארע ג'בל סיח'"
        },
        {
            "_id": 30045,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 182,
            "שם_רחוב": "שארע ג'פתה"
        },
        {
            "_id": 30046,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שארע ואדי זיד"
        },
        {
            "_id": 30047,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 131,
            "שם_רחוב": "שארע וערת סעד"
        },
        {
            "_id": 30048,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 140,
            "שם_רחוב": "שארע ח'לת אל-מריד"
        },
        {
            "_id": 30049,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 142,
            "שם_רחוב": "שארע ח'לת נאצר"
        },
        {
            "_id": 30050,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 159,
            "שם_רחוב": "שארע חנא אל-מטר"
        },
        {
            "_id": 30051,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 153,
            "שם_רחוב": "שארע טה חוסין"
        },
        {
            "_id": 30052,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 133,
            "שם_רחוב": "שארע כורום א-רישה"
        },
        {
            "_id": 30053,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 181,
            "שם_רחוב": "שארע כרם א-ראס"
        },
        {
            "_id": 30054,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 151,
            "שם_רחוב": "שארע כרם א-שיח'"
        },
        {
            "_id": 30055,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 128,
            "שם_רחוב": "שארע כרם אל-חדאדין"
        },
        {
            "_id": 30056,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 129,
            "שם_רחוב": "שארע מעד'ר"
        },
        {
            "_id": 30057,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 252,
            "שם_רחוב": "שארע ס'פד"
        },
        {
            "_id": 30058,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 134,
            "שם_רחוב": "שארע עין אל-ביד'ה"
        },
        {
            "_id": 30059,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 254,
            "שם_רחוב": "שארע עין ג'וזה"
        },
        {
            "_id": 30060,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 116,
            "שם_רחוב": "שארע ענתר"
        },
        {
            "_id": 30061,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 125,
            "שם_רחוב": "שארע פוק אל-עין"
        },
        {
            "_id": 30062,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6700,
            "שם_רחוב": "שכ אל ג'מאג'יה"
        },
        {
            "_id": 30063,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "שכ אלבסאתין"
        },
        {
            "_id": 30064,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "שכ אלח'מריה"
        },
        {
            "_id": 30065,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "שכ אלמחדר"
        },
        {
            "_id": 30066,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6751,
            "שם_רחוב": "שכ אלמשוח"
        },
        {
            "_id": 30067,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "שכ ג'בל ח'ויח'ה"
        },
        {
            "_id": 30068,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "שכ ג'פתא"
        },
        {
            "_id": 30069,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6724,
            "שם_רחוב": "שכ ואדי זיד"
        },
        {
            "_id": 30070,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6742,
            "שם_רחוב": "שכ וערת סעד"
        },
        {
            "_id": 30071,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6741,
            "שם_רחוב": "שכ כרום אלרישה"
        },
        {
            "_id": 30072,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "שכ כרם אראס"
        },
        {
            "_id": 30073,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "שכ ענטר"
        },
        {
            "_id": 30074,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6754,
            "שם_רחוב": "שכ פוק אל עין"
        },
        {
            "_id": 30075,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 6752,
            "שם_רחוב": "שכונת בנה ביתך"
        },
        {
            "_id": 30076,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 169,
            "שם_רחוב": "תופיק אל-חכים"
        },
        {
            "_id": 30077,
            "סמל_ישוב": 509,
            "שם_ישוב": "כפר כנא ",
            "סמל_רחוב": 259,
            "שם_רחוב": "תל א-סבע"
        },
        {
            "_id": 30078,
            "סמל_ישוב": 387,
            "שם_ישוב": "כפר מונש ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך הטרקטור"
        },
        {
            "_id": 30079,
            "סמל_ישוב": 387,
            "שם_ישוב": "כפר מונש ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הפרדסים"
        },
        {
            "_id": 30080,
            "סמל_ישוב": 387,
            "שם_ישוב": "כפר מונש ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 30081,
            "סמל_ישוב": 387,
            "שם_ישוב": "כפר מונש ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הקורא"
        },
        {
            "_id": 30082,
            "סמל_ישוב": 387,
            "שם_ישוב": "כפר מונש ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 30083,
            "סמל_ישוב": 387,
            "שם_ישוב": "כפר מונש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר מונש"
        },
        {
            "_id": 30084,
            "סמל_ישוב": 387,
            "שם_ישוב": "כפר מונש ",
            "סמל_רחוב": 107,
            "שם_רחוב": "סמטת הברוש"
        },
        {
            "_id": 30085,
            "סמל_ישוב": 387,
            "שם_ישוב": "כפר מונש ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שביל החלב"
        },
        {
            "_id": 30086,
            "סמל_ישוב": 1095,
            "שם_ישוב": "כפר מימון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר מימון"
        },
        {
            "_id": 30087,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "בן גמלא יהושע"
        },
        {
            "_id": 30088,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "דרך השדות"
        },
        {
            "_id": 30089,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרך השרון"
        },
        {
            "_id": 30090,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 109,
            "שם_רחוב": "האלונים"
        },
        {
            "_id": 30091,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 30092,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 30093,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הלימון"
        },
        {
            "_id": 30094,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "המיסדים"
        },
        {
            "_id": 30095,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "המנצח"
        },
        {
            "_id": 30096,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 30097,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 30098,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר מלל"
        },
        {
            "_id": 30099,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מוסד יד אסא"
        },
        {
            "_id": 30100,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "עין חי"
        },
        {
            "_id": 30101,
            "סמל_ישוב": 98,
            "שם_ישוב": "כפר מלל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "שרת"
        },
        {
            "_id": 30102,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6741,
            "שם_רחוב": "אלבלדה אלקדימה"
        },
        {
            "_id": 30103,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6742,
            "שם_רחוב": "אלבסאתין"
        },
        {
            "_id": 30104,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6733,
            "שם_רחוב": "אלח'לאילה"
        },
        {
            "_id": 30105,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "אלחואשנה"
        },
        {
            "_id": 30106,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "אלמאר"
        },
        {
            "_id": 30107,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "אלמג'לס אל קדים"
        },
        {
            "_id": 30108,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "אלמע'ור"
        },
        {
            "_id": 30109,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "אלמעברה"
        },
        {
            "_id": 30110,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6734,
            "שם_רחוב": "אלמעסרה"
        },
        {
            "_id": 30111,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "אלנבעה"
        },
        {
            "_id": 30112,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "אלע'רביה"
        },
        {
            "_id": 30113,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "אלעוזיר"
        },
        {
            "_id": 30114,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "אלשאריע אלראיסי"
        },
        {
            "_id": 30115,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "אלתין"
        },
        {
            "_id": 30116,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "זיתון מזרחית"
        },
        {
            "_id": 30117,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "זערורה"
        },
        {
            "_id": 30118,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "טריק עראבה"
        },
        {
            "_id": 30119,
            "סמל_ישוב": 510,
            "שם_ישוב": "כפר מנדא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר מנדא"
        },
        {
            "_id": 30120,
            "סמל_ישוב": 274,
            "שם_ישוב": "כפר מנחם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר מנחם"
        },
        {
            "_id": 30121,
            "סמל_ישוב": 274,
            "שם_ישוב": "כפר מנחם ",
            "סמל_רחוב": 101,
            "שם_רחוב": "צפית"
        },
        {
            "_id": 30122,
            "סמל_ישוב": 297,
            "שם_ישוב": "כפר מסריק ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר מסריק"
        },
        {
            "_id": 30123,
            "סמל_ישוב": 512,
            "שם_ישוב": "כפר מצר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר מצר"
        },
        {
            "_id": 30124,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 121,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 30125,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הבוסתן"
        },
        {
            "_id": 30126,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הבריכה"
        },
        {
            "_id": 30127,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 30128,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 30129,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הלילך"
        },
        {
            "_id": 30130,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 102,
            "שם_רחוב": "המטע"
        },
        {
            "_id": 30131,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 30132,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 103,
            "שם_רחוב": "השדה"
        },
        {
            "_id": 30133,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר מרדכי"
        },
        {
            "_id": 30134,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 124,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 30135,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 108,
            "שם_רחוב": "מעלה המייסדים"
        },
        {
            "_id": 30136,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 123,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 30137,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 126,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 30138,
            "סמל_ישוב": 764,
            "שם_ישוב": "כפר מרדכי ",
            "סמל_רחוב": 125,
            "שם_רחוב": "ריחן"
        },
        {
            "_id": 30139,
            "סמל_ישוב": 316,
            "שם_ישוב": "כפר נטר ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 30140,
            "סמל_ישוב": 316,
            "שם_ישוב": "כפר נטר ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 30141,
            "סמל_ישוב": 316,
            "שם_ישוב": "כפר נטר ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 30142,
            "סמל_ישוב": 316,
            "שם_ישוב": "כפר נטר ",
            "סמל_רחוב": 102,
            "שם_רחוב": "ההדרים"
        },
        {
            "_id": 30143,
            "סמל_ישוב": 316,
            "שם_ישוב": "כפר נטר ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 30144,
            "סמל_ישוב": 316,
            "שם_ישוב": "כפר נטר ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הרותם"
        },
        {
            "_id": 30145,
            "סמל_ישוב": 316,
            "שם_ישוב": "כפר נטר ",
            "סמל_רחוב": 107,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 30146,
            "סמל_ישוב": 316,
            "שם_ישוב": "כפר נטר ",
            "סמל_רחוב": 105,
            "שם_רחוב": "השלום"
        },
        {
            "_id": 30147,
            "סמל_ישוב": 316,
            "שם_ישוב": "כפר נטר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר נטר"
        },
        {
            "_id": 30148,
            "סמל_ישוב": 345,
            "שם_ישוב": "כפר סאלד ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 30149,
            "סמל_ישוב": 345,
            "שם_ישוב": "כפר סאלד ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר סאלד"
        },
        {
            "_id": 30150,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אביגיל"
        },
        {
            "_id": 30151,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אבידן דוד"
        },
        {
            "_id": 30152,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אבן גבירול"
        },
        {
            "_id": 30153,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אבן עזרא"
        },
        {
            "_id": 30154,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 103,
            "שם_רחוב": "אבן שפרוט"
        },
        {
            "_id": 30155,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אברבנאל"
        },
        {
            "_id": 30156,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 693,
            "שם_רחוב": "אברוצקי"
        },
        {
            "_id": 30157,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 501,
            "שם_רחוב": "אגרון"
        },
        {
            "_id": 30158,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 443,
            "שם_רחוב": "אדם יקותיאל"
        },
        {
            "_id": 30159,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 502,
            "שם_רחוב": "אהרונוביץ"
        },
        {
            "_id": 30160,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 694,
            "שם_רחוב": "אוסטרובסקי"
        },
        {
            "_id": 30161,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 503,
            "שם_רחוב": "אוסטשינסקי"
        },
        {
            "_id": 30162,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אורט"
        },
        {
            "_id": 30163,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 302,
            "שם_רחוב": "אזר"
        },
        {
            "_id": 30164,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6517,
            "שם_רחוב": "אזור המלאכה והתעשיה"
        },
        {
            "_id": 30165,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "אזור התעשיה"
        },
        {
            "_id": 30166,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6518,
            "שם_רחוב": "אזור מלאכה"
        },
        {
            "_id": 30167,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 391,
            "שם_רחוב": "אחי אילת"
        },
        {
            "_id": 30168,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 392,
            "שם_רחוב": "אחי דקר"
        },
        {
            "_id": 30169,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אחד העם"
        },
        {
            "_id": 30170,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 610,
            "שם_רחוב": "אחוה"
        },
        {
            "_id": 30171,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אייזנברג לאה ושאול"
        },
        {
            "_id": 30172,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 505,
            "שם_רחוב": "איינשטיין"
        },
        {
            "_id": 30173,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 504,
            "שם_רחוב": "אימבר"
        },
        {
            "_id": 30174,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 165,
            "שם_רחוב": "אירגוני המתנדבים"
        },
        {
            "_id": 30175,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 601,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 30176,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלחריזי"
        },
        {
            "_id": 30177,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אליהו התשבי"
        },
        {
            "_id": 30178,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 304,
            "שם_רחוב": "אלישבע"
        },
        {
            "_id": 30179,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אלישע"
        },
        {
            "_id": 30180,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 701,
            "שם_רחוב": "אלעזר דוד"
        },
        {
            "_id": 30181,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אלקלעי"
        },
        {
            "_id": 30182,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 577,
            "שם_רחוב": "אלתרמן"
        },
        {
            "_id": 30183,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 478,
            "שם_רחוב": "אמנון ותמר"
        },
        {
            "_id": 30184,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 671,
            "שם_רחוב": "אנגל"
        },
        {
            "_id": 30185,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 506,
            "שם_רחוב": "אנה פרנק"
        },
        {
            "_id": 30186,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 292,
            "שם_רחוב": "אנטק"
        },
        {
            "_id": 30187,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 401,
            "שם_רחוב": "אנילביץ"
        },
        {
            "_id": 30188,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 691,
            "שם_רחוב": "אנציקובסקי"
        },
        {
            "_id": 30189,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 305,
            "שם_רחוב": "אסירי ציון"
        },
        {
            "_id": 30190,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 715,
            "שם_רחוב": "אצג"
        },
        {
            "_id": 30191,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 590,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 30192,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 662,
            "שם_רחוב": "ארגוב סשה"
        },
        {
            "_id": 30193,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 507,
            "שם_רחוב": "ארלוזורוב"
        },
        {
            "_id": 30194,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 508,
            "שם_רחוב": "אש שלום"
        },
        {
            "_id": 30195,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אשכול לוי"
        },
        {
            "_id": 30196,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 306,
            "שם_רחוב": "בוסל"
        },
        {
            "_id": 30197,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 285,
            "שם_רחוב": "בוסתן אבו סניני"
        },
        {
            "_id": 30198,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 402,
            "שם_רחוב": "בורוכוב"
        },
        {
            "_id": 30199,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1320,
            "שם_רחוב": "ביח מאיר"
        },
        {
            "_id": 30200,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 509,
            "שם_רחוב": "ביאליק"
        },
        {
            "_id": 30201,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 510,
            "שם_רחוב": "בילו"
        },
        {
            "_id": 30202,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 307,
            "שם_רחוב": "בילינסון"
        },
        {
            "_id": 30203,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "בית ונוף"
        },
        {
            "_id": 30204,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 403,
            "שם_רחוב": "בלבן"
        },
        {
            "_id": 30205,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 404,
            "שם_רחוב": "בלום ליאון"
        },
        {
            "_id": 30206,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 405,
            "שם_רחוב": "בלפור"
        },
        {
            "_id": 30207,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 163,
            "שם_רחוב": "בלפר זאב"
        },
        {
            "_id": 30208,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 390,
            "שם_רחוב": "בן אבי איתמר"
        },
        {
            "_id": 30209,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 522,
            "שם_רחוב": "בן גוריון"
        },
        {
            "_id": 30210,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 309,
            "שם_רחוב": "בן יהודה"
        },
        {
            "_id": 30211,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 659,
            "שם_רחוב": "בנאי יוסי"
        },
        {
            "_id": 30212,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 541,
            "שם_רחוב": "בני ברית"
        },
        {
            "_id": 30213,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 250,
            "שם_רחוב": "בקעת באר שבע"
        },
        {
            "_id": 30214,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 242,
            "שם_רחוב": "בקעת בית נטופה"
        },
        {
            "_id": 30215,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 241,
            "שם_רחוב": "בקעת הירח"
        },
        {
            "_id": 30216,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 247,
            "שם_רחוב": "בקעת זוהר"
        },
        {
            "_id": 30217,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 251,
            "שם_רחוב": "בקעת יבנאל"
        },
        {
            "_id": 30218,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 249,
            "שם_רחוב": "בקעת יריחו"
        },
        {
            "_id": 30219,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 245,
            "שם_רחוב": "בקעת סיירים"
        },
        {
            "_id": 30220,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 248,
            "שם_רחוב": "בקעת עובדה"
        },
        {
            "_id": 30221,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 246,
            "שם_רחוב": "בקעת צין"
        },
        {
            "_id": 30222,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 244,
            "שם_רחוב": "בקעת רמון"
        },
        {
            "_id": 30223,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 310,
            "שם_רחוב": "בר אילן"
        },
        {
            "_id": 30224,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 407,
            "שם_רחוב": "ברדיצ'ב אבא"
        },
        {
            "_id": 30225,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 603,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 30226,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 311,
            "שם_רחוב": "ברנר"
        },
        {
            "_id": 30227,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 380,
            "שם_רחוב": "ברק"
        },
        {
            "_id": 30228,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 470,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 30229,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 713,
            "שם_רחוב": "ברש אשר"
        },
        {
            "_id": 30230,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 216,
            "שם_רחוב": "בת יפתח"
        },
        {
            "_id": 30231,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 226,
            "שם_רחוב": "בת שבע"
        },
        {
            "_id": 30232,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 511,
            "שם_רחוב": "בתי חבס"
        },
        {
            "_id": 30233,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 657,
            "שם_רחוב": "ג'ו עמר"
        },
        {
            "_id": 30234,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 512,
            "שם_רחוב": "גאולה"
        },
        {
            "_id": 30235,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 554,
            "שם_רחוב": "גבורות"
        },
        {
            "_id": 30236,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 587,
            "שם_רחוב": "גבורי ישראל"
        },
        {
            "_id": 30237,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1305,
            "שם_רחוב": "גביש"
        },
        {
            "_id": 30238,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 802,
            "שם_רחוב": "גבעולים"
        },
        {
            "_id": 30239,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "גבעת אשכול"
        },
        {
            "_id": 30240,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 408,
            "שם_רחוב": "גבעתי"
        },
        {
            "_id": 30241,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 280,
            "שם_רחוב": "גד הנביא"
        },
        {
            "_id": 30242,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 581,
            "שם_רחוב": "גדנע"
        },
        {
            "_id": 30243,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 129,
            "שם_רחוב": "גדעון"
        },
        {
            "_id": 30244,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 606,
            "שם_רחוב": "גובר רבקה"
        },
        {
            "_id": 30245,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 312,
            "שם_רחוב": "גולד זאב"
        },
        {
            "_id": 30246,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 296,
            "שם_רחוב": "גולדברג לאה"
        },
        {
            "_id": 30247,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 409,
            "שם_רחוב": "גולדשטין"
        },
        {
            "_id": 30248,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 313,
            "שם_רחוב": "גולומב"
        },
        {
            "_id": 30249,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 513,
            "שם_רחוב": "גורדון"
        },
        {
            "_id": 30250,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 388,
            "שם_רחוב": "גיסות השריון"
        },
        {
            "_id": 30251,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 546,
            "שם_רחוב": "גלבוע"
        },
        {
            "_id": 30252,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1311,
            "שם_רחוב": "גלגלי פלדה"
        },
        {
            "_id": 30253,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 578,
            "שם_רחוב": "גלעד"
        },
        {
            "_id": 30254,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 529,
            "שם_רחוב": "גלר זאב"
        },
        {
            "_id": 30255,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 384,
            "שם_רחוב": "גן השרון"
        },
        {
            "_id": 30256,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6561,
            "שם_רחוב": "גני השרון"
        },
        {
            "_id": 30257,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 365,
            "שם_רחוב": "גרוסמן חייקה"
        },
        {
            "_id": 30258,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 314,
            "שם_רחוב": "גרץ"
        },
        {
            "_id": 30259,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 174,
            "שם_רחוב": "דב לאוטמן"
        },
        {
            "_id": 30260,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 128,
            "שם_רחוב": "דבורה הנביאה"
        },
        {
            "_id": 30261,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 133,
            "שם_רחוב": "דוד המלך"
        },
        {
            "_id": 30262,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 471,
            "שם_רחוב": "דודאים"
        },
        {
            "_id": 30263,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 275,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 30264,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 595,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 30265,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 852,
            "שם_רחוב": "דיין משה"
        },
        {
            "_id": 30266,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 263,
            "שם_רחוב": "דינור"
        },
        {
            "_id": 30267,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 708,
            "שם_רחוב": "דליה"
        },
        {
            "_id": 30268,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 682,
            "שם_רחוב": "דמארי שושנה"
        },
        {
            "_id": 30269,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דניאל"
        },
        {
            "_id": 30270,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 594,
            "שם_רחוב": "דקל"
        },
        {
            "_id": 30271,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 171,
            "שם_רחוב": "דרך אפולוניה"
        },
        {
            "_id": 30272,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 172,
            "שם_רחוב": "דרך אפק"
        },
        {
            "_id": 30273,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 173,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 30274,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 270,
            "שם_רחוב": "דרך המוביל"
        },
        {
            "_id": 30275,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 164,
            "שם_רחוב": "דרך המלך"
        },
        {
            "_id": 30276,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 277,
            "שם_רחוב": "דרך הפועל"
        },
        {
            "_id": 30277,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 483,
            "שם_רחוב": "דרך השרון"
        },
        {
            "_id": 30278,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 255,
            "שם_רחוב": "דרך קדומים"
        },
        {
            "_id": 30279,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 716,
            "שם_רחוב": "האגוז"
        },
        {
            "_id": 30280,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 576,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 30281,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 568,
            "שם_רחוב": "האחדות"
        },
        {
            "_id": 30282,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 151,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 30283,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 591,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 30284,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 315,
            "שם_רחוב": "האמוראים"
        },
        {
            "_id": 30285,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 598,
            "שם_רחוב": "האמת"
        },
        {
            "_id": 30286,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 160,
            "שם_רחוב": "האקליפטוס"
        },
        {
            "_id": 30287,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 572,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 30288,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 855,
            "שם_רחוב": "האשכולית"
        },
        {
            "_id": 30289,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 159,
            "שם_רחוב": "האשל"
        },
        {
            "_id": 30290,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 585,
            "שם_רחוב": "האתרוג"
        },
        {
            "_id": 30291,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 387,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 30292,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 351,
            "שם_רחוב": "הבריגדה"
        },
        {
            "_id": 30293,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 608,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 30294,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 573,
            "שם_רחוב": "הגולן"
        },
        {
            "_id": 30295,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 316,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 30296,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 411,
            "שם_רחוב": "הגנה"
        },
        {
            "_id": 30297,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 30298,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 515,
            "שם_רחוב": "הגרא"
        },
        {
            "_id": 30299,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 317,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 30300,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 516,
            "שם_רחוב": "ההסתדרות"
        },
        {
            "_id": 30301,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 583,
            "שם_רחוב": "ההשכלה"
        },
        {
            "_id": 30302,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 318,
            "שם_רחוב": "הוז דב"
        },
        {
            "_id": 30303,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 162,
            "שם_רחוב": "הורביץ אלי"
        },
        {
            "_id": 30304,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הושע"
        },
        {
            "_id": 30305,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 154,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 30306,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 468,
            "שם_רחוב": "הזמר"
        },
        {
            "_id": 30307,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 584,
            "שם_רחוב": "החורשה"
        },
        {
            "_id": 30308,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 319,
            "שם_רחוב": "החיל"
        },
        {
            "_id": 30309,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 262,
            "שם_רחוב": "החיש"
        },
        {
            "_id": 30310,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 477,
            "שם_רחוב": "החן"
        },
        {
            "_id": 30311,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 152,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 30312,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1303,
            "שם_רחוב": "החרושת"
        },
        {
            "_id": 30313,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 575,
            "שם_רחוב": "החרמון"
        },
        {
            "_id": 30314,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 463,
            "שם_רחוב": "הטחנה"
        },
        {
            "_id": 30315,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 413,
            "שם_רחוב": "הידיד"
        },
        {
            "_id": 30316,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 167,
            "שם_רחוב": "הידידות"
        },
        {
            "_id": 30317,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1309,
            "שם_רחוב": "היוזמה"
        },
        {
            "_id": 30318,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 207,
            "שם_רחוב": "היוצרים"
        },
        {
            "_id": 30319,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 574,
            "שם_רחוב": "הירדן"
        },
        {
            "_id": 30320,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 414,
            "שם_רחוב": "הכובשים"
        },
        {
            "_id": 30321,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 592,
            "שם_רחוב": "הככר"
        },
        {
            "_id": 30322,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 549,
            "שם_רחוב": "הכלנית"
        },
        {
            "_id": 30323,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 320,
            "שם_רחוב": "הכנסת"
        },
        {
            "_id": 30324,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 145,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 30325,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 321,
            "שם_רחוב": "הכרמל"
        },
        {
            "_id": 30326,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הלה"
        },
        {
            "_id": 30327,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 582,
            "שם_רחוב": "הלימון"
        },
        {
            "_id": 30328,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 168,
            "שם_רחוב": "הלפיד"
        },
        {
            "_id": 30329,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 456,
            "שם_רחוב": "המגשימים"
        },
        {
            "_id": 30330,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 138,
            "שם_רחוב": "המחתרות"
        },
        {
            "_id": 30331,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 571,
            "שם_רחוב": "המיסדים"
        },
        {
            "_id": 30332,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 110,
            "שם_רחוב": "המלכים"
        },
        {
            "_id": 30333,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1310,
            "שם_רחוב": "המנופים"
        },
        {
            "_id": 30334,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1301,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 30335,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 453,
            "שם_רחוב": "המעלות"
        },
        {
            "_id": 30336,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 322,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 30337,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 396,
            "שם_רחוב": "המפלס"
        },
        {
            "_id": 30338,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 675,
            "שם_רחוב": "המשורר יצחק"
        },
        {
            "_id": 30339,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 406,
            "שם_רחוב": "המשטרה"
        },
        {
            "_id": 30340,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הנביאים"
        },
        {
            "_id": 30341,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 264,
            "שם_רחוב": "הנגב"
        },
        {
            "_id": 30342,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1304,
            "שם_רחוב": "הנגר"
        },
        {
            "_id": 30343,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 663,
            "שם_רחוב": "הנדל נחמה"
        },
        {
            "_id": 30344,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 324,
            "שם_רחוב": "הס"
        },
        {
            "_id": 30345,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 208,
            "שם_רחוב": "הסדנא"
        },
        {
            "_id": 30346,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 325,
            "שם_רחוב": "הסנהדרין"
        },
        {
            "_id": 30347,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 326,
            "שם_רחוב": "העבודה"
        },
        {
            "_id": 30348,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 273,
            "שם_רחוב": "העגורים"
        },
        {
            "_id": 30349,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 410,
            "שם_רחוב": "העליה השניה"
        },
        {
            "_id": 30350,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 518,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 30351,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 389,
            "שם_רחוב": "העצמאות"
        },
        {
            "_id": 30352,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 416,
            "שם_רחוב": "הפועל הצעיר"
        },
        {
            "_id": 30353,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 417,
            "שם_רחוב": "הפורצים"
        },
        {
            "_id": 30354,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 519,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 30355,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 602,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 30356,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 210,
            "שם_רחוב": "הפרסה"
        },
        {
            "_id": 30357,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 711,
            "שם_רחוב": "הצבעוני"
        },
        {
            "_id": 30358,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 156,
            "שם_רחוב": "הצבר"
        },
        {
            "_id": 30359,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 593,
            "שם_רחוב": "הצדק"
        },
        {
            "_id": 30360,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 600,
            "שם_רחוב": "הצומת"
        },
        {
            "_id": 30361,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 460,
            "שם_רחוב": "הצנחנים"
        },
        {
            "_id": 30362,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 705,
            "שם_רחוב": "הקשת"
        },
        {
            "_id": 30363,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 630,
            "שם_רחוב": "הר אדום"
        },
        {
            "_id": 30364,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 634,
            "שם_רחוב": "הר אדיר"
        },
        {
            "_id": 30365,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 629,
            "שם_רחוב": "הר בוקר"
        },
        {
            "_id": 30366,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 627,
            "שם_רחוב": "הר גריזים"
        },
        {
            "_id": 30367,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 632,
            "שם_רחוב": "הר חזון"
        },
        {
            "_id": 30368,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 622,
            "שם_רחוב": "הר חריף"
        },
        {
            "_id": 30369,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 636,
            "שם_רחוב": "הר כמון"
        },
        {
            "_id": 30370,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 631,
            "שם_רחוב": "הר כנען"
        },
        {
            "_id": 30371,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 624,
            "שם_רחוב": "הר כרכום"
        },
        {
            "_id": 30372,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 621,
            "שם_רחוב": "הר מירון"
        },
        {
            "_id": 30373,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 633,
            "שם_רחוב": "הר נבו"
        },
        {
            "_id": 30374,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 628,
            "שם_רחוב": "הר עצמון"
        },
        {
            "_id": 30375,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 623,
            "שם_רחוב": "הר צין"
        },
        {
            "_id": 30376,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 635,
            "שם_רחוב": "הר רביד"
        },
        {
            "_id": 30377,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 626,
            "שם_רחוב": "הר רותם"
        },
        {
            "_id": 30378,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 625,
            "שם_רחוב": "הר שוקף"
        },
        {
            "_id": 30379,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 620,
            "שם_רחוב": "הר תבור"
        },
        {
            "_id": 30380,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 132,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 30381,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 375,
            "שם_רחוב": "הרב אסף שמחה"
        },
        {
            "_id": 30382,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 349,
            "שם_רחוב": "הרב עמיאל"
        },
        {
            "_id": 30383,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 358,
            "שם_רחוב": "הרב קוק"
        },
        {
            "_id": 30384,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 596,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 30385,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 155,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 30386,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 397,
            "שם_רחוב": "הרמה"
        },
        {
            "_id": 30387,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 166,
            "שם_רחוב": "הרעות"
        },
        {
            "_id": 30388,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 551,
            "שם_רחוב": "הרצוג"
        },
        {
            "_id": 30389,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 520,
            "שם_רחוב": "הרצל"
        },
        {
            "_id": 30390,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 301,
            "שם_רחוב": "הרצפלד אברהם"
        },
        {
            "_id": 30391,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 545,
            "שם_רחוב": "הרקפת"
        },
        {
            "_id": 30392,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 415,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 30393,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 112,
            "שם_רחוב": "השופטים"
        },
        {
            "_id": 30394,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 327,
            "שם_רחוב": "השחר"
        },
        {
            "_id": 30395,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 161,
            "שם_רחוב": "השיטה"
        },
        {
            "_id": 30396,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 126,
            "שם_רחוב": "השילוני"
        },
        {
            "_id": 30397,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 469,
            "שם_רחוב": "השיר"
        },
        {
            "_id": 30398,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 462,
            "שם_רחוב": "השלום"
        },
        {
            "_id": 30399,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 265,
            "שם_רחוב": "השפלה"
        },
        {
            "_id": 30400,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 328,
            "שם_רחוב": "השקדים"
        },
        {
            "_id": 30401,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 148,
            "שם_רחוב": "השקמה"
        },
        {
            "_id": 30402,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 552,
            "שם_רחוב": "השרון"
        },
        {
            "_id": 30403,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 150,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 30404,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1308,
            "שם_רחוב": "התוכנה"
        },
        {
            "_id": 30405,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 851,
            "שם_רחוב": "התותחנים"
        },
        {
            "_id": 30406,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 521,
            "שם_רחוב": "התחיה"
        },
        {
            "_id": 30407,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 153,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 30408,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 329,
            "שם_רחוב": "התנאים"
        },
        {
            "_id": 30409,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 205,
            "שם_רחוב": "התעש"
        },
        {
            "_id": 30410,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 566,
            "שם_רחוב": "התפוז"
        },
        {
            "_id": 30411,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 223,
            "שם_רחוב": "וולך יונה"
        },
        {
            "_id": 30412,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 687,
            "שם_רחוב": "ויינברג"
        },
        {
            "_id": 30413,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 660,
            "שם_רחוב": "וילנסקי משה"
        },
        {
            "_id": 30414,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 523,
            "שם_רחוב": "ויצמן"
        },
        {
            "_id": 30415,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 678,
            "שם_רחוב": "וישני"
        },
        {
            "_id": 30416,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 418,
            "שם_רחוב": "ויתקין"
        },
        {
            "_id": 30417,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 330,
            "שם_רחוב": "ז'בוטינסקי"
        },
        {
            "_id": 30418,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 674,
            "שם_רחוב": "זהבי דוד"
        },
        {
            "_id": 30419,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 669,
            "שם_רחוב": "זטלר"
        },
        {
            "_id": 30420,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 281,
            "שם_רחוב": "זכריה הנביא"
        },
        {
            "_id": 30421,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 717,
            "שם_רחוב": "זלדה המשוררת"
        },
        {
            "_id": 30422,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 689,
            "שם_רחוב": "זליוונסקי"
        },
        {
            "_id": 30423,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 485,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 30424,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 331,
            "שם_רחוב": "זמנהוף"
        },
        {
            "_id": 30425,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 464,
            "שם_רחוב": "חבד"
        },
        {
            "_id": 30426,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 544,
            "שם_רחוב": "חבצלת השרון"
        },
        {
            "_id": 30427,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 260,
            "שם_רחוב": "חבקוק"
        },
        {
            "_id": 30428,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 394,
            "שם_רחוב": "חברון"
        },
        {
            "_id": 30429,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 291,
            "שם_רחוב": "חגי"
        },
        {
            "_id": 30430,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 710,
            "שם_רחוב": "חובבי ציון"
        },
        {
            "_id": 30431,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 276,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 30432,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 130,
            "שם_רחוב": "חולדה הנביאה"
        },
        {
            "_id": 30433,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 607,
            "שם_רחוב": "חומה ומגדל"
        },
        {
            "_id": 30434,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 332,
            "שם_רחוב": "חורגין"
        },
        {
            "_id": 30435,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 685,
            "שם_רחוב": "חזה עפרה"
        },
        {
            "_id": 30436,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 382,
            "שם_רחוב": "חטיבת אלכסנדרוני"
        },
        {
            "_id": 30437,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 395,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 30438,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 386,
            "שם_רחוב": "חטיבת הבקעה"
        },
        {
            "_id": 30439,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 378,
            "שם_רחוב": "חטיבת הנחל"
        },
        {
            "_id": 30440,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 579,
            "שם_רחוב": "חטיבת הראל"
        },
        {
            "_id": 30441,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 385,
            "שם_רחוב": "חטיבת יפתח"
        },
        {
            "_id": 30442,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 654,
            "שם_רחוב": "חיטמן עוזי"
        },
        {
            "_id": 30443,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 677,
            "שם_רחוב": "חיים"
        },
        {
            "_id": 30444,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 699,
            "שם_רחוב": "חיימוביץ"
        },
        {
            "_id": 30445,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 230,
            "שם_רחוב": "חנה"
        },
        {
            "_id": 30446,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 344,
            "שם_רחוב": "חניתה"
        },
        {
            "_id": 30447,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 224,
            "שם_רחוב": "חנני יוסף"
        },
        {
            "_id": 30448,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 488,
            "שם_רחוב": "חסידה"
        },
        {
            "_id": 30449,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 333,
            "שם_רחוב": "חצרות הדר"
        },
        {
            "_id": 30450,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 399,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 30451,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 419,
            "שם_רחוב": "טהון"
        },
        {
            "_id": 30452,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 334,
            "שם_רחוב": "טירת צבי"
        },
        {
            "_id": 30453,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 525,
            "שם_רחוב": "טרומן"
        },
        {
            "_id": 30454,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 524,
            "שם_רחוב": "טרומפלדור"
        },
        {
            "_id": 30455,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 420,
            "שם_רחוב": "טשרניחובסקי"
        },
        {
            "_id": 30456,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 393,
            "שם_רחוב": "יא הספורטאים"
        },
        {
            "_id": 30457,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 335,
            "שם_רחוב": "יבנה"
        },
        {
            "_id": 30458,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 714,
            "שם_רחוב": "יבניאלי"
        },
        {
            "_id": 30459,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1302,
            "שם_רחוב": "יד חרוצים"
        },
        {
            "_id": 30460,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 609,
            "שם_רחוב": "ידין יגאל"
        },
        {
            "_id": 30461,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 588,
            "שם_רחוב": "יהודה"
        },
        {
            "_id": 30462,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 336,
            "שם_רחוב": "יהודה הלוי"
        },
        {
            "_id": 30463,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 136,
            "שם_רחוב": "יהודה המכבי"
        },
        {
            "_id": 30464,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 337,
            "שם_רחוב": "יהודה הנשיא"
        },
        {
            "_id": 30465,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 526,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 30466,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 113,
            "שם_רחוב": "יואל"
        },
        {
            "_id": 30467,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 206,
            "שם_רחוב": "יוחנן הסנדלר"
        },
        {
            "_id": 30468,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 114,
            "שם_רחוב": "יונה"
        },
        {
            "_id": 30469,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 666,
            "שם_רחוב": "יוסף הדר"
        },
        {
            "_id": 30470,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 115,
            "שם_רחוב": "יחזקאל"
        },
        {
            "_id": 30471,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 338,
            "שם_רחוב": "יחיעם"
        },
        {
            "_id": 30472,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 116,
            "שם_רחוב": "ילג"
        },
        {
            "_id": 30473,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 141,
            "שם_רחוב": "ינאי המלך"
        },
        {
            "_id": 30474,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 530,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 30475,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 220,
            "שם_רחוב": "יעל"
        },
        {
            "_id": 30476,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 472,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 30477,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 683,
            "שם_רחוב": "יצחק זיקו גרציאני"
        },
        {
            "_id": 30478,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 550,
            "שם_רחוב": "יציאת אירופה"
        },
        {
            "_id": 30479,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 527,
            "שם_רחוב": "ירושלים"
        },
        {
            "_id": 30480,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 117,
            "שם_רחוב": "ירמיהו"
        },
        {
            "_id": 30481,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 118,
            "שם_רחוב": "ישעיהו"
        },
        {
            "_id": 30482,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 439,
            "שם_רחוב": "ישראל ישעיהו"
        },
        {
            "_id": 30483,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 339,
            "שם_רחוב": "כהן"
        },
        {
            "_id": 30484,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 421,
            "שם_רחוב": "כנפי נשרים"
        },
        {
            "_id": 30485,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 528,
            "שם_רחוב": "כנרת"
        },
        {
            "_id": 30486,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר סבא"
        },
        {
            "_id": 30487,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 340,
            "שם_רחוב": "כצנלסון ברל"
        },
        {
            "_id": 30488,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 536,
            "שם_רחוב": "לב הכפר"
        },
        {
            "_id": 30489,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 455,
            "שם_רחוב": "לבון פנחס"
        },
        {
            "_id": 30490,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 256,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 30491,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 681,
            "שם_רחוב": "לביא אריק"
        },
        {
            "_id": 30492,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 702,
            "שם_רחוב": "לובטקין צביה"
        },
        {
            "_id": 30493,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 673,
            "שם_רחוב": "לוונברג"
        },
        {
            "_id": 30494,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 672,
            "שם_רחוב": "לוי"
        },
        {
            "_id": 30495,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 423,
            "שם_רחוב": "לוינסקי"
        },
        {
            "_id": 30496,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 422,
            "שם_רחוב": "לופבן"
        },
        {
            "_id": 30497,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 424,
            "שם_רחוב": "לילנבלום"
        },
        {
            "_id": 30498,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 341,
            "שם_רחוב": "לסל"
        },
        {
            "_id": 30499,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 684,
            "שם_רחוב": "מאיר אריאל"
        },
        {
            "_id": 30500,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 342,
            "שם_רחוב": "מאפו"
        },
        {
            "_id": 30501,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 379,
            "שם_רחוב": "מבצע יואב"
        },
        {
            "_id": 30502,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 367,
            "שם_רחוב": "מבצע יונתן"
        },
        {
            "_id": 30503,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 450,
            "שם_רחוב": "מגדלי ים התיכון"
        },
        {
            "_id": 30504,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 658,
            "שם_רחוב": "מוהר עלי"
        },
        {
            "_id": 30505,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 720,
            "שם_רחוב": "מוטה גור"
        },
        {
            "_id": 30506,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 257,
            "שם_רחוב": "מור"
        },
        {
            "_id": 30507,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 425,
            "שם_רחוב": "מורדי הגטאות"
        },
        {
            "_id": 30508,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 473,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 30509,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 474,
            "שם_רחוב": "מזור"
        },
        {
            "_id": 30510,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 696,
            "שם_רחוב": "מזרחי"
        },
        {
            "_id": 30511,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 119,
            "שם_רחוב": "מיכה"
        },
        {
            "_id": 30512,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 221,
            "שם_רחוב": "מיכל"
        },
        {
            "_id": 30513,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 664,
            "שם_רחוב": "מנור אהוד"
        },
        {
            "_id": 30514,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6521,
            "שם_רחוב": "מעונות עממים"
        },
        {
            "_id": 30515,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 569,
            "שם_רחוב": "מעלה האורנים"
        },
        {
            "_id": 30516,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 475,
            "שם_רחוב": "מענית"
        },
        {
            "_id": 30517,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 514,
            "שם_רחוב": "מפאי"
        },
        {
            "_id": 30518,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 137,
            "שם_רחוב": "מצדה"
        },
        {
            "_id": 30519,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 427,
            "שם_רחוב": "מרבד הקסמים"
        },
        {
            "_id": 30520,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 225,
            "שם_רחוב": "מרדכי"
        },
        {
            "_id": 30521,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 217,
            "שם_רחוב": "מרים"
        },
        {
            "_id": 30522,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 426,
            "שם_רחוב": "מרכוס"
        },
        {
            "_id": 30523,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 140,
            "שם_רחוב": "מרכז קליטה ספיר"
        },
        {
            "_id": 30524,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 258,
            "שם_רחוב": "משעול בית הבד"
        },
        {
            "_id": 30525,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 801,
            "שם_רחוב": "משעול גיל"
        },
        {
            "_id": 30526,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 170,
            "שם_רחוב": "משעול האהבה"
        },
        {
            "_id": 30527,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 482,
            "שם_רחוב": "משעול הארי"
        },
        {
            "_id": 30528,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 599,
            "שם_רחוב": "משעול החיים"
        },
        {
            "_id": 30529,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 169,
            "שם_רחוב": "משעול הסובלנות"
        },
        {
            "_id": 30530,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 580,
            "שם_רחוב": "משעול הקלע"
        },
        {
            "_id": 30531,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 567,
            "שם_רחוב": "משעול רשף"
        },
        {
            "_id": 30532,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 286,
            "שם_רחוב": "משק האוצר"
        },
        {
            "_id": 30533,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6545,
            "שם_רחוב": "מתחם התחנה המרכזית"
        },
        {
            "_id": 30534,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6522,
            "שם_רחוב": "נאות דשא"
        },
        {
            "_id": 30535,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6535,
            "שם_רחוב": "נווה הדרים"
        },
        {
            "_id": 30536,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "נווה סורקיס"
        },
        {
            "_id": 30537,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 553,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 30538,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 707,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 30539,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 259,
            "שם_רחוב": "נחום"
        },
        {
            "_id": 30540,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 650,
            "שם_רחוב": "נחל הדס"
        },
        {
            "_id": 30541,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 651,
            "שם_רחוב": "נחל קנה"
        },
        {
            "_id": 30542,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 271,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 30543,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 120,
            "שם_רחוב": "נחמיה"
        },
        {
            "_id": 30544,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 531,
            "שם_רחוב": "נחשון"
        },
        {
            "_id": 30545,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 428,
            "שם_רחוב": "נטר קרל"
        },
        {
            "_id": 30546,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 709,
            "שם_רחוב": "נילי"
        },
        {
            "_id": 30547,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 345,
            "שם_רחוב": "ניצנים"
        },
        {
            "_id": 30548,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 556,
            "שם_רחוב": "ניר"
        },
        {
            "_id": 30549,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 679,
            "שם_רחוב": "נלקין"
        },
        {
            "_id": 30550,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 323,
            "שם_רחוב": "נעורים"
        },
        {
            "_id": 30551,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 218,
            "שם_רחוב": "נעמי"
        },
        {
            "_id": 30552,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 495,
            "שם_רחוב": "נרקיס עוזי"
        },
        {
            "_id": 30553,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 492,
            "שם_רחוב": "נשר"
        },
        {
            "_id": 30554,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 458,
            "שם_רחוב": "נתיב האבות"
        },
        {
            "_id": 30555,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 282,
            "שם_רחוב": "נתן הנביא"
        },
        {
            "_id": 30556,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 719,
            "שם_רחוב": "סביון"
        },
        {
            "_id": 30557,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6516,
            "שם_רחוב": "סביוני הכפר"
        },
        {
            "_id": 30558,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 346,
            "שם_רחוב": "סולד"
        },
        {
            "_id": 30559,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 690,
            "שם_רחוב": "סוסנובסקי"
        },
        {
            "_id": 30560,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 532,
            "שם_רחוב": "סוקולוב"
        },
        {
            "_id": 30561,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 704,
            "שם_רחוב": "סיגלית"
        },
        {
            "_id": 30562,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 703,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 30563,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 484,
            "שם_רחוב": "סיני"
        },
        {
            "_id": 30564,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 429,
            "שם_רחוב": "סירני"
        },
        {
            "_id": 30565,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 430,
            "שם_רחוב": "סירקין"
        },
        {
            "_id": 30566,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 695,
            "שם_רחוב": "סלור"
        },
        {
            "_id": 30567,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 139,
            "שם_רחוב": "סמ אביבים"
        },
        {
            "_id": 30568,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 266,
            "שם_רחוב": "סמ אנוש"
        },
        {
            "_id": 30569,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 491,
            "שם_רחוב": "סמ בז"
        },
        {
            "_id": 30570,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 487,
            "שם_רחוב": "סמ דיה"
        },
        {
            "_id": 30571,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 751,
            "שם_רחוב": "סמ הדרור"
        },
        {
            "_id": 30572,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 267,
            "שם_רחוב": "סמ החושן"
        },
        {
            "_id": 30573,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 803,
            "שם_רחוב": "סמ מעון"
        },
        {
            "_id": 30574,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 143,
            "שם_רחוב": "סמ רון"
        },
        {
            "_id": 30575,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 489,
            "שם_רחוב": "סמ שחף"
        },
        {
            "_id": 30576,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 712,
            "שם_רחוב": "סמטת אברהם"
        },
        {
            "_id": 30577,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 457,
            "שם_רחוב": "סמילנסקי"
        },
        {
            "_id": 30578,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 480,
            "שם_רחוב": "סנה משה"
        },
        {
            "_id": 30579,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 347,
            "שם_רחוב": "סנש"
        },
        {
            "_id": 30580,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 686,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 30581,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 668,
            "שם_רחוב": "סקיבין"
        },
        {
            "_id": 30582,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 229,
            "שם_רחוב": "עגנון"
        },
        {
            "_id": 30583,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 283,
            "שם_רחוב": "עובדיה הנביא"
        },
        {
            "_id": 30584,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 452,
            "שם_רחוב": "עוגן"
        },
        {
            "_id": 30585,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 348,
            "שם_רחוב": "עוזיאל"
        },
        {
            "_id": 30586,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 121,
            "שם_רחוב": "עזרא"
        },
        {
            "_id": 30587,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 490,
            "שם_רחוב": "עיט"
        },
        {
            "_id": 30588,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 540,
            "שם_רחוב": "עין גדי"
        },
        {
            "_id": 30589,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 451,
            "שם_רחוב": "עליה"
        },
        {
            "_id": 30590,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 122,
            "שם_רחוב": "עמוס"
        },
        {
            "_id": 30591,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 555,
            "שם_רחוב": "עמל"
        },
        {
            "_id": 30592,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 653,
            "שם_רחוב": "עמנואל זמיר"
        },
        {
            "_id": 30593,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 234,
            "שם_רחוב": "עמק אילון"
        },
        {
            "_id": 30594,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 239,
            "שם_רחוב": "עמק בית שאן"
        },
        {
            "_id": 30595,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 232,
            "שם_רחוב": "עמק דותן"
        },
        {
            "_id": 30596,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 235,
            "שם_רחוב": "עמק האלה"
        },
        {
            "_id": 30597,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 238,
            "שם_רחוב": "עמק החולה"
        },
        {
            "_id": 30598,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 231,
            "שם_רחוב": "עמק זבולון"
        },
        {
            "_id": 30599,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 243,
            "שם_רחוב": "עמק חורון"
        },
        {
            "_id": 30600,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 233,
            "שם_רחוב": "עמק חפר"
        },
        {
            "_id": 30601,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 237,
            "שם_רחוב": "עמק חרוד"
        },
        {
            "_id": 30602,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 240,
            "שם_רחוב": "עמק יזרעאל"
        },
        {
            "_id": 30603,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 236,
            "שם_רחוב": "עמק עירון"
        },
        {
            "_id": 30604,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 538,
            "שם_רחוב": "עמרמי"
        },
        {
            "_id": 30605,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 272,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 30606,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 350,
            "שם_רחוב": "עציון"
        },
        {
            "_id": 30607,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 157,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 30608,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 479,
            "שם_רחוב": "ערוגות הבושם"
        },
        {
            "_id": 30609,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1307,
            "שם_רחוב": "עתירי ידע"
        },
        {
            "_id": 30610,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 431,
            "שם_רחוב": "פועלי ציון"
        },
        {
            "_id": 30611,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 352,
            "שם_רחוב": "פיארברג"
        },
        {
            "_id": 30612,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 353,
            "שם_רחוב": "פינס"
        },
        {
            "_id": 30613,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 432,
            "שם_רחוב": "פלמח"
        },
        {
            "_id": 30614,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 354,
            "שם_רחוב": "פנקס"
        },
        {
            "_id": 30615,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 433,
            "שם_רחוב": "פרוג שמעון"
        },
        {
            "_id": 30616,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 697,
            "שם_רחוב": "פריבר"
        },
        {
            "_id": 30617,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 355,
            "שם_רחוב": "פרישמן"
        },
        {
            "_id": 30618,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 676,
            "שם_רחוב": "פרנקל פאבל"
        },
        {
            "_id": 30619,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 356,
            "שם_רחוב": "פרץ"
        },
        {
            "_id": 30620,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 656,
            "שם_רחוב": "פררה אברהם"
        },
        {
            "_id": 30621,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 274,
            "שם_רחוב": "פשוש"
        },
        {
            "_id": 30622,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 597,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 30623,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 142,
            "שם_רחוב": "צדקיהו"
        },
        {
            "_id": 30624,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 434,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 30625,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 435,
            "שם_רחוב": "צייטלין"
        },
        {
            "_id": 30626,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 261,
            "שם_רחוב": "צפניה"
        },
        {
            "_id": 30627,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 698,
            "שם_רחוב": "קארלינסקי"
        },
        {
            "_id": 30628,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 436,
            "שם_רחוב": "קבוץ גלויות"
        },
        {
            "_id": 30629,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 398,
            "שם_רחוב": "קבוצת לג"
        },
        {
            "_id": 30630,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 357,
            "שם_רחוב": "קדושי בלזן"
        },
        {
            "_id": 30631,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 481,
            "שם_רחוב": "קדושי קהיר"
        },
        {
            "_id": 30632,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 294,
            "שם_רחוב": "קדיש לוז"
        },
        {
            "_id": 30633,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 295,
            "שם_רחוב": "קובנר אבא"
        },
        {
            "_id": 30634,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 437,
            "שם_רחוב": "קורצ'ק"
        },
        {
            "_id": 30635,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 359,
            "שם_רחוב": "קלישר"
        },
        {
            "_id": 30636,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 692,
            "שם_רחוב": "קמינסקי"
        },
        {
            "_id": 30637,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 438,
            "שם_רחוב": "קפלנסקי"
        },
        {
            "_id": 30638,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6515,
            "שם_רחוב": "קרית הרצוג"
        },
        {
            "_id": 30639,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6534,
            "שם_רחוב": "קרית ספיר"
        },
        {
            "_id": 30640,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 517,
            "שם_רחוב": "קרן אברהם"
        },
        {
            "_id": 30641,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 652,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 30642,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 655,
            "שם_רחוב": "קרן המוסיקה"
        },
        {
            "_id": 30643,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 665,
            "שם_רחוב": "רוזנבלום יאיר"
        },
        {
            "_id": 30644,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 534,
            "שם_רחוב": "רוטשילד"
        },
        {
            "_id": 30645,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 535,
            "שם_רחוב": "רופין"
        },
        {
            "_id": 30646,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 219,
            "שם_רחוב": "רות"
        },
        {
            "_id": 30647,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 465,
            "שם_רחוב": "רזיאל דוד"
        },
        {
            "_id": 30648,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 1306,
            "שם_רחוב": "רחבת מיכאל"
        },
        {
            "_id": 30649,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 308,
            "שם_רחוב": "רחל המשוררת"
        },
        {
            "_id": 30650,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 688,
            "שם_רחוב": "ריזנבך"
        },
        {
            "_id": 30651,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 360,
            "שם_רחוב": "ריינס"
        },
        {
            "_id": 30652,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 440,
            "שם_רחוב": "רייק חביבה"
        },
        {
            "_id": 30653,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 441,
            "שם_רחוב": "רינגלבלום"
        },
        {
            "_id": 30654,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 361,
            "שם_רחוב": "רמבם"
        },
        {
            "_id": 30655,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 442,
            "שם_רחוב": "רמז דוד"
        },
        {
            "_id": 30656,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 123,
            "שם_רחוב": "רמחל"
        },
        {
            "_id": 30657,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 381,
            "שם_רחוב": "רעם"
        },
        {
            "_id": 30658,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 667,
            "שם_רחוב": "רפפורט"
        },
        {
            "_id": 30659,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 362,
            "שם_רחוב": "רשי"
        },
        {
            "_id": 30660,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6562,
            "שם_רחוב": "ש אלי כהן"
        },
        {
            "_id": 30661,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6523,
            "שם_רחוב": "ש אליעזר"
        },
        {
            "_id": 30662,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "ש אסבסט קפלן"
        },
        {
            "_id": 30663,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6524,
            "שם_רחוב": "ש אקרמן"
        },
        {
            "_id": 30664,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6525,
            "שם_רחוב": "ש גרין"
        },
        {
            "_id": 30665,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6526,
            "שם_רחוב": "ש דגניה"
        },
        {
            "_id": 30666,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "ש הדרים"
        },
        {
            "_id": 30667,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "ש ותיקים"
        },
        {
            "_id": 30668,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "ש יוספטל"
        },
        {
            "_id": 30669,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6563,
            "שם_רחוב": "ש כסופים"
        },
        {
            "_id": 30670,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6528,
            "שם_רחוב": "ש למפרט"
        },
        {
            "_id": 30671,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6541,
            "שם_רחוב": "ש מאוריציוס"
        },
        {
            "_id": 30672,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6564,
            "שם_רחוב": "ש מוצקין"
        },
        {
            "_id": 30673,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6565,
            "שם_רחוב": "ש מזרחי"
        },
        {
            "_id": 30674,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6529,
            "שם_רחוב": "ש מלל"
        },
        {
            "_id": 30675,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6530,
            "שם_רחוב": "ש מעוז"
        },
        {
            "_id": 30676,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6566,
            "שם_רחוב": "ש משכנות"
        },
        {
            "_id": 30677,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6531,
            "שם_רחוב": "ש עוב ב ציון"
        },
        {
            "_id": 30678,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "ש עולים"
        },
        {
            "_id": 30679,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "ש עליה"
        },
        {
            "_id": 30680,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "ש עמידר"
        },
        {
            "_id": 30681,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6532,
            "שם_רחוב": "ש עממי"
        },
        {
            "_id": 30682,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6533,
            "שם_רחוב": "ש פרוגרסיבים"
        },
        {
            "_id": 30683,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "ש קפלן"
        },
        {
            "_id": 30684,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6542,
            "שם_רחוב": "ש תקומה"
        },
        {
            "_id": 30685,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 134,
            "שם_רחוב": "שאול המלך"
        },
        {
            "_id": 30686,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 444,
            "שם_רחוב": "שבד"
        },
        {
            "_id": 30687,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 445,
            "שם_רחוב": "שבזי"
        },
        {
            "_id": 30688,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 412,
            "שם_רחוב": "שד בגין מנחם"
        },
        {
            "_id": 30689,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 144,
            "שם_רחוב": "שד גאולים"
        },
        {
            "_id": 30690,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 125,
            "שם_רחוב": "שדל"
        },
        {
            "_id": 30691,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 363,
            "שם_רחוב": "שדה יצחק"
        },
        {
            "_id": 30692,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 466,
            "שם_רחוב": "שדרת המסוק"
        },
        {
            "_id": 30693,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 227,
            "שם_רחוב": "שולמית"
        },
        {
            "_id": 30694,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 589,
            "שם_רחוב": "שומרון"
        },
        {
            "_id": 30695,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 548,
            "שם_רחוב": "שועלי שמשון"
        },
        {
            "_id": 30696,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 369,
            "שם_רחוב": "שחל"
        },
        {
            "_id": 30697,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 467,
            "שם_רחוב": "שטרן יאיר"
        },
        {
            "_id": 30698,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 446,
            "שם_רחוב": "שיבת ציון"
        },
        {
            "_id": 30699,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 293,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 30700,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 670,
            "שם_רחוב": "שיינפיין"
        },
        {
            "_id": 30701,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 447,
            "שם_רחוב": "שילר"
        },
        {
            "_id": 30702,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 448,
            "שם_רחוב": "שיפר"
        },
        {
            "_id": 30703,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ גאולים"
        },
        {
            "_id": 30704,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6519,
            "שם_רחוב": "שכ הזמר העברי"
        },
        {
            "_id": 30705,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ החלוצים"
        },
        {
            "_id": 30706,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6567,
            "שם_רחוב": "שכ הפארק"
        },
        {
            "_id": 30707,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6570,
            "שם_רחוב": "שכ חצרות הדר"
        },
        {
            "_id": 30708,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6520,
            "שם_רחוב": "שכ ירוקה"
        },
        {
            "_id": 30709,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6560,
            "שם_רחוב": "שכ כפר סבא הצעירה"
        },
        {
            "_id": 30710,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6568,
            "שם_רחוב": "שכ עלומים"
        },
        {
            "_id": 30711,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6569,
            "שם_רחוב": "שכ פעמוני גליל"
        },
        {
            "_id": 30712,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ רגבים"
        },
        {
            "_id": 30713,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 6527,
            "שם_רחוב": "שכון העובד"
        },
        {
            "_id": 30714,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 364,
            "שם_רחוב": "שלום עליכם"
        },
        {
            "_id": 30715,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 718,
            "שם_רחוב": "שלונסקי"
        },
        {
            "_id": 30716,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 135,
            "שם_רחוב": "שלמה המלך"
        },
        {
            "_id": 30717,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 454,
            "שם_רחוב": "שמחה הולצברג"
        },
        {
            "_id": 30718,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 371,
            "שם_רחוב": "שמחוני אסף"
        },
        {
            "_id": 30719,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 537,
            "שם_רחוב": "שמעוני"
        },
        {
            "_id": 30720,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 284,
            "שם_רחוב": "שמעיה הנביא"
        },
        {
            "_id": 30721,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 661,
            "שם_רחוב": "שמר נעמי"
        },
        {
            "_id": 30722,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 449,
            "שם_רחוב": "שמריהו לוין"
        },
        {
            "_id": 30723,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 611,
            "שם_רחוב": "שניר"
        },
        {
            "_id": 30724,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 372,
            "שם_רחוב": "שפרינצק"
        },
        {
            "_id": 30725,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 228,
            "שם_רחוב": "שרה"
        },
        {
            "_id": 30726,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 570,
            "שם_רחוב": "שרת משה"
        },
        {
            "_id": 30727,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 533,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 30728,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 605,
            "שם_רחוב": "תאשור"
        },
        {
            "_id": 30729,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 539,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 30730,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 604,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 30731,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 486,
            "שם_רחוב": "תור"
        },
        {
            "_id": 30732,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 373,
            "שם_רחוב": "תורה ועבודה"
        },
        {
            "_id": 30733,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 459,
            "שם_רחוב": "תל אביב-יפו"
        },
        {
            "_id": 30734,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 374,
            "שם_רחוב": "תל חי"
        },
        {
            "_id": 30735,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 476,
            "שם_רחוב": "תלם"
        },
        {
            "_id": 30736,
            "סמל_ישוב": 6900,
            "שם_ישוב": "כפר סבא ",
            "סמל_רחוב": 127,
            "שם_רחוב": "תרי עשר"
        },
        {
            "_id": 30737,
            "סמל_ישוב": 107,
            "שם_ישוב": "כפר סילבר ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר סילבר"
        },
        {
            "_id": 30738,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך אפק"
        },
        {
            "_id": 30739,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 113,
            "שם_רחוב": "דרך השדות"
        },
        {
            "_id": 30740,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 30741,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האירוסים"
        },
        {
            "_id": 30742,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הבנים"
        },
        {
            "_id": 30743,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הגפנים"
        },
        {
            "_id": 30744,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הדקלים"
        },
        {
            "_id": 30745,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 114,
            "שם_רחוב": "ההרדופים"
        },
        {
            "_id": 30746,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 30747,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הכלניות"
        },
        {
            "_id": 30748,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 109,
            "שם_רחוב": "המרגניות"
        },
        {
            "_id": 30749,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 30750,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הסביונים"
        },
        {
            "_id": 30751,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הקציר"
        },
        {
            "_id": 30752,
            "סמל_ישוב": 249,
            "שם_ישוב": "כפר סירקין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר סירקין"
        },
        {
            "_id": 30753,
            "סמל_ישוב": 875,
            "שם_ישוב": "כפר עבודה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר עבודה"
        },
        {
            "_id": 30754,
            "סמל_ישוב": 845,
            "שם_ישוב": "כפר עזה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר עזה"
        },
        {
            "_id": 30755,
            "סמל_ישוב": 3488,
            "שם_ישוב": "כפר עציון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ישיבת כפר עציון"
        },
        {
            "_id": 30756,
            "סמל_ישוב": 3488,
            "שם_ישוב": "כפר עציון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר עציון"
        },
        {
            "_id": 30757,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אוהל משה"
        },
        {
            "_id": 30758,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 111,
            "שם_רחוב": "דרך האלונים"
        },
        {
            "_id": 30759,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך השדות"
        },
        {
            "_id": 30760,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 108,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 30761,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 109,
            "שם_רחוב": "הזיתים"
        },
        {
            "_id": 30762,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 110,
            "שם_רחוב": "החלוץ"
        },
        {
            "_id": 30763,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הסילו"
        },
        {
            "_id": 30764,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הסליק"
        },
        {
            "_id": 30765,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הפועל המזרחי"
        },
        {
            "_id": 30766,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר פינס"
        },
        {
            "_id": 30767,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 105,
            "שם_רחוב": "מגדל המים"
        },
        {
            "_id": 30768,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 104,
            "שם_רחוב": "סמטת הגיבורים"
        },
        {
            "_id": 30769,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 114,
            "שם_רחוב": "קבוצת אברהם"
        },
        {
            "_id": 30770,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שלום קרניאל"
        },
        {
            "_id": 30771,
            "סמל_ישוב": 189,
            "שם_ישוב": "כפר פינס ",
            "סמל_רחוב": 107,
            "שם_רחוב": "תורה ועבודה"
        },
        {
            "_id": 30772,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 148,
            "שם_רחוב": "א זהרא"
        },
        {
            "_id": 30773,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 189,
            "שם_רחוב": "א ראזי"
        },
        {
            "_id": 30774,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 146,
            "שם_רחוב": "א רחמה"
        },
        {
            "_id": 30775,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 164,
            "שם_רחוב": "א שאפי"
        },
        {
            "_id": 30776,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 176,
            "שם_רחוב": "א שוהדא"
        },
        {
            "_id": 30777,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 143,
            "שם_רחוב": "א- זיתון"
        },
        {
            "_id": 30778,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 202,
            "שם_רחוב": "א- נוזהה"
        },
        {
            "_id": 30779,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 101,
            "שם_רחוב": "א- סולטאני"
        },
        {
            "_id": 30780,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 205,
            "שם_רחוב": "א- רדואן"
        },
        {
            "_id": 30781,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 106,
            "שם_רחוב": "א-נור"
        },
        {
            "_id": 30782,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 121,
            "שם_רחוב": "א-נסר"
        },
        {
            "_id": 30783,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 226,
            "שם_רחוב": "א-ספא"
        },
        {
            "_id": 30784,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 109,
            "שם_רחוב": "א-רבאט"
        },
        {
            "_id": 30785,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 219,
            "שם_רחוב": "א-רוודא"
        },
        {
            "_id": 30786,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 201,
            "שם_רחוב": "א-רמלה"
        },
        {
            "_id": 30787,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 118,
            "שם_רחוב": "א-רשיד"
        },
        {
            "_id": 30788,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 120,
            "שם_רחוב": "א-תוובה"
        },
        {
            "_id": 30789,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אבו בכר"
        },
        {
            "_id": 30790,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 132,
            "שם_רחוב": "אבו חניפה"
        },
        {
            "_id": 30791,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 105,
            "שם_רחוב": "אבו עמרן"
        },
        {
            "_id": 30792,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 335,
            "שם_רחוב": "אבן אלערבי"
        },
        {
            "_id": 30793,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 131,
            "שם_רחוב": "אבן חזם"
        },
        {
            "_id": 30794,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 130,
            "שם_רחוב": "אבן חלדון"
        },
        {
            "_id": 30795,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 334,
            "שם_רחוב": "אבן סירין"
        },
        {
            "_id": 30796,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 336,
            "שם_רחוב": "אבן קת'יר"
        },
        {
            "_id": 30797,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 30798,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אבן תיימייה"
        },
        {
            "_id": 30799,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 340,
            "שם_רחוב": "אברהים ח'ליל אללה"
        },
        {
            "_id": 30800,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אוחוד"
        },
        {
            "_id": 30801,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 6733,
            "שם_רחוב": "אזור שרובו בדואים"
        },
        {
            "_id": 30802,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 339,
            "שם_רחוב": "אחמד בן חנבל"
        },
        {
            "_id": 30803,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אחמד שוויקי"
        },
        {
            "_id": 30804,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 338,
            "שם_רחוב": "אל אוח'ווה"
        },
        {
            "_id": 30805,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אל אמל"
        },
        {
            "_id": 30806,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אל אנדלוס"
        },
        {
            "_id": 30807,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אל אנסאר"
        },
        {
            "_id": 30808,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אל אסרא"
        },
        {
            "_id": 30809,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אל אקצא"
        },
        {
            "_id": 30810,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אל ארד"
        },
        {
            "_id": 30811,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 301,
            "שם_רחוב": "אל ארז"
        },
        {
            "_id": 30812,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אל בורק"
        },
        {
            "_id": 30813,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אל ביאדר"
        },
        {
            "_id": 30814,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אל בלאד"
        },
        {
            "_id": 30815,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אל ג'לאני"
        },
        {
            "_id": 30816,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 218,
            "שם_רחוב": "אל הודא"
        },
        {
            "_id": 30817,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 117,
            "שם_רחוב": "אל היג'רה"
        },
        {
            "_id": 30818,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 323,
            "שם_רחוב": "אל וואחה"
        },
        {
            "_id": 30819,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 163,
            "שם_רחוב": "אל וחדה"
        },
        {
            "_id": 30820,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אל וטן"
        },
        {
            "_id": 30821,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 180,
            "שם_רחוב": "אל חליל"
        },
        {
            "_id": 30822,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אל חנדק"
        },
        {
            "_id": 30823,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אל חנסא"
        },
        {
            "_id": 30824,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אל חק"
        },
        {
            "_id": 30825,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אל חרם"
        },
        {
            "_id": 30826,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אל ירמוכ"
        },
        {
            "_id": 30827,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אל כראמה"
        },
        {
            "_id": 30828,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 318,
            "שם_רחוב": "אל לוז"
        },
        {
            "_id": 30829,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אל מג'דל"
        },
        {
            "_id": 30830,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אל מג'זרה"
        },
        {
            "_id": 30831,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אל מדינה"
        },
        {
            "_id": 30832,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אל מוהאריגון"
        },
        {
            "_id": 30833,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 113,
            "שם_רחוב": "אל מורוג"
        },
        {
            "_id": 30834,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אל מחפור"
        },
        {
            "_id": 30835,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אל מיזאן"
        },
        {
            "_id": 30836,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אל מנאר"
        },
        {
            "_id": 30837,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 145,
            "שם_רחוב": "אל מנשייה"
        },
        {
            "_id": 30838,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 242,
            "שם_רחוב": "אל מרווה"
        },
        {
            "_id": 30839,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אל ע'זאלי"
        },
        {
            "_id": 30840,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אל עוודה"
        },
        {
            "_id": 30841,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 320,
            "שם_רחוב": "אל עפוו"
        },
        {
            "_id": 30842,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אל עקאד"
        },
        {
            "_id": 30843,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 351,
            "שם_רחוב": "אל פל"
        },
        {
            "_id": 30844,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אל פתח"
        },
        {
            "_id": 30845,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 171,
            "שם_רחוב": "אל קודס"
        },
        {
            "_id": 30846,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 104,
            "שם_רחוב": "אל קסאם"
        },
        {
            "_id": 30847,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 365,
            "שם_רחוב": "אל קריה"
        },
        {
            "_id": 30848,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אל תופאח"
        },
        {
            "_id": 30849,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 362,
            "שם_רחוב": "אלאנפאל"
        },
        {
            "_id": 30850,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 376,
            "שם_רחוב": "אלבאבור"
        },
        {
            "_id": 30851,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 308,
            "שם_רחוב": "אלבהאא"
        },
        {
            "_id": 30852,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 329,
            "שם_רחוב": "אלבולבול"
        },
        {
            "_id": 30853,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 373,
            "שם_רחוב": "אלבלוט"
        },
        {
            "_id": 30854,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 357,
            "שם_רחוב": "אלבראעם"
        },
        {
            "_id": 30855,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 360,
            "שם_רחוב": "אלג'בל"
        },
        {
            "_id": 30856,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 383,
            "שם_רחוב": "אלג'ולאן"
        },
        {
            "_id": 30857,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 380,
            "שם_רחוב": "אלג'ורי"
        },
        {
            "_id": 30858,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 377,
            "שם_רחוב": "אלג'ליל"
        },
        {
            "_id": 30859,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 389,
            "שם_רחוב": "אלג'נאין"
        },
        {
            "_id": 30860,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 322,
            "שם_רחוב": "אלגאר"
        },
        {
            "_id": 30861,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 382,
            "שם_רחוב": "אלגרוב"
        },
        {
            "_id": 30862,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 352,
            "שם_רחוב": "אלדהאר"
        },
        {
            "_id": 30863,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 349,
            "שם_רחוב": "אלדיוואן"
        },
        {
            "_id": 30864,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 321,
            "שם_רחוב": "אלהודהוד"
        },
        {
            "_id": 30865,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 326,
            "שם_רחוב": "אלהלאל"
        },
        {
            "_id": 30866,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 353,
            "שם_רחוב": "אלוורד"
        },
        {
            "_id": 30867,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 306,
            "שם_רחוב": "אלזעתר"
        },
        {
            "_id": 30868,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 359,
            "שם_רחוב": "אלחבק"
        },
        {
            "_id": 30869,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 333,
            "שם_רחוב": "אלחמראא"
        },
        {
            "_id": 30870,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 388,
            "שם_רחוב": "אליאקוט"
        },
        {
            "_id": 30871,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 358,
            "שם_רחוב": "אליסמין"
        },
        {
            "_id": 30872,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 327,
            "שם_רחוב": "אלמג'ד"
        },
        {
            "_id": 30873,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 342,
            "שם_רחוב": "אלמדיאן"
        },
        {
            "_id": 30874,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 332,
            "שם_רחוב": "אלמונא"
        },
        {
            "_id": 30875,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 384,
            "שם_רחוב": "אלמעלב"
        },
        {
            "_id": 30876,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 348,
            "שם_רחוב": "אלמעראג'"
        },
        {
            "_id": 30877,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 381,
            "שם_רחוב": "אלמערפה"
        },
        {
            "_id": 30878,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 343,
            "שם_רחוב": "אלמקתבא"
        },
        {
            "_id": 30879,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 371,
            "שם_רחוב": "אלמרח"
        },
        {
            "_id": 30880,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 312,
            "שם_רחוב": "אלנבע"
        },
        {
            "_id": 30881,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אלנסים"
        },
        {
            "_id": 30882,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 304,
            "שם_רחוב": "אלנסרין"
        },
        {
            "_id": 30883,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 387,
            "שם_רחוב": "אלנקאא"
        },
        {
            "_id": 30884,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 344,
            "שם_רחוב": "אלסאחאר"
        },
        {
            "_id": 30885,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 364,
            "שם_רחוב": "אלסוק"
        },
        {
            "_id": 30886,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אלסיאד"
        },
        {
            "_id": 30887,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 374,
            "שם_רחוב": "אלסלאח"
        },
        {
            "_id": 30888,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 378,
            "שם_רחוב": "אלסמארה"
        },
        {
            "_id": 30889,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 309,
            "שם_רחוב": "אלסנאא"
        },
        {
            "_id": 30890,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 356,
            "שם_רחוב": "אלסנאבל"
        },
        {
            "_id": 30891,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 395,
            "שם_רחוב": "אלסנדיאן"
        },
        {
            "_id": 30892,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 372,
            "שם_רחוב": "אלסנובר"
        },
        {
            "_id": 30893,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 354,
            "שם_רחוב": "אלספסאף"
        },
        {
            "_id": 30894,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 315,
            "שם_רחוב": "אלסרוו"
        },
        {
            "_id": 30895,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 337,
            "שם_רחוב": "אלעדל"
        },
        {
            "_id": 30896,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 350,
            "שם_רחוב": "אלעטאא"
        },
        {
            "_id": 30897,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 313,
            "שם_רחוב": "אלפאנוס"
        },
        {
            "_id": 30898,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 347,
            "שם_רחוב": "אלפוראת"
        },
        {
            "_id": 30899,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אלקוות'ר"
        },
        {
            "_id": 30900,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 370,
            "שם_רחוב": "אלקנארי"
        },
        {
            "_id": 30901,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 341,
            "שם_רחוב": "אלראשדון"
        },
        {
            "_id": 30902,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 307,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 30903,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 391,
            "שם_רחוב": "אלשאם"
        },
        {
            "_id": 30904,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 305,
            "שם_רחוב": "אלשג'רה"
        },
        {
            "_id": 30905,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 379,
            "שם_רחוב": "אלשוויטא"
        },
        {
            "_id": 30906,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 392,
            "שם_רחוב": "אשביליה"
        },
        {
            "_id": 30907,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 361,
            "שם_רחוב": "בגדאד"
        },
        {
            "_id": 30908,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 166,
            "שם_רחוב": "בדר"
        },
        {
            "_id": 30909,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 178,
            "שם_רחוב": "בילאל"
        },
        {
            "_id": 30910,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 363,
            "שם_רחוב": "בירות"
        },
        {
            "_id": 30911,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 394,
            "שם_רחוב": "גרנאטא"
        },
        {
            "_id": 30912,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 181,
            "שם_רחוב": "דיר יאסין"
        },
        {
            "_id": 30913,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 330,
            "שם_רחוב": "דמשק"
        },
        {
            "_id": 30914,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "הגרעין הישן"
        },
        {
            "_id": 30915,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 366,
            "שם_רחוב": "הדקלים"
        },
        {
            "_id": 30916,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 367,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 30917,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 386,
            "שם_רחוב": "הפרדס"
        },
        {
            "_id": 30918,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 385,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 30919,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "השכונה החדשה"
        },
        {
            "_id": 30920,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 368,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 30921,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 369,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 30922,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 310,
            "שם_רחוב": "ודיע סרסור"
        },
        {
            "_id": 30923,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 345,
            "שם_רחוב": "זין"
        },
        {
            "_id": 30924,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 152,
            "שם_רחוב": "חאלד בן אל וליד"
        },
        {
            "_id": 30925,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 302,
            "שם_רחוב": "חבאיב"
        },
        {
            "_id": 30926,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 108,
            "שם_רחוב": "חטין"
        },
        {
            "_id": 30927,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 375,
            "שם_רחוב": "חיפה"
        },
        {
            "_id": 30928,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 231,
            "שם_רחוב": "חמזה"
        },
        {
            "_id": 30929,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 316,
            "שם_רחוב": "חנדלה"
        },
        {
            "_id": 30930,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 147,
            "שם_רחוב": "חסאן בן תאבת"
        },
        {
            "_id": 30931,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "טאהא"
        },
        {
            "_id": 30932,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 110,
            "שם_רחוב": "יאפא"
        },
        {
            "_id": 30933,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר קאסם"
        },
        {
            "_id": 30934,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 390,
            "שם_רחוב": "לשבונה"
        },
        {
            "_id": 30935,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 123,
            "שם_רחוב": "מאלכ בן אנס"
        },
        {
            "_id": 30936,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 142,
            "שם_רחוב": "מכה"
        },
        {
            "_id": 30937,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 331,
            "שם_רחוב": "מרים אלמג'דליה"
        },
        {
            "_id": 30938,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "משפחת עיסא"
        },
        {
            "_id": 30939,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 128,
            "שם_רחוב": "סלאח א-דין"
        },
        {
            "_id": 30940,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 346,
            "שם_רחוב": "ספד"
        },
        {
            "_id": 30941,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 244,
            "שם_רחוב": "עבד אל כרים קאסם"
        },
        {
            "_id": 30942,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 319,
            "שם_רחוב": "עדן"
        },
        {
            "_id": 30943,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 103,
            "שם_רחוב": "עומר בן אל חטאב"
        },
        {
            "_id": 30944,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 115,
            "שם_רחוב": "עומר בן עבד אל עזיז"
        },
        {
            "_id": 30945,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 150,
            "שם_רחוב": "עותמן אל עפאן"
        },
        {
            "_id": 30946,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 317,
            "שם_רחוב": "עכא"
        },
        {
            "_id": 30947,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 174,
            "שם_רחוב": "עלי בן אבו טאלב"
        },
        {
            "_id": 30948,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 355,
            "שם_רחוב": "עמאן"
        },
        {
            "_id": 30949,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "צרצור"
        },
        {
            "_id": 30950,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 324,
            "שם_רחוב": "קבאא"
        },
        {
            "_id": 30951,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 328,
            "שם_רחוב": "ראס אלח'רוב"
        },
        {
            "_id": 30952,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "שכ הבדואים הדרומית"
        },
        {
            "_id": 30953,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 6734,
            "שם_רחוב": "שכ הבדואים המזרחית"
        },
        {
            "_id": 30954,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 160,
            "שם_רחוב": "תבוכ"
        },
        {
            "_id": 30955,
            "סמל_ישוב": 634,
            "שם_ישוב": "כפר קאסם ",
            "סמל_רחוב": 230,
            "שם_רחוב": "תמרינדי"
        },
        {
            "_id": 30956,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 118,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 30957,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 103,
            "שם_רחוב": "האלה"
        },
        {
            "_id": 30958,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 30959,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הברושים"
        },
        {
            "_id": 30960,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 30961,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 30962,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 109,
            "שם_רחוב": "החורשה"
        },
        {
            "_id": 30963,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 115,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 30964,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 106,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 30965,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 114,
            "שם_רחוב": "השדות"
        },
        {
            "_id": 30966,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 108,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 30967,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 113,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 30968,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 107,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 30969,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 105,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 30970,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 120,
            "שם_רחוב": "כוכב הירדן"
        },
        {
            "_id": 30971,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 116,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 30972,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר קיש"
        },
        {
            "_id": 30973,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 101,
            "שם_רחוב": "נוף תבור"
        },
        {
            "_id": 30974,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 104,
            "שם_רחוב": "סימטת הגנים"
        },
        {
            "_id": 30975,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 119,
            "שם_רחוב": "סירין"
        },
        {
            "_id": 30976,
            "סמל_ישוב": 388,
            "שם_ישוב": "כפר קיש ",
            "סמל_רחוב": 117,
            "שם_רחוב": "תל רכש"
        },
        {
            "_id": 30977,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 176,
            "שם_רחוב": "א-ד'הרה"
        },
        {
            "_id": 30978,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 672,
            "שם_רחוב": "א-זעפראן"
        },
        {
            "_id": 30979,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 363,
            "שם_רחוב": "א-זערורה"
        },
        {
            "_id": 30980,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 391,
            "שם_רחוב": "א-נבי אדם"
        },
        {
            "_id": 30981,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 379,
            "שם_רחוב": "א-נג'ם"
        },
        {
            "_id": 30982,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 188,
            "שם_רחוב": "א-ניל"
        },
        {
            "_id": 30983,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 145,
            "שם_רחוב": "א-ס'יף"
        },
        {
            "_id": 30984,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 361,
            "שם_רחוב": "א-סולטאן"
        },
        {
            "_id": 30985,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 689,
            "שם_רחוב": "א-סיידה עאאישה"
        },
        {
            "_id": 30986,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 265,
            "שם_רחוב": "א-סיכה"
        },
        {
            "_id": 30987,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 238,
            "שם_רחוב": "א-סרוב"
        },
        {
            "_id": 30988,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 131,
            "שם_רחוב": "א-רביע"
        },
        {
            "_id": 30989,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 277,
            "שם_רחוב": "א-רוודה"
        },
        {
            "_id": 30990,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 387,
            "שם_רחוב": "א-ריאד"
        },
        {
            "_id": 30991,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 154,
            "שם_רחוב": "א-שיתאא"
        },
        {
            "_id": 30992,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 380,
            "שם_רחוב": "א-שעב"
        },
        {
            "_id": 30993,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 155,
            "שם_רחוב": "אאב"
        },
        {
            "_id": 30994,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 199,
            "שם_רחוב": "אאד'אר"
        },
        {
            "_id": 30995,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 675,
            "שם_רחוב": "אאיאת"
        },
        {
            "_id": 30996,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 648,
            "שם_רחוב": "אאל עמראן"
        },
        {
            "_id": 30997,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 232,
            "שם_רחוב": "אבו אלקאסם אלשאבי"
        },
        {
            "_id": 30998,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 200,
            "שם_רחוב": "אבו בכר אלסאדיק"
        },
        {
            "_id": 30999,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 147,
            "שם_רחוב": "אבו הוריירה"
        },
        {
            "_id": 31000,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 172,
            "שם_רחוב": "אבו זינה"
        },
        {
            "_id": 31001,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 290,
            "שם_רחוב": "אבו ח'ושום"
        },
        {
            "_id": 31002,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 184,
            "שם_רחוב": "אבו חניפה"
        },
        {
            "_id": 31003,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 244,
            "שם_רחוב": "אבו סופיאן"
        },
        {
            "_id": 31004,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 656,
            "שם_רחוב": "אבו עוביידה"
        },
        {
            "_id": 31005,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 234,
            "שם_רחוב": "אבו תמאם"
        },
        {
            "_id": 31006,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 311,
            "שם_רחוב": "אבן ח'לדון"
        },
        {
            "_id": 31007,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 119,
            "שם_רחוב": "אבן סינאא"
        },
        {
            "_id": 31008,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 682,
            "שם_רחוב": "אבן רושד"
        },
        {
            "_id": 31009,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 630,
            "שם_רחוב": "אבן תימייה"
        },
        {
            "_id": 31010,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 246,
            "שם_רחוב": "אוחוד"
        },
        {
            "_id": 31011,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 340,
            "שם_רחוב": "אום אל-בסל"
        },
        {
            "_id": 31012,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 309,
            "שם_רחוב": "אחמד שווקי"
        },
        {
            "_id": 31013,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 706,
            "שם_רחוב": "איאר"
        },
        {
            "_id": 31014,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 703,
            "שם_רחוב": "איבן בטוטא"
        },
        {
            "_id": 31015,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 316,
            "שם_רחוב": "אילול"
        },
        {
            "_id": 31016,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 275,
            "שם_רחוב": "אל-אחסאן"
        },
        {
            "_id": 31017,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 274,
            "שם_רחוב": "אל-אמאני"
        },
        {
            "_id": 31018,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 359,
            "שם_רחוב": "אל-אס'מאעי"
        },
        {
            "_id": 31019,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 390,
            "שם_רחוב": "אל-ארז"
        },
        {
            "_id": 31020,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 278,
            "שם_רחוב": "אל-ארקם"
        },
        {
            "_id": 31021,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 272,
            "שם_רחוב": "אל-באטן"
        },
        {
            "_id": 31022,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 296,
            "שם_רחוב": "אל-בורה"
        },
        {
            "_id": 31023,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 382,
            "שם_רחוב": "אל-בלוט"
        },
        {
            "_id": 31024,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 356,
            "שם_רחוב": "אל-בסה"
        },
        {
            "_id": 31025,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 388,
            "שם_רחוב": "אל-ברקוק"
        },
        {
            "_id": 31026,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 708,
            "שם_רחוב": "אל-ג'ורי"
        },
        {
            "_id": 31027,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 384,
            "שם_רחוב": "אל-ג'ילאני"
        },
        {
            "_id": 31028,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 362,
            "שם_רחוב": "אל-הודא"
        },
        {
            "_id": 31029,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 226,
            "שם_רחוב": "אל-וידאד"
        },
        {
            "_id": 31030,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 381,
            "שם_רחוב": "אל-ח'ואריזמי"
        },
        {
            "_id": 31031,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 357,
            "שם_רחוב": "אל-ח'יראת"
        },
        {
            "_id": 31032,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 288,
            "שם_רחוב": "אל-ח'ריף"
        },
        {
            "_id": 31033,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 354,
            "שם_רחוב": "אל-חוסיין בן עלי"
        },
        {
            "_id": 31034,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 377,
            "שם_רחוב": "אל-חורש"
        },
        {
            "_id": 31035,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 701,
            "שם_רחוב": "אל-חיאה"
        },
        {
            "_id": 31036,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 271,
            "שם_רחוב": "אל-חמרה"
        },
        {
            "_id": 31037,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 358,
            "שם_רחוב": "אל-יאקות"
        },
        {
            "_id": 31038,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 600,
            "שם_רחוב": "אל-כוכב"
        },
        {
            "_id": 31039,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 360,
            "שם_רחוב": "אל-מדינה אל-מנורה"
        },
        {
            "_id": 31040,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 141,
            "שם_רחוב": "אל-מונתזה"
        },
        {
            "_id": 31041,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 383,
            "שם_רחוב": "אל-מכתבה"
        },
        {
            "_id": 31042,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 660,
            "שם_רחוב": "אל-מנארה"
        },
        {
            "_id": 31043,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 335,
            "שם_רחוב": "אל-מראח'"
        },
        {
            "_id": 31044,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 639,
            "שם_רחוב": "אל-עדאלה"
        },
        {
            "_id": 31045,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 385,
            "שם_רחוב": "אל-עסל"
        },
        {
            "_id": 31046,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אל-עקד"
        },
        {
            "_id": 31047,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 389,
            "שם_רחוב": "אל-ערבי"
        },
        {
            "_id": 31048,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 318,
            "שם_רחוב": "אל-פוארס"
        },
        {
            "_id": 31049,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 276,
            "שם_רחוב": "אל-פיום"
        },
        {
            "_id": 31050,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 642,
            "שם_רחוב": "אל-קרע"
        },
        {
            "_id": 31051,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 247,
            "שם_רחוב": "אלאבראר"
        },
        {
            "_id": 31052,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 611,
            "שם_רחוב": "אלאדיב"
        },
        {
            "_id": 31053,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 328,
            "שם_רחוב": "אלאזדהאר"
        },
        {
            "_id": 31054,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 125,
            "שם_רחוב": "אלאזהר"
        },
        {
            "_id": 31055,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 151,
            "שם_רחוב": "אלאח'טל"
        },
        {
            "_id": 31056,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 695,
            "שם_רחוב": "אלאח'לאס"
        },
        {
            "_id": 31057,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 127,
            "שם_רחוב": "אלאחבה"
        },
        {
            "_id": 31058,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 169,
            "שם_רחוב": "אלאחראש"
        },
        {
            "_id": 31059,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 109,
            "שם_רחוב": "אלאמיר"
        },
        {
            "_id": 31060,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלאמירה"
        },
        {
            "_id": 31061,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 179,
            "שם_רחוב": "אלאמל"
        },
        {
            "_id": 31062,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 295,
            "שם_רחוב": "אלאנדלס"
        },
        {
            "_id": 31063,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 310,
            "שם_רחוב": "אלאנואר"
        },
        {
            "_id": 31064,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 217,
            "שם_רחוב": "אלאסראא"
        },
        {
            "_id": 31065,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 355,
            "שם_רחוב": "אלאציל"
        },
        {
            "_id": 31066,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 216,
            "שם_רחוב": "אלאקצה"
        },
        {
            "_id": 31067,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 638,
            "שם_רחוב": "אלאת'יר"
        },
        {
            "_id": 31068,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 298,
            "שם_רחוב": "אלאתאר"
        },
        {
            "_id": 31069,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 314,
            "שם_רחוב": "אלבאבור"
        },
        {
            "_id": 31070,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6701,
            "שם_רחוב": "אלבאר"
        },
        {
            "_id": 31071,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אלבוסתאן"
        },
        {
            "_id": 31072,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 126,
            "שם_רחוב": "אלבח'ארי"
        },
        {
            "_id": 31073,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 668,
            "שם_רחוב": "אלביאדה"
        },
        {
            "_id": 31074,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6725,
            "שם_רחוב": "אלביאדר"
        },
        {
            "_id": 31075,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 237,
            "שם_רחוב": "אלביר"
        },
        {
            "_id": 31076,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 115,
            "שם_רחוב": "אלבלסם"
        },
        {
            "_id": 31077,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 255,
            "שם_רחוב": "אלבנין"
        },
        {
            "_id": 31078,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 285,
            "שם_רחוב": "אלבראא"
        },
        {
            "_id": 31079,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 344,
            "שם_רחוב": "אלברג"
        },
        {
            "_id": 31080,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 676,
            "שם_רחוב": "אלבשרא"
        },
        {
            "_id": 31081,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 299,
            "שם_רחוב": "אלג'אמע"
        },
        {
            "_id": 31082,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6727,
            "שם_רחוב": "אלג'ולאן"
        },
        {
            "_id": 31083,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 698,
            "שם_רחוב": "אלג'נה"
        },
        {
            "_id": 31084,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 146,
            "שם_רחוב": "אלגאר"
        },
        {
            "_id": 31085,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 263,
            "שם_רחוב": "אלגדאוול"
        },
        {
            "_id": 31086,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 678,
            "שם_רחוב": "אלגדיר"
        },
        {
            "_id": 31087,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלגוז"
        },
        {
            "_id": 31088,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 239,
            "שם_רחוב": "אלגוזלאן"
        },
        {
            "_id": 31089,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 331,
            "שם_רחוב": "אלגזאלי"
        },
        {
            "_id": 31090,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 204,
            "שם_רחוב": "אלגנאת"
        },
        {
            "_id": 31091,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 666,
            "שם_רחוב": "אלדאחיה"
        },
        {
            "_id": 31092,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 327,
            "שם_רחוב": "אלדחא"
        },
        {
            "_id": 31093,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6720,
            "שם_רחוב": "אלדסת"
        },
        {
            "_id": 31094,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 661,
            "שם_רחוב": "אלהודהד"
        },
        {
            "_id": 31095,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 694,
            "שם_רחוב": "אלואסל"
        },
        {
            "_id": 31096,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 602,
            "שם_רחוב": "אלוואדי"
        },
        {
            "_id": 31097,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אלוורוד"
        },
        {
            "_id": 31098,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 647,
            "שם_רחוב": "אלוותאק"
        },
        {
            "_id": 31099,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 153,
            "שם_רחוב": "אלוחדה"
        },
        {
            "_id": 31100,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 187,
            "שם_רחוב": "אלוסילה"
        },
        {
            "_id": 31101,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 222,
            "שם_רחוב": "אלוסים"
        },
        {
            "_id": 31102,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 688,
            "שם_רחוב": "אלועד"
        },
        {
            "_id": 31103,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 332,
            "שם_רחוב": "אלזהור"
        },
        {
            "_id": 31104,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 304,
            "שם_רחוב": "אלזהרא"
        },
        {
            "_id": 31105,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 650,
            "שם_רחוב": "אלזהראא"
        },
        {
            "_id": 31106,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 693,
            "שם_רחוב": "אלזהרייה"
        },
        {
            "_id": 31107,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 326,
            "שם_רחוב": "אלזובייר בן אלעואם"
        },
        {
            "_id": 31108,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 301,
            "שם_רחוב": "אלזינה"
        },
        {
            "_id": 31109,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 193,
            "שם_רחוב": "אלזיתון"
        },
        {
            "_id": 31110,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 213,
            "שם_רחוב": "אלזנבק"
        },
        {
            "_id": 31111,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 636,
            "שם_רחוב": "אלזנג'ביל"
        },
        {
            "_id": 31112,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 680,
            "שם_רחוב": "אלזעפראני"
        },
        {
            "_id": 31113,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 627,
            "שם_רחוב": "אלח'דר"
        },
        {
            "_id": 31114,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 674,
            "שם_רחוב": "אלח'לוד"
        },
        {
            "_id": 31115,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 338,
            "שם_רחוב": "אלח'רוב"
        },
        {
            "_id": 31116,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6722,
            "שם_רחוב": "אלחווארנה"
        },
        {
            "_id": 31117,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 673,
            "שם_רחוב": "אלחור"
        },
        {
            "_id": 31118,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 626,
            "שם_רחוב": "אלחוריה"
        },
        {
            "_id": 31119,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 353,
            "שם_רחוב": "אלחכים"
        },
        {
            "_id": 31120,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 118,
            "שם_רחוב": "אלחכמה"
        },
        {
            "_id": 31121,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 223,
            "שם_רחוב": "אלחלווה"
        },
        {
            "_id": 31122,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 181,
            "שם_רחוב": "אלחמאמה"
        },
        {
            "_id": 31123,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 267,
            "שם_רחוב": "אלחמה"
        },
        {
            "_id": 31124,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 227,
            "שם_רחוב": "אלחנאן"
        },
        {
            "_id": 31125,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 209,
            "שם_רחוב": "אלחנין"
        },
        {
            "_id": 31126,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 303,
            "שם_רחוב": "אלחנסאא"
        },
        {
            "_id": 31127,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אלחסאד"
        },
        {
            "_id": 31128,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 161,
            "שם_רחוב": "אלחסון"
        },
        {
            "_id": 31129,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 144,
            "שם_רחוב": "אלחסנאת"
        },
        {
            "_id": 31130,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6730,
            "שם_רחוב": "אלט'הר"
        },
        {
            "_id": 31131,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 641,
            "שם_רחוב": "אלטיוור"
        },
        {
            "_id": 31132,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 192,
            "שם_רחוב": "אלטלה"
        },
        {
            "_id": 31133,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 240,
            "שם_רחוב": "אליאסמין"
        },
        {
            "_id": 31134,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 319,
            "שם_רחוב": "אליקין"
        },
        {
            "_id": 31135,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 128,
            "שם_רחוב": "אלירמוק"
        },
        {
            "_id": 31136,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 612,
            "שם_רחוב": "אלכוות'ר"
        },
        {
            "_id": 31137,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 111,
            "שם_רחוב": "אלכרם"
        },
        {
            "_id": 31138,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 677,
            "שם_רחוב": "אלכתאב"
        },
        {
            "_id": 31139,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 133,
            "שם_רחוב": "אללוטוס"
        },
        {
            "_id": 31140,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 150,
            "שם_רחוב": "אלליל"
        },
        {
            "_id": 31141,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 345,
            "שם_רחוב": "אלמדארס"
        },
        {
            "_id": 31142,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6712,
            "שם_רחוב": "אלמהד"
        },
        {
            "_id": 31143,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 349,
            "שם_רחוב": "אלמוודה"
        },
        {
            "_id": 31144,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 266,
            "שם_רחוב": "אלמותנבי"
        },
        {
            "_id": 31145,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 635,
            "שם_רחוב": "אלמזאר"
        },
        {
            "_id": 31146,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 619,
            "שם_רחוב": "אלמזארע"
        },
        {
            "_id": 31147,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6743,
            "שם_רחוב": "אלמזע'מתה"
        },
        {
            "_id": 31148,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 205,
            "שם_רחוב": "אלמח'תאר"
        },
        {
            "_id": 31149,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6747,
            "שם_רחוב": "אלמטלה"
        },
        {
            "_id": 31150,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 334,
            "שם_רחוב": "אלמיזאן"
        },
        {
            "_id": 31151,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 687,
            "שם_רחוב": "אלמיעאד"
        },
        {
            "_id": 31152,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 300,
            "שם_רחוב": "אלמית'אק"
        },
        {
            "_id": 31153,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 210,
            "שם_רחוב": "אלמנסור"
        },
        {
            "_id": 31154,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 620,
            "שם_רחוב": "אלמנסורה"
        },
        {
            "_id": 31155,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אלמערפה"
        },
        {
            "_id": 31156,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6711,
            "שם_רחוב": "אלמפאח'ר"
        },
        {
            "_id": 31157,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6742,
            "שם_רחוב": "אלמקאייל"
        },
        {
            "_id": 31158,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 696,
            "שם_רחוב": "אלמראעי"
        },
        {
            "_id": 31159,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אלמרג"
        },
        {
            "_id": 31160,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אלמרג'אן"
        },
        {
            "_id": 31161,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 214,
            "שם_רחוב": "אלמרווה"
        },
        {
            "_id": 31162,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 114,
            "שם_רחוב": "אלמשמש"
        },
        {
            "_id": 31163,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אלנגאח"
        },
        {
            "_id": 31164,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 251,
            "שם_רחוב": "אלנגוא"
        },
        {
            "_id": 31165,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 320,
            "שם_רחוב": "אלנדאא"
        },
        {
            "_id": 31166,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 616,
            "שם_רחוב": "אלנהאר"
        },
        {
            "_id": 31167,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 621,
            "שם_רחוב": "אלנהר"
        },
        {
            "_id": 31168,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אלנוורס"
        },
        {
            "_id": 31169,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 143,
            "שם_רחוב": "אלנור"
        },
        {
            "_id": 31170,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 603,
            "שם_רחוב": "אלנזהה"
        },
        {
            "_id": 31171,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 254,
            "שם_רחוב": "אלנזלה"
        },
        {
            "_id": 31172,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 221,
            "שם_רחוב": "אלנח'יל"
        },
        {
            "_id": 31173,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 112,
            "שם_רחוב": "אלנח'לה"
        },
        {
            "_id": 31174,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 622,
            "שם_רחוב": "אלנסים"
        },
        {
            "_id": 31175,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 649,
            "שם_רחוב": "אלנסר"
        },
        {
            "_id": 31176,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 116,
            "שם_רחוב": "אלנעים"
        },
        {
            "_id": 31177,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 186,
            "שם_רחוב": "אלנעמאן"
        },
        {
            "_id": 31178,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 351,
            "שם_רחוב": "אלנרג'ס"
        },
        {
            "_id": 31179,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 352,
            "שם_רחוב": "אלסאלחייה"
        },
        {
            "_id": 31180,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 610,
            "שם_רחוב": "אלסאפי"
        },
        {
            "_id": 31181,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 702,
            "שם_רחוב": "אלסבאח"
        },
        {
            "_id": 31182,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 606,
            "שם_רחוב": "אלסבע"
        },
        {
            "_id": 31183,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 683,
            "שם_רחוב": "אלסד"
        },
        {
            "_id": 31184,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 137,
            "שם_רחוב": "אלסד אלעאלי"
        },
        {
            "_id": 31185,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 631,
            "שם_רחוב": "אלסדאקה"
        },
        {
            "_id": 31186,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 166,
            "שם_רחוב": "אלסדרה"
        },
        {
            "_id": 31187,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6702,
            "שם_רחוב": "אלסואניה"
        },
        {
            "_id": 31188,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 286,
            "שם_רחוב": "אלסונדס"
        },
        {
            "_id": 31189,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 658,
            "שם_רחוב": "אלסוסן"
        },
        {
            "_id": 31190,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 608,
            "שם_רחוב": "אלסופסאף"
        },
        {
            "_id": 31191,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 350,
            "שם_רחוב": "אלסחאבה"
        },
        {
            "_id": 31192,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 225,
            "שם_רחוב": "אלסחווה"
        },
        {
            "_id": 31193,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 256,
            "שם_רחוב": "אלסידה אמנה"
        },
        {
            "_id": 31194,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 260,
            "שם_רחוב": "אלסידה האגר"
        },
        {
            "_id": 31195,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 170,
            "שם_רחוב": "אלסידה זינב"
        },
        {
            "_id": 31196,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 233,
            "שם_רחוב": "אלסידה סארה"
        },
        {
            "_id": 31197,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 257,
            "שם_רחוב": "אלסידה סומייה"
        },
        {
            "_id": 31198,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 220,
            "שם_רחוב": "אלסלאם"
        },
        {
            "_id": 31199,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 618,
            "שם_רחוב": "אלסלוא"
        },
        {
            "_id": 31200,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 281,
            "שם_רחוב": "אלסלמה"
        },
        {
            "_id": 31201,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 149,
            "שם_רחוב": "אלסנאבל"
        },
        {
            "_id": 31202,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 245,
            "שם_רחוב": "אלסנדיאני"
        },
        {
            "_id": 31203,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 156,
            "שם_רחוב": "אלסנובר"
        },
        {
            "_id": 31204,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 123,
            "שם_רחוב": "אלסקר"
        },
        {
            "_id": 31205,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 297,
            "שם_רחוב": "אלסראג"
        },
        {
            "_id": 31206,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 632,
            "שם_רחוב": "אלסרווה"
        },
        {
            "_id": 31207,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 211,
            "שם_רחוב": "אלסריס"
        },
        {
            "_id": 31208,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 337,
            "שם_רחוב": "אלעבאס"
        },
        {
            "_id": 31209,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6721,
            "שם_רחוב": "אלעדאסה"
        },
        {
            "_id": 31210,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 679,
            "שם_רחוב": "אלעוד"
        },
        {
            "_id": 31211,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 615,
            "שם_רחוב": "אלעזבה"
        },
        {
            "_id": 31212,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 342,
            "שם_רחוב": "אלעין"
        },
        {
            "_id": 31213,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6733,
            "שם_רחוב": "אלעירק"
        },
        {
            "_id": 31214,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 129,
            "שם_רחוב": "אלעלם"
        },
        {
            "_id": 31215,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אלעמור"
        },
        {
            "_id": 31216,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 605,
            "שם_רחוב": "אלענב"
        },
        {
            "_id": 31217,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 348,
            "שם_רחוב": "אלענדליב"
        },
        {
            "_id": 31218,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 637,
            "שם_רחוב": "אלעסר"
        },
        {
            "_id": 31219,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 158,
            "שם_רחוב": "אלערק"
        },
        {
            "_id": 31220,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 252,
            "שם_רחוב": "אלפארוק"
        },
        {
            "_id": 31221,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 347,
            "שם_רחוב": "אלפג'ר"
        },
        {
            "_id": 31222,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 287,
            "שם_רחוב": "אלפוראת"
        },
        {
            "_id": 31223,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 330,
            "שם_רחוב": "אלפראבי"
        },
        {
            "_id": 31224,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 643,
            "שם_רחוב": "אלפרדוס"
        },
        {
            "_id": 31225,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אלפרזדק"
        },
        {
            "_id": 31226,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 305,
            "שם_רחוב": "אלפרח"
        },
        {
            "_id": 31227,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 219,
            "שם_רחוב": "אלפתח"
        },
        {
            "_id": 31228,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 657,
            "שם_רחוב": "אלצדיק"
        },
        {
            "_id": 31229,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 613,
            "שם_רחוב": "אלצפא"
        },
        {
            "_id": 31230,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 168,
            "שם_רחוב": "אלקאדי"
        },
        {
            "_id": 31231,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6734,
            "שם_רחוב": "אלקבארה"
        },
        {
            "_id": 31232,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 139,
            "שם_רחוב": "אלקודס"
        },
        {
            "_id": 31233,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 215,
            "שם_רחוב": "אלקורנפול"
        },
        {
            "_id": 31234,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6740,
            "שם_רחוב": "אלקטאין"
        },
        {
            "_id": 31235,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 167,
            "שם_רחוב": "אלקמר"
        },
        {
            "_id": 31236,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 329,
            "שם_רחוב": "אלראזי"
        },
        {
            "_id": 31237,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 207,
            "שם_רחוב": "אלרבאט"
        },
        {
            "_id": 31238,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 646,
            "שם_רחוב": "אלרג'ד"
        },
        {
            "_id": 31239,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 284,
            "שם_רחוב": "אלרוחה"
        },
        {
            "_id": 31240,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 644,
            "שם_רחוב": "אלרחיק"
        },
        {
            "_id": 31241,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אלרחמה"
        },
        {
            "_id": 31242,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אלריאן"
        },
        {
            "_id": 31243,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 700,
            "שם_רחוב": "אלריחאן"
        },
        {
            "_id": 31244,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 604,
            "שם_רחוב": "אלרמאן"
        },
        {
            "_id": 31245,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אלרסאם"
        },
        {
            "_id": 31246,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 645,
            "שם_רחוב": "אלרשאד"
        },
        {
            "_id": 31247,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 343,
            "שם_רחוב": "אלשאם"
        },
        {
            "_id": 31248,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 697,
            "שם_רחוב": "אלשאפעי"
        },
        {
            "_id": 31249,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 333,
            "שם_רחוב": "אלשוגעאן"
        },
        {
            "_id": 31250,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 148,
            "שם_רחוב": "אלשיח'"
        },
        {
            "_id": 31251,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 235,
            "שם_רחוב": "אלשמס"
        },
        {
            "_id": 31252,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 614,
            "שם_רחוב": "אלתין"
        },
        {
            "_id": 31253,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 339,
            "שם_רחוב": "אלתל"
        },
        {
            "_id": 31254,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 664,
            "שם_רחוב": "אלתנסים"
        },
        {
            "_id": 31255,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 699,
            "שם_רחוב": "אלתקוא"
        },
        {
            "_id": 31256,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 262,
            "שם_רחוב": "אם אלגמאל"
        },
        {
            "_id": 31257,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6728,
            "שם_רחוב": "אם אלנור"
        },
        {
            "_id": 31258,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 259,
            "שם_רחוב": "אם ח'אלד"
        },
        {
            "_id": 31259,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6704,
            "שם_רחוב": "אם סליטי"
        },
        {
            "_id": 31260,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 294,
            "שם_רחוב": "אסמאא בנת אבו בכר"
        },
        {
            "_id": 31261,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 243,
            "שם_רחוב": "בדר"
        },
        {
            "_id": 31262,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 174,
            "שם_רחוב": "בורג' אלחמאם"
        },
        {
            "_id": 31263,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 261,
            "שם_רחוב": "בורקה"
        },
        {
            "_id": 31264,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 202,
            "שם_רחוב": "ביאדרנא"
        },
        {
            "_id": 31265,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6746,
            "שם_רחוב": "בין אלדרב"
        },
        {
            "_id": 31266,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 164,
            "שם_רחוב": "ביסאן"
        },
        {
            "_id": 31267,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 195,
            "שם_רחוב": "בלאל בן רבאח"
        },
        {
            "_id": 31268,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 324,
            "שם_רחוב": "ג'ודי"
        },
        {
            "_id": 31269,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 183,
            "שם_רחוב": "ג'מאדי א-ת'אני"
        },
        {
            "_id": 31270,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ג'מאדי אל-אוול"
        },
        {
            "_id": 31271,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 629,
            "שם_רחוב": "ג'נא"
        },
        {
            "_id": 31272,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 601,
            "שם_רחוב": "ג'נאת"
        },
        {
            "_id": 31273,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 667,
            "שם_רחוב": "גאר חראא"
        },
        {
            "_id": 31274,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 291,
            "שם_רחוב": "ד'ו אל-חיג'ה"
        },
        {
            "_id": 31275,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 269,
            "שם_רחוב": "ד'ו אל-קעדה"
        },
        {
            "_id": 31276,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6715,
            "שם_רחוב": "דבת אלמוע'ור"
        },
        {
            "_id": 31277,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 132,
            "שם_רחוב": "דהרת גבר"
        },
        {
            "_id": 31278,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6735,
            "שם_רחוב": "דחדאח אבו מורא"
        },
        {
            "_id": 31279,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 229,
            "שם_רחוב": "הארון אלרשיד"
        },
        {
            "_id": 31280,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 623,
            "שם_רחוב": "ואדי אלחמאם"
        },
        {
            "_id": 31281,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 341,
            "שם_רחוב": "וורדה"
        },
        {
            "_id": 31282,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 634,
            "שם_רחוב": "זריף אלטול"
        },
        {
            "_id": 31283,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 228,
            "שם_רחוב": "ח'אלד בן אלוליד"
        },
        {
            "_id": 31284,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 690,
            "שם_רחוב": "ח'ביזה"
        },
        {
            "_id": 31285,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6726,
            "שם_רחוב": "ח'לאיל אלעביד"
        },
        {
            "_id": 31286,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 231,
            "שם_רחוב": "ח'ליל אלסכאכיני"
        },
        {
            "_id": 31287,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6716,
            "שם_רחוב": "ח'לת אלחיזווה"
        },
        {
            "_id": 31288,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6713,
            "שם_רחוב": "ח'לת אלסקיע"
        },
        {
            "_id": 31289,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6717,
            "שם_רחוב": "ח'לת זבדה"
        },
        {
            "_id": 31290,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6741,
            "שם_רחוב": "ח'לת שלח"
        },
        {
            "_id": 31291,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 171,
            "שם_רחוב": "חאן אלחלילי"
        },
        {
            "_id": 31292,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6736,
            "שם_רחוב": "חאקורת אלעסלי"
        },
        {
            "_id": 31293,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 640,
            "שם_רחוב": "חאתם אלטאאי"
        },
        {
            "_id": 31294,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 292,
            "שם_רחוב": "חוש ברדק"
        },
        {
            "_id": 31295,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 336,
            "שם_רחוב": "חזיראן"
        },
        {
            "_id": 31296,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 313,
            "שם_רחוב": "חיטין"
        },
        {
            "_id": 31297,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 289,
            "שם_רחוב": "חמזה בן עבד אלמוטלב"
        },
        {
            "_id": 31298,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 142,
            "שם_רחוב": "חסאן בן תאבת"
        },
        {
            "_id": 31299,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 652,
            "שם_רחוב": "חסנאא"
        },
        {
            "_id": 31300,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6729,
            "שם_רחוב": "ט'אהר יאסין"
        },
        {
            "_id": 31301,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6744,
            "שם_רחוב": "ט'הרת אבו סלימאן"
        },
        {
            "_id": 31302,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6749,
            "שם_רחוב": "ט'הרת אל סנדחאווי"
        },
        {
            "_id": 31303,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6748,
            "שם_רחוב": "ט'הרת נח'לה"
        },
        {
            "_id": 31304,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 121,
            "שם_רחוב": "טאהא חוסיין"
        },
        {
            "_id": 31305,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 113,
            "שם_רחוב": "טאריק אל-ווסייה"
        },
        {
            "_id": 31306,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 152,
            "שם_רחוב": "טארק בן זיאד"
        },
        {
            "_id": 31307,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 177,
            "שם_רחוב": "טיבה"
        },
        {
            "_id": 31308,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 370,
            "שם_רחוב": "טריק א-דסת"
        },
        {
            "_id": 31309,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 107,
            "שם_רחוב": "טריק א-סנדחאווי"
        },
        {
            "_id": 31310,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 378,
            "שם_רחוב": "טריק א-ריחאנה"
        },
        {
            "_id": 31311,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 280,
            "שם_רחוב": "טריק אל-ביאדר"
        },
        {
            "_id": 31312,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 369,
            "שם_רחוב": "טריק אל-ג'ולאן"
        },
        {
            "_id": 31313,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 366,
            "שם_רחוב": "טריק אל-חוארנה"
        },
        {
            "_id": 31314,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 364,
            "שם_רחוב": "טריק אל-כבארה"
        },
        {
            "_id": 31315,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 371,
            "שם_רחוב": "טריק אל-מזר'מתה"
        },
        {
            "_id": 31316,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 374,
            "שם_רחוב": "טריק אל-מטלה"
        },
        {
            "_id": 31317,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 373,
            "שם_רחוב": "טריק אל-מפאח'יר"
        },
        {
            "_id": 31318,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 110,
            "שם_רחוב": "טריק אל-מקאייל"
        },
        {
            "_id": 31319,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 368,
            "שם_רחוב": "טריק אל-עדאסה"
        },
        {
            "_id": 31320,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 367,
            "שם_רחוב": "טריק אל-קטאיין"
        },
        {
            "_id": 31321,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 375,
            "שם_רחוב": "טריק בין א-דורוב"
        },
        {
            "_id": 31322,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 365,
            "שם_רחוב": "טריק ח'לת א-שלח"
        },
        {
            "_id": 31323,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 372,
            "שם_רחוב": "טריק סדר אל-ערוס"
        },
        {
            "_id": 31324,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 376,
            "שם_רחוב": "טריק סתאלין"
        },
        {
            "_id": 31325,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 108,
            "שם_רחוב": "יאסמינה"
        },
        {
            "_id": 31326,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 138,
            "שם_רחוב": "יאפה"
        },
        {
            "_id": 31327,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 317,
            "שם_רחוב": "יזיד בן מועאוויה"
        },
        {
            "_id": 31328,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 212,
            "שם_רחוב": "כאנון א-ת'אני"
        },
        {
            "_id": 31329,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 230,
            "שם_רחוב": "כאנון אל-אול"
        },
        {
            "_id": 31330,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר קרע"
        },
        {
            "_id": 31331,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 190,
            "שם_רחוב": "ליאלינא"
        },
        {
            "_id": 31332,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 662,
            "שם_רחוב": "מוחמד אלפאתח"
        },
        {
            "_id": 31333,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 282,
            "שם_רחוב": "מוחרם"
        },
        {
            "_id": 31334,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 705,
            "שם_רחוב": "מוסא בן נוסייר"
        },
        {
            "_id": 31335,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 270,
            "שם_רחוב": "מועאוייה"
        },
        {
            "_id": 31336,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 140,
            "שם_רחוב": "מכה אלמכרמה"
        },
        {
            "_id": 31337,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6732,
            "שם_רחוב": "מקטל אבו אללימון"
        },
        {
            "_id": 31338,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 624,
            "שם_רחוב": "מרג' בן עאמר"
        },
        {
            "_id": 31339,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 178,
            "שם_רחוב": "מרג'אנה"
        },
        {
            "_id": 31340,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 173,
            "שם_רחוב": "מרים"
        },
        {
            "_id": 31341,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6724,
            "שם_רחוב": "מרכז הכפר"
        },
        {
            "_id": 31342,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 250,
            "שם_רחוב": "נדאא אלחק"
        },
        {
            "_id": 31343,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 306,
            "שם_רחוב": "נוסרין"
        },
        {
            "_id": 31344,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 196,
            "שם_רחוב": "ניסאן"
        },
        {
            "_id": 31345,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 651,
            "שם_רחוב": "סג'א"
        },
        {
            "_id": 31346,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6745,
            "שם_רחוב": "סדר אבו אחמד"
        },
        {
            "_id": 31347,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6731,
            "שם_רחוב": "סדר אלערוס"
        },
        {
            "_id": 31348,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 322,
            "שם_רחוב": "סולימאן אלפרסי"
        },
        {
            "_id": 31349,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 659,
            "שם_רחוב": "סונבולה"
        },
        {
            "_id": 31350,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 308,
            "שם_רחוב": "סונדס"
        },
        {
            "_id": 31351,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 684,
            "שם_רחוב": "סוק עוקאד"
        },
        {
            "_id": 31352,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 386,
            "שם_רחוב": "סחלב"
        },
        {
            "_id": 31353,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6710,
            "שם_רחוב": "סטלין"
        },
        {
            "_id": 31354,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 279,
            "שם_רחוב": "סיוואר"
        },
        {
            "_id": 31355,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 206,
            "שם_רחוב": "סירין"
        },
        {
            "_id": 31356,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 670,
            "שם_רחוב": "סלאח א-דין"
        },
        {
            "_id": 31357,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 323,
            "שם_רחוב": "סלמא"
        },
        {
            "_id": 31358,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 686,
            "שם_רחוב": "סלסביל"
        },
        {
            "_id": 31359,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 218,
            "שם_רחוב": "סנאבל"
        },
        {
            "_id": 31360,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 242,
            "שם_רחוב": "ספר"
        },
        {
            "_id": 31361,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 224,
            "שם_רחוב": "סת אלחבאיב"
        },
        {
            "_id": 31362,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 625,
            "שם_רחוב": "עאבר אלנזאזה"
        },
        {
            "_id": 31363,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 163,
            "שם_רחוב": "עאישה בנת אבו בכר"
        },
        {
            "_id": 31364,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 654,
            "שם_רחוב": "עאר תור"
        },
        {
            "_id": 31365,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 315,
            "שם_רחוב": "עומר אלמכתאר"
        },
        {
            "_id": 31366,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 293,
            "שם_רחוב": "עומר בן אלח'טאב"
        },
        {
            "_id": 31367,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 248,
            "שם_רחוב": "עותמאן בן עפאן"
        },
        {
            "_id": 31368,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 681,
            "שם_רחוב": "עין אלחלווה"
        },
        {
            "_id": 31369,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 691,
            "שם_רחוב": "עין וורדה"
        },
        {
            "_id": 31370,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 307,
            "שם_רחוב": "עין חוד"
        },
        {
            "_id": 31371,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 692,
            "שם_רחוב": "עין שחאדה"
        },
        {
            "_id": 31372,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 175,
            "שם_רחוב": "עלי בן אבי טאלב"
        },
        {
            "_id": 31373,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 203,
            "שם_רחוב": "עלי חידר זחאלקה"
        },
        {
            "_id": 31374,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 197,
            "שם_רחוב": "עמאר בן יאסר"
        },
        {
            "_id": 31375,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 312,
            "שם_רחוב": "עמר בן אלעאס"
        },
        {
            "_id": 31376,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 160,
            "שם_רחוב": "עספור"
        },
        {
            "_id": 31377,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 685,
            "שם_רחוב": "עספור אלג'נה"
        },
        {
            "_id": 31378,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 117,
            "שם_רחוב": "פאטמה א-זהראא"
        },
        {
            "_id": 31379,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 182,
            "שם_רחוב": "צור"
        },
        {
            "_id": 31380,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 704,
            "שם_רחוב": "צידא"
        },
        {
            "_id": 31381,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 194,
            "שם_רחוב": "קבאא אלדאירי"
        },
        {
            "_id": 31382,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6703,
            "שם_רחוב": "קעקור אלעבסה"
        },
        {
            "_id": 31383,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6714,
            "שם_רחוב": "קרית החינוך"
        },
        {
            "_id": 31384,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 321,
            "שם_רחוב": "ראבעה אלעדויה"
        },
        {
            "_id": 31385,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 665,
            "שם_רחוב": "ראמה"
        },
        {
            "_id": 31386,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רביע א-ת'אני"
        },
        {
            "_id": 31387,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 253,
            "שם_רחוב": "רביע אל-אוול"
        },
        {
            "_id": 31388,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 135,
            "שם_רחוב": "רג'ב"
        },
        {
            "_id": 31389,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 283,
            "שם_רחוב": "ריחאנה"
        },
        {
            "_id": 31390,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 268,
            "שם_רחוב": "רמדאן"
        },
        {
            "_id": 31391,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 165,
            "שם_רחוב": "רפידה"
        },
        {
            "_id": 31392,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 208,
            "שם_רחוב": "שבאט"
        },
        {
            "_id": 31393,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 273,
            "שם_רחוב": "שוואל"
        },
        {
            "_id": 31394,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 258,
            "שם_רחוב": "שט אלערב"
        },
        {
            "_id": 31395,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6723,
            "שם_רחוב": "שכ אלווסיה"
        },
        {
            "_id": 31396,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 264,
            "שם_רחוב": "שעבאן"
        },
        {
            "_id": 31397,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 6737,
            "שם_רחוב": "שקיף זאייד"
        },
        {
            "_id": 31398,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 122,
            "שם_רחוב": "תופיק אלחכים"
        },
        {
            "_id": 31399,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 707,
            "שם_רחוב": "תופיק זייאד"
        },
        {
            "_id": 31400,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 241,
            "שם_רחוב": "תישרין"
        },
        {
            "_id": 31401,
            "סמל_ישוב": 654,
            "שם_ישוב": "כפר קרע ",
            "סמל_רחוב": 180,
            "שם_רחוב": "תמוז"
        },
        {
            "_id": 31402,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "דרך דרומית"
        },
        {
            "_id": 31403,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דרך הים"
        },
        {
            "_id": 31404,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "דרך המייסדים"
        },
        {
            "_id": 31405,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "דרך המעגל"
        },
        {
            "_id": 31406,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "דרך צפונית"
        },
        {
            "_id": 31407,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר ראש הנקרה"
        },
        {
            "_id": 31408,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מעלה הגבעה"
        },
        {
            "_id": 31409,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 31410,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 109,
            "שם_רחוב": "משעול הארז"
        },
        {
            "_id": 31411,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "נוף הים"
        },
        {
            "_id": 31412,
            "סמל_ישוב": 579,
            "שם_ישוב": "כפר ראש הנקרה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "רכס הסולם"
        },
        {
            "_id": 31413,
            "סמל_ישוב": 1130,
            "שם_ישוב": "כפר רוזנואלד (זרעית) ",
            "סמל_רחוב": 101,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 31414,
            "סמל_ישוב": 1130,
            "שם_ישוב": "כפר רוזנואלד (זרעית) ",
            "סמל_רחוב": 107,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 31415,
            "סמל_ישוב": 1130,
            "שם_ישוב": "כפר רוזנואלד (זרעית) ",
            "סמל_רחוב": 106,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 31416,
            "סמל_ישוב": 1130,
            "שם_ישוב": "כפר רוזנואלד (זרעית) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר רוזנואלד"
        },
        {
            "_id": 31417,
            "סמל_ישוב": 1130,
            "שם_ישוב": "כפר רוזנואלד (זרעית) ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 31418,
            "סמל_ישוב": 1130,
            "שם_ישוב": "כפר רוזנואלד (זרעית) ",
            "סמל_רחוב": 103,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 31419,
            "סמל_ישוב": 1130,
            "שם_ישוב": "כפר רוזנואלד (זרעית) ",
            "סמל_רחוב": 102,
            "שם_רחוב": "פשוש"
        },
        {
            "_id": 31420,
            "סמל_ישוב": 1130,
            "שם_ישוב": "כפר רוזנואלד (זרעית) ",
            "סמל_רחוב": 104,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 31421,
            "סמל_ישוב": 295,
            "שם_ישוב": "כפר רופין ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר רופין"
        },
        {
            "_id": 31422,
            "סמל_ישוב": 1166,
            "שם_ישוב": "כפר רות ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר רות"
        },
        {
            "_id": 31423,
            "סמל_ישוב": 605,
            "שם_ישוב": "כפר שמאי ",
            "סמל_רחוב": 106,
            "שם_רחוב": "האפרסק"
        },
        {
            "_id": 31424,
            "סמל_ישוב": 605,
            "שם_ישוב": "כפר שמאי ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 31425,
            "סמל_ישוב": 605,
            "שם_ישוב": "כפר שמאי ",
            "סמל_רחוב": 101,
            "שם_רחוב": "ההדס"
        },
        {
            "_id": 31426,
            "סמל_ישוב": 605,
            "שם_ישוב": "כפר שמאי ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 31427,
            "סמל_ישוב": 605,
            "שם_ישוב": "כפר שמאי ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 31428,
            "סמל_ישוב": 605,
            "שם_ישוב": "כפר שמאי ",
            "סמל_רחוב": 108,
            "שם_רחוב": "השיזף"
        },
        {
            "_id": 31429,
            "סמל_ישוב": 605,
            "שם_ישוב": "כפר שמאי ",
            "סמל_רחוב": 104,
            "שם_רחוב": "השקד"
        },
        {
            "_id": 31430,
            "סמל_ישוב": 605,
            "שם_ישוב": "כפר שמאי ",
            "סמל_רחוב": 107,
            "שם_רחוב": "התפוח"
        },
        {
            "_id": 31431,
            "סמל_ישוב": 605,
            "שם_ישוב": "כפר שמאי ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר שמאי"
        },
        {
            "_id": 31432,
            "סמל_ישוב": 743,
            "שם_ישוב": "כפר שמואל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר שמואל"
        },
        {
            "_id": 31433,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 157,
            "שם_רחוב": "אגס"
        },
        {
            "_id": 31434,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 158,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 31435,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 159,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 31436,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 160,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 31437,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 116,
            "שם_רחוב": "דרך אביב"
        },
        {
            "_id": 31438,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דרך הבאר"
        },
        {
            "_id": 31439,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דרך הגנים"
        },
        {
            "_id": 31440,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 106,
            "שם_רחוב": "דרך השדות"
        },
        {
            "_id": 31441,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 132,
            "שם_רחוב": "דרך רגבים"
        },
        {
            "_id": 31442,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 112,
            "שם_רחוב": "האורנים"
        },
        {
            "_id": 31443,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 111,
            "שם_רחוב": "האילנות"
        },
        {
            "_id": 31444,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 161,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 31445,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 126,
            "שם_רחוב": "הדקלים"
        },
        {
            "_id": 31446,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הורדים"
        },
        {
            "_id": 31447,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הזורע"
        },
        {
            "_id": 31448,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 31449,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 113,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 31450,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 101,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 31451,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 102,
            "שם_רחוב": "המעפילים"
        },
        {
            "_id": 31452,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 118,
            "שם_רחוב": "הנוטע"
        },
        {
            "_id": 31453,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 129,
            "שם_רחוב": "הנוריות"
        },
        {
            "_id": 31454,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הנרקיסים"
        },
        {
            "_id": 31455,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 131,
            "שם_רחוב": "הסביון"
        },
        {
            "_id": 31456,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 153,
            "שם_רחוב": "הצבעונים"
        },
        {
            "_id": 31457,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הקוצר"
        },
        {
            "_id": 31458,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 117,
            "שם_רחוב": "הרקפות"
        },
        {
            "_id": 31459,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 125,
            "שם_רחוב": "התלמים"
        },
        {
            "_id": 31460,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 147,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 31461,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 150,
            "שם_רחוב": "כיכר הראשונים"
        },
        {
            "_id": 31462,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר שמריהו"
        },
        {
            "_id": 31463,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 162,
            "שם_רחוב": "לבנה"
        },
        {
            "_id": 31464,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 114,
            "שם_רחוב": "נוה אביב"
        },
        {
            "_id": 31465,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 103,
            "שם_רחוב": "נורדאו"
        },
        {
            "_id": 31466,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 135,
            "שם_רחוב": "סמ אביב"
        },
        {
            "_id": 31467,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 136,
            "שם_רחוב": "סמ האורנים"
        },
        {
            "_id": 31468,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 141,
            "שם_רחוב": "סמ האלון"
        },
        {
            "_id": 31469,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 137,
            "שם_רחוב": "סמ הברושים"
        },
        {
            "_id": 31470,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 119,
            "שם_רחוב": "סמ הזורע"
        },
        {
            "_id": 31471,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 133,
            "שם_רחוב": "סמ החרצית"
        },
        {
            "_id": 31472,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 107,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 31473,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 144,
            "שם_רחוב": "שביל הבנים"
        },
        {
            "_id": 31474,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 155,
            "שם_רחוב": "שביל הדגנים"
        },
        {
            "_id": 31475,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 154,
            "שם_רחוב": "שביל החיטה"
        },
        {
            "_id": 31476,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 142,
            "שם_רחוב": "שביל החצבים"
        },
        {
            "_id": 31477,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 143,
            "שם_רחוב": "שביל החרוב"
        },
        {
            "_id": 31478,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 152,
            "שם_רחוב": "שביל היוגב"
        },
        {
            "_id": 31479,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 130,
            "שם_רחוב": "שביל היסמין"
        },
        {
            "_id": 31480,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 146,
            "שם_רחוב": "שביל הכורם"
        },
        {
            "_id": 31481,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 139,
            "שם_רחוב": "שביל הכלניות"
        },
        {
            "_id": 31482,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 151,
            "שם_רחוב": "שביל המעלות"
        },
        {
            "_id": 31483,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 138,
            "שם_רחוב": "שביל המערות"
        },
        {
            "_id": 31484,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 164,
            "שם_רחוב": "שביל הפקאן"
        },
        {
            "_id": 31485,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 134,
            "שם_רחוב": "שביל הרימונים"
        },
        {
            "_id": 31486,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 156,
            "שם_רחוב": "שביל השעורה"
        },
        {
            "_id": 31487,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 120,
            "שם_רחוב": "שביל השקד"
        },
        {
            "_id": 31488,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 145,
            "שם_רחוב": "שביל התאנים"
        },
        {
            "_id": 31489,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 140,
            "שם_רחוב": "שביל התפוזים"
        },
        {
            "_id": 31490,
            "סמל_ישוב": 267,
            "שם_ישוב": "כפר שמריהו ",
            "סמל_רחוב": 163,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 31491,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 135,
            "שם_רחוב": "אגוז"
        },
        {
            "_id": 31492,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 140,
            "שם_רחוב": "אדר"
        },
        {
            "_id": 31493,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 31494,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 189,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 31495,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אירוס"
        },
        {
            "_id": 31496,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 188,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 31497,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 190,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 31498,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 192,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 31499,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 196,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 31500,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 200,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 31501,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ביס שדה"
        },
        {
            "_id": 31502,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 180,
            "שם_רחוב": "בר גיורא"
        },
        {
            "_id": 31503,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 155,
            "שם_רחוב": "ברוש"
        },
        {
            "_id": 31504,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 166,
            "שם_רחוב": "ברקן"
        },
        {
            "_id": 31505,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 198,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 31506,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 210,
            "שם_רחוב": "גביש"
        },
        {
            "_id": 31507,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 171,
            "שם_רחוב": "גדוד העבודה"
        },
        {
            "_id": 31508,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 209,
            "שם_רחוב": "גזית"
        },
        {
            "_id": 31509,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 163,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 31510,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 208,
            "שם_רחוב": "גרניט"
        },
        {
            "_id": 31511,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 129,
            "שם_רחוב": "דובדבן"
        },
        {
            "_id": 31512,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 195,
            "שם_רחוב": "דולב"
        },
        {
            "_id": 31513,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 119,
            "שם_רחוב": "דפנה"
        },
        {
            "_id": 31514,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 186,
            "שם_רחוב": "האצל"
        },
        {
            "_id": 31515,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 223,
            "שם_רחוב": "הבריגדה היהודית"
        },
        {
            "_id": 31516,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 185,
            "שם_רחוב": "הגדוד העברי"
        },
        {
            "_id": 31517,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 121,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 31518,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 162,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 31519,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 176,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 31520,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 165,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 31521,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 170,
            "שם_רחוב": "החלוץ"
        },
        {
            "_id": 31522,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 151,
            "שם_רחוב": "החקלאי"
        },
        {
            "_id": 31523,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 150,
            "שם_רחוב": "היוצר"
        },
        {
            "_id": 31524,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 173,
            "שם_רחוב": "המגן"
        },
        {
            "_id": 31525,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 181,
            "שם_רחוב": "המושבה"
        },
        {
            "_id": 31526,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 169,
            "שם_רחוב": "המייסדים"
        },
        {
            "_id": 31527,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 149,
            "שם_רחוב": "המעיין"
        },
        {
            "_id": 31528,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 183,
            "שם_רחוב": "הנוטר"
        },
        {
            "_id": 31529,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 178,
            "שם_רחוב": "העליה"
        },
        {
            "_id": 31530,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 175,
            "שם_רחוב": "הפלוגות"
        },
        {
            "_id": 31531,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 187,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 31532,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 174,
            "שם_רחוב": "הרועה"
        },
        {
            "_id": 31533,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 168,
            "שם_רחוב": "השומר"
        },
        {
            "_id": 31534,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 177,
            "שם_רחוב": "התקומה"
        },
        {
            "_id": 31535,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 154,
            "שם_רחוב": "ורד"
        },
        {
            "_id": 31536,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 161,
            "שם_רחוב": "זמורה"
        },
        {
            "_id": 31537,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 184,
            "שם_רחוב": "חובבי ציון"
        },
        {
            "_id": 31538,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 203,
            "שם_רחוב": "חושן"
        },
        {
            "_id": 31539,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 146,
            "שם_רחוב": "חיטה"
        },
        {
            "_id": 31540,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 206,
            "שם_רחוב": "חלמיש"
        },
        {
            "_id": 31541,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 125,
            "שם_רחוב": "חצב"
        },
        {
            "_id": 31542,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 148,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 31543,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 153,
            "שם_רחוב": "חרושת"
        },
        {
            "_id": 31544,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 156,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 31545,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 199,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 31546,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 205,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 31547,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 143,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 31548,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6021,
            "שם_רחוב": "כיכר אופיר מקס"
        },
        {
            "_id": 31549,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6003,
            "שם_רחוב": "כיכר אופירה"
        },
        {
            "_id": 31550,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6013,
            "שם_רחוב": "כיכר ארליך אשר"
        },
        {
            "_id": 31551,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6020,
            "שם_רחוב": "כיכר גולדמן דוד"
        },
        {
            "_id": 31552,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6014,
            "שם_רחוב": "כיכר גנדי"
        },
        {
            "_id": 31553,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6015,
            "שם_רחוב": "כיכר האלונים"
        },
        {
            "_id": 31554,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6022,
            "שם_רחוב": "כיכר הברון הירש"
        },
        {
            "_id": 31555,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6016,
            "שם_רחוב": "כיכר הברון רוטשילד"
        },
        {
            "_id": 31556,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6006,
            "שם_רחוב": "כיכר הברושים דרום"
        },
        {
            "_id": 31557,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6005,
            "שם_רחוב": "כיכר הברושים צפון"
        },
        {
            "_id": 31558,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6009,
            "שם_רחוב": "כיכר הגפנים"
        },
        {
            "_id": 31559,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6007,
            "שם_רחוב": "כיכר הזיתים"
        },
        {
            "_id": 31560,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6018,
            "שם_רחוב": "כיכר המועצה"
        },
        {
            "_id": 31561,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6011,
            "שם_רחוב": "כיכר המורה ענתבי"
        },
        {
            "_id": 31562,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6002,
            "שם_רחוב": "כיכר הערמונים"
        },
        {
            "_id": 31563,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6012,
            "שם_רחוב": "כיכר השומר"
        },
        {
            "_id": 31564,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6001,
            "שם_רחוב": "כיכר השזיפים"
        },
        {
            "_id": 31565,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6004,
            "שם_רחוב": "כיכר התבור"
        },
        {
            "_id": 31566,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6008,
            "שם_רחוב": "כיכר זמורה"
        },
        {
            "_id": 31567,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6010,
            "שם_רחוב": "כיכר ירחן אהרון"
        },
        {
            "_id": 31568,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6019,
            "שם_רחוב": "כיכר כהן יעקב משה"
        },
        {
            "_id": 31569,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 6017,
            "שם_רחוב": "ככר הברונית רוטשילד"
        },
        {
            "_id": 31570,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 134,
            "שם_רחוב": "כליל החורש"
        },
        {
            "_id": 31571,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 127,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 31572,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר תבור"
        },
        {
            "_id": 31573,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 197,
            "שם_רחוב": "כרמים"
        },
        {
            "_id": 31574,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 158,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 31575,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 172,
            "שם_רחוב": "לחי"
        },
        {
            "_id": 31576,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 130,
            "שם_רחוב": "ליבנה"
        },
        {
            "_id": 31577,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 194,
            "שם_רחוב": "מורן"
        },
        {
            "_id": 31578,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 152,
            "שם_רחוב": "מלאכה"
        },
        {
            "_id": 31579,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 182,
            "שם_רחוב": "מעפילים"
        },
        {
            "_id": 31580,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 179,
            "שם_רחוב": "מקווה ישראל"
        },
        {
            "_id": 31581,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 167,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 31582,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 118,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 31583,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 115,
            "שם_רחוב": "נוף התבור"
        },
        {
            "_id": 31584,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 123,
            "שם_רחוב": "נורית"
        },
        {
            "_id": 31585,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 126,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 31586,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 164,
            "שם_רחוב": "סיגלית"
        },
        {
            "_id": 31587,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 157,
            "שם_רחוב": "סייפן"
        },
        {
            "_id": 31588,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 141,
            "שם_רחוב": "סמ היקב"
        },
        {
            "_id": 31589,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 201,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 31590,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 160,
            "שם_רחוב": "עינב"
        },
        {
            "_id": 31591,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 122,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 31592,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 211,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 31593,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 131,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 31594,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 136,
            "שם_רחוב": "פקאן"
        },
        {
            "_id": 31595,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 138,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 31596,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 128,
            "שם_רחוב": "צבעוני"
        },
        {
            "_id": 31597,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 207,
            "שם_רחוב": "צור"
        },
        {
            "_id": 31598,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 133,
            "שם_רחוב": "צפצפה"
        },
        {
            "_id": 31599,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 116,
            "שם_רחוב": "קורנית"
        },
        {
            "_id": 31600,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 193,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 31601,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 120,
            "שם_רחוב": "ריחן"
        },
        {
            "_id": 31602,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 147,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 31603,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 159,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 31604,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 220,
            "שם_רחוב": "שדרות קקל"
        },
        {
            "_id": 31605,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 204,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 31606,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 213,
            "שם_רחוב": "שחם"
        },
        {
            "_id": 31607,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 117,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 31608,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 191,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 31609,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 132,
            "שם_רחוב": "שיקמה"
        },
        {
            "_id": 31610,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שכ האלונים"
        },
        {
            "_id": 31611,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 105,
            "שם_רחוב": "שכ הגפנים"
        },
        {
            "_id": 31612,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 106,
            "שם_רחוב": "שכ הזיתים"
        },
        {
            "_id": 31613,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שכ החרובים"
        },
        {
            "_id": 31614,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 107,
            "שם_רחוב": "שכ המיסדים"
        },
        {
            "_id": 31615,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 108,
            "שם_רחוב": "שכ הקוטגים"
        },
        {
            "_id": 31616,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 103,
            "שם_רחוב": "שכ הרימונים"
        },
        {
            "_id": 31617,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 104,
            "שם_רחוב": "שכ השזיפים"
        },
        {
            "_id": 31618,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 102,
            "שם_רחוב": "שכ השקדים"
        },
        {
            "_id": 31619,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שכ התאנים"
        },
        {
            "_id": 31620,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 112,
            "שם_רחוב": "שכ זמורה"
        },
        {
            "_id": 31621,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 145,
            "שם_רחוב": "שעורה"
        },
        {
            "_id": 31622,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 137,
            "שם_רחוב": "שקד"
        },
        {
            "_id": 31623,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 142,
            "שם_רחוב": "תאנה"
        },
        {
            "_id": 31624,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 221,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 31625,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 139,
            "שם_רחוב": "תות"
        },
        {
            "_id": 31626,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 212,
            "שם_רחוב": "תכלת"
        },
        {
            "_id": 31627,
            "סמל_ישוב": 47,
            "שם_ישוב": "כפר תבור ",
            "סמל_רחוב": 144,
            "שם_רחוב": "תלתן"
        },
        {
            "_id": 31628,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 120,
            "שם_רחוב": "אזור תעשייה רימון"
        },
        {
            "_id": 31629,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 101,
            "שם_רחוב": "האלון"
        },
        {
            "_id": 31630,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הארז"
        },
        {
            "_id": 31631,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 31632,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הדר"
        },
        {
            "_id": 31633,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 31634,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 108,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 31635,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 106,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 31636,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כפר תפוח"
        },
        {
            "_id": 31637,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 105,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 31638,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "רחלים"
        },
        {
            "_id": 31639,
            "סמל_ישוב": 3572,
            "שם_ישוב": "כפר תפוח ",
            "סמל_רחוב": 109,
            "שם_רחוב": "שבילי הגפן"
        },
        {
            "_id": 31640,
            "סמל_ישוב": 38,
            "שם_ישוב": "כרי דשא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרי דשא"
        },
        {
            "_id": 31641,
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 31642,
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום ",
            "סמל_רחוב": 102,
            "שם_רחוב": "בוסתן"
        },
        {
            "_id": 31643,
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום ",
            "סמל_רחוב": 110,
            "שם_רחוב": "גולן"
        },
        {
            "_id": 31644,
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הראשונים"
        },
        {
            "_id": 31645,
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום ",
            "סמל_רחוב": 105,
            "שם_רחוב": "זית"
        },
        {
            "_id": 31646,
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום ",
            "סמל_רחוב": 103,
            "שם_רחוב": "חרוב"
        },
        {
            "_id": 31647,
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום ",
            "סמל_רחוב": 107,
            "שם_רחוב": "ירדן ההררי"
        },
        {
            "_id": 31648,
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום ",
            "סמל_רחוב": 109,
            "שם_רחוב": "כינרת"
        },
        {
            "_id": 31649,
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 31650,
            "סמל_ישוב": 1285,
            "שם_ישוב": "כרכום ",
            "סמל_רחוב": 104,
            "שם_רחוב": "רימון"
        },
        {
            "_id": 31651,
            "סמל_ישוב": 1094,
            "שם_ישוב": "כרם ביבנה (ישיבה) ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרם ביבנה (ישיבה)"
        },
        {
            "_id": 31652,
            "סמל_ישוב": 664,
            "שם_ישוב": "כרם בן זמרה ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אשכול אלנבי"
        },
        {
            "_id": 31653,
            "סמל_ישוב": 664,
            "שם_ישוב": "כרם בן זמרה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "בציר"
        },
        {
            "_id": 31654,
            "סמל_ישוב": 664,
            "שם_ישוב": "כרם בן זמרה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "גפן"
        },
        {
            "_id": 31655,
            "סמל_ישוב": 664,
            "שם_ישוב": "כרם בן זמרה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "זמורה"
        },
        {
            "_id": 31656,
            "סמל_ישוב": 664,
            "שם_ישוב": "כרם בן זמרה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרם בן זמרה"
        },
        {
            "_id": 31657,
            "סמל_ישוב": 664,
            "שם_ישוב": "כרם בן זמרה ",
            "סמל_רחוב": 106,
            "שם_רחוב": "מעלה הכרם"
        },
        {
            "_id": 31658,
            "סמל_ישוב": 664,
            "שם_ישוב": "כרם בן זמרה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 31659,
            "סמל_ישוב": 664,
            "שם_ישוב": "כרם בן זמרה ",
            "סמל_רחוב": 107,
            "שם_רחוב": "עוללות"
        },
        {
            "_id": 31660,
            "סמל_ישוב": 664,
            "שם_ישוב": "כרם בן זמרה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "תירוש"
        },
        {
            "_id": 31661,
            "סמל_ישוב": 88,
            "שם_ישוב": "כרם בן שמן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרם בן שמן"
        },
        {
            "_id": 31662,
            "סמל_ישוב": 580,
            "שם_ישוב": "כרם מהרל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרם מהרל"
        },
        {
            "_id": 31663,
            "סמל_ישוב": 1085,
            "שם_ישוב": "כרם שלום ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרם שלום"
        },
        {
            "_id": 31664,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אורן"
        },
        {
            "_id": 31665,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 110,
            "שם_רחוב": "אלון"
        },
        {
            "_id": 31666,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 114,
            "שם_רחוב": "ארז"
        },
        {
            "_id": 31667,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הברוש"
        },
        {
            "_id": 31668,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 31669,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הורד"
        },
        {
            "_id": 31670,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 107,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 31671,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 104,
            "שם_רחוב": "היסמין"
        },
        {
            "_id": 31672,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 108,
            "שם_רחוב": "הנרקיס"
        },
        {
            "_id": 31673,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 111,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 31674,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 109,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 31675,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 113,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 31676,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 105,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 31677,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרמי יוסף"
        },
        {
            "_id": 31678,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 120,
            "שם_רחוב": "סיגלית"
        },
        {
            "_id": 31679,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 102,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 31680,
            "סמל_ישוב": 1264,
            "שם_ישוב": "כרמי יוסף ",
            "סמל_רחוב": 112,
            "שם_רחוב": "רקפת"
        },
        {
            "_id": 31681,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 106,
            "שם_רחוב": "אשכול"
        },
        {
            "_id": 31682,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 108,
            "שם_רחוב": "דרך האבות"
        },
        {
            "_id": 31683,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 109,
            "שם_רחוב": "דרך שורק"
        },
        {
            "_id": 31684,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 31685,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגת"
        },
        {
            "_id": 31686,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרמי צור"
        },
        {
            "_id": 31687,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 105,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 31688,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 104,
            "שם_רחוב": "עינב"
        },
        {
            "_id": 31689,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 6500,
            "שם_רחוב": "שכ צור שלם"
        },
        {
            "_id": 31690,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שריג"
        },
        {
            "_id": 31691,
            "סמל_ישוב": 3766,
            "שם_ישוב": "כרמי צור ",
            "סמל_רחוב": 107,
            "שם_רחוב": "תירוש"
        },
        {
            "_id": 31692,
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 31693,
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף ",
            "סמל_רחוב": 102,
            "שם_רחוב": "הזמורה"
        },
        {
            "_id": 31694,
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף ",
            "סמל_רחוב": 103,
            "שם_רחוב": "היקב"
        },
        {
            "_id": 31695,
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הכרם"
        },
        {
            "_id": 31696,
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף ",
            "סמל_רחוב": 105,
            "שם_רחוב": "הנצר"
        },
        {
            "_id": 31697,
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הסמדר"
        },
        {
            "_id": 31698,
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף ",
            "סמל_רחוב": 107,
            "שם_רחוב": "העינב"
        },
        {
            "_id": 31699,
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף ",
            "סמל_רחוב": 108,
            "שם_רחוב": "השריג"
        },
        {
            "_id": 31700,
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף ",
            "סמל_רחוב": 109,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 31701,
            "סמל_ישוב": 1374,
            "שם_ישוב": "כרמי קטיף ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרמי קטיף"
        },
        {
            "_id": 31702,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 257,
            "שם_רחוב": "אביב"
        },
        {
            "_id": 31703,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 275,
            "שם_רחוב": "אדום החזה"
        },
        {
            "_id": 31704,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6514,
            "שם_רחוב": "אזור התעשייה"
        },
        {
            "_id": 31705,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 191,
            "שם_רחוב": "אטד"
        },
        {
            "_id": 31706,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 178,
            "שם_רחוב": "איה"
        },
        {
            "_id": 31707,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 124,
            "שם_רחוב": "אילנות"
        },
        {
            "_id": 31708,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 141,
            "שם_רחוב": "איריס"
        },
        {
            "_id": 31709,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 162,
            "שם_רחוב": "אלה"
        },
        {
            "_id": 31710,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 325,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 31711,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 185,
            "שם_רחוב": "אנקור"
        },
        {
            "_id": 31712,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 261,
            "שם_רחוב": "אסיף"
        },
        {
            "_id": 31713,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 133,
            "שם_רחוב": "ארבל"
        },
        {
            "_id": 31714,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 134,
            "שם_רחוב": "אשור"
        },
        {
            "_id": 31715,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 193,
            "שם_רחוב": "אשחר"
        },
        {
            "_id": 31716,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 288,
            "שם_רחוב": "אשכולות"
        },
        {
            "_id": 31717,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 198,
            "שם_רחוב": "אשל"
        },
        {
            "_id": 31718,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 179,
            "שם_רחוב": "בז"
        },
        {
            "_id": 31719,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 317,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 31720,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 253,
            "שם_רחוב": "ביכורים"
        },
        {
            "_id": 31721,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 209,
            "שם_רחוב": "בירנית"
        },
        {
            "_id": 31722,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 264,
            "שם_רחוב": "בציר"
        },
        {
            "_id": 31723,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 307,
            "שם_רחוב": "בשמים"
        },
        {
            "_id": 31724,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 318,
            "שם_רחוב": "גביש"
        },
        {
            "_id": 31725,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6504,
            "שם_רחוב": "גבעת מכוש"
        },
        {
            "_id": 31726,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6507,
            "שם_רחוב": "גבעת רם"
        },
        {
            "_id": 31727,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 249,
            "שם_רחוב": "גדיד"
        },
        {
            "_id": 31728,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 224,
            "שם_רחוב": "גדרון"
        },
        {
            "_id": 31729,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 313,
            "שם_רחוב": "גזית"
        },
        {
            "_id": 31730,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 215,
            "שם_רחוב": "גמלא"
        },
        {
            "_id": 31731,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 312,
            "שם_רחוב": "גרניט"
        },
        {
            "_id": 31732,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 289,
            "שם_רחוב": "גת"
        },
        {
            "_id": 31733,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 225,
            "שם_רחוב": "דגנית"
        },
        {
            "_id": 31734,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 183,
            "שם_רחוב": "דוכיפת"
        },
        {
            "_id": 31735,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 214,
            "שם_רחוב": "דלית"
        },
        {
            "_id": 31736,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 168,
            "שם_רחוב": "דרור"
        },
        {
            "_id": 31737,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 316,
            "שם_רחוב": "דרך התעשייה"
        },
        {
            "_id": 31738,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 301,
            "שם_רחוב": "האומן"
        },
        {
            "_id": 31739,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 158,
            "שם_רחוב": "האופן"
        },
        {
            "_id": 31740,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 120,
            "שם_רחוב": "האורג"
        },
        {
            "_id": 31741,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 129,
            "שם_רחוב": "האורן"
        },
        {
            "_id": 31742,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 130,
            "שם_רחוב": "הארזים"
        },
        {
            "_id": 31743,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 122,
            "שם_רחוב": "הבנאי"
        },
        {
            "_id": 31744,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 128,
            "שם_רחוב": "הברושים"
        },
        {
            "_id": 31745,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 297,
            "שם_רחוב": "הגורן"
        },
        {
            "_id": 31746,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 103,
            "שם_רחוב": "הגליל"
        },
        {
            "_id": 31747,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 112,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 31748,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 167,
            "שם_רחוב": "הדס"
        },
        {
            "_id": 31749,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 115,
            "שם_רחוב": "הדקל"
        },
        {
            "_id": 31750,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 359,
            "שם_רחוב": "ההגנה"
        },
        {
            "_id": 31751,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 110,
            "שם_רחוב": "הזית"
        },
        {
            "_id": 31752,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 160,
            "שם_רחוב": "החבצלת"
        },
        {
            "_id": 31753,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 202,
            "שם_רחוב": "החורש"
        },
        {
            "_id": 31754,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 104,
            "שם_רחוב": "החרוב"
        },
        {
            "_id": 31755,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 117,
            "שם_רחוב": "החרושת"
        },
        {
            "_id": 31756,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 159,
            "שם_רחוב": "החרמש"
        },
        {
            "_id": 31757,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 303,
            "שם_רחוב": "החשמל"
        },
        {
            "_id": 31758,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 156,
            "שם_רחוב": "היוצרים"
        },
        {
            "_id": 31759,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 298,
            "שם_רחוב": "היזם"
        },
        {
            "_id": 31760,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 309,
            "שם_רחוב": "הכבאי"
        },
        {
            "_id": 31761,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 155,
            "שם_רחוב": "המגל"
        },
        {
            "_id": 31762,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 118,
            "שם_רחוב": "המלאכה"
        },
        {
            "_id": 31763,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 121,
            "שם_רחוב": "המסגר"
        },
        {
            "_id": 31764,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 296,
            "שם_רחוב": "המסילה"
        },
        {
            "_id": 31765,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 151,
            "שם_רחוב": "המצודה"
        },
        {
            "_id": 31766,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 119,
            "שם_רחוב": "הנגר"
        },
        {
            "_id": 31767,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 135,
            "שם_רחוב": "הנפח"
        },
        {
            "_id": 31768,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 300,
            "שם_רחוב": "הסדן"
        },
        {
            "_id": 31769,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 106,
            "שם_רחוב": "העמק"
        },
        {
            "_id": 31770,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 136,
            "שם_רחוב": "העסקים"
        },
        {
            "_id": 31771,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 353,
            "שם_רחוב": "הפלמח"
        },
        {
            "_id": 31772,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 172,
            "שם_רחוב": "הפסגה"
        },
        {
            "_id": 31773,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 161,
            "שם_רחוב": "הפרחים"
        },
        {
            "_id": 31774,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 222,
            "שם_רחוב": "הצפצפות"
        },
        {
            "_id": 31775,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 368,
            "שם_רחוב": "הר הצופים"
        },
        {
            "_id": 31776,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 149,
            "שם_רחוב": "הרדוף"
        },
        {
            "_id": 31777,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הרימון"
        },
        {
            "_id": 31778,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 290,
            "שם_רחוב": "השוק"
        },
        {
            "_id": 31779,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 147,
            "שם_רחוב": "השושנים"
        },
        {
            "_id": 31780,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 266,
            "שם_רחוב": "השותלים"
        },
        {
            "_id": 31781,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 114,
            "שם_רחוב": "השיש"
        },
        {
            "_id": 31782,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 111,
            "שם_רחוב": "התאנה"
        },
        {
            "_id": 31783,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 102,
            "שם_רחוב": "התמר"
        },
        {
            "_id": 31784,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 304,
            "שם_רחוב": "התעשיה"
        },
        {
            "_id": 31785,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 166,
            "שם_רחוב": "ורד"
        },
        {
            "_id": 31786,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 292,
            "שם_רחוב": "זמורה"
        },
        {
            "_id": 31787,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 324,
            "שם_רחוב": "זמיר"
        },
        {
            "_id": 31788,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 184,
            "שם_רחוב": "חוחית"
        },
        {
            "_id": 31789,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 360,
            "שם_רחוב": "חטיבת גבעתי"
        },
        {
            "_id": 31790,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 351,
            "שם_רחוב": "חטיבת גולני"
        },
        {
            "_id": 31791,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 361,
            "שם_רחוב": "חטיבת הנגב"
        },
        {
            "_id": 31792,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 357,
            "שם_רחוב": "חטיבת הראל"
        },
        {
            "_id": 31793,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 356,
            "שם_רחוב": "חטיבת יפתח"
        },
        {
            "_id": 31794,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 363,
            "שם_רחוב": "חטיבת כרמלי"
        },
        {
            "_id": 31795,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 366,
            "שם_רחוב": "חטיבת עציוני"
        },
        {
            "_id": 31796,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 314,
            "שם_רחוב": "חלמיש"
        },
        {
            "_id": 31797,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 126,
            "שם_רחוב": "חן"
        },
        {
            "_id": 31798,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 216,
            "שם_רחוב": "חצור"
        },
        {
            "_id": 31799,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 250,
            "שם_רחוב": "חריש"
        },
        {
            "_id": 31800,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 207,
            "שם_רחוב": "חרמון"
        },
        {
            "_id": 31801,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 226,
            "שם_רחוב": "חרצית"
        },
        {
            "_id": 31802,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 305,
            "שם_רחוב": "טוחן"
        },
        {
            "_id": 31803,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 265,
            "שם_רחוב": "טיילת בולטימור"
        },
        {
            "_id": 31804,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 131,
            "שם_רחוב": "יודפת"
        },
        {
            "_id": 31805,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 320,
            "שם_רחוב": "יונק הדבש"
        },
        {
            "_id": 31806,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 323,
            "שם_רחוב": "יונת הבר"
        },
        {
            "_id": 31807,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 132,
            "שם_רחוב": "יחיעם"
        },
        {
            "_id": 31808,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 164,
            "שם_רחוב": "יסמין"
        },
        {
            "_id": 31809,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 327,
            "שם_רחוב": "יסעור"
        },
        {
            "_id": 31810,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 189,
            "שם_רחוב": "יערה"
        },
        {
            "_id": 31811,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 299,
            "שם_רחוב": "יקב"
        },
        {
            "_id": 31812,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 148,
            "שם_רחוב": "יקינתון"
        },
        {
            "_id": 31813,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 369,
            "שם_רחוב": "כדורי"
        },
        {
            "_id": 31814,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 127,
            "שם_רחוב": "כלנית"
        },
        {
            "_id": 31815,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6501,
            "שם_רחוב": "כפר הילדים"
        },
        {
            "_id": 31816,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 177,
            "שם_רחוב": "כרכום"
        },
        {
            "_id": 31817,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרמיאל"
        },
        {
            "_id": 31818,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 308,
            "שם_רחוב": "לבונה"
        },
        {
            "_id": 31819,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 196,
            "שם_רחוב": "לבנה"
        },
        {
            "_id": 31820,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 157,
            "שם_רחוב": "להב"
        },
        {
            "_id": 31821,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 190,
            "שם_רחוב": "לוטם"
        },
        {
            "_id": 31822,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 370,
            "שם_רחוב": "לטרון"
        },
        {
            "_id": 31823,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 175,
            "שם_רחוב": "לילך"
        },
        {
            "_id": 31824,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 365,
            "שם_רחוב": "מבצע אסף"
        },
        {
            "_id": 31825,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 354,
            "שם_רחוב": "מבצע בן עמי"
        },
        {
            "_id": 31826,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 367,
            "שם_רחוב": "מבצע חורב"
        },
        {
            "_id": 31827,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 372,
            "שם_רחוב": "מבצע יבוסי"
        },
        {
            "_id": 31828,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 352,
            "שם_רחוב": "מבצע יואב"
        },
        {
            "_id": 31829,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 362,
            "שם_רחוב": "מבצע מכבי"
        },
        {
            "_id": 31830,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 355,
            "שם_רחוב": "מבצע נחשון"
        },
        {
            "_id": 31831,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 364,
            "שם_רחוב": "מבצע עובדה"
        },
        {
            "_id": 31832,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 217,
            "שם_רחוב": "מגידו"
        },
        {
            "_id": 31833,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 306,
            "שם_רחוב": "מור"
        },
        {
            "_id": 31834,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 140,
            "שם_רחוב": "מורד הגיא"
        },
        {
            "_id": 31835,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 204,
            "שם_רחוב": "מירון"
        },
        {
            "_id": 31836,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 211,
            "שם_רחוב": "מעלה אורט"
        },
        {
            "_id": 31837,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 116,
            "שם_רחוב": "מעלה הכרמים"
        },
        {
            "_id": 31838,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 152,
            "שם_רחוב": "מעלה כמון"
        },
        {
            "_id": 31839,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 125,
            "שם_רחוב": "מצפה נוף"
        },
        {
            "_id": 31840,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 150,
            "שם_רחוב": "מרגנית"
        },
        {
            "_id": 31841,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 192,
            "שם_רחוב": "מרווה"
        },
        {
            "_id": 31842,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 113,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 31843,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 170,
            "שם_רחוב": "משגב"
        },
        {
            "_id": 31844,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 330,
            "שם_רחוב": "משעול אנפה"
        },
        {
            "_id": 31845,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 203,
            "שם_רחוב": "משעול ארז"
        },
        {
            "_id": 31846,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 163,
            "שם_רחוב": "משעול דולב"
        },
        {
            "_id": 31847,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 213,
            "שם_רחוב": "משעול היין"
        },
        {
            "_id": 31848,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 212,
            "שם_רחוב": "משעול הכורם"
        },
        {
            "_id": 31849,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 107,
            "שם_רחוב": "משעול הסביונים"
        },
        {
            "_id": 31850,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 145,
            "שם_רחוב": "משעול הסיפן"
        },
        {
            "_id": 31851,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 108,
            "שם_רחוב": "משעול הרקפות"
        },
        {
            "_id": 31852,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 142,
            "שם_רחוב": "משעול חצב"
        },
        {
            "_id": 31853,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 139,
            "שם_רחוב": "משעול יגאל אלון"
        },
        {
            "_id": 31854,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 165,
            "שם_רחוב": "משעול מורן"
        },
        {
            "_id": 31855,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 146,
            "שם_רחוב": "משעול נורית"
        },
        {
            "_id": 31856,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 284,
            "שם_רחוב": "משעול שדמה"
        },
        {
            "_id": 31857,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 173,
            "שם_רחוב": "משעול שקד"
        },
        {
            "_id": 31858,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 331,
            "שם_רחוב": "משעול שקנאי"
        },
        {
            "_id": 31859,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 208,
            "שם_רחוב": "משעול תפן"
        },
        {
            "_id": 31860,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 302,
            "שם_רחוב": "מתכת"
        },
        {
            "_id": 31861,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 380,
            "שם_רחוב": "נהר הירדן"
        },
        {
            "_id": 31862,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 315,
            "שם_רחוב": "נחושת"
        },
        {
            "_id": 31863,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 398,
            "שם_רחוב": "נחל אל-על"
        },
        {
            "_id": 31864,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 396,
            "שם_רחוב": "נחל בצת"
        },
        {
            "_id": 31865,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 393,
            "שם_רחוב": "נחל געתון"
        },
        {
            "_id": 31866,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 382,
            "שם_רחוב": "נחל דישון"
        },
        {
            "_id": 31867,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 385,
            "שם_רחוב": "נחל דליה"
        },
        {
            "_id": 31868,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 388,
            "שם_רחוב": "נחל דלתון"
        },
        {
            "_id": 31869,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 394,
            "שם_רחוב": "נחל דן"
        },
        {
            "_id": 31870,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 386,
            "שם_רחוב": "נחל חרוד"
        },
        {
            "_id": 31871,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 391,
            "שם_רחוב": "נחל יגור"
        },
        {
            "_id": 31872,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 387,
            "שם_רחוב": "נחל כזיב"
        },
        {
            "_id": 31873,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 392,
            "שם_רחוב": "נחל נעמן"
        },
        {
            "_id": 31874,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 395,
            "שם_רחוב": "נחל סער"
        },
        {
            "_id": 31875,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 383,
            "שם_רחוב": "נחל עמוד"
        },
        {
            "_id": 31876,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 384,
            "שם_רחוב": "נחל צבעון"
        },
        {
            "_id": 31877,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 389,
            "שם_רחוב": "נחל צלמון"
        },
        {
            "_id": 31878,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 397,
            "שם_רחוב": "נחל קלע"
        },
        {
            "_id": 31879,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 390,
            "שם_רחוב": "נחל שגור"
        },
        {
            "_id": 31880,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 381,
            "שם_רחוב": "נחל שניר"
        },
        {
            "_id": 31881,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 186,
            "שם_רחוב": "נחליאלי"
        },
        {
            "_id": 31882,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 328,
            "שם_רחוב": "נקר"
        },
        {
            "_id": 31883,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 143,
            "שם_רחוב": "נרקיס"
        },
        {
            "_id": 31884,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 180,
            "שם_רחוב": "נשר"
        },
        {
            "_id": 31885,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 373,
            "שם_רחוב": "נתיב הלה"
        },
        {
            "_id": 31886,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 144,
            "שם_רחוב": "נתיב הלוטוס"
        },
        {
            "_id": 31887,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 358,
            "שם_רחוב": "נתיב הסיירים"
        },
        {
            "_id": 31888,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 176,
            "שם_רחוב": "סיתוונית"
        },
        {
            "_id": 31889,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 326,
            "שם_רחוב": "סלעית"
        },
        {
            "_id": 31890,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 194,
            "שם_רחוב": "סמ נחליאלי"
        },
        {
            "_id": 31891,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 286,
            "שם_רחוב": "סמדר"
        },
        {
            "_id": 31892,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 200,
            "שם_רחוב": "סמטת צבעוני"
        },
        {
            "_id": 31893,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 219,
            "שם_רחוב": "סנונית"
        },
        {
            "_id": 31894,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 182,
            "שם_רחוב": "עגור"
        },
        {
            "_id": 31895,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 276,
            "שם_רחוב": "עומר"
        },
        {
            "_id": 31896,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 7001,
            "שם_רחוב": "עין כמונים"
        },
        {
            "_id": 31897,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 295,
            "שם_רחוב": "עינב"
        },
        {
            "_id": 31898,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 138,
            "שם_רחוב": "עירית"
        },
        {
            "_id": 31899,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 187,
            "שם_רחוב": "עפרוני"
        },
        {
            "_id": 31900,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 205,
            "שם_רחוב": "עצמון"
        },
        {
            "_id": 31901,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 199,
            "שם_רחוב": "ערבה"
        },
        {
            "_id": 31902,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 154,
            "שם_רחוב": "פארק אלון"
        },
        {
            "_id": 31903,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 153,
            "שם_רחוב": "פארק הנוער"
        },
        {
            "_id": 31904,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 374,
            "שם_רחוב": "פל-ים"
        },
        {
            "_id": 31905,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 321,
            "שם_רחוב": "פשוש"
        },
        {
            "_id": 31906,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 221,
            "שם_רחוב": "צאלון"
        },
        {
            "_id": 31907,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 105,
            "שם_רחוב": "צהל"
        },
        {
            "_id": 31908,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 322,
            "שם_רחוב": "צופית"
        },
        {
            "_id": 31909,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 310,
            "שם_רחוב": "צור"
        },
        {
            "_id": 31910,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 218,
            "שם_רחוב": "ציפורי"
        },
        {
            "_id": 31911,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 260,
            "שם_רחוב": "קטיף"
        },
        {
            "_id": 31912,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 251,
            "שם_רחוב": "קציר"
        },
        {
            "_id": 31913,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 123,
            "שם_רחוב": "קרן היסוד"
        },
        {
            "_id": 31914,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 169,
            "שם_רחוב": "רותם"
        },
        {
            "_id": 31915,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 171,
            "שם_רחוב": "רמים"
        },
        {
            "_id": 31916,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6508,
            "שם_רחוב": "רמת רבין"
        },
        {
            "_id": 31917,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 262,
            "שם_רחוב": "שביל אייר"
        },
        {
            "_id": 31918,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 256,
            "שם_רחוב": "שביל אלול"
        },
        {
            "_id": 31919,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 252,
            "שם_רחוב": "שביל הזורעים"
        },
        {
            "_id": 31920,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 278,
            "שם_רחוב": "שביל חשון"
        },
        {
            "_id": 31921,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 259,
            "שם_רחוב": "שביל טבת"
        },
        {
            "_id": 31922,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 279,
            "שם_רחוב": "שביל כסלו"
        },
        {
            "_id": 31923,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 263,
            "שם_רחוב": "שביל ניסן"
        },
        {
            "_id": 31924,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 254,
            "שם_רחוב": "שביל סיון"
        },
        {
            "_id": 31925,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 258,
            "שם_רחוב": "שביל שבט"
        },
        {
            "_id": 31926,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 255,
            "שם_רחוב": "שביל תמוז"
        },
        {
            "_id": 31927,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 277,
            "שם_רחוב": "שביל תשרי"
        },
        {
            "_id": 31928,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 201,
            "שם_רחוב": "שגיא"
        },
        {
            "_id": 31929,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 294,
            "שם_רחוב": "שד בית הכרם"
        },
        {
            "_id": 31930,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 188,
            "שם_רחוב": "שד השלום"
        },
        {
            "_id": 31931,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 174,
            "שם_רחוב": "שד נשיאי ישראל"
        },
        {
            "_id": 31932,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 210,
            "שם_רחוב": "שד קקל"
        },
        {
            "_id": 31933,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 285,
            "שם_רחוב": "שדמה"
        },
        {
            "_id": 31934,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 291,
            "שם_רחוב": "שורק"
        },
        {
            "_id": 31935,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 311,
            "שם_רחוב": "שחם"
        },
        {
            "_id": 31936,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 181,
            "שם_רחוב": "שחף"
        },
        {
            "_id": 31937,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 195,
            "שם_רחוב": "שיזף"
        },
        {
            "_id": 31938,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 197,
            "שם_רחוב": "שיטה"
        },
        {
            "_id": 31939,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6503,
            "שם_רחוב": "שכ אשכול"
        },
        {
            "_id": 31940,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6506,
            "שם_רחוב": "שכ האירוסים"
        },
        {
            "_id": 31941,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6502,
            "שם_רחוב": "שכ הגליל"
        },
        {
            "_id": 31942,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6510,
            "שם_רחוב": "שכ הדר"
        },
        {
            "_id": 31943,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6511,
            "שם_רחוב": "שכ המייסדים"
        },
        {
            "_id": 31944,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6513,
            "שם_רחוב": "שכ הערבה"
        },
        {
            "_id": 31945,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6505,
            "שם_רחוב": "שכ טנה"
        },
        {
            "_id": 31946,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6512,
            "שם_רחוב": "שכ מגדים"
        },
        {
            "_id": 31947,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 6509,
            "שם_רחוב": "שכ שגיא"
        },
        {
            "_id": 31948,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 332,
            "שם_רחוב": "שלדג"
        },
        {
            "_id": 31949,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 350,
            "שם_רחוב": "שער הגיא"
        },
        {
            "_id": 31950,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 329,
            "שם_רחוב": "שקנאי"
        },
        {
            "_id": 31951,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 287,
            "שם_רחוב": "שריג"
        },
        {
            "_id": 31952,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 371,
            "שם_רחוב": "ששת הימים"
        },
        {
            "_id": 31953,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 206,
            "שם_רחוב": "תבור"
        },
        {
            "_id": 31954,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 137,
            "שם_רחוב": "תדהר"
        },
        {
            "_id": 31955,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 223,
            "שם_רחוב": "תור"
        },
        {
            "_id": 31956,
            "סמל_ישוב": 1139,
            "שם_ישוב": "כרמיאל ",
            "סמל_רחוב": 293,
            "שם_רחוב": "תירוש"
        },
        {
            "_id": 31957,
            "סמל_ישוב": 768,
            "שם_ישוב": "כרמיה ",
            "סמל_רחוב": 102,
            "שם_רחוב": "האשכול"
        },
        {
            "_id": 31958,
            "סמל_ישוב": 768,
            "שם_ישוב": "כרמיה ",
            "סמל_רחוב": 104,
            "שם_רחוב": "הבציר"
        },
        {
            "_id": 31959,
            "סמל_ישוב": 768,
            "שם_ישוב": "כרמיה ",
            "סמל_רחוב": 101,
            "שם_רחוב": "הגפן"
        },
        {
            "_id": 31960,
            "סמל_ישוב": 768,
            "שם_ישוב": "כרמיה ",
            "סמל_רחוב": 105,
            "שם_רחוב": "היין"
        },
        {
            "_id": 31961,
            "סמל_ישוב": 768,
            "שם_ישוב": "כרמיה ",
            "סמל_רחוב": 103,
            "שם_רחוב": "התירוש"
        },
        {
            "_id": 31962,
            "סמל_ישוב": 768,
            "שם_ישוב": "כרמיה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרמיה"
        },
        {
            "_id": 31963,
            "סמל_ישוב": 1198,
            "שם_ישוב": "כרמים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרמים"
        },
        {
            "_id": 31964,
            "סמל_ישוב": 1198,
            "שם_ישוב": "כרמים ",
            "סמל_רחוב": 101,
            "שם_רחוב": "מרכז קליטה"
        },
        {
            "_id": 31965,
            "סמל_ישוב": 3656,
            "שם_ישוב": "כרמל ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "כרמל"
        },
        {
            "_id": 31966,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 108,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 31967,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 107,
            "שם_רחוב": "אלמוג"
        },
        {
            "_id": 31968,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 112,
            "שם_רחוב": "בזלת"
        },
        {
            "_id": 31969,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 105,
            "שם_רחוב": "ברקת"
        },
        {
            "_id": 31970,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 103,
            "שם_רחוב": "גביש"
        },
        {
            "_id": 31971,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 106,
            "שם_רחוב": "הפנינה"
        },
        {
            "_id": 31972,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 113,
            "שם_רחוב": "חושן"
        },
        {
            "_id": 31973,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 109,
            "שם_רחוב": "טופז"
        },
        {
            "_id": 31974,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 114,
            "שם_רחוב": "טורקיז"
        },
        {
            "_id": 31975,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 115,
            "שם_רחוב": "יהלום"
        },
        {
            "_id": 31976,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "לבון"
        },
        {
            "_id": 31977,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 102,
            "שם_רחוב": "לשם"
        },
        {
            "_id": 31978,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 116,
            "שם_רחוב": "סמטת המים"
        },
        {
            "_id": 31979,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 110,
            "שם_רחוב": "ספיר"
        },
        {
            "_id": 31980,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 104,
            "שם_רחוב": "ענבר"
        },
        {
            "_id": 31981,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 117,
            "שם_רחוב": "צדף"
        },
        {
            "_id": 31982,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 118,
            "שם_רחוב": "קורל"
        },
        {
            "_id": 31983,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 101,
            "שם_רחוב": "שוהם"
        },
        {
            "_id": 31984,
            "סמל_ישוב": 1207,
            "שם_ישוב": "לבון ",
            "סמל_רחוב": 111,
            "שם_רחוב": "שחם"
        },
        {
            "_id": 31985,
            "סמל_ישוב": 585,
            "שם_ישוב": "לביא ",
            "סמל_רחוב": 101,
            "שם_רחוב": "אולפן"
        },
        {
            "_id": 31986,
            "סמל_ישוב": 585,
            "שם_ישוב": "לביא ",
            "סמל_רחוב": 102,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 31987,
            "סמל_ישוב": 585,
            "שם_ישוב": "לביא ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "לביא"
        },
        {
            "_id": 31988,
            "סמל_ישוב": 1230,
            "שם_ישוב": "לבנים ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "לבנים"
        },
        {
            "_id": 31989,
            "סמל_ישוב": 2023,
            "שם_ישוב": "להב ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "להב"
        },
        {
            "_id": 31990,
            "סמל_ישוב": 380,
            "שם_ישוב": "להבות הבשן ",
            "סמל_רחוב": 102,
            "שם_רחוב": "אולפן"
        },
        {
            "_id": 31991,
            "סמל_ישוב": 380,
            "שם_ישוב": "להבות הבשן ",
            "סמל_רחוב": 101,
            "שם_רחוב": "בית ראשון במולדת"
        },
        {
            "_id": 31992,
            "סמל_ישוב": 380,
            "שם_ישוב": "להבות הבשן ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "להבות הבשן"
        },
        {
            "_id": 31993,
            "סמל_ישוב": 715,
            "שם_ישוב": "להבות חביבה ",
            "סמל_רחוב": 9000,
            "שם_רחוב": "להבות חביבה"
        },
        {
            "_id": 31994,
            "סמל_ישוב": 1271,
            "שם_ישוב": "להבים ",
            "סמל_רחוב": 201,
            "שם_רחוב": "אגמית"
        },
        {
            "_id": 31995,
            "סמל_ישוב": 1271,
            "שם_ישוב": "להבים ",
            "סמל_רחוב": 136,
            "שם_רחוב": "אודם"
        },
        {
            "_id": 31996,
            "סמל_ישוב": 1271,
            "שם_ישוב": "להבים ",
            "סמל_רחוב": 118,
            "שם_רחוב": "איריס"
        },
        {
            "_id": 31997,
            "סמל_ישוב": 1271,
            "שם_ישוב": "להבים ",
            "סמל_רחוב": 202,
            "שם_רחוב": "אנפה"
        },
        {
            "_id": 31998,
            "סמל_ישוב": 1271,
            "שם_ישוב": "להבים ",
            "סמל_רחוב": 141,
            "שם_רחוב": "בארית"
        },
        {
            "_id": 31999,
            "סמל_ישוב": 1271,
            "שם_ישוב": "להבים ",
            "סמל_רחוב": 203,
            "שם_רחוב": "בז"
        },
        {
            "_id": 32000,
            "סמל_ישוב": 1271,
            "שם_ישוב": "להבים ",
            "סמל_רחוב": 204,
            "שם_רחוב": "בצנית"
        }
    ],
        "fields": [
        {
            "id": "_id",
            "type": "int"
        },
        {
            "id": "סמל_ישוב",
            "type": "numeric"
        },
        {
            "id": "שם_ישוב",
            "type": "text"
        },
        {
            "id": "סמל_רחוב",
            "type": "numeric"
        },
        {
            "id": "שם_רחוב",
            "type": "text"
        }
    ],
        "_links": {
        "start": "/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000",
            "next": "/api/3/action/datastore_search?resource_id=9ad3862c-8391-4b2f-84a4-2d4c68625f4b&limit=32000&offset=32000"
    },
    "total": 61485,
        "total_was_estimated": false
}
}