//apostilSubmission
//אפוסטיל משרד החוץ

const handleSteps = (data, formState) => {

  return [
    'ApostilleVerification',
    'CertificateCollectMethod',
    // data.collectionMethod === 'pickup' ? 'AddressCollectDocuments' : 'CollectBranchSelecting',
    'CertificateReturnMethod',
    // data.returnMethod === 'branch' ? 'ReturnBranchSelecting' : 'AddressReturningDocuments',
    'NotarizedTranslation',
    'UrgencyExecution',
    'FinalPage'
  ];

};

const handlePrevStep = (step, setStep, formSteps, state, setMyState, data, setData, setProgressBarPhase) => {
  if (step < 0) return;
  let nowStep;
  nowStep = step - 1;
  setStep(nowStep);
  setMyState({ ...state, nextStep: formSteps[nowStep] });
  handlePhase(formSteps[nowStep], setProgressBarPhase);
};

const handleNextStep = (step, setStep, formSteps, state, setMyState, onAddRecipient, onAddOrder, data, setProgressBarPhase) => {
  const { isFinalStep } = state;
  if (isFinalStep) {
    onAddOrder();
  }
  if (step >= formSteps.length) return;
  let nowStep;
  nowStep = step + 1;
  setMyState({ ...state, nextStep: formSteps[nowStep] });
  setStep(nowStep);
  handlePhase(formSteps[nowStep], setProgressBarPhase);
};


const handlePhase = (nextStep, setProgressBarPhase) => {

  switch (nextStep) {
    case 'ApostilleVerification':
      setProgressBarPhase(1);
      break;

    case 'CertificateCollectMethod':
    case 'AddressCollectDocuments':
    case 'CollectBranchSelecting':
      setProgressBarPhase(2);
      break;

    case 'CertificateReturnMethod':
    case 'ReturnBranchSelecting':
    case 'AddressReturningDocuments':
      setProgressBarPhase(3);
      break;


    case 'NotarizedTranslation':
      setProgressBarPhase(4);
      break;


    case 'UrgencyExecution':
      setProgressBarPhase(5);
      break;

    default:
      setProgressBarPhase(5);
      break;
  }
};

export { handlePhase, handleSteps, handlePrevStep, handleNextStep };
