//officeDelivery
//המצאות למשרדים

const handleSteps = (data, formState, systemData, isTestUser) => {
  const { collectionMethod } = data;
  const { returnMethod } = data;

  if (isTestUser) {
    return [
      'OfficeDeliveryFirst',
      'Recipients',
      'WhatHanded',
      'CollectDocumentsOrUploadFile2',
      'Proof3',
      'FinalPage'
    ].flat()
  } else {
    return [
      'OfficeDelivery',
      'UrgencyRecipient',
      'Recipients',
      'WhatHanded',
      'CollectDocumentsOrUploadFile',
      collectionMethod === 'pickup' ? ['AddressCollectDocuments', 'DocumentsReady', 'Proof'] : (collectionMethod === 'upload' ? ['UploadFile', 'Proof'] : 'Proof'),
      // 'Proof',
      returnMethod === 'delivery' ? (collectionMethod === 'pickup' ? 'FinalPage' : ['AddressReturningDocuments', 'FinalPage']) : 'FinalPage'
    ].flat()
  }
};


const handlePrevStep = (step, setStep, formSteps, state, setMyState, data, setData, setProgressBarPhase, onSetLastClaimant, onSetLastDefendant, myRecipients, isTestUser) => {
  if (step < 0) return
  const { nextStep, currRecipient } = state;
  let nowStep
  let editRecipient

  if (isTestUser) {
    if (nextStep === 'OfficeDeliveryFirst') {
      if (!currRecipient?._id) editRecipient = myRecipients[myRecipients.length - 1]
      else {
        const recipientIdx = myRecipients.findIndex(recipient => recipient._id === currRecipient._id);
        if (recipientIdx > 0) {
          editRecipient = myRecipients[recipientIdx - 1]
        }
      }
      setData({ ...data, isOtherTasks: false });
      nowStep = formSteps.findIndex((page) => page === 'OfficeDeliveryFirst')
    } else if (nextStep === 'Recipients') {
      if (!data.recipients || data.recipients.length === 0) {
        setMyState({ ...state, currRecipient: null })
        // setData({ ...data, isOtherTasks: true });
        nowStep = formSteps.findIndex((page) => page === 'OfficeDeliveryFirst')
      } else {
        nowStep = formSteps.findIndex((page) => page === 'OfficeDeliveryFirst')
      }
    } else if (nextStep === 'WhatHanded' && data.recipients && data.recipients.length <= 1) {
      if (myRecipients.length) editRecipient = myRecipients[myRecipients.length - 1]
      nowStep = formSteps.findIndex((page) => page === 'OfficeDeliveryFirst')
    } else {
      nowStep = step - 1;
    }
    setStep(nowStep);
    setMyState({ ...state, nextStep: formSteps[nowStep], currRecipient: editRecipient ? editRecipient : state.currRecipient });
  }
  else {
    if (nextStep === 'OfficeDelivery') {
      nowStep = formSteps.findIndex((page) => page === 'Recipients')
    } else if (nextStep === 'Recipients') {
      if (!data.recipients || data.recipients.length === 0) {
        setMyState({ ...state, currRecipient: null })
        // setData({ ...data, isOtherTasks: true });
        nowStep = formSteps.findIndex((page) => page === 'OfficeDelivery')
      } else {
        nowStep = formSteps.findIndex((page) => page === 'UrgencyRecipient')
      }
    } else {
      nowStep = step - 1;
    }
    setStep(nowStep);
    setMyState({ ...state, nextStep: formSteps[nowStep] });
  }
};

const handleNextStep = (
  step,
  setStep,
  formSteps,
  state,
  setMyState,
  onAddRecipient,
  onAddOrder,
  data,
  setProgressBarPhase,
  setData,
  onAddEditClaimant,
  onAddEditDefendant,
  onSetLastDefendant,
  onSetLastClaimant,
  isTestUser
) => {
  const { nextStep, currRecipient, isFinalStep } = state;

  let nowStep;
  if (isFinalStep) {
    onAddOrder();
  }

  if (isTestUser) {
    if (nextStep === 'OfficeDeliveryFirst' && currRecipient) {
      onAddRecipient();
      nowStep = formSteps.findIndex((page) => page === 'WhatHanded')
    } else {
      nowStep = step + 1;
    }
  } else {
    if ((nextStep === 'UrgencyRecipient' && currRecipient) || (nextStep === 'OfficeDelivery' && currRecipient._id)) {
      onAddRecipient();
    } else {
      nowStep = step + 1;
    }
  }

  setMyState({ ...state, nextStep: formSteps[nowStep], currRecipient: (nextStep === 'Recipients') ? null : currRecipient });
  setStep(nowStep);
};



const handlePhase = (nextStep, setProgressBarPhase) => {

  switch (nextStep) {
    case 'OfficeDelivery':
    case 'UrgencyRecipient':
    case 'Recipients':
    case 'OfficeDeliveryFirst':
      setProgressBarPhase(1);
      break;

    case 'WhatHanded':
      setProgressBarPhase(2);
      break;

    case 'CollectDocumentsOrUploadFile':
    case 'CollectDocumentsOrUploadFile2':
    case 'DocumentsReady':
    case 'UploadFile':
    case 'AddressCollectDocuments':
      setProgressBarPhase(3);
      break;


    case 'Proof':
    case 'Proof3':
    case 'AddressReturningDocuments':
    case 'FinalPage':
      setProgressBarPhase(4);
      break;

    default:
      setProgressBarPhase(4);
      break;
  }
};

export { handleSteps, handlePrevStep, handleNextStep, handlePhase };
