import React from 'react'
import { TailSpin } from 'react-loader-spinner'
import unlocked from '../../../images/unlocked-padlock.svg';
import locked from '../../../images/locked-padlock.svg';

export const UserBlockBtn = ({ activityLoading, idx, onChangeActivity, user }) => {

    return (
        <>
            {activityLoading.isLoading && activityLoading.idxLoading === idx ?
                <div className='spinner'>
                    <TailSpin
                        heigth="15"
                        width="15"
                        color='#4e71ff'
                        ariaLabel='loading'
                    />
                </div>
                :
                user.userActivity === 'not-connected' && !user.isDisabled ? <img style={{ cursor: 'pointer' }} src={unlocked} alt="unlocked" onClick={() => { onChangeActivity(user, user.userActivity, idx) }} />
                    : (user.userActivity === 'blocked' || user.isDisabled ? <img style={{ cursor: 'pointer' }} src={locked} alt="locked" onClick={() => { onChangeActivity(user, 'active', idx) }} />
                        : <img style={{ cursor: 'pointer' }} src={unlocked} alt="unlocked" onClick={() => { onChangeActivity(user, 'blocked', idx) }} />)
            }
        </>
    )
}
