export function updateData(data) {
    return (dispatch) => {
        dispatch({ type: "UPDATE_DATA", data });
    };
}
export function dataSet(data) {
    return (dispatch) => {
        dispatch({ type: "SET_DATA", data });
    };
}

export function removeRecipientId(id) {
    return (dispatch) => {
        dispatch({ type: "REMOVE_RECPIENT_ID", id });
    };
}

