import React from 'react'

export const ClipboardPowerOfAttorney = ({ isHidden }) => {

    return (
        <div className={`pa-container ${isHidden ? 'hidden' : ''}`}>
            <div style={{ backgroundColor: "white", textAlign: 'right', padding: '50px', fontSize: '16px', direction: 'rtl', lineHeight: '1.3', display: 'flex', flexDirection: 'column', fontFamily: 'David' }} id='copyArea'>
                <p className="date" style={{ textAlign: 'left', fontSize: '16px' }}>
                    תאריך:__________
                </p>
                <p className="destination" style={{ lineHeight: '1.4', fontSize: '16px', marginTop: "30px" }}>
                    לכבוד,
                </p>
                <p className="destination" style={{ lineHeight: '1.4', fontSize: '16px', marginTop: "10px" }}>
                    מזכירות בית משפט ה_____________
                </p>
                <p className="destination" style={{ lineHeight: '1.4', fontWeight: '600', fontSize: '16px', marginTop: "10px", textDecoration: 'underline' }}>
                    בעיר______________
                </p>

                <p className="destination" style={{ lineHeight: '1.4', marginTop: "30px", fontSize: '16px' }}>
                    א.ג.נ.,
                </p>

                {/* <p style={{ textAlign: "center", margin: "30px 0", lineHeight: '0.9', fontWeight: '600', textDecoration: 'underline', width: '100%', fontSize: '16px' }} className="subject">
                    ייפוי כח
                </p> */}
                <br />
                <p style={{ textAlign: "center", marginTop: "30px", width: '100%', fontSize: '16px' }} className="subject">
                    הנדון:&nbsp;
                    <span style={{ fontWeight: '600', textDecoration: 'underline' }}>
                        ייפוי כח
                    </span>
                </p>

                <p className="content" style={{ fontSize: '16px', marginTop: '10px' }}>
                    אני הח״מ, עו״ד _______________, מ.ר. _______________, מייפה את כוחם של שליחי חברת דלוברי בע״מ, לרבות: ____________________, ת.ז. ____________________ ו/או ____________________ ת.ז. ____________________, הפועלים מטעמי ועבורי, לאסוף את המסמכים הבאים ממזכירות בית המשפט:
                </p>

                <p className="date" style={{ marginTop: '5px', fontSize: '16px' }}>
                    - תיק: ת״א/עת״מ/תאד״מ/פש״ר _____________, רשימת מסמכים: ________________
                </p>
                <p className="date" style={{ marginTop: '5px', fontSize: '16px' }}>
                    - תיק: ת״א/עת״מ/תאד״מ/פש״ר _____________, רשימת מסמכים: ________________
                </p>
                <p className="date" style={{ marginTop: '5px', fontSize: '16px' }}>
                    - תיק: ת״א/עת״מ/תאד״מ/פש״ר _____________, רשימת מסמכים: ________________
                </p>

                <p className="date" style={{ marginTop: '20px', fontSize: '16px' }}>
                    *ייפוי כח זה מוגבל אך ורק לפעולות המפורטות בו.
                </p>

                <div style={{ textAlign: 'left' }}>
                    <p className="date" style={{ marginTop: '30px', fontSize: '16px', marginLeft: '37px' }}>
                        בכבוד רב ובברכה,
                    </p>
                    <p className="date" style={{ marginTop: '30px', fontSize: '16px' }}>
                        ________________, עו״ד
                    </p>
                </div>
            </div>
        </div>
    )
}
