import React, { useEffect, useState, useRef } from 'react';
import { PersonalOrCompanyToggleUpdate } from './PersonalOrCompanyToggleUpdate';
import { PersonalOrCompanyUpdate } from './PersonalOrCompanyUpdate';
import { UrgencyRecipientUpdate } from './UrgencyRecipientUpdate';
import { AddRecipientPopup } from './PageUtils/AddRecipientPopup';
import { TooltipTriangle } from '../../Utils/TooltipTriangle'
import { useSelector, useDispatch } from 'react-redux';
import {
  addRecipient,
  editRecipient,
} from '../../../store/actions/recipientActions';


export const PersonalDeliveryPage1 = ({ data, setData, setFormState, formState, isError, errors, handleChanges, setTouch, touch, scrollTo, type }) => {
  const dispatch = useDispatch();
  const [isAddedRecipientModal, setIsAddedRecipientModal] = useState(false);
  const [recipientAddedName, setRecipientAddedName] = useState('');

  useEffect(() => {
    setFormState({
      ...formState, currRecipient: {
        ...formState.currRecipient,
        type: formState.currRecipient?.type || 'ADAM_PRATI',
        urgency: formState.currRecipient?.urgency || 'REG'
      }
    });
    setData({ ...data, isUpdatedDelivery: true, isEveningAction: false });
  }, []);

  const getEveningRecipients = (recipient) => {
    let eveningRecipients = data?.eveningRecipients ? [...new Set(data?.eveningRecipients)] : [];
    if (data.isEveningAction) {
      if (!eveningRecipients.includes(recipient._id)) {
        eveningRecipients.push(recipient._id);
      }
    } else {
      eveningRecipients = eveningRecipients.filter(id => id !== recipient._id);
    }
    return eveningRecipients
  }


  const onAddEditRecipient = async () => {
    const recipient = { ...formState.currRecipient };
    const recipientName = recipient?.personName || recipient?.companyName || recipient?.officeName
    if (!recipientName || !recipient?.streetAddress || !recipient?.streetNumber) return
    setRecipientAddedName(recipientName)
    setIsAddedRecipientModal(true)
    let eveningRecipients = data.eveningRecipients ? data.eveningRecipients : []
    if (recipient._id) {
      dispatch(editRecipient(recipient));
      if (type === 'personalDelivery' || (type === 'courtSubmission' && recipient.type !== 'officeDelivery')) eveningRecipients = getEveningRecipients(recipient)
      setData({
        ...data,
        isOtherTasks: false,
        eveningRecipients
      });
    } else {
      const newRecipient = await dispatch(addRecipient(recipient));
      if (type === 'personalDelivery' || (type === 'courtSubmission' && recipient.type !== 'officeDelivery')) eveningRecipients = getEveningRecipients(newRecipient)
      setData({
        ...data,
        recipients: [...data.recipients, newRecipient._id],
        isOtherTasks: true,
        isPersonalOrCompany: 'true',
        eveningRecipients
      });
    }
    scrollTo('top')
  };

  return (
    <div className='form7-personal-company page-1'>
      {isAddedRecipientModal && <AddRecipientPopup recipientAddedName={recipientAddedName} setIsAddedRecipientModal={setIsAddedRecipientModal} type={data.type} />}
      <PersonalOrCompanyToggleUpdate data={data} setData={setData} setFormState={setFormState} formState={formState} />
      <PersonalOrCompanyUpdate data={data} setData={setData} setFormState={setFormState} formState={formState} isError={isError}
        errors={errors} handleChanges={handleChanges} setTouch={setTouch} touch={touch} scrollTo={scrollTo} />
      <UrgencyRecipientUpdate data={data} setData={setData} formState={formState} setFormState={setFormState} type={type} />

      <div className="add-recipient">
        <TooltipTriangle text={'הוספת נמענים נוספים למסירת אותם המסמכים'}>
          <img
            className="btn-add"
            src='/images/plus.svg'
            alt=''
            onClick={() => {
              onAddEditRecipient()
              setFormState((prevState) => ({
                ...prevState,
                currRecipient: {
                  type: "ADAM_PRATI",
                  urgency: "REG",
                },
              }));
            }}
          />
          <h3>{'הוספת נמען נוסף'}</h3>
        </TooltipTriangle>
      </div>
    </div>
  );
};

export default PersonalDeliveryPage1;
