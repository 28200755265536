import React, { useState, useEffect } from 'react'
import SearchIcon from "../../../images/Search Icon.svg";
import { useDispatch } from 'react-redux';
import { updateErrorModal } from '../../../store/actions/systemActions';
import {
    cityOptionsLandRegistration,
    cityOptionsInheritRegistration,
    cityOptionsCompaniesRegistration,
    cityOptionsPartnershipsRegistration,
    cityOptionsAssociationsAndCompanies,
    cityOptionsLandSupervisor
} from '../../../consts/data'

export const ChooseOffice = ({ data, setData, type, submitForm, errors, setSubmitForm }) => {
    const dispatch = useDispatch();
    const [options, setOptions] = useState([]);

    useEffect(() => {
        switch (type) {
            case 'landRegistration':
                setOptions(cityOptionsLandRegistration);
                break;
            case 'landSupervisor':
                setOptions(cityOptionsLandSupervisor);
                break;
            case 'inheritanceRegistration':
                setOptions(cityOptionsInheritRegistration);
                break;
            case 'companiesRegistration':
                setOptions(cityOptionsCompaniesRegistration);
                break;
            case 'partnershipsRegistration':
                setOptions(cityOptionsPartnershipsRegistration);
                break;
            case 'associationsAndCompanies':
                setOptions(cityOptionsAssociationsAndCompanies);
                break;
            default:
                break;
        }
    }, [])

    useEffect(() => {
        if (submitForm && errors.branch) {
            setSubmitForm(false);
            dispatch(
                updateErrorModal({
                    title: 'שגיאה',
                    errorText: errors.branch,
                    submitText: 'אישור',
                    isCancel: false
                })
            );
        }
    }, [data.branch, errors, submitForm]);

    const [searchTerm, setSearchTerm] = useState("");

    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const searchResults = options.filter((choice) =>
        choice.city.includes(searchTerm)
    )

    const handleBranchSelected = (branch) => {
        setData({
            ...data,
            branch: branch.city,
            branchAddress: {
                formattedAddress: branch.address,
                comments: branch.comments,
                floor: branch.floor,
                streetNumber: branch.streetNumber,
                streetAddress: branch.streetAddress,
                city: branch.city,
                fullAddress: branch.fullAddress,
            },
            branchFullAddress: branch.fullAddress
        });
    }
    
    return (
        <div className='main-form  branch-selecting'>
            <div className='modal-content-container '>
                <h2 className='large-title'>בחרו את הלשכה</h2>

                {data.type !== 'partnershipsRegistration' &&
                    <div className="search-field">
                        <img src={SearchIcon} alt="SearchIcon" />
                        <input
                            type="text"
                            className="search-input"
                            placeholder=""
                            value={searchTerm}
                            onChange={handleChange}
                        />
                    </div>
                }

                <div className="branches list">
                    {searchResults.map((branch, idx) => {
                        return (<div key={idx} className={`branch ${data?.branch && data?.branch === branch.city ? 'blue' : 'white'}`} onClick={() => handleBranchSelected
                            (branch)}>
                            <input
                                type="radio"
                                id={branch.city}
                                name="branch"
                                value={branch.city}
                                defaultChecked={data?.branch && (data?.branch === branch.city)}
                            />
                            <span className="checkmark"></span>
                            <label htmlFor={branch.city} >
                                <p>{branch.city}</p>
                            </label>
                        </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
