import React, { useEffect } from 'react';
import { UserProfile } from '../UserDashboard/UserProfile'
import { ReactComponent as Back } from '../../images/ArrowDrop.svg'
import { useDispatch, useSelector } from 'react-redux';
import { UserMsgs } from '../UserDashboard/UserMsgs'
import { UserOrders } from '../UserDashboard/UserOrders'
import { UserDetails } from '../UserDashboard/UserDetails'
import { UserAddressList } from './UserAddressList'
import { UserInvoices } from '../UserDashboard/UserInvoices'
import { UserCompany } from '../UserDashboard/UserCompany'
import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';

export const ModalUser = ({ section }) => {
    const navigate = useNavigate();

    const onCloseModal = () => {
        navigate("/user")
    }

    const modalPreview = (section) => {
        switch (section) {
            case 'orders':
                return { title: 'ההזמנות שלי', preview: <UserOrders /> };
            case 'details':
                return { title: 'הפרטים שלי', preview: <UserDetails /> };
            case 'addresses':
                return { title: 'הכתובות שלי', preview: <UserAddressList /> };
            case 'msgs':
                return { title: 'הודעות ועדכונים', preview: <UserMsgs /> };
            case 'company':
                return { title: 'החברה שלי', preview: <UserCompany /> };
            default:
                return <></>;
        }
    };

    return (
        <div className="modal-toolbar open">
            <div className="modal-title">
                <p className="title">{modalPreview(section).title}</p>
                <Back onClick={onCloseModal} />
            </div>
            <div className="modal-content">
                {modalPreview(section).preview}
            </div>
        </div>
    )
}
