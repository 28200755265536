import React, { useEffect, useState, useRef } from 'react';
import { OfficeDeliveryUpdate } from './OfficeDeliveryUpdate';
import { UrgencyRecipientUpdate } from './UrgencyRecipientUpdate';
import { TooltipTriangle } from '../../Utils/TooltipTriangle'
import { useSelector, useDispatch } from 'react-redux';
import { AddRecipientPopup } from './PageUtils/AddRecipientPopup';
import {
  addRecipient,
  editRecipient,
} from '../../../store/actions/recipientActions';

export const OfficeDeliveryFirst = ({ data, setData, setFormState, formState, isError, errors, handleChanges, setTouch, touch, scrollTo, type }) => {
  const dispatch = useDispatch();
  const [isAddedRecipientModal, setIsAddedRecipientModal] = useState(false);
  const [recipientAddedName, setRecipientAddedName] = useState('');

  useEffect(() => {
    // setFormState({ ...formState, currRecipient: { ...formState.currRecipient, type: 'officeDelivery', urgency: 'REG' } });
    setData({ ...data, isUpdatedDelivery: true });
  }, []);


  const onAddEditRecipient = async () => {
    const recipient = { ...formState.currRecipient };
    const recipientName = recipient?.officeName || recipient?.personName || recipient?.companyName
    if (!recipientName || !recipient?.streetAddress || !recipient?.streetNumber) return
    setRecipientAddedName(recipientName)
    setIsAddedRecipientModal(true)
    if (recipient._id) {
      dispatch(editRecipient(recipient));
      setData({
        ...data,
        isOtherTasks: false,
      });
    } else {
      const newRecipient = await dispatch(addRecipient(recipient));
      setData({
        ...data,
        recipients: [...data.recipients, newRecipient._id],
        isOtherTasks: true,
      });
    }
    scrollTo('top')
  };

  return (
    <div className='form7-personal-company page-1'>
      {isAddedRecipientModal && <AddRecipientPopup recipientAddedName={recipientAddedName} setIsAddedRecipientModal={setIsAddedRecipientModal} type={data.type} />}
      <OfficeDeliveryUpdate data={data} setData={setData} setFormState={setFormState} formState={formState} isError={isError}
        errors={errors} handleChanges={handleChanges} setTouch={setTouch} touch={touch} scrollTo={scrollTo} />
      <UrgencyRecipientUpdate data={data} setData={setData} formState={formState} setFormState={setFormState} type={type} />

      <div className="add-recipient">
        <TooltipTriangle text={'הוספת משרדים נוספים למסירת אותם המסמכים'}>
          <img
            className="btn-add"
            src='/images/plus.svg'
            alt=''
            onClick={() => {
              onAddEditRecipient()
              setFormState((prevState) => ({
                ...prevState,
                currRecipient: {
                  type: "officeDelivery",
                  urgency: "REG",
                },
              }));
            }}
          />
          <h3>{'הוספת משרד נוסף'}</h3>
        </TooltipTriangle>
      </div>
    </div>
  );
};

export default OfficeDeliveryFirst;
