
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateErrorModal } from '../../../store/actions/systemActions';
import { useViewport } from '../../../Hooks/useViewport'

export const DeliveryAfterSubmission = ({ data, setData, setFormState, formState }) => {

  const { width } = useViewport();
  const breakpoint = 767;

  const [firstAlignment, setFirstAlignment] = useState(
    data?.deliveryAfter || 'false'
  );
  const [secondAlignment, setSecondAlignment] = useState(
    data?.isPrivateOrOffice || 'false'
  );
  const [thirdAlignment, setThirdAlignment] = useState(
    data?.isPersonalOrCompany || 'true'
  );
  const isPopUpModal = useSelector((state) => state.systemReducer.isPopUpModal);

  const dispatch = useDispatch();

  const optionA = 'כן';
  const optionB = 'לא';
  const optionC = 'מסירה אישית ';
  const optionD = 'המצאות למשרדים';
  const optionE = 'אדם פרטי';
  const optionF = 'בית עסק';


  useEffect(() => {
    setData({
      ...data,
      deliveryAfter: firstAlignment,
      isPrivateOrOffice: secondAlignment,
      isPersonalOrCompany: thirdAlignment,
    });
    setFormState({ ...formState, currRecipient: null })
    // return () => {
    //   setData({ ...data, isOtherTasks: true })
    // }
  }, []);

  useEffect(() => {
    if (firstAlignment === 'true' && secondAlignment === 'true' && width < breakpoint) {
      document.getElementById('recipient-choose').focus();
    }
  }, [firstAlignment, secondAlignment]);

  useEffect(() => {
    const { isDeleteRecipients, status } = isPopUpModal;
    if (isDeleteRecipients && status === 'submit') {
      setData({ ...data, deliveryAfter: 'false', recipients: [] });
      setFirstAlignment('true');
    }
  }, [isPopUpModal.isDeleteRecipients]);

  const handleChangeData = async (e, field, value) => {
    if (width > breakpoint || field === 'deliveryAfter') {
      e.preventDefault();
    }
    if (field === 'deliveryAfter') {
      const { recipients, deliveryAfter } = data;
      if (recipients && recipients.length > 0 && deliveryAfter === 'true' && value === 'false') {
        dispatch(updateErrorModal({ isCancel: false, title: 'אזהרה', errorText: 'הנמענים שלך ימחקו במידה ותלחץ אישור', submitText: 'אישור', status: 'delete_recipients' }));
        return
      }
      else {
        setFirstAlignment(value);
      }
    } else if (field === 'isPrivateOrOffice') {
      setSecondAlignment(value);
    } else {
      setThirdAlignment(value);
    }
    setData({ ...data, [field]: value });
  };

  const handleAlignment = (state) => {
    if (state === 'true') {
      return 'right';
    } else {
      return 'left';
    }
  };

  const getSubTitleText = () => {
    switch (data?.isPrivateOrOffice) {
      case 'false':
        return 'החתמת ״נתקבל״ של משרד עו״ד או משרד ממשלתי.'
      default:
        return 'המצאה כדין לאדם פרטי או בית עסק, עד 3 ביקורים בכתובת והכנת תצהיר מוסר מאומת על ידי עורך דין.'
    }
  }

  return (
    <div className='main-form block form7-deliveryAfter'>
      <div className='modal-content-container'>
        <h2 className='large-title'>
          {((data.recipients && data.recipients.length > 0))
            ? 'בחירת סוג הנמען הנוסף'
            : 'האם נדרשת המצאה לאחר הגשה?'}
        </h2>

        {!((data.recipients && data.recipients.length > 0)) ? <p className="subtitle">
          בחרו האם תרצו שנבצע המצאה של המסמכים לצדדים בהליך או לגורם אחר, לאחר הגשתם לערכאה השיפוטית
        </p> :
          <p className="subtitle">{getSubTitleText()}</p>
        }
        {!((data.recipients && data.recipients.length > 0)) && (
          <div className='first-toggle'>
            <div
              className={`form-main-toggle ${handleAlignment(firstAlignment)}`}
            >
              <span></span>
              <button
                name='deliveryAfter'
                onClick={(e) => handleChangeData(e, 'deliveryAfter', 'true')}
              >
                {optionA}
              </button>
              <button
                name='deliveryAfter'
                onClick={(e) => handleChangeData(e, 'deliveryAfter', 'false')}
              >
                {optionB}
              </button>
            </div>
          </div>
        )}

        {firstAlignment === 'true' && width > breakpoint && (
          <div className={`delivery-option ${(data.recipients && data.recipients.length > 0) ? '' : 'border-top'}`}>
            {!(data.recipients && data.recipients.length > 0)
              && <>
                <h3 >בחירת סוג ההמצאה</h3>
                <p className="subsubtitle">{getSubTitleText()}</p>
              </>
            }
            <div
              className={`form-main-toggle ${(data.recipients && data.recipients.length > 0) ? '' : 'small'} ${handleAlignment(
                secondAlignment
              )}`}
            >
              <span></span>
              <button
                onClick={(e) => handleChangeData(e, 'isPrivateOrOffice', 'true')}
              >
                {optionC}
              </button>
              <button
                onClick={(e) => handleChangeData(e, 'isPrivateOrOffice', 'false')}
              >
                {optionD}
              </button>
            </div>
            {/* <img className='icon-info' src={info} alt='info' /> */}
          </div>
        )}

        {firstAlignment === 'true' && width < breakpoint && (
          <div className='radio-btn-choice'>
            <h3>בחירת סוג המסירה</h3>
            <p className="subsubtitle">
              מסירה כדין לאדם פרטי או בית עסק בחרו במסירה אישית. להחתמת ״נתקבל״ של משרד עו״ד או משרד ממשלתי בחרו בהמצאות למשרדים
            </p>
            <div className="radio-btn-container">
              <input
                type="radio"
                id='true'
                className="radio-btn"
                name='isPrivateOrOffice'
                checked={data?.isPrivateOrOffice === "true"}
                onChange={(e) => handleChangeData(e, 'isPrivateOrOffice', 'true')}
                value='true' />
              <label htmlFor='true'>{optionC}</label>
            </div>
            <div className="radio-btn-container">
              <input
                type="radio"
                id='false'
                className="radio-btn"
                checked={data?.isPrivateOrOffice === "false"}
                onChange={(e) => handleChangeData(e, 'isPrivateOrOffice', 'false')}
                name='isPrivateOrOffice'
                value='false' />
              <label htmlFor='false'>{optionD}</label>
            </div>
          </div>
        )}

        {/* {((firstAlignment === 'true' && secondAlignment === 'true' && width > breakpoint) ||
          (data.isOtherTasks && secondAlignment === 'right')) && (
            <div className='blockSelect delivery-option'>
              <div>
                <h3 className='sub-title-bold'>למי מיועדת ההמצאה</h3>
                <div
                  className={`form-main-toggle small ${handleAlignment(
                    thirdAlignment
                  )}`}
                >
                  <span></span>
                  <button
                    onClick={(e) =>
                      handleChangeData(e, 'isPersonalOrCompany', 'true')
                    }
                  >
                    {optionE}
                  </button>
                  <button
                    onClick={(e) =>
                      handleChangeData(e, 'isPersonalOrCompany', 'false')
                    }
                  >
                    {optionF}
                  </button>
                </div>
              </div>
            </div>
          )} */}

        {/* {firstAlignment === 'true' && secondAlignment === 'true' && width < breakpoint && (
          <div className='radio-btn-choice' id="recipient-choose" tabIndex="0" >
            <h3>למי מיועדת המסירה</h3>
            <div className="radio-btn-container">
              <input
                type="radio"
                id='true2'
                className="radio-btn"
                name='isPersonalOrCompany'
                checked={data?.isPersonalOrCompany === "true"}
                onChange={(e) => handleChangeData(e, 'isPersonalOrCompany', 'true')}
                value='true2'
              />
              <label htmlFor='true2'>{optionE}</label>
            </div>
            <div className="radio-btn-container">
              <input
                type="radio"
                id='false1'
                className="radio-btn"
                checked={data?.isPersonalOrCompany === "false"}
                onChange={(e) => handleChangeData(e, 'isPersonalOrCompany', 'false')}
                name='isPersonalOrCompany'
                value='false1'
              />
              <label htmlFor='false1'>{optionF}</label>
            </div>
          </div>
        )} */}


      </div>
    </div >
  );
};

export default DeliveryAfterSubmission;
