import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UploadFile } from './UploadFile'
export const NotarizedTranslation = ({ setData, handleChanges, errors, submitForm, setSubmitForm, pageName, type }) => {
    const [alignment, setAlignment] = useState('left');
    const data = useSelector((state) => state.dataReducer.data)

    useEffect(() => {
        const { isTranslation } = data;
        if (!isTranslation) {
            setData({ ...data, isTranslation: false });
        }
        else {
            setAlignment(isTranslation === true ? 'right' : 'left');
        }
    }, []);

    const handleChangeCheckBox = (event, newAlignment) => {
        event.preventDefault();
        setAlignment(newAlignment);
        const { name, value } = event.target
        if (value === 'false') {
            delete data.language
            delete data.pages
        }
        setData({ ...data, [name]: value === 'true' ? true : false });
    };

    const handleChangeLanguage = (event) => {
        const { name, value } = event.target
        setData({ ...data, [name]: value })
    }

    const notarizedTranslationTitles = {
        apostilSubmission: {
            mainTitle: "האם תרצו לקבל הצעת מחיר לתרגום נוטריוני?",
            subTitle: "במידה ותבחרו כן, אנו נעביר את הפרטים שלכם לנוטריון מוסמך לצורך קבלת הצעת מחיר לתרגום.",
            yes: "כן",
            no: "לא",
            language: "לאיזו שפה?"
        },
        apostilInCourt: {
            mainTitle: "רוצה לקבל הצעת מחיר לאישור / תרגום נוטריוני?",
            subTitle: "אם עוד אין לך אישור נוטריוני או תרגום נוטריוני על המסמכים, אנו יכולים להעביר את הפרטים שלך לנוטריון מוסמך שיצור עימך קשר ויתן הצעת מחיר לביצוע השירות.",
            yes: "כן",
            no: "לא",
            language: "אם יש צורך בתרגום, ציינו את השפה אליה תרצו לתרגם"
        },
    }


    return (
        <div className='main-form notarized-translation' >
            <div className='modal-content-container'>

                <h2 className='large-title'>{notarizedTranslationTitles[type]?.mainTitle || ''}</h2>
                <p className='subtitle' style={{ "marginBottom": '10px' }}>{notarizedTranslationTitles[type]?.subTitle || ''}</p>
                <p className='subtitle bold'>שימו לב! מחיר ההזמנה אינו כולל את תרגום המסמכים.</p>

                <div className={`form-main-toggle ${alignment}`}>
                    <span></span>
                    <button
                        name='isTranslation'
                        value='true'
                        onClick={(ev) => handleChangeCheckBox(ev, 'right')}
                    >
                        {notarizedTranslationTitles[type]?.yes || ''}
                    </button>
                    <button
                        name='isTranslation'
                        value='false'
                        onClick={(ev) => handleChangeCheckBox(ev, 'left')}
                    >
                        {notarizedTranslationTitles[type]?.no || ''}
                    </button>
                </div>

                {alignment === 'right' &&
                    <>
                        <div className='language'>
                            <p>{notarizedTranslationTitles[type]?.language || ''}</p>
                            <input
                                type="text"
                                onChange={handleChangeLanguage}
                                name='language'
                                value={data?.language || ''}
                                placeholder='לדוגמא: אנגלית'
                            />
                        </div>
                        <UploadFile
                            isTranslation={data?.isTranslation || false}
                            data={data}
                            setData={setData}
                            handleChanges={handleChanges}
                            errors={errors}
                            submitForm={submitForm}
                            setSubmitForm={setSubmitForm}
                            pageName={pageName}
                        />
                    </>
                }
            </div>
        </div>
    );
};

export default NotarizedTranslation;
