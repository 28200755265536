const INITIAL_STATE = {
    data: {
        recipients: [],
        files: [],
        // landRegistrationData: {}
    }
}
export function dataReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case "SET_DATA":
            return {
                ...state,
                data: action.data
            }
        case 'UPDATE_DATA':
            return {
                ...state,
                data: { ...state.data, ...action.data }
            }

        case 'REMOVE_RECPIENT_ID':
            return {
                ...state,
                data: {
                    ...state.data,
                    recipients: state.data.recipients.filter(recipientId => recipientId !== action.id)
                }
            }
        default:
            return state
    }
}