//idLocate
//איתור מספר זהות

const handleSteps = () => {
  return [
    'PersonDetails',
    'Recipients',
    'FinalPage'
  ]
};

const handlePrevStep = (step, setStep, formSteps, state, setMyState, data, setData, setProgressBarPhase, onSetLastClaimant, onSetLastDefendant, myRecipients) => {
  const { nextStep, currRecipient } = state;
  const { isOtherTasks } = data
  let nowStep
  let editRecipient
  if (nextStep === 'PersonDetails') {
    if (!currRecipient?._id) editRecipient = myRecipients[myRecipients.length - 1]
    else {
      const recipientIdx = myRecipients.findIndex(recipient => recipient._id === currRecipient._id);
      if (recipientIdx > 0) {
        editRecipient = myRecipients[recipientIdx - 1]
      }
    }
    setData({ ...data, isOtherTasks: false });
    nowStep = formSteps.findIndex((page) => page === 'PersonDetails')
  } else if (nextStep === 'FinalPage' && myRecipients.length <= 1) {
    nowStep = formSteps.findIndex((page) => page === 'PersonDetails')
  }
  else if (step < 0) return
  else nowStep = step - 1;
  setStep(nowStep);
  setMyState({ ...state, nextStep: formSteps[nowStep], isFinalStep: false, currRecipient: editRecipient ? editRecipient : state.currRecipient });
};

const handleNextStep = async (step, setStep, formSteps, state, setMyState, onAddRecipient, onAddOrder) => {
  const { nextStep, currRecipient, isFinalStep } = state;

  let nowStep;
  if (nextStep === 'PersonDetails' && currRecipient) {
    await onAddRecipient()
    nowStep = formSteps.findIndex((page) => page === 'FinalPage')
  } else if (isFinalStep) {
    onAddOrder();
  } else {
    nowStep = step + 1;
  }
  setMyState({ ...state, nextStep: formSteps[nowStep], currRecipient: (nextStep === 'PersonDetails') ? null : currRecipient });
  setStep(nowStep);
};

const handlePhase = (nextStep, setProgressBarPhase) => {

  switch (nextStep) {
    case 'PersonDetails':
    case 'Recipients':
      setProgressBarPhase(1);
      break;

    default:
      setProgressBarPhase(2);
      break;
  }
};

export { handlePhase, handleSteps, handlePrevStep, handleNextStep };
