import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import { sendPasswordResetEmail } from "firebase/auth";
import { useSelector } from 'react-redux'
import { ReactComponent as Close } from '../../../images/close.svg'
import { auth } from '../../../integrations/firebase'

export function ResetPasswordSent() {
    const navigate = useNavigate();
    const systemData = useSelector((state) => state.systemReducer.systemData)
    const [errorAuth, setErrorAuth] = useState(null)

    const onForgotPassword = () => {
        if (!systemData.resetPassEmailAddress) return
        sendPasswordResetEmail(auth, systemData.resetPassEmailAddress)
            .then(() => {
                setErrorAuth('דוא״ל לאיפוס סיסמה נשלח שנית לכתובת הדוא״ל שהזנת')
            })
            .catch((error) => {
                const errorMessage = error.message;
                console.log('%c  errorMessage:', 'color: white;background: red;', errorMessage);
            });
    }
    const setModalClose = () => {
        navigate(systemData.isCheckout ? '/checkout' : '/')
    };
    return (<>

        <div className="email-sent">
            <Close onClick={setModalClose} />
            <div className="system-msg">

                <div className="msg-content">
                    <h2 className="main-title bold">דוא״ל בדרך אליך</h2>
                    <p className="decription">
                        <br />
                        מצויין! מצאנו את הפרטים שלך. מחכה לך הודעה בתיבת הדוא"ל שלך עם הקישור לאיפוס הסיסמה.
                    </p>

                    <p className="decription">
                        לא קיבלתם דוא״ל? <span onClick={onForgotPassword}><u>לשליחה חוזרת</u></span>
                    </p>

                    {errorAuth && <p className="error-message">{errorAuth}</p>}

                </div>
                <button type='submit' onClick={() => navigate('/')} className='main-btn long register' >חזרה למסך ההזמנה</button>
            </div>
        </div>

    </>
    );
}


